import React from "react";
import { LogoHeader } from "../../image/LogoHeader";
import YouTube from "react-youtube";
import { Carousel } from "@mantine/carousel";
import { loadS3Image } from "../../helpers/Functions";

export const Mu = () => {
  return (
    <div className="bg-blue-300">
      <LogoHeader className=''/>

      <div className="grid md:grid-cols-1 lg:grid-cols-2 mt-8">
      <div className="mt-8 ml-8 mr-8 video-container mb-8 pt-8 mt-8 relative" style={{ paddingBottom: "56.25%" }}>
          <YouTube videoId={"KAbClv5RVEM"} />
        </div>
        <Carousel className="mt-8 mr-8 ml-8">
          <Carousel.Slide>
            <img src={loadS3Image("mu 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_2 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_3 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_4 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_5 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_6 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_7 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_8 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
          <Carousel.Slide>
            <img src={loadS3Image("mu_9 2.JPG", "mu")} alt="mu_pics"></img>
          </Carousel.Slide>
        </Carousel>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 mt-8">
        <div className="text-black text-justify ml-8 mr-8 mt-8 mb-8">
          Només aquesta pena d’adormir-se i de despertar-se i de sentir-se la
          vida que no saps d’on et ve i que fugirà sense que sàpigues per què te
          la van donar i per què te la prenen. Té, això, això i això. I ara apa.{" "}
          <br></br> <br></br>
          CITA APA: La mort i la primavera (1986). Barcelona: Fundació Mercè
          Rodoreda, 1997. Robat a M. Rodoreda i a A. Balasch. <br></br> <br></br>
          In <em>The Gateless Barrier,</em> "無" is exemplified as the door to enlightenment. One of its koans: <br></br><br></br>
  Monk asks: Does a dog have Buddha nature or not? <br></br>
  Teacher says: None (  無。). <br></br><br></br>
  This nothing cannot be translated as a negative response, but perhaps as a non-applicable question, an intrinsic nonsense in the proposal. It could be assimilated to point seven of Ludwig Wittgenstein's <em>Tractatus Logico Philosophicus:</em> <br></br><br></br>
  "On what cannot be spoken, one must remain silent." <br></br><br></br>
  Little by little, in the history of Western humanist thought, notions about language have been crumbling, and through its cracks, the viscosity of the unspeakable has begun to ooze. Science tends to come later, like a broom car with Diogenes syndrome, and ten years after the <em>tractatus,</em> Kurt Gödel would publish his <em>Incompleteness Theorems</em> as Wittgenstein's simile of mathematical logic, axiomatically demonstrating that any axiomatic theory is incomplete, or what is the same, that there are undecidable propositions in "the language of God". In his defense, it's not as if Wittgenstein is punctual either; 18 centuries earlier, Nāgārjuna in his Fundamentals of the <em>Middle Way</em> wrote: <br></br><br></br>
  "It has been taught that there is a self and even that there is no self. But the Buddha has not taught the self or the non-self. When the object of language ceases, the object of thought ceases. Like nirvana, the nature of all things lacks arising and passing away."<br></br>
  <br></br>
  I suppose the impulse of meaninglessness has accompanied us since the first man, tightrope walking at ground level between animality and divinity on the rope thrown by the word. This ultra-verborreic circular exercise around the debris of dialectic is symptomatic of the Western condition, like a Beckettian exercise, a clamor of an emptied word. Beckett in <em>Endgame:</em><br></br>
  <br></br>
  "I use the words you taught me! If they no longer want to mean anything, teach me others!"<br></br>
  <br></br>
  This notion of the uncontrolled word nevertheless persists in Western consciousness, contrasting interestingly with the way of refining work towards simplicity that we often see in Eastern production. The trace of Wu Wei in the enso, the temporality and finitude in On Kawara, the same cinema of Ozu... These two ways of doing, while they could exemplify the Shakespearian duality, have a common background. Both share a materialization of a life force, or what is the same, an occupation of time. A falsely circular time, rather helical, constricting the body like a boa. <br></br>
  Douglas Hofstadter poses <em>MU</em> as a logical puzzle without an answer, which is solved only by leaving the formulation of the system itself. This inward flight reminds me of the "fugitive freedom" that St. Augustine longs for in his <em>Confessions:</em> <br></br><br></br>
  "And if I tried to install my soul in God so that it could rest, I would slip into the void and collapse back onto myself. My soul was for me a miserable place where it was impossible to be, but from where I couldn't escape either. Where would I go fleeing from myself? Where would I go without following my own footsteps?"<br></br>
  <br></br>
  Flee, from what? From boredom? Giacomo Leopardi speaks of <em>noia</em> or tedium as an absence that fills the void. A void in the human soul that ignites desire, but also a final void that, without warning, creates where there was nothing before. A creative impulse without origin that can resemble the dance of particles and antiparticles that materialize and disappear constantly in the void, like an infinite struggle of being against nothingness. This ontological chance and indeterminacy that we are only beginning to accept can also be seen in Young's iconic double-slit experiment, which could give an interesting twist to the concept of action painting if you let it overexpose by repeating it enough times. It would end up creating, by probability, a monochrome, following a long tradition in 20th-century art. On the right, an illustration. <br></br><br></br>
  Mu is also, according to the CCRU (or Khrú, devil of the apocalypse), a Transitional Pacific civilization linked to Lemurian influence, a proposal by Ernst Haekel about what could have been the beginning of civilization 66 million years ago in a submerged area near Madagascar. Currently, claims about the existence of Mu (nothing) are considered unfounded, although Aleister Crowley rescued it and there are Buddhist texts that mention a lost land in the Pacific. The connection between Crowley and Buddhism is not trivial, as his famous phrase "There is no god but man" is nothing more than a reformulation of the <em>koan:</em> "Kill Buddha if Buddha exists elsewhere. Kill him because you should assume your own Buddha nature," which Death Grips later reclaimed in their: "I am the beast I worship".
  <br></br><br></br>
  So is Zen anti-humanist? <br></br><br></br>
  無。 <br></br><br></br>
  This negative theology could also be applied to the intricacies of language as an Adornian approach to mystery and nonsense. I have conflicts when writing to accompany a work that grows on its own, and more so when thinking about how Derrida speaks of the truth of art in his non-saying (in other words, that the difference between form and content is trivial). I am more interested in this work simply pointing out the fluctuations of reality that accompany life, with all its vicissitudes and ruptures. <br></br>
</div>

        <div className="mr-8 ml-10">
          <img
            src={loadS3Image("extramu2.png", "mu")}
            className="mb-8"
            alt="extra2"
          ></img>
        </div>
      </div>
    </div>
  );
};
