
import React from 'react';

const BlankComponent = () => {
  return (
    <div></div>
  );
};

export default BlankComponent;
