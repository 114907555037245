import React from 'react'
import { LogoHeader } from '../../image/LogoHeader'

export const Chiuhuahua = () => {
  return (
    <div>
      <LogoHeader />
    </div>
  )
}
