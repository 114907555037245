import React, { useState, useEffect } from 'react';

export const AnimatedAscii = () => {

      const frames = [
            <div>

`rr||>>||^>-|}rrrwwwwzzzzzzwwr?vvvrrrwwwwwwwwwwwwzzr`~??+^^^^-+}wwwwwwwwww~<br></br>
`xx+>rrwz}v}zuuuuuuuuuuxxzzzz}+?>+???>rzxxxxxxxxxxxw>?}v?}w}v}wwzxxxzzzzzx|<br></br>
-uuxxuuxxxxxxxxxxxxzzzzzzzzzz?+>>+|?}|~wzzzzzzzxxxxxxxxxxzzwwwzwwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzv-+>>+++>^?wzzzzzzzzzxxxxxxxzzwwwwwwwwwwzxzzzz|<br></br>
`zzwzzzzzzzzzzzzzzzzzzzzzzzzr?}}v}wwrwzxzzzzzzzzwzwwwwwwwwwwwwwwrrwwwxxzzz|<br></br>
                  -uuxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxzzwwwrrrwwzxzwrrrrrrrrwwwwwwzxxxxxzx|<br></br>
                  `xuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwzxzwwrrrrwwzxxxwrwrrrrrwzzwrrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxzzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzwwwwwrrrwzxxwrrwzxxxxxxzx|<br></br>
                  `zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrwzuuxwwrrwzzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwxuuxxwwwwrrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzxzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxxxzzwwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzzxxxuuxxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rxuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzzzzw~<br></br>
`zzzzzzzzzzxxxxxxxxxxxxxxxxz}?vv?}xuxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzw}`<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxzv?}v||v??wuxxxuuv+}wwxxzr??vwrrrwwwzzzzzzzzzzzzv.<br></br>
`zzzzzxxzzzzzzzzzzzzzxxwvvrzz.  .+v?vzzxwv}w>.>yyxzxr??rwwrrrwwwzzzzzzzzz>.<br></br>
`xxxzzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwrrrrwwwwzzzzzz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvv}wwwrrrrwwwwwzz|<br></br>
-uuuuxzzwwwwzzxxzvv}v~  >yuzyy| .~>}}vv}vrv+`  wyuzyu-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuxwwrrrwv?}v-    .xyzxu}?rr}}wzwww}vv??+}yyxyy^    .+}??zwwwrrrrwww~<br></br>
-uuuuuxxzwwwwwz?zv       ?uxzwvv}wuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzzx}vz.   `^?}}vv}zuuuuuuuuuuuuuyyuw?+>vr}~.   .z?ruuuuxxxzzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuuuuuuuuuuuuuuuuuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrrwr}r}xxxu|<br></br>
`xxxxxxxxxxxxxxxrvrwzzxxzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuz}v?xuuuur>>+???v|<br></br>
`xxxxxxxxxxxxzzzzxzzzwzzxxxxxxxxxzzzzxxxuuuuuuuuuuuuuuuuuuuxxzzwww}^>+^>>^~<br></br>
`xxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxuuuuuuuuuuuuuuuuuuuxxw?}}rvvr~<br></br>
                  -uuuxxzzxxxzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzxxuuuuuuuuuuuuuuuuuuuuuyyyuyuy^<br></br>
                  -uuuxxzzxzxzzzzwwzzzzzzzxxxzzzzzzzzzzzzzzzzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzzzzwwv?>?v??wzzwwwzxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrr}}}}}}?+>+^v^?zwwr}}rwwwwwzzwwwwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rr||>>||^>-|}rrrwwwwzzzzzzwwr?vvvrrrwwwwwwwwwwwwzzr`~??+^^^^-+}wwwwwwwwww~<br></br>
`xx+>rrwz}v}zuuuuuuuuuuxxzzzz}+?>+???>rzxxxxxxxxxxxw>?}v?}w}v}wwzxxxzzzzzx|<br></br>
-uuxxuuxxxxxxxxxxxxzzzzzzzzzz?+>>+|?}|~wzzzzzzzxxxxxxxxxxzzwwwzwwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzv-+>>+++>^?wzzzzzzzzzxxxxxxxzzwwwwwwwwwwzxzzzz|<br></br>
`zzwzzzzzzzzzzzzzzzzzzzzzzzzr?}}v}wwrwzxzzzzzzzzwzwwwwwwwwwwwwwwrrwwwxxzzz|<br></br>
                  -uuxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxzzwwwrrrwwzxzwrrrrrrrrwwwwwwzxxxxxzx|<br></br>
                  `xuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwzxzwwrrrrwwzxxxwrwrrrrrwzzwrrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxzzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzwwwwwrrrwzxxwrrwzxxxxxxzx|<br></br>
                  `zxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrwzuuxwwrrwzzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwxuuxxwwwwrrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzxzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxxxzzwwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzzxxxuuxxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rxuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzzzzw~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xuxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzw}`<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxzv?}v||v??wuxxxuuv+}wwxxzr??vwrrrwwwzzzzzzzzzzzzv.<br></br>
`zzzzzxxzzzzzzzzzzzzzxxwvvrzz.  .+v?vzzxwv}w>.>yyxzxr??rwwrrrwwwzzzzzzzzz>.<br></br>
`xxxzzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwrrrrwwwwzzzzzz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvv}wwwrrrrwwwwwzz|<br></br>
-uuuuxzzwwwwzzxxzvv}v~  >yuzyy| .~>}}vv}vrv+`  wyuzyu-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuxwwrrrwv?}v-    .xyzxu}?rr}}wzwww}vv??+}yyxyy^    .+}??zwwwrrrrwww~<br></br>
-uuuuuxxzwwwwwz?zv       ?uxzwvv}wuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzzx}vz.   `^?}}vv}zuuuuuuuuuuuuuyyuw?+>vr}~.   .z?ruuuuxxxzzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuuuuuuuuuuuuuuuuuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrrwr}r}xxxu|<br></br>
`xxxxxxxxxxxxxxxrvrwzzxxzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuz}v?xuuuur>>+???v|<br></br>
`xxxxxxxxxxxxzzzzxzzzwzzxxxxxxxxxzzzzxxxuuuuuuuuuuuuuuuuuuuxxzzwww}^>+^>>^~<br></br>
`xxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxuuuuuuuuuuuuuuuuuuuxxw?}}rvvr~<br></br>
                  -uuuxxzzxxxzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzxxuuuuuuuuuuuuuuuuuuuuuyyyuuuy^<br></br>
                  -uuuxxzzxzxzzzzwwzzzzzzzxxxzzzzzzzzzzzzzzzzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzzzzwwv?>?v??wzzwwwzxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrrr}}}}}}?+>+^v^?zwwr}}rwwwwwzzwwwwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rr||>>||^>-|}rrrwwwwzzzzzzwwr?vvvrrrwwwwwwwwwwwwzzr`~??+^^^^-+}wwwwwwwwww~<br></br>
`xx+>rrwz}v}zuuuuuuuuuuxxzzzz}+?>+???>rzxxxxxxxxxxxw>?}v?}w}v}wwzxxxzzzzzx|<br></br>
-uuxxuuxxxxxxxxxxxxzzzzzzzzzz?+>>+|?}|~wzzzzzzzxxxxxxxxxxzzwwwzwwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzv-+>>+++>^?wzzzzzzzzzxxxxxxxzzwwwwwwwwwwzxzzzz|<br></br>
`zzwzzzzzzzzzzzzzzzzzzzzzzzzr?}}v}wwrwzxzzzzzzzzwzwwwwwwwwwwwwwwrrwwwxxzzz|<br></br>
                  -uuxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxzzwwwrrrwwzxzwrrrrrrrrwwwwwwzxxxxxzx|<br></br>
                  `xuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwzxzwwrrrrwwzxxxwrwrrrrrwzzwrrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxzzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzwwwwwrrrwzxxwrrwzxxxxxxzx|<br></br>
                  `zxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrwzuuxwwrrwzzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwxuuxxwwwwrrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzxzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxxxzzwwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzzxxxuuxxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rxuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzzzzw~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xuxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzw}`<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxzv?}v||v??wuxxxuuv+}wwxxzr??vwrrrwwwzzzzzzzzzzzzv.<br></br>
`zzzzzxxzzzzzzzzzzzzzxxwvvrzz.  .+v?vzzxwv}w>.>yyxzxr??rwwrrrwwwzzzzzzzzz>.<br></br>
`xxxzzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwrrrrwwwwzzzzzz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvv}wwwrrrrwwwwwzz|<br></br>
-uuuuxzzwwwwzzxxzvv}v~  >yuzyy| .~>}}vv}vrv+`  wyuzyu-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuxwwrrrwv?}v-    .xyzxu}?rr}}wzwww}vv??+}yyxyy^    .+}??zwwwrrrrwww~<br></br>
-uuuuuxxzwwwwwz?zv       ?uxzwvv}wuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzzx}vz.   `^?}}vv}zuuuuuuuuuuuuuyyuw?+>vr}~.   .z?ruuuuxxxzzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuuuuuuuuuuuuuuuuuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrwwr}r}xxxu|<br></br>
`xxxxxxxxxxxxxxxrvrwzzxxzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuz}v?xuuuur>>+???v|<br></br>
`xxxxxxxxxxxxzzzzxzzzwzzxxxxxxxxxzzzzxxxuuuuuuuuuuuuuuuuuuuxxzzwww}^>+^>>^~<br></br>
`xxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxuuuuuuuuuuuuuuuuuuuxxw?}}rvvr~<br></br>
                  -uuuxxzzxxxzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzxxuuuuuuuuuuuuuuuuuuuuuyyyuuuy^<br></br>
                  -uuuxxzzxzxzzzzwwzzzzzzzxxxzzzzzzzzzzzzzzzzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzzzzwwv?>?v??wzzwwwzxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrrr}}}}}}?+>+^v^?zwwr}}rwwwwwzzwwwwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rr||>>||^>-|}rrrwwwwzzzzzzwwr?vvvrrrwwwwwwwwwwwwzzr`~??+^^^^-+}wwwwwwwwww~<br></br>
`xx+>rrwz}v}zuuuuuuuuuuxxzzzz}+?>+???>rzxxxxxxxxxxxw>?}v?}w}v}wwzxxxzzzzzx|<br></br>
-uuxxuuxxxxxxxxxxxxzzzzzzzzzz?+>>+|?}|~wzzzzzzzxxxxxxxxxxzzwwwzwwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzv-+>>+++>^?wzzzzzzzzzxxxxxxxzzwwwwwwwwwwzxzzzz|<br></br>
`zzwzzzzzzzzzzzzzzzzzzzzzzzzr?}}v}wwrwzxzzzzzzzzwzwwwwwwwwwwwwwwrrwwwxxzzz|<br></br>
                  -uuxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxzzwwwrrrwwzxzwrrrrrrrrwwwwwwzxxxxxzx|<br></br>
                  `xuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwzuxwwrrrrwwzxxxzwwrrrrrwzzwrrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxzzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzwwwwwrrrwzxxwrrwzxxxxxxzx|<br></br>
                  `zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrwzuuxzwrrwzzzzwwrw~<br></br>
`zxxxxxzzzxxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwxuuxxwwwwrrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzxzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzxxxxuuxxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rxuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzzzzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xxxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzw}`<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxzv?}v||v??wuxxxuuv+}wwxxzr??vwrrrwwwzzzzzzzzzzzzv.<br></br>
`zzzzzxxzzzzzzzzzzzzzxxwvvrzz.  .+v?vzzxwv}w>.>yyxzxr??rwwrrrwwwzzzzzzzzz>.<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwrrrrwwwwzzzzzz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvv}wwwrrrrwwwwwzz|<br></br>
-uuuuxzzwwwwzzxxzvv}v~  >yuzyy| .~>}}vv}vrv+`  wyuzyy-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuxzwrrrwv?}v-    .xyzxu}?rr}}wzwww}vvv?+}yyxyy^    .+}??zwwwrrrrwww~<br></br>
-uuuuuxxzwwwwwz?zv       ?uxzwvv}wuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzzx}vz.   `^?}}vv}zuuuuuuuuuuuuuyyuw?+>vr}~.   .z?ruuuuxxxzzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuuuuuuuuuuuuuuuuuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrwwr}r}xxxu|<br></br>
`xxxxxxxxxxxxxxxrvrwzzxxzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuz}v?xuuuur>>+???v|<br></br>
`xxxxxxxxxxxxzzzzxzzzwzzxxxxxxxxxxzzzxxxuuuuuuuuuuuuuuuuuuuxxzzwww}^>+^>>^~<br></br>
`xxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxuuuuuuuuuuuuuuuuuuuxxw?}}rvvr~<br></br>
                  -uuuxxzzxxxzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzxxuuuuuuuuuuuuuuuuuuuuuyyyuyuy^<br></br>
                  -uuuxxzzxzxzzzzwwzzzzzzzxxxzzzzzzzzzzzzzzzzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzzzzwwv?>?v??wzzwwwzxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrr}}}}}}?+>+^v^?zwwr}}rwwwwwzzwwwwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rw>^>>|-+v^.vwwwwwzzzzxxzzzzzrrwwwwwwwwwwwwwwwwzzzz>->?>|~^?|`vzzwwwwwwww~<br></br>
`zx^|v?v}??>vxuuuuuuuuuxxzzzzz}?vvvr}}wzxxxxxxxxxxxz^+??+?}???}wzxxxzzzzzx|<br></br>
-uuwwxuxxxxxxxxxxxxzzzzzzzzzzzv+>++>>?|vzzzzzzzxxxxxzzxzzzzzwwzwwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzr++>>+~?v~|wxzzzzzzzxxxxxxxzzwwwwwwwwwwzxxzzz|<br></br>
`zzwzzzzzzzzzzzzzzzzzzzzzzzzz?->?>>?>>>vzzzzzzzzwwwwwwwwwwwwwwwwrrwwwxxzzz|<br></br>
-uuxxzzzzzzzzzzzzzzzzzzzzzzzzw}wwrwzzzzzwwwrrrwwzxzwrrrrrrrrwwwwwwzzxxxxxx|<br></br>
                  `xuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwzuxwwrrrrwwzxxxzrrrrrrrwzzwrrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxzzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzzwwwwrrrwzxxwrrwzxxxxxxzx|<br></br>
                  `zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrrzuuxzwrrwwzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwzuuxxwwwwrrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzxzzxzzzzzzzzzzzzzzzzzzzzzzzzzxzxxxxxxxxzzwwwwwwwrrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzzxxxuuuxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwrrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}}xuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzxzzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xuxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzwv.<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxwv?}v||v??ruxxxuuv+}wwxxzr??vwrrrwwwzzzzzzzzzzzz>.<br></br>
`zzzzzxxzzzzzzzzzzzzzxxwvvrzz.  .^v?vwzzwv}w>.>yyxzxr??rwwrrrwwwwzzzzzzzv|`<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwrrrrwwwwzzzzzz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvv}wwwrrrrwwwwwzz|<br></br>
-uuuuxzzwwwwzzxxzvv}v~  >yuzyy| .~>}}vv}vrv+`  wyuzyy-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuxzwrrrwv?}v-    .xyzxu}?rrr}wzwww}vv??+}yyxyy^    .+}??zwwwrrrrwww~<br></br>
-uuuuuxxzwwwwwz?zv       ?uxzrvv}zuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vvrzuuuuuuuuuuuuuyyuzv>>?rv~.   .z?ruuuuxxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~?vvvvvrxuuuuuuuuuuuuuuuuuuuuuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrwrvvvwxxuu|<br></br>
`xxxxxxxxxxxxxxxrvrwzzxxzzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuz}v?xuuux?>+???>z^<br></br>
`xxxxxxxxxxxxxzzzxzzzwzzxxxxxxxxxxzxzxxxuuuuuuuuuuuuuuuuuuuxxzzwww>+>+^?~}|<br></br>
`xxxxzzxzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxxuuuuuuuuuuuuuuuuuuuxvvrwr}rz~<br></br>
                  -uuuxxxzxxxzzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzzxuuuuuuuuuuuuuuuuuuuuyyyyuyuu^<br></br>
-uuuxxzzxzxzzzwr}zzzzzzzxxxzzzzzzzzzzzzzzzzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzxzzzwwv>+>v??}zzzwwzxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrr}}}}}}}v+>+|v++wwwr}}}rwwwwzzwwwwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rw?+>>+|^r?.?wwwwzzzzxxxzzzzzwrwwwwwwwwwwwwwwwwwzzz}|>>>+|+}?.>wzwwwwwwww~<br></br>
`xx+-?>+?+>^+wuuuuuuuuuxxzzzzzzwwzzzzxxxxxxxxxxxxxxx+`>>+^?++^>rzxxxzzzzzx|<br></br>
-uuv?wzzxzwzxxxxxxxzzzzzzzzzzzzv>???vvv}zzzzzzzxxxxz}}wwrrzwrwzzwwzzxzzzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz?+>+++??-?zzzzzzzzxxxxxxxxzwwwwwwwwwwzxxzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzv|>>>+~??-+wzzzzzzwwwwwwwwwwwwwwwwwrwwwzxzzz|<br></br>
-uuxxzzzzzzzzzzzzzzzzzzzzzzzzz?^??>?v??vrwwrrrwwzxzwrrrrrrrrwwwwwwzzxxxxxx|<br></br>
                  `xuuuuuxxzzzzzzzzzzzzzzzzzzwwwrrwwwzuxzwwrrrwwzxxxzwrrrrrrwzzwwrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxxzzzzzzzzzzzzzzzzzzwwwwwwzzwrrrrrwwzxxzzwwwwrrrrzxxzwrwzxxxxxxxx|<br></br>
                  `zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrrwxuxzwrrwwzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrrrrwrwwwwwwwrrwwzuuxxwwwwwrrwzzwr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzzxzxzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxxxxxxxzzwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzxxxxuuuxuuxzxxxxxxxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwrrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuuxxxzzwwwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}}xuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwzzzzzzzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xxxuuuuxuw>>wzv?vrwrrrrwwzzzzzzzzzzxxzr?`<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzxwv?}v||v??wuxxxuuv+}wwxxzr??vwrrrrwwzzzzzzzzzzx}^.<br></br>
`zzzzzxxzzzzzzzzzzzzzzzwvvrzz.  .^v?vwzxwv}w>.>yyxzxr??rwwrrrwwwzzzzzzzz>+`<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?}vvrzwwrrrwwwwzzzzwz|<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..>r}vrwwwrrrrwwwwwzz|<br></br>
-uuuuxxzwwwwzzzxzvv}?~  >yuzyy| .~>}}vv}vrv+`  wyuzyy-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuuzwrrrwv?}v-    .xyzxu}?rrr}wzwww}vv??+}yyxyy^    .+v??zwwwrrrrwww~<br></br>
-uuuuuuxzwwwzwz?zv       ?uxzrvv}zuyyuuuuuuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vvrzuuuuuuuuuuuuuyyuzv>>?rv~.   .z?ruuuuxxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuuuuuuuuuuuuuuuyuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxxuuuuuuuuuuuuuuuuuuuuuxwv??vwv?rrwvvvvwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvwwwzxxzzxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuz}v?xuuuv++>??>vu|<br></br>
`xxxxxxxxxxxxxzzzxzzzwzzxxxxxxxxxxxxzxxxxuuuuuuuuuuuuuuuuuuxxzzzz?^>+^>|?z|<br></br>
                  `xxxxzzxzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxzxxxxxxuuuuuuuuuuuuuuuuuuurvwwwrrwz~<br></br>
                  `uuuxxxzxxxxzzzzzzzzzzzzzzzzzzzxxxxxxxxxzwwzzxuuuuuuuuuuuuuuuuuuuyyyuuyuuu^<br></br>
-uuuxxzzxzxzzwr}vwwwzzzzxxxzzzzzzzzzzzzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
                  `xxxxxzzxzzwwr+++????zzzwwzzxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrr}}}}}}v}v^>+|?+^wwwr}}}rrwwwwwzzwwwzzxxxxxxxxxxxxxxxxxzzzzzzzzxxxxxxx|<br></br>


            </div>,

            <div>

`rr?+>+>+^}v`?rrrwwwwzzzzzwwwwrrrrrrrrrrrwrrrrrrwwwww>+++>^+rr`|rwwrrrrrrr~<br></br>
`xx?~>>^^^?>`vuuuuuuuuuuxxzzzzzzzzxxzxxzxzxxxxxxxxxu?.>>>^+|>^-?zxxxzzzzzx|<br></br>
-uu?^vv}z}vvrxxxxxxxzzzzzzzzzzzz}}wrwzzzxzzzzzzxxxxxv?vv?vrrvvwzwwzzxzzzzz|<br></br>
`zzwwzzzzzzzzzzzzzzzzzzzzzzzzzzz?>?+>??>?wxzzzzzzxxxxzxxxzzwwzzwwwwwzxxzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzr>>>++^vv`>zzzzzzwwwwwwwwwwwwwwwwwrwwwzxzzz|<br></br>
-uuxxzzzzzzzzzzzzzzzzzzzzzzzzzz>~>>++^>>~?wrrrwwwxzwrrrrrrrrwwwwwwzzxxxxxx|<br></br>
`xuuuuuxxzzzzzzzzzzzzzzzzzzwwww??vv?rw}vrwrrwwzxxxzwrrrrrrwzzwwrwwwzzzzzzz|<br></br>
                  `zzzzzxxxxxzzzzzzzzzzzzzzzzzzwwwwwwzzwwwrrrwwzxxxzwwwwrrrrzxxzwrwzxxxxxxzx|<br></br>
                  `zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrwwwwwwwwwwwrrrrwxuxzwrrwwzzzwwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzwwrrrrrrrrrr}rrrrwwwwwwwrwwzuuxxwwwwwrrwzzzr}-<br></br>
                  `zxxxxxxxxxxxxzwwwzzxzxzzzzzzzzwwwwzzzzzzzzzzzzzxxxxxxxxxxxzzwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxzzzxxxxuuuxuuxwxxxxxxxxzzzzzzwzzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxuxxxxzzwwwwwwwwwwwwwwwwwwwrrrrrrrwwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rxuuuuxxzzwwww}???rwwwrrrrwwwwwwwwwwzzzxzzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}xxxuuuxzuw>>rzv?vrwrrrrwwzzzzzzzzzzxxzv?`<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzxwv?}v||v??rzxxuxzv+}wwxxzr??}wrrrrwwzzzzzzzzzzz>+.<br></br>
`zzzzzxxzzzzzzzzzzzzzzzwvvrzz.  .+v??wzxw?}w>.>yyxzxr??rwwrrrwwwzzzzzzzv|>`<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwwrrrwwwwzzzwwz~<br></br>
`zzzzzzzxxxxzzzzxxz}v}v^wyxxyw.    ~uuuuxu.   `uyuxyx..?rvvrwwwrrrrrwwwwzz|<br></br>
-uuuuxxzwwwwzzzxzvv}v~  >yuzyy| .~>}vvv}vrv+`  wyuzyy-  .>}v?}wwwrrrrwwwww~<br></br>
-uuuuuyuuzwrrrwv?}v-    .xyzxu}?rr}}rwwwrvvv??+}yyxyy^    .+v??zwwwrrrrwww~<br></br>
-uuuuuuxzwwwwwz?zv       ?uxzrvv}zuuuuuuuxuxwv??vwzuy?      ?z?uxxzzwwwrrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vv}zuuuxuuuuuuuuuyyuzv+>?r}~.   .z?ruuuuxxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuuxxuuuuuuuuxuuuyuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxuxxuuuuuuuuuuxuuuuuuuuxwv?vvwv?rr}v??rwzuuu|<br></br>
`xxxxxxxxxxxxxxxrvwwwzxxzzxxxxxzwxxxuuuuuuuuuxuuuuuuuuuuuz}v?xuuz>+>>?>>xu|<br></br>
`xxxxxxxxxxxxxzzzxzzzwzzxxxxxxzwzxxxzxxxxuuuuuxxuuuuuuuuuuuxxzzz}|>>+>++wz|<br></br>
`xxxxzzxzzzzzzxxxxxxxzzxxxxxxxzxxxxxxxxzxxxxxxuuuuuuuuuuuuuuuuuuz}wzzwrwwz~<br></br>
                  `uuuxxxzxxxxzzzzzzzxzzzzzzzzzwzxxxxxxxxxxwwzzxuuuuuuuuuxuuuuuuuuuyyyuuuuuu^<br></br>
                  -uuuxxzzxzxzzwvv?rrrzzzzzzzzzzzzzzzzzzzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzzzzwww>++???>zzzwwzzxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrrr}}}}}}|>+^>+|wwwr}}rrwwwwzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrv+>+>?^?>-?rrrrwwwzzzzzwwwwrrrrrrrrrrrwrrrrrrwwwwwv+>+++^v}~|}wwrrrrrrr~<br></br>
`xxv^>?+||vv.>uuuuuuuuuuxxzzzzzzzzzzzzzzzxxxxxxxxxxxw~+>?+^|??`|rxxxzzzzzx|<br></br>
-uu?`???v?>+?wxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxv~??>>vv?>?rwwzzxzzzzz|<br></br>
`zzr}wzzzzzzzzzzzzzzzzzzzzzzzzzzwvv}vrwrwzxzzzzzzxxxzwzxzzwzzzzzwwwwzzxzzz|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzw>>>+>>?+>rzzzzzwwwwwwwwwwwwwwwwwrrwwzxzzz|<br></br>
-uuuxzzzzzzzzzzzzzzzzzzzzzzzzzzzv+>>>^^vv`>rwrwwwxzwwrrrrrrrwwwwwwzzxxxxzx|<br></br>
`xuuuuuxxzzzzzzzzzzzzzzzzzzwwwwr||?>+>+>++vrwwzxxxzwrrrrrrwzzwwrwwwzzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzzzzzzzzzzzzzwwrvvr}?}wrv?wwwzxxxzwwwwrrrrzxxzwrwzxxxxxxxx|<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwz?v}}v?wwwwwwwwwwwwrrrwxuuzwrrwwzzzzwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzzwwr}vv???v>?vrrrwrwwwwwrwwzuuxxzwwwwrrwzzzr}-<br></br>
                  `zxxxxxxxxxxxxzwwwwzxzxxzzzzzzz??v?vrzxxzr??vvvxxxxxxxxxxxxzzwwwwwwwrrwzzz~<br></br>
                  `zzzzxxxxxxxxxxxxxzzxxxxuuuxuux^zuuuxxxxxxxxxz?zzzzzzzzwwwwwwwwwwwwwwrrrwz|<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxur?wxxzwwwwwwwrvvwwwwwwwwwwrrrrrrrwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rzwuzwuxzwvwwwv???rwwwrrrrwwwwwwwwwzzzzzxzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwrrrrwwzzzzzzzzzzxxw?v`<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzzzzzzx}++.<br></br>
`xzzzzzxzzzzzzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr??rwwrrrwwwzzzzzzz++>.<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?rvvrzwwrrrwwwwzzzrwz~<br></br>
`zzzzzzzxxxxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..?rvvrzwwwrrrrwwwzzz|<br></br>
-uuuuxxzwwwwzzzxzvv}v~  >yuzyy| .~>}v?}}vrv+`  wyuzyy-  .>}v?}wwwrrrrwwwww~<br></br>
-uyuuuyuuzwrrrwv?}v-    .xyzxu}?rrr?|vzw?|vv??+}yyxyy^    .+}??zzwwrrrrwww~<br></br>
-uuuuuuxzwwwwwz?zv       ?uxzrvv}zzvvzyyz>}uwv??vwzuy?      ?z?uxxxzwwwwrw~<br></br>
`zzzzzzzzzzzxxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>vr}~.   .z?ruuuuuxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuy>?yuuuuuyx~}yuyuxwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxu?|uuuuuuuuuz~vyuuuuuuxwv??vwv?rrv??v}}zuuu|<br></br>
`xxxxxxxxxxxxxxxrvwwwzxxzzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v?xux?++>>?|}xu|<br></br>
`xxxxxxxxxxxxxzzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuuxxzzw++>>>>^vwz|<br></br>
`xxxxxzxzzxxzzxxxxxxxw}xxxxxu}~wuxxxxxxzxxxxxuuv?zuuuuuuvxuuuuuuwwzzzwwwwz~<br></br>
`uuuxxxzxxxxzzzzzzzxxx??zxxx}|rxxxxxxxxxxwwzzxuuxv}xuuw?vuuuuuuuyyuuuuuuuu^<br></br>
-uuuxxxzxzxxzzvv?}r}wzzv??vv>wxzzzzzzzzzzzzzxuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzxzzwwz>++>>?|wzzwrrwxxxxxxxxxxxxxxxuuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrrr}}}}}}|>+++>^rwww}}rrrwwwzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrv+>++v>++|vrrrwwwwzzzzzwwwwrrrwwwwwwwwwwwwwwwwwzzzr+>>+++>?|^}zwwwwwwww~<br></br>
`xx}+>>>^|vr`^xuuuuuuuuuxxzzzzzzzzzzzzzzxxxxxxxxxxxxx?+>>>^~vr^`vxxxzzzzzx|<br></br>
-uuv`>>+>+++~vxxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxx}.^>>^>>+^^?wzzzxxzzzz|<br></br>
`zzv?}wwzzrrzzzzzzzzzzzzzzzzzzzzzzwzzzzzxxxzzzzzzzxxwv}w}}wzwwzzwwwwzzxzzz|<br></br>
`zzzzzzzxzxxzzzzzzzzzzzzzzzzzzzzzw????v}vvwzzzzzwwwzzwwwwwwwwwwwwrrwwzxzzz|<br></br>
-uuuxzzzzzzzzzzzzzzzzzzzzzzzzzzzz}>>>++>?>|vwrwwwzzzwrrrrrrrwwwwwwzzxxxxzx|<br></br>
`xuuuuuxxzzzzzzzzzzzzzzzzzzzwwwww>^>>>^^v?`?wwzxxxzwrrrrrrwzzwwrrwwzzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzzzzzzzzzzzzzzww}|+?>>??>+?rwwxxxzwwwwrrrrwxxzwrwzxxxxxxxx|<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzxzzwrrrw?v}}v?zwwwwwwwwwwwrrrwxuuzwwrwwzzzzwrw~<br></br>
`zxxxxxzzzzxxxzzzzzzzzzzzzzzzzwwr}vv???v>?v}rrwwwwwwwrwwzxuxxzwwwwrrwzzzr}-<br></br>
                  `zxxxxxxxxxxxxzzwwwzxzxxzzzzzzz??v?vrzxxzr??vvvxxxxxxxxxxxxzzwwwwwwwrrwzzz~<br></br>
                  `zzzzzxxxxxxxxxxxzzzxxxxuuuxuux^zuuuxxxxxxxxxz?zzzzzzzzwwwwwwwwwwwwwwrrrwz~<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxur?wxxzwwwwwwwrvvwwwwwwwwwwrrrrrrrwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rzwxzwuxzwvwwwv???rwwwrrrrwwwwwwwwwwzzzzxzz~<br></br>
`zzzzzzzzzzzxxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwrrrrwwzzzzzzzzzzxx}vv`<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzzzzzzz?++.<br></br>
`xzzzzzxzzzzzzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr??rwwrrrwwwwzzzzzv|>+.<br></br>
`xxxxzzxxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?}vvwzwwrrrrwwwzzrrww~<br></br>
`xzzzzzzxxxxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvrzwwwrrrrwwwzzz|<br></br>
-uuuuxxzwwwwzzzxzvv}v~  >yuzyy| .~>}v?}}?rv+`  wyuzyy-  .>}v?rzwwrrrrwwwww~<br></br>
-uyuuuyyuzwwrrwv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vzzwwrrrrwww~<br></br>
-uuuuuuxxzwwwwz?zv       ?uxzrvv}zzvvzyyz>}uwv??vwzuy?      ?z?uxxxzwwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vvrzuyu+wzxuuxw?vyyuzv>>?r}~.   .z?ruuuuxxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrxuuuuy>?yuuuuuyx~}yuyyuwv??vvv>-^u>wwzzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rwzxxxxxxu?|uuuuuuuuuz~vyuuuuuuxwv??vwv?rv???}vrxuuu|<br></br>
`xxxxxxxxxxxxxxxrvrwwzxxzzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v?xuv+>>+?^?wxu|<br></br>
`xxxxxxxxxxxxxzzzxzzzwzzxxxxxxv-wxxzzxxxxuuuux>?uuuuuuuuuuuxxzz?^?>>>+vrwz|<br></br>
`xxxxxzxzzzxzzxxxxxxxw}xxxxxu}~wuxxxxxxzzxxxxxuv?zuuuuuuvxuuuuuzwxxxzzzwwz~<br></br>
`xuuxxxzxxxxzzzzzzzxxx??zxxx}|rxxxxxxxxxxzwzzxuuxv}xuuw?vuuuuuuuyuuuuuuuuu^<br></br>
-uuuuxxzxxxxzz??>v}vrzzv??vv>wxzzzzzzzzzzzzzxuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxzzxzzwwz?++>+?~}zzwrrwxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrrr}}}}}}^+>>>>+rwww}}rrrwwwwzzzzwwzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwv^>+^??>>?rwwwwzzzzzxxxzzzzwrwwwwwwwwwwwwwwwwwzzzzw>+>+^?+>^>rzzwwwwwww~<br></br>
`zxr+>+>?^?}~+xuuuuuuuuuxxzzzzzzzzzzzzzzxxxxxxxxxxxxu}+>>+>|?w?.?zxxzzzzzz|<br></br>
-uu}->>+||>?-^wxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxz~~>>+^^^>~|}zzzxxzzzz|<br></br>
`zzv|?vvrrv?vwzzzzzzzzzzzzzzzzzzzzzxzxxzzzxzzzzzzzxxr>?v??vr}v}wwwwwzzxzzz|<br></br>
`zzwwwzxxxxxxzzzzzzzzzzzzzzzzzzzzzw}wwwzzzzzzzzzzwzwwrrwwwwwwwwwwrwwwzxzzz|<br></br>
-uuuxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzr>??+????vwwwwwzxzwrrrrrrrwwwwwwzzxxxxxx|<br></br>
`xuuuuuxxzzzzzzzzzzzzzzzzzzzwwwwwwv>>++^>v>-vwzxxxxwrrrrrrrzzwwrrwwzzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzzzzzzzzzzzzzzwww}|^>>+^^?+-vzxxxzwwwwrrrrwxxzwrrzxxxxxxxx|<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzzxzww}+??>>v?>v}wwwwwwwwwwrrrwxuuxwwrwwzzzzwrw~<br></br>
`zxxxxxxzzxxxxzzzzzzzzzzzzzzzzwwr}vv???v>?}rrrwwwwwwwrwwzxuxxzwwwwrrwzzzw}-<br></br>
                  `zxxxxxxxxxxxxzzwwwzxzxxzzzzzzz??v?vrzxxzr??vvvxxxxxxxxxxxxxzwwwwwwwrrwwzz~<br></br>
                  `zzzzzxxxxxxxxxxxxzzxxxxuuuuuuu^zuuuxxxxzxxxxz?zzzzzzzzwwwwwwwwwwwwwwrrrwz~<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxur?wxxzwwwwwwwwvvwwwwwwwwwwrrrrrrrwwwwwwwzzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rzwxzwuxxwvwwwv???rwwwrrrrwwwwwwwwwwzzzxxzz~<br></br>
`zzzzzzzzzzzzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>rzv?vrwrrrrwwzzzzzzzzzzxzv}v-<br></br>
`zxzzzzzzzzzzzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzzzzzx}+>+.<br></br>
`xxzzzzxzzzzzzzzzzzzzzzwvvrzz.  .+v?`rzx>~}w>.>yyxzxr??rwwrrrwwwzzzzzz++>+.<br></br>
`xxxxzzzxxzzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwzwwrrrrwwwzwvrrw|<br></br>
`xxzzzzzzxxxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvrzwwwrrrrwwzzzz|<br></br>
-uuuuxxzzwwwzzzxzvv}v~  >yuzyy| .~>}v?}}?rv+`  wyuzyu-  .>}vvrzwwrrrrrwwww~<br></br>
-uyuuuyyuzwwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vzzwwrrrrwww~<br></br>
-uuuuuuxxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzxzzxxx}vz.   `^?}}vvrzuyu+wzxuuxw?vyyuzv>>vr}~.   .z?ruuuuuxxxxzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuzv??vvv>-^u>wwwzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}r}v}rwzxxxxxxu?|uuuuuuuuuz~vyuuuuuuxw}??vwv?}???vvvwxuuu|<br></br>
`xxxxxxxxxxxxxxxrvwzzzxxxzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v?xw+>>+?>^}wxu|<br></br>
`xxxxxxxxxxxxxzzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuuxxx}|???>>?}rwz|<br></br>
`xxxxxzxzzxxzzxxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuuuzxuxxzzwwwz~<br></br>
`xuuxxxzxxxxxzwzwwzxxx??zxxx}^rxxxxxxxxxxzwwzxuuxv}xuuw?vyuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzxxxxzz??>?v?vwzv??vv>wxzzzzzzzzzzzzzxuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxzxzzwwz?+>+^v~vxzzrrwxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrrr}}}}}}}>+???>>rwrw}}}rwrwwwwwzr}}zzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxx|<br></br>


            </div>,

            <div>

`rwv>?+|?}v?vrrwwwzzzzzxxxzzzzwrrwwwwwwwwwwwwwwwwzzzzz?+?>^v>>+?wzzwwwwwww~<br></br>
`zxr+>++?>>?|>zuuuuuuuuuxxzzzzzzzzzzzzzzxxxxxxxxxxxxuz>+>+++>}?`?zxxzzzzzx|<br></br>
-uuw^>>>|~?r|`wxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxx?~>>>^|+v>.?zzzxxzzzz|<br></br>
`zzv`>??v?>+>vzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxw|^?>+>v?>>vwwwwwzxzzz|<br></br>
`xzwvrzzxxzzzxzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzzzzwwzwr}wrrrwwwwwwrrwwzxzzz|<br></br>
-uuuxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzrvr}}wrrrwrwwwzxzwrrrrrrrwwwwwwzzxxxxxx|<br></br>
`xuuuuuxxzzzzzzzzzzzzzzzzzzzwwwwwwwv+>>+?>>+?wzxxxxwrrrrrrrwzwwrrwwzzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzzzzzzzzzzzzzzwwwww?+>>>^>}>`vxxxzwwwwrrrrwxuxwrrwxxxxxxxx|<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzzxzzwwv-+>>++^>^+}wwwwwwwwrrrwzuuxwwrwwzzzzwrw~<br></br>
`zxxxxxxxxxxxxzzzzzzzzzzzzzzzzwwr}?++???>>?v}rwwwwwwwrwwwxuxxzwwwwrrwwzzw}-<br></br>
                  `zxxxxxxxxxxxxxzwwwzxzxxzzzzzzz??v?vrzzzxrv?vvvxxxxxxxxxxxxzzwwwwwwwrrwwzz~<br></br>
                  `zzzzzxxxxxxxxxxxxzzxzxxxuuxuuu^wuuuxxxxzxxxxz?zzzzzzzzwwwwwwwwwwwwwwrrrwz~<br></br>
                  `zzzzzzxxxxxxxxxxxxxxxxxxxxxxxur?wxxzwwwwwwwwvvwwwwwwwwwwrrrrrrrwwwwwwwwzz|<br></br>
`zzzzzzzxxxxxxxxxxxxxxxxxxxxxz}rzwxzwuxxwvwwwv???rwwwwrrrwwwwwwwwwwzzzxxzz~<br></br>
`zzzzzzzzzzzzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwrrrrwwzzzzzzzzzzx}}v}-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzzzzzz?+++.<br></br>
`xxzzzzxzzzzzzzzzzzzzzzwvvrzz.  .+v?`rzx>~}w>.>yyxzxr??rwwrrrrwwwzzzzv|>++.<br></br>
`xxxxzzzxxzzzzzzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwzwwrrrrwwwz}vr}z~<br></br>
`xxzzzzzzxxxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvrzwwwrrrrwwzzzz|<br></br>
-uuuuuxzzwwwwzzxzvv}v~  >yuzyy| .~>}v?}}?rv+`  wyuzyu-  .>}vvrzwwrrrrrwwww~<br></br>
-uyuuuuyuzwwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzwwrrrrrww~<br></br>
-uuuuuuxxzwwwwz?zv       ?uxzwvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzxzzzxx}vz.   `^?}}vvrzuyu+wzxuuxw?vyyuzv>>vrv~.   .z?wuuuuuxxxzzx|<br></br>
`zzzzzxxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}??vvv>-^u>wwwzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrzzxxxxxxu?|uuuuuuuuuz~vyuuuuyuxw}v?vwv???>vv?}zxuuu|<br></br>
`xxxxxxxxxxxxxxxrvwzzzxxxzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v?z>+>+>?~vrwxu|<br></br>
`xxxxxxxxxxxxxzzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuuuxz+????>?}rrwz|<br></br>
`xxxxxzxzzzxzzxxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuuxxuuxxxzwwwz~<br></br>
`xuuxxxxxxxxxzwwrrzzxx??zxxx}|rxxxxxxxxxxzwwzxuuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzxxxxzxv>+?v??rzv??vv>wxzzzzzzzzzzzzzxuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxzxzzzwzv^>+^?~?zzzrrwxxxxxxxxxxxxxxxuuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrr}}}}}}v}?+??v??rwrw}}}rrwwwwwzwvv}rzzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrv?v?+?r}}rrrrrrwwwwzzzzzwwwwrrrrrrrrrrrrrrrrrrwwwww?>??>vv??}rwwrrrrrrr~<br></br>
`xxr+>>^?v>?+vxuuuuuuuuuxxxzzzzzzzzzzzzzzzzxxxxxxxxxxx?+>+^>+?>|vzxxxzzzzx|<br></br>
-uuz>>>>+|?w+`}xxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxr^>>>>|+rv.^wzzxxzzzz|<br></br>
`zz}`+>+>>^+~>wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxx^`>>+^?++|^vwwwwzxzzz|<br></br>
`xzr>vwwzzwrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwzwvv}vvvrwrrwwwrwwzxzzz|<br></br>
                  -uuuxzzzzzzxzzzzzzzzzzzzzzzzzzzzzzzzwzzzwwwwwwwwwzxzwrrrrrrrwwwwwwzzxxxxxx|<br></br>
`xuuuuuxxxzzzzzzzzzzzzzzzzzzwwwwwwwwv?v??}vv}wzzxxxwrrwrrrrwzwwrrwwwzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzzzzzzzzzzzzzzwwwwww?+>++>>?|>zxxzwwwwwrrrwxuxwrrwxxxxxxxx|<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzzxzzwww}+>>>+|?}+`}wwwwwwwrrrwzuuxwwrrwzzzzwrw~<br></br>
`zxxxxxxzzxxxxxzzzzzzzzzzzzzzzwwr}v>->>+++^+^?rwwwwwwrrwwxuuxzwwwwrrrwzzw}-<br></br>
`zxxxxxxxxxxxxxzwwwzxzxxzzzzzzz??v?}}wwrrrv?vvvxxxxxxxxxxxxzzwwwwwwwrrrwzz~<br></br>
                  `zzzzzxxxxxxxxxxxxzzxxxxuuuxuuu^wuuxxxxxxxxxxz?zzzzzzzzzwwwwwwwwwwwwwrrrwz~<br></br>
                  `zzzzzzzxxxxxxxxxxxxxxxxxxxxxxur?wxxzwwwwwwwwvvwwwwwwwwwrrrrrrrrwwwwwwwwzz|<br></br>
`zzzzzzzzxxxxxxxxxxxxxxxxxxxxz}rzwxzwuxxwvwwwv???rwwwwrrrwwwwwwwwwwzzzxxzz~<br></br>
`zzzzzzzzzzzzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>rzv?vrwrrrrwwzzzzzzzzzzw}r}}-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzzzzz}+>+>`<br></br>
`xxzzzzxxzzzzzzzzzzzzzzwvvrzz.  .+v?`rzx>~}w>.>yyxzxr?vrwwrrrrwwwzzzz++>++`<br></br>
`xxxxxzzxxxzzzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwzwwrrrrwwwwv}vrz~<br></br>
`xxzzzzzzxxxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvrzwwwrrrrwzzzzz|<br></br>
-uuuuuxzzwwwwzzxzvv}v~  >yuzyy| .~>}v?}}?rv+`  wyuzyu-  .>}vvrzwwwrrrrwwww~<br></br>
-uuuuuuyuxwwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzwwrrrrwww~<br></br>
-uuuuuuxxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzxzzxxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>vrv~.   .z?wuuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}??vvv>-^u?wrwzxuuuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrwzxxxxxxu?|uuuuuuuuuz~vyuuuuyuxw}v?vw}>?>?v??rzxuuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzxxxzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v??+>+^v|?rrwxu|<br></br>
`xxxxxxxxxxxxxzzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuuux?>?????vrrrwz|<br></br>
-xxxxxzzzzzxxzxxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuuuuuuxxzzwwwz~<br></br>
`xuuuxxzxxxxxzwr}}zzzx??zxxx}|rxxxxxxxxxxzwwzxxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzxxxxzx}>+>??>vzv??vv>wxzzzzzzzzzzzzzxuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxzxzzzwzv^>+^?^>zzzrrwxxxxxxxxxxxxxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrrr}}}}}}?>?v}vvwwwwr}}rrwwwzzzv??}vwxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rr}v}v?vrrrrrrrrrwwwwzzzzzzwwwrrrrrrrrwwwwwrrrrrwwwww}?vv?v}}}rrwwrrrrrrr~<br></br>
`xxw+?>^?}???rxuuuuuuuuuxxzrzzzzzzzzzzzzzzxxxxxxxxxxwzv^>>^??>>>}zxxxzzzzx|<br></br>
-uuz>+>>?+>}>`}xxxxxzzzzzzzrv}wzzzzzzzzzzzzzzzzxxzrvwz?+>+>+^}}-~rzzxxzzzz|<br></br>
`zzw~^>>||^?^`vzzzzzzzzzzzzzwvv}wzxxxxxxzzzxxzwrvv}zxv`>>>^^|>>`>wwwwzxzzz|<br></br>
`xxr~>vv}wv??rzzzzzzzzzzzzzzzz}vvv}wwwwrwzzw}vvvvrzzz?+????}}vv}rwwwwzxxzz|<br></br>
-uuxzzzzzzzxxxzzzzzzzzzzzzzzzzzrvvvvvvvvvvv???v}wzxzwrrrwrrrwwzzwwzzxxxxxx|<br></br>
`xxuuuuxxxzzzzzzzzzrzrzzzzzzwwwww}vvvvvvvvv?vrzxxxxzwrwrrvrvwzwwrwwwzzzzzz|<br></br>
`zzzzzxxxxxzzzzzzwzvrvwzzzzzzzwwwwwr??>?>>v??vwxxzzwwwwr}?v}rzzrrwzxxxxxxx|<br></br>
`zxxxzzzzzzzzzzzzw}}vrwzzzzzzxzzwwww?>+>+++?v~+wwwwwwwrr}?vw}xzwrrwzzzzwrr~<br></br>
`zxxxxxxxxxxxxxzzz}rrzzzzzzzzzwwr}vv>~>>>+~>?|~}wwwwwrrwwwuxzxwwwwrrwwzzw}-<br></br>
`zxxxxxxxxxwxxxzwwwzxzxxxzzzzzz??v?v+|??>?v????zxxxxxxxxxuxxzwwwwww}wrrwzz~<br></br>
`zzzzzxxxz}zxxxxxxzzxzxxuuuxuuu^wuuuzwxxzzxxxx?zzzzzzzzzwwwwwwwwwwwvvrrrwz~<br></br>
`zzzzzzzz}zxxxxxxxxxxxxxxxxxxxur?wxxzzwwwwwwrvvwwwwwwwwwwrrrrrwwwwwwvvwwzz|<br></br>
`zzzzzzzzwzxxxxxxxxxxxxxxxxxxz}rzwxzwuxxwvwwwv???rwwwwrrrrwwwwwwwwwrvwxxzz~<br></br>
`zzzzzzzzzwwzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwrrrrwwzzzzzzzr}zrrrr}-<br></br>
`zzzzzzzzzzrwzzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzxrwz?>+>?`<br></br>
`xxzzzzxxzzvzzzzzzzzzzzwvvrzz.  .+v?`rzx>~}w>.>yyxzxr?vrwwrrrrwwwzzvv|>+^>`<br></br>
`xxxxxzzzxwvzzzzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwzzwrrrrwwwvv??wr~<br></br>
`xxzzzzz}wrxxzzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwzwwwrrrrwrvwzz|<br></br>
-uuuuuxxzrwwwzzxzvv}v~  >yuzyy| .~>}v?v}?rv+`  wyuzyu-  .>}vvrzwwrrrrwwwww~<br></br>
-uuuuuuyuxwwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzwwwrrrrww~<br></br>
-uuuuuuxxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzxzzxxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>?r}~.   .z?ruuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}??vvv>-^x?rrwzxxxuuxxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrwzxxxxxxu?|uuuuuuuuuz~vyuuuuyuxw}?vvw}>>>???vwwxuuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzxxxzxxxxx?-zxxuuuuuuuyz~vyuuuuuuuuuz}v?^>+^?+^rrrwxu|<br></br>
`xxxxxxxxxxxxxzzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuuu}+vvv??vrrrrwz|<br></br>
`xxxxxzzzzzxxzxxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuuuuuuuxzzzwwz~<br></br>
`xuuuxxxxxxxxzw}vvwwwx??zxxx}|rxxxxxxxxxxzwwzxxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzzxxxzxr+++??>?zv??vv>wxzzzzzzzzzzzzzzuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxzxzzzww}|>++?+>zzzrrwxxxxxxxxzzzxxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrwrrrrrr}}}}v>v}rv}zwwwr}}rrwwwwzw?>???wxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrwr}rrrrrrrrrrwwwwzzzzwzwwwrrrwrwwwwwwwwwwwwwwzzzwrvrwrrrrrrrwwwrrrrww~<br></br>
`xxw>v?+?w}v}zuuuuuuuuuuux}?wzzzzzzzzzzzxxxxxxxxxxxw?}r^??+?v??vwzxxxzzzzx|<br></br>
-uuz>+>^??+?+|}xxxxxzzzzzzzv|?rzzzzzzzzzzzzzzzxxz}>~?xv+>+++^?v~^rzzxxzzzz|<br></br>
`zzw>+>?^~+}?.?zzzzzzzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wuw|+>>>^~?v~-}wwwzxxzz|<br></br>
`xxw`|???v>++?wzzzzzzzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzz?`>>+^??>>>vwwwwzxxzz|<br></br>
-uuxrrzzzzzzzxzzzzzzzxzzzzzzzzzv^~||||^|^|^~~|+?wzxzw}}rr}rrwwzzzwzzxxxxxx|<br></br>
`xxuuuuxxxzzzzzzzzxvw?zzzzzzwwwww?^~~^+++||^?vzxxxxzwwwwr+w>}wwwrwwwzzzzzz|<br></br>
`zzzzzxxxxxzzzzzz}z^?-}xzzzzzzwwwww}^}v}v^?rwwzxxxzwwwwwv-?+>rzwrwzxxxxxxx|<br></br>
`zxxxzzzzzzzzzzzzv+>-vvzzzzzzxzzwwww?v?>?+>?>>>}wwwwwwrw?^>?~xzwrrwzzzzwrr~<br></br>
`zxxxxxxxxxxxxxzzz>vvzwzzzzzzzwwr}vv??>>>>+^?v~|rwwwwrrwwrxwrxwwwwrrrwzzwr-<br></br>
`zxxxxxxxxzvxxxzwwwzxzxxxzzzzzz??v??}^~>>+>^++~>zxxxxxxxuuxxxzwwwwwvwrrwzz|<br></br>
`zzzzzxxxr>rxxxxxxzzxxxxxuuuuuu^wuuuz??vv?vr}v>wzzzzzzzzwwwwwwwwwww?>rrrrz~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxu}?wxxxzwwwwwwwvvwwwwwwwwwwrrrrrwwwwwz??zwzz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwuxxwvwwwv???rwwwwrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwwrrrwwzzzzzzzv?rrwwzr-<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzxv}}+>>??.<br></br>
`xxzzzzxxzz>wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vrzwrrrrwwwzw+^+>+^?.<br></br>
`xxxxxxrru}^xzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxwwrrrrww}>v+?r}~<br></br>
`xxzzzzw?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwzwwwrrrrrv+}zz|<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}?rv+`  wyuzyu-  .>}vvrzwwwrrrwwwww~<br></br>
`uuuuuuyuuzwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzwwwrrrrww~<br></br>
`uuuuuuuxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>?r}~.   .z?wuuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}v?vvv>-^x>}rwzxxuuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrwxxxxxxxu?|uuuuuuuuuz~vyuuuuyuxwv?vvr?>+????rwwzuuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzxxxzxxxxx?-zxxxuuuuuuyz~vyuuuuuuuuuz}v++>^>?~}rrrwzu^<br></br>
`xxxxxxxxxxxxxxzzxzzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuux>vvvv?vrrrrrrz|<br></br>
-xxxxxxzxzzxxzxxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuuyuuuuxzzzwwz~<br></br>
`xuuuxxxxxxxxxwvv?rrrz??zxxx}|rxxxxxxxxxxzwwzxxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzzxzxzxw>++>>>+zv??vv>wxzzzzzzzzzzzzzzuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxzzzzzwww|?>>>>>zzzrrwxxxxxxxzwwzzxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`wwrrrrrrr}}}}v?vrwrrzzwwr}}rrrwwwzv>+???zxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

                  `rwwwwwwwwwwrrrwwwzzzzzxxxzxzzzwrwwwwwwwwwwwwwwwwzzzxzzwzzwwwwwwwwzzwwwwww~<br></br>
`zxzv}v>?wzwzxuuuuuuuuuuux}?wzzzzzzzzzzzzxxxxxxxxxxw?}z>vv?vw}vrwzxxxzzzzx|<br></br>
-uuz>+>^>v>>+>wxxxxxzzzzzzzv|?rzzzzzzzzzzzzzzzxxz}>~?xr++>^+>>?|>rzzxxzzzz|<br></br>
`zzz?+>>>|^rv.>zzzzzzzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxx?+>>>+|vw>.vzwwzxxzz|<br></br>
`xxz~~>>+>|+^|vzzzzzzzzzzzzzxz>-~^>v}}v?}ww}?^~~^vzzzv.^>>^++++~>}wwwzxxzz|<br></br>
-uux??rrwzwrrzzzzzzzzxzzzzzzzzzv^~||||^|^|^~~|+?wzxzwv?vv?v}wr}rwwwzxxxxxx|<br></br>
`xuuuuuuxxzzzzzzzzxvw?zzzzzzwwwwwv^~~^++^||^?vzxxxxzwwrrr>w>}wwwrwwwzzzzzz|<br></br>
`zzzzzxxxxxxzzzzz}z^?-}xzzzzzzwwwww}^}wwv+vrzzzxxxzwwwwwv-?+>rzwrwzxxxxxxx|<br></br>
`zxxxzzzzzzzzzzzzv+>-vvzzzzzzzzzwwww?}}????}rv}wwwwwwwrw?^>?~xzwrrwzzzzwrr~<br></br>
`zxxxxxxxxxxxxxzzz>vvzwzzzzzzzwwr}vv???>>>^+>>>|vwwwwrrwwrxwrxwwwwwwrwzzwr-<br></br>
`zxxxxxxxxzvxxxzwwwzxxxxxzzzzzz??v??rz?+>>>^^??`^wxxxxxxuuxxxzwwwwwvwwrwzz|<br></br>
`zzzzzxxxr?rxxxxxxzzzxxxxuuuuuu^wuuuur`|?>+>>+^^?wzzzzzzwwwwwwwwwww?>rrrrz~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxur?wxxxwvv}}vrwvvwwwwwwwwwwrrrrrwwwwwz??zwzz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwuuxwvzwwv???rwwwwrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uuur>uw>>wzv?vrwwrrrrwzzzzzzzv?}zzzww~<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwzzzzxvv?>>???`<br></br>
`xxzzzzxxzz+wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vwzwrrrrwwwzw^^>+^>^ <br></br>
`xxxxxxrru}^xzzzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwrrrrwwv>?^?}}~<br></br>
`xxzzzzw?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwzwwwrrrrrv+}zz|<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}?rv+`  wyuzyu-  .>}vvrzwwwrrrwwwww~<br></br>
`uuuuuuyuuzwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzwwwrrrrww~<br></br>
`uuuuuuuxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxzzwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>?rv~.   .z?ruuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}v?vvv>-^z>}wwzzxuuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrzxxxxxxxu?|uuuuuuuuuz~vyuuuuyuxw}v?v?>+???>vrwwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuuuuz}>^>+^?~vxrrrrzu^<br></br>
`xxxxxxxxxxxxxxzzxzzzzzzxxxxxxv-wxxxzxxxxxuuux>?uuuuuuuuuuuv?}}vvvrrrrrrrz|<br></br>
`xxxxxxzxzzxxzzxxxxxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxuuuyyuuuxxzzzwwz~<br></br>
`xuuuxxxzxxxxxw??>}}vw??zxxx}|rxxxxxxxxxxxwwzxxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxzzxzxzzz>+++>?|rv??vv>wxzzzzxxzzzzzzzzuuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxxxxzzwww^????>?zxzrrwxxxxxxxr}}zwxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrr}}}}}}}}vvv}wrrzwrrr}}}rrwwwz?+>?>?zxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

                  `rwwwwwwwwrrrwwwwwwwzzzzzzzzzzzwrwwwwwwwwwwwwwwwwzzzzwzzzzwwwrrrrwzwwwwwww~<br></br>
`xxz}wwv}wxxuuuuuuuuuuuuux}?wzzzzzzzzzzzxxxxxxxxxxxw?}xv}w}rzwrwzzzxxzzzzx|<br></br>
-uux>>>^+vv??vzxxxxxxzzzzzzv|?rzxzzzzzzzzzzzzzxxz}>~?xz+>>+^?>>+?wwzzxzzzz|<br></br>
`zzz?+>+>>^vv`>zzzzzzzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxx}+>++>|?r>.?wwwzxxzz|<br></br>
`xxz+|>>^|~??`>zzzzzzzzzzzzzxz>-~^>v}}v?}ww}?^~~^vzzzw~|>>+^|+?|`vwwwzxxzz|<br></br>
-uuu+^vvvrv??vwzzzzzzxzzzzzzzzzv^~||||^|^|^~~|+?wzxzzv|>?>>?}v??rwwzxxxxxx|<br></br>
`xxxxzuuxxxzzzzzzzxvw?zzzzzzwwwwwv^~~^++^||^>vzxxxxzwrrrw>w>}zwwwwwwzzzzzz|<br></br>
`zzzzxxxxxxxzzzzz}z^?-}xzzzzzzzwwww}^}wwv^vrwwzxxxzwwwwwv-?+>rzwrwzxxxxxxx|<br></br>
`zxxxzzzzzzzzzzzzv+>-vvxzzzzzxzzwwww?}}}v?wwwwwzwwwwwwrw?^>?~xzwrrwwzzzwrr~<br></br>
`zxxxxxxxxxxxxxzzz?vvzwzzzzzzzwwr}vv????>??>vv??}wwwwrrwwrxwruzwwwwwrwzzwr-<br></br>
`zxxxxxxxxzvxxxzwwwzxxzxxzzzzzz??v??rzzv+>++++?>-vxxxxxxuuxxxzwwwwwvwwrwzz|<br></br>
`zzzzzxxxr?rxxxxxxxzzxxxxuuuuuu^wuuuxxw^+>>+^^>?`>zzzzzzwwwwwwwwwww?>rrrrz~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxur?wxxxz?->?>>?>?>?}wwwwwwwrrrrrwwwwwz??wwzz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwuzwrvwww}???rwwwwrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uuur>yw>>wzv?vrwwrrrrwzzzzzzzv?}zzzzw~<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??vwrrrrwwwzzzxv?>>????-<br></br>
`xxzzzzxxzz+wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vrzwrrrrwwwzw|++^^?-.<br></br>
`xxxxxxrru}^zzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwwrrrw}>>?+?vr~<br></br>
`xxzzzzz?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>r}vrxwwwrrrrrv+rzz|<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}?rv+`  wyuzyu-  .>}vvrzwwwrrrwwwww~<br></br>
`uuuuuuyyuzwrrrv?}v-    .xyzxu}?rrr?|vzz?|vv??+}yyxyy^    .+v?vxzzwwrrrrww~<br></br>
`uuuuuuuxzwwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxxzwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vvrzuyu+zzxuuxw?vyyuzv>>?rv~.   .z?ruuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}v?vvv>~^w>rrwzzxxuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}v}rzxxxxxxxu?|uuuuuuuuuz~vyuuuuyuuz}?v?>+>??>?rwwwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuuuuz?^>+^?^>xxrrrrzu^<br></br>
`xxxxxxxxxxxxxxzzxxzzzzzxxxxxxv-wxxxzxxxxuuuux>?uuuuuuuuuuw?}rrvvwwrrrrrrz|<br></br>
`xxxxxxzxzzxxzzzxzzxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxyuyyuuuuxxzzzwwz~<br></br>
`xuuuxxxxxxxxxz??+vvv}??zxxx}|rxxxxxxxxxxxwwzxxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuxxxzxxxzzx>++++?-}v??vv>wxzzzzxxzzzzzzzzxuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxxxxzzwwz+??v???wxzrrrxxxxxxzvv}rwxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrrr}}}}}}vvvvrrrwzwrrr}}}rwwwwr+++?^vzxxxxxxxxxxxxzzxxxzzzzzxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrwwwwwzzzwzzwwrrrrrrrrrrrrrrrrrrwwwzwwwwwwrrr}}rrwwrrrrrr~<br></br>
`xxzzzzwwzxxuuuuuuuuuuuuux}?wxzzzzzzzxzzxxzxxxxxxxxw?}xwwxzzzzwzzzzxxzzzzx|<br></br>
-uux???++}wvvwxxxxxxzzzzzzzv|?rzxzzzzzzzzzzzzzzxz}>~?xz>>?>>}v??}wzzzxzzzz|<br></br>
`zzz?+>+>?+??~?zzzzzzzzzzzzx}|~>}zxxxxxxxzzxxz}?^-+wxxw>+>^+++v>-vwwwzzxzz|<br></br>
`xxz?^>?+~~v}-~wxzzzzzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzz?^>>>+~>}?.>rwwzzxzz|<br></br>
-uuu^-???v?++>}zzzzzzxzzzzzzzzzv^~||||^|^|^~~|+?wzzzz}`|>>^>?>+^?rzzxxxxxx|<br></br>
`xxx}}xuxxxzzzzzzzxvw?zzzzzzzwwwwv^~~^++^||^>vwzxxxzwrv}r+}+}zwrwwwwzzzzzz|<br></br>
`zzzxxxxxxxxzzzzzrz^?-}xzzzzzzzwwwwv^rwwv^vrwwzxxxzwwwwwv-?+>rxwrwzxxxxxxx|<br></br>
`zxxxxzzzzzzzzzzzv>>-vvzzzzzzxzzwwww?}}}v?wwzwwwwwwwwwww?^>?~xzwwrwwzzzzwr~<br></br>
`zxxxxxxxxxxxxxzzz>vvzwzzzzzzzzwr}vv?????v}}}wrrwwwwwrrww}xwruzwwwwwrwzzzr-<br></br>
`zxxxxxxxxzvxxxzwwwwxxzxxzzzzzz??v??rzzxv+?>^???>?zxxxxxuuxxxzwwwwwvwwrwzz~<br></br>
`zzzzzxxxw?rxxxxxxxzzxxxxuuuxuu^wuuuxxxz?+>+>^>}?`vzzzzzwwwwwwwwwww?>rrrrw~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxu}?wxxxzz?->>>+^^?+-?wwwwwwrrrrrrwwwwz??wwzz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwux?+?v??v+^+vwwwwrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uuur>yw>>wxv?vrwwrrrrwzzzzzzzv>rzzxxz~<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~uxuv|v+}wwxxzr??}wrrrrrwwzzzxv>?>???r|<br></br>
`xxzzzzxxzz+wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vwzwrrrrwwwzv^>+^?|>~<br></br>
`xxxxxxrru}^zzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwwrrrw??>?>>vw|<br></br>
`xxzzzzw?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxwwwrrrwrv+rzz~<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwzwwwrrrrwwww~<br></br>
`uuuuuuyyuzwrrrv?}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vxzzwwrrrrww~<br></br>
-uuuuuuuxzwwwwz?zv       ?uxzrvv}zzvvzyyz+ruwv??vwzuy?      ?z?uxxxxzwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>?r}~.   .z?ruuuuuxxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyuz}v?vvv>-|}?rrwzzxxuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrwxxxxxxxu?|uuuuuuuuuz~vyuuuuyuuw}v?>>+???>}wrwwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvrzzzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuuuuw^>+^+>^zuxrrrrzu|<br></br>
`xxxxxxxxxxxxxxzzxxzzzzzzxxxxxv-wxxxzzxxxxuuux>?uuuuuuuuuxvrwwr}wzwwrrrrrz|<br></br>
`xuxxxxzxzzxxzzzzwwxxw}xxxxxu}~wuxxxxxxxzxxxxxuv?zuuuuuuvxyyyuyuuuxxxzzwwz~<br></br>
`zuuuxxxxxxxxxz?>+?v?v??zxxx}|rxxxxxxxxxxxzwzzxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuuxxzxzxxzx?+>^^?-vv??vv>wxzzzzxzzzzzzzzzxuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxxzxzzwwz??vvv?vzxzr}rzxxxxx}??}vrxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwrrrrr}}}}}}}}}rwwzzzwww}}rrwwww?++^>^wzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrwwwwwzzzwzwwwrrrrrrrrrrrrrrrrrrwwwzwwwwwwrrr}}rrwwrrrrrr~<br></br>
`zxxzxxxzxxxxuxuuuuuuuuuux}?wxzzzzzzzxzzxxxxxxxxxxxw?}xxxxxxxzwzzzzxxzzzzx|<br></br>
-uuxvv}??}xwwxxxxxxxxzzzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xx??}vvrrvrwzwzzxxzzz|<br></br>
`zzz?^>++v?+>^vzzzzzzzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxz>+>+|?+>+^vwwwzzxzz|<br></br>
`xxzv+>>>^|vw|-rxzzzzzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzz}+>>+>|>rv.+rwwwzxzz|<br></br>
-uuu>`>>+>^^+~?wzzzzzxzzzzzzzzzv^~||||^|^|^|~|+?wzzzzw~->>+^+|+|~vwzzxxxxx|<br></br>
`xxu?>rzzxzr}wzzzzxvw?zzzzzzzwwwwv^~~^++^||^>vwzxxxzw}>?v+?^}rvvrwwwzzzzzz|<br></br>
`zzzzzxxxxxxzzzzzrz^?-}xzzzzzzzwwwwv^rwwv^vrwwzxxxzwwwrrv-?+>rxwrwzxxxxxxx|<br></br>
`zxxxxzzzzzzzzzzzv>>-vvxzzzzzzzzwwww?}}}v?wwwwwwwwwwwwww?^>?~xxwwrwwzzzzwr~<br></br>
`zxxxxxxxxxxxxxzzz>vvzwzzzzzzzzwr}vv???v>?rrrwwwwwwwwwrwr}xwruzwwwwwrwzzzr-<br></br>
`zxxxxxxxxzvxxxzwwwwxxzxxzzzzzz??v??rzzxz??>>?}}}wzxxxxxuuxxxzwwwwwvwwrwwz|<br></br>
`zzzzzxxxr?rxxxxxxxzzzxxxuuuuuu^wuuuxxxxz?+>++>>?^?wzzzzwwwwwwwwwww?>rrrrw~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxu}?wxxxzwwv+>>>>|?}+.vwwwwwrrrrrrwwwwz??wwwz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwuuz|~>>>+^^^|>}wwrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uxxv>zv^>zz}?vrwwrrrrwzzzzzzzv?wxxxxw~<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~xxuv|v>rwwxxzr??}wrrrrrwwzzzxv?????vz|<br></br>
`xxzzzzxxzz+wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vwzwrrrrwwww>++^+?-r|<br></br>
`xxxxxxrru}^zzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwwrrr}>?>?++}z|<br></br>
`xxzzzzw?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzwwwrrrrv+rzz~<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwzwwwrrrrwwww~<br></br>
`uuuuuuyyuzwrrr??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vxzzwwrrrrww~<br></br>
-uuuuuuuxzzwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?uxxxxzwwwrw~<br></br>
`zzzzzzzzzzzzxx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>vr}~.   .z?ruuuuuuxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyyz}v?vv?^`|r>}rwzzxxuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrzxxxxxxxu?|uuuuuuuuuz~vyuuuuyuuz}v?>+>>?^vwwrwwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvrzxzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuuuu>++++?|}uuxwrrrwu|<br></br>
`xxxxxxxxxxxxxxzxxxzzzzzzxxxxxv-wxxxxzxxxxuuux>?uuuuuuuuu}rzzwrwzwwwrrr}rw|<br></br>
`xxxxxxzxzzxxzzwwrrxxw}xxxxxu}~wuxxxxxxxxxxxxxuv?zuuuuuuvxyyuyyuuuxxzzzwwz~<br></br>
`zuuuxxxzxxxxxzv>+????>?zxxx}|rxxxxxxxxxxxzwzzxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuuxxzxxxxzx?^>^^?~?v??vv>wxzzzzzzzzzzzzzzxuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxxzxzzwwzv?}rrv}zxzr}rzxxxxz?>?v?wxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rrrrrrrrr}}}}}}}rwwwzzwwwr}}rwwww++^+|?zzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

                  `rrrrrrrrrrrrrrrrrwwwwwzzzwzwwwrrrwwrrrrwwwwwwwwwwwzzwwwwwwwrrrrrrwwrrrrrw~<br></br>
`zxxxxxxxxxxxxxuuuuuuuuuux}?wxzzzzzzzzzzzxxxxxxxxxxw?}uxuxxxzwwwzzzxxzzzzx|<br></br>
-uuxrwzvvwxxxxxxxxxxxzzzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xx}vwwrwwrwzwwzzxxzzz|<br></br>
`zzz?+>+^vv?>?}zzzzzzzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxx?+?>^??>>?rwwwzzxzz|<br></br>
`xxzv+>+>?^?}^-}zzzzzzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzwr>+>+>^+vv`+rwwwzxzz|<br></br>
-uuu?->>+|~>?~~rzzzzzxzzzzzzzzzv^~||||^|^|^|~|+?wzzzzw>~>>>^||?>`+rzzxxxxx|<br></br>
`xxu?~?vvwr??vrzzzxvw?zzzzzzwwwwwv^~~^++^||^>vwzxxuxwr|^?++^v??>vrwwzzzzzz|<br></br>
`zzzwwzxxxxxzzzzzrz^?-}xzzzzzzzwwwwv^rwwv^vrwwwxxxzwwrrrv~?+>rxwwwzxxxxxxx|<br></br>
`zxxxxzzzzzzzzzzzv+>-vvzzzzzzzzzwwww?}}}v?wwwwwwwwwwwwww?^>?~xxwwrwwzzzzwr~<br></br>
`zxxxxxxxxxxxxxzzz>vvzwzzzzzzzzwr}vv???v>?v}}rwrwwwwwwrwr}xwruzwwwwwrwzzzr-<br></br>
`zxzxxxxxxzvxxxzwwwwxxzxxzzzzzz??v??rzzxzr>?vvvxxxxxxxxxxuxxxzwwwwwvwwrwwz|<br></br>
`zzzzzxxxw?rxxxxxxxzzzxxxuuuuuu^wuuuxxxxxz>??>?v??vwzzzzwwwwwwwwwww?>rrrrw~<br></br>
`zzzzzzzz>wxxxxxxxxxxxxxxxxxxxur?wxxxzwww}++++++??-^rwwwwrrrrrrwwwwz??wwwz|<br></br>
`zzzzzzzwvzxxxxxxxxxxxxxxxxxxz}rzwxzwuuxw^^>>+^~??~~}wrrrrwwwwwwwwz}+vxxzz~<br></br>
`zzzzzzzzzvvzxxxxxxxxxxxxxxz}?vv?}ur?uuu}~>>^>vv?>^vwwrrrrwwzzzzzzv?wxxxxw~<br></br>
`zzzzzzzzzz?rxzzzzzzzzzzxwv?}v||v???~xxuv+?+rwruxzr??}wrrrrrwwzzzz???v?vwz|<br></br>
`xxzzzzxxzz+wzzzzzzzzzzwvvrzz.  .+v?`wzx>~}w>.>yyxzxr?vwzwrrrrwwzv^++^?|>z|<br></br>
`zxxxxxrru}^zzzzzzxxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwwrrr?>?>?^>wx|<br></br>
`xxzzzzw?v?zxxzzxxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzwwrrrrrv+rzz~<br></br>
-uuuuuxxzvwwwzzxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwzwwwrrrrwwww~<br></br>
`xuuuuuyyuzwrrr??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vxzzwwrrrrww~<br></br>
-uuuuuuuxxzwwwz?zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?uxxxxzwwwrw~<br></br>
`zzzzzzzzzzzzzx}vz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>?r}~.   .z?ruuuuuuxxxzx|<br></br>
`zzzzzzxxxxxxxxz?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyyxrv?vv?~-+z>}wwzzxxuuuxu|<br></br>
`xxxxxxxxxxxxxxx?}w}vvrzxxxxxxxu?|uuuuuuuuuz~vyuuuuyuuz}?>>>>?+?rwwwrwzxuu|<br></br>
`xxxxxxxxxxxxxxxrvrzxzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuuyv|>++>+?uuuxwrrrwx|<br></br>
`xxxxxxxxxxxxxxzxxxzzzzzzxxxxxv-wxxxzzxxxxuuux>?uuuuuuuuz}zxxwwzzwwwrrr}rw|<br></br>
`xuxxxxzxzzxxxzwr}vzzr}xxxxxu}~wuxxxxxxxxzxxxxuv?zuuuuuuvuyuuyuuuuxxxzzzwz~<br></br>
`zuuuxxxxxxxxxxv++>??>^?zxxx}|rxzxxxxxxxxxzwwzxuxv}xuuw?vuuuuuuuuuuuuuuuuu^<br></br>
-uuuuuxxzxxxxzxv|>+^?|?v??vv>wxzzzwrzzzzzzzzzxuuuuzvvvvxyuuuuuuuuuuuuuuuuy^<br></br>
`xxxxxxxzxzzzwz}?rwzrrzxzr}rzxxxx}>>???zxxxxuuuuuuuuxuuuuuuuuuuuuuuuuuuuuy^<br></br>
`rwwwrrrrrr}}}}}}}rwwzzwwwr}}rwwwv^>+>|rzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwwwwwwwwrrrw?~}w}zzrzzxxzxzzzwrwwr???v??vrw}}wwwzzzwzzwzzwrww}~?zzwwwwww~<br></br>
`zxxxxxxxxxxuv^wuuxuuxuuux}?wzzzzzzxxxxxxxxxxxxxxxxw?}uxxxxzzzrw}~vuxzzzzx|<br></br>
-uuuzxxzwzxuv+zxxxxwxzwzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxwwzzwzwwzz}-}xxxzzz|<br></br>
`zzz?>?>^?r?|vzzzzzzwzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxx?>??>vv?v}zv>zzzxzz|<br></br>
`xxxv^>++?>>?^^rzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzww?+>+^>+??~?r>rwzxzz|<br></br>
-uuu}^>>>|~?r>.vxzzzzzzzzzzzzzzv^~||||^|^|^|~|+?wzzxzwv^>>>>|^}}-~}>zxxxxx|<br></br>
`xxu?.>?>>?++^?zrrxvw?zzzzzzzwwwwv^~~^>+^||^>vwzxxuz}r|`>++^?+^|^vr^>vzzzz|<br></br>
`zzzrvwv>rrvzzwvv?z^?-}xzwrzzzzwwww}^rwwv^vrwwwxxxw}}rvvv~+>>v}>}wr?z?+wxx|<br></br>
`zxxxxv>wxw?xwv}w>+>-vvxzzw}rrzzwwww?}}}v?wwwwwr}}vrwwwwv^>?`rw}>}v?zxv|vw~<br></br>
`zxxxv>zxzxvrvrrr?+vvzzzzzzwr}rzr}vv???v>?v}}rw}}rwwwwrwr}xw^rwr}>>vrwz}^r~<br></br>
                  `zzxr?xxxxx??rwr??wwxxxxxxzzzwr??v?vrzzxzr??vvvxxxxxxxxxxuxxw??rrv|?wwrw??|<br></br>
`zzz?wxxxr???wr?vxxzzzxxxuuuuuu^wuuuxxxxxxwvrr?wwrwzzzzzzwwwww?>rv??>rrrw>`<br></br>
`zx}vxzzz>w??r?}xxxxxxxxxxxxxxur?wxxxzzwwwv^>>^?>>+>}wwwwwrrrrw?>v?z??wwzv <br></br>
`zx?}xzzwvzv??rxxxxxxxxxxxxxxz}rzwxzwuuxwv?+>+>^+vv`^rwrrrwwwwwz?+?}+vxxxw.<br></br>
`zz?rzzzzz}?>?xxxxxxxxxxxxxz}?vv?}ur?uuuw~-+++++|++-?rrrrrwwzzzxr|+?wxxxxr.<br></br>
`zx?vxzzzzx>|}xzzzzzzzzzxwv?}v||v???~xxuv^|>}>?zwvv??}wrrrrrwwzzr>>??vv}z} <br></br>
`xxw+wzxxxz^^}xzzzzzzzzwvvrzz.  .+v?`wzx>~rw>.>yyxzxr?vwzwrrrrww}+>+^>?-w>.<br></br>
`zxur?zrru}^+?xzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?}vvwxzwwrrv|?>>?|?v?|<br></br>
`xxxxr?w?v?z?>xzzxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzww?|rrrv+}>r~<br></br>
-uyuuu}>r}wz>^wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwzz>-?rwwr>vz~<br></br>
-xuuuuyxr}v>?~v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu+^v?vv>vww~<br></br>
-uuuuuuuuwvrw^??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?u>ruz}}wwrw~<br></br>
`zzzzzzzzzxxxv?rvz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>vrv~.   .z?rz?yuuuuxxzx|<br></br>
`zzzzzzxxxxxxx?w?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyyxrv?v?+||>w>rv>zzxxuuuxu|<br></br>
`xxxxxxxxxxxxxvw?}w}v}rzxxxxxxxu?|uuuuuuuuuz~vyuuuuyyuz}>>>>+>+??wwwrwzxuu|<br></br>
`xxxxxxxxxxxxxrvwvrzxzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuyz|>>>+>??}uuxwrrrwx|<br></br>
`xxxxxxxxxxxxxz?xxxzzzzzzxxxxuv-wxxxzxxxxxuuux>?uuuuuuuuwzxxzzx>}zwwrrr}rw|<br></br>
`xuxxxxzxzzxxxxvvv?ww}}xxxxxu}~wuxxxxxxxxzxxxxuv?zuuuuuuvxyuuuu?xuxxxzzzwz~<br></br>
`zuuuxxxxxxxxxx}+++>?>~?zxxx}|rxxxxxxxxxxxzwwzxuxv}xuuw?vuuuuux>uuuuuuuuuy^<br></br>
-uuuuuxxzxxxxzx}|?>>?+>v??vv>wxzzr}}wwzzzzzzzxuuuuzvvvvxyuuuuuw?yuuuuuuuuy^<br></br>
`xxxxxxxzxzzzwzw>rzzwzxxzr}rzxxxx?+>?>?xxxxxuuuuuuuuxuuuuuuuuyvvyuuuuuuuuy^<br></br>
`rrrrrrr}}}}}}}}>vrrwzzwrr}}}rrww+>?>>?wwzzxxxxxxxxxxxxxxxxxxx?vxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwwwwwwwrrrrw?`}wvzwrzzxzzxzzwwrww}?>???>?}wvvwwwzzxzzzrwzw}ww}`>zzwwwwww~<br></br>
`zxxxxxxxxxxu?~wuuzxuzxuux}?wzzzzzzxxxxxxxxxxxxxxxxw?}uxzxxrzzrw}`?xxxzzzx|<br></br>
-uuuuuuxxxxu?~zxxxxrxzrzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxxzxwwzwrwz}`}xxxzzz|<br></br>
`zzzv?v?>vw?~wzzzzzwrzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxx}?}vvrrrrwz?^zwzxzz|<br></br>
`xxxv^++^??+>^?wzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzww?^>>|>>>>^vw+rwzxzz|<br></br>
-uuur+>>>+|?w?.?xzzzzzzzzzzzzzzv^~||||^|^|^|~|+?rzzxzwr>+>+>^^}r~-}+zxxxxx|<br></br>
`xxuv.+?+^+|+~|}}}xvw?zzzwzzzwwwwv^~~^>+^||^?vwzxxuzvw>`>>>^+|>+-+}~^?zzzz|<br></br>
`zzzv>}?^r}?rrr?v>z^?-}xzr}wzzzwwwwv^rzwv^vrwwwzxzrv}r>>?|^>+?v^vr}>w>^wxx|<br></br>
`zxxxx?^wxw?xr?rw++>-vvxzzrvv}wzwwww?}}}v?wwwwwvvvvrwwrrv^>?`rw}+v??zxv-vw~<br></br>
`zxxxv+zxzxvr?www?^vvzwzzzzwv?vzr}vv???v>?v}}rwvvrwwwwrwr}xw~rwwr>+vrwzv~r~<br></br>
`zzx}>xxxxx>+www??wwzxxxxxzzzr}????vrzzxzr??vvvxxxxxxxxxxuxxw>?wwv-?wwrw?>|<br></br>
`zzz>wxxxr?>?ww?vxxzzzzxxuuuuuu^wxuuxxxxxxxzzz?zzzzzzzzzzwwwww>>wv>?>rrrw+`<br></br>
`zxv?xzzw>w??r?}uxxxxxxxxxxxxxur?wxxxzzwwww???>?}vvvrwwwwwrrrrw>>v>z??zwzv <br></br>
`zx?}xzzwvz?>?}xxxxxxxxxxxxxxz}rzwxzwuuxwvw?+>++>+?+|vwrrrwwwwwz>^?}+vxxxr.<br></br>
`zz>}zzzzz}>^?xxxxxxxxxxxxxz}?vv?}ur?uuur>}^>>>+~+v?.?rrrrwwzzzxr-|?wxxxzr.<br></br>
`zx?vxzzzzx>-}xzzzzzzzzzxwv?}v||v???~xxuv||~?>+>?+++|vwrrrrrwwzzw>+?vvvzxv <br></br>
`xxw|wzxxzx^-vxzzzzzzzzwvvrzz.  .+v?`wzx>~rv^.>xyxwzw?vwzwrrrrwwv+>+^?|?x+.<br></br>
`zxur>zr}u}^|?xzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyxxyw?}vvwxzwwrr>|>>+>|?v>|<br></br>
`xxxx}>w?v?z>^xzzxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzww>-}rrv+v^r|<br></br>
-uuuuy}+r}wz+-wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwxz+.>www}^vz~<br></br>
-xuuuuyzvvv+>-v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu^~??v?+vww~<br></br>
-uuuuuuuuwv}w~??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?x^vuz}vwwww~<br></br>
`zzzzzzzzzxxxv>rvz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv>>vrv~.   .z?rz+yuuuuxxzx|<br></br>
`zzzzzzxxxxxxx>w?u^~>vvvvvrzuuuuy>?yuuuuuyx~}yuuyyxrv?v?+^|^r>rv+zzxxuuuxu|<br></br>
`xxxxxxxxxxxxu?r?}w}v}rzxxxxxxxu?|uuuuuuuuuz~vyuuuuyyuz?>>>>?~v??wwwrwwxuu|<br></br>
`xxxxxxxxxxxxxr?wvrzxzzxxxxxxxx?-zxxxuuuuuuyz~vyuuuuuuu>+??>>>z?vuuxwrrrwx|<br></br>
`xxxxxxxxxxxxxz>xxzzxzzzzxxxxuv-wxxxxzxxxxuuux>?uuuuuuuzzuuxxxx+}zwwrrrrrw~<br></br>
`xuxxxxzzzzzxzx??v?rrv}xxxxxu}~wuxxxxxxxxzxxxxxv?zuuuuuuvxyuuuu+xuuxxzzzwz~<br></br>
`zxuuuxxxxxxxxx}+++>>>~?zxxx}^rxxxxxxxxxxxzwwzxuxv}xuuw?vuuuuux^uuuuuuuuuy^<br></br>
-uuuuuxxzxxxxzxw|????+?v??vv>wxzwvvrrwzzzzzzzxuuuuzvvvvxyuuuuyr>yuuuuuuuuy^<br></br>
`xxxxxxxzxzzzwwz>wzxzzxxzr}rzxxxr++>>^}xxxxxuuuuuuuuxuuuuuuuuyv?yuuuuuuuuy^<br></br>
`rrrrrr}}}}}}}}}+vrrrzzwrr}}}rww}+?v??rwwwzxxxxxxxxxxxxxxxxxxx>?xxxxxzxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrw?`vrvww}wzzzwzzwwwrrr}?>??>>?}rvvrrrwwzwww}wwrvrrv`>zwwrrrrr~<br></br>
`zxxxxxxxxxxu?~wuuzuyxxuuur?wxzzzzzxxxxxxxxxxxxxxxxw?}uxzxxwzzww}`vuxxzzzx|<br></br>
-uuuuuuuuxxu?~zxxxxrxzrzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxxxxwrzwrwz}`}zxxzzz|<br></br>
`zzzr}rv?vz?|zxzzzzwwzzzzzzx}|~>}zxxxxxxxzzxxz}?^-+wxxxw}wrrrwwzzz?^zwzxzz|<br></br>
-xxxv+>+|?v+>>vzzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzwwv^?>+?v???}w+rwzxzz|<br></br>
-uuuw+>+>?+>}?`?zxzzzzzzzzzzzzzv^~||||^|^|^|~|+?rwzxzwr?+>+++^?v~|}+zxxxxx|<br></br>
`xxur-+?>||^?+`?}}xvw?zzzwzzzwwwwv^~~^>+^||^>vwzxxuzvwv~>>>+^|?v~-v~^?zzzz|<br></br>
`zzxv-?>^}v+??v?v>z^?-}xzr}wzzzwwwwv^rzwv^vrwzwzxzrv}w^~>^^^>?>|+}}>w+^wxx|<br></br>
`zxxzz?^wxw?xw?rw++>-vvxzzrvv}wzzwww?}}}v?wrwww}vvvrww}}?+>?`rw}+vv?zx?~vw~<br></br>
`zxxxv+zxzxvr?www?^vvzwzzzzwv?vzr}vv???v>?v}}rwvvrwwwwrwr}xw~rwwr>+vrwzv~r~<br></br>
`zzxr>xxxxx>+rww??wwzxxxxxzzzr}??v??rzzzxr??vvvxxxxxxxxxxuxxw>?wwv-?wwrw??|<br></br>
`zzz>wxxxw?>?ww?vxxzzzxxxuuuuuu^wxuuuxxxxxxxxz?zzzzzzzzzzwwwww>>wv>?>rrrw+`<br></br>
`zxv?xzzz>w??r?}uxxxxxxxxxxxxxur?rxuxzzwwwwrvv?}}rrrwwwwwwrrrww>>v>z??wwzv <br></br>
`zx?}xzzwvz?>?}xxxxxxxxxxxxxxz}rzrxzwuuxwvzr+>?^>?>>>vrrrrwwwwwz>^?}+vxxxr.<br></br>
`zz>}zzzzz}>^?xxxxxxxxxxxxxz}?vv?}ur?uuur>uv++++>^?v|-vrrrwwzzzxr`|?wxxxxr.<br></br>
`zx?vxzzzzx>-}xzzzzzzzzzxwv?}v||v???~uxuv|v-+>>++~^+-~}wrrrrwwzz}+^vvvrzxv <br></br>
`xxw|wzxxxx^-vxzzzzzzzzwvvrzz.  .+v?`wzx>~v^~-^?}rvvv?vwzwwrrrww?>>^>?~wx+.<br></br>
`zxur>zr}u}^|?xzzzzxz}vvwxzuy>    .?wv}v?z?.  |yyuxyw?rvvwxzwwwv|^+?>>|vv>|<br></br>
`xxxx}>w?v?z>^xzzxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzww>`}rrv+v^r|<br></br>
-uyuuyv+r}wz+-wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyy-  .>}vvwxz+.?www}^vz~<br></br>
-uuuuuyzvvv+>-v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu^~??v?+vww~<br></br>
-uuuuuuuuwv}w~??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?x^vuz}vwwww~<br></br>
`zzzzzzzzzxxx?>rvz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv?>?rv-.   .z?rz+yuuuuxxxx|<br></br>
`zzzzzzxxxxxxx>w?u^~>vvvvv}zuuuuy>?yuuuuuyx~}yuuyyxrvvv?^+-|r?rv+zzxxuuuxu|<br></br>
`xxxxxxxxxxxxu?r?}r}vv}zxxxxxxxu?~uuuuuuuuuz~vyuuuuyyu}+>>+?^>w??wwwrwwxuu|<br></br>
`xxxxxxxxxxxxxr?wvrzxzzxxxxxxxx?-zxxxxuuuuuyz~vyuuuuuyv^????>}u?vuuuzrrrwx|<br></br>
`xxxxxxxxxxxxxz>zzzzxzzzzxxxxuv-wxxxxxxxxxuuux>?uuuuuuxzuuuuxxx+}zwwrrrrrw|<br></br>
`xuxxxxzxzzzxxx???>v}?rxxxxxu}~wuxxxxxxxxzxxxxxv?zuuuuuuvxuuuuu+xuuxxzzzwz~<br></br>
`zxuuuxxxxxxxxxv^+++>?~?zxxx}|}xxxxxxxxxxxxwwzxuxv}xuuw?vuuuuux^uuuuuuuuuy^<br></br>
-uuuuuxxzxxxxzxz^????>?v??vv>wxzv??}vrzzzzzzzzuuuuzvvvvxyuuuuyr>yuuuuuuuuy^<br></br>
`xxxxxxxxxzzzwwz>wxxxxxxzr}rzxxx?+>+>+zxxxxxxuuuuuuuxuuuuuuuuyv?yuuuuuuuuy^<br></br>
`rrwwrrrr}}}}}}}>vrrrzzwwwr}}rww??}}v}zzzzzxxxxxxxxxxxxxxxxxxx>vxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrw?`vrvww}wzzzwzzwwwrrr}?>??>>?}rvvrrrwwzwww}wwrvrrv`>zwwrrrrr~<br></br>
`zxxxxxxxxxxu?~wuuzuyxxuuur?wxzzzzzxxxxxxxxxxxxxxxxw?}uxzxxwzzww}`vxxxzzzx|<br></br>
-uuuuuuxuxxu?~zuxxxrxzrzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxxxxwrzwrwz}`}zxxzzz|<br></br>
`zzzwwzw}wz>|zxzzzzwwzwzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxxzwzwzwwzzzz?^zwzxzz|<br></br>
-xxxv>?>^>v^?vrzzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzwwv>vv?v}vv}rw+rwzxzz|<br></br>
-uuuw+++^??+?>~?zxzzzzzzzzzzzzzv^~||||^|^|^|~|+?rwzxzww?^>+^++>?~>r+zxxxxx|<br></br>
`xxuw^+>?|~^}v`+}}xvw?zzzwzzzwwwwv^~~^>+^||^>vwzzxuzvwr++>>>^|vr^.v~^?zzzz|<br></br>
`zzx}.+++??^+++>v>x^?-}xzr}wzzzwwwwv^rzwv^vrwwwzxxrv}w+.+++^+^^^~?v>w+^wxx|<br></br>
`zxxwv+^wxw?xr?rw++>-vvxzzrvv}wzzwww?}}}v?wrwww}vvvrww???+>?-rr}+vv?zx?-vw~<br></br>
`zxxxv+zxzxvr?www?^vvzwzzzzwv?vzr}vv???v>?v}}rwvvrwwwwrrr}zw~rwwr>+vrwzv~r~<br></br>
`zzx}>xxxxx>+www??wwzxxzxxzzzr}??v??rzzzxr??vvvzxxxxxxxxxuxxw>?wwv-?wwrw??|<br></br>
`zzz>wxxxr?>?ww?vxxzzzzxxxuuuuu^wxuuuxxxxxxxxz?zzzzzzzzzzwwwww>>wv>?>rrrw+`<br></br>
`zxv?xzzw>w??r?}uxxxxxxxxxxxxxur?rxxxzzwwwwwwvvwwwwwwwwwwwrrrrw>>v>z??wwz? <br></br>
`zx?}xzzwvz?>?}xxxxxxxxxxxxxxz}rzrxzwuuxwvzzv>v??}}vvrwrrrrwwwwz>^?}+vxxxr.<br></br>
`zz>}zzzzz}>^?xxxxxxxxxxxxxz}?vv?}ur?uxur>uz?^>+^>+>>|vrrrrwzzzxr`|?wzzxxr.<br></br>
`zx?vxzzzzx>-}xzzzzzzzzzxwv?}v||v???~xxuv|v^++>>>^+??`^}rrrrwwzz?^+}}}zzxv <br></br>
`xxw|wzzxxx^-vxzzzzzzzzwvvrzz.  .+v?`wzx>~}>`^+++?++^+>rzwwrrrwv+>+^?|?zx+.<br></br>
`zxur>zr}u}^|?xzzzzxz}vvwxzuy>    .?wv}v?z?^~.~wwxxur?rvvwxzwww>^^+?>++v}>|<br></br>
`xxxx}>w?v?z>^xzzxz}v}v^wyxxyw.    ~uuuuuu.   `uyuxyx..>rvvwxzwr>-}wrv+v^r|<br></br>
-uuuuy}+r}wz+-wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwxz+.?www}^vz~<br></br>
-uuuuuyzvvv+>-v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu^~??v?+vww~<br></br>
-uuuuuuuuwv}w~??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?x^vuz}vwwrw~<br></br>
`zzzzzzzzzxxxv>rvz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv?>vrv-..  .z?rz+yuuuuxxxx|<br></br>
`zzzzzzzxxxxxx>w?u^~>vvvvv}zuuuuy>?yuuuuuyx~}yuuyyxrvv?>+^-|w>rv+zzxxuuuuu|<br></br>
`xxxxxxxxxxxxu?r?}r}vv}zxxxxxxxu?~uuuuuuuuuz~vyuuuuyyz+>>+??-?w??wwwrwwzuu|<br></br>
                  `xxxxxxxxxxxxxr?wvwzxzzzxxxxxxx?-zxxxxuuuuuyz~vyuuuuyz^??v???zu?vuuuzrrrwx|<br></br>
`xxxxxxxxxxxxxz>zzwzxzzzzxxxxuv-wxxxxxxxxxuuux>?uuuuuuxuyuuuuxx+}zwwrrrrrw~<br></br>
`xuxxxxzzxzzxzx?>?+vv?vxxxxxu}~wuxxxxxxxxzxxxxxv?zuuuuyuvxuuuuu+xuuxxzzzwz~<br></br>
`zxuuuxxxxxxxxxv^+>^+?-?zxxx}|rxzzzxxxxxxxzwwzxuxv}xuuw?vuuuuux^uuuuuuuuuy^<br></br>
-uuuuuxxzzxxxzxz^?vvv?vv??vv>wxw?>?vvrzzzzzzzzuuuuzvvvvxyuuuuyr>yuuuuuuuuy^<br></br>
`xxxxxxxzxzzzwwz>wxxxxxxzr}rzxxz+>>>+?xxxxxxxuuuuuuuxuuuuuuuuyv?yuuuuuuuuy^<br></br>
`rrrrrrrrr}}}}}}+vrrrwzzwwr}}rwr?}rrrzzwwwzxxxxxxxxxxxxxxxxxxx>?xxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrw?`vrvww}wzzzwzzwwwrrw}?>??>>?}wvvrwwwzzwwz}wwwvrr}`>zwwwwwww~<br></br>
`zxxxxxxxxxxu?~wuuxuyxxuuur?wxzzzzzxxxxxxxxxxxzxxxxw?}uxzxxwzzww}`vxxxzzzx|<br></br>
-uuuuuuxxxxu?~zuxxxrxzrzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxxxzwrzwrwz}`}zxxzzz|<br></br>
`zzzzzzzwzz>|zxzzzzwwzwzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxxxxzwzwwwzzz?^zwzxzz|<br></br>
-xxx}?v?>??~rwzxzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzzw}?}}v}wrrwww+rwzxzz|<br></br>
-uuuw++>^>?>>++vzxzzzzzzzzzzzzzv^~||||^|^|^|~|+?rwzxzwwv^>>^>?>>+vw+zxxxxx|<br></br>
`xxuz>>>>>^^rr`|v}xvw?zzzwzzzwwwwv^~~^>+^||^>vwzzxuzvwr?+>+++|?r+`v~^vzzzz|<br></br>
`zzxr`^>>^>|+^->v>z^?-}xzr}wzzzwwwwv^rzwv^vrwwwzxxrv}w?`++>^^|^>-~?>w+^wxx|<br></br>
`zxxw>^+rww?rv?rw++>-vvxzzrvv}wzzwww?}}}v?wrwww}vvvrww>^>+++|v??+vv?zx?~vw~<br></br>
`zxxxv+zxxxvr?www?^vvzzzzzzwv?vzr}vv???v>?v}}rwvvrwwwwrrr}zr~rzwr>+vrwzv~r~<br></br>
`zzxr>xxxxx>+rww??wwzxxzxxzzzr}??v??rzzzxr??vvvzxxxxxxxxxuuxw>?wwv-?wwrw??|<br></br>
`zzz>wxxxw?>?ww?vxxzzzzxxxuuuuu^wxuuuxxxxxxxxz?zzzzzzzzzzzwwww>>wv>?>rrrw+`<br></br>
`zxv?xzzz>w??r?}uxxxxxxxxxxxxxur?rxxxzzwwwwwwvvwwwwwwwwwwwwrrrw>>v>z??wwz? <br></br>
`zx?}xzzwvz?>?}xxxxxxxxxxxxxxz}rzrxzwuuxwvzzwvv?vrwwwwwrrrrwwwwz>^?}+vxxxr.<br></br>
`zz>}zzzzz}>^?xxxxxxxxxxxxxz}?vv?}ur?uxur>yw?+>>^>v>>>vrrrrwzzzxr`|?wzzxxr.<br></br>
`zx?vxzzzzx>-}xzzzzzzzzzxwv?}v||v???~xxuv|v+?>>++>^>?+-vrrrrwwz}?|+}rwzzxv <br></br>
`xxw|wzzxxx^-vxzzzzzzzzwvvrzz.  .+v?`wzx>~}z^^>?>^~+?^`vzwwrrrr?+>++>^wzx+.<br></br>
`zxur>zr}u}^|?xzzzzxz}vvwxzuy>    .?wv}v?z?^|||?>vrv?~}vvwxxwwv|>^>>^>?vv>|<br></br>
`xxxx}>w?v?z>^xzzxz}v}v^wyxxyw.    ~uuuuuu. ` `uyuxyx..>rvvwuzrr>-}rrv+v^r|<br></br>
-uuuuyv+r}wz+-wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyuzyu-  .>}vvwxz+.?www}^vz~<br></br>
-uuuuuyzvvv+>-v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu^~??v?+vww~<br></br>
-uuuuuuuuwv}w~??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?z?x^vuz}vwwww~<br></br>
`zzzzzzzzzxxx?>rvz.   `^?}}vv}zuyu+wzxuuxw?vyyuzv?>?rv-.   .z?rz+yuuuuxxxx|<br></br>
`zzzzzzzxxxxxx>w?u^~>vvvvv}zuuuuy>?yuuuuuyx~}yuuyyxrv??+>|`|x>rv+zzzxuuuuu|<br></br>
`xxxxxxxxxxxxu?r?}r}vvrzxxxxxxxu?|uuuuuuuuuz~vyuuuuyy?+>++?~>?w??wwwwwwzuu|<br></br>
`xxxxxxxxxxxxxr?wvwzxzzzxxxxxxx?-zxxxxuuuuuyz~vyuuuuu>?vvv???zu?vuuuzrrrrx|<br></br>
`xxxxxxxxxxxxxz>wwrwxzzzzxxxxuv-wxxxxxxxxxuuux>?uuuuuuuyyuuuuxx+}zwwrrrrrw~<br></br>
`xuxxxxxzxzzxxx?>>+????zxxxxu}~wuxxxxxxxxzxxxxxv?zuuuuuuvxuuuuu+xuuxxzzzwz~<br></br>
`zxuuuxxxxxxxxxv^+>^^?-?zxxx}|rzwwzxxxxxxxxwwzxuxv}xuuw?vuuuuux^uuuuuuuuuy^<br></br>
-uuuuuxxxzxxxzxz+?}}}vvv??vv>wxv>>???wzzzzzzzzxuuuzvvvvxyuuuuyr>yuuuuuuuuy^<br></br>
`xxxxxxxzxxzzwwz>zxxxxxxzw}rzxxv+?>?+wxxxxxxxuuuuuuuxuuuuuuuuyv?yuuuuuuuuy^<br></br>
`wwwwwrrrrr}}}}}>vrwwzzzwwr}}}r}}rwrwzzwwwzxxxxxxxxxxxxxxxxxxx>?xxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwwwwwwwwrrww?`vwvzwrzzxxzxzzwwwrwr?>??>>?}wvvwwwzxxzzzrzzw}ww}`>zzwwwwww~<br></br>
`zxxxxxxxxxxu?~wuuzuuxxuuur?wxzzzzzxxxxxxxxxzxzxxxxw?}uxzxxrzzww}`vxxxzzzx|<br></br>
-uuuuuxxxxxu?~zxxxxrxzrzzzzv|?rzzzzzzzzzzzzzzzzxz}>~?xxxxzwrzwrwz}`}zxxzzz|<br></br>
`zzzzzzzzzz>|zzzzzzwrzzzzzzx}|~>vzxxxxxxxzzxxz}?^-+wxxxxxzwzwwwzzz?^zwzxzz|<br></br>
-xxxwvr}?v?-wxxxzzzzwzzzzzzzxz>-~^>v}}v?}rw}?^~~^vzzzzwr}rwrwwwwwww+rwzxzz|<br></br>
-uuuw^>>^+?>?>?rxxzzzzzzzzzzzzzv^~||||^|^|^|~|+?rwzxzwwv^??+?v???rw+zxxxxx|<br></br>
`xxux>+>+>>^v}~|v}xvw?zzzwzzzwwwwv^~~^>+^||^>vwzzxuzvww?+>+++^>v|~v~^?zzzx|<br></br>
`zzxz|^>+||~??`|v>z^?-}xzr}wzzzwwwwv^rzwv^vrwwwzxxrv}w}|+>++^~>?^`?>w+^wxx|<br></br>
`zxxw-|?vvr>?>?rw++>-vvxzzrvv}wzzwww?}}}v?wrwww}vvvrww+`++^|+?++|???zx?~vw~<br></br>
`zxxx?>zxxxvr?www?^vvzzzzzzwv?vzr}vv???v>?v}}rwvvrwwwr}v}}wr|rwwr>+vrwzv~r~<br></br>
`zzx}>xxxxx>+www??wwzxxzxxzzzr}??v??rzzzxr??vvvzxxxxxxxxxuuxw>?wwv-?wwrw?>|<br></br>
`zzz>wxxxr?>?ww?vxxzzzzxxxuuuuu^zxxuuxxxxxxxxz?zzzzzzzzzzzwwww>>wv>?>rrrw+`<br></br>
`zxv?xzzw>w??r?}uxxxxxxxxxxxxxur?rxxxzzwwwwwwvvwwwwwwwwwwwwrrrw>>v>z??wwz? <br></br>
`zx?}xzzwvz?>?}xxxxxxxxxxxxxxz}rzrxzwuuuwvzzwrv??wwwwwwwrrrwwwwz>^?}+vxxxr.<br></br>
`zz>}zzzzz}>^?xxxxxxxxxxxxxz}?vv?}ur?uxur>yw>?>v>>vvvv}rrrrwzzzxr`|?wzzxxr.<br></br>
`zx?vxzzzzx>-}xzzzzzzzzzxwv?}v||v???~xxuv|v+}v+>+^>+>+~?rrrrwwwvv|+}wzzzxv <br></br>
`xxw|wzzxxx^-vxzzzzzzzzwvvrzz.  .+v?`wzx>~}w?+>>>>|^vv-~wzwrrrv++>>>|vzzx+.<br></br>
`zxur>zr}u}^|?xzzzzxz}vvwxzuy>    .?wv}v?z?.`-+>+^?++~|?vwxxzw+++^>>|v?vv>|<br></br>
`xxxx}>w?v?z>^xzzxz}v}v^wyxxyw.    ~uuuuuu. ^~`rrwzyz..>}vvwuz}r>-}rrv^v^r|<br></br>
-uyuuy}+r}wz+-wxzvv}v~  >yuzyy| .~>}v?v}vrv+`  wyyzyy-  .>}vvwxz+.>www}^vz~<br></br>
-uuuuuyzvvv+>-v??}v-    .xyzxu}?rrr?|vzz?|vvv?+}yyxyy^    .+v?vu^~??v?+vww~<br></br>
-uuuuuuuuwv}w~??zv       ?uxzrvv}zzvvzyyz>ruwv??vwzuy?      ?w?x^vuz}vwwww~<br></br>
`zzzzzzzzzxxxv>rvz.   `^?}}vvrzuyu+wzxuuxw?vyyuzv?>vwv`.   .z?rz+yuuuuxxxx|<br></br>
`zzzzzzxxxxxxx>w?u^~>vvvvv}zuuuuy>?yuuuuuyx~}yuuyyxwv?>>>~`^u>rv+zzzxuuuxu|<br></br>
`xxxxxxxxxxxxu?r?}r}v}rzxxxxxxxu?|uuuuuuuuuz~vyuuuuyv^>+^>|>v?w??wwwrwwzxu|<br></br>
`xxxxxxxxxxxxxr?wvwzxxzzxxxxxxx?-zxxxxuuuuuyz~vyuuuyv>v}}?vv?zu?vuuuzrrrrx|<br></br>
`xxxxxxxxxxxxxz>rr}rzzzzzxxxxuv-wxxxxxxxxxxuux>?uuuuuuyyyuuuuxx+}zwwrrrrrw~<br></br>
`xuxxxxxzxzzxzx??>+???>rxxxxu}~wuxxxxxxxxzxxxxxv?zuuuuuuvxuuuuu+xuuxxxzzzz~<br></br>
`zxuuuxxxxxxxxxv^^>^^?~>zxxx}|}wrrzzxxxxxxxwwzxuxv}xuuw?vuuuuux^uuuuuuuuuy^<br></br>
-uuuuuxxxzxxxzxw+vrwrv}v??vv>wx?+>?>?zzzzzzzzzxuuuzvvvvxyuuuuyr+yuuuuuuuuy^<br></br>
`xxxxxxxxxxzzwwz>zxzxxxxzw}rzxx>?????xxxxxxxxuuuuuuuuuuuuuuuuyv?yuuuuuuuuy^<br></br>
`rrrrrrr}}}}}}}}+?rrrwzwrrr}}}r}rwwwzwwwwwzzzzzzzzzzxxzzzzzzzx>?xxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrwwrrrwv`?wwrww}zxxzwzzwwwrwr}?>??>+>vvv}wwzzzzzzzwwww}rr+-wzwwwwww~<br></br>
`zzzzzzzzzzzxv~vuuuxxuxxuux}?rzzzzzzzxxxxxxxxxxzxxxxz}rxxzxzwwzww|+xxzzzzz|<br></br>
-uuuxxxxxxxxv~rxxxxxrzzwzzzzv|>}zzzzzzzzzzzzzzzzzzr?^?zxzzzwrwwwzw->zzzzzz|<br></br>
`zzzzwwzzzzv`rxzzzzzwrzzzzzzz}|~>vzxzzzxzzzzzzz}?+~^}xxxxzzrwwwwwz}`vwwzzz|<br></br>
-xxzwrww}rr`vxxzzzzzzwzzzzzzzzw+-~+?v}v?vrwwv?^~~|?wzzwrrrwwwwwwwww>>wwzzz|<br></br>
-uuuw+??++v>?v}zzzzzzzzzzzzzzzzz?|~|||^||^^^|~|+?rzxzzwv>v??v}vvrwwv>xxxzz|<br></br>
`xxxz>+>+>?+??~+v?wr???xzwwzzwwwwr?|~|++^^||^?vwzxxur}r?^>+^+>+?|>r?|rzzzz~<br></br>
`zzzz>++>+|~vr|`vv>v|^>zzzv}wzzwwwwr??zwr+?vrzwzxxz}?rr>+++>+|??+.?>+|?zxx|<br></br>
`zxxz`~>>>v>+|^vw}~^>?rwzzz}vvrzzwww}?}v}>rrwwwrv}vvrw>.+++|+^+^-^}+ww^^rw~<br></br>
`zzxz?vzzxx}??www??vwzzzzzzzrv?wrvv??>???+v}rrw}vvrwww??vv?v~vr}}>v+wrw>|w~<br></br>
`zzxv?xxxxr>|rwr??wwzzzzzzzzzw}??vvv}zzzzwv?vvvzzxxzzxxxxuuuv>}wwv->wwrw+?|<br></br>
`zzw+zzzx?>v>rr>rxzzzzzzxxxuxxu>vuxuuxxxxxxxxx?rzzzzzzzzzwwwz}+?wr|>?wrrr^`<br></br>
`zzv?xzzz>xv>}?wxxxxxxxxzzzzxxuz?}xxxxzwwwwwwv?wwwwwwwwwwrrrrwr^?}+r^?wwz? <br></br>
`zz?vzzzzrv?+>wxxxxxxxxxxxxxxzvvzwxxruuxzvzwz}v?vwwwwwwrrrrwwwzw^?>w?+xzxw.<br></br>
`zz>vzzzzzw>-?xzzzzzzzzzxxxwv?}r?vxw>uxuw>uz?>?v??}rrrrrrrrwwzzx}-+?>}zzzw.<br></br>
`zxv^zzzzzz>-}zzzzzzzzzzz}vvr>`-vv??~xxuv|v+vw+>?++?>>+?rrrrrw}vv|~vzzzzzr.<br></br>
`xxx>?xwzxr~~vzzzzzzzzwv?vzux.   |v?`wzx>~}z>>+>++>^??>`vwwrrr?++>>++wzzz? <br></br>
`zxxx?vr?z?v|?xzzzxz}vv}xuxuy+    .vwv}v?z?..-^>?+^~+?|-?zxzzv|>+^>^^w}rw^-<br></br>
`xxxzz^vv?ru^>xxxwvvvv^`zyxxy}     +yuuuuz  ||+v??vw?.`vv?rxxw}r+~}}?v|v>v|<br></br>
-uuuuuz??}r?||zwv?}?~   ?yuzyu` `^?r?v}vvw?~..`uyuxyz  .+vvvzxxz|-}rwv?>>z~<br></br>
`uxuuuuuzv???-}+w?`     -uyzuu}vr}r>|wzw|>v??>|xyuxyx.   .>v?vxx-->vr}>>ww~<br></br>
-uuuuuxuuxzz}-v?u^      .wuzrvvvwxv}vuuyv?xz}??vwxxyu`     .r?}w`?w???vwww~<br></br>
`zzzzzzzzzzzx???wv  .~>vvv?vrzuuyv>uwuuurw>uyuxv>>?rz|.    `x>zv?uuuzxxxzz|<br></br>
`wzzzzzzzzxxxw>}?z>?vvvvv}wxxuuur~zuuuuuuy?+uuuyuzrv>>>>^~.?w>w+?zzzxxxuxx|<br></br>
`xxxxxxxxxxxxz>z+v}}}wzxxxzzzzx}`vuuuuuuuuu++uuuuuyz^>+^>>~x?vw|vwrrrrwzxu|<br></br>
`xxxxxxxxxxxxx?wwzxxxzzzxxzzzx}`vxxxxxuuuuuu++xuuuuz>}rwv?>??uw^xuuuzrr}rz|<br></br>
`xxxxxxxxxxxxu?vr}vvwwwwzzxxx}-vxxzzzzzzxxxuu>?xuuuuuuuyyuuzxxv+zwwwrrrr}r~<br></br>
`xxxxzxzzzzzxxv?v++>?>+vxxxur~}uxxxxxxxxxzzxxxv?zuuuuuuzwuuuuy?vuuxxxzzzww~<br></br>
`zxuuuxxzzzxxxr>?|>++>|>zzxv^vw}vrrwzxxxxxxwwzxwvrxuuu}>zuuuuy>ruuuuuuuuuu^<br></br>
-uuuuuxxxzxzxxz?vvwwwrr}????wx}+++>^vzzzzzzzzzxuuwv}}v}xuuuuux^xuuuuuuuuuu^<br></br>
`xxxxxxxxxxzzwz?}zzzzxzzzwwwzxr>vvv?wxxxxxxxxxuuuuuzzxuuuuuuur>uuuuuuuuuuu^<br></br>
`rrrrrrrr}}}}}}>?v}r}wwwrrr}}}rrwwwzzwwwwwzzzzxzxxxxxxxxxxxxx??zxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}r?`?rrrr}wrrzwwvvzwr}}rrrv>>>?>^~^?vrwwwwwwwww}}r}v>`rrr}}rr~<br></br>
`wwwwwwwwwzzzx>|wuuuuuwuuwxzzz>>wzzzzzzzzzzwwwzzwrzxxxxxxxxwrzwr}w|+xzzzzz|<br></br>
-uuxxxxxzzzzx+|zxzzzzzwwzwwwwww^~?wzzzzzzzzzzzzzzzzxxxr?}zzrwwr}wwr`?zzzzz|<br></br>
`zzwwwwwwwwzv`wzzzzzzzzrzzzzzzzw+~^?wzzzzzzzzzzzzzwr?^|vzzzzwr}wwwwv`}wzzz~<br></br>
`xxzzwwwwwrv^>zzzzzwzwzzzzzzzzzzw>~||?v}vv}zzzw}?>|~~?wwwwwwr}wwwwrw^|zzzz~<br></br>
-uuuz?vv>>>v??wzz}??x?}?zzzwzzzwwz?~||||^|^+>+|~-~+vzzwv?v}v}rrrwwww}-wzzz~<br></br>
`zzzz>^>++v?+>^?vvr+r|>|rzz}rwwwwrw}>~~^+^^|^||+?rxxzwrv^>>^>?>>+vrww+}zww~<br></br>
`zzzz?+>>>^|?v>.vww||>^}rzzwvv}wwwwwwv+rrr?|?v}zxxxzw}v?+>+++^?v+`vzz+zzzz|<br></br>
`zzzz~->>^>^^+~+}w??vwwzzzzzwv?vwwwwwr?r}r>}wwwwwrr}vv?`>>>^||>+~-vwv-?zwr~<br></br>
`zzzw+>}rw}?>>}wv>vwwzzwwwzzzwv?rvv???>???+rrrrr}?vv}r+|??+^v^^?>>ww+?^|rz~<br></br>
`zzz?}zzxr>w>?w??wwwwzzzzzzzzwzr+v}vvrzzzwv??vvzrrwzzzzwxzv?r.vww??r^rr>~w|<br></br>
`zzr+zzzz}vx+??vxxzzzzzzzxxxxxxx+wxxxxxzzzxzrw}?zzzzwwzzwwwww+vwww+>?wrw>^|<br></br>
`wzv?zwwwzr?^+}xxxxxxxzzzzzzzxxxw?wxxzzwwwwwwwv?wwwwwwwwrrrrrr>?ww?`vwrww|.<br></br>
`zzv+zwwwwzv`?xzxxxxxzzzzzxxzv??vxxx}xxxx}www}v}wwwwwwwrrrrwwww+?w??+vzzzv <br></br>
`zzz^vzwwwz+`vzzzzzzzzzzzzrvvvv}v>wz+xxxx>uuw???>vwwwwwrrrrrwzzz^v+}}^wzzw.<br></br>
`zzzw>wv}z?^~vzzzzzzzzw}vv}wr`  >}>>~xzxv|r>>}?>?>>vv??vrrrrrrvr?+>r?>zzzz`<br></br>
`xxzzv>r?vvr-vzzzzzw}vv}zxzux.   `v?~wzx||vr}?>+>++>+?>-?wwrrv+++>>|?wwwzw.<br></br>
`zzzzx??rrxv`vzzw}vv}v+?yuzyy~     wrvv??w?- `^>>>>|^?v`|wzzw^+>+^^|vwrwzv <br></br>
`xxzzzzv?v>?-vzv?}v>`  -yyzuy+    .wuyuuu>  .-~?>++?||+^vxzxz}}}|^?+rrrww|.<br></br>
`uuuuuxxzv}r-v?rv~     .xyxuyv`^?v}?>v}vz?` .^vxzrzy|.?wv}xxxxxr`>r+v}?w>?|<br></br>
`xxxuuuuuuuw^??x>       }uxzzr}}}r?^}zw++vv?~ ryyxyy-  -v}?wuxuv`}wr?^>?>w~<br></br>
-xxxxxxxxxzzv??wv  .-^?vr}vvv}wxuvrvuuyv>xwv??wyuxyy-    +r?vxu^.vxzz}>>rr~<br></br>
`zzzzzwwzzzzv?vvz?vvvvvvv}wxuuuu>?uwuuu}}?yuzv>?rzuz-`.   .w?vw`>?vw}>vzzz|<br></br>
`wwzzzzzzzzx}?r>}}}rwzzxxxzzxux+?uuuuuuuu|ruuuu}??v?>>>^|.|x>}>+}wv?vzuxxx|<br></br>
`xxxxxxxxxxxr?xzzzzxxzzzzzzzxr~?xxxuuuuuu}-xuuuuuxr++++^|-rv?r^}rrrrrrrwzu|<br></br>
`xxxxxxxxxxxw?xzzxxzxzzzzzzxv-vxzzzzxxxuuy?|uuuuuuu?vv>?v}w?u}>uxxxxzr}}}w|<br></br>
`xxxzzxxxxxxw?xz}v??r}}wwzx?~rxzzzzzzzzzzxu>>uuuuuuuuuxv?v?wu>vzzwwwrr}}}r~<br></br>
`xxxzzzzzzzzz>zz}+++>?+?zz?+zxxxxxxxxxxxzzzx>?uxxuuuuuuuuzzux^uuuxxxzzwwww~<br></br>
`zzxuxxzzzzzz?wzv|?>>>++v>vzzzv??}}rzzzzzzzww??zuuuuurwuuuuuv?uuuuuuuuuuuu|<br></br>
-uxuuuxxzzzzz>wzw}wzzwwr}wxzzx?+++>^wzzzzzzzzzrvwuuz??xuuuuu+wuuuuuuuuuuuu^<br></br>
`zxxxxzzzzzzz>}wzzwzzzzzwwwwwzvv}}v}zzzzzzzzxxuxvvvvzuuuuuuw+uuuuuuuuuuuuu^<br></br>
`wwrrrrrrrr}}?>>>?v}}rwwrrr}v}rwwwwzzwwwwwzzzzzxxzzxxxzzxxx??xzzzzzzzzzxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}r?`^}rrrrwwwwww}vwrvvrrrrv>?>>>^|^?v}rrwwwwwwr}}r}}?-vvr}}rr~<br></br>
`wwwwwwwwzzzzx?^?zuuuuzuuzxzzz??ww}rzzzzzzzwwwww}v}rzxxxxxzzwzwrrw^>vwzwwz~<br></br>
`xxxxxxxzzzxx>|}wxzzzzwwzwwwwww>+v}}rwzzzzzzzzzzzzzxzxwv}zzwwwr}wwr-?vzzww~<br></br>
`zwwwwwwwwwr?`r}wzzzzwwrzzzzzzzw?^>?v}wzzzzzzzzzzzwrv>>vzzwwwwrwwwwv-?}zzz~<br></br>
`xxzzwwwzwv?+>zrzzwrr}wwrrwzzzzzz?++^+?vvv}rwzwrv?>^+?rr}v}rr}rwwrrr>~?zzz~<br></br>
-uuuzv}}??>vv?wwwvvvw>}+}vwzzwwwwzv++||^||^^+>++^^+?}vvvv}rrrrwwwwwwr~?rzz~<br></br>
`zzzz>+>+^vv>>>vv}r>}|+|vwwrwwrrwwwr?+^^^^^|^||^?v}r}}}?+??+?v???rwww>?vzw~<br></br>
`zzzz?+>+>?^>v>`?ww^^?+rrzzw}rr}rrrww}?}v}?+>?v}rwwzw}v?^>+^+^>?||vwz?wvzz|<br></br>
`zzzz+|>>+||+?^`vr??vwwzzzzzw}}vrwwwwr?vvr?}}wwwwwwrvvv|>>>+^|??^.?w}|vvwr~<br></br>
`zzzz||??vv>>^?vv?vwwwwwwwzzzw}vrv????+>??+v}rrr}vv}rr^`>>+|?^^+|+rw>?+~ww~<br></br>
`zwz?vrzxr?r>>}?vwwwwzzzzzzzwwww>?vvv}wwzz}??vvzrrwwww}}wrv??-v}r??r>rv+~r|<br></br>
`zwr+}zzz}vx^??}xzzzzzzzzxxxxxxx>}zxxxxzzzzz}vv?wzwwwwzzwww}v+?}wr+?v}vv^+~<br></br>
`wz}?}wwwzr?-^rxzzzxxxzzzzzzxxzwrvrxxzzwwwwwwwv?}wwwwwwwrrrr}}^?ww?~vvrr?|.<br></br>
`ww}>rrw}zr>`?xzxxxxxzzxzzzzrv???wxxvzxxx}wwwrvvrwwwwwrrrrrwww}>vwv>+vzzw> <br></br>
`wzw>vvw}rr>-vzzzzzzzzzzwr}vv?>?v>rz^zxxx+zuw???>vwwwwrrrrrrwzwv+v??v+wzzv.<br></br>
`zzzw?}?}w?^-vzzzzwwwww}vvrxw.  ^}>+-xzxv|r>+v}????vr}v}rrrrr}}v??>v>>wwzr.<br></br>
`zzzzv?r???v`vzwwwzrv??}xxxyx.   `v?~wzz~|?}}>?^>>^+?>>^?rwwr?>+>>+^vvwwzw.<br></br>
`zzzzx??wrx?`vrwrv??>+~?yuxyu`    .zrvv??r?~ .>+>+>+|?r+.vzzv|>+^^|+}vwwzv <br></br>
`xxzzzz}?v?>~vw?+>>|.  |yuxyu|    `zuyuuu^   -`+>?^+|^+-^wzzr}}v^~>+v}rrw|.<br></br>
`xuuuuxxz}}v-??v>`     `xyxuuv-+?vv>?v}}r+.  +>}}?vr^.|}}}xxxxxr`^v>v}?r>+`<br></br>
`xxxuuuuuuu}+>vr^ ......}xzwwr}r}}?>rzv++?>^``wyyuyw`..~}rvzxxu?`v}w?+??>?~<br></br>
-xxxxxxxxxz}v+?wv .`~+?v}}v}rrzwzvr}uuzv?zrv>vzyuuu}` ...>rv}xx-`v}zz}?>vr~<br></br>
`zzzzzwwzzzr}?v}w?v}}vvv}rwxuxwz?vuzuxxvrvuzwv?vwxwv~`... `w?vv`?vv}v>vrzz|<br></br>
`wwwzzzzzzzrrvr?}}}rwzzzzzzzrrz>vuuuuuuwz+ruxzz}???>>?+|~`|z?v^+}wvvvrwxxx|<br></br>
`zzzzxxxxxzwwvxzzzxxzzzzzzrvrr^?xxxxuuuzwr|xuuzzxx?|+^^|~|w?v?|vv}}vv}rwzx|<br></br>
`zxxxxxxxxwww?xzzwzzxzzzzrvz}|vxzzzzxxxxrxv+uuuuxxv?}?|^v}rvwv?xzzzzzw}}}w|<br></br>
`zxxzxxxxxwzz?xzr??>}vv}vrzv+rzzzzzzzzzzw}x??uuuuuxzzxrv???ww?}zzwwwrr}}}}~<br></br>
`xxxzzzzzzrzw?zzr+>++>>^zz??zxzzzxxxxxxxx}rx?vxxuuuuzwxuzzzwz?uuuxxxzzwwww~<br></br>
`zzxuxxzzzrzz?ww}|???>+>v?vzzr?>?vvrzzzzzzrwwvvzuuuuurrzxuzx}vuuuuuuuuuuuu|<br></br>
-xxxuuxxzzwrw>rzwrwwwwwwrwzzzw^>+>+?zzzzwzwrwxrvwuxzvvxuuxru?zuuuuuuuuuuuu^<br></br>
`zzzzzzzzzzzz>v}rwwzzzwwwwwrwwvrwrrzzzzzzzzzwzuxvvv}zuuuuwxw?uuuuuuuuuuuuu^<br></br>
`wwwwrrrrrr}rv?>+?vvvrwwrrrvvvrrwwwwwwwwwwwzzwwwzzzxxzzzwrx?vxzzzzzzzzzzzx|<br></br>


            </div>,

            <div>

`v}}}}}}vv}}}}rr?`>^vwwwwwwwrrrwr?vrr?vrrrv>??+vvvvrrwwwwwwrrr}}rv}>??^}}}~<br></br>
`wwwwwwwzzzzzz}?|vvvuuuuuxzzzwwzzr?}z}?wzzzzwrv?>>??rwzzzzzzzzwwrrww^}^vww~<br></br>
`xxxzzzzzzzwv??>vzvwzzwr}wwrwwwwwzv?vr??rzzzzzzzzw}v??vrzzzzwwwrwwww?+?>ww~<br></br>
`wwwwwrwwwv>>v}?w}}w}v}}?wvvvrzwwwwv?????}zzzzzzzzzzzzzrwwwwwwrwwwrrr|?^rw~<br></br>
`xzzwwwwr?>vwzwvwvvv}vr?+?>v?wzwwzzw???|>?vrwzzzzwwwwr}v??vrwrrwwr}}r?^>?z~<br></br>
-xuxzrwr??}zzwv??>}ww??^|+?r}rww}wwww??>~^||+??vvvv?????v}rr}}rwrrrwww|?>w~<br></br>
`zzzz>??+^?}?>>??rwrv>>vvrrzwrv}vv}rwwv?>~^|^|||^+>>?????v???vvvvrrrrw?^|}~<br></br>
`wzww?^>++?>+?+-?rv?vrwwwwwwwzrvvvv}wwzv?v>?+|||^+>?>>??^>+^>>>>|?}rwzw+^v~<br></br>
`zzzz?^>>>|~?v>.>v}wzwwwwwwwwwwr}}}rrrrvvvvr?r?????vvv}++>+>^|}w^`vrwww>>?~<br></br>
`zzzz|`>>>??+|~+vrwwwwwwwwwwwwww}}?>>>>+>+?>vvvrrrrrrr^`>>>|+|>>`^}rrr}??>~<br></br>
`zww}??}r}wr>~+rzwwwwzzzzzzzwwwww??vr}}}rwr?+?wzzwrrrr>?vv?>?v????rrrr>v?>~<br></br>
`wwwvvvv}vrv^-vxzzzzzzzzzzxxxxxxxvvrxxxxzzzz}>>?rwr}rr}}rwwv>?^?rv?wwv>>+?~<br></br>
`wwwr?}?r}rv~^zzzzzzzxzzzzzwwrvvvrrwxzwwwwwwww}??rr}rrrrrrr}v^~?vv>vr?rv`v~<br></br>
`wwwwvvv?rv^`?xzxzzzwwwrr}}v}v??v>rurvxxzzwwwr}vv}wwwrrrrrr}??>>vr???}w>v+-<br></br>
`wwwwr?w?>v?-vwrrrrrr}v}rrwzz-. >v^z^}xxxv}xxz}?vwwwrrrrrrrr}z?|rwv~?w?v??.<br></br>
`zzwwzvvrwr+^vrrvv>^~-~wuuuyr    ?v-~xzxr|zr??vv?}rrwwrrrrrrrv??vr}+v?}z?>.<br></br>
`zzzwww}???^>vz^``-``.`zyxuyv     v?+zzw`+>>?vr+>>+^v?>>?rwwv+>>>?+>>+wwr^.<br></br>
`zzzzzzzzw}++v?--`.   `zyuuy+     >urv?>?}v?|+r+>++>^?}?`?zz^++++?~^>?wwz+ <br></br>
`xxzzzwwzz??>rv|......-wyxxuv`-~^+}xuyux?`   ?+^>?>^~+v>.?zzv}v?>++?^}rrw+ <br></br>
`xuuxxxzzrvv>?r?`````-~vwrrrrv}}v>^>v}wv`   `?~>??>+v?^>vzxxxxx}-|?r>v}}r^.<br></br>
`xxxxuuuurzvv?w?^>??v}rwrrrrww}}v?vzz>+?+`. |zxxuuw+-rwvwzxxxxu>->>??}}r?|~<br></br>
`xxxxxxxzvx?r?}rrrrr}rrrwwzxrvw}vzwuwvv?r?-+vuuuyz+```vzv}rwzu}`+}?rw}rv~?~<br></br>
`zzzwwwwv}z?wwrwzzzzzzzzzz}vrwv}uxxxwvr}w}}}rzuuy}|`^|.?zv}rr}`^vzw}?}}?>w~<br></br>
`wwwwzzwvzwvzzwvzzzzzzzwv?vwv?wuxxxxuvzvwx}}wv}xw?>>>?>^?wvv}~`+?}r}vv??wz~<br></br>
`zzzzzxr}zr}xzzvwzzzzwv?vz}>?zzzzxxxuvrr?uurvzz}|+^^||>^|w}v>-?v+??????rzx|<br></br>
`zzzzxxvwz}}zzzzv?ww}vvwzv?rzzzzzzzzxv?u?ruuz}wz|~~-`..^?rvv-?zxw??}vv}}}r~<br></br>
`zzzzzzvwzvrzzzzr>>>vvvv?vzzzzzzzzzzzr>zr>zxxxwrz?~``|?v}}v+?vwwwvv?v}vvv}-<br></br>
`zxxzzzz}v>}wwwwr>>+^+>~rzzzzzzwzzxzzx?wx?vxzzxz}zr+>vv}}vr?xz}rrrzzzwrrrr~<br></br>
`zzxxxxzzz?vv}wwr^?????vzzzzzv>>???wzzrvzzvrwwzzz}rzxrrwvw}rxxxxxuuxxxxxxx|<br></br>
-xxxxxxzzzr}vv?vrwwwwwwwwzzzz?+>>?^}zzzvrzwvrzzxxu}vrzu}rzvxxxxxxxxxxxxxxx|<br></br>
`zzzzzzzzzzzwrv???vrwwwrrrrrw}}zzwzzzzzzvzzz}rwww}rxxur}xvzuxxxxxxxxxxxxxx|<br></br>
`wwwwwwwrrrrrrr}v??>>vrw}}rvvv}}rwwwwwww}?}}}??}rzzzz}vz}vxzzzzzzzzzzzzzzz|<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}rv?^>?+}w?vwzzzwrwwrr}rr?vrr}?rrv?vv?vrrrrwwwwwrrrrrr}rr?^}?|-<br></br>
`wwwwwwwwwzzr?>?}w}}uw}xxwrrvrzwzwwzz}?rzr>rzzw}vv+?wwwzzzzzwzwwrrwwwr+vr|`<br></br>
`zzzzzzzwzw?>vrzzzvr}?vvv}rv?r?vvrwwww??rr>?wwzzzw}>>??rwwwwwwwwwwrrrwv>w+ <br></br>
`wwrrrrrrr>?}wwr}???>?rw}rv>+?>??wwwww}??}?>vwwwzzzzwvv??vrwwwwwwwrwrrr+}? <br></br>
`zzwwrrr}+vwrrw}?v??}wrv>?>?>?vvrwwrwzw???>>>vwwwwwwwww}???}rrrrrrrrrrr??v.<br></br>
`xxzzwwr??rrr}vv>>?}}vvvv}vrrwwwrwwv}wwr??>~+^?}wwwwzwwrrr}vvvrwwrrwwww}>v.<br></br>
`wwww??v>+?}v?vv|>vvv}rwwwwwwwwr}v}vv}wzr??||^|^>?}rr}vvvvv?v}rrrrrrrrw}|v-<br></br>
`wwww?^>+^??+>+|+?vrwwwwwwwwwwwwr}vvvvwwrv?v|||||||>???>^>>^??>>>vrrzzww>v~<br></br>
`wzwwv+>>>+|>??.+rzzwwwwwwwwwwwwwr}}v}rrrv}}?}?>^+^+>>?>+>+++^?v|~vrrrwz??-<br></br>
`wwww>`>>+++^+|~?wwwwwwwwwwwwwwwrr}v>>>+>>?+v?}r?>????+~>>>^||??`~vrrrrr?>~<br></br>
`wwrr?+vv>}}>~>wzwwwwwzzzzzzzwwwwwvv?wwrvvvv?>vvwwwwwr|^?>+>v?>>?vrrr}}r?>~<br></br>
`wwrrwvvr???^-}zzzzzwwwwwwwwwwww}}}}vzzzzzzzw?^vwwwrrr}}rwrrw}vrrrr}rrrr??`<br></br>
`wwrrr}?rrwv|>v}}}rrrrr}rr}}}}v??vvzzzwwwrrrrw?+?}rr}}r}}}}rv>vvvvvrrrr}?v.<br></br>
`wwrrrw}v}}^>?z}??>++^~?xzwzu?..~}+zxvrxzzwwwww}??r}v}}v}}r}??v>vrvrwww?}v.<br></br>
`wwwwwrw}v?>>}r`......`zyuuyz.  .v??>?zzxw}zzzwrv?rrrrrrrrrrrv|?v}vvzrvv?> <br></br>
`wwwwwwwwr???w|.....  +uuxyy+    ~w`^zzxw+}xwr}}}zzwwwwrrrrrv>>?vv?vwvrz?^-<br></br>
`zzzwwwww?}^r}v~-```.`vyuuy}.    .w??wz}`+?>??v?>??>}v??vwwr?>>>?>>?vrwv>+-<br></br>
`zzzzzzzvr}vv}v||||~||vxzxx}|-```>xur??+?vvv?rz>+>+^>+?>-?wv|>+^>+~+v}?|??`<br></br>
`xxzzzzvvzvw}x}???????}wr}}}vvv>>?ruuuxv+~`.~xv^>>>+~+r}`|w}vv>vvv??>>vv|?`<br></br>
`xuxxxrvzrvrvrrrrrrrrrrrwzzwrvv?????vz?.   .rx-~>>+^?++^+}zxxx?v}}>>|v}}^>-<br></br>
`xxxxzvxurzz}vrrrrwzwwzzzw}vvw}}wzu?>>?.. .?ux}rxr>r}zwrrwzxxx??v?|^?}}}|>~<br></br>
`xxxxvwzzvzz}?zwwwzzzzrv??vzrvrxzxrvr?}?.-?wyuuyxw-?zvzzwrrwzx>>+?v?wwr}~?~<br></br>
`wzzvvrrv}zzw?rzzzzrv??vww}vrxxxxxzvww}}vvxuuuyz>?^^}}?wzzwrrv-+?zvvzzzv^w~<br></br>
`wwwvvrrvwwwzr??}vv?vwzwv?vwzxxxxxw?uvzvvxwruyx|`+>>???>}wwwv-~?+??rrzr^rz~<br></br>
`zzzzrvv}wwwwzzvvwzzzwv?}zzzzzzzzxv?uvruvvxwrz^`.-++^+>^}ww?`~?v???vv?^?zx|<br></br>
`zzzzzz??}wwwwww}rwr}}}wzzzzzzzzzzv?x}vuxvvux|.`.``+~>?zrz?`|>}zwvvrv>>}vr~<br></br>
`zzzzzz}rvv}wwwww?>+????rwwzzzzzzzv?zr?zzz}}w-.``. -|+zzr?|?r+}w}vv???vvv}-<br></br>
`zxzzzzzrvv?v}wrr>+>^+?-}zzzzrrrzzvvzxvwzzz}}}-...~>??zv>~}zxv??}}v?rwrrrr~<br></br>
`zzzxxzzzzw}v??}r>??vv?vwzzzw>+>??>vzzrvzzzw?vw~-?vv}rv>??wxxzvvv}wzxxxxxx|<br></br>
-xxxxxxzwwwwwrv??vrwrrrwwwzzw+??????wzzvrzwwz?vzwwvr}?wvwzvr}vv}wxxzzzzzxx|<br></br>
`zzzzzzzzwzzzzzwv???}rrrrrrrrrwzzzzv}zzzvzzzzz?vrzurvzrrxxzwwzxxxzzzzzzzxx|<br></br>
`rrrrrrr}}}}}}}}}}v?>?v}vvvvvv}}rrwr>rwr?+?}v}rwzz?vz}vzzzzzzwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}?^?v}}}}vv}v?}vvvvv?vrrrr}r}vrrr}?r}}rwrrrwwwrrr}}}}}}}vvv|``<br></br>
`wwwwwwwwwwwr>?rwwzz}???>+v}}rrw?vrv}rwwww}wwwv>zz}}rrrwwzzwwwwwwwwwww}}}+.<br></br>
`zzwwwwwwwwr?>wrrrr}?v??vrwwrr}?|?+?>vwwwrv}ww?+wzwrrr?vwrwwwwwwwwwrwwwr}v`<br></br>
`wrr}r}rr}v}+v}}vvvv>>?vvvv???????>?vwwwww}vrw>^}wwzwwv~v}}rwwwwwwrrrrrrrv.<br></br>
`zwwrr}}}v}??vv}v}v^^?vvv}rrrrrrwrw}www}ww}vv}++?wwwwwwv+?vv}}rrrrrrrr}rwr`<br></br>
`zzzzwwr}v}^v>vv?+~^?}rwwwwwwwwwwwrrrw}vww}vvv|+^rwwzwrr}?>>vvv}wwwwwwwwwr-<br></br>
`wwwwv}r??v+}???>~+}wwwwwwwwwwrrrrrr}rvv}r}vvv|^~>wzzwr}}rv?++}rrrrrrr}rrr~<br></br>
`rrrw?+>+|???>>>^?wwwwwwwwwwwwwwwrr}}rv?v}}}vv^|^|>v}rr>>??>v???v}rrwwwwww~<br></br>
`wwwwv+>++?++v?`+rwwwwwwwwwwwwwwwwrr}rvv}rr}}w?~^^^~|?v>+>+^>^??~+}rrwwwwr~<br></br>
`wwwz?->>>||^>>`^v}}rrrwwwwwwwwwrrrr}v>>>>?vvv?vv>|+|^?^>>>+|^}}-`vrrrrwww~<br></br>
`wwww>`???vv++^vrvvv}vv}vvvvvv}}}}}rr?vwrv>>>>v}ww>^+?`->++^>^^^|?rrrrrrrr-<br></br>
`wrrrr}rzwr???rz|``-~|^^vxzwwr}vvvv?zvrzzzzwrv+???}v?vv?>>>>+++>>?vrrr}}}}-<br></br>
`wwwwwwwwr?v?}}`  .... -zuxxuu>.`+w>zzwrrrrrrrv|?}rrrrrr}}}}}}}}}rrr}}rrrr-<br></br>
`rrrrwww}?}>vx?-..    -zyuxyy>   ~w+z}vwzwwwwrw>+vrrr}}rrrrrwwrrwwwwwwwwwr`<br></br>
`wrrrwwv?rrvv}v???+|`-ryuuyu+    `w^+>rzzxzzzzwwv>}rvvv}}}vvrr}rwwwwrrrww}`<br></br>
`wwwww?vwr}rwzv??>>??vwxxuu+     `z-?zzzzvvzzzzzwvww}}}vvvvvvvv}}rrwwwwww}-<br></br>
`wzzw?vww}wrwwrr}}}v??}}vrwv>|-.`vx?vwz?|?rrrr}?>vv?}w}vrwwv>>>??>vwwwwrrr-<br></br>
`wzr?}wwrwz}}wrrrrrwwwwwrr}}vvv?vxuu}?>^??>>??v?^>+^>>>>|?w+++++?~?}rr}v}?.<br></br>
`xv?rrr}rww>wwwwwwwwwzzzwrvvv?>>+?zuuz??????wxz>+++>^|}w|`v?>??>>??vvv}}?~.<br></br>
`xv}zzr}www>rwwwwzzwr}v???vrwv}ww}|}r`    `vuu>`>>>|+|++->wzz?|?rv?v}}}?~?~<br></br>
`xz?vzrzzxx}?}}}v???>?v}rwzzzxxxz???}`.  |wyyx?>vv??rw}v}wzxr>}ww??}}?+>>v-<br></br>
-xzxv?rwwwww}????vrwzzzwrrwzxxxz}?zvv? .?zyuuuu}?xwzwzzzwrrwv?rwr>rv??-?v}~<br></br>
`wwwwv>vvv}rwrwzzzzwwrrrwwzxxxxx?vxz?r+ruuuxuuww>}?v}v}wzzwr>vrw????ww~?rw~<br></br>
`wrrrr}>v}}rrrrrwrrrwwzzzzzzzzxr^zzwr>xuxxuyx^|r>>+>>?+?rwwr+}v?~>vrwr~vzz~<br></br>
`zzzzwwr?vrrrrwwwwwzzzwzzzzwzzz+?xwrx?vxzxy}` .}>^>>>>^vzww>>?>?+v}}}?~vwz|<br></br>
`zzzzzzwwv?}r}rrwrrvrzwwwzwwzzw~rzr}zw+zux>.   v??}zwwzxxz?->?}?>wzzw|?vv}~<br></br>
`zzwwzzzww}?vrr}}?>+???>}wwwwzv>zwrrwzvvz~ ..  ???rzzzxxv~-+????}rr}+>vvvv-<br></br>
`zzzzwwwwwwr??vrr>+>^^?-vwwzr}?vrrwrzzz?w~ ... ^??wzxwv|~+?zzwwrwwr+?wr}}}~<br></br>
`zzzxzzwwwwwwv>?}??vvvv}wzzzv++>?+?rzwz}?w.  .~???xzv^~?w+vzwwrrwv>vzzzzzx|<br></br>
-xzzzzzwwwwwwzwv?vr}}}}rwwzzv>????}rwwww>z?`>v}vvrv+~^rzw?}}rrrv??rzzzzzzx|<br></br>
`zzzzzwwwwwwwwwwwv?v}}r}}}rrrwvvxzzwwzzz}>xwrv}rv????vzzw?>v}v??rzzzzzzzzx|<br></br>
`}rrrr}}}}}}}}}}}}v>>?vvvvvvvvv>????>vwrr?>vrzv>vwrrv?rr}?>??}wwwwwrrrrrww~<br></br>


            </div>,

            <div>

`vvvvvvvvvvvv}?^?vv}}}vv}v?vvv??v?v}v}}v}}}}}}}?}}}}}rrrrrr}}}vv}vv}vvvv~..<br></br>
`wwrrrwrwwwwr>?rrwzz}???>+v}}rrw?vrv}rwwwwwwwwv+zzwwr}rwwzwwwwwrwwwwwwr}}>.<br></br>
`zwwwwwwwwww?>wrrrr}?}?>?rwrrr}?~?^>+vrrrrwwww?^wwwwww>vrrrwwwwwwwwrwwwr}}-<br></br>
`wrr}}}}}}}}+v}vvv}v>>>vvvv??vv???>??wwrrwwwww>|}wwwwz}|vrrrwwwwwwrrrrrr}v`<br></br>
`zwwrr}}}}r??vv}}}v^~>?v}rrwwwwwwrw}wwwvwwrrwr^^?wr}wwwv>?vvv}rrrrrrr}}rwr-<br></br>
`zzzzwwrrr}^v>vv?+~|?rwwwwrwwrrrrrrrrw}vwwrrrv|+^}}rwwwr}?+>vvv}rrrrrwwwrw-<br></br>
`wwwwrww}vv+}???>~+}wwwwwwwwrrrrrrrrrrvv}r}}r}|+|>wwzwrrrr}>^+v}rrrrrr}rrr~<br></br>
`rrrr?>?>^>?v???|?wwwwwwwwwwwwwwrrrrrrv?v}}}}}^^+|>?}rr>?v??}??v}rrrwwwwww~<br></br>
`wwwwv+>>^??+?>->rwwwwwwwwwwwwwwwrrrrrv?}rr}}w?~^^^~|v}++>+|?+>>|?rrrwwwwr~<br></br>
`wwwwv^>>?^~^??-~vvvv}}rrwwwwwwwrrrrr}>>>>?vvv?vv>|^|^?+>>+>|+rr`~vrrrrrww~<br></br>
`wwww?.>?+>?|^|+vvvvvvvvv?vvvv}}}}}rw?vwrv>>>>vrww>~|+`~>>>^+|>^`>}rrrrrrr-<br></br>
`wrrrv?vwww???}z~```-~||vxxzwwrvvv??z?}zzzzw}v>???}v??+>>^^+>+^^>?vrrr}}}}-<br></br>
`wrrrrrrwr?v?rr`       -zyuxuu+..^w+zzwrrrrrrrv|?}rrrrr}}}}vvv}}}}rr}}rrrr~<br></br>
`rrrrrrw}?}>vx?-..    ~xyuuyu+   `w+zrvrzwwwwrw>+vrrwrrr}}}}}rrrrwwwwwwwww~<br></br>
`wrrrwwv?rw}}v}vvv?+-~zyuuyu^    `w^++rzzzzzzzww?>}rvvv}}}vvrrrrwwwwrrrwww~<br></br>
`wwwww?vwrrwwwr}}vvv}wzxzuu^     `z-?zzzzvvwzzwzw?ww}}}vvvvv}}rrwwwwwwwwww~<br></br>
`wzzr?vzwwwwrrrrwwwr}vvvv}rv?>|`~}x?vww?|?rwwwr}?vw}rzwrwwr?>>??>?rwwwwwrw-<br></br>
`wz}?}wzwwz}}wwwwwwwwwwwr}}}}vvv}uuuv?>^??>>>?v?^>>^+?>>+vv^>+^?+^}rrrwrr?.<br></br>
`xv?}}}rwww>rwwrrrrwwwwwww}v??>+>?xuuw?????vzxz?+>++>|?r+`>?>??>>>v}}}rrv~.<br></br>
`xvvzwrrwww+}wwwwzzwr}v?>?vrwvrwwv|r}.    `ruuv-+>?+^~>?~-}zz?|?rv?r}}}?|?~<br></br>
`xz?vzwwzzx}>}}}v??>>??vrzxxxxxzz???}.   +xyyx>|??>>vv???}zx}>rww?vw}?+>?v-<br></br>
`zzxv?rwwrrw}????vrwwzzzzwzxxxxz}?zv?? .?yyuxxzv?zwwzzzzwrrr??wwr>wv?>-?vv-<br></br>
`wwwwv>vvv}}wwwzzzwwwwwwzwzzzzzx?vxx>}+ryyuxyuwr?v?}vv}rzzwr>vww????rw~?rw~<br></br>
`rrrrw}>v}}}}rwwwwwwwwwwwzzzzzxr|zzxr>xuuxuyx^|r>>>>?>>}rwwr+r}?~>vwwr-vzz~<br></br>
`zwwwwww??rr}rwwwwwzzwwwzzzwwwz+?xzzx>vxuuyv` `}^?>>>+?zwww>>?>?^v}vv>|}wz|<br></br>
`zzzzzzzwv?vr}rrw}}v}wrwwzwwwzw~rzwwzw+zxx>   .r?}zxzzxxxz?~>?}?>wzzr|?vv}~<br></br>
`zzwwwwwwz}?vrr}}?>+>??+vwwwwzv+zwwwww??r-  .  }vvxzzzxx?~~+??>?}rr}+?vvvv-<br></br>
`zzzwwwwwwwr??vrr>^>++?~vwwwvv>v}rwwzww>}`  .  ?vvxzxzv|~>>wwwzzzzw+?wr}}}~<br></br>
`wwzxzzwwwwwwv>?v??vv}}rwwww>+>+?|rwwwrv?}.   -vv?xzv|~?w>vwwwwwwv>vzzzzzx|<br></br>
-xzzzzzwwwwwwzwv?vr}}}rrwrwz????vvwwwwrr+w?`+vrvvrv^`+rwr?r}wzw}>>rzzzwzzz|<br></br>
`wwwwwwwwwwwwwwwwv?vr}r}rrrrrw?vxzzzwzzz}>xwrv}rv?>??vwww?>v}v??}zzzzzzzzx|<br></br>
`rrrrr}rrr}}}}}}}}v??vv}vv}}vvv>?????}www?>v}zv>vzwwv?rr}?>??vrzwwwwwwwwww~<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvv?^v??v?v?vvv>^+?vv}}}v>vvvvvv}}vv}}}}}}}}}}}}vvvvvvvv??^.+-<br></br>
`wwrrrrrrrrrrrw}?}rvr}}r?v>>?v}rrrrrv?}vvv}rrwwwwwwwrwwzzzwwwwwrr}}}}}}}?`.<br></br>
`zwwwwwrrrrrrr}>v??vvvv>~|>?vvvvvvv?^?+>+>vvv}rwwww?vwwwwwwwwwwrrrrr}}}v}v`<br></br>
`wrr}}}}}}}}}}??v?v?+~-^?v}rrrwwrrrrrvv??}vvv}rwwwv^rwwwr}}rrwwwrrrrrr}}}}-<br></br>
`zwwrr}}}}}}}r??vvv>|^vrwwrrrwwwwwwwwwrrrrr}rrrwwr>+rwrvvr>vv}}rrrr}}rrrr}-<br></br>
`zzzzzwr}}}}}}v??>^^?rrrrwwrrrrrrrrrrrrrwrv}r}}}r?++rwv?}r?+vvv}rrrrrwwwwr-<br></br>
`wwwwwzzwrrr}}vv>+vwwrrrrrrrrrrrrrrrrrrwwv?v}vv}r>>+wv?v}}}+?vv}rrrrrrrrrw~<br></br>
`wwwwv?v?+?v}v?+?vv?>>??vrrrrrrrrrrrrrrr}v?vvv}wr^>+??v?v}vv??v}}}rrwwwwww~<br></br>
`wwwwv^+>^>v>>>|?}>+>>+^^>?vrwrrrrrrrr}}}?vvv}rrv^>+|?v^>>+^?>>>>v}}}rrwwr~<br></br>
`wwww}+>>>>|^vv|-^...`-|^+?v}r}}}}}}}}}}???v}}}rv^>^|^?+>+^>^>v?`+vr}}}rrr-<br></br>
`wr}}?`+>+^+|^^-~.....  .>uyxzzwrv?vv}r?v}v>+v}vv}v|^>~^>>>^~+?>.+}rrrr}}r~<br></br>
`wr}rv^>?vrrvv?vv+`....-}yyuuyuv~~+v?r}vrwzw?++?v}}?|?-+++^?v?>>>vr}rrrrrr~<br></br>
`wr}rv?vrww}wzwr}v}v>`^zyuuuyr~   `v>}wrrrrrw}?++???>>>^|+??}rrrv?}}rrrrrr~<br></br>
`wwv??}rrr}}wwwwwr}v}rxxzzux>.    +}>rvvwwwwrrrv|?v}}?>>+>>+>>?v}v}rwwwwww~<br></br>
`rv??v}}rr}vrwwwwwwwrvv}wzz>.     }>-+vwwzzzzww}|?v}}v?????????>>??rwwwwww~<br></br>
`}?}?vv}rrv?}}}}}}}}rrr}vv}}v+~.`?x|vzzzzr}wwwww??rwwrvvv??vv}rrwwwwwwrrww~<br></br>
`z?vvvv}rw?}rrrrr}}}}}}}}}vvvvvvzux?vw}?>?}wwwwwv?rwr}}rr}v>>>???}wwwwwwww~<br></br>
`ww?vvv}}rv?wwwwrrr}}vvvv??vv?>+}uux?>~|+??vrwr}^??+>v???v>++++?-?}}r}rrrw~<br></br>
`zzr?vvvv}rv??????????vv}}rrv}}?>vzwv??vvv?????v+++^++>v>-+??vv?v}}v}}}rrr~<br></br>
`zzx}?}}}}}rrrrrrwwwwzwwwzzzww}v++?~    .^rxww>^+>>>^~>}?.?w}v}wwrrr}}}}}v`<br></br>
`zzzxv?}wwrrrrrrrrwwwwwwwwzzwwr?vv+~   `?xyurw>`+>>^>?>+^?v?>vvvrwrrr}}}v| <br></br>
`zzzzzv?}}}v}rrrwwwwwwwwwzr}wxvvz}^>.`?xyyuzwrv}rwrrwzwwwrvv?>?v?wwwwrr}^|`<br></br>
`wwwwww??vvvvrwwwwwwwwwwzrvwx??zwz?w?xyyuuuxxvvvv?vvv}}rwrv+>vrvvzzzzw}>>?`<br></br>
`rrrrrwr>?}vv}rwwwwwwwww}vwz?>zwwx?wuyuuuyxvxv}>+>+>?^v}}}>vrrr?v}}}v??v?v-<br></br>
`zwwwwwww>v}}}rwwwwwwwwr}wwv^rz}wxv}uuxzwv``xvr^>??>>?zzr?+rrr??v??++???v}-<br></br>
`zzwzzzwzr>?}}}}r}v?vrvvwww>vzz}wz}?}rv^`. |x?zrwzxzzxxwr?}w}>>?v}w>>v??v?`<br></br>
`zzwwwwwwzr??}v}}?++>>?^?wv?wwwrzv?~?^. ...+zvzzzzzzzxwr?v}??>vrr}?~?vvvv>`<br></br>
`zzzwwwwwwwr??vv}?|>++?^?w?v?>?v???|?. ....?w?wwzzzzz}v??vvv?vwr}}+?r}}}v?`<br></br>
`wwzzzzwwwwwrv?vvv?v}v}wwwv}^>+>+|?~>~ ..  vvvwwzzzv+^^+>>???rwr}??wwwwww}-<br></br>
-xzzzzzwwwwwr}}?v}}}}}}}}v?w?}}vv??+??.`~>vw?rwrv>||>+?}vvv}wzrv?vwwwrwwwv-<br></br>
`wwwwwwwwwwwww}}??v}}rrrr}?v}}vvzww}>zvvr}vv??^~->?v??}v}wwww}??}wwwrrwww}~<br></br>
`rrr}}rrrr}}}}}vv?>?vv}}rrrvv???}}rr>?????>^>^?+>rrv?}v}}}}}?^?wwrrr}}rw}v~<br></br>


            </div>,

            <div>

`v}}}}}}}vvvvvvvvvv??v??>vvv?||~|+>?vvvv}}vv??vv}}}v}}}}}}}vv>^^+>????+|~+`<br></br>
`wwwwwwwwwwwwwwwwwrvrvvv?>++^^+??vvvv}}}}v}rrrr}v}wwwwwwwwwrr}}v?++>?vvvv|.<br></br>
`wwwwwwwwwwwwrrrrrvvvv?>+|^?v}rrrrr}}}vv????v??v}}wwwwwwrrrrrr}v}vv?>>>?}}`<br></br>
`wrrrrrrrrrrrr}}}}v?>^^>?v}wwwrrrrrrrrrrr}v????vvv}wwwww}wrrrrrwr}}vvv?>+>`<br></br>
`wwwwrrrrrrrr}}vvv???vrr}rr}}wrrrwrrrrrrrrr}}}vv??vrrrr?vrrrrr}rrr}}vvvvv?`<br></br>
`zzzwwwr}}vv}}}}v??}v?+~`~?wr}}rrrrrrrrrrrr}}}vv}}}rww??}}}}vv}}rrr}}}}}}}-<br></br>
`wrww}r}}rrrr}vvvv}r}~`-```~?rrv}rrrrrrwwr}}}vv}rrrww?>v}}}?vvv}rr}}r}}r}}-<br></br>
`rrr}vv}v?vvv}vv}r?|`~`.`-~`.-?z}v}rrrrr}}}}vv}rv}ww?>?v}v?v}v?r}}}rrwwwww~<br></br>
`wrr}?^>>^+v?>>>}}^```~`  .-?}wwww}v}}}}}}}vvv}vv}rv>??+??+>v?>?vv}}}rrwwr~<br></br>
`www}?>>>+?>^?v^-vwv|`.---?zyyuxzwwrvv}}}}vvv}vvv}}????+>+^+++?+~?}}}}}rwr~<br></br>
`wrvv?~+>>||~??~-v}}r?|-?zuuuuyyxv?+>vvv}vvv>?rrrw}???^+>>>+~?v?.>v}}}r}}r~<br></br>
`w}vv?`>???v>>>?vrrrv}wwzwxuuz}+`   ~?}vv}v}}++vvvv??^.+>+|>>^^||?vv}rr}}r~<br></br>
`rrvv?vvv}rvvvv}rrrwwrv}zwwxr-     `v?vrr}}rr}^^>?vv>??+>?vv}}}v>^?v}rrrrr~<br></br>
`rrvv?vvv}vvwr}vvvvvv}r}}rzv^-.   .v??}}}rrrrr}?^+?v?|+^^?}}}r}}??vvrrrwww~<br></br>
`rrvv?vvv}vvrrrwwrr}}vvvvv?}}?^`.-v?`+?vrwwwwww}>^?v}>^^|+?vrrrr?>}}rwwwww~<br></br>
`rrrv?vvvv}vvvvvvvvv}}}}}}vv???vrxz^rwwwwwwwwwww+?vv}?>>^>???v}rr>?}rrwwww~<br></br>
`wwwv>vvvvrrrr}}}}vvvvvvvvvvv?+?yuu>v}vv??vwwwwr??}ww}?????>>???vv?}rrrrrw~<br></br>
`wwwr??vvvrwrrrrrrrrrrrrwwr}}}v+zuuw?^~^?}rwwrww>???>}v???+>+^?^^?v}rrrrrw~<br></br>
`zwww?>vvvvrrrrrrrrrrwwwwww}vv?+?>^~+v}?>??v}r}}++>^^>+?>~+>>v??v}v}}}}}rr~<br></br>
`zzzzw>vvvv}rrrrrrrrwwwwwr}}r}?v|-    `+v}v}?v}?+>>>>~>}v.^v??}wrrr}}v}}}r~<br></br>
`zwwzz?vr}}rrrrrrrrwwww}?vwz}}}}`- ..->}xyw}v}v.|>>+^>^^||vzw}??}rrrr}}}}}~<br></br>
`zzwzzr>v}vv}rrrrrwwwrvvrw}vr}zv??^?ruyyux}r}w}?vvvv}rvv}r}}rwwr}rwwwr}}vv-<br></br>
`wrrrrw?>vvv}rwwwwwwvv}wwv?wwvxzzxxyyuuxxrz}wwv?>vvvv}}}}v?vv}}wwwwwwwwwr?.<br></br>
`rrrrrw}??vvvrwwwwrv}wwwv?rwvwxrwuyyyuuurzwvzw?+>++?|?}}vvvv?vv}}}}}rrr}>?-<br></br>
`wwwwwww}?vvv}rwww}rwwwvvwwrvxxrwzr}?vr++xvwzz>+????>}rv?>?vvvvvvvvv}v?>?v`<br></br>
`wwwwwww}v?vv}}rwv???}v?www}wv>~|^|``|``vw}zwwrwzzzzw}v?v}rrv}wwwwww}>+>>>`<br></br>
`wwwwwwwrvv?v}}r}?+>>+?+>wrvr|``~. .~`.^zvrwwwzzzzzzrv??}}rv}wwr}vv??>++??`<br></br>
`wwwwwrrwvv??vv}}?|?++>+?wv?>>>^|..~``-vrvwrrwwwwzw}}vvwrvv}r}}v?v}v>>?vvv-<br></br>
`wwwzzwwwrvv??vvvvv}}?}wwrv?^+^+~.-`..^zvrwwrrrwwr}}v}rv??v}}rw??}??v}}}}}-<br></br>
`zzzzzzwwr}v}??v}}rrr}vvv?vvv}vv}^~^>?}v}wwwwwrv????vvv??}wwrr?+??vrrvv}rw~<br></br>
`wwwwwwwwrwvvr??v}}rrwwwr}v}}}v?r}}}}}v?vv??>^^+>>?>>>?>?vv?v?+v}rrr}}rwwz~<br></br>
`rrrrrrrr}}}?vv>???vv}}}vvv????>?>>>>+^|~-`~^+??>v??vvvv}}????}}}}}}vvv}}r~<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}}}}}}}}}}v?vvv?>^-~|~~|>>>???v??>>??vvvvvvvv>^^^^^+>????????`<br></br>
`wwwwwwwwwwwwwwwwwrrr}}v??>>++>>??vvvvvvv}}rrrrr}}}}rwwwwr}v?+^+>>+>??vvv}-<br></br>
`wwwwwwww}}}vvvvvvv}vv?????}r}v}rrrrrrrr}}vvv??vrrrr}}rrrrrr}}?>^+??>>>?v}-<br></br>
`wrrrrrrvvvvv}r}}}vvvv?rv??>+?wvvrrrrrrrrrr}}vv??vvvvvvrrrrrrrr}v?^+???>+>`<br></br>
`wwwrrrr?vvvv}}vvvvv}r}r?.....^r}v}rrrrrrrrr}}vv?>??vv}r}}}}}}}}vvv?>++???`<br></br>
`wwwwww}?v??v}rr}vvwr?>~^~.``..-vrv}}}rrrr}}}}}}}}????v}}}vvvv}}}}vv??>^>v-<br></br>
`wrrr}r??vvv}r}?v}}}r>~~->` .....?wvvrrwwr}}}}}}rr}}}v}}}}v?vv}}}}}}vvvv>+.<br></br>
`rrr}v}?v?vvrrv}rrr}}w?|-~+..`+vv}wwvvrr}}}}}}}rv}wwr}}}}v?v}rwwr}}}rrrvvv`<br></br>
`rrr}?+?>^+vv???}}rr}}w}^`^vrxyyuuzrw}v}}}}}}}}vv}rvvv?>??>v}vv}}}}}}}r}vv`<br></br>
`rrr}v++>^>?+>?||vr}}}vrrvvxuuuuyyyuw}}vv}}}}}vvvvvv}}?^>+^+>+>^+v}}}}}}}v-<br></br>
`wrr}v++>?+~|?}+.?vvv}r}}zwvxuzrv?^---+vvv}}vvr}vvv}r}>+>+>+|vr>.?}}}}}}}}-<br></br>
`rrrrv.^?>>?+^^|?}}v??vr}}wz}w|.     .^vv?vvv?>v}r}r}^`>>>^^|++-|?v}}}}}}r~<br></br>
`rrr}v???v}r}vv}}rrww}vvvvvw}+^.    ~vv?v}vvv}>|v}}vv>+++>?vvv???v?v}}}}rr~<br></br>
`rrrr?????vrrwr}vvvvv}rr}vv?vv>|...?v+?}}rrrrrv|^?v}v?^^^>v}}rr}?>>?}rrrww~<br></br>
`rrrr??v??vrrrrrrrr}}vvvvvvv?>>??vwv|+??vrwwwwwv++?vv|^+|?}}}rrr>-??}rrrww~<br></br>
`rrrw?????}r}}rrrrrrrrrrr}}}vv+}uuw+wwwwwwwwwwwr?^?vv+^+|?v}}}rw>|?v}rrrww~<br></br>
`wwww>????}rr}}rrrrrrrrrrr}}vv>}yuz+?vvvv}rwrrw}?>vrw?|^|>?>????>??v}rrrrr~<br></br>
`wwww??v??}wrrrrrrrrrrrr}vvv???vv?vw?~^?v}rrwrrv???????++>+>++?~|??v}rrrrr~<br></br>
`zwww?>v??}rwrrrrrrrr}v?vv}}v?v~.. `vr>+v}rwrr}>>+>+|?>>+|>>???v>^vvv}}}rr~<br></br>
`zzwzv?vvvvrrrrrrrrv??vrwr}}vr-.     |vv?vvv}}v?+>++>^+?v-~vv?vwv+vvv}}}}r~<br></br>
`zwwz}vvvvrrrrrrrvvv}rw}vvrvzv>?|^>?v}wxw}rv}vv-~>>>|^|+^`>rw}vv?v}}}}}}}}~<br></br>
`zwwwvv?vv}}rrrvvvrww}vvrr?rxwuxzxuyyyuzv}}www}>????v}v???>?rwwr}rwwrrr}}}-<br></br>
`wrrwvv???v}r}vvwwwrvv}wwvrxxruuyyuuxuw}w}wzwv???v??}}}}r}?>?}}wwwwwwwrrrr~<br></br>
`rrrr}vv?vv}r?vwrrrv}wwwvrxxwzxxzzxyxrwz}wwwzv+>+^?++}}}}rrv??v}}}}}rrrrrr~<br></br>
`wwwwrv}?vv}}}v}rw}rrwwvr?|~-~~~-|v?^vz}rwwwwv^?????vr}v}}}}}vvvvvv}}}}}}v`<br></br>
`wwwwwv}v?v}}rrvvv??>v??v`......-^~-?z}rwwwwwwwzzzzzwr}}}rwwwwwwwwwwr}vv??`<br></br>
`wwwwwvv}?v}}}rr}?+>>^?>-.`~~..|^-~>z}}wrrrwwwwww}vvv??v}}rwwwr}}}}}v?????`<br></br>
`wwwww}?}??v}}}rrv^?>???>|~>>>>+~|>w}rrrrrrrrwwwr}v?vv}rr}rwwwwwwwwv????^^.<br></br>
`wwwwww?vv??vv}}}}}}}v}rr}v+>++>+?r}}rrrrrrwrr}vv??vvvr}}wwwwwwww}v?v?>+?}-<br></br>
`zzwwwwrvvv>????vvvvvvvvv?vvv}rrrrv}wrrrrrrrvvv}}rwrvvv}wwwrrrr}v??>>?v}}r~<br></br>
`wwwwwwwrww}v}}}}r}}}}}vvv??>??????v?vvvv????v}}}v???v}}}}v?}}v??>??v}}}}r-<br></br>
`}}}}}}}}}}}?vvvvvvvv}}}}vv?>^|^^^|~----|^+++>>?????vv}}}v??v>|+?vvvvvvvv}~<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}rrrrrrrrrrr}vvv??+-^^||~~~~~^+>>>>^+??vvvvvv?^-~+????????????`<br></br>
`wwwwwwwwwwwwwwwrr}}}vvv???>+>???vvvvvvvvvvvvrwrr}vvrwrwrr}v>|-^?????vvv}}-<br></br>
`wwwwwwwwvvv??????vvvv??v}}}}r?}rrrrrrrrrrr}v?v}wwwrvvrrrrrr}v?^-|>????v}r-<br></br>
`wrrrrrr?>???v}rrrrrr}?zv?>+~>w?vrrrrrrrrrrrrr}?vv??vvvrrrrrrrrv?+~~>????v-<br></br>
`wwwrrr}+???v}rrrrrrrrv}v     ~wv?rrrrrrrrrr}}rrv>+>?vvr}}}}}}vvvv?>^~^??v-<br></br>
`wwwwwwv>???v}rrvv}rrrr?z?     .vr?}r}}rrr}}}}}}}v>^>>v}}vvvvv}}}}v???+~+?-<br></br>
`wrrrww??vvvvr}??vrrrrr}?z|      ?w?vrrwwr}}}}}}}r}vvvv}}}}}vv}}v}}}vv??+~.<br></br>
`rrrrrr>vvvv}}>vrr}rrrrrv?z~-^>vrwxz?vrr}}}}}}}rrrwwr}}}}}}}}rrwr}}}rr}vv?`<br></br>
`rrrr}>??+>?}v?v}}}rr}}}r?}xuyyyuuuzzv?}}}}}}}rr}}}v?vvvvvvv}}rrr}}}}}rvv?`<br></br>
`rrrr}++>++v?+>^>vr}}}}}}r?wxxuyyyyyzwr?v}}}}}}}}???}}>+>+|??>>+v}}}}}v}vv`<br></br>
`wrrrr>+>>>^|?r?.>??v}rrrrr?zxrv?>|-`.+w?vrrrrr}??v}r}++>+>+^v}|-v}}}}}vv}-<br></br>
`rrrr}`|>>^+|^+~~vv?+>?}rwwr?x|       ~wv?r}????}}}rr+~>>>^||??--?v}}}}}}r-<br></br>
`rrrr}^>??vr}vvvrrrrrv?>??vrvvz`    `v}>?rv?v}v^v}}}v~^++^>v?>>?v??v}}}}rr~<br></br>
`rrrr??v??vrrrrrrrrrrrwr}v???>vr-``>}>>vrrrr}}}|+v}}}?^+^+v}}rrrr>>?vrrrww~<br></br>
`rrrr?>???vrrrrrrrrrrrrrrr}vv>~vzzx?^+??vvrwwww?|+>??~^+|?}}}rrr?|??}rrrww~<br></br>
`rrrw?????v}}}rrrrrrrrrrrrrrrr?vyuz+wwrwwwwwwwwr?^?vr?^+|?}}}}rr^???}rrrww~<br></br>
`wwww?????}rr}}rrrrrrrrrrr}vv?^ryuz^??vvv}rwrrwr?+v}w?|^+>?>???v^???}rrrrr~<br></br>
`wwww??v??}wrrrrrrrrrrrr}v???>?r>>?r>-+?vvrrrrr??????++++>>+^?^+~>??}rrrrr~<br></br>
`wwww?????}rwrrrrrrrr}?>>?}r}?}-   -rw^>rwrwrw}|>+>>|?>>+^>>???v+~??v}}}rr~<br></br>
`zwwz?vvvvvrrrrrrrr?>>?}rwwr?}`     .?x?^}rv}}v>>+>+>++?v|~vrrrr?|??v}}}}r-<br></br>
`zwwz?vvvvrwrrrr}???}rrrrrr>r?>??v}rwwxu}>r}vvv^~>>>^||++`~}www}?vv}}}}}}}~<br></br>
`zwwz??vvv}rrrrv?vrwwrrrr}>vxyyyyyyyyuxw?vwwww}|+?>>>v??>+^?rwwwwwwwrrr}}}-<br></br>
`wrrw?>???vrr}?vwwrrrrrww>}xuuuyuuuuxx}?rwwwr???v??v}}}}r}>~>}}rwwwwwwr}}r~<br></br>
`rrrrv+v??vrr?vwrrrrrrrw?}xuuxxzwrwuz?vwwwwzr>>>^>?~vvvv}rrv++v}}}}}}rrrrr-<br></br>
`wwwwr+vvvvrr}?vrwrr}rrvr?``.... -r}?}wwwwwzr+??v??vwwwwrr}}}v?vvvv}}}}}rr~<br></br>
`wwwww>?vvv}rrr??v??+v?>?       >w?vwwwwwwwwwwwzzzzzzzzwwwwwwwrrwwwwwr}vvv-<br></br>
`wwwww?>vvv}rrrrrv+>>^>>` `--``vr?vrrrrrrrwwwwwww????v}wwwwwwwr}}vv}}vvv?>.<br></br>
`wwwwwv+???vrrrrr}+?>?v?>~|>>>>+>rrrrrrrrrrrrwww}v???vrwwwwrrwwwwwwr}v?>~~.<br></br>
`wwwwww+>???v}}rr}}r}vv}}rv+>+>>v}rrrrrrrrrwr}vv?>>???rwwwwwwwrrrwwrv?|^?}~<br></br>
`zzwwwz}??????>????????vvvvvvvrrrrrrrrrrrrr}??vrwww}??rwwwwwwwwwrv?^^>v}}}-<br></br>
`wwwwwwwwwwwwwrrr}}}vvvv???>++>+>>????vvv???vwwwrv?vrwwwwwwwwrv?^|+?v}}vv}-<br></br>
`}}}}}}}}}}}vvvvv}}}}rrrr}vv>+-~^^|||~`-|^>???>>??v}}}}}}}vv?^-|?vvvvvvvvv-<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}+>?>??vvvvvvvv??>+++^^^^^|~~~~--~|^+>+~?vvvvv^+??>>>>>>??vvvv`<br></br>
`wwwwwwwwwww?>???vrwwrrrrwvv}rwwrrvvr}}vvv???>>????>|>?????|>???????v}rrrr-<br></br>
`wwwwwwwwwwv>v??vvrrr}}}}}vvz^|^^+w??}}r}}}}}}vvv}r}v?>????>+|-|>?????v}rr-<br></br>
`wrrrrrrrr}>????v}vv?v}}}}}?x`    ~z?v}r}}}}}}}}}vvrwwrvvr}}}v?^~|??????vv-<br></br>
`wwwrrrrrr?????v}v?v}}}}}}rvw?     ^w>v}}}}}}}}}}}v?vwwwvv}}}vvv?^~^?????v-<br></br>
`wwwwwwrr?>????}v?}}}}}}}}}vvw.     >}>}r}}}}vvvvv}v????vvvvvvvvv?>~~>???v-<br></br>
`wrrrrww}?????v}v?vr}}}}}}}}?x+-~|||^wv?r}}vvvv}}}}}}>+>?vvvvv}}}vv?+-^???-<br></br>
`rrrrrrr??v??v}}}v??v}}}}}}}?rxuyyyyyxxvv}vvv}}}}rrr}v>++?vvv}}r}vvv?>|~>?-<br></br>
`rrrr}vv????v}}}}}}v>?v}}}}}vvxzuuuuuuzx?v}vv}}}}}}}}vvv?vvv}}rr}v}}v??>~+`<br></br>
`rrrr}++>+^?v>>+?}}}}?>?v}}}}?zwzzzzzzzww?}vvv}}vvv}}}+??>+v???vvvvvvv?>>|.<br></br>
`wrrrr>+>+>?^>}?`>}}}}}?>?v}}vvr........?}?rrrrrrrr}}v++>^++^??->v}}}}}v??`<br></br>
`rrrrr^^>>+~~+?+.>}rrrrr}v??}}?x-      .>w?vrrrrrv???++>>>+~>vv`~v}}}}}v??`<br></br>
`rrrrr-^?>?vv>>>v}rrrrrrrrrv???r?   .~?vv?v}????vvvv?.^>>^+?+^~|?v}vv}}vv?`<br></br>
`rrrr?vv??vrrrrrrrrrrrrrrrr}vv+^zvv?v?>?}rrvv}}v^v}}v?vvv??>+>?}}}}}}}r}v}-<br></br>
`rrr}>????}}}}}}}}}}}}}}}}}}rr??uuu??>>??v}rrrrv~?v}v}v?++^^?}rrr}}rrrrr}}~<br></br>
`rrrv>???v}}}}}}}}}}}}}}}}v???+zuu}+wrrrr}}rrrrv|?v}}?+|++>v}}}}rr?v}rrrrr~<br></br>
`www?????}r}}}}}}}}}vv????vv?>}vvwr|>?v}rrrrrrrv>+?vv|^+>?????vv}v>?vrrrrr~<br></br>
`wwr>???v}rrrrr}v?????v}}rrv?v~  .?r~+??v}rrrrrv??v}}>++>>>+>?~v?|??v}rrrr~<br></br>
`wwv>???v}}rrvv?vv}rrrrrr}vv>.     vw|?rrrrrrr?>?+?>^??>^+>??>??|???v}}}rr~<br></br>
`wwv>vvv}rrv?v}rrrrrrrrrv?wz??>+^|-~ww~?r}rrr?|v>+>++>^?v|~vwrw>???v}}}}}r-<br></br>
`zz??vvvrwr?}r}}}}}rr}}?vzuuyyyyyyyuxxr+}vvvv>??|>>>+||?v~`vww}|??v}}}}}}}~<br></br>
`ww??vvvrwr??r}}rr}}v??}xuyuuuuuuuxxzr?vrwr}v}}~~>>+^>+^^+?v}rv`?v}rrr}}}}-<br></br>
`ww?????v}rr??}}}}vv?v?+?vv}wzxxuxz}vvrwwwww??>????vv>^^vr}}v}?~vv}wrrrrrr~<br></br>
`rw>????}}}}rvv}rrv?}>.      .~vwvvvrwwrwwww?+>^+?~?}v+|+}}}v?>??vv}}}rrrr~<br></br>
`ww>?vvvrrr}}rrrrv?r+.      -?rvvvrwwwwrwwww??vv?>?}rr}+|?}}vvvvvvvvvvv}rr~<br></br>
`wwv????vv}rrrrrv??>+>^|`.^vrvv}rrrrrrrwwwwwwwwwr}}rrrwr>^rrrrrrrrrrrrvvvv-<br></br>
`wwwwwr}v????vv}}v++>^+>~?}v>vvv}}}rrrrrrrrrrrwwwwwrrrwww??rrr}}}}}}vvvvv?`<br></br>
`wwwwrrrrwrr}v????+>???>?v>+>+?|vrrrrrrrrrrrv??v}rrrrrrwwwvrwwwwwwwrrr}vv?`<br></br>
`wwwwwwwwrrrrrwrr}v??>>?v}??????}}}rrrrrrrr}v?>>?vrwrrrrwwrrwwwwwwwwwwr}vv`<br></br>
`zzwwwwwwrrrrrrrrrrrrv?+||+>?vv}rrrr}}}vv???>>>??rwwwr}rwwrrrrwwwwwwr}vv>|.<br></br>
`wwwwwwrrrrrrrrrrrr}vv?>++>+^|^^>?vvvvvvvrwwwr}??rwwwwrrrrwwrrrrrrr}?>^^+?-<br></br>
`rr}}}}}}}}}}}}}}}vvv?^?????>^||~~^+>vv}rr}}v??v}rrrrrrrr}}}}}}vv?^~-^?v??-<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}}?>?>>>??vvvvvvvv??vvv???>+^|~~^^~+>>>??>?????^+???????^>>>^.<br></br>
`wwwwwwwwwwwww}?????v}}}vvv}}}}v}w?vvrrv?}}v?>+^|^++>??>?vvvv>>?vvvvv^>??>`<br></br>
`wwwwwwwwwwwwv?????vvvvvvv}}}}}v}v ..`-v~>?wrv}v??++>+>>^?vvvv>+?vvvv|+>?>.<br></br>
`wrrrrrrrrrr?>????vvvv}}}vv}}}}v}v.... >>^>}r}}}}}v??v??+|>??vv>>??v?>+^^|.<br></br>
`wwrrrrrrrr??????vv}v?}}}}vv}}}v}v.... -?~>}rv}}}}}}vvr}}?>>+>>>^>????????`<br></br>
`wwwwwwrrr?>????v}}}}v?v}}vv}}}v}v....  ?|^vrvvvvvvv}vv}rr}?????>+~+????vv`<br></br>
`rrrrrwwr??????v}}}}}}v?v}}vv}}?rzwwrv?>vv^v}vvv}}}}}}v?rrwr??vvvv>~^?????`<br></br>
`rrrrrrr?>????v}}}}}}}}v??}}vv}v}zzuyyyyuuvr}v}}}rrr}}}v??v}}vvvvv?>|~????`<br></br>
`rrrrr}v?????v}}}}}}}}}}}??v}vvv}zxuyyyuuur}rv}}}}}}vv}v???v}}}r}vv?>^~???`<br></br>
`rrrr}+>>+^?v???v}}}}}}}}}v?v}v?}}^>>?vwxuzvrvvvvvvvvv>v?>?vvvv}}vvvv>|~??`<br></br>
`wrr}}>+>++?>+?>-?}}}}}}}}}}??vv}v.    ..~r?r}}}}}}}}?^>>^+>+>^+vvvvv?>|~>`<br></br>
`rr}}v>+>?>|~>}v.^vr}}}}}}rr}v??v}.       >?v}}}}}}}}>+>+>+|?}?.>vvvvv?^^~.<br></br>
`rr}v?-->>+??^^|+?v}}}}}}}}}}vv+>w^`.`|>???vvvvv}vv}>.+>>^^^++~~?vvvvvv>+^.<br></br>
`}}}>v???vvv}}}}}}}}}}}}v}vvvvv>}xuzv???vvvvv?v?vvvv>>????vvv?v}}}}}}}v?+>`<br></br>
`}}?????vvv}}}}}}}vvvvvvvvv???>ryux???>>?vrr}}}}??vvvvvvvvvv}}rr}}}}}}}v?>`<br></br>
`}v>???v}vv}vvvvvvvvvvvvvv}v????ru?+rrrr}vvrrrr}^?}}vvvvvvvvvv????}}}}}vv>.<br></br>
`}?????vr}?vvv}}}}}}}}}}rr?>+~. .?r-+?}}}}}rrrr?|?}rrr}v????>>??vv}vvv}vv?.<br></br>
`v>???v}rv?r}}}}}}}rrrwr}wr+.    .z>^??vv}}}}r}??v?v}?>>+>++?^>}vvvvvv}vv?`<br></br>
`v????v}}}?}}}}}r}vv}rrrzuuwv?+~. ?r^?r}}}}}r}?+?+??+??++++>>>?vvvvvvvvvvv-<br></br>
`?vvvvv}rrv?vr}v?>+^+?rxuuuyyyyuz}vzv>}}}}}rv>++?+>++>+>?||vrr}}v??vvvvvvv-<br></br>
.?vvv}r}}rw}vwv+^|+>^^>vrzuuuuuuuyuzz?v}v}v?|>??^>>>>^|?v+.?wrr?+??v}vvvvv-<br></br>
`r}vv}rr}}}}}}}?>>|.   .`^?rzxxzwwrvvv}rvv??vvv|`>++|>+^^|>v}}>>?vv}}}}vvv-<br></br>
`wwr}v?vv}}vv??zv. ...... .~?ww}}}}}rrrrr}rv>>???>>++?vvv}}}v>???vrrrrr}}v-<br></br>
`rrrrrr}v?vv}v?}r?-....`+?}r}vv}rrrrrrrrrrrv^>+^?^|+^?vvv}}}>|>??vv}}}}}}v-<br></br>
`rwwwwwwwr}vvvv?vrr?~+v}r}vv}}}}}}rrrrrrrrrv>?vv???>+vr}}}vv`+??vvvvvvvvvv-<br></br>
`rwwwwwwwwww}v?????>+??>+v}}}}}}}}}}rrrrr}rr}vr}vv}?>?wrrv??+?v}rrrrr}vvv?`<br></br>
`wwwwwrrrrrr}rr}?>^+>++?~??>?v?v}}}}}}}}}}}rrvvvvvrv>?rr}?vvvv}r}}}}}vvv??`<br></br>
`rwwrrrrrrrr}}r}vv?+|+??}v^>+>>+r}}}rrr}}}}}}}}vvvrr?>r}vv}}rrrrrrrrr}}v??`<br></br>
`rrrrwwrrrrr}}v??>>++^|^>?>?v??v}}}}v}}}???v}}}v?vrrr>v}}}}rrrrrrrrrwr}v??`<br></br>
`rwwwwwwrrrr}vvv>>????>+^^>?vvvvvv?vvv?>?????v}}v}rrr}vrrrrrrrrrrrrrr}vv?^ <br></br>
`rrrrrrrr}}vvvvv?+??>?>+>>>+>?vv}}}}}rrv???v}}}}}}rrrrrrrrrrrrrr}}rrv???^`.<br></br>
`}}vvvvvvvvvvvv??>++~+?>>>>+|+>??v}}}}}}}??v}}}}}v}}}}}}}}}}}}}}}}vv???|`>`<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}}}}v?+>>>>>??vv????vvvvvv}vv???++>+>??>>++>>>>??>?v?????????+.<br></br>
`wwwwwwwwwwwwww}??????vv}v}}}vvv}}v}vwv>v}zvv}?>++>>+?????????>?vvvvvvvvv?.<br></br>
`wwwwwwwwwwwwr?>?????v}}vv}}}vvvvvvvvw|..||~~+}rv?>^~^>>>?>????>^?vvvvvvv?.<br></br>
`wwrrrrrrrrrv>?????vv}vv}vv}vv?v}}}v}}``-~ --.-}rv}v>^^^^>>>>????^?vvvvvv?.<br></br>
`wwrrrrrrr}?>?????v}}}}}}v?vvvvv}}}vw>.`~. --`-rvv}}vv?+++>>+?????>?vvvvv?.<br></br>
`wwwwwwwrv?>????v}}}}}}}}}v?vvvvv}vvz>-|`  `-`^zvvvv}}}v??>>^+???v?>?vvvv>.<br></br>
`rrwwwr}v??????v}}}}}}}}}}}v?vv?v}v}zwzz}?|~~.?wv}}}}}vvvvvv?+>>?v????vv?+.<br></br>
`rrrr}v?????vvv}}}}}}}}}}}}}v?vvvvvwrzuuuyyx}>}}v}}}}vvvvv}rrv?>>??>>?vvv?`<br></br>
`rrr}vv????vv}}}}}}}}}}}}}}}}vv}vvvw}uxuyuuuxrwvv}}}vvvvvvv}wrv?????+^?vv?`<br></br>
`rrvvv>??>+?vvvv}}}}}}}v}}}}}}vvv?}v|^->}xyux}rvvvvv}vvvvvvvrrrv???v?~+???`<br></br>
`rvv??>^>+^??+>+^?vvvvvv}}}}}}vv??v?~.   `+}xr}v}}}}}>+>>^??>>>vvv???+|+??`<br></br>
`vv????+>>>+|>}}`~v}}vvvvvv}}}}}?>v>.      .?rv}}}}}v>+>+++^?v^`?vv??++|>?.<br></br>
`v???v|->>^^^|>^`>vvvvvvvvvvvvv?>vzr?~`--~~^?}?vvvv}+~>>>+||??-`?vvvv?+|^?.<br></br>
`v>??v++???v}v??v}}}vvvvvvvv????wuuurv??vvvvvvvvvvvv-^?>++??>+>vvvv}}v?~~+.<br></br>
`vv??v}vvvv}}}}}}}}}}}}}}r}v?>+?zuz??v?+?v}}vvvvvvvvvvvvvvvvvvv}v}}}}v?^||.<br></br>
`v}v??v}vvv}}vv}}rrrrrwwww?-`.. `w?|r}}}v?v}}v}v?v}vvvvvvvvvvvv}}}}}}}v>~^.<br></br>
`vvv}v?v}}?vv}r}v?+?wzzwxur+`    vv~>v}}}}}}}}}v^?}vvvvv?????vvvvvvvv}v?~+.<br></br>
`}vvrr}?v}v}z?~-````|vxuuuuxr>-. vv^???v}}}}}}v^>}}}}}}?+>+>?|vvvvvvvvv?^^.<br></br>
`ww}v}r}?v}v}}~``-`.`|>ruuuuyyz?|v}>v}vv}}}}}}>|?>v?>?v^>+>>+>???vvvvvv?+^`<br></br>
`wwww}v}rv?v?}r~..   .`~vxyuxuuxuxv?v}}}}}}}v?^>?^>+^>>>?^+?}}}}}vvvvvv?>|`<br></br>
`wwwwwr}}r}?vrv|```--```|>}zzzwwwr}vv}}}}v?++^>?+>>+>+|?v?.?}}}}}}}}vvv??|.<br></br>
`wwwwwwwr}vv??}}^..``-|>>?vrr}}}}}}}}}vv?>>???v^`>>>^+|^^-|??vvvv}}}}}v??~.<br></br>
`wwrrrrrr}v??>?vwv?v}rrr}}vvv}}rrrrr}}}vvv}>>>v?>+>+>?vvvv}}}vvvv}}rrrvv?^.<br></br>
`rr}}}}}}vvvv>^?vvvvvvv}}}}}}}}rrrrrrr}}}}v+>+^>>~^?v}vvv}}}?????vv}}}vvv+ <br></br>
`rrrrrrr}v}}vv?^>vvv?vvv}}}}}}}}}}}}r}}v}}v????^^+?rrrrr}}v????vvvvvvvvvv+ <br></br>
`rrrrrrr}vv????>^^>>+>>?^v}v}}}}}}}}}vv}}}}rv??>??}rrrrrr?+>??v}}rr}}}???+ <br></br>
`rrrr}}}}???+>>>>+^^>++?|??>???v}}}}}vv}}vvv??????}rrrrv>~>??v}}}}}vvv???+ <br></br>
`}}}}}vvvv??+?????>>>>??}?+>+>^?}}}}}}v}}}vv??vv??rrr}??^>?v}rrrrrr}}}v??+ <br></br>
`}vvv}}}vvv??>?+>>^>>>?vv??vv??v??vv?vv}}}}v??}v?vrrv>???vv}rrrrrrrrrvv??+ <br></br>
`}}}}}}}vvvv?>>>>?>>>>+>v}}rr}?vvv>?v?vvv}}v?v}}?v}v?vvv}rrrrrrrrrrrv????` <br></br>
`vvvvvvvvvvvv?>>????>?+|+?vv}rw}}}}?vvvv}}}v?}}}vv}vvv}rrrrrrrrrrr}v???>`|`<br></br>
`vvvvvvvvvvvvv?>+??????>^|+>+>??vvvv??vvvvv??}}}}v}}v}}}}}}}}}}}}v????^.+?`<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}rrrv>+>>>>>??vv??vvvvv?vvvvvvv??>+??vv>++>+>???vvvvvvvvvvvvv`<br></br>
`wwwwwwwwwwwwww}?>?????vv}}}}?v}}}vvvvv}vvz?rwv?>+>?^??>>>>?>>>?vvvvvvvvvv-<br></br>
`wwwwwwwwwwww}???????vv}}}}}}??}vvvvvvv}?z? .|vwv?>+^+?+>?>???>>+?vvvvvvvv-<br></br>
`wwrrrrrrrr}?>?????vvvvvvvvvvv>v}v}}}}}vr}.    }r?}v?+++|>???>>>>+>vvvvvvv-<br></br>
`wwwrrrrrrv>>????vv}}}}}vvvvv}??}}}}}}vvz`    .wvv}}vv?+||>????????+vvvvvv-<br></br>
`wwwwwwwv???????v}}}}}vv}}}}}}v>v}}vvv?x?.    -x?vvv}}}v>^^+>>>>?vv>+vvvvv-<br></br>
`rrrwwr???????v}}}}}}vvvvvvvvvv>v}}vv?wxuz?-  >z?}}}}}vvv?>?+>>^>vvv+?vvvv-<br></br>
`rrrrv>?????vvv}}}}}}}}}}}}}}v}v>vvv?vuzuuyur+}vv}}}}}vvvv?vv>+|>vvv?+vvvv-<br></br>
`rrr?>????vv}}}}}}}}}}}}}}}}}}}}??vv?x}uyuuuyux?v}}}vvvvvvv?wr?|+vvv?^vvvv-<br></br>
`r}?>?????>?v}v}}}}}}}}v}vvvvv}vv>v?z? -?zyyuzw?vvvvvvvvvvv?rww?++?v?^?vvv-<br></br>
`v>???>+>+^?v?>>>?????vv}}}}}}vvv?+}w.   .^}ux}v}}}vv+??>>v???vr??+^+^>??v`<br></br>
.+?????+>+>?++vv-|vvvv????vvvvrr}>|x+       -z?v}}v}v++>^++^?>-??vv?^-^>?>.<br></br>
.>???v>|>>>~~|??`|v}}}}}}vv??>???vzzz>``````+x?vvvv}++>>>+~>??`|?vvvv?~~?>.<br></br>
`v>?v}|->?>?v?>+?v}}}}}}}}}}}vvvzuuu}v??vvvvrv?vvv}?.+>>^+?++~^??v}}}v>`>>.<br></br>
`r}>?vvvv}}v}}}}}}}}vvvv}}v??+||vuw??v?+>vvvvvvvvv}v?vvv?vv????}v}}}}v?~~?.<br></br>
`rrr?>v}vv}}vvvv}}rwwwwxux?.     r?|}}}}v?v}}vv?v}}}vvvvvvvv??vv}}}}}v?+`>.<br></br>
`rrww?>v}}vvv}}v?+~>zyuxuyyz>.  .z?~>v}}}}v}}}}}>?v???????v?vvvvvvv}}}?>`+.<br></br>
`rrwww}+?rvvx+.     .?xyuxuyyz?``u>+v?v}}}}}}}v>+}}vvvv+>++?^?vvvvvvvvv?-^`<br></br>
`rwwwwwr??}vvz-       .?xuxxuyuv?u^?}vvv}}}}}v^|??vv?v>++>>>+?}vvvvvvv?>|~`<br></br>
`wwwwwwww?>v?vz|.....```^wuuxxxzzx^vvv}}}}}}v>|>?^>>^+?+>^>vr}}}vvvvvvv>^``<br></br>
`wwwwwwwwwv+?vvrvvvvv}r}}}}vv}}vvv?}v}}r}??>+^>?>+>+>>|>??.>vv}}}}}}vv?>>`.<br></br>
`wwwwwwwrrr?|?vvvvvvvvvvvvvv}}}}}}rrvvvv+>??>?v>`>>>^^|^+~->>>??}}}}}}v>>`.<br></br>
`wwrrr}}vvv?>|>v}}}}}}}}}}}}}rrr}}}}r}vvv}?>>?v>^>++>?v???vvvv}}}}}rrrv??- <br></br>
`}}vvvvvv>>>>+|^v}}}}}}}}}}}}}}}}}}}}}v}}r>+>^+?|^^?}}vvv}}}vvvvvvv}}}v??| <br></br>
`vvvvvv}?+?????+^?v???vvv}}}}}}}}}}}r}vvv}???+^|>?v}rrr}}}}}v??vvvvvvv???^ <br></br>
`vvvvvv}v^?+>>>>+|>>>>+?^?vv}}}}}}}}}v?}}}rr+|~>}rr}}rrr}v?>>?v}}}}}}}?>?| <br></br>
`vvvvvvvv>>^>?>+++^^>>>>^>>>??>}}}}}}v?}}}}v|~^}}}}}}rr?++>>??}}}}}vvv>>?| <br></br>
`vvvvvvvvv+>>?????>>>?vvv+>>+>+vvv}}}}?}}}}?~~v}}}}rrv^+??vvv}rrrrr}}v?>?| <br></br>
`vvvvv}vvv?^>?????~?>>vr}vvvv??v>?v}}}}}}}}?~>}v}}rr?->??v}rrrrrrrrrrv???| <br></br>
`v}}}}}}vvv?^>????^++++?rwwww}>?>??v}}}}}}}>~v}v}v?>|>?v}rrr}rrrrrr}v????` <br></br>
`vvvvvvvvvvv?++?vv??>?>|^?v}rrr???}v}}}}}}}>+}}}}vvvvvv}rrrrrrrrrr}v???>`|`<br></br>
`v???vvvv??vv?+|>?vvvvv?^|>??????vvvvvvvvvv??v}}}}}}vv}}}}}}}}}}vv????|.^?`<br></br>


            </div>,

            <div>

`}rrrr}}}}}}}}rr}?++>>>>>???vvvvvv??vvvvv?vvvvv??>?vvvv???vvvvvv???vvvvvvv`<br></br>
`wwwwwwwwwwwww}v?>?????vv}}}}vvv}}?vvvv}v}}v}}vw}????vvvvvvvvvvvvvvvvvvvvv-<br></br>
`wwwwwwwwwwrv????????vv}}}}}vvvvvv?vvvv}v}v???w??w}?>>?v?>>>>???vvvvvvvvvv-<br></br>
`wwrrrrrr}v????????vv}vvvvvvvvvvvv?v}}}v}v??>v^  -}wv>+?>+>>>++>??vvvvvvvv-<br></br>
`wwwwrr}????????vv}}}}}}vvvvv}vvvv?v}}vv}?>?}~ .. .wr??>^+>>++>>>>>?vvvvvv-<br></br>
`wwww}????????vv}}}}}}vv}}}}}}vvvv?v}vv}v>?}-  .. ^xvv}v>^+^+>>>>??>>vvvvv-<br></br>
`rwrv???????vvvv}}}}}vvvvvvvvvvvvv??vv}}}rzr^. ...w}v}}vv?+^|>>>>??>>>vvvv-<br></br>
`rv???????vvvvvvvvvvv}}}}}}}}}}vvvv?v}}vwxzuyr+. ?wv}vvvvvv?>^>>>????>?vvv-<br></br>
`r??????vvvv?vvvvvvvvvvvv}}}}}}}vvv?vvvzzuyuuyy}>z?vvvvvvvvv??>>>>>?v?>?vv-<br></br>
`rr?>?vvvv?v?}}}}}}}vvv???vv}v}vvvv?v?v?.+zyuxuxxvvv}}vvvvvvvvv>>+^?v?>?vv-<br></br>
`rw}>?>>>>^>vv???vvvvvvvvvv?vvvvvv?>?}+   .?xyxzw?v}?>v??v}vvvrv>^+?v??>vv-<br></br>
`rrr}??+>>^??^??~^vvvvvvvvvvv???v?>?}?      `vzzvvv}?^>+^+>+>^>}v^>?v?>+vv-<br></br>
`rrrrv?^>>?^~^v}|`?}vvvvvvvv}}v??vzuxz>`..   .r}vvvv++>+>+|?}?.?}>+?vv>^vv-<br></br>
`rr}rw^`>>+>?+^^|?}wrrrrwwv?>>++}uuxrv??vv??>vw?vv}^`>>>^^^+>~~vrv?>>?+^vv-<br></br>
`rrrrrv??vv}z^---``+xuxzuuw~    .rw>?vv+>?vvv}?vvv}>>??>?vv?>?vrrv}?>+^^??`<br></br>
`rrrrrrr??v?x>  ..  -wyuuuuw>   .w?~}}}}??}}vvvv}}}}vvv}vvvv???}}v}vv?|^+>.<br></br>
`rwwwrrr}>vvvz-      .vyyuuuuv` >x^+>v}}}vv}vvvvvvv}}vv??vv???vvvvv}}v>-^>.<br></br>
`rwwwwwrr}>vv}?||~-`...?uuuuyyw+wv^vv?v}}}}}}}}?>vvvvv?+++>?^v?vvvvvvv?-^+.<br></br>
`rrrrrr}vv?+vv??????????zxuxuuurv>?v}vv}}}}}}v+^??v???^>+>>>>v}vvvvvvv?-^+.<br></br>
`}}}}}}vvv?+>vrv???>?????v}rrrr}vvvv}}}}}}}}?^^??^>>^+?+++?v}}}vvvvvvv?-^>.<br></br>
`}}}}}?????+^?}}}}}}}}}}}}}}}}}v}}vv}}}vvv?>^^>v?+>++>^?v?.>}}vvvvvvv??-+>.<br></br>
`}}}}}??????+^?vvvvvvvvvvvv}}}}}}}}vvv>++>>>>???->>>+^~>?^`?vvvvv}vvv??->>.<br></br>
`}}vvv??>>>>>+^?}}}}}}}}}}}}}}rr}}}}vvvvv?>>???+~++^^?>+^>vvvvvvvvv}v?>->>.<br></br>
`vvvvv??>>?>++^|v}}}}}}}}}}}}}}}}}}vv}}}r?^>+^>>>^^>>>>?>>???vvvvvvvv?>-?>.<br></br>
`vvvvvv>?>????+|>vv?>?v??}}}}}}}}}vvvv}}}v>>++||+?vv}}}}}vvvvvvvvvvv??^~?>.<br></br>
`vvvvvv>?>????+>>??+>>+?^+?vvv}}}}?vvvv}}?>+^+>?}r}}}}}}}}}vvvv}}}}v??~|?>.<br></br>
`vvvvvv?>?>>>?^>>>?^???>++>>?+?}vv?v}vv}?++>?vv}}}}}}rr}vv?vvv}}}}}v??-^?>.<br></br>
`vvvvvvv?>>???>+++>vrrrr?+>>>+?}v}v}}vvv++>vvvv}}}rr}??????}}}rr}}}???->?>.<br></br>
`vvvvvvvv?>?vv???>|^?}wwrv?????vvvvv}}v>+?vvvvvv}v?>+^+>>?vr}rr}}vv??>-??>.<br></br>
`}}}}}}vvvv?>?vvvv?^+??v}rvvvv?vvvvv}}v+>v}vvv}v>^+>>??vv}}r}rr}vv???^|??>.<br></br>
`vvvvvvvvvvv?>+>??v?^>???vvv}}}vvv}}}}?+v}}v???+^???vv}}}}rr}}}}}v???~>?>>`<br></br>
`vv??vvvv??????+^^+>+|?????vvvvvvvvvvv>?vvvvv?????vv}vvvvvvvvvvvv?>>+->+>>`<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}v>++++++++>>???vvvvvvvvv??vvv?????????>?}}}}}}}vv?????????v?`<br></br>
`wwwwwwwwwr}v?>>???????vvvvv}vvvvvvvvv?vvvvvvvvvvvvvv?vwwwr}}vvvvvvvvvvvvv-<br></br>
`wwwwwww}?>>>??????vvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvw}???}}vvvvvvvvvvvvvvvv-<br></br>
`wwrrrv?>>>>>???vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvrw?wr?>?vvvvvvvvvvvvvvvvv-<br></br>
`wwwr?>??>???vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvzv` `rwv>>vv?>>?vvvvvvvvvv-<br></br>
`wwww??????vvvv?vvvvvvvvvvvvvvvvvvvvv?vvvvvvvvrr|    .}z?>>?+>>>>>?vvvvvvv-<br></br>
`rrww}?vvvvvvv?vvvvvvv?vvvvvvvvvvvvvv?vvvvvvvzv.     -rvvv>+>?>+++>>?vvvvv-<br></br>
`rrrrw??vvvvvv?vvvvvvvvv??vvvvvvvvvvv?vvvvvwzxx>    -w??vvv>+>^>>>>>>>vvvv-<br></br>
`rrrrw}>vvvvvvvvvvvvvvvvvv??vvvvvvvvv?vvvrxuxxyyv. -wv?vvvvv>^|>>>>>>>>?vv-<br></br>
`rrrwrw?vvvvvvvvvvvvvvvvvvvv??vvvvvvv>?vwv~wyuxuyr^rv?vvvvvvv+^^>>>>>>>>vv-<br></br>
`rwwww???>^>v}v?vvvvvvvvvvvvvv???vvv>^vr^  .vyuxuxxv?vvvvvvvv?+^^+>>>>>+?v-<br></br>
`rwwww?^>>^>v>+>|+?v}}r}}}}rr}}v>>?vv}w.     ?yuzxvv++>+^?>>>>??++++>?v>>v-<br></br>
`rrrrrv+>>>>^^||>`^`vuxxxux>>>??vwxuyux>.     +xxvvv+>>+>++vv~|v?++|>?v?+v-<br></br>
`rrrrr>`>>+|+~||~~` .wyuxyyv     ?uz}v?vv?^-. |zvvv|^>>>^|^??`~vv>^^?vv?+v-<br></br>
`rrrrw?^??>vvz+`..   `zyxxyyv    >w|?vv>^?vv}vwvvv?`+?>^>??++>vrr?|>vvv>>v-<br></br>
`}}}}v}vvv+?v}}?>|-.  -xuxuyy?  +x+~v}vv?vv?v}vvvv}vvvvvvvvvvvrwwv+^?v?+?v-<br></br>
`vvvvv????+>vvvv}rrrv?^?uuxuyy?^u?+??vvvv?vvvvv}vvvvvv?v?v?vvvwwwv?>+??+vv`<br></br>
`vvvv>+>>>^^?vvvvvvvvv}rrzzzuuxuv^vv?vvvvvvvvvvvvvvvvv+>+>?^>?vw}vv?>^^+?v`<br></br>
`vvv?+>>>>+^>vvvvvvvvvvvvvv}}rwv+vvvvvvvvvvvvvv?vvvvv>+>>>>^>vv}vvvv?^~>>>`<br></br>
`vvv?>+++>>+^?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>++??^?>^+?+>>????vvvvvvv>`+>>.<br></br>
`vvv?>+>>+^++>vvvvvvvvvvvvvv}}}}v?}}}vvvvv?^^>?v?+>++>^>v>`>vvvvvvvvv>`+>>.<br></br>
`vvv?+??>>>+^^?vvvvvvvvvvvvvv}}}}v??>^^+>?>>?vvv~>>>>^~>v>.>vvvv}}vv?+->>>.<br></br>
`vvv?+??>>>>|+?vvvvvvvvvvvvvv}}}}vvvv???>>>>?>?^->>>^>?+^^?vvvvvv}}v?|~??>.<br></br>
`vvvv+???>>^+>?vvvv}vvv}vvvvvvvvv?v}}}}}v|>+^+>??>??vvvvvvvvvvvvvvv?>-^??>.<br></br>
`vvvv?+????^>>>}r}v?>>v??v}vvvv??vvvv}v?>++++^|^^^^^+>?vvvvvvvvvvvv>^`>>?>.<br></br>
`vvvvv>>vv??+^+>rw}+>>^?+|?v?vv?vvvvvv>|^^^^^+????????>>>?vvvvvvvv?>~|>>?>.<br></br>
`vvvvvv?>?vvv?>~?r}+???>^+>+?|??vvvvv+||^>??vv}}}}}v}}}vv?v}vvvvv?>+`>>>>>.<br></br>
`vvvvvvv?>>?vv?++?v}rwv?>?>???vvvvvv+|^?vvvvvvvvvv}}}vvv}}}}}}}}v?>|~?????.<br></br>
`vvvvvvvvv?+>???|?vvvvv?vvvvvvvvvvv>^+v}vvvvvvv}vvvvvvvv}}}}}}}v??>`+???vv-<br></br>
`vvvvvvvvvvv?>+>^+vvvvvvvvvvvvvvvv?^>vvvvvvvvv?>+^^^+>?v}}}}v}}v??^->???v}-<br></br>
.+???vvvvvvvvv??^|?vvvvvvvvvvvvvvv+?vvvvvvv>~|^>>?????v}}}}}vvv?>>->????v}-<br></br>
.~~``|+???????v>+~|>vvvvvvv?vvvvv??vvvv?++|-^>>>?vvvvvvvvvvvv?>+>~~>>>>?vv-<br></br>


            </div>,

            <div>

`}}}}}}}}}v?+^^^++++++>>???????????????????????????????>v}}}}}}}}}}}}}}vv?`<br></br>
`wwwwwwwvv?>>>>>????vvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?rrwwwwwwwwwrr}}}v-<br></br>
`wwwwwwr?v?>>???vvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv?vrwwwwwrr}vvv}}}v-<br></br>
`wwwrrrrv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv}v??vrwr}}}}vvvvvvvv`<br></br>
                  `wwwwwwwv?vvvvvvv?vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvrrvzv??vvvvvvvvvvvvvvv-<br></br>
`wwwwwww}?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvrr?`.?x}???vvvvvvvvvvvvv-<br></br>
`rwwwwwwr?vvvvvvvvvvvvvvvvv?vvvvvvvvvvv?vvvvvvvvrr>`....?zv>>vv???vvvvvvvv`<br></br>
`rwwwwwwwvvvvvvv}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvrz}`...   +}}?>?>?????vvvvvv`<br></br>
`rwwwwwwwvvvvv}}??vv}}vvvvvvvv?vvvvvvvv?vvv}wzzuu?    .>?+v}?+>?>++>>?vvvv-<br></br>
`rrwwwwww}vvvrw}?>>>??}}vvvvvvv???vvvv?>?}wvryuxyy?  ~?>?}}vv>+>^>++>>?vvv-<br></br>
`rrrrrvv}v>??wv`..`~|?wxwrrr}}}}?>???>^?rv| `ryuuuy?+??v}}vvv?++^>>+>>>?vv-<br></br>
`rrrrr?^>>^+v?>+-`   +wuxuuz?>?v}v?v}}}w-    `zyuxuv+??>?v???v>+^+>>>>>>?v-<br></br>
`v}}}}v+>++?>^?>+`~  .vuuuuu|  ..+zuyuxx-     |uuxz?+>+^+++?>~>>^++>>>>>>v-<br></br>
`vvvv??~>>>||~>>|`|.. ~xuxuyz`   .wzvv?v}?-    ?uwv^>>>>+~?v?.+>?^+++>>?+?-<br></br>
`vvv?>+`>>+>v?>+>?v?^`.}uuuyyv  .v}|?vv?^?}v>`-vrv|`>>>|>>^^~^?vv++++>?v+?-<br></br>
`vvv?+??>>++vvvvvvv}rrvvuuxuyy|-w}^^vvvv????}rrvv}??vv??vvvvvvvr}?+^>?v?+?-<br></br>
`vv?++++>>+^?vvvvvvvvvv}rwzxuuwzv^???vvvvvvvvvvvvvvvvvv?vvvvvv}wr?|+?vv?+v-<br></br>
`vv?+++>++++?vvvvvvvvvvvvvv}wzxv>vvv?vvvvvvvvvvvvvvvv?++>>>+vvwww?|+vvv>>v-<br></br>
`vv>+>>>+^^+>vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvv?vvvv}v^>>>>>+?rwwr?+^?v?+?v`<br></br>
`vv>>>>>>>+^>vvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv???v?^??+>v>>>???vwwv??>>?+?vv`<br></br>
`vv>>??>>>++?vvvvvvvvvvvvvvvvvvv?vv??vvvv?+^^?vv?^>++>++?>->vv}vvv??+++?vv`<br></br>
`vv?+???>^^+?}vvvvvvvvvvvvvvvvvvv>+^|^+>?>+??vvv^>>>>^~>v?.+?}vvvvv?^|>???`<br></br>
`vvv+>v??>^+?}r}vvvvvvvvvvvvvvvvvvvv?>+>>>>>>?>^`+>+|>>^^|+vvvvvvvv?^~????.<br></br>
`vvv?+?vv?>^+vwwr}vvvvvvvvvvv???vvvvvv?v++>++>>vvvvvvvvvvvvvvvvvvv?>~^>>?>.<br></br>
`vvvv?>?vvv?++vwww}>>+??>?vvv?vvvvvv?>+>>+++++>??vvvvvvvvvvvvvvvvv?^`>>>>+.<br></br>
`vvvvvv>>?v?>>?vrw}+>>^>>~????vvvvv>^|^^^^^^^++++>??v}}vvvvvvvvvv?+-^>>>??.<br></br>
.?vvvvvv?>>?>+?vv}}+??v>|>+>>^vvv?+|^^>>>>??vv??????>?v}vvvvvvvv?>~~>>>>??.<br></br>
.+???vvvv?>^++?vvvvvvvv??????vvv?++>?vvvvvvvvvvvvvvvv??vvvvvvv}?+^->>>??vv`<br></br>
 `~|>???v???+|>?vvvvvvvvvvvvvvv?>>?vvvvvvvvvvvvvvvvvvvvvvvv}vvv?^-+>>>???v-<br></br>
.^~--~~>vvv>^^+?vvvvvvvvvvvv?vv>?vvvvvvvvvvvv??vvvvvvvvvvvvvvv>+||>>>????}-<br></br>
.>>++|-`~|>>+^|+?vvvvvvvvvvvvv?vvvvvvvv?>^^|^^^^+>?v}vvvvvvvv>+|~>>>?????v-<br></br>
.+++++^~-.`+++~-^?vvvvv?vvvvv?vvv???|||--|^^^+++>??vvvvvvvv?>^^-++++>?v??v-<br></br>


            </div>,

            <div>

`}}}}}}}}}v|^^^^^^++>>??????????????????vv???????????????+v}}}}}}}}}}}}}}}~<br></br>
`wwwwwwwww}+>>????vvvvv}}vvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv??wwwwwwwwwwwwwww~<br></br>
`wwwwwwwww}+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv>rwwwwwwwwwwwwww~<br></br>
`wwwwrrrww}>vvvvvvv?vvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwrr}}v`<br></br>
`wwwwwwwrwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvv}vv>}wwwwrr}}vvvvv`<br></br>
`wwwwwwwwwr>vvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vvvvvvvvvvvwwrxv?>}}}vvvvvvvvvv-<br></br>
`rwwwwwwwwr>vvvvvvvvvvvvvvvvv?vvvvvvvvvvv?vvvvvvvvvrw}>`.rw?>?vvvvvvvvvvvv-<br></br>
`rwwwwwwwwr>vvvwr}vvvvvvvvvvvvv?vvvvvvvvv?vvvvvvvwr?-    ~u}>>vvvvvvvvvvvv`<br></br>
`rwwwwwwwwr>vv}z+?vrwr}vvvvvvvvv?vvvvvvv?vvvvvrzxu|      `zw?+?v>+>?vvvvvv`<br></br>
`}rrwwwwwww>?vr}   .-+zxzr}vvvvvv??vvvv?>vvrwzuxxyz.   .>wrvv>>++>+^>?vvvv`<br></br>
`vvvv}}}r}v??vxv      vyuxuxrrwr}?+???>^?w}>`+yuzuyv. ^rwvvvv?++++^^++?vvv-<br></br>
`vvvvv?+>>^+vv?>`.    |yuxuy}.~+?}rv}rvr}~.   }yuxu?>?v?vvvvvv>+^^+++++?vv-<br></br>
`vvvv??++>^>?+>?+-~.  .zyxxyu-    vuuuuu?     `uuxw++>+|?+>>^??+^^>>>>>+?v-<br></br>
`vvv?^?+>>>+~~?}?.>r?~ ?yuxyy?    ?zvvv?w>`    >uxv+>++>^+v}^`?+^^>>>>>++?-<br></br>
`vvv+++.+>+^?+^^~+?vrw}vuuxuyx` `}}|?vv?^?r?~  ^x}`|>>>|^|>+`|v>>^+++>>>+?-<br></br>
`vv?++??>>>+?}vvvvvvvvvrwxzxuy??z?^+vvvv???vw}vwv?^???>?vv???vv??++++>>?+?-<br></br>
`vv>++>>+^+^?vvvvvvvvvvvvvrzxxxw>>v?vvvv?vvvv}}vvvvvvvvvvvvvvvv}v>+^^>?v+?-<br></br>
`vv+>>>>>+^+?vvvvvvvvvvvvvvvvr}>?vvvvvvvvvvvvvvvvvvvv+>+>?^?vvvwv>^^?vv?+?-<br></br>
`v?+>+>>>+^>?vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvv?vvvvv}>+>++>|?vvwwr+|>vvv>?v`<br></br>
`v?+>>>>^^>??vvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv??v?^??+>?????vvwww}+|?vv?+vv`<br></br>
`vv+?v?>^+>>}vvvvvvvvvvvvvvvvvv?????vvvvv?+^+?v??^>>^+>+?+~>vww}v?|>v?^?vv`<br></br>
`vv?+vv?>^+>}wrvvvvvvvvvvvvvvvvv?>^|^^>??+>??vvv+>>>>+~?v?.^?wrvvv^^>+?vvv`<br></br>
`vvv>+?vv?+~vwwr}vvvvvvvvvvvv??vvvv?>^>>>>>>?+?+.+>+|++^^~|?vvvvvv>~^+?vvv`<br></br>
`vvvv>^?vv>|>}wwwv?v??vvvvv????vvvvvv?v>^>++>+???????vvvvvvvvvvvvv?-^>>>??.<br></br>
`vvvvv?+>??|>vvrwv?>>+??>>??vvvvvvv?+^>>>++++?vvvvvvvvvvvvvvvvvvvv>-^>>>|. <br></br>
`vvvvvv?>++|>vvv}vv^+>^+>~???vvvv>^^^|^^^^||||^>?vvvvvvvvvvvvvvvv?~~+>>>+. <br></br>
.>vvvvvvv?^~>vvvvvv???v++>+?|?v>^||^>>>>>????>+^|>vvvvvvvvvvvvvv>^-++>>?>| <br></br>
.``|>vvvv>^~+vvvvvvvvvv??vv?v?^|^>?vvvvvvvvvvvvv?>+>vvvvvvvvvvv?+->>>+?vv?.<br></br>
.+^~`-|>>++~^?vvvvvvvvvvvvvv?^+?vvvvvvvvvvvvvvvvvvv?>vvvvvvvv??+`+>>>>vvvv-<br></br>
.++>>+-.>>>|-+?vvvvvvvvvvvv?>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>+-^>>>>>vvvv-<br></br>
.++>>>~->>>+^`+?vvvvvvvvvvv?vvvvv?vvv?>++^^^+????vvvvvvvvvv?>+-^>>>>>>vvvv-<br></br>
.+++++`-+++++|`^?vv?vvv?vvvvvvvv?+^~`-~|^^^^||^+?vvvvvvvvv?+^-~>+++>++vvvv-<br></br>


            </div>,

            <div>

`}}}}}}}}}}v?^^++>??????vvv?????vvvvvvvvvvvvvvvv????vv?????vrrrrrrrrrrrrrr~<br></br>
`wwwwwwwwww}v>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwwwwwwwwwwww~<br></br>
`wwwwwwwwwr}v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwwwwwwwwwwww~<br></br>
`wwwwwwwwwr}v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwwwwwwwwwrrrr~<br></br>
`wwwwwwwwwr}v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vrwwwrrrrrrrrr~<br></br>
`wwwwwwwwwr}??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}vw}??vwrrrrr}}}vvv`<br></br>
`wwwwwwwwwr}??vvrr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}v?+^vx}??v}vvvvvvvvvv`<br></br>
`rwwwwwwwwwv?vvrw>?}wr}vvvvvvvvvvvvvvvvvvvvvvvvvv}}v>^-`. vxv>?vvvvvvvvvvv`<br></br>
`}}}rrrrwwwv?vvz?..`~+vwr}vvvvvvvvvvvvvvvvvvvvvrzxv-..    ~x}>>v???vvvvvvv`<br></br>
`v}}}}}}r}rv?v}z` ....`zxzwwrr}vv??vvvvv??v}rwxxxuu~    .`|wr?+??>>??vvvvv`<br></br>
`vvvvv}vv}v??vrr-     `wyuuuxvvrw}?>???+>}rv^|zyxuyw. .`^vr}vv+>>+++>?vvvv`<br></br>
`vvvv??>??++?vv?++-.  .vyuuyx~ `|?r}}rr}r+`   ?yuxwv>-^?rrvvvv>+++++++?vvv`<br></br>
`vvv?>?++>++v?+?^^?>|. +yyuuy?    |uuyuur     .zyuv^>>+>v>>>?v?+^++++++??v`<br></br>
`vvv>^?+>+>>^|?}v.^vv?|+uyuuyw.  .?wvvv?w?.    >yu?+>+++^>v?`>?++^++++++?v-<br></br>
`vv?++>`^>+|+|^>|-?vv}}rxuzuyy- |rv|?vv?+vr?`  `xv->>>>^~>?>.>v>>|+++>>+>v-<br></br>
`vv>++?|>>+>vv???vvvvvvv}wxxuuvvz?+>vvvv???vw?>}w~->>+^??>+^?vv??^+++>>>+?-<br></br>
`v?+++>?>+^>?vvvvvvvvvvvvvvrzxxv+?vvvvvv?vvvvwwvvvvvvv?vvvvvvvvv?++^+>??+?-<br></br>
`v?+>>>++^^>?vvvvvvvvvvvvvvvv}v?vvvvvvvvvvvvvvvvvvvv?>>>?>>vvvvrv>+^+???^?-<br></br>
`v?+?>++^+^?vvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvv}v?^>++>+^vvvrwv+^+??v?+v`<br></br>
`v?+?v?>^^+?}vvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vvv>??+>v???vvvrwwv^^?vv?+?v`<br></br>
`vv>?vv?+^+?wrvvvvvvvvvvvvvvvvv???>?vvvv?>++>?v??^>>^+>+?^|?}www?++?vv>>vv`<br></br>
`vv?+?vv?+~?www}vvvvvvvvvvvvvvvv?+^|^^??>+?>?vvv>+>+>+|?vv.^}wrv?>+??>>vvv`<br></br>
`vvv?+?vv>|+vwww}?vvvvvvvvvv??vvvvv?++>>>>>?>+v+.+>>^^^^^~-?r}vvv>+>+>vvvv`<br></br>
`vvvv?+>??^>?}ww}v????vvvvv??vvvvvvvvv?^>+>>>>v?>??>??v???vvvvvvv?^|+???v?`<br></br>
`vvvvvv?>>^>vvvr}vv?>+?>>+?vvvvvv??++>>>+^+>?vvvvvvvvvvvvvvvvvvvv>||+>???|.<br></br>
.+?vvvvv>|^>vvvvvvv++>+^>~>?>vv?>+^^|^|^^||^^>??vvvvvvvvvvvvvvvv?+`|+>>+-. <br></br>
 .`^?v???+^>vvvvvvv??vv^>>+>|?>+|^+>>>>>>?>>+^+>??vvvvvvvvvvvvvv>~-++>>-.`.<br></br>
.|~`.~???^~^?vvvvvvvvvv?vvv??>+>??vvvvvvvvvvvv??????vvvvvvvvvv?>~-++>>>>`~.<br></br>
.+++-.|>>^~~>vvvvvvvvvvvvv?????vvvvvvvvvvvvvvvvv???vvvvvvvvvv?>~`+>+>???-~.<br></br>
.+++-``~++^-|?vvvvvvvvvvv????vvvvvvvvvvvvvvvvvvvvv?vvvvvvvvv?>~-+>>+?vvv+-.<br></br>
.+++```~>++^-~>vvvvvvvvvvvvvvvv????>>++++^>????vvvvvvvvvvvv>+~-+>>>>?vvvv-.<br></br>
.++|.-`~+^^+|-~>??????v?vvvv?v?>^~-``-~||~~~||>??vvvvvvvv?>^`-|^++++????v~ <br></br>


            </div>,

            <div>

`}}}}}}}}}}}r?^>?????????????????????????????????????????>>+?rrrrrrrrrrrrr~<br></br>
                  `wwwwwwwwwwwwv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??wwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwww}>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvv?>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rrzwv>vwrrrr}}vvvv`<br></br>
`v}}rrwwwwwwv?vvx~`+?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvv}rwrv+`^uv??vvvvvvvvvvv`<br></br>
`vvvvvv}}rrw??vww.   .-rxzr}vvvvvvvvvvvvvv?vvvvv}rz}?~.   .ww?>vvvvvvvvvvv`<br></br>
`vvvvvvvvvvv+?vx?      ?yuzxzwr}vv??vvvvv??v}rwzzxu?       vx?+?v??vvvvvvv`<br></br>
`vvvvv?>>?v?+?vr}^.    >yuxuy?+vwwv+???>^?wwv>vuxxyu`    ~vw}v>??++>?vvvvv`<br></br>
`vvvv>????>>?vvvvw}>.  |yyxuyv  .^vz}rrvrv^`  ~yuzwxv..^}zrvvv>++++^+>vvvv`<br></br>
`vvv>^?++>+^vv>>+>vwr>.`uyxuyz.   .xuuuxu`     ryx+?v++}v???vv?++++^^^>?vv`<br></br>
`vv?^+>>+>+>?^>v?`^vvww?zuxuyy-  .>w??v?w?.    +yw++>+^>^??|+v?++^^+^++>vv-<br></br>
`vv+++?|+>>^|~+?>.^vvvvrzxzxyy>.>wv^?vv?+vr>.  .z?^>>>>|+v}|.?v++|++++++?v-<br></br>
`v?+++>`+>++??>++?vvvvvvv}wxxxrwr>>?vvvv???}w+~vv.|>>+|>^^|->vv>>|+++++++?-<br></br>
`v>+++??>++>vvvvvvvvvvvvvvvvwxw?>?vvvvvv?vv?vzw}v??????vvvvvvvv??^^++++++?-<br></br>
`v>+?>++^^+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>>>>?+vvvvvv?++^++>>+?-<br></br>
`v?+v?++^^+v}vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvv}v++>++?|?vvv}r?++^+?v?^?-<br></br>
`vv+?v?>^^>vw}vvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv?vvv>v?>>vv??vvv}wr?^^+?vv+>v`<br></br>
`vv?+vvv>^^vww}vvvvvvvvvvvvvvvv?>+>?vvvv?>>>>??v?^>>^+?+>^^?rwww>~>vvv>>vv`<br></br>
`vvv>+?v?^~?wwwwvvvvvvvvvvvv??vv>+^|^>?>+?>>?v??>+>++>|?vv.+rwwv+|?vv?+?vv`<br></br>
`vvvv>^??^|?vwww??v?vvvvvv????vvvv?>+>>>>>>?|vv+`>>>^^|^+~`?wrvv>|>?>+?vv?`<br></br>
`vvvvv?+>^^vvvrr?vv????v???vvvvvvvv?vv+>+>>>>vv>+??>>?v?>>vv}vvv?|^+>vvvvv`<br></br>
.?vvvvv?^~+?vvvvv?v?++>>>^?vvvvv?>+^+>>>^+>?vvvvvvvvvvvvvvvvvvvv?|~^>?vvvv`<br></br>
 `^?vv?>^~+?vvvvvvv>+>+^+~?>?v?+^|||^^^^^||^>?vvvvvvvvvvvvvvvvvv?~~+++??+-.<br></br>
.|``~>++^~+vvvvvvvvvvv?>>>>+^^||^>>>>>>>>>+^|^>vvvvvvvvvvvvvvvv?+`|+++|..~.<br></br>
.++^.-++^-|?vvvvvvvvvvvvvv?+^>?vvvvvvvvvvvvv?>++?vvvvvvvvvvvvv?+-|++++| -+.<br></br>
.++~.|+++|`^?vvvvvvvvvvvv>>?vvvvvvvvvvvvvvvvvvv?+?vvvvvvvvvvv?+-^>^+>>>`.^.<br></br>
.++``^++++|`+?vvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^>+^>vv?|.|.<br></br>
.+^.~++++++~-+?vvvvvvvvvvvvvvv>???>++++++?vvvvvvvvvvvvvvvv?+^`^>>++?vvv?.~.<br></br>
.+~.|>?+^^^^-`^>?vv????vvvv?v?>|~--~||||~-~^^>?vvvv??????+|~`|^^^^+?vvvv``.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}?^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwww}+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzwww}vvvvvvvvvvv?vvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrr}}}vvv`<br></br>
`vv}}rwwwwwwv>vvu~`+vrwrvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvvvrwr?|.   .ww?+vvvvvvvvvvv`<br></br>
`vvvvvv?vvvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
`vvvvv>++>??+vv}r>.    +yyzuuv+vwwv+???>^?ww}?vuxzyu-    ~vw}v>>?>+>?vvvvv`<br></br>
`vvvv>??>>>??vvvvrr>.  ~yyzuy}  .|vz}rrvrv+`  ~uuxuyv .^}zrvvv>++++++>vvvv`<br></br>
`vvv>^?+>>+^?v????vww?`.xyzuyx.   .xuuuxu-     ry}?r}^?z}?vvvv?+++^^^^>vvv`<br></br>
`vv?^+>>+>++?>+??->vvrwvzuxuyy-  .>w??v?w?.    +y?^>+^+?+>^>vvv++^^+^^^>vv-<br></br>
`vv+++>++>>>|~>vv--vvvvrwxzxyy>.>wv^?vv?+vr>.  .w>+>+++|?}?.>vv++|++++++?v-<br></br>
`v?^++>.|>+^?>|^||?vvvvvvvwxxxwwr>>?vvvv???rr^~?>`>>?+^|+>|`?vv>>|+++++++?-<br></br>
`v>+++?>?++?vvvvvvvvvvvvvvvvwxw?>?vvvvvv?vv?vzzr^+?>++?v?>?vvvv??^^++++++?-<br></br>
`v>+?>>>^^+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>?>?vvvvvv?++^+++>+?-<br></br>
`v?+v?++^^>}}vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvv}?^>>^>+^vvvv}r?++^^>v?^?`<br></br>
`vv+?v?>^^>}w}vvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv?vvv???>?v??vvvv}wr?^^+?vv+>v`<br></br>
`vv?+?vv?^^}ww}vvvvvvvvvvvvvvv?>>+>?vvvv?>>>>??v?^>>^+?+>^>vrwww>~>vvv?+vv`<br></br>
`vvv>+?v?^|vwwwr??vvvvvvvvvv??vv>+^|+??>?>+>vv??>+>++>|?v?.>rww}+|?vv?+?v?`<br></br>
`vvvv>^??^^?vwwr??v?vvvvvv????vvvv?>+>+>>+?^?vv>`>>?+|~+>^.?rrvv>|?v>+?vv?`<br></br>
`vvvvv?++|+vvvrr?vv??>?v???vvvvvvvv?v>>?>>>>vvv^^?>++??>>>?v}vvv?~^+>vvvvv`<br></br>
.??vvvv?^~+vvvvvv?v?++>+>^>vvvvv?>+^>>?>^+?vvvvvvvvvvvvvv?vvvvvv?|~^>?vvvv`<br></br>
 `^?vv?>^~+vvvvvvvv?^?+++|?^vv?+||||^^^^^|^^>?vvvvvvvvvvvvvvvvvv?~~+++?v>|.<br></br>
.|-`~>++^~+vvvvvvvvvvv+????>>^||^>>>>>>>>>^^|^>vvvvvvvvvvvvvvvv?+`|+++|.`~.<br></br>
.++^.|++^-^?vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvv?>^+?vvvvvvvvvvvvv?+-|++++^ ~+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^>^+>>+.`^`<br></br>
.++`~+++++|-+?vvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^>+^>vv?~.|`<br></br>
.+^.^++++++~-+?vvvvvvvvvvvvvv?>???>+++++>?vvvvvvvvvvvvv?vv?+|-^>++^?vvv?.|`<br></br>
.+~`^>?+^^^^--^>?vv?vvvvvvvvv?+|-`-~||||~-~^+>?vvvvvvvv??+|~`|^^^^+vvvvv`-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}?^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwww}+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzwww}vvvvvvvvvvv?vvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrr}}}vvv`<br></br>
`vv}}rwwwwwwv>vvu~`+vrwrvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvvvrwr?|.   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvvvvvvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
`vvvvv>++>??+vv}r>.    +yyzuuv+vwwv+???>^?ww}?vuxzyu-    ~vw}v>>?>+>?vvvvv`<br></br>
`vvvv>>>+>??>vvvvrr>.  ~yyzuy}  .|vz}rrvrv+`  ~uuxuyv .^}z}vvv>++++++>vvvv`<br></br>
`vvv>^?>??+^?vv??vvww?`.xyzuyx.   .xuuuxu-     ruvrxw+}z}vvvvv?+++^^^^>vvv`<br></br>
`vv?^+>>+>+^??+>>~?vvrwvzuxuyy-  .>w??v?w?.    >z^>?++v?>>>vvv?++^^+^^^>vv-<br></br>
`vv+++>>++>>+|>v}|`?vvvrwxzxyy>.>wv^?vv?+vr>.  .v++>+++^?v|~vvv++|++++++?v-<br></br>
`v?^++>-|>+^^+~++`^vvvvvvvwxxxwwr>>?vvvv???rr^~?|+>>>+~+vv-`?vv>>|+++++++?-<br></br>
`v>+++?|+++?vv???vvvvvvvvvvvwxw?>?vvvvvv?vv?vzz?.+>+^+?+^^+?vvv??^^++++++?`<br></br>
`v>+?+>?+^+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>>>??>vvvvvvv?++^+++>+?`<br></br>
`v?+v?+^^^+v}vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvv}>+>++?~?vvvv}r?++^^>v?^?`<br></br>
`vv+?v?>^^>}wvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv?vvv???>?v??vvvv}wr?^^+?vv>>v`<br></br>
`vv?+?vv?^^}ww}vvvvvvvvvvvvvvv?>>+>?vvvv?>>>>??v?^>>^>?>>^?vrwww>~>vvv?+vv`<br></br>
`vvv>+?v?^|vwwwr??vvvvvvvvvv??vv>+^|>?>>?++?vv??>+>++>^?v?.>www}+|?vv?+?v?`<br></br>
`vvvv>^??^^?vwwr??v?vvvvvv????vvvv?>>+>++?>^vvv>->>?+|~>?+.>rrvv>|?v>+?vv?`<br></br>
`vvvvv?++|+vvvrr?vv??>>v?>?vvvvvvvv??+??>>>?vvv~~>>+^??>+^?v}vvv?~^+>vvvvv`<br></br>
.??vvvv?^~+vvvvvv?v?+>>^?^+vvvvv?>+^>??>^+?vvvvvvvvvvvvvv?vvvvvv?|~^>?vvvv`<br></br>
 `^?vv?>^~+vvvvvvvv?^?++^^+^vv?+||||^^^^^|^^>?vvvvvvvvvvvvvvvvvv?~~+++?v>|.<br></br>
.|-`~>++^~+vvvvvvvvvv?>??????^||^>>>>>>>>>^^|^>vvvvvvvvvvvvvvvv?+`|+++|.`~.<br></br>
.++^.|++^-^?vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvv?>^+?vvvvvvvvvvvvv?+-|++++^ ~+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^>^+>>+.`^.<br></br>
.++`~+++++|-+?vvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^>+^>vv?~.|.<br></br>
.+^.^++++++~-+?vvvvvvvvvvvvvv?>???>+++++>?vvvvvvvvvvvvv?vv?+|-^>++^?vvv?.|.<br></br>
.+-`+>?+^^^^~-^>?vv?vvvvvvvvv?+|---~||||~-~^+>?vvvv??vv??+|~`|^^^^+vvvvv`-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwww}+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzwww}vvvvvvvvvvv?vvvvvvvvvv?vvvvvvvvvvv}rwzwv>vrwrrr}}}vvv`<br></br>
`vv}}rwwwwwwv>vvu~`+vrwrvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvvvrwr?^.   .ww?+vvvvvvvvvvv`<br></br>
`vvvvvv?vvvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
`vvvvv>++>??+vv}r>.    +yyzuuv+vwwv+???>^?ww}?vuxzyu-    ~vw}v>??>+>?vvvvv`<br></br>
`vvvv>+>++>?>vvvvrr>`  ~yyzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv>++++++>vvvv`<br></br>
`vvv>^????>>?vvvvvvww?`.uyzuyz.   .xuuuxu-     rxwxyx>}z}vvvvv?+++^^^^>vvv`<br></br>
`vv?^+>++>+^?v?>>+?vvrwvzuxuyy~  .>w?vv?w?.    +?>v?>v}???vvvvv++^^++^^>vv`<br></br>
`vv+++>>+>+>?++vv^`?vvvrwxzxyy>.>wv^?vv?+vw>.  `>+>+^+++?^|?vvv++|++++++?v`<br></br>
`v?^++>^^>>>~||??~`?vvvvvvwxxxwwr>>?vvvv???rr^~>+>>>>+|?}?.>vvv>>|+++++++?`<br></br>
`v>+++>`~>+>?v>+^>?vvvvvvvvvwxw?>?vvvvvv?vv?vzw~->>>|+^++-|?vvv??^^+++++^?`<br></br>
`v>+?+>??+>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>??>+>??+>vvvvvvv?++^+++>^?`<br></br>
`v?+v?++^+>v}vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvv+>>^?+^vvvvv}r?++^^>v?^?`<br></br>
`vv+?v?>^^>}wvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvv?vvv????v??vvvvv}wr?^^+?vv+>v`<br></br>
`vv?+?vv?^^}ww}vvvvvvvvvvvvvvv?>>+>?vvvv?>>>>??v?^>>^>v>>+?vrwww>~>vvv?+vv`<br></br>
`vvv>+?v?^|vwwwr??vvvvvvvvvv??v?>+|+?>+?>++?vv??>+>+++^>v>`?wwwv+|?vv?+?v?`<br></br>
`vvvv>^??^^?vwww?vv?vvvvvv????vvvv?>++>+>?~vvvv>~>>>>|~>?>.>rrvv>~?v>+?vv?`<br></br>
`vvvvv?++|+vvvrr?vv?>>>v?>?vvvvvvvv?+???>>?vvvv-~>++^??+^^?v}vvv?~^+>vvvvv`<br></br>
.??vvvv?^~+vvvvvv?v?^>+^>+^vvvvv?>+^>>?>^+>?vvvvvvvvvvvvv?vvvvvv?||^>?vvvv`<br></br>
 .^?vv?>^~+vvvvvvvv?++>>^+|?vv?+||||^^^^^|^^>?vvvvvvvvvvvvvvvvvv?~|+++?v>|.<br></br>
.|-`~+++^~+vvvvvvvvvv???v???>^||^>>>>>>>>>^^|^>vvvvvvvvvvvvvvvv?+`|+++|.`~.<br></br>
.++^.|>+^-^?vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvv?>^+?vvvvvvvvvvvvv?+-|++++^ ~+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^>^+>>+.`^.<br></br>
.++`~+++++|-+?vvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^>+^>vv?~.|`<br></br>
.+^.^++++++~-+?vvvvvvvvvvvvvv?>???>+++++>?vvvvvvvvvvvvv?vv?+|-^>++^?vvv?.|`<br></br>
.+-`+>?+^^^+~-^>vvv?vvvvvvvvv?+|-`-~||||~-~^^>?vvvvvvvv??+|-`|^^^^+vvvv?.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>?????????????????vvvvvvvvvvvvvvvv??vv???>>>^?rrrrrrrrrrrrr~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>}wwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwww}+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzwww}vvvvvvvvvvv?vvvvvvvvvv?vvvvvvvvvvv}rwzwv>vrwrrr}}}vvv`<br></br>
`vv}}rwwwwwwv>vvu~`+vrwrvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvvv}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvvvrwr?^.   .ww?+vvvvvvvvvvv`<br></br>
`vvvvvvvvvvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
`vvvvv>++>??+vv}r>.    +yyzuuv+vwwv+???>^?ww}?vuxzyu-    ~vw}v>??>+>?vvvvv`<br></br>
`vvvv>^++++?>vvvvrr>`  ~yyzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv>++++++>vvvv`<br></br>
`vvv>^???>?>?vvvvvvww?`.uyzuyz.   .xuuuxu-     ruxxyx>rz}vvvvv?+++^^^^>vvv`<br></br>
`vv?^+>+>>+^>vv???vvvrwvzuxuyy~  .>w?vv?w?.    ^?}rvvw}vvvvvvvv++^^+++^>vv`<br></br>
`vv+++>>^>++??^??|~?vvvrwxzxyy>.>wv^?vv?+vr+.  ^+>>^|?+>++?vvvv++|++++++?v`<br></br>
`v?^++>>+>>?|~^v}>.?vvvvvvwxxxwwr>>?vvvv???rr^~>+>++>^>vv~~vvvv>>|+++++++?`<br></br>
`v>+++>-`>+^>?^^|~>vvvvvvvvvwxw?>?vvvvvv?vv?vzv-+??>^|+?>`^vvvv??^^+++++^?`<br></br>
`v>+?+>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+~?>+^>?>^>?vvvvvv?++^+++>^?`<br></br>
`v?+v?>>++>v}vvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvv>>>++?~?vvvvv}r?++^^>v?^?`<br></br>
`vv+?v?>^^>}wvvvvvvvvvvvvvvvvv???vvvvvvvvvvvv?vvv>??????vvvvv}wr?^^+?vv+>v`<br></br>
`vv?+?vv?^^}ww}vvvvvvvvvvvvvvv?>>+>vvvv??>>>>??v>+?>^?v>>>?vrwww>~>vvv?+vv`<br></br>
`vvv>+?v?^|vwwwr??vvvvvvvvvv??vv>+^>>+??+^>?vv??>+>+^+^>?+`?wwwv+|?vv?+?v?`<br></br>
`vvvv>^??^^?vwwr?vv?vv?vvv????vvvv?>+>+^>|?vvvv>|>+>>|~??>.>rrvv>~?v>+?vv?`<br></br>
`vvvvv?++|+vvvrr?vv?>>+??>>vvvvvvvv>>???>?vvvvv`~>++|>>^^|>v}vvv?~^+>vvvvv`<br></br>
.??vvvv?^~+vvvvvv?v?^>+^+>|vvvvv?>+^>>>>^+>vvvvvvvv?vvvvv?vvvvvv?||^>?vvvv`<br></br>
 .|?vv?>^~+vvvvvvvvv+^>>^+^vvv?+||||^^^^^|^^>?vvvvvvvvvvvvvvvvvv?~|+++?v>|.<br></br>
.|-`-+++^~+vvvvvvvvvv?vvvvvv>^||^>>>>>>>>>^^|^>vvvvvvvvvvvvvvvv?+`^+++|.`~.<br></br>
.++^.|>+^-^?vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvv?>^+?vvvvvvvvvvvvv?+-|++++^ ~+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^+^+>>+.`^`<br></br>
.++`~+++++|-+?vvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^>+^>vv?~.|`<br></br>
.+^.+++++++~-+?vvvvvvvvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^>++^?vvv?.|`<br></br>
.+-`+>?+^^^+-`^>?vv?vv?vvvv?v?+|-`-~||||~-~^^>?vvvvvvvv??+|-`|^^^^+???v?.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}rv^>?????????v??????????????????????????????>>>^?r}}}}}}}}}}rr~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>wwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvrwzwv>vwwrrrr}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}}rw??vrz.   .-}xzw}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvv??vvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?++++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++^+^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuwzzwvvvvvvvvv+++^+^+^>vv`<br></br>
.vv++++++^++?vvvvvvvvvv}zxzxyy>.>wv^?vv?+vw>.  .zxw}vvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvvvwxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^>vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv???v>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv||^+>?>^>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+?>`+vvrvvv?~++>vvvvv`<br></br>
.??vvvv?^?++++?+~|??r|.?>^>>>|>?^+++>^~>+?^.?++++>>||?vv`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+^`^?v?vvv??.-++^|++~|~->|.^>>+|>||^~->vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>??vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++| |+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^+^+>>+.`^.<br></br>
.++`|>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???+++++++?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^|^^--^>??v??vvvvvvvv?+|-`-~||||~-~^+>?vvvvvvvv??>|~`^^^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}?^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}-<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>wwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrrr}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}}rw??vrz.   .-}xzw}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?>vvvvvvvvvvv`<br></br>
.vvvvvv??vvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?++++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++^+^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuwzzwvvvvvvvvv+++^+^+^>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxw}vvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvv}wxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^>vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv???v>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv||^+>?>^>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+?>`+vvrvvv?~++>vvvvv`<br></br>
.??vvvv?^?++++?+~|??r|.?>^>>>|>?^+++>^~>+?^.?++++>>||?vv`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+^`^?v?vvv??.-++^|++~|~->|.^>>+~>||^~->vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>>?vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^+^+>>+.`^.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>?vv??vvvvvvvv?+|-`-|||||~-~^+>?vvvvvvvv??>|~`^^^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}-<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>}wwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>wwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrrr}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}}rw??vrz.   .-}xzw}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvv??vvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?++++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++^+^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuwzzwvvvvvvvvv+++^+^+^>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxz}vvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvv}wxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^>vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv???v>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv||^+>?>+>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+?>`+vvrvvv?~+^>vvvvv`<br></br>
.??vvvv?^?++++?+~|??r|.?>^>>>|>?^+++>^~>+?^.?++++>>||?vv`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~->vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>>?vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?+?vvvv?vv?vvv?^-^+^+>>+.`^.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>vvv??vvvvvvvv?+~-`-|||||~-~^+>?vvvvvvvv??>|~`^^^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>????????????????????????????????????????>>>^?r}}}}}}}}}}}r~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr+vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrr}}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvv}}rrw??vrz.   .-}xzw}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?>vvvvvvvvvvv`<br></br>
.vvvvvv??vvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>+>?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?>+++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++^+^+++>vvvvvvvvrwvzuxuyy~  .>w?vv?w?.    +yuwzzwvvvvvvvvv+++^+^+^>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxw}vvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvvvwxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^>vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv????>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>?^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv^|^+>?>+>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+?>`+vvrvvv?~+^>vvvvv`<br></br>
.??vvvv?^?++++?+~|??r|.?>^>>>|>?^+++>+~>+?^.?++++>>||?vv`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~-?vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>>?vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++-.++++|-+?vvvvvvvvvvv?>+?vvvvvvvvvvvvvvvvvvv?^?vvvv?vv?vvv?^-^+^+>>+.`^.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>vvv?vvvvvvvvv?+~-`-~||||~-~^+>?vvvvvvvv??>|~`|^^^^^v??vv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}rv^>????????????????????????????????v???v???>>>^?r}}}}}}}}}}rr~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr+vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv}rwzwv>vwwrrr}}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvvv}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?>vvvvvvvvvvv`<br></br>
.vvvvvv??vvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vz}v>>?>+>?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?>+++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyxu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++^+^+++>vvvvvvvvrwvzuxuyy~  .>w?vv?w?.    +yuwzzwvvvvvvvvv+++^+^^^>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxw}vvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvvvwxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^>vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv????>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>?^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv^|^+>?>+>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.v?vvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+?>`+vvrvvv?~+^>vvvvv`<br></br>
.??vvvv?^?++++?+~|??r|.?>^>>>|>?^+++>+~>+?^.?++++>>||?vv`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~-?vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>>?vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++-.++++|-+?vvvvvvvvvvv?>+?vvvvvvvvvvvvvvvvvvv?^?vvvv?vv?vvv?^-^+^+>>+.`^.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>?vv??vvvvvvvv?+~-`-~||||~-~^+>?vvvvvvvv??+|~`|^^^^^v??vv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}rv^>?????????v??????????????????????v???v???>>>^?r}}}}}}}}}}rr~<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr+vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvrwzwv>vwwrrrr}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvvv}rrw??vrz.   .-}xzr}vvvvvvvvvvvvvv?vvvvv}rwr?^`   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvv?vvvv>?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vvvvvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?>+++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++++^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuzxzwvvvvvvvvv+++^+^^+>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxzrvvvvvvvvvvv+>^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvv}wxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^?vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv????>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvv???vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^>++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv^|^+>?>^>>^^v>+^~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+v>`+vvrvvv?~++>vvvvv`<br></br>
.??vvvv?^?++^+?+~|??r|.?>^>>>|>?^++^>+~>+?^.?++++>>||?}v`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~-?vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>??vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++-.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?^?vvvv?vv?vvv?^-^+^+>>+.`+.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>?vv??vv???vvv?+|-`-|||||~-~^+>?vvvvvvvv??>^~`^^^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}-<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr+vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvrwzwv>vwwrrr}}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvvv}rrw??vrz.   .-}xzr}vvvvvv?vvvvvvv?vvvvv}rwr?^`   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvv??vvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?>+++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++++^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuzxzwvvvvvvvvv+++^+^^+>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxzrvvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvv}wxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^?vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv????>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvvv??vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^?++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv^|^+>?>^>>^^v>++~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+v>`+vvrvvv?~++>vvvvv`<br></br>
.??vvvv?^?++^+?+~|??r|.?>^>>>|>?^+++>+~>+?^.?++++>>||?}v`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~-?vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>??vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?^?vvvv?vv?vvv?^-^+^+>>+.`+.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>?vv?vvvvvvvvvv+|---|||||~-~^+>?vvvvvvvv??>^~`^+^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}v^>????????????????????????????????????????>>+^?}}}}}}}}}}}}}-<br></br>
`wwwwwwwwwwwwv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?wwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww??vvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv>rwwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwww??vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vwwwwwwwwwwww~<br></br>
`wwwwwwwwwwww>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??wwwwwwwwwwww~<br></br>
`wwwwwwwwwwwr+vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvvvv>rwwwwwwwwwrr~<br></br>
`rwwwwwwwwwwv>vvzzww}vvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvrwzwv>vwwrrr}}}vvv`<br></br>
`vvv}rwwwwwwv>vvu~`^?rwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwv>-^uv?>vvvvvvvvvvv`<br></br>
`vvvvvvv}rrw??vrz.   .-}xzr}vvvvvv?vvvvvvv?vvvvv}rwr?^`   .ww?+vvvvvvvvvvv`<br></br>
.vvvvvv??vvv+?vxv      ?yuzxzwr}vv??vvvvv>?vvrwzzxuv       vuv+?vv?vvvvvvv`<br></br>
.vvvvv>++>??+vv}r>.    +yuzuuv+vwwv+>??>+?ww}?vuxzyu-    ~vw}v>>?>++?vvvvv`<br></br>
.vvvv>+++++>+vvvvrw?`  ~yuzuy}  .|vz}rrvrv+`  ~yyzuyv .^}zrvvv?>+++^+>vvvv`<br></br>
.vvv>+^^++++>vvvvvvrw?`.uyxuyx.   .xuyyuu-     ryxxyx>}w}vvvvv?+++^^^^>vvv`<br></br>
.vv?++++^+++>vvvvvvvvrzvzuxuyy~  .>w?vv?w?.    +yuzxzwvvvvvvvvv+++^+^^+>vv`<br></br>
.vv++++++^++?vvvvvvvvvvrzxzxyy>.>wv^?vv?+vw>.  .zxzrvvvvvvvvvvv++^++++++?v`<br></br>
`v?+++++++^>?vvvvvvvvvvvv}wxwrrzr>>?vvvv???rw^.?w}vvvvvvvvvvvvv>>|++++++^v`<br></br>
`v>++++++^^?vvvvvvvvvvvvvvvvww?+>?vvvvvv?vvv??}}vvvvvvvvvvvvvvv??^^+++++^?`<br></br>
`v>+?+++^^+vvvvvvvvvvvvvvv????>+vvvvvvvvvvvv?+????vvvvvvvvvvvvvv?++^+++>^?`<br></br>
`v?+v?+^^>>??vvvvv??vvvv?+^????vvvvvv?vvvvvvv????>~+vvvvvvvvvv}r?++^^>v?^?`<br></br>
.vv+?v?>^>++>>?^?++>>?^?++>>?>>???v>>++?+>vvv?vvvvv`vvvvvvvvv}wr?^^+?vv>>v`<br></br>
.vv?+?vv?^^>>>>^+^>+^>|>|+>+>++++^^^>+^>|~+>?v?vvv?|vvvvvvvvrwww>~>vvv?+vv`<br></br>
.vvv>+?vv??vr?>v??vvvvvv?^>v??vv??>>>?+^+|`?vv???v+?vvvvvv?}wwwv+|?vv?+vv?`<br></br>
.vvvv>^?v>+>+^|>?v>+>?vvvv^|^+>?>^>>^^v>++~^>?|>>+|?v>>>>??vwrvv>~?v>+?vv?`<br></br>
.vvvvv?++?^^>+^??^+?>-+vv??v?>??>^++^++^??|~??^+>+^+^+v>`+vvrvvv?~++>vvvvv`<br></br>
.??vvvv?^?++^+?+~|??r|.?>^>>>|>?^+++>+~>+?^.?++++>>||?}v`-vvvvvv?~|^>vvvvv`<br></br>
 `^?vv?+^+`^>>^~+~|+|`^?v?vvv??.`++^|++~|~->|.^>>+~>||^~-?vvvvvv?~|+++?v>|.<br></br>
.^-`~+++++->???vvv?>??vvvvvv>^?>??????>??>+?>>>??>?vvv??vvvvvvv?+-|+++|.`~.<br></br>
.++^.|++++??vvvvvvvvvvvvvv?^^>?vvvvvvvvvvvvvv?++vvvvvvvvvvvvvv?^-|^+++^ |+.<br></br>
.++~.++++|-+?vvvvvvvvvvvv>+?vvvvvvvvvvvvvvvvvvv?^?vvvv?vv?vvv?^-^+^+>>+.`+.<br></br>
.++`~>+++^|~+?vvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvv>^-^++^>vv?-.^.<br></br>
.+^.+++++^+|~+?vvvvv?vvvvvvvv?>???++++++>?vvvvvvvvvvvvv?vv>+|-^+++^?vvv?.|.<br></br>
.+-`+>?+^^^+--^>vvv??vvvvvvvvv+~---|||||~-~^+>?vvvvvvvv??>|~`^+^^^+vvvvv.-.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvvvvvvvvv}-<br></br>
`rrrrrrrrrrrrvv}}}}}}}vvvv}}}}}}}}}}}}}}}}}}}}}}vv}}vv}}}}}vvrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrvvvvvvvvvvvvvvvv}}}vvvvvvvvvvvvvvvv?vvvvvvvvvvv?rrrrrrrrrrrrr~<br></br>
`rrr}}}}}}}rr?vvvvvvvvvvvvvvvvv}vvvvvvvvvvvvvvvvvvvvvvvv}vv}vvrrrrrrrrrrrr~<br></br>
`rrrrrrrrrrrr?vvvvvvvvvvvvvvv??vvvvvvvvvvvv}}v}}}}}vvvvv}}}}vvrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrr}?vv}}vvvvvv???vvvvv}}}}}}vvvvvvvvvv}}}v}vvvvvvvv?}r}}}}}}}}}}-<br></br>
`rrrrrrrrrrr}?vvwwwwr}vvvvvvvv}vvvvvvv??????????vvvvvv?vv}r}v?v}}}}}vvvvv?`<br></br>
`v}}}rrrrrrrv?v}z+~>vrwr}vvvv}}}vvvvvvvvvvvvvvvvvvvvrwrv?^>z}vv}}vvvvvvvvv-<br></br>
`vvvvv}}}}rrvvvrw-```-^}zwr}}vvv}vvvv}vvvvv}}vv}}rwr?>~```-wwv?vvvvvvvvvvv-<br></br>
`vvvvvvvvvvv?vvzv``````?xzwzwwr}}vvvv}}vvvv}}rwwwzxv`````.`vzv?vvvvvvvvvvv-<br></br>
`vvvvv????vv?vvrr?~````>uxzxxv>vrw}?vvv??vww}?vxzzxx^````|?rrv?vv???vvvvvv-<br></br>
.vvvv??????v?v}v}wr?~``+xxzxx}``~+vwrrr}rv>|-`^xxwzxv``+v}vvvv????????vvvv-<br></br>
.?vv?????????}}}}v}ww?|~zxzxxz~```-zzxxzz^`````}zwwzw?v}v??????>???????vvv-<br></br>
.???>>>?????vvvvvvvv}rwvwxzzxx^``-?wvvvvwv-```.+ww}}}}v??v?vvv?>????????vv-<br></br>
.??++>>>>>>>?v?????vv??v}rrwzu?-?r}?vvvv?}r?-``~wzwr}v}}}}}}}}v?????????vv-<br></br>
.???????????vvvvvvvvvvvvv}wzwrrwr??vvvvvvvvrw>~?w}vvvvvvvvvvvvv??????????v-<br></br>
`vv????????vvvvvvvvvvvvvvvv}wwv??v}vvvv??vvvvv}}}v}vv}v}vvvvvvvvv????????v-<br></br>
`v??v??????v???vv??vvvvvvvvvvv??vvvvvvv??????>????v}v}vv}}}}}vvvv????????v-<br></br>
`vv?}v??????vvvvvv?vvvvvv???vvvvvvvvvv}vvvvvvvvv??+?}}vvvvvv}v}}v?????vv?v-<br></br>
`vv?v}v????????>?????v?v????v??vvvv????v??}}vv}}}}v|v}vvvvvvv}rrv>??vvv?vv-<br></br>
`vvv?v}vv??????>?>??????>????????????????>??vvvv}}v>v}vv}vvv}rrr?>?v}}v?vv-<br></br>
`vvv??vv}vv?v??v??vv}vvvv>?vvv}vvv???v????+v}}vvvv?v}}}}vvv}rrr}??vvvv?vvv-<br></br>
`vvvv??vv???>>+??v???vvv}v?>???v??????v???>??v?????vv????vv}rr}v??vv??vvvv`<br></br>
`vvvvvv??v?????vv??v?>?vvvvvv??v????????vv>>?v????????v?+?vvvv???+>>?vvvvv`<br></br>
.vvvvvvv????????>>vv}>|v?????>?v??????>??v?|v???????>v}v^^vvv???>^^+>?????`<br></br>
 -+?vv??>?^>??>+?>>?>|>vvvvvvv?~+???>??>>>^?>|????>?>>>+^?vvvv??>|^++>??>|.<br></br>
.|--|>>>?>|????vvv????vvvvvv?>?????????????????????vv???????????+~+>++^```.<br></br>
.^^~.+>>>>??vvvvvvvvvvvvvv?>>??vvvvvvvvvvv????>>?v?????????????+|^+++>^.`~.<br></br>
.^^-->>>>+|>?v?vv????????>>????????????????????>+????????????>+|^>+>>>>`.~.<br></br>
.^|`^>>>>>+^>????????????????????????????????????>??????????>+~^>++>???~.-.<br></br>
.^~`>>>>>>>^^>????????????????>??>+++>>>>?????????????????>>^|+>>>+?????.-.<br></br>
.|`~>>?>>+>>||+>??????????????+^|~|^^^^^|~|++>???????????>^|~^+++^+?????``.<br></br>


            </div>,

            <div>

                  `?vvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????????????????????????????????v-<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}rrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}vvv????vv}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`vvvv?????vvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}vvvv???v??v}r}}}}}}}}}}}}}}}}}r~<br></br>
`}}}}}}}}}}}}rrrrrrrr}vvv}v??>??????v?v}}}rrrrrrrrr}vvv}rrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}v?>>?v}rrrrrrrr}}}vvvvvv}}}}rr}vvvvvvvvvvvv????????`<br></br>
`}r}}}}}}}}}}}}}}}}}}}}r}vvv}r}}}}}v???>>>>>>>>????????>>?????????????????`<br></br>
`}rrr}}}rrrrr}}}}}rrrrrr}}}r}}}}}}}}}}}}}}}}}}}}vvv}}}}}}rrrrrrrrrrrrrrrrr~<br></br>
`}rrrr}}rrrrrrrr}}rrrrrrrrr}}rrrrrrrrrrrrrrrrrrrrrrrrrr}v}r}}rrrrrrrrrrrrr~<br></br>
`rr}}v}rrrrrrrrr}}}}}rrrrrrrrrrrrrrrr}}}rrrrr}}}}}}}}}}rv?}r}}}}rrrrrrrr}r~<br></br>
`vvv?v}rrrrrrrrrrrr}}rrrrrrrrrrrrrrrr}}}rrrrrrrrr}}}rrrr}??v}r}rrrrrrr}}}r~<br></br>
.?v?v}rrrrrrrr}}}r}}}}}}rrrrrr}}}rr}}rrr}}rrr}}}}}}}}}}}v?v??v}}}}}}}}}}}r~<br></br>
.?v}}}vv}r}rrrrrrrrrr}}}rrrrr}}}}}}}}rrr}}}}vvvv?vvvvvv??>>>>>>>?vv}}}}}}r~<br></br>
.???>??v}}}}}}}}}}}}}}}}r}}}}}}rrrrrrrrr}}}}vv???????????????????vvvv}}rrr~<br></br>
.>>++>?????????????????????vv}}}}}}rrr}}}}rrrrrrrrrrrrrrrrrrrrrrrrrrrr}rrr~<br></br>
.?vvvvvvvv}}}}}}}}}}}}}}}}}}rrrr}}}}}r}vrrv}rrr}}}}}}}}}}}}r}}r}}}}}}}}}rr~<br></br>
`}rrrrrrrrrrvvvvvvv}rrrrrr}}}}}}}}}}}}v??v??}}}}}}}}}}}}}}}r}vv}r}}}}}}}rr~<br></br>
`}rrrrr}vvv??????????vv}}}rrrrr}}vv????>>>+>?vvvvv}}}}}}}}}}rvvvrrr}}}}}rr~<br></br>
`}rrrr}vv}}v}}}}}}vv}}}}}}}}}rrr}}}}}}rv?vv}}}}}}}}}}}}}}}}}}}vvv}}rr}}}rr~<br></br>
`}rrrrrrr}}r}}vvr}vvrr}}}}}}}}}}}}}rrr}rv}rr}}rrrrr}}}}}}}}}}r}}v?v}rr}}rr~<br></br>
`}rrrrrr}}r}vv?}}vv}rr}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}rrvvv}}}}rr~<br></br>
`}r}}}}}}r}??>???vvrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r}v}}}}rr~<br></br>
`}r}}}}}}rv???>??v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrrrr~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v??????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vvv?>>>>+++++++>`<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????>>>>>>>>>>>`<br></br>
.???????????????????????????????????????????????????????>>+++>>>>>>>>>>>>>`<br></br>
.???????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.????????????????????>>>>>>>>>>>>>>>>>>>>>++++>>++>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???>???????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?>>>>>>>>>>>>>>>>>>>>>>>>>>???????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>


            </div>,

            <div>

                  `?vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????????????v??????????????????????v-<br></br>
`}}rrr}}}}vvvvv}}}}}vvv}}}}}}rrrrrrrrrr}rrrrrrrr}}}rrrrrrrrrrrrrrrrrrrrrrr~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}vvv}}}vvvvvvvv}}}}}}}}}}vvvvvvvvvvvv-<br></br>
`}}}vvvvvvv}}}}}}}}vvvvvvvvvvv}}}}}}}}vvvvvv??????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`}r}}}}}}}}}}}}}rrrr}}}v}}vvv?vvvvvvvv}}}}}}}}}}}}}}vvv}}}}}}}}}}rrrrrrrrr~<br></br>
`}r}}}rrrr}}}}}}}}}v}}}vv??vv}rrrrrrrr}}}}}}}}}}rrrrrr}}}}}}}}vvvvvvvvvvvv-<br></br>
`}r}}}}}}}}}}}}}vvv}}}}r}}}}}r}}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}rr}vvvvv}}}}}}}}}rrrrr}}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrr}}rrrrrrrrr}}rrrrrrrr}rrrrrrr}}}rr}}}}}}v}}}rrrrrrrrrrrr}r~<br></br>
`}}}}}}}}}}rrrrr}}}}}rrrrrrrrrrrrrrrr}}}rrr}}}vvv}}}}}}}v?v}}}}}rrrrrrrr}r~<br></br>
`??????vv}}rrrrr}}}}}}}}}vv}}}}rrrrrrrrrrrr}}vvv?vvvvvvvv??v}}}}}}rrr}}}}r~<br></br>
.?????vvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}rrrr}}}}}}}}}}vvvvv}}}}}}}}}}}r~<br></br>
.??vvvvvv}}}}}}}}}}}vv}}}}}rr}}}}}}}}}}}}}}}}}}vvvv}}}vvv??vv??vv}}}}}}}}r~<br></br>
`vvvvvv}}rrrr}}}}}}vvv}}}r}}}}}rrrrrrrrr}}}}}vvvvvvvvvvvvvvvvvvvvvv}}}}rrr~<br></br>
`????vvvvvvvvvvvv??????vvvvv}}}r}}}rrrr}}}}}}}rrrrrrrrrrrrrrrr}}rrrrrr}}rr~<br></br>
`vvvv}}}}}}}}}}}}v}}}v}}}}rrrrrr}}}}}r}}}}v}r}}}}}}}}}}}}}}}}}}}}}}}}}}}rr~<br></br>
`}rrrrrrrrrr}}vvvv}}}}rrrrr}}}}}}}}}}r}vvvvv}}}}}}}}}}}}}}}}}}}}r}v}rr}rrr~<br></br>
`}rrrrr}}}}vvvvvvvvvvv}}}}}rrrr}}}}vvvvv?v?vvvvv}}}}}}}}}}}}r}}}rr}}}}}}rr~<br></br>
`}rrrrr}}}}}}}}}}r}}}}}}}}}}}rrr}}}}}}r}v}}}}}}}}}}rrrrr}}}}}}}vv}rrrrrrrr~<br></br>
`}rrrrrr}}}rr}}vr}v}rr}}}}}}}}}}}}}}rrrrv}r}}}}}}}vvvvvvvvvvvvvv???vvvvvvv-<br></br>
`}rrrrrr}}r}vvv}}vv}rr}}}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????vv??`<br></br>
`}r}}}}}}r}vvvvvvv}rrr}}}}}}}}}}}}}}vvvv?????vvvvv??????vvvvvvvv?????vvv??`<br></br>
`}r}}}}}}r}vvvvvv}}}}}}}}}}}}}}}}}rrrr}}}}}}vvvvvvv}vvvvv}}}}}}}}}}}}vvvvv-<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}vvv????????????`<br></br>
                  .vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????`<br></br>
.?????????????????????????????????????????????vv??????????????>>??????????`<br></br>
.?????????????????????????????????????????????????????????????>>>??????>>?`<br></br>
.???????????????????>>>>>>>>>????????>>>>>>>>>>>>>>>>>>?>>>>>>>>>?>>>>?>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  `?vvvvvvvvvvvvvvvvvvvvvvvvv???????????????????????v??????????????????????v-<br></br>
`}}}}}}vvv?????vvvvvvvvvvvv}}}rrrrrrrrrv}rrrrrrrr}}rrrrrrrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}vvv???vv}}}}}}}vvr}}}}}}}vvvv??????????`<br></br>
`}r}}}}}}}rrrr}}}}}}}}vvv????v}}}}}}}}vvvv????>????>???>>>>>???>>>????????`<br></br>
`}r}}}}}}}}}}}}}rrrr}}}}}}vvv}}}}}}}}}}}}}}}}vvvvvvvvvvvvv}}}}}}}}}rrrrrrr~<br></br>
`}r}}rrrrrr}}}}}}}vv}}}}}rrrr}}}}}}}}}}}}}}rrrrrrrrrrrrrrrrrrrrrr}}}}r}}rr~<br></br>
`}r}}}vv}}}}}}rvvv?v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}rrrrrrrr~<br></br>
`}rrvv????v}r}}v}r}rrrrr}}}}}}}}}}}}}}}}}}}}}}rr}}}}r}}rrrrr}}rrrrrrrrrrrr~<br></br>
`}rr}}rrr}rrrrrr}}}rrrrr}}}rr}}}rrrrr}}}}}}rr}vvrrr}}}}vvv}}rrrrrrrrrrrrrr~<br></br>
`}}}}}}}}}}rrrrr}}rrrrrrrrrrrrrrrrrrr}}}rrr}}v?>?vvvvvvvv?vvv}}}rrrrrrrr}r~<br></br>
.?>>>>>??v}rrr}}}}}}}vvvvvvvv}}rrrrrrrrrrrr}vv?>>>>>???????vvvv}}}}rr}}}}r~<br></br>
.>++++>>??????>>>>>>>>???vv}}}}}r}r}}vvvv}}}rrrr}}}}}}}}}}}}}rrrrr}rrr}}}r~<br></br>
.???vvvvvvvvvvvvvvvvvvvvvv}rr}}}}}}}vvv}}}}}}rrr}rrrr}}}}}}}}rrrr}}rrr}}}r~<br></br>
`}rrrrrrrrrrrrrr}}}vvvvv}r}}}}}}rrrrrrr}}}}v}}}}}}}}}}}}}}}}}r}}r}}}}}}}rr~<br></br>
`}rrrrrrrrrrr}}}rv?????v}r}}}}}}}}}rrrrvvvvv}}}}}}}}}}}}}}}}}}}v}rr}}}}}rr~<br></br>
`}rrrrrrrrrrr}}}vvv}vv}rr}}}}}}}}}}}}}r}v}vvr}}}}}}}}}}}}}}}r}v}vvv}}}}}rr~<br></br>
`}rrrrrr}}}}}}rvv}}vv}rrrrr}}}}}}}}}}}}}}r}rr}}}}}}}}}}}}}}}}r}v}vv}r}}}rr~<br></br>
`}rrrr}}}}}}}}}}r}r}}rrr}}}rrrr}}}}}}}}}r}r}}}}}}}}}}}}}}}}}}}rrrr}}}}}}rr~<br></br>
`}rrrrrr}}}}}}}}}}}}}rrr}}}}}rrr}}}}}}}}}}rrrrrrrrrrrrrrr}rrr}}}rrrrrrrrrr~<br></br>
`}rrrrr}}}}}}}}}}}}}}rr}}}}}}}}}}}}}rrrrrr}}}}}vvv????????????????????????`<br></br>
`}rrrrrr}}}rrr}}}}}}}}}}}}vvvv????????????>>>>>>>+++++++++++++++++++++++++.<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vv??>+++++++++>>>>+++++>>>>>>>>>+++++++.<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrr}}}vvvv???vvvvvvvvvvvvvvvvvvvvvv????`<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrr}}}}}}}}}rrrrrrr~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}~<br></br>
                  .vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  .???????????????????????????????????????????????????????vvvvv????vvvvv??vv-<br></br>
.?????????????????????????????????????????????????????????????>????????>??`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>?????????????????????????????????>?>??????????>>.<br></br>
.>+++>>>>>>>>>>>>>>>>>>>>>?????????????????????????????>>+++++>>>>>>>>>>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>?????????????>>>??>>+>>>>>>>>?????????>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`?vvvvvvvvvvvvvvvvvvvvvvvv????????????>>>>>>>>>>>+>???????????????????????`<br></br>
`}}}}}}vvvvvvvvvvvvvvvvvvvv}}}r}}}}}}v????v??????vvvr}}}}}}}}}}}}}}}}}}}}}~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}v>+>?v??????vvvrr}}}}}}}vvvvvvvvvvv??`<br></br>
`}r}}}}}}}}}}}}}}}}}}}}vvv?vvv}}}}}}r}>>>>?>+^^^+???v????????????????vvvvv-<br></br>
`}r}}}}}}}v}}}}}rrrr}}}}}}}v}}}}}}}}r}????v??????vvv}vvv}}}}}}}}}}rrrrrrrr~<br></br>
`}r}}rrrrrr}r}}}}}}v}}}}}rr}}}}}}}}}r}????v??????}rrrr}rrrrrrrrr}}}}}}}}rr~<br></br>
`}rrr}}}}}}}r}r}vvvvr}}rrrrr}}}}}}}}r}????v??????vrr}vv}}}}}}}}}rrrrrrrrrr~<br></br>
`}}}vv???vvv}}}}}}}}}}}}}}}}}}}}}}}}r}????v??????vrrv}}}}}}}}}rrrrrrrrrrrr~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}rr}}}v???vv?????}rr}}}vv}}}rrrrrrrrrrrrrr~<br></br>
`vvvvvvvvvvvvvv}}}}}}}}}}rrrrrrrrrrrr}v???v???>+>v}}v}vvvvvvv}}}rrrrrrrrrr~<br></br>
.???????vv}}}}}r}}}}}vvvvvvv}}}rrrrrr}v???v???+^^?v??vvv?vvv}vv}r}rrr}rrrr~<br></br>
.???????????????????????vv}}}}}}}}r}}v?????v????v}rr}}}r}}}}}}}}}}}}}}}}}}~<br></br>
.?vvvvvvvvvvvvvvvvvvvvvvvv}rr}}}}}}}vv?????v???vvvr}vvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}rrrrrrrrrrrrr}}}}vvvv}}rr}}}}}}}}}}}v???????????}vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}rrrrrrrrrrr}}}rv???vvv}rr}}}}}}}}rrrr}??????????v}}}}}}}}rr}}vrrrrrrrrrr~<br></br>
`}rrrrrr}}}rr}}}vv}}}v}rr}}}}}}}}}}}}}}}}}vv}}}}}}r}}}}}}}}}r}v}vv}}}}}rrr~<br></br>
`}rrrrrr}}}}}}rvv}}}}}rr}}}}}}}}}}}}}}}}}}}rrrrr}}}}}}}}}}}}}r}v}vv}rr}rrr~<br></br>
`}rrrrrr}}}}}}}}rrr}}rr}}}}}}rr}}}}}}}}}r}r}}}}}}}}}}}}}}}}}}}rrrr}}}}}rrr~<br></br>
`vvv}}rr}}}}}}}}}}}rrrr}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrr}}}}}}}}}}}}}}}rrrrr~<br></br>
`vvvv}}vvvvvvvvvvvvvvvvvvvvvvvv}}}}}rrrr}}}}}}}}vvv??????????????????????v`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv??v?vvv????vv????????>>>>>??????????????????>`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}vv????????>>>++>++>>>>>>>>>>>>>>??????`<br></br>
`v}}}}}}}}}}}}}}}}}rrrrrrrr}}}rrrrrrrr}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrr}rrrrr}}}}}}}}rr~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}vvv}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>
                  .vvvvvvvv????????????vvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  .?????????????????????????????????????????????????????????vvv????vvvvv??vv-<br></br>
.??????????????????????????????????????????????????????????????????????>??`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>???????????????????????????????????>??????????>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>?????????????????????????????>>+>+>>>>>>>>>>>>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>???????????????????>>>??>>+>>>>>>>>?????????>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`?vvvvvvvvvvvvvvvvvvvvvvvvv???????vvv>....-`..... `^?+|^^^^^^^^^^^||||||^^.<br></br>
`rrrrrrrrrrrrrrrrrrrrr}}rrrrrr}}vv?}v>.```^-....`???}vvvvvvvvvvvvvv????vvv-<br></br>
`}r}}}}}}rrrrrr}}}}}}vvv}}}}}}}}}}}rzv````+-`````v}}zrrrrrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}vvvvvv}}}}}}}rr}}}}}}}}}}rrxv```->-````.?v}zrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}}?????v}}}}}}}}}}}}}}}}}}}}}zv````>~.````?}}zrr}r}}}}}}}}}rrr}}}rr~<br></br>
`}r}}}}}rrrrrrrr}}}}}}}}}}}}}}}}}}}}rv.```>~.````?rww}}rrrr}}}}}}}}rr}}}rr~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}rv.```>~.````?rrv??vvvv}}}}}rrrrrrrrrr~<br></br>
`vvvvvvvvvvvv}}}}}}}vvvvvvvvvvv}}}}}r}````+|.````?r}??vvvv}}}rrrrrrrrrrrrr~<br></br>
.+++++++++>>>>>>>>>>>>>>>>???vv}}rr}rr~.``>|.````?wr}rrrrrrr}rrrrrrrrrrrrr~<br></br>
.?>>>>>>>>>>>????vvv}}vv}}}rrrrrrrrrr}^.``+|.````?wr}r}}}}}}}}}}rrrrrrrrrr~<br></br>
`}}}}}}}vv}}}rrrrrrrrv?}rrrrrrrrrrr}r}+`.`||.````}wr}r}}}}}rrrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrr}}}}}}r}vv}rrrrrr}}rrrrr}^`.`-^.``.+rwr}rrrrr}}}}}}}}}}}}vvvv-<br></br>
`}rrrr}}}}}}}}}}}}}}}}}}}}}rr}}}}}vvvv^````|.``|?>rv??????>>>>>>>>>>>+++++.<br></br>
`}r}}}}}rrrrrrrr}}}}}r}}}}}}}}}}}vvv???~..`~.`````v?>?????????????????????`<br></br>
`}rrrrrrr}rrrr}}}}}}}}}}}}}}}}}}rrrrrrr}?|``````-|?v}}}}rrrrrrrrrrrrrrrrrr~<br></br>
`}rrrrrr}}rrrr}}r}}}}}}}}}}}}}}}}}}}}}}rrr}vvvv}}rrrrrr}}}rrrrrr}}}}}}}rrr~<br></br>
`}rrrrrr}}}}}rrrrr}}}}rr}}}}}}}}}}}}}}}}}}rrrrrrr}}}}}r}}}rrr}}}}}}}}}}rrr~<br></br>
`}rr}}rrrrrrr}}}}}}}}rr}}}}rrrrrr}}}}}}}}}}}}}}}}}}}}r}}r}}}}}}}}}}rrr}rrr~<br></br>
.????vrr}}}}}rrrrrrrrrrrr}}}}}}}}}}}}}}}}}}rrrrr}}}}}}vv}}}}}}}}}}?vv}}rrr~<br></br>
.++>?vv?>>>>??????????????>>>??vv}}}}}}}}}}}}rrrrrrvv??vv}}}}vv}}}v?v?vvvv-<br></br>
.>>++++++++++++++++++++++>>>?vv}r}}}}}}}}}}}}}r}}vv??vv}}}}rrrrrrrr}r}}}}v-<br></br>
.>>+++++++++++>>>???????vv}}rr}}}}}}}}}}}}}}r}??>++++++>>>??????????vv}}}}-<br></br>
`vvvvvvvvvvvvvvv}}rrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}vvvvvvv?vvvv????vvv}}}}r~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrrrr}}}}rr~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>
.vvvvvvv???>>+++>>>????vvvvvvvvvvvvvvvvv???????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.??????>>>>+++>>>>>?????????????????????>+>>>>>>>???????????vvvvvvvvvvvvvv-<br></br>
.?????????>???????????????????????????????????????????????????????????????`<br></br>
                  .?????????????????????????????????????????????????????????????????????????`<br></br>
.>>???????????????????????????????????????????????????????????????????????`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>???????????????????>>>>>>>>>>>>>>?????>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>


            </div>,

            <div>

`?vvvvvvvvvvvvvvvvvvv?????????????vvv>``..`....   .^?>++++++++++++++++++++.<br></br>
`rrrrrrrrrrrrrrrrrrrrvvvvvvv}r}}vv?}v?-`..-......++?rvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}r}}}}}}rrrrrr}}r}}}v??vvvv}}}}}}}rwv~`..~`.....>?rzrrrrrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}vvvvvv}}}}}}vvvvvvv}}}}}}rrz}-...|`.....+v}zrr}}}}}}}rr}}}}}rrr}r~<br></br>
`}r}}}}}}v???vv}}}}}}}}vvvv}}}}}}}r}w}`...|`.....+}}wr}}}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}rrrrrrrr}}}}}}}rrrr}}}}}}}}}rv`...|`.....+}wrv}}}}}}}}}}}}}}}}}}}}~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}rr}}rrv....|`.....+rwv??vvv}}}}}}rrrrrrrrrr~<br></br>
`vvvvvvvvvvvv}}}}}}}vvvvvvvvvvv}}}}rrv....|`.....>r}?vvvvv}}}}}}rrrrrrrrrr~<br></br>
.>>>>>>>>>>????????????????vv?v}}rrr}?`...|-.....>wr}rrrrrrr}}}}}}rrrrrrrr~<br></br>
.?>>>>>>>>?????vvvvv}}vv}}}}}}}}}rrr}?~...~-.....>wr}r}}r}}}}}}}}}}}}rrrrr~<br></br>
`vvvvvvvvvvvv}}}}}}}}v?}}}}}}}}}}}r}v?|...--.....?rrv}}}}}}}}}}}}}}rr}rrrr~<br></br>
`}}}}}}}}}}}}}}}}}}}}vv}}}}rrrrr}rrvvv~`...-....|v}r}}}}}}}}}}}}}}}}}}}}}v-<br></br>
`}}}}}r}}}rrrrr}}}}}}}}}}}rrrrr}}}}vvv|`..`~..`-?>}}????????????????????>>.<br></br>
`}r}}}}}}rrrrrrr}}}}}}}}}}}}}}}}}vvv??>-..`~``--~|}???????????????????????`<br></br>
`}rrrrr}}}rrr}}}}}}}}}}}}}}}}}}}rrrrv?vv>~````-~~^v}}}}rrrrrrrrrrrrrrrrrrr~<br></br>
`}rrrrr}}}}r}}}}}}}}}}}}}}}}}}}}}}}r}?vvvvv???v}}rrrrr}}}}rrrrrr}}}}}}}rrr~<br></br>
`}rrrrr}}}}}}}}rr}}}}rrr}}}}}}}}}}}}rvvvvvvvvvv}r}}}r}r}}}rrr}}}}}}}}}}rrr~<br></br>
`}rr}}}}rr}}}}}}}}}}}}}}}}}}}rrrr}}}rvvvvvvvvvv}}}}}}vv}}}}}}}}}}}}rrr}rrr~<br></br>
.???vvrr}}}}}vvv}}rrrrrrr}}}}}vv}}}}}vvvvvvvvvvv}}}}}}}v}}}}}}}}}}vvv}}}rr~<br></br>
.>???vvv???????????????????????vv}}}}vvvvvvvvvvv}rrvvvvv}}}}}}}}}}v?vvvvvv-<br></br>
.>????>>>????>>>>>>>>>>>>????vv}r}}}vvvvvvvvvvvvvvv?vvv}}}}rrrrrrrr}r}}}vv-<br></br>
.????????????????????vvvvv}}}r}}}}}}vvvvvvvvvv?>>>>>+++>>>??????????vvvvv}~<br></br>
`vvvvvvvvvvvvvv}}}rrrrrrrrrr}}}}}}vvvvvvvvvvvvvvvvvvvvv????????????vvvvvv}~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vvv?vvvvvvvvvvvvvr}rr}vvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????vv}}}vvvvvvvvvvvvvvvvvvv-<br></br>
.vvvvvvv???>>>>>>>>???vvvvvvvvvvvvvvvv??>++++>>>>>?vvvvvvv???vvvvvvvvvvvvv-<br></br>
.??????>>>>+>>>>>>???????????????????????>>>>?????????????????????????????`<br></br>
.??>>?????>???????????????????????????????????????????????????????????????`<br></br>
                  .?????????????????????????????????????????????????????????????????????????`<br></br>
.>>>>???????????????????????????????????????????>>>>>>>>>>>>>>>>???>>>>>?>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>????>>>????????????????????>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>


            </div>,

            <div>

                  `?vvvvvv?vv??vvvvv???|.`....???????vv???.          +??????????????????????`<br></br>
`rrrrrrrrrrrrrrrrrrr}+`````.vrrrrrrrrrr?          .}}}}}}}}}}}}}}}}}}}}}}}~<br></br>
`}r}}}}}}rrrwrrrrrrr}>````..vr}}}rr}}}r^          |r}}}}r}}}}}}}}}}rrrrrrr~<br></br>
`}r}}}}}}}vvv}vvvvvvv?-`...-}r}}}rw}}r}.          ?r}}}}rrr}rrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}r}rr}}}}}}v>^^>vr}}}}rwrrr?           vr}}}}}}}}}}}}}}}}}}v}}}-<br></br>
`}r}}}}}}}}rrrrrrr}}}}rrrrrrr}}wr}rrv}~          .????????v???vvvvvv??????`<br></br>
`}r}}}}}}}}}}rrrrwr}}}}}}}}}}vvww}rw}v.          .}}}}}}}rr}rrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}}}}rrrrr}r}}}}r?v??rwrrww>           `rrrrrrrw}}}}}}rrrrrrrrrr~<br></br>
`rrr}}}}}}}}rr}}rwrrrrrrrrr}}>>rrrrwr`           `rrrrr}wr}rrr}}rrrrrrrr}r~<br></br>
`???>>>>>?v}}}}}rrrrrr}}}vvv}??}}wrw?            .}}}}}}w}}}}}}}}}}}}}}}}r~<br></br>
.>???++++???>>>?????v?v???????vvv}r}|             >??vv}vv????vv}}}}}}}}}r~<br></br>
.>>?vvv?????vvvv}}}}}}}rrrrrrrrrvrw+^|.           >v???v????vvv}}}}}}}}}}r~<br></br>
.???v}rrrrrrrrrrrrrrrrr}rr}rrr}wr}r}rv........|.>>?rrrwwrrrrrrrrr}}}}}}}}r~<br></br>
`rrrrr}}}}}rr}}}}rr}}}}}}r}}}}}rw}rwv~````````v>}r}}r}wrr}rrr}}}}}}}}}}rrr~<br></br>
`}rrrrr}}}}}}}vvvvv?????v}}}}}}}rr}w?.````````?v}}}}}rr}}}}}}}}}}}}}}}}rrr~<br></br>
`}rrrrr}}}}}}}}}}}}}}}}v}}}}}}}}}w}rv.````````>}rrr}}wr}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}rr}}}r}}}}}}}rrrrrrr}}}}}}}}rrrz|.```````^vr}}}rrr}}}rrr}}}}}}}}}}rrr~<br></br>
`}rrr}}}}r}vvvvvv}v}}}}}}}}}}rrr}}rv}|.```````>vrr}v}>}r}}}}}}}}}}}}}}}rrr~<br></br>
`}rrrrr}}}}v>>>>?v}}}}}}}}}rr}?v}r}}}+.``````.`>}}}rr?}r}}rrr}}}}}}}}}}rrr~<br></br>
`}rrrr}}}rr}}}}}}}}}}}}}}}}rrr??v}}}?|-|~```...^vvrz}rwrrrrrrr}}rrrrrrrrrr~<br></br>
`}rr}r}}}}}}}r}}}}}}}}}}}}}}}rvrr}}?|`-.````````>vrr}r}}}}}}r}}}}}}}}}}}}}-<br></br>
`}rr}rr}}}}}}}}}}}}}}}}}}}}}}}rr}}v?|``````````||vr??>>>>>??????>>>>>>>>>>.<br></br>
`}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}v>++^.````````->>>?rrv?>>>>>>+++>>>>>>>>++.<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v>>^.```````````.+r}rrv>>>>>>>>>>>>>>>>>+.<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??- ...........->v}}v??>>>>>>>>>>>>>>>+.<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>|~```.```-|+?vvv??>>>>>>>>>>>>>>>>+.<br></br>
.?????????????????????????????????????vvv????????????>>>>>>>>>>>>>>>>>>>>>.<br></br>
.????????????????????????>>>??????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?>>>?????>>>????????>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  `?vvv????vv??vvv?????~.`....????????????.          +??????????????????????`<br></br>
`rrr}}}}}}rr}}}}}}}r}+`````.v}}}}}}}}}}?          .}}}}}}}}}}}}}}}}}}}}}}}-<br></br>
`}}}}}}}}}}}r}}}}}}}}>`````.v}}}}}}}}}r^          |r}}}}}}}}}}}}}}}}}}}}}}-<br></br>
`}}}}}}}}vvvvvvvvvvvv?-```.-}}}}}}r}}}}`          ?}}}}}}}}}}}}}}}}}}}}}}}-<br></br>
`}}}}}}}}}v}}}}}vv}}}}v>^^>v}}}}}}r}}}?           v}}}}}vvvvvvvvvvvvvvvvvv-<br></br>
`}}}}}}}}}}}}r}r}}}}}}}}}}r}}}}r}}r}vv~          .????????v???vvvv????????`<br></br>
`}r}}}}}}}}}}}rrrr}}}}}}}}}}}vvrr}rw}}.          `}}}}}}}rrrrrrrrrrrrrrrrr~<br></br>
`}r}}rrrrrr}}}}rrrwr}rrrrrr?v??rwrrww>           `rrrrr}rw}}}}}}}}}}}}}}}r~<br></br>
`rrr}}}}}}}}rr}}rwrrrrrrrrr}}>>rrrrwr`           `rrrrrrwr}r}r}}}rrr}}}}}r~<br></br>
`?????????v}}}}}rrrrrr}}}vvv}??}}wrw?            .}}}}}}w}}}}}}}}}}}}}}}}r~<br></br>
.>???>>>>???>>>?????v?v???????vvv}r}|            .>??vv}vv????vv}}}}}}}}}r~<br></br>
.>??vvv?????vvvv}}}}}}}rrrrr}rrrvrw++^..          >v???v???vvvv}}}}}}}}}}r~<br></br>
.???v}rrrrrrrrrrrrrrrrr}rr}}}r}wr}r}rv.`......|`>>?rrrwwrrrrrrrrr}}}}}}}}r~<br></br>
`rrrr}}}}}}}}}}}}rr}}}}}}}}}}}}rw}rwv|-```````v>}r}}}}wr}}rrr}}}}}}}}}}rrr~<br></br>
`}rrrrr}}}}}}}vvvvv?????v}}}}}}}rr}w?.~```````?}}}}}}rr}}}}}}}}}}}}}}}}rrr~<br></br>
`}rrrrr}}}}}}}}}}}}}}}}v}}}}}}}}}w}rv`````````>}r}r}}wr}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}r}}}}}}}rrrrrrr}}}}}}}}rr}z^````````^v}}}}rrr}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}r}vvvvvv}v}}}}}}}}}}rrr}}rv}^````````>v}r}v}>}r}}}}}}}}}}}}}}}}rr~<br></br>
`}r}}}}}}}}v>>>>?v}}}}}}}}}}r}?v}r}}}>``----```>}}}rr?}r}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}}}r}}}}}}}}}}}}}}}}}}r??v}}}v^~^|-`````^vvrz}rr}}}}}}}}}}}}}}}}}}}~<br></br>
`}rr}}}}}}}}r}}}}}}}}}}}}}}}}rvrr}}?^-~`-------->vrr}r}}}}}}rr}}}}}}}}}}}}-<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}v?^`-`-------^^v}?v?>>???????????>>>>>>>`<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v?>>^`-------`~??>?rrv??>>>>>>>>>>>>>>>>>>.<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v?>+.`----------`>r}rrv>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}v??-...````````.~?v}}vv?>>>>>>>>>>>>>>>>`<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>|~-`````--|+?vvv???>>>>>>>>>>>>>>>>`<br></br>
.?????????????????????????????????????vvv????????????>>>>>>>>>>>>>>>>>>>>>`<br></br>
.?????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?>>>?????>>>?????>>>>>>??>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.>>+|^~~~~|~^??^~~~~~~~~~-......~~~~~~~~~`      `~~~~~~~~~~~~~~~~>|~>>~--~.<br></br>
                  `?^||||||||||+^|||||||||||~```-~|||||||||.      ~||||||||||||||||^||^|||||.<br></br>
                  .+|||||||||||||||||||||||||||||||||||||||.      ~|||||||||||||||||||||||||.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||~       ~|||||||||||||||||||||||||.<br></br>
                  .||||||||||~||||||||||||||||~~~~~|~~||||`       ~|||~||~~~~~~~~~~~~~~~~~~~.<br></br>
.~~~~||||||||~~~~~~~~~|||||^^^^^^^^^++++`      .++>>+^>???????????????????`<br></br>
.>??vvvvvvvv?????????vvvvvv}}}}}}}}}}}r}.      ~rrrrr}}rrrrrrrrrrrrrrrrrrr~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}r}}}rr?       ~rr}rrrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}rrrrrr}}}}}}}}}}}}}}}}}}}r}vv}}}}vvv}~       `rrrrrrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}vvvv}?.       .v}}}rrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}}vvv??vv???????vvvvvvvvvvvvvv}}rrrrrv?|  ...~`^????vvvv}}}}}}}}}}}}}}}}r~<br></br>
`}}vvvvvvv??????vv}}}}rrrrrrrr}}}rr}}}rr~.``.|v}vv}}}}}}}}rrr}}}}}}rrrrrrr~<br></br>
`}rrrrrrrrr}}}rrrrr}}}}v??vv}r}}}}}}}}rv`````^rrrrrrrrr}rrrrr}}}}}}rrrrrrr~<br></br>
`}rrr}}}}}}rrr}}}}}}}}}vvvvv}}}}}}}}}}rv```..?}}r}}}}}}}}}}rr}}}}}}}}}}rrr~<br></br>
`}r}}rr}}}}}}}}}}}}}rrrrvv??vr}}}}}}}}rv`````-?v}rr}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rrrr}}}}}}}}}}}}}}}}}}}rrrr}}}}}}}}}?>.`````-vv}r}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}}??`......>?}r}}}rrrrrrrrrrrrrrrrrrrr~<br></br>
`}r}}}}rrrr}}}}}}rr}}}}}}}}}}}}}}}}}}}rr}?^~--~^vrrrrrv????vv?????????????`<br></br>
`}r}}}}vvv????>>>??vvv}r}}}}}}}}}}}}}}}}v}}vvvvv????vv>+++++++++++++++++++.<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv????vvv????????????????>>>>>`<br></br>
`v}}}}}}}}}}r}}}}}}}}}}}}}}}}rr}}}}}}}rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}-<br></br>
.???????????vvvvvvv}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrr}}rrr}r~<br></br>
`}}}}}}}}}}}}}}rrrr}rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrr}}}}}}r~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}v}~<br></br>
                  .vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  .????????????????????????????????????????????????????????????vvvvvvvvvvvvv-<br></br>
                  .?????????????????????????????????????????????????????????????????????????`<br></br>
.?????????????????????????????????????????????????????????>>>>>>??>>?????>`<br></br>
.????????????????????????????????????????????????????>>>>???????????????>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>?>>>>>>>>>+>>>>>>>?>>?>>>>>?>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>++||+^+++^+++>++^+>>>>.<br></br>


            </div>,

            <div>

.>>+|^~~~~|~^??^~~~~~~~~~-......~~~~~~~~~`      `~~~~~~~~~~~~~~~~>|~>>~--~.<br></br>
                  `?^||||||||||+^|||||||||||~```-~|||||||||.      ~||||||||||||||||^||^|||||.<br></br>
                  .+||||||||||||||||||||||||||||||~~|||||||.      ~|||||||||||||||||||||||||.<br></br>
                  .|||||||||||||||||||||||||||||~~~~||||||~       ~|||||||||||||||||||||||||.<br></br>
                  .||||||||||~|||||||||||||||~~~~~~~~~||||`       ~||~~||~~~~~~~~~~~~~~~~~~~.<br></br>
.~~~~||||||||~~~~~~~~~|||||^^^^^^^^^++++.      .++>>+^>???????????????????`<br></br>
.>??vvvvvvvv?????????vvvvvv}}}}}}}}}}}r}.      ~rrrrr}}rrrrrrrrrrrrrrrrrrr~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}rr?       ~rrrrrrr}}}}}}}}}}}}}}}}}rr~<br></br>
`}rrrrrr}}}}}}}}}}}}}}}}}}}r}vv}}}}vvv}~       -rrrrrrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}vvvv}?.       .v}}}rrr}}}}}}}}}}}}}}}}}}r~<br></br>
`}}vvv??vv???????vvvv?vvvvvvvvv}}}}}}rv?|  ...~`^????vvvv}}}}}}}}}}}}}}}}r~<br></br>
`}}vvvvvvv??????vv}}}}rrrrrrr}}}}}}}}}rr~.```|v}vv}}}}}}}}rrr}}}}}}rrrrr}r~<br></br>
`}rrrrrrrrr}}}}rrrr}}}}v??vv}}}}}}}}}}rv`````^rrrrrrrrr}rrrrrr}}}}}rrrrrrr~<br></br>
`}rrr}}}}}}rrr}}}}}}}}}vvvvv}}}}}}}}}}rv```..?}}r}}}}}}}}}}rr}}}}}}r}}}rrr~<br></br>
`}r}}rr}}}}}}}}}}}}}rrrrvv??v}}}}}}}}}rv`````-?v}rr}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}rrrr}}}}}}}}}?>.`````-vv}r}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}}??`.....`>?}r}}}rrrrrrrrrrrrrrrr}rrr~<br></br>
`}r}}}}rrrr}}}}}}}r}}}}}}}}}}}}}}}}}}}rr}?^~--~^vrrrrrv????vv?????????????`<br></br>
`}r}}}}vvv????>>>??vvv}r}}}}}}}}}}}}}}}}v}}vvvvv????vv>+++++++++++++++++++.<br></br>
`}rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv????vvv????????????????>>>>>`<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}-<br></br>
.???????????vvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrr}}rrr}r~<br></br>
`}}}}}}}}}}}}}}rrrr}rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrr}}}}}}r~<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}v}v}~<br></br>
                  .vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  .????????????????????????????????????????????????????????????vvvvvvvvvvvvv-<br></br>
                  .?????????????????????????????????????????????????????????????????????????`<br></br>
.?????????????????????????????????????????????????????????>>>>>>?>>>??????`<br></br>
.?>?????????????????????????????????????????????????>>>>>???????????????>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>?>>>>>>>>>+>>>>>>>?>>?>>>>>?>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>++||+^+++^+++>++^+>>>>.<br></br>


            </div>,

            <div>

`v?^~+vvv?>^|~~~~~~|^|-`>?^~.      `~~~~~~~`     .~~~..`~~~~~|?vvvvv^`  ~v-<br></br>
`z?||^?wzzwwrv>^|||||-`~>^|^`      `^||||||.     .||~-~||||||>wzzww?-``~??`<br></br>
`v+||>v}rwzzr}v>|||||~~|~|||-      `^|||||~`..  ..||||||||||||>?rwv^|||||~.<br></br>
.^||||||^+?v>|~|||||||||||||~      .|||||||```.~?~||||||||||||~~|>?^||||||.<br></br>
                  .||||||||~~~||||~||||~~~~~~~~.     .|||||||````~?||~~~~~~~~~|||||~~~||||~|.<br></br>
.|~~~~~~~~~~~~~|||~~|^^^++>>?`      ?vvvvvv`````????>>+>>>>++||^^|^+++++++`<br></br>
.^+>>>?>>?????vv????v}}}}rrrw^      ?}}}}r}`...`?rrrrrrrrrrr}vvv}v}}}}}}rr~<br></br>
`}rrrrrrrrrrrrrrrrr}}}}}}}}}}+      |vvvvvv?>^^^?rrrrrr}}}}}rrrrrrr}}rrrrr~<br></br>
`}rrrr}}}}}}vvvvv??????vv???v+      |wrrrr}????v}rrrrrrr}rrrrrrrrrrrrrrrrr~<br></br>
`}rrrr}}}}}}}}}}}}}}}}}}rrrrr?      `r}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvvv???`<br></br>
`}r}}rr}}}}}}}}}}}}}}rrr}}}}r>       vrvv??????????????????????????????vvv-<br></br>
`}r}}rrr}}}}}}}}}}}}}}}}}}vvv+       ?r}}r}}}}}}}}}}}}}}}}rrrrr}}}}rrrrrrr~<br></br>
`}rrrrrr}}}}}}}}}}}}}}}}}}}}r>       |r}}}}}}rrrrrrrrrrrrrrrr}}}rrrrrrrr}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrrr>       .}r}}}}}}}rrr}}}rr}}}}rrrrrrr}}}}}rrr~<br></br>
`}}}}}v}vvvvvvv}}}}}vvvvvvvv}+        >r}}}}}}}}vv??v?v?vvvvvvvvv}}}}}}rrr~<br></br>
`??>>>>vv?>>+>???????>>>>>>??`.      .-vr}}}}}rvv????????????vv??v}}}}}rrr~<br></br>
`}}vvv}}rr}}vv}}}rrrr}}}}}}rv^>>..   >vvr}}}}}}rrrrrrrrrrrrrrrrrrr}}}}}rrr~<br></br>
`}rrrrrr}rrrrrr}}}}}}rrrrrrr}rvv-`...?rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}}}rrr}}}}}}}}}rr}v`.``.>r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}r}}}}}r}}}}}}}r}}?-.``.>r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}rvvvvvvv}}}}}}}rv?`````.~?v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}rv?>>>?v}r}}}}}v}|.`....~>v}rr}}}}}}}}}}}}}}}}}}}rrrrrrrrrr}r~<br></br>
`}r}}}}}}}}}}}rrr}}rrr}}}}rrv|`...``^vv}}}}}}rr}}rrrrrrrrr}}}rr}}}}}}}}}}}~<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv}r}}}}}}}}}v}}vvv????????>?>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}vvvvvvvvvvvvvvvvvvvv??>>>++++++++>>>>>>++.<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????>>>>>>>>>>>>>.<br></br>
.???????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????????????>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`v?^~+vvv?>^|~~~~~~|^|-`>?^~.      `~~~~~~~`     .~~~..`~~~~~|?vvvvv^`  ~v-<br></br>
`z?||^?wzzwwrv>^|||||-`~>^|^`      `^||||||.     .||~-~||||||>wzzww?-``~??`<br></br>
`v+||>v}rwzzr}v>|||||~~|~|||-      `^|||||~`..  ..||||||||||||>?rwv^|||||~.<br></br>
.^||||||^+?v>|~|||||||||||||~      .|||||||```.~?~||||||||||||~~|>?^||||||.<br></br>
                  .|||||||~~~~||||~||||~~~~~~~~.     .|||||||````~?||~~~~~~~~~|||||~~~||||~|.<br></br>
.|~~~~~~~~~~~~~||~~~|^^^++>>?`      ?vvvvvv`````????>>+>>>>++||^^|^+++++++`<br></br>
.^+>>>?>>?????vv????v}}}}rrrw^      ?}}}}r}`...`?rrrrrrrrrrr}vvv}v}}}}}}rr~<br></br>
`}rrrrrrrrrrrrrrrrr}}}}}}}}}}+      |vvvvvv?>^^^?rrrrrr}}}}}rrrrrrr}}rrrrr~<br></br>
`}rrrr}}}}}}vvvvv??????vv???v+      |wrrrr}????v}rrrrrrr}rrrrrrrrrrrrrrrrr~<br></br>
`}rrrrr}}}}}}}}}}}}}}}}}rrrrr?      `r}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvvv???`<br></br>
`}r}}rr}}}}}}}}}}}}}}}}}}}}}r>       vrvv??????????????????????????????vvv-<br></br>
`}rrrrrr}}}}}}}}}}}}}}}}}}vvv+       ?r}}}}}}}}}}}}}}}}}}}rrrrr}}}}rrrrrrr~<br></br>
`}rrrrrr}}}}}}}}}}}}}}}}}}}}r>       |r}}}}}}rrrrrrrrr}rrrrrr}}}rrr}}}}}}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrrr>       .}r}}}}}}}rrr}}}rr}}}}rrrrrr}}}}}}}rr~<br></br>
`}}}}}v}vvvvvvv}}}}}vvvvvvvv}+        >r}}}}}}}}vv??v?v?vvvvvvvvv}}}}}}rrr~<br></br>
`??>>>>vv?>>+>???????>>>>>>??`.      .-vr}}}}}rvv????????????vv??v}}}}}rrr~<br></br>
`}}vvv}}rr}}vv}}}}rrr}}}}}}rv^>>..   >vvr}}}}}}rrrrrrrrrrrrrrrrrrr}}}}}rrr~<br></br>
`}rrrrrr}rrrrrr}}}}}}rrrrrrr}rvv-`...?rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}}}}rr}v`.``.>r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}r}}}}}}}}}}}r}}}}}r}}}}}}}r}}?-.``.>r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}rvvvvvvv}}}}}}}rv?`````.~?v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}rv?>>>?v}r}}}}}v}|.`....~>v}r}}}}}}}}}}}}}}}}}}}rrr}rrrrrrrrr~<br></br>
`}r}}}}}}}}}}}rrr}}rrr}}}}}rv|`...``^vv}}}}}}rr}}rrrrrrrrr}}}rr}}}}}}}}}}}~<br></br>
`v}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv}r}}}}}}}}vv}}vvv????????>?>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}vvvvvvvvvvvvvvvvvvvv??>>>++++++++>>>>>>+>.<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????>>>>>>>>>>>>>.<br></br>
.???????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.??????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????????????>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvv>.|^^|?v?     -~~|+?v}>    >vvv~.>vv?|~-^vvvvvvvvvv~``<br></br>
`wzzzzwzzzzzwrvvv}}}rv+rww}wz}.    ~^|>???v^   .wzww}????+||||>?vwzzzzzw-?|<br></br>
`wwww}?wwww?+||~~||||^?}wzzw?^.    -^||~~~~.   `}www}>`~~|||+>>vwwwwwrv+~>`<br></br>
`wwwr+^vrwww}?||||||~`|?vv??>|.    -||||||`    `>rzw}?^|||||>wzzwwwv?^~.~^`<br></br>
`wwrr>~||+}wzzv^~|||~.~||~~~~|`    `|||||~.    `^?}}}}}+~||||>rwwwr?+^`-||.<br></br>
.?+^+>||||+>>>>^||||--||||||||`    `^||||~...`.-|||||||||||||||++++>>|-|||.<br></br>
.~|||~||||~~~~~|||||||||||||||-    `^||||~.``>+||||||||||||||||~~~~~~|||||.<br></br>
                  .||||||||||~~~~~||||||||~~~~~|~    .^||~|`...^?~||||~~~~~~~|||||~~~~~~~~~~.<br></br>
.||~~~~||||^^++>+^|||^+>>??????    .^^^++|~~-|>+^^|^???>>>+++^^^>?????????`<br></br>
.+>+>??vvvv}}}}}??>?vv}}}}rrrr}.   `vvv}}}}rrrrr}}vvrrrrrrrr}}}}}rrrrrrrrr~<br></br>
`}rrrrrrrr}}vvvvvvv????vvvvvvv}`   .v}}}}}}}vv?vv}}r}}}}}rr}}rrrr}}}}}rrrr~<br></br>
`}rrrrrr}}}r}rrrrrr}vv}rrrrr}??`   .vrr}}}}vv??vv}}rrrr}}}r}}}r}}}}}}}}}}r~<br></br>
`}rrrrrr}}}}}}}}}}}rrr}}}}}}}}}-    ?r}}}vv????????????vvvvvvvv???????????`<br></br>
`}rrrr}}}}}}}}}}}}}}}}}}}}}}}rr~    ^r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}}}}}}}}}}}}}rrrrrrrrrrr|    `r}}}}}}}}}}rrrrrrrr}}rrrrrrrr}}}}}}rr~<br></br>
`}rrrrrrr}}}}}}}}}}}}vv??????v}^     ?r}}}}}}}}}rr}}}}}}}rr}}}}}}}}}}}}}rr~<br></br>
`}}rr}}}}}}}}rr}}}}}}}vvvvvvvvr^     `r}}}}}}}}rvvv?????????vv}}}}}}}}}}rr~<br></br>
`??????vv????vv}}}}}vvvvvvvv}r}~.    ~vr}}}}}}}rvv????????????v}rr}}}}}rrr~<br></br>
`}}}vvvvr}}}}}}}}}}}}}}}}}}}}r?v>^...vr}}}}}}}}}rrrrrrrrrrrrrr}r}}}}}}}rrr~<br></br>
`}rrrrrr}}}rrr}}}}}}}}}}}}}}}}r}?-```v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}}}}rrrrr}}}}}}}}}vv-``..^v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rr~<br></br>
`}rr}}}}}}}}}}}r}}}}}}}}}}}}r}?>....-?}r}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}r~<br></br>
`}r}}}}}}}}}}}rv?+>?v}}r}}}}}r}v???v}rr}}}}}r}rr}}}}}}}}}}rrrrrrrrrrrrrrrr~<br></br>
`v}}}}}}}}}}}}}}v???v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}-<br></br>
`vvvvvvvvvvvvvvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vv???>>>>>>>>>>>>>>>>>>>.<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>+>>>>>>>>+>.<br></br>
.?????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??????????>>>>>>>>>>>???????>?>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvv>.|^^|?v?     -~~|+?v}>    >vvv~.>vv?|~-^vvvvvvvvvv~``<br></br>
`zzzzzwzzzzzwrvvv}}}rv+rww}wz}.    ~^|>???v^   .wzww}????+||||>?vwzzzzzw-?|<br></br>
`wwww}?wwww?+||~~||||^?}wzzw?^.    -^||~~~~.   `}www}>`~~|||+>>vwwwwwrv+~>`<br></br>
`wwwr+^vrwww}?||||||~`|?vv??>|.    -||||||`    `>rzw}?^|||||>wzzwwwv?^~.~^`<br></br>
`wwrr>~||+}wzzv^~|||~.~||~~~~|`    `|||||~.    `^?}}}}}+|||||?rwwwr?+^`-||.<br></br>
.?+^+>||||+>>>>^||||--||||||||`    `^||||~...`.-|||||||||||||~|^+++>>|-|||.<br></br>
.~|||~||||~~~~~|||||||||||||||-    `^||||~.``>+||||||||||||||||~~~~~~|||||.<br></br>
                  .||||||||||~~~~~|||||||~~~~~~|~    .^||~|`...^?~||||~~~~~~~|||||~~~~~~~~~~.<br></br>
.||~~~~||||^^++>+^|||^>>>??????    .^^^++|~~-|>+^^|^???>>>+++^^^>?????????`<br></br>
.+>+>??vvvv}}}}}??>?vv}}}}rrrr}.   `vvv}}}}rrrrr}}vvrrrrrrr}}}}}}rrrrrrrrr~<br></br>
`}rrrrrrrr}}vvvvvvv????vvvvvvv}`   .v}}}}}}}vv?vv}}r}}}}}rr}}rrrr}}}}}rrrr~<br></br>
`}rr}rrr}}}r}rrrrrr}vv}rrrrr}??`   .vrr}}}}vv??vv}}rrrr}}}r}}}r}}}}}}}}}}r~<br></br>
`}rrrrrr}}}}}}}}}}}rrr}}}}}}}}}-    ?r}}}vv????????????vvvvvvvv???????????`<br></br>
`}rrrr}}}}}}}}}}}}}}}}}}}}}}}rr~    ^r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}}}}}}}}}}}}}rrrrrrrrrrr|    `rr}}}}}}}}}rrrrrrrr}}rrrrrrrr}}}}}}rr~<br></br>
`}rrrrrrr}}}}}}}}}}}}vv??????v}^     ?r}}}}}}}}}rr}}}}}}}rr}}}}}}}}}}}}}rr~<br></br>
`}}rr}}}}}}}}rr}}}}}}}vvvvvvvvr^     `r}}}}}}}}rvvv?????????vv}}}}}}}}}}rr~<br></br>
`??????vv????vv}}}}}vvvvvvvv}r}~.    ~vr}}}}}}}rvv????????????v}rr}}}}}rrr~<br></br>
`}}}vvvvr}}}}}}}}}}}}}}}}}}}}r?v>^...vr}}}}}}}}}rrrrrrrrrrrrrr}}}}}}}}}rrr~<br></br>
`}rrrrrr}}}rrr}}}}}}}}}}}}}}}}r}?-```v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr~<br></br>
`}rr}}}}}}}}}}}}rrrrr}}}}}}}}}vv-``..^v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rr~<br></br>
`}rr}}}}}}}}}}}r}}}}}}}}}}}}r}?>....-?}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`}r}}}}}}}}}}}rv?+>?v}}r}}}}}r}v???v}rr}}}}}r}rr}}}}}}}}}}rrrrrrrrrrrrrrrr~<br></br>
`v}}}}}}}}}}}}}}v???v}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}-<br></br>
`vvvvvvvvvvvvvvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvv?v}vv???>>>>>>>>>>>>>>>>>>>.<br></br>
.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>+>>>>>>>>+>.<br></br>
.?????????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.???????????????????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??????????>>>>>>>>>>>>>>>>>>>?>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvv~.`?vvvvv`    .vv|    +v???+|+>?vvvvvvvvvvvvvvvvvvvv-<br></br>
`zzzzzzzzzzzzwwwwwwwzwv^|?vv??}`    ~zz>    ?wr}}?+~->wwwwwwwwwwwwwwr}}www~<br></br>
`wwwww}vvv}wwwwwwwwrr}>|^|~>}rr`    >}v^    ?r}r?~-|~^}wwwwwwwwwrww}>`?wrw~<br></br>
`wwwww}v?vrwwwwwwwwr?^.-?>|`|}r-    v}v^    ?wr}?|^}?^vr}rwwwwv^|vw?~`vrrw~<br></br>
`wzzwwr}r}v?>>??vrrwr??v?+-..~+`    ?vw?    >????~^}r>+^+vrwwwv^~?rv`^rrww~<br></br>
.}}v}wrv?>>^|||^+>?v??>+^|~~-~~~-  .|>?>    -|?v+`~+++||||?rwwrv?>^`.>v}ww~<br></br>
.||+>????^~~||||~|^|~~~|~|||||~||..`|||~    -|+??>?>~||||+?v}r?+|~`.~?v}rr~<br></br>
.||||~~~||||||||||||||||||||||~|-..`|||~    -|^^^^^^|||||++>>?v?^--|^+^^^+`<br></br>
                  .||||||||||||||||||||||||||||||~```~|||~    ~|||||||||||||||||||||||||||||.<br></br>
                  .||||||||||^^^^^|||||||||||^^^|----~||||    |^^^|||^^|||||||||||||^|||||||.<br></br>
.|||||||^^|^^^^||||^^^^^^^^^^++|~|~~^++^.  .>???>+++>????>>>>??????vvv???v-<br></br>
.+>++>?vvvvv???vvvv}}}v??v}}}}}v?vv?}}}v.  .?v}rrr}}}rrrrrrrrrrrrrrrrrrrrr~<br></br>
`rwwzxuuuuuuzr}rrrrrrrr}}}}}}}}???vv}rr}.  .v}vv?v}}}}}}}}}vvvvvvvvvvvvvvv-<br></br>
-uyuuxzzzzxuyuw}}}}}}vvvvv?vvvvv???vv}r}.   ?vvv?vvvvvvvvvvvvvvvvvv?vvvvvv-<br></br>
`}vvv??????vwyuwv???????>vr}rrr}vvvvvrrv.   ?r}}rrrrrrrrrrrrrrrrrrrrrrrrrw~<br></br>
`????????????}uyxwxr?>>+vr}}}vv?????vv}?    ?rr}}rr}}}}}}}}rrr}vv?????????`<br></br>
`?????????????zuxxyu?>+vrrrr}}vv????vv}?    ^}}rr}}}}}}}}}}rv+^||^^^^^^^^|.<br></br>
`?????????????zuuuxzuxw}v}}v}}}vvvvvvvr^    .}}vv????vvvvvvv|^^|^^^^^^^^^^.<br></br>
.???????????>>+vxxuuuuuxvvvvv}}}}}}vvvr~ ...|?rvvvvvvvvvvvv+^^^^^^^^^^^^^^.<br></br>
.????????>>>>>+zyuuuuuuuw}}rrrrr}}}v}}r?.```?}rrrrrrrrrrrrv|^^^^^^^^^^^^^^.<br></br>
.>????>>>>>>>>+wyuuuuuuuuvv}}}}}}vvvv}r?.```>}r}}r}}}}}}}rv^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>uuuuuuyur?v}r}}}}vv}}vv>````-?vv}}}}}}rrrrrv+^^^^^^^^^^^^^.<br></br>
.>+>>>>>>>>>>>>?xuwzyuzr}rrr}}}}}}}}}}}}}vvvv}}rr}}}}}}}}vvvv+^^^^^^^^^^^^.<br></br>
.>++>>>>>>>>>?v}rrvvwr}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv?????????+|^^^^^^^^^^.<br></br>
.+++>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?^|^^^^^^^^.<br></br>
.+++>>>++>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>||^^^^^^.<br></br>
.++++++>???????????????????????????????????????????????????????????>^||^^|.<br></br>
.>+^+>???????????????????????????????????????????????????????????????>^||~.<br></br>
.>>^^>??????????????????????????????????????????????????????????>>>>>>>>>+.<br></br>
.>>>++++>>?????????????????????????????????????????????????????>>>++^^++>>.<br></br>
.+>>++>+++>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??>>>>>>>>>>>>>>>+++>>>>>>.<br></br>
.+>>++>>>>+^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvv~.`?vvvvv-    .vv~    >vvv?^|+>?vvvvvvvvvvvvvvvvvvvv-<br></br>
`zzzzzzzzzzzzzzzzzzzzwv^~>v???r-    ~zz+    ?zzw}?+~->rwzzzzzzzzzwwwr}}www~<br></br>
`wwwww}vvv}wwwwwwwwwrr>|^|->}rw`    >}v^    ?zww?~-|~^}wwwwwwwzwrww}>`?wrw~<br></br>
`wwwww}v?vrwwwwzwwww?^.-?>|`|rz-    v}v^    ?zzw?|^}?^vr}rwwwwv^~vw?~`vwww~<br></br>
`wzzwwrrwrv?>>??vrwww??v?+-..~+-    ?vw?    >v?v?~^}}>^^+vrwwwv^-?rv`^wwww~<br></br>
.}}v}wrv?>>^|||^+>?v??>+||~~-~~|-  .|>?>    -|?}+`~++^~~||?www}??>|`.>v}ww~<br></br>
.||>?????^~~||||~|^|~~~||||||||^^..`|||~    -|+??>?>~~|||+?v}r?+|~`.~?}rww~<br></br>
.|||~~~~~||||||||||||||||||||||^~..-|||~    -|^^^^^^|||||^+>>?v?|--|^+^^^+`<br></br>
                  .|||||||||||||||||||||||||||||||-``~|||~    ~|||||||||||||~~~~|||||||~~~~|.<br></br>
                  .||||||||||||||||||||||||||||||||||||||~    ~|~|~~||||~~~~~||~~~~~||||~~||.<br></br>
.||||||||||^^||||||^^^^^^^^^^^^^^^^|^^+^    >>>>>+^++????>>>>??????vv????v-<br></br>
.^>++>?vvvvv???vvvv}}}v??v}}}}}vv}vvv}}v.   ?v}rrr}}}rrrrrrrrrrrrrrrrrrrrr~<br></br>
`rwwzxuuuyuuzrrrrrrrrrr}}}}}}}}??v}}}rr}.   v}vv?v}}}}}}}}}vvvvvvvvvvvvvvv-<br></br>
-uuuuxzzzzxuyuw}}}}}}vvvvv?vvvvvvvvv}}r}.   ?vvv?vvvvvvvvvvvvvvvvvv??vvvvv-<br></br>
`}vvv??????vwyuw???????>>vr}rrrrrrrrrrrv    ?r}}rrrrrrrrrrrrrrrrrrrrrrrrrw~<br></br>
`????????????}uyxwxr?>>+vr}}}vv??vvvvv}?    ?rr}}rr}}}}}}}}rrr}vv?????????`<br></br>
`?????????????zuxxyu?>+vrrrr}}vvvvvv}}}?    |r}rr}}rrrr}}}}rv+^^^^^^^^^^^|.<br></br>
`?????????????zuuuxzuxw}v}}v}}}}}rr}}}r^    .}}vv????vvvvvvv|^^|^^^^^^^^^^.<br></br>
.???????????>>+vxuuuuuyxvvvvvv}}}}}}}vr~ ...|?rvvvvv?vvvvvv+^^^^^|^^^^^^^^.<br></br>
.????????>>++>+zyyuuuuuyw}}rrrrr}}}}r}r?.```?}rrrrrrrrrrrrv|^^^^^^^|^^^^^^.<br></br>
.>???>>++++++>+wyuuuuuuyuvv}}}}}}}}}}}r?.```>}r}}r}}}}}}}rv^^^^^^^^^^^^^^^.<br></br>
.+>>+++++++++>+>uuyyuuyur?v}r}}}}}}}r}v>````-?vv}}}}}}rrrrrv+^^^^^^^^^^^^^.<br></br>
.++++++++++>++>?uuwzyuzr}rrr}}}}}}}rr}}}}vvvv}}rrr}}}}}}}vvvv^|^^^^^^^^^^^.<br></br>
.+++++++++>>+?v}wrvvwr}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvv?????????+|^^^^^^^^^^.<br></br>
                  .++^+++++++?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?^|^^^^^^^^.<br></br>
.++^++>++>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>||^^^^^^.<br></br>
.++++++>??????????????????????????????????????????????????????vv?v?>^|^^^|.<br></br>
.++^+>???????????????????????????????????????????????????????????????>^|||.<br></br>
.++^^>>???????????????????????????????????????????????????????????>>>>>>>+.<br></br>
.++++++++>?????????????????????????????????????????????????????>>>+^^^^++>.<br></br>
.+>>+++++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>+>>.<br></br>
.+>>++++>++^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++.<br></br>


            </div>,

            <div>

.+?vvvvvvvv?vvvvvvvvvv?-->vvvvv?`    +v>   .vvv??^-|?vvvv?~|^+++++++++^^^|.<br></br>
.>wzzzzwwrvvzzzzzzzzww}+~~>}wwwv.   .rzv   .rzw}v+|?}rwzzw+^^+>>>>>>++++++.<br></br>
.vzwwww}v?>?vvrwwww}v?>+>^~>wzzv`   .rw}.  .rzwrv>|+v}wwww?^+^^^^^^^||^^^^.<br></br>
`rwww}v??+^+>vrzzzzzwrvv>|v?>rz}^   -ww}`   ?wv|+v}??rwwwrrv++^||^^^||^^+>`<br></br>
`ww}v???+|^>}xxzzzwwwzzr}?v?--}}?.  .}zw-   ?v?^vr}>>rrrrrwz?~^~|^^^^+?v}w~<br></br>
`vv???>+^?zz}?^?}v?>++rzxzww}+vr}+   +w}`  .}}>+}r?^}}???v}wv>r}v?|?vr}}}r~<br></br>
.???>+>vxz?|~~~|^^^^^^}zzxxxzvv?v?`..-^|.  `}v|^rr}}v+|+>?vrwz}?>`^?v?>?v}~<br></br>
.???>?zzv+^+>????vvvv?vuxxxxz>+??+|`.`||.  -}r?-vv>^|||^>??v}?>+-`~|||^+^^.<br></br>
.??vzx}r}r}}vvvv??????vuuxuxv^?vv}+``^^|.  `?v?~|++|||||^^+>^||.-||||||~~-.<br></br>
.???}>^?v?????????????>?xv??-~^||>?+|+^|.  .||~||^+>>+||||~~~|++?+||||||~~.<br></br>
.?????++??????????>>++++?>|~~|||||||||||.  .|^^^||^++^^^^^^^^^^^+^^^^^^||^.<br></br>
.?????>>>???????>>>>>>>>>+^++++^^++++++^.  .^++++++^+++++++++++++++++++||^.<br></br>
`?vvvv???????>>>>>>>>>>>++++++^^^^++++++   `++++++++++++++++++++++++++^|^^.<br></br>
`vvvvv???>>???>>>>>>>>>>>>>????>+++++?}?   ^vvvvvvvvvvvvvvvvvvvvvvvvvv>^^^.<br></br>
`??????>>>>>????>>>>>??vvvv}vv}}}}vvv}r>   +}}}vvvvvvvvvvvvvvv}}}}}}rr+^^^.<br></br>
`??????>>>>>>>???>>?vvvv}}}}}}}}vvvvvv}~   ^}r}}}}}}}}}}rrrrrr}}}}}}}}^^^^.<br></br>
`??????>>>>>>>??>?vr}vv}rrrrr}}}}}}}}rv`   ^vr}rrrrrr}rrrrrr}vvvvvvvvv^^^^.<br></br>
`??????>>>>>>>>>?rwwwrrr}}}}}}}}}}rr}}}~..^>v}vvvvvv}}}}}}}}vvvvvvvvvv^^^^.<br></br>
`??????>>>>+++>vwwwrrrrrvvvvvv}}}}}}}}}`.`??vvv??vvvv}}}}}}vvvvvvvvvvv+^^^.<br></br>
.??????>>>+^+?vrwwwwwwwwr}}vv}}rr}}}}rv``-?v}}rrrrrrrrr}}r}vvvvvvvvvvv?|^^.<br></br>
.?????>>+++>?vv}wwwwwwwww}v??v}r}}}}}r?`.`>vv}}}}}}}}}}r}}}vvvvvvvvvvv?|^^.<br></br>
.????>+++++?????vv}}rwwwr}}rrr}}}}}}}}v??>???vvvvvv???????v??>>>>???vvv+^^.<br></br>
.????>+++++++>>?????vvv}r}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}}}v???v?+++^^^^^.<br></br>
.???>++++++++>>>>>>??vv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vv}}vv?^^|^^^^|^.<br></br>
.??>++++++++>>>>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}v|^^^^^^^|^.<br></br>
.?>+++++^+>>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv^^^^^^^^^||.<br></br>
.+++++>>????????????????????????????????????????????????v?????>|^^^^^^^^||.<br></br>
.++++|~??????????????????????????????????????????????????>>++>+~^^^^^^^^^|.<br></br>
.++++^``>?????>>>>>>>>>???????????????????????????????????>>???+~^||||||^|.<br></br>
.++>vv|.~>>>>???>>>????????????????????????????????????????>>>>>|~||||||||.<br></br>
.>?}vv~..^++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^~|||||||.<br></br>
`?}v^~...~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^~~~~~~~.<br></br>


            </div>,

            <div>

.^?vvvvvvvv?vvvvvvvvvv?~->vvvvvv-    +}?   `vvv??^-|?vvvv?~~|^+^+++++^^^^|.<br></br>
.+wzzzzww}vvzzzzzzzzww}+~~>}wzzz`   .wz}.  .wzw}?+|?wzzzzw^^^++>>+++++++^^.<br></br>
.?zwwww}v?+??vrwwww}??>+>^|?wzww`   .rzw`  .rzwr??|+rwwwwr?|^^^^^^^^^^^^^^.<br></br>
`rwww}v??^~~^?}zzxzzwwr}>|vv>rzz>   -zwz-   ?wv~+rr>?wwwrrr?^^|^^^^^^^^^^>`<br></br>
`wwv???>^~|>}xxxzzzwrzzr}?}v|-rz}.  .}zz~   ?v>|vzw++wzwwwwz?-||^^^^^>?v}w~<br></br>
`v???>>^^vzxr?^?}v?>|+rzuxzxr>}zw>   +ww~  `wr+^rzv+}r???vrzv>r}}?^vwwrvvr~<br></br>
.???>+>vxx?|~~~|^^^^^^rxuuuyxvv?v?-..-+^.  -w}|^wwrrv+|+>?vrzz}?>`+vv?+?v}~<br></br>
.???>?xxv+^>???vvvvvv?vyuuuyx>+?v>^`.`||`  |zz?-vv>^|||^??v}}?>+-`|||~^++^.<br></br>
`??vzurwrrrr}vvv??????vyyuyu}+?}rw>`.^^|.  -v}?~|++|||||^^+>^||.`||||||~~-.<br></br>
`??vr?+???????????????>?u}??~~^||>?+|+^|.  .||~||^>>>+||||~~||+>?+||||||~|.<br></br>
`?????++???????????>>+++?>|~~||||~~|||||.  `|||||||++|||||||||||^||||||~~|.<br></br>
`?????>+>??????>>++++++++|~|||||||||||||.  `|||||||~|||||||||||||||||||~^^.<br></br>
`??????++??>>++++++++++^|~~~~~~~||||||||   -^^^^^^^^|||||||||||||||||^||^^.<br></br>
`??????++++++++++++++++++>>>>>?>^||||?v?   >}v}vv}}vvvvvvvvvvvvvvvvvv}>^^^.<br></br>
`??????++++++++++++++>vvvvvvvv}}vvvvv}w>   ?r}}vvvvvvvvvvvvvvv}}}}}}rr+^^^.<br></br>
`??????>+++++++++++>v}}}}}}}}}}}}vvvvvr-   ?rr}}}}}}}}}}rr}rrrrrrrr}r}^^^^.<br></br>
.??????+++++++++++?}rrrrrrrrrrrrrrrrrrv`   ?rrrrrrrrr}}}rrr}r}}}}}}}r}^^^|.<br></br>
`??????+++++++++?vrr}}}}}}}}}}}}}}}}}rv~.`>vr}vvvvv}}}}}}}}}}}}}}}}}}}^^^^.<br></br>
`??????+++++++>vrrr}}vvvvvvvv}}v}}}}}}}`.-vrr}v??vvv}}}}}}}}}}}}}}}}}r>|^|.<br></br>
`?????>++++^>vrrrr}}rrrrrr}vv}}rr}}}}r?``-v}}}rrrrrrrr}}}}}}}}}}}}}}}r?|^|.<br></br>
`?????>++++>?vv}}}rrrr}}}}v??v}r}}}}}r?..`>v}}r}r}}}}}}r}}}}rrrrrrrrrrv|^|.<br></br>
`?????+++++?????vvvv}}}r}}rrrr}}}}}}}}v???vvvvvvv????????????????vvv}}}+||.<br></br>
`????>+++++++>>???????v}r}}}}}}}}}}}}rrrrrrr}}}}}}}}}}}}}}}}vvvv}?+++^^^||.<br></br>
`???>++++++++++++++>?vv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}r?^^||^^^||.<br></br>
`??>+++++++++++>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}v^^^^^|^^||.<br></br>
.?>+++++^+>>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv^^^^^^^^^||.<br></br>
.++++++>????????????????????????????????????????????????v?????>|^^^^^^^^||.<br></br>
.++++~-??????????????????????????????????????????????????>>++>^~^^^^^^^^^|.<br></br>
.+++++``>?????>>>>>>>>>????????????????????????????????????>???+|^^^^^^^^^.<br></br>
.+^>vr^`~>++>>??>>>>???????????????????????????????????????>>>>>||^^^^^^^^.<br></br>
.>?w}}|`.|++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^|^^^^^^^.<br></br>
`vrv>|```~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^~|^^^^|.<br></br>


            </div>,

            <div>

.?????+`--`````````````...````-~.     ..   .````....~vr>++^^^^^^|||||||||~.<br></br>
`?vvvv?-~~~-~~~~~~~---``..`-~~||..   ...  ..--```..+zu}???>++^^^||~--|^^^^.<br></br>
.?vvvv?-``````-~~~~-````````----.    ...  ..-~~--`>zx?>>>>^||~-`````~^^^^^.<br></br>
.?vvv??`.....~>v}+~~~~--`.--````.    ...  ..|~~-~?uw?>>>++|~```````~^^^^+>.<br></br>
`vv????`....+v}zwv^--~~-|v}rv>+?>`   ...   .^^?>?xz>+>++^~--`...`>|^^+>>>>`<br></br>
`??????`.`~+?vvvv?`..--?xzwzw}wzz?`-....  `~>++|vz?+++^|``--|^|`-||+>>>>>>`<br></br>
.??????++v}vv????-...||v}vv}wwwrrv>|||.  `>?rv?>>?>+^|?>```~?v>|~|^>>>>>>>`<br></br>
.?????vvvv??????+--|>>+}rwxzw??}rvv??v|  |>+vvvvv>^|^v}v??>~????~|+>>>>>>>.<br></br>
`???vvvv??v?????|-~>>vwxzzw}??vwww}vv}|  -|>rwrvvvv???>>?>^|??>~~+>>>>>>>+.<br></br>
`???v??????????????v?vvvwwvv???}v}r}}}`  ~^?}v>???}vv?>>>^+>^~~|^>>>>>>>>+.<br></br>
`?????????????????????>?vvvvv>>vv??}}}` .>>>>??~vvv??>?v}}}}v^||+>>>>>>>>+.<br></br>
`??????vv}}}v???????+^^>?}wwrv>?v??v}v` .>++?v>^v?vv+~~?>?}}v|^|+>>>>>>+>>.<br></br>
`?vvrwzwzzzz?????????v}}}}r}rrvvvvvvv+. .>+>>?^>}}}}v>>??v}v>|||+>>++>>+>>.<br></br>
.vzxxxzzzzzv???????vrvv??????vzwrv?v>|. .~^vv}}?vvrr^v>?}rr}^^^^^>>>>>>>>>.<br></br>
`zzwxxzzzzr??????????>?v??????zxuu}+>^.  `~vwv>|>?vr??vv?>??|^|^^>>>>>>>>+.<br></br>
`zzrxxzzw}???????????>??????>>wxxx>++|.  `.^?>+|>?v}v+?>>>>+|^^^+>>>>>>>>+.<br></br>
`zzwwr}vv??????????v??>????>>>>v?v>>?~  .``^?>>>??>>>>>>>>>+|^^^>>>>>>>>>+.<br></br>
`vv???????????????vv??>??>?v}v?vv}}}}+. `?vv}vvv}}}vv}vv}}}v^^^^^+>>>>>>>>`<br></br>
`???????>???????vvvv??>>>?vvvvv}}}}rrv~`+v}rv???vv}}rrrrrrrr?|^^^^^++>>>>>`<br></br>
.?????????????vvvvvv??>?v}}}}vvvvr}}}v-.^?vvvvvvvvvvvvvvvv}}}?>+|^^^^+>>>>.<br></br>
`}wwwv>+??????vvvvvv?>?}r}}}v}}}}}}}}v>^>?v}}}}}}}}}}}}}}}}}}}}}?^^^^^>>>>.<br></br>
`rv?>-..~?????????????vv}}rrr}r}}}}}}}}}}}}}}}}}}}}}}}v}}}}}vvv}}^^^^^>>>>.<br></br>
.--|^++^~>>????????>>>???vv}}}rr}}}}}}rrrrrr}}}}}}rrrr}}}}}}}}}}v^^^^^>>>>.<br></br>
.^>?????v}?>?????>>>+>>>>????v}}}}}}}}}}}}}}}}}}}}}}vvv???vvvv}}>^^^^+>>>>.<br></br>
.?vvvvrwzr??????>>>????????vvvvvvvvvvvvvvvvvvvvvvv}vv?????vvvv}v|^^^^+>>>>.<br></br>
`}}rrr}}v??????>>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+^^^^^+>>>>.<br></br>
.????v}vv????>>>>>?????????????????????????????????????????????|^^^|^>>>>>.<br></br>
.?????wzwr?>+++++>>>>?>+>>???????????????????>>>>>>>>>>>>>>+++~|^^^^^>>>>>.<br></br>
.??>>^^ww}??????>>>>??>>???????????????>>>???????????????>>>>>-|^^|^+>>>>>.<br></br>
.++>>>~^ww>??????????????????????????????????????????????????^~|||^^+>>>>>.<br></br>
.+>>>>|`>zrv????>>>>>>>>>>>>>>>>>>>>>>>>>>>??>>>>>>>>>>>>>>>>~||~~^^^+>>>>.<br></br>
`??>^~``?wzxv|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^-~~--~||^^^^^.<br></br>


            </div>,

            <div>

.??>>?^............................       ..........-vw>^++^^^^^|~~~~~~~|~.<br></br>
`?????>........................``.        .........^zuv>>>>>+^^|~--``~|^^^.<br></br>
.?????>...........................        ........>ux?>>>+^^|~`.....-^^^^+.<br></br>
`??????......-+?v~.........``.....        .`-.`--?uz?>>+^^~`.......~^^^+>>.<br></br>
`??????`... ~??rwv-.....~?rrr?^>+`        ..^~???uz>>+^^|`......`?|^|^>>>>`<br></br>
`??????` ..->?vv??...`.+zwrwzzzzz?|+`.   .|++~+|vz?>+^|~....~^-..~^^+>>>>>`<br></br>
`??????||?}}v????-...|`>????}zzwrvv?>+.  ^zw}???>>>+|^?>....>?^~~|^>>>>>>>`<br></br>
`???????vv??????|..`^>^?vrzrvvvrw}rr}z|  vz?>?vrv>^|^}zv??+`+?v?|^>>>>>>>>`<br></br>
`??????????????+`.`|+vzzwwvv??}wzzzwzz|  >vvrwww}}}vvv?>?+^~v?>|^+>>>>>>>>`<br></br>
`???????????????>????vvvrw}}v??w}rwwww`  ?vwwr>??vwr}?>>>^>?^~~^^>>>>>>>>>`<br></br>
`?????????????????????>vrrwwr??r}?vwww` .wz}>v?~ww}v?>?}wwwwv^|^>>>>>>>>>>`<br></br>
`?????vv}rrrv???????>~^?vwxxz}??rvvrzw. `ww?vr?+rv}}+~~v?vwwr^^^>>>>>>>>>>`<br></br>
`?v}zxuuuyyuv???????v}wzzwwwww}rvvvw}>. -zrv??+>wwww}??v?rw}>|^|+>>>>>>>>>`<br></br>
.}xyyyyyyyyr???????vzrv??????}xzw}?v>~  `?}wvrr?v?rw|v+>}rr}^^||^>>>>>>>>>`<br></br>
`uxzuuyyyyz??????????>??????>>xuyy}~|~  .^vrrv^-|>vr??v?>^>?|^|^^>>>>>>>>>`<br></br>
-uuzyyyyuw??????v????+>>>++++^ruuu+~|-  .^^^^|~`^>?}v~+^||~~|^|^+>>>>>>>>>`<br></br>
-uuxzwr}v??????vv????+^+++++^^^???^^^`  .^^^^^|^^^|||||||||||^||>>>>>>>>>>`<br></br>
`vv??????????????????+^+++>v}v??vv}}}|  -}}}}vvv}}vvvvvvvvvv|^^^^+>>>>>>>?`<br></br>
.????????????????????+^++?vvvvvv}}}rr?.`?}r}v???v}rrrrrrrrrr>|^^^^^++>>>>>`<br></br>
.???????????????????>+^>vr}}rv??vrr}}?..>}vvvvvvvvvvvvvvvvv}}?>^|^^^^^>>>>.<br></br>
`rxuz}?+????????????>+?rr}}}}}}}}}}}}v>>?}}}}}}}}}}}}}vv}}}}}}}}?|^^^^>>>>`<br></br>
`w}?+.. -??????????>+>?vv}rr}}rr}}}}}rrrr}}}rrrrrr}}}rrrrrrr}rrr}^^^^^>>>>`<br></br>
 ..`~^+|`>>????????+++>???vv}rrr}}}}}}}}}}}}}}rr}}}rrrr}}}}}}rrrv|^^^+>>>>`<br></br>
 -+?????}xv>??????++^+++++>>?v}}}}}}}}}}}}}}}}}}}}}}v?????vvvvv}>|^^^+>>>>`<br></br>
.?vv}wzuyzv?????>+++>>??????vvvvvvvvvvvvvvvvvvvvvvvvv???????vv}v|^^^^>>>>>`<br></br>
`wzzzzzwv??????>+>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>|^^^^>>>>>`<br></br>
.????}r}v???>>+^+>?????????????????????????????????????????????^^^^^^>>>>>`<br></br>
.????vuyzz?+++^++++>>>>+>>?????????????????>>>>>>++++>>>>>>>>>||^^^^+>>>>>`<br></br>
.??>>^?uuwv???????????>>>?????????????>>>>>>>???>>>>>>>>>>>>>+~^^^|^+>>>>>`<br></br>
.+++++|?uu???????????????????????????????????????????????????^|^^|^^+>>>>>`<br></br>
.++++++^?yzvv???>>>>>>>>>>>>>>>>>>>>>>>>>>????????????????>>>|^^^|^^+>>>>>`<br></br>
.>>>++++rxxuv~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>|~^|||||^^^^++.<br></br>


            </div>,

            <div>

 ``---`.``..........`-`..........`..............`??-|+>+^^^^^^^||~~~~~~~|~.<br></br>
.-~|||-|>~...........`.........``-..............?}>+???>>>+++^^^||~~~|^^^^.<br></br>
.-~|~^??>~........................ .......`....~?>>??>+++^|~~-````-~|^^^++.<br></br>
.-~~+vv>>|....`~~`................  .... ...`.`^^+>>++++^|``.....`-|^^+>>>.<br></br>
.-|?vv?>>|...`-|^|`.....`-~~~````.   .   ...`.-^+>>+++^^~`......`^|^^+>>>>`<br></br>
.|?v???>>~`.~>>>>~.....~|~~+>+|||`..     .`--|^-+>++|~-`....``|--|^+>>>>>>`<br></br>
.vv?????>>>?vv???-...`.-~>?vwwv^|~.....  `???^^??>?>^+>-....`-|~|^^>>>>>>>`<br></br>
`v?????????v??>+|-~+^-`->zzwrrvv}}`..+-  ^r}?^+}v????rr??+-.~>^^^^>>>>>>>>`<br></br>
`??????????>>>>>????~^++>v???}rwzw-..v|  ^}rrrr}rrr}r}vv?>+>?>^^^+>>>>>>>>`<br></br>
`????????????vv??????????vvvv}}w}r-.`r~  ?wwzw}}}rwwrv??>+vr?~|^^>>>>>>>>>`<br></br>
`???????????????????}vvrwwwwr??}?v`.-w-  vz}vvv?rwwr}v}wwrr}?|^^>>>>>>>>>>`<br></br>
`???????vvvvv???????v?vvv}zwz??}rv`.~w~ `rvv}v>?r?}v?~>v?}}?+^^^>>>>>>>>>>`<br></br>
`??v}r}vvvvv????????vvv?vwzzzrvvvv`.~}` ^zv?v>^vwrww}?v}?wz?^^^^>>>>>>>>>>`<br></br>
`vvrz}vvvvvv???????vv???}r}vvwxzw}..-v` |v}r????r}ww+>?}www>^^|^+>>>>>>>>>`<br></br>
`vv}vvvv}wwrrrwwv????v}}vv????wuuxv``+..`?}}vr?`>v}vv??vv?>^^^|++>>>>>>>>>`<br></br>
`vvvvv}wxxzwwww}??????>???????}uuyz|.~..`^??vv?-|?v}v^?+^|~|^^|^>>>>>>>>>>`<br></br>
`vvvvvrzzwwwwrv???v???>>??>>>++rwwv..-..`^^^>?^|^^^^|~||||~~^^|^>>>>>>>>>>`<br></br>
`????vr}rwr}v?????????>++++>?>^>??~..~..-???>>>>?>>>>>>>>>>^^^^^+>>>>>>?>>.<br></br>
.????v????????????????>++>???vv}v}+..?>>v}}}v??vvvvvvvvvvvv+^^^^+++>>>>?v>.<br></br>
`?????????????????????>>>???vvvvv}^..>??v}}vvvvvv}}}}}}}}}r?^^|^+>++++>>v?.<br></br>
`+?vv???vvv???????????>???v}}}vvv}?||?vvv}}vvvvvvvvvvvvvvvvv^^^^++++++>>?v`<br></br>
`???????vwrv????????????v}rrrrrrrv+||vrrr}}}}}}}}}}}}}}}}}}}?^^^++++++>>?v-<br></br>
.??v}}vv?????????????+>>??vv}rrrr+--`>r}}}rrrrrrrrrrrrrrrrrr}+^^^++++>>>>?-<br></br>
.>>?>>>?v}??}v??????++++++>>?v}}}vvvvv}}}}}}}}}}}}}}vvvvvvv}}?|^^++++>>>>>`<br></br>
.^?vv}rzxzrrrv????>+>>>???????vvvvv}}vvvvvvvvvvvv}vv????????vv^^^^+++>>>>>`<br></br>
`wzzzzwwr}vv?????>>>>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvv??v??vvvv?|^^^++>>>>>`<br></br>
`vv???????}}v??>++>>>>>??????????????????????????????????v???v?|^^^+>>>>>>`<br></br>
.?????vvvv}rv?>^>>>>>>>++>>????????????????>>>>>>>>>>>>>>>>>>>^~^^^^>>>>>>`<br></br>
.???>>>???>vw?^>>?????????????????????>>>?>>>>??>>>>>>>>>>>>>>~~^^^^+>>>>>`<br></br>
.++++++>??>>rw>>>>???????????????????????????????????????????+~~|^^^+>>>>>`<br></br>
`v++^||~+??>??vv?>>>>>>>>>>>>>>>>>>>>>??>>??????????????????>|~~~^^^^>>>>>`<br></br>
`z}^``.~vw?>^`-||>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^-~~~|^^|^++++.<br></br>


            </div>,

            <div>

 ...... ~-..........^>+.........``---``--`......~}r+^^^^^^^^^||||~~~~~~~|~.<br></br>
 ......+v^..........`-.........`-|~~~``~||`.....ryv>>>>>>^^^^^^^^^^^^^^^^+.<br></br>
 ....`?}?>.......................`.-|^^~```....+zv>>++^^||-``````-^^^^^+>>.<br></br>
 ...-}v??>........................ .-+|` .....`++^^^^^^^^~.......~^^|+>>>>`<br></br>
 ..+rv???>........................   .   ......|^^^^^^||-.......-^^|+>>>>>`<br></br>
 .?wv????>- .~~~-`.....`...-|-....       ....^^-^|^|-`........+^|^^+>>>>>>`<br></br>
.}w??????>^>}}>||-.......?vwzw?...       .|+^~~?vv}}>?+.......|^^^^>>>>>>>`<br></br>
`r???????>+v?^~~|^^?^...^xzrwwv>v}.  ..  `vwv~`?rwwzzwv>>~..`^|^^^>>>>>>>>`<br></br>
`????????++|~^>?vvv?-``.`>+|>rwzzw`  v^  ~}w}vvv}wwwwr}}v?>>+^^^^+>>>>>>>>`<br></br>
`????????>>+?}}v???+??>v?>?vvwww}}` .w>  ?zzzzzzzwwwrvv?>>rz}|^^^>>>>>>>>>`<br></br>
`?????????????????>?zzwwzwwrr?>v??` .z>  vw}r?vr}rzzwrzzzrvv>|^^+>>>>>>>>>`<br></br>
`?????????????????>}wr}}v?r}r>?w}v. `z? -}?rw?^}}?vvv+v?vr?^|^^^>>>>>>>>>>`<br></br>
`???vvv?????????????v??>>rzzzvv???. `z+.vw??v+^wwrrr}?v}vwz?|^^+>>>>>>>>>>`<br></br>
`??vrv???????vvvv????>+?zxzvvzwrw}. `z^`?rr}>+??zzzw?^vzzzw+^^^+>>>>>>>>>>`<br></br>
`??v?????vrzxuuuv????}zzwrv??vrxzrv-.?^-^vwv?z?`?}r?}?^vr}?|^^|+>>>>>>>>>>`<br></br>
`???>??vzuyyyyyr?????v????????vuuyy^ ||||^?}rzv||?}vv>?+^|~^^^|+>>>>>>>>>>`<br></br>
`?????ruyyyyyur???vv???>???>>>>zxx}. ~||||||>?^|||^^|~||||~|^^|^>>>>>>>>>>.<br></br>
`????ryzxxzw}??????????++++++^~^>+`  ~||^^^|||||^|~|~|||~|~|^^^|>>>>>>>v?+.<br></br>
`????vvvv??????????????+^+++>?vvv}|  ?vv}}}vvvvv}v????vv???^^^^|>>>>>>>rx>.<br></br>
`??????????????????????+^++?vvvvv}|  >rrrrrv???v}rrrrrrrrrr?|^^^>>>>>>>?yr.<br></br>
.~>?????vvv???????????>+^?vvvvv??}>..?rr}}vv???vvvvvvvvvvvv?|^^^+>>>>>>>wy~<br></br>
.?>?}wxuyyx}??????????++?}rrrrrrr?~..?r}}}}vvvvvvvvvvvvvvvv}>|^^^>>>>>>>?u^<br></br>
`zxuxwrwrv???????????>+^>?vvrrrrr- . |r}}}rrrrrrrrrrrrrrrrrr}^^^^>>>>>>>>w^<br></br>
`?>>+|^????vww??????>++++++>?v}}}vvvvv}}}}}}}}}}}}}}}}}}}}}}r?|^^+>>>>>>>?|<br></br>
 ->???vvrwzyur?????>++>>>>>>>>?vv}}}}}vvvvvvvvvvv}v??>??????vv^^^^>>>>>>>>`<br></br>
`vrwwwwwww}v?????>+++>???v???vvvvvvvvvvvvvvvvvvvvvvv?????????v?^^^^>>>>>>>`<br></br>
`}vv?>>>?vzzw}?>+++^++>>????????????????????????????vvvvvvvvvv?|^^^+>>>>>>`<br></br>
.?????????wuzr?^>???>>>+++>????????????????>>>>>>>>>>>>>>>>>>>>|^^^^>>>>>>`<br></br>
.???>>>>>+^ru>|+>????????????????????>>>>?>>>>>>>>>>>>>>>>>>>>>+|^^^>>>>>>`<br></br>
.+++++++++>+zu>+++>????????????????????????????????????????????>|^^^+>>>>>`<br></br>
`w>+|||~||^|?}r}?++>>>>>>>>>>>>>>>>>>>>?>>??????????????????????^^^^^>>>>>`<br></br>
-uw+-~~^?v|~--~^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^|^^|+>>>>.<br></br>


            </div>,

            <div>

                  .......``..........`++`........``---``--`......`+??+^^^^^^^^^^^^^||||||^|.<br></br>
 ......~>~...........``........`~|~~~``~|~`.....>vr?>>>>>++^^^^^^^^+++++>>.<br></br>
 .....^>>?-.....................```-||~-```....~????>+++^^|~~~~~~|^^+++>>>`<br></br>
 ...`+>>vv|....................... .~+|. .....`||^+^^^^^^|~-```--|^^+>>>>>`<br></br>
 ..~>+>vv?+.......................   .   ......-~|^^^^^|~``....-|^^+>>>>>>`<br></br>
 .^>+>vv??>..```-`..........``....       ....-^`--||~-`.......~^|^+>>>>>>>`<br></br>
.>>+?v?????^+vv+|`.......~+???|...       .~^~-+>>?>>^+-......`|^^++>>>>>>>`<br></br>
.>+?v??????>v?>^|^~^-...-vwzzz?|^`   .   `vw}|`^vrw}ww?++`.`-||^++>>>>>>>>`<br></br>
.+?v???????^|^+???>>^``.-???vwwr?` ..^.  ~?rwv>?}rwwwwr}v^^?^^^++>>>>>>>>>`<br></br>
.?v????????+>???????>^^>^^>>?rwz>. .-}`  ?zwwwww}}rwrv??>+v?+^^++>>>>>>>>>`<br></br>
`vv????????????vv???v}w}rwrrrv?}~. `~z~  vw}rv}wwwzzwrwzzwv+^^++>>>>>>>>>>`<br></br>
`v??????????????????wwr}}vrr}>?}-..`|}` -}v}v?^}}vr}v?}vv}>|^^++>>>>>>>>>>`<br></br>
`v??vvv?????????????vvv?>vzrrvvv`. `^v..vr?v}+^}}v}rv?}vvv>^^++>>>>>>>>>>>`<br></br>
`??vv???????????????????}xzrrxzr~. `^?`|}rrr>^>vzwwr>+}zzv>^^++>>>>>>>>>>>`<br></br>
`??v??v??vv}rwwwv????vwzzrv?vvrx}>^~|+`~>}w??w?~vrw?}??rw>^^^++>>>>>>>>>>>`<br></br>
`????v?vvrxuuyyx}????v??vv??v?}uuyx-.|~|^+v}}z?||?r}}??>+~|^^++>>>>>>>>>>>`<br></br>
`?????v}zuyyyuz}??v????>?????>?xuu? .|||||^>?v^||^+^|~^|~~|^^+++>>>>>>>>++.<br></br>
`????v}wuxuxzrv????????++>+++^~^?^  .~||||||||||||~~~|||~~||^+++>>>>>>>>+^.<br></br>
`v???vvvvvvvv??????????+^+++>?????`  >????vv????????>>???^^^^+++>>>>>>>??+.<br></br>
`}v????????????????????+^++?v}}}}r^  +wrrrrvvvv}}rrrrrrrr?+^^+++>>>>>??>?>.<br></br>
`>???????vv???????????>+^?vvvvv??}?  -vrr}vv??vvvvvvvvvvvv^^|+++>>>>>??>??`<br></br>
`???v}wzxuuz}?????????>+?}}}}}}}}v+..+}r}}vvvvvvvvvvvvvvvv++^++++>>>>>??>?`<br></br>
`}wxxxzzzrvv?????????>++??vvrrrrr+`..~}r}rrrrrrrrrrrrrrrrr?++^+++>>>>>??+?-<br></br>
`v?>>>>????vrwv??????++^++>>?v}}}vvvvv}}}}}}}}}}}}}}}}}}}rv++^+++>>>>>>?>+`<br></br>
.|+??vv}wzxyuzv????>++>????????vv}}}}vvvvvvvvvvv}vv?????vv?||^^+++>>>>>??+.<br></br>
`v}wzzzwwr}vv?v???>++>???????vvvvvvvvvvvvvvvvvvvvv?????????|||^++++>>>>??+.<br></br>
`vvv??>>??vv}zv?++++++>>???????????????????????????vvvvvvv?||||++++>>>>??>.<br></br>
.?????????vvvz}^+?????>>+++??????????????????>>>>>>>>>>>>>^-~--^++++>>>??+.<br></br>
.>>>>>>>++^?v?v>>???????????????????>>>>>>>>>>>>>>>>>>>>>>~-~-~^++++>>>>>+.<br></br>
.++^+v?~^+^-??^^++>>>????????????????????????????????????>~~~~~^++++>>>>>^.<br></br>
`v>++?|```--^>>>~-+>>>>>>>>>>???>>>>>>???????????????????^~~~-~^^++++>>>>^.<br></br>
`rv^`^~|++~~~~~|~-+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>~~---~|^+++++>>+|.<br></br>


            </div>,

            <div>

 ....................^>+`.......``--```-``........^}?^++^^^^^^^^^^^++^^^^^.<br></br>
 .....................-`.......`~|~~~`~~~-.......~xx>>>>>>>^^^^^^^++>>>>>>`<br></br>
 ........v+.....................`--~~~~~~`.......>yr>>++^^^^|^^^^^^^+>>>>>`<br></br>
 ......`}w>....................... ~+>|.........`+?+^^^^^^^^|`-^^^^+>>>>>>`<br></br>
 .....~rr??.......................  .`   ........-^^^^^^|~``..`^^|+>>>>>>>`<br></br>
 ... ^wv???- ...`~...............        .....|-..-|~-``......~^^+>>>>>>>>`<br></br>
 ...>wv????|-vr?^-.........^^~`..        .-~-`?^`|-`--.......-^^+>>>>>>>>>`<br></br>
 ..?wv?????+vwv+||.......>}zzz?``   .    `?rr^.`>}rvww+^~.`|||^^>>>>>>>>>>`<br></br>
 `vw???????+^^||~``|>-..-}}vrzrv?  ~?~   ~+}z?|?v}wzwwrrv`|?^^^+>>>>>>>>>>`<br></br>
 vzv???????>|~~^+?vv>`|-`~^~+}zx^  ?z}.  ?wwwwr}v?vwrv>>>+?+^^^>>>>>>>>>>>`<br></br>
`xv????????>^+v}vv??>vr?vr}}}rrr`  }zz`  vw}rwwzzzwwwwwwzz}|^^+>>>>>>>>>>>`<br></br>
-r?v????????>vv????>wzwrw}rw}>?v. .rzv  -}vv??^}rwzw}w}vvv+^^^>>>>>>>>>>>>`<br></br>
`}v???????????????>?w}}v??w?v}}r. .wz? .vv?}}+^v?>}r??}vv?|^^+>>>>>>>>>>>>`<br></br>
`??????????????????????>?zzwzzw}. .wz+.?w}rr?|?rzrwr+>wwz?|^^>>>>>>>>>>>>>`<br></br>
`????v}v????vvvvv????v}zz}>>}}}wv^-vz|.|?ww??w?^}wzvv?vww+^^^>>>>>>>>>>>>>`<br></br>
`????v????rxuuyyx????}?v}vvvv?ruuyw~>|~~+>}}vw>~|?w}}??>>|^^|>>>>>>>>>>>+>.<br></br>
`v??????}xyyyyyz??????>>?????>?uuu> ~|||||+?}v^||++^||^|~|^^|>>>>>>>>>>>^|.<br></br>
.??????ryzyyyx}????v???+>>++++~^?|  `|~~||~~||~||~~~~~~|~|^^|+>>>>>>>>>>^|.<br></br>
`v?????rrrr}v??????????+^++++++++>`  +>>>>???>>+++++++++^|^^^>>>>>>>>>>>||.<br></br>
-u}????????????????????+^++?}rrrrw>  +wrrrr}v}}}rrrrrrr}r?^^^>>>>>>>>v}+^|.<br></br>
`v?>^??????????????????+^?vvvvvv?}?  .vr}}}???vv}}}}}}}}}?|^^>>>>>>>>vu?^^.<br></br>
`?????vwzxuuxv????????>+?vvvvvv}}r?..~vr}vvvvvvv??????????|^^>>>>>>>>?u}^|.<br></br>
.v}zxuuxzw}vv????????>++??vvrrrrrv|..-}r}}rrrrrr}}}}}}}r}r>^^+>>>>>>>>ru+|.<br></br>
.vv??v??????}rz??????++^++>>?v}}}}vvvv}}}}}}}}}}}}}}}}}}}r?|^^>>>>>>>>?u?|.<br></br>
`+^>?vvrwzxyuuw????>++>????????vvv}}}vvvvvvvvvvv}vv??v?vvv?~^|+>>>>>>>>rw|.<br></br>
.?vwzxzwr}}vvvvv??>++????????vvvvvvvvvvvvvvvvvvvv??>>>>>???|^|^>>>>>>>>vz^.<br></br>
.?????>>>???vuzr>++>++>>??????????????????????????vvvvvvvv?|^^^>>>>>>>>vr+.<br></br>
.>????>????>>xz^+>?????>+^+>??????????????????>>>>>>>>>>>>^^^||>>>>>>>>}w^.<br></br>
.+^^+>>+^^||~?u?++>?????????????????>>>>>>>>>>>>>>>>>>>>>>~^^|^>>>>>>>>>>^.<br></br>
.^>^+xv->?+--~+??^++>????????????????????????????????????+|^^^^>>>>>>>>>>+`<br></br>
.>?+>z+-~~~~~~~~~-+>>>>>>>>>>>>>>>>>>??>?????????????????^^^^|+>>>>>>>>>+>`<br></br>
.??++?-~~~~~~~~~~-^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+~^|||^>>>>>>>>>^>.<br></br>


            </div>,

            <div>

                  ....................`-`........``--``--``........`+^|^^^^^^^^^^^^++^^^^^^.<br></br>
 .....................`........`-|~~-`~||`.......`?w?>>>>>>+^^++++>>>>>>>>`<br></br>
 ........+^.....................`--~||~~~`.......|rz?>>++^^^^^^+++++>>>>>>`<br></br>
 .......+}?......................  -++~.........`~>>>+^^^^^^|~~^^+++>>>>>>`<br></br>
 .....`?}v?-......................  .`  .........`~^^^^^|~-```-|^++>>>>>>>`<br></br>
 ....`?}v??^.....`...............        .....-`..`~~~-`.....`|^^+>>>>>>>>`<br></br>
 ...~vv????>`>>+|-.........--``..        .`-``?>`-`.``.......-^^+>>>>>>>>>`<br></br>
 ..^vv???????wr?+~.......|?rr}+``   ..   -v}v|`->vv?v?^~``~+~^^+>>>>>>>>>>`<br></br>
 `+vv???????+>+^~-`-^-`.-}w}wz}v?  `+~   ^>vz?~?}rwzzww?+-+?^^+>>>>>>>>>>>`<br></br>
 +vv????????+~~||+?v?---`~^^>rzz>  ^rv.  ?rwwr}v?>vwwv???>?+^^+>>>>>>>>>>>`<br></br>
.?v?????????+^?vvvv?+>v?vvvvvrww|  >zz`  vrrwzwzwwwwwwwwzzv|^+>>>>>>>>>>>>`<br></br>
.vv???????????vv???>}zw}wrwwr??v-  >z}. |}}v???rwwzwrwv???^^^+>>>>>>>>>>>>`<br></br>
`}vv????????????????wr}v??wv?}}r`  >zv .vvvr}>>vvv}r>v}vv>|^+>>>>>>>>>>>>>`<br></br>
`v??????????????????v??>?wzwwzrr-  +z?`^r}rw?^?wwrr}^?www>^^+>>>>>>>>>>>>>`<br></br>
`?????vvv????vvvv????v}wz}>?r}rzv|.~z>~^vww?vr??rwz}v>}z}^^^+>>>>>>>>>>>>>`<br></br>
`????v?v??vwzxxuzv???}?v}v?vvvruuy?.>^~|>>rvv}+|^vw}r?vv+|^^+>>>>>>>>>+++>`<br></br>
`vv?????vrxuyyyx}??????>?????>vuux| `|||||>v}v+||^+||+>|~^^++>>>>>>>>>++^^.<br></br>
.??????vwxuyyuwv???????+>>>+++~+?|  .|~~||~|||~||~~||~~~~^^++>>>>>>>>>^^^|.<br></br>
`}v????vrwr}vv?????????+^+++^^^^+>`  ^>>>+>>>++++++++^^^||^++>>>>>>>>+^^||.<br></br>
-uw????????????????????+^+>?v}}r}r>  ^rrr}}}}}}}}}}}}}}}?^^^+>>>>>>>>?v+|^.<br></br>
`vv?+>?????v???????????+^?v}vvvvv}v  .?rrr}???v}}}}}}}}}v+|^+>>>>>>???z?^^.<br></br>
`?????vrwxuuu}????????>+>vvvvvv}}}v..~vr}vvvvvvv?????????^|^+>>>>>>??>}?^^.<br></br>
.?vwxuuuxw}vv?????????++>??vrrrrrv+`.-}r}}}}}}}}}}}}}}}}r?^^+>>>>>>??>??^|.<br></br>
.?vvvv???vvvrzuv?????>+++++>?v}}}}vvvv}}}}}}}}}}}}}}}}}}r?^^^+>>>>>>?>+?>|.<br></br>
`?>?vrwxxuuyuxwv????++>???????vvvv}}}vvvvvvvvvvv}vvvvvvvv?^|^+>>>>>>??+??|.<br></br>
.?v}wzwr}vvvvvv}}v>++>???????vvvvvvvvvvvvvvvvvvvv??>>>>>?^||^^+>>>>>??+?}+.<br></br>
.??????>>????}}z?>>>>>>>>>???????????????????????vvvvvvv?||^^++>>>>>??+?}>`<br></br>
.>>>>?>>>>>>+v}v^>??????+^+????????????????????>>>>>>>>>^-~^^++>>>>>?>^vv^`<br></br>
.+^++v+``````^}v?>>>????????????????>>>>>>>>>>>>>>>>>>>>~-|^^++>>>>>>+^||^`<br></br>
.^>>>w+`~^|--~|+>^+++>?????????????????????????????????+~~^^^++>>>>>+^^^^+`<br></br>
.>?>+}|~~~~~~~~~~`^>>>>>>>>>>>>>>>?>>?????????????????>~~|^^^++>>>>++^^^^>`<br></br>
.??>++-~~~~~~~~~--+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^-~|||||^++++^^|||^>.<br></br>


            </div>,

            <div>

                  ........................`......``--``--`......... .-~~^^^^^^^^^^^^^^^^^^^.<br></br>
 ...............................-~~~-`~||`........|}?>>>>>>>+^+>>>>>>>>>>>`<br></br>
 .........~.....................``-~^|~~~`.......`vyv?>>+^^^^^^>>>>>>>>>>>`<br></br>
 ........?v...................... .-++- .........`+???^^^^^^^^|^+>>>>>>>>>`<br></br>
 ..... -}}?~.....................   ..  .........`-^^^^^^~--~|^|^+>>>>>>>>`<br></br>
 .....|rv??>.....................        ..........-||~`.....`^|+>>>>>>>>>`<br></br>
 ....^rv????`~-^|~`..............        `````>>.`...........-^^>>>>>>>>>>`<br></br>
 ...^}??????>zzr>-.......`+vv?-``   `.   ~vv?~`-+v>+?^~`.`+>~^^>>>>>>>>>>>`<br></br>
 `.^}???????>??^|-..--`.-rzwzzv?}. .~~   ^?vz>-?rwzzzww+.-?v|^>>>>>>>>>>>>`<br></br>
 `|}????????>~~~`-^??~```|+^?rzz}  -vv.  vvrwrvv?>vwwv?v???^^+>>>>>>>>>>>>`<br></br>
 `vv????????>|^>vvv?^|???v???rzwv  |zw` .rrrwzzzwrwwrwwwww?|^>>>>>>>>>>>>>`<br></br>
 >}???????????}vv??>vwwvwwwzwvvv?  ^zz- ^r}?v?vwzzzwwwv?+^|^+>>>>>>>>>>>>>`<br></br>
.rvv???????????????>wr}}v?rw>}}r?  |zw``vvvw}>??vr}}>}}v}>|^+>>>>>>>>>>>>>`<br></br>
`}v?????????????????vv??>rzwwr}rv. ~zz^~v}rw?+vwrrr}|?zww^^^>>>>>>>>>>>>>>`<br></br>
`??????vv?????????????vrwv>vw}rzw| `w}?>rww?v}?vwzzrv+rzv|^^>>>>>>>>>>>>>>`<br></br>
`??????rv??vrzzzz}???r?vr???}vruuy+ ?+~^??wvv?^|+}wrr>v}>^^+>>>>>>>>>+^++>`<br></br>
.?v??????vzuyyyyzv?????>?????>ryux` `|||~|>v}v+||^+||>>~~^^>>>>>>>>>>>||^^.<br></br>
`???????vuzyyux}???????+>>>>++|>>^. .||~||~|^|||||||||~~~^^>>>>>>>>>>+~|^^.<br></br>
-rv?????rwrrvv?????????+^+++||||^+-  |+++^^++^^^^^^^^|||~^^+>>>>>>>>>^~~|^.<br></br>
-xzv???????????????????+^+>?vvv}}r?  |r}}}}}}}}}}}}}}}vv>^^+>>>>>>>>>+>>|^.<br></br>
`}v?++?????vv??????????+^?}}}}}}vvv  .?rrr}???v}}}}rrrrr?|^+>>>>>>>}}>rv^^.<br></br>
.?????v}wzuyuw????????>+>?vvvvv}}}v- -vr}vvvvvvv????vvvv?|^^>>>>>>>rz+?|^^.<br></br>
`?vwzuuuxw}}v?v???????+++>?vrrrrrr?`.`}r}}}}}}}}}}}}}}}}r>^^+>>>>>>vu>||^^.<br></br>
`?vrvvvvvvv}wxyw?????>+++++>?v}}}}vvvv}}}}}}}}}}}}}}}}}}r>^^^>>>>>>?yv||^^.<br></br>
`??v}zuyyyyyuzwv????>+>?vvvvvvvvvvv}vvvvvvvvvvvv}vvvvvvv}>^^|>>>>>>?yr^|||.<br></br>
.??vrrrvv??vv??wzw>+^+>>>????vvvvvvvvvvvvvvvvvvvv?>>>>>>?|^^|>>>>>>>zr>???`<br></br>
.??????>??????vu??>>>>>>++>?????????????????????vvvvvvvv?|^^|+>>>>>>wv+vvv-<br></br>
.>+++>>>>+++++?x^+>?????+^+?????????????????????>>>>>>>?|^^||>>>>>>>v?^>>?`<br></br>
.+^++z?------~?}v>+>????????????????>>>>>>>>>>>>>>>>>>>+~^^|^>>>>>>>>^+>>>.<br></br>
.^>?>v-~~~~~~~~~++^++>?????????????????????????????????^|^^|+>>>>>>>^|>???`<br></br>
.>?>+?-~~~~~~~~~~-^?>>>>>>>>>>>>>>?>>?????????????????>|^^|^+>>>>>+^|>????`<br></br>
.???+|-~~~~~~~~~~|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>||||||^^^^^^^|^>>>>>.<br></br>


            </div>,

            <div>

                  ...............................``--``--`...........```~|^^^^^^^^^^^^^^^^^.<br></br>
 ..............................`-|~~``~|~`........->>>>>>>>>++++++>>>>>>>>`<br></br>
 .........-.....................``-|+^~`-`.......`>z}?>>>+^^^^^+++>>>>>>>>`<br></br>
 ........+>...................... .-^|. .........`^+>+^^^^^^^^^^+>>>>>>>>>`<br></br>
 ......`>??|.....................   ..  ........``-|^^^^^|-~~|^^+>>>>>>>>>`<br></br>
 .....-???v?.....................        ..........`~|~-`....-^+>>>>>>>>>>`<br></br>
 ....~>>vv??-``~|`...............   .    .````^>``...........-^+>>>>>>>>>>`<br></br>
 ...~??v????>vr}?`.......`~>?>-``   `.   ->?+--~|>^|+~-`.`>?~^+>>>>>>>>>>>`<br></br>
 `.|??v???????v>+`..```.`?rwzr>>v.  ~~   ^?}w^->vwrwwrv~`^vv|+>>>>>>>>>>>>`<br></br>
 `~??v???????^|~``|>>~```^>>vwwzr. .??.  ??}wv????}wwv????v^^>>>>>>>>>>>>>`<br></br>
 `??v????????^>v???v>~+>>?>>?rzz}. .rr. `rrrwzzzw}}wwrwwr}>|+>>>>>>>>>>>>>`<br></br>
.?}vv?????????v}vv???rrvrzwww}}??. .vz- >r}?vvrwzzzwzwv?+||^+>>>>>>>>>>>>>`<br></br>
`r}v???????????????>}wr}v?rw??vvv` .vz|~vv}wv++?rwr}?v}v}+^^>>>>>>>>>>>>>>`<br></br>
`v}vv???????????????vv???}zzrv?rv` .?z?>}}rw>~v}vv}?`?zw}^^+>>>>>>>>>>>>>>`<br></br>
.vv?????v?????????????}}wv?}wvrww+  >wv?wwwv?v?rwwwv?+wz?|^+>>>>>>>>>>>>>>`<br></br>
`??????vv??v}wwzw}???r??r??v}?ruuy| ~?^+??w?vv++?rwr}~}}^^^>>>>>>>>+++^+>>`<br></br>
.?????????}xyyyyxv????>?v?????ryuz- .||||^?vvv^~^>+^+??^|^+>>>>>>>>>++^^^+.<br></br>
`????????wxuyuxrv??????+>>>>>+|>>+.  ~||||~^+^|||||||^|~|^+>>>>>>>>>+^||++.<br></br>
`zrv????vrrr}vv????????>^+++||~~|^-  ~^^^^^^^^|||||||||~|^+>>>>>>>>++^~~++.<br></br>
-uuwv??????????????????>^+>????v}}?  ~r}}}}}}}vvvvvvvvv?^^^>>>>>>>>++^||^+.<br></br>
`r}v?>>????vvv?????????>^?}r}}r}v}v  .vrr}v??v}rrrrrrrr}+|++>>>>>?>??^>+^^.<br></br>
`??????vwzuyuzv????????++?vvvvvvv}v~.`vr}vv??vvvvvvvvvv?^|^+>>>>>?>??++^++.<br></br>
`?v}zxuuuxzzwrrv??????>++>?v}r}rrr?|``vr}}}}}}}}}}}}}}}v?^^+>>>>>?>?v+^+++.<br></br>
`?vv}rwzzwzzxuyxv????>++>>>>?v}}}}vvvv}}}}}}}}}}}}}}}}rv?^^++>>>>?>?r?||^^.<br></br>
`??v}xuuuxxxxzwv??v?++>?vvvvvvvvvvvvvvvvvvvvvv}}}}vvvv}?>^^++>>>??>?xr^|^^.<br></br>
.???vr}v>||^>??v}z?>^++>>>???vvvvvvvvvvvvvvvvvvvv?>+>>>|||^++>>>>>+>wr>v??`<br></br>
.?>?????^-~|^|+rv?+>>>>>++>?????????????????????v????v?||^+++>>>?>+>rv>vvv`<br></br>
.>++>??>^--```^}>>+?????>++???????????????????????????^~~^+++>>>>>^+?>^>>?`<br></br>
.>+>>x?~`-----+?>++>>???????????????>>>>>>>>>>>>>>>>>>--|^+++>>>>+^^^|>>>?`<br></br>
.+>>>}~~---~~~~~^~^+++???????????????????????????????>|-|^^^+>>+++^^|^????`<br></br>
.>?>>?-|~----~~~~`^?>>>>>>>>>>>>>>?>>>????????????????>~~~~~|^++^^|~~>????`<br></br>
.>>>>+~-~--~~~~~~|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^~~~--~~~~~~~-^>>>>>.<br></br>


            </div>,

            <div>

                  ...............................``--`---`..........``..`~^^^^^^^^^+^^^^^^^.<br></br>
 ..............................`-|~~`-~|~`.........-^+>>>>>>+++^^^>>>>>>>>`<br></br>
 ...............................``~^>+~```........|}wv??>+|^^^^^^|+>>>>>>>`<br></br>
 ................................ .`^~. .........`|^^^^^^^||^^^^+>>>>>>>>>`<br></br>
 .........++.....................   .    .......```|^^^^^|~~|||+>>>>>>>>>>`<br></br>
 .......`?v?`....................        ..........`-~~-`....~^>>>>>>>>>>>`<br></br>
 ..... -}v??|..-~................   ..   .````~>```..........-+>>>>>>>>>>>`<br></br>
 .....|rv???>?}v?..........-~```.   ..   `|^|--|--```....~??~^>>>>>>>>>>>>`<br></br>
 ``..^wv??????}??`.````..`?wzv~+?`  -~   +vr}~`+vrv}}v+.->rv|+>>>>>>>>>>>>`<br></br>
 ```^zv??????^|~`.``~~```^??vwrww~  >?  .?>vw>>??vvwz}?++v}^^>>>>>>>>>>>>>`<br></br>
 `-|wv???????+?r?|???~-~|+^^?wzzz^  }}. |r}rzzzzwvvwwrrrv?+|+>>>>>>>>>>>>>`<br></br>
.v}w}????????>?wrvv?>v}v}zrwzww?v+  vz-.?wv>vvzzwzzwzwv?+~|^>>>>>>>>>>>>>>`<br></br>
`rwr???????????????>vzw}v?}wv+??r?  ?xv^?v}w?|^vzwwr?v}vr+^+>>>>>>>>>>>>>>`<br></br>
.?z}v???????????????vvv??vzz}?^}vv  +zzw}vwz^`vv??v>.?zwv|^>>>>>>>>>>>>>>>`<br></br>
.vv???????????????????}}rv?ww?}wrr. ~z}}wwwv?vvzrwwv?>ww+^+>>>>>>>>>>>>>>>`<br></br>
`v??????v???vrwwr}???r??r??}v?rxuu` `r+>?vz?vv>>vwrrv`}}|^+>>>>>>>>+^^^+>>`<br></br>
`??????????zuyyyu}????>?vv????ruuz+  ^|||^?v?v|~^>+^??}+^^>>>>>>>>>>||^^+>`<br></br>
`?????????xxuyuzv??????+>?>>>+^>?>^  -|||~|+>>|||~|||+^~^^>>>>>>>>>+~~||>>`<br></br>
`xxv?????vwr}vv????????>^+++|~-~||~  -^^^^^||||||||||~~~^^>>>>>>>>>||||^>>`<br></br>
-yyuw??????????????????>^+++>>>?vv?. ~}}}}}vvvvvvvvvv??>^^+>>>>>>>>^||^^^+`<br></br>
`wrv?>+????v}v?????????>^?}rrrrrv}v  .vrr}vvv}rrrrrrrrr}^|+>>>>>>v>+||^^^^.<br></br>
`??????vrzuyyzw????????++?vvvvvvv}v~..vr}v???vvvvvvvvvv?^|+>>>>>>z>||^+>>>`<br></br>
`??vzzxuyuuuxzxz??????>+++>?v}}}r}v>``vr}}}}}}vvvvvvvvvv^|^>>>>>?z>^|^++>>`<br></br>
`???rzuyyyyyyyyyv????>++>>???}}}}}}vvv}}}}}}}}}}}}}}}}rv||^>>>>>?x?v>^^^^^.<br></br>
.???vxyuxwrwzzrv?v}?+++?vv}}vvvvvvvvvvvvvvvvvvv}}}vvvv}>||^>>>>>}u?}}vvvvv`<br></br>
.???v}vv^~--|>???xv?^+++>>???vvvvvvvvvvvvvvvvvvvv?++>>?|^|+>>>>>?v>vvvvvvv-<br></br>
.?++>>>?~~~~~~~>z?|+>?>>>+>??????????????????????????v?||^>>>>>?v>>vvvvvvv`<br></br>
.?+^?v??--~~~~~~}v^>????>+>???????????????????????????^||+>>>>>>>++?>>>>>?`<br></br>
`>+>?xv^``-~~~~~~++++>??????????????>>>>>>>>>>>>>>>>>+~^^+>>>>>>++????????`<br></br>
.>?>>}^-``|~~~~~~`++++>????????????????????????????>?>|||||^^^^^|>????????`<br></br>
.>>>>>+>|`+>|~~~~`+?>>>>>>>>>>>>>>>>>?????????????????>>>>>+++++>?????????`<br></br>
.>>>>>>|-~+>>+|~~^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ...............................`--`.`````.........`...`-||||||||^^^+++^^^.<br></br>
 ..........................```--~||~``-~|-```...``.`|^+++++^||||^^>>>>>>>>`<br></br>
 .........................````--``-|+>|`---`.....`|vv?>?+|~|~~|^^^>>>>>>>>`<br></br>
 ......................````........`|~. `-`......`~^|~~~||~|||^^++>>>+++>>`<br></br>
 .........^|...........````......   .   ........```~^^|^^|~~|||+>>>>>>>>>>`<br></br>
 .......`???.....................        ..........`-~~-`...`~^>>>>>>>>>>>`<br></br>
 ......`????~..~|.................  ..   .````~+````-``````..-+>>>>>>>>>>>`<br></br>
 .....~v????>?}v?..........~|```.   ..   `||~--~--``~-```~>>~^>>>>>>>>>>>>`<br></br>
 ``..|}???????v?>``````..`?rwv~^?`  ..   ^?vv-`|?rv}rv>~^?v?|+>>>>>>>>>>>>`<br></br>
 ```^r???????+||````~~```|>>?}vvv~  -^  .??v}>+>?v}ww}???v?^^>>>>>>>>>>>>>`<br></br>
 ``|}v??v????>?r?^???~-~~^^^>v}rr+  >}. `v}}wwrwr??}rrrw}?^^+>>>>>>>>>>?>>`<br></br>
.??}v??vv??????rwvv?>vvvvwrrwrr?v>  >z~.|v}?vvwwwwwwwwvv>~|^>>>>>>>>>>??>>`<br></br>
`vrrvvvv???????vv??>vwwrv?}wv+??r?  >x?`|?rw?^+vzwwrvv}vv^^+>>>>>>>>>>?>>>`<br></br>
.?z}vvv?????????vv??vvvv?vwzr?^}}v  |zv+^?rw+`vv?v}+`?wr?|^>>>>>>>>>>??>>>`<br></br>
.v}v??v???????????????}}w}vww?vwr}. -z?^>vzv+?vrvrr?>>wr+^+>>>>>>>>>>??>>>`<br></br>
`v??????v???v}}}}v???r??}??rv?vzxz- .r^`~vz?????vrvvv|rv|^+>>>>>>>>++>++>>`<br></br>
.??????????wxxxxxv??????vvv???rxxz>  +~.`>?vvv|^>?^|?v}+^^>>>>>>>>>>^>+^>>`<br></br>
.?????????zzxxxzv?v????>????>>>vv>+..~-.`-|???>+^|~|~>^~^^>>>>>>>>>+||^^>>`<br></br>
`wzv?????vrvvvvvvvv????>>??>>++?>|~. --`-|^||+>^--~~---~^^>>>>>>>>>||^^+>>`<br></br>
-uuxwvv????????vvv?????>+>>>+>>????. ~?+?v???vv????>>>>^^+>>>>>>>>+^^^^++>.<br></br>
`rrvv?>????vvv?????????>+>?vvvvv?vv. .+>vvv?vvvvvv??????++>>>>>>>?>+^^+++>.<br></br>
.??????v}wzxxwr????????>+??vvvvvv}v^-`^>vv??vvvvv????>>+^^+>>>>>>r+^^^+>>>`<br></br>
`??vwwwxxxxxxxxz???????++>?v}}}}r}v?|-^>}}}}}vvvvvv????>^^^>>>>>>r+^^^+>>>`<br></br>
`???}wzuuyyyyyyyv?????>+>>???}}}}}}vv?>>v}}}}}}}}}r}vvv?^^^>>>>>?z>?+^^++^.<br></br>
.???vzxuxzzzxxw}vv}??>+??vvvvvvvvvv}v???vvvvvvvvvvvvvvv>||^>>>>>}x>???????`<br></br>
.>??vvvv>^^^+>???zv?>++>>>??vvvvvvvvv????vvvvvvvv?>>>??|||^>>>>>?v+>>>????`<br></br>
.?>>>>??^---```>w?+>>>>>>+>????????????????????????????|||+>>>>?v>^>++>>>>`<br></br>
.?++????|``````-vv>>>???>+>???????????????????????????^||^+>>>>>>++||||^^^.<br></br>
.>+>?r?^```--````>>++???????????????>>>>>>>>>>>>>>>>>>~||^++>>>>++>^^^|^^^.<br></br>
.>>>>v^-`-|-~~---^++++????????????????>>>>??????????>>|~|||||||||>>^^^|^^^.<br></br>
.>>>>>^>|`^^~~~~~|>>>>>>>>>>>>????>>>>>>>>>?????>>>>>?>>>>++++++>?>+^||||^.<br></br>
.>>>>>>|-|>+|~~---+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++^||||.<br></br>


            </div>,

            <div>

 .........................`||||||||-~~~-||||^^^~^~ .............-~|^?r?|+>.<br></br>
 ........................`|++++++++^?>>+++++^^~~v?+............`^+>>?v?+?r-<br></br>
 ......................`|++^+^++^++^>??^^++^`...^vv>..........`^+>>>>>+|^+.<br></br>
 .....................^????>~--`-^+^^+^^++^-.....-+?>`....```~^^^+>>^+|^>>`<br></br>
 ....................`>??>+~.....`-~~`-|~`......``.~v?~~||^^^^^^^|+>>?v?^>`<br></br>
 ............ .........`.........~~~-``~~~`........`~^+^^^^|^^^^^^>>>>>>>>`<br></br>
 ...........~>>?>``................`|^|~.``````````?v|^^^^^|^^^^+>>>>>>>>>`<br></br>
 ..........`??rrv`.........-~````.  .~. .``````````^vv^^^++|||+>>>>>>>>>>>`<br></br>
 ``......`|->^^^-~|````..`->v?|```.      -~~````^vr}wz}vvw?^^+>>>>>>>>>>>>`<br></br>
 `````````-??>^>--|``````-~^~^----   ~.  ?rv+~~|>rr}vvwzzv|^^>>>>>>>>>>??>`<br></br>
 ``````?}v????v}???>|-``---|||+>??  `z~  ?}v}v?r?>+|>}www>^^>>>>>>>>>>?uv>`<br></br>
 ``--^?z}v????>}wzr>^+???vv?rwv>wv  ~x|  ?zwwvvwwrv}wwww}|^+>>>>>>>>>>zz>?~<br></br>
 `+rrwz}v?????>?zwwr}rwrvvwwv?>?w}. ~x^  ?zww>vzwwzzwwwz?^^>>>>>>>>>>vyv>v~<br></br>
.vwzzzrv???????+rwr}v?}w?|vzr??}zz~ `z^  >v}?~?}wzz}wwrw^^+>>>>>>>>>>wx>>r~<br></br>
`wwwv?}??????????v????zzwv}wz}>}v}? `r~  ?z}`>rv>vr>?v}v|^>>>>>>>>>>?ur>>w~<br></br>
.vr}+?}vv???????????>vr}vv}wv+`>+^v^.}+  vwv+^v}v}+~?zz?^+>>>>>>>>>>?uv>?w~<br></br>
.?vv?}v????????????????vvrr?rrvv}rz?`>- .v|}z?|?}w-`vzr^^>>>>>>>>>>>?u?>>w~<br></br>
.+?v}??????vv?vrwzwv??}??wr?}rxyz?vv}?` .`+r}wrv>>^+~??|^>>>>>>>>>>>>?>>>?-<br></br>
.|?v?????????vuyyyz????>?vvvvvxu?~|^>^. .`>>+vr?~||^^|||+>>>>>>>>+^+>>>>>>`<br></br>
`v}}rwr?????rzzxw}??????>???>>+^~||~~|. `|||||>~|^|^^||^>>>>>>>>+||>>>>>>+.<br></br>
.?vwyyxwv???vv??????????+++++^||^+>>>+  ^>>>>+^+^|^|^||+>>>>>?r+^^|>>>>>?v.<br></br>
.?v?}w}v?>?????v????????^^>?vvvv}v}rr?  ?v?vvvvvv+|^^^|>>>>>?rv>^^|+>>>>>w|<br></br>
`vv???????vwxzxxw??????>+?vvvv}}}}}}r^  v}vvvvv}}}?|^|^>>>>>>+?r>||^>>>>>?-<br></br>
`v}????rxuyyuuuxzzv????++>>?v}}}}}}}r- .?r}}}}}}}}r?>+++++++>?}}}^^|^>>>>>.<br></br>
.?vv???}xxuyyyyyyuw???>+>>>>?vvvvvv}v`.`?}}v?>>>?vv}}}}vvvvv}}}}}?||^+>>>>`<br></br>
.++vv??vxyuxwwrr}????>+>?vvvvvvvvvvvv^~|?vvvvvvvvvvvvvvvvv?????vv?|^|^+>>>`<br></br>
.???????vvv?>>>?????^^++++????????????????????????????????????????^^|^+>>>`<br></br>
.>>>++>>????????>>r?>?>>>>>???????????????????????????????????????>|^|+>>>`<br></br>
.>++>>>>??>??--^|wv+>??????????????????????????????????????????????|^|^>>>`<br></br>
.+>>?????r?~~`~>?u>++>???????????????????????????????????>>>>++>>>?|^|^>>>`<br></br>
.?>>>>>>?>^-~~~~>r?>>>>>>>>>>????>>>>>>>>>>>>>>>>>>>>>>>>>++^^++++>^^^^^++.<br></br>
.>>>>>>>>>?^~~~~~|++^^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++>>++++>+||||||.<br></br>


            </div>,

            <div>

 .........................`||~~~~~-`|||~~~~~~||~^~ .............-~|^>}>~^+.<br></br>
 ........................`|^++^^^^^^v??>++^^||~~??^............`^+>+>v>^?}`<br></br>
 ......................`|++^+^++^+^^?v?^^++^`...|??+..........`^+>>>>>+~^+.<br></br>
 .....................|????>~~~-~^+^^+^|^+|-.``..-+?+`....```~^^^+>>^+|^>>`<br></br>
 ....................`+??>+~....`-~~~``~~-`````````~?>--~||^^^^^^^+>>???|+.<br></br>
 ......................`........`||~~``~~~``````...`~|^|||||^^^^^^+>>>>>++.<br></br>
 ...........~>>?>.`................`~^|-```-```````?v^+^^^^^^^^^++>>>>>>++.<br></br>
 ..........`>>vv?`.........-~````.  .-. .``````````^v?++^++|^|+>>>>>>>>>>>`<br></br>
 ``.......~-+|^+~||.``...`-+??~```.      -~~``-`^v}r}v??vv>^^+>>>>>>>>>>>>`<br></br>
 `````````~??>+?~||``````-~^~|----.  -.  >rv+~||+}w???v}rv|^^>>>>>>>>>>??>`<br></br>
 ``````?vvv???v}???>^--```-|||+>??  .v-  ?}v}v?r?+|~+?rww+^^>>>>>>>>>>?xv+`<br></br>
 ``--|?w}v????>}wzr>+>???vv?rwv>rv  -v-  ?wwwvvww}vvrwwzv|^+>>>>>>>>>>wz>?~<br></br>
 `+vvrw}v???????wwwr}rwrvvwwv???rr. `}~  ?zww>vzwwzzwwww>^^>>>>>>>>>>vuv>v~<br></br>
.vrwwwr}???????+}wr}v?}wv|vwr??}wz- `z+  +}rv|vrwzzrwwr}^^+>>>>>>>>>>rx>>w~<br></br>
`wwwv?r??????????v????wzwv}wz}>}vv^ `r|  +z}`>wv?v}>?vvv|^>>>>>>>>>>?zw>?z~<br></br>
.vr}+?}vv???????????>vr}vv}}v^`>>^+-`r+  >r?^^?}v}^~?ww>^+>>>>>>>>>??xv>?z~<br></br>
.?vv?}v????????????????vvrr?wrvv}rv+`?~ .>|vr?^?}}`~}w}^^>>>>>>>>>>>?uv>>w~<br></br>
.+?v}??????vv?vrwwwv??v??rrv}rxuz??>??` ..^r}}}v>+^+|?>|^>>>>>>>>>>>>?>>>?~<br></br>
.|?v?????????vxyyyzv?????vvv?vxu?|^~++`  .+>>?}?-|||^|||+>>>>>>>>+^+>>>>>>`<br></br>
`vv}rw}?????}wzxz}??????>???>>>^~||~-~. .`|^^|>~~^|||||^>>>>>>>>+||>>>>>>+.<br></br>
.?vwyyxwv???vvv?????????+++++^||^+>>>+  `+>>>^^^^^^||||+>>>>>?}+^^^>>>>>?v.<br></br>
.?v?}wrv?>?????v????????+^+????vvvv}r?  +??vv???v+|^^^|>>>>>?rv?^^|+>>>>>w|<br></br>
`vv???????vrzzxzwv??????+?vvvvv}}}}rr+  ?vvvvv}}}}?|||^>>>>>>+?r>|^^+>>>>?-<br></br>
`v}????}zuuyuuuxzzv????++>>?v}}}}}}}r- .?}}}}}}}}}r?>+++++++>?rv?^^^^>>>>>`<br></br>
.??v???}xxuyyyyyyuw???>+>>???vvvvvv}v`.`?}vv?>???vvv}}vvvvvvvvvvv>^^^>>>>>`<br></br>
.++?v??vxuuxwwrr}????>+>?vvvvvvvvvvvv|~~>vvvvvvvvvvvvvvv??????????|^^^+>>>`<br></br>
.????????vv?>>>?????^^+++>???????????????????????????????????>+^++^^^^^>>>`<br></br>
.>>>++>?????????>>}?>>>>>>>??????????????????????????????????>>>>>+^^|^>>>`<br></br>
.>++>>>?vv??>-`^|r?>????????????????????????????????????????>++++>+|^|^>>>`<br></br>
.+>>????>}?~~`->?x>>>???>>>?????????????????????????>>>>?>>>+||^^+>|^|^>>>`<br></br>
.?>>>>>>>^~-~~-~>r?>>>+>?>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>++^+++>+>^|||^++.<br></br>
.>>>>>>>?>^~-~~~~|+|~~|+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++>>>>++>+|~~~~~.<br></br>


            </div>,

            <div>

                  ...................................+?v?^................................. <br></br>
                  ..................................vwwwwz?.......................```...... <br></br>
 ........................-```--~||-vw}vrw?....................`|+>>+++|... <br></br>
 ......................`+v?^|+++++^^?^|>?~-~~~-`.............`|+>vr+>??... <br></br>
 .....................~??~+|~^+++++^|^|||++++++^-```........`|+>>>?^+v+... <br></br>
 ...........`|>^.....~?|......~|^++^^?>+^++++++^~``.`--`...-^+>>>+^~|+^... <br></br>
 ...........^?v}+....?>.........`|+^|>>^^+^++++^-~`.~??++++>>>>>>>>+|^>-.. <br></br>
 ...........`.`-`....`.....```````-~~--||~`----~++^+??>>>>>>>>>>>>>>>>>>>^.<br></br>
 ``.......`|``?rv?-.``....````````~~~``~~~-``|???+?w?>>>++++>>>>>>>>>>>>>>`<br></br>
 ```````|^+?rvvwv>||-````---------|-`~|~`^?>|^?>~?x?>>++?+|^>>>>>>>>>>>>++.<br></br>
 ``````^vwzw}??vvvvvv>~`-`---~^>??}^ .~. ?vv}r}?^++^^|?rr^^>>>>>>>>>>r??>+.<br></br>
 `````^vv}rv????zwv>+??++???v}}?vz}.     >}wwrr}????v}ww>|+>>>>>>>>>vz>rww~<br></br>
 ``-~~?v??v?????}zzw}}ww}vv}rv?>vr+  |`  >z}v??vwzzzzww}|^>>>>>>>>>>xvvwww~<br></br>
.^?vwrwww}v??????rrwzwwww?>>}rvvv>. `w>  |zzw}wwzwwwwww>^^>>>>>>>>>vx?wwww~<br></br>
.>vv?}zw}v??????+^+v}vvzwzwrw}vwr?  ?zv  -rv+>r}vwr}ww}^^>>>>>>>>>>xv?zwzz~<br></br>
`>+++?}}v????????>???>?}w}?>?|.>v?  vwr. .?``?>v>+~???>|+>>>>>>>>>?u?}zwzz~<br></br>
`}>??>^vvv???????????+>vv}rwrv>vzw` >zw- .?-~?wrr^.vzv|^>>>>>>>>>>vw+rzwww~<br></br>
`}???vvv?????v???vvvv??v??v}vwwzwz^ ~w}|  ^vv-?}>||?w+|^>>>>>>>>>>>>>vwwww~<br></br>
`?^^?v???v?????}xuyuv?v??v??>vuyr}v.~v^`  ^}r+v?`??`+||+>>>>>>>>>>>>>^>?vv-<br></br>
`v?>vvvvv???>?}zyuzv??????????}v+>?.`|~.  ^??v?+~r?`-~^^>>>>>>>>>>>>>+||^^.<br></br>
.?vvvxyywwv??v}vvv???????>>>++|`~||~||~  `||~||~|++~~|^^>>>>>>>+>>>>>>||||.<br></br>
`?+?vvwz}vv>?????????????^++^^~->>????~  >???>++>>>~^^|^^>>>>?w?^>>>>??>?v-<br></br>
`}vvv????????}rrzwv??????+?vvvvv}vvrr}. `???vvvvvv}>^||^^+>>>?yr|^>>>?z?rr~<br></br>
`v}vv????}wxuuuuuzzv????>>??vv}}}}}}r>  >v????vv}}}r}>^|||+>>?v+|+>>>?x?rr~<br></br>
`??>?v???}xxyuyyyyuw???>+>???vvvvvv}v. `?vvvvvvvvvvvv}v?>>>>>+||^+>>>}v?}}~<br></br>
.+>>>????vxuxzr}}vv???+??vvvvvvvvvvv?`.~?????????????????????^^|^>>>>>+??v-<br></br>
.????>??v??v?>+>>??>>^+>>?????????????>>?????????????????????|^^+>>>>+?vvv`<br></br>
.>>>>+rxxw?>?>??>++v>+>>++??????????????????????????????????+^|^+>>>>+????`<br></br>
.>>+>??vzzw>`|+^++>u?>??>>>?????????????????????????>>+>>???|^^^^^+++|>???`<br></br>
.??????>|}r>`+?>+^+wxr?>++>>?????????????????>>>>>>+++++++>>^|||||||||+>>?`<br></br>
.??>>>?>~|~-->>>>+~>??~^???>>???>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>+?>~-+>>>+~?|-~~~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ...................................+?v?^................................. <br></br>
                  ..................................vwwwwz?.......................```...... <br></br>
 ........................-```--~||-vw}}rw?....................`|+>>+++|... <br></br>
 ......................`+v?^|+++++^^?^|>?~-~~~-`.............`|+>vr+>??... <br></br>
 .....................~??~+|~^+++++^|^|||++++++^-``.........`|+>>>?^+v+... <br></br>
 ...........`|>+.....~?|......~|^++^^?>+^++++++^~``.`--`...-^+>>>+^~|+^... <br></br>
 ...........^?v}+....?>.........`|+^|>>^^+^++++^-~`.~??++++>>>>>>>>+|^>-.. <br></br>
 ...........`.`-``...`.....```````-~~--||~`----~++^+??>>>>>>>>>>>>>>>>>>>^.<br></br>
 ``.......`|``?rv?-.``....````````~~~``~~~-``|???+?w?>>>++++>>>>>>>>>>>>>>`<br></br>
 ```````|^+?rvvwv>||-````---------|-`~|~`^?>|^?>~?x?>>++?+|^>>>>>>>>>>>>++.<br></br>
 ``````^vwzw}??vvvvvv>~`-`---~^>>?}^ .~. ?vv}r}?^++^^|?rr^^>>>>>>>>>>r??>+.<br></br>
 `````^vv}rv????zwv>+??++???v}}?vw}.     >}wwrr}????vvww>|+>>>>>>>>>vz>rwr~<br></br>
 ``-~~?v??v?????}zzw}}ww}vv}rv?>vr^  |`  >z}v??vwzzzzwwv|^>>>>>>>>>>xvvwww~<br></br>
.^?vwrwww}v??????rrwzwwww?>>}rvvv>. `w>  |zzw}wwzwwwwww>^^>>>>>>>>>vx?wwww~<br></br>
.>vv?}zw}v??????+^+v}vvwwzwrw}vwr?  ?zv  -rv+>r}vwr}ww}^^>>>>>>>>>>x}?zwwz~<br></br>
`>+++?}}v????????>???>?}w}?>?|.>v?  vwr. .?``?>v>+~???>|+>>>>>>>>>?u?}zwzz~<br></br>
`}>??>^vvv???????????+>vv}rwrv>vzw` >zw` .?-~?wrr^.vw?|^>>>>>>>>>>vw+rwwww~<br></br>
`}???vvv?????v???vvvv??v??v}vwwzwz^ ~wv|  ^vv-?}>||?w+|^>>>>>>>>>>>>>vwwww~<br></br>
`?^^?v???v?????}xuyuv?v??v??>vuyr}v.~v^`  ^}r+v?`??`^||+>>>>>>>>>>>>>^>?vv-<br></br>
`v?>vvvvv???>?}zyuzv??????????}v+>?.-|~.  ^??vv+~r?`-~^^>>>>>>>>>>>>>+|^^^.<br></br>
.?vvvxyywwv??v}vvv???????>>>++|`~^|~||~  `||~||||++~~|^^>>>>>>>+>>>>>>||||.<br></br>
`?+?vvwz}vv>?????????????^++^^~->>????~  >???>++>>>~^^|^^>>>>?w?^>>>>??>??-<br></br>
`}vvv????????}rrzwv??????+?vvvvv}vvrr}. `???vvvvvv}>^||^^+>>>?yr|+>>>?z?rr~<br></br>
`v}vv????}wxuuuuuzzv????>>??vv}}}}}}r>  >v????vv}}}r}>^|||+>>?v+|+>>>?x?rr~<br></br>
`??>?v???}xxyuyyyyuw???>+>???vvvvvv}v. `?vvvvvvvvvvvv}v?>>>>>+||^+>>>}v?}}~<br></br>
.+>>>????vxuxzr}}vv???+??vvvvvvvvvvv?`.~???????????????????>?^^|^>>>>>+??v-<br></br>
.????>?????v?>+>>??>>^+>>?????????????>>?????????????????????|^^+>>>>+?vvv`<br></br>
.>>>>+rxzw?>?>??>++v>+>>>+??????????????????????????????????^^|^+>>>>+????`<br></br>
.>>+>??vwzw>`|+^++>u?>??>>>?????????????????????????>>+>>>??|^^^^^+++|>???`<br></br>
.??????>|}r>`+?>+^+wxr?>++>>?????????????????>>>>>>+++++++>>^|||||||||+>>?`<br></br>
.??>>>?>~|--->>>>+~>??~^???>>??>>>>>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>+?>~-+>>>^~?|-~~~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ....................................   .................................. <br></br>
                  ....................................`~-.................................. <br></br>
                  ..............................``..-vwwzr|................................ <br></br>
 ............`-`..............``--`>zwwwz?................................ <br></br>
 ...........?}w}-............|^^++^^??^??-.````````...`......`............ <br></br>
 ...........^+?v|..........`>>-^++++~|~~-^|++++-.``.`>?^~..`|>>++?^....... <br></br>
 ...........`|`.``........|?-..`-|++^>+^^+>>+++~`....````..~>>r?+v>--...`^.<br></br>
 ..........`.. ~v?|.......~~````.`^^~>||+|>v>^|~~`..-?v>-``^>>+||>>?w}`.|}~<br></br>
 ``........+^+||--`..`....`````````~-`~||-`~^>???+|~?vv?+^+>>++^^^>^vwv?vr~<br></br>
 ```````^^>vzzz}?^?v?-```---------~|-`-~~~^^>?>vrrv??>>>>>>>>>>>>>>>^>v?vw~<br></br>
 ``````>rzzzzwv?ww}vr}?~`----~|+>?v>.~|-.+}}?vv?>>>>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````^v}v?vrv???}?>>+++^+>>>?vv}r~   `. >vwv++^+???>+^^>>>>>>>>>>>>||>>>>.<br></br>
 `````^^+??vv????}zv?vvv????rrvvwv       vr??+?}wzzzzw+^>>>>>>>>>?r+??^^||.<br></br>
.|~~+?>>}zw}??????v}wzzzwrvvrwr}}>   |~ .}ww}wzzzwwww}|>>>>>>>>>?v??wzwwv?`<br></br>
.?>vzwwwzw}v?????>~+v}vv}?v?vrwrvv^  >+ .wz???wvvwzwz?^>>>>>>>>>vy???}zzzz~<br></br>
.++>?++}w}}v???????????}}}r?>^??vwr. >^ `}v?>rwr>?vz}^>>>>>>>>>>rx?r}wzwrr~<br></br>
.??^|??v}vv????????????vvvvwr?>v}rw?.?~ `}}~?zwzv^+>^|^+>>>>>>>>x}+??vv}v}-<br></br>
`vvv???v???v????vrwzzv????rwrruywwzv.v- >w>?}rwv?|+?|||>??w}>>>?y?>vzz}wz}`<br></br>
.?rzw??v}rv?????ryyyw???>vvvvvzx}}wrvw` >+?w+>zr>}+?^|^>>>vw>>>?x?^vvrzzz?.<br></br>
`rrv}v?}yyuv???}rr}v?????+???>>>?vwzw?. |+?}>rz}|rr}?||+>>>>>>>>?>-~+vv???`<br></br>
`vvv??v?rxw}v?????v??????^++^^|?w}}r?-  |>?>>rvv~^>}>|^>>>+^>>>>>+`~|||~||.<br></br>
.+>>vvv???????v}zxx}?????^||~~`>?||^|- .|||~||~||~|^||^>>>+^>>>>>+-|^||||^.<br></br>
.|^?v?v????}xuuyuuwx}???>+>^^++>????v> ~????????????^|||^^^^>>>>>>}}}vvvv}-<br></br>
.>??>+vv???}uuyuuuuz}??>^>>???vv}}}rr+ ~??v?vvvv}}}}v?>>>^|^>>>>+v}}}}}}}r~<br></br>
`?????++???vww}v??v???++~>v}}}vvvvv}v. |vvvvvvvvvvv?vvvvv+|^>>>>?vvvvvvvv}~<br></br>
`vvvv?--~>?v???????>>+vr|-^?vvvvvvvv?.`?vvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
.????+|~~-^w>^++++++>??}~+|??????????~~>?????vv???????v?||+>>v??v?vvvvvvvv`<br></br>
.>>>>>+-~~~>^>>>++++>>>>++->???????????????????>>+>>>??>||++>v>???????????`<br></br>
.>?>>>>|~~~~>?????>>++???>>?????????????????????????????+^^^^^+???????????`<br></br>
.??????>|-~-|???????>>????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>.<br></br>
.??>>>>>?+||+>>>>>>>>>>>>>>>???>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ....................................   .................................. <br></br>
                  ....................................`~-.................................. <br></br>
                  ..............................``..-vwzzr|................................ <br></br>
 ............`-`..............``--`>zwwwz?................................ <br></br>
 ...........?}w}-............|^^++^^??^??-.````````...`......`............ <br></br>
 ...........^+?v|..........`>>-^++++~|~~-^|++++-.``.`>?^~..`|>>++?^....... <br></br>
 ...........`|`.``........|?-..`-|++^>+^^+>>+++~`....````..~>>r?+v>--...`^.<br></br>
 ..........`.. ~v?|.......~~````.`^^~>||+|>v>^|~~`..-?v>-``^>>+||>>?w}`.|}~<br></br>
 `.........+^+||--`..`....`````````~-`~||-`~^>???+|~?vv?+^+>>++^^^>^vwv?vr~<br></br>
 ```````^^>vzzz}?^?v?-```---------~|-`-~~~^^>?>vrrv??>>>>>>>>>>>>>>>^>v?vw~<br></br>
 ``````>rzzzzwv?ww}vr}?~`----~|+>?v>.~|-.+}}?vv?>>>>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````^v}v?vrv???}?>>+++^+>>>?vv}r~   `. >vwv++^+???>+^^>>>>>>>>>>>>||>>>>.<br></br>
 `````^^+??vv????}zv?vvv????rrvvwv       vr??+?}wzzzzw+^>>>>>>>>>?r+??^^||.<br></br>
.|~~+?>>}zw}??????v}wzzzwrvvrwr}}>   |~ .}ww}wzzzwwwz}|>>>>>>>>>?v??wzwwv?`<br></br>
.?>vzwwwzw}v?????>~+v}vv}?v?vrwrvv^  >+ .wz???wvvwzwz?^>>>>>>>>>vy???}zzzz~<br></br>
.++>?++}w}}v???????????}}}r?>^??vwr. >^ `}v?>rwr>?vz}^>>>>>>>>>>rx?r}wzwrr~<br></br>
.??^|??v}vv????????????vvvvwr?>v}rw?.?~ `}}~?zwzv^+>^|^+>>>>>>>>x}+??vv}v}-<br></br>
`vvv???v???v????vrwzzv????rwrruywwzv.v- >w>?}rwv?|+?||^>??w}>>>?y?>vzz}wz}`<br></br>
.?rzw??v}rv?????ryyyw???>vvvvvzx}}wrvw` >+?w+>zr>}+?^|^>>>vw>>>?x?^vvrzzz?.<br></br>
`rrv}v?}yyuv???}rr}v?????+???>>>?vwzw?. |+?}>rz}|rr}?||+>>>>>>>>?>-~+vv???`<br></br>
`vvv??v?rxw}v?????v??????^++^^|?w}}r?-  |>?>>rvv~^>}>|^>>>+^>>>>>+`~|||~||.<br></br>
.+>>vvv???????v}zxx}?????^||~~`>?||^|- .|||~||~||~|^||^>>>+^>>>>>+-|^||||^.<br></br>
.|^?v?v????}xuuyuuwx}???>+>^^++>????v> ~????????????^|||^^^^>>>>>>}}}vvvv}-<br></br>
.>??>+vv???}uuyuuuuz}??>^>>???vv}}}rr+ ~??v?vvvv}}}}v?>>>^|^>>>>+v}}}}}}}r~<br></br>
`?????++???vww}v??v???++~>v}}}vvvvv}v. |vvvvvvvvvvv?vvvvv+|^>>>>?vvvvvvvv}~<br></br>
`vvvv?--~>?v???????>>+vr|-^?vvvvvvvv?.`?vvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
.????+|~~-^w>^++++++>??}~+|??????????~~>?????vv???????v?||+>>v??v?vvvvvvvv`<br></br>
.>>>>>+-~~~>^>>>++++>>>>++->???????????????????>>+>>>??>||++>v>???????????`<br></br>
.>?>>>>|~~~~>?????>>++???>>?????????????????????????????+^^^^^+???????????`<br></br>
.??????>|-~-|??????>>>????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>.<br></br>
.??>>>>>?+||+>>>>>>>>>>>>>>>???????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ............^+`...............``..~?}rv^................................. <br></br>
 ..........~~}w?.-................`rzwzzz-................................ <br></br>
 .............-`..............~|||~?}??}?```--`````....................... <br></br>
 ...........................~~+>+++~|~~~~|^+++^`.``........`|^^^++`....... <br></br>
 .........................`+v+~||++^^+^^++++++^-`....~+^..`^>}?>v}^?+`.... <br></br>
 ..........```~~..........?v^``.`^+^^?>^^+^^^~|^^~`.^zzx?`|+>>>|+>>rzr-`.. <br></br>
 ........~vwr}wzv.......~?+-``````~|~~~||~````|>??>~+vvvv^+>>+^||+>?zzwwr}~<br></br>
 ```````?zzwwwwzv````|~`^>------``-~-`-~~-^^-->rwv?vv>>>>>>>>>>??+^^?}}wzz~<br></br>
 ```````vzwwwwww}?|>vzw?~`----~^+>?^`~|`~v}}??}?>>>>>>>>>>>>>>>>>+||^?v??r|<br></br>
 ``````^?>}wwwrvv?}zzwwz?^+++?}}rwz^ `- `w}rr>>>^^^^+>>>>>>>>>>>>>+^^|^++>`<br></br>
 `````~^>vwwwrv???vzwwwwww}vvwrv???.    .wrwzv+^>}}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
.|~--`-?wzww}v?????wwwzzwwwwwwwr}}}  ^^  }zwwzwrwwwwr?|^>>>>>>>>>}v>rrr}vr~<br></br>
.???>?vwwwwrv?????>}zv?v}wzwwwwzzzv  >v  vzz?}wwwwwwz?~+>>>>>>>>>uvvzwzzzw~<br></br>
`rwwww}rwwrv??????>vwv>>vwvvr>vvvvv  ?}  ??>+?w??vvwv~+>>>>>>>>>?u?}zzwrv>.<br></br>
`wwwww??v}vv????????vv??rv?wwv+wwwv  ?v `w}`->?+|+>>||>>>>>>>>>>vw^vw?+++?`<br></br>
`wwwwz??vv?????????????>rrrwwr|vzzv  ?? ?w+?>??vv^~>|+>>>>>>>>>>r}~^^||||+`<br></br>
`ww}vvvv?????????v}}}???v}??vvv}www. ?^.}>`+^}zw>+``~+>>>>>>>>>>zv~||||||^`<br></br>
.vvvv?vwxzv?????wyyyw????+|~^^vyy}w- ~--+||^|}zw++~~~+>>>>??+>>>v?||||||||.<br></br>
`+|^>v?ryywv???rwzw}????>>?>>^^v?^?- `||||||+?v>|^|~~^>>>>vr+>>vv|||||||||.<br></br>
.>?vvv??rwrv????????????>>?vvv?>^~|` `|||||||~~~|~||~^>>>>?x++++|~||||||||.<br></br>
.????v????????vrwr}?????+>?>??????v> .>?v????????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv???v???vxxuyuuxxw???>^+??v}rrrrrv .vrrrrrrrrrrrrrr+^>>>?}>}rrrrrrrrrr}r~<br></br>
`vvvv?????vuuuuuuuzr??>^>?vvvvvvvv}v  ?}vvv}}}vv}}}}}>|+>>>>>v}}}}}}}}}}v}~<br></br>
.???>?????v}}v???v???>+-++?????vvvv?. ^vvv??????vvvvv?||+>>>+vvvvvvvvvvvvv-<br></br>
.???????>??v?>???>>?}?^~?>>?????????~-^????>>>>??????>||+>>>+>>>>>>>??????`<br></br>
.?????~+???w+>???+?v?+^-?????????????????????????????>||^+>>+??????>>>>>??`<br></br>
.????>--~^?w????>???|?^|?????????????????????????????>||||^|+?????????????`<br></br>
.>????|~~-+}????????>~|>?????????????????????????????>>+^^++>?????????????`<br></br>
.??>>?>~~~~~+>>>>>>>>>>>>>>>>?????>>>>>>>>>>>>??????????????????>???????>>`<br></br>
.>>>>>>>~-~-^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ............^+`...............``..~?}rv^................................. <br></br>
 ..........-~}w?.-................`rzwzzz-................................ <br></br>
 .............-`..............~|||~?}??}?```--`````....................... <br></br>
 ...........................~~+>+++~|~~~~|^+++^`.``........`|^^^++`....... <br></br>
 .........................`+v+~||++^^+^^++++++^-`....~+^..`^>}?>v}^?+`.... <br></br>
 ..........```~~..........?v^``.`^+^^?>^^+^^^~|^^~`.^zzx?`|+>>>|+>>rzr-`.. <br></br>
 ........~vwr}wzv.......~?+-``````~|~~~||~````|>??>~+vvvv^+>>+^||+>?zzww}}~<br></br>
 ```````?zzwwwwzv````|~`^>------``-~-`-~~-^^-->rwv?vv>>>>>>>>>>??+^^?}}wzz~<br></br>
 ```````vzwwwwww}?|>vww?~`----~^+>?^`~|`~v}}??}?>>>>>>>>>>>>>>>>>+||^?v??r|<br></br>
 ``````^?>}wwwrvv?}zzwwz?^+++?v}rwz^ `- `w}rr>>>^^^^+>>>>>>>>>>>>>+^^|^++>`<br></br>
 `````~^>vwwwrv???vzwwwwww}vvwrv???.    .wrwzv+^>}}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
.|~--`-?wzww}v?????wwwzzwwwwwwwr}}}  ^^  }zwwzwrwwwwr?|^>>>>>>>>>}v>rrr}vr~<br></br>
.???>?vwwwwrv?????>}zv?v}wzwwwwzzzv  >v  vzz?}wwwwwwz?~+>>>>>>>>>uvvzwzzzw~<br></br>
`rwwww}rwwrv??????>vwv>>vwvvr>vvvvv  ?}  ??>+?w??vvwv~+>>>>>>>>>?u?}zzwrv>.<br></br>
`wwwww??v}vv????????vv??rv?wwv+wwwv  ?v `w}`->?+|+>>||>>>>>>>>>>vw^vw?+++?`<br></br>
`wwwwz??vv?????????????>rrrwwr|vzzv  ?? ?w+?>??vv^~>|+>>>>>>>>>>r}~^^||||+`<br></br>
`ww}vvvv?????????v}}}???v}??vvv}www. ?^.}>`+^}zw>+``~+>>>>>>>>>>zv~||||||^`<br></br>
.vvvv?vwxzv?????wyyyw????+|~^^vyy}w- ~--+||^|}zw++~~~+>>>>??+>>>v?||||||||.<br></br>
`+|^>v?ryywv???rwzw}????>>?>>^^v?^?- `||||||+?v>|^|~~^>>>>vr+>>vv|||||||||.<br></br>
.>?vvv??rwrv????????????>>?vvv?>^~|` `|||||||~~~|~||~^>>>>?x++++|~||||||||.<br></br>
.????v????????vrwr}?????+>?>??????v> .>?v????????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv???v???vxxuyuuxxw???>^+??v}}rrrrv .vrrrrrrrrrrrrrr+^>>>?}>}rrrrrrrrrrrr~<br></br>
`vvvv?????vuuuuuuuzr??>^>?vvvvvvvv}v  ?}vvv}}}vv}}}}}>|+>>>>>v}}}}}}}}}}v}~<br></br>
.???>?????v}}v???v???>+-++?????vvvv?. ^vvv??????vvvvv?||+>>>+vvvvvvvvvvvvv-<br></br>
.???????>??v?>???>>?}?^~?>>?????????~-^????>>>>??????>||+>>>+>>>>>>>??????`<br></br>
.?????~+???w+>???+?v?+^-?????????????????????????????>||^+>>+??????>>>>>??`<br></br>
.????>--~^?w????>???|?^|?????????????????????????????>||||^|+?????????????`<br></br>
.>????|~~-+}????????>~|>?????????????????????????????>>++^++>?????????????`<br></br>
.??>>?>~~~~~+>>>>>>>>>>>>??>>?????>>>>>>>>?>>>??????????????????>>>?????>>`<br></br>
.>>>>>>>~-~-^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ..............................``..|??>~`................................. <br></br>
                  .................................?zzzzz|................................. <br></br>
                  .................................?wvvrr^|^|--``~-........................ <br></br>
 ............................-|^|-~+~~+|^+++++~>??^.........`--~~-.`...... <br></br>
 ...........................~++++++~^|||++++||-|~|?>->?+...|?v>>}?>r?..... <br></br>
 ........`+v?vv}v`.........`^++++++^+?+|++~-`````.`>vvwz?>~+>v+^?>?zwr~... <br></br>
 .......|}zzzzzz?........`^~~^|^|~^^|^|^|-```````~~+v?rwwv|>>+|-^>>vwzw}r>`<br></br>
 ```````^vwwwwwz+..```---??>?-````-~~`-~-~+^-```+ww}vv?>>+>>>>>>+>>>???vv}~<br></br>
 ```````?wwwwwwzw?+^`>rr>>^|~-~||+^--`-->v}v?>>vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwwwv?vv?vzzzr>^^^>???}?.-|..}w}rr}?>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 `````->wwwwwwrv?????zzzzzrv?vwwwz>  .  >w}ww>^>>>>>>>+>>>>>>>>>>?v>vvv?>|.<br></br>
.~~~--~}zwzwrrv?????>}?v}wwwwwwwwz^  -  -zwzr|>>>?>?v?~^>>>>>>>>>r?}zzzzzw-<br></br>
.??+^|>rrwv>?v??????>}?+?}zzwwwwrr~ .r` .vwv?++>?w?ww^|>>>>>>>>>>xvwwwwwv?`<br></br>
`}r?v}r?vv>?}v??????+rrvv}rvr?v?}v` `z+ .v^-^^~|^^|}v~+>>>>>>>>>?xvwzzwwrv`<br></br>
`wwwwww>?vvv?????????????v^vz?^rzz` |x?  }?^v}v?v`>}+^>>>>>>>>>>vz?rr?>>>v~<br></br>
`wwr?v}vv????????????????r}w}v-vzw. +wv  ?+?^vzzr^^?|+>>>>>>>>>>v}~^^||||+`<br></br>
`rwr??vv}}???????vvvv????vv????vzr. ~?? -+.?~+zw+~``^>>>>>>>>>>>>>~||||||^`<br></br>
`?^^?vvzyy}?????}uyyz????>^~~~?uyv. >}^.^|`|>rzzv^`-^>>>>>>>>>>>>>||||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>>v??>+}}>. |?~`~|||+>v?>||~^>>>>>>r^^>>>?}|~|||||.<br></br>
.????v??v}v?>???????????>>??????^~` .~||||||~~~~~~|~|+>>>>>x+|>>>>}}~|||||.<br></br>
`vv?>?v???vvvv}wzw}?????++>>>??????. |??vv??????????^^>>>>>x?++>>>>z>vvvvv-<br></br>
`}}}v?v???zuuyyuyuzv???>^>?vv}}vv}r| -r}}}}}rrrrrrrrv|^>>>>r}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>^?vvvvvvvvv}+. ?vv???vvvvvvvv}>|+>>>>+>>>>>????????`<br></br>
`vvv???>>??vv?>?vv??>+>?vvvvvvvvvvv>`.|?????????????>||+>>>>>?????????????`<br></br>
.???????v?>+v???wxw>>???????????????>>??????????????||^>>>>>vvvvvvvvvvvvvv`<br></br>
.????????>>rr??>>?}|>??????????????????????????????>|||^++|???????????????`<br></br>
.??????????zv>??>>+->??????????????????????????????>>++++++???????????????`<br></br>
.>>>>>>>>>>>rrv~-?+->>+++>????????????????????????????????????????????????`<br></br>
.>>>>>>>>>-|~~--~~-+?>>>>>>>>??????>>>>>>>??>?????????????>?????>>>>>>??>>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ..............................``..|??>~`................................. <br></br>
                  .................................?zzzzz|................................. <br></br>
                  .................................?wvvrr^|^|~-``~-........................ <br></br>
 ............................-|^|-~+~~+|^+++++~>??^.........`--~~-.`...... <br></br>
 ...........................~++++++~^|||++++||-|~|?>->?>...|?v>>}?>r?..... <br></br>
 ........`+v?vv}v`.........`^++++++^+?+|++~-`````.`>vvwz?>~+>v+^?>?zwr~... <br></br>
 .......|}zzzzzz?........`^~~^|^|~^^|^|^|-```````~~+v?rwwv|>>+|-^>>vwzw}r>`<br></br>
 ```````^vwwwwwz+..```---??>?-````-~~`-~-~+|-```+ww}vv?>>+>>>>>>+>>>???vv}~<br></br>
 ```````?wwwwwwzw?+^`>rr>>^|~-~||+^--`-->v}v?>>vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwwwv?vv?vzzzr>^^^>???}?.-|..}w}rr}?>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 `````->wwwwwwrv?????zzzzzrv?vwwwz>  .  >w}ww>^>>>>>>>+>>>>>>>>>>?v>vvv?>|.<br></br>
.~~~--~}zwwwrrv?????>}?v}wwwwwwwwz^  -  -zwzr|>>>?>?v?~^>>>>>>>>>r?}zzzzzr-<br></br>
.??+^|>rrwv>?v??????>}?+?}zzwwwwrr~ .r` .vwv?++>?w?ww^|>>>>>>>>>>xvwwwwwv?`<br></br>
`}r?v}r?vv>?}v??????+rrvv}rvr?v?}v` `z+ .v^-^^~|^^|}v~+>>>>>>>>>?xvwzzww}?`<br></br>
`wwwwww>?vvv?????????????v^vz?^rzz` |x?  }?^v}v?v`>}+^>>>>>>>>>>vz?rr?>>>v~<br></br>
`wwr?v}vv????????????????r}w}v-vzw. +wv  ?+?^vzzr^^?|+>>>>>>>>>>v}~^^||||+`<br></br>
`rwr??vv}}???????vvvv????vv????vzr. ~?? -+.?~+zw+~``^>>>>>>>>>>>>>~||||||^`<br></br>
`?^^?vvzyy}?????}uyyz????>^~~~?uyv. >}^.^|`|>rzzv^`-^>>>>>>>>>>>>>|~||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>>v??>+}}>. |?~`~|||+>v?>||~^>>>>>>r^^>>>?}|~|||||.<br></br>
.????v??v}v?>???????????>>??????^~` .~|||||~~~~~~~|~|+>>>>>x+|>>>>}}~|||||.<br></br>
`vv?>?v???vvvv}wzw}?????++>>>??????. |??vv??????????^^>>>>>x?++>>>>z>vvvvv-<br></br>
`}}}v?v???zuuyyuyuzv???>^>?vv}}vv}r| -r}}}}rrrrrrrrrv|^>>>>r}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>^?vvvvvvvvv}+. ?vv???vvvvvvvv}>|+>>>>+>>>>>????????`<br></br>
`vvv???>>??vv?>?vv??>+>?vvvvvvvvvvv>`.|?????????????>||+>>>>>?????????????`<br></br>
.???????v?>+v???wxw>>???????????????>>??????????????||^>>>>>vvvvvvvvvvvvvv`<br></br>
.????????>>rr??>>?}|>??????????????????????????????>|||^++|???????????????`<br></br>
.??????????zv>??>>+->???????????????????????????????>++++++???????????????`<br></br>
.>>>>>>>>>>>rrv~-?+->>+++>??????????????????????????????????>?????????????`<br></br>
.>>>>>>>>>-|~~--~~-+?>>>>>>>>?????>>>>>>>>??>?????????????>?????>>>>>???>>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`-`.................................... <br></br>
 ................................`?www}^.................................. <br></br>
 ................................+zwwwz}`-```............................. <br></br>
 ............................``..`??^>v+^+++^|`.``...........````......... <br></br>
 ..........................`^+++||~~~~~^+++^~|>-````......`^>>>?v-.-`..... <br></br>
 .........~|-|>?~..........-^++>>++^>>^^+|--`.`>+``.~?vv|.|+v?+>?^?zv`.... <br></br>
 .......`?wwrvrzw-........--~|+?>|+^^>|^^-`````-|`..|>?rv~^>>+|^>>?rr}-... <br></br>
 ......`v}}wwvrr^........+???>+~``~||--~`````````-~^??}}v++>+>++^>>>?vvvv>.<br></br>
 ```````|^?wwwzv`.`````.~?+?|-``-~~--`-|+++|-```?r}v??>>>>>>>>>>>>>>>>>>>?-<br></br>
 ```````~?wwwwrw}>`~+~|??>--`-~|+|`-~-.+}r}v?+^^v?>>>>>>>>>>>>>>>>>>>>?>^+.<br></br>
 ``````-?}wwwwv>?v??v>rzwr?|^+?rw?  `.  `}}}r}}?>>>>??+^^+>>>>>>>>?+^?rrv?-<br></br>
 ``````?zwwwrrz?>?????r?vrrv??rwwr.      >v}www?^+++^^^^+>>>>>>>>v}?rrwwww~<br></br>
.~~~--~vzwrv>v}v??????w??vwwrrwwww` `^   >w????v?++>?|^>>>>>>>>>>wvvzwwwr}-<br></br>
.??+~~^rvv??v}v??????>wwwwrwwrzwr}- -v  `?}>>>v}rwrr?|>>>>>>>>>>>z?}zwzr}?`<br></br>
`rv>v??r>?}}}}v?????>>rr}vvww?v?w}| `?  ?}^>v?vr}?>?>^>>>>>>>>>>>z?}zwwrrr~<br></br>
`w}}}}r}?}vv??????????????v?}?-vzz> `}.^vw?^rr??v|vz>^>>>>>>>>>>>?>??++^?}~<br></br>
`rwwv?vvv???????????????>}r?>|~vzz> .?.+}v?r?}wrw^?w>^>>>>>>>>>>>>>>>v^~^>`<br></br>
`v}rrvv}zr?????????vv??????+^~|?wr?  v??}>?r}vwz>~-^^^>>>>>>>>++++>>?x+~~+`<br></br>
.+>v}}vryyv?????vxuuxv??????????>?v. }?~^^-^+?wwv>``~^>>>>>>>|||^+>>w?~~|^`<br></br>
.>???v??zuwv???rzyyu}?????vv???xxr|. >>~~~-||+??||`~~|>>>>>w+~~|^>>??~||||.<br></br>
.|^++?v??v??>???vvv?????++?????vr>~. `|~~~|||~~~||~~~|>>>>?x^~~^+>>?+~||~|.<br></br>
.??>|+???v}v?vrwzwv?????+^+++>?>?>?^ |???????????????|>>>>rz>?^^+++>^?v???-<br></br>
`}}?^>???}xuuuyuyzw????+^>??vvvv}}r> ~}v??vvv}}}}}}}v|+>>>vv?}v??>>??}rrrr~<br></br>
`vv?+>+>?vzzzzzz}rrv?>+^>?v}vvvvvv}> .?vvvvvvvvvvv?v?~^>>>>+?vvvvvv}}vvvv}~<br></br>
`vvvv???>>?????^`~}v^^>??>>vvvvvvvv>..>vvvvvvvvvvvvv?~^>>>>>vvvvvvvvvvvvvv-<br></br>
.??????>+++>+--`~~>^+^^????????????>~~>?????????????+|^>>>+?vvvvvvvvvvvvvv`<br></br>
.>>>>>>+>>>>--``~~-|??>>?>>>???????????????????>++>>~||^+^^???????????????`<br></br>
.>>>>>>?>>??+>|-~~~-????????????????????????????????>+++++>???????????????`<br></br>
.>?????????>?>>^-~~->>>>???????????>>>>>>>>>>>>>>>>>>>??>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>+^~~^?>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`--.................................... <br></br>
                  ................................`?wwwr^.................................. <br></br>
 ................................+zwwwz}`--``............................. <br></br>
 ............................```.`??^>v+^+++^|..``...........````......... <br></br>
 .......... ...............`^+++||~~~~~^+++^~|>-```.......`^>>>?v-.~`..... <br></br>
 .........~^~|>?~..........-^++>>++^>>^^+|--`.`>+``.~?vv|.|+v?+>?^?zv`.... <br></br>
 .......`?wzrvrzw-........--~|+?>|+^^>|^^-`````-|`..|+>rv~^>>+|^>>?rr}-... <br></br>
 ......`v}}wwvrr^........+???>+~``~||--~`````````-~^??}}v++>+>++^>>>?vvvv>.<br></br>
 ```````|^?wwwzv`.`````.~?+?|-``-~~--`-|++^~````?r}v??>>>>>>>>>>>>>>>>>>>?-<br></br>
 ```````~?wwwwrw}>`~+~|??>--`-~|+|`-~-.+}r}v?^|^v?>>>>>>>>>>>>>>>>>>>>?>^+.<br></br>
 ``````-?}wwwwv>?v??v>rzwr?|^+?rw?  `.  `v}}r}}?>>>>??+^^+>>>>>>>>?+^vwwv?-<br></br>
 ``````?zwwzrrz?>?????r?vrrv??rwwr.      >v}wzz?^+++^^^^+>>>>>>>>v}?rrwwzz~<br></br>
.~~~--~vzwr?>v}v??????w??vwwrrwwww` `^   >w????v?++>?|^>>>>>>>>>>wvvzwww}}-<br></br>
.??+~~^rvv??v}v??????>wwwwrwwrzwr}- -v  `?}>>>vrwwrr?|>>>>>>>>>>>z?}zzzr}?`<br></br>
`rv>v??r??}}}}v?????>>rr}vvww?v?w}| `?  v}^>v?vwr?>?>^>>>>>>>>>>>z?}zwwrrr~<br></br>
`w}}}}r}?}vv??????????????v?}?-vzz> `}.+rw?^rr??v|vz>^>>>>>>>>>>>?>??++^?}~<br></br>
`rwwv?vvv???????????????>}r?>|~vzz? .>.>wv?r?}wrw^?w>^>>>>>>>>>>>>>>>v^~^>`<br></br>
`v}rrvv}zr?????????vv??????+^~|?rrv  vv?r??r}vwz>~-^^^>>>>>>>>++++>>?x+~~+`<br></br>
.+>v}}vryyv?????vxuuxv??????????+?}. }?~^^-^+vwwv>``~^>>>>>>>|||^+>>w?~~|^`<br></br>
.>???v??zuwv???rzyyu}?????vv???xxr^. >>~~~-||+??||`~~|>>>>>w+~~|^>>??~||||.<br></br>
.|^++?v??v??>???vvv?????++?????vr>~` `|~~~|||~~~||~~~|>>>>?x^~~^+>>?>|||~|.<br></br>
.??>|+???v}v?vrwzwv?????+^+++>?>?>?+ |???????????????|>>>>rz>?^|++++^?v???-<br></br>
`}}?^>???}xuuuyuyzw????+^>??vvvv}}r> ~}v??vvv}}}}}}}v|+>>>vv?rv??>>??}rrrr~<br></br>
`vv?+>+>?vzzzzzz}rrv?>+^>?v}vvvvvv}> .?vvvvvvvvvvv?v?~^>>>>+v}}}}}}}}}}}}}~<br></br>
`vvvv???>>?????^`~}v^^>??>>vvvvvvvv>..>vvvvvvvvvvvvv?~^>>>>>vvvvvvvvvvvvvv-<br></br>
.??????>+++>+---~~>^+^^????????????>~~>?????????????+|^>>>+?vvvvvvvvvvvvvv`<br></br>
.>>>>>>+>>>>--``~~-|??>>?>>>???????????????????>++>>~||^+^^???????????????`<br></br>
.>>>>>>?>>??^>|-~~--????????????????????????????????>+++++>???????????????`<br></br>
.????????????>>^-~~->>>>???????????>>>>>>>>>>>>>>>>>>>??>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>^~~^?>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 .................................|v}rv+.................................. <br></br>
                  ................................-rwwwzz~``............................... <br></br>
 ............................```..?}>>v?~^^^|`............................ <br></br>
                  ..........`^???^`..........^+++^||~~~~|+++++|^````.``~|`..`|^^++^`....... <br></br>
 ..........|??vrv~.........~^++++++^>^|^++^~--?>-...^vvv^.`^>}?>vv>?|`.....<br></br>
 .........``~>?v>........~^|~^^^|^+^>>|^+^-```^?>`..`~+>+`|+>>+|>>>vwr~....<br></br>
 .......````>vvw~.......^???+~```-||~-~|~```````|?|~?vvv?^+>>++|^+>+?v}v?+.<br></br>
 ``````..`+}v>?v+`.`|>|.`^|~---`-----`~~~~^~``-+>?>??>>+++>>>>>>>>>>>>>??v~<br></br>
 ``````-|vzzrvww}v>?rr}?+------~|^-`~~`|v}}v>^vrw?+?++^^^^+>>>>>>>>>>>>>>v-<br></br>
 ``````?wzwwzzrvv?rw}??}}v+|^+?vr}` `` `?}vvrvvvv?^^^^+|^>>>>>>>>>>>+++>>?~<br></br>
 `````-vw}??vrv???vwv?>>}zrvv?vwww`    .>?vvrw}}vv?>?v>|>>>>>>>>>>??>??>+>`<br></br>
.~~--+v?>>?vvv?????r}}rrrwwwwrwwwr. ~+  >vw?>?v}r}}}r?|>>>>>>>>>>?v?wzzwv+.<br></br>
.>?vvv}vvrw}v?????>}}rwwwwwwwwzwz}. ??  ?wwr+vrrwwwww+^>>>>>>>>>?x?}wrwwwr-<br></br>
.?rwwrvrrrr}???????v}v}v}rv}}>?vvv. ??  +v?|+?}?>?vrv|>>>>>>>>>>vz^>?>??vw~<br></br>
.>v}ww??v}vv????????v???}??}w?`vww- ^?  ?zv`~??>+|+v>^>>>>>>>>>>rv+?}vv}rw~<br></br>
.>?rwr?vvv??????????????vrrrr?-vw}> ->  v}>?|??vv^+?|^>>>>>>>>>>r??vvr}}}r~<br></br>
`}rrvvvvv????????v}}}???v}?>??v}ww}`-v .}>~>`vzw~-`-|+>>>>>>>>>>r?+^^?+^?}-<br></br>
`}r}vvvwxxv?????wyyyw????+^^^^vuu}w++> .+|||~vzr^||~|+>>>>>>^^+>r?~|+^~|^^.<br></br>
.?v}vv?ryywv???rwxzr?????>??>++}v>>^^~ .||||+?v>||~||+>>>>>+|+>>}v|^^~||||.<br></br>
.>???v??rw}v????????????>>?vvv??>^~-`` `||~~~~|~~~~||^>>>+^^|+>+v}^||~||||.<br></br>
.????vv???????vrwr}?????++?>>?????v?+. +v???????????>++>>>v>|^>+?r+?vvvvvv-<br></br>
`vv?>>v???}xuuyuuxxr???>^+??v}}}}}rv?. ?}}}}}}}}}}}}}vvvvvrv|^>>>v+v}rrrrr~<br></br>
`vvv??????}uuuuuuxzr??>^+?vvvvvvvv}v+  ?vvvvvvvvvvvvv???vvv?^^+>>z>v}}}}}}~<br></br>
.?????v????v}v???????>++>>??????vvvv^ `>vvv?????vvvvv??????v>|+>>}>?vvvvvv-<br></br>
.??????>+???>>+>++>?>???????????????^-~>???>>>???????+++++>?+|^>>>+>??????`<br></br>
.????????????-`~^+v>>???????????????????????????????>^^^++>?>|^>>>++??????`<br></br>
.???????????|--`|>w>?????????????????????????????????>>>>>>?>~|||||^??????`<br></br>
.>??????????|~---^v>??>>????????????????>>>???>?????????>>>>>+^++++>>>??>?`<br></br>
.>>>>>>>>>>?>|--~-~+>>>>>>>>>>????>>>>>>??>>>??????????????????????>>>??>>`<br></br>
.>>>>>>>>>>>>>+----|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 .................................~?}}v^`................................. <br></br>
                  ................................`rwwwzx~................................. <br></br>
 ............................```..?}>>v?|^^^|`............................ <br></br>
 ..........`>v}}>`..........^+++^||~~-~|+++++|^````.`-|^`..`|^^^^^`....... <br></br>
 ..........|??vww~.........~^++++++^>^|^++^~-~?>`...^vvv^.`^>}?+vv+>~`.....<br></br>
 ...........`+>?^........~+|~|^^^^+^>?|^+^-```+??`...~^>^`|+>>>|>>+vzr~....<br></br>
 ...........^?vw~.......^???^````-||~-~|~```````|?|~?vvv>^+>>++||+>+?v}}?+.<br></br>
 `````...`^}?^?v>-.`^>^.`^|~-`-------`~~~~^~``~>?>>??>++^^>>>>>??>>>++>??}~<br></br>
 ``````-^vzzrvrw}v>vwzw?^``----~~|-`~~`|v}}v>^}zz?+v+^^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzzwzzrvv?wzrv?v}?+||+?v}r- `-.~w}vvrv?vwv|||^+|^>>>>>>>>>>>+^+>>v~<br></br>
 `````~vrv>>vrv???vz}?^+rzrvv?vwww`    .}v?vrrvrwwv?vr>|>>>>>>>>>>??+>?+^+`<br></br>
.~~~->}?^+?vvv?????wwrrwwwwwwrwwwr. ~+  vwrv>>v}wwzzzv|>>>>>>>>>>?v?wzzwv^.<br></br>
.>?}vrw?}wzrv?????>}zwzwwwwwwwzwz}  ??  ?www+vrrwzwww>^>>>>>>>>>?u?}wrwwwr-<br></br>
.>rzzw}rww}v??????>vw}}v}wv}}>?v?v. v?  ^v?~^?}?+?}w}|>>>>>>>>>>}z|++^>?vw~<br></br>
.+?}ww??v}vv????????v???r??rw?`vwr` >?  ?zv`->>+^^+v>^>>>>>>>>>>zv+?r}}www~<br></br>
.^+}zz??vv??????????????rrrww?-vwv+ ~?  v}>?->?v?+^?|^>>>>>>>>>>zv?wrwzwrw~<br></br>
`vr}vvvv?????????v}}}???v}v>??}rwwr-|v .}>-^.?zw~``-|+>>>>>>>>>>zv>+^+>>?r~<br></br>
`rw}vvvwxzv?????wyyyw????+|~|^vyy}z??> .^|||~vzr|~|||+>>>>>>^^+>zv-~||~|+^.<br></br>
.?vw}vvryywv???rwzw}????>>?>>^^v?+>??~ .||||+?v>~~|||+>>>>>^|+>>w}~|||||~|.<br></br>
.>?v?v??rwrv????????????>>vvvv??+~~~|` `||~~~~|~~~~||+>>>+|~|+>>}w||||||||.<br></br>
.????v????????vrwr}?????++?>>?????vvv` +v??????????>>>>>???+|^>>?z+vvvvvvv-<br></br>
`vv???v???vzxuyuuxzw???>^+??v}}}}}}r}. ?rrrrrrrrrrrrr}}}rrrv|^>>>v+v}rrrrr~<br></br>
`vvvv?v???vuuuuuuuzw??>+>?vvvvvvvvv}?  ?}vvvvv}}vvvvvvvvvv}v^|+>>z>?}}}}}}-<br></br>
.???>?v???v}}v???v???>+^+>?????vvvvv+ .>vv??????vvvvvvvvvvvv+|+>>v>?vvvvvv-<br></br>
.???????>??????>>>>??????>>?????????+-~>???>>>>?????????????+|+>>>+>??????`<br></br>
.????????????~-^>>w?>???????????????????????????????????????>|^>>>>^??????`<br></br>
.?????????>?|---^?x?????????????????????????????????????????>||^^^|^??????`<br></br>
.???????????||~~-^r???>>????????????????????????????????>>>>>++++++>>???>?`<br></br>
.>>>>>>>>>>?>|~~~~|+>>>>>>>>>>???>>>>>>>>>>>>>??????????????????????>????>`<br></br>
.>>>>>>>>>>>>>+--~-|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .................................`^???^`................................. <br></br>
                  ................................`+wwwzzv`................................ <br></br>
 ............~^^`........`````--~-|}}?v}?~--`............................. <br></br>
 ..........`??vr+.......-^+|~+++++^|^~-||^^^^-`````.`|>>`...``--``........ <br></br>
 ..........`~~?v>......~+``--^+++++|^|~~+++++^~``.`.`>>>-..-^>>>>>+~~`...`.<br></br>
 ...........~+~}v~....|^.....`-~|^+^+?^^++^^^^~--``.`|++~``|>>v>+???ww~..^-<br></br>
 ..........``~->>~....-`.`---````-||~~~^|~~|||--||`-vrrrv+|+>++|~+>^>v}}??`<br></br>
 `````...`+vv???>~`|>?-.``-----------`~~~-~-`-^>??|?>>?>^^+>>>+|^^>+^+?v}w~<br></br>
 ``````^?vzzwwwwr}??vv?|-`-----~~|~``-`-^?v?>?vv?>^>>>>++>>>>>>>>>>>>>>>?v-<br></br>
 ``````?zwwwww}vv}w}v?>>>+|||^>?v}^ .`..>zwrrv?v?}?>>++++>>>>>>>>>>>>>>>>>`<br></br>
 `````+vv???}}v??vrw}vv}ww}vv?vwwz^     +w}vvv}?+?>^^^||+>>>>>>>>>v?^^+>>>`<br></br>
.~~||+????}rvv????}wwwwwwwwwwwwwww- .-  |zw}??}v>^+>??^^>>>>>>>>>vv>v??>>>.<br></br>
.>>}rwwrwww}v?????vrrwwwwwwzwwwwrv. `v  -rw}?}}}rrrwz?^>>>>>>>>>?u??}}rrv+.<br></br>
.>?rwwwwww}v??????>>?v}vrw}v}>v?>^. |}. `?||>>>??vvrr++>>>>>>>>>}x+????vw}`<br></br>
.++>?vv}r}}v????????v???rv^vw>-vw}. >r` .w>~?}vv?-+}v|+>>>>>>>>>x}>?}wrwrr~<br></br>
.>>?r??vvv?????????????vrr}rw?~vzr. >}~ .?^?|vr}?-~v>^>>>>>>>>>?xv???rwwrr~<br></br>
`rwww??v?????????v}r}??vv??v}}rwwz| >w| .``v>?zw|^-++^>>>>>>>>>?xv^+>??vrw~<br></br>
`rw}v?v}r}v?????zyyyr??v>?vvvvxuwwv`??` .`~>?rz}^>~~^^>>>>>>++>>xv~~~|^>??.<br></br>
`v}}}vvxyywv???wwzw}????>?vvvvvr}?>^?~. .||+?vv?>~~~~|+>>>>>?>+>w}~||||~|~.<br></br>
.+>v?v?vzxrv????????????>???????>||||-  `|~~~||~|~~~~|^^+>>>vv>>??^|||||||.<br></br>
.>???v????????v}r}}?????++>>??????vv?~ .?v????????>~||||^+>>>?>>>?+?vvvvvv-<br></br>
.???>v????vzxuyuuxzr???>^>?vv}}vv}r}}- ~r}}rrrrrrr}>+^^||^++^^>>>?>vrrrrrr~<br></br>
`?????v???}uuyyuuyxzv??^>???vvvvvvv}?  ^vv?vvvvvvvv}vvvv???||+>>?z+?vvvvvv`<br></br>
`?????????vwzrvvvvv??>>>>>???vvvvvvv+..^??????????????????+~^>>?}v+???????`<br></br>
.??????>v}????????>>?>??????????????>^+???????????????????|~^+>vv+>???????`<br></br>
.????????rrv^-~^++>rv>????????????????????????????????????~~|^+>+^+???????`<br></br>
.????????>^?~`-~^>>vw?>???????????????????????????????????+^^|~~~||>??????`<br></br>
.?>>>>>>>^~---|~~|^^v?-^>>???????????????????????????????>???>>>>>>>??????`<br></br>
.?>>>>>>++^-|^|~~-+~`-`~>>>>>>???>>>>>>>>>>>>>????????>?????????>>??>????>`<br></br>
.>>>>>>>>|-^>>>||~+---~^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ...................................   ................................... <br></br>
                  ..................................`+??^`................................. <br></br>
 ...............................`.-rzzzz}`................................ <br></br>
 ...........`^??`........`~```-~|||}rvvrv`................................ <br></br>
 ..........`vv}z>.......|?v>~+++++^|+~~^~~|^|~`````.`^??-................. <br></br>
 ...........-->v^......>?|-~`~^++++^^||~++++++|..``.`^+^-`..~+>++>+`~`...~`<br></br>
 ...........|+-}}+....>?......`-~^+^+?+^++++++^-..`.-^+^-`.~+>r?+v??ww~..?~<br></br>
 ..........~```|?>`...|~...``````-||~||^^~~^^^~~^-.`}zzzw?~^>>>^~>>^>}rr??-<br></br>
 ````.....>vv???>|`+??-..````-`--`---`~~----`~>>??|?>?v?+^+>>+>^^|>^+?rzzz~<br></br>
 ``````+v}zzzzzwwrv??v+``-------~~^-----|?v?>?v?>>+>>>>>>>>>>>>>?>>>>>??v}-<br></br>
 ``````?zwrrww}?vzw}??+||~~||^>?v}?..~-.>zzwr??v>wv>>++>>>>>>>>>>>>>>>>>>>`<br></br>
 ````->}?+??}}v??rzw}v}wwr}v??vrwz?     ^zrvvv}>^??^^^||^>>>>>>>>>v?^^+>>>`<br></br>
.~~^^+?>?vwr}v????zwzzzwwwwwwwwwwz|  -  ~zwr??rv^||+>?>|>>>>>>>>>vv>??>++>`<br></br>
.>+rzwwwzzw}v????>}wrwwwwwwzwwwwrv` .r. `}wvvrvv}rrwz}|+>>>>>>>>?y?+v}rr?^.<br></br>
.+>rwzwwww}v??????+++?vvrw}v}?}?^-  ~z- `?-^?>^>?}v}z>|>>>>>>>>>wx>?v??}z}`<br></br>
.+~^>vrww}}v??????>?v?>?rv|vw+~vwv. >x^ .r|^rr}}?-+r}|+>>>>>>>>>x}>?rzzwr}~<br></br>
.>>>}??vvvv???????????>}r}vrw?~vzr. ?x? .?|>^vw}?`~}?|>>>>>>>>>?uv??>rzwrr~<br></br>
`rwwz??v?????????vrr}??vv>>}rrwzwz~ ?x?  .`}v?wr|>~??|>>>>>>>>>?uv^>???wzz~<br></br>
`ww}v?vv}v????>vxyyy}???+?wwr}uurz?.vv^  `-?vwwv^v|^+|+>>>>>++>?uv~~~|+?v?`<br></br>
`v}v}vvxyyr????rwzwv????>vvvvv}rvv+|?|` .~^>?}}v?||||~|+>>>>v>^>wv~||||~|~.<br></br>
.++vv}?vxur}????????????>?????>>+||^||. `|~~~||~|~||~|~|^>>>zw+>>>+|||~~||.<br></br>
.+??v}????????v}}}}?????++>>??????vvv+  ?v????>????-~~~||+>>??>>>>+?vvv??v-<br></br>
.??>>v????vrxuyuuzzr???>+>vvv}vvvvr}r| -rrrrrrrrrr}>^^^|||^^^+>>>>>vrrrrrr~<br></br>
.???>?v???vuuuyyyyuzv??^+????vv?vvv}?. |v?????vvvvv}vvv????||+>>?z+???????`<br></br>
`vvvv?????vzxzrvv}v??>>>>>>??vvvvvvv>..^?????>????????????^~^>>?xv>???????`<br></br>
.??????>rzv???>>??>>?+???????????????>>?????????????????v?^|+>>w}>vvvvvvvv`<br></br>
.???>???vwzv^-~++++w}+>???????????????????????????????????||^++>+>????????`<br></br>
.????????+^?~.|^+++vz?>???????????????????????????????????+^^^||^>????????`<br></br>
.?>>>>>>>||~-~>>+^~+v?-|>>???????????????????????????????>??>>>>??????????`<br></br>
.?>>>>>>+++-|>>>>^>|-~-->>>>>>???>>>>>>>>>>>>>???????????????>>>?????????>`<br></br>
.>>>>>>>>|-^>>>>>|?|~-~^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  .....................................`................................... <br></br>
                  ..................................`?vv??-................................ <br></br>
                  .............`...............`````vzwwzw+................................ <br></br>
                  ...........^?vv`........```.-~|^^|?v??vv-````............................ <br></br>
 ..........`>>vr+.......`~|||||++++|~~~|~|^^^^~````.`>?>-...`~|~~|-....... <br></br>
 ...........`~~^~......-|`~+``-||^+^|^^|^+++++^````.`~~~`..-^>?>+?>-~`...|`<br></br>
 ...........`-.>v?`...-~..``...``~+^|>^|+^^+^^~```..->?+-``|>>?+^?>?zr-.`v~<br></br>
 ..........|-~-|^|`...``....``````~~~-~|^~-~~~+^+|--v}}v?>|+>++|~^>^?r}vvv~<br></br>
 ````...-->vr}}v>||??^`..```--`--`-~-`-~~---~^>?vv??>>>>>>>>>>+^^^>+^>vv}w~<br></br>
 ``````>}rzzzwrvww}vvv?``-------~~|-.`-``>??>vv?+++>>>>>>>>>>>>>>>>>>>>>>?-<br></br>
 `````~vw}v}w}v?vwv??>+|~~~~|^>?v}|  .`..vwwv++++v?>++++>>>>>>>>>>>>++>>>>`<br></br>
 `````+?>>?vvv???}z}vvv}vvvvvvv}z}.     .}rv?>??>v?>>>||+>>>>>>>>?v>+^++++.<br></br>
.~~|+>>>vrwrv?????rrzzzzwwr}wwwrr?.  `. .rwrvvwr?>>??v^^>>>>>>>>>v?>vv?>>>`<br></br>
.>>}zwwwzwrv?????>?v}r}rrvrvrrww}v`  ?- `rwv?v}v}rwwz?^>>>>>>>>>vy?>v}wwv?`<br></br>
.++?vvvrwr}v??????>????}rrrv?+v?>?~  ?| `v>+>v?v?vvwr^+>>>>>>>>>wx>vv}}rw}-<br></br>
.>+|+?v}rvvv????????????}v?rw>^vrv~.-v| .}?|vwrwv|+??|^>>>>>>>>>x}>?v}}r}}-<br></br>
.???v??vv????????vv}}??vvv}rrvvzzw|`~}| `v+??}w}?-|v+|+>>>??>>>?yv>vvwrwwr`<br></br>
`vwww??vvvv?????vwzxr??v???v}rzxrr>~?z~ .`^r>?zw+?|?+|+>>>??>>>?u?^??v}wz}`<br></br>
`rw}vvv}zzr????vwzzwv???>?v???vvvrv>}?. .-+v?rzv|}??>|^>>>>>>>>>?>-~^>??v?`<br></br>
`vvv?vv}zuwv???vv}vv???????????vrv???~. `+>>?}}v^^^?^|^+>>>+>>+>?>-~|||~~|.<br></br>
.+>?v}??v}vv???vv}v?????>>??>>|>>||^|~  `|~~~||~~~||~~|^+>>+??>>>+~|^|||||.<br></br>
.^>?vvv????vvrzzwzr}v???>+>+>>>????vv+ .???????????|||||^+++>>>>>>>vvvvvvv-<br></br>
.???>?v????ruuyyuuxw???>^>???vvvv}}rr^ ~vvvvv}}}}}}?>>^^||^^++>>>>>v}}}}}r~<br></br>
`??????>??vwxxzr}}}}??>^~>?vvvvvvvv}v. |vv????vvvvvvvvv??+||+>>>?r>?v??vvv-<br></br>
`vvvv?+|^?v}rvv??????>??--+?vvvvvvvv?.`+????????????????>~~^+>>>wv????????`<br></br>
.?????+-+>?v??>>>>>>>??v|++??????????^^>????????????????|~^++?>}v?vvvvvvvv`<br></br>
.???>>>~|>>?||^++++??>>?>>^?????????????????????????????~~^^+>++^>????????`<br></br>
.??????^--|+~`+>>>>?v>??????????????????????????????????+^^^^^^^+>????????`<br></br>
.??>>>??^~~~`|?>>>^+?>~+>??????????>>>>>>>>>>>>>>>>>>>>>?????>>>?>>>>>>>>>`<br></br>
.>>>>>>>>+|-^>>>>++-`--|>>>>>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?>`<br></br>
.>>>>>>>>^~^>>>>>^>~-~|+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ....................................   .................................. <br></br>
                  ....................................`--.................................. <br></br>
 ..................................-vwww}|................................ <br></br>
 ............`-`..............``--`>zwrwzv................................ <br></br>
 ...........?}w}-............|^^++^|??^>?~`````...............``.......... <br></br>
 ...........|^?v|..........`>>-^++++~|~~~^^++++-```.`??+~..`|>>++?^....... <br></br>
 ...........`|`.``........|?-..`-|++^>+^^+>>^++~```..```...~+>r?+v>~~`..`+`<br></br>
 ..........`.. ~v?~.......--.....`^^~>||+^>v+^|~~`..-?v>-``+>>>^|>+?z}`.|r~<br></br>
 ..........+|^|~--..........`````.`---~||-`-^>???+~~?vv?+^+>>++^^^>^vwv?v}~<br></br>
 ````..`|^>vzzz}>|?v?`....``--``---~-``-~~-`+?>v}rv??>>>>>>>>>>>>>>>+>vvvw~<br></br>
 ``````>rzzzzwv?rw}}r}?-`--------~~-.~~`.+??>vv?>>+>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````^v}v?vrv???}??>>>|-~|~|+>??v-   `. >vwv++^+???>+^+>>>>>>>>>>>>||>>+>.<br></br>
 `````|^+??vv????}zvvvvv????}}?vzv       vrv?+?}wzzzww^^>>>>>>>>>?r+??+^||.<br></br>
.--~+?+>}zw}??????v}zzzzwrvvrwr}v>   |~  }zw}rzzzwwwz}|>>>>>>>>>>v??wzwrv?`<br></br>
.>>vzwwwzw}v?????>|+vv?v}?v?}rwrvv^  >+ .wzv??wvvwzwz?^>>>>>>>>>vy???}wwzz~<br></br>
.>++?++}w}}v???????????}}}r?>^??vwr. >^ `}v?+rwr>?vzv^>>>>>>>>>>wx?r}wwwrr~<br></br>
.?>|^??v}vv????????????vvvvwr?>v}}w?.?~ `}}~>zzzv^^++^^+>>>>>>>>x}+??vv}v}-<br></br>
.v}v???v????????vrzzzv?v??rw}ruywwz?.v- >r>?v}w}?|^?||^>??wr>>>?y?+vwz}wz}`<br></br>
.?wzw??v}}v?????wyyyw???>?vvv}xx}}wrvw` >+?w+>zw>}+?^|^>>>vw>>>?x?^vvrzzwv.<br></br>
`rrv}vv}yyuv???}rr}v?????+???>>>?vwzw?. |+?}>rz}|rr}?|^+>>>>>>>>?>-~>vv???`<br></br>
`vv}>???rxw}v?????v??????^++^^|?r}}r?~  ~>?>>rvv~^>v+|^>>>+^>>>>>+`~|||~~|.<br></br>
.+>>?}v???????v}zxz}?????^||||`??||^^~ .||~~|||~~~|^~|^>>>+^>>>>>^-^^|||||.<br></br>
.||>v?v????}xuyyuuwx}???>+>+^++????vv> ~v????v??????^||^^^^^>>>>>>v}}vvvv}-<br></br>
.>>?>+vv???}uyyyuuuz}??>^>>???vvv}}rr+ |?vv?vvv}}}}}v?>>>^|^>>>>+v}}}}}}}r~<br></br>
`?????++???vww}v??v???++~>v}}}vvvvv}v. |vvvvvvvvvvvvvvvvv^|^>>>>?vvvvvvvv}-<br></br>
`vvvv?-~~>?????????>>+vr|-^?vvvvvvvv?.`>vvvvvvvvvvvvvvvv?~|+>>>>vvvvvvvvvv-<br></br>
.????+~~~-^w>+++++++>??}~+|??????????~~>????????????????||+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~->^+>++++++>>>+^->???????????????????>>+>>>??>~^++>v>???????????`<br></br>
.>>>>>?|-~~~+?????>>+^???>>?????????????????????????????+^^^^^+???????????`<br></br>
.???????|-~-~???????>>?????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>+||+>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................... ................................... <br></br>
                  ....................................```.................................. <br></br>
                  ............`-....................~vrrr?-................................ <br></br>
 ..........``+?|.`............`````vzwwwz>................................ <br></br>
                  ...........|+?+`............`|^++|+v?+??-`````........................... <br></br>
 ...........``~|`...........^||++++|-~~~~^^+++^-```.`~|-`..`|++++>~....... <br></br>
 ............`............->+-`-~^++|++^^+++++^-```..-~-..`|+?v>?}+^|`...-.<br></br>
 ..........`..`~^~`.......^+`...`~+^|>^|+^|^|||||-..~}rv^`~+>>+|^>+vzr`.`^.<br></br>
 ........`|?????+.......`|-`````.`-~~--||~```~>>?>|~>vv??^+>>+^|^^>>rw}vv}~<br></br>
 ````..`+?vrzzzr?-|^|-`.-~``--``-`-~-``-~---|^>vr}???>>>>>>>>>>>?>++>?vv}w~<br></br>
 ``````|}zzzww}vrv??vr}+-`-------|^~.~~``>?>^?}?>>>>>>>>>>>>>>>>>>++^>?>>?-<br></br>
 `````~?v?v}wrvvv?vvvvv?^~~~~+???}+. .` .v}rv+++^+++++++>>>>>>>>>>>+||+++>`<br></br>
 `````|^>?}}}vv??vrr}}r}vvvvvr}v}?.      vw}}?>>?rrv>>^+>>>>>>>>>?}+++^^|^.<br></br>
.---|^|>rzwrv??????rzzzzwwr}wwwr}?.  ||  }zwrwwwzzwzwv|+>>>>>>>>>v?>rwr}vv-<br></br>
.>>?vvrwzwrv??????+?rv?v}vrvrrwwrr>  >?  }z}?vwrrwzwz?|>>>>>>>>>?u??}rwwzz~<br></br>
.vvv}v?rwr}v???????vv??vvr}v?+?v?}}  >> .vv>+vwv>vvzv|+>>>>>>>>>vx>}rwww}v`<br></br>
`vv??v?vvvvv??????????vv}vvwwv>}}r}. ?^ `r}-^vvv?^+>^^+>>>>>>>>>wr^?}v???v-<br></br>
`rrr}v?vv????????vv}}v??vvrwwrvwwz}` ?| ?w>??vvv?^|?|^+>>???>>>>zv^?vv??v?`<br></br>
`}wwrvvvvvv?????vwzzr????vvvvvwwrrr~.?- ?>^v^?zw>?~^^^+>>>??>>>>zv|>??vv?>.<br></br>
`}}vvvvruuw????vrxzwv????+>+++?}}}w^`^..|^>?^}zr^??>+^+>>>>>>>>>?>-~^>?>>+.<br></br>
`?>?>??vzuwv???vv}}v?????+>>+^^?v?v+.`..|+++>vv?~^+?^^+>>>??>>>??^-~|||~||.<br></br>
.>??vv??v}v????vv}v??????>????+?>||-.`.`||~~||~~~~||~|+>>>>?>>>>+|~^^|||||.<br></br>
.>>??vv????v}rwzzzr}v???>>?>>>??????`..^v???????????+|^+++>?>>>>>?v}}vvvvv-<br></br>
`????>v???vwuuyuuuxzv??>^>>??v}}}}rv-..>vvvvv}}}}}}}}+|++++>>>>>>v}}}}}}}r~<br></br>
`v????>>??vwxxzrrr}v??>^|?vvvvvvvv}v` `>vvvvvvvvvvvvv>~^++++>>>>?vvvvvvvv}-<br></br>
`?????+~^?vvvv??????>>>?-`>?vvvvvvv?|.~?vvvv??vvvvvvv?|^+++>>++?vvvvvvvvvv-<br></br>
.????>^--|+}?>>>>>+>??+>|^^?????????+~|>??????????????||^+>>>?????????????`<br></br>
.?????~-~^|v^>??>+>?>^|~>>^?????????????????????>>>??>~~^++>+?+???????????`<br></br>
.????>~--~^?>???>???|++^??>???????????????????????????|~~|~|^^>???????????`<br></br>
.?????^-~~|>+???????>~^>???????????>>>>>>>>>>>>>>>>>>>>++++>???>>>>>>>>>>>`<br></br>
.>>>>?>~----+>>>>>>>>>>?>>>??????>>>>>>>>>>>>>>>>>>>>>??????>>>>>>>>>>>>?>`<br></br>
.>>>>>>>^~--+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ............^+`...................~v}}v^................................. <br></br>
 .........`~~}w?.`.............``.`rwwwzx-................................ <br></br>
 ............`-`..............~|^^~?}?>v?```-`............................ <br></br>
                  ...........................|~+++++|~~~~~^^+++^````........`|^^^++`....... <br></br>
 .........................`+v+-|^+++^^^|++++++^-``...~+^..`^>}?+v}^?+`.... <br></br>
 ..........```~~..........?v|`..`^+^|?>^++^^^||^+~`.^zzx?`|+>>>|+>+rzr-`.. <br></br>
 ........~vwrrwzv.......~?+`````.`~|~~~|^~````|>??+~+vvvv^+>>+^|^+>?zzwr}}-<br></br>
 ````..`?wzwwwwz?`..`~-.^+````````-~-``-----`->rwv?vv>>>>>>>>>>>?++^?}}rzz~<br></br>
 ```````vzwwwwww}?|+vww?-.`---`--|>|`~~`-??>|?}?>>>>>>>>>>>>>>>>>+^^|?v??r~<br></br>
 ``````^??}wwwrvv?}zzwwz?----+vv?rz^ `- -zr}v>>+^^^^+>>>>>>>>>>>>>++^|^++>`<br></br>
 `````~^>vwwwrv???vzwwwwrr}vvwwv???.    .rwwzv+^>}}?^|+>>>>>>>>>>?v+^^^||+`<br></br>
.--```->wzww}v?????wwwzzzwzzwwwr}}v  ^^  }zwzwwrwwwzr?|^>>>>>>>>>}v>rrr}vr~<br></br>
.>??>?vwzww}v?????>}zv?v}wzzwwwzzzv  >v  vzz?}wwwwzwz?~+>>>>>>>>?uvvzwwzww~<br></br>
.rzzzz}rwwrv???????vwv>?vwvvr>}v?vv  >}  ??>+vw??v}wv|+>>>>>>>>>?u>vwwwrv>`<br></br>
`wwwww?vvvvv????????vv??rv?rwv+rwrv  ?v `w}`->>+^+>>^^>>>>>>>>>>vz+vw?^^^?`<br></br>
`wwzwz??vv??????????????rrrww}^vwzv  ?? ?w>?>?>vv^~>|+>>>>>>>>>>rr~^^|~||+`<br></br>
`ww}vvvv?????????v}}}???v}??v?vrwwr. ?^.}>`+^vzw>+.`|+>>>>>>>>>>z}~||||||^`<br></br>
.vvvvvvwxzv?????wyyyw????+|~^^vyyrw- ~~~+|||~vzw++~~|+>>>>??+>>>v?~|||||||.<br></br>
`>|^>v?ryywv???rwzw}????>>??>^+v?+?- `^|||||+?v>|||||^>>>>vr+>>vv|||||||||.<br></br>
.>?vvv??rzrv????????????>>vvvv?>^~|` `||||~~~~~~~~~|~|>>>>?x++++|~||^|||||.<br></br>
.????v????????vrwr}?????+>?>>?????v> .>?v????????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv???v???vxxuyuuxxw???>^+??v}}rrrrv .vr}}rrrrrrrrrrr+^>>>?}>}rrrrr}}rrrrr~<br></br>
`vvvv?????vuuuuuuuzr??>+>?vvvvvvvv}?  ?}vvv}}}}}}vv}}>|+>>>>>v}}}}}}}}}}}}-<br></br>
.???>?????v}}v???v???>^-++?????vvvv?. ^vvvv?????vvvvv?|^>>>>>vvvvvvvvvvvvv-<br></br>
.???????>??v?>???>>?}?|-????????????~-^????>>>>??????>||+>>>+>>>>>>>??>???`<br></br>
.?????~^???z+>???+?v?+^-?????????????????????????????>||^+>>^??????>>>>>??`<br></br>
.????>-~~^?w????>???|?^|?????????????????????????????>||||||+?????????????`<br></br>
.????>~~~-+v???>????>-|>????????????????????????????>>>++++>>???????????>?`<br></br>
.>>>>?>~~~~~+>>>>>>>>>>?>>>??????>>>>>>>>>>>>?>>>>????????????>>????????>>`<br></br>
.>>>>>?>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 ............`-....................|?v?>~................................. <br></br>
                  ..........``++|.`.............``.^wzwzzv`................................ <br></br>
 .............`...............`---+vv?v}>~~~-`..``........................ <br></br>
                  ...........................`-^+^^|~~~|~|+++++~~^^-........`~~||^|`....... <br></br>
 ..........................-^^^^+++||^||++++^^~-`-^~`^>+..`|>}?>}v+v>`.... <br></br>
 .........~+|+>>^.........~|-~~||++^|?>^++||~-`-~``~>rzz?|~+>?+|>>>wzr~... <br></br>
 .......->rzwwzz?.......`^^``~~~--||~~~|^~````-|^+||??v}}>^>>+|-|+>vzzw}}?`<br></br>
 ````...+}zwwwwz?....`-`~>^||-````-~~`------`-^?v}?vv?>>+>>>>+>>>+++??vvvr~<br></br>
 ```````vwwwwwwwr?^^^vrv^|~~~-`--|+~`--`|v?+~^??>>>>>>>>>>>>>>>>>>+++>>>>?`<br></br>
 ``````^vvrwwwwvv?vv}wwzv^---^???}v..`. ^zr}v>++++++>>>>>>>>>>>>>>>++^+++>`<br></br>
 `````-+vrwwww}v??vvvzzwww}vv}w}v}>     -wwwz?^+>>>>+++>>>>>>>>>>?v++>>+^^.<br></br>
.----`-vwwwwrvv????vvr}rrwzzzwwwrw>  -. .rzww>>>???v}?~^>>>>>>>>>rv?wwrr}r~<br></br>
.>>>++?wwwrvvv?????v?v??vrzzwwwwww^  ?^  vzr?>>>?wvwz>|+>>>>>>>>>uv}zwww}v-<br></br>
.rzvrwrvvrv?v????????}v?vr}vr?}?vv|  ??  ?>^^>>^^+>rv|+>>>>>>>>>?u?rwwwwv?`<br></br>
`wwwwwv?vvvv????????????vv>}w?^rww~  v? .rv~>v?>>~>?+^>>>>>>>>>>vz>}w?++^?-<br></br>
`www}rvvv????????????????rrwr}|vzw- .v? `v+?+?vr}^|?|+>>>>>>>>>>}}~^^|~||+`<br></br>
`rwr??vvvv???????vvv}????}v???v}wr~  >> +>`>|?zw+^``|+>>>>>>>>>>??~||||||^`<br></br>
`v??vvvzuu}?????ryyyz????>|~||vyy}~ .+|`^|-|+}zw?^~-|>>>>>>>>>>>?>~|||||||.<br></br>
.>>??v?}yyz}???rwxzr????>>v??++vv+` .||~||||+?v?^||~|+>>>>?}++>?>>?|||||||.<br></br>
.????v??}r}v>???????????>>???v??^~-  `|||||~~~~~~~|~~+>>>>?z+^>>+^??~|||||.<br></br>
`v?????????v?v}wwr}?????++>>>??????` `>?v???????????+|>>>>?x?>+>>>?w?vvvvv-<br></br>
`vvv??v???rxuuyuuxxr???>^+??v}}}v}r+ .}r}}}rrrrrrrrrv|^>>>>}?+>>>>vrvrrrrr~<br></br>
`vvvvv????wuuuxxxxw}??>+>?vvvvvvvv}>  ?}vvvvvvv}}vv}}>|+>>>>+?????vvvvvvvv-<br></br>
`?????????vvv???vv??>>^+???vvvvvvvv>`.^v?????????v?v?^|^>>>>+???vvv???????`<br></br>
.???????????v???vv?>??|~????????????^|+?????????????|~^+>>>++?????????????`<br></br>
.?????^+???wv???>>?>>>^~???????????????????????????>~~|^+++^+?????????????`<br></br>
.?????~~|+?zv???>>+~^>^^????????????????????????????>+|~~~~~>?????????????`<br></br>
.>>>>>|---^?vv?||?+~>~|>>?????????????????????????????>++++>????????????>?`<br></br>
.>>>>>>~--`~~~-~|~-+>>>>>????????>>>>>>>>>>>>????????????????>>>?>>?????>>`<br></br>
.>>>>>>>|~--^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ................................... . ................................... <br></br>
 ..................................|??>-.................................. <br></br>
                  ...............................`.?zzzzz|................................. <br></br>
                  .................................?wv?rr^~^|-`.`-`........................ <br></br>
 ............................-|||-~+|~^|^+++++~>v?^........``--~|-.`...... <br></br>
 ........... ...............~++++++~^|||++++||-|~|?>->?>...|?v>>}?>r?`.... <br></br>
 ........`+v?vv}?..........`^++++++^+?+^++~~`.....`>?vwz?>~+>v+^?>?zwr~... <br></br>
 .......|}zzzzzz?........`^~~^^^|~^^|^|^^-```````--+v?rwwv|>>+|-^>>vzzw}}>`<br></br>
 .......^vwwwwwz+.....```??>>-````-~-`-~-`-`````+ww}vv?>++>>>+>>+>>>???vv}~<br></br>
 ```````?wwwwwwzw?^|`>rr>?^||-``-^^--`--?v?^~`|vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwww??vv??zwwr>---~??>v?.-|..}wr}v?+>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 `````->rwwwwwrv?????zzzzwrv?vzzwz>  .  >zrww>^>>>>>>>++>>>>>>>>>?v>?vv?>|.<br></br>
.----`~}zwzwrrv?????>}?v}wzzzzwwwz^  -  -zwzw|+>>?>?}?~^>>>>>>>>>wv}zzzzzr-<br></br>
.+>^|~>wrw}>?v??????+v?+?}wzwwwwrr~ .r` .vwv?+^>?w?ww^|>>>>>>>>>>xvwwwwwv?-<br></br>
.rw?vrw?vv>?}v??????+rrvvvrvr?}?}?` `z^ .v^-|^~|^^|}v|+>>>>>>>>>?xvzwwww}?`<br></br>
`wzwwzz??vvv?????????????v|vw?^rzw` ~x?  }?+v}??v->v+^>>>>>>>>>>vz?rr>+>+v-<br></br>
`www?}}?v???????????????>r}w}v~vzr. +zv  ?+?^vzzr^+v|+>>>>>>>>>>}}~^^|~~|+`<br></br>
`rrr??v}}}???????vvvv????vv???v}w}. ~?? -+.?~>zz+~``^>>>>>>>>>>>>>|||||||^`<br></br>
`v^+?vvzyy}?????}uyyz????>^~~~?uyv. >}^.^|`|>rzzv^--^>>>>>>>>>>>>>||||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>>v??>+}}>. |?|-||||+>v?>||~^>>>>>>r^^>>>?}|||||||.<br></br>
.????v??v}v?>???????????>>??????^~` .~|||||~~~~~~~|~|>>>>>>z+|>>>>}}~|||||.<br></br>
`vv?>?????vv?v}wzw}?????++>>>??????. |??vv??????????^^>>>>>z?++>>>?x>vvvvv-<br></br>
`}}}v?????zuuyyuyxzv???>^>?v}}}vv}r~ -rr}}}}rrrrrrrrv|^>>>>}v|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>+???vvvvvvv}+..?vvv????v}vvvv}+|+>>>>+>>>>>????????`<br></br>
`vvv???>>??vv???vv??>+>?vvvvvvvvvvv>`.^?????????????>^^+>>>>+?????????????`<br></br>
.?????????>+v???wxr>>????vv?????????>>??????????????||^>>>>>vvvvvvvvvvvvvv`<br></br>
.????????>>rw??>>?v|+??????????????????????????????>~|^^++^???????????????`<br></br>
.??????????x}?>>>>^->??????????????????????????????>++++++>???????????????`<br></br>
.>>>>>>>>>>>rrv~-?+->>++>???????????????????????????????????????????????>?`<br></br>
.>>>>>>>>>-~~~--~--+?>>>>???>>???>>>>>>>>>>>>????????????????>>>?>>?????>>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .................................|?vv?|.................................. <br></br>
 ................................~}wwwzw~``............................... <br></br>
 ............................```.`?}??v?^^+^~-..``........................ <br></br>
                  ...........................-|^^|~~|~~~|++++^~~|^^-.......`~|||^+-`-...... <br></br>
 .........``.``~`..........`|++++++|++|^+^||-``+|-^~-???`.`^??>>v>?wv`.... <br></br>
 ........|?}v?vr}`.........`~^+^^^+^^>|^+|-``..`-..~>?vwv||>>>^^>>?wr}~... <br></br>
 .......>}rwzrww+........~+^^^||-~||~-~|~````````--+??}r}>^>>+|~|>>>?}vvv>.<br></br>
 ..`....^>vwwwz}-.....`.-?>?+-```----`-~~`-`````>r}vv?>>+>>>>++>+>>>>>>>>?-<br></br>
 ```````>vwwwwwwr>~~~^?v>>|~~---~~-`-``~???^~`~>v?>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````~vwwwww}??}??vvwzwv|`-~>vv?- ``  ^r}}v?++>>>>>>>++>>>>>>>>>?>++>>>?-<br></br>
 ``````?wwwwwwwv?????}w}rwrv??wzww-     `vrww?>>++>+++++>>>>>>>>>?v>v}}v?>`<br></br>
.----`-vzww}v}}v??????vvvrwzwwwwww-  -  .vw}}^+>>>+??+^+>>>>>>>>>wvvzzzwwr-<br></br>
.+>^|~+r}}v??}v?????>?}vvrwwwrwwr}- .v. .vr??^+>?wvw}^+>>>>>>>>>>z?}wwwwv?`<br></br>
`r}?vv}v?vv?}v??????>vr}vv}}r?v?rv- `v. `v^^?>^^^^^v?|>>>>>>>>>>?x?rzwwr}}-<br></br>
`wrrrrw??vvv??????????????>?r?~}zz` `w+..}?+}}???`?r>^>>>>>>>>>>?v>vv+++>v~<br></br>
`www??vvv???????????????>}}vv?~vzw` `}+..?>v>vzwr^>}^+>>>>>>>>>>??^^+?|~^>`<br></br>
`}rrv?v}r}?????v??vvv????v?>>+?vw}` .??.|+~v>?zz+~--|+>>>>>>>>>>++^^>v^~|+`<br></br>
.?>?vvvwyyv?????vuuuz????>>+++?wzv` `}>.^^`|>vwzv+--|+>>>>>>>^+++>+^?+~~||.<br></br>
.>???v?vxuwv???rzuuzv?????vv???wzv. .>^-||~|^>v?+|-~|+>>>>>r+^+++>?>>~||||.<br></br>
.>>??v??vvv?>???v???????>>???????^`  -||||||~~~~~~~~~^>>>>?x^|++>>??^|||||.<br></br>
`???+>????vv?vrwzw}?????++>+>??????. |??????????????+|>>>>?z>+^++>>v+?v??v-<br></br>
`}}v?????vxuuyyuyxzv???>^>??vvvvv}r^ ~rvvvvvv}}}}}}rv|+>>>?v?^++++?v?}rrrr~<br></br>
`vvv?????vzxxxzzwwrv??>^>?vvvvvvvv}+ .?vvv????vvvvvvv||+>>>>>?????vvvvvvvv-<br></br>
`vvvv??>>??????>^>v?++>????vvvvvvvv>..+v???????????v>|^+>>>+????vv????????`<br></br>
.?????????>>?>^|?vv+>>>????????????>^^??????????????||^>>>+>vvvvvvvvvvvvvv`<br></br>
.??????>>>>v?^^~~>>~>???????????????????????????>>>+~||^++|???????????????`<br></br>
.??????????r?>+^|^|`>???????????????????????????????++++>+>???????????????`<br></br>
.>>>>>>>>>>>vv?~`+|->>>>>??????????>>>>>>>>>>>>>>>>>>??????>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>|~~~--|~-^?>>>>???>>???>>>>>>>>>>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>?>`<br></br>
.>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`~-.................................... <br></br>
 ................................`?wzw}^.................................. <br></br>
 ................................>zwrwzv`--`.............................. <br></br>
 ...........................````.`??|>?+^+++^^...............````......... <br></br>
 .......... ...............`^+++|||~~|-^+++^~+>-.`........`^?>>?v-`~`..... <br></br>
 .........~^~^?v|..........-^++>>++^>>^++|-`..`>+``.~?vv|.|>v?+>?^vzv`.... <br></br>
 .......`vzzw?wzw-........--~|+v?|+|^>|^^``....-|`..~+>rv~^>>+||+>?rr}-... <br></br>
 ......`}}}wwvrr|........>????>~`-~|~`-~`````````--^??v}v^+>++>+^>>>?vvvv>.<br></br>
 .......|^?wwzzv........~?+?^~```~~--`-|+-``````?rvv??>>>>>>>>>>>>>>>>>>>?-<br></br>
 ```````~>wwwwrw}+.`+-~??>-`---~|-`-|-.>r?>^-``-v?>>>>+>>>>>>>>>>>>>>>??^+.<br></br>
 ``````-?}wwzwv>?v???+}zww+`--+}w?  `.  `}}vv?>^>>>>??+^^+>>>>>>>>?+^vzwv?-<br></br>
 ``````?zwwzwwz?>?????}?vrr???rzzr.      >rwwzzv^++^^|^^+>>>>>>>>v}>rwwwzz~<br></br>
.------vzwrv>v}vv????>w?>vwzrrwwww` `+   >z????v?++>>|+>>>>>>>>>>wv?zwww}}-<br></br>
.+>^~~|rvv??v}v??????>wwwwrwwrzrrv- -v  `?}>>>vwzwwr?|>>>>>>>>>>>w?vwwww}?.<br></br>
`rv>}??w??}}}vv??????>rrvvvww?v?w}| -?  v}^>}?}wr???+^>>>>>>>>>>>z?}zwwr}w~<br></br>
`w}}}}r}?vvv??????????????v?}?`vzz> `v.+wz?+rr??v|vz>^>>>>>>>>>>>?>??^^^?r|<br></br>
`wwwv>v}v???????????????>vr?>|~vzz? .>.>zv?rvrwrr^?z?^>>>>>>>>>>>>>>>v^~^>`<br></br>
`vvrrvv}zr????????v?v??????+|-|?rrv  vvvw?vwrvwz>~-^+^>>>>>>>+++^+>>vu+~~+`<br></br>
.^>v}}?ryyv?????vxuuxv??????>?>?>?r. }?^>+-^^?wwv>--~^>>>>>>+~~||+>>z?~~~^`<br></br>
.>???v??zuw????rzyyu}???v?vvv??zxr^. >>|||-~|+?>||`|||>>>>>w+||~^>>??~||||.<br></br>
.~|^^????v??????vvv?????>+????>vw>|` `|||||||~~~~~~|~|>>>>vx^|~|+>>?>||~~|.<br></br>
.??>~+???v}v?vrwzwv?????+^+++>?>?>?> |??????????>????|>>>>wz>?^|^+++^?v???`<br></br>
`}r?^>???}uuuuuuuzw????+^>???vvvv}r? ~}???vvvvvv}}}}v|+>>>}v?rv??????}rrrr~<br></br>
`vv?+>+>?vzzzzzzrrrv??+^>?v}vvvvvv}> .?vvvv?v??vvv?v?~^>>>>+?v}}}}}}}}}}}}-<br></br>
`vvvvvv?>>?????^-~rv^^>?>+>vvvvvvvv?..?vvvvvvvvvvvvv?~^>>>>?vvvvvvvvvvvvvv-<br></br>
.??????>+++>+---~~>^+^^????????????>~~>?????????????+|^>>>+?vvvvvvvvvvvvvv`<br></br>
.>>>>>>+>>>+`-`-~~-|?>>+?>>>???????????????????>++++~||^+^+???????????????`<br></br>
.>??????????^>|~~~~-????????????????????????????????++++>>>???????????????`<br></br>
.>???????????++^-~~->??????????????>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>+|~^?>>>>>>?>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`-`.................................... <br></br>
 ................................`?rww}^.................................. <br></br>
                  ................................^wwwwzr-``............................... <br></br>
 ...........................``````?v+>v+^++^|-................```......... <br></br>
 ...........`````..........`^+++^||~~~-^++++||+`......``..`|>>+>?~``...... <br></br>
 .........-||^?v+..........-^++++++^>>^++^~-`.~>|``.~?vv|.~+??+>?>?}?`.... <br></br>
 .......`+?vv?vw}`........~-~|^+^|+|^>|^+~`...`~^`..`^>v?-|>>+^|+>>}r}-... <br></br>
 ......`>>>?vv}}|.......`>???+|``-~|~`~~-```````-|~|?vvv?^+>>++^|>>>?vvvv>.<br></br>
 .......`->rr}rv`...`-`.-?^+~-```-~--`-~|``````~?v???>>++>>>>>>>>>>>>>>>>?-<br></br>
 ```````~?wzwrrw}>-|?+>?>|`-----~-``~-.|v?>|--|+v>+>++++++>>>>>>>>>>>>>>+>`<br></br>
 ``````^vrwwzw}??v?vv>vwr}^`-~^vr?  `.  `}vv??>+>>>>>>+^^>>>>>>>>>?>^>????-<br></br>
 `````-?wr}}rrr???vvv?v?vw}???}zzr.      >}rwwwv+>++^^^^>>>>>>>>>?v>?v}v??-<br></br>
.----~+?}}}v?vvv???v??wvv}wzwrwwww. `+   ?w???vv?>>??^+>>>>>>>>>>}v?wwzwv?`<br></br>
.^>?>>>}vvvvvvv????v??wwwwwwwrzwwv. ~v  -vr?>?}wzzwwv^>>>>>>>>>>>z?vwwww}v`<br></br>
.vvvrvvw??}}vv????????r}}vvrr?v?}v` ~?  ?}+^??}}v???>^>>>>>>>>>>?z>v}}}}}w~<br></br>
`}v}}}vv?vvv??????????????vvr?-vwz^ `v.`vz?~?}???|?w>^>>>>>>>>>>??+??>>?vw~<br></br>
`v}r}??vv????????????????}rv?+~vzw? .?.-}v?v>vrr}^>}>^>>>>>>>>>>?>>>?v>>?v-<br></br>
`v}r}vv}w}????????vvv????v?>+^?vwrv .?||r>>v?vzz+-`|^^>>>>>>>>++>>>>?z+|+?-<br></br>
.?v}vvvryuv?????}uuuxv???>>>???}vvr. ?~`+^-^^vww>^--~^>>>>>>+|^^+++>r>~~|^`<br></br>
.????v?vxuwv???rzuuzv?????vv???zx}+` ^``||~|^>v>|~`~~^>>>>>v^|^^+>+??~||||.<br></br>
.^+>>???vvv?????vvv?????>>?????vr>|` `.-||||~~~~|~-~~^>>>>?r^|^^>?>>+|||||.<br></br>
.???+????vvv?v}www}?????++>>>?????v> `|>?????????????|+>>>rr+>+^>>++^?vvvv-<br></br>
`vv?+>???vzuuuuuuzzv???>^>??vvv}}}r? `^?vvvvv}}}}}}}v|+>>>}v+??>>?>??}rrrr~<br></br>
`vv?>>???vwxxxxxwwwv??>^>?vvvvvvvv}?. ~?vvvvvvvvvvvv?|^>>>>+>????w?v}}}}v}-<br></br>
`????vv????????+-^vv>+>>>>>vvvvvvvv?`.|?vvvvvvvvvvvv?~^>>>>??>???v?vvvvvvv-<br></br>
.??????>+>>?+~-```>+>>>????????????>|-+?????????????+~|+>>+??+>>>>>???????`<br></br>
.>?>>??>>??>-`.```~^>??>???>???????????????????>>>>>~~~|^^+??^^++++???????`<br></br>
.???????????|^~`-~|~????????????????????????????????>+++>+>?>^^^^^^+??????`<br></br>
.>??????????+|||-~|~???????????????>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>+^|~-~^>>>>>>>?>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>^|||+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 .................................~v}}v^.................................. <br></br>
                  ................................`rwwwzx~................................. <br></br>
 .............``.............`--``?}?>vv|^||~............................. <br></br>
 ..........`>vv}>`.........`^+++^^|~~~||+++++~|`.....-|+`..`~^^^^^`....... <br></br>
 ..........|??vwr|.........~^++++++^++^^++^|~~?>-``.|vvv|.`^>}?>vv+>~`.....<br></br>
 ...........`+>v^........~+|~^^^^++|>?^^++-...^v?`...~^+^`~^>>>|+>>vzw~....<br></br>
 ...........|??w| ......^???^````-||~-~|~```````^?~-?}vv>|^>>++|^+>+?v}}?+`<br></br>
 .........|v?^?v>`..|>~.`~~`-`-``-~~-`-~-`````~>>>>?>>>+^^+>>>>>>>>>++??vr~<br></br>
 ```````|vzzrvrw}v>vwzw?~.`----``--`~~`~>>+|`~}zz?+?+^^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzwwzzwv??wzrv?vv?|`-~~+?v` `-.-wrv????vwv|^|^+|+>>>>>>>>>>>+++>>v~<br></br>
 `````~vrv>>vrv???vzv?^+rz}?v??wzz`    .}??vrr}}wwv?vr>^>>>>>>>>>>??+>?+^+`<br></br>
.---->r?^+?v}v?????wwrrwwwwzwrwwwr. ~+  vzwv>?v}wwzzz}|>>>>>>>>>>?v?rzzwv^.<br></br>
.~+vvrw?}wwrv?????>}zzwwwwzwwwwwz}  ??  ?zww+vwrwzzwz>^>>>>>>>>>?xvvrrwwwr~<br></br>
.+}zzw}rwwrv??????>?wrvv}wvv}>vv??. ??  ^v?~^?}?>?}w}|>>>>>>>>>>}z|^^^>?vw~<br></br>
.^?}ww??v}vv????????v???rv?rw?-vwr` >?  ?zv.`>>+^|+v?^>>>>>>>>>>z}>vr}rwww~<br></br>
.^+vww?vvv?????????????>rrrwz?-vwv^ ^?  v}+?-??v?^^?^^>>>>>>>>>>xvvwrwzwww~<br></br>
`}r}vvvv?????????v}}}???v}?>vv}rww}`|v .v+`^.?zw~``-|+>>>>>>>>>>xv>+^+>>vw~<br></br>
`rw}vv}wxzv?????wyyyw????+|~||vyyrw??> .+|||~}zr|||||+>>>>>>^^>>xv~|||~|++.<br></br>
`?vr}v?wyywv???rwzw}????>>??>^^v?+>??~ .|||^>?v>|||^^+>>>>>^|+>>w}~|||||~|.<br></br>
.>?v?}??rzrv????????????>?vvvv??+~|||` `||~|~~~~~~~||+>>>+|||+>>}w||||||||.<br></br>
.????v????????vrrr}?????>>?>??????vvv` +v??????????>>>>>???+|^>>?z+v}vvvvv-<br></br>
`vv???v???vzxuyuuxxr>??>^+??v}}}}}}r}. ?rrrrrrrrrrrrr}}}rrr?|^>>>v>}rrrrrr~<br></br>
`vvvv?v???}uuuuuuuzw???++?vvvvvvvvv}?  ?}vvvvvvvvvvvvvvv}}}v^^+>?z>v}}}}}}-<br></br>
.???>?v???v}}v??vv???>++>+?????vvvvv+ .>vv??????vvvvvvvvvvvv+^+>>}>?vvvvvv-<br></br>
.??????????>??????>?>????>??????????+-~>???>>>>????????????>^|+>>>+>??????`<br></br>
.???????????v~~+>>r?>???????????????????????????????????????>|^>>>>+??????`<br></br>
.?????????>?|--~+?x???>?????????????????????????????????????+~|^^^^^??????`<br></br>
.>>?????????~|~~-^r>????????????????????????????????????>>>>>>>>>>>>??????`<br></br>
.>>>>>>>>>>>+|~~~~|+>>>>>>>?>>???>>>>>??>>>>>???????????????????????>???>>`<br></br>
.>>>>>>>>>>>>>+~-~-~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
 .................................~?}}v^.................................. <br></br>
 ................................`}wwwzx|................................. <br></br>
 .............``.............`--``?}?>vv||||~............................. <br></br>
 ..........`?v}}>`.........`^+++^^|~~~||+++++~|`....`-^+`..`~||^^^`....... <br></br>
 ..........|??vwr~.........~^++++++^+^^^++^|~~?>-``.|??v^.`^>}?>vv+>~`.....<br></br>
 ...........`+>v+........~^|~|^^^++|>?^^++-`..^??`...~^+|`~^>>>|+>>vzw~....<br></br>
 ...........|??r|......`^??>|````-||~-~|~`````.`^?~-?}vv?|^>>++||+>+?v}}?+`<br></br>
 .........|v?^>v>`..|>~.`--`-`-``-~~-`-~-`````~>>>+?>>>+^^+>>>>>>>>>++??vr~<br></br>
 ``````-|vzzrvrw}v>vwzr?~.`----``--`~~`~>>+~`~}zw>+?+^^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzwwzzwv??wzr??vv?|`-~~+?v` `-.-rrv????vrv|^^^+|+>>>>>>>>>>>+++>>v~<br></br>
 `````~vrv>>vrv???vz}?+>rz}?v??wzz`    .vvvv}r}}}r???}>^>>>>>>>>>>??+>?+^+`<br></br>
.---->}?^+?}}v?????wwrrwwwwzwrwwwr. ~^  vzwv>?v}r}rrrv|>>>>>>>>>>?v?rwr}?^.<br></br>
.~+v}rw?}wwrv?????>rzwwwwwzwwwwww}  ??  ?www+vwrwzwwz>^>>>>>>>>>?x?vrrwwrr~<br></br>
.+}zzw}rwwrv??????>?rrvv}wvv}>vv??. ??  |v>~^?}?>?}w}|>>>>>>>>>>}z|^++>?vw~<br></br>
.^?vww??v}vv????????v???rv?rw?-vwr` >?  >z?.-??>^|+v?^>>>>>>>>>>z}>?r}rwww~<br></br>
.^+vww?vvv??????????????rrrwz?-vwv| ^?  ?v+?-??v?^|?^^>>>>>>>>>>xvvw}wzwww~<br></br>
`}r}vvvv?????????v}}}???v}??vv}rww}`|v .?^`^`?zw~--~|+>>>>>>>>>>xv>+^+>>vw~<br></br>
`rw}v?vwxzv?????wyyyw????+^^++vyyrw??> .~~|||}zr|||||+>>>>>>^^>>xv~|||~^++.<br></br>
`?vr}}?wyywv???rwzw}????>???>++v?+>??~ .-||^>?v>^||||+>>>>>^^+>>w}~|||||~|.<br></br>
.>?v?}??rzrv????????????>?vvvv??+~|||- .~|||~~~~|~~||^+>>+||+>>>vw||||||||.<br></br>
.????v????????vrrr}?????+>?>??????vvv` |v??????????>>++>>>?^^+>>?w+vvvvvvv-<br></br>
`vv???v???vzxuyuuxxr>??>^+??v}}}}}}r}. ?rrrrrrrrrr}}}vvvvv}?|^>>>v>}rrrrrr~<br></br>
`vvv??v???}uuuuuuuxw???++?vvvvvvvvv}?  ?vvvvvvvvvvvvvvvvvvvv^^+>?z>v}vvvvv-<br></br>
.?????v???v}rv??vv???>++>+?????vvvvv+ .>vv??????vvvvvvvvvvv?+^+>?}>?vvvvvv`<br></br>
.?????????????????>?>????>?v????????+-~>???>>>>???????????>>^|+>>>+>??????`<br></br>
.????????????~~+>>r?>?????????????????????????????????????>>+^^>>>>+?>????`<br></br>
.????????>>?|``-+?z???????????????????????????????????????>>+~||^^|^??????`<br></br>
.>???????>>>~~---^}>>>>>????????????????????????????????>>>>>++++++>??????`<br></br>
.>>>>>>>>>>++~-~~-|^^^^+>>>?>>???>>>>>??>>>>>???????????????????????>???>>`<br></br>
.>>>>>>>>>>>>>+~-~-~^^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ................................... . ................................... <br></br>
                  ..................................`+??^.................................. <br></br>
 .................................`rzzzz}................................. <br></br>
 ...........`+??`........`~```~|^||}rvvwv`................................ <br></br>
 ..........-vv}w>.......|??>-+++++^|+~~^~~|^|-`.....`^??-......`.......... <br></br>
 ...........--+?|......>?~-~`~^++++^||^~+++++>|````.`^^^-`..~+>++>+-~`...~`<br></br>
 ...........~+-}}+....>?......`-~^+^+?+^++++++|-....-^+^-`.~+>r?>v??ww~..?|<br></br>
 ..........-.`.~?+` ..^~........`-||~|~^^~|^^^~~^`.`}zzzr?~^>>>^~+>^?}r}??-<br></br>
 .........>vv???>~.+?>`....```````-~---~-````~>???|?>?v?+++>>>>++^>++?rzzz~<br></br>
 ``````+v}zzzzzwwwvv?v>````-`--```~-`---~+^--?v?>>+>>>>>>>>>>>>>?>>>>>??v}-<br></br>
 ``````?zwrrww}?vzw}?>+~~---~~~|>?+ .~-.+wr}v??v>rv>>++>>>>>>>>>>>>>>>>>>>`<br></br>
 ````->v?>>?r}v??rzw}vvrw}v????rzz?     ^zrvvv}>^??^^|||^>>>>>>>>>v?^^+>>>`<br></br>
.--|^^>>?vww}v????wwzzzzwzzwwwwwwz|  -  ~zwr??rv+|^+>?>|+>>>>>>>>v?>??>++>`<br></br>
.|+}zwwwwzw}v????>vwrwwzwwwzwrwwr}` .}. `}wv?rvv}rrwz}|+>>>>>>>>?y?+v}}r?^.<br></br>
.+>rwwwwww}v??????+++?vvww}v}?}?+~. ~w- `?~^?>+??}v}z>^>>>>>>>>>wx+?v??}z}`<br></br>
.+^+>vrrr}vv??????>?????rv|vw+~vwv. >z^ .r|^rr}}?`+r}^+>>>>>>>>>x}??}wwwrr~<br></br>
.>>>}??v}vv???????????>}r}vrw?|vzw. ?z? .?^>+vw}?`~v?|+>>>>>>>>?uv??>rzwrw~<br></br>
`wwww??v?????????vrr}??vv??}}rwzww~ ?x?  .`}??wr^>~??|>>>>>>>>>?u?^>??vrzz~<br></br>
`ww}v?vv}v?????vxyyy}???+?rrr}uurw?.vv^  `~?vwwv+v^^>|+>>>>>>+>?uv~~~|+?v?`<br></br>
`}}}vvvxyyr????rwzwv????>?vvvv}rv?>|?|` .~^>?}}v?||||~|+>>>>v+^>wv||||~~||.<br></br>
.++vvv?vxur}????????????>?????>>+|||||. `|~~~||~~||~~~~|^>>>wr+>>>^~||||||.<br></br>
.+??v}????????v}}}}?????++>>??????vvv^  ?v?????????-~~~||+>>??+>>>>?vvv?vv-<br></br>
.??>+v????vwxuyuuxzr>???+>vvv}}vv}rrr| -r}rrrrrrrr}>^^^||^^+^^+>>>>vrrrrrr~<br></br>
.???>?v???vuuyyuuyuxv??+>????vv?vvv}?. |v?????vvvvv}vvv????||+>>?z+???????`<br></br>
`vvvv?????vzxzrvv}v??>>>>>>>?vvvvvvv>..^??????>???????????^|^>>?z?????????`<br></br>
.??????>rwv???????>>?>???????????????>>???????????????????||+>>w}>vvvvvvvv`<br></br>
.???>>?>vwzv^-|+++^rv+>???????????????????????????????????~|^++>+>????????`<br></br>
.???????>+^?~.|++>+vz?>??????????????????????????????????>^^^|||^>????????`<br></br>
.>>>>>>>>|~--~?>+^~+}?-^>>???????????????????????????????>??>>???>????????`<br></br>
.>>>>>>>+++-~>>>>^>|-~-->>??>>???>>>>>??>>>>>????????????????>>>????????>>`<br></br>
.>>>>>>>>|-^>>>>>|>|--~^>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ................................... . ................................... <br></br>
 ..................................`>??^.................................. <br></br>
 .................................`rzzzz}................................. <br></br>
 ...........`+??`........`~```~|^||}rvvwv`................................ <br></br>
 ..........-vv}w>.......|??>-+++++^|+~~^~~|^|-`.....`^??-.....```......... <br></br>
 ...........--+?|......+?~-~`~^++++^||^~+++++>|````.`^^^-`..~+>++>+-~`...~`<br></br>
 ...........~+-}}+....>?......`-~^+^+?+^++++++|-....-^+^-`.~+>r?>v??ww~..?|<br></br>
 ..........-.`.~?>` ..^~........`-||~|~^^~|^^^~~^`.`}zzzr?~^>>>^~+>^?}r}??-<br></br>
 .........>vv???>~.+?>`....```````-~---~-````~>>??|?>?v?+++>>>>++^>++?rzzz~<br></br>
 ``````+v}zzzzzwwwvv?v>````----```~-`---~+^--?v?>>+>>>>>>>>>>>>>?>>>>>??v}-<br></br>
 ``````?zwrrww}?vzw}?>+~~---~~~|>?+ .~-.+wr}v??v>rv>>++>>>>>>>>>>>>>>>>>>>`<br></br>
 ````->v?>>?r}v??rzw}vvrw}v????rzz?     ^zrvvv}>^??^^|||^>>>>>>>>>v?^^+>>>`<br></br>
.--|^^>>?vww}v????wwzzzzwzzwwwwwwz|  -  ~zwr??rv+|^+>?>|+>>>>>>>>v?>??>++>`<br></br>
.|+}zwwwwzw}v????>vrrwwzwwwzwrwwr}` .}. `}wv?rvv}rrwz}|+>>>>>>>>?y?+v}}r?^.<br></br>
.+>rwwwwww}v??????+++?vvww}v}?}?+~. ~w- `?~^?>+??}v}z>^>>>>>>>>>wx+?v??}z}`<br></br>
.+^+>vrrr}vv??????>?????rv|vw+~vwv. >z^ .r|^rr}}?`+r}^+>>>>>>>>>x}??}wwwrr~<br></br>
.>>>}??v}vv???????????>}r}vrw?|vzw. ?z? .?^>+vw}?`-v?|+>>>>>>>>?uv??>rzwrw~<br></br>
`wwww??v?????????vrr}??vv??}}rwxww~ ?x?  .`}??wr^>~??|>>>>>>>>>?u?^>??vrzz~<br></br>
`ww}v?vv}v?????vxyyy}???+?rrr}uurw?.vv^  `~?vwwv+v^^>|+>>>>>>+>?uv~~~|+?v?`<br></br>
`}}}vvvxyyr????rwzwv????>?vvvv}rv?>|?|` .~^>?}}v?||||~|+>>>>v+^>wv||||~~||.<br></br>
.++vvv?vxur}????????????>?????>>+|||||. `|~~~||~~||~~~~|^>>>wr+>>>^~||||||.<br></br>
.+??v}????????v}}}}?????++>>??????vvv^  ?v????>????-~~~||+>>??+>>>>?vvvvvv-<br></br>
.??>+v????vwxuyuuxzr>???+>vvv}}vv}rrr| -r}rrrrrrrr}>^^^||^^+^^+>>>>vrrrrrr~<br></br>
.???>?v???vuuyyuuyuxv??+>????vv?vvv}?. |v?????vvvvv}vvv????||+>>?z+???????`<br></br>
`vvvv?????vzxzrvv}v??>>>>>>>?vvvvvvv>..^??????>???????????^|^>>?z?????????`<br></br>
.??????>rwv???????>>?>???????????????>>???????????????????||+>>w}>vvvvvvvv`<br></br>
.???>>?>vwzv^-|+++^rv+>???????????????????????????????????~|^++>+>????????`<br></br>
.???????>+^?~.|++>+vz?>??????????????????????????????????>+^^|||^>????????`<br></br>
.>>>>>>>>|~--~?>+^~+}?-^>>???????????????????????????????>??>>???>????????`<br></br>
.>>>>>>>+++-~>>>>^>|-~-->>??>>???>>>>>??>>>>>????????????????>>>>>??>???>>`<br></br>
.>>>>>>>>|-^>>>>>|?|--~^>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ....................................   .................................. <br></br>
                  ....................................`~-.................................. <br></br>
 ..................................`vwwz}~................................ <br></br>
 .............-...............```-.>zwrwz?................................ <br></br>
 ..........`?}r}-............|^+++^|??^>?-..``................`........... <br></br>
 ...........^^?v^..........`>>-^++++~|~~-^^++++-....`>?^~..`|>>++?^....... <br></br>
 ...........`|`.``........|?-..`-|++^>>+^+>>+++~``...-```..~>>}?>v>~~`..`+`<br></br>
 ..........`.. ~vv~.......~-.....`^^~>||+|>v>^|~~`..-?v>-``^>>+^^>>vz}`.|r~<br></br>
 ..........+|^|~``................`--`-||-`~^>???>|~?vv?+++>>++^+^>^vwv?vr~<br></br>
 .......||>vwzz}>|?v>`.....```````-|~``---``+?>?}rv??>>>>>>>>>>>?>>>+>vvvw~<br></br>
 `````.>rzzzwwv?ww}vr}?`.``--```````.-|``^+-^vv?>>>>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````^v}v?vrv???}??>++~`~~-~+^||^.   `. >vv?++^+???>+^^>>>>>>>>>>>>|^>>>>`<br></br>
 `````^^>??vv????}zv?vvv^???}r?vzv       vrv?+?vwwzzzw^^>>>>>>>>>?}+??^^||.<br></br>
.---+?+>}zwv??????v}zzzzwrvvwwr}v+   |~  }ww}rzzzwwwz}|+>>>>>>>>>v?>wzwwv?`<br></br>
.||vzwwwzw}v?????>|+vv?}r?v?vrwrvv^  >> .wz???wvvwzwz?^>>>>>>>>>vy?>?}wwzz~<br></br>
.++>?+>}w}}v???????????}r}r?>^??vwr. >^ `vv?>rwr>?vz}^>>>>>>>>>>wz>rvwzwrr~<br></br>
.?>^|??v}vv????????????vvvvwr?>v}}w?.?~ `}v~>zwz}^^>+|^+>>>>>>>>u}>??vv}v}-<br></br>
`v}v???vv???????vrzzzv????rwrruywwz?.v- >r>?v}w}?|^?~|^>?vwr>>>?y?>}ww}rz}`<br></br>
.?rzw??v}}v?????ryyyw???>?vvvvzxv}wrvw` ++?w+>ww>v+?^|^>>>vw>>>?x?^vvrzzw?.<br></br>
`rrv}vv}yyuv???}}r}v?????+???>>>?vwzw?. |+?}>rw}|rrr?|^+>>>>>>>>>>-~+vv???`<br></br>
`vvv????rxw}v?????v??????^++^^^?w}}r?~  ~>?>>}vv~^>}+|^>>>+^>>>>>+`~|||~~|.<br></br>
.>>>v}v???????v}zxx}?????^|||~`>?||^^~ .|||~|||||~^+~|^>>>+^>>>>>^~^^||||^.<br></br>
.^|>vvv????}xuuyuuwx}???>+>+^++????vv> ~????????????^||^^+^|>>>>>>v}}vvvv}-<br></br>
.>>?>+vv???}uyyyuuuz}??>^+>???vvv}}rr+ |??v??vvv}}}}v?>>>^|^>>>>+v}}}}}}}r~<br></br>
`?????++???vww}v??v???>+~>v}}vvvvvvvv` |vvv????vvv??vvvvv^|^>>>>?vvvvvvvv}-<br></br>
`vvvv?-~~>?v???????>>+vr|-^?vvvvvvvv?.`>vvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
.????+~~~-^w>+++++++>??}~^|??????????~~>????????????????|^+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~~>^+>+^+++>>?>+^->???????????????????>+++>>??>|^++>v>???????????`<br></br>
.>?>>>>|-~~~>?????>>+^????>?????????????????????????????^^^^^^+???????????`<br></br>
.>??????|-~-~???????>>?????????????>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>+^|+>>>>>>>>>>>>???>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ....................................   .................................. <br></br>
                  ....................................`~-.................................. <br></br>
 ..................................`vwwz}~................................ <br></br>
 .............-...............```-.>zwrwz?................................ <br></br>
 ..........`?}r}-............|^+++^|??^>?-.```............................ <br></br>
 ...........^^?v^..........`>>-^++++~|~~-^^++++-....`>?^~..`|>>++?^....... <br></br>
 ...........`|`.``........|?-..`-|++^>>+^+>>+++~``...-```..~>>}?>v>~~`..`+`<br></br>
 ..........`.. ~vv~.......~-.....`^^~>||+|>v>^|~~`..-?v>-``^>>+^^>>vz}`.|r~<br></br>
 ..........+|^|~``................`--`-||-`~^>???>|~?vv?+++>>++^+^>^vwv?vr~<br></br>
 .......||>vwzz}>|?v>`.....```````-|~``---``+?>?}rv??>>>>>>>>>>>?>>>+>vvvw~<br></br>
 `````.>rzzzwwv?ww}vr}?`.``--````-``.-|``^+-^vv?>>>>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````^v}v?vrv???}??>++~`~~-~+^||^.   `. >vv?++^+???>+^+>>>>>>>>>>>>|^>>>>`<br></br>
 `````^^>??vv????}zv?vvv^???}r?vzv       vrv?+?vwwzzzw^^>>>>>>>>>?}+??^^||.<br></br>
.---+?+>}zwv??????v}zzzzwrvvwwr}v+   |~  }ww}rzzzwwwz}|+>>>>>>>>>v?>wzwwv?`<br></br>
.||vzwwwzw}v?????>|+vv?}r?v?vrwrvv^  >> .wz???wvvwzwz?^>>>>>>>>>vy?>?}wwzz~<br></br>
.++>?+>}w}}v???????????}r}r?>^??vwr. >^ `vv?>rwr>?vz}^>>>>>>>>>>wz>rvwzwrr~<br></br>
.?>^|??v}vv????????????vvvvwr?>v}}w?.?~ `}v~>zwz}^^>+|^+>>>>>>>>u}>??vv}v}-<br></br>
`v}v???vv???????vrzzzv????rwrruywwz?.v- >r>?v}w}?|^?~|^>?vwr>>>?y?>}ww}rz}`<br></br>
.?rzw??v}}v?????ryyyw???>?vvvvzxv}wrvw` ++?w+>ww>v+?^|^>>>vw>>>?x?^vvrzzw?.<br></br>
`rrv}vv}yyuv???}}r}v?????+???>>>?vwzw?. |+?}>rw}|rrr?|^+>>>>>>>>>>-~+vv???`<br></br>
`vvv????rxw}v?????v??????^++^^^?w}}r?~  ~>?>>}vv~^>}+|^>>>+^>>>>>+`~|||~~|.<br></br>
.>>>v}v???????v}zxx}?????^|||~`??||^^~ .|||~|||||~^+||^>>>+^>>>>>^~^^||||^.<br></br>
.^|>vvv????}xuuyuuwx}???>+>+^++????vv> ~????????????^||^^+^|>>>>>>v}}vvvv}-<br></br>
.>>?>+vv???}uyyyuuuz}??>^+>???vvv}}}r+ |??v??vv}}}}}v?>>>^|^>>>>+v}}}}}}}r~<br></br>
`?????++???vww}v??v???>+~>v}}vvvvvvvv` |vvv????vvv??vvvvv^|^>>>>?vvvvvvvv}-<br></br>
`vvvv?-~~>?v???????>>+vr|-^?vvvvvvvv?.`>vvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
.????+~~~-^w>+++++++>??}~^|??????????~~>????????????????|^+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~~>^+>+^+++>>?>+^->???????????????????>+++>>??>|^++>v>???????????`<br></br>
.>??>>>|-~~~>?????>>+^???>>?????????????????????????????^^^^^^+???????????`<br></br>
.???????|-~-~???????>>?????????????>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>+^|+>>>>>>>>>>>>???>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ....................................... ................................. <br></br>
 ............^^`...................~v}rv^................................. <br></br>
 ..........--}w?.`................`rzwzzz-................................ <br></br>
 ..........`.`~`..............~|^|~?}?>v?`.```............................ <br></br>
                  ...........................|~+++++||~~~~|^+++^`...........`|^^^++`....... <br></br>
 .........................`+v+-|^+++^++^^+++++^-`....~++`.`^>}?>v}^?+`.... <br></br>
 ..........```~~..........??|...`^+^|?>^++^^^||^+|`.|zzx?`|+>>>|+>>rzr-`.. <br></br>
 ........~vwrrwz?.......~?+`.....`~||~-||~````|+??+~+vvvv^+>>++|^+>?zzwr}}-<br></br>
 .......?zzwwwwz?....~-.|+..``````-~~`----```->rwv?vv>>>>>>>>>>??+^^?}}rzz~<br></br>
 `````..vzwwwwww}?~+vzw?`.`--````~+~`~~`~?^-`?}?>>>>>>>>>>>>>>>>>+^^|?v??r~<br></br>
 ``````^??}wwwrvv?}zzwwz?---`+v?>rz^ `- -wv?>>>+^^^^+>>>>>>>>>>>>>+^^|^++>`<br></br>
 `````~|>vwwwrv???vwwwwwwrv??rwv???.    .rwwzv+^>r}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
 `````->wzwwrv?????wwwzzzwzzzwww}}v  ^^  vzwzzwrwzzzr?|^>>>>>>>>>}v>rrr}vr~<br></br>
.^??>?vwwww}v?????>}wv?v}wzwwwwwwzv  >v  vzw?}wwwzzwz?|+>>>>>>>>>uvvzwwzww~<br></br>
.}zzzz}rwwrv??????>?wv>>vwvvr>}v?vv  ?}  ??++?w?>vvwv|+>>>>>>>>>?u>vwwwrv>.<br></br>
`wwwww?vv}vv????????vv??rv?rwv>wwwv  ?v `wv`->>+^+>>|^>>>>>>>>>>vz^vw?^^^?`<br></br>
`wzzzz??vv??????????????rrrwwr|vzzv  ?? ?w+?>?>vv^~>|+>>>>>>>>>>rr~+^|~||+`<br></br>
`ww}vvvv?????????v}}}???v}??vvvrwww. ?+.}?`>^vzw>+``|+>>>>>>>>>>zv~||||||^`<br></br>
.vvvv?vwxzv?????wyyyw????+|~^^vyyrw- --~+~|||vzw++|~~+>>>>??+>>>v?~|||||||.<br></br>
`>|^>v?ryywv???rwzw}????>>?>+^^v?+?- `||||||+?v?|^||~^>>>>vr+>>vv|||||||||.<br></br>
.>?vvv??rzrv????????????>??vvv?>^~|` `|||~~|~~~~~~~|~|>>>>?x++++|~||||||||.<br></br>
.????v????????vrwr}?????+>??>?????v> .>?v???v????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv???v???vzxuyuuxxw???>^+??v}}}rrrv  vrrrrrrrrrrrrrr+^>>>?}>rrrrrr}rrrrrr~<br></br>
`vvvv?????vuuuuuuuzr??>+>?vvvvvvvv}v  ?}vvvvvv}vvvvv}>|+>>>>>v}v}}v}}}}}v}-<br></br>
.???>?????v}}v???v???>^->>??????vvv?. ^vvv??????vvvvv?|^>>>>+vvvvvvvvvvvvv-<br></br>
.????>>>>??v?>???>>?}?^-??>?????????~-^???>>>>>>?????>|^+>>>+>>>>>>???????`<br></br>
.?????~^?v?w+>???+?v?+|~?????????????????????????????>|^^+>>+??????>>>>>??`<br></br>
.????>-~~^?w????>???|?^|?????????????????????????????>~|||^|+?????????????`<br></br>
.>????|-~-+v???>>?>?>-|???????????????????????????????>+++++??????????????`<br></br>
.>>>>>>~~~~~+>>>>>>>>>>>>???>>???>>>>>>>>>>???>??????????????>>>>>>????>>>`<br></br>
.>>>>>>>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ....................................... ................................. <br></br>
 ............^^`...................~v}rv^................................. <br></br>
 ..........--}w?.`................`rzwzzz-................................ <br></br>
 ..........`.`~`..............~|^|~?}?>v?`.```............................ <br></br>
                  ...........................|~+++++||~~~~|^+++^`...........`|^^^++`....... <br></br>
 .........................`+v+-|^+++^++^^+++++^-`....~++`.`^>}?>v}^?+`.... <br></br>
 ..........```~~..........??|...`^+^|?>^++^^^||^+|`.|zzx?`|+>>>|+>>rzr-`.. <br></br>
 ........~vwrrwz?.......~?+`.....`~||~-||~````|+??+~+vvvv^+>>++|^+>?zzwr}}-<br></br>
 .......?zzwwwwz?....~-.|+..``````-~~`----```->rwv?vv>>>>>>>>>>??+^^?}}rzz~<br></br>
 `````..vzwwwwww}?~+vzw?`.`--````~+~`~~`~?^-`?}?>>>>>>>>>>>>>>>>>+^^|?v??r~<br></br>
 ``````^??}zwwrvv?}zzwwz?---`+v?>rz^ `- -wv?>>>+^^^^+>>>>>>>>>>>>>+^^|^++>`<br></br>
 `````~|>vwwwrv???vwwwwwwrv??rwv???.    .rwwzv+^>r}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
.`````->rzwwrv?????wwwzzzwzzzwww}}v  ^^  vzwzzwrwzzzr?|^>>>>>>>>>}v>rrr}vr~<br></br>
.^??>?vwwww}v?????>}wv?v}wzwwwwwwzv  >v  vzw?}wwwzzwz?|+>>>>>>>>>uvvzwwzww~<br></br>
.}zzzw}rwwrv??????>?wv>>vwvvr>}v?vv  ?}  ??++?w?>vvwv|+>>>>>>>>>?u>vwwwrv>.<br></br>
`wwwww?vv}vv????????vv??rv?rwv>wwwv  ?v `wv`->>+^+>>|^>>>>>>>>>>vz^vw?^^^?`<br></br>
`wzzzz??vv??????????????rrrwwr|vzzv  ?? ?w+?>?>vv^~>|+>>>>>>>>>>rr~+^||||+`<br></br>
`ww}vvvv?????????v}}}???v}??vvvrwww. ?+.}?`>^vzw>+``|+>>>>>>>>>>zv~||||||^`<br></br>
.vvvv?vwxzv?????wyyyw????+|~^^vyyrw- --~+~|||vzw++|~~+>>>>??+>>>v?~|||||||.<br></br>
`>|^>v?ryywv???rwzw}????>>?>+^^v?+?- `||||||+?v?|^||~^>>>>vr+>>vv|||||||||.<br></br>
.>?vvv??rwrv????????????>??vvv?>^~|` `|||~~|~~~~~~~|~|>>>>?x++++|~||||||||.<br></br>
.????v????????vrwr}?????+>??>?????v> .>?v???v????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv???v???vzxuyuuxxw???>^+??v}}}rrrv  vrrrrrrrrrrrrrr+^>>>?}>rrrrrr}rrrrrr~<br></br>
`vvvv?????vuuuuuuuzr??>+>?vvvvvvvv}v  ?}vvvvvv}vvvvv}>|+>>>>>v}v}}}}}}}}v}-<br></br>
.???>?????v}}v???v???>^->>??????vvv?. ^vvv??????vvvvv?|^>>>>+vvvvvvvvvvvvv-<br></br>
.????>>>>??v?>???>>?}?^-??>?????????~-^???>>>>>>?????>|^+>>>+>>>>>>???????`<br></br>
.?????~^?v?w+>???+?v?+|~?????????????????????????????>|^^+>>+??????>>>>>??`<br></br>
.????>-~~^?w????>???|?^|?????????????????????????????>~|||^|+?????????????`<br></br>
.>????|-~-+v???>>?>?>-|?????????????????????????????>?>+++++??????????????`<br></br>
.>>>>>>~~~~~+>>>>>>>>>>>>???>>???>>>>>>>>>>??????????????????>>>>>>???>>>>`<br></br>
.>>>>>>>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ..................................  . ................................... <br></br>
 ..................................|??>-.................................. <br></br>
                  .................................?zzzzz|................................. <br></br>
 ..............................```?wv?}r^~|~-`.`-`........................ <br></br>
                  ............................-|^^|~+~-^|^+++++~+??|.........`---~-.`...... <br></br>
 ........... ...............|++++++|^|||++++||-^~|?>->?>...|?v>>}?>r?`.... <br></br>
 .........+v?vv}v`.........`^++++++^+?+^++~-`......>?vwz?>~+>v>^?>?zwr~... <br></br>
 .......|}zzzzzz?........`^~~^|^|~^^||~^^-```.```--+v?rwwv|>>+|~^>>vzzw}r>.<br></br>
 .......|vwwwwwz+.....``-??>>-`````~~`-~-```````>ww}vv?>>+>>>>>?+>>>???vv}~<br></br>
 `````..?wwwwwwzw?||.>rr>?+||-```|^--`-->?|```|vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwww??vv??zzwr+-`-->?+v?.-~..}wv>+^|>>>>>>>>>>>>>>>>>>>?>>+++>?-<br></br>
 `````->rwwwwwrv?????zzwzw}?+?zzww>     >wrww>^>>>>>>>++>>>>>>>>>?v>?vvv>^.<br></br>
 -````-vzwwwr}v??????}?v}wzzzwwwwz^  -. -zwzr|>>>?>?v?|^>>>>>>>>>w?}zzzzzr-<br></br>
.|^|~-+rrw}>?v??????>}?>?}zzwwwwr}- .}- .vwv?++>?w?rw^|>>>>>>>>>>xvwwwwwv?`<br></br>
.}w?v}r?vv>?}v??????>rrvv}rvr?v?}?` `z+  v^~^^||^^|vv~+>>>>>>>>>?xvzwwww}?`<br></br>
`wwwwww??vvv?????????????v^vz?|rzz` ~z?  }?^v}???->}+^>>>>>>>>>>vz?rr>+>+v-<br></br>
`www?}}vv????????????????r}w}v-vzw. +zv  ?^?^vzzr^+?|+>>>>>>>>>>v}~^^~~~|+`<br></br>
`rwr??vv}}???????vvvv????}v????}wr. -?? -+.?~+zw^~``^>>>>>>>>>>>>>|||||||^`<br></br>
`v^^?v?zyy}?????}uyyz????>^~~~?uyv. >v^.^|`|>rzzv^--^>>>>>>>>>>>>>|~||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>>v??>+}}+. |?~`~|||+>v?>^|~^>>>>>>r+^>>>?}|||||||.<br></br>
.????v??v}v?>???????????>>??????^~` .~||||~~~~~~~~|~|+>>>>>x+|>>>>}}~|||||.<br></br>
`v??>?????vvvv}wzw}?????++>>>??????. |??vv??????????^^>>>>>x?++>>>?x>vvvvv-<br></br>
`}}}v?????zxxyyuyuzv???>^>?vv}}vv}r~ -rr}r}rrrrrrrrrv|^>>>>}}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuuxzzzrv??>+??vvvvvvvv}+  ?vv????vvvvvvv}>|+>>>>+>>>>>????????`<br></br>
`vvv???>>??vv???vv??>+>?vvvvvvvvvvv>`.^?????????????>||+>>>>+?????????????`<br></br>
.???????v?>+v???wxr>>?>?????????????>>??????????????||^>>>>>?vvvvvvvvvvvvv`<br></br>
.????????>>wr??>>?v|>??????????????????????????????+~||^++|>??????????????`<br></br>
.???????>>?z}??>>>^`>???????????????????????????????>++++++>??????????????`<br></br>
.>>>>>>>>>+>rrv~-?+`>>++>?????????????????????????????????????????????????`<br></br>
.>>>>>>>>+-~~~--~~-+>>>>>>??>>????>>>>>>>>>??????????????????>>>???????>>>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ..................................  . ................................... <br></br>
 ..................................|??>-.................................. <br></br>
                  .................................?zzzzz|................................. <br></br>
 ..............................```?wv?}r^~|~-`.`-`........................ <br></br>
                  ............................~|^^|~+~-^|^+++++~+??|.........`---~-.`...... <br></br>
 ........... ...............|++++++|^|||++++||-^~|?>->?>...|?v>>}?>r?`.... <br></br>
 .........+v?vv}v`.........`^++++++^+?+^++~-`......>?vwz?>~+>v>^?>?zwr~... <br></br>
 .......|}zzzzzz?........`^~~^|^|~^^||~^^-```````--+v?rwwv|>>+|~^>>vzzw}r>.<br></br>
 .......|vwwwwwz+.....``-??>>-`````~~`-~-```````>ww}vv?>>+>>>>>?+>>>???vv}~<br></br>
 `````..?wwwwwwzw?||.>rr>?+||-```|^--`-->?|```|vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwww??vv??zzwr+-`-->?+v?.-~..}wv>+^|>>>>>>>>>>>>>>>>>>>?>>+++>?-<br></br>
 `````->rwwwwwrv?????zzwzw}?+?zzww>     >wrww>^>>>>>>>++>>>>>>>>>?v>?vvv>^.<br></br>
 -````-vzwwwr}v??????}?v}wzzzwwwwz^  -. -zwzr|>>>?>?v?|^>>>>>>>>>w?}zzzzzr-<br></br>
.|^|~-+rrw}>?v??????>}?>?}zzwwwwr}- .}- .vwv?++>?w?rw^|>>>>>>>>>>xvwwwwwv?`<br></br>
.}w?vrr?vv>?}v??????>rrvv}rvr?v?}?` `z+  v^~^^||^^|vv~+>>>>>>>>>?xvzwwww}?`<br></br>
`wwwwww??vvv?????????????v^vz?|rzz` ~z?  }?^v}???->}+^>>>>>>>>>>vz?rr>+>+v~<br></br>
`www?}}vv????????????????r}w}v-vzr. +zv  ?^?^vzzr^+?|+>>>>>>>>>>v}~^^~~~|+`<br></br>
`rwr??vv}}???????vvvv????}v????}wr. -?? -+.?~+zw^~``^>>>>>>>>>>>>>|||||||^`<br></br>
`v^^?v?zyy}?????}uyyz????>^~~~?uyv. >v^.^|`|>rzzv^--^>>>>>>>>>>>>>||||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>>v??>+}}+. |?~`~|||+>v?>^|~^>>>>>>r+^>>>?}|||||||.<br></br>
.????v??v}v?>???????????>>??????^~` .~||||~~~~~~~~|~|+>>>>>x+|>>>>}}~|||||.<br></br>
`v??>?????vvvv}wzw}?????++>>>??????. |??vv??????????^^>>>>>x?++>>>?x>vvvvv-<br></br>
`}}}v?????zxxyyuyuzv???>^>?vv}}vv}r~ -rr}r}rrrrrrrrrv|^>>>>}}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuuxzzzrv??>+??vvvvvvvv}+  ?vv????vvvvvvv}>|+>>>>+>>>>>????????`<br></br>
`vvv???>>??vv???vv??>+>?vvvvvvvvvvv>`.^?????????????>||+>>>>+?????????????`<br></br>
.???????v?>+v???wxr>>?>?????????????>>??????????????||^>>>>>?vvvvvvvvvvvvv`<br></br>
.????????>>wr??>>?v|>??????????????????????????????+~||^++|>??????????????`<br></br>
.???????>>?z}??>>>^`>???????????????????????????????>++++++>??????????????`<br></br>
.>>>>>>>>>+>rrv~-?+`>>++>?????????????>????????????????????????>??????????`<br></br>
.>>>>>>>>+-~~~--~~-+>>>>>>??>>????>>>>>>>>>??????????????????>>>??????>>>>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`-`.................................... <br></br>
 ................................`?rww}|.................................. <br></br>
 ................................+zwwwz}````.............................. <br></br>
 ............................`````??^>v+^+++^|................```......... <br></br>
 ..........................`^+++^|~~~~~^+++^~|+`..........-^>>+>v-`~`..... <br></br>
 .........~|-|>?~..........-^+++>++^+>^++^~`..->+``.~?vv|.|+v?+>?+?zv`.... <br></br>
 .......`?wwrvrzw-........-`-|+?>|+^^>|^^-.....-|`..|>>rv~^>>+~|+>?rr}-... <br></br>
 ......`v}}ww}rr^........+?>?>+~``~|~`~~````.....`-|>?}}v++>++++^>>>?vvvv>.<br></br>
 .......|^?wwwzv. ......-?+?^-```-~--`-|^```````?r}v??>>>>>>>>>>>>>>>>>>>?`<br></br>
 `````.`~?wwwwrw}+``^~|>?>`---`-~-``~-.+v+~````~v?>>>>+>>>>>>>>>>>>>>>?>++`<br></br>
 ``````-?rwwwwv??v??v>rzwr+`--+}r?  `.  .v?+|~-~>>>>>>+^^+>>>>>>>>?+^?}}vv-<br></br>
 ``````?zwwzwwzv>?????r?vr}?+|}zzr.      ?rrr}}?^+++^^^++>>>>>>>>v}?}rwrww~<br></br>
 `````-vzwrv?v}v??????r??vwzwrwwww` .^   >w?v????>+>?|+>>>>>>>>>>wvvzwwwr}-<br></br>
.|^|--|rvv??v}v???????wrwwrwwrzwrv- -v  `?}>>+?vrwrr?^>>>>>>>>>>>w?}zwww}?.<br></br>
`rv>v?vr??}}}vv?????>?rrvvvww?v?w}| `?  ?}+>v?v}v?>?>^>>>>>>>>>>>z?rzwwrrr~<br></br>
`w}}}}rv?vvv??????????????v?r?-vzz> `v`^vw?^rr??v|vw>^>>>>>>>>>>>?>??+^^?}~<br></br>
`rwwv?vvv???????????????>}}v>|~vzz> .?`+vv?r?}wrr^?w>^>>>>>>>>>>>>>>>v^~^>`<br></br>
`v}rrvvrzr????????vvv??????>^|^?rr?  v?>}>?rvvwz>~`^^^>>>>>>>>++++>>?x+~|+`<br></br>
.+>v}}vryyv?????vxuuxv???>>????????. v?~+^-^+vwwv>``~^>>>>>>>|||^+>>w?~||^.<br></br>
.????v??zuwv???rzyyu}???v?vv???xur~. >>~||-~|+??||`~~^>>>>>w+~~|^>>??~||||.<br></br>
.^^++????v??????vvv?????>>?????vr>~. `|~||||~~~~~~~~~|>>>>?x^~~^+>>?+~||||.<br></br>
.??>|+v??v}v?vrwzwv?????+^+++>???>?^ |???????????????|>>>>rz>?^^^++>^?v???`<br></br>
`}}?+>???}xuuuyyuzw????>+???vvvv}}r> ~}v??vvv}}}}}}}v|+>>>}v?}v>?>???}rrrr~<br></br>
`vv?>>>>?vzzzzxzrrrv?>+^>?vvvvvvvv}> .?vvvv??vvvvv?v?~^>>>>+vvvvvv}}}vvvv}-<br></br>
`vvvvv??>>>????^`~}v+^>??>?vvvvvvvv>..>vvvvvvvvvvvvv?~^>>>>>vvvvvvvvvvvvvv-<br></br>
.??????>>++>>--`~~?^+++????????????>~~>?????????????+|^>>>+?????vvvvvvvvvv`<br></br>
.>>>>>>+>>>>--.`~~~|>>>>?>>>???????????????????>++++~||^+^^???????????????`<br></br>
.>>>>>>?????+>|-~~--????????????????????????????????>+++++>???????????????`<br></br>
.????????????>>^-~~->?>>>>>???????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>+++^~~^>>>>>>??>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ..................................   .................................... <br></br>
                  ..................................`-`.................................... <br></br>
 ................................`?rww}|.................................. <br></br>
 ................................+zwwwz}````.............................. <br></br>
 ............................`````??^>v+^+++^|................```......... <br></br>
 ..........................`^+++^^~~~~~^+++^~|+`..........-^>>+?v-`~`..... <br></br>
 .........~|~^??~..........-^+++>++^+>^++^~`..->+...~?vv|.|+v?+>?+vzv`.... <br></br>
 .......`vzzr?rzw-........---|+?>|+^^>|^^-.....-|`..~+>rv~^>>+~|+>?rr}-... <br></br>
 ......`v}}wwvrr|........+????+~``~|~`~~```......`-^>?}}v++>+>++^>>>?vvvv>.<br></br>
 .......|^?wwzzv. ......-?+?|-```-~--`-|^```````?r}v??>>>>>>>>>>>>>>>>>>>?`<br></br>
 `````.`~?wwwwrwv+``^-~>?>``--`-~-``~-.+v^-````-v?>>>>+>>>>>>>>>>>>>>>?>++`<br></br>
 ``````-?rwwwwv??v??v+}zwr+`--+}w?  `.  .??+|~-~>>>>>>+^^+>>>>>>>>?+^vwwvv-<br></br>
 ``````?zwwzwwzv>?????}?vr}?+|}zzr.      >rrrrr?^+++^^^++>>>>>>>>v}?}rwwzz~<br></br>
 `````-vzwr?>v}v??????w??vwzwrwwww` `+   >w????v?>+>?|+>>>>>>>>>>wvvzwww}}-<br></br>
.|^|~-|rvv??v}v???????wwwwrwwrzwrv- -v  -?}>>>vrwwrr?|>>>>>>>>>>>w?}zwww}?.<br></br>
`rv>v??w??}}}vv?????>>rrvvvww?v?w}| `?  vr+>v?vw}?>?+^>>>>>>>>>>>z?rzwwrrr~<br></br>
`w}}}}r}?vvv??????????????v?}?-vzz> `v`+rz?^rr??v|vw>^>>>>>>>>>>>?>??+^^?}~<br></br>
`rwwv?v}v???????????????>}}?>|~vzz? .?`>wv?rv}wrr^?w>^>>>>>>>>>>>>>>>v^~^>`<br></br>
`v}rrvvrzr????????vvv??????+|-|>rrv  vv?w>?r}vwz>~`^^^>>>>>>>>++++>>?x+~|+`<br></br>
.+>v}}vryyv?????vxuuxv???>>?????>?}. v?|+^-^+vwwv>``~^>>>>>>>|||^+>>w?~||^.<br></br>
.????v??zuwv???rzyyu}???v?vv???xur^. >>~||-~|+??||`~~^>>>>>w+~~|^>>??~||||.<br></br>
.^^++????v??????vvv?????>>?????vw>~` `|~||||~~~~~~~~~|>>>>?x^~~^+>>?>~||||.<br></br>
.??>|+v??v}v?vrwzwv?????+^+++>???>?+ |???????????????|>>>>rz>?^|^+++^?v???`<br></br>
`}}?^>???}xuuuyyuzw????>+???vvvv}}r> ~}v??vvvv}}}}}}v|+>>>}v?rv???>??}rrrr~<br></br>
`vv?+++>?vzzzzxzrrrv?>+^>?vvvvvvvv}> .?vvvv???vvvv?v?~^>>>>+vv}}}}}}}}}}}}-<br></br>
`vvvvvv?>>>????^`~}v+^>??>>vvvvvvvv>..>vvvvvvvvvvvvv?~^>>>>>vvvvvvvvvvvvvv-<br></br>
.??????>+++>+--`~~>^+++????????????>~~>?????????????+|^>>>+?????vvvvvvvvvv`<br></br>
.>>>>>>+>>>>`-.`~~-~>>>>?>>>???????????????????>++++~||^+^^???????????????`<br></br>
.>>>>>>?????^>|-~~--????????????????????????????????>+++++>???????????????`<br></br>
.?????????>>>++^-~~->?????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>^~~^>>>>>???>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .................................`````................................... <br></br>
                  .................................-```-`.................................. <br></br>
                  ..............................`````.```--``.............................. <br></br>
 ..........`^>??^`..........`---```..``-~-``..`......`~~`.`-^^^++|`....... <br></br>
 ..........|>>vrv~..........````````.```-``...`-`...|vvv^.-+>v?>??>?^`.....<br></br>
 ........```~??v>..............`..``-`..`......```..`~+?>-|+>>+|+>>}wr~....<br></br>
 .......````>vvw~.........```......>v?>~.......`...~?vvv?^+>>+^||+>+>v}v?+.<br></br>
 .........^}v>?v|. .-|-..``````-``|}}}}r|-~~~`~^?^~??>>+++>>>>>>>>>>>>>>?v-<br></br>
 ````..`~?wzw}wwv?^?vvv>^+^~|^^+^||?????+++++|`>?++?+++++++>>>>>>>>>>>>>>?`<br></br>
 ``````?rwwwwzrvvvrrv??>?v>-|^+>>+-`````~+^^|-|>?>^++++^^>>>>>>>>>>>++>>>?~<br></br>
 `````-vw}??vr}???v}v?>+vvv?^~^^^+~`.``-~|~~^v}vvv>|^?+^>>>>>>>>>>?>>??>+>`<br></br>
 -```+v?>?vvvvv????}vvr???wzw}???^-`--```~v?>??vrvv?v?|>>>>>>>>>>vv?wwzwv+.<br></br>
.~+??vvvvrr}vv?????v}}wvvrwwwwzzz}-.`-..-vz}>?}rwzwwr++>>>>>>>>>>x?}wrwww}`<br></br>
.?}rwrvrv}r}v???????vv}v}rv}r>vvv}?..~. |^~-`>}?>?v}v|>>>>>>>>>>vz^>???v}w~<br></br>
.?v}ww?vv}vv????????????v??}w?`vwz?  .  .   `??>+|>v>^>>>>>>>>>>}v+?vvv}}w~<br></br>
.??rw}?vvv??????????????vrrr}?-vz?. `>>?v. ^|?vvv^+v^^>>>>>>>>>>r????r}v}r-<br></br>
`}r}vvvvvv???????vvvv???vv?>??v}}..|?wr}~ ->-?zw|``~|+>>>>>>>>>>r?+^+v+^?v-<br></br>
`vr}vvvwuxv?????ruyyz????>+^++vxu>v??v+. `|||vzw^|~~|+>>>>>>+^+>}?||?^~|^^.<br></br>
.?vvvv?ruywv???rwxzrv????????>>}>`+|~^`.`|~|+?v>|~~||+>>>>>+^+>+vv^^+~||||.<br></br>
.>???v??}r}v????????????>>???v??^ .`.~``|~|~~~~~~~-~~^>>>+^+|^>+?}^^|~||||.<br></br>
.?????v????v?v}rww}?????++>>>????^ .-+>>????????????>^>>>>v?|^>+?}>??vvvvv-<br></br>
`vv?>>????}xuuyuuxz}???>^>??vv}}}r| .+r}vv}}}}}}}}}r}?vvvvrv^^>>>?>vvrrrrr~<br></br>
`vvv??????}uuuuxxxw}??>^>?vvvvvvv}v- `vvvvvvvvvvvvvvv???????^|>>>z>v}}}vv}-<br></br>
.?????v????vvv??>????>++>>?????vvvv^.~?vvvv?????vvvv?>??????>|^>>v>?vvvvvv-<br></br>
.???????>?>?>+++^^>>>??????????????>^+??????????????>^+++++?>~^>>>+>??????`<br></br>
.???????????>-`-|^v>>???????????????????????????>>>?^^^^++>?>|^>>>++??????`<br></br>
.????????>??|~-`~+r>?????????????????????????????????>>>>>>?>~|||||^??????`<br></br>
.>??????????^~---|v+??>>>??????????>>>?????>>>>>>>>>>???>>>>>>++>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>|----~+>>>>>>>?????>>>>>>>>>>>>>>>>>>>??????????????>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>+~--`|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .......................................`................................. <br></br>
 ..........-?v}}>`....................````...........`|^`..`|^^^|^`....... <br></br>
 ..........^??vww|.............................`....^vvv^.`^>}?>vv>>~`.....<br></br>
 ...........`>>?^..................`-..........```...~^+^`~+>>>|+>>vzw~....<br></br>
 ...........^?vw| .................?wv?|.......``..-?}vv?^+>>+^||+>+?v}}?+`<br></br>
 .........|v?|>?>` .~>~.....``--`.^wwzzz~~|||`~+?-`??>>+++>>>>>?>>>>++>?vr~<br></br>
 ````..`~vwzrvww}v>vwzw+|+>|^++++^+vv?vv^++++^-??+>v+^^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzzwwzwv??wz}v?+?v?~|++++++|~|||++^||-^?r?|^^^+|^>>>>>>>>>>>+++>>v~<br></br>
 `````~vrv>>?rv???vw}?+^?vv?^~|||++^~+^^+^-`|v}rww?+>r?^>>>>>>>>>>??+>?+^+`<br></br>
 -```>r?^+?vvv?????wwr}??>rzw}??>~|^|~|^~|??>?v}wwwv}v|>>>>>>>>>>?v?rwzwv^.<br></br>
.~+vvrw?}wwrv?????>}zwwv?}wwwwzzz}|--``-|vzw+vwrwzzzz>^>>>>>>>>>?x?}wrrwwr-<br></br>
.+rzzw}rwwrv??????>?w}vv}wvv}>?v?}w-.-`.||~-.>}?+?}w}|>>>>>>>>>>}z|^+^+?vw~<br></br>
.+?}zw??v}vv????????v???r??rw?`vwwv. .      .>>+||+v>^>>>>>>>>>>z}>vr}}www~<br></br>
.^+}zw??vv??????????????rrrww?-vw>. -?vv?. |~>?v?^^?^^>>>>>>>>>>zvvwrwwwrw~<br></br>
`vr}vvvv?????????v}}}???v}????}w}  >wzzr- `|.?zw~``-|+>>>>>>>>>>zv>^|+>>?r~<br></br>
`rw}vvvwxzv?????wyyyw????+|~||vyy>vzw?^. `||~}zr|||||+>>>>>>^^>>zv~||~~|++.<br></br>
.?vr}v?ryywv???rwzw}????>>??+^^v+`>??^..`|||>?v>|||||+>>>>>||+>>w}||||||~|.<br></br>
.>?v?}??rwrv????????????>??vvv??~  ~^^`.^~||~~~~~~~||^>>>+~~|+>>}z||||||||.<br></br>
.????vv???????vrrr}?????>>?>>????| .?>+>v??????????>>+>>???+|^>>?z>v}vvvvv-<br></br>
`vv???v???vzxuyuuxxw>??>^+??v}}rrr~ .?rr}}}}}rrrrrrrr}}rrrrv^^>>>v>}rrrrrr~<br></br>
`vvvv?v???vuuuuuuuzr???+>?vvvvvvv}?- ~}vvvv}}}}}vvvvv}}}}}}v^^+>>z>v}}}}}}-<br></br>
.???>?v???v}}v???v???>+^+>??????vv?|.^vvvv??????vvvvvvvvvvvv>|^>>v>?vvvvvv-<br></br>
.???????>????>>?>>??>??????????????>>?????>>>>>>????????????+|^>>>+>??????`<br></br>
.????????????~~+>>r?>???????????????????????????????????????>|^>>>>+?>>>??`<br></br>
.????????>??|---^?x???>?????????????????????????????????????>~|^^^|^??????`<br></br>
.>??>????>??||~~-^r???>>????????????????????????????????>>>>>>++>>>>??????`<br></br>
.>>>>>>>>>>>>|~~~~|+>>>>>>>?????>>>>>>>>>>>>>???????????????????????????>>`<br></br>
.>>>>>>>>>>>>>+--~-~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............~^^.......................................................... <br></br>
 ..........-?vrw>...............``.....```...........~+>`...`--~--........ <br></br>
 ..........-||?}?`.................`~~-.``.....`....->??|`.~^>>+>?+|~`...`.<br></br>
 ...........-+~v?~.................`+++`.......```..`~^+|``^>>?++?>?zw~..|`<br></br>
 ..........`-~->>-.................-^>^`...........`vrr}v+|+>++|~+>+?vr}??`<br></br>
 .........^vv>??>-.~>>-......````.`^?}++--~~~|??+``?>>>+^^+>>+^|^^>>^+?vvw~<br></br>
 ```...~?vwzw}wwr}?vv}?-``-`~||||~~++^+^||^||+?vv>+?>+++++>>>>>>>>>>>>>>?v-<br></br>
 ``````vzzwwwwrvv}wrv?>|^>>~~|||^+^+????>>+||`|??v?++^+++>>>>>>>>>>>>>>>>?`<br></br>
 `````^vv?>?}rv??vrw}v?v???+-|^^++>?vr}v>^^^^^+>+?>|~^^^+>>>>>>>>>v?^^++>>`<br></br>
 --~~+v>>?}r}v????vwwww???v?+++++^^+>+>+^^+++~?v>+++??^^>>>>>>>>>vv>v??>++.<br></br>
.~+}rww}rwwrv??????rrww??vwz?+++++^~`.`~^+^|->}}rwvvr?^>>>>>>>>>?u??}}rrv>.<br></br>
.^vwzwrwwwrv??????>>?vv??}}vr>>+^|+~`-``~-``->???vv}}^+>>>>>>>>>rz^????vr}-<br></br>
.^>?vvvv}}}v????????v???}v+}w>~vwv^......-..+}vv>-+}?|>>>>>>>>>>z}>?rwwwwr-<br></br>
.+>?r??vvv?????????????vrr}ww?~vzv^..~-`?~.~~?v}?-~v+^>>>>>>>>>>xv?v?rwwrw~<br></br>
`rwrr?vv?????????v}r}???v??v}rrzr~`......  . .vw|^-^^^>>>>>>>>>>xv++>>?v}w~<br></br>
`wz}v?v}r}v?????zyyyr???>??vvvzy?.`^>|-...  `?z}|+~~^^+>>>>>++>>xv~~~|^>?>`<br></br>
`v}rrvvzyywv???rwzw}????>?vvvvvr|`>vv^-`. .~?vv?+~~~~|++>>>+>++>w}~||||~||.<br></br>
.+?vvv?vwzrv????????????>??????^`..|^|`  .~|~||~~~-~~|^^+>++v?>>??^|||||||.<br></br>
.????v????????v}rr}?????+>>????^. .-??-`|?????????>~|~~|^+>>>>>>>?>vvvvvvv-<br></br>
.??>>vv???vzxuyuuxzr???>+>?vv}}}?-  `v?|?rrrrrrrrr}?++^^^^++^^>>>?>vrrrrrr~<br></br>
`?????v???}uuyyuuuxw???+>???vvvvvv+` ^}?vvvvvvvvvvv}vvvvv??^|+>>?z>?vvvvvv-<br></br>
.?????????vwzrvvvv???>++>>>??vvvvv?|.^v???????????????????>~^++>?v+???????`<br></br>
.???????vrv??????>>>???????????????>??????????????????????|-|++??^+???????`<br></br>
.???????vr}v+-~+>+>}v?????????????????????????????????????|~|^+>>^^>??????`<br></br>
.????????+^?~``-^>?vw?????????????????????????????????????+^^~~~~~|+??????`<br></br>
.>>>>>>>>^~-`-~~`~++v?-^>>???????????????????????????????>>?>>++>>>>??????`<br></br>
.>>>>>>>>>^-|^|~~-^~```~>>>?????>>>>>>>>>>>>>??????????????????????????>>>`<br></br>
.>>>>>>>>^~^>>>~~-+-`-~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............  .......................................................... <br></br>
                  ...........`^??`......................................................... <br></br>
 ..........-vv}z?...............``.....```...........^??-......`.......... <br></br>
 ...........--+v^..................`>+|```..........`^++~`.`~+>>+>+`~`...~`<br></br>
 ...........|+-}}+.................-vv}~.......```..`^>^~`.~+>r?+v??zw~..?~<br></br>
 ..........-```|?>` .................~~............`}zzzr?~^>>>^~+>^>vrrv?-<br></br>
 .........+vv???>~.+??`......```....|?`..````>vv~`-???v?>^+>>>+^+^>++?rzzz~<br></br>
 ``....^vvzzzwzrwrvv?v+.....````````````-|~`-?rr}>>>>>>>>>>>>>>>?>>>>>??v}-<br></br>
 ``````?zwwrww}?vzw}v?+~-~^~---~+>+?v}v?}}?|~-+?>rv>>++>>>>>>>>>>>>>>>>>>>.<br></br>
 ````->}?+>?}}v??rww}v}r???+-^^+>>?wzwzx?^+++|~^^??^^|||^>>>>>>>>>v?^^+>>>`<br></br>
.--|^^>>?vwwvv????zwzzzv?vv>|^++++^?v?v?^++++~>v+||^>?>|+>>>>>>>>vv>??>++>`<br></br>
.~+rzwwwzzw}v????>}wrww?vvwz?||^^++|-|~|+^~~->vvrr??v}|+>>>>>>>>?y?+v}}r?^.<br></br>
.|>rwzwwww}v??????+>+?v??vrvr>>^|~^^~||^^~`^?>^>?}vvw>|>>>>>>>>>wx+?v??}z}`<br></br>
.^|^>vrrr}}v??????>?????}v|vw+~}wv~~~`-~|v|+ww}}?->r}|+>>>>>>>>>x}?v}zzwr}-<br></br>
.>>>}??vvvv???????????>}w}v}w?|vzzr~.`..??|^|?rr?`~}v|+>>>>>>>>?uv??>}zwwr-<br></br>
`wwwz??v?????????vrr}??vv?>}rrwzwv^.          ?r|>~?>|+>>>>>>>>?uv^>???rww~<br></br>
`ww}v?vv}v??????xyyy}???+?rwr}uy? .~>|-~-.  -vwv+?|^>|+>>>>>>+>?uv~~~|+?v?`<br></br>
`}}vvvvxyyr????rwzwv????>?vvv}}r+|?v?^^|` .~?}}v?||||~|+>>>>v>+>wv~||||~||.<br></br>
.^+v?v?vxur}????????????>?????>^~|^^||`  .~|~||||~|~~|~|+>>>zw+>>>^||||~||.<br></br>
.+?vv}????????v}}}}?????++>>???|  -?v?- -??????????-||||^+>>??>>>>>?vvv??v-<br></br>
.??>>v????vrxuuuuxzr???>^?vv}}}v>. .^r>.^rrrrrrrrr}>+^^||^^^^^>>>>>vrrrrrr~<br></br>
.???>?v???vuuuyyyyuzv??+>????vv?vv^- >v??v????vvvvv}vvv????||+>>?z+???????`<br></br>
`vvvv?????vzxzrvv}v??>+>>>>>?vvvvv?~`+?????>>?????????????+|^>>?zv>???????`<br></br>
.??????>rzv???>>??>>?>???vv???????????????????????????????^|^>>w}>vvvvvvvv`<br></br>
.???>???vwzv^-|+++^wv+>???????????????????????????????????||^++>^>????????`<br></br>
.????????+^?~.|++++vw?????????????????????????????????????+^|^||^>????????`<br></br>
.?>>>>>>>|~~-~?>+^~+v?-^>>??????????????????????????????>>??>>>>?>????????`<br></br>
.>>>>>>>>++-|>>>>^>~-~-->>??????>>>>>>>>>>>>>>????????????????>>>>???????>`<br></br>
.>>>>>>>>|-^>>>>>|?|~-~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ...........^?vv`......................................................... <br></br>
 ..........`>?vr+..............```.....``............+?>-...-~|~~|-....... <br></br>
 ...........`~~|-..................`~--```...........~~~-..-^+?>+?>-~`...^`<br></br>
 ...........`-.>}>`................`||^`.......```..`>?>~``|>>?+^?>?zr`.`v~<br></br>
 ..........|-~-~^|. .................``.........```-vr}v?+|+>+^|~^>^?r}}vv-<br></br>
 .......``>vr}}v>~~??~.......```....`|...```|?????>>?>>>>+>>>>+^^^>+^>vv}w~<br></br>
 ``....+}rzzzwrvwwvvvv>.....```````````-^+~`|vv?>++>>>>>>>>>>>>>>>>>>>>>>?-<br></br>
 `````-vw}v}w}v?vrvv?>>~--|--~~~^+^>?vvvw}?+^|~^+v?>++++>>>>>>>>>>>>++>>>>`<br></br>
 `````+?>>?v}v???}zrvvv}>??++>++??vrwwzzv>>>+|~++v?>++|^+>>>>>>>>?v>++++++.<br></br>
.--~^>>>vrwrv?????rrzzz}vv?>^^+>>++?}v}v>>>+^->v+^>>??^^>>>>>>>>>v?>vv??>>`<br></br>
.-|}zwwwzwrv?????>?v}r}vv?v}>-|^^+++?v??>^^^|^??vvvvrv^>>>>>>>>>?u?>v}wwv>`<br></br>
.|+vvvvrwr}v??????>??????v}??|^^^^^>?v??^^|^+~>v???v}^+>>>>>>>>>wx>vv}}rw}-<br></br>
.>+^+?v}rvvv????????????vv?}r>+>>+^~|-~~^+~~+?rrv~+??|^>>>>>>>>>u}>?v}}r}}-<br></br>
.???v??vv????????vv}}??vvv}ww}vz}>>~.```^|`^+vr}?`|v+|+>>>??>>>?yv>v?wrwwr`<br></br>
`}www??vvv??????vzzxr??v???v}rzxr?~........`..vw^?|?+|+>>>>?>>>?u?^??v}ww}`<br></br>
`ww}vvvvzzr????vwxzwv???>>????}}>.````..-`...>vv|v??>|^>>>>>>>>>v>-~^>????`<br></br>
`}v}vvv}xuwv???vv}vv?????????>^^`.``.....  ..``>+^+?||^+>>>>>>+>?>-~||||~|.<br></br>
.^>?vv?v}}vv???vv}v??????>?^-``..`--~~`...`..`~||~||~||^+>>+??>>>>~|^|||||.<br></br>
.+>?vvv????vv}wzwzr}v???>+>+^~.. .|?v?^~`.`+???????|||||^+++>>>>>>>v}vvvvv-<br></br>
.???>??????ruuyyuuxw???>^>??vv?>-  .+r>..|vvv}}}}r}?>+^^^^^^++>>>>>vr}}}rr~<br></br>
`??????>??vwxxzwrrr}??>^|??vvvvvv?|` ?>|>?v??vvvvvvvvvv??+||+>>>?r>?vvvvvv-<br></br>
`vvvv?+~+??}rvv??v???>>?~->?vvvvvv>~`??+>??????????????v>~~^+>>>wv?v??????`<br></br>
.?????+->??v??>>>>>>>>?v^++?????????????????????????????^|^+>?>}v?vvvvvvvv`<br></br>
.???>>>-^???|~^>+++??>>?>>^?????????????????????????????~~^++>++^>????????`<br></br>
.??????+-~|+~`+>>>>?v???????????????????????????????????+^^^^^^^+>????????`<br></br>
.>>>>>>>|~~~`^?>>>^+v>~+>>?????????>>>>>>>>>>>>>>>>>>>>>?????????>>>>>>>>>`<br></br>
.>>>>>>>>+|`|>>>>++~---|>>??????>>>>>>>>>>>???>>???????>?????>>>>>>>>>>>?>`<br></br>
.>>>>>>>>^~^>>>>>^>~-~|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............-........................................................... <br></br>
 ...........?}w}`......................................................... <br></br>
 ...........^+?v^..............```....```...........`>?^~..`|>>++?|....... <br></br>
 ...........`|`.-`......................``...........````..~+>}?+v>~~`..`+`<br></br>
 ..........`.. ~v?~...........................```...`?v?-``^>>+^^>+vz}`.^}~<br></br>
 ........ .+|^|~-`..............................`~|~?vv?+^^>>++^^^>^vwv?v}~<br></br>
 .......||>vwzz}>|?v>.........`..........```+?>?rr}??>>>>>>>>>>>>>>>^>?vvr~<br></br>
 ``....+rzzzwwv?ww}vr}?`....```````````->>~`^vv?>>>>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````|v}v?vrv??v}?>>>>~``--~^^~~|~|^>?}z}vv?++++???>+^^>>>>>>>>>>>>||>>+>.<br></br>
 `````^+>??vvv???}wvvvv?^???}rvvwwrr}rwwwwrv?^^?}zwwww^|>>>>>>>>>?r+??^^|^.<br></br>
.---+?+>}ww}v?????v}wzzzw}?>?vvvv??vrwwwzwv?^+???vwwz}|+>>>>>>>>?v??wwwwv?`<br></br>
.-~vzzwwzw}v?????>~+v}?}v??v>~|^^+?vwwwwv++++|?+??vwz?+>>>>>>>>>vu???}wwzz~<br></br>
.~|+?++}w}vv??????>????v?vv?>~++++^}zwww>++++~?w>?>vv|>>>>>>>>>>wz>r}wzwrr~<br></br>
.?>|^??v}vv????????????vvvvrr??>^++^?>>+^^||~?wwv|^>+|^+>>>>>>>>u}+?vvv}vv-<br></br>
`v}v???v????????vrwzzv????rwrrxuv|^^-|-|^-`>?}w}?~^v||^>??w}>>>?y?+vww}rw}`<br></br>
.?wzw??vrrv?????wyyyw???>vvvv}zx}v||~`-~~|vw+?zw>v^?^|^>>>?w>>>?x?|vvrzww?.<br></br>
`}rvvvvryyuv???vrr}v?????+?????>?vv~.`.`~|>?~?vv|r}}>|^+>>>>>>>>?>-~+vv???`<br></br>
`??v??v?rxw}?????????????+++^-.....            ^|^>?^|^>>>+^>>>>>+-~||||~|.<br></br>
.+>>v}v???????v}zxzv>????^^`   .```---~~~-.  .-||~||~|^+>>+^>>>>>^~^^|||^^.<br></br>
.|^>v?v????}xuuyxxwxr???>+>^~`  `^?vvvv?|  |?v??????^|||^+^|>>>>>?v}}vvvv}-<br></br>
.???>+?v???}xyyyuuxz}??>^>?????^` .~>r?. `?vv}}}}}}}v?>>>+|^>>>>+v}}}}}}}r~<br></br>
`?????+^???vww}v??v???++~>v}}}}}v?|`.v|.+vv??vvvvvv?vvvvv^|^>>>>?vvvvvvvvv-<br></br>
`vvvv?--~>?v???????>>+?r|-+vvvvvvv>~|v?|>vvvvvvvvvvvvvvv?~|+>>>>vvvvvvvvvv`<br></br>
.????+~~~-|w+^+^++++>??}~^|????????????v????????????????||+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~->^>>++++++>>>>^->???????????????????>+++>>??>~|+>>v>???????????`<br></br>
.>>?>>>|-~~~>?????>>+^???>>?????????????????????????????+^^^^^+???????????`<br></br>
.>??????|-~-~???>???>>>>??????????>>>>>>>>>>>>>>>>>>>>>>???>>???>>>>>>>>>>.<br></br>
.>>>>>>>>+||+>>>>>>>>>>>??????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............``........................................................... <br></br>
 ...........`^>|.......................................................... <br></br>
 ...........|^>+.......................................................... <br></br>
 ...........--~^`..............```....```............-~``..`|++^+>-....... <br></br>
 ...........``..``.....................```...........-~~`.`|>?v>?v+^|`..`-.<br></br>
 ..........`...~^|`...........................```...~}r}+`-+>>+|^>+vz}`.-+`<br></br>
 .........|?????+..............................`~|~->vv??^+>>^^||^>?rw}}v}~<br></br>
 .......^?v}wzzw?`-|~`..................````|^>vr}???>>>>>>>>>>>>>++>?vv}w~<br></br>
 ``....-vzzzwwrvrv>?vrv^....``````--``|?v?~`~?}?>>>>>>>>>>>>>>>>>>+++>?>>?-<br></br>
 `````~?v?vrw}vvv?vvvvv?|```-^?>+???>v}rz}?>>+>+^+++++++>>>>>>>>>>>+^|+++>`<br></br>
 `````~^>v}r}v???v}r}}r}?vv?vw}vvvv}wwwwwwr}}?^+?rrv>>^+>>>>>>>>>?}+++^^|^.<br></br>
.-``~^|>}wwrv??????rwzzzwr}vvvv}}v}rwwwwzw}?+>???}zzw?|+>>>>>>>>>v?>rwr}vv-<br></br>
 ~+?vvrwwwrv??????+?rv?v}v??>~|+>?v}wwzwv++^+|?>??vzz?^>>>>>>>>>?u?v}wwwzz~<br></br>
.?vv}v?rwr}v????????v???>?v?>~++++^}zwwz>++++~vv+?>rv|+>>>>>>>>>vx>}rwww}v`<br></br>
`vvv?v?v}vv?????????????vvvww?++^+++???>^+^|~+vv?^^>||+>>>>>>>>>rw^?}v???v-<br></br>
`rwr}v?vv????????vvv}v???vrwwwv?>|^^-|-|^-`>>vvv?||?|^+>>>??>>>>zv^?vv??v?`<br></br>
`}ww}vvvvvv?????vwzzr????vvvvvwzrv^|~`-~~~+v+?zw+?~^|^+>>>>?>>>>zv~>??vv?>`<br></br>
`}rvvvvruuw????vwxzwv????+>>>>?}r}}~.`..~~^+-?vv^??>+^+>>>>>>>>>?>-~^>?>>+.<br></br>
`?>????vxuwv???vv}vv?????>>>^-`|-..            ^|^+?|^+>>>>?>>>??+-~||||~|.<br></br>
.>??vv??vvvv???vv}v??????>?+~-`--``----~~-.  .~||~||~^+>>>>?>>>>+|~|^||||^.<br></br>
.>>??vv????v}rwzzzr}v???>+?>^~...^?vv???~ .^?v??????+|++++>?>>>>+?v}}vvvv}-<br></br>
`????>????vwuuyyuuuzv??>^+??vv?+` .->r?. -?}v}}}}}}}}>^++++>>>>>>vr}}}}}}r~<br></br>
`vv????>??vwxxzrrr}v??>^|?vvvvv}v?|`.v|.>vvvvvvvvvvvv>|^+++++>>>?vvvvvvvv}-<br></br>
`?????+-^??vvv???????>>?-`>?vvvvvv>||v?^>vv???vvvvvvv?^^+++>+++?vvvvvvvvvv-<br></br>
.????>^--|+}>>>>>>>>??+>|^^???????????????????????????||^+>>>?????????????`<br></br>
.?????~-~^~v+>??>+>?>||~>>^?????????????????????>>>>?>~~^++>+?+???????????`<br></br>
.?????~---|?>???>??>|+^^??????????????????????????????|~~~||^^>???????????`<br></br>
.>????^---|+^??>>???>~^>???????????>>>>>>>>>>>>>>>>>>>>++++>????>>>>>>>>>>`<br></br>
.>>>>>>|----+>>>>>>>>>>>>????>>>>>>>>>>>>>>???>>???>?>????????>>>>>>>>>??>`<br></br>
.>>>>>>>|---+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............^+`.......................................................... <br></br>
                  ..........-`vw?.`........................................................ <br></br>
                  .............-........................................................... <br></br>
                  ..............................```....```..................`|^^^++`....... <br></br>
 ......................................```...........~+^`.`^>}?+vv^?+`.... <br></br>
 ..........```~~..............................```...|zzx?`~+>>>|+>>rzr-`.. <br></br>
 ........~vwrrwzv..............................-^+~-+vvvv++>>++|^+>?zzwr}}~<br></br>
 .......?wzwwwwz?.. .-`................```````>rw}??v>>>>>>>>>??>+^^?}rwzz~<br></br>
 ``.....vzwwwwww}?~^?zw?`...`````-|~``>rwv-``?}?>>>>>>>>>>>>>>>>>+^^^?v??}~<br></br>
 ``````^??}wwwrvv?}zzwwz>````^???}wr?}zzzr^~+>>+^^^^+>>>>>>>>>>>>>>+^|^++>`<br></br>
 `````~^>}wwwrv???vwwwwwwrv??wwv???vzzwwwwrwzv+|>}}?^|+>>>>>>>>>>?v+^^^||+`<br></br>
.`````-?rzwwrv?????wwwzzzzw}}}rr}}wzwwwwzwrv????vwzzr?~^>>>>>>>>>}v>}rr}vr~<br></br>
 |>>+>vwwww}v?????>}zv?v}}?v?||>??}rwwwwv>+^^^?>??rzz?|+>>>>>>>>>uvvzwwwww~<br></br>
.}zzzw}rww}v???????vwv>??????~^+++^vzwwz+++++~v?+??rv|+>>>>>>>>>?x?}zwwrv>.<br></br>
`wzwww??vvvv????????vv?????ww>~^^+++v??>^+^|~|>>|^+>|^>>>>>>>>>>vz+vw?^^^?`<br></br>
`wzzzz??vv?????????????>?}wwwr~~||^^-|-|^~`+^??v?^~+|+>>>>>>>>>>r}~^^||||+`<br></br>
`wz}vvvv?????????v}}}???v}v?vvvrw}^|~`-|~|->^vzw>+``|+>>>>>>>>>>zv~||||||^`<br></br>
`}vvv?vwxzv?????wyyyw????+|~^^vyyr}~.`..~~~~`?}r++~~|+>>>>??+>>>v?~|||||||.<br></br>
`>^+>v?ryywv???rwzw}????>>??+~~?+..           .+||||~^>>>>vr+>>vv|~|||||||.<br></br>
.>?vvv??rw}v????????????>??vvv?>~``----~~`.  `~~||||~^>>>>?x++++|~||||||||.<br></br>
.????v????????vrwr}?????>>?>>?^..|?vv>>?~ .>v????????|+>>>vu>????vvv}vvvvv-<br></br>
`vv?>?v???vzxuyuuxxw???>^+??v}v>- .`+r?. |vrrrrrrrrrr+^>>>?}>}rrrrr}}}}}rr~<br></br>
`vvvv?????vuuuuuuuzr??>++vvvvvvv}?+`.v|`>vvv}}}}v}}}}>|+>>>>>v}vvvvv}}}}}}-<br></br>
.?????????v}}v???v???>^-++?????vvv?^^v?+>v??????vvvvv?|^>>>>+vvvvvvvvvvvvv-<br></br>
.???????>?>v?>???>>?v?^-?>>????????vv??????>>>>>?????>||+>>>+>>>>>>?>>>>??`<br></br>
.?????~+?v?w+>???+?v?+^~?????????????????????????????>||^+>>+??????>>>>>>?`<br></br>
.?????--~^?w????>???|?^|?????????????????????????????>~|||^|+?????????????`<br></br>
.>????|-~-+v>??>>???>-~>????????????????????????????>?>+++++??????????????`<br></br>
.>>>>>>~~~~~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????????????????>>>>>??>???>>`<br></br>
.>>>>>>>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............`-........................................................... <br></br>
                  ..........`.|^~.......................................................... <br></br>
                  ......................................................................... <br></br>
                  ..............................```....```..................`-~||^|........ <br></br>
 ......................................```..........`^>+..`|>}?>}v+v>`.... <br></br>
 .........-^|++>^.............................```...|rzz?~~+>?+|>>>rzr~... <br></br>
 .......`>}wwwzz?..............................`~^~-+vv}}>^>>+|-~+>vzzwrr?`<br></br>
 .......+}wwwwwz>....``................--`````^?v}?vv?>>>+>>>+>>>+++??vvvr~<br></br>
 ``.....?zwwwwwwr?|||?rv+...`````-^+|^vwwv-``^??>>>>>>>>>>>>>>>>>>+++>>>>>`<br></br>
 ``````|vvrwwwwvv?vv}zzzv|```|??>vww}rzwz}^~^+^+++++>>>>>>>>>>>>>>>++^++>>`<br></br>
 `````-+vrwwww}v??vvvzwzwwv?>vzrvv}rzzwwww}rz?^+>>>>+++>>>>>>>>>>?v++>>+^^.<br></br>
.`````-?wwwwrvv????vvw}rrwwr}}rwwrwwwwwwzzrv?|^^+??v}?~^>>>>>>>>>}v?rwrr}r~<br></br>
 |+^|^?wwwrvvv???????}v?v}?v?||>?vrrwwww}>+^^~|^>}vwz>|+>>>>>>>>>xv}zwwwr}-<br></br>
.}w}}rrvvrv?v????????}v??????|^+++^vzwwz+++++~+^|^^}v|+>>>>>>>>>?x?rzwwwv?`<br></br>
`wzwzzv?vvvv??????????????>}w>~^++++v???^+^|~>??>~>?^^>>>>>>>>>>vz>}r?+++?-<br></br>
`wzw}rv?vv???????????????}rwwr-~||^^-|-|^~`+~?}rv^|?|+>>>>>>>>>>}}~^^||||+`<br></br>
`wwr??vvvv???????vv}}???v}v?vvv}w}^|~`-|~~`?|?zw+|``|+>>>>>>>>>>??~||||||^`<br></br>
`v??vvvzuu}?????ryyyw????>^~||?yyr}|.`..~-`--?}w?^--|>>>>>>>>>>>?>~|||||||.<br></br>
`>???v?ryyz}???rwxzr????>?v?>|~v?`.           .>^^|~|+>>>>?}++>>>>?|||||||.<br></br>
.????v??}r}v????????????>??????>~``----~~`.  `~~~||~~+>>>>?x+^>>++?v~|||||.<br></br>
`??????????v?v}rwr}?????+>?>??|..|?vv???~ .>????????+^>>>>?x?>+>>>>z?vvvvv-<br></br>
`vvv??v???rxuuyyyxxr???>^>?vv}v>-..`^r?. |}rrrrrrrrrv^^>>>>}?+>>>>vr}r}rrr~<br></br>
`vvvvv????ruuuxxxxw}??>+?vvvvvvvvv+-.v|`>vvvvvvvvvvv}>|+>>>>+?????vvvvvvvv-<br></br>
.?????????vvvv??vv??>>++???vvvvvvv?^^??+>??????????v?^|^>>>>+?vvvv?vv????v`<br></br>
.???????????v???vv????|~???????????vv???????????????|~^+>>>+>?????????????`<br></br>
.?????^>???w?>??>>?>>>^|???????????????????????????>~~|^+++^+?????????????`<br></br>
.?????~~|+?zv???>>+~|>^^????????????????????????????>+|~~~~~>?????????????`<br></br>
.>>>>>^---^vvv?||?^|>~|+>?????????????????????????????>++++>??????????????`<br></br>
.>>>>>>~--`~~~--|~-+>>>>>>>>>>>>>>>>>>>>>>>??????????????????>>>>>??>???>>`<br></br>
.>>>>>>>|---^++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ..............................```.....``...................``--~-........ <br></br>
 ......................................```..........`>?>...|?v>>}?>r?`.... <br></br>
 ........`+v?vv}v`............................```...~vwz?>~+>?+^?>?wzr~... <br></br>
 .......^vwzzzzz?...............................`-~~>?rwwv|>>+|~^>>vzzw}r>.<br></br>
 .......^?rwwwwz+ .....`..............`~~```````+wwr}v?>>+>>>>>>+>>>???vv}-<br></br>
 `......?wwwwwwzw?||.+rw}`..`````-+?>?rzzv````~vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ``````|rwwwwwwv?vv??zzwz+```->?+?zzzzwwzv~-|^|>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 ``````>rwwwwwwv?????zzzzw}?+>zzwwwwwwwwwwv}w>^>>>>>>>+>>>>>>>>>>?v>?vvv>^.<br></br>
.-````-vzwzwrrv??????}?vvrwr}rwwzzwwwwwwzzrv?~>>>?>?v?~^>>>>>>>>>r?}zzzzzr-<br></br>
 ~|~~`+rwwv>?v??????>}?>?}?v?+^>?vrrrwww}?>^^~+>?w?ww+^>>>>>>>>>>xvwwwwrv?`<br></br>
.}w?v}r?vv>>rv??????+r}vv??>?||+++^vzwwz+^++^-~|^^|vv|+>>>>>>>>>?xvzwwww}?`<br></br>
`zzwwzz??vvv??????????????^vw>-^++++v?v?^+^|~?v??~>v+^>>>>>>>>>>vz?wr?>>+v-<br></br>
`www?}}vv???????????????>v}r}v-~||^^~|~|^-`+-vzzr^+v|+>>>>>>>>>>v}~^^|~~|+`<br></br>
`rwr???}r}???????vvvv????vv????}r}^|~`-|||`?^>ww+~``^>>>>>>>>>>>>+~||||||^`<br></br>
`?++?v?zyy}?????}uyyz????>^~~~?uyr}^.`.`~~`-^v}wv^`-^>>>>>>>+>>>>>|~||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>?v??+|v}-.           .?>^|~^>>>>>>}^^>>>?}|~|||||.<br></br>
.????v??v}vv????????????>>?????>~``----~~`.  `~~~||~|+>>>>>z>|>>>>}}~|||||.<br></br>
`vv?>?????vvvv}wzw}?????++>>>?~..~?vv???~ `>????????^^>>>>>x?++>>>?x>vvvvv-<br></br>
`}}}v?v???zuuyyuyxzv???>^>?vv}v>-..`^r?..^}rrrrrrrrr?|^>>>>}v|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>+?vvvvvvvvv+-.v|`>v???vvvvvvv}>|+>>>>+>>>>>????????`<br></br>
`vvv???>???vv???vv??>+>?vvvvvvvvvv?^+??^>???????????>||+>>>+>?????????????`<br></br>
.???????v?>+v??>wxr>>?>????????????vv??vv???????????||^>>>>>vvvvvvvvvvvvvv`<br></br>
.?????????>wr??>>?}|>??????????????????????????????>~|^+++|???????????????`<br></br>
.??????????z}??>>>+`>???????????????????????????????>++++++???????????????`<br></br>
.>>>>>>>>>>>rrv~~?^`>>+++?????????????>>??????????????????????????????????`<br></br>
.>>>>>>>>>~~~~--~--^?>>>>>>>>>>>>>>>>>>>>>>>>????????????????>>>>>??>????>`<br></br>
.>>>>>>>>>>++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ...............................`......``..................-|~|^+-.`...... <br></br>
 ..........`.`-~`......................```..........`???`.`^??>>v>?wv`.... <br></br>
 ........~?v?vvr}`............................```...~?vwv^|>>?^^>>?wwr~... <br></br>
 .......>}wwzrww>..............................``--|>?}r}>^>>^|~|>>??}}vv+.<br></br>
 .......|?}wwwz}-.....................-^|```````>r}vv?>>>>>>>++>+>>>>>>???-<br></br>
 `......>vwwwwwwr>--`|?vv~..`````->???wzw>````-?v?>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
 ``````~vwwwwwr??}??vvwzz?-``->vv?}}}www}>~`~~~^+>>>>>>>+>>>>>>>>>?>++>>>?`<br></br>
 ``````?wwwwwwwv?????}wrrw}?+^rzwwwr}wwww}vvr?+>>>>+++++>>>>>>>>>?v>v}}v?>.<br></br>
 `````-vzww}v}vv??????vvvrzw}}wzzzzzwwwwwwrv?|+>>>>??+^+>>>>>>>>>rvvzzzzwr-<br></br>
.~|~-`^r}rv>?}??????>?v?v}v??+|>?vrwwwwrv>>^^~|+>}?r}^+>>>>>>>>>>z?rwwwwvv`<br></br>
.}}>vv}??v??}v??????>vw}v????^|++++vzwzz+^++^-|^|^^??|>>>>>>>>>>?x?wzwwwr}-<br></br>
`wwrrrw??vvv??????????????+?w>-^++++v?v?^+^|~????~>}>^>>>>>>>>>>?}>vv>++?v~<br></br>
`wwwv?vvv???????????????>?r}v?-~||^^~|~|^-->|}zwr^>v^+>>>>>>>>>>??^^^>|~^+`<br></br>
`}rrv?v}r}????????vvv????v?>>>?vr}+|~`-|||~v>?ww+~`-^+>>>>>>>>>>++^|>v|~|+`<br></br>
`?>?vvvzyyv?????}uuuxv???>>+++?zx}}^.`.`~~`~|?}wv+`-|>>>>>>>>++++>+^?+~|||.<br></br>
.??v?v?vxuzv???rzuuzv?????vv?>^rr|.           `?+^-~|+>>>>>r^^+++>?++~||||.<br></br>
.>???v??vvv?????v???????>>??????>-`----~~`  .`~~||~~~+>>>>?z+|++>>??|~||||.<br></br>
`v??>?????vv?vrwzw}?????++>+>?|..-?vv???` `>????????^^>>>>?z>+^+>>>v+?v??v-<br></br>
`}}v?????vxuuyyuyxzv???>^>?vvvv>~..`^r?..^v}v}}}}}}rv|+>>>?v?^+>++?v?}rrrr~<br></br>
`vvv?????vzxxxzzwwrv??>^??vvvvvvvv>-.v|`>v??vvvvvvvvv^^+>>>>+?????vvvvvvvv-<br></br>
`vvv???>???????>>?v?>+>????vvvvvvv?++v?+>??????????v>|^+>>>+>?????????????`<br></br>
.?????????>+?>^|vvv>>>>????????????vv??vv???????????||^>>>>>vvvvvvvvvvvvvv`<br></br>
.??????>>>>}?^+-~>?~>???????????????????????????>??>~|^^++^???????????????`<br></br>
.??????????w?>>|~^|`>???????????????????????????????>+++++>???????????????`<br></br>
.>>>>>>>>>>?}v?~`+^->>>>>????????????>>>>>>>>>>>>>>>>???????????>>>>>>>>>>`<br></br>
.>>>>>>>>>|~~---~~-^?>>>>>>>>>>>>>>>>>>>>>>??????????>>??????>>>>>>>>>>>??`<br></br>
.>>>>>>>>>>++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............................................................```......... <br></br>
 ...............................`......``.................`^>>+?v-`-`..... <br></br>
 .........|^~^?v|.....................````..........~?vv|.|>v?+>?^?zv-.... <br></br>
 .......-vzzwvrzw~............................```...~+>}v~^>>+||+>?rr}-... <br></br>
 ......`}}vwwvrr|..............................```-^>?v}v^+>+>>+^>>>?vvvv>.<br></br>
 .......~~?wwwzv...................```|>^```````?}vv??>>>>>>>>>>>>>>>>>>>?`<br></br>
 .......->wwwwrwv^.`^`->?^..````-`>?v}zz}~`````-v?>>>>+>>>>>>>>>>>>>>>?>^+.<br></br>
 ```````?rwwwwv??v>?v+}zww^```^}w?+^?www>|~``--~+>>>?>+^^+>>>>>>>>?+^vwwv?`<br></br>
 ``````?zwwwrrw?>?????}?vr}?>~vzwwwv?wwwrvv?v}rv^++^^^^^+>>>>>>>>?}>rwwwzz~<br></br>
 `````-vwwr?>v}v??????w??vzw}vwzzzwzwwww}rr???vv?>+>?|+>>>>>>>>>>rv?wwww}}-<br></br>
.~^~``~rvv??v}v??????>wwwwv??>+?vvwwrww}?>>^^>???}wr?|>>>>>>>>>>>z?vwwwr}?`<br></br>
`rv>v??w??}}}vv??????>rrvv???^~++++vwwzz>+++^-?}????+^>>>>>>>>>>>z?}zwrrrw~<br></br>
`w}}}}r}?}vv?????????????>?>}+-^++++vvv?^+^|-????+?r?^>>>>>>>>>>>?>??+^^?}~<br></br>
`wwwv>vvv???????????????>?r?>|-~||^^~|~|^~^>>rwrr^?w>^>>>>>>>>>>>>>>>v^~^>`<br></br>
`vv}rvv}zr????????vvv??????+^~|?}v+|~`-||^vwrvwz>~`^+^>>>>>>>+++++>>vx+~|+`<br></br>
.+?v}}vwyyv?????vxuuxv???>????>?>?}^.`.`||`||>}w?>`-~^>>>>>>>|||^+>>w>~||^`<br></br>
.>???v??zuw????rzyuu}?????vv???zx}.           `>||`~~|>>>>>w+~~~^>>??~||||.<br></br>
.||^+?v??v??????vvv?????>>?????vw>`----~~`  .`~~|~~~~|>>>>?z^~~|+>>?>~|~||.<br></br>
.??>~+???v}v?vrwwwv?????+++++>|..`>v???>` `>???????v?^>>>>rz+?+|^+++^?v???`<br></br>
`}}?^>???}xuuuuyuzw????>+????vv?^..`|r>..^vvvvvv}}}}v|+>>>}v>}v??????}rrrr~<br></br>
`vv?>>>>?vzzzzzz}}rv??+^>?vvvvv}}v>-.v|`>vv??vvvvvvv?~^>>>>+?v}}}}}}}}}}}}-<br></br>
`?vvvvv?>>>????|-~}v++>??>>vvvvvvv?+>v?>?vvvvvvvvvvv?~^>>>>>vvvvvvvvvvvvvv-<br></br>
.??????>+++>+-`-~~>^>++?v??????????vv??v????????????+|^>>>+????vvvvvvvvvvv`<br></br>
.>>>>>>++>>+`-.-~~-|??>>?>>>???????????????????>+++>~||^^^+???????????????`<br></br>
.>???>>?>>>>^+|-~~~-????????????????????????????????>++++>>???????????????`<br></br>
.>???????????+>^-~~->?????????????>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>+~~^?>>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............................................................``.......... <br></br>
 ...........````................``........................`^>>+>?-.-...... <br></br>
 .........~||+?v+...............``...`..............~?vv^.|>v?+>?+?w?-.... <br></br>
 .......`?vvv?}wr-...............`............```...-^+}v~^>>+||+>?}r}~... <br></br>
 ......`???v}vrr|...................``.........```-^>?v}v^+>++>+^>>>?vvvv>.<br></br>
 .......-->rrrwv...................`~-|>|```.```?vvv??>>>>>>>>>>>>>>>>>>>?-<br></br>
 `......->wwwwrwv+.`+~|>>|..````-`>?vrwrv-````-|v?>>>++>>>>>>>>>>>>>>>>>+>`<br></br>
 ``````~?rwwwwv??v?vv+vwwr^```^vr?>>?wwr?|~`--~|>>>>>>+^^+>>>>>>>>?+^?vv??-<br></br>
 ``````?zwwwrrw?>?????v?vr}?>~vwwwwv?wzwrvv?}}rv+>++^^^^+>>>>>>>>?v>vv}}v}~<br></br>
.````-|?rr}?>v}v???v??w??}zw}vwzzzwzwwzw}rr???vv?>>??^+>>>>>>>>>>rv?wwwwv?`<br></br>
.~^^~~^}vv??v}v????v??zwwwv?v?>?vvwwrww}?>>^^>???}wr?^>>>>>>>>>>>z?vwwwr}?`<br></br>
`}v?v??r??}}}vv???????rr}v???^~+++>vwwzz++++^-v}????>^>>>>>>>>>>?z>vr}}}rw~<br></br>
`r}}}}}v?}vv?????????????>??}+`++++>vvv?+++^-????+?r?^>>>>>>>>>>>?+??>>>?}~<br></br>
`}rw}??vv???????????????>?r??^-|^|+^~+|^+|+>>vrr}^>w>^>>>>>>>>>>?>>>?v++>?`<br></br>
`vv}rvvrz}????????vvv??????+^|>?rv+||~~^^^?}vvww+-`|^^>>>>>>>>++>>>>?x+|^>`<br></br>
.>?v}}vwyyv?????}xuuxv???>>>>>????}^`-.`||`~|>}w?^``~^>>>>>>+|||+>>>w>~||^`<br></br>
.>???vvvzuw????rzuux}?????vv???zx}`. .  .     ->~-`~~^>>>>>}+~~|+>>??~||||.<br></br>
.|^+>?v??vv?????vvv?????>>?????vr>`--~~~~`. .-~~~~---|>>>>?w^~|^>>>>+~|~||.<br></br>
.??>^>???vvv?vrwzwv?????++>+>>^..`>v???>` ->????>????|>>>>rw+>^^+>++^?v???-<br></br>
`}}?^>???}xuuuuuuzwv???>+>???vv?^`.`^r?`.+vvvv}}}}}}v^+>>>}v>v?>??>>?}rrrr~<br></br>
`vv?>>>??vzzzxxxrrrv??+^>?vvvvvv}v>~`v+-?vvvvvvvvvvv?|+>>>>+????vrvv}}}}}}~<br></br>
`?vvvvv?>>?????^`~}v++>?>>>vvvvvvv?>>v?>?vvvvvvvvvvv?|^>>>>?v????v?vvvvvvv-<br></br>
.??????>+++?+--```>+++>????????????vv???????????????+~|+>>+??>>?????????vv`<br></br>
.>>>>>>+>>>+-`.```-^??>>?>>>???????????????????>+++>~|||^^+??>>>>>>???????`<br></br>
.>???>>?>??>^+~--~~~????????????????????????????????>++++>>??++++++>??????`<br></br>
.>??????????>^||-~~~>?????????????>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>+^^~--^>>>>???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
.>>>>>>>>>>>>>>+||^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ................................  ....  ................................. <br></br>
                  ................................~  ...  ................................. <br></br>
                  ................................^  ...  ................................. <br></br>
 ...............................`> ..-.  ................................. <br></br>
                  ...............................`+.-.-.  ................................. <br></br>
 ..........`>v}}>`..............`>.`.~. .............-|^`..`~||^|^`....... <br></br>
 ..........|??vwr|..............`?.-`|. .`..........|?vv^``|>v?>vv+>~`.....<br></br>
 ...........`>>v^...............`+.^^|. ......```....~^+^`~^>>>|+>>vzw~....<br></br>
 ...........|??w|...............`^`?wr^  ........`.-?}vv?^+>>++||+>+?}r}?+`<br></br>
 .........|v?^>v?` .~>~.........`^->?r?`~.`...->>``??>>+^^>>>>>>>>>>++>?vr~<br></br>
 `.....`~vzzrvww}v>vwzw?- ..`````||vwzv^+````~}zz?+?++^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzzwzzwv??ww}v?vv>-.`--^?vvr}z}>>^|^^+?vwv|^|^+|^>>>>>>>>>>>+++>>v~<br></br>
 `````~v}v??vrv???vw}?^+rzv>??>wzzzwwzxzwvvv}}v}rwv?vr>|>>>>>>>>>>??^>?+^+.<br></br>
 ````+}?^+?vvv????>wwrrwwwzzwrrwzzzzzzxzwwwv>?v}wwzzz}|+>>>>>>>>>?v?rwzwv+.<br></br>
.~+vvrwv}wzrv?????>}wwwwwwzzwwzwwzzzwzxzzwww+vwrwzwww>^>>>>>>>>>?x?vrrrwwr~<br></br>
.+rzzw}rwwrv??????>?r}}v}wvv}>?v?vzwwzxw?v>~^?}?>?}w}|>>>>>>>>>>}z|+++>?vw~<br></br>
.^?}ww??v}vvv???????v???rv?}w?-vwrzwrwxw}wv`->>+^|+v?|>>>>>>>>>>zv+?r}rwww~<br></br>
.|+vww??vv??????????????rrrww?-vwvv??wzzzv+?->?v?^^>^^>>>>>>>>>>xvvwrwwwww~<br></br>
`}r}vvvv?????????v}}}???v}??vvrrwwwrwzzzr+`^.?zw-`--|+>>>>>>>>>>x?>+^++>vw~<br></br>
`rw}v?vwxzv?????wyyyw????+|~|^vyyrwzzv>v+~|||}zr|||||+>>>>>>^+>>z?~||||^++.<br></br>
.?vr}v?wyywv???rwzw}????>>??+^^v?+???>|?^~~|>?v?|||||+>>>>>^^+>>w}~|||||||.<br></br>
.>?v?v??rzrv????????????>?vvvv?>^~|||>|>|||~-~|||~~||++>>+||^+>>}r||||||||.<br></br>
.????vv???????vrrr}?????>>?>>?????vvv?>?v??????????>>++>???+^+>>?w+v}vvvvv-<br></br>
`v????v???vzxuyuuxxw>??>++??v}}v}}rwrrrrrwrrrrrrrrrrr}}rrr}?|^>>>v>v}}}}}r~<br></br>
`vvvv?v???}uuyuuuuzw???+>?vvvvvvvv}rwwwwwrvvvvvvvvvv}}}vvvvv^^+>>z>?}}}}}}-<br></br>
.???>?????v}}v???v???>+^+>?????vvvvvvvvvvv??????vvvvvvvvvvvv>|+>>v>?vvvvvv-<br></br>
.?????????????????>??????>????????????????>>>>>????????????>+^+>>>+>??????`<br></br>
.????????????~~+>>r?>???????????????????????????????????????>|^>>>>+??????`<br></br>
.?????????>?|---^?z???>?????????????????????????????????????+~||^^^^??????`<br></br>
.>>????????>~|~~-^}>?????????????????????????????????????>>>>+^+>>>>??????`<br></br>
.>>>>>>>>>>>+~~~~~|^>>>>>>>>>>>>>>>>>>>>>>>?????????????????????????????>?`<br></br>
.>>>>>>>>>>>>>+--~-~>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ................................  ....  ................................. <br></br>
                  ................................~  ...  ................................. <br></br>
                  ................................^  ...  ................................. <br></br>
                  ................................^ ..`.  ................................. <br></br>
                  ...............................`+.-.-.  ................................. <br></br>
 ..........->v}}>`..............`+.`.~. .............-|+`..`~|||||`....... <br></br>
 ..........|??vwr|..............`>.-`^` .`..........|??v^``|>v?>vv+>~`.....<br></br>
 ...........`+>v^...............`+.^^^. ......``.....~^+^`~^>>>^+>>vzw~....<br></br>
 ...........|??r|...............`^`?r}^  ........`.-?}vv?^+>>++||+>+?}r}?+`<br></br>
 .........|v?^>v?`..|>~.........`^->?r?`~.....~>>``??>>+^^>>>>>>>>>>++>?vr~<br></br>
 `.....`~vzzrvww}v>vwzr?- ...````||?rwv^+````~}zz?+?++^^^^+>>>>>>>>>>>>>?}-<br></br>
 ``````vzzzwzzrvv?ww}v???+-.`--^?vv}}z}>>+|^^+?vwv|^|^+|^>>>>>>>>>>>+++>>?~<br></br>
 `````~v}v??vrv???vw}?+>rzv>v?>wzzzwrzxzwvvv}}v}rr???}>|>>>>>>>>>>??^>?+^+.<br></br>
.-```>r?^+?vvv?????wwrwwwwzzwrwwzzzzzzxzwwwv>?v}rrrrwv|+>>>>>>>>>?v?rwwr?+.<br></br>
.~+vvrwv}wzrv?????>}wwwwwwzzwwzwwwzzwzxzzwww+vwrwzwww>^>>>>>>>>>?u?vrrrwrr~<br></br>
.+rzzw}rwwrv??????>?r}}v}wvv}>?v?vzzwzxw?v>|^?}?>?}w}|>>>>>>>>>>}z|+>+>?vw~<br></br>
.^?vww?vv}vvv???????v???rv?}w?-vwrzwrwxw}wv`-??>^|+v?|>>>>>>>>>>zv+vr}rwww~<br></br>
.|+vww?vvv??????????????rrrww?-vwvv??wzzzv+?-??v?^^?^^>>>>>>>>>>xvvr}wwwww~<br></br>
`}r}vvvv?????????v}}}???v}??vvrrwwwrwzzzr+`^.?zw~--~|+>>>>>>>>>>x?>+^+>>vw~<br></br>
`rw}v?vwxzv?????wyyyw????>^^++vyyrwzzv>v+~|||}zr|||||+>>>>>>^+>>zv~||||^++.<br></br>
.?vr}v?wyywv???rwzw}????>>v?>++v?+???>|>|~~|>?v?|||||+>>>>>^^+>>w}~|||||~|.<br></br>
.>?v?v??rzrv????????????>?vvvv??+||||>|>||~~-~|||~~||++>>+||^>>>}r||||||||.<br></br>
.????vv???????vrr}}?????>>?>>?????vvv?>?v??????????>>++>>??+^+>>?w+vvvvvvv-<br></br>
`v????v???vzxuyuuxxw>??>++??v}}v}}rwrrrrrwrrrrrrrrr}}vvvvv}?|^>>>v>vrr}r}r~<br></br>
`vvv??v???}uuyuuuuxw???+>?vvvvvvvv}rwwwwwrvvvvvvvvvvvvvvvvvv^^+>>z>?}vvvv}-<br></br>
.?????????v}}v??vv???>+^+>?????vvvvvvvvvvv??????vvvvvvvvvvv?+^+>>v>?vvvvvv-<br></br>
.?????????????????>??????>????????????????>>>>>???????????>>^^+>>>+>??????`<br></br>
.????????????~~+>>r???????????????????????????????????????>>+|^>>>>+??????`<br></br>
.????????>>?|-`-^?z???>???????????????????????????????????>>+~||^^^^??????`<br></br>
.>>??????>>+~~-~-|}>??>>????????????>????????????????????>>>>+^+>>>>??????`<br></br>
.>>>>>>>>>>++~-~~-|^+++>>>>>>>>>>>>>>>>>>>>?????????????????????????????>?`<br></br>
.>>>>>>>>>>>>>+--~-~++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ...................................`+~................................... <br></br>
 ...................................`?>................................... <br></br>
 ...................................-?>................................... <br></br>
 ............. .....................->?................................... <br></br>
 ...........`^??`...................->?................................... <br></br>
 ..........-vv}z>...................->>..............^??-................. <br></br>
 ..........`-->v^..................`??v``...........`^^^~`.`~++++>+`-`...~`<br></br>
 ...........|+`}}+.................~v}z|......``....-^+^~`.~+>r?+v??zw~..?|<br></br>
 ..........-.``|?>`..................+?........`...`}zxzw?-^>>>^~+>^>}rrvv-<br></br>
 .........+vv???>|.+??`.............~v+......>vv~`-?>?v?+^+>>++^^|>++?rzzz~<br></br>
 ......^vvzzzwwrwwvv?v^......```.....~|.`|~``?rwr?+>>>>>>>>>>>>>?>>>>???v}-<br></br>
 `````.?zwwrww}?vzw}??^--..`--`~>>+?v?++}wr??>?v?wv>>++>>>>>>>>>>>>>>>>>>>.<br></br>
 `````>}?+>?}}v??}zw}v}wwvv??>^vzzwrwzwwzwrvvv}>^?>^^|||^>>>>>>>>>v?^^+>>>`<br></br>
 `-|^^>>?vww}v????wzzzwwwzzwwwwwwzzzwzzwzzwr??rv+||+>?>|+>>>>>>>>vv>??>++>`<br></br>
 -^}zwwwzzw}v????>}wrwwwwwwzwwwwrvrrrzzr}}wvvwvv}rrwzr^+>>>>>>>>?y?+vv}r?^.<br></br>
.|+rwzwwww}v??????+++?vvrw}v}?r?+~+?}zr???-^?>^>v}v}z>^>>>>>>>>>wx>????}z}`<br></br>
.+|^>vrww}}vv?????>???>?rv|vw+|}w}}wwzwwzw|+rr}}?-+r}|+>>>>>>>>>ur??rzwwr}-<br></br>
.>>>}??vvvv???????????>}r}vrw?~vzzzwwzzwzv^>^vw}?`-}?|+>>>>>>>>?uv??>rzwrr~<br></br>
`wzwz??v?????????v}r}??vv>>}rrwzwwwwwzzwv``v??wr|>~??|>>>>>>>>>?y?^>???wzz~<br></br>
`ww}v?vv}v?>??>vxyyy}???+vrwr}uurwzzwv?>^`~>vwwv^v|^>|+>>>>>++>?uv~|~|^?v?`<br></br>
`}}v}vvxyyr????rwzwv????>vvvv}rrv??r?^||||^>?vvv?||||~|+>>>>v+^>wv|||||~||.<br></br>
.++vv}?vxur}????????????>?????>++|^+|||||?+~~||~~~|~~|~|^>>>zw+>>>^|||||||.<br></br>
.+>vv}????????v}}}}?????++>>??>???vv????v}}????????-~~~|^+>>??>>>>+?vvv?vv-<br></br>
.??>+v????vrxuuuuzzr????+?vv}}vvvvr}rr}}r}}}rrrrrr}>+^^||^^^^^+>>>>vrrrrrr~<br></br>
.???>?v???vuuuyyyyuzv??+>????v??vvvvvvvvv?????vvvvv}vvvv???||+>>?z+???????`<br></br>
`vvvv?????vzuzrvv}v??>>+>>>>?vvvvvvvv??????>?>>???????????^|^>>?zv????????`<br></br>
`??????>rzv???>>??>>?>??????????????????????????????????v?^|+>>w}>vvvvvvvv`<br></br>
.???????vwzv^-|++++w}>>?>?????????????????????????????????|||^+>+>????????`<br></br>
.????????+^?~.^++++vx???>?????????????????????????????????+^^|||^>????????`<br></br>
.>>>>>>>>^|~-|?>+^~>}?-^>????????????????????????????????>????????????????`<br></br>
.>>>>>>>+++-|>>>>^>|~~~~>>>>>???>>>>>>>>?????????????????????????????????>`<br></br>
.>>>>>>>>|~^>>>>>|?|~-~+>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ...................................`+~................................... <br></br>
 ...................................`?>................................... <br></br>
 ...................................-?>................................... <br></br>
 ............. .....................->?................................... <br></br>
 ...........`^??`...................->?................................... <br></br>
 ..........-vv}z>...................->>..............^??-................. <br></br>
 ..........`-->v^...............`..`??v``...........`^^^~`.`~++++>+`-`...~`<br></br>
 ...........|+`}}+.................~vrz|......``....-^+^~`.~+>r?+v??zw~..?|<br></br>
 ..........-.``|?>`..................+?........`...`}zxzw?-^>>>^~+>^>}rrvv-<br></br>
 .........+vv???>|.+??`.............~v+......>vv~`-?>?v?+^+>>++^^|>++?rzzz~<br></br>
 ......^vvzzzwwrwwvv?v^......```.....~|.`|~``?rwr?+>>>>>>>>>>>>>?>>>>???v}-<br></br>
 `````.?zwwrww}?vzw}??^--..`--`~>>+?v?++}wr??>?v?wv>>++>>>>>>>>>>>>>>>>>>>.<br></br>
 `````>}?+>?}}v??}zw}vvwwvv??>^vzzwrwzwwzwrvvv}>^?>^^|||^>>>>>>>>>v?^^+>>>`<br></br>
 `-|^^>>?vww}v????wzzzwwwzzwwwwwwzzzwzzwzzwr??rv+||+>?>|+>>>>>>>>vv>??>++>`<br></br>
 -^}zwwwzzw}v????>}wrwwwwwwzwwwwrvrrrzzr}}wvvwvv}rrwzr^+>>>>>>>>?y?+vv}r?^.<br></br>
.|+rwzwwww}v??????+++?vvrw}v}?r?+~+?}zr???-^?>^>v}v}z>^>>>>>>>>>wx>????}z}`<br></br>
.+|^>vrww}}vv?????>???>?rv|vw+|}w}}wwzwwzw|+rr}}?-+r}|+>>>>>>>>>ur??rzwwr}-<br></br>
.>>>}??vvvv???????????>}r}vrw?~vzzzwwzzwzv^>^vw}?`-}?|+>>>>>>>>?uv??>rzwrr~<br></br>
`wzwz??v?????????v}r}??vv>>}rrwzwwwwwzzwv``v??wr|>~??|>>>>>>>>>?y?^>???wzz~<br></br>
`ww}v?vv}v?>??>vxyyy}???+vrwr}uurwzzwv?>^`~>vwwv+v|^>|+>>>>>++>?uv~|~|^?v?`<br></br>
`}}v}vvxyyr????rwzwv????>vvvv}rrv??r?^||||^>?vvv?||||~|+>>>>v+^>wv|||||~||.<br></br>
.+^vv}?vxur}????????????>?????>++|^+|||||?+~~||~~~|~~|~|^>>>zw+>>>^|||||||.<br></br>
.+>vv}????????v}}}}?????++>>??>???vv????v}}????????-~~~|^+>>??>>>>+?vvv?vv-<br></br>
.??>+v????vrxuuuuzzr????+?vv}}vvvvr}rrrrr}}}rrrrrr}>+^^||^^^^^+>>>>vrrrrrr~<br></br>
.???>?v???vuuuyyyyuzv??+>????v??vvvvvvvvv?????vvvvv}vvvv???||+>>?z+???????`<br></br>
`vvvv?????vzuzrvv}v??>>+>>>>?vvvvvvvv??????>?>>???????????^|^>>?zv????????`<br></br>
`??????>rzv???>>??>>?>???v??????????????????????????????v?^|+>>w}>vvvvvvvv`<br></br>
.???????vwzv^-|++++w}>>?>?????????????????????????????????|||^+>+>????????`<br></br>
.????????+^?~.^++++vx???>?????????????????????????????????+^^|||^>????????`<br></br>
.>>>>>>>>^|~-|?>++~>}?-^>????????????????????????????????>????????????????`<br></br>
.>>>>>>>+++-|>>>>^>|~~~~>>>>>???>>>>>>>>?????????????????????????????????>`<br></br>
.>>>>>>>>|~^>>>>>|?|~-~+>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............-........................................................... <br></br>
 ...........?}w}`......................................................... <br></br>
 ...........^^?v|...................................`>?^~..`|>>++?|....... <br></br>
 ...........-^``-`..............``....```............````..~+>r?+v>~~`..`+`<br></br>
 ..........`.. ~v?|.....................``..........`?v>-``^>>+^^>>?z}`.^r~<br></br>
 ..........+|^|~-`.............................`-~~~?vv?+^+>>++^^^>^vwv?vr~<br></br>
 .......||>vwzz}>|?v>.......................+?+?rrv??>>>>>>>>>>>>>>>+>??vw~<br></br>
 ......+rzzzwwv?ww}vr}?`......``.......`>?~`^vv?>>+>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````|v}v?vrv???}?>>>>-.```-^^--~-``-^}z}vv?++^+???>+^^>>>>>>>>>>>>||>>+>`<br></br>
 `````|^+??vv????}zv?vv?|???}rvvwwrv?vwzww}v?+>vwzzzzw^^>>>>>>>>>?}+??^^||.<br></br>
 ``-+?++}zw}??????v}wzzzrwvvwwr}v??vwzwwwwww}rzzzwwwz}|>>>>>>>>>>v??wzwrv?-<br></br>
.`~vzzwwzw}v?????>~+vv?v}?v?vrwrvvrwwwwwzzz???wvvwzwz?+>>>>>>>>>vy???}wwzz|<br></br>
.-|+>>>}w}}v???????????}r}r?>^?vvwzwwwwwr}v?>rzr>?vz}^>>>>>>>>>>rz?r}wzwrr~<br></br>
.?>|^??v}vv????????????vvvvwr?>vv}zwwwwz?vv~?zwz}+^++^^+>>>>>>>>u}+??vv}v}-<br></br>
`v}v???vv???????vrzzzv????rwrrxywwwwwwwwz}>?}}rv?|+?||^>??w}>>>?y?>vww}wz}`<br></br>
.?wzw??v}rv?????ryyyw???>?vvv}xxv}wwwwwzv^?w+>zr>v+?^|^>>>vw+>>?x?^vvrzzw?.<br></br>
`wrv}vv}yyuv???}rr}v?????+????>>?vwzr???^^?}>rz}~rrr?||+>>>>>>>>>>`~+v}???`<br></br>
`vvv????rxw}?>???????????^++^^^?r}rr?||||>?>>}vv~^>}+|^>>>+^>>>>>+`~|||~~|.<br></br>
.>?>v}v???????v}zxx}?????^^|||`??||^||||||~~||~~|~|^||^>>>+^>>>>>^-^^||||^.<br></br>
.^^>v?v????}xuyyuuwx}???>+>^^++??????vv??????v??????^|||^+^|>>>>>>v}}vvvv}-<br></br>
.>>?>^vv???}uuyyuuuz}??>^>>???vvv}}rrr}v??v?vvv}}}}}v?>>>^|^>>>>+v}}}}}}}}-<br></br>
`?????++???vww}v??v??>++~>v}}}vvvvvvvvvvvvv??vvvvv??vvvvv^|^>>>+?vvvvvvvv}-<br></br>
`vvvv?-~~>?v???????>>+vr|`^?vvvvvvvvvvvvvvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
`????+|~~-^w>+++++++>??}~+|??????????????????v??????????||+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~~?^>>++++++>>>>^->??????????????????>>+++>>??>|^++>v>???????????`<br></br>
.??>>>>|-~~~>?????>++^???>>?????????????????????????????+++^^^+???????>???`<br></br>
.>>?????|-~-~???????>>????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>`<br></br>
.>>>>>>>>+||+>>>>>>>>>??>>?????>>>>??>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .............-........................................................... <br></br>
 ...........?}w}`.............................................`........... <br></br>
 ...........^+?v|...................................`>?^-..`|>>++?|....... <br></br>
 ...........-^``-`..............``....```............````..~+>r?+v>~~`..`+`<br></br>
 ..........`.. ~v?~.....................``..........`?v>-``^>>+^^>>?z}`.|r~<br></br>
 ..........+|^|~-`.............................`-~~~?vv?+^+>>++^^^>^vwv?vr~<br></br>
 .......||>vwzz}>|?v>.......................+?+?rrv??>>>>>>>>>>>>>>>+>??vw~<br></br>
 ......+rzzzwwv?ww}vr}?`......``.......`>?~`^vv?>>+>>>>>>>>>>>>>>>>>+>??>>`<br></br>
 `````|v}v?vrv???}?>>>>-.```-^^--~-``-^}z}vv?++^+???>+^^>>>>>>>>>>>>||>>+>`<br></br>
 `````|^+??vv????}zv?vv?|???}rvvwwrv?vwzww}v?+>vwzzzzw^^>>>>>>>>>?}+??^^||.<br></br>
 ``-+?++}zw}??????v}wzzzrwvvwwr}v??vwzwwwwww}rzzzwwwz}|>>>>>>>>>?v??wzwrv?-<br></br>
.`~vzzwwzw}v?????>~+vv?v}?v?vrwrvvrwwwwwzzz???wvvwzwz?+>>>>>>>>>vy???}wwzz|<br></br>
.-|+>>>}w}}v???????????}r}r?>^?vvwzwwwwwr}v?>rzr>?vz}^>>>>>>>>>>rz?r}wzwrr~<br></br>
.?>|^??v}vv????????????vvvvwr?>vv}zwwwwz?vv~?zwz}+^++^^+>>>>>>>>u}+??vv}v}-<br></br>
`v}v???vv???????vrzzzv????rwrrxywwwwwwwwz}>?}}rv?|+?||^>??w}>>>?y?>vww}wz}`<br></br>
.?wzw??v}rv?????ryyyw???>?vvv}xxv}wwwwwzv^?w+>zr>v+?^|^>>>vw+>>?x?^vvrzzw?.<br></br>
`wrv}vv}yyuv???}rr}v?????+????>>?vwzr???^^?}>rz}~rrr?||+>>>>>>>>?>`~+v}???`<br></br>
`vvv????rxw}?>???????????^++^^^?r}rr?||||>?>>}vv~^>}+|^>>>+^>>>>>+`~|||~~|.<br></br>
.>?>v}v???????v}zxx}?????^^|||`??||^||||||~~||~||~|^||^>>>+^>>>>>^-^^||||^.<br></br>
.^^>v?v????}xuyyuuwx}???>+>^^++??????vv??????v??????^|||^+^|>>>>>>v}}vvvv}-<br></br>
.>>?>^?v???}uuyyuuuz}??>^>>???vvv}}rrr}v??v?vvvv}}}}v?>>>^|^>>>>+v}}}}}}}}~<br></br>
`?????++???vww}v??v??>++~>v}}}vvvvvvvvvvvvv?vvvvvv??vvvvv+|^>>>+?vvvvvvvv}-<br></br>
`vvvv?-~~>?v???????>>+vr|`^?vvvvvvvvvvvvvvvvvvvvvvvvvvvv?||+>>>>vvvvvvvvvv-<br></br>
`????+|~~-^w>+++++++>??}~^|??????????????????v??????????||+>>v??vvvvvvvvvv`<br></br>
.>>>>>+-~~~?^>>++++++>>>>^->??????????????????>>+++>>??>|^++>v>???????????`<br></br>
.?>>>>>|-~~~>?????>++^???>>?????????????????????????????+++^^^+???????>???`<br></br>
.>>?????|-~-~???????>>????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>`<br></br>
.>>>>>>>>+||+>>>>>>>>>??>>?????>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............^+`.......................................................... <br></br>
 ..........--}w?.`........................................................ <br></br>
                  .............-........................................................... <br></br>
                  ..........................................................`|^|^^+........ <br></br>
 ..............................```....```............~++`.`^>}?>vv^?+`.... <br></br>
 ..........``-~|........................``..........^zzx?`|+>>>|+>>rxr~`.. <br></br>
 ........~vwrrwzv.............................`~++|~+vvvv++>>+^|^+>vzzwr}}-<br></br>
 .......?zzwwwwz?....-`.................`....`>ww}?vv>>>>>>>>>>?>+^^?}rwzz~<br></br>
 .......?zwwwwww}>~^?zw?`........`~-..+}rv-``?}?>>>>>>>>>>>>>>>>>+^||?v??r~<br></br>
 ```...|?>}wwwrvv?}zzwwz>....^??>}wr?}zzz}--+>>+^^|^+>>>>>>>>>>>>>+^||^++>`<br></br>
 `````-|>vwwwrv???vzwwwwrrv??wwv???vwzwwwwvrz}+|>}}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
 `````->wzww}v?????wwwzzwwzzzwww}}wwwwwwwwzwwzwrwzzzr?|^>>>>>>>>>}v>rrr}vr|<br></br>
 ~>>+>vwzww}v?????>}wv?v}wzzwwwwwzwwwwwwzwww?}wwwwwwz?|+>>>>>>>>?uvvzwzwww~<br></br>
.}zzzw}rwwrv??????>?wv>?vwvvr>vvvvwwwwwr?v?>+?w?>vvwv|+>>>>>>>>>?x?}wwwrv>.<br></br>
`wzwww??vvvv????????vv??rv?wwv+rwrwwwww}}w}`->>+|+>>^^>>>>>>>>>>vz+vw?^^^?`<br></br>
`wzzzz??vv??????????????rrrwwr^vwwwwwwwzzr+?>??vv^~+|+>>>>>>>>>>rr~^^||||+`<br></br>
`wzrvvvv?????????v}}}???v}??vvvrwwwwrrrwr>`+^}zw>+``~+>>>>>>>>>>zv~||||||^`<br></br>
.}vvv?vwxzv?????wyyyw????+~~^^vyyrwv?^^++~|^~vzw+^~-~+>>>>??+>>>v?~|||||||.<br></br>
`>|^?v?ryywv???rwzw}????>>?>+^^v?+??v^||||||+?v>||||~^>>>>vr+>>vv|~|||||||.<br></br>
.>?vvv??rz}v????????????>>?vvv??+~~~|||||~~||~~~~~~|-|>>>>?x>+++|~||||||||.<br></br>
.????v????????v}wr}?????>>?>>????????>>?v???v????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv?>?v???vzxuyuuxxw???>^+??v}}rrrrr}}}rrrrrrrrrrrrrr+^>>>?}>}rrrrrr}rrr}r~<br></br>
`vvvv?????}uuyuuuuzr???+>?vvvvvvvvvvvvvvvv}}}}}}vv}}}>|+>>>>>vv}}}}}}}}}}}-<br></br>
.???>?????v}}v???v???>^-+>?????vvvvvvvvvvvv?????vvvvv?||>>>>>vvvvvvvvvvvvv-<br></br>
`???????>??v?>>??>+?}?^->??????????????????>>>>??????>||+>>>+>>>>>>???????`<br></br>
.?????|+???w+>???^>??+^~?????????????????????????????>|^^+>>^??????>>>>>>>`<br></br>
.????>--~^?w????>???|?^|?????????????????????????????>~|||^|+?????????????`<br></br>
.?>>??|-~-+v>??>????>-|?????????????????????????????>?>+++++??????????????`<br></br>
.>>>>>>~~~~~+>>>>>>>>>????>>>?>>?????????????????????????????????????????>`<br></br>
.>>>>>>>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ............^+`.......................................................... <br></br>
 ..........--}w?.`........................................................ <br></br>
                  .............-........................................................... <br></br>
                  ..........................................................`|^|^^+........ <br></br>
 ..............................```....```............~++`.`^>}?>vv^?+`.... <br></br>
 ..........``-~|........................``..........^zzx?`|+>>>|+>>rxr~`.. <br></br>
 ........~vwrrwzv.............................`~++|~+vvvv++>>+^|^+>vzzwr}}-<br></br>
 .......?zzwwwwz?....-`.................`....`>ww}?vv>>>>>>>>>>?>+^^?}rwzz~<br></br>
 .......?zwwwwww}>~^?zw?`........`~-..+}rv-``?}?>>>>>>>>>>>>>>>>>+^||?v??r~<br></br>
 ```...|?>vwwwrvv?}zzwwz>....^??>}wr?}zzz}--+>>+^^|^+>>>>>>>>>>>>>+^^|^++>`<br></br>
 `````-|>vwwwrv???vzwwwwrrv??wwv???vwzwwwwvrz}+|>}}?^|+>>>>>>>>>>?v+^^^||^`<br></br>
 `````->rzww}v?????wwwzzwwzzzwww}}wwwwwwwwzwwzwrwzzzr?|^>>>>>>>>>}v>rrr}vr|<br></br>
 ~>>+>vwzww}v?????>}wv?v}wzzwwwwwzwwwwwwzwww?}wwwwwwz?|+>>>>>>>>?uvvzwzwww~<br></br>
.}zzzw}rwwrv??????>?wv>?vwvvr>vvvvwwwwwr?v?>+?w?>vvwv|+>>>>>>>>>?x?}wwwrv>.<br></br>
`wzwww??vvvv????????vv??rv?wwv+rwrwwwww}}w}`->>+|+>>^^>>>>>>>>>>vz+vw?^^^?`<br></br>
`wzzzz??vv??????????????rrrwwr^vwwwwwwwzzr+?>??vv^~+|+>>>>>>>>>>rr~^^|~||+`<br></br>
`wzrvvvv?????????v}}}???v}??vvvrwwwwrrrwr>`+^}zw>+``~+>>>>>>>>>>zv~||||||^`<br></br>
.}vvv?vwxzv?????wyyyw????+~~^^vyyrwv?^^++~|^~vzw+^~-~+>>>>??+>>>v?~|||||||.<br></br>
`>|^?v?ryywv???rwzw}????>>?>+^^v?+??v^||||||+?v>||||~^>>>>vr+>>vv|~|||||||.<br></br>
.>?vvv??rz}v????????????>>?vvv??+~~~|||||~~||~~~~~~|-|>>>>?x>+++|~||||||||.<br></br>
.????v????????v}wr}?????>>?>>????????>>?v???v????????|+>>>vu>?????vvvvvvvv-<br></br>
`vv?>?v???vzxuyuuxxw???>^+??v}}rrrrr}}}rrrrrrrrrrrrrr+^>>>?}>}rrrrrr}rrr}r~<br></br>
`vvvv?????}uuyuuuuzr???+>?vvvvvvvvvvvvvvvv}}}}}}vv}}}>|+>>>>>v}}}}}}}}}}}}-<br></br>
.???>?????v}}v???v???>^-+>?????vvvvvvvvvvvv?????vvvvv?||>>>>>vvvvvvvvvvvvv-<br></br>
`???????>??v?>>??>+?}?^->??????????????????>>>>??????>||+>>>+>>>>>>???????`<br></br>
.?????|+???w+>???^>??+^~?????????????????????????????>|^^+>>^??????>>>>>>>`<br></br>
.????>--~^?w????>???|?^|?????????????????????????????>~|||^|+?????????????`<br></br>
.?>???|-~-+v>??>????>-|?????????????????????????????>?>+++++??????????????`<br></br>
.>>>>>>~~~~~+>>>>>>>>>?????>>??>?????????????????????????????????????????>`<br></br>
.>>>>>>>|---^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ...........................................................``--~-........ <br></br>
 ...............................``....```...........`>?>...|?v>>}?>r?`.... <br></br>
 ........`+v?vv}v`.....................```..........|vwz?>~+>?>^?>?zzw~... <br></br>
 .......|}zzzwzz?.............................```--~??rwwv^>>+|-^>>vzzw}r>`<br></br>
 .......|vwwwwwz^......`..............`~-......`+wr}v??>>+>>>+>>+>>>???vv}-<br></br>
 .......?wwwwwwww?||.+rw}`.......`+?>>rwwv````|vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ````..~rwwwwwwv?vv??zzwz^...`>?+vzzzzwwz?``|^~>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 ``````+rwwwwwrv?????zzzzw}?+>wwwwwwwwwww}>vw>^>>>>>>>++>>>>>>>>>?v>?vv?>|.<br></br>
 `````-vzwzwrrv??????}?v}wzzwwwwwwwwwwwwwzwzr|>>>?>?v?~^>>>>>>>>>r?}zwwwzw~<br></br>
 -|-~`+rrw}>?v??????>}?>?}zzwwwwr}wwwwww}}wv?++>?w?rw+|+>>>>>>>>>xvwwwwwv?`<br></br>
.vr>v}r?vv>?}v??????>rrvv}rvr?v?}?rwwww?vv^-^+||^^^}v|+>>>>>>>>>?xvwwwww}?`<br></br>
`wzwzzz>?vvv?????????????}^vz?^rwzwwwwwwwz?^v}???->}+^>>>>>>>>>>vz?wr>>>+v~<br></br>
`www?}}?v????????????????r}w}v-vwwwwwrwwz}^?^vzzr^+?|+>>>>>>>>>>}}|^^|~~|+`<br></br>
`rwr??vv}}???????vvvv????}v???v}wwr?>?vrz>.?~>zw+~``^>>>>>>>>>>>>>|||||||^`<br></br>
`v^^?v?zyy}?????}uyyz????>^~~~?uy}rrrv+^>~`~>rzzv^`-^>>>>>>>>>>>>>||||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>?v??>+}}+^+??||||||+>v?+||~^>>>>>>r^^>>>?}|||||||.<br></br>
.????v??v}v?>???????????>>??????^~~~~~||||~~~~~~~~~~|>>>>>>x+|>>>>}}~|||||.<br></br>
`vv?>?????vv?v}wzw}?????++>>>??????v????vv??????????^^>>>>>x?++>>>?x?vvvvv-<br></br>
`}}}v?v???zuuyyuyuzv???>^>?vv}}vv}r}}rrrrrrrrrrrrrrrv|^>>>>}}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>+???vvvvvvvvvvvvvvv??vvvvvvvv}>|+>>>>+>?>>>????????`<br></br>
`vvv???>???vv???vv??>+>??vvvvvvvvvvvvv??????????????>||+>>>>>?????????????`<br></br>
`???????v?>+v???wxr>>?>?????????????????????????????||^>>>>>vvvvvvvvvvvvvv-<br></br>
.????????>>rr??>>v}|>??????????????????????????????+~|^^++^>??????????????`<br></br>
.??????>>??x}??>>>^`>???????????????????????????????>+++^++>??????????????`<br></br>
.?>>>>>>>>>>rrv~-?+->+++>?????????????????????????????????????????????????`<br></br>
.>>>>>>>++-~~~--~~-^>>>>>>>>????????????????????????????????????>>?????>?>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ...........................................................``--~-........ <br></br>
 ...............................``....```...........`>?>...|?v>>}?>r?`.... <br></br>
 ........`+v?vv}v`.....................```..........|vwz?>~+>?>^?>?zzw~... <br></br>
 .......|}zzzwzz?.............................```--~??rwwv^>>+|-^>>vzzw}r>`<br></br>
 .......|vwwwwwz^......`..............`~-......`+wr}v??>>+>>>+>>+>>>???vv}-<br></br>
 .......?wwwwwwww?||.+rw}`.......`+?>>rwwv````|vv??>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
 ````..~rwwwwwwv?vv??zzwz+...`>?+vzzzzwwz?``|^~>>>>>>>>>>>>>>>>>>>>>>+++>?-<br></br>
 ``````+rwwwwwrv?????zzzzw}?+>wwwwwwwwwww}>vw>^>>>>>>>++>>>>>>>>>?v>?vv?>|.<br></br>
 `````-vzwzwrrv??????}?v}wzzwwwwwwwwwwwwwzwzr|>>>?>?v?~^>>>>>>>>>r?}zzzzzw~<br></br>
 -|-~`+rrw}>?v??????>}?>?}zzwwwwr}wwwwww}}wv?++>?w?rw+|+>>>>>>>>>xvwwwwwv?`<br></br>
.vr>v}r?vv>?}v??????>rrvv}rvr?v?}?rwwww?vv^-^+||^^^}v|+>>>>>>>>>?xvwwwww}?`<br></br>
`wzwzzz>?vvv?????????????}^vz?^rwzwwwwwwwz?^v}???->}+^>>>>>>>>>>vz?wr>>>+v~<br></br>
`www?}}?v????????????????r}w}v-vwwwwwrwwzv^?^vzzr^+?|+>>>>>>>>>>}}|^^|~~|+`<br></br>
`rwr??vv}}???????vvvv????}v???v}wwr?>?vrz+.?~>zw+~``^>>>>>>>>>>>>>|||||||^`<br></br>
`v^^?v?zyy}?????}uyyz????>^~~~?uy}rrrv+^>~`~>rzzv^`-^>>>>>>>>>>>>>||||||||.<br></br>
.>?vvv?vuyw}???rwxxw????>?v??>+}}+^+??||||||+>v?+||~^>>>>>>r^^>>>?}|||||||.<br></br>
.????v??v}v?>???????????>>??????^~~~~~|||||~~~~~~~~~|>>>>>>x+|>>>>}}~|||||.<br></br>
`vv?>?????vv?v}wzw}?????++>>>??????v????vv??????????^^>>>>>x?++>>>?x?vvvvv-<br></br>
`}}}v?v???zuuyyuyuzv???>^>?vv}}vv}r}}rrrrrrrrrrrrrrrv|^>>>>}}|+>>>?rvrrrrr~<br></br>
`vvvvv????zuuxzzzzrv??>+??vvvvvvvvvvvvvvvv??vvvvvvv}}>|+>>>>+>?>>>????????`<br></br>
`vvv???>???vv???vv??>+>??vvvvvvvvvvvvv??????????????>||+>>>>>?????????????`<br></br>
`???????v?>+v???wxr>>?>?????????????????????????????||^>>>>>vvvvvvvvvvvvvv-<br></br>
.????????>>rr??>>v}|>??????????????????????????????+~|^^++^>??????????????`<br></br>
.??????????x}??>>>^`>???????????????????????????????>++++++>??????????????`<br></br>
.?>>>>>>>>>>rrv~-?+->+++>?????????????????????????????????????????????????`<br></br>
.>>>>>>>++-~~~--~~-^>>>>>>>>?????????????????????????????????????????????>`<br></br>
.>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  ...............................~^^^~~~~~~~~~~~~~~~~~~-................... <br></br>
 .............................`^>>+^^^^^^^^^^^^^^^^^^^~................... <br></br>
 ............................~>>>+^^^^^^^^^^^^^^^^^^^^~............```.... <br></br>
                  ..............`...........`^+++^^^^^^^^^^^^^^^^+++^^^-.....`-~||^^^^`.... <br></br>
                  .............`|||~-``....-++^^^^^^^|^^^^^^^^^^^^^^^^^~`-^+++^^+^^+|`..... <br></br>
 ..............~^^^^++^^~~+^^||^^^^^^^^^^^^^^^^||^^^^^^^^^||++^>?+~`...... <br></br>
 .........`````~+^^^^|^>+^^^^^^++++^^^+++^^|^||||^^^+??v>|~|??^+?||>^`.... <br></br>
 ........-+>+^+>?>^^+>>+^^|^^^^++++^+++^>>>+^^^+^^^^>??vv>~^+>|-~-~>>+`... <br></br>
 .......~^+>>+>>--^>>+^^^^^^^^^^||^^^^|>?+~|^^++^+>>?vvvv>^+^>>>~+>>?vv}}?`<br></br>
 .......`~^???vv+~+>^|^^^^^^^||+^|^^^|+v>-|^^^^^?rwr}v?????>^+>>+^>>>>>>??`<br></br>
 ......->v}rwwwwwv>+>>??v>||^^~-++????v}?>+^^>?vwv????????>^^^^^^^||^^++~~.<br></br>
 ```...-?vvvvvv?}rv}rrzxxz?+++>??????????>+>vv??????vv????+^^^^^^+>|+???+^.<br></br>
 ``````|?vvvvvv}vvv}}wxzxxz}}vv}vvvv?vvvv?vwzz???????????+^^^^^^^?v>????>+.<br></br>
 ``````>????>?v}v??v}rxzzxxrxxz}vvvvvvvvvzxzzw}r}v?v}???+^^^^^^^^v??v????>.<br></br>
 -~---~??????vvv???vvrxzxxxzxxxzvvvvvvvv}xxzwrrwzxzxz?>+^^^^^^^^+r?vv?vvv?`<br></br>
.??^>>>?>?vvr}vv?????}xxzwzxxwzzrvvvvvvvzzv?vvvvv??v>~|^^^^^^^^^+r?vv?vvvv`<br></br>
`v??vv}v?}vv??vv?????v}}}}r}rv?}wvvvvvv}vv?>?v???>?v>~^^^^^^^^^^>?>?>^>??v-<br></br>
`}rrvvv}v?????vv?????????vv??>+?vvvvvvvvv??v?vvvv>?v^`~^^^^^^^^^>>^^|>+>?r~<br></br>
`}}r}vvv}}vvvvvv????????????????vv???vvvv??vvvvv?>>>^~~~^^^^^^||||^|^?+?}r~<br></br>
.??vv}vzyywvvvv?v}rr}v?????????vv?v?vv???>+>>v}}???>+|^|^^^^^^>|||^^?^^??v-<br></br>
.?vv}w}}zxrv??vv}rr}v??????????v}v>>????????vrzw}}vv?+||^^+}>+>^|^>^+~^???.<br></br>
`v}v?v????????v?v???????????v?v}wvvvv}}}}}r}}}}}}vvv?>+^^|>w>>^~^^>^~~+?vv`<br></br>
`r}?+>????????}r}vv?????????v}rrwrwzzwwwwwzwwwwrr}w}?>>+||?w??+|||~|-+?vvr~<br></br>
`w}v??????}}}}wxrrvv???????v}wzzzzzzxzzzwzzzzzzzzwzzr>>>~^?v?v?>>+^>>????}~<br></br>
`}}}vv??vvzzzwrw}vvv??????vrwzzzzzzzzzzwwwwwwwwwzwzwr?>>^^^+?vvvv????????v-<br></br>
`v}}}}v???????????vv???????wzzzzzzzzzzzzzzzzzzzzzzzzr?>>^^^>vvvv?????vv???`<br></br>
`vvvvvv?>>+>>>?+>>??????v?vwwwwrwwr}}vvvv}}}}rrr}}wr?>>+^^^?vvv????????vv?`<br></br>
`?vvvv???>>?++>|-~|>???????vv}v?vv??vvvvvvvv????????^|~~||+vv?????????????`<br></br>
`vvvvvvvvvvv?>^~```~??vv?????????vv?????????vv??v????????????????>>>>>>>??`<br></br>
`vvvvvvvvv???>>~`-`->???????????????????????????????????????>>>>?>>>>>>>>>.<br></br>
`vvvvvvvv?>++^+++>>>?>>??????????????????????????????????>>>>>>>??>>>>>>>>`<br></br>
`vvvvvvvv????vvvvvv???>>>>>??????????????????????????>>>>>>>>>>>>?>>>>>>>>.<br></br>


            </div>,

            <div>

 ..........................................`?>>>>>>>?-............. ...... <br></br>
                  ...............``..........``````````````.-v????????~...........`-~~..... <br></br>
 .`````......`````........>v`````````````.`??????????|.....`~^>???v?`..... <br></br>
 .````````..-??>>|-`.....?xz^.`.``````.. `???????????^->?v}r}vv????`...... <br></br>
 ....````....+?vvrww}v>|vw}v?^-`.....`--|??????????????}}vv??????>`....... <br></br>
                  ............`?????vrzzrv???????????vv??vvvv?????????vv?????????+`........ <br></br>
 .............-????}w}v???vvv??????vv?vr}v???????????vv????????^..``...... <br></br>
 ..............|?vzrv??????v}rv??????rw??????????vvvvvvvvv???????rzxr?>?>>`<br></br>
 ..........`-|^+}zv???????????}v????v}>>????v}wzxxuuuuuuur???vuyyyyuyyu}vv-<br></br>
 ...`~^>?v}rwzwrz}}}rrr}vv????>??>?>?>????vwuyyyyyyyyyyyw????xyyxxxuyyyuv?`<br></br>
 ...`^?vv?????vwwuyyyyyyyuz}????????>????ruyxwyyyyyyyyyx????vyuwzyyyyyyyx| <br></br>
 ```...~>?????x}?uyyyyyyyzuyr???????????zyyyr?yyyyyyyyuv???vzwrzyuxxxuyyy} <br></br>
.-````...~+??rz??}yyyyyyyvzyyr?????????zyyyyyyyyyyyyyxv????vzx}uxwzuyyyyyy~<br></br>
.----``````->x}???wyyyyyyuyyyyv???????ryyyyyyyuuuuuxr????????wwvruyuuuuuyy^<br></br>
.--------~>vrxv????wyyyyyyyyyyx???????zwr}vvvvvvvvv???????????}?vwrrwwzuyx~<br></br>
.-----~^?vv?ww??????vwzzwr}}vvv???????????????????????????????????rxuxuxwr|<br></br>
.~~~~>vrrrr}zr???????????????>>?????????????????????>?????????????v?v}}r}x^<br></br>
.+>?wuuxxxzrz}??????????????????>|??????????????????????????vv?????+>wwrrw~<br></br>
.vuxwvv?????zv???????????????????>?????????vvvvwz}v?????????}vv????>>zzw}w~<br></br>
-ur?????????wv?????????>>????????????vvrzuuyyyyyyyuw????????}v?????>+v}wrw~<br></br>
`v?????????>}}?????????>?zzwxxzzzzxuuyyyyyyyyyyxyuwxv?????????????>>++>>?v-<br></br>
`r???>>>>>>>?}??????????}uuyyyyyyyyyyyyyuuyyyuxwxrwr}v+???????????>>+>>>>+.<br></br>
`?>>>>>>>+++>}???????????v}zuuuyyyyyyyyyuuyyyyxxuxwurr>>?????????>>++>>>>+.<br></br>
.vrrrwwrrr}}?vv??????????rxyyyyyyyyyyyyyyyyyyyuuyyzyzw???????????>>+++>???`<br></br>
`}zzzzzzzzzw??v?????????vuuyyyyyyyyyyyyyyyyyyyyyyyyyux??????????>>>??>>>>?`<br></br>
`wzzzzzzzzw?????????????vyyyuuuuzr}vvvv}}rrwzzwzuzzyz}?????????>>>>???>>>?`<br></br>
`zzzzwwr}}????>>????????vwrz}vv?????????????????v?vv??????????>>>+>>??????`<br></br>
`zzzzwrvv????>>>+>???????????????????>>?????????????????????>>>>+>>>>>>???`<br></br>
`zzzzzzzr??>>>>>+++vuzv???????????????????????????????????>>>>>+>>>>>>>>>?`<br></br>
`zzzzzzz?>>>>??v}?vyyuxwv???????????????????????????????>>>>>>?>??>>>>>>>>.<br></br>
`zzzzzx}?vrwzzxxxvuyyx}xx}????????????????????????????>>>>>>+???>>>>>>>>>>`<br></br>
`zzzzzzzxxzxzzzzw?yyyx??wx}?>?????????????????????>>++++++++++>?>>>>>>>>>>`<br></br>


            </div>,

            <div>

 ........................................`.`?>>>>>>>>-............. ...... <br></br>
                  ...........................``````````````.-v????????|...........``~~..... <br></br>
 ..```..........```.......>v`````````````.`??????????|.....`~^>???v?`..... <br></br>
 .````````..-??>>|-`.....?xz^.`.``````.. `???????????^->?v}r}vv????`...... <br></br>
 ..``````....+?vvrww}?>|vw}v?^-`.....``-|??????????????}}vv??????>`....... <br></br>
                  ............`?????vrzzrv???????????vv??vvvv?????????vv?????????+`........ <br></br>
 .............-????}w}v???vvv??????vv?}r}v???????????vv????????^..``...... <br></br>
 ..............|?vzrv??????v}rv??????rw??????????vvvvvvvvv???????rzxr?>?>>`<br></br>
 ..........`-|^+}zv???????????}v????v}>>????v}wzxxuuuuuuur???vuyyyyuyyu}vv`<br></br>
 ...`~^>?v}rwwwrz}}}rrr}vv????>??>?>?>????vwuyyyyyyyyyyyw????xyyuxxuyyyuv?.<br></br>
 ...`^?vv?????vwwuyyyyyyyuz}?????????????ruyxwyyyyyyyyyx????vyuwzyyyyyyyx| <br></br>
 ```..`~>?????x}?uyyyyyyyzuyr???????????zyyyr?yyyyyyyyuv???vzwrzyuxxxuyyy} <br></br>
.`````...-+??rz??}yyyyyyyvwyyr?????????zyyyyyyyyyyyyyxv????vxx}uxwzuyyyyyy~<br></br>
.----``````->x}???wyyyyyyyyyyyv???????ryyyyyyyuuuuuxr????????wrvruyuuuuuyy^<br></br>
.--------~>vrxv????wyyyyyyyyyyx???????zwr}}vvvvvvvv???????????}?vwrrwwzuux~<br></br>
.-----~^?vv?ww??????vwzzwr}}vvv???????????????????????????????????rxxxuxwr|<br></br>
.-~~~>vrrrw}zr???????????????>>?????????????????????>?????????????v?v}}}}x^<br></br>
.|+?wuuxxzzrz}??????????????????>|??????????????????????????vv?????+>wwrrw~<br></br>
.vuuwvv?????zv???????????????????>?????????vvvvwz}v?????????}vv????>>zxw}w~<br></br>
-ur?????????wv?????????>>????????????vvrzuuyyyyyyyuw????????}v?????>+v}wrr~<br></br>
`v?????????>}}?????????>?zzwxxzzzzxuuyyyyyyyyyyxyuwxv?????????????>>++>>?v-<br></br>
`r???>>>>>>>?}??????????}yuyyyyyyyyyyyyyuuyyyuxwxrwr}v+???????????>>+>>>>+.<br></br>
`?+++>>>+++>>}???????????v}zuuuyyyyyyyyyuyyyyyxxyxwurr>>?????????>>++>>>>>.<br></br>
.vvv}}}}}}}}?vv??????????rzyyyyyyyyyyyyyyyyyyyuuyyzyzw???????????>>++>>???`<br></br>
`}zzzzzzzzzw??v?????????vuuyyyyyyyyyyyyyyyyyyyyyyyyyux??????????>>>??>>>>?`<br></br>
`wzzzzzzzzw?????????????vyyyuuyuzr}vvvv}}rrwzzwzuzzyz}?????????>>>>???>>>?`<br></br>
`zzzzzzwwrv???>>????????vwrz}vv?????????????????v?vv??????????>>>+>>??????`<br></br>
`zzzzwr}v????>>>+>???????????????????>>?????????????????????>>>>+>>>>>>???`<br></br>
`zzzzzww}??>>>>>+++vuzv???????????????????????????????????>>>>>++>>+>>>>>?`<br></br>
`zzzzzzz?>>>>??v}?vyyuxwv???????????????????????????????>>>>>>????????>>>>.<br></br>
`zzzzzx}?vrwzzxxxvuyyx}xx}????????????????????????????>>>>>++????????>>>>>`<br></br>
`zzzzzzzxxzzzzzzwvyyyx??wx}?>????????????????????>>>++++++++>>>?>>>>>>>>>>`<br></br>


            </div>,

            <div>

 .................................`.........->>>>-........................ <br></br>
                  ................................-||``.````.+????+........................ <br></br>
 ...``..........````........... +?``-````...>?????........................ <br></br>
                  .```````....```````...........+wv?-````..``+?????`..........`-|~......... <br></br>
 .`````````..`````````........>zv?>``````--`|?????|...-..`~+??v?-......... <br></br>
 ..............-+^|~-````....?zv??+~-```---`-?????+..`~>v}}vv??~.......... <br></br>
 ..............`??vvv?>^~`.`vxv??v>|-````--`-??????>vrwrv?????^........... <br></br>
 ...............^????vv}}}?vzw}rvv>~--~----`->?????vvv??????>>`..........`.<br></br>
 ...............-??????v}wwrvvvvv+----------`^??????????????>~.`-~|+>?????`<br></br>
 ...............`>??vrzxwv?????v?-----------.^???????????????>?rzzwrwwvv?+.<br></br>
 ......``````````^vzr}}vvv??>>??`-----------`+???>?????????????vv???xyuxv. <br></br>
 ``...+????vvv}v}zzv???????????>~`.`````-|>?????????????????}v?????zyyxwz+-<br></br>
.``````>???????rxr????????????????+~``~>??v?????????????????vv????zyuz}}zu^<br></br>
.---````+?????wxr?????????????????????vv?????????????????????????vzzr}rxyy^<br></br>
.------`-+>??wxw???????????vv????????v?????vv??????????????????v}wwz}ruuzr-<br></br>
.-------~|>>vxzv??????vvv???v}v??????????vvv????????vv}rv??????}yyzw}r}rrx^<br></br>
.---~~~||^+>zxr????vrxuuxz}??vrv???????v}v??????v}wzuxzv????????}yuv}z}wuu|<br></br>
.-~|^+>??}rvxxv???wuyyyyyuuw???wv?????r}?????v}zuyyyu}???????????zw?>?rwwr|<br></br>
.?}rwzzzzzwwxz??vuyyyyuuuxuuw?>vv????v}????vrxyyyuw}v????????????????>}wwr~<br></br>
`zzwwwr}v??ruw??v}rzuyyyuvyuxv?>?>???v>???ryruxrv????????????????????>>v}r-<br></br>
`zzzzzzxxw?wxw???????vrxyyyyuz?>>>???>??vzyx}???????????????????vv???>>+?v-<br></br>
.??>>>??vrwwxr??????????vwxyyuv?????>??vxzv??????????????????????????>>>wz~<br></br>
.+>>>>>>>+>wxr?????????????vzyw???????vrv????????????????????????????>>+vw|<br></br>
.>>>>>>>>>+rxw????>>?????????}zv?????????????????????>>?????????????>>>++>`<br></br>
`vr}v??>>>+vxzv?????????????????????????????????????????????????????>>>+>+.<br></br>
`wzzzzzwv?>?xx}??????????????????>????????????>>?}wrv>?????????????>>>>?>>`<br></br>
`zzzzzzw}???wxw?????zr???????????>+?????????}r}rzxxxxv>>??????????>>>>>???`<br></br>
`zzzzwv????>?zzv???vxxuvv????????????????v}xyyuyyyuwxwr>?????????>>>>>>>>?`<br></br>
`zzz}???>>>>>}xzv??}rxuuuuzrv????????vrzuyyyyyyyyzuwvrz?????????>>>>>+>>>>.<br></br>
`z}?>>>>>>>>>>rxwv?wrzruyyyyyuxxzzxuyyyyyuuyyyyuu}wzzrxv???????>>>>++>>>>>.<br></br>
`}???????????>>rxzvvwzzuxuyuyyyyyyyyyyyyyyyuuuxxuuuzyxvv??????>>>>>++>??>?`<br></br>
`zxxxxxxzzzx}>>>vxz}?vryyyyyuxxxxxxxxxxxxxzzxxxuuuuvzw??????>>>>>++++^^>>>`<br></br>


            </div>,

            <div>

 .................................`.........->>>>-........................ <br></br>
                  ................................-||``.````.+????+........................ <br></br>
 .................``........... +?``-````...>?????........................ <br></br>
                  ..``````.......`````..........+wv?-````..``+?????`...........-|~......... <br></br>
 .``````````..```````.........?zv?>`````````|?????|...`..`~+??v?-......... <br></br>
 .......`......`+^|-``.......?zv??+~-```````-?????+..`~>v}}vv??~.......... <br></br>
 ..............`?vvv??>|~`.`vxv??v>|````````-??????>vrwrv?????^........... <br></br>
 ...............|????vv}}}?vzw}rvv>~`````````>?????vvv??????>>`..........`.<br></br>
 ...............`??????v}wwrvvvvv^```````````|??????????????>~.``-|+>?????`<br></br>
 ................>??vrzxwv?????v?```````````.|???????????????>?rzzwrwwvv?+.<br></br>
 ........````````^vzr}}vvv??>>??`````````````+??>>?????????????vv???xyuxv. <br></br>
 .....+????vvv}}}zwv???????????>~`..`...`~+?????????????????}v?????zyyxwz+-<br></br>
 ````.`>???????rxr????????????????+-``~>????????????????????vv????zyuz}}zu^<br></br>
.-`--```+?????wxr?????????????????????vv?????????????????????????vzzr}rxyy^<br></br>
.------`-+>??wxw???????????vv????????v?????v???????????????????v}wwz}ruuzr-<br></br>
.--------~>>vxzv??????vvv???v}v??????????vvv????????vv}rv??????}yyzw}r}rrx^<br></br>
.---------|>zxr????vrxuuxz}??vrv???????v}v??????v}wzuxzv????????}yuv}z}wuu|<br></br>
.--~~|^>??vvxxv???wuyyyyyuuw???wv?????r}?????v}zuyyyu}???????????zw?>?rwwr|<br></br>
.?vrwzzzzzwwxzv?vuyyyyyyyxuuw?>vv????v}????vrxyyyuw}v????????????????>}wwr~<br></br>
`zzwwwr}v??ruw??v}rzuyyyuvyuxv?>?>???v>???ryrxxrv????????????????????>>v}r-<br></br>
`zzzzzzxxw?wxw???????vrxyyyyuz?>>>???>>?vzyx}???????????????????vv???>>+?v-<br></br>
.??>>>??vrwwxr??????????vwxyyuv?????>>?vxzv>?????????????????????????>>>zz~<br></br>
.+>>>>>>++>wxr?????????????vzyw???????vrv????????????????????????????>>+vw|<br></br>
.>>+>>>>>>+rxw????>>?????????}zv?????????????????????>>?????????????>>>++>`<br></br>
`vvvv?>>>>+vxzv?????????????????????????????????>>??????????????????>>>+>+.<br></br>
`wwzzzzwv?>?xx}?????????????????>>????????????>>?}w}v>?????????????>>>>?>>`<br></br>
`zzzzzzw}???wxw?????zr???????????>+?????????}r}rzxxxxv>>??????????>>>>>???`<br></br>
`zzzzwv????>?xx}???vxxuvv????????????????v}xyyyyyyuwxwr>?????????>>>>>>>>?`<br></br>
`zzz}???>>>>>}xzv??}rxuuyuzrv????????vrzuyyyyyyyyxuwvrz?????????>>>>>>>>>>.<br></br>
`z}?>>>>>>>>>>rxwv?wrzruyyyyyuxxzzxuyyyyyyyyyyyyu}wzzrxv???????>>>>++>>>>>.<br></br>
`}???????????>>rxzvvwzzuxuyyyyyyyyyyyyyyyyyuuuxuuuuzyxvv??????>>>>>++>????`<br></br>
`zxxxxxxzzzx}>>>vxz}?vryyyyyuuuuuuuuuuuuuuxzxxxuuuu}zw??????>>>>>++>>^^>>>`<br></br>


            </div>,

            <div>

                  ..............................               `?|......................... <br></br>
                  ..............................     .   .     `??-........................ <br></br>
 .............................. .. ..   .. .| .??>........................ <br></br>
                  ....```.........````......... .`..``. ... .?+ +??^......`................ <br></br>
                  .`````````....``````.`.........`^|``.......+?+^???-``.....``````......... <br></br>
 .....``````...`~--`````......`.`^+|-`..```.~??????>..```-```~+?>......... <br></br>
 ...............|+?>|-````..`-``?>-``````...^???????^`-```~>vvvv~......... <br></br>
 ...............`+??v?^-`-`-~-|}wv+`````````~????????~`~>vr}v??>`......... <br></br>
 ................>???v}v+-```^wwvv-``````````????????>?}rv?????^.......... <br></br>
 ................??????}zv^|+v}???~``````````+?????????????????+``..`-~^>?`<br></br>
 .............`.`????????????????>~`....`````^?????????????????^~+?vrwzwv?`<br></br>
                  .....~~-``...``~????????????????+~```````-~-^??????????????????rwwrvwyuxv.<br></br>
 ````.+??v???+^~+??????????????v>~-````-||||`|??????????????????????}yuuwr.<br></br>
.``--``????vvrw}??????????????v>||||||||||||`+???????????????v?????vuuzrwr.<br></br>
.-----`~????????????????????>v?-|||||^||~|~~~>???????????????v?????vrwrrrz^<br></br>
.-------|>????????????????????>|--~~~~~|^>???????????????????????vv}xxw}ru|<br></br>
.--------+>????????????v??????v??|~~~^?????????????????????????vzxxuuurwrr-<br></br>
.--~~~~||^>??????????vvvvvv????v?????vvv????????vv????v}r}v????vuy}}wuxrzw-<br></br>
.~^+>>???v>???????vvwzxzrv??vv??????vvv??vvv????vvv}wxxz}??????ruwv?}wxwru~<br></br>
`vrrwwwwww???????vzuyyyuuzv?vvv?????????vv?v??vvrzxuuurv???????r}?????vrwu~<br></br>
`zxuxzr}}v??v??vwuuuuuuyyuxv??}v??????v}v????rzuuuuxrv???????????????>>w}w|<br></br>
`r}}}wzwv??vv?vxyuuuuuuuuuuxv??}??????rv????wrxuuwv?????????????vv????+?rr~<br></br>
.?????v}w???v?v}rwxuuuuuwwuuz??vv????vv???vzx}xrv???????????????vv????>>>w~<br></br>
.>>>>>>>????v??????vrxuuzwuuxv?>?????v>???zuz}????????????????????????>>+w|<br></br>
.+>>>>>>+???vv????????vwuyuuuz?>>????>>?vxzv???????????>??????????????>>+>`<br></br>
`v??>>>>>????v???????????}zuuur????????vw}??????????????>>???????????>>>>+.<br></br>
`wzz}??>>????v?????????????vwzzv???????v?????????????????????????????>>>>>.<br></br>
`zzzzzwv???????????????????????????????????vv?????vrrrv>????????????>>>>++.<br></br>
`zzzzwrv???????????}r}v??vv??????>??????vvv??vw}vzuuxuzv>??????????>>>>>??`<br></br>
`zzwv????>>????v??vuuyu}v???vvvv?+>?vvvv????wyyyuuyyzwzv??????????>>>>>???`<br></br>
`}v??>>>>>>??????vrxzyyyuzrvvv?????????vv}zuyyyyyyxux}rx?????????>>>>>>>>>.<br></br>
.????>>????>??????rzrxxyyyyyuxzw}}}rwzzxuuuuuuuuuuwwzxr}???????>>>>>+++^^^.<br></br>


            </div>,

            <div>

                  ..............................               `?|......................... <br></br>
                  ..............................     .   .     `??-........................ <br></br>
 ..............................  . ..   .. .~  ??>........................ <br></br>
                  ....```.........````.........  . .`.. ... .?^ +??^......`................ <br></br>
                  .`````````....``````.`....... ..^~``.......+v+^???-``..........`......... <br></br>
 .....``````...`-`-`````.........|+|-`..```.~???????.........`+?>......... <br></br>
 ...............~^?>~`.```.....`?>-``````...^???????|.....-+v}vv~......... <br></br>
 ...............`+??v?|....``.-}wv^`````````~????????`.->vwrv???`......... <br></br>
 ................>???vrv^`...|wz?v-``````````????????>?rwv?????+.......... <br></br>
 ................??????vzv|~^v}v??~``````````+?????????????????|.....`-^>?-<br></br>
 .............`.`???????????????v>~`....`````^????????????????>|~+?vrwzwvv`<br></br>
 .....--`.......-???????????????v>~``````````^??????????????????rxzrvwyuxv.<br></br>
 ````.+??vv??+|-|??????????????}+```````````.~??????????????????????vyyuw} <br></br>
.``--`-????vvrw}??????????????v+````````````.+??>????????????v?????vuuz}r}.<br></br>
.-----`~???????????????????>>v?``````````````>???????????????v?????vrw}}rz^<br></br>
.-------|?????????????????????>-..```..`|+>???????????????????????v}xxw}ru|<br></br>
.--------+>?????????????????????>~```|>????????????????????????vzxxuuurw}}-<br></br>
.---~~~~~|>>?????????????????????????vv???????????????v}wr?????vyyr}zyxrzz-<br></br>
.-~|^^+>??>????????vrzzzr????v??????vv???vv???????v}wxux}??????ryzv?rzxwru~<br></br>
`v}}rrwzzz???????vzuyuuuuzv??vv?????????vv?????vwxuyyuwv???????r}?????vwwu~<br></br>
`zxuuzr}vv?????vwyyyyyyyyuxv??}v??????vrv????rxuyyyuwv???????????????>>zrw|<br></br>
`wrrrzxz}?????vuyyyyyyyyyyuzv??r?????vwv????zwuyuz}?????????????vv????+?}r~<br></br>
.?????vwz?????v}rzuyyyyyzzyuw?>vv????vv>???xurxrv???????????????vv????>+>w~<br></br>
.>>>>>>>???????????vwxyyxzyyuv?>v????v>???xyx}????????????????????????>>+w|<br></br>
.+>>>>>>+?????????????vwuyyyyz?>>????>>?vuxv??????????>>>?????????????>>+>`<br></br>
`v?>>>>>>????????????????}xyyyr????????vz}??????????????>>???????????>>>>+.<br></br>
`zzz}?>+>????????>????????>vwzzv???????v??>????????>>>???????????????>>>>+.<br></br>
`zzzzxzv>>??????????????????>???????????????????>>?rr}?>????????????>>>>++.<br></br>
`zzzzzrv???????????}}v???????????>???????????vrv?wuuuuzv>??????????>>>>>??`<br></br>
`zzw}????>>???????vuuyuv?????????^+?????????wyyyuyyyxwwv??????????>>>>>???`<br></br>
`}??>>>>>>>>?????vrxzyyyuzrv????????????vvwuyyyyyyzux}rx?????????>>>>+>>>>.<br></br>
.??>>>>>>>>>??????rzrxuyyyyyuxzr}v}}wzzxuuuuuuuuuurwzxr}????????>>>>+++^+^.<br></br>


            </div>,

            <div>

                  ..............................               .``......................... <br></br>
 ..............................              .^>~......................... <br></br>
 ..............................              .??>-........................ <br></br>
 ....```.........`````........     .         .>??+`.....`................. <br></br>
                  .`````````...-```````........     ..  .. .. .????|.....`................. <br></br>
 ...`.``````..-````````....... ....`.  .....~`>???>-.........``~-......... <br></br>
 ...............``~^~````.......^|```.......+>>????+......`-|^>?^......... <br></br>
 ................`|?v>``...``.`|>?^``...```.|???????|..`-~^>?vv?~......... <br></br>
 ................`>??vv^`....-+>?^-``````...^???????>-`~+?vvv???`......... <br></br>
 ................~????v}+-```|>vv?-`````````^????????>+?}}v????>`.......``.<br></br>
 ................>?????v}?|||?}}v>``````````~??????????vv??????>```---|^>>`<br></br>
 ...............`??????v}}vvvvvvv|```````````>??????????????????||^>?vwzrv.<br></br>
 `````++|~--```.~???vvv}vvv????v?~`..````````^???????????????????v}}vvzyux-<br></br>
.``--`+????>^||~>?v}vv??????????+|``````````.^???????????????????v??vruuzw|<br></br>
.----`-???vvvv?>v}}v????????????~-``````````.+???????????????v??????vwzr}z^<br></br>
.------^?????v}rr}vv?????????v?~````````-~~^~????????????????v??????v?vwzu^<br></br>
.-------+????vrrvv??????????v?>+^~```~^+++++~???????????????????vvvv?vxxxu|<br></br>
.---~~~~~>??vr}v????????????v?^^^^^^^++++>>>????????????????????vvv}zyuxxx~<br></br>
.-~|^+>>>+>vrv????????vv???????>+^^^^++?????????????vvvvvvv????}xxzzuyuuzu|<br></br>
.???v}rrwv?r}??????v}r}vvv???vv??>>>??v?v???????vvvv}rzzrv??v}wxx}vv}wxuzx|<br></br>
`rwwwzwrwvv}????v}wxuuux}vv???v??????vvv?vv???vvv}wxuux}????v}}vv??????wrz|<br></br>
                  `wwwrvvvvvrv???vzuyyyyyuurvv???v????vvvvvv??v?vwxuuxzwv???????????????+?zr~<br></br>
`vv}rwwv?}rv??}xuuyyyyyyuxwvv?v??????vvvv??vv}xuxxz}v????????????v????>>vw-<br></br>
.????v}w}r}??rxxxxxxuyyyyuurv?vv?????vvv??vvww}zwv??????????????vv????>>+w|<br></br>
.>??????vr}??vv}wzxxxxuuxyyu}??v??????v??vvrx}}v??????????????????????>>+?~<br></br>
.>>>>>>>vrv??????vvrzxxxvzyyuv???????v??v?vzwv??????????>>????????????>>>>`<br></br>
`?>>>>>+vrv??????????}wxxzxxuw???????????vzv??????????????????????????>>>?`<br></br>
`zrv?>?>?rv????????????vrzxxxzv?????????vrv??????????????????????????>>>>?`<br></br>
`zzzzv?>?r}?????????v?????rwww}????????vv?????v????vrrvv>????????????>>>>?`<br></br>
`zzzzw}??}}v???????vvvv?????????????????????vv?v}}wuuuuzv>??????????>>>>??`<br></br>
`wr}vv????}v??????}zzz}vvvvv?????>???????vvvvvwuyyyyyzwzv??????????>>>>>??`<br></br>
`v????>>>>vv?????vxuuyxuzv??vvvv?^>?vvvvvv??}xuuuuxzxx}}rv????????>>++++>>.<br></br>


            </div>,

            <div>

                  ..............................               ............................ <br></br>
 ..............................              .>+.......................... <br></br>
                  ..............................              `v?^......................... <br></br>
                  ....```.........`````.........              .???~......~................. <br></br>
                  .`````````...~``````.........     ..  ..  .  ????`.....`................. <br></br>
 .....``````..|````````.......  . ...  ..  .- >???+.............`......... <br></br>
 ................``~~````.......`-```.......+^^????~......`..`|>+......... <br></br>
 .................`?v+.``..``.`.|?^`.....``.|??????>`......~?vv?|......... <br></br>
 .................^??}?`.....``-+-```````...^???????+...`+vrv???-......... <br></br>
 ................`????}r~.....~rw?-`````````^???????>|-?wwv?????`......... <br></br>
 ................^?????rz>...>zwv>``````````~??????????vv??????>`......-^>`<br></br>
 ................>?????vzwvvvrrvv~.``````````>?????????????????+..`|>?vw}v`<br></br>
 `````+~`.......-???}vwww}v?????v|-..````````^?????????????????>>}rrv?ryyx|<br></br>
.``--`>vv?>|`...|?rzwvv??????>>v>|`.````````.^??????????????????vv???vuyzw^<br></br>
.----`~???v}r}?^vzwv??????????vv|-``````````.^???????????????vv??????wxrvx^<br></br>
.------+?????vwxuzvvv????????r?-````````````.>??>>???????????vv??????>?wzu^<br></br>
.------->????vxxwvv?????>>>>}?``````````````.>??????????????????????>?xuuy^<br></br>
.---~~~~|>??}xwv????????????v^..``````.``~~|??????????????????????v}zuuxxx|<br></br>
.-~|^++>>+>vxr?????????????????^`.``.`~>????????????????v}v????}zxxuyyyuxu|<br></br>
.^^>?vv}r>>xw???????v}v??????????^^~+?vv????????????v}zxzv??vzuyu}vrwuyyzz^<br></br>
`}}rrwwwwvrzv????vrxuuxwv???????????vvv??vv??????vrzuyyr????vww}v????v}rrz^<br></br>
`wzzrvvvv}xr???vwuyyyyyux}??????????vv??vv?????}zuyyyx}???????????????+?xw|<br></br>
`rrwxxwv?wxv??}uyyyyyyyyux}???v????????}v????vxuyyuwv????????????v????>>vr-<br></br>
.???v}zx}xzv?wyyyyyyyyyyyyu}??vv??????}}????rzryx}??????????????v}????>>+w~<br></br>
.>?????rwxw??vvrzuyyyyyyzuyuv?>}?????vr????rywwv??????????????????????>>+v|<br></br>
.>>>>>>+}xw??????v}zuyyyvxyyx?>?v????v?>??ryxv>?????????>>????????????>>>?`<br></br>
`?+>>>>+}ur?????????v}zyyyyyyr?>??????>??}u}>?????????????????????????>>>?`<br></br>
`z}?>>>+vxr?????????????ruyyyyv?>???>>>?vzv>??????????>??????????????>>>>?`<br></br>
`zzzwv>+?xw?????>???????>?wzzzw????????vv?????????>?v}?v>????????????>>>>?`<br></br>
`zzzzxzv>zzv???????????????>>???????????????????v?}uuyuxv>??????????>>>>>?`<br></br>
`zzzrvv??vx}?????>}wzw??????????>>????????????}uyyyyyuww}??????????>>>>>??`<br></br>
`}v???>>>>wzv????vuuyyxxr?>??????~+????????>vzuuuuxzuzv}wv????????>+++++>>.<br></br>


            </div>,

            <div>

                  ..........................`^.............  `+?++++++++++`................ <br></br>
 ......`....................>-............ .>????????????+................ <br></br>
 ......`..........||~`......`+`.........  .~>>????????????~............... <br></br>
 ....```..........|????>^-`. .. ..       ~>>+>????????????>`.............. <br></br>
 ..`````````..`````^????????>|`.  .`--. ^?>?>>?????????????^.............. <br></br>
 ....```````..``````+????????v?>?+?v??|.??>??>??????????????`..``-|^>>>>>^.<br></br>
 ....``.........````|^>?????vv?vv?????>`>???????????????????++?v}vvvv????- <br></br>
 ..................~++^+??vvv???????+>v+>????v???vvv?>>??????vvv????????|. <br></br>
 .................`^^>??v???>>>?????>^v?????????vvv????????v?????>?????|.. <br></br>
 .......`+>>++++++>?>?wwv??vvvvzzv???^+??>?>??vvv???????vvv??????>>???~... <br></br>
 ........-???????vvv}}vwuzrrrzuuxwv??+|>>>?>?vv?????vvv}}}}}}}}v?>>>>-..``.<br></br>
 .........`+>?>>???vvv?}uuxxxuuxxwr???>+>>?+>????v}rwwwwwwwwwwrv>>??>>vvv?`<br></br>
.?vv^.``...`|>>>??v}v?vvxxzzzwzz}wwv????????+>?vrwwrwwwzzzzzw}v???vrzwzxxz|<br></br>
`r}rw>++|~~`-|^>?vw}v???rzzzwwwxrwwr??????????}wwzz}wwwzzzzrv?????vwzzxwvv~<br></br>
`rv?}v}rv>++>>>+vxr?????vwwzzzxxzwww}???????>vwwwwwwwwwwwrv??????vwwwwrrvw^<br></br>
`wr}}??vrw}?>>?vxzv???????v}}rrvvvvv????????>}r}}}}}}}}vv????????}wrv?}wzy^<br></br>
.?vvv^-~+?}r}}rwzv?????????????>++>>???>????>???????????????????v?vv?ruuuu|<br></br>
.-----~^>?vvrxwr}?????????????????????||????????????????????????????zyuuuu|<br></br>
.--|+?v}v???v}v}v?????????????????????+^?????????????????v?????v}wwxyyuuuy^<br></br>
.+?}r}v????vv?v}v?????????????vvv?????vvvvvvvvvvv?????vvvv???vwxuxxxxuyuuy^<br></br>
`}}vvvvvv?vv??v}v?vvvvvv??????vzzzzzzxzzzzxzzzzzzrvvvvvv???vrxxz}vvvvrwzxu|<br></br>
`vvvv?????vv??vrvvvvvvvvvv????}wrr??????????vv}wxuurvvv?????}}vv????vv?vzw~<br></br>
.?????????v???rx}vvvvvvvvvv???vrr}|-~~~||~||~~|^+??????????????????????rwr-<br></br>
.?????????????zu}vvvvvvvvvvv??|vrr?|^|+^~||~~|~+^^||+?????????????????>vrx~<br></br>
.>>???????????vz}vvvvvvvvvvvv>.^++^||~^`~|~-|~~--~^^^?????????????????>>?r~<br></br>
.????vvvrrv????}v?vvvvvvvvvvv>.`~||~~^~`^||++~-~~~~||?????????????????>>>v-<br></br>
`vvvvvvvwxz}v??}v????vvvvvvv}+`.--|~~|`-^|+|-~^^^|~~|?????????????????>>+?-<br></br>
`zw}vvvvrxwv???v}v??????vvvv}+`.`-~~---~^^|~~|^^^^~-~>???????????????>>+>>`<br></br>
`zzzzrv?vwv?????vv????????vvv~-|^|^>>>>>+~~|^++>>>^||?v??????????????>>+>>`<br></br>
`zzzzzzrvvv??????}v?????????>|?}v????????>>>???????vvvvvv???????????>>>?>>`<br></br>
`zzzzwrv?vv???????wvvv??????vr}v??????????????vrwrvvvvvvv??????????>>>>???`<br></br>
`wwr}v???????????>zzvvvvv???v}???>??????????v}rrrrvvvvvvv?????????>>++++>>.<br></br>


            </div>,

            <div>

 ..........................~?`............. ^}v?>>>>>>>>>-................ <br></br>
                  ......-....`..............`v^``...``````. `uwv???????????.........`...... <br></br>
 ......-..........++|-.. ...~v~````````.. .>}?????????????^............... <br></br>
 ....```..........^v?vv?>|`. ........   .?rv???????????????`.............. <br></br>
 ..````````....````>????vrwrv+~.  .-|+` ?xr}}vv????????????>.............. <br></br>
 ....```````..`````-???????vrzzrvvrzzz?.vvvvvvvv????????????~..`-|+>?vvv??.<br></br>
 ....--`........````^>?????vrzzwr}vvv}v-?????v}v??????????????}wzxzwrvv??| <br></br>
 ...................->>?vrzzrvv??????vr>????v}???vvvv????????vrr}v??????^. <br></br>
 ....................->}zrv??????????>rv????v??vrrvv?????????vv????????+.. <br></br>
 .......`?????????>+|?wzzzzzzxzzw}???>?v??????v}??????????????????????^... <br></br>
 ........|???vvvvv}wzzvruyyyyyyyuuw???+??????v?>>???v}rwwwwwwwrrv????~ ..  <br></br>
 .........~????????wzv?vuyyyyyyyyyy}???>???>?>>?v}wxuuuuyyyyuxxrv????+????`<br></br>
.vww+......~??????zzv??vuyyyyyyy}uyuv???????>>?}xuyzuyyyyyyyuwv????ruxzwww|<br></br>
-uzuy?^`....|>???wxv????zyyyyyyyzyyyr?????????wuyyy}xyyyyyyzv?????vyyxwv??`<br></br>
-zrrurxzv^`.`^>>vu}?????vuyyyyyyyyyyx????????wyyyyyyyyyyuzv??????vuyuzvrvr^<br></br>
-uxxx}rzxxw?~~>>zz???????vrwwwwrrr}}}???????vxxxxxxzzzw}v????????wyuwv}rzy^<br></br>
.vwz}^-|>vzxw}v}x}??????????????>>>?????????????????????????????vvvvvxxxxz~<br></br>
 -----~^?}rvrxrzzv????????????????????^|?????>??????????????????v???zuxyuu|<br></br>
.--|+?}w}v???vrxr?????????????????????>|????????????>???????????v}rwyyuzyy^<br></br>
.+?rr}v???????}xv?????????????v}v??????vvvvv}}}}v????????????v}zyyuuuyyxxy^<br></br>
`}vv??????????rxv?????????????}yyyyyyyyyyyyyyyyyyw?????????vxuyuzrvvwzxxxu|<br></br>
.????????????>rxv?????????????wuzx?>>>>>????v}wzxyxv????????rzrv????v}rwrr~<br></br>
.>>>>>>>>>>>>+rxv?????????????vzxr.           ...~+>?????????????????>?uz}-<br></br>
.>+++>>>>>>>>+}xv?????????????`vxz> ..............  `????????????????>>wyx~<br></br>
.??????>>+>>>+vxv????????????> .~~..   .......    ...????????????????>>>vz|<br></br>
`v}rwzzzrv??>+?x}????????????|    ...............  ..>??????????????>>>+>r-<br></br>
`wwzzzzzzzxz}??zw????????????-   .................  .+??????????????>>>>+?~<br></br>
`zzzzzzzzzw}???}xv???????????.    ................   +?????????????>>>>+>>.<br></br>
`zzzzzzww}??????zw???????????  `-..~|^^|`.....``----`>????????????>>>>>+++.<br></br>
`zzzzzzz}????????x}?????????>~vw}>>?????>^-~+>>???>>+????????????>>>>>??>+.<br></br>
`zzzzzz}?????>>>+vzv????????vuzv??????????????????>>>????????????>>>>>>???`<br></br>
`zzzzzv???>>>>>>>+rw????????}z????????????????????+++??????????>>>>>+++>>>.<br></br>


            </div>,

            <div>

 .....................`..  `~...............|v}?>>>>>>>>>+^-.............. <br></br>
 .....................^+^~``^`...........``+uzv???????????>+`............. <br></br>
 ......`..........~~-`~+++>+>?~`......`~^^>vr??????????????>~............. <br></br>
 ....```.........`~>>>>???>>+>?>+|`.`~^^+vz}vvvvv??????????>+............. <br></br>
 ..````````....````|>>>??vvv??>+++>???>^vwvvvvv?????????????>-.........```.<br></br>
.?>-````````...`````|>>?????vrwwr}}r}rv+vv???????????????????~.-~^>?vvvvv?`<br></br>
`vvv^`.....`~-``````-|+>???}wzw}vv??vvv?v????vvvv?????????????vwxzwr}vv??^ <br></br>
`vvvv?|~`...|>>>>+++^+++?rzzrvvvvv???vv>vv???vv??vv}vvvv??????vvv???????^. <br></br>
.????vv??|`-~>>+>>>>>?vrzwwrrrrr}}}v??v??v??v???}w}v?????????????????vv>.. <br></br>
`????????rrwww}??????vzz}rxuuuuuxzrrv?????????v}v???????????????????v}?+^` <br></br>
.^>?>>++>?vvv????vvvrz}vrwuyyyyyyuzrr??????????>>??vv}rwwzzzzzzwrv???v?>++.<br></br>
.++^^+???>??>>>>???}z}?vrrxyyyyyyuzzr}???????>>??}wxuuuyyyyyyuuwv?????vr}v-<br></br>
.vv}?+^^+>>vv?>^??vz}???rrwuyyyyxyyywrv????????vzuyuxzyyyyyyuzv????v}}vzxz~<br></br>
`vvv}+~``|+>?vv??vzr????}rrwzxxxzxxxw}v???????wuyyyxuyyyyyuzv?????vrr}vvr}-<br></br>
`v??v?v?>~-^???vwzwv????vrrrrrrrr}}}v????????}zwwzzzzxxxzrv??????v}r}v?rzz|<br></br>
`}vvv??v}wwv????rzv??????vvvvvvvvvvvv????????vvvvvvvvvvv?????????vr}vvvzuy|<br></br>
.>?v}???vvv}vvvvrwv?????????????????????>???????????????????????vvvv?wuuxz~<br></br>
.^>??>>??vvvv}vvw}?????????????vvv????>>????????????????????????????zyuuux|<br></br>
.++>??vvv?????vvrv?????????????}rrrrrrvv}}rrrrrrrrv????????vvvvvrwwxyyuuuy^<br></br>
.+?????????????vrv????????????vzrrv>>>>>??vv}rwzwrrrv?????v}rwzuyuuxuuyuuu^<br></br>
`vv????????????v}v?????????????uuuz?>??????????vvv?}rv?????vwzxzw}vv}wzxux~<br></br>
.??????>>>???>?vvv????????????^vzxv+~~~|||^^++>???>>vv??????vvvv?????v}wrr~<br></br>
.^++^^^^^+++++?vvv???????????+~^>?.           ...`--|>???????????????>?uxr-<br></br>
.++^^^^^++++++?vvv???????????^.~^+-...........     .`+???????????????>>vux~<br></br>
.????????>>???vvvv???????????~ ..`..............   ..^???????????????>>+vz|<br></br>
`v}rwzzzwrv????vvv???????????`    ...............   .|??????????????>>>+>r-<br></br>
`wzzzzzzzw}vv??vv?v?????????>.   ..```............   ~?????????????>>>>>+?-<br></br>
`zzzzzzw}vvv????v?v?????????>~||``|||||~-`...``---`..~?????????????>>>>+++.<br></br>
`w??vrrvv???????vv??????????}>++^|^>>>>>^^|||^++++^|-^????????????>>>>?>++.<br></br>
-v>>>>?????????>?v??vv?????vv?v}v????????>+>>?????>>+>???????????>>>>>>??>`<br></br>
-v++>>>+>????????}w}rr}v????wuxwr}}}}}vvvvvv??????>+>???????????>>>>>>>???`<br></br>
-?^^^>^|~^????????zxrrrrv???}xwwwwwwwwwwwwwwwrr}v??????????????>>++>+++>>>.<br></br>


            </div>,

            <div>

 ..`..................-..  ..............  .|vrv?>>>>>>>>>>^.............. <br></br>
 ....................`???>~`. ...........-?rux}v????????????`............. <br></br>
 .....................+??vv}v?|`. ....`>}xuxr???????????????+............. <br></br>
 ....```.........````.`?????v}wz}?|`|vwxxxxrv}vvv????????????`............ <br></br>
 ..````````....```````.^???????vwxxzzzwr}}vvvvvvv????????????|........``-~.<br></br>
`wv|`.`````....````````~>????vrwzw}vvv}}v???????vv???????????>`~^>?vv}}}vv`<br></br>
-yyx?`.....`^~-``````..`+>?rzw}v???????}w?????v}v??????????????wxzw}vv???^ <br></br>
-uyyyz?+~...>vvvvv??>+^->rzz}vvvvvv????>rv????vv?v}}}}vv????????????????^. <br></br>
`zxxuyyyx?~|>}r???vvv}wwzwxuuuuuuuxr???>?v??????}w}v???????????v?????vr?.. <br></br>
-zxuyyuxxuuyyyr??????vzwvvuyyyyyyyyuz???+??????}v>>????????????v????vuzz}|.<br></br>
`}xxxzwrwrrvv>->????vxw???uyyyyyyuzyur??>???>???>??vv}rwzzxxxxxzzv???xyxzz~<br></br>
`wwvrrzuxwx>`..~>??vxw????wyyyyyyuvyyuv??????>>>?vwxuuyyyyyyyyuzv?????}xxz~<br></br>
`?|?}}vvrzzuw+..^>?xw?????vxyyyyyyyyyyr????????vzuyyz}yyyyyyyzv????????zyx~<br></br>
 ```--``|?rwxuw?>+wzv??????vrzzzwwwwzzw???????ruyyyyyuyyyyuzv??????????vux~<br></br>
.-----```.~?rrzuxrx}???????????????>>????????vvvvv}}rrwwrvv????????????}yx~<br></br>
.-----~^?vrr}v?vvzz?????????????????????^?????>>>>????????????????????vxyy|<br></br>
.--|>vv}}v??????vx}?????????????????????~????????????????????????vv??}uuxx|<br></br>
.>vvvv??????????rxv????????????v}?????????????vvvv????>????????????vwyyxxx|<br></br>
.???????????????wz?????????????xyyyyuuuuuuyyyyyyyuwv???????vvv}wzxxuyyyyxu^<br></br>
.++>>>>>>>>>>>>>zw????????????vuuuw++++>>??v}wxuyyyuv?????vyyyyyyuxzxuyyyx|<br></br>
.???>>+>>>>>>>>>zr????????????^wzu}.         ..`~+vuyv?????vrwwwr}v?vrzxuz~<br></br>
.?????>>+++>>>>>zr???????????> +zxz|.............  ~v}????????????????}wrr~<br></br>
.>>>>>>>>>>+++>+wr???????????-  .-`.....      .......`+??????????????>?uuw~<br></br>
.>>>>>>>>???????}w???????????.   .... ........    ... ~??????????????>>?zu~<br></br>
.???vvv}}}rr}???vw???????????.  .................   ..~?????????????>>>+}r|<br></br>
`v}rwwzzzzwv?????rv?????????+     ................  ..~?????????????>>>+>r~<br></br>
`wzzzzzzz}????????}?????????~    .`--``............  .^????????????>>>+>>>-<br></br>
`zzwzxzwv?????>>>+vv????????->v}^^????>>^~```~|^^+^|. >???????????>>>>>+++.<br></br>
-r^|>vv????>>>>>>>>?????????zyuv??????????????????>>^`???????????>>>>>????`<br></br>
-?-~~~|+>>>>>>>>>>++v}?????vuxv???????????????????>>++??????????>>>>+>>??>`<br></br>
->--~~~~|>>???v}}r}wyyxv????uyuxzwwwrrr}}}vvv????>++>?????????>>>>>+>>>>??`<br></br>
->```~~~~+wzzzxxzwuyyyuu}???}xuuuuuuuuuuuuuuxxzw}?v}v???????>>>>>+++>>>>>>`<br></br>


            </div>,

            <div>

.+`...................`.~^~`.. ..........  .~?rv?>>>>>>>>>>>|............. <br></br>
`??|..................^+???>>^~`.........-?rux}v????????????>-............ <br></br>
.??v?+`...............~>??vvv???>^~`..`>}zuxrv???????????????~............ <br></br>
 ~>?vvv>-........````..|>????vvvvvv?>vwxzzzr}vvvvv???????????^............ <br></br>
.?vvvv}}v?|`....``````.-^??????v}wzwzwr}vvvvv????????????????>`......``-~~.<br></br>
`xz}??vvv}}?>|||^>??~..`->???vwzwrvv?vvvvv???????v????????????~~+??v}rr}vv`<br></br>
-uuz}v??vvvvv}vvvvv?>+^~|^?}zz}v???????vvvv????vvv?????????????}zzw}vv????.<br></br>
-xzuyzv}v?>>?vv}}v??????vrwzzwwwwr}vv???v?v???vv?vv}}}vvv???????????????>?`<br></br>
`wxuuxuzzv?v?>>>????vvvrzwrxuyyuuuuzrv????v?????vwrv???????????vv??????^|v`<br></br>
-wxuuxzwzuuuyx>^?????vwzvvrxyyyyyyyuxrv????????v}?>???????vvvv?vv????v?>?}-<br></br>
`}zzz}vww}v}wr?^^???vzzv??}wyyyyyyuzuz}?????????>>??v}rwzxxxxxxxzr???}zzrz|<br></br>
.>>>>+???>?>>>?v?+>?zzv???vrzyyyyuzyyywv??????>>?vwxuuuyyyyyyyyzv?????v}rx|<br></br>
.|-|>>+^+>>??>+>vvvwzv?????}rzxuxxxxxuz}???????}zuyyuzxyyyyyyzv????????vwu|<br></br>
 ```--`.`|>?}wwv??wx}???????v}v}vvvvvvv???????rzxxxxxuuuuuxwv??????????vwu|<br></br>
.----``-|+?v}}}}}vwwv?????????????????????????v???vvvvv}vv?????????????vuu|<br></br>
.--~|+>??vvvv???v}zv????????????????????+>???????????????????????v????vzyy|<br></br>
.|+>??vvv????????wwv????????????vvv?????>????????????????????????vv??vxyux|<br></br>
.>??????????????vw}v???????????vzwr}}}}}}}}rrrwwrr}v????????????vvvvwyyuxu|<br></br>
.>>>>???????????vw}????????????zyuuv?vvvvv}rwzxuyyxw}?????vrrwzxuuuyyyyyuu^<br></br>
.|^^+>>>>>>>>>>>vwv????????????rxxz+``--~~|^^+>??v}zz}????vuuyyuuuzwzxuyyu|<br></br>
.????>>>>>>>>>>>vrv???????????-+rzw|. .........``-|?zrv????vv}}}}v??v}wzxz~<br></br>
.?????>>++++++++v}v??????????> `^>?-...............`^?v???????????????vwwr~<br></br>
.?>>>>>>>>>++++>vvv??????????~  ......     .    ......|??????????????>?xuz~<br></br>
.+>>>>>>>???????vvv??????????`   ... ...........   ...`??????????????>>?wx|<br></br>
.~|+?vv}}}}vv????vv??????????.   ................   ..-?????????????>>>+}w|<br></br>
.^++>vzzzrvv?????v?vvv??????+    .................   .-????????????>>>>+?r~<br></br>
.++>>>?}}v??????>?}rrrv?????~.``.-|^+^|~``.....```..  ~????????????>>>+++>-<br></br>
.+^^+>>>?????>>++?wrrrrv????>}rv>>??????>+^|^++>>>>^-.^???????????>>>>?+>>.<br></br>
.+--~^+^|^>>>+++?v}r}v}rv???xyx}v?????????????????>>+~???????????>>>>>????`<br></br>
`^------``->>>+?}v}vvvv}rv??wxzzzzzwwwwwrrr}}vvv??++++?????????>>>>>>>>??>`<br></br>
->`-----```|?vvzxxzwrvvv}rv?rrwzzzzzzzzxxxxxzzzzwrvvv?????????>>>>>>>>>>??`<br></br>
-?```--~~~>>?wuxxxuxv}vvvv}??}}r}}}}}rrrrwzxxxxxzwzwv??????>>>>>+++>>>>>>>.<br></br>


            </div>,

            <div>

`}- ....................>?>~`.  .........  .~?rv?>>>>>>>>>>>>`............ <br></br>
-yx>. ..................>?vv}v>~.. ......-?rxx}v?????????????|............ <br></br>
`zyyx?-.................-????v}wwv>-..->}zxxrv???????????????>............ <br></br>
.>xuyyuv~........````....>??????vwxzvvzzzzwwr}}}vvv???????????`........... <br></br>
`xuyyyyyu}+`......````...^?????vvrzwwr}vvv????????????????????-......`-~~|.<br></br>
-uyuxzuxuyyzv>>+??}w|....~>>?}wzw}v????v}rv??????vvv??????????^|>?v}rrrrvv`<br></br>
-xzrzuzuyyyyuyyyyyyzv?>^~->vzz}vvv??????>}}????v}vv????????????vwzr}vv????`<br></br>
`w}uywwuuxzwv?v}r}v?vv}rwwrzxxxxxxxz}?????}????v??v}}}vvv????????????????x^<br></br>
`}wyu}wrrwwxv..`>??????vzw?zyyyyyyyuxr???+v?????vrrv????????????v??????->y^<br></br>
`rzxzwr}wuyzu}` ~>????rx}??ryyyyyyyuuu}??>?????v}?>>??????vvvvv?vv???>|`+y^<br></br>
`rrwwv?rrv?wuyz|.~>??wx}???vuyyyyyuvuyxv?????>>?>>??v}wwzxxxxxxxzwv???rw}z^<br></br>
 ``--..``..>rxuuv|^>rx}?????ryyyyyyyyyyr??????>>?vrzuxzyyyyyyyyxv????????u^<br></br>
 .```...... `?rzuurvxr???????vwzzzwwwzxz??????vrxyyyyxryyyyyyz}?????????vy^<br></br>
 `---``...`~>vr}vrrxzv??????????????>??v??????}rwwwzzxuuuxzrv???????????vy^<br></br>
.---``-~+?}}}v????rxv???????????????????>+??????>>??????v???????????????zy^<br></br>
.--~^?vvvv????????xz????????????????????>~???????????????????????vv????wyy|<br></br>
.>??v????????????}x}????????????wrv????????????????????>?????????vv??vxyux~<br></br>
.>??>>>>>>>>>>>>>rxv???????????}yyyyuxxzzxxuuuuuuuxr??????????vvvvvvwuyyzu^<br></br>
.-~^^+>>>>>>>>>>>wzv???????????wxxx+~~||^+>?vvwxuyyyxv????}uxuuuyyyyyyyyyz|<br></br>
.|^>????>>>>>>>>>wz???????????+vzzx?          ..`|>ryx????vxuuuuxxwrwzuyyx-<br></br>
.?vvvvvvv??>+>>>+ww???????????`.}wx}`............  .}xv??????vvvvv???}wzzz~<br></br>
.???????????>++++}w??????????>   .-......    ...... `?v??????????????>vwrr~<br></br>
.?>>>>>>>>>>>>>??vw??????????|   ....   .....     ... `>?????????????>?wuz~<br></br>
.^>?>>>>>????????vr??????????`   ... ...........    ...>?????????????>>>rz|<br></br>
.~~^?vv}}}v???????v??????????.   ..  .............   ..>????????????>>>>rr|<br></br>
.~~~~?wxwv???????>>vwv??????+    ..`-`............   ..????????????>>>+>?r~<br></br>
.~~~~~^v??????>>>+}yyy}?????~|>+~+????>+|~`..``-~~-`. .???????????>>>>+++?-<br></br>
.-`-~~~~+?>>>>>>+ryyyyyr????}yw???????????>>>??????>^`-??????????>>>>??>??`<br></br>
.-```-~~~|?????>wyyyxrxyr???uur}vv????????????????>>>+>?????????>>>>+>????`<br></br>
.-````-~~~^v}v?vyyyu}>rxyr??vxyyyyyuuuuxxzzwr}vv?>++>+????????>>>>>+>>>???`<br></br>
-+`````-~~~|}zruyyuz?+>}xy}???vwzwwzzzxuuyyyyyyyuxwrw???????>>>>>>>>>>>>>?`<br></br>
-v``````-~~~|rxxxyx}+>>+vxu>>????????????v}wxuuuuuuxv?????>+++>>+^+++>>>>>.<br></br>


            </div>,

            <div>

`}- ....................>?>~`.  .........  .~?rv?>>>>>>>>>>>>`............ <br></br>
-yx>. ..................>?vv}v>~.. ......-?rxx}v?????????????|............ <br></br>
`zyyx?-.................-????v}wwv>-..->}zxxrv???????????????>............ <br></br>
.>xuyyuv~........````....>??????vwxzvvzzzzwwr}}}vvv???????????`........... <br></br>
`xuyyyyyu}+`......````...^?????vvrzwwr}vvv????????????????????-......`-~~|.<br></br>
-uyuxzuxuyyzv>>+??}w|....~>>?}wzw}v????v}rv??????vvv??????????^|>?v}rrrrvv`<br></br>
-xzrzuzuyyyyuyyyyyyzv?>^~->vzz}vvv??????>}}????v}vv????????????vwzr}vv????`<br></br>
`w}uywwuuxzwv?v}r}v?vv}rwwrzxxxxxxxz}?????}????v??v}}}vvv????????????????x^<br></br>
`}wyu}wrrwwxv..`>??????vzw?zyyyyyyyuxr???+v?????vrrv????????????v?????>->y^<br></br>
`rzxzwr}wuyzu}` ~>????rx}??ryyyyyyyuuu}??>?????v}?>>??????vvvvv?vv???>|`+y^<br></br>
`rrwwv?rrv?wuyz|.~>??wx}???vuyyyyyuvuyxv?????>>?>>??v}wwzxxxxxxxzwv???rw}z^<br></br>
 ``--..``..>rxuuv|^>rx}?????ryyyyyyyyyyr??????>>?vrzuxzyyyyyyyyxv????????u^<br></br>
 .``.`..... `?rzuurvxr???????vwzzzwwwzxz??????vrxyyyyxryyyyyyz}?????????vy^<br></br>
 `---``...`~>vr}vrrxzv??????????????>??v??????}rwwwzzxuuuxzrv???????????vy^<br></br>
.---``-~+?}}}v????rxv????????????????????+??????>>??????v???????????????zy^<br></br>
.--~^?vvvv????????xz????????????????????>~???????????????????????vv????wyy^<br></br>
.>??v????????????}x}????????????wrv????????????????????>?????????vv??vxyux~<br></br>
.>??>>>>>>>>>>>>>rxv???????????}yyyyuxxzzxxuuuuuuuxr??????????vvvvvvwuyyzu^<br></br>
.-~^^+>>>>>>>>>>>wzv???????????wxxx+~~||^+>?vvwxuyyyxv????}uxuuuyyyyyyyyyz|<br></br>
.|^>????>>>>>>>>>wz???????????+vzzx?          ..`|>ryx????vxuuuuxxwrwzuyyx-<br></br>
.?vvvvvvv??>+>>>+ww???????????`.}wx}`............  .}xv??????vvvvv???}wzzz~<br></br>
.???????????>++++}w???????????.  .-.....    ....... `?v??????????????>vwrr~<br></br>
.?>>>>>>>>>>>>>??vw??????????|   ....   .....     ... `>?????????????>?wuz~<br></br>
.^>?>>>>>>???????vr??????????`   ... ...........    ...>?????????????>>>rz|<br></br>
.~~^?vv}}}v???????v??????????.   ..  .............   ..>????????????>>>>rr|<br></br>
.~~~~?wxwv???????>>vwv??????+    ..`-`............   ..????????????>>>+>?r~<br></br>
.~~~~~^v??????>>>+}yyy}?????~|>+~+????>+|~`..``-~~-`. .???????????>>>>+++?-<br></br>
.-`-~~~~+?>>>>>>+ryyyyyr????}yw???????????>>>??????>^`-??????????>>>>??>??`<br></br>
.-```-~~~|?????>wyyyxrxyr???uur}vv????????????????>>>+>?????????>>>>+>????`<br></br>
.-````-~~~^v}v?vyyyu}>rxyr??vxyyyyyuuuuxxzzwr}vv?>++>+????????>>>>>+>>>???`<br></br>
-+`````-~~~|}zruyyuz?+>}xy}???vwzwwzzzxuuyyyyyyyuxwrw???????>>>>>>>>>>>>>?`<br></br>
-v``````-~~~|rxxxyx}+>>+vxu>>????????????v}wxuuuuuuxv?????>++>>>+^+++>>>>>.<br></br>


            </div>,

            <div>

.?>+>?+`................~>>>^-.. ......... .-?}}?>>>>>>>>>>>>~............ <br></br>
.vv?>?v?+`..............~?vvvvv?^-.......->}xxrv?????????????>............ <br></br>
.vrxxwvvv?>-............`>????v}rrv>|``+vzxxrvvvv?????????????............ <br></br>
.ruyyyuwvvvv?^`.``-~^++..~???????vwxrrzxzwwr}}vvvvv???????????-........... <br></br>
-uyyyuuyuzrvvvv?vvv}}}?..`>????v}rzzw}}vvv????????????????????|......`-~||.<br></br>
-uyuzzuuxyyuzrvv}wzxv>~`.`^>?}wzw}v????vvr}??????vvv??????????+|>?v}rwrrvv-<br></br>
-uxwzuuuxuuxxxrvwwr}vvvv?>?vzzw}vvvv?????vr?????vvv????????????vrwr}v?????`<br></br>
-xwzuuxuzzzzwr>^>v???vvvvwzzxuuuuuxxwv????v???????v}}}vvv???????????????>?`<br></br>
-wwxuzzwrrzuw?+.->?????vzwv}uyyyyyyyuzv?????????vrwv????????????v?????>-~v~<br></br>
`wzzzwwvvzzwxzv>-~>???rxr??vxyyyyyyuuuzv??>????v}v>>??????vvvvvvvvv???^`^}|<br></br>
.??>?>>+^^|?wux}?+^>?rx}????wyyyyyywxyu}??????>?>>??v}wzzxxxxxxxxwv???}rrz^<br></br>
 ...`......-+?wxwvv?}x}?????vzyyyyyyyyyzv??????>?vrxuuzuyyyyyyyu}????????z^<br></br>
 .````......`+vwzxwrxw???????v}rrrrrrrwwv??????}xuyyyuzyyyyyyxrv?????????x^<br></br>
 `---``.`-|>v}rvvvvzxv????????????????????????v}}}}rrwzzxxzrv???????????vu^<br></br>
.----~^>?vvvv?????}x}????????????????????^??????????????????????????????zy^<br></br>
.-|+>?vvv?????????zzv????????????????????+???????????????????????vv????ryy^<br></br>
.>???????????????vxw????????????zzr}vv????vvvvvvvvv????>??????????v??vzyuu|<br></br>
.|^+>>>>>>>>>>>>>vx}???????????}yyyxwwrwwzxuuuyyyyuz}?????vvvvvv}}}rzyyyuu^<br></br>
.--~|^++>>>>>>>>>vxv???????????rxxu+..```-~|^>?vrzuyur????}uuuuyyyyyyyyyyu|<br></br>
.|^>??????>>>>>>>vzv??????????+^wxxv. ...........`~>xu}???vwzxuuxxw}wzuuyu~<br></br>
.?vvvvvvvv??>>+>+vz???????????` ^?}v.............. .+xr???????vvvv???vwwzz~<br></br>
.^>?????????>>++>vw???????????.  .......       ......+}??????????????>vzwr~<br></br>
.|~|+>>>>>>>>>???vr??????????^   ....   ......     ....>?????????????>>ruz~<br></br>
.~~|~|>>>>????????vvvv???????`   ... ............   ...^?????????????>+?wx|<br></br>
.~~~|^^?}}v???????}rr}v??????.   .. ..............   ..+????????????>>+>rw|<br></br>
.~~~~^>>vv???????v}wxw}?????+   .`-~|~-`..........   ..>???????????>>>+>>r~<br></br>
.---~~|^^>???>>>vvzyuuz}????^>?>^>?????>^|-``-~||||-. .???????????>>>>>++>-<br></br>
.-`--~~~~-|>>>>vrzyuzzuzv???rywv???????????????????>+`-??????????>>>>?????`<br></br>
.-```--~~-~^vv?wuyyu}?wuzv??wuxxzwwrrr}}}vvvv?????>+++>?????????>>>>>>????`<br></br>
.`````-~~~~^>wzzuuuw?>?ruzv?vrxyyyyuuuuuuuuuxxzzwv???>????????>>>>>>>>>>??`<br></br>
`^`````-~~~|+?zyyuxv>>>>}uv???v}}}}}}}rrrwzxuyyyyuuxzv??????>>>>>+>>>>>>>?`<br></br>
-v``````-~~~|?vxyyx?>>>>>vv>>?????????????vv}rwzxxxzv?????>++>+>>^^^^++>>>.<br></br>


            </div>,

            <div>

.>vrrwv-  ...............+???^`.  ........ .`>}}?>>>>>>>>>>>>^............ <br></br>
 .`?xuyuv-. .............^??vvr}?^`. ....`+vzz}v??????????????............ <br></br>
.|>wuuyyyuv|. ...........`?????vrxz}?-`+vwxxwvvvvvv???????????`........... <br></br>
-yyyyyyuuyyyw?~.`-~^>?v`..+???????rzxzwzzwwr}}vvvvv???????????~........... <br></br>
-uyyyuzxuzuyyyyzxuuyyyw...|????v}wwwr}vvvv????????????????????^.....``-~|^.<br></br>
-uyuwruywxyyyxwzxxxzzr^``.->?}wzrvv?????v}r???????vv??????????>|>?vrwwrrvv-<br></br>
-uuw}uyu}zzzwr>-?vv?v}rrrv?vzzwr}}}}v????>}v????v}vv????????????}wrvv?????.<br></br>
-uz}zyyxrwrzxu~.~???????vzzrxuuuuuuuxr?????v????v?v}}}vvv???????????????+. <br></br>
-xw}zuxzrrxyuxv..^?????vzwv?xyyyyyyyyur???>??????rwv????????????v?????>- .`<br></br>
-zzwwzzv?}v}wuy}-.^???}xr???wyyyyyyxwyuv??>?????}v>>??????vvvvvvvvv??>+~-~~<br></br>
.||~~~|....~vzxyu?~>>}xr????vyyyyyyxwyyz??????>?>>??v}wzzxxxxxxxxzv???vrrr|<br></br>
 ........``..^vwxyxvvxw??????}uyyyyyyyyuv??????>?vrzuuwuyyyyyyyurv???????}^<br></br>
 .````......`~?rrrwwxzv???????vvvvvvvvv}v??????}zuyyyyzuyyyyyxrv?????????z^<br></br>
 ``--``.`~>vrr}vv??wx}?????????????????????????vvvvvv}rwzzwrv???????????>z^<br></br>
                  .-``-|+?vvvv??????vxw????????????????????~??????????????????????????????wy^<br></br>
.~^>??v???????????wxv???????????v????????>????????????????????????v????}yy^<br></br>
.>????????????????zzv???????????xyxwr}vvvvvv}}}}vvvv???>>?????????v??vzyuu|<br></br>
.~~|^+>>>>>>>>>>>?xw???????????vyyuwvvv}rwzxuyyyyyyuw?????v}}}}}rrrwzyyyxx^<br></br>
.-~~^+>>>+>>>>>>>?u}???????????}zzx>     ...`-|+?}zuyx????}yyyyyyyyyyyyyyx~<br></br>
.|^>?vv}}v?>>>>>>?uv??????????^-rxxz~ .........   .-wuz????}wzxxxzw}rzxuyx~<br></br>
.?vvvvvvv}}vv?>++>xv??????????` `+??`.............. -wzv???????vv????vrwzz~<br></br>
.|+????????????>>?wv??????????.     ...         .....|vv?????????????>vzwr~<br></br>
.~~~^>>>>>>>>>????rv?????????^   ....   .......    ....|?????????????>>}ux~<br></br>
.~~~~|>?>>????????vrwv???????`   ... ............    ..~????????????>>+?rz|<br></br>
.-~~~~~?r}????????wyyuv??????.   .................   ..~????????????>>+>rw|<br></br>
.-`-~~~|?v????>>>zyyyyu?????+ ...-|++^~-..........    .^???????????>>>++>r~<br></br>
.-``-~~~-^?>>>>>zyyyxwuz????>}}?>???????>^|~~|^^^^^|`..>??????????>>>>>++?-<br></br>
.-```-~~~~~>>??ryyyuvvzuw???wuv????????????????????>>~-?????????>>>>+?????`<br></br>
.`````-~~~~^vv?uyyuw?+vzyw??vxyuuxzzzwwrr}vvv?????++++>????????>>>>+>>???>`<br></br>
.``````-~~~~+zuxxuxv>>^vxyv???ruuuuuuuuuyyyyyuuxxwv???>??????>>>>>+>>>>>??`<br></br>
`|``````-~~~~^zyuxw?>>>+?z?>????v?????vvvv}wxuyyyyyyxv??????>>>>+++>>>>>>?`<br></br>
-v```````-~~~~|zyyz>>>>>+>+>>>>?????>>>>?????vv}rwwr?????>>>>>++>>++^^++>>.<br></br>


            </div>,

            <div>

.>vrrwv-  ...............+???^`.  ........ .`>}}?>>>>>>>>>>>>^............ <br></br>
 .`?xuyuv-. .............^??vvr}?^`. ....`+vzz}v??????????????............ <br></br>
.|>wuuyyyuv|. ...........`?????vrxz}?-`+vwxxwvvvvvv???????????`........... <br></br>
-yyyyyyuuyyyw?~.`-~^>?v`..+???????rzxzwzzwwr}}vvvvv???????????~........... <br></br>
-uyyyuzxuzuyyyyzxxuyyyw...|????v}wwwr}vvvv????????????????????^.....``-~|^.<br></br>
-uyuwruywxyyyxwzxxxzzr^``.->?}wzrvv?????v}r???????vv??????????>|>?vrwwrrvv-<br></br>
-uuw}uyx}zzzwr>-?vv?v}rrrv?vwzwr}}}}v????>}v????v}vv????????????}wrvv?????.<br></br>
-uz}zyyxrwrzxu~.~???????vzzrxuuuuuuuxr?????v????v?v}}}vvv???????????????+. <br></br>
-xw}zuxzrrxyuxv..^?????vzwv?xyyyyyyyyur???>??????rwv????????????v?????>- .`<br></br>
-zzwwzzv?}v}wuy}-.^???}xr???wyyyyyyxwyuv??>?????}v>>??????vvvvvvvvv??>+~-~~<br></br>
.||~~~|....~vzxyu?~>>}xr????vyyyyyyxwyyz??????>?>>??v}wzzxxxxxxxxzv???vrrr|<br></br>
 ........``..^vwxyxvvxw??????}uyyyyyyyyuv??????>?vrzuuwuyyyyyyyurv???????}^<br></br>
 .````......`~?rrrwwxzv???????vvvvvvvvv}v??????}zuyyyyzuyyyyyxrv?????????z^<br></br>
 ``--``.`~>vrr}vv??wx}?????????????????????????vvvvvv}rwzzwrv???????????>z^<br></br>
                  .-``-~+?vvvv??????vxw????????????????????~??????????????????????????????wy^<br></br>
.~^>?vv???????????wx}???????????v????????>????????????????????????v????}yy^<br></br>
.>????????????????zzv???????????xyxwr}vvvvvvv}}}vvvv???>>?????????v??vzyuu|<br></br>
.~~|^+>>>>>>>>>>>?xw???????????vyyuwvvv}rwzxuyyyyyyuw?????v}}}}}rrrwzyyyxx^<br></br>
.-~~^+>>>+>>>>>>>?u}???????????}zzx>     ...`-|+?}zuyx????}yyyyyyyyyyyyyyx~<br></br>
.|^>?vv}}v?>>>>>>?uv??????????^-rxxz~ .........   .-wuz????}wzxxxzw}rzxuyx~<br></br>
.?vvvvvvv}}vv?>++>xv??????????` `+??`.............. -wzv???????vv????vrwzz~<br></br>
.|+????????????>>?wv??????????.     ....        .....|vv?????????????>vzwr~<br></br>
.~~~^>>>>>>>>>????rv?????????^   ....   .......    ....|?????????????>>}ux~<br></br>
.~~~~|>?>>????????vrwv???????`   ... ............    ..~????????????>>+?rz|<br></br>
.-~~~~~?r}????????wyyuv??????.   .................   ..~????????????>>+>rw|<br></br>
.-`-~~~|?v????>>>zyyyyu?????+ ...-|++^~-..........    .^???????????>>>++>r~<br></br>
.-``-~~~-^?>>>>>zyyyxwuz????>}}?>???????>^|~~|^^^^^|`..>??????????>>>>>++?-<br></br>
.-```-~~~~~>>??ryyyuvvzuw???wuv????????????????????>>~-?????????>>>>+?????`<br></br>
.`````-~~~~^vv?uyyuw?+vzyw??vxyuuxzzzwwrr}vvv?????++++>????????>>>>+>>???>`<br></br>
.``````-~~~~+zuxxuxv>>^vxyv???ruuuuuuuuuyyyyyuuxxwv???>??????>>>>>+>>>>>??`<br></br>
`|``````-~~~~^zyuxw?>>>+?z?>????v?????vvvv}wxuyyyyyyzv??????>>>>+++>>>>>>?`<br></br>
-v```````-~~~~|zyyz>>>>>+>+>>>>?????>>>>?????vv}wwwr?????>>>>>++>>++^^++>>.<br></br>


            </div>,

            <div>

.|?v}rv|`................^???^`.  ........ .`>}}?>>>>>>>>>>>>^............ <br></br>
 .`?zuyu}|`..............^??vvr}?^`......`+vzz}v??????????????............ <br></br>
.+?wuuyyyu}+-..........`.`?????vrzzr?~`^vzxxz}vvvvv???????????`........... <br></br>
-uyyyyyuuyyyz?^~~|+??vv~..+???????}zxzwzzwwr}}vvvvv???????????~........... <br></br>
-uyyyuzxuzuyyyyxxuuuyyw`..|????v}wzwr}vvvv????????????????????+.....``-~|^.<br></br>
-uyuzruywxuuyxwrwwwwzr+-`.->?}wzrvv?????v}r???????vv??????????>|>?vrrwrrvv-<br></br>
-uuz}uyuwwzwww?-?????vrwrv?vwzwr}}}}v????>}v????v}vv????????????}wrvv?????.<br></br>
-xurwuyuwrrzxu|.~???????vzzrxuuuuuuuxr?????v????v?v}}}vv????????????????+. <br></br>
-uxrzxxzr}xyuxv`.|?????vzwv?xyyyyyyyyuw???>??????}wv????????????v?????>-..`<br></br>
-zwwwwwv?v?vwuyr~.^???}xr???wyyyyyyxwyuv??>?????}v>>???????vvvvvvvv??>^~-~~<br></br>
.|---~~....`?wxyu?^>>}xr????vuyyyyyxwyyz??????>?>>??v}wzzxxxxxxxxz}???vrrr|<br></br>
 ........``..~?}xyx}vxw??????vuyyyyyyyyuv??????>?vrzuuzuyyyyyyyurv???????}^<br></br>
 .````......`|?rr}wwxzv???????vvvvvvvvv}v??????}zuyyyyzuyyyyyxrv?????????z^<br></br>
 ``--``.`|>v}r}vv??wxv?????????????????????????vvvvvvvrrzzwrv???????????>z^<br></br>
                  .-``-|+?vvvv??????vxw????????????????????~??????????????????????????????wy^<br></br>
.~^>??v???????????wx}???????????vv???????>????????????????????????v????}yy^<br></br>
.>????????????????zzv???????????xyxzw}}vvvvv}}}}vvvv???>>?????????v??vzyuu|<br></br>
.~~|^+>>>>>>>>>>>>xw???????????vyuywvvvv}rwzxyyyyyyyw?????v}}}rrrrwwzyyyxx^<br></br>
.-~~^++>>>>>>>>>>?x}???????????}xxx>     ...`-~+?vwuyx????}yyyyyyyyyyyyyyx~<br></br>
.~^>?vv}}v??>>>>>?xv??????????^`}zxz| .........   .`ryz????}wzxxxzw}rzxuyx~<br></br>
.^>?vvvvv}}vv?>++>xv??????????` .|>>`.............. `wzv???????vv????vrwzz~<br></br>
.~|+>??????????>>?zv??????????.     ...         .....~vv?????????????>vzwr~<br></br>
.~~~|^>>>>>>>>????rv?????????^   ....  ........    ....|?????????????>>}ux~<br></br>
.~~~~~|>>?????????vwzv???????`   ... ............    ..~????????????>>+?rz|<br></br>
.--~~~~>}}????????zyyuv??????.   .................   ..~????????????>>+>rw|<br></br>
.``-~~~|>?????>>?xyyuyuv????+ ...-|++^|-`.........    .^???????????>>>++>r~<br></br>
.```-~~~-^>>>>>>xyyyzwuz?????}}?>???????>+|~~|^^++^|`..>??????????>>>>>++?-<br></br>
 ````-~~~~~>???ryyyuvvzuw???wuvv???????????????????>>~~?????????>>>>+?????`<br></br>
 `````-~~~~|v}vuyyuw?+vzyw??vxyuuuxxxzwwrr}}vv????++++>????????>>>>+>>???>`<br></br>
.``````-~~~~^zuxxuxv>>^?zyv???rxuuuuuuuuuuyyyyuuxw}???>??????>>>>>+>>>>>??`<br></br>
`|``````-~~~~^wyuxw?>>>+?w?>???????????vvvvrzxuyyyyyxv?????>>>>>+++>>>>>>?`<br></br>
-v```````-~~~~|wyyx?>>>>+>+>>>>?????>>>>?????vv}rwwr?????>>>>>++>>++^^++>>.<br></br>


            </div>,

            <div>

   .^v}rw?.  ............`????+-.  ....... .`>}}v>>>>>>>>>>>>>`........... <br></br>
.``-vxuyyyz?`. ........ ..???v}rr?+`.....`>vzzrv??????????????~........... <br></br>
-xxuyyyyyuyyuv^`..``-~|>|.^?????vrxx}>-+vzxxz}vvvvv???????????^........... <br></br>
`wuyyyyuzuxxyyyxwzxuuyyy> ~???????vwxxzzwwr}}vvvvv????????????>........... <br></br>
`xyyyxwzyxzyyyyuxxxxxx}^..`>???v}wwwrvvvvvv???????????????????>`....``-~|^.<br></br>
-yyyx}zyy}zxzzw??vv}rr?^~``+?vwzrv???????vwv??????vvv??????????|>?v}rwrrvv-<br></br>
`uyu}ryyu}wrwzz`~????v}rww}vwzzwrrrr}v?????v????vvvv????????????vrrvv?????.<br></br>
`xyzvzyyuw}zyyr`.^???????rz}zuyyyyuuuzv???>v????v?v}}}vv????????????????^. <br></br>
-uyzwzxzz}rxzzur`.+????vzzv?wyyyyyyyyuxv??>??????}w}????????????v?????>-.. <br></br>
-x}vvvv}>-|^}xuyz|`>??}xwv??vyyyyyyu}uuw??>>??>?v}?>????????vvvvvvv??>^~--.<br></br>
`^..........-?wxuuv>+vxw?????wyyyyyyzyyuv?????>>?>??vvrzzxxxxxxxxz}???v}}}-<br></br>
 ........`... -?wzuu}xwv??????zuuxxxuuuuw??????>?vrzxuzzyyyyyyyuwv???????v^<br></br>
 .````......-+?}rvvvxxv????????????????vv??????}zxuuuyxxyyyyyxrv?????????r^<br></br>
 ``-````-+?v}}vv???rx}???????????????????????????????vv}rrrrv???????????>z^<br></br>
                  .-`-~^??vvv???????vxzv???????????????????|+?????????????????????????????wy^<br></br>
.^>???????????????}x}???????????v}????????????????????????????????v????vyy|<br></br>
.^+>>>>>>>>>>>>>>>rxv???????????xyyuxzwrrrrrrwwrrrr}????>?????????v??vzuuu|<br></br>
.-~~~^++>>>>>>>>>>wz???????????vyuu}^++>>??vrwxyyyyyxv????vwwwwwwwwzxyyyux|<br></br>
.~~~|^+>>>>>>>>>>>zw????????????zzx?        ...`~+?ryuv???}yyyyyyyyyuyyyyu~<br></br>
 -|>?vv}rr}??>>>>>zr??????????+.?wzx+ ..........    ?ux????vrwzxxzr}}zxuyx~<br></br>
.~|>vvvvvvv}vv?>++rr??????????-  .-~.................}wv???????vv????vrwzz~<br></br>
.~~~^?????????????}}??????????.   .....           ...`vv?????????????>vzwr~<br></br>
.~~~~~+>>>>>>>????vzzv???????+   ....  .........    ...~?????????????>>}ux~<br></br>
.-~~~~~+?????????vxyyuv??????-   ... ............    ..-????????????>>+vrz|<br></br>
.``-~~~~>v???????uyyyyx??????.   .................   ..~????????????>>+>rw|<br></br>
.```-~~~~^???>>>xyyyzzyr????>.```|+>>>^~`..........   .|???????????>>>+++v~<br></br>
.````-~~~~~>>++ryyyxvrzuv????zv?????????>+^^^++>>>>^-. ^?????????>>>>>?>>>`<br></br>
.`````-~~~~|vvvuyyuw??wxuv??vurvvvv????????????????>>||?????????>>>>+>????`<br></br>
 ``````-~~~~+xyxxuxv?>>wuz???}uyyyyyuuuuxxzwr}vvv?>+++>????????>>>>+>>>??>`<br></br>
.-``````-~~~~^zyuzw?>>+>}z>???vrwwwwwwwwwzxuyyyyuuxw}v???????>>>>>+>>>>>??`<br></br>
->```````-~~~~^wyyz>>>>>+>+>>??????????????vv}zuuyyywv?????>>>>>>++>>>>>>?`<br></br>
`w-```````-~~~~|wuyw>>>>>>++++>>??????>>>>??????vvvv????>>>>>>+>?>>+^^^+>+.<br></br>


            </div>,

            <div>

   .^v}rw?.  ............`????+-.  ....... .`>}}v>>>>>>>>>>>>>`........... <br></br>
.``-vxuyyyz?`. ........ ..???v}rr?+`.....`>vzzrv??????????????~........... <br></br>
-xxuyyyyyuyyuv^`..``-~|>|.^?????vrxx}>-+vzxxz}vvvvv???????????^........... <br></br>
`wuyyyyuzuxxyyyxwzxuuyyy> ~???????vwxxzzwwr}}vvvvvv???????????>........... <br></br>
`xyyyxwzyxzyyyyuxxxxxx}^..`>???v}wwwrvvvvvv???????????????????>`....``-~|^.<br></br>
-uyyz}zyy}zxzzw??vv}rr?^~``+?vwzrv???????vwv??????vvv??????????|>?v}rwrrvv-<br></br>
`uyu}ryyu}wrwzz`~????v}rww}vwzzwrrrr}v?????v????vvvv????????????vrrvv?????.<br></br>
`xyzvzyyuw}zyyr`.^???????rz}zuyyyyyuuzv???>v????v?v}}}vv????????????????^. <br></br>
-uyzwzxzz}rxzzur`.+????vzzv?wyyyyyyyyuxv??>??????}w}????????????v?????>-.. <br></br>
-x}vvvv}>-|^}xuyz|`>??}xwv??vyyyyyyu}uuw??>>??>?v}?>????????vvvvvvv??>^~--.<br></br>
`^..........-?wxuuv>+vxw?????wyyyyyyzyyuv?????>>?>??vvrzzxxxxxxxxz}???v}}}-<br></br>
 ........`... -?wzuu}xzv??????zuuxxxuuuuw??????>?vrzxuzzyyyyyyyuwv???????v^<br></br>
 .````......-+?}rvvvxxv????????????????vv??????}zxuuuyxxyyyyyxrv?????????r^<br></br>
 ``-````-+?v}}vv???rx}???????????????????????????????vv}rrrrv???????????>z^<br></br>
                  .-`-~^??vvv???????vxzv???????????????????|+?????????????????????????????wy^<br></br>
.^>???????????????}u}???????????v}????????????????????????????????v????vyy|<br></br>
.^+>>>>>>>>>>>>>>>rxv???????????xyyuxzwrrrrrrwwrrrr}????>?????????v??vzuuu|<br></br>
.-~~~^++>>>>>>>>>>wz???????????vyuu}^++>>??vrwxyyyyyxv????vwwwwwwwwzxyyyux|<br></br>
.~~~|^+>>>>>>>>>>>zw????????????zzx?        ...`~+?ryuv???}yyyyyyyyyuyyyyu~<br></br>
 -|>?vv}rr}??>>>>>zr??????????+.?wzx+ ..........    ?ux????vrwzxxzr}}zxuyx~<br></br>
.~|>vvvvvvv}vv?>++r}??????????-  .-~.................}wv???????vv????vrwzz~<br></br>
.~~~^?????????????}}??????????.   .....           ...`vv?????????????>vzwr~<br></br>
.~~~~~+>>>>>>>????vzzv???????+   ....  .........    ...~?????????????>>}ux~<br></br>
.-~~~~~+?????????vxyyuv??????-   ... ............    ..-????????????>>+vrz|<br></br>
.``-~~~~>v???????uyyyyx??????.   .................   ..~????????????>>+>rw|<br></br>
.```-~~~~^???>>>xyyyzzyr????>.```|+>>>^~`..........   .|???????????>>>+++v~<br></br>
.````-~~~~~>>++ryyyxvrzuv????zv?????????>>^^^++>>>>^-. ^?????????>>>>>?>>>`<br></br>
.`````-~~~~|vvvuyyuw??wxuv??vurvvvv????????????????>>||?????????>>>>+>????`<br></br>
 ``````-~~~~+xyxxuxv?>>wuz???}uyyyyyuuuuxxzwr}vvv?>+++>????????>>>>+>>>??>`<br></br>
.-``````-~~~~^zyuzw?>>+>}z>???vrwwwwwwwwwzxuyyyyuuxw}v???????>>>>>+>>>>>??`<br></br>
->```````-~~~~^wyyz>>>>>+>+>>??????????????vv}zuuyyywv?????>>>>>>++>>>>>>?`<br></br>
`w-```````-~~~~|wuyw>>>>>>++++>>??????>>>>??????vvvv????>>>>>>+>?>>+^^^+>+.<br></br>


            </div>,

            <div>

 ...`|+>>^. ..............^^^|-..  ....... .`~^++^^^^^||||^^^|............ <br></br>
 `.`^???vv?|`.............^>++>>+|-.......-^>?>+>>>>>>>>>>>>>>`........... <br></br>
.????vvvv?vv?>~....```-|-.`^+++++>?>^-.`^>>>>>>>>>>>>>>>>>>>>>`........... <br></br>
.+?vvvv?????vvv???vvvvvv+-`-+++++^+???>>>>>>++>>>>>>>>>>>>>>>>~........... <br></br>
.?vvv?>?v??vvvv????????+||~`~++>>????>++>>>>??vvv???+>>>>>>>>>~.......``--.<br></br>
.?vv?^>vv>>>>>>||^^+>>>+^^~`~>??>>^^++++++????vv}}}v?>>>>>>>>>^-|+>??????>.<br></br>
.?v?+^?v?^+++>?``^+^^+>????+>vr}vvv?+^++>|^>+>??v}}v?>+>>>>>>>>>>????>>>>^.<br></br>
.?v?|+?v?^^?vv?`.`|^^++++???wuuuuuxzv>+++^->????v?????++^++++>>>>++>>>>+~. <br></br>
.?v>^+>>+^+>??v?-``~^++>???vxyyyyuuxz??++^~v}vvvvvv?|^++++++++>+>>>+++~`.. <br></br>
.?+^^^^^~```^>?v?|..-^?v?+??vuyyyyyx???>^>>?}}v}rr^`~^++++++++++++++>|~-``.<br></br>
.-...........~^>??>~`>v?+^+??rxxxxwv????+>??vrv}?~-^++>>??????????>+>>>???`<br></br>
 ........`....`|>>???v?+++++>>?vvv???????+>????+|^>?????vvv}}}r}?>>>>>>+>>-<br></br>
 .````......`~+>?+^>??>++++++^^^^^^||||^+^^^^++>?????vvv}}}}}v??>>>>>>>>+?-<br></br>
 ````````~+>?>++^^^>??+++++++^^++++^||~~^||^+^^^^^^^++>??????>>>>>>>>>>>^?-<br></br>
.```-~^>>?>>>>+^^^^??>>+++++++>?>>>+^^^^|`-^++^^^^^^^^+>???????>>>>>>>>>?}-<br></br>
.|^+>>>>>>>>>>^^^^>????+++++?v}}rwv??>>++^^^>??+^^^+++++?????????>?>>>>?}}-<br></br>
.~~~|||||||||~-```>??>+++++?}}vvzxzzwwrrrrrwwww?>>>>>>?>>?vvvvv????>>?v}}v-<br></br>
.-----~~~~|~~`````??>+++++???>^^?>?>~|^++>vrwww}?????>???v}wwwwwrrr}wwwrr}-<br></br>
.~~-~~||||~~-`````??+++++++|~~-.``-`    .`~-`..`|^+>??????}uuuuuuuuxxxxxxz~<br></br>
.-~|+>>>??>^-````-??++++^|-~^^~ `---.  .........````+??>???v}rwzwwwwwzxxux~<br></br>
.-~^>>>>>>++++^~--??++++^|+>??|...``..```.......---`->?>???????vvvvvvrwzzx~<br></br>
.-~~~+++^^^++>>+^^??+++++>????|~~~||~~~~-``..  .````-~>>?????vvvvvvvvvrxzz~<br></br>
.~~~~~^^^+++>>++++>??>++>??v}v++>>>>>^^^++^|--`.``````-->????vvvvvvvv??rxx~<br></br>
.~||^^^+>>>??>++++????+++??vv+~~~~~~--~~||^++~-````````-++>>>???????+^^?v}~<br></br>
.|^+>??>+?v?>^|~^??????+++>>+`````````````--```--``````-^++++++>?vv???>?}r|<br></br>
.>>???>++++|~-`~????????++++|`---~~|||~-``````----`````~^++++>????>>?vvvvw~<br></br>
.??>>+^+++|-``-?????>???>++++?>++++++++^^^|||||^^^||-``~^++++++>^||^+>??v}~<br></br>
.^^^^^^^+++^>>>?????+^???+++>??>>>>>>>>>++++++++++^|||||+++++++|-``--~>?>?-<br></br>
.^^^^^^^+++++??????>^-|???+>+>???????????????>>>>>^||||^+++++^-```````-^^+`<br></br>
.^^^^^^^^+++++?????|```|>?~|^++>????????????????????>>^>+++^-``--``-----|+.<br></br>
.+^^^^^^^^+++^+????|`--`-|---~^++++^^^^^^+++>>???????++++^~`````-~-------|.<br></br>
.>^^^^^^^^^+++++???>--------``--|^^|~~---|^+++++>>>>++^|~-`````-|^^^|~-```.<br></br>


            </div>,

            <div>

                  .....................  .....                ............................. <br></br>
                  ........................  .                 ............................. <br></br>
                  ........................``...`.....          ...........``............... <br></br>
 .................`.`~^>>>>>>>>>>>>>^--``````--~~~-`...`````.............. <br></br>
 ................``|^^>>>>>>>>>>>>>>>>>>>>>?vwzxxz}?|.```````............. <br></br>
 ................`^^^^>>>>>>>++^^^^+>>>>>>>>>>?vwuyu?`.````````........... <br></br>
 ................~+^^+>>>>>+|vzzwwrv|+>>>>>>++^^^?vxz|...```````````````.. <br></br>
 ................^^^^+>>>>++wyyyyuyy>~>>>>>+ruzwv^>>v?.............``````. <br></br>
 ...............`^^^^>>>>>+?uyyyuryyw->>>>+}yzuyyv^>>>`.............`````. <br></br>
 ...............-+^^^>??>>>^>xyyyyyu?|+>>>>?yxuyyy++>>~..............````. <br></br>
 ...............|+^^+>>?>>>>+>?????>+++>>>>-vuuux?+>>>^......``....``````. <br></br>
 ...............^^^^+>>>>>>>>>>++++>>++>>>>+|^+>>+>>>>>`..```--``````````. <br></br>
 ..............`^^^^>>>>>>>>>>>>>>>>>+^>>>>>>>+>>>>>>>>-`````---`````````` <br></br>
 ````..........~+^^^>>>>>>>>>++>>>>>>+^^^^^+>>>>>>>>>>>|``````--``````````.<br></br>
 ```````````...|+^^+>>>>>>>>>>?}?>?>+>>>>>+>>>>>>>>>>>>+|^|||~--``````````.<br></br>
 `````````````-^^^^>>??>>>>>>wyyyyyurvv??????}v>>>>>>>>>?????>^~-````---``.<br></br>
.`````````````|^^^+>>>>>>>>>wxv>>???vv}}}}rxyyy?>>>>>>>>?}}rr}v>^~~------`.<br></br>
.``-----------^^^^+>>>>>>>>^>.         ..`vzzzr?>>>>>>>>?zwwwwr}v??>^|~~--.<br></br>
.------------|^^^^>>>>>>>>|            ..^v?|`.->>>>>>>>?rrrrrrrrrr}vv????`<br></br>
.~~---------|^^^^>>>>>>>>>` .```..     ..  .   ->>>>>>>>>v}}}}rrwwwzzzzzzw~<br></br>
.~~~~------|^^++>>>>>>>>>>-|+>???>+|-~~^+|`..  ~>>>>>>>>>?vvvvv}rwwzzzxxxx~<br></br>
.~|~~~~~~|^++>>>>>>>>>>>>>>?vvv}}}}}}vvv???>-  ->>>>>>>>>>>?vv}rwwzzzzzzzx~<br></br>
.|^^^^^|+>>>??>>>>>>>>>>>>vuyyyyyyuuuxxxxxxxzr>|>>>>>>>>>>>>??v}rrwwwwwwww~<br></br>
.^^++>>??>?ww?>>>>>>>>>>>>>??vv?????>>>>??v}zz?>>>>>>>>>>>>>>>>>>>>>????>>`<br></br>
.??vrzuw??}}?>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>>>>?}rwzxxxxxx|<br></br>
`xyyyyr>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++++>>>>>>>>>>>>>>>>>>>>>>>>??v}zuyyy^<br></br>
-uxw}?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}w~<br></br>
`??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^+>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^^++>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^^^^^^+>>>>>>>.<br></br>


            </div>,

            <div>

                  .....................  .....                 ............................ <br></br>
                  ........................  .                 ............................. <br></br>
                  ...................................          ............................ <br></br>
 ...................`-|>>>>>++++^^^^~```..```---~~-`....```............... <br></br>
 ................``~^^+>>>>>+++^^^^+++++++>?vrwzzwv?|.``````.............. <br></br>
 ................`|^^^>>>>>>+^|~~~~|^++++++>>>?vrxux?`.``````............. <br></br>
 ................~^^|^>>>>>^~?r}}vv?-^++++++^^||^>vww|..``````````````.... <br></br>
 ................|^||^>>>>+^}xxxzzxx+`+++++^vzr}v^+>v>...........````````. <br></br>
 ...............`^^||+>>>>+>zxxxz}xxr`^+++^vuwzuuv^+>>`............``````. <br></br>
 ...............-^^^^>>>>>>^+zuuuuux>~^>+++?uzxxux^^>>~.............`````. <br></br>
 ...............~^^^^>>>>>>>++?????+^^^>>>>-?xxxz?^+>>^.....```......``... <br></br>
 ...............|^^^+>>>>>>>>>++++++>+^>>>>+~^+>>+>>>>+`.```----`````````. <br></br>
 ..............`^^^^>>>>>>>>>>>>>>>>>+^+>>>+++++>>>>>>>-`--~~~~~--````````.<br></br>
 ``............-^^^^>>>>>>>>++++>>>>>+^^^^^^>>>>>>>>>>>|------~~---```````.<br></br>
 ``````````....|^^^+>>>>>>>>>>?v?>?++>>++++>>>>>>>>>>>>+~~~~~~~~----``````.<br></br>
 ``````````````^^^^+>??>>>>>>}uuuuux}v?????>?v?>>>>>>>>>>>+++^~~---------`.<br></br>
.`````````````~^^^^>>>>>>>>>}wv>>????vvvv}rzxuu?>>>>>>>>??????+|~~~~-----`.<br></br>
.----------``-^^^^+>>>>>>>>^+.         ..`?wrr}>>>>>>>>>?r}vvvv??>^^~----`.<br></br>
.------------~^^^^>>>>>>>>|            ..|?>|`.->>>>>>>>>vvvvvvvvv??>>++++.<br></br>
.-~~~~------~^^^^+>>>>>>>>` .```..     ..  .   ~>>>>>>>>>????vvvv}}}}}}}}v-<br></br>
.-~~~~~~--~|^^^+>>>>>>>>>>`~~|>>>>^|--~|^~`... ~>>>>>>>>>>?>???vrwwrrrrrww~<br></br>
.~~~~~~~~|^++>>>>>>>>>>>>>+???vvvvvvvv?????+`  ~>>>>>>>>>>>?v}rwzzw}}rrrrw~<br></br>
.|||||||++>>??>>>>>>>>>>>>?zuuuuxxxzzzzwzzzzw}+|>>>>>>>>>>>>?v}rwr}}}}}}rr~<br></br>
.^^^++>??>?rr?>>>>>>>>>>>>>???????>>>>>>??v}ww?>>>>>>>>>>>>>>>>??>>>>>>>>>`<br></br>
.>?v}wzr>>vv>>>>>>>>>>>>>>>>>>>>>>>+^+++>>>>>>>>>>>>>>>>>>>>>>>?v}}wwwwwwz~<br></br>
`xuuuu}>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++^+>>>>>>>>>>>>>>>>>>>>>>>>>??vwxuuu|<br></br>
-uxw}?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vr~<br></br>
`???>>>>>>>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?>?>`<br></br>
.>??>>>>>>>>>>>>>>>>>>>>>>>>????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>?>>>>?>>>>>>>>>>>>>>>>>>>>????????>>>>>>>>>>>>>>>>>>>>>>+^+>>>>>>>>>>>>`<br></br>
.>>>>>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??>>>>>>>>?>>>>>>>>>>+++++>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^^^^^++>>>>>>>.<br></br>


            </div>,

            <div>

                  ...........................              ................................ <br></br>
                  ...........................              ................................ <br></br>
                  ...........................              ................................ <br></br>
                  ...........................              ...`.........```................ <br></br>
                  ...............````........              .``````....``````............... <br></br>
                  .................``........               ```````...```````.............. <br></br>
                  ...........................               ...........`````````........... <br></br>
                  ...........................      .   .  .  .............`````.````````... <br></br>
                  ...............-......``-`.  .  ...  ..  . .....................````````. <br></br>
                  ...............`....`|^^+^^-`.. .... ............................```````. <br></br>
                  ...................-^^^^^^++^^~-`......``...`~-.........`-|^^~`....````.. <br></br>
 ..................~+^^^^^^^^^+++^|~`````````-^>v?`.``-|^^^++++^-.``````.. <br></br>
                  .................`^^^^^^^^^^^^^^^+++^|~--``````+?+|^^+^^^^^^^^^^~```````` <br></br>
                  `................|+^^^^^^^^^^^^^^^^^^++++^^^^^^^^^++^^^^^^^^^^^^+|```````.<br></br>
                  ``````..........`^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+|``````.<br></br>
                  ````````````````|+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+|`````.<br></br>
                  ```````````````-^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^----`.<br></br>
                  .||~-````--`````|+^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~----.<br></br>
.^+^^|--`--````-^^^^^^^+>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>|~|||.<br></br>
.^^^^+^^~--````~^^^^^^^>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+^^^^^.<br></br>
.^^^^^^^^^~--`-^^^^^^^+>>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^++??}zz>^^^^^^.<br></br>
.^^^^^^^^^^^|||^^^^^^+>>>>>>>>>>>>>>>>>>>+++^^^^^^^+++++>??}wxuyyyv^^^^^^^.<br></br>
.^^^^^^^^^^^^+^^^^^^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}rwzuyyyv+^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^+>>?>>>>>>>^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>?wuv+^^^^^^^^.<br></br>
.++^^^^^^^^^^^^^^^^+>>>>>>>>>>>++|~---|+>>>>>>>>>>>>>>+++++>>>??+^^^^^^^^^.<br></br>
`?>>++^^^^^^^^^^^^+>>>>>>>>++>>>>>>>+^~-~+>>>>>>>>>^|~~|^^++>>>+^^^^^^^+++.<br></br>
-uzv>>>+^^^^^^^^^+>>>>>>>>++>>>>>>>>>>>>^|>>>>>>>+^^+>>>>>>>>>+^^^^^++>??v~<br></br>
-yyyv>>>>+^^^^^^+>>???>>>+?vzzv>>>>>>>>>+^>>>>>>>>>>>>>>>>>>>+^^^+>>?}zuyy^<br></br>
`xyyw>>v?>>>+++>>>>vv>>>>>rwzzywvr?>>>>+^+>>>>>>>>>??>^>>>>+^^++>?}wzwrvv?`<br></br>
.?vrr>>wy}>>>>>>>>>>>>>>>>>vzzxuyyyxwrv>+>>>>>>>?vr}?>>>>>+^+>>>????>>>>>+.<br></br>
.>>>>>>vxw>>>>>>>>>>>>>>>>>>>??vvvv}}wzrvvvvvvrrzr?>>>>>>+>>>>>>>>>>>>>>+^.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>???>>>>>>>>>>>>>>>>>>>>>>>>>+^^.<br></br>


            </div>,

            <div>

                  ...........................              ................................ <br></br>
                  ...........................              ................................ <br></br>
                  ...........................              ................................ <br></br>
                  ...........................              .............```................ <br></br>
                  ...............``..........              .```.......`````................ <br></br>
                  ..............``````.......               .........```````............... <br></br>
                  ...........................               ..........`````````............ <br></br>
                  ...........................      .   .  . .............``````.```````.... <br></br>
                  ...............-......``-`.  .  ...  ..  . .....................````````. <br></br>
                  ...............`....`|^^^^|-`.. .... ............................`````.`. <br></br>
                  ...................-^^^^^^^^^^~-`......``...`~-.........`-|^^~`...````... <br></br>
 ..................~^^^|^^^^^^^^^^|~`````````-^>v?`..`-|^^^^^^^^-.````.... <br></br>
                  .................`^^^^|^^^^^^^^^^^^^^|~-```````+?^|^^^^^^^^^^^^^~```````. <br></br>
                  .................|^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|``````` <br></br>
                  ````............`^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|``````.<br></br>
                  ``````````.....`|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+|`````.<br></br>
                  ```------``````-^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^---``.<br></br>
                  .||~-`--------``|+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^~----.<br></br>
.^^^^|--------`-^^^^^^^+>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^+++++^^^^^^^+>|~|||.<br></br>
.^^^^^^^~----``~^^^^^^^>>>>>>>>>++^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^+>>+^^^^^.<br></br>
.^^^^^^^^^~-``-^^^^^^^+>>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^++??}zz>^^^^^^.<br></br>
.^^^^^^^^^^^|~|^^^^^^+>>>>>>>>>>>>>>>>>>>+++^^^^^^^+++++>?vvwzuyyyv^^^^^^^.<br></br>
.^^^^^^^^^^^^+^^^^^^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}rwzuyyuv+^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^+>>?>>>>>>>^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>?wuv+^^^^^^^^.<br></br>
.++^^^^^^^^^^^^^^^^+>>>>>>>>>>>++|~---|+>>>>>>>>>>>>>>+++++>>>??+^^^^^^^^^.<br></br>
`?>>++^^++^^^^^^^^+>>>>>>>>++>>>>>>>+^~-~^>>>>>>>>>^|~~|^^++>>>+^^^^^^^+++.<br></br>
-uzv>>>++^++++^^^+>>>>>>>>++>>>>>>>>>>>>^|>>>>>>>+^^+>>>>>>>>>+^^^^^++>??v~<br></br>
-uyyv>>>>+^^^^^^+>>???>>>+?vzzv>>>>>>>>>+^>>>>>>>>>>>>>>>>>>>+^^^+>>?}zuyy^<br></br>
`xyyw>>v?>>>+++>>>>v?>>>>>rwzzywvr?>>>>+^+>>>>>>>>>??>^>>>>+^^++>?}wzw}vv?`<br></br>
.?vrr>>wy}>>>>>>>>>>>>>>>>>vzzxuyyyzwrv>+>>>>>>>?vr}?>>>>>+^+>>>????>>>>>^.<br></br>
.>>>>>>vzw>>>>>>>>>>>>>>>>>>>??vvvvv}wzrvv?vvvrrwr?>>>>>>+>>>>>>>>>>>>>>^^.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>???>>>>>>>>>>>>>>>>>>>>>>>>>+^^.<br></br>


            </div>,

            <div>

                  ................................                  ....................... <br></br>
                  ................................                 ........................ <br></br>
                  ................................                 ........................ <br></br>
                  ................................                 ......`................. <br></br>
                  ................................                ....````................. <br></br>
                  .............`````..............                ..```````................ <br></br>
                  ................................                ...```````............... <br></br>
                  ................................  .   .         .....```````````````..... <br></br>
                  ...............................  ..   .   .     ..............````````... <br></br>
                  ............................... ...  ...  .   . ................``````... <br></br>
                  ................................... .... ...  .  ................````.... <br></br>
 ......................................``.```>|~..................````.... <br></br>
                  .....................................```````??.........``--`````````````. <br></br>
                  ...................................``````````v+......```-----```````````` <br></br>
                  .||~-`............................````````````-v~.``````````-`````````````.<br></br>
.^+++++|-`.......`````.....`````.``````````````~}|-~~~~~~~~----```````````.<br></br>
.^^^^^^++>+^|~```````````````---````````````...`^>???????>>^~-```````---~|.<br></br>
.^^^^^^^^^++>>>>+|~-````````---```````````````````-|?}vv}}v?>|----~|^++>>>.<br></br>
.^^^^^^^^^^^^^+++>>>>>+^^||||||~`````````````````````^w}vv??>>>>>>>+++^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^+++++++++++++-------```````````````}}^++>>>>>++^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++++++++^||~---~~-|>>>>>+++^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++++++++++^^^^^^^^^^^^^^^^^^^.<br></br>
                  .+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>>>>>+>>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
`rv>>>++>>>>++^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.?uv>>>++>>>>>>++^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>vz?>>>^>>>>>>>>>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+.<br></br>
.>>>>>>>+^>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++>>>`<br></br>
.>>>>>>>+^^>>>>>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++>>>>>>>>`<br></br>
.>>>>>>>>+^>>>>>>>>>>>>>>>>>>>>>>>++++++++++++++++++++++++>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>+^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v-<br></br>


            </div>,

            <div>

                  ................................                  ....................... <br></br>
                  ................................                 ........................ <br></br>
                  ................................                 ........................ <br></br>
                  ................................                 ......`................. <br></br>
                  ................................                ....````................. <br></br>
                  .............`````..............                ..```````................ <br></br>
                  ................................                ...```````............... <br></br>
                  ................................  .   .         .....```````````````..... <br></br>
                  ...............................  ..   .   .     ..............````````... <br></br>
                  ............................... ...  ...  .   . ................``````... <br></br>
                  ................................... .... ...  .  ................````.... <br></br>
 ......................................``.```>|~..................````.... <br></br>
                  .....................................```````??.........``--```````````... <br></br>
                  ...................................``````````v+......```-----```````````` <br></br>
                  .||~-`............................````````````-v~.``````````-`````````````.<br></br>
.^+++++|-`.......`````.....`````.``````````````~}|-~~~~~~~~----```````````.<br></br>
.^^^^^^++>+^|~```````````````---````````````...`^>???????>>^~-```````---~|.<br></br>
.^^^^^^^^^++>>>>+|~-````````---```````````````````-|?}vv}}v?>|----~|^++>>>.<br></br>
.^^^^^^^^^^^^^+++>>>>>+^^||||||~`````````````````````^w}vv??>>>>>>>+++^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^+++++++++++++-------```````````````}}^++>>>>>++^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++++++++^||~---~~-|>>>>>+++^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++++++++++^^^^^^^^^^^^^^^^^^^.<br></br>
                  .+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>>>>>+>>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
`rv>>>++>>>>++^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.?uv>>>++>>>>>>++^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.>vz?>>>^>>>>>>>>>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+.<br></br>
.>>>>>>>+^>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++>>>`<br></br>
.>>>>>>>+^^>>>>>>>>>>>>>>>>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++>>>>>>>>`<br></br>
.>>>>>>>>+^>>>>>>>>>>>>>>>>>>>>>>>++++++++++++++++++++++++>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>+^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v-<br></br>


            </div>,

            <div>

                  ....................................                  ................... <br></br>
                  ....................................                  ................... <br></br>
                  ....................................                  ................... <br></br>
                  ....................................                 .``................. <br></br>
                  .................`.................                  .``..........`...... <br></br>
                  ............````````...............                  ````................ <br></br>
                  ...........`-......................                  `````............... <br></br>
                  ...........`......................                   .``````````````..... <br></br>
                  ........................`.........                   .........````````... <br></br>
                  ..................................  .                ...........``````... <br></br>
                  .................................  ..    .    .      ............````.... <br></br>
                  .^~`.............................. ...   ..   ..   . ..............```...` <br></br>
.^>>^`..............................`.  .`.  .... .. ...```-`````````..`~|.<br></br>
.^^+>>^-............................``...`` .```>^....```-----```````-|++^.<br></br>
.^^^^+>>+~`........................````````.````>>.`````````````-~|^+++^^^.<br></br>
.^^^^^+>>>>|`.....````......````..``````````````+>-~~~~~~~--~|^+>>>++^^^^^.<br></br>
.^^^^^^+>>>>>^~-`````````````----.``````````````^?^????>>++>>>>>+^^^^^^^^^.<br></br>
.^^^^^^^^+>>>??vvv??>+^^|~~------.``````````````^v????>>>>>>>++^^^^^^^^^^^.<br></br>
.^^^^^^^^^^++>>??}zuuuxxzwr}v?+^^..`````````````~??>>>>>>>>>++^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^++>>>?}wwwrr}}}}v??~.````````````.`^?>>>>>>>>>>>>>+++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+>>>>>>>>>>>>++>>>..````````````~>?>>>>>>>>>>>>>>>>>++^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>>>>>>>>>>>>>+^+-.`````````````|r>>++>>>>>>>>>>>>>>>>+^^.<br></br>
.+^^^^^^^^^^^^^^^^+>>>>>>>>>>>>>>+-.``````````````?v^+>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>+^^^^^^^^^^^^^^^>>>>>>>>>>>>>>>~ ``````````````-?v?>>>>>+++++++>>>>>>>>.<br></br>
.>>>>+^^^^^^^^^^^^^+>>>>>>>+++++^+| ..``````````````^}v++^^^++>>>>>>>>>>>>`<br></br>
.?v>>>>+^^^^^^^^^^^^>>>>>>>>>>>>>>+|~-````````````-^>?w?^^+>>>>>>>>>>?v}zu|<br></br>
.?yv>>>>+^^^^^^^^^^^+>>>>>>>>>>>>>>>>>>>+^~-```````.`|r?^>>>>>>>>>?vwuyyuz|<br></br>
.>zz>>>>>+^^^^^^^^^^^>>>>>>>>>>>>>>>>>>>>>>>+^~....```-~+>>>>>>>?wuuzrv?>+.<br></br>
.>?v?>>>>+^^^^^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>>|-^>>+^++>>>>?>?zwv?+++^^^.<br></br>
.>>>>>>>>+^^^^^^^^^^^^+++++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?rv>>>+^^^^^^^^.<br></br>
.>>>>>>>+^^^^^^^^^^+^^^^^^^^^^^^^^^++++>>>>>>>>>>>>>>>>>>>>>>++^^^^^^^^^^^.<br></br>
.>>>>>>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++>>>>>>>>>>+++^^^^^^^^^^^^^^^.<br></br>


            </div>,

            <div>

                  ....................................                  ................... <br></br>
                  ....................................                  ................... <br></br>
                  ....................................                  ................... <br></br>
                  ....................................                 .``................. <br></br>
                  .................`.................                  .``..........`...... <br></br>
                  ............````````...............                  ````................ <br></br>
                  ...........`-......................                  `````............... <br></br>
                  ...........`......................                   .``````````````..... <br></br>
                  ........................`.........                   .........````````... <br></br>
                  ..................................  .                ...........``````... <br></br>
                  .................................  ..    .    .      ............````.... <br></br>
                  .^~`.............................. ...   ..   ..   . ..............```...` <br></br>
.^>>^`..............................`.  .`.  .... .. ...```-`````````..`~|.<br></br>
.^^+>>^-............................``...`` .```>^....```-----```````-|^+^.<br></br>
.^^^^+>>+~`........................````````.````>>.`````````````-~|^+++^^^.<br></br>
.^^^^^+>>>>|`.....````......````..``````````````+>-~~~~~~~--~|^+>>>++^^^^^.<br></br>
.^^^^^^+>>>>>^~-`````````````----.``````````````^?^????>>++>>>>>+^^^^^^^^^.<br></br>
.^^^^^^^^+>>>??vvv??>+^^|~~------.``````````````^v????>>>>>>>++^^^^^^^^^^^.<br></br>
.^^^^^^^^^^++>>??}zuuuxxzwr}v?+^^..`````````````~??>>>>>>>>>++^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^++>>>?}wwwrr}}}}v??~.````````````.`^?>>>>>>>>>>>>>+++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+>>>>>>>>>>>>++>>>..````````````~>?>>>>>>>>>>>>>>>>>++^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>>>>>>>>>>>>>+^+-.`````````````|r>>++>>>>>>>>>>>>>>>>+^^.<br></br>
.+^^^^^^^^^^^^^^^^+>>>>>>>>>>>>>>+-.``````````````?v^+>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>+^^^^^^^^^^^^^^^>>>>>>>>>>>>>>>~ ``````````````-?v?>>>>>+++++++>>>>>>>>.<br></br>
.>>>>+^^^^^^^^^^^^^+>>>>>>>+++++^+| ..``````````````^}v++^^^++>>>>>>>>>>>?`<br></br>
.?v>>>>+^^^^^^^^^^^^>>>>>>>>>>>>>>+|~-````````````-^>?w?^^+>>>>>>>>>>?v}zu|<br></br>
.?yv>>>>+^^^^^^^^^^^+>>>>>>>>>>>>>>>>>>>+^~-```````.`|r?^>>>>>>>>>?vwuyyuz|<br></br>
.>zz>>>>>+^^^^^^^^^^^>>>>>>>>>>>>>>>>>>>>>>>+^~....```-~+>>>>>>>?wuuzrv?>+.<br></br>
.>?v?>>>>+^^^^^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>>|-^>>+^++>>>>?>?zwv?+++^^^.<br></br>
.>>>>>>>>+^^^^^^^^^^^^+++++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?rv>>>+^^^^^^^^.<br></br>
.>>>>>>>+^^^^^^^^^^+^^^^^^^^^^^^^^^++++>>>>>>>>>>>>>>>>>>>>>>++^^^^^^^^^^^.<br></br>
.>>>>>>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++>>>>>>>>>>+++^^^^^^^^^^^^^^^.<br></br>


            </div>,

            <div>

 ................................................. ................`.-?>.. <br></br>
 ................................................. .``````.```````````}?.. <br></br>
                  .................................................  .``````````````.-??... <br></br>
                  ................................................   ...````````````^r?.... <br></br>
                  ..........................................```.......  .````````-|?r^..... <br></br>
 ..........`..``````.....................```````..............`->?>`...... <br></br>
                  ....................................................```.    ............. <br></br>
                  .....................................................``.......``````..... <br></br>
                  .....................`........................................````````... <br></br>
                  ................................................................``````... <br></br>
                  .................................................................````.... <br></br>
                  .......................```-----~~~---```..........................```.... <br></br>
 ................``~|^+>>>>>>>>>>>>>>>>>>>+^|-`.........``--``````````.... <br></br>
 .............`-^+>>>>>>>>>>>>>>>>>>?vv?>??v}}v?>|`...```-----```````````. <br></br>
 ..........`~^^^^^^+>>>>>>>>>>++++>>>>>>>>>>>??vrwr?^-``````-`````````````.<br></br>
 ```````.`~^+^^^^^^^^+>++^^^^^^^^^^^^^^^^^^^^^^^++>?>>+|~~~---````````````.<br></br>
.```````-|++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++>^-```````---```.<br></br>
.~~~~~~~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^??>|------`--~||.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vw}v?>+~~~|^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vr}vv?+^^^+^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>+^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^++++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+>>>>>>>>>>>>>>+++++^^^^^^^^^++++++++^^^^^^^^^^^^^^^^^^++++^.<br></br>
.++^^^^^^^^++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^^^^^^^^^^^^+++>>??+^.<br></br>
.>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^^^^^^^^++++>>?vrxyw+.<br></br>
-z}?>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}wxyyyuz}?.<br></br>
-zv>?}wv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}r}}vv??>>>`<br></br>
.>>>vv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

 ................................................. ................`.-?>.. <br></br>
 ................................................. .``````.```````````}?.. <br></br>
                  .................................................  .``````````````.-??... <br></br>
                  .................................................  ...````````````^r?.... <br></br>
                  ...............`..........................````......  .````````-|?r^..... <br></br>
 ..........`..``````......................``````..............`->?>`...... <br></br>
                  ....................................................```.    ............. <br></br>
                  .....................................................``.......`````...... <br></br>
                  .....................`........................................````````... <br></br>
                  ................................................................``````... <br></br>
                  .................................................................````.... <br></br>
                  .......................```----~~~~---```..........................```.... <br></br>
 ................``~|^+>>>>>>>>>>>>>>>>>>>+^|-`.........``--``````````.... <br></br>
 .............`-|+>>>>>>>>>>>>>>>>>>?vv?>??v}}v?>|`...```-----```````````. <br></br>
 ..........`~^^^^^^+>>>>>>>>>>++++>>>>>>>>>>>??vrwr?^-``````-`````````````.<br></br>
 ```````.`~^+^^^^^^^^+>++^^^^^^^^^^^^^^^^^^^^^^^++>?>>+|~~~---````````````.<br></br>
.```````-|^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++>^--```````---``.<br></br>
.~~--~~~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^??>|------`--~||.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vw}v?>+~~~|^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vr}vv?+^^^+^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>+^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^++++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+>>>>>>>>>>>>>>+++++^^^^^^^^^++++++++^^^^^^^^^^^^^^^^^^++++^.<br></br>
.++^^^^^^^^++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^^^^^^^^^^^^+++>>??+^.<br></br>
.>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^^^^^^^^++++>>?vrxyw+.<br></br>
-z}?>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}wxyyyuz}?.<br></br>
-zv>?}wv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}r}}vv??>>>`<br></br>
.>>>vv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ..........................................```........```................. <br></br>
                  .............````---~~~|||||^^^^^^^|||||~~~--``...``````................. <br></br>
                  ..............`~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^|-....``````................ <br></br>
                  .............`|^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^~`...`````..````````...... <br></br>
 ............`^^^^^^|^^^++++++++++++>>++>++++>??>+~.............```````..`.<br></br>
 ...........-^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>?}xxv`.............``````... <br></br>
 ..........`^^^^^^^^>>>>>>>>>>>>>>>>>>>>>>+>>>>+?z?...............````.... <br></br>
 .........`^^^^^^^^>>>>>+>>>>>++++>>>>>>>?wxxxw^>>~...............````.... <br></br>
 .........|^^^^^^^+>>>+>v}}}}v}}}?|+>>>>?uyzuyz^>+`.....``--```````````.``.<br></br>
 ........-^^^^^^^+>>>>>>>>>>>>>>>+^+>>>>wxxxxw?>>|....``------`````````~^+.<br></br>
 .......`^^^^^^^^>>>>>>>>>>>>>>>>>+>>>>>>>>>++>>>~--```````---````````~^^^.<br></br>
.```````~^^^^^^^>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>+-~~~~~~~~~---```````~^^^^.<br></br>
.```````^^^^^^^+>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>|~^>>>>>>>>^-```````~^^^^^.<br></br>
.``````~^^^^^^^>>>>?>>>>>>>>>>>>>^>>>>>>>>>>>>>>^>?????vvvv?>|--`---^^^^^^.<br></br>
.------^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>vrrrrrrrrrwr}v?>+~~|^^^^^^.<br></br>
.-----|^^^^^^^>>>>>>>>>>v}?>+>??>>>>>???vv}?>>>?wwwwwrr}vv}rrr}vv?^^^^^^^^.<br></br>
.||||^^^^^^^^+>>>>>>++>?rwzr}rzzwrrwzzxuu}?-^>>?vvvvvvvvvv}}}rrrr?^^^^^^^^.<br></br>
.^++^^^^^^^^^+>>>>>+>>?????????????????}uv ->>>++>>>>??????????>+^^^^^^^+^.<br></br>
.^^^^^^^^^^^+>>>>>>>w}vvvvvvvvvvvvvvvvv}uz.+>>>+^^^^++++++++^^^^^^^^^^^^+^.<br></br>
.^^^^^^^^++>>>>>>>>?wzuuxzzr}vvv???v}wzzz?->>>>>+^^^^^^^^^^^^^^^^++++^^^^^.<br></br>
.^^^++++>??>>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>++^^^^^++++++>>>>>>>+^^^.<br></br>
.???>>>vxx?>>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^.<br></br>
-uu}>>vxz?>>>>>>>>>>>>>>>>>>>+++>>>+^+>>>>>>>>>>>>>>>???????????????vv}}rr~<br></br>
`v?>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}rwzxxuuuyyyyyyyyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????vvvvvvvvv}-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ..........................................```.......````................. <br></br>
                  .............````---~~||||^^^^^^^^^^|||||~~~-``...```````................ <br></br>
                  ..............`~|^++++++^^^^^^^^^^^^++++++^^^|-....```````............... <br></br>
                  .............`^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~`...```````````````...... <br></br>
 ............-^^^^^^^++++++++++>>>>>>>>>>>>>>>??>+~.............```````..`.<br></br>
 ...........-^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>?}xuv`.............``````... <br></br>
 ..........`^^^^^^^+>>>?>>>>>>>>>>>>>>>>>>+>>>>+?z?...............````.... <br></br>
 .........`^^^^^^^^>>>>>>>>>>>>>+>>>>>>>>?zuuuz+>?~...............````.... <br></br>
 .........|+^^^^^^>>>>>?vrrrrvrrr?|+>>>>?yyzyyx+>>`.....``--```````````.``.<br></br>
 ........-^^^^^^^+>>>>>>?>>>>>>>>+^+>>>>wxxxxw>+>|....``-------````````~^+.<br></br>
 .......`^^^^^^^+>>>>>>>>>>>>>>>>>+>>>>>>>++++>>>-``````-``---````````~+^^.<br></br>
.```````~^^^^^^^>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>+-~~~~~~~~~----``````~^^^^.<br></br>
.```````^^^^^^^+>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>|~+?????>>>^--``````~^^^+^.<br></br>
.``````~^^^^^^^>>>>??>>>>>>>>>>>>^>>>>>>>>>>>>>>^>??vvvvvvv?>|------^^^^+^.<br></br>
.------^^^^^^^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}wwwwwwwwwwr}v?>+|~|^^^^+^.<br></br>
.-----|^^^^^^^>>>>>>>>>>vrv>+>??>>>>>???vv}?>>>?zzzzzzwrrrrrrr}}v?^^^^^^+^.<br></br>
.||||^+^^^^^^+>>>>>>++>vwzzr}wxxwrwzxxxyyr?-^>>?vv}}}}}}}rrrwwwwwv^^^^^^+^.<br></br>
.^+++^^^^^^^^+>>>>>+>>?????????????????}yv ->>>+>>??????vvvvvv??+^^^+^^^+^.<br></br>
.^^^^^^^^^^^+>>>>>>>xw}vvvvvvv}}}vv}}}}}yx.+>>>+^^^++>>>>+++^^^^^^^^^^^^+^.<br></br>
.^^^^^^^^++>>>>>>>>?xxyyuxzwr}vv???v}wxxx?->>>>>+^^^^^^^^^^^^^^^^++++^^^+^.<br></br>
.^^^++++>??>>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>+++^^^^+++++>>>>>>>>++^^.<br></br>
.???>>>vux?>>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^.<br></br>
-uu}?>vxz?>>>>>>>>>>>>>>>>>>>+++>>>+++>>>>>>>>>>>>>>>??v???????????vvvv}rr~<br></br>
`v?>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}wzxuuuyyyyyyyyyyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvvvv}}-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ..........................................````......````................. <br></br>
                  .............``````---~~~||||||||||||~~||~-`````..```````................ <br></br>
 ...........`-~||^>>>>>>>>>>>>>>>>>>>>>>?vrzr>|.....````````.............. <br></br>
 ...........|++^^>>??>>>>>>>>>>>>>>>>>+++^+?zr|.......``````````````...... <br></br>
 ..........~^^^^+>>??>>>++++++>>>>>>+?zzzw?|>?|.................```````..` <br></br>
 .........`^^^^^>>>>+??vvvvv}?^+>>>>?uyxuyx^+>~..................```````--.<br></br>
 .........|+^^^+>>>^?wxxzwrww}^|>>>>?yyuyyw+>>-...................`~^+>>>>`<br></br>
 ........`^^^^^>>>>>>>>>+^^^^+++>>>>>?????+>>>-...................|++++++>`<br></br>
 ........~+^^^^>>>>>>>>>+++>>>+^>>>>>>++>>>>>>-.........``--`````~^^^^^^^^.<br></br>
 .......`^^^^^+>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>`.......``------``-^^^^^^^^^.<br></br>
 .......-^^^^^>>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>-````````````---``|^^^^^^^^^.<br></br>
.```````|^^^^+>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>~---~~~~~~~~~---`-^^^^^^^^+^.<br></br>
.``````-^^^^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>~~~~+?????>>>^---|+^^^^^^^+^.<br></br>
.``````~^^^^^>>>>>>+>>>>>>>>>+>>>??vrzzwv?>>>>^|^>??vvvv}vv?>|~^+^^^^^^^^^.<br></br>
.------^^^^^+>>>>>>>>?wxzrzyxzwzxxxxzwr}v+>>>>+?rzzwwwwwwwwr}v>^^^^^^^^^^^.<br></br>
.-----~+^^^^>>>>>>>^?ww}vv??>?????>|~++-. >>>>>?zzzzzzwrrrrrrrv+^^^^^^^^^^.<br></br>
.----~^^^^^+>>>>>>^~^>>>>>?>>?????????vwr+>>>>>>?v}}}}}}}}rrwwww}?>^^^^^+^.<br></br>
.|^^^^^^^^+>>>>>>>|+>???????vvvv}}}vvvvwu}>>>>>>>>>?????vvvv}rrwzzz}?+^^^^.<br></br>
.^+^^^^++>>>>>>>>>>v?v}rwzxxxzzzwwzzxxuyu?>>>>>>>>>>>>>>????v}rwzzxxxz}?+^.<br></br>
.^+++>>>>>>>>>>>>>?vrwr}v???>>>>>>>>>>???>>>>>>>>>>>>>>>>>>>>>????vv}wzzzv`<br></br>
.+>>>>>?}}>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>?????>>>>>>>>>>??v-<br></br>
`v}}?>vyx?>>>>>>>>>>>>>>>>>>+^^+++++>>>>>>>>>>>>>>>>>>>>>??vv}rrrvv??>>>>>`<br></br>
-yx?>?r}?>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vrzuuuzw}v-<br></br>
`?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}wuyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v~<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ..........................................````......````................. <br></br>
                  ..............`````---~~~||||||||||||~~||~-`````..```````................ <br></br>
 ...........`-~||^>>>>>>>>>>>>>>>>>>>>>>?vrwr>|.`...````````.............. <br></br>
 ...........|++^^>>??>>>>>>>>>>>>>>>>>+++^+?zr|.......``````````````...... <br></br>
 ..........~^^^^+>>??>>>++++++>>>>>>+?zzzw?|>?|.................```````..` <br></br>
 .........`^^^^^>>>>+??vvvvv}?^+>>>>?uyxuyx^+>~..................```````--.<br></br>
 .........|+^^^+>>>^?wxxzwrww}^|>>>>?yyuyyw+>>-...................`~^+>>>>`<br></br>
 ........`^^^^^>>>>>>>>>+^^^^+++>>>>>?????+>>>-...................|++++++>`<br></br>
 ........~+^^^^>>>>>>>>>+++>>>+^>>>>>>++>>>>>>-.........``--`````~^^^^^^^^.<br></br>
 .......`^^^^^+>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>`.......``------``-^^^^^^^^^.<br></br>
 .......-^^^^^>>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>-````````````---``|^^^^^^^^^.<br></br>
.```````|^^^^+>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>~---~~~~~~~~~---`-^^^^^^^^+^.<br></br>
.``````-^^^^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>~~~~+?????>>>^---|+^^^^^^^+^.<br></br>
.``````~^^^^^>>>>>>+>>>>>>>>>+>>>??vrzzwv?>>>>^|^>??vvvv}vv?>|~^+^^^^^^^^^.<br></br>
.------^^^^^+>>>>>>>>?wxzrzyxzwzxxxxzwr}v+>>>>+?rzzwwwwwwwwr}v>^^^^^^^^^^^.<br></br>
.-----~+^^^^>>>>>>>^?ww}vv??>?????>|~++-. >>>>>?zzzzzzwrrrrrrrv+^^^^^^^^^^.<br></br>
.----~^^^^^+>>>>>>^~^>>>>>?>>?????????vwr+>>>>>>?v}}}}}}}}rrwwww}?+^^^^^+^.<br></br>
.|^^^^^^^^+>>>>>>>|+>???????vvvv}}}vvvvwu}>>>>>>>>>?????vvvv}rrwzzz}?+^^^^.<br></br>
.^^^^^^++>>>>>>>>>>v?v}rwzxxxxzzwwzzxxuyu?>>>>>>>>>>>>>>????v}rwzzxxxz}?+^.<br></br>
.^++++>>>>>>>>>>>>?vrwr}v???>>>>>>>>>>???>>>>>>>>>>>>>>>>>>>>>????vv}wzzzv`<br></br>
.+>>>>>?}}>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>?????>>>>>>>>>>??v-<br></br>
`v}}?>vyx?>>>>>>>>>>>>>>>>>>+^^+++++>>>>>>>>>>>>>>>>>>>>>??vv}rrrvv??>>>>>`<br></br>
-yx?>?r}?>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vrzuuuzw}v-<br></br>
`?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}wuyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v~<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ..........................................````......````................. <br></br>
                  ..............`````---~~~||||||||||||~~||~-`````..```````................ <br></br>
 ...........`-~||^>>>>>>>>>>>>>>>>>>>>>>?vrwr?|.....````````.............. <br></br>
 ...........|++^^>>??>>>>>>>>>>>>>>>>>+++^+?zr|.......``````````````...... <br></br>
 ..........~^^^^+>>??>>>++++++>>>>>>+?zzzw?|>?|.................```````..` <br></br>
 .........`^^^^^>>>>+??vvvvv}?^+>>>>?uyxuyx^+>~..................```````--.<br></br>
 .........|+^^^+>>>^?wxxzwrww}^|>>>>?yyuyyw+>>-...................`~^+>>>>`<br></br>
 ........`^^^^^>>>>>>>>>+^^^^+++>>>>>?????+>>>-...................|++++++>`<br></br>
 ........~+^^^^>>>>>>>>>+++>>>+^>>>>>>++>>>>>>-.........``--`````~^^^^^^^^.<br></br>
 .......`^^^^^+>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>`.......``------``-^^^^^^^^^.<br></br>
 .......-^^^^^>>>>>>>>>>>>>>>>+^>>>>>>>>>>>>>>-````````````---``|^^^^^^^^^.<br></br>
.```````|^^^^+>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>~---~~~~~~~~~---`-^^^^^^^^+^.<br></br>
.``````-^^^^^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>~~~~+?????>>>^---|^^^^^^^^^^.<br></br>
.``````~^^^^^>>>>>>+>>>>>>>>>+>>>??vrzzwv?>>>>^|^>??vvvv}vv?>|~^^^^^^^^^^^.<br></br>
.------^^^^^+>>>>>>>>?wxzrzyxzwzxxxxzwr}v+>>>>+?rzzwwwwwzwwr}v>^^^^^^^^^^^.<br></br>
.-----~+^^^^>>>>>>>^?ww}vv??>?????>|~++-. >>>>>?zzzzzzwrrrrrrrv+^^^^^^^^^^.<br></br>
.----~^^^^^+>>>>>>^~^>>>>>?>>?????????vwr+>>>>>>?v}}}}}}}}rrwwww}?+^^^^^+^.<br></br>
.|^^^^^^^^+>>>>>>>|+>???????vvvv}}}vvvvwu}>>>>>>>>>?????vvvv}rrwzzz}?+^^^^.<br></br>
.^^^^^^++>>>>>>>>>>v?v}rwzxxxxzzwwzzxxuyu?>>>>>>>>>>>>>>????v}rwzzzxxz}?+^.<br></br>
.^++++>>>>>>>>>>>>?vrwr}v???>>>>>>>>>>???>>>>>>>>>>>>>>>>>>>>>????vv}wzzzv`<br></br>
.+>>>>>?}}>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>?????>>>>>>>>>>??v-<br></br>
`v}}?>vyx?>>>>>>>>>>>>>>>>>>+^^+++++>>>>>>>>>>>>>>>>>>>>>??vv}rrrvv??>>>>>`<br></br>
-yx?>?r}?>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vrzuuuzw}v-<br></br>
`?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}wuyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v~<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ................````-------------````.....````......````................. <br></br>
 ............`~^+>>>>>>>>>>>>>>>>>>>?v}}v?~``````..```````................ <br></br>
 ...........-^+>?>>>>>>>>>>>>>>>>>>+>>>?wu?`..``....````````.............. <br></br>
 .........`|^^>>??>>>>>>>+>>>>>>>+>rr}v>^?v`..........`````````````....... <br></br>
 ........`^+^+>>>>>>>>???v?++>>>>?uyuuyu>+>-....................`````-```-.<br></br>
 ........~+^^+>>>^vxyyyuzuz>|>>>>?yyxuyy?+>|....................-^+>>>>>>>`<br></br>
 .......`^^^^>>>>+>?v???>>++^>>>>>vrrrr?+>>^...................|+++++>>>>>`<br></br>
 .......~+^^^>>>>>>>>>++++>>^+>>>>>+++>>>>>+..................~+^^^^^^^^++.<br></br>
 .......|+^^+>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>............``--`-^^^^^^^^^^^^.<br></br>
 ......`^^^^>>>>>>>>>>>>>>>>^^++++>>>>>>>>>>`.........``-----|^^^^^^^^^^^^.<br></br>
 ......-+^^^>>>?>>>>>>>>>>>>>++++>>>>>>>>>>>~``````````````-~^^^^^^^^^^^^^.<br></br>
 ``````|+^^+>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>|-----~~~~~~~~~-|^^^^^^^^^^^^^.<br></br>
.`````-^^^^+>>>>>>++>>>>>+>+>>>??v}rwrv>>>>>+-~~~~+?????>>>^^^^^^^^^^^^^^^.<br></br>
.`````-^^^^>>>>>>>>>?rrvvrxwv}wzxuyyyyuv+>>>>|~|+>??vvvv}vv?^^^^^^^^^^^^^^.<br></br>
.-----~^^^^>>>>>>>>>rw}v>^|~``...`??+-`.`>>>>>>vrzzwwwwwwwwr>^^^^^^^^^^^^^.<br></br>
.-----^^^^+>>>>>>>|  ..     .`-~~-`.    .>>>>>>rzzzzzzwrrrrr}?>+^^^^^^^^+^.<br></br>
.----~^^^^>>>>>>>+`-^>??>>>>????????^+v+`>>>>>>>?v}}}}}}}}rrwwww}v>+^^^^+^.<br></br>
.----|^^^+>>>>>>>|^>>????vvv}}rrwwwwrwuuv>>>>>>>>>>>????vvvv}rrwzzzwv?+^^^.<br></br>
.~~~|^++>>>>>>>>>+v}wzxuuuxxzwwrrrwwzxxz?>>>>>>>>>>>>>>???vv}rwzzzzxxxwv>^.<br></br>
.^++>>>>??>>>>>>>>?v}}vv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???v}wzzxxxxzr-<br></br>
.>>??>>wu?>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>??vvv??>>>>>??v}rwwz|<br></br>
`zuz?>vxv>>>>>>>>>>>>>>>>>>+++>>>>+>>>>>>>>>>>>>>>>>>>>>>?vwzzw}v?>>>>>>??`<br></br>
-xw?>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrxuuxzrv??>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrzuyyuwv`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}zyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?}|<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ................````-------------````.....````......````................. <br></br>
 ............`~^+>>>>>>>>>>>>>>>>>>>?v}}v?~``````..```````................ <br></br>
 ...........~^+>>>>>>>>>>>>>>>>>>>>+>>>?wu?`..``....````````.............. <br></br>
 .........`|^^>>??>>>>>>>+>>>>>>>+>rr}v>^?v`..........`````````````....... <br></br>
 ........`^+^+>>>>>>>>???v?++>>>>?uyuuyu>+>-....................`````-````.<br></br>
 ........~+^^+>>>^vxyyyuzuz>|>>>>?yyxuyy?+>|....................-^+>>>>>>>`<br></br>
 .......`^^^^>>>>+>?v???>>++^>>>>>vrrrr?+>>^...................|+++++>>>>>`<br></br>
 .......~+^^^>>>>>>>>>++++>>^+>>>>>+++>>>>>+..................~+^^^^^^^^++.<br></br>
 .......|+^^+>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>............``--`-^^^^^^^^^^^^.<br></br>
 ......`^^^^>>>>>>>>>>>>>>>>^^++++>>>>>>>>>>`.........``-----|^^^^^^^^^^^^.<br></br>
 ......-+^^^>>>?>>>>>>>>>>>>>++++>>>>>>>>>>>~``````````````-~^^^^^^^^^^^^^.<br></br>
 ``````|+^^+>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>|-----~~~~~~~~~-|^^^^^^^^^^^^^.<br></br>
.`````-^+^^+>>>>>>++>>>>>+>+>>>??v}rwrv>>>>>+-~~~~+?????>>>^^^^^^^^^^^^^^^.<br></br>
.`````-^^^^>>>>>>>>>?rrvvrxwv}wzxuyyyyuv+>>>>|~|^>??vvvv}vv?^^^^^^^^^^^^^^.<br></br>
.-----~^^^^>>>>>>>>>rw}v>^|~``...`??+-`.`>>>>>>vrzzwwwwwwwwr>^^^^^^^^^^^^^.<br></br>
.-----^^^^+>>>>>>>|  ..     .`-~~-`.    .>>>>>>rzzzzzzwrrrrr}?>+^^^^^^^^^^.<br></br>
.----~^^^^>>>>>>>+`-^>??>>>>????????^+v+`>>>>>>>?v}}}}}}}}rrwwww}v>+^^^^+^.<br></br>
.----|^^^+>>>>>>>|^>>????vvv}}rrwwwwwwuuv>>>>>>>>>>>????vvvv}rrwzzzwv?+^^^.<br></br>
.~~~|^++>>>>>>>>>+v}wzxuuuxxzwwrrrwwzxxz?>>>>>>>>>>>>>>???vv}rwzzzzxxxwv>^.<br></br>
.^++>>>>??>>>>>>>>?v}}vv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???v}wzzxzxxzr-<br></br>
.>>??>>wu?>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>??vvv??>>>>>??v}rwzz|<br></br>
`zuz?>vzv>>>>>>>>>>>>>>>>>>+++>>>>+>>>>>>>>>>>>>>>>>>>>>>?vwzzw}v?>>>>>>??-<br></br>
-xw?>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrxuuuzrv??>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrzuyyuwv`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}zyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?}|<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ................````-------------````.....````......````................. <br></br>
 ............`~^+>>>>>>>>>>>>>>>>>>>?v}}v?~``````..```````................ <br></br>
 ...........~^+>?>>>>>>>>>>>>>>>>>>+>>>?wu?`..``....````````.............. <br></br>
 .........`|^^>>??>>>>>>>+>>>>>>>+>rr}v>^?v`..........`````````````....... <br></br>
 ........`^+^+>>>>>>>>???v?++>>>>?uyuuyu>+>-....................`````-```-.<br></br>
 ........~+^^+>>>^vxyyyuzuz>|>>>>?yyxuyy?+>|....................-^+>>>>>>>`<br></br>
 .......`^^^^>>>>+>?v???>>++^>>>>>vrrrr?+>>^...................|+++++>>>>>`<br></br>
 .......~+^^^>>>>>>>>>++++>>^+>>>>>+++>>>>>+..................~+^^^^^^^^++.<br></br>
 .......|+^^+>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>............``--`-^^^^^^^^^^^^.<br></br>
 ......`^^^^>>>>>>>>>>>>>>>>^^++++>>>>>>>>>>`.........``-----|+^^^^^^^^^^^.<br></br>
 ......-+^^^>>>?>>>>>>>>>>>>>++++>>>>>>>>>>>~``````````````-~^^^^^^^^^^^^^.<br></br>
 ``````|+^^+>>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>|-----~~~~~~~~~-|^^^^^^^^^^^^^.<br></br>
.`````-^+^^+>>>>>>++>>>>>+>+>>>??v}rwrv>>>>>+-~~~~+?????>>>^^^^^^^^^^^^^^^.<br></br>
.`````-^^^^>>>>>>>>>?rrvvrxwv}wzxuyyyyuv+>>>>|~|^>??vvvv}vv?^^^^^^^^^^^^^^.<br></br>
.-----~^^^^>>>>>>>>>rw}v>^|~``...`??+-`.`>>>>>>vrzzwwwwwwwwr>^^^^^^^^^^^^^.<br></br>
.-----^^^^+>>>>>>>|  ..     .`-~~-`.    .>>>>>>rzzzzzzwrrrrr}?>+^^^^^^^^^^.<br></br>
.---`~^^^^>>>>>>>+`-^>??>>>>????????^+v+`>>>>>>>?v}}}}}}}}rrwwww}v>+^^^^+^.<br></br>
.----|^^^+>>>>>>>|^>>????vvv}}rrwwwwrwuuv>>>>>>>>>>>????vvvv}rrwzzzwv?+^^^.<br></br>
.~~~|^++>>>>>>>>>+v}wzxuuuxxzwwrrrwwzxxz?>>>>>>>>>>>>>>???vv}rwzzzzxxxwv>^.<br></br>
.^++>>>>??>>>>>>>>?v}}vv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???v}wzzxxxxzr-<br></br>
.>>??>>wu?>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>??vvv??>>>>>??v}rwzz|<br></br>
`zuz?>vzv>>>>>>>>>>>>>>>>>>+++>>>>+>>>>>>>>>>>>>>>>>>>>>>?vwzzw}v?>>>>>>??`<br></br>
-xw?>>?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrxuuuzrv??>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrzuyyuwv`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??}zyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?}|<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ...............`````-----------`````......````......````................. <br></br>
 ............-|++>>>>>>>>>>>>>>>>>>??vvv>^-``````..```````................ <br></br>
 ..........`|+>>>>>>>>>>>>>>>>>>>>>>>>?}}?+`.```....````````.............. <br></br>
 .........-^+>>>>>>>>>>>>>>>>>>>>>?}}v>>>>+`..........`````````````....... <br></br>
 ........-^^+>>>>>>>>>????>+>>>>?vuyyyw?>+|`....................```-----``.<br></br>
 .......`^^^+>>>>?ruyyuuuz?++>>>?}yuyyuv>>^`..................``|+>>>>>>>>`<br></br>
 .......-^^++>>>>>?v}v???++++>>>>?}rrr?>>>^-.................`~^++++>>>>>>`<br></br>
 .......|^^+>>>>>>>>>++++>>++>>>>>++++>>>>+~................`~^+^^^^^^^+++.<br></br>
 ......`^+^+>>>>>>>>>>>>>>>++>>>>>>>>>>>>>+|............``--||^^^^^^^^^^^^.<br></br>
 ......-^^^+>>>>>>>>>>>>>>>++++++>>>>>>>>>>^`.........``---~|^+^^^^^^^^^^^.<br></br>
 ......|^^^+>>>?>>>>>>>>>>>>>+++>>>>>>>>>>>+-`````````````-||^^^^^^^^^^^^^.<br></br>
 ``````^^^+>>>??>>>>>>>>>>>>>>>>>>>>>>>>>>>+~-----~~~~~~~~~|^^^^^^^^^^^^^^.<br></br>
.`````~^^^+>>>>>>>+>>>>>>>>>>>?v}rwzzwv>>>>>^-~~~~+?????>>^^^^^^^^^^^^^^^^.<br></br>
.`````~^^^+>>>>>>>>?vzw}vrwv??vv}zyyyuz?>>>>+|~|^>??vvvv}}?^^^^^^^^^^^^^^^.<br></br>
.-----|+^^+>>>>>>>^|??>^~`...    `vv>`..|>>>>+>vrzzwwwwwzzv+^^^^^^^^^^^^^^.<br></br>
.-----^^^^>>>>>>>>.         .`-~~-..    ~>>>>>?wzzzzzzwrrrrv?>+^^^^^^^^^^^.<br></br>
.----~^^^+>>>>>>>~.-^>??>>>>???????>~>?~->>>>>>>?v}}}}}}}rrwwwr}v?+^^^^^^^.<br></br>
.----|^^^>>>>>>>>~+>>??vvv}}rwwwzzzzwxyu?>>>>>>>>>>????vvvvvvrrwzzwv?+^^^^.<br></br>
.~~~~^++>>>>>>>>>>vrzxuuuuxzwwr}rrrwzzxw?>>>>>>>>>>>>>>???vv}rwzzzzxxzv?+^.<br></br>
.|^+>>>>??>>>>>>>>??vvv???>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???v}rwzzxxxzwv`<br></br>
.>>??>?zz?>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>????>>>>>>??vv}rwzz|<br></br>
`wuw>>rz?>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>>>>>>>??v}}v??>>>>>>>???`<br></br>
-uw?>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}wzzw}v??>>>>`<br></br>
.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrzxxz}v??`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}wxxw~<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vz|<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ...............`````--------```````......`````......````................. <br></br>
 ...........`|++>>>>>>>>>>>>>>>>>>?v}rv?^````````..```````................ <br></br>
 ..........-^>>>>>>>>>>>>>>>>>>>>>>>>?ru}~....``....````````.............. <br></br>
 .........~^+>>?>>>>>>>>>>>>>>>>+v}}v?^>v^............`````````````....... <br></br>
 ........|+^>>>>>>>>>>>??>+>>>>>}yyuyyv|>+......................``------``.<br></br>
 .......-+^^>>>>>}xuuyzuy}~^>>>>zyuwyyz^>>....................`~^+>>>>>>>>`<br></br>
 .......^^^+>>>>+?}r}vv??+^^>>>>>}rrr}>>>>`..................-^++++>>>>>>>`<br></br>
 ......`^^^+>>>>>>>>+^^++>>^>>>>>+++++>>>>-.................~+^^^^^^^^^++>`<br></br>
 ......~+^^>>>>>>>>>>>>>>>>^>>>>>>>>>>>>>>|.............``--^+^^^^^^^^^^^^.<br></br>
 ......|^^^>>>>>>>>>>>>>>>>^+++++>>>>>>>>>+``.........``---|+^^^^^^^^^^^^^.<br></br>
 .....`^^^+>>>>>>>>>>>>>>>>>++++>>>>>>>>>>>-``````````````-^^^^^^^^^^^^^^^.<br></br>
 `````-+^^+>>?v>>>>>>>>>>>>>>>>>>????>>>>>>|------~~~~~~~~~^^^^^^^^^^^^^+^.<br></br>
.`````|+^^+>>>>>>++>>>>>+>>>>?vwuyyyyxv>>>>+--~~~~+?????>>^^^^^^^^^^^^^^^^.<br></br>
.`````|+^^>>>>>>>>>?zxzwwzr??????vxuux}+>>>>|~~|^>??vvvv}}>^^^^^^^^^^^^^^^.<br></br>
.-----^^^^>>>>>>>>||++|-..       ~r}>` .>>>>++?vrzzwwwwwzz?^^^^^^^^^^^^^^^.<br></br>
.----~^^^^>>>>>>>+          .`-~~`..    >>>>>>rzzzzzzzwrrrrv?>+^^^^^^^^^^^.<br></br>
.----~+^^+>>>>>>>`.-^>?>>>>>???????^`>>-^>>>>>>?v}}}}}}}}rrwwwrv?>^^^^^^+^.<br></br>
.----|^^^>>>>>>>>~+>??vvv}}rwwzzzzzzzuyz>>>>>>>>>>??????vvvvvrrwzzrv>^^^+^.<br></br>
.~~~~^++>>>>>>>>>>}wxuuyuxzwwr}}}}rwwzzr>>>>>>>>>>>>>>???vvv}rwwzzzxzwv>^^.<br></br>
.||^+>>>v?>>>>>>>>??vvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??v}wwzzzzzzzxzr?.<br></br>
.>>?>>?xw>>>>>>>>>>>>>>>>>>>+^++++>>>>>>>>>>>>>>>>>>>>????>>>>??v}wzzzzzzz~<br></br>
`wur>?xw?>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrwrv??>>>>?vv}}vv-<br></br>
-uw?>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vwuuxw}v?>>>>>>`<br></br>
`?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}zuyuzrv?>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrxyyuzv-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?ruyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vx^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................`.................. <br></br>
                  ...............`````--------```````......`````......````................. <br></br>
 ...........`|++>>>>>>>>>>>>>>>>>>?v}rv?^````````..```````................ <br></br>
 ..........-^>>>>>>>>>>>>>>>>>>>>>>>>?ru}~...```....````````.............. <br></br>
 .........~^+>>?>>>>>>>>>>>>>>>>+v}}v?^>v^............`````````````....... <br></br>
 ........|+^>>>>>>>>>>>??>+>>>>>}yyuyyv|>+......................``------``.<br></br>
 .......-+^^>>>>>}xuuyzuy}~^>>>>zyuwyyz^>>....................`~^+>>>>>>>>`<br></br>
 .......^^^+>>>>+?}r}vv??+^^>>>>>}rrr}>>>>`..................-^++++>>>>>>>`<br></br>
 ......`^^^+>>>>>>>>+^^++>>^>>>>>+++++>>>>-.................~+^^^^^^^^^++>`<br></br>
 ......~+^^>>>>>>>>>>>>>>>>^>>>>>>>>>>>>>>|.............``--^+^^^^^^^^^^^^.<br></br>
 ......|^^^>>>>>>>>>>>>>>>>^+++++>>>>>>>>>+``.........``---|+^^^^^^^^^^^^^.<br></br>
 .....`^^^+>>>>>>>>>>>>>>>>>++++>>>>>>>>>>>-``````````````-^^^^^^^^^^^^^^^.<br></br>
 `````-+^^+>>?v>>>>>>>>>>>>>>>>>>????>>>>>>|------~~~~~~~~~^^^^^^^^^^^^^^^.<br></br>
.`````|+^^+>>>>>>++>>>>>+>>>>?vwuyyyyxv>>>>+--~~~~+?????>>^^^^^^^^^^^^^^^^.<br></br>
.`````|+^^>>>>>>>>>?zxzwwzr??????vxuux}+>>>>|~~|^>??vvvv}}>^^^^^^^^^^^^^^^.<br></br>
.-----^^^^>>>>>>>>||++|-..       ~r}>` .>>>>++?vrzzwwwwwzz?^^^^^^^^^^^^^^^.<br></br>
.----~^^^^>>>>>>>+          .`-~~`..    >>>>>>rzzzzzzzwrrrrv?>+^^^^^^^^^^^.<br></br>
.----~+^^+>>>>>>>`.-^>?>>>>>???????^`>>-^>>>>>>?v}}}}}}}}rrwwwrv?>^^^^^^+^.<br></br>
.----|^^^>>>>>>>>~+>??vvv}}rwwzzzzzzzuyz>>>>>>>>>>??????vvvvvrrwzzrv>^^^+^.<br></br>
.~~~~^++>>>>>>>>>>}wxuuyuxzwwr}}}}rwwzzr>>>>>>>>>>>>>>???vvv}rwwzzzxzwv>^^.<br></br>
.||^+>>>v?>>>>>>>>??vvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??v}wwzzzzzzzxzr?.<br></br>
.>>?>>?xw>>>>>>>>>>>>>>>>>>>+^++++>>>>>>>>>>>>>>>>>>>>????>>>>??v}wzzzzzzz~<br></br>
`wur>?xw?>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrwrv??>>>>?vv}}vv-<br></br>
-uw?>??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vwuuxw}v?>>>>>>`<br></br>
`?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}zuyuzrv?>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vrxyyuz}-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?ruyy^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vx^<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwxuuuuuuuuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuuyyuuxxuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuuuuxxxuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxxxxxuuuuuuuuuuyyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzzxxxyuuuuuuuuuuyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwwwwxuuuuuuxxxxuuuuuuuzuuyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuuuzv??vv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuuu}`      .........  ........... <br></br>
`vvvvvv}}}}}}rrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}+ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzzxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  .... ....... ......................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... ........``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ........-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~|^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwxuuuuuuuuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuuyyuuxxuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuuuuxxxuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxxxxxxuuuuuuuuuuyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzzxxxuuuuuuuuyuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuuuuux}xxyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuuur^~~?v?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuux?.      .........  ........... <br></br>
`vvvvvv}}}}}}rrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}+ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzzxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ ..`......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  .... ....... ......................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... ........``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuyu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ........-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuwzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuuyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwxuuuuuuuuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuuyuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuuuzwxwxuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxxxz}?>?vwuuuuuuyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzzxxxyyx}>?zzyuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuuuyx+?yuyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuuyw^?vvv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuyu?..     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}+ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzzxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  .... ....... ......................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... ........``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ........-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuuyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwxuuuuuuuuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuuuuyuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuuy}}zvzuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzzxxxxw>|.->vxuuuuuyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzxxxxyyz?`|}ryuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuuuyz`>yyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuuyz+ruwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuyu?-.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^  ......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`--``.````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ .`.......``...`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`uuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuyu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ...`. ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuuyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrwwwzuuuuuuxuyxxxxuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuyxw}+^>?ruuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxzwr>`|?vzuuuuuuyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzxxxuyyv`+?zzyuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuuu}?^vyyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuyz??zuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuux}~.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  ...  ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ...`. ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuuyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrwwwzuuuuuuwuuwrzxuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuyr?>-.`>vxuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxwr}>.?zxuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxxzxxxuyy? }yuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuuuu?+zuuuyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuyr^ryuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuyxzu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  ..... ........ .`.......``...`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr.  ...  ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. . ....  ....... .......`````..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ...`....-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.`````````.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrwwwzuuuuuuzyuwwzxuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuyrv>`.-?vxuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxxrvv^.?xuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxzzzxxuyy> vyuuuuuyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuuyx++zuuuyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuuv+zyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuyxxu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrrwwwzzzzxuuuuuuuuuuuuuyr.  ...  ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuyyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. . ....  ....... .......`````..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ...`....-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.`````````.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuyyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrwwwzuuuuuuuyzwzxzxuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuu}w>``|?}uuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxw???``?uuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxzzzxxuyx-~?uuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuuyw|?uuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuw>?uyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuywuu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyy}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`--``.````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrrwwwzzzzxuuuuuuuuuuuuuyr.  ...  ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..```.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. . ....  ....... .......`````..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ...`....-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.`````````.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrrwwwwwwrwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvv}}}}}}rrrrwuuuuuuuuuyyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrwwwzuuuuuuuyzrwzwxuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuu}w>``|?}uuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvv}}}}}}rrrrwwwwwwwzzzzxxw???``?xuuuuuuuuyyyyyyu}?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxzzzxxuyx-|>uuuuuuyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuuyr|?xuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuuw>?uyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuywuu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyy}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}rrrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .  <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrrwwwzzzzxuuuuuuuuuuuuuyr.  ...  ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..```.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. . ....  ....... .......`````..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzzuuuuuuuuuuv- .....`...  ...`....-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.`````````.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuyyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuuwv}rvzuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuwz+..+ruuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxw???-~>ruuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxxuyx`?^zuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuyv^?}yuyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuur>vyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuyyzyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ........ .........`..``.....````````. <br></br>
`uuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `xuuuuuuuuyyuuuxuuxxxuuxuuyr|   ........ ... ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-..```-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuyyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuuwv}rvzuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuwz+..+ruuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxw???`~?ruuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuxzzzxxuyx`?^zuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuyv|?}yuyyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxuuuu}>vyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuyyzyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrrwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuux}v}>vuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuu^..>zuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxz?^+.|vwuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuxzzxxzuyv`}~ruuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}}rrwwwwzwwxuuuuuuxxxxuuy+?}?uuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuux?>zyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuyxxyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuux}v}>vuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuu^..>zuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxz?^+.|vwuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuxzzxxzuyv`}~ruuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuuy+?}?uuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuux?>zyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuyxxyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyxxyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuzvv?^xuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyx^.`vyuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxzv^^.+vzuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxzwxy?`v~}uuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuyx`}wvuuuyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuur>?uyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyyzuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyxxyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuuzvv?^xuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyx^.`vyuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxzv^^.+vzuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwwzxuxxzzxxwxy?`v~}uuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuyx`}wvuuuyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuur>?uyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyyzuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuwvxyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuurv?-ruuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyx^.-zuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxxr^|.>}wuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzy+`v|vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuyr`zxwuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuuv>>yuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyyxuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuwvxyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuurv?-ruuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyx^.-zuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxxr^|.>}wuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzy+`v|vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuyr`zxwuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuuuv>>yuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyyxuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|-.<br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuyyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuwvwyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuu}v~+uuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyw~.^xuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxxw|-.?vwuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzu|`?+vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuy>^uyxuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuur>+}yuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyuuyyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuyyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuwvwyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuuu}v~+uuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuyw~.^xuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxxw~-.?vwuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzu|`?+vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuy>^uyxuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuur>+}yuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuyuuyyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuyyyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuur?ryyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuzv^-ruuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuur``>uuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxuw-``v}ruuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzz`.++vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuy`?yyuuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuu>+?uuuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuyyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuuyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuyyyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuur?ryyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuuzv^-ruuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuur``>uuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxuw-``v}ruuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxxwzz`.++vuuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuy`?yyuuuyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuu>+?uuuyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuuuuuuyyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuxwvzyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuzv?|>uuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuuv.`+uuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxx?```vwzuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxzvw}..`->xuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxuu.vxzvruyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuu|^?yyyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxuuuuuzzuuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuz?ryuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuuwv?^|uuuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuu?.`+uuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxx+``-}zuyuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzxzvwv .`~>zuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxxux.vxw?vyyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxux~+vyyyyuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxuuuuuzwyyyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuuyyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuw}uyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuuwvv?|}uuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuyx?.`^xuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxw~..-rxuyuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzzr?w? .-|?zuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxuyz.vwr??zyyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuz`?zyyuuuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxuuuuw?wyuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuyuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvv}v}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuxuxvxyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuu}vvv|?uuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuyz?.`|zuuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzxxr-..-zuyyuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxzzzv?w? .~+?xuuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxzuyw vr}?+ryyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuw.}uyyuuuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxuuuur+wyuyu|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuyuuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuxuuzzuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuuu}vv}??zuuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuuuw+.`~}xuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzzz>`..~xyuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxz}}?v}^ `^>vuyuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxuyv ?vv+^ryyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxuv.zyyyzwuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuuz+^xyuyy|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuuzuyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuyyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwzzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwrwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuxuuyuvuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuux}vvr}^}uuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwzuuyu}|.--?zuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzzzrw~`.`|uyuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxz??+rv- ~?vwuyuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxzxuy? ???++ryyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxu?-yyyywvuwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuur~>uuuyy|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuuxruyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuyyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxuxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuuuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`...`..``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuxuuyyxuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrrwwzuuuuux}vvrr?vxuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwxxxyuv~.`-?xuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzwv}?-`.`~uyuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuuz???r?` ~?}zuyuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxuy> >??++}uyyyxzwvv>. ................. <br></br>
`vvvvvvvvv}}}}rrzuuuxxuzzxuuuuyuxxxxu?-yyyuwvzwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuu}`?yuyyy|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuur}yyu}^ ......   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxuuuuuuuuyyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`--```````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxuxxxxxuuuuuuyr-   ..  .. .. ........ .........`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..``.....````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..```.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-`..``---``... <br></br>
                  -uuuuuuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuxuuyyyxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuuuuxvvv}wv?zuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwxzzyx?-``-vxuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzr?v^-`.`~uyuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuuz??}r?`.|vrxuyuuyyyyyyyyw|      ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxuy+.+>>++vuyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxu>~yyyuzvrwv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxuuuxxxxuuv`}uuyyy|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuyvvyyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuyyyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxuxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``.....`` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuxuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuwuux}vvrz}vzuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwx}}uw?```-?xuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzr>>~-`.`~uuuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuuuz}v}v>..+}wxyyuuyyyyyyyyw|      ........... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzwwxuuuuuuxxxuu-.~||^+}uyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxxu-^yuux}?}wv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxuuuxxxxuu^-zuuyyy|.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuuw+ryyu}^  .....   ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuuyyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .... ..  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxuxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`  .... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......`-`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......`````..~~``--``....... <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`~~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuyyyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuvzyz}?}wzrvzuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwx??wv+``--?zuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzr+|--`.`|uuuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxuxzrvv+..?zxuyyuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrwwwwwzwwxuuuuuuxxuux..`|^+>ryyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxux.>yuxzv>?wv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuu`?uuuyyy~.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuu}-zuyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuxuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxuuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`   ... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................``.`...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`..`......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..``-.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. . ....  ....... .......```-`..~~``--``.....`` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

                  .??????????????????????vrrrrrrrrrrrrrrrrrwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}ruyuuuuuuuuuuuuuuyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}wxuuuuuuuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rwxuuuuuuuuuuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwuuuuxuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvv}}}}}}}}rrrwwwzuuvwyz}?}wxwvzuuuuuuyyyyyyyyyyyyyyyyyyyuz}?^-` <br></br>
`vvvvvvvvvvv}}}}}}rrrrrwwwwwwx??rv+``-~?zuuuuuuuuyyyyyyyyyyyxxxzv>^-..  .. <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwwwwwzzr>|--```|uuuuuuuuuuuyyyyyyur?~```..  ....... <br></br>
`vvvvvvvvvvv}}}}}rrrwwwwwzzxuxxxz}v?^..?zuyyyuuyyyyyyyyw|   .  ........... <br></br>
`vvvvvvvvvv}}}}rrwwwwwzwwxuuuuuuxxuuz. `~|+?ryyyyxzwvv>. ................. <br></br>
`vvvvvvvv}}}}}rrzuuuxxuzzxuuuuyuxxxuz.?uxwr?+?wv?`..    .................. <br></br>
`vvvvvvv}}}}}}rrxuuuxxxzzzxxxuuxxxxuz.?yuyyyy~.     .........  ........... <br></br>
`vvvvvv}}}}}rrrrwzzzzzzzzzzxxxxxxuuuu?~xuyu}^ .......  ................... <br></br>
`vvvvvv}}}}rrrrwwwwzzzzzzzxxxxxxuuuuuuzuyu|  ...................     ..... <br></br>
`vvvvvv}}}}rrrwwwwzzzzzzxxxxxxxuuuuuuyyxr^ .......  .......  .````..... .. <br></br>
`vvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx?|`.  .....  .... .....`-````````.... <br></br>
`vvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuyr-   ..  .. .. ........ .`.......`....`.<br></br>
`vvvvv}}}rrwwwwzzzzxuuuuuuuuuuuuuyr`   ... ....... .....`................` <br></br>
                  `wxxzwrrrrrwwwwzzzzzxxxuuuuuuyyyy?.  ................````...```..... ..... <br></br>
`uuyyyyuwrrwwwwzzzzxxxxxuuuuuyyu>  . ..................`.``......````````. <br></br>
`xuuuuuyuxxxzxzzzzzxxxxxxuuyuv>~ ........... ......--`.......`~^|~~-`..```.<br></br>
                  `zuuuuuuuuyyuuuxuuxxxuuxuuyr|   ............ ......````....-``~-```....... <br></br>
                  `zuuuuuuuuuyuuuuyyyyuyyuyy?. .  ...  ....... .......```-`..~~``--``......` <br></br>
`rzxuuuuuuxzzuuuuuuuyuyyr>... .... ...``............``````.`.-^||~~-``.... <br></br>
`}rrwzzzzzzxxuuuuuuuuuu?. .. ..... ..`...........``....`~|^^`....```````.. <br></br>
                  `zxxxzwwzzxuuuuuuuuuuv- .....`...  ..... ..-. ....`.`|^||~-```.......`````.<br></br>
                  -uyyyuzzzzzxuuuxxuuu?. ........... .....`~^^-....`-~-~-``````.`. ......`--.<br></br>
-uyyyuuxzzxuyyyuuyy>........ ...`.....`-~---`...`````.``````-``.`^+^-..... <br></br>
-uuuyyyyuxuyyyyyyw>......... .``....````....``. `-`.``````-`-``.``---``... <br></br>
                  -uuuuyuyyuyyyyyw^. .....`.. .......`````..`````.-~-.````-```````...```-~.. <br></br>
                  -uuuuuuuuyyyyyy|..................`-`````.``....```~-..``---``..`......-|- <br></br>
-uuuuuuuuuuuyy}...................```.....`......``-`..`-~--``..........-|.<br></br>


            </div>,

            <div>

.???????????????????????v}rrrrrrrrrrrrrrrrwwwwwwwwwrrrwrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvv}}}zxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvv}}}rzxuuuuuuuuuuuuuuuyyyuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}}rwzuuuuuuuuuuuuuyyyuuuuuuuuyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}rrrzxuw?uuuuuyyyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvv}}}}}}}rrrrwwxyr?yw}?}zuzrzuuuuuuuuyyyyyyyyyyyyyyyyyyuxw}v?`<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrrwwwwxv^?>^``~~?wxuuuuuuuuyyyyyyyyyyuyyuzr}v?>|-`. <br></br>
`vvvvvvvvvvvv}}}}}}rrrrwwwwwwzzv~---``|uuuuuuuuuuuuyyyyyuzw}v?>>+|`...  .. <br></br>
`vvvvvvvvvvv}}}}}}rrrrwwwwzzxxxxwvv>~.`ruuyyuuuuuyyyyyyxv?>~`.     ....... <br></br>
`vvvvvvvvvvv}}}}rrrrrwwwwzxxxuuuuuxu}  .|>?vzyyyyuuxzzr??|.  ............. <br></br>
`vvvvvvvvvv}}}}}wzzxzxxzzxxuuuuuuuxur ?zw}v?+?zzrv?+^~``. ................ <br></br>
`vvvvvvvvv}}}}}rzzxuxxxxxzxxuuuuuuxu} zyuyyyu??+~-`     .................. <br></br>
`vvvvvvvv}}}}}rrwwzzzxzzzzzzxxxxxxuuz-vyuuuzv?>.  ..   ................... <br></br>
`vvvvvvv}}}}}rrrrwwwzzzzzzzxxxxxuuuuuwwuyuv?+-. .......................... <br></br>
`vvvvvvv}}}}rrrwwwwwzzzzzzxxxxxxuuuuuyyuxv>+. .......................   .. <br></br>
`vvvvv}}}}}}rrwwwwwzzzzzzxxxxxxuuuuuurv?>^-. .... ..............`````..... <br></br>
`vvvvv}}}}}rrwwwwwzzzxxxxxxxxxuuuuyz?>^`.      ...............``....```... <br></br>
`vvvvv}}}}rrrwwwzzzzxxuuuuuuuuuuuyz??~  .. ....... ....................... <br></br>
`rwwwrr}}rrrwwwwzzzzxxxuuuuuuyuuyr?>- ..................`................. <br></br>
`wzuuuuxzwrrwwwzzzzzzxxxxuuuuyyyr?>` ....................``............... <br></br>
`wxuuuuyyuxzzzzzzzzzzxxxxxuuuwvv?>. ...............``........``-``--```.``.<br></br>
                  `wzxuuuuuuyyuuxxxxxxxxxxxuuzv?^`....................````...``.`----```.... <br></br>
                  `wzxuuuuuuuuuuuuuuyuuuuuuur??~. ......................```...````````...... <br></br>
`rwzxuuuuuuuxuuuuuuuyyyyz}?>` ........................``````.`~~~~~-``.... <br></br>
                  `rrrwzzxxxxxxxxuuuuuuuur??~. ....................``....``-~-`...````````.. <br></br>
`wzzzwwwzzzxxuuuuuuuuwv>+` .........................``-`------.......````` <br></br>
`xuuuuxzzzzxxuuuuuuur?>-................``````....```-----``````.......```.<br></br>
`uuyyyuuxzxuuuuuuuu}?>`................```---`....`````````````.`-```..... <br></br>
-uuyyyyyuuuuuyyyyx}??`.................````.``....`.`````````````.-~~-`... <br></br>
-uuuuuyyyyuuyyyx}v>|`.................``......`.`.-```````-`````....``--`. <br></br>
-uuuuuuuuyyyyyy}v+..................`````````...`.`-`.````--```.`.....``--.<br></br>
                  -uuuuuuuuuuuyyzv?...................`.....`........```..```-```.........`~.<br></br>


            </div>,

            <div>

.?????????????????????????}rrrrrrrrrrrrrrrrwwwwwwwwrrrwrwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}ruuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}zuuuuuuuuuuuuuuuyyyyyyuuuuyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv}}}rwxuuuuuuuuuuuuyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}r}}zwvuuuuuyuyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rrrrxr?uwv?}wxzwzuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyux|<br></br>
`vvvvvvvvvvvvvvv}}}}}}}}rrrrrwv+?>^``~~?wxuuuuuuuuuyyyyyyyyyyyyyyyyuxwv?^-.<br></br>
`vvvvvvvvvvvvv}}}}}}}rrrrrwwwrr?--~-``|uuuuuuuuuuuuuyyyyyyyyuz}rrv>^~`.... <br></br>
`vvvvvvvvvvvvv}}}}}}rrrrwwwwwzzw}??^-.`ruuuuuuuuuuuuyyyyyyw?^~``.......... <br></br>
`vvvvvvvvvvvvv}}}}}rrrrwwwwzxuuuuxxxv  .|>?vwxuuuuyyyyyyuv-`.... ......... <br></br>
`vvvvvvvvvvvvv}}}}rwwwwzzzwxuuuuyuxu} ?zrv?>^?xyyyu}v?>+~`................ <br></br>
`vvvvvvvvvvv}}}}}rzuuuxxuzzxuuuuuuxu} wyuyyyuzuw?>|`...  ............ .... <br></br>
`vvvvvvvvv}}}}}}rrzxxxxzzzzzzxxxxxxuw`vyuuuuyyz-`...    ....... .......... <br></br>
`vvvvvvvv}}}}}}rrrwwwzzzzzzzzxxxxxxuuvryuuuyw>-`........  ................ <br></br>
`vvvvvvvv}}}}rrrrwwwwwzzzzzzxxxxxxuuuzuuyyyw-........ ...............   .. <br></br>
`vvvvvvvv}}}}rrrwwwwwzzzzzxxxxxxxuuuuuyuz}?-.. ...  . ..........`````..... <br></br>
`vvvvvvv}}}}rrrwwwwwzzzzzzxxxxxuuuuuuy}^`....  ...  ..... ....`````..```.. <br></br>
`vvvvvvv}}}}rrrwwwwzzzxuuxxxuxxuuuuuy?`.....  .....  ..................... <br></br>
`vvv}}vv}}}rrrwwwwzzzzxuxuuuuuuuuuyu?....... ....... .....`............... <br></br>
`v}zxuuxzwrrrrwwwzzzzzxxxuuuuuuyyyx^......................`............... <br></br>
                  `vrxuuuyyyxwwwwwwzzzzzxxxxxuuuuyuw|`.. ..................`.......````-`..`.<br></br>
`v}zuuuuuuyuuuxuxzzzzzxxxxxuuyw+-`............ ......```.......-||--~``... <br></br>
`v}zxuuuuuuuyyuuuuuuuuxuuuuuu?`.. ............ .......````...--..```...... <br></br>
`}}wxuuuuuuuuuxuuuuuyyyyyyyx+``. .... ................``````.``~|~~--`.... <br></br>
                  `rrrrzxxxuuxxzzuuuuuuuuuyu?|``. ...................`...```````.`~-~--````. <br></br>
                  `rrrrwwwwzzzzxuuuuuuuuuur|.`.......................``...`-~|||`....``..``` <br></br>
`rwxuuuzwzzzzxuuuuuuuux>`........... ...`...`~`....````~~~--````.......```.<br></br>
                  `zuuyyyuxzzzzxuuuxxuuz^`............ ....`--~~-`...`--`````````.`...``.... <br></br>
                  -uuuyyyyyuxzxuuyyyyyx^`.......  .......````````.....```.``````-``.-||~~... <br></br>
                  -uuuuuyyyyuuuuyyyyz?~``...............````....```.```.````---````...``---. <br></br>
                  -uuuuuuuuuyyyyyyyv|-.................`````..```.`.````````-`-`````....```~.<br></br>
                  -uuuuuuuuuuuyyyyu^|`................````.`..``......`~`..``---``..`......`.<br></br>


            </div>,

            <div>

.???????????????????????????v}}rrrrrrrrrrrrrrrrwwwwrrwwwwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}zxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}zxuuuuuuuuuuuuuuuyyyyyyuyyyyyuuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}rzuuuuuuuuuuuuuuuuyyyuuuuuuyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}v+vwxuzzxxuuuuuuuuyyyuuuuuuuyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}>?rzwv??}}r??vzyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}rr?+vv?^~`-~-?uuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyu|<br></br>
`vvvvvvvvvvvvvvvv}}}}}}}}rrrr?+`~~~`..|xyuuuuuuuuuuuuyyyyyyyyyyyyyyyxzr}?>`<br></br>
`vvvvvvvvvvvvvvvv}}}}}}rrrrwwwr?|||```~zyyuuuuuuuuuuuyyyyyyyyuzrvvv?>+~``. <br></br>
`vvvvvvvvvvvvvvvv}}}}}rrrwwwwwwzzzw}+   ~+?}vvwuuuuuuyyyyyyu}?+|-...... .. <br></br>
`vvvvvvvvvvvvvvvv}}}}rrrrwwwwzxxxuuuv ~??>++>??zuuyyyyyuuxw?>|.   ........ <br></br>
`vvvvvvvvvvvvvvv}}}}rwzzzzzzzzxuuuuy? vuuuyyyyuuuyuuzv?>+|~-`. ........... <br></br>
`vvvvvvvvvvvvv}}}}}rzxxuuuxxxxxuuuuy?.zxuuuuuuuyyv?>|~`..    ...... ...... <br></br>
`vvvvvvvvvvvvv}}}}rrwzxxxxzzxzzxxxxur ruuuuuuuyu}++.      ................ <br></br>
`vvvvvvvvvvv}}}}}rrrrwwwzzzzzzzzxxxxz|wuuuuuuy}>+~. ...... ............... <br></br>
`vvvvvvvvvvv}}}}rrrrwwwwwzzzzzzxxxxxuuuuuuyyu}^^.  ...  ....... .......    <br></br>
`vvvvvvvvvvv}}}rrrrwwwwwzzzzzxxxxxxuuuuuuz}?>^~. ...  .... .........```... <br></br>
`vvvvvvvvvv}}}}rrrwwwwzzzzzzzxxxxxuuuuux?+|`..    ... ............```...`` <br></br>
`vvvvvvvvvv}}}rrrwwwwzzzxxxxuxxxuuuuuyx?+~.  . ....... ................... <br></br>
`vvvv}rrr}}}}rrrrwwwzzzzxxuuuuuuuuuuyz>^~................................. <br></br>
`v}v}zxuuuxzwrrrwwwwzzzzzxxxxuuuuyyyr>+- ..................`..`........... <br></br>
`vvvrzxuuuyyuxwwwwwwzzzzzxxxxuuuuzwv>+` .....................`..`..``````` <br></br>
`vvv}wxuuuuuuyuuuuxxxzzzzxxxxxuxv>|-`...................```....`.``--~-``` <br></br>
`wr}vwzuuuuuuuuyuuuuuuuuuuuuuuw>+|.  ....................````..```````.... <br></br>
-yuzrrzxuuuuuuuuuuuuuuyyyyyyur>+- .......................`````...~~~~~-``. <br></br>
-uxxwrrrzxxxxxxxxxxuuuuuuuyw>>^` ..........................```-`..``----`` <br></br>
                  `wzwrrwwwwwzzzzxxuuuuuuuuzv++- .................. ....`.`.```-~~~-.......` <br></br>
                  `rrrzxuuuuzzzzzzxuuuuuuuw?+~...................```....`````~~--````....... <br></br>
`wzxuuyyyyuxzzzxuuuuuuur>+`................```--~`....`````````````..`.... <br></br>
-uuuuuuyyyyyuxxuuuyyyur>>`................````````....`.```````````.``-|~- <br></br>
-uuuuuuuuyyyyyuuyyyurv>^`.................``......`.`.-`````````````...``-.<br></br>
-uuuuuuuuuuuyyyyyyy}?+`..................``...`.`...`.```.`````````......`.<br></br>


            </div>,

            <div>

.??????????????????????????????}rrrrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv}zuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvrxuuuuuuuuuuuuuuuyyyyyyyyyyyyuuuyyyyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvrxuuuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvv+vwxuzzxxuuuuxuuuuyyyyuuuuuuuyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv}}??rwrv??}}}??vzyyyyyyuuuuuuuyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv}}v}}}?>??>^~`-~-?uuuyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}}}}}}?+`~~~`..|xyuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyx|<br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rrrrr}?|||```~zyyyuuuuuuuuuuuuyyyyyyyyyyuxxxw?>|- <br></br>
`vvvvvvvvvvvvvvvvv}}}}}rrrrwwwwwwwwv+   ~+?}vvwxuuuuuuuuyyyyyyxv>~```..... <br></br>
`vvvvvvvvvvvvvvvvv}}}}}rrrrwwwwzzuuuv -??>++>??zuuuuuuyyyyyyyr~..... ..... <br></br>
`vvvvvvvvvvvvvvvvv}}}rrwwwwwwwwxuuuu? vuuuuyyyuuuuuyyyyzwrv?+............. <br></br>
`vvvvvvvvvvvvvvv}}}}}rzxxuxxxzzxuuuy?.zxxxuuuuuuuuyx}v>`...  ............. <br></br>
`vvvvvvvvvvvvvv}}}}}rrxxuuxxxzzzxxxu} ruxxuuuuuuyyx~.. ..  ........ ...... <br></br>
`vvvvvvvvvvvvv}}}}}rrrwwzzzzzzzzzzxxw|wuuuuuuuuyxv|........  ............. <br></br>
`vvvvvvvvvvvvv}}}rrrrwwwwwzzzzzzxxxxxuuuuuuuuyyx~......................  . <br></br>
`vvvvvvvvvvvv}}}}rrrwwwwwzzzzzxxxxxxxuuuuuuyux}~ ..... .  .......  .```... <br></br>
`vvvvvvvvvvvv}}}rrrwwwwzzzzzzzxxxxxuuuuuuyz?~`... ....  .... .....`````.`` <br></br>
`vvvvvvvvvvv}}}rrrrwwwzzzzxxxxxxxxxuuuuuy}`.....  ..... .................. <br></br>
`vvvvvvvvvvv}}}rrrwwwwzzzzuuuuuuuuuuuuuyv... ... ....... ................. <br></br>
`v}vvvvzxxzwwrrrrwwwwzzzzzxxxuuuuuuyyyy?......................`........... <br></br>
                  `vvvvvwuuuyyyuwrrwwwwzzzzzxxxxuuuuuyyx+... ..................`..`.....```` <br></br>
                  `vvvvvrxuuuuuyuuxxzxzzzzzzxxxxxuuyx?+-............ ......```.......`~|~-~`.<br></br>
`rr}vv}zuuuuuuuuyyuuuuuuxxxuuxuuuv~.  ...................````....-```````. <br></br>
-uuuw}}zuuuuuuuuuuuuuuyyyyyyyuyu?... ....  ...............`````..-----```. <br></br>
-uyyxr}rwxuuuuuuxxxuuuuuuuuuyy}^... .......................`````.`.-|~|~-` <br></br>
-xxzrrr}rrwzzzzzzxxuuuuuuuuux>.... .............. .....``...``~~||`....``` <br></br>
`rr}}rwzxxxzwzzzxuuuuuuuuuu?-...............`....-. ....`.`~|~~~-```...... <br></br>
`rrrrwuuyyyuzzzzzxuuuxxuux>............. .....`-~|-....`-----``````.`. ... <br></br>
                  `xuuuuyyyyyyuxzzxuuyyuuyu+........ .........`--`--`.....```.``````-``.-||~.<br></br>
                  -uuuuuuuuuyyyyuxuyyyyyur^.................````....``..```.``````-`-``.```-.<br></br>
                  -uuuuuuuuuuuyyyyyyyyyw^`.................``.``....`.`.---````````````....`.<br></br>


            </div>,

            <div>

.?????????????????????????????????}rrrrrrrrrrrrrrrrrrrrrrwwwwwwwwwwwwwwwww~<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzuuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}zxxuuuuuuuuuuuuuuyyyyyyyyyyyuuuuyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxxuuuuuuuuuuuuuuuyyyyyyyyyyyuuuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?>vv}v}wwwwzyuzrwwuuuuuuuyyyuuuuuyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?+?}}}vvv>}??>+?vruuuuuuuyyuuuuuuuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv}?>?vv?+^.--~`}yuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}?>>|-|~-`.`^uuuuuuuyuuuuuuuuuuyyyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}}}v?+----`-`~xyyyyyyyuuuuuuuuuuyyyyyyyyyyyyyyyuu|<br></br>
`vvvvvvvvvvvvvvvvvvvv}}}}}}rrrr}vv?+-...^?vr}>?}uuuuuuuuuyyyyyyyyyuw}r}v>^.<br></br>
`vvvvvvvvvvvvvvvvvvv}}}}}rrrrrwwwwzx> .~|^|||?vvuuuuuuuuuuuyyyyyr?+|-`.... <br></br>
`vvvvvvvvvvvvvvvvvv}}}}}}rrrwwwwwzxu| vuxxxxuuuyuuuuuuuuyyyyyyx?|~...    . <br></br>
`vvvvvvvvvvvvvvvvvv}}}}}wzzzwzzzzxux`.uuuxxxxuuuuuuuuyyyxvv?>+|-`. ....... <br></br>
`vvvvvvvvvvvvvvvvvv}}}}rzuuuxxuzzxuz.^yuuxxxuuuuuuuuyr?>|-`... . ........  <br></br>
`vvvvvvvvvvvvvvvv}}}}}}rzxxxxzzzzzxv.}uuuxxuuuuuuuyyr~~...    ............ <br></br>
`vvvvvvvvvvvvvvv}}}}}rrrwwwwzzzzzzzr?xxxuuuuuuuuuyr>~-. ......  .......... <br></br>
-zrvvvvvvvvvvvvv}}}}rrrwwwwwzzzzzzxxuxxxuuuuuuyyy}--.. ...  .............. <br></br>
-yuw}vvvvvvvvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuyuwv?~`. ...  .............``` <br></br>
-uuzrvvvvvvvvvv}}}}rrrwwwwzzzzzxxxxxxxuuuuuuv^-`... ....  ..... ....`````. <br></br>
-uuw}vvvvvvvvv}}}}rrrwwwwzzzxuuxxuxxxuuuuuu?-`...  ......  ............... <br></br>
-xzw}vvvvv}}}}}}}rrrwwwwzzzzxxxuuuuuuuuuyx>-`. ... .............`......... <br></br>
`wr}vvvv}zxuuxzwrrrrwwwzzzzzxxxuuuuuuyyyz^`` ..................``......... <br></br>
`}}vvvvvwxxuuyyuxwwwwwwzzzzzxxxxxuuuuuxr^-`. ..................`.......``` <br></br>
`vvvvvvv}zuuuuuuyuuuxuxzzzzxxxxxxuuur+~``........... ......```.......-|~--.<br></br>
`rrwwr}v}zuuuuuuuuyyuuuuuuuuuuuuuux?~`. ....................````...--..``` <br></br>
-uuuyyxr}wxuuuuuuuuuxuuuuuyyyyyyyz^~`. .... ................``````.``~|~~-.<br></br>
-uuuuuxwr}rzxxxuxxzzxuuuuuuuuuyx>^~`. ...................`...`````-`.`----.<br></br>
-xxxzwwwrrwwwwwzzzzuuuuuuuuuuu}|--.......................``...`-~||~`....` <br></br>
`rrr}}}rzxuuuxzzzzzxuuuuuuuuz>~``......... ...`...`-.....```-~~~--````.... <br></br>
`}rrwwwzuuuyyuxzzzzxuuuuuuuw+~`........... ....`-`-~-`..``--`````````.`... <br></br>
                  `rxuuuuuuuyyyyyuxzuuuyyyyyz^|`....... .......````````.....``````````-``.-~.<br></br>
                  `xuuuuuuuuuuuyyyuuuuuyyyxv^~-.................``....``...``.```````````... <br></br>


            </div>,

            <div>

                  .??????????????????????????????????vrrrrrrrrrrrrrrrrrrrrrrrwwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}zuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwuuuuuuuuuuuuuuuyyyyyyyyyyyyuuyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxuuuuuuuuuuuuuuuyyyyyyyyyyyyuuuyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?>vvvvvvrwwzyuzrwwuuuuuuuyyyyyyuuuyyyuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?+?}}}vvv>}??>+?vruuuuuuuyyyyyuuuuuyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv}?>?vv?+^.--~`}yuuuuuuuyyyyyyuuuuuuyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv}?>>|-|~-`.`^uuuuuuuuyyyyuuuuuuuuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}v?+----`-`~xyyyyyyyyuuuuuuuuuuuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv}}}}}rrr}vv?+-...^?vr}>?ruuuuuuuuuuuyyyyyyyyyyyyyyu|<br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}}rrrrwwwwz+ .~|^^||?vvuuuuuuuuuuuuyyyyyyyzv>|||` <br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}rrrrrwwwwz| vuuxxxxuuyuuuuuuuuuuuyyyyyyx+..    . <br></br>
`vvvvvvvvvvvvvvvvvvvv}}}}}rrrwwwwwzx`.uuuuxxxxuyuuuuuuuuyyyyuxzrv` ....... <br></br>
`vvvvvvvvvvvvvvvvvvvv}}}}rzxxxzxxzzz.^yuuuxxxxuuuuuuuuyyxwv~-`..  ........ <br></br>
`vvvvvvvvvvvvvvvvvv}}}}}rrxuuuxxxzxv.}uuuxxxxuuuuuuuuyu+`.    .........    <br></br>
`vvvvvvvvvvvvvvvv}}}}}}rrrwzzzzzzzzr?zxxxxxxuuuuuuuuyz>  .....  .......... <br></br>
`rr}vvvvvvvvvvvvvv}}}rrrrrwwwwzzzzzxxxxxxxuuuuuuuuyu+. ................... <br></br>
-uyywvvvvvvvvvvvv}}}}rrrwwwwwzzzzzxxxxxxxuuuuuuyyyx+ .... ..  .......  .`. <br></br>
-uyyxvvvvvvvvvvvv}}}rrrwwwwwzzzzzzxxxxxxuuuuuyu}>~.  .....  .... ......```.<br></br>
-uyyxvvvvvvvvvvv}}}rrrrwwwzzzzzxxxxxxxxuuuuuyw~   ..  ..... ..........```. <br></br>
-uyyzvvvvvvvvvvv}}}rrrwwwwzzzxuuuxuuuuuuuuuyw`  ...  ....... ............. <br></br>
-uuzvvvvvvvwzwwr}}rrrwwwwzzzzxxxxuuuuuuuuyyv. ....................`....... <br></br>
`r}vvvvvvvwuuyyuuzrrrwwwwzzzzxxxxuuuuuuyyy?  . .............. ...`.``..... <br></br>
                  `vvvvvvvvvwuuuuuyyuzzzzzzzzzzxxxxxxuuuurv^ ... ....... ......``........``~.<br></br>
`vvvv}}vvv}xuuuuuuuyyyuuxxxxxxxxxxxuyz+.  ............ ......````. ..``-~-.<br></br>
`zzzxuuzr}}xuuuuuuuuuyuuuuyyuuuuuuuyv` . ....  ....... .......`````..~-``` <br></br>
-uuuuuyyx}}wxuuuuuuuxxxuuuuuuyyuyyz?... .... .................``````.``|^|.<br></br>
-uuuuuuxr}}}rwzzzzzzzzxuuuuuuuuuuv.... ..... ..`...........``....`-~|~..`` <br></br>
-xxwwrr}}rrwzzzwwwzzxuuuuuuuuuu}^ ......... ........... ...``..-||||~-``.. <br></br>
`}}}vv}}}wuyyyyxwzzzzxuuuxuuuu?. ..........  .....``|^`....`---~~-```````. <br></br>
`}}}wzxxxuyyyyyuxxzzxuuyuuuuy?........ .........`---~-`...```````````````. <br></br>
`}}zyyyyuuuuuyyyyuxxuyyyyyyu?......... ........`````..`...`.`.`.````````.. <br></br>


            </div>,

            <div>

.???????????????????????????????????v}rrrrrrrrrrrrrrrrrrrrrrwwwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}wuuuuuuuuuuuuuuuyyyyyuyyyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}zxuuuuuuuuuuuuuuyyyyuuuuyyyyyyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvzuuuuuuuuuuuuuuuyyyyyyyyyyyyuuyyyyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwrzzrv??vwuuuuuuuuyyyyyyyyyyyuuuuyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv>+vvvvvvvv>?}>+-+rzwxuuuuuuuuyyyyyuuuuyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv?>?vvvvv?>``|~|`vyuuuuuuuuuuuyyyyuuuuuuuyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv>????^^|~`..`~zuuuuuuuuuuyyyyyuuuuuuuyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv?>>|`~~~--``|zuyuuuyyyuuyyuuuuuuuuuyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv}vvv?^^+~```.`>??v}>>vuuuuuuuuuuuuuyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv}}}}}rrrrrv- . ...`--vwuuuuuuuuuuuuuyyyyyyyyyyuzx|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv}}}}}rrrrww` ^wwwzzxxuuxuuuuuuuuuuuuuyyyyyyzv>~--.<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}}rrrrww} .wxuuxxzzxxuuuuuuuuuuuuyyyyyyyv~`.... <br></br>
`vvvvvvvvvvvvvvvvvvvvvv}}}}}rwwwwwzv +uuuuuuxxxxuuuuuuuuuyyyywr}v?^`...... <br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}}rzuuuxuu+.zuuuuyuxxxxuuuuuuuuyxv?+`............ <br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}}rxxuuxxx~+xxxuuuxxxxxuuuuuuyyz-.....  ......... <br></br>
`}vvvvvvvvvvvvvvvvvvv}}}}rrrwwzzzzzrwzzxxxxxxxuuuuuuuyz?~........  ....... <br></br>
-uzzzwvvvvvvvvvvvvvv}}}rrrrrwwwwzzzzxzxxxxxxuuuuuuuyyw-................... <br></br>
-uyyyyx}vvvvvvvvvvv}}}}rrrrwwwwwzzzzzxxxxxxuuuuuyyuzv~...... .  .......  . <br></br>
-uuuyyurvvvvvvvvvv}}}}}rrrwwwwzzzzzzxxxxxuuuuuuyr>~`... ....  .... .....`` <br></br>
-uuuyyxr}vvvvvvvv}}}}}rrwwwwzzzzxxxxxxxxuuuuuuyv`....   ..... ............ <br></br>
-uuuyuzrvvvvvvvvvvv}}rrrwwwwzzzxxuuuuuuuuuuuuy?....... ....... ........... <br></br>
-uuuxw}}vvvv}zxxzwwrrrrwwwwzzzzxxxxuuuuuuyyyu>.....................``..... <br></br>
`wr}}}vvvvvvwxuuyyyuwwrwwwwzzzzzxxxxuuuuuyyz|... ..................`.``... <br></br>
                  `vvvvvvvvvvvrxuuuuuyuuxxzxzzzzzxxxxxxuuyz?^-............ ......```.......`.<br></br>
`vv}vv}r}}vv}zuuuuuuuuyyuuuuuuxxxuuxuuuv~.. ...................`````...-`` <br></br>
`xxuxxuuuuw}}zuuuuuuuuuuuuuuyyyyyyyuyx+``. .... ................`````..---.<br></br>
-uuuuuuuuyxr}rwxuuuuuxxxxuuuuuuuuuyuv^``. .......................```````.-.<br></br>
-uuuuuuxxzrrr}rrwzzzzzzxxuuuuuuuuuz^.`.. ....................``...``~|^|`. <br></br>
`rwzwwrrr}}rwxxxxzwzzzxuuuuuuuuux?-...............`...`-.....````||~~-```` <br></br>
`}}v}}}}}rrzuyyyyuzzzzzxuuuxuuuz+``........... .....--~~`....`----```````..<br></br>
`}}}}}zxuuuuuyyyyyuxzzxuuyyuxxx^`.................`--`-``.....```.```````` <br></br>


            </div>,

            <div>

`??????????????????????????????????v}}rrrrrrrrrrrrrrrrrrrrrrrrwwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}rrxyuuuuuuuuuuuuuuuyuuuuyyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wuuuuuuuuuuuuuuuuyuuuuuyyyyyyuyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwuuuuuuuuuuuuuuuuyyyyyyyyyyyuuyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrw}}rrv??vwuuuuuuuuuyyyyyyyyyyyyuuuuyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv>+vvvvvvvv>?}>+-+rzwxuuuuuuuuuuyyyyyyuuyyyuuuyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv?>?vvvvv?>``|~|`vyuuuuuuuuuuuuuyyyyyuuuuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv>????^^|~`..`~zxuuuuuuuuuuuuyyyyyuuuuuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv?>>|`~~~--``~wzuuuuyyyuuuyyyyyuuuuuuuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv}vvv?^^+~```.`+??v}>>vuuuyuuuuuuuuuuyyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}}}}rrr}v- . ...`--vzuyyuuuuuuuuuuuuyyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}rrww` |rwwwzzxuuuuxxxxuuuuuuuuuuyyyyyyyyzv`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv}}}}}}rrrw}  rzzzxzzzzzxxxuuuuuuuuuuuuuyyyyyy?~.. <br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}}}rrrrwv ^xzxuuuuxzxxxuyuuuuuuuuuyyyyyyuw|  .. <br></br>
`vvvvvvvvvvvvvvvvvvvvvv}}}}}}rwzzzz+.wzxuuuuyuxxxxuuuuuuuuuyyuw?>^~`. .... <br></br>
`vvvvvvvvvvvvvvvvvvvvvv}}}}}rrxuuux~+xzxuuuuuxxxxuuuuuuuuyy?|-.      ..... <br></br>
`vvvvvvvvvvvvvvvvvvvvv}}}}}rrwzxxxzrwzzzzxxxxxxxuuuuuuuuyy?   ...   ...... <br></br>
-uuwvvvvvvvvvvvvvvvvvv}}}}rrrrwwwwzzzzzzxxxxxxuuuuuuuuuy?-. ........ ..... <br></br>
-uyyuuux}vvvvvvvvvvvv}}}}rrrwwwwwzzzzzzxxxxxxuuuuuuuyyy?  .. ...  ........ <br></br>
-uuuuyyyzvvvvvvvvvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyx}?~. ..... ........... <br></br>
-uuuuuyyzvvvvvvvvvvv}}}rrrrwwwwzzzzzxxxxxxxuuuuuuu>.      ....  ...........<br></br>
-uuuuuyyzvvvvvvvvvv}}}}rrrwwwwzzzxxuuxuuuuuuuuuyx~  ...  ...... .......... <br></br>
-uuuuyyx}vvvvvv}rr}}}}rrrwwwwzzzzxuuxuuuuuuuuuyw- ...... .............`... <br></br>
-uuuxzrvvvvvvvruuyuuxwrrrwwwwzzzzxxxxuuuuuuyyy}. . .............. ...````. <br></br>
                  `rrvvvvvvvvvvvzuuuuyyuxwwwwzzzzzzxxxxxuuuuyxz?. .. ....... ..........`.... <br></br>
                  `vvvvvvvvvvvvvrxuuuuuuuuuuuuxxxxzxxxxxxuuu?`. ............ .....````...... <br></br>
`}rrwwrwzzzw}v}xuuuuuuuuyyuuuuyuuuuuuuuyw~   .... ........ ......`````...~.<br></br>
`xuuuuuuuyyyz}}zuuuuuuuuuuxuuuuuyyyyyyyv. .  .... ............`...`````..`.<br></br>
`uuuuuuuuuuuw}}}wzxxxxxzzzxuuuuuuuuuyr~... ..... ..`...........`....````-` <br></br>
`wxxuuxxzzwr}rrrwwwwwwwzxuuuuuuuuuuz?. ........ ........... ...``..`-~|||~.<br></br>
`}}}}rr}}}}}}rxuuuuzwzzzxxuuuuuuuuv` ..........  ...`..`~|`....```-||~-```.<br></br>
`}}}v}}}}rwwwxyyyyyuzzzzxuuuxxxxxv...................`~~~~`...``-`````````.<br></br>


            </div>,

            <div>

                  `rwr????????????????????????????????vrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwwwww~<br></br>
                  -yywvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwzzxuuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyy^<br></br>
-yxvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}xuuuuuuuuuuuuuuuuuuuuuuyyyyyyuyyy^<br></br>
-u}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}zxuuuuuuuuuuuuuuyuuuuuyyyyyyyyyy^<br></br>
-wvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwvv?>?>>>vuuuuuuuuuuuuyyyyyyyyyyyyuuyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv?vvvvvvvv??>vv^^-^wuxxuuuuuuuuuuuuyyyyyyyyyyyuuuuyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvv+>?vvvvvvv?^.|^||`?uuuuuuuuuuuuuuuuyyyyyyuuuyyuuyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv?>??v?v?+^^-````~rzuuuuuuuxuwzuuuuyyyyuuuuuuyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv?>>>??-~|~~-```~rwwxuuuyx?>++?zyyyyyyuuuuuuyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?+>+^~~~-``-``|???v}?+vu+~~xyyyuuuuuuuuuyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv}}vvvv?>`..   ....`zur^|ryuuuuuuuuuuyyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}rwr. `?v}rrrzuyuyuzxuxxuuuuuuuuuyyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}rw? .rzwwwzzzzxxxxuxxxuuuuuuuuuuuyyyyyyuw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}w^ ?zwwwrwuxxzzzxxuuuuuuuuuuuyyyyyyyyw|. <br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv}}}}rrrr` rwwzxw?}wyuxxxxuuuuuuuuuyyyyuuzw}?... <br></br>
-rvvvvvvvvvvvvvvvvvvvvvvvv}}}}}rzuv.?uxzwv?-~>rxxxxxuuuuuuuuyyzw?-`... ... <br></br>
-x}vvvvvvvvvvvvvvvvvvvvvv}}}}}rwxuw?xxxzzzz???zxxxxuuuuuuuuyx|`. .  ...... <br></br>
-uzr}vvvvvvvvvvvvvvvvvv}}}}}}rrrzzzxzzzzzzxuuzxxxxuuuuuuuyur^ ......  .... <br></br>
-uyyyxrww}vvvvvvvvvvvvv}}}}}rrrwwwwwzzzzzzxxxxxxuuuuuuuuyx|............... <br></br>
-uuuuuyyyywvvvvvvvvvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuuyyuw| .... .   ...... <br></br>
-uuuuuuyyyx}vvvvvvvvvv}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyxv+-. ...... ....  ... <br></br>
-uuuuuuuyyxvvvvvvvvvv}}}}rrrwwwwzzzzxxxxxxxxuuuuuuy}`  ...  ..... ........ <br></br>
-uuuuuuuyyzvvvvvvvvvv}}}}rrrwwwwzzzxuuuuuuuuuuuuuy}......  ............... <br></br>
-uuuuuuuuw}vvvvv}wzzwrr}rrrwwwwzzzzxxxxuuuuuuyyyy?...................`..`. <br></br>
`zxuxzwr}vvvvvvvwuuyyyuxwrwwwwwzzzzzxxxuuuuuuyyu>... ..................`.. <br></br>
`vvvvvvvvvvvvvv}zuuuuuyyuzzzzzzzzzzxxxxxxuuyu}?|............ ......``..... <br></br>
`vvvvvvvvvv}}vvv}xuuuuuuuyyyuuxxuxxxxxxxuuyr^.  ... .... .........`````. . <br></br>
`vvrzwzxzxxuuxr}}xuuuuuuuuuyuuuyyyyuuyuuyu?... .... ........ .......`````. <br></br>
`}zuuuuuuuuuyyz}}wxuuuuuuuxxxuuuuuuuyyyyw>... .... .................`````. <br></br>
`xzxuuuuuuuuuxr}}}rwzzzzzzzxxuuuuuuuuyu?.... ..... ..`...........``...``-~.<br></br>
-x}}wzzxzwwrr}}rwzzxzwwwzzxuuuuuuuuuuv~...............`....`. ...``.`~|||~.<br></br>
`r}}v}}}}v}}}}}wuyyyyxzzzzzxxxxxxxxx>............  ......-~|`....``--~--``.<br></br>


            </div>,

            <div>

`rwwwrv????????????????????????????v}}rrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwwwww~<br></br>
-yyyyzvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyyyyy^<br></br>
-uyyxvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rrwuuuuuuuuuuuuuuuuuuuuuuyyyyyyyyy^<br></br>
                  -uyywvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvzuuuuuuuuuuuuuuuuyuuuuuyyyyyyyy^<br></br>
-uyxvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wwvv?>?>>+vuuuuuuuuuuuuuuuyyyyuuyyyyyyyyy^<br></br>
-yurvvvvvvvvvvvvvvvvvv?vvvvvvvv??>vv^^-^vrzxuuuuuuuuuuuuuyyyyyyyyyyyyuuuyy^<br></br>
-z}vvvvvvvvvvvvvvvvvv+>?vvvvvvv?^.|^||`?rwuuuuuuuuuuuuuuuuyyyyyyyyyyyuuuyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvv?>????v?+^^-````~r}wuuuuuuxuwzuuuuuuyyyyyuuuuyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv?>>>??-~|~~-`.`~rrrzxuuyx?>++?wuuuyyyyyuuuuuuyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?+>+^~~~-``-``|????v?+vu+~~xuuyyyyyyuuuuuuuyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv}vvvv??`..   ....-zur^|ryuuyuuuuuuuuuyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}wr. `?v}rrrwxyuyuzxyuuuuuuuuuuuuyyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}w? .rzwwwwwwzxuuuuuuuxxxuuuuuuuuuyyyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}w^ ?zwwwrrzzzzzzzzxxxuuuuuuuuuuuuuyyyyyy|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}rr` }wwwz}>vwuuxxzzxxuyuuuuuuuuuyyyyyyyu?.<br></br>
-xwvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}r?.?zwzwv>-~>}xyuxxxxuuuuuuuuuyyyurv?>+-  <br></br>
-uywvvvvvvvvvvvvvvvvvvvvvvv}}}}}rww?uuxuxzw???zuuuxxxxuuuuuuuuyw?>-      . <br></br>
-uyuvvvvvvvvvvvvvvvvvvvvvv}}}}}rrwxuuxzxzzzxxwxxxxxxxuuuuuuuyy}.   .. .... <br></br>
-uyuxxw}vvvvvvvvvvvvvvvvv}}}}}rrrrwwwzzzzzzzzxxxxxxuuuuuuuuyr>. .......... <br></br>
-uuyyyyuxxxwvvvvvvvvvvvvv}}}}rrrrwwwwzzzzzzxxxxxxxuuuuuuuyyv. ............ <br></br>
-uuuuuuuyyyywvvvvvvvvvvvv}}}rrrrwwwwzzzzzxxxxxxxuuuuuuyyxr?. ..... .  .... <br></br>
-uuuuuuuuuyyzvvvvvvvvvvv}}}rrrrwwwwzzzzzzxxxxxxuuuuuuyv|..    .... ..... . <br></br>
-uuuuuuuuuyyzvvvvvvvvv}}}}}rrrwwwwzzzzxuuxxxxxuuuuuuu>   ..  ...... ...... <br></br>
-uuuuuuuuyyurvvvvvvv}}}}}}rrrwwwwwzzzxuuuuuuuuuuuuyu+  ..... ....... ..... <br></br>
`zxuuuuuuuz}vvvvvvrxuuxzwrrrrwwwzzzzzxxxxuuuuuuyyyz-   ................... <br></br>
`vvwzzwr}vvvvvvvv}zuuuyyyuzwwwwwwzzzzxxxxxuuuuyyy}` .. ....... ..........` <br></br>
`vvvvvvvvvvvvvvvv}wuuuuuuuuuuxxxzzzzzxxxxxxuuyr^~. ........... .....````.. <br></br>
`vvvvvvv}}}}rwr}vv}xuuuuuuuuyyuuuuuuxxxuuuuux>.  .... .... ..........````. <br></br>
`vvvrxuuuuuuuyyuw}}xuuuuuuuuuuuuuuyyyyyyyyyw~ .  .... ................````.<br></br>
`wrwxuuuuuuuuuyyw}}rwxxuuuuxzzxuuuuuuuuuyx>` .. .... ...`.............````.<br></br>
-uuuwzuuuuuuxxzw}}}rrwwwzzzzxxuuuuuuuuuu}` ........ ...............``...`-.<br></br>
-uuwvvrrwwwr}}}}}rzxuuxzwzzzxxxxxxxxxxw^. ......... ....`...`~. ....`.`|||.<br></br>


            </div>,

            <div>

`rwwwww}???????????????????????v}v??rrrw???|}rrrrrrrrrrrrrrrrrrrrrrrrwwwww~<br></br>
-yyyyyyxvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rwxvvv+}yuuuuuuuuuuuuuuuuuuuuuyyyyyyy^<br></br>
-uyyyyx}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vv^?uuuuuuuuuuuuuuuuuuuuuuyyyyyyy^<br></br>
-uyyyywvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??v??>~}uuuuuuuuuuuuuuuuuuuuuuyyyyyyy^<br></br>
-uyyyuvvvvvvvvvvvvvvvvvvvvvv}rrr}}vv?+^?++-?rxuuuuuuuuuuuuuuuyuuuuuyyyyyyy^<br></br>
-uyyywvvvvvvvvvvvvvvvvvvvvvw}}vv}wr>++^-~-`vxuuuuuuuuuuuuuuuyyyyyyyyyyyyyy^<br></br>
-yyuwvvvvvvvvvvvvvvvvvvvv?vwvv+.^??^+|~||->zuxv}uuuuuuuuuuuyyyyyyyyyyyyuyy^<br></br>
-xz}vvvvvvvvvvvvvvvvvvvvvvvvvv+``+^|+~~-~-?wv?+^}uuuuuuuuuuuyyyyyuuuyyuuyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv?>+>+``--~^|~--+?>>+^^vzuuuuuuuuuyyyyyuuuuuyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv?+++++^-``-`-~-`|}}}rrv?zuxxuuuuuuyyyyyuuuuuuuyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvv?^^++^^||~-```---^}vvv}wuuuxxuuuuuyyyuuuuuuuuuyyyyy^<br></br>
                  `vvvvvvvvv??vvvvvv???????|||||~~~~~~``-``?vrwwzuyyuuuuuuyuuuuuuuuuuuyyyyyy^<br></br>
`vvvvvvv????????vvvv?>++>|`---|^|---`.`.  .`|>vwzrrwwuyyuuxuuuuuuuuuyyyyyy^<br></br>
`vvvvvv?>??>???>>>+>^|+^+>>???vvv?>>. .  ..    ..    ->vwuxuuuuuuuuuuyyyyy^<br></br>
`vvvvvvvv????vvvv???vvvvvvvvvvv}}}+|~  .vr}}}}vvvvvv???+}uuuuuuuuuuyyyyyyy^<br></br>
`rr}vvvvvvvvvvvvvvvvvvvvvvvvvvv}}}?>|  |zwwwwxuuuuuxxuuuyuuuuuuuuyyyyyuxzr-<br></br>
-yyyxvvvvvvvvvvvvvvvvvvvvvvvv}}}}}rw}  |xxzwzuuuuuuxxxxxuuuuuuuuyuxw>|~`.. <br></br>
-uyyywvvvvvvvvvvvvvvvvvvvvvv}}}}}}rzx` `zuxzzxuuuuuxxxuuuuuuuuuy}~`.     . <br></br>
-uyyyx}}vvvvvvvvvvvvvvvvvvv}}}}}rrrwx>  }zzzzzxxxxxxxuuuuuuuuyu}. .....  . <br></br>
-uuyyyyyuw}r}vvvvvvvvvvvvv}}}}}rrrrww^ |xzzzzxxxxxxxuuuuuuuuy}-. ......... <br></br>
-uuuuuuuuyyyyxvvvvvvvvvvvv}}}}rrrrwwzv.+xzzzxxxxxxuuuuuuuyyu}. ... ..  ... <br></br>
-uuuuuuuuuuyyyrvvvvvvvvvvv}}}rrrrwwwwz?vzzzxxxxxxuuuuuuuzv+~.  ....  ....  <br></br>
-uuuuuuuuuuyyyrvvvvvvvvv}}}}}rrrwwwwzzzzxxxxxxxxuuuuuuu?.   .   .... ..... <br></br>
-uuuuuuuuuuuyyrvvvvvvvvvv}}}rrrwwwwzzzzxuuuuuuuuuuuuuu>   ...  ...... .... <br></br>
-uuuuuuuuuuyuwvvvvvv}wwwrr}rrrwwwwzzzzzxxxuuuuuuuuuyx^  .................` <br></br>
-uzrzxuuuxwwvvvvvvv}xuyyyuxwrrwwwwzzzzzxxxxuuuuuyyyw- . ........ ......... <br></br>
-z}vvv}}vvvvvvvvvvv}xuuuuuyuxzzzzzzzzzzxxxxxuuuux}v` .. ..............``.. <br></br>
`vvvvvvvvvvvvvvvvvv}wuuuuuuuyyyuuuxxxxxxxxxxuuuv-  ...................```` <br></br>
`vvvvvvwwwxzzzxuxw}vwuuuuuuuuuyyuuuyyuuuuuuuuz+.   .... ....... .......```.<br></br>
`vvvvruuuuuuuuuuyyr}rzxuuuuuuuxxxuuuuuyyyuyur~ .. .... .................``.<br></br>
`wxxxxzuuuuuuuuuuz}}}rwzzxxzzzzxuuuuuuuuuuz|......... ...`..........``.....<br></br>
`uuuuz}rzxxxzzwwr}}rrwzzzwwzzwxxxxxxxxxxzv` ........  .......... ...``..`~.<br></br>


            </div>,

            <div>

`rrwwwww}???????????????????????}rr}?vrr???|}rrrrrrrrrrrrrrrrrrrrrrrrrrrww~<br></br>
-uyyyyyyyzvvvvvvvvvvvvvvvvvvvvvv}ww}vrxuvvv+}yuuuuuuuuuuuuuuuuuuuuuuuyyyyy^<br></br>
                  -uuuyyyyyrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vv^?yuuuuuuuuuuuuuuuuuuuuuuuuyyyy^<br></br>
-uyyyyyywvvvvvvvvvvvvvvvvvvvvvvvvvvvv??v??>~vzxuuuuuuuuuuuuuuuuuuuuuuyyyyy^<br></br>
-uyyyyyu}vvvvvvvvvvvvvvvvvvv}rrr}}vv?+^?+>-?}vwuuuuuuuuuuuuuuuuuuuuuuyyyyy^<br></br>
-uyyyyywvvvvvvvvvvvvvvvvvvvw}}vv}wr>++^--``v}}zuuuuuuuuuuuuuuuuyuuuuyyyyyy^<br></br>
-uyyyyxvvvvvvvvvvvvvvvvvv?vwvv+.^??^+|~~~`^}}wv}uuuuuuuuuuuuyyyyyyyyyyyyyy^<br></br>
-yyyuwvvvvvvvvvvvvvvvvvvvvvvvv+``+^|+~--~`>v??+^}xuuuuuuuuuuuyyyyyyyyyyyyy^<br></br>
`www}vvvvvvvvvvvvvvvvvvvvv?>+>+``-~~^|~``+?>+^|^vzuuuuuuuuuuuyyyyyuuuuyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv?+++++^-``-`-~-`|}}}rrv?zuuuuuuuuuuuuyyyyuuuuuuyyy^<br></br>
-zvvvvvvvvvvvvvvvvvvvvv?^^++^||~-````---^wr}}rwzxuuuuuuuuuuyyyyyuuuuuuuyyy^<br></br>
-u}vvvvvvv??vvvvvv??????>|~|~~------`````?vrrwwwuyuuuuuuuuyyyuuuuuuuuuyyyy^<br></br>
-u}vvvvv????????vvvv?>+++|`-``~^|---`.`.  .`|>vrwrrwwxyyyyuuuuuuuuuuuyyyyy^<br></br>
`rvvvvv?>??>???>?>+>^|^^+>>???vvvv>>. .  ..    ..    ->vzuxxuuuuuuuuuuyyyy^<br></br>
`vvvvvvvv????vvvv????vvvvvvvvvvvv}+|~  .vrr}}vv??vvv??>+vuxuuuuuuuuuuuuyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}?>|  ~zwwwwzzxuuuuuxxxxuyuuuuuuuuuyyyyyy^<br></br>
-uxxxz}vvvvvvvvvvvvvvvvvvvvvvvv}}}}wv  |zwwzwzxuuuuuuxxxxuuuuuuuuuyyyuwrv?`<br></br>
-uyyyyuvvvvvvvvvvvvvvvvvvvvvvvvv}}}}r` `xxxuzzxuuuuuuxxxxuuuuuuuuyzv?|..   <br></br>
-uuuuyywvvvvvvvvvvvvvvvvvvvvvvv}}}}rw>  wxxxzzzxxxuuxxxxuuuuuuuyyw`   .. . <br></br>
-uuuuyyuzzrvvvvvvvvvvvvvvvvv}}}}}rrrw^ |zzzzzzzzxxxxxxuuuuuuuuyz?` ....... <br></br>
-uuuuyyyyyyxzxz}vvvvvvvvvvvv}}}}rrrrwv.+zzzzzzxxxxxxxuuuuuuuyyw`  ........ <br></br>
-uuuuuuuuuuuyyyuvvvvvvvvvvvv}}}}rrrwwz?vzzzzzxxxxxxuuuuuuyyuw?` .....    . <br></br>
-uuuuuuuuuuuuyyu}vvvvvvvv}}}}}}rrrwwwwzzzzzzxxxxxxuuuuuuyr+`.    .... .... <br></br>
-uuuuuuuuuuuuyyu}vvvvvvvvv}}}}rrrwwwwzzzzxxuxxxxxuuuuuuy?.  ..   ..... ... <br></br>
-uuuuuuuuuuuuyyzvvvvvvvv}v}}}}rrwwwwzzzzxuuuuuuuuuuuuyy?   .... ....... .. <br></br>
-uuuzxuuuuuuuxrvvvvvv}zuuxxwwrrrwwwwzzzzzxxxuuuuuuyyyu^   ................ <br></br>
-uyu}vrzzzw}}vvvvvvvvwuuuyyyuzwwwwwwwzzzzxxxxuuuuuyyw~ .. ....... ........ <br></br>
`zxrvvvvvvvvvvvvvvvvvrxuuuuuuuuxuxxxzzzzxxxxxxuuyz>~. ........... .....```.<br></br>
`vvvvvvvvvv}r}}}wwrvvvzuuuuuuuuyyuuuuuuuxxuuuuuu?`   ... .... ... ......``.<br></br>
`vvvvvv}zuuuuuuuyyyx}}wuuuuuuuuuuuuuuyyyyyyyyyx^ .. ....  ...............`.<br></br>
`vv}rwrzuuuuuuuuuuyx}}rwzxuuuuxxzxuuuuuuuuuyu?- .. .... ...`.............`.<br></br>
`v}uuuuzrxuuuuuuxxzr}}}rrwzzwwwwzxxxxxxxxxxz^  .. ....  ..............``.. <br></br>


            </div>,

            <div>

`rrrrrwww}??????????????????????vrrrrv?}rr}}rrrrrrrrrrrrv^?????+`?rrrrrrww~<br></br>
-uyyyyyyyyzvvvvvvvvvvvvvvvvvvvvvv}zuxrvxuuuxuuuuuuuuxzwr}v}vvv?>>xuuuuuyyy^<br></br>
-uuuuyyyyuurvvvvvvvvvvvvvvv}wwrr}vvvvvv}wwzzxuuuuuz}vv}}}}v>??>}uuuuuuuuyy^<br></br>
-uuuuyyyuuzvvvvvvvvvvvvv}wwwwwwwwwv?vvvvvvv}xuv?v??vv}}}}v^>??xuuuuuuuyyyy^<br></br>
-uyyyyyyuxvvvvvvvvvvvvvwwwwwzzzxzzzv???vvv???v>+>++^+?vv?>>^>uuuuuuuuuyyyy^<br></br>
-uyyyyyyuwvvvvvvvvvvvvwrvrw}vvvvwwww????>+^+++>>>+++^^>?+^>>zuuuyyuuuuyyyy^<br></br>
-uyyyyuyxvvvvvvvvvvvvrrvvvvvvv??vrrvv?>^^++>>>>>>+^^^^|>+~?xuuuyyyyyyyyyyy^<br></br>
-uuuyyyx}vvvvvvvvvvv?wvvvvv??????v}?v>|++++>>++^^^^^^^|~?ruuuuyyyyyyyyyyyy^<br></br>
-uuuuxwvvvvvvvvvvvvv?rvvvv>~-~+?+??>?^-|^+++++|~|^^^^|^|ryuuuuyyyyyyuuuyyy^<br></br>
-x}vvvvvvvvvvvvvvvvvvvvvv?+`.`^?^^?>|^|~^>>>>+|||^^^||||?yuuuuyyyyyuuuuuyy^<br></br>
-uzv?vvvvvvvvvvvvvvvvvvv??+``|++?>>+~||~+>>>>>+^^^^^^|~|?yuuuyyyyyuuuuuuyy^<br></br>
-uyzvvvvvvvvvvvvvvvvvvv?>??-``.`|^^??|~~^+>>>>>+^^^^^|~}xuuuyyyyuuuuuuuuyy^<br></br>
-uuxvvvv??vvvvvvvvvvv????>>^```++`+~>+-~||^^^||||||||~+uuuuyyuuuuuuuuuuyyy^<br></br>
-uuzvvv??vvvvvv?v??>^+^^+>>^~|^|^^>^`~^^|^^|-````````|wuuuyuuuuuuuuuuuuyyy^<br></br>
-urvvvvvvvvvvvvv?>++>>>+++>>+~^++>+^~-~|++>+|||~~~-^?wxxxuuxxxuuuuuuuuuuyy^<br></br>
`rvvvvvvvvvvvvv?+>>>>>>>>^^++^~++^|^+|.`^|++^^^^||}zxzzzzxxuuuuuuuuuuuuyyy^<br></br>
`rww}rrvvvvvv>>+>>>>>>>>>++++>^~~^^+^-``|~^^^^^|~^vvuuxxxxuuyuuuuuuuyyuyyy|<br></br>
-uyyyyyu}v??>+^>>>>>>>>>+++>>>>>|~||^-``~~|^^^^|~~+vyuxxxxxuuuuuuyuyuxw?^~.<br></br>
`}r}rzxrvvv?^^^>>>++^^^^^+++^^^^^^-`--``-~|^^^|||^^}uuuuuuuuuuuyyuwv|`.    <br></br>
`}}v??v}vv}?|||+>+|||||||^++|||||||~-`.~|~|^^|||^^`.`-|+>?v}zuzv+`.        <br></br>
`vv}v??vvvv>|^|^^|||||||||^||||||||||~``~~|||||||`          .`.            <br></br>
.^>???>++>>>+~^^||||||||||||||||||^^^^||^||~~~|`.                          <br></br>
`??????^|+>^^+||||~~~||||||~~~~~||||||^||||~||-.                  ..... .. <br></br>
-uuuuuuz}vvv?vv??^~~|~~~||^+???>^^++||^|~~```~^.    .`~+>?|  ...   .... .. <br></br>
-uuuuuuuuuuuuuyyyw??vv???vvv}}rrrrww}}>...   .. .~>vwxuuu?.  ...  ...... . <br></br>
-uuuuuuuuuuuuuuuz}vvvv}}wwwrr}rrrwwwzx>       .?zyyyyyux>. ............... <br></br>
-uuuux}wxuuuxzwrvvvvvv}zuyyyuuzrrwwwwz~      ~zyuuuuyuz^   ............... <br></br>
`zxuuwvvv}}}vvvvvvvvvv}zuuuuuyyxzzzzzw`     `zuuuuxx}v- .. ........ .....`.<br></br>
`vv}}vvvvvvvvvvvvvvvvv}rxuuuuuuuyyuuuz.     ?uxuxxv~   ..................`.<br></br>
`vvvvvvvv}rwwzxzzxuxzr}rxuuuuuuuuuyuuu` .. `uyuxz>.   .... ....... ......`.<br></br>
`vvvvvvv}zuuuuuuuuuuuz}}wxuuuuuuuxxxuy+  . ?yuxr^ ....... ...`.............<br></br>
`vvvrzxxxxxuuuuuuuuuxr}}}rwzzzzzwwzzxu>   ~zxz>. ...........`...........`. <br></br>


            </div>,

            <div>

`rrrrrrrrwr???????????????????????rrrrrv?}rrrrrrrrrrrwrrv~>????|.?rrrrrrrw~<br></br>
-uuuuuyyyyyxvvvvvvvvvvvvvvvvvvvvvvrxyyu}}uuuuuuuuuuuxwr}vvv??v>^+xyuuuuuyy^<br></br>
-uuuuuuuyyyyxvvvvvvvvvvvvvv}wwwr}vvvwzrv}xuuuuuuuuwvvvvvvv?+?>+vuuuuuuuuuy^<br></br>
-uuuuuuyyyyyu}vvvvvvvvvv}wwwwwwwwwv?vvvvvv}rwuv>????vvvvv?~>?>xuuuuuuuuuuy^<br></br>
-uuuuuyyyyyu}vvvvvvvvvvwwwwwzzzxzzzvvvvvvvv??v>+++^|^>???+^~+uuuuuuuuuuuyy^<br></br>
-uyuyyyyyyyzvvvvvvvvvvwwvrw}vvvvwwwzvvvv>++++++++^|^|~^+|~|+zuuuuuyuuuuuyy^<br></br>
-uyyyyyyyyuvvvvvvvvvvrrvvvvvvvvvvrw}}??^+++++++++^|~~~-^|`?xuuuuuuyyuuuyyy^<br></br>
-uuuuyyyyywvvvvvvvvv?w}vvvv????vvvr?}?|++++++++|~~~~~~-`>ruuuuuuyyyyyyyyyy^<br></br>
-uuuuyyyuwvvvvvvvvvv?r}vvv>|-~+?+?v??+-|++++++|~~~~~~-~-ryuuuuuuyyyyyyyyyy^<br></br>
-uuuwzzwvvvvvvvvvvvvvvvvvv>`.`+v^+?>|^^~^++++^~~~~~~----?yuuuuuuyyyyyyuuyy^<br></br>
-uyu}vvvvvvvvvvvvvvvv?vvvv?~~^>>v??>~||~+++++++|~~~~~-`->yuuuuuuyyyyuuuuuy^<br></br>
-uyyu}vvvvvvvvvvvvvvvvv?>??^~~.`+>>vv^~~+++++++|~~~~~-`}xuuuuuyyyyyyuuuuuy^<br></br>
-uyyyzvvvvvvvvvvvvvvvvvv???>-~->>~?^??~^+++++||~~~~~~`^uuuuuuyyyuuuuuuuuuy^<br></br>
-uyyyzvvvvvvvvvvvv?>>+++>>>^~|||^+?+~^>+++++|~~~~~~~-^zuuuuuyyuuuuuuuuuuyy^<br></br>
-uyux}vvvvvvvvvv?++++++^++++^-^^^++^~~|++^+^~~~~~--^?zuuuuuuuuxuuuuuuuuuyy^<br></br>
-uyzvvvvvvvvvvv>+++++++++|^^+^-^+^^|++``|~^|~~~~--vwxzzzxxxxxxxuuuuuuuuuyy^<br></br>
-uxvvvvvvvvvv>>++++++++++^^^^+|-~^|^+^~`-`~~~~~~-~??uxxxzzxxuuuuuuuuuuuyyy^<br></br>
-uuxuxzxwv?v>^|++++++++++^++++++~--~|^|```~~~~~~``^vuuuuxxxxuuuuuuuyyyyyyx|<br></br>
`vvv}wx}vvv?^||++++^|||||^+^||~|||```~|~``-~~~~--||}xuyyuuuuuuuyyuzwrw}v+. <br></br>
`vvv??vvvvv?~~-++^~~~~~~~|+^~~~~-~~-`.`~-`~~~~-`~~. .-~^>?v}zuwv+`.        <br></br>
`vvv???????>-~~||~~~~~~~~~~~~~~~~~-~~--``-~---~~-.          .`.            <br></br>
.|+>?>+|^+^^|`~~~~--~~~~~~~~~~~~~~~~~~~~~~~-``-.                           <br></br>
`>>?>>>~-^^~~~``---``-~~~~---------~~~~~-~~`--`              .. .. ....... <br></br>
-uuuuuuz}v??>???>|--|-``-~|^??>^|~|^~~|~``...-|      .~^>?v}v|....  .... . <br></br>
-uuuuuuuuuuuuuuyyuzvv?????vv}}}}}}rw}}>      .  .->vwxuyyyyr`  ...  .....  <br></br>
-uuuuuuuuuuuuuuuyyurvvvvvvv}vv}}}rrwwx>       .>zyyyyuuuuyv.  .... ....... <br></br>
`zuuuuuxzuuuuuuuuz}vvvvvvrxuxxzwrrrwww~      ~wyuuuuuyyyy?   ............. <br></br>
`vwuuuyzv}wzzzr}vvvvvvvv}xuuyyyyxwrwww.     .zuxuuuuuyyx^  . ............. <br></br>
`vv}wxz}vvvvvvvvvvvvvvvv}zuuuuuuyuxxxz.     ?uxxxuuyx?~` ........... ..... <br></br>
`vvvvvvvvvvvvvv}}}}rwr}vvruuuuuuuuuyyu`    .zuuuuuyv-   ............ ..... <br></br>
`vvvvvvvvvvrxuuuuuuyyyur}rxuuuuuuuuuuu^    ?yyyyyu?... ....  ...........`. <br></br>
`vvvvvv}rrwxuuuuuuuuuyuw}}wzxxxxxxzwwx>   -zxxxu}^... .... ...`........... <br></br>


            </div>,

            <div>

`vrrvvvvvvvv????vrrrv?>>>>>>>>???vvvvvvvvvvvv??}vvrrrrrrrv}}}v|~~^++++++++.<br></br>
`zyxzwwwwwrvvvvzuxw}vvvvvv???vvvvv}rwwzwwwwwwr}wuxuyuuuuuxxxu?|^^>>>>>>>>>`<br></br>
`uxwwwwr}}}}rxwv}vv?vvvv?vvvvvvvvvvvvv}rwwwwwrv}}xwxxxxxxxxzz^^+>>>>>>>>>>`<br></br>
-uzwww}}}}}}vvrv?vvvvvvvvv}}}}}vvv??vvvvv}rwwr???vrrxxxxxxwz}~>>>>>>>>>>>>`<br></br>
-xwww}}}}}}vvvvvvvvvvvvvvv}vvv?????>??vvvvvv}}>++^?vwzzxx}^}v^>>>>>>>>>>>>`<br></br>
-zwwr}}}}}}vvvvvvvvvvvv}vv??>>+>???>+>?v??>>>?+>>+>v}wzzv||z}^+>>>>>>>>>>>`<br></br>
-zww}}}}}}}v?vvvvvvv?v}v?>>>>>>>?vv.`?||+>>++>>>++^vvrr>~|>ur^+++++>>>>>>>`<br></br>
-zwrv}}}}}vv?vvvvvvv?vv>+>>>>>>+?vw}>?~|~^--`~+++||>v}+|^+?uz+++++>>>>>>>>`<br></br>
-zwrv}}}}vvvvvvvvvvvvv>+>>+||^++???zw?|???^`.``~^+^^v?^^>>?uu?+>>>>>>>>>>>`<br></br>
-zwrvvvvvvvvvvvvvvvvv>>>>>+|~|^^>~ -??>>???>~``-|+++v^^^+>>}v?>>>>>>>>>>>>`<br></br>
`xwrvvvvvvvvvvvvvvvvv?+>???>>?>+?>++>?>????>?^``^~|>>^^^+>>++>>>>>>>>>>>>>`<br></br>
`zxw}vvvvvvvvvvvvvvvvv>>|`?}v``?^^?vv>~|>??>^?>~^``-|^^>>>>>+++++>>>>>>>>>`<br></br>
-zxzrvvvvvvvvvvvvvvvvv?++^>v}|.?||?v??+|~+???>>>|~>>>^|+++>>+^++>>>>>>>>>>`<br></br>
-uzzz}vvvvvvvvvvvv???>>+~~|-~+>^>????>?>++??>>>>>++>>?}?>++>>++>>>>>>>>>>>`<br></br>
-uyzzwvvvvvvvvvv?>>>>>>+``--`~+^+^+>+>vrv+>?>>>>>>++v}rrr?++>>>>>>>>>>>>++.<br></br>
-uyuzww}vvvvvvv?>+>>>>>|``----^^|^^+vx}>` `+>>>>>>v?v}}}}r}?^^+>>>>>>>>>+^.<br></br>
-uuux}vrw}v?v??>>>>>>>>|```~`--`-^|vz?-  . .-+>>>>?>v}}}}}}}}+^^++>>>>>>^+.<br></br>
-uuuxwwr}r}v>>+>>>>>>>>+``.|-`-`-|~?^......`--|>>>>?v}}r}}}}rv^^+++>>>>>+^.<br></br>
-uuxxuuuxr}v??>>>>>+++++~~~`.````-~- ......|^^--^>>vv}}r}}}}}r?^++^+>>++~` <br></br>
`xxxzxxuuuxr>^^>>++|^|^^^^++~``````-. .|^~-^++^`~?+^^?+???vv}}}^--``~~~~`. <br></br>
-uuuxzxxxxxxr>++^|^|~||^^^^|-`````````+|++++^^^|vr>~`>^-++++>>>-.```-~~~`. <br></br>
.???????v}wzzwrv?>>>+++++^^~.`````````^++++++>?rv?+~`>>`|>+++++`..`--~~~`. <br></br>
.^^^^^^|~|^+>v}rr}rrv?>+^^^~```````````~+++?vr?^-|+~`+>~`++++>+````~||||-` <br></br>
.>>>>>>>>+++^^+?vwww}+^^^^~~|~||~---`````?vv>~|``~``->?>|>vvv?+^?~`-||||-` <br></br>
.>>>>>>>>>>>>>>>>?}v>+>>+^^^^|^>>>>>+^~...-..````-|>v}rv??vv?+>>?+.-||||`` <br></br>
.>>>>>>>>>>>>>>>>>>>>>>++^^^^^~+>>>>>>^```.``.`^>?}rrrrv??>>+>>>>>`-|||~`` <br></br>
.>>++++++++++>>>>>>++^^^^^^+^^^|>>>>>>~``````~>?vrrrrrrv?+-|>>?>>>`~|||-`~.<br></br>
.^^++++^^^^^^^^^+^^^^^^^^^+++++^^>>>>>`````.`>>>?vrrrrr?|-~>>?>>??~~|||``|.<br></br>
.^^^^+^^^^^^^^^||^++^|||^^++++++^+?>>+````-`|>>+>?vrrv?|``+?>?>???^~||~`--.<br></br>
.^^^^^^^^^^^^^^^+>>+^^^^|^^^+++++^+>>>~`````+>>>?>??>>>---+?>?>???|-||~```.<br></br>
.^^^^^^^^^^^^++>>++++++^^^+^^^++++++++-....~>>>???^------~+>>?+??>+-|^~```.<br></br>
.^^^^^^^^^^+++>>+++++++^^^^^^^^^^^^^^^-...`^>+>>+~````-~--+>>?>>^+|`~|-``` <br></br>


            </div>,

            <div>

`vrrvvvvvvvv????vrrrv??>?????????vvvvvvvvvvvv??vvvrrrrrrrrrrrv|||^^^^^^^^|.<br></br>
`wyzwwwwwrvvvvvzyuz}vvvvvvvvvvvvvvv}rwwwwwwww}v}uzxyyyyuuuuuyv++++++++++++.<br></br>
`uxwwwwrvvvv}xzvrvvvvvvvvvvvvvvvvvvvvv}rwwwww}vvvxwuuuuuuuuux+++++++++++++.<br></br>
-uwwwrvvvvvvvvrvvvvvvvvvvvvvvvvv???vvvvvv}rww}vv?vwwuuuuuuuur|++++++++++++.<br></br>
-zwwwvvvvvvvvvvvvvvvvvvvvvvv??>++++>?vvvvvv}r}vvv?}ruuuuuz>z}~^+++++++++++.<br></br>
-www}vvvvvvvvvvvvvvvvvvvv?>++^|^^^+^^+?vvvvvvvvvvvvwzuuu}++uv-|^^+++++++++.<br></br>
`wwrvvvvvvvvvvvvvvvvvvvv>+++++|^+??..>^+?vv??vvvv??wruu?^+?y}-~~~||^++++++.<br></br>
-ww}vvvvvvvvvvvvvvvvvv?^|^++++^|>vww+?^>+>^^|+?vv??v}z?^++?yz~~~~|^+++++++.<br></br>
-zw}vvvvvvvvvvvvvvvvv?^^^+^^+++^???uzv?vvv>|~|^?vv??wv^++++uu+~|++++++++++.<br></br>
-zw}vvvvvvvvvvvvvvvvv+^^++++^^|~?~ -?}vvvvv?+|^+?vv?w>++++^vv>+++++++++++^.<br></br>
`xwrvvvvvvvvvvvvvvvvv?^>>?????>>?>++?vvvvvv?v>|^?>???++++++||^^++++++++++^.<br></br>
`wzwvvvvvvvvvvvvvvvvvv>?|`?wr--v||?vv?>>?vvv>v?+?|+^^++++++^~~~~~^+++++++^.<br></br>
-wzwrvvvvvvvvvvvvvvvvvv^^^>}w^.?-^?v?v?+^?vvvvvv>>vvv>|~||^+|-~~^++++++++^.<br></br>
-uwzw}vvvvvvvvvvvvvvvvv?+++^+>>^??vv??v????vvvvvv??vvvv>~~~^+~|+++++++++^^.<br></br>
-uuwzw}vvvvvvvvvvvvvvvv?^^++++^|+^+?>?wxr??vvvvvvv??vvvvv>~-^^++++++++++|~.<br></br>
-uuyzwwrvvvvvvvvvvvvvvv>||^++++|~^^+vyz?`.-?vvvvvvv?vvvvvvv>~-~^++++++++~~.<br></br>
-uuyyxrrw}vvvvvvvvvvvvv?~|~+^^+~-||vuv-  . .^?vvvvv>?vvvvvvvv~-~~|^+++++--.<br></br>
-uuuyuwvvrrv?vvvvvvvvvv?|^->^~++^^~?^  ....|++>vvv???vvvvvvvv?-~~~|+++++--.<br></br>
-uuuuuxxxw}v?}vvvvvvvvvv>>+~~^+++++~   .`.`>??++?vv?>v?vvvvvvv?-~~~^+++^-~.<br></br>
-uuuuuuuyyuwv?vvvvv????vv???>^++++++- .|>+^????+^vv?>v?vvvvvvvv|~|~|++++-~.<br></br>
-uyuuuuuuuyyyx}?????>???vvv?+^^++++++~?>???????>}uv>^v?>vvvvvvv+`|^|^+++~~.<br></br>
.????vvvrwxuyyuzrv????????>~~^|^+++++^>???????}uz}v?^vv+?vvvvvv>--^+^+++~~.<br></br>
.^++++++++>>?rxyyyzzxzv?++^~`|+^++^^++^+???}rxr?>?v?+vv>+vvvvv?^^`~+++++~~.<br></br>
.++++++++++++++?ruyyzv+++^|---+++^||^^^^|vzw?^+|+>+^>vv?^?vvvv??v^-|++++|~.<br></br>
.++++++++++++++++>}v+++++|~~~-~+++++^|||~|>^|^||^^^>?vv?^+?vv?vvv?-|++++~~.<br></br>
.++++++++++++++++++++++^|~~~~~-^++++++^^+^^|^^^++>?vvvv?^+>??vvvvv~|+++^~~.<br></br>
.^^||||||||||^^++++^||~~~~~~~~~-^+++++++++++++++?vvvvvv>^+^>vvvv?v~^+++|~^.<br></br>
.~~~~~~~~~~~~~~~|~~~~~~~~~~~~~~--+++++++++^~|^^^+?vvvvv>^+>vvvv?vv+^+++~~^.<br></br>
.~~~~~~~~~~~~~~--|^|~---~~~~~~~~`~+^||^^^^^^^^+~|+?vvvv+^|?vvv?vvv>|++^~||.<br></br>
.~~~~~~~~~~~~~~~^++|~~~~~~---~~~~~~||^^^|||~~+^|^^^>??v^~^?vvv?vvv+|++^~~~.<br></br>
                  .~~~~~~~~~~~~~~++|~~~~~~~~~~--~~~~~~~~~~~~~~^+|+++^|^^+^|+?vvv?vv??~++|~~~.<br></br>
.~~~~~~~~~~~~~^^~~~~~~~~~~~~~--------------~^~|||^^^^^^^||?vvv??>?>`^+|-~-.<br></br>


            </div>,

            <div>

`????????????+++>>??>+++++++^+++>>>>????`.....??++>?????>>>>>+``^||^||||||.<br></br>
`v}}}}}}}}v?????vv??????????>???????v}r>..````}v??vwwwwww}v??^`-|+^+++++++.<br></br>
`}}}}}}}v?>>????????????????>>??????v?}}+`...-}?>?}wwwwwwwrv?-`--^^+++++++.<br></br>
`}}}}}vv?>>>>>?>>????????????>??>>>?vvvzxr>-.|r>>v}rwwwwwwrwr+``-^+^++++++.<br></br>
`}}}}v?>>??>>???>>>????????>>>+++++??vwrrzxw??}>?vv}rwwwwr?rr??^-+>|^^^^^^.<br></br>
`}}}v?>>????>?????>>>?????>+++^+++>>>?zzwrrzzvv?vvv}rwwwv?>v}???^+^^++++^^.<br></br>
`}}}?>>??????????????>>>>+++++^+>>?~-.|vzzwrvvv?vvv}}wwv>>?v?+?+|^^^^+++++.<br></br>
`}}}?>????????????????>^^^+++++^>v}v`. .^}ww?v??vvvv}r?>>>?wv-~|^^+>+^++++.<br></br>
`}}vv????????????????>+^^+^++++^????`.....+}?v>?vvvvrv>>>>?wz>|+>>>>>>++++.<br></br>
`}}vv?>??>???????????>+++>>>++|^?|`-`..... .>v^+??vvr?>>>>>vv>>>>>>>>>>>^~.<br></br>
`}}vvv???>??????vvvvv?>???????+>??>???>????>}v^+>>??>>>>>>>++>>>>>>>>>>>>+.<br></br>
`v}}vv???>?+...``+wr}}v>^~?v?|^?>??>>>>>????>?>+>^+^~|>>>>>>^^^^+>>>>>>>>>`<br></br>
`vv}vvv??>?>.... `wwrzz- ..``..>?^>???>++>??????>+??>^--~|+>+^^++>>>>>>>>>`<br></br>
`}vv}vvv?>>?......vz}wz^ ......>?>??>>>>?vvvvv?>>+>>>??>^|^>>++>>>>>>>>>>>.<br></br>
`}}v}}vvv>>v?>|`. >xrwz? .... ~v+^+++>?vv?>+?vv???????vvv?+^+>>>>>>>>>>>++.<br></br>
`}}}}vvvvv>>???v?>>}}}zv  .. .??|^+>?r}v>-.>vvvvvvvvvv??????+^++>>>>>>>>+^.<br></br>
`}}}}v??v??>>>>>????vvvv`    +v||^>}wv>+>^|>??v?vvvvvvvvvvv?v+^^^+>>>>>>^^.<br></br>
`}}}}v?>???>>?????>>????>>^~~v>^^+>?^`~>>^>?>+?rzxzwwzwr}vvvvv+^^^^>>>>>^+.<br></br>
`}}}}v??????>????????>>>^+>>>?++^+^`.-|>>>>+?rwrrzxzwzzxx}+>vv?^^^^>>>>>^+.<br></br>
`}}}}v?v?v??++>????>>>>??>>>+++++++>>^+??>>}zxzwwwwzzxv>|-`-~+?>^+^+>>>>^^.<br></br>
`}}}}}????????>>>>>>>>>>>>>>+^++++>?>^???vzxzzzzuuzrv>`.-~~~~-~--^++>>>>^+.<br></br>
.??????>?????v?>?>>>>++>+^|-|+^^^+++>?>vwzxxzzzuuuw+.``.-~~~~||-.~?++>>>^^.<br></br>
.++++^^^++^|^>??vv????>|-``.`^^|^++>?>vzzwwxuuxz}>`..-~``~~|+>>+^->v>>>>+^.<br></br>
.++++++++^+^|`-~+?vv?+-````..~^^^+??^vrwrxxzzzr>`...`~~-`~+???>>?|`|vv?>^^.<br></br>
.++++++++++^^^|~-|>>-`````````|+++?>^?}vrrww}?-....`~~~-~+>??>???>``~>}v>^.<br></br>
.++++^^+++++++^++^|++|```.```.`^+++++>r}vrw?-....`-~~~~~+++>??????-`--+?vv`<br></br>
.+||||~|||^^^^+^|^||^^~...`````-+++++^?wr?|....`-~~~~~~^^++>??????+||-`~?v`<br></br>
.||-~|~~|||||||~^+^-````...````.~>++++^}v`......`-~^+++^^>???????v>^>^-``?-<br></br>
 -|~-||-~||||||~^+++-......````.`|++^|~-.........`^???>^^>????????>^>+~``-`<br></br>
.~~~-~|~~|||||~|++++^`.``..`..``.``````.... ...`~|^+>>>^^??????????+>>|``|`<br></br>
.~~~~~~|-~~~~~~^++^^^|`.````..````........ ...`^+++^+++^+??????????+>>|`+v-<br></br>
.^||||||||||^^++^^^||^|`.````...........`. ...|^^^++>>>+>>?????????++>|>vv-<br></br>


            </div>,

            <div>

.>+^^^^|^^^^^||||||||||||||||~|||||||+?>+?vv?-     .?|>????>>>>??????vvvv+.<br></br>
.>>>>>++>>++++++++++++++++++++^+++++>?v??vzzzwv`   ~v+vvvvvvvvvvvvvvvwwwwr-<br></br>
.+>>>>+>>>+^^+++++++++++++++++^+++++>vvv}r}rwxyx?` ^v+?vvvvvvvvvvvvv?vwwrw~<br></br>
.>>>>++>++++++^^^+++++++++++++++++++?v?wxyxr}wuuux>?v^vvvvvvvvvvvvvvv??}ww~<br></br>
.>>>>+++++++++++^^^++++++++++++>+++>vv}??uyyxzzzuyzvv+vvvvvvvvvvvvvv?????}`<br></br>
.>>>>+++++++++++++^^^++++++++++>>++?v?w` -vuyuuzwxrv?>vvvvvvvv}vvvv???>+>>`<br></br>
.>>>>++++++++++++++++^^+++++++++?>?v??+....~}yyuuzv}?>>????vvvvvvvvv???>>?-<br></br>
.>>>+++++++++++++++++++^^+++++++>?vv>?. .....^ruuuv}>>>>>?vvvvvvvvvvvv????`<br></br>
.>>>+++++++++++++++++++++^+++^++>???>~    ... .+zyv}>>>>>???vvvvvvvvvvv???`<br></br>
.>>???>>>>?????>>>>>>>+++++>>^|+??>+>^`      .  .>v}>>>>>>>>>vvvvvvvvvvvv?`<br></br>
`v???v????vvvv????????????????+???vvv?v???>>>>^||~vv+>>>>>>>>?vvvvvvvvvvvv`<br></br>
`v| .....``-v}vvv}w}?vvv???vvv?>vv}rv++>>>?????vvvv?+>>>>>>>>>?vvvvvvvvvvv`<br></br>
`v^         }xz}wxyu-.....`-~~+?v}v>^+++++++^^^++^^+++++>>>>>>>?vvvvvvvvvv-<br></br>
.?>         ?xzr}xuy^..        >r?^+^++++>>????vv??>+++>>>>>>>>>>???vvvvvv-<br></br>
 >v>~.      +uxz}zxy?...       ?r++++^^^^+>?vvvvvvvv?+++++^^^+>>??????vvvv-<br></br>
.+?v}}?^`.  `uuurxxyv ..      ~w>++++^^+>++?vv????vvv?>^||^+>>?vvvvvvvvvvv-<br></br>
.+>+>>?v}v>~.}xurxuuv ..     .vv^+++>>>>??vvv+.`+?vvvvvvvvvvvvv?>>>>?vvvvv-<br></br>
.+>>>+^^+>?vvvv}vrxur. ....  >w++^+>???vvvvv?.~>v}}}}vv?v??vvvvvvv???>??vv-<br></br>
.>+>>+++++^^+??vvvvv}.      `r?^++^>vvvvvvvvv>v}}}}v??vrww}rwwrvvvvvvvv??v-<br></br>
.>++>>++++++++++>?vv}?^~`.  ?v|+^^?vvv???vvvvv}}}v>>vxuuxxzzwwzuxrvvvvvvvv-<br></br>
.+>+>>+++++++++++^^+>?v}vv?>}>++|+v?^`-^>vvv?v}v?>vwzxxzzzzzxzrrxyyw>|?vvv-<br></br>
.>>>+>>+>++++++++++++^+>?vvvv?>+|+?|.~>?v}}}}}?>?xyuzr}zzzzzxxux}?^....`|?`<br></br>
.+>>>+>>>>>++++++++++++>>???v?++|^+???v}}v}}v??}uuxzzzzrwzzxux}+` ........ <br></br>
.>>+>>+>>>>>>++++++++++++++>>++++++?vvv??v}?>vuurrzxzxxxwrxxv~.  ....`...`.<br></br>
`v??++^+>>>>>>>>+++++++++++++++++^>?>>>?vv??zuxzzwrzxxxxuz?`  .......`~|^^.<br></br>
.?vvv?>++>>>>>>>>>>>>>++++++++++^|^+^+?v??wuwzzzzxzrwxxxv-  ........`~+>>>.<br></br>
.???vvv?>>>>>>>>^|^>>>>++++++++^^|^+^>??vxxzw}wzxxxxwrv-  ........`|++++>>`<br></br>
.vvv???v?+>>>>>+|~|^+++++++++++^|^+^?>>wuxzzzz}rxzxxr|  ......`.`~+>>++++>`<br></br>
.vvvv???v?+>>>+|~~~~^++++++++++||++>v+zurwzzzzzrrxw>.  ......`.-^>>>>+++^^.<br></br>
.vvv??vvvv??v??>---~|^+++++++++^^++^v+vuxrrzzzzxw>` .......``.-+>>>>>>++^+.<br></br>
.?vv?vvvvvv?????>``-||^+++++++++^++^>>^wxxrrzzx}~  ......`````~+>>>>>>>+??-<br></br>
.???>???????>????+``-~~++++++^^^^^^^|>^>zzz}}z?. .............~++++++>>?vv`<br></br>


            </div>,

            <div>

.+^~|^|~~||||||||||||||||||||~|||||||+?>?v}w}>|||~. . |v>????????????v???>.<br></br>
.++++>+++^^++++++++++++++++++++++++++?v?rzuuuur>^^^^| >r?vvvvvvvvvvvvrrrrr-<br></br>
.+++++^+++++^^++++++++++++++++++++++>vv?wxuuuuyur>^vz^?v?vvvvvvvvvvvv}rrrr~<br></br>
.+^+++^+++++++^^^++++++++++++++>>+++?v?}rryuuuuuyuvrzvvv?vvvvvvvvvvvvvv}rr~<br></br>
.+^++^+++++++++++^^++++++++++++>>+++vv?v^|?zyuuuuuxzw?v??vvvv?vvvvvvv??vv}-<br></br>
.++++++++++++++++++^^++++++++++>>>>>v?v| .-+vxyuuuzwr?v???????vvvvvvvvv??v`<br></br>
.++++++++++++++++++++^^+++++++++>??vv??.   .~+}uyuzxv+?>>>>???vvvvvvvvvvvv-<br></br>
.++++++++++++++++++++++^^+++++++>??v?v~      .~?wyzxv>?++>????vvvvvvvvvvvv-<br></br>
.++>>>>>++++++++++++++++^^++++++>????>.        `|?}uv>?++>?????vvvvvvvvvvv-<br></br>
.???????????????>>>>>>+++++>>^|+??>+??|.         .`>>?>^++++>???vvvvvvvvvv-<br></br>
.vvvvvvvvvvvv?????????????>>??+?v??vvvv???>>>++^^|+^^?+^+++++>???vvvvvvvvv-<br></br>
 `.....``|+>wzwrwr}}?vvvvvvvv?>>?v}wr?>>>???vvvvvv}v>?++++++++>????vvvvvvv-<br></br>
 `.      `++zuuuuyx>`.``-~|^++???r}?+++++++^^^^++++^+++++++++++>????vvvvvv-<br></br>
 `.      `+^ryuuuyx+.         .?}?++^++++++>?????????>>+++++++++++>????vvv-<br></br>
 .`..    .+^vyxuuyu>`          ^w>+++^^||^>?vvvvvvvvv???+^^^^^+++>>>>>????`<br></br>
.^|~-``...^^?uxuxyu>-         .vv^+++^||^+>??v??vvvvv???>||||^+>>?????????`<br></br>
.+??>+^~--}v?xuyuuu>~         +w>^++^^++>???v>~||~^??vvvv????????????vvvvv-<br></br>
.^+++>>?>>vrwxwxzxy?|        `r?^^^^>?????vvv|^~`~>?vvvvvvvvvvvvvvv????vvv-<br></br>
.>+++++++>>>??v}zxu?~.       ?r++^^>??vvvvvvv??^^??v??vv}}}}r}vvvvvvvvvv?v-<br></br>
.>+++++++++++>>???}?``...   |w>+^|>?vvvvvvvvvvv?????vwwrrzxxzxuuxzzwrr}vvv-<br></br>
.++++++++++++++++>>??+|~-`.`}v^+|+vv?^+>?vvv?vvv??v}r}rzxuuyyuuuuyuzvvrzzz~<br></br>
.+++++++++++++++++++>>???>+?w?>^|>v?~--|>??vvv?+vrzw}rxxuyuuyyyyxwv>+++?vv`<br></br>
.+++++++++++++++++>>>>??vv}rv?+^|>??+`-+?vvvv??vzz}ruyuuuuuyyyxv?>>>??>~.  <br></br>
.++^+++++++++++++++>>>???????>++^>??>^>??vv??vwzrvruyuyyuxuuwv?>>>??+-.  . <br></br>
.??>++^+++++++++++++++++>>???>++^|+?????vv??rzzrvrxuyyuyyur?+>????+-. .... <br></br>
.>???>++++++++++++++++++++++>+++^||^>????>vwww}vzyuxxuyyw?>>>>>>+`. ..```` <br></br>
`??>????>+++++++++++++++++++++++^||^>?>+?}zzrvvxyuuyuxwv>>>>?>^.  ..``````.<br></br>
`vv?>>???++++++++++++++++++++++^|^^^>++?}zw}}rzuyuyyxv>+>>?>|`  ..````---` <br></br>
.????>???>+++++++++++++++++++++|~^^+?+vr}}vrxyuxuyu}?>>>>>^.  ..```------- <br></br>
.?vv?>????>????>^^+++++++++++++^^^^^?+?r}vwyyuyyx}?>>>>?^.  ...`----------.<br></br>
.vvv???????>????>||^++++++++++++|^^^>?+vxuxuyyyx?++>>>^`  .....`--------~~.<br></br>
.vvv?v?vvvv??v???>||+++++++++^^^^^^^>?++uuxwxxr>>>+++-.  ......`------~~||.<br></br>


            </div>,

            <div>

.^~~|||~~||||||||||||||||||||||^|||||+??>vv}rrwrwrv?-  `>>>???????????????`<br></br>
.++++++++++++++++++++++++++++++>+++++?v?vwzyuxuyyyuzr?~~v??vvvvvvvvvvvvvvv-<br></br>
.^++++++++++^^+++++++++++++++++>>+++>vv?}zuyuuxxuuyyuxx????vvvvvvvvvvvvvvv-<br></br>
.+^+++++++++++^^++++++++++++++++?+++?vv?w+}yyuuuuxuyyuuv?>?vvvv?vvvvvvvvvv-<br></br>
.+^+++++++++++++^^^+++++++++++++>?++?v?}?  +wyyuuuxxuux??>???>>?vvvvvvvvvv-<br></br>
.+++++++++++++++++^^^+++++++++++>??>vv?w~   .>xyyuuuxww??^++++>?vvvvvvvvvv-<br></br>
.+++++++++++++++++++^^+++++++++>+>??v?vv      `?xyyyuxr??^+++>>>vvvvvvvvvv-<br></br>
.+++++++++++++++++++++^++++++++>?>??v?r~        `vuyux}??++++>???vvvvvvvvv-<br></br>
.????????>>>>+++++++++++^+++^+++?????v?.          -vuuv?>+++++>>>?vvvvvvvv-<br></br>
.?vv??vvvvvvvv????>>>>+++++>+||+>???>?v>`           -??v++++++++>>?vvvvvvv-<br></br>
`vvvvvvvvvv????????vv?????>>>>+?v??vvvvvv?>>>+^|||~~~|?v^++++++++>>?vvvvvv-<br></br>
 ..```-~>wzwwrrr}}}vvvvvvvv???>??vv}rr?+>???vvvvvv}}}rvv^+++++++++>>?vvvvv-<br></br>
        |xyyuxuuux^`~|^>>??vvvv?v}rv?++++++^^^^^^^^^^^+++++++++++++>>?vvvv-<br></br>
        ~zuyuxuyyy~        ..`>}vv>+^++++++++???>>?????>++++++++++++++>??v-<br></br>
        ~wuyuxuyyy^           .>rv++++++|~|>??vvvvvvvvvv??>+++++++^^^++++>`<br></br>
.`.     `ruuuzuyuy+            >z?++++|~~^+>>?vvvvvvvvvvv?>||~|||~~|||^+++.<br></br>
`v?>|`. .}uyyzuyuy>           `rr^++^|~^+++?vvvv?~--|?vvvv?+^^^+>>????????`<br></br>
.+>?vv?>~rxuyxuuuy?           ?z>^+^|^^++>?vvvv>.  .~>?vvvvvvvvvvvvvvvvvvv-<br></br>
.++++>?vvv}rzrzuyyv.         ^zv^^+^+>???vvvvvv+..~+?vvvvvvvvv?vvvvvvvvvvv-<br></br>
.++++++++>?vvvvrzx}         .}w++^^>?vvvvvvvvvvv^^?vvvv????v}}rwwzxxxxzr}v-<br></br>
.+++++++++++>??vvvv~..      ?z?^^+?vvvvvvvvvvvvvv?vvv??>?vrxuuyyyuuxxuyyyy|<br></br>
.+++++++++++++^+>?v}v?^-.  ~wv^|+?vvv?||+>?vvv???vvv??>?}zyyyuyyyyyyuxxxuu|<br></br>
.+++++++++++++++++>??}rrv??}w?>|^?vv?. .~>?vvv???vv???wxxxuuyuyyyyyyyyuzrv`<br></br>
.+++++++++++++>???vvvvvv}rwz}?+|^?vv~ .|?vvvvvvvv????xyyyyuxuyyuyyyyuzwv>` <br></br>
.++++++++++++++++>>???????vvv>++^?vv+`^?vvvvvvvv???vuyuuyyyuxxuyyyuzrv^.   <br></br>
.^++++++++++++++++++++>>?????+++^^+>?vvvvv??vvv???ruzxyyyyyyyuxxxz}?-.     <br></br>
.>+++++++++++++++++++++++>>?>+++++|^?v??>>>?v????wyyuzzuyyyyyyuw?+`        <br></br>
`v?>++++++++++++++++++++++++++++++|^??+++>?v???vzyyuyyxzxyyuxwv^.        . <br></br>
.?>++++++++++++++++++++++++++++++^||++++>?????}xyyyyyyyuzzxwv|.       .... <br></br>
.>>>>+++++++++++++++++++++++++++^||^+++>????vxyuxuyyyyyyur?|.      ....... <br></br>
.vvv?>+>+++++++++++++++++++++++^^||^+>?????zyyyyuxuyyyuz?~        ........ <br></br>
.vvv?>+>>>>>>>>++++++++++++++^^^|~|^>??^?vrxuxxxuxwxxwv|         ......... <br></br>


            </div>,

            <div>

.|||||||~~|||||||||||||||||||||+|||||^??>?vvr}}rrrrr?|` .>?>??????????????`<br></br>
.+++++++++^^+++++++++++++++++++?>++++?vv?rzxyuuuuyyyyz????v?vvvvvvvvvvvvvv-<br></br>
.+++++++++++^^+++++++++++++++++>?++++vv?vzzyyuuuuxuuyyyzw>v?vvvv?vvvvvvvvv-<br></br>
.+++++++++++++^^++++++++++++++++?>++>vv?r?^}uyyuuuuxuuyz}>v?vv???vvvvvvvvv-<br></br>
.+++++++++++++++^^^+++++++++++++>?++?v?v}` .+wyyyyuuxxxzv??>?>+>??vvvvvvvv-<br></br>
.+++++++++++++++++^^^+++++++++>++??>?v?}?    `?zyyyuuuzrv??++++>??vvvvvvvv-<br></br>
.+++++++++++++++++++^^++++++++>?>>v?vv?r-      `?xyyuyxw??>+++++??vvvvvvvv-<br></br>
.>>>>>>+++++++++++++++++>++++++>???vv?}?.        -?xyyxw??>+++++>??vvvvvvv-<br></br>
.vvvvv??????>>>>+++++++++>>+^+++??????v-          .~vxxw??>++++++>??vvvvvv-<br></br>
.?vvvvvvvvvvvvvv????>>>+++>?+|^+>?v?>>v?~            ~>v??++++++++>??vvvvv-<br></br>
`vvvvvvvvv??????vvvvvvv???>>?>+?vv??vvvvv?>++^|~~--```->??+++++++++>??vvvv-<br></br>
 ```-~|>vzwwr}r}}}vvvvvvvvv??????vvvrwv>>???vvv}}}}}rrrr??^+++++++++>??vvv-<br></br>
       -vyyuxuuux?~~^+>>???vvv?>v}rr?>+++++^^^^^^^^^^^^++++++++++++++>??vv-<br></br>
       -vyyuxuyyu>.      ..`-~?vvr?+^++++++++>?>>+>?????>+++++++++++++>>?v-<br></br>
       `vyyuxuuyy?.           .?}?^+++++^^^+???vvvvvvvvvvv?>++++++++++++++.<br></br>
       `?yyuxuyyyv.            >zv++++^||^+>>?vvvvvvvvvvvv??^|||||~~~~||^+.<br></br>
.~`..  `>uyuxuuyy}`           `}z^+++|||^++>?vvvv>|~|+?vvvv?+^^|^++++>>>?>.<br></br>
                  .??+|-.^?xyuxuuyyr`           ?x?|++^^^+++?vvvvv^.   `+?vvvvvv?????vvvvvvv-<br></br>
.>????>?wzxxzxuyyw-          |zr^^+^^>>??vvvvvvv~  .~?vvvvvvvvvvvvvvvvvvvv-<br></br>
.+++>????vvrrzxxuz~         .vx>^^^+?vvvvvvvvvvv?-`+?vvvv???vvvvvrwwzxxzwr-<br></br>
.+++++++>???vv}rzw-         >zv|^+?vvvvvvvvvvvvvv??vvvv???vvvrxuuuuxxuuuyy|<br></br>
.+++++++++++>???vv>|-`.    -rw+|+>vvvv?>??vvvvv??vvvv?????}rxyyyyyyyuuxxxu|<br></br>
.++++++++++++++>??vv??>|-``?zv^^+?vvv~  -^>vvv????vv??vvvvwxyyyyyyyyyyyyyu~<br></br>
.+++++++++++>>????vv}rrrvvvzr?+^+?vv+  .^??vvvv??vv??vv}zuuxuuyyyyuuuyyur?`<br></br>
.+++++++++++>>>?????vvv}}rrwv?+^^?vv^ .|?vvvvvvvv???v}rxyyyyuxxuyyyyyxv?>+.<br></br>
.++++++++++++++++>>>????vvvv?>++^+???|+?vvvvvvvv??vvvryyyyyyyuuxxyuwv?>|`. <br></br>
.+++++++++++++++++++>>>????v?+++^^^>?vv?????vvv??v}}zxzuyyyyyuyyxv>>+~.    <br></br>
.?>+++++++++++++++++++++>>??>+++++|^>???????????}}}xyyxzuyyyyyx}?>+`.      <br></br>
.>+++++++++++++++++++++++++>>++++^|^>?>++?????vrvruyyyyuzzyyxv?>+-.     .. <br></br>
.+>>++++++++++++++++++++++++++++^||^>>+>????vrw}ruyyyyyyyxrv?>^`.     .... <br></br>
.???>+++++++++++++++++++++++++++^||^++>>>??}r}zuuxuyyyyyxv>+|`     ....... <br></br>
.vv??+^+^^+++++++++++++++++++^^^|~|^^>>>???v}xuuuxwxxxxv>+^`       ....... <br></br>


            </div>,

            <div>

.~||||||~~|||||||||||||||||||||+|||||^??>>vvwvvrwrrrrr+. ?v^??????????????`<br></br>
.+++++++++^^+++++++++++++++++++?>++++?vv?}wzyyuzxyyyyyyw+vr+vvvvvvvvvvvvvv-<br></br>
.+++++++++++^^^++++++++++++++++>?^+++vv??w}yyuyyxwxuyuuyr}r^vvvv??vvvvvvvv-<br></br>
.+++++++++++++^^^+++++++++++++++?>^+>vv?}r`?uyyyyyxwzuyyv}}^?v?>+?vvvvvvvv-<br></br>
.+++++++++++++++^^^+++++++++++++>v++?vv?w>  `vyyyyyuxzzuvrv^>>+++>vvvvvvvv-<br></br>
.+++++++++++++++++^^^+++++++++>++??>?v?vr.    ~}yyyuyyxw?wv^+++++>vvvvvvvv-<br></br>
.+++++++++++++++++++^+++++++++>?>>v?vv?w?       ^wyyuuyx?w?+++++++?vvvvvvv-<br></br>
.??>>>>>+++++++++++++++>>++++++???vv??vw`        .+wyyyzvz?+++++++>vvvvvvv-<br></br>
`vvvvvvvv?????>>+++++++++?>+^+++>?????}?           .>zyrvz>++++++++>vvvvvv-<br></br>
.?vvvvvvvvvvvvvvvv???>>+^^>?+|^++?v?>>vv>.           .+vvw++++++++++>vvvvv-<br></br>
`vvvvvvv????????vvvvvvvv??>>?>^>vv??vvvvvv>+^|~~-````..~}w^++++++++++>?vvv-<br></br>
 `-~|^+vwrr}}}}vvvvvv????vv??????vvvrwr?>???vv}}rrrrrrrrwr^++++++++++++?vv-<br></br>
       vyuuwwuxxw||+>??vvvvvvv>>vvrwv>+++++^^^||^^^^^^^^+^^+++++++++++++?v-<br></br>
       vyyyxzyyyu.     ..`-^>?vvvrv>^+++++++++>>+^+>????>+^^+++++++++++++?`<br></br>
       ?yuyxzyyyy~            ~vv?^++++++^^^>??vv?vvvvvvvvv?^+++++++++++++.<br></br>
       >yuyxwyyyy+             >rv++++^^||^+>>?vvvvvvvvvvvvv>^^||||||~~~|^.<br></br>
 .     >yuyxryyyy?            .vz^+++^||^+++?vvvvv?^|^?vvvvv>^^||||||||^^+.<br></br>
`r?^`. >yyyxwyyyyv            >zv~++^|^+++>?vvvvv+.  .|^?vvv?v???>???vvvvv-<br></br>
.?}zz}>?wzuuzyyyyv           ~rw^^+^^++>??vvvvvv?.  .|??vvvvvvvvvvvvvvvvvv-<br></br>
.+^+>vrww}}}vxyyyr          .?x?^^++>?vvvvvvvvvvv-.`>vvvvvvvv?????v?ruxxzw~<br></br>
.+++++^>?vrww}}rzw.         +z}|^^>vvvvvvvvvvvvvv?+?vvvvvv??+?rzxxuxzzxxuy^<br></br>
.++++++++^^>?vwwr}^`.      `}x+|++vvvvv?vvvvvvvvv?vvvvvv?+^>wyyyyyyyyuuxzz|<br></br>
.++++++++++++^+>vrzzv>~`.  ?xv|^+?vvv?`.-^>?vv??>>vvvv?+^+?ryyyyyyyuuyyyyy|<br></br>
.++++++++++>>??????vrzzwv??wz?+^+?vvv`  `+??vv???vvvv>++vxxzzuyyyyuuuuuuyy^<br></br>
.+++++++++++>>??vvvvvvv}wzxuv?+|+?vv^  `>vvvvvvvvvv?+^?zyyyyuzzuyyyyyyyyyz-<br></br>
.++++++++++++++++>??vvvvvvvvv>+^+>vv?-->vvvvvvvvvv?+|?yyyyyyyyuzzuyyyyuv|. <br></br>
.+++++++++++++++++++>>??vvvvv+++|^+?vvvvvvvvvvvvv?^^rxzuyyyyyuyyuzzuz?`    <br></br>
.+++++++++++++++++++++++>??v?+++++^^>vvv???>?vvv?^+zyyxzuyyyyyyyyyw+.      <br></br>
.^+++++++++++++++++++++++++>>++++^|+>v?++++?vvv?|>xyyyyuzzyyyyyyw+.        <br></br>
.|^+++++++++++++++++++++++++++++^||^>>++^>vvvv?+?yyyyyyyyxzuyyr^.       .. <br></br>
.?>+++++++++++++++++++++++++++++||||++++?vv?>>vuxzyyyyyyyyuwv|        .... <br></br>
.vv>+++++++++++++++++++++++++^^^|||^^^+?v?>|?zuuuzrxuxxxxur|        ...... <br></br>


            </div>,

            <div>

.+>>>>>>+^^+^+???>?????????????v?????v^...`-|~~|||||||`  `-............... <br></br>
.???????>>>>?vvv?v}}}}}}}vv}}}}rr}}}}w}`.`~^++^|^++++++|``~.``````````````.<br></br>
.??????>>>>?vvv?v}v}}}}}}}vv}}}}wvvvvwz+.-~+^^^^^|^^^^^+|`~.````..````````.<br></br>
.????>>>>>?vvv+?v}}}}}}}}}}?}}}vrrvv}zz}-~.-^+^^++^|^^++~`-..`....````````.<br></br>
.???>>>>>?vvv^.?}vv}}}}}}}}vv}}v}w}vrzzz?`  .~+++^^^^|^+-`-.......```````` <br></br>
.??>>>>>?vvv|. ^}}vvv}}}}}}v?}}}vww}rzwzw.    .~++^^^^^|`--.......```````-`<br></br>
.?>>>>>?vvv~...`v}}vvv}}v}}}vvrw}}zwzzwx}.      `|++^^+^`-`........``````?-<br></br>
.??????vv?- ....>}}}}}v}}}v}v?vrwrwwwzzx>~        `|+++^`~`.........```.+}-<br></br>
`vvvvrww}~......`v}vv}}v}r}}vvv}rwrwwwxr+^.         `|+|`~..........``.|vv-<br></br>
`vvvrww}~.``````.vwrr}}}vv}r}v?}}wzwrrzz}>-           `-`~............-vvv-<br></br>
`v}wzzv`.``..```.+zzzzzwwwr}wr?}wzwzzzzzxzv`.......... .`~............>vvv-<br></br>
.^>>?>`-~----````-wxzzzzzzzwwwr}wzzzxxxr}rw+``----------~~...........~?vv}~<br></br>
.~||`  ~+^^||^^||.+v}rrwzzzzzzwvzxxxzr}vvv}?........................`>>>vw~<br></br>
.||`   -+^+^|+++^..^^^^+>>??v}xwrxw}vvv}}}}}-.......................>>>>vr~<br></br>
.|`    -+^^^|+^^+. |+^^^^^^^^^?zrrvv}v}v}vv}?..``.`````````........^>>>?vv-<br></br>
..     `+^^^|+^^+` `+^^^^^^^^^^rzrv}}}vvvvv}v`.`````````````..... -+++>?vv`<br></br>
       `+^^^|+^^+- .^^^^^^^^+^>zu?v}}vvvv}}}w>.```.....`````.... `+>+>?vv?.<br></br>
.-`..  `+^^^|++^+~  ~+^^^^^^^+ruw?v}vvvvvv}wzr```.    ..`````...`>??v}wwr?`<br></br>
 `-~~-``~^^^|++^+~  `+^^^^^^^?xxvv?vvv}}rwzzzz^..    ...````````?vvv}www??`<br></br>
 ....`~~---~~^+++|  .^^^^^^^>zurvvvv}wwzzzzzzzv`.   .````````..|vvvvzuuwv?`<br></br>
 .......``-----~||   ~+^^^^^}uzvvv}rzzzzzzzzzzz+....``````...`+wwzzxxxxvv}-<br></br>
 ...........`-~~--.. .^^^^^?xu}vv}wrwzzwwzzzzzz}`.``````...`|+wxzzuuuurvvv-<br></br>
 ...............`~~~-`??+^+wuwvvvrzzrw?>?v}wzzww|.````....`~+}xzzxuuuxv}}}-<br></br>
 .................``-~vuzrrxxrvvvwzzr>~+v}rwzzwwv````...~^^|?zxxxuuuurv}}}-<br></br>
 ...............``````~zxuuuzwvvvrzzv-^vrzzzzzzzx>``..`^++^>wwwzuuuuzv}}}v`<br></br>
 ...................```vzzzzz}vvv}zzr>?rzzzzzzzzzw`..-+++^^}xzzzxuuu}}v?|` <br></br>
 ......................|zzzzw}}vvv}wzzrzzzzzzzzzzz+.|^^^+^?xzzxuuxxwv>-``` <br></br>
 .......................?wwzwv}}vvvv}zwwwwr}rzzzw}v+++^^^?zxzxuuuyz^`.```` <br></br>
 .......................-}}}}v}}vvvv}zr?}v}wzzzwvrur^^+^^}xxzxyyzv~.`````` <br></br>
 ........................?}}}}}}vvvv}}}vv}wzzzr}wyyu?^^^vzwzxuw?+|.``````` <br></br>
 ........................|}}}}}}vvvvv}}vvzzwr}zuuuyyz+^?zxzwr?^^+```````-` <br></br>
 .........................v}vvvvvvvvvvvwr}}v}xuuuxzxu?^}zz}>^^^+~.```````` <br></br>


            </div>,

            <div>

.????????>????}rrv}wrwwww}vwrrrrrrrrrw|                                    <br></br>
`vvvvvvv?vvv}xuuzzyyyyyyyywyyyyyyyyyyyr                                    <br></br>
`vvvvvv?vvv}xuurxyuuuuuuuyzzyyuuuuuuuyy^                                   <br></br>
                  `vvvv??vvvrxuu?zyuuuuuuuuuuruyuuuuuuuyyw.                                  <br></br>
`vvv??vvvwuuu> ryuuuuuuuuuyzzyuuuuuuuuyy^                                  <br></br>
`vv?vvvvwuuu+  >yuuuuuuuuuuuruuuuuuuuuuyw                                ..<br></br>
`v?vvvvwuux|   `uyuuuuuuuuuyzzyuuuuuuuuyy|                               >~<br></br>
.?vvvvzuuz~     ?yuuuuuuuuuuuruuuuuuuuuuy}                              |}-<br></br>
`vvv}zuuz-      `uyuuuuuuuuuywzyuuuuuuuuyu`                            `vv-<br></br>
`vv}xuur`        vyuuuuuuuuuyuryuuuuuuuuuy?                           .?vv-<br></br>
`v}xuuv.         |yuuuuuuuuuuywuyyyuuuuuuyz.                          >vvv-<br></br>
                  `rxuuv.          .zyuuuuuuuuuyxwyyyuuuuuuuy^                         ^vvvr|<br></br>
`xuu?             ?yuuuuuuuuuuyruyyuuuuuuuyr                        `vvv}x|<br></br>
`xu>              ~yyuuuuuuuuuyxwyyuuuuuuuyy~                      .?vvvzu|<br></br>
-x+               .wyuuuuuuuuuuyruyuuuuuuuuyv                      >}vvwuu|<br></br>
`|                 >yuuuuuuuuuuyzzyuuuuuuuuyx.                    ~vvvrxux-<br></br>
                   `uyuuuuuyyuuuuruyyyyyyyyyy>                   `vvv}xuuv <br></br>
                    }yuuuuuuyuuuyzxyyyyyyyyyyw                  .?vvvzuuz^.<br></br>
                    ^yuuuyyuyuuuyuwyuuuuyyyyyy|                 >vvvruuu?^.<br></br>
                    .xyuuyyyyuuuyywxyuuuyyyyyy}                ~vvv}uuu}^+.<br></br>
                  vyuuyyyyuuuuyuwyuyyyyyyyyy|              `vvvvxuuz+++.<br></br>
                     |yuuyyyyuuuuuywxyyyyyyyyyyr             .?}vvzuuu?^++.<br></br>
                     .zyuyyyyuuuuuyuwyyyyyyyyyyy^            +}vvruuur^+++.<br></br>
                      ?yuuuyyuuuuyyywxyyyyyyyyyyr           -vvv}xuuu>+>++.<br></br>
                      `uyuuuyuuuuuyyuwyyyyyyyyyyy+         .?vvvzuuu}^++++.<br></br>
                       vyuuuyuuuuuuyyzxyyyyyyyyyyz.        >}vvwuuux++++++.<br></br>
                       -uyuuyuuuuuuuyuwyyyyyyyyyyy?       ~}vv}xuuuv^+++++.<br></br>
                        vyyuyuuuuuuyyyzxyuuyyuuuyyx`     `vvvvzuuuz+>+++++.<br></br>
                  ~yyuyuuuuuuuuyuwyyuyyuuuuyyv    .?vvvwuuuu?+++++++.<br></br>
                         ryuyuuuuuuuuyyzxyyyyuuuuyyu-   ^}vv}xuuur|+++++++.<br></br>
                         ^yuyyyyyyyyyyyuwyyyyyyyyyyy}  `vvvvzuuuu>++>>>>++.<br></br>
                         .zyuxxxxxxxxxxuwzuxxxxxxxxuu~ >vvv}xxzxv|+++++++^.<br></br>


            </div>,

            <div>

.????????>????v}}vvr}rrrrvvr}}}}}}}}}r^ ............   ................... <br></br>
`}}}}}}vvv}v}zxzrzuxxuuuuuwuuuuuuuuuuur............ ...................... <br></br>
`}}vvvvv}vv}zxx}zxxxxxxxxuzzuxxxxxxxxxu^.......... ....................... <br></br>
`vvvvvv}vvrzxz?wxxxxxxxxxxxrxxxxxxxxxxuw......... ........................ <br></br>
`vvvvv}vvwxxx>.}xxxxxxxxxxuzwuxxxxxxxxxu+ ..... .......................... <br></br>
`v}vv}vvrxxz+. >uxxxxxxxxxxxrxxxxxxxxxxur................................`.<br></br>
`vvvvv}wxxz| ..`xuxxxxxxxxxuwwuxxxxxxxxxu^...............................>-<br></br>
.?vvv}wzxw~.....?uxxxxxxxxxxxrxxxxxxxxxxuv.. .......................... |v`<br></br>
`vvv}zzxr- .....-xxxxxxxxxxxuwzxxxxxxxxxxx- .......................... `??`<br></br>
`vv}zxx}-........vuxxxxxxxxxxxruxxxxxxxxxx? ......................... .??v`<br></br>
`}rzxxv`........ ^uxxxxxzxxxxuwxxxxxxxxzzzw......................... .>vvv`<br></br>
                  `rzxx?............zuxxxxxxxxxuzwuxxxxxzzzzx+ ....................... ^vv?v~<br></br>
                  `zzz?.............?uxxxuxxxxxuurxxxxxzzxzzur....................... `vvvvw~<br></br>
                  `xx?..............|uxxxxxxxxxxuzwuxxzzzzxxxu| .................... .?vvvrz~<br></br>
-z+................rxxxxxxxxxxxxrxzzzzzxxxxuv......................>vvv}wz~<br></br>
                  `^ ............... ?uxxxxxxxxxxuzwxzzzxxxxxxz`................... |vvvvwww-<br></br>
 ..................-zxxxxxxxxxxxx}zzzxxxxxxxu> ................. `vvvvwwzv.<br></br>
                  ...................vuxxxxxxxxxzxrwzxuxxxxxxur...................?vvvrwwr^.<br></br>
 ...................+uxxxxxxxxzzzz}xxxxxxxxxxu| ............... +vvv}www?+.<br></br>
 ...................`zuxxxxxxzzzzxwzxxxxxxxxxxv............... |vvvvwwzv>>.<br></br>
 ....................vuxxxxxzzzzzxzrxxxxxxxxzzu^ ............ `vvvvrwzw>>>.<br></br>
 ....................^uxxxzzzzzzzxxwzxxxxxxzzxx}............ .?vvvrwwz?>?>.<br></br>
 .....................wxxzzzzzzzxxxxwuxxxzzxxxzx^ .......... >vvvvwwz}>??>.<br></br>
 .....................?uzzzzzzzxxxxuwzxzzzxxzzzx}.......... ~vvvvwwww?>>??`<br></br>
 .....................`zzzzzzxxxxxxuxrzzzxxzzzzzx+ ....... .?vvvrzwz}>????`<br></br>
 ..................... ?xzzzxxxxxxxxx}wxxzzzzzzzxw.........>vvv}wwww??????`<br></br>
 ..................... -zzzxxxxxxxxzzzrxzzzzzzzzzx> ..... |vvvvwwwzv??????`<br></br>
                  ...................... vxxxxxxxxzzzzxrzxzzzzzzzzxz` ... `vvvvrwzzw???????`<br></br>
 ...................... ~xxxuxxxzzzzzxzrxzzzzzzzzzx? ....?vvv}zzxxv???????`<br></br>
 ........................}uxxxzzzzzzzzxrwxzzzzzzzzxx- . +vvvvzzzxr>???????.<br></br>
 ....................... +uxxzzxxxzzxzxzrxxzzzzzzzxxv. `vvvvwxxxz????????>.<br></br>
 ........................`wxzzzzzzzzzzzzrrzzwzzwzzzzz~ ?vvv}zzzzv>???>>>>+.<br></br>


            </div>,

            <div>

`vvvvvv?vvvv?vvvv?vv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvv??^^>???vvvvvvvvvv??vvvv-<br></br>
`wwrrrvwwwrvrwwwvwrvwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?>>vv}vrwwwwwwwww}}wwwww~<br></br>
`w}v}vwww}vrwwrvw}vwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv?+?vvv}wwwwwwwww}vrwwwwww~<br></br>
`vvvvwwwv}www}vw}vwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv>>?vvvrwwwwwwwwrv}wwwwwwww~<br></br>
`vv}wwrv}www}vwvvwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv>>?vvvwwwwwwwww}vrwwwwwwwww~<br></br>
`vrww}vrwww}vwvvwwwwwwwwwwwwwwwwwwwwwwwwwwww}v>?vvv}wwwwwwwwrv}wwwwwww}}ww~<br></br>
`rwwvvrwww}vwv}wwwwwwwwwwwwwwwwwwwwwwwwwwww}?>?vvvrwwwwwwwrvvrwwwwww}v}wrv`<br></br>
`wrv}wwwwvvrv}wwwwwwwwwwwwwwwwwwwwwwwwwwww}?+?v?}wwwwwwww}vrwwwwww}v}wrv?>`<br></br>
`rvrwwwwv}rv}wwwwwwwwwwwwr}wwwwwwwwwwwwwrv?>???rwwwwwww}v}wwwwwwrvvrrv??>?`<br></br>
`vrwwwrvrrvrwwwwwwwwwwww}vrwwwwwwwwwwwwrv????vwwwwwwwrv}wwwwwwrvvrrv??>??v`<br></br>
`rwwwrvr}vwwwwwwwwwwwww}vwwwwwwwwwwwww}v??v?}wwwwwww}vrwwwwww}v}w}??>??vvv`<br></br>
`rwwvvw}vwwwwwwwwwwwwwvvwwwwwwwwwwwww}v??v?}wwwwwwrv}wwwwww}v}r}v????vvvvv`<br></br>
`wwv}wvvwwwwwwwwwwwwwvvwwwwwwwwwwwww}vvv??rwwwwww}}wwwwwwrvvr}v????vvvvvvv`<br></br>
`rvrwvvwwwwwwwwwwwwwv}wwwwwwwwwwwwrvvvv?vrwwwwwr}rwwwwwrvvrrv???vvvvvvvvvv`<br></br>
`vrwvvrwwwwwwwwwwwrv}wwwwwwwwwwwwrvvvv?vwwwwwwrrwwwwww}vrrv???vvvvvvvvvvvv`<br></br>
`rw}vrwwwwwwwwwwwrvrwwwwwwwwwwww}vvvv?vwwwwwwwwwwwww}v}r}???vvvvvvvvvvvvvv`<br></br>
`w}vwwwwwwwwwwww}vrwwwwwwwwwwww}vvvv?}wwwwwwwwwwwr}v}r}vvvvvvvvvvvvvvvvvvv`<br></br>
`vvwwwwwwwwwwwwvvwwwwwwwwwwwww}vvvv?rwrrwwwwwwww}v}rrvvvvvvvvvvvvvvvvvvvvv`<br></br>
.vwwwwwwwwwwwwvvwwwwwwwwwwwwrvvvvv?rr}rwwwwwww}??}rvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`wwwwwwwwwwwrv}wwwwwwwwwwwwrvvvvv?}}}wwwwwwwr?+?r}vvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`rwwwwwwwwwrvrwwwwwwwwwwwwrvvvv??vvrwrwwwww}??}}vvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`rwwwwwwww}vrwwwwwwwwwwwwrvvvv??vvwrvrwwwrv>?}vvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`rwwwwwww}vwwrwwwwwwwwww}vvvv??v}w}vrwww}?>v}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`rwwwwwwvvwrvrwwwww}www}vvvv??vrrvrwww}?+>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvr~<br></br>
`rwwwwwv}w}vrwwwwrv}wrvvvv???vr}vrwwrv>^?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrww~<br></br>
`rwwwwv}wvvwwwww}vrwrvvvv??v}rv}www}?+>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwww~<br></br>
`rwwrvrwvvwwwwwvvwwrvvvv??v}vvrwwrv?+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwwwr}-<br></br>
`rwrvrwv}wwwwrv}ww}vvvv??vvv}www}???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwwwr}}r~<br></br>
`wr}wrvrwwrrvvrww}vvvv??v??rwwrv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvrwwwwr}}rww~<br></br>
`rrw}vrww}rvvwww}vvvv?vv?vwwwrv??vvvvvvvvvvvvvvvv??vvvvvvvvvvrwwwwr}}wwwrv`<br></br>
`rw}vwwrv}v}www}vvvvvv??}wwrv???vvvvvvvvvvvvvvv???vvvvvvvvvrwwww}}rwww}vvv`<br></br>
`w}vwwrv}?rwwwvvvvvvv??wwwrv?????????????????????v??????vvrrrr}v}rrr}v????`<br></br>


            </div>,

            <div>

`vvvvvv?vvvv?vvvv?vv?vvvvvvvvvvvvvvvvvvv?????????v?+~|+>?>???????????>????`<br></br>
`wwrrrvwwwrvrwwwvwrvwwwwwwwwwwwwwwwwwrrrrrrrrr}}}v>^+??vv}r}}}}}}r}vv}}}}r-<br></br>
`w}v}vwww}vwwwrvw}vwwwwwwwwwwrrrrrrrrrrr}}}}rrr}?+|+??vv}}}}}}}}}v?v}}}}}}-<br></br>
`}v}vwwwvvwww}vw}vwwwwwwwwwwwrrrrrrrrrrrrrrrrr}?^|>??vv}}}}}}}r}?v}}}}}}}}-<br></br>
`vv}wwrv}www}vwvvwwwwwwwwwwwrrrrrrrrrrrrrrrrr}?+^>??v}}}}}}}rrv?v}}}}}}}}}-<br></br>
`v}ww}vrwww}vwv}wwwwwwwwwwwrrrrrrrrrrrrrrrrrv?>+>??v}}}r}rrrv?v}}}}}}}vv}}-<br></br>
`rwwvvwwww}vwv}wwwwwwwwwwwrrrrrrrrrrrrrrrrrv?+>?>?}}}}}}rr}vv}}}}}}}vvv}v?`<br></br>
`rwv}wwwwvvrv}wwwwwwwwwwwrrrrrrrrrrrrrrrrrv?^>?>v}}}}}}rrvvv}}}}}}v?v}v?>+.<br></br>
`rvrwwwwv}rvrwwwwwwwwwwww}vrrrrrrrrrrrrrrv?>???}r}}}}rrvvv}}}}}}v?v}v?>+++.<br></br>
`vrwwwrvrrvrwwwwwwwwwwrw}vrrrrrrrrrrrrr}v?>??vrr}}}rr}vv}}}}}}}vv}v?>+++>?`<br></br>
`rwwwrvr}vwwwwwwwwwwwrwvvrrrrrrrrrrrrr}?????vr}}}}rrvv}}}}}}}v?v}?>+^+>>>>.<br></br>
`rww}vw}vwwwwwwwwwwrrwvvwrrrrrrrrrrrrv?>???}rr}}rr}vv}}}}}}v?v}?>^^++>>>>>.<br></br>
`wwv}wv}wwwwwwwwwwrrrvvrrrrrrrrrrrrrv?????}r}}}rrvv}}}}}}v?v}v?++>>>>>>>>>.<br></br>
`rvrwvvwwwwwwwwwrrrrvvwrrrrrrrrrrwrvv????rr}}}}}v}r}}}}}v?}v?++>>>>>>>>>>>.<br></br>
`vrw}vrwwwwwwwwrrrrv}wrrrrrrrrrrwrvvv??vrrr}}}}}r}}}}}v?vv?>+>>>>>>>>>>>>>.<br></br>
`rw}vrwwwwwwwrrrw}v}rrrrrrrrrrrr}vvv??vr}}r}}}}rr}}}vvv}?>+>?>>>>>>>>>>>>>.<br></br>
`w}vwwwwwwwrrrrw}?rwrrrrrrrrrrr}vvv??vr}}}}r}}}rrv??v}v>>>>>>>>>>>>>>>>>>>.<br></br>
`vvwwwwwwrrrrrwvvrrrrrrrrrrrrr}vvv??}rv}r}}}}rr}??v}v?>>>>>>>>>>>>>>>>>>>>.<br></br>
.vwwwwrrrrrrrrvvwrrrrrrrrrrrrvvvv??}}v}rr}}}rrv>>vv?>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rwwrrrrrrrrrv}wrrrrrrrrrrrrvvvv??vv}rrrr}rr}>^?}v?>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rrrrrrrrrw}v}wrrrrrrrrrrr}vvvv??vv}r}rrrrrv++vv?>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rrrrrrrrw}vrwrrrrrrrrrrr}vvv????vr}v}rrrv?^>v?>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rrrrrrrrvvrrrrrrrrrrrrr}????>??}rvvrrr}v+^?v??>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
`rrrrrrwvvw}v}rrrrr}rrrv????>?v}}v}rrrv>|^?????>>>>>>>>>>>>>>>>>>>>>>>>>?v-<br></br>
`rrrrrrvvw}vrwrrw}v}rrv????>?vrvvrrr}?^|+??????>>>>>>>>>>>>>>>>>>>>>>>?v}r-<br></br>
`rrrrrv}wvvrrrrwvvrw}vvv??>?v}v}rrrv?^+????????>>>>>>>>>>>>>>>>>>>>>?v}}}r-<br></br>
`}rrrv}rvvrrrrrvvrr}?vvv???vvvrrr}v>+??????????>>>>>>>>>>>>>>>>>>>?v}}}}}v-<br></br>
`}}v?}}?v}}}}v?vrr}vvvv???v?vrrrv?>>???>???????>>>>>>>>>>>>>>>>>?v}}}}vvv}-<br></br>
`}v?}v?v}}v}v?}}}v??vv??v??}rr}v?>?????????????>>>>>>>>>>>>>>>?v}}}}vvvv}r-<br></br>
`}v}v?}}}vv??}}}?>>???vv?vwrw}?>????????????????>>>>>>>>>>>>?v}}}}vvv}}}v?`<br></br>
`}rv?}}v?v?v}}}?>>>>>???}rrrv????????????>??>>?>+>>>>>>>>>?v}}}}vvv}}}v???.<br></br>
`}v?}}v?v?v}}}?>???>>>?}rrrv??v?????????>>>+>>+>>?>>>>>>?v}}}}v?v}}}v?>>++.<br></br>


            </div>,

            <div>

                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????-``.`--~`````------`----------``----` <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvv>--..`|~---~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv?``..`|~^+-~~~~~~~-~~~~~~~~~~~~~~~~~~.<br></br>
                  `rwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvvvv-.`-^+++^`~~~~~~~-~~~~|^^^||~~~~~~~~.<br></br>
                  `rwwwwwwwwwwwwwwwwwwwwwwwwwvvvvvvvvvvv^~^+++++^`~~~~~~--~^++++||~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvvvv>+++++++^`~~~~~~|^++++^|~~~~~~~~~~~~.<br></br>
                  `rwwwwwwwwwwwwwwwwwwwwwwwvvvvvvvvvvvvv?^++++++^`~~~~^+++++^|~~~~~~~~~~~~~~.<br></br>
                  `rwwwwwwwwwwwwwwwwwwwwwrvvvvvvvvvvvvv??+++++++^`~~^++++++^|~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwrvvvvvvvvvvvvvv?>+++++++^`|++++++^|~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwrvvvvvvvvvvvvvvv?>++++++^|^++++++^~~~~~~~~~~~~~~~~~~~.<br></br>
`wwwwwwwwwwwwwwwwwww}vvvvvvvvvvvvvvvv?+>+++>^|+++++++^~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwrvvvvvvvvvvvvvvvvvv?^>+++||+++++++^|~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwrvvvvvvvvvvvvvvvvvvv>+>++||+++++++^~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvv?+>>+||+++++++^~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvv>+++^|++++++++|~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwww}vvvvvvvvvvvvvvvvvvvv?v?^++^|++++++++|~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwr}vvvvvvvvvvvvvvvvvvvvv???++++|++++++++^~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  `rwwwwwrvvvvvvvvvvvvvvvvvvvvvvv???++++|^++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwr}vvvvvvvvvvvvvvvvvvvvvvvvvv?>^+++~+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>^+++^|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .??vvvvvvvvvvvvvvvvvvvvvvvvvvv?++++++|^+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.??vvvvvvvvvvvvvvvvvvvvvvv???>^++++++|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.??vvvvvvvvvvvvv???????>^^^^+++++++^^|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.??vvvvvvvvvvvvv??vv?>>>^||++++++++++~+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.??vvvvvvvvvvvvvv?????>^++|^+++++++++|+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .?vvvvv?v???vvvvvvv?????+^^^+++++++++|^+++++++^~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 `-|+>???????????>++>?vv>||^+++++++++^|+++++++^~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
      ........ `>??vvvvv?^|^^^^^+++^^^~++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 .............. .-|+vvvv?v??>^^^^++++^~^+++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 .................  -?vv??v?v?>+^|++++^|+++++++^~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 ................... -?vv>^?vvv+||+++++^|+++++++~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .....................^~+vvvvv?+||^^^^^^`^++++++~-~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>


            </div>,

            <div>

                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????~``.`--~`````------`----------``----` <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvv>~~-.`|~---~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwrrwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv?--`.`||^+-~~~~~~~-~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvvvv~.`~^>>>+-~~~~~~~-~~~~|^^^||~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvvv^~+>>>>>^-~~~~~~--~^++++||~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvvvv>+>>>>>>^`~~~~~~|^++++^|~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvvvvv?+>>>>>+^`~~~~^+++++^|~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwvvvvvvvvvvvvv??+>>>>++^`~~^++++++^|~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwwwwwrvvvvvvvvvvvvvv?>+>>>+++^`|++++++^|~~~~~~~~~~~~~~~~~.<br></br>
`wwwwwwwwwwwwwwwwwwwwrvvvvvvvvvvvvvvv?>+>>>+++|^++++++^~~~~~~~~~~~~~~~~~~~.<br></br>
`wwwwwwwwwwwwwwwwwww}vvvvvvvvvvvvvvvv?+>>>>>^|+++++++^~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwwr}vvvvvvvvvvvvvvvvv?^>+++^|+++++++^~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwwwrvvvvvvvvvvvvvvvvvvv>+>++^|+++++++^~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvv?+>>+||+++++++^~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvv>+++^|++++++++|~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwwww}vvvvvvvvvvvvvvvvvvvv?v?^++^|++++++++|~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwwwwwwr}vvvvvvvvvvvvvvvvvvvvv???++++|++++++++^~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  `rwwwwwrvvvvvvvvvvvvvvvvvvvvvvv???++++|^++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`rwwr}vvvvvvvvvvvvvvvvvvvvvvvvvv?>^+++~+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
`}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>^+++^|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .????vvvvvvvvvvvvvvvvvvvvvvvvv?++++++|^+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.??????vvvvvvvvvvvvvvvvvvv???>+++++++|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.?????????vvvvvv???????>^^^^^^^^^^^^^|+++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.????????????????????>>>^||^^^^^^^^^^~+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.?????????????????????>|^^~|^^^^^^^^^|+++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.???????????????????>???^|||^^^^^^^^^|^+++++++^~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 `-~^>?>>>?>>?>?>^^^+?vv>||^^^^^^^^^^||++++++++~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
      .........`>??????v?|~^^^^^^^^|^|~++++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 .............. .-|^?vv????>+^||^^^^^^~^+++++++|~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .................  -?vv??v?v?+^||^^^^^|+++++++^~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
 ....................-?vv>^????^~|^^^^^^|+++++++~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .....................^-+vvv???+~|||||||`^++++++~---------~~~~~~~~~~~~~~~-.<br></br>


            </div>,

            <div>

 -|^+^|^}w}vvvvvvvvvvrwrvvvvvvvvvvvvvvvv???????>`-~|||||||||||||||||||||||.<br></br>
                  .~|^+^?xyxwwwwwwwwwwzyyxwwwwwwwwwwwwwwwvvvvvv?v?-~~^++++++++++++++++++++++.<br></br>
.~|+^?uyzwwwwwwwwwwwxyuzwwwwwwwwwwwwww}vvvvvv?v^~~~~+++^++++++++++++++++++.<br></br>
.-|+vyuzwwwwwwwwwwwzuyxwwwwwwwwwwwwww}vvvvvvv?>-~~~|+++|++++++++++++++++++.<br></br>
                  .~~?yuzwwwwwwwwwwwwuyuwwwwwwwwwwwwwwrvvvvvvv??^^-~~~+++~++++++++++++++++++.<br></br>
                  .-^uyzwwwwwwwwwwwwzxxzwwwwwwwwwwwwwrvvvvvvvvv+^+-~~|++^-^+++++++++++++++++.<br></br>
.-ryxwwwwwwwwwwwwwzzwwwwwwwwwwwwwwwvvvvvvvvvv|+>|-~|++~-++++++++++++++++++.<br></br>
 +yuwwwwwwwwwwwwwwzzwwwwwwwwwwwwwrvvvvvvvvvv+|+>|-~|+|--++++++++++++++++++.<br></br>
                  vyzwwwwwwwwwwwwwzxzwwwwwwwwwwwwrvvvvvvvvvv?~+++-~~||-`~++++++^||^+++++++|.<br></br>
                  .xuwwwwwwwwwwwwwxuuzwwwwwwwwwwwrvvvvvvvvvvv^++++`~~|-.-|+++^|~|++++++++|~~.<br></br>
-uxwwwwwwwwwwwwwwzwwwwwwwwwwwwwvvvvvvvv?vv>++++^`~~~.`~^+|~~|^+++++++^|~~~.<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv??>+++++--~~~`~~|~~~^++++++++|~~~~~.<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvv>^+++++^`~~~-~~~~~~+++++++++|~~~~~~.<br></br>
-uzwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvv?^++++++--~~--~~~~~++++++++^~~~~~~~~.<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvvv?>++++++++`~~~-~~~~~++++++++^~~~~~~~~~.<br></br>
                  .xxwwwwwwwwwwwwwwwwwwwwwwrvvvvvvv?v?+++++++++|-~~--~~~~^++++++++~~~~~~~~~~.<br></br>
 ?ywwwwwwwwwwwwwwwwwwwwwrvvvvvv??>+++++++++|+~~~~`~~~~|++++++++|~~~~~~~~~~.<br></br>
                  `uxwwwwwwwwwwwwwwwwwwwrvvvvvvv?+^++++++^~~|^-~~--~~~~++++++++^~~~~~~~~~~~.<br></br>
                  +yzwwwwwwwwwwwwwwwwwrvvvvvv?+++++++^~--~~||~~-`-~~~~++++++++|~~~~~~~~~~~.<br></br>
 ..+xzwwwwwwwwwwwwwww}vvvv??|^++^++++----~~|||^|^|-~~^++++++++~~~~~~~~~~~~.<br></br>
 .. ~rzwwwwwwwwwwwwrvvv?????>^|++^||^--------~~~|||--^+++++++^~~~~~~~~~~~~.<br></br>
 ... .^vrwwwwwwwww}v??>????vv?^>^~--``````-------~~|~|+++++++^~~~~~~~~~~~~.<br></br>
 ..... ..-^>????>|-`..```.``--`.....````````````--~~~|+++++++^~~~~~~~~~~~~.<br></br>
                  ........         ...............`````````````````-~~~+++++++^~~~~~~~~~~~~.<br></br>
                  ..............................`````````````````````--^+++++++|~~~~~~~~~~~.<br></br>
                  ..............................``````````````````````-~+++++++^~~~~~~~~~~~.<br></br>
                  .................................`.`````````......```-^+++++++|~~~~~~~~~~.<br></br>
                  ....................................```````````.``.```-++++++++|~~~~~~~~~.<br></br>
                  ...................................`````````````````````-^++++++|~~~~~~~~.<br></br>
                  .......................................`````````````````.^+++++++|~~~~~~~.<br></br>
                  .......................................``````````````````-++++++++|~~~~~~.<br></br>
                  ............................................```````..``````^+++++++^~~~~~.<br></br>


            </div>,

            <div>

 -~|^^|^}w}vvvvvvvvvvrwrvvvvvvvvvvvvvvvv???????>`-~|||||||||||||||||||||||.<br></br>
                  .~~^^^?xyxwwwwwwwwwwzyyxwwwwwwwwwwwwwwwvvvvvv?v?-~~^++++++++++++++++++++++.<br></br>
.-~++?uyzwwwwwwwwwwwxyuwwwwwwwwwwwwwww}vvvvvv?v^~~~~+++^++++++++++++++++++.<br></br>
.-|+vyuzwwwwwwwwwwwzuyxwwwwwwwwwwwwww}vvvvvvv?>-~~~|+++|++++++++++++++++++.<br></br>
                  .~~?yyzwwwwwwwwwwwwuyuwwwwwwwwwwwwwwrvvvvvvv??^^-~~|+++~++++++++++++++++++.<br></br>
                  .-^uyzwwwwwwwwwwwwzxxzwwwwwwwwwwwwwrvvvvvvvvv+^+-~~|++^-^+++++++++++++++++.<br></br>
.-wyxwwwwwwwwwwwwwzzwwwwwwwwwwwwwwwvvvvvvvvvv|+>|-~|++~-++++++++++++++++++.<br></br>
 +yuwwwwwwwwwwwwwwzzwwwwwwwwwwwwwrvvvvvvvvvv+|+>|-~|+|--++++++++++++++++++.<br></br>
                  vyzwwwwwwwwwwwwwzxzwwwwwwwwwwwwrvvvvvvvvvv?~+++-~~||-`~++++++^||^+++++++|.<br></br>
                  .xuwwwwwwwwwwwwwxuuzwwwwwwwwwwwrvvvvvvvvvvv^++++`~~|-.-|+++^|~|++++++++|~~.<br></br>
-uxwwwwwwwwwwwwwwzwwwwwwwwwwwwwvvvvvvvv?vv>++++^`~~~.`~^+|~~|^+++++++^|~~~.<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv??>+++++--~~~`~~|~~~^++++++++|~~~~~.<br></br>
-uzwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvv>^+++++^`~~~-~~~~~~+++++++++|~~~~~~.<br></br>
-uzwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvvv?^++++++--~~--~~~~~++++++++^~~~~~~~~.<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvvv?>++++++++-~~~-~~~~~++++++++^~~~~~~~~~.<br></br>
                  .xxwwwwwwwwwwwwwwwwwwwwwwrvvvvvvv?v?+++++++++|-~~--~~~~^++++++++~~~~~~~~~~.<br></br>
 ?ywwwwwwwwwwwwwwwwwwwwwrvvvvvv??>+++++++++|>|~~~`~~~~|++++++++|~~~~~~~~~~.<br></br>
                  `uxwwwwwwwwwwwwwwwwwwwrvvvvvvv?+^+++++^^~~|+-~~--~~~~++++++++^~~~~~~~~~~~.<br></br>
                  +yzwwwwwwwwwwwwwwwwwrvvvvvv?+++++++^~--~~||~~-`-~~~~++++++++|~~~~~~~~~~~.<br></br>
 ..+xzwwwwwwwwwwwwwww}vvvv??|^++^++++----~~||^^^^|-~~^++++++++~~~~~~~~~~~~.<br></br>
 .. ~rzwwwwwwwwwwwwrvvv?????>^|++^||^-------~~~||^|--^+++++++^~~~~~~~~~~~~.<br></br>
 ... .^vrwwwwwwwww}v??>????vv?^>^~--`````-------~~||~|+++++++^~~~~~~~~~~~~.<br></br>
 ..... ..-^>????>|-`..```.``--`....````````````--~~~~|+++++++^~~~~~~~~~~~~.<br></br>
                  ........        ...............````````````````--~~~~+++++++^~~~~~~~~~~~~.<br></br>
                  .............................````````````````````----^+++++++|~~~~~~~~~~~.<br></br>
                  .............................`````````````````````---~+++++++^~~~~~~~~~~~.<br></br>
                  ..............................`.`.```````````....```--^+++++++|~~~~~~~~~~.<br></br>
                  ...................................``````````..```````-++++++++|~~~~~~~~~.<br></br>
                  ...................................`````````````````````-^++++++|~~~~~~~~.<br></br>
                  ......................................``````````````````.^+++++++|~~~~~~~.<br></br>
                  ......................................```````````````````-++++++++|~~~~~~.<br></br>
                  ..........................................````````.````````^+++++++^~~~~~.<br></br>


            </div>,

            <div>

 ``-~||~~|~~-^?v}+..... .. ........ `+~`..``-`-.          .``..`~^+~--```. <br></br>
 --^>++>???+???rzz?`... .............|^~`-``|^-.         .`|..```-||^^|`.. <br></br>
.-~+>???vvv??vwzzxxv~`.  ..........````---...-`        .|-~^|`....----..   <br></br>
.~~|>>>>>?vv?vvv}wzz}?|`.    ...........``.`...       `-~^^^|~-`..`-.      <br></br>
.--^++^|~^>??vvv}}}rwwwv?>~|. .........`......       .`.``-~~~|~-.         <br></br>
.---~|||||^^^++?vv}}r}rzw}?wv+.............``.       ....^-..`|~`          <br></br>
 `-`-~||||||++^^~|>+>>?}zzwrzz+`...........`.       ...`.`....             <br></br>
 `-`-~~~~|||^>??|>??>+^+?>+^>vv?~...........        .....                  <br></br>
.``````--|>??vvvv????>~|++^++++++~......|..                                <br></br>
.|+?>>+>>?vv????>^>>?+^+++++++++++++^^~~+-^`                               <br></br>
.|>vvvvv+~~~~~~~~+?>|~|-+++++++++++++++++|>?.                              <br></br>
                  .-~^?v?-.`````-------```^++++++++++~++++++|+?`                             <br></br>
 ...>|`.`.``````----~~-`~^^^++++++^~+++++++|^>~           ............|`.. <br></br>
                  ..`~......```````-~|^^|----^+++++++^||^+++^|~-................``......... <br></br>
 ..............`>vrrwxzr???+-~~~~|++++^~^++^~-`.......  .................. <br></br>
 .............~rzwwzxwwwwwwr?>^`-~^+++++++++|`............................ <br></br>
 .............?zwwzzwwwwwwwwvv>--~^++++++++|~??~.......................... <br></br>
 .............-rwwwwwwwwwww}vv>`-|+++++++++|^wuz?~........................ <br></br>
 ..............|}wwwwwwwwr}vv?-`~+++++++++++^+}xx}>`...................... <br></br>
 ................^?v}}}}}v??|``-^>++++++++++^^?v}wwv~..................... <br></br>
 ..................`-|+^|~`....-|^++++++++^|~|^++>?vv>^|`................. <br></br>
                  ..............................-~~~|^+++^||~`-~~||^+vxxww?~`.............. <br></br>
 ..............................```--||~-|++~``-~~|||>v}}rz}v?+-. ......... <br></br>
 ..............................`..`~-^>|??>-```-~~~~^+>>>vzzrvv?~... ..... <br></br>
 ............................````````vv^>v?~````--~~|^^^^^?w}??}}?>^-.. .. <br></br>
 ............................```````.-?>??>|.`````-~~|||~~^??>>>??vvvv?^`. <br></br>
 .................................`````vv>v+.`..```-~~~~~-~>?>++++++>?v}r?.<br></br>
 .................................````^v++?^....````--~----+>>>+|||||^>?vr~<br></br>
 ..................................`.+v?~>+-..````````----~^>?>^||||||^+>?`<br></br>
                  ..................................``?v???|.`````````````-~|^^^||~~~~~|^^+.<br></br>
 ...................................-v?vv>-``````````````---~~|||~~~~~~|^^.<br></br>
                  ..................................`?v?^-..```````.````````--~~~~~~----~~~.<br></br>


            </div>,

            <div>

 ``-~||~~|~~-^?v}+..... .. ........ .+~`..``-`-.          .``..`~^+~--```. <br></br>
 --|+++>???+>??rzz?`... .............|^~`-``|^-.          `|..```-||^^|`.. <br></br>
.-~+>???vvv??vwzzxxv~..  ..........````--~`..-`        .~-~^|.....`-~-..   <br></br>
.~~|>>>>>?vv?v}}}wzz}?~..    ..........```.`...       `-~^^^|~-`..`~.      <br></br>
.--^++^|~^>??vvv}}}wwwwv?+~~. .........`...`..       .````~~~~|~-.         <br></br>
.---~|||~|^^^+>?vvv}r}rzw}?rv+.............``.       ....|-..`|~`.         <br></br>
 `---~||||||++^^~|>+>??}zzwwzz+`...........`.       ...`.``...             <br></br>
 `-`-~~~~|||^>??|>??>+^+?>+^>v??-...........        .....                  <br></br>
.`````---|>??vvvv????>~|++^++++++~......|..                                <br></br>
.|+?>>+>>?vv????>^>>?+^+++++++++++++^^~~+-^`                               <br></br>
.|>vvvvv+~~~~~~~~+?>|~|-+++++++++++++++++|>?.                              <br></br>
                  .-~^?v?-..````--------``^++++++++++~++++++|+?`                             <br></br>
 ...>|`.`.`````-----~~-`~^^^++++++|~+++++++|^>~         . ....`.......|`.. <br></br>
                  ..`~......```````-~|^^|----^+++++++^||^+++^|~-.................`......... <br></br>
 ..............`>vrrwxzr???+-~~~~|++++^~^++^~-`.......  .................. <br></br>
 .............~rzwwzxwwwwwwr?>^`-~^+++++++++|`............................ <br></br>
 .............?zwwzzwwwwwwwwvv>--~^++++++++|~>?~.......................... <br></br>
 .............-rwwwwwwwwwww}vv>`-|+++++++++|^wuz?~........................ <br></br>
 ..............|}wwwwwwwwr}vv?-`~+++++++++++^+}xx}>`...................... <br></br>
 ................^?v}}}}}v??|``-^>++++++++++^^?v}wwv~..................... <br></br>
 ..................`-|+^|~`....-|^++++++++^|~|^++>?vv>|~`................. <br></br>
 ..............................-~~~|^+++^||~`-~~||^+vxxww>~`.............. <br></br>
 ..............................```--||~-|++-``-~~|||>}}rrz}??+`. ......... <br></br>
 ..............................`..`~-^>|??>-```-~~~~^+>>>vzzr}v>~... ..... <br></br>
 .............................```````vv^>v?~````--~~|^^^^^?w}??}}?+|`.. .. <br></br>
 ............................```````.-?>??>|.`````-~~||||~^??>>>?vvvv?>|`. <br></br>
 .................................`````vv>v+.`..```-~~~~~-->?>+++++>>?v}}?.<br></br>
 .................................````^v++?^....````--~----+>>>+^||||^>?vr~<br></br>
 ..................................`.+v?~>+-..````````----~^>?>^||||||^+>?-<br></br>
                  ..................................``?v???|.`````````````-~|^+^^||~~~~|^^+`<br></br>
 ...................................-v?vv>-``````````````---~~|||~~~~~||^^.<br></br>
                  ..................................`?v?^-..```````.````````--~~~~~~----~~~.<br></br>


            </div>,

            <div>

 ``-~||~~~~~-^?v}+..... .. ........ .+~`..`.`.        ..` .``.``|^+~--```. <br></br>
 --|+++>???+>??}zz?`... .............|+~````^-       `..  `|..```-^|^^-.   <br></br>
.--^>>??vvv??vwzzxxv-.. ...........``----~`...       -..~--^^.....`--`     <br></br>
.~~|>>?>>?vv?v}}}wzz}?~..    ..........```...       .`--~+^^|~-`...`       <br></br>
.--|++^|~^???vvvv}}wwwwv>^--. .........`...`.      .``````~||~~`.          <br></br>
.---||||~|^^++>?vv}rwrwzr}?}v^...........`...      ``....|-....            <br></br>
 `---~||||||+>+^^^+>>??}zxwwxz^.............      .....`...                <br></br>
 `---~~~|||||^+>+-+>^~^????++wrv~..........        ...                     <br></br>
 ````----~|+^?vv?+??>^-~^++|^++++~.....-~.                                 <br></br>
.~|^>++^++?vvvvvv?++??~+++++++++++|||-`^~|`                                <br></br>
.~^>vvvvv>^|^^^|||+>^||~^+++++++++++++^+^^?.                     ...-`.... <br></br>
.~~|?vvv~.````---~~--`-`~++++++++++~+++++^^>~            ....`.......~|... <br></br>
 ...`?+-.``````---`-~~-``|^+?>++++^~+++++++^++.  .  ..........`......`^`.. <br></br>
                  ...``.....`````````-~~|`^?vrv?^++^++^|^+++^^~-.................-......... <br></br>
                  .............`````.```~vzwwvvv|||^+^^^|^++^~``........  ................. <br></br>
                  .....................+zxwwrvvv|--|++++++++^~-`........................... <br></br>
                  ...................`vzzwwwvvvv^`-^+++++++^~|v?-.......................... <br></br>
 ..................`}zwxwwrvvvv|`~++++++++^~>zuw?-........................ <br></br>
 ..................|zwwzww}vvv>`~+++++++++++^+}xzv>`...................... <br></br>
 ..................`rwwww}vvv>``|+++++++++++^^?vrwwv~..................... <br></br>
 ...................~vrv???>~..`~++++++++++~-|++>>?vv+~-`................. <br></br>
 .....................`--``....`~~|^^^++^||~`-~~||^+vzzr}>-`.............. <br></br>
 ...............................```-~|~~|>>|`--~~~||>}rwwzv?>^`. ......... <br></br>
 ..............................`...`-->+^??|```-~~~~^+>>?vzwr}v>-... ..... <br></br>
 .............................```````-v?^??>````-~~~|^^^^+?wrvv}}?+~`.  .. <br></br>
 .............................```````.~???>?-`````--~||||~^??>>??vvvv?>|.. <br></br>
 .................................````.-v>+v-...```-~~~~~-->?>++>>>>??v}}>.<br></br>
 .................................`````??|??-....```--~--`-^?>>+^||||^>?vr~<br></br>
 ...................................`.^v?|>^`.````````-----^>?>^||||||^+>?-<br></br>
 ...................................`.>vvv?~.````````````-~|^++^||~~~~|^^+`<br></br>
 ....................................`???>~``````````````---~~|||~~~~~~|^^.<br></br>
 ....................................>v?|..```````.````````--~~~~~~----~~~.<br></br>


            </div>,

            <div>

                  ``-~||~~~~~-^?vv+..... .. ..........^~`..`.``        ..- .``..`|^^|~-```. <br></br>
 --|++^+??>++>?}zz?`... .............~+|````|-       `... .|..```-^||^-.   <br></br>
.--^>>??vvv?>vrzzxxv-..............```---~`...       -.`-`-|^.....--~`     <br></br>
.~~|+>????vv?v}}rwzzv>-.     ...........``...       .``-~+^^^~`.....       <br></br>
.--|+++||^???vvvv}}wwwr?>^``. .........``..`.      .````--~|||~`.          <br></br>
.--~|^||~|^+++>?}}}rwrwzr}?}v|...........`...      ``....|-....            <br></br>
 `---~||||||^>++^^>>???}zxwrxz^.............      .........                <br></br>
 `---~~~|||||^+>+-+>^~^????++wr?~..........        ...                     <br></br>
 ````----~|+^?vv?+??>^-~^++|^++++~.....-~.                                 <br></br>
.~|^>++^++?vvvvvv?++??~+++++++++++|||-`^~|`                                <br></br>
.~^>vvvvv>^|^^^|||+>^||~^+++++++++++++^+^^?.                     ...-..... <br></br>
.-~|?vvv~.````---~~--`-`-++++++++++~+++++^|>~            ....``..`...-|... <br></br>
 ...`?+-.``````---`-~~-``|^+?>++++^~+++++++^++.  .  ..........`.......^-.. <br></br>
                  ...``.....`````````-~~|`^?vrv?^++^++^|^+++^^~-.................-......`.. <br></br>
                  .............`````````~vzwwvvv|||^+^^^|^++^~``........ .........`..... .. <br></br>
                  .....................+zxwwrvvv|--|++++++++^~~`........................... <br></br>
 ...................`vzzwwwvvvv^`-^+++++++^~|v>`.......................... <br></br>
 ..................`}zwxwwrvvvv|`~++++++++^~>zuw?-........................ <br></br>
 ..................|zwwzww}vvv>`~+++++++++++^>}xzv>`...................... <br></br>
 ..................`rwwww}vvv>``|+++++++++++^^?vrzwv~..................... <br></br>
 ...................~vrv???>~..`~++++++++++~-|+>>??vv+--`................. <br></br>
 .....................`--``....`~~|^^^++^||~`-~~||^+?zw}}>-............... <br></br>
 ...............................```-~|~~|>>|`--~~~||+rwwzzv?>|`  ......... <br></br>
 ..............................`...`-->+^??|```-~~~~^>>>?vzw}}v+`......... <br></br>
 ..............................``````-v?^??>````-~~~|^^+++?wwvv}}?^~`.  .. <br></br>
 .............................```````.~???>?-````--~~||||~^?v?>??vvvv?+~.  <br></br>
 ...............................``````.-v>+v-...```-~~~~~-->?>++>>>>??v}v>.<br></br>
 .................................`````??|??-....```--~~---^?>>+^^|||^>?vr~<br></br>
 ...................................`.^v?|>^`.````````-----|>?>^||||||^+>?-<br></br>
 ...................................`.>vvv?~.````````````-~|^++^||~~~~|^^+`<br></br>
 ....................................`???>~``````````````---~||||~~~~~||^^.<br></br>
 ....................................>v?|..```````.````````--~~~~~~----~~~.<br></br>


            </div>,

            <div>

                  ``-~||~~~~-~+?vv+.....  . ..........^~`..`..      .|^`.^...`..-^+^||-`.   <br></br>
 --~+^^+>?>+^>?vzz?.... .............~+|`````      `?>... .|`.```~+^~.     <br></br>
.--^>>??vvv?>v}wzxxv`..............```-~---.       ``~.````|^....``.       <br></br>
.~~|+>????vv??}rrwzzv>-.   ............```..      .`.``--+^^^|`..          <br></br>
                  .-~|+++^|^?v?vvvvvrwzrr?+|`.. .........``...      .`````--~||-`.           <br></br>
.--~|^||~~^++>>?}}}rwwwzr}?v?~..............     ...-....-`..              <br></br>
 `---~||||||^>++++>????}zzrrzz^............      ... ... .                 <br></br>
 ``--~~~||||^++^|||^|^>vrzzzzxzv~..........       ..                       <br></br>
 ````---~~||||^>>>~>>^^?>???+?vvv+`.......                            .... <br></br>
 ``-`~^|~~|^???vvv???>+`~^++^+++++>`. -|``                     ....`|-`... <br></br>
 `-||?v?vv?>?>>>>>+^>>>|^^++++++++++^~|+~?^                ``...`..~>`.... <br></br>
 .`~~>vvv?````-----~||-|-|+++++++++^+++++|+>`        .......```..`...-|... <br></br>
 .....^?+`.````-----~~`-``+++++++++-^+++++^^>`  ..............`.......+-.. <br></br>
                  .....~`...``````--`-~~~--|||^++++^^+^^+++++|~`.................-......`.. <br></br>
                  .............`````````````-~|||++++^|||^++^~-.........  ........`..... .. <br></br>
 ......................-|>??}v>~--~^++++++++~^-........................... <br></br>
 ..................|+?vrzxwwwrrv|`-^++++++^~^w>`.......................... <br></br>
 .................?wwwwwxzwwww}v|`~++++++++|>zuw>-........................ <br></br>
 .................?wwwwwxwwww}v?`~++++++++++^>vxzv+`...................... <br></br>
 .................`vwwwwwwwrvv?-`~++++++++++^|?}wzwv~..................... <br></br>
 ...................-+???????+`.`~^++++++++|-~+>>?v}v+-`.................. <br></br>
 .........................`.....`~~~~|+++^||-`~~|^^>?wwvv+`............... <br></br>
 ...............................````-~~~~^>>-`-~~~||+rzzzzv>>~.. ......... <br></br>
 ...............................``..`--?>+??|``-~~|~|>>??vzw}vv+`......... <br></br>
 .............................`````````vv+?>^```-~~~|^^+++?wwvv}v?|-..  .. <br></br>
 ............................`````````.-?v??v````---~||||~^?v?>??vvv?>^-.  <br></br>
 ...............................````````~v>??`...``-~~~~~-->?>++>>???vv}v+.<br></br>
 .................................``````?v^?>`...```-~~~---^?>>+^^|||+>?vr~<br></br>
 ...................................```|vv??~..```````-----|>??^||||||^+>?-<br></br>
 ...................................```^vvv>`````````````--|+++^|||~~||^^+`<br></br>
                  ...................................``.?v+-```````````````--~||||~~~~~||^^.<br></br>
 .....................................~v>-.```````.````````--~~~~~~---~~~~.<br></br>


            </div>,

            <div>

                  ``-~|^~~~~-~+?vv^.....  . ..........|~`..`..      .~^`.^...``.-^+^||-`.   <br></br>
 --~^^^^>>>+^+>vzz>.... .............~+|`````      `?>... .|`.```~+^~.     <br></br>
 --|>>>?vvv?>v}wzxxv`..............```~~---..      ``~..```||.....`.       <br></br>
.~~~+>???vvv??}rrwzzv>-.   ...........```-..      `-````-+|^^|`..          <br></br>
.-~|+++^|^?v?vvvvvrwzr}?^~`.. ..........`...      .`````--|^|-`.           <br></br>
.--~|^^|~~^+>>>?}}}rwwwzrv???-.............       ..-....-`..              <br></br>
 `---~||||||^+++++>??vv}zzrrzz^............      ... ... .                 <br></br>
 `---~~~||||^++^|||^|^>vwxxxzxzv~.........        ...                      <br></br>
 ````---~~||||^>?>~>>^^?>???+?vvv+`.......                            .... <br></br>
 ``-`~^|~~|^???vvv???>+`~^++^++++++`. -|``                     ....`~~`... <br></br>
 `-||?vvvv?>?>>>>>+^>>>|^^++++++++++^~|+|?^                ``...`..~?-.... <br></br>
 .`~~>vvv?````-----~||-|-|+++++++++^+++++|+>`        ......`-``..`..`-|... <br></br>
 .....^?+`.````---`--~`-``+++++++++-^+++++^^>`  ......................^-.. <br></br>
                  .....~`...``````----~~~-`~|~^++++^^+^^+++++|~`.................`......`.. <br></br>
                  .............```````````.`-~|||++++^|||^++^~-...................`........ <br></br>
 ......................`|>??}v>~--~^++++++++~^-........................... <br></br>
                  ..................|+?vrzxwwwrrv|`-^++++++^~^w+`.......................... <br></br>
 .................?zwwwwxzwwww}v|`~++++++++|>zur>`........................ <br></br>
 .................?wwwwwxwwww}v?`~++++++++++^>}xzv+`...................... <br></br>
 .................`vwwwwwwwrvv?-`~++++++++++^|>}wzwv~..................... <br></br>
 ...................-^>??????+`.`~^++++++++|-|+>??v}v^``.................. <br></br>
 .........................`.....`~~~~|+++^||-`~||^+>?w}v?+`............... <br></br>
 ...............................````-~~~~^>>-`-~~~||+rzzxzv>+~............ <br></br>
 ...............................``..`--?>+??|``-~~|~|>>??vzw}vv^`......... <br></br>
 .............................`````````vv>?>^```-~~~|^+++>?wwvv}v>|`. .... <br></br>
 .............................````````.-?v??v````---~||||~|?v?>?vvvv?>|`.  <br></br>
 .............................`````````.~v>??`...``-~~~~~--+?>++>>???vv}v^.<br></br>
 .................................``````?v^?+`...```-~~~---|?>>++^|||+>?vw~<br></br>
 ...................................``.|vv??~..```````-----|>??+||||||^+>?-<br></br>
 ...................................``.^vvv>````````````---~^>>^|||~~~|^^+`<br></br>
                  ...................................``.?v+-```````````````--~|||||~~~~||^^.<br></br>
 .....................................~v>-..``````..```````--~~~~~~~--~~~~.<br></br>


            </div>,

            <div>

 ``-~|^~~~~-~>vvv^...... . ..........|-`.....      .~^`.^. .``.-^^^||-`.   <br></br>
 ---^^^^+>>^^+>vzz>.... ............ ~+|`.```      `??`.. .|`.```|>^~.     <br></br>
 --|>>>?vvv?>vvrzxxv`..............```~|---..      `-|`.`.`||.....`.       <br></br>
.~~~+>???vvv??}wwzxxv+-.  ..........`.```-`.      `-````-^|^^~`..          <br></br>
                  .-~~^++^|^?v?vvvvvrwzrv?|-..  ..........`...      .````--~|^|-`.           <br></br>
.--~|^^|~~^+>>??}r}rwwwzrv???-..........`..       ..-`...``..              <br></br>
 `---~||||||^+++>>??vvvrzzr}zz^............      ... ... .                 <br></br>
 ``--~~|||||^++^|||^|^>vwxxxzxz?~..........       ...                      <br></br>
 ````---~~||||^>?>~>>^^?>???+?vvv+`.......                            .... <br></br>
 ``-`~^|~~|^???vvv???>+`~^++^++++++`. -|``                     .....-~`... <br></br>
 `-||?vvvv?>?>>>>>+^>>>|^^++++++++++^~|+|?^                ``...`..~?~.... <br></br>
 .`~~>vvv?````-----~||-|-|+++++++++^+++++|+>`        ......`-``.....`-|... <br></br>
 .....^?+`.````---`--~`-``+++++++++-^+++++^^>`  ......................+~.. <br></br>
                  .....~`..```````----~~~-`~|~^++++^^+^^+++++|~`.................`......-.. <br></br>
                  .............```````````.`-~|||++++^|||^++^~-...................`........ <br></br>
 ......................`|>??}v>~--~^++++++++~^-........................... <br></br>
                  ..................|+?vrxxwwwrrv|`-^++++++^~^w+`.......................... <br></br>
 .................?zwwwwxzwwww}v|`~++++++++|>zx}>`........................ <br></br>
 .................?zwwwwxwwww}v?`~++++++++++^>}xzv^`...................... <br></br>
 .................`vwwwwwwwrvv?-`~++++++++++^|>}zxwv~..................... <br></br>
 ...................-+>??????+`.`~^++++++++|-|>>??v}v^``.................. <br></br>
 .........................`.....`~~~~|+++^||-`~||^+>?r}v?+`............... <br></br>
 ...............................````-~~~~^>>-`-~~~|^^rxxxzv>^-............ <br></br>
 ...............................``..`--?>+??|``-~~|||>???vzw}v?^.......... <br></br>
 .............................`````````vv+?>^```-~~~|^+++>?wz}v}v>~`. .... <br></br>
 ............................`````````.-?v??v````--~~||||||?v?>?vvvv?+~`.  <br></br>
 .............................``````````~v>??`...``-~~~~~--+??>+>????vv}?^.<br></br>
 .................................``````?v^?+`...```-~~~---|?>>++^|||+>?vw~<br></br>
 ...................................``.|vv??~..```````-----|>??+||||||^+>?-<br></br>
 ...................................``.^vvv>````````````---~^>>+|||~~||^++`<br></br>
                  ...................................``.?v+-```````````````-~~|||||~~~~||^^.<br></br>
 .....................................~v>-..```````````````--~~~~~~~--~~~|.<br></br>


            </div>,

            <div>

 ``--|^~~~~-~>vvv|...... . ..........~-`....      >>~|-.^. .```~^^^|-.     <br></br>
 ---^^^^+>>^|^>vzz>................. ~+|`.`.     .~>??`... ~`.```|>-       <br></br>
 --~+>>??vv?>vv}wxxv`..............```~|---.     `---|`....~|.....         <br></br>
.~~~+>???vvv??}wwzxxv+`   ..........`.```-`      ~~-````-^|^+~..           <br></br>
.-~~^+++^+?v?vvvvvrzz}v>~`..  ..........`..     .-``.``--~~-`.             <br></br>
.--~^^^|~~^>>???}r}}wwzz}v?>?`..........`..     ....~``...               ..<br></br>
 `---~||||||^+++>>?vvvvrzzr}zw+............     .......               ...`.<br></br>
 ``--~||||||^+++^|^+^+>?rzxxxxw?- ........      ...             ..^|`..... <br></br>
 ```----~~||^^^^^^^^+^+vrwrv}xuuz>........                     .```+^..... <br></br>
 `````-~--||^+>+v?^+??|+~^++|>?>>?v~. ``.                 .....`....`--... <br></br>
 -``~~+v????>???????>>?||++++++++^+?^.|+~+`          .`... .`...`..~?|.... <br></br>
 ```~|>vvvv|~---~~~-~^^~^|++++++++++++^++^+|.     .....`...`-``...`.`-~`.. <br></br>
 ...```~v>~.```--------`-`^++++++++^|+++++^^+.  .`....................^~.. <br></br>
                  ......-`..``````---~~~-``~||^+++++^^+^+++++^~..................`......~.. <br></br>
 ............``````````--`-~~?v>^++++|||^+++|-`..................`........ <br></br>
                  .....................````~?wzvv^^|^++++++++|~~........................... <br></br>
 ....................-rrrwzzzwvv?``~+++++++^|}+`.......................... <br></br>
 ....................`wwwwwwwrvv?~`^+++++++^+zx}+`........................ <br></br>
 .....................+wwwwxzwvv>`|>+++++++++>}xz?^....................... <br></br>
 ......................+wwwzwwv?``^+++++++++^|>}zxw?-..................... <br></br>
 .......................~vv}vv^``-|^+++++++^~~+??vvrv|`................... <br></br>
 .........................-~`....`--~~|+^||^|~-|^^+>?}v??^`............... <br></br>
 ...................................`-~-~~+>^`--~~|^^rxxxz?^|`............ <br></br>
 ..............................````..``-v>>??-`-~~|||>???}zwvv?|.......... <br></br>
 .............................``````````?v>>>+``-~~~|^+++>vwz}v}v+-`. .... <br></br>
 .............................```````````>v?v?```--~~||||||?}???vvv??^-..  <br></br>
 ..............................``````````-v>??`..``-~~~|~--+??>+>??vvvvv?|.<br></br>
 .................................``````.?v>>|...```-~~~~-`|?>>++^^|^+>?}w~<br></br>
 ...................................`````?vvv~.```````-----~>>?+^|||||^+??-<br></br>
 ...................................`````??>~.``````````---~^>>+||||~~|^++`<br></br>
 ...................................```.-v>`.`````````````-~~|||||~~~~||^+.<br></br>
                  .......................................+?-.```````````````--~~~~~~~--~~~|.<br></br>


            </div>,

            <div>

 ``-~|^|~~~-|?vvv|...... . ..........~-`....      >>-~-.|` ..``~^^^|-.     <br></br>
 ---|^^^^++^|^?vzz>................. ->|`.`.     .~>??-.`. --.``-|>`       <br></br>
 `-~+>>>?vv?>?v}wxxv`.................~^---.     `--~^`....~|.....         <br></br>
.~~~+>???vvv??}wwzxxv^`   ..........`````-`      ~~-`````^|^+~..           <br></br>
.~~~^>>+^+?v?v}vvvrzz}v+-`..  ..........`..     .-``.``--~|-`.             <br></br>
.--~^+^|~~^>>???}r}}wwzz}v?>>`..........`..     ....~``...               ..<br></br>
 `---~|||||^^+++???vv}vrzzr}zw>............     .......               ...`.<br></br>
 ``--~||||||^+++^|^+^+>?rzxxxxw>- ........      ...             ..^|`..... <br></br>
 ```---~~~||^^^^^^^^+^+v}wrv}xuuw>........                     .```+^`.... <br></br>
 `````-~--||^+>+v?^+??|+~^++|>?>>??~. ``.                 .....`....`--... <br></br>
 ```~~+v????>???????>>?||++++++++^+?^.~+~+`          .`... .`......-?^.... <br></br>
 ```~|>vvvv|----~~~-|^^~^|++++++++++++^++^+|.     .....`...`~``...`.--~`.. <br></br>
 ...```~v>~.```--------`~`^++++++++^|+++++^^+.  .`....................^~.. <br></br>
                  ......-`..``````---~~~-``~||^+++++^^+^+++++^~...`..............`......~.. <br></br>
 ............``````````--`--~?v>^++++|||^+++|-`..................`........ <br></br>
                  ...............```...````~?wzvv^^|^++++++++|~~......... ................. <br></br>
                  ....................-rrrwzzzwvv?``~+++++++^|r+`.......................... <br></br>
                  ....................`wwwwwwwrvv?-`^+++++++^+zxv+`........................ <br></br>
 .....................+wwwwxzwvv>`|>+++++++++>}uz?|....................... <br></br>
 ......................+wwwzwwv?``^+++++++++^|>}zxr?-..................... <br></br>
 .......................~vv}vv^``-|^+++++++^~~+??v}rv|.................... <br></br>
 .........................-~`....`--~~|+^||^|~-|^^+>v}v?>|`............... <br></br>
 ...................................`-~-~~+>^`--~~|^^}uxxz?^|`............ <br></br>
 ..............................````..``-v>>?>-`-~~|||>??v}zwvv?~.......... <br></br>
 .............................``````````?v>>?+``-~~~|^++>>vwz}v}v+-.. .... <br></br>
 .............................```````````>v?v?```--~~||^^|^?}???v}v?>^-.   <br></br>
 ............................````````````-v>??`..``-~~||~~-^??>+>??vvvvv?~ <br></br>
 .................................``````.?v>>|...```-~~~~-`~??>+++^^^+>?}w~<br></br>
 .................................```````?vvv~.``````------~+>?>^|||||^+?v-<br></br>
 ...................................`````??>~.`````````----~^>>+^|||~||^++`<br></br>
 ...................................```.-v>`.`````````````-~~|||||~~~~||^+.<br></br>
                  .......................................+?-.```````````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 ``~||^|~~~-|?v}v~...... ............-``...     ->?+`~~.~` ..-`~^|`        <br></br>
 ---|^+^^++^|^?vzz>..... ........... ->~`..     `~^???~.`. `-`````         <br></br>
 `-~+>+>?vv?>??vwxx?`.................~^~-`     ----~^-....-|..            <br></br>
.~~~+>???vvv??}wzzxxv^`.  ..........`````-`    .|||-```.`^~~~.           . <br></br>
.~~~^>>>++?vvv}vv}rzz}?+`..   ..........``.    .`~-`..`----.          .~-. <br></br>
.--~^+^^~~^>????vr}}rwzz}??^+......`....`..    .....~``..           ...`~~.<br></br>
 `---~|||||^^^++???v}}vrzw}vwr+...........     ......           .........`.<br></br>
 ``---~|||||^+++^|^^^+>?}zxzzxw>` ........    .`..           ....`+^`..... <br></br>
 ```--~~~~||^^^^^|^>+^+vrzzxuuuuz+........                ......``-++`.... <br></br>
 ```````--|^^^^^^>+|+>>?v??vvvzwrzv-....               .............``-`.. <br></br>
 `````-~>?>>++???vv+>?+^--^+^|++++vw>`~`~`          .``.....`......`>+.... <br></br>
 .`````~>vvv>^|~~|^^~|^>~^+++++++^^>>^^^^+^.      .....``. .~``...``-~-`.. <br></br>
 ......``>?+.``---``-----||+++++++++|++++^^>-  ..............`........^~.. <br></br>
                  .......``..`````---~~~~-`-^++++++++|+++++++|`...`..............`.....`|`. <br></br>
 ...........``````````----`~~^?v>^+++^||^+++^-...................`........ <br></br>
                  ...............```..`````.-?rrrv^^+++++++++^+~........  ................. <br></br>
 .....................```.|}xwwwv^-~+++++++|^z>........................... <br></br>
 .....................``-?wzzwwrv+`~+++++++^>zzv^`........................ <br></br>
 ......................~rwwwxww}v|`^+++++++++?ruz?|....................... <br></br>
 ......................-wwwwzww}>`-^++++++++^|>}zxr?-..................... <br></br>
 .......................|}wwrrv>``-|^++++++^~~+??v}rv|.................... <br></br>
 ........................`~|||`..````~~|^|||~-~^^++>v}?>+~................ <br></br>
 .................................`..``--^|+>~`-~~|^^}uxxz?|~`............ <br></br>
 ..............................`````````-??>?|`-~~|||>?vv}zrv??~.......... <br></br>
 .............................```````````->??v~`-~~~~^++>>vwzrv}?^`.. .... <br></br>
 .............................`````````````???+.`--~~||^^|^?rv??v}v?>~`.   <br></br>
 .............................``````````..^v?>-.```-~~||~~-^??>>>??vvvvv>~ <br></br>
 .................................```````.+vvv|..```-~~~~-`~??>+++^^^>?v}r-<br></br>
 .................................```````.-v?+`.`````------~+>>>^|||||^>?v~<br></br>
 ...................................``````~v^..````````----~^>>>^|||~||^+>`<br></br>
                  ...................................````..+?``````````````-~~|^|||~~~~~|^+.<br></br>
                  ........................................-??```````````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `-~||^|~~~-^?v}v~...... . ..........-``...     ->?+`--.-` ..--|^|`        <br></br>
 ---|^+^^^+^|^?vzw>..... ............->~`..     `~^???|.`` `-````.         <br></br>
 `--^>+>?vv?+??vrxx?`.................~^~``     ~---~+~.. .-|..            <br></br>
.~~~+>???v}v??}wzzxxv|`. ...........````--`    .+||-```.`^~~~.           . <br></br>
.~~~|>>>+>?vv?}vv}rzz}?^`..   ..........``.    .-~-`..`----.          .~~` <br></br>
.--~^+^^~~^>????vr}}wwzz}?>|^. ....`....`.     .....-`...           ...`~~.<br></br>
 `---~|||||^^^++??v}}r}rzw}vw}>...........     .......          ``.......`.<br></br>
 ``---||||||^+++^|^^+>>?}zxzzxw+` ........    .`..           ....->^`..... <br></br>
 ```--~~~~||^^^^^||>+++?rzzxuuuuz+.......                 ......``-++`.... <br></br>
 `````----~^^^^^^>+|+>>?v??vvvzwrzv-....               ............```-`.. <br></br>
 `````-~>?>>++???vv+>?+^--^+^|++++vw>.~`~`          .``.....`......`>>.... <br></br>
 ``````~>vvv>^|~~|^^~|^>~^+++++++^^>>^^^^+^.      .....``. .~``...``~~-`.. <br></br>
 ...`..``>?+.``---``-----||+++++++++|++++^^>-  ..............``.......^|.. <br></br>
                  .......``..`````---~~~~-`-^++++++++|+++++++|....`..............`.....`|`. <br></br>
 ...........`````````---~-`~~^?v>^+++^||^+++^~..................``........ <br></br>
                  ..............````..`````.-?rrrv^^+++++++++^+-........  ................. <br></br>
 .........................~}xwwwv^-~+++++++|^z?........................... <br></br>
 .......................`?wzzwwrv+`~+++++++^>zz?|`........................ <br></br>
 ......................~rwwwxww}v|`^+++++++++?ruz?~....................... <br></br>
 ......................-wwwwzww}>`-^++++++++^|>}zxr?`..................... <br></br>
 .......................|}wwrrv>``-|^++++++^~~+??vrrv|.................... <br></br>
 ........................`~|||`..````~~|^|||~-~^^++>vv?>^~................ <br></br>
 .................................`..``--^|+>~`-~~|^^}uuzz?|-`............ <br></br>
 ..............................`````````-??>?|`-~~|||?vvv}zrv?>-. ........ <br></br>
 .............................```````````->??v~`-~~~~^++>>vzzrv}?^`.. .... <br></br>
 .............................`````````````???+.`--~~|^^^^^?rv??v}v?+~`.   <br></br>
 ............................```````````..^v?>-.```-~~|||~-^??>>>??vvvv?>- <br></br>
 .................................```````.+vvv|.````-~~~~-`~??>++++^^>?v}r-<br></br>
 .................................```````.-v?+`.`````------~+>>>^^||||^>?v~<br></br>
 ...................................``````~v^..````````----~^>?>^||||||^+>`<br></br>
                  ...................................`````.+?``````````````-~~^^^||~~~~||^+.<br></br>
                  ........................................-??```````````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--||^^~~~~+?v}v~ .....   ..........-`...     .+??^``-.`` ..-~||.         <br></br>
.---|^+^|^^^|^?}zw+..... ............`>~`.     `-^+???^.`` .-``..          <br></br>
 `--^+++??v?+>??rzx?`.................~^~`     `|~--|>|.. .-|.             <br></br>
.-~~+>???v}v??vwzzxxv|`. ...........````--.   .`+||--``.`|-`.          ... <br></br>
.~~~|>>>>>?vv?vvv}wzz}?|`.    ..........``     `-|~``.`~-`.        ....||` <br></br>
.--~^++^~~^>??v?v}}}wwwz}?>~|. ....`....`.    ......-`..        ... ...`|~.<br></br>
 `---~|||||^^^++?vv}rr}rzw}vw}+...........    ........        .`~-.......`.<br></br>
 ``---~||||||+++^|^++>??}zxwzxw+` ........    .~..         ......`+^`..... <br></br>
 ```--~~~~|||^^^^^^++++?}wzxuuuuw^.......      .        .........-~+>-.... <br></br>
 ````-----~|^^+^^|^>++>v}}v}wzuuuuv` ....           ...............```-`.. <br></br>
 ```````-+?>>+^>?>v?^>?+|--^+^vv?vzz>....         ...``............`^>`... <br></br>
                  ````````|?vv?^^^^++++++^`+++++++^+??+||+^`     ......```. .~``...``~^``.. <br></br>
 ..```````~>>-``--```-~~~|^+++++++++^+++^|+^. ...............``.......||.. <br></br>
                  .........``.```----~~~|--`|++++++++|^+++++^~....`.............`......`|`. <br></br>
 ...........`````````---~-`-|^+>++++^^||^+++|~..................``........ <br></br>
                  ..............````..`````.`~||||^^+++++++++^?-........  ................. <br></br>
 ..........................~>?v?>~-~^++++++|^x?........................... <br></br>
 ........................`>wzwrvv?`-^++++++^>wz?~`........................ <br></br>
 .......................`?wzzwrvv?~~+++++++++>wuz?~....................... <br></br>
 .......................?wwwzwrvv?-~++++++++|~>vxx}>`..................... <br></br>
 ......................~rwwwww}v?-`-|^^^+++^|~^?v}wwv~.................... <br></br>
 ......................+}v}v}?+~``````-~|~-~-`~^++>?vv>^|-................ <br></br>
                  .......................`-~-`.....```````^++?^`-~||^+vxxzw?~`............. <br></br>
 ...............................`````````~???>+-~~|||>v}}rz}v?+-. ........ <br></br>
 ..............................````````````^vv?--~~~~^+>>>vzzr}v?|`.. .... <br></br>
 .............................`````````````^v+>``-~~~|^^^^^?w}??}}?>^-.. . <br></br>
 ............................``````````````?v?+..``-~~|||~-^??>>>?vvvvv?+` <br></br>
 .................................`````````^vv^..```-~~~~-`~>?>++++++>?v}r-<br></br>
 .................................`````````-v>`.`````------~+>>>^^||||^>?v~<br></br>
 ...................................``````.-?-.````````----~^>?>^||||||^+>`<br></br>
 ...................................`````.->?^.```````````-~|^^^||~~~~~|^+.<br></br>
                  ........................................-?v??`````````````---~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--|^+^~~~~+?v}v- .....   ..`.......``...     `>??^```.``...-~||.         <br></br>
.---|^+^|^^||^?}zw+..... ............`+~`.     `~^>???+.`- .-``..          <br></br>
 `--|+++>???+>??}zx?` .. .............~+~`    .-|~--|>|..  -|.             <br></br>
.--~+>???v}v??vwzzxxv~`. ...........````--    .-^||--`-.`~-`.          ... <br></br>
.~~~|+>>>>?vv?v}}}wzz}?|..    ..........``    .`-|~``.`--`.        .`..||` <br></br>
 --~^++^|~^>??vvv}}}wwwwv?+~~. .........`.    ......``..       ..`.....-|~.<br></br>
 `---||||||^^^++?vv}rr}rzw}?rv+...........    ........       ..~~-.......`.<br></br>
 ``---~||||||+>+^^^+>>??}zzwwxw^` ........    `~..         ......`^^`..... <br></br>
 ```--~~~||||^^^^^^++++?}wzxuuuuw^.......      .        .........-~>>-.... <br></br>
 ````-----~|^^+^^|^>++>?}rvrwxuuuu?` ....           ...............`-```.. <br></br>
 ```````-+?>>+^>?>v?^>?+^~-^++vvv}xz>`...         ...``.............|>`... <br></br>
 ````````~?vv?^^^^++++>++`+++++++|+?v+|~>|`     ......```. .~`.....`~^``.. <br></br>
 ..```````->?-``--```-~~~|^+++++++++^+++^|+^. ...............``.......||.. <br></br>
                  .........``.```----~~~|--`|++++++++|^+++++^~....`.............`......`^`. <br></br>
                  ...........`````````--~~-`-|^++++++^^||^+++|~..................``........ <br></br>
                  ..............`````.```````~~|||+++++++++++^?-........  ................. <br></br>
 ..........................->???+~-~^++++++|^z?........................... <br></br>
 .........................+wzw}vv?-`^++++++^>wz?~......................... <br></br>
 ........................>wzzwrvvv~~+++++++++>wuz?~....................... <br></br>
 .......................>wwwzwrvv?-~++++++++|~>vxx}>`..................... <br></br>
 ......................|wwwrww}v?-`-|^^^+++^|~^?v}wwv~.................... <br></br>
 ......................+r}}}r?+~``````-~|--~--~+++>?vv>^|`................ <br></br>
 .......................`-~-`.....```````^+>?^`-~||^+vxxww>-`............. <br></br>
 ...............................`````````~???>+-~~|||>}}rwz}??+`. ........ <br></br>
 ..............................````````````^v??--~~~~^+>>>vzzr}v?~... .... <br></br>
 .............................`````````````^v^>``-~~~|^^^^^?w}??}}?>|`.. . <br></br>
 ............................``````````````?v?+.```-~~|||~-^??>>>?vvvvv?^` <br></br>
 .................................`````````^vv^..```-~~~~-`~>?>++>+++>?v}r-<br></br>
 .................................````````.-?>`.`````--~----+>>>+^^|||^>?v~<br></br>
 ...................................``````.-?~.````````----~^>?>^||||||^+>`<br></br>
 ...................................`````.`>v+.```````````-~|^+^^|~~~~~|^+.<br></br>
                  ........................................-?vv?`````````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--|^+^|~~|>vv}v- .....   ..`.......``..    ..`>??^```......-|~.          <br></br>
.--~|^++||^||^?}zw+..... ............`+~`    ..`~+>???+``~ .``.          . <br></br>
 `--|+^^>???++>?}zx?`... .............~+~.   .`-|~--^>^..  .`        ..... <br></br>
.---^>???vvv??vwzzxxv~..............```--.    `-^^~--`-.``.         ..`... <br></br>
.~~~|+>>>>?vv?v}}}wzz}?~..    ..........`.   ..`~|~-`.``.         ..-..~~` <br></br>
 --~|++^|~^>??vvv}}}wwwwv>^--. .........`.   .......``.       ..``.....-|~.<br></br>
 `---||||~|^^++>?vv}rwrrzw}?rv^...........   ........     .....-|~. .....-.<br></br>
 ``---~||||||+>+^^^+>>??}zzwwxz^. ........   .`~.       .........`~|`..... <br></br>
 ```--~~~||||^^^^^|++^+?vwzxuuuxr|.......     ..      ...........-|>>-.... <br></br>
 ````-----~|^^+^^|^>>>>vrw}wxuuuuu?` ....          ................`~```.. <br></br>
 `````````|^>+^^+>>>?+>>?>|^+?rrrwuw+...         ....``.............~+`... <br></br>
 `````````~>?v?>^+>>>+>>>~`|+^^>++?}}|``~`     .......``.. .~-.....`|+``.. <br></br>
                  ..````````-~^+```````-~|~~+++++++^+++++^|^`  ...............``.......~|.. <br></br>
                  .........`.````-----~~~~`~|++++++++^^+++++^`....``............`......`^-. <br></br>
                  ..........``````````--~|~``|+++++++^^||^+++|`...................-........ <br></br>
 ..............`````.```````-|^+^+++++^^+++++}~......... ................. <br></br>
 ..........................`>ww}?^~|+++++++|?xv`.......................... <br></br>
 .........................`>xzrr}v^`|++++++^>wz>-......................... <br></br>
 ........................-^>xwwrrv>`|++++++++>wuz?~....................... <br></br>
 .......................`||^wwwrrv+`|+++++++^~>vxx}>`..................... <br></br>
 .......................-~|~?wwr}v^`~|||++^|~~^?vrzwv~.................... <br></br>
 .......................``---?vvv?~````-~---|~~++>>?vv>|~`................ <br></br>
 .............................~^?^.``````->>?+`-~||^+vxzrr>-`............. <br></br>
 ..................................```````|>??>-~~|||>}rwwz}??^`. ........ <br></br>
 ...............................````````````>v?|-~~~~^+>>?vzzr}v>-........ <br></br>
 .............................``````````````>?+|`-~~~|^^^^^?wr??}}?+~`.  . <br></br>
 .............................`````````````->v?^.``--~|||~~^??>>>?vvvv?>|` <br></br>
 .................................``````````~??-.```-~~~~~-->?>++>>>>??v}}-<br></br>
 .................................```````````>+``````--~----+>>>+^^|||^>?v~<br></br>
 ...................................```````.~>+````````----~^>?>^|||||^^+>`<br></br>
 ...................................``````.^??>~.`````````-~~^+^^||~~~~|^+.<br></br>
                  .........................................`|~+~-`..````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--~^+^|~|^>vv}?` ..... .  .`.......``..    ``-?v?^````.....-^~         . <br></br>
.--~|^++||^||^?rzw^..... ............`+~`    `--~+???>>``| .``.         .-.<br></br>
 `--~+^^+??>++>?vzz?`... .............|+~    ~^~|~--^?+..  .`       .`~~-. <br></br>
 ---^>>??v}v??vrzzxxv-..............```-~.   .--^^~~-`-.``         .`~|`.. <br></br>
 ~~~|+>?>??vv?v}}rwzz}?~.    ...........`.   ..`~^|-`.``.       ...`~`.-~` <br></br>
 ---|++^|~^???vvvv}rwwwwv>^-`. .........`.   .....`.`..      .`~-`.....-^|.<br></br>
 `--~|^||~|^^++>?}}}rwrwzw}?}v^...........   `..... .     ``..`||~. .....-.<br></br>
 ``---~||||||^>++^^>????}zzwwzz^. .......   ..`~.       `.``.....`~|`..... <br></br>
 ```--~~~||||^^^^||^+^+>vwzxuxux}|.......    ...     .......... .-|>>~.... <br></br>
 ```-----~~||^^+^^^>?>>vrwrwuuuuux?`.....          ................`|```.. <br></br>
 `````````-|?++^^>>>?++?v?>^?vwxxuuw+...         .....`.............-+-`.. <br></br>
 ``````````~?vv?^>>>?>+?>>-~^^+?>>vxz~`-|.    ........``.. .--.....`|>``.. <br></br>
 .````````.`->?-````---~|+~++++++^^>>^++^^+` ...............````..`...~|.. <br></br>
                  .........`.````-----~~~|-~|^+++++++^^+++++^`....``............`......`^-. <br></br>
                  ..........````````-`-~~|~-`~+++++++^^||^+++|-...................-......`. <br></br>
 ..............`````.```````-|+++++++^^^++++>w~......... ................. <br></br>
                  ...........................?zzr?+~^+++++++|?uv`.......................... <br></br>
 ..........................~uxwwrv+`~++++++^>wz>-......................... <br></br>
 ..........................|uzwwwv?`~++++++++>wuz?-....................... <br></br>
 ..........................`rwzww}v-~+++++++^~>vxzv>`..................... <br></br>
                  ...........................+zwwwr?--~||^+^|~~^?vwzwv~.................... <br></br>
 ............................?rrwr>`````~--~+|~+>>??vv+~-`................ <br></br>
 .............................^?v>`.``````>>?>`-~|^^+vzzr}>-.............. <br></br>
 ..................................```````->???--~|||>rwwzzv?>|`. ........ <br></br>
 ...............................`.``````````^??^-~~~~^>>>?vzwr}v>-........ <br></br>
 .............................`````````````.^?++`-~~~|^^+++?wwvv}}?^~`.  . <br></br>
 .............................`````````````.^vv?.``--~||||~^?v?>??vvvv?+~. <br></br>
 .................................```````````??|.```-~~~~~-->?>++>>>>??vr}`<br></br>
 .................................``````````.>?`.````--~--`-^?>>+^^|||^>?}~<br></br>
 ...................................```````.`??````````-----|>?>^||||||^+>`<br></br>
 ...................................``````.|>vv+.`````````-~|^++^||~~~~|^+`<br></br>
                  .........................................`--^^|`..````````---~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--~^++|~|+?v}}?` ..... .  .`.......`.      ``~?v?|`-``........      ...`.<br></br>
.--~|^++||^||+?rzr^..................`^.     `--~>???>>``|....       ....-.<br></br>
 `--~+^^+>?>+^>>vzz?`... .............|~.   .|^~|~-~+?>...         ..`~~-. <br></br>
 ---^>>??vvv?>vrwzxxv-..............```-.   ..-~|^~~-`~..         ..`|^-.. <br></br>
 ~~~|+>????vv?v}rrwzx}>-.   .............   ...`~^|~`...      ......|`.`-` <br></br>
 ---|+++^|^???vvvv}rwzrr?+|``. ..........   .....``...      .`-~-`.....-^|.<br></br>
 `--~|^||~|^++>>?}}}rwwwzr}?v?|..........   .`.....       ```.`~|~. .....-.<br></br>
 ``---~||||||^>+++^>????}zzwrzz^. .......   ..`-.      ...`-`..`.``~`..... <br></br>
 ```--~~|||||^^^^^|^+^+>vwzxxxxz}~.......   ....     ...``.......`|>+~.... <br></br>
 ```-----~~||^^+^^^>?>>?rwrzuuuuux?`.....         .................`^-.`.. <br></br>
 ``````----~+++^^++>??>?vv?+?vzxxuur^...        ......`.........`...`^~`.. <br></br>
 ``````````-+?v?>+>>??^>?+~`|^>???rxz^```     ........``..  `-...`..|?-... <br></br>
 .```````````|>^-`-`---~^+~|++^++^^>>|||^^^. ...............`-``..`...-|.. <br></br>
                  .........`.````-----~~~|--^^+++++++^^++^|^-.....``............`.......^-. <br></br>
                  ..........```````----~~||``~++++++++^^|^++^|-...................-......`. <br></br>
 ..............``````````````|^+>>^^++^^^+++?z~.......... ........`..... . <br></br>
 ...........................|>vr}?~~+++++++|?uv`.......................... <br></br>
 ..........................`vrwwr}?~~^++++++>wz>`......................... <br></br>
 ..........................-rzwww}v|`++++++++>wuw?-....................... <br></br>
 ..........................~wwwwwrv|-^++++++^~>vxzv+`..................... <br></br>
                  ..........................`?wwwwwv-`-~~^^|~~-^?vwzwv~.................... <br></br>
 ............................>vv}v^`````--~|+^~+>>??}v+--................. <br></br>
 .............................-~+~.```````+??>~-~|^^+?zw}v>`.............. <br></br>
 ..................................````````|+v?|-~|||+rzzzzv?>~........... <br></br>
 ..............................``.``````````-?>^-~~|~^>>??vzw}}v+`........ <br></br>
 .............................`````````````.`>??--~~~|^^+++?wwvv}}?^-..  . <br></br>
 .............................``````````````-+v?``---~||||~^?v?>??vvv??^-. <br></br>
 ................................````````````~?>.```-~~~~~-->?>++>>???vv}v`<br></br>
 .................................``````````.|?+-.```--~~---^?>>+^^|||+>?}~<br></br>
 ...................................```````.`>?|~`.````-----|>??+||||||^+>`<br></br>
 ...................................``````.`~^>~`````````--~~^++^||~~~||^+.<br></br>
                  ..........................................```-``.`````````---~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `--~^++|~|>?v}}?` ..... .  ``.......`.    .`--~?v?|`-``......      .`--`~.<br></br>
.--~|^++||^||+?rzr^..................`^.   `.`--|>?v?+>-`^.        .-||~-~.<br></br>
 `--~+^^^>?>^^+>vzz?`... ............ |~   .`^^~~~-~+?>...        .^|||~~` <br></br>
 `--|>>??vvv?>v}wzxxv`..............```-   ...-~|^~~--~..      ....--|^~.. <br></br>
 ~~~|+>????vv??}rrwzzv>-.   ............    ...`|^^~-`.     .`~`....|`..-` <br></br>
 `--|+++^|^?v?vvvv}rwzr}?^~`.. .........    `....``..      `||~~~`.....-^|.<br></br>
 `--~|^||~~^+>>>?}r}rwwwzrv?v?~.........    .`.....      .--```~|~` ....`-.<br></br>
 ``---~||||||^+++++>????}zzrrzz^. .......   ````.     ....`~`..`.``-`..... <br></br>
 ````-~~|||||^^^^||^+^+>vwzxxxxzv~.......   ...    .....``.......`|>+-.... <br></br>
 ```-----~~||^^+^^^+?>>?}wwzuuuuyx?......         .`.............`.`^~.`.. <br></br>
 ``````--~~~~>>^^^+>????vr?>?rxuuuur^. .        ......`.........`...`|~`.. <br></br>
 ````````--`~?vv?+?>??^+?>^-~^?}vrzxx>~`.     ........``..  `-...`..|?-... <br></br>
                  .```````````-+?~--`-~~~^++~++^^^^+vv~|+??|. ...............`-``..`..`-|.. <br></br>
 ..........`````---`-~~~||~^++++++^^^^+^|^>` ....``....................+-. <br></br>
 ...........``````----~~|^--~+++++>++^^^^+^|^-...................-......`. <br></br>
                  ..........`...``````````-```||^?vv^+^^|^+++vx|...................`....... <br></br>
 ...........................``>}w}v+^++++++|vuv-.......................... <br></br>
 ...........................`?uwwrv>-^++++++>rz+`......................... <br></br>
 ...........................?zzzwrv>`^++++++++wuw>-....................... <br></br>
 ..........................^zwwxww}>`|+^++++^~>vxzv+`..................... <br></br>
 ..........................|rwwwww}|``--|^~~~-^?vwzwv~.................... <br></br>
 ...........................`^>???|`````-`~^>>~+>>?v}v^``................. <br></br>
 ..................................`````-`~??>+~~|^+>?wrvv+`.............. <br></br>
 ................................```````````^?v>-~|||+rzzxzv>+~........... <br></br>
 ..............................``````````````?++~~~|~^>>??vzw}vv^`........ <br></br>
 .............................``````````````.^vv|-~~~|^++++?wwvv}v?|`..... <br></br>
 .............................````````````````??-``--~||||~|?v?>?vvvv?>^`. <br></br>
 ................................````````````.+?`.``-~~~~~--+?>++>>???vv}v`<br></br>
 .................................``````````.`?v+.```--~~---^?>>++^|||+>?}~<br></br>
 ...................................```````.`+???~.````-----|>??+|^||||^+?`<br></br>
 ...................................``````````~~-````````---~^>>^||~~~~|^+.<br></br>
                  ..............................................`.``````````--~~~~~~~---~||.<br></br>


            </div>,

            <div>

 `--~^++^~^>?v}}?` ..... .. `-........     .``-|?v?|--``.....     ..``-~~~.<br></br>
.--~|^+>^||||>vwzr|...... ............`    `.`--|>vv?^>-`^.       .`~||~-~.<br></br>
 `---^^^^+>>^|+>vzz?`.................`.   .`|^~~~~~>??...       .`+^||~~` <br></br>
 `--|>>>?vvv?>v}rzxxv`..............```.   ...-~~^~~--|.       ....-`|+|`. <br></br>
 ~~~|+>???vvv??}wwzxxv>-.  .............   ....`~+^~-`.     .-~`....|`..`` <br></br>
 ---~+++^|^?v?vvvv}rwzr}?|-... .........   .`....``..     .`|||~-`.....-^|.<br></br>
 `--~|^^|~~^+>>>?}r}}wwwzrv???-.........   ..`.....     ..--```~|~-  ...`-.<br></br>
 ``---~||||||^+++>+??vv?}zzrrzz+........   .````.     ....`~`..```.``..... <br></br>
 ```--~~|||||^++^^|^^^+>?rzxxxxzv~......   ....    .....``.......`|?+-.... <br></br>
 ```---~~~~||^^^+^^+?>>?}wwzuuuuyx?.....          .`.............```+|.`.. <br></br>
 ``-``---~~~~^++^^^>????}rv>?wxuuuur|. .        ......`.........`....-~-.. <br></br>
 ````````----^?v?>>???>+??+-~^?r}rzux?~.      ........``... `-...`..~?~.`. <br></br>
 .````````````|>^--`-~||^>+-~^^^^^>vv||+>+-  ...........`...`-``...`.`-|.. <br></br>
 .....``..```````-----~~~|~~+++++^|^^^+||>>`. ....`....................^~. <br></br>
 ..........```````---~~~|^`-~+++++>++^+^^+^^^-...................-......-. <br></br>
 ..............``````````-```||^+>^^++^|^+++vx|...................`....... <br></br>
 .................`........```>vv?|~+++++++|vuv-.......................... <br></br>
 ...........................`?xwrv?^`|++++++>wx+`......................... <br></br>
 ...........................>zzww}v?.~+++++++>zur>-....................... <br></br>
 ..........................~wwwzwrv?`~^^^+++^~>}xzv+`..................... <br></br>
 ..........................+wwwwwrv|``--~^~-~-|>vwxwv~.................... <br></br>
 ...........................~^+++^-```````~+>>-^>??v}v^``................. <br></br>
 ..................................````--`->??>~~|^+>?r}v?+`.............. <br></br>
 ...............................````````````~?v>-~|||+rxxxzv>+-........... <br></br>
 ..............................`````````````.+>+~~~|~|>???vzw}v?^`........ <br></br>
 .............................```````````````-?v|`~~~|^+++>?wz}v}v>~`. ... <br></br>
 .............................````````````````+?~`---~||||||?v?>?vvvv?>|`. <br></br>
 ...............................`````````````.~?|.``-~~~~~--+??>+>????vv}v`<br></br>
 .................................``````````.`>v>-```-~~~---|?>>++^|||+>?}~<br></br>
 ...................................`````````-++^-.````-----|>>?+^|||||^+?`<br></br>
 ...................................`````````````````````---~^>>+^|~~~||^+`<br></br>
                  ..............................................`..`````````--~~~~~~~--~~||.<br></br>


            </div>,

            <div>

 `---^++^|^>?v}v?` ..... .. `-........    ..``-^?v?~--``...` .`.  .~~``|||.<br></br>
.--~|^+>^||||>vwx}|...... .............   .`.`--|?vv>^+~.|.    . .^^^^|~-~.<br></br>
 `---^^^^+>>^|^>vzz>..................`   .``|^~~~~~>??`..      .`~>^||~~`.<br></br>
 `--~>>>?vvv?>vvrzxxv`..............```.  .-.`~|~^~~--|`      .....-`|+|`. <br></br>
 -~~|+>???vvv??}wwzxxv+`.  ..........`..  .`...`~+^|-`.    .-|~`....|`..`` <br></br>
 `-~~^+++|^?v?vvvvvrwzrv>~-..  .........   .`...``-`.    .`~^||~-`.....`+|.<br></br>
 `--~^^^|~~^+>???}r}}rwzzrv???`.........   ..`.....    ...`-```~|~-  ...`~.<br></br>
 ``---~||||||^+++>>?vvvv}zzr}zz+........   ..```.     ....`|`..```.``..... <br></br>
 ```--~~|||||^++^^|^^^+>?rzxxxxz?- .....   ....   ......``...... .|?+-.... <br></br>
 ````--~~~~|^^^^+^^+>>+>vwwzuuuuyx>.....          ``.............```+^.... <br></br>
 `````----~|||^+^^^+???v}rv?vwuuuuu}|. .        ................`....-~-.. <br></br>
 ````````--~~->v??+????+?v?|~^?rrwxux?~.      ........``... .-...`..~?|... <br></br>
 .```````````-->>~---|^|+?+~~|^|^^>v?^^>>^`  ...........`...`-``...`.`-|`. <br></br>
 ....```..`.`````------~~|^~^++++^|^^^^|^>?`. ....`....................^~. <br></br>
                  ...........``````---~~~|^-~~^+++++++^+^^++^^`...................`......-. <br></br>
                  ...........`.```````````-```|^^++++++^^^++^vx^...................`....... <br></br>
 .................`........```+v?+|^+++++++|}uv-.......................... <br></br>
                  ............................+xwr??~`|+++++^?zx+`......................... <br></br>
 ...........................|xxwrvv?``+++++++?xx}>`....................... <br></br>
 ..........................`rzzzwrv?--^+^+++^|?}xzv^...................... <br></br>
 ..........................>zwwwr}?|```-~|~-~~|>}zxw?-.................... <br></br>
 ..........................`^+^|-````-````-+>?-^>??vrv^``................. <br></br>
 ..................................```---``^???|~|^+>?rv??+`.............. <br></br>
 .................................`.`````````>?>-~~|^^rxxxzv+^-........... <br></br>
 ..............................`````````````.^?>|-~|~|>???vzw}v?|......... <br></br>
 ..............................```````````````??^`~~~|^+++>?wz}v}v>~`. ... <br></br>
 .............................```````````````.~v^`---~||||||?}???vvvv?+~.. <br></br>
 ...............................`````````````.`v?```-~~~~~--+??>+>????vvv?`<br></br>
 .................................```````````->vv+```--~~---|?>>>+^^|^+>?}~<br></br>
 ...................................``````````~~--`````-----|>>?+^|||||^+?-<br></br>
 ...................................```````````..````````---~^>>+^||~~||^+`<br></br>
                  ..............................................````````````--~~~~~~~~-~~||.<br></br>


            </div>,

            <div>

 `---|++^|+?v}}v?. ........ `-.......    ..```-+?v?~--`.....  .-..`~~``|^^.<br></br>
.--~|^+>+||^^>vwx}|...... ............    ....--|?vv>|^~.~..  .``-++^^|~-~.<br></br>
 `---^^^^^++^|^>vzz>..................    .``~^~~~~|>?v-..      .`|>>||~~-.<br></br>
 `--~>>>??vv?>?v}wxxv`...............`.   .-.`~|~^~~-~|`     ......--|+^-. <br></br>
 -~~~+>???v}v??}wwzxxv+`.  ..........`.   .`...`~+^|-`     .-|~`....|-`.`` <br></br>
 `-~~^>++^+?v?v}vv}rwz}v>~`..  ........   ......``-`.    .`|^^|~-`.....`+|.<br></br>
 `--~^^^|~~^>>???}r}}rwzzrv?>>`.........   ..`.....    ...`-``-~|~-. ...`~.<br></br>
 ``---~|||||^^+++>>?vvvvrzzr}ww>........   ..```.     ....`|`..`--`....... <br></br>
 ```--~~|||||^+++^|^^^+>?rzxzxxw?- .....   .....  ......``...... .~>+-.... <br></br>
 ```---~~~~||^^^+^^^>++>vwwzuuuuyz>.....   ..    .`-.............```++`... <br></br>
 `````----~|^^^^|^^>v??v}wv?vwuuuuu}~. .        ....`...........`....`--.. <br></br>
 ````````--~~~^>??>????>?v?^~+vwrwxux?~      .........`-... .`...`..-?^... <br></br>
 ``````````----|+^|~~|^^^?>|-~||^+?vv>>|~`.  ...........``...~``...`.`-~`. <br></br>
                  ....```.````````--`----~~^|~++^^^~^^|~|^?+.......`....................^~. <br></br>
                  ..........```````---~~~|^~`-^+++++++^+^^^^||`....`..............`......~` <br></br>
                  ...........```````````----``~^++++++^^|^+++vz^...................`....... <br></br>
 ...............`````.`....```|+^^^^+++++++|wy}~......... ................ <br></br>
 ............................~??vvv^`|+++++^>wx>`......................... <br></br>
 ...........................-?vwwrr}~`^++++++>zxv+`....................... <br></br>
 ..........................`>?vzwrr}^`|+++++^|?}xxv^...................... <br></br>
 ..........................|???wwr}v>~`-~|~-|~|>}zxw?-.................... <br></br>
 ...........................-~-^???+^~````->??-^??vvrv|`.................. <br></br>
 ................................`````----`|>??^|^^+>?}v?>|`.............. <br></br>
                  ..................................``````````+?+-~~|^^ruuxz?+|`........... <br></br>
 ..............................``.```````````~?>^-~|||???v}zw}v?|......... <br></br>
 .............................```````````````.|?^`~~~|^+++>vwz}v}v+-.. ... <br></br>
 .............................`````````````````++``--~||^^||?}???v}v??^-.. <br></br>
 ...............................```````````````??|``-~~~|~~-+??>>>??vvvvv?`<br></br>
 .................................````````````->>^```-~~~~-`|??>+++^^^+??r~<br></br>
 ...................................```````````````````-----~>>?>^|||||^>?-<br></br>
 ...................................```````````..````````---~^>>+^||~~||^+`<br></br>
                  .............................................`````````````--~||~~~~---~||.<br></br>


            </div>,

            <div>

 `---|++^|+?v}}v>. ........ `-.......   ...```~+?v?~--.....`  .?~-~~~``^++.<br></br>
.--~|^+>+||^+?vwz}|...... ....`......    ...`.--|?vv>|||```` .`^+++^^^|~-~.<br></br>
 `---|^^^^++^||>vwz>..................   ..``~|-~~||>?v~. .. ..``-|>>||~~-.<br></br>
 ``-~+>>>?vv?>?vvwxxv`................   .`-.`~|~^~--~^`    .~.....--|+^-. <br></br>
 -~~~+>???v}v??}wzzxxv^`.  ..........`.   -`...`~+^|-`    `~|^~`....~-`..` <br></br>
 `-~~^>>+^+?vvv}vv}rwz}v+-`..  ........   .`.`..``~`.   .`~|^^|~-`..``.`+|.<br></br>
 `--~^+^^~~^>>???vr}}rwzzrv?+>`.....`...  ...`.....   ....`-`--~|~-. ...`~.<br></br>
 ``---~|||||^^+++???vv}vrzz}vww>........  ...```.   ......`^`..`--`....... <br></br>
 ```--~~|||||^+++^|^^^+>?}zxzzxw>- .....   .....  ......`-........->+-.... <br></br>
 ```---~~~~||^^^+^^^>++>vrzzuuuuuz+.....   ..    .`-.............``->+`... <br></br>
 `````----~|^+^||^^>vv??}w}?vzuuuuuv~. .       .................`...``--`. <br></br>
                  ````````--~|^|-????v????vv^|+vzwzxux?-      .........`-... .`......`?+... <br></br>
 ``````````--~--~>+|||++^>?^~~|^^+?}r}v~`.   ...........``...~``...`.-~~`. <br></br>
 ....```.````````````---~|^>|^^|||~^^~~^+?^...................`........^~. <br></br>
                  ...........``````---~~~|^|-~^++++^++^+||+^~-`....`..............`.....`~` <br></br>
 ...........```````````--~~``-+++++++^^|^+++}r^...................`....... <br></br>
 ...............`````.``...``-|||+^^^+++++^^xy}~......... ................ <br></br>
 ...........................```^?}v+`~++++++>ru>.......................... <br></br>
                  .............................|wwwww+.|+++++++zxv+`....................... <br></br>
 .............................>xwwwr?--^++++^|?rxz?|...................... <br></br>
 .............................~wzzww}>``-~~-++|>vzxw?-.................... <br></br>
 ..............................>}rrv?+-```->??|^??v}rv|................... <br></br>
 ...............................`--````---`-|>v+|^++>?}??>|`.............. <br></br>
 ...................................`````````~?>~~~|^^}uuxz?^~`........... <br></br>
 ...............................`.````````````>?+-~|||??vv}zwvv?~......... <br></br>
 ..............................````````````````>+`~~~~^++>>vwzrv}v+-.. ... <br></br>
 .............................````````````````.-?``--~||^^||?}v??v}v?>|`.  <br></br>
 ...............................```````````````>}?-`--~~|~~-^??>>>??vvvvv?.<br></br>
 .................................```````````..~^+|``-~~~~--~>?>+++^^^>?vr~<br></br>
 ...................................`````````....`..```-----~+>?>^|||||^>?-<br></br>
 ...................................`````````````````````---~^>>>^||~~||^+`<br></br>
                  ............................................``.``.````````--~|||~~~---~||.<br></br>


            </div>,

            <div>

 `---~^+^^>?v}}v>. ..... .. `-.......   ...```~>?v?---`.....  `?~-~~|``+>>.<br></br>
.--~|^+>>^^^+?}zx}~...... ...``......    ...`.--|?}v>|||`.`. .`++++^^^|~-~.<br></br>
 `---|^^^^^+^||>vzz>..... ...........    ..``-|-~~|^??v|. .. ````~^>>||~--.<br></br>
 `---+>+>?vv?>??vwxxv`................   .`-.`~^~^~--~^.    .~.....-~|++~. <br></br>
 -~~~+>???v}v??}wzzxxv^`.  ..........`.   --...`-+^|-`    `~|+|`....~-``.. <br></br>
 `-~~^>>>++?vvv}vv}rzz}?+`..   ........   .`.`..`-~-.   .`~^^^|~-`..``..^|.<br></br>
 `--~^+^^~~^>????vr}}rwzz}??^+. ....`...  .........   .`..``---~|~-. ...`~.<br></br>
 ``---~|||||^^^++???v}rvrzzrvwr>........  ...```.   ......`^`..`~~`....... <br></br>
 ```---~|||||^+++^|^^++>?}zzzzxw>` .....   ..`..   .....`-...`....`>+-.... <br></br>
 ````--~~~~||^^^+^^^>+++?rzzuuuuuz+.....   ..    ..-.............`--+>`... <br></br>
 `````----~|^^^||^^+vv??}w}v}zuuuuuv-. .       .....................```-`. <br></br>
 ```````---~|^|->?>>vv?v?vv+|>vzzzxux?-       ........`-.....`......`>>... <br></br>
 ``````````--~~-|?>|||>>^+?^~~|+^>?}r}v~`.   ...........``...~``...``-~-`. <br></br>
 ....````````````````---~|^>~^^|||~^^|||^>|...................``.......||. <br></br>
                  ..........```````---~~~|^|-~^++++^++^+^^+^~|` ...`..............`......|` <br></br>
                  ...........``````````---~~``-+++++++^^|^+++ww^..................``....... <br></br>
                  ...............````````...```||^+^^^+++++^^xyr~......... ................ <br></br>
 ...........................```^???^`~++++++>ru?.......................... <br></br>
                  .............................|zwwww+.|+++++++zzv^`....................... <br></br>
 .............................>xwwwrv--^++++^|?rxx?|...................... <br></br>
 .............................~wzzwwv|``-~~-^^|>vzxr?-.................... <br></br>
 ..............................?wwr?^~--```>??|^??vrwv|................... <br></br>
 ..............................->^~````---`-^?v+|^++?v}?>^~............... <br></br>
 ..................................``````````|?+~~~|^+}uuzz?|-`........... <br></br>
 ...............................`.````````````>?^-~|||?vvv}zrv??~......... <br></br>
 ..............................````````````````?+`~~~~^++>>vwzrv}?^`.. ... <br></br>
 .............................````````````````.|>``--~||^^^^?rv??v}v?>~`.  <br></br>
 ...............................```````````````?}>-`--~~|~~-^??>>>??vvvvv>.<br></br>
 .................................`````````````~^|-``-~~~~-`~>?>++++^^>?vr~<br></br>
 ..................................``````````.......``------~+>>>^|||||^>?-<br></br>
 ...................................````````````````````----~^>>>^|||~|^^+`<br></br>
                  ............................................``````````````--~|||||~~--~||.<br></br>


            </div>,

            <div>

 ``--~^++^>vv}}v+. ..... .  `-......    ...```~>?v?---`.....  -?~-~~|`->??`<br></br>
.--~|^+?>^^+>?}zz}~...... ...``......    ...`.-~^?}v>|~~`.-. .-+++^^^^|~-~.<br></br>
 `---|^+^|^^|~|>vzz>..... ...........    ...``~-~~|+??v^. ...-`.`~^>>^|~--.<br></br>
 `---^>++?vv?+??vrxx?`................   .`-``|^~^~--|^.    `|..`..-~|+^~` <br></br>
 `--~+>???v}v??vwzzxxv^`. ...........`.  .--...`-+^|-.    -~|^|`....---`.. <br></br>
 `-~~|>>>+>?vv?}vv}rzz}?^`..   ........   .`.`..`-|-.   .-~^^^|~-`..`-..^|.<br></br>
 `---^+^^|~|>????v}}}rwwz}?>|^. ....`...  ...`.....   .`..``-~~~|~-.  ..`~.<br></br>
 ``---~|||||^^^++???v}rvrzw}vwr>........  ...```.   .......^-..`~~-....... <br></br>
 ```---~||||||+++^|^^+>>?}zzwzxz+` .....   ..`..    .....-...`....`++`...` <br></br>
 ````--~~~~||^^^+^^^++++?}wzxuuuuw^.....   ..    ..-.............`--+>-... <br></br>
                  ``````---~|^^+^|^^+????vwrvrxuuuuuv-...        ....................```-`. <br></br>
 ```````--~~|^^-+?>?vv?v?vv>|>vzzzxuz?`.      ........`-............`+>`.. <br></br>
 ``````````-~~~-|??^|^>?^+?^~-|++>vrwvv~..   ..........```. .~``...``~|-`. <br></br>
 ...`````````````-`````-~|+>|^^|||~^+|+^^>| ..................``.......||. <br></br>
                  ..........```````---~~~|^^-~^++++^++^++++^-^` ...`.............`......`|` <br></br>
                  ...........``````````---~~-``+++++++^^|^+++xw^..................``....... <br></br>
                  ................```````..````||^++^^+++++^^xyr~......... ................ <br></br>
 ...........................```|+?>|`~++++++>ru?`......................... <br></br>
 .............................~zwwwr>.|+++++++zz?|`....................... <br></br>
                  .............................+uwwwrv--^++++^|?rux?|...................... <br></br>
 .............................~wwzwr?```-~~-~||>vxxr?-.................... <br></br>
 ..............................}ww}>-`---``>??|^??vrwv~................... <br></br>
 ..............................^}?|.```---`-^?v+|^+>?vv?+^-............... <br></br>
                  ..................................``````````|?+~~~|^+vuxzz?|-............ <br></br>
 .................................````````````>v^-~|||>vv}rzrv?>-......... <br></br>
 ...............................```````````````?^`~~~~^++>>vzzr}}?|`.. ... <br></br>
 ..............................```````````````.+>`--~~|^^^^^?rv??}}v?+~`.  <br></br>
 ...............................``````````````~vv>``--~~||~-^??>>>?vvvvv?>.<br></br>
 .................................`````````````~+|-``-~~~~-`~>?>++++++>?vr~<br></br>
 ...................................`````````...`...``------~+>>>^^||||^>?-<br></br>
 ...................................````````````````````----~^>?>^||||||^+`<br></br>
                  ............................................``.`..````````--~|||||~~--~||.<br></br>


            </div>,

            <div>

 ``--~^+++>v}}}v^. ..... .  .-......    ...```-??v?`--`.....  ~?~-~||`->??`<br></br>
.--~|^+>>^^+>?rzzv~..........``......    ...`.-~^v}v>~~~`.-. .->>+^^^||~-~.<br></br>
 `---~^+^|^^|~|>vzz>..... ...........    ...``~--~^+???+. ...-`.`~+>>^|~--.<br></br>
 `---|>++>???+>??rzx?`................   .````|^~^~--|^.    `|..`..-||^^|` <br></br>
 `---+>???v}v??vwzzxxv|`. ...........`.   --`..--+^|-.    `-~^^`....----.. <br></br>
 `-~~|+>>>>?vv?vvv}rzz}?^`.    ........   .`.`..`~|-.   .-~^^^||-`..`~..|~ <br></br>
 `---^++^|~|>??vvv}}}rwwz}?>~|. ....`...  ...`.....   ....``-~~~|~-.  ..-|.<br></br>
 ``---||||||^^^++?vv}rr}rzwr?r}>........  .`.```.   .......^-..`~~-....... <br></br>
 ```---~||||||+>+^^^++>??}zzwwxz+` .....   ..`..    .....`.```....`^+`...` <br></br>
 ````--~~~~|||^^+^^|++++?}wzxuuuuw^.....   ..    ..-`.............-~+>~... <br></br>
                  `````----~|^^^^|^^^????vwrvrxuuuuuv`...        ....................`-```. <br></br>
 ```````--~~|^^-^?>>vv?v?vv>^>}xxxuuz?`       ........`-.............|>`.. <br></br>
 ``````````-~~~-~??+|^>?^+?^~-|>>>vrwv}~`.   ..........```. .~-`....`~^``. <br></br>
 ...`````````````--````--~^>~|^|||~^+|+^^>^ ..................``.......~|. <br></br>
                  ...........``````----~~|^^-~^++++^++^++^^^-|` ...``............`......`^` <br></br>
                  ...........``````````---~~-``+++++++^^|^+++xr^..................``....... <br></br>
                  ...............``````````````||^+^^^+++++^^zyw~......... ..`............. <br></br>
 ...........................```|>?>^--++++++>rx?`......................... <br></br>
 .............................^zwwwr>.~+++++++zz?~`....................... <br></br>
                  ............................-?xwwwrv--^++++^|?wuz?~...................... <br></br>
 ............................`^wwwrr?```-~~-~^|>vxxr?-.................... <br></br>
 ..............................?}}v+``---``>??|^?v}wwv~................... <br></br>
 ..............................|?>-.``----`-^?v+^++>?vv>^|-............... <br></br>
                  ..................................``````````~?+~~~|^+vxxww?~`............ <br></br>
 .................................````````````>v^-~|||>}}rwzrv?+-. ....... <br></br>
 ...............................```````````````?^`~~~~^+>>>vzzr}v?|`.. ... <br></br>
 ..............................```````````````.+>``--~|^^^^^?w}??}}v>^-..  <br></br>
 ...............................``````````````~vv>``--~|||~~^??>>>?vvvvv?^.<br></br>
 .................................`````````````~^~-``-~~~~~-->?>++++++>?vr-<br></br>
 ...................................`````````...`...``--~----+>>>+^||||^>?-<br></br>
 ...................................````````````````````----~^>?>^||||||^+.<br></br>
                  ............................................``.`..````````--~|^|||~~--~||.<br></br>


            </div>,

            <div>

 ``--~|^+>?v}}}?|. ..... .  .`......    ..```--??v?`-`......  ^?~-~||~|?v?`<br></br>
.--~~^>>>^^>?vrzzv-..........``......    ...``-~^vrv>~~-`.-. .~?>+^^^|~~~~.<br></br>
 `--~~^+^||^|~|?}zw+..... ...........    ...``~--~^>???>. ...````~+>>^|~--.<br></br>
 `---|+++>???+>??}zx?`... ............   .````|^~|~--|+.    .^..`..-||^^|- <br></br>
 `---^>???v}v??vwzzxxv~`.............``   --`..--^^|-.    `-~^^`....--~~`. <br></br>
 `-~~|+>>>>?vv?v}}}wzz}?|..    ........   ``.`..`~^~.   .--^^^||-`..`~..~~.<br></br>
 `---|++^|~|>??vvv}}}rwwwv?+~~. ........  ...`.....   ..````~|~~~~-.  ..`|.<br></br>
 ``---||||||^^^+>?vv}rwrrzw}?}v+........  .`..`..    ......^-..`||~. ......<br></br>
 ```---~||||||+>+^^^+>>??}zzwwzz+` .....   ..`..    .....`````....`|^-...`.<br></br>
 ````--~~~~|||^^+^^|++^+>vwzxuuuxw^.....   ...   .`-`.............-~>>~... <br></br>
 `````----~~|^^^^^^^????vrw}rxuuuuuv`...        ....................`~``-. <br></br>
 ```````--~~|^^~~??>?v?vvvv?^>}xxxuuz>.       ........``.............~>`.. <br></br>
 ``````````-~||-~>v>^^+?+^?>|-|???vwz}}^`.   ..........```. .~-.....`~+``. <br></br>
 ...`````````````--`-``--~+>+~^^|||^>|+^+>>. .................```......~^. <br></br>
                  ...........``````--`-~~|^^~-^+++++^+^^+^^+-`. ...``............`.......^- <br></br>
                  ............```````----~||-``^+++++++^|^+++zr^...................-....... <br></br>
                  ................`````````````~||++^^+++++^^wyz~......... ..`............. <br></br>
 ...........................```^v}v?|`^++++++vxv`......................... <br></br>
                  ............................`?xwwwvv--++++++^wz?~........................ <br></br>
                  ............................vzzzwrvv^`|^^++^~?wuz?~...................... <br></br>
 ............................+rwr}vv?~-``~|--^|>vxx}?`.................... <br></br>
 .............................`~|~-~-`---``+??^|?v}wwv|................... <br></br>
 ...................................``------|>v>|++>?vv>|~`............... <br></br>
 .................................``````-----`?>|~||^+vxxww?~`............ <br></br>
 .................................````````````^v>-~|||>}rwwz}??+`. ....... <br></br>
 ...............................``````````````.>>`~~~~^+>>>vzzr}v?~... ... <br></br>
 ..............................````````````````+?``-~~|^^^^^?wr??}}v>|`..  <br></br>
 ..............................```````````````|?v?-`--~|||~~^??>>>?vvvv?>^.<br></br>
 .................................````````````.-|~```-~~~~~-->?>++>>>>>?vr~<br></br>
 ..................................``````````...`...``--~----^>>>+^||||^>?-<br></br>
 ...................................````````````````````----~^>?>^||||||^+`<br></br>
                  ............................................````..`````````-|^+^||~~--~||.<br></br>


            </div>,

            <div>

 ``---|^+??v}}}?~. ..... .  .`......    .````--????`--......  +?--~||~^?vv`<br></br>
.--~~^+>>+^>?vwzzv-..........`-......    ...``-~+vrv+~~-`.-. .~?>^^^^|~~||.<br></br>
 `--~~|++||||~|?}zw+..... ...........    ...`.---~+????>. . .`-``|+>>^|~--.<br></br>
 `---~++^+???++>?vzx?`... ............   .````^^~|~-~^+.    .^..`.`-^|^^|- <br></br>
 `---^>>??vvv??vrzzxxv~..............``   --`..-~^^|-.    ``-|^`....--~|`. <br></br>
 `-~~|+>?>>?vv?v}}rwzx}?~..    ........   ``....`~^~.   `--^^^||-`...|`.-~.<br></br>
 `---|++^|~|>??vvvv}}wwwwv>^--. ........  ...`.....   `.````~||~~--.....-|.<br></br>
 ``--~||||~|^^++>?vv}rwrrzw}?}v^........  .`..`..    .`....|-..`||~. ......<br></br>
 ```---~~|||||^>++^^>>???}zzrrzz+. .....   ..`..    .....````.....`~^`...`.<br></br>
 ````--~~~||||^^+^^|^+^+>vwzxuuxxr|.....   ...   .```.............-|>>~... <br></br>
 `````----~~|^^^^^^^>?>?vrw}wxuuuux?` ..        ...`................`|``-. <br></br>
 ```````--~~|^^~~??>?v??vv}?^?}xxxuuz>.       .........`.........`...-+-`. <br></br>
 ``````````-~||~~>v>^^>?+^?>|~|???vwz}}|`.   ..........``.. .--.....`~>``. <br></br>
 ..``````````````--`-``--~+>+~^^|||^>|+^+>>. .................```..`...~^. <br></br>
                  ...........``````--`-~~~^+~-^+++++^+^^+^^+-`. ...``............`.......^- <br></br>
 ............``````-----~||-``^+++++++^|^+++z}|...................-....... <br></br>
                  ...............``````````````~||^^^^+++++^^wyz|.........  .`............  <br></br>
 ...........................```^v}v?|`^++++++vxv`......................... <br></br>
 ............................`?xwwwvv--++++++^wz>-........................ <br></br>
                  ............................?zzzwrvv^`|^^++^~?wuz?~...................... <br></br>
 ............................+}rr}vv?~-``~|--^|>vxx}>`.................... <br></br>
                  .............................`-|~-~-`---``+??^|?vrzwv~................... <br></br>
 ...................................``------|>v>^+>>?vv>~~`............... <br></br>
 .................................``````-----`?>|~||^+?zzrr?-`............ <br></br>
 .................................````````````^v>-~|||+}wwzz}??^`. ....... <br></br>
 ...............................``````````````.>>`~~~~^+>>?vzzr}v>-....... <br></br>
 ..............................````````````````+?``-~~|^^^++?wrvv}}?+|`.   <br></br>
 ..............................```````````````|?v?-`--~||||~|?v?>??vvvv?>|.<br></br>
 .................................````````````.-|~```-~~~~~-->?>++>>>>??vr~<br></br>
 ..................................``````````...`...``--~~-`-^?>>+^^|||^>?-<br></br>
 ...................................````````````````````----~|>?>^||||||^+`<br></br>
                  ............................................`````.`````````-|^+^||~~--~||.<br></br>


            </div>,

            <div>

 ``---|^+??v}}v?~. .......  .`......    .`-``--????`--.....   >?--~^||^?vv-<br></br>
.--~~^>>>+^>?vwzzv- .........`-......    ...``-|+vrv+~~-`..  .|??^^^^|~|^^.<br></br>
 `--~~|++||||~^?rzw+.................    ...`.---~+???>|  . .`-``|+>>^^|``.<br></br>
 `---~++^+>?>+^>>vzx?`... ............   .````|^~|~-~^|     .^`.```-+|^^~- <br></br>
 `---^>>??vvv?>vrzzxxv~..............``   ---..-~|^|-.    ```|^`....--~|-. <br></br>
 `-~~|+>????vv?v}}rwzx}?~..   .........   ``....`~^~.   `--^^^^|-....|`.`-.<br></br>
 ``--|+++^|^>??vvvv}}wzrrv>^-`. ........  ...`.....   ````-`~||~~~`.....-|.<br></br>
 ``--~|^||~|^+++>?}}}rwwwzw}?vv^........  ....`..    .`....~~`.`~|~` ....`.<br></br>
 ```---~~|||||^>++^^>????}zzrrzz+. .....   ..`..    .. ..`.`-`..`.``|`.....<br></br>
 ````--~~~||||^^+^^|^+^+>vwzxxxxx}|.....   ...   .```....``.......`|>>~... <br></br>
 ````-----~~|^^^^^^^>?>>?rwrwuuuuux?` ..        ...``...............`^-.`. <br></br>
 ```````--~~|^^^~>?+?vv?vv}?+?}xuxuuw>.       .........`.........`...`^~`. <br></br>
 `````````--~||~-+v?>+>?>^??^~|?v?vwxw}^-.   ..........``..  --...`.`~>-`. <br></br>
 ..`````````````-`~---`-~~^>>|^^|||^?^>^+?>..................````..`...-^. <br></br>
                  ..........``````--``-~~~^+|-~+++++^+^^+^^+|`.....``............`.......^- <br></br>
 ............``````----~~|^~``|+++++++^|^++^r}~...................-......` <br></br>
 ...............``````````````-^}}v+|+++++^|wyx|.........  .`......`.....  <br></br>
 ............................-`vzww}|`|++++++}xv-......................... <br></br>
 .............................`rzwwr?`-++++++^wz>-........................ <br></br>
                  ..............................vzwwwv^`|^^++^|?zuz?~...................... <br></br>
 ..............................^}wrv>~-``-~~^>|>vxx}>`.................... <br></br>
                  ...............................`~-```---``+?v+^?vrzwv~................... <br></br>
 ..................................```------~+v?^>>??vv+--`............... <br></br>
 .................................``````-----`?>|~||^+?zw}}>-............. <br></br>
 .................................````````````^v+-~|||+rwzzz}?>|`. ....... <br></br>
 ...............................``````````````.>+`~~~~^>>>?vzzr}v>-....... <br></br>
 ..............................````````````````>?``~~~|^^+++?wwvv}}?+~`.   <br></br>
 ..............................```````````````^?v>``--~||||~|?v?>??vvvv?+~.<br></br>
 .................................```````````.`-~-``---~~~~--+?>++>>>???vr~<br></br>
 ..................................```````````..`..```--~~-`-^?>>+^^|||^>?-<br></br>
 ...................................````````````````````-----|>??+^|||||^>`<br></br>
                  ............................................````..`````````-~^++||~~~-~||.<br></br>


            </div>,

            <div>

 ```--|^+??v}}v?-. ..... .   `......    .`-..-`?vv?```.....   ??--~^|^+?vv-<br></br>
.--~~^+>>++>v}zxzv- .........`-......    ...``-|>vw?+~~-`.. ..|??^^^^|~|++.<br></br>
 `--~~^+>||||~^?rxw+.................    ...`.`--~+?v?+|  . .```-|+>>+^|-`.<br></br>
 `---~+^^^>?>+^+>vzx?`... ............   .````|^~~~~~^|     .^`.```-+^||~~.<br></br>
 `---|>>??vvv?>v}wzxxv-..............``   ---..-|~^~-.    ```|^`....--|^~` <br></br>
 `-~~~+>????vv??}rrwzx}?~.   ..........   `-....`~+~.   `--^^^^|-....^`.`-.<br></br>
 ``--|+++^|^>v?vvvv}}wzrrv+|`.. ........  ...`.....   ..``--~^||~~`.....`^.<br></br>
 ``--~|^||~~|++>>?}}}rwwwzw}??v|........  ....`..    .`....-~`.`~|~` ....`.<br></br>
 ```---~~|||||^+++++>????}zzr}zz+.......   ..``.    .. ..`.`~`..`.``~`.... <br></br>
 ````--~~~||||^^+^^|^+^+>?rzxxxxzv|.....   ...   .```....``.......`|>>~... <br></br>
 ````-----~~|^^^^^^^+?>>?}wwwuuuuyx?` ..        ...``.............`.`^~.`. <br></br>
 ```````--~~|^^^~>?+?v??vv}?+?rxuuuuw+.       .........`.........`...`|~`. <br></br>
 `````````--~||~-+v?>+>?>^??^~|?vv}zxw}|-.   ..........``... `-...`..~?-.. <br></br>
 ..````````````--`~---`-~~^>>|^^|||^?^>^>?>..................`-``..`...-^. <br></br>
                  ..........``````--``-~~~^+|-~+++++^+^^^^^+|`.....``....................^~ <br></br>
 ............``````----~~|^|``|+++++++^|^++^r}~...................-......` <br></br>
 ..............```````````````-^}}v+|+++++^|wyx^.........  ........`...... <br></br>
 ............................-`vzww}|`^++++++}x}-......................... <br></br>
 .............................`rzwwr?`-++++++^wx>`........................ <br></br>
                  ..............................vzwwwv^`|^^++^|?wuw?-...................... <br></br>
 ..............................^}wr?>~-``-~~^>|>vxxv>`.................... <br></br>
 ...............................`~-```---``+?v+|>vwzwv~................... <br></br>
 ..................................```------~+v?^>>?v}v+-`................ <br></br>
 .................................``````-----`?+|~|^^+?wr}v>-............. <br></br>
 .................................````````````^v+-~|||^rzzzzv?>|`......... <br></br>
 ...............................``````````````.>+`~~~~|>>??vzzr}v>`....... <br></br>
 ..............................````````````````>?``~~~|^^+++?wwvv}}?^-..   <br></br>
 ..............................```````````````^?v>``--~||||~|?v?>??vvvv?^- <br></br>
 ................................````````````.`-~-```--~~~~--+??++>>???vvr~<br></br>
 ..................................```````````..`..```--~~--`|?>>+^^|||^>?~<br></br>
 ...................................````````````````````-----|>>?+^|||||^>`<br></br>
 ............................................````..````````--~^+>^||~~~~||.<br></br>


            </div>,

            <div>

 ```--|^+??v}}v>-. ..... ... .......    .`|`.-`?vv>-``........??--~|^+>vvv-<br></br>
.--~~|+>?>+?vrzxw?` .........`-.....    ....``-^>vw?^~~``... .+??^|^^^~|>>`<br></br>
 `--~|^+>^||||^?rxw+...... ..........   .`..`.`-`~>?v?^-.... .`--^++++^|-`.<br></br>
 `----^^^^+>>+^+>vzx?`................   ``.``|^~~~~~+~      |`.```~++||~~.<br></br>
 ``--|>>??vvv?>v}wzxx}-..............`.  .--~..-|~+~`     .``~|`....-`|+|` <br></br>
 `-~~~+>????vv??}rwwzx}?~.  ...........   `-`...`~+~   .`--^|^^|-....^-..`.<br></br>
 `---|^++^|^>v?vvvvv}wzr}?^~`.. .......   ...`...`.   ..`---~^||~~`.....`^.<br></br>
 ``--~|^||~~|+>>>?}r}}wwwzwv???~........  ....`..    .-....`~```~^~-  ...`.<br></br>
 ```---~~|||||^+++++>??v?}zzr}zz>.......   ...`.    .. ....`~`..````-`.... <br></br>
 ````--~~~||||^^+^^|^+^++?rzxxxxzv|.....   ....  .`......``...... `|>>~`.. <br></br>
 ````----~~~|^^^+^^^+?>>?}wwzuuuuyx?` ..   .    ...``.............```+|.`. <br></br>
 ```````--~~|^++||>^?vv?v}rv>?rxuuuuw^.       .........`.........`....-~`. <br></br>
 `````````--~|^|-^vv?????+??^~|?}}rzuwv~.    ..........``... .-...`..~?|.` <br></br>
 ..``````````----`~|~-`~|~^?>~||||^>?||>?>^..................`-``...`.``^` <br></br>
                  ..........``````---`-~~~|+^-~++++^^+^^^|^+`.......`....................^~ <br></br>
                  ...........```````----~~|^|``|++++++^^^^+++zv~...................`......- <br></br>
                  .............````````````--``~+^|^^^+++++^+uyx+.......... ........`...... <br></br>
 ............................~}wrv?+--++++++?zu}~......................... <br></br>
 ............................+xzwww}>.~++++++?xx>`........................ <br></br>
                  ............................-rwwwrv|`-^++++^|vzuw?-...................... <br></br>
 .............................|v}v?~---``~||^+^?vxxv+`.................... <br></br>
 ..............................`-```----```+??|^>vwxwv~................... <br></br>
 .................................````------|?v++>??v}v+``................ <br></br>
 .................................``````-----|?+~||^+>?r}v?>`............. <br></br>
 .................................``````````-`?v|-~|||^rxxxzv>+~.......... <br></br>
 ................................`````````````~v|-~~|~|>>??vzzr}v+`....... <br></br>
 ..............................```````````````~v|`-~~~|^++++?wz}v}}?|`..   <br></br>
 ..............................``````````````~?v?-`---~||||||?v?>?vvvv?>^` <br></br>
 ...............................`````````````.-~-````-~~~~~--+??>+>>???vvr-<br></br>
 ..................................``````````..``..```--~~--`|?>>++^^||+>?-<br></br>
 ...................................````````````````````-----|>>?+^|||||^+`<br></br>
 ..........................................`.````.``````````-~^+>^||~~~~||.<br></br>


            </div>,

            <div>

 ````-|^+??v}}v>`  ..... ... .......    .`|`.-`?vv>-``........>?--~|^+?v}v-<br></br>
.--~~|+>?>+?vrzxw?` ........ `-.....    ....`--^?vw?^~~```.. .+??||^^^~|>>`<br></br>
 `--~|^+>^||||+?wxw+...... ..........   .`.....-`~>vv?^-.... .`-~^+>+^^|-`.<br></br>
 `----^^^^+>>^|^>vwx?`................   ``.``|^~~~~|+~      ~-.```~++||~~.<br></br>
 ````~>>>??vv?>?vrzxx}-..............`.  .--~..-|~^~`.    ..`~|`....-`|+^` <br></br>
 `-~~~+>???vv}??}rwzzx}>-.  ...........   `-`...`~+~    ``-^|^^|-....|-`.` <br></br>
 `---~^+++|^>v?vvvvv}wzr}?^-`.. .......   ...`...`.   .``---~^||~-`.....`^.<br></br>
 ``--~|^^|~~|+>>>?}r}}wwwzwv???-........  ....`..    .-....`~```~^~-  ...`.<br></br>
 ```---~~||||||+++>>??vv?}zzr}wz>.......   ...`.    .. ....`|`..``````.... <br></br>
 ```---~~|||||^+++^|^^^+>?rzxxxxz?~ ....   ....  .`......``...... `~>>~`.. <br></br>
 ````---~~~~||^^^^^^^>+>>vwwzuuuuyx?. ..   .    ...``.............```+^.`. <br></br>
 ``````---~~|^^+||>^>vv?vvrv??rxuuuur^.       .........`.........`....-~-. <br></br>
 `````````--~|^|-^vv?????+??^~|?r}rxuwv~.    ..........``... .-...`..~?|.. <br></br>
 .```````````----`~|~-`~|~^?>~||||^>?||>?>^..................`-``...`.`-|` <br></br>
                  .......`..``````--```-~~|+^-~++++^^+^^^|^+`.......`....................^~ <br></br>
                  ...........```````---~~~|^|``|++++++^^^^+++wv~...................``.....-.<br></br>
                  .............````````````--``~+^|^^^+++++^+uyx+...................`...... <br></br>
 ....................`.......~}wrv?+--++++++?zu}|......................... <br></br>
 ............................+xzwww}>.~++++++?zx>`........................ <br></br>
                  ............................-rwwwrv|`-^++++^|vzur?-...................... <br></br>
 .............................|v}v?~---``~^|^+^?vxxv+`.................... <br></br>
 ..............................`-```----```+??|^>vwxwv~................... <br></br>
 .................................````------|?v++>??v}v^``................ <br></br>
 ..................................`````-----|?+~||^+>?r}v?+`............. <br></br>
 .................................``````````-`?v|-~~|^^}xxxzv>+~.......... <br></br>
 ................................`````````````~v|-~~|~|>???vzz}vv+`....... <br></br>
 ..............................```````````````~v|`-~~~|^+++>?wz}v}}?|`.. . <br></br>
 ..............................``````````````~?v?-`---~||||||?v?>?vvvv?>|` <br></br>
 ...............................`````````````.-~-````--~~~~--^??>+>????vv}-<br></br>
 ..................................``````````..``..```--~~--`~>?>++^^|^+>?~<br></br>
 ...................................````````````````````-----~+>?>^|||||^>`<br></br>
 ..........................................`.````.``````````-~^+>+||~~~~||.<br></br>


            </div>,

            <div>

 ````-|^+??v}v?+`  ..... ... .......    .`^`.~->vv>~``........+?`-~|+>?v}v-<br></br>
.--~~|^>>>>?}wxxw?` ........ `~`....    ..`.`--^?vw?|~~``... .+??||^++~|??`<br></br>
 `-~~|^+>^||||+vwxw+....`. ..........   .`.....-`~>vv?|`.... .`-~^+++^^|-`.<br></br>
 `----|^^^^+>^|^>vwx?`...............    --.``|^~~~~|+~      -~.```~+>||~~.<br></br>
 ````~+>>??vv?>?v}wxxv-..............`.  `-`~..-^~^~`     ...~|`....--|+^-.<br></br>
 `-~~~+>???vv}??}wwzxx}>-.  ...........   `-`...`~+~    ```^|^^|`....|-`.` <br></br>
 `--~~^+++^^?v?v}vvvrwzrv?|-..  .......   ....`..`.   .``--~|^^|~-`.....`^.<br></br>
 ``--~|^^|~~|+>???}r}}rwwzrv?>?-.....`..  ....`..    .-``..`~```-^~~. ...`.<br></br>
 ```---~~||||^|+++>>?vvvv}zzrvwz?.......   ...`.    .......`|`..``-```.... <br></br>
 ````---~~||||^+++^||^^+>?}zxzxxz?~ ....   ....  .`......``...... .~>>~`.. <br></br>
 `````--~~~~||^^^+^^^>++>vwwzuuuuyx?....   .    ...`-.............```++`.. <br></br>
 ``````---~~|^^+^~^+>vv?v}r}?vwxuuuur^.        ..................`....`~-. <br></br>
                  `````````--~|^^~~?v?????+??+~^?rrwxuw?~.    ..........`-... .-...`..-?+.. <br></br>
 .```````````-----~+|~-~|~^>>~|||^^>?^|+?>|..............``...~``...`.`-|` <br></br>
                  .......`.````````-```-~~~+^--++++^^+^^^|^+-.. ....`....................^~ <br></br>
 ...........```````---~~~|^|``|+++++++^^^++>rv-....`..............``.....~.<br></br>
                  .............``````````----``-||++^|+++++^+uyz+.......... ........`...... <br></br>
                  ....................```.....`-^rrrv+-++++++?xur|......................... <br></br>
                  .............................^zzwwrv|~++++++?xu?`........................ <br></br>
 ............................|wwzwr}v~-^^^++^|vzxr>-...................... <br></br>
 ...........................`wwr}v?>~-```~^||+^?vxxv+`.................... <br></br>
 ............................+>^~-```---```+??|^>vwxwv~................... <br></br>
 ..................................```------^?v^+>??vr}^`................. <br></br>
 .................................``````-----|?+~|^^+>?}v??+`............. <br></br>
 .................................``````````-`?v|-~~|^^}xxxxv>^-.......... <br></br>
 ................................`````````````~v~-~~|~|>???vzw}vv^`....... <br></br>
 ..............................```````````````^v-`-~~~~^+++>?wzrv}v>~`. .. <br></br>
 ..............................``````````````~??+``---~||||||?}v??v}vv?+~. <br></br>
 ...............................``````````````-~`````--~~~~~-^??>+>??vvvv}-<br></br>
 ..................................``````````..`...```--~~~-`~>?>++^^|^+>v~<br></br>
 ...................................````````````````````-----~+>?>^|||||^>`<br></br>
 ..........................................`````..``````````-~^+>+||~~~~||.<br></br>


            </div>,

            <div>

 ````-~|^??v}v?>-.  ........ .......    ..~~.-|~vv?|-``......-`?~-~~|>?v}v-<br></br>
.--~~|^>>>>?vwzxwv~......... .~`....    .`````~~?vr}^~~-`...  ~?v+|^++||>?`<br></br>
 `--~~^+>>|||^+?rxz?...... ....`.....   .-...`.---+vv?^`......`-||++++^^~`.<br></br>
 `----~^^^^+++||+?rxv-.... ..........    ~-`.`-^~~~~|+~      .|`.``~^>^|~-.<br></br>
 ````-^>>>?vv?>?vvrzxw^...............   `~`-`.`^~^|`     ...`|`....`-~++~.<br></br>
 ``-~~^>????v}v?vwwzxxw?~.  ...........   `--....-^~    ..`|||^^-`...`|`...<br></br>
 ``-~~|+>>^^>vvvvvvv}wzwv?|-..  .......   .``.`..`.   ..`-~-|^^||~``..`..|.<br></br>
 ``---|^^^|~|+>???vrr}rwwzwv?+?|........  .......    .`-`...-``--||~`  ..`.<br></br>
 ```---~|||||^^^++>??vv}vvwzwvrzv`......   ...`.    ........~~...--`...... <br></br>
 ````---~~||||^+++^||^^^>>vwxzzxxv^.....   ....  .`.......-`.......`+>|`.. <br></br>
 `````--~~~~||^^^^^^^>>++?rwzxuuuuuv-...   .    ....-`.............``|>~.. <br></br>
 ``````----~~^^+^~^++?v??vrr??rxuuuuz>.        .......`...............`--` <br></br>
 `````````--~|^^~~?v?????+??>||>rwwxuwv|.    ...........-`....`......`+?`. <br></br>
 .``````````---~--~+|~-~|~^>>~|||^^>?^|+?>|...............`...--``..```-~` <br></br>
                  .....````````````--``--~~^^~-++++^^+^^^|^+~.......`....................-|.<br></br>
 ...........```````----~~|^|``|+++++++^^^+++r}^....................`.....-.<br></br>
                  ...........````````````----``-||++^|+++++^+uyx?.............. ....`...... <br></br>
 .................```````....`-^rrrv+-++++++?xuz>......... ............... <br></br>
 .............................^zzwwrv|~++++++?xu}-........................ <br></br>
 ............................|wwzwr}v~-^^^++^|vwxw?~...................... <br></br>
 ...........................`wwr}v?>~-```~^||+^?vzur>-.................... <br></br>
 ............................+>|~-```---```+??||+?wxz}+................... <br></br>
 ..................................```------^?v^^>??vrr>`................. <br></br>
 ..................................`````-----|?+~|^^+>?vv??+-............. <br></br>
 .................................``````````-`?v|-~~|^^?xuxx}>^~.......... <br></br>
 ................................`````````````~v~-~~||~+??vvwzrvv>`....... <br></br>
 ...............................``````````````^v-`-~~~~^+++>?rzw}}}?|`.. . <br></br>
 ...............................`````````````~??+```--~||^^^|>}v??v}vv?>~` <br></br>
 ................................`````````````-~`````--~~|~~-~??>+>>?vvvv}-<br></br>
 ..................................``````````..`...```--~~~-`-+?>+++^^^+>?~<br></br>
 ...................................````````````````````------^>?>+^||||^+`<br></br>
 ..........................................`.```..``````````-~|+>>^|~~~~||.<br></br>


            </div>,

            <div>

 `````-|^>?v}v?>~.  ......... .......   ..`^``|-?vv^|``......^~+>`-~-+?v}v-<br></br>
.---~~|^>>???wzxzv+. ........ --....    ...```--+vvw?~~-`..  ~`>v?||+++|+?-<br></br>
 `--~~|+>>^||^^?}zx}~...... ...`....    .-`....`--^?}v+....-`..`~^++++^^|-.<br></br>
 ``----|^^^^++^|^?vzz+...............    ^~`.``~|~~~|^`    `. --.``-^>>||~.<br></br>
 `````~+>>>?vv?>?vvwxx?`..............   `|-`-.`||~^-.    ....~~.....~-^+^.<br></br>
 ``-~~~>????v}v??}wzzxxv^`   ..........   ``-`...`~^.   .`.`^~|+~`....|-`. <br></br>
 ``--~~^>>+^+?v?v}vv}rzz}?+-`..  ......   .``..`..`   ..``~-~^^^|~-`..`..`.<br></br>
 ```--~^+^|~~^>????}r}}wwzz}v?++......`.  .`......   ..~``..`-`--~|~-. ....<br></br>
 ````---~|||||^^+++???v}}vrzw}vwr^......   ....`.   ........`|`..`-~`..... <br></br>
 `````--~~|||||^+++^|^^^+>?rzxzzxw>` ...   ....   -`......``...`.. .~>+`.. <br></br>
 `````---~~~~||^^^+^^^>++>vrzxuuuuuw^...   ..   ....`-.............``->+`. <br></br>
 ```````---~~|^^+^|^^>v??v}wv?}zuuuuuv-.       .......`...........`...``--.<br></br>
                  `````````---||^^~+v??v????v?^|+vzwzxxz?      ..........``... .`......-?^. <br></br>
 .```````````--~~~->>|~|+^^??^|||^^+?>?>>^` ..............``..`~``...``-~~.<br></br>
                  .....`````````````--``---|^+-^+++^|^^^^|^^+`...................`........^`<br></br>
                  ............``````----~~~^+~`~+++++++|^^+++vv?` ...`..............`.....`.<br></br>
                  ...........`````````````--~-``|^|+^^++^+++^xyur|..................``..... <br></br>
 ..................```````...``-|v}?~-^++++^?zuuv~......... .............. <br></br>
 .............................``vww}v--++++++?zxx>...... ............... |~<br></br>
 ..............................>wwwvv^`^+++++|?}zzv^`....................w|<br></br>
 .............................+zzwr}?~``-~|^^+|>?ruz?|..................?x-<br></br>
 .............................+wwv>+-`-````+>?||^>}zxr?-.............. ^x}`<br></br>
 ..............................--`.````-----^?v^^>??v}rv~...........`.`rv?`<br></br>
 ...................................````-----|?^~|^^++>v}??>|......~``?}vv`<br></br>
                  ....................................`````````??--~~~|^+ruuxz?^~`.`~..`?v?|<br></br>
 .................................````````````|?`--~~|||??vv}zwvv?~|...rvw|<br></br>
 ................................`````````````>v``--~~~|^++>>vzzrv>...?r}x~<br></br>
 ...............................`````````````~?v^```---~||^^|^vrvv|. |}vxx|<br></br>
                  ................................`````````````-~``.```--~~|~~-+????v+??wzx|<br></br>
 ..................................``````````.......```-~~~~-`~??>zwrv}xxx~<br></br>
 ....................................```````````````````------~>>}zvv?zzxx|<br></br>
 ...........................................```...```.``````-~|^?zw>>rzwwr~<br></br>


            </div>,

            <div>

 `````-~^+?v}v?>|.  ......... .......   ..`|``|-^vv+|``....`.~^~?--~-|?v}v-<br></br>
.---~~|^>>???rzxzr>` ........ .-`...    ...````-^vvr?~~-`..  -`^v?^|+++|+?-<br></br>
 `---~|^+>>^|^+>vwxw^...... ....`...    .-`.....--|?vv>....``..`-^+++^^^|-.<br></br>
 ``----~^+^^^+^||>vwz?`.... .........    ^|`.``-|~~-||`    .. `-`.`-^>>^|~.<br></br>
 `````-^>>>?vv?>??vrzxv-..............   `|~`-``~^~^-.   ... .-|.....-~|+^.<br></br>
 ```-~~^>???vvv??vwzzxx}+`.  ..........   ``--...`-|.   .`.`|~|^|`....-~`` <br></br>
 ```-~~|+>>++?vvv}vvvrwzrv+-`..  ......   .```.`...   ...`-~~^^^||-`...`...<br></br>
 ````--|+^^|~|>????vr}}rwzzrv?^>`.....`.   `......   ..```..``----|~-.  .. <br></br>
 ````---~|||||^^^++???v}rvrzzrvwr?......   ....`.   .........|-..`-~`..... <br></br>
 `````---~|||||^+>+^||^++>?}zxzzxz?- ...   ....   -`.......`........`+>-.. <br></br>
 ``````--~~~~||^^^+^^^++++?rzzuuuuux>...   ..   .....-.............`--+>-. <br></br>
 ```````----~|^^+^|^^+?v??}w}?vzuuuuu}|.       ........................```.<br></br>
 `````````---~|^^~+v??v????v?+|^vzzzxxx?      ..........`-............`+>. <br></br>
 .````````````-~~~->>|~|+^^??^|||+++?>?>>^` ..............``...~``....`-|-.<br></br>
                  .....``````````````--`---~^^-^+++^|^^^^|^^+~.. ................`........~`<br></br>
                  ............```````---~~~|+~`~+++++++|^^+++vv?`....`....................`.<br></br>
                  ............````````````--~-``|^|+^^++^+++^xuuw+...................`..... <br></br>
 ..................```````...``-|v}?~-^++++^?zuuw|......... .............. <br></br>
 .............................``vww}v--++++++?zxu?`..................... ~~<br></br>
 ..............................>wwwvv^`^+++++|?vzxv+`....................w|<br></br>
 .............................+zzwrv?~``-~|^^+|+?}xxv^`.................?x-<br></br>
 .............................+wwv>^-`-````^>?||^+vzxw?~.............. ^x}`<br></br>
 ..............................--`.````-----^?v^|+??v}r}^...........`.`rv?`<br></br>
 ...................................````-----|?^~|^^++>?}v?+|`.....~``?}vv`<br></br>
                  ..................................`.`````````??--~~~|^^vuuxzv^~`.`~..`?v?|<br></br>
 .................................````````````|?`--~~|||>?vv}zw}v?~|...rvw|<br></br>
 ................................`````````````>v``--~~~~^++>>vwzrv>...?r}x~<br></br>
 ...............................`````````````~?v^```---~||^^||?rvv|. |}vxx|<br></br>
                  ................................`````````````-~``.```--~~||~-^????v+??wzx|<br></br>
 ..................................``````````......````-~~~~-`~>?>zwrv}xxx~<br></br>
 ....................................```````````````````------~+>}zvv?zzxx|<br></br>
 ...........................................```...```.``````--~^?zw>>rzwwr~<br></br>


            </div>,

            <div>

 ``````-|^>vv}?++`   ......... ....... ....-|`~^-?v?~^``....``>~+^`~~-+?v}-<br></br>
.----~~|^>>??vzxxw?~...........--...    ...`-``~-?}}r+~~......|`?v>~^++^^>`<br></br>
 `---~~^+>>^||+>vwxz?`...... . .`...    .-``....`--+v}+.....-...`^+++^^^|-.<br></br>
 ``----~|^+|^^^|~^?rz}~..............    |^-`.``~~--~~.    .-. --.``|+>+|~.<br></br>
 `````-~>>+>?v?>>??vwxw+..............   .||-`-.-^||-    ``.. .~-....`~~^+.<br></br>
 ````--|>???vv}v??rzzxxz?~.  .........    ``--`...-`    ```.-|~|+-....`~-`.<br></br>
 ```-~~~^>>>+>vv?v}vv}wzzv?~`..  ......   ..``.....   ...`-~-|^^||~``..-`. <br></br>
 ````--~^+^|~|^????v}r}}wwzwv?+^|. .....   .`.....   ...-``.``--~~~|-`. .. <br></br>
 ````---~||||||^^++>??v}rr}wzwvvwv~.....   ......   .........-|`..-~~`.... <br></br>
 ``````--~~|||||^>++^|^++>>?wzzwzxv|. ..   ....   `-.......``...`....~>|`. <br></br>
 ``````---~~~~||^^^+^|^>++>vwzxuuuuuv-..   ..    ..`.`-.............`-|>+` <br></br>
 ```````----~~|^^+^^^^>v??v}wvvwxuuuuz>.       ........`...............```.<br></br>
 `````````---~|^^|~??>?vv??vv?^^>rxzxxx}.     ...........-`............-?| <br></br>
 .````````````-~~~-^?+||++^??+||~^>+?>+>+|` ..............``...`~``...``|~.<br></br>
                  .....```````````````-```-~~+~^+++^|^^^+|^+??`. .................``........<br></br>
 ............```````----~~~^|`-+++++++|^^++>wv?^.....`..............`..^>-.<br></br>
 ............````````````--~~-`~|^+^^++^++^+xuuxv`..................```w}?`<br></br>
 ..................`````````````|>?>^~^++++^?zxuu?`........  .`.......vv??`<br></br>
 .............................^vwwwww>-++++++?zxxz^...... .......... +z??v^<br></br>
 ...........................~vwzzwwwr?`^+^+++|>vrxr?~.............. `rr>>z|<br></br>
 ...........................+rrrrr}}?^`---|^^^|+?vwuw>-.............?z?+}u|<br></br>
 ............................`-~||^~-``````|>?||^^?rxz}>`........`.|w?>?xz~<br></br>
 ...................................````----^?v^|^>?v}rw?-.......``v}??rxx|<br></br>
 ....................................````----|?+~~|^++>?vv>+^`....`-??vxwz~<br></br>
 ....................................````````-??---~~|^^>wuxxr+~|..`}?wxzx|<br></br>
 ..................................``````````.+>`--~~~|||?vv}ww+`..?vvwwzx|<br></br>
 .................................```````````~?>```--~~~|++>>?v^..|}vzzzzz~<br></br>
 ................................``````````.`+??-````--~~|^^^+>>~`??}zxxxx|<br></br>
                  .................................````````````-``..```--~~||~||rwvvvzwwzzx|<br></br>
 ...................................```````````....````--~~~~->x}vvzzxxxxz~<br></br>
                  ....................................````````````````````----~ww??rxzzzzzr~<br></br>
 ...........................................```...```````````?z}>?zwzzzwvv`<br></br>


            </div>,

            <div>

 ``````-~^>?v}v>+-.  ......... ....... ....`~`-^-+vv|^``....``+>~>-~~-|?v}-<br></br>
.---~~~|^+>??vzxxwv|. ........ `-...    ...`-``--^v}r?~~`.....~`+v?||+++^>`<br></br>
.----~~|+>>+^|+>?rzxv-...... . .``..    .-``.....--^v}>.....-`..-|>++^^^|-.<br></br>
 ``-----|^+^|^^|~^?}zw^..... ........    ->~`.```~-~~~    ..`` `-```~+>>^~.<br></br>
 ``````-+>+>?vv?+??vwxz?..............   .~|-`-``|^~`    `-.. .-~.....~~^+.<br></br>
 ````--~+???vv}v??}zzzxx?|.. .........    ```--...-.   .```.`|-|+|`....--`.<br></br>
 ````-~~^>>>+>?vvv}vv}wzz}?|`..   .....   ..```.`..   .`..`~-~^^^|~-`..``. <br></br>
 ````--~^+^^~~^>???vv}}}wwzzv?>|^. .....   .`.....   ...-``.```-~~~|~-. .. <br></br>
 `````---||||||^^^+>?vv}rr}wzw}vw}+.....   ......  ..........`^`..`~~`.... <br></br>
 ``````--~~|||||^+++^|^++>??rzzwzxw^` ..   ....   `~`......`-.`.`....`+^`. <br></br>
 ```````--~~~~||^^^+^^^++^+?rzxuuuuur|..   ..    ...``-.............`-~>>` <br></br>
 ```````-----~|^^+^^^^+????}w}vrxuuuux?`       ........`...............`-`.<br></br>
 ``````````---~^^|~??>?vv??vv?+|>}xzxxxw.     ...........``............`+^ <br></br>
 .````````````--~~-^?+||++^??+||~^>+?>+>+|`...............```. .~``...``~|.<br></br>
                  ......``````````````-```-~~+~|++++|^^^+|^+??-. .................``........<br></br>
 .............``````----~~~^|`-+++++++|^^+^>zv?+.....`.............`...^>-.<br></br>
 .............```````````--~~-`||^+^^++^++^^xuuu}~..................``-zwv`<br></br>
 ..................`````````````|>?>^~^++++^?zxuy}-........  .........v}v?`<br></br>
 .............................^vwwwww>-++++++?zxxx?................. +xvv}^<br></br>
 ...........................~vzzzwwwr?`^+^+++^>?}xw?|.............. `ww>?z|<br></br>
 ...........................+wrrrr}}?^`---|^^^~+>vwuz?~.............vuv>}u|<br></br>
 ............................`--||^~-``````|>?||^^>}xxr?`........`.|zv??xz~<br></br>
 ...................................````----^?v^|^>?v}wwv~.......``v}v?rxx|<br></br>
 ....................................````----|?+~~|^++>?vv?+^-....`-??vxwz~<br></br>
 ...................................`````````-??---~~||^+}uxzw>~|..`rvzxxx|<br></br>
 ..................................`.````````.+>`---~~|||?vv}ww+...?rrzzzx|<br></br>
 .................................```````````~?>````-~~~~^+>>?v~ .|rvxxzzz~<br></br>
 .................................`````````.`+?>-````-~~~|^^^+>+~`??wxxxxx|<br></br>
                  .................................````````````-``.`````--~||~||rwvvvxzzzzx|<br></br>
 ...................................```````````````..```-~~~~->x}vvzxxxxxz~<br></br>
                  .....................................```````````````````----~ww??rxzzzzzr~<br></br>
 ...........................................`````.```````````?z}>?zzzzzwvv`<br></br>


            </div>,

            <div>

 ``````-~|+?v}v>~~.   ..............  ......`--|^-?v>-~....``-~?~^|-~-`>v}-<br></br>
.---~~~~|+>??vrxxz}+` ..........``.. . ...```-``~-?rrv^-`...`.`|`?v^|^+++>`<br></br>
.----~~~^+>>^^^>?}zxw+......... .-..    .```.....`-~?}>....``-..`~+>++^^^~.<br></br>
 ``----~~|^+||^^||+vwzv`.............    .^^-`.``--~~-   .+`.~..````-^>>+~.<br></br>
 ``````-~+++>???>+??vwx}|.............    `^|````~^|`    ->..  .|`.`..`||^.<br></br>
 `````--|>???vvvv??rzzxuw+`.. ........    ```---..``   .```..-~-^^`....`~-.<br></br>
 ````-~~~+>>>>?vv?v}v}rwzwv+`..   .....   ...``....   .-`.`-~-^^^||~`...~` <br></br>
 `````--|++^|~|>??vvv}}}rwwzr?>|~`  ....   ..`....   ....-`````-~~~~~-`. . <br></br>
 `````---~||||||^^++?vvv}rrrzwrvvr?`....   .....   ...........~|`..-|~` .. <br></br>
 ``````---~||||||^>++^|^>>??vwxzwzx?-. .   ....   .--.......``.`......~+-. <br></br>
 ```````--~~~~|||^^++^|^+^^>vwzxuuuuz?`.   ..    ...````.............-~^>^.<br></br>
 ```````-----~~|^^^||^+>???vrw}}zuuuuur^.       ........................-` <br></br>
 ``````````---~|^^~+?>>vvv?vvv?^+?zxxxuu`     ............`.........`...|v-<br></br>
 .````````````--~~~~??^^+>^??>|^|~+?>+>?>|`.. .............``. .-~.....`vr-<br></br>
 ......``````````````--```-~^~|+++^|^^^||^^?r>.  ................``?v^`^r}-<br></br>
 .............```````----~|||`-+++++++^^^++>xx?>~....``...........`}v?^}wr-<br></br>
 .............````````````--~~`~^+?+|+++++^+xxuuz?............... ?v?>?xzw~<br></br>
 ...................````````````-vrv>~^++++^?wzxuu>.........  .`.`}v?>wzww~<br></br>
                  .............................`|?zwwr^`++++++?wzxxw~...... ......?v+|?xwrw|<br></br>
 ...........................`-^}wwwwr>`^+++++^>?vwxv+`......... ~wv~^wzwwx|<br></br>
 ...........................`~^rrr}v?|`---|^|^~^+?}xu}+`.....-..??+|vxw}wx|<br></br>
 ..............................`~~-````````~+?||^^+?zxwv|...`~`^v?>?zzwzxx|<br></br>
 ...................................````----^?v^|^+>?vrwr>`..```|?^rzrrzxx|<br></br>
 ....................................`````---|?+~~|^++>??v?||`. ^v?wzwwxzz|<br></br>
 .....................................```````-??----~~|^+?z>~-``??v}zzxxxx|<br></br>
 ....................................````````.+>``--~~~||^v+-``+??rwzwzzzz~<br></br>
 ..................................``````````^v+````-~~~~|?+`.-?^vwxxxxxz}-<br></br>
 .................................`````````.`+>^``````-~-|>+?????vrwzzxxwr-<br></br>
 .................................```````````....``````--~|>r??}zzzzxzzr}r~<br></br>
                  ...................................```````````````..```--~vv+?wwwwxxxzrrr-<br></br>
 .....................................````````````````````?w?+vzwwzzzr}}r}-<br></br>
 ...........................................`````......``-v}>?wwrwzzw}}vv?.<br></br>


            </div>,

            <div>

 ```````-|^>vv}>~~.   ..............  ......`~`|^->v?`~`...``-`?+~^~~~`^v}~<br></br>
.-~-~~~~|^>>?v}zxz}>-.......... ``.. . ...```~``~`>rr}+-`...`..^`>v>|^++>>`<br></br>
.-----~~|+>?+^^>?vwxz?......... .``.    .````....`~~>}?....```...-^>++^^^~.<br></br>
 ``-----~|^+^|^^|~+?rx}-..... .......    .~+~`.```~-~`   .>|.~` `-``-^>>>~.<br></br>
 ``````--++++???>+???wxw+.............    `^^-```-^|`    ->`.. .--..`.`|||.<br></br>
 `````--~>???vv}v??}zzxuz>-.. ........   .```---...`   .--``.`~-|+~....`--.<br></br>
 ````-~~~^>>>>?v}vv}}}rwzzv>-..   .....   ...```...   .-`..`~-|^^^|~`...-` <br></br>
 `````--~^++^~|^??vvvv}}}wwwwv?^--. ....   ..`....   ....-``````~|~~|-`. . <br></br>
 `````---~||||||^^++>?vv}rr}wzrvvr?~....   ......  ...........`|`..`||-... <br></br>
 ```````--~~|||||^>++^|^+>???wzzwzx}~. .   ....   .-~.......``.```....`+|` <br></br>
 ```````---~~~~||^^^+^|^++^+?rzxxuxuxv`.   ..    ...```-.............`-^>+.<br></br>
 ````````----~~|^^^^^^^+????}wr}zuuuuuz+.       ........................`- <br></br>
 ``````````---~|^^~^?++?vv?vv}v++?wxxxuu-     ............``........`...~r-<br></br>
 .`````````````-~~~~??+++?^??>|^|~^??+??>|`................``.. .|`.....}x|<br></br>
 ......`````````````---```-~^~|+++^|^^^|^^^?r?`  ................``?}>~>uz~<br></br>
 .............```````----~~~|-~+++++++^^^++>xu?>|.....`...........`wrv?wzx|<br></br>
 ..............````````-``--~~`~^>}?|+++++++xxuux?`.............. vrv?vuzz|<br></br>
 ...................````````````-rzr?~^++++^?wzxuyv`........  .`.~u}v?wxxu|<br></br>
 ..............................`?zww}^-++++++?rzxxz>.............vx?>vuzwz|<br></br>
 .............................`}wwwwr>`^+++++^>?vrx}>-......... |uw??zxxxu|<br></br>
 .............................-rrw}v?|`---|^|^~^+?}zuw>`.....~`.}}??rxzzzz~<br></br>
 ..............................`~~`````````~+?||^^^?rxzv+`..`|-+w}vvxxxuxx|<br></br>
 ...................................````----^??^|^^>?vrww?```-`-+}?rzzzzxx|<br></br>
 .....................................````---|?+~~|^++>>?vv~|`. +r?xxxxzwz~<br></br>
 ....................................````````-??----~~|^+>r+`...}vrzzxxxxu|<br></br>
 ...................................`````````.>>``---~~|||?^`..>rvxxzzzzzz~<br></br>
 ...................................````````-+v+````--~~~|?+..`}?wxxxxxxzv-<br></br>
 .................................`````````.`+>^``````-~-|?+v???vxzzzzxxrv`<br></br>
 ..................................````````````..``````--~|?x}vvzxxxxzz}vv-<br></br>
 ....................................``````````````...``--~rr??}zzzxxxwvvv-<br></br>
 ......................................```````````````````?u}>?xxxzzz}vvv}-<br></br>
 ...........................................````.....```.-wzv>}zzzzzrvvv??.<br></br>


            </div>,

            <div>

 ```````-~^+?v}?|-`   ..............  .......-`|^^~vv|--....`--^?~^|~~--?}~<br></br>
.-~-~~~~~|+>?v}zxzr?~. ..........`.. . .`...`-~`-~~vrr?-`....`.-^-v?||^+>>`<br></br>
.-----~~~^>>>^^+?vwxzv~..........``.    ..``....`.-~^??`...````..`|>>+^^^~.<br></br>
 ```---~~~|++||||~^?}zz>..... ......     ..^|-..`.---.   .>>``~ .````|+>>^.<br></br>
 ``````--~+++>???++>?vzx?`... .......    ..~+|````|^`   .->+..  .|..`.`-^|.<br></br>
 `````---^>???vvv?>vwzzxu}~...........   .``----`...   .--`-..---^^`....`-.<br></br>
 `````-~~~+>?>>?vv?v}}rwzzr?|..    ....   ....``...   `|-`.`-~~^^^||-`...~.<br></br>
 `````---|+++|~^>??vvvv}}wzwwv?+--. ....   ..``...   ..`.```````-||~|~`..  <br></br>
 ``````---||||||^^++>?vv}rwrwzw}?}v+`...   ......  ............|~..`~|~. . <br></br>
 ```````---~~|||||^>++^^+>>??}zxwwzz>. .   ....   .`~`.......`````.....~^` <br></br>
 ```````---~~~||||^^+^^|^+^+>vwzxuuuxr^.   ..    ....````..........`^|||>+.<br></br>
 ````````-----~~|^^^|^^^>?>?vrwrwxuuuuuv`       .......`...........?}}vv??`<br></br>
 ```````````---~|^||>+^>vvvvv}}?+>}xuxuu|     .............`..``. -vvvvv}x~<br></br>
 .`````````````--~~~??++>?+>?>^>^~|>?+??>|. ...............```??^|?}vvvvwx|<br></br>
 .....``````````````---``---^|~++++||^^||++?w?|. ............|??>>}rzrvrux|<br></br>
 .............```````----~~~~`-+++++++^^^^^+wuw+|`....``.....??++?}zzwrzxx|<br></br>
 ..............````````--`--~|`~^+>^|++++++^zxxuur|........ ~?+^^?rwrwzuxx|<br></br>
 ..................````````````->}rv+-^++++^?wzzuuz|........??||?}xzwrzxxx|<br></br>
 .............................-vrzww}^-++++++?rwxxxv-..... ~v>~^vwxrrwxxzz|<br></br>
 .............................`vwwww}?`^+++++^>?v}zz?~..`..??+^>}xzrrxxxxu|<br></br>
 .............................`^?vv??^`--~|+|^~^^>vwuxv>`.|?>?>vzzwwzxxzzw~<br></br>
 ...............................```````````~+?||^^^>vzuv-``-?+?rzwwwxxuux}.<br></br>
 ...................................````----|??^||^+?vv+~-.-?+v}}z}zxzzzw?.<br></br>
 ....................................`````---|?+~|||^>~~--`+??vv}zwxxxxw}|.<br></br>
 .....................................```````-??----~|-```|v>vvvwrzzzzr}rv`<br></br>
 ...................................`````````.>>``--~~~`..??>vvrzwxxzr}vwv`<br></br>
 ..................................`````````->v^````-|||||?>???wwzxxw}vwv>`<br></br>
 .................................`````````.`^+|`````~-vvv??wrwwwxzwrr}rr>`<br></br>
 ..................................````````````.`````.^}??>vxwzwxxzwrr}?vv-<br></br>
 ....................................``````````````..`?v>++zzrrwxwrrrwv???`<br></br>
 ......................................`````````````.^vv^^vuzwwxxr}}}v?>>>`<br></br>
 ...........................................````.....?v?~+zzrvwzwrrr}?>+++.<br></br>


            </div>,

            <div>

 ````````-|^?v}v|``.   .............  .....`.`-~^^-?v>`-....``|~?+~|~~-`+}~<br></br>
.-~-~~~~~|^+>vvwxzwv^. ......... ``.....`...``~``|-?wrv|....````+`?v+|^+>?`<br></br>
.------~~|+>>+^+?vrzxr^......... `-.    .```....```~^??`...````...~>?+^^^~.<br></br>
 ```----~~|^>^|||~|>vwz?`..... .....     ..|^-`.```--.   `>>~.|. ````~+>>^.<br></br>
 ``````---++^+>??>^>>?wx}~...........    ..`+^-```~+`   .~>>`.. .~`.````^^.<br></br>
 ``````--~>>??vvvv>?}zzxuw^`..........   .``-----...   `~-`-`.`-`|^`....`-.<br></br>
 `````-~~~^>??>?v}??}}}rzzw?+`.  . ....   ....``...   `|~`.``--^^^^|~`...~.<br></br>
 ``````--~^++^~|+??vvvv}}rzww}?+~``  ...   ...`...   ..`..``````-|||~~-`.. <br></br>
 ``````---~^||~||^+++>v}}}rwrzwrv?}>`...  .......  .......`....`|`.`-||` . <br></br>
 ```````---~~|||||^>++^^+>???vwzwrzx?`..   ....   ..--.... ..``.-``....`|- <br></br>
 ````````--~~~||||^^++^|^+^^+?rzxxuxuw>.   ..    ....````.....`....~?+||++.<br></br>
 ````````-----~~|^^^^^^^+?>>?}wrrxuuuuu}~       ........`..........}uuuwvv`<br></br>
 ```````````---~|^^|>>^+?vvvv}rv+>vzuuuy>     .............`..``. ~uzzxxuu|<br></br>
 .`````````````-~~~-?v>+>?>>??>>^~|>?>??+~. `..............```wz?^vuxxxxzz|<br></br>
 .....``````````````---``---^+~++^+||^^^|>>>zv^. ........... +w}v?xzzzxxuu|<br></br>
 ..............```````--`-~~~--^++++++^^^^|+rux>|`.....`.....w}v?}uxxxzzzx|<br></br>
                  ...............````````----~|`~^^^^^++++++^zxxxuw+........ +uvv?zzzxxxxxx|<br></br>
 ...................```````````~v}v?|-^++++^?wwzxuu?........wx?>vuxxzzzzzx|<br></br>
 .............................~wxxww}^-++++++?rwzxuw^..... +yw??zzxxxxxxzz~<br></br>
 .............................`vwzww}v`^+++++^>>?}wxv|.`~..ww??vuzzzzzzzxu|<br></br>
 ...............................^?v??>`-~~~+^^~|^+?rxxv?~`>zrv?zxxxuxxxzzr-<br></br>
                  ...................................```````~+?||^^^+?wu?-`~^r?vxwzzzxxxuz? <br></br>
 ...................................`````---|??^|||^>v?~...~z?wuxxxzzzzrv| <br></br>
                  .....................................````---|?+~|||^?`-...?wvxzxxxuxuzv?. <br></br>
 ......................................``````-??----~^``..`wvwxzzzzzzzv?v>.<br></br>
 ....................................````````.>+``--~||.  ?}vuxxxxxxzvv?r?.<br></br>
 ....................................```````->v^````~^+^>^??wzzzxxxxrv?}?+.<br></br>
 ...................................```````.-+>|````-^~wwrvvxxxxzzzrvvvv}+.<br></br>
 ...................................```````.``````````+u}vvrxxxxxxwvvv???v`<br></br>
 ....................................`````````````...`rz???xzzzzzwvvv}?>>?`<br></br>
 ......................................`````````````.>ux??ruxxxxzvv?vv+^||.<br></br>
 ...........................................```......}x}>vzwwwzz}vvvv?|||~.<br></br>


            </div>,

            <div>

 ````````-~^+v}v+``.   .............  .....```-`+^||v?`-`...``~~>?~~~~~`~v~<br></br>
.-~~~~~~~~|++?vrzzw}>`.......... .`.....`...``~-`~~^rw}+`...```.~^~v?||++?`<br></br>
.------~~~^>>>+^>?}zxw?` ....... .-`    .```.....``~|>?- ..````...-^?>^^^|.<br></br>
 ````---~~|^++||||~^?rxr|..... .....     ..`^~`..`.--.   `>>+.-~ .```-^>>+.<br></br>
 ``````---|+^^+??>++>?}xz>.... ......    ...^^~```-^`   .-+?|..  `|..```~^.<br></br>
 ```````--^>???v}v??vwzxxx?`..........   .```---~`..   .~--`-..``-^|....``.<br></br>
 ``````-~~|>????v}v?v}rrwzzv>`.  . ....   ....```..   `|~-`.`--~+^^^~`...`.<br></br>
 ``````--~|+++||^??vvvv}}}wwwr?+|``. ...   ...`...   .....`````--~||~~-`.. <br></br>
 ```````--~|^||~|^++>>?}}}rwwwzr}?}?~...  .......  .......``....|-```||~.  <br></br>
 ````````---~~|||||+>++^^>????rzzrwxr~..   ....   ..`-........`.-``....`-~.<br></br>
 ``.`````---~~~||||^^+^||^+^+>vwxxxxuzv-   ...   ....`-``.....``...~?+--^>.<br></br>
 `````````----~~||^^|^^^^??>?vrwrzuuuuuz+      .........`..........}uuxzv?`<br></br>
 ````````````--~~^^|>>^+>vvvv}r}?+?wuuuu?     .............``.``. ~uzxxxuu|<br></br>
 ``````````````--~~-?v>+>?>>??>?+~~+????+~. -`..............``wz?^vuxxxxzz|<br></br>
 ....`.`````````````---``---^+~++^+||^^^|>>>xr>`............ +w}v?xzzzxxuu|<br></br>
 ..............```````--``~~~--^++++++^^^^|+ruuv|-.....`.....w}v?}uxxxzzzx|<br></br>
                  ................```````----~~`~^^^^^++++++^zxxxxx?-....... +uvv?zzzxxxxxx|<br></br>
 .................`````````````~v}v?|-^++++^?wwzxuuw-.......wx?>vuxxzzzzzx|<br></br>
 .............................~wxxww}^-++++++?rwzxux?`.... +yw??zzxxxxxxzz~<br></br>
 .............................`vwzww}v`^+++++|>>?vrxw+``~..ww??vuzzzzzzzxu|<br></br>
 ...............................^?v??>`-~~|+^^~|^+>vzurv~`>zrv?zxxxuxxxzzr-<br></br>
 ...................................```````~+?|||^^+>}uv-`~^r?vxwzzzxxxuz? <br></br>
                  ...................................`````---|??^|||^+??~...~z?wuxxxzzzzrv| <br></br>
                  .....................................`````--|?+~~||^?`-...?wvxzxxxuxuzv?. <br></br>
 ......................................``````-??----~^``..`wvwxzzzzzzzv?v>.<br></br>
 .....................................```````.>+``--~||.  ?}vuxxxxxxzvv?r?.<br></br>
 ....................................```````->v^````~^+^>^??wzzzxxxxrv?}?+.<br></br>
 ...................................```````.-+>|````-^~wwrvvxxxxzzzrvvvv}+.<br></br>
 ...................................```````.``````````+u}vvrxxxxxxwvvv???v`<br></br>
 ....................................`````````````...`rz???xzzzzzwvvv}?>>?`<br></br>
 ......................................`````````````.>ux??ruxxxxzvv?vv+|||.<br></br>
 ...........................................``.......}x}>vzwwwzz}vvvv?|||~.<br></br>


            </div>,

            <div>

 `````````-|^?vv>-..   .............. .....```-`|+^|??|`-...``-^^v^~~~~`-?-<br></br>
.--~--~~~~|^+?v}zzwr?-..................``...`-|`-|-vwr>-...`````>`?v||^+>`<br></br>
.------~~~|+>>+^>v}wxzv~ .........``    ..```....``-~+>- ..-```...`^??^^^|.<br></br>
 ````--~-~|^+>^|||~^?}zz>...... ....     ...||-..`.`-    -?+>-`|..```-|>>+.<br></br>
 ``````----+^^+>?>+^+>vzx?`..........    ...-+|````|.   .-^?>... .|`.```~^.<br></br>
 ```````--|>>??vvv?>?}wzxu}~..........   .```-----..   `~~-`~..```||`....`.<br></br>
 ``````-~~~+>????v}??}rrwzxr?~.   .....   .....``..   `||~`.``--^^^^|-`....<br></br>
 ```````--~+++^|^>v?vvvv}}wzwrv+|``. ...  .....`..   ...`..````--~|||~~`.. <br></br>
 ```````--~|^^|~~|++>>?}r}rwwwzw}?vv|`..   ......   .......`....`-```~^~`  <br></br>
 ````````---~~|||||^>++++>????vzzwrzz>..   ....   ..`-`.... .`^|||-....``-.<br></br>
 ``.``````--~~~||||^+++^|^+^+>?rzxxxxx}^.  ...   ....`-``....-+^^||+?+-`|+.<br></br>
 `````````----~~~|^^^^^^^+?>>?}wwwuuuuuu?       ........-~``.~~~~~~ruuxzv>`<br></br>
 ````````````--~~|^|++^^+?vvvvrr?>?}xuuu?     ..........~||~|~^^~-^xxxxx}r~<br></br>
 ``````````````--~~-+?>+>?>>??>?>|||????^`. ``..........----++wzv>vuxxxwvv-<br></br>
 ....`.``````````````---`---^+~^+^^||^^^|++^zw?~. .....`--`-~?zw}vzzzzzrv}-<br></br>
 ..............```````````-~~``^++++++^^+^|+}uuw^-.....^^``-~wrr}ruxxzwvvv-<br></br>
 ................```````----~~`-|^^^^+++^++^zxxxxx}~..~|-```?u}rvzwzxxwv}v-<br></br>
 ..................````````````-?}vv^`^++++^?wwwzuux+.~~``~~wxvvruxxzzvvv}-<br></br>
 ..........................`..-vzzww}+`++++++?rrwxuu}^~--`-?uzvvzzxxuzvvvv`<br></br>
 .............................`?wwww}v`^+++++|+>?vrzzv+|^-~wz}v}uxzzzv?vvv`<br></br>
 ...............................+vvv?>`-~~|+^^~||^+?vrrv||?zwrvzxxuxwvv??>.<br></br>
 ................................-||-``````~+?|~|^~~|>zv~+>?wvvxwzzw}vvv?^ <br></br>
 .................................-`..```---|??|~^~-~+v^~||>xvzxxxzrvv??+- <br></br>
 ......................................````--|?^-`--->^|||~?w}xzxzww}}v+|. <br></br>
 ......................................``````-??`````>||^|^w}wxzwr}zwv?>>^.<br></br>
 ......................................```````>+````~>+~--vrvxxxwrww}??>}>.<br></br>
 .....................................`````.~?v^`-^^^+>??>vvwzzwwwzzv?>v>|.<br></br>
 ...................................```````.-^+|`~+^^>>zww}}xzwwwwr}vvvvv^.<br></br>
 ...................................`````````````^^||~?xrrvwzwwzzw}vvv?>??`<br></br>
 ....................................``````````.-^|~-|wzvv?xwrrwwv??vv?>>?`<br></br>
 ......................................`````````|^|~-?uzvvwxzzzzr?>???+^||.<br></br>
 .........................................`.``.`^|~-~rzr?vwrrrwwv>>???^||~.<br></br>


            </div>,

            <div>

 ```````-`-~^>v}?~...   ............. .`....`````+^++?>.``...``>^??~~|~-`^`<br></br>
.-~~~~~~~~~|+>v}wxw}?|. .................`...``|-`|~^ww?^``-`````^+|}>|^^+`<br></br>
.------~~~|^>>>++?}wxx}+......... .` .. ..`.`....```~^|`..-|-```.`.~>?>^^^.<br></br>
 ````---~~~|^>^|||||>vzxv`..... ....    ....-|-`..`.`   .+?++^`-~ .``-~+>+.<br></br>
 ```````---|+^^+>?>^^>?rxr|.... .....    ....++~````.   .-|>?|... `~..``-|.<br></br>
 ````````--+>???vvv>?vrzxuz+..........   ..```-~-~`    `|~----..``-^-....`.<br></br>
 ``````-~~~^>????v}v?vrrwzxz?^.   .....   ....````.   .|^|-`.```|^^^^~`... <br></br>
 ```````--~^+++||+?vvvvvv}rzwrv>^-..  ..  .....`..   ...``.````---|^|~~-`. <br></br>
 ```````--~~^^||~|^+>>>vr}}rwwzw}v?v+`..  .......   .......``....-````^|~. <br></br>
 ````````---~~||||||+>++++??v?vwzz}wx}`.   .....   `.``..... ~w}v?+`...```.<br></br>
 ``.``````---~~||||^^++^||^^^+>vwxxxxxr>.  ...   .....``.....?uxuuuz}?^`-+`<br></br>
 `````````----~~~||^^^^^^^>>>>vrwwxuuuuyv  .    ........~v^`-zxzzzzzxuuu+..<br></br>
 ````````````---~~^^|^^^^>v}vv}r}??vzuuuv      .........vuwvvxxxxxxzzzzz-. <br></br>
 ```````````````--~|~??++?v??????+||+v??|.  `~.........-rr}vzxzzzxxxxxxv.. <br></br>
 ....`.````````````--~^|~~|~|>||+^^^~^^+|||~wxv|...... vwv}vxxxxxxzzzzx^ . <br></br>
 ...............```````````-~~`|++++++^^+^|>rxux?-`...`xwvvrzzzxxxxxxxr... <br></br>
 ................````````----~-`|^++^+++^++>xxxxxzw>. ?u}>?xuxxzzzzzzu?.`. <br></br>
 .................``````````````>vvv+-|++++^?wwwzxuuv-zxv?rxzxxxxxxxzz~.`. <br></br>
 ..........................``..`wwww}>`++++++?}rwzxuwvxvv?zxxzzzzzzxuv.... <br></br>
 ..............................`zzww}?`^+++++|^>?vrwwrwrvvuxxxxxxxzzr~ ... <br></br>
 ...............................?zww}+`-~~|++|-~|^-~^^v}?rzzzzxxxxuzv. ... <br></br>
 ...............................`}wr?``````|+?|-||....vw?xxxxzzzzzwv+ .... <br></br>
 ................................`v?`.``````~>?~-+`..`wrrxxxxxxxxz}v` .... <br></br>
                  ......................................````-`|?^~....+xvzzzzzzzxxv??+-`. . <br></br>
 .......................................`````~v>-....}}vuxxxxxzzv?>zv^++~. <br></br>
 ......................................``````-?>~``.~}?wxxxxxxuzv>??+^^^v| <br></br>
 ......................................`````^??^~~r}v??xxzzzzzwvv?vr^~~+?`.<br></br>
 ....................................```````~+^-`?xw}vrxxxxxxzvvvv>v???v?|.<br></br>
 ....................................`````````..`wxvv?zwzzzxxrvv}?+?v?>>>?`<br></br>
 ....................................``````````.+uw?>vuxxxzz}??vv+~??>>>>?`<br></br>
 .......................................````````ruw?vxzxxxxwvvvv?~-+?>>+^|.<br></br>
 ............................................`.|xzv?vxwwww}?vvvv?--^>>>^|~.<br></br>


            </div>,

            <div>

 ``````````|+>v}v^``................. .`....```-`^^+^?>-``...``^+>?^-~~~`|`<br></br>
.---------~|>>vrww}}v>~`...```````.......`...``~|`~^~rzv^-`-`````-?-v?||^^.<br></br>
.---------~^>??>>?vrzzr?`..````....`... ..`.`.....``-||-..~~~```.`.`+??|^^.<br></br>
 `````---~~|^?>^^^||>}wwv~..`--.....    ....`|~`..`.`   .>v>^+``|..``-~^>+.<br></br>
 ```````---~^+++>?>+^>?}wr^..........    ....|+|````.   `~|>?>... .|`.``-|.<br></br>
 ````````-`^????v}v??v}rwzw>`.........   ..```-~---    `|~---~..`.`|~......<br></br>
 ```````-~~^?????v}v?v}rrwwr?+`.  .....   ....```-.   .|+||`````~+^^^~`... <br></br>
 .....```--|>>>+^>?vvvvvvvv}}}v?|-..  ..  ......`.   ...`-`````---|^||~-`. <br></br>
 .......```-||~~-~|^^^+?vvvrwwzzrv??>-..  .......   ....--`~|``..`````||~` <br></br>
 ........````-----~~~||^++??vvvrzzrrzw|.   .....   `````````.^zrv?>`...```.<br></br>
 .........```------~|^+^^|^+^+>vwzxxxxw?`  ...    `````+~--``vuxuux}v>|``^.<br></br>
 ..........`````--||^^++^^+>+>?}wwzuuuuy}  ..   ..-````-^}>~|zxzzzw}rrrr+..<br></br>
 .........`````--~^^|^^^^+?}vvvr}?>vwuuuv      ...```.``}urvvxxxxxw}}vv}`. <br></br>
 .....``````````--~|~??++?v?v????+^|+v??|.  .~...``````^ww}vzxzzzzrrwrr?.. <br></br>
 ...```````````````--~^|~~|~|>||+^^^~^^^|||-wx}^```..-`vz}}vxxxxxz}}}rr|...<br></br>
 ...............```````````-~~`|++++++^^+^|+rxuu}|-...~xwvvrzzzzxzrrrrv... <br></br>
 ................````````----~-`|^^+^+++^+++zxxxxzw?-.?u}??xuxxxz}}v}r>.`. <br></br>
 .................``````````````>v?v+-|++++^?wwwzzxxr+zxv?rxzxxxxrr}}}`..` <br></br>
 ..........................``.`~zwww}?`^+++++>vrwzzzz}zvv?zxzzzzrv}rr?.... <br></br>
 .............................-^xzww}v`^+++++|^>?v}rwwwr}vuxxuxzr}}}?- ... <br></br>
 ..............................`vwrw}>`-~~|++|-~|+~|>>v}?wzzzzxzrrrv?..... <br></br>
 ...............................`vr}?``````|+?|-|+``-`vwvxxxxzwrvvv?|..... <br></br>
 ................................`?>`.```-``~>?~~>~~-~zrrxxxxzzwrv?>`..... <br></br>
 ......................................``````|?^|``~->xvzzzzwwzzw???|``. . <br></br>
 .......................................`````~v>|.`--r}}uxxzzzzwv?>zv|^^~. <br></br>
 .......................................````.-?>|`~`^rvwxxzzzxxr?>??+||^?| <br></br>
 ......................................`````^??^||wr}vvxxzwwwwwvv?v}^||+>`.<br></br>
 ....................................`````..~+^~`?uzw}wxxzzzzrv???>v???v?|.<br></br>
 ....................................```````--```wx}}?xzwwzzzv????^?v?>>>?`<br></br>
 .....................................`````~~-``>uwv?}uzzzzzv????+~>?>>>>?`<br></br>
 .........................................`|~-`-ruz?vxzzzzxrv????~-^?>>+^|.<br></br>
 .........................................`~~``^xzv?vzwrrr}?????>--|+>>^|~.<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>`>>>>>>>>>+....``...`````-+++>>^.`...``-?|??~~|~-~.<br></br>
`???????????????????????~-?????????....``.`..```^--^~>zw?+`-````-`>+^v^|^|.<br></br>
                  `????????????????????????`~???????+`` .....`.`....```~^~^?^~|```..`.|?v^|^.<br></br>
`?????????????????????????.|?????>.  .  .....~|-.....`. .>v?^^|.~- .`-~|++.<br></br>
`?????????????????????????^.+??+-...     ... `>^-.``    `-~+??~... `~..``-.<br></br>
`??????????????????????????>|>+. ....    ...`.-~~--.   `|~~--~-....-|`.... <br></br>
`??????????????????????????>>>-.   ...    ....```-.   .-^||-`````^^^+|-`.. <br></br>
.>??????????????????????>~^^^???^~`.. .   ......`.   ...^>^`...`-~~^^|~~`. <br></br>
.+>+++>>>>>>>>>>>>>>>>>+^|+vrrwzw}v>?~..   .....`   ...|yuuzrv>~..````-^~- <br></br>
.^++++++>+++++^+^+++++^||+??vvv}wzw}wz?`   .....   ....?xzxxuuuxwv?-..```` <br></br>
.++^+++|^++>>++|-~||||^+^|^^^+>?vwxxxxxv|  ....   ?r?|-zuxxzzzzzxxx`.....~.<br></br>
.++++^++|``~++^|-`-||^^+^^^>>+>vrwzxuuuyx` ..    `xurv?xzxxxxxxxzzv......` <br></br>
.^^++^^|-`..```--~|^^^||^^>vvvv}rv??rxuux`     . >z}}?}uzzzzzxxxxu+ ...... <br></br>
 ..``.``..``````--~|^~+?^+?vvvvv}v+||?}r?    ~`..rz}}?zuxxxxxzzwwz`.`..... <br></br>
 ......`````````````--|>^|^^|>>|++^^~||||~`.vuz>^xzvvvxwzzzxxxxxuv..--`... <br></br>
 ...............````````````--~-+++++++^+||-vxxxrxz>>ruxxxzzzzzzx+........ <br></br>
 ................`````````---~-`~++++++^^++^vxxwzx}?vxzzxxxuxxxzz`......`` <br></br>
 ...................```````````--|>?>|~++++^}wzrz}v?vuzzzzzzzxxu}........` <br></br>
 .......................``````>vwwwwwv-|++++^>v}wz}?ruxxxxxxzzzw^ ........ <br></br>
 ............................`rwwzwwwv-~+++++~+?v}v?xzzzxxxxxux}`......... <br></br>
 .............................~?}}vv?|`-|^^+^|..~xvvuxxzzzzzzz}?.......... <br></br>
 ...............................````..`````~+>|.|uvwuxxxxxxxx}v^ ......... <br></br>
 .....................................```~~.~>?.?xvxzzzzxxxuwvv-.......... <br></br>
 ........................................~`-.^?-rr}uxxzzwwz}??w}^^~`...... <br></br>
 ........................................--- |??w?wxxxxxxxz??}v+||+v^```.. <br></br>
 ........................................->-`>????xzzzzxxxrv?rv+|^^?^-~---.<br></br>
 ........................................~|`>v?}v}uxxxzzz}?vv?v>++?????+`~ <br></br>
 .....................................``````??>vvzxxxxxuzvvvv>^+>>>>>>>>?+.<br></br>
 ....................................`````.?zvvv?xzzzzzwvvvvv^|||||?v?>>>?`<br></br>
 .....................................`````wur?>}uxxxxzvvvvv?~~|~~-|??>>>?`<br></br>
 .........................................|uu}?vzzzzzz}vvvvv+-~~~--~>?>++^.<br></br>
 .........................................?xz??vxwwwwv?????v|`-----~^>>+|~.<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>`+>>>>>>>>+...``````---~~`^^+++^..`...``>+>?^`~~-`.<br></br>
`???????????????????????--?????????....`-````---^~-|^|wwv+~`````-`|?|v>~||.<br></br>
`????????????????????????`~???????>.`..````-`-```..`--^~^v+~|-```.``->v>|^.<br></br>
`???????????????????????v?.^?????>`.....`.```~+|`....`...>v?+|^`-~..`-~|++.<br></br>
`?????????????????????????^`+??+-`...   ......?>~`.`    `-~^??+... .~`.``-.<br></br>
`???????????????????????????^>>. .....   ..`-`~^~`-.  .`|~~---~....`|-.... <br></br>
`????????????????????????????>~`.......  ...`-`````   .`|^|~`````~^|^^-`.. <br></br>
.>>??>>?????????????????>~^^^???^~`.....  ......`.   ...^>^-...`-~~^^||~-` <br></br>
.++^^^^^+++++>>>>>>++++^^~^?}}rrrv?>?^`.. .```....  ...~xxxw}?+~..````-||~.<br></br>
.^^^^^^^^^^^^^|^|^++++^|~^>?vvvv}rr}rz}~.  .....   ....?wwwwzzzzr?>-...``` <br></br>
.^^||^^~|^^+++^~`~~~~|++^||^^^>?v}wzxxx}>. ....   ?}?|-rzzwwrrrwzzz`.....`.<br></br>
.^^^||^^~`.~^^^~``-~|^+>^^|>>+>vrwrzuuuuu` ..    `zz}??zwwzzzzwwwwv......`.<br></br>
.||^||||`...```---~^^++^^^+vv??vr}??}xuux`     . >wvv?vzwrrrwwwwzz+ ...... <br></br>
 .....```.`````-~--~|-+?^^?v?vvvvv+^|?}r?    ~-..rzv??wzzzzwwwwrrw` `..... <br></br>
 ....`.`````````--``-`|>||^^|>?^+^|^~~~~|~`.?zr>|wr???z}}rrwzzzzzv..`-``.. <br></br>
 ...............```````````-~~^|>+++++^|+||`?rww}w}^^vwwrrrrrrrrz+........ <br></br>
 .................``````-`-~~||-|+++++^|^^+|?zzrwx}??zrrwzzzzwwww`.......` <br></br>
 .................``...````-`--~~^>?>~-++++|vrr}wv??vuwwrrrrwwwzv... ....`.<br></br>
 ...................`....`````>vwzwwwv-|++++^+?}rwv?rxzzzzwwwrr}| ........ <br></br>
 ...................``...````-rwwzwwrv-~>+++^-^?v}??xwwwzzzzzzw?`......... <br></br>
 ..............`....``...`````~?}}vv?|`~^^^^^~..~z??xzxzwwwwwwv>.......... <br></br>
 .........................```````--```--```-^>~.|xvwxzxzzzzzzv?| ......... <br></br>
 ...........................```````````-`~~.~>>.?zvxwwwwzzzxr??-.......... <br></br>
 ...........................`````````````~--.^>-}vvxzzwwrrw}??rv^|~`...... <br></br>
 ...........................`````````````--- |??}>wxzzzzzzw??vv>||+v^--`.. <br></br>
 ...........................`````````````-+``>????zwwwwzzz}v>}v>^+>?|-~---.<br></br>
 ...........................`````````````||`>v?v?}xzzzwwwv??v?v>++?v???+`~ <br></br>
 ...........................````````````````>>>??wzwzzzzrvv??>^++>+>>?>+?+.<br></br>
 .......................`..````````````````>zv???xwwrwwr??vvv||||||+v?>+>?`<br></br>
 ..........................````````````````rxv>>}xzzzzwv?????~~|~~-~>?>>>?`<br></br>
 ..........................```````````````|xxv>?wwwwzzvvvvvv^-~~~---+?>>++.<br></br>
 ..........................``.............?zw??vzrrrrv??????~`------|+>+|~.<br></br>


            </div>,

            <div>

 `---~~~~~~--~|>??|-`....`. `r}v?}vv?vvvv}}}}}}}v`-~~||-|-`-...`^>|?>~|~~^.<br></br>
.~~~~~~~~~~~~|^+?v}vvv>``??|+uv??}x}rzxzzwzwwzxxw~--|-?vv>>~--.``-`>^?v^++.<br></br>
.~--------~~|^^^++>vrzxw?^??vv?v?vvvvwuxxxxxzzwwv..```^>?v>>?>--`..-.|>+>^.<br></br>
.~-------~~~||^^|||^+>vrw?|>??v?????vrxxxxxxxxxu?.....``~+???>?^`|`..``-^-.<br></br>
.-----------~||||^++^^^+^|+>????????v}zwwwwzzxxu>..``--````~^??^-`..-```.`.<br></br>
.------````-~^^^+>??>+>^~^>>?????????}xxxxzzwwww|..`-`--`~-~--~````~~`...`.<br></br>
 -````-```--~^^^+++^|~~|-`^>?????????}zzxxxxxxxz-.`..```-~~|~`-``-|^~``..`.<br></br>
 `````````-`-||^|-~~~~|~-|+>>>?>>????v}rvzzxxxu}..``...````..`~~~~---`-|~-.<br></br>
 ```````````-~~|~~|++^|~^++^+++`-^>?rw}}rzwwwww?.............````.```--~||.<br></br>
 .```````````--~~~+>>>>|-~~v?^>>~-|wxxzzxxxxxxr+...  .............`-`..``-.<br></br>
 ````````````---~++|~^v?. .zv>?u?--?zwzzxxxxuz>`     ...`........`.........<br></br>
 ..``````````---+?~~~>rv^~+zvvvyxv~|xxzwrrwzr|   .........``...............<br></br>
 ..````````````~v^-~|?z}>^?}v??}}}v?rwwwwru}` ``.......................... <br></br>
 ..````````````+r+`--??v^-+}?>v}?^~^^vzww?>. |?^~.............`........... <br></br>
 ..```````````-^??. >| ^^ `r??xux>~+^??v?||- ..--`......   .........``.... <br></br>
 ...``........|-->-~^-`~^..r}vuxx?--`~^|~++^`|~--`.         .............. <br></br>
 .............|`..^^^v~||..r}vxzzw??+~^`~++++>v}}r}}v>-................... <br></br>
 .............|...^|`  |`.-wvrxzzzr?v??+-|||^|>+?vwr}w?~-`................ <br></br>
 .............-..```?..~`.?wvzuxxxz}vvvv>|---|+~>|vvv??>^^~...........   . <br></br>
 .............~+-...?^`~.`wvvxuxxxuzvvv}vv??|>?+?^vv>^++^~~^~-`...... -?>. <br></br>
 .............|v~..^v}?`.}}?vxzzxxxxw}v}vvv?+~??~+vv?^rv?^^+``-~`-`..|xwr` <br></br>
 .............`>`..|??~.|vv?vxzzzwzww}?vvvvvv>^>?rww>^???>v>^~~-.``.~}wvv. <br></br>
 ..............-`..|?^..~?}vruxuxxxxzvvvvvv?v+|>~~|~~~~|+>>>>^^|-~-`^v?++`.<br></br>
 ...............```|~...?rw?zxxxxxxxrv}}}}}}}|->~``--~|^+>>?vv?^~`..`zxv>  <br></br>
 .......................}rv?zwwwwwwrvvvvv}}}v-.``----~~~|||^>?vvv?+`.>w}+  <br></br>
 ..................... `z}?vuxxxxxzvvv???v?v?`..``------~~~||+v}rrr?>^^|.. <br></br>
 ..................... ~z?>}uxxxxxwv}}}}}vvv?``````````---~~~|+>>??wzw}v^` <br></br>
 ..................... >u??wuxxxxz}vv}}v}}v}>.````````````-~~~|^^^+?rr}vwv`<br></br>
 ..................... vu??zzzzzwv?vvvvvvvv}^.``.``````````---~||||^???>vr~<br></br>
 ......................rx>vuxxxxwvvvvvvvvvvv~```....`````````--~~~~-^>+++?-<br></br>
 .................... `ur>vuxxxx}vvv}}}}}}}v-```....```````````-----~++^|+`<br></br>
 .................... |xv?}uzzzwvvvvvvvvvvv?..............`..```----~^^|~~.<br></br>


            </div>,

            <div>

 `---~~~~~~--~~|||^?v^` .`. `r}v?}vv?vvvv}}}}}}}v--```..+>||...`~^-^?>^~~?`<br></br>
.~~~~~~~~~~~~~~~~|^?ww?``??|+uv??}x}rzxzzwzwwzxuw`~---`-?vv+~-..`-``~?r+?v`<br></br>
.~---------~~~~~-~^+?rzzv+??vv?v?vvvvwuxxxxxzzwwv..```|~^+|?w}^+``````|-v> <br></br>
.~---------~~~~~~~^+>??vr?|>??v?????vrxxxxxxxxxu?.....`.`-->vvw?-~~`.`..|`.<br></br>
.---------------~|^+>+^|~|+>????????v}zwwwwzzxxu+..-``.....-`|?vv>|~~`~`...<br></br>
.------``````----~|^+^^|~^>>?????????}xxxxzzwwww~ .-+|`.``-|---~^>??~.`. `.<br></br>
 ------````````---|~~~~~--^>?????????}zzxxxxxxxz`...~|~`-.`~|-|~--~^`....-.<br></br>
 `````````````-~--~~~~|~-|++>>?>>????v}rvzzxxxu}..`````-`...`-+^|-```.`^||.<br></br>
 `````````````-~~~|++^|~^++^+++`-^>?rw}}rzwwwww?.......`.....``-``.``~~~^|.<br></br>
 .````````````----+>>>>|-~~v?^>>~-|wxxzzxxxxxxr+...  .............-``..``` <br></br>
 ``````````````--|^|~^v?. .zv>?u?--?zwzzxxxxuz>`     .....```..........`~` <br></br>
                  ....``````````-^?~-~+rv^~^zvvvyxv~|xxzwrrwzr|   ...........`-`......``... <br></br>
 .......```````~v^--~?z}>^?}v??}}}v?rwwwwru}` `~...............`-......... <br></br>
 .....`````````+r+`--??v^-+}?>v}?^~^^vzww?>. |vz}-........................ <br></br>
 ..`-`````````~^??. >| ^^ `r??xux>~+^??v?||- ..|??~....    ........``..... <br></br>
 ..`--`...```.|-->-~^-`~^..r}vuxx?--`~^|~++^`|-..`....      .........``....<br></br>
 .............|`..^^^v~||..r}vxzzw??+~^`~++++>v??v}rwz}~. ................ <br></br>
 .............|...^|`  |`.-wvrxzzzr?v??+-|||^|>+?vrr}zurv?`....`.......... <br></br>
 .............-..```?..~`.?wvzuxxxz}vvvv>|---|+~>|vvv?vvrz}|..........  .. <br></br>
 .............~^-...?^`~.`wvvxuxxxuzvvv}vv??|>?+?^vv>^+^^~^>~-`...... -?>` <br></br>
 .............|v~..^v}?`.}}?vxzzxxxxr}v}vvv?+~??~+vv?^v??+v?~`~~`-`..|xwr` <br></br>
 .............`>`..|??~.|vv?vxzzzwzww}?vvvvvv>^>?rww>^^++>}?v++-.``.~}wvv. <br></br>
 ..............-`..|?^..~?}vruxuxxxxzvvvvvv?v+|>~~|-~~~||^|^>>?+~|-`^v?++` <br></br>
 ...............```|~...?rw?zxxxxxxxrv}}}}}}}|->~```--~~~|||>}ww}>`.`zxv>  <br></br>
 .......................}rv?zwwwwwwrvvvvv}}}v-.```-----~~~|^+??v}}?~.>w}+  <br></br>
 ..................... `z}?vuxxxxxzvv????v?v?`..````-----~~~~^^+>>vv?+>>`. <br></br>
 ..................... ~z?>}uxxxxxwv}}}}}vvv?```````````------~||^+}xzzw?|.<br></br>
 ..................... >u??wuxxxxz}vv}vv}vv}>.`````````````----~~||>??vvw}-<br></br>
 ..................... vu??zzzzzw}?vvvvvvvv}^.```..```````````--~~~^+++>vw~<br></br>
 ......................rx>vuxxxxwvvvvvvvvvvv~````....``````````---~||^^^^v~<br></br>
 .................... `ur>vuxxxx}vvv}}}}}}}v-```......```````````--~~~~~-+`<br></br>
 .................... |xv?}uzzzwvvvvvvvvvvv?....................`---~~--`~`<br></br>


            </div>,

            <div>

 ``````----~~~~|||^^?r????w}}}}}}}}}}}}}}rv........``````|~^+>>--~~~^+rwww~<br></br>
 -----~~~~~|~~||||^+vu?vv?yzwzzzzzzzzzxzzxx~`-..`..`.``--~~~-^?>|^^^>vyyyy^<br></br>
 ```--~~~~~~~~~~~~~|>u}?v?uxxxxxxxxxxxxxxzx^ ...-```..`~-```.>??|^^^>}yyyy^<br></br>
 `----~~~~~~~~~~~-~~^ur???zuwruxuxxxxxxxxxu> ......`...````--|+^~|^^>}yyyu~<br></br>
 `-------------~~~~~|zw???wzv}rrrzzwwxxxxxx?  .`...........````-~|^^>vyu}>.<br></br>
 ``----------------~~wzv+?rrvv???vv?rxzzzzw?............```.....``^^>?z?>v`<br></br>
 ``-------``````-----rur>?}v??????+>>+>vuuwv`..         `^-`..``-`|^>>>vxw.<br></br>
 ``-----`````````````vu}???>?????+~>v}rzxx}}~.     ......~~`.`.`||~^>>vyyv`<br></br>
 `````````````````.`.?uv???v>~~+++^?zxxzzwvv+.  .......`.`````...--|+>vxv>`<br></br>
 `````````````||~`.  ^xv???u}`+wzzzzzxxxxwvv?.  .............`....`^+>?>>>`<br></br>
 ````````````^v?+....|yvv}?zz~ruxxxuuuzzz}v}?  ~`.....  ..........`^^>>>>>`<br></br>
 ....```````-+}}>..`~~u}v}vwyxuxxxxxw}}wr}vv^  >?^`..   .........`|^|>>>>>`<br></br>
 .......`..```rr+``-|+??v??vrv?????+^^>wrrz}~|-  ...  .........`-|^^^>>>>>`<br></br>
 ..........-``w}~|+`~?.`v???v???>>^-~^|^^^?r>+~^??vv+```......~|^^^^^>>>>>`<br></br>
 ..........-.`v> v^ +v~.~rvvuuxxzwr?+~~``^`|^+++>vrrv>>^^|~`~|^^^^^^+>>>>>`<br></br>
 ........``-..>``> ~?v?..vrvuxxxzzz}v???++~-++++~^>??+>+?~-^^^^^^^+>>>>>>>`<br></br>
 .......`---`.~.~|?zw}r`.>wvxxxzzzxxxzrvvv?^-~~~|+>?+^vxv^~^^^^^+>>>>>>>>>`<br></br>
 ..........~|^+``--->}w`.>z?zuxxxxxxxuzvv}v?^>~`~>>?v>+v?^|^^^^>?>>>>>>>>>`<br></br>
 ..........-+>^.|`?`.|}-.?w?wuxxxxxxxxwv}}}vvv>??}wwv+>?||^^^+?zv>>>>>>>>>`<br></br>
 ...........+>|.` `^.`?-.vv?ruxxxxxxxx}vvvvvvv??>++^-`>+>>|+>}yw>>>>>>>>>>`<br></br>
 ...........|>`. .`...|`|zv?vuzzzzzzzwvvvvvvvv?---``-~^+>^^?zyy?>>>>>>>>>>`<br></br>
 ...........`>.. `v. .-`>v??vuxxxxxxxwv}}v}}}}v`--```~||~-+vyyu>>>>>>>>>>>`<br></br>
 ............|`.``?^ ``>v}v}vuxxxxxxxrv}vv}}}}v-.````--~-`|?yyu>>>>>>>>>>>`<br></br>
 .............`.^^||`~.?uv}}?uuxxxxxx}vvvvvvvvv|.```````--~>wyu?>>>>>>>>>>`<br></br>
 ...............-~-~~. ^y}v}?zxzzzzzrvvvvvvvvvv+.```````--~|>ry}>>>>>>>>>>.<br></br>
 ..................... -uw?}?wuxxxxxwvvvvvvvv}}?...`````----~+vx?>>>>>>>>+.<br></br>
 ..................... `zw??>ruxxxxxrvvv}v}}}}}v...``````````~^?v>>>>>>>>+.<br></br>
 ..................... .zxv??}uxxxxx}vvvvvvvvvv?`....`````````-|>>>>>>>>>>.<br></br>
 .......................wu}??vuxxxxwvvvv?vvvvvvv~.......````````-|>>>>>>>>.<br></br>
 ...................... vu}??vuxxxxwvvvvvvv}}vv}+........`````````-|>>>>>>`<br></br>
 ...................... ?urv?vuuxxurv}}}}v}}}vv}?.......`````````````|^^++.<br></br>
 ...................... +uwvv?zxzzz}vvvvvvvvvvv??.......```.....```.`````-.<br></br>


            </div>,

            <div>

 ....```````---~~|^^?r????w}}}}}}}}}}}}}}rv. .....  .``````^+~``-~~~^+rwww~<br></br>
 `````---~~~~~~||^^^vu?vv?yzwzzzzzzzzzxzzxx`....``...`````---||`|^^^>vyyyy^<br></br>
 `````-----~~~~|||||>u}?v?uxxxxxxxxxxxxxxzx^ ....`-|-.`..``.`-^-~^^^>}yyyy^<br></br>
 `````----~~~~~~~~~~^xr???zuwruxuxxxxxxxxxu> .........~-`....~|-`|^^>}yyyu~<br></br>
 `````-----~~~~~~~~~|zw???wzv}rrrzzwwxxxxxx? ............``...```~^^>vyu}>.<br></br>
 `````-----~~~~----~~wzv+?rrvv???vv?rxzzzzw?................`....`^^>?z?>v`<br></br>
 ``````--------------rur>?}v??????+>>+>vuuwv`..        .......````|^>>>vxw.<br></br>
 ``````------------``vu}???>?????+~>v}rzxx}}~.     ............^+~~^>>vyyv`<br></br>
 ``````------```````.?uv???v>~~+++^?zxxzzwvv?~  ...............`^|-|+>vxv>`<br></br>
 ````````````-||~`.  ^xv?v?u}`+wzzzzzxxxxwvvv- ..  ...........`````^+>?>>>`<br></br>
 ````````````^v?+....|yvv}?zz~ruxxxuuuzzz}v}?  >>-....  ..........`^^>>>>>`<br></br>
 .....``...`-+}}>..`--u}v}vwyxuxxxxxw}}wr}vv^  >}v?+-.   ....... `|^|>>>>>`<br></br>
 ..........```rr+``-~+??v??vrv?????+^^>wrrz}||-  ..```~^-......`-^^^^>>>>>`<br></br>
 ..........-``w}~|+`~?.`v???v???>>^-~^|^^^?r?+~^??vrwzzxv~...`~^^^^^^>>>>>`<br></br>
 ..`.......-.`v> v^ +v~.~rvvuuxxzwr?+~~``^`|^+++>vrrv?v??v^`~^^^^^^^+>>>>>`<br></br>
 ..........-..>``> ~?v?..vrvuxxxzzz}v???++~-++++~^>??^>+?~~^^^^^^^+>>>>>>>`<br></br>
 ..........-`.~.~|?zw}r`.>wvxxxzzzxxxzrvvv?^-~~~|^>?+~?zv+|^^^^^+>>>>>>>>>`<br></br>
 ..........||^+``--->}w`.>z?zuxxxxxxxuzvv}v?^>~`~>>?v+~+?+|^^^^>?>>>>>>>>>`<br></br>
 ..........-+>^.|`?`.|}-.?w?wuxxxxxxxxwv}}}vvv>??}wwv+>>~~^^^+?zv>>>>>>>>>`<br></br>
 ...........+>|.` `^.`?-.vv?ruxxxxxxxx}vvvvvvv??>>>+~`|~.-^+>}yw>>>>>>>>>>`<br></br>
 ...........|>`. .`...|`|zv?vuzzzzzzzwvvvvvvvv?--~~~-~~``-^?zyy?>>>>>>>>>>`<br></br>
 ...........`>.. `v. .-`>v??vuxxxxxxxwv}}v}}}}v``````~|~--+vyyu>>>>>>>>>>>`<br></br>
 ............|`.``?^ ``>v}v}vuxxxxxxxrv}vvv}}}v-.....`~~-`|?yyu>>>>>>>>>>>`<br></br>
 .............`.^^||`~.?uv}}?uuxxxxxx}vvvvvvvvv|......````->wyu?>>>>>>>>>>`<br></br>
 ...............-~-~~. ^y}v}?zxzzzzzrvvvvvvvvvv+.......``--|>ry}>>>>>>>>>>.<br></br>
 ..................... -uw?}?wuxxxxxwvvvvvvvv}}?.......`````~+vx?>>>>>>>>+.<br></br>
 ..................... `zw??>ruxxxxxrvvv}v}}}}}v.........````-^?v>>>>>>>>+.<br></br>
 ..................... .zxv??}uxxxxx}vvvvvvvvvv?`.........````-|>>>>>>>>>>.<br></br>
 .......................wu}??vuxxxxwvvvv?vvvvvvv~.........``````-|>>>>>>>>.<br></br>
 ...................... vu}??vuxxxxwvvvvvvv}}vv}+.........````````-|>>>>>>`<br></br>
 ...................... ?urv?vuuxxurv}}}}}}}}vv}?.........```````````|^^++.<br></br>
 ...................... +uwvv?zxzzz}vvvvvvvvvvv??.......`.`.........``````.<br></br>


            </div>,

            <div>

 ..........+r}>???+r}}}}}}}}}}}}}}}r?...........................``.`|?vv>~ <br></br>
 ......````~xuw?}v?wuuuuuuuuuuuuuuuux~.....................`````-^^|-~+vv?`<br></br>
 .......````?ux}?v?vuxzzzzzzwwwwwwwwz?~-`....................```--|~^-^^>?`<br></br>
 ........```~xzr??>?rxzzzzzxxxxxxxuuxv?^`...  ..........`-~..``.`.``||-^||.<br></br>
 ........````vxz}v>vvuuxxxxxzw}zzv??rv?}>`..   ..............~``...`|-`--..<br></br>
                  ........````|uuzv??vruxxxrvvvvv?|+vzvvvwr?^-  ................```..````~-.<br></br>
 ........`````vux}??vvvvvv????????vwzvv??vrzw. |`...........`......`....`` <br></br>
 .......``````^uuzvvv?}x???>?????+v?>^+v^^+?v- v}|...........`.......^v|.. <br></br>
 .......`````..}xzv?v??zv+>????>+||>v?vv?|^||. >wr+``................wwv`. <br></br>
 ......````-`. ~xxw?vv??vvvv???vv}rxx}vvv>?>+  ?}wz+  .. ...........`xx}+. <br></br>
 .......`~~`....rux?vvv?xuxzwwwwwwwzzvvvv?vv> `rwwzz| .---~-~~~~||~~|zzv?^.<br></br>
 ......`-``...`?wuuvvvv?vxwzzxxxxuuxr??v?>+>. `^^~--`  -|~+~`.^-.~~.-?vv?+ <br></br>
 ......``.....`^}zv>vv???zuuxwwww}?>++^+??>>|.  ..-`>??^???^-.`>``+~-~ru}?`<br></br>
 ......-`...~|~`-~`.-?vvv?v??>?>?^-|^^|^vzzzv|^|+>v+vrv>>wr>+>|+~``...`vyr~<br></br>
 ......~~`.^|`+.....`.?wv?vrv???v?^~~-+^~>+>+^+`??v>~^||~>??vvr+`.......>v-<br></br>
 ......~>+|^ -r. ...``.vrv}xzzwzzzz}??+?|`-|+++-+vr^^^^>+>wzxxxxw>`.....   <br></br>
 ......~???~``>.`-...|.-zvvzuxxxxxuz}}}}}v|-~-~|>>|^|+>vz>vrxuuuuuv~...... <br></br>
 ......-?v?>-`-~v>...`-.vrv}uxxxxxxxxwv}vvv??>+?+~|^^>>>???+>}zzzxuz?`.... <br></br>
 .......>>~>`-``-.~>^`|.?wv?zuxxxxxxxwvv??vv??^~-|^|>>>>>}v+|^>>>?}wxw?~`. <br></br>
 .......~- -`.-`+vvrr?+.>w??vuxxzzwwwv>??vvv?+|~|^^+>>>|^??+|~~~~~^?}wxwv| <br></br>
 ........~..`.~> ->vwv+.?rv??xzwzzx}>|||||^^|||^^+>>>>+~^+^~-----~~+vrrzxr.<br></br>
 .........-....-.  |?v?-vv???vuxxxx+|^?}}}vv?>+>>>>>>>~-~~~-~~`---~>}rrwzx~<br></br>
 .........`^. .......^wv?vvvvvxuxxuw?>>>>????>>>>>>>>?``````~~-`--~^>?v}rw~<br></br>
 ..........->>>~.....`?u}v}vvvvuxxxuuzv+^^+>>>>>>>>>?v`....`-~-``-~|||+?v}-<br></br>
 ............?}?`......zxvv}vv?xuxxzww}vvv^+>>>>>>>>?w`.......```--~~~~|^+.<br></br>
 .............-+~..... ?yw?v}v?vuwwzxxwv}}>^>>>>>>>>>z>``.....``--------~|.<br></br>
 ...............`-`... `xx}?????xuxxxxwv}}+^>>>>??>>>vr>^|~...``````----~~.<br></br>
 ...................... ?x}v???v}uxxxxwv}>~v>>>>>+^^+>>>+|~....```````---~.<br></br>
 ...................... `xx}v??}vzuxxxrv>~?}>>+|~||~``-~-`.......`````----.<br></br>
 ....................... ?uwvv??vvuwwwwv^^?>+|>?>>??..............``````--.<br></br>
 ....................... -ux}v??vvzxxux}>^^^+vvvvvv}|............`````````.<br></br>
 ........................ vuwv????vxzzz}vv??v??vvvvv?..........`.`.``...``.<br></br>


            </div>,

            <div>

 ..........+r}>???+r}}}}}}}}}}}}}}}rvv}}v`........ ..................`.`+?`<br></br>
 ........``~xuw?}v?wuuuuuuuuuuuuuuuux?vzu?......................`````|||~^.<br></br>
 .........``?ux}?v?vuxzzzzzzwwwwwwwzz?vwzx+.....................`````-^|^~.<br></br>
 .........``~xzr??>?rxzzzzzxxxxxxxuuxv?>?rw^  ..............`-~`..`````-`^.<br></br>
 ............vxz}v>vvuuxxxxxzw}zzv??r}?^^+vw`  ...............``.--`....-|.<br></br>
 ............~uuzv??vruxxxrvvvvv?|+vzvv^|^>r> .~``. ..............````..``.<br></br>
 ...........``vux}??vvvvvv????????vwzvv?~^+?v..}w}?|. .................... <br></br>
 ..........```^uuzvvv?}x???>?????+v?>^+v+^+>>` >}wxzv|...........`...^v|.. <br></br>
 ..............vxzv?v??zv+>????>+||>v?vv?|^+^. `|^>}r?`..............wwv.. <br></br>
 ........``-`. ~xxw?vv??vvvv???vv}rxx}vvv++^^  ++^>|  >v-... .......`xx}^. <br></br>
 .......`-~`....rux?vvv?xuxzwwwwwwwzzvvvv???> -rv?vr| +zv~--~~~~~|~-~zzv?^.<br></br>
 ......`-`....`?wuuvvvv?vxwzzxxxxuuxr??v?>+>. `+^---`  -^^>~-.^-.~~.-?vv?+ <br></br>
 ......``.....`^}zv>vv???zuuxwwww}?>++^+??>>|.  ..-`+??^?wv+>+>+.`^~-~ru}?`<br></br>
 ......-`...~|~`-~`.-?vvv?v??>?>?^-|^^|^vzzzv|^|+>v+?}v>>wr?+?>>>?>-..`vyr~<br></br>
 ......~~`.^|`+.....`.?wv?vrv???v?^~~-+^~>+>+^+`??v>~^||~>>>>vrwwzz?.. .>v-<br></br>
 ......~>+|^ -r. ...``.vrv}xzzwzzzz}??+?|`-|+++-+vr^^^^>>~^>?rzxxxxx}+.    <br></br>
 ......|???~``>.`-...|.-zvvzuxxxxxuz}}}}}v|-~-~|>>|^|+>vz+>?}wwwxuuuuuv-.. <br></br>
 ......-?v?>-`-~v>...`-.vrv}uxxxxxxxxwv}vvv??>+?+~|^^>>>??vv}r?+>}xuxuux>` <br></br>
 .......>>~>`-``-.~>^`|.>wv?zuxxxxxxxwvv??vv??^~-|^|>>>>+}}r}}v>||?vv}wzur`<br></br>
 .......~- -`.-`+vvrr?+.>w??vuxxzzwwwv>??vvv?+|~|^^+>>>|~~^++>v?^~~~~|+?}z~<br></br>
 ........~..`.~> ~>?rv+.?rv??xzwzzx}>|||||^^|||^^+>>>>+--~~~|+>^~~---~~|?w~<br></br>
 .........-....-.  |?v?-vv???vuxxxx+|^?}}}vv?>+>>>>>>>~----~|||~~~-----|vz|<br></br>
 .........`^. .......^wv?vvvvvxuxxuw?>>>>????>>>>>>>>?``````````~|-`---|?v-<br></br>
 ..........->>>~.....`?u}v}v}vvuxxxuuzv+^^+>>>>>>>>>?v`.........-|~```-~^^.<br></br>
 ............?}?`......zxvv}vv?xuxxzww}vvv^+>>>>>>>>?w`..........`````-~||.<br></br>
 .............-+~..... ?yw?v}v?vuwwzxxwv}}>^>>>>>>>>>z>``.........``----~~.<br></br>
 ...............````.. `xx}?????xuxxxxwv}}+^>>>>??>>>vr>^|~.......`````-~~.<br></br>
 ...................... ?x}v???v}uxxxxwv}>~v>>>>>+^^+>>>+|~........`````-~.<br></br>
 ...................... `xx}v??}vzuxxxrv>~?}>>+|~||~``-~-`..........`````-.<br></br>
 ....................... ?uwvv??vvuwwwwv^^?>+|>?>>??..................````.<br></br>
 ....................... -ux}v??vvzxxux}>^^^+vvvvvv}|................`````.<br></br>
 ........................ vuwv????vxzzz}vv??v??vvvvv?....................`.<br></br>


            </div>,

            <div>

 ...^r>+>>>>>?vr}}}}}}}}}}}}}}}}}}>??v???vv}v-...  ..  ....................<br></br>
 ....vx}vvvvvvvzyuuxxxxxxxxxxxxxxxzvvv?????rxr`.....................`````-.<br></br>
 ....`wx}?vvvv}vzzwwzzzzzzzzzzxxzxxw>^+>>??vwx?`................. ..`````-.<br></br>
 .....|xx}??vv}vvxuxxxxxxxuxxzwwwrw?|~^+>?v??vw>`...............~+-`....`~.<br></br>
 ......>uzv???vvvvuuxxxxxxw}vv??v?>~~~-||^^^^^?w?-............. >xv+..-`.`.<br></br>
 .......vxr????vvvvuxxuuwvv??????>+??v>---````^vw}>-... ....... -xrv` -~^|.<br></br>
 .......`wuwvv?vv}vrxzw}>???>??>>>vzxz?`    .-+>vrwwvv>~. .......rur?^^~``.<br></br>
 ``......~uuwv?vvvvv}vrwv>>???vv}wxxu+  -`--~|+>>>>?rzxzv|.```~-`?wv?>-... <br></br>
 `........>ux}?v}}vv?ww}v}wzxxxxxxxxx}  >^~~||^++^^^^^+^|~~^-``^``>rx}?`.. <br></br>
 ..........vux}?vvv??}rrxxxxxxxxxuuxxz|~}w?-~~|^++^~.  ...`?+`.~|..`?uzv-. <br></br>
 ......... .wzr?vvvvv?vxuxxxxxxzzv>>>+^?wwz^.`..`..`|~+}v>>r?^-......-vw?. <br></br>
 .......... ~xurv}}vvvv?uxzzzwwr?--|^+|?rwzv```|?|+}v?^?}?>?v}?|.   ...... <br></br>
 .....`-``...?uzvvvvvvv?vzwrvv???|~~||`>>>vw++~->?|+???>??v}rwr}?^~`...... <br></br>
 ....-^|`.....vuwrvv???v?vv?????>-----+v?|?}||^++r>+>>>>?v}}}}wrww}v+?^... <br></br>
 ...`-....... .rrvv}}vvv??vxzwwzz}v??v?????>^+>^?v>|||^+++>>??vvvwzw}rwv-. <br></br>
 ...``......`~^|-.^?>vwrvvvwuxxzwzxxuxxzz?^??>?>^|||||^++^^^^^^>>vwzzzzzv+.<br></br>
 ...~`....~+|`.....~+-?zrvv?zzzxxxxxxxxxxzv?+>??|-~~|^^^+^^^>>>>?rwzxuuxxx|<br></br>
 ...~|...~+.-v`....``^.>xwv?vxuxxxxxxxxxxxxr?vvvv|-~|^^^+^^>vv??vr}??}xuxx|<br></br>
 ...`?...?. `r. ..`~>-|.>xrv?vuuxxxxxxxxzwwxrvvvvv|-||^^|^^>}rr}}}}?^|>rww~<br></br>
 ....>>.`>.`.>..^+^>??-|.rz}vv}uuxxxxzwzzxxxzvvvvv?-`-~~~~~||^+???vv>|~~|^`<br></br>
 ....`v~.>.-``^?r?-+>?+~->urvvv}uxzwwzzzxxxxxrvvvvv?``````-``---~|>>^~----.<br></br>
 .....+v-^>`-----. -~|?-++z}vvv?}zwzzxxxzrwxxz?+>>vv?``````---~~~^^~---`--.<br></br>
 ......>?|??-.-`^?>`..^^~}xvv????wuxxxxr^+?vxw|+?r?vv>``````````-----|~`--.<br></br>
 .......^^|>~ `> .^-...`-rv?vvv}v?zuxxxz?^?v}v|>>v?vvv+.....`.......`~|-`-.<br></br>
 ........||.~`.~.  .....?wvv}}}v}?vxuxxxuv^??}^+>>>vvv?^.............`````.<br></br>
 .........|- .......    >uwvvvvv}vvvxuuzv?|>>>^^>>+?v?vv|..............```.<br></br>
 ..........||. .....     vu}?vvv}v?vvuz?|>>>>>>>>>>>>????-.............```.<br></br>
 ........ . `+~. .....  .^vur?vv}}?vv}x?|+?>>>>>>>>??>++|^-............```.<br></br>
 ........... .-^~`...`..-`.}urvvvvvv}v+|||^+++>>>>>>???>>??`.............`.<br></br>
 ............. .`~~~|^~`.. .wuv??v}?}v|^>>>>>+++++++>>????v?...............<br></br>
  .............   ......... -wzrvvv?v}vvzuzzr}r}}}vvvvv??vvv>............`.<br></br>
 `................   ....... |xz}vv??vvvvzzrwxzzzzrvvv?vvvvvv+..........``.<br></br>


            </div>,

            <div>

 ...^r>+>>>>>?vr}}}}}}}}}}}}}}}}}}^`~||^??vvvv}}?~...  ..  ..........`.....<br></br>
 ....}x}vvvvvvvzyuuxxxxxxxxxxxxxxxw^|+>vr}v???vwxz+.....................``.<br></br>
 ....`wx}?vvvv}vzzwwzzzzzzzzzzxxzzxw+^+>>+++>>>?wxw-............  ..... .`.<br></br>
 .....|xx}??vv}vvxuxxxxxxxuxxzwwwrw?|~|^^^^^+?vvv}z?-...........-+`..```.. <br></br>
 ......>uzv???vvvvuuxxxxxxw}vv??v?>~~~-||||||^>>^+?rv~......... >xv-..`~-. <br></br>
 .......vxr????vvvvuxxxuwvv??????>+??v>--```..~~||^?w}>`....... -xwv` .-~~.<br></br>
 .......`wuwvv?vv}vrxzw}????>??>>>vzxz?`    .`--~~^+?rzr?>+-..  .rur?^^~.. <br></br>
 .....`..~uuwv?vvvvv}vrwv>>????v}wxxu+  -`-`----~|^+>??vwzw}>---`?wv?>` .`.<br></br>
 ....``...>uxr?v}}vv?ww}v}wxxxxxxxxxu}  >^------~|^++^~``-~|~>v>``>rx}?` . <br></br>
 ..........vux}?vvv??}rrxxxxxxxxxuuxxz|~}w?`-~~~||^~. .....^++r?+`.`?xzv-. <br></br>
 ......... .wzr?vvvvv?vxuxxxxxxzzv>>>+^?wwz^....`..`|~+}v>^?>+?rw?.  -?w?. <br></br>
 .......... ~xurv}}vvvv?uxzzzwwr?--|^+|?rwzv```|?~^???^?}?+>vrzzzzv|.  ... <br></br>
 .....`-```..?uzvvvvvvv?vzwrvv???|~~||`>>>vw++~->?~+>??+>?vv}}}}wzwv?|`... <br></br>
 ....-^|`.....vuwrvv???v?vv?????>-----+v?|?}||^++r>+>^^|^>???v}}}}rwwwv?+^`<br></br>
 ...`~....... .rrvv}}vvv??vxzwwzz}v??v?????>^+>^?v+|||||||^^+>???v}}vrww}v~<br></br>
 ...``......`~^|-.^?>vwrvvvwuxxzwzxxuxxzz?^??>?>^-`~|||||||++^^^^^+>>?rzzw~<br></br>
 ...-`....~+|`.....~+-?xwvv?zzzxxxxxxxxxxzv?+>??|`--~~~~|||^^^^^^>++>vrzxu|<br></br>
 ...~|...~+.-v`....``^.>xwv?vxuxxxxxxxxxxxxr?vvvv|-----~|^^^^^^^>v???}r}vr~<br></br>
 ...-?...?. `r.  .`~>-|.>xrv?vuuxxxxxxxxzwwxrvvvvv~````-~^^^^^^+vwr}}}rv+|.<br></br>
 `...>>.`>.`.>..++^>??-|.rz}vv}uuxxxxzwzzxxxzvvvvv?-````-~~~~~~|+>??vvvv?^.<br></br>
 ....`v~.>.-``^?r?-+>?+~->urvvv}uxzwwzzxxuxxxrvvvvv?`````````--```-~~|+??|.<br></br>
 .....+v-^>`-----. -~|?-++zvvvv?}zwxxxxxzrwxxz?+>>vv?``````````---~~~|^^|-.<br></br>
 ``....>?~??-.-`^?>`..^^~}xvv????wuxxxxr^+?vxw|+?r?v}>`````````````--~~~-~.<br></br>
 `......^^|>~ `> .^-...`-rv?vvv}v?zuxxxz?^?v}v|>>v?}vv+.......````..`````-.<br></br>
 ........||.-`.~.  .....?wvv}}vv}?vxuxxxuv^??}^+>>>vvvv^.................`.<br></br>
 .........|-  . ....    ?uwvvvvv}vvvxuuzv?|>>>^^>>+?v?vv|................. <br></br>
 `.........||.  ....     vu}?vvv}vvvvuz?|>>>>>>>>>>>>????-................ <br></br>
 `......... -+~. .....  .|vur?vv}}?vv}x?|+?>>>>>>>>??>++|^-............... <br></br>
 ........... .-^~`...`..-`.}urvvvvvv}v+|||^+++>>>>>>???>>??`.............. <br></br>
.~`........... .`~~~|^~`.. .wuv??v}?}v|^>>>>>+++++>>>>????v?.............. <br></br>
.`.............   ......... -wzrvvv?v}vvzuzzr}r}}}vvvvv?vvvv>............. <br></br>
 ....`.....          .... .. |xz}vv??vvvvzzrwxzzzzrvvv?vvvvvv+............ <br></br>


            </div>,

            <div>

`?+>???>??vr}v}}}}}}}}}}}}}}}v|>~-``--~~~||^+?v}}}}v?-...  `>~. .........`.<br></br>
`????v}v?v?wxzzzzxxzxzzzxxxxx>-wr^~~~~|^^+>?v}}vvvv}rr>.....vr?` ...```... <br></br>
`}?>>?v}v?vvruxxxxxxxxxxxxxxr?vrzz?~~~|^^^+>??>>>>?>vwr|... .}wv^..~-..... <br></br>
.}zv??v}}?vvv}xxxxxxxxxxxzrvvvvvvrxv~~~|^^^^^^^^+>?vvvrv-... -rwv?^. ..... <br></br>
 `vwv?>?rvvvvvvxxxxxxxxz}v??????vvv}}^~|||||||^|~^??>+>vv|`.`.+v}r?^. .... <br></br>
 .`vw}?>?}?vvvvvzuxxxxxv???>?v????rwzw>~~~~---`..-~|^+++v?|-``.~>}xzv~.... <br></br>
 ..`vw}??v?vvv??vwuxxxr>??v?????--?wxuz?-````. .---~~^>>^???`~|` .->}}?... <br></br>
 ....?wv??v?vvvvvvrz}vvv?>>>?>>>^?vv}vv}?>v}v>~--~|^^||>>?v?>vr}?|`..~~... <br></br>
 .....?zr?v}vvvvvvv}}wxzvvv}}}}rzx?^++~?}wrrwz?|+^^^`-~|+^||^?v}wrv|...... <br></br>
 ......>zrvvv}}vvvvvvzwvrzxxxxxxx?`|^||?>vrrwv|`.  ... .||~||||^>v}}>``... <br></br>
 .......+zwvvv}vvv?v?}rzuxxxxuuxr++~-~>>>?rv^. .`....^+>+^+??>>>???}r?-... <br></br>
 ....... ^zw}vvvvvvv?vvzuuxuxr}?>>+~|>?>|++^|`^?wv-|^++>???vvvv?vrrrww?`.. <br></br>
 ........ |wrvv}vv}}vvv?rww}????????>^`..-|>+^>^?rv+^~|+>>???vv?v}}}rwr?+-.<br></br>
 ........  -wx}r}vvvvvvv>?v??vvv}www?>>>|-||^+}vvv^`|}+^++^^+???vvv}}}rwr}-<br></br>
 .........  `rrw}vvvvv????>}uxxxxxxw????|-~~?rxr?v>-|v?~|||||^^++>?vv}}rrr~<br></br>
 ......``..`~+rrw}v????v}??vzuxxxz}vv??vvv}rxx}+~^?^+?+-~||||||^++^^^+>>?v-<br></br>
 ``...--.....`|}wrvrw}}wrrvv?wuxwvvvvvzxuuxzwv^>>^++>>|-~~|||||^^+^^^+++>?-<br></br>
 ``..~-.......`?v??>?}rzxxz}?vvvvvvrzuuxxxxx}?^>+^^+>+?^-~~~|^^^+++^+????v~<br></br>
 .```|...`. .++~+????>??ruxxv}?>?vxuxxxxxxxw?>>+>^^+++??>~--~|^^+++^?}rvvv~<br></br>
.--`-|`..`.~+~..~>??vv>>^vxxz}}vvruuxxxxxw}?>>>+|+>++vv???|`-~||||||+?vvvv-<br></br>
.>~``|`...~+. >v-^>>???>`^?zxxwvvvvxuxxzv??>>>>|`+>+?vw}???+`````---`--~||.<br></br>
.^```|``..>.  |r-^|.`|+|`.^?zxxwv}??wuwv?>>>>>>>>>+?}wwwrv??+-.````-`-~~~~.<br></br>
.-``-|-`..>.`..?~`~+?`....`|vxzrv}vv????+++++++>>>?rzzwwwrv>??^`````-----~.<br></br>
.^+^~-^...|`-^```|?v>`...``.|zwv}v?v?+^+vv}v+|+??vrzxzzwwwr????>`.````````.<br></br>
 --|-.~-...^. `---`.   ..``.`rxrv??}}}?vzxr>|>??vrwxxzzzwwvwr????~........ <br></br>
 -.....|`..`|.~~.`.|>^-````.`}wvvvvvrr}vvv?^>??v}wzzxzzzz}rzwrv??>+....... <br></br>
 .......^^`..|-+~`^ .|>?```.-vrr}vvv}rr??+~^+?}vrrwwzzzzz}wwwwwv?>?>`..... <br></br>
.....`...|+|+>???-^-   .....`|rzrvvvv}rv>?>>vz}rwwwzzzzz}wwwwwwwv????~.... <br></br>
 `.`......~^????>~..... .. .. ~rz}vvvvv??+????zzwwwwwzzrwzzwwwwrvr????+... <br></br>
 ``..``.....~>??>?^.......  ..+^^zxv?vv????vv??wzwwwwzwrxzzzwww}}wrv???+`. <br></br>
 `...-`.......|??>~...   .  ..|~ `vxwv??v}v}}}vvrxxxxzrzxxxxzwwvrwwrv?>??| <br></br>
 .-`.......... .^?+`.......  .-`...>zrvv?vvvvv}vvvzzz}wzzzzzzw}vrrrrrv>???`<br></br>


            </div>,

            <div>

`?>??v????vr}}}}}}}}}}}}}}}}}v~+````````--~~~||+??vvvv}}+. `?~. .......... <br></br>
`?vvvvvv?vvwxzzxxzxxxxxxxxxxx+`r}|``----~||^^>?}}v???v}zxv..vw?` ....``... <br></br>
`r??vvvv?vvvruxxxxxxxxxxxxxxr>vrzz>----~~~|^^+>>>++>>>>}xx+..wx}^..~-..... <br></br>
.wu}?vvvv?vvv}uuxxxxxxxxzwrvvvvvvrxv~-~~~~||^^^^^^^+>?vv}zr|.-zu}>^. ..... <br></br>
 `ruwv?vv?vv}vvxuxxxxxxzvv??????vvv}}^-~~~|||~~~-||||+>++>vv|.^v}wv^`..... <br></br>
 .`}uw}v?v?}}vvvzuxxxxuv???>?v????rzzw>-~~~~~-`..-~----~^+^+?^`~+vxzv|.... <br></br>
 ..`vuzvvv??vvv?vzuxuxr>??v?????-~?wuux?~---`. .-----~^^~^>>>>?v>++?v}?... <br></br>
 ....vxrv?v?vvv}}vrz}vvv?>>>>>>>^?vv}v}w?>vrv?~--~|^^|~+|^+>+>?rzxzv>||... <br></br>
 .....?uz}vvvvvv}}v}}wxzvvvvvvvrzx>^++|?rwwwzz?|+^^^`-`-+^^>>^|^+?vwz}^... <br></br>
 ......?ux}v?}vvvvvvvzwvwxxxzzxxu?`~|~^?+?wwzv|`.  ... `|^|^^^^+^||>vwz?`. <br></br>
 .......>uxr?v}vvv?v?}rzuxxxxuuu}^>~`~?>^?rv^. .-....+++~^+>>?vv?>?vvrzxv~ <br></br>
 `...... ^xur?vvvvvv?vvzuuxuxr}?>?>||>?>~+^||`^?zv-~^+|-~+????v}v??}wwzzx}`<br></br>
 ``...... |zzv?vv}}}}vv?rww}?????????^`.`-|++^>^?wv+|--~~|+>>+>?vv?v}v}rwz~<br></br>
.```.....  ~zurrvvvv}}vv>?v??vvvrzxx}>>>|~^|^+}vv?^.~}^`-|^^^|||>????v}}}w~<br></br>
.`-`.... .  -wzw}vv}}v????>}uxxxxxxw????|-~~?wur?v>-|v?`--~||||||^^++???v}~<br></br>
 ```...``..`|>wwxrvvvv?v}??vzuxxxzv??>?vvv}wxx}+~+?++?+``--~||||||+++^^^^^.<br></br>
 ..``.~~.....`^wxz}wx}}wrr}v?wuxrv???vzuuuxxz}^>>^++>>~``--~~~~||^^^+^^^++.<br></br>
 ````~-..... .-?wv??vwwxuxzr?v?vvvvrzuuxxxxx}?^>+^^+>+?^```----~^^^^^^^+?v`<br></br>
 ```-+`......++^??v??>??wuxxv}>+?vxuxxxxxuxw?+++>^^+++??>-````-~|^^^^^^?rw~<br></br>
 ``|^^```..~+~..|>??vv>>+vxuz}v??ruuxxxxxrv?>>>+~+>++vvvv?|````--~~~~~|^>?`<br></br>
.^>v?+`...~+. >v`^>>???>`^?xuxzv??vuuxuwv?>>>>>|`+>+?rxwvvv>`..`````---``-.<br></br>
.+>>^+-`..>.  |w~+|.`|+|`.|?zxxwv}?vwuwv?>>>>>>>>>+?rxxxz}vv>-.```````---~.<br></br>
.>+|~+|```>.`. v~`~+?`.....~vxxwv}vv????+^^^^^+>?>?rxxxxxxr??v^...````````.<br></br>
.-~^>>?|`.^--+-`-|v}>`......|zzv}v???^|+vrrv^|+??vwxxxxxxxzvvv}?`.`..``... <br></br>
 ```-`+~...^. `--~-.   ......ruwv??v}v??zuz?|>vvvrzxxxxxxx}xz}vv?~........ <br></br>
 `.`-..^-..`|.~~.`.|>+~`.....}zvvvvvvvv?vv?|>?vrrzxxxxxxxwwxxz}vv?+....... <br></br>
 ```....>>` .||>~`^ `^?v-...`vrr}vvvvv}??+~^+?wrzxxxxxxxzrxxxxxwv?v>`..... <br></br>
 ~^|`....^>^+?v}?-+-   .... .~rxr}vvvvvv????}xrzxxxxxxxxrzxxxxxxvvvvv|.... <br></br>
 ``````...~>vv???|....        ~rxrvvvvv}v>?vvvxuxxxxxxxwwxxxxxxx}z}vv}>... <br></br>
 .`.`..`~-..|v}v??^...        +^^zxvvvvv???}vvvzuxxxxxzwxxxxxxxwrxzrv}v?-. <br></br>
 ...``..`....`^v}?^...        ~- `vuwv??v}vv}vvvwuuxxzrxxxxxxxxrxxxxw}?vv^.<br></br>
 .``.-......`..`>v?` . ....  .`.   >xrvv?vvv}vvvv}zxz}zzzzzzzzw}zzzzzr??vv`<br></br>


            </div>,

            <div>

.??????>??vr}}}}}}}}}}}}}}}}v|````````````--~~||^??vvvvvvv?>`............. <br></br>
`vvvvvv??vvrxxxxxxxxxxxxzzzzxv^~~``-----~~~||^+>?vv?????vv}rv-............ <br></br>
`w}vvvvv??v?}uuxxxxxxxxxxxxwr}v?>~------~~~~|^^^+++^^+>??v}}}?~`.......... <br></br>
`xxrvvvvv?vvvvzuxxxxxxxxxzrvvvvvv???~`--~~~~|^^^^^|^^^+?w}v?vv?+~`........ <br></br>
                  .vuxwv?vv?vvvvvwuxxxxxxxwv?????????^~--~~~~~|||~--||~||~?wv?+?v?|`........ <br></br>
 .?uxzv??v?vvvvvruuxxxuz??????vv???v>~~~~~~~--...`~~-~---}zv?>?vv>|-`. ... <br></br>
 ..?xuz}vv??vvvvv}xuxxw????vvv??>|~|+>+~~--^?+`.-~-~~~~-`~?vv??}rr}v}?-... <br></br>
 ...+xxwv?v???vvvvvzwvv?>>?????>|>rww}?^~|?wzr}|--~~--~-`~|>vwrv?v}rww}>`. <br></br>
 ....|zuxrvvvvvvvvv}rrxxv>???????v}w>|++^vwwwww?||||~``~`~~~^+?vv>+>>vvvv^ <br></br>
 .....~wuxwv?}vvvvvvvvwrvwxxzzzxxw}?`-~~|vv?vw}?.   . ...`-~^^^^>++++>?vv}`<br></br>
 ......`ruxzvv}vvvvvv?}wzuxxxxuuxrv+^^--^?v>?vv|  ...-+~---~+>???vv??v}rrr-<br></br>
 .```....vuxz?vvvvvvv??v}xuuxxz}v?>?>>^^~+|-+>^-~-`++^-`-~||+?????v}v?}rww~<br></br>
 `````....?uxr?vvvv}vvvv}v}}vv????????>|-``~|^+^~^>>-.`--~~|^>+++>?vvvvv}r~<br></br>
 `-~`....  +uurrvv}}vvvvv????vvv}rwzzwv?+|^-^|^+??|.`~``->?-|^^||^^+>>??vr~<br></br>
.||`......  ^xxwrvvvvvvv?v?vvvuuxxuxzzv>?>>-^+?}w?-``~-`->r~-~||||||^++>>>`<br></br>
.-`....````-~>zzxw}vv???r??vrr}xuxzzw??v>>vvvwxr?|?||~-`|>?--~~|||||^^+^^^.<br></br>
 `....~~--^|^~^rzxzzxxrwwwrv?}v?rxww}?vv}wzzzzrv>^>++^~|+>|`--~~~~||^^^^^^.<br></br>
 `..`~----~||~~>?^+>vrzxuxxx}}>}zz}rvvwxxxxxxw?>^^+>>++^+^------~~|^^^^^+>`<br></br>
 ```~^^|^|||^?>~``..``~>ruuxrz}?wxvvvruxxxxx}?>+++>^^+>?+~``````-~~||^||+?-<br></br>
.~~^+>>++^^??-...``````.`+wuzrzrvvv??xuxxxzr?+>+>>^``>>+??^```````---~~~~|.<br></br>
.^>??vv>+??+.`?+..``````..-}uxzwwv?rwrzxxz}>+>>>+>+++>>?xwv?-..```````````.<br></br>
.>??>??+?>~.-|vv......`....`}uxxzx?v}}v}w}??>>++++>>>>?zxxw}v|`..`````````.<br></br>
.>+++++>>|``-`>v. .`>-......-rxzrwr}v???>^>>^+>>+|+>??wxxxxz}v?-...``````` <br></br>
.>^||~^^^`>+`.-^.-?}v^.......>xzwrv??>??v>^>v}}v?^>vvvuxxxxzrw}v^`......`` <br></br>
.~--|`--`~.~~-^^+>?~.  ......|xxzv>?vvvv}rv?vwxv^>vr}xxxxxxxwzzrv?-....... <br></br>
 ```~```.~` ..--``.``.. .....~zw}?v}vvvvvvv>>??^+>?vxxxxxxxzwxzzw}?|`..... <br></br>
 -~--|`.``|..?+`-``+>??>`....|rvrrvvvvvvv??>+?+^+v}xxxxxxxxzzxxzzzr?>~.... <br></br>
.^~--~|````~~|`.`|^`.`|?~....|~~}xr}vvvvvvv?vr}vvwxuxxxxxxxwxxxxzzz}v?+`.. <br></br>
.-`````~`..`~-...`?^-...... ...``?zw}vvvvv?vvv}rr}rxuxxxxxxzxxxxxxzrzr?>~. <br></br>
 `--...`~-`~-`````~~......    .?.-+vxz}?v?vvvv?}rr}}zuxxxxzzxxxxxxzwzzrv?+.<br></br>
.~```..```-`...`-||-````...   .>..`.+wxwvvvvvvvv}rrr}}xuxxwxxxxxxuzzxzzwr?`<br></br>
.```.-`.`..-`.`-~||~--```...  .......`?zwrvvvvvvvv}r}vvwxwwzzzzzzzzzzzwzwv`<br></br>


            </div>,

            <div>

.??????>??vr}}}}}}}}}}}}}}}}v~.`.`````````````-~~||+?vv??>?vv}v` ......... <br></br>
`vvvvvv??vvrxxxxxxxxxxxzzzxxx}+``````-----~~~~~|^^+>???>>>??vzx?.......... <br></br>
`rvvvvvv?vv?}uuuxxxxxxxxxxzr}r?>^`````----~-~~~||^^^^^^+>+??vrzz+......... <br></br>
-xz}vvvvv?vvvvxuxxxxxxxxxz}vvvvv???>~``---~~~~~||^|||||>w?>??>?vv|`....... <br></br>
.}uxwv?vv?vvvvvwuxxxxxxxwv??v??????|```---~~~~~~--~~~~~-?zv>~^>+vw?-. .... <br></br>
 .vuxz}??v?}vvvvruuxxxuz??????vv??>?^``---~--`...`~~~---~}xv?+^+>}zr?|``.  <br></br>
 ..?uxz}vv??vvvvvvxuxxw????vvv??>^~-^^~`---+??`.-~~~~-~-`~?vv?+^+?v}wzw}?|.<br></br>
.`..>uxwv?v???vvvvvzw???>>?????>|>wzw}?~~~?wzzr|---~--~-`~|>}zw?>>>+>?vrzz-<br></br>
 ....^xxxrvvvvvvvvvv}}xxv>???>>???wu?~++|vwwwww?|||^|``~`~-`-^?}}>||||||>}-<br></br>
 .....~zuxwv?}vvvvvvvvwrvwzzzzxxxuuv`-~~|??+?wwv.   . ....```--^>>+>?>+^>?`<br></br>
 ......`ruxzvv}vvvvvv?vrzuxxxxuuxzr>>+`-|??^vwr+  ``.-+-````--~>???vvvv?vr~<br></br>
.``````..vuuz?vvvvvvv???vzuuxxz}v?>??>||~>|->?+``.-+>^`````-~~~+>????vv??r~<br></br>
.-````-`..?uxr?vvvv}vvvvv?v}vv?????????>~``~|^+^~|+>-..````-~-~^++^|^>??v}~<br></br>
.|--~~`..  >uurrvv}vvv}}v?>>??vv}rwzzz}v>>>-|~^^??|..```-??`---~||||||^++>`<br></br>
.~^^-... .  ^uxwwvvvvvv??}v??vuuxxxxuxv>???-||>}w>-`.```~?w~----~|||||||++.<br></br>
.|`.. .``-~~~>zzxw}vvv??rv?vv}}zuxxxz??v>>??vwux}|?|-`.`|>?`-``-~~|||||^^^.<br></br>
.`...`~~~~?+>^^rzxzzxxrwwzrv?v>?rxzz}?vv}wzzzzz}>^>+^|~^>>|````---~~~|^^^+.<br></br>
.>~.`~~~~~|+++|+>|^>vrzxuxxxr}>}xzr}vvwxxuxxxzv?^^++>^^++^-```````--~|^^^^.<br></br>
`?>~^++^>?+>>?>-......`+}uuxrz}vzu??v}uxxxxxwv?>+>>^^+>?+~``````````-~~|||.<br></br>
.>|+>?>?v}v?>`...........^wuzrzwvv?+>zuxxxxwv?>>>>^``+>+?v^`...`..``````--.<br></br>
.>^>?}v}wzv+.-?+..........`vuxzwwv?vvvwuuxz?>>>>>+>>+>+?xwv?~........`````.<br></br>
.>>??v}v}v~`|>rv............vuxxxxv?vvv}zz??>>++++>>>>>zxxzrv|`.......```` <br></br>
.+>v?^>??^`|~`?v   `>-......`ruzwwr}}v???+??^+>>^|+>?>ruxxxx}v?-.......... <br></br>
.>?v>~-`+-+>` ~+.-?rr+.......+uzwrv???vvv^~+rxxw?~+vvvuxxxxxww}v+`........ <br></br>
.??>^-``-+.~^|>+>>?|.  ......-xuzv>?vvvvvv?v}xuv|^?}vxxxxxxxrxxwv?~....... <br></br>
.>+|^|``.+-  .-~````..  .....`zw}?v}vvvvvvv?v??~^>?vuxxxxxxzwxxxz}v^`..... <br></br>
.~|^|+~~~`+``v>````?v??+`....~}?rr}vvvvvvv}v>v||^?vzxxxxxxxwzxxxxxw??|.... <br></br>
.>>>^~+~--`~~~. .+>-`->v~....|``}xr}vvvvvvvv?vvv?rxuxxxxxxxrxxxxxxx}}v?`.. <br></br>
.++^---|-``.``.  `r?~`-``.  .. ..?zz}vvvvv?v}?v}vv}xuxxxxxxwxxxxxxxrzw}v^. <br></br>
.-`---`.~~`..-`...~^`..`..    .v..-vuz}vv?vvvv?vvvv}zuuxxxwzxxxxxxzwxxw}v?.<br></br>
.~~^|````-|~....`~||~~~-`...  .?... ^wuzvvvvvvv?vvv}vvxuxxwxxxxxxuzzxxxxrv`<br></br>
 -|~-`.`~``~-...->?>^^^|-``.   ..`.  .?zzz}vvvvvvv}vvvvwxwwzzzzzzxwzzzzzzv`<br></br>


            </div>,

            <div>

.??????>?>^vrvvv}}}}}}}}}?---`..........```````--~~|^+????>>?????>. ...... <br></br>
`vvvvv}vvv?>}zxxxxxxxxxxxx}+--```````````----~~~||^^+>??>>>>??vvvv^....... <br></br>
`w}vvvvv??v?+}xuxxxxxxxxxzxz?~^??~-``````---~~~~~|||^^^^^^+>??}rv??~...... <br></br>
`xz}vv}}v?vvv?>ruxxxxxxxxxzrvvvvv??>-````----~~~~||||||^>++^++???>??~..... <br></br>
`wxxwv?vv?vvvvv>?xuxxxxxxwv????????>-````---~~~~--~~~~~~+>^|~~||+?>??>-... <br></br>
`vzuxz}v?v?vvvvv?>ruxxuxr??????v???>~.```-----`. .`~+~--~++^|~~||+?vvvv?|-.<br></br>
 |vwxxxrvv??vvvvvv>vzw}v???vvv???>~----``--~+^. `-~->?|-`~+^^||||^+?vv}rw}-<br></br>
 .~vwxxz}vv????vvvvvz}v}??>>???>+^?wv>|---~^?r}|-----vv^..-|+?>^|^+>>+>>?v-<br></br>
 ```>rxxxw}vv}vvvvvvv>?xzrv?????vvzu}>^|^++>rww}??>+-~^`--``-~^>+||^^^^^^>`<br></br>
 ```.+}xuxz}?vvvvvvvvv>>wr}wzzxxxxxwv?--`-~|v}vv????| .~??>-```~||+>>>????`<br></br>
 ````.|vxuxz}vvvvvvvvv}vrvwuxxuxxxw}v?|-~`-^?}v?>??+---``|>>^`-~|^???vvvv}-<br></br>
.-`-````?zuux}vvvvvvvvvvv>+wuxzw}v??>>>>^-||++|-~~~~~````.`-~--~|^>>>????v`<br></br>
.--~-`-`.|wuxzvvvvvvvvvvvvvv+??v??????vv?^~`-~++^||^`.````````-~~|^^^^^+>?`<br></br>
.|~~|~`.. `vuxzr}v}v}}}}v?v?~|?vv}wwww}v???|~~~^^+~..``^~|^````---~|||||^+.<br></br>
.^+|`.......?uuzw}v}}vvv?vv???wrwuuxxxwv?>+^^|~>>+`..`-|^|>``-+-`-~|||||^^.<br></br>
.>~.......```+zuxzr}vvv??}?v}}}}??rxxwrr?>??>vrzzv?~``-|^+>~-|?~`--~~~|||^.<br></br>
.` ...`...-~|`-}xxxzxuxxwwwr}w???>`>wrzwvvvvrwzzv+??^~|+>>^||+^`-----~~|^^.<br></br>
 `..`...`||^^|`^?>+>?}zuuuxxww>}xw>|+vr}rrzxxzww?+>?>+>+|+^|^|``````--~|^^.<br></br>
.^~``````-~|+??~......`^vzuuwzvvxyz?``>v}zuxzwwv>>>+>>+^-^^||````````---~~.<br></br>
`}>^+^|+^|>?>`...........-vxxrzw?}v?-+?v}zuxwrr?+>+^^>++>>+~......````````.<br></br>
`vv?>>>??v?-.`~`...........+xxzzwv?v}}}wrv?rwv?>?>>++>+>>>>?~..........``` <br></br>
`vv??>??v|..`?r> ...........>xuxxxrvrvv}rr?v?>>?+^+++++?>>}xr+`.........`. <br></br>
.????>>?~..`.~}>  ..``.......?xxxwrr}v???>??~|^^>??>?^+??vxxzw?|.......... <br></br>
`vvv??^~`.``.`?^..`+v?`....`.`rxxzwv??vvv>^>}v>|vv?v?^>rvzxxzzwr?-........ <br></br>
`}}vv>^~`~``..>|.~??+`..`|~`-`vxxzr??vvvvv?v}wz>`^vr+>}}rxxxzxzzz}+`...... <br></br>
`v?>>>^~.^?>~`>>|~+|. ..-^|`~|?xzrr}}vvvvvvvvvv++^^^>>?rxxxxzxzxxzw?~..... <br></br>
.>>+>?>+--+^-~~~+-.+>?+~~||~->?wvvww}vvvvvvv?v>>>?v?++wuxxxxxxzxxxzw}>`... <br></br>
.^+>>>?+|`-~>+...~-+>???|-~~.+++~>wwr}vvvvvv?vvvvrrr}??ruuxxxxwxxxxzzrv|`. <br></br>
.++++^^|~```||. `~-```|+^```.`-..|vv}zw}vvvv}vvvvv}}ww}??zuxxxwxxxxzxwwr?|.<br></br>
.^>|~|~---.......+|......... ..--`~>++}xzwvvvvvvvvv}}w}rv?vzuuzxxxxzxzwzwv`<br></br>
.|^^~|^-``-`.....`~........   .`^~..`-~+}zwwr}vvvvv}vv}wwwv?}uzxxxxxxxzxzw-<br></br>
.-----`~~``~-`...```........   .~-.. .---?wwrwr}vvvvvv?rrr}}??wzzzzzzzwzzw-<br></br>


            </div>,

            <div>

.??????>?+`vrvvvv}}}}}}}}>...............`````````---~~|+>?>>>>>>>?v?` ... <br></br>
`vvvvv}vv}?|?zxxxzxxzzxzxxv~...```.````````----~~~~~||^^++>>+>>???}ww>.... <br></br>
`z}vvvvv??v?|?zuxxxxxxxzxzzw>`|?v+^`````````----~~~~~||^||^^^^+>?vv?vr^... <br></br>
`zw}vv}}v?vvv?~vuuxxxxxxxxxwv?vvvv?+~-`.````----~~~~~|||||||||||^++>>?}>`. <br></br>
`xxxwvvvv?vvvvv^>xuxxxxxxzvv?v>?????>~`.````---~-----~~~~~~~~~~~~~~^+>vrv^.<br></br>
`uuxuz}vv??vvvvv>~}uxxuxzv?????vv???~...````---`..`~?|--~~-------~~|^>?vrw~<br></br>
 +xuxxxrvv??vvvv}?~?zrvv?????vv???^~~`````````. `-~-vr+--~~~-----~~|^+>??v-<br></br>
 .^zuzzzrvv??????v??z}vr}?+>>>>>>^+v?~.``-~-^}v^-~~--wz>..`------~~|^+++^^.<br></br>
 ``-vuxxxz}vvvvvvvvvv|>xxzv>????vvwxux+`^++|}www}v?^-~>-~~`````---~~~|^^^+`<br></br>
 `-``?uuxxzr?vvvvvvvv?|^wrv}wzzxzzxxuz~`~||~??+vzzzz> .+wzv|`````---^>>??v-<br></br>
 ````.+zuxxxr?vvvv}}}vv?}vruuxuxxxzz}???~`-^+?^?rw}?|-```+vr>````-~~^>???v-<br></br>
 ```-~--vuuxurvv}}vvvvvvv|~ruxzwrv??>>??>`+>^|-^+^^~-.```..--````-~~|++>++.<br></br>
 `---~|-.>uuzzv??vvvvvvvvv??|??vv???>?}rwv>---^+++~|..``..`.`````-~-|^^^|^.<br></br>
.||^^--`. ~rxzzr}vv}}}}}}?r?-->?vrrrrr}vv}?+++-^|^-.``-v^.````````---~|||^.<br></br>
.>++-.  ...`vuuzwrvvvvvvvv}?v?v?ruuuxxxrv?+??>-^++`..`|>?.`.`-?~````--~||^.<br></br>
`?+`...``````^zuwxw}}vv?>}?}}}}v^^}uuxww?>v?+?}zux?~.`|++~|^~+w^````--~~~|.<br></br>
.` .```.....``-vxwzwxuux}rwwrxv>^- +wzxz?v??v}zxzvzz>-^?>>^+^??````````--~.<br></br>
  .``.  `^^^^~`|v?>>?vwuuuxxrw>}zw^-^vvv?vwzzzxx}?}}?+>>~+>+>^`````````---.<br></br>
.`...~+..`-`^??|`.....`|?zuuwwvvuyu?. |??ruxxxxx?v?>>>+|`+>+^`````````````.<br></br>
-xv>??>+`.+?>-...........`?xxwzr?rr?``~?vwuuxxu}>>+>>>+>>>>^`......``...`. <br></br>
`xzr}v?>+?>`.``............^zuzwwvvv}vvvvv?zuxr?v?>>+>>>>>>?-............. <br></br>
`xz}}?+>?-  .vr^ ...........~zuxzxzrz}}}rvvv??vv>^+>>~^>>+vuw>`........... <br></br>
`zrv??+?`    ?z^ .. .........^xxuw}}vv?vvvv?~~^^?}wrv~|>??xxuzv+.......... <br></br>
`zwrrv?>     ~z^  .|}}-..`.|`.vuxxz}???vvvv}}?~-}uuz?~+}vwxxxxwzv|........ <br></br>
`zwvrwv+.~|. .?| -vwv|.`->^`+.?uxuz??vvvvvvvv?v>`^wr|+}v}uxxxxruxw?-...... <br></br>
`w?}z}?>.>ww>-??^^?^. .-^>>`>+^uxwz}}vvvvvvvvvv}?^~~^>?}uxxxxxrxxxxr+..... <br></br>
.?v?v???`~>v+`~^>~.~~>|`|+>^|??xv?zz}vvvvvvv?vvvv??>~|ruxxxxxxrxxxxxz?^... <br></br>
.^>??vvv>`||??`````?vrv>+^++`?vv.~rxw}vvv}}vvvvvvv?vv^|}uuxxxx}xxxxxurr?|. <br></br>
.^^+v>>+|-``+>..|?. .~+v?~||.|>`.`>>}xzrvvvvv}vvvvv?vv?|>wuuxx}zxxxxxwwxr?.<br></br>
.}?~?v^-~|`.    .}>     .....`..+-|~`~}uuzvvvvvv}}}v?v?}?^?xuuwzxxxxxzruxw-<br></br>
.?>>+^+|^|``...  `^.  ...    . .}^.`.  |}xzxzrvvvvv}v??}}v?+vuwzxxxxxxrxxu|<br></br>
.+~-``-+~```..~-.. ........     ?| .... .^wxzxzwrv?vvv>vvvvv>>rzzzzzzz}zzz~<br></br>


            </div>,

            <div>

.???|~-~~|^vrvvv}}}}}}v>|-..................```````----~||+>>+++++>??>^^.  <br></br>
`vvvv?^~??~~vxxxxxxxxxxzv>|`.`~^`...``..``````---~~~~~||^^++++++>>?}wv??~. <br></br>
`z}?vv??>||~`>zuuxxxxxzzxr?r>^>?>^+^~....``````----~~~|||^|||^^^+>??vv?>>- <br></br>
`zzrvvv}?^|||-`?uuuxxxxxxxwrvv?vvv?>`....``````--~~~~~~||~~||~~~~||||>?>+>.<br></br>
`xxzwvvvv??^~|~`|ruuuxxxuzv??v>?????+-...``````----~~~~~~~~~~~~~---~~^>?v?`<br></br>
`xuxxx}v???v>|~|-`>wxxxxzv??????vv??~....```````..`-^~-~~~----------~|+>?v-<br></br>
.vxuxxxwvv??v?+~|~`+xzwvv????vv???>|-```..````. .---+>~-``---------~~|+++>`<br></br>
.>vzxzwzwvv?vvv>|~~~?v?}rv>+>>>?>>>++-```---~+-`-~--->?~ .`--------~||^^||.<br></br>
 `^?wuxxxzrv?vvvv>~-~`-?}zr???????}rr?-.~^+^>??}v}v?+|~.`-``-````---~~||^+.<br></br>
 ``->ruxxxxwvv}}}}v>~-``^vrwvwzzzzzuux>---~~||^v}vwzv^+>?>^-```````-~^^+?v-<br></br>
.~-``~vxuxxxz?vvv}}}v+~~^|?wwuuxxxxz}v??>-```++?vv}v?>??~~+>|`````--~^++>>`<br></br>
.~-``-|?wuuxuzvv}}}}}v?^~`.v}?www}v??????^+-|^~||^+~~-```.`-|`````--~|^^^^.<br></br>
.~~--~~-|vuuxxrvvvvvvvvv>~~}|`-???????v}wrv>^`~|^+^|~^-...`-+|.````-~~~~||.<br></br>
.||^^~|-.`>zxzzr}vvvvvvvv??v`.``>?vwww}vv}v??+-~-^~~`~|^~.`~|-`--``----~~~.<br></br>
.>>>-...`--|wuuxwz}vvvvvvv}v^|+>vrwwzuxzr?>??>|^||`...`||``~|+>||~``--~--~.<br></br>
.?^...`---~-->xuxzxw}vvv?}v}}}r}??v}zzzz}v}?>vv}xz?-..-~~-^+++?^|-`-~>>--~.<br></br>
.. .``...`~-``-vxwzzxxuxwwwwwxrv|`.+vzxw}w}?vvrxxwwz?`~^+>+>^~^^^~~|^?|---.<br></br>
 .``...`^?>|-``^v????}zuuuuuwz?}rv~++?}}??}rrruxw}v}?>++^>>++^|^^^^^^~````.<br></br>
.`.. .+^^||`^>>|`..```~+?wuuzzvvuyx?`.|>>vrrrzuxrwv>>>+||>>++^^^^|~-``````.<br></br>
.>||>~+?+|>?+`......`~|~-~?zxwxr?wzv..~??ww?>?wz}v>>>>+++>++||^|-`........ <br></br>
.?vzzr>+??+......``-``~|^^-^wuzxwv?vvvvvv?^>???vr?>>>+++>+^+^|-`.......... <br></br>
`wzrw}??>`...??~...`-~~~-~+~-}xxxxzwzr}}}vvv????||++^^+>>++??+~........... <br></br>
`}}rv??+.   .vw~..````-~`.`-.-vxuxzwvv??vvv>-```-^>+??>??>>?}}w?^`........ <br></br>
`zwww}>`    .>r-.``+rr~```.``.^}uxxwv??vvvv}v>-.|++|~|?w}??w}xzxr?^`...... <br></br>
`zwwr}~`.... -v-.+}w}>-.`~-`-.~?xux}?vvvvvvvv?v?--^^`-+?v??vwxzxxzr?^-.... <br></br>
`wvrr?`|~+>+-|?>~+v+....`~~.~`-?zxzvvvvvvvvvv?v}v>~``~~|>>?rxxzxxxzzv>|`.. <br></br>
.>>>?>`|~+???>???|......`-~-`~~>vvvrr}vvvvvvvv}}}vv?|`|++++?rxzxxxzxxrv+|` <br></br>
.^??+?|-`..-}v>??>`++^|-``--.~^^~`|?rzw}vvvvvvvvvvvvv>|^>+^^+?}xuxxzxzzr?+`<br></br>
.^>+??+-~`-^>?^^?>.-|>?v?```.````.``^v}zzwrvvvvvv}}vv}v>+?>+^^|?wuuxzzzxzv`<br></br>
.v?>}}>~-|++~...|?`  .`--.......`.`..-^?vzzwvr}}vvvvvvv}v>?v>+^^+?wuzzzxxz-<br></br>
.?>>???>>^~-`.. `^`.   ....  ...~~-....`|>?rzxwwr}}}v??vvv?>vv>^^^^?wzzxxu|<br></br>
.^^^^+>?+~|-```..|-........   . -~~.... .`^+?rxzwwrr}v?vv?v?>?}v+^^^^?wzzz~<br></br>


            </div>,

            <div>

.??>`..--|?}}vv}}}}}}}v-.......`..............````````---~~~|^^^^^^++>????`<br></br>
`vvvv+`.>?-|}uxxzzzzxxxz?..`.~>}-...``.....`````----~~~~~||^^^^^^^+>>?vr}v-<br></br>
`wv??v?++```.^wuuxxxxzzzx}|v?vvv^^>??`.....`.````----~~~~~|||||||||^+>????`<br></br>
`zzrvvv}>-.``.`>zuxxxxxxxzv}vv?????>`........````----~~~~~~~~~~~~~~~~~~~^>`<br></br>
`xzzwvvvv?>`....`?xuuxxxuzv??v>>???>+~.......`````---~~~~-~~--~--------~|^`<br></br>
`xxxxx}v???v^`....~vzzzxx}??????vvv?^.......````.`---~----~~-----------~~|.<br></br>
`xxxxxxz}v??v?~....-zxwvv?>??v?????>~`......``. .``-----`.-------------~||.<br></br>
.ruxzwwwwvv?vvv>-...^?>vww?+>??????^|`````-~~` .-~-----` .----``````---~||.<br></br>
 `?xuxxxxxrv?vvvv+`....`?zz?>?????v}?-...~^+++|?}r}v?+` .`````````````--~~.<br></br>
 ``|wuxxxxxwv?}}}}v^.... >zwvrwzzwwxuxv~~^~^^|^v?>wwzzr>-``````````````~~~.<br></br>
.||||?uuxxxxzvv}v}}}?~....>vruuxxxxz}v????^`-^>+?+}}rrwz+`````````````-~||.<br></br>
.^^^~-|ruuxxxzvvv}}}}}?-.. v?^}r}}v???????>`~>+|-^>>>>|~````+-.```````-~~|.<br></br>
.^+>|~``?xuuxxwvvvvvvvvv+..r| .>?v?????vwwwr?-`-|++++v|...`~v>...``````---.<br></br>
.^^+>>+^~+}xzww}}vvvvvvvv?>w`  .~+?zzwr}vvvv}+>>~||^-^>...-^>~`~~``````---.<br></br>
.>+^~`.-??>+wuuxrzr}}v}vvvvv-`|+>?v}wuuxwvvv>>??^~.......`~++?>^^|``--~``-.<br></br>
.>|...`-|++||?xuxwxw}}vvv?vv}rrwrvv?wzzzwzwv>v??wz>-.....-+>?++++~`-~?v``-.<br></br>
 ..-~-...>?>+^|vuwxxxxxxxw}rzxzv^--?>zw}zuz???vxuxrw?-..~+>>|``>>~|^>r>-``.<br></br>
 -|`. .^v}v?+^~^vv??vrxxuuuxrw??v?|v>+}w?????vuxxz?}ww+|>>>+>++>>>>>+|````.<br></br>
.`.   >}v??~+>+~``-~||^?vrxuzz?vyyxv?-^?>????wuxxx}v??>>>>>>>>>>>>^-`.````.<br></br>
   -+`^rv???^`...```~>>>>>?ruwx}vzu}`.^??}v-`|}xuxv?vv>>++++>>>>^`........ <br></br>
 `?xuw+|}?~.....`~^^~|>??v>^vxzxwv???vvvv?-`~|>}z}+v?+^^?+~+>>^`.......... <br></br>
`rwwr}v}^...->>`..`-^>>>>?}?`>zuxzzrww}}}vvv??>^-~++|>?v}v~+>>>`.......... <br></br>
.??v}vr|    ~z}--~^+`~^>~`->`.|xuuuzvv??vv?>`   .`--vuuw}?~??vurv~........ <br></br>
.??}zr^   . .r}``^^?rr^--......?uxxxvv?vvvv}v>-.  . `>wuw^+r?zuxwx}^...... <br></br>
`v}z}v.      ?}`~vwzz}^........`zuuz??vvvvvv}?v?|. .. .>?~>?}xxxzwuur+.... <br></br>
`vvw}> |?+~.`+?|`?z?-. .........wuw?v}vvvvvvv?v}}?^.....`||vzxxxxzzuuuv|.. <br></br>
.+^^?> `????rwwwv|`      .......r}>vzrvvvvvv}?}}vv}v+`.....|vxuxxxwxxxxrv| <br></br>
.~~+>?.    ~wwwwz}~+~`.. ......`v~ ~vxzrvvvvv?vvvvvv}v>`.   .|}uuxxrxxxxwx|<br></br>
 |^~>v~.|`|?v}www>`?vrr}?......~-   .|?zuzwrv?vvvvvvv}}v+`.   .~vuuzzxxxxw|<br></br>
.>?>}??+~^vzv~-~v^  .`^v>........  ..` .>wuu}zrvv?vvvv}}v??^. . .|}uwzxxxz~<br></br>
`}?>?vv??>^+-`  +}`     .... ... -+ .`   .^vzuxxzwrvvvvvv?vvv>`.. .|}rxxxu|<br></br>
.++>>?}r}??^~`. `r?.   ...   ... ~? ..      |}xzzzzwr}vv??v??}v>`   .^wxzz~<br></br>


            </div>,

            <div>

.~~-.`~~`->v}vvv}v?^|^|`.....`...................```````----~~~|^||^^^^+??`<br></br>
.^|^?>`.~^`-?xuxzzzwv>>+~`...`~+`...`........``````---~~~~~|||^^^^^^+>>??}~<br></br>
`>>v>|~--````~vxuxxxxwv+^|`^-~|?^~+?^.........`````-----~~~~~||||||||^^++>`<br></br>
                  -ww?~~||-````.`^vzuxxxxw?+|^~^?vvv??~``........`````-----~~~-~~~~~~~~~~~~|.<br></br>
`xxzv+~~|~-`.``..^vzxxxxx}+~^???????>+`........`````------------~~~~----~~.<br></br>
`xxxuxv|~~~~````..`+}xxzzrv?????vvv?>-``......`...`-----``-------~~~-----~.<br></br>
`xxxxuuw?^~~~~-`...`>v}v}v??????vvv?+..`.....`. .`^>>+~`..`------------~~~.<br></br>
`zxzwwzzzv?|~^^-`...-||^?rv?>>?????+~.....````...`+>>>>~ .````````````----.<br></br>
.?}xxxxxzxwv^|~~~-......~+?}v?>>??>^~`...`~|^+|+>?v}vv>|`````````````````-.<br></br>
 ~+?zuxxxxxzv??^~~~-.....~>v}zzrwzzrv>|``-~~~~-+^+}}vrr?|```````````````--.<br></br>
.~|>?}xuxxxxxvvv?^|||`....~?v?v}xxzzwv>>??>--```^>???}ww^```.``````````---.<br></br>
.^|^^+?zuxxxxxvv}v?>^|~`...+v-^|+vv??????>~..`.`~^+^^^|-.```~-`...```````-.<br></br>
.|>>|^|+vuuxxxxr}vvv?+^~-..>?..`~>~+>?v}wr?>~..`-|^^^?~...`~>>+`..````````.<br></br>
.|^^++>++?wxzzzwrrvvvv?+~--+^...`+-++>}wwrr}v-`~.-~~-|+`..`|++^|-|~--`````.<br></br>
.^|^+~-^????wuxxzzw}vvvv?+~^>````>~^++>?}wwrv??|`|-`.....`-~^+>++^~~-`````.<br></br>
.+~`-+>>>??>>}xuuzxxwr}}}v?>v+^>>v>>}r}}v}}}rwvvvr?`.....`~~^^+++`-||+>```.<br></br>
 ..``|+^^?vvv??}xzwzxxzwzwr}wzzr?>^v}xzr}}v>>vrwwr}v^`..`~|^|-->+~^+^?|``` <br></br>
 `~-`..?www}v?>?vvvv}wxuuuuxwzv???|??vww?>>+-|?rwrwwzv|-~^>>>^>>>>>>>~```` <br></br>
.`... >zzwr???+|-````-|>vwuuxz?vuyz?>~>>>??|-+>?}}rw}}?>>?>>>>>>>>+^^+`... <br></br>
   .~|?zw}}?^`.....``-~--|>ruwz}vxur-.^>?v?-`|????^>vv>>>+++^^+++^|-~|-... <br></br>
 .`|?r??r?~`````---~--~||^~~>rwzwvv?++vr}>``--|>?>^?>^^|++|+>+|-~|-....... <br></br>
.^??^?}v+```~++`..``-~|~-~+~.~vxzwwrwxwvv????>+^--~--~||+v>>>??-~~`....... <br></br>
.++?vv?-....>wv``---..`-`.`-..`vuxxxrw}?vv>~.    ...|>^|~-~?vvr>>>-....... <br></br>
.>+vw>- ....^w?..~--^?^``......|rxxxw}vvvv}}?|`.  ...-^+^`-^+v?>?rv?|`.... <br></br>
.>?w>|. ....-}?.`^|>wr>........`?xxx}vvvvvvv}?v?|.  . .-~`-||++>>vxxr?^~.. <br></br>
.>vv|` `-``..?>..-?v>-..........>wx}??}vvvvvv?v}}?^`.....``|^^+++?rxuur?+~.<br></br>
.^>~-^-`|~~~+?v?>|^`.  .........+}vv>?r}vvvvv?vvvv}v>-. ...`~++^+^^?}xuwr?.<br></br>
.~^~-+~-~~`~}}vv?>```...........^^--|+?vrwrr}?vvvvvvvv?~... .`^++++^+?vzzx~<br></br>
.|+`-~`.``-+?>???^`^>>>+~......```.  `|>>?}zw}wrr}}vvvvv>~`.   `|++^^+^>?r~<br></br>
.|>-~~|-`-^?>---+-.`~^?v?`........  ....~^+>?rzzwwrr}}}}vv?>-.. .`|+^^++^+`<br></br>
.>+^^~~~`~>?|...+^.  ..`-.........`.......`|^>?}zzzwwwwr}vv?v?|`.. `^++++v~<br></br>
.^||~~~^?vv^....+v~... ..........`~~....    `^>+>vwzwrrrr}rvv}v?~``..`^+vv.<br></br>


            </div>,

            <div>

 .``.~?|..|?vvv}}v^. ..............................````````---~~||^^||^^++`<br></br>
 ``-?v~`````>wuzzwz}>`..````....`~..............`````--~~~~~~||^^^^^^^^+>v-<br></br>
.~^v?`.````--`>ruxzzzr>`....~-`>?>>?>|..........`````----~~~~~||||||||||^>`<br></br>
`w}+.`````````.`>wuuxzzv^...+>vvvv???|..........``````--~~--~~~~~~~~~~~~-~.<br></br>
`zzzv~..`.`````..`>wxzxxxv|->???v????>-.........``````---```~~~~~-~~~~--~~.<br></br>
`xuxuxv-......``....vxxwrzw???????vv??>```......```````...`--------~~-----.<br></br>
`xxxxuuz?-``..``.....+vv}}}v?????vv??+`...........-|^-.  .----------------.<br></br>
`wwwwzzxz}>-.-~`........^vzw?>?????>+-.......```.~?vv?+-.`-------```````--.<br></br>
`zuxxxzzzzrv|`..`.........|}w?>>??^. .......-^++~?vvvwwr?~`-```-`````````-.<br></br>
.^}xuxxxxxuxv?>-`.````.....~rzwrzzx}+.....`|^||+||+>+}ww}?~..````````````-.<br></br>
.~|>ruuxxxxxx}vv?~``-`..`..^?-|?wzwzxv^+>>??v^-|~^^++vrwvv+.````..````````.<br></br>
                  .|~-^?wuuxxxxurvvv?|-``....^?...-?????vvv?-`-.``~^^~|+^~~~`.````||`.``````.<br></br>
 ~+^^>+?wuuxxxxrrvv}?|~`...|v...->-+>vrwwr?-.....`-~^++`....`~^~|>+```````.<br></br>
.|^|^|+>?vrzzwww}rv?vv?|...~v...->...|vxxzwr?-`-.|?>-^+`....-~~-~>>|~-````.<br></br>
.~->?>+?v?v}rzxxzwz}vvvv?^.`r`..`>.....~vxuzrvv|`?v>```.....`-~~+>>>>^~~``.<br></br>
.|~-+}r}vvv??}xuuzzuzr}}}}?~}`-|+?^>v}?>^>vwuxwr?v?.........`-|++^++~||~``.<br></br>
 ...`+rrvv}rr}vrzuwzxzrv}}rr}wxzw}?ruuxw?++~^}z???v?-......`~^+>>++~-|^~``.<br></br>
 .-~~--rzzxzw}vvv}}}wxuuuxxuzzr+|^>^vzzw>^+` .-?vvuzxv^`.`~++>>>>+++>+^+~`.<br></br>
 `````?xuxz}??>|-`..`-|>vzuuxwvvwxw?++??+??`...|??wxzuwv>??>>>>>>>>>>+~`.. <br></br>
 .``-vxxwvv?|`...........`>ruwwv}uuz^.^+vv?^^|~--`.^v}+vv>+^^|^+>>>+||`... <br></br>
 ```>zww}?^|~--`............+rzzr}}?+|vz}+.```~||^+^+|^+^+>?}?~~+>+~`..... <br></br>
 `>?^?r?^^++^+~`..............?xzrrrwxwvv??>>>+^|--`..``|rxwvww^+>+`...... <br></br>
.|+??v+~~|^+rz>..`.............>uxxxwzr??v>-..      .... .+rxr?|+>+-...... <br></br>
.?>?v^~`~^^+wx> .-. `>~.........?uxxxwv?vv}}?|`.  .......  .~v>|>?vrv^.... <br></br>
`>>}|~--~^|-?x> `. ~rzv`........`zxxzvvvvvv}}?v?~.  ........  `|^?xxzzr>`. <br></br>
.^v>`.`..``.-r+ ..>wv|. .........}uzv?}vvvv}}?v}}v>-.  ..........^rxuzwxz?.<br></br>
.^r` >v+~`.`-+>^`~v^.............vx}~>ww}vvvv?vvvv}}?^`  ........ .|vxuwwu|<br></br>
.>v. +vvvv?~????+`. .............v}-  ~?wxxzw?vvvvvvvvv?~. ........  -?zzw|<br></br>
 |?.   ...``-------+|~`..........?-   ...^vxuwxzzwrvvvvvv>|`. ........ `>r~<br></br>
.->.. .... -~----``+v}rr}+..... -~    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-`.. ...-?}+..|>`.  .-^?-....  ..... `.     .|vzuuxxxxxzw}?v}v>-.  .....+`<br></br>
.~....  >rw+.. ~z?``..   .... .....v- ..  ..    `>wzzzzzzzzrv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..~?vvvv}v^................................````````---~~||^^||^^++`<br></br>
 ``-?v~`````>wuzwwz}>`.`````....->`.............`````--~~~~~~||^^^^^^^^+>v-<br></br>
.~^v?`.````--`>wuxzzzr>`...`^~->vv?>>>`.........`````----~~~~~||||||||||^>`<br></br>
`r}+.`````````.`>zuuxzzv^..`vvvvv????^..........``````--~~--~~~~~~~~~~~~-~.<br></br>
`zzzv~.``.`````..`>wxzxxxv|~v???v??v?+..............```--```~~~~~-~~~~--~~.<br></br>
`xxxuz?~.......`....?zxwrzr??????????>^``............``...`--------~~-----.<br></br>
`xxxxuuz?-``..``.....>vv}}}v?????????>-............`~~``~-----------------.<br></br>
`wwwwzzzz}>-.`~`........^vzw?>???>>+^`.......```.`>vv?>>^~-------```````--.<br></br>
`zuxxxzzzzrv^`..`.........|}wv?>??|. .......`^++~?v??rwwv~`````-`````````-.<br></br>
.+}uuxxxxxuxv?>-`.````.....~rzwrzzx}+.....`|^||+|^+++vwwv?~..````````````-.<br></br>
.~|>ruuxxxxxx}vv?~``-`..`..^?-|?wzwwxv^+>???v+`|~^+>+???vv+.````..````````.<br></br>
                  .|~-^?zuuxxxxurvvv?^~``....|v...-?????vvv?``-...`^^~||``~~`.````||`.``````.<br></br>
 ~+^^>+?wuuxxxxrrvv}?|~`...|v...`>-+>vrwwr?-......`~^++`.......`|>+-````-~.<br></br>
.|||^|+>?vrzzwww}rv?vv?|...-v`..`?...|vxxzwr?-`-.|?>-^+`...`-~-`~+>|~~~|^|.<br></br>
.~~>?>+?v?v}rzxxzwz}vvvv?^.`r`..`>.....~vxuzrvv|`?v>```....`-~~|+>>?>+++~`.<br></br>
.|~-+}r}vvvv?}xuuzzuzr}}}}?~}`-|+?+>vv?>+>vwuxwr?v?.........`-|+>>>+|+^|``.<br></br>
 ...`+r}vv}wr}vrzuwzxzrv}}r}}wxzw}?ruuxw?^+~^}zv??v?-......-|^+>>>+~~^|~``.<br></br>
 .-~~--}zzxzr}vvv}}}wxuuuxxxzzr+~^>^vzzr>|+` .-?vvuzxv^`.`^+>>>>>>++>^^+~`.<br></br>
 -~--`>xuxw}??>|-`..`-~>vzuuxwvvwxw?++??+??`...|??wxzuw?>??>>>+++>>>+-``.. <br></br>
 `-^^vxzwv?>~............`>ruwwv}uuz^`^+vv?^^^|--`.^vr+vv>+^|~~|+>>>-..... <br></br>
 -|>}zrrv?^-``..............+rzzr}}?+|vz}+.```~||^+^+|^+^+>?}?--^>>^...... <br></br>
 ~?}>?r?^|^~~~`...............?xzrrrwxrvv?>>?>+^|--`..``~ruwvww|^>+`...... <br></br>
.^+??v>|^^^|rw>. `.............>uxxxwzr??v>-..      .... .+rx}?|+?+-...... <br></br>
`?>?}^||+^||rz> .-. `>~.........?uxxxwv?vv}}?|`.  .......  .~v+|>?vrv^.... <br></br>
`>>}^~-||>^`>x> `. ~rzv`........`zxxzvvvvvv}}?v?~.  ........ .-|^?xxzzr>`. <br></br>
.^v>`.-`--`.`}+ ..>wv|. .........}uzv?rvvvv}}?v}}v>-.  ..........+ruuxwxz?.<br></br>
.+r`.>v>|`.`-+>^`~v^.............vx}~?ww}vvvv?vvvv}}?^`  ........ .|vxuwwu|<br></br>
.>v`.+vvvv?~????+`. .............v}-  ~?wxxzw?vvvvvvvvv?~. ........  -?zzw|<br></br>
 |?.    ..``-------+|~`..........?-   ...|vxurxzzwrvvvvvv>|`. ........ .>r~<br></br>
.->.. .... -~----``>v}rr}+..... -~    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-`.. ...-?}+..~>--`..-+?`....  ..... `.  .  .|vzuuxxxxxzw}?v}v>-.  .....+`<br></br>
.~....  >rr+.. ~z?`-`.. ..... .....v- ..  ..    `>wzzzzzzzzrv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..~vvvvv}v^................................````````---~~||^^||^^++.<br></br>
 ```?v~`````>wuzwzz}>`.```.``...`-..............`````---~~~~~||^^^^^^^^+>?-<br></br>
.~^v>`.````--->wuxzzzr>`...`-.->>^+>~`..........``````--~-~~~~||||||||||^?`<br></br>
`r}+.`````````.`>zuuxzz}^..-|^?vv?vv?>-.........``````--~~~-~~~~~~~~~~~~~~.<br></br>
`zzzv~.``.`````..`>wxzxxxv|`^?????????-..............``-~~--~~~~~~~~~~-~~~.<br></br>
                  `xxxuz?~.......`....?zxwrzw????????v??+-............```-```--------~~~--~-.<br></br>
`xxxxuuz?-``..``.....+vvv}rv????vvv??^-`..........|v}r?+-`----------------.<br></br>
`wzwwzzzz}>-.`~`........^}zw?>???>>^~........`-`..?wwww?+|--------``````--.<br></br>
`xuxxxzzzzrv|`..`.........|}r?>>??|. .......-^++|~v}v}rw>--```--````````--.<br></br>
.>}uuxxxxxxxv?>~`.````.....~rzw}zzx}+.....-^+^~+^^?>+?wv|````````````````-.<br></br>
.|^>ruuxxxxxx}vv?~``-`..``.^?`~?wzwwzv^+????v+`~-?+|^>?>-`````````````````.<br></br>
.|--^?zuuxxxxurvvv?^~``....|v...-?????vvv>`.`...`^|~~^-......```....``````.<br></br>
 -++^>+?wuuxxxurrvv}?|~`...|v...`>-+>vrwwr?-.....`-~^>+`....`|>-.``~-```-|.<br></br>
.~||+|+>?vwzzwww}rv?vv?|...-}`..`?...|vxxzzr?-`-.^?>`||`...`|>>|-+>+~~~~^^.<br></br>
.~-+?>>?v?v}rzxxzwz}vvvv?|.`r`..`>.....~vxuzrvv~`?v^.......`|+>>>>+|~|||-`.<br></br>
.|~~>rr}vvvv?rzuuzzuzr}}}}?~v`-|^?+>vv?>^>vwuxw}?v?.........-^>>+~|+^~~-``.<br></br>
 ...`+r}vv}w}vvrzuwzxzrvr}r}}wxzwvvwuuuw?++~+}zv??v?-......~+>>>++>>+-````.<br></br>
 .`~~~~rzzzwrvvvv}}}wxuuuxxxzzr>|+>+vxxw?^+` .-?vvuzxv^`.|??>>>>>>>>>-````.<br></br>
 -|~-`?xuxw}??>^-`..``|>vzuuxrvvwxw?+>v?+??`...|??wxzxr??}?>+++++>>>++`... <br></br>
 `-~~?zw}??^-............`>ruwwv}uuw^`^+vv?^^^~--`.^vr+vv+^+~`-~+>>+~^`... <br></br>
 `~-+r}}v+`.................^rzzr}}?^~vw}+.```~||^+++|^^^+>?}?-~+>+-...... <br></br>
 ~??->r>`.....................?xzrrrrxwvv?>>?>+^|-``..``~ruwvwv|>>|....... <br></br>
.?>??v^``..`vr+. `.............>uxxxwzr??v>-..      ......+rx}?|>?+-...... <br></br>
`???}|-~`...vz> .-. `>~.........?uxxxwv?vv}}?|`.  .......  .~v^^>?}rv^.... <br></br>
`?>w+-``~-. +z+ `. ~rzv`........`zxxzvvvvvv}}?v?~.  ........  `|^?xxzzr>`. <br></br>
.+v?`-`~~-`..}+ ..>wv|. .........}uzv?rvvvv}}?v}}v>-.  ..........+rxuxwxz?.<br></br>
.>r-`>}v?|`.`+>^`~v^. ...........vx}~?ww}vvvv?vvvv}}?^`  ........ .|vxuwwu|<br></br>
.?v`.>}rrrv^????+`. .............v}-  |?wxxzw?vvvvvvvvv?~. ........  -?zzw|<br></br>
.^v`...````~-------+|~``.........?|   `..|vxurxzzwrvvvvvv>|`. .......  `>r~<br></br>
.~>`` .``  -~---```?v}rrr>``.`` -|.   ``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-`.` .. -?}+..~>..~~~+>v+~-``...`.`. `.     .|vzuuxxxxxzw}?v}v>-.  .....+`<br></br>
.-`...  >rr+.. ~w| .-~`-||~``....``}- ..  ..    `>wzzzzzzzzrv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..~vvvvv}v^................................````````---~~||^++^^+++.<br></br>
 ```?v~`````>wuzwzz}>`.```......................`````--~~~~~~||^^^^^^^^+>?-<br></br>
.~^v>`.````--->wuxzzzr>`....`..>>~^>^`..........``````---~~~~~|||||||||^+?-<br></br>
`r}+.`````````.`>wuuxzz}^...`-?vvv???+-.........``````--~~~-~~~~~~~~~~~~~~.<br></br>
`zzzv~.``.`````..`>wxzxxxv|`~???v?>???|.........``````---``-~~~~~~~~~~-~~-.<br></br>
                  `xxxuz?~.......`....vzxwrzw???????vv???~..........`--```..`--------~~~--~-.<br></br>
`xxxxuuz?-``..``.....+?vv}rv?????vv??^``..........>vv?-  .----------------.<br></br>
`wzwwzzxzr?-.`~`........^}zw>>?????>^`.......`-`.-}rr}?^.---------``````--.<br></br>
`zuxxxzzzzrv|`..`.........|}r?>>??^. .......-^++~+v}rwwz+---`---````````-`.<br></br>
.+}uuxxxxxxxv?>~`.````.....~rxw}zzx}+.....-^+||+||>?>vww?~`.``````````````.<br></br>
.|^>rxuxxxxxx}vv?~``-`..``.^?-|?wzwwzv^>????v|-~|>^^+vwr+~-.``````````````.<br></br>
.|--|?zuuxxxxurvvv?^~``....|v...-?????vvv>```...|+^||+^-````````--`.``````.<br></br>
 ->+^++?wuuxxxurrvv}?||`...|v...`>-+>vwwwr?-.....`-~^+^`....-^?~-||```````.<br></br>
.~||+|+>?vrzzwww}rv?vv?|...-}`..`?...|vxxzzr?-`-.^?>`^^`...`~^+~->>||`````.<br></br>
.~~+?>>?v?v}rzxxzwz}vvvv?|.`r`..`>.....~vxuzrvv|`?v+```.....-|^^+>>+^~--``.<br></br>
.|~~+rr}vvvv?rzuuzzuzr}}}}?~v--|^?+>vv?>^>vwuxwr?v?.........`|+++-|>~~~~``.<br></br>
 ....+rrv?}w}vv}zuwzxzrv}}r}}wxzwvvwuuur+++~^}zv??v?-......`|+>>++>^-~|~`` <br></br>
 .`~|--}zxzw}vvvv}}}wxuuuxxxzzr>|+>+vxxw?^+` .-?vvuzxv^`.~^>>>>>>>>>>|~|-` <br></br>
 -~-``>xuxw}??>^-`..`-|>vzuuxrvvwxw?+>}?+?v`...~??wxzx}??v?>>>>>>>>>>?-`.. <br></br>
 ````?zwv>?^-............`>ruwwv}uuw^`++vv?^^|~--`.^vv^v?+^^|~|+>>>+|+-... <br></br>
 `. ~}}}?^..................+rzzr}}?|~vz}+.```~||^+++|^^^+>?}?-~+>^-``.... <br></br>
 `>>`>}+......................?xzrrrrxwvv?>>?>+^|-``..``~wuwvwv~+>|....... <br></br>
.>>??v-....`vr+. `.............>uxxxwzr??v>-..      .... .+rxr?|>?^-...... <br></br>
`v??v~`~`. .vz> .-. `>~.........?uxxxwv?}v}}?|`.  ........ .~v+|>?vrv^.... <br></br>
`??r^```-`. +z+ `. ~rzv`........`zxxzvvvvvv}}?v?~.  ........  `|^?xxzzr>`. <br></br>
.>}?`~``-~`..}+ ..>wv|. .........}uzv?rvvvv}}?v}}v>-.  ..........+rxuxwxz?.<br></br>
`?r-`?}v?>-.`+>^`~v^.............vx}~?wr}vvvv?vvvv}v?^`  ........ .|vxuwwu|<br></br>
.?}``?rwwwv>????+`. .............vr~ .^?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
.>v```|^^~~|-------+|~--``````.``v^ ..-..|vxurxzzwrvvvvvv>|`.  ......  .>r~<br></br>
.~>-~.```..-~----``?vrwww?~~-~`.-^-...-`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-``| ...-?r+..~>. .`|+>v?>+|~`-.~-|`.-.     .|vzuuxxxxxzw}?v}v>-.  .....+`<br></br>
.-`...  >rr+.. ~w| ..-`-|>+~-.-`.--r~.`.  ..    `>wzzzzzzzzrv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..~vvvvv}v^................................`````````---~~|^++^^+++.<br></br>
 ```?v~`````>wuzzzz}>`..``.`....->`.............``````--~~~~~~||^^^++^^+>?-<br></br>
.|^v>`.````--->wuxzzzr>`...`^-~>v??>>>`.........``````---~~~~~|||||||||^+?-<br></br>
`r}+.`````````.`>zuuxzz}^..-vvvvv??v?^..........``````--~~~-~~~~~~~~~~~~~~.<br></br>
`zzz?-.``.`````..`>wxzxxxv||v???v????+...............``--``-~~~~~~~~~~-~~-.<br></br>
`xxxuz?~.......`....?zxwrzr?v????????>|..............`-`..`--------~~~--~-.<br></br>
`xxxxuuz?-``..``.....>vvvrrv?????v???>`............-^+||^-----------------.<br></br>
`wzwwzzzz}>-.`~`........^}zw?>???>>+~`.......````.>}}v???|--------`````---.<br></br>
`xuxxxzzzzrv|`..`..`......|}wv>?v?|. .......`^++|>v??}wr?~--`---````````-`.<br></br>
.>}uuxxxxxxxv?>~`.````.....~rxwrxxx}+.....`|^||+|^+^+vwr??~.``````````````.<br></br>
.|^>rxuxxxxxxrvv?~``-`..``.^?-|?wzwwzv^+??v?v+`|-^>++??>>?^.``````````````.<br></br>
.~--^?zuuxxxxurv}v?^~```...|v...-?????vvv>```...`^|~^|`.--`.````~~`.``````.<br></br>
 ~>+|++?wuuxxxuwrvv}?|~`...|v...`>-+>vrwwr?-......`-|++`. .....`~^^-````-~.<br></br>
.~||+|+>?vrzzwww}rv?vv?|...-}`..`>...|vxxzwr?-`-.|?>`|^`....-~-`~+>^-~||^^.<br></br>
.~~+??>?v?v}rzxxzwz}vvvv?|.`r`...>.... -vxuzrvv|`?v+```.....~|^^+>>>++++|-.<br></br>
.||~+}w}vvvv?rzuuzzuzr}}}}?~v--|^?+?v?>?^>vwuxwr?v?.........`~^+>>++|^^|``.<br></br>
....`+rrvv}w}vv}zuwzxzrv}}r}}wxzwvvwuuuw>^+~^}zv??v?-......-|+>>>>+||^|~`` <br></br>
 .`--..}zxzw}vvvvvvrwxuuuxxxzzr>|+>+vzxw?^+` .-?vvuzxv^`.`++>>>>>>+>>^^^-` <br></br>
 `..  ^xuxw}??>^-...`-|>vzuuxrvvzuw?++}?>?v`...|??wxzuw?>??>>>++++>>^-``.. <br></br>
 .. .?wwv>?^-............`>rurwvruuz^`++vv?^^|~-``.^vw+vv>++|-~|+>>>-..... <br></br>
 .. -r}v?^..................^}zzr}}?^~vz}>.```~||^+++|^+^+>?}?--^>>^...... <br></br>
 .+^`>}+.....`................?xzrrrrxwvv?>>>>+^|~``..``~ruwvrw|^>+`...... <br></br>
.|^>?v~... `vr+  `.............?uxxxwzr???>-..      .... .+rx}?|+?+-...... <br></br>
`?>?v`.... .vz> .-. `>~........`vuxxxwv?}}}}?|`.  ........ .~v||>?vrv^.... <br></br>
.+>}-.  .`. +x+ `. ~rzv`........~zxxzvvvvvv}}?v?~.  ........ .-^^?xxzzr>`. <br></br>
.+v>`..  .`..}+ ..>zv|. ...``..`.}uxv?rvvvv}}?v}}v+-.  ..........^ruuxwxz?.<br></br>
`?r|.>v?^`.``+>^`~v^. ....`-`-.``vur|?ww}vvvv?vvvv}v?^`  ........ .|vxuwwu|<br></br>
.?}|.?vrr}v+????+`. .```.-~^~^.~~vw>`~^?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
.^v|`+~~|^|~------->^++^`~|^^^.|^v+|-|-..|vxurxzzwrvvvvvv>|`.  ......  .>r~<br></br>
.~>^-^^|^-.-~----``>vrwwr>~~|^.-|+~|~|-.   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.|`~|...`~?r+..~>.   .~+?||~``.`~~-|---.     .|vzuuxxxxxzw}?}}v>-.  .....+`<br></br>
.-`.~.  >rr+.. ~w| .. . .``-....``-}~.`.  ..    `>wzzzzzzzzrv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..~vvvvv}v^................................`````````---~~|+>>+^+++`<br></br>
 ``-?v~.````>wuzzzz}>`..``.`....`>`.............``````--~~~~~~||^^^++^^+>?`<br></br>
.|^v>`.`````-->wuxzzzr>`...|+`++??>+~|-.........``````-----~~~|||||||||^+?-<br></br>
`r}+.`````````.`?zuuxzz}^..^???vv??v?>..........```````-~~~-~~~|~~~~~~~~~|.<br></br>
`zzzv~.``.````...`>wxzxxxv||?????????+................`----~~~~~~~~~~~~~~-.<br></br>
`xxxuxv~.......`....?zxw}zw??????????>-`.......... ..`~~``---~-----~~~--~-.<br></br>
`xxxxuuz?~``..``.....>?vv}rv????vv???+|-......... .^vrr}?-----------------.<br></br>
`wzwwzzzxr?~.`~`........^}zwv>>>>+|~`........`--` +wwwwww+`--------``-`---.<br></br>
`xuxxxxzzzrv^`..`..`......|}wv??}?~. .......`|++^|?v??rz?````---````````--.<br></br>
.>}xuxxxxxxx}?>~`.````.....~rzwrxxx}+.....`^>^~+^^>|+>wv``````````````````.<br></br>
.|^>}xuxxxxxxrvv?~``-`..``.^?-~?wzwwzv^>??v??>`~->>^+>+``````````````````-.<br></br>
.~--|?zxuxxxxurvvv?^~```...|v...-?????vvv>`.`....~-~^^` ....`````...``````.<br></br>
.->+|++?wuuxxxuwrvv}?|~`...|v...`>-+>vrwwr?-......-~~++.....``-`..-||~~--^`<br></br>
.~~|>|^>?vrzzwww}rv?vv?|...-}...`>...|vxxzzr?-`-.|v?-~~`....|+^~~^?>~|^+>>.<br></br>
.|-^??>?v?v}rzxxzwzrvvvv?|.`r`...>.....~vxuzrvv|`?v^.......`^>>>>>>~~>^+^~.<br></br>
.||~^}w}vvvv?}zuuzzuzr}}}}?~v`-|^?+?vv??^>vwuxwr?v?.........~+>>>+^+>^~~``.<br></br>
....`+rrvv}w}vv}zuwzxzrv}}r}}wxzwv?ruuuw>^+~^}z?v?v?-......|+>>>>>>>+~```` <br></br>
 .`--..}zxxw}vvvv}}rwxuuuxxxzzr>~+>^vzxwv+^` .-?vvuzxv^`.^??>>>>>>>>+-```` <br></br>
 `..  ^xuxw}??>|-...`-|>vzuuxrvvzuw?++vv+??`...|??wxzuw??}?>>^^^^+>>^~`... <br></br>
  . .?zwv>?^-............`>rurwvrxuz^`^>vv?^^|~-``.^vw>?v>^^~..`|+>+-``... <br></br>
  . -r}v?|..................+rzzr}}?|~vz}>.```-||^+++|^+^+>?}?--^>>-...... <br></br>
 .^|.>}+.....................`?xzwrrrxwvv?>>>>+^|-``..``~ruwvrr~+>+....... <br></br>
.|+>?v-... `vr+  .............`vxxxxwzr???>-..      ......+rx}>|>v+-...... <br></br>
`?>?v`.... .vz> .-. `>~....```.~ruxxxwv?}}}}?|`.  .......  .~?|+??rrv^.... <br></br>
.^>}`.  .`. +z+ `. ~rzv`...`-`-->xxxzvvvvvv}}?v?~.  ........  -^^vuxzzr>`. <br></br>
.^v>.    .`..}+ ..>wv|. ..`-^-`+~wux}vrvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
.>r` +v>~`.``+>^`~v^..`-``|+~^`^|vur+?ww}vvvv?vvv}}v?^`  ........ .|vxuwzu|<br></br>
.>r-.+v}}v?|????+`. .-|^`|++|^`~|vw+-~^?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
 |v~~~`~~`--------->^+^~``-~||`-~v+`-~-..|vxuwxzzwr}vvvvv>|`.  ......  `>r~<br></br>
.~>|~+^^+~`-~----``>v}rrr+..--..~^`.`--.   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.^`|~-`.-|?r+..~>.   .-+?-....  ```..``.     .~vzuuxxxxxzw}?v}v>-.  .....+`<br></br>
.~`.^...>rr+.. ~w| ...  .....   ..`}-...  ..    `>wzzzzzzzzwv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..~vvvvv}v^................................`````````---~~|+>>+^+++`<br></br>
 ``-?v~`````?wuzzzz}>`..``..`...................``````--~~~~~~||^^^++^^+>?`<br></br>
.||v>`.`````-->wuxzzzr>`....``.>>~+>^`..........``````-----~~~|||^|||||^+?-<br></br>
`r}+.`````````.`>wuuxzz}^..`-~?vvvv??^`.........``````--~~~-~~~||~~~~~~~~|.<br></br>
`zzw?-.``.````...`>wxzxxxv|`~?????>???|.........``````---```~~~~~~~~~~~~~-.<br></br>
                  `xxxuz?-.......``...?zxwrzw???????vv???~.........```-```..`--~~----~~~--~-.<br></br>
`xxxxuuz?-``..``.....+?v}rrv?????vvv?^``..........+??>`  .----------------.<br></br>
`wzwwzzzz}>-.`-`........^}zw>>?????>^`.......```.-v}}}?^.-----------------.<br></br>
`xuxxxzzzzrv|`..`..`......|}r?>>??^.........-^++~+v}rwwz+---`---````````--.<br></br>
.>}uuxxxxxuxv?>-``````.....~wxwrzzx}+.....-^+||+||+?>vww?^-.``````````````.<br></br>
.|+?ruxxxxxxxrvv?~``-`..``.^?-|?wzwwzv^+>?v?v|-~|+^^+vwr>^-.`````````````-.<br></br>
.~--|?zuuxxxxurv}v?^~```...|v...-?????vvv>``-.`.|+^||+^-````````--`.``````.<br></br>
.~>+|^^?zuuxxxuwrvv}?|~`...~v...`>-+>vwwwr?-.....`-~^+^`....-|>~~^|```````.<br></br>
.~~|>|^>?vwzzwww}rv?vv?|...-}`..`>...|vxxzzr?-`-.^?>`^^`...`~^^-->>||`````.<br></br>
.|~^????v?v}rzxxzwzrvvvv?|.`r`...>.....~vxuzrvv|`?v+```.....-~^^+>>++~--``.<br></br>
.||~^}w}vvvvvrzuuzzuzr}}}}?~v`-|^?^?v}v+^>?wuxwr?v?.........`~^++-|+~~~~``.<br></br>
....`+}rvv}w}vv}zuwzxzrvr}r}}wxzwv?wuuxr>+^-^}zv??v?-......`|+>>++>^-~|~``.<br></br>
 .--`..}zxxw}vvvvv}rwxuuuxxxzzr>~+>+vzxw?^^` .-?vvuzxv^..-^>>>>>>>+>>|~^-` <br></br>
 `..  ^xuzw}??>^-...`-^?}zuuxrvvzuw?>+v?+>?`...|??wxzu}????>>>>>>>>>>?~`.. <br></br>
  . .?zwv>?^-...........-|?wxrwrrxyz^.+>?v?^^|~-``.^vv^vv>+^|~|+>>>+^+-... <br></br>
  . -r}v?^..........```````^?rwzw}v?|~vz}>.```-||^+++|^^^+>?}?-~+>^~``.... <br></br>
 .^|`>}>.............`-|-`--~>}xxwrrrxwvv?>>>>+^|-``..``~wxwvr}|+>|....... <br></br>
.^+>?v~... `vr+  .....`~+^^^^|>ruxxxwzr???>-.       .... .+rx}?|>?+-...... <br></br>
`?>?v`.... .vz> .-. `?^-^^>?>>+>ruxxxwv?}}}}?|`.  ........ .~v+|>?vrv^.... <br></br>
.^>}`.  .`. +z+ `. |rz}~-+|>?^>+?xxxzvvvvvv}}vv?~.  ........  `|^?xxzzr>`. <br></br>
.^v>.    .`..}+ ..>z}?+^|^^->^~+|wux}vrvvvv}}?v}}v>-. ...........^ruuxwxz?.<br></br>
.>}` >v>~`.``+>^`|r??+>>|~|~-^~-~vxr|?ww}vvvv?vvv}}v?^` ......... .|vxuwwu|<br></br>
.>v. +vvvv?|????+``-||--`````-|.`vr~ `^?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
.|v`.  `..`-------->^~-`......`..?|  .-`.|vxuwxzzwrvvvvvv>|`.  ......  `>r~<br></br>
.~>~```-^..~~----``>v}rr}+..... -~.  .``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.|--~---|>vw+..~>.   .-+?`....  ...`. ``     .~vzuxxxxxxzw}?v}v>-.  .....+`<br></br>
.~~.^~`-?wz>.. ~w| ...  .....  .. .}- ..  .     `>wzzzzzzzzrv}}vv>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?|..-vvvvv}v^................................`````````---~~|+>>>++++`<br></br>
 ```?v~`````>wuzzzz}>`..``......->`.............``````--~~~~~~||^^^+++^+>?`<br></br>
.||v>`.`````-~>wuxzzzr>`....^|`>v?????`.........``````-----~~~|||^|||||^+?-<br></br>
`r}>.`````````.`?zuuxzz}^..`?vvvv????~..........``````--~~~-~~~||~~~~~~~|^.<br></br>
`zzzv~..`.`````..`>wxzxxxv|~v???v??v?+`.``......``````---```~~~~~~~~~~~~~-.<br></br>
`xxxuxv~...`...``...vzzrrwwvv?????vv?>>```.......``````. .`--~~----~~~--~-.<br></br>
`xxxxuuz?~```.`-.....>?v}rrv???>?????+`............``.  `------------~----.<br></br>
`wzwwzzzxr?~``~`........^}zw?>????>>^~.......````|?vv?^~------------------.<br></br>
`xuxxxzzzzrv^~..`..`......|}w?>>??|. .......-^++~vv>?wwr?~```---````````--.<br></br>
.>vxuxxxxxux}??~``````.....~rxrrzzx}+.....`~^||+|^^+>rwzwr|..`````````````.<br></br>
.|^>}xuxxxxxxrv}?~``-``.``.^?-|?wzwwzv^+>>v?v+-||^+>+v}rwz?.`````````````-.<br></br>
                  .~--|?wxuxxxxurvvv?|~-``...|v...-?????vvv?-`~``.~+^|^^|^+^`````-++`.``````.<br></br>
.->+|^^?wxuxxxuwrvv}?^~`...|v...`>->>?rrwr?-.....`-~^+^`...````-+v?`.`````.<br></br>
.~~|>|^>?vrzzwww}rv?vv?|...~}...`>...|vxxzwr?-`-.~?>-^+`....```.|>>~---```.<br></br>
.|~^????v??v}zxxzwzrvvvv?|.`r`...>.... ~vxuzrvv|`?v?```.....````^>>??+^^-`.<br></br>
.||~^}w}vvvvv}zuuzzuzrr}}}?~v--|^?+>vv?>|>?wuxwr?v?`.........`-^>>>+~++|``.<br></br>
....`+}rvv}w}vv}zuwzxzr}r}r}}wxzwvvruyu}?+^~^}zv????~.......`|+>>>+--++^-`.<br></br>
 .-``..}zxxw}vvvv}}rwxuuuxxxxzr>~+>+?zzwv++` .-?vvxwxv^...`|>>>>>++>>>>?~` <br></br>
 ..   +uuxw}??>|-...`~?}zxuuxwvvzuw?++}v+??`...|??wzzurv+???>>>>>>>>+|~-.. <br></br>
  . .>zwv>?^-.........-|>?vwurzwrxyz^.+>?v?^^|~-``.^v}+vv?++^|^+>>>>-..... <br></br>
    -r}v?|.........-~~|+>>>>?wwzw}v?|~vz}>.```-||^+++|^+^+>?}?-~^>>+...... <br></br>
 .||.>v^............`^^+>>>>++}xxzwrrxrvv?>>>>+^|-``..``|wuwvrx^|>>-...... <br></br>
.^+>?v-... `vr+  ``.`|+++?>+>++vuxxxwzr???>-..      .... .+rx}v^^>+-...... <br></br>
`?>?v`.... .vz> .-. `?>++^>>|++^vuxxxwv?}}}}?|`.  ........ .~v+|>?vrv^.... <br></br>
.^>}`.  .`. +z+ `. +rz}++>-^|`-|>xuxzvvvvvv}}vv?~.  ........  -^^?xxzzr>`. <br></br>
.^v>. .  .`..}+ ..>z}>^|~~-.~..`-ruxv?rvvvv}}?v}}v>-. ...........^ruuzwxz?.<br></br>
.?r` >v>~`.`->>^`~r?>--``........vxv~>ww}vvvv?vvv}}v?^` ......... .|vxuwwu|<br></br>
.+v. +vvvv?~????+``..............v}~ .|?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
.|v.   ...`-------->|~``.........?- . `..|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
.~+.. .`~..------``>v}rr}+..... -~    ``   `>}uuuxxxxzr}vv?v?|.  ....... ..<br></br>
.-``~. .-+vw>..~>.   .-+?-....  ...`. `.     .~vzuxxxuxxzwr?v}v>-.  .....+`<br></br>
 ``.~^..?ww?-. ~z|      .....  .. .}- `.  .     `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..-vvvvv}v^................................``````````--~~|^>??>+++`<br></br>
 ```?v~`````>wuzzzz}>`..``.....``^`.............``````--~~~~~~||^^^+>+^+>?`<br></br>
.||v>```````-~?wuxzzzr>`...-|.^+?>>+~|`..........`````------~~|||^^^^^|^+?-<br></br>
`r}+..````````.`>wuuxzz}^..|???vv??v?>...........``````--~~~~~~||||||||||^`<br></br>
`zzw?-.``.`````..`>wxzxxxv~~??????>??>................`------~~~~~~~~~~~~-.<br></br>
`xxxuz?-...`...``...?zxw}ww??????????>~........... ..`~~``---~~----~~~~-~-.<br></br>
`xxxxuuz>-```.`-`....>vvv}}v????vv???+~`......... `+vr}v>`----------~~----.<br></br>
`wzwwzzxz}>-``-`........^}zw?+>?>>^~-........`--..>wwww}v^----------------.<br></br>
`xuxxxzzzzrv|-..``.`......|}wv??v?~. .......`|++^|vvvvww?--------```````--.<br></br>
.>ruuxxxxxxxv?>-``````.....-rxwrxxx}+.....-+>|~+^^?+>?wv~`````````````````.<br></br>
.|+?ruxxxxxxx}vv?~``-``.``.|?~|?wzwwzv^>?????+`~->>^+>+-``````````````````.<br></br>
.---^vzuuxxxxurv}v?|~-``...|v...-?????vvv+`.`....~~~^^` ..````````..``````.<br></br>
.~>+|^^?zuuxxxuwrvv}?|~`...~v...`>-+>vrwwr>-......-~~++.....`-~`..`~|~~`-^`<br></br>
.~~|>^^>?vwzzwww}rv?vv?|...-}`..`>...|vxxzzr?-`-.|v?-~~`...`|++~~^>+~~^^>+.<br></br>
.|~^????v??vrzxxzwzrvvvv?|.`r`...>...`.~vxuxwvv|`?v^.......`|+>>>>>~~+^^|~.<br></br>
.|^-^}w}vvvvvrxuuzzuzr}}}}?~v`-|^?+?}r?+^>?wuxwr?v?.........-+>>>+|^+|~~``.<br></br>
.`...^}rvv}zr}}wxuzxuzw}r}r}}wxzwvvwuuur?^^-^}zvv?v?-......|>>>>>>>>+~```` <br></br>
 .```..}zxxw}}}}vrwwxuuuuxxxzzr>~+>+vzxw?^+` .-?vvxzxv|..^??>>>>>>>>>-```` <br></br>
 `... ^uuxz}???+|^>???vvrxuuxwvvzuw?++v?+??`...~??wxzur?vr?>>+^^^>>>+|```` <br></br>
  . .>zwv>?^~```~^>>>>>?>>vwurw}ruyz^`++vv?^^|~-``.^vw>?v+^^~``-|+>+~~`... <br></br>
    -r}vv+-~|+>>?>>>>>>>>+~^?wzzr}v?|~vz}>.```-||^+++|^^^^>?}?--+>>~...... <br></br>
 .||`+}>`.``-^>>??>>>+>>+^``-~vxwwrrrxwvv?>>>>+^|~-`..``~ruwvw}~+>^....... <br></br>
.++>?v|... `}r?-~+>>>+^^|^-..`.>uxxxwzr???>-..      .... .+rx}?|>v+-...... <br></br>
`?>?v`.... .vz?|^>~-|v?````.....?uxxxwv?}}}}?|`.  ........ .~?^^??rrv^.... <br></br>
.^>}-.  .`. +z>`+^|>wzv`........`zuuzvvvvvv}}vv?~.  ........ .-||vuxzzr>`. <br></br>
.^}+. .  .`..}+.-~?zv|. .........}uz??rvvvv}}?v}}v+-. ...........^ruuzwxz?.<br></br>
.?}` >v>~`.``+>+~|}^. ...........vxv~>ww}vvvv?vvv}}v?^` ......... .~vxuwwu|<br></br>
.+v. +vvvv?~????+`. .............v}- .|?wxxzw?vvvvvvv}v?~. ........  -?zxw|<br></br>
 |?.   ...`-------->|~``.........?-   `..|vxuwxxzw}vvvvvv>|`.  ....... .>r~<br></br>
.~+.. ...  -~----``>v}rrr+..... --   .``   `>}uuuxxzzwr}vv?v?|.  ....... ..<br></br>
.--.. .. -?w>``|>.   .-+?`....  ...`. ``     .~vzuuxxxxxzw}?}}v>-.  .....+`<br></br>
 ``...  >ww>-~|+z| ..   .....  .. .}- `.  ..    `>wzzzzzzzz}}}vvv>^. . .?+ <br></br>


            </div>,

            <div>

 .``.~?|..-vvvvv}v^................................``````````--~~|^???>+++`<br></br>
 ``-?v~`````?zuzzzzv>`.```......................``````--~~~~~~||^^^+>>^+>?`<br></br>
.|^v>```````-~?wuxzzzr>`.......>+-^>-............`````------~~|||^^^^^|^+?-<br></br>
`r}>..````````.`>zuuxzz}^..```?vvvv??>^`.........``````--~~~~~~|||~||||||^`<br></br>
`zzzv~..`.`````..`>wxzzxxv|.`?????+???+..........`````-------~~~~~~~~~~~~-.<br></br>
`xxxuxv~...`...``...?xxw}zw????????v???+`.........`-~``-`.`--~~----~~~~-~-.<br></br>
`xxxxuuz?~```.`-`....>?vv}}?????vv}v?~````.......`vrr}^. .`---------~~----.<br></br>
`wzwwzzzxr?~``-`........^}zr>>?????>^` .....``-` -}wwww>.`----------------.<br></br>
`xuxxxxzzzrv^~..`..`......|}r?>+??^.........~+++||vwwwwz^`-------```````--.<br></br>
.+vxuxxxxxxx}??~``````.....~rxwrzzx}+.....~+>~|+^|>?>vww>`````````````````.<br></br>
.^^>vxuxxxxxxrv}?~`````..`.^?-|?wzwwzv^>?????|-~~?^^^vwr~`````````````````.<br></br>
.--~|?wxuxxxxurv}v?|~-``...|v...`?????vvv+```.``+++||>+-.`````-```..``````.<br></br>
.~++|^^?wxuxxxuwr}}}?^~-...|v...`>`^>vwwwr>-.....`-~^+^`....->r~..`-``````.<br></br>
.~~|>^^>?vrzzwww}rv?vv?|...~}...`?...|vxxzzw?-`-.+?>`^^`....|>>|->>^|`````.<br></br>
.^~^????}v?v}zxxzwzrvvvv?|.`r`...>....`~vxuxwvv|`?v^```....`~^>>>>+^~`````.<br></br>
.|^-^}w}vvv}v}zuuzzuzr}}}}?~v--|^?+?}wv^^>?ruuwr?v?.........-^>>^`~>~`````.<br></br>
.`.`-+rrr}wzwr}wxuzxxxrv}}r}}wxzwv}wuuur?+^-^}zv??v?~......-+>>>+^>>-`````.<br></br>
 `-```-rxuuzwr}rrwwwwxuuuxxxzzr>~+>+vwzw?^+` .-?vvxwxv^..|>?>>>>>>>>?~```` <br></br>
 .... ^uuzwr}v}rvvv??>>?}xuuxwvvzuw?++v?+??`...~>?wzzx}?v}?>>>>>>>>>?}-``` <br></br>
 .. .>zrv>?>+++++>>+~~~~-->wurw}ruuw+`+>vv?^^||-``.^vv+v?+^^|-~^>>+|+?^... <br></br>
    -r}vv>|^++++++^~`.......+rzzr}}?|~vz}+.```-||^++>|^^|+??}?~|>>~.`-.... <br></br>
 .~~`>}>-~++>>>+++>+|~-`......?xzwrrrxrvv?>>>>+^|~``..``|wuwvwv|>>`....... <br></br>
.++>?v-...`|ww?||^^~`..........>uxxxwzr???>-..      .... .+rx}?|>?^-...... <br></br>
`?+?v`...   vzv^^+. `>-.........?uuxxwv?}}}}v^`.  ........ .|v+|?vvwv^.... <br></br>
.^>}`.  .`. +z?^+` ~rz?.........`zuuzvvvvvv}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.^}>. .  .`..}?~- +wv|. .........}uz??rvvvv}}?v}}v>`. ...........+ruuzwxz?.<br></br>
.?r` >v>~`..`>?>`~v^.............vx}~>ww}vvvv?vvv}}v?^` ......... .|vxuwwu|<br></br>
.^}. +vvvv?~????+`. .............v}~ .|?wxxzwvvvvvvvv}v>~. ........  -?zzw|<br></br>
 |v.   ...`-------->|~``.........?-   `..|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
.~>.. .....------``>v}wr}>..... -~    ``   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
.--.. .. -?r+``|>.   .-+?-....  ...`. ``     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
 ``...  >wr+.-?}z|       ....  .. .}- `.        `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..-?vvvv}v^................................``````````---~|^>???>++`<br></br>
 ```?v~`````?zuzzzz}>`.`````....->`..............`````--~~~~~~~||^^+>>++>?`<br></br>
.~|v>```````-|>wuxzzzr>`....^~`>v?????`..........``````-----~~|||^^^^^|^+?-<br></br>
`r}+..````````.`>wuuxzz}^..`vvvv?????|...........``````--~~~~~~||||||||||^`<br></br>
`zzw?-.``.`````..`>wxzxxxv~~v???v????+`.``.......`..``---```-~~~~~~~~~~~~-.<br></br>
`xxxuz?-...`...``...vzzrrzr?v?????v??>>```...........``...`--~~--~~~~~~-~-.<br></br>
`xxxxuuw>`.``.`-`....>?v}r}v?????????>`.............`. .`-----------~~----.<br></br>
`wzwzzzxx}>-``-`........^}zw?>????>>^~.......````|?vv?^-`-----------------.<br></br>
`xuxxxzzzzrv|-..`..`......|}wv>+??|. .......-^++|?v>?wwr?|`------```````--.<br></br>
.>wuuxxxxxxx}?>-``````.....~rxrrzzx}+.....`|^||+||+++}www}|.``````````````.<br></br>
.^^?ruxxxxxxxrvv?~```-`..`.|?-|?wwwwzv^>>>v?v+`|~|>>+?v}rw?.``````````````.<br></br>
.--~^vzuuxxxxurv}v?|~-``...|v...`?????vvv>-`-``.-^^|^^~~^^`````-^+`.``````.<br></br>
.~++||^vzuuxxxuwr}}}?|~-...~}...`>->>vrwwr?`.....`-~^+^`...````-^??```````.<br></br>
.~-|>^^+?}wzzwww}rvvvv?|...-r`..`?...|vxxzwr?-`-.~?>-^+`....````|>>|~-----.<br></br>
.^~^?v?v}v?vwzxxzwzrvvvv?|.`r`...?.....~vxuzwvv|`?v?```.....`---^>>?>+^^-` <br></br>
.^^-|vw}vv}vvrxuuzzuzr}}}}?~v--|^?+?vv?>|>vwuxwr?v?.........``~^>>>^~++|`` <br></br>
.`..`+}r}}rzw}vwzuwzxz}v}}rr}wxzwv?ruuxw?+^~+}zv????-......`-^+>>>>--++|-` <br></br>
 .-^^||rxuuzr}vv?v}}wxuuuxxxzzr>|+>^vwxz?|^` .-?vvuwxv^...-^>>>>>>+>>>+>|`.<br></br>
 ````.>uuxzr??>^-....`~>vzuuxrvvzuw?+^?????`...|??wxzuw?+???>>>>>>>>+~--`` <br></br>
 ....?zww}vv+`...........`+}urw}}uuz+.^+vv?^^||-``.^v}+vv?++^||^+>>>~..... <br></br>
  . ~r}rr?^||~~-``..........^}zzr}}?|~vz}>.```~||^+++|^+^+>?}?--^>>^...... <br></br>
 .||`>r?^^^+^+|`..............?xzwrrrxwvv?>>>>+^~-``..``|ruwvrz^^>>-...... <br></br>
.+>>?v^.`~|^rw>..`.............>uxxxwzr???>-.      ..... .+rx}v^+?+-...... <br></br>
`>>?v-..`-~^wz> .-. `?|.........vuuxxwv?}}}}?|`.  ........ .~v>|>?vrv^.... <br></br>
.+>}-...`-`~?x+ `. |rzv`........`zuuzvvvvvv}}vv?~.  ........  -^^?xxzwr>`. <br></br>
.^v+...  .`.`r+ ..>zv|. .........}uz??}vvvv}}?v}}v+`. ...........^ruuzwxz?.<br></br>
`?}` >v>~`..`+>^`|v^. ...........vxv~>ww}vvvv?vvv}}v?^` ......... .~vxuwwu|<br></br>
.^v. +vvvv?~????+`. .............v}- .~?wxzwr?vvvvvvvvv>~. ........  `?zzw|<br></br>
.|?.   ...`-------->|~``.........?-   ...|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
.-+.. .....-~---```>v}rr}+..... -~   ..`   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
.--.. .. `?w+..~>..  .-+?`....  ...`..`.     .~vzuuxxxxxww}?}}v>-.  .....+`<br></br>
.``...  >rw+.. ~x>...   .....  .. .}- ..        `>wxzzzzzzz}v}vvv>^. . .?+ <br></br>


            </div>,

            <div>

 .``.~?|..-vvvvv}v^................................``````````---~|^>???>+>`<br></br>
 ``-?v~`````?zuzzzzv>`.```.`..``-?-.............``````--~~~~~~~||^+>??>>>?`<br></br>
.|^v>````````|>wuxzzzr>`...|?`?>???+|>~..........``````-----~~~||^^^^^^^+?`<br></br>
-w}>..````````.`?zuuxzz}^..+vvvv?>?vv>............`````---~~~~~|||||||||^+`<br></br>
`zzzv~..`.`````..`>wuxxxxv~^v????>?v?|................`--~~--~~~~~~~~~~~~~.<br></br>
`xxxuxv~...`...``...?xzw}wr??????v???+``..........  .`~~`-~--~~~~~~~~~~-~-.<br></br>
`xxxxxuz?~.``.`-`...`>vvvr}}????vv???>^~`........  ~vrwwv~----~-----~~~-~-.<br></br>
`wzwwzzzxr?|``-`........^}zzv>+>>+|~`.``.....`~~-.|wwwwwx+----------------.<br></br>
`xuxxxxzzzrv^~..`..`......|}w}??}v~. .......`|+++^?v>>rwv--------```````--.<br></br>
 ^}xuxxxxxux}??~``````.....-rzwrxxx}+.....-+>^~+|+?^>+r?```````--`````````.<br></br>
.^^+vxuxxxxxxrv}?|```-`..`.^?-~?wwwwzv^>??v??>`~->>++>~.``````````````````.<br></br>
.--~^?rxuxxxxurv}v?|~-``...|v...`??v??vvv+`.`....~~~^^` .``````````.``````.<br></br>
.~++|||?wxuxxxuwr}}}?^~-...|v...`>-+>vrwwr?-.....`~|~++.  ........-|^||`~+`<br></br>
.|-|?^^+>vwzzwww}rvvvv?|...~}...`?...|vxxzzw?-`-.|vv-~~`...`|>^~~^?>~^+>??.<br></br>
.^~^>v?v}v?v}zxxzwzrvvvv?|.`r`...>.....~vxuxwvv|`?v^.......-+??>>>>~~>+>+~.<br></br>
.^^-|vw}vv}vv}zuuzzuzr}}}}?~v--|+v+>?}?>+>vwuxwr?v?.........~+>>>>^+>+|~`` <br></br>
.`..`+}rr}rzw}vrzuwzxz}v}}rr}wxzwv?ruuuz?^^~^}x?v?v?~......^>>>>>>>>>~```` <br></br>
 .-~~~~wxuuzrvvv?v}rwxuuuxxxzzr+~+>+vzxr>^^` .-?vvxwxv^`.|v?>>>>>>>>+````` <br></br>
 `--~~?uuxz}??>|-...`-|>}zuuxrvvzuw?+>v?^??`...|??zzzuz??}?>>^~||+>>|````` <br></br>
 `^++vuzrv?+-............`>wurw}}uuw^`^>vv?^^||-``.^?z>?v>++~..`~+>+`..... <br></br>
.^^>}xr}v?+|--`.............+rzzr}v?^~vz}+.```-||^+++^^+^+?v}?--^>>~...... <br></br>
.->v?vr?^+>+^^-...............?zzwrrrxrvv?>>>>+^|~``..`-~ruwvww|+>+....... <br></br>
.+>??v>+^++>rw+  ..............>uxxxwz}???>-.      .......+rx}>^>v+-...... <br></br>
`>>vr>>^>>+>wz> .-. `>-.........?uxxxwv?}}}}v^`.  ........ .|v|+??rrv^.... <br></br>
.+?r^++>>?>~?x+ `. ~rz?.........`zuuzvvvvvv}}vv?|.  ........ .-^^vuxzwr>`. <br></br>
.^}>``|.-~-`~r+ ..+wv|. .........}uz??}vvvv}}?v}}v>`. ...........+ruuzwxz?.<br></br>
.?r` >v+~`..->>+-|v^.............vx}~>ww}vvvv?vvv}}v?^` ......... .|vuuwwu|<br></br>
 ^}. +vvvv?~????+`. .............v}~ .~?wxxzwvvvvvvvv}v>~. ........  -?zzw|<br></br>
.|v.   ...`-------->|~``.........?-   ...|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
.~>.. ... .------.`>v}rr}>..... -~    `.   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
.--.. .. -?r^..~>||  .-+?-....  ...`..`.     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
 ``...  >wr+.  ~z?~`...  ....  .. .}- ..        `>wxzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..-?vvv}}?^................................``````````---~|^>?v??>>`<br></br>
 ```?v~`````?zuzzzz}>`.````.....................``````--~~~~~~~||^^>??>>>?`<br></br>
.~|v?````````|>wuxzzzr>`.......>+-^>-............``````--~--~~~||^^^^^^^+>`<br></br>
`r}+..````````.`>wuuxzz}^..```?vvvv??>^`..........`````----~~~~|||||||||^+`<br></br>
`zzw?-..`.`````..`>wxzzxxv|.`?????+???+...........`````------~~~~~~~~~~~~~.<br></br>
`xxxuz?-...`...``...?xxw}zw????????v???+`.........`-~````.`--~~~~~~~~~~~~-.<br></br>
`xxxxuuw>`.``.`-`....>?vv}}?????vv}v?~-```.......`vrw}^. .`---~-----~~~-~-.<br></br>
`wzwzzzxx}>-``--........^}zw>>?????>^`.......`-` -}wwwr>.`----------------.<br></br>
`xuxxxzzzzrv|~..``.`......|}r?>+??^.........~+++||vrwwwz^`-------```````--.<br></br>
.>wuuxxxxxxx}?>~``````.....~wxwrzzx}+.....~+>||+^|>?+vww>`-````--`````````.<br></br>
.^^>wuuxxxxxxrv}?~```-`..`.|?~|?zwwwzv^>????v|-~~?^^^vwr|`````````````````.<br></br>
.--~+vzuuxxxxurv}v?|~-``...|v...-??v??vvv+```.``^^+||>+-``````-```````````.<br></br>
.~^+||^vzuuxxxurr}}}?|~-...~}...`>`^>vwwwr?`.....`-~^+^`.`..~>}~``-```````.<br></br>
.|-|?+^+?}wzzwww}rvvvv?|...-}...`?...|vxxzzw?``-.+?>-^|`....|>>|->>^|`````.<br></br>
.^|^>vvv}v?vwxxxzwzrvvvv?|.`}`...?.....~vxuxwvv|`vv^```.....~^>>>>+^~`````.<br></br>
.^^-|vz}vvvvvwzuuzzuzr}}}}?~v--|^v>>vv??^>vwuuwr?v?.........-^>>^`|>~````` <br></br>
.`..`+}rr}wzw}vrzuwzxz}v}}r}}wxzwv?wuuxw?^^|+}zvv?v?-......-^>>>+^>>-````` <br></br>
 .-^^~-rxuuw}vvv?v}rwxuuuxxuzzr>|+>+vzxz>^+` .-?vvuwxv|..|>?>>>>>>>>?~```` <br></br>
 ~|~-~>uuxw}??>^-....`~>vzuuxrvvzuw?+>}????`...~>?wzzx}?v}?>>>>>>>>>?}-``` <br></br>
 `~^>vwr}??+-............`+}uww}}xuz+`++vv?^^||-``.^vv+v?+^^|-~^>>+^^?^... <br></br>
 |>?vw}vv>-`................|}zwr}}?^~vz}>.```~||^+++|^^^+??v?~|>>~```.... <br></br>
.>vv^?rv|--`.`................?xzwrrrxwvv?>>>>+^~-``..``|wuwvwv|>>`....... <br></br>
.vvv?r>`~~``}r+  ..............>uxxxwzr???>`.      ..... .+}z}?|>?^-...... <br></br>
.?v?r|`~~--.vz> .-. `?|.........vuxxxwv?}}}}?|`   ........ .~v+|?vvwv^.... <br></br>
.?>r|--||^-.+x+ `. |rzv`........`zuuzvvvvvv}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.^v+``~~+-``.}+ ..>z}|. .........}uzv?}vvvv}}?v}}v+`. ...........^ruuzwxz?.<br></br>
.?}-~>vv>`.`-+>^`|}+.............vxv~>wr}vvvv?vvv}}v?^`.......... .~vxuwwu|<br></br>
 ^v|.+vvvv?^????+`. .............v}- .~?wxzwr?vv?vvvvvv>~. ........  `?zzw|<br></br>
.|?~.  ...`-------->|~``.........?-  ....|vxuwxzwr}vvvvvv>|`.  ....... .+}~<br></br>
.~>.. ...  -~---```?v}rr}+..... `-    .-   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
.-~.. .. `?w+..~>.~+~`|>?`....  ...`. ``     .~vzuuxxxxzwr}?}}v>-.  .....+`<br></br>
 ``...  >rr+.. ~z^.^>``-~`...  .. .}- ..        `>wzzzzzzzz}v}vvv>^. . .?+ <br></br>


            </div>,

            <div>

 .``.~?|..-?vvvv}v^................................``````````---~|^>?vv?>>`<br></br>
 ``-?v~`````?zuzzzz}>`.````.....->`.............```````--~~~~~~||^^>???>>?`<br></br>
.|^v>````````|?wuxzzzr>`....^^`?v?????`..........``````--~--~~~||^^^^^^^+>`<br></br>
-r}>.`````````.`?zuuxzz}+..`vvvvv????~............`````--~--~~~|||||||||^+`<br></br>
`zzzv~.````````..`>wxzxxxv||v???v????+`````.......`````--`.`-~~~~~~~~~~~~~.<br></br>
`xxxuxv~...``..``..`vzxrrwr?v?????vv?>>`-`........`````. .`--~~~~~~~~~~~~-.<br></br>
`xxxxuuz?~.``.`-`....+?v}r}????>?????+-............``.  .-----~-----~~~-~-.<br></br>
`wzwwzzzxr?|```-........^vzw?>????>>+~.......````|?vv?^-``----------------.<br></br>
`xuxxxzzzzrv^~..`..`......|}wv>>??^. .......`^++|v?>?wwrv~`------```````--.<br></br>
 |}xuxxxxxxx}??~``````.....~rxwwxzx}+.....`|+|^+^+^>+rwzzw|..`---`````````.<br></br>
.^|^vxuxxxxxxrv}?|```-`..`.^?-~?wwwzxv^+>>v?v+-^^|+?+v}wwz?.``````````````.<br></br>
.--|^?rxuxxxxurv}v?|~-``...^v...`??vv?vvv?-`~```|+^|++^+>+`````->>````````.<br></br>
.~^+|||?wxuxxxuwr}}}?^~-...|v...`>->?vrrrr?-.....`-~^++`..````.->v?```````.<br></br>
.|-|?+^+?vrzzwww}rvvvv?|...~}...`?...|vxxwwr?-`-.~?>-^+`........|>>~~~-```.<br></br>
.^|^>vvv}v?v}zxxzwzrvvvv?|.`r`...>.... ~vxuzrvv|`?v?```........`|>>v?>^^-` <br></br>
.^^-^vw}vvvvvrzuuzzuzr}}}}?~v--|^v+>vv?+^>vwuxwr?v?`..........`|>>>^~+>|`` <br></br>
.`..`+}r}}rw}vv}zuwzxz}v}}r}}wxzwvvzuuur>++~+}z?????~.......`-^>>>+-~>>^-` <br></br>
 .-||~~rxuuwvvvv?v}rwxuuuxxxzz}>|+>+vzxw?^+` .-?vvxwxv^`..`|+>>>>>+>>>>?|` <br></br>
 -|~--?uuxwv?>+^-...`-|>}zuuxrvvzuw?^+v?>?v`...|??zzzurv^???>>>>>>>>>|~~`` <br></br>
.~-``?wrv>?^-............`>wurz}}uuz^`+>vv?^^||-``.^v}+vv?++^^^+>>>>~`.... <br></br>
 ``.~}vv?|..................+rzzrrv?^~vz}+.```~||^+++~^+^+>?}?~-^>>+...... <br></br>
 `^>`>v^......................?zzwrrrxrvv?>>>>+^|-``..``|wuwvrx+^>>-...... <br></br>
.????v-... `v}+  ``............>uxxxwzr???>-.       .... .+rx}v^^?>-...... <br></br>
.?vv}|-~`  .vz> .-. `>-.........?uxxxwv?}}}}v^`   ........ .|v>|>vvrv^.... <br></br>
`??w>`-`-`. +x+ `. ~rz?.........`zuuzvvvvvv}}vv?|.  ........ .-^^?xxzwr>`. <br></br>
.?}v.|`|~+~..}+ ..+wv|. .........}uzv?rvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
`?w-~?}v??|.->>+`~v^.............vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 >}.^>wwwwv>????+`. .............v}~ .+?wxxzw?vvvvvvv}v?~. ........  -?zzw|<br></br>
 >v`-`|^^-~|------->|~``......`..v^   ~..|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
.|>-^.-``  -~----``v}rrrr?~``|~ `^-   ~.   `>}uuuxxxxzw}vv???|.  ....... ..<br></br>
.-~.~ .. -vr+..~>..`^>>?}?>+^^-`.~--. -.     .~vzuxxxuuxzwrvv}v>-.  ... .+`<br></br>
 ``...  >wr+.. ~w|  `+~^+>>^|-~..-`r- `.        `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..-?vvvv}?^................................```````````--~~|+??v??>`<br></br>
 ``-?v~`````?zuzzzz}>`.```.`..``-?-..............``````--~~~~~~||^^>?v??>?`<br></br>
.~|v?````````|?wuxzzzr>`...|>`?>?v?+^+~...........`````--~---~~||^^^^+^^+>`<br></br>
`r}+.`````````.`>wuuxzz}^..+vv?v?>?v?>............`````------~~|||||||||^+`<br></br>
`zzw?-.````````..`>wxxxxxv~^v????>?v?^................`------~~~~~~~~~~~~~.<br></br>
`xxxuz?-...``...-...?xzw}wr??????v???+``..........  .`~~`-----~~~~~~~~~~~-.<br></br>
`xxxxuuw>-.````-`...`>vvvr}}????vv???>+~`........  ~vrrrv~----~-----~~~~~-.<br></br>
`wzwzzzxx}>-````........^}zzv>+>>+|~````.....`-~`.^wwwwwz+------------~---.<br></br>
`xuxxxzzzzrv|~..`..`......^}w}??}?~. .......`|+++^?v>?rwv---------````----.<br></br>
.>wuuxxxxxxxv?>~-`````.....-rzwrxux}+.....`+>^~+^+>^>+rv-`````---`````````.<br></br>
.^^>wuuxxxxxxrv}?~```-`..`.|?-~?zwwwz}^>??v???`~->>>>>|.``````````````````.<br></br>
.--|+vwuxxxxxurv}v?|~-``...|v...-??v?vvvv+`.`...`~~~^^` ..````````````````.<br></br>
.||^||^vzuuxxxuwr}}}?|-~...~}...`>`+>vrww}?-......-|~++.. ......``-|^|~`~+`<br></br>
.^-|?+^+?}wzzwww}rvvvv?|`..-}`..`?...|vxxzzw?-`-.|vv~~~`....|>^~~^?>~^++??.<br></br>
.^|^>vvv}v?vwxxxzwzrvvvv?|.`}`...?.... -vxuxwvv|`?v^.......`+>?>>>>|~>+>^~ <br></br>
.+^~^vz}?vvvvwzuuzzuzr}}}}?~v--|^v>>v???^>vwuxwr?v?.........~+>>>>^+>+|~`` <br></br>
.`..`+vr}}rw}vvrzuwzxz}vr}r}}wxzw}vwuuxw>^+|+}x?v?v?-......^>>>>>>>>>~```` <br></br>
 .`~`..rxuuwvvv??v}rwxuuuxxxzzr>|+>^vxxw>^+` .-?vvuwxv^..|??>>>>>>>>+`````.<br></br>
.`..  ^xuxw}?>>^-...``~>vzuuxrvvzuw?+>r?>?v`...|??wxzuw??}?>>+|||+>>|````` <br></br>
 ....>zwv??+-............`+}uww}}xuz+`>>vv?^^||-``.^vw>?v>++-..`|>>>`.``.. <br></br>
 -. -}vv?+`.................|}zw}}}?^~vz}>.``-~||^++>|^+^+??}?--^>>~...... <br></br>
 .~|`+}>`....`................?xzwrrrxwvv?>>>>+^~-``..``~}uwvww|+>>....... <br></br>
.>>>>v|... `}r+  `.............>uxxxwzr???+`.      .......^}x}>^>v+-...... <br></br>
.???v-...  .vz> .-. `?|.........vuxxxwv?}}}}?|`   ........ .~?|+??rrv^.... <br></br>
`??}~-  .`. +x+ `. |rzv`........-zuuzvvvvvv}}vv?~.  ........ .-^|vuxzwr>`. <br></br>
.>v>|....``..}+ ..?zv|. .........}uzv?rvvvv}}?v}}v+`. ...........+ruuzwxz?.<br></br>
`v}+->}??~..`>>^`|}+. .......`...vxv~?wr}vvvv?vvv}}v?^`.......... .~vxuwwu|<br></br>
`?v^.vrwwrv^????+`. ...`.``-`|.`~vw^ ~+?wxzwr?vvvvvvvvv>~. ........  `?zzw|<br></br>
`?v|`>^++?+^------->+>>+`|^+||.|^v>|`|~..|vxuwxzwr}vvvvvv>|`.  ....... .+}~<br></br>
`>>|~~|^+~.-~---```>vrzwr?>+~^.|+^^>~^~`   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
.|-`+...`~?w>..~>....`+>?>>+-~.^|->?^|`.     .~vzuuxuxxzwr}?}}v>-.  .....+`<br></br>
 -`.~.  >rr+.. ~z| ......|+|~.`~-`^r|``.        `>wzzzzzzzz}v}vvv>^. ...?+ <br></br>


            </div>,

            <div>

 .``.|?|..-vvvvv}v^.................................``````````--~~|^>?vv??`<br></br>
 ``-?v~.````?zuzzzz}>`.````......................``````--~~~~~~||^^>?vv?>?`<br></br>
.~^v>````````~?wuxzzzr>`.......>+-^>-.............`````--~---~~||^^^++^^+>`<br></br>
-w}>.`````````.`?zuuxzz}+..`--?vvvv??>^`..........`````------~~|||||||||^+`<br></br>
`zzzv~.````````..`>wxzzxxv|``?????+???+............````----~-~~~~~~~~~~~~~.<br></br>
`xxxuxv~...``...-..`?zxw}zw????????v???+`.........`-~````.`---~~~~~~~~~~~-.<br></br>
`xxxxuuz?~.````-`....>?vv}}?????vv}??~````.......`vrr}^. .`---~-----~~~~~-.<br></br>
`wzwwzzzxr?|`````.......^}zr>>?????>^`......``-` -}wwww>.`-----------~~---.<br></br>
`xuxxxxzzzrv^~`.`.........|}r?>+??^.........~^++||vwwwwz^`--------````----.<br></br>
 ^}xuxxxxxux}??|-`````.....~rxwrzzx}+.....~+>~|+^|>?+vww>`-```---`````````.<br></br>
.^|^vxuxxxxxxrv}?|```-`..`.^?-|?wwwwzv^>??v?v|-~~?^^+vwr~.````````````````.<br></br>
.-~^^?rxuxxxxurv}v?|~~``...^v...->????vvv+```.``^^+|^>^-.`````-```````````.<br></br>
.||^|||?wxuxxxuwr}}}?^-~...|v...`>`^>vwww}?-.....`-~^+^`.`..->r~..`-``````.<br></br>
.^-|?>^+?vrzzwww}rvvvv?|`..~}...`?...|vxxzzw?``-.+?>-^^.....|>>|~>>^|`````.<br></br>
.^|^>vvv}v?v}zxxzwz}vvvv?|.`r`...?.... ~vxuxwvv|-vv^``......~^>>>>+^~`````.<br></br>
.++~+vz}vvvvvrzuuzzuzr}}}}?~v--|+v+>vv??+>?wuuzr?v?.........-^>>^`~>~````` <br></br>
.`..`+}r}rrw}vv}zuwzxzrvr}r}}wxzwvvruuxr>^+|+}xv??v?~......-^>>>+^>>-.```` <br></br>
 .`~`..wxuuwvvvv?v}rwxuuuxxxzzr>|+>^vzxwv+^- .-?vvxwxv^..|>?>>>>>>>>?-````.<br></br>
 ``.  +uuxwv?>+|-...`-|>vzuuxrvvzuw?+>vv>??`...~>?zzzx}?v}?>>>>>>>>>?}-``` <br></br>
 .. .>zrv??^-............`>rurw}}xuz^`+>vv?^^||-``.^vv+v?+^^|-~^>>+|^?^.`. <br></br>
    -}vv?|..................+rwzrv}?^~vz}+.```~||^++>|^^^+??}?~|>>~.`-.... <br></br>
 .-|`>v^......................?zzwrrrxrvv?>>>>+^|-``..``|wuwvrv|>>`....... <br></br>
.>>>??-... `v}+  ``............?xxxxwzr???>~.       ......+rxv?|>?^-...... <br></br>
.+?vv`...  .vz> .-. `?~........-}uxuxwv?}}}}v^`.  ........ .|v+|?vvrv^.... <br></br>
.+?r`.  ``. +x+ `. ~rz?.....`.``>xxuzvvvvvv}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.^v>. .  .`..}+ ..+wv|. ...-^`.^-wux}vrvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
`?w- +v>|`..`>>+`~v^. .`.`|>~|`+|vuw>?ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |r~.>v}}v?~????+-. .-|^.^>+~^.^^vw+~~^?wxxzw?vvvvvvv}v?~. ........ .-?zzw|<br></br>
 |}^~--~^|~-------->^>+|``~|^^`~|v+-||-..|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
.|>^|+>++|`------``>v}wrr+..~~..~^`.~|-.   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
.^-|~`..-~?r+..~>.   .-+?-....  ```..~-.     .|vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
 |``^.  >wr+.. ~w|       ....  ...`v-``         `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 .``.~?^..-?vvv}}v^.................................```````````--~|^>??v??-<br></br>
 ``-?v~`````?zuzwzz}>`.````.....-+...............``````--~~~~~~~|^^>?vv???`<br></br>
.~|v?````````~?wuxzzzr>`....|~`>v?>>?>............`````--~---~~~|^^^+++^+>`<br></br>
`r}+.`````````.`>wuuxzz}^...???vv????~ ...........`````------~~||||^||^|^>`<br></br>
`zzw?-.````````..`+wxzzxxv|~????v????>`.``........`````-```--~~~~~~~~||~~~.<br></br>
`xxxuz?-...```..-`..vzxrrww???????vv??>```.........````...`---~~~~~~~~~~~-.<br></br>
`xxxxuuw>-.````-`...`>?v}}r????>?????+`............``.  .`----~-----~~~~~-.<br></br>
`wzwwzzxx}>-`````.......^}zw?>?????>+~.......```.|?vv?^-``-----------~~---.<br></br>
`xuxxxzzzzrv|-`.``........^}w?>>??^. .......-^++~vv?vwwr?~`--------``-----.<br></br>
 >wuuxxxxxxx}?>~-`````.....~rxwrzzx}+.....`|+||+|+^>>rwww}|..`---`````````.<br></br>
.||>wuuxxxxxxrv}?~```-`..`.|?~|?zwwwzv^+>>vvv+-|~^+>>vrwrw?.``````````````.<br></br>
.-~^^?wuxxxxxurv}v?|~~``...|v...-??vv?vvv?-`~``.~^^|^+^^+^`````-++````````.<br></br>
.||^||^vzuuxxxuwr}}}?^-~...~}...`>-+>vrrrr?`.....`-~^+^`.`````--^??```````.<br></br>
.^-~>>^+?}wzzwww}rvvvv?|`..-}`..`?...|vxxwwr?-`-.|?>-^+`....``-`~>>~---```.<br></br>
.^^^>vv}}v?vwxxxzwzrvvvv?|.`}`...?.....~vxuzwvv|`?v?```.....````^>>?>+||-`.<br></br>
.>+~+vz}??v}}rxuuzzuzr}}}}?~v--|^?^?v}v>^>vwuxwr?v?.........``~|>>>+~^^~`` <br></br>
.`..`+vwrrrwrvvrzuwzxz}v}}r}}wxzwv?wuyxr?+^~+}z?????-.......`^>>>>+--^+|`` <br></br>
 .-~`..rxuuwvvvv?v}rwxuuuxxxzzr>|+>^vxxw?^^` .-?v?uzxv^...`^>>>>>+++>>>?|`.<br></br>
 `..  ^xuxwv?>>^-...``~>vzuuxrvvzuw?++v?+>?`...|>?zxzxr?+???>>>>>>>>>^~~`` <br></br>
  ...>wrv>?+-............-?wxrw}}xyz+.+>vv?^^||-``.^v}+vv?++^|^+>>>>~-`.`. <br></br>
    -v?vv+`................|?}wwrv}?^~vz}+.``-~||^+++~^+^+>?}?--^>>^`..... <br></br>
 .-|.+}?`....`.........``...`+vzzwwrrxwvv?>>>>+^~-``..``|wxwvrx^|>>`...... <br></br>
.>>>>v|... `}r+  ......`|~`-~-+vuuxxwzr???>-.      ..... .+rx}v|+>+-...... <br></br>
.+?vv`...  .vz> .-. `?|.-|^>++^+ruxuxwv?}}}}?|`   ........ .~v+|?vvrv^.... <br></br>
.+>}`.  ``. +x+ `. |rzv`.|~^?+>^?uxuzvvvvvv}}vv?~.  ........  -^^?xxzwr>`. <br></br>
.^v+. .  .`..r+ ..>z}+|~-^^~>>+>|wux}vrvvvv}}?v}}v+`. ...........^ruuzwxz?.<br></br>
.>}` >v>~`..`>>^`|}>++??^^?+~?+^^vur^vwr}vvvv?vvv}}v?^`.......... .~vxuwwu|<br></br>
 |v` +v}vv?|????+``~+?^+~~|~~^^`~vr~.~+?wxzwr?vvvvvvvvv>~. ........  `?zxw|<br></br>
 |v|`. -`.`--------?+^-``...```..?^ .`-`.|vxuwxzwr}vvvvvv>|`.  ....... .+}~<br></br>
.^>^~|--^~`~~---```>?}rr}+..... `~. ..`-   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
 +~|||~^>>vw+..~>..  .-+?`....  ......``     .~vzuuxxxxxww}?}}v>-.  .....+`<br></br>
.^>.^-`~?ww+.. ~z^ ...   ....  .. .}- `.  .     `>wzzzzzzzzr}}vvv>^. ...?+ <br></br>


            </div>,

            <div>

 .``.|?|..-vvvvv}v^.................................```````````--~|^^>?vvv-<br></br>
 ``-?v|`````?zuzzzz}>`.```.`..``-?-..............``````---~~~~~~|^^+?vvv??`<br></br>
.|^v>````````~?wuxzzzr>`...^>`?>?v?+|>~...........`````--~~--~~~|^^^+>+^+>`<br></br>
-w}>.`````````.`?zuuxzz}^..+vvvv?>?v?>............``````-----~~|||^^^^^|^>`<br></br>
`zzzv|.````````..`>wuxxxxv|^v????>?v?^.................`--~~--~~~~~~~|||||.<br></br>
`xxxuxv~...```..-`..?xzw}wr??????v???^``..........  .`~~`-----~~~~~~~~~~~-.<br></br>
`xxxxuuz?~.````--...`>vvv}}}????vv???>+~`........  ~vrwwv~----~-----~~~~~-.<br></br>
`wzwwzzxxr?~`````.......^}zzv>>>>^|~`.``.....`-~-.|wwwwwz>-----------~~~--.<br></br>
`xuxxxzzzzrv^~`.``........|vwv??}?~. ........~+++^?v>?rwv-----------------.<br></br>
 ^}xuxxxxxuxv??|-``````....~rzwrxuxr+.....`+>^~+^+>|++r?``````---`````````.<br></br>
.|~|vuuxxxxxxrv}?|```-`..`.^?-~?wzwwz}^>??v??>`-->?+>>|.``````````````````.<br></br>
.~~^^>}xuxxxxurv}v?|~~``...^v...-??vvvvvv>`.`....~~~^^` ..````````````````.<br></br>
.|~||||?wxuxxxuwr}}}?^-~...|v...`>`+>vrwwr>-......~|~++.  ........-^^||`-+`<br></br>
.^-~>>++?vrzzwww}rvvvv?|`..~}...`?...|vxxzzr?``-.|vv--~`...`|>^~~^?>~^++??`<br></br>
.|^^>v}}}v?vrzxxzwxrvvvv?|.`r`...>.... ~vxuzwvv|`?v^.......-+??>>>>~~>+>+~.<br></br>
.>>|>vw}v?v}v}zuuzzuzwr}}}?~v--|^v+>vv?>|>vwuxwr?v?.........~+>>>>^+>+|-`` <br></br>
.-..-+vwrrwwrvv}zuwzxz}}}rr}}wxzwvvwxux}?^+|^}z?v?v?~.....`^>>>>>>>>>~```` <br></br>
 .--`..wxuuwvvvv?v}rwxuuuxxxxx}>|+>^?zxwv++` .-?vvxwxv^`.^v?>>>>>>>>+````` <br></br>
 `... ^xuxwv?>+|-...`->}wxuuxzvvzuw?++}v+?v`...|??zzzuw??}?>>^|||+>>|````` <br></br>
 ....>wrv>?^-.........`|>?vwuwzwrxyz^.>>vv?^^||-``.^vz>?v>++~.``|+>+`````. <br></br>
    -vvv?|.........`~-~^++++?wwzz}}?^~vz}+.```~||^++>^^++^?v}?--|>>~...... <br></br>
 .-|.+v^............`^^^>>+>++rxxzrrrxrvv?>?>>+^|-``..``~ruwvww|+>+....... <br></br>
.>>>??-... `vr+  ``..~+++?>+>^>vuxxxwzr???>~.      .......+rxv>|>v>-...... <br></br>
.+?vv`...  .vz> .-. `?>>>^>>^+>+vuxuxwv?}}}}v^`   ........ .|v|+??rrv^.... <br></br>
.+>r`.  ``. +x+ `. +rzv+>>~+^`|+>zxuzvvvvvv}}vv?~.  ........ .-^|vuxzwr>`. <br></br>
.^v>. .  .`..}+ ..+zr>|^^|~.|`.--ruzvvrvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
.>r` +v>|`..`>>+`~}v>---`........vx}~?ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |v. +vvvv?~????+-`..............v}~  |?wxxzwvvvvvvvv}v?~. ........ .-?zzw|<br></br>
 ^?.   ...`-------->|~``.........?~ . ...|vxuwxxzwr}vvvvv>|`.  ......  `>r~<br></br>
 |>.. .`~..------``>v}wr}+..... -~    `.   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
 --`~. .~>vw>..~>.   .-+?-....  . .`..`.     .|vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
.^`.|^..?ww?~. ~w|       ....  .. .}- ..  .     `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvv}}v^.................................```````````--~||^>?vvv-<br></br>
 ``-?v|`````?zuzzzz}>`.``````.`..................``````----~~~~~|^^+?v}v??`<br></br>
.~^v>````````~?wuxzzzr>`...``.`>+-^+-.............`````--~~--~~~|^^^+>>^+>`<br></br>
`r}+.`````````.`>wuuxzz}^..``~?vvvv??>|...........``````-----~~|||^^^^^|^>`<br></br>
`zzw?-.````````..`>wxzzxxv|.-?????>???|..............```---~--~~|~~~~|||||.<br></br>
`xxxuz?-...```..-`..?xxw}ww????????v???|...........``````.`---~~~~~~~~~~~-.<br></br>
`xxxxuuw>-.````--...`>?vv}}?????vvv??|-`..........?}r}>-..----~-----~~~~~-.<br></br>
`wzwwzzxx}>-`````.......^}zw?>????>+~........`-`.`vwwww>`-~----------~~~~-.<br></br>
`xuxxxzzzzrv|-`.``........|}r?>>??^. .......`^++||vrrrwz+`----------------.<br></br>
 >wuuxxxxxxxv?>~~``````....~rxwrzzx}+.....~+>||+^|>>+vwr+````----`````````.<br></br>
.|~+wuuxxxxxxrv}?~```-`..`.^?-|?wwwwzv^>?????|-~~>^|+?vv~`````````````````.<br></br>
.~~^^?wuxxxxxurv}v?|~~``...|v...-??v??vvv+..`...~^^~|+|..`````-```````````.<br></br>
.|~|||^vzxuxxxuwr}}}?^-~`..~}...`>`+>vwwwr>`.....`-~^++`....-+v-..`-`````-.<br></br>
.^-~>>++?}wzzwww}rvvvv?|`..-r`..`?...|vxxzzr?``-.^v?-^|`....|>>|->>^|---~~.<br></br>
.|^^>v}}}v?vwzxxzwxrvvvv?|.`r`...?.... ~vxuzwvv|`?v^.`......|+>>>>+^~~~--`.<br></br>
.>>|>vw}vvv}}rxuuzzuzr}}}}?~v--|^v+?}v?++>vwuuwr?v?.........-^>>+`|>|````` <br></br>
.-..`+vrrwwwrvvrzuzzuzwr}}r}}wxzwvvwuyur?^+~+}zvv?v?-......-+>>>++>>~````` <br></br>
 .```..rxuuwvvv??rwrxuuuuxxxzzr>|+>+vzxw?^+` .-?vvxwxv^..^??>>>>>>>>>-````.<br></br>
 `... ^xuxwv??>^--~+>?vrwxuuxzvvzuw?++v?+??`...~??wxzx}?v}?>>>>>>>>>>?-``` <br></br>
  ...>wrv??+-...`~|^+++???vzuwzrrxyz+`++vv?^^||-``.^v}+v?+^^|`-|+>+^^?~``` <br></br>
    -v?v?^``-~|^+++>?>????^+?zzzrv}?^~vz}+.``-~||^++>|^^^+??v?~~+>|````... <br></br>
 .-|.^}>`....~^+>++^^+???>|-^^vxzwrrrxwvv?>>>>+^|-``..``~wuwvwv|>>~....... <br></br>
.>>??v~... `}r>`~|^+>?>+>>^``|~?uxxxwzr???>-.      .......+rz}>|>v^-...... <br></br>
.+?vv`...  .vz>-~^~-|v?||~|`````vuxuxwv?}}}}?|`   ........ .~v+|??}wv^.... <br></br>
.+>}`.  ``. +x>.~~|?wzv```.``...-zxuzvvvvvv}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.^v>. .  .`..}+ -~?z}^...........}uz??rvvvv}}?v}}v+`. ...........+ruuzwxz?.<br></br>
.+r` >v>~`..`>>>~^v+`............vx}~?wr}vvvv?vvv}}v?^`.......... .~vxuwwu|<br></br>
 |v. +vvvv?|????+`. .............v}- .|?wxzwr?vvvvvvvvv>~. ........  -?zxw|<br></br>
 ^?.   ...`-------->|~``.........?-   ...|vxuwxzwr}vvvvvv>|`.  ......  .>r~<br></br>
 ^>.. .....-~---```>vrwr}+..... -~    .`   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
 --......-?w>``|?.   .-+?`....  . .`..`.     .|vzuuxxxxxzw}?}}v>-. ......+`<br></br>
.^`.....>wz?|-.~z^ ...   ....  .. .}- `.  ..    `>wzzzzzzzzrv}vvv>^. ...?^ <br></br>


            </div>,

            <div>

 ```.~?|..-vvvvv}v^.................................```````````--~|||^>vvv-<br></br>
 ``-?v|`````?zuzzzz}>`.`````....-+...............``````------~~~|^^+?v}}v?`<br></br>
.|^v>```````.-?wuxzzzr>`..`.||`?v??>??`...........``````-~~---~~|^^^+>>++>`<br></br>
-w}+.`````````.`?zuuxzz}+..`?vvvv????~............``````-----~~|||^^^^^^^>`<br></br>
`zzzv~.````````..`>wxzxxxv|~v???v????+-.``.........````-```---~~||||||||||.<br></br>
`xxxuxv~...```..``..vzxr}ww???????vv?>>```........`````...`---~~~~~~~~~~~-.<br></br>
`xxxxuuz?~.```.--....>?vvrr????>?????+`.............`.  .-----~---~~~~~~~-.<br></br>
`wzwwzzxxr?~---``.......^}zw?>????>>+~.......```.|?vv?^```-----------~~~~-.<br></br>
`xuxxxzzzzrv^-`.``........|}w?>>??^. .......-^++~?v>?wwr?|`---------------.<br></br>
 +rxuxxxxxxx}?>|~``````....~rxwwxxxr+.....`|^||+^^^>+rwzzr|.`-----````````.<br></br>
.~~|}uuxxxxxxrv}?|```-`..`.^?~~?wzwzzv^+>>vvv^-||^+?>vrwwz?.``````````````.<br></br>
.~~+^>}xuxxxxurv}v?|~~``...^v...-??vvvvvv?~`~```~^^|^^^^++`````-+>````````.<br></br>
.|~|^^|?wxuxxxuwr}}}?^-~`..|v...`>->>vrrrr?-.....`-~^++`..`````-+??```````.<br></br>
.^~~>?>+?}rzzwww}rvvvv?|`..~}...`?...|vxxwwr?-`-.|?>-^+`....````~>>~---```.<br></br>
.|^++vrrvv?vrzxxzwxrvvvv?^.`r`...>...``~vxuzrvv|`?v?```.....````^>>??>||-`.<br></br>
`?>|?vw}vvv}}}zuuzzuzr}}}}?~v--|+?+?}wv^^>?wuxrr?v?`.........`-|>>>^-++|`` <br></br>
.-..-+vwrwwwrrrwxuzxxxr}}}r}}wxzwv}wuux}?++~+}z???v?~.......`|+>>>+`->>^`` <br></br>
 .-```-wuuuzr}}}}wwwwxuuuxxxzzr>~+>+vwzw?^^` .-?vvxwxv^`..`|+>>>>>++>>>?|` <br></br>
 .... ^xuxwrvv}}vv??????}xuuxwvvzuw?++v?>??`...|??zzzxwv+???>>>>>>>>>|~~`` <br></br>
 .. .>wrv??++^^^++>^||~~`->wuwz}}xyz^`++vv?^^||-``.^v}+vv?++^|^+>>>>|````` <br></br>
    `v?vv+|^^^++^^^~`......`>rwzr}v?^~vz}+.```~||^+++~^+^+>?}?-`^>>+`..... <br></br>
 .-|.^}>--^++>++>>>+^~~`......?xzwrrrxwvv?>>>>+^|-``..``|wuwvrx^|>>-...... <br></br>
.?>???-...`~rw?~|^^-`..`.......>uuxxwzr???>-.      ..... .+rx}v|+>+-...... <br></br>
.+?vv`...  .vz?^|^. `>~.........?uxuxwv?}}}}v^`   ........ .|v+|>vvrv^.... <br></br>
.+>r`.  ``. +x?^+` ~rz?`........`zuuzvvvv}v}}?v?~.  ........  `^^?xxzwr>`. <br></br>
.+v>. .  .`..}?~-.+wv|...........}uz??rvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
.+r` +v>|`..`>?+`~v^.............vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |v. +vvvv?~????+-. .............v}~  |?wxxzwvvvvvvvv}v?~.  ....... .-?zxw|<br></br>
 ^?.   ...``------->|~``.........?~ . `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
 ^>.. .....------``>vrwr}>..... `~    ``   `>ruuuxxxxzw}vv?v?|.  ....... ..<br></br>
 -`.. .. -?w+.`|>.   .-+?-....  . .`. ``     .|vzuxxxuuxzwrvv}v>-. .... .+`<br></br>
.+....  >rr+.-?vw|       ....  .. .}- `.  ..    `>wzzzzzzzzwvr}}v>^. . .?^ <br></br>


            </div>,

            <div>

 ```.~?|..-?vvvv}v+.................................```````````--~||||+?v}~<br></br>
 ``-?v|`````?zuzzzz}>`.`````....-?-..............``````------~~~||^+>vv}vv-<br></br>
.|^v>```````.->wuxzzzr>`.`.-^`^>???>^>-...........``````--~---~~||^^+??>>>`<br></br>
-w}+.`````````.`>wuuxzz}^..~vvvvv??v?+............``````-----~~~||^^^^^^^>`<br></br>
`zzw?-.````````..`>wxxxxxv~|v???v????^................`````---~~|||||||||^.<br></br>
                  `xxxuz?-...```..``..?xxwrwr??????????+~..............`-`..`---~~~~~~~~~~~~.<br></br>
`xxxxuuw?-.`````-`...>?vv}rv????vv???>|..........  ->?>+>~----~~--~~~~~~~-.<br></br>
`wzwwzzxx}>----``.......^}zwv>>??>+|~`.......`--`.+rrv?vv|-----------~~~~-.<br></br>
`xuxxxzzzzrv^-`.```.......|}wv??v?~. .......`|++^>v??vwwv-`---------------.<br></br>
 >wuuxxxxxxxv?>~~``````....-rxwwxxx}+.....`^>|~+|+>|+?w}>+-.`-----````````.<br></br>
.~~+wuuxxxxxxrv}?~```-`..`.^?-~?wzwwz}^>??v?v>`~-^+>+>>+++|```````````````.<br></br>
.~~+^>wuuxxxxurv}v?|~~``...|v...-??v?vvvv>`.`.`.`||~^^`.```````-|~````````.<br></br>
.^~|^^|?wxuxxxuwr}}}?^-~`..|}...`>->>vrwwr?-......--|++.......``~^^~~--`-|.<br></br>
.+~~>?>+?}wzzwww}rvvvv?|`..-r`..`?...|vxxzzr?-`-.^??-|^`....-|~-~^>+~||^+^.<br></br>
.|^++vrrvv?vrzxxzwxrvvvv?^.`r`...?.....~vxuzwvv|`?v+..`....`|^+^+>>+^+++~`.<br></br>
`?>|?vw}v?v}}rxuuzzuzr}}}}?~v--|^v+?v}v+^>vwuxwr?v?`.......`-|^+>>+^++^~`` <br></br>
.-..`>}wwwzzwr}wzuwzxxrv}}r}}wxzwvvwuyxr?++~^}zvv?v?~......~^+>>>>+^^^~-`` <br></br>
 .--~~~wuuuzrvvvv}rrwxuuuxxxzzr>~+>^vwzw?|^` .-?vvuzxv^`.-+>>>>>>>>>>||^-` <br></br>
 .....+xuxwrv??>+|~~~~^>vzuuxwvvzuw?+^?????`...~>?zxzuw?>??>>++++>>>^---`` <br></br>
  . .>wr}???+~````````...`+ruwz}}xyz^`^^vv?^^||-``.^vw>vv>+^~`-|^>>>-.```` <br></br>
    `v?v}?^^||||~-``........^}zzr}}?^~vz}+.```~||^++>|^+^+>?v?-`|>>|...... <br></br>
 .~|.^}?^|^^+^^|~--```........?xzwrrrxwvv?>>>>+^|-```.``~ruwvww|^>+`...... <br></br>
.?>??v~..`-~rw?``-`............>uuxxwzr???>-.      .......+rx}?^>?+-...... <br></br>
.+?vv`......}x?..-. `?|.........vuxuxwv?}}}}?|`   ........ .|v^^??}rv^.... <br></br>
.++}`.  ``..?x>.-. ~rzv`........`zuuzvvvv}v}}?v?~.  ........ .-||vuxzwr>`. <br></br>
.^v>. .  .`..}>.`.>zv|...........}uz??rvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
.^r` >v>~`..`>>^`|v+.............vx}~>ww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
 |v. +vvvv?~????+-. .............v}-  ~?wxxzw?vvvvvvv}v?~.  .......  -?zxw|<br></br>
 ^?.   ...`-------->|~``.........?- . ...|vxuwxxzwr}vvvvv>|`.  ....... .>r~<br></br>
.+>.. .....-----```>vrwr}>..... -~    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 -`.. .. `?w+..|>.   .-+?-....  . .`..`.     .|vzuxxxuxxzw}?}}v>-. ......+`<br></br>
.+....  >rr+...+x+....   ....  .. .}- .. ...    `>wzzzzzzzzrv}}vv>^. ...?^ <br></br>


            </div>,

            <div>

 ```.|?|..-vvvvv}v^.................................```````````--~||||+?v}~<br></br>
 ``-?v|`````?zuzzzz}>`.``````....................``````------~~~||^+>v}rvv-<br></br>
.|^v>```````.`>wuxzzzr>`..```.`>+~^>-.............``````--~---~~||^^+??>>>`<br></br>
-w}+.`````````.`?zuuxzz}^..``-?vvvv??>|...........``````-----~~~||^^^^^^^+`<br></br>
`zzzv~.````````..`>wxzzxxv|.-?????>???|..............```------~~|||||||||^.<br></br>
`xxxuxv~...```..``..?xxw}zw????????v???|...........``````.`~--~~~~~~~~~~~~.<br></br>
`xxxxuuz?~..-```~`...>?vv}rv????vvvv?|-`..........?}r}+`..----~~--~~~~~~~-.<br></br>
`wzwwzzxxr?~---``.......^}zw?>????>+~........`-` `vwwww>.------------~~~~-.<br></br>
`xuxxxzzzzrv^-`.```.......|}r?>>??|. .......-^++||vrrrwz+-----------------.<br></br>
 +rxuxxxxxux}?>|~-`````....~rxwrzzxr+.....~+>~|+^|?>+vwr+`--`-----````````.<br></br>
.~-^}uuxxxxxxrv}?~```-`..`.^?-|?wzwwzv^>?????|-~~>+^^?}v~```````--````````.<br></br>
.|~+^>}xuxxxxurv}v?|~~``...|v...-??v??vvv>```.`.~^^~|+|`.`````-```````````.<br></br>
.^~~^^|?rxuxxxuwr}}}?^`~`..|}...`>`+>vwww}>-.....`--^++`....-^v-.``-`````-.<br></br>
.+~~>?>>?}rzzwww}rv?vv?|`..~}...`?...|vxxzzr?``-.+??-||`....|>>|->>^|---~~.<br></br>
.|+++?rrvv?vrzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v^.`.....`|+>>>>>|~-~--`.<br></br>
`??|?}rrv?v}}rzuuzzuzr}}}}?~v--|+v+>v}?>+>?wuuwr?v?.........-^>>+`|>|````` <br></br>
.-..`>}wwzzzw}vrzuwzxzr}}}r}}wxzwv?ruuuw?++~^}zvv?v?~......~+>>>++>>~````` <br></br>
 .-~~~|zuuuz}vv??}rrwxuuuxxxzzr>~+?+vzxw?^+` .-?vvxzxv^`.|??>>>>>>>>>-```` <br></br>
 `----?uuxwv??>^~``.``|>vzuuxwvvzuw?++v?+??`...~??zzzx}?v}?>>>>>>>>>>?-``` <br></br>
 `~~|vxzwvv>~`...........`>wuwz}}xyz^`^+vv?^^||-``.^v}+v?+^^~`-|+>+^^?|``` <br></br>
 ~~|?zv}v?^|~~-`............+rwzr}}?^~vz}+.```~||^++>|^^^+??v?-~>>|```.... <br></br>
.->?+?r?^^++^^-...............?xzwrrrxwvv?>>>>+^|--``.``~wuwvwv|>>~....... <br></br>
.>???v+^^^^>rw>..``............>uuxxwzr???>-.      .......+rx}?|>?^-...... <br></br>
.+?v}^^|^+^>wz> .-. `>~.........?uxuxwv?}}}}v^`   ........ .|v+^??}rv^.... <br></br>
.^+r||~^|+^-?x> `. ~rz?`........`zuuzvvvv}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
`^v>..-.```.-r+ ..+zv|...........}uz??rvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
.^r` >v>~`..->>+`|v^.............vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |v. +vvvv?~????+-. .............v}~  ~?wxxzwvvvvvvvv}v?~.  .......  -?zzw|<br></br>
.^?.   ...`-------->|~`..........?- . ...|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.+>.. .....------.`>v}wr}+..... `-    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 -`.. .. -vw+..~>--  .-+?-....  . ... `.     .|vzuxxxuuxzwr?v}v>-. .... .+`<br></br>
`+....  >rr+.. ~z?``...  ....  .. .}- .. ...    `>wzzzzzzzzwv}}vv>^. . .?^ <br></br>


            </div>,

            <div>

 ```.~?^..-vvvvv}v^.................................```````````--~~|||^?v}~<br></br>
 ``-?v|`````?zuzzzz}>`.``````...``...............``````------~~~||^+>?vr}v~<br></br>
.|^v>```````.`>wuxzzzr>`..`.-``>>^+>^`............``````--~---~~||^^+???>>`<br></br>
-w}+.`````````.`>zuuxzz}^...||?vvv???+`...........``````-----~~~||^^^^^^^+`<br></br>
`zzwv~.````````..`>wxzzxxv|`|?????>???~.............```````---~~|||||||||^.<br></br>
`xxxuzv~...```..``..?xxwrzw???????vv??>-...........````...`---~~~~~~~~~~~~.<br></br>
`xxxxuuz?-..````~`..`>?v}rrv?????vv??^``..........|>>^.  .`---~~--~~~~~~~-.<br></br>
`wzwwzzxx}?~---``.......^}zw?>????>>^`.......``` -v}}v?|.`-----------~~~~-.<br></br>
`xuxxxzzzzrv^``.```.......|}r?>+??^. .......-^++~>}v}wwz>-`---------------.<br></br>
 +ruuxxxxxxxv?>~~-`````....~rzwrzzxr+.....-^+~|+^|>?>}wwv>~.`-----````````.<br></br>
.~-^ruuxxxxxxrv}?~```-`..`.^?-~?wzwzzv^+>?v?v|-~~>^^+vrr?>^```````````````.<br></br>
.|~+^>ruuxxxxurv}v?|~~```..|v...-??v??vvv>```.`.~+|~|+|--~````-`||````````.<br></br>
.^~~^+|?wxuxxxuwr}}}?^`~`..~}...`>`+>vwwr}?`.....``~^+^`.``.-^>-~^^```````.<br></br>
.+~~>??>?rwzzwww}rvvvv?|`..-}...`?...|vxxzzr?``-.^?>-^+`....~^^--+>||-````.<br></br>
.|+++?rrvv?vrzxxzwzrvvvv?^.`r`...?.....~vxuxwvv|`?v+.``.....-~||+>>>+~~~-`.<br></br>
`??|?}rrv?vrrrzuuzzuzr}}}}?~v--|^v+>v}?>+>vwuuwr?v?.........`~^++-^+~~|~`` <br></br>
.`..`+}zwzzzw}vrzuwzxzr}}}r}}wxzwv?ruyuw?++~^}zv??v?-......`~+>>++>|-||~`` <br></br>
 .`~~~|zuuuz}v???}}rwxuuuxxxzzr>~+>+vzxw>|+` .-?vvuzxv^`.`~>>>>>>>>>>^|^-` <br></br>
 -~-~->uuxwv??>^~`..``|>vzuuxrvvzuw?+>v?>??`...~>?zzzx}?>??>>>>>>>>>>>~-`` <br></br>
 .~^+vxzr??>-`...........`+rurz}}xyz^`^+vv?^^||-``.^v}+vv>+^^~|+>>>+^^-``` <br></br>
 ~+?vw}}v?|-``..............^}wzr}}?^~vz}+.```~||^++>|^^^+>?v?-~+>^~``.... <br></br>
 |?v+?r?||^|~~`...............?xzwrrrxwvv?>>>>+^|--`..``~wuwvw}|+>|....... <br></br>
.??vv}>||^||rw+..``............>uxxxwzr???>-.      .......+rx}?|>?^-...... <br></br>
.>?v}+||^^^~rz> .-. `?~.........?uxuxwv?}}}}v|`   ........ .|v+|??vrv^.... <br></br>
.+^r^|~^^+^`>x> `. |rzv`........`zuuzvvvv}v}}?v?~.  ........  `|^?uxzwr>`. <br></br>
.^v>``~`~-```r+ ..>zv|...........}uz??rvvvv}}?v}}v>-. ...........^ruuxwxz?.<br></br>
.|r`.>v?^`..->>+`|v^.............vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |v`.+vvvv?|????+-. .............?}-  |?wxxzw?vvvvvvv}v?~.  ....... .-?zxw|<br></br>
.+?`.  ...`-------->|~``.........?- . ...|vxuwxxzr}}vvvvv>|`.  ....... `>r~<br></br>
.+>.. .....-~---```>v}wr}+..... `-    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 -`.. .. -?w+..~>`--`.-+?`....  . ... ``     .|vzuxxxxxxzwrv}}v>-.  .....+`<br></br>
`+....  >rr+.. ~z>`--........  .. .}- ..  ..    `>wzzzzzzzzrv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^.................................```````````--~~||||>v}~<br></br>
 ``-?v|`````?zuzzzz}>`.`````..``-?`..............``````-------~~||^+>?v}r}~<br></br>
.|^v>```````.`>wuxzzzr>`.`.~+`^+???>^+`...........``````--~---~~~|^^+?v???`<br></br>
-r}+.`````````.`>zuuxzz}^..~vvvvv??v?+............``````------~~||^^^^^^^+`<br></br>
`zzwv~.````````..`>wuxxxxv~^v???v??v?^................````---~~~|||||||||^.<br></br>
`xxxuzv-...````.`-.`?xxw}zw??????????^-..............`-`.`-----~~~~~~~~~~~.<br></br>
`xxxxuuz?-..````~`...>?vvr}v?????v???>^`.........  -?v??>-----~~~~~~~~~~~-.<br></br>
`wzzwzzxxr?~---``.......^}zw?>>??>+|-........`--`.+rwrv}}^-----------~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}wv>?v?~. .......`|++^^???vwwv-----------------.<br></br>
 +ruuxxxxxxxv?>||-`````....~rzwrxxxr+.....`^>|~+|^>|+?wv||-``------```````.<br></br>
.~-^ruuxxxxxxrv}?~```-`..`.^?-|?wzwwz}^>??v??+`~-^+++>+~-~-```````````````.<br></br>
 |~+^>ruuxxxxurv}v?|~~```..|v...-??v??vvv>`.`....~~~^^`..```````~~````````.<br></br>
 ^~~^+|?wxuxxxuwr}}}?^`~`..|}...`>-+>vrwwr?-......--|>+......````~|~~--`-|.<br></br>
.+|~>??>?rwzzwww}rvvvv?|`..-}...`?...|vxxzzr?``-.^v?-||`....-^~-~^>+-|^^>+.<br></br>
.|+>+?rrvv?vwzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v^.......`^+++>>>^^>++|-.<br></br>
`v?^v}r}v?vrrrzuuzzuzr}}}}?~v--|^v+>vv?>^>vwuxwr?v?`........-^++>>++++^~`` <br></br>
.`..`>}wzxzzrvv}zuwzxzrv}}r}}wxzwvvzuyur>++~+}zvv?v?~......~+>>>>>>++|-``` <br></br>
 .-~~~~zuuuwvv???}}rwxuuuxxxzzr>|+>+vxxw?^+` .-?vvxzxv^`.->>>>>>>>>>>~-~`` <br></br>
 -~--->uuxwv??>^~`..``|>vzuuxrvvzuw?+>}?>?v`...~>?zxzuw??v?>>++^^+>>^---`` <br></br>
 `---?wrv??^-............`>rurz}}xyz^.++vv?^^||-``.^?w>?v>++|`-|^+>>-````` <br></br>
 ``.~}?vv^`.................+rwzr}}?^~vz}+.```~||^++>|^+^+>?}?--^>>|`...`` <br></br>
 ~>+`+v+`.....................?xzwrrrxwvv?>>>>+^|-``..``~ruwvrw|+>+....... <br></br>
.?vv?v~.`..`}r+ .``............>uuxxwzr???>-.      .......+rx}?|>?+-...... <br></br>
.??vr~-|`...vz> .-. `?~.........?uxuxwv?}}}}?|`   ........ .~?|^??}rv^.... <br></br>
.>>w+`-`--. +z> `. ~rz?`........`zuuzvvvv}v}}vv?~.  ........  `|^vuxzwr>`. <br></br>
`?}?`~`~~|-.`}+ ..>zv|...........}uzv?rvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
.^w-~?}v?>-.`>>+`|v^.............vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 +}`->}rrrv^????+-. .............vr~  |?wxxzr?vvvvvvv}v?~. ........  -?zxw|<br></br>
.?v``.`--`-|------->|~``.........?| . `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.>>`-.```  -~---```?vrrwr?```-` `|.   ``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-`.- .. -?w+..|>..-~|+?v>^||-`..```. -.     .|vzuxxxxxxzwrv}}v>-. .... .+`<br></br>
`^....  >rr+.. ~z^..`|-~^+^~-``..``}- `. ...    `>wzzzzzzzzwv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^..................................``````````--~~||||>v}~<br></br>
 ``-?v|`````?zuzzzz}>`.`````..```-...............```````------~~||^++??}r}~<br></br>
.~^v>```````.`>wuxzzzr>`.`.-~.~+>^++~`............``````--~~--~~~|^^+?v???`<br></br>
-r}+.`````````.`>zuuxzz}^..-|+?vv?vv?>`...........``````------~~||^^^^+^^+`<br></br>
`zzwv-.````````..`>wxxxxxv|`|?????>???~...............```--~~-~~|||||||||^`<br></br>
`xxxuz?-...````.`-..?xxr}zw????????v??+-............``--`.`----~~~~~~~~~~~.<br></br>
`xxxxuuz?-..````~`...>?vv}rv????vvv??+-`..........|?}rv>-``---~~~~~~~~~~~-.<br></br>
`wzzwzzxxr?----``.......^}zw?>???>>^-........`-`..?wwww?+|-----------~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}r?>>v?|. .......`^++^|v}v}wz?-----------------.<br></br>
.>ruuxxxxxxxv?>||-`````....~rzwrxxxr+.....-+>|~+^|>++?w}|`---`-----`````--.<br></br>
.~-+wuuxxxxxxrv}?~```-`..`.^?-|?wwwwzv^>??v??^`~->+^+??>-```````--````````.<br></br>
 |~+^>ruuxxxxurv}v?|~~-``..|v...-??v??vvv>`.`...`~~~|^-..`````-```````````.<br></br>
 ^~~^+|?wuuxxxuwr}}}?^`~`..~}...`>`+>vwww}?-.....`--|++.....`|>-``-~~--``~.<br></br>
.+|~+??>?rwzzwww}rv?vv?|`..-}...`?...|vxxzzr?``-.+??-||`....|>+|-+>+~~~|+^.<br></br>
.|+>>?rrvv?vwzxxzwz}vvvv?^.`r`...>.... ~vxuxwvv|`vv|.......`|+>>>>+|~|||~-.<br></br>
`v?^vrr}vvvrrrzuuzzuzr}}}}?~v--|^v+>vv?>^>vwuuwr?v?.........-^>>+-~+^~--`` <br></br>
.`..`>}zzxxwrvv}zuwzxzrv}}w}}wxzwvvzuyur>++~+}zvv?v?~......~+>>>^+>>^`.``` <br></br>
 .-~-``wuuuwv????}}rwxuuuxxxzzr>|+>+vxxz?|+` .-?vvxzxv^`.^??>>>>>>>>>````` <br></br>
 --`..+uyxr}??>^~`..``|>vzuuxrvvzuw?++}?>?v`...~>?zxzxr?vr?>>++++>>>>+-``` <br></br>
 .`..>wrv??^-............`+rurz}ruyz+.++vv?^^||~-`.^vr+v?+^^~`-|+>>^|^~``` <br></br>
 .. -v?vv^`.................^}wwr}}?^~vz}+.```~||^++>|^+^+??}?-~+>^```..`` <br></br>
 -^|.^v>`....`................?xwwrrrxwvv?>>>>+^|-``..``~ruwvw}|>>|....... <br></br>
.??v?v~... `}r+..``............>uxxxwzr???>-.      .......+rx}>|>v+-...... <br></br>
.??v}-``.  .vz> .-. `?~.........?uxxxwv?}}}}?|`   ........ .~v^^??}rv^.... <br></br>
`>>r^`..``. +x> `. |rz?.........`zxxzvvv}}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
`?v?``.`-~-.`}+ ..>zv|. .........}uz??rvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
`>r~`?}v?+`.`>>+`|v^.............vxv~?wr}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.>}~`?rrwrv+????+-. .......`.`..`vw~ .^?wxzzr?vvvvvvvvv?~. ........ .-?zxw|<br></br>
.?v-`-|^|||^------->^|~~`--~--.``?^...-..|vxuwxxzw}}vvvvv>|`.  ....... .>r~<br></br>
.?>~~.--~..-~---```?vrwwr?||-|``~|--.`-.   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.-``| ...-?w+..|>...`~>?v?>+|~`~`~~^``-.     .|vzuxxxxxxzw}vv}v>-. ......+`<br></br>
`|..`.  >rr+.. ~z| ..```~>+|~.-`.-~r~.`. ...    `>wzzzzzzzzrv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^..................................``````````---~||||+?}~<br></br>
 ``-?v|`````?zuzzzz}>`.```````..`-...............```````------~~~|^++>?vrr~<br></br>
.~^v>```````.`>wuxzzzr>`..`.--`>?^+>^-............``````--~~--~~~|^^+?vv??`<br></br>
-r}+.`````````.`>zuuxzz}^...|^?vvv???|`............`````------~~||^^^^++^+`<br></br>
`zzwv-.````````..`>wxzzxxv|`+???v?>???~.............```````---~~|||||||||^`<br></br>
`xxxuz?-...````.`-.`?zxwrzz???????vv??>``..........````...`---~~~~~~~~~|~~.<br></br>
`xxxxuuz?-..````~`...>?vvrrv??????v??^`...........~^^-. ..`---~~~~~~~~~~~-.<br></br>
`wzzwzzxxr>----``.......^}zw>>????>>^`.......```.~vvv?+~.`-----------~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}r?>>??|. .......`^++~?vv}zzr?-`------------~--.<br></br>
.+ruuxxxxxxxv?>||-`````....~wxwrzzx}+.....`^+~|+|^>>>}ww}?~.``-----````---.<br></br>
.~`+wuuxxxxxxrvv?~```-`..`.^?-|?wzwzxv^+>?v?v^-|~+^++?rrv?+.``````````````.<br></br>
 |~+^>ruuxxxxurv}v?|~~```..|v...-??vv?vvv>```.`.~+^~|+^||~`````-^^-```````.<br></br>
 ^~~^>|?ruuxxxuwr}}}?^`~`..~}...`>`+>vwwr}?`.....``~++^`.``.`|^-~++```````.<br></br>
.^|~+???vrrzzwww}rv?vv?|`..-}...`?...|vxxzwr?``-.^?>-^^`.`..-||-~>>^|`````.<br></br>
.^>>>?rwv??vwzxxzwz}vvvv?^.`r`...>.... ~vxuxwvv|`?v+.``.....`~~|+>>>>|~~-`.<br></br>
`v?^vrrrvvvwrrzuuzzuzr}}}}?~v--|^v+>vv??^>vwuxwr?v?`........`-^++~^+~|^-`` <br></br>
.`..`>}zzxxw}vv}wuwzxzr}}}w}}wxzwvvwuyuw>++~+}zv??v?~......`~+>>++>~-|^~`` <br></br>
 .-~-..zuuuwv????}}rwxuuuxxxzzr>|+>^?xxz?^+` .-?vvuzxv^`.`~+>>>>>>>>>^^+~` <br></br>
 ``.  ^xyxr}??>^~`..``|>vzuuxrvvzuw?++}v+?v`...~>?zxzxr?>??>>>>>>>>>>>~-`` <br></br>
 ....>wrv??^-............`>rurz}ruyz+.++vv?^^||~-`.^v}+vv>+^^~|^>>>+^^~``` <br></br>
 .  -v??v^`.................^}wwr}}?^~vz}+.```~||^++>|^+^+>?v?~~+>+~```.`` <br></br>
 -^~.^v>`....`................?zzwrrrxwvv?>>>>+^|-``..``|wuwvwr|+>^`...... <br></br>
.>?v?v~... `}r+ .``............?uxxxwzr???>-.      .......+rx}?|+?+-...... <br></br>
.>?vv`...  .vz> .-. `?~........`vuxxxwv?}}}}?|`   ........ .~v+|?vvrv^.... <br></br>
.^^}-.  .`. +z> `. |rzv`........|zxxzvvv}}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
`+v>...  .`..}+ ..>zv|. ...`-`.-`ruzvvrvvvv}}?v}}v>-. ...........^ruuxwxz?.<br></br>
.+r~.>v>^`..`>>+`|v^. .`.``~-~.-`vu}^?wr}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.>r|.?}rrr?|????+-. .`--.~|^-^.~^vw+.|^?wxzzr?vvvvvvvvv?|. ........ .-?zxw|<br></br>
.?v|`+~-~^|~------->^+>^`~|^^^`||v+|-|~..|vxuwxxzw}}vvvvv>|`.  ....... .>r~<br></br>
.?>^~^+||-.-~---```>vrwwr>-~||.`~^~~~~~.   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
`|.~|...`-?w+..~>.   .-+?|-~``.```-~--`.     .|vzuxxxxxxzwrvv}v>-. ......+`<br></br>
`|..~.  >rr+.. ~z| ...  .```.....`-r~.`. ...    `>wzzzzzzzzrv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^..................................``````````---~~|||^?}~<br></br>
.-`-?v|`````?zuzzzz}>`.`````...`->`...............``````------~~~|^++>??}w|<br></br>
.~^v>```````.`>wuxzzzr>`.`.`^-~>v??>>>`............``````--~---~~||^+?vvv?`<br></br>
-r}+.`````````.`>zuuxzz}^..`vv?vv????^............```````--~--~~~|^^^+++^+`<br></br>
`zzwv-.````````..`>wxxxxxv|~v???v????+..`.............`````--~~~||||^|^^^+`<br></br>
`xxxuz?-...````.``..?xxr}wr???????vv?+^``............``...`----~~~~~~~||||.<br></br>
`xxxxuuz?-..````~`...>vvvr}v?????v???>-........... `--``~~----~~~~~~~~~~~-.<br></br>
`wzzwzzxxr>----``.......^}zw?>???>>^|`.......`````>vv?>++~-----~-----~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}wv>>v?|. .......`|++|?v??}wrv~`------------~--.<br></br>
.+rxuxxxxxxxv?>||-``````...-rxwrxxxr+.....`^+||+|^+^+vwrv?~.``-------``---.<br></br>
.|-+wuuxxxxxxrvv?~```-`..`.^?-|?wzwwzv^>>?v?v>`|-^>>>????v+```````````````.<br></br>
 |~^^>ruuxxxxurv}v?|~~-``..|v...-??v?vvvv>``-.`.`|^~^|-`~~`````-^^````````.<br></br>
.^~~^>|?wuuxxxuwr}}}?^`~`..~}...`>->>vrwrr?`......`~^++`....````|>>~~--``~.<br></br>
.^|~+???vrwzzwww}rvvvv?~`..-}...`?...|vxxzwr?``-.|??-^+`....`~--|+>^~~||^^.<br></br>
.^>>>?rrv??vwzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v>```....`-~~|+>>?>+^^|-.<br></br>
`v?^vrr}vvvwrrzuuzzuzr}}}}?~v--|^?^?vv??^>?wuuwr?v?`........`-|^>>>+|++~`` <br></br>
.`..`>rzzxxw}vv}wuwzxzr}}}r}}wxzwv?ruyxw>++~+}z???v?~......-|^>>>>+~|^^~`` <br></br>
 .--`..zuuuwv????}}rwxuuuxxxzzr>|+>^vzxw?^+` .-?vvxzxv^`.`|+>>>>>>+>>^^+~` <br></br>
 `... ^xyxr}??>^~`..``|>vzuuxrvvzuw?++}v+>?`...~??zxzuw?>??>>>+++>>>+~--`` <br></br>
 ....>wrv??^-............`>wurz}ruyz+.+>vv?^^||~-`.^vr>vv>+^|~~^^+>>~````` <br></br>
    -v??v^`................`>rwzr}}?^~vz}>.```~||^++>|^+^+>?v?--|>>^`.```` <br></br>
 -^~`^v>`....`...............-vzzwrrrxwvv?>>>>+^|-``..``~ruwvwz^^>>`...... <br></br>
.>?v?v~... `}r+ .``.....``...`~vuxxxwzr???>-.      .......+rx}?|+?+-...... <br></br>
.>>vv`...  .vz> .-. `?~..``-`-`|ruxxxwv?}}}}?|`   ........ .~v^|>?vrv^.... <br></br>
.|^}`.  .`. +z> `. |rzv`.``~|`~~?xxxzvvv}}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
.~v>. .  .`..}+ ..>zv|...`-~+~->|wux}vrvvvv}}?v}}v>-. ...........+ruuxwxz?.<br></br>
 ~r` >v>~`..`>>+`|}+``~|--^>|+-^|vur+vwr}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |}- +vv}v?~????+-..`~||`~^+|^`-|vw|`-^?wxzzr?vvvvvvvvv?|. ........ .-?zxw|<br></br>
.?v|-`.-~``-------->^^~-..`-~~```v^.`--..|vxuwxxzw}}vvvvv>|`.  ....... .>r~<br></br>
.>>+-||~+~`~----```>?}wr}+..``..~|`..`-`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.|`|~~``~^?w+..~>.   .-+?`....  ......`.     .|vzuxxxxxxzwrvv}v>-. ......+`<br></br>
`|`.+`..?wr+.. ~z| ...   ....   ..`}-... ...    `>wzzzzzzzzrv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^..................................``````````--~~~|||^?}~<br></br>
.-`-?v|`````?zuzzzz}>`.``````.```~................``````------~~~|^++>>?}w|<br></br>
.|^v>```````.`>wuxzzzr>`...-~.-+>^++~`.............``````--~---~~||^+?v}v?`<br></br>
-w}>.`````````.`>zuuxzz}^..~^+?vv?vv?>`...........```````--~--~~~|^^^+>>^+`<br></br>
`zzw?-.````````..`>wxzzxxv|`^?????>???-...............```----~~~||||^^^^^+`<br></br>
`xxxuz?-...````.``..?xxw}zw????????v??+-.............`--`.`----~~~~~~~||||.<br></br>
`xxxxuuz?-..`-``~`...>?vv}}v????vvv??+-`..........|?}rv>-`----~~~~~~~~~~~-.<br></br>
`wzzwzzxxr>-`---`.......^}zw?>???>+^-........`-`..?wwww?+|-----~-----~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}w?>>v?|. .......`^++^|?}v}wz?`------------~~--.<br></br>
.>ruuxxxxxxxv?>~|~``````...~rxwrxxxr+.....-+>|~+^^>++?w}|`----------------.<br></br>
.|->wuuxxxxxxrvv?~```-`..`.^?-|?wzwwzv^>??v??^`~->+^^??>-``````----```````.<br></br>
 ||^^>wuuxxxxurv}v?|~~-``..|v...-??v??vvv>`.`...`|~~|^-..`````-```````````.<br></br>
.^|~^>^?wuuxxxuwr}}}?^`~`..~}...`>`+>vrwwr?-.....`-~|++.....`|>-``-|~--``~.<br></br>
.^|~+?v?vrwzzwww}rvvvv?~`..-}...`?...|vxxzzr?``-.+v?-||`....|>+|-+>+~~||^^.<br></br>
.+>>>?rwv??vwzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|-vv^.......`|+>>>>+|~|||~-.<br></br>
`v?^vwrr}vvwrrzuuzzuzr}}}}?~v--|^?^>v}v+^>?wuuwr?v?.........-+>>+-~++~--`` <br></br>
.`..`>rzzuxw}vv}wuwzxzrv}}r}}wxzwv?wuyx}?++~+}zvv?v?~......~+>>>+^>>^`.``` <br></br>
 .--`..zuuuwv????}}rwxuuuxxxzzr>|+>+vzxw?^+` .-?vvxzxv^`.|??>>>>>>>>>````` <br></br>
 `... ^xyxr}??>^-...`-^?}zuuxwvvzuw?++}v+>?`...~??zxzxr?v}?>>++++>>>++```` <br></br>
 ....>wrv??^-..........``|?wxrz}ruyz+.+>vv?^^||~-`.^vr+vv+^+~`-|+>>^|^~``` <br></br>
    -v??v^`.........````---^?rwzw}}?^~vz}>.```~||^++>|^+^+??}?~~+>^-`````` <br></br>
 -^~`|v>`....`.......`~|~--~~+}xxzrrrxwvv?>>>>+^|-``..``~ruwvw}|>>|....... <br></br>
.>?v?v~... `}r+  ....`-~^++^^|>}uxxxwzr???>-.      .......+rx}>|>v+-...... <br></br>
.>>?v`...  .vz> .-. `?+~+^>?+>++}uxxxwv?}}}}?|`   ........ .~v^^??}rv^.... <br></br>
.|^}`.  .`. +z> `. |rzv~~+|+>|>+?xxxxvvv}}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
.~v>. .  .`..}+ ..>zr>^||+^`+|-^|wuxvvrvvvv}}?v}}v+-. ...........+ruuxwxz?.<br></br>
 ~r` >v>~`..`>>^`|r??^++~-^-`|---vu}|?wr}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
                  |v. +vvvv?~????+```--`-`.````-..vr~ .^?wxxzr?vvvvvvvvv?|. ........ .-?zxw|<br></br>
.??`.  ...`-------->^~-`......`..?~  .-`.|vxuwxxzw}}vvvvv>|`.  ....... .>r~<br></br>
.++-..`-|..~~---```>?}wr}+..... `~   .``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.|`-~``-^>vw+..~>.   .-+?`....  . .`..``     .~vzuxxxxxxzw}vv}v>-. ......+`<br></br>
.~-.^~`-?zz>.. ~z| ...   ....     .}- `. ...    `>wzzzzzzzzrv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.~?^..-?vvvv}v^..................................``````````--~~~|||^?}~<br></br>
.-`-?v~`````?zuzzzz}>`.````````.`.................``````------~~~||^++>?}z|<br></br>
.|^v>```````.`>wuxzzzr>`..`.```>+~^>|..............``````------~~||^+?v}}v`<br></br>
-w}>``````````.`>zuuxzz}^..``-?vvv???+-............``````--~--~~~||^^+>>++`<br></br>
`zzwv~.````````..`>wxzzxxv|`~?????>???|..............`````---~~~|||^^^^^^+`<br></br>
`xxxuzv~...````.``..?xxw}zw???????vv???~...........`-```..`-~-~~~~~~~~||||.<br></br>
`xxxxuuz?-..`-``~`..`>?vv}}?????vvvv?^````........?vv?~  .----~~~~~~~~~~~-.<br></br>
`wzzwzzxxr?-`---`.......^}zw???????>^`.......``` -}wrrv^.`-----~-----~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}r?>>??^. .......-^++|^v}rwwz^`------------~~--.<br></br>
.+}xuxxxxxxx}?>||~``````...~rxwrzxxr+.....-+>~|+^|>?>vww?-```-------------.<br></br>
.|`>wxuxxxxxxrvv?~```--`.`.^?~|?wzwwzv^>??v??|`~~>^^+?wr^--````----```````.<br></br>
 ~|^^>ruuxxxxurv}v?^|~-``..|v...-??v??vvv>```...~^^~^>^-``````-`~~-```````.<br></br>
.^|~^>^>ruuxxxuwr}}}?^`~`..~}...`>`+>vwww}>`.....`-~^+^`````-^?-`~|-``````.<br></br>
.^|~+?v?vrwzzwww}rv?vv?~`..-}...`?...|vxxzzr?``-.^?>-^^`.```|++~-+>^|`````.<br></br>
.+>>??rwv??vwzxxzwzrvvvv?^.`r`...>.... ~vxuxwvv|`?v^.``....`~|++>>>+^~-``` <br></br>
`v?+vwrr}vvwrrzuuzzuzr}}}}?~v--|^?^>}v?+|>?wuxwr?v?.........`|+>+-~>~---`` <br></br>
.`..`>rxzuuz}vv}wuwzxzrv}}r}}wxzwvvwuyx}?++~+}xv??v?~......`|>>>^^>+-`--`` <br></br>
 .-``..zuuuwv????}}rzxuuuxxxzzr>|+>+vzxwv^+` .-?vvxzxv^..~+?>>>>>>>>>~-~`` <br></br>
 .... ^xyxw}??>^-````|?}wxuuxwvvzuw?++}v+??`...~??zzzx}??v?>>>>>>>>>>?~``` <br></br>
 .. .>wrv??^-.....````~^>?vwuwzrrxyz+.+>vv?^^||~-`.^v}+v?>^^|-|^>>>+^>~``` <br></br>
    -v??v^....`````~^^+>>>+>?wwzw}v?^~vz}+.```~||^++>|^+^+??}?-~+>^-`````` <br></br>
 `^~`^v+.....```````-^+>>+++^+}xxzrrrxwvv?>>>>+^|-``..``~wuwvr}|>>~....... <br></br>
.+?v?v~... `vr+..```-^+++>>^^^^vuxxuwzr???>-.      .......+rx}?|>?^-...... <br></br>
.+>?v`.... .vz>..~..`??++^>>~||~vuxxxwv?}}}}?|`   ........ .|v+|?vvrv^.... <br></br>
.|+}`.  .`. +z> ``.+rzv||^`|~.`-+xxxzvvv}}v}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.~v>. .  .`..}+ `.>zr>~~--`.-..`-ruxv?rvvvv}}?v}}v+-. ...........+ruuzwxz?.<br></br>
 ~r` >v>~`..`>>+-|}?^```.........vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 |v. +vvvv?~????+``..............v}-  |?wxxzw?vvvvvvvvv?|. ........ .-?zzw|<br></br>
.??.   ...`-------->|~``.........?-   `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.^+.. .`-..-----```>v}wr}+..... `-    ``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
.``.`...`^vw>..|>.   .-+?`....  . .`..`.     .~vzuxxxxxxzwrvv}v>-. .... .+`<br></br>
.`..~~..>wz?~. ~z| ...   ....     .}- .. ...    `>wzzzzzzzzwv}}vv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.|?^..-?vvvv}v^..................................```````````--~~||||+v~<br></br>
.-`-?v~`````?zuzzzz}>`.`````...`->`...............```````------~~||^+++>vw|<br></br>
.^^v>```````.`>wuxzzzr>`...`+-~>v??>+>`............``````------~~||^+>v}}v-<br></br>
-w}>``````````.`>zuuxzz}^..~vv?vv??vv+.............``````--~---~~||^^+>>++`<br></br>
`zzw?-.````````..`>wuxxxxv~|v???v??v?^`...............`````---~~~||^^^^^^^`<br></br>
                  `xxxuz?~...````.``..?xxwrww????????v?+~..............``...`-~-~~~||~~|||||.<br></br>
`xxxxuuz?-..`-``~`..`>vvv}}v?????v???>~..........  `^+||^------~~~~~~~~~~~.<br></br>
`wzzwzzxxr>-----`.......^}zw?>>??>+|`........````.>v}v???|-----~-----~~~~-.<br></br>
`xuxxxzzzzrv^``````.......|}wv??v?~. .......`|++^+v??}wwv~------------~~--.<br></br>
.>}xuxxxxxxxv?>||~``````...~rxwrxxxr+.....`^+|~+|^+^+vw}?>~.`-------------.<br></br>
.|->zxuxxxxxxrvv?~```--`.`.^?~|?wwwwzv^>?????+`~-^>>+?>>??^```--`--```````.<br></br>
 ~|||?wuuxxxxurv}v?^|~-``..^v...-??vvvvvv>`.`...`|~~^^`.--`````-|^-``````` <br></br>
.^|-^?^?wuuxxxuwr}}}?^`--..~}...`>`>>vrwr}>`......`-|++.....````~++~~--``~.<br></br>
.^||^?v?v}wzzwww}rv?vv?~`..-}...`?...|vxxzzr?``-.|??-||`...`-|~-|+>^~||^^^.<br></br>
`>????rwv??}wzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v+.......`|^^^+>>+^>++|-.<br></br>
`vv+vwrr}}vwwrzuuzzuzr}}}}?~v--|^?^?}r?+^>?wuxwr?v?........`-|^+>>+^^+^~`` <br></br>
.`..`+rxzuuzw}}rzuzxxzr}}}r}}wxzwvvwuuxr?++~^}z???v?-......~^++>>>>^^^|~`` <br></br>
 .```.`zuuuzr}vvvrrwzxuuuxxxzzr>|+>^vwxw?^+` .-?vvuzxv^`.`^+>>>>>>>>>^^^-` <br></br>
 .... ^xyxwrvv??>>>????vrxuuxwvvzuw?++v?+??`...~??zxzuw????>>>++++>>+~--`` <br></br>
 .. .>wrv>?+|~-~|+++++++++?wuwz}ruyz+`^+vv?^^||~-`.^vw>vv>++|-~|^>>>~````` <br></br>
    -v??v>~|^^^+>+++^|^|^~`-?wwwr}}?^~vz}+.```~||^++>|^+^+>?}?~-^>>^`````` <br></br>
 `|~`^}>-`~~|+>>>?>>>++^|~..``?xzwrrrxwvv?>>>>+^|-``..``~ruwvwz|+>+`...... <br></br>
.+?v?v~....-rr?-~^++^||~~~`....>uxxuwzr???>-.      .......+rx}?|>?+-...... <br></br>
.++?v`...  .}z?|^+`.-?^````.....?uxxxwv?}}}}?^`   ........ .|v^^>?}rv^.... <br></br>
.~^}`.  .`. +x?-^|`^rz?`........`zxuzvvv}}v}}vv?~.  ........  `|^?uxzwr>`. <br></br>
.-v>. .  .`..}>.-`?zv|. .........}uxv?rvvvv}}?v}}v+-. ...........+ruuxwxz?.<br></br>
 ~r` >v>~`..`>>+-|v^. ...........vx}~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
 ^v. +vvvv?~????+`. .............?}-  |?wxxzw?vvvvvvvvv?|.......... .-?zxw|<br></br>
.??.   ...`-------->|~``.........?-   `..|vxuwxxzw}}vvvvv>|`.  ....... `>r~<br></br>
.|+.. .....-~---```>v}wr}+..... `-    ``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 ``.. .. -?w+``|>.   .-+?`....  . .`..``     .~vzuxxxxxxzw}vv}v>-. ......+`<br></br>
.`....  >rr>`-|>z| ...   ....     .}- `. ...    `>wzzzzzzzzrv}}vv>^. ...?^ <br></br>


            </div>,

            <div>

 ```.|?^`.-?vvvv}v^..................................```````````--~~||||+v~<br></br>
 -`-?v~`````?zuzzzz}>`.``````.```>-...............```````------~~||^+++>?w|<br></br>
.^+v>```````.`>wuxzzzr>`.`.|+`>+???+|^-............``````------~~~|^+>v}}}-<br></br>
-w}?``````````.`?zuuxzz}^..^??vvv??v?>............```````--~---~~||^^+>?>>`<br></br>
`zzzv~.````````..`>wuxxxxv|^?????>?v?+................```-----~~~||^^^^^^^.<br></br>
`xxxuxv~...````.``..?xxw}ww??????????+-`..........  .`~-``--~~-~~||~||||||.<br></br>
`xxxxuuz?~..`-``~`..`>vvv}}v????vv???>^-......... .|vrr}?-`----~~~~~~~~~~~.<br></br>
`wzwwzzxxr?~----`.......^}zwv>>??>^~`.`......`--` +wwwwww^`----~---~~~~~~-.<br></br>
`xuxxxxzzzrv^-.````.......|vwv??}v~. .......`|++^|?v??ww?`------------~~~-.<br></br>
.+vxuxxxxxux}??|^~``````...~rxwrxuxr+.....-+>|~+|^>^>>wv-`----------------.<br></br>
.|->wxuxxxxxxrv}?|```--`.`.^?-~?wzwwz}^>?????+`~`>>+>>^````````----```````.<br></br>
 ~|||>rxuxxxxurv}v?^||-``..^v...-??vvvvvv>`.`....~~~^^` .````````````````` <br></br>
.+|-^?^>ruuxxxuwr}}}?^`--..~}...`>`+>vrwwr?`......-~|++.....``-```-|^~~`-^`<br></br>
.^||^?vvv}wzzwww}rvvvv?~`..-}...`?...|vxxzzr?``-.^v?-~~`....~>^~~^?>-^^+?>.<br></br>
`>????rwv??}wzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v^.......`^>>>>>>~~+^+^~.<br></br>
`vv^vzrrr}}wwrxuuzzuzr}}}}?~v--|^?+?}wv+^>vwuxwr?v?........`~^>>>+|^>^~-`` <br></br>
.`..`>wxxuuxwrrwzuzxxxrv}}w}}wxzwvvwuuxr?++-+}zvv?v?~......|>>>>>>>>>-```` <br></br>
 `-```-zuuuxwrvvvrrwwxuuuxxxzzr>|+>+vwzw?^+` .-?vvuzxv^`.^v?>>>>>>>>>````` <br></br>
 .... ^xuxww}vvv????>>>?vzuuxwvvzuw?++v?+??`...~??zzzuz??r?>>+^^^+>>^-```` <br></br>
 .. .>wrv??>+^^^^++|~---``>ruwz}}uuz+`^+vv?^^||~-`.^?w>?v>++~``~^+>+--````.<br></br>
    -v?vv>|^^^++^^|-`.......+rwzr}}?^~vz}+.```~||^++>^^+^+??}?~-^>>~`````` <br></br>
 `||`^v>~~^+++++^++^~--.......?zzwrrrxwvv?>>>>+^|-``..``~ruwvww|+>+....... <br></br>
.+?vvv~...`~rr?~~^^`...........>uxxuwzr???>-.     ........+rx}>|>v+-...... <br></br>
.++?v`...  .}z?|~^. `>~.........?uxxxwv?}}}}v^`   ........ .|v|^??rrv^.... <br></br>
.|+r`.  .`. >x?~^` ~rz?.........`zxuzvvv}}vv}vv?~.  ........ .`||vuxzwr>`. <br></br>
.-v>. .  .`..}?`-.+wv|. .........}uxv?rvvvv}}?v}}v+-. ...........+ruuzwxz?.<br></br>
 ~r` >v>~`..`>?+`~v^. ...........vx}~>ww}vvvv?vvv}}v?^`.......... .|vxuwwu|<br></br>
 ^v. +vvvv?~????+-. .............?}-  |?wxxzwvvvvvvvvvv?|.. ....... .-?zzw|<br></br>
.??.   ...`-------->|~``.........?-   `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.~+.. .....-----```>?}wr}+..... `-    ``   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 ``.. .. -?r+.`|>.   .-+?`....  . .`..``     .~vzuxxxuxxzwr?v}v>-. .... .+`<br></br>
.`....  >rr+.`+vz| ...   ....     .}- `. ...    `>wzzzzzzzzwv}}}v>^. . .?^ <br></br>


            </div>,

            <div>

 ```.~?^`.-?vvvv}v^..................................```````````--~~~|||+?~<br></br>
 -`-?v~`````?zuzzzz}>`.```````````................```````------~~||^++++?w|<br></br>
.|+v>```````.`>wuxzzzr>`..`.```>>^+>|`.............``````------~~~|^+>?v}}-<br></br>
-w}?-`````````.`>wuuxzz}^..`~|?vvv???+`............``````--~---~~||^^+>??>`<br></br>
`zzw?~.````````..`>wxzzxxv|`|?????>???~..............``````---~~~||^^^^^^^.<br></br>
`xxxuz?-...````.``..?xxwrww???????vv??>~...........````...`-~~-~~|||||||||.<br></br>
`xxxxuuz?-..`-``--..`>?v}}r??????vvv?^`...........+??>`  .--~--~~~~~~~~~~~.<br></br>
`wzwwzzxx}>--~--`.......^}zw?>?????>^`.......``` `v}}}?^.`-----~~-~~~~~~~-.<br></br>
`xuxxxzzzzrv^`.`````..`...|vrv>>??^. .......-^++|^v}rwww+``-----------~~~-.<br></br>
.+}uuxxxxxxxv?>||~``````...~wxwrzxx}+.....-++~|+^|>?>}ww?^-`--------------.<br></br>
.^~?zuuxxxxxxrvv?~```--`.`.^?-|?wzwwzv^>>?v?v^`~~>^^+vrr>^|````----```````.<br></br>
 ~|~|?wuuxxxxurv}v?|||-```.|v...-??v?vvvv>```...|^^~|+^-``````-`~|-```````.<br></br>
.+^-^?+?wuuxxxuwr}}}?^`--..~}...`>`+>vwwr}>`.....`-~^+^``````^>--|^-``````.<br></br>
.^|^^?vvv}wzzwww}rvvvv?~...-}`..`?...|vxxzzr?``-.^?>-^^`.`..-^^~-+>^|-````.<br></br>
`????v}wv??}wzxxzwzrvvvv?^.`r`...>.....~vxuxwvv|`?v+.``....`-|^^>>>++~~-`` <br></br>
`vv|?zwr}}}wwwxuuzzuzr}}}}?~v--|^?+>vv?>^>vwuxwr?v?.........`|^++-|>~~~-`` <br></br>
.`..`>wuxuuxr}}}wuwzxz}v}}r}}wxzwv?wuuuw?++~+}zv??v?~......`|+>>^^>+-~~-`` <br></br>
 .-~~~~zyuxzrv??>}}rwxuuuxxxzzr>|+>+vzxw?^+` .-?vvuzxv^..-^>>>>>>>>>>~~|`` <br></br>
 ```..>xuxwrv?>^~...``~>vzuuxrvvzuw?++??>??`...~??wxzx}????>>>>>>>>>>?~``` <br></br>
 ....?zwr}v?^`...........`+}urwv}uuz+.^+vv?^^||~-`.^v}+v?>+^|~|^>>>+^+~```.<br></br>
 ...~vv}r?^|~~~-``..........^}wwr}}?^~vz}+.``-~||^++>|^+^>>?v?~~+>+~`````` <br></br>
 `++->r?^^^^^^~`..............?xzwrrrxwvv?>>>>+^|--`..``~wuzvr}|>>|....... <br></br>
.+vvvv^``||^rw>..`.............>uxxuwzr???>-.     ........+}x}?|>?^-...... <br></br>
.++?v-`.-~|^wz> .-. `?|.........?uxxxwv?}}}}?|`   ........ .~v+|?vvrv^.... <br></br>
.|^r-...`~--?x> `. |rzv`........`zxuzvvv}}v}}vv?~.  ........  `|^?xxzwr>`. <br></br>
.-v>.... .`.`}+ ..>zv|. .........}uz??rvvvv}}?v}}v+-. ...........^ruuzwxz?.<br></br>
 ~r` >v>~`..`>>^`|v^. ...........vxv~>ww}vvvv?vvv}}v?^`.......... .|vxuwwu|<br></br>
.^v. +vvvv?~????+`. .............?}-  ~?wxzzr?vvvvvvvvv?~.......... .`?zxw|<br></br>
`??.   ...`-------->|~``.........?-   ...|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
.-+.. .....-~---```>?}wr}+..... `-    ``   `>}uuuxxzzwr}vv?v?|.  ....... ..<br></br>
 ``.. .. -?w+..~>`.  .-+?`....  . .`..``     .~vzuuxxxxxzw}?v}v>-. ......+`<br></br>
.`....  >rr+.. ~x?....   ....     .}- `. ...    `>wzzzzzzzzrv}vvv>^. ...?^ <br></br>


            </div>,

            <div>

 ```.|?^`.-?vvv}}v^..................................```````````--~~~|~|^?-<br></br>
 -`-?v~`````?zuzzzz}>`.`````....-+................```````------~~|||++^+?r|<br></br>
.^+}>```````.`>wuxzzzr>`.``.|^`>v?????`............``````------~~~|^++?v}r~<br></br>
-r}?~`````````.`?zuuxzz}^..`?vvvv????~.............``````--~~--~~||^^+????`<br></br>
`zzwv~.````````..`>wxxzxxv||v???v????^`.``...........````.``--~~~||^^^^^^^.<br></br>
`xxxxzv~.....``..`..vzzr}ww?v?????vv?>>```...........``. .`----~~|||||||||.<br></br>
`xxxxuuz?~..`-``--...+?v}}}v???>?????>`...............  ``--~--~~~~~~~~~~~.<br></br>
`wzwwzzxxr?~`---`.......^vzw?>????>>^~.......````~?vv?^--------~~--~~~~~~-.<br></br>
`xuxxxzzzzrv^-.`````.``...|}wv?>?v^. .......`^++|vv>?ww}?~`-----------~~~-.<br></br>
.+}xuxxxxxxxv??|||``````...~rxwrxxx}+.....`|^|^+|+^>+rwzwr|..-------------.<br></br>
.^->rxuxxxxxxrvv?|```--```.^?~|?wzwzxv^+>>vvv+-||^>?>v}rwz?.``--``-```````.<br></br>
 ~|~~>rxuxxxxurv}v?^||-```.|v...-??vv?vvv?-`-``.~+^|^^||+^`````~>+-```````.<br></br>
.+^-|?+>wuuxxxuwr}}}?^`--..|}...`>->>vrwwr?`......`~^+^`.``````-+??```````.<br></br>
.^|^^>}vv}wwzwww}rvvvv?~...-}...`?...~vxxzzr?-`-.|?>-^+-````````~>>~~~-```.<br></br>
`???vv}wv??vwzxxzwzrvvvv?^.`r`...>.... ~vxuzwv?|`?v?```...``````^>>??+^^-`.<br></br>
`vv+?zwr}}}wwrzuuzzuzr}}}}?~v-`|+v^>vr?>+>vruxrr?v?`.........`-^>>>+~>+~``.<br></br>
.-...>wuxuuxw}v}wuwzxzrv}}r}}wxzwv?ruyuw?++~^}z?v???~.......`~+>>>+`~>>|``.<br></br>
 .-~--~zyuxz}v??>v}rwxuuuxxxzz}>~+>+vzxr>^+` .-?vvxzxv^`..`|>>>>>>++>>>?|` <br></br>
 -~~~~?xuxwrv?+^-...`-|>vzuuxrvvzuw?+>v?^??`...|??wzzuw?^???>>>>>>>>>|~~`` <br></br>
 `^^^vxz}??>`............`>ruwz}}xuw^`^+vv?|^||--`.^v}+vv?++^|^+>>>>~````` <br></br>
.||>vzr}v?^~--`.............+rwwr}}?^~vz}>.```~||^+++|^+++>?}?-`^>>+`````` <br></br>
.~?}?vw?^^++^^~...............?zwwrrrxwvv?>>>>+^|-``..``|wuwvrx+|>>-...``` <br></br>
.>vvvv?>^++>rw+  .`............>uxxuwzr???>-.     ...... .+rx}v^^>+-...... <br></br>
.^+vr+^^>>>>rz> .-. `>~.........?uxxxwv?}}}}?^`   ........ .|v>|>?vrv^.... <br></br>
.~+w^|^>+?>->z> `. ~rz?`........`zxxzvvv}vvv}?v?~.  ........  `|^?uxzwr>`. <br></br>
.-v>``~.`~-`~}+ `.+wv|. .........}xzv?}vvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
 -r` >v+~`..->>+`~v^.............vxv~>ww}vvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.>v. +vvvv?~????+-. .............v}~  ~?wxxzw?vvvvvvvvv?|.......... .-?zzw|<br></br>
`??.   ...``------->|~`..........?-   ...|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.`>.. ...  ------.`>v}rr}+..... `-    .`   `>}uuuxxxzwr}vv?v?|.  ....... ..<br></br>
 ``.. .. -?r+..~>~~. .-+?-....  . ... `.     .~vzuuxxxxxzwr?v}v>-.  ... .+`<br></br>
.`....  >rr+.. ~z?~`...  ....  .  .}- ..        `>wzzzzzzzzwv}}vv>^. . .?^ <br></br>


            </div>,

            <div>

 ```.|?^`.-?vvv}}v^..................................```````````--~~~|~|^?-<br></br>
 -`-?v~`````?zuzzzz}>`.``````.```>`...............```````------~~~||^+^^>r|<br></br>
.|+}?```````.`>wuxzzzr>`.`.~+`>+???+|^-............``````-------~~||^+?v}r~<br></br>
-r}?-`````````.`>wuuxzz}^..^???vv??v?>.............``````--~~--~~~|^^+????`<br></br>
`zzw?~.````````..`>wuxxxxv|^?????>?v?+................```-----~~~||^^^^^^^.<br></br>
`xxxxz?-`....``..``.?xzr}ww??????????>-`..........  .`~-``--~~~~~~||||||||.<br></br>
`xxxxuuz?-..`-```-..`>?vv}}}????vv???>^-......... .^vrrv?``-~--~~~~~~~~~~~.<br></br>
`wzwwzzxx}>-`---`.......^}zwv>>?>>^|`.`......`--` +wwwwr}^`----~~--~~~~~~-.<br></br>
`xuxxxzzzzrv|`.`````.``...|}wv??v?~. .......`|++^|vv??ww?`------------~~~-.<br></br>
.?ruuxxxxxxx}?>|||``````...-rxwrxuxr+.....-+>|~+^^>^>>wv-``---------------.<br></br>
.^~?wuuxxxxxxrvv?|```--```.^?-~?wwwwz}^>??v??+`~`>>+>>^--`````-----```````.<br></br>
 ~|~~?wuxxxxxurv}v?|||-```.|v...-??vvvvvv+`.`....|~~^^`..`````-``-````````.<br></br>
.+^-|?>?zuuxxxuwr}}}?^`--..~}...`>`+>vrwwr?`......-~~++.....`-~```-|^|~`-^`<br></br>
.^|^^>}vv}wzzwww}rvvvv?~...-}`..`?...|vxxzzr?-`-.^v?-~~`....~>^~-^?>~^++>>.<br></br>
`?v?vv}wv??}wzxxzwzrvvvv?^.`r`...>.... ~vxuxwvv|`?v^.......`^>>>>>>~~+^+^~.<br></br>
`vv^?zwrr}}wwwxuuzzuzr}}}}?~v-`|^?+>vv??^>vwuxwr?v?.........~^>>>+|^>^~-`` <br></br>
.` ..>wuxuuxrvv}wuwzxz}v}}r}}wxzwvvwuyxr?++|+}z?v?v?-......|>>>>>>>>>-```` <br></br>
 .`||~~zyuxwvv?>>}}}wxuuuxxxzzr>|+>+vzzw?^+- .-?vvuzxv^..^v?>>>>>>>>>````` <br></br>
 -|~-->xuxwrv?>^-...``~>vzuuxrvvzuw?+>}????`...~??wxzuw??r?>>+^^^+>>^~```` <br></br>
 `~^+?r}v??^-............`+}xwzv}xuz+`++vv?^^||--`.^vw>?v>+^~``~^+>+-~-``` <br></br>
 -++>v}vv+`.................|}wwrv}?^~vz}>.``-~||^++>^^+^+??}?-~^>>~`````` <br></br>
 ^v?|>r?~``..`................?xwwrrrxwvv?>>>>+^~-``..``~ruzvwr|+>+.....`` <br></br>
.vr}v}+`--.`}r+  ..............>uxxxwzr???>-.     ........+rx}>|>v+-...... <br></br>
.?v?r|-~--`.}z> .-. `?|.........?uxxxwv?}}}}?|`   ........ .~?|^??rrv^.... <br></br>
.>^r|`~-~^` +z+ `. ~rzv`........`zxxzvvv}vv}}?v?~.  ........  `||vuxzwr>`. <br></br>
.`v>`--~^~-`.}+ ..>zv|. .........}xzv?}vvvv}}?v}}v+-. ...........+ruuzwxz?.<br></br>
 ~r-->}v?-``-+>^`|v^. ...........vxv~>ww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
.>v|`>v}}}?+????+`. .............v}-  ~?wxzzr?vvvvvvvvv?~............`?zzw|<br></br>
`??-. ....`-------->|~`..........?-   ...|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
 `>.. ...  -~----``?v}rr}+..... `-    .`   `>}uuuxxzwwr}vv?v?|.  ....... ..<br></br>
 ``.. .. -?r+..~>.->|-|??~.........`. ``     .~vzuuxxxxxzw}?}}v>-.  .....+`<br></br>
 `....  >rr+.. ~z|.~^``~~-`.. .. ..}- ..        `>wzzzzzzzzr}}vvv>^. ...?^ <br></br>


            </div>,

            <div>

 ```.^?^`.-vvvv}}v^..................................```````````--~~~|~||>`<br></br>
 ``-?v~`````?zuzzzz}>`.````````.`.................```````------~~~||^+^^>}|<br></br>
.|+}?```````.`?wuxzzzr>`.````..>+~+>~..............``````-------~~||^+>?vw~<br></br>
-r}?~`````````.`?zuuxzz}^..`-`?vvvv??>^`...........``````--~~--~~~|^^+?vv?`<br></br>
                  `zzwv|`.```````..`?wxzzxxv|.`?????+??v+..............`````----~~~||^^^^^^^.<br></br>
`xxxxxv~`....``..``.?xxr}zw????????v???+`..........--```..`-~~~~~|||||||||.<br></br>
`xxxxuuz?~..`--``-...>?v}}}?????vv}??~-```.......`vww}^. .`-~--~~~~~~~~~~~.<br></br>
`wzwwzzzxr?~`~-~`.......^}zw?>?????>^. ......``. ~}wwww>.`-----~~--~~~~~~-.<br></br>
`xuxxxzzzzrv^-.`````.``...|}r?>+?v+. .......~+++||vwwwwz^``-----------~~~-.<br></br>
.>}xuxxxxxxx}??|||``````...~rxw}zxxr+.....~+>~|+^|??>vww>``---------------.<br></br>
.|~>}xuxxxxxxrvv?^```--```.^?-|?wzwwzv^>?????~--~?||+?wr|``````----```````.<br></br>
 ~|~->rxuxxxxurv}v?^||-```.|v...-??vv?vvv+`.`..`+^^||+^~`````-~``-````````.<br></br>
.+^-|?>?wxuxxxuwr}}}v^`--..~}...`>`^>vwwwr?`.....`-~^+^`````~>r|``-~-`````.<br></br>
.^|^^>}v}vwwzwww}rvvvv?~...-}...`?...|vxxzzr?``-.+?>-^|`````|>?|->>^^`````.<br></br>
`?v?vv}wv??vrzxxzwzrvvvv?^.`r`...>...  ~vxuxwvv|-vv^.`.....`~+>>>>+^|`.```.<br></br>
`vv+?zwrr}}wrwzuuzzuzr}}}}?~v-`|+v+>vv?>^>vwuuw}?v?........`-^>>^`~>~`.``` <br></br>
.`...+wuxuuxrv?vwuwzxzr}}}w}}wxzwvvwuyxr+++~+}z???v?~......-+>>>+^>>-.```` <br></br>
 .`~~~~zyuxwvv??>}}}wxuuuxxxzzr>~+>+vxxw?^+` .-?vvxwxv^`.^>?>>>>>>>>?-.``` <br></br>
 -|~-->zuxwrv?>^-...`-|>}zuuxrvvzuw?+>}?>?v`...|??wzzx}?v}?>>>>>>>>>?}-.`` <br></br>
.---`>}vv??^`............`>rurzv}xuw+`+>vv?^^||--`.^vv+v?+^^|-|^>>+^+?^.`` <br></br>
 ``.`vvv?|..................+rwwr}}?^~vz}>.```~||^++>|^^^+??}?~|>>~``-```` <br></br>
 ->?`+}^......................?zzwrrrxrvv?>>>>+^|-``..``~wuzvwv|>>-....``` <br></br>
.?}}v?```. `v}+  ``............>uxxuwzr???>~.    ....... .+rx}?|>?^-...... <br></br>
.??vr|-~`  .vz> .-. `>~.........?uxxxwv?}}}}v^`.  ........ .|v+|??vwv^.... <br></br>
.??z>-~`--. +z> `. ~rz?.........`zxxzvvvvvvv}?v?|.  ........  `|^?xxzwr>`. <br></br>
.+}v`|`|~+~..}+ ..+wv|. .........}xzv?rvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
`+z~~?}v??|.->>+`~v^.............vxv~>wwrvvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.?}.|>rrwwv>????+-. .............vr-  +?wxxzwvvvvvvvvvv?~............-?zzw|<br></br>
.vv`-`|^^-~|------~>|-``......`..v^   |..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
 -?-^.```  -~----``v}rwwr?~-`|~ `^`   ~.   `>}uuuxxxxzr}vv?v?|.  ....... ..<br></br>
 ``.| .. -?r+..~>..`^>>?rv>+^|``.~`-..~.     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
 `....  >rr+.. ~w|  .^~^>?>^~-~. --r- `.        `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

 ```.|?^`.-?vvv}}v^..................................```````````--~~~~~||+`<br></br>
 `--?v~````-?zuzzzz}>`.`````.`..->`...............```````------~~~||^+^^+v~<br></br>
.|+}?```````.`>wuxzzzr>`.``.^|`?v??>??`............```````------~~||^+>?vr|<br></br>
-r}?~`-```````.`>wuuxzz}^..`?vvvv????~.............``````--~~--~~~|^^+?vvv`<br></br>
`zzw?~.`````````.`>wxzxxxv|~v???v??v?+`.``............```..`---~~||^^^^+^^.<br></br>
`xxxxz?-`....```.``.vzxr}wr???????vv?>+```...........`.. .`-~~~~~|||||||||.<br></br>
`xxxxuuw?-..`--``-..`>vv}r}v???>?????>`...............  `---~~-~~~~~~~~||~.<br></br>
`wzwwzzxx}>-`~-~`.......^vzw?>?????>^~........``.-?vv?^-`------~~-~~~~~~~-.<br></br>
`xuxxxzzzzrv|`.`````.``...|}wv>>??^. .......`^++^?v>?wwr?~`-----------~~~-.<br></br>
.?wuuxxxxxxx}?>|||``````...~rxwwxxxr+.....`|^|^+|+^++}wzw}|.`-------------.<br></br>
.||?wuuxxxxxxrvv?^```--```.^?-~?zzwzxv^+>>vvv+-||^+>>vvrrw?``-------``````.<br></br>
.~|-~?wuxxxxxurv}v?|||-```.|v...-??vv?vvv>-`-...~+^~^^~|^^-``--~+>-```````.<br></br>
.+^-|?>vzuuxxxuwr}}}?^`--..~}...`+`>>vrwrr?`.....`-~^+^`..`````-+??-``````.<br></br>
.^^+^>}}vvwzzwww}rvvvv?~...-}`..`?...|vxxzzr?-`-.|?>-^+`````````|>>|~~--``.<br></br>
`vv?vv}wv??}wzxxzwzrvvvv?^.`r`...?...  -vxuzwvv|`?v?```....``---^>>?>+^^-`.<br></br>
`vv+?zzrrrrwrwzuuzzuzr}}}}?~v-`|^v+>v?>?^>vwuxwr?v?`........``~^>>>^~++|`` <br></br>
.`..`>wuxuuz}vvvwuwzxzrvr}w}}wxzw}vwuyuw>^+|^}z?v???-......`-|+>>>+`-++|`` <br></br>
 .`~-..wyuxwvv?>>v}rwxuuuxxxzzr>|+>^vxuz>^+` .-?vvuzxv^`..`^>>>>>>++>>>?|` <br></br>
 `..  ^zxxwrv?>^-...``~>vzuuxrvvzuw?+>}?+?v`...|??zxzuwv+???>>>>>>>>+~~~`` <br></br>
 ....+}v???^-............`+}uwzv}xuz+`+>vv?^^||--`.^v}+vv?+^^||+>>>>~`````.<br></br>
 `. `vvvv+`.................|}ww}}}?^~vz}>.```~||^+++|^+^+>?}?~-^>>+`````` <br></br>
 `++`^r>`....`................?xwwrrrxwvv?>>>>+^~~``..``|ruzvrx+^>>`..```` <br></br>
.>??vv|... `}r+  ..............>uxxxwzr???>-.    ....... .+}x}v^^>+-...... <br></br>
.^+?v-...  .vz> .-. `?|.........?uxxxwv?}}}}?|`   ........ .~v+|>?vrv^.... <br></br>
.+|}~`  .`. +z+ `. ~rzv`........`zxxzvvv}}}}}?v?~.  ........  `|^?uxzwr>`. <br></br>
.^v>~....``..}+ ..>zv|. .........}uzv?rvvv}}}?v}}v+`. ...........^ruuzwxz?.<br></br>
.?}^`>}?>~..`+>^`|}^. .......`...vx}~vwr}vvvv?vvv}}v?^`.......... .~vxuwwu|<br></br>
                  `vv+.vrrrr?^????+`. ..``.--~`^.-~vw+.^^?wxxzr?vv?vvvvvv?~............`?zzw|<br></br>
`?v|`>^^^>^^------->+>>+`|^>+^.^+v>|`^-..|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
`+>|~^^|^~.-~----``>vwzzr?++^^.^+^|+|^-.   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
.~``^...`~?r+..~>..  .^>?++|~~.||-+?|^`.     .~vzuuxxxxxzw}?}}v>-.  .....+`<br></br>
 `..-.  >rr+.. ~z| .... .|^~-.`-`-|r~`..        `>wzzzzzzzzr}}vvv>^. ...?+ <br></br>


            </div>,

            <div>

 ```.^?^`.-vvvv}}v^...................................```````````-~~~~~||+`<br></br>
 `--?v~````-?zuzzzz}>`.`````..``-?-...............```````-------~~||^+^^+v~<br></br>
.|+}?```````.`?wuxzzzr>`...|>`>+???^|+~............```````------~~||^+>>?r|<br></br>
-r}?~`````````.`?zuuxzz}^..^vvvv?>?vv>.............```````--~--~~~||^+?vvv-<br></br>
`zzwv|``````````.`?wuxxxxv|^v????>?v?^................````-----~~~|^^^^++^.<br></br>
`xxxxxv~`....```.```?xzr}wr??????v???+`..........   .`~-``---~~~~|||||||^^.<br></br>
`xxxxuuz?~..``-``-`.`>?vv}}v????vv???>+~.........  ~vwwwv~`-~~-~~~~~~~~||~.<br></br>
`wzwwzzzxr?~`~-~`.......^}zwv>>>>+^~````.....`--` ^wwwwzx+-----~~~~~~~~~~-.<br></br>
`xuxxxzzzzrv+-.``-``.``...|}wv??}v~. .......`|+++|?v>?rwv`------------~~~-.<br></br>
.>}xuxxxxxxx}??|||``````...~rxwrxuxr+.....-+>|-+^^>|++rv```---------------.<br></br>
.|~>}xuxxxxxxrv}?^```--```.^?-~?wzwwz}^>??v??>`~`>>+>>~``-----------``````.<br></br>
.~^--?}xuxxxxurv}v?^||-```.|v...`??vvvvvv+`.`....~~~^+` .```````--```````` <br></br>
.+^-|?>?wxuxxxuwr}}}v+``-`.~}...`+`+>vwww}?`......-~~++.  ..``````-|^|~-~+`<br></br>
.^^+^>}}vvwwzwww}rvvvv?~...-}...`?...|vxxzzr?``-.^v?-~~`....|>^~~^?>-^+>??.<br></br>
`v}?}}}wv??vrzxxzwz}vvvv?^``r`...>...  ~vxuxwvv|`?v^.......-+>?>>>>~~>+>^~.<br></br>
`vv>?xzrrrrwrrzuuzzuzr}}}}?~v-`|+v+>vv??^>?wuxw}?v?........`~^>>>>|^>+~-`` <br></br>
.`..`>wuxuuzvv?vwuwzxzr}}}w}}wxzwv?ruyuw>++|^}z?v?v?~.....`^>>>>>>>>>-..`` <br></br>
 .`~-.`zyuxwvv??>}}rwxuuuxxxzzr>~+>^vzxw}>+` .-?vvxwxv^`.^v?>>>>>>>>+````` <br></br>
 ``.  ^zxxwrv?>^-...`-|>vzuuxrvvzuw?++vv+??`...|??zzzuz??}?>>^|||+>>|````` <br></br>
 .. .+}v?>?^`............`>rurzv}uuw+`^>vv?^^||--`.^vw>?v>++~..`|+>>-`````.<br></br>
    `vvv?|..................+rwwrv}?^~vz}+.```-||^++>^^+^+?v}?~-^>>~`````` <br></br>
 `++`+}+......................?zzwrrrxrvv?>>>>+^|~-`..``~ruzvww|+>+...```` <br></br>
.>??v?-... `v}+  ``............?xxxxwzr???>~.    .........+rx}>|>v+-...... <br></br>
.|^?v`...  .vz> .-. `>-........-}xxuxwv?}}}}v^`.  ........ .|v|^??rrv^.... <br></br>
.~|r`.  ``. +z> `. ~rz?.....`.``+xxuzvvv}v}v}?v?|.  ........ .`||vuxzwr>`. <br></br>
 `v>...  .`..}+ ..+wv|. ...-|`.^-wxx}v}vvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
 ~w- >v>|`..`>>+`~v^. .```|>~|`+~vxr>?wwrvvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.?w~.>v}rv?~????+-. .~^^.^>>|^.^+vw+||^?wxxzwvvvvvvvvvv?~............-?zzw|<br></br>
.^}|~|-~^|~`------~>^++|``~~^|.~|v+-||~..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.->+~+++>|`------``>v}rrr+..-~..~^`.~~~.   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
.|.^--..-|?r+..~>.   .-+?-..... ```.`-~.     .~vzuuxxxxxzwrvv}v>-.  ... .+`<br></br>
 -``^.  >wr+.. ~w|       ....   .``v-.`.        `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

.```.|?^`.-?vvv}}v^..................................```````````--~~~~~||^`<br></br>
 `--?v~````-?zuzzzz}>`.```````..```...............```````-------~~||^+^^+?~<br></br>
.~+}?```````.`>wuxzzzr>`.````....-+?+..............```````------~~||^++>?r|<br></br>
`r}>~`-```````.`>wuuxzz}^`.``..^?vvv??>`............``````--~--~~~||^+?v}}-<br></br>
`zzw?|``````````.`>wxzzxzv|..-?v???>????|............`````-----~~~|^^^++++.<br></br>
                  `xxxxz?-`....```.``.vxzwrzwv^vv???vvv?^...............````--~~~~~|||^^||^^.<br></br>
`xxxxuuw>-..``-``-`..>?vv}v}?????vv??>`.............`-||-`--~~~~~~~~~~~|||.<br></br>
`wzwwzzxx}>-`~-~`.......^}zw?>???>+^|-...........``^}rwwr~`----~~~~~~~~~~-.<br></br>
`xuxxxzzzzrv|`.``-``.```..|}wv??vv|........~||-~-^|+++}}?....`--~----~~~~-.<br></br>
.?wuuxxxxxxzv?>~~|-`````...~rzrrzxx}+. ...`+>>??+`~~+||`.````-------------.<br></br>
.~|>wuxxxxxxxrvv?+-``-~```.^?~~?zzzzxr>. .....`.. .``|+|`------------``---.<br></br>
.~^-~?zxxxxxxurv}v?^||-```.|v...-?wzzzz}+. ..... ...^+--```````````-``````.<br></br>
.+^~|>?vzuuxxxuwr}}}?^``-`.~}...`+.+}uuuuz?-......`^?>`.````````-~^^^|````.<br></br>
`+^++>}rv}wzzwww}rvvvv?~...-}`..`?...|vxuuuz?``-...-+|`.``````~>+^++>|````.<br></br>
`v}?}r}wv??rwzxxzwzrvvvv?^``r`...>.....~?xuxzrv|...........``~??>>>>>^>??`.<br></br>
`vv>?xzwrwwwrwzuuzzuzr}}}}?~v-`|^?^?v}v++>vwuuxx}+.....`|^^+>>>>??>>+>>+-`.<br></br>
.`..`>ruuuuzv??vwuwzxzrv}}w}}wxzwv?ruyxw?+^-^}uuuu}?-..+?++>>>>>>>>>+|-``` <br></br>
 .--`..zyuxwvv??>}}rwxuuuxxxzzr>|+>^vzxw?^+` .~vzuuwxv^`.^?>>>>>>>>+`..``` <br></br>
 `..  ^zxxwrv?>^-...``~>vzuuxrvvzuw?++}?>>?`....|vzxzuw??v>>+^^+>>>~.````` <br></br>
 .. .+vv?>?^-............`?wxwzv}uyz+.>>?v?^^||~-`.^vr>v?+^^~`~^>>+```````.<br></br>
    `vvv?+`................|?rwwr}}?^~vz}>.```~||^++>|^+^>?v}?~^>>|```````.<br></br>
 `+^`+r?`....`........`~`...`+vzxzwrrxwvv?>>>>+^~~-``.``|wuwvw+^>>-..````` <br></br>
.+??vv|... `}r+  ......`+|-~|~+}uuxxwzr???>-.    ....... .+}z}^^>v^-...... <br></br>
.|^?v`...  .vz> .`. `?|.~^>?>>+>ruxxxwv?}}}}?|`   ........ .~?|^?v}rv^.... <br></br>
.~|}-.  .`. +z+ `. |rzv`.^^>?>?+?xxuzvvv}}}}}vv?~.  ........  `|^?uxzwr>`. <br></br>
 `v>...  .`..}+ ..>w}>>+|++|>?+?^wxx}vrvvv}}}?v}}v+`. ...........+ruuzwxz?.<br></br>
 -r` >v>~`..`+>^`|}???v?++?+-?+|^vur^vww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
.?v` >v}vv?~????+``^>>^+~~~--^^.`vw~ -+vwxxwr?vvvvvvvvv?~............-?zzw|<br></br>
.|v~`  -`.``-------?+|```....``..?^  `~`.|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
 ~>^~~`-^-`|~----``>?}wr}+..... `-.  .`-   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
 +`||^~+??vw+..~>..  .-+?`....  ...`. ``     .~vzuuxxxxzwr}?}}v>-.  .....+`<br></br>
 |>`^~-^vww+.. ~z| ...   ....     .}- .. ..     `>wzzzzzzzz}}}vvv>^. ...?^ <br></br>


            </div>,

            <div>

.```.^?^`.-vvvv}}v^..................................```````````--~~~~~||^`<br></br>
 `-~?v|````-?zuzzzz}>`````````..```...............```````-------~~||^^^^^?-<br></br>
.|+}?-````````?wuxzzzr>`..```....-+?+..............```````-------~||^+++>}|<br></br>
-w}>~`-```````.`?zuuxzz}+`.``..^?vvv??+`............``````--~--~~~||^+>v}}-<br></br>
`zzwv^``````````.`?wuzzxxv|..-?v???>????|............`````-----~~~||^^+>>+.<br></br>
                  `xxxxxv~`....```.```vxzwrzwv^vv???vvv?^...............````---~~~~~||^^||^^.<br></br>
`xxxxuuz?~..``-``-`..>?vv}vv?????vv??>`.............`-||-`--~~-~~~~~~~~|||.<br></br>
`wzzwzzzxr?~`~-~`.......^}zw?>???>+^|-...........``^}rwwr~-----~~~~~~~~~~~.<br></br>
`zuxxxzzzzrv+-.``-``.```..|}wv??vv|........~||-~-^|+++}}?....`--~----~~~~-.<br></br>
.?}xuxxxxxxx}??|~|-`````...~rzrrzxxr+. ...`+>>??+`~~+||`.````-------------.<br></br>
.~|^vxuxxxxxxrv}?+-``-~```.^?-~?wzzzxr>`......`.. .``|+|`-----------`-----.<br></br>
.|^-~?rxuxxxxurv}v?^^|~```.|v...`?rzzzzr>` ..... ...^+--```````````-``````.<br></br>
.+^~|>?vwxuxxxuwr}}}v+``-`.~}...`>.^}uuuuz?-......`^?>`.````````-~^^^|````.<br></br>
`>^>+>}rvvrwzwww}rvvvv?~...-}...`?...|vxuuuz?``-...-+|`.``````~>+^++>|````.<br></br>
`vr?}r}wv??}rzxxzwzrvvvv?+``r`...>.... ~vxuxzrv|...........``~??>>>>>^>??`.<br></br>
`vv??xzwwwwwrrzuuzzuzr}}}}?~v-`|^?+>vv?>|>?wuuxxr+.....`|^^+>>>>??>>+>>+-`.<br></br>
.`..->wuuuuzv??vwuwzxz}v}rr}}wxzwvvwxyxv?^+~+}uuuu}?~..^?++>>>>>>>>>+|-``` <br></br>
 `--..`zyuxw}v??>}}rwxuuuxxxxxr>|+>+?zxwv++` .~vzuuwxv^`.^?>>>>>>>>+`..``` <br></br>
 `..  ^zxxw}v?>^-...`->}wxuuxzvvzuw?++}v+?v`...`|vzzzuw??v>>+^^+>>>~.````` <br></br>
 .. .+vv?>?|`.........`|>?vwuwzwwxyz+.+>vv?^^||~-`.^vr>v?+^^~`~^>>+``````` <br></br>
    `vvv?|.........-~~|++>>+?wwzzv}?^~vz}+.```-||^++>|^+^>?vr?~^>>^```````.<br></br>
 `+^`>}+............`|^+>>>>++rxxzwrrxrvv?>>>>+^|~-``.``|wuwvw+^>>-..````` <br></br>
.>??v?-... `v}+  ``..~+++?>>?^+vuxxuwzr???>~.    ....... .+rzv^^>v^-...... <br></br>
.|^?v`...  .vz> .-. `?>>>^>>^+++vuxuxwv?}}}}v^`   ........ .|?|^?v}rv^.... <br></br>
.~|r`.  ``. +z> `..+rx}++>~+^`~^>zxuzvvv}}}}}vv?|.  ........  `|^?uxzwr>`. <br></br>
 -}>...  .`..}+ `.>zr>^+||~.|..`~rxxv?}vvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
 ~w` >v>|`.`->>+`|rv>-~-`........vx}~>wwrvvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
`?}. >vvvv?~????+-`..............v}~  |?wxxzwvvvvvvvvvv?~............-?zzw|<br></br>
.|v.   ...`-------->|~``.........?~   `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
 ->.. .`~..------``>v}wr}+..... `-    `.   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
 `.`~` .->vw>..~>.   .-+?-....  ..... `.     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
 ~~`|^..?zw?~. ~w|       ....     .}- `. ..     `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

.-``.|?^`.-vvvv}}v^..................................```````````--~~~~~||^`<br></br>
 `-~?v|````-?zuzzzz}>`````````...`................```````-------~~||^^^^^>-<br></br>
.~^}v-````````>wuxzzzr>`..```......`-|>^....`......```````-------~~|^+++>v~<br></br>
-r}+``-```````.`>wuuxzz}^`..````..^?vv???+~`........``````--~---~~||^+>v}r~<br></br>
`zzw?+``````````.`>wuuxxzv~..~??`+v????????-......``...|~|---~~~-~||^^+>>>`<br></br>
`xxxxz?-`....```.```+wuxxuz}+....>??|^????|........`~`-^~^^~-|~-~~||^^^^^^.<br></br>
`xxxxuuw>-..``-````...^}zzwzxr+...^>>????|`..........`~~`||-`-~~~~~|~~||||.<br></br>
`wzwwzzxz}>-`~--`.......^}xxzzzv~. .^v>~-...........``..```----~~~~~~~~~~~.<br></br>
`xuxxxzzzzrv|`.``--`..``..|vxuxxw?~..>?................ ..``----~~--~~~~~-.<br></br>
.?wuuxxxxxxzv?>|~^-`````...~?vxuuuxr+. ............````..```------~-------.<br></br>
.~|>wuxxxxxxxrvv?+-``-~```.^?.-?zwzzxr>...........`````..``--~^--~+>``----.<br></br>
.|^-|vzxxxxxxurv}v?^^|~```.|v...-?wzzzz}+`........`````.`````^v--|>+-`````.<br></br>
.+^~|>?}zuuxxxuwr}}}?^``-`.~}...`+.+}uuuuz?-....`...```..````|?^~^+^^-````.<br></br>
`>+>+>vrv}wzzwww}rvvvv?~...-}`..`?...|vxuuuz?-`-.........````^>>>+-+>-```` <br></br>
`vr?}w}rv??}zxxxzwzrvvvv?+``r`...?.....~vxuxzwv|...........``^>>>>+>~````` <br></br>
`vv??xxwwwzwrwxuuxxuzr}}}}?~v-`|^?^?v}?>+>?wuuux}+......`````+>>>>>+`````` <br></br>
.`..`+wuuuuzvvvvwuzzuzwrr}r}}wxzwvvzuyxr?++~^}uuuu}?-...`````+>>>>>>-`````.<br></br>
 .`-`..wyuxw}}v??rzwxuuuuxxuzzr>|+>+vzxw?^+` .~vzuuwxv^...```^>>>>>>~````` <br></br>
 `..  |wxxw}v?>^~~^>?v}rwxuuxwvvzuw?>+v?+?v-...`|vzxzux}+`...~+>>>>>>````` <br></br>
 .. .^vv?>?+-...-^++>?????vzuwz}}uyz+`^+?v?^^^|~-`.^?xuxzv>|`.~>>+^+v^```` <br></br>
    `v}vv+`-~^^+>>????????^+?zzz}v}?^~vz}+.``-~||^++>>?zxz}v}?~+>^~+?>````.<br></br>
 `>+`+r>`...`|>>????>>????~`|^vxzwwrrxrvv?>>>>+^~~```..`>wuwvw>+>^.~|````` <br></br>
.+??vv|... `}r>~|+>>>?>+>>^..-`?uuxxwzr???>-.    ....... .+rz}^+??+-...... <br></br>
.||?v`...  .vz?|+>^||v?~~-~`....vuxuxwv?}}}}?|`   ........ .~?|^??zrv^.... <br></br>
.-~}-.  ``. +z>`^>+?wzv`........-zxuzvvv}}}}}vv?~.  ........  `||vuxzwr>`. <br></br>
 -v>...  .`..}+ ~^vz}^. .........}uxv?rvvv}}}?v}}v+`. ...........+ruuzwxz?.<br></br>
 ~}` >v>|`..`+>>~^}+. ...........vxv~>ww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
.?v. >vvvv?|????+`. .............v}-  |?wxzzr?vvvvvvvvv?~. ..........-?zzw|<br></br>
 ~?.   ...`-------->|~``.........?- . ...|vxurxzwr}vvvvvv>|`.  ....... .>}~<br></br>
 ->.. ... .-~----``>vrwr}+..... `-    ``   `>}uuuxxzwr}}vv?v?|.  ....... ..<br></br>
 .... .. -?w?``~>... .-+?`....  . ... `.     .~vzuuxxxxzwr}?}}v>-.  .....+`<br></br>
.||`..  >ww>^~`|w| ...   ....     .}- .. ..     `>wzzzzzzzz}}}vvv>^. ...?+ <br></br>


            </div>,

            <div>

.-``.|?^`.-vvvv}}v^..................................```````````--~~~~~~|^`<br></br>
 `-~?v|````-?zuzzzz}>`````````...`................```````-------~~||^^^^^>-<br></br>
.|^}v-`-``````?wuxzzzr>`..```.......-|>+....`......```````-------~~|^+^^+v~<br></br>
-w}>``-```````.`?zuuxzz}+`..````..^?vv???+~`........``````--~---~~||^+>v}r~<br></br>
`zzwv>``````````.`?wuuxxzv|..~??`+v????????~......``...|~|--~~~~-~||^^+>?>`<br></br>
`xxxxxv~`....```.```>wuxxuxr+....>??|^????|........`~`-^~^^~-|~-~~||^^^^^^.<br></br>
`xxxxuuz?~..``~-```...^vzzwzxr+...^>>????|`..........`~~`||-`--~~~||~~||||.<br></br>
`wzwwzzxzr?~`---`.......^}xxzzzv|. .^v>~-...............```-----~~~~~~~~~~.<br></br>
`zuxxxzzzzrv^`.`-~-`..``..|vxuxxz?~..>?...............  ..`-----~~--~~~~~-.<br></br>
.?}xuxxxxxxx}??|~^-`````...|vvxuuuxr+. ............````..```------~-------.<br></br>
.-|^}xuxxxxxxrv}?>-``-~```.+?.-?wzzzxr>`..........`````..``--~^--~+>``----.<br></br>
.^^-~?rxuxxxxurv}v?^^|-```.^v...`?wzzzzr>`........`````.`````^v--|>+-`````.<br></br>
.+^~|>?vwxuxxxuwr}}}v+``-`.~}...`>.^}uuuuz?-....`...```..````|?^~^+^^-````.<br></br>
`>+>>?vwvvrwzwww}rvvvv?~...~}...`?...|vxuuuzv-`-.........````^>>>+-+>-```` <br></br>
`vwv}wrrv??}rzxxzwxrvvvv?+``r`...>....`~vxuxzw}^...........``^>>>>+>~````` <br></br>
`vv??xxzwzzzrrzuuzzuzr}}}}?~v-`|+?+?}wv+^>?ruuuxr+.........``+>>>>>+`````` <br></br>
.`..->wuuuuxwrrwxuzxxxr}r}r}}wxzwv}wuux}?++~^}uuuu}?~......``+>>>>>>-`````.<br></br>
 `-```~zyuxxzzrrrrwwwxuuuxxxzzr+~+>+vwzw?++` .~vzuuwxv^......^>>>>>>~````` <br></br>
 .... |wzzwrrr}r}vvv??>?}xuuxwvvzuw?++v?>??`...`|vzzzux}+`...~+>>>+>>````` <br></br>
 .. .+vv?>?>+++++>>^~~~~`->wuwz}}xuw+`++vv?^^||~-`.^?xuxzv?^`.~>>+|+v^```` <br></br>
    `v}vv>|^^++++||~`.......+rwzr}}?^~vz}+.```-||^++>?vxuxrv}?~+>+~+?>````.<br></br>
 -+^`>}>-~^+>>>>>>>+|~~`......?xwwrrrxwvv?>>>>+^|~-``..`?zuwvr>+>^.~|````` <br></br>
.+??v?-...`|ww?||+^-...`.......>uuxuwzr???>~.     ...... .+rzv^+??+-...... <br></br>
.~|?v`...   vzv^^^. `>~.........?uxxxwv?}}}}v^`   ........ .|?|^??zrv^.... <br></br>
.-|r`.  ``. +xv^+` ~rz?.........`zxuzvvv}}}}}vv?|.  ........  `||?uxzwr>`. <br></br>
 -}>...  .`..}?|-.+wv|. .........}uxv?rvvvv}}?v}}v>-. ...........+ruuzwxz?.<br></br>
 |w` >v>|`.``>?>`~v^.............vx}~>wwr}vvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
`?v. >vvvv?~????+-. .............v}~  |?wxxzwvvvvvvvvvv?~............-?zzw|<br></br>
 ~?.   ...`-------~>|~``.........?-   `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
 -+.. ... .------``>v}wr}+..... `-    ``   `>}uuuxxxxzw}vv?v?|.  ....... ..<br></br>
 .... .. -?w+.`|>.   .-+?-....  . ... ``     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
.++-..  >rr+.-?vz|       ....     .}- `.  .     `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

.~``.|?^`.-vvvv}}v^..................................```````````--~~~~~~~^`<br></br>
 `-~?v|````-?zuzzzz}>`````````....................```````-------~~||^^^^^>`<br></br>
.~^}v~`-``````>wuxzzzr>`..```......................```````-------~~|^^^^+?~<br></br>
-r}+`--```````.`>wuuxzz}^`..`................``.....``````--~---~~||^+>?vr~<br></br>
`zzw?>``````````.`>wuuxxzv|...``............`~>|.`...`````--``--~~||^^+>??`<br></br>
`xxxxz?-``...```.```+wuxxux}+.............`.^>?|...`````.``..---~~||^^^^^^.<br></br>
`xxxxuuw>-..`-~-```...^vzzwzxr+.....`-`.....```.....`.......----~~||||||||.<br></br>
`wzwwzzxx}>-----`.......^}xxzzzv|...................``````---`---~~~~~~~~~.<br></br>
                  `xuxxxzzzzrv^`.`-~-`.```..|vuuxxzv~. .`.................````----~~-~~~~~~-.<br></br>
.?wuuxxxxxxx}?>|~^-````-`..|vvxuuux}+..............`````````----~|-----~~-.<br></br>
.-|>wuuxxxxxxrvv?>~``-~```.^?.-?zzzzxr>`..........`````````--~~~|||^^|~~--.<br></br>
.^^-|vwuxxxxxurv}v?^^|~`-`.|v...-vwzzzzr+.........````````--~^>+++>+^|||-`.<br></br>
.+^||>?}zuuxxxuwr}}}?^``-`.~}...`>.+}uuuuz?-....`...``````-^>>?++>>>>^||-`.<br></br>
`?>>>?vrvvwzzwww}rvvvv?~...-}`..`?...|vxuuuz?-`-........`~??+|~-|>?>^>^||` <br></br>
`vwv}wrrv??}wxxxzwzrvvvv?+``r`...>.....~vxuxzw}|.........`-``----||++^>~|`.<br></br>
`?v??xxzzzzzrwxuuzzuzr}}}}?~v-`|+?+?vv?>|>?ruuux}+...........-------^|-`-`.<br></br>
.`..`>wuuuuxw}vvzuwzxz}v}}r}}wxzwvvruyxr?+^~^}uuuu}?-........`------~~````.<br></br>
 .~|~~~zyuxxww}?v}}}wxuuuxxuzzr>~+>^?wzw?|+` .~vzuuzxv^......`------~~```` <br></br>
 ```..^wxxw}v?>^~-```-|>vzuuxrvvzuw?++v?>??`....|vzxzuxr>`...```----~-```` <br></br>
 .. .^v}}vv?>-`..``......`+}uwz}}uuw+.^^?v?^^||~-`.^?xuuur?^`.``----~````` <br></br>
    `}}}}>^|^^^~~-`.........|}zz}}}?^~vz}>.```~||^++>?vxuuzwr?~~~---~-````.<br></br>
 ->+`>w?||^^^+^~````..........?xwwrrrxwvv?>>>>+^~~-``..`?wuuwzw?+````````` <br></br>
.+??vv|..`-|rr>````............>uuxuwzr???>-.    ....... .+ruw}}wv>-....`. <br></br>
.~~?v`....``rx?..-. `?|.........vuxxxwv?}}}}?|`   ........ .~v}vwzzrv^.... <br></br>
.-|}-.  ``.`?x> `. |rzv`........`zxuzvvv}}}}}vv?~.  ........  `+}xuxzwr>`. <br></br>
 -v>...  .`.`}+ `.>zv|. .........}uxv?rvvv}}}?v}}v>`. ...........+ruuzwxz?.<br></br>
.|r` >v>|`..`>>^`|}+. ...........vx}~>ww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
`?v. >vvvv?~????+-. .............v}-  |?wxxzr?vv?vvvvvv?~. ..........-?zzw|<br></br>
 ~?.   ...``------->|~``.........?- . `..|vxuwxzwr}vvvvvv>|`.  ....... .>}~<br></br>
.-+.. ...  -~----``>v}wr}+..... `-    ``   `>ruuuxxzwr}}vv?v?|.  ....... ..<br></br>
 .... .. -?w+..~>.   .-+?`....  . ... ``     .~vzuuxxxxzzw}?}}v>-.  .....+`<br></br>
.>+`..  >rr+.. ^x>....   ....     .}- `. ..     `>wzzzzzzzz}}r}vv>^. ...?+ <br></br>


            </div>,

            <div>

.~``.|?^`.-vvvv}}v^..................................```````````--~~~~~~~^`<br></br>
 ``|?v|````-?zuzzzz}>````````.....................```````-------~~||^^^^^>`<br></br>
.|^}v|`-``````?wuxzzzr>`..```......................```````-------~~||^^^^?-<br></br>
-w}>`--```-```.`?zuuxzz}+`..`................``.....``````--~---~~||^^>?vr~<br></br>
`zzwv?``````````.`>wuuxxzv|.................-^?+``...`````-```-~~~||^^+>??`<br></br>
`xxxxzv~``...```.```>wuxxuxr+.............`.???+........``...--~~~||^^^^^^.<br></br>
                  `xxxxuuz?~..`-~-```...^vzzwzxr+.....-~`.....```....``.......----~~||||||||.<br></br>
`wzwwzzzxr?~----`.......^}xxzzzv|..................```````---`---~~~~~~~~~.<br></br>
                  `xuxxxzzzzrv^`.`-~-`.```..|vxuxxzv|. ..............`````````----~~-~~~~~~-.<br></br>
.?}xuxxxxxxx}??|~^-````-`..|?vxuuuxr+..............`````````----|^--~~~~~-.<br></br>
.-~^}xuxxxxxxrv}?>~``-~```.+?.-?wzzzxr>`..........`````--```-~~~||^^^||~--.<br></br>
.+^-~?rxuxxxxurv}v?^^|~`-`.^v...`?rzzzzr>`........`````````-~^++++>+^^^|-`.<br></br>
.^^||>?vwxuxxxuwr}}}v+-`-`.~}...`>.^}uuuuz?-....`...``````-+???+>>>>>^|^-`.<br></br>
`?>>>?vwvvrwzwww}rvvvv?~...~}...`?...|vxuuuzv-`-......```~vv>|-`~>?>^?+||` <br></br>
`vwv}zrrv??vrzxxzwzrvvvv?+``r`...>.....~vxuxzw}^.........`~``...`~~+++?~|`.<br></br>
`?v?vxxzzzxzrrzuuzzuzr}}}}?~v-`|^?^>v}?>+>vruuuxr+................`-^^-`-`.<br></br>
.`..`>wuuuuxw}vvwuwzxzr}r}r}}wxzwv?ruyuw?++~^}uuuu}?~.............``|^````.<br></br>
 .--~-~zyuxxwwv??v}rwxuuuxxxzzr>~+>+vzxr>^+` .~vzuxzxv^............`~^```` <br></br>
 --~~~?zxxwv??>^-`..`-|>}zuuxrvvzuw?+>v?^??`....|vzzzuur>`.........`--```` <br></br>
 `|^^vzzrvv+-............`>wuwzv}uuw+`^+vv?^^||~-`.^?xuuur?^`......``````` <br></br>
 |^+vxw}v?+|~-`.............+rwzr}}?^~vz}+.```~||^++>?vxuuxwr?~~``````````.<br></br>
.|?}vvw?^^>+^^~...............?xwwrrrxrvv?>>>>+^|~-``..-?wuuwxx}+`..`````` <br></br>
.>?v}v>+++>>rw+  ``............>uuxuwzr???>~.    ....... .+ruwwuxv>-....`. <br></br>
.~~?}++^>>>>wz> .-. `?~.........?uxxxwv?}}}}v^`.  ........ .|vwzuuxrv^.... <br></br>
.-^w^^+>???->x> `. ~rz?.........`zxuzvvv}}}}}vv?|.  ........  -?zuuxzwr>`. <br></br>
 -}>`.|.-~-`~r+ `.+wv|. .........}uxv?}vvvv}}?v}}v>-. ......... .+ruuzwxz?.<br></br>
.^w` >v>|`..->>+`~v^.............vx}~>wwrvvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
`?v. >vvvv?~????+-. .............v}~  |?wxxzwvvvvvvvvvv?~. ..........-?zzw|<br></br>
 ~?.   ...`-------~>|~``.........?-   `..|vxuwxxzwr}vvvvv>|`.  ....... `>r~<br></br>
.`+.. ... .------.`>vrwr}+..... `-    ``   `>ruuuxxxxzwrvv???|.  ....... ..<br></br>
 .... .. -?w+..~>~~. .-+?-....  . ... `.     .~vzuxxxxuxzwrvv}v>-.  ... .+`<br></br>
.?>`..  >wr+.. ~z?-`...  ....     .}- .. ..     `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

.~``.|?^`.~vvvv}}v^..................................````````````-~~~~~~~^`<br></br>
 ``|?v|````-?zuzzzz}>`````````....................```````--------~|||^^^^+`<br></br>
.~^}v|`-``````>wuxzzzr>`..```.......................``````--------~||^^^^>-<br></br>
-r}+.--```-```.`>wuuxzz}^`..`........................`````--~---~~~||^+?vr~<br></br>
`zzwv?``````````.`>wuuxxzv|...................`.......````---`-~~~||^^+?v?-<br></br>
                  `xxxxz?-``...```.`-`+wuxxuxr+...............`.`.....``.````.`-~~~~||^^^^^^.<br></br>
                  `xxxxuuw?-..`-~-```...^vzzwzxr+.....`~`...................-.`---~~|||||||^.<br></br>
`wzwwzzxz}>------.......^}xxzzzv|..................```````~`-`--~~~~~~~~|~.<br></br>
                  `xuxxxzzzzrv^`.`-|~`.```..|vuuxxzv|. ..............`````````----~|||~~-~~-.<br></br>
.?ruuxxxxxxx}?>|~^~````-`..|vvxuuuxr+..............`````````----~^||~~-~~-.<br></br>
.-|>wuuxxxxxxrvv?+~``-~```.^?.-?wzzzxr>`..........`````--``---~~|||~~~~~--.<br></br>
.++-~vwuxxxxxurv}v?^^|~`-`.|v...-vwzzzzr+.........`````````--~||||||~~~~-`.<br></br>
.+^||+v}zuuxxxuwr}}}?+``-`.~}...`>.+}uuuuz?-....`...``````-~||^||||||~-~-`.<br></br>
`?????vwvvrzzwww}rvvvv?~...-}`..`?...|vxuuuz?-`-......```-||~~-`-||||^---`.<br></br>
`vwv}zrrvv?}wzxxzwzrvvvv?+``r`...>.... ~vxuxzwv|.........``````````~~~~`-` <br></br>
`???vxxzzxxzrwxuuzxuzr}}}}?~v-`|^?^>vv??^>vwuuux}+................``--```` <br></br>
.`  .>zuuuuzrvvvwuwzxz}vr}r}}wxzwv?wuyxw?++~^}uuuu}?~.............``-~````.<br></br>
 .`||-~zyuxzwwv??v}rwxuuuxxxzzr>~+>+vzxw?^+` .~vzuuzxv^............`--```` <br></br>
 ~~-~~>zzzwv??+^-`..``|>vzuuxrvvzuw?+>v?>??`....|vzxzuxr>`..........`````` <br></br>
 `~+>vr}}??+-............`+}uwzv}xuw^`++vv?^^||~-`.^?xuuur?|`......``````` <br></br>
 ~+?vzr}v?|```..............^}zz}}}?^~vz}>.```~||^++>?vxuuxwr?~~`.````````.<br></br>
 ^vv>?wv|~|---`...............?xwwrrrxwvv?>>>>+^|~-``..-?wuuwxx}+`..`````` <br></br>
.?vv}}>~^^~~}r+  ..............>uuxuwzr???>-.    ....... .+}uwwuxv>~...``` <br></br>
.++>w+~|^^|`}z> .-. `?|.........vuxxxwv?}}}}?|`   ........ .~vwzuuxrv^.... <br></br>
.^|r|~-|^>^.+z> `. |rzv`........`zxuzvvv}}}}}vv?~.  ........  `>zuuxzwr>`. <br></br>
 -v>``~-^~-``}+ `.>zv|. .........}uxv?}vvvv}}?v}}v>-. ......... .+ruuzwxz?.<br></br>
.+r-`>v?+`.`->>^`|}^.............vx}~>ww}vvvv?vvv}}}?^`.......... .~vxuwwu|<br></br>
.?v~.+vvvv?^????+`. .............v}-  ~?wxxzr?vvvvvvvvv?~. ..........-?zzw|<br></br>
 ~?`.  ...`-------~>|~``.........?-   ...|vxuwxxzw}vvvvvv>|`.  ....... .>r~<br></br>
.`+.. ...  -~----``?vrwr}+..... `-    .`   `>}uuuxxzwrr}vv?v?|.  ....... ..<br></br>
 .`.. .. -?w+..~>.~|-.~>?`....  . ... ``     .~vzuxxxxxxzw}?}}v>-.  .....+`<br></br>
.?>`..  >rr+.. ~z^`~^..``....     .}- .. ..     `>wzzzzzzzzr}r}vv>^. . .?+ <br></br>


            </div>,

            <div>

 -``.|?^`.~vvvv}}v^..................................````````````-~~~~~~~^`<br></br>
 ``|?v|````-?zuzzzz}>`````````....................```````--------~|||^^^^+`<br></br>
.|^}?^`-``````?wuxzzzr>`..```.......................``````--------~||^^^^>-<br></br>
-w}>.~~```-```.`?zuuxzz}+`..`........................`````------~~~||^+>?}~<br></br>
`zzwv?-```.`````.`>wuuxxxv|............................```--~--~~~~||^+?vv-<br></br>
`xxxxzv~``...```````>wuxxuxr+.......................|+`````----~~~||^^^^^^.<br></br>
                  `xxxxuuz?~..`-~-```...^vzzwzxr+.....`~`..............`````^``--~~~|||||||^.<br></br>
`wzzwzzzzr?~-`-```......^}xxzzzv|...................`````.~`----~~~~~~~~|~.<br></br>
                  `xuxxxzzzzrv^`.`-|~`````..|vxuxxzv|. ..............````````-----~|^^||-~~-.<br></br>
.?vxuxxxxxxx}??|~^~````-`..|vvxuuuxr+. ............`````````-----+^||~-~~-.<br></br>
.-~^}xuxxxxxxrv}?>~``-~```.+?.-?wzzzxr>`..........`````---`-----~|~-------.<br></br>
`>+-~?rxuxxxxurv}v?+^|~`-`.^v...`?wzzzzr>`........```````````------```````.<br></br>
.+|^|+?vwxuxxxuwr}}}v+-`-`.|}...`>.^}uuuuz?-....`...`````````````--```````.<br></br>
`v????vwvvrwzwww}rvvvv?~...~}...`?...|vxuuuzv-`-......```````````````````` <br></br>
`vw}}zwr}v?vrzxxzwzrvvvv?+``r`...>.... ~vxuxzw}^........`````````````````` <br></br>
`???vxuxzxxzrrzuuzxuzr}}}}?~v-`|+v+>vv?>^>vwuuuxr+.................``````` <br></br>
.`  .+wuuuuwvv??wuwzxzr}r}w}}wxzwvvzuyxr>++~^}uuuu}?~..............```````.<br></br>
 .`~~-|zyuxz}rv??v}rwxuuuxxxzzr>|+>+vxxw?++` .~?zuxzxv^..............````` <br></br>
 -~--->wzzrv??>^-`..`-|>}zuuxrvvzuw?+>}?>?v`....|vzzzuur>`...........````` <br></br>
.-`-`+?vv>?^-............`>wurzv}uuw+`+>vv?^^||~-`.^?xuuur?^`......``````` <br></br>
 ``.~}}v?^..................+rwzr}}?^~vz}+.```~||^++>?vxuuxwr?~~`.````````.<br></br>
 ~??-?r+.....`................?xzwrrrxrvv?>>>>+^|~-``..-?zuxwxx}+`..``````.<br></br>
.?vv}?-``. `vr+  .`............>uxxuwzr??v>-.    ....... .+ruwwuxv>-...``` <br></br>
.>>?r|-|`  .vz> .-. `?~.........?uxxxwv?}}}}v^`.  ........ .|vwzuuxrv^.... <br></br>
.>?z>`-`~-. +z> `. ~rz?.........`zxxzvvv}}}}}vv?|.  ........  -?zuuxzwr>`. <br></br>
.>}v`|`~~+~..}+ `.+wv|. .........}uz??}vvvv}}?v}}v>-. ......... .+ruuzwxz?.<br></br>
`?w-~?}v?>-`->>+`~v^.............vxv~>wwrvvvv?vvv}}}?^`.......... .|vxuwwu|<br></br>
.?}.|>rrwwv>????+-. .............vr~  ^?wxxzwvvvvvvvvvv?~.......... .-?zzw|<br></br>
 +v.-`~~^--|------~>|~``......`..?^   -..|vxuwxxzwr}vvvvv>|`.  ........`>r~<br></br>
 ->`|.```  ------``v}rwwr?-``-- `|`   -`   `>ruuuxxxxzw}}v???|.  ....... ..<br></br>
 .`.~ .. -?r+..~>..`^>??}?+^||``.-``. ~.     .~vzuxxxxxxzwrvv}v>-.  ... .+`<br></br>
`v?...  >rr+.. ~z|  `^~|+>+|~`-. -`}- `. ..     `>wzzzzzzzzwvr}vv>^. . .?^ <br></br>


            </div>,

            <div>

                  .^~~--~-```^^^|^^+-..........-~`.-^+^^^^^^^^^^^^^^~`..```````````-~~~~~~~^`<br></br>
.>++vv~````-|>>++>>^~````````|^`..`~+>>>+++++++>>>>+^~```--------~|||^^^^+`<br></br>
`??vv>||-`````|>>++++^~`````.~^`.....-^>>>+++++++>>>>>+-``--------~||^^^^>`<br></br>
-zw?|++^|--```.`|+>>+++^-```.-+`.....~..-+>+++++++>>>>>>^-`-----~~~||^+>?v~<br></br>
`xxxr?^|||~-````.`~??>>>+^-..-^`.....|....`~^+++++>>>>>>>>>^~~---~~||^+?vv-<br></br>
`xxxxwv+||||~`````~`~+>++>+^--^`.....|.......`|>>>>>?v>+>>>???>+|~~||^^^+^.<br></br>
`xxxxxzw?+|~|^^-```...-^+++++??-....`>`...``....~+>>>>+>>?v>>?????>^|||||^.<br></br>
`xxxxxzww}?+^^|~-`......-^>+^?x>`...`|.....-~-`...-+>>>>>>?>>????????^^|~~.<br></br>
`xuxxxxzrr}v>^~~|+|`..``..-^>?x?^|`..|.......-^|-``-`~>>??>???????????>?>|.<br></br>
`?rzxxxxzwwrv??>+>^|-````..`|>x?+++|-|`~.~..`~~`||~`..`-^>?????????????>??`<br></br>
`??vwxuxxxzzzr}}v?+|^^|-...--.>++>>v?vv>|>^+>+-.```-^~```-|+??????????????`<br></br>
`}v??rzxxxxzzxwrrrv??>+^|--+v||^>}wwzwzwv?>>^^-~~~-``~|~-```-^>?>?????????`<br></br>
`v???vrwzxxxxxxwwrrwrv???>>?z>++>v+>v}rzxx}?>++^^~`..````-```--|+?????????`<br></br>
`r}v}rwzwwwzwzzzwzwwwwrv>>+?z>++>?-.`^?wxxuxr>^+|~-``````-~~~~-```|>??????`<br></br>
`}wwwxzzwr}wzxxxxzzwrrrr}v>?z>~-`|..||.+?wzzzzr>|||||----```.````````|>???`<br></br>
`??}}xxxxuuxzzxuuxxuxzzwwwv?w^|+>}??vv?>^+?vzzzz}>-`.............```---|^>`<br></br>
.|+??}xuuuuxwwwwzuzxxxzwzzzrrvrwzwwxxzwr??>|+vzrww+~`.....``-~||^^|~~--```.<br></br>
.?vvvvvxuuuxzzwrrwwwzxuuxxxxwrvvv}}}wxxwv||^-`^?r}>>??+~~||~~--```.....``` <br></br>
.????>?rwwr}v}}vv?????v}wxuuzwrvrzxwrrzz?|`|~-|^>vvvvv?>|`...........````` <br></br>
.-~~`^>>>^^~````~^+>??>>>?}zuwwwwzwwv?rvv?|>>>>?>-.`~++>>+|-.......``````` <br></br>
 `-`^vv??^-````....`-^>??>>?vzzzwwwv>?}}r^`^>>???|``-~|>>>>++|--`.````````.<br></br>
 -+>|?v>~```...........-+>??>?}xxwwwrrvvv??vvvv?>~-`...`|>>>+>>>~`````````.<br></br>
.++>??|-`...^^-..........`^>??>vuxxxzzrv}rv?++^+~-~~-`....-+>+^>>^~`..```` <br></br>
.||^+```....^>~..`...~`....`^??>}uxxxzwrwrrwrv>+~-~~~~~-`...`^++>>+^|-.... <br></br>
.^+?^--```. ->-... `+>|......->??xxxxwwwwwwrwww}>|---~~~~--`...~+>>++++~.. <br></br>
.>v?-|-~~|-..^-...-+^`........`+?wuxrrzwwwww}rzwr}?^~-~~~-~~~-`..-^>>++>+~.<br></br>
`?}++??>>+`.`-~-.`^`............|wxw?vzzzwwwwvwr}rr}v>|~~--~~~~-``.`^>>++>`<br></br>
.?v|+|+++>^~|||~-`...............?z?^^v}zuxxx}}}?rr}}}v?+~-~~~~~~~~-``|>++`<br></br>
.>v||`-`-``````````-``...........~^^^^?>+?rxuzww}www}vvvv?+^~-~~~~~~~~--|+`<br></br>
 -^~^`---..`````...|^++++|`..``..`.|^^?>^^^>vwzrrzxxzwr}}}?v?>|--~~~~~~~-~.<br></br>
.```. ...`|+-..`~..``-~|^~~-``...`.`+^?>^^^^^+>?rzzxxzwwwrvvv}v?^~-~~~~~~?`<br></br>
.?+...~-^+^-...`+`...```---``.`..`.^^+?+^^^^^^-`~?rzwwwrrw}}v}vvv?>~---|?>.<br></br>


            </div>,

            <div>

`?>>+`..`````....``..........?v-.^vrv}rr}}}}}}}}}}?`..```````````-~~~~~~~^`<br></br>
-xw}}v^````````.`-``````````.vw-..->}zuuxuuuuuuuuuuzv^````-------~|||^^^^+`<br></br>
`xx}}vv?~```````..``````````.?w-.....>vzxuxxxxxxxxxxuu}~``--------~||^^^^+`<br></br>
`xuz}vv}v+-``````.``````````.?z-....`v.`+}zwxxxxxxxxxxuxv|``-----~~||^+>>v~<br></br>
`xxuwvvvv}v^`.```..-````````.>z-....`}`. .-?rzzxxxxxxxxxwwr?^~`--~~||^+?vv-<br></br>
`xxxx}vvvvv}v|````~`..``..``.>z-....`}`.... .^vzuuxxxxzrzxuuuz}?+~~~|^^^+^.<br></br>
`xxxxx}vvvvv}v?|```...``.....+z~....`}-...^|.  `>ruuuwwxxxxxxxuuuzv+~~||^^.<br></br>
`xxxxxz}vvvvvv?>~`.....`.....^z~....`}`....^>>-. .|vwzuxxxxxxxxxxxuxr?>|~~.<br></br>
`zxxxxxwvvvvvvvv??+-..```....|z~....`}..... .^vv+``^`>}wuuxxxxxxxxxxuzrrv+.<br></br>
`vwwwzzzrvvvvvvvvvvv>`.``....|z~.....v+>.?--+??~??>-...~?wuxxxxxxxxxxxxwzu~<br></br>
-wxuxuuuuzwwrrr}}}}}}v>-.....~z|`-->^?zzvy}wxx>-|^+>?+-.`-+vwzxxxxxxxxxxww~<br></br>
`xxuuuuuuuzwzzzzzwwrrr}v>|>?vvrrzxzrwrzzuuuuzr?????-`^?+~-``~>}wwuxxxxxxxx~<br></br>
`uwzuuxxxuxzzzzwwzwzzxxxuuuuuuuuuux??>?rzuuuuxzrr?^`...`-~``-~|?vwuuuxxxxu|<br></br>
`xx}xxuxxxxzwzzzzxxxxxuuuuuxxxxxzr>~+^+vwzxuuzr}}?>~-.`--^+>++|-``+vzuuxxu|<br></br>
`rwwxxzxzrwxxuxxxxzzwwwwwzxuuz??|`.`??~??}rwwxw}rrr}v+++|~`.....``..`+}xxx|<br></br>
`>?wrzzuuuuuuuuuuuuuxxxxzzrzu???vww?vv??+++>}}r}}v^~``...........`-~^^>??}|<br></br>
.>vxuuuuuuuuuxxxxxxxxxxxxxxrw>vrxuuuz}v}vv?^>}r???`......`-~^>??vvv?>+^-.`.<br></br>
`uyuuuuuuuuuuuxxxxxxxxxxxxxzrvvrxuuuuuz}v~|v?>vv?>`-|+???v???>^~-`......`` <br></br>
`zzwr}}}}}}rwzxuuuuxxxxxxxxuwwx}vrxyyuuyv~.?v>v}v????>^~-`............```` <br></br>
.|^^^^^||~~-`--|>?rzuuxxxxxxuzwzx}v}xuu}v>~vvvrzz>.................``````` <br></br>
 ~+>???>>++^||~`...`+vzuuxxxxxxwrzx}?r}?w>+}xxxxz+ .......``.``-``````````.<br></br>
 `~+????>^~~`.........`+}xuxxxxxxzwwwv??vv}zxxxxr>^`......`...`-``````````.<br></br>
 .`~|^+|`................~vxuxxxxxxzzwwwwzxxxxuu}?}v?^`...............```` <br></br>
 ``........................-vuuxxxxxxxxxzwrxxxxx}?vv}}v?|`................ <br></br>
.~~~~~~~``...................+zuxxxxxxxxxxzrzuxxrv}vvv}}}??|`............. <br></br>
.+>>+^^^~~~`..................-}uxxxxxxxxxxxrzuxwwr}}}vv}?v}v?^`.......... <br></br>
.+?vv?+|||`.....................?uxxxxxxxxxxx}xwvzwr}vvv}?v}}}}v?^`....... <br></br>
.>???`...........................>uxxxxxxxxxuw}r?zzw}vvv}v?}vvv}}}v?+-.... <br></br>
.???>^-`..........................?uxxxxxxxxxxvv?wzzw}vv}v?vvvvvvv}}}}?>~. <br></br>
 -~+?>~^~..........................vuxxxxxxxxxw??rzxw}vv}}?vvvvvvvvvv}}}}v`<br></br>
.^^`. .............................-zxxxxxxxxxz>?}wzzrvvv}?v}}}}}}}}}}}}}}-<br></br>
.^~. `}?|...........................>xzzzzzzzz}>vv}wr}vvvv?vvvvvvvvvvvvvvv-<br></br>


            </div>,

            <div>

.?vv?|...````....``..~^~.....-~`.-^??^^^^^>v}^>}v>~..`|>>>>>+>+++>>>>?????`<br></br>
.vxzrv|``````````-``.~>^`````|^`.`^v?>>>?wuuw+>?rzwv>~`-^>??????>?????vvv}~<br></br>
.?zzr}?~-```````````.`>^``.~.~^`~+>^.|?wuuxxv???vwzzwr?-.~|+??????????vvv}~<br></br>
`?rxw}v+|-````````````+^-`-+~-?++>>^+r??vwzrv}rwzwrwzww}?^``-|>?vvvv?vv}}w~<br></br>
.?vuz}}?|^~-`.```..-`.++-`~>>>w>>>>>?z?>^|+?}wzzzwwzzzzw}v?+~-`-^>vvv}}rww~<br></br>
`??zxw}v^^||~`````|`.`|^-`|>>vz?>>>>?w?>+++^^>vww}}wxuzrwwzz}?>^|~~+?v}rr}~<br></br>
.>>ruz}v+||||^^~-``...~||~^>>vx?>>>>?w??>>?>~-~^>vrwxrrwxxwzwrrv??>^~|>v}r~<br></br>
.??vxxw}??>^^^|~`.....-^|||>>vu?>>>>?w?>>>+???+|~|>}wzwwxzwrvv}wwv???+^|+?-<br></br>
.v??zxzwv>>^|||~^>>||||^^^>>+?x?>>>>?w?>>>>+^?vv?^^>^>v}zzwrr}vvv}??????>|.<br></br>
`??>rxzw}>++>?v}rwwwv>+|||~-~?x?>>>>?wvv>}?v}}v>vv?^```|?}w}}}}vv?????????`<br></br>
`???vuxzzrwzzzzzzwwwwrv^~-`^+?x?+>+vvrxxwurwzzv>???+?+~``-+?vv??????????>?`<br></br>
`r}}wxxxxuxzzzzzzzzzzzw?>>>vvv}}rrzwzwzzuxxzwrvvvvv+^>?>|~--|>????????????`<br></br>
`xzxuxxxxxxxxxxzzxzxxzzwrzz?rrwrrzzvv?vrzxzzzzwrrv?+|~~~~|~-~|^>>?????????`<br></br>
`xxzuxxxxxxxxxzzwww}>++?rxurvzwwrv?^>>?vrwzzzwrr}v?^~`-~^++>+^~-``|>??????`<br></br>
`wzxxxxxxzxzw}vv??>^|||^wzxx?r}v?+^|??+v?}r}wwrr}v??>~>++>|-``......`|>vv}~<br></br>
`}rzzxxuuuuwvv?vv}?>+^^+zxzzz}}vv}}???>?>>??}}rr}v+|-```-|~`.``--|^+???}v}~<br></br>
`vwxuuuuxuxzvv}r}rwwv>|~wxzwrvrrwxxuwv?vv??>?}rvv?|`````-~|>>?vvvvv?>^~-.`.<br></br>
`uuuuuuuuzwrwzzwrzxxxw}>?xxzwrrzxuxxxxrv?~~>+^+^|--||>?>>??>^|~``......``` <br></br>
`xxzzwwwrv}wzzrwxuuuuuuxwwzxzwwzwzuuw?}?~..^^~|^^+??>?>^-|-..........````` <br></br>
.?vvvvvv>+?v?^>vrwzuuuuuuxzwwwzxxwv>||^-~`.vvv????~^^~~~~|`........``````` <br></br>
`vvvvrr}??}?+?vv???v}zxuuuuuxww}vv^``---^`.vz}vv?>~+++|~|~``.``-`.````````.<br></br>
.vv}vwwr?>?+>vvvv?????v}zuuuuuxzrv+^|~-----?vvv????>^^^~|-``.``~`````````` <br></br>
.??v?v}v>|~^?v???????????vwuuuuuuzwv>^||||?}vvvv?vww}?+~~...........```-~~.<br></br>
.????>???>^|^>??v??????????vwuuuuuuxz}?+^>vwzzwrv}wwzw}?^....```--~~||||^^.<br></br>
`vvvv??v???>+||+>????????????vxuxxxxxwwv>?vvwzxxzwwwwwvvv??>^~~|^|^^++++>>`<br></br>
`}}}vv??vvv???+^||+>?????????>?wuxxxzrr}?v?vvzxxzzzwwrvrr}www}?>++>>++++^^.<br></br>
`}rwwr}v?v??????>+||^+>?????????}xuwrzxrvv??v}xzwxzzwrvzzrr}}vv?+~-`...... <br></br>
`}rwr???>>>???>???>>^||+>???????>}zrwuxrv??vv}wzrxxzw}v?^|~~~---~~~-`..... <br></br>
`rwr}v????>>>???>>???>+^||+??????+?zuuurvv}}}wrwrzxxz}}>~~-~~~~~~~~~~~~-`. <br></br>
.??v}}vvv??>>>>>??>>>???>+||^>??+~+wxzwrrxxxxxz}rzxxz}v^~~-~~~~~~~~~~~~||~.<br></br>
`vv?>+>???>>?>>>>>>??>>>??>>+^|||||^rwwzxuxxxxxvrzzxw}vv?^-~~~~~~~~~~~~~~~.<br></br>
`vv?^>wr?????????>>>>???????>>>^|^^+vxzzzzzzzzwvrwwwr}?vvv>--~~~~~~~~~~~~~.<br></br>


            </div>,

            <div>

 ?r}v?`.`````....``..>}?`..........^+.....-?v.~v?~`..-?rwwrrvrr}rr}}}}}}}}-<br></br>
 +uxwr^``````````-``.>u}-``.`....~vx~...+}uuv..~vwv?|``|?wuxuyuxzxuuuuuuuu|<br></br>
 -zuwr?`````````````.|xr~`.+`..`?xy}.-?wuuxz+`-|>v}}v?^`.^>vzyyyuxuxxxxxxx|<br></br>
.`vuzr}~````````````.`zw^`~z>.^}xuuv}xyuuxrv^>??vvv?vvv??+``->}xyyyuxxxxxx|<br></br>
.`^uxr}>.-```````..-`.}x+.?ux>rxuuuyyuuuzwrvv}rrrv?v}r}v?>^-```~>vxyyyuuux|<br></br>
.-`wuw}v``````````~`.-v}>`}uyxzuuuuuuuuuxxxzwr}}v+>?zxwv}}w}?|----|?ruyyuu|<br></br>
 `.vuz}}~````-~--``...vr}vruuuuuuuuuuuuuuuzwr}}}>>>?rv}vwxv}}vv^~~~~~^?zyy^<br></br>
.|->ux}}>+^---```.....>rr}vxyuuuuuuuuuuuuuzr}}}}}}}}}r?vzrv?~^?v}?~~~~~|?w^<br></br>
.+^~zuw}?>^~````-+>>?v?v}rzxxzuuuuuuuuuuuuuxwr}}}}}}}?+?vv???>|~^>~~~~~~~~.<br></br>
`>|`vuz}v>||^?vrzxxxxuw?vv?>?wuuuuuuuuuuuuuuuzrr}}rv>+>>>??++++^~``-------.<br></br>
`+~`+xz}}}wzxuuuxxxxxxx??>>vruxxwwwxuuuuuzrrwwwr}rv+++++++>>++~``---------.<br></br>
`???vzxxxuxxxxxxxxxxxux?>v?}vvvvvvwuxwrrxz}}}}}}}}}}vv??+++++>+-`--`````-`.<br></br>
`zxuuxxxxxxxxxxuuuuuxwrv?vr~??v?vrwwrr}}wzr}}}}}}}}rrv?>++>+++>+---```````.<br></br>
`xxxxxxxxxxxuxzwr}}v-..~vwyv>}}vvvv??}}}}}}}}}}}rr}v>|+>??++++~^|````````` <br></br>
`xxxxxxxxxxzv?^|~-`    .wwzw|vrvvv}v??vvv}}v}}}}v?>+|->>?}?>+|.....```-|+?`<br></br>
`xxxxxxxxxu}^^-~|>^.   .zxzwwzzvv????+>?}v}vr}}}r}?++~^+?v?+-~~+>??v}rwwr}-<br></br>
`xxxxxxxxuxw>>?v?v}v>`  }zww}xzr}rzzr???v+??rrr}v??>^>++>??}}vrr}vv?+|~``` <br></br>
`xxxxxxxxrv?vrwv?rxxz}?~+zwrwwzxzzwzww???|^^^^^|~`>?^v?++?v^~-``.......``` <br></br>
`xxxxxxurvvzuzvvzuuuuuuzvv}wzr}xxxuxv~>-. .~~~~~~|vv?}}?^v?..........````` <br></br>
`wzzxxxxv?wyw?vxyuyyyyyyyxrvv}zxzwv~  .   .?v?>||vvzz}}}?}~........``````` <br></br>
-xzrwuxx?vxr?vxyyuuuuyyyyyyuwv??>+.        ?r>^|^vrxxz}}}?``.``-`.````````.<br></br>
-xuxrxxx?v}vvuyyyuuuuuuuuuuyyyz}?+.        ||~~~^?wxxxw}}^.`.``~`````````` <br></br>
`xxuwzxuv??vxyyyuuuuuuuuuuuyyyyyxwv>-.    |?>^|^+}xxxxw}v`.........``-|^+?`<br></br>
`xxxzrxxxwvvvrxyyyuuuuuuuuuuuuuuyyuzv?|. .?v}}v?vwxxxxr}?..``-|+>>?vv}rrrw~<br></br>
`xxxxwwxxuxzrvv}wxuuuxxxxxxxxxxxxxxuuvv^.~^>vwxxxxxxxzvvv?vv}}rwwwwwzzxxxu|<br></br>
`xxxxxwwxxxxuxw}vv}wxuxxxxxxxxxxxxxxr?v?~|~^?rxxxxxxxw?wwzzzxxxuuuuuxxzw}v-<br></br>
`xxxxxxzrxxxxxxxz}v?vrzuxxxxxxxxxxu}?}zv|~~|+}xxxxxxxwvuxzzr}v?>+^|~-`.... <br></br>
`xxxxxxxzrwxxxxxxxxw}??}wxxxxxxxxur?}yxv|~|^^?xxxxxxxrv?-`...      ....... <br></br>
`xxxxxxxxxwwzxxxxxxxxzwvvvrzuuuuu}?}uyuv++>>?vzxxxxxx}}| ................. <br></br>
`xxxxxxxxxxxwrzxxxxxxxxxzrvvvrxu}?}uxw}??wzzxxxxxxxxz}v-  ................ <br></br>
`xxxxxxxxxxxxxwwwzxxxxxxxuxzrvvvv?vvvv}wxxxxxxxxxxxxwv?v>`................ <br></br>
`xxxxxxxxxxxxxxxzwwwzxxxxxxxxzw}vv}wzxxxzzzzzzzzzzzzrv?vvv^............... <br></br>


            </div>,

            <div>

 ?rvv?+++++|~||^^+>+^?}v+^^^^^^^^^|>?|^||^>v}~+v?|~~~^?}rrr}v}}}vv????????`<br></br>
 >uz}r?>>>>>>>>>>??>+vux?+>>>>>>>?rx?>>>vwuu}--+}wv?>||>vwuxuuxxrv???vvv}}-<br></br>
 -zxv}v^>>>>>+^++^^+^?ux?+>v>++>}xyw>?}zuuux?+??vwzw}?>^~>?vzxuzv????vvv}}-<br></br>
.-}uwv}+|^^^^^+>>>>>>>xx?>?u}+?wuuurruuuuuxz}rrzzz}}r}v???~|+?vvvvvvvvvv}}-<br></br>
.`>uz?}>~+++++++++++++wxv+}uuvxuuuuuuuuuxxxzzzzzz}vwrwr}v??^^~`-^>?vv}}rwz~<br></br>
.-|zu?>?||~~~-|^|~+^~+}}?+zuuuxuuuuuuuuuxxuxxzwwv?v}zzwr}}w}?>+^^^|+?vrrrw~<br></br>
 ``}x}+?>^|||-|~~``-~|v}}}zuuuuuuuuuuuuuuuxxzzwr??vv}v}vzz}}vv}v?>>>>>?}ww~<br></br>
.|-?xzv}???-|+|`|`.-~~?zzzwuuuuuuuuuuuuuxxzzzzzzr}}}}}v}zwrv^+v}}v>??>>>?v~<br></br>
.>??xuxzv?+|~>^`|>??vvv}rwzxzzuuuxxuuuuuxxzzwwwww}}}}v?vrr}vvv>>??>???>>>^.<br></br>
`???ruxz}?^+>vv}}zzzzxr?vvv??wuuxzzxxuuuxzzzzw}}}}}v??vvvvv?v???>+^^>++>>>`<br></br>
`??>vxzwwvr}wzwxr?rrrrw??>vrruxzwrrzxxxxuw}rrww}}r}???????????>^^^^^^+++??`<br></br>
`vvvwzwv?}xrwzzux?vzzxzv???}v}}vvvrzzw}}zwv}}v}r}}r}}vvv?v????>|~||^+>????-<br></br>
`xuzv?+++>>?xxzuux?rzzzrvv}^?v}??v}r}}}}rw}}}}vr}}}}r}v?>>>+++++~~~|^+??++`<br></br>
`zv++++++++?uuxzwzv|^^|?}}uv?}vv}????}}}}}}}}}v}rr}v????v?>>++~|^|^++^+^^+.<br></br>
.>^++++++++vw}vv??^..-`~wrrw^?}vvv??^>vvvvvv}}}v}????>v?vr^^+^.``-~||^>??v-<br></br>
.^^++++++++?vv?vvv?+~~-|rvv}vvv???????>v}?v?v}}v}}v??>??rv--~|~^+>??v}wwwr-<br></br>
`?>||++++>+^?v}r}rrv?+--vrrr}xuzxxuwvv??v?>?vvv}v???vv~~+^~?r}wrv??>++|~-|.<br></br>
`wr}?>||^|-`^wz}}ruxxzv>?xwrwwzzzzzwwwv??+^^+>>^`.?v?v^-|vv??>^-`....````|.<br></br>
`zzwww}v>`-~|v}}rvwzxxuxrrwrwrrzzzxxw>?~`.-||>?+.`v}vwwv?wv^^^~--~~^~````-.<br></br>
`zzxxzzzv?vv+>vxr?vwzzzzzwzr}wzxzzr?-..--..~??v?+??wxwww}w>^^^^||^~-|-``--.<br></br>
-xzxxuuurvzr?vwx}}zzzzzzzwuuzrr}vv^.   .`. ->>>>>vrwwzwrw}^|+>?^-|`.````--.<br></br>
-xuuxuuuwww}}zxzzwzwzzzzwzuuuuxwv+.        ..-+++vrwwwwwr?^^+++``-^`````--.<br></br>
-xuuxuuuwwzwuuuxzzzwwzzzzxzw}rr?+~`.      .```|+>vwwwr^+v^^^^|~`--````-~^|.<br></br>
-uuuuxuuuxzzwzuyuuxzzzzwwwwrv?+^>?>+~`.  .`--~??vrwww}???^+>^|~^+>>|~||+>^.<br></br>
-uuuuxxuuuuuxzwzuuuuzzwwwrv?>^^++>>>>~`....`-~vwrrrrr}????vvv????v}v>>??vv-<br></br>
-uuuuuxxuuuuuuxzwwzxuuxwwwwv>^|++>>>^`-`...``-+?>>>++^~^?vvv}}v>wx}rrrwwr}-<br></br>
-uuxxxuxzxuxxxxxzzwrwzxxxzwwwr?+^^+|`^>-.....-?????>>++vr}vv??>~||||~-~`.. <br></br>
-xuxxxxxxzxxxxxxxxxzwrrwzxxzwwwwv>~.~?>`.....>}vv?????v?-~-`.`............ <br></br>
-xxxxxxxxxxzzxxxxxxxxxzwrrwxuxzzz}>^^+>`....>}rrrv??v}}+`--``............. <br></br>
-xxxxxxxxxxxzzzxxxxxxxxxxzwrwzxx}?vzv+-..-`+}rr}}r}?vvv^~~~--`............ <br></br>
-xxxxxxxxxxxxxzzzxxxxxxxxxxxzr}}}vvvvv>-~~>w}vvvvvvv?vvvv+~--............. <br></br>
-xxxxxxxxxxxxxxxxxzzxxxxxxxxzzwrr}}rwz}|~>wzzwrvvvvv?v?rwr?--............. <br></br>


            </div>,

            <div>

                  ?r??vrrrrrvvvvrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrvvvvvvvv???????????v?`-~~~~~^`<br></br>
 >yrvrwuyyyyuuuuyuuuuuyyuuuuuuuuuuuuuuuuuuuuzrrrrrrrr}}}}}}}}}}rv~~||^^^^+`<br></br>
 ~xzv}}rxuuxxzzwwwwzzuuuuuuuuuuuuuuuuuuuuuuxzzxzxxxzwr}}}}}}}}r?--~||^^^^^.<br></br>
.~wx}?}}r}}}rwzxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxwr}}}}}}}}r?---~~|^^^^>`<br></br>
.`vxr>vvvvrzxxzzzzxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuxwr}}}}}}}}}}?-`--~~|^^>?}~<br></br>
.-?uw>|vv}vvvvv}}vrrrrrrrwxuuuuuuuuuuuuuuuuuuuxzr}}}}}}}}}}}v}}vv?+^||^+>>`<br></br>
 `^uzv`?wrvv?>???>??vr}}wxuuuuuuuuuuuuuuuuuuuuxrr}}}}}}}}}}?vwxuw}vvv?+|^^.<br></br>
.|~wz}?v}v}>?w?>}?>v}rwxuuuuuuuuuuuuuuuuzzzxuuuxr}}}}}}}}}}vvvrrrwwrr}v?>^.<br></br>
.vrxuuuuvvv?vxw~?}vrwrwwwwwwwxuuuzzxuuuuzrrrrwwzzr}}}}}}}}}}}}}}}}rwurv}v?.<br></br>
-uuuxxxxz}?rrrvv>v}vr}}vvv}}}wuxwrrrwzuuzrr}}}}}}}}}}}}}}}}}}}}}}}}}}vvvr}-<br></br>
-xxxxxuux?v>?vvw?|?>??????}rrzxwr}}}}rrzw}}}}}r}}}}}}}}}}}}}rrrr}}}vv}}}ux~<br></br>
-xxuux}>-?w?}wrux^+}rwwwr?}rrww}vrrrr}}vvvv}}v}}}}}rr}}rr}}vv??>>??vvrxzww|<br></br>
-uuw?-   `.^xxruyz|vrxzzw?vr}rr???vvvvv}}rr}}v?r}}}rrrrv??>++^^^+++>?vzzvv-<br></br>
-r^.       |xxwwzuv.vrwzw?vr}v?r}?>v}}v}}}r}}}?}r}}rrrr}vvv?>>>|+>?vvv}vvv-<br></br>
 .         >xzzzzwv++vvvw??vv?vvv?^~|>???>?v}rv?r}}rrrrr}}+++++^>>????vvvv-<br></br>
           >uzzzxzzxrzwwv^~>>>>?vv}}}}?}r??>?v}?}rr}}}v?xv.^??>>>>+++?vvvv-<br></br>
.~.        .rxzzzzz?rzzzrvvvvzyyyyyv>rv?vv+>>?vv?vvvzw-`^``?rr}}?>^>>??>?v-<br></br>
`vv?|`      -wzzw}zwzzzzxxw?vwzzzzzrwxxv>?>??v}?`-vrwv-`^vwrwrv?+^++^^+++?`<br></br>
`r}vvv?>~.   +zxv-?vwzzzwwxvzxzzzwzxxxv>?v?>>vwv `r}rwrrzzwwwrv???vv?>^++>.<br></br>
`xxxzw}}vv>~.|?x> `>??????zzzzzzzzxxw+|zw|. |?rw?+>}wwwwwwwwwwz}v}?+v?+++>.<br></br>
-uuyyyyuzrvv??>v||????????zzzzzzxxz?.  ~>.  .>v}vv???rwwwwr}wzu?+?~`|+++>>.<br></br>
-uuuuuuyyyuxrv?????>?????vxxxxxzwv-          ~?v??????rzww}}ww}``+v^^++^>+.<br></br>
-uuuuuuuuuyyyuz}vvv?>?vvvrv???+`.            .>v??????`~v}wwwrv???>^||--+- <br></br>
-xuuuuuuuuuuuyyyuw}vvvv?+>>>~`                ?vv????>^>?wzw}?^^+>>`....`. <br></br>
-xuuuuuuuuuuuuuuyyyxrvvv??>^.                 ^???????????v??++|^+?>..`-|+`<br></br>
-xuuuuuuuuuuuuuuuuuuuuw}vvv>~.                .``......`|+++++~.?w^+>vwwww~<br></br>
-xuuuuuuuxxxxxxxxxxxxxxxz}vvv?^.             .~|~-```-~^+>+++?|``|>+>>^~-` <br></br>
-xxxxxxxxxxxxxxxxxxxxxxxxxxwvvvv>`           ^?>^|||~+v?^v?>^?+|`.-|^-.... <br></br>
-xxxxxxxxxxxxxxxxxxxxxxxxxxxxwvvvv>`        >}vvv>|-^}vv?rvv?+. .......... <br></br>
-xxxxxxxxxxxxxxxxxxxxxxxxxxxxxuzvvv}?`     .???+^?>`^vvvwzvv}?............ <br></br>
`xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxuuzvv}}~   ~v+|~~~~~~^vvrxxrv}| ........... <br></br>
-xxxxxxxxxxxxxxxxxxxxxxxxxxxzzzzzw??vv>  `wxzr?>|~|^+v}zzzrvv............. <br></br>


            </div>,

            <div>

 ?r?vvrrrrrvvv}rrrrrrr}}}}rrrrrrrrr}}}}}}}}r}v??????????????????v?~||||||^`<br></br>
 ?uzrzxuyyyuuxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuw}}}}}}}}}}}}}}}}}}rv||^^+>>>?`<br></br>
.^xxrwwwxxxzzzwwwwzzxuuxxxxxxxxxxxxxxxxxxuuxzzzzzzzwr}}}}}}}}}rv|~~|++>>>>`<br></br>
.^zxrv}}}vvv}rzxxxuuuuuuxxxxxxxxxxxxxxxxxxxxxxxxxxzr}}}}}}}}rwv^++|^^+??+>`<br></br>
.~vxr>vv?v}rrwwwzzzxxuuuxxxzzzzzzzzzzzzzzwzzzxxxzr}}}}}}}}}}}?~~^>+^^>??vr~<br></br>
.-?zv>|vvvvvv?vvvvrrrwwwrrwzzzzzzzzzzzzzzzzzzzwr}}}}}}}}}}}}v}r}vv?>++>?v}-<br></br>
 .^z}?`?wrv}v????>?vvrwwwzxzxxxxxxxxxxxxxxxzzzr}vv}}}}}}}}}?vwxuzrwr}v?+>>`<br></br>
.^-}w}v}r}r?vwv?}?>v}rwxxxxxuuuuuuuuuuuzvrxzzzzw}v}}}}}}}}}vvvrwwwwww}}v?^.<br></br>
`vrvruux}}}v}xw^v}?v}}wwwwwwzzxuxzzxzr}}}wzwwwrrw}}}}}}}}}}r}}}}}}rwuw}}}?`<br></br>
-uuu}vzxz}vrwwv}?}}vvvvv}v}}rzuxwv???}zxxwwr}vv}w}}}}}}}}}}rrrrrrrr}rwrrr}-<br></br>
-xxuur?rxv}?vrrzv>v???????v}rzr???vrwwzzzrrw}v}rr}}}}}}}}rrrrrrrr}}vvrw}xz-<br></br>
-xxuuur^-?wvrzzux??r}r}}}?vv????}zzzzwrrrv}r}v}r}}}}}}}}r}}}v??>>??vvrxzww|<br></br>
-xuwv+~`.``+xzwuux^v}zwrv+?vvrw}vvvr}v}wr}}}r}vr}}}}}r}vv??>>+++>>>?vvzwvv-<br></br>
-w>-```--` ~wzwrzuv-v}vv}?vr}w}zwv>}rwww}}r}v}?v}vvvvvvv????>>>+>??vvvvvvv-<br></br>
.`````````.+}wzzzwv+^?v}zvvr}vvr}v>++v?>?vv>vr}vr}vvvvvvvv^+++>>>??????vvv-<br></br>
  `````````>zzzzxzwzrzwwv>^??>??vv}rr}^^>v?|>}rv}rr}}}vvxv`^??>>>>+++>??vv-<br></br>
.-.`.```````}zzzzzzvrzzzr}}r}xuyyyyv??^~|>|^+>??vvvvzw|~^-`?vvvv?>^>>>>>?v-<br></br>
`v??>~`..``.|wzxw}zzzzzzzxwv}zzzzzzr???^`~-|??v?`-v}rv|~^vvvvvv??+++^++++?`<br></br>
`r}vvvv?^. ..>zzv|?}rrrr}wx}zzzzzw}?>?>^|+||>vw? `vv}}}rrr}}}rv?v??v?>^++>.<br></br>
`xzzzrvv}v+`.^?w>.~>??????zzzzzrv?>>+~~}?-. |?}v+|?rrrrwwwrww}v?vr?^??+++>.<br></br>
-uuxxxxzzr}?>???^^????????zw}v?>>?>~.  -|.  .?v?>>?vvrwwwwwwxxu?|?^`^+++>>.<br></br>
-uuuuxxxuuxzrvvv?????????>?>>>>>+|.        ..^?v???vvvrzwzwzzw}``>v^^+++>+.<br></br>
-uuyyyuxxxxxxuzr}}v?^|++^^||~-`.           .`-?vvvvvv?|+vrwvv?v?vv>^||~~>- <br></br>
-uuuuxxxxxxxxxxxz}?^|~~`````..             ..`vrrvvvv???vwwr}v??>>+`...`-. <br></br>
-xuxzxxxxxxxxxzwwwv?>|---``..              ...>vvv}}}vvvvvvv?>+|^>>>`.`-~+`<br></br>
`xxxxxxxuuuxxxzv??????+|---`.               ..-^^||^||^+>???>^-.?r^+>????v`<br></br>
`zxxzzxxxxxxzzzv>>>>>????+|~|~`.             .--~~~~||^>>>>?>^`.`-^^++~-`. <br></br>
`zzzzxxxxxxxzwzw?>>>>>?????>^|||-.           |>^~-~|~+v?+v?>>^-``.`-~-..`-.<br></br>
`zwzxxxxxxxxxzzzv>>>>>>??????>^||^-.        ^?>??>|-+vvv?}vv?|..``--~|~~||.<br></br>
`zzxxxxxxxxxuzzzw?>>>>>>????????+|||-.   . .^^>>>??|+vvvrzv}wv-~~~~------`.<br></br>
`xxxxxxxxxxuuxzzzv>>>>>>?????????>~-~~.   ~?|-|^+>+^+vvrzzr}}+`---``...... <br></br>
`xxxxxxxxxuuuuzwzr>>>>>>>>?????>>>-`-~~  `vr}v?>+++^+v}wwwrv?`............ <br></br>


            </div>,

            <div>

`v?}rrrrrrrrrrrrrrrrr}vvvv}}}}rrrrrrrr}vvvvvvvvvvvvvvvvvvvvvvvvvvvv??^|^|+`<br></br>
`ruyyyyyyuxzzwwzuuuyyuzwrrrrrrwwzzzzxuuxzwr}}rrrr}}rrrrr}}}}rrrr}vvvvvvvvv-<br></br>
`xuuuuuxwr}}}}}}rxuuuuxr}}}}}}}}}}}}}rwwwzzzwr}}}}}}}}}}}}}}}}vv}vvvvvvvv?`<br></br>
`ruuxwrr}}vv?v}}}rzuuuuw}}rrr}}}}}}}}}}}}}}}}rrrvv}}}}}}}}vv}wzxuz}vvvvv>>`<br></br>
-zvrr}vv???????}r}rzuuuzrv???????????????>>?vvvvvvv}}}}}}}vvvvvvwuzvvvvvv}~<br></br>
`r}????????vv???vrrrwxuxr}>????????????????????v}r}}}}}}}}rr}}}}}}rvvvvvrx|<br></br>
.-+?>?vv}rwwzrv???}r}rzxrr??vvvvvvvvv}}}}vv???????v}}}}}}}}}}}}}}}}}}}vvrw-<br></br>
-?`>}vwzzzzzzzz}v???}rrwrr}}xzzzwwwzzzw}>vzrv??>?>}vv}}}}}}}}}}}}vv}}vv}r?`<br></br>
-xr->zrzzzzzzzzzwv???vrrrrrvzwwrwzzwv>|^?wzz}v?>v?r}}}}r}}vvvvvvvvvruurxr}-<br></br>
`uyz^~rrwzzzzzzzzz}???vrr}}?rwzwrv>|^?rxxzz}????wvr}}}}??vvvvvv}vvvvzyuz}}-<br></br>
`ruuu?`?rwzzzzzzzzzwv???vv}v}wv+|^?rxuxwxz}w???r}}r}}}}vvvvv}vvvvvvv}xur}}-<br></br>
-wwuuur|^vwzzzzzzzzzzv??>?v?^|^?wuyuyurxx}r}??vrvrr}}}}rrr}vv?>>?vvvvrwv}}-<br></br>
`xwzuuyu?->wxrwwzzzwwwv>^`+??wuyux?xxrxuv??v}}}v}rrrr}}}}}}rr}v?vvvvvvvvvv-<br></br>
-xxwwwzxu}`~vvvrzwr}}?+|>}w}vyyyyr>wrxuw?v}v??vvvv??>>>>>>>>>>?v??vv??>vvv-<br></br>
.}xzzzzzwwrv>vv}}rv>^?}xyyuuzuuxxzwwxx}|?uv +}}}}v+++>>>>>+++>vvv??>+>+?vv-<br></br>
 ~xzzzzzzzzz}wzzzwv}zwwwwwwwwwwwwwzzxr` .rw.~??v}}v?vvv???>++?v?>++++>>>vv`<br></br>
 .?xzzzzzzzw?vwzzzzzzzzxzzzzzzzzzzzxr`   .`.|++>??vvvww}??>+>>++>>>^++++??.<br></br>
`v>vwzzzz}wzzzzwwzzzxzw}wzzzzzzzzzxr`      .^>??>+>?vvwr??>++>?}r??++++>>>.<br></br>
`v}vvvwzx^-??vrrr}}vv????wxzzzzxxz?.       .^>?>~--|>??r?v>+?rvrrv??^+^+++.<br></br>
`}vv}vvvzz- .>????????????xxxxwv^.         .+>?. `wz?}}r}r}rw?|->r??^^+++>.<br></br>
-ux}vvvvv}r^+????????????vz}>~.            `+??` .?}rwwwwzxuyyu}.}v?+^+>>>.<br></br>
-uyyx}vvvvvvr}v??????????|.                |?}}v??vrwwwwruyyx}r?>zv?++>>>+.<br></br>
-uuuuu}vvvvvvvzwwr}?^``.                   +vwrw}}wrwwwwrw}+-~vvww??++>+++.<br></br>
`xxxwvvvvvvvv?>??>-....                    >?rrzuzuzrwwwww}vrzwv?>++^^>+++.<br></br>
-xzv?vvvvvv?vv?+>+..                      .|?vwwwzxyurwwwwwzw?+++++>~-~|++.<br></br>
`}?vvvvv}xx?vvv.                            ~>?vrrzxxwrwwwwz}~.-|+>>+`...`.<br></br>
.?vvv?vzxxuw?vv^                           . ...-^>vvvv}v?v}v`....`~^^`... <br></br>
`vv?vwxxxxxuvvvv.                        .......`->vvvvvvvvvv. .  ..`|+|?v-<br></br>
`?vrxxxxxxuuzvvv^                       `>~```-~>vvvvvvvvvvv?-~+??}rzxxxuu|<br></br>
`vzxxxxxxxuuyvvv?.                     .>v?^~~+v}rvvvvvv?vvwzxxxxxzzwwrr}}-<br></br>
`xxxxxxxxxuuyz?vv|                     +>|?>-|}}}zrvvvvv?rwwwwr}vv??+|~``. <br></br>
`xxxxxxxxuuuuuvvv?                    `--~---?vv}zrvvvvv?vv?>|~`...     .. <br></br>


            </div>,

            <div>

`v?}r}}}}}}rr}v}}}}}}vv?vvvvvvvv}}}}}}}v?????????????vvvvvvvvvvv?????^|^^+`<br></br>
`rxuxxxxxxzwr}rrzxxxxxr}}}}}}}}}rrrwwxxxww}}}}}}}}}}}rrrwwwwwwww}vv?vvvvvv-<br></br>
`xuxxxzzwr}}}}}}rzxxxxw}v}}}}}}}}}}}}}}rwwzzw}}}}}}}}v}}rrrwwr}}}vvvvvvvv?`<br></br>
`rxzwr}}}}vv?}r}}}wzxxzr}}}}}}}}}}}rrrrrr}}v}rr}vvvvvvv}}}vvrwwzyzvvvvvv>>`<br></br>
-wvrr}v??????>?}}}}wxxxw}v?vvvvvvvvvvvvv??>??vvvvvv}}}}}}}v}}vvvwuw}vvvvv}~<br></br>
`rv?????????????v}}}rzuxrr?vvvvvvvvvv?vv???????v}rr}}rrrrrwwwrrr}rr}vvvvrx|<br></br>
.~>?>??vv}rrw}v???}rrwzxrwvvv}}}}}}v}}}r}vv???>???v}}}}}rrrrrrrrwzzwv???}}-<br></br>
-?`>}vrzwwwwwzw}vv?vrrrzwwr}xzzzwwwzzzw}>vzrv??>?>vv}}}}}rrrwrrr}rr}vv?v}?`<br></br>
-xr->w}wwwwzzzzzw}vv?vrwwww}zwwwwzzwv>^^?wzzvv?+v?r}}}}}}}vvvvvvvvv}zwvwrv-<br></br>
`xuw^~}}wwwzzzzzzz}vv?vrwwr?rwzwrv>|^?wxxzzv>>>>v>?v}}v????vvv}}vvv?}zwrvv`<br></br>
`ruuu?`?}rzzzzzzzzzwvvvv}}}v}wv>|^?wuyxwxz}r???}}v>vwr}vvv?vvvvvvvv??wzvvv`<br></br>
-wwuuxv-^vzxzzzzzzzzzv???vvv^|+?zuyuuxrzx}rrvv}w}v?rr}v}rrrvv?>>?vv??v}?vv`<br></br>
`xwzxzzw>-?wz}wwzzzwww}?+->v?zuuuxvxxrzz}vv}wwr}}?}rv}}}}}}rr}v?vv??v???vv`<br></br>
`zzw}v}}wv`-v}vrzwrr}?+^?}w}}yuuyw?wrxuwv}rv?vvv???>>>>>>>>>>>?v?v??>+^?vv`<br></br>
.}zwwr}vv}v?>}v}rrv>+?rxuuuuxuuxxzwwzx}^?uv >r}}}?+>>>++++^^^+?vv?>^^^^+??`<br></br>
 ~zwwwwwwwww}zzzzwvrzwwwwwwwwwwwwwzzxr`.`vv.^?vv}}v?v?????+|^>??>+^^^^^^??.<br></br>
 .>zwwwwwwwr?}zzzzzzzzzzzzzzzzzzzzzxw-.. .`.>????vvvv}rv??+|^+^+++^|^^^^>>.<br></br>
`?>vwwwwwvrwwwzwzzzzxzw}wzzzzzzzzzxw- ......>???>++??v}}??>+^^>v}>+||||^^^.<br></br>
`vvvvvwzx+-??vrrrrr}vvv??wzzzzzxxzv` ......`>??>---|>??}???>?}v}v?++||~^^^.<br></br>
`}vv}v?vwz~ .>?????????v?vxxxxwv+`  ..`....->>?. `rw?vv}v}v}r?|->}?>|||^^^.<br></br>
-uz}vvvvvv}++?????????vv?vz}?|`   .........-+??` .?v}rrrrwzxxxxv.}??^^++^^.<br></br>
-uyyx}vvvvvvr}vvvvvvvvv??|`.   ............^?v}????v}}}}}xuuzv}?+r?+|^^^^^.<br></br>
-uuuuu}vv?vv??wwwwrv+-``.       ...........>vrrrvvr}}}}}v}v^`-??}}>^||^^^^.<br></br>
`zxxw}}}v?????>???~```..... ..   ..........>?}}wxwxz}}}}}v??v}v?>+|^|~^^^+.<br></br>
-xz}v}rr}vv???>+++`... ....    ............|?v}}rzzux}rr}}v}v+|^|^^^-`-~^+.<br></br>
`rv}rr}}rxx?>>>.                ............|>?vrrzzz}}rr}v}?`.`~|^^^`...`.<br></br>
.?v}}v}zuxuw???|         ..         .......``...`^>vvvvvv???>`....`-~|.... <br></br>
.?>>?rzxzxxuv?>>.       ..           ... .````...`^????????>>.......`|^|>?`<br></br>
.>>vrrrrrrzuz?>?|                       .+~~-~~`|>??>?>>???>>`-^+??vv}}}}}-<br></br>
.?}}}}}}}}rzz?>>>.        ...          .^??^|~+?>?>>>>>>^>??v}}}vvvv?????>`<br></br>
`wwwrr}}}}rzz}>>?~        ...          ^|~>+`-?>>v?>>>>+^???????>>+|~~``.. <br></br>
`wr}}}}rrwwzww>+++                    `---```|>>>v?>>>>+^>>+|-``...   .... <br></br>


            </div>,

            <div>

.^^^^^^^^^>?+^^^^^^^^^^^^^^^^^^^||||||||||||||^^^^^+??????}r??}v??---~|^^|.<br></br>
.+>>++>>>>>>>>>>+++++>>+++++++++>>>>>>>>>>>??>>++++>>?vvvvwywzuu}}?~|^^^^^.<br></br>
`v}?+++++++++++++++++++>>>???????????????????????>>+++>?vvvuuxruzvv^|||||^.<br></br>
`w?+++++++++++++++++>>?????????vvv}}rrr}}vv????????>+^^+>?vwu}vwurv?~|^^^^.<br></br>
`?++++++++++++++>????????vv}rrwwwwwwwwwww}?>+++++++>>>>???vrwvvvxxvw^|^+>?-<br></br>
.^+++>>>++++>>???????v}rrwwwwwwwwwwwwwww}}vv?>++++>?vvvvvvv}vvvvwuzu^|^+>?-<br></br>
.+++++++^+??????vv}rwwwwwwwwwwwwwwwwwwwwr}vvv???>++++>?vvvvvvvvv}uyu^|^^^+.<br></br>
.+++++>??>???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrv+??>++++>?v?vvvvvvxuv~|||||.<br></br>
.^>>>??????}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww^w}-}v???>+++>+?vvvvv}}>~~~~~~.<br></br>
.v}???vv}?vzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv.`` .`~>???>++^+?vvvvvv--~~~~~.<br></br>
`}??v}?^|+vrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?v}rr}> >wv?>++>?vvvvvv>`-~~~~~.<br></br>
`v}wr|``|uyy}vwwwwwwwwwwwwwwwwwwwwwwwwwwwzxyyyyyu>`wr?++>?vvvvvvvv~-------.<br></br>
`wwz>....vzw+>wwwwwwwwwwwwwwwwwwwwwwwwwwxuuyyuuu}-v?+++>>>>>+>vvv?``````--.<br></br>
`wrr+.....```vwwwwwwwwwwwwwwwwwwwwwwwwzuyyyyz}}}^>>^+++^++^+>+>vvv-```````.<br></br>
`v???|...``-?wwwwwwwwwwwwwwwwwwwwwwww}uyyyw?vrrrv?????+^+^.`~>+>vv````````.<br></br>
`??????>+++?wwwwwwwwwwwwwwwwwwwwwwwww}uyz+.+wwwwwww}?++++>-..-+>??..``````.<br></br>
.???????????rwwwwwwwwwwwwwwwwwwwwwwww}x?`-?rwwwwwr?++^++>v?...`+>|.....```.<br></br>
.>>????>???>vwwwwwwwwwwwwwwwwwwwwwwww}+^vwwwwwwr?>++++++>v}+`..`+`....````.<br></br>
.vvvvvvvz}v>?wwwwwwwwwwwwwwwwwwwwwwwww}wwwwwwwv++>>++++^+?vvv?^``.....````.<br></br>
`vvvvvvvuwvv>vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv>^+++++++++++++>????>^~``.```.<br></br>
`vvvvvvvzv?v}?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwr++++>+++>+++>++++++++>>?>+~```.<br></br>
-xuxw}vv>?}vv>?wwwwwwwwwwwrrrwwwwwwwwwwwwwwr++++++++++++++++^^^^|~~~--````.<br></br>
-uuuuuw}?^v?|~~vwwwwwwwww}????^~?wrwwwwwrrwwv++++++++++^...........```````.<br></br>
-uuuuuuxw?|~+>>^vwwwwww}rwv?>-  .vx}www}???v}>+++++++++>~............````` <br></br>
-uuuuuuuux?+>>+~`?}rwwwwwzzr?????vwrr}v?++++++>>+++++++>>`................ <br></br>
-uyuuuuuuuu>`--~~``-~^++>>>>???>+^+>>+++++++++|^+>>>+>>>>^................ <br></br>
.?}wxuuyuuux^|^-``..`..`-`.....    .~^>>>+++++. .`-^+++>>>`............... <br></br>
 ..`+?vvwxuyx^-```..``.`-`.........  .`|+>>++>-   .>++^^>>|............... <br></br>
 ```.->`.-+vzv`..-~```...........  ...  .-^+>>^.  `-`-`.`|^............... <br></br>
 .```````....|``|>?|`.....~|`....  .....  ..~^>` ..   .................... <br></br>
.>>>~~`````..```~^|-``....`-.....  .....     .``.......................... <br></br>
                  .+`..`.~~-`````.....``..`........                ......................... <br></br>


            </div>,

            <div>

.^^^^^^^^^>?+^^^^^^^^^^^^^^^^^^^||||||||||||||^^^^^+??????}r??}v??--~~|^^^.<br></br>
.+>>+>>>>>>>>>+++++++>>+++++++++>>>>>>>>>>>>>>>++++>>?vvvvwywzuur}?~|^^^^^.<br></br>
`v}?++++>>>>>>+++++++++>>>???????????????????????>>+++>?vvvuyx}uzvv^|||||^.<br></br>
`w?+++++>>>+++++++++>>?????????vvv}}rrr}}vv????????>+^^+>?vwu}vrurv?~|^^^^.<br></br>
`?++++++++++++++>????????vv}rrwwwwwwwwwww}?>^++++++>>>>???vrwvvvxxvw^|^+??-<br></br>
.^++++>>++++>>???????v}rrwwwwwwwwwwwwwww}}vv?>>+++>?vvvvvvv}vvvvwuzu^|^+>?-<br></br>
.+++++++^+??????vv}rwwwwwwwwwwwwwwwwwwwwr}vvv???>++++>?vvvvvvvvv}uyu^|^^^+.<br></br>
.+++++>??>???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwww}rv+??>++++>?vvvvvvvvxuv~|||||.<br></br>
.^>>>??????}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww^rv-}v???>+++>+?vvvvv}}+~~~~~~.<br></br>
.v}???vv}vvwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv.``..`->???>++^+?vvvvvv--~~~~-.<br></br>
`}??v}?^|+v}}rwwwwwwwwwwwwwwwwwwwwwwwwwwwww?vrww}> >wv?>++>?vvvvv}>`-~~~~-.<br></br>
`v}w}|``|uuuvvwwwwwwwwwwwwwwwwwwwwwwwwwwwzuyyyyuz>`wr?++>?vvvvvvvv~-------.<br></br>
`wwz>....?zr^>wrrrwwwwwwwwwwwwwwwwwwwwwzxuyyyuuu}-v?+++>>>>>+>vvv?--````--.<br></br>
`wrr+.....-``vrrrwwwwwwwwwwwwwwwwwwwwwzuyyyyz}}v^>>++++^++^+>+>vvv--``````.<br></br>
`v???|...``-vwrrwwwwwwwwwwwwwwwwwwwww}uyyyw?vrr}v??v??+^+^.`~++>vv````````.<br></br>
`??????>++>?wwwwwwwwwwwwwwwwwwwwwwwww}uyw^.>wwwwwww}?++++>-..-++??.```````.<br></br>
.???????????rwwwwwwwwwwwwwwwwwwwwwwww}z?`~?wwwwwwr?++^++>v?...`+>|..``````.<br></br>
.>>????>???>vwwwwwwwwwwwwwwwwwwwwwwww}^^vwwwwwwr?>++++++>v}+`..`+`...`````.<br></br>
.vvvvvvvz}v>?wwwwwwwwwwwwwwwwwwwwwwwwwrwwwwwwrv++>>++++^+?vv??|``.....````.<br></br>
`vvvvvvvuwvv>vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv>^+++++++++++++>????>^~``.```.<br></br>
`vvvvvvvzv?v}?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwr++++>+++>+++>+>>+^^^+>>>>+~`.`.<br></br>
-xxxw}vv+?rv?>?wwwwwwwwrwwr}rrwwwwwwwwwwwwwr++++++++++++++++^^^^|~~~--````.<br></br>
-uuuuxw}?^v>~~~vwwwwwwwrw}????^~?wrwwwwwrrwwv++++++++++^....`......``````` <br></br>
-uuxxxuxr?||+>>^vwwwwww}}wv>>-  .vx}wwz}???vv>+++++++++>~.......`....`````.<br></br>
-uuuxxxxxz?+>>+~`?}rwwwwwwzr?????vw}}}v?++++++>>+++++++>>`................ <br></br>
-uuuuuxxxuu>`--~~``-~^+++>>>??>>+^^>++++++++>+~^^+++++++>^................ <br></br>
.?}wxuxuxxxx^|^-`...`..`-`.....    .~^>>++++++...`-^++++>>`............... <br></br>
 ``~>?vv}zxuz^-```..-`.`-`.........  .`|+>+++>- . .>++^|++~............... <br></br>
.-~-`|>`.-+vwv`..~~```........... ....  .-|+++|...`-.-`.`|^............... <br></br>
                  `-~~~```...`~``|??|`.....~~`....  .....  ..~^+` .  . ....`............... <br></br>
.>>+~|`````..```~||-``....``.....  .....     .``.......................... <br></br>
                  `+`.```|~-``--`.....``..`.......                .  ....................... <br></br>


            </div>,

            <div>

.????>+^^>?????>^^^|||^^^^^||~~~|||^^^++>>>???????????????rr?}}????|-~|^||.<br></br>
`v??>>>>>vvvv?>++>>>?????????>>>>>>+++++++>>>>???vvvvvvvvvzyzyurvv}?~^^^^^.<br></br>
.+++++++?vvv?++>???????????????????????>>+++++++++>>???vvv}uyxzxvvvv|||||^.<br></br>
.++++++>vv?>+^>??????????????vv}}vvvv?????????>>>>>>+^^+??vzu}}urvv}^|^^^^.<br></br>
.+^^+++>?>+++>?????????vv}rrwwwwwwwwwwrr}}vvvvv???>>++>?vvvwzvvxxvwu^|^+>?-<br></br>
.+?++++>>+++??????vv}rwwwwwwwwwwwwwwwwwwwwwww}v?++++??vvvvv}}vvwyxyx||^+>v-<br></br>
.??+++++>+>????v}rwwwwwwwwwwwwwwwwwwwwwwwwrv>++++>?vvvvvv?vvvvv}uyx?~|^^^+.<br></br>
.??>^+++++>?v}wwwwwwwwwwwwwwwwwwwwwwwwww}?+|~+^|>>>>>>>??>vvvvvvzwv+~|||||.<br></br>
.???^++++???}zzwwwwwwwwwwwwwwwwwwwwwwwwwrv?^ `..`~^++++>++vvvvvvvvv--~~~~~.<br></br>
.???^+++???>>??v}wwwwwwwwwwwwwwwwwwwwwwwwr?--|^|` `??>+^^>vvvvvvvv>`-~~~~-.<br></br>
.???+++???^```?wwrwwwwwwwwwwwwwwwwwwwwwwwzzxuyyuv vw}?>^+?vvv??vvv?---~~~-.<br></br>
.???+^??v^`..`zyy}vwwwwwwwwwwwwwwwwwwwwwxuyyyyyx~>zz}?++>vv?>++?vv}>`-----.<br></br>
.???+>???+....`^+`vwwwwwwwwwwwwwwwwwwwxuyyyyuww?+wwwv?^+?v?++>>+>vvv~```--.<br></br>
.????????v|... .`+wwwwwwwwwwwwwwwwwwrxyuyyzvvvv?wwwr?>^>>>+-`|+>+>v}+`````.<br></br>
.????????}w?||^>vwwwwwwwwwwwwwwwwwww}xyyz+~vrwwwwww?>^+++^```.`~+>>v?`````.<br></br>
.????????vwwwwzzzwwwwwwwwwwwwwwwwwww}xu?..?wwwwww}?^+^++++`.....`~+?v`````.<br></br>
.?????????wwwwwwwwwwwwwwwwwwwwwwwwww}v|~?rwwwww}?+++^^+++>~.......`|?`.```.<br></br>
                  `vvvvv????rwwwwwwwwwwwwwwwwwwwwwwwwwr?vwwwwwwr?+++++^^++?v?`........``````.<br></br>
.vvvvvv?w?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++++++++++>v}^..........````.<br></br>
`vvvvvv?zv?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr>++++>++++++^+?vv`.........````.<br></br>
`vvvvvv?rv+vwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++++>+++>+++^>v}^.....````````.<br></br>
`vvvvvv?+v}?rwwwwwwwwwv???v?>?vwwwwwwwwwwwv^++++++++++++++?vv`-~``````````.<br></br>
`vvvvvv??+v^?wwwwwwwwrr???>.  .rwrwwwwvv}rwv+++>>+++++++++>v}|```.````````.<br></br>
`vvvvv?|+^^|+vwwwwwwwrw}?+-````?xwrzwr??????++^~^+>>+++++++?}?..`....`````.<br></br>
`vvvvv>||>>++^>?v}}rwwwwwrwrrr}}v??>>>+++++++++` .`|^+++++++vv`..........` <br></br>
`}vvvv?+|r>~----~```-~~~~~~~~``..   .~>>++>>++>+. ..``|^>>++>}~........... <br></br>
-uz}v?|>>>^~|+|-`...`..`~`............^>+>+^>>>>^.`^+|`.`|+>+?^........... <br></br>
-uuuzv^``.-|^~````..-`.```........... `>>>`.`|+>>~->>+`....`^+|........... <br></br>
-uuuyw+`..~-.``..~~``............ .....|>|    .-^+|````......``........... <br></br>
                  .vxuyx```.....`-+??~``...`|~`.... .....`+. ...  ..-....................... <br></br>
.>++}u^````.````~~~```....``.....  .....`       .. ....................... <br></br>
                  .|``.~+|~```~-`....```..`.......                   ....................... <br></br>


            </div>,

            <div>

.????>+^^>?????>^^^||^^^^^^||~~~|||^^^++>>>???????????????rr?}}????|-~|^||.<br></br>
`v??>>>>>vvvv?>++>>>?????????>>>>>>+++++++>>>>???vvvvvvvvvzyzyurvv}?|^^^^^.<br></br>
.+++++++?vvv?++>???????????????????????>>+++++++++>>???vvv}uyxzxvvvv||||^^.<br></br>
.++++++>vv?>+^>??????????????vv}}vvvv?????????>>>>>>+^^+??vzy}}urvv}^|^^^^.<br></br>
.+^^+++>?>+++>?????????vv}rrwwwwwwwwwwrr}}vvvvv???>>++>?vvvwzvvxxvwu^|^+>?-<br></br>
.+?^+++>>+++??????vv}rwwwwwwwwwwwwwwwwwwwwwwwrv?+++>??vvvvv}}vvwyxyx||^+>v-<br></br>
.??+++++>+>>???v}rwwwwwwwwwwwwwwwwwwwwwwwwrv>++++>?vvvvvv?vvvvv}uyx?~|^^^+.<br></br>
.??>^+++++>?v}wwwwwwwwwwwwwwwwwwwwwwwwww}?+|~+^|>>>>>>>??>vvvvvvzwv+~|||||.<br></br>
.???^++++???}zzwwwwwwwwwwwwwwwwwwwwwwwwwrv?^ `..`~^++++>++vvvvvvvvv~~~~~~~.<br></br>
.???^+++???>+??vrwwwwwwwwwwwwwwwwwwwwwwwwr>--|^|` `??>+^^>vvvvvvvv>`~~~~~-.<br></br>
.???+++???^```?wwrwwwwwwwwwwwwwwwwwwwwwwwzzxuyyuv vw}?>^+?vvv??vvv?---~~~-.<br></br>
.???+^???^`..`zyy}vwwwwwwwwwwwwwwwwwwwwwxuyyyyyx~>zz}?++>vv?>++?vv}>`-----.<br></br>
.???+>???+....`^+`vwwwwwwwwwwwwwwwwwwwxuyyyyuww?+wwwv?^+?v?++>>+>vvv~```--.<br></br>
.????????v|... .`+wwwwwwwwwwwwwwwwwwrxyuyyzvvvv?wwwr?>^>>>+-`|+>+>v}+`````.<br></br>
.????????}w?^~^>vwwwwwwwwwwwwwwwwwww}xyyz+~vrwwwwww?>^+++^`.`.`~+>>v?`````.<br></br>
.????????vwwwwwwzwwwwwwwwwwwwwwwwwww}xu?..?wwwwww}?^+^++++`.....`~+?v`````.<br></br>
.?????????wwwwwwwwwwwwwwwwwwwwwwwwww}v|~?rwwwww}?+++^^+++>~.......`|?`.``` <br></br>
`vvvvv????}wwwwwwwwwwwwwwwwwwwwwwwwwr?vwwwwwwr?+++++^^++?v?`........`````` <br></br>
.vvvvvv?w?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++++++++++>v}^..........```` <br></br>
`vvvvvv?zv?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr>++++>++++++^+?vv`.........````.<br></br>
`vvvvvv?rv+vwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++++>+++>+++^>v}^.....````````.<br></br>
`vvvvvv?+v}?rwwwwwwwwwv???v?>?vwwwwwwwwwwwv+++++++++++++++?vv`-~``````````.<br></br>
`vvvvvv??>v^?wwwwwwwwrr???>.  .rwrwwwwvv}rwv+++>>++++++++++v}|```.````````.<br></br>
`vvvvv?|+^^|+vwwwwwwwrwv?+-````?xwrwwr??????++^~^+>>+++++++?}?.......`````.<br></br>
`vvvvv>||?>++^>?v}}rwwwwwrwrrr}}v??>>>+++++++++` .`|^+++++++vv`..........` <br></br>
`}vvvvv+^r>-`---~```-~~~~~~~~``..   .~>>++>>++>+.  .``~^>>>+>}~........... <br></br>
-uz}v?~>>+^~^+|-`...`..-~`............^>+>+^>>>>|.`^+~..`|+>+?^........... <br></br>
-uuuzv^``.-||~`````.-`.```........... `>>>`.`|+>>~~++^`....`^+|........... <br></br>
-uuuyw^`..~-.``..~~``............ .....|>|    .-|+|````......``........... <br></br>
                  .vxuyx```.....`-+??~``...`|~`.... .....`+. ...   .`....................... <br></br>
.+^+}u^``-`.````-~-```....``..... ......`    ..... ....................... <br></br>
                  .|``.~+|-```~-`....```..`.......                   ....................... <br></br>


            </div>,

            <div>

.???????????>^^>????>^^^^^^|||^^^+>>>>>>+^^||||^^^^+??????rvvrv????~-~~^^|.<br></br>
`vvvvvvv??>>>++?vvv?>>++++>>??????????????????>>++++>?vvvvuxuuu}vv}^|^^^^^.<br></br>
`vvvv??>+++++++?vvv>>++>?????????????vvv??????????>+^^+?vvzyzruwvv}>|||||^.<br></br>
`??>>++++++++++?vv?+++???????vvv}}rwwwwwwwrvv?>>>+>>>>???vruvvxu}wu?~||^^^.<br></br>
.++++++++++++>+>v?++++??vv}rrwwwwwwwwwwr}??>>+>???vvvvvvvv}wvvwuuyu+||^+>?`<br></br>
.+++++++++>>++>+??+++?rwwwwwwwwwwwwwwwr??+++++++>>>>>????vvvvv}uuzv~||^+>v-<br></br>
.++++++++>??>+++>>+++vwwwwwwwwwwwwwwwww}vvv>+?^|>+++++>>>?vvvvvzrv>-||^^^+`<br></br>
.+++++|`|????^+++++++}wwwwwwwwwwwwwwwwwwwwz+^v`+v??>>+++^>vvvvvvvv^-||||||.<br></br>
.++>^`.|zuuw?>++++++>wwwwwwwwwwwwwwwwwwwwr?. . ..`vv?>+++?vvvvvvvvv>-~~~~~.<br></br>
.++~...+yyyz~?++++++?zwwwwwwwwwwwwwwwwwww}?}rrrv -wr?+++?vvvvvvvvvvv>-~~~-.<br></br>
.+>~....~>>~`??^++++}wwwwwwwwwwwwwwwwwwzuyyyyyy?.rw?>++?vvvvvvvvvvvv}>`-~-.<br></br>
.??>`.....``???>+++>wwwwwwwwwwwwwwwwwzxuyuyyuu}`vzv?++?vv?????vvvvvvv}+`--.<br></br>
.????|---|+}wv?v?++vzwwwwwwwwwwwwwwwzuyyyyz}}v+vzr?>+???>~~^^++>>?vvvvv|`-.<br></br>
.??????rrwwwwrvrr++rwwwwwwwwwwwwwwwrzyyyw??}rrrwwv?++>++^..````-~|^>?vvv-`.<br></br>
.??????wwwwwwwrwzv?wwwwwwwwwwwwwwww}xyr^ ^rwwwwwr>^^+++++.``````````-~+??`.<br></br>
.??????rwwwwwwwwwwwwwwwwwwwwwwwwwww}z>.~?wwwwwwv>^^++>??>.```..```````..-` <br></br>
.?vv?>?}wwwwwwwwwwwwwwwwwwwwwwwwwww}?+vwwwwwwv>++^^++>v}?.........```````` <br></br>
.^?vrv?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv>+++++++++v}?.............```` <br></br>
.^>^+v??wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr?+++++++++>+?vv^............```` <br></br>
`????x}?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr>+++++++>++++>?vv|...........````.<br></br>
`v?vzwz?vwwwwwwwwwwvvvvv?v}rwwwwwwwwwwwwv++>++>>+++>++++>vv|......````````.<br></br>
`v?vrvv?>rwwwwwwwww????| ..`rwrwwwwwrrwwr>+++>+^+>>+>++++>vv|`-~``````````.<br></br>
`vv?+?+??vwwwwwwwrwv>+-..   vyu}www}???v}v+++++|`~^>>++++++?v^```.````````.<br></br>
`vvv??>^^|?vv}rrwwww}vvv????vwwrwr}v>>>>++++++>+. .`|+>>>>>+>v^......`````.<br></br>
`xzr}+>|^?+^^^|-~~|^>+>>>>>>++|~``.`~+>>+++>+++>- . ..`----```~~.........` <br></br>
-uuuuzv^+r+-`--~~`..`.........    .. .-|+>>>>>>>+..``..................... <br></br>
-uuuuyr>^||~^+|``..``..-~`..`........   .-^+>>>+>^-^>~.................... <br></br>
-uuuuuy?.`~|~-``````~`.```.............    .-|+>>>|^+|`................... <br></br>
`zuyyuuu~.~-.``.`|-``............ ....... .   .`|+>-.``................... <br></br>
.|?vrzuy}.....`~>?>~``...`^~`.... ......     .   .`~...................... <br></br>
                  .+|~`~|?r|``````--````...```..... ......     .....  ...................... <br></br>
                  .|-`.``|````~``....```..`.......                   ....................... <br></br>


            </div>,

            <div>

.???????????>^^>????>^^^^^^|||^^++>>>>>>+^^||||^^^^+??????rvvwv????~-~~^^|.<br></br>
`vvvvvvv??>>>++?vvv?>>++++>>??????????????????>>++++>?vvvvuxuuu}vv}^|^^^^^.<br></br>
`vvvv??>+++++++?vvv>>++>?????????????vvv??????????>+^^>?vvzyzruwvv}>|||||^.<br></br>
`??>>++++++++++>vv?+++???????vvv}}rwwwwwwwrvv?>>>>>>>>???vruvvxu}wu?~||^^^.<br></br>
.++++++++++++++>v?+++^??vv}rwwwwwwwwwwwr}v?>>+>???vvvvvvvv}wvvwuuyu+||^+>?`<br></br>
.+++++++++>?++++??+++?rwwwwwwwwwwwwwwwr??++++++++>>>>????vvvvv}uuzv~||^+>v-<br></br>
.++++++++???>+++>>+++vwwwwwwwwwwwwwwwww}vvv>+?^|>+++++>>>?vvvvvzrv>-||^^^+`<br></br>
.+++>+|`|????^+++++++}wwwwwwwwwwwwwwwwwwwwz+^v`+v??>>+++^>vvvvvvvv^-||||||.<br></br>
.++>^`.|zuuw?>++++++>wwwwwwwwwwwwwwwwwwwwr?. . ..`vv?>+++?vvvvvvvvv>-~~~~~.<br></br>
.++~.. +yyyz~?++++++?wwwwwwwwwwwwwwwwwwww}?}rrrv -wr?+++?vvvvvvvvvvv>-~~~-.<br></br>
.+>~....~>>~`??^++++}wwwwwwwwwwwwwwwwwwzuyyyyyy?.rw?>++?vvvvvvvvvvvv}>`-~~.<br></br>
.??>`.....``???>+++>wwwwwwwwwwwwwwwwwzxuyuyyuu}`vzv?++?vv?????vvvvvvv}+`--.<br></br>
.????|---|>}wv?v?++vzwwwwwwwwwwwwwwwzuyyyyz}}v+vzr?>+???>~~^^++>>?vvvvv|`-.<br></br>
.??????rrwwwwrvrr++rwwwwwwwwwwwwwwwrzyyyw??}rrrwwv?++>++^..````-~|^>?vvv-`.<br></br>
.??????wwwwwwwrwzv?wwwwwwwwwwwwwwww}xyr^ ^rwwwwwr>^^+++++.``````````-~+??` <br></br>
.??????rwwwwwwwwwwwwwwwwwwwwwwwwwww}z>.~?wwwwwwv>^^++>??>.```..```````..-` <br></br>
.?vv?>?}wwwwwwwwwwwwwwwwwwwwwwwwwww}?+vwwwwwwv>++^^++>v}?.........```````` <br></br>
.^?v}v?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv>+++++++++v}?.............```` <br></br>
.^>^+v??wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr?+++++>+++>+?vv^............```` <br></br>
`???vx}?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr>+++++++>++++>?vv|...........```` <br></br>
`v?vzwz?vwwwwwwwwwwvvvvv?v}rwwwwwwwwwwwwv++>++>>++>>++++?vv|......````````.<br></br>
`v?vrvv?>rwwwwwwwww????| ..`rwrwwwwwrrwwr>+++>+^+>>+>>+++>vv|`-~``````````.<br></br>
`vv?>?+??vwwwwwwwrwv>+-..   vyu}www}???v}v+++++^`~^>>++++++?v^````````````.<br></br>
`vvv?>>^||?vv}rrwwww}vvv????vwwrwr}v>>>>++++++>+. .`|+>>>>>+>v^.....`````` <br></br>
`xzr}>>|^?^^^^|-~~|^>+>>>>>>++|~``.`~+>>+++>+++>-    .`----```~~.......... <br></br>
-uuuuzv^>r+-`--~~`..`.........    .. .-|+>>>>>>>+```-`.................... <br></br>
-uuuuyr>^||~++|``..``.`~~`..`........   .-^+>>++>^`^>-.................... <br></br>
-uuuuuy?.`~|~-``````~`.``..............    .-|+>>>~^^|.................... <br></br>
`zuuyuuu~.~`.``.`^-``............ .........   .`|+>-.`.................... <br></br>
.|?v}zuy}.....`~>?>~``...-^~..... ......   ...   .`~...................... <br></br>
                  .^|--~|?r|```````````....```............   .......  ...................... <br></br>
                  .|~````~```-~``....``...`.......                   ....................... <br></br>


            </div>,

            <div>

.??????+^>??????>^????????^^^^^^^>>>>>>>>>>>>>^^||^+????rrrrv?????`~--~|^|.<br></br>
`vvv??>>+?vvvvvv>+?vvvvvvv>>+>?????????????????????>>?vvxyrzx}vv}v~||^^^^^.<br></br>
`?>>++++++?vvvv>+++?vvvvv?>+>???vvv}rrr}}vvv???????>++>?wrvwuv}wu?-~|||||^.<br></br>
.+++++++++>vvv>++++>vvvvv?++?v}rwwwwwwwwwwwwwrv???+++>?vvvvwuwuyu?|-~|||^^.<br></br>
.+>++++++++?v?++++++?vvvv?+>wwwwwwwwwwwwwwwww}??>^^>?vvvvvvryyuz}}v?||^^+>`<br></br>
.+>++++~~+++>>++>++>+?vvv?+?wwwwwwwwwwwwwww}v?>>^+?v}vv??}vwuz}vvvvvv>^^>v-<br></br>
.+>>+>^`r?++++++?++>++?vv>+}wwwwwwwwwwwwwwz-+>`+>????>>+?vv}rvvvvvvvvv?^^+`<br></br>
.++>+>~.zyv+++++??++>+>?v>?wwwwwwwwwwwwwwr? `|^>>^+++^+^?vvvvvvvvvvvvvvv^|.<br></br>
.++>+>`.`+>-^+++???^+++>?+vwwwwwwwwwwwwwr>^`~+>~-?v}?>^+>vvvvvv?>>???vvvv^.<br></br>
.++>>+....`-?>+>v??>^+++>+rwwwwwwwwwwwwxuuuzzx}.?zwwv?++>vvvvvv?^^+++>?vvv`<br></br>
.+++>^``-|>}zr?+rv??+++++?zwwwwwwwwwwzuyyyyyyx~?zwww}?^+>vvvvvvv----~~|^>v~<br></br>
.++>+>rrrwwwwwwvrw???>++^vwwwwwwwwwwuyyyyuzr}??zwwww?^^+>vvv>?vv---------~.<br></br>
.++>+?zwwwwwwwwwww}?v}+++wwwwwwwwwrzyyyu}?v}}rwwwwv>++++>vvv+?v?`--`````-- <br></br>
.++>+?zwwwwwwwwwwwwv}zv^?zwwwwwwww}xyuv`|rwwwwwwv>++>?+^>vv>>?v?`--```````.<br></br>
.++>+?zwwwwwwwwwwwwwwwz?vwwwwwwwww}ur~.|vwwwwwv>^++>v}v^+v^->>v+``````````.<br></br>
.?^++?wwwwwwwwwwwwwwwwwwwwwwwwwwww}v|>}wwwwwv>+++++?v}}|>+..~>v|.````````` <br></br>
.v?+>vwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwwwwwwv>+++>+++?vvv`~.`..~v`..```..``` <br></br>
.vv+vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}>++++++++>vv}?.......~.......```` <br></br>
`vv|vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv+++++++++++vv}>...............```` <br></br>
`vv>?wwwwwwwwwww???v???vrwwwwwwwwwwwwww>+>++++>>>++?v}+...............```` <br></br>
`r}vv}wwwwwwwwrr???>. ...^wrrwwwwwwwwww?++>++++>+++?v}+...........````````.<br></br>
-ur?v?rwwwwwwr}r>+~.     |yywrwwwrv?v}rw?-|>+++++>++v}>..``..`--``````````.<br></br>
-u?|??>?vvvrwwww}v???>++^+wzxrwwwr??????>-.~++++++>+?}?.`....``-`````````` <br></br>
-r-^}>>||~++>>>+>>??????vv????>+^>>++++++~  `+>>+++++vv`.......`....`````` <br></br>
`+^>???|+v+^^^~``````.......     .|>>>>+>|.. .~+>>+++>v|.................. <br></br>
 `+^|r}^?}+-`~-~-`.```.............-+>>+>^.    .-|++++>+.................. <br></br>
 .`-^|`>|~~|++~``..``.`~|`..`........~+>++. ....  .+^^+^.................. <br></br>
 .``|?+```~|-```````~..``...........  .|>>. ..... -+||.-`................. <br></br>
                  ..``~-..`~`.`..`^-``............ .... .`^- .........`.................... <br></br>
.|^|````......`|??>-``...-^~............ ..  ..... ....................... <br></br>
                  .|~--``---````.``````.....`.............   ............................... <br></br>
                  .^~``-~|```-~``....``..``.......                   ....................... <br></br>


            </div>,

            <div>

.??????+^>??????>^????????^^^^^^^>>>>>>>>>>>>>^^||^+????rrrrv?????`~--~|^|.<br></br>
`vvv??>>+?vvvvvv>+?vvvvvvv>>+>?????????????????????>>?vvxyrzx}vv}v~||^^^^^.<br></br>
`?>>++++++?vvvv>+++?vvvvv?>+>???vvv}rrr}}vvv???????>++>?wrvwuv}wu?`~|||||^.<br></br>
.+++++++++>vvv>++++>vvvvv?++?v}rwwwwwwwwwwwwwrv???+++>?vvvvwuwuyu?|-~||^^^.<br></br>
.+>++++++++?v?++++++?vvvv?+>wwwwwwwwwwwwwwwww}??>^^>?vvvvvvryyuz}vv?^|^^+>`<br></br>
.+>++++~~+++>>++>++>+?vvv?+vwwwwwwwwwwwwwww}v?>>^+?vvvv??}vwuz}vvvvvv>^+>v~<br></br>
.+>>+>^`r?++++++?+++++?vv>+}wwwwwwwwwwwwwwz-+>`+?????>>+?vv}rvvvvvvvvv?^^>`<br></br>
.++>+>~.zyv+>+++??++>+>?v+?wwwwwwwwwwwwwwr? `|^>>^+++^+^?vvvvvvvvvvvvvvv^|.<br></br>
.++>++`.`+>-^+++???^+++>?+vwwwwwwwwwwwwwr>^`~+>~-?v}?>^+>vvvvvv?>>???vvvv^.<br></br>
.++>>+....`-?>+>v??>^+++>+rwwwwwwwwwwwwxuuuzzx}.?zwwv?++>vvvvvv?^^+++>?vvv`<br></br>
.+++>^``-|>}zr?+rv??+++++?zwwwwwwwwwwzuyyyyyyx~?zwww}?^+>vvvvvvv----~~|^>v-<br></br>
.++>+>rrrwwwwwwvrw???>++^vwwwwwwwwwwuyyyyuzr}??zwwwr?^++>vvv>?vv---------~.<br></br>
.++>+?zwwwwwwwwwww}?vr+++wwwwwwwwwrzyyyu}?v}}rwwwwv>+++^>vvv+?v?`--`````--.<br></br>
.++>+?zwwwwwwwwwwwwv}zv^?zwwwwwwww}xyuv`|rwwwwwwv>++>?+^+?v>>?v?`--``````` <br></br>
.++>+?zwwwwwwwwwwwwwwwz?vwwwwwwwww}ur~.|vwwwwwv>^++>v}v^+v^->>v+``````````.<br></br>
.?^++?wwwwwwwwwwwwwwwwwwwwwwwwwwww}v|>}wwwwwv>+++++?v}}|>+..~>v|.````````` <br></br>
.v?+>vwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwwwwwwv>+++>+++?vvv`~.`..~v`..```..``` <br></br>
.vv+vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}>++++++++>vv}?.......~.......```` <br></br>
`vv|vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv+++++>++++>vv}>...............```` <br></br>
`vv>?wwwwwwwwwww???v???vrwwwwwwwwwwwwww>+>++++>>>++?v}+...............```` <br></br>
`r}vv}wwwwwwwwrr???>. ...^wrrwwwwwwwwww?++>++++>+++?v}+...........````````.<br></br>
-ur???rwwwwwwr}r>+~.     |yywrwwwrv?v}rw?-|>+++++>++v}>..``..`--``````````.<br></br>
-u?|??>?vvvrwwww}v???>++^+wzxrwwwr??????>- ~++++++>+?}?.`....`--`````````` <br></br>
-r-^v>+|~|++>>>+>>??????vv????>+^>>++++++~  `+>>+++>+vv`.......`...``````` <br></br>
`+^>???|>v^^||~-`````.......     .|>>>>+>|.. .~+>>+++>v|..............``.` <br></br>
 `+||}v+?}+`-~~~-`.```.............-+>++>^.    .-|++++>+.................. <br></br>
 .`-+|`+~~~^++~``..``.`~~`..`........~+>++.  ...  `+^++^.................. <br></br>
 .``~?+```|~-````-.`~...`...........  .|>>. .... .|+|^.``................. <br></br>
                  ..``-`..`-`.`..-^-`............. .... .`^- .........`.................... <br></br>
                  .^+|````.....``|??+-``...~^-............ ..  ..... ....................... <br></br>
                  .~-`-``---````.``````.....`............................................... <br></br>
                  .+|`-~~~```~~``....``..``.......                   ....................... <br></br>


            </div>,

            <div>

.^^^^^^^^^>?????????????????>^^^^^|^>>>>>>>>>>>>>+||~^>?}rvr??vrwrv???????`<br></br>
.>>>>>>>>+?vvvvvvvvvvvvvvvvv?>>>++???????????>>>>++++>>?ruuyzxyuz}vvvv}v?>.<br></br>
.>++++++++?vvvvvvvv?>>?vvvvvv?+>++?v}}v?>+++++>>>??vvvvvvxuxyuwvvvvvv?+|||.<br></br>
.+>+++++++>vvvvvvv?>++++??vv}v>>>+?www}v?>>>++>>>?????vvvwxrx}vvvvv>|~||^^.<br></br>
.>?^^+++++>vvvvvv?++++>?>+>?v}?>>+?wwwrv??????>>++++++??v}r}}}}v?+--|||^++`<br></br>
.??.->++>++?vvvv?+++^>????>++?v>>+?wwwwrrr}>}v>????>++v?vvv}}v?|---~|||+>v~<br></br>
.??`.^>+>>+>vvv?++++v}??????>>+>>+>wwwwwww?-}^+wr}?>++?vvvvvv>-`--~~||^^+>`<br></br>
.??>``+>>>>>vv?>+++vwww}v????}v>++>wwwwwv|-`-``.|}?>++?}vvvvvvv?>|~~~||^^^.<br></br>
.????+^+>>>+?v>+++?wwwwwwrv??vwwv>>wwwwzwzxuzx? ?}?++>vvvvvvvvvvvvv>^~~|||.<br></br>
.??????^++++>>+++?wwwwwwwwwrv}wwzwvwwzuyyyyyuw`>zv?++>vv??vvvvvvvvvvv?>^~-.<br></br>
.??????>++>>>+>+?wwwwrrwwwwwwwwwwwwzxyyyyyuxz^?zw??+>?v?+>?vvvvvvvvvvvvv??`<br></br>
.?}?????>+>>>>+>rwwwwwrwwwwwwwwwwwwuyyyyzr}}v}wwr>^^>>>+>+>>>>>>>>>>>>+^||.<br></br>
.?r}v???}>>>>>+vwwwwwwwwwwwwwwwwwrwyyuv>vrwwwww}>++++++>>>>>>>>>++^|~````-.<br></br>
.vrrr}??wv+>>+?zwwwwwwwwwwwwwwwww}xy}-.+rwwwwr?+++^++>>+^^^^||~~-`````````.<br></br>
.vwwwwrvww?++>wwwwwwwwwwwwwwwwwww}x>~>}wwww}?+++++^++>>>-..``````````````` <br></br>
.vwwwwwwwww?+vwwwwwwwwwwwwwwwwwww}rvwwwww}?+++>++++++++>+`.``````````````` <br></br>
.vwwwwwwwwwrvwwwwwwwwwwwwwwwwwwwwwwwwwwr?+++++>+++>?>|`|>~.......````..``` <br></br>
                  .vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++++++++++?vv~..-^`...........```` <br></br>
 ?wwwwwwwwr????v>>?vv}wwwwwwwwwwwwwww?++++++>>++++?}v`................````.<br></br>
.?wwwwwwrr}???>~. ....^wwrwwwwwwwwwww?+++>>+++>+++v}^.................```` <br></br>
.??rwwwwww}>^-. ....  `xywrwwwwwrrrwwv++>~^++++++>vv..............```````` <br></br>
.^+??>>??v}vvvvv??>^|~`?xurwwww}???vv}?+|.~>+++++>v|.....``..`--``````````.<br></br>
 -|^??+??^|~+>????vvvvvvvvvvvvv?>>>>+++^. ->+++++??....`.....`--``````````.<br></br>
.~-+v++||^++>>+|--~|`..```....`+>++++++. .`+++++>v`............`...``````` <br></br>
.~>>?v?|>v^^||~--``-`....`.....`>>>+++` ...|>+++?~ ...................```` <br></br>
 `+~|vv+?v^`-~~~-..```......... ->>+>|.....~>+++~..```.................... <br></br>
 .`~>|`^--~^+^-``..``.`|~`..`... ~>>+`.....`+>+` .->+-.................... <br></br>
 .``~?^```|~`````-.`-........... .~>~ ......++`  .^^|~.................... <br></br>
                  .```-`..`-`.`.`-^-`............. .~.  .....|` ........................... <br></br>
.+>|-```.....``^??+```...~^-...... . ........ ............................ <br></br>
                  .--`-`-~--````..``.``.....`............................................... <br></br>
.>^-~~~-.``~~``....``..`.......           .        ....................... <br></br>


            </div>,

            <div>

.^^^^^^^^^>?????????????????>^^^^^|^>>>>>>>>>>>>>+|||^>?vrvr??vrwrv???????`<br></br>
.>>>>>>>>+?vvvvvvvvvvvvvvvvv?>>>++???????????>>>>++++>>?ryuyzxyuw}vvv}}v?>.<br></br>
.>++++++++?vvvvvvvv?>>?vvvvvv?+>++?v}}v?>+++++>>>??vvvvvvxuxyuw}vvvvv?+|||.<br></br>
.+>+++++++>vvvvvvv?>++++??vv}v>>>+?www}v?>>>>+>>>?????vvvrxrx}vv}}v>|~||^^.<br></br>
.>?^^+++++>vvvvvv?++++>?>+>?v}?>>+?wwwrv??????>>++++++?vv}rvvv}}?+--|||^++`<br></br>
.??.->+++++?vvvv?++++>????>++?v>>+?wwwwrrr}>}v>????>++v?vvvvvv?|---~|||+>v~<br></br>
.??`.^>++++>vvv?++++v}??????>>+>>+>wwwwwww?-}^+rr}?>++?vvvvvv>-`--~~||^^+>`<br></br>
.??>``+>>>>>vv?>+++vwww}v????v}?++>wwwwwv|``-``.|}?>++?}vvvvvvv?>|~~~|||^^.<br></br>
.????+^++++>?v>+++?wwwwwwrv??vwwv>>wwwwzwzxuzx? ?}?++>vvvvvvvvvvvvv>^~~|||.<br></br>
.??????^++++>>+++?wwwwwwwwwrv}wwzwvwwzuyyyyyuw`>zv?++>vv??vvvvvvvvvvv?>^~-.<br></br>
.??????>++++++>+?wwwwwwwwwwwwwwwwwwzxyyyyyuxz^?zw??+>?v?+>?vvvvvvvvvvvvv??`<br></br>
.?}?????>+>++>+>rwwwwwwwwwwwwwwwwwwuyyyyzr}}v}wwr>^^>>++>>+>>>>>++>>>>+^||.<br></br>
.?r}v???}>>++>+vwwwwwwwwwwwwwwwwwrwyyuv>vrrwwww}>++++++>>>>>>>>>++^|-````-.<br></br>
.vrwr}??wv++>+?zwwwwwwwwwwwwwwwww}xy}-.+rwwwwr?+++^++>>+^^^^||~~-`````````.<br></br>
.vwwwwrvww?++>wwwwwwwwwwwwwwwwwww}x>->}wwww}?+++++^++>>+-..``````````````` <br></br>
.vwwwwwwwww?+vwwwwwwwwwwwwwwwwwww}rvwwwww}?+++>++++++++>+`.``````````````` <br></br>
.}wwwwwwwwwrvwwwwwwwwwwwwwwwwwwwwwwwwww}?+++++++++>?>|`|>~........```..``` <br></br>
.vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?+++>+++++++?vv~..-^`...........```` <br></br>
.?wwwwwwwwr????v>>?vv}wwwwwwwwwwwwwww?+++>>++>++++?}v`................````.<br></br>
.?wwwwwwrr}???>~.  ...^wwrwwwwwwwwwww?++>>>+++++++v}^.................```` <br></br>
.??rrrwwww}>^-. ..    `xyzrwwwwwrrrwwv++>~^>+++++>vv`.............```````` <br></br>
.|^>?>>??v}vvvvv??+^|~`?xurwwww}???vv}?+| |>>+++^>v|.....``.``--``````````.<br></br>
 -|+??+??^|~+>????vvvvvvvvvvvvv?>>>++++^. ->+++++??....`.....`--``````````.<br></br>
.~->v++||+>>>>^|--~~`..``-....`++++++++.  .+++++>v`............`...``````` <br></br>
.~?>?}?|??||||-~-``-`.`..`.....`>>>+++` ...|>+++?~ ...................```` <br></br>
 `^~|v?+?v^`-~~~`..```......... ->>+>|.....~>+++~ .``..................... <br></br>
 .`|>|`^`-~^+^-``..``.`|~`..`... ~>>+`...  `+>+` .|>+`.................... <br></br>
 .``~?|``-|-`````-.--........... .~>~ .... .++`  .|^|-.................... <br></br>
                  .`````..`-..`.`|+-`............. .~.  .....^` ........................... <br></br>
.+>~-```....``-+??^```...|^-...... . ........ ............................ <br></br>
                  .```-`~--````......``..................................................... <br></br>
.>^-||-`.``~-``...``...`.......          ..        ....................... <br></br>


            </div>,

            <div>

.^^^^^^+????????????????????+^^^^^^+>+^^|||^^^^^++>>>???vw}}v?vwv~----~|^^.<br></br>
.>>>>>>>?vvvvvvvvvvvvvvvvvvv?>+>???vvv??>++>>>??vvvvvvvv}ywzxruyu?~~|||^+^.<br></br>
.>>>+++>+>?vvvvvvvvvvvvvvvvv>++?v}rwwr}v??>>+++>>>???vvv}z}}uxyur}?||||||^.<br></br>
.+>++?+^>++>vvvv?>vvvvvvvvvv>+>rrrrwwwr}}vv??>>+^^+++>vvv}}vzyuwvvv?~|||^^.<br></br>
.++^>?-.|+++>?vv>>>vvvvvvvv?>+>rrrrwwwwwwww}}}???>+++?vvvvvvruzvvvvv?||^++.<br></br>
.+++??`.`|>>>>??>++>?vvvvvv?++?wrrrwwwwwwwv?zv?w}?>+>v?vvvvv}z}vvvvvv?|^>?-<br></br>
.+>???>..`-+>>>>>+++>vvvvvv?++vrrrrwwwwwr?.~+.>}}?+>??vvvvvvv?vvvvvvvv>^+>`<br></br>
.+?rv???~`.~+>>>+++>>?v}vvv>+>wrrrrwwwwv^~|||^. ^++?vvvvvvvvv+>???vvvvv+|^.<br></br>
.>}rrv??????++>++>>+>>>v}}v++?zwwrrwwwzxxuyuuw.^++?vvv?>?vvvv^-~|+???vvv^|.<br></br>
.?rrrrrv?????>+++>>>>>>>v}v>+vwwwwwwzuyyyyyyw-+?>?v?>++>?vvvv?~---~|+??v?~.<br></br>
.vrrrrrrv????v?+>>>??>>>>??>>wwwwwzxyyyyuxzz>??>>?+++>>+?vvvvv+-~~~-~~|>v>.<br></br>
`rwwrrrrr}???vr?+>>v??>>>>>+vwwwwruyyyu}v}vrw?>>>+++++>+?vvvvv?---------~+.<br></br>
`rwwwrrrrrrv?vrw?+?w}??+>>>>wwwwwryyx?~?rwwwv??>++++++++?vvvvvv^`--`````-`.<br></br>
`rwwwwwrwwrrvvrww>vww}??>>>vwwwwrzu?`.>rwwwr?>^+>?>+>+++>?vvvvv?``````````.<br></br>
`rwwwwwwwwwrr}www}}www}r?+>wwwww}x?^?rwwwwv>+++++?v^|+>>++?v?vvv-````````` <br></br>
`rwwwwwwwwwrrwwwwwwwwwwwr>}wwwwwrwwwwwwwv>++>+>++>v?``~^>+>?+>vv|.```````` <br></br>
`rwwwwwwwwwwwwwwwwwwwwwww}wwwwwwwwwwwwv>++++++++++?v>...`~^+>`^v>.```..``` <br></br>
`rwwwwwwww}v}}}}rwwwwwwwwwwwwwwwwwwwr?+++++++++++^>?v^.....`|-.~?``...```` <br></br>
`rwwwwwww}????|.`-|^?vrrwwwwwwwwwwww?+++++>++++++++>?v~.........``....````.<br></br>
`vrwwwwrrv?>^`.....  -xzrwwwwwwwwwwwv+++++++++>>+>+++??`..............```` <br></br>
.>?rwrr}}v>~``....   `xyurwwwwrv}rwww>++>++++++++>>>^^??...........``````` <br></br>
.~^>?>>?^?r>>????>>+^^}zxzwwwwr????vv?^-|+>>+++++>>++^^?+.`.``````````````.<br></br>
 -|+v?+>?^||^++^||^^^++>>???+|~`..`^+++`..~++>>++>>+++^^?~...`--``````````.<br></br>
.~->v^+||+>>>>^~--||`...``..........`^>-   .`-|^+>++^^^^^>.....`...``````` <br></br>
 ~?>?r?~??|~~|-~-``-`.`..`............`~...   ..``~|^^++^^~...........```` <br></br>
 `^-|??>??^`~|~~`..```..`............. ..... ......----|^+|............... <br></br>
 .`|>^`|``~++|-``..``.`|~`..`......... .  ........~>+`...`|`.............. <br></br>
                  .``~?|.`-|``````-.--.............................~||-.................... <br></br>
                  .````...``..```|+-`.................. ................................... <br></br>
.>>~-````...``-+??|```..`|^`..... ........... ............................ <br></br>
                  .```-`~~-````......``..`.................................................. <br></br>
                  .?^-^|-`.``|--`...`....`.......          ..        ....................... <br></br>


            </div>,

            <div>

.^^^^^^+????????????????????+^^^^^^+>+^^|||^^^^+++>?????vw}}v?vwv~-----~||.<br></br>
.>>>>+>>?vvvvvvvvvvvvvvvvvvv?>+>???vvv??>+++>>??vvvvvvvv}ywzxruyu?~~|||^^^.<br></br>
.>>>+++>+>?vvvvvvvvvvvvvvvvv>++?v}rwww}v??>>+++>>>??vvvv}z}}uxyur}?||||||^.<br></br>
.+>++?+^>++>vvvv?>vvvvvvvvvv>+>rwwwwwwrr}vv??>>+^^+++>vvvvvvzyuwvvvv~||||^.<br></br>
.++^>?-.|+++>?vv>+>vvvvvvvv?>+>wwwwwwwwwwww}}}???>+++?vvvvvvryzvvvvv?||^^+.<br></br>
.+++??`.`|+>+>??+++>vvvvvvv?>+?wwwwwwwwwwzv?zv?w}?>+>v?vvvvv}x}vvvvvv?|^>?-<br></br>
.+>???>..`-+>>++++++>vvvvvv?++vwwwwwwwwwr?.~+.>v}?+>??vvvvvvvvvvvvvvvv>^+>`<br></br>
.+vwv??>~`.-+>+++++>+>vvvvv>+>wwwwwwwwwv|~|||^. ^++?vvvvvvvvv+>??vvvvvv+|^.<br></br>
.>}ww}??????>+>++>+^++>?}}v>+?wwwwwwwwzxxuyuur.^++?vvv?>?vvvv^-~|+>?vvvv^|.<br></br>
.?rwwwrv?????>+++++>+++>?}?>+vwwwwwwzuyyyyyyw->>+?v?>++>?vvvv?~---~|+>?v?~.<br></br>
.}wwwwww}????v?+>++??+++>??+>wwwwwzuyyyyuxzz>v?^>>+++>>+?vvvvv+--~~~--|>v>.<br></br>
`rwwwwwwwr???vw?++>v??++++>+vwwwwruyyyu}vvvrw?+>++++++++?vvvvv?---------~+.<br></br>
`rwwwwwwwwrv?vww?^>w}??+>>>>wwwwwryyx?-?rrwwv??>++++++++?vvvvvv^`-----`--`.<br></br>
`rwwwwwwwwwwvvwww>vww}??+>+vwwwwrzu?``>rwwwr?>^+>?>+>+++>?vvvvv?``````````.<br></br>
`wwwwwwwwwwwwrwww}}www}r?+>wwwww}x?+?rwwwwv>+++++?v^|+>>++?vvvvv-````````` <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwr>}wwwwwrwwwwwwwv>++++>++>v?``~^>+>?+?vv|.```````` <br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwvwwwwwwwwwwwwv>++++++++++?v>...`~^+>`^v>.```````` <br></br>
`rwwwwwwww}v}}}}rwwwwwwwwwwwwwwwwwwwr?++>++++++++^>?v^.....`|-.~?`....```` <br></br>
`rwwwwwww}????|.`-|^?vrrwwwwwwwwwwww?++++++>+++++++>?v~.........``....```` <br></br>
`v}wwwwrrv?>^`....   -xzrwwwwwwwwwwwv++++++>>>>>>>+++?v`..............```` <br></br>
.>?rr}r}}v>~``....   `uyurwwwwrv}rwww>++>>+++++>>>>>^^??...........``````` <br></br>
.~|+?>>?+?}+>>???>>>+^}xxzwwww}????vv?^-|+>+++++>>++>+^v>.`.``````````````.<br></br>
 -|>v?+>?|||+++^||^^^+>>>???+|~`..`^+++`..~++>>+>+++++++?~...`~-`````````-.<br></br>
.~~?v^+||>>>>>^~--~|`...``..........`^>- ....-|^+>>>++>++>.....`...``````` <br></br>
 ~?+?w?~??|~~~-~```-`.`...............`~..     .``~^++>>++~...........```` <br></br>
 `|-|??>??|-~|~-`..```..`............. ....  .....`-~|~^++^............... <br></br>
 .`|?^-~``~++|```..``.-|~...`........  . .........`>^....-^`.............. <br></br>
                  ```~?~..-|```..--.-`............................ `-~`.................... <br></br>
                  `-````..``..``-^+-`.................  ................................... <br></br>
.>>~~````...``-+?>|```..`^|`.............................................. <br></br>
                  `````|--````......``..`.................................................. <br></br>
.>|~^|``.``~~-`...`............          ..        ....................... <br></br>


            </div>,

            <div>

.^^^^^+?????????????????????+^^^|^+>>>>>++++^^||^^^++>??}wrvr?vrrr?----~|^.<br></br>
.>>>>>>?vvvvvvvvvvvvvvvvvvvv?>++?vv}}rr}v?>>+>>>??vvvvvvzywruxyyuzv?^|^^^^.<br></br>
.+>>+++>??vvvvvvvvvvvvvvvvvv>++?rrwwrv??>+++>>????vvvvvvrzvvxyuxrvv??>|||^.<br></br>
.+>++>++?vvvvvvvvv?>?vvvvvvv>++vwwwwr}v????>>+++++++>??vvvvvwuz}vvvv???^|^.<br></br>
.+++>>^|+????vvvv?>>>?vvvvvv>++}wwwwwr}}}}v?v?>?>>++>?vvvvvv}wrvvvvvv?vv>^.<br></br>
.>+++>|~|>????vv?>+>>>>?vvvv>+>rwwwwwwwwzr~vr^?rv?>+??vvvvvvvr}vvvvvvvvv}v`<br></br>
.??++++|||>?????>+^>??>>?vvv>+?wwwwwwww}?- `` ^v}?++?vvvvvvv?^^>??????????`<br></br>
.???>+++^|^?????++>????>>?v?>+?wwwwwwww?^+>>?~ |?>+>?vv?>vvvv>-~~~||^^+||^.<br></br>
.?vv?>++>++????+++vvv????>>?>+vwwwwwwxuuyyyuw`|v?+>?v?>+>>vvvv>---~|^^+^||.<br></br>
.?vvv??>+++>??>++>}}}v????>>>>rwwwwzuyyyyyyw~^}v?+>??+++>>?vvv?>----~|^^^~.<br></br>
`vvvvvv?>+++??>++v}}}}v????>+?wwwzxuyyyuzrw??rr?>+>+++++>>>>?vv??~-----~^~.<br></br>
`rrvvvvv?>>++>?>+}}r}}}}v??v??wwwxuyyz}v}}rwwrv>^++++>++>>>>>?v???+------~.<br></br>
`rwwvvvvvv?>+>???}}wr}}}}v?}wrwwwuyw>`+}wwwwv?>^+++>>>>^|++>>>>?????^-``-`.<br></br>
`rwww}vvrw}?>>??v}rwwr}}}}vrwwwwzxv~->vwwwr?>>+^+>>+>>++---~|^^^~~^^^~````.<br></br>
`wwwwwrvwwwrv???rwrwwwrw}}}wwwwrzr?vrwwwrv>++>+++>>>+^+>^``-`---`````````` <br></br>
`rwwwwwwwwwww}??rwwwwwwwr}rwwwwwrwwwwwrv>>+++>>+++>?>~.~+~````--`````````` <br></br>
`rwwwwwwwwwwwww}wwwwwwwwwrwwwwwwwwwwrv?>++>>>+++++>vv^..`-.``.```````````` <br></br>
`rwwwwww}vvvv???vvrwwwwwwwwwwwwwwwwv?>++++>>>++++++?}>.........`````..```` <br></br>
`wwwwwwwvv??^-....`->rwwwwwwwwwwwww?>++++++++++++++?v>................```` <br></br>
`vvrrwrr?>|-`...... `ruzrwwwwwwwwwwv>+>~-+++>++++++??|`...............```` <br></br>
.>?rr}}v>?>|~-``.....}yyzwwww}vv}rwr?>+^^|+++++>+++??```...........``.```` <br></br>
 ~|+>>>?+v}>|^+^+^|^|>}rrrwwr??++????>^`~`-+++++>+>?|..````.``````````````.<br></br>
 -|>v?+>>|~^>++^~~~-````--~|-....`^^+>~. ..~>+++>+>>...```...`~-`````````-.<br></br>
.~~??^+|^>>>>>|~--||`...``........`~^+`...  -^++>+>`.....`.....`..````````.<br></br>
                  ~?^?z?~v?|~~~-~```-`.`.............|-.....  ~||^|-..................````` <br></br>
 `~-^>?>??|-||~-`..```..`........ ..`` ....  .||~.`~~`.................... <br></br>
                  .`^?+--.`|+^~```..``.-|-........ ............-|- -?+..................... <br></br>
 ``.~>-..~|```..--.-`................ ....... .~..~-~`.................... <br></br>
                  `~`-``..``..``-++-`.....`...........  ................................... <br></br>
.+^~~`````..``-+>>~```..`^|`..... ........................................ <br></br>
                  `````|--````.....```..`.................................................. <br></br>
.>~~^~``.`-~--`...`............          ..        ....................... <br></br>


            </div>,

            <div>

.^^^^^+?????????????????????+^^||^>>>>>>>>>>+^||||^^+>??}rrvr?vrwwv----~||.<br></br>
.>>>>>>vvvvvvvvvvvvvvvvvvvvv?>++vv}rwwwr}v?>+>>>???vvvvvxyw}uuyyyxrv+|^^^^.<br></br>
.+>++>+?vvvvvvvvvvvvvvvvvvvv>++?wwwwrv?>++++>???vvvvvvvvwwvvzyuz}vvvv>|||^.<br></br>
.+>+++++?vvvvvvvvv?>?vvvvvvv>++vwwww}v?>++>+++++++++>?vvvvvvwurvvvvvvv?^|^.<br></br>
.++>++++>vvvvvvvv?>++?vvvvvv>>+vwwwwr}vvvv????+>>>++?vvvvvvv}}vvvvvvvvvv>|.<br></br>
.?++>>+++?vvvvvvv>++>+>?vvvv>++}wwwwwwwwzr`vr|vrv?>+??vvvvvvvvv}vvvvvvvv}?`<br></br>
.??++>+++>vvvvvv>+^>?>^>?vvv>+>wwwwwwww}>` `..^v}?++?v}vvvvv?~|^>>>???????`<br></br>
.???++++++?vvvv?++?????++>vv>+?wwwwwwww???vvv| ?v?+>?vv?>?vv}?`----~~~|||^.<br></br>
.???>++>>++?vv?+++rwv???>+>?>+vwwwwwwxuyyyyur.?w?++>v?>++>?vv}?~--~~||||||.<br></br>
.?????++>++>vv>++?www}???>++>+}wwwwxuyyyyyyr`?zr?^>??+++++>?vvv?-`--~~~~~~.<br></br>
`v?????^++>+??+++vwwwwrv???>++wwwzuyyyyxw}w>vzwv>^>>+++>+++>?vvvv|`------~.<br></br>
`r}?????>+++>>>+>wwwwwwwv?vr?>wwruyyyz??}}rwwwv+^++++>>+>>>++?vv}v>------~.<br></br>
`rwrv???vv+++>>+?zwwwwwww}vwwrwwryyr^.>rwwww}?+^^++>>>>^-|^+++>?vv}v+-``--.<br></br>
`wwwwv??}wv>++>+vzwwwwwwwwrwwwwrzx?`~?wwww}?+++^^>>++>>+`.```-~||^+>>^````.<br></br>
`wwwww}vwwww?+++}wwwwwwwwwwwwwwrzv?}wwww}?+++>>>++>?>|^>^.```````````````` <br></br>
`wwwwwwwwwwww}?^rwwwwwwwwwwwwwwwrwwwww}?+++>+>>>++>vv^.-+-..`````````````` <br></br>
`rwwwwwwwwwwwzzvwwwwwwwwwwwwwwwwwwww}?+++++>>++>+++?}?...-...``.`````````` <br></br>
`rwwwwwwv???v>>?vvrwwwwwwwwwwwwwwwwv++++++>>>++++++?}?..........````..```` <br></br>
`wwwwwww????~. ....`+wrrwwwwwwwwwww>++++++++++++++^?}>................```` <br></br>
.vvrrr}r>|-. ...... -uuzrwwwwwwwwww?+++-`+++>++++++?}|................```` <br></br>
.>?}}vv?^>>^|~-```..`xyyrwwwwvvv}rw}+++^|~+++++>+++?v..............``.```` <br></br>
 -~+>>+?>?v^~|^^^||^^?v}}}wwr>+^+???>+^-~.`+>+++>+>v^....``.``````````````.<br></br>
 -~?v?^++|~^>>+^~~~``.`..``-`....-+++>- .. ->+++>^??...`.....`~-``````````.<br></br>
.~|??++|+?>>>+|---~|`...``....... -+++....  ~>++>^>`...........`..````````.<br></br>
 |?|vx>~v?~-~~-~```-`.`........... `>^.......^>+++-..................````` <br></br>
 `--^^>>>>~~^|--`..``..``...........-~ ..... `>>+~---~.................... <br></br>
 ``^?+-`.-|^|~```..``.-|-........ .... .......^>| |>+..................... <br></br>
                  .`.~+`..~~```..~-.-`........................ `+`.~`-`.................... <br></br>
.-~--``..``..``|>+-`.....`...........  ...... .`.......................... <br></br>
.+|~~.````..``-^>+-``...`^|...... ............. .......................... <br></br>
                  .-```-^-```-`.....```..`.................................................. <br></br>
.>~|^~`..`-~~~`...``...........          ..       ........................ <br></br>


            </div>,

            <div>

.++^^^>??????????????????>>>^^|^^>?????????>>+^||+>>>???}rrvr}v}r?+----~|^.<br></br>
.>>>>>>vvvvvvvvvvvvvvvvv????>??v}rrwwwwwr}v?>>+>?vvvvvvvzyw}xuuyyw>^|||^^^.<br></br>
.+>++++?vvvvvvvvvvvvvvvv???vvvv}wwwww}}vvvv>>>>?v????vvvrwvvwuyuwv+|^^|^^^.<br></br>
.++>+++>vvvvvvvvvv??vvv????w}vv}wwwwrr}}vv?>++>>>++>??vvvvvvrxw}vv>|^^^||^.<br></br>
.++++++>?vvv??vvvv????????rr}vvrwwwwwrrr}v>+>>+>>++>??vvvvvv}}vvvvv^^^++^^.<br></br>
.>++>+>>>?v?>>?vv?????>>?}wr}}vrwwwwwwwwr?.??~?v?>+???vv???vvvvvvv}?+++?v?`<br></br>
.>>>>+++>?v??>>?????v?++?rrr}vvwwwwwwww?|..`  `>?>>?????>>???>???vvv>^+>?v-<br></br>
.>??>+++>?????>>>>vv??++v}rr}vvwwwwwwww??v}v}` >?>?????>>>?>>>+>????>~|||^.<br></br>
.????+++>>+?vv>+>+vrv>>?vv}}}v}wwwwwzxuyyyyu?.+??>>>>>>++>>??>>^>???v>||||.<br></br>
.????>+++++>??>++>vvv??v}vvv}vrwwwzuuyyyyyu?`?v?>++>>+++>>>???+^-~+???+~~~.<br></br>
`v???>>+++????>>+?????}}}}}vvvwwwxuyyyuw}v}>vv??+^+>+++>>>>+>v>^^---~|^|-~.<br></br>
`vv???>>+?vv?>>>>????vwwrv}w}vwwruyywv??}rwwv??+^+++>>>+>>>>>??+^^~------~.<br></br>
`vvvv?>>?}vvv?>>?}???vwwwr}wwrwwwyw+..?wwww}>+++++++>??+-|++>??+||^^~-----.<br></br>
`}v}w?>?rwrvvvv?vz}??rwwwwwwwwwrxv~`+vwwwr?>^+>+++++>?v?`.`-|+>|``-~~-````.<br></br>
`wrrw}?}wwww}vvv}ww}vwwwwwwwwwwrzvvrwww}?>++++>>++>>>+?v^.```^>|`````````` <br></br>
`wwwwwwwwwwwwr}vrwwwrwwwwwwwwwwwwwwwwr?>++>>>>>>++>?>^|?>`.```|^`````````` <br></br>
`wwwwwwwrrrrwwwrwwwwwwwwwwwwwwwwwwwrv>++++>+++++>++>>+|>+`.....``````````` <br></br>
`rwwwwwwv???+|^>?v}wwwwwwwwwwwwwwwr?+++++++>++++>++>>+^>^........```..```` <br></br>
`wwwwwwv???>`......`?wrwwwwwwwwwww}^+>++++++++++>++>>+^>|............````` <br></br>
.?vrrr}}?^^>>^^~`.. >yuzwwwwwwwwwwr-|+>||~|>++++>++>>|^>-............````` <br></br>
.+?}}vv?^vv??+^`....-uyywwww}vvv}rw?`-^^^-`+>>+>>++?>-^>`..........``.```` <br></br>
 -|+>>+?>??^~||~~````^+>?v}r?^|^^+>^````|`.-+>++>+>?|`^>```.``````````````.<br></br>
 -~?v?^++||+>>+^~~|-`.....``......`..`.... .-^++>+>>``^+.``..`~-`````````-.<br></br>
.~^??>+|>?>>>+~---~|`...``...........`..... .-+>>+>~``--.......`..```````` <br></br>
 |?|vx>~}?~----|```-`.`.............`....... .`|>+|-~```............`````` <br></br>
 `-~^|>>>+~~^|--`..``..``........ .......... ..`~|~~||``.................. <br></br>
 ``^?>-``-|^|-```..``.-|-........ .............`..|+^-`................... <br></br>
                  .``~+`.`~~.``..~`.-`.............................-`--`................... <br></br>
.~|--``..``..``|>+-......`...........  ...............`................... <br></br>
.^|~-`````.```-^>^-``...-^~............................................... <br></br>
                  .~```-|````-`.....```..`.................................................. <br></br>
.>~|^-...`~~~~`...``..........           ..        ....................... <br></br>


            </div>,

            <div>

.>+^^^>??????????????????^^^^^|^+??vvvv?????>>+|^>??????vwrv}wvvr^`----~|^.<br></br>
.>>>>+>vvvvvvvvvvvvvvvvv?+>+>?vrwwwwwwwwwww}??++?vvvvvvvwyw}ryuyyv-~|||^^+.<br></br>
.+>+++>vvvvvvvvvvvvvvvv?+++?rwwwwwwwwwwwwwr??>+?v?>>?vvvrwvvvxyuzv-~~||^^^.<br></br>
.+>>^^+?vvvvv?vvvvvvvvv>++?wwwwwwwwwwwwwwrv?>+>?>++>v?vvvvvvvwx}v}^~~||||^.<br></br>
.+>++++>vvvv?+?vvvvvvv?++>rwwwwwwwwwwwwwrv^|++^++^+?v?vvvvvvvvvvvv?~||||^^.<br></br>
.++>?+++?vv?^+>?vvvvvv>++}wwwwwwwwwwwwwwv^.||~+?>^>vv?v?>>?vvvvvvvv>~||^+>`<br></br>
.++??+++?vv+>++>?vvvv?++?wwwwwwwwwwwwwr?~.-`  .^++?}vv?>+++>?vvvvvvv|||^+?-<br></br>
.>???++>>v?^??+++?vvv?+>wwwwwwwwwwwwwwwv}wwrr`.^+>vv?>++++>++>?vvvvv?~^^^^.<br></br>
.????++>>>+>??+++>?vv>+vwwwwwwwwwwwwzuyyyyyu>.+^>??>+++++>>+>^^>?vvv}?||||.<br></br>
.????++++++????++++??+?wwwwwwwwwwwzuyyyyyyu?->++>>+++++>++++?>`-~+?vv}?|~~.<br></br>
.????+++++?????>^+++++}wwwwwwwwwwxuyyyx}vvv?v>+++++>+++>++>+>v|----~^+>+-~.<br></br>
.????++++?zrv???>++++>wwwwwwwwwwryyyr>+v}wwr?+++^+>+>>>++>>+>v?----~~----~.<br></br>
.???v?++?wwwwv??vv+>+vwwwwwwwwwrzyr| `vwwww?^^++++++>vv>-^>>+?v~----------.<br></br>
`v??w?^>wwwwwwr?vz?++}wwwwwwwww}u?`~?rwwwv>+^++>>++++?vv`.-^+?v^`--```````.<br></br>
`w}}wv>rwwwwwwwr}wwv+wwwwwwwwww}zv}wwwwv>+++++>++>+++>vv^.``-?}?`````````` <br></br>
`wwwwrrwwwwwwwwwwwww}wwwwwwwwwwwwwwwwv>+++++>>>+++++++?v?..```>?`````````` <br></br>
`wwwwwwwr}}rrwwwwwwwwwwwwwwwwwwwwww}>+++>+++>>>++++>++?}v`.....-`.```````` <br></br>
`rwwwwwr???>-`~^??}rwwwwwwwwwwwwwwr++++++>+>>++++++>++?}v`.......```..```` <br></br>
`wwwwww????+``..  .`}wrwwwwwwwwwwwv|>>++>|+>>++++>+>++?}?............````` <br></br>
.?v}}}}?v>?r}vv?^~. vyurwwwwwrrwww}.-+>+>-~>+++++>+>++?}+............````` <br></br>
.+>vv?v?+ww????|-```^uuurwww}??v}rz+ .|>>-.^>++>+++>^+?}~...........`.```` <br></br>
 -|+?>+v>??|~||~~````~~^^v}v+~|||^+|...-+| .^>++>++>++?v``...`````````````.<br></br>
.-~???^^^^|+>>+^~||-`.............. ....`~...^>+++++++??.``.``|-``````````.<br></br>
.~+??>^|>>+>++~-`-~~`...-`.................. .^>++>>>^?+......``..```````` <br></br>
 |>~vz>|r>~---~|```-`.`....................   .~>>>+>^>~............`````` <br></br>
 ``|^~>++^~|+|-``..``.```..`....  ..........    `^>>>++~.................. <br></br>
 `-^?>```-|^~-```..-`.-|`........ .............. .^+>>+`.................. <br></br>
 .``~|`.`~-.``.`~``-............................ .``~+>`.................. <br></br>
                  .||--``......`-^?+-......``..........................`~`.................. <br></br>
                  .|-~-`-`-`.```-^^|-``...-^~............................................... <br></br>
                  .~`--~|```--`.....``..``.................................................. <br></br>
.>-||`...`~~~~`...``..........           ..        ....................... <br></br>


            </div>,

            <div>

.>+++>???????????????????>+^^^^>??vvvvvv???+++++>???????vrrvvr}v}?>----~|^.<br></br>
.>>>???vvvvvvvvvvvvvvvvv??>+>?rwwwwwwwr}vvv???>>?vvvvvvvrxrv}xuuyz?+~||^^+.<br></br>
.+>+>??vvvvvvvvvvvvvvvvv?>+>vvwwwwww}vvvvv?>>++>>>>+?vvv}rvvvwuuur>>|||^^^.<br></br>
.+>>+>??vvvvvvvvvvvvvvv??>>vvvwwwwwwrr}vvv???>>>>++>??vvvvvvvrzxr}?++||||^.<br></br>
.+>+++>?vvvv??vvvvvvvvv??>vvv}wwwwwwwrrwrv|>?>>?>>+>??vv?vv??v}}vvv>>^||^^.<br></br>
.+++>+>>vvvv???vv?vvvv???vvvv}wwwwwwwwwwv| |-~?v?++??vv?>??????vvvv?>>|^+>`<br></br>
.++>>+>+?vv?????????vv??vvvvvrwwwwwwwwv|`.``. .>>+>?v?>++>>^>????vvv??^^+?-<br></br>
.>?>>+>+>v??????>>??????}vvvvwwwwwwwwzw}wzzzv ~?>+???>+++>>~-^>???vvv?>|^^.<br></br>
.????++>>>??vv?>++???>?}rvvvvwwwwwwzxuyyyyyw`~v>+>>>+++++++>~`-^>???vv>|||.<br></br>
.????>++>+>?vv?++>>??+>}}vvvrwwwwzxuyyyyuur~^v?>++++++++>++?^|`--|+>?vv|~~.<br></br>
.????>>+++???v?>^?>>>>?vvvvvwwwwzuyyyurvv}vvrv>++++>++++++>?++~-----~|>+-~.<br></br>
`vv??>>>>?vvv?>>>v?>>?vvvvv}wwwrzyyz?^?}rwzr?+++^+>>>>>+++>?>>^----------~.<br></br>
`vvv}?>>?vvvv?>>v}v?>?}rvvvrwwwruz>`.+rwwwv?^^+>++>>???>|^>??>+-----------.<br></br>
`}vvwv??vvr}vv??}w}v?v}wrvvwwwrzw^|?vwwrv?>+++++>++>??>>~-|>?>>~`--```````.<br></br>
`wrrw}vwr}rw}vv}www}?vrwwrvwwwrrw}wwwrv?>++>+>++>+++>??^-``|>>>|`````````` <br></br>
`wwwwwwwwwwww}vwwwwwrrwwwwwwwwwwwwwwv?>+++>+>++++>+++>?+~..-^^~^`````````` <br></br>
`wwwwwr}}v}}rrrwwwwwwwwwwwwwwwwwww}?>++>>>++>>>>++>+++??|`..`~.``````````` <br></br>
`rwwwwvv??>>^~|+??vwwwwwwwwwwwwwww?++>+||+>>>>+++++>++??^........```..```` <br></br>
`rwww}v?vv}?|-~~-`.>wzrwwwwwwwwwww?^>>+~``~^+>>++++>++>v|..........``````` <br></br>
.?v}}}wvv>?r}vv>^~.-zyxwwwwrrrrwww?`|+>-`..`-~^+>++>++>v~............````` <br></br>
.+>vv?v?^ww??>>~-```>zzwrzw}v??v}r}`.-^~|`.````-|+>>++>?`...........`.```` <br></br>
 -|>?>+v>?>|~||~~````-`-~??+-``----`...`^-..`````-^>++>?``...`````````````.<br></br>
.~~v?>^^|^|>>>+^~||-`........................`^+```|++>+.`..``|-``````````.<br></br>
.|>???^^?>++++~-`-~~`...-`.................  .`~````-^>~......``.````````` <br></br>
 |>~vz>^r>~`--~|```-`.`....................    .``````^`.......`....`````` <br></br>
 `-^^->+^^~^+|-`..```.```..`....  ..........    .`~~-```.................. <br></br>
 `-|?>```~||~````.`-.`~~`........ .............. .~|~``................... <br></br>
                  .``~~..`|`.``.`|``-............................ .`.```................... <br></br>
.^|--``..`...`~>?+`.....```............................................... <br></br>
                  .~-~``-```.```-|^~```...~^-............................................... <br></br>
                  .|`--~|```--`.....``..``.................................................. <br></br>
.>-||`...`~~~-`...```.........           ..        ....................... <br></br>


            </div>,

            <div>

.^^>??????????????????????+^^^+vvvvvvvvvv?>||+>>?????????vv??vwvvrr~`--~|^.<br></br>
.>>>vvvvvvvvvvvvvvvvvvvvvv>>+>wwwwwwwwrv>>+>>?????vvvvvvv}}vvryuuyxv~||^^+.<br></br>
.+>+?vvvvvvvvvvvvvvvvvvvv?+++?wwwwwrv?>++++++++^++++>vvvvvvvvvxyyz}}+~|^^^.<br></br>
.+>>+?vvvvvvvvvvvvvvvvvvv?+++?wwwwwwr}v????>??>>>>++??}vvvv?vvwuwvvvv||||^.<br></br>
.++>+>?vvvvvvvvvvvvvvvvvv?+>+vwwwwwwwrrrrr|v}?vv??^+?v}v?v?+>?}rvvvvv>~|^^.<br></br>
.++++>>?vvvvvvvvv??vvvvvv>>>+vwwwwwwwwwwv~ +`^rv?>+>?vv?+??+>>>?vvvvvv|^+>`<br></br>
.+++>++>?vvvvvvvv^+?vvvvv>>>+}wwwwwwwwv|-`--- .??++???>++?>~+>>>?vvvvv+^+?-<br></br>
.??++++>>vvvvvvv>+>+>vvvv+>+>wwwwwwwwzwzxuxuv ?v?^>?>++++>+``-|++>?vvv?|^^.<br></br>
.???>++>+?vvvvv?+>?>+>vv?>>+?zwwwwwzuuyyyyu}.?w?++>++++>+++?``--|+>?vvv|||.<br></br>
.?????++>+?vvvv>+???>+>??>>+vwwwwzuyyyyuxuv~?z}?+++++>++>++v~-----~|>?v^~~.<br></br>
`v?????>+>>?vv?++v????+>>>>>rwwrzyyyyzvvvrvrzr?^+++>>>>+++>v+`-------~>^-~.<br></br>
`rrv?????++>v?++>wv????++>+?zww}xyy}^^vrwwzr?+^+++>>++>>++>v?`-----------~.<br></br>
`rww}v????>+?>++vzwv???}>++vwwwry}~.-vwww}?++++>++>??>+>+^+vv-------------.<br></br>
`wwwww}v??}?++++wwwr??vwr++rww}xv~+}wzw}?+++>++++++>vv~~+>+?v|`----```````.<br></br>
`wwwwwww}v}zv++vwwwwr?}ww}?wwwrwwwwww}?++++>>>>>>+++>v>.`~+?v+```````````` <br></br>
`wwwwwwwwwwwzv>wwwwww}rwwwwwwwwwwww}?+++++++>>>++>++>??`..`+v?.``````````` <br></br>
`wwwww}vvvvvrr}wwwwwwwwwwwwwwwwwwwv+++++>>++>>>>++>++>v|....~?```````````` <br></br>
`rwwww????+^?>+?vvvrrwwwwwwwwwwwww+++++--^+>>>+++++>++??.....``.````..```` <br></br>
`rwwwv??vv}v?>???+`rxwrwwwwwwwwwww+++>+`..`~^>>++++++++?`..........``````` <br></br>
.??}v}z}v??r}vv>^|.>yyzrwww}}}rwwz^~+>+. ....`|+>++++++?-............````` <br></br>
.^>v??v>^ww??>>~-```?rrvwzwv???vvr? `^+`~`......~+>>>>+?`.............```` <br></br>
 -|>??+v>>+|||||~`````..-+^`..`````...-`^`........|>>>++.`...`````````````.<br></br>
.-~v?>^||^^>>>+|~||-`.`.................  ...`~+. .`+>+~.``.`-|-`````````-.<br></br>
 |>?v?^^?>+++^--``~~`...-`.................  .``... .|+.......``..```````` <br></br>
 |^-vw>+r>-`--~~`````......................   ........~.............`````` <br></br>
                  `-+^`+||||^+|``..``.````..`....  ..........  ....-|~....................` <br></br>
 ``|?>```~|~-````.`-.`~~`........ .. .............~>|..................... <br></br>
                  .``~-..`~`.``.`|```............................ .`.`..................... <br></br>
.+^--`-......`~>?+`.`...`-`............................................... <br></br>
                  -`~``---`.```-||-```...~^-............................................... <br></br>
                  .^-~~~~```~-`.....``..``.................................................. <br></br>
                  .^-~~`...`~-~-`..```..........           ..        ....................... <br></br>


            </div>,

            <div>

.^+???????????????????????^^^^+vv?>>v?????>||+>>?????????v}??vr}}rwv>~--|^.<br></br>
.>>?vvvvvvvvvvvvvvvvvvvvv?+++>www}}rr}}vvvv?>?????vvvvvvv}rvv}xuyyur?>^|^+.<br></br>
.+>??vvvvvvvvvvvvvvvvvvvv?++>vwwwwwrvvvvvv?>+>>>++++>vvvvvvvv}wxyxr}?>>^^^.<br></br>
.+>???vvvvvvvvvvvvvvvvvvv?+++vwwwwwwrr}vv?>>?>+++>++>?vvvvvvvvrxz}vvv??>^^.<br></br>
.+>????vvvvvvvvvvvvvvvvvv?>>+vwwwwwwwrwr}?~??+?v??>+>?}vvvv???}r}}vvv???>+.<br></br>
.+>????vvvvvvv?vv??vvvvvv?>>+vwwwwwwwwr?+` -.-?}??>+?vv?>?v??>>???vvv?????`<br></br>
.++?????vvvvvv???>>?vvvvv??>+vwwwwwwww>`~~||` `}v?>>???>>??>>+^^+>?????>??`<br></br>
.?>>>>>??vvvvv??>+>+?vvvv??>>vwwwwwwzxxxuuuw-`v}?++>>>+++>?+|~--~|^>??>^+?`<br></br>
.??>+++>>?vvvvv?+>?++>vvv??>>vwwwwzxuyyyyyx^`vw}>+++++++++>?------~^>>>~~^.<br></br>
.??>??++>+?vvvv>+>??>+>????>?vwwzxuyyyyuxx>^}z}?++++++++>>+?~`------~+>|~~.<br></br>
.?>>???>+>>??v?>+?????+>>>???vwwxyyyxr}}vrvwrv>^^++>>>>+>?^?+--`------^|-~.<br></br>
.????????+++>?>>>v?????++>>?vrwzuuw>`>wwwwrv>+^^+++>++>>>?~^+```---------~.<br></br>
.?vv?v????>++>++?v?????v>+>?vrwxx>`.|}wwrv>+++>>+++>>>+>?+-|+-``----------.<br></br>
.?vvvrrvv?v?++??vvvvvvv}}?+?vwwxv|?rzwr?>+++++++++>?>?~^?>-|^------```````.<br></br>
.?vvvvwwr}rwv>?}wwrv?}r}wr??vwwwwwwwrv>++>+>>>>>>++?+^|~+|-~^~```````````` <br></br>
`vvvvvrwwwwww}vwwwww}rwwwwwv}wwwwwrv>+++>+++>>>+++>?|~|-|`.-+|```````````` <br></br>
`r}vvv???v}vrr}wwwwwwwwwwwwrwwwww}?+++++>>++>>>>++>?~`|-~`.`-~`.`````````` <br></br>
`rwrvv>>vv>+?+^???vwrwwwwwwwwwwwwv>+|+>~|++>>>++++>?~`~-.`......````..```` <br></br>
`rwrz}v?vv}v?>???^~zzwrwwwwwwwwwwv+^-|+-`-~^+>>++>>?~``~...........``````` <br></br>
.??r}}xrv?vr}vv>^|.?yyzwwww}v}rwwr^-`-~-````-~^++>?+```~.............````` <br></br>
.^+????>^zw>>>+~-```>vvvww}?>???vv?..``.~~`````-|??-```-..............```` <br></br>
 -|???+v>>+||^||~`````..-~-.......`.....~~-`````-|-`````.`...`````````````.<br></br>
.-~v?>^||+^>>>+|~||-`.`................. ..```|>-...`-`..``.`-|-`````````-.<br></br>
                  .^??v?^^v+^^^^--``~~`...-..................````-.....``.......``..```````` <br></br>
 |^-vr>+}>-`--~~`````........................`........`.............`````` <br></br>
                  `-+^`+|~||++|``..``.``-`..`..... ................-|~....................` <br></br>
 ``~?>```~|~-````.`-.`-~`........ ................|>|..................... <br></br>
                  ```-`..`~`.``.`|```.............. ............. ...`..................... <br></br>
.>|-```......`|>?+`.`...`-`............................................... <br></br>
                  .``~`----`.```-~~-```...~|`............................................... <br></br>
                  .^-~~~-```~~``....``..`................................................... <br></br>
                  .^`~-`...`~-~-`..`-`..........           ..        ....................... <br></br>


            </div>,

            <div>

.++++++++++++++++++++++++|~~~~~>?~-~||~~~~||~-~^+++^^+++++??+++??>??>^-~||.<br></br>
.????????????????????????+|||^?v?^??>+>>????|^>??>>^^?????v???>?vvv?>?+^^+.<br></br>
.????????????????????????>^|^?v???????vvvv?|^>>+^||||>?????>?>>?vv?>>>>+^^.<br></br>
.?????????????????????????+|^???????????v?||++^^|||||>??????????v?>?????^^.<br></br>
.?????????????????????????>||?v????vv?v?>~~|~-|||^^||>???????>>>?????????^.<br></br>
.>????????????^>??????????>^|>v?vvvvvv?~`..`.`~>+^^~|>??>???>^^^++>>>????>.<br></br>
.+>>??????????^|^>?????????^|>v?vvvvv?|~^+++~.^v+^^|^>?>|+??>|||||||^^>>??`<br></br>
.^^++>????????+^||^+>??????+|>v?vvvvvvvvvv??`+vv+^|~^?>^|^>?+||||||||||^+>`<br></br>
.^^|^^++>?????>^^^^|^+>????>^>vvvvvvvvvvvv?~>vvv+||>vrrv>^+>+^||^^^^^^^^^|.<br></br>
.^^|^|^^^^>>???+^|^^||^>???>|>vvvvvvvvvv??^?vv?>|^?}rr}}}v>^^^||^^^^^^^^^^.<br></br>
.^^|+^^|^^^^+>?>^^|^+^|^+>?>|>v?vvvv??????vv?>^||^^?rrrrwz>|+>+|^^^^^^^^^^.<br></br>
.^^^^^^+^^|^^^^++^^^++^|^^+>|>??vv>~~?vvvv?+^^+>?v?vrr}}zw>+>?v>^^^^^^^^^^.<br></br>
.^^^^?>+++^^^^^|^^^^?+++^^^^^??v?~-^?vvv?+^^^^>}}}}}v}}rz}++++>?^^^^^^^^^^.<br></br>
.^^^^?vv??>+++??>^^|?v>+?>^^^???+??vvv?+^^^^^^^vw}rw?v}}w?^^+++^|^^|||||^^.<br></br>
.^^^^>vvvvvv?>>vvv?++vv>?v>^>vvvvvvv?+^^^^^^^^^?r}rz?|?v}?^^^^^^||^^||||^|.<br></br>
.^^^^^?vvvvvvv?vvvvv?vvvvvv^?vvvvv?+^^^^+++++^^^+vwz?^^?}?|^^^||||||||||||.<br></br>
`??+^^|++>?>???vvvvvvvvvvvv?vvvvv>|^^^^^++++^>?v?vwz?+^^?>||||~|||||||||||.<br></br>
`v}?++^^vvv+?^^>?>?v?vvvvvvvvvvvv+^^-^^^^^++>}rrr}wz>+^^||~~~~||||||||||^^.<br></br>
`rwwzxzvwxw?>+???>>}v?vvvvvvvvvv}?+>^+?vvvvv}r}}}vww+^^||||~~|||||||||^>??`<br></br>
.?vzxxuxwrvvv??>+^~?}vvvvv?>>?vzxw}v^+vrrrrrr}}}v}zv^^^^^^^|~~~^>>>>>+++>>`<br></br>
.^+v}}w}?zr>???^||^+?v??vv+^>>vwww}?+>>?rrrrrr}r}zw>++++++++^^+>>>>>>++++>`<br></br>
.??}}}vr??>|>??>>>>>>>^^+^~+?>>>+^>?+>+>?}rr}}}}rz?++++++>+++>>>>>>>>>>>>>`<br></br>
.??wv?+||+^>>>^^^>??>++++^+>>++^^+^^^++++?}}}}}}w?^^^^+>>>>>>>?>++++++++++`<br></br>
.?v?v?+>v????>++^|+>++++>+^^^+^^^^^^^^^^^^+vvvvv?^^^^^^^^^^^^^+^^^^^^^++++.<br></br>
                  .|^|??++?+||||^^|~~||~~~~~~~~-----------~-`-|+^~--~-~-----~~~~~~~~~~~|||||.<br></br>
                  .-~^|~^||||^^|~~--~~--~~-----````````````````~````|^|-----------------~~~~.<br></br>
                  .~~~+^--~|||~~~~~-~~--|~-----`````````````````````^+|-`````---------------.<br></br>
                  .~~~~~--~|~-~--~|~~~----------````````````````````~-~---------------------.<br></br>
                  .+||~~~~~~~~~|^++^|~~~--~|~-----``````````````````-----------------------~.<br></br>
                  .~~|~||||~~||~|||||~~~~~^^|-------------------------~~-----------~~~~~~~~~.<br></br>
                  .^|^^^||||^^||~~~|||~~|~~~~----------------------------~~~~~~~~~~~~~~~~~~~.<br></br>
                  .+^^^|||||^^^^||||^|||~~~~~~---------------------------------------~~~~~~~.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^+^^++++++++++++++++^+^+++++++++++++++++++++^^?uyyuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++++++++++++++++++++++++++++++++++^^^+}uyuuuxv>>>>+^^^++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++^>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++vuuxxw}v?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^+++++++++++++++++++++++++++++>v}vzuuuxwv?>>>?>+++++++++++^.<br></br>
.^+>>>+^>?}^?||+>>>+++++++++++++++++++++++++?xyyyuuxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+?r}}r}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zuuuyuuw>?v?++++++++++++++^>wxuzxxxyyyyyuuuxxxxzzww}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzwzxzvvrxuuxxxzwwwz~<br></br>
-xxxuuuxw}v>}wzwwxuuuxzrv??wuuuxxzzxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxzzzzzwzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxxzzzzwr}}vvvvvv-<br></br>
`}}}?>?vrvv}v}}}}v}rrrrrrrrwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^^^^^^^^^||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^^||||^^^^^^^^^^^^^^|^^^^|||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~``~-->>?^-~`--~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?+~|||~`~||~~>>^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+|^^^^^|^^^^^^>>+^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^|?+^^^^^^^^^^^^^^^^|^+?^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++^^^+++++++++++++++++^^+++++++++++++++++++++^^?uyyuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uuuuux}>>>>+^^^++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++^>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^+++++++++++++++++++++++++++++>}}vzuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?}^?||>>>>+++++++++++++++++++++++++?xyyuuuxzzr}?++++++++++++++??>.<br></br>
.vwxxxz}zyz>>^>vvv?+++++++++++++>>+?r}}}}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zuuuyuuw??v?++++++++++++++^>wxxzxxuyyyyyuuuxxxxzzww}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuxr}v>}wzwwxuuuxzrv??zuuuxxzzxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxzzzzzzwzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrv}}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^^^^^^^^^||^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^|||||||||||||||||^^^^^^^^^^^^^^^^||||||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~``~-->>?^-~`--~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?>~|||~`~||~~>>^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+|^^^^^|^^^^^^>>+^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^|?+^^^^^^^^^^^^^^^^|^+?^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++^^^+++++++++++++++++^^++++++++++++++++++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^^++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++^>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^+++++++++++++++++++++++++++++>}}vzuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?}^?||>>>>+++++++++++++++++++++++++?xyyuuuxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+?r}}}}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zuuuyuur??v?++++++++++++++^>wxxzxxuyyyyyuuuxxxxzzww}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??wuuuuxzzxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxzzzzzzwzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuxxxxxxxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrv}}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^^||^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^|||^^^^^^^^^^^^^^^^||||||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.  .-~`~|~`~`. ..-~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`. .`~`-^|`|~`  .-^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|~```.   ..`-~^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^-```^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>??>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^++^}zzz?++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^-~^~+???|^~-+^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^|~^|--|^~|+^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++^^^+++++++++++++++++++++^^^^?+^++++++^^?>^^^^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++++++++++++++^^^^?>^++++++++^+?+^^^+}uuuuux}>>>>+^^^+^+++^^.<br></br>
.^++++++++++++++++++++++++++++|+?^+++++++++++^?>?rrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++^^^^+++++++++++^+}xuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^^++++++++++++++++++++++++++++++++++++++++++++ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^+++++++++++++++++++++++++++++>}}vzuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuuxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zuuuyuur??v?++++++++++++++^>wxxzxxxyyyyyuuuxxxxzzww}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??zuuuuxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxzzzzzzwzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrv}}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^^||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^^||^^^^^^^^^^^^^^^^|||||^^^^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.  .-~`~|~`~`. .`-~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`. .`~`-^|`|~`  .-^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|~``..   ..`-~^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^-```^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>??>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^++^}zzz?++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^-~^~+???|^~-+^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^|~^^--|^~|+^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++^^^++++++++++++++++++++^^^^^?+^++++++^^?>|^^^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++++++++++++++^^^^?>^++++++++^+?+^^^+}uuuuux}>>>>+^^^+^+++^^.<br></br>
.^++++++++++++++++++++++++++++^+?^+++++++++++^?>?rrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++^^^++++++++++++^+}xuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^+++++++++++++++++++++++++++++>}}vzuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuuxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zxuuyuur>?v?++++++++++++++^>wxxzxxuyyyyyuuuxxxxzzww}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??zuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxzzzzzwzzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrv}}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^^||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^^||^^^^^^^^^^^^^^^^|||||^^^^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~-~~~~~~~~~~~~~~`.-~~~~~..-~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^||~..^^^^~ `|||^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^| .||||` ~^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`.|||^.`^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~ |^^^.|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.-++~.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^||^+..++`-^||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+|.`|^+-.^|.|^~``^+^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~--||.`..||~~~^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++^^^++++++++++++++++++^++^^++^^^^`  -^^^^^^^^^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++++++++++++++++^+^^^^+^|||^+++^^^^^^+}uyuuux}>>>>+^^^+^+++^^.<br></br>
.^++++++++++++++++++++++++++++^^++^^^^^^^^+++^+?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++^^^+++v}}v>+++^>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++^~~++^vrr}++^~^^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++++++^^+>~||||~~^||-+>^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++>>+^++++^|++^^^>>^+wuxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^++++++++++|+>++^^+++++++++++^+}v?ruuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++^^^+++^+++++++++++?zyxvvzxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zuuuyuur??v?++++++++++++++^>wxxzxxuyyyyyuuuxxxxzzzw}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??zuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxzzzzzzzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrvv}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .||||||||||||^^^|^^^^^^^||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^^||^^^^^^^^^^^^^^^^||||||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~-~~~~~~~~~~~~~~`.-~~~~~..-~~~~~~~~~~~~~~~~~~~~~~~~||~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~..^^^^~ `^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^| .^^^^` |^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^`.^^^^.`^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^~ ~^^|.~^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ -^^-.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^||+^..^^.-^~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+|.`|^+- ^|.|^~``^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|~--|| `..||-~~^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++^^^++++++++++++++++++^++^^+++^^^`  `^^^++++^^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^++++++++++++++++++++++++++++++++++^|||^+++++^^^^+}uyuuux}>>>>+^^^+^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++++^^^^^^^+++^+?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++^^+++v}rv>+++^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++^~~++^vwwr>+^~^^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++++++^^+>~||^|~~^||-+>|vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^++++++++++++^^+>>+^++++||++^^^>>^+wuxzwv?????v?>+++++++++++.<br></br>
.^++++++^~--^||^++++++++++|>>++^^+++++++++++^+}v?rxuuxwv?>>>?>+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++^^++++^+++++++++++?zyxv?zxzzr}?++++++++++++++>?>.<br></br>
.vwxxxz}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zuuuyuur??v?++++++++++++++^>wxxzxxuyyyyyuuuxxxxzzzw}vv}v?+^^vuuuxzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??zuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxzzzzzzzzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuxxzzzzxxxuuuxxxxxxxxxxzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrvv}v}}}}v}rrrrrrrwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .||||||||||||^^^^^^^^^^|||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^^||^^^^^^^^^^^^^^^|^^^|||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~|~.<br></br>
                  .^^^^^^^^^^^+^^^^^^^|^^+^^^^^^^^^^^~|^^^^^||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^+^^^+>>|^^+^^^^^^^^^^^~|^^^^|~^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^??>?+>^^^^^^^^^^^^^|~^^^^||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^|>??>>?>?+|+^^^^^^^^^^^~^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^+>>-..`|>|+^^^^^^^^^^^^~|^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^+|~-+>+~-~||^^^^^^^^^~^||^^||~^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^|^+^|~|^--|^+|^^^^^^||^^^||^^||^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^++^^||^+^^^^^^^^^^^^^^^|||||^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++^^^++++++^^^^^^^^++++^++^^+++^^^|~~||^^^++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^++++++++++++++++++++++++++++++++++~|^^-^++++^^^^+}uyuuux}>>>>+^^^+^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^~`.`+~.`~^+++?vrrxuuxxxxzwwrrv>>++++++^^.<br></br>
.^+++++++++++++++++++++++++++^~````~~^>+|`..-~>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++|. .-|^-+>|^^|`  .}yuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++^|~```........`~|vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++^`..~^^++^^^+ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--^~|^+++++++++++++^+++++++^^^^+++^>}}vzuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||+>>>+++++++++^++++++++>>>++++?xyuxxxxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv>++++++++++++++++>?wwwr>>+rrxuxxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zxuuyuur>?v?++++++++++++^^^+??|+++vvrv+>^v??zxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++^+??ryr+^>}r+?w?>>}xwzzxuuxzzzzzxzvvrxuuxxxzwwwz~<br></br>
-xxxxuuzr}v>}wzwwxuuuxzrv??zuzwxvvrxuuxxuuxrvwwwzzxxxzzzzzzzxxxxxxzzzzzzzz~<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuzwurvrzzxxuuuuuxrvzxxxxxzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?vrv}}v}}}}v}rrrrrrrwwwwvvvrrrrrrrrrrrrvvvrrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++|^+++++++++++++^^^++++++++++++++++++++++++++.<br></br>
                  .||||||||||||^^^^^^^^^^|||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||^^|||^^^^^^^^^^^||^^|^^^|||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~||||~.<br></br>
                  .^^^^^^^^^^^++^^^^^^|^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^++^^^+??||^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^|^vv?v>>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^|>?v?????>|+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^+??-..`+?>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^+>^+~>??^||^|^^^^^^^^^~^^^^^^^~^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^|^+^|~+?|~|^+~^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+>^^^|^+^^|^^^^^^^^+^^^^^^^|^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^++++++^^^^^^^^++++^+++++++^|^^++^|^++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++~|+^-^++++^^^^+}yyuuux}>>>>+^^^^^^^^+^.<br></br>
.^++++++++++++++++++++++++++++++^|-`.`+-..~^+++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++^~`..`--^+|-`..`~>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++~.  `|^`^+~~^|`  .vyuuxzzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++^|~``.........`~|vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++^...-^^++++^>ruxzrv?????v?>+++++++++++.<br></br>
.+++++++^~--^||^++++++++++++++++++++^|^^^++++>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||+>>>++++++++++++++++++>>>++++?xyuuuuxzzr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv>++++++++++++^+^++>wzz}++^}}xuuxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zxuyyuur??v?++++++++++++^^^+??`^^|vvv?|^~?>>zuxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++^+??ry}~-^vv->r>~~vxwrrxuuxzzzzzxzvvruuuxxxzwwww|<br></br>
-xxxxuuzw}v>}zzwwxuuuxzrv??zuw}z??rxuuzxuuxv?}rwzzxxxzzzzzzzxxxxxxzzzzzzzz|<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuwru}?vzzxxuuuuuzv?wxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrrwwwv??rrrrrrrrrrrr?vvrrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++|^^++++++++++++^^^++++++++++++++++++++++++++.<br></br>
                  .|^|||||||||||^^^^^^^^^|||^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^|^^||||||||||||^||||^^^^^^^^^^||||||^^^|||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^+++^++++^^++++++++^^^+++++++++++++++++++++^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~||||~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^+^|^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^|^||^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^|~~---~~~|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^|~~|||~~||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^||~~|^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^++^^|^^+^^^^^^^^^^^+^^>+^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^+^^^^^^++^++^^^^^^+++++^+?++^^^^++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^+++++++^++++^++>>>|+^+++>^+++++++^^^++^^++++^^^^+}yyuuux}>>>>+^^^^^^^^+^.<br></br>
.^++++++^>++++^>v+>?++^++++++++++^|+??+^||^++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++^>??^+>>>+^++++++^|||^vvv^^||~^|^>xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++>>>-``-+^|+^++++^~--|>}v}>>?v>|--~ryuuxzzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++^^|~~+++~-||^++++++^^|^????????|~^^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^|~|~-|^+^^++?v>++++?vv???v?>+++^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~--~-~^+^~^+^^^^++>>++++>????v}v^+++>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?|~+>>>++++++++??++++>???v}}v^++?xyuuuuxzzr}?++++++++++++++>??.<br></br>
.vwxxxz}zyz>>^>vvv>+++++++++vv>+>???vxxuw??vzxuuuxxzzrvvv?>+++++>??>++vuuu~<br></br>
.?}zxuyyuur??v?+++++++++++++?}v}r????wzzz}vvw}rxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++vzxux}}vrz}wxwrrzxzxxxuuxzzzzzxzvvruuuxxxzwwww|<br></br>
-xxxxuuzw}v>}zzwwxuuuxzrv??zuuxxzzxxxuuuuuuxwzzxxxxxxzzzzzzzxxxxxxzzzzzzzz|<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuxxuzwxxzzxuuuuuuzwzxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrrwwwrzwwwwwwwwzxxxzwwrrrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^++++++++++++++++++++++++++++^?vvvvvvvvvvvvvv>^++++++++++++++++++++++++++.<br></br>
                  .||||||||||||^^^^^^^^^^|||^|||^|+++++++++++++^|^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^|^^||||||||||||||||||||||^^^^^^^^|||^^^|||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^++++++++++++++^^^^^^^^^^^^^^^^^^^++++++++++^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~||||~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^?+^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+^^^+>^++^^^^^^+++++^>}>+^+++++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^+++++++^^^++^++>??^+^+++?^+++++++^^++++^++++^^^^+}yyuuux}>>>>+^^^^^^^^+^.<br></br>
.^++++++^>+++^^?}??}??^^+++++++++++?ww>++++++^+?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++^+++>^>?v>>???>+++++++++++?uuz^^^^+?^^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++>??-``->?>>^^+++++++^?uux??rw?+++^ryuuxzzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++^^|^|???^~|^|^+++++++^?uuuuuuux>^+^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^|~+>~|^+^^++vw>+++^?zzxuuxv?>^+^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~--~-~|+^|^^^||^++>>++++>}wwzxuz^+++>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?|~+>>+++++++++??+^++?wwzxuur^+>?xyuuuuxzzr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++wz?^>vzzzxuuz}wzuuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur??v?+++++++++++++}uxwxxzzzxuuuyyuuuxuxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw>rzr?+>?vrrr>++++ruuuuuuzwxuuuuuuuxxuuuuuxzzzzzxzvvruuuxxxzwwww|<br></br>
-xxxxuuzw}v>}zzwwxuuuxzrv??zuuuuuuuzwxuuuuuuxzzxxxxxxzzzzzzzxxxxxxzzzzzzzz|<br></br>
-uuuzv?>>++?>++?v}zxxxxzzzzuuuuuxuxzwxxuuuuuxxxxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrrwwwwxzzzwwwzzxuuxzzzrrrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++vwzwzzzwwzzzzwr>+++++++++++++++++++++++++++.<br></br>
.||||||||||||^^^^^^^^^^|||^|||||+>>???>>>>>>>^|^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^|^^|||||||||||||||||||||||^^^^^^^|||^^^|||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^+++++++++^+++++++++^^^^^^^^^^^^^^++++++++++^^^+++++++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|||||~~~~~|~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^+++++^^^++^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++++++++++^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^+^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^+^+??>+^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^+++^^^^^^+^^^++^+++^^^++++++^+vwvv?+^+++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^++++++++++++^+++++^+^+++>^++++++??vvvv?+^+++^^^^+}yyuuux}>>>>+^^^^^^+++^.<br></br>
.^++++++^>++++^+?^+?++^+++++++++^>?}zzv???+++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++^+>>^^+>+^++++++++++>?uuxv???+>+^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^++++++++++++>+-`--^|~+^++++++++^>zuuv?}v?+++^ryuuxzzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++^^|~~^^^~~||^+++++++++>ruuwvvvvv++^vuuxxrvv?????vv?>+++++++++^.<br></br>
.^++++++^^||^^^^||-~|^+^^>>>?+++++>}xurvv??v++^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~-`~-|^++|^++^^^+??++++++>}xx}v?>?>+>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||+>>>+^+++++>?>+++++?}zx}v?^++?xyuuuuxxzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>+^+vv??+^^^++++>?v>^>?vwzxzzwrwzuuuuuuxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur????>?+?>>^^+++++?r}wxuxzzuuuuyuuuuxuuxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzr>r}v?|~^?vvv>++++vxuuuuxzzuuuuuuuuuxuuuuuxzzzzzxzvvruuuxxxzwwww|<br></br>
-xxxxuuzr}v>}vv?>vvvv?vvv??zuuuuuxxzzuuuuuuuuxxxxxxxxzzzzzzzxxxxxxzzzzzzzz|<br></br>
-uuuzv?>>+^+++^^??vvvrw}vzzuuxxuxxzzzxxxxxxxxxxxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}vv>vvvvv?>}v}}>?rwwzwzxxxzzzzzzzxxxzzzzwrrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^++++++++++++++^+^^+++++++>???}zxzzzzzzzzzzzwv?++++++++++++++++++++++++++.<br></br>
.||||||||||||^^^^^^^^^^^||||||^+>?>>>>?>>>>>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^|^^||||||||||||||||||||||||^^^^^^|||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^++++++++^^^+++++++^^^^^^^^+++^^^++++++++++^^^+++^^++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~^^^^|~~~~~|~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^|>>>>>^^^++^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++>++>+++>+^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>>+^++^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^+?vv>^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^^^+++^^^^^^^^^^++++++^^^++++++^>zuuux?^+++++++^?uyuuuxw?^^^^^^^^^^^^^^^.<br></br>
.^++++++++++++++++++++++++++++++^>zuuuuuz>^+++^^^^+}yyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++++++++++++++++++^?xxuuuxxr+++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++?vxuuuxzw+^+^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++++^^}uuz>v?>>^+^ryuuxzzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++++++++++++>^+++++++^?uuv^+^?w+++vxuxxrvv?????vv?>+++++++++^.<br></br>
.^++++++^^||^^^++++++++++??+++++++^vuuv^++?x>^^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~-`|||^+++++++++^v?++++++^vuu?^^?w?^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||+>>>>^++>++??++++++^vzx>>++++?zyuuuuxzzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz++^|vv???+^|+++>>+>^+>??rzxrw}rwwxuuuuuxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur>????}?}v?>^++++++??wxuuzxuuuyyyuuuxxxxzzww}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxwv>}???^-~^???>++++?zuuuxxzxuuuuuuuuuxuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>v>>|-^+|>+?????zuuxuxzzzxuuxxuxxuxxxxxxxxzzzzzzzxxxxxxzzzzzzwz~<br></br>
-uuuzv?>>+|^++||>?>++?vv+zzuxzzuxzwwxuxwzzzzzzzxxxxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}}v~?vvvv>^v?vv|>wwzzzzuuxzzzxzwzzwwwzzxzwrrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^+++++++++^++++^^^^^++^+++>v}wxxxxzwzzzzzzwwzzr>+++++++++++++++++++++++++.<br></br>
.|||||||||||||^^^^^^^^^^||||||>???>>>>??>>>>>>>^|^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^|^^|||||||||||||||||||||||||^^^^^|||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^++++++++^^^+++++++^^^^^^^^+++^^^++++++++++^^^+++++++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~^^^^^~~~~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^+>>+>>^^^++^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^+>+^+>>+++^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++>++^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+++^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++>?>^^^^^^^^^^^^++++++++^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^+?}rrv^^^^^^^^^++?vv??++++++^++^^^^^^^^^.<br></br>
.^^^^+++^^^^^^^+++++++++^^^++++++^?wzwuu?^++++++^^>???vvvv>^+++++^+++^^^^^.<br></br>
.^++++++++++++^>>+++++++++++++++^>vvvwxxz>^+++^^^^+>}xuyux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++++>+++++++++++++^>??vzz}w?+++++?vrrxyuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++^>?++++++++++++++>>?vxw???+++^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++??++++++++++++++++?wu}+>>++++^ryuuxzzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++>??>+++++>++++++++^>zuv^++>?++^vxuxxrvv?????vv?>+++++++++^.<br></br>
.^++++++^^||^^^+>??>+++++>>+++++++^?xu?^++>??>^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~-`^||>v???++++++?>+++++++?zx?++>?>>?}}vxyuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||?v}}v^+++++>>+++++++?}z>+++++>zyuuuuxxzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>wzrrv^>+^+++>?>++>?vrwx}r}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur?v?vrzrzr?v>^++++>>?wxuuxxxuuyyyuuuxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxz}>r}}}v??v}}v>++++?}xyuxxzxxxuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>vvvv?vvvvv}v???zuuuuxzzzzxxxuuuuuuxxxxxxxzzzzzzzxxxxxxzzzzzzwz~<br></br>
-uuuzv?>>+^+>>??v}}vvrw}vzzuuxxxzzwzxxzzxxzxxxxxxxxzzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}vv>vr}}r}vw}rr??wwzxzxuxxzzzzzzzzzxxzxuxzwrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^++++++++++???????????>++??v}wxxuxzzzzzxzzzzzxw??>+>+++++++++++++++++++++.<br></br>
.||||||||||^^^^^^^^^^^^^|||||^>vvv?????????????+^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||||||||||||||||||||||||||||||^^^|||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^++++++++^^+^^^^^^^^^^^^^^^+++^^^++++++++++^^^+++++++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|^^~^^~~|~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^+>+^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+|^>>^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|+>>^+>>^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>>>>+^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|+>>>>^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|+>>>>^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>vr?^^^^^^^^^^^^++^>>+++^^^^+^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^?zxuux+^^^^^^^^++>>>>+^^++++^++^+^^^^^^^.<br></br>
.^^^^+++^^^^^^^+++++++++^^^+++++^+rz}vuuv^++++++^^>>>+>???>^^>>++^+++^^^^^.<br></br>
.^++++++++++++^v?^+^++++++++++++^??++vzwz+++++^^^^^+?wuyux}>+>++^^+^^^++^^.<br></br>
.^+++++++++++++>+>?^++++++++++++++^^^wr?}++++++?vrrzyuuxxxzwwr}v>>+++++++^.<br></br>
.^++++++++++++++^vv^++++++++++++++++>xv^+++++^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^++++++++++++++>z}++++>+++++++++++^vu?+++++++^ryuuxzzzwvv??vrv?>++++++++^.<br></br>
.^+++++++++++++^?uw?^++>>>^+++++++++wu?^++++>+^vxuxxrvv?????vv?>+++++++++^.<br></br>
.^++++++^^||++^^?xzv^+++++++++++++++wu?^+++^v?^^>ruxzwv?????v?>+++++++++++.<br></br>
.+++++++^~--+|~?xxz}^+++++++++++++++wz?>++++??}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||wuux}++++++++>++>++>+vw++++++>zyyuuxxxzr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^?uuuur^>>++++>}+++>>v}ww}}}rwwxuuuxxzzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur?rvrxuuuz+}>^+++>?+>wxxxuzzuuyyyuuuxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw>wzzxuuuuwuw>++++??wyuxxzzzzuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzw}v>}zzxuuuuuuxrv??zuuuuxzwzzwwxuuuuuuxxxxxxxzzzzzzzxxxxxxzzzzzzwz~<br></br>
-uuuzv?>>++???rzzxuuuxzzzzzuuuuxzwwxzzwzxxxxuxxxxzzzzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}zzzwwxuuzzzwrwzxxxxuxzzzzwwxxzzxuxxuxzwrrrrrwrrrr}}}vvvvvvvvvv-<br></br>
.^+++++++++>wzzzwzxxwwzv^>v}}}zzxuxzzzzzxxzzzxxzww?+>+++++++++++++++++++++.<br></br>
.||||||||||^+>>>>+++>>>^|||||^?vvv?????????????>++|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||^^^|||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^++++++++^^++++^^+^^^^^^^^^+++^^^++++++++++^^^+++++++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|||~||~~||~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^+++^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^++^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^+++^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>++^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^+>>>+^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^^^^>??}wv^^^^^^^^^^^^^+^++++^^^^+^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^+^++^^^^^^^^^^^^^^+?wvv}z+^^^^^^^^++>>>+>^^^^+^+>+^^^^^^^^^.<br></br>
.^^^^+++^^^^^+++^+?+++++^^^+++++^+???>}w>^^+++++^^>?>>>?vv>^++>+^^+++^^^^^.<br></br>
.^++++++++++++^>+>?+++++++++++++^+>++?v??+^+++^^^^+?vvvvvv?+++++^^^^^^++^^.<br></br>
.^+++++++++++++++?v+++++++++++++++++^v?+?++++++?vrrxyx}???????vv>>+++++++^.<br></br>
.^+++++++++++++++}r>++++++++++++++++>r?++++++^?xuuuzrxx?????>}wv?>>++++++^.<br></br>
.^++++++++++++++?zw?++++++++++++++++?w>+++++++^ryuuv?v}??>>>>v?>>++++++++^.<br></br>
.^+++++++++++++>vxz?+++++++++++++++^vz>^++++++^vxuxw?>>>>>>>>>>>+++++++++^.<br></br>
.^++++++^^||^+^>vxz?^+++++++++++++++}z>+++++?>^^>rx}rw?>+>>>>>>+++++++++++.<br></br>
.+++++++^~--+||>ruz?++++++++++++++++v}>+++++>?}}vxyuzwwv?>+>?++++++++++++^.<br></br>
.^+>>>+^>?r??||vzux?++?+++++++++++++>?++++++>zyuuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz?>^?zxuuv^>v+++++?+++>>?vwr}}}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuur?vv}zxuur+}?^++++>^>wxxxuxxuyyyyuuuxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw?wzwzxuuxwxw>++++??wyuxxzxxxuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}vvwzzxxuuuuuzrvv}zuuuuxzwzxxxxuuuuuuxxxxuuxzzzzzzzxxxxxxzzzzzzwz~<br></br>
-uuuzv?>>+>vv?}xxuuuuuxzxxxuuuuxzwzxzzxxxxxuuxxxxzxxzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}wzxzzxxxuxxzzwzxxxxxxzzzzzzzxxzxuuxxxxzzzzrrwwrrrr}}}vvvvvvvvvv-<br></br>
.^+++++++++?wzzzzzzxxzz}>>v}}rzxxxxxxxxuuxxxxxxxzwv??+++++++++++++++++++++.<br></br>
.||||||||||^>>>>>>>>?>>+^||^|+?vv}vvvvvvv???????>++^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||||^|||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^++++++++^^++++++++^^^^^^^^+++^^^+++++^^^++^^^+++++++++^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^+^++^^^^^^^^^^^^^^^^^+v}>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^+?^^^^^^^^^^^^^^^^^^?wrwzw^^^^^^^^^^^^^+++^^^^+^^+^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^++^^+^^^^^^^^^^^^^^>rr>^>w+^^^^^^^^^+?>>+v^+^^+|+>^^^^^^^^^^.<br></br>
.^^^^+++^^^^^+++^?r+^+++^^^+++++^>>+^^>v^++++++++^?x>>+v}w?^^^>>^^+++^^^^^.<br></br>
.^++++++++++++++^}x>^^++++++++++++^+++^^++++++^^^^+}}++>???>>>++^^^^^^++^^.<br></br>
.^++++++++++++++^rx?+++++++++++++++++>^++++++++?vrrxyz?^>>>>++vv>>+++++++^.<br></br>
.^+++++++++++++^?zx}+++++++++++++++++v+>+++++^?xuuuwvxx+>>>>+}wv>>>++++++^.<br></br>
.^++++++++++++++rzxz++++++++++++++++>v^+++++++^ryux?^>v+>>>>>??+++++++++++.<br></br>
.^+++++++++++++vzzzv++++++++++++++++?r^+++++++^vuuxr?+++>^^^^++>++++++++++.<br></br>
.^++++++^^||^^+vwxz?^++++++++++++++^?}^+++++++^^>rxv}w?+^^>+++++++++++++++.<br></br>
.+++++++^~--^||^?uz>++>++++++++++++++?++++++^>}}vxyu}vwv?>++?+^^++++++++++.<br></br>
.^+>>>+^>?r}?||>rux?^>r+++++++++++++++++++++?xyuuuuxwwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyxv>^>rzuu?^>z>++++++++>>+vr}}r}rwwxuuuuxxzzrvvv?>+++++>??>++vxuu~<br></br>
.?}zxuyyuuw>?vvwzuuv^vv^+++++^>wxxzxxuyyyyyuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxxw?wzwwzuuzrxw>+++>??wyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}}zxwwzxuuuxxzrvrwzuuuuxzwxxxuuuuuuuuuuxuuuxzzzzzzzxxxxxxzzzzzzwz~<br></br>
-uuuzv?>>+?zw?vxuuuuuuxxxuxuuuuxzzzzzxuuxuuuuxxxxxxuzzzxxxxzzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}wxxxxuxxuuxxzwzuuuxxxwwzzzzxxzzxuuzzzzzxuxrrwwrrrr}}}vvvvvvvvvv-<br></br>
.^++++++++>wzwwzzzzuuzzzv?v}vwxuxxxxxxuuuuxxxxxxzzzzv++>++++++++++++++++++.<br></br>
.||||||||||+>+>>??>>????^||^^??v}}}}vv}}vvvvvvv?>>>>^|^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||||||||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^^+^^^++^^^^^^^^^++^^^^^^^^^^^^^^+++++^^^^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>vv>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+>^++^^^^^^^^^^^^^^^?}vvr}^^^^^^^^^^^^^+++^^^^+^^+^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^+^+>+^^^^^^^^^^^^^+vv+^>v^^^^^^^^^^+?>??v++^^+^+>^^^^^^^^^^.<br></br>
.^^^^+++++++++++^?w>^+++^^^+++++^+++^^+?^++++++++^?x??>vrw?^^+>+^^+++^^^^^.<br></br>
.^+++++++++++++^+}x?++++++++++++++++++^^++++++^^^^+}w?>>?v?>>>++^^^^^^++^^.<br></br>
.^++++++++++++++>rz?+++++++++++++++++++++++++++?vrrxyzv>????>>vv>>+++++++^.<br></br>
.^++++++++++++++?zxr+++++++++++++++++?+++++++^?xuuuw}xx>>>>>>}wv?>>++++++^.<br></br>
.^++++++++++++^>wzxz>++++++++++++++++?^+++++++^ryux?>?}>>>>>>??+>+++++++++.<br></br>
.^+++++++++++++vrwz?++++++++++++++++?v^+++++++^vxuxw?+>>>++^+>>>++++++++++.<br></br>
.^++++++^^||^^^?vzw>++++++++++++++++?v^+++++++^^>rxv}}?+++>+++++++++++++++.<br></br>
.+++++++^~--+||^?zw+++>>+++++++++++++?++++++^>}}vxyuwrr?>>>+>>+^++++++++++.<br></br>
.^+>>>+^>?rv?||>vxz>+>}>+++++++++++++>++++++?xyuuuuxzwrv>>+++++++++++++>?>.<br></br>
.vwxxxx}zyxv>^>}wxz?^>w?++++++++>>+vrr}}}rwwxuuuuxxzzr}?>>>+++++>??>++vxuu~<br></br>
.?}zxuyyuxwvvv?vwxx?^vv++++++^>wxxzxxuyyyyyuuxxxxxzzzwrv???>++^vuuuuzwwwwz|<br></br>
.>>wuuuuxxz}wzr}zxuwrxw>++++??wyuxxxuuuuuuuuuuuuuuuxxzwv??vv??rxzzxxxzwwwz|<br></br>
-xxxxuuzr}rxxzzzxuuuxuxr}}rzuuuxxzwxxxuuuuuuuuuuxuzzwrvvvvvvvv}rwrwxzzzzwz~<br></br>
-uuuzv?>>>vxz?vzuuuuuuxxxxxuuuxzzzzzxxuuxuuuuxxxxxzrvvv}wwwrrv??vv}}vvvvvv-<br></br>
`}}}?>?v}vrzxuxuuxxuuxxxzzxxxxzzwwzzzxxxzzzxxwzzzwz}vv}wwwrrr}v???vvvvvvvv-<br></br>
.^++++++++?wzwzzzzzxxxzzv?vr}wzzwwwwwzzzzzzwrwzzzrrrv>++++++++++++++++++++.<br></br>
.||||||||||+>+>>>?>???>>^||^^??v}vvvvvvvvvvv????>>>+^|^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||||||||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^^^^^^^++^^++^^^^^^^^^++^^^^^^^^^^^^^^+++++^^^^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?vvv+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?+^^^^^^^^^^^^^^^?v??v?^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?v+^^^^^^^^^^^^^^>+^^+>^^^^^^^^^^+vwuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^++++++++++^+wyv^+++^^^+++++++^++++^+++++++++^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^+++++++++++++^?xxz++++++++++++++++++++++++++^^^^+}yyuuux}>>>>+^^^^^^++^^.<br></br>
.^+++++++++++++^}zxx>++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++>wzzu?^+++++++++++++++++++++++^?xuuuuxxxxr}}vvrrv??>++++++^.<br></br>
.^+++++++++++++vzzzw?+++++++++++++++++++++++++^ryuuxzwz}>>^+>?v>>+++++++++.<br></br>
.^+++++++++++++?vrw>++++++++++++++++++++++++++^vuuxxr>v>>>>^>>>+++++++++++.<br></br>
.^++++++^^||^^^+^}}^+++++++++++++++++>++++++++^^>ruxr??+>>>|+>++^+++++++++.<br></br>
.+++++++^~--+||^^}r^++}v+++++++++++++?++++++^>}}vxuuzz}^>>++>>++++++++++++.<br></br>
.^+>>>+^>?r^?||+>wr+++}r^++++++++++^?r++++++?xyuuuuxxzr++>>>>>+++++++++>?>.<br></br>
.vwxxxz}zyz>>^?v}xw++^rv^+++++++>>+vwzr}}rwwxuuuuxxzzr}>+>>>>+++???>++vuuu~<br></br>
.?}zxuyyuxzxwv?+vxz++?}++++++^>wxxzxxxyyyyyuuxxxxxzzzwr?+>>>>+^vxyuuzwwwwz|<br></br>
.>>wuuuuxxxxwwr?wxu}rxw>++++??wyuxxxuuuuuuuuuuuuuux}wzr+>>++++rrv}wxxzwwwz|<br></br>
-xxxxuuzrwxxxxzzxuuuxxxr}v?wuuuxxzwxuuuuuuuuuuuuxxv???>>+^^+^+????vxzzzzwz~<br></br>
-uuuzv?>>?zuuw?vxuuuuuxzxxxuuuxzzzzzxuxuuxxxxzxxxx}>>++>vvv??++>??v}vvvvvv-<br></br>
`}}}?>?v}}wxuuuuuxxxuuuuuxxzwwwrrrzxxuxxxzwrrrrrrw}^^>vwwwrrrv?>+^?vvvvvvv-<br></br>
.^++++++++?zzzzxzzzzxxxwrv}wzxw}??vvvvvv}vv?>?vrrvv?>?++++++++++++++++++++.<br></br>
.|||||||||^+++>>>>>>?>++|||^>v}}}v????>>?????????>^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||||^||^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^+++++++^^^^^+++^++++++^^^^^^^^++^^^^^^^^^+++^^^++^^^^^^^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?v??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?+^^^^^^^^^^^^^^^>v?>v?^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?v>^^^^^^^^^^^^+^>+^^+>^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^++++++++++^+zyv^+++^^^+++++++^^+++^+++++++++^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^+++++++++++++^?xzw++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^^^^^++^^.<br></br>
.^+++++++++++++^rrww>++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++>wwwx?^+++++++++++++++++++++++^?xuuuuxxxxw}}vvrrv??>++++++^.<br></br>
.^+++++++++++++vzzzw?+++++++++++++++++++++++++^ryuuxzwz}>>+>>?v>>+++++++++.<br></br>
.^+++++++++++++?vrw>++++++++++++++++++++++++++^vuuxxr>v>>>>+>>>+++++++++++.<br></br>
.^++++++^^||^^^+^}}^+++++++++++++++++>++++++++^^>ruxr??+>>?+>>++^+++++++++.<br></br>
.+++++++^~--+||^^}}^++}v+++++++++++++?++++++^>}}vxuuzzr+?>>>>>++++++++++++.<br></br>
.^+>>>+^>?r^?||+>r}+++}r^++++++++++^>}^+++++?xyuuuuxxzw>>???>>+++++++++>?>.<br></br>
.vwxxxz}zyz>>^?vvzr++^rv^+++++++>>+vwz}}}rwwxuuuuxxzzr}?>???>>>+???>++vuuu~<br></br>
.?}zxuyyuxzxwv?+vzz++?}++++++^>wxxzxxxyyyyyuuxxxxxzzzww?>????>+vxuuuzwwwwz|<br></br>
.>>wuuuuxxxzwwr?rxu}rxw>++++??wyuxxxuuuuuuuuuuuuuuxrwzr>>?>>>>rrv}zxxzwwwz|<br></br>
-xxxxuuzrwxxxxzzxuuuxxxr}v?wuuuxxzwxuuuuuuuuuuuuxxv???>>>+>>+>????}xzzzzwz~<br></br>
-uuuzv?>>vzuxr?vxuuuuuxzxxxuuuxzzzzzxuxuuxxxxzxxxx}??>>?}}v??>>>?v}}}vvvvv-<br></br>
`}}}?>?v}}zxuuuuuxxxuuuuuxxzwwrrrrzxxuxxxzwrrrrrrw}+>?vwzwwww}?>>+?vvvvvvv-<br></br>
.^++++++++vzzzzzzzzzxxxwrvvrzwrv??vvvvvvvvv?>?v}}vv???>>>>>>>>>>++++++++++.<br></br>
.|||||||||^++^+>>>>>?>+^^||+?v}}v????>++????????>>^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||||||||^^^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^++++++^^^^^^+++^++++++^^^^^^^^++^^^^^^^^^^^+^^^+++^^+++^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^++^^>>^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^vuv^^^^^^^^^^^^^^++^^++^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^++++++++++^?xxw++++^^^++++++++^++^++++++++++^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^++++++++++++++}v?v++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++r+^+^++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++^>xv>v>^+++++++++++++++++++++++^?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^+++++++++++++?xxxz>+++++++++++++++++++++++++^ryuuxxzzz}vvvvrv?>+++++++++.<br></br>
.^++++++++++++++?wz?++>}++++++++++++++++++++++^vxuxzrvv}v?vvvv?>++++++++++.<br></br>
.^++++++^^||^^^+^vv^+^?}++++++++++++++++++++++^^>ruxzwvw}vwrv?>+++++++++++.<br></br>
.+++++++^~--+||^^??+++??++++++++++++++++++++^>}}vxuuuxwzuzzw?+^+++++++++++.<br></br>
.^+>>>+^>?r^?||>>?>+++>v++++++++++++++++++++?xyuuuuxzzzuuuxz??v>+++++++>?>.<br></br>
.vwxxxz}zyx?++>vv}v+++rv^+++++++>>+vr}}r}rwwxuuuuxxzzrzuuuuzzxuw>??>++vuuu~<br></br>
.?}zxuyyuuxz?v?++rr^^?}++++++^>wxxzxxuyyyyyuuxxxxxzzzwzuuuuxuxzzuuuuzwwwwz|<br></br>
.>>wuuuuuuw?rzr?+wxvrwr>++++??wyuxxxuuuuuuuuuuuuuuuuxzxxuuuuxzwxuuxxxzwwwz|<br></br>
                  -xxxxuuzzuzzxzzxwxuuxxzrrvvzuuuxxzwxuuuuuuuuuuuuxuuuxzzzuuxxxzzzxxxxxzzzwz~<br></br>
-uuuzv?>?xuu}++vrxuuuuzzuxxuuuxxzzzxxuuuuuxxxxxxxxxxxzzzuxzzwzzzzxxzrvvvvv-<br></br>
`}}}?>?v}zuuxxzxuuuuxxxuuzrwwwrrwwwwwzzwwwrrrrrrrrwzzzzzuxzzzzzzzwrvvvvvvv-<br></br>
.^+++++++vzzwzxxxxxzzwwzz?>v?>>>++>>>?v????>+++++>?}ww}}}}}vvv}}?>++++++++.<br></br>
.||||||||^+++++>++>>>++++|+>??v}?>>>>^||+?????+^^++++^^^^^^||^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||||||^|^^^^||||||^^^^|^|||||||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^+++++++^^^++++^^++++++^^^^^^^^++^^^^^^^^^^^^^^^^^^^^+++^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~~~~~~~|||~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>+^^^^^^^^^^^^^^++^^>>^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^vuv^^^^^^^^^^^^^^++^^++^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^++++++++++^?xxw++++^^^++++++++^++^++++++++++^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^++++++++++++++}v?v++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++r+^+^++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++>xv>v>^+++++++++++++++++++++++^?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^+++++++++++++?xxxz>+++++++++++++++++++++++++^ryuuxzzzz}vvvvrv?>+++++++++.<br></br>
.^++++++++++++++?wz?++>}++++++++++++++++++++++^vxuxzrvv}v?vvvv?>++++++++++.<br></br>
.+++++++^^||^^^+^vv^+^?}++++++++++++++++++++++^^>ruxzrvw}?wr??>+++++++++++.<br></br>
.+++++++^~--+~|^^??+++??+++++++++++++++++++++>}}vxuuuxwzuzzw?+^++++++++++^.<br></br>
.^+>>>+^>?r^?||>>?>+++>v++++++++++++++++++++?xyuuuuxzwzuuuxz??v>^++++++>?>.<br></br>
.vwxxxz}zyx?++>vv}v+++rv^+++++++>>+vr}}r}rwwxuuuuxxzzrzuuuuzzxuw>??>++vuuu~<br></br>
.?}zxuyyuuxz?v?++rr^^?}++++++^>wxxzxxuyyyyyuuxxxxxzzzwzuuuuxuxzzuuuuzwwwwz|<br></br>
.>>wuuuuuuw?wzr?+wxvrwr>++++??wyuxxxuuuuuuuuuuuuuuuuxzxxuuuuxzwxuuxxxzwwwz|<br></br>
                  -xxxxuuzzuzzzzzxwxuuxxzrrvvzuuuxxzwxuuuuuuuuuuuuxuuuxzzzuuxxxzzzxxxxxzzzzz~<br></br>
-uuuzv?>?xuu}++vrxuuuuzzuxxuuuxxzzzxxuuuuxxxxxxxxxxxxzzzuxzzwzzzzxxzrvvvvv-<br></br>
`}}}?>?v}zuuxxzxuuuuxxxuuzrwwwrrwwwwwzzwwwrrrrrrrrrzzzzzuxzzzzzzzwrvvvvvvv-<br></br>
.^+++++++vzzwzxxxxxzzwwzz?>v?>>>++>>>?v????>+++++>?}ww}}}}}vvv}}?>++++++++.<br></br>
.||||||||^+++++>++>>>++++|+>??vv?>>>>^||+?????+^^++++^^^^^^||^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||||||^|^^^^||||||^^^^|^|||||||||||||||^^^^^^^^^^.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^++++^^^^^^++++^^++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^^^^+++++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|||~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^>v?^^^^^^^^^^^^^+^+^^^^+^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^+^+ryz+^^^^^^^^^^^^+^^^^^^^^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^^^++++++++++^?xrr>^+++++++++++++++++++++++++++^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^+++++++++++++^v?++++++++++++++++++++++++++++^^^^+}yyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++?^+^+++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++}?^??^+++++++++++++++++++++++^?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++>xr}u?++>++++++++++++++++++++++^ryuuxxzzw}?vvvrv?>++++++++^.<br></br>
.^++++++++++++++?zxv+++w>+++++++++++++++++++++^vuuxxwvvrw?}vvv?>++++++++++.<br></br>
.+++++++^^||^^^+^?v^+++v++++++++++++++++++++++^^>ruxzwvzurxrv?>+++++++++++.<br></br>
.+++++++^~--+||^^>>++++?++++++++++++++++++++^>}}vxuuuxwxuuzw?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>+++^v}^+++++++++++++++++++?xyuuuuxzzzuuxzw?vzv^++++++>?>.<br></br>
.vwxxxz}zyx>++>vvv?+++w?++++++++>>+vr}}r}rwwxuuuuxxzzrzuuuxzzxur>??>++vuuu~<br></br>
.?}zxuyyuuxv?v?++v}^++?++++++^>wxxzxxuyyyyyuuxxxxxzzzwzuuuuxuxzzuuuuzwwwwz|<br></br>
.>>wuuuuuxw?rzr?+vzvrr}>>+++??wyuxxxuuuuuuuuuuuuuuuuxzxxuuuuuzzxuuuxxzwwwz|<br></br>
                  -xxxxuuzxxzxwzxzwxuuxxzrrv?wuuuxxzwxuuuuuuuuuuuuxuuuxzzzuuxxxzzxxxxxxzzzzz~<br></br>
`uuuzv?>?uxu?^>?vxuxxxxzxuzuuuxxzzzxxxxuuxxxxxxxxxxxxxzzuxzzwzzzzxxw}vvvvv-<br></br>
`}}}?>?vrzuuxzzxxuuuuuxxuxwwwwwwwrwwwzzzzwwrrrrrrrrzxxzzxzzzzzzzzw}vvvvvvv-<br></br>
.^+++++++}wzwxuxxuuxzzwzzw?+^++++++++}?>>>+++++++++?rwr}}vvvvvvv?+++++++++.<br></br>
.||||||||^^+++>>>>>>>+^++>?>?>+^|^^^^^^||^^^^^+^^^^^++^||||^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||^++^^|^++^|||^^+++++^|^^||||^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+++^^^+++^^^+++++++++++^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^++^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^>v?^^^^^^^^^^^^^+++^^^^+^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^+^+ryz+^^^^^^^^^^^^++^^^^^^^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^^^++++++++++^?xrr>^+++++++++++++++++++++++++^+^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^^++++++++++++^v?++++++++++++++++++++++++++++^^^^+}yyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++?^+^+++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^++++^.<br></br>
.^++++++++++++++}?^??^++++++++++++++++++++++++?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++>xwru?++>++++++++++++++++++++++^ryuuxzzzz}?vvvrv?>++++++++^.<br></br>
.^++++++++++++++?zxv+++w>+++++++++++++++++++++^vxuxxwvvrw?}vvv?>++++++++++.<br></br>
.^++++++^^||^^^+^?v^+++v++++++++++++++++++++++^^>ruxzwvzurxrv?>+++++++++++.<br></br>
.^++++++^~--+||^^>>++++?+++++++++++++++++++++>}}vxuuuxwxuuzw?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>+++^v}^+++++++++++++++++++?xyuuuuxzzzuuxzw?vzv^++++++>??.<br></br>
.vwxxxz}zyx>++>vvv?+++w?++++++++>>+vr}}r}rwwxuuuuxxzzrzuuuxzzxur>??>++vuuu~<br></br>
.?}zxuyyuuxv?v?++v}^++?++++++^>wxxzxxuyyyyyuuxxxxxzzzwzuuuuxuxzzuuuuzwwwwz|<br></br>
.>>wuuuuuxw?rzr?+vzvrr}>>+++??wyuxxxuuuuuuuuuuuuuuuuxzxxuuuuuzzxuuuxxzwwwz|<br></br>
                  -xxxxuuzxxzxwwxzwxuuxxzrrv?zuuuxxzwxuuuuuuuuuuuuxuuuxzzzuuxxxzzxxxxxxzzzwz~<br></br>
`uuuzv?>?uxu?^>?vxuxxxxzxuzuuuxxzzzxxxxuuxxxxxxxxxxxxxzzuxzzwzzzzxxw}vvvvv-<br></br>
`}}}?>?vrzuuxzzxxuuuuuxxuxwwwwwwwrrwwzzzzwwrrrrrrrrzxxzzxzzzzzzzzw}vvvvvvv-<br></br>
.^+++++++}wzwxuxxuuxzzwzzw?+^++++++++}?>>>+++++++++?rwr}}vvvvvvv?+++++++++.<br></br>
.||||||||^^+++>>>>>>>+^++>?>?>+^|^^^^^^||^^^^^+^^^^^++^|||^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||^++^^|^++^|||^^+++++^|^^|||||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+++^^^^^^^^^+++++^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^?w?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>xuz+^^^^^^^^^^^++^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++^?zv?+^+++++++++++++++++++++++++^+^?uyuuuxw?^^^^^+++++^^^^^.<br></br>
.^^++++++++++++^v>^^^+++++++++++++++++++++++++^^^^+}yyuuux}>>>>+^^^^^^++^^.<br></br>
.^++++++++++++++>^+^+++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^++++^.<br></br>
.^++++++++++++++v>^??+++++++++++++++++++++++++?xuuuuxxxxwrr}}wrv??>++++++^.<br></br>
.^++++++++++++++x}?x?++?++++++++++++++++++++++^ryuuxzzzzzvvvvrv?>++++++++^.<br></br>
.^++++++++++++++?xx}+++}>+++++++++++++++++++++^vxuxxrvvrurvvvv?>++++++++++.<br></br>
.^++++++^^||^^^+^??^+++>++++++++++++++++++++++^^>ruxzwvzuxxwv?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++>}+++++++++++++++++++++>}}vxuuuxzxuuxz?>?>+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++v}^+++++++++++++++++++?xyuuuuxzwwuuuzz>vuv^++++++>??.<br></br>
.vwxxxz}zyz>>^>vvv?+++v>++++++++>>+vr}}r}rwwxuuuuxxzzrruuuzzwxur>??>++vuuu~<br></br>
.?}zxuyyuuzv?v?++?v^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwwuuuuxuuzzuuuuzwwwwz|<br></br>
.>>wuuuuuxw>rzr?+?wvrr}>+>++??wyuxxxuuuuuuuuuuuuuuuuxzzxuuuuuxzuuuuuxzwwwz|<br></br>
-xxxxuuxxxxxwzxzwxuuuxzr}}?wuuuxxzwxuuuuuuuuuuuuxuuxxzzzuuxuxzzxxxxxxzwzwz~<br></br>
-uuuzv?>vuxx?+>?vxuxxxzzxuxuuuxxzzzxxxxuuuxxxxxxxxxxxxxzuxzzwzzzzxxw}vvvvv-<br></br>
`}}}?>?vrxuuxzzxxuuuuuuxuxwwwwwwwrrrrrrrrrrrrrrrrrrzxuxzxzzzzzzzzw}vvvvvvv-<br></br>
.^++++++>rzzwxxxxuuxxwrzzw?++++++++++++++++++++++++?}}r}vvvvvvv}?+++++++++.<br></br>
.||||||||^+++++++>>>>+^++^^|||||||||||||^^^^^^^^^^^|^^^^^^|^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||^^^^|^^^^^^|||||^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++^^^^^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^?w?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>xuz+^^^^^^^^^^^++^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++^?zv?+++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^++++++++++++^v>^^++++++++++++++++++++++++++^^^^+}yyuuux}>>>>+^++^^^++^^.<br></br>
.^++++++++++++++>^+^+++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^++++^.<br></br>
.^++++++++++++++v>^??++++++++++++++++++++++++^?xuuuuxxxxwrr}}wrv??>++++++^.<br></br>
.^++++++++++++++x}?z?++?++++++++++++++++++++++^ryuxxzzzzzvvvvrv?>++++++++^.<br></br>
.^++++++++++++++?xx}+++}>+++++++++++++++++++++^vuuxxrvvrurvvvv?>++++++++++.<br></br>
.^++++++^^||^^^+^??^+++>++++++++++++++++++++++^^>ruxzwvzuxxwv?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++>}+++++++++++++++++++++>}}vxuuuxzxuuxz?>?>+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++v}^+++++++++++++++++++?xyuuuuxzwwuuuzz>vuv^++++++>??.<br></br>
.vwxxxz}zyz>>^>vvv?+++v>++++++++>>+vr}}r}rwwxuuuuxxzzrruuuzzwxur>??>++vuuu~<br></br>
.?}zxuyyuxzv?v?++?v^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwwuuuuxuuzzuuuuzwwwwz|<br></br>
.>>wuuuuuxw>rzr?+?zvrr}>+>++??wyuxxxuuuuuuuuuuuuuuuuxzzxuuuuuxzuuuuuxzwwwz|<br></br>
-xxxxuuxxxxxwzxzwxuuuxzr}}?zuuuxxzwxuuuuuuuuuuuxxuuxxzzzuuxuxzzxxxxxxzzzzz~<br></br>
`uuuzv?>vuxx?+>?vxuxxxzzxuxuuuxxzzzxxxxuuuxxxxxxxxxxxxxzuxzzwzzzzxxw}vvvvv-<br></br>
`}}}?>?vrxuuxzzxxuuuuuuxuxwwwwwwwrrrrrrrrrrrrrrrrrrzxuxzxzzzzzzzzw}vvvvvvv-<br></br>
.^++++++>rzzwxxxxuuxxwrzzw?++++++++++++++++++++++++?}}r}vvvvvvv}?+++++++++.<br></br>
.||||||||^+++++++>>>>+^++^^||||||^|||||||||^^^^^^^^|^^^^^^|^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||||^^^|||||^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^++^^^^^^^^^^+++++++^^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^vz}+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>xww>^^^^^^^^^^^^++^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++^?}+++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^++++++++++++++?+++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^++^^^++^^.<br></br>
.^+++++++++++++++^+++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^++++^.<br></br>
.^++++++++++++++>+^+>++++++++++++++++++++++++^?xuuuuxxxxzzr}rwrv??>++++++^.<br></br>
.^+++++++++++++^}>+v?++?>+++++++++++++++++++++^ryuxxzzzzxxv?vrv?>++++++++^.<br></br>
.^+++++++++++++^?rwr+++?++++++++++++++++++++++^vuuxzrvvwuxr?vv?>++++++++++.<br></br>
.^++++++^^||^^^+^?v++++?++++++++++++++++++++++^^>ruxzwvzuxz}v?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++>w++++++++++++++++++++^>}}vxuuuxzxuuuw?vwv+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++?v++++++++++++++++++++?xyuuuuxzwwxuxzw>?uw+++++++>??.<br></br>
.vwxxxz}zyz>>^>vvv>+++?+++++++++>>+vr}}r}rwwxuuuuxxzzr}xuxzzvvuw???>++vuuu~<br></br>
.?}zxuyyuxz??v?++>?^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwrxuxzxuuuxuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>rvrrr>++>+??wyuxxxuuuuuuuuuuuuuuuuxzzxuuxxuxxuuuuuuzzwwz|<br></br>
                  -xxxxuuxxzxwrxzzwxuuuxzrvvvwuuuxxzwxuuuuuuuuuuuxxuuxxzzzuxxuxzzxxxxxzzzzzz~<br></br>
`uuuzv?>rxxz>>+?vwuxxxzzzuxuuuxxzzzxxxxuuuxxxxxxxxxxxxzzxzzzwzzzzxxrvvvvvv-<br></br>
`}}}?>?vrxuuxwwzxxuxxxxxxxwwwwwwwrrrrrrrrrrrrrrrrrwzxuuxzzzzzzzzxz}vvvvvvv-<br></br>
.^++++++>wzzxxxxuuuuxzrwzzv+++++++++++++++++++++>++v}}}}vvvv}}}}v+++++++++.<br></br>
.||||||||+>>>>++>>??>+|+>>^||||||^|||||||||^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||^^^^^^^^|^^^||^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++^^^+++++++^^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^vz}+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^+++^^^^^^^^>xww>^^^^^^^^^^^^++^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++^?}+++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++?+++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^++^^^++^^.<br></br>
.^+++++++++++++++^+++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^++++^.<br></br>
.^++++++++++++++>+^+>+++++++++++++++++++++++++?xuuuuxxxxzzr}rwrv??>++++++^.<br></br>
.^+++++++++++++^}>+v?^^?>+++++++++++++++++++++^ryuxxzzzzxxv?vrv?>++++++++^.<br></br>
.^+++++++++++++^?rwr>++?++++++++++++++++++++++^vuuxzrvvwuxr?vv?>++++++++++.<br></br>
.^++++++^^||^^^+^?v++++?++++++++++++++++++++++^^>ruxzwvzuxz}v?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++>w++++++++++++++++++++^>}}vxuuuxzxuuuw?vwv+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++?v++++++++++++++++++++?xyuuuuxzwwxuxzz>?uw+++++++>??.<br></br>
.vwxxxz}zyz>>^>vvv>+++?+++++++++>>+vr}}r}rwwxuuuxxxzzr}xuxzzvvuw???>++vuuu~<br></br>
.?}zxuyyuxz??v?++>?^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwrxuxzxuuuxuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>rvrrr>++>+??wyuxxxuuuuuuuuuuuuuuuuxzzxuuxxuxxuuuuuuzzwwz|<br></br>
                  -xxxxuuxxzxwrxxzwxuuuxzrvvvwuuuxxzwxuuuuuuuuuuuxxuuxxzzzuxxuxzzxxxxxzzzzzz~<br></br>
`uuuzv?>rxxz>>+?vwuxxxzzzuxuuuxxzzzxxxxuuuxxxxxxxxxxxxzzxzzzwzzzzxxrvvvvvv-<br></br>
`}}}?>?vrxuuzwwzxxuxxxxxxxwwwwwwwrrrrrrrrrrrrrrrrrwzxuuxzzzzzzzzxz}vvvvvvv-<br></br>
.^++++++>wzzxxxxuuuuxzrwzzv+++++++++++++++++++++>++v}}}}vvvv}}}}v+++++++++.<br></br>
.||||||||+>>>>++>>??>+|+>>^||||||^|||||||||^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||^^^^^^^^|^^||^^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^++++++^^^++++++++^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+rxr+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^+++^^^^^++^>wvv>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++^?>^^+++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++?^++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxxzwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxw}rrv??>++++++^.<br></br>
.^++++++++++++++v+^>>++?>+++++++++++++++++++++^ryuxxzzzzuuwvvrv?>++++++++^.<br></br>
.^+++++++++++++^??vw?+++++++++++++++++++++++++^vuuxzrvvzuuw?vv?>++++++++++.<br></br>
.^++++++^^||^^^++}w?+++?>+++++++++++++++++++++^^>ruxzwvruuxvv?v+++++++++++.<br></br>
.^++++++^~--+||^+++++++v++++++++++++++++++++^>}}vxuuuxwwuuur?}u?+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>++++>++++++++++++++++++++?xyuuuuxzwrzuxux>+zr^++++++???.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}xxzxuv?x}>??>+>vuuu~<br></br>
.?}zxuyyuxw??v?++>>^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwrxzwzxzxxruuuxzzwzwz|<br></br>
.>>wuuuuxzz?rzr?+>vvrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzzzzxxuuuuuxuuzwzwz|<br></br>
                  -xxxxuuxzwzwrzzzwxuuuxzrvrrwuuuxxzwxuuuuuuuuuuuxuuuuxzzzzzzxuxzxxxxxzzzzzz~<br></br>
`uuuzv?>?v>v>++?vwuxxxzzzuxuuuxxzzzxxxxuuuxxxxxxxxxxxzzxxzxuuxzzzxx}vvvvvv-<br></br>
`}}}?>?vrzxzwwwzzxxxxxxzxxwwwwwwwrrrrrrrrrrrrrrrrrwzxuuuxxxuxzzzxz}vvvvvvv-<br></br>
.^++++++?zzzzxxxxxxxzxxxxz?+++++++++++++++++++++++>vrrr}}}v}}}}}v+++++++++.<br></br>
.|||||||^++++++++>>>>>++++^||||||^|||||||||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||^^^^^^^^^^^||^||||^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+rxr+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^+++^^^^^++^>wvv>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^++++++++++++^?>^^+++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++?^++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxxzwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxw}rrv??>++++++^.<br></br>
.^++++++++++++++v+^>>++?>+++++++++++++++++++++^ryuxxzzzzuuwvvrv?>++++++++^.<br></br>
.^+++++++++++++^??vw?+++++++++++++++++++++++++^vuuxzrvvzuuw?v??>++++++++++.<br></br>
.^++++++^^||^^^++}w?+++?++++++++++++++++++++++^^>ruxzwvruuxvvvv+++++++++++.<br></br>
.^++++++^~--+~|^+++++++v++++++++++++++++++++^>}}vxuuuxwwuuur?ru?+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>++++>++++++++++++++++++++?xyuuuuxzwrzuxux>+zr^++++++???.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}xxzxuv?x}>??>+>vuuu~<br></br>
.?}zxuyyuxw??v?++>>^+++++++++^>wxxzxxuyyyyyuuxxxxxzzzwrxzwzxzxxruuuxzzwzwz|<br></br>
.>>wuuuuxzz?rzr?+>vvrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzwwzxxuuuuuxuuzwzwz|<br></br>
                  -xxxxuuxzwzwrzzzwxuuuxzrvrrwuuuxxzwxuuuuuuuuuuuxuuuuxzzzzwzxuxzxxxxxzzzzzz~<br></br>
`uuuzv?>?v>v>++?vwuxxxzzzuxuuuxxzzzxxxxuuuxxxxxxxxxxxzzxxzxuuxzzzxx}vvvvvv-<br></br>
`}}}?>?vrzxzwwwzzxxxzxxzxxwwwwwwwrrrrrrrrrrrrrrrrrwzxuuuxxxuxzzzxz}vvvvvvv-<br></br>
.^++++++?zzzzxxxxxxxzxxxxz?+++++++++++++++++++++++>vrrr}}}v}}}}}v+++++++++.<br></br>
.|||||||^++++++++>>>>>>+++^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^|||||||||^^^^^^^^^^^||^||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+wxv^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^+++^^^^^++^>v>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++>^^^+++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++>+++++++++++++++++++++++++++++^^^^+}uyuuuxr?>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxxxwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxzrrrv??>++++++^.<br></br>
.^++++++++++++++?^+++++>>+++++++++++++++++++++^ryuxxzzzzuuzvvrv?>++++++++^.<br></br>
.^++++++++++++++?>>}?+++++++++++++++++++++++++^vuuxzrvvzuuxvvv?>++++++++++.<br></br>
.^++++++^^||^^^+>rzv+++?++++++++++++++++++++++^^>ruxzrvvuuxvvvr>++++++++++.<br></br>
.^++++++^~--+~|^++>++++?++++++++++++++++++++^>}}vxuuuxwruuu}?ruv+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzwrzuxux>+wr^++++++???.<br></br>
.vwxxxx}zyz>>^>vvv>+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}zxwxuv>zv>??>+>vuuu~<br></br>
.?}zxuyyxxr??v?++++^+++++++++^>wxxzxxuyyyyyuuxxxxxxzzwrzzwzuxxx}uuuxzzwzwz|<br></br>
.>>wuuuuxzz?rzr?+>vvrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzwwzxuuuuuuxuuxwwwz|<br></br>
                  -xxxxuuxwrrrrzzwwxuuuxzrvrrwuuuxxzwxuuuuuuuuuuuxuuuuxzzzwwzxuxzxxxxxzzzwwz~<br></br>
`uuuzv?>??+?>++?vzxxxxzzzxxuuuxxzzzxxxxuuuxxxxxxxxxxxzzzzxxxuxzzwxz}vvvvvv-<br></br>
`}}}?>?vrzwzwwwzzxxxzzzzzzwwwwwwwrrrrrrrrrrrrrrrrrwzxuuuuuuuuuxzzwvvvvvvvv-<br></br>
.^++++++vzzzzzzxxxxxxxxxxz>+++++++++++++++++++++++>vrrwr}}}rrr}}v>++++++++.<br></br>
.||||||^+++++++++>>>>>>+++^||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^|||||||||^^^^^^^^^^^^||||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^^+^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+wxv^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^++++^^^^^+^>v>>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^^+++++++++++++>^^^+++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxr?>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxxxwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxzrrrv??>++++++^.<br></br>
.^++++++++++++++?^+++++>>+++++++++++++++++++++^ryuxxzzzxuuxvvrv?>++++++++^.<br></br>
.^++++++++++++++?>>v?+++++++++++++++++++++++++^vuuxxrvvzuuxvvv?>++++++++++.<br></br>
.^++++++^^||^^^+>rzv+++>++++++++++++++++++++++^^>ruxzwvvxuxvvvw>++++++++++.<br></br>
.^++++++^~--+~|^+>>++++?++++++++++++++++++++^>}}vxuuuxwruuu}?ruv+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzzwzuxux>+wr^++++++???.<br></br>
.vwxxxz}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}zxwxuv>zv>??>+>vuuu~<br></br>
.?}zxuyyuxr??v?++++^+++++++++^>wxxzxxuyyyyyuuxxxxxxzwwrzzwzuuxx}uuuxzzwzzz|<br></br>
.>>wuuuuxzz?rzr?+>vvrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzwwzxuuuuuuxuuxwwwz|<br></br>
                  -xxxxuuxwrrrrzzwwxuuuxzrvrrwuuuxxzwxuuuuuuuuuuuuuuuuxzzzwwzxuxzxxxxxzzzzzz~<br></br>
`uuuzv?>??+?>++?vwxxxxzzzxxuuuxzzzzxxxxuuuxxxxxxxxxxxzzzzxxxuxzzwxz}vvvvvv-<br></br>
`}}}?>?vwzwzwwwzzxxxzzzzzzwwwwwwwrrrrrrrrrrrrrrrrrwzxuuuuuuuuuxzzwvvvvvvvv-<br></br>
.^++++++vzzzzzzxxxxxxxxxxz>+++++++++++++++++++++++>vrwwr}}}rrr}}v>++++++++.<br></br>
.|||||||+++++++++>>>>>>>++^||||||^||||^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^|||||||||^^^^^^^^^^^^||||^|||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^>?+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>zw?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^++++^^^^^+^>?+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++++^^++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxr?>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxuxwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxzwrrv??>++++++^.<br></br>
.^++++++++++++++?+++++++++++++++++++++++++++++^ryuxxzzzxuuxvvrv?>++++++++^.<br></br>
.^++++++++++++++?++?>+++>+++++++++++++++++++++^vuuxxrvvzxuxvvvv>++++++++++.<br></br>
.^++++++^^||^^^+?}rv+++>++++++++++++++++++++++^^>ruxzrvvzuxvv}w+++++++++++.<br></br>
.^++++++^~--+~|^+??++++>++++++++++++++++++++^>}}vxuuuxw}xuuv?ru?+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzzrwxzux>^}}^++++++???.<br></br>
.vwxxxz}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}wzwxuv+w?>??>+>vuuu~<br></br>
.?}zxuyyuxr>?v?++++++++++++++^>wxxzxxuyyyyyuuxxxxxxzzw}wwwzuxzzvuuuuzzwwzz|<br></br>
.>>wuuuuxzxvrzr?+>?vrrr>++++??wyuxxxuuuuuuuuuuuuuuuuxzzzwwzxuuxuuuxuxxwwwz|<br></br>
-xxxxuuxw}}vrzzwwxuuuxzrv}wzuuuxxzwxuuuuuuuuuuuxuuuuxzzzzzzxuxzxxxxxzzzwzz~<br></br>
`uuuzv?>?>+?>++?vrxxxxzzzxuuuuxzzzzxxxxuuuxxxxxxxxxxxzzzxxxxuxzwwzz}vvvvvv-<br></br>
`}}}?>?vwzwzwrrwwzxzwwwwzzwwwwwwwrrrrrrrrrrrrrrrrrwzxuuuuuuuuuxxzwvvvvvvvv-<br></br>
.^++++++vxzz}??v}rzxzwr}zz?+++++++++++++++++++++++>?rwwwrrrrwwwr}>++++++++.<br></br>
.|||||||^++++^^^^+>>>>>>>+^||||||^||||^^|^^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^|||||||||^^^^^^^^^^^^||||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^>?+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>zr?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^++++^^^^^+^>?+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++++^++^++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxr?>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxuxwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxuxzwrrv??>++++++^.<br></br>
.^++++++++++++++>^++++++++++++++++++++++++++++^ryuxxzzzxuux}vrv?>++++++++^.<br></br>
.^++++++++++++++?++>>+++>+++++++++++++++++++++^vuuxzrvvwxuxvvvv>++++++++++.<br></br>
.^++++++^^||^^^+?}rv++++++++++++++++++++++++++^^>ruxzrvvzuxvvrw+++++++++++.<br></br>
.^++++++^~--+~|^+??+++++++++++++++++++++++++^>}}vxuuuxwvxuuv?}x?+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzzrwxzuz>^}}^++++++???.<br></br>
.vwxxxz}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzr}wzwxuv+r?>??>+>vuuu~<br></br>
.?}zxuuuuxr>?v?++++++++++++++^>wxxzxxuyyyyyuuxxxxuxzzw}wwwzuxzzvuuuuzzwwzz|<br></br>
.>>wuuuxxzxvrzr?+>?vrrr>+++>??wyuxxxuuuuuuuuuuuuuuuuxzzzwwzxuuxuuuxuxxwwwz|<br></br>
-xxxxuuxw}}vrzzwwxuuuxzrv}wzuuuxxzwxuuuuuuuuuuuxuuuuxzzzzzzxuxzxxzxxzzzzzz~<br></br>
`uuuzv?>?>+?>++?vrxxxxxzzxuuuuxzzzzxxxxuuuxxxxxxxxxuxzzzxxxxuxzwwzz}vvvvvv-<br></br>
`}}}?>?vwzwzwrrwwzxzwrrwzzwwwwwwwrrrrrrrrrrrrrrrrrwzuuuuuuuuuuxxzwvvvvvvvv-<br></br>
.^++++++vxzzv??vv}zxzrvvwz?+++++++++++++++++++++++>?rwwwrrrrwwwr}>++++++++.<br></br>
.|||||||^++++^^^^+>>>>>>>+^||||||^||||^^|^^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^|||||||||^^^^^^^^^^^^||||||^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>v?++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^++++^^^^^+^++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?++^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?+^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxx}>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxxxwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxxxwwwwwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzzwzrv}rv?>++++++++^.<br></br>
.^++++++++++++++>+++>+++>+++++++++++++++++++++^vuuxzrvv}rzr?}wv>++++++++++.<br></br>
.^++++++^^||^^^+????++++++++++++++++++++++++++^^>ruxzrv?zuw?}xr+++++++++++.<br></br>
.^++++++^~--+~|^+vv>++++++++++++++++++++++++^>}}vxuuuxw}xux??vw>+++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzwrrzzx?++v>+++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuuuxzzr}}wzw?++v+>??>+>vxuu~<br></br>
.?}zxuuuuxr??v?++++++++++++++^>wxxzxxuyyyyyuuxxuuxxzzw}}rxzv???vuuuuzzwwwz|<br></br>
.>>wuuuxxzz?rzr?+>?vrrr>+++>??wyuxxxuuuuuuuuuuuuuuuuxzzzwzxxzwwuuuxuuzwwwz|<br></br>
-xxxxuuzw}}?rzzwwxuuuxzrvvwzuuuxxzwxuuuuuuuuuuuxuuuuxzzzwzxuuxxxxzxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}xxxxxzzxuuuuxzzzzxxxxuuuxxxxxxxxuxxzzzzxxuxzzwwzw}vvvvvv-<br></br>
`}}}?>?vzwrzr}}wwwxzwrrrwwwwwwwwwrrrrrrrrrrrrrrrrrzxuuuuuuuuuxzxxwvvvvvvvv-<br></br>
.^++++++vxzrv>???v}xw}vvvv>+++++++++++++++++++++++>vrwwwrrrrwwwrv>++++++++.<br></br>
.|||||||^++++^^^^+>>>>>>>>^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^^|||||||^||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^+??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^>v?++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^++++^^^^^++^^^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?++^^^^^^^^^^^^^^^.<br></br>
                  .^^++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxwv+^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuuuw>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxuxzzwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxuxxrrwwwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzzvwv?}r}?>++++++++^.<br></br>
.^+++++++++++++>>^^+>+++>+++++++++++++++++++++^vuuxzrvvvvrv?}x}>++++++++++.<br></br>
.^++++++^^||^^^+????++++++++++++++++++++++++++^^>ruxzrv?wx}?}xr+++++++++++.<br></br>
.^++++++^~--+~|^+v}>++++++++++++++++++++++++^>}}vxuuuxwrxxz???r++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>++++++++++++++++++++++++++?xyuuuuxzwwrwzz>++?++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuuuxzzrvvrzw+++?+???>+>}xuu~<br></br>
.?}zxuuuuur??v?++++++++++++++^>wxuzxxuyyyyyuuxxuuxzzzw}vrxz?>>>vuuuuzzwwwz|<br></br>
.>>wuuuxxzz?}zr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzzwxuzwrruuuxxuzwwwz|<br></br>
-xxxxuuzw}}?rzzwwxuuuxzrv?wxuuuxxzwxuuuuuuuuuuuxuuuuxzzzwzxuuxxxxzxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxxzxuuuxzzzzxxxxuuuxxxxxxxuuxzzzzzzxuxzwwwzw}vvvvvv-<br></br>
`}}}?>?vzr}wr}}rwwxzrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuuuuuuuuuuzzxxrvvvvvvvv-<br></br>
.^++++++vzw}?>>??v}xwvv???++++++++++++++++++++++++>vwwwwrrwrwwwrv+++++++++.<br></br>
.|||||||^++++^||^+>>>>>>>>^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^^|||||||^||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^+v?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?>++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^++++^^^^^++^^^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?++^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxwv>^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuuuz>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxuuxzzwrrv>>++^++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxuxzr}wwwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzwvrv?}w}?>++++++++^.<br></br>
.^+++++++++++++>>^^+++++++++++++++++++++++++++^vuuxzrvvvvv??rxv>++++++++++.<br></br>
.^++++++^^||^^^+>>?>++++++++++++++++++++++++++^^>ruxzrv?wx}?}z}+++++++++++.<br></br>
.^++++++^~--+~|^+}}>++++++++++++++++++++++++^>}}vxuuuxwrxur>??}++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwwrwz}+++?++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuuuxzzrvv}z}+++>+>??>+>}xuu~<br></br>
.?}zxuuuuur??v?++++++++++++++^>wxuzxxuyyyyyuuxuuxxzzzw}v}zr?+>+vuuuxzzwwwz|<br></br>
.>>wuuuxxzz?rzr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzzzxux}vruuuxxuzwwwz|<br></br>
-xxxxuuzw}v?rzzwwxuuuxzrv?rxuuuxxzwxuuuuuuuuuuuxuuuxxzzzzzuuuxxxxzxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxxzxuuuxzzzzxxxxuuuxxxxxxxxuxzzzzzzxuxzwwwzw}vvvvvv-<br></br>
`}}}?>?vz}}wr}}rwwxwrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuuuxuuuuuxzzxxrvvvvvvvv-<br></br>
.^++++++vzwv?>>???vzrvv???>+++++++++++++++++++++++>vwwwrrrrwrrwrv+++++++++.<br></br>
.|||||||^+++^^|||^+>>>>??>^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^||||||||^||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^+v?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+?+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^+^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^+^^^^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuz?+^^^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxwr?^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuuxz>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxuuzzzwwwv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzw}vwwrv??>++++++^.<br></br>
.^++++++++++++++++++++++>+++++++++++++++++++++^ryuxxzzzwvv??vwv?>++++++++^.<br></br>
.^++++++++++++++>^++>+++++++++++++++++++++++++^vuuxzrvv?????wxv>++++++++++.<br></br>
.^++++++^^||^^^+>>>>++++++++++++++++++++++++++^^>ruxzrv?wzv?vw}+++++++++++.<br></br>
.^++++++^~--+~|^+}}>++++++++++++++++++++++++^>}}vxuuuxw}zuv>?>v++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwr}rz?^++?++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuuxxzzrvvvw?^++>+>??>+>}xuu~<br></br>
.?}zxuuuuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxuuxxzzzw}vvr}?+>^vuuuxzzwwwz|<br></br>
.>>wuuuxxzz>rzr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzwzuuzvvrxuuxxuzwwwz|<br></br>
-xxxxuuzw}v>rzzwwxuuuxzrv?rxuuuxxzwxuuuuuuuuuuuxuuuxxzzzzxuxxxxxxzxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxxzxuuuxzzzzxxxxuuuxxxxxxxxuxzzzzzzuuzzwwwzw}vvvvvv-<br></br>
`}}}?>?vz}}wr}}rwwzwrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuuxxxuxuuzwzxxrvvvvvvvv-<br></br>
.^++++++vzrv?>>???vrrv????>+++++++++++++++++++++++>vrwwrrrrwrrwrv+++++++++.<br></br>
.|||||||^++^^^|||^+>>>>??>^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^|||||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^++++++++^++^^^^^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+v>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^++^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?++^^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxzw?^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxxz>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxuuxzwwwwv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzw}}rwrv??>++++++^.<br></br>
.^++++++++++++++++++++++>+++++++++++++++++++++^ryuxxzzzw}v??}zv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????rxv>++++++++++.<br></br>
.^++++++^^||^^^+>+++++++++++++++++++++++++++++^^>ruxzrv?wzv?vr}+++++++++++.<br></br>
.^++++++^~--+~|^+}v>++++++++++++++++++++++++^>}}vxuuuxw}zz?>?>?++++++++++^.<br></br>
.^+>>>+^>?r^?||>>?>+++++++++++++++++++++++++?xyuuuuxzwr}}w>+++?++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuuxxzzrvvv}?+++++???>+>vuuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxuuxxzzzw}vvrv?++^vuuuxzzwwwz|<br></br>
.>>wuuuuxzz>rzr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzzzxxzvvruuuxxuzwwwz|<br></br>
-xxxxuuzw}v>rzzwwxuuuxzrv?}xuuuxxzwxuuuuuuuuuuuuuuuxxzzzzxxxxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzxuuuxzzzzxxxxuuuxxxxxxxxuxzzzzzxuxzzwwzzr}vvvvvv-<br></br>
`}}}?>?}z}}w}}}rwrzwrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuuxxxuxuuzwzxx}vvvvvvvv-<br></br>
.^++++++vwrv?>>???v}rv????>+++++++++++++++++++++++>vrwwrrrrwrrrrv>++++++++.<br></br>
.|||||||^++^^^|||^+>>>>??>^||||||^||||^^|||^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^^||||||||^|||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^+++^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+v>^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+>+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+>+^+^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxzxv^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxzw>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxuuxzwwwwv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}rwrv??>++++++^.<br></br>
.^++++++++++++++++++++++>+++++++++++++++++++++^ryuxxzzzw}v??}zv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????rzv>++++++++++.<br></br>
.^++++++^^||^^^+>+++++++++++++++++++++++++++++^^>ruxzr}?wz??v}v+++++++++++.<br></br>
.^++++++^~--+~|^+vv+++++++++++++++++++++++++^>}}vxuuuxw}xr>>?>?++++++++++^.<br></br>
.^+>>>+^>?r^?||>>?>+++++++++++++++++++++++++?xyyuuuxzwr}v}++++>++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>+>vuuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuuuxxxzzzw}vv}v?++^vuuuxzzwwwz|<br></br>
.>>wuuuuxzw>}zr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzzwzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzw}v>rzzwwxuuuxzrv?}xuuuxxzwxuuuuuuuuuuxxuuuxxzzzzxzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzxuuuxzzzzxxxxuuuxxxxxxxxuxzzzzzxuxzzwwzwr}vvvvvv-<br></br>
`}}}?>?}wv}r}}}rwrzwrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuuxxxxxuxwrwxx}vvvvvvvv-<br></br>
.^++++++vr}v?>>????}rv????>+++++++++++++++++++++++>vrwwr}}rw}}rrv>++++++++.<br></br>
.|||||||^>+^^^^||^+>>>>??>^||||||^||||^^|||^^^^^^^||^^^^^^^^|^^^^^^^^^^^^^.<br></br>
                  .|||||||||||||||||||||||||||||^||||||||||||^^^^^^^^^||||||||^|||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^++++^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^+?+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+?+^+^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxzxv+^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxw}>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxuxxzwwwwv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}rwrv??>++++++^.<br></br>
.^++++++++++++++++++++++>+++++++++++++++++++++^ryuxxzzzw}v??}zv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????}zv>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?ww??v}v+++++++++++.<br></br>
.^++++++^~--+~|^+??+++++++++++++++++++++++++^>}}vxuuuxw}z}>>?>>++++++++++^.<br></br>
.^+>>>+^>?r^?||>>?>+++++++++++++++++++++++++?xyuuuuxzwr}vv++++>++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuxuxxzzrvvvv>+++++>??>+>vuuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuuuxxxzzzw}vv}v?++^vuuuxzzwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>+++>??zyuxxxuuuuuuuuuuuuuuuuxzzzwzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzw}v>rzzwwxuuuxzrv?vxuuuxxzwxuuuuuuuuuuxxuuuxxzzzzxzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzxuuuxxzzzxxxxuuuxxxxxxxxuxzzzzzxxxzzwwzwr}vvvvvv-<br></br>
`}}}?>?}rv}r}}}rrrzwrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrzuxxxxxxuxwrwxz}vvvvvvvv-<br></br>
.^++++++vv??>+>>???vv??>>>>++++++++++++++++++++++++vrwwr}rrw}}rrv+++++++++.<br></br>
.|||||||^++^^^|||^^++>>>>+^||||||^||||^^|||^^^^^^^||^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||||||||||^||||||||||||^^^^^^^^^^|||||||^|||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^++++^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?>?+^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxzzw?^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}??>?+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzwvv??}xv?>++++++++^.<br></br>
.^+++++++++++++++^++++++++++++++++++++++++++++^vuuxzrvv?????vrv>++++++++++.<br></br>
.^++++++^^||^^^++??+++++++++++++++++++++++++++^^>ruxzwv?zr??vv?+++++++++++.<br></br>
.^++++++^~--+~|^+?>+++++++++++++++++++++++++^>}}vxuuuxwvw?>>?>>++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwr}v?+++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuxxxxxzzrvvvv>+++++>??>+>vuuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuuxxxxzzzw}vv}v?+^^vuuuxzzwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>+++?v?zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??zuuuxxzwxuuuuuuuuuuxxxuxxxzzzzxzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuuxxxxxxxxxzzzzzzxxxzzwwwwr}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}r}wzwrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwuxxzxxxuxrrwxzvvvvvvvvv-<br></br>
.^+++++>?>++++++>???>>+++++++++++++++++++++++++++++?rrrrrrrrv}rrv+++++++++.<br></br>
.||||||^>>+^|||||||^+>>++^|||||||^||||^^|||^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||||||||||^||||||||||||^^^^^^^^^^|||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^++++^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?>?>^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxxzr?^^+++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}??>?+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzwvv??}xv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vrv>++++++++++.<br></br>
.^++++++^^||^^^++?>+++++++++++++++++++++++++++^^>ruxzwv?z}??vv?+++++++++++.<br></br>
.^++++++^~--+~|^+>++++++++++++++++++++++++++^>}}vxuuuxwvw?+>?>>++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwr}v?+++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuxxxxxzzrvvvv>+++++>??>+>vuuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuuxxxzzzzw}vv}v?+^^vuuuxzzwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>+++?v?zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??zuuuxxzwxuuuuuuuuuuxxuuxxxzzzzxzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuuxxxxxxxxxzzzzzxxxxzzwwwwr}vvvvvv-<br></br>
`}}}?>?v}v}}}}}}}vwzwrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwuxzzxxxuxrrwzzvvvvvvvvv-<br></br>
.^++++++?>++++++>>>>>>+++++++++++++++++++++++++++++?rrrrrrrrv}rrv+++++++++.<br></br>
                  .||||||^+++^|||||||^^+++^^|||||||^||||^^|||^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||||||||||^||||||||||||^^^^^^^^^^|||||||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^++++^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuz??v>+^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxx}>v+^+^+++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxv>?>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzwvv??}z}?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxxr}v?????vvv>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?wv??vv>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwv}?>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwr}?>+++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwuuxuxxxzzrvvvv>+++++>??>+>}uuu~<br></br>
.?}zxuuyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuuxxxzzzzw}vv}v?+++vuuuxzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxuxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuuxxxxxxxxxzzzzxxxxxzzwwwwr}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwxxzzxxxuzrrwzwvvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++?}}}rrrwrv}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||||||^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuz??v>+^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxx}>v+^+^+++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuuxv>?>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuxxzzzwvv??}z}?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzr}v?????vvv>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzwv?wv??vv>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwv}?>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxzwr}?>+++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwuuuuxxxzzrvvvv>+++++>??>+>}uuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuuxxxzzzzw}vv}v?+++vuuuxzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxuxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuuxxxxxxxxxzzzzxxxxxzzwwwwr}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwxxzzxxxuzrrwzwvvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++?}}}rrrwrv}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^|||^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||||||^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^+^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuz?vv+++^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxz?^??^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>?>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxzzzwvv??}w}?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrvvr???vv>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwvv>>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++vxyuuuuxzwr}?>+++++++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwzuuuuuxxzzrvvvv>+++++>??>++}uuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuuxxxxzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwxzrrzzxxw}rwwrvvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++?}?+?vrrvv}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||||||^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^+^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuz?v?+++^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxz?^??^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>?>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??}w}?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrvvr???vv>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwvv>>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++vxyuuuuxzwr}?>+++++++++++++???.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwzuuuuxxxzzrvvvv>+++++>??>++}uuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuuxxxzzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrwxzrrzzxxw}rwwrvvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++?}?+?vrrvv}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||||||^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^>>^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuzv?>+++^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^+v^+++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>?>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrvvv???v?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>++++++++++++++++++++++++v}xyuuuuxzwr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwxuuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxxxxxzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrzwrrzxxzw}rww}vvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++>v>+??????}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||^||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^>>^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuuzv?>+++^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^+v^+++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>?>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wwv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrvvv???v?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>??+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>++++++++++++++++++++++++v}xyuuuuxzwr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwxuuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxxxxxzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrzwrrzxxzw}rww}vvvvvvvvv-<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++++++>v?+??????}rr?+++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^||||^||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^++^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^+>^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvvv????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+~|^+++++++++++++++++++++++++++>^>}}vxuuuxwv?>>>?>+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++>>>xyuuuuxzwr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxxxxxzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwzzwr}}r}vvvvvvvvvv-<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++++++++?+++>>>>>+??++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^++^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^++^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^+>^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvvv????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+~|^+++++++++++++++++++++++++++>^>}}vxuuuxwv?>>>?>+++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++>>>xyyuuuxzwr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyyuuxxxxxzzzw}vv}v?+^+vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzwwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwzzwr}}r}vvvvvvvvvv-<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++++++++?+++>>>>>+??++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>++++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+~|^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^+++^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuzr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuxr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuxr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvruuuxxxzwwwz|<br></br>
-xxxxuuxr}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+++^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^++++++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^+^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxzrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wxuzxxuyyyyuuuxxxxxzzzw}vv}v?+^^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?+>?vrrr>++++??zyuxxxuuuuuuuuuuuuuuuuxzzzzzxzvvrxuuxxxzwwwz|<br></br>
-xxxxuuxw}v>rzzwwxuuuxzrv??wuuuxxzwxuuuuuuuuuuuxxxxxxzzzzzzzxxxxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>++?v}zxxxxxzzzuuuxxzzzxxxxuuxxxxxxxxxxzzzzxxxxxzzzwwr}}vvvvvv-<br></br>
`}}}?>?v}v}}v}}}}v}rrrrrrrwwwwwwwrrrrrrrrrrrrrrrrrrrrrrwwrrrr}}}vvvvvvvvvv-<br></br>
                  .^++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
                  .|||||||||||||^^|^^^^^^||||||||||^||||^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^+^+^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++++^?uyuuuxw?^^^^^^^^+++^^^^.<br></br>
.^^+++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^++^^^++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>++^^+++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxzzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vuuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^++++++++++++++++++++++++++++^>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyyuuuxzwr}?++++++++++++++>??.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?}zxuyyuxr??v?++++++++++++++^>wuuxxzxxuyyyyuxxxxxzzzw}vv}v?+++vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?++?vrrr>++++??zyw}}r}wzuzwrrxuuuuuuuxzzzzzxr>?wuuuxxxzwwwz|<br></br>
-xxxxuuxw}v>rzxwvvxuxxzrv??wuyu}>?vvv?zuvvv}?}xuxxxxxzzzzz}>>wuxxxxxzzzzzz~<br></br>
`uuuzv?>>++?>^vv?}zz}zzxzzzuuuuw?}xvzvzuv>?x}vrxxxxzzzzvr?+vvzv}zr}}vvvvvv-<br></br>
`}}}?>?v}v}rwrzw}wzzwzwwrrwwwwzzvwzzzwwzwr}zvvwrrrrrrwwvwv?}rw??wwr}vvvvvv-<br></br>
.^+++++++++>??????>????>++++++>????>>>>???????>+++++++>>>>>>>>>>>>>+++++++.<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^^|^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^+++++++++++^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?wuux?+^^^^+^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++++++++++++++++^+^?uyuuuxw?^^^^+^^^++^+^^^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^^^^+}uyuuux}>>>>+^^^^++++^^.<br></br>
.^+++++++++++++++++++++++++++++++++++++++++++++?vrrxuuxxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^ryuuxxzzwvv??vrv?>+++++++++.<br></br>
.^++++++++++++++++++++++++++++++++++++++++++++^vxuxxrvv?????vv?>++++++++++.<br></br>
.^++++++^^||^^^+++++++++++++++++++++++++++++++^^>ruxzrv?????v?>+++++++++++.<br></br>
.^++++++^~--+||^+++++++++++++++++++++++++++++>}}vxuuuxwv?>>>?++++++++++++^.<br></br>
.^+>>>+^>?r^?||>>>>+++++++++++++++++++++++++?xyuuuuxxwr}?++++++++++++++>?>.<br></br>
.vwxxxx}zyz>>^>vvv?+++++++++++++>>+vr}}r}rwwxuuuxxxzzrvvvv>+++++>??>++vuuu~<br></br>
.?rzxuyyuxr??v?++++++++++++++^>wuuxxwzzuyyyyuxxxxxzzzw}vv}v?++^vuuuuzwwwwz|<br></br>
.>>wuuuuxzw>rzr?++?vrrr>++++??zurvvvv}rxw}}}zuuuuuuuxzzzzzxr+?wuuuxxxzwwwz~<br></br>
-xxxxuuzr}v>rzxr??xuzxzrv??wuyuv^????^ru?>>v+?xuxxxxxzzzzzv||ruxxxxxzzzzzz~<br></br>
-uuuzv?>>++?>^r}?}zzvzwxzzzuuuur>vx?w?wx?|>xv?}xxxxzzzz?}?~??z?vzr}}vvvvvv-<br></br>
`}}}?>?v}v}wwrxzrzxxwzzzwrwwwwxxvwxzzzzzww}xvvzrrrrrrwwvw??}wz??xzw}vvvvvv-<br></br>
.^+++++++++>????????vv?>++++++??????>>??vv???v>+++++++>??????>?????>++++++.<br></br>
                  .|||||||||||||||||||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||||||||||^^^^^^^^^^^^^^^^^^^||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^++^++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^+^^^^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
                  .^+++++++++++++++++++++++++++++++^^+^^^++^++^++^+^?uyuuuxw?^^^^^^^^++^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^+>+^++^^^^++^^^^^+}uyuuux}>>>>+^^^^++++^^.<br></br>
.^++++++++++++++++++++++++++++++^++^^+???>+^+++?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++^++^>????^^++?xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++>+++++^|-|++++|>zyuuxxr?vvv??vrv?>+++++++++.<br></br>
.^+++++++++++++++++++++++++++++>+^^++^|~|++++^+rxuxxw??v???>????+^++++++++.<br></br>
.^++++++^^||^^^++++++++++++++++>+^^^++^|+>+^^^+?>ruxzz???>>>?>++^+>>++++++.<br></br>
.^++++++^~-~>~|++>^+++>+++++++>>++>^|^||^?^>+>}rvxuuuuw?>>>>>++++++?++++++.<br></br>
.^+>>>++>?r+?^|?>>>>+++^+>++++??+>>+^^||^>^??xyyuuuxxzr}>+>>>>>+++>>+????>.<br></br>
.vwxxxx}zyz>++?rv?}?+^^|+?++|`-~|??v?|++vwr}v?>>xxxzzwrv>>>>>>?^>??>?vrxuu~<br></br>
.?rzxuyyuxzv?v??+|||+~>^+?+>+`.`|~|^-`-`v?||~.`vxxxzzwww}rw?>>>>?vwzxzwwwz|<br></br>
.>>wuuuuxzwvv}?-`-^+^>r?>>+?}?`|vv??^+^+v?v}^|ruuuuuxzzzzzx?+>>?vzxxxzwwwz~<br></br>
-xxxxuuzw}v?vv?>??}v?wxz}??zuu>|?vrwrvxurr}^^ruuuxxxxxzzzzw?+>rzxzxxzzzzzz~<br></br>
-uuuzv?>>+>vv?v}vv?v}zzxxzzuuuw^}wurx}zx}??wwrzxxxxxxzzrwv>}}r}rzwzzwvvvvv-<br></br>
`}}}?>?vrv}zzzxxzxzxzzzxwrwwzzxwrzxzzzzzzwwxrwzwwrrwwwzrzvvwzxvvzzzzw}vvvv-<br></br>
.^++++++>+>?????????vvv?++++>>?vv???????????vv?>>+++>+>??????????????>++++.<br></br>
                  .||||||||||||||||||^^|||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||||||||||^^^^^^^^^^^^^^^^||||^^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^++^++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^>+^+^^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^+?++^>++?>^^+^+^?uyuuuxw?^^^^^^^^++^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^>?>^+^^^^^??+^^^^+}uyuuux}>>>>+^^^^++++^^.<br></br>
.^++++++++++++++++++++++++++++++^??^^>vvv?+^??+?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++?|++|?wvwr^^???xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++?+^>++^^-^+^++~?uyuuxxv>?vv??vrv?>+^+++++++.<br></br>
.^++++++++++++++++++++++++++++^?+~^++^||-^++^->xxuxxw?>v>??+++??>|>+++++++.<br></br>
.^++++++^^||^^^++^++++++++++++>?+|~|++~`^?+~-|+v>ruxzx??>++^>+++^>??++++>+.<br></br>
.^+++++>^~-^>~^>>?^++^?>+>++++?v^+?~-~``~v-?+>}zvxuuuuw?+>>>+^++>+>}++>?^+`<br></br>
.^+>>>+>>?r+?^|??????>+^+?+++^v}>??+|~~~-?^}?xyuuuuxxzr}++>>>>?++^??+vz??>.<br></br>
.vwxxxx}zyz>++?zv?wv?>^~>v^+~.-||}w}?-|~?zr}?>~|xxxzzww}^+??>>v^+>?>ruxxuu~<br></br>
.?rzxuyyuxzr???v>^^^?+?~+v+v>.  ...-.   >+...  ?xxxzzwwxrwx?>>?+>?vwuzwwwz|<br></br>
.>>wuuuuxzw}??>```-~.|r???^vu? .}zv>^|~-v?}r``vuxuuuxzzzzzx>>>^+>rxuxzwwwz~<br></br>
-xxxxuuzw}vvv>|~???>+ruuw??zuu~~wuuyyuuuuyx|+xyuuxxxxxzzzzuw?^vzzzxxzzzzzz~<br></br>
-uuuzv?>>+?zzv?v}?+?wzzxxzzuuu}~zuuxuxxxxu}ruuuxxxxxxxxxxzxuxvzuzzuxz}vvvv-<br></br>
`}}}?>?vrvrzzxxxuxzxzzxuwrwwzzzrzzxzzzzzzzxzxxxzzwrwzwzxxzzxuuxzzzxuxrvvvv-<br></br>
.^++++++?>>????????vvvv}>+++??vvvv?vvv?>>>??vvvv?+++>+>>?????v?>?????>++++.<br></br>
                  .||||||||||||||||||^^^||||||||||||||||||||||||^||^^^^^^|^^^^^^^|^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||^^^^^^^^^^^^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>+^+^^+^+^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^+?++^+++?>^^+^+^?uyuuuxw?^^^^^^^^++^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^>?>^+^^^^^>?+^^^^+}uyuuux}>>>>+^^^^++++^^.<br></br>
.^++++++++++++++++++++++++++++++^??^^>v}v?+^??+?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++>|++|?wvwr^^???xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++>+^>++^|-|+^++~?uyuuxxv>?vv??vrv?>+^+++++++.<br></br>
.^+++++++++++++++++++++++++++++?+~^++^||~^++^->xxuxxw?>v>??+++??+|>+++++++.<br></br>
.^++++++^^||^^^++^^++++++++++++?+^~|++~`^?+~-|+v>ruxzx??>++^>^++^>??++++++`<br></br>
.^++++++^~-^>~^>>?^++^?>+>++++?v^+?~-~``~?-?+>}wvxuuuuw?+>>>++++>+>v++>?^+`<br></br>
.^+>>>++>?r+?^|??????>+^+?+++^v}>>?+|~~~-?^v?xyuuuuxxzr}++>>>>?++^??+vz??>.<br></br>
.vwxxxx}zyz>++?zv?wv?>^~>v^+~.`|~vrv?`|~?z}v?+~|xxxzzww}++??>>}^+>?>ruzxuu~<br></br>
.?rzxuyyuxz}???v>||^?+?~+v+v>.  ...`    +^...  ?xxxzzwzz}wxv>>?++?}zuzwwwz|<br></br>
.>>wuuuuxzw}??>```-~.~w??>^vu? .}z}>^|~~v?}r`.vuxuuuxzzxzxx>>>^+>rxuwwwwwz~<br></br>
-xxxxuuzw}vvv>|~???>+ruuw??zuu~-ruuyyyuuuyz-|zuuuxxxxxzzzzuv>^vzzzxxzzzzzz~<br></br>
-uuuzv?>>+?zzv?v}>+?wzxxxzzuuur~zuuuuxxxxuv}xuuxxxxxxxxuxzxuwvzuzzuxx}vvvv-<br></br>
`}}}?>?vr}rzzxxxuzzxzzxuzwwwzzzrzzxzzzzzzzxzxuxzzwrwzzxxzzzxuuxzzzxuuwvvvv-<br></br>
.^++++++???????????vvvv}>+++???vvv??vv??>>??vvvv?+++>>>>?????vv??????>++++.<br></br>
                  .||||||||||||||||||^^^|||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>+^++^+^+^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^+?++^+++?>^^+^+^?uyuuuxw?^^^^^^^^++^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^>v>^+^^^^^>?+^^^^+}uyuuux}>>>>+^^^^++++^^.<br></br>
.^++++++++++++++++++++++++++++++^??^^>v}v?+^??>?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++>|++|?wvwr^^???xuuuuxxxxzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++>^^+++^|-|+^++~?uyuuxxv>?vv??vrv?>+^+++++++.<br></br>
.^+++++++++++++++++++++++++++++?+~|++^||~^++^->xxuxxw?>v>??+++>?+|>+++++++.<br></br>
.^++++++^^||^^^++^^++++++++++++?+^~|++~`^?+~-|+v>ruxzx???++^>^^+^>??++++>+`<br></br>
.^++++++^~-^?~^>>?^++^?>+>++++>?^+?~-~``-?-?+>}wvxuuuxw?+>>>++++>+>v^^>?++.<br></br>
.^+>>>+^>?r+?^|??????>+^+?+++^?v>>?+|~~~-?^v?xyuuuuxxzr}++>>>>?++^>?+vz??>.<br></br>
.vwxxxx}zyz>++?zv?w}??^~>v^+~.`~~v}v?`~-?z}v?+-|xuxzzww}++???>}^+>?>}uzxuu~<br></br>
.?rzxuyyuxz}???v>||^>+?~+v+v>.  `..`    ^|...  ?xxxzzwzwvwx}>>?++>}zxzwwwz|<br></br>
.>>wuuuuxzw}??>``-~~`~w??>^}u? .vz}?^^|~??rr`.vuxuuuxzzxzxx>>>^+>rxuvwwwwz~<br></br>
-xxxxuuzr}vvv>|~>??>+ruur??zuu|-}uuyyyyuuyz`~wuuuxxxxxzzzxuv>^vzzzxxrwzzzz~<br></br>
-uuuzv?>>+?zzvvv}>+vwzxxxzzuuur~zuuuuxxxxuv}xuuxxxxxxxxuzzuur?wuzzuxxrvvvv-<br></br>
`}}}?>?vr}wzzxxxuzwxzzxuzwwwzzzrzzxzzzzzzzxxzxxzzwrwzzxxzzzxuuxzzzxuuwvvvv-<br></br>
.^++++++???????????vvvv}?+++???vv???vvv?>>??vvvv?+++>>>>?????vv??????>++++.<br></br>
                  .||||||||||||||||||^^^|||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>+^++^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^+?++^+++?>^^+^+^?uyuuuxw?^^^^^^^^^^^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^>v>^++^^^^>?>^+^^+}uyuuux}>>>>+^^^^^+++^^.<br></br>
.^++++++++++++++++++++++++++++++^??^^>v}v?+^??>?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++>|++|?wvrr^^???xuuuuxxzzzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++>+^>++^|-|+^++~?uyuuxxv>?vv??vrv?>+^+++++++.<br></br>
.^+++++++++++++++++++++++++++++?^~|++^|^~+++^-+xxuxxw?>v>??+++>?+|>+++++++.<br></br>
.^++++++^^||^^^+^+^++++++++++++?+^~|^^~`^?+~-|+v>ruxzx??>++^+^^+^>?>++++++.<br></br>
.^++++++^~-^?~^+??^++^?>+>++++>?^+?~--``-?-?+>}wvxuuuxw?+>>>++++>+>v^^>?++.<br></br>
.^+>>>>^>?r+?^|??????>+^+?+++^?v+>?+|~~~-?^v?xyuuuuxxzrr++>>>>?^+^>?+vz??>.<br></br>
.vwxxxx}xyz>++?zv?w}??^~>v^+~ `--vvv>`~-?z}v?^-|xuxzzww}++?v?>}^+>??}uzxuu~<br></br>
.?rzxuyyuxz}???v>~~|>+?-^v+?>.  `..`    ~~...  vxxxzzwxwvwxr?>?>>>vzuzwwwz|<br></br>
.>>wuuuuxxz}??>``-~|`~wv?+^ru? .vxr?+^||??rr`.vuxuuuxzzxzxx>>>^+>rxuvzwwwz|<br></br>
-xxxxuuzw}vvv>|~>??>+}uur??zuu^`vuuyyyyuuyz``ruuuxxxxxzzzxx?>^vzxzxxwzzzzz~<br></br>
-uuuzv?>>+>wzvvvv>^vwzxxzzzuuur~zuuuuxxxxu?vzuuxxxxxxxxxzxuzv?wuzzuxxr}vvv-<br></br>
`}}}?>?vr}wzzxxxuzwxzzxuxwwwzzzrzzxxxzzzzzxxzuxzzwrwzzxzzzzxuuxzzzxuuz}vvv-<br></br>
.^++++++????????????vvv}?+++???vv???vvv?>>??vvvv?+++>>>>?????vv??????>++++.<br></br>
                  .||||||||||||||||||^^|||||^||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^^|||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>>^++^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^+?++^+++?>^^+^+^?uyuuuxw?^^^^^^^^^^^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^?v>^+^^^^^>?>^^^^+}uyuuux}>>>>+^^^^^+++^^.<br></br>
.^++++++++++++++++++++++++++++++^??^^>v}}?+^?v>?vrrxuuuxxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++>|++|?rvrr^^???xuuuuxxzzzr}}}rrv??>++++++^.<br></br>
.^+++++++++++++++++++++++++++++>+^>++^|-|^^++-?uyuuxxv>?vv??vrv?>+^^++++++.<br></br>
.^+++++++++++++++++++++++++++++?^-|++^|^~+++^-+xxuxxw?>v>??+++>?+|>+++++++.<br></br>
.^++++++^^||^^^+^+^++++++++++++?+^||^^~`^?^~-|^?>ruxzx?>>++^+^^^^>?>++++++.<br></br>
.^++++>>^~-+?~^+??^++^?>+>++++>?^+?~--``-?-?+>}wvxyuuxw?+>>>++++>+>v^^>?+^.<br></br>
.^+>>>>^>?r+?^|??????>^|+v++^^?v+>?+|~|~~?^v?xyuuuuxxzrr++>>>>?^+^>>^vz??>.<br></br>
.vwxxxx}xyz>+>?zv?wv?>^~>v^+~ `-`?vv>`~-?z}?>^`~xxxzzzw}++?v?>}^+???}uzxuu~<br></br>
.?rzxuyyuxzv???v+~~~>+?-|v+?>.  `..`    -~...  vxxxzzzxw?rxw?>?>>>vxuzwwwz|<br></br>
.>>wuuuuxxzv??>.`~||`-wv?++rx?  vxr?>+^|??wr` vuxuuuxzxxzxz>>>^>>}uxvzwwwz|<br></br>
-xxxxuuzw}v}v>|~>??>+}uxr??zuu^`vuuuyyyyuyz`.}uxuxxxxxxzzxz+>+vzxzxxwzwzzz~<br></br>
-uuuzv?>>+>wzvv?v>^vzzxxzzzuuur~xuuuuxxxxu??zuuxxxxxxxxxzxuz??ruzzuuxw}vvv-<br></br>
`}}}?>?vr}zzzxxxuzwxzzxuxzwwzzzrzzxxxzzzzzxxzxxzxwrwwzxzzzxxuuxzzzxuuz}vvv-<br></br>
.^++++++????????????vvv}v+++???vv???vvv?>>??vvvv?+++>>>>?????vvv??????++++.<br></br>
                  .|||||||||||||||||||^|||||^||||||||||||||||||||||^^^^^^^^^^^^^^^|^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^+^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^?+^++^+^++^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^++++++++++++++++++++++++++++++^^>?++^+^^?>^^+^+^?uyuuuxw?^^^^^^^^^^^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^??>^+^^^^^>?>^^^^+}uyuuux}>>>>+^^^^^+++^^.<br></br>
.^++++++++++++++++++++++++++++++^??+^>vr}?+^?v>?vrrxuuuuxxzwwrrv>>+++++++^.<br></br>
.^++++++++++++++++++++++++++++++>~++|?r?rr^^???xuuuuxxzzzr}}rwrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++^^>++^~-~^^++-?uyuuxxv+>vv??vrv?>+^^++++++.<br></br>
.^+++++++++++++++++++++++++++++?^-|++^||~+++^-^zxuxxw?>v>??+++>?+|>+++++++.<br></br>
.^++++++^^||^^^+^+^++++++++++++?+^||^^~`|?+~-|^>>ruxzx?>>++^+^++^>??++++++.<br></br>
.^++++>+^~-^?~^+??^++^??+>++++>?++?|--``->-?+>}rvxyuuxw?+>>>+++>>+>v^^??+^.<br></br>
.^+>>>+^>?r+?+|????v???+?r?>>^?v+>?+|~||~?^??xyuuuuxxzrr++>>>>?^+^+>^vw??>.<br></br>
.vwxxxx}xyz>++?zv?wv???~>w??+ .``??v>`~-?wv?+|`~xuxzzzzw>+?v?>}^+???}xrxuu~<br></br>
.?rzxuyyuxzv???v+--~++?-^r?v?`  `...    ``.`. .vxxxxxxuz?rwv?>?>>>?zuzwwwz|<br></br>
.>>wuuuuxxwv??>.`~|^`~wvv??zuv  vxw?>>+^?vzr`.vxuuuuuxxxxuw>>>^>>vuw?zwwwz|<br></br>
-xxxxuxzr}rr}?||>??>+vuuz}}xuu+.?uuuyyyyyyw..vuxuuuuuxxzzxr+>+}xuxuw}zwzzz~<br></br>
-uuxzvv>>?vzx}v???^vzzxxxxxuuuw~zuuxuxxxxu??wuuuxxxxxxxzzuuv?>}uzzuuuzrvvv-<br></br>
`rwwvv}rzwzxxxxxuxzxzzxuuxxzzzzrzzxxxzzzzzxuxxxxxzzzwwzzzxxxxxxxzzxuuxw}}}-<br></br>
.+++>>>+???????>????vvvvv?>>???v????vvv?????vvvv?>+>>>>>?????vvv??????>>>>`<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||^^^^^^^^^^^^^^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^+++^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^>>+^^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^>+^++^+^^^^^^^+?wuux?+^^^^^^^^^^^^^^^^.<br></br>
.^+++++++++++++++++++++++++++++++^>?+^^^^^>?^^+^+^?uyuuuxw?^^^^^^^^^^^+^^^.<br></br>
.^++++++++++++++++++++++++++++++^??>^+^+^^^>?>^^^^+}uyuuux}>>>>+^^^^^+++^^.<br></br>
.^++++++++++++++++++++++++++++++^??+^>}rr?+^>v>?vrrxuuuuxxzwwrrv>>+++++++^.<br></br>
.^+++++++++++++++++++++++++++++++~++|?}?}r^^???xuuuuxxzzzr}}}wrv??>++++++^.<br></br>
.^++++++++++++++++++++++++++++++|^+++^~-~^+++-?uyuuxxv+>vv??v}v?>+^^++++++.<br></br>
.^+++++++++++++++++++++++++++++?^-|++^||~+++|-^rxuxxwv>v>?>^+^>?+^?+++++++.<br></br>
.^++++++^^||^^^+^+^++++++++++++?+^|~^^~`|?^~-|^+>ruxzx?>?++++^+++>??++++++.<br></br>
.^++++>+^~-^?~|^??+>>+vv>>+^++>?++?|--``-^-?+>}}vxyuuxw?+>>>++>>>+>v^^??+^.<br></br>
.^+>>>+^??r^?+^????v?vzv}xw}?^>?+>>+|~||~?^??xuxuuuxxzrr++>>>>?^++++^vw??>.<br></br>
.vwxxxx}xyz>>+?wv?wv??}~+yuy} ...+^?>.-`?wv+^~.~xxxzzxxx>+?r?>}++???vx}xuu~<br></br>
.?rzxuyyuxz?????^-`-^+>`+yuux~ .~...    .`.`` .}xxxuuuuuvv}??>?+++?zxzwwwz|<br></br>
.>>wuuuuxzwv??>.-|^^`~rvwuuuuw. vuzv?>>>>vxr..}xuuuuuuuxzxr>>+^>??xv^wwwwz|<br></br>
-xxxxuxzrrwwr?|^>??>+vuuuuuuuu> ^uuuuyyyyyr. vuxuuuuuuzzzu?^>>}uuuu}vzwwzz~<br></br>
-uuxzr}??ruuxr}??v^vxzxuuxxuuuw~zuuxuxxxxu>>ruuuuxxxzxzwzux?+>vuxxuuuxwvvv-<br></br>
                  `rxxzxuxxxxxxxxxuuzxxzxuuuuxzzzwzzxxxzzzzzxuxxxxxzxxwwwwzxuxzzxxzzxuuxxzwr~<br></br>
.+>??v?>???????>???????vv??????v?????vvv????vvvv?>>>>>>??????vvv??????>>>>`<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||||||^^^^^|^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^+^^+++^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^+++>^|^^^^^^^^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^>+^++^++^^^^^+>?vrw}>~~+^^^^^^+^^^^^^^.<br></br>
.^+++++++++++++++^^^^^^^^^^^+++++^>?+^+^^^>?^^+^^^?wzzzwzv+~|^^^^^^^^++^^^.<br></br>
.^++++++++++++++^^^^++++++^^^^++^??>^+^+^^^>?>^^^^^?zzzwzw?+^+>+^^^^++++^^.<br></br>
.^++++++++++++++^^+++>++>>++^^++^??+^>}wr?+^>v??v}}wxzwwww}vv}rv>++++++++^.<br></br>
.^+++++++++++++^^+>>>>>>>>>>>+>^^~++|?}?}r^^>??uuuuzwwrrr}vvrwwvv??++++++^.<br></br>
.^+++++++++++++^^>>>>>>>>>>>>>++|^+++^~-~^+++-?uyuxwr?+>}???vrrvv>+^++++++.<br></br>
.^+++++++++++++^+>>>>>+++|+^+>>?+-~++^||~+++|-+wxzzr}v>v??>^+^>?>^?+++++++.<br></br>
.^+++++++^||^^^++>>>+++||~|~|^+v>+|~^^~`|?^~~|>++vwwzx?>?++++^^++>?>^+++++.<br></br>
.^++++++>|-+?~~^??>>>|??^>|~~~^>+>?|`-``-|~?>>}v?rxxuxr?+>>>++>>>+>v^^?v+^.<br></br>
.^+>>>++?vr+?+^????v??}??xvv?~^+~>??^~||~?>v?zxwxuxwxzrv++>>>>?+>++>+vr??>.<br></br>
.vwxxxx}xyz>>+?wv?wv??v~^uxuv ...+>v>.-`+wv>^-.~xxzzxxxz>+?}?>}+>???vz}xuu~<br></br>
.?rxuuyyuuzv????^``-^+>`+uuux~ .~`..    .`.-` .vuxuuuuuu??v??>?+++>}zzwwwz|<br></br>
.>?zuuuuxzwv??>.-|^^`~w}wuuuuw. vuzv??>>>vxr..vzuuuuuuuxzrv>>+^???z?^wwwwz|<br></br>
-xxuxuxw}v}rw?|^>??>+vuuuuuuuu> |xuuuyyyyyr. vuxuuuuxuxzzu?+>>}uuuxv}xwwzz~<br></br>
`uuxxwv>?vrzur}??v+vzzxuuuuuuuw~wuuxxxxxxu++}uuuuxxxzxzzxur>++?uxxuuuxwvvv-<br></br>
                  `wxxxxxxuxxxxxxxuuzxxzxuuuuxzzzwzzxxxzzzzzxuxxxxxzxxwwwzzxuxwzxxzzxuuxxwrr~<br></br>
.+>????????????>???????vv????????????vvv????vvvv?>>>>>>??????vvv??????>>>>`<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||||||^^^^^|^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^||||||^^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^++^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>+|^^^~-^^^~~^++^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>~|^^^|^|^|||^+++^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?+^+^^+>+|>?>|^|~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^+^^^++^^^^^^^^^+^^^^^^^^+^+?|++^??+?|~+>+>^|^^+^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^+^^+^^^^^+^^^^^++^^?>^+^^>+^>?|++??`.`.`..?>+^+^|>^^^^^^^.<br></br>
.^+++++++++++++++^|>^~~+^||^++++^^>?^^^^^^>?^+?^^^>|||^^?+. ->|+^~>^^++^^^.<br></br>
.^++++++++++++++^^-|>v??v>|~|^^+^??>+++++^^>v?>>+^|~~~~~>>|^|+^++^+^++++^^.<br></br>
.^++++++++++++++~-+v}uwwuuz?^-+>^??+^>}wrv+^>v?}v??>>+^^^^~~^?}v?+>^+++++^.<br></br>
.^+++++++++++++|-^zxxxuuuuuux?}^-~++|?v>v}^^>>?uuxuv?>+>?>+^}wwrr?v++++++^.<br></br>
.^++>+++++++++^~|wuuuuuuxuxuuzv>~|+++|~--^+++-vyyur?+^^+w?>>}zzzu??+++++++.<br></br>
.^++++++^+++++^`vuuxuuvrr~}>}uux}~~++^||~++^|-vwzv?^+?>v??>|++>vz+?+^+++++.<br></br>
.^++++++?^||^^+?vvzuvv+|^ ` ->?uyw^~|^~`|>|~|^r>>+^^wz>>>++++^^+++?>|+++++.<br></br>
.^++++++w>-^?~-+???}?.>~ ^`.. .>?uu^`-``-~>zr>r^~>v?uuv>+>>>++>+?+>v^^?v+^.<br></br>
.^+>>>+>}w}^v+|v??vv?+`.~w|>>`.. ?uz>~^^~vxyrvr+rz}?xur?^+>?>>??x>>z+?r???.<br></br>
.vwxxxxruyz+?>?w}?rv??>-~r?}v   .^vz?.``-wv>-..-wzw}uux?|+v}?>vvv?v?vr}xuu~<br></br>
.?wuuuyyuuxv????|`.`|^>-^xxxu| .|-..     ..~` .?uuuxxuuw?|>>?>?+++>?}wwwwz|<br></br>
.?zuuuuuuwvv?>>.~^++-~}}wuuuuw. ?yxrv??+>rur..v}uuuxuuuuw>>>>++??>v>+wwwwz|<br></br>
`uuuuux}?^-vw?^^>??>+?uxuuxuxx? .zuuuyyuuy}. }uxuuxxxxxzxz^+>?ruuuzvwxzwzz~<br></br>
`xxxuz>+?>`vuwr?+v+vzzuuuuuuuuz~}xuxxxxuux^-vuxuuxxxzzwzux?|^+>xuuuuuuzvvv-<br></br>
`xxuuuxuyuwxuxxxzxxxxzxuuuxzzzzwzzxxxzzzzzxuuxuuuxxxwwwzxuuw}rzuxzxxuxwvvv-<br></br>
.>?????vvvvv???>??vvv??vv?+>?????????vvvv???vvvvv?>>>>>?????vvvvv?????>+++.<br></br>
                  .||||||||||||||||||^^||||||||||||||||||||||||||||||^^^^^^^^^^^^^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^|||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>+|^^^~-^^^~~^++^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>~|^^^|^|^|||^+++^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?+^+^^+>+|>?>|^|~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^+^^^^^^^^+^>?|^+^??^?|~+++>^|^^+^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^+^^^^^^^^+^^^^^++^^?>^+^^>+^>?|++??`.`.`..?++^+^^>^^^^^^^.<br></br>
.^+++++++++++++++^|+^~|^^|^^++++^^>?^^^^^^>?^+?^^^>|||^^?+. ->|+^~>^^++^^^.<br></br>
.^++++++++++++++^|-|>vv?v>^~|^^+^??>+++++^^>v?>>+^|~~~~~>>|^|+^++^+^++++^^.<br></br>
.^++++++++++++++~~>v}uwwuuzv^-+>^??+^>}wrv+^>v?}v??>>+^^^^~~^?}v?+>^+++++^.<br></br>
.^+++++++++++++|-+xxxxuuuuuux?}^-~++|?v>v}^^>>?uuxuv?>+>?>+^}wwrr?v++++++^.<br></br>
.^++>+++++++++^~|wuuuuuuxxxxuzv>~|+++|~--^+++-vyyur?+^^+w?>>}zzzu?>+++++++.<br></br>
.^++++++++++++^`vuuxuuv}r-v+vxxx}~~++^||~++^|-vwzv?^+?>v??>|++>vz+?+^+++++.<br></br>
.^++++++?^^^^^+?vvzuv?+|^ ` `+?xuw^~|^~`|>|~|^}>>+^^wz>>>++++^^+++?>|+++++.<br></br>
.^++++++r>~+?~-+???}?.>~ ^`.. .+>xu^`-``-~>z}>}^|>v?uuv>+>>>++>+?+>v^^?v+^.<br></br>
.^+>>>+>}wr+v+|v??vv?+`.~w|>>`.. ?xz>~^^~vxy}vr>rz}?xur?^+>?>>??x>>z+?r???.<br></br>
.vwxxxxruyz>?>?w}?rv??>-~r?}v   .^vz?.``-wv>-..-wzw}uux?|+v}?>vvv?v?vr}xuu~<br></br>
.?wxuuyyuux}????|`.`|^>-^xxxu| .|-..     ..~` .?xuuxxuuw?|>>?>?+++>?}wwwwz|<br></br>
.?zuuuuuuwvv?>>.~^++-~}}wuuuuw. ?yxrv??+>rur..?vuuuxuuuuw>>>>++??>v>+wwwwz|<br></br>
`uuuuux}>^-vw?^^>??>+?uxuuxuxx? .zuuuyyuuy}. }uxuuxxxxxzxz^+>?ruuuzvwxzwzz~<br></br>
`xxxuz>+?>`vuwr?+v+vzzuuuuuuuuz~}xuxxxxuux^-vuxuuxxxzzwzux?|^+>xuuuuuuzvvv-<br></br>
`xxuuuxuyuwxuxxxzxxuxzxuuuxzzzzwzzxxxzzzzzxuuxuuuxxxwwwzxuuw}rzuxzxxuxzvvv-<br></br>
.>?????vvvvv???>??vvv??vv?+>?????????vvvv???vvvvv?>>>>>?????vvvvv?????>+++.<br></br>
                  .||||||||||||||||||^^||||||||||||||||||||||||||||||^^^^^^^^^^^^^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^^^|||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++++++++^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^+^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>^^^^|~^^^||^^+^+^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>^|^^^~~|^|~~^^+^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>|^^^^^^|>++|^^||^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^^^^^^^?^^^^>???^>??|^^||^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^+^+^^^^^^+^>>^+^?v|.|`..-v?^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^^+^-|++^^++^+^++^^?>^+^^>+^>?^^+v^~--|+~.`~>|+^|?^^^^^^^.<br></br>
.^++++++++++++++^^~|^>>+>^~|^+++^^>?^^^^^^>?^??+^^^~~|||?>|-->|++|+^+++^^^.<br></br>
.^++++++++++++++|||?}u}vxwv^-~^+^??>+++++^^+v?vv>>+^+|~~++~|^+>++^>^++++^^.<br></br>
.^+++++++++++++^-|wzwxxuuuux}|?+|??+^?rwwv+^>??z}rwv?>>>+^^~?v}?>>+++++++^.<br></br>
.^++++++>++++++~-}uuuxuuuuuuywv|`~++^?v>v}^^>>?xuux?>?>>?>?>rwrwxv>++++++^.<br></br>
.^+++++^v?++++^-?uuxuuzxw?z}zuzr^|+++^~--^+++-vuyw?>^++^???>vzwvx?^^++++++.<br></br>
.^+++++++++++>++}uuuzw^+? +.^vwyw~~++^||~++^|-?vv>^|??>}>+>|^^>?}+?+^+++++.<br></br>
.^+>v>++^^vv+^|vvvzx?-+`.   ..-vwx+-|^-`|+|-`^>^~~>?z?>>>++++^^^^+??^+++>+.<br></br>
.^>zyw++^^wr?~-|???>?.+~ ^-~|....vx?`-``~->r^|+^?rv?zvr>+>>>+>>>?+>w+^?v+^.<br></br>
.^vuux?>?vxuw>|v??vv?>~.-r>??^^...?w?||^-?xu+~vrzw??wuw?^+>v?>?^w?+}+?}???.<br></br>
.vuuuuzruyuxw??w}?rvv??~|wwwv . ..`v?.``.vv+...-vwv}wu}>+^?v?>v+v?v??v}xuu~<br></br>
.?rxuuyyuuxv+???~`.`|^>`+uuuu^ .~-`.     ..|-  >zux}wux>~|>>?>>+++>>vwwwwz|<br></br>
.>?xuuyxw?>?>>>.~+>>~~vrzuxuuw. >uxrvv?+?ru}.`^^?uuxxuuzv++>>++??>?++wwwwz|<br></br>
-xxxuuv^~..v}~^^>??+^>wzuuuuuu? .wuuuyyuuu}  rzzzuxxxxxxur|+>?ruuurvwxxzzz~<br></br>
`xxxuuwv^?}vuww>|v>vzwuuuuuuuxx~vuuxxuxuuu^.?uxuuxxxzzwzuu?|^+>xuuuuuuxvvv-<br></br>
`xxxuuuyuuuuuxxzwxuuxzxuuuxxxzzzzzxxxzzxxzzxuxuuuxxxwwwzxuu}v}wxxxxxuxzvvv-<br></br>
.>??????vv????????vvv??vv?>??????????vvvv??vvvvvv?>>>>>????vvvvv??????>+++.<br></br>
                  .||||||||||||||||||^^|||||||||||||||||||||||||||||^^^^^^^^^^^^^^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||^^^^^^^^^^^|||||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>^^^^|~^^^||^^+^+^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>^|^^^~~|^|~~^^+^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>|^^^^^^|>++|^^||^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+>^^^^^^^^^^^^^^^^^^^^^?^^^^>???^>??|^^||^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^+^+^^^^+^+^>>^+^?v|.|`..-v?^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^^+^-|++^^++^+^++^^?>^+^^>+^>?^^+v^~--|+~.`~>|+^|?^^^^^^^.<br></br>
.^++++++++++++++^^~|^>>+>^~|^+++^^>?^^^^^^>?^??+^^^|~|||?>|-->|++|+^+++^^^.<br></br>
.^++++++++++++++|||?}u}vxwv^-~^+^??>+^+++^^+v?vv>>+^+|~~++~|^+>++^>^++++^^.<br></br>
.^+++++++++++++^-~wzwxxuuuux}|?+|??+^?rwwv+^>??z}rwv?>>>+^^~?v}?>>+++++++^.<br></br>
.^++++++>++++++~-}uuuxuuuuuuywv|`~++^?v>v}^^>>?xuux?>?+>?>?>rwrwxv>++++++^.<br></br>
.^+++++^v?++++^-?uuxuuzxw?z}zuzr^|+++^~--^+++-vuyw?>^++^???>vzwvx?^^++++++.<br></br>
.^++^++++>+++>++}uuuzw^+? +.^vwyw~~++^||~++^|-?vv>^|??>}>+>|+^>?}^?+^+++++.<br></br>
.^^>v>++^^vv+^|vvvzx?-+`.   ..-vwx>-|^-`|+|-`^>^~~>?z?>>>++++^^^^+??^+++>+.<br></br>
.^>zyw++^^wr?~-|???>?.+~ ^-~|....vx?`-``~->r^|>^?rv?zvr>+>>>+>>+?+>w+^?v+^.<br></br>
.^vuux?>?vxuz>|v??vv?>~`-r>??^^...?w?||^-?xu+~vrzw??wuw?^+>v?>v^w?+}+?}???.<br></br>
.vuuuuzruyuxw??w}?rvv??~|wwwv . ..`v?.``.vv+...-vwv}wu}>+^?v?>v+v?v??v}xuu~<br></br>
.?rxuuyyuuxv+???~`.`|^>`+uuuu^ .~-`.     ..|-  >zux}wux>~|>>?>>+++>>vwwwwz|<br></br>
.>?xuuyxr?>?>>>.~+>>~~vrzuxuuw. >uxrvv?+?ru}.`^+?uuxxuuzv++>>++??>?++wwwwz|<br></br>
-xxxuuv^~..v}~^^>??+^>wzuuuuuu? .wuuuuyuuu}  rzzzuxxxxxxur|+>?ruuurvwxxzzz~<br></br>
`xxxuuwv^>}vuww>|v>vzwuuuuuuuxx~vuuxxxxuux^.?uxuuxxxzzwzuu?|^+>xuuuuuuxvvv-<br></br>
`xxxuuuyuuuuuxxzwxuuxzxuuuxxxzzwzzxxxzzxxzzxuxuuuxxxwwwzxuu}v}wxxxxuuxzvvv-<br></br>
.>??????vv????????vvv??vvv>??????????vvvv??vvvvvv?>>>>>????vvvvv??????>+++.<br></br>
                  .||||||||||||||||||^^|||||||||||||||||||||||||||||^^^^^^^^^^^^^^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^||||||||||||||||||||||||^^^^^^^^|||||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^+^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>>|^^^~-^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^>||^^^|^|^|~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^?+^^^^+>+|>?>|^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?|^^^?v>v||>>^+^|^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^+^+^^^^^^^^>>^+^??`.`...`?>^^+^^>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>+~~+^^^^++^^++^+?>^+^^>+^>?^^+?|||^^?^..->|+^~>^^^^^^^.<br></br>
.^++++++++++++++^|-^>v???^~~|^+++^??^^^^^^>?^vv>+^+|~~~~>>|||+|++^+^+++^^^.<br></br>
.^++++++++++++++~~>v}urwuxr>~-^+^??++^+>+^^+v?}}>>>^>^||^^~~^>>+^^>^++++^^.<br></br>
.^+++++++++++++^-+xxxxuuuuuuz>}^~>?+^?rwwv+^>??rvrwv?>??>++|?vv?v?+++++++^.<br></br>
.^++^^+++++++++~|zuuuuuuxuuuuzv^-~++^>v>v}^^>>?xuuw?+?++?>>>}zrwuv>++++++^.<br></br>
.++>??+^+^?v^+^`vuxxux}wr|w?ruxx?~+++^---^+++-vxxv?^^+++???+?w}?w>^^++++++.<br></br>
.^^ruu}++^vuw?>?ruuu}}|`> - ->?uz^~+++~~|++^|->??+||?v>}>+>|+^>>>|?+^+++++.<br></br>
.^^vuw?++^?zzu>>vvzw?.+- .... .>vuv-|^~`|^|-`|^|~+????>+>+++^^^^^+>>^++>>+.<br></br>
.^^}r+++^~-?vx+^v??++`+|.?++>~.  >z?`-``~->v^|+??wv??>x>~>>>+>>+>+>w+^?v+^.<br></br>
.^+?r?+???r>zv>v??vv?>^-|w?vv^?-~.>vv|^^`>x}|^vwuv>?wzz?~+?v?>v^r>^v+?v???.<br></br>
.vzzxuzwuyuwr??w}?}vv??~^xzx}  .````^....?v| . `?}r}zur+^^???>v+???>?vrxuu~<br></br>
.?ruuuyyuz}?|???~`.`~^>->uuuu+ .|-`.     .`^`  ~?xx}wuu>-|>>?>>+++>>vwwwwz|<br></br>
.>vuuuurv+~>>>>.~+>>~-rwwxuuux` +xxwvvv?vwuv.`^~>uuzxuux>~+>>++?v>>+>wwwwz|<br></br>
-xxxuuw^`..??-^^>??>>?xxxxuuuuv  }uuuuyuuuv .rzrzuxxxxxxu}|>>?ryuuv}wuxzzz~<br></br>
`xxxuuuz?+~?uzw?^v>}uzuuuuxuuxu|?xuxxxxuux|.?yxuuxxxzzwzuu?|^++zuuuuuxx}vv-<br></br>
                  `xxxuuuuyuuyuuxzrxuuxxxuuuxxxxxzxzxuxzzxxzzzuuuuuxxzzwzxuuuv??rxuxxuuxzvvv-<br></br>
.>>?????vvv????>??vvv??vvv?????????vvvvvv??vvvv}v?>>>>????vvvv}vv?????>+++.<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||||||^^^^^^||^|||^^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||||||||^^^^||||||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^++^^^^^^^+^^^^^^^^^++^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>>|^^^~-^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^^>||^^^|^|^|~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^^^^^^^^^^^^^^?+^^^^+>+|>>>|^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?|^^^?v>v||>>^+^|^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^+^+^^^^^^^^>>^+^??`.`...`?>^^+^^>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>+~~+^^^^++^^++^+?>^^^^>+^>?^^+?|||^^?^..->|+^~>^^^^^^^.<br></br>
.^++++++++++++++^|-^>v???^~~|^+++^>?^^+^^^>?^vv>+^+|~~~~>>|||+|++^+^+++^^^.<br></br>
.^++++++++++++++~~>v}urwuxr>~-^+^??++^+>+^^+v?}}>>>^>^^|^^~~^>>+^^>^++++^^.<br></br>
.^+++++++++++++^-+xxxxuuuuuuz>}^~>?+^?rwwv+^>??rvrwv?>??>++|?vv?v?+++++++^.<br></br>
.^++^^+++++++++~|zuuuuuuxuuuuzv^-~++^>v>v}^^>>?xuuw?+?++?>>>}zrwuv>++++++^.<br></br>
.++>??+^+^?v^+^`vuxxux}wr|w?ruxx?~+++^---^+++-vxxv?^^+++???+?w}?w>^^++++++.<br></br>
.^^ruu}++^vuw?>?ruuu}}|`> - ->?uz^~+++~||++^|->??+||?v>}>+>|+^>>>|?+^+++++.<br></br>
.^^vuw?++^?zzu>>vvzw?.+- .... .>vuv-|^~`|^|-`|^|~+????>+>+++^^^^^+>>^++>>+.<br></br>
.^^}r+++^~-?vx+^v??++`+|.?++>~.  >z?`-``~->v^|+??wv??>x>~>>>+>>>>+>w+^?v+^.<br></br>
.^+?r?+???r>zv>v??vv?>^-|w?vv^?-~.>vv|^^`>x}|^vwuv>?wzz?~+?v?>v^r>^v+?v???.<br></br>
.vzzxuzwuyuwr??w}?}vv??~^xzx}  .````^....?v| . `?}r}zur+^^???>v+???>?vrxuu~<br></br>
.?ruuuyyuz}?|???~`.`~^>->uuuu+ .|-`.     .`^`  ~?xx}wuu>-|>>?>>+++>>vwwwwz|<br></br>
.>vuuuurv+~>>>>.~+>>~-rwwxuuux` +xxwvvv?vwuv.`^~>uuzxuux>~+>>++?v>>+>wwwwz|<br></br>
-xxxuuw^`..??-^^>??>>?xxxxuuuuv  }uuuuuyuuv .rzrzuxxxxxxu}|>>?ryuuv}wuxzzz~<br></br>
`xxxuuuz?+~?uzw?^v>}uzuuuuxuuxu|?xuxxuxuux|.?yxuuxxxzzwzuu?|^++zuuuuuxx}vv-<br></br>
                  `xxxuuuuyuuyuuxzrxuuxxxuuuxxxxxzxzxuxzzxxzzzuuuuuxxzzwzxuuuv??rxuxxuuxzvvv-<br></br>
.>>?????vvv????>??vvv??vvv?????????vvvvvv??vvvv}v?>>>?????vvvv}vv?????>+++.<br></br>
                  .|||||||||||||||||||^|||||||||||||||||||||||||||||^^^^^^||^||||^||^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||||||||^^^^||||||||||^^^^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^++^^^^^^^+^^^^^^^^^++^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^|>+|^^^~-^^|~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^>+^^^^^^^^^^^^^^^^^^^^^+>~^^^^|^|^|||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?^^^^^>?>^??>~^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^+^>?^+^+v?~?-`|^>>^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+^^+|^^^^++^^^^+^^^^^^^^^>???>^+^v>`.``~..+++^+^|>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^~+|~|++||^^++++^^+^^>?>^^^>??|^++|||^^?+`.->|+^~>^+^^^^^.<br></br>
.^++^^^+++++++++^^-+?w}>>+^^|^^^+>^^+vwwzr+^^?v?++>||~~~>+|^^^|++^>^+++^^^.<br></br>
.^^^+>+^++^^+++^~-?}}uz?vzrvv?>^^+^+|v?^v}+^^+>?^+++?>+^^|~-|+>+^++^++++^^.<br></br>
.^^>wxz?^^>?^++|-?uuuxuuuuuxzv>^++++^~~^~|++++~>vrr?>???>>>+???>>?++^+^^+^.<br></br>
.^^>xxzw+^}u>^^-^xuuuuuxxzxxuz?~+++++^+^|++++^|zuuv?|^>??>??}r}?r?+++++++^.<br></br>
.^++zv+++>vuw}?`vuxuux?}v.v~vzu}>~~+++^^+++^|^?wwv>|->v>?>^^>?}??^?>^+++++.<br></br>
.^++rv+++>+wuu}?vruu??+|~ . `~+xuw^-~|-~^|->^+??+|^^^+??+^|+^+>>^>>+^+++++.<br></br>
.^+>>++++>+vuu}>??rv> >v..^`. .-+xu+-----|v}^^~-~?v???^+>>>+>>+>>|+?^++>++.<br></br>
.^+wx>>+^^>?zxxv???>>|^``|v??^.  ~rx|^>|-vy?^|+vv}??}vz>~>>>>>v+?+>}+++>+^.<br></br>
.^+vw+?+>?}?}vvr??}}?????}?}w?.   `| .. .?x>~+xxv>?vrrx?~+??>>}+v?^>>v+>??.<br></br>
.vwxuxz}xyu??v?}???????}wxuuxx?  ~`       .. .|?>?zvruw+^^?>>>?>?>??++ruuu~<br></br>
.?}xuuyyx}>+>v?~....-?>v?|vuuuu> -}vvvv`>v?`  ..?ux}zuu}~~>>>>++++>>vwwwwz|<br></br>
.>>zuux?>||>+>|`>?v?+|?zv`ruuuuu| >uuuywux+ `v- `z?zuuuu}|+>>+>vv|>vvzwwwz|<br></br>
-xzxuux>.^v`.-|>???+^??}}vuuuuuur .xuuuuu~ ~zuz+~zxxxxuuuv^>>?xuz?vxrzzzzz~<br></br>
`xxxuuuu}+>>v>?+r?^r}wxxxuuuxxxxx-^xuuuu> ^zxxuxxuuuxxxuxz}>??wxuuuuuzxwvv-<br></br>
`xxxuuuuuyxuuur}xuwxuxuuuuuuxzzxuxzxuuuxrvuuxuuuuxxuxzzxuuuuzwxxxxzzxxxxvv-<br></br>
.>>>????vr}rrwr}vrwr}vvvvv?vvvvwwwwwww}www}}}}}vv?>>????vv}rrrrrrrrv???>++.<br></br>
                  .|||||||||||^^^||^^^^||||||||||^^^^^^||^^^^^^^||||^^^^^^^|^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||||||||^^^^^||||||||||||^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^|>+|^^^~-^^|~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^>+^^^^^^^^^^^^^^^^^^^^^+>~^^^^|^|^|||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?^^^^^>?>^??>~^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^+^>?^+^+v?~?-`|^>>^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^+^^+|^^^^++^^^^+^^^^^^^^^>???>^+^v>`.``~..+++^+^|>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^~+|~|++||^^++++^^+^^>?>^^^>??|^++|||^^?+`.->|+^~>^+^^^^^.<br></br>
.^++^^^+++++++++^^-+?w}>>+^^|^^^+>^^+vwwzr+^^?v?++>||~~~>+|^^^|++^>^+++^^^.<br></br>
.^^^+>+^++^^+++^~-?}}uz?vzrvv?>^^+^+|v?^v}+^^+>?^+++?>+^^|~-|+>+^++^++++^^.<br></br>
.^^>wxz?^^>?^++|-?uuuxuuuuuxzv>^++++^~~^~|++++~>vrr?>???>>>+???>>?+++++++^.<br></br>
.^^>xxzw+^}u>^^-^xuuuuuxxzxxuz?~+++++^+^|++++^|zuuv?|^>??>??}r}?r?+++++++^.<br></br>
.^++zv+++>vuw}?`vuxuux?}v.v~vzu}>~~+++^^+++^|^?wwv>|->v>?>^^>?}??^?>^+++++.<br></br>
.^++rv+++>+wuu}?vruu??+|~ . `~+xuw^-~|-~^|->^+??+|^^^+??+^|+^+>>^>>+^+++++.<br></br>
.^+>>++++>+vuu}>??rv> >v..^`. .-+xu+-----|v}^^~-~?v???^+>>>+>>+>>|+?^++>++.<br></br>
.^+wx>>+^^>?zxxv???>>|^``|v??^.  ~rx|^>|-vy?^|+vv}??}vz>~>>>>>v+?+>}+++>+^.<br></br>
.^+vw+?+>?}?}vvr??}}?????}?}w?.   `| .. .?x>~+xxv>?vrrx?~+??>>}+v?^>>v+>??.<br></br>
.vwxuxz}xyu??v?}???????}wxuuxx?  ~`       .. .|?>?zvruw+^^?>>>?>?>??++ruuu~<br></br>
.?}xuuyyu}>+>v?~....-?>v?|vuuuu> -}vvvv`>v?`  ..?ux}zuu}~~>>>>++++>>vwwwwz|<br></br>
.>>zuux?>||>+>|`>?v?+|?zv`ruuuuu| >uuuywux+ `v- `z?zuuuu}|+>>+>vv|>vvzwwwz|<br></br>
-xzxuux>.^v`.-|>???+^>?}}vuuuuuur .xuuuuu~ ~zuw+~zxxxxuuuv^>>?xuz?vxrzzzzz~<br></br>
`xxxuuuu}+>>v>?+r?^r}wxxxuuuxxxxx-^xuuuu> ^zxxuxxuuuxxxuxz}>??wxuuuuuzxwvv-<br></br>
`xxxuuuuuyxuuur}xuwxuxuuuuuuxzzxuxzxuuuxrvuuxuuuuxxuxzzxuuuuzwxxxxzzxxxxvv-<br></br>
.>>>????vr}rrwr}vrwr}vvvvv?vvvvwwwwwww}www}}}}}vv?>>????vv}rrrrrrrrv???>++.<br></br>
                  .|||||||||||^^^||^^^^||||||||||^^^^^^||^^^^^^|^|||^^^^^^^|^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^^|||||||||||||||||||||||||||^^^^^||||||||||||^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^+>?>>>|^^^~-^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^>v}rv>^+^??||^^^|^|^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^}w}rzr^^|+v?>|^+>+|>?>|^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>>-|v?^+^|???^??+v|~>>^>^|^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^++++++||^~~^+++||>^??`.`.`..?>+^+^^>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^~|+++>???^^+++++^+>^^++++|`|+?|||^^?^. ->|+^~>^^^^^^^.<br></br>
.^^^++^++^^+++++^|-^?vv>??+|^+|~||^+++~|+++^^|~>+^>|~~~~>>|^|+|++^>^+++^^^.<br></br>
.^+v?++++++++++^~~?}}uwwrrw?~-++^|~|^^~|^^~~^^^+^>>^>+^^^^~~^>>+^|^^++++^^.<br></br>
.^>>^+>++>+++++|->xxxxuuuuuuz?}^|+^~--``--~^++>?vrr?+>>?>+||+???+|??^++++^.<br></br>
.^+^+++++++?+++~|zuuuuuuxxxuuzv^~|+^~~^^~^^++^vxuu}|??+>?|^>++>>^+?++++++^.<br></br>
.^+++++++^>xw?^`vuuuuxvr}~r>}uux}^~|..`.`++++++rxv>~|>>++^>^++>>>>++++++++.<br></br>
.^+++++++++?>?????zuvv^vr.~.-+?xr^      .^+++v>?>+||+|^+>>>>>>>>^^+>^+++>+.<br></br>
.^+++++^^^||^^~???v}?``?| ... .`   ..^-. .-^++^~~+v??~+|~>>>>>?+^++^^+++>+.<br></br>
.+>++++v}++|++^????>?^+^.`~>>~.   ~z+|^^`  `|||?vwv?^+?>->>>>>v?^^^++++++^.<br></br>
.+?>>>>>?vw>??}w??rr????>v?vr?v-. .vx^`~-`  .^zuw??v^>??~^>>>>?v+^^^+?>???.<br></br>
.vwxxxz}zyw+??vv>>>?v??}vzxxxzz}v` .v}|..`>. .>v?vr}^}?++|>>>>>>>>>?++ruuu|<br></br>
.?}zuuyyyzv?v?+...`.`???v}xuuxzrv> .vvr- +r.  `-|wuv^wr>~^>>>^||++>?vwwwwz|<br></br>
.>>wuyur?^>>+>-+vv}v>^?xzzzzw}rrvv. ~rzv+v. |....-?wvrzz>|>>>>}w>|>?xzwwwz|<br></br>
-xxux}+~^.. `^>>??>+++?vw}vww}}www>.-wzuz`^}v`. ..~wxxxxx+>>>?uu}?vzxxzwzz~<br></br>
`xuu}^~`>|. `>|}r>?vw}rv+??xuzrwuuxr>xuv-vuux?v+`vwxxxuuuv|?}?wuuuuuxxzwrv-<br></br>
-xuuuuxz}zw?}?}uuuwzuux?-?xuuuzzxuuuuux?wuxzzxxuzuuuxxuuuz}wzzxzzzzzxxuux}-<br></br>
.???????}wzxxxxzxxzwrrr??v?}}vrwxxzwzwrwxzzzwrrr}v?vvv}r}}}}}}}rrrrrv????>.<br></br>
.||||||||^^+++^+>+^^^^^^^|||||^^++^^^^^^+^++^^^^|||||^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||||||||||||||||||^^^^^|||||||||||||^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^^^^^^^+>?>>>|^^^~-^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++^^>v}rv>^+^??||^^^|^|^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^}w}rzr^^|+v?>|^+>+|>?>|^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|>>-|v?^+^|???^??+v|~>>^>^|^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^+^^^^^^^^^^++++++||^~~^+++||>^??`.`.`..?>+^+^^>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^~|+++>???^^+++++^+>^^++++|`|+?|||^^?^. ->|+^~>^^^^^^^.<br></br>
.^^^++^++^^+^^^+^^-^?vv>??+|^+|~||^+++~|+++^^|~>+^>|~~~~>>|^|+|++^>^+++^^^.<br></br>
.^+v?++++++++++^~~?}}uwwrrw?~-++^|~|^^~|^^~~^^^+^>>^>+^^^^~~^>>++|^^++++^^.<br></br>
.^>>^+>++>+++++|->xxxxuuuuuuz?}^|+^~--`--~~+++>?vrr?+>>?>+||+???+|??^++++^.<br></br>
.^+^+++++++?+++~|zuuuuuuxxxuuzv^~|+^~~^^~^^++^vxuu}|??+>?|^>++>>^+?++++++^.<br></br>
.^+++++++^>xw?^`vuuuuxvr}~r>}uux}^~|..`.`++++++rxv>~|>>++^>^++>>>>++++++++.<br></br>
.^+++++++++?>?????zuvv^vr.~.-+?xr^      .^+++v>?>+||+|^+>>>>>>>>^^+>^+++>+.<br></br>
.^+++++^^^||^^~???v}?``?| ... .`   ..^-. .-^++^~~+v??~+|~>>>>>?+^++^^+++>+.<br></br>
.+>++++v}++|++^????>?^+^.`~>>~.   ~z+|^^`  `|||?vwv?^+?>->>>>>v?^^^++++++^.<br></br>
.+?>>>>>?vw>??}w??rr????>v?vr?v-. .vx^`~-`  .^zuw??v^>??~^>>>>?v+^^^+?>???.<br></br>
.vwxxxz}zyw+??vv>>>?v??}vzxxxzzrv` .v}|..`>. .>v?vr}^}?++|>>>>>>>>>?++ruuu~<br></br>
.?}zuuyyyzv?v?+...`.`???v}xuuxzr}> .vvr- +r.  `-|wuv^wr>~^>>>^||++>?vwwwwz|<br></br>
.>>wuyur?^>>+>-+vv}v>^?xzzzzw}r}}}. ~rzv+v. |....-?wvrzz>|>>>>}w>|>?xzwwwz|<br></br>
-xxux}^~^.. `^>>??>+++?vw}vww}}wwz>.-wzuz`^}v`. ..~wxxxxx+>>>?uu}?vzxxzwzz~<br></br>
`uuu}^-`>|. `>|}r>?vw}rv+??xuzrwuuxr>xuv-vuux?v+`vwxxxuuuv|?}?wuuuuuuxzwrv-<br></br>
-xuuuuxz}zw?}?}uuuwzuux?-?xuuuzzxuuuuux?wuxzzxxuzuuuxxuuxz}wzzxzzzzzxxuux}-<br></br>
.???????}wzxxxxzxxzwrrr??v?}}vrwxxzwzwrwxzzzwrrr}v?vvv}r}}}}}}}rrrrrv????>.<br></br>
.||||||||^^+++^+>+^^^^^^^|||||^^++^^^^^^+^++^^^^||||||^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .||||||||||||||||||^||^||||||||||||||||||||||||||||^^^^^|||||||||||||^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+^^^^>+^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>^^^^^^?}wwwv+^^>>?++^^~~^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|vw}}wzv^^|>|??^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?^|vv+^^^+|+??+^+|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^|^~|||+++^|v??vv>v^+?>|^^~|^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++^^?+^|+++++~+??>-.-...`v?^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^-|++^^^+^^++++++|>^^++^+^|--|?|~~~|>|..~>|+^~?^^^^^^^.<br></br>
.^+^++++++^^^^^+^^~|+??>>^-~|^-~~~^++|~^+^~~~~-~^|+|~~~~?>|~~+|+^^+^++^^^^.<br></br>
.^++++++++++++++|~+v}yr}xzv+|?|~|~-~~~-~~~--|^^^>>>^>^||+^~^^+>+^+v+>+++^^.<br></br>
.^+++^+++++++++^-^zzzxuuuuu}v>v^^+^~~-`-~~~|++>vvvv??>>>~+^?>vv>^>v+^^+++^.<br></br>
.^+++?}>+++++++~~ruuuuuuuux}rwv|-|+^~-^|~|^^++?xz>v?+>>>|+|||+++>>>+++++++.<br></br>
.^+++wuv^++>?+^->vzxzxwzr>zvzuxw?```..``.|++++^ru?|+>>>++++++>>>>+++++++++.<br></br>
.^+++>?>+++++++???vwvr|^? |.|?}uw        |+++++??>~~^|^+>>>>>>?>^^^+^+++++.<br></br>
.^++++++^^|^+^?v????>>?...    `??`   -`  .|++++|~|???~+~+>>>>>?v^+>+^+++++.<br></br>
.^+++++++|-|?vwv??wr?+?>..`^^`  .|   ~+~   ||^|^?wv?^|>+^>>>>>?w^^^^+++++^.<br></br>
.^+>>>+^+????vxr?vwr????+??v}>>.` `  .~^`  `~?xuzv>?^+>?^>>>>>>v+^^++++??>.<br></br>
.vwxxxzvvuwv?vv???v}v?>?vwzzxrzvr+~. `-..   .~v}?>v}|>?++>>>>>>>>^>>?+vuuu~<br></br>
.?}zuuxzwzvv??`.....-?>>?v}xuxzw}}v> .?`.    .|~|ruv~?r^>>>>>^^>>>?zwrwwwz|<br></br>
.>>wuuurv?>>?`->>>?^`>^?v}}rrrr}}}?>`.v>.   ..~^`|}w|>zvv>>>+?>^+++wxzwwwz|<br></br>
-xxuuz?~-`.`+>??vvv?+^?vwr}}}}}}}rvv~.?v`  .+v?`..`?rrxv}>>+?xx???rxuxzzzz~<br></br>
`xuz?~`... `.||>?>+^?vzzr}}wwrr}wxrrvvzzv>}zz>.  .`-?xx}vv?+vzxuuuuxzz}vvv-<br></br>
`xz?^+v>?>>+^?rwv??}zuuw}wxuuuxzzuxzxuuzwuuzrv????vr}zuxuuurwxuxxxw}}}}}vv-<br></br>
.??????vvvzuxxxxuxxzxxxz?>>??wv?wxxxxxzwxxzzw?v???????vxxuxzwwwwwwv>>+++++.<br></br>
.|||||||||?v??v?????????^||||>^|>????>++????>^>^^||^|||>>???++^++++^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||||||||||||||||||||||||||^^^^|||||||||||||^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>+^^^^>+^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>^^^^^^?rwwwv>^^>>?+^^^~~^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^^|vw}}wz}^^|>|??^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?|~?v+^^^+|+v?+^+|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^||~|~|+++^|v??vv>v^+??|^^~|^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++++^^?+^|+++++~>??>-.-...`v?^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>|-|++^^^+^^++++++|>^^++^+^|--|?|~~~^>|..~>|+^~?^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^^~|+??>>^~~|^-~~~^++|~^+^~~~~-~^|+|~~~~?>|~~+|+^^+^++^^^^.<br></br>
.^^+++++++++++++|~+v}yr}uzv+~?^-|~-~~~--~---|^^^>>>^>^||+^~^^+>+^+v+>+++^^.<br></br>
.^+++^^++++++++^-^zzzxuuuuu}?>v^^+^~~-`-~~~|++>vvvv??>>>~>^?>vv>^>v+^^+++^.<br></br>
.^+++vr?^++++++~~ruuuuuuuux}rwv|-^+^~-^|~|^^++?xz+?v+>>>|+|||+++>>>+++++++.<br></br>
.^+++zy}^++>?+^->vzxzxwzw>zvzuxw?```..``.|++++^ru?|>>>>++++++>>>>+++++++++.<br></br>
.^+++>?>+++++++???vw}r|^? |.|?}yz        |+^+^^??>~~^|^+>>>>>>?>^^^+^+++++.<br></br>
.^++++++^^|^+^?v????>>?...    `?v`   -`  .|++++|~|???~+~+>>>>>?}^+>+^+++++.<br></br>
.^++++++^|-~?vwv??wr?+?>..`^^`  .^   ~+~  .||^|^?wv?^|>+^>>>>>?w^^^^+++++^.<br></br>
.^+>>>+^+????vxr?vwr????+??v}>>.` `  .~^`  `|?xuzv??|+>?^>>>>>>v>^^++++???.<br></br>
.vwxxxzvvuwv?vv???vrv?>?vwzzurzv}>|. .-..   `~v}?>v}|>?++>>>>>>>>^>>?+vuuu~<br></br>
.?}zuuxzwzvv??`.....-?>>??}zuxzw}}r? .>`.    .|~|ruv~>r^>>>>>++>>>vxwwwwwz|<br></br>
.>>wuuurv?>>?`~>>>?|`>^?v}}rrrr}}}}v. ?>.   .`|+`|}w~>zv}>>>+?>|++>zxzwwwz|<br></br>
-xxuuz?~``.`>>??vvv?+^?vwr}}}}}}}r}r~.>v.  .+}v`..`?}rx?}>>+?ux???rxuxzzzz~<br></br>
`xuw?~`..  ``~|+>>+^?vzxw}rrrrr}rxrrvvwzv>}wz>    .`>xxv?v?^vxxuuuuxzzvvvv-<br></br>
`xz>~^v+?>++^?rwv??}zuuwwzxxuuxxzuzzxuuzwuuzrv?>??vrvzuxuuurwxuxxxw}vvv}vv-<br></br>
.??????vvvzuxuuuuxxxxxxzv>>>?zv?wxxuuxzwxxzzw?v???????vxuuuxwwwwwwv>>+++++.<br></br>
.|||||||||vvvvvv????????||||^>^|>?????++????>^>^|||^|||>????>+^++++^^^^^^^.<br></br>
                  .||||||||||||||||||||||||||||||||||||||||||||||||||^^^^|||||||||||||^^^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?vvv?|>>^^|+>|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^^^^?v}wwwzv+>>?^>~|^^~-^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>vrvv}wr+^^>?>^^|^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++|^>^~|>^+++^?>?>>^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++|~+^-~++++^>v??v?>v||>>|^^~|^^+^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++^>>+|^+++++~+??>~.`....?>^^+^~^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>+~|++^^^^~^+^+++^|^|^++^^^~--~?~---~>-. ->|+^|>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^^-|+??>>|-|+--~~|++^-|^^|~-~|-^+^^~~~~~?+~`~+|++^+^>+^^^^.<br></br>
.^^+++++++++++++|~+v}urruw?>~-|^||~~~--~---~^+^^+++^>^||>~|^++++^|>?>+^+^^.<br></br>
.^+++++++++++++^-^zzzxuuux}v}^v+^+^~~``-~~|^++++>vv?>>>>|^>^^>>+^>?>+++++^.<br></br>
.^++++>++++++++~~vuuuxxxuuxzxwv|~|^^`-^~`|^+++++??vv?>++^+^||^>>>>>+++++++.<br></br>
.^+++>>>++++++^->?wuxuwzwrxzxxzw- ..  .. |++++^??++>>>>>>>>>>>>>>++^++++++.<br></br>
.^+++++++++++++????zxx??v`>+>wzx`        `^++++??+~~^||^>>>>>>>v+^^+^+++++.<br></br>
.^++++++^^|>?>vv????v??+`  . ^|r^   .|~.  `++++|~|???~>|^>>>>>>r++++^+++++.<br></br>
.^+++++++|~+?}r???ww????.-`>^-`|-`  .~+^.  -^|~^?wv?^|>>^>>>>>>r>^^^+++++^.<br></br>
.^+>???>?v?v?}xv?vwr?????}vwrvv>^`   `~^-  .|+ruzv??|+>>^>>>>>>v>^^++++???.<br></br>
.vwzzzxvwu}v?vv??vv}v???rxuxuxxzwv`  ^-...  .|}}?>v}|>?^^>>>>>>>?++>>>vuuu~<br></br>
.?}wxuuxwvvv??`....`^??>??vwxxzrv}^  -?`.    `^||ruv~?r^+>>>>++>>>>rzwwwwz|<br></br>
.>>wuuw??>>?> -++>>-.?>?vvvvrrr}}}?   ?>.    ..``|rw~>z?v>>>>?>|>>+?xzwwwz|<br></br>
-xxuuz>--.`+>>vv}vvv>|+?rr}}}}}}}r?.. `>    .`....`?}rz>r>>>}ux>^^+}xxzzzz~<br></br>
`xuz?-`.. ```+~>>>+||?rvv}}rrrrrrv^..``..-`..`+|  .`rxz>??>+}zz???vwrzzrvv-<br></br>
`xz>~|+>?>>^+?vv?>>r?vrrwzxxuuxxu?~|~|^?zx}v?rzw???rzxuvv}??wxzzwwr}vwxwvv-<br></br>
.??????vvv}zrxuzv>vzrrwx}?>>?v?}xxxxxxuuuxxxxzxw???vrwzuuuxxxxxxxxwrv>>>++.<br></br>
.|||||||^?vr}rwr}}r}rr}}vv+|>???vvvrrrrrwwwr}vvv>^+>>>>??vvvvvv???>>>+^^^^.<br></br>
                  .|||||||||^||^^^^^|^^^||||||||||||^^^^^^^^^^||||||||||||||||||||||||||^^^|.<br></br>
                  .|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?v}rrv|??+^|>?|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?zwwzzzv+???|>||^^|~^^^~|^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?rwv?}w}^^^?v?^^^^~||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++|+>|~|+^+++^??v>>^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++|~++~^++++^>vv?v?>?||>>|+^~||^?^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++^?>+^+++++^~+??>~.`. ..?>^^+^~>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>>~|+^^^^|-^+^+++^^^^++^^^^~--~?~``-~+`. ->|+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^^-|+??>>|~+?~-~-|++^-|+^|~-~|~>>^^~~~~|?+~`~+|++^+^>+^+^^.<br></br>
.^^+++++++++++++|~+v}urruw??|-^^^|~~~`-~---~^+^^+>++>^||>~++>>++^|+v>+^+^^.<br></br>
.^+++++++++++++^-^zzzxuuuz?vr^v+^+^~-``-~~|^+++^+?vv>>>>^|?^^>+^^>?>+++++^.<br></br>
.^+++++++++++++~-vuuuxxxuxzzuwv|-|^|`~^~-|+++++^>?vv?>++^++^+^>>>>>+++++++.<br></br>
.^++++++++++++^->?ruuuwwwxuuuxzw`     `. |++++^>+^+>>>>>>>>>>>>>+++^++++++.<br></br>
.^+++++++++++++????zuurv}~?v?xux.        .^++^^v?^~~^~^|+>>>>>>}>^^+^+++++.<br></br>
.^++++++^^|>?>vv????rv??~ .. ?^z+   -^^`  `++++|~|???|?+^>>>>>>w>+++^+++++.<br></br>
.^++++++^|~+vr}>??ww????`|`>^~`>|`  .|++`  `^|~^?wv?^|>>^>>>>>>r>^^^+++++^.<br></br>
.^+????>?v?v?rx??vwr?????r}zr}}?>|  .-~^-. .|^}uzv??|+>>^>>>>>>v?^^++++>??.<br></br>
.vrwrzx}zxvv?vv?vvv}v???wuuuuxxxzr.  >-...  .|}}?>v}|>?|^>>>>>>>?++>+>vxuu~<br></br>
.?}rzuyxrvvv?>`....`+??>??vwxxzrv}|  |?`.    `^||ruv~>r^+>>>>++>>>>rzwwwwz|<br></br>
.>>wuu}?>>+v^ -+++>`.?>>???v}rr}}r>   ?>.    ...`|rw~>z>v>>>??>|>>+?xzwwwz|<br></br>
-xxuuw>--.-?>>}}}}vv?|+?}rr}}}}}}r?   `+    .   ..`?}}z+r>>>wux+|^|vxxzzzz~<br></br>
`xuz?-....|-~>~+>>+^|?v?v}}rrrrrrv| ... .`.  `?>. .`zxz^?>>+rzw>>??}}zxwvv-<br></br>
`xz>~~|>??>|+?v?>++r>?vrwzxxxxxxu+`-``~?zxv??wux???rxux??v>?zxwwrr}vvwuzvv-<br></br>
.??????vvvvw}zuz?^?wv}}xr?>>???wuxxxxxuuuuxxuuuxvv?vxxuuuuxxuuuuuuxxv>?>++.<br></br>
.|||||||^?}wzzzzwwzwzzzwwr>|?v?}}}wzzxxxxxxzwwrr?^>????vvvvvvr}}v????>^^^^.<br></br>
                  .|||||||||^^^^^^^^^^^^^|||||||||||^+^^^++++^^|||||||||||||||||||||||||^^^|.<br></br>
                  .|^^^^^^^^^^^|||^^^^^^^^^^^^^^^^^^^||^||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^+++++^^+++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>>?>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>v}rwwr?||^^^|^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+v}wwwzr}?>+^^^+~^^^|~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>vwv??v}v>>^^^|~^^^~||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++||+^|~~^^++>>>>^|^^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++^`^?|~++++++vv?????v|^?>|^^~|^^+^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^+^||++++++^^?+~^+++++|^???>~.`...`v>^^+^|^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^-|++|^|`-^^^++^|^^^++^|^~-`-|>|---~>~..->|+^|>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^^~|+??>>^^~~`~~~^++|~^+^|--|||++^^~~~||?>~`-^^++^+^+^^+^^.<br></br>
.^^+++++++++++++|~+v}urrwv?>--^^|~~~-`-~--~|^+^^+++^>^||>~|>>^>+^||+>>+^^^.<br></br>
.^+++++++++++++^-^zzzxuuzrrwr^v+^+^~-`--~~|+++++>?v?>+++|~>^|^++^+>>>>+^+^.<br></br>
.^+++++++++++++~-?zuuxuxuuxuywv~`-~~.|^~`|++++++>?vv?>+++>>^+++>>>>+++++++.<br></br>
.^+++>v?++++++^~>?vxuuwzw?x}zux~      `` ~++++^++>++>>>>>>>>>>>>>+++++++++.<br></br>
.^++++vv+++++++????}zz+>? ^`+}r-    ...   ~+++^>>+~~+~^|^>>>>>>??+^+^+++++.<br></br>
.^+++^>?^^|???vv???????>-..  .-|.   ~^^-  .|+++^~|???|?+|>>>>>>?v^++^+++++.<br></br>
.^++++^?>^|+vz}>??rzv???|^~??-..    `|++-  .||~^?wv?^|>>|>>>>>>?v^^^+++++^.<br></br>
.^+>??>>v}vv?wx??vzw?????v?}z}?-`   -~~^~.  .^}uzv??|+>>|>>>>>>??^^++++>??.<br></br>
.vwxxxx}zzvv?}v??vvrv???vzxxxzxr>.  .?-...   ~}}?>v}|>?|^>>>>>>>>>+>+>vxuu~<br></br>
.?}zxuxwv?v?>^`...`->??>??vrzxzw+.   ^?`.    .^||ruv~?r|+>>>>++>>>>wzwwwwz|<br></br>
.>>wxrv>>^?v- `~-|~..?>>?????}r}^..   v>.    ...`|rw~>z>?>>>??+^>>+?xzwwwz|<br></br>
-xxuuz?~`.|?+?}}}}}v?^^?}rr}}}v-``.   `>    .   ..`?}}w^}>>>wuv|^^^>zzzzzz~<br></br>
`xuw?~`. .--^>^???>^^+v??}}rrwv```....        `.  .`?zz^v>>+wzr^^^+>vrrrvv-<br></br>
`xz>~|^+?>+|>v??>+>v+|>?vzxxuxx?~`......-?v??vv????rwxx?+>+^}wr}v}vvv}wrvv-<br></br>
.??????vvv?>>rr?^~>v?+^>+>>>??vz?|~~~~~?xuuuzzuuwv??xuuzvvvvzzrzuxv??>vv>+.<br></br>
.||||||||+>?vvvv?>?????>>+^|?v?vv?+~~~-vxzzzzwwzv>>?vvv}rrrwwzzzw}vvv???>^.<br></br>
.|||||||^++>>>>??>??>?>>>^||^^|^|||~```~^^+^^^+^^|^|||||||^++>>>>^|^^|||^|.<br></br>
                  .|^^^^^^^^|||||||||^^|^^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^++++++^+++^+++^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>???+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?wwwzww?|^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+wwwwzw}v??+||>>|^^^|~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vrw??v??v?v>+||~^^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++|>>||~^^++>?v>>^^^^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^++++++|~+?|^+++++?v???>??v^+??|^^||^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^||++++++^?>+|^+++++~>???>-.-...`??^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^-|++|++`~^|^++^++^+++^||~~--|>|~~~|>|..~>|+^~?^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^^~|+??>>+?|~|~-~^+^-|+++|--^^+>+^+|~~||?>|`~+^++^>^^^^^^^.<br></br>
.^^+++++++++++++|~+v}yrrv??+-~^^|~-~`-~~--~^++^^>>>^>^||+^~>?^?+^|^^>?>^^^.<br></br>
.^+++++++++++++^-^zzzxuurvzur^v+^+|~-`--~~^++++?????>+++^~^++|+>^^>>??>^+^.<br></br>
.^++++>++++++++~-?ruuxuuuuuuywv-`-~-`|^~`|++++^>?vvv?>>>++>+>>+>>>>>++++++.<br></br>
.^+++?r?++++++^~???zuuwzr>wvzux`      `` -++++|+>>>+++++>>>>>>>>>>++^+++++.<br></br>
.^+++>w}+++++++????vwr|^? |.^vv.    ...   -+++^+>+~~>^>^|>>>>>>>}+^+^+++++.<br></br>
.^+++^>v^^|?vvvv????+??+-`..  `-   .|^^|.  |+++^~|???|?+->>>>>>?w^++^+++++.<br></br>
.^++++^?>+^+vz}>??}zv???+^^??-      `|++|.  ~|~^?wv?^|>>|>>>>>>?r^^^+++++^.<br></br>
.^+>>>+^vrvv?zx??vzz??????>?xv>`.   |~~^|-  .^}uzv??|+>>|>>>>>>?v^^++++???.<br></br>
.vwxxxx}wrv??}v??vvrv????rwzzrzv^   .?-....  ~}}?>v}|>?|^>>>>>>>>?+>++vuuu~<br></br>
.?rzuxw}v?}?+~`...`|>??>??v}zxzz^    >?`.    .^|~ruv~?r|+>>>>++>>?>zwrwwwz|<br></br>
.>>wzv?>+|?v` `-`~- .??+?????}r}~    .r>.    ...`|rw~>z>>>>>??^^>>^vxzwwwz|<br></br>
-xxuux?|`->>^?}rrr}v>+^?rrr}}}v` ...  ^?      ....`?}}w^v>>>wu?~^^^+zzzzzz~<br></br>
`xuw?|`..-`->>+???>++|??>}rrrrv ... .             ..>zw|v?>+wzr^|^^+v}vvvv-<br></br>
`xz>~|^+?>^^????++>v>`+??zxxuxx+ .....  .>vv???>>>?r}zx>^>+^}r}vvvvvvvvvvv-<br></br>
.??????vv??^+vv+|^+?>~~|~^>>>?}u>. ....?xuuu}rxuzv??xxuw>???wrvruz>+>>wr>+.<br></br>
.|||||||||^>????>|>?>?>^^|||>}?vv?-... ?zwzzzzzxw???rwrrzzzuuuuuxw}}}vvv?^.<br></br>
.||||||^+>?????vv?vv?v???+||++|+|||-...~^^^^^>>+^^+^^|||^^+??????^^^^|||||.<br></br>
                  .|^^^^^^^^|||||||||||||^^^^^^^^^^^^^|||^^^^^^^^^^^^^^^^^^^^|||||^^^^^^^^^^.<br></br>
                  .^+^^^^^^^^^^^^^++++++^+++^+++++^^^^++^^^^^^^^^^^^^^^^^^^^^^^^^^^^+++^^^+^.<br></br>
                  .+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>??>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?}wzzw}?+^^^^^|^+^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?vwwwwzz}^^^^^^^+~|^^~~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?vrww}}}?^^^^|^^||^^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|+??v??>^^++++++|||^^^++|>>+|^^~~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^|^^|+++||^~~^^|++++++^|~^^+v?v^^?>|++||^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^+^^^^^|>^`|+++++^~>?+^^^+|~~`|^|>?~.`. .`v>^^+^|^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>^-|>++^--^+^+++|++|^^^~`~|||^|?|--~|>-..->|+^|>^^^^^^^.<br></br>
.^+^^+++^^^^^^^+^^-|+>??>|~--|~|^+^|~^^^|~-~+>++>>+~~~~~?+-`-^^^^^>^^^^^^^.<br></br>
.^++++++++++++++|~+v}u}vrr?+--|||||~~|||--^+^>>~>>+^>^||>^`|+>?+>+^^+>+^+^.<br></br>
.^+++++++++^^++^-^zzzuuuzzxur+v^~|~-``----|^|^++?vv?>+++^~~+++++>++>>>>++^.<br></br>
.^+++++++++>?++~~vrrxuuuuuuuuwv~`-~-.``.`~^^++>?}r}?>+++++>>^^|^^>>>>>???>.<br></br>
.^++^>?>+^?v?+^-?v?vruzxzvxrxuz+. .. .-` .|+++^>v?>>??>>>>>>>>>>>>>>++++>>.<br></br>
.+++++??>?>?>++????vvr?>v +~>rw+|`... ..  .~^+^>|~+>+^+^^>>>>>>>?>++^+++++.<br></br>
.++++++v?+|>?v}v???????++... -~>||` ....   .`++~`~+^+~+~|>>>>>>>???^^+++++.<br></br>
.+++++^?v>>>}zr>??v}v???^^~?>-.`.~.  .`||.   .-|^>+>^|^~+>>>>>>>?v+^++++++.<br></br>
.^+>>>>^v}v?vxu???zurv???v?}z}?|~   ..~^|~.  .^ur?>?|+|^+>>>>>>>?v+++++>??.<br></br>
.vwxxxwv}}v??}v???v}v????}zwzzx}-   .?-....  .|}?>v}|^+^+>>>>>>>vv^>++vxuu~<br></br>
.?}wrv????v??>^~-~^????>?v}wxzz+    `??`.    .~||ruv->?+>>>>>>>>???xzwwwwz|<br></br>
.>>wxw}>^??>.      .`??+>>>??vw>    .-w>.    ...`|r}>??+>>>>>>++?>?rxzwwwz|<br></br>
-xxuuz?^^+?^~>?????^^?>>???vvvv^.   ``>v`.    ....`?r??+>>>+>?>++>+?zzzzzz~<br></br>
`xuw?~`++`~>???vvvv??>>>?rrrwwv|`   .-`-`.  ..    .-^>>+}???>>?++++>vvvvvv-<br></br>
`xz>~~>vv>>+>+??>++vv~~+?zxuuuuv^.   -|-^?vvv?>????}??}??????>?>?>>?vvvvvv-<br></br>
.????v}rrrv>^^+|^>?v+~~????>>>?v?`   .->vruuw?v}v???}}}}?v?vv>>???>+>?rv++.<br></br>
.||||+????vv????vvv^|+??+||^^>>vr?.    ^vzzzw??v?+^+>??wzzwu}>???>>}r}?>+^.<br></br>
.|||||^>?vv}}v}r}}?^+v}?^^|>+|>v?>.   `?v???>^^^^^^^^|+v???v?>>??>>??+||^|.<br></br>
.|^^+^|+>>+>>+++++>>>>+^^^^>?>????~`>>??????+^^^^^^^^|+??????????????>+^^^.<br></br>
.^^^^^^^^^^^^^^^^^^++^^^^^^>?v??????v????????>>>>>^^^^+>???????>>>>>??+^^^.<br></br>
.+++++++++++++++++++++++++++?v???vvv?????????????>>>++++++++++>+++++>>>+++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?vv????????????????????>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>??vv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>??>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?rwzzzwv^^^^^^^|>?|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>wwwwzwwx}^^^^^^^>||^^|~^^^||^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?wwwwwwww?^++++^^^^^^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^>^^+vrww}v?>^+++++++^^^^^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^|+>^+++|>??|^~^+++++^^||^^>v>v^|>>|++||^+?^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^??~|++++^^^+?>^^^^~~||^||?v~.`.  .?>^^+^~>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|>+~|>??+-|+++++^>>>^^~~-^vvvv?+?|``-~+`  -+|++^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^|-|++>v>|-~-~~^++^??v>+^^>?vv?>vv+~~~~|?+~`-|^^^|>^^^^^^^.<br></br>
.^++++++++++++++|~^v}xvvrzv^-`~~|^|?vvvv??v??v?~?>^+>^||?^~~+??+?>^^^^^^+^.<br></br>
.^+++++++++^^++^-^zwwuxxuuuur+v^----^^^|~-~~|+++?}rv?>>>+|~^>>>+?+^+++>++^.<br></br>
.^++++++++^?v>+~~rr?wuuuxuxuuwv|--~~-``---~|^^>xuuw+^++++++>^+++^+>>>?v}}?.<br></br>
.^+++++++^vur>^`?v??vuuuxuuuuxzw?-~|`|+|` -+++^ruv+?}}?>>>>>>?>??>>>>>>>>?.<br></br>
.++++++^>?vv?>+??????rwvw~?v?uuuu?-|. `+.  -^+^?+|?v>>>>>>>>>>>>>>>+|^++++.<br></br>
.+++++^?v+~+?v}v??????v>? .. >^zuu?.        `++~|++++|+|+>>>>>>>>v?|^+++++.<br></br>
.+++++^?}???wz}>?>?vvv??||`>^-`>^w^   .||`    -||||>^||^>>>>>>>>?u?^++++++.<br></br>
.^+>>>>^vvv?rux???zuuv???}vw}r}?>.    ~^||.   -uv+>?|+|>>>>>>>>>vx+++++>??.<br></br>
.vwxxw}vvvv??vv??v}}v?v??vzwwxuz-   `?-.....  `}?>v}||^>>>>>>>>>xv+>++vxuu~<br></br>
.?rr??>>>v??rv?>>>?rv??>?v}wxwz~    ?r?`. ... -||ruv->>>>>>>>>>?uvzxwwwwwz|<br></br>
.>>wuxr?|v?|.      .~??++>>??vr+   .v}w+.    ...`|rvvv>>>>>>>>>>}vwxxzwwwz|<br></br>
-xxuuz?>??v~`^>^^+^.`??+>????v}}`   ?}}r+~     ...`?w>>>+>?++>>>>v?xzzzzwz~<br></br>
`xuz?--z}.>v}}vvvrrvr?|^?rwwwwrr>   -}}wr~. .`    .|+++>rzxw^+>>>>>v}vvvvv-<br></br>
`xz>~-?xw>??>|>>>+^vw^-|vzxxuuuxw.   >wwx}vvrv>????}?>vzzzzx?^>>?>>?vvvvvv-<br></br>
.???vzxxxuwv^`~^+v?v^-~ruv?>>>>>?~   .>>>?xuz??????????vzz?zv^>>?>>+??}?++.<br></br>
.||||?zxzxuz>>vvwxr~-~vz?^|^||+rxv    `^vxxzr||?>|||||^zuzru?|+^^^+}w}>^^^.<br></br>
.|||^|+}zzxuxxzzzx?-^}z?~||}?~}uzw.   ~wuxzzv||^|||^+|?uuzzx?>>?vv}zw>|^^|.<br></br>
.|^+>||>}v?vv?????vv}v?|^^|vx?zxxx|`}}xuxxzz?^|+^+^++|>zuuxzzzzxxxzzzv>^^^.<br></br>
.^^^^^^^^^^^^^^^^^^++^^^^^^?uuxxuuxxuuuxzzwww}?v}?++^^+vwwwrrrr}vvvvww?^^^.<br></br>
.++++++++++++++++++++++++++^}uxzuuuuzzzzzzzzzzxzw??>+++>>>++>>>+++++>>>+++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>ruuzzw}}vvvvvvvvvvvv}rv>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>vzzxz}?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|||||~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+??vvv?>+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>vrwwwww}v+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^vwwwwwwwwr>^^^^^^+~|^^|~^^^||^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|vwwwwwwwwv^++++^+^|^^^|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^|^+^+vrwrv???|^++++++^^^^^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^+^|~|^++^^>?>^^~~^++++++^^^^>??v^^?>|++||^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^+^^|^~~^`~+++++^|^>v>|+++|~-`--~^?~.-...`v>^^+^|^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^|+>-||||-~^+++++^>>>|^^^|-~~-~~-|``-~~>-..`||+^|>^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^|~|>>??||~~~~|^++|>??^^^~~|~~-`^>|~|~~|?+~`~^+++++^^^^^^^.<br></br>
.^++++++++++++++|~+v?}}vv?+~-`-~|^^^+>+^~-~~-~-`>?>+?+||>^`~>?>+v?^^^^^^^^.<br></br>
.^+++++++++^^++^~+}}rwuuuxwwr+?~--~-`--~-`--~|||?}rv???>|~~+>>++>+^++++>+^.<br></br>
.^+++++++++>?++|>v??rzuuuuuuuwv|--~~-``-``-|++?wzw}+|?+^^+>>^?>>++>>>??vvv`<br></br>
.^++++++^^?v?+++?v??vwzxwvxrxuzw?~||`|^~. `+>>>vw>|?>?+>>>>>>>>>>>>>>>>>>>`<br></br>
.+++++??>>??>>???????}v?>.+~>}wuu?-`  .-   .^+^?+|?}>>>>>>>>>>>>>>>+|+++++.<br></br>
.+++^?vv?^~?vvvv????v?v^~    `~vwu^         .++~|+?>+|^~+>>>>>>>>??|^+++++.<br></br>
.+++??^?v??vxxv???vvvv?>^|-+^`.``?`   .|~.   .-~^++^||~|>>>>>>>>>r?^++++++.<br></br>
.^+>>?>>?}?vzuw??vxxz????vvr}v?|~     ~^|`    ~w+|^?|^|>>>>>>>>>?w>^+++???.<br></br>
.vwxxz}vvv??vvv??vvv??v?v}zzwzxr~   .>-....   .v>^v}|~^>>>>>>>>>vw>>++vxuu~<br></br>
.?}}v????v??r?>++>?rv????vvrxwz+    ^r?`.      `|ruv-^+>>>>>>>>>?vrxwwwwwz|<br></br>
.>>rzw}>+v?~.      .~??>+>>?rrr^    ?}w+.  .    .|}}>>+>?>>>>>>>??vxxzwwwz|<br></br>
-xxuux?>???~`^>^^+^`-??>???vvv}~   .v}}r^` ..   ..`?r?+>?>>>+>>>>?>}zzzzwz~<br></br>
`xuw?^`?>`>v}}vvv}}}v>^+?}rrwww^.   |}}w}-. .`  `..~+++?v???^+>>>>>>vvvvvv-<br></br>
`xz>~-+?v>??+|>>>>^?v|^>vwxxuuuz|   .?wwx}vvv??????}>^+?v?vv?^>>>>>>?vvvvv-<br></br>
.???}xuuuw?}+`~^+>+??^^v}?>>>>v}v.   `>>>?vzzvv??????+>}xvvr?^+>>>>++?}}?>.<br></br>
.|||^vzxzxwv^|>vwvv++?v?+^>+|^vwx?    `?>?????++^|^||^?zx}?vv|^^^^^>>r}?^^.<br></br>
.|^|||+rzzxxxzzxxxv^>}v+^>?w>|vvv?`   .?zvr}}?>^^^^^^^vxxxrw?^^>??v?}v+^||.<br></br>
.^^^+^|?}}v}r}}}}rv?}rv??>>rv>??}z?|~~|vuuxxv?>^^^^^^^?zxxuxr}rzxxxxxr?^^^.<br></br>
.^^^^^^^^++++++>>????>>>>+^?ux}vxuxxwwwxxxzzw}?v?>++^^>}wwwzzzzrvvvrzw?+^^.<br></br>
.+++++++++++++++>>>>+++++++>wuuxxuuuxxzzzzzzzzxx}?>>++>>+>>>>>>++++>>?>>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>?wuuxzzr}vvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>vwzzzr?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|++^|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?vrwwwrv+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?wwwwwzwwz>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+wwwwwwwwwz?|^^^^>>|^^^~~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^}wwwwwwwwr>^^^^|>^|^^^~||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^+vrr}???}?|^+++++^^^^^++|>>+|^^|~^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^+^+^^~^+++^^>?+++|^^^+++++++^|>v?v^>??|++||^^^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^+^||^~~^~+++++++|+?}>~+++++^||~~~?~.-...`v?^^+^^+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^+^~+?|+~~^-^+^++++^>++^^+^++^~~|~-```~~|>|.`-||^^~?^^^^^^^.<br></br>
.^+^^^^^^^^^^^^+^|^+v??+-^~~|~|+++^+?>^++~|^~~~~-|-+>|~|?>~~^>+++?+^^^^^^^.<br></br>
.^++++++++++++++~|?v?>}v?+~----|^^^^||^+|~-~~--`>v?>v?+~>+-^>}>^}?^^^^^^^^.<br></br>
.^+++++++++++++||>??v?xuuxv}r^+~-~~~---~--~|~|||>vv??vv?|~|>++++>+^^+>+>>^.<br></br>
.^++++++++++++^^v?>?v}uuuuuyywv|`-~~~``-`~>??>?vv??>^v?>+>>>+???>+>>>???v}-<br></br>
.^++++++^+++++>v???>?vww}>wvzuzw?~|~`~^~..|??????|+?|>|+>>>>>>>>>>>>>+>+>>`<br></br>
.+++^>rr>+??+>}?????v???- |.|v}uu?-. .``   .^+^?+~?z?+>++>>>>>>>>>>+|^++++.<br></br>
.+++^}xv>|^v}v???v?vvvv^    ..`?}u~    .    .++||+wv+^^~^>>>>>>>>?v|^+++++.<br></br>
.++^v}?????wyx????}vvv?|||~|^`  .>.   .+~    .~~^>?+||~|>>>>>>>>>vv^++++++.<br></br>
.^+>?????v?vuu}??}xz}??????vv>>.`     ~^|.    >v|^+>|||+>>>>>>>>>w?^+++>??.<br></br>
.vzxxw}v}v??vvv??vvv????vwzzr}zv+    +-...    .?>^v}|-^>>>>>>>>>>x?>++vxuu~<br></br>
.?}vv???vv??}>+||+?r????>?vvzwz}.   ~}?`.      .|ruv~~^>>>>>>>>>>rvxwwwwwz|<br></br>
.>>}rvv>?v?~.      .~??>+++?uz}+   .?vw+.  `.   .|rw~~|?}>>>>>>>>??xxzwwwz|<br></br>
-xxuuu?>?v?~`^>+^+^`|??>?}}}}}}`   .}}}w|. -~   ```?}}^vx>>>>+>>>>>vxzzzwz~<br></br>
`xuw?>`|~~+?}}vvvvvv?++>>vrrrwr-   .v}}w}`. .~ .>..`>>^rv>>>^^>>>>>>v}vvvv-<br></br>
`xz>~-|^>+>>^|>>>>++>|vvvvxxuuuw.   ~wwwx}vv>???>>?}+|^>?>v??|>>>>>>>vvv}}-<br></br>
.???zuuuuv~r+`|++^~|??>?>>>>>?zu}.   ~?>>>?wzxx?????>|?zu>}v+|^>>>>+^?}x}>.<br></br>
.|||>rxzzxv>|-|?r>???rv^||v>^^xxx>    `}+||^>r?||^+||^wxx?^>}^|^^^^||ryr^^.<br></br>
.|^|||>rzzxwzwwxxxzv?v?|>?uz+|v>?>^.   ^}>vvzzv^^^^||>rzzx}}?~|++?v>vz?^||.<br></br>
.^+^^^^?}wzxxxuxxu}?}zrzz?vx?^||?ru}.. >uuuuzzr^^^+^^+vzzxuu}v}wxuxxuwv+^^.<br></br>
.^^^^^^^^>>>???vwwzz}}}}?+^}uzv>zuuurrruuxxzzrv?>^+^+^?}rwzxuuuw}}}zzzr>+^.<br></br>
.+++++++++++++++>>>>+++++++?xuuxxuxxuxxzzzzzzzzzv>++++>>+>>>???>+++>??>>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>?xuuxzzw}vv?vvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?}wzzw?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~^>>>>????>>>~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^??v}wwwzwrv?+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^|+vwwwwwwwwwv^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^rwwwrrrrww?|+++^++||^^~~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|?}}vvvvv}v|^+++^^||||~|||^|~~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^++^++^~^?vv??>v^|++++++^~--^>>+?>^|^^|^++^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^|^++++|^?+?>^+^^++++++|-^>v??>???+^^^^>?+^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^+^^||~~|~^^^++++^^?v+|+++++^||-`|?^`--~|^??+^+>>^^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^|+>--`-|~^^^^^++^~|^~^++++|~~~~-~`.```|~`||||^^^^^++>>>>`<br></br>
.^+^^^^^^^^^^^^+^^~|+>>+`--~|~|^^^^~~~^^^~||~~-~~~``~~||+^~~^+^^^>++++++>>`<br></br>
.^+++++++++++++^~~+?>?v??+``---~||||~-~||~~~-~|~^>^>r?|~||~|>v>^?>++++++++.<br></br>
.^+++++++++++>>^|+v??vwwww?>?|~--~~~~-`-~---~|^+|^?r?++|-|^++^^^>>>++++++^.<br></br>
.^+++++++++++?>+???vvvwwwxxuyr?~`-~~~``-````^+?v^^v?~>>^^>>>+++++>?>+>>>??`<br></br>
.^+++++^^++^??>?????v??}}?z}xuxw?~``.-^~. ..|>>+|~^^~+|^>>>>>>>>>>?>++++++`<br></br>
.+++++??>>??v?v????>?>??|.+->v}xx?    ..    .-^>^|>?>^^++>>>>>>>>??+^+++++.<br></br>
.++++?v?^+?}}?????vv?>?^```--`~?v}`    ..    .`~~^?>+|||+>>>>>>>>?}^^+++++.<br></br>
.+>>v}v???}xur???}zzwv?>>^~>>|`.`?.   .+|.    ..-??^||~|>>>>>>>>>??^++++++.<br></br>
`??>??>>vv?ruzv??}wwv????vv}v??|~.    .^|`    `~.|+?|^|+>++++>?>>?>++++>??.<br></br>
`rwzxzrvv???vvvvvv}v????vrwwwzxw?`.  .....     -..?}||+++++++>?>>?>+++vuuu~<br></br>
.?}}vv??v??v}>^^^^?v????>??vzwzr`.  .`~`.       .-+v~|>+^+++>>>>>???wwwwwz|<br></br>
.>>vv??>???-.       |??>+>?}uzr>..  ``^+.  .     .~v~|>v?^++>>>>+>>?zzwwwz|<br></br>
-xxuux?^???-`^>>+++~+??>?v}}}}}~... ``-}|. ..   ...?}}>vr+++++++>>>>wzzzwz~<br></br>
`xuw?^`^|^>?vvvvvvvv?+++>?}rrrw^... `-`?}`.  . .` .`>?>wv>>+^^++>>>>v}vvvv-<br></br>
`xz?^~|+?++++^>?>>++?+v}}}zxuuuz~.`..~~^z}v?|~|->??}>>>?v?v??+^>>>>>?vvvr}-<br></br>
.??}xzwrz?|r+`|++^~^}>>>>>>?>?}r?`.. .``+??v?vz|?????+?}w>??+^^>>>>++>?}}?.<br></br>
.|^+?}v??v??|-~>?^>+v??^^>}>^^v??^...  ~--|+?zv-|^^^|>zxv>^>>^^++++|^?v?^|.<br></br>
.|^||^>}rwrvvv}wrv??rv??v}u?^|>++^+~`  `~`vrxxr?^^^||?wzzzrw>|^+^+?>>?>^^|.<br></br>
.^^^^^^?vrzxxuuxxz}vzxzxz}vv?^^^+?}r-  `+ruuxxz>^^^^^>}zzxuxvv}vv}xzzwv+^^.<br></br>
.^^^^^^^^>????v}zzxzr}rrv?+vuzrv}zxurv+vwuxzzwr?+^^^^^?rwwzxuuuwr}rzzzr>+^.<br></br>
.++++++++++++++>>>>>+++++++?uuuuxxxxuxzxxzzzzzwv>>++++>>>>>>???>+++????>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>?zxxxzzw}v???vvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}rrr?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~>vvvvvvvvvv?~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^}wwwwwwwzzwx>^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^|vwwwwwwwwwww+^^^^^>>|^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>}rww}vvvrr?|++++++~~^^~~^^^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>?v>+++|?>~^++++++||^~+^^^|~~|^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^++++^^^^^^???++^+++++++^~+|?v????^|++++++^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^|||||^++++++?>??++^+++++^+-+??v??vv???++>+?}?^^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^+^^^~~~~~~~|++++++>?+^+++++|~|-^vv?^-+?vv?v?^^??^|^^^^^^.<br></br>
.^+^^^^^^^^^^^^^^^|>>~---~||~~||^++^^^^++++^|~---->|-`-~^+>?vv?>>+++++????`<br></br>
.^+^^^^^^^^^^+^^^^~|-^>+-~-~~~~~~|^+^|^^||~~--->>^|~^>?>>>>+?vv?+>>>>>>>>?-<br></br>
.^+++++++++++^?>~~+?+??>vv>`----~~~~~---~~~~-~^^+^|?wv>>>+>???v?>>>>+++++>.<br></br>
.^++++++++++^?v?+~v??vvvvv?~+~---~~~~-``~~~~-|+>|^vuv>+^|+>>+>>>>>v+^^^^++.<br></br>
.^+++++++++++}?????vv?vvvwzxur?~`-----~~--``|+?v|+rv++>^|>>>>>>>>>z>^+++++.<br></br>
.^++++^^+++^v}?????vv>+?vwxuuuxw?-...`+|.   `^+>|+>>^>>|+>>>>>>>>?x+++++++.<br></br>
.++++++^????rv??v?>+>|v??vvvvw}}w?           `^+|+>^>++|>>>>>>>>>vx+^+++++.<br></br>
.^++>>+^~?vrv?????}v?^?+>?????????.    ~.     .~~|^+?||^>>>>>>>>>?r^^+++++.<br></br>
.>???vv?>?zyx????zuyu????>|v???>~v.   .+^-      |r??^|~^>>>+>>>>>??^++++++.<br></br>
`zw>>++>}??zxr???v}rv???vrvr}}v?>>.   .^||`     .>??|+^^+^^^^}}>>>>++++>??.<br></br>
`zrwxw}vv???vvvvv}r??????v}}wxxxzw`   .....`.     >}~>?~|^|^^vr>>>>+++vuuu~<br></br>
.?v}vv?vv??v}+|||^>?????>>>vzrww}}-   .`. ...     .?->r^~^|^>>>>>+>>wwwwwz|<br></br>
.>>v??>+v??`        ^???>?vzuw}}}r~   `+`          ?~>zw+||^>>>+^+>>wzwwwz|<br></br>
-xxuuw>^???``^>++>>^>??>?}}vvvv}}r^    v^.        .?}}zxv~|^^^^|+>>>rzzzwz~<br></br>
`xuw?-~>^>>?vvvvvvvv>^+>>>}rrrrr}r?    +}`.       .`?zzxr?>+^^||>>>>v}vvvv-<br></br>
`xzv+~^+?+++++>?>>++?>}rr}wxuuuxzwz|   .zrv?` ..+??rvwxzxzr}v>|+>>>>vvvv}}-<br></br>
.?vxuwvvv++r+~^++^|>r++>>>>?>?>>>>>|    ^???~^}`???????vv?+++^^>>>>+>++?}v.<br></br>
.|^?r}+||^+w>-~|+|^|}^+^?}w?||^^||?v-   .~|>?u}->+^^|?zx>^+>||+>>>+^+^^^+^.<br></br>
.||||+>v}r?????vv+^>zvvzuux+||^+||vyv    .}uuux}+^^|^vzzzzzz^|++^|?+|^^^^^.<br></br>
.^^^^|+?vrxxuuxxxwrxuuuuxz?>?^?^|^>xr    vyuuxz?|^^^^vrzzuuzvv}???zzrwv+^^.<br></br>
.^^^^^^^+?vv}}rzxxxzrrwwr}??uxuw?rzuz?`>}uxxzzz?^^^^^^?rwzxxuuuzwrrzxzr?+^.<br></br>
.+++++++++++++>????>++>>+++vxxxuuxxxuxwxuxzzzzw?++++++>>>>>????>++>????>++.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>rzzzxzw}v???vvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>??vvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~+??????vvvv?~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?v}rrwwzzwwz>^^^^^^^^^^^|^|^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^|?}wwwwwwwwww?^^^+^++^++^|||^||^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>}rwwr}}}wrv+>+++++||^^~-|^|||^|^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>vv????>v?^^>+++++^~|-|~|^|~~~||^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^++++^||^>?vvv??+^+^++++^-`-|>>??>~-^^^^+++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^|^^^^+++^|+??v?>+||^^++^+-`^+?????+++|^++?v>^^^^^^^.<br></br>
.^+^^^^^^^^^^^+^^^+^^||||||||^+^|~^+>>^|||^++^||--??>-`~+>>>>+^^??^|^^^^^^.<br></br>
.^+^^^^^^^+^^^^^+^|>+~--~|^^||^^^+^|~^^++^^^^|~-~~>~``-~^++>?>>+++++>>>>??`<br></br>
.^+^^^^^^^+^^^||^|-~~^+|`--||||||^++^~||~-~||~-|+||~^+>>>>>+?>>>+>>>>>>>>?`<br></br>
.^+++++++++^^^?>~~^?>?>|>>~-~~~||||||-``--~|~~^|^||>v?>>>>++>>>>>>>>++++++.<br></br>
.^+++++++++^^>v?>^v>??>>++|~^~-~~~~~~~-`-~~~~|+>^^?r?>+^^+>>+>>>>>?>+^^+++.<br></br>
.^++++++++>++v?????vv?++>?vvv?+-`---~-~~````~^>v+>??>+|||+>>>>>>>>}>>+++++.<br></br>
.^++^^^+>+>+?v????v}r?^+>?vvvvv?^`...`+|.   .-~^+>>>^^||+>>>>>>>>?}>>>++++.<br></br>
.++++^^+??vvrv???v??v>?+>???vv?>?^   ...     .```~++>|||+>>>>>>>>?v+++++++.<br></br>
.+^+>>>++?rz}?????}}v>?+>?>vv??+^|. ...~`      ..`^^>~~|+>>>>>>>>?v^++++++.<br></br>
.+????v???zyx????rzzw????>|????+~v. . .^+-.     `?>|~-~^+>>>>>>>>??+++++++.<br></br>
`}v>>>??v??ww}???}}}v????}v}vvv?>>.   .^||`      |>?~+^|^^+^+??>>>>++++>>>.<br></br>
`wrwzw}vv???vv???????????v}}r}wzrv`    ...`.      ?}|>?-|^^++?v>>>>+++?vvv-<br></br>
.?}}v??vv??vv^~---|+>??????vwvvv??-   ... ..      `v|>r|-|^>>>>>>+>>vv????-<br></br>
.+>???>>v??|.    ...^??????rwr??vr| . `^.         .v|>zw^~^>?>>++>>?rv????-<br></br>
-xxuuw>+???^~^>>>??>???>????vvv}wr+ ...v|.        .?r}zxv||^+>>++>>>vv????`<br></br>
`xuz?-~>^+>??vvvvv??>^+>>?}rrrrwrr?... >}`.       -->zzxwv>^^^^^>>>>??????`<br></br>
`xzv^~+>?+++>+>?>>++?+}v}rwxuuuxzwz^...`zr?+.  .>?vrvwxxxz}?>+^+>>>>?vvv}}-<br></br>
.?}zx}vvv++}>|+++^|+v^>>??>?>??>>>>^ .. ^?+~.~v|}v?????v}?+^+^+>>>>^++>?vv`<br></br>
.|^>??^||^>w?|+++|^^v+>>?vv>||^^^+?v-   .~-~?uw>?+^^^>}}?>??+>>?>>>^++>>>+.<br></br>
.||||+>}}wv?vvvrv>>?z}}zw}v^||^++^?w? .  .vxuxxr>^|^^?}wzwzz>+>>>>??+>>>>^.<br></br>
.^^^^^>v}wxxuuxxxzwxuuuuxw?+>+?>+^+zw....vyuuxxv++++>?vwzxxwv}rv?vxxwwv>>+.<br></br>
.^^^^^^^>?vv}}wzxxxxwwwwwr>?r}xwvwzux?-?ruxxzzzv>>>>+^?}wzzxxzzw}}rwzzw?>>`<br></br>
.++++++++++>>>?vv???>>>>>>+?v}zxxxxxuuzxuxzzzzw?>>>+++>+>>>>???>++>>??>>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>?v}zzzwrvvvv}}vvvv????>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vv}?>???????>>>>?????>>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|^^^^|~~~~||||~~~~~~~~|~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?}rwwwwr?+^++++++^++>^?+v?~^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?wwwwwwwwwww>^++++++++|+??v?+>^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>wwwwwwwzzwwzw|+++++++^~|v??vvv+~^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?wwwwwwwwwwwwr^+++++++|~-+||>???>^+>>^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+rwwwwwwwrwwr?^+++^+^|-|~|>||^>v?^^^>+|^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^?}rww}??>+??^+++^~~-|>??^^|+>>^?v?|^^~|^^^^.<br></br>
.^+^^^^^^^^+^^++^^+^^|^^^^+^+++^>???^>^^|++^+++|~|??~.~--^>>>|>v?>++++++++.<br></br>
.^+^^^^^^^?v?>+^^^|+|-^^+++++++++^^||??vv^>++++~|+v|`-|^>>>>>+^>>^+>>>>>>?.<br></br>
.^+^^^^^^^vv????^~-|+>^++++++++++++^>+>?>^^+++~-^^^~^^+>>>>>>>>>>>>>>>+++>`<br></br>
.^+++++++^}v??????|>}?+++^||||^^+^^++++>?^^+^~-~>>||^>>>>+>>>>>>>>>v?^^^^+.<br></br>
.^++++++++w????????+?>^|~~~~~~~~||~|^^||~-||~|^?vv|^>>>+^^>>>>>>>>>wr^++++.<br></br>
.^++++++^>zvv???v??vv?--~||||~~~~~~--~+^|``-``^xur|?>+^||^>>>>>>>>>vu?^+++.<br></br>
.^++^>vv?v}}??????wuux>||----------`|???|.     |r?|v?+^^|+>>>>>>>>>>zr++++.<br></br>
.???????v}zur????wuxyu+``-`^>^-`-. .?v?>>        `|}?+++|+>>>>>>>>>>>??+++.<br></br>
.+^+>v}vv}yuxv???}wzzr>^??^vvv?--`->vvvv^.        |>?+>^|>>>>>>>>>>>>>>>++.<br></br>
.^+????}??}uw??????????^>???vvv>?vv?v?|^^+~      .||^^>^|>>>>>>>>>>>>>>>>>`<br></br>
.^+>>>vv??????vvvrw}???>?v?v???v?vv^`  ~||~.     `?>~^>^|>>>>>>>>>>>>>>>>>`<br></br>
.vww}}v}????rw>~-``|>???vvvv?~?v?^`    ...`      -}}|>?-|+>>>>>>>>>>>>>>>>`<br></br>
.v}v??>v????^`      `???vvvvv+|>+++.   .  .      ?uw?>r||^>v>>>>>>>?>^^^+>`<br></br>
.?>>>>>?v???- -|~~++~??????>>>^|vr}v??+-.        ~}z+>zr^^+vr?>>>>?xv||^^^.<br></br>
`xzxxw>>v???+^?vv}}vv?>+++^++?vrur}}}r}r|.       .`vzrzxv^|+vzw?>>>??||^^|.<br></br>
`xuw?-~?^|?>>???????>^>??}}}}rwzz}}}}}}w}`.   .. -v+>xxxuw>|^>??>>>>++++^^.<br></br>
`uz>~-???>>>?++++++>+-r?+xxuuuxxzzwwwwwwx}>    `?uurvzuuxxv|^^+++>?>>vvvvv-<br></br>
`rwz}>?vv?~?}>>^>+>?|-vvz}>??>??>>??>?>>>>|  `?zuw?????rzz?^^???+^+^^+?v?>`<br></br>
.|^++|||>>vwxzxv+?vr?rzuv|+|||+^>}rv|++^^^..?xyuz>|^+|^+}xxrwuuuwv???}zxzw-<br></br>
.|||^+?rzxuxxuuuzxxxxxxx>||||^>>xv||^>r+++?wuuxzv|||?^?wzzzzxxxuuuuuuuxxz?.<br></br>
.^^^^?}wxxxzzzxxuxxuuuuuxv>||>uux?^vxux}wzuuuxzzwv?r}^?zzzzzwzxxuuuuuxzzw}`<br></br>
.^^^^^^+v}rwzzzxxuuxxxxzxr+^^>zuxzzuuuuuuuuuxxzzzzxr+^^?}rrrv????vv}}rwwzz~<br></br>
.++++++++++?vvv}}}}}v?????++++}zzzuuuuuuuuxzzwwwzzv++++++>>>>>+++++++>>>?v-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?wwwzxuuuuxxzwwwzzzwvvv?>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>vwwwwzzxxzwwwwwwwwwww?>>>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|^^^^|~~||||||~~|~~~~~|~~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+?vrwwwwrv+^++>++^^+^^^>^?>|^^|^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>}rwwwwwwwww>^++>+++++~~>>?>^+^|||^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>r}}wwwwzwwwww|++>++++^|-?>>??>|~|||||^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>r}}rwwwwwwwwr++++++++|~`^||+>>>+^+++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^|^^^^^++^^^^^v}}rrwwwrrwrv+++^^++^~~-~+||^+?>^^^>+||^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^|^^^^++^^^+^>?v}r}??>+??|^++^~|~|+^>~~~^>+|>?>|+^||^^^^.<br></br>
.^+^^^^^^^^+^^^^^^^^||^^^++^+++^^+>>|+^^|++^^++|~|?>`.~-~^+>+|+??+++++++++.<br></br>
.^+^^^^^^^????+^^^|+|-|^+++++++++^^~->?v?+>^^++~|>?-`~|^++>>>++++^+>>>>>>?.<br></br>
.^+^^^^^^^??????+~-|+>^+^^+++++++++^^|>?>+|^^+|~^^|-~^++>>>>>>>>>>>>>>++++.<br></br>
.^++++++^+v???????|>}v++||^||||^+^|^^^|+?+|+^|~~||||^>>>++>>>>>>>>>v>^^^^^.<br></br>
.^+++++++>r????????>?>|~`-^^|~~~||~|^^||--~~~~^>??^+>>>+++>>>>>>>>>r}^++++.<br></br>
.^+^^+++^?zv?vv?vv?vv?---~^||~-~----~~^~~``-``^rwv|>>+^^^+>>>>>>>>>?z>++++.<br></br>
.^++|>?v?vr}??v???zuuu?|~--```~``..`->+^-.     ~}?|?>+|^^+>>>>>>>>>>r}++++.<br></br>
.???????}wxur????wuxyu>``~|++|`... .^>+^|        `~v?+|^^>>>>>>>>>>>>?>+++.<br></br>
.+^+?v}vvryux}???vrzzr?+??+???>``.`|>???|.        -^?+^^^>>>>>>>>>>>>>>>++.<br></br>
.^^>???v??}uw??????????+??+^>>>|+>???>|^^+~      .``-~+^|>>>>>>>>>>>>>>>>>`<br></br>
.^+>>>vv?????vvvvrw}???>?>>>+++>+>?+`  |||~.     .||`-|||+>>>>>>>>>>>>>>>>`<br></br>
.vrwr}vv????}w>|---^>????>>>^`+?+-.    ...`      `??`|^`~+>>>>>>>>>>>>>>>>`<br></br>
.v}v???v????+-      `???vv???|-^~|+.  ..  .      +vv^~?-~^>}>>>>>>>??++++>`<br></br>
.>>>>>>?v???~ `|~~++~??????>+>~~?v}v??>-.        `?v~|vv^|+vw?>>>>?xv^^^^^.<br></br>
`zzzzr>?v???>^?vv}}}v?>++++++??vw}}}}r}r|.        .?v?vzv^|+vzr?>>>??^|^^|.<br></br>
`uuz?-|?^|?>>???????>^+??v}}}r}ww}}}}}}w}`.   .. `?||rzxuw>|^>??>>>>++++^^.<br></br>
`uz>~-???>>>?++++++>+-}??wzuuuuxzzwwwwwwx}>    .>}}??wuuxxv|^^+++>>>>vvvvv-<br></br>
`rww}??vv?^vv>>+>+>?|`v}z}>??>??????>???>>|  `+vr?^+???}wr?|^>??+^+^^+>v?>`<br></br>
.|^^^^^+??vzzwwv+>vv?}zxv+>+^|++>v}v|+>^^^`.+rwv?^|^>^^+vwz}vrwrv????vwwr}-<br></br>
.|^^+>vwzxuxxuxxrrwzzxxx?^++^^??z?||^+}++++vr}vvv+^+?^?}rwzw}}rwwwwxuuxzw?.<br></br>
.^^^^?rzxxxxzxxxxxxuuuuuxv?+^>xux?+vzzwv}v}}rrwzz}?r}+?zzzzwrrwwzzxuuxzzzr`<br></br>
.^^^^^^+v}rwzzzxxuuxxxxzxw>++>rxxzzuuuxxzrwwzxxzzzxw>++?rwrrv???vvv}rrwwzz~<br></br>
.+++++++++>?v}}}}rr}v?????>>>+vwzxuuuuuuxxxzzzzzzzv>>>>>>>>>>>>>++++>>>>?v-<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?rwwzxuuuuuxzzzzzzzwv}}?>>>>>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>vwwwwzzxxzzwwwwwwwwwwv??>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~|||||||~|>vvvvvvvvv+~~~|||||||||~--||-~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^++++++^||+rwwwwwwwwwww?||++++++++++^^^?^??>^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^+++++++^^^}zwzzzzzwwwwwz?++++++++++^||->>vvv>|+^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^|^++++++++++++}wwwwwwwwwwwww?++++++++++^~|~>v>>??v?^^^.<br></br>
.^^^^^^^^^^^^^^^^^~+~++++++++++++^?wwwwwwwwwwww}^++++++++++^~~->>~|?vv?v?|.<br></br>
.^^^^^^^^^^^^^^|+v^+~+++++++^^+++++v}wwvv}vvr}v?^++++^^+++^|~--~^^|+>?vv?^.<br></br>
.^^^^^^^^^>>^^++>v?~~+^++++^|~^+++^^>??^>>+^??+^++++|~~~~~~~--||^^>^^^>vvv`<br></br>
.^+^^^^^^+v???vv>?v?||~|^++|~~~^+++^^^|^^^+>^^^++++^~~~~~-`~-|^+^~+^^^^^?v-<br></br>
.^+^^^^^^?v???vv????+`---~~~~~~~^++++^++?vv>^^++++^|~---|--+?++++^+^^^^^>v-<br></br>
.^+^^^^+^vv????v?^-~+>?>~``-~~~~-|^++++^+++||^++^|~-``-~^|+>v++?}+>^^^+^??`<br></br>
.^++++++^}v???vvv?>+vwrv>+v+-~^^|--|^^^^^>^^^^^~--~+>>^^++>>>?>+?+>^^^+^^+`<br></br>
.^+++++^+zv?vvvv?v???v??+?ux}+?^||^|~-~~~`--~~-~~|?vv?>>>>>>>???v?^++^^>}v.<br></br>
.+^+?????w}v>vvv??vrwrv??vwrv?>~. `~|~--```-~~|^^+>++^+>>>>>>>>>>vv++>}w}?.<br></br>
.^+^^>?vrrw}?vv???xyyyyv???>>??`     ....|~..``.|+^|||^>>>>>>>>>>>>?>>?>++.<br></br>
.+>>++>vrxyy}vv??ryxyyz????>~-           ..      .`~+||+>>>>>>>>>>>>?+^+++.<br></br>
`vvvvv}??uyyrvvv?v}rzr?????-         `~^~.         .^~|^+>>>>>>>>>>>>>>>>>`<br></br>
.|^++?}??vzw?v???????????^.       .^`|++++~.         .^+|+>>>>>>>>>>>>>>>>`<br></br>
.^^>v}}???????vvv}rv????^         `w}-~^~|||-.         --^+>>>>>>+^^^^^^+>`<br></br>
.?}}vv}??????r}?>>>>????+         .?x?-....--~`         .|>?>>>>>^||^++|^^.<br></br>
`}v??>}??????>`     +????>-.     .?}}}?`. ...-`         -|+zv>>>>>?wwwv|^^.<br></br>
.>+++^?v?????..|||+^^??????+~^...|r}v}w+`    ..        ~?~^vur?>>>>rxw?^^|.<br></br>
`vvvv?|v?????+?vvv}rv??>?>+++?`..?}}}}}r|.      .     `wz?||?}v>>>>>zv|^||.<br></br>
-uuz?~>?+>????vvvvv?>>^+??vr}v^|?r}}}}}w}`.          .}xxu?|^++++>>>vv^^^^.<br></br>
`uz>~|?>?>++^>+>>>>>+^~>z>?xuuuxxzzzwwzzu}vv|       .}uxxz}^^^^^^^^^?vvv??-<br></br>
.????+?wxv?}~>>++++++~?vu>?w?>?vuuuv>}uxz??>.      .>w?>??>^+|^^^^^|^+++>v~<br></br>
.|^||?xxxxur+^+>++++|+zuuxux?^?zx?>+|?xv^^|.     `>wz>||+^}?^|^^^^|>??v^^+`<br></br>
.^?rrzxxxxuuxzv>++>?rxxxxxxz?^wur||||^}+|^.    -vxuxv|^^>?z?||^^||^rxxzv}v~<br></br>
.^>vv}rzzzzuuuuzwrzuuuuuuxxwv?}xz>?v?+>^^-   ~}uuxzwv>?rwxzwvv?>?}wxxxxxxx|<br></br>
.^^^++>vwzzzxxuuuuuuxxxxxxxuu?+rzvruuxwrv`|^}uuuuxzzxrzxxzzzzzzxxxxxxxxxzx|<br></br>
.+++>>++???vrwwwzzzzzwrrr}}v?>+?wzxuuuuurwxuuuxzxzzzzxxxxzwrwwwv????????v?`<br></br>
.>>>>>>>>>>>>>>>>????>>>>>>>>>>>wwzxuuuuuuxuxzzwzwzzzxuuxv>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}wwwwzxxxxxzwwwwwwwzzxxw?>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~|||||||~|>vvvvvvvvv+~~~|^^||||||~--||-~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^++++++^||+}wwwwwwwwwwwv||++++++++++^^^?^??>^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^+++++++^^^}zwzzzzzwwwwwz?++++++++++^||->+vvv>|+^^^.<br></br>
.^^^^^^^^^^^^^^^^^^^|^++++++++++++}wrrrwwwwwwwww?++++>+++++^~|~>v>>??v?^^^.<br></br>
.^^^^^^^^^^^^^^^^^~+~++++++++++++^?wwrrwwwwwwww}+++++++++++^~~->>~|?vv?v?|.<br></br>
.^^^^^^^^^^^^^^|+v^+~+++++++^^+++++v}rrvvvvvr}v?+++++^^+++^|~--~^^|+>?vv?^.<br></br>
.^^^^^^^^^>>^^++>v?~~+^++++^|~^+++^^>??^>>+^??+^++++|~~~~~~~--||^^>^^^>vvv`<br></br>
.^+^^^^^^+v???vv>?v?||~~^++||~~^++++^^|^^^+>+^^++++^~~~~~-`~-|^+^~+^^^^^?v-<br></br>
.^+^^^^^^?v???vv????+`---~~||~~~^++++^++?vv>^^++++^|~---|--+?++++^+^^^^^>v-<br></br>
.^+^^^^+^vv????v?^-~+>?+~``-~~~~-|^++++++++||^++^|~-``-~^|+>v++?}+>^^^+^??`<br></br>
.^++++++^}v???vvv?>+vr}?>^?|`-|^~--~|^^^^>^^^^^~--~+>+^^^+>>>?>+?+>^^^+^^+`<br></br>
.^^++++^>zv?vvvv?v??????^?xwv^?^~~|~--~~~`--~~-~~|>v??>>>>>>>???v?^++^^>}v.<br></br>
.+^+?????w}v>vvv??v}r}v???}}v?>-. .-~~--```-~~||^+>++^+>>>>>>>>>>vv++?}w}?.<br></br>
.^+^^>?vrrw}?vv???xuxxxv???>+>>.     ....|~..``.|++^||^>>>>>>>>>>>>?>>?>++.<br></br>
.+>>++>vrxyy}vv??ryxuuw????>~-           ..      .-~+||+>>>>>>>>>>>>?+^+++.<br></br>
`vvvvvv??uyyrvvv?v}rz}????>`         `~^~.         .^~|^+>>>>>>>>>>>>>>>>>`<br></br>
.|^++?}??vzw?v???????????|.       .^-|++++|.         .^+|+>>>>>>>>>>>>>>>>`<br></br>
.^^>v}}???????vvv}rv????|         `w}-~^~|||-.         --^+>>>>>>+^^^^^^+>`<br></br>
.?}}vv}??????r}?>>>>????+         .?x?-....--~`         .|>?>>>>>^|^^++|^^.<br></br>
`}v???}??????>`     +????>`.     .?}}}?`. ...-`         `~+zv>>>>>vwwwv|^^.<br></br>
.>++++?v?????..|||+^^?????>^~+...|r}v}w+`    ..        -?-|?ur?>>>>rxw?^^|.<br></br>
`vvvv?|v?????+?vvv}rv??>?++++?`.`?}}}}}r|.      .     .}r?~|?}v>>>>>zv|^||.<br></br>
-uuz?~>?+>????v}vvv?>>^+??v}v?||?r}}}}}w}-.          .vwww?|^++++>>>vv^+^^.<br></br>
`uz>~|?>?>++^++>>>>>+^~>z>?zxxxxxzzzwwzzu}vv^       .vzwwrv^|^^^^^+^?vvv??-<br></br>
.????>?wxv?}~>>++++++~?vu>?w?>>vuuxv>}xww??>.       +}?+??>^+|^^^^^^^+++>v~<br></br>
.|^^|?xxxxur+^+>++++|+zxxzxz?^?zx?>+|?xv^^|.     `>}r+||>^}?^|^^^^^>>?v^^+`<br></br>
.+vwwzxxxxuuxwv>++>?}zzzzxxz?^wur||||^}+|^.    -?wzr?|^^>vx?||^+^^^rxxzv}v~<br></br>
.^?vv}wzzzzuuuuzzrzuuuuuuxxwv?}xz>?v?+>^^-   ~vzxwrrv>?wwxzwvv?>?}wxxxxxxx|<br></br>
.^^^++>vwzzzxxuuuuuuxxxxxxxuu?+rzvwuuxw}?.||vxxxxzzzxrzuxzzzzzzzxxzzzzxxzx|<br></br>
.+++>>++???vrwwwzzzzzwrrrr}v?>+?wzxuuuuu}}zuuxxxxzzzzxxxxzwrwwwv????????v?`<br></br>
.>>>>>>>>>>>>>>>>?????>>>>>>>>>>wwzxuuuuuxxuxzzzzzwzzxxxxv>>>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>}wwwwzxxxxxzwwwwwwwzzxxw?>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~|~>??>+`-|||||||||||||v}vvvvvvvvvv>~~~||||||||~|~~~~~~~~.<br></br>
                  .^^^^^^^^^^^^^^^^^|^vvvvv~^++++++++++++vxzzzzzzwwwwwz?||^++++++++++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^>>?v?^+~~^++++^^++++^}wwwwzzzwwzwwwr++^++++++++++^-|^^^^.<br></br>
.^^^^^^^^^^^^^^^^|>vvv?|^|-~^+^|~~^+++^vwwwwwwwwwwwww}++++++++++++++-~>^^^.<br></br>
.^^^^^^^^^^^^^^^|?v?v?^^^^|--~~~~~|+++^>vrrrwwwwwwww}>++++++++++++^^|~?>+|.<br></br>
.^^^^^^^^^?+^^^^^?vv>^^^^^^^|~-~~~~^+++^>?>>???vr}}v>^+++++++++++^~|~+?v?^`<br></br>
.^^^^^^^^????+^^?vv>^+^^^^^^^^^|||~~++++^+|^>>+>??+^^++++^^++++++|~-|????v-<br></br>
.^+^^^^^^v?????>vv?|^^^^++^^^^^^^^~-|++++>+>+>?+++^+++++|~~^++^|~~--+^|?vv`<br></br>
.^+^^^^^>v?????vvv?+^->vv+|+++++++^~~^++^+>???^|++++++^|~~~~~~~~~`^^^^^+?v-<br></br>
.^+^^^+^?}?????v??>~``|~`....``-~~|~|~|^+^>>^||^++++^|~------~|^^^>^^^^^^^.<br></br>
.^+++++^}v??v??????|                .|~-~~~|||^^||~~---`||-|^^^++^>^^^++++.<br></br>
.^>>>+++w}v}vv???v?`                 .`--````-~----~|^+>~|+??+>>++++++++++.<br></br>
.^+?vvvvrvv??vv?v??.                    `^|`-~~~||^++++>+>>>??v???>+++++++.<br></br>
.^^|+?vwrwxzv?????x^         ..``        ~- .````>v?>^+>>>>>>>>>>??>>+++++.<br></br>
.>????vvyyyyuv???vyz`        >}xw>`-||~`.        .+^||^>>>>>>>>>>>>>>>>>>>`<br></br>
`zzwvr??}uyyux????vw}`       |}}}}?>++++^-.       .`^~|^>>>>>>>>>+>>>>>>>>`<br></br>
.^^+?r???vwwvv??>?????~       ->????>|^+++^-.        .^^^>>>>>>>^||^^^^^>>`<br></br>
.^?}}}???????????vv????^       .~^~?v-~^~|~^|`         `~^?>>>>>|||||||^^>`<br></br>
.v}vvr????????}vv???????|       .|-~wv-....--^+.         `}v>>>>+|+>++^|^^.<br></br>
`v???}????????^    `????>        ?rv}}?`. ...->~          .v}>>>>+wxzr^|^^.<br></br>
.>>++?v??????|.~-~^|????+.` ....`vv}vvw+.    ....           vw?>>>vxx?||^^.<br></br>
.++++^???????+>?vv}v????+>>.``.`v}}}}}rw>`      .          .^>>++>>zz+||^^.<br></br>
`wwv^`>>>?????}}}}v??>>>>v-```.>xxz}}wxxu?.               .|+^^^^^+}}?>++>.<br></br>
`uz?-+?+>^++^>>>>>>>++||v^.``.-xuxxzwxzwxwvv??>.         +^|^^^^^^+vvvvvvv-<br></br>
.???vxxxx+?>~?+++++++|>r?`....vuuv>??>>>>>???+-         ~?^||^^+^^|+++++++.<br></br>
.|>vxxuuuzzr}}?+++++>->z?.-^+|ruu?|||^|^||||`         .-?v|^^||^|^?||++|||.<br></br>
.}xxzzzxuuxxzr++>???|~|+?vzu?^?xxv||||||^>|.         `??wxzrv????rxvvzwv?}~<br></br>
`wzwwwzzzxuuxzrrxuuxwwzxuuuw>?vrww?|^|||^|.       `+vzxxzzzzzwwwzwwzzzxxxx|<br></br>
.>???>?wzxxxuuuuuuuuuuuuxzxxuz>?wzzrzv^^-     .~?ruyuxzzzxxxz}?>>>++?vvvvr|<br></br>
.++>v++?vwzzzxxuxxxzzzxzzzzxxr?^vwzuuuz>..~}??wuuuxxxzw}vrwzzzwrrr?>>+>v}v~<br></br>
.>>>>>>>>vvv?v}}}r}}}}vvv???>?>>?wwzxuuu}>}uuuuxzzzwwwv?>???>?>??????>?>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>+vwwwwzxxxzxxxxxzwwwwwwrwwwv>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~|~>??>+`~|||||||||||||v}vvvvvvvvvv>~~~||||||||~|~~~~~~~~.<br></br>
                  .^^^^^^^^^^^^^^^^^|^vvvvv~^++++++++++++vxzzzzzzwwwwwz?||^++++++++++^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^^^>>?v?^+~~^++++^^++++^}wwwwzzzwwzwwwr++^++++++++++^-|^^^^.<br></br>
.^^^^^^^^^^^^^^^^|>vvv?~^|-~^+^|~~^+++^vwwwwwwwwwwwww}++++++++++++++-~>^^^.<br></br>
.^^^^^^^^^^^^^^^|?v?v?^^^^|--~~~~~|+++^>vrrrwwwwwwww}>++++++++++++^^|~?>+|.<br></br>
.^^^^^^^^^?+^^^^^?vv>^^^^^^^|~-~~-~^+++^>?>>???vr}}v>^+++++++++++^~|~+?v?^`<br></br>
.^^^^^^^^????+^^?vv>^+^^^^^^^^^|||~~++++^+|^>>+>??+^^++++^^++++++|~-|????v-<br></br>
.^+^^^^+^v?????>vv?|^^^^++^^^^^^^^~-|++++>+>+>?+++^+++++|~~^++^|~~--+^|?vv`<br></br>
.^+^^^^^>v?????vvv?+^-+vv+|+++++++^~~|++^+>???^|++++++^|~~~~~~~~~`^^^^^+?v-<br></br>
.^+^^^+^?}?????v??>~``|~`....``-~~|~|~~^+^>>^||^++++^|~------~|^^^>^^^^^^^.<br></br>
.^+++++^}v??v??????|                .|~-~~~|||^^||~~---`||-|^^^++^>^^^++++.<br></br>
.^>>>++^w}v}vv???v?`                 .`--````-~----~|^+>~|+??+>>++++++++++.<br></br>
.^+?vvvvrvv??vv?v??.                    `^|`-~~~||^++++>+>>>??v???>+++++++.<br></br>
.^^|+?vwrwxzv?????x^         ..``        ~- .````>v?>^+>>>>>>>>>>?>>>^++++.<br></br>
.>????vvyyuyuv???vyz`        >}xw>`-||~`.        .+^||^>>>>>>>>>>>>>>>>>>>`<br></br>
`zzwvr??}uyyux????vw}`       |}}}}?>++++^-.       .`^~|^>>>>>>>>>+>>>>>>>>`<br></br>
.^^^?r???vwwvv??>?????~       ->????>|^+++^-.        .^^^>>>>>>>^||^^^^^>>`<br></br>
.^?}}}???????????vv????^       .~^~?v-~^~|~^|`         `-^?>>>>>|||||||^^>`<br></br>
.v}vvr????????}vv???????|       .|-~wv-....--^>.         .}v>>>>+|+>++^^^^.<br></br>
`v???}????????^    `????>        ?rv}}?`. ...->~          .v}>>>>>wxzr^||^.<br></br>
.>>++?v??????| ~-~^|????+.` ....`vv}vvw+.    ....           vw?>>>vxx?||^^.<br></br>
.++++^???????+>?vv}v????^>>.``.`v}}}}}rz>`      .          .^>>++>>zz+||^^.<br></br>
`wwv^`>>>?????}}}}v??>>>>v-```.>xxz}}wxxu?.               .|+^^^^^+}}?>++>.<br></br>
`uz?-+?+>^++^>>>>>>>++||v^.``.-xuxxzwxzwxwvv??>.         +^^^^^^^^+vvvvvvv-<br></br>
.???vxxxx+?>~?+++++++|>}?`....vuuv>??>>>>>???+-         ~?^||^^+^^|+++++++.<br></br>
.|>vxxuuuzzr}}?+++++>->zv.-^+|ruu?|||^|^||||`         .-?v|^^||^|^?||++|||.<br></br>
.}xxzzzxxuxxz}++>???|~|+?vzu?^?xxv||||||^>|.         `??wxzrv????rxvvzwv?}~<br></br>
`wzwwwzzzxuuxzrrxuuxwwzxuuuw>?vrww?|^|||^|.       `+vzxxzzzzzwwwzwwzzzxxxx|<br></br>
.>?????wzxxxuuuuuuuuuuuuxzxxuz>?wzzrzv^^-     .~?ruyuxzzzxxxz}?>>>++?vvvvr~<br></br>
.++>v++?vwzzzxxuxxxzzzxzzzzxxr?^vwzuuuz>..~r??wuuuxxxzw}vrwzzzwrrr??>+>v}v~<br></br>
.>>>>>>>>vvv?v}}}r}}}}vvv???>?>>?wwzxuuuv>}uuyuxzzzwwwv>>???>?>??????>?>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+vwwwwzxxxzxxxxxzwwwwwwrwwwv>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~^^???+---||||||||||^v}vvvvvvvv}>~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^+?v??>+^^^~~~~~|++++>wxzzzwwwwwwzx}^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^+vvv+^^^^^^|----|+++^?wwwwzxxwwwwwwx?||^++++^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^???^^^^^^^^|~-~|+++^?wwwwwwwwwzwwwzv^|^+++++++^^.<br></br>
.^^^^^^^^>?^^^^^^^^^^^^^^^^^^^^^++^^^^^|~~+++^?}wwwwwwwwwwwww>+++++++++++^.<br></br>
.^^^^^^^^???>^^^^^^^^^^^^^+^~`..`-|^+^^+~~++++|??>vrrwwwwwww?+++++++++++++.<br></br>
.^^^^^^^+v????>^^^^^^^^^^^-.       .-^++~-^+++^+|^?>>v}r}}v>^+++++++++++++.<br></br>
.^+^^^^^?v??????+|^^^^^^+`            `^^-~+++>+^^+?++??++^++++++++++++++^.<br></br>
.^+^^^^^vv???????>|^>|>??`             .~|-|++^>????^^?+^++++++++^^+++++|~.<br></br>
.^++++^+w??????????>^?v??~               -|-|^^>?^^|^+++++++++^|~~~^||~~~-.<br></br>
.^++^^^?wvvv??????v?????>v>               ~~-~~~^^|^++++++^^|~~~~~~~----`-.<br></br>
.????v?v}v??????vv??rwrv???`              .``-```~~~||||~~----|--~~~~~~|^^.<br></br>
.+???v}vvvv??vv?v??zyyyuv??|      .|-       `++-~~~~~-----~|^+>>>>+^+^^+++.<br></br>
.^|^?v?xuuuxv?>???wyyuyx????.      `+^-.    .``..~~||||~~^+>>???v?>>++++>?`<br></br>
.?vvv??wyyyyur????uyuuxv????        `^+^`        .`~|~^+>>>>>>>>>>>>>+>>?v~<br></br>
`ww}v???uyyuuwv???vr}v?????^-      `+^++^~.        `++^^>>>>>>>+^|^^^^^>>>`<br></br>
.??}v????v}v????+???????????^..... |v??+++^~.       .-||+>>>>>>^|^||||^+>>`<br></br>
.?}rv?????????????????????v~````.~^++^|~~||^^-.       .-|v?>>>>^|^^^||^^+>`<br></br>
`v?vv??????????v}r????????-```..~??>r?-....--^?`        .>x?>>>>^+>>+^||^+.<br></br>
.???}??????????^...>??????`...`+v}}}}}?^|....`+|.         ?z}?>>+vxxzv||^^.<br></br>
.+++?v????????~``~-+??????-``|+>>v}}}}zxu?   ...`.         `>?>>>vuxx}||||.<br></br>
.^+++?????????>???v???????++>?>>wzr}}rrr>?.     ...         .|++^?xzzw}vvv-<br></br>
.?>+|^>>??????vrr}v???>+}u>+?rrxuuxr}}}w}`~.      .          .^^^>r}}vvvvv-<br></br>
`xw>->>+>++>>>???>>>>>^?zu+^^}uuuxxwwwwwx}rv?????.           .|^^>vvvvvvvv-<br></br>
.?}xzr|>??v~+>+++++++^^}xr^^~^vxuz>>>?>>>>>?>>>>^.         .-|^++^++++++++.<br></br>
.}xxuu}^+wxv^?+++++++->ux?~?w?>zxu?||||||^+|^~`          ^^|^>rzw+^^^+>^^^.<br></br>
-zzzzzxwzuz^~+>>??vrv^-^^-|ru^|rzxx+|^|||^^|`          .~zw^||vux?^|||^>?>`<br></br>
`wwzzzzxxxzwv}zxuuuuuv~+?vzuw>>vzzuw+|^^^|`.         .-|+rur??wuuxwr}}wzxx|<br></br>
.vwv??vwxuuuuuuuuuuuuzzuuxxuzz?>rzxuz+^^~.         .|>?vzzzxxxw}}vvv}rwwzz~<br></br>
.+>++++>wxxzxuuuuuxxxxxxzzzuuz>+?rzxuz?`   .   .`^vwzxxxzzuw??>>>++++++>>>`<br></br>
.>?}?>>?rwwwwzzxzzwwwwwwrr}v??>>vwwzxuu?|>}v|?}zuyyuuxzzr?vvvv}rr}>>>>>>>>`<br></br>
.>>?>>>????>????????????>>>>>>>>?wwwwzxuxuuxxuuxxxxxxzwwv>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~^^???+---||||||||||^v}vvvvvvvv}>~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^+?v??>^^^^-~~~~|++++>wxzzzwwwwwwzx}^^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^+vvv+^^^^^^|----|+++^?wwwwzxxwwwwwwx?||^+++++^^^^.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^???^^^^^^^^|~--|+++^vwwwwwwwwwzwwwzv^|^+++++++^^.<br></br>
.^^^^^^^^>?^^^^^^^^^^^^^^^^^^^^^^+^^^^^|~|+++^?}wwwwwwwwwwwww>+++++++++++^.<br></br>
.^^^^^^^^???>^^^^^^^^^^^^^+^~`..`-|^+^^+~~++++|??>vrrwwwwwww?+++++++++++++.<br></br>
.^^^^^^^+v????>^^+^^^^^^^^-.       .-|++|-^+++^+|^?>>v}r}}v>^+++++++++++++.<br></br>
.^+^^^^^?v??????+|^^^^^^+`            `^^-~+++>+^^+?++??++^++++++++++++++^.<br></br>
.^+^^^^^}v???????>|^+|>??`             .~|-|++^>????^^>+^++++++++^^+++++|~.<br></br>
.^++++^+w??????????>^?v??~               -|-|^^>?^^|^+++++++++^|~~~^||~~~-.<br></br>
.^++^^^?wvvv??????v?????>v>               ~~-~~~^^|^+++++++^|~~~~~~~----`-.<br></br>
.????v?v}v???????v??rwrv???`              .``-``-~~~||||~~----|--~~~~~~|^^.<br></br>
.+???v}vvvv??vv?v??zyyyuv??|      .|-       `++-~~~~~-----~|^+>>>>+^^^^+++.<br></br>
.^|^?v?xuuuxv?>???wyyyyx????.      `+^-.    .``..~~||||~~^+>>???v?>>++++>?`<br></br>
.?vvv??wyyyyyr????uyuuxv????        `^+^`        .`~|~^+>>>>>>>>>>>>>+>>?v~<br></br>
`ww}v???uyyuuwv???vr}v?????^-      `+^++^~.        `++^^>>>>>>>+^|^^^^^>>>`<br></br>
.??}v????v}v????+???????????^..... |???+++^~.       .-||+>>>>>>^|^||||^+>>`<br></br>
.?}rv?????????????????????v~````.~^++^|~~|~^^-.       .-|v?>>>>^|^^^||^|+>`<br></br>
`v?vv??????????vrr????????-```..~??>r?-....--^?`        .>x?>>>>^+>>+^||^+.<br></br>
.???}??????????^...>??????`...`+v}}}}}?^|....`+|.         ?z}?>>+vxxzv||^^.<br></br>
.>++?v????????~``~-+??????-``|+>>v}}}}zxu?   ...`.         `>?>>>vuxx}||||.<br></br>
.^+++?????????>???v???????++>?>>wzr}}rrr>?.     ...         .|++^?xzzw}vvv-<br></br>
.?>+|^>>??????vrr}v???>+}y>+?rrxuxxr}}}wv`~.      .          .^^^>r}}vvvvv-<br></br>
`xw>->>+>++>>>???>>>>>^?zu>^^ruuuxxwwwwwx}rv?????.           .|^^>vvvvvvvv-<br></br>
.?}xzr|>??v~+>+++++++^^}xr^^~^vxuz>>>?>>>>>?>>>>^.         .-|^++^++++++++.<br></br>
.}xxuu}^+wxv^?+++++++->ux?~?w?>zxu?||||||^+|^~`          ^^|^>rzw+^^^+>^^^.<br></br>
-xzzzzxwzuw^~+>>??vrv^-^^-|ru^|rzxx+|^|||^^|`          .~zw^||vux?^|||^>?>`<br></br>
`wwzzzzxxxzwv}zxuuuuuv~+?vzuw>>vzzxw+|^^^|`.         .-|+rur??wuuxwr}}wzxx|<br></br>
.vwv??vwxuuuuuuuuuuuuzzuuxxuzz?>rzxuw+^^~.         .|>?vzzzxxxw}}vvv}rwwzz~<br></br>
.+>++++>wxxzxuuuuuxxxxxxzzzuuz>+?rzxuz?`   .   .`^vwzxxxzzuw??>>>++++++>>>`<br></br>
.>?}?>>?rwwwwzzxzzwwwwwwrr}v??>>vwwzxuu?|>}v|?}zuyyuuxzzr?vvvv}rr}>>>>>>>>`<br></br>
.>>?>>>????>????????????>>>>>>>>?wwwwzxuxuuxxuuxxxxxxzwwv>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~+?>>>>>~|~^^~~~~|vv|~~~~~~~~~~~~~||||-.|~```````|+`-----```````.<br></br>
.^^^^^^^^^vv??????^^^>+^^^>ruz>^^^^^^^^^^^^^^~`.  ^v>+>?+~-~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^}}???????^^+???|?v+>+^^^^^^|~~-```..      +vv?>?+`~~~~~~~~~~---~-.<br></br>
`?>+^^^+ww????????+?}v?+v?^^^|~-`..                .`|>???^~-~~|^^^|~|||||.<br></br>
.?vvvv>}zvv????????v??>v?|`.                      .??..`|+>+~||~||^+^^^^^^.<br></br>
`????}rv???v??????>^~`..                           .`.      ~^|~^^^^^^^^^^.<br></br>
.?r?}v?+????v?^..                                         ~|^^||^+^^^^^^^^.<br></br>
`vvzv???????>~.      ..`--|>?+~~~~~~~~~--``.              -v?^^^^^>^^^^^^^.<br></br>
.+rr?????>`..`.  .-^>??????vv}vv>+^++++++++^^|~.         `..~>|^^~?^^^^^+^.<br></br>
.vzv?????|`-^>?>+???>>?????>???????+^^+^^^^^+^>-        ~>|~~+|^^|>^^^^^+^.<br></br>
`vxv?????>??vwrv}v?????????^++++++>>+|^++^^^++^.        ++-|^+>+^^>^^^^++^.<br></br>
.?x}???????}zwwwrrv???????>+?>?|~^^^^^^++++++++`        +^^|>vv?+>++++++++.<br></br>
.+vr??????vv????????>?????>?wr}|-|++>?>++++++^>`       .?>>>??>>>>>+++++++.<br></br>
.+|>?????????????????????++>??rw?-|?rww}>+++++^.       .???>vrv?>+++++++++.<br></br>
.|^++>???????????????vv>|.-`~^>wy?-++^+?v+++++|        .?>?>?v?>+^^+^+++++.<br></br>
.^>>^v?+>?????????>>>+?w}-   .-?}w~-|++^?^^++>~        .?>?+++>+++>>^+++++.<br></br>
.^>+r?>++^++++++++++~~^+vzv^|`  .~-+~|++??^^^|.        -^?|>>^^^^^^||^^^++.<br></br>
.||}v+?^^??>+++++++|..+>+?}wrv>`~~-v}-~^+w^^^^.        >|++~++^^^^^|^>r??>.<br></br>
.?rxz}++ryr?++++++^..->rvvwwux>~~~-|wv-.~?`---        .++~++|^?|^+^|>>wvuu~<br></br>
.vwzur?xyu}>>+++++-.~}}xuuuuu}-~~->v}r?`. ...         -?+>??^^?}^r++>>v}zz|<br></br>
.?zwuuyx}?-`^>++++`>vzxuxxzxx?-~~^}}v}w+`             |?~>?zv?>?+?|>>>?wwz~<br></br>
.-^vuz?|-....+>+?++}rwwrr}wx}^~|>v}}}}rw|.           .^++>^??}>>^~+>>+vzwz|<br></br>
.---+-...    .||}vvv}}}}}}wxrr}v}}}}}}wz}-.          ~>|^^>+^>wv|^++>>vvrz~<br></br>
`??+~~^+?>>^~+??wrwzwrrrwzxuuuuxwwwwwwwwx}v^         ~++|^||?wv^|+vwxzwzz}-<br></br>
.?r????vv???>????????>>>>>>???>>?v>>>?>>>>>.        -?zv^+^}uwv?}zzxzxz?++.<br></br>
 >z+||||||||||||||||^^^||||||+?w}^||||||||.         }xxz}}wxzzxxzwwzxxr}?|.<br></br>
.ryr||||^|||^^|||||^||||||||?wxw+|||||||^`         -r}}}zuxwrrwrwwwzzwwxw?.<br></br>
`xxur+||||||?+^^^^^^^^^|>}r}uuz>|^^^^^^^~          ~^^|>xxw>+>^^^++++++++^.<br></br>
.vzzuxv+>>>>>^^^^^^^^^^>rzzxuuz?^^^+^^^^.         `++?vxxzv^+>^^^^^^^^^^^^.<br></br>
.+}xxxxzrvv??>++++++>>>>?rwzuxwu?++++++.         .++?uuuux?++>>+++++++++++.<br></br>
`wxuxwv?>>>>>>>>>>>>>>>??vwxux?}x?>>>>`          ^>>}xuuuz>>>??>>>>>>>>>>>.<br></br>
`xuuxw}}vvvvvvvv}}}}}}}}}rzzr}?>rv>>>~          `>>>>?xxzr>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~+?>>>>>~|~^^~~~~|vv|~~~~~~~~~~~~~||||-.|~```````|+`-----```--``.<br></br>
.^^^^^^^^^vv??????^^^>+^^^>ruz>^^^^^^^^^^^^^^~`.  ^v>+>?+~-~~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^r}???????^^+???|?v+>+^^^^^^|~~-```..      +vv?>?+`~~~~~~~~~~~--~-.<br></br>
`?>+^^^+ww????????+?}v?+v?^^^|~-`..                .`|>???^~-~~|^^^|~|||||.<br></br>
.?vvvv>}zvv????????v??>v?|`.                      .??..`|+>+~||~||^+^^^^^^.<br></br>
`??>?}rv???v??????>^~`..                           .`.      ~^|~^^^^^^^^^^.<br></br>
.?w?}v?+????v?^..                                         ~|^^||^+^^^^^^^^.<br></br>
`vvzv???????>~.      ..`--|>?+~~~~~~~~~--``.              -v?^^^^^>^^^^^^^.<br></br>
.+rr?????>`..`.  .-^>??????vv}vv>+^++++++++^^|~.         `..~>|^^~?^^^^^+^.<br></br>
.vzv?????|`-^>?>+???>>?????>???????+^^^^^^^^+^>-        ~>|~~+|^^|>^^^^^+^.<br></br>
`vxv?????>??vwrv}v?????????^++++++>>+|^++^^^++^.        ++-|^+>+^^>^^^++++.<br></br>
.?x}???????}zwwwrrv???????>+?>?|~^^^^^^++++++++`        +^^|>vv?+>++++++++.<br></br>
.+vr??????vv????????>?????>?wr}|-|++>?>++++++^>`       .?>>>??>>>>>+++++++.<br></br>
.+|>?????????????????????++>??rw?-|?rww}>+++++^        .???>vrv?>+++++++++.<br></br>
.|^++>???????????????vv>|.-`~^>wy?-++^+?v+++++|        .?>?>?v?>+^^+^+++++.<br></br>
.^>>^v?+>?????????>>>+?w}-   .-?}w~-|++^?^^++>~        .?>?+++>+++>>^+++++.<br></br>
.^>+r?>++^++++++++++~~^+vzv^|`  .~-+~|++??^^^|.        -^?|>>^^^^^^||^^^++.<br></br>
.||}v+?^^??>+++++++|..+>+?}wrv>`~~-v}-~^+w^^^^.        >|++~++^^^^^|^>r??>.<br></br>
.?rxz}++ryr?++++++^..->rvvwwux>~~~-|wv-.~?`---        .++~++|^?|^+^|>>wvuu~<br></br>
.vwwur?xyu}>>+++++-.~}}xuuuuu}-~~->v}r?`. ...         -?+>??^^?}^r++>>v}zz|<br></br>
.?zwuuyx}?-`^>++++`>vzxuxxzxx?-~~^}}v}w+`             |?~>?zv?>?+?|>>>?wwz|<br></br>
.-^vuz?|-....+>+?++}rwwrr}wx}|~|>v}}}}rw|.           .^++>^??}>>^~+>>+vzwz|<br></br>
.---+-...    .||}vvv}}}}}}wxrr}v}}}}}}wz}-.          ~>|^^>+^>wv|^++>>vvrz~<br></br>
`??+~~^+?>>^~+??wrwzwrrrwzxuuuuxwwwwwwwwx}v^         ~++|^||?wv^|+vwxzwzz}-<br></br>
.?r????vv???>????????>>>>>>???>>?v>>>?>>>>>.        -?zv^+^}uwv?}zzxzxz?++.<br></br>
 >z+||||||||||||||||^^^||||||+?w}^||||||||.         }xxz}}wxzzxxzzwzxxr}?|.<br></br>
.ryr||||^|||^^|||||^||||||||?wxw+|||||||^`         -r}}}zuxwrrwrwwwzzwwxw?.<br></br>
`xxur+||||||?+^^^^^^^^^|>}r}uuz>|^^^^^^^~          ~^^|>xxw>+>^^+++++++++^.<br></br>
.vzzuxv+>>>>>^^^^^^^^^^>rzzxuuz?^^^+^^^^.         `++?vxxzv^+>^^^^^^^^^^^^.<br></br>
.+}xxxxzrvv??>++++++>>>>?rwzuxwu?++++++.         .++?uuuux?++>>+++++++++++.<br></br>
`wxuxwv?>>>>>>>>>>>>>>>??vwxux?}x?>>>>`          ^>>}xuuuz>>>??>>>>>>>>>>>.<br></br>
`xuuxw}}vvvvvvvv}}}}}}}}}rzzr}?>rv>>>~          `>>>>?xxzr>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~|~~~~~~~~||||||||~.     -?>^|>+-`--------`````.<br></br>
.^^^^^^^^^^^^^^^^>^+v?^^^^^^^^^^^^+^^^||~~-``.       `+????^--~~~~||||||||.<br></br>
.^^^^^^^^^^^^^^>??++v>+^^^^^+^^|~-``..             .^+.`+????>^-~~~||^^^^^.<br></br>
.^^^^^^^^^^^^^?v??>^^^>^^^^^-..                     ~?^  .`-|^|.|^^||^^^^^.<br></br>
.^^^^^^^^^^^+}v???>|+>>>^^|`                                  .~^^^^^^^^^^.<br></br>
.^^^^+???>^>vv????>+??>||.                                   `|~^^^^^^^^^^.<br></br>
.^^^^|>?vrv?>??+??????>>~.     -+>v}v>~--```...             ~+|||^?^^^^^^^.<br></br>
.>>>>>+vrv????????????-..-|`~.`^++>?+++^+++^^|+~`          .++^^^~>^^^^^^^.<br></br>
.+>?vv}zv?????vv?vv????????>+^^^^^^^^^^+^^^^^^>^^`          `>|^^^>^^^^^+^.<br></br>
.^^|+>r}????????????v?????vvvv?>|~~^^^^^^^^^^^|>+-         .|+|+^^>^^^^^+^.<br></br>
.^+?}>v}?????????????????>+>>>>>~~~^^^++^^^^++^^^-         .^^+^^>>^^^++++.<br></br>
.>}}?+>v?????????????????>>>?>?^-~~^+++++++++++??>         `+??>>>++++++++.<br></br>
.}}vv??vv?????????????v}vvvwxwr>~>|^+++++++++^>zx?         ~}v?>>>>+++++++.<br></br>
 ??+^|++>??????????????>^+>?rzw>|>?+++++++++++^}u|         `}rv??}>+++++++.<br></br>
.|||^^?++>>??????????>+>?`++|wu}^~+^++++^++++++??.         -?v??ww^+^+++++.<br></br>
.^++^>+|+>++++++++++^|+>> ...>^zxz?-|+++^^^++++~-          `+++?uw?+^+++++.<br></br>
.^^^^^|^+?+|>>+++++^>..~^~->|~`>|wxv>~+++^^^^|-|~          |>^|+wv|^^+++++.<br></br>
.^^>>++|+>?^??>+++++-.>v?r}zr}v?>+vur-~^~^|^^^vx~          -+++^v?^+^++>?>.<br></br>
.vwxxxz}r}z>+^>+++^->>rxuxuuuxzzwrv?x?-..`.--|}?           |||^^|>?r>+vxuu~<br></br>
.?rzuuuyyuv+-`~++++^?uuuuuuxxzw}vvvv}}?`. ...`^`           |>+~?>+>v?rwwzz|<br></br>
.>?wuuyzv>~`...|+^^vxwxxxwzwrrvvvv}}}}w+`    ..           .?>v^rv+>>vzwwwz|<br></br>
`zzxuz?|-`..  .^?+?r}wwr}}}}}vvvv}}}}}}r|.                -?+?zw>>v?}wwwzz|<br></br>
`xxw?-...     .|}r}v}}}}}zzrrrr}}}}}}}}w}-.               ^+|??^>}?>vvrrvv-<br></br>
`xw>~~|+?>>+~>v?w}wwwrr}wxxxuuuxzzwwwzzwx}vv^            -+>|^vwwzrwzxxx}v-<br></br>
`zr????vv???>????????>>>>>>>>>>>>?>>>?>>>>?>`          .}zzz?wuuxxuuuxzzrr-<br></br>
`zz>||||||||||||||||^^^|||||||^^^||||||||||.           +yxzxxxxxzzxuuxxux?.<br></br>
.>}?|||||^|||^|||||^||||||||^^++^|||||||^|`            `?zw?????vrwzzzzzxx|<br></br>
.^??^^^^^^^^^^^^^^^^^^^^^^^^?w?^^^^^^^^^^`             ~^?v|^^||^^++++++>?`<br></br>
.+rzw>^^^^++++^^^^^^^++++++^rxw+^^^^^^^+-            +~?++>^^^^^^^^^^^^^^^.<br></br>
.+>??rr??>++++++++++++++>>?rx?v?+++++++-             +v+>>?+++++++++++++++.<br></br>
.>>>vz}v?>>>>>>>>>>>>>>>>vxxw?>?>>>>>>|            |`-x?>>>>>>>>>>>>>>>>>>.<br></br>
.>v}xx}vvvv??>>>>????v}rwzzr}>>>>>>>>^             ~wrxr>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||||||~.     -?>^|>+-`--------`````.<br></br>
.^^^^^^^^^^^^^+^+>^+v?^^^^^^^^^^^^+^^^^|~~-``.       `+????^--~~~~||||||||.<br></br>
.^^^^^^^^^^^^^^>??++v>+^^^^^^^^|~-``..             .^+.`+????>^-~~~||^^^^^.<br></br>
.^^^^^^^^^^^^^?v??>^^^?^^^^^-..                     ~?^  .`-|^|.|^^||^^^^^.<br></br>
.^^^^^^^^^^^>}v???>|+>>>^^|`                                  .~^^^^^^^^^^.<br></br>
.^^^^+???>^>vv????>+??>||.                                   `~~^^^^^^^^^^.<br></br>
.^^^+|>?vrv?>??+??????>>~.     ~+>v}v>~--```...             ~+|||^?^^^^^^^.<br></br>
.>>>>>+vrv????????????-.`-|`~.`^++>?+++++++++^>~`          .++^^^~>^^^^^^^.<br></br>
.+>?vv}zv?????vv?vv????????>+^^^^^^^^^^^^^^^+^?^^`          `>|^^^>^^^^^+^.<br></br>
.^^|+>r}????????????v?????vvvv?>|~~^^^^^^^^^^^|>+-         .|+|+^^>^^^^^+^.<br></br>
.^+?}>v}?????????????????>+>>>>>~~~^^^^+^^^^++^|+~         .^^+^^>>^^^++++.<br></br>
.>}}?+>v?????????????????>>>?>?^-~~^+++++++++++?v>         `+??>>>++++++++.<br></br>
.}}vv??vv?????????????v}vvvwxwr>~>|^+++++++++^?xu?         ~}v?>>>>+++++++.<br></br>
 ??+^|++>??????????????>^+>?rzw>|>?+++++++++++^ru^         `}rv??}>+++++++.<br></br>
.||^^^?++>>??????????>+>?`++|wu}^~+^++++^+++++^??.         -?v??ww^+^+++++.<br></br>
.^++^>+|+>++++++++++^|+>> ...>^zxz?-|+++^^^++++|-          `+++?uw?+^+++++.<br></br>
.^^^^^|^+?+|>?+++++^>..~^~->|~`>|wxv>~+++^^^^|-^~          |>^|+wv|^^+++++.<br></br>
.^+>>>+^+>?^??>+++++-.>v?r}zr}v?>+vur-~^~^|^^^}u~          -+++^v?^+^++>?>.<br></br>
.vwxxxz}r}z>+^>+++^->>rxuxuuuxxxzrv?x?-..`.--|}?           |||^^|>?r>+vxuu~<br></br>
.?rzuuuyyuv+-`~++++^?uuuuuuxxxwrvvvv}}?`. ...`^`           |>+~?>+>v?rwwzz|<br></br>
.>>wuuyzv>~`...|+^^vxwxxxwzwrrr}}}}}}}w+`    ..           .?>v^rv+>>vzwwwz|<br></br>
`xxuuz?|-`..  .^?+?r}wwr}}}}}}}}}}}}}}}r|.                -?+?zw>>v?}wwzzz|<br></br>
`xuw?-...     .|}r}v}}}}}zwrrrr}}}}}}}}w}-.               ^+|??^>r?>v}rwvv-<br></br>
`xw>~~|+?>>+~>v?w}wzwrr}wxxxuuuxzzwwwzzwx}vv^            -+>|^vwzzwwzxxx}v-<br></br>
`zr????vv???>????????>>>>>>>>>>>>?>>>?>>>>?>`          .}xzw?wuuxxuuuxzxwr-<br></br>
`zz>||||||||||||||||^^^||||||||||||||||||||.           +yxzxxxxxzzxuuuxux?.<br></br>
.>}?|||||^|||^||||||||||||||^^++^|||||||||`            .?zw?????vrwzzzzzxx|<br></br>
.^??^^^^^^^^^^^^^^^^^^^^^^^^?w?^^^^^^^^^^`             ~|?v|^^||^^++++++>?`<br></br>
.+rzw>^^^^++++^^^^^^^+++^^^^rxw+^^^^^^^+`            +~>+++^^^^^^^^^^^^^^^.<br></br>
.+>??rr??+++++++++++++++>>?rx?v?+++++++~             +v++>?+++++++++++++++.<br></br>
.>>>vz}v?>>>>>>>>>>>>>>>>vxxw?>?>>>>>>|            |`-x?>>>>>>>>>>>>>>>>>>.<br></br>
.>v}xxrvvvv??>>>>????v}rwzzr}>>>>>>>>^             ~wrxr>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||||~~~--.       `^>?>+-```-----~~~--.<br></br>
                  .^^^^^^^^^^^^^^+^^^^+^^^^^^^^^^^^+^^|~-``...         -`.|?vv?^~-~~~~~|^^^^.<br></br>
.^^^^^^^^^^^^^^++^^^+^^^^^^^^^^^|`..                .>?~ .-^>???-`^|||||^^.<br></br>
.^^^^^^^^^^^^^+vvv>^^^>+^^^^^^|-                     .`.      ...-^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^?v?>^^^++^^^^||`                                `^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^vv??>|^>>|^^|||-.     .......                   .~^^^^^^^^^^.<br></br>
.|^^^^^|>??>??????????>+>||~`. ..``~^^^^^^^|~--`.            .||^++^^^^^^^.<br></br>
.||^^^^^+v}v???vvvv?????>~^|-`-~-^^+++++++++^>>^^-.          .^+^^+^^^^^^^.<br></br>
.||~^>?vvv??????????????>??+^^||~|>?>+^^^^^^^+?|^+|          `^+^~>^^^^^^^.<br></br>
.|~~????v????????????????vrrvv>~~>w}?^^^^^^^+^+>^^-          -|+^|>^^^^^+^.<br></br>
.|~~vv}vv??????????????vv????v?`~^??+^+++^^+++^^++-          `>+^++^+^+^++.<br></br>
.^^|?vv>?v?????????????v?^>>?+?|~^^^^++++++++++?v}>          ~v?>wv+vv^+++.<br></br>
.++?v?>>>???????????????>?wuur}>-^+++++++++++^?xux>          -?>>?v+>wv+++.<br></br>
.+?>+^||+>>???????????>+++>vzxzr>~^+++++++++++^ru}`          |}?>>+++}}+++.<br></br>
.^^^^^|>+^>>>>>????>>++?> -`|vrux?-|++++^+++++^??|.          ~v?>+^+^>>+++.<br></br>
.^+++^|^|^>+^+++++++|`~+~    .-v}x?-|+++^^^++++|~`           ->>>>>+^?>+++.<br></br>
.^+++^|^^^>^++>++++^|. .`.`^^`. .?xv~|+++^^^^|-^?^           `^^++^^++++++.<br></br>
.^+>>>+^>?>|?^+>+++^..+>+??}}??`` >z}-~^|||^^^}uw`           ~+^^??++++>?>.<br></br>
.vwxxxz}zuw>+|`+>++``~?wrzzuxwz}r>||wv-..`.-`|}v~            -^|~?z?+>vxuu~<br></br>
.?rzuuuyyu}>-`.>?+^.~rrxuuuxxxzw}}}v}}?`. ...`^~             ~^|^>vvzzwwwz|<br></br>
.>>wuuyx}>~`...>w>`>vzxuxzwwrrrr}}}}vvw+`    ..             .^^^>>>}uuwwwz|<br></br>
`xxxuz?|-...  .>w+>}}wwrr}}}}}}}}}}}}}rw|.                  ->^^>?}zzzwwwz|<br></br>
`xuzv-...     .^}vvv}}}}wxrrrrr}}}}}}}}w}-.                 |^>?wxzr}v}ww}-<br></br>
`xw?|~^+?>+^~+??wrwzwrr}wxxuuuxxwwwwwzwwx}vv?.             ^rrxxuxxzwzxxzv-<br></br>
`xw????vv??>>????????>>>>>>>>>?>>>>>>?>>>>??~             .zxxuuuxxuuuxxw}~<br></br>
                  `zz+||||||||||||||||^^^|||||||^||||||||||||`              ?zzzxxxzzuuuuuuw`<br></br>
.+?+|||||||||||||||^|||||+>|||^^||||||||||`              `>>>?????rzxxzzuu|<br></br>
.^+^^^^^^^^^^^^^^^^^^^^^^^>^+v^^^^^^^^^^^-              .++|++|||^^^+++>??`<br></br>
.?x?++^^^^^^^^^^^^^^^++++^^^vv+^^^^^^^^+~               >z}+++^^^^^^^^^^^^.<br></br>
.>??+>>v?+++++++++++++++++?}w>>>+++++++~               -?zz>+>++++++++++++.<br></br>
.>>>>v}v?>>>>>>>>>>>>>>>>vxx}>>}>>>>>>|               `>>vr>>>>>>>>>>>>>>>.<br></br>
.>>?}ux}v???>>>>>????v}rzxzz}>>?>>>>>+.              .+>>>>>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||||~~~--.       .^>?>+-.``-----~~---.<br></br>
                  .^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^+^^|~-`....        .~-.~?vv?+^|~~~~~|^^^^.<br></br>
.^^^^^^^^^^^^^^>+^^^^^^^^^^^^^^^|`.                 .>v^  `^>???~`^^|||||^.<br></br>
.^^^^^^^^^^^^^+v}v>^^^>>^^^^^^^-                      .`      ...-^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+vv?>^^^>+^^^^^^`                                `^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^vv??>|^>>|^^^^+|.     .``....                   .~^^^^^^^^^^.<br></br>
.|^^^^^^>?????????????>+>^^^~`.  ..-^+++^^^^|~|`.            .~|^^^^^^^^^^.<br></br>
.~|^^^^^^v}v???vvvv?????>~^^-----^^+^^^^^+++^>>^^~.          .^+^^+^^^^^^^.<br></br>
.~~~^+?vvv??????????????>??+^^|~~|>?>+^^^^^^^+?|^>+          `^+^~?^^^^^^^.<br></br>
.|~~?v??v????????????????vwr}v>~~?zr?^^^^^^^+^+>^^~          `|+^|>^^^^^+^.<br></br>
.|~~vv}?v??????????????vv????v?-~+vv+^++++++^+^^+>|          `>+^++^++^+++.<br></br>
.^^|?vv+?v?????????????v>^>>?+v|~^^^+++++++++++?vr?          ~v?>zv+}v^+++.<br></br>
.^+?v?>^+??????????????>+?wuuw}>-|^++++++++++^>xuy?          -?>>?v+>zr+++.<br></br>
.+??+^^|+>>???????????>+++>vzxzw?~|+++++++++++^ruw~          |}?>++++ww+++.<br></br>
.^^^^^^++^++>>>???>>+++?> -.|?}uu?-|++++^+++++^??+.          ~v>+^^+^>>+++.<br></br>
.^++++^^|^>+|+++++++~.~+~    .-v}x?-|+++^^^++++|~-           ->+++>+^v>+++.<br></br>
.^++++|^^|>^^+>++++^~  .`.`||`  .?xv~|+++^^^^|-^?>           `^^^^^^++++++.<br></br>
.^+>>>+^>v>|?^^>+++^..+>^??vv>?`` +w}-~^|||^^^}uz`           ~+^^?v^+++>?>.<br></br>
.vwxxxz}zyw>+|`+>++.`~?w}zzuxrzvr>|~wv-....--|}}|            -^|~?x?+>vxuu~<br></br>
.?rzuuuyyu}>-``>?+|.~r}xuuuxxxzw}}rv}}?`. ...`^|.            -^~^>vvzxwwzz|<br></br>
.>>wuuyx}>~`...>x>`>vzxuxzwwrrr}}}}}vvw+`    ..             .~+|+>>}uxzwwz|<br></br>
`xxuuz?|-...  .+z+>}}wwrr}}}}}}}}}}}}}rw|.                  -+||>?rxzzwwwz|<br></br>
`xuzv-...     .^}vvv}}}}zxrrrrr}}}}}}}}w}-.                 |^>?xuxz}v}ww}-<br></br>
`xw?|~^+?>+^~+??wrwzwrr}zxxuuuxxwwwwwzwwx}vv?.             ^zwuuuuxxwzxxzv-<br></br>
`xw????vv??>>????????>>>>>>>>>?>>>>>>?>>>>??~             .wuuuuuxxuuuxxw}~<br></br>
                  `zz^||||||||||||||||^^^|||||||^||||||||||||`              ?zzzxxxzzxuuuuuw`<br></br>
.+>^|||||||||||||||^|||||+?|||||||||||||||`              .>>>>????}zxxzzuu|<br></br>
.^+|^^^^^^^^^^^^^^^^^^^^^^>^+v^^^^^^^^^^^-              .++|+>|||^^+++>>??`<br></br>
.?x?^^^^^^^^^^^^^^^^^++++^^^v?^^^^^^^^^+~               >uw+++^^^^^^^^^^^^.<br></br>
.>v?++>v?+++++++++++++++++?}w++>+++++++|               `?xx>+>++++++++++++.<br></br>
.>>>>v}v?>>>>>>>>>>>>>>>>vxx}>?r>>>>>>|               `>>}z>>>>>>>>>>>>>>>.<br></br>
.>>?vux}}??>>>>>>?v??v}rzxzz}>>?>>>>>+.               ^>>??>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||||~~--`.        -+?>>|```-----~~~~-.<br></br>
.^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^+^^|~-`...         .||.`>vv??>^~-~~~||^^^.<br></br>
.^^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^|-`.                .^?+  .-^>??|.|^^|||^^.<br></br>
.^^^^^^^^^^^^+?v+?>^^^>+^^^^^^^|.                     ..        .-^^^^^^^^.<br></br>
.^^^^^^^^^^^^^+?vv>^^^>>^^^^^^~.                               .^+^^^^^^^^.<br></br>
.|^^^^^^^^^^^^?v??>|^>+|^^^^^|-.    .````...                   ~^^^^^^^^^^.<br></br>
.~|^^^^^+??>??????????+^>^^^|-.. .`-^+++++^^^~|``             `^^^>^^^^^^^.<br></br>
.~~|^^+^|?}v???vvvv??????|^^^~``-^+++^^^^^^++^>^+~.           -+^~^^^^^^^^.<br></br>
.~~~|++??vv?????????????>>>+^>|~~^^++^^^^^^^^^>^^+>           ~+^^>^^^^^^^.<br></br>
.|~~^vv??v???????????????}wrvw>~~^??+^^^^^^^+^|>+^~           |^^^>^^^^^+^.<br></br>
.^||+?v}vv?????????????vv??????~>vr?+^^^++^^^+^^++|          .+++v?^??+^++.<br></br>
.^^^>vv>>vv????????????v>^>>?+?^+??+^++++++++++?vrv.         .?>>r}>?v>+++.<br></br>
.^^?v?>++>?????????????>+?wuuwv+-|^++++++++++^?xuy}           >>>?>++>w?++.<br></br>
.^>?+^^|++>???????????>++>?vxxzw?-|+++++++++++^rur|          .??>++++>v>++.<br></br>
.|^|^^^+>^++>>>>>>>>+++?v.^|>wzuu?-|++++^+++++^??+.           ??+^^+^+>+++.<br></br>
.^++++|^|^?+|+++++++^~+^| ...^|rzu?-|+++^^^++++|~~            ^+++>+^+++++.<br></br>
.^++++^^^|>+^^>++++^>...`~`>^-`|-ruv~|+++^^^^|-^??            |^++>^++++++.<br></br>
.^+>>>+^>v?|?||>+++^`.>??wvwrvv+^~?x}-~^|||^^^}uz-           .^^^>v?+++>?>.<br></br>
.vwxxxz}zyw>+|`+>++-^>}xxxuuuxxzw}?>zv-....-`|}}^            .^||>rv+?rxuu~<br></br>
.?rzuuuyyxv+-``?v+^`>xxxuuxxxzzr}}}}}}?`. ...`^|.            `^~^?v}zxzwwz|<br></br>
.>>wuuyzv>~`...>x?`vzwxxxzwrrrr}}}}}v}w+`    ..              `>|>>+wuxzwwz|<br></br>
`xxuuz?|-`..  .+w??r}wwrr}}}}}}}}}}}}}}w|.                   ~^^??}xzzwwwz|<br></br>
`xuz?-...     .^}vvv}}}}zxrrrrr}}}}}}}}w}-.                 `^>?xuxz}v}ww}-<br></br>
`xz>~~^+?>++~>v?w}wwwrr}zxxxuuuxzwwwwzwwx}vv?`             .rzuuuuxxzzxxx}`<br></br>
`uw????vv???>????????>>>>>>>>>>>>>>>>?>>>>??|              ?uxuuuuxuuuxxw}~<br></br>
                  `zw^||||||||||||||||^^^|||||||^^|||||||||||`              -zzzxxxzzxuuuuuz-<br></br>
.^+^|||||||||||||||^|||||^?^|||||||||||||^`               ^>>>>>>?vzxxzzuu|<br></br>
.^+|^^^^^^^^^^^^^^^^^^^^^^?^^v^^^^^^^^^^^-               +?^^^|||^^++++>??`<br></br>
.vz>^^^^^^^^^^^^^^^^^++++^^^??+^^^^^^^^+~               -v?^^>+^^^^^^^^^^^.<br></br>
.??>+++v?+++++++++++++++++>vw++++++++++~               .>xx?+>++++++++++++.<br></br>
.>>>>?vv?>>>>>>>>>>>>>>>>vxxv>>}>>>>>>|               .+>wx?>>>>>>>>>>>>>>.<br></br>
.>>>?xxr}v?>>>>>>v??vvv}zxzzv>>}?>>>>+.               ~>>v}>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|||||~~-`..        -+?>>^```-----~||~~.<br></br>
.^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^|~-`..          .^+.`+vvv?>^|-~~~||^^^.<br></br>
.^^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^~`.                .^?>  .-|>??^.|^^^||^^.<br></br>
.^^^^^^^^^^^^+v?+?>^^^+>^^^^^^^^`                      .        .`^^^^^^^^.<br></br>
.^^^^^^^^^^^^+>?vv>^^^>>^^^^^^^`                               .^+^^^^^^^^.<br></br>
.|^^^^^^^^^^^^?v??>|^++|^^^^^^^`    .`````...                  -^^^^^^^^^^.<br></br>
.~~|^^^^^>>>??????????+^>+^^^~-...`-|++++++^^|^-`             `^^^?^^^^^^^.<br></br>
.~~~^^+^|?}v???vvvv??????^^^+|``-^+++^^^^^^++^?^+|`           -+^~>^^^^^^^.<br></br>
.~~~|^+>?vv?????????????+++^^>|~~^^^^^^^^^^^+^?^^+?           ~^^^>^^^^^^^.<br></br>
.|~~|}v>?v???????????????}w}vw+~~^>>^^^^^^^^+^|>+^|.          |^^^>^^^^^+^.<br></br>
.^^||>v}vv?????????????vv??????~?ww?+^^^++^^^+^^++^.          +^+r?^?v+^++.<br></br>
.^+^+vv>+?v?????????????>^>??+?+???+^++++++++++?vr}.          >>>}r>>r?+++.<br></br>
.^^>v?>++>?????????????>+?wuuwv^-|^++++++++++^?xuyw.          ??>>>+^+zv^+.<br></br>
.^>?+^^|++>???????????>++>?}uxzw?-|+++++++++++^rur+           ??>+++++}?++.<br></br>
.|^|^^^^>^++>>>>>>>>+++?}`++?xuuu?-|++++^+++++^??>`           ??+^^+^+?+++.<br></br>
.^++++^^|^>+|++++++++^>|^ ...>^zxu?-|+++^^^++++|~|.           ^>++>+^+>+++.<br></br>
.^++++^^^|>+^^>++++^?.`.`|`>|~`+|wuv~|+++^^^^|-^?v            |^^+>^++++++.<br></br>
.^+>>>+^>v?|?|~>+++^-.>v?z}zr}}?++vu}-~^~||^^^}uz|            ^^^+rv+^+>?>.<br></br>
.vwxxxz}zyw>+|`+>++->?rxuuuuuxxxzwv?x?-....--|}}+            .^~|+}}+vzxuu~<br></br>
.?rzuuuyyxv+-``?}+^-?uuuuuxxxzzrv}}}}}?`. ...`^|.            `^~+?}rzuxwzz|<br></br>
.>>wuuyzv>~`...>uv-vxwzxzwwrrrr}}}}}}}w+`    ..              `?|>>>zuxzwwz|<br></br>
-xxuuz?|-`..  .+wv?r}wwrr}}}}}}}}}}}}}}w|.                   -^^??}xzzwwwz|<br></br>
`xuz?-...     .|}}vv}}}}zxr}rrr}}}}}}}}w}-.                 .|>vxuuzrv}rwr-<br></br>
`xz>~~^+?>++~>v?w}wzwrr}zxxxuuuxzwwwwzwwx}vv?`             .rzuuuuxxzwxxx}`<br></br>
`uw????vv???>????????>>>>>>>>>>>>>>>>?>>>>??|              ?uxuuuuxuuuxxw}~<br></br>
                  `zw^|||||||||||||||^^^^|||||||^^|||||||||||`              `wzzzxxzzxuuuuuz-<br></br>
.^+^|||||||||||||||^|||||^?^|||||||||||||^`               ^>+>>>>?vzxxzzuu|<br></br>
.^+|^^^^^^^^^^^^^^^^^^^^^^?^|v+^^^^^^^^^^-               >?^^^|||^^++++>??`<br></br>
.vz>^^^^^^^^^^^^^^^^^+++^^^^??+^^^^^^^^+~               `??^^?+^^^^^^^^^^^.<br></br>
.??>+++v?+++++++++++++++++>vr++++++++++~               .+xx?+>++++++++++++.<br></br>
.>>>>?vv?>>>>>>>>>>>>>>>>vxxv>>v?>>>>>|                ^>zu?>>>>>>>>>>>>>>.<br></br>
.>>>?xxr}v?>>>>>>v??vvv}zxzzv>>r?>>>>+.               ~>>rw>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||~~--`.         `^??>+-```----~|||~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+^|~-`..          .^>`.|?vv??+^-~~~|||^^.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|`.                 ~?>.  .~+??+.-^^^^^^^.<br></br>
.^^^^^^^^^^^^>?+^?>^^^++^^^^^^^^-                      .        .`|^^^^^^^.<br></br>
.^^^^^^^^^^^+>>>vv>^^^>>^^^^^^^-.                              .|+^^^^^^^^.<br></br>
.|||^^^^^^^^^^?}??>|^++|^^^^^^|`.    .````...                  -^^^^^^^^^^.<br></br>
.|~||^^^^>>+??????????+^++^^^~``..``|^+++++^^|^-`.            .^^^+^^^^^^^.<br></br>
.|~~|^^^|>}v???vvvv??????^^^^|``-^++++^^^^^+^^>^+^-           .^^|^^^^^^^^.<br></br>
.||~~^>>?vv?????????????+^++>?~~|^^^^^^^^^^^^^?^^+?           `+^|>^^^^^^^.<br></br>
.^^|~?}v?v??????????????v}rrvr+~|^++^^^^^^^^+^^>+^^.          -+^+>^^++^+^.<br></br>
.>++^>?}?vv????????????vv???v??^?vv>^^^^+++^^^^^++^.          ~++r}^>}?^++.<br></br>
.^+^+?v?+?v?????????????+^>?v>v?v}v+^++++++++++?v}r`          |?+?v++?>+++.<br></br>
.^^>v?>++>?????????????>+?zuuwv^~^^++++++++++^?xuyz.          ~?>>>++^v}++.<br></br>
.^>?>^^|++>???????????>+++?vxuzw?~|+++++++++++^rur>           |?>+++++??++.<br></br>
.|^^^^^^>^++>>>>>>>>+++??.~`+}ruu?-|++++^+++++^??>`           ~?+^^+^+>+++.<br></br>
.^++++^^||>>|^>+++++|`||-  ..`-vwu?-|+++^^^++++|~|.           `>++>>^+++++.<br></br>
.^++++^^^|>>^|>>+++^^....``+^`..`vuv~|+++^^^^|-^?}.           `^^^>>++++++.<br></br>
.^+>>>+^>v?|?|->>++^..>?>vvr}??~~.?z}-~^~||^^^}uz|            -+^^?v?^>??>.<br></br>
.vwxxxz}zyw>+|`>?++`-^vzzxxuuzxrw?+^zv-....--|}}>             -||+?v+vxxuu~<br></br>
.?rzuuuyyxv+-``?w>^`^zwuuuxxxxzw}}}}}}?`. ...`^|`             ~~+?}rzuxwwz|<br></br>
.>>wuuyzv>~`...>u?.?}wxxxzwwrrr}}}}}v}w+`    ..              .+|>>>zuxzwwz|<br></br>
-xxuuz?|-`..  .>z>>}}wwrr}}}}}}}}}}}}}rw|.                   .^^?v}xzzwwwz|<br></br>
`xuw?-...     .^}vvv}}}}xx}rrrr}}}}}}}}w}-.                  ~>vxuuzrvvrwr-<br></br>
`xw>~~^+?>++~+v?wrwzwrr}zxxxuuuxzwwwwzwwx}vv?`              ?zuuuuxxzzxxxr-<br></br>
`uw????vv???>????????>>>>>>>>>>>>>>>>?>>>>??|              |xxuuuuxuuuxxw}~<br></br>
`wr^|||||||||||||||^^^^|||||||^^|||||||||||`              .}zzzxxzzxuuuuux-<br></br>
.^+||||||||||||||||^|||||^?^|||||||||||||^-               -++>>>>>vzxxzxuu|<br></br>
.^^|^^^^^^^^^^^^^^^^^^^^^^?^|?+^^^^^^^^^^-               ~?+^||||^^+++>>??-<br></br>
.vw+^^^^^^^^^^^^^^^^^+++^^^^??+^^^^^^^^+~                +>+^++^+^^^^^^^^^.<br></br>
.??>+++??+++++++++++++++++>vr++++++++++|                ~v}>+>>+++++++++++.<br></br>
.>>>>?vv?>>>>>>>>>>>>>>>>vzxv>>?>>>>>>^                ~>zu?>>>>>>>>>>>>>>.<br></br>
.>>>?zur}v?>>>>>>v??vvv}wxzzv>>r?>>>>+.               `>>rz>>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||~~--`.         .|??>+~```----~|||~.<br></br>
.^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^^^^~-`..          .+?-.~?vv??>+~-|~~||||.<br></br>
.^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^^^^~`.                `>?.  .-^>?>.`^^^^^^^.<br></br>
.^^^^^^^^^^^+?>^^>>^^^++^^^^^^^^^.                     .         `|^^^^^^^.<br></br>
.^^^^^^^^^^^>v++?v>^^^>>^^^^^^||`                               |^^^^^^^^^.<br></br>
.^~~^^^^^^^^^^>}??>|^++^^^^^^|~|~    .`-```..                  `^^^^^^^^^^.<br></br>
.^~~~^^^^>>+>>vv????>?+^++^^^|~-..``|^++++++^^+--.            .|^^^^^^^^^^.<br></br>
.^~~~|^^|+v}v???v????????+^^^|~--^^+++^^^^^^^>>^+^|           .^^^>^^+^^^^.<br></br>
.^|~~|?>>?}?????????????+|^>?v~~|+^^^^^^^^^^^+?|^>?.          .+^~?^++^^++.<br></br>
.++^~^}}?v???????????????}vr?v^~^^^^+^^^^^^^^^+>^|+`          `++>?|+?>^++.<br></br>
.???+^>v?vv????????????vv?vvv??>+>>+++^^^^^^^+^^>>>`          -+^}w++z}^++.<br></br>
.^^^+?v?+?v?????????????+^>?v?vvwwv++++++++++^+?v}w|          |?+>>+^>>+++.<br></br>
.^^+???+^>?????????????>+vzuuwv^|++^^++++++++^>xuux`          `>>>>++^>w>+.<br></br>
.^+?>^^|^>>>?????????>++>+?vzxzw?||+++++++++++^ruw?.          -?>++++^+v++.<br></br>
.|^^^^^^>^^++>>>>>>>^^+?? -.|v}uyv-|++++^+^^++^??>~           -?+^^+^^+>++.<br></br>
.^++++^|||>>|^>+++++-.~|`    .`?}x?-|+++^^^++++|~|`           `>++>>^+++++.<br></br>
.^++++^^^|^>^~>>+++^~  ...`^^`  .?xv~|+++^^^^|~^?r.           `^^^??++++++.<br></br>
.^+>>>+^>vv^?^`+>++|..+>^??vv>>.` >z}-~^|||^^^}ux+            `+^^^rv+???>.<br></br>
.vwxxxz}zyz>+|`>?++`.~?w}zzuxrzvr>|~wv-....-`^}}?.            `||++?+vuuuu~<br></br>
.?rzuuuyyu}>-``?x>|.~r}xuuxxxxzw}r}v}}?`. ...`^|`             -~+?}wzxxzwz|<br></br>
.>>wuyyx}?~`...>u?.>vzxuxzwwrrrr}}}}vvw+`    ...              ^|>>>xuxzwwz|<br></br>
-xxuuz?|-`..  .>w+>}}wwrr}}}}}}}}}}}}}rw|.                   .^^?rruzzzzwz|<br></br>
`xuw?-...     .^}vvv}}}}xz}rrrr}}}}}}}}w}-.                  ~>vxuuzwvvrzw-<br></br>
`xw>~~^+?>>^~+??wrwzwrr}zxxxuuxxzwwwwzwwx}vv?-              >xuuuuxxxzxxxw-<br></br>
`uw????vv???>????????>>>>>>>>>>>>>>>>?>>>>??^              `xxxuuuxuuuxxwv-<br></br>
                  `wr||||||||||||||||^^^^||||||||||||||||||||`               vzzzxxxzzuuuuux-<br></br>
.|^||||||||||||||||^|||||^>^|||||||||||||^-               .+++>>>>vwxxxxuu~<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^>^|>^|^^^^^^^^^-               -?>>|||||^+++>>?v-<br></br>
.vr^^^^^^^^^^^^^^^^++++++^^^>?+^^^^^^^^+~                ^?>^^^^^^^^^^^^^^.<br></br>
.??++++??+++++++++++++++++>v}++++++++++|                -?v++>?+++++++++++.<br></br>
.>>>>????>>>>>>>>>>>>>>>>vzx?>>>>>>>>>^.               ->xu?>>>>>>>>>>>>>>.<br></br>
.>>>>rur}??>>>>>>v??vvv}wxzzv>>}?>>>>+.               .>>wz?>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

.~|~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~||||~~-``.         .~??>+|````----~||~.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|-`..           +v|.->vvv??>|-||~||||.<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^|`.                `+?`  .`|+>>..^^^^^^^.<br></br>
.^^^^^^^^^^^^++^^>+^^^++^^^^^^^^^`                               `|^^^^^^^.<br></br>
.^^^^^^^^^^+??^+?v>^^^>>^^^^^||^~.                              -^^^^^^^^^.<br></br>
.^|~|^^^^^^^^^>vv?>|^^+^^^^^^|~||.   .``````...                .^^^^^^^^^^.<br></br>
.^|~~|^^^++^>>vv???>>?+^+^^^^~~-`..`~^^+++++^|>~-.            .|^^+^^^^^^^.<br></br>
.^|~~~|^|+vvv???v????????+^^>~~~~|^+++^^^^^+^+>^+^^            |+|^^^^^^^^.<br></br>
.^||~~+???v?????????????+|>?v?~|^++^^^^^^^^^^^?^^+v.          .^+~>|^++^++.<br></br>
.^+^||v}v}v?????????????vrv?>?^|^^^^+^^^^^^^^^^>+^^`          .^^?v^^?v+++.<br></br>
.???>>?v?vv????????????v??vv???+^++^++^+^^^^^+^|+++-          .+^vv++?v+++.<br></br>
.+++^?vv+???????????????++>?v?vvr}>++++++++++^+?v}w>          .>+>++++++++.<br></br>
.^++?v?>^>?????????????>+vxuuwv??>+++++++++++^>xuuz-          .+>>>++++rv+.<br></br>
.^+?>+^|^>>>?????????>++>+?vxxzw?|^+++++++++++^rur?.          .?>++++++?>+.<br></br>
.|^^^^^^>^^++>>>>>>>+^+??.~-+}wuyv-|++++^++^++^??>~           .>+^^+^+++++.<br></br>
.^++++^|^|>>|^>+++++^`^~-  ..`~}wu?-|+++^^^++++|~|`           .+++>?^+++++.<br></br>
.^++++^^^|^>^~+>+++^+....``+^`.``vuv~|+++^^^^|~^?w`           .^+^>?>+++++.<br></br>
.^+>>>+^+vv^v^`+>++^..>?>}vr}v?~~.?x}-~^|||^^^}ux>            .^^^^?v+?}??.<br></br>
.vwxxxx}zyz>+|`?v++`-^vzzxxuxzxrwv>^zv-....-`^}}?.            .||^^>+vuuuu~<br></br>
.?rzuuuyyx}>-``?u?^`^zzuuuxxxxzw}}r}}}?`. ...`^|`             .~+v}zzxxzwz|<br></br>
.>>wuyyz}>~`...>uv`?rzxxxzwrrrrr}}}}vvw+`    ...              `^>>?uuxzwwz|<br></br>
-xxuuz?|-`..  .>w>>}}wwrr}}}}}}}}}}}}}rw|.                    -^?wwuzzzwwz|<br></br>
`xuz?-...     .^}vvv}}}}xz}rrrr}}}}}}}}w}-.                  -?}uuuzwvvrzw-<br></br>
`xz>~~^+?>++~+??wrwwwrrrzxxxuuxxzwwwwzwwx}vv?~              `wuuuuxzxzzxxw-<br></br>
`uw????vv???>????????>>>>>>>>>>>>>>>>?>>>>??^.              vuxuuuxxuuxxwv-<br></br>
`w}||||||||||||||||^^^^|||||||||||||||||||^-               ^wwzzxzzzuuuuux~<br></br>
.|^||||||||||||||||^||||^|>^|||||||||||||^-               .^+++>>>?wxxxxuu~<br></br>
.^^^^^^^^^^^^^^^^^^^^^^^^^>+|+^|^^^^^^^^^~               .?>++^|||^+++>>?v-<br></br>
.v}^^^^^^^^^^^^^^^^++++++^^^>?>^^^^^^^^+~                ~?>++^^^^^^^^^^^^.<br></br>
.??++++??+++++++++++++++++>v}++++++++++|.               `>>++>>+++++++++++.<br></br>
.>>>>>???>>>>>>>>>>>>>>>>vzx?>>>>>>>>>^.               .>}r>>>>>>>>>>>>>>>.<br></br>
.>>>>}ur}?>>>>>>>v??vvv}zxzzv>>v?>>>>+.               .^>zz?>>>>>>>>>>>>>>.<br></br>


            </div>,

            <div>

`??????????????????????????????????????????>>+|||||||||^?}}}}v??>>>>>>>>>>`<br></br>
`}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}v??>>++++^^^^^^+}zv>?}zxxzzwrvv??????-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>+^^++++++^^^^^^^?rzv++>?vv}}>>}vvvvv}-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+^^^^^^^^^^^^^+^++++>>>++++++++?vvvvvvv}-<br></br>
`vvvvvvvvvv}wvvv}}vvvv}}vvvvv??vv?~||||^^^^^^^^^^^++++++++++++++?}}vvvvvv}-<br></br>
`vvv?vvvvvvvvvvr}vvvvvvvvvvv???v}v>+^++?vv???>>++^+++++++++++++?}}vvvvvvv}-<br></br>
`}}vvvvvvvvvv}}}}vvvv}vvvvvvv??v}vvvvvv}}}}}}}rvv?++++++++++++>v}rw}vvvvv}-<br></br>
`}}vvvvvvv}r}v}}}}}}vvvv}}v}w??v}vvv}}}}}}}}}}w}}}}>+++++++++++v}vr}vvvvvv-<br></br>
`v}}vvvzr}wvvvvvvvvvvvvvvvrwz}vv}}}}}}}}}}}}}}w}v}zv++++++++++>v}}wvv}}vvv-<br></br>
`v}}}}rwwz}vvvvvvvvvvvv}rzw}}}}}}}}}}}}}}}}}}}}wr}rv++++++++++>}}wx}vwu}vv-<br></br>
`rwwwwwzvvwvvvvvvvvvvv}r}rrrr}}}}}}}}}}}}}}}}}}}rrw}>+^^^^^^^^+}}ww}}}w}vv-<br></br>
`rr}}rwrvvvvvvvvvvvvvv}vvv}wzzzzzzr}}}}}}}}}}}rwzxur++^^^^^^^^+rwwrr}}v}}v-<br></br>
`}}}rrvv????v?????vvvvvvvwxuuuxxxwr}}}}}}}}}}}wuuyuv^^^^^^+++^+wzzwr}}vwx}-<br></br>
`v}}vv?????????????????vvvrxyuuuz}r}}}}}}}}}}}}xyxz?^^^^^++++^+zzrr}}}v}r}-<br></br>
`}}vvv?????????????????vz?v}ruyyuzv}}}}}}r}}}}}zwrv^^^^^+++++++wr}}}}}}vvv-<br></br>
`}}}vvvv?vvvvvvvv???}v}?v+>?>}vuyywv}r}}}r}}rrr}v}v||^^++++^^^+}rrrw}}}}vv-<br></br>
`v}}vvv}}}}}vv????vvr^++>v?}rv?}vuyzv}}r}}}}}}}}wu?|^^^++^^^^^+}r}}rwr}r}}-<br></br>
`v}rr}}v}zzvrv>?????>+?}vzrzxxzw}}zuxvv}}}vv}rzuur^^^^+++^^^^^+}}vv}zrwuzr-<br></br>
`wzuuuxwzxzvv?>vv>?>?vrzzzzzxuuuuxxwuzv????vv}zxw?|^^^^++^^^^^>vv?vrrzuuuu|<br></br>
`zzxxxxxxzrv>++vxv>>vzzzzzzzzzuuxxxxuuzv?????v}}v^^^^^^++^^^^^>??rzuuuuxxu|<br></br>
`rrzxxxzrv>+^|^vxw?}zwzzzwwwwwzxxxxxxxuwv>>>>???>^^^^^^++++^^^?v?vwyuuxxxu|<br></br>
-xxxzwv?>+^||~^vz}vwwwwrrrrrrrrwxxxxxxxxr?>??>>>++^^^^^+++++++?vruuuuuxxxu|<br></br>
`xxwv>+^|~~~~~^?wwrrrwrwzwrrwwrrzxxxxxxux}?>??>+++^^^^^+++++^?wxyyyuuxxxxu|<br></br>
-uxv>>>?v???>?vvwwwwrrwwzwrwwzzwzxuuuuuuuxzwwv++++^^^^+++^^^+zyyuuuuuuxuuu|<br></br>
-uzvvvvvvvvvv????vvv?>>??>>>>>>>?}rwwwwrrrwwr>^^++++++++++^^}yuuuuuuuuuuxz~<br></br>
`xw>++^^^^+++^^^^^^^^^||^^>?>+||>vvv}}}}vv}v+^++++++++++++^?uuuuuuuuuuuuuu|<br></br>
`v?>^|^>+^|||||^^||^^^^|^^??v?>>?vvvvvvvv}v>++++++++++++++>}rrrrrrwxuuuuuy|<br></br>
`vv>+^++^^|^^^^+++^+++>>??vvv}vvvvvvvvvv}v>++++++++++++++>rrrrvvvvv}v}}rww~<br></br>
`wz?>?????v??>+>>>>>>>>??vvvvr}vvvvvvvv}v>+++++++++++++++vwwwr}}}}vvvvvvvv-<br></br>
`rrvvvv}rvvvvvv????????vvvvwwvvvvvv}}}}v>+++++++++++++++?}}}}}}}}}}}vvvv}}-<br></br>
`v}vvvrw}vvvvvvvvvv????vvrxurvvvvv}}}}v>+++++++++++++++>rww}}}wr}}}}}}}}}r-<br></br>
`}r}}zxzzw}}}vvvvwwv??vwxxxurv?vrvv}}}>^^^^+^^^^+++++++vrxxr}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}}}rrrrrrrwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwwzzzzzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuxzzzwwwwwzzzzzzzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyuuuxxzzzzzzzzxxxxxxxxxxuuuuxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyyuuxxxxzxxxxxxxxxxuuuuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuuxxxxxxxxxxxxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyuuuuuxxzzzzzzzzxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuyuuuzwr}}}}}}}}rrwzxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uuuyuzr}}}}}}}}}}}}}}rwxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuxw}}}}}}wzwr}}}}zxzwxuuuyyyyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
                  -uyuzwzuzrwxyuuuuxzzuyuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyyuu|<br></br>
                  -uuyuuyuuuuyxwrrwzuuyuwwzxxxyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyuuuuuyyyyuu|<br></br>
-uuuyyzwwzuzr}}}}}}rwr}}}}}rxyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyuuuuuuuuuyyyyuy^<br></br>
-uxxuzr}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyuuuyyyyyuuyyyuuuuyyuuuu^<br></br>
-uwrr}}}}}}}}}}}}}}}}}}}}}}}}wuyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyuyyyyuuuu^<br></br>
-xr}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyuuuy^<br></br>
-xr}}}}}}}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuu^<br></br>
-xr}}}}}}}}}}vv}}}}v}}}}vvv}}}vryyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu|<br></br>
-x}v}}vv}}}}v??v}}v??v}}v>>?vvv?xyyyyyyyyyyyyyyyyyyuuuyyyuuyyyyyyyuuuuuuuu|<br></br>
-u}v}v>>?v}}?>>>?vv>>>?v?>>?>??>wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu|<br></br>
-yr??>>>>???>>>>>?>>>>>>>>ruv>>+vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
-yw>>>>rv+>>>>??>>>>???>>vuyyw??vuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
-uu?>>v?||?>??vvv???vvvrwzyuyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uu}?}uzwzuwwr}rr}vv}}}rxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuxruuuyyyyuuuuuxzzwzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuuuuuuuyyuuxxuzrrrxxuuuuuxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
                  -uuuuuzzyuuuuuuxuuuwvvvzuuxuuxv?zxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}}}rrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzwwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuxzzzwwwwwzzzzzzzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyyuuxxzzzzzzzxxxxxxxxxxxuuuuxxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuuxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-uyyyuuuxxwwrr}}rrrwwzxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuyuxwr}}}}}v}}}}}}}rzxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uuuuzr}}}}}}wzwwr}}}}rrrzuuyyyyyyyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuwrzxw}}wxyyuuxwrwxuxzzuyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyuuu|<br></br>
                  -uuuxxyuuxxuyzwwzuuuuyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
-uuyyyxwzuyuz}}}}rwxuuzrwzxzuyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyuuuuuuuyyyyuu|<br></br>
-xzxyxr}}rwr}}}}}}}}rr}}}}}}wuyyyyyyyyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuyyyyuy^<br></br>
`r}rwr}}}}}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyyuuuu^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}wyyyyyyyyyyyyyyyyyyyyyuuuyyyuuuuyyyyuyyyyuuuu^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyuuyyyuuyuyyyyyyyyyuuuy^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyuuuuy^<br></br>
`}}}vv}}}}}}}v}}}}vv}}}}vv}}}}wuyyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu|<br></br>
`}}v??v}}}}}??v}}}???v}v?>?v}}}zyyyyyyyyyyyyyyyyyyyuuuyyyuuyyyyyyyuuuuuuuu|<br></br>
.?v?>>?v}vv?>>>?}?>>>?v?>>>>vvvwyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu|<br></br>
.>?>>??>?v?>>>>>?>>?>>>>?vzv>??}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.>>?vwzv>??>???>>??vv??>?wuu}??}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
`??vzxv>??vwwvvv?vvv}vvvruuuyxxxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-}vzuuxzxzxyuwrrrrrrrrrrwxuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -xzuuuuuyyyyuuuuuuuxxxxuxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuuyuuuuuuuuxxuxwrrzuxuuyuyxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuyz}uyuuuuuxxuyxv??}uuxxuxuv?wxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}}}rrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuxzzzwwwwwzzzzzxzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyyuuxxzzzzzzzxxxxxxxxxxxuuuuxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyyuuxxxxzxxxxxxxxxxuuuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxzzwwwwzzzxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-uyyyyuxwr}}}}v}}}}}rrzxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyyuxr}}}}}}}rwr}}}}}}rzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuzrrwr}}}wxuyuxwr}}}wrwxuyyyyyyyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
                  -uuuxxuyuwwxuuzwzuuxwwxyuzxyyyyyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uxuyuzzuyyuzr}}}wxyuuyxxuyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
-wwuuw}}rzxw}}}}}}}wuyxr}wxxxyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyuuuuuuuyyyyuu|<br></br>
`}}rr}}}}}}}}}}}}}}}rwr}}}}}wuyyyyyyyyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuyyyyuy^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyyuuuu^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyyyyuuuyyyuuuuyyyyyyyyyuuuu^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}wyyyyyyyyyyyyyyyyyyyyyyuuyyyuyyuyyyyyyyyyuuuy^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}wyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuy^<br></br>
`}}vv}}}}}}}vv}}}}vv}}}}vv}}}ruyyyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuu|<br></br>
`v?>?v}}}}v?>?}}v???v}}v??v}}}uyyyyyyyyyyyyyyyuyyyyuuuyyyuuyyyyyyyuuuuuuuu^<br></br>
`?>>>?}}v?>>>>vv?>>>?v?>>>?vv}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>vw?v?>>>??>>>>>??>>>?r?>>?vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
.?ruuv>>>}w}?>>>?vvv?????v?>?wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`xuur?>?zyyyx??v}}}vvvvvvrzxwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
                  -uuuxwrxyyyyuwwwzzzzwwwwrrzuyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyuuuyyyuuuuuuxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuuuyuuuuuuuxxuuwrrwxxxuuyyyxzuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuyr?zyuuuuuxxuuu}??vxyuxxxxu}>wuxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}}}rrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuuyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuxzzzwwwwwzzzzzxzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
                  -yyyuuxxzzzzzzzxxxxxxxxxxxuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyyuuxxxxzzzxxxxxxxxuuuuuxxxxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyuuuuxzzwwwwwzzzxxxxxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-uyyyyuxwr}}}}}}}}}}rrzxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyyuxr}}}}}}rwzwr}}}}}rzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuzrrwr}}rwxuyuxw}}}}wrwxuyyyyyyyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
                  -uuuxxuyuzwxuuzwzuuxwwxyuzxyyyyyyyyyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uxuyuzzuyyuzr}}}wxyuuyxxuyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
-wwuuw}}rzxw}}}}}}}wuyxr}wxxxyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyuuuuuuuuyyyyuy|<br></br>
`}}rr}}}}}}}}}}}}}}}rwr}}}}}wuyyyyyyyyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuyyyyuy^<br></br>
`}}vv}}}}}}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyuuuuu^<br></br>
`}}vvvvv}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyyyyyuuyyyuuyuuyyyyyyyuuuuu^<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}wuyyyyyyyyyyyyyyyyyyyyyuyyyyuyyuuyyyyyyyyuuuu|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}wuyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuu^<br></br>
`}}vv}}}}}}}vv}}}}vv}}}}vv}}}ruyyyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu|<br></br>
`vv??}}}}}v?>?}}v??>v}}v??v}}}uyyyyyyyyyyyyyyyuyyyyuuuuyyuuuyyyyyyuuuuuuuu^<br></br>
`?>>?v}}v?>>>>vv?>>>?v?>>>?vv}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>vw?v?>>>??>>>>>??>>>?r?>>?vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
.?ruuv>>>}wv?>>>?vvv?????v?>?wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`xuur?>?zyyyx??v}}}vvvvvvrzxwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
                  -uuuxwrxyyyyuwwwzzzzwwwwrrzuyuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyuuuuuyuuuuuuxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuuuyuuuuuuuzxuuwrrwxxxuuuuuxzuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuyr?zyuuuuxzzuuu}??vxyuxxxxu}>wuxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}rrrrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuuyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuuxzzwwwwwzzzzzxzzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
                  -uyyyuxxzzzzzzzxxxxxxxxxxxuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxuuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxzzwwwwwwwzzxuuuuuxxxxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuzwr}}}}}}}}}}}rwxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuzr}}}}}rrr}}}}}rwwrwxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-uyuxwww}}}}zuyuzwr}wuyuuxuyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyzwxyyxwwxyyuuyyxxuyuzzxuyxxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuyxuyyuyuwrrwxuyyyxr}}rwrrzyyyyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uuyuzrrwxuzr}}}}}rzxxr}}}}}}}}xyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyuuu|<br></br>
-zzxr}}}}}r}}}}}}}}}}}}}}}}}}}}wuyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
`}}vv}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyuuuyyyyyuuuuuuuuuyyyyuy|<br></br>
`}v>>?v}}}}}}}}}}}}}}}}}}}}}}}}}wyyyyyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuuyyyuy^<br></br>
-}?>>>??vvv}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyuuuyyyyyyuyyyuuuuyuuuuu^<br></br>
-r?+>??>>?v}}}}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyuuuyyyuuyuuyyyyyyyuuuuu^<br></br>
-xvrrr?>>?v}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyuyyyyuuyyuyyyyyyyyuuuu|<br></br>
-uuuuu}?>?v}}}}}}}v}}}}}}vv}}vvv}zyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuu^<br></br>
-uuuuyx?>?v}v?v}v???}}}v?>?vv?>?vwyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyyyv>?v?>>vv?>>>v}v?>>>??>>>?wyyyyyyyyyyyyuuyyyuuuuyyuuuuyyyyyuuuuuuuu^<br></br>
-uyyyyuv>>>>>>>>>>>>??>>>>>>>>>>?wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuuu?>>??>>>>>>>>>>>?v?>>>>>??xyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuux?>?}+^>>?v}?>>?vvvv??vvvxzyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuxvwv>>}vwxxrv??vwwwwwwrzuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
                  -uuuuuuuyyyyyuuuxwrrwuuyyuxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
                  -uuuuuuuyyyyw???vuuuyyuuuzxuuxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuyyyyx>|||~vyuuuuuxxuuzvvvzuuzzyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuyyyyyy}^|^^||wyuuuuzxuuzv??vzzv?uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}rrrrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuuyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuuxzzzwwwzzzzxxxxzzzxxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyyyuuxzzzzzzzzzzzzzxxxxxuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyyuuxzwrr}}rrrrrrwwzxxuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuzrr}}}}}}}}}}}rzzzzzuuuxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyuzr}}}}}}rzxwr}}}zyyuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yuwwzzr}}rwuyuyuxwwuyzwwxuuxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-xwwxyyuzwxyyzwzxuuyyuw}}}rwrrxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uxuyuzxyyyuwr}}}rwzxz}}}}}rr}rxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuzr}rwuxr}}}}}}}rrr}}}}}}}}}wuyyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uuw}}}}}}}}}}}}}}}}}}}}}}}}}}}}wyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyuuu|<br></br>
`wr}}}vvv}}}}}}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
`}v?v}?>??v}}vv}}}}}}}}}}}}}}}}}}wuyyyyyyyyyyyyyyyyuuuyyyyyuuuuuuuuuuyyyuy|<br></br>
`}v>?v>>?>>?vv?}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuuyyuuy^<br></br>
-r???>>????>??>v}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyuuuyyyyyyuyyyuuuuyuuuuu^<br></br>
-r?vz?>?vwrv?>>?}}}}}}}}}}}}}}}}}rzyyyyyyyyyyyyyyyyyuuyyyuuyuuyyyyyyyuuuuu^<br></br>
-xvruxrv}ww}?>>>v}}}}}}}}}}}v}}}}}wyyyyyyyyyyyyyyyyyuyyyyuuyyuuyyyyyyuuuuu|<br></br>
-uxxuuuwr???>>>>?v}}vvv}}}v??v}}vvwyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuu^<br></br>
-uuyyyyw}vr?>>>>>?vv?>?v}v?>>?vv??}yyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuu|<br></br>
-uyyyyyzrwv>>>>>>>?>>>>??>>>>>??>>}yyyyyyyyyyyuuyyyuuuuuyuuuyyyyyyuuuuuuuu|<br></br>
-uyyyyur}v>>>>>>>>>>>>>>>>??>>>>>?xyyyyuyyyyyyuyyyyyyuyyyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuuxv>+>>>>+>>+>??>>>>v}}v>?v?}yyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuux???>>>>???v}r}v??vwwrwwwwwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuxrur}x}zxrxuuzr}vrxuuuuuuxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
-uuuuuuuyuyyuuuuz}vvwuuuyuuuuxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuur+^^||?uyuuuuxuuur}}ruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyyuuz+|^+^^~}yuuuuzuuurv?vzyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuyyyyuuuxx?^^^++^~>uuuuxzxzwrvvv}zxuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}vvvvvvvvvvvvvvvvv}}rrrrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwzzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuuuyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwzwzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyy^<br></br>
                  -uyuxzzwwwwwwzzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuuxzzzwwwwwwwwwzzzzzzxxuuuuxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxzwrr}}}}}}zuuuuuuuuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyuxw}}}}wzwwwwuyxzzzzzzzzxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyuxw}}}}rxyuuuuuuyx}}}}}}}}wxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uuzzwr}rwuyxwrrrrrwr}}}}}}}}}rxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-xxuyuuxxyyzr}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
`xyuzwzxuuz}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uzr}}}}rr}}}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-w}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
`}}}}}}}}}}v???v}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyuuu|<br></br>
`}v}}}vvv}}?>>>>?v}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
`}v}}}?>?vv>>???>>?vvvv}}}}}}}}}}}}}xyyyyyyyyyyyyyuuuuyyyyyuuuuuuuuuuyyyuy^<br></br>
`??v}v?>>??>?v}v?>>?v?v}}}}}}}}}}}}}zyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuuyyuuy^<br></br>
.>>?vv>??>>??}wwv?>>>>?v}}}}}}}}}}v}wyyyyyyyyyyyyyyuuuyyyyyyuyyyyuuuuuuuuu^<br></br>
`}?>??>??rv}rwvvw}?>>>>v}}}}}}}}vv}}ruyyyyyyyyyyyyyyyuyyyyuuuuuyyyyyyuuuuu^<br></br>
-x}?>>>?vwww}??}wwv>>>>v}}}}}}}v??v}ruyyyyyyyyyyyyyuuyyyyyuyyuuuyyyyyuuuuu|<br></br>
-xr?>>>>vww}vvwzwrv>>>>v}v?v}v?>>>?}wyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyuuuuuu^<br></br>
-z}?>>>>?rwwwwwr}?>>>>>v?>>?v>>++>>vzyyyyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuu|<br></br>
`}?>>>>>vwwwwwrv?>>>>>>>+>>>>>>??>>vxyyyyyyyyuuuyyyyuuuuuuuuuyyyyyuuuuuuuu|<br></br>
`v?>>>+>vv?}zr?>>>>>>>++?>>>>?v}}v>}yyyuuyyyyyuuyyyyyyuyyyyyyyyyyyyuuuuuuu^<br></br>
`?>>>>?>+++>v?>>>?>>>>?>??>>?}zurv}xuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`>>>>ww>>>vz}>+>vr>>?ruz}rvvwxyyuxuuxxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>?wux?vzyy}}vrux}ruuw}v}wzyyuuyuxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
.>?zuuxzuyyyuyuuuuuuxv^|||||?uyuuxuuz}}rxyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`vxuuuuuyyyyyuuuuuuu?|||||||-}yuuxuuzv??}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyuuuuuuuu?||^^^^^|-vyyuxuyurv?vzyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuyyuuuuuuuuuuv^^^^^^^^^~vuxxzxzr}vvvvzuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvv}}rrrrrrrrwwwwwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwwzzzzxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzzxxuuuuuuuuuuyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwzzwzzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyy^<br></br>
                  -uyuxzzwwwwwwzzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uyuuxzzzwwwwwwwwwzzzzzzxxuuuuxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxzwrr}}}}}}zuuuuuuuuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyuxw}}}}wzwwwwuyxzzzzzzzzxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyuxr}}}}rxyuuuuuuyz}}}}}}}}wxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uuzzwr}rwuyxwrrrrrwr}}}}}}}}}rxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-xxuyyuxxyyzr}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`xyuzwzxuuz}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uzr}}}}rr}}}}}}}}}}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-w}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}zyyyyyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyuuuu|<br></br>
`}}}}}}}}}}v???v}}}}}}}}}}}}}}}}}}}xyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyuuu|<br></br>
`}v}}}vvv}}?>>>>?v}}v}}}}}}}}}}}}}}ruyyyyyyyyyyyyyyuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
`}v}}}?>?vv>>???>>?vvvv}}}}}}}}}}}}}xyyyyyyyyyyyyyuuuuyyyyyuuuuuuuuuuyyyuy^<br></br>
`??v}v?>>??>?v}v?>>?v?vv}}}}}}}}}}}}zyyyyyyyyyyyyyyuuuyyyyuuuuuuuuuuuyyuuy^<br></br>
.>>?vv>??>>??}zwv?>>>>?v}}}}}}}}}}v}wyyyyyyyyuuuyyyuuuyyyyyyyyyyyuuuuuuuuu^<br></br>
`}?>??>??rvrrwvvw}?>>>>v}}}}}}}}vv}}ruyyyyyyyyyyyyyyyuyyyyuuyuuuyyyyuuuuuu^<br></br>
-x}?>>>?vwww}??}wwv>>>>v}}}}}}}v??v}ruyyyyyyyyyyyyyuuyyyyyuyyuuuyyyyyuuuuu^<br></br>
-xr?>>+>vww}vvwzwrv>>>>v}v?v}v?>>>?}wyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyuuuuuu^<br></br>
-zv?>+>>?rwwwwww}?>>>>>v?>>?v?>++>>vzyyyyyyyyyyyyyyuuuuyyyyuuyyyyyuuuuuuuu|<br></br>
`}?>>>>>vwwwwwrv?>>>>>>>+>>>>>>??>>vxyyyyyyyyuuuyyyyuuuuuuuuuuyyyyuuuuuuuu|<br></br>
`v?>>>+>vv?}zr?>>>>>>>++?>>>+?v}}v>}yyyuuyyyyyuuyyyyyuuyyyyyyyyyyyyuuuuuuu^<br></br>
`?>>>>?>+++>v?>>>?>>>>?>??>>?}zurv}xuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`>>>>ww>>>vz}>+>vr>>?ruz}rvvwxyyuxuuxxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>?wux?vzyy}}vrxx}ruuw}v}wzyyuuyuxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
.>?zuuxzuyyyuyuuuuuuxv^|||||?uyuuxuuz}}rxyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`vxuuuuuyyyyyuuuuuuu?|||||||-}yuuxuuzv??ruyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyuuuuuuuu?||^^^^^|-vyyuxyyurv?vzyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuyyuuuuuuuuuuv^^^^^^^^^~vuxxzxzr}vvvvzuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvv}}vvvvvvvvvv}rwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
-yyuxzzwwwwwwwwwwwwzwwrrr}}}}}}}}}rwwzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwwwwwrr}}}}}}}}}}}rwxzzwwwwzyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwwwrr}}}rzxxzwrrrwuyuxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyy^<br></br>
-uyuxzzwwwwwwwwwxxwwxuuxuuuuuuyuwr}}rwzxuuyyxuyuyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uyuuxzzzwzzzzxuuyyyuzrr}rrwzxzr}v}}}}}}rwzzwrxyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxxzzuuuuwrzxxw}}}}}}}}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyuuuxxxuyxr}}}}}}}}}}}}}}}v???v}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuuuuzwzwr}}}}}}}}}}??vv}v>>>>>??v}}}}}}}}}}rxyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuw}}}}}}}}}}}}}?>>v}v?>??vv>+>??v}}vv}}}}ruyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuxr}}}}}}}vv}}}v>>>?v?>>+^^+~|+?>>?vvv}}}}}wyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyyyyuuz}}}}}}}v>?v}v?>>?v?>^^vzv>}zw}?+>>??v}}}}}zyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuur}}}v}}}?>??v?>>?vwv^~>yyy>~ryxv>>>>+?}}}}}ruyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuxr}v?>?}v>>???>>??}ww?z?>?+.`^zur?>>>??v}}}}}zyyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuuuyx}v?>>>??>>??}?>?vwwwxyx+`. `|vyr?>>>?v}}}}}}ruyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyuuyyzv>>>>>>>+>?vww}}}wwzuyyuv+~~^vz}?>>>?}}}}}}}ruyyyyyyyyyyuuuuyyyyuu|<br></br>
-uyuyyyz????>>>??^~+}wzwwwwwzxuuux}}wzxr?>>>?}}}}}}}rxyyyyuuuuuuuuuuuyyyuy^<br></br>
-uyuuuyxwzv?>>>?}~^?zvvzwwwwwwwwwwwwwwz}>>>>??v}}}}}}zyyyyuuuuyuuuuxuuuuuy^<br></br>
-uuuuuuyyx}?>>>?}?vu?-`vzwwwwwwwwwwwwww?>>>>>>v}v?v}}wyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuuyu}?>>>>?}xuu?|+wrwwwzxwwwwwww?>>>>>>>vv>>?v}wyyyyuuyyuuyyyyuuuuuu^<br></br>
-uuuuuuuyzv>>>>>>>vzuuzrwrrzxr>>vwwwwv>>>>>>>>?>++>?}wyyyyuuyyuuuyyyyuuuuu^<br></br>
`uuuuuuuu}?>>>>>>>>?wxzwwwwzv-->?}zwv>>>>++>>>>>??>>vwyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyyyyxv>>>>>>>>>>>}xzwwwwwv???vr?>>>>>>>>>>>vxuzvvwuuyyyyyyyyyyuuuuuuuu|<br></br>
-uyyyyyyw?>>>>>++>>>>>vzzzzzwzzwr}v?vv?????>>?wwrzyyzxuuyuuuuyyyyyuuuuuuuu|<br></br>
-uyyyyyyr>>>>+>??>>>>>>?vv}rrrrrr}vvv??vvvv}}xuuuuuyyuuuyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuuuu}>>>>?wur>>+>vxr?>>>?z}?>>?wz??vwxuuuuuyx}v}wxuyuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuv>>?ruuuw?>v?>xx?+>vuyz??ruuxrrzuuuuyyxv+~~||^>ryyyyyyyyyyyuuuuuuy^<br></br>
-uuuxxxx}>?zyuuuzvwu?--~+?vuyuuwuuuxwzuuuuuuuv+||||^|||^ryyyyyyyyyyuuuuyuy^<br></br>
-uxzwwwvv}zyyuuuuxuuz?>?wuxuuuuuuuu}ruuuuuyx>||^^^^^^^^|+xyyyyyyyyuuuuuuuy^<br></br>
`ww}rwwrwxyyyuuuuuuuuuuuuuuuuuuyuyr}uyuyyuz>||^^^^^^^^^^|vyyyyyyyyuuuuuuuu^<br></br>
`wwwwwwwwzxxuuuxzzxuuuuuuuuu}zyuxz}zzzuur?+^^^^^^^^^^^^^|+uyyyyyyyyyuuuuuu^<br></br>
`rrwwwwwwwwwzxuz>`~?xuuuuuuv^}zr}rwzzzv>|||||||||||||||||^zuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvv}}vvvvvvvvv}}rwwwwwwwwwwrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
-yyuxzzzwwwwwwwwwwwzwwrrr}}}}}}}}}rwwxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwwwwwrr}}}}}}}}}}}rwzzzwwwwzyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwwwrr}}}rzxxzwrrrwuyuxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyy^<br></br>
-uuuxzzwwwwwwwwwzxwwxuuuuuuuuuyuwr}}rwzxuuyyxuyuyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uyuuxzzzwzzzzxuuyyyuzrr}rrwzxzr}v}}}}}}rwzzwrxyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxxzzuuuuwrzxxw}}}}}}}}}}}}}}}}}}}}}}}}}rxyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyuuuxxxuyxr}}}}}}}}}}}}}}}v???v}}}}}}}}}}}}}xyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuuuuzwzwr}}}}}}}}}}??vv}v>>>>>??v}}}}}}}}}}rxyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuw}}}}}}}}}}}}}?>>v}v?>??vv>+>??v}}vv}}}}ruyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuxr}}}}}}}vv}}}v>>>?v?>>+^^+~|+?>>?vvv}}}}}wyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyyyuuuz}}}}}}}v>?v}v?>>?v?>^^vwv>}zw}?+>>??v}}}}}zyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuur}}}v}}}?>??v?>>?vwv^~>yyy>~ryxv>>>>+?}}}}}ruyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuxr}}?>?}v>>???>>??}ww?z?>?+.`^zur?>>>??v}}}}}zyuyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuuuyx}v?>>>??>>??}?>?vwwwxyx+`. `|vyr?>>>?v}}}}}}ruyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyuuyyzv>>>>>>>+>?vww}}}wwzuyyuv+~~^vz}>>>>?}}}}}}}ruyyyyyyyyyyuuuuyyyyuu|<br></br>
-uyuyyyz????>>>??^~+}wzwwwwwzxuuux}}wzxr?>>>?}}}}}}}rxyyyyuuuuuuuuuuuyyyuy^<br></br>
-uyuuuyxwzv?>>>?}~^?zvvzwwwwwwwwwwwwwwz}>>>>??v}}}}}}zyyyyuuuuyuuuuuuuuuuy^<br></br>
-uuuuuuyyx}?>>>?}?vu?-`vzwwwwwwwwwwwwww?>>>>>>v}v?v}}wyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuxuuuyyu}?>>>>?}xuu?|+wrwwwzxwwwwwww?>>>>>>>vv>>?v}wyyyyuuyyuuyyyyuuuuuu^<br></br>
-uuuuuuuyzv>>>>>>>vzuuzrwrrzxr>>vwwwwv>>>>>>>>?>++>?}wyyyyuuyyuuuyyyuuuuuu^<br></br>
`uuuuuuuu}?>>>>>>>>?wxzwwwwzv-->?}zwv>>>>++>>>>>??>>vwyyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyyyyxv>>>>>>>>>>>}xzwwwwwv???vr?>>>>>>>>>>>vxuzvvwuuyyyyyyyyyyuuuuuuuu|<br></br>
-uyyyyyyw?>>>>>++>>>>>vzzzzzwzzwr}v?vv?????>>?wwrzyyzxuuyuuuyyyyyyuuuuuuuu|<br></br>
-uyyyyyyr>>>>+>??>>>>>>?vv}rrrrrr}vvv??vvvv}}xuuuuuyyuuuyyyyyyyyyyyuuuuuuu^<br></br>
-uyuuuuu}>>>>?wur>>+>vxr?>>>?z}?>>?wz??vwxuuuuuyx}v}wxuyuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuv>>?ruuuw>>v?>xx?+>vuyz??ruuxrrzuuuuyyxv+~~||^>ryyyyyyyyyyyuuuuuuy^<br></br>
-uuuuxxx}>?zyuuuzvwu?--|+?vuyuuwuuuxwzuuuuuyuv+||^^^|||^ryyyyyyyyyyuuuuyuy^<br></br>
-uxzwwwvv}zyyuuuuxuuzv?vwuxuuuuuuuu}ruuuuuyx>||^^^^^^^^|+xyyyyyyyyuuuuuuuy^<br></br>
`ww}rwwrwxyyyuuuuuuuuyuuuuuuuuuyuyr}uyyyyuz>||^^^^^^^^^^|vyyyyyyyyuuuuuuuu^<br></br>
`wwwwwwwwzxxuuuxzzxuuuuuuuuu}zyuxz}zzzuur?+^^^^^^^^^^^^^|>uyyyyyyyyyuuuuuu^<br></br>
`rrwwwwwwwwwzxuz>`~?xuuuuuuv^}zr}rwzzzv>|||||||||||||||||^zuuuuuuuuxxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrrrrrwwwwwwwwwwwwwwwwwwwrrrw~<br></br>
-yyuxzzwwwwwwwwwwwwzzwwwwr}}}rwzzwr}}}rwxuxzzwwzuyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwzwwzuuxwwxuyuuuuzzwzuuuuuuuuxuyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwzzzzxuuuuxuyyuuxw}rwzxuyyxwrrrwzxuyyyyyyyyyyyyyyyyyyyyyyyuyy^<br></br>
-uuuxzzzwwwwwzzzzxuuuzrrrzxwrrr}}}}}rwwr}}}}}}rwzxuyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uyuuxxzzzzzzzzzwzuzr}}}}}}}}}}}}}}}}}v}}}}}}}}rrrwzzuyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxxzzzzzz}}}r}}}}}}}}}}}vvv}}}}v??vv}}}}}}rrrrrwuyyyyyyyyyyyyuuuuuuu|<br></br>
-uyyyuuuxxxxxzr}}}}}}}}}}}}}}v?>?v}}v?>>>??v}}}}}rrrrrwuyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuuuuxxxxr}}}}}}}}v?v}}}v?>>>v}v?>?v??>>?v}}}}rrrrrzyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxz}}}}}}}}v?>?}}v>>>???v?>>>?v>^>>??v}}}rrrrrxyyyyyyyyyyyuuuuxx|<br></br>
-uyyyuuuuxxxr}}}v?v}}?>>>vv?>>?v}?>+|+>-~+^^?>>>?v}rrrrrwuyyyyyyyyyyyuuuuu|<br></br>
-uyyuuuuuxxx}}}v?>>vv>>????>>?vrzr>|vyyw~?uw}?>>>>?}}rrrrxyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuxxxvv?>>>>>>>>?v}?>??}zzrv}?xx?.-wyw?>>>>>v}}rrrzyyyyyyyyyyyuuuuu|<br></br>
-uuuuuuuuuux?>>>>>>?>+>?rz}vv}wwwzuu+-` .~?yz?>>>>?v}}rr}ruyyyyyyyyyyyuuuu|<br></br>
-uyuuuuuuuux?>>>>>?vv|~^vrwwwzzwwxuuw|-.`~>uw?>>>?v}}}rrrrxyyyyyyyyyyyyyuu|<br></br>
-uyuuyuuuyyxv?>>>>?wr~?vuvvzwzzzzzxuuw>^^>}w}?>>>?}}}}rrrrzyyyyuuuuuuyyyuu|<br></br>
-uyyyyyyyyyzv?>>>>>?zvzz>~~wwwwzwwzzzzwwrwzzv>>>>v}}}}rrrrzuuuuuuuuuuuyyuy^<br></br>
-uyuuuuyyyur?>>>>>>>vzuy}-`?zwwwwwwwwwwwwrw}>>>>>?v}}}rrrrzuuuyuuuuuuuuuuy^<br></br>
-uuuuuuyyyzv>>>>>>>>>?zuuw?rr}wwzzxzwwwwrw}?>>>>>>?}}vvrrrwuyyyyyyuuuuuuuu^<br></br>
-uuxuuuuyu}?>>>>>>>>>>?xxzwwwrzzz}vvwwwww}?>>>>>>>>vv??vr}wuyyyuyyyyuuuuuu^<br></br>
-uuuuuuuyzv>>>>>>>>>>>>}uzwwwwzv||??}www}?>>>>>>>>>??>>?rrzyyyuuuyyyuuuuuy^<br></br>
`uuuuuuuu}?>>>>>>>>>>>>?rxwwwrrrv???vwwv>>>>+^+>>>>>????vrxyyyyyyyyuuuuuuu^<br></br>
-uyyyyyyxv>+>>>?rv>>>>>>?}zzzzwwww}}rwv>+>>>^~^>>????wzvvruyyyyyyyuuuuuuuu|<br></br>
-uyyyyyyw?>?vvwuuv>>>+>????vvrzxzzzzwv?>??????}?>?wzxuuzvwyyyyyyyyuuuuuuuu^<br></br>
-uuxxuuuwvvvwuuuu?>>>?^?ux?>>?rurvvvv}v}}???vwxwvzyuuuuuxuyyyyyyyyyuuuuuuu^<br></br>
-xzwzwrr}}}rzzxuuv?vzw|~+^+>vzyu}?vwxuw}}v}zuuuuxuuuuuuyyyyyyyyyyyyuuuuuuy^<br></br>
-uzwr}rwwwwwwxuuuwzuuu}?+?vryyuuzzuuuxzrrzuuuuuuuuw?+^^>vruyuyyyyyyuuuuuuy^<br></br>
`wwzrrwwwwwwzzxuuuuuuxxuxuuuuuuuuuuuuwwzuuuuuuuuw?|||||||^>zyyyyyyuuuuuyuy^<br></br>
-zwwr}rwwwwzzzzzxuuuuuxuuuuuuxzuuuuu}vzuuuuuuxxv+^^^^^^^^^^>uyyyyyuuuuuuuu^<br></br>
`rr}v?vvrwwzzzzzzxuxr}}wuuuur?wyuuuw?ryyuuuuxz?||^^^^^^^^++^}yuyyyuuuuuuuu^<br></br>
`uuzrvv?vwzwzzzzxuyu?-.`?xuv++xuuux}zxxuuuuz}>^^^^^^^^^^^^++>xyyyyyyuuuuuu^<br></br>
-xxzww}vvvwwwzwxuuuuy?`..~?^~>zwr}rrwwwwwwv+||||||||||||||^^^wuuuuuuxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvvvv}}}vv}}}}vvvv}}}}}vvvvv}rrrrrrrrwwwwwwwwwwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwzzzzzzxxxzxxxxuuxzxzzzxuuxzwwxuyyyuuuuyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyuxzzwwwwwwwwwwwwwwzzxuuxxxzzzxuuxwwwwwwzuuuuuxxuuyuuuyyyyyyyyyyyyyyyyyy^<br></br>
-uuuxzzwwwwwwwzzzzzxxzzzxxxzwrr}}}wwwzzw}}}rwzxxzzxxxuuyyyyyyyyyyyyyyyyuyy^<br></br>
-uuuxzzzwwwwwzzzzxxuzr}}}rr}}}}}}}}}}}}}}}}}}}}}rwzzzxxxuyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxzzzzzzzzzzzxw}}}}}}}}}}}}}}}}}vvv}}}}}vv}}}}rrwxxxxuxuyyyyyyuuuuuuu^<br></br>
-uyyyuuxxzzzzzzzwwr}}}}}}}}}}vvv}}}v??>?v}}vv??vv}}}}rwzxxxxuyyyyyyuuuuuuu|<br></br>
-uyyyuuuxxxzxzzzzr}}}}}vv}}v???vv}v??>>>?vv??????vv}}}rzxxxxxuyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxzzzwr}}}v??v}v??>>?????>?????>>?vv????v}}}rzxxzxxuyyyyyuuuxxx|<br></br>
-uyyyyuuuuxxxzzzr}}vv?>>?v??>?>??>>>??v??>+^>vvv????vv}}wzzzzxuyyyyyuuuuxx|<br></br>
-uyyyuuuuuxxxzzz}????>>?>??>??v???>??}r?+>+>|-^^+?????v}}wzzzzxyyyyyyuuuuu|<br></br>
-uyyuuuuuuuxzzz}?>>?>>??>>>>?}rvvv?vwxxw>?yyw|+v?>?????vvrzzzzxuyyyyyuuuuu|<br></br>
-uyuuuuuuuuuzzwv?>>>>>?vv?++?vwrr}rwwxxzv^wuv--vz}??????v}zxzzxuyyyyyuuuuu|<br></br>
-uyuuuuuuuuuxwv?>>>>>>?r}vr}x?vwwzxxrv}v?|^+-.-?zw???????}wxzzxxyyyyyyyuuu|<br></br>
-uyyyyyyyyyyxv??>>>??+^?wwuw}?vzzzxxz}vvv>|^-`~vzrv???vvv}wzzzzxyyyyyyyyuu|<br></br>
-uyyyyyyyyyyrv?>>>>??^>?zzxz>|?zzzzxxzw}rr??>+>}}v????v}}}rzzzzxuuuuuyyyuu|<br></br>
-uyyyyyyyyyx}?>>>>>?v?vv?vrur?vwwwwwwwwwwwzwr}}}v????vv}}}rzzzzzuuuuuuyyuy^<br></br>
-uyuuuuyyyur?>>>>>>>>vv}??}wzwwwrrwwwwwwwwwr}}}v?>>??vvv}}rzzzzzuuuuuuuuuy^<br></br>
-uuuuuuyyyw?>>>>>>>>>?vv}}zwwwwwwzxxxxzwwww}v}v?>>>????v}}}zzzzxuyuuuuuuuu^<br></br>
-uuxuuuuuxv>>>+>?vv?>>?vvvwzwwwwwwv?v}}www}vvv>>>>>??>>vvvvwzzzxuyyuuuuuuu^<br></br>
-uuuuuuxxz}?>>?vv}r?>>>?vv}zzzwrvv??vv}ww}vvv>>>>>>>?>>?v?vwwzzxuuyuuuuuuy^<br></br>
-uuuuuuxzr}vv?vvvrv>>>>>?vv}rwzw}}}vvv}r}}}v>+>>>>>???v???vrwzxuyyyuuuuuuu|<br></br>
-uyyyyuuzrvvvv}}ww?>+>>>>wuwv}}rzwwrwwr}vvv>>>>+^>?>?vzv>?v}rzuyyyuuuuuuuu|<br></br>
-uyuuuuuz}vvrwzxxw}??vrv|>v??vvwxuzwwr}vv????vv++?vvv}wwvrzwwwxyyyuuuuuuuu^<br></br>
-uyuuuxxwrrwzxxxxr}}wzwzr?>>?vxyxzvvwzw}v???v}r}vzuxrrxxxuyxzxuyyyyuuuuuuu^<br></br>
-uuzxzwrrr}rzzxxxr}wxx}}rwwwzuyuxwrxuuxz}vv}wzzxuuuxzxuuyuuuxuyyyyyuuuuuuy^<br></br>
-uuwwrrrwrvv}wxuxzxuuuzwrzxuyyyuuuuxuuuzrrzxuuuuuuuzrr}}rzuyyyyyyyyuuuuuuy^<br></br>
-uuxzzzzwrvvvrzxxuuuxxxuuuyyyuzxuuuuuuzwzxxuuuuyuwr}v?+^||+vwxuyyyyuuuuuuy^<br></br>
-uuxzzzzww}vv}wwzxuxxxxxxzzxwvvuuuuuuz}rzuuuuuuxr}?>^^^^^||^??wyyyuuuuuuuu^<br></br>
-uxxzzzzwww}vv}wwzxxxxxxwv??>>}uuyyuzrvwuuuuuuw}v>^^^^^^^^^^+>?uyyyuuuuuuu^<br></br>
-uuuxxzzzwww}vv}wzzuxw}rzr?|->zuuxuxxrzuuyyuxrv?^^^^^^^^^^^^^>?wyyyyuuuuuu^<br></br>
-xxxxxxzzwww}vv}}??wyxrr}w?..-}zw}rwwwwwwzzr}?>|||||||||||||^>?}uuuuxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvvvv}}}}}vvvwwrvvvvv}rrr}vvvvv}r}}vvrwwrr}vvrwwwwwrrrw~<br></br>
                  -yyuxzzwwwwwwwwwwwzzzzzzxxxxzrrwxzzwwrrrwzxzwrrrrwzxuuxuyuuuyyuxxyyyyyyyyy^<br></br>
-uyuxzzwwwwwwwwwwwwwwwzzxzwr}}}}}rrr}}}}}}}rrrwrr}rrwxuuxzzzzuuyuyyyyyyyyy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzzr}}}}}}}}}}}}}}}}}}}}r}}}}}}rrwwwwwwwzxuyyuyyyuyy^<br></br>
-uuuxzzzwwwwzzzzzzzzzzzw}}}}}}}}}}}}vv}}}}}}vvv}}}}}}}}}rwwrwwwwwzuxuuuuuy^<br></br>
-uyyuuxzzzzzzzzxxxxzxzw}}}}}}vvv}}v???v}}}v??>?}}}}}v??v}}rrrwwwwrwwxuuuuu|<br></br>
-uyyyuuuxxzzzzzxxxxxzw}vv}}v???v}v?>>?v}vv?>>>>?vvv??>>?v}}}}wwwwwwwzuuxxu|<br></br>
-uyyyuuuxxxxzzzxxxxzrv??vv??>>>v?>>???vv??>>??>>?v?>???>?vv}}rwwwwwwwxuxxx|<br></br>
-uyyyyuuuxxxxxxxxxz}v?>>??>>??>>>>??v??>>>>?vv?>>>>v}v??>??v}}rwwwwwwxuxxx|<br></br>
-uyyyyuuuuxxxxxxxw}?>>>>>>>>??>>>?v}rv?>>??vrwr?+^>v}rv??>??v}rwwwwwwzuuxx|<br></br>
-uyyyuuuuuxxxxxxw}?>>>>>>>>>vr?^|+?rw}???vrwr}v>>?^.`||???>??v}rwwwwwzuuuu|<br></br>
-uyyuuuuuuuxuuuwv?>>>>>>>>>>vw??vzr?rwr}rwzxwv}vzyz``?>|??>>>?vrwwwwwzuuuu|<br></br>
-uyuuuuuuuuuuuz}?>>>>>>>>>>>?wrurw?+wwwzzwzzxxr>rz?..?u}v?>>>>?}wwwwwzuuuu|<br></br>
-uyuuuuuuuuuyxrv>>>>>>>>>>>?vzuy}~.|xzzzzwrwzxv~~. .`?uxv?>>>??}wwwwwzuuuu|<br></br>
-uyyyyyyyyyyurv?v?>>>>>??????vxyx+-^zzzzxwrwzxr^|..`~wuz??>>?vv}rrwwwxyyuu|<br></br>
-uyyyyyyyyyywv>>?}?>>>?rr???+?zxuwv}wwwzzzwwwzxv>^||?xwv?>>>?}}}rrrrrxyyuy|<br></br>
-uyyyyyyyyyxr?>+>v}v?vwrv???>>wzzzzwwrrwwwwwwwwwwr}rw}?>>>>?v}}}rrrrrzyyuy^<br></br>
-uyuuuuyyyyr}}vv?vvr}}}???????wzwwwwwzzzzzzwwwwwrrrrv?>>>>?v}}}}rrrrrzuuuy^<br></br>
-uuuuuuyyyzv>?}rrr}r}}v?vv????rzzwwzzzr}rwwwwwwrrr}?>>>>>?vvvv}}rrrrrxuuuu|<br></br>
-uuxuuuuuxrvvrr}rwwr}}rrv??????rzzwrrr?>?vvrwwwrrv>>>>>>>??vvvvv}rrrwuuuuu|<br></br>
-uuuuuuuuuzwrrrwrrrrrrrrr}??}zrvvrzwrrrvvvvwwrr}?>>>>>>>>>>?vvv?}rrrzuuuuy^<br></br>
-uuuuuuuuuuxzwwwrwwrrrrrrrv|>rwv?vwxzwwwr}rwrr}?>>>???v?>>>>?vvvvrrwxuuuuu|<br></br>
-uyyyyyuxzzwrrrrrwwrrrrrrwx?~->vwxuywrwzzwwrr}?>+??^|^}}>>>>>?vvvrwzuuuuuu|<br></br>
-uyyyyyyuxxwrrr}vvrwwwzwrwzuzrzuyyyx}}wuxwvv??>>>?>~-+}r??v?+>??vrwxuuuuuu^<br></br>
-uyyuuuuuyyuzwwvvv}wzzzzwwwxuyyuuyyuuuyuxzv??v???v}wwzurv?v??wzvvrxuuuuuuu^<br></br>
-uuuuuuuuuuuzzwvvv}wzzzzzzxuuyuuuuuuuuuuxzv???v}wzxuuuur}wzxxuyx}wuuuuuuuy^<br></br>
-uuuuuuuuuuuzwwrvvvwwzzzzzxuyyyuxuuxxuuuuwr}}rzxuuuuuuuxuyyyyyuuzuyuuuuuuy^<br></br>
-uuuuuuuuuuuxwwwvvv}wzzxzzxxuzv?xuuuuuuuzwwzxuuuuuuuuxxxr???vwxyyyuuuuuuuy^<br></br>
-uuuuuuuuuuuxwwwvvvvrzzxxxuuv-`|ryuuuuurvvrxuuuuuuuxxz}?^|~~~|^?}uyuuuuuuu^<br></br>
-uuuuuuyyyyuxwwwvvvv??vuuuxxw|.-+xyuuu}??ruuuuuuuxzz}?^|^^^^^||^^vyuuuuuuu^<br></br>
-uuuuuyyyuuuxzwrv?>|--^wyuxxu}-``?xuuxv}xyyyyyyuxz}?^||^^^^^^^^+++zyuuuuuu^<br></br>
-xxuuuuuuuzrrxxxx>..``->uyuxzw>```?rwzrwrrzxxxxwr?|||||||||||||^+^}uxxxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvvvv}}}rr}vv}}vvvvvvv}}}vv}}}vv}}vvvrrr}}}}}rwrrrrrrrw~<br></br>
-yyuxzzwwwwwwwwwwwzzzzzzxxuuxzr}wr}}}}}}rwwrrrrrr}rwzzzxuuuuuyuxuyyyuuyyyy^<br></br>
-uyuxzzwwwwwwwwwwwwwwzzzxxxzw}}}}}}}}}}}}}}}}}}}}}}}}wzzwwwxxxxxxuuyyuyyyy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzxzwr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrwzxxxuuyyyy^<br></br>
-uuuxxzzwwwwzzzzzzzzzzzzzwr}}}}}}}}}vv}vvvv}vv}}vvv}}}}}}}}}}}}}}rzwzxxuxu^<br></br>
-uyyuuxxzzzzzzzxxxxxxzzw}}}vvvvvv?????v???vv??vvv??vvvvvvvvv}}}}}}}rwzzzzz|<br></br>
-uyyyuuuxxzzzzzxxxxxxzrvvvv??????>>?>>????????>?????????????vv}}}}}}}wzzzz|<br></br>
-uyyyuuuxxxxzzxxxxxxw}??v??>>>>?>>>>>>???>???>>>???>???>>????v}}}}}}}wzzwz|<br></br>
-uyyyyuuuxxxxxxxxxxwv?>>>>>>>>>>>>???????>>>???>??????????????v}}}}}}wzzwz|<br></br>
-uyyyyuuuuxxxxxxuxrv?>>>>>>>>>>>????v?vvv???v}}vvvvvvv?vvv?????v}}}}}rzzzz|<br></br>
-uyyyyuuuuxxxxxxz}v?>>>>>>>>??>+?>?rwr?v}v}}rwr}}}?~~^`~>?>>????v}}}}rzzzz|<br></br>
-uyyuuuuuuuuuuuz}?>>>>>>>>>+?v>?}zzvwz?vwwwwwzzrzuw>?w>.|>|>>>???vv}}rzzzz|<br></br>
-uyuuuuuuuuuuux}?>>>>??>>>>>?vvwwxz>???zzwwzxuxrxx?+?}?^>r?>>>>???v}}rzzzz|<br></br>
-uyuuuuuuuuyyurv>+>>>?v?+>?vv}rwwv?>?>?zzwwzxuzvv>..`~???w}?>>>????v}rzzzz|<br></br>
-uyyyyyyyyyyyw??v?>+>>vv??vv?v}wzvvv}?}zwwwzxux}v>```^??}wr?????vvv}}wxxzx|<br></br>
-uyyyyyyyyyyxv>>vrv?vvvwrvv?>?vrzzrwzwwwwwwwzzzw}v>|^??vrrv????vvvvvvrxxxx|<br></br>
-uyyyyyyyyyuw?>>?rzwrrrwvv?>>?v}wzwwwwrwrrwwwwwwwwwwwrv}v?>???vvvvvv}rxxxu|<br></br>
-uyuuuuyyyyzvrrrzzwwwwr}vvv???v}rzwwwwzzxxxxzzwwwwwzr}v?>>>??vv}}}}v}rzzxu|<br></br>
-uuuuuuyyyurvwwzzwwwrr}v}}vvv??}vrzwwzww}vvrwwwwwww}v?>>>>???vvvv}vv}wzzxu|<br></br>
-uuuuuuuyuxzxxxzwwwr}rwwr}vvvv?}w}rwww}vv??v}rwwwrv?>>>>>>?vvvvvvvv}rzzzuu|<br></br>
-uuuuuuuuuuxxzzwwrrwwwwwww}}wzv}rv}}wwwr}vv}}wwwrv?>>>>>>>>?????v}vvrzzxuy^<br></br>
-uuuyyyyuuuuxzwrrrrrrwwwwwrv}zv^+?}zxxwwwrrrwzw}v>>???vv>>>>???v???vwzzxuu^<br></br>
-uyyyyyyuuuxxxzrrww}}}wwwwzrv?vvwuyyxr}rzzzwwwv?>>?+|^vv>>>>>>??>??vzzxuuu|<br></br>
-uyyyyyyyuuuxxxr}}}}}}wwwwzxxzxyyyyuzwzuuuz}??>>>?>|`|vv??v>>>++>>?rzzxuuu^<br></br>
-uyyuuuuuyyyuuxr}}v}}}wwwwwxuuuuuyyyuyyuuuz}?v????vr}rwwwv?>?r}???vwzxuuuy^<br></br>
-uuuuuuuuyyyuuxr}}}}vvwwwwwxuuuuuuuuuuuuxxwvvvvvvrwxuuuzzw}rzxuzwrwzxuuuuy^<br></br>
-uuuuuuuuyyyuuuw}}}}}}rzwwwxuuuxrzuxxuuuuxw}}}rzxuuuuuuuuuuyyyyuxuzzuuuuuy^<br></br>
-uuuuuuuyyyyuuuz}vvvrwwwwzzzxxv+?wxuuuuuxzwwzxxuuuuuuuuuxw}}rzuyyuuuuuuuuy^<br></br>
-uuuuuuuyyyyuuxzrvv?vrrrruuuz}?-^?vxuuuz}v}rxuuuuuuuuuxrv?^~~~^>vzuuuuuuuu^<br></br>
-uuuuuuyyyyyuuxzrvv?+^>}ruyyurv|.+vwuuxv>?}xuyuuuuuyxwv>+^^^^^||~+vzuuuuuu^<br></br>
-uuuuuyyyuuzwwzzzrr+...?wwyyyu}?``?vxuw?vzuyyyyyyuxrv>^^^^^^^^^^||?}uyuuuu^<br></br>
-xxxuuuuuuu}?vwxyz}+...`?vxyuurv-.-+vzwrwrwxxxxxxrv>^||||||||||||~+vzuxxxx|<br></br>


            </div>,

            <div>

`rrr}}vvvvvvvvvvvvvvvvvv}}rrrrrrvvvvvvvv?????vvv}r}vvvvvvv}rr}}}rwr}vvv}rw~<br></br>
-yyuxzzwwwwwwwwwwwzzzzzzxxuuuuxzr}}}}}}}}}}}}}}}rrr}}}}}rwzxuyuuyuuuuxxxuy^<br></br>
-uyuxzzwwwwwwwwwwwwwwzzzxxuuxzr}}}}}}}}}}}}}}}}}}}}}}}}}}rrwxxuxwwwzxuuuuy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzxxxzr}}}}}}}}}}}}}}vv}}}}}}}v}}}}}}}rrrr}}}}rwxuuy^<br></br>
-uyuuxzzwwwwzzzzzzzzzzzzxzr}}v}}}}vvvv}vv???v}}}vvv??v}}}}}}}}}}}}}}}}rrwx^<br></br>
-uyyyuxxzzzzzzzxxxxxxxzzr}vvv?v}v?????v?>>??v}vv??>>??v}vvvv?v}}}}}}}}}}}r|<br></br>
-uyyyuuuxxzzzzzxxxxxxxw}vv?>??v??>>>>>>>>>??vv??>>>>???vv??>>?v}}}}}}}}}}}~<br></br>
-uyyyuuuxxxxzxxxxxxxzr}v?>>>>?>>>>?>+>>???v???>>>?????>???>>>>??v}}}}}}}}}~<br></br>
-uyyyyuuuxxxxxxxxxxz}v?>>>>>>++>>???>+?v}}}>>>>??vvv?>>??????>>??v}}}}}}}}~<br></br>
-uyyyyuuuuxxxxxxuxw}?>>>>>>>>>>>??v+|>v}rr}?>??v}rwrv>>?vwr}?>>>?v}}}}}}}}~<br></br>
-uyyyuuuuuxxxxxxxrv?>>>>>>>>>>+>??v>vzuz?vrvvvrzwww}v+|~-^>???>>>?v}}}}}}}~<br></br>
-uyuuuuuuuuuuxuxrv?>>>>>?v>++>?>??zxyrv>+wwwwwwwwwrrwzrv-..-~?>>>>?v}}}}}}~<br></br>
-uyuuuuuuuuuuuxrv>>+>>>>v}?>?vr??}xxw~.`>xzwwwwzxuuurzzv-|^}>>?>>>>?v}}}}}~<br></br>
-uyuuuuuuuuyyuwv>+??>????}}v}r}??}uxw^`|}xzwwwzxuyxr~``..^?uzv?>>>>>v}}}}}~<br></br>
-uyyyyuuyyyyyzv?>>?vvvrv?vwwr}?+?}xxxr?vwzzwwwwzuyxv-...~>wuzv>>>>??v}}}}r~<br></br>
-uyyyyyyyyyyuv>+?vrwzwwr}rr}}????}xzzzzwwwrwwwwzzxxr^~-~>}uzv?>>>?vvv}vv}w|<br></br>
-uyuyyyyyyyyw?>vrxuuuwwwrr}v}}vv?}zwwwwwwwrwwwwwwwwwrvvv}z}?>>>>?vvv}}vv}w|<br></br>
-uyuuuuyyyyurrzxxxzzzwwwrrwrrr}v?vwzwwwwwxxxxxxzwwwwwzzz}?>>>>>?v}}vvv}}}w~<br></br>
-uuuuuuyyyyuuuuuuxzwwr}}}wwwwr}}v?}wzwwwwv??v}rwwwwwww}?>>>>>>?vvvvvvv}}}z|<br></br>
-uuuuuuuyyyyyyuuxzwwrrwwwwwwwwrwzzv?}zzww}????vrwwww}?>+>>>??????vv}}vvvrx^<br></br>
-uuuuuuuuyyyyuuzwwrrrrrwwwwwwwrzr??>vwxxzwrv?vrwwwrv?>>>>>>??>>?vr}}}}}}wu^<br></br>
-uuuyyyyyuuuuuuxzrrrr}vvwwwwwwr}??}zuyzrwzwwrwwzwv???>v}>>>>>>?vvv???vvrxu|<br></br>
-uyyyyyyyyyuuuuuxwwrvvvvrwwwwwwwxyyyyuvvrxxzzzwrv>?>>>??>>>>>>>???>>?vrzxu|<br></br>
-uyyyyyyyyyyuuuuxwwrv?vvrwwwwzzxuyyyyuxuyyyx}??>>>??+|>??}}>>>>>++>>?vwzuu^<br></br>
-uyyuuuuyyyyyuuuxwwrv?vvrwwwwzzxuyyyyyyuuuuu}v?????}vvvwxxw>>v?>>>>>?}zuuy^<br></br>
-uuuuuuuuyyyyuuuxwwrv?vvrwwwwzzxuxuuuuuuxuuzvv??vvrwxuyuuxr?v}wrzzw?vwuuuy^<br></br>
-uuuuuuuuyyyyuuuxwwr}vvvrwwwwwzw?vuuuuuuuuxwr}}rwxuuyuuuuuzzxuyyuux}}xuuuy^<br></br>
-uuuuuuuyyyyyuuuxwwr}}}vv}wzzxxw+^vzxuuuuzwwwwzxuuuuuuuuuyyuuuuyyyuzxuuuuy^<br></br>
-uuuuuuuyyyyyuuuzwrvvvv?>?ryyyyx?.`>vuuurvvv}wuuuuuuuuuyuw?+++>?vrxuyuuuuu^<br></br>
-uuuuuuyyyyyxv??vwrv?|```~?uyyyu}`.`+wyr+>?}xuuuuuuuuyyw?^||||||~~|>zyuuuu^<br></br>
-uuuuuyyyuuxr?+>zyyur`.`|`>zyyyyx+..~vu?+vzyyyyyyyuyuw?^|^^^^^^^^^||?uuuuu^<br></br>
-xxxuuuuuuuz}+~?zyyuw`....~vuuuuxv...|}vrwwzxxxxxxxw?|~||||||||||||~+zuxxx|<br></br>


            </div>,

            <div>

`rrrr}vvvvvvvvvvvvvvvvvv}}}rrrrr}vv?????????????v}}}vvvvv?v}rr}v}rwr}vvv}r~<br></br>
-yyuxzzwwwwwwwwwwwwzzzzzxxuuuuuxw}}}}}}}}}}}}}}}}rrr}}}}}}}rxyyuuyuuuuxzxy^<br></br>
-uyuxzzwwwwwwwwwwwwwwzzzxxxuuxw}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rzuyuzwrwxuyuy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzxxxxw}}}}}}}}}}}}}}}vv}}}}}}vv}}}}}}}}www}}}}}rxuy^<br></br>
-uuuuxzzwwwwzzzzxxxzzzzzxzw}}}vv}}}vvvv}}v???v}}}}v???v}}}}}}}}}}}}}}}}}wx^<br></br>
-uyyyuuxzzzzzzzxxxxxxxxzw}}vv??v}vv?>??v?>>>?v}}v??>>>?v}}vvv?v}}}}}}}}}}r|<br></br>
-uyyyuuuxxzzzzzxxxxxxxwr}v??>??v?>>>>>>>>>>??vvv?>>>>>>?}vv?>>?v}}}}}}}}}}~<br></br>
-uyyyuuuxxxxzxxxxxxxxw}v?>>>>>?>>>>?>>>>???????>>>???>>?vv?>>>>?v}}}}}}}}}~<br></br>
-uyyyyuuuxxxxxxxxxxz}v?>>>>>>>>>>>?v>++?vrrv>>>>??vvv?>>>????>>>?v}}}}}}}}~<br></br>
-uyyyyuuuuxxxxxxxxw}v?>>>>>>>>>>>?}v^^??vrw}?>??v}rwrv>>>vwrv?>>>?v}}}}}}}~<br></br>
-uyyyuuuuuxxxxxxxr}?>>>>>>>>>>+>>>vvvxyu?>}rvv}rwzww}?+^-~>vvv?>>>?v}}}}}}~<br></br>
-uyuuuuuuuuuuuuxrv?>>>>>?vv?+>???>ruyz}?-+wwwwwwwwrr}}wz?..`~>??>>>?v}}}}}~<br></br>
-uyuuuuuuuuuuuurv?>>>+>>?}}v?vwr??ruyv. .vxzwwwwzxuyzzyx?`-?v>??>>>>?v}}}}~<br></br>
-uyuuuuuuuuyyyzv>+?vv?>>?}}}}wwv>?wuyw~.|zxwwwwwzuyuv~~~..~}uwv?>>>>?v}}}}~<br></br>
-uyyyyuuyyyyyxv?>>?}rrrv?vrrrr}?>?zxxu}?}zzwwwwwxuyu?`  .`>zuzv>+>>>?v}}}}~<br></br>
-uyyyyyyyyyyyr>>?vzxuzwr}rr}}}vv?vzzwwzwwwrwwwwwzxuuv~--~+ryx}?>>>?v?vvv}}~<br></br>
-uyuyyyyyyyyzv?}zuuuuxwwwrrrwwrrvvzzwwwwwwrrwwwwwwwww}v??rzr??>>>?vv}}vvv}~<br></br>
-uuuuuuyyyyyzxuuuxzzzzwwrrrwwwwrvvrzzwwwwzxxxxxzwwwwwwzzz}?>+>>>?vvvvv}}v}~<br></br>
-uuuuuuyyyyyyyyyuuxwwwr}v}wwwwwwrvvrzzwwww??v}rrwwwwwwwv?>>>>>>?vvvvvv}}}r~<br></br>
-uuuuuuuyyyyyuuuxxzwwwwwwwwwwwwwzxr??rzwwwv????}wwwwwv?>>>>?v??vvvv}}vvvrz|<br></br>
-uuuuuuuuyyyyyuxzwrr}}}}wwwwwwwww}??vrxzzwwv??vwwzw}?>>>>>>>>>>?vr}}}}}vrx|<br></br>
-uuuyyyyyuuuuuuuuzwwwrvv}wwwwwwwr}wuyyzv}wzwrrwzwr???>?r?>>>>>>vvvv??vv}zu|<br></br>
-uyyyyyyyyyuuuuuuxwwrv??}wwwwwwwxyyyyyr?vzxzwzww}???>>>>>>>>>>>>???>>?vrxu|<br></br>
-uyyyyyyyyyyuuuuuxwwrv??}wwwwwwzxyyyyyuuyyyuw???>>??>|>?+vv>>>>>>++>>?}wuy^<br></br>
-uyuuuuuyyyyyuuuuxwwrv??}wwwwwzzxuyyyyyuuuuuwv?>???vv??}zxx?>??>>>>>>vrxuy^<br></br>
-uuuuuuuuyyyyuuuuxwwrv??}wwwwwzzxxxuuuuuxxuxrv??vv}rzuuuuux?>?}}vwzv?vzuuy^<br></br>
-uuuuuuuuyyyyuuuuxwzwv??vrwwwwwwv?xuuuuuuuxzr}}rwzxuuuuuuux}wxyyuuuzvruuuy^<br></br>
-uuuuuuuyyyyyuuuuxwwwr}v?v}zxxxur+?zwxuuuzwwwwzxxuuyuuuuuyyyyyyyyyyxwxuuuy^<br></br>
-uuuuuuuyyyyyuwr}}rv?vv>|~+zyyyyx+`^+ruurvvvvwxuuuuuuuuuyx}?>>??vwxuuuuuuu^<br></br>
-uuuuuuyyyyyuv~`|}w}v^`...`}yyyyyv...>uw++>vzuyuuuuuuyyxv>||||||~~|+ryuuuu^<br></br>
                  -uuuuuuyyuuuxv~-?yyyy+..~..?uyyyyz~..-z?^?wuyyyyyyyuyxv+|^^^^^^^^^|~+uyuuu^<br></br>
`xxxxuuuuuuuxv~-vyyyy>.....~wuuuux>...>?vwwwxxxxxxxxv+~||||||||||||~~}uxxx|<br></br>


            </div>,

            <div>

`rrrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrr}vv??????????????}r}vvv?v?vrwrvv}rwr}vvv~<br></br>
-yyuxzzwwwwwwwwwwwwzzzzzxxuuuuuuxwr}}}}}}}}}}}}}}}}rrr}}}}}}rzuyuuuyuuyuxx|<br></br>
-uyuxzzwwwwwwwwwwwwwwzzzxxxuuxzwr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}wxuyuzwwzuuy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzxxuxzw}}}}}}}}}vv}}}}vvv}}}}}}}v}}}}}}}rwzw}}}}rzu^<br></br>
-uyuuxzzwwwwzzzzzxzzzzzzxxzr}}vvv}}}vv??v}vv???v}}}vv???v}}}}}}}}}}}}}}}}w|<br></br>
-uyyyuuxzzzzzzzxxxxxxxxzzr}}v???v}vv?>>?v??>>??v}vv?>>>>?v}}vvvv}}}}}}}}}}~<br></br>
-uyyyuuuxxzzzzzxxxxxxxzw}vv?>>>?v?>>>>>>>>>>???vv?>>>>>>?vvvv?>>?v}}}}}}}}~<br></br>
-uyyyuuuxxxxxxxxxxxxxzr}v?>>>>>>>>>>??>>>???v???>>>>???>>????>>>>?v}}}}}}}~<br></br>
-uyyyyuuuxxxxxxxxxxxw}v?>>>>>>>>+>>?v?+^>?}wrv>>>>??vv?>>>?????>>>?v}}}}}}~<br></br>
-uyyyyuuuuxxxxxxxxzrv??>>>>>+>>>>+>vv+^????rrv>>?v}rwrv?>>?}w}??>>>?v}}}}}~<br></br>
-uyyyuuuuuxxxxxxxz}v?>>>>>>>?>++>?>v}vzyuv^vr}v}rwzzwrv>^^^?}r}??>>?v}}}}}~<br></br>
-uyuuuuuuuuuuuuuz}?>>++>>>?v}?>?}}?}uux?>-^rwwwwwwwr}v}r}?`.-|???>>>?v}}}}~<br></br>
-uyuuuuuuuuuuuuzv?>>>??>>>?v}}vrw}?}uur` .>xzwwwwzzxuuzuyr~`>?~+?>>>>?v}}}~<br></br>
-uyuuuuuuuyyyyxv?>+??}}v?>?v}rww}??wuuz^`~}xzwwwwzuyyr|+>-.`?xwv?>+>>>?v}}~<br></br>
-uyyyyuuyyyyyu}>+>?vwzwrr}v}rwr}??vwzxxwv}zzwwwwwzuyu?`.  .^}yx}?>>>>>?v}}~<br></br>
-uyyyyyyyyyyyw??vrxuyyurrwwr}}rrr}}zzwwwwwwwrwwwwzxuu}~-``|vuuwv>>>??vvv}}~<br></br>
-uyuyyyyyyyyurrxyuxzxxzwwrrrrwwww}rzzwwwwwwwrwwwwwwwwzv?+?vxz}?>>>>?v}vvv}~<br></br>
-uuuuuuyyyyyuuyyyuuxzwwwrr}}rwwwwrrwzzwwwwzxxxxxzwwwwwwzzzwv?>>>>>?vvv}}v}~<br></br>
-uuuuuuyyyyyyyuuyyuxzwrrr}}wwwwwwwrv}zzwwwr??v}rwwwwwwwwrv?>>>>>>?vvvv}}v}~<br></br>
-uuuuuuuyyyyyyuuuxzwwwwwwwwwwwwwwwz}>?rzwwrv????rwwwwwrv?>>>?>>>?vvvvvvvv}~<br></br>
-uuuuuuuuyyyyyyyuuzwr}}vv}wwwwwwwwwvv}zxzzwrv??}wwwwrv???>>>>>>??v}}}}vvv}~<br></br>
-uuyyyyyyuuuuuuyuuuzwwrv?vrwwwwwwwzxyyy}?rzwrrrwwwr}v>?}?>>>>>>?v??vvvvvrw~<br></br>
-uyyyyyyyyyuuuuuuuuzwwv??vrwwwwwwzuyyyuvv}zzzzzwr}v?>>>>>>>>>>>>>>??>>?vwz|<br></br>
-uyyyyyyyyyyuuuuuuuzwrv??vrwwwwzzxuyyyyuyyyyxvv??>??>>?>>?>>>>>>>>>>>>?vwx|<br></br>
-uyuuuuuyyyyyuuuuuxwwrv??vrwwwzzzxyyyyyuuuuuuv??>??vv???v}rr???>++>>>>?}zu^<br></br>
-uuuuuuuuyyyyuuuuuxwwwv??vrwwwwzzzxuuuuxxxuuw}???vvrwzuuuuuxv??v?v}r?>?wxy^<br></br>
-uuuuuuuuyyyyuuuuuzwzwv??v}wwwwwwrzuuuuuuuxzw}v}rwzuuyyuuuuzrwxuuuuuw?vxuy^<br></br>
-uuuuuuuyyyyyuuxwrwwwrv????vwxxxxw}wvvuuuxwwwwzzxuuuuuuuuuyyuyyyyyyuxrzuuy^<br></br>
-uuuuuuuyyyyyuv|-~>>???^~~-+zyyyyx??~~wyx}vvvrzuuuuuuuuuuyx}??vvrzxuyuuuuu^<br></br>
-uuuuuuyyyyuuz| .>r}}?-....-ryyyyy?...?u?>>?}xuuuuuuuuuyxv+||||||||+?xyuuu^<br></br>
-uxxuuuyyuuuuz>-`}yyyx-.^~.~}uyyyyr`..|}+>}uyyyuyyyyyuz?+^^^^^^^^^|~~vuuuu^<br></br>
`xxzzxuuuuuxuz>`-ryyyz-.....|wuuuuz~...|?wwwxxxxxxxxwv^~||||||||||||~+wuxx|<br></br>


            </div>,

            <div>

`rrrr}vvvvvvvvvvvvvvvvvv}}}rrrrrww}vv??????????????vrrvvvvv??vrw}vvrwwr}vv~<br></br>
-yyuxzzwwwwwwwwwwwwzzzzzxxuuuuuuuzr}}}}}}}}}}}}}}}}}rrr}}}}}}rzuyuuyuuyyxz|<br></br>
-uyuxzzwwwwwwwwwwwwwwzzzxxxuuuxwr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rxyyxrrwxuy^<br></br>
-uuuxzzwwwwwwwzzzzzzzzzzxxxuxw}}}}}}}}}vv}}}}vvv}}}}}}}v}}}}}}}}rzzr}}}}rx|<br></br>
-uyuuxzzwwwwzzzzxxzzzzzzxxzw}}vv?v}}vv??v}vv???v}}}}vv???v}}}}}}}}}}}}}}}r~<br></br>
-uyyyuuxzzzzzzzxxxxxxxxxzwr}v??>v}vv?>>>v??>>>>v}}}v?>>>>v}}}}v?v}}}}}}}}}~<br></br>
-uyyyuuuxxxzzzzxxxxxxxxwr}v?>>+>v??>>>>>>>>>???vvv?>>>>>>?v}}?>>?v}}}}}}}}~<br></br>
-uyyyuuuxxxxxxxxxxxxxzw}v?>>>>>>>>+>??>>>???vv???>+>???>>>vv?>>>>>v}}}}}}}~<br></br>
-uyyyyuuuxxxxxxxxxxxz}v?>>>>>>>>+>>>v?+^+>vww}>+>>???vv?>>?????>+>?v}}}}}}~<br></br>
-uyyyyuuuuxxxxxxxxxw}v?>>>>>+>>>++>>r+~?v>^}z}>>??v}rwr?>>>vwrv?>>>?v}vv}}~<br></br>
-uyyyuuuuuxxxxxxxzrv?>>>>>>>v?+>>?>?r?wuyw`^rrvv}wwzzwr?^^|?vww??>>>?v}v}}~<br></br>
-uyuuuuuuuuuuuuuz}v?>+++>>+vwv>?}w??xuu??-.vwwwwwwww}vvvrv-.`~+???>>>v}v}}~<br></br>
-uyuuuuuuuuuuuux}?>>>v?>+>+?rwv}ww??xyu|  -xzwwwwwzzxurxyy|`~v`+??>>>>v}}}~<br></br>
-uyuuuuuuuyyyyu}?>++>}w}?>>>vwwwr?>vxuy>`.?uzwwwwwxuyz|+?|..~xwvv?>>>>?v}}~<br></br>
-uyyyyuuyyyyyyr?>>?vrwrwwrvvrrwwv??}zzxzvvwzwwwwwwxyyr~.  .-?yuz?>>>>>?v}}~<br></br>
-uyyyyyyyyyyyz??vrxyyyurrwwr}v}wr}vwzwwwzwwrrwwwwwzxuw|-``-+xyx}?>>>???v}}~<br></br>
-uyuuuyyyyyyywwuyuzzzzzwwwrrwwrwzw}zzwwwwwwr}wwwwwwwwzv?+^?zuwv?>>>?v}vvv}~<br></br>
-uuuuuuyyyyyuuyyyyuxzwwwrr}}rwwwwwwrzzwwwwwxxxxxzwwwwwwwwzz}?>>>>>?v}v}}v}~<br></br>
-uuuuuuyyyyyyyyuuuyuzwwrr}}rwwwwwwz}vwzwwww??v}rwwwwwwwww}?>>>>>>?vvvv}}v}~<br></br>
-uuuuuuuyyyyyyuuuxzwwrrrrwzwwwwwwwzr>>}zzwwv????}wwwwww}?>>>??>>?v}vvvvvv}~<br></br>
-uuuuuuuuyyyyyyyyuxwrrr}vvwwwwwwwwwvv}wxzzwwv??vwwzzw}?v?>>>>>>?vrr}}}vvv}~<br></br>
-uuyyyyyyuuuuuuyuyuzwww}??}wwwwwwwzxyyyw?vwwwrrwzwzwv>>v?>>>>>>?v??vvv?v}}~<br></br>
-uyyyyyyyyyuuuuuuuuzww}???vwwwwwwwxyyyur?vwxzzzzwr}v>>>>>>>>>>>>>>>??>>?}r~<br></br>
-uyyyyyyyyyyuuuuuuuwwwr???}wwwwzzzuyyyyuyyyyurvv?>??>>?>>>^+>>>>>>>>>>>?}z|<br></br>
-uyuuuuuyyyyyuuuuuuwwwrv??}wwzzzzzuyyyyyuuuuur??>>??v??>?vvr???>++++>>>vru^<br></br>
-uuuuuuuuyyyyuuuuuxwzwr???}wwwwzwzzuuuuuxxuuz}v???v}rzxuuuuyv>>????rv>?}xy^<br></br>
-uuuuuuuuyyyyuuuuuxzzz}???vwwwwwwrwuuuuuuuuzw}vv}rzxuuyuuuuu}vrxuuuyz?vzuy^<br></br>
-uuuuuuuyyyyyuuz}vwzzrv?????rxxxuz}wv?zyuxzwwwwzzxuuuuuuuuyyuyyyyyyuu}wuuy^<br></br>
-uuuuuuuyyyyyy?`..~+>?>^~~~-vyyyyyr?-.?yu}vvv}wxuuuuuuuuuyurv?v}rzxuyuuuuu^<br></br>
-uuuuuuyyyyuuu>..~r}vv-.....+yyyyy}...~uv>>?}zuyuuuuuuyyxv>^|||||||+?zyuuu^<br></br>
-uxxuuuyyuuuuuv~.>yyyy|.~^.~>zyyyyz-...v++vzyyyyyyyyuuxv+^^^^^^^^^||~>uyuu^<br></br>
`xxzzxuuuuuxxu?``?yyyu|.....`vuuuuu^...~?rwwzxxxxxxxxv+~||||||||||||~~}uxx|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}}}}}}}}vv??????????????????vv???????v???v}}}v?v-<br></br>
-yyuxzzzwwwwwwwwwwwzzzzzxxxzzzzzzw}}vvv?v}}}}vvv?????vvvv????vvrzrrxxzzzr}~<br></br>
-uuuxzwwwwwwwwwwwwzzzzzzzzzzzzwr}vv?????v}}}v?????vv???????vvvvvrwzwvvv}rw~<br></br>
-uuuxzzwwwwwwzzzzzzzwwwwwzzww}vv???????vvvvv????vvv?????vvvvv}r}v}}v???vvr~<br></br>
-uyuuxxzzzwzzzzzzzwwwwwwrr}vv???????????v????>???????>>?vrrwwwwv?????vvvvv-<br></br>
-uyyyuuxxzzzzzzwwwrrr}}}vv????>>????>>>>??>>>>++>vrr?~~+?rwwww}???vvvv}}r}-<br></br>
-uyyyuuuxxxzzwrr}}vv}}vv????>>>>??>>>>>>>+>??>^vwxzzr|~?vvrwwr???v}}rwwwwr-<br></br>
-uyyuuuuuxzwr}}vvvv}vvv???>>>>>>>>>>>>>>>>??vv}zrzzzv~->rrwr}vvv?vrwwwrv?v`<br></br>
-uyuuuxuxwr}}}}r}}r}vv??>>>>>>>>>>>>?>+++>vrwwwr>?v>~-~>wwwr}}vvvv}rwzxv>}~<br></br>
-uuuxxuuxzzzzxxxxxzzwr}vv?>>>>>>>>>>v>+?v??rzwww>||~|>?vzwwwww}}vvv}wrr?>r|<br></br>
-uxzzxxuuxxxxxxxxxzwrr}v?>+>?>>>>>>>v?wxur+vwwzxzv????vrrr}}rww}vvvv}>|^?x|<br></br>
`zzzxxxxxxxxxxuuxzwr}}v>>>+?v?>>?v?>}wwv?++rwwzxxz}}}}rwzw?>??v}}vvvv?>?rx|<br></br>
`zzxxxxxxxxuuuuuzwrr}v?>+>>>vv??vv??}ww>``+zzwzzxzzzxxzxuuv>?w?vvvvvvvvrxx|<br></br>
`xxxxzzzzxuuuuxwwww}??v?>>+>?vvvv?>?}ww?|~?zwwwwwwzxuxvvrv>>vxzr}vvvvv}wzz~<br></br>
-xxxxxxxxuuuuxwrwwrvvvvvv????vvv?>>?}}r}?v}rwwwwwwzuuzv>^|+>ryuxwr}vvv}rww~<br></br>
-xxxuxzzzxxxxxwzxzrrwwr?vvvvv?vvv??vvvvv}}}v}wwwwwzxxzv?+>??wxwv??v}}}}rww~<br></br>
-xxxuxzzuuyyyuuuyzrrwzzwwzzzzzzzzr?vvvvv}w}vvrwwwwwwwzr}vv}ww}v?+^?}rrrrrw~<br></br>
-xxxxzzxxuuyyyyyyyyuuuuuuxxxuuuuw}v?vvv}r}vv}rwzzwwwwwwwwzz}??>>>?}rwrrrrw~<br></br>
-xxxxxxxxuuyyyuuyyyyuuuuxxxxuuzrvvv??vrrvvv?>?vvrwwwwwwwww}v??>?v}rwwwwrr}-<br></br>
-xxzzxxuuyyyyyyyuuuuuuuuuuuuuw}vv}r}?vvvvvv?>>>?v}rwwwwr}vvv}vvv}rwwrr}vv?`<br></br>
-xxxxuuuuyyyyyyyyyuxxxxxzzxxw}vvvzuzwrrzzxxr?>>?vv}}r}}rrvvvvvv}rzzr}v?v??`<br></br>
`xxuuuyyyuuuuuuuuuuuuuuxzzxzzz}??vwxxuyuxxxwrv?vvv}}v??vvvvvvv??v}vvrr}}}r`<br></br>
`xuuuyyyyyyuuuuuuuuuuuxzwzxxxuwv?>?vruyxxxzr}vv}rrvv?????????>>>?}??}wwwwz-<br></br>
`xuuuyyyyyyyuuuuuuuuuuxzwwxuuuxzr}rwuyyyyyxzwrrwwv?v??v?v?>>??????^~|^+>?z|<br></br>
`xxxxxzzxuyyyuuuuuuuuuxzwzxuuuuxxuyyyyyyyuuuuzwwrvvvv???v}}w}rwrrv+~``->}u^<br></br>
`zxxzv???}xyyyuuuuuuxxxzwzxuuuxxxxuyyuuuxxxxxzwr}}}}}rzxuuuuzwwwzwrrv?vwuy^<br></br>
-uuuux}v??vrzuuuuuuxxxzwwwzxuxxxxxxuxuuuuuuxxzrrrwzxuuyyuuuuzzxuuuuyuwzuuy|<br></br>
-uyuuyuxrvvvvvrzzwxxxzzwwwzzxuuuuxr}}wxuuuuxzwwwxuuyuyuuuuyyyuuxzwr}v>vxyy|<br></br>
`zuyuuyyyxrv??~`+?vrrrr}vvvvzyyuzr}}vvwuuzw}v}zuuyuuuyyyyuz}???>???????vru^<br></br>
`}rxyyyyyu}?>>-.^vxxzzv?????wyxr}wzv??}uzv>?wxuyyyyyuuzw}?+^||||||^^+????v~<br></br>
`r}}wuux}???vrv^?ryyyy}?v}?vv}}rxux?+^+r}vrxyyuuuxzw}v?>^^^^^^^^^^^||^????`<br></br>
`?v???v?>?}rwz}??ryyyu}??>^|->v}}vrv>^^?vwwrwzzzrvv??>^||||||||||||||~>???`<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}vvvv?????????>????????+^^^+????>+^^^^^^>??????v-<br></br>
-yyuxzzzwwwwwwwwwwwzzzzzxzw}}}}}}}}vv??>?}}}}v??>>>>>?v??>>>>????>?}}}vv?v-<br></br>
-uuuxzwwwwwwwwwwwzzzzwwrr}}}}}}vv??>>>>>v}}v?>>>>>??>?>>>>>??????>vv??>>>?`<br></br>
-uuuxzzwwwwwwzzzzzww}}}}}}}vvv??>>>>>>>?}v?>>>>????>>>>????vvv}v>>?>>>>??>`<br></br>
-uyuuxxzzzwwzzzzwr}}vvvv???>>>>>>>>>>>>vv?>>>????+^+^~^?vrrwwww?>>>>??????.<br></br>
-uyyyuuxxzzzzzw}vvv??>>>>>>>>>>>>>>>>>>?>>>???~``>}w>..^?rzwww}>>???vvv}rv`<br></br>
-uyyyuuuxxxzr}v??>>>>+>>>>>>>>>>>>>>>>>>>>>?>|`?zyyyx``vw}rwwwvv?vv}rwzzzr`<br></br>
-uyyuzzxuxrv?>>++++>+>>>>>>>>>>>>>>>>>>>>>??v?ruxyyyv.`>yuxwwwwr}wwwwwr?+>.<br></br>
-uuxzwzuwv?>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>?vwzyx+?v+..`>yyyxzwwwwwwwzxy>.v~<br></br>
-uzwrrzxwwwwwzzzzzzzzwwwwv>>>>>>>>>>>>??>>vrzxyu+-`  .-?yyyxwwwwwwwzzv}~`}^<br></br>
-zrv}wxuuxxxxxuuxxxxxxxzv>>>>>>>>>>>>?vr}?vwwzuyw|~--~^zyuuxzwwwwwwz}..`~z^<br></br>
`vvvwzzzzzzzzxuuuxxxxxr?>+>>>>>>>>>>>???vvvwwwzxur??v}wzzzzwwwwwwwwz}|-|vy^<br></br>
`vvrzzwwwrwxuuuuuuuuxv>>>>>>>>>>>>>>??????vrwwwxxxzwzwwwwwwwwwwwrv}wwwv}yy^<br></br>
`zwwr}}v}wxuuxxxuyyx?>>>>>>>>>>>>>>>??????v}wwwwzzzwwwwwwwwwxxzzwrwwwwwzxu|<br></br>
-zr}}}rrwzwrrrzxuyz?>>>>>>>>>>>>>>>>>??????vwwwwwwwwwwwwwwwwzxuuuuxwwwwwzz|<br></br>
`zzwww}vvvv}rzuyyx?>++>>>>>>>>>>>>>>>>??????}wwwwwwwwwwwwww}vvv??vwwwzzzwz|<br></br>
`wwwwrvvwzxyyyyyurvvv}rrwwwzzzzzw?>>>>+>vrv??}wwwwwwwwwwwww}????^^}wwzzzzw|<br></br>
`wwww}}}}wxyyyyyuuyyyyyyyyyyyyyu}>>>>+>vv?>>??vrwwwwwwwwwww}?????}zwwzzzzz|<br></br>
`wwwwww}rzuyyyyyyyyyyyyyyyyyyyz?>++++?}v>++>>>?v}wwwwwwwwwzwv??vrzwwzzzzw}-<br></br>
`wwwwzzxuyyyyyyyyyyyyyyyyyyyyr?>+?v?vr?>>>>>>>>>?v}wwwwwwwwzwwrzzzzzww}v?>`<br></br>
`wwwwxuuuyyyyyyyyyyyyyyyyyyu}?>|~}yyu}}rwxx}>>>>>>??vrwwzzwwzzzzzzzrv?>?>>`<br></br>
`wwwzuyyyuuuuuuuuuuuuyyyyyyxzz?~-^}rvryyyyywv>>+>>?vv??v}rwwww}vv}vvwwwrrr`<br></br>
`wwwxyyyyyyuuuuuuuuuuuuyyyyyyyr~`..`|ryyyyz?>>>?}}?vv??????vv???vx>>zyyz}x~<br></br>
`wwzuyyyyyyyuuuuuuuuuuuuyyyyyyyr??>?wyyyyyr?v}zuywv}vvvvv???vv}}}v~``||~|z^<br></br>
`wwwzwvvvuyyyuuuuuuuuuuuuuyyyuuuuuyyyyyyyuzzxuuuuz}vv???v}rrzxxxuw^`...-vy^<br></br>
`}rzv-...~vuyyuuuuuuuuuuuuuyuuuuuyyyyyuuxxuxxxxxzwwr}}rzxuuuuuuuuuzv???}uy^<br></br>
                  `uyyyw+....~?xuuuuuuuuuuuuuuuuuuuuyxzuuuuuuuuxzwwwzxuuyyuuuuuuuuyyyyuuyyuu|<br></br>
-yyyyyy}|....`?wuuuuuxuuuuuuuuuuuzrvwuuuuuuuzrwzxuuuuuuuuyyyyuzw}?>^|~>wyu|<br></br>
.vuyyyyyuv-..  `ruuuuxuuuuuuuuuxv>vwxuuuuyxvv}zuuuuuyyyyuzrv?>^|||||||~|?z^<br></br>
 `>wyyyyyw|  ...vyuuuuuuuuuuuur>^?zuuuuyyuvvzuyyyyyyuzr?>^^|||^^^^^^^^^^~|`<br></br>
.>^+?xur^..`^???xuuuuuuuuuuxr?+>vwrzwwwzuwzuyyuuxwv?>^^^^^^^^^^^^^^^^^^^^~ <br></br>
.~^~`-|. `+?}rrwxuuuuuuuuw?>^||>+~>xwr}}}}}}rr}v?|~||||||||||||||||||||||~ <br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvvvvvv????>>>>>>>++>>>>>>>>>>^^^>>>>++^^||^^^>?????v-<br></br>
-yyuxzzzwwwwwwwwwwwwwwwwr}v??????????>>>???????>>>>>>>?>>>>+|+??>++?????vr~<br></br>
-uuuxzwwwwwwwwwwzzwww}vv??????????>>>>>>?????>>>>>>>>>>>>+|^?rww?|+?rv??vr~<br></br>
-uuuxzzwwwwwwzzzzwrvv??????????>>>>>>>>????>>>>>>>>>>>>>???vwzzw>|^^v}}}v}~<br></br>
-uyuuxxzzzzwzzzw}v???????>>>>>>+>>>>>>>??>>>>>>>>++++^+?v}wzrwr+--~^vwwwwr-<br></br>
-uyyyuuxxzzzzw}v????>>>>>>>>>????v>>>>>>>>>>>>^|^?vv?~~?vwxzv?+```~+}zzzxz~<br></br>
-uyyyuuuxxxw}v??>>>?????vv}}}}}}v?>>>>>>>>>>>+^?}www}|~vwrzx}v+|`-+vzxxuxz~<br></br>
-uyyuuxuuzrvv???vvv}}}}rrrrrrr}?>>>>>>>>>>>>??vrrxxx?--?uxxxwv??>?vrxxxw}}-<br></br>
-uyuuuuuxwr}}}}rrrrr}}}r}}rrr}?>>>>>>>>>>>>?vvww??v?^`~?uuuzzrvvvv}wzxur?w|<br></br>
-uuxxxuuxxzxxxxxxxxzzzzzzwrr}>+>>>>>>>>>>>>vv}ww>~-```~vuuuzzzwrwwwwzwwv?z|<br></br>
-xxzzxuuuuxxxxxxxxxxxxxxwrrv?>>>>>>>>>?v?>?vv}rw}+|~~|+ruxxzwwwwwwwww?>?vu^<br></br>
`zzzxxxxxxxxxuuuuxxxxxzrr}?>>>>>>>>>>>>????vv}rrwv???vvrwwwwwwwwwwwwwv??zy^<br></br>
`zzxxxxxxzxuuuuuuuuuxwrr}?>+>>++++++>??????vv}}rr}}vvvv}wwwwwwwwr}rwwwrrzx|<br></br>
-xxxxzzzxxuuuuuuyyyuzrr}?>>>>>????vvv}}}}???vvv}}}vvvvv}wwwwzzzwwrwwwwwwww~<br></br>
-xxxxxxxuuuuuuuuyyuzwwrv?vv}rrrwwwwzzzxzv???vvvvvvv}}}v}rwwwzzzzxzzwwwwr}r~<br></br>
-xxxxxzzzzxxuuyyyuzwwwwwwzzzzwwwwwwwww}??????vvvvvvrr}v}}wwr}}}}}}wwwzwr}r~<br></br>
-xxxxxzzuuuyyyyyyxxxxxxuuuuuuuuuuxwwwv>>?v????vvv}}}vvvv}}r}}vvv??rwwzww}}~<br></br>
-xxxxzzxxuuyyyyyyyyyyyyyyyyyyyyyxzzw?>>??>>????v}}vvvvvvv}}}vvvvv}wwwzzwr}~<br></br>
-xxxzxxzxuuyyyyyyyyyyyyyyyyyyyuxzw}?>>v?>?}r}?vv}r}vvvvvvv}}vvv}wwwwzzzwrv`<br></br>
`xxxxxxuuyyyyyyyyyyyyyyyyyyyyuzwwwv??v>^+rzww}?vr}vvvvvv}vv}}}rrwwzzwwr}}v-<br></br>
`xxxxuuuuuyyyyyyyyyyyyyyyyyyxzwr}zuuur?>?}xz}?>vv>>>??vvww}rrrrrwzzwr}}}vv-<br></br>
`xuuuyyyyyuuuuuuuuuuuyyyyyyuuuzvvrxxxzrv??v?+^>v?>??v?vvrwzxz}vvv}}}wwwwwz~<br></br>
-uuuuyyyyyyuuuuuuuuuuuuyyyyyyyx}v?vvrxxwvv?||>v}}}vrr}rrrwwwrv??vr??rzzw}z~<br></br>
-uuuuyyyyyyyyuuuuuuuuuuuyyyyyyuxwwrzuyyuzzv?vrzxuzrzzwzzwwwwwr}}}v^~~++^>w|<br></br>
-xxxxxzzzuyyyuuuuuuuuuuuuuuyyyuuuuyyyyyuuxwwwzxuuxxzwwwwwzzzxxxxzw>~```|vz|<br></br>
`zxxzv???}zyyyuuuuuuuuuuuuuyuyuuyyyyyyyuuuuzzxxxxxxxzzxxuuuuuuuuuxz}???}zx|<br></br>
-uuuux}???vrzuuuuuuuuuuuuuuuuuuuuuyuuyyuxzwzuxxxxxuuuuyyuuuuuxuuuuuuxxxuuy|<br></br>
`zuuuuuz}vv??vrxuuuuuuuuuuuuuuuuuxzwxuuxrvruxzzxuuuuuuuuuuuuuxxzw}vv?vrxyy|<br></br>
.>}xuyyyyx}??>>vzuuuuuuuuuuuuuuxw}rzzr}vvvrzwzxuuuuuuuyyuxwr}v??????????ru^<br></br>
 .~?wuyyyxv>>>??zuuuuuuuuuuuuuz}vvr}v??vv}rwxuyuyyyyuuzw}?>>>>>??????????v-<br></br>
.>|-|vzz}???vwwwuuuuuuuuuuuxz}v?vv??>>>?}zxuuuuxxwwrvvv?+^^^^>????????????`<br></br>
.+>-.`-|??}wzzzxuuuuuuuuuzrv?>+>+|+??>++>?rwwwr}?++>>???^||^>?????????????`<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvv}}vvv?????>++^^^^^^^^^^^^^^^^+??+^^^^^^^^~~~~^^||+>>>>?-<br></br>
-yyuxzzzwwwwwwwwwwwwr}}vv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^>^`~??+~`|++>?}w|<br></br>
-uuuxzwwwwwwwwwzzwr}v??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^``?xyuv..|w}?vwx|<br></br>
-uuuxzzwwwwwwzzzw}v?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??>}uyyx^.`-rxxzwz|<br></br>
-uyuuxxzzzwzzzw}v?>>>>>>>>>>>>>>>????????>>>>>>>>>>>>>>??vzwv}v- .`-ruuuuu|<br></br>
-uyyyuuxxzzzzrv?>+>>>>>?????vvv}rwv?????>>>>>>>>++>>>>>?vruz~`.  .`~ryuyuu|<br></br>
-uyyyuuuxxzwv?>>???vv}rwzzxxxxxuwv????>>>>>>>>>>>>>>>>>?}wxu>~`...-|wyuyux|<br></br>
-uyyyuuuxzrvvv}wzxxxxuuuuuuuuux}????>>>>>>>>>>>?vvv?>>?vrrzu}|~--~~>xuuuux|<br></br>
-uyyyyuuxzzxxuuuuxxxxxxuuuuuuxv???>>>>>>>>>>>>?????vv??vrrwwz?|~^+>rzzzzzz|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxxxuuuzv???>>>>>>>>>>>>>?????????vrrwwzzrv}rrrwwwwwz|<br></br>
-uyyyuuuuuuuxxxxxxxxxxxxxuuwv??>>>>>>>>>>>>>>??????????vrrwwwwwrrrrrrwwwxx|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxuur???>>+>>>>>>>>>>>>?vv???????vrrrwwwrrrrrrrwwwxu|<br></br>
-uyuuuuuuuuuuuuuuuuuuuuuu}??>>>>>?????????????vv???????vrrrrrrrrrrrrrrw}v}~<br></br>
-uyyuuuuuyyyyyyyyyyyuuux}???vv}}rwwzzzzzz}??????????????}rrrrrrrrrrrrrw}vv-<br></br>
-uyyyyyyyyyyyyyyyyyyyyuwrwzxuyyyyyyyyyyurv????>>????v???vrrrrrrrwwwrwwwv?v-<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxv?????>>>>>?rrv???}rrrrwwwrrrwwwv??-<br></br>
-uyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyw??????>>>+>?v??????v}rwwwrrrrrrww}??`<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyur????????>>?v?>>>?????v}rwrrrrrrwwwr??`<br></br>
-uuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyur?????ruuxv}rvv??????????vv}rrrrrwwwwvv`<br></br>
`xuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyu}vvv>^+xyyyx}zur>>>>??v?>>?v}v}wwwzzzwwr~<br></br>
-uuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyuxuur^|~+}xw?>wz?++>>+>}}?vvvvvrzzwwzzzzz|<br></br>
-uyyyyyyyyuuuuuuuuuuuyyyyyyyyyyyyyyyyz+~-`-^|~>zv>>?v?vvrzxuzv?vv}}rrr}rwz|<br></br>
-uyyyyyyyyyuuuuuuuuuuuuyyyyyyyyyyyyyyy}>|-``|>rwv}rwwwzxxuuuxvvvvvvv}vvv}r~<br></br>
-uyyyuuuyyyyyuuuuuuuuuuuyyyyyyuuuuyyyyuw????vwzzxxxxuuuuuuuuxw}vvvvvvvvvvv-<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuuuyyyuuuuyyyyuxwr}vvwxuuuuuuuuuuuuuxxzw}vvvvvvvvv-<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuuuuuuyyyyyyyyyyuwwzxxuuuuuuuuuuxxzzzzzwr}vvvv}r~<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuuuuuuuuuuyyyyxv?ruxxxuuuxxxuuuuuxzzzzzzzzwrrzuy^<br></br>
.?xuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuyuzw?^>xuxxxxxxxxxuuuuxzzzzzzzzzxuuyuy^<br></br>
 `|vuyuyyyyyuuuuuuuuuuuuuuuuuuuuuuuz}?+>+|vyuuxxxxxxxxuuyxrrrrrrrzxuuuuuuy|<br></br>
 .``+wyyyyyuuuuuuuuuuuuuuuuuuuuuuwv>+^++^>zuxxxxxxxxxuuyxv????v}zuuuuuuuuu|<br></br>
.>^-`-?xuuuuuuuuuuuuuuuuuuuuuuz}?>++++^^>}zzwwrr}}wxxuyuv+++>?wuyyyyyyyyuu|<br></br>
.??~`.`vuuxxxuuuuuuuuuuuuuxxr?>++^^^^^||||rzwwwrvvv}wxxz>^^+}xuxxxxxxxxxxx~<br></br>


            </div>,

            <div>

`rwwr}vvvvvvvvvvvvvvv}}v??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>>?-<br></br>
-yyuxzzzwwwwwwwwwzzzwrvv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vrx|<br></br>
-uuuxzwwwwwwwwwzzzzrv?>>>>>>>>>++++++++++++>>>>>>>>>>>>>>>>>>>>>>>>>>??rxy^<br></br>
-uuuxzzwwwwwwzzzzwv>>+++>>>>>??????vvvvv}}}?>>>>>>>>>>>>>>>>>>>>>>>+>?vwxy^<br></br>
-uyyuxxzzzzzzzxzv?>>>??vv}rwzzzxxxxuuuuyyxv>>>>>>>>>>>>>>>>>?vv?>>>+??vwxy^<br></br>
-uyyyuuxxzzzzzzv??vrwxxuuuuuuxxxxxxxxuuxv?>>>>>>>>>>>>>>>>>??vv}rv?>??}wzu^<br></br>
-uyyyuuuxxxxxzwwxxuxxxuuuuuuxxxxxxxxuxr?>>>>>>>>>>>>>>>>>>>?????vvrv?vrwwz|<br></br>
-uyyyuuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuw?>>>>>>>>>>>>>>>>>>>>???????????vrwwz|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxuuuuuuuuuuyzv?>>>>>>>>>>>>>>>>>>>>>???????????v}wwz|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxxuuuuuuyyur?>>>>>>>>>>>>>>>>>>>>>>?????????????}wwz|<br></br>
-uyyyuuuuuuxxxxxxxxxxxxxxxuuuyyz?>>++++++++++>>>>>>>>>>>>?????????????}wwz|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxuuuuyur?>>????vvvv}}}rrr}?>>>>>>>>????????????vwwz|<br></br>
-uyuuuuuuuuuuyyyyuuuuuuuuuuyuwrrzxuuyyyyyyyyyyyy}>>>>>>>>>>???????????vrwz|<br></br>
-uyyuuuuyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyyyyyyyy}>>>>>>>>>>>>>????vv????}ww|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyr>>>>>>>>>>>>>>>>?}w}????vww|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyr?>>>>>>>++>>>>+>?vv?????vvvv~<br></br>
-uyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyx?>>>>>>>???>>>?v}v?>>>>>>????`<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuv>>+>>>>}uyyx}wwx}>>>>>>>>????`<br></br>
-uuxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuv>??vv+zzxyyyy}vxw???>>>>>???>>`<br></br>
`xuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyurv}rx?~^|^}zwzv?}??}vv??vzxz?>?`<br></br>
-uuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxwrr^~`..```~?+???v}wzwwxuxwzu|<br></br>
-uyyyyyyyyuuuuuuuuuuuyyyyyyyyyyyyyyyyuuxxxzzzwrr?|~|+-```^?}rr}vvvwzxxxuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuyyyyyyyyuyyyyuxzzwr}vv}rr}v>?}?+^>vwxuuuxxxxxxxuuuy^<br></br>
-uyyyuuuyyyyyuuuuuuuuuuuyyyyyyuuuuyyyyurv???v}rrrr}rzwzrrxuuuuuuuuuuuuuuuy^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuuuyyyuuuuyyyyyuzrv??vrrrrrrwr}rxuuxuuuuuuuuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuuuuuuuyyyyyyyuuuzw}}}}}}vv>>zuuuxuuuuuuuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuyyyyuuxxwvvvv?>^|`?uuuuuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuyyyuuyuv?>>+^|^~-zyuuuuuuuuuuuuyyuuuu|<br></br>
                  -uuuuuuuyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyw?^^^^^|`+uuuuuuuuuuuuuuyyyuyx~<br></br>
-uuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyuz}?+^^^^^^~`?yuuyyyyyyyyuuuuuuyuv`<br></br>
-xxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxzzxzrv>^^^^^^^^^^~`vyuxxxxxxzzzuyyyyyyx>.<br></br>
-xzzxxxuuuuuuuuuuuuuuuuuuuuuxxxxx}vvv>^||||||||||||-`vxxzwr}}}}}vrxxxxxuw^.<br></br>


            </div>,

            <div>

`wwrr}vvvvvvvvvvvvvvv}}v??+^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^+>>>?-<br></br>
-yyuxzzzwwwwwwwwwzzzzrvv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vrx|<br></br>
-uuxzzwwwwwwwwwzzzzrv?>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??rxy^<br></br>
-uuuxzzwwwwwwzzzzwv>>>>>>>>>>?????vvvv}}rrr?>>>>>>>>>>>>>>>>>>>>>>>+>?vwxy^<br></br>
-uyyuxxzzzwzzzxxv?>>>??vv}rwzzzxxxxuuuuyyx}?>>>>>>>>>>>>>>>>?vv?>>>+??vwxy^<br></br>
-uyyyuuxxzzzzzwv??vrzxxuuuuuuxxxxxxxxuux}?>>>>>>>>>>>>>>>>>??vv}rv?>??}wzu^<br></br>
-uyyyuuuxxxxxzwwxxuxxxuuuuuuxxxxxxxxuxr?>>>>>>>>>>>>>>>>>>>?????vvrv?vrwwz|<br></br>
-uyyyuuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuwv?>>>>>>>>>>>>>>>>>>>???????????vrwwz|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuyzv?>>>>>>>>>>>>>>>>>>>>>???????????v}wwz|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxxuuuuuyyyur??>>>>>>>>>>>>>>>>>>>>>?????????????}wwz|<br></br>
-uyyyuuuuuuxxxxxxxxxxxxxxxuuuyyzv?>>>>>>>>>>>>>>>>>>>>>>>?????????????}wwz|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxuuuuyur?????vvvvv}}rrrrrv>>>>>>>>>????????????vwww|<br></br>
-uyuuuuuuuuuuyyyyuuuuuuuuuuyuwrwzxuuyyyyyyyyyyyyv>>>>>>>>>>???????????vrww|<br></br>
-uyyuuuuyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyy}>>>>>>>>>>>>>????vv????}ww|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuur>>>>>>>>>>>>>>>>?}w}????vwz|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyu}?>>>>>>>>>>>>>+>?vv?????v}v}~<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyx?>>>>>>>????>>?v}v?>>>>>>????`<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuxv>>+>>>>}uyux}wwx}>>>>>>>>????`<br></br>
-uuxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuw?>??vv+wzxyyyy}vr}????>>>>???>>`<br></br>
`xuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyurvv}u?~^^+}zww}?v??}}v??vzxz?>?`<br></br>
-uuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxwwrvv}r^~``.```-??????v}wrwxuxwzu|<br></br>
-uyyyyyyyyuuuuuuuuuuuyyyyyyyyyyyyyyuxw}vv??vvv}}?|~|+-```^?vv}v??vwzxxxuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuyyyyyyyyuuyyurv??????}}}}v?>?}?+^>vwxxxxxzzxxxxuuuy^<br></br>
-uyyuuuuyyyyyuuuuuuuuuuuuuyyyyuuuuyyyuwv?>>?}}}}}vv}wwzrrxuuuuuuuuuuuuuuuy^<br></br>
-uyuuuuuuyyyyuuuuuuuuuuuuuuyyuuuuuyyyyyyxwvvvvvv}}v}r}}rxuxxxuuuuuuuuuuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuuuyuuuuyyyyyuuuxvvvvvv}???>>zuuxxxuuuuuuuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuyyyyuuzv?????v?+^~`?uuuuuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuyyyuuuz?>>++^|^~-zuuuuuuuuuuuuuyyuuuu|<br></br>
                  -uuuuuuuyyyyuuuuuuuxuuuuuuuuuuuuuxuuuuuyyyyy?^^^|^^|`+uuuuuuuuuuuuuuuyyuyz~<br></br>
                  -uuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyuzr?+^^^^^^~`?uuuuuuuuuuuuuuuuuuuv`<br></br>
-xxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxzzxz}v?+^^^^^^^^^~`vuxxxxzzzzwwxuyyyyyx>.<br></br>
-zzzxxxuuuuuuuuuuuuuuuuuuuuuxxxxx}vvv?^^^^^^^||||||``vxxzwr}}vvvvrxxxxxuz+.<br></br>


            </div>,

            <div>

`wwrr}vvvvvvvvvvvvvvvvvv}}}}v?>>>?>>>????????????????????????????>^^^^^^^^.<br></br>
-yyuxzzzwwwwwwwwwzzzzzzzxxzrv?????????vvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>.<br></br>
-uuuzzwwwwwwwwzzzzzzzzzzzz}vvvvv}}rwwzzzxxxxxxxxxxxwvvvvvvvvv??>>>>>>>>>>>.<br></br>
-uyuxzzzwwwwzzzzzzxzzzzzzzzzzxxxuuuuuyyyyyyyyyyyyuzrvvvvvvv??>>>>>>>>>>>>>`<br></br>
-uyyuuxxzzzzzzzxxxxxzxxxxxxuuxxxxxxxxuuuyyyyyyyuzrvvvvvvvv?>>>>>>>>>>>>>>>`<br></br>
-uyyyuuuxxzzzzxxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyuz}vvvvvvvvv?>>>>>>>>>>>>>>>?`<br></br>
-uyyyuuuxxxxxxxxxxxxxxuuuuuuxxxxxxxxxuuyyyyuz}vvvvvvvvvvv?????????????????`<br></br>
-uyyyyuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuuuyyyuz}vvvvvvvvvvvvvvvvvvvvvv?????vvv`<br></br>
                  -uyyyyuuuuxxxxxxxxxxxxxuuuuuuuuuuuyyyyyuxrv?????vvvvvvvvvvvvvvvvv??????vvv`<br></br>
-uyyyyuuuuuxxxxxxxxxxxxxuuuuuyyyyyyyyyuzr}}v?>????vvvvvvvvvvvvvvvvv????vvv`<br></br>
-uyyyuuuuuuuuxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyuxr???}rwzzzzxxxvvvvvvvv??????v`<br></br>
                  -uyuuuuuuuuuuuuuuxxxxxxxxuuuuyyyyyyyyyyyyyyyyxv??vwxuuuuyurvvvvvvvvvv?????`<br></br>
-uyuuuuuuuuyyyyyyuuuuuuuuuuyyyyyyyyyyyyyuuuyuu}???vrwwwzwv>??vvvvvvvv?????`<br></br>
-uyyuyyuyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyzrzuuz}rr}}rrwrv?????????????????`<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyur}zxuxxxzzzxxv??????????????vvv?`<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxzxxxxuyuuyyuuuur??????????vvvv}rw}v-<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyurvrxzxuuxzuyux}?>>>???>>?wuuuuuuzw}-<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyurv}}zxwrrzxz}???????>^>vxuuuuxwzwv`<br></br>
-uuxuuuuyyyyyyyyyyyyyyyyyyyyyyyuuuuxzwr}}vv}rr}}}}}}vvvv}}v+~+rwvrww}?vwr?`<br></br>
-xuuuuuuuyyyyyyyyyyyyyyyyyyyyyuxr}v?????v}}}}}}}}}}}}}}}}rv+|>vr???+++>v}}-<br></br>
-uuuyyyyuuuuuyyyyyyyyyyyyyyyyyyx}???????}}wr}}}}}vvvvvvvv}v>|+?vv}wv???wuu^<br></br>
-uyyyyyyyyyuuuuuuuuuuuyyyyyyyyyyyx}?>>>v}}}}}}}}}vvv}vvvvv?>>+++>?}wwrzuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuyyyyyyuuuyyuwv?v}}}}}}}}}vv}wr}vvv>++>>+>vrzxxuuuy^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuyyyyyuuuuuyyuzv}}}}v}}}}vv}zzrv??>>>????wyxxuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuyyuuuuuuuuxrvvvvvvvvv}}vvvv?>>?????v??vvvuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuyuuyuuuuyyuzv??vvvvvvvvv?????>>>?v?>??>^+wyuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuyyyuw????vv????>>>>>??>>>+++>+|vyuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuyux????>>?>>>++++>?>+^^^+>+|^zyyyyuuy^<br></br>
-uuuuuuuyyyyuuuuuuxxuuuuuuuuuuuuuuuuuuuuzxx}?rw>++>++++++^^+>+|~~?uuuuyuuy^<br></br>
-uuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyuzz?????>+>>>>>>>>+^~-?uuuuuuuy^<br></br>
-xxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyuzwwr}v?>>>+>>>>>>>>>+|-vyyyyuuuy^<br></br>
-xzzxxxuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxuuuuz}vv?>>>>>++>>>>>>>>>+^|}uxxxxxxx|<br></br>


            </div>,

            <div>

`wwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwv>^^^^^^^.<br></br>
-yyuxzzzwwwwwwwwzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyu}?>>>>>>>>.<br></br>
-uuuxzwwwwwwwwzzzzzzzzzzxxxuuuuuuuuuuuyyyyyyyyyuyyyyyyyyyyyyyur?>+>>>>>>>>.<br></br>
-uyuuxzzwwwwzzzxxxxzzzzzxxuuuuxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyw?>>>>>>>>>>>>`<br></br>
-uyyuuxxzzzzzzzxxxxxzxxxxxuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyxv>>>>>>>>>>>>>>`<br></br>
-uyyyuuuxxzzzzxxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyxv??>>>>>>>+>>>++.<br></br>
-uyyyuuuxxxxxxxxxxxxxxuuuuuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyuuuuxxxzzwwr}}}v?`<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyu}wuyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyyuuuuuxxxxxxxxxxxxxuuuuuyyyyyyyyyyyyyyyv?vrxuyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyyuuuuuuuuuuuxxxxxxxxxuuuuyyyyyyyyyyyyyyyr???vrxuyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxxxxxuuuuuyyyyyyyyyyuuuyyz????v}wwwzzzuuyyyyyyyyyyyuuuu|<br></br>
-uyuuuuuuuuyyyyyyuuuuuuuuuuyyyyyyyyyyyyur}wxx}v}}}}}}}rrrrwzuyyyyyyyyyuuuu|<br></br>
                  -uyyuyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyz?vxuuxxxwrrzuuuuxzwxuyyyyyyyyyuux|<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxxxuwzuyyuuyuxuyuzwwzuuuyyuuuyuuuuuxv`<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyzv?}rruuzwwuyyuzr}}}}}rzxuuzwuuuuuxv?`<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyywvvv}wr}}}wuxr}}}}}}}vv}rwr}rxuuu}>>`<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuxwrvv}}}}}}}}}}}}}}}}}}vv??vvv?v}}wxwv??-<br></br>
-uuuuuuuyyyyyyyyyyyyyyyyyyyyyxw}vv????v}}}}}}}}}}}}}}}}}vv?>>>?????v?vwxxu|<br></br>
-xuuuuuuuyyyyyyyyyyyyyyyyyyyyxrv??????v}}}}}}}}}}}}}}}}}vv??>>?}??vv}zxuuu|<br></br>
-uuyyyyyuuuuuyyyyyyyyyyyyyyyyyyuzv?>>?}}}rw}}}}}}vvvvvvv?>?????}vvzxzuuuuu|<br></br>
-uyyyyyyyyyuuuuuuuuuuuuyyyyyyyyyyyx}??}}}}}}}}}}}vvv}vvv>>????????zxxxxuuu|<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuyyyyyyuuuuyyur}}}}}}}}}}}vv}wr}?>>?>>????}uxxxxuuy^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuyyyyyuuuuuurv}}}}}}}}}}}vv}zz}?>>??????vxuuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuyyyuuuuu}??v}vvvvvvvvv}}v??>>>>>??????wyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuuuyuuuyuuxwvvv??vvvv??vv?>>>>>>?}v???vruuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuyyuv??v??vv?>>>>>>>?}}>>?v?vzyuuyyuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuuyw?>?vv>>>>+++++>?zw??>vxx}ryyyyyyuuu^<br></br>
-uuuuuuyyyyyuuuuuuxxuuuuuuuuuuuuuuuuuuyz}zyx>++++>?????zwrzxu}-+xuxyuuyuuu|<br></br>
-uuuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyuyy}???vwxyzwzuyuuyyyv>??+?uuuuuuu|<br></br>
-xxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyyxuyyyyyyuzuyyyyyyyyuw>?wyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuxuuuuuuuxx|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwwwzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyyuy^<br></br>
                  -uyuuxzzwwwwzzzxxxxzzzzzxxxuuuxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyyuuxxzzzzzzzxxxxxzxxxxxuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyuuuxxxxxxxxxxxxxxxuuuuuxxxxxxxxuuuuyyuuyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyuuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyywvrxyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxuuuuuuuuuyyyyyyyyyyzv?v}zuyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyyuuuuuuxxxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyv????vwxuuyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyyuuuuuuuuuuuxxxxxxxxxuuuuyyyyyyyyyuxuyyr??vvvvrrrwwwzuuyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuuxxxxxuuuuyyyyyyyyyyuvvrxxzxwww}}}}rwwrrwzxyyyyyyyyyuuuu|<br></br>
-uyuuuuuuuyyyyyyyyuuuuuuuuuyyyyyyyyyyyyz?}xyyyuyyzrwzuyuuxxzzuyyyyyyyyuuuu|<br></br>
-uyyuyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyuwwzz}rxyuxzzyuuyyuzrwzxuuyyuxuyyyyyuuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxv?v}}zwr}}rzyyxw}}}}}}rwxuxrrzuuuuuxu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxzw}v}}}}}}}}}}ww}}}}}}}}vv}rr}}}}wxuuuu|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyuuxwr}vv??v}}}}}}}}}}}v}}}}}vvv?>?v}vvv}v?vrxuu|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyzv????????}}}}}}}}}}}}}}}}}v???>>+?v???vv}rrwxu|<br></br>
-uuuuuuuyyyyyyyyyyyyyyyyyyyyuwv?>>>>v}}}}}}}}}}}}}}}}}?>>???>>>??>?vzyuuuu|<br></br>
-xuuuuuuuyyyyyyyyyyyyyyyyyyyyyyuwv??}}}}}}r}}}}}}}}}}v>+>?v?>>?}??vv}uuuuu|<br></br>
-uuyyyyyyuuuuyyyyyuyyyyyyyyyyyyyyyxr}}}}}rr}}}}}}vv}v?>>??????vv??zzwxuuuu|<br></br>
-uyyyyyyyyyuuuuuuuuuuuuyyyyyyyyyyyyz}}}}}}}}}}}}}vvvv?>>??????????xxxxuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuyyyyyyyuuurv}}}}}}}}}}}}}vvvr}?>??>>?????wuxxxxuuy^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuuyyyyuux}>?vvvvvvvv}}}}v??vwz?+??????v?}yuuuxuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuyuyyuuxxzrv??vv}vvv}}v>>>>>>>>???????}uuuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuuuuuuyyyuw?>>?rz}??vv???>>>>>>???v??v}xuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuu}>>??vvvv????zz?>>?v}v??v}wuuuuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuurvzxzv>>>>++>??+>}vww?vwuyyyyyyyyyuuuu^<br></br>
-uuuuuuyyyyyuuuuuuxuuuuuuuuuuuuuuuuuuuyyy}++++>???>?rzw}}ruywv}uyyyyuuyuuu|<br></br>
                  -uuuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyx???vwxuyywruyuuuyyu?vxxzuyyuuuuuu|<br></br>
                  -xxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyuuuyyyyyyyuuyyyyyyyywrzv?zuyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuxzzwzxxuuuuuxx|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwwwzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzzzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyyuy^<br></br>
                  -uyuuxzzwwwwzzzxxxxzzzzzxxxuuuxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -uyyuuxxzzzzzzzxxxxxxxxxxxuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyuuuxxxxxxxxxxxxxxxuuuuuxxxxxxxxuuuuyyuuyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyywvrxyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuuxxxxxxxxxxxxxuuuuuuuuuyyyyyyyyyyzv?v}zuyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyyuuuuuuxxxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyv????vwxuuyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyyuuuuuuuuuuuxxxxxxxxxuuuuyyyyyyyyyuxuyyr??vvvvrrrwwwzuuyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuuxxxxxuuuuyyyyyyyyyyuvvrxxzxwww}}}}rwwrrwzxyyyyyyyyyuuuu|<br></br>
-uyuuuuuuuyyyyyyyyuuuuuuuuuyyyyyyyyyyyyz?}xyyyuyyzrwzuyuuxxzzuyyyyyyyyuuuu|<br></br>
-uyyuyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyuwwzz}rxyuxzzyuuyyuzrwzxuuyyuxuyyyyyuuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxv?v}}zwr}}rzyyxw}}}}}}rwxuxrrzuuyyuxu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxzw}v}}}}}}}}}}ww}}}}}}}}vv}rr}}}}wxuuuu|<br></br>
-uuuuuyyyyyyyyyyyyyyyyyyyyyuuxwr}vv??v}}}}}}}}}}}v}}}}}vvv?>?v}vv}}v?vrxuu|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyyzv????????}}}}}}}}}}}}}}}}}v???>>+?v???vv}rrwxu|<br></br>
-uuuuuuuyyyyyyyyyyyyyyyyyyyyuzv?>>>>v}}}}}}}}}}}}}}}}}?>>???>>>>>>?vzyuuuu|<br></br>
-uuuuuuuuyyyyyyyyyyyyyyyyyyyyyyuwv??}}}}}r}}}}}}}}}}}v>+>?v?>>>???vv}uuuuu|<br></br>
-uyyyyyyyuuuuuuyyyuyyyyyyyyyyyyyyyxr}}}}}}}}}}}}}vv}v?>>??????v???zzwxuuuu|<br></br>
-uyyyyyyyyyuuuuuuuuuuuuyyyyyyyyyyyyz}}}}}}}}}}}}vvvvv>>>??????????xxxxuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuyyyyyyyuuurv}}}}}}}}}}}}}vvvvv>>??>>?????wuxxxxuuy^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuuyyyyuux}>?vvvvvvvv}}}}v??vwz?>??????v?}yuuuxuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuyuyyuuxzzw}??v}}vvv}}v>>>>>>>>???????}uuuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuuuuuuyyyyur>>?}r???}wv??>>>>+>???v??v}xuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuur>>??v??????vxz?>>>vvv??v}wuuuuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuuuuuuuuwvzxzv>>>>++>v?+>}vww?vwuyyyyyyyyyuuuu^<br></br>
-uuuuuuyyyyyuuuuuuxuuuuuuuuuuuuuuuuuuuyyy}++++>???>?rzz}}ruywv}uyyyyuuuuuu|<br></br>
                  -uuuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyxv??vwxuyywruyuuuyyu?vxxzuyuuuuuuu|<br></br>
-xxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyuuuyyyyyyyuuyyyyyyyywrz}?zuyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuxzzzzxuuuuuuxx|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwwwzzzzzwrwxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzwvvvrwxxuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyyuy^<br></br>
-uyuuxzzwwwwzzzxxxxzzzr???vv}wxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxxzzzzzzzxxxxxxxx}??????v}rzxxxuzrzuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-uyyyuuuxxzzzzzxxxxxxxxxv????????v}wzzrvvwxuyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuuuxxxxxxxxxxxxxuuuzv????vvvv}}}}}}}}rwzxuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxz}rwxuw??vv}}}}}}}}}}rwuuuuuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuuxxxxxxxxxx}??v}r}}}}}}rzuxxzwwxuwrrrwzxuxzuyyyyyyyyyyyyyyyyuuuxx|<br></br>
-uyyyuuuuuuxxxxxxxxxz}?vvrwwwrrrzyuxuuuyuw}}}}}}}rr}rwwzuuyyyyyyyyyyyuuuxu|<br></br>
-uyyyuuuuuuuuuuxwrwwzz}}rxyyyuxxuzrrrrwwr}}}}}}}}}}}}}}}}rwxuyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuwv?v}uxwxuzzxuyyxr}}}}}}v}}}}}}}}}}}}}}}vvv}wxuyyyyyyyuuuu|<br></br>
-uyuuuuuuuyyyyyyyu}?vxyuuwr}rrwwr}}}}}}}v??v}}}}vvv}}}}}vruyyuyyyyyyyyuuuu|<br></br>
-uyyuyuyyyyyyyyyuux}}wuuw}}}}}}}}}}}}}}}v>+>>?v}?>>?vv???v}xyyyyuyyyyyyuuu|<br></br>
-uyyyyyyyyuzwr}vvv?v}rwwr}}}}}}}}v????vvv>+>+>>?>>+>>??>+>??wuuuuuuyyyuuuu|<br></br>
-uyyyyuw}vv????????v}}}}}}}}}}}}}}?>+>>>>>>??>>+>?>>>>+>>>rzwxuuuuuuyyyuuu|<br></br>
-uuuuuuxrv?????????}}}}}}}}}}}}}}v?>>???>>????>>>???>>>???ryyuyuuuuuuuuuuu|<br></br>
-uuuuuuyyyxrv??????}}}}}}}}}}}}}}v>>>???+-`^+~|+??????????vxyyyuuyyyyuuuxu|<br></br>
-uuuuuuuyyyyyxr?>>?}}}}}}}}}}}}}}v?>>????+zyz|rr???????>^>vwyyuuuuuyyuuuuu|<br></br>
-uuuuuuuuuyyyyyyz}v}}}}}}}}}}}}}}}?>>???}v}v+`vyuwv???v??^>}uuuuuuuuuuuuuu|<br></br>
-uyyyyyyyuuuuuuyyur}}}}}}}}}}}}}}}?>+>??}^`.`~ryyuv??v>vv+>?zuuuuuuxxuuuuu|<br></br>
-uyyyyyyyyyuuuuuw??v}}}}}}}}}}}}}}v?>>??v?||+vyux}????>v>>>?zuuuuuuxxxuuuu^<br></br>
-uyyyyyyyyyyuuur??>v}}}}}}}}}}}vvvvv>+>????}ww}vv????v??>>+?xyyyuuuxxxxuuy^<br></br>
-uyuuuuuyyyyyuuxzzzr}}}}}}}}}}vvvvvvv?>>?????????v}vrz}wr?>vuyuyyuuuxxuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuu}vvvv}}}}}}}}v}}rv?>>?v??????+?xzuxuyzvzyuuyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuxvvvv??}}}v}}}}}zxrv?>}}???v???wyuyyyyyuuuuuyyyyuuuuyuy^<br></br>
-uuuuuuuyyyyyuuuuuurv}v?>?v}v??v}}v???vv?>>>?????zyyyyyyyuyuuuyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuxuzv?}xvv?>>>??v?>^?v>>>>?v>+?zyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyuuuuuuxxuuurwuu}>>++>+>?>>v?>vrz}}xrxyuwuyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uuuuuyyuuuuuuuuuuuuuuuuuuv>>>??}wxrvwzzxuyuuuzx}>~?yyyyyyyyyyyyyyyuuuuuuu|<br></br>
`xxxxuuuuuuuuuuuuuuuuuuuuuv?}wxuuuuuuuyyyyyyyuxz?+?zyyyyyyyyyyuuyyuyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuxxuxxxxxxxxuuuuuuuuxxuuuuuuuuuuuuuuuuuuuxuuuuxxx|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwzzzzzzzwrwxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwwwwwzzzzzzzwvvvrwxxuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uyuuxzzwwwwzzzxxxxzzzr???vv}wxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyyuuxzzzzzzzxxxxxxxx}??????vvrzxxxuzrzuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-uyyyuuuxxxzzzzxxxxxxxxxv????????v}wzzrvvwxuyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyuuuuxxxxxxxxxxxxuuuzv????vvvv}}}}}}}}rwzxuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxz}rwxuw??vv}}}}}}}}}}rwuuuuuyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuuxxxxxxxxxx}??v}r}}}}}}rzuxxzwwxuwrrrwzxuxzuyyyyyyyyyyyyyyyyuuuxx|<br></br>
-uyyyuuuuuuxxxxxxxxxz}?vvrwwwr}rxyuxuuuyuw}}}}}}}rr}rwwzuuyyyyyyyyyyyuuuuu|<br></br>
-uyyyuuuuuuuuuuxwrwwzz}}rxyyyuxxuzr}rrwwr}}}}}}}}}}}}}}}}rwxuyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuwv?v}uxwxuzzxuyyxr}}}}}}v}}}}}}}}}}}}}}}vvv}wxuyyyyyyyuuuu|<br></br>
-uyyuuuuuuyyyyyyyu}?vxyuuwr}rrwwr}}}}}}}v??v}}}}vvv}}}}}vruyyuyyyyyyyyyuuu|<br></br>
-uyyuyuyyyyyyyyyuxx}}wuuw}}}}}}}}}}}}}}}v>+>>?v}?>>?vv???v}xyyyyuuuyyyyuuu|<br></br>
-uyyyyyyyyuzwr}vvv?v}}rr}}}}}}}}}v?????vv>+>+>>?>>+>>?>>+>??wuuuuuuyyyyuuu|<br></br>
-uyyyyuw}vv????????v}}}}}}}}}}}}}v>>+>>>>>>??>>+>?>>>>+>>>rzwxuuuuuyyyyyuu|<br></br>
-uuuuuuxrv?????????}}}}}}}}}}}}}}v>>>???>>????>>>???>>>???ryyyyyyuuyyyyuuu|<br></br>
-uuuuuuyyyxrv??????}}}}}}}}}}}}}}v>>>???+-`^+~|+??????????vxyyyyyyyyyyuuuu|<br></br>
-uuuuuuuyyyyyxr?>>?}}}}}}}}}}}}}}v?>>????+zyz|rr???????>^>vwyyyyuuyyyuuuuu|<br></br>
-uuuuuuuuuyyyyyyz}v}}}}}}}}}}}}}}}?>>???}?vv+`vyuwv???v??^>}uyuuuuuuuuuuuu|<br></br>
-uyyyyyyyuuuuuuyyur}}}}}}}}}}}}}}}?>+>??}^`.`~ryyuv??v?v?+>?zuuuuuuxxuuuuu|<br></br>
-uyyyyyyyyyuuuuuw??v}}}}}}}}}}}}v}v?>>??v?||+vyux}????>v>>>?zuuuuuuxxxuuuu|<br></br>
-uyyyyyyyyyyuuur??>v}vvvv}}}}}vvvvvv>>>????vww}vv????v??>>+?xyyyyuuxxxxuuu^<br></br>
-uyuuuuuyyyyyuuxzzzr}vvvv}}}}vvvvvvvv?>>?????????v}vrx}wr?>vyyuyyyuuxuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuu}vvvv}}}}}}}}}}}}v?>>vv???v??+?xxuxuyzvzyyuyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuxvvvv??}}}v}}}}rxzrv??}v??vzv??wyuyyyyyuuuuuyyyyuuuuyuy^<br></br>
-uuuuuuuyyyyyuuuuuuwv}v?>?v}v??v}}vv??vv?>>>?????zyyyyyyyyyyuuuyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuxuzv?}xvv?>>>??v?+^?v>?>>?v>+vuyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuyyyyyuuuuuuxuuuurwuu}>>++>+>?>>v?>vrz}}xrxyuwuyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uuuuuyyuuuuuuuuuuuuuuuuuuv>+>??}wxrvwzzuuyuuxzx}>~?uyyyyyyyyyyyyyyuuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuuuuuuuuxv?}wxuuuuuuuyyuuuyyuxz?+?zyyyyyyyyyyuuyyuyyuuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuxxuxxxxxxxxuuuxxxuuxxuuuuuuuuuuuuuuuuuuuxxuuuxxx|<br></br>


            </div>,

            <div>

`rwr}}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwzzzzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uuuxzzwwwrrwwzzzzxzzzzwzxxuuxxxuuuuuuyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uyuuxxzww}vvvv}}rwzzzzzzzxxxxr}zxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyuuxxzz}??????vvv}}rrwzzzzv?vrzzzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-uyyyuuuxxxzrv???????????vv}}}vvv}rwwzxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuxxxxxzv???????vvvvvvvvv}wuuuuuuuuuxzxuyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxz}????vv}}}}rrrrrwxyxwwrrrwzzrrwwwzxuyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuuxxxxxxwv?v}}}}}wuuuuuuyyzr}}}}}}}}}}}}}}}}rzxuyyyyyyyyyyyyyuuuxx|<br></br>
-uyyyyuuuuxr}rwzzw}rr}}}rxuzwzzzxxrrrrrrr}}}}}}}v?v}rwzxuyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuz}??v}rzuuxzzxuzrrrrrrr}}}}}}}}}}}vv}}}}uyuuuyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuzvv}wuuuyyyyxrrrrrrvv}}}}}v??vv}v????v}xyuuyyyyyyyyyyyyyyuuuu|<br></br>
-uyyuuuuuuuuuuuzzuxrrwzzwr}}}}}}????vv}v>+>>???>>+>>?zuuyyyyyyyyyyyyyyyuuu|<br></br>
-uyyuyuuuzvvv}uyuuw}v}}r}}}}}}}}v>++>>??>>>>+>>>>>>+>ruuuuuyyyyuuuuuyyyuuu|<br></br>
-uyyyyyyyuzv?vrxuzr}}}}}}}v??vvvv?+>>>>>>???>>>>????>vuyuuuuuuuuuuuuuyyyuu|<br></br>
-uyyyuyyyyyywvv}r}}}}}}}}}v>>>>??>>????>>???????????>>}xuuuuuuyuuuuuuyyyuu|<br></br>
-uuuuuuyyuz}vvvvvvv}}}}}}}v?>>???>>>>>++>?????????>+++?}xuuyyyyyyyyyyyyyuu|<br></br>
-uuuuuxzrvv??vvvvvv}}}}}}}v?>>???>`^vw+|}v???????v?w?++?}xyyyyyyyyyyyyuuuu|<br></br>
-xuxwrvv??vv?v}vvvv}}}}}}}}v?>????+vyx+`vuuzv????+>x?>+>?}uyyyyyyuyyyuuuuu|<br></br>
`w}vv????????vrr}}}}}}}}}}}v?>>??vv--`.`?yyuv???v^?w?++>>?wyyyyyuuuuuuuuuu|<br></br>
`}vv??????????rrrr}}}}}}}}}}v>>>??r|```~ryx}????v?v??>>>+>vxuuuyyuuuxuuuuu|<br></br>
-uuxzwr}vv????vr}vvv}}}}}}}}v?>>>?vv+^>}z}v???vv???r}zrv?>?wuuyyyuuuxxuuuu^<br></br>
-yyyyyyyyyuuxz}}vvvvvvvv}}}}}v?>>>>???vv?????rwrv}ruxuuyuxrzyyyyyuuuxxxuuu^<br></br>
-uyuuuuuyyyyyr?v}}}vvvvv}}}}}vv??>>>????????????vuuuuuuyuuyuyyyyyyuuuuuuuy^<br></br>
-uuuuuuuuyyur???}r}}vvvv}}}}}}}}v?>>>???vz}???vvzuuyyyuyyuyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyuxzzzwr}v}wwr}}}}vvvvv}r?>>??}v??vrwuuyyyyyyyyyyyuuyyyyuuuuyuy^<br></br>
-uuuuuuuyyyyyuuuur}}?vzu}?vv}}?>>??v?>>>>>??+^^?yyyyyyyyyyyyyuuyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuzvv}?>?vv?>>?v?>>++v}??vvv}??}zwzyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyuuuuuxzxzv??v??>>>>>>>>?>>ruuyuzwxyur>>?zyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuz}zu?>>>?vrzrvwwwxuuyyuzzw?|~+}uyyyyyyyyyyyuyyyuuuuuuu|<br></br>
                  `xxxxxuuuuuuuuuuuuuuuxuu???vzuuuuuxuuuyyuuuuuuxzxyyyyyyyyyyyyyuuyyuuyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuurwuuxxxxxxxxxuuuxxuuuxxuuuuuuuuuuuuuuuuuuuxxuuuxxx|<br></br>


            </div>,

            <div>

`rwr}}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwzzzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-yyuxzzzzzwwzzzzzzzzzzw}rwxuuuuuuuuuuuyyyyyyuyuuyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
                  -xxzzwwwwwwwzzzzxxxxzzzwv?vwzzxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`vv????????vvvvv}rwzzzzw}v}rrwxuyyuxzzxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
-wv???????????????vv}}}}rxuuuuuuuuuuzr}rwzzxuuyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yyzv???????????vvvv}rrrwuyyxrrrrrrrr}}}}vvvv}rwxuyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyuwv???????vv}}}rzuuuyyyyyw}}}}}}}}}}}v?>?vrzxuyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuwv????vv}}}rxyuxzzzzzzr}}}}}}}}}}}}v??zyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
-uyyyyuuuz}?v}}}v}wuuw}}}}}}}}}}}}}}}v????vv??wyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuxxxxxw}rzzzzzuyz}}}}}}}}}}v??vvv}v>+>>>>>?ruyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyux}v??v}rxuuuuuyuw}}}}}}}}}}v>++>>??>>>>>>>>>ruyyuuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyyyux}vvrxywrrrwwr}}}}v????vvv?>>>>++>>??????>?}uyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyyyyuuxxyzr}}}}}}}}}}v?++>>>>>+>???>>>????>>>+?}zuyyyyyyyyyyuuuuuyyyyuu|<br></br>
-uyyuuzwzyyuw}}}}}}vvvv}vv>>??>>>>??????????+|^~>+?}rxyyyyuuuuuuuuuuuyyyuu|<br></br>
-uyxrvv?vwxzr}}}}}}v?>>>??>>?????>>?????????}?vw?+>?v}zyyyyuyyyuuuuuuyyyuu|<br></br>
-uuxw}v?v}}}}}}}}}}}?>>>>>>+|^+>~~vvvv??????v|?u?>>+>?vwuyyyyyyyyyyyyyyyuu|<br></br>
-uuuuuuz}vv}}}}}}}}}v>>>???~ +xyv`?uuur????v~.>u?>++++>?ruyyyyyyyyyyyyuuuu|<br></br>
-xuuuuz}v}}}}}}}}}}}}?>>??>~?>xw|`-ryyuv?????^?}?>???>>>?ruyyyyyyyyyyyuuuu|<br></br>
-uuuw}v??v}}}}}}}}}}}}?>>??vu+`. `~?yur????????vrvwuuxzw}}wuyyyyyuuuuuuuuu|<br></br>
-uz}?????v}}}}}}}}}}}}v?>>??rz>`.-^rzv?????????ryuuuuyyyyuuuuyyyyuuuuuuuuu|<br></br>
`}v???????v}}}}}}}}}}}}v?>>>?vrv>?}v????vrwwr?vuyyyyuyyyyyyyuyyyyyuuxuuuuu^<br></br>
`????????>>v}}}}}}}vvvvvv?>>>>?????????????>??}yyyyuuuuuyyyyyyyyyuuuxxuuuu^<br></br>
`????>>>>??v}}}}}}vvvvvv}v?>>>+>?????????????vxyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
`vvv}}rwzxurvv}}}}vvvvvv}}}v??>>>>??????rxv??xyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuyyyw?vwrwzw}vv}zx}?????>>+>v}????vv>-~wyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyu}vrwwwxw}vvvwzr?>?rv>>?vr}>>>??+~+?>uyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyuuz?vv?vvv??????}v+^v}^^?}wzwvzyy}>}w}?zyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuyyyyyuuuzvv}?>??>>++>+++>>~~^vxyyyuzxxv|...|vuyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuzv>>>+>>>?v}?}}}wzuyyyuxzr>|>vwuyyyyyyyyyyyyyuyyyuuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuuuw}v+>?rxuu}wuuxxuuyyuuyyyyyyyyyyyyyyyyyyyyyuyyyyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuwvwuuuxxxxxxxxxuuxxxuuuxxuuuuuuuuuuuuuuuuuuuxxuuuxxx|<br></br>


            </div>,

            <div>

`rwr}}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwwwzwwzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uuuxzzzwwwwwzzwvv}wzzzwzxxxxxuuuuuuuuyyyyyyuyuuyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyuuxzzzzzwzzxz}vv}rrwzzxuuxzwzzzxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uuxzzzzwwwwwwrrrrrwzzxuuuuuxzwwwwwrrwwwzxxuuyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
`wrvvvvvvvvvvvvvvv}zuuxzzwwwwwrrrrr}vvvv}rwxuyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`vvv??????vvvvvv}rwxuuxwrrwwr}}}}}v????rxuuuuyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`vvvv????vvvv}zuuuuuuuxwwwwwwr}}}}}v?>?vwuuuuyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`vvvvvv}}vvv}zyxwrrrwwr}rr}}}}v????vv?>>?vzuuyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`vvvv}rwr}}}wuxr}}}ww}}}}}}}}}}?>>>>>>>>>>?rzuuyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`w}?vrrzxzwwxyxrrrrwr}}v????????>>????????>>?}zuyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-yuwrrwuuuuuyuwrwwwr}}}}?>>>>>>>>>?????>++>>+>?}zuyyyuyyyyyyyyyyyyyyyyuuuu|<br></br>
`zzzwwuxrrwwzw}}}vvvvvvvv?>????>>>????>~v?>?>+>?v}wxuyyyyyyyyyyyyyyyyyyuuu|<br></br>
`vruzuyzrrrrr}}}}v>>>>>>?>>>???????????v>wxv?>++>??vwxyyyyyyyyyuuuuuyyyyuu|<br></br>
`zzyyyxrrwwr}}}}}}?>>?>>>>>>??????????rz~+xw?>++>>>>?vwuyyyuuuuuuuuuyyyyuu|<br></br>
-uuxuur}}rr}}vvvvvv?>????>>>??????????v~.-}xv>>?>>>???vwxyyyyyyuuuuuyyyyuu|<br></br>
-xzwzw}vv}}}}??>>>>>++>^?+`+}}}}v?????v>-|}r?>>rzzzzzzxuuyyyyyyyyyyyyyyyuu|<br></br>
`v}rrwrr}}}}}v??>>??>``}uy?.+xuyxv?vvv?????????}xzxuuuuuuyyyyyyyyyyyyyyuuu|<br></br>
`vv}wrr}}}}}}}v?>???>`~rxr~.~?uyxv?vvvv?????vz}?vzxuuuuuuuyyyyyyyyyyyyuuuu|<br></br>
`wwwr}vvv}}}}}}v?>>???r+~. `^~wzv??vvv??v???ruxzuuuuuuuuuuuyyyyyyyyuuuuuuu|<br></br>
-yurvvvvvv}}}}}}v?>>??wz+`.`|+rv???vvrwzr??vxuuuyuuuuuyyyyuuuyyyyyuuuuuuuu|<br></br>
-zv??vvvvvv}}}}}}v?>>??vzv+|^?v????v}rv???vvxuuyyyyyyyyyyyyuuyyyyyuuuxuuuu^<br></br>
`v????vvvvv}}}}}}}v?>>>>??????????????????vruuuyyyyuuyyyyyyyyyyyyyuuuxuuuu^<br></br>
`??????vvvv}}}}}}}}v??>>>>???????????????vvzuuuyyyyuuuyyyyyyyyyyyyuuuuuuuy^<br></br>
`???????}rr}}}}}}}vvvv??>>>>>>??????????rw?}uyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`???????vrr}zxwwxwv???ww?>>>>++>>?vv???>+|-vuyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.???vv}wzxwwzzrwzr}v??}r?>>?vv?>>?wwv}v>v}|^rzuuuyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`zxxuuyyyyuzrrrv????v}?>>>>>v}?^?}wxxuxuuuz+|~|+}uuyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuyyyyxv??v}v?>>>>>>>++>?>|-?}zxuuuzxu}>~`~|>ruyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-uuuuuuuux}}wxuxwv?>+>>>>??vwrvwwxuuuuuuuuzrwxuyyyyyyyyyyyyyyyyuuyyuuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuz}?>>?}zzrxyuuuuuxuuyyyyyyyyyyyyyyyyyyyyyyyyyuyyyyyyuuuu^<br></br>
-xxxxxxuuuuuuuuuuuyy}vrxuuuzzxxxxxxxxxuuxxxuuxxuuuuuuuuuuuuuuuuuuuuxxuuuxu|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwwwrwzzzzzzzzzzxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzzwwwrvvv}wzzzzzxzzxxxxxuuuuuuuyyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxxzzzzv??vv}rwxuyyuxwrrrrrrrwwzzxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-yyyyuuxxxzwr}}}wzxuuuxzwr}}}}}}}vvvvv}rzuuyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
-uxzzwwwwwr}}}}rxyyuzr}}}}}}}}vv??>?vwxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`vvvv??vv}}}rrwzxyyuz}}}}}}}}}}v>>>>vrxyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`?????v}}}wxuyyyuuxzw}}}}}vvvvvvv>+++>vrxyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
`???vv}}}rxyuxzwr}}}}}}}}}v?>>>>>>+>>>>>vrxyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`??vv}}v}zyyz}}}}}}}}}}}}}}v>>>>>>>>???>+>vwxuyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`?v}rwwrwuyxr}}}}}}v????????>>??????>+^>>+>?vrwzxuyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
.v}rxyyyyyyzr}}}}}}}?+>>>>++>>?????>~v?>??+>>??vvrwxuyyyyyyyyyyyyyyyyyuuuu|<br></br>
`}rzyxxxxxxr}}vvv}}}v?>?????????????v>ryzv>+>>>>>??v}wuyyyyyyyyyyyyyyyyyuu|<br></br>
-zzuuw}}}}}}}v?>>>????>>???????????ry>-}yr?+>>+++>>??vwuyyyyyyyuuuuuyyyyyy|<br></br>
-uuyzr}}}}}}}}?++>>>>>>>>?????????vv>`.^xx?>+>?v}wzxxuuyyyuuuuuuuuuuyyyyyy|<br></br>
-uyuw}}}}}}}}}v?>??????>>??????????v>``-rzv?+>}zuyuuuuuyyyyuyuyyuuuyyyyyyy|<br></br>
`xyzr}}}}v??????>>??>>^`^}wrrv??????v?>?vv????rr?wuuuuyyyyyyyyyyyyyyyyyyyy|<br></br>
`}wr}}}}}}?>>>>>>^~-?xu?`>xyyu}???????????vxr?>?rxuyyyyyyyyyyyyyyyyyyyyuuu|<br></br>
`vvv}}}}}}v?>>>??+.`xyy?`-+xyyw???????????vuyuuuyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`vv}}}}}}}}v?>>???~>+>^. -~?yxv??????v}???ryyyyyyuyuuuyyyyyyyyyyyyyuuuuuuu^<br></br>
.?v}}}}}}}}}}?>>>??}}|`..-~?uv???v}zxz}???wyyyyuuuyyyuyyyyyyyyyyyyuuuuuuuu^<br></br>
`}v}}}}}}}}}}}v?>>>?vwv>^||vv????vr}v?>???wyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-r?v}}}}}}}}}}}v?>>>>?v}?????????????????}uyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`???vv}}}}}}}}}}vv?>>>>>????????????????vvzyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`?????v}}}}}rr}}}}v??>>>>>>>???????????>}vwyyyyyyyyuyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`??????v}}}wuuwwxr????zw?>>++>>>?vv???~```ryyyyyyyyuyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`???>>>>?v}rwwrrwr?>>>v?>>>?v?>>?}xzzvvzr|~?wwr?>vzyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>>??v}wzv}}}????v???>>>+>}z?+v}wuuyuyyuv. ...`+wuyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`v}wzuyyyz?>?vv>>>>>>>>>+>?>|-?wxuuuuzxz?-`-+vrzyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
-xuuuuuuyr>>>?v}?>>>>>>>>?}wrvwzuuuuuuuuuzzxyyyyyyyyyyyyyyyyyyyuuyyuuuuuuu|<br></br>
-xxxxxxuur?vrzuux}?>>>>vzw}xyyuuuuuuuyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuuuuuyuuuuyuv>?wuuuvwuuxxxxxxxuuxxxuuuxxuuuuuuuuuuuuuuuuuuuuxxuuuxu|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  -yyuxzzzwwrwwwzzzzzwzzzzxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzzwrvvvv}wzzzzxzzzzzzxxxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxxzz}v?vv}}wzxyyyuzwr}}rrrrrrwwzzxuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-yyyyuuxxzw}v}rzxuuuxxzwr}}}}}}vvvvvv}wzuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
-xxzzzzwwwr}}}wuyuxw}}}}}}}}vv??>>?rzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`vvvv?vvv}}}rwzuyyuw}}}}}}}}}v?>>>>v}zuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`????vv}}wxuuyyuxxzw}}}}vvvvvvv?>+++>?vzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`???v}}}ruyuxzwr}}}}}}}}v?>>>>>>>>>>>+>?vwuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`??v}}v}zyuw}}}}}}}}}}}}}v?>?>>>>?????>+>?vrxuuyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`vv}}rrwuyxw}}}}}vv????????>???????>+^^>>+>?v}rwzxuyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`vrzuuuuyyzr}}}}}}v>+>>>>+>>>?????++vrvv?>>>>>??vv}wzuuyyyyyyyyyyyyyyyuuuu|<br></br>
`vwuuuuuuxr}}v}}}}v?>?????????????r??ruu}?>>>>+++>??vwxyyyyyyyyyyyyyyyyyuu|<br></br>
`zuuzr}}}}}}v????????>????????????zx?|vuxv>+>>>??v}rzxuyyyyyyyuuuuuuyyyyyy|<br></br>
-uyxr}}}}}}}v?++>>>>>>>??????????vv+``^xu}?>>?rxuuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
-uuz}}}}}}}}}?>>??????>>??????????v?|-|rzv?>>?wrwuuuuuyyyyyuuyyyuuyyyyyyyy|<br></br>
-uxw}}}}vvvv???>???+^-->vrrrv??????vv?????v???}vvzuyuuyyyyyyyyyyyyyyyyyyyy|<br></br>
`ww}}}}}v?>>>>>+^^~?wr>|>zuyu}v??????????vzuw}}xuuuyyyyyyyyyyyyyyyyyyyyuuu|<br></br>
`vv}}}}}}v>>>>?>|.+xyy?-`+zyywv??????????vzyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`vv}}}}}}}v?>>??>-|?v^..`~?uuv?????v}}v??vuyyyyyyyyyyuyyyyyyyyyyyyyuuuuuuu^<br></br>
`vv}}}}}}}}v?>>>??}>|`..`|+x}????vwxz}v??vxyyyyuyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`}}}}}}}}}}}v?>>>??}}?>||~?v????vr}v?????}uyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`}vv}}}}}}}}}}v?>>>?v}}??????????????????zyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`??vv}}}}}}}}}}v?>>>>>>????????????????vv}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`????vv}}}}}rr}}}v??>>??>>>???????????>>}vuyyyyyyyuxyyyyyyyyyyyyyyyuuuuyuy^<br></br>
`??????v}}}wuxwwxw??>?zw>++++>>??vvv?^-`.^uyyyyyyyuxyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`???>>>?vv}rrr}}w}>>>>??>>>?v?>>?wxxwvww?-^vwrv>+vxyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.??>>???vr}}vv??vv?>??>>+^?zw>?vrzuyuuyyw|. .``|?xyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.??v}wzuyw??vv?>>>>>>>>>+?>^~^}zxuuyxzx}^``~?zxyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
`wxxuuuuyr>>>???>>>>>>>>>vwrv}wxuuuuuuuuxzzuyyyyyyyyyyyyyyyyyyyuuyyyuuuuuu|<br></br>
-xxxxxxuuw??v}zxwv>>>>?vw}wuyuuuuuuuuyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuuz}wuuuuyyz?>?zuurvuuxxxxxxxuxuxxxuuuuuuuuuuuuuuuuuuuuuuuuuxxuuuxu|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
-yyuxzzw}rwwzzzzzwwzzzzzxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzrvvvv}wzzzzxxxzwwwwzzzzzzxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxxv??vv}rwzuyyuxwr}}}}}}}}}}}rwzxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-yyyuuxzrvv}rzxuuxzwr}}}}}}}vvv??v}rzxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
-xxzzwr}}}}}wuyuzr}}}}}}}}v?>>>>?vwxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`vvvv}}}}}rwzxyyuz}}}}}}}}}v?>+++>?vwxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`??v}}}wxuuyuuxxzw}}}}v???????+>>>+>?vrxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`vv}}}ruyuzwrr}}}}}}}}}?+>>++>>>??>>>+>?}zuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`v}}}}zyuw}}}}}}}}}}}}}v?>????????>+>+++>?}rwzzxuuyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`v}}}wuyxr}}}}}v????????>>??????>|?>^>?>+>>>??vv}rwxuuyyyyyyyyyyyyyyyuuuuu|<br></br>
`wxuuuyyzr}}}}}v?+>>>>++>>??????||?xxwv?>+>>>+>>>??vrxuyyyyyyyyyyyyyyyuuuu|<br></br>
`uuuuuuxw}}}}}}}v>>?????????????wz^+zyxv?+>>>>>?vrzxxuuyyyyyyyyyyyyyyyyyuu|<br></br>
-uxwrrr}}}v?vvvvv?>>????????????zx^.|zyw?>+>?}zuyyuuuuyyyyyyyyuuuuuuyyyyyy|<br></br>
-xw}}}}}}}v>+>+>>>>>>???????????v~..`?yxv?+>vwrrzuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
-z}}}}}}}}v?+>>?????????????????vv^~|?w}????vw??xuuuuuyyyyyuyyyyuuyyyyyyyy|<br></br>
`r}}}}}}}vvv?>???>^--?v}rrv??????vv??????wrv?+?zuuuuuuyyyyyyyyyyyyyyyyyyyy|<br></br>
`}}}}}v???>>>>+>|?rv||?xuyu}????????????vxyuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuu|<br></br>
`vv}}}}?>+>>>>|.+yyy?`->xyyzv???????????vxyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
`vv}}}}}?>>>??+.~vzv`.`~?uu}??????vr}???vuyyyuuyyyyyuuuyyyyyyyyyyyyuuuuuuu^<br></br>
`}}}}}}}vv>>>??>v+-`. .|^zrv???v}zxr????vuyyyuuyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`}}}}}}}}}v?>>>?vwv>|-~~+}?????}wrv?????}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`v}}}}}}}}}vv?>>>?vw}?>>???????????????vwuyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`vv}}}}}}}}}}v?>>+>????????????????????}ruyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`??v}}}}}}}}ww}v??>>>?v??????????????+~?wyyyyyyyyywwyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`???v}}}}}}wuzv}xr?>>vxr>++>>>??????^--.~zyyyyyuuyxzyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`?????v}}rxuw}vv??>>>>?>>>??>>>?}zxz}xx+`~?rrv>~+}uyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`??>>>>?vrxz}vvv?>>>??>++?wzv>vrzuyuuyyz~. .``->wuyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>>>??vwrv}?>>>>>>>>>>++^~~^}zxxuyuzxv|.`~?wuuyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
.?v}rzuuyr>???>+>v>>>>+>?v??}wxuuuuuuuuzrwuyyyyyyyyyyyyyyyyyyyyuuyyyuuuuuu|<br></br>
`xxxxxxuuw>>>?vv??>>>>?r}wuuuuuuuuuuyyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuux??vzuuuz?>?}uuvvuyuxxxxxxxuuuxuuuuuuuuuuuuuuuuuuuuuuuuuuuxxuuuxu|<br></br>


            </div>,

            <div>

`rwrr}vvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrrwwwwwwrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
-yyuxzzw}rwwzzzzzwwzzzzzxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-uyuxzzrvvvv}wzzzzxxxzwwwwzzzzzzxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyuuxxv??vv}rwxuyyuzr}}}}}}}}}}}}rwzuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-yyyuuxzrvv}rzxuuxzwr}}}}}}}vvv??v}rzxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
-xxzzwr}}}}}wuyuzr}}}}}}}}v?>>>>?vwxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`vvvv}}}}}rwzxyyuw}}}}}}}}}v?++++>?vwxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`??v}}}wxuuyuuxxzw}}}}v???????+>>>+>?vrxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`vv}}}ruyuzwrr}}}}}}}}v?+>>++>>>??>>++>?}zuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`v}}}}xyuw}}}}}}}}}}}}}v>?????????>+>+++>?}rwzzxuuyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`v}}}wuyxr}}}}}v????????>>??????>|?>^>?>+>>>??vv}rwzuuyyyyyyyyyyyyyyyuuuuu|<br></br>
`wxuuuyuw}}}}}}v>+>>>>++>>??????||?xxwv?>+>>>+>>>>?vrxyyyyyyyyyyyyyyyyyuuu|<br></br>
`uuuuuuxr}}}}}}}v>>?????????????wz^^zyxv?+>>>>??}wzxuuyyyyyyyyyyyuyyyyyyuu|<br></br>
-uxrrrr}}}v?vvvvv?>>????????????zx|.^zyw?>+>?}xyyyyyuuyyyyyuuyuuuuuuyyyyyy|<br></br>
-xw}}}}}}}?++>+>>>>>>???????????v~..-?yxv?+>vwr}wuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
-z}}}}}}}}v>>>??????????????????vv^~|?w}????vw??xuuuuuyyyyyyyyyyuyyyyyyyyy|<br></br>
`r}}}}}}}vvv>>???>^`~?vrrrv??????vv??????wrv?>?xyyuyuuyyyyyyyyyyyyyyyyyyuy|<br></br>
`}}}}}v???>>>>>>|?wv~|vuyyu}????????????vxyuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuu|<br></br>
`vv}}}}?>+>>>>|.>yyy>`-?uyyw????????????vxyyyyyuyyyyyyyyyyyyyyyyyyyyyuuuuy^<br></br>
`vv}}}}}?>>>??+.~vzv`.`~?yu}??????vr}???vuyyyuuyyyyyuuuyyyyyyyyyyyyuuuuuuy^<br></br>
`}}}}}}}v?>>>??>v^-`. `|^xw????v}zxr????vuyyyuuyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`v}}}}}}}}v?>>>?vwv+~-~~+r?????rwrv?????}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`v}}}}}}}}}v??>>>?vw}?>>???????????????vwuyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`vv}}}}}}}}}}v?>>+>????????????????????}ruyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`??v}}}}}}}rzw}v??>>>?v??????????????^~?wyyyyyyyyywwyyyyyyyyyyyyyyyuuuuyuy^<br></br>
`???v}}}}}}wuwv}xr?>>vz}>++>>>??????^~`.~xyyyyyuuyxzyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`?????v}}rxxw}vv??>>>>>>>>??>>>?}zzz}xz+`~?rrv>~+}uyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`??>>>>?}rxz}vvv?>>>>>>++?zxv?vrzuyuuyyw~. ..`~>wuyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.>>>>??vwrvv?>>>>>>>>>>++^|^+}zxxuyuzxv|..~?wuuyyyyyyyyyyyyyyyyyuyyuuuuuuu^<br></br>
.?vvrzuuyr????>+>v>>>>+>?v??vwxuuuuxxuxzrwxyyyyyyyyyyyyyyyyyyyyyuyyyuuuuuu|<br></br>
`xxxxxuuuw>>>?vv??>>>>?r}wuuuuuuuuuuuuyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
-xxxxxxuux??vzuuuz?>?ruuvvuyuxxxxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxuuuuu|<br></br>


            </div>,

            <div>

`rrr}????vv}}rrr}}}vvv}}}}}rrrrrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
`zwwr}v}}wzxuyyyuuzrrrrrrrwwwzzxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`r}}}}wxuuuuxzzww}}}}}}}}}vvvvv}rwzuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
`r}}}}wxuyuxw}}}}}}}}vv??????v}wwzzxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`wwwzxuuyyyyxr}}}}}}}v>+++>>?v}zxuxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuxzwwr}v????????++++>+>?v}zxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yyxwwr}}}}}}}}?++++++>>>>?>>++>>?vwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yyz}}vvv}}}}}}v>??>>>>>????+>>+++>?}wuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
-yyw}}v?>>>?????>>??????^`~|`~~+?>++>?v}rwzxuuyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
-xxw}}}v>+>>>+++>>??????-~^vzrvv???>>>>>??vvv}rwxuuyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`}}}}}}}?>>>>>>>>>?????>ry?->uyuv???+>>>>>>>>>>??vvrzuuyyyyyyyyyyyyyyyuuuu|<br></br>
`?????>>>>++>???????????uyv.`>uyxv??>+>>>>>>+++>>?v}wuyyyyyyyyyyyyyyyyyuuu|<br></br>
.+>>>>>>>>>>>??????????}>^. .~vyuv???>>>>>>>??}wxuuyuuyyyyyyyyyyyuuuyyyyuu|<br></br>
.>>>>>+|~``~|>?????????vv~`.`-?urv???>+>+>vwuyyyyyyuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
.>+>+~~?v|.|}xzzv???????vv>+>>?v??????>>?rwvvrzuuyuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
.>+|-^uyyz``|wyyxv????????????????????>>>}?|+>?rxuuuuuyyyyyyyyyyuyyyyyyyuy|<br></br>
.>>`.?}xx?..`^zyyr????????????????????>>^--+rxuuyyuyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
`??>|w?~-.  .~?yxv????????????????????>>vwxyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
`????ru?--`.`||rv????????v}zz?????????+>>?ruyyyyyyyuuuyyyyyyyyyyyyyyyuuuuy^<br></br>
.>>??v}z}+~~|~^???????vrzxzv?????????>+>>>>vzyyyyyyuuuuyyyyyyyyyyyyuuuuuuy^<br></br>
`?>>???v}w?++>??????vwzwv+~-~????????+>>>?wrzyyuyyyyuyyyyyyyyyyyyyuuuuuuuu^<br></br>
`vv?>>>??????????????????????????????>>>?zyuuyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`vvv??>>>>??????????????????????????r}v}wyyxzuyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`vv?>?>>>>>>>??????????????????????rxyxuyyuxuyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`}v?>>>>>>>vwv>>?????vz}??????????vzwrxyyyyyyyyuyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`vvv?>>>>>?wzv>?ww?>>vwv????????^-``.~xyyyyyyyyuyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>????v?vzyw}?>?>>>?????????v>^??`..?yyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>>>?wuw?v}v?|?wvvv??????vrzw+?ux|..vyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.>>>>>???>+>>>+vzuyyxxzwwwxuyxzxux|..>uyyyyyyyyyyyyyyyyyyyyyyyyuuuyyuuuuuu^<br></br>
.>>++++++>??>>+>vzuuyyyyyyyuuzuyyy}`.`?zyyyyyuuyuyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
.>>>>++>>wyw>>>>>?wzzxxxuuuuuxzuzv^....|?rxuuuuyuuyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
.>>>>?vr}wuz?>>>>}rwxuxzxxuuuxxxv`..... .`~+>>?zuuuuuuuuuuuuuuuuuuuuxxuuuu|<br></br>


            </div>,

            <div>

`rr}}vvvv}}}}}}}vvvvvvvvvvv}}rrrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
-xuxzzwwrwwzzxxxzw}}}}}}vvv}}rwxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`wzxxxxzzzzzwwr}vv??????vv}}}}rwzxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuy^<br></br>
`wwwwwwwzzzw}vvv????????vv}rwwzzzzxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`rrwwwwrrrrr}v????>>?>>>>??v}wzxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`zxxxxzr}v?????>>>>>>>>>>>>???vrzxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`zw}v}v????????>+|~~~~^>+>>>>>??v}wzuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`}}v????????????^^|+>?>+>???>>>+>??vvwzzxuuuuyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`}}??>>>>>?????>?w}>>v}}v>>>++++>>>>>??vvv}rrwzxuuuyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`vv?????>>????>?www?+>vrvv?vvv???>>>>>>>>??????v}rwzxuuyyyyyyyyyyyyyyuuuuu|<br></br>
.>????????????>+?v?^|^+vxyr>?rrr???>>>>>>>>>>>>>>?v}wxuuyyyyyyyyyyyyyyuuuu|<br></br>
.~^>??????????v?^~~~~|~?yy}^^?rr}???>>>>>>>>??vv}wzzxuuyyyyyyyyyyyyyyyyuuu|<br></br>
 `|vv??????????vv+^^^+^v}?+~~+vrr???>+>>>>?}wxxuuuyuuuyyyyyyyyyyuuuyyyyyuu|<br></br>
.``^v}???>>?????v??????vv>+|~^?}v????>+>?vwuuyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
.``~?vv}v?+>v}}vv????????v???????????v?>?rzwzzuuyyuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
 ```~ryxr}+^>vrr}v?????????????????????>>v?+?}zxuuuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
 ....}rr}?^~^>vrrv??????????????????+~~+>>?vxuuuyuuyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
.^|~|vv>>^~~|^?ww}vvv????????????????>>}zuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
.????vr?+^||+?}xw}vv??????vvv????????>?wzzuuyyyyyyyuuuyyyyyyyyyyyyyyyuuuuy^<br></br>
.?????vvv????????>>>??vvv}vv????????>+>vwzzxuyyyyyyuuuuyyyyyyyyyyyyuuuuuuy^<br></br>
`???????v}v?++>??????v}vv??????v????>+>>vzuuuyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
`??????????????????????????????vv???>>>>>wyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`?????????????????????????????v}r}??vvvvvzuuuyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`??>>>>>>>>???????????????????}rrrvv}zxuxuyuyyuuuyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`???>>>>>>?rz}??vv???}z}??????vrrwzwvvrxuyyyyuuxuuyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.??v?>>>+>vzzv??wzv??vrv???????}vv?>-~}uyyyyyyuuyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>?v}v}}vvzyz}????????????????^^v?`..>yyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>?vwuyuzzxz}?v}vvv?????vv}}?|?ur`.-wyyyyyyyyyyyuuuuyyyyyyyyyyyyyuuuuuuuy^<br></br>
.>++>?vrwwwwwwwzuxuxzwwrwxxuuxzxzv-`>wuyyyyyyyyyyyuuuyyyyyyyyyyuuuyyuuuuuu^<br></br>
.>>+++++>?}wrwwwxuyyyyyyyuuuuuuuwv?|>vzuyyyyyuuyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.??>>>>>>vzwv?v}}wxuuuuuuuxuuuuu}+-`^?vrzxuuuuuyuuuyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`vv?>>???vrwv?vvvwwzuuuuuuuuxzzz>...~^>??vv}rrwxxxxxxuuuuuuuuuuuuuuuuxuuuu|<br></br>


            </div>,

            <div>

`rr}vvvvvvvvv???>>>>>>?????vv}rrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
`zzzwwwrr}}}v?????????vvvv}}rwxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`}vvvvv????v?>>>>>>>>?v}wzzxxxxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`rv????>>>>>>>>?>>++>??vrwxxxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`???????>>>^|^^||^~>>+>??v}rwzxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
.?????????^~~+??>+~^|>>>>>?vv}rwzzzzzxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
.>>>?????>?rw?+?r}vv????>+>>??vvv}}}}}rrwwwzxxuuyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
.>>>????^vxzxz^`|rzxxrv???>++>>>>??????????vvv}rwzuuyyyyyyyyyyyyyyyyyuuuxx|<br></br>
.??????+~?zzw?-`~vuzzuwvv??>++>>>>>>>>>>>>>>>>>???v}wxuyyyyyyyyyyyyyyuuuxu|<br></br>
.??????v?-|^~..->>rzww}vvv??>++>>>>>>>>>>>>>>+++>>??vrxuyyyyyyyyyyyyyuuuuu|<br></br>
.??????vr>-`...-+^+}}??vvvv?>>+>>>>>>>>>>>>>>??v}wzzxxuuyyyyyyyyyyyyyyuuuu|<br></br>
.???????vz?^~~^>~-->>>??vvv???>+>>>>>>>>>>>vwxuuuuyyyuuyyyyyyyyyyyyyyyyuuu|<br></br>
.>?vvv???vv????v?>>>????v?????>+>>>>>>>>?vvuyyyyyyuuuuyyyyyyyyyyuuuyyyyyuu|<br></br>
.>??vvv?????????v??????????????>+>>>>>+?}ruyyuyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
.>??vvv?????????????????????????>>vwwv?>>vzuyyyuyuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
.>???vvv????????????????????????>^|>>>?>>>>?rxuyyyuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
.>>??vv??vv}}vv????????????????>+|````|>>??vwzuyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
.>>?vv}rwwwwr}v?vvvvv??????????>+>+^^^?}wzxuuyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.?vrwrv??>+++>?vvvvv???????????>+>>>++?uyyyyyyyyyyyuuuyyyyyyyyyyyyyyyuuuuu^<br></br>
`?}?>+^^^^^||||???????????????v?+>>>+++wyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
`??>^^^^^^^|-``+?????????????vvw?>>>>+^?uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`???>++>????>????????????????}zuw?+>>>++}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.????>???????????????????????wuyu}>>>>??vxyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>>????????v?????????v??????wyyyz?++?zx}zyyyyyyuyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>>>?????vzx}??}}v?vrxv??>>vzyyyuv|~|>??wyyyyuuuyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>>???????}xx}?vrz}??v}v?>++>vzxw}?|``~>}xuuyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.vvrrrwwrvzux}??????????>+++^|^??~....^?wxuuyyyyuyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`zxxuuyyyuuuuwvvv????>>+>v}?~.-}u?..`>vzuuyyuuuuuuuuyyyyyyyyyyyyuuuuuuuuuy^<br></br>
`wzxxuuyyyyyyyyuuxzr}vv}wyyyz}}}?~`-vuyyyyuuxxxuyuuyyyyyyyyyyyyuuuyuuuuuuu^<br></br>
.>???vv}rzxuyyyyyyyyyyuuyyuuyyz>`->wyyyyyyyyuuuuyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.??>>>>>>>?vrxuuyyyyyuuuyuxuyx?^~>}xuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
.???>>??????}xuxxxzxuxxuyuuuuv||>?vwzxxuuuuuuuuuxxxxxuuuuuuuuuuuuuuuuxuuuu|<br></br>


            </div>,

            <div>

`vvvv?????????????????????vvv}rrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
`vvvvvv}}}}}v????????v}}}rrrwwzxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
.>>+>>>???vv??>>?????v}wzzzzzzzzzzzzxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.>>+++++>>>?????>>>???v}rrwwwwwwwwwwwwzzxxuuuyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
.~^+>?>>>>>+^++^^+^>>>>????vv}}}}}}rrrrwzzzxxuyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
.--|??????+|~>???>^++>++>>?????vvvvvvvv}}rrwzxuyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`??>?????+>}wv>?rwrv???>>>>>?????????????vvrwxxuyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`}vvv???^vzzzw^->rwwwv??>>+>>>>>>>>>>??}rrwwwwwwzxuuyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`}vvv??>~>wzw?---+wzzr???>+>>>>>>?>???vrwrrrrrrr}rrwzxuyyyyyyyyyyyyyyuuuxu|<br></br>
`}}}v??v?-|^-..-~-?zzr????>+>>?vr}?????}wrrrrrrrrrwwwzuuyyyyyyyyyyyyyuuuuu|<br></br>
`}}}}v?vr>--`..-~~+wr??????>?}wwwr}???v}r}}wwwzxxuuyuyyyyyyyyyyyyyyyyyuuuu|<br></br>
`}}}}v??vr>~~~~|||>v???????>>?vvvrrr}}vv}}?xuuyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
`}}vv????vv????????vvvv?????>+>>>?}r}v??}rvuyyyyyyyuuuyyyyyyyyyyuuuyyyyyuu|<br></br>
`}v????????????????}}}v?????>+>>+>>?vv?v}}xyyyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
`?????????????????????>>?????>>>>>?rrv?v}xuuyyyyyuuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
`???????????????????>>>??????>+>?>^?>>}rwwwzxuuyyyuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
`???????vvvvvv????????????????v?>^```~rwrrrwzxuuyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`???vv}}rrvvvv??????????v}}r}}w?|~-~`~vzzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`?vrwrv??+||~~+??????????v}r}rw^----``+uyyyyyyyyyyyuuuyyyyyyyyyyyyyyuuuuuy^<br></br>
`?}?>+^^^~---~^????????????vvrw^``-```-}yyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
`??>++++^|^||||+?????????????rw?~--````+uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`???>>+>>??>>>>>?????????????}zr+--````-}yyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.??????????>>>>>?????????vv?vxyz?|---|>>?uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.???????????v????v}}}rwwr}v}wuyur>-`->zw?wyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>???????vrxu}??}zzwwxuxrv}rxuyyuv```-^^~?yyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
.>??v???v}xuur??}zwrrzzw}}rrwzuu}>`..`-|+vxyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.???vv}wwwxyuzv?????v}}v}rr}}}rwv~````~^?}xyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`r}}rzuyuuuuuxz}???>>>vrrzzw}?vzyr^-->vrrzyyyyuuyyyyyyyyyyyyyyyyuyyuuuuuuu^<br></br>
`rrrwzxuuyyyyyyyuw}v??vwxuyyuzrrrvv?vuyyuxxuuxxuyyyyyyyyyyyyyyyyuuyyuuuuuu^<br></br>
.^^^+>??}zuyyyyyuuuyyuxxuuuuuyz?^?v}zzuyuwrxyuuyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.----``--~^>?rwwwwwwzzzuuuuuyxv??}wwwwuyuxxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
.-----~|||-`^??>>>????vxyyyuur??rxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

.>>>>????????vvvvvvvvvvvvvvvvvvv}}rrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwww~<br></br>
.???vvv}}}}}}}}}rr}}}}}}}v}}}}rrrwwzxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
.>>>???vvvvvvvvv?????????????????vvv}rwxuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.^+++>>>>??????????????????????>>>>???v}wxuuuyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
.~||^+>>>>>>>>>???????>>>>>>>>>>??vvv}rwwzzzxuyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.--~++++>>>>>>>>>>>>>>>>>>+>??v}rrrwrwwzzzzzzxuuyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`??>>>>++>>>>>>>>>>>>>>>>?v}rwwwwwwwwwzzxxxuuuuyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`}}v??>>++>>>>>>>>>>>>>?vrwwwwwwzzzwzzuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
`}}}v??>>++>>>>>>>>>?v}rrrrrwwwzzxwrwwzyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
`rrr}???>+++>>>>>>>?vr}}rrrwzzxuyu}vvv}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`rrrrv????>??>>>>>>>>??vrrwzuyyyyyx}}}wuyxuyyyyyyyyuyyyyyyyyyyyyyyyyyyuuuu|<br></br>
`wwwwv???}}ww?+>>>>>>>+>?vrzxuuzzuyuuuxxuxvuyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
`rr}v????}rrr?|+>>>vvv?+>>>?r}>++?xuuzvvrrvuyyyyyyyuuuyyyyyyyyyuuuuuyyyyuu|<br></br>
`}v?????>^|~``~>>>>}rr?++>>>?>^^^^?wzrv}}vxyyyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
`????????+-.`|+>>+^+>+^+?v}w}>>++|>vv}}rzyyyyyyyyuuuuuyyyyuuuuuuuuuyyyyyyy|<br></br>
.?????????>++>>>>>>???vrzzzz}??>???v??zuyyyyyuyyyyuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
.?????????>>++>?}rwzzzzzzzxxxzz?^>++~^yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
.?????????>>++>>vwzzzzzzuyyyyyy>`....`ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.????????>>>+>>>>?}wzzzzxuyyyyz-......~uyyyyyyyyyyyuuuyyyyyyyyyyyyyyuuuuuy^<br></br>
.????????>>++>>>>>>?rzzzzzxuyyr`.......vyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
.???????>>>++>>>>>>>>vwzzzzzxuw`..... .|uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.???????>>+++>>>>>>???}wwwwzzzz|..... .`vyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.??????????vvvv}}rwzzzzxxxxuuuy?.....`^|+uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.?????>>>}xxxxzzxuuuuuuuuuyyyyyw^....+wv^ryyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>???>>>>}xyyxzzxuuuuyyyuuuuuuuuw|...`--`+uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
.>?v}?>>?rxyyuzzxuuuuuuuuuuuuuuuv+..`-~~|+wyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
.???rrrwzwxuyuxzzzzzxuuuuuuuuuuur+++>>>???wyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`v??vrxuxzzzxxuxzwwwwzuuuuuuuuuuuwv?>}zr?vyyyyyyyyyyyyyyyyyyyyyyuuyuuuuuuu|<br></br>
`r}??v}wzxuxxxuuuzwwwwzxuuuuuuuuyuuw?xyyzrwyyyuyyyyyyyyyyyyyyyyyuyyyuuuuuu|<br></br>
`}}vv?vvrzuuuuuuuxuuuuxuuuuuuuuurw?>??wyz>~wyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.????????vv}rwwwrrrwzxxuuuxuuuuuzr?+^>wyxrrxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
.----~^>??vvvvvvvvvvvvrxuuuuuxuuuuuuxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`vv?????????????????????????vvvv}}rrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwwrrwwrw~<br></br>
`?vvvvvvvvvvvvvv????????????v}rrwzzxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
.+>>??????????>>>>>>>>>>????vvvvvv}rrwxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
.^+>>>>>>>>>>>>>>>>>???????vvvvvvvvvv}rzuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
.>>>>>>>>>>>>>>>>????vvvvvvvvvvv}rwwzxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>>>>>???vvvvvvvvvv}wzxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.+>>>>>>>>>>???vvvvvvvvvv}wxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
.+>>>>>>>>??vvvvvvvvvv}wzuuuuuyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
.+>>>>>>>>???vvvvvvvrwzxxuuuuyyyyyxwwzxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
.?>>>>>>>>+>>??vvvvrzxxxxuuuyyyyyu}vvvruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
`}?++>>>>>???>>??vvv}}wxuuuyyyyyyuz}}}wuyxuyyyyyyyyuyyyyyyyyyyyyyyyyyyuuuu|<br></br>
`?>++>>>vwzxz?+>>>?vvvv}wxuyyyuzzuuuuuxxuz?xyyyyyyyuuuyyyyyyyyyyyyyyyyyuuu|<br></br>
.^^^>??>?wxzw>~+>>>?vvvv}}wzxr>^^vuuuzv}zr?xyyyyyyyuuuyyyyyyyyyuuuuuyyyyuy|<br></br>
.+++>>>+~~||--^??vvvvvvv}}r}?+||^^rzxrv}}?}uyyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
.+>>>>>>+|||^?v}vvvv}rrwzxxx}>+|~|?}rr}}wxxyyyyyyuuuuuyyyyuuuuuuuuuyyyyyyy|<br></br>
.+>>>>?vv}vvvv}}}rwzxuyyyyyur?>>>??vv?wxuyyyyyuuyyuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
.>>>>>?vvvvvvv}zuuyyyyyyyyyuuzxv>?>>++uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
.>>>>>>>?vvvvv}rxyyyyyyyyyyyyyy?``...`ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>??vvvv}rwxyyyyyyyyyyyx|......~xyyyyyyyyyyyuuuuyyyyyyyyyyyyyuuuuuy^<br></br>
.>>>>>>>>>>??vvvvv}zuyyyyyyyyyr`.......vyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
.>>>>>>>>>>>>?vvvvv}wxuyyyyyyy}`.......+yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>>>>>>>>>????vv}}rrwzxuuyyyyyz-..... .~xyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
                  .?????vvrzzxxxxxxuuuuuuuuuuyyyx+.....-^^ryyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`vvvvvvvwuyyyyuuuuuuuuuuuuuuuyxv~....+r>vuyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`vvvvvvv}wuyyyuuuuuuuuyuuuuuuuuw?-...```|}uyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
.?vww}}}}wuyyyuuuuuuuuuuuuuuuuuxv~...``-|vuyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
.>vzuuuuuzxuyyuuuuuuuuuuuuuuuuuxv||||^^++vuyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`rvrxuuuuzwwzxuuuuuuuuuuuuuuuuuuzv>+>?}v>vyyyyyyyyyyyyyyyyyyyyyyuuyuuuuuuu|<br></br>
`xzrwxxxxxxzwzxuuuuuuuuuuuuuuuuyuzrv?wxz}wxyyyyyyyyyyyyyyyyyyyyyuyyyuuuuuu^<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzz?+}rxxwrvxyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
                  -xuuuxxuuuuuuuuuuuuuuuuuuuuuuuuuuxv?vrxuxwzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`}vvv}wzuuuuuyyyyyyuuuuuuuuuuxxzxzzxzxuxwwxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`}}vvv????????????????????vvvrrrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwrwrwwrw~<br></br>
`zww}vvvvvvvvvvvvvvvvvvv}}rrrzxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
`wwwr}}vvvvvvvvvvvvvv}}rwzzxxxxxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`wwwwrr}}vvvvvvvvv}wzxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
`wwwwrr}}}vvvvvv}wxxxxuuuuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`wwwwrr}}}vvv}wzxxxxxxuuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`wwwwrrr}}}rwzxxxxxxxxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
`wwwrrrr}rzxxxxxxxxxxxxxxuuuuyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  `wwrrrrrrrwzxxxxxxxxxxxxxuuuuyyyyxwwwzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-xzwrrrr}}}rwzxuuxxxxxxxxuuuyyyyyrvvvvzyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-yu}rrrrrrrrrwwzxuuuuuuuuuuyyyyyyx}}}rxyuxxyyyyyyyyuuuyyyyyyyyyyyyyyyyuuuu|<br></br>
`w}?vrrrrrrwwwwwzxuuuuuuuyyyyxwwxyuuuuxxuw?zyyyyyyyuuuyyyyyyyyyyyyyyyyyuuu|<br></br>
`???}rrrwwwwwwwwzzxuyyyyyyyy}+^^>zyuuwv}xw^}yyyyyyyuuuyyyyyyyyyuuuuuyyyyuy|<br></br>
`v}rrrrrwwwzzxxuuuyyyyyyyyyw+||^|?xzz}vrr^-ryyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
`rrrrrrwxxxuyyyyyyyyyyyyyyuv+||~->}rwr}}?+vuyyyyyyyuuuyyyyyyyuyyuuuyyyyyyy|<br></br>
`rrrrwxuyyyyyyyyyyyyyyyyyyz?>++>+?vvvv}wzuyyyyyyyuuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
`r}}}rzuyyyyyyyyyyyyyyyyyyuwrwxv?????+zyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`}}}}rrzuyyyyyyyyyyyyyyyyyyyyyy?--``.`wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  `rrrrrrrwzuyyyyyyyyyyyyyyyyyyyu|......~zyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuy^<br></br>
                  `rwwwwwwwwwzuuuuuuuuuuyyyyyyyyr`.......vyyyyyyyyyyyuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
`rwwwwwwwwwwzxuuuuuuuuuuyyyyyy?........>yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  `rwwwwwwzzxxxuuuuuuuuuuuuuyyyy?...... .+yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  `xxxuuuuuyyyyyuuuuuuuuuuuuuyyy?......`|^xyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyyuuuuuuuuyuuuuuuy?......~^|ryyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  `uuuuuuuyyyyyyuuuuuuuuuuuuuuuyw^`......`?yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
`zuuuuuuyyyyyyuuuuuuuuuuuuuuuuuw>.......^uyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`wxuyuuyyyyyyyuuuuuuuuuuuuuuuuur>....```~}yyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
`wwxyyyyyyyyuuuuuuuuuuuuuuuuuuu}^|~|+>>>>vyyyyyyyyyyyyyyyyyyyyyyyuyuuuuuuu|<br></br>
`wwzxuuuyyyuuuuuuuuuuuuuuuuuuuuuv?>>???>?zyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`zxxxxuuuuyuuuuuuuuuuuuuuuuuuuuuuz?+zyuv}yyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
`zxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuw}uyyxrvuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuuuuuuuuuuuuuxxz}z?>>>wxw^`vuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwwwwrrwrwwwwwwwwwwwwwwwwwwwrwwwwrw~<br></br>
                  -yyuuxzzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyuuuxzzzzzzzzxxxxxxxxxxxuuxxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxxxuxxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxu|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyyuuuuuxxxxxxxxxxxxxxxuuuuyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyuuuuuuxxxxxuxxxxxxxxxuuuuyyyyxwwwzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyyuuuuuuuuuuuuuxxxxxxxxuuuyyyyurvvvvxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyyuuuuuuuyyyyyyuuuuuuuuuuyyyyyyz}v}rxyuzxyyyyyyyyuuuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyyuuuuyyyyyyyyyyyyyuuuuyyyyxwzuuuuuuxxuw>wyyyyyyyuuuyyyyyyyyyyyyyyyyyuuu|<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyuv+^^?xuuurvrxr|vyyyyyyyuuuyyyyyyyyyuuuuuyyyyuy|<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyr^|||^vxxzvvrr^-vyyyyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
-uyuuuuyyyyyyyyyyyyyyyyyyyx?+|~~-?rwzwr}v^?zuyyyyyuuuuyyyyyyyuyyuuuyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyur?>^^++?vvvv}wwzxuuyyyyyuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxxuuuyyyyyyyyyyyyyyyyyuzrrwx}?????>wuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
                  -uuuuuuuyyyyyyyyyyyyyyyyyyyyyyyv~|--``wyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  -uuuuyyyuuuuyyyyyyyyyyyyyyyyyyu+......|xyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu^<br></br>
                  -uyyyyyyyyuuuuuuuuuuuuyyyyyyyyr`.......?yyyyyyyyyyyuuuyyyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuyyyyyy?........>yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuyyyyyyyuuuuuuuuuuuuuyyyy>...... .+yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuyyy>.......`+uyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyyuuuuuuuuuuuuuuyy>......`-^zyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuuuv~`......~ryyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyyuuuuuuuuuuuuuuuuw?|......-vyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuyuuuyyyyyyyuuuuuuuuuuuuuuuux}^.....`-?zyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuyyyyyyyuuuuuuuuuuuuuuuuuuuuv~---~|^>?wyyyyyyyyyyyyyyyyyyyyyyyuyyuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuuuuuuuuxw>^^+>??>?uyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`zxxxxxuuuuuuuuuuuuuuuuuuuuuuuuxw}>^}zzvryyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  `xxxxxxuuuuuuuuuuuuuuuuuuuuuuuuuzwvvxuzzzwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuuuuuuuuuuuuuxxzwz?>vvzzw?>ruuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwrwwrrwrwwwwwwwwwwwwwwwwwwwwwwwwrw~<br></br>
                  -yyuuxzzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyuuuxzzzzzzzzxxxxxxxxxxxuuxxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxxxuxxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxu|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuxxxxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuuxxxxxxxxxxxxxxxuuuuyyyyuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyuuuuuuxxxxxuxxxxxxxxxuuuuyyyuzwwzxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyuuuuuuuuuuuuuuuxxxxxxuuuuyyyyzvvvvruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyyuuuuuuuyyyyyyuuuuuuuuuuyyyyyxvvvvwuyuzxyyyyyyyyuuuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyyuuuuyyyyyyyyyyyyyyuuyyyyxwwxyuuuuxxuu}+ryyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyuv+^^>wyuuxvvrxr~?yyyyyyuuuuyyyyyyyuuuuuuuyyyyuy|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyy}^|^^^?xxxrvvrr^`>uuyyyuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
-uyuuuuyyyyyyyyyyyyyyyyyyw?>^||~~vwzzwrrv~|?uyyyyyuuuuyyyyyyyyyyuuuyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyu??>^||~>vv}}}vv?>?wyyyyyyuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxuuuuyyyyyyyyyyyyyyyyu}vvvzx}???vv?>}zuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`xuuuuuuuyyyyyyyyyyyyyyyyyyyyyy?>>+^|-vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
-uuuuyyyyuuuuyyyyyyyyyyyyyyyyyy>`.....+xyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu^<br></br>
-uyyyyyyyyyuuuuuuuuuuuyyyyyyyyw-.......>yyyyyyyyyyyuuuyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uyyyyyyyyyyuuuuuuuuuuuuyyyyyy?........~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uyuuuuuyyyyyuuuuuuuuuuuuyyyyu^........^uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuyz-........>yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyyuuuuuuuuuuuuuuyw`........?yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuyw`...... .?yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuuuz^`..... .>yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyyuuuuuuuuuuuuuuuuuuz?.......^uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuyyyyyuuuuuuuuuuuuuuuuuuuuw?.......-wyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuuuuuuuuuu?~`.``|~|^vyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`zxxxxxuuuuuuuuuuuuuuuuuuuuuuuv>++>>>>??ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
`xxxxxxuuuuuuuuuuuuuuuuuuuuuuuxv?>?vrv|}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuuuuuuuuuuuuuxxxu}~>uuz?wuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwrwwrrwrwwwwwwwwwwwwwwwwwwwwwwwwrw~<br></br>
                  -yyuuxzzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyuuuxzzzzzzzzxxxxxxxxxxxuuxxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxxxuxxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxu|<br></br>
                  -yyyyyuuxxxxxxxxxxxxxxxuuuuuxxxxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuuxxxxxxxxxxxxxxxuuuuyyyyuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyuuuuuuxxxxxuxxxxxxxxxuuuuyyyuzwwzxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyuuuuuuuuuuuuuuuxxxxxxuuuuyyyywvvvvruyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyyuuuuuuuyyyyyyuuuuuuuuuuyyyyyxvvvvwuyuzxyyyyyyyyuuuyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyyuuuuyyyyyyyyyyyyyyyuyyyyzwwuyuuuuxxuu}+ryyyyyyyuuuyyyyyyyyyyyyyyyyyyuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyuv+^^>zyuuzvvrx}~?yyyyyyuuuuyyyyyyyuuuuuuuyyyyuy|<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyv^|^^^?xxz}vvr}^`>uuyyyuuuuuyyyyuuuuuuuuuuyyyyyy|<br></br>
-uyuuuuyyyyyyyyyyyyyyyyyur?>^|~~|}wzzwrrv||?uuyyyyuuuuyyyyyyyyyyuyyyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyx??>^|~~>vv}}}vv?+?ruuyyyyuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxuuuuyyyyyyyyyyyyyyuyx}vv}zx}???vv?>}zxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`xuuuuuuuyyyyyyyyyyyyyyyyyyyyyy?>>++|-vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
-uuuyyyyyuuuuyyyyyyyyyyyyyyyyyy>`..`..+xyyyyyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu^<br></br>
-uyyyyyyyyyuuuuuuuuuuuyyyyyyyyw-.......>yyyyyyyyyyyuuuyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uyyyyyyyyyyuuuuuuuuuuuuyyyyyy?........~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uyuuuuuyyyyyuuuuuuuuuuuuyyyyu^........|uyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuyz-...... .+yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyyuuuuuuuuuuuuuuyw`...... .>yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuyw`...... .?yyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuuuw^`..... .?yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuyyyyyuuuuuuuuuuuuuuuuxxr?.......>yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuyyyyyuuuuuuuuuuuuuuuuuuxx}?......`^zyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuuuuuuux>~...`~~^+vyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
`zxxxxxuuuuuuuuuuuuuuuuuuuuuuxv+^^++>>??ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
`xxxxxxuuuuuuuuuuuuuuuuuuuuuuuw?>+>?}?+ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuuuuuuuuuuuuuxzxzv->zuwvwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwrwwrrrrwwwwwwwwwwwwwwwwwwwwrrrrrw~<br></br>
                  -yyuuxzzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyuuuxzzzzzzzzxxxxxxxxxxuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxuuuuuuuxxxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuxxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyuuuuuuxxxxxuuxxxxxxxxxuuuuuuzwwwxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxxxxxuuuuuuzvvvv}xyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyyuuuuuuuyyyyyyuuuuuuuuuyyyuwvvvvvwuyuzvruyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
                  -uyyyyuuyyyyyyyyyyyyyyuuuxwwzuuuxxxzxxuuz+|?uyyyyyyuuuyyyyyyyyyyyyuuyyyyuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyuzwv>+++vzyuuxwv}wxu?^^ryyyyyuuuuyyyyyuyuuuuuuuyyyyyy|<br></br>
-uyyyyyyyyyyyyyyyyyyuzwv+^^^^>vuuxwvvvvzx>|^?zuyyyuuuuyyyyuuyuuuuuuyyyyyyy|<br></br>
-uyuuuuyyyyyyyyyyyyuuwv?+^^^^^>wxuxz}}zxr|~>?rxuyyuuuuyyyyyyyyyyyyyyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyuxx}??>^~-`->rzuuxzzwrv+++?}xuyyuuuuyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxuuuuyyyyyyuyyuuxx}?>^+>>?v}}}}}}vv?>++>?vwzuyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
-xuuuuuuuyyyyyyyyyuuuxxzzxyyyyxv?v????>``+}zxxuuyyyyuyyyyyyyyyyyyyyyyuuuuy^<br></br>
-uuuyyyyuuuuuyyyyyyyyyyyyyyyyyx+>?>?>^|?rzxuyyyyyyyuuuuyyyyyyyyyyyyuuuuuuu^<br></br>
                  -uyyyyyyyyyuuuuuuuuuuuyyyyyyyyx~`..`..`vyuyyyyyyyyyuuuyyyyyyyyyyyyuuuuuuuy^<br></br>
                  -uyyyyyyyyyyuuuuuuuuuuuuuyyyyyr`.......~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuyyyyy>........`}yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuyyx~.........?yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyyuuuuuuuuuuuuuyyv`.........?yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuuu|.......  ~zyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuyv`......  .?uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyyuuuuuuuuuuuuuuuu+.......  ~zyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyuuuuuuuuuuuuuuuuuw^....... .vyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
`xxxxuuuuuuuuuuuuuuuuuuuuuuw?>`..... -uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
                  `zxxxxxuuuuuuuuuuuuuuuuuuuxr?^..... .?yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
`xxxxxxuuuuuuuuuuuuuuuuuuuxv>|..... `}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyuuuuuuuu}-.......^vxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwrwwrrrrwwwwwwwwwwwwwwwwwwwwrrrrrw~<br></br>
                  -yyuuxzzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyyuuxzzzzzzzzxxxxxxxxxxuuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxxuuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxx|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuuxxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuxxxxxxxxxxxxxxxxuuuuuuxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyyuuuuuxxxxuuuxxxxxxxxxuuuxzwwwwzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxxxxxuuuuz}vvvvvwuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyuuuuuuuuyyyyyyuuuuuuuyyyyxv?????vzyyuv?vzyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyyuuuyyyyyyyyyyyyyyurvv}zuxxxzzwzxuuu?||^}yyyyyuuuuyyyyyyyyyyyuuyyyyyuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyx?^^^^^}yyyuuzvvwxuw^^^>uyyyyuuuuyyyyyuyuuuuuuuyyyyyy|<br></br>
-uyyyyyyyyyyyyyyyyyyz>||^^^^+xyuxwvvvvrxx+|^>vxyyuuuuuyyyyuuyyuuuuuyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyurv>^^^^^^|vxuuzw}v}xzr|`|??vxyyuuuuyyyyyyyyyyyyyyyyyyyy|<br></br>
-uuuuuuyyyyyyyyyyyr???|~~~~``?ruuuuxzzzw}+^>>?vryyyyuuyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxuuuuyyyyyyuyyxv?>+^~-`|+?v}rwzzwr}vv??>>+>?ryyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
-xuuuuuuuyyyyyyyyuxwvvwwwzuyxrrvvv?????^.~++>?ruyyyuuuyyyyyyyyyyyyyyyuuuuy^<br></br>
                  -uuuyyyyyuuuuuyyyyyyyyyyyyyyyyx?vvvvvv?`.`^ruyyyyyyuuuuyyyyyyyyyyyyuuuuuuu^<br></br>
                  -uyyyyyyyyyuuuuuuuuuuuyyyyyyyyw~||~|~-`~vrxyyyyyyyyyuyyyyyyyyyyyyyuuuuuuuy^<br></br>
                  -uyyyyyyyyyyuuuuuuuuuuuuuyyyyyu|`..-...~xyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuuuyyyyyuuuuuuuuuuuuyyyyy}`........?yyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuyyu+.........-zyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuuy}`..........}yyyyyyyyyyyyyyyyyyyyyyyyyuuuuyuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuuu^..........`zyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuuuuuuuuyv........  .?yyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyyuuuuuuuuuuuuuuux~.......   ^uyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyuuuuuuuuuuuuuuuuuu?........  `zyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`xxxxuuuuuuuuuuuuuuuuuuuuuu^........ .}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
                  `zxxxxxuuuuuuuuuuuuuuuuuuuz~....... .?yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuy^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuuuw`...... .^uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -xuxxxxuuuuuuuuuyyyuuuuuuux?-..... .ruuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrrwwrwrwrrrwwwwwwwwwwwwwwwwwwwwrrrrrw~<br></br>
                  -yyuuxxzzwwzzzzxxxxxxxzxxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyuuuxzzzzzzzzxxxxxxxxxxxuuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxzzzzzzxxxxxxxxuuuxxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuxxxxxxxxxxxxxxxuuuuuuxxxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuxxxxxxxxxxxxxxxxxuuxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
                  -uyuuuuuuuxxxuuuxxxxxzzzzxzzwwrrzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxzzzzxxz}}vvv}wxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
                  -uyuuuuuuuyyyyyyyyuxxxxxxuzvvv??vvrwuyurvvwuyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyuuuuyyyyyyyuuuuxzr}}}wxzzzzwwwzxuuuv^|^>ruyyyyuuuuyyyyyyyyyyyyuyyyyyuu|<br></br>
-uyyyyyyyyyyuuxxxxx}>+^+>>}uuuuxxw}}wzxu?^^^+vxyyyuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
-uyyyyyyyyyuxxxxxzv+^^^^++>zyuuwvvvvv}zuz+^^>??}uyuuuuyyyyuuuyuuuuuyyyyyyy|<br></br>
-uuuuuuyyuuxxzzz}??^^^^^^+^vxuzw}}vvvrzxx>-~>???}xuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
-uuuuuuyuxxxzzwv>??^|^^^^+^?ruzwwzw}}rzxwv^^++?v?vwuyyyyyyyyyyyyyyyyyyyyuy^<br></br>
-uuxuuuuuxxxzz}?>>>^---`-|>}wzxuuxxzzzzw}rw}>++>??ruuyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xuuuuuuuuxxzzw}vv???+^>vrxzwrrwzwr}vvvvwuyz>^++>vxxxuyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyyyyyyuuuuxzzzzzzzxuyyyyuxxz}vvvvvvv}zv???>>?}xxxxxuyyyyyyyyyyyyuuuuuuu^<br></br>
-uyyyyyyyyyuuuuuxxuuuuuuuyyyyyx}}}v}}}}v^-`|>}zxuuxxxxuyyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuuuuuyyyyy}^+^^++|~-~|+?wuuxxuuuuuuyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uyuuuuuyyyyyuuuuuuuuuuuuuuuuuw^|`-|~.-``vyyyyyuuxxuuuuuyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuuuuuxxuv-```-`.``-ryyyyyuuuxxuxxuyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyyuuuuuuuuuuuuuuuz|.........`?yyyyuuuuxxxxuyyyyyyyyyyyyuuuuyuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuuuux>...........-wyuuuuuxxxxuyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuyv`............}uuuuuxxuuuyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyyuuuuuuuuuuuuuuux~............?xxxuxuuyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyuuuuuuuuuuuuuuuuuu?............?xxxxxuyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  `xxxxxuuuuuuuuuuuuuuuuuuuuw-...........?zxxuyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu^<br></br>
                  `zxxxxxuuuuuuuuuuuuuuuuuuu?..........`?zzzxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
`xxxxxxuuuuuuuuuuuuuuuuuuu^.........-}xzzzxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xuxxxxuuuuuuuuuyyyyuuuuuw~........`}zzzwwzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rrr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrwrrwwwrrrrwwwwwwwwwwwwwwwwwwwwrrrrrw~<br></br>
                  -yyyuxxzzzzzzzzxxxxxxxxxxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyyuuxzzzzzzzzxxxxxxxxxxxuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxxzzzzzxxxxxxxxuuuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxuuuuuuuxxuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxzxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuuxxxxxxxxxxxxxuuzwwwwxyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
-uyyuuuuuuxuuuuuxxxxxwrwr}}}}}}zyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxw}}}}}}}}}}v}wuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuuuuuuuyyyyyyyyx}vv}v??vvvvv?vruyyyyyuxxuyyyyyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyuuuuyyyyyyx}v?>>++vrvvvv}vvv}wxyyyz?+++>?wyyyyuuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
-uyyyyyyyyyuwv>^^||^^|+}uuuxxxzwwwzxuuu}^^^||^vyyyyuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
-uyyyyyyyyx}?^^^^^^^^^^^}yuuxxz}vvvwxxuyw+^^^^^vuyuuuuyyyyuuuyuuuuuyyyyyyy|<br></br>
-uuuuuuyywv?+^^^^^^^^^^^>uuxzw}vvvvvv}wxyv^^^^^>ruuuuuyyyyyyyyyyyyyyyyyyuy|<br></br>
-uuuuuuy}>??^^^^^^^^^^^^>xuxxrv??????vrzuuv^^^^>v}wuyyyyyyyyyyyyyyyyyyyuuy^<br></br>
-uuuuuuuv+??+^^^^^^^^^^^?uuuxzrr}vvvvvrzuyyv~-~>vvvvzyyyyyyyyyyyyyyyyyuuuu^<br></br>
-xuuuuuuuw??>||||||||--+zyuuuxxuxrvvvvwzuyyu+-^>?vvvvwyyyyyyyyyyyyyyuuuuuu^<br></br>
-uyyyyyyyyuxr?^-```.`->}uuuuuuuuuzwwwwzxuuuyz>>??>?vvvxyyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyyuxw}vv}}}}ruuuuuuuyuuuuuuyyyyuuu?>?vv?vvvryyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuyxzxuuuyyyyuuuxxxzzzzzxuuw???v?vvv}uyyyyyyyyyyuuuuuuuu^<br></br>
-uyuuuuuuyyyyuuuuuuuuuuuuuuzw}vvvv????????vxuyuw?>>??vv}uyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuxwv??vvvvvvvv?vv}xyyyurv>>>>>>vuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuuwv}vvvvvvvvv}zyyyywv?>+>++?xyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuux^~>?v??vv??>+~^?v}vv>>>++?xyyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuu+````..``..... `vv?>+>??ruyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyyuuuuuuuuuuuuuuuz^^>~.|^-..~-....|+|+rzuyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyuuuuuuuuuuuuuuuuuu>..```|^-..-`...``|?zyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuuuuuuuuu}`..............`ruyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  `zxxxxxuuuuuuuuuuuuuuuuuuu+................?yyyyyyyyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
-xxxxxxuuuuuuuuuuuuuuuuuy}`................|uyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -xuxxxxuuuuuuuuuyyyyuuuyv`.................-zuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rwr}}}}vvvvvvv}}}}}}}}}}}rrrrrrrrrrrrwrrwwwrrrrwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>
                  -yyyuxxzzzzzzzzxxxxxxxxxxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyyuuxxzzzzzzzxxxxxxxxxxxuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxxzzzzzxxxxxxxxuuuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxuuuuuuuxxxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxxuuuuuuxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -yyyyyuuuxxxxxxxxxxxxxxzxuuuuxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
                  -uyyuuuuuuxxxxxxxxxxxxxuuzzzwwzyyyyuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
-uyyuuuuuuxuuuuuxxxxxwwwwrrrr}}wuyyyuuuuyuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxwrrrrrrrr}vvvwuyyuuuyyyuuuuyyyyyyyyyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuuuuuuyyyyyyyyyuw}}rrvv}vv???v}uyyyyuxzzuuyuuyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyuuuuyyyyyyxrv??>>>vrvvvv}vvv}rzuuuw>^^+>?ruuuuuuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
-uyyyyyyyyyyzv>+++++++>}uuuxxxzwwwzzxxxv^^^||^?xuuuuuuyyyyyuuuuuuuuuyyyyyy|<br></br>
-uyuuyyyyyur?>++++++++^+}yuxxxz}vvvwzzxur+^^^^^?xuuuuuyyyyyuuuuuuuuyyyyyyy|<br></br>
-uuuuuuyywvv>+++++++++^^>uuxzw}vvvvvv}rzxv^^^^^>}xuyuuyyyyyyyyyyyyyyyyyyuy|<br></br>
-uuuuuuyr??v+++++++++^^^>xuxzrv???????vrzxv^^^^>vvruyyyyyyyyyyyyyyyyyyyuuy^<br></br>
-uuuuuuuv>?v>+++++++++^^vuuuzzrr}vvvvv}}zxx?~-~>?vvvzyyyyyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuuuuwv??+^^++^^^~-+wuxuxzzxx}vvvv}rzxxx^-^>?vvv}zyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyyyyyyyuxrv+~-----|?rxuuuuuxxxwwwwwwzxxxxr+>????}}}xyyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyyuxz}}}rrrrwuuuuuuuuuuuuuuuuuuuuu?>?vvv}vvwyyyyyyyyyyyuuuuuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuxzxuuuyyyuuuuxxxzzzzzzuuw???vvvv}}uyyyyyyyyyyuuuuuuuy^<br></br>
                  -uyuuuuuuyyyyuuuuuuuuuuuuuuzwrvvvvvv??????vxuyyzv???vvvruyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuuuuxwv??vvvvv}}vvvv}xyyyurv??????vuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyyuuuuuuuuuuuuuuwv}}vvvvvvvv}zyyyyzv?????>vxyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuux+~??vv?vv??>+~+?vr}v?????vxyyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuuuuuu>-`````--``... ~v}v?>??vwuyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyyuuuuuuuuuuuuuuuz^^>|`^^~..|~``.`+>>>wxuyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyuuuuuuuuuuuuuuuuuuu>..```|^~..-`...`-^?zyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  `xxxxxuuuuuuuuuuuuuuuuuuuur-...............rxuyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`zxxxxxuuuuuuuuuuuuuuuuuuu>```.............?yyyyyyyyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuuyr-.`..............|uyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -xuxxxxuuuuuuuuuyyyyuuuyv|`................-zuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rwr}}}}vvvvvv}}}}}}}}}}}}rrrrrrrrrrrrwrrwwwrrrrwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>
                  -yyyuxxzzzzzzzxxxxxxxxxxxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -uyyyuuxxzzzzzzzxxxxxxxxxxxuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuu|<br></br>
                  -uyyyuuuxxxzzzzzxxxxxxxxuuuuxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
                  -uyyyyuuuxxxxxxxxxxxxxuuuuuxxxxzzzzzzzzuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yyyyyuuuxxxxxxxxxxxxxxxuuuuwv???vvv}}}wxyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxxx|<br></br>
-yyyyyuuuxxxxxxxxxxxxxxxuuuz?^+???>?vv}}rxyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuz}?vvv}?>>?vvvvruyyyyyyyyyyyyyyyyyyyyyyyyyuuuxu|<br></br>
-uyyuuuuuuxuuuuuuxxxxxxxxuuyxvvv?vv?>>>>>>>?zyyyyyyyyyyyyyyyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuuuuuuuuuuyr?+~?ur>>++++^^>vuyyyyyyyyyyyyyyyyyyyyyyyyuuuu|<br></br>
-uyuuuuuuyyyyyyyyyyuuuuuyyyxv??+^^`.~>^^^^|^+?}xyyyyuuyyyyyyyyyyyyyyyyyuuu|<br></br>
-uyyuuuuyyyyyyyyyyyyyyyyuz}vvvvv>~``-||^^^^^||^+vxyuuuyyyyyyyyyyuuuuyyyyuu|<br></br>
-uyyyyyyyyyyyyyyyyyyyyxrvv?vvvv??vv?+^^^^^^^^^^^|>xyuuyyyyyuuuuuuuuuyyyyyy|<br></br>
                  -uyuuyyyyyyyyyyyyyyyyzv???vvvvvvvvvv?^^^^^^^^^^^^^vyuuyyyyyuuyuuuuuyyyyyyy|<br></br>
                  -uuuuuuyyyyyyyyyyyyyxvvvvvvvvvvv????v^^^^^^^^^^^|~?uuuyyyyyyyyyyyyyyyyyyuy|<br></br>
-uuuuuuyyyyyyyyyyyyyzvvvvvvvv??????>?^-~|^^^^^||-->uuuyyyyyyyyyyyyyyyyyuuy^<br></br>
-uuuuuuuyyyyyyyyyyyywv?vvvvv??vvv?>>?+```-~~~-```~}yyyyyyyyyyyyyyyyyyyuuuu^<br></br>
-xuuuuuuuuyyyyyyyyyywv?>>>>+++>?>+++>>-.........~vuuuuyyyyyyyyyyyyyyuuuuuy^<br></br>
-uyyyyyyyuuuuuuuuuuuuxzr?>>>>>???>>???~.......~>}xuuuuuyyyyyyyyyyyuuuuuuuy^<br></br>
-uyyyyyyyyyyuuuuuuuuuyyyzwxxzzzzzzzwr}?>^^+>>v}wxyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uyyuuyyyyyyyuuuuuuuuuuuuxuyyyyyyyyyuxzr}}rwwzxuyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
                  -uyuuuuuuyyyyuuuuuuuuuuyzwuuuuuuuuuuyyuuxxxuuuyuyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
                  -uuuuuuuuyyyyuuuuuuuuuuurruuuuyyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyyuuuuuuuuuzvvxuuxzwrr}}rrrwzzxuuuyyyyyyyyyyyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuuxvvrw}v??????????>>?vxuyyyyyyyyyyyyyyyyyyyyuuuuuuuy^<br></br>
                  -uuuuuuuyyyyuuuuuuuuuuuuvvvvvv??vvvvvvvv??vxyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyuuuuuuuuuuuuux}?>>>?vvvvvvvvvv}yyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuyuuuuuuuuuuuuuuuuuuw+.`|+>?????>??>>>vxyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
`xxxxxxuuuuuuuuuuuuuuuuuuuuw>>..............+xyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  `zxxxxxuuuuuuuuuuuuuuuuuuuxwru~............?uyyyyyyyyyyyyyyyyyyyyyyyyyyyuy^<br></br>
-xxxxxxuuuuuuuuuyuuuuuuuuu}vzv+|.-^||||`.-`ryyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xuxxxxuuuuuuuuuyyyyyuuuuu}r?.``.-||||-....|zuuuuuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rwrr}}v}vvvvv}}}}}}}}vv}}rrrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>
                  -yyyuuxxzzzzzzzzxxxxxzzzxxuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuy^<br></br>
                  -uyyyuuxxzzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxxuuuuxxxxxxxxxuuuuuuuuuuuuxzzwxyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuxrvv?vvv}wwrrr}}}}rzxyyyyyyyyyuuuuxxx|<br></br>
-uyyyuuuuxxxxxxxxxxxxxxuuuuuuuuuuuuyuv??v?v??>?vvvv??>>vzuyyyuuuyyyyuuuxxx|<br></br>
-uyyyuuuuuxxxxxxxxxxxxxxxuuuuuuuuuyuv??vvvv}v>+>>>>>>??}wwrrrrwuyyyyyuuuux|<br></br>
-uyuuuuuuuuxxxxxxxxxxxxxxxuuuuyyyyu}>?vvvvvv?>>>>>>?vvvvvv}zxuuyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxxuuuuyyyyyr>?vvvvvv?>>>^~--?wr}vv}uyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyx???>????+|++-...+uyuv}}rzzxyyyyyyyyyuuuu|<br></br>
-uyuuuuuuyyyyyyyyyyyuuuuuuyyyyyyyzrv>>++++>>+-`--`-?r>>uuyyyyyyyyyyyyyyuuu|<br></br>
-uyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyx}v>>>????>?vv?|...|??vvwuyyuuuuuyyyyuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuv??vvvvvvvvvv?`.~^^^^^^>vxyuuuuyyyyyy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyuzwxuuuuuzvvvvv?vvvvvvv>||^||||^^^^>zyuuyyyyyuy^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyzv>+>xuuuuy}++>?vvvvvvvvv???>+~```-|^^?uyyyyyyyuy^<br></br>
-uuuuuuuyyyyyuuuyyyyyyyyw>^^^vuuxzwwv?vvvvvvvvvvvvvvv???^`.`-^^^zyyyyyyuuy^<br></br>
-uuuuuuuyyyyyyyyyyyyyyyx>^^^^ruuxxr}}v?vvvvv?vvvvvvv?>>??|```|^+zyyyyyuuuu^<br></br>
-uuuuuuuuuyyyyyyyyyyyyyw^^^||ruuuxwwwvvvvvv?+?vvvvv????>?+`.`~^?uyyuuuuuuu^<br></br>
-uyyyyyyyyuuuuuuuuuuuuyw+^^~|zuuuwww}vvvvvv>+?vvvvvvvv>>?^`.`-+wyyuuuuuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuyz+|~-?yuuuzr}vv?vvvv??vvvv????>+>>~`.`+wyyyuuuuuuuu^<br></br>
-uyyuuyyyyyyyuuuuuuuuuyr>+|~wyuuuuuxuu}v??vvvv?>>>^+>>>>|~|>}uyyyyyuuuuuuy^<br></br>
-uyuuuuuuyyyyuuuuuuuuuuv?>>?uyuuuuuuyyw?>??v}r}vv??v}rr}}xuyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuzv?>?vuyyyyyyyyyuzzuuuyyyyyuuyyyuzuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyyuuuuuuuuwv?vv}zzw}}}}}}}}}rwwwwzxxuuuuuzwxyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuux}vvvv?v?vv??v??v???v???????vwzr}ruyyyyyyyyyyuuuuuuuy^<br></br>
-uuuuuuyyyyyyuuuuuuuurvvvvvvv}ux}vv??vvvvvvvvv?v}wzzzxxyyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyyuuuuuuuuuur??vvvvvvzuzvvvvvvvvvvv}vvyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-xuuuuuuuuuuuuuuuuuuur?vvvvv?>^||?v??vvvvvv??>^>vxyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`xxxxxxuuuuuuuuuuuuuuuv?>>??`.  .|||+?>~-``.....~wyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuxwvvu?..`?w???vz?.......?xyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyuuuuyyywxv.|>rv????zx|.`++~`}yyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyyyyuuyuxv-.|`^v>>?rz+`..~~`.-zuuuuuuuuuuuuuuuuuuuuuuuuu|<br></br>


            </div>,

            <div>

`rwrr}}v}vvvvv}}}}}}}}vv}}rrrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>
                  -yyyuuxxzzzzzzzzxxxxxxzzxxuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuy^<br></br>
                  -uyyyuuxxzzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuu|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxxxxuuuuuuuuuyuuxzzwxyyyyyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuuxrvv?vvv}wwrrr}}}}rzxyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuyuv??v?v??>?vvv??>>>vzuyyyuuuyyyyuuuxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuuyyyyyuv??vvvv}v>++>>>+>??}wwrrrrwuyyyyyuuuux|<br></br>
-uyuuuuuuuuxxxxxxxxxxxxxxxuuuyyyyyu}>?vvvvvv?>+>>>>>?vvvvv}zxuuyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxxuuuuyyyyyr>?vvvvvv?>>+^~`-?wrvvv}xyyyyyyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuyyyuuuuuuuuuuuuyyyyyx???>????+|>+`.  ^uyuv}}rzzxyyyyyyyyyuuuu|<br></br>
-uyuuuuuuyyyyyyyyyyyuuuuuuyyyyyyyxrv>>++++>>+-`-`.`?r+>uuyyyyyyyyyyyyyyyuu|<br></br>
-uyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyx}v>>>??v?>?vv?|...~??vvwuyyuuuuuyyyyuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuv??vvvvvvvvvv?..-|^^^^^>vxyuuuuyyyyyy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyuzwxuuuuuzvvvvv?vvvvvvv>~~~~-~~|||^>zyuuyyyyyuy^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyxv>+>xuuuuy}++>?vvvvvvvvv???>^~...`-|^?uyyyyyyyuu^<br></br>
-uuuuuuuyyyyyuuyyyyyyyyyw>^^^vuuxzwwv?vvvvvvvvvvvvvvv??>|...`~|^zyyyyyyuuu^<br></br>
-uuuuuuuyyyyyyyyyyyyyyyx>^^^^ruuxxr}}v?vvvvv?vvvvvvv?>>>>~...-|+zyyyyyuuuu^<br></br>
-uuuuuuuuyyyyyyyyyyyyyyr^^^||ruuuxwwwvvvvvv?>?vvvvv????+>^...`|?uyyuuuuuuu^<br></br>
-uyyyyyyyyuuuuuuuuuuuuyw^^^~|zuuuwww}vvvvvv?+?vvvvvvvv++?^...`^wyyuuuuuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuyz+~--?yuuuzr}vv?vvvv??vvvv????>^>>`...+wyyyuuuuuuuu^<br></br>
-uyyuuuyyyyyyuuuuuuuuuyr>+|~wyuuuuuxuu}v??vvvv?>++|^>+++~-|>}uyyyyyuuuuuuy^<br></br>
-uyuuuuuuyyyyuuuuuuuuuxv>>>?uyuuuuuuyyw?>??v}}}v????}rrvvxuyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuuzv?>?vuyyyyyyyyyuzzuuuyyyyyyuyyyuzuyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuuwv?vv}zzw}}}}}}}}rrwwwwzxxuuuuuzwxyyyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuux}vvvv?v?vv??v??v???v???>>>?vwzr}ruyyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuyyyyyyuuuuuuuurvvvvvvv}ux}vv??vvvvvvvvv??}wzzzxxyyyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyuuuuuuuuuur??vvvvvvzuzvvvvvvvvvvvvvvyyyyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
-xuuuuuuuuuuuuuuuuuuur?vvvvv?>^||?v??vvvvvv??>^>vxyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`xxxxxxuuuuuuuuuuuuuuuv?>>??`.  .|||+?>~-``.....~wyyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
                  -xxxxxxuuuuuuuuuuuuuuuuxrvvu?..`?w???vz?.......?xyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyuuuuyyywxv.|>rv????zx|.`>^~`}yyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuyyyyyyyyyyuxv-.|`^v>>?rz+`..~~`.`zuuuuuuuuuuuuuuuuuuuuuuxuu|<br></br>


            </div>,

            <div>

`rwrr}}v}vvvvv}}}}}}}}vv}}rrrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>
                  -yyyuuxxzzzzzzzzxxxxxxzzxxuuuuuxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuy^<br></br>
                  -uyyyuuxxzzzzzzxxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxu|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxxxxuuuyyyyuuuuuuuuuuuuuuuyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuyurvvvvvv}}rrrrr}rrwxuyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuuyyuuyr>>?v????>>?v??????}zxuyyyyyuuuxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuuyyyyyyyuyz???vvvvvv>+>>>>>>>?wuuuuuxuyyuuuux|<br></br>
-uyuuuuuuuuxxxxxxxxxxxxxxuuuuyyyyyyyuyx???vvvvvv?++>>>>???vv}rwwwzyyyuuuuu|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxxuuuuyyyyyyyyxv?vvvvvvv?+++>>>?vvv}}}wxuyyyyyuuuuu|<br></br>
-uyuuuuuuuuuuyyyuuuuuuuuuuuuyyyyyyyyuv>???????+~^^~~~^vw}vvvruuyyyyyyyuuuu|<br></br>
-uyuuuuuuyyyyyyyyyyyuuuuuuyyyyyyyyyyxr}?>++>>++^|....?yyzv}rwzzuyyyuyyyyuu|<br></br>
-uyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuur?>>>????|--`.`>v|+v}rzuyyuuuyyyyuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuyyyyyuuwvv?vvv}r????>~. .-||^+>vzuyuyyyyuy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyywv?>?zuuuuuxvvvvv}wz}vvvvv>`.~|^^^^^^+?xyyyyyuy^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyz?^^^^vyuuuuuw>>?vzxrvvvvvvv?|~~-----~^++?uyyyyuy^<br></br>
-uuxuuuuyyyyyuuuyyyyyyyyz>^^^^?uuuxzwzw?vrzw}vvvvvvvvvv?>^~`.``-^++wyyyuuu^<br></br>
-uuuuuuuuyyyyyyyyyyyyyyu?^^^^+vuuuxxz}v}rz}vvvvvvvvvvvvv?>>^``.`^++}yyuuuu^<br></br>
-uuuuuuuuyyyyyyyyyyyyyyz+^^^~|ruuuuuzww}rwvvvvvvvvvvvvv?>++>^..`|^>}yuuuuu^<br></br>
-uyyyyyyyyuuuuuuuuuuuuyz+^^|-|zyuuuzwwrvrvvvvvv??vvvvv???>^++`.-|^>zuuuuuu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuyz>~-``>uuuuuzr}vwrvvvvv?>>vvvvv??v>|++``-~+ruuuuuuu^<br></br>
-uyyuuuyyyyyyuuuuuuuuuuv?>^-~}yuuuuuxxxuw?vvvv?>?vvvv?>+>+^>|--~>wyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuur?++>>?xyuuuyuuuuuuvv??vvvvv???>^^>>?>|+?ruyyuuuuuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuuu}v?>??}uuyyyyyyyyuyxv???vvvvvv???v}}wxuyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuuzvv?vvvruxzrrr}}}}rr}??vrwzuyyuuuxwwxyyyyyyyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuz}vvv???vvww????????????????vvrzxzr}wyyyyyyyyyuuuuuuuu^<br></br>
-uuuuuuyyyyyuuuuuuuxrvvvvvvvvwuux}vv???vvvvvvvv???v}rrrwxyyyyyyyyyuuuuuuuu^<br></br>
                  -uuuuuyyyyyuuuuuuuuuzv??vvvvvruuuxvvvvvvvvvvvvvvwuuuuyyyyyyyyyyyyyyyuuuuuu^<br></br>
-xuuuuuuuuuuuuuuuuuuu???vvvvvvvv???vv?vvvvvvv??>wuyyyyyyyyyyyyyyyyyyuuuuuu^<br></br>
`xxxxxxuuuuuuuuuuuuuuw??>??v?`.. .`^+>>>^~~~```.`^wyyyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuuuuuuuuuuuuuuuxr?>?uu?. `+}}vv}rr|......~}uyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyuuuyyyu}ru>.^?vwv???vxv-`.~-~>yyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuyyyyyyyyyyyz+|.~>~~v?>??rr|`..~``-ruuuuuuuuuuuuuuuuuuuuuxuu|<br></br>


            </div>,

            <div>

`rwrr}}v}vvvvv}}}}}}}}}}}}rrrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
                  -yyyyuxxzzzzzzzzxxxxxxxxxxuuuuuxuuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuy^<br></br>
                  -uyyyuuxxzzzzzzzxxxxxxxxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxx|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxxxuuuuyyyyyuuuuuuuuuyyyyyyyyyyyyyuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyzv?????v}rwzwwzzzzzxuyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyw?>?>v?????????v?????ruuyuuuxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuuyyyyyyyyyyyyw????vvvvvv?++>>>>+>?rxuyyyuuuux|<br></br>
-uyuuuuuuuuxxxxuxxxxxxxxxuuuuyyyyyyyyyyyyz?>???vvvvv?>+>>>>>>?}zuuuuxuuuuu|<br></br>
-uyuuuuuuuuuuuuuuxxxxxxxxuuuuyyyyyyyyyyyuv>?vvvvvvvv>++>>>??v}}}}}rrzuuuuu|<br></br>
-uyuuuuuuuuuuyyyyuuuuuuuuuuuyyyyyyyyyyyyw??>>?????>^^+++>?vvvvvvwuuuyyuuuu|<br></br>
-uyuuuuuyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyzzv>>++>>>+^+|``+wzrvvvrxxuyyyyyuu^<br></br>
-uyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyzv?>>??vv>-. .vyyr?vvvrwzuyyyyuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyuxwrrwuyyyyuuurvvvvvvv}?---`.|?``>>>?vwuyyyyyy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyu}?+^^^+ryuuuuuxvvvvvvwxuv?vv?+` .-|^^^^^>ryyyuy^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyx?^^^^^+}yuuuyyyw>+?vwxyurvvvvvv^.`~|||||^^^?xyuy^<br></br>
-uuuuuuuyyyyyuuyyyyyyyyyu?^^^^^^vyuuuuxxxr>vrzuuwvvvvvvvv>^|-`````-||^?uuu^<br></br>
-uuuuuuuuyyyyyyyyyyyyyyy}^^^^^|>xyuuuxxw}}rxxzw}vvvvvvvvvvvv?+-....`~^^ryu^<br></br>
-uuuuyyyuyyyyyyyyyyyyyyy?^^^^~`?uuuuuyuzww}xyurvvvvvvvvvvvvv?>>^`...-^^vyu^<br></br>
-uyyyyyyyyuuuuuuuuuuuuyy?||^^-`vyuuuuuxzzwwux}vvvvvv?vvvvvv?>+>>^...-|+ryu^<br></br>
-uyyyyyyyyyyuuuuuuuuuuyz?^--`.-zyuuuuuzr}}xu}vvvvvv??vvvvv?>?>+>>`..`~?xuu^<br></br>
-uyuuuuuyyyyyuuuuuuuuuz??>+|`.>uyuuuuuuxxuuuvvvvvv?>?vvvvv??v>^>^...`^zuuy^<br></br>
-uuuuuuuuyyyyuuuuuuuux}?>^>?>>}yuuuyyuuuyyuu}?vvvv?>?vvvv??+>+|^`.`~?zuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuuurvv??v???wyuyyyyyyyyyyyz?v?vvvvvv??>>^|+>+|^?}xuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuuux}vvvvvvvvuuxzrwrrrrwwwzz}v>>??????>>++?wxxuyyyuuuuuuy^<br></br>
-uuuuuuuyyyyyuuuuuux}vvvv??v?vxuwv????????????>>>>>>vzx}vv}xyyyyyyuuuuuuuu^<br></br>
-uuuuuuyyyyyuuuuuuurvvvvvvv?}xuuuuwvvvv??vvvvvvvv?>+?v}}v}wyyyyyyyuuuuuuuu^<br></br>
-uuuuuyyyyuuuuuuuuuzv??vvvvvvzuuuuuzvvvv?vvvvvvvvv??}rrwzxuyyyyyyyyyuuuuuu^<br></br>
-xuuuuuuuuuuuuuuuuuuv??vvvvvv}xuuxxr?vvvvvvvvvvv??ryuyyyyyyyyyyyyyyyyuuuuu^<br></br>
-xxxxxxuuuuuuuuuuuuuz???vvvv}?^||````^++>?>|~~~``->vuyyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuuuuuuuuuuuuuuzv?>>?}ux>.  .~}wvv}}wv`......?uyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyuuyyuxr??zy?.`-.?zr????vu>~..``~xyyyyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuyyyyyyyyyyyw``|.|v?.~}?????wv~~..-`-wuuuuuuuuuuuuuuuuuuuxuu|<br></br>


            </div>,

            <div>

`rwrrr}}}vvvv}}}}}}}}}}}}}rrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
                  -yyyyuxxzzzzzzzxxxxxxxxxxxuuuuuxxuuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  -uyyyuuxxxzzzzzxxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxx|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxxuuuuuyyyyyyyyyuxxxuxuuuyyyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuuyyyyyyyuux}??????v}wzxzxxxuuuuuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuuyyyyyyyyyyuuzv?????v>?>????vvvvv}rzuuxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyuuxv>vvvvvvvvv?++>>>???}zxuuuux|<br></br>
-uyuuuuuuuuxxxuuxxxxxxxxxxuuuyyyyyyyyyyyyyuuxv>?v??vvvvv?>+>>>>?v}zuuuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxxxxxxuuuuyyyyyyyyyyyyuuur???vvvvvvv?>>>>>>??}zxxxxxxuu|<br></br>
-uyuuuuuuuuuyyyyyuuuuuuuuuuuyyyyyyyyyyyyyuuz???>???????>+>>>??vvv}rwwwzxuu|<br></br>
-uyyuuuuyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyuxwz?>+++>>+^|>?>?vvvv}}ruuyyyuu^<br></br>
-uyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxxrv???????>+^|+ruzrv}}rxxuyyuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyxrvvvv}zyyyuuuxx}vvvvvv???~..~vrx?>??vvrzyyyy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyxv>^^^^+?wuuuuuuuwvvvvv}}rxv^||-..-.`|^^+>?vwuy^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyr>^^^^^+?zyuuuuuzzv>>?vwxxux}vvv?>- .~^^^^^+>>?x^<br></br>
-uuxuuuuyyyyyyuuyyyyyyyyyz+^^^^^+?zyuuuuxxwzr?vwzzxxx}vvvvvv>..````-~~^+>?-<br></br>
-uuuuuuuuyyyyyyyyyyyyyyyu?^^^^||+}uuuuuxzzrrwwxzzzwrvvvvvvvv??+~`....``^>>.<br></br>
                  -uuuuyyyyyuuyyyyyyyyyyyyz+^^^^|-+zyuuuuuuxwwzwxuuxrvvvvvvvvvvvv?+|`..``|++.<br></br>
-uyyyyyyyyyuuuuuuuuuuuyy}+||||--?uyuuuuuuzwxwwxxxrvvvvvvvvvvvvv?+++-..`|^^.<br></br>
-uyyyyyyyyyyuuuuuuuuuuywv?+-`.`~?yuuuuuuxwrwrzxz}vvvvvv?vvvvvv?>+^^^`.`|^^.<br></br>
-uyuuuuuyyyyyuuuuuuuuuz??>?>|`-^wyuuuuuuuuuxxuurvvvvvv??vvvvv?+??^^+-`~||>-<br></br>
-uuuuuuuuyyyyuuuuuuuuzvv?+>??>>?uyuuuyuuuuuuuxurvvvvvv?>vvvvv???>||+|~~~>z^<br></br>
-uuuuuuuyyyyyuuuuuuuxvvvvvvvv??}uuyyyyyyyyyyuuurvvvvvv??vv?vv?>^++>+|^>vuy^<br></br>
                  -uuuuuuuyyyyyuuuuuuuwvvvvvvvv??wuuzwrrrrrwwwzzwr?vvvvvvvv??????????vvwuyyy^<br></br>
-uuuuuuuyyyyyuuuuuuzvvvvv??v?vwuuzv?????????????>??>?v???>??vvwxuuuyyuuuuu^<br></br>
-uuuuuuyyyyyuuuuuuz}??vvvv?vwxuuuuzr}vvvvvvvvvvvvv>>>++??v}wzuyyyyuuuuuuuu^<br></br>
-uuuuuyyyyuuuuuuuux}?>?vvvvvrxuuuuuux}vvvvvvvvvvvvvv??v}}rwxuyyyyyyuuuuuuu^<br></br>
`xxxuuuuuuuuuuuuuuur???vvvvvvruyyyuuzvvvvvvvvvvvvvv}rxuuuyyyyyyyyyyyyuuuuu^<br></br>
-xxxxxxuuuuuuuuuuuux?vv?vvvvvvv??r?|``|>?>>?>~~---~^vzyyyyyyyyyyyyyyyyyuuu^<br></br>
-xxxxxxuuuuuuuuuuuuyxv?>??vvzuw+..^--+}wr}}r}?`.....-?uyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyyyuxw}v>+?xu?...`|vw}vv???r?~`.`^?uyyyyyyyyyyyyyyyyyyyy^<br></br>
                  -xxxxxxuuuuuuuuyyyyyyyyyyyw-.-|.-+~.`|v?????vv~|-.`^?xuuuuuuuuuuuuuuuuxxuu|<br></br>


            </div>,

            <div>

`rwrrr}}vvvvv}}}}}}}}}}}}}rrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
                  -yyyyuuxzzzzzzzxxxxxxxxxxuuuuuuxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxuuuuuuyyyyyyyyyyuxxzzxxxuuuyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyxv???????}wzxxxuuuyyuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuyyyyyyyyyyyyyyyz????v?v??>???vvvvvv}wxxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyyyyyzv>vvvvv}vvv?++>>>>>>?vrzux|<br></br>
-uyuuuuuuuuxxxuuxxxxxxxxxuuuyyyyyyyyyyyyyyyyyyuv>?v??vvvvvv?>>>>>>>?wuuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxuxxxxuuuuyyyyyyyyyyyyyyyyyur???vvvvvvvv>+>>>>>?}wxuuxxu|<br></br>
-uyuuuuuuuuyyyyyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyuv??>??vvvv???+>>??vv}}}}}}wx|<br></br>
-uyyuuuuyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyuxxv>++>>>>>++^>?vvvvvv}zxxuu^<br></br>
-uyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyuuurvv?>??vvv?|-^rzr}vvvwuuyyy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyurv?>>>?ruyyyuuuuwvvvvvvvvvv^..?uyu??vvvv}xyy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyuv>^^^|||>ruuuuuuuxvvvvvvv}wz?````->^.~+++++?vz^<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyx?^^^^^^^?xyuuuyyyyz>++?vrxuyuv???>~. .~^^^^^^|^.<br></br>
-uuxuuuuyyyyyuuuyyyyyyyyyu?^^^^^^^?xyuuuuuxxuw>?vrzuyyxrvvvvv?|..----~~~||.<br></br>
-uuuuuuuuyyyyyyyyyyyyyyyy}^^^^^^|+wyuuuuuxxwrrrzxzzzxz}vvvvvvv>|-`......`~.<br></br>
-uuuyyyyyyuuyyyyyyyyyyyyu?^^^^^~`>xyuuuuuyuzwr}xyyuurvvvvvvvvvvvv?^`....`` <br></br>
-uyyyyyyyyyuuuuuuuuuuuyyr?>|~~~``?yuuuuuuuxzzwwuuyuwvvvvvvvvvvvvv?>>|`.... <br></br>
-uyyyyyyyyyyuuuuuuuuuuuw?v?+-...`wyuuuuuuuzr}rxuuurvvvvvv?vvvvvvv>+>>+`... <br></br>
-uyuuuuuyyyyyuuuuuuuuuw??>>?>^-`+uyuuuuuuuuxuuuuurvvvvvv??vvvvvv?>?++>-... <br></br>
-uuuuuuuyyyyyuuuuuuuurvvv>?????>}yuuuuuyuuuuuyuuuvvvvvv?>>vvvvv??vv^^>`... <br></br>
-uuuuuuuyyyyyuuuuuuuzvvvvvvvv?>>wyuyyyyyyyyyyyyuu}vvvvvv>?vvvvvv?>>^^~.`~?~<br></br>
-uuuuuuuyyyyyuuuuuuurvvvvvvvv??vxuuzrr}}rrrwwzzxxr?vvvvvvvvvvv?>^^>>+^?vzy^<br></br>
-uuuuuuuyyyyyuuuuuuzvvvvv??v?vzuuurv>??????????????v??vvvv?>>>>>?rxxxuuuuu^<br></br>
-uuuuuuyyyyyuuuuuuwv??vvvv?vzuuuuuxr}vvvvvv?vvvvvvvv>+>>>>>??vwuyyyyuuuuuu^<br></br>
-uuuuuyyyyuuuuuuuurv??vvvvvvruuuuuuuuwvvvv??vvvvvvv??++?vvv}wxyyyyyuuuuuuu^<br></br>
                  `xxxxuuuuuuuuuuuuuu???vvvvvvvwuyyyyyyr?vvvv?vvvvvvv??vrwzxxuyyyyyyyyyuuuuu^<br></br>
-xxxxxxuuuuuuuuuuuyw?vv?vvvvv}w}vzx?+-`|??>>?>~-~~--~vxyyyyyyyyyyyyyyyyuuy^<br></br>
-xxxxxxuuuuuuuuuuuuyw?+?vvvv}uuv~`>>..~wxw}rrwv`......>uyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyyyuwv?>>>^vux+  `-`vzzvv???z?|-..`~zyyyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuyyyyyyyyyuzv`..~-.|^...^r?????}v~|-.`-^zuuuuuuuuuuuuuuuxxuu|<br></br>


            </div>,

            <div>

`rwrrr}}vvvvv}}}}}}}}}}}}}rrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
                  -yyyyuuxzzzzzzzxxxxxxxxxxuuuuuuxxxuuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
                  -uyyyuuuxxzzzzzxxxxxxxxxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  -uyyyuuuxxxzzzxxxxxxxxxuuuuuxxxxxxuuuuuuyyyyyyyyyyyuzzzzzxuuyyyyyyyyuuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxuuuuuuuuuuuuuuyyyyyyyyyyyyyzv>>????vvrzxuxuuuyuuxxx|<br></br>
-uyyyyuuuxxxxxxxxxxxxxxxuuuuuuuuuyyyyyyyyyyyyyyyyr?????????>???vvvvvvrzxxx|<br></br>
-uyyuuuuuuxxxxxxxxxxxxxxxuuuuyyyyyyyyyyyyyyyyyyyw??vvvvvvvvv?++>>>>>>?vrzx|<br></br>
-uyuuuuuuuuxxxuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyz?>vv??vvvvvv?>>>>>>>vzuuuu|<br></br>
-uyuuuuuuuuuuuuuuuxxxxxxuuuuyyyyyyyyyyyyyyyyyyuv>?vvvvvvvvv?>>>>>>?vwxuuuu|<br></br>
-uyuuuuuuuuyyyyyyyuuuuuuuuuyyyyyyyyyyyyyyyyyyyz??>>?vvvvv?>>?+>??vv}}rrrwx|<br></br>
-uyyuuuuyyyyyyyyyyyyyuuyyyyyyyyyyyyyyyyyyyyyyyuxw?>++>>>>>++^>?vvvvv}rwzxu|<br></br>
-uyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyuux}vv?>???vv>|~?zw}vvv}uyyyy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyurv?>>>?vxyyyyuuuurvvvvvvvvvv|.-wyyz?vvvv}ruy^<br></br>
-uyuuuyyyyyyyyyyyyyyyyyyyyyuv>^^^|||^?xyuuuuuuzvvvvvvv}ww^...`+v|`^>>+++?}|<br></br>
-uuuuuuyyyyyyyyyyyyyyyyyyyx?^^^^^^^+}yyuuuyyuuv++>?vwxuyx????^`. .~^^^^^^|.<br></br>
-uuxuuuuyyyyyuuuyyyyyyyyyy?^^^^^^^+ryuuuuuxxxuv>?vrxuyuxrvvvvv?`.`~~~~~|||.<br></br>
-uuuuuuuuyyyyyyyyyyyyyyyyr^^^^^^||vuyuuuuxxxwrrwzxzzxuz}vvvvvvv^-`......`-.<br></br>
-uuuyyyyyyuuyyyyyyyyyyyyu?^^^^^|`-}uuuuuuyyxwwrwuyyuurvvvvvvvvvvv?+-...... <br></br>
-uyyyyyyyyyuuuuuuuuuuuyyrv?^~~~`.~zyuuuuuuuzzzrzyuyurvvvvvvvvvvvvv?>^`.... <br></br>
-uyyyyyyyyyyuuuuuuuuuuur??v?|`...>yyuuuuuuuw}}ruuux}vvvvvv?vvvvvvv>+>>-... <br></br>
-uyuuuuuyyyyyuuuuuuuuuwv?>+>?>|`-wyuuuuuuuuuxuuuuurvvvvvv??vvvvvv?>>+>^... <br></br>
-uuuuuuuuyyyyuuuuuuuurvvv??????>?uyuuuuyyuuuuyyuuzvvvvvv?>?vvvvv??v?^>^... <br></br>
-uuuuuuuuyyyyuuuuuuuzvvvvvvvvv>>}uuyyyyyyyyyyyyyuxvvvvvv?>?vvvvvv??>||`.`~.<br></br>
-uuuuuuuyyyyyuuuuuuurvvvvvvvv??}uuuxwr}}rrrwwzzxxxv?vvvvv?vvvvv?>+^>+|^>vz^<br></br>
-uuuuuuuyyyyyuuuuuuwvvvvv??vvvzuuuzv?>????????????>?v?vvvvv???>>>?rzxxuuuy^<br></br>
-uuuuuuyyyyyuuuuuuwv?vvvvv?}xuuuuuxw}vvvvvv?vvvvv??vv>>>>>>>??vzxyyyyuuuuu^<br></br>
-uuuuuyyyyuuuuuuuurv??vvvvvvwuuuuuuuuz}vvvv??vvvvvvv?>+>?vvvrwuyyyyyuuuuuu^<br></br>
                  `xxxxuuuuuuuuuuuuuz???vvvvvvvzuyyyyyyxvvvvv?vvvvvvv???vrwzzxuyyyyyyyyyuuuu^<br></br>
-xxxxxxuuuuuuuuuuuu}?vvvvvvvv}z}}xy}?|`-+?>>??^-~~~-~>wuyyyyyyyyyyyyyyyuuy^<br></br>
-xxxxxxuuuuuuuuuuuuu}?+?vvvv}xur^-?}~.`vzz}}rww?......`}yyyyyyyyyyyyyyyyyy^<br></br>
-xxxxxxuuuuuuuuuyyyyyx}v?>>>|?xu>. ~+`+xxrvv??vz^~..``?yyyyyyyyyyyyyyyyyyy^<br></br>
                  -xxxxxxuuuuuuuuyyyyyyyyyuwv`..`-.`^`..`vv?????r?~|..-~vuuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`}vvvvvvvvvvvvvv}rr?.v}}}}rrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`zzwwwrrrrrrrrrrwzyy|>xxxuuuuuuxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`zzwwrrrrrrrrrrr}}wu}.}xxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
                  `zzwrrrrrrrrrrrrrrrwz^~uuuuuxxxxxxuuuuuuyyyyyyyyyyyyuzwzwzzzxuuyyyyyuuuxxx|<br></br>
`zzwrrrrrrrrrrrrrrrrrv.}uuuuuuuuuuuuuyyyyyyyyyyyyyyxv?>?????v}zuuuyyyuuxxx|<br></br>
`zwrrr}}rrrrrrrrrrrrrw|^uuuuuuuuuyyyyyyyyyyyyyyyyyz?>>??????????vv}}}rwzxx|<br></br>
`r}}}}}}v}}vvv}rrrrrrw?.zuuuuyyyyyyyyyyyyyyyyyyyyz?>vv?vv}vvvv>+>>>>>>?v}z|<br></br>
`wwr}}vv??????vrrrrrrr}`ruuuyyyyyyyyyyyyyyyyyyyyxv>?v???vvvv}?>>>>>>+?wxxx|<br></br>
`vv????????????vrrrrrrw-?uuuyyyyyyyyyyyyyyyyyyyu}??vvvvvvvvv?>>>>>>>?vwxuu|<br></br>
.???????????????}rrrrrw^|yuuyyyyyyyyyyyyyyyyyyyxv?>>?vvvvvv?+?>>>???vwzwwz|<br></br>
.???????>>??????vrrrrww>+yyyyyyyyyyyyyyyyyyyyyyxxw??++>>>>>+++>?vvvvvvvv}w|<br></br>
.???????>?v??????vrrrrw?^yyyyyyyyuuuuyyyyyyyyyuyu}vv?>>??vv?+^?r}vvvv}zuuu^<br></br>
.????vvv?vv???????rwrrwv~yyyyur?>>++>?vuyyuuuuuurvvvvvvvvvv?`~wyywvvvvrrru^<br></br>
.??vvvvvvw}???>???vwrrwv|yyy}>^^^^^||+vuyuuuuuyzvvvvvvvv}r>..`^?r^~???>++v~<br></br>
`vv}}wzwwxv????>???}wrwv+yuv^^^^^^^^>zyuuuuyyyyv+++>?}zxux>>>+~.. .|^^^^^|.<br></br>
.?wzzzzrzr???>??>??vwwwv>yv^^^^^^^^>xyuuuuuxxxuv>?vrzuyyurvvvvv>` `||~~||~.<br></br>
  +zzzzrr???+^~+????rww??x+^^^^^^||}uuuuuuxxxwrrrxxzzxux}vvvvvvv|.......`` <br></br>
`+ |zzwrv>??`xz?|+>?vww^?v>^^^^^~`^zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
-xv.^x}r||?|>uyyz>|>vwr|rvv?|~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>~`... <br></br>
`zx}`}wv+v--uuuuuuv->}v-vv?v?~`..`ryuuuuuuuuw}}ruuuurvvvvvvvvvvvvvv?>>>`.. <br></br>
`zzxrwzv|u>vyuuuuuur>?++?>+>??>|`|xyuuuuuuuuuxuuuuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
`zzzzzzz^>yyyuuuuuuux>-vv??v????>vyuuuuuuuuuuuyuuuzvvvvvvv?>vvvvv?>?v++>`. <br></br>
`zzzzzzzz|?yyuuuuuuuwv?vvvvvvv?>}xuyyyyyyyyyyyyyyyzvvvvvv?>>vvvvvvv??^^^.. <br></br>
`zzzzzzzzz^vyyuuuuux}vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv?vvvvvv??^+^|-~+`<br></br>
`zzzzzrrwzz?vuuuuuurvvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>>+>vv}rzu^<br></br>
`zzzzz}v????>xuuuuwv?vvvvvvrxuuuuuur}vvvvvvvvvvvvv??vv>?v???>>??vwzuyyuuuu^<br></br>
`wwzzzw+vwwxuuuuux}v>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
`zvvwzr|uuuuuuuuuur???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
-xzvv}v+xuuuuuuuuuu??vvvvvvvv}xzwuyxv?|-~+?>>??+~~||~~?zuyyyyyyyyyyyyyyyuy^<br></br>
`zzx+?rxuuuuuuuuuuyxv>>?vvvvvzyz?>}u?..-vww}}rrw?......~zyyyyyyyyyyyyyyyyy^<br></br>
`v??>zuuuuuuuuuuyyyyuwv??>??~+ru}`.^w|`?uxrvv??vx^~..``vyyyyyyyyyyyyyyyyyy^<br></br>
                  -v~rxuxuuuuuuuuyyyyyyyyuxr?`. .-..-~^..|w??????r?~|..-~vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`???????v}}}v`|v}rr?|v}}}}rrrrrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`}}}}}}}}rzxu?-}zzuu?vxxxuuuuuuxxxxuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`}}}}}}}}}}zxw|^wwzuw+rxxuuuxxxxxxxxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`}}}}}}}}}}}zw?-?wwzx?>uuuuuxxxxxxuuuuuuyyyyyyyyyyyyuzwzwzzzxuuyyyyyuuuxxx|<br></br>
`}}}}}}}}}}}}}v+|rzzzw>wuuuuuuuuuuuuuyyyyyyyyyyyyyyxv?>?????v}zuuuyyyuuxxx|<br></br>
`}v}vvvv}}}}}}}?-?zzzzvvuuuuuuuuuyyyyyyyyyyyyyyyyyz?>>??????????vv}}}rwzxx|<br></br>
`vvvvvvvvvvvvvvv^|wzzzr>zuuuuyyyyyyyyyyyyyyyyyyyyz?>vv?vv}vvvv>+>>>>>>?v}z|<br></br>
`}}vvvvv??????v}?-vzzzw>zuuuyyyyyyyyyyyyyyyyyyyyxv>?v???vvvv}?>>>>>>+?rxxx|<br></br>
`??????????????vv~?zzzz?}uuuyyyyyyyyyyyyyyyyyyyu}??vvvvvvvvv?>>>>>>>?vrxuu|<br></br>
.>>>>>??????????v^>xzzxvvuuuyyyyyyyyyyyyyyyyyyyuv?>>?vvvvvv?+?>>>???vrwwwz|<br></br>
.>>>>>>?????????v>^zxxx}vyyyyyyyyyyyyyyyyyyyyyyxxw??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>???vv?????>`vuxxrvyyyyyyyuuxuuyyyyyyyyyuyu}vv?>>??vv?+^?r}vvvvvzuuu^<br></br>
.>>>>>>>?vv???????`vuxxzvyyyyur?>+++>?vuyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>?>>????r}???????-vuxxzvyyy}>^^^^^||+vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.?????vvvrv???????-?xxxzvyyv^^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.?v?vvv?vv????????->xxuw}yv^^^^^^^^>xyuuuuuxxxuv+>?}zuyyuwvvvvv>` `~|~~||~.<br></br>
 .^vvvv??>>?>>+>??~+xxurrx+^^^^^^||vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.|.|v?v??+>?^wwv>?~^xxuv}v>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
`v>`^v??||>^?xxxw?`>xxxvwvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
`vv?|??>^?||zxxxxx+~wxw>vv?v?|`..`}yuuuuuuuuwr}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
`vvv?vv?|r>?zxxxxx?vvw?>?>+>??>|`|xyuuuuuuuuuxuuyyurvvvvvvv?vvvvvv?>>>>+.. <br></br>
`vv>++?v|^rwwzxxxx+wu?+vv??v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
`v?>z?>>?|>wwzxxxz^wwvvvvvvvvv?>}xuyyyyyyyyyyyyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
`v>?xxx}??|?wwzxx}+z}vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
`?>rxxzxx}>|?wzxx?vrvvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.+}uxxzzzz}|`vwxz^vv?vvvvv?rxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.^zxxux}zxxxv?}xr|vv>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
`wwrzxz?uuuuuw?w??r???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
-xzrrww?uuuuuyxv+xu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
`zzxvvzxuuuuuuyzryyxv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
`rrrvzuuuuuuuuuyyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.>?wxxxuuuuuuuuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.?>++++++>>>+||+?v??>>>>++>>>>vv>rrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.?>>>>>>>??vv?|>vvv??????>>???vw?ruuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>????vv^^?????vvzr??++>?vv?xxxxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.???>?????>?wz>|>??vvvzyyyuzv++?v?uuuuuuyyyyyyyyyyyyuzzzwzzzxuuyyyyuuuuxxx|<br></br>
`v???????>?www>^|vvvvvuyyyyyyuv>??uuuyyyyyyyyyyyyyyzv?>?????v}zuuuyyuuuxxx|<br></br>
`?>??????>vzwrv?+?rv?ruuyyyyyyy}wvyyyyyyyyyyyyyyyyw?>>??????????}rzxxxuxxx|<br></br>
.>>??????>rwwwwv?vzv?zyyyyyyyyywwyyyyyyyyyyyyyyyyw?>vv?vv}vvvv>+>?????vvrz|<br></br>
.>>?????>?zwwwrr}vr??zuuyyyyyyuruyyyyyyyyyyyyyyyz?>?v???vvvv}?>>>>>++>vwxx|<br></br>
.>>>>>??>}wwwwwwwvv?}xuyyyyuzrruyyyyyyyyyyyyyyyxv>?vvvvvvvvv?>>>>>>>>?vzuu|<br></br>
.>>>>>>>?wwwwwwwwv?}rzxxzvv}wxyyyyyyyyyyyyyyyyyw??>>?vvvvvv?>?>>>????vvrwz|<br></br>
.>>>>>>>}wwrrwwww?>}wwzxzv>ryyyyyyyyyyyyyyyyyyyxxw??++>>>>>>++>?vvvvvvvv}w|<br></br>
.>>>>>>?wwwwwwwwrv?rrwxx}??xyyyyuuxuuyyyyyyyyyuyx}vv?>>??vv?+|?}}vvvvvzuuu^<br></br>
.>>>>>+vwwwwwwwwwv?zrwvzv?yyyur?>+++>?vuyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>>}vwwwwwwwr?wzyy}?vwyy}>^^^^^||+vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+?r}rwwwwww?rv}yu}vyyyv^^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.^^^++rrrrwwwwwvvr??w}?uyyv^^^^^^^^>xyuuuuuxxxuv+>?}zuyyuwvvvvv>` `~|~~||~.<br></br>
.>??>>>vrrrwwwrvuuwwxzuyyz+^^^^^^||vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.?vv}w}+vrrrwwvzuuzzyyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 `--~~>v>vrrwr^ruuzzyyyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.|^^^|~->?}}w??>uuwzyuur?v?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.^^^^^+^~|>}}?w>vuwxyuwv??+>??>|`|xyuuuuuuuuuxuuyyurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.^^~~~^^^~|}?wuwwv?xu}vvv??v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.^^~+^||^||}v}}zwv>xwvvvvvvvvv?>}xuyyyyyyyyyyyyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.^~~>>>+|~-?vv}rr+vx}vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|~+>>>>>^~?vv}vv+}rvvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.~+>>>>>>+>vv}>>}v}v?vvvvv?rxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.-+>>>>>>+>v>>?uxvvv>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.+>>>>>>>^ruwrzxwzr???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.+>>>>>>>|zuuyuzrux??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.+>>>>>>++uuuuuxxuuxv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.+>>>>>>|?yuuuuuyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
 |>>>>>>~wuuuuuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.>^^^^^^^^^^^+?>^>>++^++++++vvvv?rrrrrrrr}v??>rwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>>vv??v?>>????>+>rwwxrzyyyyyyyyuxr?uyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+?}vvv?>??v}z}?>+vrwww}uuuuuyyyuuywuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+>}wvvv??vvvzuzwrwrvv}w}uuuuyyyyxuyyyyyyyyuxzzwzzzxuyyyyyuuuuxxx|<br></br>
`?>>>>>>>>>vzwvvvv}vv}uuwrwuyu}v}vyyyyyyyyxyyyyyyyyzv?>?????v}zuuyyyuuuxxx|<br></br>
`>>>>>>>>+>rzw}}v}r}vwuxwwruuyywzryyyyyyxzuyyyyyyyw?>>?????????vrwzxxuuuxx|<br></br>
.>>>>>>>>+?wwww}rww}vzuuwwwuuuyxzyyyuxzrwyyyyyyyur?>vv?vv}vvvv>+>????vv}wx|<br></br>
.+>>>>>>>>}zwwrwwwwvvzuuxuxxuuxrwrzwwxxyyyyyyyyyw?>?v???vvvv}?>>>>>++>vwxx|<br></br>
.>>>>>>>+?wwwwrrwwwvrxzwwzzxwrwux}?wyyyyyyyyyyyzv>??vvvvvvvv?>>>>>+>>>vzuu|<br></br>
.>>>>>>>>}zwwwwwwwwrrr}wwvv}rxuxz}?xyyyyyyyyyyyr??>>?vvvvvv?>?>>>??????}wz|<br></br>
.>>>>>>+?rwwwwwwwr}r}}wwwv>ruuu}vwuyyyyyyyyyyyuxxw??>+>>>>>>>+>?vvvvvvvv}w|<br></br>
.>>>>>>>vwrwzwwwrrwwrr}wr??wzuyx}zxuuyyyyyyyyyuyx}vv?>>??vv?+|?}}vvvvvzuuu^<br></br>
.>>>>>+?rrrzzwwww}wxrv?x}vyyzw}?^^++>?vuyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>>v}wrwwwwwrvuxz}wvvzyuz>-~|^^||+vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+?rwwwwwwwwvwwzvz}vuuur?~|^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.~~|^^vzzwwwwww}}w}v^v?wxzv^~|^^^^^>xyuuuuuxxxuv+>?}zuyyuwvvvvv>` `~|~~||~.<br></br>
.???>>^>vwwwwwwvuuwvvvyyyz+^^^^^^||vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`vvvwxz?+?zwwwvruuvrz?uyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 `.`-~>rv?vwwr^}uzvuxv}zzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.|~~~-``+v?}z?+?uzxyxuuv???v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.^^^^^^|`-??w?v?rxxxzyzwv++>??>|`|xyuuuuuuuuuxuuyyurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.^^^^^^^^-~?vrr}w}rwzwvv???v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.^^^^^^^^||>?}v}vvvuzwrv>vvvvv?>}xuyyyyyyyyyyyyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.^^^^^^^^|~>+v}}}>wz}vvv?vvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.^^^^^^^^|~>^vrrr?w}?>?????vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.^^^^^^^^||?>?+>rzwv^?vvvv?rxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.^^^^^^^^|^+~??vvv?>+?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.^^^^^^^^~>?^ryuuxv???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.^^^^^^^|~??+zuuuyuv?vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^|~??>xuuuuyxv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^~^??>uuuyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^->?>?yuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`?>^^^^^^^^^^^>>|^^++^^^+++^>??vv>++>??vv?^vvvrwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`?>>>+>>>>>>>?v?>??vv????>>?}vzyyuzr?>?vrw?uzruyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>??>>>>>vv?v?vrr??????vv}uyuyyyyzv??r?xyxuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.???????>>>+?v?vvvwwr?vv????vwuuyyyyyyywvv>xyyyyyyyyuxzzwzwzxuyyyyyuuuuxxx|<br></br>
`??????>>>>>v????wwwr}}vvvv?vzuyyyyyyyyywz}uyyyyyyyzv?>?????vrzuyyyyuuuxxx|<br></br>
`?????>>>>+?vvv?vwwwww}r}rv?vxyyyyyyyyyyrvyyyyyyyuw?>>?????????vrxuuuuuuxx|<br></br>
`????>>>>>>vvvv?wwwwwwwwrrv>vxuyyyyyuuxwvuyyyyyyu}?>vv?vv}vvvv>?vvwxxxxxxx|<br></br>
`???>>>>>+?v?v?vwwwwwwwwwwv?}zxuxzxxzwrwuyyyyyyur?>?v???vvvv}?>>????v}zxxx|<br></br>
`??>>>>>>>???v?rwwwwwwr}}rv}}zzxzv??rzuyyyyyyyyw?>??vvvvvvvv?>>>>>>+>?rxuu|<br></br>
`??>>>>>+?v??vvzwwrwwr}rrvvvvwww}++vyyyyyyyyyyu}v?>>?vvvvvv?>?+>>????vrwwz|<br></br>
`?>>>>>>>?v???rwwwwwwrww}}}rrrwvvv?vyyyyyyyyyyuxuw??>+>>>>>>>+>?vvvvvvvv}w|<br></br>
.>>>>>>>>v??vvwwwwwwwwrwxvw}?vww>>ruuyyyyyyyyyuyx}vv?>>??vv?+|?}}vvvvvzuuu^<br></br>
.>>>>>>+???vv}wwwwwww}rurvyzzw???|^+>?vuyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+>v????wrrwwwwrvxz?uuxyw?`-|^||^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.+>>>>+?vvv?vzwwwwwwvwx?rrwzx?+-^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.||~|||>????wzwwwww}?wv?wvv>+^~^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
`rrvv??+>v???}zwwwrvvv}uyz^~~|^^^||vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`rrr}rw}>?xuz?vwwwvvr>wyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.--````~|`~?vz>vzrvxw|vxzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 -------|+-^>^??wrwuvv?}???v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.^^^^^^|~~+|?+~>v}ww?w??v>+>??>|`|xyuuuuuuuuuxuuyyurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.^^^^^^^^||-+>+++vwv}uvv>??v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.^^^^^^^^^^-~>++~?w}xxxuv>vvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.^^^^^^^^^^|~+>>^?rr}vwv?>vvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.^^^^^^^^^^^-+??+?vvvvvv>^?vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.^^^^^^^^^^^-~^>+vwr?vvv?>vrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.^^^^^^^^^^^`^^|-?}vv?^?vvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.^^^^^^^^^^^`+>>^????>?vvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.^^^^^^^^^^^`+>+>uz?>vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^^^^^`+>+?yyuv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^|`>>^}yyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^|~>>^xyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`??^^^^^^^^^^^^^>>|++^^^|^^^^^^?v>++>??vv?+v?vvv}vvwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`?>>>+>>>>>>>>+?v?+??>>>>????>vuyuzr?>?vrw?yxzwwzzvyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>??>>>>>>>vv??vv>>>??v???ruuyyyyzv??r?uyyxzwwvuyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.???????>>>>>+?vvvwwv>??vvv?>vzyyyyyyyywvv?uyyyuyxvxyzzwwzwzxuyyyyyyuuuxxx|<br></br>
`??????>>>>>>>v?vwwrvvvv}rr??vzyyyyyyyyyrzwuyyyuuyurv>>?????vrzuyyyyuuuxxx|<br></br>
`?????>>>>>>+?v?vwwrrw}rwwr>?}xuyyyyyyyywwyyyyyuyur?>>?????????}wxyyyuuuxx|<br></br>
`????>>>>>>>>vv?wwwwwwwwww}+?rxuuyyyyyyuruyyyyyxu}?>vv?vv}vvvv>?}rzyyyuuxx|<br></br>
`???>>>>>>>+?v?vwwwwwwwwwwv>v}xuyyyyuzwwuyyyyxzx}?>?v???vvvv}?>>?vvrzxxuxu|<br></br>
`??>>>>>>>>>vvvrwwwwwwwwww}vvvzxxwrrrzuyyyuxwwur?>??vvvvvvvv?>>>>>>>?vzuuu|<br></br>
`??>>>>>>>+?vvvwwzrwwwwww}vv}vwzzvvvuyuxzzzzuyxvv?>>?vvvvvv?>?+>>????vwwwz|<br></br>
`?>>>>>>>>>?v?rwrwwwwwww}}rrrrwwr}??rrzxuyyyyyxxyr??>+>>>>>>++>?vvvvvvvv}w|<br></br>
.>>>>>>>>+?vvvwwwwwwwwwwr?}v?vrzv?rxxwvzyyyyyyyyx}vv?>>??vv?+|?}}vvvvvzuuu^<br></br>
.>>>>>>>>+?v?}wwwwwwwwwrvvuwxz?v}?>>+|>xyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>>>+>vvvwrrwwwwwwwrvuxzzwr>>?+`-^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.++>>>>>>???vzwwwwwwwwwv}}zuzz?|>??^|wyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.>+^^^^^^?>>wzwwwwwwww}vvwz}}v>^+>>+vuyuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
`rr}v????v?>?}zwwwwwwwv}xuv>??>?>--vyyuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`}}vv???rzxuz?vzwwwww}vzu}?>??>?-.|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.^|~~~~|>>>?vz?}zwwwr+?xwvv?++|~..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 .```-~^>>>>>>vvwwwz?|~?????>|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.+++>++|`^????>?v}wr^>^~??+>??>|`|xyuuuuuuuuuxuuyyurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.^+++>>?+-^????>^vzv?zv+>??v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
 ----~~^>>-^???+|vzvwuxwv^?vvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.||~~~--~^>->?>+|?wvwwwww???v??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.^^^^^^|~-^+~?>^^?vvwzwu}>r??}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.^^^^^^^^^~|~>>+^vwr}vv}??vvxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.^^^^^^^^^^|-|?>~vrwwv>vv}?vxuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.^^^^^^^^^^^|~>>~>>?v?v}}}v?}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.^^^^^^^^^^^^-+>>xw??vv?????v}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^^^^^^-^>?uuxrv>>?vvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^^^```?xxuur+??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^^^`^^rrrwwvzuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`?>^^^^^^^^^^^+|?v>+^^^^^^^++|+??}rrrrrrrrv??vvv}vvwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`?>>>>>>>>>>+>>?}v>>+>>>>>>>>>>wrzuuuuyyyyyuxzwwzzvyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+>?>vv>+>>?v}v??+^>?ww}uuuuuyyyyyyyxzwwvuyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+>?v??v??v??}zxuu}?+^vwvuuuuyyyyyyyyyuyxvxyzzwwzwzxuyyyyyyuuuxxx|<br></br>
`??>>>>>>>>vvvv?vvvv?vxuuuuwwr??}vyyyyyyyyyyyyyuuyurv>>?????vrzuyyyyuuuxxx|<br></br>
`?>>>>>>>+?vv}}vv}rvvruuuuxwwwxrzryyyyyyyyyyyyyuyur?>>?????????}wuyyyuuuxx|<br></br>
.>>>>>>>>+?vvwwvrwwvvzuuuuxrwruzzyyyyyyyyyyyyyyxuv>>vv?vv}vvvv>?}wuyyuuuxx|<br></br>
.>>>>>>>>>vv}wwwwwwvvzxuuuxwwwxwuyyyyyyyyyyyyxzxv>>?v???vvvv}?>?vvwuyuuuxu|<br></br>
.>>>>>>>+?vvwwwwwwwv}zxuuuxr}vwuyyyyyyyyyyuxwwur>>??vvvvvvvv?>>>>>?vwxuuuu|<br></br>
.>>>>>>+>vv}wwwwwwr}rwzzz}vvrruuuuyuuuuxzzzzuyzvv?>>?vvvvvv??>+>>???v}wwwz|<br></br>
.>>>>>>+?vvwwwwwwrv}rrwwwv>}uxxuuuuxvvzxuyyyyyxuyr??>+>>>>>>++>?vvvvvvvv}w|<br></br>
.>>>>>>>vv}zwwwwrrwwrrzwv>>wzxuuuxxxxwvzyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+?vvwwwwwww}wzrr}}??uuuxrvv??>+^>xyyuuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>>v?vzwwwwwr}uxuuv?vwuuv?????+`-^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+?}vrwrwwwwvzxzux}vxzuz>?>>???+~wyyuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.^^+++vvvw}rwwwrrzwwr}vz}ux??vv>+>>+vuyuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.>>??>>?rzr}www}xxxxxzxvxu}??v??>`-vyyuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.???v}v+rzwwww}zuxuuuurrur?>??>?-.|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 -~~|^>}vrwwwwvwuuuuxz?zwvv?++|~..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 ``----^?vrwwvvvxuuuzv??????>|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.++>>++~.|vwrvw?}xuu}v}^??+>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.^^+++>?+.+w}zuw}vwx?v?+???v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
 ----~~^>>-?ww}r}r}rvxw???vvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.||~~~---|>|}r}vv?x}zxrwz}??v??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.^^^^^^||`|v?rv?v?z}rwrzwv}??}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.^^^^^^^^|~??}?+vzw}?vvwvvvvxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.^^^^^^^^|^^~+vwrrvvvvvr}}?vxuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.^^^^^^^^~>?>+wwwwv?vvv}}rv?}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.^^^^^^^^~????rzxxx}vvv?????v}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^|~???>}xuuuxrv>>?vvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^~^???>?vrzxuur+??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^-????>wzrvvr}vzyxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.>^^^^^^^^^^^+>++?>+^^^^^^^^>?v}?rrrrrrrrr}vvvrrwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>>????v?>>>>?>^^>vv}wvwyuuuyyyyyuxzyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+?v????>>??vz}?+|+?v}v?uuuuuuyyuyyxuyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+>}wv????vvvwuxxxwv>>?v?uuuuuyyyuyyyyyyyyyyxzwwzwzxuyyyyyyuuuxxx|<br></br>
`??>>>>>>>>vww???vvvvvuuxzxuyuv???yyyyyyyyuyyyyyyyyzv>>?????vrzuyyyyuuuxxx|<br></br>
`?>>>>>>>+?wwrvvvv}vvruuxxxuyyyrwvyyyyyyuuyyyyyyyur?>>?????????}zuyyyuuuxx|<br></br>
.>>>>>>>>+vwwwwvrwwv?wuuxxxuyyyzwyyyuuuxxyyyyyyyuv>>vv?vv}vvvv>v}zyyyuuuxx|<br></br>
.>>>>>>>+?rwwwrrwww??zuuuuuuyyu}xxuxuuuyyyyyyyyxv>>?v???vvvv}?>?v}xyyuuuxu|<br></br>
.>>>>>>>+vwwwwwwwww?vzxxxuuxzrrxuxzxyyyyyyyyyyu}>>??vvvvvvvv?>>>??vwuyuuuu|<br></br>
.>>>>>>+>rwwwwwwwwrv}}wzw?v}wxuuuzzuyyyyyyyyyywvv?>>?vvvvvv??>+>>??vvrwwwz|<br></br>
.>>>>>>+vwwwrwwww}v}}}www?+ryyuzxuyyyyyyyyyyyuxuy}??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>>}wwwwwwwrrrwvrrwv??zuyyuzxxuuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+?wwwwwwwwzvrx}v?zv?yyuz}?++++>?vxyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>+>}}wwwwwwwrvyxuz}?vwyyw>~||^^||^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+?rwwwwwwww?wwwzz}vuyyv>||^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.~|^^+}wwwwwwwz}}w}v???xuuv^||^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.????++?}wwwwwwvuuxrwwuyyz+^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.??vruz>?}wwwzvwuuzzxwuyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 .`.`->}?v}wwr^ryxzyuwzuzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.~||~-``>vv}w?>>uxuyuuur???v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|-~>vr?v>}xuuxuz}v>+>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^-~vvwxrwvrxzrvvv??v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^|~?vrvwrvvyz}}v?vvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^|->?v}r}>zz}vvv?vvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^|~??v}}}?w}??vv???vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^||vvv>>rzwv+vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^|^>+??wzwv?>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^~?}?ryyuu}???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^-vr}xuuuyu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^||rr}xuuuuyxv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^->r}vuuyyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^`?r}}uyyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.+^^^^^^^^^^^+>?>??>^^^^+^++vv?vrrrrrrrr?+-`-~v??wwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>+?vrvr}?>>??????rr}uyyyyyyyyyx}?^`rw}yyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+>v}zzr}??vvvv???}rzuyyyyyyyyyyuxr?vr}yyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>+?vwwwr}v}}vvv?v?vrzuuuyyyyyyyyuuuwur}yyyyxzwwzwzxuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>>?}wwwwwwwvv}v}vvvwxuyyyyyyyyyyxyyxzyuyyzv>>?????vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+?vwwwwwww}vrw}}vvvwuuyyyyyyyyxzuyyxryyur?>>?????????}zuyyyuuuxx|<br></br>
.>>>>>>>>>>vvwwwwwwwrwww}}vvvrxuyyyuuxzwzuyyyzuyuv>>vv?vv}vvvv>v}xyyyuuuxx|<br></br>
.>>>>>>>>+?vrwrwwwwwwwwwwwv?v}zxzrzzwzuyyyyuzuyxv>>?v???vvvv}?>?}ruyyuuuxu|<br></br>
.>>>>>>>>+vvwwwrwwwwwwr}v}v?rrxxz}?wyyyyxzwzuyuv>>??vvvvvvvv?>>>??ruyyuuuu|<br></br>
.>>>>>>>+?vrwwwwwwwwwr}}rrrvwrzwrv?zzwrwzxuyyywvv>>>?vvvvvv??>>>>?v}rwwwwz|<br></br>
.>>>>>>>>?vwwwwrwwwwrrwwwv?v}}w??}xrvrxyyyyyyuxuyv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+>vvwwwwwwwwwww}wwvv}vrwrvzxuz}?xyyyyyyyyx}vv?>>??vv?+|?}}vvvvvzuuu^<br></br>
.>>>>>>+??}wwwwwwwwwwv}zzzux}v}}???>>^>xyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+>vvrrwwwwwwwwr?zxz}xx}?^>??^`-^}uuuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>+?vwwwwwwwwwwwvwzzv}yzv??^>??||wyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.^^^^+>v}w}wzwwwwwwv?}}vvzzv?vv>^|^^wyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.?>>>>+~?zrrwwwwww}?vvuvuu}?vv??>`-vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`vvvv}rv??zwwwwrww?v}?}wxr??vv??~.|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.|||^+>rx?vzww}rwrvxz?>wrvv?>++|..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 ```---+vz?}zrvrwrwuz}xv>>??>|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.+>>>+^-->}?w}vrwwwx}wzwv++>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.+>>????~`>?v}v}www}?rvv???v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
 -~~|^+??+~>?rvvv}wvv}}v?vvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.~~~~~~~^?+~?}}r}}}?>vvv?vvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.^^^^^^|~-+^^}rwww??>~?????vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^|-^->?}rr?v+^vvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^^~`>?vv>?v>|?vvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^^`+zuxvwwwv}??vvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^^`+wuxwyzvww}}>vvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^^^^|`+rxuxxrv}?}v?vvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^|->}zuuxzzrv?|>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^~~?}rwzxuuuux>v?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.+^^^^^^^^^^^^|^??v>^^^^||^+++|?}}}}rrrr>~``+>?>^wwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.?>>>>>>>>>>>>>}rrw?>>++>????>?xyyuuuuuuuzv^^+??>yyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>>>+?rrrr>+>>???????}uyyyyyyuuuuuw>?>>>yyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>>>+r}}r}>vv???v}?>?ruuuuyyyyyyuuuz?x?>yyyyzwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>>+?r}}wwrwv?v}wr?>vzyyyyyyyyyyyyuyr}yxyyzv>>?????vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>>>+}rrrwww}v}www}>>vzuyyyyyyyyyyyyyw?yyur?>>?????????}zyyyyuuuxx|<br></br>
.>>>>>>>>>>+?rrrwwww}wwwwwv>>vxuuuyyyyyyyuyyyvzyxv>>vv?vv}vvv?>v}uyyyuuuxx|<br></br>
.>>>>>>>>>>+vr}rwwwwwwwwwwv^>vxuyyyyyyyuyyyzvwyxv>>?v???vvvv}?>?}zyyyyuuxu|<br></br>
.>>>>>>>>>+?wrrwwwwwwwwwwwv+v}xzuuyyyyyuz}?vxyuv>???vvvvvvvv?++>?vzyyyuuuu|<br></br>
.>>>>>>>>>+}wrrwwzwwwwwwww}vwvxzxxzwrv?vvwuyyuwvv>>>?vvvvvv??++>>?vwwwwwwz|<br></br>
.>>>>>>>>+>rrrw}rwwwwwwwrv??vvwwr}}+|?wuyyyyyuxuyv??++>>>>>++^>?vvvvvvvv}w|<br></br>
.>>>>>>>>+vrrrwwrwwwwwwwv?v}}}rwrrwzr?~wyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>>>+>rrrrwwwwwwwwrv}zzw}rrww}??>~|xyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>>>+?wrrwwwwwwwwwrwwvzzv??vrv+.`^}uuuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>>>+}rrwwwwwwwwwwww?}yxvzw|>vv^-wyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.>>++++^?r?}zwwwwwwwwww??yyrzux?+^+^ryuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.???>>>+}wvvrrwwwwwwww}^xyx}xxw}?.-vyuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.?>>>>>?wwzwwrrwwwwwwr>?r}v}xx}}~.^zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.+++++|?}v}}rwrrwwrwwv>w}vwv???+..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 `--~~|v}vvvv}rrwrrww>zr???>+^-..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
`vvv??+~|vvvv}}}rrrwvvwvv?>>??+|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
`vv}rwxz+-v}v}}}}rrr>}vvvv?v????>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
 --~^+?rx?~v}}}}vrw?>}vvvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
 --`````+r?|}}}}v}r+|vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^|~``?>?}v}}}+>>|vvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^~`>|v?}}}^?>`?}vvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^|--|?}}?vzv-^vvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^^-.?}}>xyz}r?>?vv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^^~`?}}vyu}uuwr~vvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.^^^^^^^^^^^|`?vrrrv}uvw?+vvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^^^`+?rxrrrrvv`>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^^^`++v}rxxxxz~v?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.^^^^^^^^^^^^^+^^^^^^^^^^^^?v?++|~``~|^^+?>|wrwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>?????>+>>??>>>>?>>+>>>>rw}xyxw}?+~~+>>v~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.???????>+>?vv?+>+vv+>>????}vvuyyyyyxr?^|^>~}yyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`??????>>>?vvv?++>vv??????}rrwuyyyyyyyurv??`?yyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
`?????>>+?vvvv>>>v}vvv??v}zr}zyuyyyyyyyurzw?vyyyyyyzv>>>??>?vrzuyyyyuuuxxx|<br></br>
`????>>>+?vvvv??vr}}}}vv}rz}}xyyyyyyyyyyxwuzuyyyyur?>>????????vrxyyyyuuuxx|<br></br>
`????>>+>vvvvvv?v}rwww}v}wwrruuyyyyyyyyyyvuyyyyyxv>>vv?vv}vvv?>vruyyyuuuxx|<br></br>
`???>>>+?vvvvv?v}wwwww}v}zr}ruuyyyyyyyuxrvyyyyyxv>>?v???vvvv}?>vrxyyyyuuxu|<br></br>
`??>>>+>vvvvvv?}wwwwwwvv}zwwrxuuuuuxzwwrwuyyyyxv>???vvvvvvvv?+>?vzuyyyuuuu|<br></br>
`?>>>>+?vvvvv?vzwwrwwwvvrwrwzxxxur?}xxwzuyyyyur}}>+>?vvvvv???+>>?vrwwwwwwz|<br></br>
`?>>>>>vvvvvv?rzwwwwwwv}v}rwzzxuzr>>wzuyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>+?vvv??vvwwwwwwwr}r}wwr}wr?++vzuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>vvvv???}wrwwww}v}}v}xwwr}v?~|+>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>+?vvvvv??w}rwww}rwwr?xxzuz}?+`-^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.++>>vvvvvv?}zwwwwrwwrz?vrwxuw>.`~|^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.^^|+???????wzwwwwwwvwr^v??vr?``|^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
`}}v}vvvvv???vzwwwwvrz?>ru?|^>+-~|^vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`vvvw}vrwwzxz?vzwwvvuvvv?w?>--~|~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.+|^>+^^+>>?vz?vzr>rzvxxr}??`-~-..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.~^>???????>>>v?w??zwrxzyz}|+~`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|>???????????>???vr}}vv}?+|??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.-~+???????????>~wrrr}}}}v>vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.>+--??????????+-rzzr}vv???vvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.>>?|~?????????|^?zxwr}???vvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
 ~|+?+~???????+`|????v??v??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.~~~~>^^?????>|^`rw?+??vvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^|~+|?>???^|+`?}v>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^|~|^+??>->+`?w???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^|`^??+^??~v}>?vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^-^??^>?>+yur++>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^~^??>>?|vyxuv}v??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^~|>??++-rzzrwyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.^^^^^^^^^^^>>>|^^^^^^^^^++?}v>|^+``~^^^>?>~wrwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>+?v}}+>>>>++>???>xyyyuzr?+--|>>?v~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>+>}rwv+>>++>??v?>?uuuuyyyyur>|-^>v|xyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>+rwww?+>>>?vv??>?ryyyyyyyyyyyw+^^>~xyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>+?wwww?+?????v???vzuyyyyyyyyyyyx?r>`xyyyyyyzv>>>??>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>+}rwww?}}???vwv>?vxuuuuyyyyyyyyyz?yvuyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>+?wrwwwwwv?}www?+?rxyyyyyyyyyyyyyy>xyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>+vwwwwwwrvwwwwr?^?rxuuyyyyuyyyyyyx?uyyyyz?>>?v???vvvv}?>vruyyyyuuxu|<br></br>
.>>>>>+>wwwwwwwwwwwwwr?^?wxuyyyyyuuuyyyz?xyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>+vwwwwwwwwwwwwwr>>vwxuuuyyyuyyuwvvxyyyyur}}++>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>+>rrwwzwwwwwwwwwr?vvwxxuyyyxw}v?vzyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>+vwwwwrzwwwwwwwwvvrvrwrrwv|+>vwuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>+rwwwwrwwwwwwwr???vvrwwwrr?+~~+>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>+?wrwwwwwwwwwwrvvwwwwrrzzr}}?``^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>+}wwwwwwwwwwww}wr}wv?vwzz?|``-|^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.+++?wwzzwwwwwwwwwww+wyv?v>vw}|`|^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.>>+}rvwwwwwwwwwwww>vyu}uy}|>v?--|^vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
.>>?z}vzwwwwwwwwwzv>yuzzyyrv-``|~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
.>^vwr}rzrwwwwwwww^rxz}yyurv``~-..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.++}}rrrwzwwwwrwz>vx}rwwz}r|^|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.^?wrrrrrrwwwwww}>wv?}v?v>||??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.-|?rrrrrrrrwwrw>zuux}?????vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
`z}|~}rrrrrrwrwv?uuuzvvvvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
`rxu>~}rrrrrrww>xuuz}vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
 -|?x?|rrrrrrwv-wuu}?vvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.-``-}?>wrrrrv^>|xwv>vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^|-`?^}v}rr>^?~?vv>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^|`^+>}r}^vv^^r???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^|~`+rr??yu???^?vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^.+}w?wyxwyxv^+>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^`^}rvrxvuuwz>?v??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^`|vrwvv?w????yyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.>^^^^^^^^^^|>}v+^^++>>>>+>+rwrwwwwwwwrvv?^^>|rwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>+}zrvv????v}}???uyyyyyyyyyyuxxvwv~uyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+?zwww}v?v}wz}??vuyyyyyyyyyyyuuzxy}xyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>+vzwww}}}wwwwv>?ruuuyyyyyyyyyuxxxyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+>wwwwwwwwwwww?>?wuuuuyyyyyyyyuuwyyyyyyyyzv>>>>?>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+vzwwwwwwwwwww???zxuyyyyyyyyuxw}xyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+>wwwwwwwwwwwww?vvzxuyyyyuxzr}vruyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>>+vzww}wwwwwwwwr}rvrwwww?>v}}wzuyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>>>wwwwr}wwwwwwv??v}rwwwwv+|vyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+?zwwwwwwwwwwv?}rrr}rwrv?^~zyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>>+rzwwwwwwwwwr}wrzv?}wwr~+}uyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+?wrzzwwwwwwwww?wy}r}?rzv>wuuuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>>+}}vzzwwwwwwwz?vyuzyyv>?+-|++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+>zrvrwwwwwwwzv+xxruyxx+.~^^|^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>+vzwwwwwwwwwww^rw}rwzrv`~^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.-~~|^+wzwwwwwwwwwr>~vvv}v?^`~^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.????>+~?zwwwwwwwwv^??yuuz^||^^^^||vuuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`rrwzxyw~>zwwwr}ww^?r^uyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ``-~+?wu^>zwwv}zv+uz?vwwvv?^~~-..>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 --``...^w^vz}?rwvvuzuuv???v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^|~`-?+wv?rrv}}wyxwv^>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^|~~?v?vvw}+vwvv???vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^^`-vv??rwww}r}>vvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^^|`?}v}wwwwrvv>vvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^^|.?wwwwwr?>+>???vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^^^.->vrrwr^??vvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^^^.v?>+??>>??vvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^|.wyuxzv+???vvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^|.wuuuur??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^^^|.rzxxwzxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^-`xuxzuyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^`^yyyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`>^^^^^^^^^^|>vv}>^|^>>>>++?wwwwwwwwwwv||--?wwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>+rzzz>>????v???}yyyyyyyyyyyw?}-vyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+?zwwwvr??v}w?>?wuuuyyyyyyyyyvrzzyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>+}wwwww}v}www>^?zuyyyyyyyyyyy}?yyyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+>zwwwwwrwwwww>~vxuuyyyyyyyyyu?zyyyyyyyyyzv>>>>?>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+vwwwwwwwwwwwr>>vxuyyyyyyyyu}vzyyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+>wwwwwwwwwwwwr>}vxxuyyyuw}vvryyyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>>+vzwwrzwwwwwwwv?rvwwwwv+?vwxyyyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+>wwww}rwwwwww???vvrwwww?>>xyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+vzwwwwwwwwwrv}wwrrrww}v?|>uyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>>+wzwwwwwwwwwrwrvx?>?rwv->wyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+?wrzzwwwwwwwwr>uuvzw+vw?+zxxuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>>+rvvzzwwwwwwwz>zyzxyuv^||++++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+?zw}rwwwwwwwzv?uxryyww~-^^^^^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>+}zwwwwwwwwwwr>z}}rwwr?-^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.-~||^+zzwwwwwwwwwz?}wv}v??^-|^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.????>+^vzwwwwwwww}vyyyyuw^|^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`rrzxuyr~?zwwwwwww?xyyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ``-|>vzx^?zwwwwzv>yuuyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 ---``.`^w^}wwwwr+~}yuywvv?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^~`-?>zvwz?^?|uurv??>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^~~|?vzw+v?`}rvvv??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^^-`vzv?yx?v>?vvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^^~`vwvwywuyr>>vvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^^|`?wwvvvu}w+?v??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^^^`>}wr}}}v?^}vvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^^^`???vrwwzv~vvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^|-wwwwv?^+++vvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^|-rwwwr|^?vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^^^|->>?v+vuv>>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^->urv?ryyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^`vyyyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

`>^^^^^^^^^^|>}?^^^^+>>>+^^}wwwwwwr}}v+`~>+~wwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>+rzv>>>????v?>vyyyyyyyyuuux}??>~uyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.>>>>>>>>>>+?zwv}v??v}r}??vuuuuuyyyyyuuuwur|zyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>+rzwwrvv}wwwv??ryyyyyyyyyyuuyxzyxuyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+?zwwwr}rwwwwv?vwuuuuuyyyyyyuyzzyyyyyyyyyzv>>>>?>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+}wwwwwwwwwww???zuyyyyyyyyyyyzryyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+>wwwwwwwwwwww???zxuyyyyyyyuzvvuyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>>+vzwwwzwwwwwwwv}vwzxuxzwrvvv}xyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+>wwwr}wwwwwwwvv}rrrrrv>^?zuyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+vzwwwwwwwwwr??vv}}wzwrv||uyyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>+>wwwwwwwwwwrvrzw}}wwwv|^?xyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+?wwzwwwwwwwwwrrxv?vrwr>}yuxxuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>>+rvrzzwwwwwww}?uuzyx??v?~>+++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+?z}vwwwwwwwwr+zuzuyxw^-~||||^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>+}wwwwwwwwwww>vx}wzzr?`|^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.~~||^>zwwwwwwwwwwv-?v?}vv+`~^^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.???>++|vzwwwwwww}^?+uuxuw^^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`}rwzuy}`vzwwwvwz?>}|zyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ```~^?wz~vzw}vww+xx??}uzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
 ---``..>r|wrvvw}vyzxy}??v?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|`~>?}?vrrv}}yzwv>?>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^~--v??vww?vwvv>v??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^|.>v??vvwzwrr>vvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^^`|}}rwrwv?>?>vvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^^~`vwwwwr?~??vv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^^~`^+?vr}|+vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^^~^uwv??^?>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^-^yuyux}???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^`|xxxzuu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^^^.>uxzuyyxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^^.vyuyyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^|.wyyyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.+^^^^^^^^^^|>v}?^^^|^+>>^?rrrwwr?|``^+|?wwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`v?>>>>>>>>>>wwz?+++>?????xyyyyyyyu}^|^^vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`?>>>>>>>>>+vzww>>?????>>vuyyyyyyyyyz?v-vyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>+wwwwvr??vr}>?ruuuyyyyyyyyw}zzyyyyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+?zwwwz}vrwzv^?wuyyyyyyyyyyx?yyyyyyyyyyyyzv>>>>?>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+rwwwwwrwwww?~?zuyyyyyyyyyuvwyyyyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+?zwwwwwwwwww?+vzuuyyyyyyxrvzyyyyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>>+rwwwwwwwwwww?vvzxuuuxrvvvwyyyyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+?zww}wwwwwwwv?vvrrrw?|?ruyyyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+}zwwwrwwwwwv?v}r}wwwwv|>yyyyyyyyyyyyur}}++>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>+>wwwwwwwwww}rww}?vwwv+^?uyyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+vwwzwwwwwwww}?u}vv?r}^vyyuxuuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+>wvwzwwwwwwww>xyryy?>?>+?>+++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+vwvrwwwwwwwz?vyzzyxw^|^^||||^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>+rwwwwwwwwww}>z}}wxrv~+^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.~||^^?zwwwwwwwwww?r}vr?v>~|^^^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.???>+++rwwwwwwwzv}yyyuuxr+^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`rrzxuuv~rzwwwwww?uyyyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ```~+?zr^rwwwwz??yuuyyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.~~~-```>v>zwww}^~ryuuywvv?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|`|>vvwz>>>^yuurv??>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^~~|?ww>wv-rxrvvv??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^|.?wvvyzwr??vvvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^^`?w}rzryzr^vvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^^`+rz}?v}?+?vvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^^-|?v}wwwz^?vvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^^-?}}rv???^+vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^^`vwww}~>>??vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^^`+??v+vu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^^|~xrv?ryyxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^-^yyyyyyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^`?yuuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.>>+^^^^^^^^|^>|^^^^||^>+?wr}??+~-~^^>>|wwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
`v?>>>>>>>>>>}v+>++>????+zyyyyyuzv+||>?|xyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`?>>>>>>>>>+vz?+>>???v???uyyyyyyyyur>^+~vyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
.>>>>>>>>>>>ww?vv??v}r??vuuuuyyyyyuyxwr|?yyyyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+vzwwr?vrwzr??ryyyyyyyyyyuywuxryyyyyyyyyyzv>>>??>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>>+wwww}rwwww}??wuuuyyyyyyyyywuyyyyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+?zwwwwwwwwwv??xuyyyyyyyyyywwyyyyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>>+rwwwwwwwwwwv??xxuyyyyyuxrvryyyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+?zwwwzwwwwwwr}vwzzxzrr}v}wuyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+}www}wzwwwwv?v}rwrw}>^ryyyyyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>+?zwwwwwwwwrvv}}r}ww}?+|xyyyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+}zwwwwwwww}www}vrww?+ryyuuxuuyyyyyyyyyyyx}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+>r}zwwwwwwwzv}ywzrvrw>vr?>+++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+vrvwwwwwwww}?yuzyxv+>>+^||||^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>>>wwrrwwwwwww>wr}wxwv?v+^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.~|^^^?zwwwwwwwwr?^}?}vv>>v^^^^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.?>>>+^+wwwwwwwwv^+ruxxxxz+^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`}}rzxx>^wwwwvww^?>>yyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ````|?wv+wwv?w}?ur?vwyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.~|~~-`.???w?vw}wuzyzvvwvv?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|`^^}??wwv?zzrv?v??>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^~.>v>?}rrrrr+vvv??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^-~vvvv}rr}r??vvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^~`vwzzzv>>>?vvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^|.^?vrwv+}vvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^|-wv???>}v?vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^|-uyyuuzvv>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^~-uuuuuyr???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^^-^uuuuuyu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^^`?yuuuuuyxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^^.vyuuuuyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^~`zuuuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.^^^^^^^^^^^|^>+^^^^^^|^+?^~``~|^^>>^wrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.++>>>>>>>>++?}?>>>++>??}yuz}?|-~+>?+uuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
.?>>>>>>>>>+vww?+++>???>zyyyyyu}+-|>|xuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`?>>>>>>>>+?zww>>>????>vuyyyyyyyyv++-xyyyyyyyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+}zwr?v??vr>>}uuuyyyyyyyvr?uyyyyyyyyyyyyyzv>>>??>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>+>wwwwwvvrzr+>wyyyyyyyyyywvyyyyyyyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+vzwwwwrwwwv~>zuyyyyyyyyuvzyyyyyyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>+>wwwwwwwwwwv>vzuyyyyyuxrvxyyyyyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+vzwwwwwwwwwvv}zzxxw}vvvzyyyyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>+wwwrrwwwwwv?vv}rrw>>vuyyyyyyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>+?zwwwwwwwwvvrrr}wwrv+^uyyyyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+rzzwwwwwwrr}w}>?rw>|vuyyyuxuuyyyyyyyyyyyu}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+?wrzwwwwwww}?yrwz>vv^zu}?>+++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+}}}zwwwwwww?xuwyu}|?w}+|||||^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+>zwrwwwwwwz?vw}wuz??yv^^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.|^^^^vzwwwwwwwwr?w?}vv>>u}|^^^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.>>>>++?zwwwwwww?xyuxxzzuz+^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`}}wzux>>zwwwwzvvyyyyyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ```-|?z??zwwwz^}yuuuuyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.~||~-``?>}rww?^|xuuuuywvv?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|-^>?ww^?|?yuuurv??>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^|`>wv?uv?vzu}vvv??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^^`+wvwuwy}>vvvvvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^-^rwv?wvv>vvvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^~~?v}wrr?|vvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^~>vv}}v??|?vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^^-?www?~v?v>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^^`+>??+zyr???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^|-zzvvxyuu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^~~uyyyuuuyxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^->yuuuuuyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^`}yuuyyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.^^^^^^^^^^^|++^^?>^^^^||~||||^^+?>>wrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrrrrw~<br></br>
.>>>>>>>>>>>+?>>>>>++>>?v?+|^^^+>>v>zuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuu^<br></br>
`v?>>>>>>>>^?v+>>++>??>ryyuzw?+~|+>+?uuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxxx|<br></br>
`v?+>>>>>>+?wv++>????>>xyuyyyyyw?^^+^uuyyyyyyyyyyyyyyxwwwzwzuuyyyyyuuuuxxx|<br></br>
.>>>>>>>>>+}w?????v}?>vuuuyyyyyyyw}>-uyyyyyyyyyyyyyzv>>>??>?vrzuyyyyuuuxxx|<br></br>
.>>>>>>>>+>wwwr?vrww?>}yyyuyyyyyyyzyvxyyyyyyyyyyyur?>>????????vrxyyyyuuuxx|<br></br>
.>>>>>>>>+vzww}rwwwr?>wuyuyyyyyyyywyyyyyyyyyyyyyxv>>vv?vv}vvv?>vwyyyyuuuxx|<br></br>
.>>>>>>>+>wwwwwwwwwr??zuyyyyyyyyurzyyyyyyyyyyyyz?>>?v???vvvv}?>vruyyyuuuxu|<br></br>
.>>>>>>>+vzwwwwwwwwr??zxuyyyyuz}vzyyyyyyyyyyyyz?>???vvvvvvvv?+>vruyyyyuuuu|<br></br>
.>>>>>>>>wwwrwwwwwwrr}rwwxvv}}rzyyyyyyyyyyyyyur}}+^>?vvvvv???+>?vwwwwwwwwz|<br></br>
.>>>>>>+vzwwrwwwwwv??}rwwwv|?yyyyyyyyyyyyyyyyuuyuv??++>>>>>+++>?vvvvvvvv}w|<br></br>
.>>>>>>+rwwwwwwwwv}rr}rww?+>zyyyyuxuuyyyyyyyyyyyu}vv?>>??vv?+|?r}vvvvvzuuu^<br></br>
.>>>>>+?wwwwwwwwww}wwv}}wv?yyu}?>+++>?vuyyyuuuuurvvvvvvvvvv?``wyywvvvvrrru^<br></br>
.>>>>>+}vwzwwwwwzvvyzyx????u}+|||||^|^vuyuuuuuyzvvvvvvvv}r>..`^?r+->??>++v~<br></br>
.>>>>+?zrrwwwwww}?zwzuzv>zyv^^^^^^^^>zyuuuuyyyy}+++>?vwxuu>>>+~.. .~^^^^^|.<br></br>
.|^^^^}zwwwwwwwr^?v}}v?>uyv|^^^^^^^>xyuuuuuxxxuv+>?}zuyyurvvvvv>` `~|~~||~.<br></br>
.?>>>++?zwwwwww?|>xzzzzuyz+^^^^^^||}uuuuuuxxxzwrrxxxxxuxrvvvvvvv^`......`` <br></br>
`vvrwxw^?zwwvw}^?~zyyyyyuv>^^^^^~`|zyuuuuuyyuwrrruyyyuwvvvvvvvvvv??^`..... <br></br>
 ...`~?w>vzv?zvruvvvxyyyzvv?^~~-`.>yuuuuuuuuzzzrzyuyuwvvvvvvvvvvvvv?>|`... <br></br>
.||||~``?>r?vw}zwuu}?uywvv?v?|`..`}yuuuuuuuuw}}ruyuurvvvvvvvvvvvvvv?>>>-.. <br></br>
.|^^^^^|-~???rwvvw}vrurv??>>??>|`|xyuuuuuuuuuxuuyuurvvvvvvv?vvvvvv?>>>>+.. <br></br>
.|^^^^^^^-~v??vrwwzvrrvvv??vv???>vyuuuuuuuuuuuyuuyzvvvvvvv?>vvvvv?>?v++>`. <br></br>
.|^^^^^^^|`vrrrrv???v}vvvvvvvv?>}xuyyyyyyyyyyuyyyyzvvvvvv?+>vvvvvvv??^+^.. <br></br>
.|^^^^^^^^.^vwww??xz}vvvvvvvv??wuuuuzrr}}rrrrwwzzxxv?vvvvv??vvvvvv?^+^|`-^`<br></br>
.|^^^^^^^^`v?>???uu}vvvvv??vv}xuuux}v>?????????????>?vvvvvvvv???>+^>vvv}zu^<br></br>
.|^^^^^^^^`xyuxzuxrv?vvvvvvrxuuuuuuw}vvvvvvvvvvvvv??vv>?vv??>>??v}zuyyuuuu^<br></br>
.|^^^^^^^|`zuuuuuzvv>?vvvvv}xuuuuuuuuxrvvvvv??vvvvvvv?>+>????vruyyyyuuuuuu^<br></br>
.|^^^^^^^~`xuuuuuyr???vvvvvv}xuuyuyyyyrvvvvv?vvvvvvvv?>v}rwwzxuyyyyyyyuuuu^<br></br>
.|^^^^^^^-^uuuuuuyu??vvvvvvvv}xzzuyx}?|-~+?>>??+~~||~~?xuyyyyyyyyyyyyyyuuy^<br></br>
.|^^^^^^^`?yuuuuuuyxv?>?vvvvvzyz?>}u?..-vww}}rrw?......|zyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^^`ruuuuuuyyyuwv??>??~+ru}-.^w|`?uxrvv???x^~..``?yyyyyyyyyyyyyyyyyy^<br></br>
.^^^^^^^~~uuuuuyyyyyyyyuxr?`. .-..-~^..|w??????}?~|..--vuuuuuuuuuuuuuuxxxu|<br></br>


            </div>,

            <div>

.+++++++++++++>+>>>>+++^^^^^^^++^+?????????????????????vvvvvvvvvvvvvvvv???`<br></br>
.>???????????????????>>>>+^+>>>??+vvv}}}}}}rrrrrrrrrrrrrwwwwwwwwwwwwwrrr}}~<br></br>
.>>????????>??????>>>>>vv?+^|^+>?^?vvvvv}}}}}}rrrrrrrrrr}}}rrwwwwwrrrr}}vv~<br></br>
.?????????>?vv?>>>>>?>?}}}}v?>^^>^?}vv}}}}}}}rrrrrrwrvv??v?vv}}rrrrrr}}}vv~<br></br>
.??>>??????vvv>>>>????v}}}}}}rv+>|?}}}}}r}}}}rrrrrrv?>+>>>>>??v}}}}}}vvvvv-<br></br>
.>>>>>>>>>?vvv??????>?v}}}}}}}rv??v}}}}rrrrrrrrrr}?>>+>>>>>>>>>?vr}}}vvvvv`<br></br>
.>>>>>>>>>?vvvv??vv?+?}r}}}}}}}}>rrr}}}}}}rrrrrrv?>>??????????>??}}}}}vvvv-<br></br>
.>>>>>>>>>vvvvvvvvv?+?vr}}}}rrr??}vvv??????vv}rv?>>???>???????>??}}}}}vvvv-<br></br>
.>>>>>>>>?vvvvvvvvv>>?v}rr}}v?>?v??????????????+^>>?????????>>>??}rrr}vvvv-<br></br>
.>>>>>>>>vvvvvvvvvv?????v>++>>??>>>>>?????vvv?+>>~~^>??????>>>>>?vvvvvvvvv-<br></br>
.>>>>>>>?vvv?vvvv???????>^-~?????vvvvvvvvvvvvvvr}?>+|^++>>>+++>>??????????`<br></br>
.>>>>>>>vvvvvvvvv???+^>>|-->v}}}}}}}}rrr}}}vvvvvv???>++>???>+^>???????v}}}-<br></br>
.>>>>>+?vvvvvvvvv?^?~|~++-?}}v??>+++>>?}}}}vv}}v???????????>--vr}v???????}-<br></br>
.>>>>>+??vvvvv???|???v>~^?}r?+^++++^^+?v}vvvv}}v??????????>-`-^>v^~>>>>>+?`<br></br>
.>>>>+>v?vvvv?>?|>?>vv?+}rr?+++++++^+v}}}}vvv}}?+++>>?vv}v++>+|-``-|+++++^.<br></br>
.+++>+?vvvvv?>?>^>+???+vrr?+++++++^+v}}}}vvvvv}?+>>?v}}}}??????>|`~^^^^^^^.<br></br>
.^^++^+?vvv?>??>?vvvvvvrrv++++++++^?}}}}}vvvv????vvvvv}v????????+~~~~~~~||.<br></br>
.>???v?^?vv??v??v}}}}}}}}?>+++++^|^v}}vv}}}}v????}}}}}v???????????>+|~~~~~.<br></br>
 -~~~|>?+????v^?}}}}}}}rv???+^^^|~>}}}}vvv}}v????}}}vv??????????????>^~-~~ <br></br>
.^++^^||>+?vv?+^v}}}}}rv????>^|~~~?}}}vvvv}v????v}}v????????????????>>>|~~ <br></br>
.+++++++^~>vv>?^>}}}}}???>+>>>>^||v}}}}vvv}}vvv}}vv????????>???????++>>>~~.<br></br>
.+++++++^||v??}??>?r}????????>>>>?}}}}}vvvvvvv}}}}v???????>+??????+??>>>|~.<br></br>
.++++++++^^?vv?vv?+}??????????>+?v}}}}}}}}}vv}}}}}v???????++???????>?+++~- <br></br>
.++++++++^|?v????+vv??????????>v}v}vv?????????vvvvv???????>???????>+>+^||^.<br></br>
.++++++++^|???v??+???????>????v}v}v>>+>>>???>>>>>>>+>???????????++^>????v}-<br></br>
.^+++++++^^vvv?^????>???????v}vvvvv?>>????????????????>>??>>++>>??v}}}}}}}-<br></br>
.^+++++++^|>??>v}v??>>??????vvvvvv}}vv??????>>????????>++++++>?}r}}}}}vv}}-<br></br>
.^+++++++~>v?>v}}v?>?>???????v}}}}}}}r???????????????>+>????vv}r}}}}}}}}}}-<br></br>
.^+++++++-?}}}}}}}v>??????????vvv}}v?>|-~^>++>>^||^|||>v}rrrrrrr}}}}}}}}}}~<br></br>
.^+++++++-vvvvvv}}}v?>+??????v}v>+?}>``~?v??????>`.....~vwrrrrrr}}}}}}}}rr~<br></br>
.^++++++|^vvvvvv}}}}}v?>>>>>~+v}?-`+v^~?}vv?????v^|-`-`>rrrrrrrrrrrrr}}}rr~<br></br>
.^++++++~>vvvvv}}}}}}}}}v?>-.``-``~||``~v?>>>>>?>|^``|~?rrrrrrrr}r}}}}vv}}-<br></br>


            </div>,

            <div>

.??????????????????????>+^++++++++++++++++++++++++^^^+>???????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vvvvvvvvvvvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vvvvvvv}-<br></br>
.>>??vvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????`<br></br>
.>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^~~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+|`.       .-^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>|-.     ........ ..-^>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>|`.  -.`~|^++>>>>>>+^|~--~^+>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>^-.   ``.|>>>>>>>>>>>>>>>>>>+^^^+>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>|.  .`~+>>~`+>>>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>^.  `|>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>~ .~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>^ `+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++>>>>>>+.<br></br>
.>>>>>>>>>>>>>>`->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>+.<br></br>
.>>>>>>>>>>>>>^|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>+.<br></br>
.>>>>>>>>>>>>>^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>^>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>>+++++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

                  .?????????????????????????????????????????????????????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????vvvvvvvvvvvvvvvvvvvvvvvvvvv}-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvvvvvv-<br></br>
.vvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvv-<br></br>
.?vvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??vvvvvvvvvvv-<br></br>
.>>?vvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????vvvvv`<br></br>
.>>>>???vvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>?????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^|^++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^~`.     ..`~^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>|`.    .```````...`~+>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>|`.   .||+>>>>>>>>>+^|~--~^+>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>+~.  `~`  `+>>>>>>>>>>>>>>>>^||^+>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>~..`~^+>>|. .+>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>^..-+>>>>>>>+-..-+>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>+.~>>>>>>>>>>>>+-..|>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>|^>>>>>>>>>>>>>>+^~-|+>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>>+.<br></br>
.>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++.<br></br>
.>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>++>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>++++++++++++++++++++++++++++++++++++++>^.<br></br>


            </div>,

            <div>

.++^^>????????????????????????????????????????????????????????????????????`<br></br>
.>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>??vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvv}-<br></br>
.>>>>>?vvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??????????`<br></br>
.>>>>>>>??vvvvvvvvv?>>>>>>>>>>>>>>++^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>????>>>>>>>>>>>>>^-..  ..`~^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>+~`. `-.-~|||~---|^+>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>+|`.    |}+>>>>>>>>>+^||+>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>+~...~^`   ?v^>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>|..|++>>+.  .??+>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>+`|>>>>>>>+`  .>?+>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>^>>>>>>>>>>>|.  ~>+>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>+~. .`~+>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>+|``..~+>>>>>>>>>>>>>>>>>>>>>+++>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>+|-~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>+.<br></br>
.>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>+++++++++++++++++++++++++++++++++++^++>+.<br></br>


            </div>,

            <div>

.++^^>????????????????????????????????????????????????????????????????????`<br></br>
.>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>??vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvvvvvv-<br></br>
.>>>>?vvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvvvv-<br></br>
.>>>>>?vvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??????????`<br></br>
.>>>>>>>??vvvvvvvvv?>>>>>>>>>>>>>>++^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>????>>>>>>>>>>>>>^-..  ..`~^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>+~`. `-.-~||~~---|^+>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>+|`.    |}+>>>>>>>>>+^||+>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>+~...~^`   ?v^>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>|..|++>>+.  .??^>>>>>>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>+`|>>>>>>>+`  .>?>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>^>>>>>>>>>>>|.  ~>+>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>+~. .`~+>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>+|``..~+>>>>>>>>>>>>>>>>>>>>>+++>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>+|-~+>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>+.<br></br>
.>>>>>++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>+.<br></br>
.>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>+++++++++++++++++++++++++++++++++++^++>+.<br></br>


            </div>,

            <div>

                  .++^^^^^^^^^^^^^^^^^^^^^^?????????????????????????????????????????????????`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}-<br></br>
.>>>>>>>>>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>+++?????>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>+++>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvv?????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>???vvvvvvvvvvvvvvvvvvvvvvvvv?`<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>??vvvvvvvvvvvvvvvvvvvv?+.<br></br>
.>>>>>>>>vvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvvvvvv???>>>+.<br></br>
.>>>>>>>>?vvvvvvvvvvvv??>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>?????>>>>>>>>>+.<br></br>
.>>>>>>>>>?vvvvvvvv??>>>>>>>>++|~-```~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>?????>>>>>>>>^~`.  |++++++^||^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>+~-~^~  .+>>>>>>>>>>+++>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>^~^>>>>`  |>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>+^>>>>>>+. .+>>>>>>>>>>>>>>>>+>+>>>>>>>>>>>>>>>++>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>++>>>>>>>>|  ~>>>>>>>>>>>>>>>>>>>>>>+>>++++>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>++>>>>>>>>>- ->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>++>>>>>>>>>>>>>>>>>>>>>>>>+` +>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>++++>>>>>>>>>>>>>>>>>>>>+-.+>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>++++>>>>>>>>>>>>>>>>>|`~+>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+||>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>++++++++++++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

`vv}?>??????????^^^^>???????????????????????????????????>+++++++++++++++++.<br></br>
`zx}vvvvvvvvvvv?>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????.<br></br>
`wv?vvvvvvvvvvv>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????.<br></br>
`vvvvvvvvvvvvv?>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvvvvvvv???.<br></br>
`vvvvvvvvvvvv?>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvv>>>?vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvv????????>vvvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvv?????????>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvv?????????>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvv???????????>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv??>???????????>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`?vvvvv?>>>????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????vvv?vvvvvvv-<br></br>
.>>???>>>>>?????????>?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>????>>>?vvvvvvvvvv-<br></br>
.>>>>>>>>>???>???vv??vvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>?>?vvvvvvvvvv`<br></br>
.>>>>>>>>>?>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>+|~|^|-+>>??vvvv?vvvvvv`<br></br>
.>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>^. `+^.|+>??vvvvvv??????`<br></br>
.>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>|`.`.~???vvvvvvvvv?v???`<br></br>
.>>>>>>>>>++?vvvvvvvvvvvvvvv??>???vvvvvv???>>>>>+>>>>>>?vvvvvvvvvvvvvv?v??`<br></br>
.>>>>>>>>>>+?vvvvvvvvvvvv??>+|~^>>>>>>>>>>>>>>>+>>>>>?vvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>>>vvvvvvvvvv??>>>>+~~+>>>>>>>>>>>>>>+>>>>?vvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>+>>?vvvvvvv??>>>+|``--~^>>>>>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>++>>>?????>>>>+^^^`.~||+>>+>>>>>>>>>>>+>?vvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>+>>>>>>>>>>>++>>>>^`^|+>>>>>>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>>+>>>>>>>>>>>>>>>>+.+^^>>>>>+>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>>>++>>>>++>>>>>>>>^.++^+>>>>+>>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>>++>>>>>>>>>>>>>>>`.>>>>+>>>++>>>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>>>+^^+>>>>>>>>>>>>>-.^>>>>>>>>>+>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>^-````-~^^++>>>+|..^>>>>>>>>>>+>>>>>>>>>>>>>>?vv????????????????`<br></br>
.>>>>>>>+||+^++^^|~-``--`.`|>>>>>>>>>>>>>+>>>>>>>>>>>>>+>++>>>>>>>++>>>>>+.<br></br>
.>>>>>>>+++>+++>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>+>>>>>+.<br></br>
.>>>>>>>++++>++>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>++>>>>>>>+>>>>>+.<br></br>
.>>>>>>>++++>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>+>>>>>+.<br></br>
.>>>>>>+++++>>>>>>>>>>>>>>>>++++++++++++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

    `vrrrr?????????+^+???????????????????????????????>^^^^^^^^^^++++++++>?`<br></br>
   ^zyuyu}vvvvvvvv?>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>>>>>>>>>>>>>>>>>?}-<br></br>
 .vyuuuzvvvvvvvvvv>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>>>>>>>>?v-<br></br>
`wyuuur?vvvvvvvvv?>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>?v-<br></br>
-uuuu}vvvvvvvvvvv>>>>>+vvvvvvvvvvvvvvvvvvvvvv?>>>>?vvvvvvvvvvvvvvvvvvvv?vv-<br></br>
-uuzvvvvvvvvvvvv?>>>>>>?vvvvvvvvvvvvvvvvvvvvvv>+>vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uwvvvvvvvvvvvvv>>>>>>>>vvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvv>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvv?+>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvv-<br></br>
`vvvvvvvvvvvv?>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvv-<br></br>
`vvvvvvvvvvv>>>>>>>>>>>>+vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????vvvvvvvvvvvvv-<br></br>
.>???vvvvv?>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>^^>>>??vvvvvvvvvv-<br></br>
.>>>>>>??>+>>>>>+>?????vvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>++>^-^>>?vvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>^``^>>>^^-+?vvvv?v?v??vv-<br></br>
.>>>>>>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>+^.`+>>>>>|~vvvvvv?vvvv?v-<br></br>
.>>>>>>>>>>>>++?vvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>+>>| ~>>>???`^vvvvvv?v?v?v-<br></br>
.>>>>>>>>>>>>+>?vvvvvvvvvvvvv??????vvvvv??>>>>>>+>>>` ^?vvvv^ ?vvvvvvvvvvv`<br></br>
.>>>>>>>>>>>>+>?vvvvvvvvvvv?>>>>>>>>>>>>>>>>>>>+>>>>?~ ^????- ?vvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>+>?vvvvvvvvv?>>>^^++>>>>>>>>>>>>>++>>>?vv>`..`..+vvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>++>?vvvvvv??>>>|  ^>>>>+++>>>>+++>>+>?vvvvv?>>?vvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>+>>>>>>>>>>++>+^`->>>>>>>>>>>>>>>>>>vvvvvvvv}vvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>++>>>>>>>>>+>>>+>`~>>>>>>>+>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>++>>>>>>>>>>>>>>+.|>>>>>>+>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>+^~~+>++>>+++>>>>>>>>>+^.|>>>>>++>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>+^-~^^+>>>>>>>>>>>>>>>-.>>>>>>+>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>+-`|^>>>>>>>>>>>>>+?``>>>>>>+>>>>>>>>>>>>+????>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>+>|.->>>>>>>>>>>>+>+ |>>>>>>++>>>>>>>>>>>+++^>>>>>>>+>>>>>>++.<br></br>
.>>>>>>>>>++>+>+^`.^>>>>+++++>?~ `>>>>>>>>+>>>>>>>>>++>>>^>>>>>>>+>>>>>>++.<br></br>
.>>>>>>>>>+>+>^>>>~..|>??>??>~. ~>>>>>>>>>+>>>>>>>++>>>>>>+>>>>>>+>>>>>>++.<br></br>
.>>>>>>>>++++>+>>>>>|`........~+>>>>>>>>>>+>>>>>>++>>>>>>>+>>>>>>+>>>>>>++.<br></br>
.>>>>>>>>+++>+>>>>>>>>>+|||^+>>>>>>>>>>>+>+>>>>>>>+>>>>>>>>+>>>>>+++++++++.<br></br>
.>>>>>>>>>+++>>>>>>>>>>>>>>>+++++++++++++++^++++++++++++++++^+^+^+++++++>+.<br></br>


            </div>,

            <div>

    ...?rrr}????????>^??????????????????????????????>^^^^^^^^^^^^^+++++???`<br></br>
   ..|ryuux}vvvvvvvv?>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>?vv}-<br></br>
  .`>xyuxzvvvvvvvvv?>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>>>>>>>>>vvv-<br></br>
 .-vyuuxwvvvvvvvvv?>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>>>>>>vvv-<br></br>
 ^zyuxxrvvvvvvvvvv?>>>>>vvvvvvvvvvvvvvvvvvvvv??????vvvvvvvvvvvvvvvvvv??vvv-<br></br>
`xuuxz}vvvvvvvvvv?>>>>>>vvvvvvvvvvvvvvvvvvvvv?++>?vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuxzvvvvvvvvvvvv?>>>>>>?vvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`zxzvvvvvvvvvvvvv>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`wwvvvvvvvvvvvvv?>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvv?>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvv??>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvv?????^???vvvvvvvvvvvvv-<br></br>
.>??vvvvvvvv?>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>|->>??vvvvvvvvvvv-<br></br>
.>>>>>?????>>>>>>>>>????vvvvvvvvvvvvvvvvvvvvvvv?>>>>++>+>^-~>??vvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>|.`+>>>>+^`+vvvvvvvvv?vv-<br></br>
.>>>>>>>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>++.`+>>>>>++-|vvvvvvvvvv?-<br></br>
.>>>>>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvv>>>>+>>^ ~>>>??v?>~-vvvvvvvvvv`<br></br>
.>>>>>>>>>>>>>>+?vvvvvvvvvvvv?????????v???>>>>>+>>>>- ^?vvvv?+>.+vvvvvvvvv`<br></br>
.>>>>>>>>>>>>>>+?vvvvvvvvvv??>>>>>>>>>>>>>>>>>>+>>>?v| |?vvv?^+ |vvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+?vvvvvvvvv?>>+|~^+>>>>>>>>>>>>++>>>vvv>..-~-``.`?vvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+??vvvvvv?>>>+`. |>>>>>++>++++>>>+>vvvvvv>|~-~^?vvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>++>>>>>>>>>++>^| ->>>>>>>>>>>>>>>>>?vvvvv}}vvv}vvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>+>>>>>>>>>+>>++~ ^>>>>>>+>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>++|^>>>++>>>>>>>>>>>+?-.^>>>>>>+>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>+++^~^+++>>>>>>>>>>>>+>`.+>>>>>+>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>+^|`|++>>>>>>>>>>>>>+|..+>>>>++>>>>>>>>>>>?vvvv???????????????`<br></br>
.>>>>>>>>>>>>+^` |++>>>>>>>>>>>>>~` ->>>>>+>>>>>>>>>>>+>>>>>>>>>+>>>>>>>+>.<br></br>
.>>>>>>>>>>>>>++..^++>>>>>>>>>>>^~~ ->>>>>+>>>>>>>>>>+>>++>>>>>>+>>>>>>>>>.<br></br>
.>>>>>>>>>>>+++>^`.^>>>>>>>+>>>^~^..^>>>>>>>>>>>>>>+>>>>>^+>>>>>>>>>>>>>+>.<br></br>
.>>>>>>>>>>>++++>+~.`|+>>>+++|~~~..|>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>+++.<br></br>
.>>>>>>>>>>>++++>>>+-..-~----`...`+>>>>>+>>>>>>>>>>+>>>>>>++>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>+++>>>>>>>|-`.....`|+>>>>>>>>>>>+>>>>>>+>>>>>>>+>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>++>>>>>>>>>>+++^+>>>+++>+++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

       ?rrrrv???????>^>?????????????????????????????>^^^^^^^^++++++++++???`<br></br>
     `}yuyurvvvvvvvv?>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>?vv}-<br></br>
    +xyuuxvvvvvvvvv?>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>>vvv-<br></br>
  .vyuuuzvvvvvvvvvv>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>>>vvv-<br></br>
 ~wyuuuwvvvvvvvvvv?>>>>>vvvvvvvvvvvvvvvvvvvvv??????vvvvvvvvvvvvvvvvv???vvv-<br></br>
`xyuuu}vvvvvvvvvvv>>>>>+?vvvvvvvvvvvvvvvvvvvv?++>?vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuuu}vvvvvvvvvvv?+>>>>>>vvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvv?vvvvvvvvvvvvv-<br></br>
-uuxvvvvvvvvvvvvv>+>>>>>+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`xzvvvvvvvvvvvvv?+>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvv?>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvv?vvvvvvvvvv-<br></br>
`vvvvvvvvvvvvv?>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvv?????~>??vv?vvvvvvvvvv-<br></br>
.???vvvvvvvv?>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>~`>>??vvvvvvvvvvv-<br></br>
.>>>>>???vv>+>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvv?>>>>++>++^-~+?vv?vvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>|.`+>>>>+^`+}}v?vv??v?vv-<br></br>
.>>>>>>>>>>>>>>++?vvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>+^.-+>>>>>+>`~vvv??vvvvvv-<br></br>
.>>>>>>>>>>>>>>+>vvvvvvvvvvvvvvvvvvvvvvvvvvv>>>>+>>^ ->>>??vv?``vvvvvvvvvv`<br></br>
.>>>>>>>>>>>>>>+>vvvvvvvvvvvv?????????????>>>>>+>>>>~ ^?vvvvvv> +}vvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+>vvvvvvvvvv??>>>>>>>>>>>>>>>>>>+>>>?v| ^?vvvvv> ~vvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^>vvvvvvvvv?>>+~-^+++>>>>>>>>>>++>>>vvv>.`|+++~..?vvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^>?vvvvvv?>>>+|` ^>>>>+++>++++>>>+>vvvvvv>~---|?vvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>++>>>>>>>>>++>+?.->>>>>>>>>>>>>>>>>?vvvvv}}vvv}vvvvvvvvvvvv-<br></br>
.>>>>>>>>>++>>>>++>>>>>>>>+>>+>^ ^>>>>>>+>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>+^||>>>++>++++>>>>>>+?~ ^>>>>>>+>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>|-^+++>>>>>>>>>>>>+>- ^>>>>>+>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>^.|++>>>>>>>>>>>>>^?..+>>>>++>>>>>>>>>>+>vvv?????>>>>>>>>>?>`<br></br>
.>>>>>>>>>>>>>>~ |+^>>>>>>>>>>>>++^ ->>>>>+>>>>>>>>>>>+>++>>>>>>++>>>>>+++.<br></br>
.>>>>>>>>>>>>>+>. ^++>>>>>>>>>>>+>> `>>>>>+>>>>>>>>>>+>>++>>>>>>+>>>>>>>+>.<br></br>
.>>>>>>>>>>>+++>^`.^>>>>>>>>>>>+>?. |>>>>>>>>>>>>>>+>>>>>^+>>>>>++>>>>>>+>.<br></br>
.>>>>>>>>>>>++++>+-.->?>>>+>>>>>+. ~>>>>>>++>>>>>++>>>>>>>+>>>>>>+>>>>>+++.<br></br>
.>>>>>>>>>>>++>+>>>+-.`^+++>+|`. .^>>>>>+>>+>>>>>>>+>>>>>>++>>>>>>>>+++>>+.<br></br>
.>>>>>>>>>>>+++++>>>>+|`..   .`~+>>>>>>+>>>>+>>>>>>+>>>>>>>+>>++>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>++>>>>>>>>>>++^^+>>>+++>+++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

       `~?rr}v?>?????+>?????????????????????????????+^^^^^^^^^^^++++++>???`<br></br>
     .`|vuuxzrvvvvvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>??vv}-<br></br>
    `-^ryuzw}vvvvvvv?>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>??vvv-<br></br>
  .`-?xyxww}vvvvvvv?>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>>??vvv-<br></br>
 .-~vuuzwwvvvvvvvvv?>>>>vvvvvvvvvvvvvvvvvvvvv??v??vvvvvvvvvvvvvvvvv???vvvv-<br></br>
 ->wyuzwwvvvvvvvvv?>>>>>?vvvvvvvvvvvvvvvvvvvv?>>>?vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}uyuzwwvvvvvvvvvv?>>>>>>vvvvvvvvvvvvvvvvvvvvv>?vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuuwwrvvvvvvvvvv??>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`xxww}vvvvvvvvvvv?>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vv}vvvvvvvvvvvv?>>>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv>vvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvv?>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvvvvv?????~+vvvvvvvvvvvvvvv-<br></br>
.???vvvvvvvvv?>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvv??>>>>>>>|.|>?vvvvvvvvvvvv-<br></br>
.>>>>>???vv??>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvv?>>>++>++++~`~?vvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>+>?vvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>-.-+>>>>+^-~>?vvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvv>>>>+^.-+>>>>>>?^|^+?vvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+>?vvvvvvvvvvvvvvvvvvvvvvvvvv>>>>+>>^ ~>>>??vvv^|>~?vvvvvvv-<br></br>
.>>>>>>>>>>>>>>+>?vvvvvvvvvvvv????????????>>>>>+>>>>| ^?vvvvvv?~>^~vvvvvvv-<br></br>
.>>>>>>>>>>>>>>>>?vvvvvvvvvv?>>>>>>>>>>>>>>>>>>+>>>?v^ ^?vvvvv?-^+`vvvvvvv-<br></br>
.>>>>>>>>>>>>>>+>??vvvvvvv?>>>|--^>>>>>>>>>>>++>>>?vvv>.`^>>>+-~+``vvvvvvv-<br></br>
.>>>>>>>>>>>>>>+>>??vvvv??>>>^` .+>>>>++>>+++>>>>>vvvvvv^`....`-`|?vvvvvvv-<br></br>
.>>>>>>>>>>>>>>>>+>>>>>>>>>++++. ^>>>>>>>>>>>>>>>>?vvvvv}v??>>>?vvvvvvvvvv-<br></br>
.>>>>>>>>>>>+^+>+>+>>>>>>>>>>>++ .>>>>>>>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>+^^~^>+++++++>>>>>>+?| `+>>>>>+>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>+~`^++++>>>>>>>>>>+>| .^>>>>++>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>+``+++>>>>>>>>>>>>+>` .^>>>++>>>>>>>>>>>>?????>>>>>>>>>>>>>>.<br></br>
.>>>>>>>>>>>>>>| |+^+>>>>>>>>>>>>+|. `+>>>+>>>>>>>>>>>+>+>>>>>>>+>>>>>>+>+.<br></br>
.>>>>>>>>>>>>>+>`.+^^>>>>>>>>>>>>+|. .+>>>>>>>>>>>>>>>>>++>>>>>>+>>>>>>+>>.<br></br>
.>>>>>>>>>>>>>+>+`.^+>>>>>>>>>>>>+`..`>>>>>+>>>>>++>>>>>>+>>>>>>>>>>>>>+>>.<br></br>
.>>>>>>>>>>>>>>+++-.-+>>>>>>>>>+^`. .^>>>+>+>>>>>>+>>>>>>>+>>>>>>>>>>>>+++.<br></br>
.>>>>>>>>>>>>>>+>+>^`.`|^^^^^|-`...`^>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>^`..........`|+>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>+|-`...`~^+>>+++>++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

         ^rrrr?+?????>+????????????????????????????>^^^^^^^^^^^^+++++>????`<br></br>
        ?uyuyw?vvvvvv?>vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>>>>>>>>>>>>?vvv}-<br></br>
      .vyuuur?vvvvvv?>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>>>>>>?vvvv-<br></br>
     |zyuuu}?vvvvvvv?>>+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>>>>?vvvv-<br></br>
   .?uuuuuvvvvvvvvv?>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vvvv-<br></br>
  -}yuuuxvvvvvvvvvv>>>>>>vvvvvvvvvvvvvvvvvvvv?>>>?vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?uyuuuzvvvvvvvvvvv>>>>>+?vvvvvvvvvvvvvvvvvvv?>?vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuuwvvvvvvvvvvv?>>>>>>>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`xuuurvvvvvvvvvvvv>>>>>>>+vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvv?vvvvvvvvvvv-<br></br>
`v}}vvvvvvvvvvvvv?>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv|vvvv?vvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvv?>>>>>>>>>>+vvvvvvvvvvvvvvvvvvvvvvvvvv????~^vvv?vvvvvvvvvvv-<br></br>
.>??vvvvvvvvvv?>>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvv??>>>>>>>^`~>v?vvvvvvvvvvv-<br></br>
.>>>>>>??vvv?>+>>>>>>>>>>?vvvvvvvvvvvvvvvvvvvvv?>>>++>+++++|->vv?vvvvvvvv}-<br></br>
.>>>>>>>>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvv>>>+`.|+>>>+++>||?vvvv??v?vv-<br></br>
.>>>>>>>>>>>>>>>>+?vvvvvvvvvvvvvvvvvvvvvvvvvv>>>>+^.~>>>>+>?vv>--??vv?vvvv-<br></br>
.>>>>>>>>>>>>>>>++?vvvvvvvvvvvvvvvvvvvvvvvv?>>>>+>>^ ~>>>??vvvv?~.?vvvvvvv-<br></br>
.>>>>>>>>>>>>>>>++?vvvvvvvvvvv?>>>????????>>>>>+>>>>+ |?vvvvvvvv?-.vvvvvvv-<br></br>
.>>>>>>>>>>>>>>>+>?vvvvvvvvv?>>>>>>>>>>>>>>>>>>+>>>?v> ^?vvvvvvv?| ?vvvvvv-<br></br>
.>>>>>>>>>>>>>>>++>vvvvvvv?>>>^-`^>>+>>>>>>>>++>+>?vvv?.`>?vvvvv>..vvvvvvv-<br></br>
.>>>>>>>>>>>>>>>>+>>?vvvv?>>>+^~ ^>>>>+++++++>>>>>vvvvvv|..`---..`?vvvvvvv-<br></br>
.>>>>>>>>>+++>>>>+>>>>>>>>>++>++ ~>>>>>>>>>>>>>>>>?vvvvv}v?+||^>?vvvvvvvvv-<br></br>
.>>>>>>>>>>>+~^>>>^>>>>>>>>+>>^?..+>>>>>>+>>>>>>>>+>vvvvvvv}}}}}vvvvvvvvvv-<br></br>
.>>>>>>>>>>>>+`~>>++++++++>>>>>??..^>>>>>+>>>>>>>>>>>vvvvvvvvvvvvvvvvvvvv}-<br></br>
.>>>>>>>>>>>>>+`^++++>>>>>>>>>>+>?. |>>>>>+>>>>>>>>>>>?vvvvv??????????????`<br></br>
.>>>>>>>>>>>>>>~`+^++>>>>>>>>>>>^>?. |>>>>^>>>>>>>>>>>>>>>>>>+>++>>>>>+^>+.<br></br>
.>>>>>>>>>>>>>>+.^+^++>>>>>>>>>>>+>> .+>>>+>>>>>>>>>>+>+^>>>>>>>+>>>>>>^>+.<br></br>
.>>>>>>>>>>>>>>>~.+^^>>>>>>>>>>>>++?  ^>>>>+>>>>>>>+>>>>++>>>>>>>>>>>>>^>+.<br></br>
.>>>>>>>>>>>>>>>+-.^^+>>>>>>>>>>>^v| .+>>>>+++++++^>>>>>>+>>>>>>>>>>>>>+>+.<br></br>
.>>>>>>>>>>>>>>+^>-.~+>>>>>>>>>>+?+  ~>>++>++>>>>>++>>>>>>+>>>>>>>>>>>++++.<br></br>
.>>>>>>>>>>>>>>+++>^..~>>>>>>>>++` .|>>++>>>++>>>>>++>>>>>>>++>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>+++>>>|`.`~|^^|`. .-+>>+>>>>>>>+>>>>>+>>>>>>>>>>>>>>>>>>>>+.<br></br>
.>>>>>>>>>>>>>>>+>>>>>>+~`.   .`|+>>++++++++++++++++++++++++++++++++++++>+.<br></br>


            </div>,

            <div>

 ......  ~????????????>??????????????????????v?`.....`??>>>>>>>>>???++>>>>`<br></br>
                  .......+vvvvv??vvvvvv?vvvvvvvvvvvvvvvvvvvvrwzv`````.+vv?????????vvvv?????`<br></br>
 .....`?}vvvv?????vvv??vvvvvvvvvvvvvvvvvvvrzwwz>````^wr????>>?????vvvv????`<br></br>
 ... ~v}vvv?vvvvv???????vvvvvvvvvvvvvvvvvwwwwzzz^```vr?>>>>>>>>>???vv}v???`<br></br>
 ...+vvvvv??vvvvvvv?????vvvvvvvvvv?vvvv}wwrwwwwzw~`~+>?vvvvvvvvvvvvv}}rv??`<br></br>
 .`?vvvvv????vvvvvv??????vvvvvvvvv?vvv}wrrzzw}}}r>|?}rvvvvvvvvvvvvvv}rr}v?`<br></br>
.>vvv}w}?????vvvvvv??????vvvvvvvvv??vrzr|?xzzr}}>>vzzvvvvvvvvvvvvvvvvrrr}v`<br></br>
`vvvvrxwwwwr}vvvvvv??????>vvvvvvvv??rzv~`-vxzz}+?zwz}vvvvvvvvvvvvvvv}rrr}v-<br></br>
`vvvv?}z?|+>?vr}}v?????????vvvvvvv?rzv-```~zxz?|zwzzvvvvv?vvvvvvvvvvrrrr}v`<br></br>
.??????z}`.```+??>^~`+?vv????????v}zzv````.>zzr>|vzrvvvvv>vvvvvvvvv}rrrrvv`<br></br>
`??????vzv~```~rw}}}?`^>??vwrvv}rwwrwzrv??>+rzzw?^++>??vv>?vvvvvvvvrrrr}vv-<br></br>
`v??????vrwv+`.~}rv}r+--..`}zwwwwr}v?vv}rrrwwwwwr}>---~+??+>?vvvvvvvrrrvvv-<br></br>
`???+>>>>??}rv+`>r}vr>`-..`vzwvvvvvvvvvv????????>>>++^~~~-|^+?}vvv?vv}}vvv-<br></br>
.??>>>>>>>>>>?vv?vv}w^>v`~vzwvvvvvvvvvvvvvv???>>>>~`+>>??>+|~`|?vvv??vvvvv-<br></br>
.?>>>>>>>>>>>>>>??vw?-rz?rz}vvvvvvvvvvvvvvvvv>>>>++`~>>>???vv?^.~?vvvvvvvv-<br></br>
.??>>>>>>>>>>>>>>>vv-?zwzw}vvvv??vvvvvvvvvvv???>>>>+.~>>?vvvvv}>.-?vvvvvvv-<br></br>
.???>>>>>>>>>>>>>?>~>}rwrvvvvv?>>>?vvvvvv???????????>`|?vvvvvvvv-||vvvvvvv-<br></br>
.?>>?>>>>>>>>>>>>^`>vvvvvvvv?>>>>>>????????????????vv?`+vvvvvvvv-~-vvvvvvv-<br></br>
`??>>>>>>>>>>>>>^`-??vvvvvv?>>+~`^>????????????????vvv?~^?vvvvvv`.~vvvvvvv-<br></br>
.++>>>>>>>>>>>>+``+>???vv??>>>^|`>????????>|^>????vvvvvv+||^++^- |?vvvvvvv-<br></br>
 .->>>>>>>++>>+``^>>>>>>>>>+>>^^`+??????>++~`-|+??vvvvvvvv?>>>+`^vvvvvvvvv-<br></br>
 .`+>>>>>>?>+|``+>+>>>>>>>>>>>+>``|~>>>+>++>?>^~`-~+?vvvvvv}vv++vvvvvvvvvv-<br></br>
 ``^>>>>>>???-.|>>>>>>++++>>>++>+..`>>>>>++>?????>^~~||^^+++++?vvvvvvvvvvv-<br></br>
 `.~>>>>>>>?+.~`^++++>>>>>>>+^+++^. |>>>>>+>>??????????v?????vvvvvvvvvvvv?`<br></br>
 `.-+>>>>>>>`|?~`++++>>>>>>+++>>+>| .^>+>>>>>>>>>????????????????????????+.<br></br>
 `.`^>>>>>>^`+>+`~|+>+>>>>>>+>>>>>-`..+>>>>+>++>+>>+>>>>????????????????>+.<br></br>
 ``.~>>>>>>^`+>>|.|++>>>>>>>>>>>>+.-..+>>+>+>>+>?????????>?????????????>>+.<br></br>
 `..`+>>>>>+`^>>+~.^+>>>>>>>>>>>>|`-.`>>>>+^++>???????????????????????>+>+.<br></br>
 ````^>>>>>>^~++++~`~+>>>>>>>>>>^~...|>>>?????????????????????????????>+++.<br></br>
 ```.|>>>>>>>|~^++>+``~+>>>>>>>+-. `^>>+???????????>>>>>>>???????????>>>>+.<br></br>
.``..`+>>>>>>>+~|^+>>^```~|||~`..`~+>>>>??????>>>>>>>>>??????????????>>>>+.<br></br>
 ``..`^>>>>>>>>+|-~+>>>+|-`.. .-^+>+++++>>>>+++++>>????????????????>++++>+.<br></br>


            </div>,

            <div>

.?????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>>?vvvvvvvv??????>>>>>+.<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwrvvvvvv}w}v??????v}}}}vv???>.<br></br>
`}}}}}}}}}}}rrrrrrrwwwrrrrrrrrrrrrrrrrrrrrwwwww}???+^?????>>++^^+?v}}v??>>.<br></br>
`}r}}}}}}}}rrrrv?>++>>?v}rrrrrrrrrrrrrrrrwwwwwww?--^vr}v?????????^|}}}?>>>.<br></br>
`}r}}}}}}}r}?+||^^++++++^+vrrrrrrrrrrrrrwwwwwzr>+>vvvvvvvvvvvvvvv>>vvvv?>>.<br></br>
`}r}}}}}}}>-^?}rwwwwwwrrr?~?}rrrrr}rrrwwwwwrrv^?wv??vvvvvvvvvvvv>?vvvvvv??.<br></br>
`}}}}rrrr?-vrrrrrrrrrrrrrw?~vrrrrr}rrwwr??}r?-vr?>?}}vvvvvvvvvv??vvvvvvvv?`<br></br>
`}}}}rwww|?wrrrrrrrrrrrrrr?^}rrrrr}rwr?~~^v?`vv>?}}}vvvvvvvvvvvvvvvvvvvvv?-<br></br>
`}}}}}wwr->}}rwrrrrrrrrrrr|>rrrrr}vvv?||^^^-+z?^}}}vvvvvvvvvvvv?????vvvvv?`<br></br>
`}}}}}rww~~??vvrr}}vv}www?-?vv?????v}v^^||~.~vr?+?v}v??vvvvvvv??????vvvv??`<br></br>
`vvvvv}rwv-??vvwwwwr?^>?+->????vvrr}rrvv???>>|^?>^+????vvvvv????????vvvv??`<br></br>
`vv????vv}>?v??vwwwr?^|~ `^}r}}}r}vvvvvv}}}rzvr?+~-``|>??vv????????vvvvv??`<br></br>
`vvv????????vv?+?}}}?>~...`?v}vvvvvvv?vvv?vv- ?rv?>+~``-`~+?v}}}}}}}}}}v}}~<br></br>
`vvv??????v??vvvv}}}v~.-.`?v??vvvvvvvvvvvr}` .???vv??>>?v>+^~|?vvvvvvvvvvv-<br></br>
`vv????????????vvvv}+ |?>?vvvvvvvvvvvvv}wrx? >v?vvv?vv+>v????>.|?vr}}v???v-<br></br>
`vv??????????v???vv>.~?}rvvvvvv??vvv??}?vxzx>rvvvvvvv}?+??v?++~.+vwwwzw}vv-<br></br>
`?vv???????v????vv+.^???v`.?z}v??>???r> ~xwwwvvvvvvvv}}+>vvvv+~.`>?vvrwzvv-<br></br>
`zr}vv?????v???vv^ +v?>>?v^|zw}`~v?}rrv>+}zr}??vvvvv}rv??vvvvv|.`^++++>?>|.<br></br>
`xwrrr}vvv?v????|.^?v?>???vvvvx^`v?vv?vvv?vv>>?vvvv?v}vv?vvvvvv.`^++++++++.<br></br>
`v?????????????|.+????>>???>??v}}vv?vvvvv?+^++?vvvv??vvvvvvvvvv.`+++++++++.<br></br>
.?????????????|.^???>+>+>>>>>??vv??>?vvv???>^^>vvvvvvvvvvvvvv}?.~+++++++++.<br></br>
`vvvvvvvvv}vv^.>vvv+`~>+++>>>>>??>^^?v??+>???v??????vvvv?vvvvv-~?+++++++++.<br></br>
`rwwwwwwwwwz?.?wwww^.~>>>?++++++>+|-|>>|~~~^?vvvvv?????>+>???^~vv+++++++++.<br></br>
`vvvvvvvvvv?.|vvvv}|`~>>>?>|++++>|~.-++^>v?+^^++>>?????+>>>>+?}}}>+++++++^.<br></br>
.>?????????-.?????^```+>>++^^+>>>+~.+>+^^+>>?????>>>++|^>>??v}vvv?^++++++^.<br></br>
.++>vvv>???.`????~.``.+>>>>>+>>>>>.->^^++>??????vvvv?>>vvvvvvvvvv?++++++^^.<br></br>
.+?vvvv????.~?>^-.````^>>>>>>>>>>-.|+^?v???????vvvvv?>?vvvvvvvvvvv+++++^^^.<br></br>
.>?v?}?????`-~````````~>>>>>+++>~.`+>>>>>>>???vvvvv?>?vvvvvvvvvvvv++++^^^^.<br></br>
.~>v?v?>>+~..`-````````+>>>>>>+~.`|>>>++?vvvvvvvvvv??vvvvvvvvvvvvv>+++^^^^.<br></br>
.`~>^+?^````.``-```````+>>>>>+-.`~+>>+++?vvvvvvvv?>>vvvvv??vvvvvvv?++^^^^^.<br></br>
 `-^|^~``````.`````````|>>>>^`.-^+++++>>?vvv???>>>>?vvvvvv?vvvvvvv?++^^^^^.<br></br>
 `~^^+~```````..`.`````->>+~..^++++++++++>>>++>>??vvvvvvv??vvvvvvv?^^|||||.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????>>>+.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}}}vvvvvvvvvv???>.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv??>?????v?>++?vvvv??>>.<br></br>
`wwwwwwwwwwwwwwr}v????vrrwwwwwwwwwwwwwwwwwwwwwwwv+^+?vvvvv?>+?vv?>+vv??>>>.<br></br>
`wwwwwwwwwww}??>||+>>+|^?v}wwwwwwwwwwwwwwwwwwwr+|?rwwwrrr}}vv?+?v>?v?>>>>>.<br></br>
`wwwwwwwwwv??v?vrwwwwwr??v?}rwwwwwwwwwwwwwwrwv`>wwrrrrr}vvvvvvv^+?vv??>>?>.<br></br>
`wwwwwwwwv?rv?rwwwwwwwww>vv?rwwwwwwwwwwwr}rr?.?wrrrrr}vvvvvvvvv>+v???????>`<br></br>
`wwwwwwww?vv?wwwwwwwwwwwv?v?rwwwwwwwwr}}}}rv`-}rr}}}vvvvvvvvvvvv>?????????`<br></br>
`wwwwwwww??vwwwwwwwwwwww}??vwwwwwwrr}}}}rr}|~+}r}}vvvvvvvvvvvvv?+?????????`<br></br>
`wwwwwwww>>wwwwwwwwwwwwwr+>}rr}}}}}}rrr}}vv+|+>?vvvvvvvvvvvvvv??>>>>>>>>>>`<br></br>
`v}}rwwwwv?wwwwwwwwrvv}}>-vv}}}}rrrr}vvvvvvv?^|^^>?vvvvvvvvv???>>>>>>>????`<br></br>
`vvvvv}}rrv}wwwwwwwrvvvv`>}rr}}}vvvvvvvvvvv}wvv>^`.`~+?vvvvv??????????????`<br></br>
`vvvvvvvvvvv}}}}rrrrvvv|`>??????vvvvvvvvvvvv|`vrvv>+-``||^>?}rrr}}}}}}v}rr~<br></br>
`vvvvvvvvvvvvvvvvv}}v?|.^>>???vvvvvvvvvvvr}~.`vvvvv??>+^+|>>>>?vvvv?vv?v}}~<br></br>
`vvvvvvvvvvvvvvvvvvv?-.^>+>????vvvvvvvvvrrx? -}vvvv?v?+>?+~|>?|>??vvv??vvv-<br></br>
`vvvvvvvvvvvvvvvvvv?~`~v?+>???v??vvvv?v?vzxv`^vvvvvvv}?+>?v>`^^~?vrrr}}v?v-<br></br>
`vvvvvvvvvvvvvvvvv>~^->>>.`>vv}vv????}+`|uxx+?vvvvvv}rv^>?>>+`>-+?vv??}rv?`<br></br>
`zzwr}vv?v?vvvvvv>|?^?+^+++^??}|+vvvv??^.vuxv?vvvvvvrr???v?^+-^-|+^||-+>+|.<br></br>
`zwwwwwr}vvvvvvv>~?+>?++>^>vv+v?>?^v??v?+>}v?>?vvvvvrv?vvvv>^~|`|^^^^^^+++.<br></br>
`vvvvvvvvvvv???+~??~??>+>+++?vvww}+vvvvvv?>>>>?vvv?vvvvvvvvv>~|`^+++>>++++.<br></br>
`vv???????????>|??|>?>?>+>+>>>?vv}???vvvv?????vvvvvvvvvvvvvvv^-`^+++++++++.<br></br>
`zzwrwwwwwwww>^}r?>?^>>-~+>+>>>????>?vvv>>???vvvvvvvvvvv?vvvv+.~++>+++++++.<br></br>
-zxxxuuuuuuu}^wux>w?>?>.~>++>>>>>>+|^>?+~-~^>vvvvvvvvvv??vvv}~`>>+++++++++.<br></br>
`rwrrrwwwwzr~?wzv+z?+?+.-?>|?+^>>^|`--+^>v?>^~^+>??vv???????+`+>>+++++++++.<br></br>
`v?vvvvvvvv^^vvv+>?^||-.`+>^^+^>>>~`-|+^^^+>???>^^+++~~|^^++^??+>+++++++++.<br></br>
.??v}vr?v?>~v}??|~---`..`^>>>>>>>>-~-^^+>>?v???vvvv?>^+?????vvv+++++++++++.<br></br>
.?}ww?>>?}?|vr?>``-~~~`..^>>>>>>>~~~|^>vv??????vvvv?^>vvvvvvvvv>+>++++++++.<br></br>
`v}w}?+++>?+>>~|-~~|~~`..~>>+++>-`~~+>>>>>???vvvvv?>^?vvvvvvvvv?+>++++++++.<br></br>
.+vw>?^~~--~`-~~~-~|~~-..`+>>>>~.~-^>>++?vvvvvvvv??^?vvvvvvvvvv?+>++++++++.<br></br>
.^^^~~+|`-~|`-||~-~|~|-...^>>>~.``^>>>>>?vvvvv???>+>vvvvvvvvvvvv>>++++++++.<br></br>
.``~~~--~|^^|--~|~`-~|~`..~>+-.`|+++>>>>>????>>>>>>?vvvvvv?vvvvv>>++++++++.<br></br>
.`~|~|~|^^+^|~---~--`-~`..`-..-^++++++++++++^^>>???vvvv????vvvvv>+++++++++.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????>++++.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vvvvv?>>>>.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv???v}rwrvvvvvvv?>>>>.<br></br>
`wwwwwwwwwwwwwwwww}}}rwwwwwwwwwwwwwwwwwwwwwwwwww}?>>>??>>^^>??vvvvvv?>>>>>.<br></br>
`wwwwwwwwwwwwwwr?||+^>?}wwwwwwwwwwwwwwwwwwwwwww?+?}wwwwwww}>~^>?vvvv>++>>>.<br></br>
`wwwwwwwwwwwwrv+^vwwrv>>vrwwwwwwwwwwwwwwwwwwwr|+wwwwwwwwr}}v?^^+?vv?++++>+.<br></br>
`wwwwwwwwwww}v>?wwwwwww?^vwwwwwwwwwwwwwwwwwww|~wwwwwwwrr}vvvvv+|>v>+++++>+.<br></br>
`wwwwwwwwww}?>?wwwwwwwwr|?wwwwwwwwwwwwwwwwww}.?zwwwrrr}vvvvvvvv~+?>>>>>>>>.<br></br>
`wwwwwr}}}v??vwwwwwwwwww^^wwwwwwwwwwwwwwwwww}^+rwrr}}vvvvvvvvvv?->????????`<br></br>
`wwwwwwrrvvrwwwwwwwwwwww>^wwwwwwwwwwwwwwwrr}}}?^?r}vvvvvvvvvvvv?>++>>>>>>?`<br></br>
`}rrwwwwwrwwwwwwwwwwwwww>+wwwwwwwwwwwrrr}vvvvv}?|^?vvvvvvvvvv?????????????`<br></br>
`vvv}rrwwwwwwwwwwwwwwwww~?wwwwrrrr}}}}vvvvvvvvvvv?~|>vvvvvv?v?????????????-<br></br>
`vvvvvvv}}rrwwwwwwwwwwwv`vr}}}}}vvvv?vvvvvvvv}vvv?+^~~+?vvvvvvvvvvvvv}vvww~<br></br>
`vvvvvvvvvvvv}}}rrrrrww+-?????????vvvvvvvvv}v?vvv?>^>>^`-+?vv??????vvvv}rw~<br></br>
`vvvvvvvvvvvvvvvvvvv}}?->?++++?vvvvvvvvvvv}?~`vvvv?>+>??+--^???????vvvv}rr-<br></br>
`vvvvvvvvvvvvvvvvvvvv?+~?>+++>???vvvvv?vvvr> .vvvvv?>>>>??--^?v????vv?vv}}-<br></br>
`vvvvvvvvvvvvvvvvvvv?+|^?>>>>>?vv?vvvv?vvrz} ^vvvvvvv?>^>?>`||rr?>?v>>????-<br></br>
`}rrr}vvvvvvvvvvvvvv^>-??^>+?>v}vvv?????-vxu>vvvvvv}}?>??^+--~??|||^^^???v~<br></br>
`vvvvvvv????vvvvvvv++^^?>^^>?-`vxv+?????`~xwr?vvvvvr}??vv?^~`-~|~`~~||+???`<br></br>
.??????????????????~>~??>^+^>???zz-|v??vv?vv>>vvvvvrv?vvvv?^`-^+++++++++++.<br></br>
`v??vvvv??????????|+^^???>^^>>>?vw}v??vvv??>>>vvvv??vvvvvvv?~`^>++++>>++++.<br></br>
`}rrrwwzzrvvvvvvv>^?~??>+^+++>>>?vv?>?vvvv????vvvvvvvvvvvvvv|.^>++++++++++.<br></br>
`vwrrwzzzzwzzzzzr^v?^r?^|.^++>>>>>>++>??>~|>>vvvvvvvvv?vvvvv^.^>++++++++++.<br></br>
`vr}v}wwwr}wwrrwv^r|?rv+|.|+>>+^>>+~~.|^^??^|+?vvvvvv??vvvvv~`++++++++++++.<br></br>
.?vvv?vv?v?vvvvv+?}-??+|-`~>++^^>>+^~.|^|^>>>+^^^>??>>?????|-^++++++++++++.<br></br>
.>?w}vvvvv?vr}?>>vv`^|---`->>++>>>>+-`|^>>?v???v??>|`|+^^^+?v?++++++++++++.<br></br>
.??vrv>?vv?+>>?v???.`-~~~``>>>++>>>|`-|?v?????vvvv?^+vvvvvvvv?++++++++++++.<br></br>
.?v??+>^>>~+|~~+^~~.`~|~~-`^>>>+++|.-+>++?vvvvvvv?+^?vvvvvvvvv++++++++++++.<br></br>
.|?v?++>^.`~--``--~-.~~~~~.~>>>>>^..^>>>>?vvvvvv?+^+?vvvvvvvvv++++++++++++.<br></br>
.^-.`^+-..`~~~|~~-~~``~~~~`->>>>|.`++>+++>????>>+^+?vvv?vvvvvv>+++++++++++.<br></br>
.-``....`-~^^^|||~--~``~~~``+>|``|++>>>>++++^^++>+>?vv??vvvvvv>++++++++>++.<br></br>
.|^|-```~^++++^^||~---``--`.```|+>++++++++++^^+++>?vvv???vvvvv?+++++++++++.<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>+^^++.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vvv??>>>>.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}}}}rwwwwr}vvvv?>>>>>.<br></br>
`wwwwwwwwwwwwwwwwwwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwr?>>??>>>>>vrrvvvvv??>>>>>.<br></br>
`wwwwwwwrwwwwwwwr>|^^?rwwwwwwwwwwwwwwwwwwwwwwww?+?rwwwwrvvv?^?vvvvv?+++>>>.<br></br>
`wwwwwwwrrwwwww?|>vrv>+vwwwwwwwwwwwwwwwwwwwwww?~rwwwwwwww}??v^^vvv?>++++>+.<br></br>
`wwwwwwww}rwww?+vwwwwwv+vwwwwwwwwwwwwwwwwwwwwv^?wwwwwwwwrrv^>v+^vv>+++++>+.<br></br>
`wwwwwwwww}rr++vwwwwwww?|rwwwwwwwwwwwwwwwwwww>?vwwwwwwrr}vvv+>?|??>>>>>>>>.<br></br>
`wwwwwr}}}}v+?rwwwwwwwwr-vwwwwwwwwwwwwwwwwwwwv>vrwwwrr}vvvvvv?+^-+>>>>>>>>`<br></br>
`wwwwwwrr}}rwwwwwwwwwwww^?wwwwwwwwwwwwwwwwwwrwv>?}wr}vvvvvvvvvv>+||^>???>?`<br></br>
`}rwwwwwwwwwwwwwwwwwwwww>?wwwwwwwwwwwwwwwrr}}rr}?|>vvvvvvvvvv?????????????`<br></br>
`vv}rrwwwwwwwwwwwwwwwwww^?wwwwwwwwwwwrr}}}vvvvvvv?^|>vvvvvvvv?????????????-<br></br>
`vvvvv}}rrwwwwwwwwwwwwww-vwwwwwrrr}vvvvvvvvvvvvvvv??~~?vvvv?vvvvvvvv}vv}rr~<br></br>
`vvvvvvvvv}}rrrwwwwwwwwv-vvvvvvvvvvvvvvvvvvvv}vvv?>??>|-+?????vvvvvrw}vv}r-<br></br>
`vvvvvvvvvvvvvv}}}rrrrw+^v?>>???vvvvvvvvvvv}?>}vv?+^???+`-+???vvv?v}}r}vvv-<br></br>
`vv?vvvvvvvvvvvvvvvvv}?-+v?>+++>?vvvvvvvvvv?`.vvv??+^>>?>^-^??vvv??v}}}?vv-<br></br>
`vvv?vvvvvvvvvvvvvvvvv|-?v?>++>???vvvvv?v}r? `vvvv??>>+>?+^`+??vv>?v?vv??v-<br></br>
`vvvvvvv?vvvvvvvvvvvv?`+??>>?>?vvvv?vvv??vwx-?vvvvv}v>>?>^~.`+?>|^>?vvv???`<br></br>
`vvvvvvvvvvvvvvvvvvvv|^>??+|?~`>rr??????.-xx}vvvvvvr?>vv?+~..^~--~>????vr>`<br></br>
`??????????????????v?->>??+^+>>^}x+`v??v?>}r?>vvvvvr??vvv>^``^+^^^^+>>+?>|.<br></br>
`????vvv???vvv??????|^^>??>+++>?v}r???vvv?>?>>vvv??vvvvvvv>~`^+++++>++++++.<br></br>
`?vv}vvrw}vvvvvv???>`>^??^>++>>>>?vv?>vvvv????vvvv?vvvvvvv?+`|>+++++++++++.<br></br>
`?vvvvv}wr}wwwr}vv?^~?^?>-|`^>>>>>>?^>???^^?+vvvvvvvvvvvvvv>.|>+++++++++++.<br></br>
`?v}}}v}r}vv}}v}v?v-^v+v>-|`^?>^^>>^~-`^|+>|+?vvvvvv???vvvv?.^>+++++++++++.<br></br>
`?}}v+???v?vv?v?vrv`?v>?~-``|>+^^>>+^`-^|^+>|~^>??vv??vvvv?~-+>+++++++++++.<br></br>
.^^?????v}vvv}v>?vv`??|~-~-.~>>>>>>>+`-|>>??????>++~~++^^||>>+++++++++++++.<br></br>
.~-+???????|^+v??>>.^~.-~~-.->>>++>>~`->??????vvvv>^>v???vvv?+++++++++++++.<br></br>
.~^|~+??}?--~|-~^--.`-`~~~~`-+>>+++-.|>++?vvvvvvv>^>vvvvvvvv?>+>++++++++++.<br></br>
 `~>>++>>|^^~`.`-~~-.~``~~~``^>>>>~.-+>>+>vvvv??>++?vvvvvvvvv>+>++++++++++.<br></br>
.~--~+>~...``-~|^|~~``~``~|``~>>+`.~+>++++>?>>>+++>vvv??vvvvv?++++++++++++.<br></br>
.^^|||~~```~^+++^|||~``~``~-``~~`~+>>+>>++++^^+>>??vvv??vvvvv?++++++++++++.<br></br>
.+++^^|~~|^++++++^^^^|---```..`|+>>+++++++++++>>??vvv?>?vvvvv?++++++++++++.<br></br>


            </div>,

            <div>

                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???+^||++.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvv>>+>>>.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrwwwwwwwww}vvvv>>>>>>.<br></br>
`wwwwwwwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr?>?????vwwwwr}vvvv?>+>>>>.<br></br>
`wwwwwwwvwwwwwwwwv>>>vwwwwwwwwwwwwwwwwwwwwwwwwwv^?rwwwrv^|vww}vvvvv>+++>>>.<br></br>
`wwwwwwwv}wwwwww?~?v?|?wwwwwwwwwwwwwwwwwwwwwww}~}wwwwwwwwv-?vvvvvv?+++++>+.<br></br>
`wwwwwwww?}wwww?|rwwww^>wwwwwwwwwwwwwwwwwwwwwwv~wwwwwwwwwwv`^vvvvv>+++++>+.<br></br>
`wwwwwwwwr?v}}?+rwwwwww~?wwwwwwwwwwwwwwwwwwwwwv~wwwwwwww}vvv~~?vv?>>++>>>>.<br></br>
`wwwwwwwwww?>>vwwwwwwwwv-wwwwwwwwwwwwwwwwwwwwww>vwwwwwrvvvvvv>-~^^++^>>>>>`<br></br>
`wwwwwwwwwwwwwwwwwwwwwww|vwwwwwwwwwwwwwwwwwwwwwr>vwwrvvvvvvvvv?+^|^^>???>?`<br></br>
`}rwwwwwwwwwwwwwwwwwwwww>?wwwwwwwwwwwwwwwwwwrwwwr>>vvvvvvvvvv?????????????`<br></br>
`vv}rrwwwwwwwwwwwwwwwwww>?wwwwwwwwwwwwwww}vvvvr}vv>|?vvvvvvvv?????????????`<br></br>
`vvvvv}rwwwwwwwwwwwwwwww^vwwwwwwwwwwwwrv??vvv??vvvvv|^?vv?v??v?vvv}rv???vv-<br></br>
.?vvvvvvv}}rwwwwwwwwwwww~vwwwwwwwr}vv????vvvvv?vv??vv?~+v?????v}}rwzx}????`<br></br>
`??vvvvvvvvvvv}}rrwwwwwr-r}??????>>??vvvvvvvv}v??^|^?}v~~?????}}}}}}rrv???`<br></br>
`vv?vvvvvvvvvvvvvvvv}}}?-}v++++>?vvvvvv?vvvv>+}v??|^+>??~~????}r}}}}}}v???`<br></br>
`vvv????vvvvvvvvvvvvvvv||vv?+++>>?vvvvv?vvv? .vvv?>^>^+??-~v>^>?}}vv?vv??w-<br></br>
`?????????vvvvvvvvvvvvv`?v?>^>>>vv??vvv>v}rz.>vvvvv>?^+>>?`+|~??>v?>v}}??|.<br></br>
`?vvvvvvvvvvvvvvvv?v?v?`vv>|^>>~?}vv????>-zxvvvvvvv}+?v?++~ -?>v??vv}rv}r. <br></br>
`??????????????????v?v^~vv++^^>|^ww|????>~vrv>vvvv}}>vvvv^^.|+|+++>???>}>`.<br></br>
`???????vv}rww}???????`^??>++^>>?vw>>vv???>>+>vvv>?vvvvvvv^~->++>>>+++++++.<br></br>
.??>>>>?wwwrwwwv>>>>>>`>>>>+^++>>?vvv??vvv????vvvv?vvvvvvv?|-+++++++++++>+.<br></br>
.??>>>+vr}rrrrr}>>>>>>`???+`|>++>>>?>^?v?>+?^vvvvvvvv?vvvvv|`+>+++++++++++.<br></br>
.???vv?v}v??vvvv>>>>>>-???+-|>++|+>>^~-^^^>>vvvvvvvv??vvvvv^`>++++++++++++.<br></br>
`vv??+^>}rv???v?v}?+vv~?>^~~|>+^^+>>+^`^|^++|^?vvv}?>vv}vv?-->++++++++++++.<br></br>
.~`...+^>rwv?}}?`|?vw?~>|-~~->>++>>>>+`|+?????>>++^~+?>>+^|+++++++++++++++.<br></br>
 .....>?++vvvvv^...`?|.--~~|-+>>+++>>-`^??????vvv?~|??>???vv++++++++++++++.<br></br>
 .....-vr?>>?v+...``--.~~~~|-^>>>+++-`+>+>vvvvvv?>^?vvv}vvvv?+++++++++++++.<br></br>
 ```....~?rr?-.`-~~~~~..~~~~~|>>>>+`.^+>+>vvv??>^^+vvv?vvvvv?+>+++++++++++.<br></br>
.^+^^|~`..`-..~^+||||~~..~~~|-+>>^``^++++++>^^++++?vvv?vvvvvv+>+++++++++++.<br></br>
.+>>>>>+|-``-^+++^^||||~``---.-|-~^>+++>++++^+>>>?vvv?>vvvvvv+>++++++>++++.<br></br>
.++++++^|||^+>+++++++^||~-`--``|+>>+++^++++>>>>?vvvvv>?vvvvv?^++++++++++++.<br></br>


            </div>,

            <div>

.??????????????????+>??????????>|?vvvvv????vvvvvvvvvvvvvvvvvvvvvv???>>>>>>.<br></br>
`vvvvvvvvvvvvvvvvvv>?vvvvvvvv}wz}?}zzwwwzwwrrrrrrrrrrrrrrrrrrrrr}vvv??????`<br></br>
`vvvvvvvvvvvvvvvvvv>>vvvvvvvrzzwrv+??>>>?vwzwrr}rrr}}}rrr}}}}}}}vvvv??????`<br></br>
`vvvvvvvvvvvvvvvvvv>>v}rwwzzw}v?>>>>???>>>?}wwwr}??vv??vvrrrr}}vvvvv??????`<br></br>
`vvvvvvv?vvvvvvvv?+-~}zzzz}?>>>>>>>>>>?v?>>?rww}>?}rrr}v??vr}}vvvvv???????`<br></br>
`vvvvvvv??vvvvv}>~??~?rwv>+>>????????>>?vv?>>?}+}rv}rrrrrv>?vvvvvvv???????`<br></br>
`vvvvvvvv?v}vvv?~vzv~v+~>>>>>???????????>?v??>++v???}rrrrrv+?vvvvv????????`<br></br>
`vvvvvvvvv>?vv>|vzzv|+>.|?>>????????????>>>?vv>`>>>>?}r}}vvv>>vvv?????????`<br></br>
`vvvvvvvvvv?+>?vwzzr>+>^`>>????????????????>>?v^^>>>??vvvvvvv?>???????????`<br></br>
`vvvvvvvvvv}}}vvwzzr?+>>`^>??????>>+++++>>>>>+^+|??>+|~~>vvvvvv??>>??v????`<br></br>
.vvvvvvvvvvvvvvvwww}v^>>-|?????>^|^+++++++++^++^|`^?+~-``~>?v?vvvv??v?vv??`<br></br>
.??vvvvvvvvvvvvvwww}v^>>-^????+^||+++++++^|~-|^~-`..~+>|```|>?vvvvvvvvvvvv`<br></br>
.??????vvvvvvvvv}wwrv^+>-^??>+|||^++++^|``-~~------`..`-``-+|^>?vv}}vvvvvv-<br></br>
.>???????vvvvvvvvwwrv++?`+??^||||^|~-````---~-``-``~~`..```|?>~|?v}rrvvv?v-<br></br>
.??????????????vvrw}}++>`>>-```.```.`-~--~~-----^||^>>+-.~|~~?v?^^?vvvvvvv-<br></br>
.?????????????????}vv|~|`+^.....`~~~~~~`----``^>????????^|>+^vv}}?|^vvv?vv-<br></br>
.?????>???????????v}?|`.`^|`.....`-~~~~`---` .??vv???????++?>?v?v}v+-+v??r-<br></br>
.??????????????????v?^`.~^|`....````-~-``-|?.+vvvvv???????~?>^vv?v??v+~^>^.<br></br>
.?????????????????>??+..|^~`....```--~---.?}??vvvvvv??v?>^~-^?>?vvvvv}v>+`.<br></br>
.?????????????????>??>.`|^~``....^>-^>??+~?v??vvvvvv??vvv++-^++>++?????v>` <br></br>
.???????????vv???+??>+.-||~-``..|?v+>?????>???vvv??vvvvvv?+^|+^^^|+>+>+>>+.<br></br>
.?????>>????????|`^??> -~~|~```|>>?vv??vvv????vvvv?vvvvvvv?^~++^^^^>>>>>>+.<br></br>
.>????>????v???v```~>^ ~||-.`-|>>?????????????vvvvvvv?vv???|`|^+>?>+>+++>+.<br></br>
.>????>???>>???>-+~````^^^-.`~+>>?????+>>????vvvvvv??????vv?+???v?>+>+++>+.<br></br>
.??>+^^>???>?>v^+?>|--~>|-...|>>>?????+>?>??>>??????>vvwzzzv?????>+++>+>>+.<br></br>
.~-```+^>?v?>?v|`|+>?+|+|`.-^+>+>?????+>??????>+^>??}wrr}vv}??>>>++>+>>>>+.<br></br>
 `-```+>++?????-```~>~``..`+?>+>>>>??>>>???????}rrv}wwrr}}}}?++++++++++>>+.<br></br>
 `-```->?>>>>?+.`````......`|?>>>>>+^+??>>??vwzzwr}wzw}}vvvv?+>>>>+++^+>>+.<br></br>
 ```````~>??>~`.```..........^>>>>+||+>>+?vwzzwr}vv}}vvvvvvv?|^|||||^^++>+.<br></br>
 `````````-~``-````.`........`>>+^~~+++>?v}}v}vv???vvvvvvvv??---``-|++++>+.<br></br>
.------```````~~`-````....`..`||~|+++>v}}}}vvv???vvvvv?v?????----|+>++++++.<br></br>
.------`.`.`---|`--````.....`.`|^^++?vvvvvv?????vvvvv>>??????---^+++++++^^.<br></br>


            </div>,

            <div>

.^||||||^|~|||||^^^.`||||||||-|~ >rrr}v?|-^???????????????????????????????`<br></br>
.>>>>>>>+^^^^+>>>>>.`+^+++^+^?xyv~?uxzwwuxrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
.>>>>>>>^~^|^>>>>>+.`+^^+>+>}yyuw> ^~.  -vuyxrvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>^|||+>>>>++`.+?}wxuyzv^`    -|`   `vzxz}vvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>+^|+++>>+++`.zyyyxv~.     .. .^+.  ~rwzwrvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>+^>>+>+^^?>.xyz>.   ........  `+|. .~}rrr+vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^|vy? ?>.   ............. `^~. `v?` -vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>|>uy?        .............  -+|.   . `>?vvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^zyuv`      ................  -+~.    .??vvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+uyuv^     ........             `|^`.  ..+??vvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>>uzzv>    .......                 .^+~.   .+vvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+xuzv+   ......                      `^+`   `+?vvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^vxzv>   .....                         ..  .~``+?vvvvvvvvvv-<br></br>
.+>>>>>>>>>>>>>+?zzv>  .....                               .?>``>?vvvvvvvv-<br></br>
.+>>>>>>>>>>>>>>^}wv?. ....                     .-||~~-`..  .?v+.`>?vvvvvv-<br></br>
.+>>>>>>>>>>>>>>+>r}v` ....                   .~>?????????+-`}v}v^.-?vvvvv-<br></br>
.>>>>>>>>>>>>>>>>^}zv- ....                 .~>??????????????r}vv}v|.~??vv-<br></br>
.>>>>>>>>>>>>>>>>>>rv| ....                `+>?????????????????vvv}}?~.|?v-<br></br>
.>>>>>>>>>>>>>>>>>+>?^ .....          ....`^>????????????????????vvvvv?|`^`<br></br>
.>>>>>>>>>>>>>>+>>>^>^ ......    .`|^^^^++^>??????????????????????vvvvv}v|.<br></br>
.>>>>>>>>>>>>>>>?~++>^ .......  `+>>??????>???????????????????????vvvvvvv}-<br></br>
.>>>>>>>>>>>>>+?~ `^>+ ........-+>?????????????????????????????????vvvvvvv-<br></br>
.>>>>>>>>>>>>>>?   .^| ... ...-+>???????????????????????>>>>>>??}wrvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^.~. ...`..   `+>????????????????????>>++>>?vwxuyyyrvvvvvvv-<br></br>
.>>>>>>>>>>>>+>-->+~. -+-.   ->>????????????????>>++>?vwxuyyyyyyurvvvvvvvv-<br></br>
.>>>>>>>>>>>>+>`|>>>+|+++-`+?++>>?????????????>++>vwxyyyyyyuuzw}vvvvvvvvvv-<br></br>
.>>++>>>>>>>>+>`^>>>>>>>++>zu?+>+>>?????????>+>vzuyyyyyyuzrvvvvvvvvvvvvvvv-<br></br>
.>>++>++>>>>>>+.+>>>>>>>>>++vr+>+++>>????>+>vzyyyyyyyxw}vvvvvvvvvvvvvv?vvv-<br></br>
.+>++>^+>>>>>>+`^>>>>>>>>>>>+v>++++>+>>++?}uyyyyyyxw}vvvvvvvv????????vvvvv-<br></br>
.>+^+>++>>>>>>+-^>>>>>>>>>>>>+++>>+++++?wuyyyyyuw}vvvvvvvv?>>++++^+?vvvvvv-<br></br>
.>>++++^>>>>>>^|->>>>>>>>>>>>>>>+>>++?wyyyyyuz}vvvvvvvv?>^^^^+++>?vvvvvvvv-<br></br>
.>>++>+++>>>>>++`>>>>>>>>>>>>>++++++}uuuuuxrvvvvvvvvv?|-~|^++++>?vvvvvvvvv-<br></br>


            </div>,

            <div>

.^^^^^^^^^~|||||^^^.`^|||||~~-|~ >r}}v??|~^???????????????????????????????`<br></br>
.>>>>>>>+^^^^+>>>>>.`>^^^^|^|?zuv~?xxzwwuxrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>^~^^^++>>>+.`>|||||^vuuzw>.^~. .-?uyz}vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>+|^^>>>>>++`.+?v}wzxw?|.  . -|`   `vwzzvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>>>>>>>+|>>>>>+++`.wuuuz?~.  ......`^|.  ~}wzwrvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>+>>>>+^^?> zyw>.  ...........`^|. .~}rr}+vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^|?u? ?>. ......``````... `^|. `v?`.-vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>|>uu?   ......````````....  -+|.  ...`>?vvvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>^zuxv` ..........````....... .-+~......??vvvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>>>>>>>+xxxv^ ........``...    ...  .  `|^-....`>v?vvvvvvvvvvvvvvv-<br></br>
.>>?????>>>>>>>>z}rv>  .........       ....  .   .|^~`.  `+?vvvvvvvvvvvvvv-<br></br>
.>>??????????>>>wwrv+  .......        .....   ...  .-^+`   `+?vvvvvvvvvvvv-<br></br>
.>>??????????>>+vr}v>  ......         ......  ....    ..  .~``+?vvvvvvvvvv-<br></br>
.>???????????>>+>rwv>  .....        .........  .           .?>``>??vvvvvvv-<br></br>
.>????????????>>^vrv?. ....        ..........   `|^||-``..  .?v+.`>??vvvvv-<br></br>
.>>??????????>>>++rv?` ....     ............  .~?????????>+-`v?vv^.->??vvv-<br></br>
.>>>>>>>>>>>>>>>>~vw?- ....      .........  .|>?????????????>}vvvvv|.->??v`<br></br>
.>>>???????????>+^+rv| .....      .......  `+???????????????????vvvv?`.|??`<br></br>
.>>????????>?>>>+^^>?^ .....        ....`.`^>????????????????????vvv?v?|`|`<br></br>
.>>?????>>???+^|^^+^>^ ...`..    .~^+>>>>+^>??????????????????????v?vvvv?|.<br></br>
.>>?????>???>+|^?-+++^ ...``..  `>????????>??????????????????????>??vvvvvv`<br></br>
.+>>>>>>>?>+^^|>~ `^>+ ....``..->>????????????????????????????????+vvvvvvv`<br></br>
.^+>>>>>>?>||^|+   .^| .......~>>???????????????????????>++^^+>?v}v?vvvvvv-<br></br>
.++^>>>>>>+||^+|.~. ...-.....->>???????????????????>++>>+>?vrzxuux}?vvvvvv-<br></br>
.>>^|^^^^|||||>-`++~. ~+-..  ~>>?????????????????>++>>vwxuuuuuuxzv?vvvvvvv-<br></br>
.>>|~~||||||||>`-^+>>^+>+-`^?^++>?????????????>++>vrxuuuyuuxzw}v??vvvvvvvv-<br></br>
.++||~~|||||||+`~|^>>>>>>+>zu?+>>>??????????>>>vwuyyuuxxxw}vvvv???vvvv?vvv-<br></br>
.^^~|^~~||||||^`~|^++>>>>>>+v}+>>>>??????>+>vwuyuuuuuxrv?vvvvvv??vvvv??vvv-<br></br>
.||~~^|~|^||||^`-|^^+>>>>>>>+?>+>>>>>?>>+?}xyyuuuuzrvvvv?vvv??>++>>>??vvvv-<br></br>
.|~~~|~~|^^||^^-`|||+>>>>>>>>+++>>>>>++?ruyuuuuxrvvvvvvvv?>+^||~-~^>vvvvvv-<br></br>
.^|~~~~~||^^|^||.|||^++>>>>>>>>>>>>>+?wuyuuuuw}vvvvvvvv?>^~-~||~~>vvvvvvvv`<br></br>
.^|~~||~||||^+|^.|^|^^++>>>>>+>>>+++}xuxxxxrv?vvvvvvv?^~~|^|||~|????????vv`<br></br>


            </div>,

            <div>

.?????????????>>>+^`.?>.````.-??????`^>????????????++^^^^^^^^^^^^^^^^^^^^^.<br></br>
`v}}}}rrrr}vvv??v?>`|v-.-~--``>?vvvv>~?vvvvvvvvvvv??>>++++++++++>>>>>>>>>>.<br></br>
`vvvv}}}}}}v?v????+-+?`.......`~>vv??^~??vvvvvv????>+>>>>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvv}vvv????^|>+`.  ..`..``>vv??~>v??vvv?????>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvv?????-|>^`. ..``~>?^~?vv??-?vv??????>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvv??>+`^>^..`~|||+>?????vv?+~vvvv???>>+>>>>>>>>>>>>>>>>>>>>>>?`<br></br>
`vvvvvvvvvvvvvv?^+`^+~~~^|||~|^>vrv???>v+|vvvv?vvvvvvvvvvvvvv}}}}}}}}rrrrr~<br></br>
.vvvvvvvvvvvvvv?+^`+||+>>?++>?????vvv^|vv~+vvv??????????????????vvvvvvvvvv-<br></br>
.???vvvvvvvvvv?>?^-+~+>>^^>??>????vvv?^?v?-?vv????????????????????????????`<br></br>
.?????v?v?vvvv?>?^`||+>>>+^>???vv}v??????v>~v}}v}rrwwwwwwwwwwwwwrrrrwwzzww~<br></br>
.????????????????|.~+>>>>>>?vvvv???>?>?v?+v~>}}}vrwwwwwwwwwww}rwwwwwwrwzzz~<br></br>
`??????vvvvvvv???~.|++++>>>++>>>>>>??>?vv?>>-?vvvvv}}rrrr}vvvv}rrrrr}rr}rw~<br></br>
`?v??vvvv????????-`+++++>>>++>?>>???>>vvvvv?||vvvv>|+>?v????v}}}}}}}}v}}vv-<br></br>
.????????????????``+++++>>>>+>??>>>+>?vvv?vv>`?v???+|+>??vv?v}r}}}}v}}v?>+`<br></br>
`vvvvvvvvvv}}}v??`|>+>++>>>>>>>>+^>?vvvvv??vv+`vv???^^>>>>?+>?vvvvvv}v>+>|.<br></br>
.???????????????>.^>>>>++>>?????????vvvvv??vv}|^v????^++++|^++^+++>?>|+~^^.<br></br>
.???????????????>.^>>>>>+++>?vvv???vvvvvv??vvvv`>v???>+++>+++++++++^~`..   <br></br>
.???????????????+.|?>>>>?+++>?vvvvvvvvvvv?>vvv}>.?v?>>+++++++++++++~`.^+^` <br></br>
.????????????v?+^.`|+>>>??>+>?vvvvvvvvvvv?>?vvvv~-?+~||^++++++++++^..^>>>+.<br></br>
.?????????v}wv|^^..`^>>>>v?>+>?vvvvvvvvvv>+?vvvv?`^?|``~++++++++++-.|>++++.<br></br>
`vv??>>??v}w}|||~...~>>+>?vv>++?vvvvvvvvv++?vvvvv>`>+~-~^+++++++>+.->>++++.<br></br>
.+???????}}>|~||`..``+>>+>?vv?++>?vvvvvv?++>?vvvvv|^>+>?+++++++++~ ^>+++++.<br></br>
 .`++>?>+v>~||~~. ...|>>+>??vvv>++?vvvvv?++>??vvvvv~+++?>++++++++.-^>+++++.<br></br>
.^`-?>~~^+-~||~~. ``.-+>+>>??vvv?>+>?vvv+>?vvvvvvvv?`+^>>+++++++~.^^++++++.<br></br>
`v?`....```~~~||. ``.`~^+>>>???vv??>>????vvvvvvvvvvv>`++>+++++++`->^+>++++.<br></br>
`?~-..`--``~~|||..``.``^???vvvvvvvvvvvvvvvvvvvvvvvvvv|.+^++++++^.|+^^>++++.<br></br>
`|`~~..`~~`-~~||..`.``~+???vvvvvvvvvvvvvvvvvvvvv?vvvvv`.+~^>+++~.^+^-+>+++.<br></br>
.-~~|-.`~~--`~||..~``.`~+??vvvvvvvvvvvvv?vvvvvvvvv?vvv?.`+`+>++`.+++-|>+++.<br></br>
.^^|||~``~~~~--~..|-.``.`^??vv?vvvvvvvvv??vvvvvvvvvvvvv|.`~~+>+..+++|-+>++.<br></br>
.^^^^^^|``~~|-`-..|~-``.``|>???vvvvvvvvvv>vvvvvvvvvvvvv?^..~~+^.`+++^-^>++.<br></br>
.^^^^^^^|`-||~-` .||^-``.`-^+>?vvvvvvvvvv?>vvvvvvv??vvv?>^..``` -++>|-+>++.<br></br>
.^^^^^^^^|`|^|~- .^+++~`.``-|^>??vvvvvvvv?^?vvvvvv?vv??>^+^.    |+++-~++++.<br></br>


            </div>,

            <div>

.?vvvvvvvvvvvvvvvv+|.>>`>+>>^^???>??????????????????++^|^^^^^^^^^^^^^^^^^^.<br></br>
`v}wwwwwwwwwwwwwwr?++??>?vv???vvvv?>?vvvvvvvvvvvvvv?>>++++++++++>>>>>>>>>>.<br></br>
`vvv}rrwwwwwwwwwrv?>>?>+^|^^+?vvvv?+^+?vvvvvvvvvvvv>+>>>>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvv}}rrrrrr}v}v>>>+`.^+??>+>?v??|^>vvvvvvvvvv?>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvvvvvv?>>>|.~vv>^|??+^?v>^>vvvvvvvvv?>>>>+++>>>>>>>>>>>>>>>>>>.<br></br>
`vvvvvvvvvvvvvvvv>++>^.~>~    `>v?~^v??vvvvvvvv?>>>>>>>>>>>??????????vvvvv-<br></br>
`vvvvvvvvvvvvvvv>>+^+.~++.     .-vv+->?vvvvvvvvvvv}}}}}}rrrrrwwwwwwzzzzzzz~<br></br>
`vvvvvvvvvvvvvvv?>>+-->+|.    .`.~v}?-~vvv??????vvvvvvvvvvvvvvvv}}}}}}}}vv-<br></br>
`?vvvvvvvvvvvvvvv??+.+>>^. .~~>?>>vvvv~~vvv???????????????????????????????`<br></br>
.???vvvvvvvvvvvvvv?-|>>>+~-+??vvrwvvvv>~~vvvvwwwwwwwwwwwwzwzwrr}rrwzzzzzww~<br></br>
.?????vvvvvvvvvvvv+`+++>>+>v}}}vvvvvv?^v^`?vvvzwzzzzzzzzzzz}}}wzzr}rzzzzzz~<br></br>
.??????vvvv??vvvvv^~++^+>>+>>???vvvvv>+v?>`?vvvwrrrrrrrrrw?v}}rrr}}}vrrrrr-<br></br>
`?v??vvv???????vvv`^+++++>>>+?vvvvvv?^?v?v?->v????vvvvvvvv?vr}}}}}}}v?vv??`<br></br>
.?vvvvvvvvvvvvvvv?-+>++++++++>vv?>>^+>vvvv}v~+v?^+>vvvvvvv?v}rr}}vvvv?^... <br></br>
`v}}}}}}}}}}}}}}}+^>>>++>>+++^^^^^^>>?vvv?vvv|+v?^^+?vv}rv>???vvv??vv>...  <br></br>
.???????????????v~+>>>>++>>>>>+>+^>>?vvvv?vvvv^^v?|+++^>??^^>?????v}?`~`.. <br></br>
.????????????????||>>>>>++>>>>??>++?vvvvv?vvvv}^^v>^>>+^^++++++++>>>.|>``. <br></br>
.????????????????+->>>>>>+++>>?vvv?vvvvvv>?vvvvv~+v++++++++++>+>++^.|+++^^.<br></br>
.????????????vvvv?`-^>>>>>++>>?vvvvvvvvvv>?vvvvv?`|+^>++++++++++++~`>+++++.<br></br>
`v?????????vrrwzz}.`|>>>>>?>+>>vvvvvvvvvv>>vvvvvv>.`|+++++++++++++-|>+++++.<br></br>
-rxv>>>>?>vrrrrrwv.`~>+++>>?>++?vvvvvvvvv+>vvvvvvv?-`^++++++++++++~|>+++++.<br></br>
.>?vv??vv>vr}}rrv~`.`^++^>>?v?>+>?vvvvvv?++?vvvvvvvv~->+++++++++++~|>+++++.<br></br>
.>?>~|+|-^v}v?>>-~~.`~++^+>>?vv?++?vvvvv?++?vvvvvvvv?.|>++++++++>+|->+++++.<br></br>
.vvz+.  ..^}v+-~-~|`.-|+|+>>>?vv?>+>?vvv>?vvvvvvvvvvv^.^>+++++++++^`++++++.<br></br>
`??v~.`...`|~~|~~~|~ ``||^^++>??vv?>>?vv?vvvvvvvvvvvv>~`^>+++++++++`^>++++.<br></br>
`?+?^......-~||~~~||`.-`+++>?vvvvvvvvvvvvvvvvv?vv??vv?^^.^>++++++++-`>++++.<br></br>
`?^?>..`~~`.~|||~~||~..`|^^?vvv?vvvvvvvvvvvvvv?vvvvvvv++~.^>++++++>|.^>+++.<br></br>
.>^|+--~||~`.-||~~|||` -`|+?vvvvvvvvvvvv?vvvvvv?v?????>^>~.^>+++++>+.`>+++.<br></br>
.++||^^|||||`.`~~~||||..^~++>?vv??vvvvvv?vvvvvvv?vvvvvv^+>-.^>>++++>` ^+++.<br></br>
.+++++++^||||` `-~|||^^ -+~++>?????vvvvvv>vvvvvv?vv??v?+>>>-.|+^+++>~ |>++.<br></br>
.++++++++++^||` .-|^++>- ^^~^^|>?v?vvvvvv?>vvvvvv??????+>+++` `^+++>` ^>++.<br></br>
                  .+++++++++++++^- .^+++++..+|-||^?v??vvvvvv|?vvvvv??????++++++- -+++^ `++++.<br></br>


            </div>,

            <div>

.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????>^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvv?+>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv??++>>>>>>>>>>>>>>>>>>>>>.<br></br>
.vwwwwwwwwwwwwwwwwwwwwwwwr}vvv}rrwwwwr}}vvvvvvvvvv>++>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?wwwwwwwwwwwwwwwwwwwww}??v???+|?v}}}vvvvvvvvvvvv?>++>>>>>>>>>>>>>>>>>+>>>.<br></br>
`vrwwwwwwwwwwwwwwwwwww?~>??>|v??v?vvvvvvvvvvvvvvv?}}}}}}}}}}}}}}vvvvvvvvvv-<br></br>
-zv}wwwwwwwwwwwwwwwww}^~?++???>?vv?>>vvvvvvvvvvvvwxxxxxxxxxxxxxzzwrwwwwwwz~<br></br>
`rvvv}rwwwwwwwwwwwwwrv>>??^>>++?vvv>|+?vvvvvv?v?vrrrrrrrrrrrrrrr}vwrwv}}}}-<br></br>
`vv?vvv}rrwwwwwwrrr}vv>>>^`-|>++?v??^|>vvvvvvv???????????????????wxzrz????`<br></br>
`}r}vvvvvvv}}}}vvvvvv?^^~~~>?>^+?>++?>>?vvvvv?>???????>?>????>>>>zxuzrr>>>`<br></br>
`}r}}??vvvvvvvvvvvvvv-`~~+^^.  .|vv??^-~+?v??>??>>>>>>>>vrwzwv?v}?>vwr????`<br></br>
`}}}}}v?vvvvvvvvvvv?^-~^|++` ..|~?vv}v|^|>??????????????rrr}}}v>-...`>???v`<br></br>
`rrrrrrr}vvvvvvvvv?~??>^>>>|~>vv}vvvv?>vv>^?wwrrrrrrrrv?}rr}}vv?`.....^-.-.<br></br>
`}}}}}}}}}vvvv??v?~?vv?+++>+>vv????vv^?vv}v++vvvvvvvvv?^??v}??v?-.... .|+>.<br></br>
`v}}}}}}}}}}vvv?v?~vvv?+^^>>++>>??vv?|?vvvvv?-~??????vv~^>>???}v>...  `???-<br></br>
.?????????????????-????>+^++++?vvvv?^+?v?vvvvv~.^?vvvr~.`+??^|???~. ..|??>`<br></br>
`vvvvvvvvvvvvvvvvv^?vvv>>+++^^^>>+^^+>vv?vvvvvv|.-+?v+```|>+^++>z^  .`^+++.<br></br>
`zxxxwvwxxxxwrrrrzr+uuu?+>++>++^^^^+>?vv?vvvvvvv+-`^+++++++++^^>?-..~^++++.<br></br>
`zzxzrrrxxzrrwrrwrz??xx>+>>++>>>??>?vvvv??vvvv>>>^^`|+>++++>>>>+++.`++++++.<br></br>
`}}vvvw}?>|?w}rrrrv}|??`^>>>++>>?vvvvvvv?>vvvvv>^|>+``+>++++++++>>`.++++++.<br></br>
`vvv}?}|...^}v?v??~~>--~~>>>>++>>vvvvvvvv+vvvvvv>~+>>-`+>++++++++>^.|>++++.<br></br>
.vvv?+?`...-?r}?}+..``.``>>+>>++>?vvvvvvv+?vvvvv?|+++>~-+>++++++++>~.+>+++.<br></br>
.???+^^....`+?vv?``-~|`..^>^+>>?++?vvvvv?+?vvvvvv|^>++>~-+++++++++++`->+++.<br></br>
`rwr}v>`....`^>~`-~|||~-.`+^+>>???+>?vvv??vvv?vvv>^>++++~-+++++++++>+`~>++.<br></br>
`vvvvvv?`......-~|||||~~~.`|+>+>>?>>>?vvvvvv?vvv?+^>+++++~~>++++++++>+`|>+.<br></br>
`vvvvv?>>+....|+||||||~~||`.^>++?vvvvvvvvvvv?????+|>++++++-~>++++++++>+`^+.<br></br>
`}}}}v>vvv?|~+^|^^^|||~-|^+~.^>>vvvvvvvvvvvvv>?vv?|>+++++++`~>++++++++>|-+.<br></br>
`??>>~^>++>^+>>+~^>++^^|^++>-.+>v???vvvv?vvvv?vvv?^++++++>++`~>>+++++++>-^.<br></br>
.++++|++||^>>++>+^|+>+++^++>>`.^?????vvv>vvvvvvvv?^++++++++++`~>>++++++>|~.<br></br>
.++++^++^+>+>++++>^~^>++++++++.`+?????vv>>vvvv?vv>+++++++^++>+`->>+++++>|`.<br></br>
.+++++++++++++++++>^`~++++++++|.~~?vv?vv?^?vvv?vv^+++++++++++>+``+>++++>|`.<br></br>
.+>++++++++++++++++>+-`++++++++`-+~^???v?^|?vv?v>^+++++^++++++++~`|+++++``.<br></br>


            </div>,

            <div>

.vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????>|^^^^^^^^^^^^^^^^^^^^^^.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvv?+>>>>>>>>>>>>>>>>>>>>>>.<br></br>
`rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvvvvv??++>>>>>>>>>>>>>>>>>>>>>.<br></br>
.vwwwwwwwwwwwwwwwwwwwwwwwr}vvv}rrwwwwr}}vvvvvvvvvv>++>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?wwwwwwwwwwwwwwwwwwwww}??v???+|?v}}}vvvvvvvvvvvv?>++>>>+++++++>>>>>>>>>>>.<br></br>
`vrwwwwwwwwwwwwwwwwwww?~>??>|v??v?vvvvvvvvvvvvvvv?vvvvvvvvvvvvvv??????????`<br></br>
-zv}wwwwwwwwwwwwwwwww}^~?++???>?vv?>>vvvvvvvvvvvvwxxzzzzwwwwwrrrr}}}vvvvv?`<br></br>
`r?vv}rwwwwwwwwwwwwwrv>>??^>>++?vvv>|+?vvvvvv?v?vrr}}}}}}}}}}vvvvvzrwvvvvv-<br></br>
`v??vvv}rrwwwwwwrrr}vv>>>^`-|>++?v??^|>vvvvvvv???????????????????wxz}z????`<br></br>
`vv??vvvvvv}}}}vvvvvv?^^~~~>?>^+?>++?>>?vvvvv?>>>>>>>>>>>????>>>>zxuzrr>>>`<br></br>
.???????vvvvvvvvvvvvv-`~~+^^.  .|vv??^-~+?v??>>>>>>???>>vrwzwv?v}?>vwr????`<br></br>
.???????vvvvvvvvvvv?^-~^|++` ..|~?vv}v|^|>??????????????rrr}}}v>-...`>???v`<br></br>
`rrrrrrr}vvvvvvvvv?~??>^>>>|~>vv}vvvv?>?v>^?wwwwwwwwwwv?}rr}}vv?`.....^-.-.<br></br>
`}}}}}}}}}vvvv??v?~?vv?+++>+>vv????vv^?vv}v++vvvvvvvvv?^??v}??v?-.... .|+>.<br></br>
`v}}}}}}}}}vvvv?v?~vvv?+^^>>++>>??vv?|?v?vvv?-~>?????vv~^>>???}v>...  `???-<br></br>
.???????vvvvvv????-????>+^++++?vvvv?^+?v?vvvvv~.^?vvvr~.`+??^|???~. ..|??>`<br></br>
`vvvvvvvvvvvvvvvvv^?vvv>>+++^^^>>+^^+>vv?vvvvvv^.-+?v+```|>+^++>z^  .`^+++.<br></br>
`xuuuz}zuxuxwrrrwzr+xxxv+>++>++^^||+>?vv?vvvvvvv+-`^+++++++++^^>?-..~^++++.<br></br>
`xxxzrrrxxzrrwwrw}w>?zw++>>++>>>??>?vvvv??vvvv>>>^|`|+>++++>>>>+++.`++++++.<br></br>
`}}vvvw}?>|?w}rrrr?v|??`^>>>++>>?vvvvvvv?>vvvvv>^|>+``+>++++++++>>`.++++++.<br></br>
`vvv}?}|...^}v?v??~~>--~~>>>>++>>vvvvvvvv+vvvvvv>~+>>-`+>++++++++>^.|>++++.<br></br>
.vvv?+?`...-?r}?}+..``.``>>+>>++>?vvvvvvv+?vvvvv?|+++>~-++++++++++>~.+>+++.<br></br>
.???+^^....`+?vv>``-~|-..^>^+>>?++?vvvvv?+?vvvvvv^^>++>~-+++++++++++`->+++.<br></br>
`rwr}v>`....`^>~`-~|||~-.`+^+>>???+>?vvv??vvv?vvv>^>+++>~->>+++++++>+`~>++.<br></br>
`vvvvvv?`......-~|||||~~~.`|+>+>>?>>??vvvvvv?vvv?+^>+++++~~>++++++++>+`|>+.<br></br>
.??????>+^....|+|~||||~~||`.^>++?vvvvvvvvvvv?????+|>++++++-~>++++++++>+`^+.<br></br>
`v????+??v>|~+^|^^^|||~-|^+~.^>>vvvvvvvvvvvvv>?vv?|>+++++++`~>++++++++>|-+.<br></br>
`??>>~^>++>^+>>+~^>++^^|^++>-.+>????vvvv?vvvv?vvv?^++++++>++`~>>+++++++>-^.<br></br>
.++++|++||^>>++>+^|+>+++^++>>`.^?????vvv>vvvvv?vv?^++++++++++`~>>++++++>|~.<br></br>
.++++^++^+>+>++++>^~^>++++++++.`+?????vv>>vvvv?vv>+++++++^++>+`->>+++++>|`.<br></br>
.+++++++++++++++++>^`~++++++++|.~~?vv?vv?^?vvv?vv^++++>++++>+>+``+>++++>|`.<br></br>
.+>++++++++++++++++>+-`++++++++`-+~^???v?^|?vv?v>^+++++^++++++++~`|+++++``.<br></br>


            </div>,

            <div>

                  .|||||||||||||||||||||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>+>>>>>^|~~||||||~~|+>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>>>>>+++^~--~^+>>>>>>++^`+>>>>>.<br></br>
`}r}}}}}}}}}}}}}}}}}}}}}}vvvvvvvvv??????????????>^~~^>>>>>>>>>+++>+|+>>>>>.<br></br>
`xuuuuxxxxxxxxxxxxxxxxxxzzwrzzzzzzzzzrvvvvvvvv?>|+??????>>>>>>++++++++++++.<br></br>
                  `rwwwrrrrrrrrrrrrrrrrrrrvvrwwwwwwwwwxuxv?vvvv?|+?v????????????????????????`<br></br>
.?????????????>>>>??????vwwwwwwzzxwwwwzxv????|>???????????????????????????`<br></br>
.>>??>>>>>+^^^^^^^|-|>>vwwwwwzxzzwwwwwwzz>++-+>>>>>>>>>>>>>>>>>>>>>>?>>>>?`<br></br>
.>>>>>>>+^+>>>>>>>>>`|?rwwwwwwwwwwwwwwwwzv^+~~~||+>>>>>>??}r??????????????`<br></br>
.??????>????????????>`?rwwwwwwwwwwwwwwwww?++++^^|~~++++++>?zr??}rrwr??????`<br></br>
`rrwwrvrrrwwwrrrww}r?^}}wwwwwwwwwwwwwww}?^++^^^+++`|>++>>++>??+v}vv}?vvvvv-<br></br>
`vvvv?vvvvvvv}rr}vru>vvvwwwwwwwwwwwvvv??>^++|++++>-^>+++++>+^`-???vv>v???v-<br></br>
.???????????vrrrr?v?}wv??v}rwwww}????>>+v>+|+>>+^~^>++++++++>|`>?vvv??v?v}~<br></br>
.?vvvvvvvvv?v}}}}>..??>?^^>vvvv}>~+>^>????^^+^|~~^>+++++++++>+`>v?>|``?vwx~<br></br>
`vvvvvvvvvv?>}v??+..`++^+>++^^^>?~-~+|>?^>|--|^++>++++++++++>>```....`?rrv`<br></br>
`wzzzrvr}v+.-?vv>||..~>^>+++^|~~^+``||?>+++^-||^+>+++>+++++++^`......|>?vv.<br></br>
`}}vvvvzw}+..^>|~>`..^++>++++^^^~~||^^^+?^^^||^+^^^^^^+++++^^-......`^^>?v`<br></br>
`v?????vrvr?`.......`^+>++++>>+||^^+^~+>?|^||~~~~~-~~~~|^++|^~`-.....|}v?v`<br></br>
`??????v}?>?^......`^>+++>>+^||++^||^^^-+^+~~~?vv+^???^~-|+^>?>>+~--`~v}vv~<br></br>
`}rrrr}?v?v+~....``~>>>>>+|||+>++^|||~|^~^^|~>}vv^?vv?^||-|||>vv?vvv?>??v?`<br></br>
`xuuuurvv?>`....~??^>++^~~|+>++|~^>?>|~^|-|~~?vvv>vv??>^--^+>^>?v?rzwzwyv}-<br></br>
`wwwwwvr??>|~-~???>~|~|^|||++^~-+?v}vv?^--~~~?vv>??+>>>+^>>>+>>?>>??v}wx??`<br></br>
`vvvvv?}??+>vv??+>^--^^|~~|+|-^>|^>?vvvv>--~-|}vvv+~~+?+^>>>>+>>>>>>>>>|^^.<br></br>
.??>??>v}vv>?vvv?v?>--~~~~~|~~?>?vv????vv?^|^?vvvv>+++-|>>???>^^^>>>>>>>^+.<br></br>
`??vv??vvxuxzw}?}v?+>^|~---|~~+|?vvv?>??vvvvvvvvvv>++--~+?vv?--~^+>>>>>>++`<br></br>
`vvvvv??>?vvv??+>>>?>>>>+|--~`-^>>++~+?vvv>>>vv??>+^+???vvvvv?>??^+>>>>>>>`<br></br>
.+>>>>>>^^+>>>>>>+||+>>>>>>+^|^+^--~+vvv?>++>?+>?+^+?v?vvvvvvv???^^?>>>>>>`<br></br>
.++>>>+++>>>>>>>^~-`~>>>>>>++?>|+>>?vvvv?vvv|-^+^||^^~~|>??????>>+|^>>>>>>`<br></br>
.+++++^+>>>>>?>^+>>^~~|^+>?vvvvvvvvvvv>?vv?>+^`..   `---`~^+++>?>>^`~^^|^+`<br></br>
.++++^>>>>>>>>^+>>>>>+|-++?vvvvvv>+>++^-|~~-^>`     -~`+?>>++++++>^``````-.<br></br>


            </div>,

            <div>

                  .|||||||||||||||||||||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>+>>>>>^|~~||||||~~|+>>>>>>.<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>>>>>>>>^~--~^+>>>>>++^^`+>>>>>.<br></br>
`}r}}}}}vvvvvvvvvvv????????????????>>+>>>>>>>+>>>^-~|+++>>>>>>>++>+|+>>>>>.<br></br>
`zzxxzzzzzwwwwrrrr}}}vvvvvvvwzzzzzzzwv>>>>>>>>>|-~^>++++++++++++++++++++++.<br></br>
`rrrr}}}}}}}}}}}vvvvvvvvvvrwwwwwwwwwxyx?>????>~^>????>>>>>>>>>>>>>>>>>>>>>.<br></br>
.?????????????>>+>>?????}wwwwwwzxxwwwwzxv????|>?????????????????>>>>>>>>>>`<br></br>
.>>>>>>>>>+^^^^^^^|-|?>vwwwwwzxzwwwwwwwzz>++-+>>>>>>>>>>>>>>>>>>>>>???????`<br></br>
.>>>>>>>+^+>>>>>>>>>`|?rwwwwwwwwwwwwwwwwzv^+~~~||+>>>>>>??vr??????????????`<br></br>
.??????>????????????>-?rwwwwwwwwwwwwwwwww?++++^^|~~++++++>?zr??}rrwr??????`<br></br>
`rrwwrvrrrwwwrrrww}r?^}}wwwwwwwwwwwwwww}?^++^^^+++`|>++>>++>??+v}vv}?vvvvv-<br></br>
`vvvv?vvvvvvv}rr}vru>vvvwwwwwwwwwwwvvv??>^++|++++>-^>+++++>+^`-???vv>v???v-<br></br>
.???????????vrrrr?v?}wv??v}rwwww}????>>+v>+|+>>+^~^>++++++++>|`>?vvv??v?v}~<br></br>
.?vvvvvvvvv?v}}}}>..??>?^^>vvvv}>~+>^>????^^+^|~~^>+++++++++>+`>v?>|.`?vwx~<br></br>
`vvvvvvvvvv?>}v??+..`++^+>++^^^>?~-~+|>?^>|--|^++>++++++++++>>```....`?rrv`<br></br>
`v}}}vvr}v+.-?vv>||..~>^>+++^|~~^+``||?>+++^-||^+>+++>+++++++^`......|>?vv.<br></br>
`???v?vzwr+..^>|~>`..^++>++++^^^~~||^^|+?^^^||^+^^^^^^++++^^^-......`^^>?v`<br></br>
.??????vrvr?........`^+>++++>>+||^^+^~+>?|^||~~~~~-~~~~|^++|^~`-.....|}v?v`<br></br>
.??????v}?>?^......`^>+++>>+^||++^||^^^-+^+~~~?vv+^???^~-|+^>?>>+~--`~v}vv~<br></br>
`vvvvvv?v?v+~....``~>>>>>+|||+>++^|||~|^~^^|~>}vv^?vv?^|~-|||>vv?vvv?>??v?`<br></br>
`xxzzz}vv?>`....~??^>++^~~|+>++|~^>?>|~^|-|~~?vvv>vv??>^--^+>^>?v?rzwzwyv}-<br></br>
`wwwwrvr??>|~-~???>~|~|^||^>+^~-+?v}vv?^---~~?vv>??+>>>+^>>>++>?>>??v}wx??`<br></br>
`vvvvv?}??+>vv??+>^--^^|~~|+|-^>|^>?vvvv>--~-|}vvv+~~+?+^>>>>+>>>>>>>>>|^^.<br></br>
.?>>>>>vvvv>?vvv?v?>--~~~~~|~~?>?vv????vv?^|^?vvvv>+++-|>>???>|^^>>>>>>>^+.<br></br>
`??????vvxuxzw}?}v?+>+|~---|~~+|?vvv?>??vvvvvvvvvv>++--~+?vv?--~^+>>>>>>++`<br></br>
`v??????>?vvv??+>>>?>>>>+|--~`-^>>++~+?vvv>>>vv??>+^+???vvvvv?>??^+>>>>>>>`<br></br>
.+++++++^^+>>>>>>+||+>>>>>>+^|^+^--~+vvv?>++>?>>?>^>?v?vvvvvvv???^^?>>>>>>`<br></br>
.++++++^+>>>>>>>^~-`~>>>>>>++?>|+>>?vvvv?vvv|-^+^||^^~~|>??????>>+|^>>>>>>`<br></br>
.++++^^+>>>>>?>^+>+^~~|^+>?vvvvvvvvvvv>?vv?>+^`..   `---`~^+++>?>>^`~^^|^+.<br></br>
.++++^>>>>>>>>^+>>>>>+|`++?vvvvvv>+>++^-|~~-^>`     -~`+?>>++++++>^``````-.<br></br>


            </div>,

            <div>

                  .|||||||||||||||||||||||||||||||||||||||||||||||^^^^^^^^^^|~``````.`-^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++++++++++>>>>>>|-~|++>>>>>^~-++>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^+^^^++++++++>>>^--^>>>>>>>>>>>~-+>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++^^+>>>>>+>>>>~`|>>>>>>>>>>>>>>`^>>.<br></br>
.+>>>>>>+++>>>>>>>>+++++>>>>>++++++++++++++>>>>>>>>+--^++>>>>>>>>>>>>>-|>>.<br></br>
.>??????>>>>>>>>+++++++++++++++++++++++++++++++++++--++++++>>>>>>>>+++-+>>.<br></br>
.??v?????????>>>>>+++++++++++++>>????>+^^^^^^^^^^+--^^^+++++>++++++++^-+++.<br></br>
`vvvvvvv??????????????????????}wwzzzxuzv+^^^+>>>>^`++++^+>>>>++++++>>`^>>>.<br></br>
.??????????????????????????vwzwwwwwzzzzzz?^++++++`|+++++++>>>???>>>>|->>>>`<br></br>
.>>>>>>>>>>>>>>>>>>>>??>>>vwwwwwxxzzwwwwzx?++++++`++++++>^>?}rrzv>>>->>?>?`<br></br>
.>>>>>>>>>>>>>>>???>rzr???rwwwwxzwwwwwwwwzw+++++^`|^++>>++>v}r}vv???^??}??`<br></br>
.?????????????vvv??}uxzv?>wwwwwwwwwwwwwwwwv^++|~-..~~~|+^~|>vv??}?>??v?r??`<br></br>
`rwwwrrrrrr}vrwz}?^+>v}v++wwwwwwwwwwwwwwwv>-|~|>>~^?>^~-^~.~v??}v?~.->>}vv-<br></br>
`vvvvvvvvvvv}}?????`.->-^^vrwwwwwwwwwwwrvv+-`|vv>+???>~`~~.-?+>>>^`...-???`<br></br>
.???????????>?v?v?v~..~|`+>vv}rrwwwrr}}vv?-`-+>+?vv??|~`-~..~+>+-.....^>??`<br></br>
.?vvvv}vvvv>`?vrr??|..`^`^++>vvvvvvvvvv?>-`^>>?vvvvv?>~````..`-......`>>??`<br></br>
`?vvvvzwr}?..^^>?>?~..`+`^>+|+>>????>+^`-^????>+>vv?>+++`.`.....``...`>?>?`<br></br>
`?????vwww>-...``-~...~+`+++++++^|~--`.-?vvv?>?v?>?>+>??+............`????`<br></br>
`?vvvv?v}v^?`........~>|~>++++++>+|~~~~vvvv?vvvvv+~+>?vvv?>^`-++~``~|>vvvv-<br></br>
`??????rv>->+`......`++`+>++>++++^^^??>}vv?>+???>+^>vv??vvvv??v?v??vv????}~<br></br>
.??????}?^~^^`......->~~>+++^>?>???vv|+++~|~`~~~`^??v?????????v?v}vv????v}-<br></br>
.?????r}>+~`.....|>^^^`^>++^+???????``|+?>|-~~~-`~>????????>>>?+????>^v}v?`<br></br>
.????vwv?>^`--->?v?>+``+++^>^?????>..|^?}}v>~~----^??????>>>>>+?>>>>>?>???`<br></br>
`?vv?vrv?>>+>vv}?vv+>-`|^|+^+???>|..~~^+>??v?>^+>?vv???>>>>??+~|>>>>>>+>??`<br></br>
.????vr?+>?>>??v>>>>+~.--~-`^>^-`.`^^+v??+?vvvvvvvvvvvvvv?vvv?>|~>>>>>>>++.<br></br>
.>>>>vrrwv+^+^+>>>>>>>~`-~```-`..`|^~>?>^+?v????>>?vv???+?vvv???+|>>>>>>>+`<br></br>
.>>>>?vuxxr?>>^+>>+^>>>+|~~~|^~`-`.....->^-`-|+???v?+~^^~~>????>>++>>>>>>>.<br></br>
.?>>>>>rrxwuv}v>|>|`~>>>>>>>+>>>>|      ..     ..|~|^>>>~->>++>>>>^+>>>>>>`<br></br>
.++>>>++>>>?+??^|>|``|>>>>>>>^>>>+.             .^-~~~~~--^>>>>>>>+~^>>>>>`<br></br>
.++>>>>>>>^>>>++>>+|~`|^++>>^^>>>>~             .+|-``````|>>>>>>>>^`|++>>`<br></br>
.++>>>>>>>^+>>>>>>>^^-`--~~~-^>>>>+.     -`     .++|---|>?>+>>>>>>>>`----~.<br></br>
.++>>>>>>>^~>>>>>>>+^~-------|++++^`     ..     .^^+^^^+?r^`+>++++++-````-.<br></br>


            </div>,

            <div>

                  .||||||||||||||||||||||||||||||||||||||~~||||~``|^^^^^^^|..^^^^^^^^^^^^^`..<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^++++>>>+^~~^^+>>>>`.^>>>>>>>>>>>>>+. <br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>|+>+++^|||-`--~~^>>~.+>>>>>>>>>>>>>>>- <br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^|+>+>+^~|>?????????+.^>>>>>>>>>>>>>>>>| <br></br>
.+>++++++>>>>>>>>>>>>+++>>>>>++^^^~|>++++|~>vvvv?>?v?+^.+>>>>>>>++++>>>>>| <br></br>
.++++++++++++++++++++++++++++^++>>++>++||~~?vv?+>?v+>^.^++++>+++++??++++>- <br></br>
.++++++++++++++++++++++++++++>>+>++++++~`|?}v>>vvvv?|.`++>>++++++>}w+++++`.<br></br>
.?????????????>>>>>>>>???>+^>++++>????>~>v}}vv??vvvv^.+?v}wwv>++++wr?++>^|`<br></br>
.??????????????>>>>>>wuzr>^>++^>vwzzwwvvvvvv??>-^+++`^>}rrr}}?>???rv??vv|?~<br></br>
.>>>>>>>>>>>>>?vv?>>vuxxv++>++vwwwwrvvvvv>^|~-```-^~~>>vvvv?vv^^|~->?>?>|v`<br></br>
.????????????}rzzzv?vvrr>+>>+}wwwr}vvvv>^>v?|^|~-?|-????v??vr?+....`?>>`+?.<br></br>
`???????????vwrr}v?v~.-++>>^?zwv?vvvvv?+}wzw|||||+^v??|-?v???+?.....>>+-??`<br></br>
`vvvvvvvvvvv?r}v???w+.~++++^?v????????+vwwwv^~|~`^zz>`..^?>?>v~.....~?^^vv-<br></br>
`vv??}vv???}>>vrrv??>.|>+++||???vv??+?}www}v^~-.|vv}-....~+?v^.......>->??`<br></br>
`????rzwv?r>.~+>vv>>~.|+>>+||>?v????>}rr}vv?-`.|???>+......``........+|vvv-<br></br>
`????vwzr}?...`-|+>>`.-|+++|--+>>>v+>vvvvv?~..|????>>|..............|+^v??`<br></br>
`?vvv??}w}?|......`.   ..``-~|~^~+?^>v??>^```-^??????++`.....`-``-+??++v??`<br></br>
`?????v}rv>>|.....  .`^^^^^~``||~~~~~~~~~`.-``+vv?}v????++||^??v??rv?^+???`<br></br>
`vvvvvvwv>+++-..  ...~>>>>>>+|`|+++|~~~^|```~>?}v???????????vv?v}?v??+|???`<br></br>
`???v?vr>+>+~`.......-^++++++>+-|>^|~~~^~~?+?vvvvv?????????>?vv?}??>>?|>??`<br></br>
.????vrv>>+`... ..^++>-|^++>>>>+`-||||||`|v?vv???vv?vv??v?>>?>?>?>>>>>?+>?`<br></br>
.??>?wv??>+`-`..|>>>??^-||^^^^^||.`~|||~-++^v?vvvv?vvvvvvvvvv??>???>?>>???`<br></br>
.??>vw???>+>??~?v??>>??+|~~~|~~||~..~~-~>?>|??vv?????v?+>>?vv??|+??>>>>>??`<br></br>
.???r}?+>>?>>?~>?+>>>>>??+|~~--```~```->?>^>?vv??vv?+>+^++^>?v?--|+>>>>>??-<br></br>
.>+?vr}>++++>>~->>>>>>>+^+>++^|   ``.....`^^~>+++>+^|+>>?>|~~>>>+--^>>>>>>`<br></br>
.>>+vxxx>+++>>+.>>+-^>>>>++++++`      `.    .+++>>~+>>>>^--++^>??+-|>>>>>?`<br></br>
.>>+xwxx}>+>???-~?+`~>>>>>>+>>>+      ~`     +>>>+-~~|++|~>>>>????>|^>>>>>`<br></br>
.?>>?vwwu}??>>>>.^?^-|>>>>>+>>>>`     ..     `+>>>~~----~>?????????>-^>>>>`<br></br>
.++>+++vv?v?^|>>+`^>^`-|^+++>>>>|             .`~^+^~..`+>>>>>>>>>>>+~^>>>`<br></br>
.++>>>>++>>+|~>?>>-~>>-`-|^+>>>>+`               .->^ .|>>>>>>>>>>>>>^`-|^`<br></br>
.++>>>>>>>>>|`-^>>?|-+>|^+++>>>>>-     .|~`        .`-^>>>>>>>>>>>>>>>----.<br></br>
.++>>>>>>>>>+```~>?>>|~+>+^>+++++`     ~++^|.        .^>+++++++++++>>+~`--.<br></br>


            </div>,

            <div>

                  .||||||||||||||||^|||||||||||||||||||||||~||||||^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++^^^^^^+++++>>>>>++>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^^^^^^++++++>>>>>+>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++^^^^^+>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>.<br></br>
.+>++++++>>>>>>>>>>>>++++>??????+++++|+++++>>>>>>>>>>>>++>>+>>>>++++>>>>>>.<br></br>
.++++++++++++++++++++++>vwzzzzzzwv++++++>??>>>>>>>>>>>++++++>>++++??++++>>.<br></br>
.+++++++++++++++++++++?rwrrrwwwwzuw>+^|+?>???>+>>>>>>++++>>++++++>rw++++++.<br></br>
.>>>>>>>>>>>>>>>+++++>wwrrwzxxzzzxy}+++^^+>??>^++++>>++?v}ww?++++>zr?++>>?`<br></br>
.>>>>>>>>>>>>>>>>>>>+vwwwwwwwwwwwwzz||+|>>>>??|+++||+>>}rrr}}?>???rv??vv?v-<br></br>
.>>>????????>>?v?+^+^?wwwwwwwwwwwwzz+++++^~^+??^++^~~|~???>+>>|^|~->?>???v`<br></br>
.????????????}r?>+>>>+vwwwrwwwwwwr}>|>>+++||^+?>|~--```---^??+^.....^>>>>>.<br></br>
`?v????????>v}++>>>>+++vv??vv}}}v?^^+>>+++^~`.`|>??vvv?????vv>?.... .~+^>?`<br></br>
.|>>???>???>>|-^++^^+++~-|v?^????||+^~-~||^++^|~~|?vvv??vv???v^...  `^>?vv-<br></br>
.+?v?}}v???r?~^>>>^|+++|~>?>^+??>^-~~|+++>>>++++|`|+>?^vvvv????~.....????v-<br></br>
`????wzrv?r>`~~++>>>+++^->>-~~+???^++^++^^+++++^~`|^^-^?vv?+??>>.....?vvv}-<br></br>
`????vwzr}?..-``~^++++++|~-`|^>???^++|~^^^|^^^~-``--|~.`^+~||--~....|?????-<br></br>
.|+>>?>vwv>+^|``-~~|^||+^|~-+>???>^++^^+++~---``---|^^|`.....`-``-+>??????-<br></br>
.++^^|+^^~~+^+~`^~--~~~^++^^^+?>?^^++^+++|--`~^?v?}v????+^||^??v??rv??????`<br></br>
`vvvvv?>>>?^`````.`--`|?>^^^+^|^+|+|~~^^^---``|vv???????????vv?v}?v???????`<br></br>
`?????^+?vv?+|||~`  .`?vv??>>+~``-~-`-~~-``~?~.>}v?????????+?vv?}??>>?>???`<br></br>
.????>^+??v>^|~...-~+>+||+>??>|`---``.```-^^^` ^v}w}???vrv>>>??>?>>>>>?>??`<br></br>
.???vr>?v>>^|~-`|>>>????>+|~^>?>+>>>|  `>~..    >rzwx}vv?>?????????>?>>???`<br></br>
.???vw?>>>++>??v???>>???????>^+>??>>-   .       `?????>+^>??+^^^+??>>>>>??`<br></br>
.???r}?+>??>>>???+>>>>????????^|>??^            -???>>>>>>+|~--~-|+>>>>>??-<br></br>
.>+?vr}>++++>>++>>>>>>>+++>>>>+^|^^            `>>^^+>>>>+|-~+??+`-^>>>>>+`<br></br>
.>++}xxz>+++>>>>>>+-^>>>>+++++++^|.           ~??>-+>>>>|-~?v?vvv>-|>>>>>?`<br></br>
.>>>xzxx}>+>???>>>+`~>>>>>>+>>>>+`          .>????-|~|++|~?vvvv}}}?~^>>>>>`<br></br>
.?>>?vwzu}??>>>>>>>^-|>>>>>+>>>>|          `+>???>~-----~??????????>~^>>>>`<br></br>
.++>+++vv?v?^|>>>+>>^`-|^+>+>>>+.         .^^^^++++^~..`+>>>>>>>>>>>+~^>>>`<br></br>
.++>>>>++>>+|~>?>>++>+-`-|^+>>>`           `^^^+++>>| .|>>>>>>>>>>>>>^`-|^`<br></br>
.++>>>>>>>>>|`-+>>>>+>>|^+++>>~            .`~^+++++^~^>>>>>>>>>>>>>>>----.<br></br>
.++>>>>>>>>>+``-~>>>>>>>>+^>>+.               .~^^^^^++>+++++++++++>>+~`--.<br></br>


            </div>,

            <div>

                  .||||||||||||||||^||||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>.<br></br>
.+>+++++>>>>>>>>>>>>>>>>++>>>>++>>>>>>>>>>>>>>>>++>>>>>>+>>>??>>>>>>>>>>>>.<br></br>
.+++++^^^++^+++^^^^^^|||?}wzzwr}>~||||||||^^^^^+++++++>+++??v?v?++++++++>+.<br></br>
.++++++++++++++++++++>?rzwwzzzzxur+^+^^^^^^^^^||||^||^|^+?}?vv}}>>????v??v-<br></br>
.+>++++>>+>>v?+||~-`.>wwwwzzzwr}rzz+^||^^^^^^^|~~~?>~|-.`>?^|+rv>>>???r???`<br></br>
.>>>>>>>>?}>~|v>+>+^>vwwwwrv?>+++>?^^^^|||+>>>>>>+vw?+?>>>vvvvv>?>??>???>?`<br></br>
.>>>?>>>+?}>>?rv+~+??vrwr?++++>++++>+^+>+|+????????v?>|`.|>>?>+~--~~+>?}vv-<br></br>
.?????????r}vv?|?-|..|r}+^+++++++++>>^~^++^?>?????>?-.....`--`......>^??}?`<br></br>
.>?vrrv?vv^^|>??>???>^+^+++++++++++++^~-++^??+?vv?+?`...............>^?>rr~<br></br>
.??zuzwr?...`|>++>>?vv?^^++>>>>+^|^+^|-``~~?v?v???++`...............+>+vzz~<br></br>
.???rw}vv| .`.`|^>>?vvvv?++++++^^>?vv?>+|~~+>?vv??v?|..............`?v?vrw-<br></br>
`???wr>>?>`....~^^|^??vvvvvvv?vvvvvvvv??>???+^>v}}}}v`....-........^}v????`<br></br>
.?v}wr}v?>?`.....`.^???>???vv>vvvv?vvvvv?vvv?^>v?????>~~|>?>||||~``???????`<br></br>
.vvv?v?+^^+`...`^>>????+>>^~|~+>>?+vvv??^>>+^??????????vvv?}vvv???????????-<br></br>
`r}}}?^++^. .`~>vvv????????>|~|~---+>??>~|-``|>?????????vvv}}}v???????????`<br></br>
`}???>???v?>^>vvv???++?????>+^~``~~---~~--`-|?v??????>>?vv?}vv?>??????????`<br></br>
`?>>??v??vv?>?vvv^>+>++>??>>>^~-`-|~~~~~-~^?v~^????>>>>>>>>>>>>+??????????`<br></br>
.}}vvv?v}}?|^>??>>>+^>>>>>+>?++>^~^|-`-~|+>+- .>>>>>>>>>>>>>>>>>>?????????`<br></br>
-xzuzu}rr?+-->>>>>>^-+>>>>>>>>??????^.~-       ~>>>>>>>??>>>>>>>+???????vv-<br></br>
`wzuwyv??>+-->>>>>>>-~>>>>>>>>>?????+ -`        ^?>>+|`~+>>>>>>>>>???????v-<br></br>
.??????????-~^>>>>>>+-~>>>>>>>>?????`          .>?>+-`-|~~^>>>>>>>>???????`<br></br>
.>>>>++>>>>~-~^>>>>>>^`~^+>+>>>>>?>`          .+?>^-~+???~-^>>>>>>>???????`<br></br>
.>>>>++++++^`-~+>>>>>>|```--+>>+v>.          `>?+-`+}rrrw}|-+>>>>>>>wzwwwz~<br></br>
.>>>>>>>>>>>---~>>>>>>>-```^>>>?+          .|?>|-`>wzzzzxxr~|>>>>>>>?wuuuu|<br></br>
.?>>>>>>>>>>|`--|>>>>>>~``-|~~>~          ~>>>^-`+vvvvvvvvv?-^>>>>>>>~?}vv-<br></br>
.++>>>>>>>>+``--|>>>>>>>?+~~|~.         .`~~~~--`^>>>>>>>>>>|-^>>>>>>~`|++.<br></br>
.^+>>>>>>>>+``--^>>+||>wv++>+.          .----``-|>>>>>>>>>>>+--~^>>>>|-`++.<br></br>
.++>>>>>>>+~`---~~~--`^?^++>~             ..`+??+>>>>>>>>>>>>|---~^^+~-`+>.<br></br>
.^+>>>>>>>^-``-------.  ^++>+.               ->?+++++++++++>>^-`--------++.<br></br>


            </div>,

            <div>

                  .||||||||||||||||^||||||||||||||||||||||||||||||^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>+|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.+>>>>>>>>>>>>>>>>++++++>>>>>>>>>>>>>>++^||~~~~~~|~^|||^+>>>>??>??>>>>>>>>.<br></br>
.+>++++>>?>>>>^|^^^+^++++++++++>>>>>>>>>>+++++++++?}}++^++++?+-`^v>>>>>>>>.<br></br>
.++++++??^??^|~^+^^+++++>?vvvv?>++++++++++++++++++??w?+++>>>?>>>??>>++++>>.<br></br>
.++++++v|`|>|^++++}}>+?}wzwwwwwwv?>>>>>>+++>?>++?>>>}?>>>>?>v}vv>^>??vvvvv-<br></br>
.>?>++++?|^?>>||^vw?|?wwwwwrrrrrwz?++|~|^^^^+^||^+^?+|~~``|``^>>>+>???vvvv-<br></br>
`rw}}?+||???>^^|^?v>|?v?vv????>>>?+---|^^++++++^+^>??>???^^++-``^>>>+>??vw~<br></br>
.rzr}v>|-~~`..```.-^++++++++^^||^^^++>>+++++++++++|^>^+>|^???>`.`|^>+>?vzz-<br></br>
.?}}>^>?-``.``````^+^|||^+++++++>>>++++++++^~-~^+^|^^~~|??+>??^..~>vv?>?}}~<br></br>
`??vvv?>+`.````.`~|~|^^^^^~^+++++>+^^++++++++--~||-~-``~^>>>?>+|-+}}wzv??w|<br></br>
`?v}}?>>+.......~~>??>??>^``|^+++++^|^^~|^+++|----``-~||~~-~|+??v??vrwzwv}-<br></br>
`v?v?>+?+`....|~~>????vv?+~.-|||^^^|~^^|---~~-``||~??>>>^|~``-~^>>??}zv?vr~<br></br>
`vv??>+>v?`. `>+?v??vvvv??+~-~~~||~-~^^|~~--``~?r}vvv+??+>?>^~``-^>?v???}}-<br></br>
`r}???>??+>>||>v?>|>?vv?>>+^---~~~~~~~~~~~~~-^????????v??v}v>^--|^>v??????-<br></br>
.vv??>>>>??+>???????????>>|-````---~~~~----~`~+>????^?vvv?v???++^+?v??????-<br></br>
`v????>???>>???vvv?>^>>>|---`-~~~~~~~--`````--~+???????????vv?++>?????vvvv-<br></br>
`vvv??vvvvvvv??vv???++>^~~-----~~~~~-``-^++^^>???????????>>v?>>>??>??vvvvv-<br></br>
.?vvvvvv????>>^+>++^|^>>??~-~~-`-~-`-|?????v?^^>?????>>>>>>v???>???vvvvvvv-<br></br>
.?vvv?>>>+>>>~-~+>>++?????~~++~`-~^~|-~~-````.-+|^>>>>>>>>>???????????????`<br></br>
.??????vv???>~~|>>>>+????+-~^>>>>>>>~         .~--|>+>>>>?>v}}v}vvvvv}}}}}-<br></br>
`?vvvvv?????+~~^>>>>>????>^|~^|||+>?+          .`--+>>++>>>>vvvvv???vvvvvv-<br></br>
.??????????>~~~+>>>>?????????^+^>???>.         .-|^+>+++++>+>?????????????`<br></br>
.>>>>>>>>+|---~^>>>>???????>rv????>~.         .^???++>>?>>>>>?vvv?????????`<br></br>
.?>?????+~~~---~^|+?vvvvvvv?}v}v>-.         .|vwwr}v???v???>>?wrrrrwwzzzzz~<br></br>
.???????>~~~----~|>v}rr}}}v^>+|.           -}zzzwwzxv?vvvv?>>>vzzzzzzxxuuu|<br></br>
.>???????^-------~>v??????^              ..>}vvv?vvv?+?????>>>|?v}vvvvvvv}-<br></br>
.++>>>>>++?^^~---|+>++++>>~            .```-^++^++>>>||>>>>>+|-~+>>++++++>.<br></br>
.^+>>>>>+?rr?^^^^++>+++>>>^.          ..`---|^^^+>>>>^~|+>>>+~-`|^++++^^++.<br></br>
.++>>>>^>rw?|^^^++++++++++|              ..`|+>>+>>>>>^~~|^+^~---^+++^^^++.<br></br>
.^+>>>>-->^|^^^^+++++^^^+^.                  .`~|++++++^---~~--`~^^^^^^^++.<br></br>


            </div>,

            <div>

.||||||||||||||||^||||||||||||||||||||||||||||||^^^^^|^|^?v?>??>^^^^^^^^^^.<br></br>
.+>>>>>>>>>>>>>>>>>>>>++^^||||~~|~~|||^+>>>>>>>>>>>>>>>vrv??vv}?++>>>>+>>>.<br></br>
.+>>>>>>>>>>>>>>>>>+^|~~|^^^^^++++++^^^^+>>>>>>>>>>>>>v}vvvvrwv?v?>vrr?+>>.<br></br>
.+>>>>>???>>>>>+?^~|^>>>>>>>>>>>+^^|^++>>>>>>??vv???v}v??++?}v?`~????rr>>>.<br></br>
.+>+++?v~+?++^~-+v^+++++>>>>>+|~^^+>>>>>>>+>rzxr?v?>>?>^`..`v??|~vrvv}r?>>.<br></br>
.+>>++>?>??||+>?>}?v?>>+++++||>???>>+++++>>vrzw}^>?>v^....->vv}}rrrrr}???v`<br></br>
.++rr?>>?>?+~--+?vv}}rw}v?>?vwwzwwwwrv?vvv}}??vvv????.....^?>???v????vwzwz~<br></br>
.>+?zw+|~``.....^v+^^>?vvv?rwwwwwwwwwwv???>+|>?????v-.....~++??>?>>?v}}}}v-<br></br>
.???vz|.....`...-^^+++++|+vrrwwwwwwwwww>|^++>++++^+~.......-.|???^~???????-<br></br>
`vvv}v?......```++>>+++++^+>???>???v???^+>>+++++++^-......... ...`-+vvvvrr~<br></br>
.???}vv~....|>^|>++++++>>>>++^^+++^^+^^^++++++++++>^..........`+>????????v~<br></br>
`??vvvr?~.`+v?^^++>+++++++++>>+++++>+++|+++++++++++^-|-.....`+`+>?v}??v}rw~<br></br>
`}}}vvvvv^`+?-~^+>+++++++++++++^++++++^~|^+++>>>>++^|>+.....`>->??rrv}wxzw~<br></br>
`zzr>?vvvv??>-||++>>+|^^+>++^^+||+++++|~-~~|+++^^+>++|~~`....``>??vr}vvvvv-<br></br>
.v}vv}}}}}}v?|~|||^^||||^^|~^++^~~|^^^|~~-~~~~|~^?v?^+?v>++|-`.|???v}vvvvv-<br></br>
.???????????+>~~||~~~~~~~~~^^^++|-~~||~~~~~`|+>?vvv+??v}v}}v??>>?}?vrv????-<br></br>
.?????????>?+~+^~~~-||-~~~~~~~~~||~~~~~~~~|`^>>vvvv^~vvv?vv????>?vvvr?????-<br></br>
.??????????^|^^^|+>>??+~|~~|||||||||~~~~^?v?^>>?vvv?~-^+|+???v}rzwv}v}rrrr~<br></br>
.?????????+^^^^^+??????|~~~~---~--~~~~-~vv??+++^^^???-``-^>>?rwuzxzvvvvvvv-<br></br>
.????????--+>>>????????~-~--^+|+???+`~-.~>>>+---`~|>v|--~^^>+vrzrzw?vvvvvv-<br></br>
.???????+`~>?>>>?????????>+|+>~~^+>^~^^+>>>>>+~~-~~???-`--|~~??vv}??vvvv??`<br></br>
.????????~-^>>>+>?????????-         `+>>>>>>++|--~-+vv?+|`-->wrrrrrrrrwwww~<br></br>
.????????|-~|^>>>?????????~         +>>>^|~--``-~||^?vvv?^`~}wrrwwwwwwwzzz~<br></br>
.????????~----~^+>????????.         -^|~--``-||>??>^>vvv>>-|??????vvvvvvvv-<br></br>
.?>???????+~-|++??????????^         .`````~???????>++?v??>-|??????????????`<br></br>
`vv}}}}}rr}}}rrwwwwwwwwwwww`         .```~vwwwwwww?>^>?+^--^rrrrrrrwwrrrrr-<br></br>
`rrwwwzzzzwrwrrxxxxxxxxxxxu>    ..    .|+-}xxxxuuuv|||+>^`-+uuuuuuuuuuuuuu|<br></br>
`vvvvvvvvv?vz?vvvvvvvvvvvvv?     ?`    +``?vvvvvv}?+>>>?+--|rrrrrrrrrrrrrr~<br></br>
.+>>>>>>>>+~|`>>++++++++++++`    ~^     .^>+++>>>>++?>>>^--`~+>>>>>>>>>>>>`<br></br>
.^+>>>>>>>|  `>>>>>>>>>>>>>+`    `^.    `+>++>>>>>+|>>+|----`~>>>>>>>>>+++.<br></br>
.++>>>>>>>-  ~>>>>>>>>>>>>>+.    -`     ~>>>+>>>>>+|~~------+^>>>>>>>>>+++.<br></br>
.^+>>>>>>>~  |>>>>>>>>>>>>>~    .-      ~+++++++++++^|----->}?^>++++++++++.<br></br>


            </div>,

            <div>

.|||||||||||||+?^|||||||||||||||||||||||||||||||^^+++^^|^+^||||||^>???????`<br></br>
.+>>>>>>>>+?>>?v>>>>>>>++^^|^+++++++++++>>>>>>>>>>>?v??v}}vvv??>??vvv}rwwr-<br></br>
.+>>>>>>>>++v???>+>>>>+++^^^^+++>>>>>>++>>>>>>++>>>>???vvwzxuw}v?~^vvv?>vw~<br></br>
.+>>>>??>>^>?v>+>++++>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>+++>>?v>>|?v?v???>`<br></br>
.+>>>>?v>>^|>?>|>>++>>>>>>>>>+^^+++>>>>>>>>>?vv?>>>+>>>^--^|>>v??}rrv??vvv`<br></br>
.?v???>+|--``^???????vvvvvvv??vvvvvvvvvvvvv}rwwr??v???~`.~?+???vrrrr}v?vvz~<br></br>
`rwzrv```--``-|>>???vzuxzzzzzxxxxxzzwwrw}vr}}rzzzwwz?`...`v>>???vvv}}}vrrw~<br></br>
`vv}}v````^+>?>|>>^|^+?rrwwrrrrrrwwwwwrr}?>>+>>?????-.....>?+>>>>>?vv}}}}v-<br></br>
.???v}~..|?>^+|||--~--~+>?vvvvvv}rwwwwwrr}>|+>+^^^+-......`++??>>^^???????`<br></br>
`vvvv?v-.~>^~~-~~~~~||>>>>???????}rrrrr}}}v^|+>>>+>`........``^>???>~->???`<br></br>
.????>?+|^~`--``--->v?>+++++++^^|+vvvvvvvv?+++++>+>`...........`|>+`.^????`<br></br>
`vv}v}vv??>^??~-~~~>>|^+++++>>+++~~+^||^?>+++^>>?>+`..`~|^^^++||~``.~??vvv-<br></br>
`ww}vv?v}v|+?^-~^??>>^++++++++++^^-``~|-|^^^^?vvvv~`.-vvvvvvvwvrr}v?vrwzzz~<br></br>
`}}rvvvvvv??>~--???++++++++++++^|^^``-~|^--~>?????-``+>++^+?>??rwrwzzwr}vv-<br></br>
.vvv}}}}}????|-^+~^^+++++++++++^|^^~-|^^~~^>>++++>+..`+?>???+|>?v}rwwrzr?v-<br></br>
`??????????>+^~+~~||||^^^^++^^^^+^^^`-^||^||^+>>???+~.~??v}}v?vv?vvv}}v??v`<br></br>
.??vvvv???+?>??>^>>|-~~~|~~~~~~||||~`~+~~|^|^+>???>^??-~>?v}vvv?>?v?vv????-<br></br>
.||+?vv???>????>??v?vv++v+~~|~~~~~-`|v>~+???+++>????vv?^-+>?v}vvv}}}}}}r}r~<br></br>
`vv?>^~-~|`~~>>>v}rrrrrv+|~------``-^?||????>++~~|+?>???>>?>?}rrr}}vvvvvvv`<br></br>
`vvvvvv?^|>>+>>+????????>>+``-`~+|^?^`..`~|^^~-```->?+|^|+??>?rwwvv???????`<br></br>
`vvvvvvv^`^?v?>?vvvvvvvvv??|..`---++`~^+>??vv?>>^~->?>+++|>???vrrv????????`<br></br>
`rrrrrrrrv-~>>>??rrrrrrrrrv+       ~?rwrrrrrr}}vv?|+????+~|>??v}rv}}}}}}}}-<br></br>
`zzzzzzzzzv?|+?+>??vvrxxxxr?.       `^vwzzzwwwww}+++>???>~`~??}rrvzxzzzzww~<br></br>
`r}rrrrr}vv?+>?^^>vvv}rrrr?>+`        .`^?}}}}}}?>>+>???^|~+?vvvvvvrrrrrrr~<br></br>
`v???vvvv??~+>>>?vvvvvvvvv?++|            `+?vv???>>+>?>|~?vvvvv????????vv-<br></br>
`vvvvvvvvvv-+?>>?vvvvvvvvvv^|~.    ``.    ...~>?vv?>+>>^^?+?}}}}v?????????`<br></br>
`rrrrrrrrrr~->vv}}}}}}}}}}}?||~    `||||` .....?vv?+^^>v}z??wwr}vv}}}}}}}}-<br></br>
`vvvvv????v+>>?vvvvvvvvvvvv?^|^` . .|^+??^~.-|+?vv?>>rwuwww?vvv?vvvvvvvvvv-<br></br>
.+>>>>>>>>++++>>>>>+++++++++^~-.   .----|>>|>>??vvv??}vv>+?>^+>>>>>>>>>>++.<br></br>
.^+>>>>>>>+~~^>>>>>>>>>>+++>^-.   `--~~~~`..`+>????>>^|-^||^^+>>>>>>>>>>++.<br></br>
.++>>>>>>>+~~^>>>>>>>>>>>>>>|.   -|~~-`..  .^>>>>^~--~+v}?^|^+>>>>>>>>>>++.<br></br>
.^+>>>>>>>+~~+>>>>>>>>>>>>>|.   `|---.     .+++>+^^^|^???v>|>+++++++++++++.<br></br>


            </div>,

            <div>

.|||||||||||>>|||^|||||||||||||||||||||||||||~-|^^^^^^^^^^^^^^^^++??>>????`<br></br>
.+>>>>>>+>?>?v>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>^^>>>>>>>>>>>>>>>>>?vv??v}rr~<br></br>
.+>>>>>>>+v?v?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>|+>>>>>>>>>>>>>>>++??????}r~<br></br>
.+>>>>?>?+?v}>>>+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>|>>>>>>>>>>>>>>>>^>>????v}-<br></br>
.>>>>>???~~+??+++>++>>>>>>>>>>>>>>>>>>>>>>+>>???^>>>>>>>+>>>>+>>?>???+++^|.<br></br>
`}r?v?~-`...-++>>>>?}vvvvvvv}}}}}}}}}}}}vv?+>?v}??}vvvvvvvv?????>^|~`..``` <br></br>
-xuu}v...-|>?>>++^^^?ruuuuuuzzuuuuxxxxx}vv???zxuv}zzwwwwr}}?>|-|^--~~~~-``.<br></br>
`}wwvv~.`>??>~|~`..``^}}}}v}v}wr}rwwww}???+>vrrr>v}vvvvvv?|~+?v}}vv}rr}}v?`<br></br>
.????r+..|||~^|~`.`~v>|++>?????vrwwwwwww?|^>????^???????|^?vvrrvv}>~^?v}rr~<br></br>
`vvv?v^^>>+>>>|~~+>>?z?>>>>????}wwwwwwwww>+~+>++>>>>>>^.~r?>^?}vv??..vv}v}~<br></br>
.?v??`????|^^~-->+>+^>++++++++|>rwwwwwwww>+~+>>>?>>>>-..`v++++?v??>`^rvv>v-<br></br>
`v?v}~|???++++^^?^>+>+++++++>++~+v}v?vr}?++~>??????>~..`.>v+>+??????vvvv??`<br></br>
`?v}rv|+?>~?vvv>+^^>+++++++++++^-++||`|^^+|>rrrrrrr+.````-}??>????????????`<br></br>
`v}}}}????vvv>????~^^^+++++++++^~|~|-`~~~-+vvvvvvv?`.````.+}>?>???????????`<br></br>
`vvv??vv??>>^~????^~||^^++++^^^^-`~^~^^~^>??????>>^....````vv??>>?????????`<br></br>
.??>>>>+^>+~``|>????^+^~-~|~~|^^^~---||>vvv??>>>>>+....`````>?vv++>???????`<br></br>
.??>?>>?||^|~--?vvvvvvvv?>~--~|||~``|>?vvv?>>>>>>?~```...`````~>??>>>?>>??`<br></br>
`v+vw??zv--```>zzzzzzzzzzw>~~~~~``--vwr}}?>>>>>>+>~.``......``..`~++^^~``-.<br></br>
`xxx}+}zr?+``^wwwwwwwwwr?+^|~~-``--|?vv?v>>>>>+>^+~.-|^++++^|~-``..........<br></br>
`vvvvvvvvvv?}vvv??????vvv?>```~++-.>?>>>+-|+>+~~|~`|rzzxxxxxzzwr}?>^-`.```.<br></br>
`vvvvvvv????wv?v?v?????????.  .`-. ???+`....`~|||`.>zrrwwwwwzzzxxxxxz}>|`..<br></br>
`wwzzzzzwww}?~?wwwwwwwwwwww`       ~|++>>????vvvv|``vzzvvvvv}rwwwrwwzxxz}>.<br></br>
-xuuuuuuuuuz. >uuuuuuuuuuuu?             ..`~+?w?^>^`+v>?>>>???v}rwwrrwzuu|<br></br>
`wwwwwwwwwz? |wwrwwwwwwzzzzz+     .......      |+>??+``>?>>?v}??>?}rzxzrrw~<br></br>
`vvvvvvvvvv?>vvvvvvvvvvvvvvv|    ~???????-    `+>>??^~-|???v}}???v}}vrxxzw-<br></br>
.???????????????????????????-    ~>>>>>^`    .+>>>>>|~-`>??v}}vv}}}}}?vvvv-<br></br>
.???????????????vvvvvvvvvvvv^    |????~     .~++>>>+~~-|????}rrr}}}v??vvvv`<br></br>
`??vvvvvvvvvvvvvvvvvvvvvvvv}>    ^?v?`    .|++>>>>>^~~~??????vv}}}}?>?????`<br></br>
.+>>>>>>>>>>>>>>>>>>>>>>>>>>`    ~^`     `^++>>>>>>|~~|????????v}}v?+^++++.<br></br>
.^+>>>>>>>>>>>>>>>+++>>>+++^.   .`     .-^^|^>>>>+|~~|^?????v?>?}}??++>>++.<br></br>
.++>>>>>>>>>>>>>>>>>>>>>>>>`          `|^^||+>>+|~-`|^>?????vv>?v}??>+>>++.<br></br>
.^+>>>>>>>>>>>>>>>>>>>>>>>|          `^|||~~>>+^~---^^>?????v?>?vvvv>+++++.<br></br>


            </div>,

            <div>

.|||||||||+++^||^+^|||||||||||||||||||||||||||||^^^||^^^+>+++>>>>>>>++>>>>`<br></br>
.+>>>>>>++v???>>???>>>+++++>>>>>>>>>>>>>>>>>>>+>>++>>>>>????????vvvvv?????`<br></br>
.+>>>>>++?v???>>???>>>>>>>>>>>>>>>>>>>>>>???>>>+^^+>>>>>??????????vvv?????`<br></br>
.+>>++?>>>???>+>??>+>>>>>>>>>>>>>>>>>>>>>??>>>>+++>>>>+^>?????????vvv?????`<br></br>
.>>>>>>^|`~+>>+^>?>++++>>>>>>>>>>>>>>>>>>?>>>>+?+>>>++^++>>???>>??vv}vvv??`<br></br>
`}rvv?``...`-|~~~??>???v??v}}}}}}}}vvv????>>>>???????>??>????????vvvvvvv??`<br></br>
-xxxv+..-+???>+^`^?>v}zuxzwwzzxxxxzzzw}vv???>}rr?v}v>vvv????>+??v?????????`<br></br>
`vvvvv-.~???>|~-`~-|^+?v}}v?vv}}v}}}}v?^>>?>vvvv?vv>?vv>>?+|?}}rrrrwzwwwwr-<br></br>
.????r>-~+||^^~-~|+|?^^++++????vrrwr}?>?^~|?????>?>????~|?vw}}rv}v>^+?????`<br></br>
.??v?v?+>>^>>^-|^>>>>?+^+^+>>?vrwwwwwwv?>|||>>++++>>>>^.|}??>>>++~-..>|^|^.<br></br>
.??vv^???>^++~~+>>>||^+++>>+^+v}wwwwwwr?>|^^>>>+>>>>?^`-+v|--`~^^>+~|?^|~~.<br></br>
`??vv^+??>+>+|+>?++|^^+++++++|+?vrrrrwv+^|+>?>??????>^^|`~|-|>?vvvvv}vvv?>.<br></br>
`v}}r}>??>|??+??????+^++++++++~|+>>>>~-~|+>vvvrrrrrv>+`..`??vv????????vvvv`<br></br>
`vvvvvv????+^~?vv?>+||++++++++|-~^~-`-~~^+???vvvvv?>^`.`^+?v??????vvv?????`<br></br>
.?????????-`~~>???>|~~||^^++++~`-|^~-||+??>?????>>+~`..^>|-?}???>vv??+|>??`<br></br>
.???????>```.`+?????>>+|-~~|||~`||~-~~>?v>?vv?>>>>>~...~>|-~????vv???|``??`<br></br>
.??????v|`|+^+>??vvvvvvv?^--~~|~--`-^?vv>>v??>>>>>+~....>>-~|||?}?>?>|`>v?`<br></br>
`rvrwvvw~???v?vwzzwwwwwww}>~~~~``--^vv?+-^>>>>>>|^^.....`>~--~|~~|+>~`|?||.<br></br>
`www}?rv|v?>v>v}vvvvvvvv?>+~~~-``-|~~-`...`>>>>+~^-.``---+?^^^||~~~~--||~~.<br></br>
`vvv??v?>>?>^^????????????>.```|`...`.....`+>>|~-`.-+>>>>>}wvvvvv?>+^|||||.<br></br>
`??????????^+?????????????>.   ..  .`...``..`~|~`..~>+++++>vrvv}}}rr}v?+||.<br></br>
`rwwwwwwrrrv??v}}}}}}}}}}}?         ..`+?>^~-|++^```^>>^^^|^v}vvvvvvv}}}v>.<br></br>
-xuuuuuuuuux?>}uuuuuuuuuxuw|         .-~~~~|^^+-~`-``~+----~~vvvv?vvvvvv}}-<br></br>
`wwwwwwwwwzv+?wzwzzzzzzwwwww^      ..~^||-`~~` `--~~-`.----~|>????vv}}vvvv-<br></br>
`vvvvvvvvvv??vvvvvvvvvvv????`    .>>^?vv?+~~~..+|~~~--`-~~~|||-~~^>>>?vvv?`<br></br>
.????????????>>>>>>>>>>>>>^`     |>^|>>+|-``..+>+>?vvvvvvvvvv?>+|~~~~`-~~~.<br></br>
.?????vv?????vvvvvvvvv???+-`.   `>?^>?|````.`|>>>}r}vvvv}rrwzzzwrv?>~`--~-.<br></br>
`?vvvvvvvvvvvvvvvvvvvvvv>~``.  `->?^+|`````^>>>>^???>>>?v}rrrwwwzzzrv?^~--.<br></br>
.+>>>>>>>>>>>>>>>>>>>>>+~``.   .`^+`..``.`+>>>>+^|^>>++>???vv}rwzzwr}}}v>~.<br></br>
.^+++>>>>>>>>>>>>>>++>+^|~`.  ..-|`..```|>>+>>+^^~>????v?????v?vwww}}}}}}v`<br></br>
.++>>>>>>>>>>>>>>>>>>>^|^|`  .````....-|+>+>>>^|~-????vvv??>??>?vv}}vv}}}}`<br></br>
.^+>>>>>>>>>>>>>>>>>>+^|^-.  .```... `^++++^+^||||>>??vv???>??>??vvv??v?vv`<br></br>


            </div>,

            <div>

.||||||||>?^|||~|||||||||||||||||||||||||||||||^?>+^^^++>>>>>?????>+^^^^^^.<br></br>
.++^^^^^^?}?+>?>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>+++++>?}}}vvv}rr}vvv???>.<br></br>
.++^^|||>?v>++????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+^|^+>+-?vrr}}vv}}r}}}vvvv`<br></br>
.+>++^?>^v?^^^?v?>>>>>>>>>>>>>>>>>>>>>>>>>>>??+^|^+>>>|->?vr}}}}}}}}}}vv??`<br></br>
.>>>+>>-``^|^>|>?+^++++>++++>>>>>>>>>>>>>>>???^|+++>>^-++>?vvvvv?vvrrrrrr}-<br></br>
`}}v??..`-^|-|||??>+???????+>???>>>>>>>>>>>v+^^>>++++~+++>>??vvv??vrrrrrww~<br></br>
`wzzv+`.|?>>++|~>v?v?vv}}v>>??????????>>????^+>>>>>+~+>>>>>+>??????}wzzxxx~<br></br>
`vvv??^`^v++>+--^>?v?????????????????>++??>+>?????>|>??????>?>?v}wzwwwwwzz~<br></br>
.????vv??>^>>^`^?>>?>^>+>>?????v}}}v?^|+^>|>?????>^???????????rrr}vv}}vvv}-<br></br>
.??vvvv>>+|>>~|>>+^+^|+++^^>>?}rwwww}?>>^`^>>>>+^^>>>?>>+????v??vv????v?vv-<br></br>
.?????>>>++>>-^+||+~|^+++>++|vwwwwwwww>+^`+?>>>+>?>>??>^+?+????^~-`````.`-.<br></br>
.????????^>>^`|>??>~~^++++++^>rwwwwww}++~^???>>????????-+>?+~`````````````.<br></br>
`rr}}}}rv+^-~->vv}}v>~^++++++^>v?+>v?||||v}}??}}}vvvv?-|?+-``````---`````-.<br></br>
`vvvvvvv?~-~~|+vv??v?~|^^^++++~+|~~-||-|?v?>?vvvv???+-+?~`-``-^?vrrr}v?>+~.<br></br>
.???????-~```~>??????>|~~|^++^~++>+|^~?vv>^?v???>>>+->?-``-+vwrvvv?????vvv-<br></br>
`?vvv??^++~+????????????~-~^^|-^?>||~>}v>?vv??>>>>+^?+.`+v}rv???vvvvv???v}-<br></br>
`vvvvv>>?>?vw}?????vv??v?~--~~~``-~^>v?++|-|??>>>|~>^..?wr?>?vvvr?v}?++?vv-<br></br>
`vvvvv+v>vvv}??v?????????>||~~`.`~^vv>..   +??>+~-?|..`vv?+?v???>?zv??+v??`<br></br>
`vv???+?>???+`+???????????>|||~^^>>|.    `|>??>|-+^....??>??v???vw???+`->?`<br></br>
`??????>+>>|`^>>??>>>??????.``~~`.   .`|+??+>>^-~>`....|v>>????}w????^.`v?`<br></br>
`????????>>++>>>>>>>>?????+        .|+>???~.`||-^`.....`?>^????v????^.-r?>`<br></br>
`vvvvvvv??????????????????-      .~+++>????+~``-`.......+?^+???>>>>+`~}?>>`<br></br>
`zzzwwwwwwwwwwrrr}}v}vvvvv?-     ~+++?vvv???>^~.........`?>|+??????>>????>`<br></br>
`rrrrrrrrrrrr}}}}}}vvvvvvvvv`   -++>>?vv??>++>`..........~?+|^????????????`<br></br>
`vvvv??????????????????????+   .^^??>????+|++-.``.........^>+|^+>>>>>>????`<br></br>
.>>>>>>>>>>>>>>>>++++>>>>+^.   -++>>>>>+~~++^-.```--~~~~~-->v?>^^^++++++>+.<br></br>
.???????????????????????>>~   .|+>>>>>>+>>>>^~```-~~~~~~~~~|?}}v?>>+>+++++.<br></br>
`???????????????????????>+.   `+>>>+>>>????+|-````-``------~~+?v}v???>>>++.<br></br>
.++^^++++++>>>>>+++++++++|   .-++++|^>>>>>+|~-~|-``.```````----~^++?vvv?>>.<br></br>
.++^^+++>>>>>>>>>>>>>>+++`   `~^|^+-^>>>>+|~~~?wr}v?>+~``````````````~||-~.<br></br>
.^+++>>>>>>>>>>>>>>>>>++^.  .```->>^^>>>^|~~~~~+?}zxxxz}?+-```````--`````` <br></br>
.^+++>>>>>>>>>>>>>>>>+++`   ....~>++||^^||~~~~-~+??v}wzzzw}?^````````-----.<br></br>


            </div>,

            <div>

.------~+>>^|||~^^^||||||||||||||||||||||||^++???vv????>>>>???v??>^^^^^^^^.<br></br>
.~~~~~~|>??>+>?>>>>>>>+++++>+>>>>>>+++++>>>>>>>??vv>?????}}vvvvr}}vvvvv??>`<br></br>
.|~~~~~|???>+>????????>>++>>>>>>>>>>++++>>>>>>>++++^>>>~>v}}}}vv}}}}}}}}vv`<br></br>
.++^~^?^>??^++???>>>>>>>>>>+>>>>+++>>>>>>>>>??>+|^+>>+||>?vr}}r}vv}}}}}v??`<br></br>
.>>+^+^```|~^?>>>^++>>>>>++++>>>++++>>>>>>>???+|+++++^~++>?v}}}}vvv}}}rr}v-<br></br>
`vvv?+..-^+~~~|>?>>>??????>^+++++++++++>>>>?+>>>>++++~++>>??vv}v?v?vv}rrrw~<br></br>
`}rrv+..^?>>+^-~?v????????>^++++++++++++>>?>>>>>>+++|+>>>>>>???v?v?vv}wzzz~<br></br>
`vvv??>-+?+>>^-~???v?????????>?>>>>>>>+>??>>?>>>>>+^>?????>>>>>?v}}}vvrwzz~<br></br>
.????vv??+^>>~-+?>>?>>?>>?????>??vv?+^~^^?+>>?>>>>^>???????>>>??vvvrwwzzww-<br></br>
.??v}v?>+^^>+~|+++^>^|+++^>???}rwwwr?^^^~|>>>>++^+??>???>??>????vrrrrrrrrw~<br></br>
.>???>>++^>>^-+++++|~^+++>++^vwwwwwww?++~`>?>>>+?v?????>>?>????>>>++>>>>>?`<br></br>
`?????>>>++^~`+>???~~^++++++|?rwwwwww?++~^???>>vvvvv???|>>>^~~|^^|~-`````-.<br></br>
`}}vvvvv>|~-~^?vvvv?^~+++++++|?v>+?v>|^|~vvv??}}}vv???--|`.-+|``.```.......<br></br>
`???????^-``~|>?????>~|^^^+++^|^|~~~^|-|vv??v}}vv??^-`--..|~```~^+>+^^|~-`.<br></br>
.????>+>~|~+?^>?>>>>>>||~|^++||>>+^^^^?}v??}}v??>>+^|?>-~^~-^>>^|~~~||^^++.<br></br>
`????+?^>?>vzv??????????-~|^||->?^~||v}v?v}?^|+>>>+^?^`^>+++^~-|>???vv???>`<br></br>
`???>>>+????v???????????>--~~~~``-|>?vv>+~.  ~?>>+~>|`^>>+~~+?}rr}}rvvvvvv-<br></br>
.???>?+????>+++>>>>>>????+|||~`.-~>v?^.    ~>?>>|-+|~|-+^+?vwvv?>?wvv?>???`<br></br>
.???>>+>????+~+?????>?????>||||^^^-`    `^?}r?>^-^+|~..?wv??v???vrv???^+??`<br></br>
`?????>++>>+|+>???>>>????v?.``~-.    `^?}wrvv+~~->+-..-v}?>>???}w?vv?>~^v?`<br></br>
`???>>>>>>>++>>>>>>>>>????^       .|?rrrrr?>+^``|^`...-?v?>?v????vwv>||}?>.<br></br>
`?????>>>>>>>>???>>>>>>>??`     .-vr}}}}}rv>^++||`....->??>????>v}?>|^?^>>.<br></br>
`vvvvv?????vv????>>>>>>>?v?`   .~?r}}}}}?+>???+-......`+^??>???r}????>-~?>.<br></br>
`vvvv?vv??????????>>>>??vvv|   `>}}}}}v>>?v+^+-........^|^???>vr????+~|}v?`<br></br>
.?????????????????>>>?????+`  .~??vvv>^??+~^^~.........~|->??>>>???>~>}???`<br></br>
.++++>>>++++++++>>>>>>>>>|-.  `+>>>>+>>+~-+^|-.........`|~~>??>>>>?^+v????`<br></br>
.??>>>>>+++>>>>>>>?????>^|`  .-+>+^|>?>+>>+|~`..........-~-~+???>>????????`<br></br>
.?>>>+^^+>>>>>>>>????>>+|~.  .-++>>>>>>>++^~``..........`~~~-|????????????`<br></br>
.^|~~~|^+++++>>>>>>>>++^|.   .~>+++++>>+^|~````-`........`~|~--~|^^>????>?`<br></br>
.~~~~|+>>>>>>>>>>>>++++^~   .`^+^+++>+^||~~```+?>>+|-``...-||~-`-```-~||-~.<br></br>
.~-~|^>>>>>>>>>>>>>>>++~`  .````~>+^^^^|~~~```-|+>????>+^~``~~~--~------``.<br></br>
.~~|^+>>>>>>>>>>>>>>+++-.  .`...~+^^^|||~~-`````-~~^+>????>|--~~```-----~-.<br></br>


            </div>,

            <div>

 ....`~+>+|^+++++>>>>^|||||~~~~|||~`...`+>>??v}vrrv}}}?????v???+++++++++^^.<br></br>
 ......^v?>>>>>???????>>++++++>>>>+|```-^??v?w}xuxzzzrvv??vv}}}vvvvv?????>`<br></br>
.``....>?>+++>>????????>+^^^^>>>>>>+|~|+>???>??v}}zzwvvvvvvvvv}}}}r}}}}}}}-<br></br>
.+^-.|^^?+|^>>++>???????>>>^|>>>>>>>+^+>???>++>>+>vr?>????vvvvvvvvv}}}}}}}-<br></br>
.??+||-.`~-+>|~^-+???????>>>>>>>>>>>>>>>>>>>+>>>++>?++>>+>?vr}rr}vvvv}}}}}-<br></br>
.????-.|??~+>^|v>-^???????>>+++++>>>+++++++^+>?>>>>++|>>>>??v}}}}r}vvv}}rr~<br></br>
.????+.|?+^+~~>?v>~+?>?????>+^||^+>>+^^^^^^^++>>>>>>++???????vv}}}vvv??vrr-<br></br>
.????v>+>+>>|`~????>>??vvvv?>+^^^+>>+^^^^^^^+>??>++^+???????>???vvvvv???}r~<br></br>
.>?vvv??>|+>|`~+??v?>???vvvv?>+++>?>>^^++++>??>++|^???vv???>>>>????vv?vv}w~<br></br>
.?>>?>>>+^>^`-^+>+??>>+++??vv??v}}}?^||^^>???>+^|>vvv??????>>>>++>???vrwwr-<br></br>
.>>>>>>>^|~|~|>>>>>>~^+>+++>?}wwwwwr?^^|->??>>++?v}vvv???>>>^~-~--?v}rrwwz~<br></br>
`????>>+-~~~^+>????|~^+++>>+~?wwwwww?++^`^??>>?v}}}v??>+|~`..`~-`+?vvvvvvv-<br></br>
`?????^||~``-^>?>>>~-~^++++++|??+>v>^++^-??>?v}rr}v>^`..   .--|+???>^|~--`.<br></br>
.????^~^|~|?>^>>>++^|`-|^^+++~~|~-~^^^|~+??}wwwr?+-   ......`+?>^-`........<br></br>
`???|^^^+++}w>>>>++++^~-~|^++~+>>^^^|~~?vwxxwv+`..`|++++>?^+?+-............<br></br>
`??+^+++>>++>>????>>>>>^-~~||~^?^||~+?ruuz?|.   .^?>>++>^~+>-..``-----``...<br></br>
.>+-^+>>+^^+++>????????>|--~~~-`---?wxzv|.   .~>>>>+^|^~|>|.`~|^||~--~~~~|.<br></br>
.|^-|+>>>>>>>>>>+++>>>??>|||~-```~wzv^.   .|vrww?++~~|~^?|~~|~--~^>>>>^|~~.<br></br>
.??>|^>>>?????>>++++>>????>|||-~??^`   .^vzuyx?^~^|~~~??^~-~^>?}wzzwwwwwrv`<br></br>
`????+^^^+>?>>>+++++>>???}>...-.`.   ~vzyyuw?^+v?^^~+?^|~+?}wwwr}vvv????v}~<br></br>
`??>>>>+^+++++>+>>>>>????v|       `>wuyyx}??v}}}?+^>^``vr}vvv??>>?}v?v????`<br></br>
`>>>>++^+++>>>>>>>>>>>>>>>.    .+}xuuuxv?>rzwzxrv>+`..+zv?>?????vrvvv}??vv`<br></br>
`>>>>>++>>>?>>>>>+++++++>vv`  .vxxxuxv?}zrvzz}??>~....>wv>>?v?vw}?v}??>>}v`<br></br>
.>>>>>>>>?>>>>>>>>+^^^^>vv?`  ^}rwwv?rzr}}v?^~+|`.....?r?>>vv>?v?rwv??>vv?`<br></br>
.^+>>>>>>+++>>>>>>+^^^????-  .>?v??}w?>>+^||+>-.......?v?>>v??>?r}?>+?>~>?`<br></br>
.|^+>>>>^|||^>>>>>++^???>-   .^>>>>>^+>++++>>`........???>????}zv??>??`^v>`<br></br>
.+>>>+|~~~|^+>>>>>>+???>~.. .~>>>>^~>>>>+^^+`.........?+??????wv????^`?w??`<br></br>
.>^|-```~^++>>++>>+??>>+-   `|^>>>+>>>+^|~^`..........+>>???>>>????^-vr???`<br></br>
.``...`~^>>>++>>?^>>>>+^`  `~~^>>>>>+^|~--`...........~+^+???>>???+-vv????`<br></br>
 .....~^>>>>>>>?>+>++++~.  `--^^^^||~~-```............`^^^+???>>?????????>`<br></br>
 ...`-^>>>>>>>??+^>+++|.  .||--~~~~-----`..............~++^++??>??????????`<br></br>
 ..`~^+>>>>>>>?>>||~---.  `^~``-~~~-----```.............|^^^|^>????>>?????.<br></br>


            </div>,

            <div>

 ....`~|^^^+++++>>>>>+^^||~~--~~||~`..`||^>>???>??+>??vvv?????>+++^^^+++^^.<br></br>
 .....->?>+>>>????????>>+++^~++>>>>+|-~+??vvvwr}}vrwxxzzvvv}}}v???????????.<br></br>
 `... .>+^+++>>????????>+^^+|>>>>>>+|~|+>????vwzzxyuuww}v???vvvv}}}}vvvv}}-<br></br>
.+|`.-|++-^>+|~^>???????>>>+^>>>>>>>+^+>??>???vv}rzuzrvvvvvvvvvvvv}}}}}}}r~<br></br>
.??+|~``~`~^^`|^-+???????>>>>>>>>>>>>>>>>>>>>>>>>?vw}v????vvv}}}vvvvv}}rrr-<br></br>
.????`.+?+|^+~^v|`^????????>+++++>>>++^+++^^^+>>>>>?v?>>>>?vv}}}}}vvvvv}rr~<br></br>
.????+-^>^+>^~+>>~~>???vvv?>+^||^+>>+^|||||||+>>>++++????????v}}}rrr}vvv}r-<br></br>
.????v?>+^>^~|>?>??>??vvvvv?>^|~~+>>^||~~~~|^>>>+^^^+?vvv?????vv}}}}}vv??}-<br></br>
.>>????>+^+|`-^>>???>??vvvvv?+^||+>>+^|||~|+??>^^||>vvv??????????vvvvvv??v-<br></br>
.>>>>>>>|-|^`|+>>????+++?vv}v??vvv?>+++++>>??>>>+^?vvvv????>>>>>>>???v}vvv-<br></br>
.>>>>++|~|-~^+>>>?>>^^+++++>v}wwwwrv^|^^^????>^+?v}}vv?>>+^-`.````>?vvvv}r~<br></br>
`???>^^^-``-|+>>>>?^-^++>>++|vwwwwwv>++|~>??>+?}r}}v?+~`..   ....`|?vvvrrr~<br></br>
`???+>^|~-^?|+>>>++~-~^+++++^^??>??^+++|-??+?}rww}?|`.      ..`-|^+???????`<br></br>
.??^>^|^^~|?++>>+^|~-`-|^^++^`||-`~^++|-+?vwzzwv^`.         .~||++>>>+++++.<br></br>
`?^+|^+^^^|??+>>+^||||~-~~^+^-+>+|^^~-`+wuuzv^`     ....``.~^~~+>+^|~```...<br></br>
`+++^>+++++^+>???>>++>>|-~~||~+>~|~~+?wyzv^`    .`~|~~|^|^>?|++|-`........ <br></br>
.~|-++++^|^+>>>>>>>????>~--~~~````^}uuv^.    .^????+^+^|+v>|^~`............<br></br>
.`-`^^^++>>>>>+^|||++>??+|||~```-?uw?`    `+}xuw?^~~^^|>?||-....`-~||~-`...<br></br>
.>>^|++>>>>>?>>+^|||^>????^`~~`^v?|.   `+}uyx}>|^>+~|^?>~~`.-|+>??>>>>?>+^.<br></br>
`???>^^^^+>>>>>+^^^^+>???v| ....`   .^}uxrv?>>vwwrv|>?^|~~^???>++^^^|~~||^.<br></br>
`?>>>>+^^^+>>>++++>>>>>>?}-       -?zxr}vvvvwuyur??>^|^+>>++++>??v}}}v?>^|.<br></br>
`>>>>++^^++>>>>>>>>+^+>>??.    `>rzwrww}v}ruuuuzvv?|^^~>+>?v}}rrww}rrwwwwr-<br></br>
`>>>>+++>>>?>>>>+^~~|^++>v?.  |wwv}xx}vwuuzxxwvvv>^+~.+}rvvv}vvvv?vv????v}-<br></br>
.>>>>>>>>?>>>>>>|~|||^^?vv^  .>v}zx}vzuuxzwv>^+^^^|`.`rrv??????>?}rv?v????`<br></br>
.^++>>>>>+^^+>++>+^^^^?v>^.  `>??>>vwvv?>^||^>+|~`...^}v?>>????vw}vvvv>^??`<br></br>
.|^+>>>+^|~~||^>>>+++???+....-+>>+>>>>>+^+++?^`.....`>vv?>?vv?rzvvvv??~>}v`<br></br>
.+>>>^|-`-~~~+>>>>>>???>` ..`^>>>>>>>>>+^~^+`.......~+v???v???rvrrvv>-?w??`<br></br>
.>^~`...-|^|+>++>>>??>+~. .`^?>>>>>>>+|-~^^.........^^????v?>>vrv??>^?v???`<br></br>
.`.. ..-^++>>+>>?+>>>+^-   ~>++++++^~~--^-..........^|+??????}v????+?|^???`<br></br>
 .. ..-^+>+>>>>?>+>++++`  .-~---~~-----|`...........^||>????r}?????^~?}???`<br></br>
 ....-^>>>++>>?>^|++++|.  ->+``-------~-`...........+|~+???>>?v???^^v}????`<br></br>
 ...-^+>>>>>>>>>+||~`-`   |>|..~+^~~-~-``...........^~~->>?+++???->v?>???>`<br></br>


            </div>,

            <div>

 ....`~~|^^+++++>>>>>+^^||~~--~~||-. .^>~~>|>^----``->vr}????+++++^+++++^^.<br></br>
 .....^v++>>>>????????>>+++^-^>>>>>>>++?vvv}}}?++>vvzyyxvv}}}}??>>????v???.<br></br>
 `... `?~|++>>>????????+++++|+>>>>>>^~|+>?v}r}xxzuuzuxzv????vv}vvv}vvvvv}v-<br></br>
.+|`.`^+|`+>+~-|>???????>>+>>>>>>>>>+++>?>>v}?w}xxzuuur}r}vvvvvv}}}}}}}rrr~<br></br>
.??+|-``^`^^~`>^`+????????>>>>>>>>>>>>>>>>>?>>>>?vruuwv}vvvvvv}}vvvvv}}rr}-<br></br>
.????``>?|^~``|?-`+?>??vv??>+++++>>>+^^^^++^^+>>>>>vz}>????vvvv}}vv???v}rr~<br></br>
.????>~^^^>>+~~|~`->?vvvvv?>^|||^+>>^|~~~~|||+>>>++^^v?>??>??}rr}}rrr}v?vr~<br></br>
.>>??v?>+^>||??>^+>>??vvvvv?>^~~~^>>^|~~~~~~|>>+|^+^^vv?vv????v}rr}}rrr??v-<br></br>
.>>>>??>+^^-`~??>??>>?vvvvvv?+|~~+>>+|||~~~|+>+||||>vv??v???????v}}}}r}>??`<br></br>
.>>>>>>>~-~+|+>>>????>+>vvv}v?>???>>>+++^^+???>>>|>}vvv?????++???????vv>>>`<br></br>
.>>>>>|`-^~|^+>>>>??++++>>+?}}wwzwv>^^++>???v>^^?v}}}v?>+|-. .`-|^>???>??v-<br></br>
`???>-`||``-~+>>>>>^-^++>>+^+}wwwww++^^+???>++?}rrrv?^`.    .`.. ..~>>>vv}~<br></br>
`??>-|++^~?z++>>+^+~-~^+++++~>?>>?>^+++~^?>+?}rww}?~.       .`..  `+v}wwwz~<br></br>
.?>`^+^^^|~+|+>+^||-``-|^^++~`||~~^+++|->?vzzzr?~.           . .^?rzzwwr}}-<br></br>
`?`|>++^~~~-~>>+^||~|~~--~^+^~>>^^+|~`-+zyur>-          .....`^}zwv?+|~-``.<br></br>
`|~>>>++++>>>???>>+++++~`-~||~>^~|~|^?xyr>`     .`-|--~|^^^|+}w?^-........ <br></br>
.`|+++++++>>>>>>>>>?>>>>--~~~~```~?uyx?-     `>vv??>^>??>>>v}>-............<br></br>
 .`^^^++++>>>>+^~~|+>>?>|~~~-`.`^zy}^.    -?wyur?+~|>?>+|>v>`..............<br></br>
.>^~|++>>>>>>>+^||||^>>???~.`~->v>`    `?zyxr?>+>?>^>+~~??-................<br></br>
`??>^|||^+>>>>+^|~~|^+>>>?`  ```.   .>ruzv>>?rxuuxr+^-^v+........`--`......<br></br>
`?>>>>+^^^+>>>++^++>>>^|?v`      .^vxwv??}zyyuuuzrv^-?v~...`-^?v}wzzz}?>|` <br></br>
`>>>>++^^++>>>>>>>>+~-^>??.    -?ww??vwuyy}xyuux}?>+}?`.-+vwxxxxzwwwwwzzzw-<br></br>
`>>>>+++>>>?>>>>+|``-|^+?}?  .vzv?vwuyyyyyxzuzwrv?vr>.~vzzzw}vv???>?????vv-<br></br>
.>>>++>>>?>>>>>+-`-~~|+?vv`  +??vzyyyyyyuxzr?>^+v}?-.~wrvv???>>>????????>>`<br></br>
.^^+>>>>>+^^+>^-++^^^>?v|`...`+?>>?vvvv?>^|~~^??+`...vr??>>???vvvv}}rr}}}v`<br></br>
.|^+>>>+|~~~|-|>>>>>????^  .~+>>>>>+>>+^^+^^??^`....~zv?>>?vvv}vv}}vvv}}}w~<br></br>
.+>>>^|-``~~`^>>>>?????+` `+??>>>>>>>>>+|~^>~.......>w?>>>}????vxx}vv????v-<br></br>
.>^-`...`|^-+>+>>????>^`  ^>>>>>>>>>+|~-~>^.........?}?>>?}?>>}x}???v?|???`<br></br>
.`.   .`^+~+>+>>???>+||`  `^^^^^^^|~---~>-..........vv?>??v?vzw?????>.^???`<br></br>
 .   .`^++~>>>>??>^|^+^.  -~-----------+`...........vv?>??v?zr?????-.?w???`<br></br>
 ....-^>>>~^+>>+^|^+++~  .+>|``-------~`...........`}v?>?????vv???~-rr?>??`<br></br>
 ...-^+>>>>^+++++>>^|~.  `>>~..~+^~~-|-.............v??>>>?>>>?v?.+r?>>>?>`<br></br>


            </div>,

            <div>

`rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwww}vv??????>>>>>+-+????????vv+`.|?vv`<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyyyyyuurr}vv?vvvv>^?}}}}v??vvv>..>zzrr-<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuyuuuxzwr}}v++v}}}}}rvvvv^ .?zzwr}`<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuuxyuuxxxx}^>rwzzxxxxzzw^.-ruxxxzw-<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuyuuuuyxuuuuuuuxwrxxzwzxxuuuz^ |zuxxuuyy|<br></br>
                  -uyyyyyyyyyyyyyyyyuuuuuuuuuuuuuuxuuuuuuuuruuxuuuxxuuuxxxxzzxxz~ +xuxuuuuyy|<br></br>
                  -uyyyyyyyyyyyyyyyuuuuuuuuuuuuuuxxxuuuuuuwwuxuuuxxxuuuuxxzuuxzv~+xuxuuuuuyy|<br></br>
-uyyyyyyyyyyyyyyyyuuuzzzwwwwrrwrrrrrrrrr?rrrw}vrwzzzzzzrwzxxxxwrwzxuuuuuyy|<br></br>
-uyyyyyyyyyyyyyyyyyyuxzwr}}}}}}}}}}}}}rv?}}}?^?wzzzzzzwwzzwzzwzwzzwzuuuuyy|<br></br>
-uyyyyyyyyyyyyyyyyyyyyuxzzzwwr}}r}}}vvv??vv?~?wrrrrwwrrrw?+?}wzwzzzzzxxuyy|<br></br>
-uyyuuuuuuuyyyyyyyyyyyyyuuxw}vvvvvvvvvv?vv>|?}}}}}rr}rrwv.`}uuuuuuuuuuuuyy|<br></br>
-uyyuuuuuuuuuuuuuuuuuyyyyyuuzrv}}}}}}vvvv>|?}}}}rrr}rrw?`-wyuuuuuuuuuuuuyy|<br></br>
-uyyuuuxxxxxxuuuuuuuuuuuuyyuxzzwrrwwrrrrv>}zwwzzzzwwwz?.-wuuuuyyuuuuuuuuyy|<br></br>
-uyyuuuzxxxxwrwzzxxuuuuuuuuuuuuxxzzzzzr}?wxxxxuuuxxuuv`~zuuuuyyyuuuuuuuuyy|<br></br>
-uyyuuuzxxuuz}v}}}rrwzxuuuuxxxuuuxzrzwv?}wzxxuuuxzuuv.|xuuuyyyyyuuuuuuuuyy|<br></br>
`xuuuuuxxxuuzr}}vvv}}rrrrwzzzxuuuxzxz>>}vv}wxuuxxuuv.|xuuuyyyyyuuuuuuuuuyy|<br></br>
`xuuuuuxxxuuz}}}}}vvv}}rr}}vvrwzzzxuz+rwrvv}rxxxuu}`|xuuuyyyyyyuuuuuuuuuyy|<br></br>
`zzuuuuzxxuxz}v}wzwr}}vvv}}}}}}}v}rvvvxzzwwwwzzuu}`^xuuuyyyyyuuuuuuuuuuuyy|<br></br>
`wruuuuxxxuxzv}}wzzzzzwrr}vvv}rvrr?+?}zzxxxxxzuur`~wuuuuyyuuuuuuuuuuuuuuyy|<br></br>
`r}zuuuxxxuxzv}}wzzzzzxxzzw}vvvv}?~?vrzzxxxzrxuw-~?v}wuyuuuuuuuuuuuuuuuuyy|<br></br>
`rrwxuuxxxuxzv}}wzzxxzzxxxzwwrrwv~?}vrrrwzzrzzz~^vv>+?vwuuuuuuuuuuuuuuuuyy|<br></br>
`}rrzxxxxxxxzv}}wzzxxzzxxxzrrwzz?}zwrwrrrr}}w}?vrz}?|->vruuuuuuuuuuuuuuuyy|<br></br>
`v}r}zxxxxxxz}v}wzzxxxxxxzwrrwzv}xuzzxxzwrrwv-~zzzuz}?>vvwuuuuuuuuuuuuuuyu|<br></br>
.vv}r}zxxxxzz}v}rzzzxzzxxzwrrwvvxxuzzuuuzzxr`-zuuuuyuxw}}wuuuuuuuuuuuyuuzz~<br></br>
.?vv}vrxxxxzzvv}}zzzzzwzzzwrwwvzxuuw}zxwwxw~`wuxuuuuuyuuuuuuuuuuuuuuuxzzzx|<br></br>
.??vv}}wzxxzz}}}}zzzzwwwzwwwzzzxxxxrvwzwxz|`ruuuuuuuuuuuuuuuuuuyuuxzzzxxxx|<br></br>
.?v??vwvrxxzwrv}vrzzzwwwwzzzzxxxxxzwvrwxx^`ruuuuuuuuuuuuuuuuyyuxzzzxxxxxxx|<br></br>
.?v??vzwvzzwr}?vvvwzzzzzzzzzzxxxxxzwv}zx+`}uuuuuuuuuuuuuuyuuxwwwzzzzzxxwww|<br></br>
.vv??vrz}}zzrv???v}rwzxzxxxxxxxxxxzwv}z>.}uxuuuuuuuuuuyuxzzwrwzxuuuuuxrv?v-<br></br>
.?v??vrwrv}zw}???>?vrxxzxxxzzzxxxxww}}>.vuuuuuuuuuuuuxzzzzwwzxxuuuuuxv??vv-<br></br>
`}vv?v}rrw}vv?+>?vvzxxxxxxxwwwwwzxwr}>`?uuuyyyyyuuxzzzzxzwzuuuuuuuuwvvvvvv-<br></br>
`rrrrr}}v?>^?v}}rr}wxuzwxxxzr}wr}rr}v^>xzxxxxxxzwwzzzzwwrwxxxxxxxz}???????`<br></br>


            </div>,

            <div>

`rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvwwrv?>>>?????>>??| +vvv?v}rrrwwwwwrrw~<br></br>
-yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyzruyyyuxw}vvr}vvvvv~`?zxxz}??vvvvrwxuuuy^<br></br>
-uyyyyyyyyyyyuuuuuuuuuuuuuuuuuuuyu}zyuuuuyuuzrr}rxx}`-wuxxxxu}rr}vv????vv}~<br></br>
-uyyyyyyyyyyyuuuuuuuuuuuuuuuuuuuyrryyyyyuuuuyuw}rr?.+zxxxxuyyzwxzzzzwwr}vv`<br></br>
                  -uyyyyyyyyyyyyyyuuuyyyyyyyyuuuuuzvxuuuuuuuuuuwxyxr?+wxxxxuyyyurxzzzzzzzzzz|<br></br>
-uyyyyyyyyyyyyyyuuuuuuxxxxzzzzwzv}zwwwwwzwzz}wxwrrzw}}wxyyyyyywxxxzzzzzzzx|<br></br>
-uyyyyyyyyyyyyyyyuzwwwwwrrrrrrwv?}rwwwwwww}vwz}vv}wwww}rzuyyyyzwxxxxxzzzzz|<br></br>
-uyuuuuuyyyyyyyyyyux}??v???????>??v??vvvv???v>^?vrr}}rrr}}wuyyurxxxxxxxzzz|<br></br>
                  -uyuuuuuuuuyyyyyyyyyur???????????vvvvvvv??vv^ ^zzzzxxuuxxzwxyyyrxxxxxxxxzz|<br></br>
-uyuuuuuuuuuuuuuyyyyyyurv}rrrv?vvvvvvvv??vv| +xxxxuyyyyyyyyyyyyrzxxxxxxxzx|<br></br>
-uyuuzzxxuuyyyyuuuuuuuuyuwrwzr}zzzzzzzv}wr| ?xxxxuyyyyyyyyyyyyywwxxxxxxxzx|<br></br>
-uyuuwwrwwzzzzxxuuuuuuuuuuxrr}wxzzzzxrwxx>.vuxxxuyyyyyyyyyyyyyyxwxxxxxxxzx|<br></br>
-uyuxwr??v}rrwwwwwwzzxxuuuyyr?rxzzzzrwxx^.vuxxxuyyyyyyyyyyyyyyyxrxxxxxxxzx|<br></br>
-uyuxwr??????vv}}rwwwwwrwzxuwww}wxxrwxu+.ruxxuuyyyyyyyyyyyyyyyyurxzxxxxxzx|<br></br>
-uyuzw}?vvwwrrvv???vv}}rwwwrv}zzr}rrux+.>vrxuyyyyyyyyyyyyyyyyyyxwxzxxxxxzx|<br></br>
-uyuzw}?vvzzzzzzzwr}v????v}v?rwww}vwz+.>+->vzyyyyyyyyyyyyyyyyyyxwxxxxxxxzx|<br></br>
-uyuzw}?vvzxxxxxxxxzzzr}vv????vvvvzw>`}z?+^?vxyyyyyyyyyyyyyyyyuwxxxxxxxxzx|<br></br>
-uyuzw}?vvzxxxxxzzzzzzzzxzzvvvv?>??^>?vrxzr}vzyyyyyyyyyyyyyyuzwzxxzzzzzzzx|<br></br>
-uyuzwr?vvwxxxxxzzxxzzzzzxw}wzzvrr|.vzwruyyyyyyyyyyyyyyyyuxwzxxxzzzzzzzzzx|<br></br>
-uyuzww?v?rxxxxxzzxxzzxxzxwvvzrwx?.}uuyyyyyyyyyyyyyyyuuxzzzxxxxxzzzzzzzzzx|<br></br>
-uuyxww?v?rxxxxxzzxxxzxxxxr?v}rxv.vuuyyyyyyyyyyyyyuxzwzzxxzxxxxxzzzzzzzzzx|<br></br>
`xuyxww???}zzzzxxxxxxzzzxz}?v?zr.?uuuyyyyyyyyyyuxzwwzxxxxzzxxxxxzzzzzzzzzx|<br></br>
`rzuxwwv??vzzzzxxxxxxxzzxzv??vw->uuyyyyyyyyuuzwwzzzzzzzzzzzxxzzzzzzzzzzzzx|<br></br>
                  .?rzxwwv???wzzxxxxxxxwzxxzv??v+~xuyyyyyyuxzwwzxxxzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
.?vrzwwv?v?}zzxxxxxzwwrrwr???v~ruuuuxxzzzzzxxxxxzzzzzzzzzzzzzzzzzzzxxxxxzx|<br></br>
.???}rw}?v?vzzxxzxxz}vrw}vvvrwzxxxzwzzxxxxxxxxzzzzzzzzzzzzzzzzzzzzzzxxxxzx|<br></br>
.???>v???vvvwzzxwrzxzrvvrwzzxxxxxxxxxxzzzxxxxzzzzzzzzzzzzzzzzzzzzzzzzxxxxx|<br></br>
.?v??vr}??vvvvvv}}vwxxxzwzzzzxxxxxxxxxxxxxxxzzzzzzzzzzzzzzzzww}}}rrwwwzvv}~<br></br>
.vvvv??}????????vvv??zzzxxxxxxxxxxxxzxxxxxxzzzzzzzzzzzzzzzwv??}zuuuyyu>... <br></br>
.vvvvv??>???vv??+|~|?xzzzxxxxxxxxxxzzzxxxxzzzzzzzzzzzzzzzwv?ruyyyuyyr- ... <br></br>
.vv???vvvvv?^~-|>vzxuuxxxxxxxxxxxxzzzxxxzzzzzzzzzzzzzzzzrvvxyyyyuyu?...... <br></br>
.?vvvvvv?|`.^?wxuuuuyyuxxxxzzzzzzzzzzzzzwwwwwwwwwwwzzwwv?}xuuuxxuz^ ...... <br></br>


            </div>,

            <div>

`rwwwwwwwwrrrrrr}}}}}}}}}}r??rrrrrrrvrw}>`?r}rwwrw?????????>??vvvvvv>^^^^^.<br></br>
-yyyyyyyyyuzzzxxzzzzzzzwww}+}xzxxxxwxyxwr}rxuyyyyyzwwr}vvvvv??vvv}rww?>>>+.<br></br>
-uyyyyyyyyuuzvv}}}}v}}}}v?^>rrrwwr}zxw}}zzzrwuyyuyxzxzzwr}}vvvvvvvvvv}v?vv`<br></br>
-uyuuuuuuuyyux}?vvvvvvvv>>>vvv}}vv}v+?rwwwwzwrwuyyxzxzzzzwwwwrr}vvvvvvvvvv-<br></br>
-uuuuuuuuuuuuuux}v}wrr}??vv}}}}vvr?-+zxzxxxzzzrrzuxwxzzzzwwwwzzzzwr}vvvvvv-<br></br>
-uuuuuuuuuuuuuuuuw}rxzvwzvxxzz}rz?`?uuxuyyyyuuuuzuuwxzzzwwwzzzzzzxxzz}vvvv-<br></br>
`xuxuxxxxxuuuuuuuxxr}vzz}wuuxwxu?`}uuuuuuuuuuyyyyyuwxxzzwwzzzzzzxxxxxx}vvv-<br></br>
`wwzzxxxxxxuuuxxuuuxz}}v?zuxrxx^-wuuuyuuuuuuuuuuuyuwxzzzzzzzwzxxxzzxxxzv??`<br></br>
`rrrwwwzzzzzzzxxxuxzxuzv}xxrxw-~zuuuyuuuuuuuuuuuuyurxzzzzzzzwzzzzzzzwrr}>>`<br></br>
`rv?vvv}}}rwwwwwrrvzxuz}wwrxw`|xxxuuuuuuuuuuuuuuuyurxzxxxzzzzzwwzzzzwr}}v?`<br></br>
`rvvvvvvvvvvvvv?v?vwzz}rx}r}`~?vwuyuuuuuuuuuuuuuuyxwxzxxxxzxxxzzzzxxxxzww}`<br></br>
`rvvv}wzwwr}}vvv??vvvvvvvrw?^wv++}xyyyuuuuuuuuuuyyzzxzxxxxzxxxxxxxxxxxxxxx|<br></br>
`rvvv}zzzzzzzzwzzwwr}}}vvv~?r}xwvv}yyyuuuuuuuyyyuxzxzzxxxxzxxxxxxxzzzzzzzz~<br></br>
`}?vvvzzzzzzzzzxxxxxwr?}z||xxxxuuuuuuuuuuyyyuuxzzxxxzzxxxxw}}}}zxw}}}}}}}}~<br></br>
`}???vwwwzzxxxxxzzzxwrvr?-xuuyyuuyyuyyyyuuxzzzzxxxxxxxxxxzwvvvvrxxr}}}}}}}~<br></br>
`}???vzzzzxxxxxxzxxzwvv?`}uuuuuyyyyyuxzzwzzzxxxxxxxxxxxxxzwvvvv}xxxw}}}}}}~<br></br>
`wv?vruxxxxzzxxzxxxww?v~?yuuzwwzzzzzzzzxxxxxxxxxxxxxxxxxzzzvvvv}zzzxw}}}}}~<br></br>
`zv?vrxxxxxzzzzzrrwr}vv>xuxr}v???v}zzxxxxxxxxxxxxxxxxxxzzzzvvvv}zzzxxw}}}}~<br></br>
`zrvvrxxxxzzzzv}zrv?vrrww}}vvvvvvvrrrwzzxxxxxxxxxxxxxxxzwzzvvvv}xzzzxxw}}}~<br></br>
`w}vrrzxxxzwwwv?vrwwzxzrvvvvvvvvvrrrwrrwwzzxxxxxxxxxxxzzwzzvvvv}xzzzzxzr}}~<br></br>
`wwvrv}rrrwrrzzzzwzxxzr}vvvvvvv}}rwwwwrrrwzxxxxxxxxxxzzwwzwvvvvrxxzzzzxz}}~<br></br>
`wz????vvvvvvvrzzxxzw}}}vvvvv}rrrwwwwwwrwzxxxxxxzxxxxxzzwzrvvvvwxxzzzxxzw}~<br></br>
.?r??vv?>+^^^+}xxxz}vv}v}vvvrwrwwwwwwwwwwzxzrrwzxz>^+>?vwz}vvv}zzzzzzxzxzr~<br></br>
.?>^~~-~+?}wxxuxxrvvv}}}}}}}wwwwwwwwwwwwrr}}wuuyz|     .`-+?vvwzzzzzzzxxzz~<br></br>
.``-^vwxuyyuyyzzwvv?v}}}}}}}wwwwwwwwwww}v}zuyyyr` ..     .`-~+vrzxxzzzxxzx|<br></br>
.?r}>?wuuyyyyuwxxzr>?}}}}}rrwwwwwwwwwwrv}xyuyy?.....    .--~|+???}wzzxxxzx|<br></br>
-xxxv^>}uuuuyzzzxxzv?}}}vvvwzwwwwwwwwwrvzyuyu+.....   .~++^+???????vwzxxxx|<br></br>
`zuuuzvvxuuuxwxzxxx}v}v?>>?zzwwwwwwzzw}zyuyz~ .....   -++>????????>vxwwxzx|<br></br>
`xuuuuuuuuuurzzxxxzrvvvv?+vxxwwwwwzzwvwyyyw` .....   .-~~|>???????>}yuxrzx|<br></br>
`xuuuuuuuuuwrzzzzxxwvvv}rrzxxzwwzxxwvwyyy}. .....    .-~~^???vv???>}yuyzwx|<br></br>
-xuuuuuuuuzvwwwzzzzzzzzzxxxxxxzxxzr}xyyy?.......     .-~^???vvvv??>wyuywzx|<br></br>
-xuuuuuuuuvvwwwwwwzzzzzzxxxxzzzwvv}zxxx?......     .`-~^+???vvvvv>>xuxxrzz~<br></br>


            </div>,

            <div>

`rwwwwwwwr??????????>>??>>>~.+????vv?}wv}}}}}}}}v?????>?>>??>?}v?vvv?|     <br></br>
-yyyyyyyyyuzwxxxxxzw}r}vvv|.>wzzxxxxwwyzxxxxxxxxxzzw}v???v????xr}wwr}?>^-` <br></br>
-uyyyyyyyyyyxwwxxxxrxzrww|`vxxxuyyyyyyuzxxxxzxxxxxxxxzrv???v?vx}}wrrrwwwwr-<br></br>
`zzzzxuuuuyyyuz}wurzxrzw~~wxxuuyyyyyyyxzxxxxxxxxxxxxxxxxr??v?}x}rwrrrrrrrw~<br></br>
`rrrrwwwwwwzzxxxwvvurz}`>xxxuyyyyyyyyyzwxxzxxzxxxxxxxxxx}??vvwz}rrrrrrrrrr-<br></br>
`vv?vvv}}rrwwwwww?vvzv`vuxuuyyyyyyyyyywzxxzxzzzxxxxxxxxwv??vvr}vrrrrrrrrrr~<br></br>
`vvvv???vvvvvvvv?v}r?-??}uyyyyyyyyyyyywxzxxxxxxxxxxxxxzvvvvv}rww}rrrrrrwww~<br></br>
`vvwzzwwr}vvv??????>^vv|-}yyyyyyyyyyyzwxxxxxxxxxxxxxxxzzzwz}vzuyxv}www}v>^.<br></br>
`vvzxxxxxxxzzwrv?v+`}zzx}ryyyyyyyuxzzzxzzxxxxxxxxxxxxxxxxwrr?vzzwwv?^`.    <br></br>
`vvzxxxxxxxxxzzv?v`vxuyyyyyyuuxzzzzzxxxzxxxxxxxxxxxxxxxxxz}wrv?vr}v?+`.    <br></br>
`?vzxxxxxzzzzzr?>`vuuyyuuxxzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxx}wwwv?vrwwwrv?^` <br></br>
`?vzxxxzrr}v?v????xxxzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxvrwwwwwwrrrrwwwr-<br></br>
`?vzxwzxzvv}v?vwxxxzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxvrwwrr}}}}vvv??>`<br></br>
`???vvv}zzrrzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx?````?}^....     <br></br>
.??>>>>>?wxxxxxxzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxz>    ^rz^        <br></br>
.|^>????}zzxxxxxzzxxxxzwrrrrw}wxxxxxxxxxxxxxxxxxxxxxxxxxxr?.   -rwx>       <br></br>
`z?>xyyyzxzwzxxxxxxxz}vrxuyr~ .~>}xxxxxxxxxxxxxxxxxxxxxxzvr.   `z}wu?      <br></br>
-uw?vyyzzzzvwxxzzxxr?vxyyx>       ~vwxxxxxxxxxxxxxxxxxxx}vz.   `zrrzx>     <br></br>
-uyyuyxrxxzvvvvvrzw?}yyyv`       .|+>?}zxxxxxxxxxxxxxxxz?}w.   `xzrwxx^    <br></br>
-uyyyu}xxxzv?>+>ww?}yyz^         ^>??++>vwxxxxxxxxxxxxx}?r}    |xzrwxxz~   <br></br>
-uuyyvwxzxzwrwwzrvwyyv`       .`|>>??>++^?wzxxxxxxxxxxz??r?    >xxr}zxxr.  <br></br>
-yyy}vzzxxxxxzrvvxyz|        -^>>>????>>^rywzxxxxxxzxxv?v}~    vxxwrwxxx?  <br></br>
`rwvvwzzxxxz}vvwyyv.        ->>>??????>>+xywzxxxxxxxxr>vv?.   `xxxzrrxxxz- <br></br>
.???vzxxxxxz}}xuz+    .... .+>?????????+?yywzzxxxxxxz??vv~    ?xzzzwrxxxxv <br></br>
.?vvv}wzxxxxxxzr^  .........+??????????+xyurzzxxxxxxv>vv?    `zxxxzwrxxxzx~<br></br>
.?vvvvvvvwzxzzxxx}^........^>??????????wyyurzzxxxxxw>>??`    vxxxxxwrzxxzx|<br></br>
.?v?vv???v}zxzxxxxxr>` ...|w>????????vxyyywzxxxxxxz?^>>>^-. |xxxxxxrrzxxzx|<br></br>
.?vvvvvvvvvvrxxxxxxxxz?`  ?y????????wyyyuwzxxxxxxx}+?^`-|+++>vwxxxzrrxxxzx|<br></br>
.+??vvvvvvvvvrxxxxxxxxxw>.?yx?????ruyyuzwzxzzxxxxw>>~     .+??}xxxw}rxxxxw-<br></br>
.?v??vvvvvvvv?rzxxxxxxxxxw}zyw??vxyyuzwzxzwvwxxxz?~.     .|>?rzxzwrvzxxxw}~<br></br>
`}rv??vvvvvvvv?v}wzxxxxxxxxzwzrzyyxwwzxxw}vvwxxzv.      `>>?zxxwwuwvzxxw}w~<br></br>
`rwrwv???vvvvvv??vv}}rwzzxxxzwwrrrrzzzzzwrwwzzrv^`.   .|>>?rzzrrzv?wzzrvzw-<br></br>


            </div>,

            <div>

`wwwwwwwwrv?????????????>>>~.+????vv?}wv}}}}}}}}v?????>?>>??>?}v?vvv?|     <br></br>
-yyyyyyyyyuzwxxxxxzw}r}vvv^.>wzzzxxxwwyzxxxzxxzzxzw}vv??vv????xr}wr}v?+|-. <br></br>
-uyyyyyyyyyyxwwxxxxrxzrww|`}uxxuuyyyyyuzzzzzzzzzzzwzzzrv???v?vx}}}vvv}}}}v`<br></br>
`zzzxxuuuuuuuuw}wurzxrzw~~wxxuuuuyyyyyxwzzzxxzzzzwzxxxxxrv?v?}x}}}vvvvvvvv`<br></br>
`rrwwwwwzzzzzxxzwvvurzr`>xxxuuuuuyyyyyzwzzzxxzwwzzxxxxxx}v?vvwz}}vvvvvvvvv`<br></br>
`vvvvv}rrrwwwwwww?vvzv`vuxuuyuuuuyyyyywzzzzzzzwwzxxxxxxwv??vvrvvvvvvvvvvvv`<br></br>
`vvvvvvvvvvvvvvv?v}r?-??}uyuuuuuyyyyyywxzzzzzzzzzxxxxxxvvv}vrrrrvvvvvvvv}}-<br></br>
`vvwzzzwr}vvv??????>^vv|-}yuuuuuuyyyuwwzzzzzzzxxzxxxxxzzzwz}}zxuzvvvvvv?>^.<br></br>
`vvzxxxxxxxzzwrv?v+`}zzzvruuuuuuuxxzwwzzzzzzzxxxxxxxxxxxxw}r?vzwrr?>~`.    <br></br>
`vvzxxxxxxxxxzzv?v`vxxuuuuuuuuxzzzzxxxxxxxxxxxxzzzzxxxxxxz}wrv?v}}v?^`.    <br></br>
`?vzxxxzxzzzzz}??`?xuyyuuxxzzzzxxxxxxxxxxxxxxxxxzwwxxxxxxx}wwr?>?vvvvv?>|` <br></br>
`?vzxxxzrr}v?v????xxxzzzzzxxxxxxxxxxxxxxxxxxxxxzzzzxxxxxxxvrww}vvvvvvvvv}v`<br></br>
`??zxzzxzvv}v?vwxxxzzwzxxxxxxxxxxxxxxxzzxxxxxxzzzzxxxxxxxxvrww}vvvvv?????>.<br></br>
`???vvv}zzrrzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzzxxxxx?`-``>v|....     <br></br>
.??>+>>>?rzzzzzzzzzzzzxxxxxxxxxxxxxxxxxxxxzzzzzzxxxzxxxxxz>    ^}r^        <br></br>
.^+>????}zzzzzzzzzzzzzzwrrwww}zxxxxxxxxxxxxxxxxxxxxxxxxxxrv.   -}}z+       <br></br>
`z?>xyyyzzzrzzzzzzzzw}vrxyyw| .~?rxxxxxxxxxxxxxxxxxxxxxxzvw.   `wv}z?      <br></br>
-uw?vyyzwzzvrzzzzzzr?vuyyx>.     .|vwxxxzxxxxxxzxxxxxxxx}vz.   `z}vwz+     <br></br>
-uyuuyxrzzzvvvvv}zw?}yyyv`       `+>>?}zxxxxxxxxxzzzzxxz?}r.   `zw}}ww|    <br></br>
-uyyyu}xzzwv?>^>ww?}yyz^        .+???>>?vwzzxxxxzzzzzxx}?}v    ~zw}}www-   <br></br>
-uyyuvwxzzzrrrwwrvwyyv`       .-^>??v??>>?wzxxxxxzzxxxz??}?    >zz}vrww}.  <br></br>
-yyy}vxzzzzzzz}vvxyz|   .   .~>>>>?vv???+rywzxxxxxxzzxv>v}~    vzw}}}www>  <br></br>
`rwv?wzwwwzz}vvwyyv. ...... ~>>????v???>+xywzxxxxxxzx}+?v?.   `zwwwr}wwww` <br></br>
.??>?rrrrwzz}}xuz+  ........+>?????????>?yywzzzzzzzzw>>vv~    ?zwwwrvwwww? <br></br>
.??>??}wzxxxxxzr^   ........+??????????+zuurzzzzzzzz?+vv?.   `wwwwwrvrwwww-<br></br>
.????>?v}wxxxzwwwv|........^>??????????wyyxrzzzzzzzw+>??`    ?zwwww}}rwwww~<br></br>
.?????vv??vrwrrrrww}?`....|w????>>??>?zyuywwzwwwwrw?^???>|` ~wwwwwwvvrwwww~<br></br>
.>???????>>?vrrrrrwww}>`  ?y??????>?rxxxxrrwwwrrrrv>?>~|>??>+?}wwww}}wwwzz~<br></br>
.+>????????>>vrrrrrrrrw}>.?yx?????}xuuxr}wwwwwwwww?>~.   .`>??vwww}vrwwzxr-<br></br>
.??>>?????????vrrrrrrrrrw}vwuw??vxyyuzrrzwrv}zzzz?|.     .|>?}wzw}}vrwwzw}|<br></br>
`vv??>??????????v}rrrrrrrrr}}zrzyyxwwwzzwvvvwxxzv`      ->>?wzzwwz}?rwzw}z|<br></br>
`vvvv?>>>???????????vv}}rrrrr}rrrrrwzwwwwrwwzzr?^-.   .|>>?rzzrrz??rww}vww~<br></br>


            </div>,

            <div>

`wwwwwwwwwwwwr}}wwwwww}v>>>}rvvvv+???vvvvv>>v}}?}?>+>??v}?++v?v}v?v+ ..... <br></br>
-yyyyyyyyyyyyzwzyyyyyuxv???zzwwwv?vw}rwwwv?}zx}}xw>`^}wzxzv?vrvwz}v....... <br></br>
-uyuyyyyyyyuzw}wuuuuuz}?v??wrrwv??rx}}rwv?vzzx?rr>`?xrvxwxw??vrvwv`....... <br></br>
-xzuuyyyyyuzwwvruyuuzr?vv??rrwv??}xxr}r}?vwxxr>}|-ruuywuwzz???rrv+ ....... <br></br>
-wwuuyyyyyzrrw}}uyuzw??v?vvrw}??}zxxr}r??rxxx?>~+zuyyyyurxz???}z>~........ <br></br>
`}ruuyyyyxwrrw??xyxwv?vvvz}rw??vzxxxwv}v}zwrw^``ruuyyyyzwxxv??}?.......... <br></br>
-}ruuyyyur}rr}>?rzrv>???v}??>>?}wwzzrvxwzzv?r^v??yyyyyyrzxxw??+. ......... <br></br>
-}}uuyyxrvv}r???rrr????}zz}?>?vwvvv???vv}}vvwzxxwzzzzzzzxzzz}?.            <br></br>
-}vxuuyxwwwwr?v?}rv?v?vr}v?-~~^+++>+++++?rzzxxzzxxxxxxxxxxxxru}.           <br></br>
`rvzuyuwrrrw?????v>^|--~~|+>?v}rwzxxuuuxxxxxxxxr?vvxxxxxxxxxwv+.           <br></br>
`rvzuuzrrrr}??^-~v|+?vwzxuuuuyyyyyyyyuzwxxxxxxxxr|^xxxxxxxxxx?             <br></br>
`wvruxrrrrrv??+v}vxyr>zyyyyyyyyyyyyyxwzxxxxxxxzrv>zxxxxxxxxxxz-            <br></br>
`wv}xzrrrrr?vvvwuwyyw^?yyyyyyyyyyyuwzxxxxxxxxxzwwzxxzzxxxxxxxu+            <br></br>
`wrvwrrrrwv?vvv?vrzuyxxyyyyyyyyyuwwzxxxxxxxxzzzzww}vrzxxxxxxx}.            <br></br>
`wwvrrrrrrv??vvv???v}wxuyyyyyyuzwzxxxxxxxxzr}rrwwzxzzxxxxxxxz`             <br></br>
                  `wwvvrrrrv??vvv???v??vvvrzxuuzwzxxxxxxxxxwwxuyyyyyxrwxxxxxxx|              <br></br>
                  `wwr?rrrw????vv?????vwxzzzzzzzxxxxxxxxxxzryyyuuuzwwzxxxxxxx+               <br></br>
`wrwvvrrr?????v??????vrzxxxxxzzzzzzxxxxzvuuyzz}wzxxxxxxxxx>                <br></br>
`wrrr?rrv?vv??vvv?????v}zxxxxxxxzzzxxxxrvyzzrwzxxzwzxxxxx?                 <br></br>
                  `wrrwvvw??v??????????vvvvwzxxxxxxxxxxzxwvwrrxxxxzvvzxxxx?                  <br></br>
`rrrrr?r?????v???????vvvvv}zxxxxxxxxxxxxxzzxxxxxzzzxxxx}r-                 <br></br>
`rrrrwr?v}??????rr????vvvvvvrzxxxxxxxxxxxxxxxxxxxxxzxx?|x}.                <br></br>
`wr}v?+-.`?rr}vvuuv?rv??vvvvvvrwzzzxxxxxxxxxxxxxxxxxw> >zx^                <br></br>
.|`.    .?wrrrrvzzv?v?rv????vvvvvvv}}}}}}rrrrrrrrrr}^ `vzx}.               <br></br>
       `vwrrrrrv?>^..?wwrv?^``~|+?????????????????vv. ?}xxx`               <br></br>
      -}wwwrv?|`    ?zrv????|.   `^>??vv?????????v?v.>vwxxx^               <br></br>
     ^ww}?|`       >w>- `????>-     .`~+>???>>^-. ->>}}xxxz?           -~  <br></br>
    ^v+-.         .+.    `?v???>~.        .^+.   .>vzvwxxxz?.          +w?.<br></br>
   ..                     `?vv????+~`.     >| .|>vrw}wxxxxr?`          ?ww~<br></br>
                           .?v??>>?vv??>^^^>>?vwzxz}wxxxzwrz~  .|.    .?rw~<br></br>
                            .>?????>??vvvv}wzxxzzr}wxxzwrwzz-.?zw     .}rw~<br></br>
                              ^?????>>>?v}wwwwwz}vzzw}rwwww}?ruu?     .}rr~<br></br>


            </div>,

            <div>

`vvvvvvvv}rwwwwwwwwww}v?vrwwwww}v?+??>?}vvvv?>?>vv?vvv>?v}}>+v~`v}rrrrrv?}~<br></br>
`zwwwwwwwuyyyyyyyyyyxwzvruyyyyxwr??vv?}wwrwv?vvrxr}rw}?rxxr>?||zuuyyyyyrzx|<br></br>
`wwwwwwrruuuuuuuuuuzww}>ruyuuzrr????vv}rrr}?vv}zzr}r}?}xzz?+`-zuyuuuuyzrzx|<br></br>
`wwwwwwr}uuuuuuuyyxwwr??}uyyzrr??vvvz}}wwr?vvrzxxr}r?vr}w?+`--?uyuuuyu}zzz|<br></br>
`wwwwwwrvuuuuuuuuzr}r???vwzrvv>+>>?v}??vv>??vwzzzwv?vrr?r?++z??zyyyyywwzzz|<br></br>
`wwwwwwrvxyuuuuuw}}rv?v?vww}}v????}zzvv}?>??rrv?vv>vr}}vzwzxuxzxxxxxzrxzzz|<br></br>
`wwwwwwrvxuuuuyxwwww?vv??wr}v?????}}v??>~|~^++^~---~^?}zxxxxxzzzzzzzzzzzzz|<br></br>
`wwwwrrrvxuuuuuwrrwv?v??>vvv+^^~------>+^+>??vvvvvvv}zxxxxxwxxxzzzzzzzzzzz|<br></br>
`wwwwrrrvzuuuuwrrr}?vv?>~?v?`-^>?vrwzzvxuuuuyyyyyyyuxxxxxxxwv??wzzzzzzzzzz|<br></br>
`wwwwrrwvruuuzrrrw??>`.`~>vvz}}xuuuuuuyuyyyyyyyyyuwzxxxxxxxx}>`rzzzzzzzzzz|<br></br>
`wwrrrrwv}uuuwrrr}?v?>}xu}vxu?|vyyyyyyyyyyyyyyyuzwzxxxxxxxz}v?vxzzzzzzzzzz|<br></br>
`wrrrrrr}vxuzrrrw??vvvvxyx}yyz?vyyyyyyyyyyyyyuzwzxxxxxxxxxzzzzzzzw}v}zzzzz|<br></br>
`vvvrrrrr?zxrrrw}>?vvv?vrzuyyyuuyyyyyyyyyyyyxwzxxxxxxxxxxxxxxzw}v}v?zzzzzz|<br></br>
`wuxzwrrwvrwrrrwvv?vvvvv??v}rzxuyyyyyyyyyyxwzxxxxxxxxxzzzww}}}rzxywrzzzzzz|<br></br>
-uxzwwrrwvvrrrrr?}v?vvvvvvvvvvv}rrzxuuyyxwwxxxxzxxxxxzrwwrwxuyyyyyx}xzzzzz|<br></br>
                  `wwrrrrrrr?rrrwv????vvvvvvvvvrzzzzzzzzwwwzxxxxxzxxxxzruyyyyyyyyuxzwrzzzzzz|<br></br>
`wrrrrrrrwv}rrw?????vvvvvvvvvv}zzzzzzzzzzzzxxxxxxxzzrxyyyyyzuxwwwzzxzzzzzx~<br></br>
`wrrrrrrrr}?rrr??????vvvvvvvvvvvzzzzzzzzzzxxxzzzxxrvzyuxywu}rwzxzzzzzzzzx> <br></br>
`wrrrrrrrrr?}w}?v??v??vvvvvvvvvvvwzzzzzzzzxzzzxxxzv?yyuru}rzzxxzwwzzzzxz+  <br></br>
`wrrrrrrrrrr?wv?v????????vvvvvvvvv}zxzzzzzzzzzxxxz?vyuzvwzzzzzrvvwzzzxw|   <br></br>
`rrrrrrrrrrrvvv??vv?v?????vvvvvvvvvvwxzzzzzzzzzzxxwrrwwxxzzzz}vvrzzxxw+    <br></br>
`rrrrrrrrrrwwv?zv???????vv????vvvvvvvrzxzzzzzzzzzzxxzzzzzzzzzzzzzzzx}rw`   <br></br>
`wwrrr}}vv??>^`-`~vvv??}zz?>vv??vvvvvvvrzzzxxxxxxzzzzzzzzzzzzzzzzzx?>wxv.  <br></br>
`v????>~.       ~}r}}}vrxx??wwwv??vvvvvvvvv}rwwwzzzzzzzzzzzzzzzzxz> ?wzx+  <br></br>
.?v???-        ^}}}vv}vvwwv?r}vv?????vvvvvvv??vvvvvvvvvv}}}}}}}}v| -vwzzw. <br></br>
.vvv?` .      >rvvvv}}rvv?+~`. +vr}}}>`~>??????????????????>>>>>` .vvwzzz^ <br></br>
.vv+...     .?r}vvvv??^-.      >r}}?~   .-+??vvv????????>>+^-.~> .v}vzzzz? <br></br>
`?~  .     .vwr}?>~`          ~w}?>++-     .`-~^+>>>>+>^-`.   ~||rr}}zzzzv`<br></br>
..        -vv>|.             .v?|???>>+`             ++      .+vzw}vzzzzz}`<br></br>
         .^-                 ^-  +???>>?>|`         .>~ ..`~^?vwwvvrzzzzz}`<br></br>
                                 .>??????vv?>+|~`-``~>^~+>>vwzzwrrrzwzzzz}`<br></br>
                                  .?????>???vvvv?????>????}zzz}rr}wwwwwzz}`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvv?rwwwwwwwwwrvvv+++vvv}vv???^>>>>?}???vv?>????}}}?v?+>vv+^.<br></br>
`zwwwwwwwwwwwwwwr}yyyyyyyyyyz}r????wuuuzrr}?????}ww?v?v?>???vwxxz}}>v?vr?+ <br></br>
`wwwwwwwrrrwrwwwv}yuuuyyuuuxwwv????zyuxwrr???vvrzzzv}}}????vr}vr}?>>r}rzww~<br></br>
`wwwwwwwrrrwwwwwvryuuyyyuyxwr}?v???zxwvv}?????}zzr}?vv+++++??>+^~-~?zxxxzx|<br></br>
`wwwwwwwrrrrwwwwvwuuuyyyyxwrr??v??>}vvvv>++|~-~````>v>-~^+>>???vvvvwxxxzzz|<br></br>
`wwrwwwwrrrrrwww?wuyyyuyxwrw??vv?+^vvvv^.`-~|+??v}rvvrxuuuyuyyyyyyuxzzxzzz~<br></br>
                  `wwrrwwwrrrrrrww?wuyyuuuwrwv??-...-?vvvvrxxxxuuuuuuvwyyyyyyyyyyyxwzxzzzzzz|<br></br>
`wwrrrrrrrrrrrrr?ruyyuuwrrr?vv^>}zv}rvw?|?uuuyyyyyyzyyyyyyyyyyuzwxxxzzzzzz|<br></br>
`wwrrrrrrrrrrrrrvruyyuwrrw??v?vvxuwv}rur^>wyyyyyyyyyyyyyyyyyyzwzxxxxxxxzzz~<br></br>
`wwwwwrrrrrrrrrwvrxyyxrrr}?vvv??}uuvruyyzrxyyyyyyyyyyyyyyyyxwwxxxxxxxxxzzz|<br></br>
.>?vv}rrrrrrrrrwv}zuuwrrr?v?vvvv?}zvxyyyyyyyyyyyyyyyyyyyyuzrzxxxxxxxxxxzzz|<br></br>
.~``-~+?rrrrrrrr}vzxzrrr}vw??vvvv??v}}wzuuyyyyyyyyyyyyyuzwzxxxxxxxxxxxxzzz|<br></br>
.~|>vwxuuxxzwrrrrvwwrrrw?vrr?vvvvvvvvv}r}}rwzxuuuyyyyuzrzxxxxxxxxxxxzzzzww~<br></br>
`}uyyyyuxzzwrrrrwvrrrrr}????v?vvvvvvvv}zxxzzzzzzzwzzzwzxxxxxxxxxxxxzwwwr}r~<br></br>
-yuuzzwrrrrrrrrrrv}wrrwv??????v?vvvvvvvvwzzxxxxxxxzzzxxxxxxxxxxxxxzwuyyyyy^<br></br>
-zwwrrrrrrrrrrrrr}vwrrr?????????vvvvvvvvvrzxzxxxxxxxxxxxxxxxxxxxzzryyyyyyy^<br></br>
`wrrrrrrrrrrrrrrrr?rrwv?????????????vvvvvvvwxxxxxxxxxxxxxxxxxxxz}ruyuxyyzy^<br></br>
`wrrrrrrrrrrrrrrrwv}rw?????vvv??v???vvvvvvvvrzxxxxxxxxxxxxxzzxx}?xyyuzyyrx|<br></br>
`wrrrrrrrrrrrrrrrr}?w}?v??vvvv??????vvvvvvvvv}zxxxxxxxxxxxxzxxzvvyyyzxuzrz|<br></br>
`wrrrrrrrrrrrrrrrrr?rv}rv??vvvv??????vvvv????vvwxxxxxxxxxxxxxxw??yyyzwwzzx|<br></br>
                  `rwwwwwwwwwwwwwwwwwrvvwwwr????vvv????v??vv??vvvvrzxxxxxxxxxxxxzv?wxwwzxzzx|<br></br>
`vv????????????v?>>>|^+^^^|~?????vr}}?????vvvvvvvvrzxxxxxxxxxxxxzwwzxxxzzx|<br></br>
.?????????????>|.         .vr}}vvruxz>?rr}??vvvvvvvv}rwzxxxxxxxxxxxxxxxzzx|<br></br>
`v??vvvvvvvvv>`          -}wrrrrvrzzw>vwrrrv???vvvvv??vvv}rwwzzzzzzxxxxxzx|<br></br>
`vvvvvvvvvv?~.          >}}rrrrrvvwww?vwwwr}>?vv?????????????vvvvvv}}}rrwz|<br></br>
.vvvvvvvv?~.          .?r}}rrrrr}vv}}v}vv??>>}rrr}v???????????????????>>??.<br></br>
.vvvvvv?~.           -vrrrrrrrrwww}?>|`.?v??rrwr?~`+?vvvvvvv?????????>+^~` <br></br>
`vvvv?|.            ^rrrwwwww}v?^-.     ???}r}v~    .-^>???vvvvv?>>^`..    <br></br>
`}v?~.            .?wrr}vv?+~`          ^?r}?>>^.        .``-~~-->|       .<br></br>
.^`              .?v>^-..               -}v>?>?>?|.             |?`    .`+`<br></br>
                 ..                    .+>??v????v?+-..        .>>..-|>?v}~<br></br>
                                       . ~?????>???????>^|~----~++^^>v}rwz|<br></br>


            </div>,

            <div>

                  ..............+rwwwwwwwwwwwwwwwrrrwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr-<br></br>
                  ..............vyyyyyyyyyyyyyyyyuuuyyyyyuuuuuuuuuuuuuuuuuuuuuyyyyyyuyuuuuu|<br></br>
                  .......```````wuuuuyyuuyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu~<br></br>
                  .````````````-xuuuyyuyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxuuuuuuuuxxxuuuuuux~<br></br>
                  .```````````.~uuuuyyyyyuuuuuuuuuuuuuuuuuuxxxxxxxxxuuuxxxxuuuuuuuuuuuuuuuu|<br></br>
                  .`..`````````.~uuuuyyyyyuuuuuuuuuuuuuuuuxxxxxuuuuuuuuuuuuuuyyyyyyyuuuuuuuy|<br></br>
                  -w?|`..``````.~uuuuyyuuuuuuuuuxxxxxuuuuuuuuuuuuuyyuuuuyyyyyyyyyyuuuuuuuuuy|<br></br>
                  -uyuw?|`...``.~uuuuyyuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyyyuuuuuuuuuuy|<br></br>
-uuuyyuzv>|``.-xuuuyyuuuuuuuuuuuuuuuuuyuuuuyyyyyyyyyyyyyyyyyyyyuuuuuuuuuuy|<br></br>
-uuuuuuyyyuzv>|ryuuyyuuuuuuuuuuuuuyuuyyyuuuyyyyyyyyyyyyyyyyyyuuyuuuuuuuuuy|<br></br>
                  -uuuuuuuuuuuyyuxuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyuuuuuuuuuuuuuuuuuy|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyuuuuuuuuuuuuyyuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyuuuuuuuuuuuuuuxzzw~<br></br>
-uuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuxuuuuuuyuuuuuuuuuuuuuuuuuuuuuuuuuuxzzwwww}~<br></br>
                  -uyuuuuuuuuuuuuuuuuuuuuuuuuuuuuurxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxzwwwwwzrw~<br></br>
                  -uyuuuuuuuuuuuuuuuuuuuuuuuuuuuuurwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxzzzzrwz~<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuxxxuuuuzxuwrxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyuxwxx~<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuzwwzxxzwxxwwxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyyzuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuxwwwwwrwxuzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuyyyxxyuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuuuzwrrrrwzuxxuuuuuuuuuuuuuuuuuuuuuuuuyyuuyyyuwuuuu|<br></br>
`uyuuuuuuuuuuuuuuuuuuuuuuuuuxzzwwwzxuuuuuuuuuuuuuuuyuuuyyyyuuuuxxxxx}wzwzz~<br></br>
                  .vuuuuuuuuuuuuuuuuuuuuxxxxxuuuuuxxxxuuuuuuuuuuuuuuuuuuuuxxxzzzwwwwwrvwwwwz~<br></br>
.?}uuuuuuuuuuuuuxxxzzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuxxzzzwwwwwwwwwwzvrwwwww-<br></br>
`v?}uuuuuuuuuuxxzzzzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuuuxxzzwrrrrwwwwwv?}vvv??`<br></br>
.vv?}uuuuuuuxxxxzzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxzzwv????>???vv??-<br></br>
.vvv?vxuuuuxxxxxzzzzzzxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxw}v>?}}}}vrr`<br></br>
`rrrr}}uuxxxxxzzzzzzzxuuuuuuuuuuuuuuxxxxuuxzzzzzwrrwzxxxxuuuuuuuu?v}rzrrw- <br></br>
`uuuuuuxxxxxxxzzzzzxxuuuuuuuuuuuxxxxzzzxuuxrrrrvv???vv}}rrwwwzzxw?zrv??r~ .<br></br>
`uuuuxxxxxxxxxzzzzxuuuuuuuuxxxxzzzzzzzzxxuxrrrrv??????????vvvvv}?vrv??v>->|<br></br>
`xuxxxxxxxxxxxzzzxxuuxxxzzzzzzzzzzzzzzzzxuxrrrrv>?>>>>>>>+>+>>>>>>>>^?++?v-<br></br>
`xxxxxxxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzuuzrrrr}???????????????????>?| -rz~<br></br>
`xxxxxxxxxxxzzzzzzzzzzzzzzzzwwwwwwwwwwwzzzwrr}}v????wwwwwwwwwwwvvwv}v. ^vx~<br></br>


            </div>,

            <div>

 ..............>wwwrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
                  ..............vyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy^<br></br>
                  .............`wyuuyyyuuuuuuuuuuyyyyyyyyyyyyyyyyyyyuuyyyuuuuuyuuuuuyyyyyyu|<br></br>
                  .............-xyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuyyyyyyyyuuuuyyyyyux~<br></br>
                  .............~uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  .............~uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuyyuuuuuuuuuuuyy|<br></br>
                  -w?|`.........~uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuyy|<br></br>
                  -yyuw?|`......~uyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuyy|<br></br>
-uyyyyuzv>~`..-xyyyyyyyyyyyyyyyyyyyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy|<br></br>
-uyuuuyyyyuzv>~}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy|<br></br>
                  -uyuuuuuyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy|<br></br>
                  -uuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuu|<br></br>
                  -uuyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyyuuuxzzw~<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuxuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuxzzwrrw}~<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyuuyyuwuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuxzwwrrrrwrw~<br></br>
-uyyyyyyyyyyyyyyyyyyyuuuyyyuuuuurwuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuxxzzzww}rz~<br></br>
                  -uyyyyyyyyyyyyyyyyyyyuxxuuyuuzxuwrxyuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuyuxwxx|<br></br>
                  -uyyyyyyyyyyyyyyyyyyyuuxwwzxxzwxuzzuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyzuyy^<br></br>
                  -uyyyyyyyyyyyyyyyyyyyuuuxzwrwwrwxuxxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxxyyy^<br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyuuxwrrrrwxuxuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuwuuuy|<br></br>
`uyyyyyyyyyyyyyyyyyyyyyyyyuuxxzzwwzuuuyyyyyyyyyyyyyyyyyyyyyyuuuxxxxz}wzwzx~<br></br>
                  .vuyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuxxxuuyyyyyyyyyyyyuuuuuuxxzzwwwwwrwrvwwwwz~<br></br>
.?}yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyuxxzwwwrrrrrrrwwwvwwwwww-<br></br>
`vv}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuxxzwwwrrrrrwwv?}vvvv?`<br></br>
.vvv}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuxzw}????>???vv?v-<br></br>
`vvvvvuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuxw}v>?rr}rvrr`<br></br>
`rwwr}}uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuzzzzzzzzzxxuuuuuuuuyuu?v}wzrww~ <br></br>
                  -uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxrrrrrwwrrrwwwwwwzzzxxw?xzrvvz~ .<br></br>
-uuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxrrrrrvvvvvv}}}}}}}}}r?vww}?}?->|<br></br>
-uuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxrrwwv????????????????>???>?+??v-<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyxrrww}?vvvvvvvvvv}}}}v?v???| ~rz~<br></br>
-uyyyyyyyyyyyyyyyyyyyyyyyyyuuxuxxxxxxxxxuuzrrrr}???vwzwwzzzzzzw}vz}}}. ^vx~<br></br>


            </div>,

            <div>

`vvvvvvvv}v??v?v}}}rrrrrrrrrrrrrrrrrrrrrrr}vvvv}}vv}}}}}}}}}}}v?????????vv`<br></br>
`zzzzzzxzr}}wrrzuuuuuuuuuuuuuuuuuuuuuuuuuuuuzwwwzxzrwxuuuuuuuuxzrvvvvvvv}}`<br></br>
`zzzzzz}vv}}}rrxxxxxxxxxxxxxxxxxxxxxxxxxxxxxuxzzwwzxwrwxuuxxxxxuxrvvvvvv}}-<br></br>
                  `zzzzrvvrrrrrrrxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxuxzwrwzxzrwzuuxxxxuuzvvvvvvv`<br></br>
`zzrvvrrrrrrrrrxuuuuxxuuxxxxxxxxxxxxxxxxxxxxxxxxuxzwwwzxz}rzuuxxxxzz}vvvvv`<br></br>
`rvvv}rrrrrrrrwxzwwwzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxuuzwrwzxzrrzuxxxxzr}vvvv`<br></br>
`zzwrrrrrrrrrrrr}rwzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxuuzwwwzxwrwxuuzwrr}}v?.<br></br>
-uuxxzrrrrrrr}vzxxxuxxxxxxzxxxxxuuxxxxxxxxxxxxxxxxxxxxuxzwrwzxzwwxxzr}}}vv`<br></br>
-uuxxuxxzwwwr}wxuuxwwwwxxxxuuxxxrrxuuuuxxxxxxxxxxxxxxxxxxxzwrwxxzwwzxw}vvv-<br></br>
-uuxxxxxxxxxzwwxuwrrzxxyyyyyyyz?`.`^?}xuuuxxxxxxxxxxxxxxxxuxzrrwxxxzxxzr}v-<br></br>
-uuuuuxxxxxxuxxuwrzuyyyyyyyu}^. ...  .`^?rzuuuxxxxxxxxxxuuuuuxzwrwxuuxxzr}-<br></br>
-xzwwwzxxxxxxxuzrwxyyyyyyy}~. ........  .~?vrwxuuuxxxxxxuuuuuuuxzwwzxxxx}v-<br></br>
`rr}vvwxxxxxxxxrxuuyyyyyr^............`^???????vrzxuuzzxxuuuuuuuuxzwwzxx}v-<br></br>
`}}}}}rxxxxxxuzzyyyyyyu?. ...........~??????????vvvrwzwwwzxxuuxuxxuxwrzxrv-<br></br>
`vv}}}wxxxxxxxwyyyyyyw~ ............^??????vvv}}}}}}vv}rr}}wzxxxxxxxxzwwrv-<br></br>
.?>+?wxxxxxxuwxyyyyyv. ............~????vv}}}}}}}}}}}}vv}rr}rwwzxxxxxxxz}v-<br></br>
`v}??xuxxxxxxzyyyyy?...............>????v}}}}}}}}}}}}}}}}}vvvvrwzxxxxxxxw}-<br></br>
`wwzxxxxxxxxwuyyyy?................>????v}}}}}}}}}}}}}}}}}}}}}v}wzxxxxxxz}-<br></br>
-xuuxxxxxxuzxyyyyv.................>????v}}}}}}}}}}}}}}}}}}}}}}vwwzxxxxxzv-<br></br>
-xuuuxxxxuzwyyyy}`.................>????v}}}}}}}}}}}}}}}}}}}}}}vxzwxxxxxzv-<br></br>
-xuuuxxxuxwuyyyw`.`.............. .?????v}}}}}}}}}}}}}}}}}}}}}vvxuxrzzxxzv-<br></br>
-xxuuxxxxwxyyyx|..``. .............?????v}}}}}}}}}}}}}}vvvvvvvvvzuuzrxzzwv-<br></br>
`xxxuxuxwxyyyu+`^?zzv|............`??????v}}}}}}}}}vvvvvvvvvvvv?zuuurxzz}v-<br></br>
`zzzxuxwxyyyuw}zuuuuuu? .........^??>???v}}}}}}}}}}}}vvvvvvvvvv?zuuurwzwv?`<br></br>
-zzzzzzuyyyuzzuuuuzxxu+ ......`+????>???v}}}}}}}}}}}}}}}}}vvvvv?wuuurrwrvv-<br></br>
`zzrrxyyyyuuxxxzuxwuuv.......^???v??>???v}}}}}}}}}}}}}}}}}}}vvvvxuuuwwzwvv`<br></br>
`xxzwzxyyyuxxxzzuwv?+`.`....^???v}v?????v}}vvvvvvvvvvv}}}}}}}}}xuuuyzwzzr?.<br></br>
-xxuuxzzuyuxuxrz}~...`````..??vv}}}?????v}}vvvvvvvvvvvvvvvvvvvzuuuuywwzz?>`<br></br>
-xxuuuuxzzxzr>-`..``````````?v}}}}}v????v}vvvvvvvvvvvvvvvvvvvwuuuuuuwzzz??-<br></br>
`xxuuuuuuxz?`..`````````````?v}}}}}}v????}vvvvvv????????????}xxuxuux}zwwvv-<br></br>
-zzzxuuuxxuuw?~`````````````^v}}}}}}}}vvvvvvvvvv???????????}xuuuuuuwwwv}vv-<br></br>
`xxxzzxuxxxxuuxv~````````````?}vvvvvvvvvvvvvvvvv?????vvvv?vzxxxxxxxwwvvvv?.<br></br>


            </div>,

            <div>

`}rrrrrrrwrv?}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v?vv}}??v}}}}}}}}}vv?>>>>>?>???.<br></br>
-uyyyyyyyzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzwrwzxw}rxuuxxxxxxxw}?vvvvvvvv`<br></br>
-uyyyyuzrzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzwrrwzw}rzxxxxxxxxz}??vv?vvv`<br></br>
-uyyuzrzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzrrrzxw}rzxxxxxxxxw??vv?vv`<br></br>
-yyzrzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzwrrzxwv}zxxxxxzwwv?????`<br></br>
-zrzxxxxxxxxxxxzrr}rwwzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzwrrzxw}rzxxxxzr}v????`<br></br>
`zxxxxzzzzxxxxwvv}wzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzrrrwxw}rzxxwr}vvv??.<br></br>
-uxxxzzzzzxxxzrwzxxxxxzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxzrrrzxwrrzxw}vvv??`<br></br>
-xxxxxxxzzzzzzzxxxzwr}}zxxxxxxxx}}zxuxxxxxxxxxxxxxxxxxxxxxzw}rzxwrrzz}vv??`<br></br>
-xxxxxxxxxzzzzzxxw}}wzxyyyyyyyz?. .|?}zxxxxxxxxxxxxxxxxxxxxxz}}wzxzwzxw}vv`<br></br>
-xxxxxxxxzzzzxxxrvwuyyyyyyyu}|.       .~>}zxxxxxxxxxxxxxxxxxxxzrrwzuxxzzvv`<br></br>
`zw}}vwxxxxxxxxzv}zyyyyyyyv-             `+?vrzxxxxxxxxxxxxxxxxxwrrwxxzzvv`<br></br>
`vvvvv}zxxxxxxx}xuuyyyyyr|            .~+>++++>?rwzxxzzzxxxxxxxxxxzrrzzzvv`<br></br>
`vvvvv}zxxxxxxwzyyyyyyu?             ->>>>>>>>>>??v}rzwr}wzxxxxxxxxxr}wxrv-<br></br>
.??vvvrzxxxxxxwyyyyyyr-             ~>>>>>????????????v}}vv}wzxxxxxxxzrr}v`<br></br>
.>|~?}zzxxxxxrxyyyyu?.             `>>>>????????????????vvvvv}rzxxxxxzzzv?`<br></br>
`vv??xxzxxxxzwyyyyu?               |>>>>????????????????????vvv}wzxxxxxxrv`<br></br>
`rrzxxxzxxxxruyyyu>                ^>>>>???????????????????????vrwzxxxxxwv`<br></br>
`zxxxxzzxxxwzyyyy?                 +>>>>??????????????????????vvrrzxxxxxzv`<br></br>
`zxxzzzzxxzwyyyyv.                .+>>+>???????????????????????vxzrzxzxxwv`<br></br>
`zxxzzzzxxruyyyw.                 .+>>+>????????????????????????xux}zzzxw?`<br></br>
`zxzzzzxxrxyyyx-  ..              .^+>>>????????????????????????zuyz}zzzr?`<br></br>
`zxzzxxxrxyyyu^.~?wzv~            .|^+>>????????????????????????zuuu}zzzv?`<br></br>
`xxzzxzrxyyyur}zuuuxuu>         .`^^^+>+>???????????????????????zuyywzzzv?`<br></br>
`xzzzzzuyyyuwwuuxxzxxu^       .~+>>+^+>+>???????????????????????zuyywwzwv?`<br></br>
`xxrwxyyyyuxzxxwxxruuv.      ~++>?>>++>+>??????????????????????vuuyywwzwv?`<br></br>
`xxzwzxyyyuxxxwwur??^.      ->>>???>++>>>?????????????????????vxuyyyzwzz}?`<br></br>
`zxxxzwzuyuxxxrwv`          ^>?????>+>>+>?????????????????????zuyyyywwzx}?`<br></br>
`zxzzxxxzzzw}+..            ^???????>+>+>????????????????????ruuyyyurzzzv?-<br></br>
`zxxxxzxxxz>.               ^????????>>+>???????????????????ruuyyyyurxzzvv-<br></br>
`zzzxxxxxxxxw>`             `???????????>??????????????????}uyyyyyyzwxxzv?`<br></br>
                  `zxzwwzxxxxxxxw?`            +????????????????????????????vxxxxxxxxwzzzwv?.<br></br>


            </div>,

            <div>

`+|wwr}}rrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}vvvv???vvv}}}}?-````........... <br></br>
-wruuyyyyyuyyuxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzzw}vv}wwzzr>~-----``......... <br></br>
.vzzzxyyuuuuuzzxxxxxxxzzzzzzzxxxxxxxxxxxxxxxxxzz}vv}ww>`.`-------`........ <br></br>
                  +uyyuuuuuxxzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzrvvv^....`-------`....... <br></br>
 vyuuuuuxzzxxxxxzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxz}v-.....`~~----```..... <br></br>
                  vuuuuxxxxxxxxxzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxzr?......`------``..... <br></br>
                  `zxuxzxxxzxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxzr+.....``-----``..... <br></br>
                  -xxxzxzzzzxxxzzzzzzzzzzzxxxzzzzzxxxxxxxxxxxxxxxxxxxxxwv?+`...`-----``..... <br></br>
-xxxxxxxxzzzzzzxzzxxxzxxvvzxxxxxwwzxxxxxxxxxxxxxxxxxxz}v}v?>^~````---`.... <br></br>
-xxxxxzwzzzzzzzzxuuuyu}+..`|?vwr???v}wxxxxxxxxxxxxxxxz}^>v}}}vv>~-``--``.. <br></br>
-uxwr}}}wzzzzxzxuxuyr^....... ..-^>>??vv}wzxxxxxxxxxxw}-.->v}}wzw}?^~--`.. <br></br>
.>?vv}}}wzzxxzzuuux>`.```..      .`-^>???v}rwzzxxxxxxwv~...-?}}rzxxzr?+~`..<br></br>
`+..-^?vwzxxzzuuyz|.````.      ..`-~~|^?v}}}}}rrwzzxxwv|.....+v}zzxzzzzr?~.<br></br>
`rv|--+}xxxxwxuyw`.```..       .``||^>?????v}}}}rrwwzrv?-.....|vwxxxzzzxzr-<br></br>
`rrwwwzxxxxzxyyr~````.         .``~^>????????v}rrrrrrvv??......^}zxxxzzzww~<br></br>
`}vv}zzxxxzzuyw~~```.          .``|+??????????vwrrrrrv??v~......v}wxxxxzwr~<br></br>
`rr}rzxxxzzyyz~`~-`.           .`-^>???????????zxzwrrv??v>......~}}wxxxzww~<br></br>
`rwzzzzzzuuuuvvr>~.          ...`~^>???????????rxxzrr?????`......?vvwxxzww~<br></br>
`zxxzxzwuyuuxxuuy?         ...``-~^????????????zxxwrr????v|......~vv}zxzww~<br></br>
`zxxxxxzxuyuuuuuv`        .``-`-~|^???????????rxxxrw}????v+......`?vv}zxww~<br></br>
`zxzzzzzzzrxuyz^          .`~||~||^??????????rxxxr}wv?????>.......?}vvwxww~<br></br>
`zxzzzzzxxzwv?-           `~||||||^?????????rxxur}rrv?????>.......?}vvvwww~<br></br>
`zxzzzxxxxuuxr^...```.    `~|^||||^????????rxxuw}rr}v??v?v>.......?rv}}rww~<br></br>
`xxzzxxzxxuuxxzwv^````.   .~|^||^>>>??????wxxxr}rrr}v?v???+......`vrv}v}ww~<br></br>
`xxzzxxuuuuxxxxxuxr>~`.    `~|^>????>??>?zxxxr}}}rrv???????|`....`}rv}r}}}~<br></br>
`xxxxxuuuuuxxxxxxxuxz?`     `+>??????>>?wxxx}}}v}rrv?????????^`..^wrvvxxvv~<br></br>
`wzzzxuuuuuxxxxxxzwr}v?>~.  .>???????>vzxxz}rr}rrr}v????+-`~>???+?rrv}uxvv~<br></br>
`w}vrwzxuuuuuxxxwv????????^``v}v????>vzzzwrrrrwrrr}????~.....`+v}v}rvruz}v~<br></br>
`x}v}wrrwzzzz}v?????????????vvrwr}v?rxxzr}wwwwwwr}v??>`.......`?vv}}vwuz}}~<br></br>
`xwv}zzwwrrv????????????????}rrrrwxzzzzv}wwwwwrrv???+` ......`?vvvrrvzuwv}~<br></br>
`wrvvwzzzzzr}?>>????????????vrrwrrrrrrrrrrrr}vv???>~........`?vv}rw}vxuwvr~<br></br>
`wwrrwwzzzzzzzw?`|>??????????v}rrrrrrrr}vvv????>??^........~??vrrrrv}xxwvv`<br></br>


            </div>,

            <div>

`}}r}?+??>`}rrrrrrrrv}}}}}}}}}v?>???vv|...............       `.>vvvvvvvvv?`<br></br>
`r}rzvv}v^^ryyyyyyyxzxxxxxxxxxxw?}zzv-.................      `^}wwwwwwwwwr-<br></br>
.?vvvv?v?^?}uyyyuzwzxzzzzzzzzzzxwvv|...................       +wwwwwwwwwww~<br></br>
`rzzxz}r>>uyyyuzwzxxzzxxzzzzzzzzxz^ ..................       .^rwwwwwwwwww~<br></br>
`rzxxx}w|wyuxzwzxxxzzxxxxxxxxxxxxx>  .................       .-vwwwwwwwwww~<br></br>
`wzzwr}}?uzwzzxzzzxxzxxxxxxxxxxxxz?^` ................       ..>wwwwwwwwww~<br></br>
.}xzzrrxxxzxxxxxxxxxxxxxxxxxxxxxxw^+v+`................      ..+wwwwwwwwww~<br></br>
.vrw}wxxxxxzzxzzzzwzxxzzxxxxxxxxxr~ |vv>`................    ..+wwwwwwwwww~<br></br>
.>+>vzz}rr}zxwzyyr-`^?wxxzzzxxxxx}+  +vrr^.................. ..^rwwwwwwwww~<br></br>
.|>zxzx?-+rxzwyx>.    .|?vrwwzzxzv?` .?vzu?....................`^rwwwwwwww~<br></br>
`vzyzwzzrrzzwy}`      .+>>>vrrrwr?v-  >v}xxv`..........   ......`>wwwwrrww~<br></br>
-uuurzzzzzzzu?        ~>>>+?}rrrv>?-  ^vvwxx}`..........    ....-~+zzwr}}}-<br></br>
-uux}wwzzrzyw?`..   .|>>>>>rz}rr???`  ~vv}zzx}`.........     ...-`.^wzzrrr-<br></br>
-uyw}wwzzzzzzv...  `>>>>>>}uwvww???.  |vv?rzzx}.........       ....-?wxzww~<br></br>
`zzzxxxxxxxxz}+.   ~?>>>>rurvrw}>?+   >v?v}rzzx?.........       ...->+?rwz~<br></br>
`zxxzxxxxxxxxxx}^..?>>+?zx}vv}r?++>~..??vur}zzzx|.... ....      ....~^.~^v|<br></br>
`wzzzxxxxxxxxzwwr}?v?>vxzvrv?v?+`.`+++??wzvwzzxzv. `??. ..       ....`..~`.<br></br>
.vv}wzxxxxxxxxzzwwwrrrw}v}v??+`.    ~>>vu}}zzzrwu>|zyyr- .       .......-+.<br></br>
.????vrwzxxxxxxxxzr}}v?>>+++|^.   .~>??wxvzzr}wxzzxwrwuu>.        .......^`<br></br>
`v}xw??vv}rrr}}v?>|~-.....   .~~`~+????zv}w}rwwzuz}}v??uy^        .........<br></br>
 >wwwv?^?vv}+...    ...`....`~^>>>>?????}rwzwwxyxrzzv??x>.        ........ <br></br>
 .+rrw> .+}w?         .`^>???????vvv}}}rwwrwxyyyurw}?vv- ..       ........ <br></br>
 ..^wz~   .~?.           .-+?}rwwwrrrwwwrwxyyyyyyuxz}^. ...       .....    <br></br>
 ...~r- ... .....           .-+?v}wwwrwzuyyyyyyyyuxr~ .....       .......  <br></br>
 ....`..................  ....  .`+rzxxxuyyyyyyuzrwz> ......      ........ <br></br>
 .......................    ...->vzxwrvvvruyyuzrwzzxv ........    ...... . <br></br>
 .........................  ...vuyy}wwwrvvur>|>?v}wz}....   .... .......   <br></br>
 ...............................`?wxzwrr}?~. .....```...       ........... <br></br>
 ................................ .|v}?^. .........                ....    <br></br>
                  ...       ........................... .............                  .    <br></br>
                  ...       .    ..`|^|-`...........................                        <br></br>
 ............`|>?vrwzzwrv??^`......................                        <br></br>


            </div>,

            <div>

`rrr}?+?>+`}rwwwwwwrv}}}}}}}}}v?>???vv|                      -.?}vvvvvvvvv-<br></br>
`rrrzvv}?~|ryyyyyyyxzxxxxxxxxxxw?}zzv- ....          .       `>wxzzzzzzzzz~<br></br>
.?vvvv?v?|?}uyyyuzwzxxzzzzzzzzxxwvv~                         .>zxzzzzzzzzx|<br></br>
`wzzzz}r>>uyyyuzwzxxxxxzzzzzzzzzxz^                          .>wxzzzzzzzxx|<br></br>
`wzzxx}w~wyuxzwzxxxzxxxzzzzzzzzzxx>                          .-}xzzzzzzzxx|<br></br>
`wxzwr}}?uzwzzxzzzzzxxxzzzzzzzzzxz?|.                        ..>xzzzzzzzxx|<br></br>
.}xzzrrxxxzxxxzzxxxxxxxzzzzzzzzxxw^^?^.                     ...>xzzzzzzzxx|<br></br>
.vww}wxxxxxxxxzzzzwzxxxxzzzzzzzxxr- |vv>.                    ..>xxzzzzzzxx|<br></br>
.>+>vzz}rr}zxzzyyr``^?wxxxxzzzzxx}^  ^vrr|                    .+zzzzzzzzxx|<br></br>
.|>zxzx?`^rxzwyx>.    .^v}zxxzzxzv?. .?vzu?.                 . `+wzzzzzzxx|<br></br>
`vzyzzxxrrxzwy}`      .>???rxxzxw?v-  >v}xxv`                   .>wxzzzzzz|<br></br>
-yyyrzxxzxzzu?        ^????vwzxz}??-  ^vvwxxr` .                `-^zxwwwww~<br></br>
-uyurxxxzrzyw?`     .+?????wuwxxv??`  ~vv}zzxr.                 ``.^wxzwww~<br></br>
                  -yyzzxzxxxzzzv.    -??????ryxwxz???.  |vv?wxzuv.                ...`?wxzww~<br></br>
`zzzxzzxxxxxzv^.   ^?????wyzrzx}>?^   +v?v}rzzx?                   `++>rwz~<br></br>
`zxxzzzzxxxxxxxr>`.????vxuwrrww?+++- .??vur}xzzx~ .    .            -|.~|v|<br></br>
`wzzzzzzxxxxxxxxxz?rv?ruxrzw}rv+` .|^^??zzvzzzxzv. `??.            ... .`..<br></br>
.?v}wzxxxxxxxxxxxxxzzzxzrwrv?+`     ->>vu}}xzzrwu+|zyyr-                -+.<br></br>
.????vrwxxxxxxxxxzwwwrv??>>+|~.   .~+??wzvzzw}wzzwxwrwuu>.              .^`<br></br>
`v}xw??vv}}}}}vv?>^|~`.....  .`-`-+>???zvrw}rwwwuz}}v??uy|              ...<br></br>
 >rwwv?^?vvv^..      .``....`-^>++>>????}rrwrwxyxrzzv>?x>.                 <br></br>
  ^rrw> .^vw?         .-^>???????vvv}}vrwwrwxyyyurwv?vv-                   <br></br>
   ^wz-    ~?.           .-^?}rwzwwrrrwrrwxyyyyyyuxz}^.                    <br></br>
  . ~r-      .              .-+?v}rwrrwxuyyyyyyyyuxr~                      <br></br>
     `.                          `+rzxxxuyyyyyyuzwwz>                      <br></br>
                              .`+vzxwrvvv}uyyuzrwzzxv                      <br></br>
                              .vuyy}wwwv>?xr>~>?v}wz}.                     <br></br>
                                `?wxzwr}v?-.     ..``.                     <br></br>
                                  .|v}?|.                                  <br></br>
                  <br></br>
                  .`~^|-..                                                  <br></br>
        .  ..`|>?vrwwww}v?>|`.                                             <br></br>


            </div>,

            <div>

.+?r}?+>??|v}}rrrrrrrrrrrrrrrrr}}}}vrr?>>>>+++++^||^^^|~--~~^>>vv>^^^+++||.<br></br>
.+vww}vrr?>wxuyyyyyyuyuuuuuuuuuuxxuuwv??????????>>>>>>>^||||^?wzv????????v`<br></br>
 |?}}vvvv>vwzxuuuuuuuuuuuuuuuuuuuzw??>>>>>>>>>>>>>>>>>>>^|||~^>????????vxv.<br></br>
.>rzzw}r??xxxuuuuuuuuuuuuuuuuuuuuuv~+?>>>>>>>>>>>>>>>>>>^||-..`>?????vzx}?`<br></br>
.>}zzzvw+rxxuxxuuuuuuuuuuuuuuuuuuuv~|>>>>>>>>>>>>>>>>>>>+~`. ..+???vrzzv^^.<br></br>
.>vzww}r?xzzzuuuuuuuuuuuuuuuuuuuuuwv>^>>>>>>>>>>>>>>>>>+^`.  ..-????}}+|||.<br></br>
.^?rzwwzzzzzzxuuuuuuuuuuuuuuuuuuuuvvzv>>>>>>>>>>>>>>>+|-`.. ...`++>>>>??^^.<br></br>
 |+?wwzzzzzzzzzxxuxuuuuuuuuuuuuuux?|?zz}>+++>>>>>>>>^-`.     ..`+?v}r}?>|~.<br></br>
.|~~vzzrwwrzxzzuuz>+?}xuuuuuuuuuuxv||vzxzv>+^+++>+^|~`````````-+}zzxr?>^~|.<br></br>
.>?}}zzv^?wxzzuz?|~~~|^vzxuuuuuxz}v^~>wwxu}+>^~||~~~~~||~|^^^+>?}zw}v?v?>+.<br></br>
.vwxwzzzrwxzzu}~````--^ww}vzuuuuz?v~.`?}wuur?>+^~~~~~~||~|+>>++>>?}rrwwwrr~<br></br>
`wxxwzzzzxzzxv```.....?rwr?vrzzxzrr|..^?vrzxw?>>|~~||||^++>>>+++??vwzwwwww~<br></br>
`wzzwxzzzwwxw?~`````~>??vr}wx}}rvv}^..^v?v}}rv^+^||^^+>>>>>>++>>????rwwwww~<br></br>
`ww}}zzzzzwwwv~````>}}vvvvwuzwzr>+>`--?}}v}}}w}^|^+>>>>>>>>>>++++++?vwwwww~<br></br>
`v}}}rwwzzww}?|`.`~v}}}}}wxz}}vv?rv~~|v}rwrrrwzv^+>>>>++>>>++++^^^^>vv}wwz~<br></br>
`}rrwwwzzzzr}xzv^``vv}}rxz}}v?zzr}}?^+}}rxwrwwzz?+>>>+++++++++++++++????vr~<br></br>
`}}rwwzzzzzr}wzux}vvvvwxz}}wzww}>^>vvv}}zz}wzzzzw?>?rv>+++^^^+++^^^++>>>??`<br></br>
.?vv}rwzzwzzrrwwxuuuwwxzzzzr}v>^~~~|?}vrxwrzzzzzuv?zuuw?^^^^^++++++^^^^+?v`<br></br>
.?vvv}rwzzzzzzwwwwwrr}rrr}}v?>-``-^?v}}zxrzzzwzxzzxzzzuxv>>>>>>>>>>+++++>v-<br></br>
`}}zwvv}}rrrrrvv?>>>+-^+++^||+??>?v}}r}xwwzwzzzzxxzzwrruuv>>>>>>>>>>>>>>>>`<br></br>
.?rww}v>v}}}>~-``...-||^^^^+>?v}}}}}}}}rwzzzzzxuxzxxwrrxv++>>>>>>>>>>>>>^^.<br></br>
 ~?rrr?.->vw?.````...`-|>?}rr}}}rrrr}rwzzzzzxuuuuzzwrwr?+>>>>>>>>>>>>++++^.<br></br>
.~~>rw^``.`^?-````.......`^?v}wzzzwvvwzzzxuuuuuuuuxxw?++>>>>>>>>>>>>>+^|~~.<br></br>
.~~~>r+~~--------`````. ...``|>?vv?^vzxxuuuxuuuuuuxz?+>>>>>>>>>>>++^^^^^-`.<br></br>
.~~~~^|~~~~~~~~~~~~~~~-`      . ..~?wxxxuuuuuuuuzzzzv>>>>>>>>>+++++^^^||~-.<br></br>
.~~~~~~~~~~~~~~~~~~~~~~~`.     .`>wzzzwrrwuuuxzzzxzz}+>++^^^^^^^^^^^^^^|~~.<br></br>
.~~~||||~~~~~~~~~~~~~~~~~~`   .|??rrzzzw}rxwv?v}rrww}^^^^^^^^^^^^^^^^^^^-`.<br></br>
.~~||||||||~~|||~~~~~~~~~~~`.   .~?wxzzww}?++++++++>>^^^^^^^^^^^^^^^^^^~``.<br></br>
.~~~~--~~||||||||||~~~~~~~~~-.    +vrwr?>++>>++^^^^^^^^^^^^|~~~~~~|||^^~``.<br></br>
.|||~````-----~~~||~|~~~~~~~|-.   .~+>>>>>>>>+^^^^^^^^^^^^^^|~--------~~``.<br></br>
.~~~----~~~~~~--~|+???+^|||~~~~.    `+>>>>>++++^^^^^^^^^^^^^|~----------``.<br></br>
 `~|||||||||^+??v}rwwwrr}vv?+|~~`.   ~++++++++^^^^^^^|||||||~-````````--``.<br></br>


            </div>,

            <div>

  ~||~~~>vvvv>?}}}}rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}v>>>>>>>>>>>>^......... <br></br>
 .|^^^+>}rrwwrzuuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyyyuuxv???????????+`........-.<br></br>
 .|^^>vvrrrrrrzxxxuuuuuuuuuuuyyyyyyuuuuyuuuuuuuuuuw?????vvrr?>-........~>` <br></br>
 .|++?vvrrrrrwzxxxuuuuuuuuuyyyyyyyyyyyyyyuuuuuuuuxvv}wzxuyz?|........`^>^. <br></br>
 `>}v?vvrrrwzzxuuxuuuuuuuuuyyyyyyyyyyyyyyyyyyyuuuuuuyyyyu}>-........`||-.  <br></br>
 ~+}vv?vrrrrwxxuuuuuuuuuuuuyyyyyyyyyyuuyyyyyyyyuuyyuuyyz?~`............ .. <br></br>
 -~vrv?vwrrrrwxuuuuuuuuuuuuyyyyyyyyyyuuuuuuuuuuuyyyuyur>-......     ..--...<br></br>
 ``>rvvvrrrrrrwzxuuuuuuuuuuuuuuuyyyyyuuuuuxxuuuyyyyux}>`...      .~>??|```.<br></br>
 -~~vr}vrrrrrrrrwzxuuuuuuuuuuuuuuyyyyuuuuuuxxuuuuuuz?|......````>}rr?|---`.<br></br>
.vv??rrrrrrrrrrrwzxuuyuuuuyyuuuuzzxuuuuuuuxxuxxxxxw>`````-~|>??>}rv++++^~-.<br></br>
.?vv?vrrrrrrrrrrwwrwzxuuuxzxuyyyxvvrwwwwxuuxxuuzzv^~~|+>?v???v?>v???v}r}v}-<br></br>
.^>????rrr}rrrv}}vvv?v}zux}?vwzxuuzwr}v}rwzxxxuuwv}rwwzzw}}}}vvvvv}rwwwwwz|<br></br>
.|^+??>?rwvv}vv}v?}}vvvv}wzr}}?vzxzw}vv}rr}}}rwxxzzzwwwwwwwwwrrr}}rrrwwwww~<br></br>
.|^|^>?^?rvvv?vv??v}rww}v}rzrrwrvvvrwwwwwwr}vrwwwwwwwwwwwwzzzwr}}}}}rrwwww~<br></br>
.|^^|+??????>+^~^+?v}zzzwwww}}}}rwzxxzzxzww}}wwwwwwwwwwwzzzzww}}}}rrrwwwww~<br></br>
.^+>???v}rv??}vv?+~+v}wzzr}r}vzuxxxxxxxzzwwrrwwwwwwwwzzzzzzwwr}}rrrrwwwwww~<br></br>
.>>>??vvvvrv?vvzr}r?v}vwwrrzxxuuuxxxxxxwwwrrwwzzzzzzwwwwwwwr}}rrrrrwwwwwww~<br></br>
.?????vvvv}}???v}wxuw}wzxxxxxxxxxxxxxxxzzwwzzzzzzzwwwwwwrr}rrwwwwwwwwwwwww~<br></br>
.vvvvvv}vv}}}v???vvvv}wzzzzzzwwwzzzzxxxxzzzzzzzzwwwwwwwwwwwzzzzzzzwwwwwwww~<br></br>
`v}}}}}}}}}}}}vvvv??}vwzzxxxxzzzzzzzxxxzzzzzzwwwwwzzzzzzzzzzzwwwwwwwwwrrrw~<br></br>
`vvvvvv}}}}}}}}vvvvv}}rrwzzzwzxxxxxzxzzzzzzzzzzzzzzzzzwwwwwwzzzzzzzzzzwrrr~<br></br>
`vvvvvvv}}}}}}v}rrrrrrrrrwzzzxzxxxxzwwzzzzzzzzzzzzzwwwwwwwzzzxxxzxxzzzwwrr~<br></br>
`v}vvvvv}}}}}}}}rwwww}v}rrrrrrwzzzzr}wzzzzzzzzzzzzwwwwwwwzzxxxzzzzwrr}}}}r~<br></br>
`v}}}}}}}}}}}}}}}rwzzw?>??vv}}rww}vv}zzzzwwwwwwwwwwwwwwwwwzzzwwrr}}}vvvvv}~<br></br>
`v}}}}}}}}}}}}}}}}wzzzzv+^+^+>>????vrwwwwwwwwwwwwwwwwwwwwwwrrrrrrr}}}}rr}r~<br></br>
`v}}}}}}}}}}}}}}}}rzzzzzr?^|||+++?}rwzwwwwwwwwwwwwwwwwwwwwwrrrrrrrrrrrrrrr~<br></br>
`}}rrrrr}}}}}}}}}}rzzzzzzz?^|^+>>>vrzzwwwwwwwwwwwwwrrrrrrrrrrrrr}}}}vvvv}}~<br></br>
`}rrrrrrrrrrrr}}}rrzzzzzzzzr>^+>>??}zzwwwwwwwwwwwrrrrrrrr}}}}}}}}}}vvvvvvv-<br></br>
`rrrrrrrrrrrrrrrrrwzxzzzzzzzw>^^+>wuxzzwwwwwwwwwrrrrrrrr}v}}v}}}}}}}}vvvv}-<br></br>
`rrrrrrrrrrrrrrrrwwzxzzzzzzzxw>~||+rzzzwwwwwwwwwrrrrrrr}v}}vv}}}}}}}vv}}rr~<br></br>
`}rrrrrrrrrrrrrrwwwzxzzwzzzzzxz?||^>vwwwwwwwwwwwwwwwrvvvvvvvvv}}v}vvv}wwrw~<br></br>
`vvvvvvv}rrrrrrrwwwzzxzwzzxzwwzzv^-|+vwrrrrrrrrrrrrrvvvvvvvvvvvvvvvvv}rrrr~<br></br>


            </div>,

            <div>

 .`~|~..>??????vvvvvv}}}}rrrrrrrr?.`^?v}rrrrrrrr}v|`.......    ........... <br></br>
 .`-`.`+??}}}}vwwwzzxuuuuyyyyyyyuzvzxuuyyyyyyyyuu?~`...................... <br></br>
 .....||>v}}v+?wrrwxxxxuuuuuuuyyuxuuuyyyuuuuuuuur~`.......``.............. <br></br>
 .....`+???>-~wzrrwzzxuuuuyyyyyyuuyyyyyyyuuuuuux>`..``~|^^~............... <br></br>
 .`````~?^-`->zzwrwxxuuuuuuyyyyyyyyyyyyyyyyyyuur|||^^+++^`................ <br></br>
 `-`````?r>-`vxxzzzxuuuuuuuyyyyyyyyyyyyyyyyyuuu>`~^++^^|.................`.<br></br>
 -~-``.`+}wv^vxxxxxuuuuuuuuyyyyyyyyyyuuyyuuyuu}...`~^+-.....`-~|^|`...``--.<br></br>
 `-~~```-?rwrwzwwzxuuuuuuuuuuyyyyyyyyuuyuuuuuu}?v}rwr|`-~|+>?+|~```````-~-.<br></br>
 ``-||~-~+rrwwwrwzxuuuuuuuuuuyyyyyyyyuuuuuuuuxuyyuuw+|>??>+-``...--`-~|||~.<br></br>
                  .~~~~^^^^|?wzwrrzxxxxuuuuuuyyyyuuuyyuuuuxuuuuuuuuxw???+|`...```.---~^^^|~|.<br></br>
.+^--|+++>?wwwrwzzzzwzuuuuuzxuyuur}xuuuuxxxxuuuxxw?^-`.     ..``~~|>>>>>>?`<br></br>
.||~~~^>>??wwrrzzwwwrrr}wxx}vrwzux}}rrwxuxwzxxzwv>-.............```-????vv`<br></br>
.~~||-`~>?r}vrwzwrwr}}v?vvrww}v?}zx}?>?}zxzwwww>-````````+???>>>>>>^>???vv-<br></br>
.-`-~--`|vz}>?wzrrwvvrv}wr}w}}v}?>?}}wr}rwwrrwwrrwr?^``|vww}}}v?????v}vvvv`<br></br>
.~----~|+}wv??}rr}v>>?vwzwwwv??vrzuxzwwwrr}vvvvv}?^-``>rzzzzzzw}vvv}rrr}}v`<br></br>
.~~--^^>rz}?vwwzzr?v+>vvwwrvvrwxxxzzwzzzwr}v}}}v-`-^?}zxxxxxxwwwwwzzzzwr}r-<br></br>
.~~|^>>vzw}v}zwwwwwwzwrwwwwwzwwwrrwzzxxzzw}v?>^|>vwzxxzxxxzzwwwwwwwzzzzzww~<br></br>
.|^>???wzwwrzzzr}}rzwrrrwwwwwwwwwzzxxxxwr}v?>?}wzzzwwwwzzwwwwwwwwwwwwwwwww~<br></br>
.^>??vvzzwzzzzwr}}}rrrrrrwwwwwwwzzzzzzwrrwzzxxzzzzzzzzwwzzzzxxxzzwwwwwwwww~<br></br>
.>??vv}zzwzzzzwrrr}rzzwwwwwwwwzzzzzzzwwzzzzzzwwwwwwwwwrrwzxzzzzzwwwwwwwwww~<br></br>
`v}}v}wzzzzzzzzzzzzzzzwwwwwwzzzzzzwwwwwwwwwwwwrwwwwwwwrrwwwwwzxxxxxxxzzzwz~<br></br>
`vrwwwzzzzzzzzzzzzzzzwwwzzzzzzzwwwwwwwwzzzzzzwr}}}}vvvvvvvv}rwrrwzxxzwrvv}~<br></br>
`vrzzzxxzzzzzzzzzzxzzzzzzzzwwwwwwwwwwzxxzzzzzwr}vvvvvvvvvvv}}}}}vvvv????vv-<br></br>
`vrzzzxxxxxzzzzzzxxzzzzzzzwwwwwwwwwwwzzzzwwwr}vv}}rrrrrrrrrr}}vv?????vvvv}-<br></br>
`v}zzxxxzzzzzzzzxxxzzzzwwwwwwwwwwwwwwrrrrrrrrrrrrrwrrwwwwr}v?++^?}}rrrrrrr~<br></br>
`v}rzxxxzzzzzzzzxxxzzzzwwwwwwwwwwwrrr}}}}}}}}}}}}}}vvv??+|-``~+?v}wwr}}}}r~<br></br>
`v}rzxxxxxzzzzzzzxzxzzzwwwwwwwwwzrvv}}vvvvvvvvvvv?>+|-`..`~+??>^?}vvvvvvv}-<br></br>
`rrwzxxxxxzzzzzzxxxzzzzwwwwwwrr}rvvvvvvvvvvvvv?^`....`~+?>+|`...`vrrrrr}}}-<br></br>
`rwwzxxxzzzzzzzzzwwzzzwwwwww}vvv}vv}}}vvvvv???>|~-`-+?vv|``~|>???}wwwwzzwz~<br></br>
`vvrwxxzzzzzxzzzwrrrwwwwwwww}}}}vvvv}}rrrr?~-~^????vv>-...`|?rwwwwwwwwzzzz|<br></br>
.??vv}zzwwzzzzzzwrrrwwwrrwwwwwrr}}}}}rrwww}v??vwwwrr}?>^`....~?rrrrwwzxxzz|<br></br>
.~^>>?vwrrrwzzzzzwrrwwwrrwwwrrrrrrrrrrrrrrrwwwwwwwwwrrwrv>`...`+v}}}}rwwww~<br></br>


            </div>,

            <div>

  `+>|..>>????>?vvv+>vvv}rrrrrrrr? .~>v}}rrrrrrrr?.........    ........... <br></br>
 .~|`.`>+|vvvv?rwwrvwwwzuyyyyyyyx}?rzuuyyyyyyyyyx+........................ <br></br>
 .....-~-?vv?-?wrrrrrrrzuuuuuuuuzwxuuyyyuuyyyuux}......................... <br></br>
 ......+>>+~..rrrrrrrrzuuyyyuyuuuuyyuyyyuuyyuuuw| ........................ <br></br>
                  ......`+`   |wrrrrwwzzxuyyyyyyyyyuuuyyyyyyyyyx?.......................... <br></br>
                  ...... +r^. ?wrrrrwxxxxuyyyyyyyuuuuuyyyyyyyyuz~ ......................... <br></br>
 .......`}wv`?zzrrwzzxzxuuuyyyyyyyyyyuuuyuuuyx?     .`......`^>?vv^....... <br></br>
 ``......>rwrwzwwwxxxxxuuuuuuyyyyyyyyuuyyuuuuw?>?v}wv-.~+>v}wzrv>|`....... <br></br>
 ``````..-}rwzwrwxxxxxxuuuuuuyyyuyyyyyuzuyuyxzxuyyyr^>vwzzrv>^~`.......... <br></br>
 `-``````.?wxwrrzxxzwzuuuuuyyyyuuuyyyuuzwxuuuuyyuxwrww}?^`~~~------``````. <br></br>
.-------~~^wzwrwxzxzwzuuyuuwwuuuyr}zxyyyx}wxuyuxzz}v>-.    ...`-~~~~~~~~--.<br></br>
.~~|^||||^?wwrwzxxxzzxzvrxuw?}}zux??vrxuux}wzxzr?^`         .  .....~|||||.<br></br>
.~|+?^||^>z}?wwzxxzwrz??vvvww}}??wxv>+?vwzwrwwr|.   .   .|?>^^++++^~~~~~||.<br></br>
.||++~~~~?x}-?zzxxzvvv?}zz}rv??v?^?}vw}}rrrr}rr}rr}?-  `?}v>+>>>>>>???>^||.<br></br>
.~~~~---^rxr??zzzz}>+|>}zzw}?>|>rxuzrrr}vvvv????v?-.  -v}}}}rrr}}vvv}v??>+.<br></br>
.~~~~^~+rx}?}xzzzwrr>>>>}w}?vrrzuxzwrwwww}?vvvv>.  `+?wwwzzzzzzzzzzzzwrv??`<br></br>
.~~|^>+vxzv?vxzzwwrwzwzwrrwwwwwwrrrwzxxxzwv>+~``|?vwzzwwzzzxxxxxzzzzzzzwww~<br></br>
.^^>???wzwrwxxzwwrrrrrrrrrrrrrrrwwzxxxzr??>^~^?rwwwzzwzxxxzzzzwwwwwwwwwwww~<br></br>
.^+>??vzzwwzzzwwrrrwwwwwrrrrrrwwzzzzzw}vv}wzxxxxxzzzzzzzzzzzzzxzzwwzzzzzzz|<br></br>
.>>???}zzwzzzzwwwwwzzzwwwwwrrwwzzzzzwrrwzzzzzwwwwwwwr}vv}wzzzzxxzzzzzzzzxx|<br></br>
`vv}vrzzzwzzzzwwzzzzzzwwwwwwwzzzzwwwwwwwwrrr}}}}}rrrr}}rrrr}rwzzxxxxxxxxxx|<br></br>
`vrzzzxzzzzzzzzzzzzzzwwwzzzzzzwwrrrrrwwwzzzzwrvvvv???????>>?vvvvvwwwwww}vw~<br></br>
`}wxxxxxzzzzzzzzzzzzzzzzzzzwwwwrrrrrwzxxxxxxxrvvv?>?????????>>>>>>>>>>>??v-<br></br>
`vwxzzxxzzzzzzzzzzzzzzzzzzwwwwwwrrrrwzzzwwww}???vvv}}}}}}}}vvv???????vvvvv-<br></br>
`vrzxxxxxxxzzzzzzzzzzzzwwrwwwwwrrrrrrrr}}}}}}rrrrwrrrrwww}v?+^|->rwwwrrrrr-<br></br>
`v}wxxxxzzzzzzzzzzzzzzzwrrrrrrrrrwrvvvvvvvv?vvvvvvvv??+|-.   .`^>?}}}}}}vv-<br></br>
`v}rxzxxzzzzzzzzzzzzzzzwwwrrrwrrzv????vv?v?>????>^|`.     .~^+|-?vvvvvvvvv-<br></br>
`rwzxzzzzzzzwzzzzzzzzzwwwrrwr}}v}?????????>>>>^`.     .-|+^~.   .vwwwwwwrr~<br></br>
`rwzxxzzzzzzzzzzwwwwwwwrrrrrv????????????>>+|+|`....~>?+-.``~^>?vrxxxxxxxx|<br></br>
.>>}}zzzzzzzzzzzwrrrrrrrrrrrv???????vvv}}v+...`^>>+^^~.   .-?wuxxxzzzzzzxx|<br></br>
.|^??vzwwwzzzzzzwwwrrrrrrrrrrrr}vvvv}rwwrr??>+?wzzr}v?>~`....`>wzzzzzzzxxx|<br></br>
.-~++>vzwwwwwzzzzwwwwwrrrrrrrrrrrrrrrrrrrrrwwwwwwzzzzxzz}+.....^}rrrwwwwzz|<br></br>


            </div>,

            <div>

.+???~..-^?vvv?v}}}?v}vvvvvvvvvvv?~+?v}rrrrrrrrrrv++++++^^^^^^^^^^^^^^^^^|.<br></br>
.?vv|..|?vrrrr}xxxxwxxxzzzzzzzzzwrrzxuuuuuuuuuuxzv>>>>>>>>>>>+++++>>>>>>>+.<br></br>
.??~..^?v}rr}?}xzzzzzxzwwwzwwwzxzxuuuuuuuuuxxxxxr>>>>>>>+>>>>+^^^^+++>+>++.<br></br>
.?~.`+?v}}vv?>zzzzzzzwrrwwwzzxxuuuuuuuuuuuuuuuuxv????>>>>>+???>+^^+++>>>>>`<br></br>
.|`^????v?>>>vxzzzzwrrrrwzxxuuuuuuuuuuuuuuxxxuxr??????>^~|^^^+>?>>>??????>`<br></br>
.->?????vzv?>}xzzzr}rrrwzxuuuuuuxxxxzzzzzzzzzwr^~^^^^+^~^??>++??????????>>`<br></br>
.>?>?????zxw?rxzwr}}rwwzxuuuxxzzzwwwwwwwwwwwzw?....-|~|????+>?vv}}?+++>>+>.<br></br>
.+>??????}zxzzwr}}rwwzxuuxzwwzzwzzzzzzzzzwwzw}?>?vv}?^vrwzr}wrv?>+^~>+++++.<br></br>
.>?vv????vzxzr}}rwwzzzzzzwwwzzzzzzzzzzwrwwwzr}rwww}???zxxzwwr?>+^|^^+++^~` <br></br>
.>??vvvvv?}wr}}rzzxzwwzzxxxuuuuuzwzzwwwr}rwwwwzzwrvrzw}v???}vv>>>+^>+>>+^|.<br></br>
.>?vvvvv?>?}rrwzzzzzzxuuuuuxzuuxz}vrwwwzwv}rwwr}v}w}v>^|||~^>?>+>+^?????>>.<br></br>
.?v}vvvv??vwzzzzzzxxxxxwzxuxrwwzzw??v}rwwrvvvvvvv?>^~^^``-|||||~~~`->?>^--.<br></br>
`v}}}}}}}}zwrzzzxxxzzxwrwwwzzwrv?}rv?>?vvwv?}wz?~`-|>~.  -??v}}v??>>++^~.`.<br></br>
`}}}r}r}v}zwvrxxxxzrww}wzzwr}v?v?>?vv}}}rr}vvrrv}}rv-  .^?+~^v}v+~~???>`.. <br></br>
`}}}}}vvvwxzrrzzzzwvvv}wzzrv?>+?vrrvv}wr}}v?????v?^.  `>|``--~|~``..-~`... <br></br>
`v}}}}??wxwrzxzzzwwz}v}vrrv??vvwzzwwrwwwzr}}}}}v|~^+?v}}vvvv>>>>>+^|~~-`.. <br></br>
`v}}}}?}zzwrwxzzwwwwzwwr}}}vvv}rrwwzxxxxxzw}v?>>?}wzxzzzxxxxzzzzzwww}v>+^|.<br></br>
`}rrwr}zzzwzxxzzwwwrr}vvv}}rrrrwwzzxxxxwrr}v?vrzxxxxzzxxxxxzzwr}}v}rwrrrrr-<br></br>
`}rrwrwzwwzzzzwwrrrrrr}rrrrrrrrwwwwwww}}rwzzxxxxxxxxxxzzzzzwrrv>>>>>?????v`<br></br>
`}r}rrrwwwwzzzrr}}rrrrr}}}}vv}rrrrrr}vvrwzzxxzzzwwwwwwrrrr}}}}r?+>>>>>>+>>`<br></br>
`rr}}rwwwrrrrr}}}}}rr}}}}}}}vrrrr}}}}}}}}wwwwrrrwwwwwwrrr}vvvv}}>+>>>+++^^`<br></br>
`v}r}}rrrrrrrrrrrrrr}}}}}}}}}}}}vvvvv}}rzzzzzzrrr}vvvv??????????>~---`````.<br></br>
.v}r}}rrrrrrrrrrrrrrrrrrrrr}}vvvvvvv}wzxxxxxxzrr}v?????????????>>`.......`.<br></br>
.v}rr}rrrrrrrrrrrrrrrrr}}}}}vvvvvvvvwxxzzzzwrvvvv?vvvvvvvvv?>+|-```....```.<br></br>
`v}r}rrrrrrrrrrrrrrrrr}}}}}}v}vvvvvrwwwr}vvvvvvvvvvvvvvvv?+-.....~~|^>???v-<br></br>
`vvv}rrrrrrrrrrrrrrrrr}}}}}vvvvvvrrvvv????????????????+^~.    ..``^vwzzzzx|<br></br>
`r}}rrrrrrrrrrrrrrrrrr}}}}vvvvvvrv?????????>????>+|~`````-||....`~~|>v}rrr~<br></br>
`wzzzzzzzwwwrr}}}rr}}}}vvvvvvvvvv?????????>>>>^~`..``-|+>??^`    `~~>vrrrr~<br></br>
`v}rrrrrrrrrrrrr}v}}}}}}}vvv?????????????>>>>?>||~~|>vv?^`  ..```-|+?vrwww~<br></br>
.??vv}r}}}rrrrrr}}}}}}}}vvvv???????vvvvv}rv++>?vvvvvv?|`.. .`|>>???v}rwwww~<br></br>
.>???v}}}}}}rrrr}}}}}}}}vvvvvv}}}rrrrwwwwwr}}vrzxxzwrv?+--..`|?rwwwwwwwwww~<br></br>
.>>???v}}}}}}}}}}}}}}}vvvvvvv}rrrrrrrrwwrrwwwwwwzzzzzzzzwv>++^+?rrrrrrrrrw~<br></br>


            </div>,

            <div>

`}}}v~.  |v}}}}}}}}}}vv???????>>>??v}}}}}}}}}}}}}}vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-xxx>..`vxxxxxxxxxxxxxxwvvvvvvvvvrwxxxxxxxxzzzzzwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
-zz>..>zxxxxxxxxxxxzxxzvvvvvvvvrwzxxxzzzzzzwwwwwwwwwwrrrrwzzzwrwwwwwwrrrrr~<br></br>
-x?.|}uxxxxxxxzxxxxxzrvvvvvvvrwzxxxxxxxxxxxxxxxxxxzzwwwwzzwuuuzwwwwwwwwwrw~<br></br>
-?-?xxxxxxxxxzxxzxzwvvvvvvrwzzxxxxxxxzzzzwwwwzzzxxuuxxwv?vrr}wxuxzxxzzzzww~<br></br>
.+ruxxxxxxxxxxzxzz}vvvvv}wzzzzzwwwrr}vvvvvvvvvvvwrr}vrv>vuyr}wzxzzzzzwwwww~<br></br>
.rzwxxxxxxxxxxxzrvvvv}rwwzzzwr}vvv???v???????????v??v+?xxxuvvvvvvvvvrrwwww~<br></br>
`v}zxxxxxxxxxzrvvvv}rwzzzw}vvvvvv??vvvvvvvvvvv???v>?+vyyyyr}}v??????wrrwww~<br></br>
.vzxxxxxxxxxwv?vv}rwzwrvvvvvvvvvvvvvvvvvvvvvvvvv??+>r}uuxzwxx}??????}wwv>- <br></br>
.vrzxxxxxzw}vvv}wzzw}vv}rrwzzzzz}?vvvvvvvvvvvvvv??+wzrv}zxxzxzv?v??v?rwv?>.<br></br>
`vrxzwzz}vvvvrrwwwrrwzzxxxxxxxzwv?vvvvvvvvvvv??>+vxxw}}zxxzzxxwvv?>}wwr}v?`<br></br>
`rzzrrwr}v}wwwzzzzzxxxxxxxxxxxzw}?vvvvvv???>^|~?vzxzrxz?>rzzxzz}??>?vrv+``.<br></br>
`zzzwwzzzzzzwwzzxzzxxxxxxxxxxzwvv?vvv????v>>}rzw?>}rz?`  -?vzxxwvv?v??+~ ..<br></br>
`zzzzzxxzwwwzzzxxzzxxxxxxxxzrvvvvv????v}}vv+vrrvv?ww|   ~+~-^vw}^--??>+.   <br></br>
`zzzzzzwrzzxzzzxzzzzzzzxxz}vvvv??>||?v}}}v????????v|. .^`     ..     ..    <br></br>
`zzzzzr}wzxzzzzzzzzzzzzzwvv????v}}}}}rwwrrrrrrwwzzw}vvvv?>>>-`......       <br></br>
`zzzzw}rzzzzzzzzzzzzzw}v???>>?vv}wzxxxxxxxxxxxxxxxzzzzzzzzzzzwwwwrr}?^`..  <br></br>
`zzzzzwzzzzzxxxxxxzzrv??>?vv}rwzzxxxxxxxzzzzzxxxxxxxxxxxxxzwrr??>+>v}vvvvv`<br></br>
`zzzzzzwwzxzzzzzwwwr}vvv}r}}}}}}}}}}}}}}wxzzxxzzzzzzzzzzwwrv??^     ...``~.<br></br>
`wzwwwwrrwwwww}vvvvvvvvvvv?>>?>>?>?????>?rwzzzzzzzzzzzzwrv?>>??~           <br></br>
`wrr}rr}}}vvvv??v???????????+>>>>>>>>?>>?rwzzzzzzzzzzzwrv??>?>>>.          <br></br>
`vvv?????????vvvvvvvvvvvvvvv?>?>>>>>>>?vwzzzzzzzzwwrr}vv???>?>>>^          <br></br>
.??v???v?????vvvvvvvvvvvvvvv?>?>>>>>?vrwzzzzzzzzr}v???>>>>>?????>.         <br></br>
.??v??vv????????vvvvvv????vv?>?>>>>?}zzzzzzwr}vv??>>>>>>>??>^|-`.          <br></br>
.??v????vvvv????vvvvvv????v?>>>>>>?vzww}v????>>>>>>>>>>>+~`.       ..`~^>?-<br></br>
.????????vvv????vvvvv????v?+>??>??}}v??>>>>>>>>>>>>>>>>>?^.       .?rzzxxx|<br></br>
`}vvvv?vvvvv???vvvvv??v???>>?>>>????>>>>>>>>>>>>>>>>>?}}}rv^.     ..~?rwww~<br></br>
`rwwwwwzr}}}v?????????????>?>>>>?>>?>>>>>>>>>>>>??vv}}}}}v??^       `>rwrw~<br></br>
.??vvvvvvv?vvvv?>???????v?>>>>>>>>>>>>>>?????vv}}rrr}}}}?^          .|vwrw~<br></br>
.??vvvvvvvvvvvvv??vvvvvv?>>>>>>????vvv?v}rwzzzzzzwwwwwrv++     ...->?vrrrw~<br></br>
.??vvvvvvvvvvvvv?vvvvvv?+>>>>?v}rwwzzzxxxxxxxxxxxxxxxzr?+v|~??v}rrrwwwwwrw~<br></br>
.??vvvvvvvvvvvvv?vv?v?>^>>???vrrrrrwwwzzzzzzzzzzzzzzzzzzzzzzzwrrrrrrrrrrrr-<br></br>


            </div>,

            <div>

`vvvv?^^^>vvvv}}}}}}}}vvvvv????>^^^+>>>>>>>>>>>>>>>??vvvvvvvvvvvvvvvv??>>>`<br></br>
`zzz}?>?}wwzzzxxxxxxxxxzrrr}}v?>^>???????????????vvrwwwwwwwwwwwwwwr}v?????`<br></br>
`zz}??vwwwwzzzzzzzzzzxzw}}}vv?+>??vv??vv??v}}vvvrwwwwwrrwwwwwwwr}v?>>>>>>>`<br></br>
`zrvvrzwwwzzzzzzzzzzzwr}}vvvv??vvvv}rwwwwzzxxzxxxxzzwwwwwwwzzw}?>++>>>???v-<br></br>
-rvrzzwwwzzzzzzzzzzzwr}}}}rwr}}}}wzzzzzzzzzzzzzzxxxxzzwrv}wv????>>???v}rrr~<br></br>
`}zxzwwwzzzzzzzzzzwrrrrrrwwwrrwwwwwwwrrrrrrrrrrwzzzwrw}v}zzv?}r??vv}}}}v??`<br></br>
`wzzzwwzzzzzzzzzwrrrrrwwwwwwrrrrrrrrrrrrrrrrrrr}rr}}}?vwzxz}rrr}vv>^>>>>>?`<br></br>
`rwzzzzzzzzzzzwrrrrwwwwwwwrr}rrrrrrrrrrrrrrr}r}}}rv?>?zuuuwrwww}??^~+++>>?`<br></br>
`rzzzzzzzzzzzrrrrwwzzwwrrrrrrrrrrrrrrrrr}}}}}v??>>+^??zxzwrwwr}?>|||^++^|-.<br></br>
`wzzzzzzzzzwrrrwwzzzwrrwwwzzzzzzwrrrrr}}}}vv?>^^^+>vv??vv}r}??++|~~^^?v?vv`<br></br>
`rzzzzzzwrrrrwwzzzzwzzzzzzzzzzzzrrrr}}}}}}v??????v}vvvvwzzzw}?+^+++??????>`<br></br>
`wzzwwzwwrwwzzzzzzzzzzzxzzxxxzzzwr}}}}}}}vvv?+>?v}rwwuz?>?vvv??+|~~|^+|-`..<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzxxxzzrr}}}}}}}v}v?vv}v>?wzw>`  `^+?v?+``-+|~~`...<br></br>
`zzzzxxzzwzzzzzzzwwwzzxzzzzzwrr}}}}}}}}rr}}?v}v?vvr}~   ``..-^>^`..++||... <br></br>
`zzzzzzzwzzzzzzwwwwwzzzzzzwrr}}}}vvv}rrrrr}v???v}}v|...~`          ..-`..`.<br></br>
`zzzzzwwzzzzzzwwwwwwzzzzzwrrr}rwzzzzwwwwwwwrrrrrrwrv????>>>>||~~||||----``.<br></br>
`zzzzzwwzzzzzwwwwwwzzzwrrrr}rrwzzzxxxxxxxxxxxxxxzzzzzzzzzzzxzzzwrrr}v?|~-`.<br></br>
`zzzzzzzzzzzzzzzzzzzzrr}}rrwwzzzzxxxxxxxzzzzzxxxxxxzzzzzzzzww}>^||?vvvvvvv`<br></br>
`zzzzzzzzzzzzzzzzzzwwwrwwwwwwwwwzzzzzzwzzxxzxxzzzzzzzzzzrwr}v?`...`---~~|^.<br></br>
`wzzwwwwwzzzzzwwwrrrrrrrrrrrrrrrrrrrrrrrrwzzzzzzzzzzzzwwr}vvv+-`.`----````.<br></br>
`wwwwwwwwwwwrrrrrrrrrrrrrrrr}rrrrrrr}}}v}rwwwzzzwrwwwwwrr}v?^````-~|~~~--`.<br></br>
`rrr}rrrrrrrrrrrrrrrrrrrrrrr}rwrrrr}}}v}wwwwwwzzwrrrr}vv}v?~``->?~------``.<br></br>
`}r}}}}}}}}}}}}}}}}rrrrrrrrr}rwrrrrrrrwwwwwwwwwwwwr}vv?v?^+^-|?v?|````````.<br></br>
`}}}}}}}}}}}}}}}}}}}}}rrrrrr}rrrrrrwzxzzzzwwrrr}}}}}}vvv+~++^^^~-`````..``.<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}rrr}}rrrrrrzxxzzwwr}}}vvvv}}r}}}>-``-````-~~^+>??v-<br></br>
`vvvv}}}}}}}}}}}}}vv}}}}}}}}}}}}}rwwrr}}r}}rrr}}}}}}}}}rr?^^^^^^^^+vwwzzxx|<br></br>
`}}}}}}}}}}}}}}}vvv}}}}}}}v}rrr}}}}vvvvv}}}rrrrrrrrrrwwzzxzv>+>>+++>?}rrww~<br></br>
`rrrrrrrr}r}}}vvvvvvvvvvvvvvv}}}}}vvvvvv}vvv}}}rrwwwzzzzzwrrv^|^|^||?vrrrw~<br></br>
`v}}}}}}}}}}}r}v??vvvvv}}vvvvvvvvvvvvv}}}vvvrwzzzwrrwzxzwv~|||~~~~~^>vrwww~<br></br>
`v}}}}}}}}}}}}vvvvv}}}}}vvvvvvv}}}}}rrrrwrrwzxxzzwwrrrwwvv~|^+++>>?v}rwwww~<br></br>
`v}}}}}}}}}}}vvv?vv}}vvvvvvvv}rwwrwzzxzzzwwzzxxzzzwwwrr}vr??v}rrrwwwwwwwww~<br></br>
`v}}}}v}}}}}vvv??v}}}vv?vvvvv}rrrrrwwwwwwwwzzzwwrrrrrrrwwwwwwrrrrrrrrrrrrw~<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvv}}}}}}}}}}vvv??>~~~~`..............`^?}}}}}vvvvvvvv???-``. <br></br>
`zzwwwwwwwwwwzzzzzzxxxxxzzwwrr?^~~~-`...........`|>vzzzzzzwwwwwwww}v?|````.<br></br>
`zzzzwwwwwwwwzzzzzzzzzzzwwrrv>|~~---`..``-^>??>?vwxxzwwwwrwwwwwr?+~-`..... <br></br>
`zzzzwwwwwwzzzzzzzzzzzzwwr}v?+~|~||^?vv?vrwxxzzxxxzzwwwwwwwwr?+~.......`-^`<br></br>
`zzzzwwwwwzzzzzzzzzzzzzwwwzwv>++?v}wzzzzxxxxxxxxxxzzzwwwwwwv~`......`|>vvv-<br></br>
`zzzwwwwwzzzzzzzzzzzzzzzzww}?v}rzzzzzzzzxxxxxxxxzzzwwwwwrrv++v?``~+>???+~`.<br></br>
`zzzwwwwzzzzzzzzzzzzzzzwwwrrrwwzzzzzzzzzzzzzzzzzwwwwwwr}rwvrxw}vv?|....... <br></br>
`zxzwwwzzzzzzzzzzzzzzzwwwrrwwzzzzzzzzzzzxxzzzwwwwwr}?>?wzzzzwzzr?v-......`.<br></br>
`zxzwwzzzzzzzzzzzzzzzzwwwzzzzzzzzzzzzzzzzzwwr}?>^||~|^vw}vv???v?^`......`~.<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrrv?^-`-|>?>|+?|>??-..~....`|>?vr~<br></br>
`zxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwrv????vv?+~~+?}}}}v>``--~+>^^^^||.<br></br>
`zxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwrrr}v?????}wzrv>||||---`..`.......<br></br>
`zxxxxxzzzzzzzzzzzzwwzzzzzzzzzzzzwwwrrrrrr}v>+^^^vzzv?^~~~~``+`........`...<br></br>
`zxxxxxxzzzzzzzzwwwwwzzzzzzzzzzwwwwrrrrrrr}vv??>v}?>|~```````...  .---~|>?`<br></br>
`zzzzxxxzzzzzzwwwrrwwzzzzzzzzwwwwwwwrrrrwwr}v??vzz?|-``-~-...    ..~+??vv}~<br></br>
`zzzzzxzzzzzzwwrrrrwwzzzzzzzzzzzzzzwwwwwwwwr}}}vv}v>+^^^+>??>>>>??v}}}}rrr~<br></br>
                  `zzzzzzzzzzzwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzxxxzwwwzzzzzxxxxxxzr??vvvvvvvv-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxzzzzzzzzzzzzzwwwv|-`~????>???`<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwvrv}}?...|????????`<br></br>
`wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwwwwwwzzwr}}rrr|...+vvvvvv??`<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrrrrrrrwwwrvwrrwrrrw}?|...~vrrrrrrr}-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrrrrrwwr}}}}}}vrv>`..`>v}rwwwzzwr~<br></br>
`zzwwwwwwwwwwwwwwwzzzzzzzzzzzzzzzzzzzwwwrrrrrrrrrwwrr}}}v~^|``?vvvvv}}}}}}~<br></br>
`wwwwwrrrrrrrrrrrrrwwzzzzzzzzzzzzzzzzzzwwwwrrrrrwwzzwwrr^.||+?v?vvv}}}}}}v~<br></br>
`rwrrrrrrrrrrrrrrrrrrwwzzzzzzzzzzzzzzzzzzwwwwwwrrrwzzzzz>|-^}r}vv}}rrrrrrr~<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrwwzzzzzwwwwwwwwwwwwwzzzwwwwwwwwwzzzzwzxzzzzzwrrrrwwz|<br></br>
`rrrrrrrrrrrrrrrr}}rrrrrwwwwwzzwwrrrwwwwwwwzzzzzzzzzzzzzzzxxxzzzzzwwwrrrrw~<br></br>
`rrrrrrrrrrrrwwrvv}rrrrrrrrrrwwwwwrrrrrwwrrwwzzzzzzzzzzzzzzzzzzzzzwzwwwrrr~<br></br>
`rrrrrrrrrwwzzw}vv}rrrrrrrrrrrrwwwrrrrwzwrrrwzzzzr}}rwzzzzzzzzzzzzzzzzzzzz~<br></br>
`rrrrrrrwwzzzwr}v}rrrrrrrrrrrrrwwrrwwzzzwrrrwzzzwr}vv}wzzzzzzzzzzzzzzzzzxx|<br></br>
`rrrwwzzzzzzzrvvv}rrrrrrrrrrrwwzw}wzzzzwrrrwzzzwwrvvvv}wwwzzzzwwwwwwwwzzzx|<br></br>
`rwwzzrwzzzzrv??v}rrrrrrrrrrrrwwr}wwwww}v}rwwwwrv?????v}rrrrrwwwrrr}}rrwwz~<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}?>>+~...```-|+?}}}}}}}}}}}}vvvvv???>>.<br></br>
`zzwwwzwwwwwwwwwwwzzxxxxxxzzzzxxxxzzw}??^~|+?vv}rwzxzzzzzzzxxzzzwr}v????>+.<br></br>
`zzwzzzwwwwwwwwwwzzzzzzzzzzzzzzzzzwrv?>>?vrwwwzzzzzzzzzzzxzzzwrvv?>+^|-`.. <br></br>
`zzzzzzwwwwwwzzzzzzzzzzzzzzzzzzzwwrvvv}rwwwzzzxxzzzzzzzzzzwrv?+~-``....... <br></br>
`zzzzzwwwwwzzzzzzzzzzzzzzxzzzzwwwr}}rwwzzzzxxxxxxxzzzzzwrv?>^--`--`....... <br></br>
`zxzzzwwwzzzzzzzzzzzzzzzxxzzzwwrrrwwzzzxxxxxxxxxxxzzwrr}vv?>>+^|-```...... <br></br>
`zxzzzwwzzzzzzzwzzzzzzzzzzzwwwwwwzzxzxzzzzzzzzzzzzwwr}v??>+>+^~`.......... <br></br>
`zxxzzzzzzzzzzwwzxxxxxxxzzwwzzzzzzzzzzzzzzzzzzzzwwrv?>++>???+~``.......`...<br></br>
                  `zxzzzzzzzzzzzzzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwrrv????vvv?+-`......`...... <br></br>
`zzzzzzzzzzzzzzzzzzxzzzzzzzzzzzzzzzzzzzzzzzwwwrr}}}r}v?+~--`.`.`+~........ <br></br>
`zxzzzzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrwwwwwr}v????>>?vvr>~^~...... <br></br>
`zxxxzzzxzzxxzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwwrr}}vv?>>v}wzz>~^``.... <br></br>
`zxxxzzzxzzzzzzwwwzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwrrr}}}}vvvv}vvv+`||~~~-`.<br></br>
`zxxxxxxzzzzzzwwwwzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwwwwww}vv???>~|^^>~~~|^|-.<br></br>
-zxxxzzzzzzzwwrwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwr}rr?v?>|`....~|``-~||~.<br></br>
`zzzzzzxzzzwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwr}vvvvvv???>-   .|||^^+>`<br></br>
`wrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrr}v>-`+v??>>>?`<br></br>
`rrwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxwvvwwr}v??>`<br></br>
`rrwzzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwwv-.>rrrr}vvv-<br></br>
`wwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwzzzzzzzzzzzzzzzzzzzxzzzw}~.^vr}}rrrr}-<br></br>
`wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrrrrwwwwwwwwwwwwwwzzzzw?>?}rrrrwwrrr-<br></br>
`zzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwrrrrrrwwwzzwwrrrr}}}v>?}rrwwwr}}}}-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrrrrwwwrrrrwrr}}r}vv}rrr}}vvvvv~<br></br>
`wwzzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzwwrrrrrrrrwzzzzwwrrrrrrrrrrrr}}}rr}v}}~<br></br>
`rwwwwwzzzzzzzzzzzzzzzzzxzzzzzzzzzzzzwwrrrrrrrrwwzzxzzzzwrrwwwr}}}rwr}}rrw~<br></br>
`rrrrrrrwwwwwwwwwwwwwzzzxzzzwwwwwzwwzzwwwwwrrrrrwwwwwzzwrwzwrrrrrr}vv}rr}r~<br></br>
`rrrrrrrrrrrrrrrrrwwzzzzzzrr}}rrrrrrrrwwzzwwwwwwwwwwwrr}wzxzrrrr}vvvv}}vv}-<br></br>
`rrrrrrrrrrwwrrrwwzzzzzzzw}}}rr}}}}rrrrwzwrwwzzwwzzzwr}rwwwrrr}vv??vvvvvrw~<br></br>
`rrrrrrrwwzzzwwwzzzzzzzzwr}}}}}}}}rrwzzzzrwzzzzzzzzwr}}}rrr}}vvv???vvvvrww~<br></br>
`rrrwwzzzzzzzzzrzwwwwwwrr}vv}}}}}rwzzzzzzwzzwwzzzwr}v}}}vvvv???v???vvvv}rr~<br></br>
`wwzzzzzzzzzzzwr}}rrrrr}vvvv}}}}wzzzzzwwzzzwwww}vvvvvvvvvvvvvvvvvvvvvvv}}}-<br></br>
`wzzzzzzzzzwrrvvvvrr}}vvvvv}}}rrwwwww}}rr}}}}}v???vvv?vv??????????????vvvv-<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}v^`..`-|>v}}}}}}}}}}}}vvvv?+-.... <br></br>
`zzwwwzzzwwwwwwwwwwwzzzzzxzxxxxxxxzxzzzw?+|^+?v}rwzzzzzzzzxxxzzrv>^-`..... <br></br>
`zzwzzzzwwwwwwwwwzzzzzzzzzzzzzzzzzzzwwv???}rwwwwzzzzzzzzzxzwr?+~~`......`. <br></br>
`zzzzzzwwwwwwzzzzzzzzzzzzzzxxzzzzzwwr}vrwwwwzzxzzzzzzzzzz}?+-....-````.... <br></br>
                  `zxzzzzwwwwzzzzzzzzzzzzzzzzzzzzzwwrrrwwzzzzxxxxxxzzzxzw?+~`|~|+?vv?^~`...` <br></br>
`zxzzzzwwzzzzzzzzwwzzzxzxxxzzzzwwwwwzzzzxxxxxxxxxzzzrv???vvvrww}?+-``..... <br></br>
`zxzzzzzzzzzzzzwwzzzzzzxxzzzzwwwzzzzzzzzzzzzzzzzzzwr}rrwwwww}?>|`````..... <br></br>
`zxzzzzzzzzzzzwwzxxxzzxxxzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwr}v??+^~``...`..`.<br></br>
`zxzzzzzzzzzzwwzzzzzxxxzzxzzzzzzzzzzzzzzzzzzzwwrwwwwwwwwwrv>|`............ <br></br>
                  `zzzzzzzzzzzwwzzzzxxzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwrrv??+~~~^|~-`....... <br></br>
`zxzzzzzzzzzzzzzxxzzzzzzzzzzzzzzzzzzzzzzwwwwwzzzzzzzwr}}v}}v??>+~```-`.... <br></br>
`zxxzzxzzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwr}vv???>+++|~~-```.<br></br>
`zxxxzzzzwrwrzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwrrrwwrrwr}??+||~~-.<br></br>
`zxzzzzzzzrwwzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwr}v??>+?zuur~-~----.<br></br>
`zzzzzzwrwzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrvv?>~`.-?+v?--~|~~-.<br></br>
`zrzzwwwwzzzwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwr}}wvvvv?`  .++^^++>`<br></br>
`w}wwrrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrwr}}vv>. -??>>>>?`<br></br>
`wrvvvwzxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzzv>vwrr}v??`<br></br>
`vvv}zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwzzwwwwr+|?wwwrr}r}-<br></br>
`vvvwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzww>`>}}}}wwzwr-<br></br>
`v}wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrrwwwwwzzzzzzwwwwwwwwwwwvvrrrwzzwrr}-<br></br>
`wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwrrwwwzzzxzzwwr}rr}}}rrwzzw}v}}v-<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwzwwrrrrrrwwwwwwwwwwr}}}rwzwww}vv}}}}~<br></br>
`rwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwzwwrrrrrrrrwwzzzzwwrrr}rwwzzwr}vv}}}}ww~<br></br>
`rwrwwzzzzzzzzzzzzzxxzzzzzzzzzzzzzwrwwwrrrrrrrrwzzxzzzzzwwzzzwrvvvv}}}rzzz~<br></br>
`rrrrrwwwwwwwwwzzzzzzzzzzzzzzzzwzr}}rwrrwwrrrwwwzzr}}zzzzzzz}}vvvvv}}rrrwr~<br></br>
`rrrrrrrrrrrrrrwwwwwzzzzzzzzzzzrvvvvv}rwwzwrwzzzw}v}wzzzzzz}?}vvvvvvvv}}vv-<br></br>
`rrrrrrrrrrwwwwrrwwzzzzzzzzzzzwvvvv}rrrwzwwwzzzwrwzzzzzzzz}vvvvvv???vvvvvv-<br></br>
`rrrrrrrwwzzzwwwzzzzzzzzzzzzw}vvvv}rwzzzzzzzwwzzzzzzzzzzzw}vvvvv??vvvvvvvv-<br></br>
`rrrwwzzzzzzzzzvzzzwwr}wzwrvvvvvvvrzzzzzzzzzrrzzzzwwwzzr}vvvvvvvv?vvv???vv-<br></br>
`wwzzzzzzzzzzzzwwr}vv}wr}vvvvvvvrwzzzwrrwzzwr}}}vv}rr}vvvvvvvvvvvvvvvvvvvv-<br></br>
`wzzzzzzzzzzzzr}vvvv}}}v?????v}wwwwr}}}rr}vv?vv??vvvv????????????????????v`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}vvvvvvvvvvvvvv}}vv?~`~^|---``.<br></br>
`zzwwwwwzzwwwwwwwwwwwzzzzxzxxzxxxxzxxxzzzzzwwwrwwwwwwwwwzzzwrv>|||+>>>+^|~.<br></br>
`zzwzzzzzwwwwwwwwzzzwzzzzzzzzzzzwwwzzzzzwwwrrwwwwwwwwzzzzwv????vv}}r}v?++^.<br></br>
`zzzzzzzwwwwwwzzzzzzzzzzzzzzzzwrrwzzzzzwwwwwwzzzzzzzzzwwrr}}rwwwwr}v?>+^^^.<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzzzwzzzxzzzzzzzzzzzzzzzzwwwwwwwwwwwwr}vv?vvv?>+.<br></br>
`zzzzxzzzzzzzzzzzzwwzzzxzzzzxxzzxxzzzzzzzzzzzzzzzwwwwwwwwwwwwwwwwwrvv?>^|^`<br></br>
`zzxzzzzzzzzzzzzzwzzzzzzzzzzxxxzzzzzzzzzzzzzxxzzzzzzzzwwwwwwwzww}?>^^^||~|.<br></br>
`zzzzzzzzzzzxxzzzzzzzxxxxxxxxzzzzzzwwwwwzzzzxzzzzzzzzzzzwwwwr}v?+^^||||||^.<br></br>
`zzwzzzzzzzzzzzzzzzzzxxxzzzxzzwzwwwwzzzzzzzzzzzzzzzzzzwwwwrv?>+^^|||||||~|.<br></br>
`zzzzzzzzxxzzzzzzzzzwxxxzzzzzzwwwwzzzzzzzzzzzzzzzzzzzzzzrv???????????>+|~|.<br></br>
`zzzzzxxzzzzzzzzzzzwzxzzzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwrr}vvvvv?>>>>+.<br></br>
`zxxxxxzwzzzzzzzzzzzzzzzzzzwwzzzxzzzzzzzzzzzzzzzzzzzzzzzzwww}vv??>>>>>>>?>`<br></br>
`zxxzwrrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwrr}}}v??>+>>>`<br></br>
`zzr}}}rzzzzzzzzzwwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrr}vv????>>>>>`<br></br>
`r}vv}}rrwzzzwwzwwwwzzzzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzw}v??^||????????`<br></br>
`r}vvvvvrwwzzwwzzzzzzzzzzwwwzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwr}v?>^>wu}?vvvv-<br></br>
`vv}vvvrwzzzwwwwzzzzzzwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzwwwr}v}vvv++?rrrrr}-<br></br>
`vvvvvrwrrr}}rwzzzzwwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzzrwrr}|^}rwwzzw~<br></br>
`vvvv}}vvvvvrwzzzzzwzzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzw}}wzwzzzzw~<br></br>
`vvvvvvvvv}rwzzzzwwzzzwwzzzzzzzzzzzzwwwwzzzzzzzzzzwwwwwwwwzzzzzwrzzzwzzzzz~<br></br>
`vvvv??vv}wwwwwwwwwwwwzzzzzzzzzzzzzzzwwwwwwwwzzzzzzwwrrr}rwwwwzzzzzwwwwwr}~<br></br>
`vv???vvvrzzzwwzzzwwwzzzzzzzzzzzzzzzzzwwwwwrrwwwzzzzzwwwwwwrrrwwwwwwwr}}}}-<br></br>
`vvvv?v}wwzzzwwzwwwzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzwwrrrrrrrrrr}}}}}-<br></br>
`vvvvvrwwwwwwwwzzzzzzzzzzzzwwwwwwwwwwwwwwzzzzzzzwwwwwwwwwrrrrrrrwwwrrwrrr}-<br></br>
`vvvv}rwwwwwwwzzzzzzzzzzzzzzzwwwwwwwwwzzzzzwrrwwwwwwwwwwwwrrwwzzzzzwzzzwwr~<br></br>
`vvv}rrrwwwwwzzzzzzzzzzzzzzzzzzzwwwzzzzzwr}}}rrrrwwwwwwwrrrwzzzzzzzzzwwr}v-<br></br>
`vv}rrrrrrwwwwwwzzzzwwzzxzzzzwwwwzzzzwwzwrrrrrwwwwwwwwrrrwwzzzzzwrvvvwrrvv-<br></br>
`v}rrrrrrrrrrwwwzwwrrzzzzzzwwwzzzzwwzzzwrrrrrwwwwwwwwwrwwwwwwwwwwrr}vrr}vv-<br></br>
`rrrrrrrrrwzzzzzzwwzz}zzzzzzzzw}zzzzzwrrrrrrwwwwwwwwwwwwwwwwwwwwrwwww}v?vv-<br></br>
`rrrrrwwzzzzzzzzzzzzw}zzzwzzzzwwwr}}}}rrwwwwwwwwwrrr}}rrwwwwwwwr}vvvvv??vv-<br></br>
`rrrwwzzzzzzzzzzzzzwrrr}}vvr}}}}}vvvvv}rwwwwr}}}vvvv}rrrr}}rrr}vvvvvvvvvvv-<br></br>
`rwzzzzzzzzzzzzzwrr}}vvvvvvvvvvvvvv??vvvvvvv?v????v}}vvvv?vvv????????????v`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}vvvvvvvvv????vvvvvvvvvvvvvvvv~<br></br>
`wwwzzzzzzwwzzzzzzzzzzzzzxzxxxxxxxxxxzzxxxzwwwwzzwr}vvvvrrrwwwwwwwwwwwwwww|<br></br>
`wwzzzzzzzzzzzzzxxxzzzzzzzzzzzzzxzzwwwzxzwwwwzzzw}vvvv}rwwwwwrrrrwwwwwwwww~<br></br>
`zzzzzzzzzzxxzzzzzzzzzzzzzzxxzzzwrrwzzzwwzwzzwrvvv}wwzzzzzwwwwwwwwwwwwzzzz~<br></br>
`zzxzzxzzzzzzzzzzzzzzwwwzzzxxzzwwzzzzzzzzzwrv}}rwzzzzzzzwwwwwwzzzzzzzzzzzx|<br></br>
`zzxzxxzzwzzzzzzzzzwzzzxzzzzzzzzzzzzzwww}vvvrwzxxzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
`zxxxzwwwzzzzzzzzzzzzzzzzzzzzzzzzzzr}}v??v}wzzzzzzzzzzzzzwwwwwzzzzzzzzzzzx|<br></br>
`zzzr}wzzzzzzxxxzzzzzzwzzzzzzxxzw}vvvvvvrzzxxzzzzzzzzwwwwwwwwzzzzzzzzzzzzx|<br></br>
`wr}}wzzzzxxxzxzzzzzzzzzzzzxzw?vvvvvvvrzzxzzzzzzzzzwwwwwwzzzzzzzzzzzzzzzzz|<br></br>
                  `vrzzzzzzxxzzzzzzzzwwzzzzzzwvv??vvvrwzzzzzzzzzzzwwwwwzzzzzzzzzzzzzzzzzwwww|<br></br>
                  `zxxzzxxzzzzzzzzzzzzzzzzwrvv??vvrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrww|<br></br>
                  `zxxxzzzzzzzzzzzzzzwwrrvv???vvrzzxxzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwrrrrrrrww~<br></br>
`zxzzzzzzzzzzzzzzw}vvvvvvvv}wzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrrrrrrrrw~<br></br>
`zzzzzzzzzwzzzz}vvvvvvvvvvvrwrrwzzzzzzzzzzzzzzzzzzzzzzwwwwwwwrrrrrrwwwwwrw~<br></br>
`zzzzzzzzzwww}v?vvvvvvvv?vvv}rwzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwzzzzwwwwrw~<br></br>
`wwwzzwwrrrvv??vvvvvvvvvvv}rrwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwww~<br></br>
`vvwzwvv}vv???vvvvvvvvvvrrrrrwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxz~<br></br>
`v}r}vvv??vv?????vvvv}rrrrrrwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz~<br></br>
`}vv?vvv??vv????vvv}rrrrrrrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz~<br></br>
`vvvv????????vvvv}rrrrrrrwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz~<br></br>
`vvv???v?????vvv}}}rrrrwwzzzzzzzzzzzzzzzzzzzzwwwwwwwwwrrrrwwwzzwwzzzzwwwww~<br></br>
`vv??vvv?????vv}}}rrrwzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwrwwwwwwwrrrrwrrrwrrw~<br></br>
`vvvvvvvvv??vv}rrrwwwzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwzzwwr}v}rrrrrrrrrrrr~<br></br>
`vvvvvvvvvvv}rrrwwzzzzzzzzzzzzzzzzzxxzzzzxzxxxxzzzzzzwr}vvv}}rrrwwww}}}rwz~<br></br>
`vvvvvvvvvrrrrwwzzzzxxzzzzzzzzzzxzwwwwzzxzzzzzzzzzzzrvvvv}rrwwwwzzwwwzww}}-<br></br>
`vvvvvv}rrrrwwwzzzzzzzzzzzzzzzzw}v}wzxzzzzzzzzzzzwrvvvvv}rrrrrzzzzwr}vvvvv-<br></br>
`vvvv}rrrrrrwwwwwrwrwwzzzzzzwrvv}rzzz}wzzzzzzzwrvvvvvvvvv}}}rwwr}vvvvvvvv>-<br></br>
`vv}rrrrrrrrrrrwwwzzzzzzzzwr}rwzzzzzzwzzzzwr}vvvvvvvvvvvv}rwrvv??v??vvvvvv-<br></br>
`}rrrrrrrrwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzw}vv?vvvvvvvvvvvvvvvvv??vv?vvvvvvv-<br></br>
`rrrrrwwzzzzzzzzzzzzzzzzzzzzzwrrzzzzzwr}vvvvvv??vvv??vvvvvv??vvvv?vvvv?vvv-<br></br>
`rrrwwzzzzzzzzzzzzzzzzzzwwrvvrwzzwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`rwzzzzzzzzzzzzzzzzzzww}vvvv}rrrvv?????vvvv??v????v??v???????????????????v`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvv????????????vvvvvvvvvvvvv}v}}}}}}}}}}}}}}}}}}}}}}r~<br></br>
`wwwwwwwwwzzwwzzzzzzzr}}v?vvvvvv}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzzx|<br></br>
`rrrrwwzzzzzzzwwwww}vvvvvvvvvvvv}}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rwwzzzzzzzzzzzwwr}vvvvvvvvvvv}}rrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`wzzzzwzzzzzzzzzwrvvvvv}}}}}}rrwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwzzzzzzzzzzzz|<br></br>
`zzzxzwzzzzzzzwr}vvvvv}}}}rrwwwwzzzzzzwwzzzzzzwwrrwwwwzzzzxzzzzzzzzzzzzzzx|<br></br>
`zzzzzzzzzzzwrvvvvv}}}}}rwwzzzzzzzzzzzzzzzzwwwwwzzzzzzzwwrrrrwwzwwwwzzzxzx|<br></br>
`zzzzzzzzzzr}v?}r}}}}}rwzzzzzzzzzzzzzzzzzzzzzzzrzxzwwr}}}}}rwzzxzzzzzzxzzx|<br></br>
`zzzzzzzzrrwwzwwr}}rwzzzzzzzzzzzzzzzzzzzzzzzzzzwwwr}}}}}rwzzzxzzzzzzzzzzzx|<br></br>
`zzzzzzzwwzzzzwrwzzzzzzzzzzzzzzzzrxzzzzzzzzzwrr}}}}}rwwzzzzzzzzzzzzzzzzzzx|<br></br>
`zzzzzzzzzzzzzzzzzxzzzzzzzzzzzzzxxzzzzwwwwr}}}}}}rwzzzzzzzzzzzzzzzzzzzzzzz|<br></br>
`zzzzxzzzwwzzzzzzzzzzzzzzzzzzzzwwwr}}}}}}}}}}rwzzzzzzzzzzzzzzzzzzzzzzzzwww~<br></br>
`zwzwwwwwwzzzzzwwwwzzwrzzzzwrr}}}}}vv}}}}}}rwzzzzzzzzzzzzzzzzzzzzzzzzwwwrw~<br></br>
`r}}rwzzzzwwr}}}rwwwwzzzzwr}v}vvv}}}}}}}}rwzzzzzzzzzzzzzzzzzzzzzzzzzwrrrrw~<br></br>
`}rwzzwr}vvvvv}wzzzzwwwwr}}}}}}}}}}}}rrwzzxxzzzzzzzzzzzzzzzzzzzzzwwwwwwwww~<br></br>
`wwwr}vvvvvvvrwwzwwr}}}v}}}}}}}}}}}}}rwzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwzzwww~<br></br>
`}vvvvvvvvv}wwwr}vvvvvvvvvvvvvvvvvv}}rwwwwzzzzzzzzzzzzzzzzzzzwwwwwwzzwwwww~<br></br>
`?vvvvvvvv}wr}vvvvvvvvvvvvvvvvvvvvv}rrrwwwwwwzzzzzzzzzzzzzzzzwwzzzzzzwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}rrrrrwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzz~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}rrrrrrrrwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv}}}}}rrrrrrrrwwwwwwwwzzzzzzzzzzzzzzzzwwwzzzzzzzzzz~<br></br>
`vvvvvvvvvvvvvvvvvvv}}}rrrrrrrrrrrrrrwwwwwwwwzzzzzzzzzzzwwwwwwwwzzwwwwwwwz~<br></br>
`vvvvvvvvvvvvvvvv}}rrrrrrrrrrrrrrrrrrrrrrrwzzxzzzzzwwwwwwrrwwwwwwwwwwwrrrw~<br></br>
`vvvvvvvvvvvvvv}}rrrrrrrrrrrrrrrrrrrrrrrwzzzzzzwwwwrrrrrrrrwwwwwzzwwrrrrrr~<br></br>
`vvvvvvvvvvvvvv}}rrrrrrrrrrrrrrrrwwwwwzzzzzzzzwr}rrrrwwwwwzwzzwwwr}}}rrrrw~<br></br>
`vvvvvvvvvvvvv}}rrrrrrrrrrrrwwwwzzzzzzzzzzwr}v}}rwwzzzzzwwwr}}vvv}rrrrrrrw~<br></br>
`vvvvvvvvvvv}rrrrrrrrrrwwwwzzzzzzwwzzzzwr}}}rwwzzzzzwr}vvvvvvvvv}}rrrrrrrr~<br></br>
.vvvvvv??v}rrrwwrrwwwwzzzzzzzzzzzrrzzr}}rwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvv?vv}rwwwwzwzzzzzzzzzzzzzzwzzzzwwwzzz}vwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvv}wwwwwwwzzzzzzzzzzzzzzzz}zzzzzzzww}?v}vvvvv??vvvvvvvvvvvv???vvvvvvvv-<br></br>
                  `vvvrwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvv-<br></br>
`v}wwwwwwwwzzzzzzzzzzzzzzzzzwwww}vvv???vvvv??v???vv}vvvv?????????????????v`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vvv}}}}}}}}}}}}}}}}}}}}}v}}}}}}}}}vv}}}}r~<br></br>
                  `wwwwwwwwwwwwwwwwwwwwwwwwwwzzwwwzzzzxxxxxxxxxxxxxxxxxxxzzzzxxxxxxxzzzzxxxx|<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrrwwwwwrrrrwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
                  `rrwwwwwwwwwwwwwwwwzzzzzzwwwwwwwwwwwwwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
                  `zzzzzzzzzxzzzzzxxxxxzzzzzzzzzzzzzzzzwwwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzxx|<br></br>
                  `zxxxzxxxxzxzzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxx|<br></br>
                  `zxzzzxxzzzzxxzwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
                  `zxzzzzzzzxxzwrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzr-<br></br>
`zzzzwwzxxzwrwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxw}v-<br></br>
`zxzrrzzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzrv}r~<br></br>
`zrvvwzzzzzxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwzw}}}rz|<br></br>
`rv}zzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwrrrrrrrrrrr}v}rv}w~<br></br>
`}rzzzxxzzzzzzzzwwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwrrrrrrr}}}rrrrrw~<br></br>
`wzxxxzzzzwwwwrrrrrrwwwzzwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwrrrr}}rwzwwwrrw~<br></br>
                  `zzxzzwwwrrrrrrrrrrrrrwwwwzzzzzzzzzzzzzzzzzxxzzzzzzzzzzwwwrrrwwwzxzwwrrrrw~<br></br>
                  `zwwwwrrrrrrrrrrrrrrrwwzzzzzzzzzzzzzzxxzzzzxxzzzzzzwwwrrrrwwzzzxzwwrrrrrrw~<br></br>
                  `wrrrrrrrrrrrrrrrrrrrwwwwwwwwwwwwwwzzzwwwwwwwwwwrrrrrrrrwwzzzzzwwrrrrrrrrw~<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwrrrrrrrrrrrrrrrrrrwwwzzzzzwwrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrrwww}}}rrrrrrrrrrrrrrrrwzzzzzzwwrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrwwzzw}vvrrrrrrrrrrrrrrrwwzzwzzwrrrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrrrrrrrrwwzzxz}vvvrwwrrrrrrrrwwzzzzzzzr}vv}rrrrrrrrrrrrrrrwz~<br></br>
`rrrrrrrrrrrrrrrrrrrrwwzzzzrv}wwwwrrrrrrwwzzzzzzwr}vvvvrrrrrrrrrrrrrrwwzzz~<br></br>
`rrrrrrrrrrrrrrrrrr}}rwzzwwwwwwwrrrrwwzzzzzzzzwvv??v}rrwrrrrrrrrrrwwzzzrrr~<br></br>
`rrrrrrrrrrrrrrr}vvv}wzzzzzzwrrrrwwwzzzzzzzzwr}vvvv}rrrrrrrrrrrrwzzzw}v}rw~<br></br>
`rrrrrrrrrrr}}vvvvv}wwrzzzwwwwwzzzzzzzzzzwwwwrvvvv}rrrrrrrrwwwzzzzw}vvrwzw~<br></br>
`rwrrr}}}vvvvvvvvvrzzzzzzzzzzzzzzzzzzzzzzzzw}v?vvv}rrwwwwzzzzzzzzwwwzzzzzz~<br></br>
`}}vvvvvvvvvvvvvvwzzzzzzzzzzzzzzzzzzzzzrzzrvvv?vv}rrrrrrwzzzzzzzzzzzzzzzzz~<br></br>
.vvv?vvvvvvvv?v}zzzzzzzzzzzzzzzzzzzzzzr}rvv????vvvvvv}rwzzzzzzzzzzzzzzzzzr~<br></br>
`vvvvvvvvvvvv}wzzzzzzzwvv}zzzzzzzzzzzzzww}v???vvvvvv}wzzrzzz}vzzzz}rwwzzzw~<br></br>
`vvvvvvvvvv}rr}rzzzzzzzwrwzzzzzzzzzzzzwrvvv??vvvvvvwzzzz}zzzwwr}vv?vvvvvv}-<br></br>
`vvvvvvvvvvvvrwzzzzzzzzzzzzzzzzzzzzzw}vvvvvvvvvvv}zzzzzzzwrvvvv?vvvvv??vvv-<br></br>
`vvvvvvvvvvwzzzzzzzzzzzzzzzzwwwwww}vv????????v??rwwwwrr}vvv?v????????????v`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `zzzzzzzwzzzzxxxzzzzzzxxxxxxxxxzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrrrrrrrrrwwwwwrrrwwrrrrrrrrrrrrrrrrrrr~<br></br>
                  -zzzzzzzzzxxzzzzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwrrrrwwwrrrrrrrrrrrrrrrrrrrrr~<br></br>
                  `zzzzzzzzzxxxzzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwrrrrrrrr~<br></br>
                  `zzzzzzzxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzzzzzzzzzzzwwwwwww~<br></br>
                  `zxzzzzxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxxxzzzzzzzxxxxxzzzzzzx|<br></br>
                  `zxzxxxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxxxzzzzzzzxxzzzzzzzzzx|<br></br>
                  `zxxxxzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzzzzxxxxzzzzzzzxzzzzzzzzzzz|<br></br>
                  `zxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxzzzzzzzzzzxxxzzzzzzzzxzzzzzzzzzzz~<br></br>
                  `xxzzxxxzzzzxxxxzzzzzzzzzzzzzzzzzzzxxxzzzzzzzzzzzzzxxzzzzzzxxzzzzzzzzzzzzz~<br></br>
                  `zzzzxxxzzxxxxzzzzzzzzzzxzzzzzzzzxzxxzwzzzzzzzzzzzzzzxzzzzzzzzzzzzzzzzzzzz~<br></br>
                  `zxzzxxzxxxzzzzzzzzzzzxxzzzzzzzzzxzwrwzxzzzzzzzzxxxzzxzzxzzzzzzzzzzzzzzzwz~<br></br>
`zxzzxxxxxzzzzzzzzxxxzzxxzzzzzzxzw}v}wzzzzzzzzzzxxxzzzzwwzzzzzzzzzzzwwrrww~<br></br>
`zxxxzwwzzzxzzzzxxxxxxzzzzzxxxxr?vvvvwzzzzzzzzzzxxzzzv>?vzzw}wxzwr}vvv}vrw~<br></br>
`zxxzr}wzzzxxxxxxxxxxzzzzzzxxzrv?vvvwzzzzzzwwwzzzzzzzwrzzzzwwwvvvvv???v}rw~<br></br>
`zzzwwzxzzzxzxxzxzzxzzzzzzzzzw}vvvvrzwzzzzzzzzzzzzzwwwwzzw}vvvvvvv???}wwww~<br></br>
`zzzzxxzzzzzzxxzzzzzzzzzzzxzwrvvvv}rrwzzzzzzzzzzzzwr}wzw}???vvvvv??}wzzzwz~<br></br>
`zzwwwzzzzzzzzwrrwzwwwrwzzzz}v?vvvv}rwzzxzwwwzzzwr}vv}vv???vvvv}}rwzzzzwww~<br></br>
`zzwwzzzzw}}}vvvvrwrrrwzzzw}vv?vvvvrrwwzr}wwzzzzv???vvv?v??vvvrwwwwwwwrrrw~<br></br>
`zzzzzzw}vvvvvvv}rwrrwzzz}vv?vvvvv}rrwr}}wzzzzrv???vvvv????v}rrrwwrrrrrrwz~<br></br>
`zzzw}vv?vvvvvvvrrrwwzzwv?v??vvvvvrrr}}rzzzzrvvvvvvvvvvvv?vwwwwwzwrrrwzwww~<br></br>
`zw}vvvv?vvvvvvrrrwzzzwvvvvvvvvvv}rr}}wzzzw}??vvvvvvvvvvv}wzzzzzwrrwwr}vvv-<br></br>
`rvvvvvvvvvvvvrwwwwzzrvvvvvvvvvv}wwwwzzzz}v|-?vvvvv?????vrzzww}vvvvvv???vv-<br></br>
`vvvvvvvvvvvvrwwzzzzrvvv??vvvv?vwzzzwr}r}?~.-?vvvvv?????}wwrvv??vvvvvv?vvv-<br></br>
`vvvvvvvvvvv}zzzzzzrvvv++vvvvvvwwww}vvvvvv-``?vvv??????vvvv???????????vvvv-<br></br>
`vvvvvvvvvvvwzzzzzrvvvvvvvvvvv}}vvvvvvvvvv~.^vvv??????>v????vvv????????vvv-<br></br>
.vvvvvvvvvv}wrv}wrvvvvvvvv??vvvv???vvvvvvv>~vv??????????????>+??????v>>vvv-<br></br>
`vv??vvvvvv}}vvvvvvvvvvvvv??vvvv???vv?vvvv??vv???????????v?^^>???v?v??vvvv-<br></br>
                  `vv?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????vv???????????????vvvv?vv??vv?vvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>???vvv????????v?????vvvvvvvvvvv?vvv-<br></br>
                  `vv?vvvvvvvvvvvvvvvvvvvvvvvv???????v?????????v???????????????????????????v`<br></br>


            </div>,

            <div>

 ......--..`+?}}}vvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}vvv?vvvvvvvvv}}}}}}v?`<br></br>
 ...```^-`^?wxxzwwwwwwwwzzxxxxxxxxzzxxxxxxxxxxxxzzxxxzzwv}wwwwrwwzxzzzzw}v-<br></br>
 ...-``~^vzxxzwwwwwwwwzzzzzzzzzzzzzzzzzzzzzxzzzwwwzzzzz}vvrwrrrrwzzzzz}vvv`<br></br>
 ...~>>?wxxzwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwrwzzzzzvvv}rrrrwzzzzwv??vv`<br></br>
                  -`~vzwxxzzwwzxzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrrrzzzzzzvvvvvrrrzzzzwvv??vv`<br></br>
 -|ruxzzzwwzzzzzzzzzzzzzzzzzzzzzzzzzzwwwrrrrrrrrwzzzzzwv}vvv}rwzzzwvv??vvv`<br></br>
 ^wxxzzwwzzxzzzzzzzzzzzzzzzzzzzzzzwwwwwwrrrrrrrrzzzzzzrwrvvvvrzzzz}v?+?vvv`<br></br>
`zxzzzzzzzzzzzzzzzzzzxxxzzzzzzzzzzzzzzzzwwrrrrrrwzzzzzzzr??vvwzzz}vvvv}vvv`<br></br>
`zzzzzzzzzzzzzzzzzzzzxzxxxzzzzzzzzzzzzzzzwrrrrrrrzzzzzzz}??v}zzzrvr}}w}vvv`<br></br>
`zxzzzzzzzzzzzzzzzzzzxzxxxzzzzzzzzzzzzzzwwwwwwwwwzzzzzzzv??}zzzwrzzrzzvvvv`<br></br>
                  `zxzzzzzzzzzzzzzzzzzzxrwxzzzzzzzzzzzzzzzzzzzzzzzzzzzzrvwvvrzzzzzzzzzzzvvvv`<br></br>
`zxzzzzzzzzzzzzzzzzxxx}}xzxzzzzzxzzzzzzzzzxzrwzxxzzzzzz}}zxzzzzzzzzzzzvvvv`<br></br>
`zxzzzzzzzzzzzzzzzzxzxwwzxxzzzzzxxzzzzzzzwv??zxxxzzzzzzrzzzrzzzzzzzr}x}?+`.<br></br>
                  `zxzzzzzzzzzzzzzzzxxzwwwzzxxxzzzxxzzzzzzrv?vzxzzzzzzzwwzzz??wzzzzzzzxr+`.. <br></br>
                  `zxzzzzwwzzzzzzzzzxxzrwvwzxxxxxzzzzzzzzzzzxxxxzzzzzxw~|zzzzxzzzwzzxz?-.... <br></br>
`zzzzwwwzzzzzzzzzzxxzzwvwzzxxxz}wxzzzzzzzzzzzxzzzzzw|..vzzzwzw??wzv~..```. <br></br>
`zzwwwzzzzzzzzzzzzxxzzvv}zxxxxw}}zzzzzzwzzwrzzzzzzz^..`}zw>}zzzzx}......`` <br></br>
`zzzzxxxzzzzzzzzzzxxrvv??rzzzzrvvwzzwwwrrwwr}wwwzz>..~}zrv~}zzzzx>`~^+>>?v`<br></br>
`zzzzxxxzzzzzzzzzzxxr?vv?vzzwwv??vzwrr}vvrrr}}vzz?..+zz}v>|rz}wzzvvvvvv?vv-<br></br>
`zzzzxxxzzzzzzzzzzzxrvvvvvrzzw?v?vwzwr}vv}rwrvvzw`.?zzwvv|+}vvzrvv??????vv-<br></br>
`zzzzzzzzzzzzzwwzzzzz}vv?vvvvrvvvv}zwr}vvvrwrvvx?.vzzrvvv~>v?v}v????v?|+?v-<br></br>
`wwwwwwrrwrwwwr}wzzzxrvv??vv?vvv??vzzzrvvvrr}vvz}rwv}vvvv~?v?vvv?v??????vv-<br></br>
`wrrrrzw}rrwrrr}v}}rwrvv?????vvv??vzxxrvvvvvv?vzxzvvv??vv^?v?????vv?????vv-<br></br>
`wrrrrzzr}rwwrr}vvvv}}vvvv?vvvvvvv?}xzvv?vvvv?vzzv?????vv??v????v?>+>>?vvv-<br></br>
`zwwrwzzw}vvrwww}vvvvvvvvvvvvvvv???vr}vvvvv???vw}???????vvvv???????vvv?vvv-<br></br>
`zzzzwwzzz}vvzzzwvvvvvvvvvvvvvvvvv?vvvvvvvv?v?vvv?????v????????????v???vvv-<br></br>
`rwzzzzzzzz}v}zzzwv?vvvvvvvvvvvv???vvv?v?????vv??v????>v???????????????vvv-<br></br>
`vv}}wzzzzzzzrrzzzw}vvvv?vvvvvv?-.~?vv???????vv>.?v????v????????????v??vvv-<br></br>
.vvvvvvrzzzwwzzzzzzwr}vv???vvvv?^>???v???????v?-.-?v??vv??????????????vvvv-<br></br>
`vv?vv?vvrw}v}rww}}rrr}vv???vvvvvvv???vv?????v>...|v????vv??????????????vv-<br></br>
`vvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvv?>?vvvvvv|..``+v??v?^+?v??????????vvv-<br></br>
`vv?vvvvvvvvvvv??vvvvvvvvvvv??????????>??????v>`...`?????>????????????????`<br></br>


            </div>,

            <div>

 ..............`````````````````````-``~`.````````+?}}}}}}}}}}}}}}}}}vvvvv-<br></br>
 ..`````.````````````````-----------------`-~^^>?}zxxzzzzzzzzzzzzzzwwwwwww~<br></br>
 ...`````````````````````````--------~-``-^>vrwxxxzzzzwwzzzzzzzwwwrrrrrrrw~<br></br>
                  ..`````````````````````````~~~~-~|^+^~|?vwzzzxzzzzzzzzzzzzzwwrrrrrrrrwwzz|<br></br>
 ..````````````````````````^>~~~~~~~|>vwzzzzzzzzzzzzzzzzzwwwrrrrwwwwzzzzzx|<br></br>
 `````````````````````````-^~-|~~~~>}zzzwzzzzzzzzzzzzzzwwrrrwwwzzzzxxzzzxx|<br></br>
 `````````````````````````````-^?}rzxzzzzzzzzzzzzzzzzwwrwwwwzzzzxxxrwxzzxx|<br></br>
 ```````````````````````````-+}zxzzzzzzzzzzzzzzzzzwwwwwwzzzzzzzzxw?+}xzzzx|<br></br>
 ```````````````````````..->}zxzzzzzzzzzzzzzzzzzwwwzzzzzxxxzxxxxr??vzzzzzz|<br></br>
 ```````````````````````.|}xxzzzzzzzzzzzzzzzzzwzzzzxxxxxzzzxxxzzwwzwwwwwzz~<br></br>
 ```````````````````.`.`>wxzzzzzzzzzzzzzzzzzzzzzzzzzzxxzzzzxzzwwwwrwrwwwzz~<br></br>
                  .--```````-------```~~^vxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwzzzwrwwwzzzzzzz~<br></br>
.^^^^||||~~------++>?vzxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwzwwwzzzzzzzzzzzzzwr~<br></br>
.++>>>>>+^|~~~--+?>?rxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwzzwzzxxxzzzzzrv`<br></br>
.^^^^^+++>>>+^~|>vwzxzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz}rzzrzzzzzzzzr}}?`<br></br>
.???>^||^+>>??vrwxxzzzzzzzzzzzzzzzzzzzzzzwzzzzzzzzzzwwwzz}rzzwwzzzzzzz}vv?`<br></br>
`}}}}?>>>>>?vrzxxzzzzzzzzzzzzzzzzzzzzzzwzzzzzww}rrrrrrwzzwwrzr}zzzxr}zv???`<br></br>
.vv}}?>>>??vwxzzzzzzzzzzzzzzzzxzzzzzzwwwzwwrrwr?vrrrrrzwwzwvv}vwzzv`>z}???`<br></br>
.?vv}}v?>?vzxzzzzzzzzzzzzzzzzzzzzzzwwrrwwrrrrrr}}rrrrwz}rzrvvv?}zx+~rzzv?v`<br></br>
 -^?rwwr}rzzzzzzzzzzzzzzzzzzzzzzzzwrrrrrrrrrrrrrrrwzrwz}?v}v??v?vwrwwrzrvv-<br></br>
 ...-?wwxxzzzzzzzzzzzzzzzzzzwwzzzwrrrrrrrrrrrrrrrrzzrwzv??v????v?vrw}vvvvv-<br></br>
                  `~`..+rxzzzzzzzzzzzzzzzzzwwwzzwwwrrrrrwwrrrrrrrrrwzwwzv??????????vvv???vv-<br></br>
.->?`.~wxzzzzzzzzzzzzzzzzzwzzzwwrrrrrrrrzwwrrrrrrr}wzz}vvv??v????v??????vv-<br></br>
 -|rv~?xxzzzzzzzzzzzzzzzzzzzzwrrrrrrrrrrrwzwwrrrrr}vwrv?????????????????vv-<br></br>
 `-?z?}xzzzzzzzzzzzxxzzzwwwwwrrrrrrrrrrr}}rwzwwwrrvvvrv???vv??????v????vvv-<br></br>
 `-|>>}xzzzwwwwwzzzzzzzzwwrrrrrrrrrrrrrrr}vvvrzwwrvvvv????????????v??v?vvv-<br></br>
 .--|^?zzwwrrrrrrwwrrrrrwwwwrrrrrrrrrrrrrr}??vvrww}vv??????????????v???vvv-<br></br>
 -||+>?rzwrrrrrrrrrrrrrrr}}}rwwrrrrrrrrrwwrvv??vvwwv???????????????????vvv-<br></br>
.??vrwrwzzwwrrrrrrrrwwwwwwrvv}}rwwwzwwwrrrr}v????vrv??????????????????vvvv-<br></br>
`ww}}}rwwzzwwwwrwwwwwwzzzzzzwr}vvvv}}}}rrr}vvv?????v??????????????vvv???vv-<br></br>
`}rwwwwwwzzzzwrvvvvvvv}}}}vvvvvvv??????vvvvvvv????????v??vvvvv?????^|?vvvv-<br></br>
.?vvvvvvv}}}}}}vvvv?????vvvv??????????????????????????????????????~|>?????`<br></br>


            </div>,

            <div>

                  ..............`.................................................-`..`^?vv-<br></br>
                  ..`````````````````````.............```````````````````...`.```````^vzzww~<br></br>
                  ..`````````````````````..............``````````````````...``````-+vzzwwrw~<br></br>
 ...```-``````````````.................`````````````````..``````^}zxzzwwzz|<br></br>
 ```|>?^---``````````................`````````--````````.```..~?zxzzzzzzxx|<br></br>
                  ```~|~|--```````````.................````````-```...``..-.`|?zxzzzzzzzzxx|<br></br>
 ``.....`````````````....................``````......``..->vzxzzzzzzzzzzzx|<br></br>
                  ....... .`.............................................^rxxzzzzzzzzzzzzzx|<br></br>
                  .....--`............................................`?zzzzzzzzzzzzzzzzzx|<br></br>
                  .....`~...................................``````~--vxwwzzzzzzzzzzzzzzzx|<br></br>
.`    ...-.....`.........``````............``````~^+>vwwwwzzzzzzzzzzzzzzxx|<br></br>
                  .~    ...-^```````````.`````````.....`````````--|^+?vwwrwwzzzzzzzzzzzzzzzz|<br></br>
                  .`     . .`.|^|||||||~~-````````````````-----~|^||^?rwrwzzzzzzzzzzzzzwwwww~<br></br>
                  ..     ..``~|^^^^^++^^|~--````````````--------~||?rwwwzzzzzzzzzzwwrwrrrrr~<br></br>
                  ..    ...`-``--~~~||^^^++^~--```````````````-``+?rwwzzzzzzzzzwwrrrrrrrrrr~<br></br>
       ......`|+??>+|~~~^^+>++^|~~-``````````.-+?wwzzzzzzzzwwrrrrrrrrrrrrr~<br></br>
        .....-}}}}}v?+^^^+>????????>>+^~`....-vwwzzzzzzzzwwrrrrrrrrrrrrrrr~<br></br>
         ....`>?vvvv?+++>????????????????+|-|vxzzzzzzzzzwwrrrrrrrrrrrrrrrr~<br></br>
   ..    ....``~?vvvv?+^^^+++>>>>>??????vvvvrzzzzzzzzzzwrrrrrrrrrrrrrrrrrr~<br></br>
  ...     ......-+?}}}vv?>>++++++++>>>>???}zxxzzzzzzzzwwrrrrrrrrrrrrrrrrrr~<br></br>
  .....    ...... .~vwrrr}vvvv????????????rxzzwzzzzzzzzzwwrrrrrrrrrrrrrrrr~<br></br>
  .........   ..--...>}}}vvvv}}v}}}vvvv}}rzzwwrrwwzzzzzzwwrrrrrrrrrrrrrrrr~<br></br>
 ...............~??...>}}}}}}vvv}}}rrwzzzxxwwrrrrwwwwwwwrrrrrrrrrrrrrrrrrr~<br></br>
.`......-.......-+w?..-rzw}?>^^+>>???v}rwzzrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
 .......`~......`~}z~..^?>~`````-~~~||+>vwwrrrrrrrrrrrrrrrrrrrrwwwwwwwrrrr~<br></br>
 ........-`.....`-^>~.````````````-^?v???vrrrrrrrrrrrrrrrrrrrrrrrrwzzzzwww~<br></br>
 ........``......``~-...```````````-|?vwwzzwwwwrrrrrrrrrrrrwrrrrr}}}rzwzzz~<br></br>
 ```````.```````--~||~---`````````````-+?}rwzwwwr}}}rrrr}}}rwwwrr}v??v}rzz~<br></br>
.+>+>>>?>>>>>>????????>>>^^++++++^+++^^>vrzzzzwzzzwwrrrr}}}}rrrrwwrr}vv}rz~<br></br>
.^++>>??vvvv??????????????????????????>+++>??v}}}}rwwzzzzzzzzzzwwzzzzzzzwz~<br></br>
.---~~||||||||||||||||^^^^^^^^^^^^+>>>>>>>+^^+>??v}rwzzzzzzzzzzzzzzzzzzzzx|<br></br>
 ....`--~||^^|||~~~|||~~||~-```````----~~~~~+>??v}}rrrwwr}}}}wwwwwwrwwr}vv-<br></br>


            </div>,

            <div>

  .............`...................................................``.|?}}~<br></br>
                  ....``````````````````````````......``......```````````.`````-```-|?wxzx|<br></br>
  ......````````````````````````..................`````````````-``~>rzzzzz|<br></br>
   ......`---```.```-````````````````````..``......`````````````-+}xzzzzzz|<br></br>
    .....``-~~~``~>>^---````````........`````````.`````````````~?zxzzwwwzx|<br></br>
                  .  ....`````~|~~--`````````........````````...````-`````-?rzwwwwwwwzx|<br></br>
                  ...  ....``......``````````....................``...``-^vwwwrrrrwwwzx|<br></br>
                  ..... ...........`............................`....`|?rwwwrrrwwwzzzx|<br></br>
        .``.. ......`-``..............................``~>}wwwwwwwwzzzzzzx|<br></br>
 .      ..```........`-...............................`>vwwwrwwwwzzzzzzzzx|<br></br>
                  .       .||||`. .....-`...``......................``~vwwrrrrwzzzzzzzzzzzx|<br></br>
 .       .^+||~. .....-^-`````````...```````````````~>?vwrwwzzzzzzzzzzzzww~<br></br>
                  .         ...`........`.|^|||~~~~~-````````````--~|+^?wwwzzzzzzzzzwwwrrrr~<br></br>
 .            .........``-|^^^|^^^^^|~--```-------~^>vwzzzzzzzzzzzwwwwwwww~<br></br>
              .........`-``--~~~~~||^^^^~--``-```~>vzxxzzzzzzzzzzwwzwwwwww~<br></br>
  .           ............`|+??>^~~~~~|^>+^|~--|>vzxzzzzzzzzzzzzzzzzzzwwww~<br></br>
  ...         ............-}}}}}v>+^^^+>??>>>?}wxxxzzzzzzzzzzzzzzzzzzwrrrr~<br></br>
 .....        ..........``->?vvvv?+++>vrwv??vwxxxzzzzzzzzzwwwzzzzzwwwrrrrr~<br></br>
                  .....        ............``~?vvvv???vwwwwwzzzzzzzzwwwwwwrwwzzzzwwwrrrrrrr~<br></br>
 ......    ................`.`^?v}}rzzwrrwzxzzzzwwwrrwrrrrwzzwwwrrrrrrrrrr~<br></br>
                  ...... ..... .............``...-?zzzwrwwzxzzzwwwwwwwwwwwwwwwrrrrrrrrrrrrr~<br></br>
                  .....`......  .............`~|`-?xxzzzzzxxzwwrrrrwrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
 `.....-~....`..............`~>vvxxxzzwwzzzzwrrrrrrrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
                  .-`.....^`..`-`...`..`.......-|rxzwwrrrwzzzwrrrrrrwwwrrrrrrrrrrrrrrrrrrrrr~<br></br>
 .......-~..`.....``..-......`-vzwr}}}}rrwwrrrrwrrrrrrrrrrrrrrrrrrrrrrrrrr~<br></br>
 ....`..`~`.`......`..``......~+?v}}}rrrrrrrwwwrvv}rrrrrrrrrrrrrrrrrrrrwww~<br></br>
 ....`...|`.`.........``...`|>?vvv}rrrrrrrwwzw}vv}rrrrrrrrwwrrrrrrrrwwzzzz~<br></br>
 ``.``..`-`````````````-````-~|^+?v}}}}}wwwr}}vv}rwwwwwwwr}v}rrrwwwwwwwwww~<br></br>
.>????>>+++++++>>>????>>>>>???????v??????vvrwzwwzzzzzzww}vvv}wzzzzzzw}vvvv-<br></br>
.>???????>>>++^+>>???vvvv???????????????>????vvvvvv}}}}}rwwwwrrrrrwzzww}vv-<br></br>
.^+^^^^^^^|||~~~-~~|^^||||||||||^^^^^^^^^^^^^^++?????vvvvvvvvvvvvvv}}}}vvv-<br></br>
                  ````--~-``.`.....`--~||^^|||~-~~~~~~~~~-`````-~~||^+?vvvvvvvvvvvvvvvvvvv?`<br></br>


            </div>,

            <div>

              .`........`.............................................`^?}~<br></br>
                  .            ....`````````````````````````....``..............`````|?vzxx|<br></br>
               ....````-`````````````````````..................``~>vwzzzzz|<br></br>
                  .....`-----`.``--`````````````````.``....``.```^?rxxzzzzzz|<br></br>
   .              ....`-~~|`.|>>^---`````````....```````..`.-|vzxzzwwwwwww~<br></br>
                  .        ....`````~~-~~-``````````....```.```..`^vwzzwwwrrrrrrw~<br></br>
                  ..             ..   ...``......```````````...........`|?wzwwrrrrrrrrrrw~<br></br>
                  ..     .....  ..............................`?wzzwwwwwrrrrrwwww~<br></br>
     .     ..       .`...  .....`-`....................+zzwrr}??+?v}wwwzwz|<br></br>
     ..     ..       .```.   ....`-.............`.....~?>^~--`...|?wzzzzxx|<br></br>
     ..     ..       .>+^^-  .....-`...````..........```.`.....-?wzxzzzzzx|<br></br>
                  ..     ..       .^+||~.   ...-^-``````````.``````````````^vwzzzzzzzzz|<br></br>
                  .          ....    ...`.~^||||~~~~~-`````~````~+vxxzzzzzzzzzz|<br></br>
     .       .            ..    ...``-||^^^^^^^^|~--`````~>vzxzzzzzzzzzzzx|<br></br>
                  .       .            ..    ....`-|^^~~~~~|^^^+^|-`|+vzxzzzzzzzzzzzzzx|<br></br>
     ..       ..                ....~??>>??>^~~~~|^+??vrzxzzzzzzzzzzzzzzzx|<br></br>
     ...    .....            .  .-?r}^-v}}}}v>^^^>?}zxxxzzzzzzzzzzzzzzzzzx|<br></br>
 .......   .......           .-+}zz}-``>?vv}}}vvrzxxxxzzzzzzzwwwwwzzzzzzzz|<br></br>
 .......   .......       . `^vzzzw}-.`-^?}wzxxxxxxzzzzzwwwwwwrrrwwwwwwwwww~<br></br>
 .......   ........     . ^rxxzwrrv|^?}wzzzzzzzzzzzwwwrrrrrrrrwrrrrrrrrrrr~<br></br>
 .......  ......... . ...>xxzzwwrrrwwzxzzzzzzzzzwwwrrrrrrrrwzzwwrrrrrrrrrr~<br></br>
.~``````..........``..`^}xxzxzwwwwwzzzzzzzzzzzzzwwwrrrrrwwzzwwwwrrrrrrrrrr~<br></br>
.~``..~|^+|^-``...`~|`>zxzzzzzwzzzzzxxzzzzzzzzzzwwwrrrwwzzzrrwwrrrrrrrrrrr~<br></br>
 .``.``...`^+|.....`?vzxzzzzzzzzzzzzzzzzzzzwwwwrrrrrwwzzwzr}rrrrrrrrrrrrrr~<br></br>
.--|.. .-|^..``....`}xzzzwwwwzzzzzzwwwwwwwwrrrrrrwwwzzzw}v}rrrwwwrrrrrrrrr~<br></br>
.~`^`.-++^|`.....`.+xzwwwwrrrwwwrwwwwwrrwwrrwwwwwzzwwzr}v}wwzzzzwwwwwwwwww~<br></br>
 ``~`.>~.-.`.....`.~}wwrwwwwwwwwwzzwzwwzzwwzzzzwwwzzzzwzzzzxzzzzzzzzzzzzzz~<br></br>
 ..`-``..-...``.`-`-?wzwwrwwwrrrrwwwzwzzzzwrrr}v}wzzzzzzwr}}r}}}rzwwzwzzzx~<br></br>
.|>>+^^+>>>++???????????>>>?????vvvwwr}vvvvv??vvvvvvvvvv????????vvvvv}}}v}-<br></br>
.???????????????????>>>+^^^+>?v}rwwr}}}vv??vvvvvvvvvvvvvvvv?????????????vv-<br></br>
.++++>>>>>>>>>>++++^^^||~----~~||^+>>>>+^++>>?>>??vvv}}vvvvvvvv?????v??vvv-<br></br>
 `-``````..````--~~--``.......`-~~~|||~~~~~~~~~~~~||``-``-|^>>>???????????`<br></br>


            </div>,

            <div>

                       .``.......................................`|vvvvvv}~<br></br>
                  ..... ......``````````````````````...........```^vwwwzzzzx|<br></br>
                  .......``````````````````````......`...`^vwwwwwwzzzz|<br></br>
                  ........`----``````````````````````.``~?rwwwwwwwzzwz|<br></br>
                       .......`---~~-``^++-`````````````.``+}wwwwwwwwwwwrw~<br></br>
                         .......```````~-~~-````````````.`>wwwrwwwwrr}v}rw~<br></br>
                          ..... ....``.....````````````.-vzwwwwwrv??>?vrrw~<br></br>
                    ..      ....  ....................`+rxzwr}v?>^^>v}wrrw~<br></br>
              ..    ...      ..`.........`--`........`?}}v?>^|~``->}wwrwww|<br></br>
                  .     ..       .````........-`.....``.`-~-````...`+vwwwwwzx|<br></br>
               .     ......    `|||~. .....``....`-`.....``...->}zzzzzzzxx|<br></br>
               .      .....    `^~~|.......`~~``````````````->wxzzzzzzzzzx|<br></br>
                  .      .           ..........`.`^|||~~~~~~~^?rxxxzzzzzzzzzz|<br></br>
   ...         .                   ........````||^^^^^^^>vwzxzzzzzzzzzzzzx|<br></br>
 .....         .                   .........``----~|^>?vwxxzzzzzzzzzzzzzzx|<br></br>
                  ......       ..                    ...........`~+?vvwzzzzzzzzzzzzzzzzzzxx|<br></br>
                  .....       ...      ...       ...```-|~.`.`-^vwxxxxxzzzzzzzzzzzzzzzzzzx|<br></br>
  ......    .....     ..... ....`|>>?}v?|..-+vrzzxxzzzzzzzzzzzzzzzzzzzzzxx|<br></br>
                  .....  ........   ........`~^vwzwr?~.`|?rzxxxxxzzzzzzzzwwwwwwwwwzzzzzzz|<br></br>
   .```...........  ......`~+vrzzzw}v?^?vzxxzzzzzzzzzzzzwwwwrrrrrwwwwwwzwz|<br></br>
                  .```........... .....`|vwzxzzzwrrwwwzzxzzzzzzzzzwwwwwwrrrrrrwwwwzzwwzww|<br></br>
                  ...``-..`^`..`.`.....-??rxxxzzwwwwzzzzzzzzzzzzzzzzwwwwwrrrrrrrwwwwwwrwwww~<br></br>
 ...``~-^~^~-``.^-^~+^rxxxzzzzzzzzzzzzzzzzzzzzzzzzwwwwrrrrrrrrwwwr}}rrrrrr~<br></br>
 ...``-|-```~````..`?zxxzzzzzzzzzzzzzzzzzzzwwzzzwwwwrrrrrrrrrwwwr}rrrrrrrr~<br></br>
 ...-````^|.|`.. ``>zzzzzwwwwwwzzzzzzwwwwwwwzzwrrrrrrrrwrwwwrrrrrrrrrrrrww~<br></br>
 ...~`-~|+|`~~..|+^wzwwwwwrrrrrwwwwwwwwwwzzzzwrrrwwzwwwwwwr}vvv}}}}}}}rwww~<br></br>
 ...-`~-~+-``~.|^.-vwzwwwwrwwwzzzzwzwwzzzzzzzzwzzzzzzwr}}}vvvvvvvvv}}}}}}}-<br></br>
 ...-`-``|...~.-..-~+v}rrrrrrrrrrrrwrrrr}}}}}}v}rrrrr}vvvvvvvvvvvvvvvvvvv}-<br></br>
.^>++^|~-`~+>>+^>>>>>>>???????????????vvv????vvvvvvvvvvvvvv?????vvvvvvvvvv-<br></br>
.???????>>??????????????????>>?>>>>?????v??????????vvvvvv???????????????vv-<br></br>
.+++++>>>>>+++++>>>>>>>>+^^^^||||||~~~|^^++^^^++++>>>??????vvvvvv??vvv?vvv-<br></br>
 ```````````-```````````````-``````....`-~~~~~||~~~~~~~|~|^^++>>>?????????`<br></br>


            </div>,

            <div>

                               ..`....````.....................-?}vvvvvvv}~<br></br>
                  ....`````````````````````````-?wzzzwwzzzzx|<br></br>
                                .....```-```````````````````>rzzzwwwwwzzzz|<br></br>
      ..                         ......`----``````````````|vzzwwwwwr}vvvrw~<br></br>
       ..                          ....``~~|~``+>>~--````>rzwwwr}?>^~~+?rw~<br></br>
       ..                             ...`````-|~~~-``.`?zzwwrv>|````+vrrw~<br></br>
        .                          ...   ...``.....````?xzwwrv^-```^?}rrrw~<br></br>
        .                   ..      ....  ...........`?xzwv+~``..`>}wrrrrw~<br></br>
                      ..    ...      ..`..  .....`--`~>>+~`.....~?rrrrrrww|<br></br>
                  .     ..       .````.  .....``......`..`|vwrrrrwwzx|<br></br>
                       .      .        ~>+^|.  ....`-..`....`^?rrrwwwzzzxx|<br></br>
                       .      .        `+^||`   ....~|````~>vrwwwwzzzzzzzx|<br></br>
                  ...           .      .           ...     . ...+?vrwwwwzzzzzzzzzzz|<br></br>
         ....          .                   ... .. ...~+}xxzzzzzzzzzzzzzzzz|<br></br>
                  .........        ..                    ..    .~+rxxzzzzzzzzzzzzzzzzzx|<br></br>
                  ...........        ..                       .|?rzxzzzzzzzzzzzzzzzzzzzx|<br></br>
    ..... ......       ..     ....           .~>}zxzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
   ......  ..`..    ......   ......  .    .~?}zxxxxzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
   .....   .```.  ........   ........  .|?}zxzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
   ....`   .````.........    ..``-~-|+>vzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
 .-|..``   .```.``.........`|~~+?v}rzwwwwwwzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzx|<br></br>
 |^|..``   .``-..-+``.->>?vww}rzxzzwwwzzzzzzzzzzzzzzzzzzxxzzzzzzzzzzzzzzzx|<br></br>
.~`...-`   .-`~-^~^-~>rxxxxzzzxzzzzzzzzzzzzzzzzzzzzzzzzwwwwzzzwzzzzzzzzzzz|<br></br>
                  ....`~`   .~`~+-``|vzxxxzzzzzzzzzzzzzzzzzzzzzzzzzzzzwrrrrrrrrwwzzzzzwwwww~<br></br>
 ....`|-   .|`--.>vwxzzzzwwwwwwwwwzzzxxxzwrwwzzzzzzzzwwwrrrrrrrwwwwwrrrrrr~<br></br>
 `...`~- . .~`-~^wzwwwwwrrwwwwwwrwwzzzzzzwwzzzzzwwwrvv}}}}}}vv}}}vvvvvvvvv-<br></br>
                  .`...`~~ ...-~|~^vrwrwwwwwwwwzzzwwzzzzwwzzwzwzzzzwrvvvvvvvv?????????????vv-<br></br>
.`...``~....--~-`|`--+^|>?vvv}rrrwwwwr}vvvvvvvv}}}vvvv??????????????????vv-<br></br>
.~-~~^++++^^++^~```^>>^^+>??v??v?v????????vvvv???vvvvvvvvvvvvvvv?v??vvvvvv-<br></br>
.+>>>>>????????>>>???????????????????>>>???????????????????????????v?v?vvv-<br></br>
.|^^^^+++^+^^^+++>>>++>>>>>>>>>>++++^^^^||~~~~~||||||||||||^^^^^^+>???vvvv-<br></br>
                  ```.``````````````````````.```````---```......``-~~~|~~~~~~~~~~~|^+++++++.<br></br>


            </div>,

            <div>

                  .```.....................`^^^^^^^^^^.<br></br>
                                    ....``````````````````````~>?>>>>>>>>>.<br></br>
                                    .......`````````````````|+>?>>+>>>>>>>`<br></br>
                                   . ........``---`````---~+???>>>++^^|^^+.<br></br>
              .                        .......`~^^~-`-^+^^????>>^~-````-|^.<br></br>
                                         ......`---``-~~+???>+^~```.`-|^^^.<br></br>
 ..                                       .........`..`-^+>+|~``````|+^^^+.<br></br>
 ..                                 ..     .... ......-++~-```....~^++^^+>`<br></br>
 ..                         ..     ......   .........``---``.....-^++^^>vw|<br></br>
                             ..     .. ....  .............-`..``~++++>?rzx|<br></br>
                              .      . ...... `-```.......``.`~+>>>>vwxzwr~<br></br>
                              .      . ...... `~``-.......`^++>>??}wxxrvv}-<br></br>
                .             .                  .......``|>^>vvrzxzwrv}wz|<br></br>
   ...            .           .                   .....`-|+>>?v}r}}}}}rwwz|<br></br>
   .....   .........          .                   ...`-~|^+>???vvvvv}wzzzz|<br></br>
    ..... ...........                 .          .`--~~~|^+^^+>v}wzzzxzzzx|<br></br>
    ..... ...... .....       ....     ...    ...`~||^~~~~|^>??}zzxxzzzzzzx|<br></br>
   .....  ...... ......   ........   ....  .`--~~~~~^^+^^>vwzxxzzzzzzzzzzx|<br></br>
   .....   ..``   ..... ...........  ...`--~|||~|~|^>??vrwzxzzzzzzzzzzzzzx|<br></br>
   .....   ..``   ..``...............`-^??+|||^>??vrwwwwzzzzzzzzzzzzzzzzzx|<br></br>
 ........`..`-`.....``.`...``....`-|^++>????vv}wzxzzzzwzzzzzzzzzzzzzzzzzzx|<br></br>
 ......```..`-....````.`^>^>+++++++?v}}}}rzzzxxxzzzzzzzzxxxzzzzzzzzzzzzzzx|<br></br>
 ......`....`~....`--^>??v??>??vvrrzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwzzz|<br></br>
 ......`....`|.....~+v}?>?>????vwzxxzzzzzzzzzzzzzzzzzwwwzwwwwwwzzwwwwwrwwz|<br></br>
 ......`....`-....-??v??v??v?v}zzzzzzwwwzwwwzzxxzzzwwwwwwwrrrrrwwrrrrrrrww~<br></br>
 ......`....-~`..`|???>vv??}wwwwwwwwwwwwwwwwzzzzzwwwrrrrrrrrrrrrrrrrr}}vvv-<br></br>
 ......-....-|`..`~???+?vvrzzwwzzzzzzzzwwwwzzzzwwzwwrrrrrrrrrrrrrrrr}vvvvv`<br></br>
 ......-....-|-....-~|-~|`~>????????vvvvvvvvvvvvv}}vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.~~--~~||||^+++^|||^+|---~+>?+^+??>>>???v?????????vvvvvvvvvvvvv?vvv?????vv-<br></br>
.+>>>>>>>>>?>?????????>>?????????????????v??????>>>>>???????????????????vv-<br></br>
.|^^^^^^^^^^^+++++^^^++++>>+>>>>>>>>>>+^^^^^|||||~~~~|^|||^^^^^^^^^^+>>>>>`<br></br>
                  ````````````````````````````````..```````````..``..```-~~|~~~~~~~~~||||||.<br></br>


            </div>,

            <div>

                  ...````....................... <br></br>
                  ....`````````````````````````` <br></br>
                  .....```````````````````````. <br></br>
                  ..                         .....``----``.``````````````.<br></br>
                    ..                         ......`~^|~``|>>~--```````` <br></br>
                  .            .             . ....`--``-~~~~-`````.``` <br></br>
                  ..                                       ... .....``.....`````````` <br></br>
                  ...                               ...     ..... ..................`.<br></br>
                  ...                         ..     ..       .... ......``-`......``.<br></br>
                  ..                           ..    ..   .    ..... ......`-....`-~-.<br></br>
                  ..                           ..     .   ...  `||~~` .....`-..`-~~-`.<br></br>
                  .                            .      .   ...  .^~~~` .....`~^~||~```.<br></br>
                  ..                           .      .            .... ....```++^^^+.<br></br>
         ...          ....          .                    ... ....```|^^>>>.<br></br>
 ...      ....    .........         .       .            .. ......`-~~|+>>.<br></br>
 ....     ....   ...........        .        .                ......`~>?vv`<br></br>
 ......   ....   ..... .....        ...     ...              .....```>zwww~<br></br>
 ......   ....   ...`.  .....    ......   ......         .........`-~^v}rr~<br></br>
 ......  .....  ....`.  ..``. .......... ........    .............``-~|>v}-<br></br>
                  ...``.. .....  ....`.  .``-`......................................``--`^?-<br></br>
 ...``.........`~..``.  .``-```........ ....```~-.....`....```.......`-~>v~<br></br>
                  ..```........-~~..``   .-`-`.`+-`..```...`-~|~||~-.```....``-```-`-^?vwzx|<br></br>
 ..```.......`-`...--  ..~`--|~^~~--~>>?+>+^~~``-|>+---~|~`-|^>?vvrrwzzzzz|<br></br>
 ..```........`....-- .  -`->|```|~~~-`-~^??^~---^?}??>v}}v}wzzzzzzzwzzzzx|<br></br>
 .````........`....~~    ~`~|`>?~>^~`.`^>+~+?v}rwzzzwwwzzzxxzzzzzzzzzwwzzx|<br></br>
 ```.`.......``....~~... ~-^^+v?^|+`.^??v}}wzzzwwwzzzzzzzzzzzzzzwwwwwwwwww~<br></br>
 ```.........``....-^... ~^>++v>|~>`~?+vwwwwwwwwwwwwwwzzzzzzzzzzzzzzzzzwwz~<br></br>
 `````........`....-|....`-|~`+`..|`-..~|~+???????vvvvvvvv}rrr}}}}}rwwwwwz~<br></br>
.~|~|~~~~~--~~~~|^^>>++^^^+^~-``^>>+^+>>>>>>???????>>?>>>?????vvvvvvvvvvvv-<br></br>
.|||^^^^++++>>>>>>>?????????>>>????????????????????>++++^^++>??vvvvvvv????-<br></br>
 ``-~~|^^^^^^^^^^^^^^+^^^^^^+++++++>>>>>>>>>>+^^^^^|||||~~--~||^||^^||||||.<br></br>
                  .`````-----```````````````````````````...```````````........`~~||||~~||~~.<br></br>


            </div>,

            <div>

                  ..````....................... <br></br>
                  ...`````````````````````````. <br></br>
                  .....``````````````````````.. <br></br>
                  ..                          ....```---``.`````````````` <br></br>
                    ..                           ....`~|~~``^>>|--```````` <br></br>
                  .            .                ...`````-~~~~-`````..`` <br></br>
                  ..            ..                         ...  ....``.....`````````` <br></br>
                  ...           ...                 ...     ....   .................. <br></br>
                  ...              ...        ..     ..       ....   ....``~`........ <br></br>
                  ..               ....        ..    ..        .``..  .....-~........ <br></br>
       ..               .....       ..     .        `>+^|`   ...`-.`````.. <br></br>
                  .                ......      .      .        .+^~~`   ....~|``````..<br></br>
                  ..              ..........   .      .            ..    ......+^^^||.<br></br>
                  ...          ...........   .                    ..    ..```|^^^^+.<br></br>
                  ..       ....    ...................       .            ..   ....`--`--~|.<br></br>
 ....     ....   ........``............     ..                 ......`^>??`<br></br>
 ......   ....   ..... .`.``...........     ...                 .....+r}}w~<br></br>
                  ......   ....  ....`.  ...`...........   ......          ..     ...`~??vr~<br></br>
                  ......  .....  ....`.. .`.`....``...............         ...    ....``|?v~<br></br>
 ..```.. .........```......`.....`.....................    ..     ......->-<br></br>
                  `````.. ..````-`..``....`.-`....`.........``..........    ...     .....`~.<br></br>
 .````.......`~^~..`-....``--``~.`````....````..``......  ..... .....-|>?v`<br></br>
 .````........``...``....~-~~~~~`````~-~`---``..````.........`-||^^^>??vrw~<br></br>
 ``---.............``. ..---^~``.`````````~~-`..``|^```~|^^^>??vv????v}wzx|<br></br>
 ``--`.......`....`--....````.`-`||`...--`.`^++>>?v}vvvvvvvv???v}}v}rrrwzx|<br></br>
 `````........-`.``~~....`````~-`--`.-||+>??v}}}}rwwzzzzzzzzzzzzzwwwwwwwww~<br></br>
 `````........-`..`~|....`---`-`.`~-^?+?vvrrwrrrrrrrrrrrrrrrr}}}v}}}}vv}}r~<br></br>
 `````........`..``--....--~~`-...`.`..```-^++^^^^+^++++^^^+>>>>>?v??vv}wz~<br></br>
.~||||~~~--`....``----```--```..---`````````----~~~~||~||>????vv}}}vvvvvvv-<br></br>
.|||^^^++|~~~----~~~~~~~--~-~||+>>+++>>>++>>>??>+^^^|^^^^^++>??vvvvvv?????`<br></br>
 ```-~|^^^^^^|||||||||||~-``-~|^^^^++>>>>>>>>+++++^^^|||~---~||^||^^||||||.<br></br>
                  .`.```----``````````````..........````...``````---``........`-~||||~~|~~~.<br></br>


            </div>,

            <div>

                  ..````....................... <br></br>
                  ...`````````````````````````. <br></br>
                  .....``````````````````````.. <br></br>
                  ..                          ....```---``.`````````````` <br></br>
           .....    ..                           ....`~|~~``^>>~--```````` <br></br>
                  .`........   ...      .                ...`````-~~~~-````...`` <br></br>
                  ..    ..`.......   ....                  ...  ....``.....``.``````` <br></br>
                  ...   .``...`````.  .....         ...     ....   .................. <br></br>
                  ...    .....`````-`........ ..     ..       ....   ....``~`........ <br></br>
                  ..      ....``````~~-``..... ..    ..        .``..  .....-~........ <br></br>
                  ..      ...```````-~~-``.......     .        `^+^|`   ...`-.`````.. <br></br>
                  .        ......``````.............  .      ..`^^|~`   ....~|```````.<br></br>
                  ..         ......```............`.. .      .``.  ..    ......+^^^||.<br></br>
                  ...     ........``...........`.``.      ..`..   ..    ..```|^^^^+.<br></br>
                  ..       ..............`--`....``....`.``...    ..`.    .    ....`--`--~|.<br></br>
 ....     ....```````----~|~~`..`~-...`..`.....  ..`.. .   ..........`^>??`<br></br>
 ......   ............``--~~~~~`..``..`...`................`````.....+r}}w~<br></br>
                  ....................`.````----`........ ..............`````.````````~??vr~<br></br>
                  ....`````````.`--~`.`````````...`.....`...```......```.......`````````|?v~<br></br>
                  .```--````--`...`-~~`.`....``..-~....`--``--````..````.................-+`<br></br>
                  ``````````-~~`....```.....`--```~-......``-~-`--````````................  <br></br>
                  .````.............`-``...-~||-```~`.......`~~`-~~-``....``.```.......```` <br></br>
                  .````.............``-```..`````............``---``........````````...`-~?`<br></br>
 `---`............................`----`....```...............```...```-->~<br></br>
                  ``--`...`.```````--~~-``........`--``--`................`````~+?vvvvvvvvw|<br></br>
 ```-`...```...-~~~~~```---``........``````-^^++^+++>>?+|+?v}wzzwzwwwwzzww~<br></br>
 ```---``--`...-----~`.`-~~~-`....`~+>+++^+?vvv?|~^>>>?>>>+^^+++^||~~|>>??`<br></br>
                  ```--```---...```....`-~~~||~`.....``.....`~~-``````.......```````--~~|^+.<br></br>
.~||~-`.........````.....````..........     ..   .......`-^>>??vv??????vvv-<br></br>
.|||-...``....................`-~~---~^|||^|~~~~~~~----~^++>>??vvvvvv?????`<br></br>
 `---~~~~|~~~~~~~-``.........`--~~||||^+>>?>>>++++^^^|||~---~~|^||^^||||||.<br></br>
                  .`````----~~--~~~~|~~-`............```...``````---``........`--||||~~|~~~.<br></br>


            </div>,

            <div>

                  ..````....................... <br></br>
                  ..                       ...`````````````````````````. <br></br>
                  .....             ......                   .....``````````````````````.. <br></br>
                  ............    ..........                  ....```---``.`````````````` <br></br>
    .......``-`.... ...........                  ....`~|~~``^>>~--```````` <br></br>
                  .......`~~~-``... ...-`........               ...`````-~~~~-````...`` <br></br>
                  .......-~~~~~~--`...`~-`.........         ...  ....``.....``.``````` <br></br>
                  ......-|~~~~~~|~-...`~~-`........ ...     ......................... <br></br>
                  .......-~~~~~~|||~-``-~~~-....... ...       .``..  ....``~`........ <br></br>
                  ..    ..`-`-~~~~~----`-~~~~..........       .`-``.  .....-~........ <br></br>
                  ..     ....```-~~-```.``````..`.....`       .-|||~`   ...`-.`````.. <br></br>
                  ..     ........`-~`...........--`...`.     ..~||-~`   ....~|```````.<br></br>
                  ..   ...........`~~`......``..`~~`....    ..`~`....    ......+^^|||.<br></br>
                  ...............`-`..........`~~``....  ...~~.   ........```|^|||^.<br></br>
                  ..     ............``------`..``.....-~~`........`~-...........```------~.<br></br>
 ....  ......-~~-````~~~~~||~-```~`...`--`........--`.......`.......`-|^+>.<br></br>
                  ..........``~~-```...`-~-~~~~~-``....````........~-...``----```-``..|????`<br></br>
 ..``.`--````-~--``.``.`-```~~~`...........```....````~~~~-`..``~~~--~+>++`<br></br>
                  .```-~~~~-~-`.`-~~-`````...`-`.............--..```-~~~--``...``````--`--~.<br></br>
                  ``--~~~~~~~~`...`-~-`......--``````........```-~-~~``.........`.........-.<br></br>
                  ````---~~--``.............`-~~~~~~-.........`~~~~|~~`...````............. <br></br>
                  ``...............``--``..````----``..........``---`.....````--```..```..` <br></br>
                  `````.............``--``............`````..................``--``....```-.<br></br>
                  `---``.``...```````````...........-~~~-``.`.............``........`--`....<br></br>
 ``--``.```.`---~~~~~~~~~-`........```--`................``...`~+?v}r}v???`<br></br>
 ``--``````..``~~~~~~`.`-~~-`.........``````-~||~-~--~|~`--|^+?}r}}vv}rwwr~<br></br>
 `---~~~-```..`-~~---``-~~~~~-`....`|^^||~||>?>+~-```~^|~-`...-|~-````~|^^.<br></br>
                  ```------``..........`-~~~~~~-.............``----```..... ..``..````----~.<br></br>
 ```...........``-~~-`................        ............`~^+>??>?>>>???v-<br></br>
 ````..........`..``-``........`-~~-`-~|||~-----~---`````-||^>?vvvvvv?????-<br></br>
 --~~~--~~~~~-```...........`--~~~~~~||^+>>>>>++++^^|||~~---~~||||^^||||||.<br></br>
                  ````--~~~~~~~~~~~-``................```..``````---``........`--||||~~|~~~.<br></br>


            </div>,

            <div>

                  ................    .        ..```........................ <br></br>
                  ..   ........ .....``...........   ...      ...`````````````````````````` <br></br>
                  .`-``..............`-~--``........ ....     .....``````````````````````.. <br></br>
                  .`~~~~~~~~~-```....``-~||~-`............      ....```---``.`````````````` <br></br>
 ..`-~~~~~~~~~~~~-`````~||~~~~-`..........       ....`~|~~``^>>~--```````` <br></br>
                  ....`~~~~~~~~~~~~~--```~||~~~~~~`...........      ..`~~```-~~~~-`````.``` <br></br>
                  .....``-~~~~~~~~~~~-```-~~~~~~|~...``.......  ... .`~````.....``.``````` <br></br>
                  ....```---~~~~~~~~`....`````~~`..`-`````..   ....`~```................ <br></br>
                  ...........```-~~~~`..............``.-~`..     ..-~-......``~`........ <br></br>
                  ...........````--~~-``.....```.....``.--..    ...-~~`.......-~........ <br></br>
                  ...........````````-~~-```.....``...-~~`.-`. ......-~-`.......`-.`````.. <br></br>
                  ...........``````````-~````.........`~-`.``........~~`...  ...`~~``````` <br></br>
                  .........`````````-~~~~----````.....``..``......`-~`........`````~~||||.<br></br>
                  .    ..````-~--``..`-~~~~~~~~~-`-`...````.`-`..```~|~`.`````-~~~~-`~|||~|.<br></br>
                  ... ....`--~~~-``....`~~~~~~~~~--``..`~~`.`~-`````~~-``--~~~~~~~```~~-``~.<br></br>
                  ......`--~~~~~~~-```..--``-~~~~~~`....-~`..`--```--```-~~~~~-`....-~~~~~|.<br></br>
                  ```---``--~~~~~~~~~`...-`..-~~~``......``...`~~----`-~~|~~~-`......```-~~.<br></br>
                  `~~~~~~~~~~~`````-~-``....`-~~-..............------------````.`````...... <br></br>
                  -~~~~~~~~~~~....`-~~~-`...`-~~`..````..........`-~~~~``......```````..... <br></br>
                  -~~~~~~~~~~~`......```.....`--```~~~`............``````....-~~``````..... <br></br>
                  ```--~~~-``......```````......``---``......................`-~||~```````` <br></br>
                  .................`-~~~~-`...```......`--````.................`-``........ <br></br>
                  ````................``.............`~~~~~~-```............``.........``.. <br></br>
                  `---``````--~~~~~~~~~~-`............`-~~~~~-`............`--`......`-~`.. <br></br>
                  `-```..`````~~~~~~~~~~~~~`.......................``````..........`-~||-`` <br></br>
                  ---~~-``````-~~~~~~-`.`-~~~-......```````...`-~~~~~--~~~`.....`-||----~~~.<br></br>
                  .~~~~-`.```--`````````-~~|~~|~`....``~~--`..`~~~~~~```-|~-`....``-`........<br></br>
                  ----`.```..`...````...`------`......... .....`--~--`````.....``.-``-----~.<br></br>
 ............`-~~|||~-``..............    ..... ..........``-^++>>?>>>???v-<br></br>
                  ...........```---~~~~-`.........`-```~^^~`..``----``....````~^?vvvvv?????-<br></br>
 ..```--``...................``-~~~~~||^+>>>>+^++++^^||~-----~|^||^^||||||.<br></br>
                  `````-----```..............`---`....```````````---``........`--||||~~||~~.<br></br>


            </div>,

            <div>

                  ...............     .        ..```........................ <br></br>
                  ..   ........ .....``...........   ...      ...`````````````````````````` <br></br>
                  .`-``..............`-~--`......... ....     .....``````````````````````.. <br></br>
                  .`~~~~~~~~~-```....``~|||~``............      ....```---``.`````````````` <br></br>
 ..`-~~~~~~~~~~~~-`````~||~~~~-`..........       ....`~|~~``^>>~--```````` <br></br>
                  ....`~~~~~~~~~~~~~--``-~||~~~~~~`...........      ..`~~```-~~~~-`````.``` <br></br>
                  .....`--~~~~~~~~~~~-```-~~~~~~|~...``.......  ... .`~````.....``.``````` <br></br>
                  ....```---~~~~~~~~`....`````~~`..`-`````..   ....`~```................ <br></br>
                  ...........``-~~~~~`..............``.-~`..     ..~~-`.....``~`........ <br></br>
                  ...........````--~~-``.....```.....``.--..    ...-~~` ......-~........ <br></br>
                  ...........````````-~~-```.....``...-~~`.-`. ......-~-`.......`-.`````.. <br></br>
                  ...........``````````~~`````........`~-`.``........~~`...  ..``~~``````` <br></br>
                  .........`````````-~~~~----````.....``..``......`-~`........`````~~||||.<br></br>
                  .    ..````-~--`..``-~~~~~~~~~-`-`...````.`-`..```~|~`.`````-~~~~-`~|||~|.<br></br>
                  ... ....`--~~--``....-~~~~~~~~~~-``..`~~`.`~-`````~~-``--~~~~~~~```~~-``~.<br></br>
                  ......`--~~~~~~~-```.`--``-~~~~~~`....-~`..`--```--```-~~~~~~`....-~~~~~|.<br></br>
                  ```---``--~~~~~~~~-`...-`..-~~~``......``...`~~----`-~||~~--`......```-~~.<br></br>
                  `~~~~~~~~~~~`````-~-``....`-~~-..............---`--------````.`````...... <br></br>
                  -~~~~~~~~~~~....`-~~~-`...`-~~`..````..........`-~~~~-`......```````..... <br></br>
                  -~~~~~~~~~~~`......```.....`--```~~~`............``````....-~~`````...... <br></br>
                  ```--~~~-``......```````......``---``......................`-~||~```````` <br></br>
                  .................`-~~~~-`...```......`--````.................`-``........ <br></br>
                  ````................``.............`~~~~~~-```............``........```.. <br></br>
                  `---``````--~~~~~~~~~~-`............`-~~~~~-`............`--`......`-~`.. <br></br>
                  `-```..`````~~~~~~~~~~~~~`...............`.......``````..........`-~|~-`` <br></br>
                  ---~~-.`````-~~~~~~-`.`-~~~-......```````...`-~~~~~--~~~`.....`-||----~~~.<br></br>
                  .~~~~``.```--`````````-~~~~~|~`....``~~--`..`~~~~~~```-|~-`....``-`........<br></br>
                  ----`.``````...````...`------`......... .....`-~~--`````.....``.-``-----~.<br></br>
 ............`-~~|||~-``..............    ..... ...........`-^++>>?>>>???v-<br></br>
                  ...........``----~~~~-`.........`-```~^^~`...`----``....````~^?vvvvv?????-<br></br>
 ..```--``...................``-~~~~~~|^+>>>>+^+++^^^||~-----~|^||^^||||||.<br></br>
                  `````----````..............`---`.....``````````---``........`--||||~~||~~.<br></br>


            </div>,

            <div>

                  ............. ...``````-----`.....   .........```........................ <br></br>
                  `....`--````......`````-~|~~~~-`.. .`..........`````````````````````````` <br></br>
                  `-`````~~~~~-``...````.``~~~~~~~~. .--`..........``````~-``````````````.. <br></br>
                  .`````-~~~~~~~~~--~~-`..```-~~`--...`--...... ....`````--`.`````````````` <br></br>
 .``--~~~~~~~~~~~~~~~~~-``..``````....```.....   ....```~~``^>>~--```````` <br></br>
                  .`~~~~~~~~~~~~~~~~~~~~~~~-``.```.....````..`.     ....`----~~~~-`````.``` <br></br>
                  ..``-~~~~~~~~~~~~~~~-```-~-````......-`````-`  ... ..`---`.....``.``````` <br></br>
                  .....`~~~~~--~~~~~~~~`....``.........--```~~`.  .....`~~~`............... <br></br>
                  ......`````...```-~~~~`..............``.```~`.   ...-~~~`....``-`........ <br></br>
                  .............```----~~-```......`........``--......`~~~-.......`-```..... <br></br>
                  .`~~`.....`-~~~~~~-~~~~~~~~-`...``...-~`..---.......-~~`.`....``-``````.. <br></br>
                  ..```.....-~||~-````-~~~~~~~```...`..`~-`.`~-......`-~-.......`-~~~----``.<br></br>
                  . ........`---`...``-~~~~~~-````......`-`.`--.`-``-~~~-`.```..`~~~~~~~~||.<br></br>
                  ..  .``----~~--`..`-~~~~~~~~-~--`......`-``~~-~~~~~~~``-~~~~``-~~~-~~~~||.<br></br>
                  .....``--~~~~~~~~~```-~~---~~~~~-`......```--~~~~~~~----~~~~~~~-```~~-``~.<br></br>
                  ..``-~~~~~~~~~~~~~~-``--`..``-~~~`.....``...``-~~~-```~~~~~~~`....-~~~-``.<br></br>
                  ``~~~~~~~~~~~~~~-```-`.-`....................`-~~~---~~~~~~~~`.........``.<br></br>
                  .-~~~~~~~~~~~```````.```....`````...`.........``--~~~~`..`-~~~~-`......... <br></br>
                  .-~~~~~~~~~~-....```.......`~~~~~-``~-...........----`......`----```.`.... <br></br>
                  .-~~~~~~~~~~`..........``..``````~||~~`.`.............```...-~~-`-~-`....`.<br></br>
                  -----~~~-``......`~~```~~`.....`-----``~~-``..........`....`--`~~~|-..... <br></br>
                  ................`````.````.`--``.....`--~|~~-..................`````..... <br></br>
                  ..........`````............`--``..`-~~~~~~~--`.......................```-.<br></br>
                  `--~-``-~~~~~~~~~~~-`...............`~~~~~-`..............``.........`-``.<br></br>
                  `--``..`~~~~--~~~~~~~`````......................``---```........``....... <br></br>
                  -~~~~-.`-~-~~~~~~~~-``-`````................`-~~~~~~-~~~-`............... <br></br>
                  .~~-`....`````.`````--~~-``-~-`.......`.....`~~~~----``~-````....`........ <br></br>
                  `.....``..`....```.....````--`...............`-~~~~~----`.....`.````````` <br></br>
                  .....`-``.``-~~~~~~-```.....................................``-||^+^^+++^.<br></br>
 ..........`----~~~~~~~-`.............``-``...................`|+>>>+^^+^^.<br></br>
                  ...................```.....````````~~~|^^|~--`---------```````------~|~--.<br></br>
                  .```````.................```---``````````----``----``````````--~~~~~~~~~-.<br></br>


            </div>,

            <div>

                  `-`------`````````......`--------.  ...``--`..```........```............. <br></br>
                  .~~~~~~~~~~~~``..````....``~~~~~~~`......-|~-`````````````-~`````````````` <br></br>
                  .~---~~~~~~~~~--`````.......-~```~`......`~~~``...``````.`|~````````````.. <br></br>
                  ....``-~~~~~~~~~~~~~-`.......`...........`-~-.....``-`..-~--``-`````````` <br></br>
                  .``--~~~~~~~~~~~~~~~~~-``.................`~-.. ..``...`~~-~~~---```````` <br></br>
                  `-~~~~~~~~~~~~~~~~~~~~~~~-`...............`~~..........~~~````--`````.``` <br></br>
                  .~|~~~~~~~~~~~~~~~~~~~~~~~~~`........`-.....-~`........-~~-.....``.``````` <br></br>
                  .~|~~~~~~~~~~~~~~~~~~~~~~~~~~`......`--`....-|`.......`~~~`.........``.... <br></br>
                  .~|~~~~~~~~~~---~~~~~~~~~~~~~~`.....`~~-....`~`.....--~~~~-....``..``..... <br></br>
                  .~|~~~~~~~~~-`..`-~~~~~~~~~``--`.`-``-~-..```~-...``~~~-`--`...````-``.... <br></br>
                  `-~~~---~~~-`.``----~~~~~-...``..-~--~~``~~-~~-``~~~--`-~-```-~~~~~~~-`.. <br></br>
                  .....``..`--``-````-~~~~~--`.```..```-~~~~||~~~--~~---~~````-~~~~~~~~~~``.<br></br>
                  ..........---~~`.`~~~~~----`..`...``...`~~~~~~~~-~~~~~||-``...`~~~~~~~~||.<br></br>
                  ``````````-~~~~~`-~~~~-`.....``....``....````~~~~~~~~---~~~-``-~~~~~~~~~~.<br></br>
                  .~~~~~~~~~-~~~~~~~~~~~~~`......``.............`~~~~~~~---~~~~~~~~~~||~----.<br></br>
                  `.`-~~~~~~~-~~~~~~~~~~~`......................-~~~~~~~|~~~~---```````.... <br></br>
                  ..-~~~~~~~~~~~~~-```~~-``..................```-~~~~~-``-~~~`............`.<br></br>
                  .`~~~~~~~~~~~~---`....`````..`````````.........`-~~--`....`~~~-`.....`-~~~.<br></br>
                  .-~~~~~~~~~-``--`.......```---~~~~~~~~--``...............``~~|~~-...`-~~~|.<br></br>
                  .-~~~~~~~``....`......``---`````~~~~~~~~||-`..........``-~~~---~~~-`.``-~~.<br></br>
                  .-~--~~~~~-.........````~~~-`.`-~~~~~~~~~~~~`.........`~~~~~`..`-~|~...... <br></br>
                  ``.............``..``.``-~~~~~~~~~~````-~~~-`.........````...``-~|~`..... <br></br>
                  ....````````.............`----`-~~~~~~~~~~`....................`-``....`-.<br></br>
                  `-~~~~~~~~~~~-``.................``-~~~~``........`````...............~~~.<br></br>
                  `-~~~~~~~~~~--~~-`...........................``-~~~~~~~~-`.....`--`...`--.<br></br>
                  -~~~~~~~-~~~~~~~|-```......................`-~~--~~~~~~~~---`............ <br></br>
                  .---`........``--~~~--`....`-~-``..........`~~-```-~~~~-````-~~-`......... <br></br>
                  ...........................`-```.........````-~~~-~~~~~--```````-````.... <br></br>
                  ..``-~~~~~~------``............................```..............`------`. <br></br>
                  ........``---~~~~~|~---```....................................`````....``.<br></br>
                  ...........`--~~~~~~~~~~~~~~-``...`-~~~~~`........`````````.........`````.<br></br>
                  .....````..``---~~~~~~~~~~~~----`-~~~~~~~~~~~~~~~~~~|||||~~~~~~~~~~~~~|~~.<br></br>


            </div>,

            <div>

                  `-`------````-````.......`-------.    ..``----`.```.````..``............. <br></br>
                  .|~~~~~~~~~~~~~~~~~--`.....`~|-~~~`.......-~~~~`.```````..`~-````````````` <br></br>
                  .~-~~~~~~~~~~~~~~~~~~~-`....`-`.`-.........-~~~`..``````..~~-```````````.. <br></br>
                  .`-~~~~~~~~~~~~~~~~~~~~~`.................`-~~....``-`..`~~~````````````` <br></br>
                  `-~~~~~~~~~~~~~~~~~~~~~~~~.................`~-...`--`..`~~~-`.`~-```````` <br></br>
                  .~~~~~~~~~~~~~~~~~~~~~~~~~~~........``-.....`~-...`~~...~~~`...`-`````.``` <br></br>
                  .~|~~~~~~~~~~~~~~~~~~~~~~~~~~`......~~-`....`~~...`~~..~~~~`....`````````` <br></br>
                  .~|~~~~~~~~~~~~~~~~~~~~~~~~~|~``....-~~-.....~~....-~--~~~~-........``.... <br></br>
                  .~|~~~~~~~~--``-~~~~~~~~~~`-~~-`--`.`~~~``-..~~`...-~~~-`-~-...`-``--`.... <br></br>
                  .~|~~~~~~~~-.....`-~~~~~~-`..``..-~-`~~~~-~~`~~~`.`~~~~``---``-~~~--``.... <br></br>
                  -~~~~~~~~~-`...`-~~~~~~~-`......`~-`~~~~~~~~~~~-`~~--``-~--~~~~~~~~~~-`.. <br></br>
                  ..`-~~~~~~-````~~~~~~~~~--`````..--..`~~~~~~~~~~-~---~~~`...`~~~~~~~~~~`` <br></br>
                  .....``.`-~`-~~~~~~~~`..`````--...-`....`~~~~~~~~~~~~~~~--```~~~~~~~~~~||.<br></br>
                  ----```.`-~~~~~~~~~~~~`.......`...``.........-~~~~~~~~~~~~~~~~~~~~~~~~~||.<br></br>
                  .~~~~~~~---~~~~~~~~~~~~~......................`~~~~~~~~~~~~~~~~~~~~~~~```-.<br></br>
                  `-~~~~~~~~~-~~~~~~~~~~-`..................`..`~~~~~~-~~~~~~`````......... <br></br>
                  .`~~~~~~~~~~-~~~~~~~~-`..``-~~~~~~~-``....`-``~~~~~~`..`-~|`........``--~.<br></br>
                  `~~~~~~~~~~-----`-`.`.`-~~||~~~|||~~|~-........---`.....`~|~-`....`~~~~~|.<br></br>
                  .-~~~~~~~~~~~--`......`~~~~---~~~~~~~~~~~`..........``--~-~~~||~`..-~~~~~|.<br></br>
                  .-~~~~~~-`...``.....`-|~~-`..`~~~~~~~~~~~~-.......`~~~~~~~~~``-~~-`.`-~~~|.<br></br>
                  .--.`-~~|~`.........-~~~~-----~~~~~----~~~~~`.....`~~~~~~~~`...-~~~`....``.<br></br>
                  .......`......`....`~~~~~|~|~~~~~~`..`-~~~--.......````-`...`~~~~~-...... <br></br>
                  ``-----```..........````--~~-``~~~~~~~~~~`...................--~~-`.....` <br></br>
                  ~~~|~~~~~~~~-`..................`~~~~--`.........``--~-`.............`-~~.<br></br>
                  ~~~~~~~~~~~~~~~`.............................`-~~~~~~~~~~~-`..........-~~.<br></br>
                  .~~~|~~~~~~~~~~~~~`.........................`-~-`-~~~~~~~~~~---`.........`.<br></br>
                  --~--``....```----````..-~~~`............`-~~``.`~~~~~~~`..``~~-`........ <br></br>
                  .....................``````--``..........```--~~~~~~~~~-``````--~~``..... <br></br>
                  ....```````````````............................```..............`-----``` <br></br>
                  ........`-~~~~~~~~|~-```.......................................``--~`.... <br></br>
                  ......`~~~~~~~~~~~~~~~~~~~~-`.....`-~~~~~`..........```````.............` <br></br>
                  .....`-~~~~~~~~~~~~~~~~~~~~~~~----~~~~~~~~~~~~~~~~~~~~~~~~~~~--------~~~~.<br></br>


            </div>,

            <div>

`rwrrr}}vvvvv}rrrrrrr}}}}}}}}}}}}}}rrrrrrrrwr}vvvvvvv}}r}}}}}}}}rrrrrrrrrr~<br></br>
                  -yyyyuxzzwrrwwwwzzxuxzzzzzxxxxxxxxxxxuuuuuuyyuxzzzzxuuuuuxzzzzzxuuuuuuuuuu|<br></br>
-uyuyuxzzwwww}vvv}zxwwzzzzzzzzzzzzxxxuxxxxuyyyuuxxzxxxxxxxzzzzzxxuuuuuxxxx|<br></br>
-uyuuuxzzzzzzw}}}rzzzxxxzzzzzzzzzzzxxxxxxxuuzwwwzzrrwrrrwzzzzzzxxxuuuuxxxx|<br></br>
-uuuxxzzzzzzxuxxzwzzzzzzzzzzzzzzzzxxxxxxxxzwvv}wzw}}r}v}wzzzzzzxxxxxxxxxxz|<br></br>
-uuxzzzzzzzzzwzzzr}}}}rwzzzzxuuuxxxxxzxxxxxuxzzzw}}r}}rwzzzzzxxxxxxxxxxxxz|<br></br>
-uuxzzzzzzzzzzxxw}vvvvrwxuuxzzwwwwwwwwwwwwwwwzzxxxwr}rzxxzzzzxxxxxxxxxxxzw~<br></br>
                  -uuxzzzzzzzzzzxzrvvvrzuuxzwwwwwwwwwwwwwwwwwwwwwwwzxuuzwzxxzzzxxxxxxxxzwwrr~<br></br>
-xxzzzzzzzzzzzzzr}wxuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxuxzzzzzzxxxxxxxzrrrw~<br></br>
                  -xzzzzzzzzzzzzzwwuuxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxyxwwzzxxxxxxxwrrrw~<br></br>
                  `zzzzzzzzzzzzzwzyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuyzwxxxxxxxxzrrrw~<br></br>
                  -zzzzzzzzzzzzrxuzwwwwwwwwwwwwwwxzwxuuuuuuuuuuuuuxxxzwzwwwwuyxwxxxxxxxzrrrw~<br></br>
                  -zzzxxzzzzzxrzxwwwwwwwwwwwwwwwwzwwzzzzzzzzzzzzzzxxxxzxwwwzuyyxwuxuxxxxwrrw~<br></br>
                  `zxxxxzzzzzwzxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxyzwxuxxxxzrrw~<br></br>
                  `zxxxxzzzxzrzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxywxxxxxxxzzw~<br></br>
                  `zxxxzzzxxwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwuxwxxxxxxxxz~<br></br>
                  `zxxxzzzxxrzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxyruxxxxxxxz~<br></br>
                  `xxxxxxxxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwuwxxxxxxxxx~<br></br>
                  `xxxxxuuuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxzzuuuxxxxx|<br></br>
                  -xxxxuuuuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzuuuxxxxx|<br></br>
                  -xxxxxuuuzwwwwwwwwwwwwwwwwwwwrrrrrrrrrrrrrrrrrrrwwwwwwwwwwwwwwwzzwuuxxxxxx|<br></br>
                  -uuxxxxuuzrwwwwwwwwwrrrrrrrrrrrrwrwwwwwwwwwwrwrrrrrrrrrwwwwwwwwwvwuxxxxxxx|<br></br>
-uuxzxxxxx}wwwwrrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrww}?zuxxxxxxu|<br></br>
-uuxxxxxxurv}rrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv??xxxxxxxxu|<br></br>
                  -uuxxxxxxxuvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuxxxxxxuu|<br></br>
-uuuxxxxxxxzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxuuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxuuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwv???vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxzwwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwrrr}}vvvvv}rrrrrrr}}}}}}}}}}}}}}rrrrrrrrwr}vvvvvvv}}r}}}}}}}}rrrrrrrr}r~<br></br>
                  -yyyyuxzzwrrwwwwzzuuxzzzxxxxxxxxxuuuuuyuuuuyyuxzzzzxxuuuxxzzzzzxuuuuuuuuuu|<br></br>
-uyyyuxzzwwww}vvvrxuzwzzzzxuuuuuxxxxxxxxxxuuyuuuxxxxxxxxxxzzzzzxxuuuuuxxxx|<br></br>
-uyuuuxzzzzzzrvvv}zwwxuuuuxxzwwwwrrrrrrrwwwzwwwzxxxzzwrrzzzzzzzxxxuuuuxxxx|<br></br>
-uuuxxzzzzzzxuxxzwzxuxxzzwwrrrrwwwwwwwwwwwwrr}}rzzxxxw}}wzzzzzzxxxxxxxxxxz|<br></br>
                  -uuxzzzzzzzzzzzzzxxxzwrrrrwwwwwwwwwwwwwwwwwwwwwwwrwwzzzzzxzzzxxxxuxxxxxxxz~<br></br>
-uuxzzzzzzzzzzzxuuz}}}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwzuxzzzzxxxxuxxxxxxzw~<br></br>
-uuxzzzzzzzzzzxuzr}}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzwzxuxzzzxxxxxxxxxwwrr~<br></br>
                  -xxzzzzzzzzzwxxzrrrwzwwwwwwwwwwwwwwwwzzzzzzzzzzzwwwwwzxxzxxzzzxxxxxxxzrrrw~<br></br>
                  -xzzzzzzzzzzuxwrwzzwwwwwwwwwwwwzzzzwwwwwwwwwwwwwwwwwwwwwzzxzzzxxxxxxxwrrrw~<br></br>
                  `zzzzzzzzzzxxwrwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxuzzxxxxxxxzrrrw~<br></br>
                  -zzzzzzzzzzxwrwwwwwwwwwwwwwwwwwwwwwzzwwzzzzwzwwwwwwwwwwwwwzuuwxxxuxxxzrrrw~<br></br>
                  -zzzxxzzzzxwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuxzxxuxxxxwrrw~<br></br>
                  `zxxxxzzzxzrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuxxxuxxxxzrrw~<br></br>
                  `zxxxxzzwuwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxuzxxxxxxxzzw~<br></br>
                  `zxxxzzzzxrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwuxzxxxxxxxxz~<br></br>
                  `zxxxzzzzzrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxuzxxxxxxxxz~<br></br>
`xxxxxxxzwwwwwwwwwwwwwwwwwwwwwwwwwwrrr}}}}}}}}}}v}vv}vvvv}}}rrzuzxxxxxxxxx~<br></br>
`xxxxxxxxwwwwwwwwwwwwwwwwwwwrr}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxxuxxxxxxx|<br></br>
-xxxxuuuzwwwwwwwwwwwwwwwrr}vvvvvvvvvvvvvvvvvv????????????v??vvvwuxuuuxxxxx|<br></br>
-xxxxuuuxwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvvv????????vvv???vvrxxxuuxxxxxx~<br></br>
-uuxxxuuxzrwwwwwr}vvvvvvvv}}rrrrrrwwwwwwwwwrrrrr}}}vvv??vv}rrwwzrzuxxxxxxx|<br></br>
-uuxzxxxxzrwwwrvvv}}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrrwwr}xuxxxxxxu|<br></br>
-uuxxxxxxurv}}}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvxxxxxxxxu|<br></br>
                  -uuxxxxxxxxvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuxxxxxxuu|<br></br>
-uuuxxxxxxxzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxuuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxuuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwv?v?vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxzwwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwrrr}}vvvvv}rrrrrrr}}}}}}}}vvvvvvv}}}}rrrwr}vvvvvvvv}}}}}}}}}}rrrrrrrr}r~<br></br>
                  -yyyyuxzzwrrwzzzzxuuuxxxzzwwwwwwzzzzzzxxxxxxxxxxzzzxxuuuxxzzzzzxuuuuuuuuuu|<br></br>
-uyyyuxzwwwww}vv}wuuxwwwwwwzzzzzzzzzzzzzzzzzzxxxxxxxxxxxxxzzzzzxxuuuuuxxxx|<br></br>
-uyyuuxzzwwzzr}rzzzwrwwzxxzwwwwwwwwwwwwwwwwwwzzzxuuxxwwwzzzzzzzxxxuuuuxxxx|<br></br>
-uuuxxzzzzzzxuuxwrrwzxxzzwwwwwwwwwwwwwwwwwwwwwwwzxuuuzr}wzzzzzzxxxxxxxxxxz|<br></br>
                  -uuxzzzzzzzzxxzwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxuuzwxzzzxxxxuxxxxxxxz~<br></br>
                  -uuxzzzzzzxuxwrwzxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxyzrzzzxxxxuxxxxxxzw~<br></br>
                  -uuxzzzzzzxzwrwzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuyxwzzxxxxxxxxxzwwr~<br></br>
                  -xxzzzzzzxzwrzzwwwwwwwwwwwwwwwwwwwwwwzzzzzzzzzzzwwwwwwwzxuxwzzxxxxxxxzrrrw~<br></br>
                  -xzzzzzzxxwwzzwwwwwwwwwwwwwwwwwzzzzzzwwwwwwwwwwwwwwwwwwwwzuxzzxxxxxxxwrrrw~<br></br>
                  `zzzzzzxxwrzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuzzxxxxxxxzrrrw~<br></br>
                  -zzzzzzxzrwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxuzxxxuxxxzrrrw~<br></br>
                  -zzzxxzxwrzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxuzxxuxxxxwrrw~<br></br>
`zxxxxxxrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrr}}}rrrrrrwxzxxuxxxxzrrw~<br></br>
`zxxxzxzrzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrr}}}}}}}}}}}}}}}}}}}zxzxxxxxxzwzw~<br></br>
`zxxxzxzwzwwwwwwwwwwwwwwwwwwwwwwwrrrr}}}}}}}}}}}}}}}}}}}}}}}}wuzxxxxxxxxxz~<br></br>
`zxxxzxzwzwwwwwwwwwwwwwwwwwwwrr}}}}}}}}}}}}}}}}}}}vv}}}}}}rrrwuxxxxxxxxxxz~<br></br>
`xxxxxzzwwwwwwwwwwwwwwwwwrrr}}}}}vvvv?????>>>+++++++++>?vvv}}rxzxuxxxxxxxx~<br></br>
`xxxxxxxwwwwwwwwwwwwwwrr}}}}v???>++^^^^^||^^^^|||||^+??vv??vvvv}uuxxxxxxxx|<br></br>
-xxxxuxxwwwwwwwwwwwrrr}}v?>+^^^^||||||~||||||~~|^+>???????????vwuuuuuxxxxx|<br></br>
-xxxxuuuzwwwwwwwwwr}v?+^|~|||||||^+^^+>>+++^^+>??????????????vzuuuuuxxxxxx~<br></br>
-uuxxxuuxwwwwwww}??+++>???vv}}rrrrwwwwwwwwwrrrrr}}}vvv??v}rwzxxuuuuxxxxxxx|<br></br>
                  -uuxzxxxxwrwwrv???vvrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrwzzxxuuuuuxxxxxxu|<br></br>
-uuxxxxxxxr}}vv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wxxxxxxxxu|<br></br>
                  -uuxxxxxxxxvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuuxxxxxuu|<br></br>
-uuuxxxxxxxzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxuuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxuuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwv?v?vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxuuuuuu|<br></br>
-uuuxxxxxxzwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxzwwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv?vvvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rwrrr}vvvvvv}rrrrr}vvvvvvvvvvvvvvvvvvvvvvvvrwwrvv}}vvv}}}}}}}}}rrrrrrrrrr~<br></br>
                  -yyyyuxzzwwwwxxzzzzzwwwwwwwwwwwwwwwwwwwwwwwwzxuuuxzzzxuuxxzzzzzxuuuuuuuuuu|<br></br>
                  -uyyyuxzzwwzxzrrrwzwwwwwwwwwwwwwwwwzzzwwzwwwwwwwzuuzzuuuxxzzzzzxxuuuuuxxxx|<br></br>
                  -uyyyuxzzzxxzrrwzzwwwwwwwwwwwwwwzzzzzwwwwwwwwwwwwwzuuwwwzzzzzzzxxxuuuuxxxx|<br></br>
-uuuuxzzxuxwwwwwwwwwwwwwwwwwwwzwwwwwwwwwwwwwwwwwwwwzuuw}wzzzzzzxxxxxxxxxxz|<br></br>
                  -uuxxzwzuxwwzzwwwwwwwwwwzzzzwwwwwwwwwwwwwwwwwwwwwwwwwzuwzxzzzzxxxxxxxxxxxz|<br></br>
                  `xxxzzzxzwwzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuxzzzzxxxxuxxxxxxzw~<br></br>
                  -uuxzzuxwrwwwwwwwwwwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzuxwzzxxxxxxxxxzwwr~<br></br>
                  -xxzzxxwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxuxzzxxxxxxxxzrrrw~<br></br>
-xzzzxwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrr}}}rwzxzzxxxxxxxwrrrw~<br></br>
`zwzxzrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrr}}}}}vvvvv}vruzzxxuxxxxwrrrw~<br></br>
`wwzxzrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrr}}vvvvvvvvvvvvvv}wzxxxxxuxxxzrrrw~<br></br>
`zzzuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrr}}}}vvvvvvvvvvvvvvvrwwwxxxxxuxxxxwrrw~<br></br>
`zzzxwwwwwwwwwwwwwwwwwwwwwwwwwwr}}}vvvvvvvvvv?????>>>>v}}}}}wzxxxuxxxxwrrw~<br></br>
`zzzxwwwwwwwwwwwwwwwwwwwwwwwr}}vvvvvvvv???>>++^^^^^>?vvvvvvvvwuxxxxxxxzwzw~<br></br>
`zzzxwwwwwwwwwwwwwwwwwwwwr}}vvvvv??>>++^++^^^||~|+?vvvvvvvvv}xuuxxxxxxxxxz~<br></br>
`zzzzwrwwwwwwwwwwwwwwwwr}vvvv?>>+^^^^|||||||||+>?vvvvvvvvv}wzxuuxxxxxxxxxz~<br></br>
`zzzzzrwwwwwwwwwwwwwwr}}vv?++^^^||~~~~|||||^+?vvvvvvvvv}wzrrzxuuuuxxxxxxxx~<br></br>
`xxzzzwwwwwwwwwwwwwr}v?>^^^^^||||||~|~~||+>?vvvvvvvvrzxuuzwwxuuuuuxxxxxxxx|<br></br>
-xxxxxxwwwwwwwwwwr}v?+^|||||||~~~|~~||+>?vvvvvvv}wzxuuuuuxxxuuuuuuuuuxxxxx|<br></br>
-xxxxxxzwwwwwwww}v?+^|~~~~~~~|||^^+>??vvvvvvv}rzxuuuuuuuuuxwwzuuuuuuuxxxxx~<br></br>
-uuxxxxxwwwwwwrv>+^^^++>??vv}}rrrrwwwwwwwwwrrwwwrrwwzzzxxxzwwzxuuuuxxxxxxx|<br></br>
-uuxzxxxzwwwwv>+>?vvrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzxxuuuuuxxxxxxu|<br></br>
-uuxxxxxxxr}v?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wxxxxxxxxu|<br></br>
                  -uuxxxxxxxxvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuuxxxxxxu|<br></br>
-uuuxxxxxxxzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxuuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxuuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwv?v?vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxuuuuuu|<br></br>
-uuuxxxxxxzwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxxwwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv?vvvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`rrrw}vvvvvvvv}rr}}vvvvvvvvvvvvvvvvvvvvvvvvvrwwrvv}}vvv}}}}}}}}}rrrrrrrrrr~<br></br>
-yyyuxwwrrr}rxuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxxxxzzzxuuxxzzzzzxuuuuuuuuuu|<br></br>
                  -uyuxzwwrrrwxxzwwwwwwwwwwwwwwwwwwwwzxzwwzwwrrrrrrxuxxuuuxxzzzzzxxuuuuuxxxx|<br></br>
                  -uuxxzwwrwxxzwwwwwwwwwwwwwwwwwzzxxzzzwwrrrrrrrrrrwzuuzwzzzzzzzzzxxuuuuxxxx|<br></br>
-uxxzzwwzuxwwwwwwwwwwwwwwwwzzxxzwwwwrrrrrrrrr}}rwwwwxxr}wzzzzzzxxxxxxxxxxx|<br></br>
-xxzwwwzxzwwwwwwwwwwwwwwxxxzzwwwwwrrrrrrrrrrr}rwwwwwwzxrzzzzzzxxxxxxxxxxxz|<br></br>
                  `xzzwwzuzwwwwwwwwwwwwwzxzzwwwwwwrrrrrrrrrrrrrrwwwwwwrrzuzzzzzxxxxuxxxxxxzw~<br></br>
-zzwwwuzwwwwwwwwwwwzzwwwwwwwwwrr}rrr}rrrrrrrrwwwwwwwwwwzxzzzzxxxxxxxxxzzwr~<br></br>
`zwwwzxwwwwwwwwwwwwwwwwwwwwwrrrrrrr}}}rrrr}rrwwwwwwwwwwwxxzzzxxxxxxxxzwrrw~<br></br>
`zwrwxwwwwwwwwwwwwwwwwwwwwrrrrr}rrrrrr}}}}rrrwwwrr}}vvvvvvrzzzxxxxxxxwrrrw~<br></br>
`wrrzzwwwwwwwwwwwwwwwwwwwrrrrrr}rrr}}}rrrrrr}vv???????????}zzzxxxxxxxwrrrw~<br></br>
`wrrxzwwwwwwwwwwwwwwwwwrrrrrrrr}rrrrr}vvvvvv??????>?????>?zzzzxxxxxxxzrrrw~<br></br>
`wwrxwwwwwwwwwwwwwwwwwrrrrrrrrrrr}}v?>>????????>>>>???>?}xzzzzxxxuxxxxwrrw~<br></br>
`zwwxwwwwwwwwwwwwwwwrrrrr}}rrrrv?>+++>????????????????}xuxxxxxxxxuxxxxwrrw~<br></br>
`zwwxwwwwwwwwwwwwwwrrrrrrrrrv?>+^^^+>???????????????rxuuuuuuuuuxxxxxxxzwww~<br></br>
`zwwxwwwwwwwwwwwwwrrrrrrrv?>>+^|~|+??????????????vwuuuuuuuuuuuuuxxxxxxxzxz~<br></br>
`zwwzwwwwwwwwwwwwrrr}rrv>^^^^|~|+??????????????rxuuuuuuuuuxxxxuuxxxxxxxxxz~<br></br>
`zzzwzwwwwwwwwwwrrrrrv>^^^||||^>????????????vwuuuuuuuuuuuxwrzxuuuuxxxxxxxx~<br></br>
-xzzwzwwwwwwwwwrrr}?>^^^~~||+>?????????>?v}zuuuuuuuuuuuuuzwwxuuuuxxxxxxxxx|<br></br>
-xxzzwzwwwwwwwrrrv>^^|~|||^>???????????vwxzxuuuuuuuuuuuuuxxxuuuuuuuuuxxxxx|<br></br>
-uuxxzwwwwwwwrr}?++^~~~~^>>?????????v}rrrwzxxxxxxuuuuuuuuuxwwzuuuuuuuxxxxx|<br></br>
-uuzxxzwwwwwwrv+^+^|^++>?v}}}rrrwrrwwwwwwrwwrrrrrrwwzzzxxxzwwzxuuuuxxxxxxx|<br></br>
-uuxzxxxwrwwr?^+>?vvrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzxxuuuuuxxxxxxu|<br></br>
-uuxxxxxxzr}??vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wxxxxxxxxu|<br></br>
                  -uuxxxxxxxxvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuxxxxxxxu|<br></br>
-uuxxxxxxxxzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxxuu^<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxxuuu|<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv?v?vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxuuuuuu|<br></br>
-uuuxxxxxxzwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxxwwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}vvvv}}vvvvvvvvvvvvvvvvvv?????????vvvvvv}}rrr}}vv}}vvvv}}}}}}}}rrrrrrrrrr~<br></br>
`zzzzzzzwwwwwwwwwwwwwwwwr}}}}}v}}}}wwwwwwzzxzzzwwzxzwzxuxzzxzzzxuuuuuuuuuu|<br></br>
`zzwwzwwwwwwwwwwwwwwwww}v}}vvvvv}vrwwwwwwwrr}}vvvwzxxuuuxxzzzzzxxuuuuuuxxx|<br></br>
`wwwzwwwwwwwwwwwwwwwwwvv}vvvvvvvv}rrrwr}}}}}}}vv}zzxxxxxxxzzzzzzxuuuuuxxxx|<br></br>
`wwzzwwwwwwwwwwwwwwwrvvvvvvvvvvvvrwr}}vvvv}vv???ww}}rrrrzzzzzzzxxxuuuxxxxx|<br></br>
`wzzwwwwwwwwwwwwwwwr}}}vvvvvvvvvvr}vvvvvvvvv???wxwv}r}v}wzzzzzxxxxxxxxxxxx|<br></br>
`wzwwwwwwwwwwwwwwwrv}}vvvvvvvvvvvvvvvvvvvvvv?vzuz}}r}}}rzzzzzxxxxuxxxxxxxz~<br></br>
`zwwwwwwwwwwwwwwwwv}}vvvvvvvvv>?vvvvvvvvvvvvvzuzrvrr}rzzxzzzzxxxxxxxxxxxzw~<br></br>
`zwwwwwwwwwwwwwww}v}vvvvvvvv?^>vvvvvvvvvvv?v}zxzrrwwwzxxxxzzzxxxxxxxxxwwrr~<br></br>
`wwwwwwwwwwwwwww}v}vvvvvvv?+++?vvvvvvvvvv?vrwwzxxxuxxxxxxxzzzzxxxxxxxzrrrw~<br></br>
`wwwwwwwwwwwwwwrv}vvvvvv?+^^|?vvvvvvvvvvv}zxxxxuxxuuxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`wwwwwwwwwwwwwwvvvvvvvv>^^^~+vvvvvvvvvvvzuuuuuuuxxuxxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`wwwwwwwwwwwww}v}vvvv?+^^||^?vvvvvvvvv}xuuuuuuuuuuxxxxxxxxzzzzxuxuxxxzrrrw~<br></br>
`wwwwwwwwwwwwwv}vvvv?^+^|||?vvvvvvvvvzuuuuuuuuuuuuuuuuuuxxxxxxxxxuxxxxwrrw~<br></br>
`wwwwwwwwwwww}v}vvv>^^|~~|?vvvvvvvv}xuuuuuuuuuuuuuuuuuuuuuuuuuuxxuxxxxzrrw~<br></br>
`wwwwwwwwwwwwvvvvv+^^~|||?vvvvvvv}wuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxzzw~<br></br>
`wwwwwwwwwwwrv}vv+^+|~~|?vvvvvv}zuyuuuuuuuuuuuuuuuuuuuuuuuuuxuuuxxxxxxxxxz~<br></br>
`wwwwwwwwwww}vvv+^^~~~|?vvvvv}wuyyyyuuuuuuuuuuuuuuuuuuuuuxzwzxuuxxuxxxxxxx~<br></br>
`wwwwwwwwwwwv}v+^|~~~^?vvvvvruyyyyyyuxzzzuuxuuuuuuuuuuuuuzrwxuuuxxxxxxxxxx|<br></br>
                  `wwwwwwwwwwrvv^|^~~~^?vvvvrxyyyyyyyyuzwwzxxxuuuuuuuuuuuuuxzxuuuuuuuuuxxxxx|<br></br>
                  `rrrwwwwwwwrv?~^|~~+?vvvrzuuuuuuuxxzzrrrrwzxxxxxxuuuuuuuuuxzzxuuuuuuuxxxxx|<br></br>
`zr}}}rrrrr}v+^^|^>vvvvrwwrrrrwwwwrrwwwwwrwwrrrrrrwwzzzxxxzrrzxuuuuxxxxxxx|<br></br>
-xxwr}}}}}r}++^^>?}}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzzxuuuuuxxxxxxu|<br></br>
-uuzzzwr}}}v>?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wuxxxxxxxu|<br></br>
                  -uuxzxxxxzzvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvuxxxxxxxu|<br></br>
-uuxxxxuxxuzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?zuuxxxxxuu|<br></br>
-uuuxxxxxxxx}vvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxxuuu|<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv?v?vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxxuuuuu|<br></br>
-uuuxxxxxxxwwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwwwwrvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`vvvvv}rvvvvvvvvvvvvvvvvv?+>>^^^^^^vrrrrrwwwrvvvvv}}vvvv}}}}}}}}rrrrrrrrrr~<br></br>
`wwwwxuxwwwwwwwwwwwwwwww?+??>>>>>>?xuuuuuyyyuzwwzzxzwzxuxzzxzzzxuuuuuuuuuu|<br></br>
`wwwzuxwwwwwwwwrrrrrrrr?+>>+>>>>>>rxxuuuuuuyuuxzwwzxxuuuxxzzzzzxxuuuuuuxxx|<br></br>
`wwzuxwwwwwwwwrrrrrrrr++>^>>>>>>>?xxxuxxxxuyyyyuxzzxxxxxxxzzzzzzxuuuuuxxxx|<br></br>
`wzuuwwwwwwwwrrrrrrr}+>>||>>>>>>>}xxxxxxxxuuxwwwzw}}rrrrzzzzzzzxxxuuuxxxxx|<br></br>
`wxuzwwwwwwwwrrrrrr}>>>~|+>>>>>>?zzzxuxxxxxrvv}zxwv}r}v}wzzzzzxxxxxxxxxxxx|<br></br>
`wxxwwwwwwwwwrrrrr}+>>~||+>>>>>>rzzzxuuxzzwrrwxuz}}r}r}wzzzzzxxxxuxxxxxxxz~<br></br>
`zwwwwwwwwwwrrrrrr+>>|~~|>>>>>>vzzzxuuuxzwrrwxuzrvrrrrzzxzzzzxxxxxxxxxxxzw~<br></br>
`zwwwwwwwwwwrrrrr>^>||~~|>>>>>?wxxxuuuuuxwrrrzxzrrwwwzxxxxzzzxxxxxxxxxwwrr~<br></br>
`wwwwwwwwwwrrrrw?^?~~~~~+>>>>>}zxxuuuuuuxzrrrwzxxxuxxuxxxxzzzzxxxxxxxzrrrw~<br></br>
`wwwwwwwwwwrrrr}^>^~~~~|>>>>>vxxuuuuuuuuuxzxxxxuxxuuxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`wwwwwwwwwrrrrr+>+~~~~~^>>>>?zzxuuuuuuuuuuuuuuuuxxuxxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`wwwwwwwwwrrrr?^>~~||||+>>>?wxxuuuuuuuuuuuuuuuuuuuxxxxxxxxzzzzxuxuxxxzrrrw~<br></br>
`wwwwwwwwwrrr}^?^~~||~^>>>>wuxuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxuxxxxwrrw~<br></br>
`wwwwwwwwwrrw?+>~~~~~|+>>>ruuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxuxxxxzrrw~<br></br>
`wwwwwwwwwrrr^+|~~~~~^>+>ruuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxzzw~<br></br>
`wwwwwwwwwrwv|>|~~~~|+>+}uuuuyyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuxuuuxxxxxxxxxz~<br></br>
`wwwwwwwwwrw?++|~~~~^+>}uuuuyyyyyyyyuuuuuuuuuuuuuuuuuuuuuxzwzxuuxxuxxxxxxx~<br></br>
`wwwwwwwwwrr+>||~~~|+?ruuuuuyyyyyyyyuxzzzuuxuuuuuuuuuuuuuzrwxuuuxxxxxxxxxx|<br></br>
`wwwwwwwwwr}^>||~~|^>wuuuuuyyyyyyyyyuzwwzxzxuuuuuuuuuuuuuxzxuuuuuuuuuxxxxx|<br></br>
`rwwwwwwwwwv^>~|~~^?zuuuuuuuuuuuuxxzzrrrrwzxxxxxxuuuuuuuuuxzzxuuuuuuuxxxxx|<br></br>
`r}vv}}}}}}?|>~|~|vxxzzwwwrrrrwwwwrwwwwwwrwwrrrrrrwwzzzxxxzrrzxuuuuxxxxxxx|<br></br>
-xzr}vvvvvv?|+~^>vwwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzzxuuuuuxxxxxxu|<br></br>
-uxzww}vvvv?+?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wuxxxxxxxu|<br></br>
                  -uuxzzzzzzzvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvxuxxxxxuu|<br></br>
-uuxxxxuxxuzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?wuuxxxxxuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxxuuu|<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvzxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv???vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxxuuuuu|<br></br>
-uuuxxxxxxxzwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwwwwwvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu^<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`vvvv?????????v}??>>>>>>>>v}}}}}}}}rrrrrrwwwrvvvvv}}vvvv}}}}}}}}rrrrrrrrrr~<br></br>
`wwwr}}}}}}}}}vv}vv???????zxxxxxxxxxuuuuuyyyuzrwzwzzwwzxxzxxzzzxuuuuuuuuuu|<br></br>
`www}}}}}}}}}}>>v????????vzxxxxxxxxxuuuxxuuyuxzwwwzxxuuuxxzzzzzxxuuuuuuxxx|<br></br>
`www}r}}}}}}rv~>?????????vzzzzzzzzxxxuxxxxuyyyyuxzxxuxxxxzzzzzzzxuuuuuxxxx|<br></br>
`www}r}}}}}}}+>+>????????vzzzzzzzzzxxxxxxxuuxzzzzwrrwwwwzzzzzzzxxxuuuxxxxx|<br></br>
`www}r}}}}}}?+?~+????????rxzzzzzzzzzxuxxxxxw}vvwxwv}r}v}wzzzzzzxxxxxxxxxxx|<br></br>
`www}r}}}}}}^>^~+????????wxzzzzzzzzzxuuxzzzr}rxuz}}rr}}rzzzzzxxxxxxxxxxxxz~<br></br>
`www}r}}}}r?^>|~^???????vzxzzzzzzzzxuuuxzwrrwuuxrv}}}rwzxzzzzxxxxuxxxxxxzw~<br></br>
`www}r}}}}}+>+|~|???????vzzzzzzzzxxuuuuuxwrrrzxzr}wrwzxxxxzzzxxxxxxxxxzwrr~<br></br>
`www}r}}}}v^?|~~~???????rzzzzzzzxxuuuuuuxwrrrwzxzzxxxxxxxxzzzxxxxxxxxzrrrw~<br></br>
`www}r}}}}?+>|~~|???????zzzzzzzxuuuuuuuuxxzzzzxuxxuuxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`www}r}}}}+>^~|~|??????}xzzzzzzxuuuuuuuuuuuuuuuuxxuxxxxxxxzzzzxxxxxxxwrrrw~<br></br>
`wwwr}}}}v|>|~|~|??????zxxxzzxxuuuuuuuuuuuuuuuuuuxxxxxxxxxzzzzxuxuxxxzrrrw~<br></br>
`wwww}}}}v^>~~|~~?????}uuuuuxxuuuuuuuuuuuuuuuuuuuuuuuuuuxxzzzxxxxuxxxxwrrw~<br></br>
`wwww}r}}?^>~~|~~?????xuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxuxxxxzrrw~<br></br>
`wwwwr}}}?|>~~|~|????ruuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxzwzw~<br></br>
`wwwww}}}>~+~~|~|????xuuuuuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxz~<br></br>
`wwwww}}}>|+~~~~|???wuuuuuuuyyyyyyyuuuuuuuuuuuuuuuuuuuuuuuzwzxuuxxxxxxxxxz~<br></br>
`wwwwwr}r?|+~~~~^??vuuuuuuuuyyyyyyyyuuxzxuuuuuuuuuuuuuuuuzrrzuuuxxxxxxxxxx|<br></br>
`wwwwww}rv^+~~~~+??xuuuuuuuyyyyyyyyyuzwwzuxzuuuuuuuuuuuuuxzxuuuuuuxuuxxxxx|<br></br>
`}}}}}}}vv^>~|~~+?wuuuuuuuuuuuuuuxxzwrrrwwzzxxxxxuuuuuuuuuxxxuuuuuuuuxxxxx|<br></br>
`vvvvvvvvv>+|~~|>}uxxzzwwwwrrwwwwwrwwwwwwrwwrrrrrrwwzzzxxxzrrzxuuuuuuxxxxx|<br></br>
                  `vvvvvvvvv?^+~|^?rwrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwwwxxuuuuxxxxxxu|<br></br>
`vvvvvvvvvv>>?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wuuxxxxxxu|<br></br>
`r}}}}}}}}rvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvxuxxxxxuu^<br></br>
`zwwwwwzxxuzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?wuuxxxxxuu|<br></br>
-uuuxxxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxxuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvxxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv???vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxxuuuuu|<br></br>
-uuuxxxxxxxzwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuxxuuuuuu|<br></br>
-uuuxxxxxxzwwwwwwvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu|<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`vv}?|^~|||||~+wwwwrr}}}}}}}}}}}}}}rrrrrrwww}vvv?v}}vvvv}}}}}}}}rrrrrrrrrr~<br></br>
`wwx?++~|^^^^||zyyyuxzzzzzxxxxxxxxxxuuuuyyyyxr}}rwzzrwwxzzzzzzzzxxuuuuuuuu|<br></br>
                  `wwx+?^~||^^^^~?uxuuxzzzzzxxxxxxxxxxuuuuuuuyuxwwwrwwzxxuxzzzwwwzzzxuuuuuxx|<br></br>
`wwz^?|||||^^^^|}wxuzwzzzzzzzzzzxxxxxuxxxxuyyyuuxzxxxxxxzzwzzzzzzxxuuuxxxx|<br></br>
`wzz^?|||||^^^^~?}zzzzxzzzzzzzzzzzzxxuxxxxuuuxxxzzwzzzwwzzzzzwwzzxxuuxxxxx|<br></br>
`wzz^?|||||^^^^~?wzxxuxzzzzzzzzzzzzzxuuxxxxz}vvrzw}}r}}rzzzzzwzzxxxxxxxxxx|<br></br>
`wwz+?||||||^^^|>zrrrrwwzzzzzzxxxzzzxuuxzzzr}rzuxr}rr}}rzxzzzxxxxxxxxxxxxz~<br></br>
`www+?||||||^^^^^}vvvvrwzzzzzzzzxxzxxuuxzwrrwxuxrv}}}rwzxzzzzxxxxuxxxxxxzw~<br></br>
`www+?||||||^^^^|vvv}rwzzzzzzzzzxxxuuuuuxwrrrzxzr}rwwzxxxxzzzxxxxxxxxxzwrr~<br></br>
`wwz+>|||||||^^^|vrrwzzzzzzzzzzzxxuuuuuuxzrrrwxxzzxxxxxxxxxzzxxuxxxxxzrrrw~<br></br>
`wwx>?|||||||^^^|rzzzzzzzzzzzzzxxuuuuuuuuxzzzzxuxxuuxxxxxxxzzxxxxxxxxwrrrw~<br></br>
`wwz>>^~|||||^^^|wzzzzzzzzzzzzxxuuuuuuuuuuuuuuuuxxuxxxxxxxzzzzxxuxxxxwrrrw~<br></br>
                  `wwzv++~|||||^^^|wzzzxxxxxxzzxxuuuuuuuuuuuuuuuuuuxxxxxxxxxzzzzxxxuxxxzrrrw~<br></br>
`wwwr+>~|||||^^^|wuuuuuuuuuxxxuuuuuuuuuuuuuuuuuuuuuuxuuuxxzxxxxxxuxxxxwrrw~<br></br>
`wwww+>^||||||^^|zuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxuxxxxzrrr~<br></br>
                  `wwww?++~||||||^^zuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxzwww~<br></br>
`wwwwv~>~||||||^^zuuuuuuuuuuuyyyuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxxxz~<br></br>
                  `wwwww^++~|||||^+xuuuuuuuuuuuyyyyuyuuuuuuuuuuuuuuuuuuuuuuuxzzxuuxxxxxxxxxz~<br></br>
`wwwwwv|?~~~~|||>uuuuuuuuuuuyyyyyyyyuuxzxuuuuuuuuuuuuuuuuzrrzuuuxxxxxxxxxx|<br></br>
`rwrrrr>++~~~~||?uuuuuuuuuuyyyyyyyyyuzwrzuxzuuuuuuuuuuuuuzzzuuuuuuxxxxxxxx|<br></br>
`vvvvvvv+>^~~~~~vuuuuuuuuuuuuuuuuxxzwrrrwwzzxxxxxuuuuuuuuxxxxuuuuuuuuxxxxx|<br></br>
`vvvvvvvv>+^~~~~wuuxxzzwwwwrrwwwwwrwwwwwrrwwrrrrrrwwzzzxuxzrrzxuuuuuxxxxxx|<br></br>
`vvvvvvvvv>+^~|+zwwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwwwzxuuuuxxxxxxx|<br></br>
`vvvvvvvvvv>>?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wuuxxxxxxu|<br></br>
`vvvvvvvvv}vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvxuxxxxxxu|<br></br>
`vvv}}rwwxuzvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?wuuxxxxxuu|<br></br>
`xxxxzxxxxxx}?vv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvuxxxxxxuuu^<br></br>
-uuuxxxxxxxzw}???v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvvvxxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv???vvv}rrwwwwwwr}vv????????vv}rwwwwwwr}vvvvv?vvzuxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwww}?vvvvvvv}}rww}vv?????????>??+?wwwrr}vvvvvvv?vxuuuuxxuuuuuu|<br></br>
-uuuxxxxxxzwwwwwwvvvvvvvvvvv}vvvvvv?vvvvvvvv>>}}vvvvvvvvvvvruuuuuuuuuuuuuu|<br></br>
-uuuxxxxxzwwwwwwww}vvv??vvv?>>?|?vv??vv?vv?~>^>>vvvvvv?v?vzxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

.+^++++>vvvvv}rwwwwrr}}}vrv?>?}v??+vwv+}v?+^^+>>>>+>>>>>>>>+++`|}}r}}}vv}r~<br></br>
.??????>}wwwwxuyyyyuxzzzxxzr?rxwr}?vwur?xuwv>>>???????????vv??????vvv??rux|<br></br>
.????????}wrwzxuuuuuxzzzuxwr?wz}rrrrvwr?vzyyw}???????????????????????vzuur-<br></br>
.?????????rrrr}rrwxuzzzzuxzzvvrwzxxxxxzwxxxzxxxzw}?????>>?????>?????wuyxv?`<br></br>
.????????>?wwvvvvrzzwzzxxxzzvrzzwwwwwwwwzxxwzwwzuxxxzwwwwwwzw>????}xyyr??v-<br></br>
.???????>>?rzzwr}rzxxxzuxzwwrz}}rwzzzzzzzwx>vv?vrrwrzxzzzwxz>+>?rzyyurvruy^<br></br>
.>????????>?xxxxzwwwwrwux}wzxuzzzzzxxxxxxrw}vv}wz}vv}}rxuyyyxxwzzxuuxzuyuz~<br></br>
.~>???????>>rwzxz}vvvvrx}}xuuuuxzzzzwzxxxx}vvrzzwvvvwruuuuxxyyyyxwxxuuuxr}~<br></br>
.~|>???????>vxuxrvvvv}wzzuuuuuuuxxuxvwzzzxwvv}rrvvrzxuuuuxrzxxyyyuxxxxwvv}~<br></br>
.|~|+>???????zzwr}}rrwzxyuuuuuuuuxuyzzxzzzw?vvvv}zzzzxxxxzzuux}ruuwwz}vvvv-<br></br>
.^|||>???????}zzzzzzzzzwuuuuuuuxuuuuuzuuxxr??vv}wzzzzxxzzxxzzrvvzxz}rvvvvv-<br></br>
.>|||^>?????>vzzzzzzzzxzzuuuuuuuwuyuuxzyuuwv?}?vvzzzzwrwwzzwvvxzrxzvrvv?v}~<br></br>
.++~||^>????>?zzzzzzzz}wzxyxzxuyxwzuux}zxzwzwrvvxuuuzruuxzzrrxzxwrrv}vvvrz~<br></br>
.^>~|||^>?????zxxxxuurvuyzzzvwwzxr}}ww}vwrww}v?wuuuzxyuxxzrzxzzzz}vv}wwrww~<br></br>
`>++~|||>????>wuuuuux}uyuuxw}xxxxwxvwwvruvrzw?vrrrzzuxxzwrwxxxxuu}vvzxzzwr~<br></br>
`v>>|~||^>???+vuuuuuuzuuuuxuyxxyuuuwrxuyu?rx}wzwrvwzzzzzzuuuuuuyx?vzxxxzww~<br></br>
`v?^+~|||^>>>+vuuuuuuuzuuuxyyuzxyyyuruuuzvwwrrxxuzwwvwwzxxxxuyuuxvxuzwxxxz~<br></br>
`vv+++~|||>>>>?uuuuuuuxzuuwzuyuwrxuxr}xxz}rrvrxuyyuwxuxwzzzzzxx}wwuzzzxxxz~<br></br>
`v}v^>|~~~^>?>?uuuuuuuuxzuuxzzxxwwrvrrwwrzz}}wxuuxzuyyyyuxuxzwwrwuxwxxxxxx|<br></br>
`}}r?^>|~~|+?>?uuuuuuuuuuuuxxuxxuz}rrwwrzuxzwwwwwzuuuyuxxuuuxxuuzuxxxxxxxx|<br></br>
`r}rr?+>|~|^>??uuuuxxuuuuyuuuyyyyuxzzwr}zxzxzzzrzrwwzxxuyuuuyyyxzuuuuxxxxx|<br></br>
`r}vvv>+>|~|+??uuuuxuuxxxzwzzzwwwwwzwrrrrrwwwwrrrr}vrwzzzxxuuuxxuuuuuxxxxx|<br></br>
`vvvvvv?^>|~+>?uxzwwwrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwrrrrwrrwzzxxuuuuxxxxxxx~<br></br>
`vvvvvvv?>+^|+?wrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrwwwzuuuxxxxxxu|<br></br>
`vvvvvvvvv?+?}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv}uxxxxxxxu^<br></br>
`vvvvvvvvvrrv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vv?}uuxxxxxuu|<br></br>
`vvvvvv}wxuxvvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvxxxxxxxuuu^<br></br>
`v}}rrwxxxxzwvvvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvv?wxxxxxxuuuu|<br></br>
-xxzwzzxxxxzww???vvvrrwwwwwwwwwwrr}}}}}}}rrwwwwwwwwwwwrvvvvvv?ruxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwwv??vvvv}}rwwwwrv??>>>>>>>>>???vwwwwwr}vvvvvv??wuuxxxxuuuuuu|<br></br>
-uuuxxxxxxxwwwwwvvvvvvvvvv}}rvvvvvvvvvvv?v??^>rr}}vvvvvvvv?vxuuuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwwwwwrvvvvvvvvvv????vvv??vv?vvv?>^??vvvvvvvvv?rxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwrr}}}vr>|^+>>>>^>wwwwwrrv^>>+??>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
-uuuxxzzzwwwwxuyyyyuxzxwxuv+>>?????+}yy}uyux>??>}v??????vvvvv?????????????`<br></br>
-uuuuuxzzwrrwxxuuuuuxzzwux}?>>??????>??.wyux???>}?????v}}v???????????????v-<br></br>
-uuyyuxzzwrrrrrrwwxuzzwwyzwr+>>???????|~zyyuv>?>v????}}???????????vvrwzzxx|<br></br>
-uuyyuxzzwwwwvvvvrzxwzrzywzzv+>>????????>?wuw>?+>???v????????vrwzxxuuuuuuu|<br></br>
-uuuuxzzzzzzzzrr}rzxxx}yurzzw?>>>???????????}>?>????>????v}wxuuuuuuyyyyyyy|<br></br>
.ryxxzzzzzzzxxxxzwwwzvryurzzzw?+>>>??????????>????????vrxuyyuyyyyyyyyyyywv`<br></br>
 ~}uzzzzzzzzzwwxzrvvv?wzxrzzzzw>+>>>???????????????>}uyyyyrzyyyyyyyyyur???`<br></br>
.-~vxzzzzzzzzzuxw}vvv?}zxwzzzzzw?>r?+>>?????>+???????v}wz>.}yyyyyyuwv?????`<br></br>
.~~~}zzzzzzzzzxwr}}}rvryzwzzzzzzz??uw?>+>>?>>>??????????>~+v}rrr}?????????`<br></br>
.~|~|}zzzzzzzzzzwwzzzvwuxwzzzzzxxur}uyxr?>>>>>???????????????????????????>.<br></br>
.~||~^rzzzzzzzzzzzzzz}}uxrzzzzzxuuuxzxxuyuwv?>>>>>>>>>>>>+????????????>>>?`<br></br>
.||||~^zxzzzzzzzzzzzzwvyuwr}zzxxuuuuuxzxuyyyyxzr}}}}}}wz?>????????>++>??}z|<br></br>
.|||||-?xzzzzzxxxxxxxz}yu??zxxxuuuuuuuuuxzxxuyuyyyyyuuz?+>>>>>>>>>?vrw}wux~<br></br>
.||||||~vxzzzxxuuuuuuuwxwvvuuuuuuuuuuuuuuuuxxxwzxwzw?>^+++>>>?v}wxxuxwxyxr~<br></br>
.||~|||~|wxxzxxuuuuuuuxvwr>uuuuuuuzuuuuuuuuuuuuuuxzwrrrrwwzxuuuuuuxwzwyzrw~<br></br>
.~~~~|||~>xuxxuuuuuuuuz?zurxuuuuuzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzwxv?wzxz~<br></br>
.~~~~~~~|~}uuuuuuuuuuuwr}zwwxxxux}xvwuuuuuuuuuuuuuuuuuuuuuuxwzuxwxyv?wxxxz~<br></br>
.^~~~~~~~~^xuuuuuuuuuuxzrxuyyyyyuyx|zuuxxxuuuuuuuuuuuuuuuxzvvzzzyy}vxxxxxx|<br></br>
.+^~~~~~~|-vuuuuuuuuwwzuyyyyuuxxzzxvwxw}wzxxuuuxxxvwwuuuuz}vzuyyxzzxxxxxxx|<br></br>
`>|>^~~|||~|xuuuuuu}vuyyyuxwwzzzxxxxwwvv}zzrzxuux???wuuuz}zuyyxzzuuuuxxxxx|<br></br>
`wv++>|~~||-}uuuuurvyyuxzrvwzzzzzwwwz}?v}rrrwzzzzzuzwzwwzuuv?wxxuuuuuxxxxx|<br></br>
`vvv>+++|~~~?uuuuz?rzwwrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}r}}rzxuuuuxxxxxxx|<br></br>
`vvvvv>+++|~^zzwwrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwwzzxuuuxxxxxxu|<br></br>
`vvvvvvv?>+^?}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv}uxxxxxxxu^<br></br>
`vvvvvvvvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv}xuxxxxxuu|<br></br>
`vvvvvvv?vzxvvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvv?xxuxxxxuuu^<br></br>
`vvvvvv}wxxzr?vvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vvvv?ruxxxxxuuuu|<br></br>
`vvvvrzxuxxzwr???vv}rwwwwwwwwwwwwwwrrrrrrwwwwwwwwwwwwwr}vvvvvv}uxxxxxxuuuu|<br></br>
`rwxxxxxxxxzwwr???vvvv}rwwwwwrvv??>>>>>>>???v}wwwwwrr}vvvvv??ruxxxxxuuuuuu|<br></br>
-uuuuxxxxxxwwwwrvvvvvvvvv}}rwvvv???vvvvv????^?wrr}}vvvvvvv??wuuuuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwwwww}vvvvvvvvvvv??vvvv?vvv??vv?++vvvvvvvvvvv?vzxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwr}v^+>>>>>>^rrrrrv|>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
-uuxxxzzzwwwwxuyyyyxzr>???????>yuuuuxv????????????????????????????????????`<br></br>
-uuuuxxzzwrrwxuuuuuxwr?????????yuuuuxz>???????????????????????????????????`<br></br>
-uuyyuxzwrrrwwwwzzxzw}>>???????yuuuuuuv>??????????????????????????????????`<br></br>
-uuuuuxzwwwwwvvvvrzwr}>>??????>uuuuuuxz>??????????????????????????????????`<br></br>
-uuuuuzzzzzzzw}vvrzwwr?>??????>uuuuuuuu?????vv????????????????????????????`<br></br>
-uuuxzzzzzzzxuxxwzwwrrv>>?????+wuuuuuuxr>???v}????????????????????????????`<br></br>
-uuxzzzzzzzzzwwzxw}v}z}>>??????>uuuuuuxz>???v}?????????vv?????????????????`<br></br>
-uuxzzzzzzzzzzxuw}vvzz}?>>?????>>xu+}uxxv??>vv>?????vvvv??????????????????`<br></br>
-uuxzzzzzzzzzxxz}vv}xzvv+?????????+.ruxxv>??v??????}vv????????????vvv}}rrw~<br></br>
-uxzzzzzzzzzzzzw}}}}xz}r??????????>^vxuur>???????}v?????????v}rwzxxxxxuux}`<br></br>
`zxzzzzzzzzzzzzwrrvzuw}rw????????????>?rz>?>>?>?v???????}rzxxxxxxxuuuuyz??`<br></br>
.?vzzzzzzzzzzzzww}?uuwwrzr?????????????>>+????>?????v}wxuuuuuuuuuuuuuxv???`<br></br>
.++?wzzzzzzzzzzzwv?zxrzwxu}???>>?????????????????vrxxuuuuuuuuuuuuuuzv?????`<br></br>
.|^+?}zzzzzzzxxzz?vrzrzzuuu}???+>?????????>????>vzuuyx??uuuuuuuuuwv???????`<br></br>
.|||^>?wxzzzzxxxw?}zz}xzuuuur>wr?>>>???>>+??????????v~.vuuuyuxrv?????????>.<br></br>
.~~~~|^>rxxxxxxxzvrzw}xzuuuuyw?zxwv>>>>>>>???????????>>????????????????>>>`<br></br>
.~~~~~||^ruuuuuxzvwzzrxzxuuuuyurrxyxrv?>>>>>?????????????????vvv????>>>?v}|<br></br>
.|~~~~~~~^wuuuuxz}vuxrxwxuuuyyyyuzwzuyuz}v?>>>++>>>??>?vvvvvvv??>>>>>vrv}z~<br></br>
.>|~~~~~~~^zuuuuxw}uuv}rxuuyyyyyyuxzwwuuuuuxxzwwzxux??????????++?vvvrzr}zw~<br></br>
.^>>|~~|||~>uuuuxzruz??xxxuyuuyyyuuxw}}}wzrwuzxyzrv?>?????????vrzzrzuwrzzw~<br></br>
`}>+>>|~~||-}uuxxxrw}}>xxxxuxuyyyyuxzwr}wzwzzrw}?????vvvrwr}rwzzwrxuwrzzzz|<br></br>
`wr?++>>^~~~>uuxxxzvvzvxxxzzxxxuuxzwwwr}rwzwzxxxxxzxuuuuuxrv}rrrwwzwwzzzxx|<br></br>
`vvvv?++>>||+zuuxxxvvw}}rrrrrr}rwwrrrrrrrrrrrrrrrr}rwwrwwwrrwrrx??rzxzzxxx|<br></br>
`vvvvvv??>>>?wzwwwrrrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrwwrrrr}rzvvzuxxxxxxu|<br></br>
`vvvvvvvvv??}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvruxxxxxuu^<br></br>
`vvvvvvvvv}vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvruxxxxxuu|<br></br>
`vvvvvvvvwurvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vv?wuxxxxuuu^<br></br>
                  `vvvvvvrxxxzvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvxxxxxuuuu|<br></br>
`vv}rzxxxxxzrvvvv}rwwwwwwwwwwwr}vv???????vv}rwwwwwwwwwwwwrvvvvv?wxxxxuuuuu|<br></br>
`zxuxxxxxxxww}?vvvvv}rrwwwwww}v??????????>>?>?wwwwwwwwr}vvvvvvvwuuuuuuuuuu|<br></br>
-uuuxxxxxxzwwwwvvvvvvvvv}}rr}??vvvv?vvvvvvv?+>rrrrr}vvvvvvvvvvwxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwr?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^^^>>>>>`<br></br>
-uuxxxzzzwwwwxuyyyyw??????????????????????????????????????????????>>>?????`<br></br>
-uuuuxzzzwrrwxuuyyuv??????????????????????????????????????????????>>??????`<br></br>
                  -uuyyuxzwrrrwwwzzzz???????????????????????????????????????????????????????`<br></br>
                  -uuyyuxzwwwwrvvvvrr???????????????????????????????????????????????????????`<br></br>
-uuuuuxzzzzzzr}vvrr???????????????????????????????????????????????????????`<br></br>
-uuuxxzzzzzzxuxz}w}???????????????????????????????????????????????????????`<br></br>
                  -uuxzzzzzzzzzwzrww????????????????????????????????????????????????????????`<br></br>
-uuxzzzzzzzzzzxz}v??????^?>???????????????????????????????????????????????`<br></br>
-uuxzzzzzzzzzxur>???????^ur^>?????????????????????????????????????????????`<br></br>
-xxzzzzzzzzzzzz?>??????+?yuz++????????????????????????????????????????????`<br></br>
`xxzzzzzzzzzzzz>>??????|ryuux++???????????????????????????????????????????`<br></br>
`zzzzzzzzzzzzzz>>??????~zyyyuz|???????????????????????????????????????????`<br></br>
`wzzzzzzzzzzzzz>>??????|xyyyuu}|???????????????????????????????????>>?????`<br></br>
.>vzzzzzzzzzzxx>>??????^uyyyyux+>?????????>>>????????????????>>?vrr>??????`<br></br>
.~~>}xxzzzzzzxx>>??????^uyyyyuu}~??????>>>>>>>????????????>?vwzxxx>???????`<br></br>
.~~-~?rzzzxxxxx?>??????|xyyyyyux^??????+>>>>>>>??????????vwxuuuyu>>???????`<br></br>
.~~~~~|?zuuuuux?+??????^ryyyyyuuv^???>v+>>>>>>>>????>>vwxuuuyyyy?+????????`<br></br>
.|~~|~~|>wuuuxw}+>??????>yyyyyuxw^???>}>>>>>>>>???>>vzuuuyyyyyu>+????????>.<br></br>
.|~|||||~^vxuwxw>>>?????^}yyyyyuu>??>>r>>>>>>???+>}xuuuyyyyyyx++??????>>>>.<br></br>
.^~~~||||~~?rwyw?+>??????^ry>>yyu}+?>?v+>>>??>>>}uyyyyyyyyyyr|>?????>>>>>>.<br></br>
.^++|~~~|||~+uurw>>>??????>>.>yyuz+?>?>>>>??^+}xyyuyyyyyyyx?|????>>>>>>>+?-<br></br>
`v>+>>^|~~|~vyurur^>>???????|?wyyu??>???>?>~?uyyy}`ryyyuw?^+???>>>>>>+>?}x|<br></br>
`}v?>+>>>^~^uyxwuuv+>>>???????>?vw???^?>??|^vvvvv`.????++>??>>>>>>>>?vrxxu|<br></br>
`vvvvv?+>>>?xxwrwzw????vvvv}}}}}v}}}r}}vrvrr}}vvvvv??vvvvv?????v?rzxxuxxxu|<br></br>
`vvvvvvvv?>?rrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvxxxxxxuu^<br></br>
`vvvvvvvvv??}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}v?zxxxxxuu|<br></br>
`vvvvvvvvvzvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvzxxxxuuu^<br></br>
`vvvvvvvrxur?vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvxxxxuuuu|<br></br>
`vvvvv}zuxxz?vvv}wwwwwwwwwwwwwwwrr}}vvvvv}rwwwwwwwwwwwwwwwr}vvvv?rxxxxuuuu|<br></br>
`v}rwxxxxxxz}?vvvv}rwwwwwwwwwrv??>>>>>>>>>>??vwwwwwwwwwwr}vvvvv?vxuuuuuuuu|<br></br>
`xuuuxxxxxzww}vvvvvvv}rrwwwwwvvv????vvvv????^>rrrrrrr}vvvvvvvvv?zxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwv>+>v?v}}v?>>>>>>>>>>>+~?}?>>>>>>>>>>>>>>>>>>>?vv?~+>>?rrr~<br></br>
-uxxxzzzzwrrwxuuv??>?rzz}?????????????>^?v?????????????????????zy?^????ruu|<br></br>
-uuuuxzzzwrrwxuv???>+vwv??????????????>>??????????????????????>w}+>?????xu|<br></br>
-uuuyuxzwrrrwzr????>>>????????????????????????????????????????>?+>>?????vx|<br></br>
-uuuyuxzwwrrr}???>>>??????????????????????????????????????????>~>>???????w|<br></br>
-uuyyuxzzwwzzv???>>???????????????????????????????????????????+^>>???????r|<br></br>
-uuuxxzzzzzzx????>????????????????????????????????????????????+^>????????v~<br></br>
-uuxzzzzzzzz}?????????????????????????????????????????????????>>??????????`<br></br>
-uuxzzzzzzzzv??????????????????????????????????????>??????????????????????.<br></br>
                  -uuxzzzzzzzw??????????????????????????????????????????????????????????????.<br></br>
                  -xxzzzzzzzzr??????????????????????????????????????????????????????????????.<br></br>
`xxzzzzzzzz}??????????????????????????????????????????????????????????????.<br></br>
                  `zzzzzzzzzzr??????????????????????????????????????????????????????????????`<br></br>
                  `zwzzzzzzzzv??????????????????????????????????????????????????????????????`<br></br>
.?rzzzzzzz}???????????????????????????????????????????????????????????????`<br></br>
                  .~^?wxzzrv????????????????????????????????????????????????????????????????`<br></br>
.~~-^vz}??????????????????????????????????????????????????????????????????`<br></br>
.~~~~~>???????????????????????????????????????????????????????????????????`<br></br>
.|~|~~????????????????>?????????????????>?????????????????????????????????`<br></br>
.|~||~>>????????????>r}+???????????>>>>>>>>???????????????????????????????`<br></br>
.^~~~|^>>>?????????>wuur+???????>>>>>>>>>>>>>????????????>>???????????????.<br></br>
.+++|||^>>>>>?????>vyuxxw>?????>?>>>>>>>>>>>>??????????vrr|>???????????>?>.<br></br>
`?>+>>+^^>>>>>????>xyuuxxw>????>??>>>>>>>>>>??>??????}zuuyr+???????>>>>>?>.<br></br>
`w}>++>>>+>>>>>>>??yuuuuxuw>????>??>>>>>>>>??>?????}xuuuuyy??????>>>>>>>>>.<br></br>
`vvvvv?>>>>>>>>>>>>uuuuuuxxw????>>v>>>>>>>v>>?????zxxuuuuuyv+?>>>>>>>>>++?~<br></br>
`vvvvvvvv?>?r}}}}vvwwwwwwwww}v}}vvvvvvvvvv}?vv}v?}rrrwwwwww}vvvvv?>?>??vwu^<br></br>
                  `vvvvvvvvv?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvxzzxuuu|<br></br>
`vvvvvvvvv}?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvxxxxuuu^<br></br>
`vvvvvvv}xx?v}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvxxxuuuu|<br></br>
`vvvvvvwuxxv?v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?}xxxuuuu|<br></br>
`vvvrzxxxxxw?vvv}rwwwwwwwwwwww}vv?????????vv}rwwwwwwwwwwwwwrvvvvv?zuuuuuuu|<br></br>
`zxuuxxxxxxwrvvvvv}rwwwwwwwwrv???????????>>>+>rrrrrrrrrr}vvvvvvv?rxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwrr}}}}}}}}}}}}`...````````````-?rr}}}r^.-``-----------`.<br></br>
-uxxxzzzzwrrwxuyyyyuxxzzzxxxxxxxx?.```-```---~~~^vxuxzzzz>-~~~~~~~~~~~~~~~.<br></br>
                  -uuuuxzzzwrrwxuyyyuxxzzzzxxxxxxxxx?`````````--^vwwxxwwwzz+---~~~~~~~~~~~~~.<br></br>
-uuyyuxzwrrrwzxxuxuuxzzzzxxxxxxxxz}+^~----`~>vwwwwzzwrzuz```-----~~~-~~~~~.<br></br>
-uuuyuxzwwrrr}v}rwzuzzzzzzzzxxz}v???v}}vvvvxyyuxrv??>+>xw.```````-~---~~~- <br></br>
-uuyyuxzzwwww}vvvrzzzzzzzzzzrv?????vxwvrwzuyuz}v??>>>>??z~````````-``----`.<br></br>
-uuuuxzzzzzzxzzwrwzxxxxxzz}v???????}uwzxxxz}??????>>>???}v```````````````?~<br></br>
-uuxxzzzzzzzr?zxxwwrrrwz}??????????wzzuxrv????????>>>????}v|```|```````^rz~<br></br>
-uuxzzzzzzzw?+}xzrvvv}v???????????>vuurv???????????>?????}vrv+^?~```~?rxxz~<br></br>
-uuxzzzzzzzv>>>zw}vvv?????????????+vuv????????????????????rr}>>???wwxuxwwr~<br></br>
-xxzzzzzzz}?>>+?r?>>??????????????+???????????????>???????zy}^????zuxzwrrr~<br></br>
-xxzzzzzzz??>>>>>>???????????????>????????????????????????wz+>????vxxzrrrr~<br></br>
`rzzzzzzx}??+>>>>??????????????????????????????????????????>>>?????wuzrrrr~<br></br>
.~?wzzzzw???+>>???????????????????????????????????????????^>>??????vxzrr}v`<br></br>
.~~+vxzzv???+>???????????????????????????????????????????>+>>???????wz}?>+.<br></br>
.|~~~>rz????+????????????????????????????????????????????>+>????????v?>>>?`<br></br>
.~~~~~^??????????????????????????????????????????????????>>?????????++>>??`<br></br>
.~~~~~~^????????????????????????????????????????????????????????????>+>???`<br></br>
.|~~~|~>????????????????????????????????????????????????????????????>>????`<br></br>
                  .+~~~|~+??????????????????????????????????????????????????????????????????`<br></br>
.^>^~||+>>????????????????????????????????????????????????????????????????`<br></br>
`v>+>+~+>>>???????????????????????????????????????????????????????????????`<br></br>
`rv?+>>+>>>>>???????????????????????????????????????????????????????????>?`<br></br>
`vvvv?>+^^>>>>>???vvvvvvvvvvvvvvv}}vvvv}vvvvvvvvvvvvvv?????????????>>>>+>r|<br></br>
`vvvvvvv?+|?v}}}rrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrr}vv?>>>>??}zu|<br></br>
`vvvvvvvvv?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?}zzzxxxu|<br></br>
`vvvvvvv?vvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv}xxxxxuu|<br></br>
`vvvvvv?}xr?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv?wxxxxuuu^<br></br>
`vvvvv}zuxz?vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvv?xxxxxuuu|<br></br>
`v}}wzxxxxx}?vv}rwwwwwwwwwwwwwr}}vvv?vvvv}}rwwwwwwwwwwwwwwwr}vvv?ruxxxuuuu|<br></br>
`xuuxxxxxxxzvvvvvv}rwwwwwwwwwr??????>>>>>>>>>?wwwwwwwwwwrr}vvvvv}uuxuuuuuu|<br></br>
-uuuxxxxxxzwwvvvvvvvv}}rrrrr}vvvvvv?vvvv????++rrrrrrrr}vvvvvvv?vxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwrr}}}}}}}}}}}}}>`````-----``--`?rrr?`--------`^}rr}}}rr~<br></br>
-xxxxzzzzwrrwxuyyyyuxxzzzxxxxxxxw+~~~~~~~~~~~~~~~~vyuv|~~~~~~~~~~~>xuuxxuu|<br></br>
                  -xuuxxzzzwrrwxuyyyuuxzzzzxxxxxxv|~~~~~~~~~~~~~||~~?ur~~~~~~~~~~~~~~^zuxxxx|<br></br>
                  -uuuyuxzwrrrwzxuuuuuxzzzzxxxxx?-~~~~~~~~~~~~|||||~?x?-~~|~~~~~~~~~~~+xxzzx|<br></br>
-uuyyuxzwrrrrr}rrwzuzzzzzzzxx?`~~~~~~~~||||||||||~vu+-~~|~~~~~~~~~~~-?xwzz~<br></br>
                  -uuyyuxzzwwwwvvvvrzzwzzzzzzx?.-~~-~~~~~|||||||~~~~wz|-~~|~~~~~~~~~~~~~rwzz~<br></br>
-uuuuxzzzzzzzzwr}rzxxuuxzzz}.`~~~~~~~|||||~~~---`?rv~~~~~~~~~~~~~~~~~-?wzz~<br></br>
-uuxxzzzzzzzxxxzzzwwwzzzzzz^.`~~---~||||~~~----`>r}v----~~~~~~~~~~~~~-^zxz~<br></br>
-uuxzzzzzzzzwwwzzrvvv}}wwzr```~----~|~~~~------+w}}?`---~~~~~~~~~~~~~~|zzw~<br></br>
-uuxzzzzzzzwwwxxw}vvv}rwwwv.``-``--~~~~------`^rr}}?`---~~~~~~~~~~~|||~wwr~<br></br>
-uxzzzzzzzzwwwww}vvv}rwwww>.````-------`---``+wzwww?`----~~~~~~~~~|||~-}rr~<br></br>
`zxzzzzzzzwwwwwwrrrwwwwwww^..```--``--``-~-`>wzzxzx?`-`---~~~~~~--~||~`}rr~<br></br>
.+}zzzzzzzwwrwwwwwwwwwwwwz+..``````````--``?xzzxxzx}```---`~~~~---~||-|wrr~<br></br>
.~|?rzzzzwwwwwwwww}}wwwwzz?.`````````````^ruxxxxxxxx|.`````-~~-`--~~~`>wrr~<br></br>
.|~~^vzzwwwwwwzzz}???rxxxw}|.``````````^vzr?}xxxxxxuv..`````````-----~}rr}~<br></br>
.~~~~~?rwwwwwzzzzv????vzuw}w>`......~?vrv??>>rxxxxxxu?`.``...````-```vr}}}-<br></br>
.~~~~~>??vrwww??}?????>>vxrrwrv?>>?vzrv?????>?wuxuxxxx?-..`+^.``````?zrrr}-<br></br>
.~~~~~>????vv?>>>???????>?wv}}r}wxz}v??????>??vxzrxxxz}v^+???```..^}xzwwzw~<br></br>
.|~|||+??????>>>?????????>>}zw}rurv????????>????>>vxuw?>?????++^>}xxzxxzzw~<br></br>
.+^|~~^>??????>>???????????>wux}v?????????>?????>>>}v>>??????vwzuuxxxxxxxw~<br></br>
`?+>+||+>??????>????????????>zw?????????????????>>>>>????????vuzrvvvvvvv}}-<br></br>
`rv++>>^+>>????????>>>>??>??>???????????????????>+>????????????>>?????????-<br></br>
`vvv?>>>++>>>?????????vvvvv}}}}}}}}}}}}}}}}vvvvvv??vv????>???>???????????w|<br></br>
`vvvvvv?>||>?v}}rrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrr}vvvv????????}zu^<br></br>
`vvvvvvvv?>vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv??vvrzxxu|<br></br>
                  `vvvvvvvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvruxxxxxu|<br></br>
`vvvvvvv}xv?vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}v?zxxxxxuu|<br></br>
                  `vvvvvvzuxw?vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvxxxxxuuu^<br></br>
`vv}rzxxxxxv?vvrwwwwwwwwwwwwwwwwwwrrrrrrwwwwwwwwwwwwwwwwwwwwr}vv?zuxxxuuuu|<br></br>
`xxuuxxxxxxz?vvv}rrwwwwwwwwwwrv???>>>>>>>>??v}wwwwwwwwwwwwr}vvv?ruxxxxuuuu|<br></br>
-uuuxxxxxxxzwvvvvvv}}rrrrrrrwvvvvvvvvvvv????+>wwwwwwwwrr}vvvvvvwuuuxuuuuuu|<br></br>
-uuuxxxxxxxwwwvvvvvvvvvvvvvvv??vvvv?vvvv?vv?>|v}}}}vvvvvvvvvvvzxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvv}rwwwwrr}}}}}}}}}vvrrrrrrrrrrrrrrrr??rrrr}v}}}}}}}}}}rrrrrrrr~<br></br>
-xxxxzzzzwrrwxuyyyyuxxzxzxxxxxrzuuuuuyyyyyyyyyuvzxyyyyx}zzzzzzzxxuuuuuuuuu|<br></br>
                  -uuxxzzzzwrrwxuyyyyuxzzwzxxxxxrxyyyyyyyyyyyyyxwruyyyyyzrzzzzzzzxxuuuuuuuuu|<br></br>
-uuuuxzzwrrrwzuuuuuuxzzvwxxzzz}xuuyyyyyyyyyyyxuyyuuyyyrzxzzzzzzxxuuuuuuuuu|<br></br>
                  -uyyyuxzwrrrrrrwwwxuzzzvvwwvxxxxxxuyyyyyyyyyyyyyuuuuuxruxxzzzzzxxuuuuuuxxu|<br></br>
-uuyyuxzwwwwwvvvvrzxwwzv}uyyyyyyyyyyyyyyyyyyyuuuuuuuu}zxzzzzzzzxxuuuuuuxxx|<br></br>
-uuuuxzzzzzzzw}}}rzxxxuwzyyyyyyyyyyyyyyuuuuyuuuuuuux}rrrwzzzzzzxxxuuuxxxxx|<br></br>
-uuxxzzzzzzzxxxxzzzzzzzxrwuuuuuuuuuuuuuux}??xuuxzzw}}}}}wzxzzzxxxxxxxxxxxz~<br></br>
-uuxzzzzzzzzzwwzzr}}}}rwzwrzxuuuuuuuuuux>>v?}zzwvv}r}}rwzxzzzxxxxuxxxxxxxw~<br></br>
`xuxzzzzzzzzwzxxw}vvv}rwzzzwwr??wurzxzzwrvwrwxuxw}}}}rwzzzzzzxxxxuxxxxxxzw~<br></br>
.+}zzzzzzzzzzzxz}vvv}rwzzzzzzwr?vz?rxxxuxzrrrwzzwrwwwzxxxxzzzxxxxxxxxxzwrr~<br></br>
.|^>}zzzzzzzzzzzwwwwwzzzzzzzzzzxuuxuuuuuxwwwwzxxxxuxxxxxxxxzzxxxxxxxxzrrrr~<br></br>
                  .|^~|?wzzzzzzzzzzzzzzzzzzzzzzzzxuuuuuuuuuxxxxxuuxxuxxxxxxxxzzxxxuxxxxwrrrr~<br></br>
`>+^~-|vzzzzzzzzzzzzzzzzzzzzzzxxuuuuuuuuuuuuuuuuuxxxxxxxxxzzzzxxuuxxxwrrrr~<br></br>
`w?++|~-+}zzzzxxxxxxxxxxxxxxxxxuuuuuuuuuuuuuuuuuuuuxxxxxuxzzzzxxxuxxxzrrr}~<br></br>
`ww}?++^~~?zxxxxuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxxxxxxxxuxxxxwrr}~<br></br>
`wwwwv+>^|~^vuuuuuuuuuuuuuuuuuuuxxxxxzzzzxxxxuuuuuuuuuuuuuuuuuuxxuxxxxzrrr~<br></br>
`wwr}vv?++^~-?xuuuuuuxxzzwwwwwwwrrrrrrrrrrrrrwrwwwzzzxxuuuuuuuuuxxxxxxzwzw~<br></br>
`}}vvvvvv?++^-^wuxzwwrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrwzzzxxuuuxxxxxxxxxw~<br></br>
`vvvvvvvvvv?>+||v}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrv}uuxxxxxxxxxz~<br></br>
`vvvvvvvvvvvv?>^?rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv?xuuxxxxxxxxx~<br></br>
`vvvvvvvvvvvvvvrv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv?zuuuuxxxxxxx~<br></br>
`vvvvvvvvvvvv}uxvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}v?zuuuuuuxxxxx|<br></br>
                  `vvvvvvvvv?vzuux??vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv?zuuuuuxxxxxu^<br></br>
`vvvvvvvvvwuuuuuv?v}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvxuuuuxxxxxxu|<br></br>
`vvvvv}rzuuuuuuuw?v?}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv}uuuuuxxxxxxu|<br></br>
`v}rwzxuxuuuuuuuuv?vvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?wuxxxxxxxxxuu|<br></br>
`xuuxxxxxxxxxxxxxw?vvv}rwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vvv?}uxxxxxxxxxuuu^<br></br>
-uuuxxxxxxxzwzzzxxr?vvvvrwwwwwwwv?vvv??????}wwwwwwwww}vvvvvruuxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwwwzxxxrvvvvv}rwwww}??vvvvv?v?|?wwwwwwr}vvvvvvwuuuxxxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwwzxxxxwvvvvvvv}}??|`??+?^>v`~+>vrr}vvvvvvv}xuuxxxxxxxxxuuuuu|<br></br>
-uuuxxxxxxxzwwwwzxxxxx}??vvvv>-|?>>?`+`|?^||||??v?????vwxxxxzxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

.^^^^^^^^^+?v}rwwwwrr}}}}}}}}}}}rrrrrrrrrrrrrrr}}}}r}}}}}}}}}}}}}}rrrrrrrr~<br></br>
.>>>>>>>>>>vwxuyyyyuxxzzzxxxxxxxxxxuuuuuuuxxuuxwzzxuuxxxzzzzzzzxxuuuuuuuuu|<br></br>
.>>>>>>>>>>?wxuyyyyuxzzzzxxxxxxxxxxuuuuuuzuuwxwrwwxuxzzzzzzzzzzxxuuuuuuuuu|<br></br>
.>>>>>>>>>>>vxuuuuuuxzzzzxxxxxxxxxxuuuuuuuzxxxzzzzxxzzzxxzzzzzzxxuuuuuuuuu|<br></br>
.>>>>>>>>>>>?wwwzzxuzzzzzzzxxxxxxxxzuuyyyxzxxzzzzzxxuuuuxxzzzzzxxuuuuuuxxu|<br></br>
.>>>>>>>>>>>+?vvvrzxwwzzzzxxxxxxxzxuyyyyyyyyuuuxzzzxuxxxzzzzzzzxxuuuuuuxxx|<br></br>
.>>>>>>>>>>>>?}vvrzzxxxxxxxxxxxzwuuxuxuyuuuzzv}xzzwzwrrrwzzzzzzxxxuuuxxxxx|<br></br>
.>>>>>>>>>>>>+wxwwzzzxxxxxxxxxzzxz^`+uzyuuzz?  vzuw}r}}}wzxzzzxxxxxxxxxxxz~<br></br>
`?>>>>>>>>>>>^vxxw}}}}rwzzxxxxwuxx+`>uzyuuuzr??wwxur}}rwzxzzzxxxxuxxxxxxxw~<br></br>
`?>>>>>>>>>>>>?xw}vvv}rwzzzzzwzuuzxxxxuyuuuuxuxxzxzzvrzzzzzzzxxxxuxxxxxxzw~<br></br>
.>>>>>>>>>>>>>>rrvvv}rwzzzzzzrxuuuxxwr}v????vuuuzuuwrzxxxxzzzxxxxxxxxxzwrr~<br></br>
.^>^+>>>>>>>>>>vwrwwwzzzzzzzzwzyuuuyw``~+>+~vyyxxuuz}uxxxxxzzxxxxxxxxzrrrr~<br></br>
.^>^~+>>>>>>>>>?zzzzzzzzzzzzzzruyuuyy}?vvvv}uyyzuyuuzuxxxxxzzxxxuxxxxwrrrr~<br></br>
-?^>|~|^>>>>>>>>wzzzzzzzzzzwzzzwuyuuuyuzwwzuyuyxuuuuwxxxxxzzzzxxuuxxxwrrrr~<br></br>
`r+>+~~~^+>>>>>>wxxxxxxxxxvvxxuxzxuuuuuyyyyuyyyzuuuuwxxxuxzzzzxxxuxxxzrrr}~<br></br>
`w}>>^~~~|^>>>>+ruuuuuuuuwzuyyyyyxzzxuuuuuuuuuuzuuuuwuuuuxxxxxxxxuxxxxwrr}~<br></br>
`wwv??^||||^+>>+}uuuuuuuuuxzuuyyyyuxxxzzzzxxxxxuuuuzxuuuuuuuuuuxxuxxxxzrrr~<br></br>
`wwr?>?^|||||^++vuuuuuuuuxxxxzxxrxuuuuuuxxuuuuuuuuuruwwxuuuuuuuuxxxxxxzwzw~<br></br>
`}}vv???^^^||~|^vuuuuxxxxxxxxxuxrwxwuuuuuuuuuuuuuuurwrzrxuxuxxuuxxxxxxxxxw~<br></br>
`vvvvv>>?+^^^||~vxxxxxxxxxxxxuuuuxzwxxxxxuuuuuuuuuwzuuuwzzwrrzuuxxxxxxxxxz~<br></br>
`vvvvvv>>>+++^^~vxxzzzwwwwwwwwwwwwwwwwwwwwwwwwzzzrwxuuurwwrrwxuuuxxxxxxxxx~<br></br>
`vvvvvvv?>?+^^^^vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzrwwwwzxuuuuuxxxxxxx~<br></br>
`vvvvvvvvv>>+>v}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrzuuuuuuuxxxxx|<br></br>
`vvvvvvvvvv??}wwwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}}wxuuuxxxxxu|<br></br>
`vvvvvvvvvvvvv}wrrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwrvvruuxxxxxxu|<br></br>
`vvvvvvv}rzu}vv}rrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrvvvzuuxxxxxxu|<br></br>
`vvv}wzzxuuuz?vv}rrrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvruxxxxxxxuu|<br></br>
`xxxxxxxxxxxx}?vvvrrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvxxxxxxxxuuu^<br></br>
-uuuxxxxxxxzzzvv}vv}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vvvvxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwww}vv}vv}rwwwwwwwwwv??vvvvv??>vwwwwwwwwwwwrvvvv}xuxxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwwzw}v}vvv}}rwwwwr}>>v??v???>~>}wwwwwwwr}vvvvv}xuxxxxxxxuuuuu|<br></br>
-uuuxxxxxxxzwwwwxzrvvvvvvv}r}v>^+`>?|>+>?`.~^>}rr}}vv?vvvvrxxxxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvv?-+>~---~~~~~~--v}rrrrrrrrrrr}}}}}}}}}}}}}}}}}}}rrrrrrrr~<br></br>
`wwwwwwwwwwwwwwww}^>?^|||||^^^^||^zxuuuuuyyyyuxwwwxuuxxxzzzzzzzxxuuuuuuuuu|<br></br>
`wwwwwwwwwwwwwww}^>?||||||||||||~>xuuuuuuyyyuxwrrrzuxzzzzzzzzzzxxuuuuuuuuu|<br></br>
`wwwwwwwwwwwwww}^+?^||||||||||||~vxxuuuuuyyyuzrrwwxzwwzxxzzzzzzxxuuuuuuuuu|<br></br>
                  `wwwwwwwwwwwwww^^?+|||||||||||||^wxxxuuuuuuyuxzzzzzxxxuuxxzzzzzxxuuuuuuxxu|<br></br>
                  `wwwwwwwwwwwww?~?+|||||||||||||-?xxxxuuxxzxyyyyxxzzxuxxxxzzzzzzxxuuuuuuxxx|<br></br>
`wwwwwwwwwwwwv->>|^|||||||||||~|wzzxuuxzrxuwxxzzzzwzwrwwzzzzzzzxxxuuuuxxxx|<br></br>
`wwwwwwwwwwwr|+?||||||||||||||-vxzxxzzzzxrz}}}}rzw}rr}}}wzxzzzxxxxxxxxxxxz~<br></br>
`wwwwwwwwwww>|?+|||||||||||||~+xzzzwzuuuw}wr}wzuxr}rr}}rzxzzzxxxxxxxxxxxxz~<br></br>
`wwwwwwwwww}~??|||||||||||||||wxzzxuyyuuuuuuxzxzw}}}}rwzzzzzzxxuxuxxxxxxzw~<br></br>
`wwwwwwwwww>^?|~|||||||||||~~vxwzxuuxuuuyyxxzzxz}}rrwzxxxxzzzxxxxxxxxxzwwr~<br></br>
`wwwwwwwwwr|??-~|||||||||||~>zrxz?+vxzyyyxw?..vwurzxxuxxxxxzzxxxxxxxxzrrrr~<br></br>
`wwwwwwwww?|?+~||||||||||||^w}xxz` -yruuyuzv-`vwuywxxxxxxxxzzxxxuxxxxwrrrr~<br></br>
`wwwwwwwww++?~~||||||||||~~}zryuzz}wzxyuuuuxxzzwzzxwxxxxxxzzzzxxuuxxxwrrrr~<br></br>
`wwwwwwwwr^>>~~||||||||||~?xrzyuuuuzzwwwrrwruuuryzwrxxxxuxzzzzxxxuxxxzrrr}~<br></br>
`wwwwwwww}|?>~~|||||||||~>xxrzyuuyy}-``~~-`?yyzzyywvuuuuuxxxxxxxxuxxxxwrr}~<br></br>
`wwwwwwwwv-?>~~||||||||~+xuuxruuyyyu>|?????xuywxyyx}uuuuuuuuuuuxxuxxxxzrrr~<br></br>
`wwwwwwww}|?>~~|||||||~|wuuuuwwyyyyyyxwrrwuyyyruyyyruuuuuuuuuuuuxxxxxxzwww~<br></br>
`wwwwwwwwr|>>|~|||||||~vu}vxxxwzuyyyyyyyyyyyyywuuuuwuuuuuuuuuuuuxxxxxxxxxw~<br></br>
`}rwwwwwww^^>~|||||||~?uzrxyyyyuzzxuuuyyyyyuuurxuuuruuuuuuzwwxuuxxxxxxxxxz~<br></br>
`vvv}}}r}r?|?~||||||~?uuxzxuyyyyyuzzzzzzzzzzzzzuyuwzuuuuuzrrzuuuxxxxxxxxxx~<br></br>
`vvvvvvvvv?|?|~|~||~?xxxxzwrwwwwwrwr}}}}}}rrwzxzxxrxuuuuuxzzxuuuuuuxxxxxxx~<br></br>
`vvvvvvvvvv++>~~~^+?rrrwrrwwwwwwwwwwwwwwwwwwwwwwwwrwwwwzxxxxxuuuuuuuuxxxxx|<br></br>
`vvvvvvvvvv?^>^?vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwr}}wuuuuuuxxxxxu|<br></br>
`zw}vvvvvvvv??vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr}vrzuuxxxxxxu^<br></br>
-uuxzzxxxxxxvvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv?uuxxxxxxu|<br></br>
                  -uuxxzxxxuuxvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvvvxxxxxxxuu|<br></br>
-uuxzzxxxxxxvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv}uxxxxxuuu^<br></br>
-uuuxxxxxxxx}??vvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv?wxxxxxxuuu|<br></br>
-uuuxxxxxxxzwvvvvvv}rwwwwwwwwwwwrrrrrrrrr}}rwwwwwwwwwwwwwr}vvv?ruxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwv??vvvv}rwwwwwwwww???>??????^?wwwwwwwwwwwr}vvvvvruxxxxxuuuuu^<br></br>
-uuuxxxxxxxzwww}vvvvvvv}rwwwrrrv>>vv?vv??>`|?vrrrrrr}vvvvvvvvwxxxxxxxxxxxx|<br></br>


            </div>,

            <div>

`vv}}}vvvvvvvvvvvvvvvvvv??????????vvvvvvvvvvvvv?v}}}}}}}}}}}}}}}}}rrrrrrrr~<br></br>
`zxxzwwwwwwwwwwwwwrrrrrrrrrrrrrrrrwwwwwwr}}vvvv?vwxuuxxzzzzzzzzxxuuuuuuuuu|<br></br>
-xxzwwwwwwwwwwwwwr}r}}}}}}}}}}}}}rwwr}vvvvv?????>?xuxwwzzzzzzzzxxuuuuuuuuu|<br></br>
-zwwwwwwwwwwwwwwrrr}}}}}}}}}}}}}rrrvv???????????>?xzwwwzxzzzzzzxxuuuuuuuuu|<br></br>
`wwwwwwwwwwwwwwrrr}}}}}}}}}}}}r}????????????????>}xxxxuuxxzzzzzxxuuuuuuxxu|<br></br>
`wwwwwwwwwwwwwr}r}}}}}}}}}}}r}?^+????????????????zzuuxxxxzzzzzzxxuuuuuuxxx|<br></br>
`wwwwwwwwwwwwr}rr}}}}}}}}}rr?^^>????????>???????wzwzwwwwzzzzzzzxxxuuuuxxxx|<br></br>
`wwwwwwwwwwwwr}r}}}}}}}}}rv>^++>????????>>>>>??vxw}}r}}}wzxzzzzxxxxxxxxxxx|<br></br>
`wwwwwwwwwwwr}rr}}}}}}}r}?^++|^?????????>>>>>>vxxr}rr}}rzxzzzxxxxxxxxxxxxz~<br></br>
`wwwwwwwwwww}rr}}}}}}}rv^|+^~~??????????vv?>>vuxw}}}}rwzzzzzzxxuxuxxxxxxzw~<br></br>
`wwwwwwwwwwr}r}}}}}}}r?^^+|~~+???????>>ruyx?vxuxr}rrwzxxxxzzzxxxxxxxxxzwwr~<br></br>
`wwwwwwwwww}rr}}}}}rr?^>+^|~^>>??^~^?vwuzwr???wuxzzxxuxxxxzzzxxxxxxxxzwrrr~<br></br>
`wwwwwwwwwr}rr}}}}r}>^>+|~||?????``?xuuu}?vr??wuuuxxxxxxxxxzzxxxuxxxxwrrrr~<br></br>
`wwwwwwwwwr}r}}}}}v++>^|~~~>?>???}xuuuuuuuuuuxxxuuuxxxxxxxzzzzxxuuxxxwrrrr~<br></br>
`wwwwwwwww}rr}}}}}^+?^~||~+?>>?vxyyuuuuuuuuuyyuxxuuxxxxxxxzzzzxxxuxxxzrrr}~<br></br>
`wwwwwwwwwrrr}}}}+^?^||||^??>>vuyyuuwrwwwwrwyyuuuxxxuuuuuxxzzxxxxuxxxxwrr}-<br></br>
`wwwwwwwwrrrr}}}+^>^~~||^?????yuuuuuwzxxxzwwv}zxyuzxuuuuuuuuuuxxxuxxxxwrrr~<br></br>
`}rwwwwwwr}rr}r?|?+~~~~|?????wyxw~`vyxuuuzu+ .}zyyzzxuuuuuuuuuuuxxxxxxzwwr~<br></br>
`v}}rwwwww}rr}v|++~~~~~>?>>>>zuzz+-vyuyyyuxw?vzzyuxywuuuuuuuuuuuxxxxxxxzxw~<br></br>
`v}}}rrrwwrrr}>~>|||||+?vv???zyuxuxuuyyyuyuuyuzuuxyywxuuuuxzzxuuxxxxxxxxxz~<br></br>
`vvvvv}}}}r}r}|^^~|||+??}uyuxzyyyuurvvvvvv?}uuuuzyuuzuuuuzrrzuuuxxxxxxxxxx~<br></br>
`vvvvvvvvvvvv?~+~~||+???>?wuuxwuuuuv-|+>>+^ruyuzuuuuwuuuuzzzxuuuuuxxxxxxxx~<br></br>
`vvvvvvvvvvvv>|+-|^^>>>????}zzwrwwwwvv}}}}rwwzzwxxxxruuuuxxxxuuuuuuuuxxxxx~<br></br>
`rr}vvvvvvvvv++>+>????vvv}}rrwwwwwwwwwwwwwwwwwwwwrwwrwwwzzwrrzuuuuuuxxxxxu|<br></br>
-xxwwr}vvvvvv>???v}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrr}}rwxuuuxxxxxxu^<br></br>
-uuxzxxxxzwrv?vv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}v}wuuxxxxxxu|<br></br>
-uuxxzxxxuuuvvvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvvvxxxxxxxuu^<br></br>
-uuxzzxxxxxxvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvxxxxxxuuu|<br></br>
-uuuxxxxxxxz?vvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvv}xxxxxxuuu|<br></br>
                  -uuuxxxxxxxzvvvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvvvvwxxxxxuuuu|<br></br>
-uuuxxxxxxxz}vvv?vvv}rwwwwwwwwwwv??????????vwwwwwwwwwwwwr}vvvvv}xxxxxuuuuu^<br></br>
-uuuxxxxxxxzwrvvvvvvv}rwwwwwrrrr??v??vvvv?|^vrrrrrrrrr}vvvvvvv}zxxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvvvvv}rwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}rrrrrrrr~<br></br>
                  -xxxxzzwwwwzzxuyuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzzzzzzxxuuuuuuuuu|<br></br>
                  -xxzzrrwwzxuuuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrzzzzzzzxxuuuuuuuuu|<br></br>
-uuxwwzzuyyuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrwwwwwwww}wzzzzzxxuuuuuuuuu|<br></br>
                  -uuzzuuuyuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrzzzzzxxuuuuuuxxu|<br></br>
                  -uxwuuyuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrwzzzzxxuuuuuuxxx|<br></br>
                  -xzyuuxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrzzzzxxxuuuuxxxx|<br></br>
-}xyxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrr}}}vvv}}wzzzxxxxxxxxxxx~<br></br>
`vxuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}v??????>>>>>>>>rwzxxxxxxxxxxxz~<br></br>
.vxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv?>+^+>?>>+++++++++vzxxuxuxxxxxxxw~<br></br>
`vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?+>>?>++++^^^^^+++++++vzxxxxxxxxxzwwr~<br></br>
`v}wwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?>++??>+^|^^^^^^^^++++>>vzzxxxxxxxxzwrrr~<br></br>
`vvwwwwwwwwwwwwwwwwwwwwwrrwwwwrv>+>??>^||||^^^++++++++++>vxzzxxxuxxxxwrrrr~<br></br>
`vv}wwwwwwwwwwwwwwwwwwwwwwww}?++>??>+||vrv+^^+++++++++^>vxzzzzxxuuxxxwrrrr~<br></br>
`vvvrwwwwwwwwwwwwwwwwwwwww}?+>>?>++++^vyyyr^^+++++++^^>wuxzzzzxxxuxxxzrrr}~<br></br>
`vvv}wwwwwwwwwwwwwwwwrwwv?^>>+^>++^>vwwzw}+^^+++++^^>vxuxxzzzzxxxuxxxxrrr}-<br></br>
`vvvvrwwwwwwwwwwwwwwww}?+>?+||++^+vxuuzw???>^|^+^^+vzuuuuuxxxxxxxuxxxxwrrr~<br></br>
`vvvvvrwwwwwwwwwwwwww?^+?+~~~^+^>rxuuuuyyyyuzv>|+?zuuuuuuuuuuuuuxxxxxxzwwr~<br></br>
-rvvvv}rwwwwwwwwwwwr>^?>^~~~~||?xxxuuuuuuuuuuyu}ryuuuuuuuuuuuuuuxxxxxxxzxw~<br></br>
                  `zvvvvvvrwwwwwwwwwv^+?+||||+~~?uyuyyyyyyuuuuyuyyzzuuuuuuuuxzzxuuxxxxxxxxxz~<br></br>
`zwvvvvvv}wwwwwwwv^>?^~|^^^zv+uyyyyuuuuuuuuuuyyuywxuuuuuuzrrzxuuxxxxxxxxxx~<br></br>
`zzwvvvvvvv}wwwwr^>+||~|||^xuwyuxuuuuuuuuuxuyuuyyxzuuxuuuzzzxuuuuuxxxxxxxx~<br></br>
`xzxzvvvvvvvv}rr+>>|~~~~~~|uzwuzzrwxzuuuuxzr?vxzyurxzzxuuxxxxuuuuuuuuxxxxx~<br></br>
-xxxxzrvvvvvvvv>^>~||||||||wxrwz~.`?rwxzxwx-  vzxxvxyzxuuxzwwzuuuuuuxxxxxu|<br></br>
-uuxxxuzvvvvvv?|?^|^+>>???v}wrrr}}}rrrrrrrr}}vrrwrwzz}zxxxzwwzxuuuuxxxxxxu|<br></br>
-uuxzxxuxz}vvv+>??v}rrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrrwwzzxxuuuuxxxxxxu|<br></br>
-uuxzzxxxuuxw??vv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv}rzxxxxxxxuu^<br></br>
-uuxzzxxxxxuzvvvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvv}xxxxzzxuu|<br></br>
-uuuxxxxxxxxvvvvvvrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvvvxxxxxxuuu|<br></br>
-uuuxxxxxxxz?vvvvv}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvv?vxxxxxuuuu|<br></br>
-uuuxxxxxxxz?vvvvvv}wwwwwwwwwwwwwrrrrrrrrrrrwwwwwwwwwwwwwwrvvvv?vuxxxuuuuu^<br></br>
-uuuxxxxxxxxvvv?vvvv}rwwwwwwrrrrv?????????^?rrrrrrrrrrrrr}vvvvv?wxxxxxxxxx|<br></br>


            </div>,

            <div>

`}}}}}rrvvvvvvv}rwrrr}}}}vvvvvvvvvv}}}}}}}}vvvvvvvvvv}rrrrrrr}}}}}}rrrrrrr~<br></br>
                  -xxxxuuxzwzzxuuyyuxzzzzzzzzwwzzzzzzzzzzzzzzzzwwwwwwzzzxuuuuuuxxxuuuuuuuuuu|<br></br>
                  -xxxxxzzzxuyuuuxzzzzzzzzzzwwwwwwzzzzzzzzzzzwwwwwwwwwzzzxuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuzzzxuyyuxzzwzzzzzzzzzzzwwwwwzzzzzzzzzzzwwwwwwwzzzzzzxuuuuuuuuuuuuuuuuu|<br></br>
                  -uuxzuuyyuxzzwwwzzzzzzzzzzzwwwwrwwzzzzzzzzwwrrrwwwzzzzzzzxuuuuuuuuuuuuxxxu|<br></br>
-uxxuuyuxzzzzzzzzzzzzzzzzzzwwr}}rwzzzzzzzzww}vvrwwzzzzzzzzxuuuuuuuuuuuxxxx|<br></br>
-xzyuuuzwwzzzzzzzzzzzzzzzzwwww}}rwzzzzzzzzwr}}}wwwzzxzzzzzxuuuuuuuuuuuxxxx|<br></br>
-rxyuzwwwzzzzzzzzzzzzzzwwwwwwwr}rwwzzzzzzzwrrwwwwwzzzzzwwwzuuuuxuuuxuxxxxx~<br></br>
`vuuwrrrwzzzzzzzzzzzzzwrrrwwwwwwwwwzzzzzzzwwrrrrr}}}rrrr}}}xuuxxuuxxxxxxxz~<br></br>
`}xzrrrrwwwzzzzzzzzzzzwwwwwwwwwwwwwzzzzzwr}vv}}}}}}}}}vvvvvwuxxuuuxxxxxxxw~<br></br>
`}zwrrrrwwwwzzzzzzzzzzzwwwwwwwwwwwwzwwr}}}}}}}vv}}}}}vvvvvvzuuuuxxxxxxzzwr~<br></br>
`}wrrrrrrwwwwzzzzzzzzzzzwwwwwwwwwwr}}}rr}}}}}vv}}}}}}vvvvvruuuuuxxxxxzwrrr~<br></br>
`}}rrrrrrwwwwwzzzzzzzzzzzwwwwwww}}}}r}}}}v}}}}}}}}}}}vvvvruuuxxuuxxxxzrrrr~<br></br>
`}}rrrrrrrrwwwwzzzzzzzzzzzzzwr}}}}}}}}rzxzr}}}}}}}}}}}v}zuuuxxxxuuxxxwrrrr~<br></br>
`}}}rrrrrrrrwwwwzzzzzzzzzzzr}}r}}}}}vvwyyyx}}}}}}}}}}}rxuuuxxxxxxuxxxzrrr}~<br></br>
`}}}rrrrrrrrrwwwzzzzzzxxzr}}}}}vvvv}wxxuzzr}}}}}}}}}rzuyuuuxxxxxxuxxxzrrr}-<br></br>
`}}rrrwrrrrrrrwwwzzzzzzw}rr}vvvvvvrxuuxxrr}}}vvvvv}wuuyyuuuxxxxxxuxxxxwrrr~<br></br>
`vrrrrrrrrrrrwwwwwwzzr}}r}vvvvvv}wzzzzzzzzzzzw}vv}zxxxuuuuuuuuuuxxxxxxzwwr~<br></br>
`wrrr}}rrrrwwwwwwwwwvv}}vvvvvvv}zzzzzzzzzzzzzzzrrwzwwwzzxxuuuuuuxxxxxxxzxw~<br></br>
`zwrr}}}rrwwwwwwwwrvv}}vvvvvvv}zzzzzzzzzzzzzzzzzzwwwwwwwxuxxxxuuxxxxxxxxxz~<br></br>
`zzr}}}}}rwwwwwwwrv}r}vvvvvwr}wzzzzzzzzzzzzzzzzzzwwwwwwzuzrrzxuuxxxxxxxxxx~<br></br>
`zzz}v}}}}}rwwwwwv?v}vvvvvvzzwzzzzzzzzzzzzzzzzzzzzrwwwwzuwwzxuuuuuxxxxxxxx~<br></br>
`zzxxr}}}}}}}rwwvv?^?vvvvvvzwzzwzrrzzzzzzzzrvvwwzzrwwwwzwxxxuuuuuuuuuxxxxx~<br></br>
-xxxuxzr}}}}}}}vvvv+|>vvvvvwwwrz>^+vrrwzzww>||}zww}wzwrvwuxwwzuuuuuuuxxxxu|<br></br>
-uuxxxuxw}}}}}vvvvv?^^+?}rrrwrww}}}vv}}}}}wrr}rwwrwwrv?}uxzwwzxuuuuxxxxxxu|<br></br>
-uuxzxxxuxw}}}vvvv}r}vvvv}rwwwwwrrr}}}}}}rrwwwwwwwr}vvvwwwzzxxuuuuuxxxxxxu|<br></br>
-uuxxzxxxxuxw}}}}rrrrrvvvvv}rrwwwr}}v??vv}rwwwrr}vvvv}rwwwwrrrwzxxxxxxxxuu^<br></br>
-uuxzxxxxxxux}}}}}rrrr}vvvvvvvvvvvvr}vvr}v}}}vvvvvvv}wwwwwwwr}}}wxxxxzzxuu|<br></br>
-uuuxxxxxxxxrv}}}}rrrrrr}vvvvvv?v}v}?}vv}v}?vvvvvvvrwwwwwzwwr}}}rxxxxxxuuu|<br></br>
-uuuxxxxxxxzvv}}}}rrrwwwwr}vvvv?vrvv????v}r?vvvvv}wwwwwwwzzwr}}}}xxxxxuuuu|<br></br>
-uuuxxxxxxxzvv}}rrrrrwwwwwwr}vv??}}??????rv?vvv}wwwwwwwwwzzwr}}}}uxxxuuuuu^<br></br>
-uuuxxxxxxxx}}}}}rrrrrrrrrwwr}vv???|||~~^?>>}}rrrrrrrrrrrrr}}}}}wxxzxxxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrrrrrrrr}rrrrrrwwwwwwwwwwwwwrrrr}}}}rrrrrrwwwwwrrrrrrrrrr~<br></br>
                  -uyyyyyyyyyuuuuuyyyyyyyuuuuuuuuyyyyyyyyyyyyyyyuuuuuuuuuuuyyyyyyyuuuuuuuyuu^<br></br>
                  -uyyyuuyyyyuuuuyuyyyyyuuuuuxuuuuyyyyyyyyyyyyyuxxxxuuuuxuuyyyyyyyuuuuuuuuuu^<br></br>
                  -uyyyyyyyyyyuuuyyyuuyyyyuuuuuuuuyyyyyyyyyyyyuuxxxxuuuxuuyyyyyyyuuuuuuyuuuy^<br></br>
                  -uyyyyyyyyyyyuuuuyuuuuyyuuuuuuuxuyyyyyyyyyyuxxzzxuuuxuuyyyyyyyuuuuuuuuuuuy^<br></br>
-uyyyyyyyyyyyyyuuuuuuuuyuuuxzrvwxuyyyyyyyyyuzw}rzuuuuuyyyyyyyuuuuuuuuuuyuy^<br></br>
-uyyyyyyyyyyyyyyuuuuuuuuuuuzv?>vzuyyyyyyyyyxz}??}zuuuuyyyyyyuuuuuuuuuuuuuy^<br></br>
-uyuuuyyyyyyyyyyyuuuuuuxxuux}??vzuyyyyyyyyyxw}?vwwzuuyyyyyyyuuuuuuuuuuuuuu|<br></br>
-uyuuuuuyyyyyyyyyyuuuuxwzxuxzrv}zxyyyyyyyyuxwvvwzzuyyyyyyyyyuuuuuuuuuuuuuu|<br></br>
-uuuuuuuyyyyyyyyyyyuuuw}wxxuxzwrwxyyyyyyyyyuzrrzxuyyyyyyyyyyyyyuyuyyuuuuux|<br></br>
                  -uuuuuuuuyyyyyyyyyyyyuxwwzxxxxwwwxuyyyyyyyyxzwzxuuyyyyyyyyyyyyyyyyyyuuuxzz|<br></br>
                  -uuxxuuuuuyyyyyyyyyyyyuuzzzxxxzwwzuyyyyyyyuuzzuuuyyyyyyyyyyyyyyyyyyuuuxzwr~<br></br>
                  -xxzxxxuuuyyyyyyyyyyyyyuuzzxxuuzwzuyyyyyyyyuuuuyyyyyyyuuuuyyyyyyyyyuuxwrrr~<br></br>
                  -zzzzzzxuuuyyyyyyyyyyyyyyuuuuuuxzzuyyyyyyyyyyyyyyyyyyyuuuuyyyyyyyuuuxzrrrr~<br></br>
                  `zzzzzzzxxuuuyyyyyyyyyyyyyyyyyyuuxuyyyyyyyyyyyyyyyyyyyuuyyyyyyuuuxuuxzrrrr~<br></br>
                  `zzzzzzzzxxxuuyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyuuuxuuxxzrrrr~<br></br>
                  `zzzxxzzzzxxxuuuyyyyyyyyyyyyuuuuuuxxxxxxxxxxxxuuuuuyyyyyyyyuuuxxxxxxxxrrrr~<br></br>
                  `zxxxxxzzzzxxxxuuyyyyyuuxxxzzzzwwwwwwwwwwwwwwwwwzzzxxuuuuuuuuuuxxxxxxxzwrr~<br></br>
                  `zxxxxzzzzxxxuuuuuuxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxxuuuuuxxxxxxxxzzw~<br></br>
                  `zxxxzzzxxuuuuuuuxxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzuuxxuuuxxxxxxxxz~<br></br>
                  `zxxxzzzxuuuuuuuuxxuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxrzrwxuuxuxxxxxxxx~<br></br>
                  `xxxxxxxxuuuuuuuuuvrzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxzrrrxuuuuxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuuuw|?rwwwwwwwwwwwwwwr}rwwwwwwwwwwwwwwwwvvxxxuuuuuuuxuxxxxx|<br></br>
-xxxxxxuuuuuuuuuuuu?-^?rwwwwwwwwww}v???vvrwwwwwwwwwwwv>^wuxzzxuuuuuuuuxxxx|<br></br>
`xxxxxuuuuuuuuuuuuux+-~+?rwwwwwwrv???????vrwwwwwww}v>|~}uxzrrwxuuuuuxxxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuxw^-~^+?vrwwwwr?>|--^?vwwwwr}v?+^|-?uuuxxxxuuuuuxxxxxuu|<br></br>
-uuxzxxxuuuuuuuuuuuzzr+~|||^+??vv??v?~~???vvv?>+^||~^vuuuuuuuuuuuuuxxxxxxu|<br></br>
-xuxxxxxxxuuuuuuuuxzwr}?||||||^~`?^?|++??>>-|||||~~>wuuuuuuuuuuuxxxxxxxxxu|<br></br>
-xuxxxxxxxxxxxxxxxzwrwzuw>~-~||.`v+~```|~?? `~~-~+}xuuuuuyyuuuxxxxxxxxxuuu|<br></br>
-xuuxxxxxxxzwwzzxzzxxuuuuur>||~.-?v`....^}?.`~|+}xuuuuuyyyyuuxxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwwwzxxxxuuuuuuuuz}>-?v?.....~}?-+?rxuuuxxxuuuyyuuuxxxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwwwzxxxxxxxxxxxxzvv?v?.....-v|>vwxxxzzzzzzxxxxxzzzzzzzzzzxxxx|<br></br>


            </div>,

            <div>

`rrrrrwwrrrrrrrrrrrrrrrrr}rrrrrwwwwwwwwwwwwwwrrrr}}}}rrrrrrwwwwwrrrrrrrrrr~<br></br>
                  -yyyyyyyyyuuuuuuyyyyyuuuuuuuuuuyyyyyyyyyyyyyyyyuuxxuuuxuuuyyyyyyyuuyyuuyuu^<br></br>
                  -uyyyyyyyuuuuuyyyyyyyuuuxxuuuuuuyyyyyyyyyyyyyyuuxxuuuxxxuuuuuyyyyyyuuuuuuu^<br></br>
                  -uyyyyyyyyuuuuuyyyuuuuuuuxuuuuuuyyyyyyyyyyyyyuuuxuuuuxxuuuuuyyyyuuuuuuuuuy^<br></br>
                  -uyyyyyyyyyuuuuuuyuuuuuuuxxxuuuuuyyyyyyyyyyyyyuxxuuuxxuuuuyyyyyyuuuuuuuuuy^<br></br>
                  -uyyyyyyyyyyyyuuuuuyuuuuuxxxzzwzuyyyyyyyyyyyyuzzxuuuuuuuuyyyyyyyuyyuuuyyuy^<br></br>
-uyyyyyyyyyyyyyuuuuuuuuuuuxzvvvzuyyyyyyyyyyyuz}vrxuuuuuuyyyyyyyyyyyyyyyuuy^<br></br>
-uyyyyyyyyyyyyyyyuuuuuuuuuxr??vwuyyyyyyyyyyyuw}vv}rxuuuyyyyyyuuuyyuuyuuuuu^<br></br>
-uyuyyyyyyyyyyyyyyuuuuuxuuxw}vvrxuyyyyyyyyyuxr}rr}}wuuyyyyyyyyuuyuuuuuuuuu^<br></br>
                  -uuuuyyyyyyyyyyyyyyuuuzwzxuxwwwwwxyyyyyyyyyuxwwwwwzxuyyyyyyyyyyuyuyuuuyuuu|<br></br>
                  -uuuuuuyyyyyyyyyyyyyuuuxxxuuzwwwwxuyyyyyyyyuzzzzzxuyyyyyyyyuuuyyyuyyuuuuxx|<br></br>
                  -uuuuuuyyyyyyyyyyyyyyyuuuxxuxzwwwxuyyyyyyyyuxxxxuuyyyyyyyyyuuyyyuuuuuuxxww~<br></br>
                  -xuuuuuuyyyyyyyyyyyyyyyyuuuuuxzzwxuyyyyyyyyuuuuuyyyyyyuuuuuyyyyyuuuuuxxzrr~<br></br>
                  -xxxxuuuuyyyyyyyyyyyyyyyyyuuuuuxzxuyyyyyyyyyyyyyyyyyyyuuuuuyyyyyuuuuuxzwww|<br></br>
                  `zzxxxxxuuuyyyyyyyyyyyyyyyyyyyuuxxuyyyyyyyyyyyyyyyyyyyuuyyyyyyyuuuuuxzwwww|<br></br>
                  `zzzzxxxxuuuyyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyyyyyyuuuuuuuxzwww~<br></br>
                  `zzzxxxxxxxxuuyyyyyyyyyyyyyyyuuuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyuuuuuuuuxzwrr~<br></br>
                  `zxxxxxzzxxxxuuuyyyyyyuuuxxxzzzzzzzzzzzzzwzzzzzzzzxxuuuuyyyyuuuuuuuuxxzwrr~<br></br>
                  `zxxxxzzzxxuuuuuuuuuxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzxuuuuuuuuxxxxxxzzw~<br></br>
                  `zxxxzzzxxuuuuuuxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxuuuuuuuxxxxxxxxz~<br></br>
                  `zxxxzzzxuuuuuuuuuzuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwuwzrwxuuuuxxxxxxxx|<br></br>
                  `xxxxxxxxuuuuuuuuuvvzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxwrrrxuuuuuuxxxxxxx|<br></br>
`xxxxxuxuuuuuuuuuuz|>}wwwwwwwwwwwwww}v}rwwwwwwwwwwwwwwr??xxxuuuuuuuuuxxxxx|<br></br>
-xxxxxxuuuuuuuuuuuu}~|?}wwwwwwwwwrv?????vrwwwwwwwwww}?^^zuxzzxuuuuuuuuxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuu>-~^>vrwwwwwwv??>+>>v}wwwwwww}v>^~|wuxzrrwxuuuuuxxxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuxz?-~||>?v}rwwv??^-~>??wwr}v?>^|~~+}uuuxxxxuuuuuxxxxxuu|<br></br>
-uuxzxxxuuuuuuuuuuxzzw?|||||^+>>>?>v+||?>>?>>+|||~~|?zuuuuuuuuuuuuuxxxxxxu|<br></br>
-xuxxxxxxxuuuuuuuuxzwrr}?|~~|||`.?+>-|~++?^.-~~~~-+}xuuuuuuuuuuuxxxxxxxxxu|<br></br>
-xuxxxxxxxxxxxxxxxzrrrzuxv>~~|~.`??-`..`+v>.`~~~+vxuuuuuuuyuuuuxxxxxxxxuuu|<br></br>
-xuuxxxxxxxzwwzzxzzzxuuuuuxr?>^-+v?`....^}>.~+?vxuuuuuuyyyyuuxxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwwwzxxxxuuuuuuuuuz???v?.....~v>+?rxuuuuuuxxuyyyuuuxxxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwwwzxxxxxxxxxxxxw?v??>.....`>|?vvrwzzxxxzzxxxxxzzzzzxzzzzxxxx|<br></br>


            </div>,

            <div>

`rwwwwwwwwrrrrrrrrrrrr}}rrrrrrrwwwwwwwwwwwwwwwwwwwwrrrrr}}}}}}}}}}rwwwwwww~<br></br>
                  -yyyyyyyyyuuyuuuuuuuuxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyuuuxxxxxzzxxxuuyyyyyyyy^<br></br>
                  -uyyyyyyuuuuuuyyyuuuuxzzxxxuuuyyyyyyyyyyyyyyyyyyyuuuxxzzzzzzzxuuyyyyyyyyyy^<br></br>
                  -uyyyyyyyyyuuyyyyyuuuxzzxxxxxuyyyyyyyyyyyyyyyyyyuuuuxzzzzzzxxuuyyyyyyyyyyy^<br></br>
                  -uyuyyyyyyyyyyyyyyyuuxxxzzxxxuuyyyyyyyyyyyyyyyyyuuxxzwzxxzxxuuyyyyyyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyyyyyuuxxzzzxxxuyyyyyyyyyyyyyyyyuuxxuxuuuxxxuuyyyyyyyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyyyyyyuuxxzxzzzxuyyyyyyyyyyyyyyuuxxuuuuuxxuuyyyyyyyyyyyuuuy^<br></br>
-uyyyyyyyyyyyyyyyyyyyyuuxxz}v}zuyyyyyyyyyyyyyyuzwwzzxxxxuuyyyyyyyyyyuuuuuy^<br></br>
-uuuuuyyyyyyyyyyyyyyyyyuuxw?>?wxuyyyyyyyyyyyyyxw}vv}wzxuyyyyyyyyyuuuuuuuyy^<br></br>
-uuuuuuuyyyyyyyyyyyyyyyyuxw}v}wxuyyyyyyyyyyyyuzwv??}zxuyyyyyyyuuuuuuuuuuuu^<br></br>
                  -uuuuuuuuyyyyyyyyyyyyyyyyuxzwwwzuuyyyyyyyyyyuxwwwrwzuuyyyyyyyuuuuuuuuuuuxx|<br></br>
                  -uuuuuuuuuyyyyyyyyyyyyyyyyuxzwwzxuyyyyyyyyyyuzwwwzxuuyyyyyyuuuuuuuuuuuxxzw~<br></br>
                  -xuuuuuuuuuuyyyyyyyyyyyyyyyuxzwwxuyyyyyyyyyyuzwwzxuyyyyyyuuuuuuuuuuuuxzzrw~<br></br>
                  -xuuuuuuuuuuyyyyyyyyyyyyyyyyuxzwzuuyyyyyyyyuuzzzuyyyyyyyuuuuuuuuuuuuuxzzzx|<br></br>
                  -xuuuuuuuuuyyyyyyyyyyyyyyyyyyuuxxuuyyyyyyyyyuuuuyyyyyyyuuuuuuuuuuuuuuxxxxu^<br></br>
                  -xxxxuuuuuuyyyyyyyyyyyyyyyyyyyuuuuuyyyyyyyyyyyyyyyyyyyyuuuyyyuuuuuuuuuuuuu^<br></br>
                  -xxxxuuuuuuuuyyyyyyyyyyyyyyyyyyuuuuuuuuuuuuyyyyyyyyyyyyyyyyyyyuuuuyyyuuuuu^<br></br>
                  -xxxxxxxuuuuuuyyyyyyyyyyuuuuxxxxzzzzzzzzzzzzzxxxuuuuuyyyyyyyyuuuuyyyyuuxxu^<br></br>
                  `xxxxxxxxxxuuuuuuyuuuuxxzzwwwwwwwwwwwwwwwwwwwwwwwzzzzxxuuuuuuuuuuuuuuuxxxx|<br></br>
                  `zxxxxzzxxuuuuuuxxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxuxxuuuxxxxxxxxux|<br></br>
                  `xxxxzzzxxuuuuuuuuzwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzxxzzxuuxxxxxxxxxx~<br></br>
`xxxxxxxxuuuuuuuuuw>}wwwwwwwwwwwwwwwr}rwwwwwwwwwwwwwwwwv}zrrzuuuxxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuuuuv~?}wwwwwwwwwww}v???v}wwwwwwwwwwwrv>^rzwzuuuuuuxxxxxxxx|<br></br>
-xxxxuxuuuuuuuuuuuux>~|>vrwwwwwwwwv?????vwwwwwwwww}?>|~}uxxxxuuuuuuuuxxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuuz>-~^+?vv}rwwr??^`->??wwwr}v?>^||^}uuxzrrzxuuuuuuuxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuxxz?||||^^++>??^?^~|?^|???>+^||~~>wuuuuxzzxxuuuuxxxxxuu|<br></br>
-uuxzxxxuuuuuuuuuuuxzzwv>|~~|||-.>+|-``~|?-`~|~~~-|vxuuuuuuuuuuuuuuxxxxxxu|<br></br>
-xuxzxxxxuuuuuuuuuxzwrrzzv>|~~|..>?`....>v~.-~~~+vzuuuuuuuuuuuuuxxxxxxxxuu|<br></br>
-uuxxxxxxxuxxxuuuxzwrrwxuxzrv?>-|v?`....>}^.|>?}xuuuuuuuuuyuuuuxxxxxxxxxuu|<br></br>
-xuuxxxuxxxzzzzzxzzzzxuuuuuxxx}????`....~>~>?rxuuuuuuuuyyyyuuxxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwwwzxxxxuuuuuuuuxr>vv?+`.....-+vv>vzuuuuuuuuyyyyuuxxxxxxxxuuuu^<br></br>
-uuuxxxxxxxzwwwwzxxxxxxxuuuw?^-vv?+.....`++vv-|>vwzzzzzxxxxxxzzzzzzzzzxxxx|<br></br>


            </div>,

            <div>

`rwwwwwwwwwwwrrrrr}}}}}}rrrrrrwwwwwwwwwwwwwwwwwwwwwrrrrr}}}}}}}}}}}rrwwwww~<br></br>
                  -yyyyyyyyyyyyyuuuuuxxxxxxxuuuyyyyyyyyyyyyyyyyyyyyyyyyuuxxxxzzxxxxuuyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyuuuxxxzzzxxxuyyyyyyyyyyyyyyyyyyyyyyuuuxxzzzzzzxxuuyyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyuuuuxzzzzzxxuyyyyyyyyyyyyyyyyyyyyyuuuzzxzzzzzxxuuyyyyyyyyy^<br></br>
                  -uyuyyyyyyyyyyyyyuuuxxzzzzxxxuyyyyyyyyyyyyyyyyyyyyuuxzzxxzzxxuuuyyyyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyyyuuxxzzzzxxxuuyyyyyyyyyyyyyyyyyyuuuxxuuxxxxuuuyyyyyyyyyyy^<br></br>
                  -uyyyyyyyyyyyyyyyyyyuxxxxxxxxxuyyyyyyyyyyyyyyyyyuuuuuuuuxzxuuyyyyyyyyyyyuu^<br></br>
                  -uyyyyyyyyyyyyyyyyyyyuxxxxxzzzuuyyyyyyyyyyyyyyyuuxxzzzzxxxuuyyyyyyyyyyyyyy^<br></br>
                  -uuuuuyyyyyyyyyyyyyyyyuuxxwvvwxuyyyyyyyyyyyyyyuxzwrrrwwzuuyyyyyyyyyuuyyyyy^<br></br>
-uuuuuuuyyyyyyyyyyyyyyyuxz}??rzuyyyyyyyyyyyyyuuzrv?vwzxuuyyyyyyyyuuuyyyyuy^<br></br>
-uuuuuuuuyyyyyyyyyyyyyyuuxwrrwzxuyyyyyyyyyyyyuxww}v}zxuyyyyyyyyuuyyyuuuuuu^<br></br>
                  -uuuuuuuuuuyyyyyyyyyyyyyyuxzwwwxuyyyyyyyyyyyuxzwwwwzuuyyyyyyyuuuuuuuuxxzzz|<br></br>
                  -xuuuuuuuuuuyyyyyyyyyyyyyyuxzwwzuuyyyyyyyyyyuzwwwzxuuyyyyyyuuuuuuuuxuxzwww~<br></br>
                  -xuuuuuuuuuuyyyyyyyyyyyyyyyuxzwzxuyyyyyyyyyuuzzzzuuyyyyyyyuuuuuuuuuuuxzzzx|<br></br>
                  -xuuuuuuuuuuyyyyyyyyyyyyyyyyuuxxxuyyyyyyyyyyuuxuuyyyyyyuuuuuuuuuuuuuxxxxxu^<br></br>
                  -xxxxuuuuuuuuyyyyyyyyyyyyyyyyyuuuuyyyyyyyyyyyyyyyyyyyyyuuuuuuuuuuuuuuuuuuu^<br></br>
                  -xxxxuuuuuuuuyyyyyyyyyyyyyyyyyyyuuuyyyyyyyyyyyyyyyyyyyyyyuuuuuuuuuyyyuuuuu^<br></br>
                  -xxxxxxxuuuuuuyyyyyyyyyyyuuuuuuuxxxxxxxxxxxuuuuuuuuyyyyyyyyyyyuuyyyyyuuuuy^<br></br>
                  `xxxxxxxxxxuuuuuyyyyuuuxxxzzzwwwwwwwwwwwwwwwwzzzzzxxuuuuuuuuuuuuuuuuuuuuuu^<br></br>
                  `xxxxxxxxxuuxuuuuuxxzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxuuuuuuuuuuuuuuuuu|<br></br>
                  `zxxxxxxuuuuuuuuuuzwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwxxxxxuuuuuuuuuuuu|<br></br>
`xxxxxxxuuuuuuuuuux?vwwwwwwwwwwwwwwrv?vrwwwwwwwwwwwwwwr?}xwwzxuuuuxxxxxxxx|<br></br>
`xxxxxxxuuuuuuuuuuuw^+vrwwwwwwwwwwv??>??vwwwwwwwwwwrv?^>xzwzuuuuuuxxxxxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuur^-^?v}rrwwwwwv?>||>?rwwwwwr}v?>|~?zuxxxxuuuuuuuuxxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuuur+~~|++??vv}v>?^`->+>}}vv?>^|~~^?zuuxzrrzxuuuuuuuxxxx|<br></br>
-xxxxxuuuuuuuuuuuuuuxxw?>|~|||||-^^^~--+|>-~^|~~~~|>ruuuuuzzzzxuuuuxxxxxxx|<br></br>
-xuxzxxxuuuuuuuuuuuxzzzzr?+|~||`.+?`...`>v`.-~~|+?vzuuuuuuuuuuuuuuuxxxxxuu|<br></br>
-xuxzxxxxuuuuuuuuuxzwrrzuxwv??>-`>?`....>v~.^>??}wxuuuuuuuuuuuuuxxxxxxxxxu|<br></br>
-xuxxxxxxxuuxuuuuxzwrrwxuuuxxw}+>??`....|?|^?}zxuuuuuuuuuuuuuuuxxxxxxxxxuu|<br></br>
-xuxxxxuxxxzzzzxxzwwzxxuuuuuxz??v?>`....`~^v??rxuuuuuuuuyyyuuxxxxxxxxxxuuu|<br></br>
-uuuxxxxxxxzwwwzxxxxuuuuuzrv?>-vv?>~....`+>vv-+?rwxuuuuuyyyyuuxxxxxxxxuuuu|<br></br>
-uuuxxxxxxxzwwwwwr}}v}}}v??>+~`vv??+~..-^>|vv.~|^?vvvvvvrwzxxzzzzzzzzzxxxx|<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}}}}}}}}}}}}}rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}rrrrrr~<br></br>
                  -uyuuuuuuuuuxxxxxxuxxxxxxxxuuuyyyyyyyyyyyyyyyyyyuuuuuuxxxxxzzzzzzzxxuuuuuu|<br></br>
                  -uuuuuuuuuxxxuuuuuuuxxzzzzxxxuuuuuuuyyyyyyyyyyuuuuuuuxxxzzzzzzzzxxuuuuyyuu|<br></br>
                  -uuuuuuuuuuxuuuyyyuuxxzzzzxxxuuuuuuyyyyyyyyyyuuuxxuuuxxxxxxxxxxuuuuyyyyyyy|<br></br>
                  -uuuuuuuuuuuuuyyyyuuxzzzzzxxxuuuyyyyyyyyyyyyyuxxxxuuzzzxuuuuuuuuuyyyyyyyyy|<br></br>
                  -uuuuuuuuuuuuuuuyyuuxzzzzzxxxxuuyyyyyyyyyyyyyuuuxxuxzxxuxxuuuuuyyyyyyyyyuy|<br></br>
                  -uyyyuuuuxxuuuuuuuuuuxzzzxxxxxxuuuuyyyyyyyyyyyuuxxuuuuuuxzxuuuuyyyyyyyuuuu|<br></br>
                  -uyyyuuuuuuuuuxuuyyyuxxxxxxxxxxuuuuuyyyuuuyyyyuuuuuxzzzzxxxuuuuyyyyyuuuuuu^<br></br>
-uyuuuuuuuuuuuuuuuyyuuuxxxzxzzxxuuuuuyyuuuyuuuxxuuxrrr}wxuuuuuuuuuuuuuuuuu|<br></br>
-uuuxuxxuuuuuuuuuuuuuuuxxzw}rzxxuuuuuyyuuuuxxxxuxzwrrrrzxuuuuuuuuuuuuuuuuu|<br></br>
-uuuuxxxuuuuuuuuuuxxxxxxxzwrrwwxxuuuuyyuuuuxxuuxwrv}wwzxuuuuuuuuuuuuuuuuux|<br></br>
                  -uuuuuuuuuuuuuuuuxxxxxuuuxxzwwwzxxuuuxxxxxxxxxxwrrwwzxxuuuuuuuuuuuuuxuxxzz|<br></br>
                  -uuyyyyyuuuuuuyyuuxxxuuuuuxzwwwwzzzzzzzzzzzzzzzwwwzxuuuuuuuuuuuuuuuxuxzwrr~<br></br>
                  -uuyyyuuuuuuuuyyuuuuuuuuuuuxzwwwwwzwwzzzzzzwwwwzxuuuuuuuuuuuuuuuuuuuuzwwwz|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuuuuxxxzzzzzzzzzzzzzzzwwzxuuuuuuuuuuuuuuuuuuuxzwwrw~<br></br>
                  -xuxxxxxxxxxuuuuuuuuuuuuuuuuxxzzwwzzzzzzzzzzzwwwxuuuuuuuuuuxxuuuuuuuuzwwww|<br></br>
                  -xxxxxxxxxxuuuuuuuuuuuuuuuxzzwwwwzzzwwwwzzzzzzwwzuuuuuuuuuuuxuuuuuuuuxzzzx|<br></br>
                  -xxxxxxxxxxuuuuuuuyyyyuuxzwwwwwwwwwwwwwwwwwwwwwwzzxuuuuuuuuuuuuuuuuuuuxzzx|<br></br>
                  `xxxxxxxuuuxuuuuuuuuuuuxwwwwwwwwwwwwwwwwwwwwwwwwwwzxuuuuuuuuuuuuuuuuuuxzxx|<br></br>
                  `zxxxxxxuuuuuuuuuuuxxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxxxuuuuuuuuuuuuuuxxx|<br></br>
                  `zxxxxxxuuuuuuuuuuxxxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxxuxxxxuuuuuuuuuuux|<br></br>
`xxxxxxxuuuuuuuuuuurwzwwwwwwwwwwwwwrr}}rwwwwwwwwwwwwwzwrzxzwzuuuuxxxxxxxxx|<br></br>
`xxxxxxxuuuuuuuuuuuzvv}rrwwwwwwwwwr}vvvvrwwwwwwwwrrrr}vwxzwzxuuuuuxxxxxxxx|<br></br>
-xxxxxuuuuuuuuuuxuuuurvvvvv}rwwwwwrv>++>?rwwwwwrvvvvvvwzwzzrwwwwxuuuuxxxxx|<br></br>
-xxxxxuuxzzxzzwzwxuuuxwvvv????v}r}??+^^^|?}}vv???vvvvzuuzrv?vvvv}xuuuuxxxx|<br></br>
-xxxxxuzr}}}v?vwxxuuxxuxrvvv?>???^^>^~~~~|+??>?vvvvwxuuuxz?vv??v}xuxxxxxxx|<br></br>
-xuxzxuwv??v}vvzuuuxzzzxuxw}vvv?^|>^|-``+>~+?vvvwzuuuuuuuurwrv?rzzxxxxxxuu|<br></br>
-xuxxxxwrv?v}wrxuuxzzrrzuuuuxzwv??>++~--+?~^vwxxuuuuuuuuuuuzwwrwwwxxxxxxxu|<br></br>
-xuxxxxwwr}wrwxuuxzr}}}zuuuuuuw>>??^|^~-~||||}uuuuuuuuuuuuuuxzwrr}rxxxxxuu|<br></br>
-xuxxxxxwrrr}wzxzv??????v}wr??^~>v>>+^|~~|>>~-^vwzwr}rrwzuyyuzrrr}rwxxxuuu|<br></br>
-uuuxxxxr}r}v}}rv??>>????>>+++|^^?v>>>^|+?|^+`-~|^+??????vwuuxrrrrrrrxuuuu|<br></br>
-uuuxxxwrrr}}?>>>>>>>>>>>++++^-^>~??>^^+>+`+>`-||^++++++>>?v}}}r}}}}vrzxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}vvvvvvvvv}}}}v}}}}}rrrrrrr}}}}}}}}}}}}}}}}}}}}}vvv}}}}}}}}}rrrrrr~<br></br>
                  -xxuuuuuxzzwwwzzxxxxxxzzzxxxxxxuuuuuuuuuuuxxxxxxzzxxxxxxzzzzzzzzzzxxuuuuuu|<br></br>
                  `xxxxxxxxzzwwzxuuuuuxxzzzzxxxxxxxxxxxuuuuuuuuxxzwwzxxxzzzzzzzzzzxxuuuuuuuu|<br></br>
                  -xxxxxxxxzwwwzuyyyyuxzzzzzxxxzzxxxxxuuuuuyyuuxwrrrzuuxxxxxxuuxxxuuuuuuuuuu|<br></br>
-uxxxxzzzwwwwxuyyyuuxzzzzzxxxxxxuuuuuuuuuyyyuw}rrrxuzwzxuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuxzzwrwzxuuuuuuxzzzzzxxxxxxuuuuuuuuuuyyuzrwwrzzwzxuxxuuuuuuyuuuuuuuuu|<br></br>
                  -uyyyuxzwwrwwwwwzzxuxzzzzxxxzzzzxxxxuuuuuuuyyuxzzzxxuuuuxzxxxxuuyyyyuuuxxu|<br></br>
-uyyyuxzwwzzzr}rzxuuxzzzxxxxzzzzzxxxxuxxxxuyyuuuxuuxxzzzzzzzzxxxuuuuuuxxxu|<br></br>
                  -uyuuuxzzzxuuxzzzxuuuuuxxxxxxzzzzxxxxuxxxxxxzwzzxuzwrrrrzzzzzxxxxuuuuxxxxx|<br></br>
-uuuxzzwzzxxxxxxzzzzzzzzxxxxzzzzzzzxxxuxxxzrv}zxuxwrr}v}wxzzzxxxxxxxxxxxxx|<br></br>
-xuuxxxzzzzzzwwzzr}}}}rwzzzzzzzzzzzxxxxxzwwrwxuxwrrr}}rwzzzzzxxxxxxxxxxxuz|<br></br>
-uuuuuuuuuxxzxxuz}vvv}rzzzzzzzzzzzzzzzzzwwwwzxxz}vrrrwzzzzzxxxxxxuxxxxxxzw~<br></br>
-uyyuuuuuuuuxuuuxwr}}rwzzzzzzwwwwwwwwwwwwrrrwzzwwrwwzxxxxxxxxuuuuxxxxzwrrr~<br></br>
                  -uyyuuuuuuxxuuuuxzwwzzzzzzwwwwwwwwwwwwwwwrrwrwwzzxxuxxxxxxxxxuuuuuxxxzrrrr~<br></br>
                  -uuuuuuuxxxxxxxxzzzzzzzzzwwwwzzwzwwwwwwwwrwwwwwwwzuuuxxxxxxzzxuuuuxxxwrrrr~<br></br>
                  -xxxxxxzzzzzzzzzzzzzzzzzzxxzzzwwwwwwwwwwwwwwwwwwwzxxxxxxuuxzzxxxuuxxxzrrrr~<br></br>
                  -zzzzzzzzzzzzxxxxxxxxxuzxzwwwwwwwwwrwwwwwwwwwwwwwzxxxxxuuuxzzzxxxuxxxxrrrr~<br></br>
                  -zzxxxxxxxxxxxuuuuuuuuxzzrwwwwwwwwwwrwwwwwwwwwwwwwzzxuuuuuxxxuuxxuxxxxwrrr~<br></br>
                  `zxxxxxuuuxxuuuuuuuuuuxzwwwwwwwwwwwwrwwwwwwwwwwwwwzxxuuuuuuuuuuuxuxxxxzrrr~<br></br>
                  `xxxxxxxxxxuuuuuuuuuuuxzwwwwwwwwwwwrrrwwwwwwwwwwwwzxuuuuuuuuuuuuuxxxxxxzzw~<br></br>
                  `zxxxzzzxuuuuuuuuuuuuuuzwrwwwwwwwwwwwwwwwwwwwwwwrrwzuuuuuuuxxxuuuxxxxxxxxz~<br></br>
                  `xxxxxzzxuuuuuxxxxuuuuuzrrrwwwwwwwwwwwwwwwwwwwwwrrwzuuuuxzwwzxuuuuxxxxxxxx~<br></br>
`xxxxxxxxuuuxxwwwzzxxxxzrrrrrwwwwwwr}}}}rwwwwwrrr}rxxxzwr}v}zuuuuuxxxxxxxx~<br></br>
-xxxxxuuuuuuxzr}}wwwzzxuxwrrrrrrrr}v>>>+?rrrrrrrrwuxxz}?vvvv}}wwxuuuuxxxww~<br></br>
-xxxxxuuxzzw}rvvvrwzxuuuuuz}rrrrr}?>^^|~~?}rrr}}xuuuuuxw}???????vxuuuuxxxx|<br></br>
-xxxxxuzrvv??>?vwxxxxxuuuuuuw}}}}+|?>^|||`?r}}wuuuuuuuuux}>???+>?zxuxxxxxx|<br></br>
-xuxzxuw?>????>}uuzwr}}rwxuuuuxwv>>??+|~||^vwxuuuuxzzzxxuur}v??vv}zwxxxxuu|<br></br>
-xuxzxx}v???vv?ww}vvvvvv}}rzzwr}?????>^^++^+?vrzwr}}}}}}wxxrvvvvvvrwzzzxxu|<br></br>
-xuxxzzvvv?vv?vr}vv????vv}}}vv?>|vv???>^+>+`->vvvvv}}}}}}}rr}vvvv?vxzzwwxu|<br></br>
-xuxxwwwvvvv?vwwv+++++++>>??>+++~?vv??>+>>|-~-~>?v???????vrzw}vvv??vzzwrxu|<br></br>
-uuuwwwwv??v???>^||^++++++++++++^^v?vv??>+~~^|||||^++++++^>wzrvvvv???wzwzx|<br></br>
-uuxrrw}?????^`-~||^+++++++>+++++|^>>??>||-++|~|^^+++++++|~|>>??????^?rwzz|<br></br>


            </div>,

            <div>

`}}}}}}}}vvvvvvvvv}}}}v}}}}}rrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrr-<br></br>
                  -xxuuuuuxzwwwwzzzxxxxxzzzxxxxxuuuuuuuuuuuuxxxxxxzxxxxxxxzzzzzzzzzzxxuuuuuu|<br></br>
                  `xxxxxxuxxzrwzxuuuuuxxzzzzxxxxxxxxxxxuuuuuuuxxxzzzzxzzzzzzzzzzzzxxxuuuuuuu|<br></br>
                  -xxxxzxxxzzrwzuyyyyuxxzzzzxxxzzxxxxxuuuuuyyuuxwrrrzuuzzzzzzxxzzzxuuuuuuuuu|<br></br>
-uxxxzzzzwwwwxuyyyuuxzzzzzxxxxxxxxxxuuuuuyyyuw}rrrxuzwwzzzzxxzzzxxuuuuuuuu|<br></br>
                  -uuuuxzzzwrwzxuuuyuuxzzzzzxxxxxxxxxxuuuuuyyyuwrwwrzzwwzxxzzzzzzzxxuuuuuuuu|<br></br>
                  -uyyyuxzwwrwwwwwzzxuxzzzzzxxxzzzxxxxuuuuuuuyyuxzwwzxxuuuxzzzzzzxxxuuuuuxxu|<br></br>
-uyyyuxzwwwww}vvvrxxzzzzzzzzzzzzzxxxxuxxxxuyyyuuxxxxxxxxxzzzzzzxxxuuuuxxxu|<br></br>
                  -uyyuuzzzzzzzrvvvrzzzxxxzzzzzzzzzzxxxxxxxxxuxzwwzzwwwrrrzzzzzzxxxxuuuuxxxx|<br></br>
-uuuxzzwzzzxxxxzzzzzxxxzzzzzzzzzzzzxxxuxxxzr}v}wxw}r}}v}wxzzzzzxxxxxxxxxxx|<br></br>
-uuxzzzzzzzzzwwzxw}}}}rwzzzzzzzzzzzzxxxxzwwrrwuuzr}r}}rwzzzzzxxxxuxxxxxxuz|<br></br>
-uuxxzzzzzzzzzxuw}vvv}rzzzzzzzzzzzzxxuuuzwrwzxuz}v}}}rwzzzzzzxxxxxxxxxxxzw~<br></br>
-uuxzzzzzzzzzzxxrvv}}rwzzzzzzzzzzzxxuuuuxwrrwzxzrrwwwzxxxxzzzxxxxxxxxxwrrr~<br></br>
                  -xxzzzzzzzzzzzzzwrrwzzzzzzzzzzzzwwxuuuuuxwrrrwxxxxxxxxxxxxxzzxxuuxxxxzrrrr~<br></br>
                  -zzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwwxuuuuuxwrrrwwzxxuuxxxxxxxzzxxxuxxxxwrrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwwwuuuuuzwwwwwwwzxuuxxxxxxxzzxxxxuxxxzrrrr~<br></br>
                  -zzzzzzzzzzzzzxxxxxxxxuxxxzwwrrwwrwzxxxzwrrwrrrwzzuxxxxuxxzzzxxxxuxxxzrrrr~<br></br>
-zzzxxxxxxxzzxuuuuuuuuuuuzwrwrwwwrwwwzw}rwrrrrrrwzuuuuuuuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxxxxxxzxxuuuuuuuuuuuwwrwwwrr}}r}}v}rrrwwwwrwzxuuuuuuuuuuuuuxuxxxxzrrr~<br></br>
                  `xxxxxxxzzzxxxuuuuuuuuxxxzwrwwrrrrrrrrwrrwwrwwwrwzxzxuuuuuuuuuuuuxxxxxxzzw~<br></br>
`zxxxxzzxxxuuuuuuuxww}}rwww}rwwwwwwwwwwwwwwwwrr}rwwrrwxxuuuuuuuuuxxxxxxxxz~<br></br>
`xxxxzzxxuuuuuxzwwrrrv?vvvrr}rrwwwr}vvvv}wwrrrrrr}}v?vwr}vv}wxxuuuuxxxxxxx~<br></br>
`xxxxxxxxxxzz}????vvvv?vv}vvv}}}rr??>>+^+}rrr}vvvv???vv???>?}ww}wzzzxxxxxx|<br></br>
`xxxxuzwzzzwvv????>>???}wzxzwwr}}v>?+||~-?vv}r}wzxw}v?>??>>?v}r}wrwzrwxxxx|<br></br>
-xxxuzwwwrr}vv????+???????vwuuzw}?>???+^~~vrwzuxrvv??vv?v?>>?vvv}rrrvrzxxx|<br></br>
-xxxzrr}vv??????v???>>??????vww}????>>^^^|^?v}v????????>++????v?vvvvv}}xxx|<br></br>
-xuxrvvv???????vwr>>+++>>>??????>?v???+^++-^>>>????>>>>>?}w}???????>?vrzuu|<br></br>
-xuxzzr?>??????vv|-^+++++++>>>++|?v???++>^--~^+>++++++++^>}}v??????>?rzxuu|<br></br>
-xuxxzw}v????>>~``|++++>++++++++^+vv????>|-^^^^^^++>++++^---^?>?>??}wzwrxu|<br></br>
-xuxxwrww}??>>?>-`-~^++++++++++++|????v>^~|+++++++++++++^~`-??>>??}wwzw}zu|<br></br>
-xuurrrrrvvvv?v?-..-|^^^+++++++++^^????>~-|+++++^^^++^^^|`.|}w}v}}vvrzwrzu^<br></br>
-uuxr}rrvvvvvvvv?^.`~^^^^^++++++++|^???+`|^^+++^^^^^^^^^~`+vwww}vvvv?}rrwx|<br></br>


            </div>,

            <div>

`}}}}}}}}}vvvvvvvv}}}}v}}}}}rrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrr}v-<br></br>
                  -xxxxxxxzzwwwwwzzzxxxxzzzxxxxxuuuuuuuuuuuuxxxxxxzxxxxxxxxxxzzzzzzzzxuuuuux|<br></br>
                  `xxzzzzzzwrrrzxuuuuxxxzzzzxxxxxxxxxxxuuuuuuxxxxzzzzxzzzzzzzzzzzzzxxuuuuuuu|<br></br>
                  -xxzzzzzzwwrwzuyyyuuxzzzzzxxxzzxxxxxxuuuuuuuuxzrrrzuuzzzzzzzzzzzxxuuuuuuuu|<br></br>
-uxxxzzzzwrrwxuyyyuuxzzzzzxxxxxxxxxxuuuuuyyyuz}rrrxuzwwzzzzzzzzzxxuuuuuuuu|<br></br>
                  -uuuuxzzzwrwzxuyyyuuxzzzzzxxxxxxxxxxuuuuuyyyuwrwwrzzwwzxzzzzzzzzxxuuuuuuuu|<br></br>
                  -uyuuuxzwwrwwzzzzxxuxzzzzzxxxzzzxxxxuuuuuuuyuuzzwwzxxuuuxzzzzzzxxxuuuuuxxu|<br></br>
-uyuyuxzwwwww}vv}rxxzzzzzzzzzzzzzxxxxuxxxxuyyyuuxxxxuxxxxzzzzzzxxxuuuuxxxu|<br></br>
-uyyuuzzzzzzz}vvvrzzzxxxzzzzzzzzzzxxxxxxxxuuxzzzzzwwwwrwzzzzzzzxxxuuuuxzxx|<br></br>
-uuuxxzwzzzxuxxzzzzzxxxzzzzzzzzzzzzxxxxxxxzw}v}rxw}}}}v}wxzzzzzxxxxxxxxxxx|<br></br>
-uuxzzzzzzzzzwzzxw}}}}rwzzzzzzzzzzzzxxxzzzwrrwxuzr}r}}}rzzzzzzxxxuxxxxxxuz|<br></br>
-uuxxzzzzzzzzzzxz}vvv}rzzzzzzzzzzzzzxxxxzwwwzxuzrvr}}rwzzzzzzxxxxxxxxxxxzw~<br></br>
-uxxzzzzzzzzzzxxrvvv}rwzzzzzzzzzzzzzzxxxzwrrwzxzr}rwwzxxxxzzzxxxxxxxxxwwrr~<br></br>
                  -xxzzzzzzzzzzzzzwrrwwzzzzzzzzzzzzzwzxxxxzwrwwwxxxzxxxxxxxxxzzxxuuxxxxzrrrr~<br></br>
                  -zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwwzzzzzwwwwzxxxxxuuxxxxxxxzzxxxuxxxxwrrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzwwzzzwzzzxzwwzxuuuuxuxxxxxxxzzzxxxuuxxxwrrrr~<br></br>
-zzzzzzzzzzzzzzzxxxxxxxxxxxzzwwwzxzzwwwwzr}xzxzzuuxxxxxuxzzzzxxxxuxxxzrrrr~<br></br>
-zzzxxzzzzzzzxxuuuuuuuuuuuxwwr}rzwrzr??vrr}r}}rruuuuuuuuuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzzzxxxuuuuuuuuuuux}wr}}}??}v?>?vr}}}rwrxuuuuuuuuuuuuuxxxuxxxxzrrr~<br></br>
`xxxxxzzzzzxxxuuuuuuuxxzxuxvrr}r}}}rrrr}}rrrrrr}zxxwxuuuuuuuuuuuuxxxxxxzzw~<br></br>
`zxxxxzzxxxuuuuuuuxwwv?vvzuv}rrwwwwwwrrrwwwwwr}vzz}vvvzzxuuuuuuuuxxxxxxxxz~<br></br>
`xxxxzzzxuuuuuxxww}}}?>??vxzvv}rrr}v?>>^>rrr}}vrw}v?>?v}v}}wwzxuxuuxxxxxxx~<br></br>
`xxxxxxzzzw}wwr}}vvvvv??>?vvvvvv}}??>+^|`>vvvv?????v??}vvv?vvrrv}rrrzxxxxx|<br></br>
`xxxxxrv}}}v}}}}vv?v}v?v}wzxzzw}}v?>>|^^-+?vrwwwzzw}v?????vv}r}v}}}}?}xxxx|<br></br>
-xxxu}v?vvvvvvv????v?>>>>>?ruuuuwvv???>^||vzzxxz}??????}v????}}vvvvv?vwuxx|<br></br>
-xxxr??????vv??vv?+>+>>>>>>>?}?>^?v?>?>+^^`|>??+>>>>>>++++vv??vv???????zux|<br></br>
-xuxv?????????v}}?|+++>>++>>++>+^?v??>++++``-|^>>>>>+++>|>}}v???????>>?wuu|<br></br>
-uuxzz}?>????vvv?^`^++++++++++++^>v??v>>>+-||^++++++++++~|vvv??v???>?}xxxu|<br></br>
-xuxxxxzv+|+>??>+~-^+++>>>+++>+++^v?>???>|-^^+++++++++++~`+^>?>++>+vxxxxxu|<br></br>
-xuxxxxzzr?++?>^|-~~^++++++++>+++|??v?v?^~|++++++^++++^||`-`^?>>>vwxxxxxxu|<br></br>
-uuuxxzwwww}}}v>````~|||||^++++++^^???v>||^^++++^^^^^^||-`.`vrwzzzzzzzxuuu^<br></br>
-uuuxzwwwwr}}vvrv+``~~~~~~|^++++++|^>>?~|+^^+++^||||||||-->vzzzzwwwwrwzxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}vvvvvvvvv}}}}}}}}}rrrr}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr}v-<br></br>
                  -xxxxxxxzzwwwwwwwzxxxxzzzzxxxuuuuuuxuuuuuxxxxxxxzxxxxxxxxxxxzzzzzzzxuuuuux|<br></br>
                  -xxzzzzzzwwrrzxxxxxxxxzzzzxxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzxxuuuuuuu|<br></br>
                  -xxzzzzzzwwrwzuuuuuuxzzzzzxxxzxxxxxxxuuuuuuuuuzwwwzxxzzzzzzzzzzxxxuuuuuuuu|<br></br>
-xxxzzzzzwrrwxuyyyyuxzzzzzxxxxxxxxxxuuuuuyyyuzr}r}zuxwwzzzzzzzzxxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzzzzzxxxxxxxxxxuuuuuyyyuw}rrrxxwrwxzzzzzzzxxxuuuuuuuu|<br></br>
                  -uyuuuxzwrrwwzxxxxuuxzzzzzxxxzzzxxxxuxxxzxuuuxzwwwzzzxuuxzzzzzzxxxuuuuuxxu|<br></br>
-uyuyuxzwwwww}vv}rxuzzzzzzzzzzzzxxzzxxxzzzxxuyuxzzxuuuuxxzzzzzzxxxuuuuuxxu|<br></br>
-uyyuuzzzzzzw}vvv}zxwzxzzzzzzzzzzzwzwwzzwwzzxxzzzzwwzwwwzzzzzzzxxxuuuuxxxx|<br></br>
-uyuuxzzzzzxxxzwrwzzxuuxzzzzzzzzzwwrvrrrr}ww}}v}zw}}}}}rwxzzzzzxxxxxxxxxxx|<br></br>
-uuxzzzzzzzzxzzxxwrrrrwzzzzzzzzzzrv?vv}vvv}}vrzxzr}r}}}rzzzzzzxxxuxxxxxxxz~<br></br>
-uuxxzzzzzzzwwzxz}vvvvrzzzzzzzzw}????>??v???rxuxr}r}}rrzzzzzzxxxxxxxxxxxzw~<br></br>
-uuxzzzzzzzzzxxxrvvvv}wzzzzzzzw????v???vvv?vrr}w}}rrwzxxxzzzxxxxxxxxxxzwrr~<br></br>
-xxzzzzzzzzzzzzzr}}rwwzzzzzzzrv??>vvvr}>>vv}r}?}wzzxxxxxxxxzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzzzzzzzzzzzzwv??|~+>?vv^-??v}vvrzxxzxxxxxxxzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzw}??>?>|>??v>>?>vvwzzxxxxxxxxxxzzxxxxuxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzzzxxxxxxxxzz}>+v}??????+>?v>v}rxxzxuxxuxzzzzxxxxuxxxzrrrr~<br></br>
`zzzzxzzzzzzzxxxuuuuuuuuuuxxzv>|?vv}v|-?^?vv^>?vxzrzxxuuxxxzzxxxxuxxxzrrrr~<br></br>
`zzxxxzzzzzzxxxuuuuuuuuuuuzv}wv>+>?vv^|>>++^+?vw}v}wwuuuuuuuuuxxxuxxxxwrrr~<br></br>
`zxxxxzzzzzxxxuuuuuuuuuuuur?vw}vv??vvvvvvvvvvvv?>>}wxuuuuuuuuuuuxxxxxxxwzw~<br></br>
`zxxxxzzxxxxuuuuuuuuuuuuuxvvz}r}rwwwwwwwwwwwrrv>^?wxuuuuuuuuuuuuxxxxxxxxxz~<br></br>
`zxxxzzzxuuuuuuuuuuuuuuuuxw?vrwwwwwww}vvvwwwwwwv>wuuuuuuuuxzzxuuxxxxxxxxxx~<br></br>
`xxxxzzxxuuuuuuuuuuuuuuuuxzvvrwwwwwwv>+^~vwwwrv?wuuuuuuuuzr}zuuuxxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuuuuuuuuuuuuwvvv}}}}?>v?|~~>vvv?}uuuuuuuuxzwzuuuuuuxxxxxxxx|<br></br>
-xxxxuuuuuuuuuxuuuuuuuuuuuyuzvv?vvv^?v+||~>v?vwuuuuuuuuuuxxxxuuuuuuuuuxxxx|<br></br>
-xxxxxuuuuuuuxzuuuuuuuuuuuuyyuzv?>???v??>^^?wuuuuuuuuxuuuuzrrzxuuuuuuuxxxx|<br></br>
-xuxxxuuuuuxwr}xzrwz}v??????vwwv>|?v??>>||-~>}zx}v?????vrzw}rzxuuuuxxxxxxx|<br></br>
-uuxzxxxuxw??vv??+++^+++++++^++++^vv?v>>++|`-~^^^++++++^~^??vzuuuuuxxxxxxu|<br></br>
-uuxzxxxrv?+^>??v?>+^++++++++++++^?v>?vv+>+-|^^^+++++++^^>?>??v}rr}zxxxxxu|<br></br>
-uuxxxxw}}?^^>??vvv>++++++++++++>^?v??v???+|+++++++++++^^>>>????vv}vzxxxxu|<br></br>
-uuuxzwr}?+++++?vvv>|^+++++++++++^>vvvvvvv+^+++++++++++++?>+?vvvvv?rrzxuuu^<br></br>
-uuuxwr}?>?>+++??v?~-|^|^^++++++++^?>?v???|^++++++++++++^??>???vv?>?v}zxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}vvvvvvvvv}}}}}}}}}rrrr}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrr}v-<br></br>
                  -xxxxxxxzzwwwwwwwzxxxxzzzxxxxuuuuuuxxuuuuxxxxxxxzxxxxxxxxxxxzzzzzzzxuuuuxz|<br></br>
                  -xxzzzzzzwwrrwzxxxxxxxzzzzxxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzxxuuuuuxx|<br></br>
                  `xxzzzzzzwwrwzuuuuuuxzzzzzxxxxxxxxxxxuuuuuuuuxzwwwzxxzzzzzzzzzzzxxuuuuuuuu|<br></br>
-xxxzzzzzwrrwxuyyyuuxzzzzzxxxxxxxxxxxuuuuyyyuzr}r}zuxwwzzzzzzzzxxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzzzzzxxxxxxxxxxxuuuuuyyuw}rrrxxwrwxzzzzzzzxxxuuuuuuuu|<br></br>
-uyuuuxzwwrwwzxxuuuuxzzzzzxxxzzxxxxxzwww}}wxuzwwwwzzzxuuxzzzzzzxxxuuuuuuxu|<br></br>
-uyuuuxzwwwww}}}}rxuzzzzzzzzzzzzzxwvv}}vv}}vzuuxzxxuuuuxxzzzzzzxxxuuuuuxxu|<br></br>
-uyuyuzzzwwzwvvvv}zxwzzzzzzzzzzzz}vvv?vvvvvv}wrzzzwwzwwwzzzzzzzxxxuuuuxxxx|<br></br>
-uyuuxzzzzzzxzwrrrzzxuuxzzzzzzzwv?vv?vvvv?vvr}?vzz}}}}}rwxzzzzzxxxxxxxxxxx|<br></br>
-uuxxzzzzzzzxxxxxzrrwwwzzzzzzzwvv?>>>?r}?+vvvvrzzr}r}}}rzzzzzzxxxuxxxxxxxz~<br></br>
-uuxxzzzzzzzwwzxz}vvvvrzzzzzzzrv?+~++-++?>+^?wuuw}rrrrwzzzzzzxxxxxxxxxxxzw~<br></br>
-uuxzzzzzzzzzzuxwvvvv}wzzzzzzzv>?~?v?+>?}v+?}v?}vvrrwzxxzzzzxxxxxxxxxxzwrr~<br></br>
-xxzzzzzzzzzzzzzr}}rwwzzzzzzzv>^>>r}?r}+^?>?}v^>vv}zxxxxzxxzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzzzzzzzzzzzzw++?++?}rr}|.>v}v>^?rz}}uxxxxxxzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzzzzvvwzv?+>?vvv???>++??vz}vwxxxxxxzzxxxxuxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzzzxxxxxxxxr}xzv????++>>^>^|>???}}?rwwxxxxxzzxxxxuxxxzrrrr~<br></br>
`zzzzxzzzzzzzxxxuuuuuuuuuuz}zxw????????^`+????>?v?>vrwxxxxxzzxxxxuxxxzrrrr~<br></br>
`zzxxxzzzzzzxxxuuuuuuuuuuur>?rv??????^~`.`-?>>?}?+>>>zxxuuxxxxxxxuxxxxwrrr~<br></br>
`zxxxxxzzzzxxxuuuuuuuuuuuuv~+??????v?>|-~^|^+>?>+>>>ruuuuuuuuuuuxxxxxxxwww~<br></br>
`zxxxxzzxxxuuuuuuuuuuuuuuuv^rr}vvv}rrrrrrr}vv?>-^+?wuuuuuuuuuuuuxxxxxxxxxz~<br></br>
`zxxxzzzxuuuuuuuuuuuuuuuuuw+??}}wwwwwwrrwwwwwwr>?ruuuuuuuuxzzxuuxxxxxxxxxx~<br></br>
`xxxxzzxxuuuuuuuuuuuuuuuuuu}?}wwwwww}?>>+}wwwrvvuuuuuuuuuzrrzxuuxxxxxxxxxx|<br></br>
`xxxxxxxuuuuuuuuuuuuuuuuuuuur?v}}r}?>?>^|+?}v??zuuuuuuuuxzwzuuuuuxxxxxxxxx|<br></br>
-xxxxuuuuuuuuxxxuuuuuuuuuuyyyrv??vv^?v>^^~?v?vxuuuuuuuuuuxxxxuuuuuuuuuxxxx|<br></br>
-xxxuxuuuuuuurvzxuuuuuuuuyyyyyuwv???vvv?>^^vzuuuuuuuuxzxuxw}wxxuuuuuuuxxxx|<br></br>
-xuxxuuuuuuxw?+rxxuuxzrvvvvvvwz}?^?v??>>||-^?rxz}v??vv?vw}??vzxuuuuxxxxxxx|<br></br>
-uuxzxxxxx}??+^?wwv??+++++++^^+++^vv?v>>++-`~|^++++++++>>>?+?xuuuuuxxxxxxu|<br></br>
-uuxzxxxxz??++>>???>^|+++++++++++^?v>?v?+>||^^++++++++>+>??>v?}xxuuxxxxxxu|<br></br>
-uuxxxxxxx?+>++>vvvvv+^+++++++>+++?v??v???|+++>+++++++++^>>?v??vrrzxxxxxxu|<br></br>
-uuxxxxxxv??>++??vvvv|^+++++++++++>vvvvvv>|+++++++++++>^?v?>vvvv}v?xxxxuuu^<br></br>
-uuuxxxur?v??>>??vv?|`|||^^++++++++?>?v??|^+++++++++++^~?vv>v???v?>wzzzxxx|<br></br>


            </div>,

            <div>

`}}}}}}}}vvvvvvvvv}}}}}}}}}rrrr}}r}vvvv}vvv}v}}}}}}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxxxxxxzzwwwwwwwzxxxxzzzxxxxuuuuxzzzzzxzwwzwzxxzxxxxxxxxxxxzzzzzzzzzuuuxx|<br></br>
-xxzzzzzzwwrrwzzzxxxxxzzzzxxxxxxzzwwrrww}}}}}}wzzzzzzzzzzzzzzzzzzzxxuuuuxx|<br></br>
`xxzzzzzzwwrwzuuuuuuxxzzzzxxxxxwwzwvv????r}vrwrv}wr}wwzzzzzzzzzxxxuuuuuuuu|<br></br>
-xxzzzzzzwrrwxuyyyuuxzzzzzxxxzwwrrv?v??vvv}v}r}?vwwzxxxxxzzxzzxxxuuuuuuuuu|<br></br>
-uuxxxzzzwrrwxuyyyuuxzzzzzxzzwwwrv?}vvvvv?vr}vvvrwzzwrwzzzzzwwrzuuuuxuuuuu|<br></br>
-uuuuuzzwwrwwxuuuuuuxzzzzzzzwwwvv????}}}?>?>^?}rrwzzrrrwwwwwrr}zuuuxuuuxxu|<br></br>
-uyuuuxzwwwwwrrrrwxuxzzzzzzzwwv?v?>>>>?>??+~~^v}vwxxz}}rrr}}}}ruuxxuuuuxxu|<br></br>
-uyuyuxzzwwzwvvvv}zxwwzzzzwrwv???>>v?|??vv>>}v???vzzzvvvvvv}}rxxzzxuuuxxxx|<br></br>
-uyuuxzzzzzzxwrr}rzxxuuxzwwwv??>?+vw?>>>>^??}}???vzww}rrwzzzzzzzzxxxxxxxxx|<br></br>
-uuxxzzzzzzzxxxxzzwwwwwwzzz}??+>?+?}r}}?.~v}v????rwwwr}rwzzwwwzxxxxxxxxxxz~<br></br>
-uuxxzzzzzzzwwwzz}vvvvrzzzzv?+>v?>+>v}rr??}>|>v??wwv?ww}zxzr}}wuxuxxwwxxzw~<br></br>
-uuxzzzzzzzzzzxuwvvv}}wzzzr?+?ww}v>++?vvv???+????r????v?rzr}vrrzuxzrvrzzwr~<br></br>
-xxxzzzzzzzzzzxzrvv}rwwwwrr??rxrvvv?>>>>>+>^~++?v>+>?>>+?zw}}rwzzr}wzzrrrr~<br></br>
-zzzzzzzzzzzzwrr}v}rwwr}}rw?vrwzvvv??>~~~`~~||>+++-|^^~||?xzwxxxzwzxxwrrrr~<br></br>
`zzzzzzzzzzzr}}}}}rrrr}}rzr>?zwrrvv????>++>>>|~~`^|`----`-vzwwwzzxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzwrrwwwrrrzv^vuzrv>?}vvv+?x>|?v^||.+>~|^+?vrvrwwxxuxxxzrrrr~<br></br>
`zzzzzzzzzzzzzzzzzwr}v}wzz>`?rw}>|vw???`|>r?}v^~|?|^??>^?zxxzxxxxuxxxzrrrr~<br></br>
`zzzxxzzzzzzzzwwr}}vv}wuur+-`~>?>?r}vx?`-.~v~.>vvw^``~++?wxxzzuxxuxxxxwrrr~<br></br>
`zxxxxxzzzzzw}vvvvv}}zuuu}>-``^~?vrxz>``......>}z}+^>vwzxxxxxxuuxuxxxxzwww~<br></br>
`zxxxxzzzxxw}}rrrwwzxuuuuw?^|~?``|?z?~|^|-....~^^^>vzxuuuuuuuuuuxxxxxxxzxz~<br></br>
`zxxxzzzxxwrzxuuuuuuuuuuuuw?+~+??vvvvrrw}?>>>>+>vzxuuuuuuuxxxxuuxxxxxxxxxz~<br></br>
`xxxxzzxxzzuuuuuuuuuuuxxxxxuxvvwwwwwv^^^>v}r}?+wuuuuuuuuuxwrwxuuxxxxxxxxxx|<br></br>
`xxxxxxxzzuuuuuuzzuuuuxzzzxuyzvv}rrv+`..~>?++>ruuuuuuuzzxwrwxuuuuxxxxxxxxx|<br></br>
-xxxxxuxxuuuuuxzvrxuuuxxzzuyyyx}??v|-~....|>?ruuuuuuuuwv}}}}xuuuuuuuuxxxxx|<br></br>
-xxxuuuzxuuuxrv?+}xuuuuuuuyyyyyyzv?>^|-...^?vxuuuuuuuuuv?>>>rxuuuuuuuuxxxx|<br></br>
-xxxxxuxuuuur?>^+?wuxuuuuzrrrwxz}?>?|~~`...|?xxwrrwxuuuv??>?vzxuuuuxxuxxxx|<br></br>
-uuxxxxuuuuxwv?+>vzxzwrv>>+++^++++?v?+|`...-^>>++++>?zuz??vvvuuuuuuxxxxxxu|<br></br>
-uuxzxxxuuuuuwv??vw}?+^^++++++++++>v>>>|+--++++++++++>?v??vvvzuuxxxxxxxxxu|<br></br>
-uuxxxxxxxxuuwv??vvvvv?|++>>+++++++vv??v?^^++++++++++++???vvvvxuxxxxxxxxxu|<br></br>
-uuxxxxxxxxxz}v???vvvvv+|+++++++++^>?vvv>|++++++++++^>vvvv??vvrzuxxxxxxuuu^<br></br>
-uuuxxxxxxxzrvv??>?vv?>-~~|||++++^^|>???|^++++++||^^-~>????>???}xzzzzzzxxx|<br></br>


            </div>,

            <div>

`rr}}}rr}}}vvvvvv}}}}}}}}}rrrrrrrr?????????vv}rr}}}rrrrrrrr}}}}}}}}}rrrrr}-<br></br>
-xxxxxxxzzwrrrrrrwzzzzzzzxxxxuuuurvvvvv}?>vv?vxxzzxxxxxxxxxzzzzzzzzwwxxuxz|<br></br>
`zxxxzzzzwr}rwzzzzzzzzzzzzzxxxxx}vv?>vrr?>>~|~>wzzxzw}wzzzzzzzrwwzzzxuuuxz|<br></br>
`zzzzzzzzwr}rzxuuuuxxzzzzzzzzzzvvv?|~~^^~}?>vv+>?}?>>vwwzzzzwwrwzxxuuuuuuu|<br></br>
-xxzzzzzzwrrwzuyyyuuxzzzzzzxz}vv??^+?^~|?}}?vw}+>}?vvzwwr}}rwwxzxxuuxwuuuu|<br></br>
-uxxxzzzzwrrwxuyyyuxxzzzzzzzv>v>??^rr??}rvrwv??+vr}}v?vvvv?>???}yuyx}xuuuu|<br></br>
-uuuuxzzwwrrwxuuuuuuxzzzzzzv+??^>++v}r}v?>v?+-^>?vzw?>>>>>>>>>|vuuwvzuuxxx|<br></br>
-uyuuuxzwrrrwwwwwzxuxzzzzzvv>?>>???vvvvv?>++--+++?wwv+>>>>>>+^>uur?wzxxxxx|<br></br>
-uyuuuzzwwwwwvvvv}zxwwzzw}?>>?????vv?>vvv^>>??}?++vw}+^++++++>xu}^vzuuxzxx|<br></br>
-uyuuxzzzzzzzwr}}rzzzxxwvv???????v??|||-^-?rv?}r>>}rrvvvvvvvvr}v?}xuxxzzxx|<br></br>
-uuxzzzzzzzzxxxxzwww}}v}wrv?vvvv?|>?>vv^-|>v>v}}+?rw}vvv}r}vv}}wxuxxxxzzxz~<br></br>
-uuxzzzzzzzzwwwzzvv???vrr}r}?>>+~?+>vrr}??v-`?rv+?rwv}rr}rv?^~?uxxxzv?wxzz~<br></br>
-xuxzzzzzzzzwzxuw}v???}r}rv>+>v?vr?+>v}rrrrv>?v?>vwrrzr?}?^``>~}uxr>|?zzww~<br></br>
-xxzzzzzzzzzzzxzrv???vv??+>>^?wzrwv>+>?vv}}v?^|>>}v}w}>|?w?-~+?v}>+?wzrrrr~<br></br>
-xxzzzzzzzzzz}v??>>????+|^v||?rrwwwv??>?????+-^+`?^>>^^^|?xw?wxw??wxxwrrrr~<br></br>
-zzzwzzzzzzw?>???>???>~|>v?+vyz}}rwrvv?vv?>^>|~-~++^^^^^^|?v?}vvvzxxxwrrrr~<br></br>
`zzwwzzzzzwrrzzw?>?vv?>>>v?vrw}}v^?zrv}wwrv+~|v?r++^^^^^^||+>v?rxuxzzwrrrr~<br></br>
`zzzzzzzzzrzzzzzv???+^|>wv`-`~>v^`vyuv+?}rvv-ww+vr+^^^^^||vwxzxxxuxxxzr}rr~<br></br>
`zzzxxzzzzwr}}v?>^^^^|?xz+`~. vz}`?yuuwz?+vr.^rr?z+||~--^wxxxxxxxuxxxxw}rr~<br></br>
`zxxxxzzzzzzv+^||^^^^}uux+~`.|xuz+^yz}zx?vw-.`.|r?-??~+vzuuuuuuxxuxxxxzrrr~<br></br>
`zxxxzzzzzzv|^+>???}zuuuwvr+|>z+>>`xu?}xv?.   .->?v}rwxuuuuuuuuuuxxxxxxzxw~<br></br>
`zxxxzzzzz?>rzxxuuuuuuxwzuzv?^?v}?~~+??w>  .|>+|?wzzxuuuuuxzzxuuxxxxxxxxxz~<br></br>
`zxxxzzzz?vxuuuuuxuuuurzr}rxuvvrwwwr-   .~>?^``?wzxuuuzwuxr}wxuuxxxxxxxxxx~<br></br>
`zxxxxxxvruuuuuuxvxuuxv???}xyuv?v}r}~   +^` `+?xuuuuuuxvrr}rxuuuxxxxxxxxxx~<br></br>
`xxxxxurruuuuuzzv?xuuxv}??uyyyu}?>v~      .>?+wuuuuuuuuv+>??wuuuuxxxxxxxxx~<br></br>
`xxxxxurxuuuu}??++vuuuxxwxyyyyyyx}?~ .    -~ ?yuuuuuuuu}v>^?}xuuuuxxuxxxxx~<br></br>
`xxxxxxxuuuuu}>^^?vxxxuuyxwrrwxuwv>|        `rxwrwzxuuu}?v?v}zxuuuuxxxxxxx|<br></br>
-uuxxxxuxuuuuur??vrxxxxw?>+++++>++>v|``    .^>++++>?ruuuv?v?ruuuuuuxxxxxxu|<br></br>
`xuxzxxxxxuuuuw?vvvww?+^^++++++++++v^``|~.`^+++++++^+>?rvvvvvuuuxxxxxxxxxu|<br></br>
`xuxxxxxxxxuuuwvv?vv???>|++>>++++++??+|v>-^+++++++++^>??vvvv?wuuxxxxxxxxxu|<br></br>
`xuxxxxxxxxxxxr?v??vvvv?~^+++++++++^??v?|~+++++^^++|?vv?vv>vvvxuxxxxxxxuuu|<br></br>
`xuuxxxxxxxzwz}vv?+v?v?+-|||~^+++^^|+>>+-|^+++^|~~|`^>?>?>^???zxzzzzzzzxxx|<br></br>


            </div>,

            <div>

`}}}}}}}vvvv?????????v?????????>>??vvvvvvvv}}}vv?>>?vvvvvvvvvvvvvv???>+?vv-<br></br>
`xxxxzzzzww}?????????vvvv?v??v}}v???vv}rr}}??vvvvv??wrrzzwwzzzrv?}vvrzzxzw~<br></br>
`zxzzzzzzwr}vvvv??????}}}vvv?>>>???>?>>>>?v?^>v?vrv?vv?v?>?vrv??rrzxxxxxzr-<br></br>
`zxzxxzzzzr}}}r}vvv????vvv}vv?>++>>>>?>^||+?????vv??>????>v>>+^?v}zxxzzzzw~<br></br>
`xxrv}r}}}}}rwwzzwrrrv???????v??>+++++?vvv??v}rwrv?????^+?v>?>++??>??}rrwz~<br></br>
`xxw}vv????vvv?????vr}v?>+>?>????>+++vrzrrrwrrr}v}}???>>?????vv>?v?++vrwww~<br></br>
`xuuxwr}}}vv???v?vvv????????>>>>>+^>vvv}}}}}vvvvwwr}???????>^^^>}wxzwwrv?}-<br></br>
`xxwwzzr}}v??v}rwwrv??????vvv??>>+>v?>vvvvvv?vv}rw}v????^^>>v??}}vr}v????>`<br></br>
`xuxzrvv????}wwr}vvvvvvvvvvvvvrv>?v?^>??v??vv>vvv>?>>>++||?}}}?>>>?>>>>>>>`<br></br>
`xw}vvvvvvvw}v}r}??v???v?vvvrwzr^+?>|??vvv???>^>?>^^???>>++vvv?>>>>>>>>>>+.<br></br>
-zw}vvvvr}vwwrwrvv???v?vwzzwwz}}^^+~~+>???>^^?+->?-`?rr>>>+v?v?>>>+++++^+?-<br></br>
`r}v}r}}vvvvvvvvvv}}v???vxwzrrvv+^+^^--|||^-.>}?v}^?vrr?>??v?v+++++^^+>vv}-<br></br>
`}wz}}vv?vrr>vrw}v}>^^^||vzwwvvv+++++>-```-~++}wr??vrr}???v?vvv???>?v}}vvv-<br></br>
`}v}vvrr}}vvvvrrzw>+??>?wzwww}vv>^+++?v??v^+?vvrv?vr}}????v?}}?v}}rww}}v??-<br></br>
-}vvv??v}}}}}vvrrwxv>+?}xxrrwrwv?+++^>vrrrv??^~~?rr}}?>??r?vrr>??vrwwwvvvv-<br></br>
`v}rvvvvvvvvv}v}}}}zr^`>vwzwrrrzv?>+^+?v}rrr}?+>}r}}v?>vv>?wrrvzxzwrrr}}vv`<br></br>
`vvvvwwrv?}}rrvv}vvv}v++v}rr}r}rzv??>+>?v}rr}vv??vvv??v?+?wzr}?}>^~~|?>?}r-<br></br>
`}v?vr}rwwrrwwrvrwwrr}vvvvvvvvv}}zv???>>??????}v?v???v?+?wzww??^+>^>vv+~->~<br></br>
`vvv}}}v?vrrrwwrrrwwzr|`>????vvvvv}v?>>?>>>?v????>v?v>>?zzwrv?rv}vv?vv?v+..<br></br>
`v}vvwrrrzzzzzzwwwzwzwvv}v?v???vvvv}v>~||||^++^~|^>>||>rr}}v?xuuwzxxwrrv|. <br></br>
`wwvvv}wwwwwwwwwwr}}}v}}v?v?????????>~-~~~~||^^^++^^++>>^|>^vuuuzuuxzuuux?.<br></br>
`}}}vrr}vv}}rrrrrv??||???vr}v}r}v~~>>>???>>||~|~----~~~~~~||vuxrxyywxyuwuu~<br></br>
`wwwwv^^^^?vwwwwzr}wv^++>zxwrzvr}`}v??vv}v?>>^|~^>|`~|^^^.`~zxz}xzwrxxuruu|<br></br>
`zxxz>|+?rxuuuuuuvvur^+++rxwwurv|`zx>+>??>^|~~...|?^-~vv|.>?}}rv}r}vwz}}zz~<br></br>
-xxwv>vwxuuuuxw}v^vuz>++vxuuuyyr|.^zw>}v^~>?>`.`~`~v}>?`.`+?vvvv?}}vr}?rwz~<br></br>
-xxwvwxxuuuuuz?^+?vxu?^vuuyyyyyywv|`?}w}???~... .. .+-..~^>?}zrvv}wzzwwzxu|<br></br>
-xwrxuuxuuuuuxv>>vvxuvvyyuzrrwxuwv+` -?v>`. ..       .^?+v??}wzzzxxxxxuuux~<br></br>
`xzxxxuuuuuuuuxv?vvzxzxx}>+++++>+^`. ...              +w?vv?rzzxxxxxxxxxxx~<br></br>
-xuxzxxxuuuuuuu}vvv}wr?>^++++++++|.                  .vv?vvvrxxxxxxxxxxxxz~<br></br>
-xuxxxxxxxuuuuurvvvvv??>|++>>>+>^.                  .^+>??v?vzzzzzzzxxzzzz~<br></br>
-xuxxxxxxxxxxxur?v??vvvv+|+^^+>>~                  .^>|>>^?vvwzzzzxzxxxzzx~<br></br>
-xuxxxxxxxxzzzzwvv?>????-`~~~|++`                  .-|~-~^>??wwwzzzzzzzzzz~<br></br>


            </div>,

            <div>

`}}}}}}vvvvvvvv???>??vvv????????>>???>??????????~^>?}v>v???vv?~|v}}}}}}}vv-<br></br>
`xxxxzzr}}vv???vv}rvvvvvv}}}vrr}v>^>??vvv?vv??v???v?v}vv?vv???+?vxxzzxxxwr-<br></br>
`zxzzr}}}vv?>>+^>>?vvv?vv}}vvv?vvv?^|^^>?????+>???v?>?|?>???wwvv?vvv???>?>`<br></br>
`zxzzxxxzz}r}}rv?>>???????vv?????vv>++^~^>????>??vv?>^??>???v?vv}r}?^~|^>^`<br></br>
`xxzwrr}rv}zz}zxwv?vv?????>??+^?>>??++>vwwvvv?vvv}}wv>?+?>??vv?vv?+~``~|+?-<br></br>
`wxxzwwr}v}zzr?vvr}?vvvv??????>|>>+++?}rrzrwwrrrwwr}v}?>?^??v?++>vv??>?>?>`<br></br>
`rrzzzwwwwv}}}}zzzr}v>+?vvvv?vv?>+^+vvvvv}}}ww}vvvv}wwvv>^>??>+vzxxuxuw??+.<br></br>
`}}v}r}vvvv?vv}?}wrrrvv?>???v}}vv?+v???vvvvvvvvvvvrwzxw>>>???>vuzrxzrvv>?>`<br></br>
`vv}}}rvvv?>?vvwvvv}r}vrrr?vwrz}>>??^??vvvvvvv?}v}}wzwv++|>???}}v????>>>>>`<br></br>
`??vvwxzvvvv}wwzzvrw}}ruwzzwxvz>||+|??}???v??v??}}r?+|~^^??v?>>>>>>>>>>>>>.<br></br>
-zrrzwwzxzwrvwwwzx?^^^|}urxww?z>^^|-+>r?vvvvv??>>>+~|~-+v}??^>>>>>>>+++++^.<br></br>
-xxr}rwwwzzxvwwwwxz+^^^^zrzrv?w>+^++-->?v?v>++?^-|+>^-+rrr?>|>>>>++++++^~^.<br></br>
`rwwzw}rw}rwvwwwwwxx>^^|?zrwv?r>++++^-||+^^|-`?v|~?w>>vrrrr>++++++++^~|>vr-<br></br>
`vvv}rwwrrrwvrwwwwwxx>vrrrwww?w?^+++^>>||``-~|+wwwz}>vrrrr}>?^^+^|~^>}zz}v-<br></br>
`}vvvvv}rrrwv}wwwwwwyv~vxwwrwvvr^++++vvvv>^|+rvvzz}?v}rrrr?+?v+^>?ruyyw}vv-<br></br>
`rrwr}}v?vrzzwwrrrrwyv.?ru}wwr?}?^++^?v}rrrv??vv}??vrrrrr?~>?}xuyyyuz}vv?v-<br></br>
`wwwz}rwrvvvv}wzzzwzu?.+vxurzwv?r?^+++?}}rrr}?~.~?rrrrrv?^?vv}}wrrrrwwxxxz~<br></br>
`r}v}}}}rr}}vv?vrwxuxxzrv>???}v??vv>+^^?vrrrr}v?vrrr}}v+^vv?rr?rwrwwzwwrr}-<br></br>
`wr}vvvvv}wzzzwwr}}rzxxxr+^|^^++++>?>^^^??v}v?rrvvvvv?^^}v?vwv?}v??>|~--~?`<br></br>
`zzw}r}}vvvvvv}}rrrrzzzz?^^^^+++++^^^^^^^^^^+?rv?+?>^^+}v?vw}?vv|^vv?+>>++.<br></br>
`rrr}rwwwwwwr}}}vv+>????~```-~||^^^^++++^^^|^+??>?|>vvwv>?}w}rxuux}vvvvv}v-<br></br>
`zzzrrv>?vv}rrrw}^~>?vvv?>?>^|-````-~~|^++++++++++^|+>>^?}zvvyyyyyuxxux}v}-<br></br>
`zxzwr+||^>vrwrzxr?v}}rrzuyyyxvvv+?+~-``--~~||^^^^++++^|~|++xyyyu}uyyyyyzz~<br></br>
`zxww+^>vrzxxuuxz}?uxxuuuuuuyyrvv`+wrv>^|~~`-```--~||^++^^|?xzxyzryyyyxxyy|<br></br>
-xww>?}zzxxuuxv??>?uuuuuuuuyyyy}`.>v}wrvvvvv?>>+~``-````-`|zxxww}uyyyu}uuy^<br></br>
-zzw}zzxuuuuuu?+^??wuuuuuuyyyyyr ~w?^?}w}vv??>+`~~ `>?>|.-?r}rw?wxxyurxyyy|<br></br>
-rzwzxuuuuuuuuwv?vvxuuuuuuzrrwxu+ >v|?vv^|^>>^. -~` .+}?^v?vvvvvzzzwrwuuyz`<br></br>
`rzxxxxuuuuuuuuw?vvwxxuuw?+++++>>~.^?v??^^?+`  . .~|-``|~?v?vv?vzzwvvzzxz?-<br></br>
`xuxzxxxuuuuuuuxvvvvrz}?^++++++++^. `>??>~.    .    `|^|-|??vvv?vvv?v}}rvv~<br></br>
-xuxxxxxxxuuuuuu?vvvvv?>|++>>>+++~    `.          .    .`|???v??vvv?vvvv?}~<br></br>
-xuxxxxxxxxxxxxuvvv>vv?v>~+^^+>>^.                       -vv??vvvv}vvvvv?}-<br></br>
-xuxxxxxxxxzzzzz}vv>>???~`~~~|^^`                        |>>w}?v}zxzr}}}vv`<br></br>


            </div>,

            <div>

`}}}}}v???vvvv?>>>?????????????v?>?>+>????>>??>??>+^??vv?>???>??v>>v}}}}}v-<br></br>
`zxxzzxzzrvrww}}v????vvv?v}vv}vvvv???^^>???>+>+^????v?}}???v??wzrv?}wrvv}}~<br></br>
`}zw}}}rrw}wwzvv}rv????vv?>vv?vv??v??>^|||+>>^|~+???v?vv?>????rvvvvrr}?vzr-<br></br>
`w}zzzzw}vvwwz}vvvw}?vv??}?^??????+??>++^>??v?+^>?v??>???+?>>?vvvvxxur?}r?`<br></br>
`}w}zzzzzwvrrwwvvvvwrv}}??vv>>?>+?>++++?rxwvvvvvvvvv}wv?++>+^?vvvzwrv?????-<br></br>
`vrz}rzzzwrvvvrvv?v}rwvvv???v????+>>+?vv}rzrww}wrrwwrr}v>++^>vv?vvvv???>>?`<br></br>
`vv}vwrwzwwv?vv}}v??vvr}??v??vvvv?>+?v?vvv}}}wzrvvvvv}r}????????wzzzzzwrv+.<br></br>
`v}vrzxrwww}?vvwzrvv?v}wwv?vvwzz???v????vvvvvvvvvvv}wzzr}?v??+>wzxxzw}v???`<br></br>
`wwzzwwzwrrwvzwwzrwzwwxzwzrzx}w}||>?+???vvvvvv?vvvvrrzxu}>>+^+wxrrr}??>>>>`<br></br>
-uxwrwwwxzrwrwwwwxv++^vurzxzw?u>^^^|???vvvvv?vv?}}}}w}v?>^???>????>>>>>>>>`<br></br>
`wzzwrwwrwzzwrwwwzx>|^|wzwzzvvx++^^|++?vv?vv?v?>?}vv>^^~|?vv+>>>>>>>>>>>>+.<br></br>
`zzrrrzr}rwwvrwwwwxx>^|?urzw?vw++^^^-~??v?}vv???^^++~||~?r}?^>>>>>++++++^~.<br></br>
`zxxxzzwrrwrvwwwwwwxx>^|rzrz?}w^++^^~->+????|~>?~~~>?^~^rrr?^>>>>+++++^~~>`<br></br>
`vvwzxxxzwwrr}}rwwwzuw?wvxwzvvz+++++^`~~~?-^-->v?~^vrv??}rrv^+++++^|~~>vrr-<br></br>
`}vv}rzxxuxxzw}v}}rzyw.}u}xw}?x>^+++^|>|`~``|~+?z}rwr?v}rrr?-^^|~~^>vzuwr}`<br></br>
`vvvvvvrxxxxxxzw}vvv}> ^uxrz}?vw++++^?v}vv?|+?vv}zw}vv}rrrv}^^>?vwuyyyw}}r-<br></br>
`vvvvvv}rwzzzwwwwwr?~~--?r}rw}?r}+^+^+v}rrr}vvv}}}}v}}rrrrvuuuyyyyuuxw}vvv-<br></br>
`vvvvv?vvvv}rrrwwww?^^^^^^^^??>?}}>++^>v}rrr}?~.|?}}rrrr}vvxxxuuxzzzxxxxux~<br></br>
`rr}ww}vvvv?vvvvvv?^|^^^^^++^^^^^>?+^+^?v}rrr}v?vrrrr}}v?+vvv?vzxxxuuxxxzw~<br></br>
`zzwwwwwwwrr}}v??+-``-~||^^^+++++++^^+^^+>?vvvrwvv}}vv?++vv?v?}wr}vvvv???v-<br></br>
`wwwwwwwwwwwwwr}>.  `~~````-~~|^^++++++^|||||>}r??>?>+++vv?v??rrr}?}}v???+.<br></br>
`wrrrwrwwwwwr}?` ..~?rrv?+~`````--~|^^^++++^^++>^>++???vr??v?zuyyyur}vvvvv`<br></br>
`zxw}?^>>??}v??????vvvvvv}rrw}+^~```---~|^^++++++++||+?v+?v?ryyyyyyuxxxzvv-<br></br>
`xz}v|^+>v}}wxuxxv?}}rrwzuuyyyr??v+~~-```-~|||^++++++++^|||vyyyyyxxyyyyyxr-<br></br>
-z}v|>vvrzxuuxv??>?uuuuuuuuyyyyw++}v>>>^~`..``-~|||^^++++|+zxxuyu}xyyyyyyy|<br></br>
-}rvvrwzuuuuuu?+^??zuuuuuuyyyyyv.-?rr?>>>>^-....```---~~~+zxxzwxrzyyyyyxuy^<br></br>
`}}rzxuuuuuuuuwv?vvxuuuuuuzr}wz` >?>?v}v????>|~. `~..--`~rzwzz}?zyyyyyzwuy^<br></br>
`rzxxxuuuuuuuuuw?vvwxxuuw?+++++|..>|??v?+^+>+^` `~-` .-??}}}}}vryyyyywryyy^<br></br>
`xuxzxxxuuuuuuuxvvvvrz}?^++++++>~..^?v?>~^?+-.  .`||-.-vvvvvvv?zuyyyzruyyy^<br></br>
-xuxxxxxxuuuuuux?vvvvv?>|++>>>+>+` .^???+-.    .   .`~>vv?vvv?vwzxzrvzzxuu|<br></br>
-xuxxxxxxxuuuuuuvvv>vv?v>~+^^+>>^.   `-.              >vv?vvv?v}}}v?vzxxzv`<br></br>
-xuxxxxxxxxxzzzx}vv>>???~`~~~~^+-              .      .>vv????vvvvv?v}rrv?-<br></br>


            </div>,

            <div>

.?vv?}}}}}v?vvv??vvv??>>>+???>?v???>+?+|+???^|^>+>?>+>?>??^>??>?}}v???v???`<br></br>
`vwz}zxxzzrwzwwrvrrxzvv?>???vv??vvvvv>>^|^>???+^|^????v?}v>?????r}vvv}??v}~<br></br>
`v}wr}zzzz}rwwwwvvv}zrvwwvvv>?v?+????v?+^|~|^>>^^~+?????v??+?>+vrvv?v}vvv}-<br></br>
`vvrrwrzzwr?vvvrvwvv}z}?wxv?v??v?^>?>+>+^>?vvv?>++?vv???v?+>?^|?vvvvvvv??v~<br></br>
`vvvruzrzww}?vv}}zxvv}zr?rz?>v??v?+>?>^?vzxwvvvvvvvvvvrr?++>?+?vvvvvvvvvvv-<br></br>
`wwwwzuwwzwr??vvxzyzvvvzr?vw?>?vvv?++?vvv}rzrwr}rrrwzrrrv?>>??vv???????>?>`<br></br>
-zwwwwwxrrwwvrwwxwxurv}wwrvvv?}wv?v??vv?vvvr}}zzrvvv}vvv}v?vvv?>v}vvvv???>`<br></br>
-uz}wwwwzr}wvwwwwxrwwxxzwrwr}r}u>^>?v>???vvvvvvvvvvvv}wzw}v?>^^wxxxxwv?v??`<br></br>
`xyx}rwwwxzxzwwwwxz??vuzzzwxuvz}^|+?+????vvvvv?vvvvv}rwzzx}++>zzrzxr}>>>>>`<br></br>
`}wxw}rrwwzzwwwwwzu?~|}xrxzz}vu>+^^|??+?vvvvv?v?v}v}rrwwz}>???}vvv??>>>>>>`<br></br>
`zw}zzwrwwwrvwwwwwzu?|>urzzz?wr++^^^?~>vvvvv??}??}r}r}?++^??>>>>>>>>>>>>>>.<br></br>
`xuxzzwwrrw}}wwwwwwzx>~}zwzr?uv++^^^|~?vvv?rv?v>>?v?>|^^|>}>>>>>>>+++++++^.<br></br>
`zxxxxxxwvvv}}wwwwwzuz++xrzv?uv^++^|^~??>rvwv???>-~^^|-|-vr^>>>>>++++++|-~.<br></br>
`v}xxxxxxuxz}+^?vrwxyz?r}zr}?uv^++^^^-^^|v>v+~+?^-~-???^|rv^>>>>+++^|~|+?r-<br></br>
`vv}wzxxxxxux?|~|+?vz+.zwwwr?w}^++++^~~-`^^~~^??v+>?rwv>?vv^+++^||~|>vxuzv`<br></br>
`?vvvv}wzzxxz?++^^^||-`?uzzrv?x>++++^~??++^`|>??vw}zwv?vrr>-|||^>vwuyyuzrv`<br></br>
`vvvvvvvv}rr>>+++^^^^++^+??w}?vw++++^>v}r}}?>vrrvwzw}}r}}w}v}wxuyyyyyuw}}r-<br></br>
`}www}vv???^``~||^^^^^^^+^^++>+vv^+++^?vrrrrv??vvrvv}r}rvxyyyyyyyuuxzw}v}}-<br></br>
`}wwwwwwr}v>|`.```~~|^^++++^++^^>^^^+^^v}rrrr?~`^?v}}rrr}zxxuuuxxzxzxxuuuu|<br></br>
`rwwwwwwwwwzwrv+..````-~^^^^++++^+^^+^^>?}}}rrvvvrrrrr}}v?+}vvrwxuzwuyuuuu|<br></br>
`wwwwwwwwwwwz}+. ...`^~```--~|^^++++++^||^>???wwvvv}}}v>^^vr>?vwz}vvr}v?>>`<br></br>
`wwwwwwwwwwv>.     `vzw}?|-````-||^+++++^^||~+v}????+++^^?u??vrwr}v?r}???>.<br></br>
`rw}v???v??+~~~|^+>v}}}}}vv?>^-```-~|^^+++++++^+^>v}??>+>xr?vxuyyyyx}}}}}v-<br></br>
`xz}v||^?v}}wzrvvvvvvv}}rwxxuuv?>--``--~|^+++++++^++|^??rx??xyyyyyyyxwrrr}-<br></br>
-z}v|>vvrwxxuxv?>??zxxuuuuuyyyyr}v++^~-``-~||^+++++++^|~^|?zyyyyyuuyyyyyyu-<br></br>
`}w?vrwzuuuuuu?+^??zuuuuuuyyyyyy?v}??>>+.`.``-~|^^^^^^+^|>zuxyyyu}zyyyyyyy|<br></br>
`}}rzxuuuuuuuuwv?vvxuuuuuuz}}rz>.|vr?>>??^`....`-`-~~~||?zxxzzuxrzyyyyyyuy^<br></br>
`wzxxxuuuuuuuuuw?vvwxxuuw?++++| .???vvvv????^... .--```vxzzzzw}rzyyyyyyxuy^<br></br>
-xuxzxxuuuuuuuuxvvvvrz}?^++++++- ~>|?}v?+^^+>|` .--~|^^v}}rzrvvxyyyyyywryy^<br></br>
-xuxxxxxxuuuuuux?vvvvv?>|++>>>>+` ~+v??>~|?>|.  .`-~`>vvvvv}v?xyyyyyuwzyyy^<br></br>
-xuxxxxxxxuuuuuuvvv>vv?v>~+^^+>>+. `?vv?>|.     .  .`?v?vvvv?vyyyyyuwzyyyy^<br></br>
-xuxxxxxxxxzzzzx}vv>>???~`~~~~^+^.  .^|.        .   `???v?vv??rxuxxr}xxuuu|<br></br>


            </div>,

            <div>

`}}}}}}}}}}vvvv????????vvvv?>>>>^|~|||^^^~--`````-~||||||||~~??????}rv?vv?.<br></br>
`xxxxzzzzzzzr}}r}rwwwr}vv??v}}v??????>^+++++^~~---||^+>>>>++^>?v}}}ruyrrzv`<br></br>
`zxzzzzzzwww}rr}}vv???????????>>>>+^|+>?>+++++^~---~~|^++++++^|^?vv?vuuwwr`<br></br>
`zxzzzzzzwwrrwwr}}rwwzzzwr}vvv?>++>???>^^^^^^^^>?>|-`-~^^+++++++^^>?v}uuzr-<br></br>
`zxzzzzzzwrrwzxxxuuuxwrr}vv?+^++>??>+^||^^^^>?v}}}v?^--~~|^++++++++++>vxuz|<br></br>
`xxzzzzzzwrrwzxuyux}??>++++^^|^^^^^^^^||||?vvv}}}}}}}?+|-~~~|^+++++++^|+}z|<br></br>
-xxxxxzzzwrrwzxwv}v?>+^^^^^^^^^^^++>??v??rux}}}}}}}}}}vv>|---~~^^^+++^^^|>`<br></br>
-uuuuxzzzwrrwzw}v?>^^^^^^^++>?vvrwzxuxzxuuxuz}v}}}}}}}vvvv?|-```-~^^^^^^^|.<br></br>
-uuuuuzzzwr}rrv>^^^^^^>??}wzxxxxxzxxzwxuuuuuuw}vvvvvvvvvvvvv?^-``-~~~|||||.<br></br>
-uyyyuxzwr}v?+||^^>?v}zzzxxzzzzzzzzwzuuuuuuuuuw}vvvvvvvvvvvvvv?>~````--~~?|<br></br>
-uuuuuw}rv?^^+>?v}zxxxxxzzzzzzzzzwwxuuuuuuuuuuxrvvvvvvvvvvvvvvvv?}?-``.`+r~<br></br>
-uuxwrrv>^+?}zxzzwwwwzzzzzzzzzzzr>xuuuxxzzzwwr}vvvvvvvvvvv?>+^??zwrv>`.~}r~<br></br>
-uz}}?++?}wxxzzzzw}}}}rwzzzzzzzz>`ry}??vv}}vvvvvv?>^^||^+^+++^^vr}v???^??v-<br></br>
`wr?>?}zzzzzzzxxw}vv}}rwzzzzzzzz}.|ur-ruzr}vv???>+~```|??vvvvvv??rwrv?>??v~<br></br>
`v?vwxxzzzzzzzzzr}}}}rwzzzzzzzzzx?.?y>?}v??++++++>?????vvvv??>^-..+rwv????-<br></br>
`}xxzzzzzzzzzzzwwwwwwzzzzzzzzzzzzx^.}r+vv???vvvvvvvvvvv??^-...~^+` `v}????`<br></br>
-xxzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxuz-.?vvvvvvvvvvvvv?>|`.     .^v??` .?v???`<br></br>
-zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxuuu> .?vvvvvvvvv?^-.   ....   .~vv?` .~~+?`<br></br>
`zzzzzzzzzzzzzzzzzxxxxxxxxxzzzxxuux-  .?vvvvv>~.    ....     .. -?v?-   .?-<br></br>
`zzxxxxzzzzzzxxxxxxxxuxxuuuxxxuuxuu~   .>v?^`   .....        ....`?v?- . ^-<br></br>
                  `zxxxxzzzzzxxxxxxxxuuuuuuuuuuuuuuuu?     `.  .....         .......`?v?-  ..<br></br>
`zxxxxzzxxxxxxxxxxuuuuuuuuuuuuuuuxx}. ..   ....           ........ `?v?-   <br></br>
                  `zxxxxzzxxxxxxxxuuuuuwxuuuuuuzrwwwww~ ......            ............`?v?-  <br></br>
`zxxxzzzxxuuuuuuuuxxr?xuuuuuyzv}wwww- ....             ..............`?v?` <br></br>
`xxxzzxxxuuuuuuuxvv?>?uuuuuyyyw?vv}+   .                   ............>v~ <br></br>
`xxxxxxxuuuuuuuuz??|>?zuuuuyyyyxv?>                        .............>? <br></br>
                  -xxxxxuuuuuuuuuuxv??vvxuxxuuyyyyyu^                        ..............~.<br></br>
-xxxxxuuuuuuuuuuur?v?rx?>>>>?}zw}?.                        ............. ^-<br></br>
-xuxxxuuuuuuuuuuuzvvv?+^+++++++++-.                        .........   .>x~<br></br>
                  -xuxzxxxxuuuuuuuuzvvvv|^+++++++++`                         ........   .?uu|<br></br>
-xuxxxxxxxuuuuuuuwv>vv>~+++++>+>|.                         .......   `vuuu|<br></br>
-xuxxxxxxxxxxxxxx}v^>v>~||||||+^.                          . ....   `}xxxx|<br></br>


            </div>,

            <div>

.~~|^>?v}}}vvvvvvvv?+|~~~|~-~|>v?|~|~-.?rrwrrrrr}}rrrrwwwr}vv?????vv^`+??v`<br></br>
.>vrzzzzzzzzwwrrwr?^^++>+||+vwz?^^+>^. vy}???v}wwxuuuyuz}??>>>>??????^-?}}-<br></br>
`xxzzzzzzzwwrrrr?+^^^??>^>}xxv>^^+?}r` ~uv|rxuuuyyuxxzw}vv?>^-``-++>>>+>v}-<br></br>
`zxzzzzzzwwrrwr>~|^?v?+>}xzv+|+?}zuuw- .vu>wuuuyuzrv??>^^|||++++?v}}}}}}}}-<br></br>
`zxzzzzzzwrrwv+|+?z}++}xzv^^?}zxxxuz++~ `xr?uuzr???????vvvvvvv}}}}}}}}vvvv-<br></br>
`xxzzzzzzwww?|^vxx?^vzwv+>vwxxzzxxz+?z?  >yvwwvvv}}}}}}}}}}}}}}vv?>+|~`... <br></br>
-xxxxxzzzwr>~?zyw+?zzv>vwxxxxxxxxz?ruz`.  ?r?}}}}}}}}}}}}v??+|-..          <br></br>
-uuuuxzzzw+^}xur?wx}v}zzzxxxxxxxzvwux^... .?vv}v}}}}vv?+-`.        ..      <br></br>
-uuuuuxzw>?wzzv?wrwzxzzzzxxxxxxxzzxxv.. .  .?}}}}v?^`.    ........         <br></br>
-uyyyuxw?vwwwv?vv}zxzzzzzzzzzzzzxxxz-.   .  .?v+-.     .......       ....  <br></br>
-uuuuuxv}zzzwr}}}rzxxxxxzzzzzzzzzzzx^    .   ..   ........         ......  <br></br>
                  -uuuxxwrzzzzxxxzwwwzzzxzzzzzzzzzzzzx?    ..    ........            . ..... <br></br>
-uuxzzzzzzzzzzzzzw}}}}rzzzzzzzzzzzzzr.    ..........                 ....  <br></br>
-xuxzzzzzzzzzzxxw}vvv}rwzzzzzzzzzzzxx`     ......                    ..... <br></br>
-xxxzzzzzzzzzzxzr}}}}rzzzzzzzzzzzzzxu|     ...                      .....  <br></br>
                  -xxzzzzzzzzzzzzzwwwwwzzzzzzzzzzzxxxuw`                              ....   <br></br>
                  `xzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxuuu|                                ....  <br></br>
                  -zzzzzzzzzzzzzzzzzzzzzxzzzzzzzzxuuuv                                 ..... <br></br>
                  `zzzzzzzzzzzzzzzxxxxxxuuuxxxzzxxuuu|                                 ..... <br></br>
                  `zzxxxxzzzzzzxxxuuuuuuuuuuuxxxuuuuu^                                 ..... <br></br>
                  `zxxxxzzzzzxxxxuuuuuuuuuuuuuuuuuuuuv                                 ..... <br></br>
`zxxxxzzxxxxxxuuuuuuuuuuuuuuuuuuuww}`.                              ...... <br></br>
`zxxxxzzxxxxxuuuuuuuuuuuuuuuuzzwwrww>                               ...... <br></br>
`zxxxzzzxuuuuuuuuuuuuuuuuu}zyvvwwwwwv..                             ...... <br></br>
`xxxxxxxxuuuuuuuuuuuuuuuxv}uyxvv>^~`...                             . .... <br></br>
`xxxxxxxuuuuuuuuuuuuuur?>+xyyyx|     ..                            .. ...  <br></br>
                  `xxxxxxxuuuuuuuuuuuuuz?+~~zyyyz.    ....                           .....   <br></br>
-xxxxxuuuuuuuuuuuuuuzvv>||v}zy?    ... .                          .....  -`<br></br>
-xuxxxuuuuuuuuuuuuuv^?v>-++++?`   ...                             ...  `?x|<br></br>
-xuxzxxxxxuuuuuuuuv^>vv>|>++>^    ..                              . .~?zuu|<br></br>
-xuxxxxxxxuuuuuuux^^vv?|^++>>| ``..                               .+rxuxuu|<br></br>
                  -xuxxxxxxxxxxxxxu?|vvvv^|^^++- .``                                .rxzzzxx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}vvvvvvvvv}}}}}}}}}}}}}}rrrrrrrrrrrrrrr}}}}}}}}}}|  ^????????v?v`<br></br>
-xxxxxzzzzzzwwrrwwzzzzzzzxxxxxxxxxuuuuuuuuuuxxxxxxxxxzzzzxv. .v}vvvvvvvv}}-<br></br>
`zzzzzzzzwwwrrrrrwzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzzxr` . .`...`~+v}vv-<br></br>
`zxzzzzzzwwrrwwwwzzzzzzzzzzzxxxxxxxxxxxuuxxxxxxxzzzzzzzzx+ .          -?r}-<br></br>
                  `zxzzzzzzwrrrzxxxxxxxzzzzzzzzxxxxuuxxxuuuuxxxxxzzzzzzzzzr..             -?~<br></br>
                  `xxzzzzzzwrrrzuuuuuuxzzzzzzxxxxxxxxxxuuuuuuuuxzwrrzxxzzx+ .              ..<br></br>
-xxxzzzzzwrrwxuyyyuuxzzzzzxxxxzzzxxxuuuuuyyyuzr}rrzuxwwr`..                <br></br>
-uuuuxzzzwrrwzuuyyuuxzzzzzxxxxxxxxxxuuuuuyyyuw}rwrzxrrw}...                <br></br>
                  -uuuuuxzzwrrwwzzzxxuxzzzzzxxxxxxxxxxuuuuuuuyuxzzwwzxxxur...     ..         <br></br>
-uuyyuxzzwwwr}vv}}zxzwzzzzzzzzzzxxxxxxxxxxuyyuuuxxxxxxuw...    ...         <br></br>
-uuuuuxzzzzzwrvv}rzzzxxxzzzzzzzzzzzxxxxxxxuuxzzzzzwwwwww` .   ....       `.<br></br>
-uuuxxzzzzzzxxzzwwzxxxxzzzzzzzzzzzzxxxxxxxxw}v}rzw}}}}}v` ..  ...      `?w|<br></br>
-uuxzzzzzzzzzzzzzwrrrrrzzzzzzzzzzzzxxuxxzzwr}wzxzr}r}}}rv. .   .    .~vxuz~<br></br>
-xuxzzzzzzzzzzzxw}vvv}rwzzzzzzzzzzzxxuuxzwrrwxuxw}r}}rwzx? ..     -?wxuxzw~<br></br>
-xxxzzzzzzzzzxxzrvv}}rwzzzzzzzzzzzzxuuuuzwrrrzxzr}rwwzxxxx^   .->ruuxxzwwr~<br></br>
-xxzzzzzzzzzzzzzrrrwwzzzzzzzzzzzxxxuuuuuxzwrrzzxxzxxxxxxxxz>|?ruuuxxxzwrrr~<br></br>
                  `xzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxuuuuuuuxzzzzzxuxxuuxxxxxxzxxxuuuxxxxzrrrr~<br></br>
                  -zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxuuuuuuuurxxuuuuuuxuxxxxxxxzzzxxxxxxxxzrrrr~<br></br>
`zzzzzzzzzzzzzzzxxxxxxxxxxxxzxxuuxzwwwzzv}xuuuuuuuuxxxxxxxzzzzxxxuxxxzrrrr~<br></br>
`zzzxxxzzzzzzxxxuuuuuuuuuuuxxxuuzrrrrrwr}rzuuuuuuuuuuuuuxxxzxxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzzxxxuuuuuuuuuuuuuuwwwwzzwwwww}vwuuuuuuuuuuuuuuuuuuuxxxuxxxxwrrr~<br></br>
`zxxxxzzzzxxxxuuuuuuuuuuuuuuuzrvwwwr}}}}}vvuuuuuuuuuuuuuuuuuuuuuxxxxxxzwww~<br></br>
`zxxxxzzxxxuuuuuuuuuuuuuuuuuuxw}}rr}vv???vvrzwxuuuuuuuuuuuuuuuuuxxxxxxxxxz~<br></br>
`zxxxxzzxuuuuuuuuuuuuuuuuuuyw?rwwwwwwwwwwwwwr>vxuuuuuuuuuxxzzxuuxxxxxxxxxz~<br></br>
`xxxxxxxxuuuuuuuuuuuuuuuuuyzv?vrwww}???vwwr}????vwuuuuuuxzwwzxuuxxxxxxxxxz~<br></br>
`xxxxxxxuuuuuuuuuuuuuuuuuur??wv?vvv+|>^`|???vw+>>?}uuuuuuxzxxuuuuuxxxxxxxx~<br></br>
`xxxxxuuuuuuuuuuuuuuuuuuuv?v?>}wv?~+^^^|`^vwur>~^??vzuuuuxxxxxuuuuuuuuxxxx~<br></br>
-xxxxxuuuuuuuuuuuuuxrvvv??v?|vuyyr+?v^|+~^zyyyuv||>?vv}zxuxwrzxuuuuxxxxxxx|<br></br>
-xuxxxuuuuuuuuuuuxv>+++^?v?||wuxv^v??>+^||`vzxxv++|~??>^>vzzzxxuuuuxxxxxxu|<br></br>
-xuxzxxxuuuuuuuuu?^+>++?v+|++++++|v?>^^|^+```~^+>>+~>vv>^^+}uuuuuuuxxxxxuu|<br></br>
-xuxxxxxxxuuuuuu}~^^^+vv?|^>>++++|v????>?^~~~^+>++>^^>?vv>^|?xuuxxxxxxxxuu|<br></br>
-xuxxxxxxxxxxxuz>~^>?vv?+|^+^++^^|>?>???>~^^^++++++^`->v???>~}xxxzzzzzzzxx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}vvvvvvvv}}}}}}}}}}}}}}rrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxzzxxzxzzzwrrrwwzzxxzzzxxxxxxxxxuuuuuuuuuuxxxxxxxxxzzzzzzzzzzzzzzzzzzzzw~<br></br>
                  `zzzzzzzzzwwrrrrrwzzzzzzzxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzzzzzzzzzzzzzzzzxxzw~<br></br>
                  `zxzzzzzzwwrrwwwwzzzzzzzzzzzxxxxxxxxxxxuuxxxxxxxzzzzzzzzzzzzzzzzzzzzxuuuzw~<br></br>
                  `zxzzzzzzwwrrzxxxxxxxzzzzzzzzxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzzxxuuuuxz~<br></br>
                  `xxzzzzzzwrrrzuuuuuuxxzzzzzxxxxxxxxxxuuuuuuuuxzwrrzxxzzzzzzzzzzzxxuuuuuuuu|<br></br>
-xxzzzzzzwrrwxuyyyuuxzzzzzxxxxzzxxxxuuuuuyyyuzr}rrzuxwwzzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzzzzzxxxxxxxxxxuuuuuyyyuw}rrrzxwrwzzzzzzzzzxxuuuuuuuu|<br></br>
                  -uuuuuzzzwrrwzzxxxuuxzzzzzxxxxxxxxxxuuuuuuuyuxwzwwzzzxuuxzzzzzzzxxuuuuuuuu|<br></br>
-uuyyuxzzwrrr}}}}rxuxzzzzzzzzzzzzzzxxuxxxxuyyuuxxxzxuuuxxzzzzzzzxxuuuuuxxx|<br></br>
-uuyyuxzzzzzw}vvv}zzzxxxzzzzzzwr}}}rwzz}zxuuuxxxxzwzzwwwzzzzzzzxxxuuuuxxxx~<br></br>
-uuuuxzzzzzzxxzwrwzxxxuxzzzwrrrrwwwzzw}rvxxzrv}rzw}}r}}}zzzzzzzxxxxxxxxxxx~<br></br>
-uuxzzzzzzzzzzzzzwrrrwwzzzzrvwwwwwzzww}w?wzr}rzxxr}}}}}rzzzzzzxxxxxxxxxxxz~<br></br>
-xuxzzzzzzzzwwzxzr}vv}rwzzzrvwr}v?v}vrrvv}}zvrxxw}r}}rwzzzzzzxxxxuxxxxxxzw~<br></br>
-uxxzzzzzzzzzxxxr}vvv}wzzzzrv}?++r?>??vvvvvu>vvv}}rwwzxxxzzzzxxxxxxxxxzwrr~<br></br>
-xxzzzzzzzzzzzzzr}}rrwzzzzrr>++~?zx}|~?++v}??vrvv}}zxxxxxxzzzxxxxxxxxzwrrr~<br></br>
`xzzzzzzzzzzzzzzzzzzzzzzrrrr?+|>^?ux??>^??+?vv}w?}r}xxxxxxzzzxxuxxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzzzzzzwrr}?|~^^+?v}wv+||vr?>vv}rv?r}zxxxxxzzzxxxxxxxxwrrrr~<br></br>
-zzzzzzzzzzzzzzzzxzzzz}r}v|``^+?^||>>|||~?wv?vv}}?vvxxxxxxzzzzxxxuxxxzrrrr~<br></br>
`zzzxxxzzzzzzxxxxxxxuw}rvv+``--?^->}}}^>|`~?v?vvvv?wuuuuxxzzzxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzzxxxuuuuuuz}}vv?~~>^+~>^?v>-|+~`.+v+vvvvuuuuuuuxuuxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzxxxxuuuuuuuur}vvv>>vr?~|-`~+^>w~`^^??>vvruuuuuuuuuuuuuxxxxxxzwww~<br></br>
`zxxxxzzxxxuuuuuuuuuuuuzvvvv?vz}^`~>?>>}v~|vvv?>?}xuuuuuuuuuuuuuxxxxxxxxxz~<br></br>
`zxxxxzzxuuuuuuuuuuuuuuuurv???w}vv}}r^}wv>?w}?>wuuuuuuuuuxxzzxuuxxxxxxxxxz~<br></br>
`xxxxzzxxuuuuuuuuuuuuuuuuuyzv?}}wzwww}}rr}rww}>?vwuuuuuuxzwwzxuuxxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuuuuuuuuuuurv??vwwwwrrrrrwwwwr?|>>?}uuuuuuxzzxuuuuxxxxxxxxx~<br></br>
`xxxxxuuuuuuuuuuuuuuuuuuu}?v?>??v}v?+?>-+vv??}>~+??vxuuuuxxxxuuuuuuuuuxxxx~<br></br>
-xxxxxuuuuuuuuuuuuuxw}vvv?vv^?uwvv~+|+^-`|vzyyuv||>vvvrzuuzrrzxuuuuuuuxxxx|<br></br>
-xuxxxuuuuuuuuuuuuw?+++^?v?^|rzrv?++v>^+~~-?vwxv+^|~??>^>vzzzxxuuuuxxxxxxu|<br></br>
-xuxzxxxuuuuuuuuuv^+>+^?v>~+++^+^^v??>^^|^-``-|+>>+~>vv?^^>wuuuuuuuxxxxxuu|<br></br>
-xuxxxxxxxuuuuuuz|^^^+vv?|^>>+>++^v?>++^^+|--|++++>+^>?vv>^^vuuuuxxxxxxxuu|<br></br>
-xuxxxxxxxuuxxux?||+?vv?>-|+^++^+^>??v>+?+|^^+++++++`->v???>~wxxxxzzzzzzxx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}vvvvvvvv}}}}}}}}}}vv}rrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxzzzxxxxxzwrrrwwzzxxzzzxxxxxxxxuuuuuuuuuuuxxuuxxxxxxzzzzzzzzzzzzzzzzzzzw~<br></br>
                  `xzzzzzzzzwwrrrrrwzzzzzzzxxxxxxxxxxxxxxuxxxxxxxxzzzzzzzzzzzzzzzzzzzzzzxxzw~<br></br>
                  `xxzzzzzzzwrrrwwwwzzzzzzzzxxxxxxxxzwwrwzxxzxxxxxzzzzzzzzzzzzzzzzzzzzxuuuzw~<br></br>
                  `xxzzzzzzwwrrwzzxxxxzzzzzzzzzxzwwrwwwwwzzzwzxxxzzzzzzzzzzzzzzzzzzzxxuuuuxz~<br></br>
                  `xxzzzzzzwrrrzuuuuuuxxzzzzzxxxrvzzzwwzzzzrxwuxzzwwzxxzzzzzzzzzzzxxuuuuuuuu|<br></br>
`xxzzzzzzwrrwxuyyyuuxxzzzzzxxx}}rv?}v??vwwwzuzrrrrzuxzwzzzzzzzzxxxuuuuuuuu|<br></br>
-uuuxxzzzwrrwxuyyyuuxr}}wzzxxz}????zzv}??}}xuw}rrrzxwrwzzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuuzzzwrrwzxuuuuuz????rzxz}?>?v>?ux?|v++uuzwwwwzzzxuuxzzzzzzzxxuuuuuuuu|<br></br>
-uuyyuxzwwrrrr}}rwxxrr}rrw}zw?+>?vv}xuz}v??ryuuxxzzxuuuuxzzzzzzzxxuuuuuxxx~<br></br>
-uyyyuxzzzwww}vvv}zzrzwzwv}}rvvvvvv}rww}??v}wxxxxzwzzzzzzzzzzzzxxxuuuuxxxx~<br></br>
-uuuuxzzzzzzxzwrrwzzxxzzrrwzwvvv???>??????v}}vv}zw}}r}}}zzzzzzzxxxxxxxxxxx~<br></br>
-uuxxzzzzzzzzxxxzwrwwwrv}vz}v?+|^^+?v>^^rw??}vvwwr}}}}}}zzzzzzxxxxxxxxxxxz~<br></br>
-uuxzzzzzzzzwwzxzr}vv}}vvvv?v|~|^+|vr?>|~rw??v}wwv}r}rrzzzzzzxxxuuxxxxxxzw~<br></br>
-uuxzzzzzzzzzzxxw}vvv}r}r}vvrv|``--||--`.`?z??vvrrvrrwzxxzzzzxxxxxxxxxzwrr~<br></br>
-uxzzzzzzzzzzzzzr}}r}v?v}vzr+??.``~|-``^||^>rvv?vrrrxxxxxxzzzxxxxxxxxzwrrr~<br></br>
-xxzzzzzzzzzzzzzwwzrrv>v??v?+^~--^?v+~|??vvv?vvv>?}vxxxxxxzzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzzrr}vv????++^~|?vwwv^||rr?vvv?v}v??rxxxxxzzzxxxxxxxxwrrrr~<br></br>
-zzzzzzzzzzzzzzzzwrrvvvvv??+^~~vrrwzwv^?xrvvvv?v?vw}vzxxxxzzzzxxxuxxxzrrrr~<br></br>
`zzzzxzzzzzzzzxxxrz}vvvv?>?^|->zxrzuux|}v??vvv??vvv}rruuxxzzzxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzzzxxuxrw}vv?v>+>>~-^vv}}r}?-?v>?^???vvvv}rrzuuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzxxxxuuuwrr}}vv??>~|-|?v?>|~``}w+>>^^>vvvv}rwzuuuuuuuuxxxxxxxzwww~<br></br>
`zxxxxzzxxxuuuuuuuzwr}vvvv?|~||^>^~--~~}x>^??vvvvvv}wwxuuuuuuuuuuxxxxxxzxz~<br></br>
`xxxxxzzxuuuuuuuuuuuxwr}vvvv>+??~~|>??>>?^??vvvvv}}rr}xuuuxzxxuuxxxxxxxxxz~<br></br>
`xxxxzzxxuuuuuuuuuuuuxxzvzuxv?>???vv?v}v}}?vvvvvv}rv??vwuzrrzxuuxxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuuuuuz}v?}yuuuuwv?vvv???rzr?vvvvv}rzv??v}xzwzxuuuuxxxxxxxxx~<br></br>
-xxxxuuuuuuuuuuuuuux}?>?rxzxuuuxrv>^?+|++?}wzxxzzzxxr?}vvxxxxuuuuuuuuuxxxx~<br></br>
-xxxxxuuuuuuuuuuuuuwv?>+>>>>>vuuz?+>?>^^~~?}wxzv>+>?vvvvvwzwwzxuuuuuuuxxxx|<br></br>
-xuxxxuuuuuuuuuuuuu???|^++++++??+|??>++^+|^^+>>>+++^^^?v?v}wwzxuuuuxxxxxxu|<br></br>
-uuxzxxxuuuuuuuuux????|+^^+++++++^?v??>>>~^+++++>++^||?v?+>ruuuuuuuxxxxxuu|<br></br>
-uuxxxxxxxuuuuuux?^>???v?|+++++++++?????+~+++++>+++^?>?v????ruuuuxuxxxxxuu^<br></br>
-xuxxxxxxxuxuuuur??vvv?>+-~^+^++++|+???+|~++++^^++|-^^+>??>++xxxzzzzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvvvvv}}}}}}}}}}vv}rrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxzzxxxxxxzwrrrwwzxxxzzzxxxxxxxxuuuuuuuuuuuuuuuxxxxxxzzzzzzzzzzzzzzzzzzzw~<br></br>
                  -xzzzzzzzzwwrrrrrwzzzzzzzxxxxxxxxxxzwwwwwzzwzxzxzzzzzzzzzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwrrrrrwwzzzzzzzzxxxxxxuzrr}vvvv}rr}r}zzzzzzzzzzzzzzzzzzzzzxuuuzw~<br></br>
`xzzzzzzzwwrrwzzzzzzzzzzzzzzzxxxu}?>+?w??>?}}zvzzzzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
`xxzzzzzzwrrrzuuuuuuxxzzzzzxxxxzwv>+?wuw>-+>?vrzwwzxxzzzzzzzzzzzzxuuuuuuuu|<br></br>
`xxzzzzzzwrrwxuyyyuuxxzzzzzxxwvr}?+?|?uur>r^^vwwrrzuxzzzzzzzzzzxxuuuuuuuuu|<br></br>
-uuxxxzzzwrrwxuyyyuuxzzzzzxxxwvr>^>?rw}}uuv^>}}rrrzxwrwzzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuuzzwwrrwzxuuuuuxzzzzzxzrvv??vv?vrrrzv>?vvvwwwzzwzxuxzzzzzzzxxuuuuuuuu|<br></br>
-uuyyuxzwwrrwrrrwwxuxzzzzzwwwr>??v??>>>?vv???vvzzzzxuuuuxzzzzzzzxxuuuuuxxx~<br></br>
-uyyyuxzzwwww}v??}wzzzzzz}vvwv?+|--~|+?+^|>v?vv?vrwxxzzzzzzzzzzxxxuuuuxxxx~<br></br>
-uuuuuxzzzzzzw????r}wxxxvv??vvv---~|~vw?^-~v???vv}}}wr}rzzzzzzzxxxuuuxxxxx~<br></br>
-uuxxzzzzzzzxxv?v}wrwxwvvvvrr}rv-`---++~--`?vv?>?v}rv}v}zzzzzzxxxxxxxxxxxz~<br></br>
-uuxzzzzzzzzww}rzwvv}r?>v?vuz>|v|```~-`````^??wv???vrvrwzzzzzxxxuuxxxxxxzw~<br></br>
-uuxzzzzzzzzzzxzrvvvv?+>v?^?>|^|~~~>?^--``~v???vw}???v}xzzzzzxxxxxxxxxxzrr~<br></br>
-uxzzzzzzzzzzzxzr}}vvvvv????+++~~?vr}?||~--|v??v?v}}v??wxxzzzxxxxxxxxzwrrr~<br></br>
-xxzzzzzzzzzzzzzwwwvrwvvv??vv?>+}xxzxrv>~|^~vz??vvvv}w}}xxzzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzzrrr}vvv??v?~~>vwzrzxz+^?>|+uv?vvvr}wxwzxzzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzwrr}vvv?>+??^``~v}v?v>~~^+??v??>?vrr}xxxxzzzzxxxuxxxzrrrr~<br></br>
`zzzzxzzzzzzzzxxx}zwvv???^>>~?>^~+v?v>``-|+^??>|??vrwvzuxxzzzxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzzzxxux}zwvv??v?^+^|>??^^>+-^+++^|^?>>y}vrw}wuuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzzxxxxux}wwr}vvv??+++^|~~+>>>>+^^>>>?v??+?vrrwuuuuuuuuxxxxxxxzwww~<br></br>
`zxxxxzzxxxuuuuuurrwwr}v>>vv??????+++^^^>?????vv?}uw}}ruuuuuuuuuuxxxxxxzxz~<br></br>
`zxxxxzzxuuuuuuuux}rww}?vv??vv???v????vvvv???vvvvvwzv?>}rzxxxxuuxxxxxxxxxz~<br></br>
`xxxxzzzxuuuuuuux}vv}v>~>xuzwr+....~^?>^^?vv?vvvvvv}vv>+>?vrzxuuxxxxxxxxxx~<br></br>
`xxxxxxxuuuuuuuuw?>>??+~`?wxuyz`~??v>^~~-??}vvvvvv}rrzv?+?vwxuuuuxxxxxxxxx~<br></br>
-xxxxxuuuuuuuuuuz?>??ww?}rvvruyz??????>^->wxxrvv???>uxw?v??xuuuuuuuuuxxxxx~<br></br>
-xxxxxuuuuuuuuuuuv?vvux?wwrv??v}?^>?+>>|^~>?vrv+--`|}xz?vv?wzxxuuuuuuuxxxx|<br></br>
-xuxxxuuuuuuuuuuuvvvvw?^+?>+^++++^?}??>+>|+++++|>?}?^?vvvvv}wzxuuuuxxxxxxu|<br></br>
-uuxzxxuuuuuuuuuxvvvv?>>^^++>>+++++v>???+~++++^|>??^>>+vvv?vxuuuuuuxxxxxuu|<br></br>
-uuxxxxxxuuuuuuuw?vv?v?v?^+++++++++??vv>|^++>+~|^+-?}?+v>vvvwuuuuuuxxxxxuu^<br></br>
-xuxxxxxxxuuuuuurvvv+v?>^`~~~|+++++^+>?|~++++|~~|^`~>^++^?>>?xxxxzzzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvvvv}}}}}}}}}}}vv}rrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}v}}v-<br></br>
                  -xxzzxxxxxxzwrrrwzzxxxzzzxxxxxxxxuuuuuuuuuuuuuuuxxxxxxxzzzzzzzzzzzzzzzzzzw~<br></br>
                  -xzzzzzzzzwwrrrrrzzxzzzzzxxxxxxxxxxzwwwwwwwwrzxzzzzzzzzzzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwwrrrrwwzzzzzzzxxxxxxxxxwr}vv??v}r}}rrzzzzzzzzzzzzzzzzzzzzxxuuzw~<br></br>
`xzzzzzzzwwrrwzzzzzzzzzzzzzzxxxxuwv>^+}r>++?}}}rzzzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
`xzzzzzzzwrrrzxuuuuuxxzzzzzxxxxzw}?^++zuv-+>>?rzwwzxxzzzzzzzzzzzzxxuuuuuuu|<br></br>
`xxzzzzzzwrrwxuyyyuuxxzzzzxxxwvr}?^???wzzrz>~vrwrrzuxzzzzzzzzzzzxuuuuuuuuu|<br></br>
-uuxxxzzzwrrwxuyyyuuxzzzzzxxzwv}>|>vrxw>zuv++vvrrrzuzrwzzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwzuuuuuuxzzzzzzww}v??vvvvv}rw}???vvrwwzzwwxxxzzzzzzzxxuuuuuuuu|<br></br>
-uuyyuxzwwrrwwwwwzxuxzzzzzrrww>???>>>++^????+?vrwwzxuuuuxzzzzzzzxxuuuuuxxx~<br></br>
-uyyyuxzzwwww}vvv}zxzzzzzvvvrvv^```--^v?^->v>?vv?rwxxxzzzzzzzzzxxxuuuuxxxx~<br></br>
-uuuuuxzzzzzzr????wwzxxxvv?vvvw>---~~vw?^-|vv???vvrrwrrrzzzzzzzxxxuuuuxxxx~<br></br>
-uuxxzzzzzzzwv?>v}rwzxz}vvvzzrv}+`---|^--``??rv???vr}vv}zzzzzzxxxxxxxxxxxz~<br></br>
-uuxzzzzzzzzr??vww}}v?v}zvvwr?~?+```~|--```+?~vrv???}v}wzzzzzxxxuuxxxxxxxz~<br></br>
-uuxzzzzzzzzwwzw}vv}}^^vv?+??^+^~|+>?>~~---|v+??}r}???vzxzzzzxxxxxxxxxxzwr~<br></br>
-uxzzzzzzzzzzzzw}}vvvv}v?????>>~~v}wzv>+|-~~>v?vvvv}}v?}xxzzzxxxxxxxxzwwrr~<br></br>
-xxzzzzzzzzzzzzzwwr}}rvvvv?vv>+?}xuwxxw?~|+|^vvvvvv}}zwwxxzzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzwwwr}vvv>+?+|-|>vr}zzx>|>>|+vv?vvvrrwxzzxzzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzzwzwrvvvv>^?>^+|`?}}v}+-~|^>?v?>?>?rr}xxxxzzzzxxxxxxxzrrrr~<br></br>
`zzzzzzzzzzzzzzxwwzw}v??v>++^>++|^???>-|^++^>?>|???}w}wuxxzzzxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzzzxxuzrzwvvv?v?+?>+>>>+^++~^++^||+???rzw}rrruuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzzxxxxuwrzwr}}v??v??>>>>>?>^+++++>??vv????>vrrxuuuuuuxxxxxxxxzwww~<br></br>
`zxxxxzzzxxxxuuuz}wwwrv?+>v}vvvvv???>^^+>?v????v?v}wzrvruuuuuuuuxxxxxxxzzz~<br></br>
`zxxxxzzxxuuuuuuuwrrrvr}}?>?????|~--+??vvv?vvvvvvv}ww}?vrzxxxxuuxxxxxxxxxz~<br></br>
`xxxxzzzxuuuuuuuxvv}}}rvruuxw}>....``||-|vvv?vvvvvv}}}?^>?vwzxuuxxxxxxxxxz~<br></br>
`xxxxxxxuuuuuuuuw?>>?v}vvwuuyyx|`~~~`...`??}rrvvvv}rrwv?+?vwxuuuuxxxxxxxxx~<br></br>
-xxzxxuuuuuuuuuuz?>??wxwzr}}wuyu?~-```~|->wxuzwvvv??v?}?v??xuuuuuuuuuxxxxx~<br></br>
-xxxxxuuuuuuuuuuuv?vvux??>>>>?v}?^|~?>?+^|+?vrv>^^--~^}?vv?wzxxuuuuuuuxxxx|<br></br>
-xuxxxuuuuuuuuuuuvvvvw?^++++++++++??>??>>|++++++^+>||?wvvvv}wzxuuuuxxxxxxu|<br></br>
-uuxzxxuuuuuuuuuxvvvv?>>^+++>++++++v>???+|+++++^^>+~>?vv?v?vxuuuuuuxxxxxuu|<br></br>
-uuxxxxxxuuuuuuuw?vv?v?v?^+++++++++??vv>|^++>+~^++~?v?>v>vvvwuuuuuuxxxxxuu^<br></br>
-xuxxxxxxxuuuuuurvvv>v?>^`~~~|+++++^+>?|~++++^~~|^`~+|++^?>>?xxxxzzzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvvv}}}}}}}}}}}}vv}rrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxxxxxxxxxzwrrrwzxxxxzzzxxxxzzxxuuuuuuxxxxxxuuuuxxxxxzzzzzzzzzzzzzzzzzzzw~<br></br>
                  -xzzzzzzzzzwrrrrwzzxzzzzzzxxxxxxxxxxwwwrrrwwrrzxxzzzzzzzzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwwrrrrwzzzzzzzzxxxxxxxxuxr}v?v???vr}}wwzxzzzzzzzzzzzzzzzzzzxxxzw~<br></br>
`xxzzzzzzwwrrwwwzzzzzzzzzzzzxxxuzwr?|~?x}^`^?vvz}zzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
`xxzzzzzzwrrrzxuuuuxxzzzzzzzxzrzr}?+~-?uu}>v++vvrzzzzzzzzzzzzzzzzxxuuuuuuu|<br></br>
`xxzzzzzzwrrrzuyyyuuxxzzzzxxxz}}v^^>?rwv}uuv|>}vrrzuxzzzzzzzzzzzxxuuuuuuuu|<br></br>
-uxxxzzzzwrrwxuyyyuuxzzzzzxzzwvv?>?}v}z??ur?>vvvrrzuzrwzzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzzzzzwr}w???vv??>>?}}??^?vvrwzzwwzxxzzzzzzzxxuuuuuuuu|<br></br>
-uyyyuxzwwrrwwwzzzuuxzzvv?vvwr??+|~-~^>>+|?v+??v?vwzxuuuxzzzzzzzxxuuuuuxxx~<br></br>
-uyyyuxzwwrwr}vv}rzxzwr???vvvvv+`--|~?rv>->vv??vvv}}zxxxzzzzzzzxxxuuuuuxxx~<br></br>
-uyuuuxzzzzzzrvvv}zzzwvr}v?}}}rr+`---^?^~`>?vw?>??vr}}wwzzzzzzzxxxuuuuxxxx~<br></br>
-uuuxxzzzzzzzzwrwwzzxw}zr?vzzr+?v```----``+v`^}rv??vrvvrzzzzzzzxxxxxxxxxxz~<br></br>
-uuxzzzzzzrv???rrwr}rzuur????>|^|-`~+>~-``-v^`|?rrv??vvwzzzzzxxxxuxxxxxxxz~<br></br>
-uuxzzzzzzw???vz}}v?>vrrv?>??++^~^??}?^|~--+v>}vv?vr}v??zzzzzxxxxuxxxxxzww~<br></br>
-uxzzzzzzzzrrzwvv}}v+vrvvv?????++rxxxzv?+~|^?vvv?vvvvwr}wxzzzxxxxxxxxxwwrr~<br></br>
-xxzzzzzzzzwzzwrrv??vrvvv?|+?+~+vrxwzxx}~^?^~>?wwvvr}zzzrxxzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzzzzzzzzzrr}r}vvv>^>^+|--+}}vvv>~|>>???vzvvrrruxxxzzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzwzwwrvv???>+^|++^^vv?v^-~>|^?v>^?vv?}}zxxxzzzzxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzrzwr}vv???+>>>^^|^+>>^+^|^~^??>>?r}v}}wuxxzzzxxxxuxzxzrrrr~<br></br>
`zzzxxxzzzzzzxxzrwzwvvvv?>?v???????>~~||+^+??vvv}rwuwv}uuxxxxxxxxuxxxxwrrr~<br></br>
`zxxxxzzzzzzxxuzrwzw}vvv?>}r}v??????>^^+??vv}v?+vrrv>?}zwzuuuuxxxuxxxxzwww~<br></br>
`zxxxxzzzzxxxxuzrwwrvvv?>??v}v??vrwzw???vvv}v>??v}r}}xxw}wuuuuuuxxxxxxxzzw~<br></br>
`zxxxxzzxxuuuuux}wwvwxwrv?>+>?vrzzrv?>?vv??v?>?vvv}rrrrv??rxuuuzwxxxxxxxxz~<br></br>
`zxxxzzzxuuuuuuwvrv>xuuuuuzzwr}ww>.   `vrwv???v??vv}rrr}?+>vzz}w}wxxxxxxxx~<br></br>
`xxxxzxxxuuuuuu}+^`-wuuuuuuyyyuw^.-....~+|?wwwwwwrvvv}rww>+?rwrw}wxxxxxxxx~<br></br>
-xxzxxuuuuuuuuuz?~``wuuuuz}}wxyy>.`-....`~rxuuuxxxxzwrrv?|~~+wwrvwxuuxxxxx~<br></br>
-xxxxuuuuuuxxxuu}?>?}uuz?+++++???^`.`....`+>?v?>>>?vzuu}?>^^?vvvvzuuuuxxxx|<br></br>
-xxxxxuuuuuz}vrrzww}?zv++++++++++++^.....->++++++++++?rvvvvvwwzxxuuxxxxxxx|<br></br>
-uuxxxuuuuuuw}}}vv}?vv>>++>>>++++++?>--++??++++++++|>??v?v??xuuuuuuxxxxxxu|<br></br>
-uuxzxxxxuuuuxzrv?v??v?v?^++++>++++>???????+>+|^++|?v?>v>vvvruuuuuuxxxxxuu^<br></br>
-xuxxxxxxxuuuuuurv}?+v>>|`~~~|+++^^^++?-^?+++^~~|~`~^+++|?>>?xxxxzzzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvvv}}}}}}}}}}}}vvvrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxxxxxxxxxzwrrrwzxxxxzzzxxxxzzxxuuuuuuuuuuxxuuuuxxxxxzzzzzzzzzzzzzzzzzzzw~<br></br>
                  -xzzzzzzzzzwrrrrwzzzxzzzzzxxxxxxxzzzwwwwwwwwrrzxxzzzzzzzzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwwrrrrwzzzzzzzzxxxxxxxzzzr}}v}??vvrwzzwzxzzzzzzzzzzzzzzzzzzxxxzw~<br></br>
`xxzzzzzzwwrrwwwwzzzzzzzzzzzxxzzwr}v>+?w}>>}vr}z}zzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
`xxzzzzzzwrrrzxxxxxxxzzzzzzzxzrr}v?>?^^v}}>wvv}vrzzzzzzzzzzzzzzzzzxuuuuuuu|<br></br>
`xxzzzzzzwrrrzuyyyuuxxzzzzxxxz}}?||?r}++?}zv>?vvrwzzzzzzzzzzzzzzxxuuuuuuuu|<br></br>
-uxxxzzzzwrrwxuyyyuuxzzzzzxzzrv??>>rrzzrvv??>vvvrwzzwwwwzzzzzzzzxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzzzzzwwr}>+?v}r?v}}?>?v+>?vrwzwrrwwzzzzzzzzxxuuuuuuuu|<br></br>
-uyyyuxzwwrrwzzzzzuuxzzvv?vvrv??^||>?vv?^^+vvv?v?vrrrwwwwwwwwzzzxxuuuuuxxx~<br></br>
-uyyyuxzwwrwr}vv}rzxzwr???}vv++^`--|+?v?+~|^+?}}}}rrwwwrrrrrrrzxxxuuuuuxxx~<br></br>
-uyuuuxzzzzzz}vvv}zzzwv}vv}r?+^^|--~+??~~-~~^^v>^>vr}}wwzxxzxzzxxxuuuuxxxx~<br></br>
-uuuxxzzzzzzxxzwwwzzxz}wwvvzv?~|>~-|?v>~--~~-~?r??vvvvv}wzzzzzzxxxxxxxxxxz~<br></br>
-uuxzzzzzzw}rrwzwwr}rwzzrv??v?+|----+?|----+~+?v}r}vvvv}wzzzzxxxxuxxxxxxxz~<br></br>
-uuxzzzzzzzvv}rzw}vvv}r}v?vv?>>~`-^+??^|--`^?vrvv?vrr}}}wzzzzxxxxxxxxxxxww~<br></br>
-uxzzzzzzzzwwxzr}vr}v}rvvvv??+|~|?rrzzv?+-|+?vvvv}vv}wwwrzzzzxxxxxxxxxzwrr~<br></br>
-xxzzzzzzwr}}wwrr}vv}zr?vv>^>|-~?}xwzxuz+-~~~??wwvv}}wwzrzxzzxxuuxxxxzrrrr~<br></br>
-zzzzzzzzr}}rxzzw}v?r}vv??||^^~~^?vrvvr}>|||^>?vzv?}}}zzwzzzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzw}rwzzwwz}v}vvv???>^^^^|^?v??+^^+~+>?>^?v?v}vwzwwzzzzxxxxxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzwwzr}}vv??>>?>>++>+^+>^^^|^>^??>>?v}}z}rwzzzzzzxxxuxxxzrrrr~<br></br>
`zzzxxxzzzzzzxxwrwwr}vvv?^?v???????>^||^>>>??vv?}r}rxw}wxxxxxxxxxuxxxzrrrr~<br></br>
`zxxxxzzzzzxxxzrrwwr}vvv?>vr}vvv?v??>^+>?vvvv}?+v}v?vwr}xxuuuuxxxuxxxxzrrr~<br></br>
`zxxxxzzzzxxxxzrrrrvvv???v??vv??vrwzr???v}vvv+?vvvv}vv}}wxuuuuuuxxxxxxxwzw~<br></br>
`zxxxxzzxxuuuuzwrr}?rzz}v????>??v?>>>+v}}}vvv>?vvv}r}>?rvrrxuuuzwxxxxxxxxz~<br></br>
`zxxxzzzxuuuuuzrv}?+xuuuuuxxzzzv?|.. .~v}w????v??vv}}v}r?>>vzzrwrwxxxxxxxx~<br></br>
`xxxxzxxxuuuuuxv?|~^wuuuuuuyyyyy^.-....`|~>wwwwwwwwrvvrww?>?rzwzrwxxxxxxxx~<br></br>
-xxzxxxuuuuuuuxv+--^zuuuuz}}wxyyv..`....`-rxuuuxxuuuz}vv?^~+vzxwrzxuuxxxxx~<br></br>
-xxxxxuuuuuxxxx}^|>vwuuz?+++++???~`~~`..`-+>?v?>>>?vzuu}??+>vrrwwxuuuuxxxx|<br></br>
-xxxxxuuuuuzwwwvv}r}vzv+^++++++++++>|~``.-+++++++++++?rvvvvvrwzxxuuuxxxxxx|<br></br>
-uuxxxuuuuuuzrr}r}}vvv>>++>>>++++++?>^+>|+>++++++>+|???v?v??xuuuuuuxxxxxxu|<br></br>
-uuxzxxxxuuuuxz}}}v??v?v?^++++>++++>??v?>^^+++|^++~?v?>v>?vvruuuuuuxxxxxuu^<br></br>
-xuxxxxxxxuuuuuuwvv?+v>>|`~~~|+++^^^++?-|>^++^~~|~`~^+++|?>>?xxxxzzzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvv}}}}}}}}}}}}}vvvrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}v-<br></br>
                  -xxxxxxxxxxxwrrrwzxxxxzzzxxxxzzzuuuuuuuuuyyyuuuuuxxxxxxzzzzzzzzzzzzzzzzzzw~<br></br>
                  -xzzzzzzzzzwrrrrwzzzzzzzzzxxxzzwzzzzwzuuuuuxzxxzzzzzzzzzzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwwrrrrwzzzzzzzzxzwrrrrrrww}wwzwrrr}rwwzzzzzzwrrrrwwwzwzzzzzxxxzw~<br></br>
`xxzzzzzzzwrrwwwwzzzzzzzzzwrwwwwwwwwrvv}vv}wrrwwwzwwwwwwwwwwrrrrwxxzxuuuxz~<br></br>
`xxzzzzzzwrrrzxxxxxxxxzzzwwwwwwr}}}}}}vvrvvrr}}}rrrrrrrr}}}v}rzxuxxxuuuuuu|<br></br>
`xxzzzzzzwrrrzuyuuuuxxxzxwrrr}}vv?>?vv?vv?vvvvvvvvvvvvv}}rzxxzzzxxuuuuuuuu|<br></br>
-uxzzzzzzwrrwxuyyyuuxxzzxxw}v???rw>`>?>??>>??????vv}rwzzrzzzzzzzxxuuuuuuuu|<br></br>
-uuuuxzzzwrrwxuyyyuuxzr}}rzv>+`?zxw>}?^+????}rzzxzwr}v}}}}wzzzzzxxuuuuuuuu|<br></br>
-uyuuuxzwwrrwzxxxxuuxr???}rv+?>vwrrxx?>+~~~|>v}rvvvvv}}}}}}rwzzzxxuuuuuxxx~<br></br>
-uyyyuxzwwrwr}v}}rxuwr}vrr}>~|?rz}v}?+^|-`--``->vvvvv}}}}}rwrwzzxxuuuuuxxx~<br></br>
-uyyyuxzzwwzw}vvvrzzw}}rv+??^~|^>v?>^||-----``~~^>v}v}rrrwwzwwzzxxuuuuxxxx~<br></br>
-uuuuxzzzzzzxzwwrwwwrrr}|`~>?|^^^???v>+^~~-~?>}vvv?vvv}rrrwwrrzzxxxxxxxxxz~<br></br>
-uuxxzzzzzzzxxxxxw}}rr}>--`~}^|||?}rz?-|~-`>rwrrwvvv}vv}rrrrrrzxxuxxxxxxxz~<br></br>
-uuxzzzzzzzzzwzxw}rr}}vv>|~|r^~+~|>v?-`---~>?vr}vvvv}}vvrrrrrzxxxuxxxxxxzw~<br></br>
-uxxzzzzzzzzzzxzwrr}vvvvzw??}~-~~``|???~--|>>>wzrvvv?vv}rrrrwxxxxxxxxxzwrr~<br></br>
-xxzzzzzzwwwwwzxwr}vvvv?vrvv?---^?vr}}>~---||^vxxvv?+?}}rrrrzxxuuxxxxzrrrr~<br></br>
-xzzzzzzzwrwzwzww}??vv?vr}>??^--~>?rv?^+^|^^~>?}xw>??vvrrrwzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzzzwwwrwzw}??}v}rz}?v+>^||~|++^+^^||++?v>+??v}v?}rwzzzxxxxxxxxwrrrr~<br></br>
`zzzzzzzwwrwrrwzr?>wr?rr}?v??>^+^^^^^|~>>>???vv+^+?}uv?}zzzzzxxxxuxxxzrrrr~<br></br>
`zzzxxxw}}v}xwrwz?+vv?vv??v>???????++^+?vv??????vvv}xuzxuxzzzxxxxuxxxzrrrr~<br></br>
`zxxxxxw}vvrwwr}w}?vvvvv??v|?++??vv???vvv}zz>|>?vvvv}xx}}xuuuuxxxuxxxxwrrr~<br></br>
`zxxxxzzwrrwrrr}vvvvvvv????^+|+v}vr}?>???vv?+>?vvvv}rrv^vxxxuuuuxxxxxxxwww~<br></br>
`zxxxxzzxxuzrrr}vvvvvv}}?>>?????v?..`````|-.>??vvv}r}vw}vrr}rwwxxxxxxxxxxz~<br></br>
`zxxxzzzxuuxrrr}vv??rxuuwrzxxuuv?>~ ....`-.`>?vvvv}}rzzr>++?vrwxxxxxxxxxxx~<br></br>
`xxxxzzxxuuuzwrvv??vxuuxwruyyyyyx}>`......`vrrrrrwzxxzzr}???wuuuuxxxxxxxxx~<br></br>
-xxxxxxxuuuux}}?^+?vuuuz}wz}rzuyuz?>-.....>xuuuxxxuuuxxw?v??xuuuuxxxuxxxxx~<br></br>
-xxxxuuuuuuuuv?^`~vvuuux?>++++>?>^|v?+>+>^^>???>>>?vxuuw?vvvuuuuuuuuuuxxxx|<br></br>
-xxxxxuuuuuuuw?~-^v?}xr?^+++++++++^v???>?|++++++++++>?}vvvvvwzxuuuuuxuxxxx|<br></br>
-uuxxxuuuuuuuu}>?vv??v?>++>>>>++++^>>???+|+++++++>+^???}??v?wxxuuuuxxxxxxu|<br></br>
-uuxzxxxuuuuuuxwrw}v?v?v?|^^++>++++^>?v+~+++>+||++~?v?>v+?vvruuuuuuxxxxxuu^<br></br>
-xuxxxzxxxuuuuuurvvvvv>?|`-~-~+++^^^^^?-~++++^~~|~`-^>++|?>>?xxxxxxzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvv}}}}r}}}}}}}}vvvrrrrrrrrrrr}}}}}}vv?v}rr}vv}}}}}}}}}}}}}}v-<br></br>
                  -xxxxxxxxxxxwrrrwzxxxxzzzzxxxzzzxuuuuuuuuuxxxxxxzwrwuuxxzxxzzzzzzzzzzzzzzw~<br></br>
-xzzzzzzxxzzrrrrwzzzzzzzzzxxxzwzzwwxzzzzzzzwwwr}}wxxxzzxzzzzzzzzzzzzzzzzzw~<br></br>
`xxzzzzzzzwwrrrrwzzzzzzzwwwwrrrww}}rvrwzwr}vvv?vwxzr}rwwwwwwwzzzzzzzzzzxzw~<br></br>
`xxzzzzzzzwrrrwwwzzzzzwwwrwrwwwwrrr}v}r}vv}v?vrxxr}}}}}}rwwwwwrwwxxzxuuuxw~<br></br>
`xxzzzzzzwwrrwzzzxxxwwwrwwwwwwwrrr}r}}}}r???vzz}vvvvvvvv}}rrrrzxxxxxuuuuxz~<br></br>
`xxzzzzzzwrrrzuuxuuxwzwzxwrrr}vv}r???vvv??vwxwv?vvv??vvvvrzzzzwwxxuuuuuuuu|<br></br>
-uxzzzzzzwrrwxuyxuuxwwwwzwrv?vv?}r?`??>?vr}v????vvvvvv}}}wwwwwwwzuuuuuuuuu|<br></br>
-uuxxxzzzwrrwxuyyxuuxw}}}}}?+v?vv}?~?>^+????v}rrrr}}vvvv}}rrrwwwwxuuuuuuuu|<br></br>
-uuuuuzzzwrrwzxuuxxux}vvvvv?+}vv?vv??^`-`---|>?vvvv}}}}}}}}}rrwwwzuuuuuxxx~<br></br>
-uyyuuxzwwrrwr}}rwxuw}}v}??-^}zzzzv?>|---`--```>vvvvv}rr}}}}}rrwwzuuuuuxxx~<br></br>
-uyuyuxzzwwwwvvvv}zzrvv}v|>^>v}vr}?+|-`--+???>~+??vvvv}rr}}}}}rwwzuuuuxxxx~<br></br>
-uuuuxzzzzzzxzrrrrwrvvvv^`->v?????>++|~~-^??vvwrvv??v?vv}}}}}}rwwxuxxxxxxx~<br></br>
-uuxxzzzzzzzxxxxz}}}?>?^-~->?||+>?rrr?-~~|^?zrww?vv?}v???v}}}}rrxuxxxxxxxz~<br></br>
-uuxzzzzzzzzzwwzr}rv^|^>+|-^}|~??v}?>~--`~??rwwv>???vvv?v}}}}rwzxuxxxxxxxw~<br></br>
-uuxzzzzzzzzzzxzwr}>~~||?+~^}?^++^~|>^~-`->>?rxx}??????v}}}}rzxxxxxxxxzzrr~<br></br>
-uxzzzzzzzzzwzxzr}v?^|~~^+^+}v|`-|vr}?~-||^|^?zuxr???~^+v}rrzxxuuxxxxzrrrr~<br></br>
-xzzzzzzzzzwwwzwrvvv?+^>v?>???|-~^>v??^+^+??+?vzux?vvv?}rrwzzxxuuxxxxwrrrr~<br></br>
`zzzzzzzzzwwrrzzrvvvvvrrrvvv?v>~~~|~|^++>????vrv?vvvwr?rzzzzzxxxuxxxxwrrrr~<br></br>
`zzzzzzzzrrr}rwzv??}v?rw}vvv?v>^^+++^|+vwwwr}}r?++?}uw}wuxzzzxxxxxzzxzrrrr~<br></br>
`zzzzxxw}vvv}}v}}?+?v?v}}vv>?v>+++>>>+?????rvv???vvvzxxxzwzzzxxxxxxxxzrrrr~<br></br>
`zxxxxxz}vvvvvvv???rvv}}}v?~??~>>?v??+||||??>>v}v?vv}zxv^?xuzzzzxxxxxxwrrr~<br></br>
`zxxxxzzr}vvvvrw?|?}?vvv???^?~|v}}rr?`---|?|^>}}?vvvrr}+?rzzzwwwwwzzzxzwww~<br></br>
`zxxxxzrrr}}rrr}v>?vv?vv?>>????v}}?>|....``.+>v}vv}r}vwv?}vvrwzzzrzzxxxzxz~<br></br>
`zxxxxzrrrv}}}}v???vvwxuwrwwxuuvv??``....`..^?vvvv}}rxuv>++>}zzxxxxxxxxxxz~<br></br>
`xxxxzzzw}}rvvvvv??vxuuxw}wuyyyyxrv|`......>}}}}}wzxuuu}v???wuuuuxxxxxxxxx~<br></br>
-xxxxxxxuzzw}vvv???}uuuzrvvr}wuyuxv?^~.. .^xuuuxxxuuuuuz?v??xuuuuxxxxxxxxx~<br></br>
-xxxxxuuuuzwr??+~+vvuuuuv?++++>?>+^v???+>^^>???>>>>vxuuw?vvvuuuuuuuuuuxxxx|<br></br>
-xxxxuuuuuxwrv+-|>v?}xr?+?>+++++++^v???>?^+++++++++^+?}vvvvvwzxuuuuuxuxxxx|<br></br>
-uxxxxuuuuux}v>~~?v??v?>|^>++>++++^>>???+|+++++++>^^???vv?v?rxxuuuuxxxxxxu|<br></br>
-uuxzxxxuuuuxrv>>vvv?v?v?~^^++>++++^>??+~+++>+||++-?v?>v+?vvruuuuuuxxxxxuu^<br></br>
-xuxzxxxxxuuuxr}vv}}??>?|`-~-~+++^^^^^?-~++++|~~|~`-^>++|?>>?xxxxxxzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvv}}}}r}}}vv}}}vvv}rrrrr}}}}v?????????????>?}rr}vv}}}}}}}}}v-<br></br>
-xxxzxxxxxxxwrrwzzxxxxzzzzzxzzwxxuuzwzzwrr}vvvvvvvvr}}vvruuuxzzxzzzzzzzzzw~<br></br>
-xzzzzzxxxxzrrrrwzzzzzzzwwwzwwrwxzzvvvvvvvvvvvvvv}r}vvrxuuxwwwwwwwzzzzzzzw~<br></br>
`xxzzzzzzzzwrrrrwzzzzwwrrrrrwwwwz}vwv???vvvvvvvv}}v?vzuxzwrwwwwwwwwzwzzzzw~<br></br>
`xxzzzzzzzwrrrrrwzzwwrrrrrrwwwwwrr}}rrv?vvvv?vv}v??rzzwr}}rrrwwwwwwzxxxxzw~<br></br>
`xxzzzzzzwwrrwzzzzzzwrrrwwwwwwwrrrrr}}rvvv??vvv??}zzwrvv}rrrrrwwwwwwzzzzww~<br></br>
`xxzzzzzzwrrrzuxxxwzwwwwwwwwwrrrrr}}}?rv>>?vv??}wwrvvvv}}}}}rrrrrrwzzzzwww~<br></br>
-xxzzzzzzwrrrwuuxwrwwwwwwrwvvv}}v?vv}v}z}}}vvrww}vvvvvvvvv}}}}}}}}rwwwrrrw~<br></br>
-uuxxxzzzwrrwzxuuxzwwrrrr}???}xr??^~+???v}}}r}}vvvvv??vvvvvv}}}}v}}}}rrrrw~<br></br>
-uuuuuzzzwrrwxwxuuuxw}}}v?>??ruxv+`>|--~-||^^+>??vvvvvvvvvvvvv}}vv}}}}rrwr~<br></br>
-uyyuuxzwwrrwr}}wxxrwr}v?>+?rxuuz?`?+-~|~`````````~++>vvvvvvvvvvvv}}}}}}wz~<br></br>
-uyuyuxzzwwww}vv}rwr}r}}?>v??zuuxwv}>|^^~-|^+^~||^|||>vvvvvvvvv}vv}}}}}}rw~<br></br>
-uyuuuxzzzzzzw}}}}}}v?vv+~+~`}xxwvwv|^|~-~~|^?wrvrr>^?vvvvvvvvvvv}v}}}}rrr~<br></br>
-uuxxzzzzzzzxxxxz}}v^+??|^??vrzz}vr>|^~--|||+?wxvrwvvv?????vvv}v}}}v}}wzrw~<br></br>
-uuxzzzzzzzzzwzw}}v^~~^>^^^?}??w}}}>-~---|>|+?rv??v}wwr?vv?vvvv}?vrv}zuzzw-<br></br>
-uuxzzzzzzzzzzwrr>-`~||~|||?v?+?vvv??v~~>^^~|vwwvvv}rwv?}}vvrr}v?wrzxzzxw}-<br></br>
-uxzzzzzzzzzzwrr}>-`-|^^^|~+}v++>?}}zr^-~^|--?wzwrrxw^||?rr}rrr}vuuuzrwwr}~<br></br>
-xxzzzzzzzzwwwr}v|``-~~~~~~^}r?^?v}wr?~|~~~-~|vzwwxuuz??r}rrzzw?vuuuxzwr}}~<br></br>
`zzzzzzzzzwzwrr}v|`-~^--```|?wr>+^^^+?v??||^++??vvwxux}vrwzxuuw?>?}rzwrr}r~<br></br>
`zzzzzzzwwzz}}}}v?|^~``-|-`->wr?>|~-?v}}}?vvvv}v^|>zxwzxuuuuuux}+>?}xzrrrr~<br></br>
`zzzzzzwwwzrvvvvvv?+~`~>?+||>rv+~-->+?v?^^?}}}}}v?v?vxuuwwzxzww}wxuxxzrrrr~<br></br>
`zxxxzwzrwrv}vvv??vrrvv}v}}??v?>-~^|~~``-^?+>vrwvvvvvwzr?vzzzwwwxxxxxxwrrr~<br></br>
`zxxxwwrrrrr}v}}?+?}v}}vv???vvvv>>??>-~^|^>^?vzzvvvrxz}?vrzzzzw}wzzzzxzwww~<br></br>
`zxxzwrr}}vrw}}vv>?v?vv???vvvv}v??v?>|^|-^??rwxxr}vrwww?+??vwxzxxzzzxxxzxz~<br></br>
`zxzwwrvvv?vvvvv???v?v}ww}vv}ww?>>^-||^^|?v?>??vrr}zwrzv>^^>wuuuxxxxxxxxxz~<br></br>
`xxwww}}v??vvvvvv?vv?vrww}?}zzwwx}v>??+++^`.--->>vzxxuxvv?>?zuuuuxxxxxxxxx~<br></br>
-xxwwr}}}vvvvvvvv?vzrv}}}??wr}ruuw?v>>|~`..-~|>}^|rzuuuw?v??xuuuuxxxxxxxxx~<br></br>
-xzrr}}}}vvvvvvvv?v}zrww}wrv?>+?>++v?>>~````-`^+--??xuuwvvvvuuuuuuuuuuxxxx|<br></br>
-xzrr}}}}}}vvv??vvv>rxzrvw}??+++++^v>??>-`----^-`|+^?wrvvvvvwxuuuuuuxuxxxx|<br></br>
-xxrr}}}}}}v??>^>v??rrvv|^>?++>+++^>>??>`.---~|--+^^?v?vv?v?wzxuuuuxxxxxxu|<br></br>
-uuwrr}}}}}}v??++??vv}vv?-+^^+>++++^>?v^.`----``~>-?v?>v+?vvruuuuuuxxxxxuu|<br></br>
-xuxrr}}}}}}}v???v}??r??--~~~~+++^^^^|?---`--```||`-^>++|?>>?xxxxxxzzzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}}}}vvvv}}}rr}}vvvv}}vvv}}rr}}v}vvv????????????>>>vvvv??vvvvvvvvv?`<br></br>
-xxzzxxxxxxxwrrwzzzzzzwwwwwzzwwxxuuzwzxwvvvvvvvvvvvvv???vwzwr}rrrrrrrrrrr}-<br></br>
-xzzzzzxxxxzrrrrrrrrwwwrrrrwrwrwxwwv}rrr}vvvvvvvvvv?>>vwzwrvvvvvv}}}}}}}}}-<br></br>
`xxzzzzzzzzwrrrr}rrrrrrrrrwwrrwww}vwvvvv}}vvvvvv??>>?rzwrvvvvvvvvvv}}}}}}v-<br></br>
`xxzzzzzzzwwrrrrrwzwwwwwwwwwwwwwrr}rwr}v}}vvvv??>>>vwwrv??vvvvvvvv}rrrr}v}-<br></br>
`xxzzzzzzwwrrrwwwwwwwrrwwrwwwwwrrrrrr}rvvv}v??>>?vwwrv????vvvvvvvvv}}rrrrz~<br></br>
`xxzzxzzzwrr}wzzwwrwwwwwrrwwrrrrrrrrrvwvvvvv>>?}wwrv????????vvvv}rwzxxxxzz~<br></br>
`xxzwzxxzwrr}wzzzw}wwwwwwrr}vv}}v??v}v}zrvvvvrxxrvvvvvvvvv}}}}}rrrwwwwwwrw~<br></br>
-uxxzzxxxzwr}wwzzzrrrrrrrrvvv}xwvv>>???vvrrwr}zwr}}}}}}}}}}}r}vvvvvvv}rrrr~<br></br>
-uuuxxzxxxzwrw}wzzzwrrrrvv??vruuz>|v?>^|+????>?vv}}vvvvvvvv??vv?vvvvv}}}}r~<br></br>
-uuuuuuxxxxxzw}vrwz}rrrv?v?}rzuuuv^}v?>^|`-~-``-`-~|^>vvvvvv?vv?vvvv}}vvrw~<br></br>
-uyuyxwxxxxxxzwrv}r}}r}v??vv?zuuuxzuv>|~^|^-~-|||||^^?}vvvvvv}vvvvv}}}v}rr~<br></br>
-uyuuuzzxxuxxxzzw}}}vvvv>^?||wuuxzuz?+>``?>--|???}r?+???????v}v?vvv}}}}rrr~<br></br>
-uuuxzzzwzxuuxwzxw}}??vv+?}rwuuuzwzv?+`.->?~-^?}vvv?+>~---~~?}v?}}}vv}wzrw~<br></br>
-uuxzzzzzzzzxxwv}rv?????>?vr}}}wwwrr??+>???~-|????>>???++^~^}}}}v}v?vwzwzw`<br></br>
-uuxzzzzzzwwwzzr}v++???>??+>-`>}wzzwwxvrzrv~-^????>+>??+vv>?ww}}vrv}wrrzwv-<br></br>
-uxzzzzzzwwrrr}}}?~|^>??>|~|>?>vzwwwzwvvvrv-`>???>??>~~~>v??rrr}vxxxzwzz}v-<br></br>
-xxzzzzzwrwrwr}v?|-~~||^>>>>wz}}xxxuzv?v?>~-~|>?+>vvv?^-+>??zxw?vuuuxxrvv}~<br></br>
`zzzzzzzrrwzwrvv?|`-|~`-~^>?rxxzrvv>>?wzv`-||||^>^v}vv>^?>?vrzr??}wwrv??}}-<br></br>
`zzzzzzwrwzw}vv??>|^~``~^|-|?zzw}??^vzx}?~++++>+|~^vv??vv}}}}vv>^+>????vv}-<br></br>
`zzzzzwrwww}vv????+|```-|^^^>}}?>>+?vvv?>?>+>>>>>>>++vvvvvvvv>^^>??????vrr~<br></br>
`zxxxwrrww}??+>????>>+|++?}r?vv?~~++^+^+?vv?v?+>vv??>?????vvv?>>?????vwzzw~<br></br>
`zxxzrrrrr}v?|^>????v??>?>?}v}}?~^???>>??vvwwwv?}wrvvv>^>v}v}v??}}wwzxxxzw~<br></br>
`zxzrrrr}}vv?^|^>??????????vv}rv^+??????>?wxuuwrrvvrvv>~-^vv}rrzxxuuuxxxxz~<br></br>
`zxzrrr}vv>>^||||^?????v????vrw??v>+?vv??}xwv?>?}v?}?>?|-`>?zxxzxxuxxxxxxz~<br></br>
`xxwwr}vv>|~~|||||??~|+?>?vv}wr}zrvv}wzzz}?^|``|~>?????|||?}zxuzzxxxxxxxxx~<br></br>
-xxwwr}vv>~~~||^^^>?>~^^>?vzr}vxu}?}?vv}?^+|``~?-.^+???+^>^?wzzrzxxxxxxxxx~<br></br>
-zwrr}vvvv>~~^^++^|^>^>+>}www}>v?>?r}?|-```---??~|>~?v??>>+>v}rwwxxuuuxxxx|<br></br>
`zwrr}vvvvv>^>>+>??|?????}r}}?>>>>vv}?|~`.-|++?|~>>~?rvv????rwr}}xxxuuxxxx|<br></br>
`wwrr}vvvvv?>?>^>rrrwv??~|+v?^+>+>???+^|``+>>>+||?~^v}v}v?v?vv}}}wzxxxxxxx|<br></br>
`wwrr}vvvvvv??>^?}}wxzwv^~>|^+>>>>>|^^>^^>?>>+^~^+-?vvvvv}rwwxxxxzzwzxxxxu|<br></br>
`wwwrrvvvvvv}v>^vrr}zxzrv??^|>>??>++??v??>>>>>^`^~|+??>+>????zwwwwwrwzzzzx|<br></br>


            </div>,

            <div>

`}}}}}}}}r}vvv?vvvvvv}}vvvvvvvvvvvvvvvvvv????????????????>>>>>>>>>>>>>>>>>`<br></br>
-xxxzzxxxzzzr}rwzzzzwzzwwwwwwwwwwwwwrwwr}}}vvvvvvvvvvvv??????????>????????`<br></br>
-zzzzzzzxzwwrwwrrrwwwwwwrrrwwrrrwwwrrrrr}}}}}vvvvvv???>>>>>>>>>>>>>>>>>>>?`<br></br>
`zzzzzzzxzzzwr}}rrrrwwwrrwwwwrrrwwrrrrrrr}}}}}vv??>>>>>>>>>>>>>>>>>>>>>vrw~<br></br>
`zzzwwzzzxzwr}}rrrwwwwwwwwwwwrrrrrrrrrrrrrr}vvvv>>>>>>>>?v?>>>>>>>>>?vrw}?`<br></br>
`zzzwrrrwzwr}rwrrrwwwwrwwrrrwrrrrrrrrrrrrrr}vvvv?>>>>>>>vwv>>>>>>>?}wzw}vv-<br></br>
`zzwrwrrrr}}}rwrrrrrrrrrrrr}rrrrrrrr}rrrrrrrvvv??>>>>>>>vwr>>????wxxxzwwzx|<br></br>
`zzw}wwwrr}}}rrrrrrrrrrrrrr}vvv}rv?vvvr}rrrrv}}}v???????wxxrv}}wuuuz}zxuux~<br></br>
`xxwrrwwwwr}}rrrrrrrrrrrrv}}rzw}vv??vv?v}}}rvvrwr}vvvv}}xuxrr}}xuxwvrxxzrr~<br></br>
-uuzwwrwzwwrrrrrrrrrrrrr}vvvwxxxwvv}vv???v?v}?r}}vvv???vzwv??}zwwr}}ww}vvw|<br></br>
-xuuuwrrzzwwwwrrrrrrrrr}vv?vwuuuxxzzzxrv>>~|>???+^++++?v}v??vxwv}}}wxx}rrw|<br></br>
-uuyyxwrwzzwwwwrrrrrr}}v?v??wuuuuuuuxz?|.^?-^^>>^`+>>??}rrrwwxwwr}rzxzwzrz|<br></br>
-uuuuuxzwwzwwwwwwrrr}v?v?vvvzuuuuuuuuv?``+xvv||>>->???v}}}}}wxw}}rwwzzuxwz|<br></br>
`xuuuuuxxxzwww}}wrrrvv?vvrwzxuuuuuuuu}|>+-vrr?>?>~^|->??????wxrvwwr}}zuw}r-<br></br>
-uuuuuuuuxxxzw}v}wr}vv??}xxxzzzxuuuuuuv>^~vrvv?v>^|~^?vvvvvvzzzzwwr}}zzv}v`<br></br>
-uuxuuuzwxzxxzwr}wv}????zwv?>>rxuuuuuuu}vvz}vr?>~--~|vv}vvvrzzxzzwwrrz}??>.<br></br>
-uuxrxux}vwzxzw}v}?}v?vvrv???}zxuuuuxzxuxxzvv}v>-``~->vvvvv}xuuuxxxxxz}?>>`<br></br>
-xxz}wzxzvvwzzw}vv?v???rzzv?v}}rwuuuxzzxuz}vv}?^|^|~~-?}v}vrxuuuuuxxwv^^??`<br></br>
-zzzrrrrwzr}zwr}vv?v????~->wxxxxxuxzwwxww}}}}v+^^^|^|-`^v}rrwzxxxzw}?+^^>?`<br></br>
`zzz}rrvvrwww}vvv???v>??+>}xxxxuuuuxzuuxr}}}v+|^^||^|--`|v}}}vvv???+^++++v-<br></br>
`zzw}}r}}}}vv???v??>v??}rrrrwwzzxzxzzzw?}w}v^~|||~||-`---|+>?>^||^^+++++wz~<br></br>
`zzr}rrr}vv+^^>?????>^^?v}wwrr}vv}xwvv?+wzr?>>||^+^~-```--~||~~~~|^^+>?rux|<br></br>
`zzwvrrr}v?^|~|>???~`~+^>?vwww}vvrxzwvv}xw???v^|+>^--````---|||||^>??}xuxx|<br></br>
`www}rrr}vv+^|--|>?|-|>>+>?v}rrr}}wzzwrwv???v?~-~~~~--||-`^~-~+?v}wzxuuuxx|<br></br>
`wwr}rrrvv>|-```|?v?~~^>^>?vv}}v}r}}wzwv}rwwwv|^>>>>?>>|~~>>>?v}zzxxxxxxxx~<br></br>
`wwrrrr}v>~-````^?vv|-~||+}r}}}}}}vvvwzzxzww}?>?>???v?>+>?vrrww}rwwzxxxxxx~<br></br>
`wwrrrrvv>~~--~~--~|^--`>v}wrrrwwrr}v}rzzwzxv??r??+>>>>?v?vv}wwvrrrrwzxxxx|<br></br>
`rrrrr}vvv>~-~|^^~----``+?v}rrvwwr}v}??}v?vrrwx}wr^|+>>?vv??vvrr}wwwzzzzww~<br></br>
`rrrrrvvvvv|-|^^^?>^|~~--+??v}}}}}}?}v?v?>}}rzwvrw^~+>vwwrv}}}}vvwwwzzzzww~<br></br>
`rrrrrvvvv?|~^^|>vvv?+||~^>????v?vv?v+>>^^}}wr?v?v|^vrzzzw}wv>>?v}wwzzzzww~<br></br>
`rrrr}vvvvv>|~-`~??????>^|v?>>??>v?^^>>?vwzxuzwrv++rzxxuuxxuz}}vvv}}rwzzzw~<br></br>
`rrrr}vvvvv>?+-`^>>>>>??>?xzw?>+vxr?vrr}rwzzzxz}}?wzwzwwwwwwv^>?v????vrwww~<br></br>


            </div>,

            <div>

`}}}}v}}}rvvvvvvvvvvvv}vvvvvvvvvvvvv???vvvv??+>???vvvvvvvvvvvvvv?vv?>>>>>>`<br></br>
-xxxzwzxxzzwrrrwwwwzzzzzwwwwwwwwwwwwwrrwwww}v>?vvvrwwwrr}}}}}}}}vvv?>>>???`<br></br>
-zzzxzrrxzwwwwrrrrrzxxw}}}}}rrrrrrrrrwwrrrr}vv?????}}vvvvvvvvvvv??>+>>>>>?`<br></br>
`zwwzxzwxzwzzw}}}}}rrvvv}rrrrrrrrrrrrrrrrwr}vvv?>>+>?vvvvvvvvv???>++++?vrw~<br></br>
`zwrr}wzzuxzwrr}}}}}vv}wwrrrrrrrrrrrrrrrwr}vvvvv>>>>+??v}wv?????>>++>vzz}?.<br></br>
`www}}rrwzwrrrrrr}}rrwwwrrrrrrrrrrrr}rrrrvvvvvvv?+>>>>>>}ur>>>>>++?vrxxv>>`<br></br>
`rwwv}rrrrr}}rr}}vvv}rwwwrr}rrrr}}}}vrr}vvvvvvvvv>+>>>+|vww>+++^+vwwwwrwzz|<br></br>
`rwr}rrrrrrrrr}}}vvvv}}rr}w}r}}}rv??}}}v?vvvvvvv??+>>>++vrv+|^|^}wwwvwuuux~<br></br>
`ww}}rrrrrr}}}}}}}vvvvvv}v}rzzwr}}v?}v?>??vvvv???>^++^^^v}?|+>>wzxzvzuxzww~<br></br>
`wzr}}}}r}}vv}rrrr}vvvv}}vvvzxxzzwr}vv?>??>?v?v??>^+^^^>}v+^>rxuuxxuux}}wx|<br></br>
`zxxz}vv}}vvv}rrrr}vvv}r}v??wxxxzwrwrvvv>>-`^?vv?+++^^^vr?>?vwwwzxxuuuzuwz|<br></br>
`zxxzw}vv}vvvvv}}}}v}}vrvvv?rzzzwwrrwww| ~>~^>?v?^++^|+rrvrwwrrrrwwzxzxxrx|<br></br>
`zxzzzzwvvvvvvvvvvvvv?>vv}v?wwrrwrwxxwv+~`zxz?+??|^^||}rrwrrrrrv?vrwzzuzwx|<br></br>
`wzzzzwwwrvvvvvvvvvv??+?v}rwzzzwwwwuxw?zv.?uu}>?>`~|~vwrwwwwwwv?>>?v}xur}}-<br></br>
-zwzzzrrwww}vvvvvvvv?v>?}}}zzzzzzwwzxxv^`.?uzr+?^|^^+wwwwwwwrv>+>>>>?xzv??`<br></br>
`wwrzzrv?wzwwvvvvvvv>???rrrwzzzzzzwwzuu?++}x}r>|~^++>wwrrrr}??vvvvv??rv+>>`<br></br>
`ww}?wwr>+rzwz}v??+?>>??wwwwwwwzzzzzzxuuzzxxzzv|`-~|~?r}}}v?}}}}rrr}vv+||^`<br></br>
`rrr?v}ww?^}zwz}?>^>>+?rrr?^>>?vrzzxxxzzxuuuuuw>-~|^|~?}}}v}}}}}rrv?+|~||~.<br></br>
`rrr?v??rzv?zzz}?>^+^+??|.~}zzzzzzzxxxz}wxxuuxv+~|^^|-`+rrrrrr}}v?>>|^^^|>.<br></br>
`rr}?vv??vrwzwrvv?^+>~>?^?wwzxuuuxxxxxxzzxxxx?>++>>+|--`|v}r}}v?++++++++>}-<br></br>
`rwv?vvv?????>>???>>v+>wzzwwwzxuuuxxxzr?}xxx?+++++>>~`---|+>?>+^^^^++^++rx~<br></br>
`zz}vrrv????>++>>>>>>+|?}rwwwwwzzzzwvvv?wzw?>??+||||-```--~||~~|^^+++++vuu|<br></br>
`zzw}r}}????>>>+++^-~^+|+?v}}rrwwwwwrvv}zwv>????>^~--````---||||||^^>?wxuu|<br></br>
`wwr}rrrv???v?+|-~>~|^++|>??}rwwwrrrwrrw}v?????>+|--``~^|-`--~+>??vrzxuuxx|<br></br>
`}r}vrrrr}vvv?-.^vr?~^^+|?r}rr}v}rr}vvvv}r}rrv??vvvvvv?>^~~|++v}rwxzzwwzxx~<br></br>
`}r}}}}r}}}vv>|`+?}r^~|+??v}}}}}vvv}>v>?v}}r}rr}}rwwwwwww}vv}vv}}vvrrrwwwz~<br></br>
`}r}}rr}v}v}v?+~--~^|``>vv}rrrrwwwrrvv????}wwv}wv}rrwwrwzrrrrrrr}}vv}r}rrw~<br></br>
`}r}}rr}}}}}??>+||~----^?vrrrrrrrrvv}}vrr?vzz}w}wrvrrr}zxz}rrrrrrrrr}}}}}}~<br></br>
`}r}rr}}}}}v????^||||||^>>???vvvv}v?}vvvvvzwrv?vrr?}}vrxxz}zrrrrrrrrrrrr}}-<br></br>
`}rr}}vvvv?^+>>?>^||||+>??>>?????????>>?>?r}w?~++?+?vrxuxzwxw}}}rrrrrrrrrr~<br></br>
`}rr}vvvvvv^|^^+++^||||^++}v>>??>vv>>?>vvrzxzzwv>~^vwzxxxzzurv}}}}}}rrrrrr~<br></br>
`}rr}}vvvvv?^>>+^^^^^^||~>uuw?|~?xz??}rvrwzzzxxw}?wzwwzzzzzzv+??vvvvvv}}rr~<br></br>


            </div>,

            <div>

.??>>>?>??>>++>>>++|???vvvvvvv??v}}v>++vvv>+^``-~+??????>++>>>>>???+|>????`<br></br>
`vvvv?>v}v}v?+>+>+>?vvvrwwwwwwwzuuuuzrwxxwr?+~-~~^???????vvvvv??????????vr~<br></br>
`vv}rv>>rww}?+^~+^|?vvv>>?vvrrwwwxuxuuuuuuuv||^^|-~????>>vzwrrr}}vv}}??vv}-<br></br>
`}vv}ww?vwwr??~~||^+>--+?v}rrwwwwwuzwxuuuux}|~|||-`~??????zuuuxzwv???v??vv-<br></br>
`wwrrrv}}r}v??+~^|^>|~?wxzrwwwwwzwzuzxuuuu}?^+^||-``->v???}xzzzzv????v}???`<br></br>
`wwwr?>???+^>?++++>??vvvvwwrzzwwzzwwrxuuz??????>^--~~-+>+^+}rrrv?????wuwv?`<br></br>
`rwwrv???>|^^|^+||^^^>vvvv}}wzzzwrrvruuz?||++^+?>~-~~-`~~~~+????????}xuuxz~<br></br>
`rwzwwr}v>^^||||~~^^+^^????vrwwzzwrvrxz>-~^|~-~~~~~~~~---~~~>>>??v}zuuuuux~<br></br>
`wzzwrr}}v?>-`|>^~~||^|~^>>?vrwzzxwr??>`-~|~--`---~|~~-~~~~~?vv}wzxuuuxxxx~<br></br>
`}wwwr}}}vv?-|?>+^~~~~||~~||^?}rrzzr>~``---``````-~~~~-|+??|?zuuxxxxuuuuxx|<br></br>
`}}}rrr}}v>?~+>??+^|-~^>>^|||+vv}}}}>.``````````--~||~~+vv????}xxxxxxxxuuu|<br></br>
`}}}v}}}}v??^.`~^++^|-~++++^||?vvvvv?~~-``-~-```-~~~~~^>v??>+>>vzuxxxxxxuu|<br></br>
`}rr}}}}}vv>~---`----``~^+++^^^?vvvwv>>+|^+^^|--~~--|??v?>>>>>???}zxxxxxxu|<br></br>
`rr}r}vvvvv?|~~~~~----``-|+++++?vvvwv>>>+^|^+^----`|?vvv??>?v??????}zxxxxx|<br></br>
`}}}}vvvvv?^~~~~~~~~~~~-``|^+++?vvv}r?>+^^|^+|```|`>vvvvv?>??>>>?????rxxxx|<br></br>
`vv?vv??v?~`-~----~~~~~~~--~^>??vr}vv?v?>+++|``-|?>?vvv??>++>^^>^>>>??vwxx|<br></br>
`?+~~~~~---~~~~~~-----------^>??vrrw}v?v???>+|^+||+?vvv+++^|>>|>??>>+>??vw|<br></br>
.---~-~--~~~~~~~~~~~~~---~~--^?}}}rwrwv???vvv}}v^|^+>?>~~||~|+^???v???????`<br></br>
                  .--~~~~~~~~~~~~~~~~~~~~~~~|++^vzvrrrrrwv??vvvvv?^+^|~|-~||~--|^+??vvvvvvvv`<br></br>
.-~~~~|~~~~~~~~~~~~~~~~~~~~~^}wvrrrrww}wrv????v?vvv?^^^|^^^^|-|||+?vvvvvvv-<br></br>
.-~~||^|||||~~~~~~~~~~~~~~~~>v?vr}rwzw}v}r??>?????vv?+^^^^^^^~-|>>????v}zz~<br></br>
.+>???>||^|~~~~~-~~~~~~~~~|??+|?}^vv}z}}vvwv?}v?+^^>??^^^^^|+^|>?>>>>>?vwx|<br></br>
.??vvv>~|||~~~~~---~~~|^+>+^~-^?+~^|>wwrr}v}w}zw}?>|||||||~-^>??^^^^+>?vrz~<br></br>
.>>?vvv?>^||>^++^^^++>>+^||^>vrv>^^|+}rr}}ww}v}rrv?>|-``---~|+>v?>vv}}}vvz|<br></br>
.+++>?v}r}?+??++^^^^||~~^?wwxxww}??^+?vrwrr}}r}wwrv>?>>|---^?v??wv}}?????w|<br></br>
.??>>??v}r}}}v+-~||||^^?wwwwrzzwv??v}??v}rrrrr}rzw}>????>+^>v?>?}???>????w|<br></br>
`}r}vvvvv}rrr}?~|^+^^+>vrrzxxrrrr}}rzr???v}rrwrrzwrv?????????+^v>+>??????z|<br></br>
`}rvv}}}}}rr}}v>-|>>>?vrwzzzr}}rrwr}zz??vvvzxr}}zzr???????????vv++>?????}w-<br></br>
`}}vvv}}}}rr}}rw|~????vrrwz}?v}}rwv}xw??vvwuu}??wzr>??????????r??vv???>vwv`<br></br>
`}}vvvv}}}vv}rzxv++???vrrrw???v}}v?wzr?>vwuuuv?>??>|>????????v??vv}vv??r?v`<br></br>
`}vvvvvvvvvvvzuuwwr}v??vrr}?vrrwrr}rv}rvruuuxxz>+>?|^^+>????}v??v}}}}}zzvv-<br></br>
`}}}vvvvvvvvrxxxzzxuuxzzzzv?v}rwrzxzwzxxwzxzxwr>+>??~~~~+>??wv???vvvvvwwrr~<br></br>


            </div>,

            <div>

.------------|++^^|||+??v?????^^>??>++++>+```-~~~|^+++^^^^^^^^^^++^^^vvvvv-<br></br>
.~~~~~~~~~~~~|^^^^^^^||>vvvvvvvvrr}}v??v}?+~|^++++^||||||>>>>>>>+>>v}rwwwz|<br></br>
.~~||||||||||||||~~~~~``^?vvvvvvvzzwrrrrwwr?~~|||~~~~~~~~?r}vv?vv}wxurrrwz|<br></br>
.>>>>?+||||~|||||~-~~-~^^>?vvvvvvvzwwwwwzzw}~``-|~-~~~~~-|wxxuuuuuuuuxv}rw|<br></br>
`rwww}+|||~~~~~||~----~+??>v}vvvvv}zwzzzzzr?|^|-||~~~||~~-?xxuuuuuuuuuw?v}~<br></br>
`wwzw+~|||~---~~||~-----~?>?rrvv}wvrwzzzwv??v}r?^||~|~~~~-|wzuuuuuuxuxur}}-<br></br>
`wzzwv+|||~----~||||--~~--^+vww}}zw?wwzwv|-|^+??v^~~|~~||~`vzuuuuuuuuuuu}r~<br></br>
`wzzzxwv>~-------~~~----~~--??rwwzzv>}r?----~~~~^^~~~~~|||~>zuuuuuuuuuuuxr~<br></br>
`zzzzzzwr>>|````````----~|~-|?v}wzwv`^>`---------~~~~~~^+>>^ruuuuuuuuuuuux~<br></br>
`vwzxzwwrw}r~````-```---~~~~->?v}wr}|`----`````````````^?}r^vuxuuuuuuuuxxx|<br></br>
`???wzwwrr?r^`-----```--~~~--^v}}}}r>--````````````````>wxzrrzxxuuuuuxxxuu|<br></br>
`??vvwwwwwvr>`----------~~~~-`>vrrrw?`````-```````````+vz}vv?rzzxxxxxxxxxu|<br></br>
.?rwrwzzzzr>``-------~~-~~~~~-~?v}rrv~~----````````.|}zzwvvv}rzzxxxxxxxxxx|<br></br>
.}zzzzzxxzzv``-``----~~--~~~~--+v}}r?~~-----``````.+zzzwrrv}z}wzxxxxxxxxxx~<br></br>
.vwwrrzxxxr|--````--~~---~-````~?rrrv~~~---`````-+|vzzzzz}v}zvwzzxxxxzxxxx~<br></br>
`??vrr}rr}^`~~-----~~---~~-``-||>rrwv~|^^|~-`-|>vrv}zzzrwrv}w}vzvwzzxxxxxx~<br></br>
`>||^||----~~~~~~~~~---~~~~--~|^?vrr}?~~~|~~-~vvvvvzzxxv}rvvrw?zzww}}wwwxx~<br></br>
.||^|||-`--~~~|^^|~~~~~~~|||~~~}vvrwvwv|||||||?vvvvvrw}?vvvv?}vzzzzzwzw}wz~<br></br>
.~^^||^|---~|^^^||||||||^^+^~~vw>vrrv?w?^^^+^>vvvvv???????????v}xxxxxxxxzz~<br></br>
.~^^^^^|~-~||||~~||^^||^^^^^^vz>+?rr}rvr?^+++?vvvvv????????????vrrwzxxxxxx|<br></br>
.|^^^^^||~---~~||^|||^^^^^^+vu?+??rrrw}vr}?^^?vvvvv????????????vrvvv}rwzxx|<br></br>
.|^^^+++^^|-~||^||^^^^^^^+?wxv?vr|?v}xw}v}wv>}rvvvvv???????????vv????vv}}r~<br></br>
.|^^^++++++^~~~~||^^++?vrxzv??}u?-+|?uxwrrvvrrxzwr}?>>>>>>^~^?rr++++++>>?v-<br></br>
.^^^+>>>>>++^|??vvrwzzxzrv??vzurv+?^?uuxwrww}vvwzv>|-------~--^r?|+++++++r|<br></br>
.+++>>>?vv>^++?}}}}}v????vrzxzwxv+?+vxxuuzwwww}zxr?-`-~~~~~--~|+r~+++++++}|<br></br>
.>>>>???vv?????>??vvv}rwzxzwwzxz?>??xuuuuuuxxz}ruzv`-~~~~~~-~~^+v~+++++++}^<br></br>
`}}}vvvvvvvvvvv??vrwwwwwwzzxuz}vvv}}uuuuuuuuxxr}uzr>-~~~~~~~~~^v^~++++++>x|<br></br>
`}r}vvvvvvvvvvvv?vzzxxuuuuuuwvv}rrr}uuzuuuuuxxwvuxr|-~~~~~~~~-??~|++++++rz`<br></br>
`}r}vvvvvvvvvvvzrvzxxxxxuuuzvv}rrwvruurzuxuuuxwvuxr--~~~~~~~~|v|~^++++^?zv`<br></br>
`}}vvvvvvvvvv?wuv}wuuuxxxxxvvvrrr}vxuuwvxuuuxzzrxx?`-~~~~~~~~v^~|^++++?wv}-<br></br>
`}}vvvvvvvvv?}zzvvwuuuuuuuz?v}}rr}wuuuuvvxuxwzr}}rv--~~~~~~~v>-~|++++>xxv}-<br></br>
`}vvvvvvvvv?}}rr}vwzuuuuuur?vvvrrzxxxxxzvwxwx}vvvv?>--~~~~-^z>-~|^+++>wxr}-<br></br>


            </div>,

            <div>

.|^^+>|```-+>???^|~`~^+>>~`````````````````....``?vvvv}}}}}rr}}}}}}}}vvvvv-<br></br>
.~^^+>+~-`|>?vrw?^|~-`-~-``--------`````-````-||^vwwwzxxxxxxxxzzxxxzzzrrwz|<br></br>
.``-~~|^^~~|>}zzw?>+^~-------------------````-~~vrrwwzzzzzzzzzzzzzzzzxzrrz|<br></br>
.````-~|^^|~^}wrrv^>???>^|~~~-----------`---```+r}vv}rwzzxxzzzzzzzzxxxxwrw|<br></br>
.````--~|||~|v}}v}^-~^>???>>++++^^^|||||~----~^>}rvvvvvvrwzzzzzzzzzzzww}}r~<br></br>
 `````-~~~~--?}rrrv~-~~~|^^^^^^^^^^++^^^|-`|>>>>?r}vvvvvv}}vvrwvrwrrwwwrrr~<br></br>
 `-------~~-``+v}rrv^^>+^^||~~~~---~~~---`->>>>>>???vvvvvrrrrrr}rrrrrwwwrw~<br></br>
 `---------~^|-^?v}rv++?w}??>>>>>++^^^^+>>^+>>>>?>>>??vvv}rrrrrrrrrrrrrrrr~<br></br>
 `---------~+>~`-?vvrv+ruxz}?>>>?>>>>>>+>+|^>>>>???vvvvvvv}}}rrrrrrrrrr}}r~<br></br>
 `---------~>^--^|>v}r}zxzxuxr?>+>>>>>>>>?>????????vvvvvvvvvvv}}}}}}}}}vv}-<br></br>
 `---------+>|~|>^+v}}}rvv}rzuuxw}??????????vv}}vvvvvvvvvvvvvvvvvvvvvvvv}}-<br></br>
.`--------|>^~~>>^???vvvvvvvv}rzuuxw}v??????}v}}}vvvvv}vvvvvvvvvvvvvvvv}}r-<br></br>
.--~~~~~-|>+~~^?+>???zv>?vv??}}}}rzxuxzwr}vv}}}}v}v}vv}}}}vvvvvvvvvvvvv}}}-<br></br>
.~~--~~~^??|~|?>+???wuzv??v}vvzzwr}}}rwzxuxw}}}}v}rv^^>>??v}rr}v?vvvvvvvvv`<br></br>
.||~~~->??^||??+>?vrux}rr}?v}vvrwxxzwwr}}}}}v}}}v}rv+^++^^++>v}}}vvvvvvvvv`<br></br>
.||||~|>+|||>?^+?vwuxr}rxurv}r}r}rzxxxxxzzzzwwr}vrrv>^++++^^|+r}?????vvvvv`<br></br>
.||^^^^^~~~>?++?}xuwrrrxuzz}}}r}r}wxxzzzzzzzrxxwvrr}?^^^++++^>w}^^>???>???`<br></br>
.||^^|^^~~|^^>}uuz}}}wuuzxuw}}}}}}rxxxxxxxxxzwzwwwwv?||^^^^^|>wv^+???>>>>>`<br></br>
.|||||+>^^^>vrxx}v}rzuzzxuuuw}}}rrrwxuuuuuuuxxwwuxv^`--~~~-~~~???^++>>++>>`<br></br>
.~|^^>>>?>?r}v??}rzuzwzxuuuuuw}}rrrrxuuuuxxxzzwvxxw>`-~~~~~~~-+>r+++++++>>`<br></br>
.^>?????>>>>???vwuxzzxuuuuuuuuz}}}r}xuuuxzuzzzwvxuv-`-~-~~~~~->?r+++++++++.<br></br>
.>>???vv?????v}}vrzxuuuuuuuuuuux}v}}xuuxwxxzzzzvww?v|-~~~~~~-~?}v^++++++++.<br></br>
.>???v}}vvvvvv?v}wxuuuuuuuuuuuuuzvvvwzxzwxzzzzz}w}?+--~~~~~~-+}r+^++++++++.<br></br>
`vvv}}}vvvvvvvvvvwxxxuuuuuuuuuuuzzr?v}zzxzzwwrw}w}?>--~~~~--+}w>|+++++++++.<br></br>
`}}}}}}vvvvvvvv?vwxxxxxxxxuuuuuxzuwwwrzuuxzwzrrrrv?|`-~~~-|?v?v|^+++++++>>`<br></br>
`}}}}}}vvvvvvvvvw}uuuuuuuuuuuuuxzuwzxzxxxzrrr?vvv++`-~~~~~v>~-v?++++++++?v~<br></br>
`}rrrr}vvvvvvvvv?}uuuuuuuuuuuuyuzuxzzzzzzwrzvvvv?++`-~~~~^v|-~v}++++++++?w-<br></br>
`}rrr}}vvvvvvvv^>vvvxuzuuuxuuuzxxxzzzwzwwrrw}vvv?+^--~~~~>v~~^rv++++++++vx~<br></br>
`}rr}}}vvvvvvv??v?>+???}w}vrw}vv}vv?rvvv}}rr}v??>++-~~~~~??-~?w+++++++++vz~<br></br>
`}rr}}}vvvvvvv?vvv}v??????vvvv}}}}?vv>>>v}}????>+>+-~~~-|v+-+r?^++++++++>z~<br></br>
`}rr}}}vvvvvv?+>>?vvvvvvvv?vvvvvvv?v???????>+++>>?|~~~~|??-+}?^+++++++++?u|<br></br>
`rrr}}}}vvvv?+~``>v?vvvvvvvvvvvvvv?v?>>>??>>>>>>>>~-~~+??-~}>^+++++++++^vx~<br></br>


            </div>,

            <div>

.+???v^```------`----------``````````````-----~+??????????v}}v??vvvvvvvvv}-<br></br>
.~?v}r?||~~~~^}r?+|~~~~~~~~~~~--~~~~~~~~-~|^^>rrr}vvvvvvvv}}wrv}rrrrrrrrwz|<br></br>
.``+>|>??+^|~}yyyuwv>|~----~~~--~~~~~~~~-+??vvv}}rrrr}}}}v}}r}rrrrrrrwwwww~<br></br>
.````-^>??>+|wu}?vwxuxwv?>+^||~-~~------|vvvvvvvvvvvv}}rrrrrrrrrrrrrrwwwwz|<br></br>
 `----~|^+>>>vwv+++??vrwzxuuxzwr}}vvv???vvvv???vvvv??vvv}}}}rrrrrrrrrrrrrw~<br></br>
.------||||>}}rrr}?>?>++>>??vv}rrwwwwwrrv>>??????????vvvvvvvvvv}rrrrrrrrrw~<br></br>
.-----~~||||vv}}}rr}}rv??>>+++^||||||^^+>+>??????????vvvvvvvvvvvvv}}v}rrrw~<br></br>
 -----~~||~~r}vvvvv}}vrzxxzwr}vvvvvvvv}rwrv???????????vvvvvvvvvvvvvvvvv}}r~<br></br>
 `---~~~~~~~zx>+>v??vv?wrrrwwwwrrrrrrr}}vv????????????vvvvvvvvvvvvvvvvvvv}~<br></br>
 `--~~~~~~->u}^^rv?rr??wuxxxzw}}vvvvvvrrwzrrrvv???????vvvvvvvvvvvvvvvvvvvv`<br></br>
.-~~~~~~~~~rx>^?xvrxuxvwuxxxuuuuxxxxxuuuuuuv}wwrr}}vvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.-~~~~~~~-?u?|^wwvzxxuxxxxxxxxxxuuuuuuuxxuuzwwrrrrwwwrxw}v?vvvvvvvvvvvvvvv`<br></br>
.-~~~~~~-+xr||?x?rxxxxxxxxxxxxxxxuuuuxxxuuuuuuuz}wrwrvxxxzw}vvvvvvvvvvvvvv`<br></br>
.|~---~~>xw^|>zvvwxxxxxxxxxxxxuuuuuxxuxuuuuuuuuuwxxv~-|+?vwzzwrvvvvvvvvvvv`<br></br>
.||||~-vxr^|^r}v}zxxxxxxxxxuuxwuuxuuuuuuuuuuuuuuwxxr>`~~~~|+>vwww}vvvvvvvv`<br></br>
.||^^++v?|||vz?}wxxxxxxxuuuuuuvrxxxuuuuuuuuuuuuuwxxw+-~~||~~~^uz??v??vvvvv`<br></br>
.|||^???>+|vxvvwxxxxxuuuuuuuxuz?vxxuuuuuuuuuuuuuruxz>-~~~~~|~+yx~~>?>?>>??`<br></br>
.|||||+????v?vzxxxuuuuuuuuxxxxuw?vzuuuuuuuuuuuuxruzx?-~~~~~|~+u?~^>??>>>>>`<br></br>
.|||~|????????wuuuuuuuuxxxxuuuuuuwwuxuuuuuxxxxxwzuw?`~~~|||||^v~|+>>>>>>>?`<br></br>
.~||+??????????}xxxxxxxxuuuuuuuuuuuuuuuuuzwz}rrvxxw>-~||||||~-v+|>>>>>>>??`<br></br>
.+????vv?????vv?vxxxxxuuuuuuuxuuuuuuuuuuxrxv}}vvvr>-~~||~~~---v?|>>>>>????`<br></br>
.???v}}}v??vvv???ruuuuuuuuuuxuuuuuuuuuuzvzrv}vvvv?-~~|||~~~---z?|>>>>>????`<br></br>
.?v}}}r}vvvv??????zuuxuuuuuuuuuuuxxxzzz}vzv}v?v}}^`~|||~~~~--?y?|+>>>>????`<br></br>
`}rrrrr}vv????vv??rwzxuuuuuuuuuurwzrvv}}r}vv???}}|`~~~~~~~--?yr~|+>>>>????`<br></br>
`rrrrrrvvv?vvvv?}xzwwwwzxxxxxxxw}urv}}zuuzvvv>?}v~-~~~~~~~^rx?|~^+>>>>????`<br></br>
`rrrrr}vvvvvvv?vuxuuuuxxxxxxxxxwruv}}}zxwv????vv?--~~~~~~|xv|~^^>>>>>>>>vw~<br></br>
`rrrrr}vvvvvv?vvvxuuxxuxuuuuuuuu}urv}}r}}}}vvv}v?`-~~---~>u+|>>>>>>>>>>>vu-<br></br>
`}rrrr}vv??vv??`~v?+wzvxuzrxxzvr}}wzwr}}}}}}}}}v>`~~~----?x|+>>>>>>>>>++>z|<br></br>
`rrrrrrv????vv?----`||`>?+->?^`~^~-^>??v}rr}}}}}|`~~~~---}r~+>>>>>>>+^^^+w^<br></br>
`rwrrrr}v?vvvv?-~~~~~~~~-~~~~-~~~||||~~||^++>>>>-~~~~~~-|u?-^++++++^^^^^+w|<br></br>
`wwrrrr}vvvvvv?-~~~~~~~~~~||~~~|||||||||||~~~~~~||||||~^rr-~^^^^^^^^^^^^?u|<br></br>
                  `wwrrrrrvvvvvv?~~~~~~~~~~|||~~~~~~~~~~~~~~~~~~~~~~~~~|?zr|~|||||||^^^^^^rx~<br></br>


            </div>,

            <div>

`}}rrr}}}}rrrrrrrrr}}}?}???????v-~~~~~||||~+v++>??????>++++++?v??>??????vv`<br></br>
-xuuuuuuuuuuuuuuuuuuuzrxvr}}rwv>|+^^^^^^^^^}z>>?vvvvvv>>????rx}v??v}}}}}}r~<br></br>
-uuxxxuuuuuuuuuxxxxxwwzzww}}ww>~^^^^^^^^^^>u}+>??vvvvv+>???wwv???v}rrrwrr}-<br></br>
`wzzxuuuuuuuuz}xwrrrrrwwrv?v}r?~^^^^^^^^^?xw>+>??vvvv?~^?}zr???vv}}rrrrwwz~<br></br>
`}wzzxxxxxxxz}zxvwwzxuxwrr?v}}>~^^^^^^^+vzv++>???vvvv^|?zwv???vv}}rrrrrrww~<br></br>
`zxxxxxxuuxxxwux}rwzxzw}}vvv}}>-^^^^^^^vx>^>?????vvv>~vw}???vv}rr}rrrrrrrw~<br></br>
-xxxxxxuuuxuyzxuwrwwwwrrrrrrrv^-^^^^^^^rw+?????????>+|?}?vvvv}rr}}}}}}}}}r~<br></br>
.vxwwuxrwxuzwxzzxzwwwwrrwwwrrr^~^^^^^^^z}+?????>+^~|>||wv???v}}}}}}}}}}}}r~<br></br>
.|>|^v?-^v?+^>>>>?v}}}rrwwwwwv~^+^^^^^>u?+>>>^|~~~~~>|~rzvvvv}}}}}}rr}}}}r~<br></br>
.|~||+^|++^+>>>>>>>>>>???????+^>?>~--`??`-~--~~~~~~~+^|wzvrrr}}}}}}rrr}}}r~<br></br>
.^^^^++>>>>>???????????>>>>>>>????>-`>v-`~~~~~--~~~~^+>zwvrwzzw}}}}rrrr}}r~<br></br>
.^^++^+>??>>>?????????????>>???>>>?vvv~`-~~~~~--~~~~|?}rvvrzzzzzzwwwrrr}}r~<br></br>
.|^>>^+????>>????????????????>+^+?v?^-``-~~~~~~~~~~~^wr+?}wzzzzzzzzzwwrr}r~<br></br>
.^+>+^+????>>????????>>>??>+||>v?|----~~-~~~~~~--~|?rr+~?wzzzzzzzzzzzzwwrr-<br></br>
.>^^^>>????>>??????>>>>>>+|+?}}>`-~~~~|~~~~---~^>???^>++}zzzzzzzzzzzzzzzwz~<br></br>
.>+^+>?????>>??????>>>>???}w}?-`-~~~~~~~||^>>???>^|~|???rzzzzzzzzzzzzxxxxu|<br></br>
.||^>??????>>>>>>>>>?vrwr}?^--~~~~|+>?vv}v>+|||~~|^+>v??wzzzzzzxxxuxxxwwww~<br></br>
.~~^>??>>>>>>>>>>>vrzwv>^~--~~~^>?}vv?>+^^|||^+++>>>>v??rwwzxxuxxzzxxzr}r}-<br></br>
.~~^+>>?>>>>>>>??rrv^----~~~|>vv?>+^|^^+>>>>>>?>>>>>>???rxuxxxzxxwrwwrrwzw-<br></br>
.~|^|^^>>>>>>>?r}?|~~~-~~~|?vv>^^^^>>>>>?????>>>>++^>??}uxzz}wwzwwwwwwwzzz~<br></br>
.~|||||^+>>>?r}??~-~|^^~~>vv>|^^+>??????????>>+^^^^+?vrr}rwwrwwwwwwwzzzxxx|<br></br>
.~|||||||>vwrv??^-~||+>+v}>^^^+>?????>>>>>>>++^|^^>vr}}}}rwwrrwwwwwwzxxxxx~<br></br>
.~||||^>}zw?????|||||~>zw+|^^>???????>>>>>>>>>>??v}vv}v}}rrrrrrwwwwwzxxxxx~<br></br>
.|+??}zz}?>>??v>~|||~?wv>>++>????>???vvvvvvv}}rr}v>^??>}vvv}rrwwwwwwwxxxxx|<br></br>
`}zxz}?++>?vv?>|~~~|?r?~|+?????>?vvvv?vvv??vv???v?+++>?rvvvv}wwwwwwwwzxxuz`<br></br>
`v?>+||+??vvvv?^|~^vw?||^>>?v??vv????v}}}rr}}}??>^+>>>vwvvvv}wwwwwwwwzxux>.<br></br>
`+||^+>?vvvvvvv?>}xw>~|^>????vrv^>??}}}}rrrrrr}v?>>>>>}wvvvvrwwwwwwwwzxzv~.<br></br>
`?+>>>>?vvvvvvv}zxrv?>^+>>?>vr???}r}r}}}}}}}}}}}}?>++>rrvvvvrwwwwwwzzr?^v^.<br></br>
`?>???>?vvvvv}zurvvvvrv>>>>?w>??}rr}}}}}}}}}}}}}rr}v>>}}vvvvwwzzwwrv>~`-v?.<br></br>
`?>??>>?vvvwxxz}??vv}rrv?>>w?^?v}rr}}}}}}}}}}}}}}}r}v?>vvvvv}}v?>^|-```->v.<br></br>
`?>?>>>?rzxwr}}}vv}}rrrrv>rv?+?}rrrrrr}}}}}}}}}}}}}}r}?+``--````````````|w-<br></br>
`?>?v}rrwr}v}}}rr}}}v}}}?vr>+>?vvvv}}vvvvvvvvvvvvvvv}}}v^....```````````~}~<br></br>


            </div>,

            <div>

.|+vr???vvvvvvvvvvvv????>>>???vwr}v?????????????}}}rr}>>???????????vvvvvvv-<br></br>
.?xuvv}rwwwwwwwwrwwrr}}rrrrwzuuxxw}}}}}}}}}}}}}}zuuuuu??vvvvvvvvvvv}rrrrrw~<br></br>
`xzvv}rrrwwrrwzzzxxuuuuyyyyyyzv}}}}}}}}}}}}}}}}}wuuuux|?vvvvvvvvvvvvv}rrrw~<br></br>
`r?vrrwrwr}rzw}rrwwzwwwzwr}wr}}}}}}}}}}}}}}}}}}}}xuuur`?vvvvvvvvvvvvvvv}rw~<br></br>
`?vrwwrwrrwww}wxuuuuuuuuuxrvvv}}}}}}}}}}}}}}}}}}}zuux~.+vvvvvvvvvvvvvvvv}r~<br></br>
`vrwwww}wwv}wxuuuuuuuuuuuuuzrvv}}}}}}}}}}}}}}}}}}wux^..|vvvvvvvvvvvvvvvvvv-<br></br>
`rwwww}wzv}zxuuuuuuuuuuuuuuuxwvv}}}}}}}}}}}}}}}}}}?^^..~vvvvvvvvvvvvvvvvvv`<br></br>
`rwwwrwrvwxuuuuuuuuuuuuuuuuuuxzvv}}}}}}}v}}}}}}?^` .?..-rvvvvvvvvvvvvvvvvv-<br></br>
`rwwrrw?wuuuuuuuuuuuuuuuuuuuuuuxvvv}}}}}vvv?>|`.  ..>~..zzvvvvvvvvvvvvvvvv-<br></br>
                  `rwwrzrvzuuuuuuuuuuuuuuuuuuuuuuuxr+-~~~~`...      . ++..rxzwvvvvvvvvvvvvvv-<br></br>
`rw}}}}wxuuuuuuuuuuuuuuuuuuuuuuuuuz|              ..~?..vzzzzzrvvvvvvvvvvv-<br></br>
`rrr}vzuuuuuuuuuuuuuuuuuuuuuuuuuuuxz^             ..`?..vzzzzzzzzwrvvvvvvv-<br></br>
`r}wxvzuuuuuuuuuuuuuuuuuuuuuuuw?+~`...             ..v` vxzzzzzzxxuuxwv?vv-<br></br>
`}rx}rxuuuuuuuuuuuuuuuuuuuuw?~.  ......            ..?- vxzzzzzxxxuuuuuwv?`<br></br>
`zvvrxuuuuuuuuuuuuuuuuuuur+. ...........           ..>~ vxzzzzzxxxxxxxxxxw-<br></br>
`zw}wuuuuuuuuuuuuuuuuuuw^. ..............          ..+|.}xzzzzxxxxxxxxxxxz~<br></br>
`}?rzuuuuuuuuuuuuuuuuz>. ..................        ..|~.rxzzzxxxxxxxxxxxxw~<br></br>
                  `v?vxuuxuuuuuuuuuuuuv` .....................        .~~.zxzzzxxxuxxxxxxzww~<br></br>
                  `vvvvzuuuuuuuuuuuuuv` .......................       .`-`xxzzzxxxxxxxxzwrrr~<br></br>
`vvvv?ruuuuuuuuuux^>?`........................      .``|uxxzzxxxxxxxxzwrrr~<br></br>
`vvvvv?vwxuuuuuux| .>v~........................     ...>uxzzzxxxuuxxxzwrrr~<br></br>
`vvvvvv?v}zxuuuu> ...|v^........................   ....>zxzzzxxxxuxxxzrrrr~<br></br>
`vvvvvv?ww}}zuuw......`?>........................  ....?vzzzzxxxxuxxxzwrrr~<br></br>
`vvvvvv?zzzw}}z+ .......^>-....................... ...`w>>?vwuxxxuxxxxzrrr~<br></br>
                  `vvvvvvvxxxxxw?` ........`+|...................... ...^z?+|+vuuuxxxxxxzwwr~<br></br>
                  `vvvvvvvxxxuuu?^``. .......-|`....................... ?z????ruuuxxxxxxxxzw~<br></br>
`vvvvvvvuuuuuuwv?v>|. .......-`...................... vx?v??zxuuxxxxxxxxxz~<br></br>
`vvvvvv}uuuuuuuw?vvw}~.........-`.....................zrvvv?zxuuuxxxxxxxxz~<br></br>
`vvvvvv}uuuuuuuxvvvvwu?.  ......``...................`rvvvvvxuuuuuuuxxxxxx~<br></br>
`vvvvvvvuuuuuuux?vvvvv?>`.. ..........................|}??v?xuuuuuuuuxxxxx|<br></br>
`vvvvvvvuuuuuuuuvvv?vv?}?~-. ..........................++?vvvzxuuuuuuuxxxx|<br></br>
`vvvvvv?xuuuuuuurvv>>???~`~-.   ........................~?+>}wzzxxxzzzzzzz~<br></br>


            </div>,

            <div>

`}rrrrrrrrrr}}vvvvvv???????????????????????????vr}r}v. ~vvvvvvvv??????????`<br></br>
-uuuuuuuuxxzzzzzzzzwr}}}rr}}}}}}}}}}}}}}}}}}}}}}zzuu>  `vwzzzzzzwr}vvvvvvv`<br></br>
-uuuuxxzzwwzzzzzzzwwwwwr}}}}}}}}}}}}}}}}}}}vvv??vzzv....vwzzzzzzzzw}vvvvvv`<br></br>
-uuxxzzwwwwzzzzzzzzzzwwwwv}r}vvvvvvvvvvvvvv?????vv~^|.. >zzzzzzzzzzzwvvvvv`<br></br>
-uxzwwwzwwwzzzzzzzzzzzwwxxrrrv?????????????????>~. `?...~wzzzzzzzzzzzwv?vv`<br></br>
`xzzzzwwwwwzzzzzzzzzzzw}???>?wv?????????????>+-. ...>^...}zzzzzzzzzzzzz}vv`<br></br>
`zzzzzzzzzzzzzzzzzwv>|`.. ..`?vv???????>>^-`.       ~?...?wzzzzzzzzzxxxxrv`<br></br>
`zzxzzzzzzzzzzzz}+`.........``````......           ..?~. >zzzzzzzzxxuuuxxz-<br></br>
`zzzzzzzzzzzzz}^. ...........``....                ..|+..|zwzzzzzxuuuuuuuu^<br></br>
-xxxxzzzzzzzr+. ..............``....                 `>`.~zwwzzxxuuuuuuuuu|<br></br>
                  `zzxxzxzzzw?...................``.....               .^`.`wwwzzxxuuuuuuuuu|<br></br>
                  `zzxxxxxxr~ ....................```....               -|..rwwzzzxxuuuuuxxu|<br></br>
                  `zzzzxxxz+......................```......             .|..vrrwzzxxuuuuuxxu|<br></br>
`zzwwzxw^>+......................````.....            .~..vrrrzzxxuuuuxxxx|<br></br>
                  `zzwwzz^.`+?-......................``......           ....?rrrzxxxxxxxxxxx~<br></br>
                  `wwwwz?...`+?~.....................````......         ....?rwzxuuxxxxxxxxz~<br></br>
-xwwzw-.....|?+.....................````......        ....>zxxxxxxxxxxxxzw~<br></br>
-uuzw? ......`>>`....................```........       ...+xxxxxxxxxzzzwww~<br></br>
-xxxz}~........|>|....................```........     ....^xzzxxxxxxw}}}rr~<br></br>
-xxzzzz?` ......-?>....................```.............`..^zzzxxxxxxw}vv}r~<br></br>
-xzzzzzxw+........|>~...................```..............^vzzzxxxxxxz}vvv}~<br></br>
`zzzzzzzzx}|.......`^|.................``-`.............}yyxrzzzxxxzw}vvvv-<br></br>
`zzzzxzzzzxxv~.......`|-.............```````....~^>>>+~vywrwwrzzzxzzwvvvvv-<br></br>
`zzzxxzzzzzzxz?`.......--...........`-```````.|ruuuuuuuxvvv?}vzzzzzzz}vvvv-<br></br>
-zzxxxxzzzxxxzv?-........`..........--```````.?uwrrrrrr}vvvv?vzzzzzzz}vvvv-<br></br>
`zxxxxzzxxxuux?+^>|. ...............-``````````>rrrrrrr}vvv?+?rrrrrrr}}}vv-<br></br>
`zzxxzzxxuuuuuw??vvr>. .......``````-`````````.`?wxxzzzrvv????rrrr}}}}}}v}-<br></br>
`zzxxzzxxuuuuuuw?vvzyw>` ..`````````````````...`vzrrrrw}vv?>?}uxzwwwrrrr}}~<br></br>
`zzzxxxxxuuuuuuxvvvvwuw^`..``..`````````````..-rw??vvvvvv??>vxuuuxxxxzzzww~<br></br>
`zzzzxxxuuuuuuux?vvvvv?>~|-....````````````..`wz?>?}}vvvv??>ruuuuuuuuxxxxu|<br></br>
`zzzzxxxuuuuuuuuvvv?vv?}>|^|`....``..........vuv>????vvvv?>|?zxuuuuuuuxxxx|<br></br>
`zzzzzzxxuuuuuuurvv>>???~`~|~`..........`~|+?zz????????>?+`..?zxxxxzzzzzzz~<br></br>


            </div>,

            <div>

`rrrrrrrww}v>~...   -}r}???????????????????????rv+?- ...>vvvvvvvvvvvv?????`<br></br>
-uuuuuyu}>~........  ^zxw}v}}}}}}}}}}}}}}}}}}r}?-.+?....|wzzzzzzzzzzzvvvvv`<br></br>
-uuuuz?-............  ~wzzwv}}}}}}}}}}}}}}}v?^`  .`v^....vzzzzzzzzzzz?vvvv`<br></br>
-uyz>................  -wzuz?+?vvvvvvvv?>^-.     ..|v... +xzzzzzzzzzz?v?vv`<br></br>
-u?. .................. ~zuuz|  .......           ..?|....wxzzzzzzzzzvv>v?.<br></br>
`|...................... ~zxxu>                     ~?... ?xzzzzzzzzzw?>??-<br></br>
                  ........................ |xuxuv.                  ..+-...`wzzzzzzzzxxv?vr-<br></br>
                  .......................... ^xxxur`                   `^....vxzzzzzxxuuxvzz~<br></br>
                  `?`........................ +xxxuz|                   -`...|xzzzxxuuuuuxxu^<br></br>
.?v-........................ +xxxxu>                 .......}xzxxuuuuuuuuu|<br></br>
                  `?v^........................ ?uxzxuv`                ......^zzxxxuuuuuuuu|<br></br>
 ..|v?`........................vuxxxuz^               .......}zxxxuuuuuxxu|<br></br>
                  ...`?v|........................vxxxxuu?.              ..... +xxxxuuuuuxxu|<br></br>
 .....|?>`.......................vuxxxxur-              ......wxxuuuuuxxxx|<br></br>
  .....`>v~.......................vxxxxxxx?.            .... `}xxzxuuxxxxx~<br></br>
.~.......~v>.......................}uxxxxxxv`            .. ^xyuuxwzxxxxxz~<br></br>
-uv`.......+?-.....................`ruuxzwwrr+             +uuwr}v}ruuxxxw~<br></br>
-uuz>. .....`>+.....................`ruxzwrrwxv`          -xu}v}vvv}>?vrww~<br></br>
`xxzx}+.......~+-....................`wuzwrrrwxw+.    .`~>wurvvvvvv?^ .`>w~<br></br>
`xxzzzx}^.......~~....................`ruwwwwzxuu}--?}wzzw}}v}vvvv?>+ . ~r|<br></br>
-xzzzzzzz}^.......`................... .rxzxxxuuxuruur}}vvr}vvvvv?>>?  ..-`<br></br>
`zzzzzzzzzx}+........................ -?zuxxxuuuux}zw?vvwxzv??vv?>>>?.  .  <br></br>
`zzzzxzzzzzxx}+..................... >uur}}}}wxuuux}r?>?zx?>>>>>>>>>?.   . <br></br>
-zzzxxzzzzzzzxx}|...................?yxvvvvvvvwuuuuxww}v??>>>>>>>>>>?.  .. <br></br>
-xzxxxxzzzzxxzv??+`. ............. -uuvvvvvvvvrxuuuuuuxwv???>>>>>>>??.     <br></br>
`zxxxxzzxxxxuu?+^?vv^. ......   ...>yrvvvvvvv?vzuuuuuuuw??>^. ......       <br></br>
`}rzxxzzxuuuuuw??vvxur|.    `+?vv}rzxvvvvvvvv>?rxxuuuuux?v??}|.            <br></br>
`wzwzxzxxuuuuuuw?vvwuuur- .?zzrvvvv}r}}vvvvv?>??|>?ruuurvvv?zwv?^`^~. ..   <br></br>
`v}wxxxxuuuuuuuxvvvvwxr?^~?z}>>vvwxuxrrrvvvv>>?> -^>+?rvvvvvxuuzw}wzr?v}?^.<br></br>
.?v?vrzxuuuuuuux?vvvvv?>|^vw?>>?vuuw}}}}vvv?>>?|  `rv??}??v?xuuuxzwzzxzzzz~<br></br>
`vvvvvv}wzxuuuuuvvv?vv?}>~?w?>>>>v????????>>>>?`   .>vv?+?vvvxuuuuxxzwwwzz~<br></br>
`vvv???v}zxuuuuurvv>>???~`|}v>>>>>>>>>>>>>>>>>v.     +v|^?>>vwzxxxxxzzwwrw~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr?v}?rr}}}}}vvvvvvvvvv}rrrrrrrrrrrrrrrr}vvvvvvvv}v}}}}}}}}}}vv-<br></br>
-uuxxxxxxxzxuwvywwuuuxxzwrwxuxwwwwxuuuyyyyyyyyyyyyuxw}}}}wzzzzzzzzzxxxxxzw|<br></br>
`uuzzzzzzzzzzu}wurruuxxzzzxuuzwwwwxuuuuuuuuuuuuuuuuxzwrrrwzzzzzzzzzzzzzzww|<br></br>
`uxzzzzzzzzzzxx}uuzzuuxxzxuuxwrwzxuuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `uxzzzzzzzzzzzxxzyyzwuxxzzxuxwwzxuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `zxzzzzzzzzzzzzxzuyurwxxxzxxxxxxxxxxxxuuuuxxxxxxzzzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  -wwzzzzzzzzzzzzxxzuzywwxxzxxxxxxxxxxxxxuxxxxxxxzzzzzzzzzzzzzzzzzzzzzzxxxzw~<br></br>
                  `zzwwzzzzzzzzzwzxzxxvxrzzzzxxxxxxxxxxxuuxxxxxxxxzzzzzzzzzzzzzzzzzzzxuuuuxz|<br></br>
                  `zxxwrwzzzzzzzzzzuwuv?zwzzxzzxxxxxxxxuuuuuxxxxxzzzzxxzzzzzzzzzzzzxxuuuuuux|<br></br>
-xxxxzrwzzzzzzzzzxxzu?}rzxxzzxxxxxxxxuuuuuuuuxzwwrzuxzzzzzzzzzzxxuuuuuuuuu|<br></br>
`zxxxxxzrwzzzzzzwruzzuwx}xxxxxxxxzxxuuuuuyyyuzrrrrzuzwwzzzzzzzzxxxuuuuuuuu|<br></br>
-xxuuxxzzrwzzzzwrxuuzwuu}zxxxxxxxzxxuuuuuuyyuzrrwwzzwwzxzzzzzzzxxuuuuuuuxu|<br></br>
                  -uuuuuxzzzrrzwrrxuuuuxwzrruxxzzzzzxxxuuuxuuyuuxzwzzxxxuuxzzzzzzzxuuuuuuxxu|<br></br>
                  -uuuuuxzzzwrzxxuuuuuuuuzwrrxzzzzzzxxxxxxxxuyyyyuxxzxxxxxxxzzzzzxxxuuuuxxxx|<br></br>
-uyyuuxzzzzzzrzxxxuuuuuuxz}wzzzzzzzxxxxxxxuuxzzzzzrrrrrrzxzzzzzxxuuuuxxxxx~<br></br>
-uuuxxzzzzzzxzwxxxuuuuuuxxz}wzzzzzzxxxxxxxxz}}}rzw}}}}}}wzzzzzxxxxxxxxxxxz~<br></br>
-uuxxzzzzzzzzzrwuxxuuuuuuuuz}zzzzzzxxuuxzzwr}wxuz}}rr}}rzzzzzxxxxxxxxxxxxw~<br></br>
-uuxzzzzzzzzzzxzzuxxxuuuuuuuxrwzzzzxxuuxzwrrwxuzrvrr}wwzzxzzzxxxxxxxxxxzzw~<br></br>
                  `xxzzzzzzzzzzxxxrzuxxxuuuuuuuxwwxxxxuuuuzwrrrzzzwrwwwzxxxxzzzxxxxxxxxzwwrr~<br></br>
`xxzzzzzzzzzzzzzw}zuuuuuuuuuuuxzruuuuuuuxwwwwwzxxxxuxxxxxxzzzxxxxxxxxzwrrr~<br></br>
                  -xzzzzzzzzzzzzzzzzrxuuuuuuuuuuuxzwuuuuuuuxzzzxxuxxxuuxxxxxzzzzxxxxxxxwrrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzwxuuuuuuuuuuuurwzzzzzzzxxuuuuuxxuxxxrzxzzzzxxuuxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzxwvzzxuuuuuuuuuuuxvwwwwwwwwwrruuuuxxxxu}vzzzzzxxxuxxxzrrrr~<br></br>
-zzzxxzzzzzzzxxzzv?xuzzuuuuuuuuuuxrv>++>vwrv?ruuuuuuuuuw+>?vrxxxuuxxxzrrrr~<br></br>
-zzxxxzzzzzzxzv??>?uuuxzzxuuuuuuuxz^^~~~~??vwuuuuuuuuuuw?^~+vuuxxuxxxxzwrr~<br></br>
`zxxxxxzzzxxxx?+|??wuuuuxzxuuuuuuux}?>||~vwxuuuuuuuuuuuw????ruuuxxxxxxxzww~<br></br>
`zzxxxzzxxuuuuw??vvxuuuuuuwvrxuuuuxz>?+~|?zxuuxxxxuuuuux?v??xuuuxxxxxxxxxz~<br></br>
`zzxxzzzxuuuuuuw?vvwuuuuw?+++>vzzwzxv?+^>|^>?>>>>>?wuuurvvv?zxuuxxxxxxxxxz~<br></br>
`xxxxzxxxuuuuuuxvvvvwxr?^^+++++>?vrz>???+|^++++++++^>?rvvvvvxuuuxxxxxxxxxz~<br></br>
`xxxxxxuuuuuuuux?vvvvv?>|++>+++++^+>>v?>~|++++++>>^>???v??v?xuuuuuuuuxxxxx|<br></br>
`xxxxxxuuuuuuuuuvvv?vv?}>~^^^+>++++~+>?--++++^~~^^|vv???+?vvvxuuuuuuuuxxxx|<br></br>
-xxxxxxuuuuuuuuurvv>>???~`~--~^++^^|-|>-^^^++~----`^+?>+^?>>vwzxxxxxzzzzzz~<br></br>


            </div>,

            <div>

`}rrrrrrrrrrrrrrrrrrrrv}vvvvvvvvvvvrrrrrrrrrrrrrrrr}vvvvvv}v}v}}}}}}}}}}vv-<br></br>
-uuuuuuuuuuuuuuuuuuuuuzrwrwxuzwwwwxuuyyyyyyyyyyyyyuxw}}}}wzzzzzzzzzxxxxxzw|<br></br>
                  `uuuuuuuuuuuuuuuuuuuuuurwzxyuzrwwzxuuuuuuuuuuuuuuuuxzwrrrwzzzzzzzzzzzzzzww|<br></br>
                  `uuuuuuuuuuuuuuuuuuuuuuxrxuuxwrwzxuuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `uuuuuuuuuuuuuuuuuuuuuuurzxuxwwzxuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `uuuuuuuuuuuuuuuuuuuuuuuzwxxxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `uuuuuuuuuuuuuuuuuuuuuuuurxxxxxxxxxxxxxuxxxxxxxzzzzzzzzzzzzzzzzzzzzzzxxxzw~<br></br>
                  `uuuuuuuuuuuuuuyyyuuuuuuuwwxxxxxxxxxxxuuxxxxxxxxzzzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
                  `xxxuuuuuuuuuuuuuuuuuuuuuxrxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzxxuuuuuux|<br></br>
                  `zzzxuuuuuuuuuuuuuuuuuuuuuwzxxxxxxxxxuuuuuuuuxzwwrzuxzzzzzzzzzzxxuuuuuuuuu|<br></br>
                  `wzzwzxuuuxxxuuuuuuuuuuxxxxrxxxxxzxxuuuuuyyyuzrrrrzuzwwzzzzzzzzxxuuuuuuuuu|<br></br>
                  -zwwwwzxxuxzzxxuuuxxxuuxzzxrzxxxxzxxuuuuuyyyuzrrwwzzwwzxzzzzzzzxxxuuuuuuxu|<br></br>
                  -uuzwwzzxxxzzzzxuuuxxxuuxzzwrxzzzzxxxuuuuuuyuuxzwwzzxxuuxzzzzzzxxxuuuuuxxx|<br></br>
                  -uyyuwrwzzzzzwzzxxxxzzxuxzwwrzzzzzxxxxxxxxuyyyyuxxxxxxxxxxzzzzzxxxuuuuxxxx|<br></br>
                  -uyuyuzwwwwzzzwzzzxxzzzxxzzrvzzzzzzxxxxxxxuuxzzzzzwwwwwwzxzzzzzxxuuuuxxxxx~<br></br>
-uuuxxzzzwwwwrwzwzzzzzzzxzzwvxzzzzzxxxxxxxxz}v}rzw}}}}}}wzzzzzxxxxxxxxxxxz~<br></br>
-uuxxzzzzzzzr??}wzzzzzzzzzzzrzzzzzzxxuuxzzwr}wxuzr}rr}}rzzzzzxxxxxxxxxxxxw~<br></br>
-uuxzzzzzzzzzzzzr}wzzzzzzzzvvxzzzzzxxuuxzwrrwxuzrvrr}rwzzxzzzxxxxxxxxxxxzw~<br></br>
`xxzzzzzzzzzzxxxrvvvrrwwwz}+rzzzzzxxuuuuzwrrrzxzr}rwwzxxxxzzzxxxxxxxxzzwrr~<br></br>
`xxzzzzzzzzzzzzzrrrrwwww}wrrzzzzxxxuuuuuxwwwwwzxxzxxxxxxxxzzzxxxxxxxxzwrrr~<br></br>
                  -xzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxuuuuuuuuuxzzzxxuxxuuuxxxxxzzzzxxxxxxxzrrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzwzzzzzzxxuuuuuxuuxxurzxzzzzxxuuxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzxwvzxxxxxxxzzzvrrwwwwwwwwwwwrruuuxxxxxu}vzzzzzxxxuxxxzrrrr~<br></br>
`zzzxxzzzzzzzxxzzv?xuuuuuuuxxx}?}rr}>+>>vwrv?ruuuuuuuuuw+>?vrxxxuuxxxzrrrr~<br></br>
-zzxxxzzzzzzxzv??>?uuuuuuuuuuuu}v??~^~~~~??vwuuuuuuuuuuw?^~+vuuxxuxxxxzwrr~<br></br>
`zxxxxxzzzxxxx?+|??wuuuuuuuuuuuyuzv>?>||~vwxuuuuuuuuuuuw????ruuuxxxxxxxzzw~<br></br>
`zxxxxzzxxuuuuw??vvxuuuuuuz}}rzuzr?v>?+~|?zxuuxxxxuuuuux?v??xuuuxxxxxxxxxz~<br></br>
`zzxxzzzxuuuuuuw?vvwuuuuw?++++^>+^+v??+^>|^>?>>>>>?wuuurvvv?zxuuxxxxxxxxxz~<br></br>
`xxxxzxxxuuuuuuxvvvvwxr?^++++++++++v?v??+|^++++++++^>?rvvvvvxuuuxxxxxxxxxz~<br></br>
`xxxxxxuuuuuuuux?vvvvv?>|++>+++>++^>?v?>~|++++++>>^>???v??v?xuuuuuuuuxxxxx|<br></br>
`xxxxxxuuuuuuuuuvvv?vv?}?~^^^+>>+++~+>?--++++^~~^^|vv???+?vvvxuuuuuuuuxxxx|<br></br>
-xxxxxxuuuuuuuuurvv>>???~`~--~^+++^|-|>-^^^++~----`^+?>+^?>>vwzxxxxxzzzzzz~<br></br>


            </div>,

            <div>

`rrrrr}}rrrr}vvv}}}}}}}}vvvvvvvvvvvrrrrrrrrrrrrrrrrvvvvvvvvvvv}}}}}}}}}}vv-<br></br>
-uuxxxxxuxuuxwwxuuuuuxxzwrzxuzwwwwxuuyyyyyyyyyyyyyuzw}}}}wzzzzzzxxxzzxxxzw|<br></br>
                  -uuxxxxxxxxxzwrzxxxxuxxzzzxuuzrrwzxuuuuuuuuuuuuuuuuxzrrrrrwzzzzzzzzzzzzzww|<br></br>
                  -uxxxxxxxxxxzwrwzxxxxxxxzxuuxrrwzxuuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  -uxzzxxxxxxxzwwwzzxxxxxzzzxuxwwzxuuuuuuuuuuuuuuuuxzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `xxxxxzxxxxzwwwwwzzxxxzzzzxxxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzzzzzzzww~<br></br>
                  `zzxxzzzzzzwrrwwwzzxxxzzxxxxxxxxuxxxxxxuxxxxxxxxzzzzzzzzzzzzzzzzzzzzzxxxzw~<br></br>
                  `zzxxxzzzzwwwwzzzzxxxxzzzxxxxxxxxxxxxxuuxxxxxxxxzzzzzzzzzzzzzzzzzzzxuuuuxz~<br></br>
                  `zxxxxxzzzwwwzxxxxxxxxzzzzxxxxxxxxxxxuuuuuxxxxxzzzzzzzzzzzzzzzzzzzxuuuuuxx|<br></br>
                  `zxxxxzzzwwwwxuuuuuuuxzzzzxxxxxxxxxxxuuuuuuuuxzwrrzuxzzzzzzzzzzzxuuuuuuuuu|<br></br>
                  `xxxzzzzzwwwwxuyyyyuxxzzzzxxxxxxxzxxuuuuuyyyuzrrrrzuzwwzzzzzzzzxxuuuuuuuuu|<br></br>
-xxuuxzzzwwwzxuyyyuuxxzzzzxxxxxxxzxxuuuuuyyyuw}rwwzzwwwzzzzzzzzxxxuuuuuuxu|<br></br>
                  -uuyuuxzzwwwwzzxxxuuxxzzzzxxxxxxzzxxxuuuuuuyuxzwwwzzxxuuxzzzzzzxxxuuuuuxxu|<br></br>
-uuyuuxxzwwwwr}}}rxuxzzzzxxzzzzzzzxxxxxxxxuyyyuxxxxxxxxxxxzzzzzxxxuuuuxxxx|<br></br>
-uyyuuxzzzwzzr}v}rzxxxxxzzzzzzzzzzzxxxxxxxuuuxzzzzwwwwwwzxzzzzzxxuuuuuxxxx~<br></br>
-uuuxxzzzzzzzxzzwwzxxxxxxzzzzzzzzzzxxxxxxxxzrv}rzw}}}}}}wzzzzzzxxxxxxxxxxz~<br></br>
-uuxxzzzzzzzzzzzzzwwwwwzzxxzzzzzzzzxxuuxzzwr}rzuzr}rr}}rzzzzzxxxxxxxxxxxxw~<br></br>
-uuxzzzzzzzzzzxxzr}vv}rzzzzzzzzzzzzxxuuxzwrrwxuzr}rr}rwwzxzzzxxxxxxxxxxxzw~<br></br>
-xxzzzzzzzzzzxxxr}}}}rwzzzzzzzzzzzxxuuuuzwrrrzxzr}rwwzxxxxzzzxxxxxxxxxzwrr~<br></br>
                  `xxzzzzzzzzzzzzwrrrwwzzzzzzzzzzzxxxuuuuuxwrwwwzxxzxxxxxxxxzzzxxxxxxxxzwrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxuuuuuuuuuzzzzxxuxxuuxxxxxxzzzxxxxxxxxwrrrr~<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzxxuuuuxxuuxxuwzxzzzzxxuuxxxwrrrr~<br></br>
`zzzzzzzzzzzzzzzxwvzxxxxxxxzzzvrrwwwwwwwwwwwrruuuxxxxxu}vzzzzzxxxuxxxzrrrr~<br></br>
-zzzxxzzzzzzzxxzzv?xuuuuuuuxxx}?}rr}>+>>vwrv?ruuuuuuuuuw+>?vrxxxuuxxxzrrrr~<br></br>
-zzxxxzzzzzzxzv??>?uuuuuuuuuuuu}v??~^~~~~??vwuuuuuuuuuuw?^|+}uuxxuxxxxzwrr~<br></br>
-zxxxxzzzzxxxx?+|??wuuuuuuuuuuuyuzv>?>||~vwxuuuuuuuuuuuw????ruuuxxxxxxxzww~<br></br>
`zxxxzzzxxuuuuw??vvxuuuuuuz}}rzuzr?v>?+~|>zxuuxxxxuuuuux?v??xuuuxxxxxxxxxz~<br></br>
`zxxxzzzxuuuuuuw?vvwuuuuw?++++^>+^+v??+^>~^>?>>>>>?wuuurvvv?zxuuxxxxxxxxxz~<br></br>
`xxzzzzxxuuuuuuxvvvvwxr?^++++++++++v?v??+|^++++++++^>?rvvvvvxuuuxxxxxxxxxz~<br></br>
-xxxxxxuuuuuuuux?vvvvv?>|++>+++>++^>?v?>~|++++++>>^>???v??v?xuuuuuuxxxxxxx|<br></br>
`xxxxxxuuuuuuuuuvvv?vv?}?~^^^+>>+++~+>?--++++^~~^^|vv???+?vvvuuuuuuuuuxxxx|<br></br>
-xxxxxxuuuuuuuuurvv>>???~`~--~^+++^|-|>-^^^++~----`^+?>+^?+>vwzxxxxxzzzzzz~<br></br>


            </div>,

            <div>

`vvvvvv??????vvvvvvvvvvvvvv??????vvvvvvvvvvvvvvvvvvv??vvvvv}vv}}}}}}}}}}vv-<br></br>
`zzwwwr}}vv}}rrwwwwwrrrrrrrrrrrrwzzzzwwwwwwwwwwwzzwr}}rwzzzxzzxxxxxxxuuuxz|<br></br>
`zzwwrr}}vvv}}rwwwwrrrrrr}rwwwwzzzzxzzzzzzzzzzwzzzzwrrrrwwzzzzxxxxxxxxxxxz|<br></br>
`zzwwwrrr}}}rrrrrrwwwwrrrrwzxxzzxxuuuxxxxxxxxzzwzzzwwwwwwzzzzxxxxxxxxxxxxz|<br></br>
                  `xxzwwwwrrrrrrrrrrrrwwwrrwxuxxxxuuuuuuuxxxxxzzzzzwwwwwwwzzzzzxxxxxxxxxxxxz|<br></br>
                  `xxzzzzwwwwwwwwrrrrwwwrrzxuuxxxxxxuuuuxxxxzzzzzzwwwwwwwwzzzzzzzzxxxxxxxxxz|<br></br>
                  `zzzzzzzzzzzwwwwwrrrrrrzxuuuxxxxxxxxxxuuuxxzzzwwwwwwwwwwzzzzzzzxxxxxxxxuxz|<br></br>
                  `zxxxzzzzzzzwwwwwwwwwrzxxxxxuuuuuuuuxuuuuuxxxzzwwwwwwwwwzzzzzzxxxxxxuuuuxz~<br></br>
                  `zxxxxxxxzzzzzzzzzzwwwzxxxxxxuuuuxuuuuuuuuuuuxzzwwwwwwwwzzzzzzxxxxxuuuuuxx|<br></br>
                  `zxxxxxxxzzzzzxxxxxxxzxxxuxzxxxxxxuuuuuuuuuuuuzzwwwwwwwwwwzzzzxxxxuuuuuuuu|<br></br>
                  `xxxxxxxxzzzzxuuuuuxxxzxxzwzxxxxxxxxxxuuuuuuxxwrrrzzwwwwrwzzzzxxxuuuuuuuuu|<br></br>
                  -xxuuxxxxxzzxxuuuuuuxwrxwzzzxxxxxxxxxxxxxxxxxzwwwwzzrrrrwwzzzxxxuuuuuuuuuu|<br></br>
                  -uuyuuuxxxzxxxxuuuuxzrwzxuzxxxxzzzzxxxxuuuuuuuxxzzzzwwzzzwwzzzxxxuuuuuuuxx|<br></br>
-uuyuuuuxxxxxzzzzzzzz}zxxxzxxzzzxxxxxuuuuuuuuuuuxxxxzzzzzwwwwwwzxuuuuuuuxx|<br></br>
-uuuuuuuxxxxxzzzzzwzr}xxzzzxxxxxxxxxxuuuuuuuuuuuuxxxzwwwwwwwwwwwzxuuuuuuxx~<br></br>
-uuuuuuuuxxxuxxxxw}rrzxxxxxxxxxxxxxxxuuxuuuxxzzxxxxzzrrrwwwwwwzzzzzxuuuuxz~<br></br>
                  -uuxxxxxxxxxuuuuxwvrzzxxxxxxxxxxxxxxxuuxxxxzzzxuuzzzwrrrwwwwwzzzzzzxxuuuxz~<br></br>
                  -uuxxxxxxxxxxxuuxwrwwzzxxxxxxxxxxxxuuuuuxxxxxxuuxzzzwwwwwwwwzzzzzzzzxxuuxz~<br></br>
                  -xuxxxxxxxxxxxxxzwwwwzzxxxxxxxxxxxxuuuuuxxxxxxuxxzxzzzzzzzwwzzzzzzzzzxxxxz|<br></br>
                  `xxxxxxxxxxxxxxxzwwwzzxxxxxxxxxxxuuuuuuuxxxzzxxxxxxxxzzzzzzwzzzzzzzzzzzzzz|<br></br>
                  `xxzzzzzzxxxxzzzzzzxxxxxxxxxxxxxuuuuuuuuuxxxxxxxxxxxxzzzzzzwwzzzzzzzzwwzzz~<br></br>
                  `zzzzzzzzzxxzzzzxxxxxxxxxxxxxxxxxxxzzzzzzzzzzzzwzzwwwwwrrwwwwzzzzzzzzwwzzz~<br></br>
`zzzzzzzzzzzzzzzxzzxxxxxxxxxxxwwwwrrr}}}}vvvvvvvvvvvvvv?>vv}}}rrwzzzwwwzzz~<br></br>
`zzzzzzzzzzzzwzzzwwxuuuuuuuuuxrvvvv?>+++????>?????????v?|^+>?vvvvv}wzzwwzz~<br></br>
`zzzzzzzzzzzzzr}rv}uuuuuuuuxxzwv?+|```..~>>>?vvvvvvvvvv?+|~~>vvvvvvvwzzzzz~<br></br>
`zzzzzzzwwzzzzvvvrwxuuuuuxzzzwwv?>^-~--~^?v}r}}}vvvvvvvv?>++?vvvv?v}wzzzzz~<br></br>
`wwwwwwwwwwzzzwwrwwxuuxxzw}vv???>+||-|+++?}}rr}}}vvvvv??+?>>?vvv?v}wzxxzzz~<br></br>
`wwwwwwwwwwwwzzzrwwxuxzwv>^^|``--``^^????>+>??>>>>?}rr}?+++>?vvv}wzxxxzxzz~<br></br>
`wwwwwwwwwwwwwzzrwwzzzv>|~|~----```?vvv?>|~~~~~~||^+?v}vv??+?v}rwzxxxzzzzz~<br></br>
`wwwrrrwwwwwwwwz}rwr}v?>--~```````|vv}v?^----------|??vvvvv?rwr}rwzzzzzzzw~<br></br>
`wwwwwwwwwwwwwwwv}v?vv??>~`..``--~+?vvvv?>>++|~|+^+??>??>?vv}wwwrwzzzzwwww~<br></br>
`wwwwwwwwwwwwwww}v}v??>?~.....`~-~+>?v??>>>>>^||||^???>+|^+>v}}rwwwwwwrrww~<br></br>


            </div>,

            <div>

.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>???vvvvv}}}rrrrrrrrrrr}-<br></br>
`vv??????????????????????????????v}v??????????????vvv}rrwzzzxxuuuuuuuuuuuu|<br></br>
`vv????????????????????????????}ww}????????????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`}}vvv??????????????????????vwxuxzzwwr}}vv?????????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`rrr}vvv??????????????????vwuuuuuuuuuxr}}}vv????????vv}}rrwzzzxxxuuuuuuuuu|<br></br>
`wzwwr}}vvv??????????????wuuuuuxxzzzzwrr}}vvv????????vv}rrwwzzzxxxxuuuuuuu|<br></br>
`zxzzwwrr}}vvv??????????zuuxxzzwzzzxxxuuuxzwr}vvv??vvvv}rrwwzzzxxxuuuuuuuu|<br></br>
`xxxzzzwwwwr}}vvv??????zuxzzxxxuuuuuuuuuuuuxzrr}vvvvvvv}rrwwzzzxxxxuuuuuuu|<br></br>
`xuxxxxzzzwwwrr}}vvvv??zzxuuuuuuuuuuuuuuuuuuuzrrr}}vvvvvrwwwzzzxxxxuuuuuuu|<br></br>
-xuuuuxxxxzzzzwwrr}}}v}zuu}>uuuuuuuuuuuuuuuuuuwrrrr}v}vvvwwwzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzwww}wuux?|>uuuuuuuuuxxxzzzwwwr}}}}}}vvvvvzzzzxxxxxuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzrzuuw>v?}uuuuuxzwwrrrrrrwwwzwr}}}}vvvvvrzxxxxxxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxzzuz}>zu>uuxzwwrrwzzxxuuuuuuuuuxwrrrrrr}v}rzxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuxwxwv>uxv}zwwwzxxuuuuuuuuuuuuuuuuxwrrrrrr}vvv}wxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuwwr}?zrv}zzxuuuuuuuuuuuuuuuuuuuuuuxrrrrrrr}}}vv}zuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuu???v>vwxuuuuuuuuuuuuuuuuuuuuuuuuuuuwrrrrrrrrr}}}vruuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuv>vrwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzrrrrrrrrr}}}}vruuuuuu|<br></br>
`xuuxuuuuuuuuuuuyvvzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxrrrrrrrrrr}}}}vruuuuu|<br></br>
`xxxxxxxuuuuuuuuxrxzwzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzrrrrrrrrrrr}vv}vxuuuu|<br></br>
`zzzzxxxxxxuuuuu}}vvrxuuuuuuuuuuuuuuuuuuuuuuuuuxxxxzwrrrrrrrrrrr}}v}vwuuuu|<br></br>
`zzzzzzzxxxxxxuwz}}zuuuxuuuuuuuuuuuuuuuuuxxzzwwwrrrrrrrrrrrrrrrr}}}}v}uuuu|<br></br>
`wwwwwzzzzzxxxxzx}v}rwwzuuuuuuuuuuuuxxzzww}}vv??>>>>>>++>???vv}rr}}}}}uuuu|<br></br>
`rrrwwwwzzzzzzzx}vrxzzxuuuuuuuuuuxzwrv?>+^~--``````---``------~^>?}}vruuxx|<br></br>
`}}}}rrrwwwwwzrxzxzwrzuuuuuuuxzwrv?^~``````-----------------------~?vzxxxx|<br></br>
`vvvvvv}}}rrwwrxuzwzxuuuuuuzwr}v>.      ->>>++++>>>>>+^~----------`-rxxzzx|<br></br>
`vv?vvvvvv}}}rrxuuuuuuuuxwr}}r}^       >rwwrrr}v?++>>??v?+|~---~--~vxxzzzz|<br></br>
`???????vvvvvvvwuuuuuuxw}}}rwv`      .?wrrrrrrrrv>~.   .~>vv?^~-|>rxzzzzzz~<br></br>
`????????????vvvxuuuuz}}rrrw?.      .vwrrrrrrrrrwww}v?>|`..~?vv?}xzzzwwwwz~<br></br>
`??????????????vvzuuu}}rrrw?.      `vwrrrrrrrrrrrrrrwwr}v?+` .|?vwwwwwrrrw~<br></br>
`?????????????????}wz}wrrwv.      `}wrrrrrrrrrrrrrrrrrrrrr}v?^. `}rrr}}}}r-<br></br>
`???????????????????vrrrw}.      .vwrrrrrrrrrrrrrrrrrrrrrrrr}vv?^}}}vvvvvv-<br></br>
`???????????????????rrrrr~       ~rrrrrrrrrrrrrrrvvr}}rrrrrrr}vv?vvvvvvvvv-<br></br>


            </div>,

            <div>

.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>???vvvvv}}}rrrrrrrrrr}}-<br></br>
`vv??????????????????????????????v}v??????????????vvv}rrwzzzxxuuuuuuuuuuuu|<br></br>
`vvv???????????????????????????}ww}????????????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`}}vvv??????????????????????vwxuxzzwwr}}vv?????????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`rrr}vvv??????????????????vzuuuuuuuuuxr}}}vvv???????vv}}rrwzzzxxxuuuuuuuuu|<br></br>
`wzwwr}}vvv??????????????wuuuuuxxzzzzwrr}}vvv????????vv}rrwwzzzxxxxuuuuuuu|<br></br>
`zxzzwwrr}}vvv??????????xuuxxzzwzzzxxxuuuxzwr}vvv??vvvv}rrwwzzzxxxxuuuuuuu|<br></br>
`xxxzzzwwwwr}}vvv??????zuxzzxxxuuuuuuuuuuuuxzrr}vvvvvvvvrrwwzzzxxxxuuuuuuu|<br></br>
`xuxxxxzzzwwwrr}vvvvv?vzzxuuuuuxxxuuuuuuuuuuuzrrr}}vvvvvrwwwzzzxxxxuuuuuuu|<br></br>
-xuuuuxxxxzzzzwwrr}}}v}zuu}>uuxxuuuuuuuuuuuuuuwrrrr}v}vvvwwwzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzwww}wuux?|>uuuuuuuuuxxxzzwwwwr}}}}}}vvvvvzzzzxxxxxuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzrzuuw>v?}uuuuuxzwwrrrrrrwwwzwr}}}}vvvvvrzxxxxxxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuxxxzzuz}>zu>uuxzwwrrwzzxxuuuuuuuuuxwrrrrrr}v}rzxuuxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuxwxwv>uxv}zwwwzxxuuuuuuuuuuuuuuuuxwrrrrrr}vvv}wxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuzwr}?zrv}zzxuuuuuuuuuuuuuuuuuuuuuuxrrrrrrr}}}vv}zuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuu???v>vwxuuuuuuuuuuuuuuuuuuuuuuuuuuuwrrrrrrrrr}}}vruuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuv>vrwuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzrrrrrrrrr}}}}vruuuuuu|<br></br>
`xxxxuuuuuuuuuuuyvvzzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuxrrrrrrrrrr}}}}vruuuuu|<br></br>
`xxxxxxxuuuuuuuuxrxzwzuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuzrrrrrrrrrrr}vv}vzuuuu|<br></br>
`zzzzxxxxxxuuuuuv}vvrxuuuuuuuuuuuuuuuuuuuuuuuuuxxxxzwrrrrrrrrrrr}}v}vruuuu|<br></br>
`zzzzzzzxxxxxxuwz}}zuuuxuuuuuuuuuuuuuuuuuxxzzwwwrrrrr}}}}}}rrrrr}}}}v}uuuu|<br></br>
`wwwwwzzzzzxxxxzx}v}rwwzuuuuuuuuuuuuxxzzww}}vv???>>++++++>???v}rr}}}}}uuuu|<br></br>
`rrrwwwwzzzzzzzx}vrxzzxuuuuuuuuuuxzwrv?>+^~-----------``------~^>?}}vruuxx|<br></br>
`}}}}rrrwwwwwzrxzxzwrzuuuuuuuxzwrv?^~``````--~~~~~~~~~------------~?vzxxxx|<br></br>
`vvvvv}}}}rrwwrxuzwzxuuuuuuzwr}v>.      `+>>+++>>>>>>+^~----------`-rxxzzx|<br></br>
`vv?vvvvvv}}}rrxuuuuuuuuxwr}}r}^   .   >rrrrrr}v?++>>??v?+|~------~vxzzzzz|<br></br>
`???????vvvvvvvwuuuuuuxw}}}rrv`   .. .?wrrrrrrrrv>~.   .~?vv?^~-|>rxzzzzzz~<br></br>
`????????????vvvxuuuuz}}rrrw?.      .vrrrrrrrrrrwww}v?>|` .~?vv?}xzzzwwwwz~<br></br>
`??????????????vvzuuu}}rrrr?.      `}rrrrrrrrrrrrrrrwwr}v?+` .|?vwwwwrrrrw~<br></br>
`?????????????????}wz}wrrr?.      `}wr}rrrrrrrrrrrrrrrrrrr}v?^. `}rrr}}}}r-<br></br>
`???????????????????vrrrw}` ...  .vrrrrrrrrrrrrrrrrrrrrrrrr}vvv?^}}}vvvvvv-<br></br>
`???????????????????}rrrr~       ~rrrrrrrrrrrrrrrvvr}}}}}}}rr}vv?vvvvvvvvv-<br></br>


            </div>,

            <div>

.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvv}}}rrrrrrrrrr}}-<br></br>
`vv???????????????????????????????????????????????vvv}rrwzzzxxuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????v}v???????????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`}}vvv????????????????????????}wxzw}vvv????????????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`rrr}vvv???????????????????vwxuuxxxxzw}}vv??????????vv}}rrwwzzxxxuuuuuuuuu|<br></br>
`wzwwr}}vvv??????????????vwxuuuuxxzzzwrr}}vv?????????vv}rrwwzzzxxxuuuuuuuu|<br></br>
`zxzzwwrr}}vvv??????????vxuxxxzvrzzzxxxxxzwr}v???????vv}rrwwzzzxxxxuuuuuuu|<br></br>
`xxxzzzwwwwr}}vvv??????vxuxzzzzwrzuuuuuuuxxxwr}}}vvvvvv}rrwwzzzxxxxuuuuuuu|<br></br>
`xuxxxxzzzwwwrr}vvvvv??wxzxxzxz??}uxxxxxxxxxxw}}r}}}}vvrrrwwzzzxxxxuuuuuuu|<br></br>
-xuuuuxxxxzzzzwwrr}}}vvwxxxzxv?ww}uxxxuuuuuxxzrrrrrr}vv}rwwwzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzwwrr}zuxrrw?vuyvwuuuxxxzzzzzwr}}}r}}vvvrzzzzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzz}zwzzzw?zyurvxzwwrrrwwzxzzzwr}}}}}}}}wzzxxxxxxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuxxxxwzxwwzrvxxwv}zwwzxxxuuuuuuuxxxzrrrv?vvv}rzxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxwzzwrv>}r}wzxxuuuuuuuuuuuuuxxzzwr}v?>>>???}wxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuzvvvrwrrzxxuuuuuuuuuuuxxxxxxxxzzzwrr}}?>>>>>>vxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxvr?wwxxuuxxuuuuuxxxxzzzwzzzzxzzzzrrrrr}?>>+++?}xxxuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuxxzxzxxxxxxxuuxxzzwrrrrrwwzzzzzzzzrrrr}rr?++++++>vvzuuuu|<br></br>
`xxxxuuuuuuuuuuuuuxxxxxwwzxuuuxzwrrrrrrrwzzzzzzzzzzzrrr}}}}v>+++++^>v}uuuu|<br></br>
`xxxxxxxuxuuuuuuuuzxxwwwzxxxxzwrrrrrrwwzzzzzzzzzzzzwrrrrrrr}v>>+++^^?v}rxu|<br></br>
`zzzzxxxxxxuuuuuuxxuuxzwwr}vv}rrrrrrrwxxzzzzzzzzzzwrrrrrrrrrr}vv?>+^?vvvru|<br></br>
`zzzzzzzxxxxxxxxxxuuurrwzwr}v}rrrr}wxrrzzrvvvvvvvv?????????vv}rrr?^^>vvvvr~<br></br>
`wwwwwzzzzzxxxxxzuuuzrxxwvv?vr}}}rzxzv?v?~````~>???>++>>>>>>>>??vv>^>vvvv?`<br></br>
`rrrwwwwzzzzzzzzzuxuxxzzv?v}r}v}rwwr}v?-...`.+}wrrr}v?>>>>>>>>>>>??^?vvvv?`<br></br>
`}}}}rrrwwwwwzzwzxxxxuxzzzwr}v?>?vvvvv~   ..+wwrrrrrrr}????>>>>>>>+>?v?vv?`<br></br>
`vvvvv}}}}rrwwwrzxxxxxuxuxz}?+~-?vv??^     ~rwrrrrrrrrrrrv????>>>>?rv?????`<br></br>
`v??vvvvvv}}}rrrwxxxxxxxxw?^-..>vvvv>.    `?vrrrrrrrrrrrrrv>+????vwzzv????`<br></br>
`???????vvvvvvv}}rxxxxxx}?|`..+vv?v?.     ^??rrrrrrrrrrrrrww>|+?rzwwww}???`<br></br>
`?????????????vvvvrzxxxv>~...^vv??>`    .>??vvrrrrrrrrrrrrw?``^+?wwwwwwrv?`<br></br>
`???????????????vvvvrzr?|...~???^`.     >vvvvvvrrrrrrrr}rr+`..`|?rwwwwwwr}-<br></br>
`???????????????????v?>+~.`-????.      .?v???vv}rrrrrrr}v|``..``-vwrrrrrrr~<br></br>
`????????????????????^^+>??????+       +vvvvvvvvrrrrrrr?`..``..`.`?r}}}}rr~<br></br>
`????????????????>??^|+???>>>++-      -v????????vrrrrrr|..........`vvvvvvv-<br></br>


            </div>,

            <div>

.?>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvv}}}rrrrrrrrrr}}-<br></br>
`vv???????????????????????????????????????????????vvv}rrwwzzxxuuuuuuuuuuuu|<br></br>
`vvv??????????????????????????v}v??????????????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`}}vvv??????????????????????}zxzzwwrvvv????????????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`rrr}vvv??????????????????}xuuxxxzzr}}}v????????????vv}}rrwwzzzxxuuuuuuuuu|<br></br>
`wzwwr}vvvv??????????????zuuxwwxzzxxxxzwr}vv?????????vv}rrwwzzzxxxuuuuuuuu|<br></br>
`zxzzwwrr}}vvv??????????}uxzzxx?vuuuuuuuzr}}}v???????vv}rrwwzzzxxxxuuuuuuu|<br></br>
`xxxzzzwwwwr}}vvv???????}xxxu}??}uuuuuuxxw}}}r}vv??vvv}}rrwwzzzxxxxuuuuuuu|<br></br>
`xuxxxxzzzwwwrr}vvvvv??rxzwzv}u?zuxzzwwwwwr}}}vv}?vvvv}rrrwwzzzxxxxuuuuuuu|<br></br>
-xuuuuxxxxzzzzwwrr}}}vzxz}}?}r??wwwwwzxxxxxxwr}}}}vvvv}rwwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxzzzzwww}zzw}v>vvrzxxxxuuuuuuuuuuzrrrr}vvv}wwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzzvvvv}wzxxuuuuuuxxuuuuuuuuuzrrr}}}}vrzzzzxxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuxxxxxvvv}wwzuuuuuuuuxxuuuuuuuuuzrrrr}v>^^?vrzxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxwr}rr}zuxxxuuuuuuuuuuuuuuuzrrrrr}?|.  .->wuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxwxz}v}}rwwxuuuuuuuuuxxxzzwrrrrrrrww}+.   .^ruuuyyuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuwxuwvwzxxzzuuuuuxzwrv??>>v}rrrrrrrrrrwv-     +r}v}uuuuu|<br></br>
-xuuuuuuuuuuuuuuuuxxuurzxzwrzxuuzrv>^~-``-^?rrrrrrrrrrrrrr}-         ?yuuu|<br></br>
`xxxxuuuuuuuuuuuuuwuuuuzwzxuux}?^~---``-+vrwrrrrrrrrrrrrr}rv         `zyuu|<br></br>
`xxxxxxxuxuuuuuuuuruxuuuuuxzv>~``---~+?}wwrrrrrrrrrrrrrrrrrr+.        `|}u|<br></br>
`zzzzxxxxxxuuuuuuuzxxuuuux}>-`----|~`+wwwrwrrrrrrrrrrrrrrrrrrv?>`       -u^<br></br>
`zzzzzzzxxxxxxxxuuxzuuuuz?-`-----``+}-^rwrrrrrrrrrrrrrrrrrrrrrww}`       |-<br></br>
`wwwwwzzzzzxxxxxxxxzxuuxv-`---`.`-?wr~ ~rr}rrrrrrrrrrrrrrrrrrrrrwv.        <br></br>
`rrrwwwwzzzzzzzzxxxxzzxx?`---` .?}vv?   `vvrrr}rrrrrrrrrrrrrrrrrrw>        <br></br>
`}}}}rrrwwwwwzzzzzzxxxzzr?>~.  .|~...    .+vwrrrrrrrrrrrrrrrrrrrrrr-       <br></br>
`vvvvv}}}}rrwwwwwwwzzzzzzw?.   `.          -vrrrrrrrrrrrrrrrrrrrrrw}-      <br></br>
`v??vvvvvv}}}rrrrwwwwwww}+                  `}rrrrrrrrrrrrrrrrrrrrrw}|     <br></br>
`???????vvvvvvv}}}rrrrr}^                   `wwrrrrrrrrrrrrrrrrrrrrrrw?.   <br></br>
`?????????????vvvvv}}vv^                    .^vrrrrrrrrrrrrrrrrrrrrrrrw}|  <br></br>
                  `????????????????vvv?v^                       .vwrrrrrrrrrrrrrrrrrrrrrrrw?.<br></br>
                  `???????????????????v^                         ^wrrrrrrrrrrrrrrrrrrrrrrrrw~<br></br>
`?????????????????>}?                          .vwrrrrrrrrrrrrrrr}}}}rrrrr~<br></br>
`????????????????|v}`                           +rrrr}}}}vvvvvvvvvv}}}rr}r-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrr}}-<br></br>
`vv??????????????????????????vv}}}v????????????????vv}rrwzzxxxuuuuuuuuuuuu|<br></br>
`vvv???????????????????????}zxxxwr}}???????????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv????????????????????}xzxzwxxzwrr}????????????vvv}rrrwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv??????????????????rxzwrrxxxzrrrvv??????????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv?????????????vzz}vwrwzzxxzzzwrv?????????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv?????????vwvv}wzxxxuuuuuuxw}vv???vvvv}}vv}rrrwzzzxxuuuuuuuuu|<br></br>
`xxxzzzwwwrr}}vvv??????v}?rxxxxxxuuuuuuuwr}}vv}rwwwrrrvv}rr}rrrwwwzxuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv??}z}}rrwuuxzw}}vvvv}}rrrrrrrrrr}}rrrrrrrrrrrrrwwzzww~<br></br>
-uuuuuxxxxzzzwwwrr}}}vvzrrwwzzr?>^~|``^|?rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwww|<br></br>
-uuuuuuuxxxxxzzzzwwwwrrxzzxxv+~---+||^..-+vww}v}}rrrrrrrrrv?+?}r}}}}}rrrrv-<br></br>
-uuuuuuuuuuuxxxxxzzzzwzxxuw>```~^>~`.    ..-?vvv}}rrr}}}}}rrv>~+vrr}vv>~`. <br></br>
-uuuuuuuuuuuuuuuxxxxxzwxuw^--`->?-...``|?}?^^?v}}rrrrrrrrr}}}r}?~~?r}|. .. <br></br>
-uuuuuuuuuuuuuuuuuxxxxxzx?~|``~>?vvvvrzzxxzwrrrwwwzzzzzzzzwwr}}rr>`-?wv~ . <br></br>
-uuuuuuuuuuuuuuuuuuuuuxzzv>>?}wvwr?v}}wwwww}}}}}rwwwwwwwzwwwwwr}}w}^.|rw>. <br></br>
-uuuuuuuuxzzxxxxxxxxxxzzzwwwzxwv}r}rr}}}}rwr}}}}rwwzzzzzzzzzzzww}vrw?``vw?`<br></br>
-xuuuuuuuzxxxxxzxxxxxxzzzwrrzrv}}zw}}v}}v??????vvv}rwzzzzzzzzzzzw}v}w}`.vr-<br></br>
`xxxxxuuuuzzxxxxxxxxxzwww}}r}>v}rzr}}}v>>>??vvv}}}}}}rwwwwwzzzw}}}vv}rv.`v-<br></br>
`xxxxxxxuuuzwwwwwwwr}}vvv????}r}rzr}}}vv}}r}rr}}}}}vv}}}wzzzzw}vvvvvvvr| ?-<br></br>
`zzzzxxxxxxxxxzwr}}rr}}}v??vr}}}rzr}}}rrr}}rvvv}}}}}}vvvwzwwrvvvvvvvvvr^ >-<br></br>
`wwzzzzxxxxxxxxxxxxwrwwrvv}}}}}}rzrrr}}}}r}vv}r}v??????}zw}vvvvvvvvvv}}- ?-<br></br>
`wwwwzzzzzzzzzzzzzwrrrrr}}}}}}}}wzr}v}r}}}v}rv????????vvr}}vvvvv}vvvv}? -v-<br></br>
`rrwwwwwwzzwzwwrr}rrrrwr}}r}}}}}rr?>>vr}}}}}v>>>????????}}}}vvvvvvvvvv`.?}`<br></br>
`}}}rrrrrrrwr}}}}}}rwzwrwr}}r}vvvv>>?}}}}}}}}}rv????????}}}}}vvvvvvvv-.?}v-<br></br>
`rrrrrrrrrrrrrrrrrrwwwwzzwr}v??vrv?vv}}}}}}}}}}}vvv?????v}}}}v}vvv}v-.>}vv-<br></br>
`rrrrrrrrrrrrrrr}}vvv}}wrv?|+v}vwwr}}}}}}vv??vvvvvvv}vv?}}}}v}vvvv?``?}vvv-<br></br>
`rrrrrrrrrrrr}}}vvvvvvv?>~.~vvvrw}}}}}}v??>>>?????vvvvv}v}}vvvvv?+~+v}vvvv-<br></br>
`rrrrrrrrrr}}vvvvvvvv??|. ~}vvrw}}}}}v??>>>>>?????????vvv}}v}vvvvvv}vvv?vv`<br></br>
`}}}}}}}vv}}vvvvvvv?>|.  `vv??rr}}}}vv?^>v????????????????v}wwwr}}vvvvv??v-<br></br>
`vvvvvvvv}}vvvvvv?>|.    -??????v????|~?r}vv????????>???|.~wwwrrrrr}}vv??v-<br></br>
`??????vv}}vvvvv?~.       `^???>>>~. -v}????vvvv???????|   >wr}}}}}vvvvv?v-<br></br>
`????????vvvvv?+.          .~-`...  ^}v???>>??????v?>^`    .?vvvvvvvvvvvvv`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>??^>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrr}}-<br></br>
`vv????????????????????????????}zxwrv??????????????vv}rrwzzxxxuuuuuuuuuuuu|<br></br>
`vvv??????????????????????????zuxxxxz}?????????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`r}vvv???????????????????????}xzzzwruxzv???????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv???????????????????vwz}}}rrwzwwrvv>????????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv????????????????r}}rwzxxxxxxxzrv????????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv????????????vr}vvwzxuuuuuuuw}vv??????vvvv}rrrwzzxxxuuuuuuuuu|<br></br>
`xxxzzzwwwrr}}vvv????????vzzvv}rwuuuuuuuuzr}vv??vvvvvv}}}rwzzzxxxuuuuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv???vxuxzzxzrvv???>??vv}v}}rrrrrrrrr}}rwzzxxxxuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwrr}}}vvvwuuuxv>^~-```~^``~?rrrrrrrrrrrrrrr}}}zxxuxuuuuuuuu|<br></br>
-uuuuuuuuxxxxzzzzwwwwrr}wuxw+----|^^+^`.. >}wr}}rrrrrr}}}}}}v}rwzxuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzzzzwwrxu?`--~^v?+.    .``>}rvrrrrrrrrrrr}}}}}vwzuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxxxxxzzzww?~-``+?.  ..`?v}- -?v}rrrrrrrrrrrrrrrrr}zuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxxxxxzrr}~ . ?^  .?}v}v?.   >}rrrrrrrrrrrrrrrrrrrwzxuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuxrrrr~`v?.?+   .>}}rv-   -}}rrrrrrrrrrrrrrrrrrrr}}zx|<br></br>
-uuuuuuuuuuuuuuuuuuuuxrrrr>|>}v`-v`   ~|-^+^.   ^>?rwrrrrrrrrrrrrrrrrrrrrr~<br></br>
-xuuuuuuuuuuuuuuuuuuuwrrr?.+v??^.v-   .            `>rwrrrrrrrrrrrrrrrrrww~<br></br>
`xxxxxuuuuuuuuuuuuuyzr}}}` .-   `v.                  `?rrrrrrrrrrr}rrrrrrw~<br></br>
`xxxxxxxuuuuuuuuuuuwrrrv>       `v.                    -}wrrrr}}}}rrrrrrrw~<br></br>
`zzzzxxxxxxxuuuuuzrrrrr}^       -v.                    .wrrrrvvv}wr}rrwrr}`<br></br>
`wwzzzzzxxxxxxxzrrrrrrrr`       -?                     `uuwrrvv}r}vrzwz}vv`<br></br>
`wwwwwzzzzzzwwrrrrrrrrwv        |?                     |yuuz}v?}r}rzzzr??r~<br></br>
`rrrwwwwwr}rr}}}}rrrrrw~        ~^                     |uuuuzv?}}wwrr}vvzz|<br></br>
`}}}rrrrrrrrrrrrrrrrrw?-?|      .-           .^|-.     -uuuuuw?vr}vvv}wwwz~<br></br>
`rrrrrrrrrrrrrrrrrrr}vvrw+      ^^           |zww}v>~` .zuxxxux}rrrrwwzzrr~<br></br>
`rrrrrrrrrrrr}vvvvvvvvwr?`     .v^           +v}rwwwwr?+wuxxxxxx}r}wzww}vv-<br></br>
`rrrrrrrrrr}vvvvvvv}}v>`       ~>.             .`~>vrwwrrxzzzzzzz}r}}v?-?v-<br></br>
`rrrrrrr}}vvvvvv??v?|.        `-                    `|?}}wzzzzzzzr}rvv? >v-<br></br>
`}}}}vvvv????????v|          .`.  .>>^|-`.             .-+wwwwwwwwr}vvv.^v-<br></br>
`vvv?????????????^      `~`  ``   `>>??vvv?>^-.           +wrrrrrrr}?vv>`?-<br></br>
`???????????????+    `+?v?. .~.        ..`|+??v?+-.        ?r}}}}vvvvvv?^?-<br></br>
`???????????????. .^?vv+`.  `-   .>?+^|-.    .`~>??^`      .vvvvvvvvvv??vv-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv??????????????????????????????vwxxzv?>??????????vv}rrwzzxxxuuuuuuuuuuuu|<br></br>
`vvv????????????????????????????vxxxxzz}?>?????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????}wwrwrrrxz??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv??????????????????????}rv}rrzzzzw}?>???????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????????}zzv?vwxuuuxzrv???????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv??????????????zuxv}}wwxuuuuuz}vv?????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`xxxzzzwwwrr}}vvv??????????zuxuxzzzzzzzxxuzrvvv????vvv}}rrwzzzxxxxuuuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv?????vuuxrv>+|||||^>??vvvv??vvvv}rrrwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwrr}}}vvvv?vuz?---------~--~~>v}}rrrr}}}rwwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxzzzzwwwwrrr}vvwv``--~|?v>-....~}wrr}rrrrrr}rwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzzzzzwwwrr?+>^~`->~.     .|rwr}rrrrrr}v}zxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxxxxxxzwrrr}>`   +^     |?v`-}rrrrrrrr}}v}xxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxxxzrrrwv. ~>` ^>  .??v}v. `?wrr}rrrrrr}}wuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuxrrwr?~>>}v`  +`  `v}}v-  .?wr}rrrrrrrrvwxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuzrwrw?..?}}v.  |-   |-^?+    >w}}rrrrrrrrrrrzxuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuxrrrr}r`  `~^?^  |~             +}rrrrrrrrrrrrrrwwzxuuuu|<br></br>
`xxxxxuuuuuuuuuuxwrrrrrvv.         |~              |}rrrrrrrr}}vvv}rrrrwwz|<br></br>
`xxxxxxxxxuuuuxwrrrrrrrvv.         |~               ?rrrrrrr}vvv}}rrrrrrrr~<br></br>
`zzzzxxxxxxxzrrrrrrrrrr}?.         |~               `zwrrrrrvvrrrrrrrrrrrw~<br></br>
`wwzzzzzwwr}}}rrrrrrrrr}v.         --                vyzrrrrvrrrrrrrrrrrrw~<br></br>
`wwwwrrrrrr}}}}rrrrrrrwzz.         ~`          ~`.   |yuxrrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrr}rrrr}rzxuuw``.      -v`         .wwv?~. wyuxrrrrrrrrrrrrrrrw~<br></br>
`}rrrrrrrrrrrr}rrrwxxxxwrr}`     -?~          .~>vrwv|?yuuxwrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrr}wzzzx}??^`      ``               `^vrvxuxxxw}}}}}}}}}}rrw~<br></br>
`rrrrrr}}}}vvvv}rwwwz?.           `.                 `>}uxxxxzrvvvvvvvvvv}-<br></br>
`rr}}vvvvv??vv}}}}}r^       .`   .`.   ||`..           `rxzzzxxzwr}vvvvvvv-<br></br>
`vvvv?????????vvvvv-     `^?v+   ``   `vvvvv?>~`.       `wzzzzzzzzzwrr}vvv`<br></br>
`vv??????????????v?   ->?v?+|.  .~.      ..`-^>??>|.     ~wwwwwwwwwwwwrrr}-<br></br>
`??????????????????^.??^`....   `~    +?>>^~`.  `^?v?~    ?wrrrrrrr}}}}}}}~<br></br>
`???????????????????`..-^?vv-   `.   .^+>??vv??^-. .^??. -vr}}}}}vvvvvvvvv-<br></br>
`??????????????????+->vv?+|`. ........   ..`~+?vv?>-  -.|vvvvvvvvvvvvvv???`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv??????????????????????????????vwxuzv?>??????????vv}rrwzzxxxuuuuuuuuuuuu|<br></br>
`vvv????????????????????????????vxzxxzz}?>?????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????}wwrrrrrxz??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv??????????????????????}rv}rrzzzzw}?>???????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????????}zzv?vwxuuuxzrv???????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv??????????????zuxv}}wwxuuuuuz}vv?????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`xxxzzzzwwwr}}vvv??????????zuxuxzzzzzzzxxuzrvvv????vvv}}rrwzzzxxxxuuuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv?????vuuxrv>+|||||^>??vvvv??vvvv}rrrwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwwr}}}vvvv?vuz?---------~--~~>v}}rrrr}}}rwwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxzzzzwwwwrrr}vvwv``--~|?v>-....~}wrr}rrrrrr}rwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzzzzzwwwrr?+>^~``>~.     .|rwr}rrrrrr}v}zxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxxxxxxzwrrr}>`   +^     |?v`-}rrrrrrrr}}v}xxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuzrrrwv. ~>` ^>  .??v}v. `?wrr}rrrrrr}}wuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuxrrwr?~>>}v`  +`  `v}}v-  .?wr}rrrrrrrrvwxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuzrwrw?..?}}v.  |-   |-^?+    >w}}rrrrrrrrrrrzxuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuxrrrr}r`  `~^?^  |~             +}rrrrrrrrrrrrrrwwzxuuuu|<br></br>
`xxxxxuuuuuuuuuuxwrrrrrv}.         |~              |}rrrrrrrr}}vvv}rrrrwwz|<br></br>
`zxxxxxxxxuuuuxwrrrrrrrvv.         |~               ?rrrrrrr}vvv}}rrrrrrrr~<br></br>
`zzzzxxxxxxxzrrrrrrrrrr}?.         |~               `zwrrrrrvvrrrrrrrrrrrw~<br></br>
`wwzzzzzwwr}}}rrrrrrrrr}v.         --                vyzrrrrvrrrrrrrrrrrrw~<br></br>
`wwwwrrrrrr}}}}rrrrrrrwzz.         ~`          ~`.   |yuxrrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrr}rrrr}rzxuuw``.      -v`         .wwv?~. wyuxrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrr}rrrwxxxxwrr}`     -?~          .~>vrwv|?yuuxwrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrr}wzzzx}??^`      ``               `^vrvxuxxxw}}}}}}}}}}rrw~<br></br>
`rrrrrr}}}}vvvv}rrwwz?.           `.                 `>}uxxxxzrvvvvvvvvvv}-<br></br>
`rr}}vvvvv??vv}}}}}}^       .`   .`.   ||`..           `rxzzzxxzwr}vvvvvvv-<br></br>
`vvvv?????????vvvvv-    .`^?v+   ``   `vvvvv?>~`.       `wzzzzzzzzzwrr}vvv`<br></br>
`vv????????????????   ->?v?+|.  .~.      ..`-^>??>|.     ~wwwwwwwwwwwwrrrr-<br></br>
`??????????????????^.??^`....   `~    +?>>^~`.  `^?v?~    ?wrrrrrrr}}}}}}}~<br></br>
`???????????????????`..-^?vv-   `.   .^+>??vv??^-. .^??. -vr}}}}}vvvvvvvvv-<br></br>
`??????????????????+->vv?+|`. ........   ..`~+?vv?>-  -.|vvvvvvvvvvvvvv???`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv???????????????????????????????vrw}?????????????vv}rrwzzxxxuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????zxxxxzv>??????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????vwzwrwwwrw??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv?????????????????????vzrrrvvrwwzzv?>???????vvv}}rrwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????????rxxxrv?rzuuxzr????????vvv}}rrwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv?????????????}xuuur}rrrzuuuuxw}??????vvvv}rrwwzzxxxuuuuuuuuu|<br></br>
`xxxzzzzwwwr}}vvv?????????}uuuuxxwrrrwzzxxuz}v????vvvv}}rrwzzzxxxxuuuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv?????xuxr?>^~~--~~|+>?}}vv??vvvv}rrrwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwwrr}}vvvvvuz?-`----~~~-~-`--+vvvvvvv}}rrwwwzzzxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxzzzzwwwwrrr}vw?``~~~~+?v?~~~-|?}rrrrrr}}rwwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxxzzzzzww}rrvv?^--..>+. ..  .|rrrrrrr}}wwzzzxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxxxxxrrrrww+.  . .?.    .~+|-}wrrrrr}}wzzxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuwrrrrr+..^v? .?. .?v?}}+ `vwr}rrrrrzxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuxrrrr?``}?vv~  ~|  `vr}}-  .?r}rrrr}xxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuwr}rv.  |v}r^  `+   ^^>v?.   +v}rrr}zxxuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuwrr}}?   .`~++. `+   .   .     >}rrr}wuxuuuuuuuuuuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuxrrr}v+          `^             |}rrrrruuuuuuuuuuuuuuuuu|<br></br>
`zxxxxxxxxuuuuuuuzrrrrrv-          -^             -}rrrrrwuxuuuuuuuuuuuuuu|<br></br>
`zzzzxxxxxxxxxxwwrrrrrr}`          -^              >rr}}}}wrrzzxxxxuuuuuuu|<br></br>
`wwzzzzzxxxxwwr}}}rrrrwv.   .      .-        +>~.  .?rr}}rrrrrrrrrwwwwzzzz|<br></br>
`wwwwwzzzzzrrrrrr}}}rrwv `+?v.     -^       .}ww}?| ~w}rrrrrrrrrrrrrrrrrrr~<br></br>
`rrrrwwrrrrrrrrrrr}vvrwvvrrv?.     ?^        `^?}wwv~vrrrrrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrrrrrrrrr}v>-.      -v.           .->}?~rr}}}}}}vvvvv}}}}}rr-<br></br>
`}rrrrrrrrrrrrrrr}}}}?-`          .~               .` ?}vvvvvvvvvvvvvvv}r~ <br></br>
`}}v}}}rrrrrrr}vvvvv+.             -.                 `vvvvvvvvvvvvvvvv}+  <br></br>
 -vvvvv}rrrr}vvvvvv|        ..`    `.   |^~`..         |vvvvvvvvvvvvvvvv`  <br></br>
...vv?vvrrr}vvvvvv+     .-^???+    `.   ~+>???>^~.      +}vvvvvvvvvvvvvv`  <br></br>
-> ^}vvv}}vvvvv??v>  .|???>~`.    .-.   ......`|>??+-    }wrrr}vvvvvvvvv-  <br></br>
`v..vvvvvvvvv??????|`?>~``-^>>.   .-   .?vvv?>~`  `+??. |rrrrrrr}}vvvv?v^  <br></br>
`v..vvvvvvv?????????``~+???>^-  .........``|+??vv>|..`->vv}}}}}}vvvvvvvv?>`<br></br>
`?..vvvvv??????????>>??+-`.   .............   .-+?v?>-.?vvvvvvvvvvvvvv???v-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv???????????????????????????????vrr}?????????????vv}rwwzzxxxuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????wxxxxzv>??????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????vwzwrwwwrw??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv?????????????????????vzrrrvvrwwzzv?>???????vvv}}rwwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????????rxxxrv?rzuuxzr????????vvv}}rwwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrr}}vvv?????????????}xuuur}rrrzuuuuxw}??????vvv}}rwwwzzxxxuuuuuuuuu|<br></br>
`xxxzzzzwwwr}}vvv?????????}uuuuxxwrrrwzzxxuz}v????vvvv}}rwwzzzxxxuuuuuuuuu|<br></br>
`xuxxxxzzzzwwrr}}vvvv?????xuxr?>^~~--~~|+>?}}vv??vvv}}rrwwwzzzxxxuuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwwwr}}vvvvvuz?-`----~~~-~-`--+vvvvvv}}}rwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzzwwwrrr}vw?``~~~~+?v?~~~-~?}rrrrrr}}rwwwzzzxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxxxzzzzzwrrrvv?^--..>+. ..  .|rrrrrrr}}wwzzzxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxxxxxrrrrww+.  . .?.    .~+|-}wrrrrr}}wzzxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxuwrrrrr+..^v?  ?. .???}}+ `vwr}rrrrrxxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuxrrrr?``}?vv~  ~|  `vr}}-  .?r}rrr}}xxxxuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuwr}rv.  |v}r^  `+   ^^>v?.   +v}rrr}zuuuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuwrr}}?   .`~++. `+   .   .     >}rrr}wuuuuuuuuuuuuuuuuu|<br></br>
`xxxxxuuuuuuuuuuuuxrrr}v+          `^             ^rrrrrruuuuuuuuuuuuuuuuu|<br></br>
                  `zxxxxxxxxuuuuuuuzrrrrrv-          -^             ~rrrrrrwuuuuuuuuuuuuuuuu|<br></br>
`zzzzxxxxxxxxxxwwrrrrrr}`          -^              >r}}}}}wrrzzxxxuuuuuuuu|<br></br>
`wwzzzzzxxxxwwr}}}rrrrwv.   .      .-        +>~.  .?rr}}rrrrrrrrrwwwwzzzz|<br></br>
`wwwwwzzzzzrrrrrr}}}rrwv `+vv.     `^       .}ww}?| ~wrrrrrrrrrrrrrrrrrrrw~<br></br>
`}rrrwwrrrrrrrrrrr}vvrwvvrrv?.     ?^        `^?}wwv~vwrrrrrrrrrrrrrrrrrrw~<br></br>
`rrrrrrrrrrrrrrrrrrrrrr}v>-.      -v.           .->}?~rr}}}}}vvvvvv}}}}}rr-<br></br>
`}rrrrrrrrrrrrrrr}}}}?-`          .~               .` ?}vvvvvvvvvvvvvvv}r~ <br></br>
`}}v}}}rrrrrrr}vvvvv+.             -.                 `vvvvvvvvvvvvvvvv}+  <br></br>
 -vvvvv}rrrr}vvvvvv|        ..`    `.   |^~`..         |vvvvvvvvvvvvvvvv`  <br></br>
...vv?vvrrr}vvvvvv+     .-^???+    `.   ~+>???>^~.      +}vvvvvvvvvvvvvv`  <br></br>
-> ^}?vv}}vvvvv??v>  .^???>~`.    .-.   ......`|>??+-    }wrrr}vvvvvvvvv`  <br></br>
`v..vvvvvvvvv??????|`?>~``-^>>.   .-    ?vvv?>~`  `+??. |rrrrrrr}}vvvv?v^  <br></br>
`v..v?vvvvv?????????``~+???>^-  .........``|+??vv>|..`->}}}}}}}}vvvvvvvv?>`<br></br>
`?..vvvvv??????????>>??+-`.   .............   .-+?v?>-.?vvvvvvvvvvvvvv???v-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv???????????????????????????????vv}v?????????????vv}rwwzzxxxuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????wxxxzw????????????vvv}rrwwzzxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????vrzwrwwwvv??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv?????????????????????vzwr}vvrwwwzv?????????vvv}}rwwwzzxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????????wxxxzv?vzxuxz}????????vvv}}rwwwzzxxxuuuuuuuuu|<br></br>
`zzzzwwrrr}vvv?????????????rxuuuz}rrrrxuuuxwv??????vvv}}rwwzzzxxxuuuuuuuuu|<br></br>
`xxxzzzzwwrr}}vvvv????????wuuuuxzw}}}rwzzxuxrv????vvvv}}rwwzzzxxxuuuuuuuuu|<br></br>
`xuxxxxzzzzwwrrr}}vvv????vuuzv>^|~----~~^>?}r}???vvv}}rrwwwzzzxxxuuuuuuuuu|<br></br>
-uuuuuxxxxzzzzwwwwr}}vvvvrxv~``----~~~-~-``-+vvvvv}}}rrwwwzzzxxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzzwwwrrr}}}~`~^~~~>?v?|~|~^?vrrrrr}rrrwwwzzzxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxxxzzzzwrrr}v}>|``..^>`... ..|}wrrwrrwwwzzzzxxxxxuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuxxxxxwrrrrwr|   `. |~     .||`vwrrrrrwzzzxxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuzrrrrwv^`.^v}. ||  >v>v}? `vw}rrrrzzxxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuwrrw}+.`rvvv+  -+  `v}}}^  .?}rrrrrxxxuxuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuurr}r>   -v}r?  `>   |>?}v.   ?}rr}rxxuuuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuwrr}v|   ..`|^. .>   .  ...   ^vrrrwxuuuuuuuuuuuuuuuuuu|<br></br>
`xxxxxxuuuuuuuuuuuuzrr}v`          `>            |}rrrzuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zxxxxxxxxuuuuuuuuxrrrr?.          -?            .vrrrzuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzxxxxxxxxxuxxxr}rrw?           `>       ~|`.  ~rr}rwrxxuuuuuuuuuuuuuuu|<br></br>
`wwzzzzzxxxxxxzwrrr}rrw> .~?v`     .-      .vwrv>` ?r}rrrrrrwwwwzzzzzzxxzz|<br></br>
`wwwwwwzzwwwwwrrrrr}}}r+|vrr}^     `>       `+vww}+~vrrrrrrrrrrrrrrrrrrrwz|<br></br>
`rr}rrrrrrrrrrrrrrrrr}}}wr?^.      +>         .-?}w^+wrrrrrrrrrrr}}rv>vwwz|<br></br>
`v?vrwrrrrrrrrrrrrrrrr?v^`        .?|            .^-.?}v}}}rrrr}v}}^~?wwwz|<br></br>
`v?+~^vr}}rrrrrrrr}}v|             ~`                `vvvvvv}}vvvv-~vvrwzw~<br></br>
`wwr}>-~v}}}rrrr}vv?-              `-                 |}vvvvvvvvv~ ?v?}www~<br></br>
`rwww}}-`}v}}r}vvv>.        .`-`   .`   .^^~`..        +}vvvvvvvv`.vvvrw}v-<br></br>
`vrwwv}? >vvvvvvvv-     .~+???>`   .`    ~|>???>^-.     ?}vvvvvvv~ ?vvv}v}~<br></br>
`w}}}vv?.^vvvvvvvv>  .^???^-...    `-    `-....`|>??^.  ~vvvvvvvv?.-vvvv?v-<br></br>
`wv?vv??.+vvvvvv???|`?>~-~^???+    ..    >??v?>^-..`>+.|vr}}vvvvvv?~?vvv??`<br></br>
`r??vv?^`vvvv???????`-^??>^~`.. ............`~+?vv?^`.|v}}}}}vvvvvvvvvvvv?`<br></br>
`v?????+?v???????????>^`.....................  .`~>vv>|?vvvvvvvvvvvvvv???v`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`vv???????????????????????????????v}}v?????????????vv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????wxxxzw????????????vvv}rwwwzxxxuuuuuuuuuuu|<br></br>
`r}vvv????????????????????????vvrzwrwwwvv??????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvvv????????????????????vzwrrvvrrwwzv?????????vvv}}rwwzzxxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv?????????????????vwxxxzv?vzxuxz}????????vvv}}rwwwzxxxxuuuuuuuuu|<br></br>
`zzzzwwrrr}vvv?????????????rxuuuz}rrrrxuuuxwv??????vvv}}rwwzzzxxxuuuuuuuuu|<br></br>
`xxxzzzzwwwr}}vvvv????????wuuuuxzw}}}rwzxxuxrv????vvvv}rrwwzzzxxxuuuuuuuuu|<br></br>
`xuxxxxxzzzwwrrr}}vvv????vuxzv>^|~----~~^>?rrv???vvv}}rrwwwzzzxxxuuuuuuuuu|<br></br>
-uuuuuxxxxxzzzzwwwr}}vvvvrxv~``----~~~-~-``-+vvvvv}}}rrwwwzzzxxxxxuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzzwwwrrr}}}~-~^~~~>?v?|||~^?vrrrrr}rrwwwwzzzxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxxxzzzzwr}r}v}>|``..^>`... ..|}wrrwrrwwwzzzzxxxxuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuxxxxxwrrrrwr|  .`. ~~     .||`vwrrrrrzzzzxxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuzrrrrwv^-.^v}. ~|  >?>v}? `vw}rrrrzxxxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuwrrw}+.`rvvv+  `>  `v}}}|  .?}rrrrwxxxuuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuurr}r>   -v}r?  `?   |>?}v.   ?}rr}rxuuuuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuwrr}v|   ..`~^. .>   .  ...   |vrrrwxuuuuuuuuuuuuuuuuuu|<br></br>
`xxxxxxuuuuuuuuuuuyzrr}v`          .>            |}rrrzuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zxxxxxxxxuuuuuuuuxrrrr?.          -?            .vrrrzuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzxxxxxxxxxuxxxr}rrw?           `>       ~|`.  ~rr}rwrxxuuuuuuuuuuuuuuu|<br></br>
`wwzzzzzxxxxxxzwrrr}rrw> .~?v`     .-      .vwrv>` ?r}rrrrrrwwwzzzzxxxxxzz|<br></br>
`wwwwwwzzwwwwwrrrrr}vvr+|vrr}^     `>       `+vww}+~vrrrrrrrrrrrrrrrrrrrwz|<br></br>
`rr}rrrrrrrrrrrrrrrrrr}}wr?|.      +>         .-?}w++wrrrrrrrrrrr}}rv+vwzz|<br></br>
`v??}wrrrrrrrrrrrrrrrr?v^`         ?^            .^-.?}v}}}rrrr}v}}^~?wwzz|<br></br>
`v?^~^vr}}rrrrrrrr}}v|             ~`                `vvvvvv}}vvvv-~vvrwzz|<br></br>
`wwr}>-|v}}}rrrr}v}?-              `-                 |}vvvvvvvvv~ ?v?}wzz|<br></br>
`rwww}}-`}v}}r}vvv>.        .`-`   .-   .^^|-..        +}vvvvvvvv`.vvvrwzw~<br></br>
`}zzzv}? >vvvvvvvv-     .~+???>`   .`.   ~^+???>^-.     ?vvvvvvvv~ ?vvv}wx|<br></br>
`wwwrvv? ^vvvvvvvv>  .^??>^-...    .-.   `-....`|>??^.  ~vvvvvvvv?.-vvvvvw|<br></br>
`wvvvv?? ^vvvvvv???^`?+~-~+???+    ..    >?vv?>^-..`>+.|vr}vvvvvvv?~?vvvvv-<br></br>
`rvvvv?^`?vvv???????`-+??>^~`...............`~+?vv?^`.|v}}}}}vvvvvvvvvvvv?`<br></br>
`vvv???^?v???????????>|`.....................  ..~+vv>|?vvvvvvvvvvvvvv?v?v`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvv}}}}rrrrrrrrrrr}-<br></br>
`v?????????????????????????????????vv??????????????vv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????????}zzxwv????????????vvv}rwwwzxxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????????vwxzwwzwv???????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvv?????????????????????vzzzwrvvwzzwv?????????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`zzwwr}}vvv?????????????????vzxxxxrv?rxuxz}v???????vvv}}rwwzzxxxxuuuuuuuuu|<br></br>
`zzzzwwrrr}vvv????????????vwxuuuuz}wwrzuuuuzv??????vvv}rrwwzzxxxxuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvv????????xuuuxzw}vv}}rwxxuuwv????vvvv}rwwwzzxxxxuuuuuuuuu|<br></br>
`xuxxxxxzzzwwwrr}}vvv????ruxr?+|~--``--~^>?rr}???vvv}}rrwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuxxxxxzzzzwwwr}}vvvvzz>-``-~~-~~~-~-``-+vvvvv}}rrrwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzzwwwrrr}}?~|++~~~>vv?||~~+>?}}}}rrrrwwwwzzzxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxxxzzzzwrrrrrr>~`. .~+```..`-+rwrrrrwwwwzzzzxxxxuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuxxxxzrrrrrwv~  .`. .|      `--vwrrrrwzzzxxxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxrrrrrr>`|-~vr~ .^  +?+>vv .vrrrrrzxxxxxxuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxrr}w?- .vrvv>. .>  `vr}}+  .?rrrrzxxxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxrr}v`   `?vrv. .?   ~>?rv.  |v}rrzxuuuuuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuzrr}?.    ..-|. .?   .  .`.  ~?}rrzxuuuuuuuuuuuuuuuuuuu|<br></br>
`xxxxxxxuuuuuuuuuuuxrr}>           .?.          ~?rrzxuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zxxxxxxxuuuuuuuuuuurrr+           -?       .   .?rrxuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzxxxxxxxxxxxuuxw}rw^  .~>`     `^      ~v?^` |}}zxuuuuuuuuuuuuuuuuuuuu|<br></br>
`wwzzzzzxxxxxxzwwwrrrrr-.+v}}|      |      `?}wr?`?}rrwwwwwwwzxxxxxxxxxxxx|<br></br>
`wwwwwzzwwwwwwrrrrrrr}v^vr}?~.     .?        .^vr}>v}rrrrrrr}}rrwwwwwwwwzz~<br></br>
`rwwwwwwrv??}rrrrrrrrrrrv>`..      |?           ~?^^}rr}}}??vrwwwwrr}v}rrr|<br></br>
`vvvrwwwwr}?++vr}}rrr?>+.          ^?             ..+}v}v^?}rwwrrr}?>vrwwz|<br></br>
`v???vrwrrwwr?^?}v}}>.             .~                ^v}+>}}rrrr}v>?}rzxzx|<br></br>
`zwr}v?v}rrrwrv-?vv|               .-.                >}|?vrrrr}}??v}rwzww|<br></br>
`rwrwwr}}wrr}}v`>v|         .`--   .-.   |>+|-.       .?+^v}}}rrr?>v}wwzwr~<br></br>
`vrw}vvv?rr}vv?`vv`     `|>??>^`   .-.   .-|+>??>^`.   `?>vvvv}r}?>}rwrr}r|<br></br>
`wrwww}?>}rvv?~+vv+  `+??>|-``-.   .-.   .|~-`.``|>?^ .|?vvvvvvv}}??}}vvvv~<br></br>
`w}rwwvvvvvv???vvvv|`?+||+>???>-   ...   .^>>???+~``|~+?vvvvvvvvvvvv}r}vvv-<br></br>
`rvvvv}vv???????????-|>?+|-`..................`|>???+-?}vvvv}vvvvvvv}wwwr}-<br></br>
`v??vwr??????????????|`......````````````````... .`~>??vvvvvvvvvvvvvvvvvw}-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>????vvvv}}}rrrrrrrrrrr}}-<br></br>
`vv?????????????????????????????}wzxxz}???????????vvv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????v}}wzrrrzzrv>?????????vvv}rwwzzxxxuuuuuuuuuuu|<br></br>
`r}vvv??????????????????????vzxxw}}}vrxxz}?????????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvvv??????????????????rxuuuxzzzzzuuuuxr???????vvv}}rwwzzxxxuuuuuuuuuu|<br></br>
`zzwwr}}vvv???????????????zuuxwv??>>>>??vwxx}??????vvv}}rwwzzxxxxuuuuuuuuu|<br></br>
`zzzzzwrrr}vvvv??????????}uzv+~-~~~---~~~|>vrv????vvvv}rrwwzzxxxxuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvv???????wr|``-~~~~||~-~~---+v???vvvv}}rwwzzzxxxxuuuuuuuuu|<br></br>
`xuxxxxxzzzwwwrr}}vvv??vv}^`~|~||+?vv?|~~-|~|?v}vvvv}}rrwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuxxxxzzzzzwwr}}}}rrr}}v>~..`|+>~...`~+?}wrrr}}rrrwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxzzzzwwrrrrrwr>.  .~. .^     `~`.?wrrr}rwwwwzzxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxxxzrwrrr}>`-+~+vv. .> .??>v}|  +rrrrrrzzzzxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuxwrw}rv`  `}}}v^  .>  |vv}?.   >r}rr}wzxxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxwrrr}}^    `^?}?  .?   |^?v`   .v}rrrrzxxxxuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuzrrrr}?`       .`  .?.     ..    ^}rrrrwxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuzrrrrr}?            .?.           .vrrrrrzuuuuuuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuxwrwrrrrr+             ?-       .    >rrrrrrzuuuuuuuuuuuuuuuu|<br></br>
`xxxxxxuuxxxz}}rrrrrr}~   .~^.      ~`      `v?|. `}rrrrr}wxxuuuuuuuuuuuuu|<br></br>
`zxxxxxxwwrrrrrrrrr}}r` .^vr}~      +.      ~vww}>`+rrrrrrrr}zxuuuuuuuuuuu|<br></br>
`zzzzzwrrwwrrrrrrr}vwr.|vwv>`      .v.       .~?vw}-v}}rrrrrrrrwzxxuuuuuuu|<br></br>
`wwrrrrrrrrrrrr}}v}zu}vrv^.        `v.          .^v~~v}rrrrrrrrrrrrwxxxxxx|<br></br>
`rrrrrrrrrrrr}vvv}zxr+}^.          `?.            ...?}v}}rrrrrrrrrrrrrwwz|<br></br>
`rrrrrrrrrr}vvvv}zxw` .             -.               `?}vvvv}rrrrrrrrrr}?>`<br></br>
`rrrrrrrrr}vvvvrwzr-                ``   .            `v}vvvv}}rrrrrr}>`->`<br></br>
`}rrrrrrr}vvvv}ww}`        .`-|+^   .`   >??>|`.       `vvvvvvv}rrr}v-.>rw~<br></br>
.?v}rr}}}vvvv}}}r^      `^>??+~`.   .`   ..`~^+>>>|`    ^zw}vvvvvvv?- >rv}~<br></br>
 .>vvvvvvvvvv}vvvv`  .~>?^-`.`~^-   ``   .>^~-`..-^>>` -}zzzw}vvvvv? -vvv}~<br></br>
`| ?vvvvvv??vv???v?-`>|`-^>???>+`   ..   .^^>????>~``-~rwwwwwwr}vvvv^+vvvw|<br></br>
.v`-vvvv????????????--+??+~`.......```..........-^?v?^~}wrrwwwwwrvvvvv??v}~<br></br>
`v++????????????????>^-.....``------~~~~------``....->?vrrrrrr}}}}}vvvvvvv~<br></br>
`??????????????????>`.````-----~~~-----------------`..+v}}}}}}vvvvvvvvvvv?`<br></br>
`??????????????????>`----~--------------------------``-vvvvvvvvvvvvvvvv???`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???>>>>>>>>>>>>>>???vvvvvv}}}rrrrrrrr}}}-<br></br>
`v????????????????????????????v}rrzzzw}vv?????????vvv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv???????????????????????v}wxxxzwwwzxzzrv????????vvv}rwwzzxxxuuuuuuuuuuu|<br></br>
`r}vvv????????????????????vzxzw}?>+++>?v}rzr???????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`wwr}vvvv????????????????vwz}??>>>+++>>>?>?vv??????vvv}rrwwzzxxxuuuuuuuuuu|<br></br>
`zzwwr}}vvv??????????????}w??>>+^|||||||+>>>??????vvvv}rrwwzzxxxxuuuuuuuuu|<br></br>
`zzzzzwrrr}vvv???????????}?>^-~|~^+>+^~~~--|^?????vvvv}rrwwzzxxxxuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvv???vvvvrv>>^---+>>>+-..`~^>v}}}}}}}}}rwwzzzxxxxuuuuuuuuu|<br></br>
`xuuxxxxzzzzwwrr}}vv}}rrrr?|`...`..~^..  .---+vrrrrrrrrwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuxxxxzzzzwwwr}rrrrv+-+?|~^~ .`^.-+|+?+`-~>}rrrrrrrwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxxzrrrrrrr?--`~?+++`  .> .++^>` .`->}rrrrrrrwzxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuxzzzwwrrrrr^`.. `->v^  .?. -|+v>.  .~?r}rrrrrrwzxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuxzwwwwrrr}}}v`   .`....  .?. .````    .`?}}rrrrrrwwzxuuuuuuuuuuu|<br></br>
-uuuuuuuxzwwwwwwwrr}}v|     ...`.   ?`  ..-`.    .-v}}rrrwwwwwwzuuuuuuuuuu|<br></br>
-uuuuuxzwwwwwwwwwr}}}v`         .   |`           .`+r}}}rrwwwwwwwzuuuuuuuu|<br></br>
-uuuxwwwwwwwwwwr}}rwwv.    ..       ..        .`. .`vwwr}}rrwwwrrrwxuuuuuu|<br></br>
-xxwrrrrrwwwwr}}rwzzx>.  `|+>.      .`        ^>+|^~^zzzzwrrrrrwrrrwwzxuuu|<br></br>
`zzrrwwwrrr}}}rwzzzzw- `^>??|.      `~      .`|>>>?}?vzzzzxzwr}rwwwwwrrxuu|<br></br>
`zzrrrrr}}}rwzzzzzwzv~^>??^|-.      +`      .`---~^vr?zzzzzzzxw}}rrrwwwwzx|<br></br>
`zwr}}}v}rwwwwzzwwww+v?>|`-`.       >`        ..`-``??vzzzzzzzzw}}}}rwwwwz|<br></br>
`r}}}}}}wwwwwwwwrrw?~^|-`.          -.           .`  `|wzwzzzzzzzr}}}rrwww~<br></br>
`}r}}}rwwwwwrrrrrzv-`...            ``               ..^wwwzzzzzzzr}}rrrrw~<br></br>
`rrr}}rwwwrr}}}rwr~`.       ..```.  .`   `-``...      ..^wwwwwwzwwww}}rr}v-<br></br>
`rr}}rrwrr}}}}}ww^`.     ..`...     .`   .....`````.   ..^rrrrrwwwwww}?>?v-<br></br>
`}}}}rrrr}vvv}rwr~    .``.. .`~^^.  .`   .>>^~`....``.  .>rrrr}rrwwrr?^?rw~<br></br>
.?vv}vvvvvvvv}}}r?`. .`..~+???>^`.  ..   .~|^>???>^`... >xzwr}}}}}}}?^v}v}~<br></br>
.~>??v??vvvvvvvv}v>|..~+?>^-`....   .`.......  .`~>??|.|zzzzzr}}}}}}^>vvv}~<br></br>
`+`????????????vvvv>~>^-......```...````..`````.....`^>?zzzwwwwr}}}v??vv}w~<br></br>
.?~^v?????????????vv+....``.``-````----------```````..^rwwwwwwwwr}}}}vvv}r~<br></br>
.?>>????????????????^`````----~~~~~~~~~~--~~~-----```.-vrrrrrr}}}}}vvvvvv}-<br></br>
`???????????????????``~---~~~~~~~-~~~~~~--~~~~~~~~~--``?}}}}}}vvvvvvvvvvvv`<br></br>
`??????????????????>----~~-------------------------```-|vvvvvvvvvvv???????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>v}}}}v?>>>>>>>>>>>????vvvv}}}rrrrrrrrrrr}}-<br></br>
`vv????????????????????????????wxxxxxxxwv?????????vvv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????}zxxxxxxxxxzrv????????vvv}rwwzzxxxuuuuuuuuuuu|<br></br>
`r}vvv?????????????????????vzzrwr}rrr}rwrrzv???????vvv}rrwzzzxxxuuuuuuuuuu|<br></br>
`wwr}vvvv?????????????????vxxw}}vv}}vvv}v}}v???????vvv}rrwwzzxxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv???????????????vzwwwwzwvvvrzzwwrvv?????vvv}}rrwwzzxxxxuuuuuuuuu|<br></br>
`zzzzzwrrr}vvv???????????vwwzzzzz}vvvvrzzzzzr}v???vvv}}rrwwzzxxxxuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvv??????}xxzzzzr}}rrrrvwwwzxzrrvvvvv}}}rwwwzzxxxxuuuuuuuuu|<br></br>
`xuxxxxxzzzwwwrr}}vvvvvvzxxzzzwwwvvrr}rrrwzzw}}}v}}}}rrwwwzzzxxxxuuuuuuuuu|<br></br>
-uuuuuxxxxxzzzzwwwrr}}}vrzwrv?>+|---~-~^+?vv????vrrrvvv}zzzzzxxxxuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxzzzr}rrrr}?>^|+>>>^-~+>+|^+^|``-+v}rrrr}v??rzxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxxxvvrrrr}}}v?^^~~||~|+?|~~~~|~. .+}}rrrrrrv+vxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuxxu??wrrrrvv}}}r>` .`---^~--`..    .?}}}}rrrw}+}wxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuxrv?rrrrrrv?}}}>...~>-  +`      ..  .+??}rrrrrr?~zuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuv>}wrrrrrr??vv- ?v??>.  ?|  `~-^>-    `>}rrrrrrr??rxuuuuuuuuuu|<br></br>
-xuuuuxuxxux>}wrrrrrrr??^.  .>??v`  +^  `?v??.     .v}rrrrrrwr}}zxuuuuuuuu|<br></br>
-xxxxxxxxxz?vrrrrrrrrv>~   . ..`-.  -?   .|>?| ...  +}}}rrrrrrrrrwzxuuuuuu|<br></br>
`zzzxxxxxr?}wrrrrwwr}?` ....        .>`      . .... -w}}}rrrrrrrrrrwzxxuuu|<br></br>
`zzzzzzxv?rwrwwwwwrv}r` ..           `^           ...ruw}}}rrr}v}rrrrvwuuu|<br></br>
`zzwwzw>+rrrrrrwwr}rzz`              .>.             >uuxr}}}v}}rrrrr}~>xu|<br></br>
`wwwwv>?rrrr}vv}r}wzw}.    .-+.      .|.      |?>|`. `zuuuzvvvrrrrrrrw~ ?u|<br></br>
`rrrvvrwrrrrrr}}v}zrr?   `^?v}^      .`       ?r}}vv?|vzuuu}}rrrrrrrr?. ^x|<br></br>
`rr}`vwrrrrrrrrr}rr}r?.-?vv}}v+      `~   .   `^?vv}}??rzuurrwrrrrrw?.  +x|<br></br>
`r}}|.?rrrrrrrrrrvvv}>>}r}v>~.       -+          .`~>>|rrzzrwrrrrrwv.  .vx|<br></br>
`vvvv` +}rrrrrrrr}v?v|+}>~.          `>.               |}rrrrrrrrrv. . +ww|<br></br>
`v??v?. +}rrrrrrrrr}?...    ....      +`   ..  ...      >wrrrrrrr}` . -vrw~<br></br>
`????v+  >}rrrrrrrrr}v-               -^              .~}rrrrrrrw+ ...?rrr~<br></br>
`??????` .?rrrrrrrrrrrr?|.            .`         ..`~?vrrrrrrrrrr`.. ~vw}r~<br></br>
`??????>. `vrrrrrrrrr}}}}v?~. ....    .`   .~|^+>>>vv}rrrrrrrrrwv....>}r}}-<br></br>
`???????|  ~}rrrrrrrr}}}}}}}v?+++>`   `.   .^^~`..?}}}}rrrrrrrrw+ . ~?vvvv-<br></br>
`????????-  +rrrrrrrr}}}}}}v}v````.   ``     .`~||v}vvv}rrrrrrww-   +?vv?v-<br></br>
`?????????. .?rrrrrr}}}vvvvv?>||^^-   ``    ~^~-``?>?vvvv}}}}}rv.  .>?????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???????>>>>>>>>>>>????vvvv}}}rrrrrrrrrrr}}-<br></br>
`vv????????????????????????????v}}rrrrr}???v??????vvv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv?????????????????????????v}rwwrrrrrrrvv}??????vvv}}rwwzzxxxuuuuuuuuuuu|<br></br>
`r}vvv??????????????????????}}v}wwrr}vvvvrzv???????vv}}rwwzzzxxxuuuuuuuuuu|<br></br>
`wwr}vvvv?????????????vv???rzzwrrwzwrvvvwzrvv?????vvr}}rwwwzzxxxuuuuuuuuuu|<br></br>
`zzwwr}}vvv???????????vvvvvzxxxxxxzwwzzxxzwwwv?vvvv}}v}rrwwzzxxxxuuuuuuuuu|<br></br>
`zzzzzwrrr}vvv?????????vvwzxxxzxxzwzrwzxxxxxr}rwr}rr}}}rrwwzzxxxuuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvv??????}zzxxxxwzzzzzwwxxxxxzzwr}r}v}}}rwwwzzxxxxuuuuuuuuu|<br></br>
`xuxxxxxzzzwwwrr}}vvvvvvxxzzxxxxxzzzzzxxxuxxxzwrv}}v}rrwwwzzzxxxuuuuuuuuuu|<br></br>
-uuuuuxxxxxzzzzzwwrrr}}rzuxw}vvvv??vv?vv}rwrwwwvvrrr}v}rwzzzzxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxxzzwrrrrrwwvv???????vv}v??vvv?????>>??v}}vvwzxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxr}rrrrvrwrv?????????rv??????^|~>?vv??>?}rvwxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuu}vr}v??v}rrrr?^|^>>??v????>+~~^|`>rrrrrv??vvwzxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuzw}rv?>>v}}rrr?++>?r?||v?^^^^||+>+` >r}rrrrv?vv>xuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuwvwwv+?}rr}vr}>|v}vrv+^^}?|^>??vw?^~ `+v}rrrrrv???wuuuuuuuuuuu|<br></br>
-xuuuuuuuuuu}wwr>+vrr}v}v+|^>v}r}+|^v?^^>rwwr^|~ .`|}rwwrrrrr}}rzuuuuuuuuu|<br></br>
-xxxuuuuuuurrwv>?vrrr}v>---~|^+>>+|~?v^^^>vvv>~~ .~.|??vrwrrrrrrrwzxuuuuuu|<br></br>
`zxxxxxuuz}wwv>?rwrr}v^`--`.  ..----~v^-~~~~~--. `- .~--+?}wwrrrrrrwxuuuuu|<br></br>
`zxxxxxxw}rrv?vrrrr}}}|```.    `----`+?-`-```...``...|^~``|>?v?v}rrwwwzuuu|<br></br>
`zzzzzzvvwrrrrwrrrrrwr~.    ..`------~v|``.`...-`````?rv>|---``|>??vww?vxu|<br></br>
`wwwwrvvwrrrrrrrrrwzr>   ..~+?-..`````?`   .``>v?>|~`^rwr}v??????>>}wz?~vu|<br></br>
`www}}rwrrrrrrrr}rr?+~ .`^?vvv^...   .`   ...`?v?>?}v?wzzwwvv}rrr}}ww}|~?x|<br></br>
`rrr+vwrrrrrrrwrv+^^>>|>vv?^>v+...   .`    ...~|`->wwrwuuuxwrrrrrwwwv^--?x|<br></br>
`r}}>|vrrrrrrr?>++?v}vvr?||`......   .`.          .?vvvuxxzwwwwwwwwv^--|rx|<br></br>
`vvvv^-?}}v??>+>?}}vv>?}>.           .``      .....`^+^?zzwwwwwwrw}^~-~?wz|<br></br>
`???v?~`>v}v?v}}rrrrv|-|~.     .      ``.     `-~~~ .~--vwrwwwwrwr+---+rww~<br></br>
`????v>`-?v}}}}}rrrwrv+-``   .`~.     .`.             ->rwwwrrrrwv|--|vwrr~<br></br>
`??????|``?v}}}}}}rrrrrv>` `-|~.       ..          ..-vwwrrrrrrrw>|-`+}w}r~<br></br>
`???????`.~vv}}}}}}}}rrrr+--`.         ...  ......`-|vrrrrrrrrrrv^|`-?}}}}-<br></br>
`???????+``^v}}}}}}}}}}rv~||~-````.   .  . .`...  ~???}rrrrrrrrw?~~`^vvvvv-<br></br>
`????????^-`>vv}}}}}}}}}}>|||-....    ..     ...`|v??vv}}}rrrrrr|``.>vv??v-<br></br>
`?????????~`-?}}}}}}}}}}}r?>|`.....   ..    .`.`--|^>^?vvvv}}}}v`..`??????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvv}}}rrrrrrrrrrr}}-<br></br>
`vv???????????????????????????????????????????????vvv}rwwzzxxuuuuuuuuuuuuu|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`r}vvv??????????????????????????vv???????????????vwvv}}rwwzzxxxxuuuuuuuuuu|<br></br>
`wwr}vvvv?????????????????????}rzzw}vv???????????wwv}}}rwwzzzxxxxuuuuuuuuu|<br></br>
`zzwwr}}vvv?????????????????rzzwwuuxwrvv??vvvv?vwzrvr}}rwwwwwxxxxuuuuuuuuu|<br></br>
`zzzzzwrrr}vvv??????????vvvwuxwwzuuuxzzz}vvv?vvwzw}wz}}rwrwzrxxxuuuuuuuuuu|<br></br>
`xxxxzzzwwwrr}vvvvv???????rxuuxxxxuuxxxxzrwrv}}wwwzz}}zz}rzrzxxxuuuuuuuuuu|<br></br>
`xuxxxxxzzzwwwrr}}vvvvvvv}xxxxxxxxxxxxxxzrzxzrwwwwzwwzzrzzrzxxxxuuuuuuuuuu|<br></br>
-uuuuuxxxxxxzzzzwwrrr}}}wxzxxxxxxuuuxxuxwzxxzwzwwzwwzzzzwrzzxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuxzrwwwzzzwwwrwuuxzxxxxxxuxxxzwzxuuzww}vvv}vww}rzxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuxzrrzzzzwwzrwuuuxxxxxxxzzzzxxxuuzrwwrv}}vvv?vzzxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuzwrrrrvvwzzxxxxzzxxxxxxuuuuux}rwwrvrwwwv?}}wxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuux}>?vrzxzzzzxxxxxxxxxxuxxzwzzzwrvwwr}v->r}wxuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuzvvr}rzzzzzwwwwwzxxxxzzxv^?vwwwr?v}v}rv-+vrwwzuuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuxv?vrrrwzzwwzzzwzzzzzwzrrv+~-~+vv>?v}rrw}^`|vzzxxuuuuuuuuuu|<br></br>
-xxxuuuuuuuuuuxv?v}}}}}rrrwwzzzzzwr}+?rrwwrv>-.-->v?vvv}r}??v}wxxxxuuuuuuu|<br></br>
`zxxxxxuuuuuxwr}}}}}}}}rrr}vvvvvwwr}?`vv}rrrr}?~`>v++>+??vrwrv++vxxxuuuuuu|<br></br>
`zxxxxxxxxzrvvrwr}}}}vv}rv????v}rrr}}`+vv}rrr}vv}r}vv??????vv}v>^+vxuuuuuu|<br></br>
`zzzzzxxxxwrrrrrrrrrrrrvv???v}rwrr}r?.|vvv}r}}}rrrrrrrr}vvv????vv?>vuuxxxu|<br></br>
`wwwwzzzzzzwzwwwr|vrv?>+>?v}rr}}rv??..??.`-~^+>?v}rrrrrrrrr}}}}}vvv}uxxxxx|<br></br>
`wwwwwwzzwwzwwwzr~vv?>?vv}vvvv??v?-  ^?`   .    ..-^??v}}}}rrrrrv?~vuxxxxx|<br></br>
`rrrrrwwwwwwwwzr+>v??vrrr}?+>v?+>-  ~^>+|||>>>^-.  ....````----`...}uxxxxx|<br></br>
`r}vv}}rrrrwwr}?^vvvrrrr}?++^|`  .-~||>?++++>>>>+|^?>>?>>+^||~~~~^?zxxzzzz|<br></br>
`vvvvvvv}}v?>>>?v}}rrrrr}?>~.   `|^^|^+?>++++>?????v}}}}}}}}}}}}}}rzzzzzzz|<br></br>
`?????vvvvvv?vvvvrrrrrrwr?`  .`+>+-. ..-```...`-~+>|+>~?wwrrrrrrwwzzzwwwww~<br></br>
`?????????vvvvvv?>rrrrr?~..-+?vv~.`.     .`          ...?xzzzzzzzzzwwwwwrr~<br></br>
`??????????????v?``-~~`.`>?}rv?~~^+.      `.          ..+zzwwwwwwwwrrrrr}r~<br></br>
`????????????????>`...`>}?>?^~>?>~.       ..         ..^wwwrrrrrrrr}}}}}}}-<br></br>
`?????????????????????v}?||~  ...        . ..        .`>rrrrrr}}}}}vvvvvvv-<br></br>
`????????????????????????>+`               .~.      `~->vvvvvvvvvvvvvv???v-<br></br>
`????????????????????vvvvvv+.               ..  . .^|.^->vvvvvv???????????`<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv???????????????????????????????????????????????vv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????v}v}rrwzzzxxxuuuuuuuuuxx|<br></br>
`}}vvv???????????????????????????????????????????vr}v}rrwwzzxxxxuuuuuuuuuu|<br></br>
`wwr}}vvv??????????????????????v}}v??????????????vr}v}rrwwwwxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv????????????????vrzwzxzw}vv?????????}wr}v}}rwwwrzxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv???????????}xuzwxuuxzrwrv??????}wr}ww}}rrwwwwxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv???????rxxxzzxuuuuxxxrvvv??vwwrzzr}wwrzwwzxxxxuxxxuuuuu|<br></br>
`xuxxxxxxzzzzwwr}}}vvvvvvvzuuxxxuuuuuuuuzvww}v}rwwwzwzwrzz}wzzxxuxxzxuuuuu|<br></br>
`uuuuuxxxxxzzzzwwwrrr}}v}wxuuuuuuuuuuuuxrrxxz}}wwwwwzwzrwrwzwwzzzxxxuuuuuu|<br></br>
-uuuuuuuuuuzwzzzzzzzzwwwzxxxxuuuuuuuxxxwwzuuxzwrrwwwwwr}wzzzzxzwxxzuuuuuuu|<br></br>
-uuuuuuuuuuxxzzzzzzzzzzzxuuxxxuuxxxxxxzzxuuxxxzwr?>?}w}wwzzzzzzxzxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxzzzzxzzzzxuuuuxxxxxxxxxxuuuxzxxwzzv>++}vxzzzxxzzzxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxxzzxxxzxxxxuuuuuuuuuuuuuxzrxzzxzw}}vv>vxzxxxxzxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuxzzzxxxxxxxxxxxuuxxxx}>?}wwzxzrvv}r}>?rzxxxzuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuxxxxzwxxzxxxxzxxxxxzzww?>+^+?rr}v?vrrw}?>?}xzuuuuuuuuuuuu|<br></br>
`xuuuuuuuuuuuuuuxzzzrv?xxxxxxxzzxzww>?vrrrw}?+~^??v++?}rw}?>vwxuuuuuuuuuuu|<br></br>
`xxxxxxxxxuuuuuxzzwr?^>wxxxxxxxxzzzwv^}vrwwww}???v}}?>|+?vv+?w??wuuuuuuuuu|<br></br>
`zxxxxzzzzzwzwzxxw}>~>}rzxxxxxxxxxxzw>?}v}rrwrwwrrrr?vv?>?r?>zzv??ruuuuuxu|<br></br>
`zzzzxxzzzwwrwwwwrv^>wrrrwzxxxxxxuzw}+?}vvrrrrrrrrrr>^?r}rrr??wzzw}wuuuxxx|<br></br>
`wzzzzzzxxxzzzzzw?vv?}}}}v}rrwzzzzrv^+vv|^+???vv}r}?+^>}}rrrwv?rzwzwuuxxxx|<br></br>
`wwwwwwwzzzzzzzzz>}wr}}r}}v?vv}}}}?~-??~`-|^^^^^|-`-~+v}}rrrrr?+v}?}uxxxxx|<br></br>
`rr}rrwwwwwwwwzw?vr}rrrrrr}^?}}vv?^^v}vvvvvvvv??^|||~||^+++>>>>|..-ruxxxxx|<br></br>
`}vv}}}rwrwrwwwrvwr}rrrrrrv~>v|~~^?}r}rrrrrrrrrrr}vvvvvv???????+..`rxxzzzz|<br></br>
`vvvvvvv}rrrr}}}}}rrrrrrrw?`||.-?vrr}rrrrr}}}}}}vvvvvvvvvv??>>+|-->rwzzzzz|<br></br>
`??????vvvvvvvv}v}}}}}rr}v` |~.~>??|------```.....````.`~------~|?rrwzwwww~<br></br>
`??????????vvvvvv?rr?>v?^. ^v>-`  .       .         . ..->>>??vv}}vrzwwwrr~<br></br>
`??????????????v?~|||-.->?vvvv>||^|..`---->|--~~~~~~~~~-?rrwwrr}}}}rrrrrrr~<br></br>
`????????????????>|-``.~rr}v?>v}v?|~~~~~--|^``-----```-+vvvvvvvv}}}}}}}}}}~<br></br>
`??????????????????????-+}}v|^+|-````..````~...........|>v}}}}}}}}vvvvvvvv-<br></br>
`??????????????????????~`v}>~|~``.    .```. ...       .~|?vvvvvvvvvvvv???v-<br></br>
`?????????????????????v>`|?v|~~`.   ..``.   ....   ..`+.~~?v??????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv???????????????????????????????????????????????vv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwrwrxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv?????????????????vvrxw}vv?????????????vvv}}rwrz}zxxxuuuuuuuuuu|<br></br>
`zxzzzwwwrr}vvvv????????????rxzrxuuz}}}?>???????vvvv}}}r}rwrzxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv???????vxurrrxuuuxzxzv?v?????vvvv}}}r}x}wzzxxuuzwzuuuuu|<br></br>
`xuxxxxxxzzzzwwr}}}vvvvvv?wuuxzxuuuuuuuuzvrv????vv}}}}w}wzvww}zuxzzrzuuuuu|<br></br>
`uuuuuxxxxxxzzzwwwrrr}}}vvxuuuuuuuuuuuuuwvzxw??v}rrrr}wvzrwz}vzw}zzwuuuuuu|<br></br>
-uuuuuuuuuxxxxxzzzzzwwwwwwzuuuuuuuuuuuuz}zuuuwvv?vrzwrv}wrzwwxrvwrruuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxxzzzzwwuxzxxxuuuuxxxwrzuuuuzv}v-`+?r}wwzw}rwrzwwuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxrxuuuxxxxzxxzzxxxuuuxxzvwr>`.`rrzwzrzzwwrzuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuxrzuxuuuuuuuuuuuuuuuzvzwruwv?}?wzzzwzzzxrruuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuwxrxxuuuuuuuuuuuuuxwwwzrxuw}?}}wzxzzzzxz}uuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuzrxxwzuuuzxxxxxxzzz}}zrwxuxrv+>}wwzzzzzrvxuuuuuuuuuuu|<br></br>
`xuuuuxuuuuuuuuuuuyuw?|xuuzzxxwwzzzww}v}vrwxuxwr}v?``+vrwwv>vxuuuuuuuuuuuu|<br></br>
`xxxxxzzzxuuuxxuuyzr> `wuuuuxxxzwwwwrrz}wzuuxr}rrrrr?|`~>?> ?yuuuuuuuuuuuu|<br></br>
`zxxxxzwrzzzzrwzxz}^ |rrzuuuuuuxxxxxxuxw}}wzz}rrrrr}|>?>|^r-`xyuuuuuuuuuxu|<br></br>
`zzzzxxzwr}}rwwwwr? `wrr}rzxuuuuuuuuuuzv?}rr}rrrrrw}. `vv}rr+~zyuuuuuuxxxx|<br></br>
`wwzzzzzxxxr}}rwzwwv>v}}}vv}rwzzxxxxxxzwrr}}rrrrwwv|  `vv}}rw?~wuuuuxxxxxx|<br></br>
`wwwwwwwzzzrwwwwzzwzzwrr}}v?vv}rr}}r}v>+?vrrrrrrv+-`~|v}}rwrrr|.?uuxxxxxxx|<br></br>
`rr}rrrwwwwwrrrwwzwwzxxzz}}`+}rrrwrrrrvv}rrrrrrr}vrwwwwwwwwwwrr?.~rxxxxxxx|<br></br>
`}}v}}}rrwwrwwwrrwrrrwzzzr}.|wv}rwwwrwwwwrrrrrwwwwwwwwwwwwwwwwwv. .rxxzzzz|<br></br>
`vvvvvvv}rrr}}v}v}rwwzzzrr? ?wvvrwwwwwwrrrrrrr}}}vvvvvvvv??>^|-.  +rwzzzzz|<br></br>
`??????vvvvvvvv}vvvvv}}}vv`.}?.`|>??+|~-````.......             .>r}wzwwww~<br></br>
`??????????vvvvvvvv}+.>vv^ +}|              ............`-~|+>??vrv}zwwwww~<br></br>
`??????????????vvvvv?+.?wv?vvv?^~---|>???vvvvvvvvvvvvvv}}rrrrrr}}v}rrrrrrr~<br></br>
`???????????????????vv-.}r}}vvv}}v}}}r}}}vv}}vvvvvvvvvvvvvvv}vvvvv}}}}}}}}~<br></br>
`????????????????????v> ^wrvrrvvvv??>+|>vvv>`~`````----~^?vv}v}}}}vvvvvvvv-<br></br>
`?????????????????????v` v}vrrrvv^.  .+v}?~  .~       .-`>vvvvvvvvvvvv???v-<br></br>
`?????????????????????v^ `>}rrr?`   |?vv|.    ^>     `^`~.?v??????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv???????????????????????????????????????????????vv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwwwwxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv??????????????????v}w}vvv?????????????vvv}rrwwzwxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv????????????vwzwzuzw}}v?????????vvvv}}rrrwzwxxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv????????wxzwrzuuuzrzw????????vvvv}}rrwzwzzxxxuuxxxuuuuu|<br></br>
`xuxxxxxxzzzzwwr}}}vvvvvv?rxuxwzxuuuuxxuwvvv????vv}}}rwrwzrzzwzxxxxzxuuuuu|<br></br>
`uuuuuxxxxxxzzzwwwrrr}}}vvwuuuuxuuuuuuuuwvww}??v}}rrwrwrzwzzrrwzzxxxuuuuuu|<br></br>
-uuuuuuuuuxxxxxzzzzzwwwwwwwuuuuuuuuuuuux}rxxx}vvv}wzwwrwzzxwwzrwzzxuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuxxxxxzzzzwwxxzxuuuuuuxxxzrwxuuuwvv?++?vwwzzzrrwwzxzxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxzzuuxxxxxxxxxxzzzuuuuuzvw}+--^wzxwwwwwzzzxuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuxxzzuuuuuxxxxxxxuuuuuxrzz}xw?|^~>vrwwzwzzrrxuuuxzxuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuxxzxxuuuuuuuuuuuuuxxwwzwzuwv?}vvvzzzzzzwwzxxxxzxuuuu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuxzxxzzuuuxxxxuxxxxxwwxwwxuxrv??rwzzzzzwwrzxxxxuuuuuuu|<br></br>
`xuuuuuuuuuuuuuuuuuuxr?zuuzzxuzrzxzzwrr}}rzzxxzw}vv+>vrwwwr}rzxxuuuuuuuuuu|<br></br>
`xxxxxzxxuuuuuuuuuuzv>>ruuuxxxxzwzzzzrwrwzxuuzr}}vv}v?>?vvv^vxxuuuuuuuuuuu|<br></br>
`zxxxxzzzxxxxzxxuxw?+?r??xuuuuxxxxxxxxxzwrzxxwr}}v>+~>?v??}+-xyuuuuuuuuuxu|<br></br>
`zzzzxxxzzzzzzxxxz}+>w?^>}zxuuuuuuuuuuxrvvrwrrrrrrw}~..^?}rr>~wyuuuuuuxxxx|<br></br>
`wwzzzzzxxxzwwzzxxzr??|>}}}rwzzxxxxuuxwwrr}v}rrrrr}?^|^>^}rrw?-}yuuuxxxxxx|<br></br>
`wwwwwwwzzzrwzwzzzzwr+>rr}}vvv}}rrrwr}vvv}}vrrrr}??>>?vv}rrr}r?`>xuxxxxxxx|<br></br>
`rr}rrrwwwwrwrrrwwwwwwzzwrr>?rr}}rv?}r}}rrrrrrrrr}rwwwwrrrrwwrrv--}uxxxxxx|<br></br>
`}}v}}}rrwwrwwwwrwr}rzzzwrr+?wrrrrr??rrrrrrrrrrrrwrrwrrrwwwwwww}+`.?zxzzzz|<br></br>
`vvvvvvv}rrrrr}}}rrwwrwzrr?|}w}rrrw?>wrrwwrwwrrrrrr}}}}}}vvv???+~.`|}zzzzz|<br></br>
`?????vvvvvvvvv}v}}v}rr}}}^^rv^+>?v+~?--|^^^|~--`````````....... `~~rzwwww~<br></br>
`??????????vvvvvvv}r?^?vv?-?v>````` .`                  ....``----`?zwwwww~<br></br>
`???????????????vvrrv?-~?}v}vv?>+^~~+??>>>>>>??????????vvvvvv????>?rrrrrrr~<br></br>
`???????????????v??vvv|.+rrrrvvv}}}}}}}}}}}}}}vv?????????vvvvvvvv}r}}}}}}}~<br></br>
`?????????????????????>..vwrr}vv}}v??>+|^^>?^>`.........`vvv}vv}}}vvvvvvvv-<br></br>
`??????????????????????~ |}rrrrr}?~`...``-|-```       .-`?vvvvvvvvvvvv???v-<br></br>
`?????????????????????v>..+r}}rv|---``````..  ^+     `^`~`?v??????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv???????????????????????????????????????????????vv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv????????????????????v?????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv?????????????vrwzxw}v}??????????vvvv}}rrwwzzzxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv?????????wxzrruuxw}rv????????vvv}}rrwwwzzxxxxuuuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwr}}}vvvvv???wuzrrzuxxuzxxw???????vv}}rrrwwwzzxxxxuuuuuuuuuu|<br></br>
`uuuuuxxxxxxzzzzwwrrr}}}vvvuuuuuuuuuuuuuzv}vv?vv}}rrrwwwzzzzzwvxuuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxxxzzzzwwwwr}}uuuuuuuuuuuuxr}zxw??v}rrwwwzzzzzzwrrxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxxzzzzzwwzzxuuuuuuuuuxr}zuuurvv?}zzwzzzxz}wwrxuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxwxuxzxxxxxxxzwrwxuuuuzvrv>>?}zxxz}wr}zzxuuuuuxxuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuxxrxuuuxxxxxxxxxuuuuurzz}zw?`.`~>vrwwrzw}}zxxxw}ruuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuzzwuuuuuuuuuuuuuuxxwrzrzxwv?v?>>wzwzwwrwwrwzwrwuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuzuzzuuuuuuuuuuuxxwrwwrwuxwvvv}wzzzzzrwwzwwrwxuuuuuu|<br></br>
`xuuuuuuuuuuuuuuuuuuuuuwxuzzxux}zzwzwrr}v}wwxuuz}v}}rzzzzzzzzzwrxuuuuuuuuu|<br></br>
`xxxxxxxuuuuuuuuuuuuuuzrxuuxxzxzwzxzw}r}rzxuuuzrv??vrwwzzzzzwrzuuuuuuuuuxu|<br></br>
`zxxxxxxxxxxuuuuuuuuuzr>+xuuuuxxxzxxxxxzwrzxuzrvv>-``+?r}}}v|zyuuuuuuuuxxu|<br></br>
`zzzzxxxxxxxxxxxuuuuwr+ `vzuuuuuuuuuuuxr?vvww}}}}rr}?^`->vrr>~wyuuuuuuxxxx|<br></br>
`wwzzzzzzxxxxxxxxxuzr+ ~}r}rwzxxuuuuuxwrrr}?vrrrrrrrrwv+-}r}r?-vyuuuxxxxxx|<br></br>
`wwwwwwwzzzrzzzzxzxrv.-rr}}}vvvvrwrwrrrrr}v?rr}rrrrrr}}v}rrrrw}^+xuxxxxxxx|<br></br>
`rr}rrrwwwwv}}rrrrwwrrwwwwwzwwwrv}?-vrrrrrrwrrrrrrrrrrrrrrrwwwww+-}uxxxxxx|<br></br>
`}}v}}}}}rrwrrrr}}}vrzzwwwwzzzxxxrr-~r}rrrrrrrrrrrrrrrrrrrrrrrrwz^ >zxzzzz|<br></br>
`vvvvvvvvv}}}rrrwrrw}}wwwrrwzxxxxrr>`rvrwwwwwwwwwwwwwwwwwwwwwwr}?`  vzzzzz|<br></br>
`??????vvvvvvv}}}}}v}rrrwwrrwwwwrvr+`r-`>?v?>+^|||^^^^^^||~~~~`.   .}zwwww~<br></br>
`??????????vvvvvvv}}wrrvvvvvvvvvvvv.+?.                            >wwwwww~<br></br>
`???????????????v}r}}}? ~wrrvvvvvv+|?v?+|~||^^+>>>>?????>>>+++^^++?rrrr}rr~<br></br>
`???????????????v??vvvv-.rrrrvvv}}vvvvv}}}}vv}v??????????vvvvvvvvv}}}}vv}}~<br></br>
`?????????????????????v? +wr}vvvr}vvvv?~--~?vv-         `v}}}}vvvvvvvvvvvv-<br></br>
`???????????????????????`.vr}vrrrr}}}>.  `?}?~`        -`?vvvvvvvvvvvv??vv-<br></br>
`?????????????????????vv> |rvvrrrrr}^   ~?v+. ^|     .+`~|????????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv???????????????????????????????????????????????vv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv????????????????????v?????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv?????????????vrwwxr}vv??????????vvvv}}rrwwzzxxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv?????????rxzwruuxw}rv????????vvv}}rrwwwzzxxxxuuuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwr}}}vvvvv???wuxrrzuxxxzxxr???????vv}}rrrwwzzzxxxxuuuuuuuuuu|<br></br>
`uuuuuxxxxxxzzzzwwrrr}}}vvvuuuxxuuuuuuuuzv}vv?vv}}rrrwwzzzzzzzrxuuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxxxzzzzwwwwrr}uuuuuuuuuuuuurvzxw??vrrrwwwzzzzzzzwwxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxxzzzzzwwzzxuuuuuuuuuxr}zuuu}vv?rzwwzzxxzrwzwxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxwxuxzxxxxxxxxzrwxuuuuzv}v>?vrzxxzrzwrzzxuuuuuuxuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuxxwxuuuxxxxxxxxxuuuuuwzz}zr?```|?}wwwwzwrwzxuxzwwuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuzzzuuuuuuuuuuuuuuuxwrzrzxwv+>+^|vwwzwwrwzzzxzzzuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuzuzzuuuuuuuuuuxxxwrwwrwuxwvvv}rrwwzzwwwwwzzzxuuuuuu|<br></br>
`xuuuuuuuuuuuuuuuuuuuuuzxuzzxux}zzzzwrr}vvwwxuuz}v}}rzzzzzzzzzwwxuuuxxuuuu|<br></br>
`xxxxxxxuuuuuuuuuuuuuuxwxuuxxzxzwzxxw}r}}zxuuuxrv??vrwwzzzzzzwzxuxuuuuuuuu|<br></br>
`zxxxxxxxxxxuuuuuuuuuxw?>xuuuuxxxzxxxxxzwwzxuxrvv+--|>vrrrrv+zuuuuuuuuxxxu|<br></br>
`zzzzzzxxxxxxxxxuuuuzw?.`?zuuuuuuuuuuuxrvv}ww}}}}}v?>|-~?vrr>~wyuuuuuuxxxx|<br></br>
`wwzzzzzzxxxxxxxxxuzr>.-?v}rwzxxuuuuuuwr}r}??rrrrrrrr}>-~}r}r?~vuuuuxxxxxx|<br></br>
`wwwwwwwzzzwzzzzxxxw}-`?v}}}vv}}rwwwwrrrrrvvrrrrrrrrrrv?vrrrrw}++xuxxxxxxx|<br></br>
`rr}rrrwwww}rrwwwwwwr}?vrrrwrr}}v}?~vvvrrrrwrrrrrrrrrrrwwrrwwwww>-vuxxzzxx|<br></br>
`}}v}}}}}rrrrrrwrrr}rwv}rrwzzzzzzwr~|rvvrrrrrrrrrrrrrrrrrrrrrrrww>.>zzzzzz|<br></br>
`vvvvvvvvv}}}rrrwrrw}}rrwrwwzzzzxww>~r}vrrwwwwwwwwwwwwwwwwwwwwwrv~. vzwzzz|<br></br>
`??????vvvvvv}}}}}r}}r}rwwrwwwwww}r>~r|`>???>>^^^^^^^|||||~||~-`.   vzwwww~<br></br>
`??????????vvvvvvv}}wrw}v}vvv}vvvvv`+?.                            ^wwrrrw~<br></br>
`???????????????v}rr}}?.^rrr}vvvvv>^vv>+^||^^++>>>???????>>>>+++++?rrr}}rr~<br></br>
`???????????????v??vvvv|-}rrr}vv}}vvvvv}}}vvvvv??????>>>>vvvvvvvvv}}}vvv}}-<br></br>
`???????????????????vvv? |rrr}}}r}}vvvv|~``^?v~         `v}}}}vvvvvvvvvvvv-<br></br>
`??????????????????????v` ?rr}rrrr}}}?`  .|??|`        ``?vvvvvvvvvvv????v-<br></br>
`?????????????????????vv> -}}v}rrrr}+.  .|?>. ||     .^`~|?v??????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vvv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv??????????????????????????????????????????????vvv}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv??????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv??????????????v}rwvvvv??????????vvvv}}rrwwzzxxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv?????????vwxwruuz}}}v>???????vvv}}rrwwwzzxxxxuuuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwrr}}vvvvvvv?vuxrrruxuxwzx}???????vv}}rrwwwzzzxxxxuuuuuuuuuu|<br></br>
`uuuuuxxxxxxzzzzwwwrr}}}vvvxuuxzuuuuuxuuzvvv?vvv}}rrrwwwzzzxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxxxzzzzwwwwr}vxuuuuuuuuuuuuwvwz}??vrwwwwzzzzzxxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxxzzzzzwwwzuuuuuuuuuuuw}wxuxv?vvwwwzzzzzxxxxzwruuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxwzuxzxxxxxxxxzrrxuuuuzv}?vwzxxxxxxxzzzwxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuwzuuuxxxxxxxxxxuuuuzxwvw}>^>?}wzzzzzwrwuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuxzwuuuuuuuuuuuuuuuxz}zrzxw?....`^}ww}rrwuuuuuuuuuuu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuuuzxzzuuuuuuuuuuxxuzrwwrwuxwvvvvv?vrrwwwwwwxuuxxzzuuu|<br></br>
`xuuuuuuuuuuuuuuuuuuuuuzxuzzxuurzzzzzrrrvvwwzuuz}vv}rwzzzzzwwwwwwzzzwwxuuu|<br></br>
`xxxxxxxuuuuuuuuuuuuuuyxzuuxxzxzwzxzw}}}vwxuuuxrvv}vwzzzzzzzzzwrwwzxxuuuuu|<br></br>
`zxxxxxxxxxxuuuuuuuuuuuw}zuuuuxxxzxxxxxzwwxxuxwv?+>?}rwzzzzw}}zxuuuuuxxxxu|<br></br>
`zzzzzzxxxxxxxxxuuuuuxwv`-wuuuuuuuuuuuuw?v}rz}v??>^~-~?}}}}}?|wyuuuuuuxxxx|<br></br>
`wwzzzzzzxxxxxxxxxxxxrv`.>}rwxuuuuuuuuw}}}r??vrrrwwr}>.`?rrrrv^vuuuxxxxxxx|<br></br>
`wwwwwwwzzzzzzzxxxxxwr- >rrr}}}rwwwzzwrr}rrv}rrrrrrrrr?^?rrr}rr>>zuxxxxxxx|<br></br>
`rr}rrrrwwwwwzzzzzzxrv -wrrrr}vvvvvvv>-vrrrrrrrrrrrrrrwwwrrrwwwwv|?uxxzzxx|<br></br>
`}}v}}}}}rrrwwwwwwwzwv~+rrwrrwwwwwwzwr>`}rrrrrrrrrrrrrrrrrrrrrrrwr|+zzzzzz|<br></br>
`vvvvvvvvv}}}r}rrrrrrrrrwwzwwzzzzzzzzr}`v}rwwwwwwwwwwwwwwwwwwwwww}| vzzwww|<br></br>
`??????vvvvvv}}}}vwwrvvw}rwwrwwzxxxxwvr-v|->???>>>>+^^^^|||^^^^|-.  ?zwwww~<br></br>
`??????????vvvvvvv}}}}w}}r}vrw}rrrr}vv?`?`                         ~rwrrrr~<br></br>
`???????????????vvvvvvvvw}rrrvvvvv??v?|>vv>+++>>>>???????>>??>>>+>?}rr}}}}~<br></br>
`???????????????????vv}wrvv}r}}vvvvvvvvvvvvv?v}v????>>+^^v}vvvvvv}}}}vvvv}-<br></br>
`???????????????????vv}}>.?wrrr}v}rvvvv}?` .+vv-        `}}}}}vvvvvvvvvvvv-<br></br>
`??????????????????????v?.`}rr}rrr}vvvv+. .>}v~        `-?vvvvvvvvvv?????v-<br></br>
`????????????????????vvvv^ ?rvvrrr}vv?-  .+v>.--     .^-~+vvvv????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vvv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv?????????????????????????????????????????????vvv}}rrwzzzxxxuuuuuuuuxxx|<br></br>
`}}vvv????????????????????????????????????????????vvv}rrwwzzxxxuuuuuuuuuuu|<br></br>
`wwr}}vvv?????????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvv??????????????????????????????????????vvv}rrwwzzxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwr}}vvvv??????????????v}rwvvvv??????????vvvv}}rrwwzzxxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwrr}}}vvv?????????vwxwruuz}}}v>???????vvv}}rrwwwzzxxxxuuuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwrr}}vvvvvvv?vuxrrruxuxwzx}??????vvv}}rrwwwzzzxxxxuuuuuuuuuu|<br></br>
`uuuuuxxxxxxzzzzwwwrr}}}vvvxuuxzuuuuuxuuzvvv?vvv}}rrrwwwzzzxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuxxxxxxzzzzwwwwr}vxuuuuuuuuuuuuwvwz}??vrwwwwzzzzzxxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuxxxxxzzzzzwwwzuuuuuuuuuuuw}wxuxv?vvwwwzzzzxxxxxzwruuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxwzuxzxxxxxxxxzrrxuuuuzv}?vwzxxxxxxxzzzwxuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuwzuuuxxxxxxxxxxuuuuzxwvw}>^>?}wzzzzzwrwuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuxzwuuuuuuuuuuuuuuuxz}zrzxw?....`^}ww}rrwuuuuuuuuuuu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuuuzxzzuuuuuuuuuuxxuzrrwrwuxwvvvvv?vrrwwwwwwxuuxxzzuuu|<br></br>
`xuuuuuuuuuuuuuuuuuuuuuzxuzzxuurzzzzzrrrvvwwzuuz}vv}rwzzzzzwwwwwwzzzwwxuuu|<br></br>
`xxxxxxxuuuuuuuuuuuuuuyxzuuxxzxzwzxzw}}}vwxuuuxrvv}vwzzzzzzzzzwrwwzxxuuuuu|<br></br>
`zxxxxxxxxxxuuuuuuuuuuuwrzuuuuxxxzzzxxxzwwxxuxwv?+>?}rwzzzzw}}zxuuuuxxxxxu|<br></br>
`zzzzzzxxxxxxxxuuuuuuxwv-~zuuuuuuuuuuuuw?v}rz}???>^~-~?}}}}}?|wyuuuxuxxxxx|<br></br>
`wwzzzzzzxxxxxxxxxxxxrv` >}rwxuuuuuuuuw}}}r??vrrrwwr}>.`?rrrrv^vuuuxxxxxxx|<br></br>
`wwwwwwwzzzzzzxxxxxxwr- >}}r}}}rwwwzzwrr}r}v}rrrrrrrrr?^?rrr}rr>>zuxxxxxxx|<br></br>
`rr}rrrrwwwwzzzzzzzxrv `}rrrr}vvvvvvv>-vrrrrrrrrrrrrrrwwwrrrwwwwv|?uxxzzzx|<br></br>
`vvv}}}}}rrrwwwwwwwzwv~^vrwrwwwwwwwrrr>`}rrrrrrrrrrrrrrrrrrrrrrrwr|^zzzzzz|<br></br>
`vvvvvvvvv}}}r}rrrrrrrrv}wwwwzzzzzzzzrr`v}rwrwwwwwwwwwwwwwwwwwwww}| vzzwww~<br></br>
`??????vvvvvv}}}}vwwrvvrv}wwrwwzxxxxwvr-v|~>??>>>>>+^^^^^^^^^^^^~.  ?zwwww~<br></br>
`??????????vvvvvvv}}}}wr}r}vrwrrrrr}vv?`?`                         ~rwrrrr~<br></br>
`?????????????vvvvvvvvvvw}rrrvvvvv??v?|>v?>>>>>?>>????????>>>>>>+>?}rr}}}}~<br></br>
`??????????????????vvv}wrvv}r}}vvvvvvvvvvvvv?vvv???>>+^^^v}vvvvvv}}}}vvvvv-<br></br>
`???????????????????}}}}>.?wrrr}v}rvvvv}?`..+vv-        .}r}}}vvvvvvvvv?vv-<br></br>
`??????????????????????v?.`}rr}rrr}vvvv>.  ^vv~        `-?vvvvvvvvvv?????v-<br></br>
`???????????????????vvvvv+ ?rvvrrr}vv?~  .+??`--     .^-~>vvvv????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vvv}rwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv?????????????????????????????????????????????vvv}rrrwzzzxxxuuuuuuuuuxx|<br></br>
`}}vvv????????????????????????????????????????????vv}}rrwzzzxxxuuuuuuuuuuu|<br></br>
`wwrr}vvv?????????????????????????????????????????vv}}rwwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvvv?????????????????????????????????????vv}}rrwzzzxxxxuuuuuuuuuu|<br></br>
`zxzzzwwwrr}vvvv????????????????v}??v???????????vvvv}}rrwzzzxxxxuuuuuuuuuu|<br></br>
`xxxxxzzzwwwrr}}vvv??????????vwzrxxw}}v?>???????vvv}}rrwwzzzxxxxuuuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwrr}}vvvvvv?v?ruwr}xuuxrwwv?????vvvv}}rrwwzzzzxxxxuuuuuuuuuu|<br></br>
`uuuuuuxxxxxxzzzwwwrrr}}vvv}uuzwxuuuuxxuz?v??v}}}rrrrwwzzzxxxxxuuuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxxxzzzzzwwwww}zuuuuuuuuuuuuwvrrv?v}rrwwwzzzzxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuxxxxzzzzzwwrwuuuuuuuuuuuw}rxuzv?v}wwzzzzzxxxxxuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxzrxxzxxxuuuxxzw}wuuuuwvv?}zzzxxxxxxxuxuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuxwuuuxxxxxxzzzzxuuuxxwvwv?rwwzzxuuuxzrwuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuzwxuuuuuuuuuuuuuuux}z}wxr?``~|+?rzzzrrxuuuuuuuuuuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuxzxwuuuuuuuuuuuuuzwwzrwuxwv>+++|`|}wwwuuuuuuuuuuuuu|<br></br>
`xuxxuuuuuuuuuuuuuuuuuuuzuxzxuuwwzzzxwrwvvwwzuuz}v}vvrr}wrrwzwwwuuuuxxuuuu|<br></br>
`xxxxxxxxuuuuuuuuuuuuuuuwxuxxzxzwwxzw}}}}wzuuuxwvvvv}rwzzwzw}}}}zxzw}}xuxu|<br></br>
                  `zxxxxxxxxxxxuuuuuuuuuuurzuuuuxxxzxxxzxzwzxuuuz??vvvwzzzzzzzzzzrrwrwzxxxxu|<br></br>
`zzzzzzxxxxxxxxxxuuuuuuww?vxuuuuuuuuuuuzvvrwx}| -++>v}zzzzzwrvvwxxuuuxxxxx|<br></br>
`wwzzzzzzxxxxxxxxxxxxuwrv..vwzuuuuuuuuz}}rrv>^??}}v?>|+v}}}}}v+vuuxxxxxxxx|<br></br>
`wwwwwwwzzzzzzxxxxxxxxrr` ?rr}rrwwzzzzw}?vv>vrwwrrrrr}+?}}}rrr}>?wuxxxxxxx|<br></br>
`rr}rrrrwwwwzzzzzzzzxwr+ >wrrr}vv}?`..`>?v++vrrrrrrrrrwwrrrrrwrw}>vxxxzzzx|<br></br>
`vvv}}}}}rrrwwwwwwwzzr}.`rrrrrrvvv|->vrzzwr?-vrrrrrrrrrrrrrrrrrrwwv?zzzzzz|<br></br>
`vvvvvvvvv}}}rrrrrrwwrv `rrrrr}vvvrxxxxxxxwr^>}wwwwwwwwwwwwwwwwwwww~}zwwww~<br></br>
`???????vvvvvv}}}}}}}}v^.?wrrwwwwwzzzxxxzzr}^+~~+>>+^^^^^^++++>>>+- vzwwww~<br></br>
`??????????vvvvvvvvv}rr}vv}}}zwwwrwrrzwr}vvv`+`                    -rwrrrr~<br></br>
`???????????????vvvv}vvv?^}vw}rzv}z}vvvv??v+`???>>????????????>>>+>}r}}}}}~<br></br>
`???????????????????vvvvwv?}wvzr}w}vvvvvvvv+ .>v?>++^~-``?}}}}}}}v}}vvvvvv-<br></br>
`?????????????????????vvv-`}vvwrrvv}vvvvv}+  ~v?.       .v}vvvvvvvvvvvv?vv-<br></br>
`????????????????????vvv}+ ?rwrvv}}vvvvvv^  -v?.       .-vvvvv????????????-<br></br>
`???????????????????vvvvvv+}}vvvr}vvvvv?-  `?>-`      ~-->vvv?????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvv}}rrrrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vv}rrwwzzzxxuuuuuuuuuuuux|<br></br>
`vvv?????????????????????????????????????????????vv}}rrwwzzzxxxuuuuuuuuuxx|<br></br>
`}}}vv????????????????????????????????????????????vv}rrwwwzzxxxuuuuuuuuuuu|<br></br>
`wwrr}vvv?????????????????????????????????????????vv}rrwwwzzxxxxuuuuuuuuuu|<br></br>
`zzwwwrr}vvvv?????????????????????????????????????vv}rrwwzzzzxxxxuuuuuuuuu|<br></br>
`zxzzzwwwrr}vvvv????????????????v}??????????????vvvv}}rrwzzzzxxxxuuuuuuuuu|<br></br>
`xxxxxzzzwwwrr}}vvvvv????????vwzrxxw}}v?>??????vvvv}}rrwwzzzzxxxxxuuuuuuuu|<br></br>
`xuxxxxxxzzzzwwwr}}vvvvvv?v?ruwr}xuuxrwzv?????vvvv}}rrwwzzzxxxxxxuuuuuuuuu|<br></br>
`uuuuuuxxxxxxzzzwwwrrr}}vvv}uuzwxuuuuxxuz?v??v}}}rrrrwwzzzxxxxxxuuuuuuuuuu|<br></br>
-uuuuuuuuxxxxxxxxzzzzzwwrw}zuuuuuuuuuuuuwvrrv?v}rrwwwzzzzxxxxxuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuxxxxzzzzzzwrwuuuuuuuuuuuz}rxuzv?v}wwzzzzzxxxxxuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuxxxxxxzrxxzxxxuuuxxxw}wuuuuwvv?}zzxxxxxxxxuxuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuuxwuuuxxxxxxzzzzxuuuxxwvwv?rwwzzxxxxxwrwuuuuuxxxxuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuzwxuuuuuuuuuuuuuuux}z}wxr?``~|+?rzzzrrxuuuuuxxxxuu|<br></br>
-xuuuuuuuuuuuuuuuuuuuuuxzxwuuuuuuuuuuuuuzwrwrwuxwv>+++|`|}wwwxuuuuuuuxxuuu|<br></br>
`xuxxuuuuuuuuuuuuuuuuuuuzuxzxuuwwzzzxwrwvvwwzuuz}v}vvrr}wrrwzwwwxuuuxxxxuu|<br></br>
`xxxxxxxxuuuuuuuuuuuuuuuwxuuzzxzwwxzw}}v}wzxuuxwvvvv}rwzzwzw}}}}zzzw}}xxuu|<br></br>
                  `zxxxxxxxxxxxxxxuuuuuuuurzuuuuxxxzxxxzxzwzxuuuz??vvvwzzzzzzzzzzrrwrwzxxxxu|<br></br>
`zzzzzzxxxxxxxxxxxuuuuuww?vxuuuuuuuuuuuzvvrwx}| -++>v}zzzzzwrvvwxxuuuxxxxx|<br></br>
`wwzzzzzzxxxxxxxxxxxxuwrv..vwxxuuuuuuuz}}rrv>^??}}v?>|+v}}}}}v+vuuxxxxxxxx|<br></br>
`wwwwwwwzzzzzzzzxxxxxxr}` ?rr}rwwzzzxzw}?v?>vrwwrrrrrv+?}}}rrr}>?wuxxxxxzz|<br></br>
`rr}rrrrwwwwzzzzzzzzxwr+ >wrrr}vvv?````>?v++vrrrrrrrrrwwrrrrrrrw}>vxxxzzzz|<br></br>
`vvvv}}}}rrrwwwwwwwzzr}.`}rrrrrvvv|->vrzzwr?~vrrrrrrrrrrrrrrrrrrwwv?zzzzzz|<br></br>
`vvvvvvvvv}}}rrrrrrwwrv `rrrrr}vvvrxxxxxxxwr^>}wwwwwwwwwwwwwwwwwwww~}zwwww~<br></br>
`???????vvvvvv}}}}}}}}v^.?wrrwwzwwzzzxxxzzr}^+~~+>>+^+++++++++>>>+- vzwwww~<br></br>
`????????????vvvvvvv}rwrvv}}}zwwwrwrrzwr}vvv`+`                    -rwrrrr~<br></br>
`?????????????????vv}vvv?^}vw}rzv}z}vvvv??v+`???>?????????????>>>>>}r}}}}}-<br></br>
`????????????????????vvvwv?}wvzr}z}}vvvvvvv^ .>v?>++^~-``?}}}}}}}v}}vvvvvv-<br></br>
`?????????????????????vvv-`}vvwrrvv}vvvvv}+  ~v?.       .v}vvvvvvvvvvvv?vv-<br></br>
`????????????????????vvv}+ ?rwrvv}}vvvvvv^  -v?.       .-vvvvv????????????-<br></br>
`???????????????????vvvvvv+}v}vvr}vvvvv?-  `?>-`      ~-->vvv?????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvvvv}?vrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vv}zwwwzzzwzx}wuuuyuuuuux|<br></br>
`vvv?????????????????????????????????????????????vv}xz}rwzwrzz}wxzrzuxuuxz|<br></br>
`}}}vv????????????????????????????????????????????v}xxz}wzrr}rzzzr}}zzwxzx|<br></br>
`wwrr}vvv?????????????????????????????????????????vvzxxzrwrrvvv}}wzxxxz}rx|<br></br>
`zzwwwrr}vvvv????????????????????????????????????vvvv}}zz}}}?vv}vv}v}vvvwx|<br></br>
`zxzzzwwwrr}vvvv????????????????????????????????vvv}}v?vzwz}vvvvvv?v?}zzz}-<br></br>
`xxxxxzzzwwwrr}}vvvv???????????????????????????vvvv}rrvvzxxvv?vvv?vvwxxwv}|<br></br>
`xxxzzzzxzzzzwwrrr}vvvvvv????????????????????vvvvv}rrwvrxxxzwvv??vwzxxwvru|<br></br>
`wzzzxxzwzxxxzzwwxw}rr}}vvvvvvvvvvvv?????vvvvv}}rrrrww}rxxxxxz}}zxxzzwvruu|<br></br>
-xzr}rwzzwzxxxxxwxzwrzwwwrrrr}}}}}}vvvvvvv}}}}rrrwwwwzwrxzzxzwzxxxwr}v}uuu|<br></br>
-uyuxr}}rwzzxuuuzwzz}wzzzzzwwwwwrrrrrrrrrrrrrrwwwwzzzz}}rzzxwzzxzwvvv?}uuu|<br></br>
-uuuuuurvwzzzzxuuwxzvwxxxxxxxzzzzzzwzwwzzzzzzzzzzzzzxr}w?+vr}zzzwvvv??vwuu|<br></br>
-uuuuuuuw}wwwzzwzwxwv}uuuuxxxxxxxxxxxzzzzxxxxxxxxxxxxrrr}?~>v}wz}v?>^vvvxu|<br></br>
-uuuuzzzxxwv}wwwrzxw}vruuuuuuuuuxxxxxxxxxxxxxxxxxxxxuwrrrrv^|>??>^||?vvvwu|<br></br>
-xuuzzzwwrvvvv}}}wxzzw}wuuuuuuuuuuuuuuuurwwwzxuuuuuuuxrrrrrrv>>>>?vvvvvvwu|<br></br>
`xuuzv}}wz}vvvv}rvwxxxzrzyuuuuuuuuuuuuxzzxxw}}zxuuuuuurrrrrrrw}v}vvvvvvruu|<br></br>
`xxzzzzxxzvvv}vvvvzxxxxz}wzxuuuuuuuuuuwxzxxuxxw}}ruuuuzrrrrrrrrrvvvvvvruuu|<br></br>
`zzrxzwrr}vvv?vvvvrzxxxw?rrrwuuuuuuuuxwxuxxwrwrrzvvuuywvrrrrrrrr}vvvvwxxxu|<br></br>
`zzr}v}}rwwrvv?vvrrwzxz}?+rr}wuuuuuuxzzzzrvr}vzxzzw}}zzvrrrrrrrrvvvvwuxxxx|<br></br>
`zzwwwzxxxxxxzzzzzzxwww}}-vrrrwuuuuuzuxwrwzwrrxxuuux}v}}rrrrrrrvvvrxuxxxxx|<br></br>
`r}v}}rwwwzzzzzzzzzxxzwr}-vrrr}wuuuuzzwxuuur?vv}wxuuurvvrrrrrrvvvruxxxxxzz|<br></br>
`}}}}rrr}vvvvvvv}rwzzxr}}`vrrrwr}zuuwzxuuuwv}rwwxxxuuz}vrrrrrvvv}xxxxzzzzz|<br></br>
`vvvv}}}rrrr}}vvvvvv}}vr?-}rrrrrvvwwzuuuuuxxzxxuuuuzw}vrrrr}}vvrxxxzzzzzzz|<br></br>
`vvvvvvvvv}}}rv?vv?v???>|?}rrrrrrv}}xuuuuuuuuuuxzrvv}rr}r}}vvvwzzzzzzwwwww~<br></br>
`???????vvvvv}vvv?^^~~^>vwrrrrrrr}rrwxxxxxxzw}vvv|~^>>?}vvv}wzzzzwwwwwwwww~<br></br>
`????????????vv?}vvvvvv}rwwrrrrrrrrrr}rrr}}v?^-|>`.  .-.?rwwwwwwwwwwrrrrrr~<br></br>
`???????????????vvvvvvv}}rrrrrrrrrrrrrrrrrr?. . `.  ~?? ?zwwwwwwrrrr}}}}}}~<br></br>
`????????????????vvvvvvvvvv}rrrrrrrrr}vvrv~  ~?..~  `+- >wrrrrrr}}}vvvvvvv-<br></br>
`???????????????????vvvvvvvvvvvvvvvvvvvvv`  `?v. -`   ..>r}vvvvvvvvvvvv??v-<br></br>
`?????????????????????vvvvvvvvvvvv}}rwwwr?.  ..   ~.  ^^`vvvvv????????????-<br></br>
`???????????????????vvvv}}}rr}}rrrrrrrrrrwv.  -   ..     >vvv?????????????-<br></br>


            </div>,

            <div>

.??>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvvvvv}?vrrrrrrr}}}-<br></br>
`vv??????????????????????????????????????????????vv}zwwwzzzwzx}wuuuyuuuuux|<br></br>
`vvv?????????????????????????????????????????????vv}xz}wwzwrzz}wxzrzuxuuxz|<br></br>
`}}}vv????????????????????????????????????????????v}xxz}wzrr}rzzzr}}zzwxzx|<br></br>
`wwrr}vvv????????????????????????????????????????vvvzxxzrwrrvvv}}wzxxxz}rx|<br></br>
`zzwwwrr}vvvv????????????????????????????????????vvvv}}zz}}}?vv}vv}v}vvvwx|<br></br>
`zxzzzwwwrr}vvvv????????????????????????????????vvv}}v?vzwz}vvvvvv?v?}zzz}-<br></br>
`xxxxxzzzwwwrr}}vvvv???????????????????????????vvv}}rrvvzxxvv?vvv?vvwxxwv}|<br></br>
`xxxzzzzxzzzzwwrrr}}}vvv????????????????????vvvvv}}rrwvrxxxzwvv??vwzxxwvru|<br></br>
`wzzzxxzwzxxxzzwwxw}wr}}vvvvvvvvvvvv?????vvvvv}}rrrwww}wxxxxxz}}zxxzzwvruu|<br></br>
-xzr}rwzzwzxxxxxwzzwrzwwwrrrr}}}}}}vvvvvvv}}}}rrrwwwwzwrxzzxzrzxxxwr}v}uuu|<br></br>
-uyuxr}}rwzzxuuuzwzz}wzzzzzwwwwwrrrrrrrrrrrrrrwwwwzzzz}}rzzxwzzxzwvvv?}uuu|<br></br>
-uuuuyurvwzzzzxuuwxzvwxxxxxxzzzzzzzzzwwzzzzzzzzzzzzzxr}w?+vr}zzzwvvv??vwuu|<br></br>
-uuuuuuuw}wwwzzwxwxwv}uuuuxxxxxxxxxxxzzzzxxxxxxxxxxxxrrr}?~>v}zz}v?>^vvvxu|<br></br>
-uuuuzzzxxwv}wwwrzxw}vruuuuuuuuuxxxxxxxxxxxxxxxxxxxxuwrrrrv^|>??>^||?vvvwu|<br></br>
-xuuzzzwwrvvvv}}}wxzzw}wuuuuuuuuuuuuuuuurwwwzxuuuuuuuxrrrrrrv>+>>?vvvvvvzu|<br></br>
`xuuzv}}wz}vvvv}rvzxxxzrzyuuuuuuuuuuuuxzzxxw}}zxuuuuuurrrrrrrw}v}vvvvvvruu|<br></br>
`xxzzzzxxzvv}}vvvvzxxxxz}wzxuuuuuuuuuuwxzxxuxxw}}wuuuuzrrrrrrrr}vvvvvvruuu|<br></br>
`zzrxzwrr}vvv?vvvvrzxxxw?rrrwuuuuuuuyxwxuxzwrwrrzvvuuywvrrrrrrrr}vvvvruxxu|<br></br>
`zzr}v}}rwwrvv?vvr}wzxzr?+rr}wuuuuuuxzzwzrvrvvzxzzw}}zzvrrrrrrrrvvvvwuxxxx|<br></br>
`zzwwwzxxxxxxzzzzzzxwww}}-vrrrwuuuuuzuxzrwzwrrxxuuux}v}}rrrrrrrvvvrxuxxxxx|<br></br>
`r}v}}rwwwzzzzzzzzzxxzwr}-?rrrrwuuuuzwwxuuur?vv}wxuuurvvrrrrrrvvvruxxxxxzz|<br></br>
`}}}}rrr}vvvvvvv}rwzzxr}}`vrrrrr}wuuwzxuuuwv}rrwxxxuuz}vrrrrrvvv}xxxxzzzzz|<br></br>
`vvvv}}}rrrr}}vvvvvv}}vr?-}rrrrrv?wwzuuuuuxxzxxuuuuzw}vrrrr}}vvrxxxzzzzzzz|<br></br>
`vvvvvvvvv}}}rv?v??v???>|?}rrrrrrv}}uuuuuuuuuuuxzrvv}rr}r}}vvvwzzzzzzzwwww~<br></br>
`???????vvvvv}vvv?^^~~^>vwrrrrrrr}rrwxxxxxxzw}vvv|~^>>?}vvv}wzzzzwwwwwwwww~<br></br>
`????????????vv?}vvvvvv}rrwrrrrrrrrrr}rrr}}v?^-|>`.  .-`?rwwwwwwwwwwrrrrrr~<br></br>
`???????????????vvvvvvv}}rrrrrrrrrrrrrrrrrr?. . `.  ~?? ?zwwwwwwrrrr}}}}}}~<br></br>
`????????????????vvvvvvvvvv}rrrrrrrrr}vvrv~  ~?..~  `+~ >wrrrrrr}}}vvvvvvv-<br></br>
`???????????????????vvvvvvvvvvvvvvvvvvvvv`  `?v` -`   ..>r}vvvvvvvvvvvv??v-<br></br>
`?????????????????????vvvvvvvvvvvv}}rwwwr?.  ..   ~.  ^^`vvvvv????????????-<br></br>
`???????????????????vvvv}}}rr}rrrrrrrrwwrwv.  -   ..     >vvv?????????????-<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvv}}}vvv??rrrr}}}}-<br></br>
`vv??????????????????????????????????????????????vvv}xxwwxxxxwxzv}xxwzuuxx|<br></br>
`vvv?????????????????????????????????????????????vvv}xxxwwxxwrwzzzxxz}wzzz|<br></br>
`rr}vvv??????????????????????????????????????????vvvvzzxxrzxrwrrzzwr}rwzxz~<br></br>
`wwwr}}vvv???????????????????????????????????????vvvvv}}wz}vww}vv?vrzxxz}v~<br></br>
`zzzwwrr}}vvv????????????????????????????????????vvv}}v?vwwrw}vvvv}v}rv?vx|<br></br>
`xxxzzzwwrr}}vvvv???????????????????????????????vvv}}rwvvwxzzrvv?vvvvv?}xx|<br></br>
`xxxxxxzzzwwwrr}vvvvv?????????????????????????vvvv}}rrw}vzzzxrv??vvvv?vzzz~<br></br>
-uuuxxxxxzzwwwrwwrr}}vvvvvv??????????????????vvv}}rrrww}vzzzzxzv?vvv}rwxwv`<br></br>
-uuuuuuxxxxwzxzrwwwwrrr}}}vvvvvvvvvvvvvvvvvvvv}}rrrwwwzrrxzzzxx}}rzzxxzrvv~<br></br>
`zxuuuuuuuuxrxxxwwzzzwwwwwrrr}}}}}}vvvvvv}}}}}rrrwwwzzw}}zxzzzwzxxxzw}vvvz|<br></br>
`zrrxuuuuuuuzwxxxrzxxzzzzzzwwwwwwwwrrrrrrrrwwwwwwzzzzz}rr?}zzzwxzzz}vvv??w|<br></br>
`v}wzzzxuuuuuwzxzvruxxxxxxxzzzzzzzzzzwwwwwwzzzzzzzzxxxrrw?|vzzzxxzvvvv?>?v-<br></br>
`w}vwzzwrrzxuwzxwvvuuuuuuxxxxxxxxxxzzzzzzzzzzxxxxxxxxuwrrr?|+vrwz}vv?+|?vv-<br></br>
-uuwvwzwwwwzw}}xz}v}uuuuuuuuuuxxxxxxxxxxxxxxxxxxxxxuuuzrrrr}?^||||||+?v}vv`<br></br>
`wwzxxzvvrwrrwrwxxzr}xuuuuuuuuuuuuuuuuuuxzxxxxuuuuuuuuurrrrrrrvv??vvvvvvv}~<br></br>
`wzzw}}vvvv?v}}wzxxxz}rwwzuuuuuuuuuuuuuxzwxxz}}xuuuuuuu}rrrrrrrrvvvvvvvv}u|<br></br>
`r}vvrr}rr}v??vwzzzzzz}wrrwuuuuuuuuuuuxzxxxxzwrrwwxyuuxvrrrrrrrrr}vvvvv}uu|<br></br>
`wzzxxrvv}rv}}vvrzzzzx?+rrrruyuuuuuuuywzzzxzwww}wzvwyyuv}rrrrrrrr}vvvv}uxx|<br></br>
`rzzw}vvvvv?vv?vvrzzxz}->}wrrxuuuuuuuxwzxz}}vvrwzzr}zzzvvrrrrrrr}vvvvrxxxx|<br></br>
`}rrvvvvvv}rwwzzzzzwzwr?`}rrr}zuuuuuxzxrrr}rrrwxxxuuz}vvrwrrrrr}vvvvwxxxzx|<br></br>
`wwzzzzzzzxxxxxxxxxxwrr?`rrrrr}wxuuuzxuxwzxzw}rxuuuuuuwvrrrrrr}vv}wxxxxzzz|<br></br>
`vvvvvv}}rrrrr}}rwzzxwr~-r}rrrrrv}xuxrzxuuuurvv}}xuuuuzv}rrrr}?vzxxxxzzzzz|<br></br>
`vvvvvvvvvvvvvvvvvv}rr? ?}rrrrrrrvvrwzuuuuuzvrrwzuuuxw}vrrr}v?vxxxzzzzzzzz~<br></br>
`v??vvvvvv}v????vvvv>~.+r}rrrrrrrrr}ruuuuuuxxxxuxz}vv}r}}v}vvwxzzzzzzwwwww~<br></br>
`????????vvv?vv?^-``-^?}rrrrrrrrrrrrruuuuuxxxwr}v?~?vrrrvvvrzzzzzwwwwwwwww~<br></br>
`?????????????vv???vvv}rrrrrrrrrrrrrrrrrrr}v?|^?v?`...``-vwzwwwwwwwwrrrrrr~<br></br>
`??????????????vv}vvvv}rrrrrrrrrrrrrrrrrrrrr?`..~|   |? `wwwwwrrrrrr}}}}v}-<br></br>
`???????????????vvvvvvvvv}}}}vvvvvv}r}}}}rr+. .` `` .>?`.rrrr}}}}}}vvvvvvv-<br></br>
`?????????????????????vvvvvvvvvvvvvvvvvvvv`  `??. |. .  .v}vvvvvvvvvvv???v-<br></br>
`????????????????????vvvv}}}rrrwwwwwwwwwwr?. .^~. .~  .>|~vvvvv???????????-<br></br>
`???????????????????vvv}}}rrrrrrrrwwwwwwwwwv.  .   `.  . .????????????????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvv}}}vv}??rrrr}}}}-<br></br>
`vv??????????????????????????????????????????????vvv}xxwwxxxxwxzv}xxwzuuxx|<br></br>
`vvv?????????????????????????????????????????????vvv}xxxwwxxwrwzzzxxz}wzzz|<br></br>
`rr}vvv??????????????????????????????????????????vvvvzzxxrzxrwrrzzwr}rwzxz~<br></br>
`wwwr}}vvv???????????????????????????????????????vvvvv}}wz}vww}vv?vrzxxz}v~<br></br>
`zzzwwrr}}vvv????????????????????????????????????vvv}}v?vwwrw}vvvv}v}rv?vx|<br></br>
`xxzzzzwwrr}}vvvv???????????????????????????????vvv}}rwvvwxzzrvv?vvvvv?}xx|<br></br>
`xxxxxxzzzwwwrr}vvvvv?????????????????????????vvvv}}rrw}vzzzxrv??vvvv?vzzz~<br></br>
-uuuxxxxxzzwwwrwwrr}}vvvvvv?????????????????vvvv}}rrwww}vzzzzxzv?vvv}rwxwv`<br></br>
-uuuuuuxxxxwzxzrwwwwrrr}}}vvvvvvvvvvvvvvvvvvvv}}rrwwwwzrrxzzzxx}}rzzxxzrvv~<br></br>
`zxuuuuuuuuxrxxxwwzzzwwwwwrrrr}}}}}vvvvv}}}}}rrrwwwwzzw}}zxzzzwzxxxzw}vvvz|<br></br>
`zrrxuuuuuuuzwxxxrzxxzzzzzzwwwwwwwwrrrrrrrwwwwwwzzzzzx}rr?}zzzwxzzz}vvv??w|<br></br>
`v}wzzzxuuuuuwzxzvruxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzxxxxrrw?|vzzzxxzvvvv?>?v-<br></br>
`w}vwzzzrrzxuwzxwvvuuuuuxxxxxxxxxxxxxzzzzzzxxxxxxxxxxuwrrr?|+vrwz}vv?+|?vv-<br></br>
-uuwvwzwwwwzw}}xz}v}uuuuuuuuuuuuxxxxxxxxxxxxxxxxxxuuuuzrrrr}?^||||||+?v}vv`<br></br>
`wwzxxzvvrwrrwrwxxzr}xuuuuuuuuuuuuuuuuuuxzxxxxuuuuuuuuurrrrrrrvv??vvvvvvv}~<br></br>
`wzzw}}vvvv?v}}wzzxxz}rwwzuuuuuuuuuuuuuxzwxxz}}xuuuuuuu}rrrrrrrrvvvvvvvv}u|<br></br>
`r}vvrr}rr}v??vwzzzzzz}wrrwuuuuuuuuuuuxzxxxxzwrrwwxyuuxvrrrrrrrrr}vvvvv}uu|<br></br>
`wzzxxrvv}rv}}vvrzzzzx?+rrrruyuuuuuuuywzzzxzwww}wzvwyyuv}rrrrrrrr}vvvv}uxx|<br></br>
`rzzw}vvvvv?vv?vvrzzxz}->}wrrxuuuuuuuxwzxz}}vvrwzzr}zzzvvrrrrrrr}vvvvrxxxx|<br></br>
`}rrvvvvvv}rwwzzzzzwzwr?`}rwr}zuuuuuxzxrrr}rrrwxxxuuz}vvrwrrrrr}vvvvwxxxxx|<br></br>
`wwzzzzzzzxxxxxxxxxxwrr?`rrrrr}wxuuuzxuxwzxzw}rxuuuuuuwvrrrrrrrvv}wxxxxzzz|<br></br>
`vvvvvv}}rrrrr}}rwzzxwr~-r}rrrrrv}xuxrzxuuuurvv}}xuuuuzv}rrrr}?vzxxxxzzzzz|<br></br>
`vvvvvvvvvvvvvvvvvv}rr? ?}rrrrrrrvvrwzuuuuuzvrrwzuuuxw}vrrr}v?vxxzzzzzzzzz~<br></br>
`v??vvvvvv}v????vvvv>~.+r}rrrrrrrrr}ruuuuuuxxxxuxz}vv}r}}v}vvwxzzzzzzwwwww~<br></br>
`????????vvv?vv?^-``-^?}rrrrrrrrrrrrruuuuuxxxwr}v?~?vrrrvvvrzzzzzwwwwwwwww~<br></br>
`?????????????vv???vvv}rrrrrrrrrrrrrrrrrrr}v?|^?v?`...``-vwzwwwwwwwrrrrrrr~<br></br>
`??????????????vv}vvvv}rrrrrrrrrrrrrrrrrrrrr?`..~|   |? `wwwwrrrrrr}}}}}v}-<br></br>
`???????????????vvvvvvvvv}}}}vvvvvv}r}}}}rr+. .` `` .>?`.rrrr}}}}}vvvvvvvv-<br></br>
`?????????????????????vvvvvvvvvvvvvvvvvvvv`  `??. |. .  .v}vvvvvvvvvvv???v-<br></br>
`????????????????????vvvv}}}rrrwwwwwwwwwwr?. .^~. .~  .>|~vvv?????????????-<br></br>
`??????????????????vvvv}}rrrrrrrrrwwwwwwwwwv.  .   `.  . .????????????????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvv}}}vv}??rrrr}}}}-<br></br>
`vv??????????????????????????????????????????????vvv}xxwwxxxxwxzv}xxwzuuxx|<br></br>
`vvv?????????????????????????????????????????????vvv}xxxwwxxwrwzzzxxz}wzzz|<br></br>
`rr}vvv??????????????????????????????????????????vvvvzzxxrzxrwrrzzwr}rwzxz~<br></br>
`wwwr}}vvv???????????????????????????????????????vvvvv}}wz}vww}vv?vrzxxz}v~<br></br>
`zzzwwrr}}vvv????????????????????????????????????vvv}}v?vwwrw}vvvv}v}rv?vx|<br></br>
`xxzzzzwwrr}}vvvv???????????????????????????????vvv}}rwvvwxzzrvv?vvvvv?}xx|<br></br>
`xxxxxxzzzwwwrr}vvvvv?????????????????????????vvvv}}rrw}vzzzxrv??vvvv?vzzz~<br></br>
-uuuxxxxxzzwwwrwwrr}}vvvvvv?????????????????vvvv}}rrwww}vzzzzxzv?vvv}rwxwv`<br></br>
-uuuuuuxxxxwzxzrwwwwrrr}}}vvvvvvvvvvvvvvvvvvvv}}rrwwwwzrrxzzzzx}}rzzxxzrvv~<br></br>
`zxuuuuuuuuxrxxxwwzzzwwwwwrrrr}}}}}vvvvv}}}}}rrrwwwwzzw}}zxzzzwzxxxzw}vvvz|<br></br>
`zrrxuuuuuuuzwxxxrzxxzzzzzzwwwwwwwwrrrrrrrwwwwwwzzzzzx}rr?}zzzwxzzz}vvv??w|<br></br>
`v}wzzzxuuuuuwzxzvruxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzxxxxrrw?|vzzzxxzvvvv?>?v-<br></br>
`w}vwzzwrrzxuwzxwvvuuuuuxxxxxxxxxxxxxxzzzzzxxxxxxxxxxuwrrr?|+vrwz}vv?+|?vv-<br></br>
-uuwvwzwwwwzw}}xz}v}uuuuuuuuuuuuxxxxxxxxxxxxxxxxxxuuuuzrrrr}?^||||||+?v}vv`<br></br>
`wwzxxzvvrwrrwrwxxzr}xuuuuuuuuuuuuuuuuuuxzxxxxuuuuuuuuurrrrrrrvv??vvvvvvv}~<br></br>
`wzzw}}vvvv?v}}wzzxxz}rwwzuuuuuuuuuuuuuxzwxxz}}xuuuuuuu}rrrrrrrrvvvvvvvv}u|<br></br>
`r}vvrr}rr}v??vwzzzzzz}wrrwuuuuuuuuuuuxzxxxxzwrrwwxyuuxvrrrrrrrrr}vvvvv}uu|<br></br>
`rzzxxrvv}rv}}vvrzzzzx?+rrrruyuuuuuuuywzzzxzwww}wzvwyyuv}rrrrrrrr}vvvv}uxx|<br></br>
`rzzw}vvvvv?vv?vvrzzxz}->}wrrxuuuuuuuxwzxz}}vvrwzzr}zzzvvrrrrrrr}vvvvrxxxx|<br></br>
`}rrvvvvvv}rwwzzzzzwzwr?`}rwr}zuuuuuxzxrrr}rrrwxxxuuz}vvrwrrrrr}vvvvwxxxxx|<br></br>
`wwzzzzzzzxxxxxxxxxxwrr?`rrrrr}wxuuuzxuxwzxzwrrxuuuuuuwvrrrrrr}vv}wxxxxzzz|<br></br>
`vvvvvv}}rrrrr}}rwzzxwr~-r}rrrrrv}xuxrzxuuuurvv}}xuuuuzv}rrrr}?vzxxxxzzzzz|<br></br>
`vvvvvvvvvvvvvvvvvv}rr? ?}rrrrrrrvvrwzuuuuuzvrrwzuuuxw}vrrr}v?vxxzzzzzzzzz~<br></br>
`???vvvvvv}v????vvvv>~.+r}rrrrrrrrr}ruuuuuuxxxxuxz}vv}r}}v}vvwxzzzzzwwwwww~<br></br>
`????????vvv?vv?^-```^?}rrrrrrrrrrrrruuuuuxxzwr}v?~?vrrrvvvrzzzzzwwwwwwrww~<br></br>
`?????????????vv???vvv}rrrrrrrrrrrrrrrrrrr}v?|^?v?`...``-vwzwwwwwwrrrrrrrr~<br></br>
`??????????????vv}vvvv}rrrrrrrrrrrrrrrrrrrrr?`..~|   ~? `wwwwrrrrrr}}}}}v}-<br></br>
`???????????????vvvvvvvvv}}}}vvvvvv}r}}}}rr+. .` `` .>?`.rr}}}}}}}vvvvvvvv-<br></br>
`?????????????????????vvvvvvvvvvvvvvvvvvvv`  `??. |. .  .v}vvvvvvvvvv????v-<br></br>
`????????????????????vvvv}}}rrrwwwwwwwwwwr?. .^~. .~  .>|~vv??????????????-<br></br>
`??????????????????vvvv}}rrrrrrrrrwwwwwwwwwv.  .   `.  . .????????????????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvv}}}vvv??rrrr}}}}-<br></br>
`vv??????????????????????????????????????????????vvv}xxwwxxxxwxzv}xxwzuuxx|<br></br>
`vvv?????????????????????????????????????????????vvv}xxxwwxxwrwwzzxxz}wzzz|<br></br>
`rr}vvv??????????????????????????????????????????vvvvzzxxrzxrwrrzzwr}rwzxz~<br></br>
`wwwr}}vvv???????????????????????????????????????vvvvv}}wz}vww}vv?vrzxxz}v~<br></br>
`zzzwwrr}}vvv????????????????????????????????????vvv}}v?vwwrw}vvvv}v}rv?vx|<br></br>
`xxzzzzwwrr}}vvvv???????????????????????????????vvv}}rrvvwxzzrvv?vvvvv?}xx|<br></br>
`xxxxxxzzzwwwrr}vvvvv?????????????????????????vvvv}}rrw}vzzzxrv??vvvv?vzzz~<br></br>
-uuuxxxxxzzwwwrwwrr}}vvvvvv?????????????????vvvv}}rrwww}vzzzzxzv?vvv}rwxwv`<br></br>
-uuuuuuuxxxwzxzrwwwwrrr}}}vvvvvvvvvvvvvvvvvvvv}}rrwwwwzrrxzzzzx}}rzzxxzrvv~<br></br>
`zxuuuuuuuuxrxxxwwzzzwwwwwrrrr}}}}}}}}}}}}}}}rrrwwwwzzw}}zxzzzrzxxxzw}vvvz|<br></br>
`zrrxuuuuuuuzwxxxrzxxzzzzzzwwwwwwwwrrrrrrrwwwwwwzzzzzx}rr?}zzzwxzzz}vvv??w|<br></br>
`v}wzzzxuuuuuwzxzvruxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzxxxxrrw?|vzzzxxzvvvv?>?v-<br></br>
`w}vwzzwrrzxuwzxwvvuuuuuuxxxxxxxxxxxxxzzzzzxxxxxxxxxxuwrrr?|+vrwz}vv?+|?vv-<br></br>
-uuwvwzwwwwzw}}xz}v}uuuuuuuuuuuuxxxxxxxxxxxxxxxxxxuuuuzrrrr}?^~|||||+?v}vv`<br></br>
`wwzxxzvvrwrrwrwxxzr}xuuuuuuuuuuuuuuuuuuxzxxxxuuuuuuuuurrrrrrrvv??vvvvvvv}~<br></br>
`wzzw}}vvvv?v}}wzzxxz}rwwzuuuuuuuuuuuuuxzwxxz}}xuuuuuuu}rrrrrrrrvvvvvvvv}u|<br></br>
`r}vvrrrrr}v??vwzzzzzz}wrrwuuuuuuuuuuuxzxxxzzwrrwwxyuuxvrrrrrrrrr}vvvvv}uu|<br></br>
`rzzxx}vv}rv}}vvrzzzzx?+rrrruuuuuuuuuywzzzxzwww}wzvwyyuv}rrrrrrrr}vvvv}uxx|<br></br>
`rzzw}vvvvv?vv?vvrzzxz}->}wrrxuuuuuuuxwzxz}}vvrwzzr}zzzvvrrrrrrr}vvvvrxxxx|<br></br>
`}rrvvvvvv}rwwzzzzzwzwr?`}rwr}zuuuuuxzxrrr}rrrwxxxuuz}vvrwrrrrr}vvvvwxxxxx|<br></br>
`wwzzzzzzzxxxxxxxxxxwrr?`rrrrr}wxuuuzxxxwzxzw}rxuuuuuuwv}rrrrr}vv}wxxxxzzz|<br></br>
`vvvvvv}}rrrrr}}rwzzxwr~-r}rrrrrv}xuxrzxuuuurvv}}xuuuuzv}rrrr}?vzxxxxzzzzz~<br></br>
`vvvvvvvvvvvvvvvvvv}rr? ?}rrrrrrrvvrwzuuuuuzvrrwzuuuxw}vrrr}v?vxxzzzzzzzzz~<br></br>
`???vvvvvv}v????vvvv>~.+r}rrrrrrrrr}ruuuuuuxxxxuxz}vv}r}}v}vvwxzzzzzzwwwww~<br></br>
`????????vvv?vv?^-```^?}rrrrrrrrrrrrruuuuuxxxwr}v?~?vrrrvvvrzzzzzwwwwwwrww~<br></br>
`?????????????vv???vvv}rrrrrrrrrrrrrrrrrrr}v?|^?v?`...``-vwzwwwwwwrrrrrrrr~<br></br>
`??????????????vv}vvvv}rrrrrrrrrrrrrrrrrrrrr?`..~|   ~? `wwwwrrrrrr}}}}}v}-<br></br>
`???????????????vvvvvvvvv}}}}vvvvvv}r}}}}rr+. .` `` .>?`.rr}}}}}}}vvvvvvvv-<br></br>
`?????????????????????vvvvvvvvvvvvvvvvvvvv`  `??. |. .  .v}vvvvvvvvvv????v-<br></br>
`???????????????????vvvvv}}}rrrwwwwwwwwwwr?. .^~. .~  .>|~vv??????????????-<br></br>
`??????????????????vvvv}}rrrrrrrrrwwwwwwwwwv.  .   `.  . .????????????????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?????vvvvv}}}vv}??rrrr}}}}-<br></br>
`vv??????????????????????????????????????????????vvv}xxwwxxxxwxzv}xxwzuuxx|<br></br>
`vvv?????????????????????????????????????????????vvv}xxxwwxxwrwwzzxxz}rzzz|<br></br>
`rr}vvv??????????????????????????????????????????vvvvzzxxrzxrwrrzzwr}rwzxz~<br></br>
`wwwr}}vvv???????????????????????????????????????vvvvv}}wz}vww}vv?vrzxxz}v~<br></br>
`zzzwwrr}vvvv????????????????????????????????????vvv}}v?vwwrw}vvvv}v}rv?vx|<br></br>
`xxzzzzwwrr}}vvvv???????????????????????????????vvv}}rwvvwxzzrvv?vvvvv?}xx|<br></br>
`xxxxxxzzzwwwrr}vvvvv?????????????????????????vvvv}}rrw}vzzzxrv??vvvv?vzzz~<br></br>
-uuuxxxxxzzwwwrwwrr}}vvvvvv?????????????????vvvv}}rrwww}vzzzzxzv?vvv}rwxw}`<br></br>
-uuuuuuuxxxwzxzrwwwwrrr}}}vvvvvvvvvvvvvvvvvvvv}}rrwwwwzrrxzzzzx}}rzzxxzrvv~<br></br>
`zxuuuuuuuuxrxxxwwzzzwwwwwrrrr}}}}}}}}}}}}}}}rrrwwwwzzw}}zxzzzrzxxxzw}vvvz|<br></br>
`zrrxuuuuuuuzwxxxrzxxzzzzzzwwwwwwwwrrrrrrrwwwwwwzzzzzx}rr?}zzzwxzzz}vvv??w|<br></br>
`v}wzzzxuuuuuwzxzvruxxxxxxxzzzzzzzzzzzzzzzzzzzzzzzxxxxrrw?|vzzzxxzvvvv?>?v-<br></br>
`w}vwzzwrrzxuwzxwvvuuuuuuxxxxxxxxxxxxxzzzzzxxxxxxxxxxuwrrr?|+vrwz}vv?^|?vv-<br></br>
-uuwvwzwwwwzwv}xz}v}uuuuuuuuuuuuxxxxxxxxxxxxxxxxxxuuuuzrrrr}?^~|||||^?v}vv`<br></br>
`wwzxxzvvrwrrwrwxxzr}xuuuuuuuuuuuuuuuuuuxzxxxxuuuuuuuuurrrrrrrvv??vvvvvvv}~<br></br>
`wzzw}}vvvvvv}}wzzxxz}rwwzuuuuuuuuuuuuuxzwxxzv}xuuuuuuu}rrrrrrrrvvvvvvvv}u|<br></br>
`r}vvrrrrr}v??vwzzzzzz}wrrwuuuuuuuuuuuxzxxxzzwrrwwxyuuxvrrrrrrrrr}vvvvv}xu|<br></br>
`rzzxx}vv}rv}}vvrzzzzx?+rrrwuuuuuuuuuywzzzxzwww}wzvwyyuv}rrrrrrrr}vvvv}uxx|<br></br>
`rzzw}vvvvv?vv?vvrzzxz}->}wrrxuuuuuuuxwzxz}}vvrwzzr}zzzvvrrrrrrr}vvvvrxxxx|<br></br>
`}rrvvvvvv}rwwzzzzzwzwr?`}rwr}zuuuuuxzxrrr}rrrwxxxuuz}vvrwrrrrr}vvvvwxxxxx|<br></br>
`wwzzzzzzzxxxxxxxxxxwrr?`rrrrr}wxuuuzxuxwzxzwrrxuuuuuuwv}rrrrrrvv}wxxxxzzz|<br></br>
`vvvvvv}}rrrrr}}rwzzxwr~-r}rrrrrv}xuxrzxuuuurvv}}xuuuuzv}rrrrv?vzxxxxzzzzz~<br></br>
`vvvvvvvvvvvvvvvvvv}rr? ?}rrrrrrrvvrwzuuuuuzvrrwzuuuxw}vrrr}v?vxxzzzzzzzzz~<br></br>
`???vvvvvv}v????vvvv>~.+r}rrrrrrrrr}ruuuuuuxxxxuxz}vv}r}}v}vvwzzzzzzzwwwww~<br></br>
`????????vvv?vv?^-```^?}rrrrrrrrrrrrruuuuuxxxwr}v?~?vrrrvvvrzzzzzwwwwwwrww~<br></br>
`?????????????vv???vvv}rrrrrrrrrrrrrrrrrrr}v?|^?v?`...``-vwzwwwwwwrrrrrrrr~<br></br>
`??????????????vv}vvvv}rrrrrrrrrrrrrrrrrrrrr?`..~|   |? `wwwwrrrrrr}}}}}v}-<br></br>
`???????????????vvvvvvvvv}}}}vvvvvv}r}}}}rr+. .` `` .>?`.rr}}}}}}}vvvvvvvv-<br></br>
`?????????????????????vvvvvvvvvvvvvvvvvvvv`  `??. |. .  .v}vvvvvvvvvv????v-<br></br>
`????????????????????vvvv}}}rrrwwwwwwwwwwr?. .^~. .~  .>|~vv??????????????-<br></br>
`??????????????????vvvv}}rrrrrrrrrwwwwwwwwwv.  .   `.  . .????????????????`<br></br>


            </div>,

            <div>

.????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>??????????????????vvvvvv??`<br></br>
`vvvv?????????????????????????????????????????????????vv??vvvvvvv}zzwwrr}}~<br></br>
`v????????????????????????????????????????????????????vvvvvvvvvvv}zzwwr}vv-<br></br>
`v???????????????????????????????????????????????????vvvvvvvvvvvvrzzzww}vv-<br></br>
`v?vvvvv???????????????????????????????????????????vvvvvvvvvvvvvvrzzwwr}vv-<br></br>
`vvvv?????????????????????????????????????????????vvvvvvvvvvvvvvv}rrr}}vvv-<br></br>
                  `??vv???????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vv??vv???????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uxwv?vv?vv??????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uyyrvv??vvvv????????????????????????vv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuxwvv?vvvvv????v???????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`wzxuuuuuxwv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvv}rrr}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrrrr}vvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}zwwwrrrwzx|<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzwwwzuuyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwzzzxuuuuyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvwzxxuuuuyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wzzxxzxxxuuuuyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzuyyyyuxuuuuuuuuu|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rzzzuyyyyuuxxzwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrwzxxxzzwwrr}}}}}rrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vv}}}}}}}}}}rrrrrrrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}r}rrrrrrrwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}vvv}}}}}}}}}}}}}rrrrrrrrrrrrrwwwwz~<br></br>
`vv}}vvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrwwwwwwwwwz|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrwzzzzzwwwzzzz|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrwzzzzzzzzzzzx|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrwwzzzzzzzzzzxzx|<br></br>
`}rrrr}}}}}}}}}}}}}}}r}}}}}}}}}}}}}rrrrrrrrrrrrrrrrrrrrrwwwwwzzzzzzzzzxxxx|<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwwwwwwzzzzzzxxxxxxu|<br></br>
`rwrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}rrrrrrrrrrrrrrrrrrrrrrrrwwwwwwzzzzzzzzxx|<br></br>


            </div>,

            <div>

.????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>???????????????????????`<br></br>
`vvvv?????????????????????????????????????????????????????vvvvvvvvv}}}vvvv-<br></br>
`v???????????????????????????????????????????????????vvvvvvvvvvvvvv}}}vvvv-<br></br>
`v?????????????????????????????????????????????????vvvvvvvvvvvvvvv}}}}vvvv-<br></br>
                  `v?vvvvv???????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vvvv?????????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `??vv???????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vv??vvv?????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uuwv?vv?vv????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uyyrvv??vvvv????????????????????????vv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuxwvv?vvvvv??????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`wzxuuuyuxwv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvv}rrr}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}vvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}rrwzx|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}wuuyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wzzxuyyyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxyyyuyyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wzzxuuuuuuuuuyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzuyyyyuuuuuuuuuuu|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rzzzuyyyyuuxxzwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rwzxxxzzwwrr}}}}}rrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vv}}}}}}}}}}}rrrrrrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}rrrrrrrrwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}vv}}}}}}}}}}}}}}}}rrrrrrrrrrrwwwwz~<br></br>
`vvv}vvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrwwwwwwwz|<br></br>
`}}}}}}}}}}vvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrwwwwwwwwwzzz|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrwwwzzzzzzzzzx|<br></br>
`}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrwwzzzzzzzzzxzx|<br></br>
`rrrrr}}}}}}}}}}}}}}}}r}}}}}}}}}}}}rrrrrrrrrrrrrrrrrrrrrwwwwwwzzzzzzzzxxxx|<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwwwwwwzzzzzzxxxxxxu|<br></br>
`rwwrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}rrrrrrrrrrrrrrrrrrrrrrwwwwwwwzzzzzzzzxx|<br></br>


            </div>,

            <div>

.????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>????????????????????????`<br></br>
                  `vvvv???????????????????????????????????????????????????vvvvvvvvvvvvvvvvvv-<br></br>
                  `v????????????????????????????????????????????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
                  `??????????????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvv???????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vvvv?????????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `??vv?????????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vv??vvv?????????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uuwv?vv?vv????????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
                  -uyyrvv??vvvv??????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuxwvv?vvvvv??????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`wzxuuuyuxwv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvv}rrr}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}rwzx|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wuuyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzzxuyyyyy|<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxyyyuuyyyy^<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wzzxuuuuuuuuyyy^<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzuyyyyyyyuuuuuuuu|<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rzzzuyyyyuuxxzwwwwwwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rwzxxxzzwwrr}}}}}rrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vv}}}}}}}}}}rrrrrrrrrw~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}rrrrrrrrwww~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}}}vvvv}}}}}}}}}}}}}}}}rrrrrrrrrrwwwwz~<br></br>
`vv}}vvvvvvvvvvvvvvvvvvv}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrwwwwwwwz|<br></br>
`}}}}}}}}}}}}}v}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrwwwwwwwzzz|<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrrwwwwwwzzzzzzx|<br></br>
`}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrrwwwwwwzzzzzxzx|<br></br>
`rrrrr}}}}}}}}}}}}}}}}r}}}}}}}}}}}}rrrrrrrrrrrrrrrrrrrrrwwwwwwwwzzzzzzxxxx|<br></br>
                  `rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrwwwwwwwwzzzzzzxxxxxxu|<br></br>
`rwwrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}rrrrrrrrrrrrrrrrrrrrrrwwwwwwwzzzzzzzzxx|<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}}}vvvvvv???>>>>>>>>>>>>>>>>>>>>>>>>??????????????????????????`<br></br>
                  `zxzzzzzzzzzzzzzrrwzzwrvv???????????????????????????????vvvvvvvvvvvvvvvvvv-<br></br>
                  `zzzzzzzzzzzzzzzzzwwwwv??????????????????????????????vvvvvvvvvvvvvvvvvvvvv-<br></br>
`zzzzzzzzzzzzzzwrw}}v}vvv??????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`zzzzzwwwwwrr}}rvv???v?????????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`zzzwrv}?v?v???v??vv>?????????????????????????????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`w}vv?????}vv}vvw}zwv?????????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`v???v}rwwzzzzzzzzzz}??????vvv???????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}vvwzzzzzzzzzzzzzzzv?vrrrwzzzwwwv?????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`rwwxzzzzzzzzzzzzzzv>?wzzzzzzzzzzzw}???????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`}rrxxxzrr}vvvrwzzr>v??vv?v}zzr}vvvvvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
.r}?wuux}}r>+??vrzrr}??vv???vr}vv?v?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
.}rv?rxxxxxzzzzzzzz}v??vvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`vvvvvwzzzzzzzzzzzrv??v?vvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`wwv???zxzzzzw}v}????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}rwzx|<br></br>
`wxwwv??vvwzzzzrv?vvvv}rwwvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}vv}wuuyyy|<br></br>
`rzwxz}vv?>vrr}rv?vrvrrrwwzzzw}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzzxuyuyux|<br></br>
`wwwxz}wxxxzrr}vvv}vrr}}wzxxxxzzrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxyyyuuyuxu^<br></br>
.???}wrwzwrvvvvv}}>>v}vwxxxxzwzxxxwvvvvvvvvvvvvvvvvvvvvvvv}wzzxuuuuuuyuxxz-<br></br>
.>?????rwwrv?????++++>?vrzzwzxxxxxuzvvvvvvvvvvvvvvvvvvvvrzuyyyyyyyuuuuzxz}-<br></br>
.>>>????????>>>>>>>?>+++>v}zxxxxxxzzzvvvvvvvvvvvvvv}rzzzuyyyyuuxxzwwwwuxvv-<br></br>
.????????????????????>>>+vv}zxxxzzzzxx}}vvvvvvvvvvv}rwzxxxzzwwrr}}}}rxz}v}~<br></br>
.>?????>?????????????>>>+?}vvwzzzxxxwzw}vvvvvvvvvvv}vvv}}}}}}}}}}r}rxzvv}r~<br></br>
.>?>>>>>>?????????????>>+?}vvrxzxzwxrv}vvvvvvvvvvv}}}}}}}}}}}}}}r}ruzvv}wz|<br></br>
.>??>>++>>????????????>>+vv}v}wvz}}r}}vvvvvvv}}}}vrwzzzzwr}rrrrr}ruwvv}rwz~<br></br>
.+>>>>>??>????????????>>>vv}vv}vvv}vv}}}}}}}}}}}}}xzzzzxxzw}}rr}wxzv}rwwwz|<br></br>
`vv?>+++>?????????????>+?vv}}}}}v}}}}}}}}}}}}}}}}}wxzzzzr}}v?v}}zwv}rwwwzz|<br></br>
-uuuxzw}v????????>???>>+v}}}}}}}}}}}}}}}}}}}}}}}}}}zzzz}vvvvv}rr}v??wzzzzz|<br></br>
-uuuuuuuuuxxw???>>??>+>+v}}}}}}}}}}}}}}}}}}}}}}}rrrrxxz}vv?wzw}???rzxxxxxu|<br></br>
-uuuuuuuuuuuuuv>???>+++>}r}}}}}}}}}rrrrrrrrrrrrrrrr}zxxrvvrrrr??rxxxxxxxxu|<br></br>
-uuuuuuuuuuuuuuv??>>++++?rrrrrrrrrrrrrrrrrrrrrrrrrr}zxz}}zzwv?vzxxxxxxxxxu|<br></br>
-uuuuuuuuuuuuuuz?>>>++++>vrrrr}}}}}rrrrrrrrrrrrrrr}vzzwv}wzr?vzzzzzzzzzw}r~<br></br>


            </div>,

            <div>

.??vvv}}}}}}}}}}}}}}vvvv}???>>>>>>>>>>>>>>>>>>>>>>>>??????????????????????`<br></br>
`}rzxxxxxxxxxxxxxxxxzwzzx}vvv????????????????????????vvvvvvvvvvvvvvvvvvvvv-<br></br>
`rzxxxxxxxxxxxxxxxxzzwzww}??????????????????????????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
`rzzzxxxxxzzwxzwrwwr}vvvvv?????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`v}wzxzzwwr}}vvv?v?v?????vv????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.v}www}v?vv?????????>vv???v????????????????????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvv}vv?>>v?}vrrrwzzwzzwv?????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `vvvvvv?vrzzzxxxxxxxzzzz?????????????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-}vvv}}}}xxzzzzxxxxxzzzv??v}}vvv?v?????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-wz?vvv}}xxzwrrwrrwzzzv^vvxzzxxwvvvvv????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`uwvv?v??zxxr?}>^???}zv}v?zzzzwrr}?vvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`w}???rv>vxzzwzr}wzwzzz}?>vrw}?v??v???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
`?+}v?v}r}wxzxxxxxxzzzwv??v?????v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}~<br></br>
.??v}vvv}zwwxxxxxwr}rv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}}~<br></br>
.??vr?wxuzww}}rzzzzrv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rwzxu|<br></br>
.???ww}xuxrwwv?>vrrw}?v}?}rww}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}rxuyyyy^<br></br>
.????rzuuxrrxuuxrw}v?v}vrzwrwxxzwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvv}wzxuyuuyuy|<br></br>
.???????}vzwzxzrv}}vvr?}wwvruuuuuzzzw}vvvvvvvvvvvvvvvvvvvvvvv}}wuyyyyyyyyx~<br></br>
.????????>>?}ww}vv???+^>??vrxuxxxxuuuuxrvvvvvvvvvvvvvvvvv}rzxxuuuuuyuuyyuw-<br></br>
.??????????>>>??>>+>+^+>++>v}}wuyyuuuxxzw}vvvvvvvvvvvvv}wuyyyyyyyuuxzwzx}v`<br></br>
.????????????>>??????>>>+>+}}}}wuyuxzzxxuurvvvvvvvv}wxxuyyyuuuxxzw}wxz}wvr~<br></br>
.?????>>>>>>>>>>?????>>>^+^v}}}}}rzxxuxuxwz}vvvvvvv}}rzzzzwwwr}}}vrxwwwzzw-<br></br>
.?????????????????????>>^+^vvv}}}v}xzxw}zv}}vvvvvvv}vv}}}}}}}}}}}zxwzxzzzw~<br></br>
.???????????>>>>>>>>>?>>^+^vvv}}}}}}}v}vvv}vvvvv}}}}}}}}}}}}}}}}xxxxxzzzzx~<br></br>
.????????>>>>>>>>>>>>>>>??+v}}}}}}}}?}vv}}}}}}}}}}}}rrrrrrrr}r}wxxxzzzzxxx|<br></br>
.???????>????????????vrzuu}v}}}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrrrvrzxxzzzxxxx~<br></br>
.??????>???????????}xuuuuuyxr}}}}}}}}}}}}}}}}}}}}}rrrrrrrrrr}}rzxzzzzxxxxr-<br></br>
.????????????????}uuuuuuuuuuuuxxzzw}}}}}}}}}}}}}}}rrrwwrrrrrrzzzzzzzzxxzr}-<br></br>
.?????????????>vxuuuuuuuuuxwxuuuyyrv}}}}}}}rrrrrrrrrrrrrwwzzzzzzzzzzw}vvvv-<br></br>
.?????????????}uuuuuxuuuxzrzuuuuyw??vvrrrrrrrrrrrrrwzxxzzzzzzzzzzzwvv?vvrx|<br></br>
.????????????wyuuuuuuuxwwrruuuuyz?vvv}zzwwr}rr}}wzxxxzzzzzzzzzzzzrvvvzxuuu|<br></br>
.???>???????}yyuuuuuuzrrvwuuuxxw?????rzzzzzrr}}wzzzzwzwwwwwwwwzwv?vrzxxxxx|<br></br>


            </div>,

            <div>

.????vvvvvvv}}}}}}}}vv}}}v?vvv>>>>>>>>>>>>>>>>>>>>>>??????????????????????`<br></br>
`v}wzzzwzzxxxxxxxxxxxzzxxwrrrrvvvv????????????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`}wwwwwwwzxxxxxxxxxzzzzzzw}rwr}v????????????????????vvvvvvvvvvvvvvvvvvvvv}~<br></br>
`}wwwwwwwwzzzxzzwzwrr}}r}}vvvv}vv??????????????????vvvvvvvvvvvvvvvvvvvvvv}`<br></br>
`vvrwwrrrwwrr}}vvv?vvvv?vv????v???????????????????vvvvvvvvvvvvvvvvvvvvvvv?~<br></br>
`v}rrr}v}vvvv?v???????v?????v????????????????????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvv????vvvvv?vv}}}rwwv}}}rvv???????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`w}vvv??vvrr}}rrzzxxxxxx}}wwwv???????????????vvvvvvvvvvvvvvvvvvvvvvvvv}vvv-<br></br>
-xzzwrv?v}rrrrrzxxzzzzxw}rwwr??v???????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-xuwzw}vv}rr}}}zzwwwww}?}rxw?vrrv????????vvvvvvvvvvvvvvvvvvvvvvvvvvwv}vrvv~<br></br>
`uxwzwzrrwrrv??vv}v?v??v?vzzrrvr}v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvw}rwzzvv~<br></br>
`r}vr}zwr}rv}rvvwzzzzrrwr}rwwvvrv?v??vvvvvvvvvvvvvvvvvvvvvvvvvvv}wrrzzzzr}~<br></br>
`?>}vvv}}vwrrrwwzxxxxxzr}ww}v??v}v?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvzwwzzzzzzr-<br></br>
.??v}v}}vvrwzwwzwwwwzw}vv}vvv???vv?vvvvvvvvvvvvvvvvvvvvvvvvvvvv}zzzzzzzwr}`<br></br>
.??vr}z}v?}r}zwzzwr}vv}rrrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzzzzzzxzwz|<br></br>
.???ww?vvvvrww}vrwwwv>?r}r}}wvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvzzzzxuxzzu^<br></br>
`?????vvvv??}wzxzwwzwwr}}}}}vwzzwwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvrxxxuuzwwuy^<br></br>
.???????????????v}www}vrw}rvwxxwwzxxzrvvvvvvvvvvvvvvvvvvvvv}v}wxuuuuzwxuyu|<br></br>
.???????????????>???v?+>?>+>?v}wzxuuxuuxwwr}vvvvvvvvvvvv}rwzzxuuuuuzwxyyux|<br></br>
.????????????>?>>>>>++>>++^+++>vzzzxuuuuuxwwwr}vvvvvv}}}zxuuuuuuuuzrzxxxww~<br></br>
.????????????>>?????>>>>>>+>+++vxxzzzxxxxxzzzzzrvvv}zxxxuuuuuuuuzrvwxzwwrw~<br></br>
.???????>>>>>>>>???>>>?>>>>???}xxxuxxxzxxzxzwwz}}}vvvzxxxxxxxxzrvvrzwzzzzz~<br></br>
.????????????????>>>????>>>??rxxxzxuuxzxuxwrr}wrrr}}rwzzzzzzzw}v}zzwzzzzzz|<br></br>
.??????????????>>>?????>>>>?}xxxxxxxwwwxxzz}zzzzzzzzzzzzzzzzw}v}zzzzzzzzzx~<br></br>
.??????????>>?>>??????????>vxxxxxxxwrrwzxxzvzzzzzzzzzzzzzzzr}v}wzzzzzzzxxx|<br></br>
.??????????????????????vvv?rxxxxxzwrrzxxxurvrwwwwwwwzzzzzwrv}rrwzzzzzxxxxx|<br></br>
.????????????????>???vvvv?}uxxxxzr}rwxxxxzv}v}}}}}}}}}}v}vv}rrrzzzzzzxxxxz~<br></br>
`vv???????????>>>?????????xyuuuuzwwzxxxzr+?vvvv}}v}}}}vv}}rwwzzzzxxxxxxxzz~<br></br>
`vv???????>>>>>??????????}xxuuxzxxzzzzwr?^>v}}}}}}}}}rrrwzzzzzzzzxxxzzzwww~<br></br>
`vv?????>>>>>>???????????}wzxxzzxw}}rrr}rrrrrrrrrrwwzzxzzzzzzxxxxxzwwwwzzu|<br></br>
`vvv???????????????????????}}zxxwv}}}rzzwwrrrrrrwzxxxxxzxxxxxxxxxzwwwxxuuu|<br></br>
`vvv???????????????vvv????????ww}}}}}rwwwwwwrrrwzzzzzzzzzzzzzzzzwrrzxxxxxx|<br></br>


            </div>,

            <div>

.?????>>???v}}}}}}}}}}}}}vvvv}>>>>>>>>>>>>>>>>>>>>>>??????????????????????`<br></br>
                  `vvvv?vvvrzzxxxxxxxxxxxxxxwzzxvvvv????????????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v??????vrxxxxxxxxxxxxxxzzwzxzrv????????????????????vvvvvvvvvvvvvvvvvvvv?}-<br></br>
`???????vrwxxxxzwzzwrrrwr}}v}rrv???????????????????vvvvvvvvvvvvvvvvvvvvvv}`<br></br>
`?v?v???vwwwzwr}}}vvv}vvvv????v?v??????????????????vvvvvvvvvvvvvvvvvvvvvv?~<br></br>
`?vvvvvvwr}}v?vvv??????????vv????????????????????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`v???v?v?vvvvvv>??vvvvrw}wzzzv????????????????vvvvvvvvvvvvvvvvvvvvvvvv?v?v-<br></br>
-xwvv????vv}vvvvwzzxxxxxxxxxxv???????????????vvvvvvvvvvvvvvvvvvvvvvvvv}vvv-<br></br>
-uyuxwv???vvv}vzxzzzzzxxxxzx}>>????????????vvvvvvvvvvvvvvvvvvvvvvvvvvv?vvv~<br></br>
-uuuyyzvv>vvvvvxxwwwwr}}rzxr^>???????????vvvvvvvvvvvvvvvvvvvvvvvvvvzvv?wvv~<br></br>
`xuuuuuuxrvv??>rxxrvv++???zwvv?v???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvzvrwxxv}~<br></br>
`}}rwzxuu}v?v}>?xxxzwv}wwzzxwv?wv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrz}wxzzxrv~<br></br>
`vvvvvv}v?rvvv}}wxxxxxxzxxzwv?>vv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvxzzxzzzxx}`<br></br>
`vvvvv}r?>v}rv}wrwzxxxzr}}v?????v???vvvvvvvvvvvvvvvvvvvvvvvvvvv}xxxxxxxzrv.<br></br>
`vvv}zw???v}}uzwwrvvvwzxzrv????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrxxxxxxxrvv~<br></br>
`vvvv}?????}rwzxuwwr?>?rwr?vr?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvzxzxxxwv?x^<br></br>
`vvv?>??????vv}zxwwuuxrw}v?v?vwzrww}vvvvvvvvvvvvvvvvvvvvvvvvv}vwxzzxzv??zy^<br></br>
`vvv????????>>>?}}zxx}vrrvr?vww}vzuuxwvvvvvvvvvvvvvvvvvvvv}}vvzxxzxw??ruyy^<br></br>
`vv?????????????>>??}v??>+^^++?}}wuuzxuuxzw}vvvvvvvvvvvv}wwwwxxxxxzv?wyyuy|<br></br>
`vv>?????????>>>>+>>>>>>>>^+^^^>v}}wxyyyyuzzzw}vvvvv}}rwzzzxxxxxxxv?wuxzzz|<br></br>
`v?>???????>>>>?????>>>>>>++^^^vzw}wwxxxzzzuuuxwvvv}zxzwzzxxxxxxwv?rrrrrww~<br></br>
`v?>??????????????>+^>>??????>}yyyuzwzwwxxzuxzx}r}vv?wzxxxxxxxz}?vrrrrrrww|<br></br>
`v?>?????????????>+>?????????ryuuyyyyzxyyywrw}zrrrvv}zxxxxxxxwv?}rrrrrrwwz|<br></br>
`v?>???????????>>??????????>}yyyyyyuzwzyyyxvxxzxxxxxxxxxxxxxr??}rrrrrwwwwz|<br></br>
`}????????????>?????????????uyuuyyuzwwuyyyz?zxxxxxxxxxxxxxz}??}rrrrwwwwzzz|<br></br>
`}????????????????????????>wyyuuyuwrrxyuyy}?rwwwwwwwzzzzwrv?vrwwwwwwwzzzzz|<br></br>
`}v>???????????>>>>>>>>>>>?yyuuyur}rxuyyyz???????????vv????}wrwwwwwzzzzzzx|<br></br>
`rv?????????>>+^^++++++++^wyyyuxw}wxuyuur~??????????????v}wwwwwwwwzzzzzxxx|<br></br>
`r}???????>+++++^^+^^^++^?xuuxz}rwzuuxzw?|^?vvvvvvvvvvvrwwwwwwwzzzzzzxxxxx|<br></br>
`rr?????>>>>>+++^+++++++~?zzzw}}rwzzzw}}rrrrrrrrrrwwwwwwwwwwwzzzzzxxxxxxxu|<br></br>
`rr}?????????>++++++++>>>^>??}r}}}}rr}rrrrrrrrrrwwwwwwwwwzzzzzzzxxxxxuuuuu|<br></br>
`wwzv???????>+++>>>>????>>^^^^vrrr}}}rrrrrrrrrrrrrrrrrrwwwwwwwzzzzzzxxxxxx|<br></br>


            </div>,

            <div>

.?????>>???vvvvvvvvvvv}}}}vv}}vvvvv?v>>>>>>>>>>>>>>>??????????????????????`<br></br>
`vv??vvvv}wwwwwwwwzxxxxxxxzzzxwwwwrwwv????????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v??????v}wwwwwrwwwzxxxxxzzzxzzwr}vrrwvv????????????vvvvvvvvvvvvvvvvvvvvv}-<br></br>
`v??????v}rwwwww}wwzzzzzwwwwwwwwwwrrww}vv??????????vvvvvvvvvvvvvvvvvvvvvv}`<br></br>
`vv????vvrrrwr}vvvvrrwr}r}}}}}}r}vrrw}}v??????????vvvvvvvvvvvvvvv}vvvvvvv?~<br></br>
`?vvvvvvr}vvvvvv??vvvvv??vvvvvv????vv?vv??????????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vv?vv?v?vvvvvv>?vvvvvvvvvv}}??>??????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-ux}v???vvvvvvvv}}}rr}rrzwzzzvvrr}rr}v???v???vvvvvvvvvvvvvvvvvvvvvvvvv}vvv-<br></br>
-uyyuzrv??vvvvvr}}}}rrrzxxxxwv}wwwwwv????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-uuuyyurv?vvvvvww}vvvvvwwzwr??}wwwwv>????vvvvvvvvvvvvvvvvvvvvrv}}rvwv}vw}v~<br></br>
`xuuuuuuxzr}v??}}rv??^>}}}}}???vvwv>??v?vvvvvvvvvvvvvvvvvvvv}}}zzwr}rwzzv}~<br></br>
`v}rwzxxuzwrrw?>rx}}rv?zzzzzrv}wrr}}v?vvvvvvvvvvvvvvvvvvvvv}wrzwzxr}zzzzr}~<br></br>
`vvvvvvvvvrvwv?vzwwr}rwwxxxzw}}wwrvv?vvvvvvvvvvvvvvvvvvvvvvwzzwrxzzrzzzzzr-<br></br>
`vvvvv}}v?rrv???rwwzwzzwrww}r}vv}v??vvvvvvvvvvvvvvvvvvvvvvvwzwwwxxwwzzzzwr-<br></br>
`vvv}wrvvv}??vvvvz}rzxzzw}vv}rwrv?vvvvvvvvvvvvvvvvvvvvvvvvvrzzwzxwrxxxuxzz|<br></br>
`vvvvrvvv????vvvvvzxwvvzzrvv}v}rv}vvvvvvvvvvvvvvvvvvvvvvvvv}zzzrrrxuuuxzzu^<br></br>
`vvv????????????v?vwwwrzzrrzrrwr}rv}}v}zwvvvvvvvvvvvvvvvvv}wzzw}zuuuuzzzuy^<br></br>
`vvv?????????>>???vvv???v}xwwrrv}}zzwrrzzrwwwrzzzzrrr}vvv}zzwwrwuuuuzwxuyy^<br></br>
`vv???????????????????>>>++>?>>>+vzwrzxuxxxzzwzzzwwzzwrvrxxzzzrxuuuzwxyuuu|<br></br>
`vv?????????????>>>>>>>>>>++^^^^^v}rwzxzxuxxxzzzzwzzwwrzxzzxxwzuxxw}zxzzzz|<br></br>
`vv?????????????????>>>>>>>>>++???}zxzzwwwwzwzxzwwzwwzxzzxxxzrxxz}vrrrrrww~<br></br>
`v?????????????????>>>>>???????vvwuuxuxzuuuxwwz}}}}vvzxxxxxxwwzwv}rrrrrwww|<br></br>
`v???????????????>>>?????????vvvzyyyuuxuyyuzzrzwrrvv}wxxxxxzrw}v}rrrrrwwwz|<br></br>
`vv??????????????????????????vvzyyyyuzzuyyuvxxxxxxxxxxxxxxxwr}vrrrrrwwwwwz|<br></br>
`vv?????????????????????????vvwyuyyxzwxyuyz?zxxxxxxxxxxxxxwv}vrrrrwwwwzzzz|<br></br>
`}v???????????????????????>?vruyyuzrrxuuuuv?}rrrrrwwzzzzw}vv}rwwwwwwzzzzzz|<br></br>
`}v???v?????????>>>>>>>>>>>vvxyyuw}wzuyuu}??????????vvv???vrwwwwwwwzzzzzzx|<br></br>
`r}?v?v??????>>++++++++++^?v}uuxwrzxxuux?.|?v???????????vrrwwwwwwzzzzxxxxx|<br></br>
`rrvvvvv???>>>>>>>>+++^++>??vr}v}wwzzzwr?+>v}}}}}}}}}rrrwwwwwwzzzzzxxxxxxu|<br></br>
`rrv?vvv?>>>>>>>>>>++++++?????>>?}wwwwr}rrrrrrrrrrwwwwwwwwwzzzzzzxxxxxuuuu|<br></br>
`rwrvvvvv????>>>>>>>>>>?>>?????>>>vwwrrrrrrrrrrwwwwwwwwwzzzzzzxxxxxxuuuuuu|<br></br>
`wwz}vvvv???>>>>>????????>>>++>>>>>}rrrrrrrrrrrrrrrrrrwwwwwwwzzzzzzzxxxxxx|<br></br>


            </div>,

            <div>

.?????????????????????}}}}}}}}}vvvv?v>>>>>>>>>>>>>>>??????????????????????`<br></br>
                  `vv????????v?v????vzxzxxxxxxxxxxxxzzxv????????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v???????????????v}wxxxxxxxxxxxxzwrwwzv?????????????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `v???????????????vwzxxxxxxxxxxxxxxzzzzrvv??????????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`vv?v???????????vv}zxxzwzwwzwzzzwrwzz}}v??????????vvvvvvvvvvvvvvv}}vvvvvvv-<br></br>
`v?vvv???????????v}rrrvvvv}}}v}v?v?vv??v?????????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -zv???vv?v???????vvvv??????v??????????????????vvvvvvvvvvvvvvvvvvv?vvvvvvvv-<br></br>
-uuz}v??vv???????vvvv?vvw}wwwrwzwwwz}?>??v???vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uyyuuzrv?vvvvv???vvv}vwxxxxxxxxxxxxv?>????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
-uuuyyyu}vv??vvv??v???vxzwrrrrwzxxz?>>???vvvvvvvvvvvvvvvvvvvvrv}}wvvvvvvvv~<br></br>
`zxuuuuuuuxrvvv??v?v?^?xxr?v>+??}zv+??v?vvvvvvvvvvvvvvvvvvvvr}}zxw?vvvvvv}~<br></br>
`vv}rwzzxuuxxzv>vx?vv??zxxzwr}zwwzr}??vvvvvvvvvvvvvvvvvvvvvrxrxxxxrvvvvvv}~<br></br>
`vvvvvvvv}}}uv>?zv}v?v}rxxxxxxzxxxr??vvvvvvvvvvvvvvvvvvvvvvzxxxzxxx?vvvvv}~<br></br>
`vvvvvvvvrwrv>??}r}rvrwrwzxxxz}v}v??vvvvvvvvvvvvvvvvvvvvvv?zxxxxxx}?v}}rww~<br></br>
`vvvvvvwxxw??????xrwyywwrv}wzxxr?????vvvvvvvvvvvvvvvvvvvvvvrzzxxxr?zzxuuyy|<br></br>
`vvv?}zxxr??????>?zyyuwuxrvvvrwr?v}?vvvvvvvvvvvvvvvvvvvvvvv}zzxzvvxyyyuuyy|<br></br>
`vvvv??vv????????>?v}vrzuwzuxwrv?v?}r}ruzvvvvvvvvvvvvvvvvv}zxxz?}uyyyuuuyy|<br></br>
`vvvvvv?>????????????>??v}uuxvrv}?vrvvzyuwxxxrxuuurwr}vvv}xxzxvvyyuyyyyyyy^<br></br>
`vvvvvvv>????????????>>>>+?vv?>^|>vvvwwuwxuuuwyuyxzuuzrvrxxxzzvuyuuuuuuuuu|<br></br>
`vvvvvv???>???????>>>+>>>>++^^^|^?vvvv}v}zxxzzyyywuxzwrzxxxxx}rxzzzzzzwwww~<br></br>
`vvvvvv?????????>>>>???>>>>>>>>>+>}zzwvvvv}}v}wzwwuw}wxxxxxxz?rrr}rrrrrrww~<br></br>
`vvvvvv>?????????????>>>>????????vuyyyxzuuuzvvvvvv}vvzxxxxxxvvrrrrrrrrrwwz|<br></br>
`vvvv}v>????????????>>??????????ryyyyyxzyyywwwwrrr}vvwxxxxxz?}rrrrrrrrwwwz|<br></br>
`vvv}}v>??????????????????????>}yyyyuzwxyyu}xxxxxxxxxxxxxxx}vrrrrrrrwwwwwz|<br></br>
`v}}}}}>??????????????????????vyyyyuw}zyuyw?zxxxxxxxxxxxxx}vrrrrrrwwwzzzzz|<br></br>
`}}}}}r?????????????>>>>>>>>>?uyyuw}rzuyuu??vv}}}rwzzzzzwv?rrrrwwwwwzzzzzx|<br></br>
`}}rrrr?????????>>++++++++++^wyyuw}wzxuux???????????vvv?>vwrwwwwwzzzzzzxxx|<br></br>
`}rrrrrv>?????>>>>>++^++^^+^>xxxzwzzzzzw>.|?????????????}wwwwwwzzzzzzxxxxx|<br></br>
`rrrrrrr???????????>++^++++++vv?}r}rrwr}vvv}rrrrrrrrrrwwwwwwwzzzzzxxxxxxxu|<br></br>
`rrwwwwwv>????????>+++>>>>???>^^+vwrrr}rrrrrrrrrrrwwwwwwwwzzzzzxxxxxxuuuuu|<br></br>
                  `rwwwwwww????????????????????+^+^^?wwwrrrrrrrwwwwwwwwwwwzzzzzxxxxxxuuuuuuu~<br></br>
`wwzzzzwz}>?????????????????+>+^^^|}rrrrrrrrrrrrrrrwwwwwwwwwwzzzzzzzxxxxxw.<br></br>


            </div>,

            <div>

.?????????????????????vvvvvv}}vv}v?vv???>>>>>>>>>>>>??????????????????????`<br></br>
                  `vv????????v??????vrwrwzzxxxxxxxxxzzxwvrv?????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v???????????????v}rwwzxxxxxxxxxxzwzzzrrr}?v????????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
`v???????????????vrwwwzxxxxxxxxxxxxzzzrrww}v???????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`???v???????????vv}rwwzzzzzzzzzzzzzzzwwwrrvvv?????vvvvvvvvvvvvvvv}}vvvvvvv-<br></br>
`vv?vv???????????vv}v}r}r}r}rrr}vr}vvvvvvvvv??????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uwv??vv?v???????vvvvvvvv??vv?v?v}????????????vvvvvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uyxwvv??v????????vvvvvv}v}wr}}rrrrw}v?}?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uyyyuxwv?vvvvv???vv?vv}rrrzzxzzxxxxr}}wv???vvvvvvvvvvvvvvvvv?vvvvvvvvvvvv~<br></br>
-uuuuuyyzvv???vv??v??v?v}}}wwrwzxxx}vvrv???vvvvvvvvvvvvvvvv}vr}}}rvvvvvvvv~<br></br>
`zxxuuuuuuxw}vv??v??}??rvvvrv>???}v?}}???vvvvvvvvvvvvvvvvv}vwwrwzwvvvvvvv}~<br></br>
`vvv}rwzxxuuuz}??v??r}?rw}wxwwzr}wr}r}v??vvvvvvvvvvvvvvvvwrwxwxzwzrvvvvvv}~<br></br>
`vvvvvvvvvvwurv?v??wvv}vrwwxxxxxxxzrrrv?vvvvvvvvvvvvvvvvvzwzzxxzrzzvvvvvv}~<br></br>
`vvvvvvvv}rzr??????}rrw}rzzzxzwwwv?v}v?vvvvvvvvvvvvvvvvvvwwzzxxwwzrv}}rwwz|<br></br>
`vvvvvv}zxw}v????v?vxywxzwrwwwzwr}v???vvvvvvvvvvvvvvvvvvvrwzxzrwzwrzxuuyyy|<br></br>
`vvvv}zxxw?v??????vv}wwuuz}zw}}r}r}?vvv}vvvvvvvvvvvvvvvvvrwwzrwzwwuyyyuuyy|<br></br>
`vvvvv}}}??????????????vr}wuuwzwr}?vvvrxwzzr}}}vrrr}}rr}vwwzzrxzxuuyyuuuyy|<br></br>
                  `vvvvvv???????????????????vrzrxww?vvvvwzzzuxxzxxuuxzxzzwrwxxwwxzyyuyyyyyyy^<br></br>
`vvvvvvv???????????????>>>>>>>??>+>+v}}wwxuxuxuuyuxxuxwrzxxxwzzuuuuuuuuuxx|<br></br>
`vvvvvv???>????????>>>>>>>>>++^^^++>vvvv}rrzzxuuuxxxxzwzxxxxwwwxzwwwwwwwww~<br></br>
`vvvvvv?????????????????>>>>>>>>>>?vzzw}v}v}v}}wwwzw}zxxxxxxrvrrrrrrrrrwww~<br></br>
`vvvvv}??????????????>>>>?????????ruyyuuuuuxvvvvv}rvvzxxxxxzv}rrrrrrrrwwwz|<br></br>
`vvvv}v??????????????>???????????ruyyyuzuyyzwzwrrrrvvwxxxxxzvrrrrrrrwwwwzz|<br></br>
`vv}}}v?????????????????????????ruyyuzrxyyu}xxxxxxxxxxxxxxxvvrrrrrrwwwwzzz|<br></br>
`v}}}}}????????????????????????ruyyuw}xyyyr?zxxxxxxxxxxxxz}?rrrrwwwwzzzzzx|<br></br>
`}}rr}rv????????????>>>>>>>>>>vuyuz}rzuyuz??vvv}}rwzzzzzrv?rrwwwwwwzzzzzxx|<br></br>
`}rrrrrv?????????>>+++++++++^?wyuzrwzxuuw???????????vvv?>vwwwwwwwzzzzxxxxx|<br></br>
`rrrrrr}?v?????>>>>>>>>+^^^++?}r}}wwwzzw>-+?vvv?vvvvvvv?}wwwwwzzzzzzxxxxxu|<br></br>
`rrrrrrrv???????????>>++++>>>??>>>?}rrr}}}}rrrrrrrrwwwwwwwwwzzzzzxxxxxxuuu~<br></br>
`rwwwwww}?v????????>>>>>??????>>^>??vrrrrwrrrrrrwwwwwwwwzzzzzzxxxxxxuuuuuz`<br></br>
`wzzzzwwwvvv?????????????????>>++^+??rrrrrrwwwwwwwwzzzzzzzzxxxxxxuuuuuuuur`<br></br>
`wzzzzzzzwvv???????????????>>>+++^|>?rrrrrrrrrrrwwwwwwwwwwzzzzzzzzzxxxxxxv.<br></br>


            </div>,

            <div>

.???????????????>>>>>>>>????}}vv}v?vv???>>>>>>>>>>>>??????????????????????`<br></br>
`vv????????v??????????v}}zxxxxxxxxxzxzrzv?????????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v???????????????????v}xxxxxxxxxxxxxzwwzww?v????????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `v???????????????????vwzxxxxxxxxxxxxxzrwzzrvv??????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`???v???????????????v}wzxxxxxxxxxxxxxxxxxw}?v?????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}v??????????????????vwwwrrrrwwwrzz}rrrvrvvv?????vvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uuwv??v?v???????????v}vv???v?v?vr????????????vvvvvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uyyxwvv??v??????????vvvv?vr}}v}}}}rvr}r?????vvvvvvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uuuyyuzwv??v?????vv>?vvvvvzzxxxxxxxxxxz?????vvvvvvvvvvvvvvv??vvvvvvvvvvvv~<br></br>
-uuuuuyyyzvv??vvvvv?>v???vvzwrrwzxxxxxw?>??vvvvvvvvvvvvvvvv}v}wvvvvvvvvvvv~<br></br>
`wzxxuuuuuuxwvvv??>>rr??>>}xz?v?>?vrxw??>vvvvvvvvvvvvvvvvvv}zxr?vvvvvvvvv}~<br></br>
`vvvv}rwzxxxuzzv>>>?zr?vv?rxxzz}v}}rzrv??vvvvvvvvvvvvvvvvzwzxzxrvvvvvvvvv}~<br></br>
`vvvvvvvvvvzuxy????urvv??r}xxxxxxxxxxr??vvvvvvvvvvvvvvvvvxxxxxxz?}vvvvvvv}~<br></br>
`vvvvvvvvv}xyvv????vwrrv}zwrzxxxz}vr}?>vvvvvvvvvvvvvvvvvvxzxxxxvvv}}}rwwzz|<br></br>
`vvvvvvvwxwxy?>????>wyrxxzrr}}wzxz}?????vvvvvvvvvvvvvvvvvrxxxwv?}rzxuuyyyy|<br></br>
`vvvvvrxxw?xv>??????>vwuyzruzvvvrw}??v?}vvvvvvvvvvvvvvvvvrxxw?vwzxyyyuuuyy|<br></br>
`vvvvvrwwv?v?????????>??vv}xurzxxv?vvv}rvxuwr}}vwwwrrwr}vzzxr?uyyyyyuuuuyy|<br></br>
`vvvvvv????>?????????????>>?rwuuz?vvv}rv}xyzuuxwyyywuuuzwxxxr}yyyyyyuyyyuy^<br></br>
`vvvvvvvvv??????????????>>>+^>vvv++^vvvvwwxzuuzzyyuwuuwwxxxx}wyuuuuuuuxxzz|<br></br>
`vvvvvvvvv>??????????>>+>>>>>>+^^^|+vvvvvvvrzzwxuuzwxwzxxxxxvwzwwwwwwrrrww~<br></br>
`vvvvvvvv?>????????>>>??>>>>>>??>>>>wxzr}}vvvvvvvvv}}zxxxxxzv}}rrrrrrrwwww|<br></br>
`vvvvvvvv?????????????>>>>?????????xyyyuxuyuvv}vvv}vvzxxxxxzvrrrrrrrrwwwwz|<br></br>
`vvvvvvv}????????????>>??????????vuyyyuwuyyzwzwwrrrvvwxxxxxwvrrrrrrwwwwzzz|<br></br>
`vv}}}}}}???????????????????????vuyyuzrxyyxvxxxxxxxxxxxxxxzvvrrrrrwwwzzzzx|<br></br>
`v}}}}}}}???????????????????????uyyur}xyyyvvzxxxxxxxxxxxxzv?rrrwwwwwzzzzzx|<br></br>
`}}rrr}}rv>?????????>>>+++++++>xyyzvrxyuu}??vvvv}}rzzzzzrv?rwwwwwwzzzzxxxx|<br></br>
`}rrrrrrr}>???????>>>>++^++^^^vuuxwwzxxzr???????????vvv?>vrwwwwzzzzzzxxxuu~<br></br>
`rrrrrrrrw????????????>+^^+++^?vvv}rwww}vvvvvvv???vvvvvv}wwwwzzzzzxxxxxuur`<br></br>
`rwwwwwwww}>??????????>>+>>>>>>>+|>vr}}rwrrrwrrrrrrwwwwwwwwzzzzzxxxxxuuuuv.<br></br>
`wzzwwwwwwwv????????????????????^^+^vrrrrrrrrrrwwwwwwwwwzzzzzxxxxxxuuuuuu> <br></br>
`zzzzzzwwwww??????????????????>++++^^rwwwwwwwwwwwwwzzzzzzzzxxxxxuuuuuuuyw|.<br></br>
`zzzzzzzzzzz?>??????>>>>>>>>>++>>^||>rrrrrrrrrrwwwwwwwwwwwzzzzzzzzzxxxxuv-.<br></br>


            </div>,

            <div>

.???????????????>>>>>>>>????vv?v}v?vv???>>?>>>>>>>>>??????????????????????`<br></br>
`vvvvvv????vv?????????v}}zzzzxxxxxxzxzrz}v?v??????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`v???????????????????v}zzzzzzxxxxxxxzwwzzw}}vvv?????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
`????????????????????vrzzzzxxxxxxxxxxzwzxzr}}}v????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`???v???????????????vvrzzzzxxxxxxxxxxxxxxzrv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`rvv?????????????????vwrw}rrwzwwwzzrwrwrw}}}v?vv???vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-yux}??v?v???????????vvvv??vv?v?vr?v????????v?vvvvvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uyyuz}v??vvv????????vvvv?v}vvvv}vvrv}v}????v???vvvvvvvvvvvvv?vvvvvvvvvvvv-<br></br>
-uuuyyuxw}v?vv????????vvvvvwwwzzxzxxzzxzv?vvvv??vvvvvvvvvvvv??vvvvvvvvvvvv~<br></br>
`uuuuuuyyurvv?vvvvv??v??vvvr}}rwzzxxxxzv?vvv???vvvvvvvvvvv}}v}rvvvvvvvvvvv~<br></br>
`wzzxxuuuuuuzrvvv??>}}??>>vzr?v?>?v}zr???vv??vvvvvvvvvvvv}r}zxrvvvvvvvvvv}~<br></br>
`vvvvv}rwzxxuxzv?>>?r}vv}?}wwzwrvr}}wrvvvvvvvvvvvvvvvv}r}zzzzzzrv}vvvvvvv}~<br></br>
`vvvvvvvvvvzuxu????zvv}vvr}wwzzxxxxxxwvv}vvvvvvvvvvvvv}r}xxzzzzwv}}vvvvvv}~<br></br>
`vvvvvvvvvrxurv????v}v}v}zwwzxzxzrrw}v?vvvvvvvvvvvvvvv}}}zzzzxxvv}}}rwzzzz|<br></br>
`vvvvvvvwxwxuv?????>vwvwxzwwrrwzzzrvvv??vvvvvvvvvvvvvvv}}wzwxzvv}wxuuyyyyy^<br></br>
`vvvvvrzxzvx}?????????vrzw}xz}}rwwrvvv?}vvvvvvvvvvvvv}}rrrzxzv}zzuyyyuuuuy|<br></br>
`vvvvvwwz}v}?????????>?????}w}zxxrvvv?}}vxuwwrwrwwzwwzww}zzxwvuyyuuyyuuuuy|<br></br>
`vvvvvv???????????????????>?vvwzzv}vvvvv}zuwxuxxuuyzuuuzzxzxwryyyuuuuuuuuu|<br></br>
`vvvvvvvvv???????????????>>+++???>>^???vrwzwxuxxuuuzxuzzxzzxrzuuxxxxxxxzzz|<br></br>
`vvvvvvvvv???????????>>>>>>>>>+^^^++??vvv}vrwzwxxxzwxwzxxxzz}rwrrrrrrrrrww~<br></br>
`vvvvvvvvv??????????>????>>>>>??>>>>vwzwr}}}vvv}}}}}}zxxxxzzv}}rrrrrrwwwwz|<br></br>
`vvvvvvvv?????????????>>>??????????rxuyuuuyu}}}vvv}vvzxxxxxwvrrrrrrrwwwwzz|<br></br>
`vv}}}}}}????????????>????????????wuyyuwuyyzzzzwrrrvvwxxxxxw}rrrrrrwwwzzzz|<br></br>
`v}}}}}}}????????????????????????wuyyzrxyyxvxxxxxxxxxxxxxxzv}rrrwwwwzzzzzx|<br></br>
`}}}}}}}}v??????????????????????wuyur}xyyuvvzxxxxxxxxxxxxzvvrrwwwwwzzzzxxx~<br></br>
`}}rrrrrrv???????????>>>++++++>ruyx}rxuux}??vvvv}}rzzzzzrvvrwwwwwzzzzxxxxx~<br></br>
`rrrrrrrr}????????>>>>>>+++^^^?rzzwrzxxz?>??????????vvv?>vwwwwzzzzzxxxxxur`<br></br>
`rwwwwrrrwv????????????>>+++++??????rww}v}vvvvvvvvvvvvvv}wwwzzzzxxxxxxuux>.<br></br>
`wzwwwwwwwr???????????>>>>>>>>>>>++?vv}rrrrrrrrrwwwwwwwwwwzzzzxxxxxxuuuuz^.<br></br>
`wzzzzwwwwzv????????????????????++^^?vvrrrrrrwwwwwwwwwzzzzzzxxxxxxuuuuuu}~.<br></br>
`zzzzzzzzzzw??????????????????>++++^|v}rwwwwwwwwwzzzzzzzxxxxxxuuuuuuuuuu?~.<br></br>
`zxxxxzzzzzzv?????????>>>>>>>>+>>+^|+vrrrrrrrrrwwwwwzzzzzzzzzzzzxxxxxxxz|~.<br></br>


            </div>,

            <div>

.???????????????>>>>>>??>?>>?>>???????v??>?>?>>>>>>>??????????????????????`<br></br>
`vvvvvvv????????????????????}wwxxxzxxzzxz}rvv?????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`????????????????????????vrwzxxxxxxxxxxxxzzwr}v?????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `????????????????????????vwxxxxxxxxxxxxxzrrwzzv????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`????????????????????????vrxxxxxxxxxxxxxxzwzzz}vv??vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`r}vv????????????????????vwzxzzzxxxxxxxxxzxxw}}v???vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-yyuz}??vvv???????????????vrwvvv}}vr}}}}v}vvvvv???vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uyyyuz}v??vv????????????vvv}???vv?v?v??????????vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuyyyxw}v?????????????vvv}vvzzzzzzzzzzzxv????vvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`uuuuuuuyyu}vv????v????vwv???v}wwwzxxxxxxxr?>>?vvvvvvvvvv}zvvvvvvvvvvvvvvv~<br></br>
`rwwzxxuuuuuxz}vvv?>??>?wvv???wxv???vwzxz?>>?vvvvvvvvvv}rzz}vvvvvvvvvvvvv}~<br></br>
`vvvvvv}rwzxxzuz}?>???}}wvvvvvzzzzr?vv}zw???vvvvvvvvvvwzzzzx}vvvvvvvvv}}v}~<br></br>
`vvvvvvvvvvrwuv?>>????zw?z}?vrwxzzxxxxxxwv?vvvvvvvvvvvzxxxxzvvvvvvvvv}}}v}~<br></br>
`vvvvvvvvv}yxx?>???????rxzw}zzrzxxxw}rw}v?vvvvvvvvvvvvrzxxzvv}vvv}}rwzzzxz|<br></br>
`vvvvvvv}wwywxv+????????wxwxuxrv}wzxwv????vvvvvvvvvvvvvwxz??}vvvrxuuyyyyyy^<br></br>
`vvvvvvrxzwyrzv>?????????vv}zwxuwv}ww}?}?}vr}vvvvvvvvr}zxw?rr}rxuyyyuuuuuy|<br></br>
`vvvvvzxxrvyzv??????????>>>>?vxyxrzwv?v?}w}rxzxzrwzzzyzzxwvxzzxyyuuyyuuuuy|<br></br>
`vvvvv}v}v?wx??????????????>>+?}wrzrvv?vrrv}zzyyzuuuzuwxxzvxzzuyyuuuuuuuuu|<br></br>
`vvvvvvvv??vv?????????????>>>+^+??v?+^?vvvv}rzyyxxuxwzzxxxvwzzzxxxxxzzzwwz~<br></br>
`vvvvvvvvvvv???????????>>>>>>>>>>>>>^+vvvvvvv}zzr}}}}wxxxx}vw}}rrrrrrrwwww~<br></br>
`vvvvvvvvvv????????????????>>>>?????>?wuz}}w}}vvvv}}rxxxxxw?r}}rrrrrwwwwzz|<br></br>
`vvv}vvvvvv????????????>>??????????>vxyyuuyyr}}vvv}v}zxxxxzvrrrrrrrwwwwzzz|<br></br>
`vv}}}}}}vv????????????????????????ryyuzxyyzzxzwwrrvvrzxxxxvrrrrrrwwwzzzzx|<br></br>
`v}}}}}}}}}???????????????????????wyyzrxyyrvxxxxxxxxxxxxxxrvrrrwwwwwzzzzxz-<br></br>
`}}rrrr}}}}??????????????>>>>????zyuw}uyyw?vzzxxxxxxxxxxxw?rwwwwwwzzzzxxx}`<br></br>
`}rrrrrrr}rv>?????????>>+++++++>wuuwrxuur?v?vvvv}}rzzzzz}?}wwwwzzzzzxxxxx?.<br></br>
`rwwwrrrrrrr??????????>>?>+^++^>}}vvwzzr^|??????????vvvv?}wwwzzzzzxxxxxur|.<br></br>
`wwwwwwwwrrrv>???????????>++++>+++^^}rr}rr}r}}}vvvv}}rrrwwwzzzzxxxxxuuuuv-.<br></br>
`wzzzwwwwwwww?????????????????????+^>vrrrrrwwwwwwwwwwwwzzzzzzxxxxxuuuuux>-.<br></br>
`zzzzzzzzwwww}>??????????????????>^^+^?wwwwwwwwwwwwwwzzzzzxxxxxuuuuuuuuw~|.<br></br>
`zxxxzzzzzzzzr>????????>>>>>>>>>>+++^^?wwwwwwwwzzzzzzzzxxxxxuuuuuuuuuuyv~+.<br></br>
`xxxxxxxxzzzzw????????????>>>>>>>>>^|+}rrrrwwrrwwwwwzzzzzzzxxxxxxxxxxxx>-|.<br></br>


            </div>,

            <div>

.???????????????>>>>>?????>>?>>???????v??>?>?>>>>>>>??????????????????????`<br></br>
`vvvvvvv????????????????????}wwxxxzxxzzxzrrvv?????????vvvvvvvvvvvvvvvvvvvv-<br></br>
`????????????????????????v}wzxxxxxxxxxxxxzzwr}v?????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
`??vv????????????????????vwzzxxxxxxxxxxxzwrwzz}v???vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`????vvvvv???????????????vrzzxxxxxxxxxxxxzwzzzr}vv?vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`r}vv??vvvv??????????????vrzzzzzxxzxxxzxxzxzw}rv???vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-yyuxwv??vvvv?????????????v}r}vv}}}r}}v}vvvvvv????vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uyyyyxrv???vv???????????vvvvv??vv?v?v???????????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuuyyuzrvv?????????v??vvvvvvwzzzzzzzzzzx}????vvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`uuuuuuuyyyzvv????v????vwv???vvwwzzzzzxxxxw?>>??vvvvvvv}v}zvvvvvvvvvvvvvvv~<br></br>
`}rwzzxxuuuuuxwvvv?>??>?rvvv??rzvv?vv}wzzv?+?vvvvvvvvvv}rzz}vvv}}}vvvvvvv}~<br></br>
`vvvvvvv}rwzxxuzr?????vvwvvvvvzzzzrvvvrzwv??vvvvvvvvvvwzzzzz}}}rrrvvvv}}v}~<br></br>
`vvvvvvvvvvrwu}??>????rrvz}?vrwxzzxxxxxxwv?vvvvvvvvvvvzzzzzzvrrrrr}vv}}}v}~<br></br>
`vvvvvvvvv}uzx?????????vzzwrzzwzzzzwrww}v>?vvvvvvvvvvvrzzzzvvrrrrrwwwzxxxx|<br></br>
`vvvvvvv}wwurzv>????????rzzxuxwrrwzzw}v????vvvvvvvvvvvvwxz}vrrrrwxuuuyyyyy^<br></br>
`vvvvvvrxzwu}zv>???????????v}rzuzrwww}v}v}vr}vvvvvvvvrrzzwvwwwzxuuuuuuuuuy|<br></br>
`vvvvvwxxw}uw}???????????>>>>?wzxwzwvvv?}r}rxzxzrwzzzyzzxz}xzzxuuuuuyyyyuy|<br></br>
`vvvvv}}}v?wx??????????????>>>?wzzzrvv??rrv}wzyyxuuuzuzxxz}xzzxuuuuuuuuuxx|<br></br>
`vvvvvvvvvvvv?????????????>???>??vv??+?vvvv}}zuuxxuxwzzxxx}wwzzzzzzzzwwwww~<br></br>
`vvvvvvvvvvv???????????>>>??????>>????}}}}}}v}zzwwwrrwxxxx}vw}}rrrrrrrwwwz|<br></br>
`vvvvvvvvvvv??????????????????????????wuzr}wrrrrrrwrwxxxzzwvr}rrrrrrwwwzzz|<br></br>
`vv}}v}}vv}????????????>????????????vxyyuuuuwrrrrrr}wzxzzzzvrrrrrrrwwwzzzx|<br></br>
`v}}}}}}}v}???????????>>???????????ruyuzxyyzzxzzzwwrrwzzzxxvrrrrrwwwzzzzxx|<br></br>
`}}rr}}}}}}???????????????????????wuuxwxyyw}xxxxxxxzzzzxxxwvrrrwwwwzzzzxx}`<br></br>
`}}rrrrr}}}??????????????????????wuxwrxuyzv}zzxxxxzzzxxxxzvrwwwwwzzzzxxxx>.<br></br>
`}rrrrrrrrrv??????????>>>+++++>>rzw}}zxzr?vvvvv}}}wzzxxzrvrwwwzzzzzxxxxuw|.<br></br>
`wwwwwwwrrrr??????????>>?>+^^^^+v???vzzr+^?v????vvvvv}}}v}wwwzzzxxxxxxxx>`.<br></br>
`wzzwwwwwwrwv????????????>++++++++^^vrr}rrrr}}}}}}}}rrrwwzzzzzzxxxuuuuuz~-.<br></br>
`zzzzzwwwwwwr?????????????????????+^+?rrrrrwwwwwwwwwwwwzzzzzxxxxxuuuuuu}`|.<br></br>
`zxxzzzzzzwww}???????????????????>^^+^?wwwwwwwwwwwwwzzzzzzxxxxuuuuuuuuu?~^.<br></br>
`zxxxxxzzzzzzw?????????>>>>>>>>>>+++^^?wwwwzzzzzzzzzzzzxxxxuuuuuuuuuuyx||^.<br></br>
`xxxxxxxxxxzzw????????????>>>>>>>>>^|+}rrwwwwwwwwwwzzzzzzzxxxxxxxxxxxuv-~~.<br></br>


            </div>,

            <div>

.???????????????>>>>>>??????>>>>>??????????>??>???????????????????????????`<br></br>
`vvvvvv???????????????????????v}rzzzzzzzzwwvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`??vv??????????????????????vvrwzzxxxxxxxxxxzzrw}????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  `??vvvvv????????????????????vwzxxxxxxxxxxxzwwzxv???vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`????vvvvv??????????????????rzzxxxxxxxxxxxzzwzzw}v?vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`}}}vv??vvv????????????????v}wwwzwzwzzzwzzzzwrw}vv??vvvvvvvvvvvvvvvvvvvvvv-<br></br>
-uyyuxwv??vvv???????????????}}vvvvvvvvvvvvvv???????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  -uuuyyyxrv???v?????????????vvvvvv?vvvvv?vvvvv????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`uuuuuuyyxzrv????????????????vvv}wxxzzzzzzzzw?>?vvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`uuuuuuuuyyyzvv?????????vv??????}wwr}r}rwzzw>>>?vvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`}rrwzzxxuuuuuxwvvv??????}rr}??vvzrr?v?vrrv??vvvvvvvvvvvvvvvvv}vrrvvvv}vv}~<br></br>
`vvvvvvvv}rwzxxxxz}vv?>?vrw}}vv}wxxxzzxzzzwrvvvvvvvvvvvvvvvv}rrwzrv}vv}}}}~<br></br>
`vvvvvvvvvvvvvvvvvvv??>?vv}wr}r}wzxxxxxxxzrv?vvvvvvvvvvvvv}rrwwzzwr}}}}}}}~<br></br>
`vvvvvvvvvvvvvvvvv???????vvvvzxzwwwzzzwwwv??vvvvvvvvvvvvv}zwzzzwwrzrwzzzxx|<br></br>
`vvvvvvvvvvvvvvv??????????????rxxzzzwwzwv??vvvvvvvvvvvvvv}zzzxzwzzxwzuuuyy|<br></br>
`vvvvvvvvvvvvvvv???????????v???}xuuxzw}?}}v}rw}}vvvvvvv}}rzxzxxxxzwwxuxuuy^<br></br>
`vvvvvvvvvvvvvv??>>>>>??vvvvvvv}zxzrrr}vv}v}}zzxzwrrwwwzwzzzzxzzwrwuuuuuuy|<br></br>
`vvvvvvvvvvvvvv??>>>?vvvvvv?v}zzxxxrrr}v}}?vrwxxuuxxxzzxxzzzzwwwwxuuuuxxzz|<br></br>
`vvvvvvvvvvvvv???>>vvvvvvv?vzuuuxxzzxxzvzwwzwwzzxxxzzxxxxxxwrwzxxxxzwrwwww~<br></br>
`vvvvvvvvvvvvv???>vv?vv??vruuuuxzzxuuxzwzzwzwwwzzxxxxxxzzz}v}rrrrrr}}wwwwz|<br></br>
`vv}}}}vvvvvv?????v??v??vzuuuuxzuuuuzrwxxzwzzxxxzzzzwwwwrvv}}}}}}rrwwwwzzz|<br></br>
`v}}}}}}}vvvvv??????v???}uuuxzzxxuz}vwwwwwwwwwwwwrrrrrr}v}rrrrrrrwwwwzzzxz-<br></br>
`}}rr}}}}}vv}v???????>>>vwww}zzzz}??vrrrrrrrrrrrrrwwzwr}rrrrrrrrwwwzzzzxzv`<br></br>
`}rrrrrr}}}}}v???????>>>v}}}rzzwv?vvrrrrrrrr}rwzzzzzr}rrrrrrrrwwwwzzzzxx}^.<br></br>
`rrrrrrrrr}}rv??????????????v}rv???????}wzwwzzzzxzw}}rrrrwwwwwwzzzzzxxxx?-.<br></br>
`wwwwrrrrrrrr}???????????>>+++>>>+^+++>vv}wzxxwr}v}rwwwwwwwwwwzzzxxxxxu}|`.<br></br>
`wzwwwwwwrrrrrvv???????>>>>++^^^^^+^^?}r}vvvv}vv}}wwwwwwwzzzzzzxxxxuuuu?`~.<br></br>
`zzzzzwwwwwwrw}v??????????>>+++++++^^?wrrrrr}}rrwwwwwwzzzzzzzxxxxuuuuux^-^.<br></br>
`zxxzzzzzwwwwwwvv?????????>>>>>>??>^^^?}rrrwwwwwwwwwzzzzzzxxxxxuuuuuuur-^|.<br></br>
                  `xxxxxzzzzzzzwzv??????????????????+|^+|vwwwwwwwwzzzzzzzzxxxxuuuuuuuuuu?~^~.<br></br>
`xxuxxxxxzzzzzwv?????????>>>>>>>>>++^^^}zwzzzzzzzzzzxxxxxuuuuuuuuuuuyz+~|~.<br></br>
-xuuuuuxxxxxxzz}???????????>>>>>>>>+^|?rwwwwwwwwwzzzzzzzxxxxxxxxxxxxx}~~||.<br></br>


            </div>,

            <div>

.???????????????>>>>>>???????>>>>>???>??>???>?????????????????????????????`<br></br>
`vvvvvv?????????????????????????vvrzxwrzwrzrv}v?vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`??vvv???????????????????????v}rwxzzxxxxxxxxwzrrvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`??vvvvv?????????????????????vwxxxxxzzzxxxzzrwz}vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`?????vv?????????????????????}zxxxxxzzzxxxzzrwzwvvvvv???vvvvvvvvvvvvvvvvvv-<br></br>
`vvvvv??vv??????????????????v}wwwwwrrwwrzrwwwwrvvvvvvv??vvvvvvvvvvvvvvvvvv-<br></br>
-uyuuuz}v?vvv???????????????vvv?v????v??vv????v?>?vvvv?vvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuyyuw}v??????????????????vvvv?vvvvvv?vv??????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`uuuuuuyyuzw}v?????????????>?vvvvrxzzzzwzwzzw?>?vvvvvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`xuuuuuuuuyyurvv????????????????vwxwv}vvvrzzv>>vvv}vvvvvvvvvvvvvvvvvvvvvvv~<br></br>
`}}rwwzxxuuuyuxzrvv?????vvvxwvvv?zxzv}?v}v}r??vvvv}vvvvvvvvvvvvvvvvvvv}}v}~<br></br>
`vvvvvvvvv}rwzxxxzrvvvvvvrvwr?v}}zxxzzxzzzxzv?vvvvvvvvvvvvvvvvvvvvv}vv}}v}~<br></br>
`vvvvvvvvvvvvvvvvvvvv????vvvwz}}rrxxzzzwwzwv?v}vvvvvvvvvvvvvvvv}}wwr}}}}}}~<br></br>
`vvvvvvvvvvvvvvvvvv??????????wxuxrvrzzzzw}?>v}}}}}}}vvvvvvvv}wwrrvwwwzwzxx|<br></br>
`vvvvvvvvvvvvvvvvv????????>?>+?xxxxw}vwzwv??vvvv}}}}vvvvvvvwxxwwwzxxxzwxyy|<br></br>
`vvvvvvvvvvvv}}}}????????vwzz}??rzuyxrvvrr}vvrv}vvvvvvvvvvrxxxxxxxxxxzwuyy^<br></br>
`vvvvvvvvvvvv}}}v>????vwuyyyyyyuzxxr}rrvv}?rv}rxz}}}}vwwrwxxxxxxxxxxxxzuuu|<br></br>
`vvvvvvvvvvvv}}}?>>?vzuyyuxzxxxwzzz}rr}}}v>}rv}zzwwwrrwzzxxxxxxxxxxxxxrzzz|<br></br>
`vvvvvvvvvvvv}}v?>?xxzuxxzwzxxxxzzzzzxzzxzzzwwwwwwwwwwwwzxxxxxxxxxzzw}rwww|<br></br>
`vvvvvvvvvvvv}}v?>xxrzxvwzxxxxxxzzzzzzzzzzzzwwwwwwwwwwwrrrrr}vvvv}vv}wzzwz|<br></br>
`vv}}}}}vvvvvv}??rzvwuvwzzzzzxzzzzzzzxxxzzzzwwwwrrr}vvv????vvvvvv}rwzzzzzx|<br></br>
`v}}}}}}}vvvv}}???}vx}vwwwwwzzwwrrr}}}}vv}rvvvv??????????v}rrrrrwwwwzzzzxr`<br></br>
`}}rrrr}}}}vv}}??>>vzv???vvvvvvv????????vv????????vvv}}}rrrrrrrwwwwwzzzxr+.<br></br>
`}rrrrrrr}}}}}}???>vrv??????vvv??????vvv????>?v}}}}rr}}}rrrrrwwwwzzzzxxz>|.<br></br>
`rwwwrrrrrrr}}}v?????vvv???????????>>>>>?vv}}}}}}}}r}rrrrrrrwwwzzzzxxxxr~-.<br></br>
`wzwwwwrrrrrrrr}???????????>++++++++^^^??vvrwwvv?v}rrrrrrrrrwwzzzxxxxxx>`-.<br></br>
`zzzzwwwwwrrrrrr?????????>>>>+>>>>++++vr}v??vvvv}}rrrrrrwwrwzzxxxxxuuuw`~^.<br></br>
`zzzzzzzwwwwwwwwv???????????>>>>>>++++rwrrr}}}r}rwwwwwwwwwwwxxxxuuuuuuv`^|.<br></br>
`zxxxxzzzzzwwwwww??????????????????>^^?}rrrwwwwwwwwwwwwwwwwzxxuuuuuuuu+|+-.<br></br>
                  `xxxxxxxzzzzzzzwv??????????????????+++^?wrwwwwwwwwwwwzzzzzzzxuuuuuuuux|^^-.<br></br>
`xuuuxxxxxxxzzzw??????????????????>+^+^vzwwwwwzzzzzzzzzzzzzxuuuuuuuuyv-^|~.<br></br>
-uuuuuuuuxxxxxxxv????????????>>>>>>>^|+rwwwwwwwwwwwwwzzzzzzzzxxxxxxxx+-||^.<br></br>


            </div>,

            <div>

.????????????????????????????????????>>+?v}}}}}}}}vvv??>??????????????????`<br></br>
`vvvvvvv???????????????????????????????}zxxxxxxxxxzzzz}?vvvvvvvvvvvvvvvvvv-<br></br>
                  `v?vv????????????????????????????????vwxxxxxxxxzzzzzzzrv?vvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv?????????????????????????????}zxxxwww}vvv?v??vv??vvvvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvv??v???????????????????????????rxzw?????vvvwvv????vvvvvvvvvvvvvvvvv-<br></br>
`vvvvvv??vv???????????????????????????}rr}vvwzxxxxxxxv+?vvvvvvvvvvvvvvvvvv-<br></br>
-uuuuuxz}v?vvv???????????????????????>?vvvvvxz}v??vrz??vvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuyyyuw}v??vv????????????????????????>+>}zxwv?vr}zv?vvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuuuyyuzrvvv?????????????v}rrwzzw}>vv??rxxxxxxxxx}?vvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuuuuuuyyurv?????????????zxxxxxxzwv?vvvrzxxxxxzz}??vvvvvvvvvvvvvvvvvv}~<br></br>
`}}}}rwzzxxuuyuxz}vvvvv?}wrr}zxzzxxxv}w}wv?z}wzxxxxz??vvvvvvvvvvvvvvvvv}}}~<br></br>
`vvvvvvvvvv}rwzxxzzrvv?rxz}wrzzzzxxz?vv?vvrwr}}v}rw}?vvvvvvvvvvvvvvvv}}}}}~<br></br>
`vvvvvvvvvvvvvvvvvzz}wwxxw}rwzxzwwxz??}?>>vrxux}v??vv?vvvvvvvvvvvvv}}}}r}}~<br></br>
`vvvvvvvvvvvvvvvvzx}rwzzwwwxuuyyur}v?vzvv>+?wxzxuzrzv?vvvvvvvvv}rwzxxxuuxw~<br></br>
`vvvvvvvvvvvv}}}xxzwz}zrwzxuyyuxzzr??vzzww?>??vrxzwx}?vvvvvv}}rxuyyyyyuuux|<br></br>
`vvvvvvvvvvv}xwzzxxxwzwzxuyyuxzzwzx}r?}zrzx???>>??vv??vvvvv}zuuuuyyyyyuyuy^<br></br>
`vvvvvvvvvvv}xwwzxxzrvvuyyuxzwwzzxurv??rwrzx????>>>>>>vvrzwwuuxzxxxuyuuuxu|<br></br>
`vvvvvvvvvvvvxxwzxxxrv?uyxzzzzzxuxz}v??vzvvyv????????>>vwwuyuzxuuxzzzwzzwz|<br></br>
`v}}vvvvvvvvvzxwzxxxwvv}xzwzzxuxzzrvwrv}zv?x??????????>>vrwwrzzzzuuyuzrwzz|<br></br>
`v}}}}vvvvvvvzxwzxxxw?}?vwzxuxzzz}???wzzw?vx???????????>?}}vvvvvzuuxzxzrzz|<br></br>
`}}}}}}}vvvvvzxwrvvvv??}??}wzzrv?????wxz??u}>???????????>?rrrrr}}}wzxwrvrw-<br></br>
`}rrr}}}}}}}vzzrvvv?????v???xuv?????}w}??zu?>????????????>vrrrrwwv}}rvvwv>.<br></br>
`rrrrr}}}}}}}}}vv???v???????wx}?????v??}zw?+>?????????????>rrrwwwwvvvww}+|.<br></br>
`rrrrrrrr}}}}}}}}}vv????????wxr??????vrr?++>>?????????????>?wwwwzzwzxxx>~-.<br></br>
`wwwwwrrrrrrr}}}}}}}}vvvv}}}zxv}rvvvvv>^^+>???????????????+?zwzzzzxxxxw|`-.<br></br>
`zzzwwwwrrrrrrrrrrrrrrrrrrrrr}}}}}rr>^+>>????????????????>^>zzzzxxxuux>`~^`<br></br>
`zxzzzzwwwwwrrrrrrrrrrrrrrrrrrrrrrrr}>???????????????????>^>xxxxxuuuuz|~|^`<br></br>
`zxxxxzzzzzwwwwwwwwwwwwrwwwwwrrrrrrr}>>?????????????????>+^>xxxuuuuuu}-^~~.<br></br>
`xuxxxxxzzzzzzzwwwwwwwwwwwwwwwwwwwrwv>>>????????>>>?????++^>zzxuuxxxxv|^~-.<br></br>
-xuuuuxxxxxxzzzzzzzzzzzzzzzzwwwwwwwww????????????>>>>>?>++^>zzzzzzzzzzr?~|.<br></br>
-uuuuuuuxxxxxxxxxzzzzzzzzzzzzzzzzzzzw???????????????>>++++^?}vvvrwrv?^^>++.<br></br>
-uuuuuuuuuuxxxxxxxxxxzzzzzzzzzzzzzzz?>????????????????>>>+|+rxxzrrrr>-|^^^.<br></br>


            </div>,

            <div>

.????????????????????????????????????>>+?v}}}}}}}}vvv??>??????????????????`<br></br>
`vvvvvvv???????????????????????????????}zxxxxxxxxxzzzz}?vvvvvvvvvvvvvvvvvv-<br></br>
                  `vvvv????????????????????????????????vwxxxxxxxxzzzzzzzrv?vvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv?????????????????????????????}zxxxwww}vvv?v??vv??vvvvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvv??????????????????????????????rxzw?????vvvwvv????vvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv?????v????????????????????????}rr}vvwzxxxxxxxv+?vvvvvvvvvvvvvvvvvv-<br></br>
-uuuuuxzrv??vv???????????????????????>?vvvvvxz}v??vrz??vvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuyyyyxrv???v????????????????????????>+>}zxwv?vr}zv?vvvvvvvvvvvvvvvvvvv-<br></br>
-uuuuuuuuyyuwrvv?????????????v}rrwzww}>vv??rxxxxxxxxx}?vvvvvvvvvvvvvvvvvvv~<br></br>
-uuuuuuuuuuyyyzvv?vvvvvvv????zxxxxxxzwv?vvvrzxxxxxzz}??vvvvvvvvvvvvvvvvvv}~<br></br>
`}}}}rrwzzxuuuuuxwvvvvv?}wrr}zxzxxxxv}w}wv?z}wzxxxxz??vvvvvvvvvvvvvvv}}}}}~<br></br>
`vvvvvvvvvvv}rwxxxzwvv?rxz}wrzzzxxxz?vv?v}rwr}}v}rw}?vvvvvvvvvvvvvvv}}}}}}~<br></br>
`vvvvvvvvvvvvvvvvvzz}wwxxw}rwzxzwwxz??}?>>vrxux}v??vv?vvvvvvvvvvvv}}}rrr}}~<br></br>
`vvvvvvvvvvvvvvvvzx}rwzzwwwxuuyyur}v?vzvv>+?wxzxuzrzv?vvvvvvvvvrwzxxuuuxzw~<br></br>
`vvvvvvvvvvvv}}}xxzwz}zrwzxuyyuxzzr??vzzww?>??vrxzwx}?vvvvvv}rzuyyyyyuuuuu|<br></br>
`vvvvvvvvvvv}xwzzxxxwzwzxuyyuxzwwzx}r?}zrzx???>>??vv??vvvv}rxuyuuyyyyyyyuy|<br></br>
`vvvvvvvvvvv}xwwzxxzrvvuyyuxzwwzzxurv??rwrzx????>>>>>>vvrzwwuuxzxxxuyuuxxx|<br></br>
`vv}vvvvvvvvvxxwzxxxrv?uuxzzzzzxuxz}v??vzvvyv????????>>vwwuyuzxuuxzzzwzwwz|<br></br>
`}}}}vvvvvvvvzxwzxxxwvv}xzwzzxuxzzr?wrv}zv?x??????????>>vrwwrzzzzuuyuzrwzz|<br></br>
`}}}}}vvvvvvvzxwzxxxw?}?vwzxuxzzz}???wzzw?vx???????????>?}}vvvvvzuuxzxzrzx|<br></br>
`}rr}}}}vvvvvzxwrvvvv??}??}wzzrv?????wxz??u}>???????????>?rrrrr}}}wzxwrvvv-<br></br>
`rrrrr}}}}}}vzzrvvv?????v???xuv?????}w}??zu?>????????????>vrrrrwwv}}rvv}^+`<br></br>
`rwrrrrrr}}}}}}vv???v???????wx}?????v??}zw?+>?????????????>rrrwwwwvvvww>||.<br></br>
`wwwwwrrrrrrr}}}}}vv????????wxr??????vrr?^+>>?????????????>?wwwwzzwzxur--`.<br></br>
`zzzwwwwrrrrrrrr}}}}}vvvv}}}zxv}rvvvvv>^^+>???????????????+?zwzzxxxxxx+--^`<br></br>
`zxzzzwwwwwwrrrrrrrrrrrrrrrrrr}}}}rr>^+>?????????????????>^>zzxxxxuuu}`~^>`<br></br>
`xxxxzzzzzwwwrrrrrrrrrrrrrrrrrrrrrrr}>???????????????????>^>xxxxuuuuy?`^|+`<br></br>
-xuxxxxxzzzzwwwwwwwwwwwwwwrrrrrrrrrr}>>?????????????????>+^>xuuuuuuuu||^~|.<br></br>
-xuuuuxxxxzzzzzzzzzzzwwwwwwwwwwwwwwwv>>>????????>>>?????++^>zxxuuxxxw>+|-~.<br></br>
-uuuuuuuxxxxxzzzzzzzzzzzzzzzzzzzwwwww????????????>>>>>?>++^>zzzzzzzzzzr?||.<br></br>
-uuuuuuuuuxxxxxxxxxxxzzzzzzzzzzzzzzzw???????????????>>++++^?}vvvrwrv?++>++.<br></br>
-uuuuuuuuuuuuuxxxxxxxxxxxxxxzzzzzzzz?>????????????????>>>+|+rxxzrrrv~|^^^^.<br></br>


            </div>,

            <div>

.???????????????????????>>>>>>>>>>>>>>>>>>???vv?vvvv?>>>>?????????????????`<br></br>
`vvvvvvv?????????????????????????????????}zxxxxxxzzzzz}v?vvvvvvvvvvvvvvvvv-<br></br>
`vvvv??????????????????????????????????v}zxxxxxxxxzzxxxwv??vvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvv???????????????????????????????rxxxwwwrrwrwrrwrzrvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv???????????????????????????????vwz}v?v????????vvv??vvvvvvvvvvvvvvv-<br></br>
`}vvvvvv????????????????????????????????vvv??vv}}r}}}??v???vvvvvvvvvvvvvvv-<br></br>
-uuuuxxxzrv??????????????????????????????vvvzzzzxxxxxw>???vvvvvvvvvvvvvvvv-<br></br>
-uuuuuyyyyxrv???????v}wwrv?????????????>v??}z??>?vwxv>>>?vvvvvvvvvvvvvvvvv-<br></br>
-uuuuuuuuuyyxw}vv?v}wzzxxxzrvv????????????>vxzrv}}wx?r?vvvvvvvvvvvvvvvvvvv~<br></br>
-xuuuuuuuuuuyyyzvvrzzxxxxxrr}wrvvvv???????vvzxxxxxxxr}?vvvvvvvvvvvvvvvv}v}~<br></br>
`v}}}}rrwzzxuuuyzrzxxxxxxxzv??vzxxzzvv?????rwzxxxzwwwv?vvvvvvvvvvvvvv}}}}}~<br></br>
`vvvvvvvvvvvv}rwwwxxzxxxxxxxwv?vwrwxxw??>}rrw}rwzxzwv?vvvvvvvvvvvvvv}}}}}}~<br></br>
`vvvvvvvvvvvvvvvvvrzxxxxzzzzw}????}zzzz}vwzwwx}v??v?>??vvvvvvvvvv}}}rrrr}}~<br></br>
`vvvvvvvvvvvvvvv}rwwzzwwxuuuzww}vr}??vrzxxz}ruzxxwwx}vvvvvvvvv}wzxuuuuuzww~<br></br>
`vvvvvvvvvvvvvvwxr}z}rwzuyyuzzzzxz}????vrzxxv??wxzxxr}v?}vv}rwxyyyyyuuuuuy^<br></br>
`vvvvvvvvvvvvvv}xzrx}wzzuyyuzzzwxuzv}zzwr}wxw>???vwzvv?vv}rxuyuuyuuyyyyuuu|<br></br>
`vvvvvvvvvvvvvvvzxxzwzzzuyyuzzzzxuzzvzzvvvvwx????>>>>++vvwwxuwzxzxuxzzxxzz|<br></br>
`v}}}vvvvvvvvvvvzxxzrzzzuyyuzzzzuuzz}ww????vz?????????>>}rxyzzuxzyuzzxzwrz|<br></br>
`}}}}}}vvvvvvvrwwwxxrzzzuyyuzzzzuxzz}zw????v}??????????>>vzxwxxrxuzzuzrwwz|<br></br>
`}r}}}}}}vvvvvxzr}}xz}wzuyyuzzzzuxzr?rv??????>??????????>?}}v}}v}rvwrvrrzx|<br></br>
`}rrr}}}}}}}v}}xzz}ww}wwuyyuzzzzuxwzwv???????????????????>}rrrrwwrr}vwzr?v~<br></br>
`rrrrrrrr}}}}}vzxxxrrzxzzxxxwwwwzwzuzzrv?>?xv>???????????>?rrwwwwzzzwxr++?~<br></br>
`wwwwrrrrr}}}}}xxxxzzzwwrwwzzzzwwwwrwzzxw?ww?>????????????>}wwwzzzzxxz>|~^`<br></br>
`wzwwwwwrrrrrvzxzwwzxxxz}}rrrwwzz}zxr?}zuzv>+>????????????>?zwzzzxxxxr~-`^`<br></br>
`zxzzzwwwwrrrrrzxxzrzxxwwxxxxzzzzrxw?vwzw?^+>?????????????>>zzzxxxxux>`-^?`<br></br>
`xxxxzzzwwwwwwrrrwxxzwzrzxxxxzzzzrxwxz}>^+>>??????????????+>xxxxxuuuw~~|+?`<br></br>
`xuxxxxzzzzzwwwwwrrwzzwwr}rrrrrr}w}r}++>>????????????????>^?xxuuuuuuv-^~^?`<br></br>
-uuuuuxxxxzzzzzwwwwwrwzxvvzzzzzwrwwrr?>?????????????????>+^?xuuuuuuu>|^-~>`<br></br>
-uuuuuuxxxxxxzzzzzzzzzwwrwwwwwwzzzwww?+>?????????>>>???>++|vuuuuuuxw~^~~|^`<br></br>
-uuuuuuuuuxxxxxxxzzzzzzzzzzzzzzzzzzzzv>>??????????>>>>>>++^vzzxzzr}v^v?^+>`<br></br>
`xuuuuuuuuuuuxxxxxxxxxxxxxxxxxxxxxzzz????????????????>>>++^?vvv?vv??}}?^+?`<br></br>
`xuuuuuuuuuuuuuuxxxxxxxxxxxxzzzzzzzz}>????????????>>>>>>>>^^???????}}v^^^+.<br></br>


            </div>,

            <div>

.???????????????????????>>>>>>>>>>>>>>>>>>???vv?vvvv?>>>>?????????????????`<br></br>
`vvvvvvv?????????????????????????????????}zxxxxxxzzzzz}v?vvvvvvvvvvvvvvvvv-<br></br>
`vvvv??????????????????????????????????v}zxxxxxxxxzzxxxwv??vvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvv???????????????????????????????rxxxwwwrrwrwrrwrzrvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvv???????????????????????????????vwz}v?v????????vvv??vvvvvvvvvvvvvvv-<br></br>
`r}vvvvv????????????????????????????????vvv??vv}}r}}}??v???vvvvvvvvvvvvvvv-<br></br>
-uuuxxzxzw}v?????????????????????????????vvvzzzzxxxxxw>???vvvvvvvvvvvvvvvv-<br></br>
-uuuuuuyyyyz}v??????v}wwrvv????????????>v??}z??>?vwxv>>>?vvvvvvvvvvvvvvvvv-<br></br>
-uuuuuuuuuyyyzrvv?v}wzzxxxzrvv????????????>vxzrv}}wx?r?vvvvvvvvvvvvvvvvvvv~<br></br>
`xuuuuuuuuuuuyyu}vrzzxxxxxrr}wrvvvv???????vvzxxxxxxxr}?vvvvvvvvvvvvvvvv}v}~<br></br>
`vv}}}}rrwzzxuuyzrzxxxxxxxzv??vzxxzzvv?????rwzxxxzwwwv?vvvvvvvvvvvvvv}}}}}~<br></br>
`vvvvvvvvvvvvv}rwwxxzxxxxxxxwv?vwrwxxw??>}rrw}rwzxzwv?vvvvvvvvvvvvvv}}}}}}~<br></br>
`vvvvvvvvvvvvvvvvvrzxzxxzzzzw}??v?}zzxz}vwzwwz}v??v?>??vvvvvvvvvv}}}rrr}}}~<br></br>
`vvvvvvvvvvvvvvv}rwwzzwwxuuuzww}vr}??vrxxxz}ruzxxwwxrvvvvvvvv}rzxuuuuuxzwz|<br></br>
`vvvvvvvvvvvvvvwxr}z}rwzuyyuzzzzxz}????vrzxx???wxzxxr}v?}vvrwzuyyyyyuuuuuy^<br></br>
`vvvvvvvvvvvvvv}xzrx}wzzuyyuzzzwxuzv}zzwrrwxw>???vwzvv?v}}wuyyyyyuyyyyyuuu|<br></br>
`vvvvvvvvvvvvvvvzxxzwzzzuyyuzzzzxuzzvzzvvvvwx????>>>>++vvwzxuwzxzxuxzzzzzz|<br></br>
`v}}}vvvvvvvvvvvzxxzrzzzuyyuzzzzuuzz}ww????vz?????????>>}rxyzzuxzyuzzxzwrz|<br></br>
`}}}}}vvvvvvvvrwwwxxrzzzuyyuzzzzuxzz}zw????v}??????????>>vzxwxxrxuzzuzrwwx|<br></br>
`}r}}}}}}}vvvvxzr}}xz}wzuyyuzzzzuxzr?rv??????>??????????>?}}v}}v}rvwrvrrwz|<br></br>
`}rrrr}}}}}}}v}xzz}rw}wwuyyuzzzzuxwzwv???????????????????>}rrrrwwrr}?zw??}~<br></br>
`rrwrrrrr}}}}}vzxxxrrzxzzxxxwwwwzwzuzzrv?>?xv>???????????>?rrwwwwzzzwz>^+}~<br></br>
`wwwwwrrrrr}}}}xxxxzzzwwrwwzzzzwwwwrwzzxw?ww?>????????????>}wwwzzzxxuv~~-?-<br></br>
`wzzwwwwwrrrr}zxzwwzxxxz}}rrrwwzz}zxr?}zuzv>+>????????????>?zzzzxxxxx^``^?`<br></br>
`zxzzzzwwwwrrrrzxxzrzxxwwxxxxzzzzrxw?vwzw?^+>?????????????>>zzxxxxuuv`~+??`<br></br>
`xxxxxzzzwwwwwwrrwxxzwzrzxxxxzzzzrxwxz}>^+>>??????????????+>xxxxxuuu>~^~??`<br></br>
`xuuxxxxzzzzwwwwwrrwzzwwr}rrrrrw}w}r}++>>????????????????>^?xxuuuuux~^|->?`<br></br>
-uuuuuuxxxxzzzzzzzzwrwzxvvzzzzzwrwwrr?>?????????????????>+^?uuuuuuyv~+~-|+`<br></br>
-uuuuuuuxxxxxxzzzzzzzzwwrwwwwwwzzzzww?+>?????????>>>???>++|vuuuuuux+~^-|^>`<br></br>
`xuuuuuuuuuuxxxxxxxzxzzzzzzzzzzzzzzzzv>>??????????>>>>>>++^vzzxzzr}?^v?^>?`<br></br>
 ?yuuuuuuuuuuuxxxxxxxxxxxxxxxxxxxxxzz????????????????>>>++^?vvv?vv?v}}?^+?`<br></br>
 |xyuuuuuuuuuuuuuuuuxxxxxxxxzzzzzzzz}>????????????>>>>>>>>^^???????}}v^^^>`<br></br>


            </div>,

            <div>

.?????????????????v??????????????????????????>?>?????>????????????????????`<br></br>
`vvvvv}}vvvvvvvvv}rr}vvvvvvvvvvvvvvvvvvvvvvrrrzwzwzwwr}vvvvvvvvvvvvvvvvvvv~<br></br>
`vv}}}}}vvvvvvvvvvrr}vvv?vvvvvvvvvvvvvvvvvwxxxxxwwrzxxxz}vvvvvvvvvvvvvvvvv-<br></br>
`v}}}}}}}vvvvvvvvvvwr}vvvvvvvvvvvvvvvvvv?}zxuxxxxxxxxxxxx}?vvvvvvvvvvvvvv}~<br></br>
`zw}}}}}vvvvvvvvvvvwzw}vvvvvvvvvvvvvvvv??wxxzwwrrwrrwwwzz}?vvvvvvvvvvvvvv}~<br></br>
-uxr}}}}vvvvvvvvvvv}zw}vvvvvvvvvvvvvvvv??wxv???v?vv??v?v}vv?vvvvvvvvvvvvv}~<br></br>
-uyuxxxzzzw}vvvvvvvvrwrvvvvvvvvvvvvvvvv?vzrvwzwxzzxzzwv?}v?vvvvvvvvvvvvv}}~<br></br>
-uuuuyyyyyyuz}vvvvvvrzzwwwwzzzwwwrrvvvv}r}vrwzxxxxxxzwvvv>?vvvvvvvvvvvvv}}~<br></br>
-uuuuuuuuuuuuuzr}rwzzxxxxxxzzzzzxxxxxrrw}?+???>}zxwv?v??>?vvvvvvvvvvvvv}}r~<br></br>
`xuuuuuuuuuuuuyuuxxzzxuuuxxxxxxxzzzxuuuzrv??zwv}wx}}vr?>?vvvv}}}}}}vvv}}}r~<br></br>
`rrrrrrrwwzzxuuuuuxxxxuzzxxxxz}}rzxxuuuxzwv?wxxxxvzxxv>?vrrwrr}}vvvv}}}}rr~<br></br>
`rrrr}}}}}rzxxxxxxzwwzxw?}zzz}vrz}}zxzwwzxzrwwxxrvrzrv}zzzwwr}}}vvv}}}rrrw~<br></br>
`wwwwwwrwxuuuxxzwr}}}}v}v?rzzr}w}rzuxxxxxxxzrwvrzwxzwzwwrrrrr}}}}rrrrrrrwz|<br></br>
`rrrrrwzuuuuuxxxxxxxxxzrwwzxxxzzxuuuwvv}}}vw}uzwxxxxzuw}rrrrrwzxuuuuuuzwzu^<br></br>
`rr}}}vzxxxuuuuxxxxxuuuuuuuuuyuuuxxxxwvvvv}wwwwxuxuxwz}}}rwzzuyyyyyyyuuuuy^<br></br>
`rr}}}vwvvv}rwzxxzzuyyyyuyyyyyyyuuxzwzzrwzzwv???v}zzwxw}rzxyyyyuuuuxxxxxxu|<br></br>
`rrr}}}}v?vvvvvrwzuuyyyyyyyyyyyyyuuuzwwwwwwr}vvvv???????wxuuuxuxxuuzxuxrwx|<br></br>
`wwrrrr}v?vvvvv}}wxuuyyyyyyyyyyyyyyuuuxxxxxxuuxzrv???????rwuxxuxuyuzuxwrzu|<br></br>
`wwrrrrr}???vvvv}wxuuyyyyyyyyyyyyyyuuyuuuuuuuuuuwv???????vzuzxuxwzw}wzrzxu|<br></br>
`wwwwrrrrr}}}vvvvwxuuyyyyyyyyyyyyyuuxzzwzwrwzzzzrv????????rrrrwwwwwwzzr}wx|<br></br>
`wzzwwwwrrrrzz}vv}wxuuuyyyyyyyyyyuuxw}vvv}???vvv}}}}}vvv??vwwwzzzzzzxz?>v}~<br></br>
`zzzzwwwwww}zrvv?v}wxuuuuyyyyyyyuuxzrv?vvvv?>???????vvvv}}rzzzzzxxxxz>^^?}-<br></br>
`zzzzzwwwwwwr}vvvvv}wzxuuuuuyyyyuzwrrrrv?vvv>^^~??????????vrxxxxxxxxw^|~>v-<br></br>
`zxxxzzzzzzwwwr}vvvv}rwwzzzzzzzzw}vvvvrzw}v?-.`>????????????xxxxxxxx?~^?vv`<br></br>
`xxxxxxxzzzzzzwwrrvv}}}vv}}}}}v??vvvvv}}wxxv^~>????????????>zxxxxuuw`~^??>.<br></br>
`xuuxxxxxxxxxzzzwr}rvvvv?vvvv>->vvv}vvvrwzr}}v????????????>?uuuuuuuv-^~>?>`<br></br>
-uuuuuxxxxxxxxxxxzzrvvvvv?vvvv??v}}wwwzxw?>???vvv?????????>vuuuuuuu+^^`|>>`<br></br>
-uuuuuuuxxxxxxxxuuxxzwwwrrrrrrrrrwzxxxzv????????vv???????>>wuuuuuyw~^~~|+>`<br></br>
`ruuuuuuuuuuuuxxuuuuuuxxxzzxxxxxxxzwrw}+????????????????>>>xuyuuuy?-||^+??`<br></br>
.^vuuuuuuuuuuuuuuuuuuuxxzwzzzzwwwr}vvwr?>??????????????>>+?yuwvvwz?|^^^+??`<br></br>
.^^zuuuuuuuuuuuuuuuuuuuxxzzzzzzzxxxxuz?vv???????????????>^?vv??rrrr>+^^+>?`<br></br>
.^~?uuuuuuuuuxxuuuuuuuuuuuuuxzzzzzzxx???????????????>>>>>+|>?>?vrw>|^^^^+?`<br></br>


            </div>,

            <div>

.???v}rrrrrrr}}}rr}vvv?????v}rrrrrrrrrrrr}v??>?>?????>????????????????????`<br></br>
`wwzxuyyyyyyuxzwzzwr}}vvvvwxuyyyyyyyyyyyyuxzwrzwzwzwwr}vvvvvvvvvvvvvvvvvvv~<br></br>
-xuuuyyyyuuuuxr}}vvvvvvvvvrxxuyyyyyyyuuuuuuuuuxxwwrzxxxz}?vvv}rr}}vvvvvvvv-<br></br>
-uyyyyyyuuuuuzrvvvv}}rrwwzzxxuuuyuuuuuzzrwxuuuuuxxxxxxxxx}v}rwr}vvvvvvvvv}~<br></br>
-uyyyyyuuxxxuxrrwwzxxxxxxxxxxxxxuuuuuxwv?wxxzzzzxxxxzzzzzwwwrvvvvvvvvvvvv}~<br></br>
-uyyyyuxzwwzxxxxxxxxzzwwwwwzwwwwzzxuuxz}vwzv?vv}wzxxxzwzxz}vvvvvvvvvvvvvv}~<br></br>
-uyyyuuuxzzxxxxxxzwwr}}}vvvrr}}}rzuuzwwwzzrvwzwxzxxuuuuzwv?vvvvvvvvvvvvv}}~<br></br>
-uyuuuuuuuuuuxxzzr}vvvvvvvv}}v}}wxuzr}}rrwzxxxzxxxuuuxzwv??vvvvvvvvvvvv}}}~<br></br>
-uuuuuuuuuuuuuuxzr}}}rrwzzzzzwwwxuxxzrvv}vvwzzrrxuxrv}v}v?vvvvvvvvvvvv}}}r~<br></br>
-uuuuuuuuuuuuuyyyuwwzxuuuuuuuuuuuxxxxxz}vv?vxxxzzxr}vr??vr}}}}}vvvvvv}}}rr~<br></br>
-uxzxxxxuuuuuuuuuuuuxxxzzxxxuxxxxxuuuuuuw}vvwuuuu}zxxrvvrzzwwr}}vvv}}}}rrr~<br></br>
-uxzzzzxuuyyuuuuuuuxxxxw?}zzzzzuuuuuuyuuuxzrwwxxzwwzzwzxuuuuuxwr}}}}}rrrrw~<br></br>
-xxzzzwzuuyyyyyuuuuuxzw}v?rzzzuuuuuuuuyyuuuzrwvrzzxzxuuyyyyyyuxwwwwrwrrwwz|<br></br>
                  -xxzzwwwuuyyyyyyyyyyuuzrrrwzxuuxxuuyuxxuuuxzruzwzzzuuyyyyyyyyyuuuuuuuxwzuy^<br></br>
-xxzwwrzxxuuyyyyyyyyyuuxzzzxuyuuxxxxuzzxuuux}v}zuuuyyyyyyyyyyyyyuyyyyuuuuy^<br></br>
-xxzwwrw}wzuuyyyyyyyyyyuxzxuuyyyuxxw}wwwzxuxwv??vwuyyyyyyyyyyyyyuuuxxxxxxx|<br></br>
-xxzxxzw}rzxuuyyyyyyyyyuuxuuuuyyyuxzv?v}zxxxxwvv?vwxuyyyyyyyyyyyuuuzxxzrwx|<br></br>
-uuxxuuuw}rwzxuuuyyyyyyyuuuuuuuuuuuzwwxxxxuuuuxz}vrzuuuyyyyyyyyyuuuzuxwrzu|<br></br>
-uuxxxxxx}}rwwzxuuuuuuuuuuuuxxxxxuuuzuyuuuuuuuuuzrwzxuuuuuyyyyyyuuz}wzwzxu|<br></br>
-uuuxxxxxxxxxxxxxxxxxxxxuuuyuxxxxxxurv}vwxxuuxxzzzxxxuuuuuuuuuuuuxzzzw?vwz|<br></br>
-uyuuuuuuuuuuuuzwwwxxxzzxuuyyuuxxxzwv??vwxuuuxwv???vv}}rwxuuuuuuuuxxxv?}r}-<br></br>
-uyyuuuxxxxxxzwrv}}zxxxzxxuyyyyuxxzv??vrzxuuuxwv????v???vrxuyyuuuuxxv??vwr-<br></br>
-uuuuuuuxxzzww}}}rrwxxxxxxuuyyyyuuxzwrwzuuyyuxwv???vv???vrzuyyuuuuxz|``>v?`<br></br>
`uuuuuuuxxzzzwwwwwwwwzxxxzxxzzxuuuuuuuuuyyyyuz}vvvvr}???vrzuyyuuuuu?`~+??>.<br></br>
-uuuuuuuuuxxzzzxzwrrzxxxxwzzwrrwuuyyyyyyyyyuxwvv}rwzv???vwxuyyuuuuu|~|+?>+.<br></br>
-uuuuuuuuuuuxxxxzrrwxxxxzrzzzv+vxuyyyyyyyyuxw}v}wzxr???v}zxuyuuuuuw~^~|?>+`<br></br>
-uuuuuuuuuyuuuxxxwwxxxxxw}zzzz}rwxuyyyuuuzwr}v}wxxw???v}wxxuyuuuuy?~^--+>>`<br></br>
`wuyuuuuuuuuuuuuuuuuuuuxw}zzwzzwwzxuxxxzr}vvvrzxxw???vrzzxxuyuuuuu^~~~^+>>`<br></br>
.+>}uuuuuuuuuuuuuuuuuuux}vzxrzxzzzxxzwv??vvrwzxxr??v}wzzzwruuyuuyr~||^+???`<br></br>
.+^^xyuuuuuuuuuuuuuuuyyx}vzxwwxuuuuxzwv?vrzxxxzv?v}rzzzw}?vyuwvvwv++^^+>??`<br></br>
.^^|vyuuuuuuuuuuuuuuuyuuxxxxxxuuyyyuuuwzxxuxz}vvrwzzww}v?+?vv??rrww>^^^+??`<br></br>
.+^|^uyuuuuuuuuuxxuuuyuuuuuuxzxxxxxxxzzzzxrv?v}wwww}vv??>+|>?>?vrw>^|^^+??.<br></br>


            </div>,

            <div>

`v}v?????????????????????????????????v}vvv???>?>v}rrrrrr}}}v??????????????`<br></br>
`uuxw}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvrzxz}vvrrrzwxzxuyyyuuuxzw}vvvvvvvvvvvv~<br></br>
`zxxzw}}vvvvvvvv??????vvvvv?vvvvvvv}zxxwvvwxxxxxwwwxuuuuuuxzzzw}vvvvvvvvvv-<br></br>
`wwxzzzw}vvvvvvvvvvvvvvvvvvvvvvvvv}wxxzrvvzxxxxxxxxxxuuuuuuxzzzw}vvvvvvvv}~<br></br>
-uuxzzww}vvvvvvvvvrwwwwwwwwr}vvvv}wxuxwv?wxxzwwrrzrrwzzxuuuuxzzzzrvvvvv}}}~<br></br>
-uyyuwr}vvvvvvvv}wxuuuuuuuuxxzwr}rzuxw}??wz??v?v?vvv?vvvwwzxxxzzzzrvv}rrrw~<br></br>
-uyyyuxzzwwwrvv}wxuuuuuxxxuuuuxzwzxuzr}??vvvwzwxzzzzzwv?}v?wzxzzzzzzwwwr}}~<br></br>
-uuuuyyyyyyyyuwwxuuuxzwrrwzxuuuuxxuxxzwr??vrzzxxxxxxzwvvv??}rzzxzxxzw}}}}}~<br></br>
-uuuuuuuuuuuyyuuuuuxzr}}}}rwzzzxxxuuuuuxw}>v??>}zxwv?v??>?v}rwzxxzzzwr}}}r~<br></br>
-uuuuuuuuuuuuuyyyyuzwrzxxxzzzzxxxxxxuuuuux}vzwv}wx}}vr?>?vvrwzxxxzwwzwwwrr~<br></br>
`xxuuuuuuuuuuuuuuuxxzxxwzxxxxxxxxxxxuuuuuuxwwxxxxvzxxv??vv}rwwzzxzwwzzxxzz|<br></br>
`rwzxuuuuuuuxxxxxzrrrwxr?vzzzwzxxxzwzxuuuuuuzzxxrvrz}??}}}v}}}rwzzwwzxuuuu|<br></br>
`rrwwxuuuuuuuuxzw}vvvvv}?>rzwzzxxxxxxuuuuuuuuwvrzwrv???vv}}}}}rwwzzxuuuyyy|<br></br>
`rrrrwwzuuuyyuuuxxxxxzrvv}rwzxxxxxxuzwwwzxuuxuzwzrrwwxrv}}}rwxuuuuuuuyyyyy^<br></br>
`wwrrrrzzzxxuuuuuxxxwzxzzzzzxuuxzwwzxzrrzxxx}vvzuxuxrz}v}wxxuyyuuuuuuyyyyy^<br></br>
`wwrrrrw}}}rwwzxxxzxuyyuzzzzzzxuxzzrvzwwzzzw????vvwzwxrvruyyyyuuuuuuuuuyyy^<br></br>
`wwwwwrwwwwwwrrrrrzuuyyyuxzzzzzxuxzzv?}wzzwvv?????????>>wuuuuuuxxxxuuuuuyy^<br></br>
`wwwwwwwwrwzwwr}}rzxxuyyyyxzzzzzxuxzwwzzzzzzxxzzv????????wzxzzzwwzxuuuuuyy^<br></br>
`zzwwwwwwv}wwwr}}rzzzxuuyyuxzzzzzzuxzuuxzzzzzxuxv????????vxuxwxzzuuuuuuuuy^<br></br>
`zzzwwwwwwwzzzww}}wzzzzxuyyuuzzzzzzuwrw}}v>???vv??????????wxzuuxxyuuyuxxuy^<br></br>
`zzzzzwwzzzxuuxxwvvzzzzzxuyyyuxzzzzwvvvvv?>^++???????????>vr}zzwwxxzxzxxuu^<br></br>
`xxxzzzzzxxxxzzw}vvvwzzzzzuyyyuxzzr????vv??>++?>???????????rwwwzzxuwv}zxuu^<br></br>
`xuxxxzzzzxzwwrr}vvvv}wzzzzuuyyuzvvvv????v?v^~|-??????????>vxxxuuuurvrxuux|<br></br>
`xuuxxxxxzzzzzzw}vvvv???}}rrwxxz}vv????v???>` `>????????????zxxuuuzv}zxxzr~<br></br>
-uuuuuuuxxxzzzzzr}??vvv??vvvvrrv??vvvvvvvv?`.-+????????vvv?>zuuuuu}vwxxwvv-<br></br>
-uuuuuuuuxxxxxxxz}v??vvv??vv?>->vvvvv??vvv>|+>>???????vrw}??uuuuuu?rzxzv??-<br></br>
-xuyuuuuuuuuxxxxx}v}vvvvv???vv>?v}wwr}}vv>>>?????????vv}r}vruuuuyzvwzz????`<br></br>
`??rxuuuuuuuuuuuuxxxzrvvvv?vvvv?vwxxzzw}????????????vv}vvvvxuuuuuw}wzv??>>`<br></br>
`??>>xyuuuuuuuuuuuuuuuz}v???????v}wzzzrv????????????vvv????uuuuuuxxxr>>??>`<br></br>
`??+^vyuuuuuuuuuuuuuuuuwvv??vvvv}}wzxuwvvvvvv?????????vv?>vyuzwzuxxxwv???>`<br></br>
`??+^?uuuuuuuuuxxxuuuuuuxxzzzxxxxuuuyyxr}}rw}v??????v}rrv>?}}}wxuuxvvv}rrv-<br></br>
`???}wxuuuuuuuzwrvruuuuuuuuuxxxxxxxxxxzw}v}}vv?????v}rr}?>^>?}zzzz?^++>??v`<br></br>


            </div>,

            <div>

.????????????????vrrv????v??????????????vv???>?>???>>>>>>>>>>???vvv???>???`<br></br>
`}}}}vvv}}rwwwwwrwww}}v}wzrvvvvvvvvv}r}vvvv}}}wwzwwww}v???????vvvvvvvvvvvv-<br></br>
`}}}}}}}}rwuuuuxr}}vvvvvvvvvvvvvvvvvrzw}v}rzzzzzrr}wzzzzv???????????vvvvvv-<br></br>
`wwr}}}}rrzuuxzwr}vvvvvvvv???????vv}rwrv?vzzzzzzwzzzzzzzzv>???????????vvvv-<br></br>
-uuuzr}}rrwzzwr}}vvvvvvvvv????????vvvvv??rzzwrwzzw}}}rrwwv?????????????vvv-<br></br>
-uyyyuwrrrwwrr}vvvvvvvvvvv????????vvvv???rw??vvxz}v???>?v??????????????vvv-<br></br>
-uuyyuuzzzzzwrvvvvvvvvvvvvvvvvvvvvvvv????vvvwwzzzzzwwr?>??>????????????vvv~<br></br>
-uuuuuuuuuyyuuxrvvvvvvvvvvvvvvvvvvvvv???>??rwwzxzzzzwrv??>???????????vvvv}~<br></br>
-uuuuuuuuuuuuuyuxr}vvvvv}}}v}vvv}rwzz}???>^???>vzzwv?v?>+>?????????vvvvvv}~<br></br>
-uuuuuuuuuuuuuuuyyuw}rzxxxxzzzzzzwzxxxwv??>?wrvvrzv}}r?+>v????????vvvvvv}}~<br></br>
`xxzxuuxzwwwwwzxxxxxxxxwzxxxxwvvrzxxxxzzr?>?rzzzzvzxxv???vvvvv????vvvvv}}}~<br></br>
`rwzxxxxwrrrwwzxxzrrrwxr?vzzzv?rz}vv}}}rwzr}wwzz}vrz}?vvvvvvvv????vvvvv}}r~<br></br>
`rrwwzzwwzxxxxzzwrvvvvv}??rzwrv}vrzzzxzzzzzwrrv}wrw}rv?vvv????vv}}}}vvv}zz|<br></br>
`rwwwwwwxxxxxxxxxxxxxzrv}}wwzzrrwzxuwvvvvv?}vxwrwrrzxx}v???v}wzxxxxzrrwzxx|<br></br>
`rwwwwrzzzzzzwwwzxzzwzxzzzzzxuuxzwrwxwvvvv????vzxzuxwwvvv}wzxxxxxxxxzzzzzx|<br></br>
`wwwwwrwvvvvvvvvvv?wyyyuxzzzzxuuxzz}vwr??v??>>>??vwzwz}v}xxxxxxxxxzzwwwwwz|<br></br>
`wwwwwrrvvvvvvvvvv}uuyuyuxzzzzzuuxzzv????v????????????+>rzzwwwwrrr}}}rrrwz|<br></br>
`zzzwwww}vvvvvvvvvwxxuyyyuxzzzzzxuxzzwzzzzzzxxzzv??????>?vv}vvvvv}r}wwwrzx|<br></br>
`zzzzzzzwvvvvvvvvvwzzxuuyyyuzzzzzxuxzuyxxxxxxxuuv>?????>>?wxw}wrrxxzxxzxxu|<br></br>
`zxzzzzzzzwwwvvvvvwzzzzuuyuuuxzzzzxurv}v}v>???vv???????>>>}zwxxzzuxzuzwwwz|<br></br>
`xxxxxxxxxxzxzvvvvvzxzzzxuyuuuxzzzxwvv???v+^++???????????>?rvrwrrzr}wv}}vv~<br></br>
`xuuuuuuuuuxxr}vvvvvzxzzzxuuyyuxxxw????vvvv>++?>??????????>rwrrrwzr^|^?vvv-<br></br>
`uuuuuuuuuuxzrvvvvvvvrzzzzxuyyuuw}vvv????vvv+||~??????????>vxzzxxx?~^?v??v~<br></br>
-uuuuuuuuuuxxzw}vvvvvvvv}}rrrr}vvvvvvvvv????` `>????????????zxxxuz|^>?v??v~<br></br>
-uuuuuuuuuuxxxzxr}?vvvv??vvvvvv???vvvvvvvv?`.->????????????>zuuuur^>^????v~<br></br>
-uyyuuuuuuuxxxxxz}v??vvv??vv?>~>v}}vvvvvvv>^>>????????????>?uuuuu?++|+???v~<br></br>
-wrrxyuuuuuuuuxux}vvv?vvv?vvvv?}wr}vvvvvv>>???????????????+vuuuux+>^+>???v-<br></br>
`vv??vxuuuuuuuuuuxxzw}vvvvvv}rwrrr}}vvvv?????????????????>>zyuuyz??>??vv??-<br></br>
`vv??>vyuuuuuuuuuuuuuuwvvvvvv}rrrw}}v??>????????????????>>>uyyuur?v???vv?v-<br></br>
`vv??>>xuuuuuuuuxxuuuuurvvvvvv}}}rrrrw???????????????>>>>+vyuz}}}vvv???v?v-<br></br>
`vv???^ryuuuuuyz???wuuuuxxxzzxxxxuuuuz??????????????????>+?}}vvwwwzvvvvv?v-<br></br>
`vv???>vyuuuuyuv??>>wuuuuuuuxxxxxxxxxv?v????????????????>>^??>vvww?>?vv??v-<br></br>


            </div>,

            <div>

`vvvv???vvvvvvvv??????>>>>>>>>>>>>>???++++++++>>?>>>>>>>>>>>++>>??????>>??`<br></br>
`}rwwwrrwwwwwwwwr}}vvvvv???????????v}v?>>>>vvv}}r}rrrvv???????????vvv???vv-<br></br>
`}rrwrrrrrrwwrrr}}vvvvvvv???????????vv?>??vrrrr}vvv}wwwrv?>>>>>>>>?????vvv-<br></br>
`xzwrrrrrrrwwrrrr}}vvvvvv???????????????>?rrrrrr}}}rrrrrr?>>>>>??????????v-<br></br>
-uyuxzwwrrrrrrr}}}}vvvvvvv?????????????>>}wr}vvvvv??vvv}}?>>>>>>??????????-<br></br>
-uyyyyuzrrrrrr}}}}}}}vvvvvvv???????????>?}}?>>>?>?>+>>>>?>>>???>>>????????-<br></br>
-uuuuyuxzzzwwwr}}}}}}}}}}vvvvvvvvv?????>>???v}}r}}}}}v?+?>+>???????>??????-<br></br>
-uuuuuuuuuuuuuxzr}}}}}}}}}}}}}}}}vvvv???+>?v}}rrrrrr}}???^>???????????????-<br></br>
-uuuuuuuuuuuuuuuuzwr}}}rrrrrr}}}rwzzz}??>+|??>+?}rv?>v>>^+>??>????????????-<br></br>
-uuxxuuuuuuuuuuuuuuxwwzxxxxxzzzzzzzxxxw??>+>}v??vr?vvv>+>????????????????v-<br></br>
`xxzzzzwwzwzzzxxxxxxxxxwzxxxxzr}wzxxxxzz}?>>vrrrr?rrr?>>?????????????????v-<br></br>
`wzzzwwwwwwwzzxxxzwwwzxw?}xxx}vwzr}}rrrrwz}}rrwwv?v}?>>?????????????????vv-<br></br>
`wwzzzzwzxxxxxxxzw}}}}}rv?rzzrvrvrzzzxzzzzzwrrvv}vv>>>>????????????????v}}~<br></br>
`wzzzzzwuuuuuxxxxxuxxzw}rwwzzzwrwzxxwvv}vv?}}zr}rvvv}rv>????v}rwwrr}vv}rrr~<br></br>
`wzzzzwzxxzzzzzzzxzzzzxxzxxxxuuxzwrwxwvvvv????vrzwzz}rv??v}rwwwwwwwrr}}}}r~<br></br>
`wzzzzwz}rrrrrrrrrvzuuyuxzzzxxuuxzzrvzrvvvv?>++>?vrr}rv?vwwwwwrrrr}vvvvvv}~<br></br>
`zzzzzzzrrr}}}}}}}wuuuuuuxxxzxxuuxzzv?v?vvv??>>>>>>>>+|^v}}vvvvvvvvvvvvvv}~<br></br>
`zzzzzzzr}rrrr}}}}zxuuuyyuuxzxxxxuxzzzzzzzzxxxzz?>>>>>>^>????????vvvv}}}rr~<br></br>
`xxxxzzzz}}rrrr}}}zxxxuuyyyuxxxzzxuuzuuxxxxxxuuuv>>>>>>+^?v}vvvvv}rrwwwwzx|<br></br>
`xxxxxzzzzzww}}r}}zxxxxuuuuuuxxxxxxuw}r}}v????vv?>>??>>>>>vwrrr}rzwzuzw}}r~<br></br>
`xxxxxxxxxxzxzr}r}rxxxxxxuuuuuuxxxxzvvvvvv>^^+????>???>>>+?vvrwrwzwzxw}vv}~<br></br>
`xuuuuxxxxxxxwr}}r}rxxxxxxuuuuuuxxzvvvvvvvv>++?>????????>>>}}}}}rrv??vvvv}~<br></br>
`uuuuuuuxxxxzw}}rrrrrwzxxxxuyyuuzr}rrvvvvvvv+|^|?????????>>vxzzzxv++?vv?v}~<br></br>
-uuuuuuuuuxxxxzr}rrrrrr}rwwwzwrrr}}}}v}}vvv?-.`>????????????zxxuu?>>?v??v}~<br></br>
`uuuuuuuuuuuxxxxzw}}}rrvv}}}}}}v?v}}}}}}}vv~`~>????????????>zuuux??>>v??v}~<br></br>
`xuuuuuuuuuuuuuuxw}vv}}}v}r}v?~?}r}}}v}}r}?+>>????????????>?uuuu}??+>???v}~<br></br>
`xxzzzxuuuuuuuuuxwrr}}}}}}}}}}?v}}}}}}}rv?????????????????>}uuuuv>>???v??v-<br></br>
`zwrr}}zuuuuuuuuuuxzzw}}rrr}}}r}}}}r}}rv??????????????????>zyuuxv???vvvvvv-<br></br>
`}rr}vv}uuuuuuuuuuuuuuw}}}}}}}}}}}rr}vv?????????????????>>?uyuuw?vvvv}}vv}~<br></br>
`v}}vvvvzyuuuuuuxwwzuuuwr}}}rrrrrrwwwzv?????????????????>>vyuz}vv}}}r}}vv}~<br></br>
`v}}vvvvruuuuuuurvvvruuuuxxxxxxuuuuuuxvv?????????????????>v}}vvrzzz}}}}vv}~<br></br>
`v}vvvvvruuuuuuzvvvvvxuuuuuuxxxxxxxxx}?v?????????????????>+?v?vvwzv?vvvvvv-<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvv??????>>>>>>>+++++>???^^^^^^^^^^>>>++++++++^^^^+>>>>>>>>??`<br></br>
`rwzzzzzzzzzzwwwr}vvvv????????>>>>?v}v>>>++>?>????vvvv????>>>>++>????????v-<br></br>
`}rwzzzzwwwwwwwwrr}}vvvv?????>>>>>>?vv>>++>????????vvvvv?>>+++>+++>>??????-<br></br>
`uxzzzzzwwwwwwwwrr}}vvvvv??????>>>>>>??>+>???????????????>^+++>>>>>>+>>???-<br></br>
-uyuuuzzzwwwwwwwrr}}}vvvvv??????>>>>>>>++?v????>>>>>>????>++>+++>>>>>>>>>>`<br></br>
-uuuuuuuxzwwwwwwwrrrr}vvvvv??????>>>>>>+>??+|^^^^^||^^^^+^++>>>>>+>>>>>>>>`<br></br>
-uuuuuuuuxzzzzwwwwwwwrrrr}}}}}}}v????>>>^>>>??????????>|^|^+>>>>>>>++>>>>>`<br></br>
-uuuuuuuuuuuuuuuxwwwwwrrrrrrrrrr}vvvv?>>+^^????????????>^~^++>>>>>>>>>>>>>`<br></br>
`uuuuuuuuuuuuuuuuuxzwrrwrrwwwrrwwwzzw}?>>^~+++|>???>>?>^|++++++>>>>>>>>>>>`<br></br>
`xxxuuuuuuuuuuuuuuuuxwzxxxxxzzzxxzxxzz}?>>^^??>>??>>>?+^+?>>>>>>>>>>>>>???`<br></br>
`xxxxxxzzzzzzxxxxxxxxxxzxxxxxxwwwzxxxzzwv?++?vv??>???>|+>?????>>>>>???????`<br></br>
`zzxxxzzzwzzzxxuuxzzzzxzvrxxxrvwzrrrwwwwwz}v}v}v?+??>|^>>?????>>>>>>>>>>??`<br></br>
`zzxxzxzzxuuxxxxxzrrwrrwrvwzzw}w}rzzzzzzzzzzwrvv???+|^^+>>>>>>>?>>>>>>????`<br></br>
`zzzzzzzuuuuuxxxxuuuxxwrwwzzxzwwwzxxw}}}v}vr}w}vv?>???>+>>>>????????>?????`<br></br>
`zxxxxzxxxxxxxxxxxxzzxxxzxxxxuuxzwwwzwvvvvvv???}rvr}vv?>>??vvv????????????`<br></br>
`zxxxxzxwwwwwwwwwzrzuuuuxxzzxxxxzzzrvzwvvvvv>^^+>?vvvv?>?vvvv????????>>???`<br></br>
`zxxxxxxzwwwwwwwwwzuuuuuuxxxxxxxxzzz}vvvvvv?>>>>+^+^^|~|????????>>>???????`<br></br>
`zxxxxxxzwzzzwwwwwxxuuuuyuuxzxxxxuxxzzzzzzzzzzww?>++^^^|^??>>????????????>.<br></br>
`xuuxxxxxwwwwzwwwwxxxxuuuyuuxxxxzxxxzxuxxzxxxxxxv>>>>+^||>????????vvv?^..  <br></br>
`zuuuxxxxxzzzwwwwwxxxxxuuuuuuxxxxzxuw}r}r}????vv?>>>>>++^+?vvvvv??|-.      <br></br>
`ruuuuuuxxxzxxwwwwzxxxxxxuuuuuxxxxxz}}}vvv?+>>?????>>>>>++?vv}v+..         <br></br>
`ruuuuuuuuuxxzwwwzwzxuxxxxuuuuuuxxzrr}}}}vv?>>v?????????>>>}?|.            <br></br>
`wxuuuuuuuuxxzwwwwwwwzxuxxuuuuuuzwrwwwr}}}}v++>+?v???????+~-.              <br></br>
`wxuuuuuuuuuxxzwwwwwwwwwzzzzzzwwwwwwrrrwr}}v|`~????????>|.                 <br></br>
`wxuuuuuuuuuuuuuxzwwwwwrrwwwwwrvvrrwwwwrrr}+|+?vvvvvv+`                    <br></br>
`wzuuxxuuuuuuuuuxzwwrwwwrrwwrv>vrwwwwrrwwrv????vvvv?`                      <br></br>
                  `rzuzzwwxuuuuuuuuzwwwwwwwwwwwwvrwwwwwwwwrv?vvvvv??-                        <br></br>
`rzzzzrrruuuuuuuuuuxxwrwwwwwwwwrrwwwwwwrvvvvvvvv>`                         <br></br>
`}rzzwrr}zuuuuuuuuxxxuzwwwwwwwwwwwwwwr}vvvvvvv|`--                         <br></br>
`v}wwwrr}ruuuuuuuzr}rxuzwwwwwwzzzzzzzz}vvvvv+.                             <br></br>
`v}rwwrrrruuuuuuxrrr}ruuuuxxxuuuuuuuuur}}}?-                               <br></br>
`v}}wrrrrrzyuuuwrrrrr}zuxuuuxxzzxxxxxw}}}>.                                <br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvv?????>>>>>>>++^^^+???^||||||~|^^~`.            .`~^>>>>??`<br></br>
`zzzzzzzzzzzzzzzwr}vvv???????>>>>>?v}v++++^^++|-..                 .`-^>?v-<br></br>
`wzzzzzzzzzzzzzzwwr}vvvv????>>>>>>>?vv>++^+>^`.                         .`.<br></br>
-uuxxzzzzzzzzzzzwwrr}vvvv?????>>>>>>>??+^++`.                              <br></br>
-uyuuuxzzzzzzzzzwwrrrvvvvv?????>>>>>>>>+^-.                                <br></br>
-uuuuuuuuxzzzzzzwwwww}}vvvvv?????>>>>>>|.          .`.                     <br></br>
-uuuuuuuuuxxzzzzwwwwwwwwrr}}}rrrv????>-          .~>|                      <br></br>
-uuuuuuuuuuuuuuuxzwwwwwwwwwwwwwwr}v}?`         `|^>^      ..               <br></br>
                  `xuuuuuuuuuuuuuuuuxzzwwzzzwwwwwwwzz?.        .`-.-^   ..`~+.               <br></br>
`xxxuuuuuuuuuuuuuuuuxxxxxxxxxzzxxx>  .      ..  `+..`-``.>`       .        <br></br>
`xxuuuxxxxxxxxxxxxxxxxuxxuxxxxzzz| ....        `>~~~`.  ^^    ..~+.        <br></br>
`xxxxxxxzzzzzxuuuxzzzzxzrzxxxwww| ....  .     `>|..    ->.  `~~^?~         <br></br>
`xxxxxxxxxuuxxxxxzwwwwwzwrzxxz}- ...............      .>`.~~-..>+          <br></br>
                  `zxxxxxxuuuuuuxxuuuuuxzwwwzzxw` ......                ^+||`.  |?.     .`~+`<br></br>
`zxxxxxxuuuuuxxxxxxzzuuxzxzxz- ......                `>~.    `?~   .`~->vv`<br></br>
`zxxxxxxzzzzzzzzzzrzuuuuxxxw- ......      .          ..     .>> .-~-`..??>.<br></br>
                  `zxxxxxxxzzzzzzzzzzuuuuuuxz- .......    .^?~                -?||~`.   |??` <br></br>
`zxuuxxxzzzzzzzzzzxxuuuuyu|     .....  `?}}}>`             .++`.     .?v~  <br></br>
`zxuuuuxxzzxzxzzzwxxxxuuu+.|??>` ... .+}}v}}}?|.           ..        `>~   <br></br>
`wxuuuuuxxxxxxzzzzxxxxxu?~vr}rw}` . `?}vvvvvv??+-                    `-  `.<br></br>
`zxuuuuuuxxxxxzwzzzxuxz}?rv??v}r~ .^v}vvvvv?vv?>>^`                 .~-`~` <br></br>
`rwrrvvv??????>>>?>?vr}vvv???}rv.`?}vvvvvvv???v?>>>~.               .~`.   <br></br>
.>>>>>>>>>>>>>>>>+>vvvvv????vrr>~v}vvvvvvvvvv??v??>>+-.             ..     <br></br>
.>>>>???vvvvv}vvvv}}}vv?????v}}?v}vvvvvvvvvvv???vv?>>>+`                   <br></br>
.??v}}rrrrrrr}}}}}}}vvv>?vvvvvvvvvvvvvvvvvvv??vvvvv?>+|-`                  <br></br>
`v}rrrrrrrr}}}rr}}}}vvv>>vvvvvvvvvvvvvvvvvvvvvvvv???+----`.                <br></br>
`v}}}}}}}}}}rrrr}}}}vvv?>?vvvvvvvvvvvvvvvvvvvvvvvv+~+|-----`.              <br></br>
`v}}}}}vv}rrrrrrrrr}}vvv>?vvvvvvvvvvvvvvvv+?vvvv??>~-+^~-----`             <br></br>
`v}vv??vv}rrrrrrrrrrr}}v?vvvvvvvvvvvvvvvvv^v>?????>+-`+^~-----`.           <br></br>
`v????v}v}rrrrrrr}vv}}v}vvvvv}}}}}}}}}vvvv?wr^-^>>>+|``+^|--`````          <br></br>
.???vv}}v}rrrrrrr}vvv??vv}}}}}rrrrrrr}}vvvv?}}?~|+++^-.`++^-````-`.        <br></br>
`v}}}v}}}v}rrr}}}}vv?>??rrrr}}}}}}}}}}vvv?>^>}vv^-^++~` -++|-``````.       <br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}}}v?????>>>>>>+^^^||+??-                                     <br></br>
`zxxxxuuxxuuuxxxzw}vvvv?????>>>>++>v-       ``                             <br></br>
`zxxxxxxxxxxxxxxzzwr}vvv??>>>>>>+>^.       ->.    .^-        ..            <br></br>
-uuuuxxxxxxxxxxxzzwwr}vvv????>>>>+.      .`-~   `~^>.      .~>`         `^`<br></br>
-uuuuuuuuxxxxxxxzzzwrvvvvv????>>>`      .. ^. `|-.|^     `^|>>.      .~>?+ <br></br>
-uuuuuuuuuuxxxxxzzzzzr}}vvvv????|         .^.^|. .?-   .|+`.>^     .|>>?>. <br></br>
-uuuuuuuuuuxxxxxxzzzzzzzwwrrwwwv.         ->>-   `?`  `+`  `?-    -?>`|?-  <br></br>
-uuuuuuuuuuuuuxxxxxxzzzzzzzzzzz-          ^>.    |?. ||.   |?.  .+?~ .>+   <br></br>
`xuuuuuuuuuuuuuxxxxxxxzzzzzzzx?           `      |?`^-     >+  ->^.  |?`   <br></br>
`xxuuuuuuuuuuuuxxuxxxxxxxxxxxz.                  ~?+.     .>| -?-   .>+    <br></br>
                  `xuuuuuuuuuuuuuuuxxxxxxxxxxxu?                   `+.      .?~-+.    ~?-    <br></br>
`xxuuuuuuuxxxuuuuuxxxxxxzzxxu^        +~                  .?>|.     >>.  ..<br></br>
`xxuuuuuuuuuuuuuuuzzzzzzzzxzw`       +v?^`                .?~      .>^  `>.<br></br>
`xxuuuuuuuuuuuxxuuuxxxxzwr}vv`      ~vvv?>|.               .       .?` |>. <br></br>
`zxuuuuuuuuuuuuuxuuxxzrvvvvvv..-~. `?vvvv?>+|.                     .>`+|   <br></br>
`zxuuuuuuxxzzzzwww}vvvvvvvvvv|??+..?vvvv???>++|`                   .>+`    <br></br>
`zwrr}v??>?vvvvv?vvvvvvvvvvv??+|. >vvvvv???v>+++^`                 .^.     <br></br>
.?>|^^^^>vvvv???vvvvvvvvvvv??>^- +vvvv?^????v>++++~`.                      <br></br>
.+^+++^?vv??>?vvvvvvvvvv?vv??+>.^vvvvv?>??v?>v?++^^|~`.                    <br></br>
.^^++^?vv?>?vvvvvvvvvvvv?v???+-^vvvvvvv?z}>v?^vv?+^||||~`.                 <br></br>
.^^+^?vv??vvvvvvvvvvvvv?vv???^+vvvvvvvvvvyr+v^>vvv?>^||^^^|`.              <br></br>
.^^^+vvv?vvvvvvvvvvvvvv?vv>>??vvvvvvvvvv?vwwv+|vvvvv???>++++^~`            <br></br>
.^^^>vvvvvvvvvvvvvvvvv>?vv>+>vvvvvvvvvvvv??v}?^>???vvv?>+^^^+^^v?|.        <br></br>
.^^^?vvvvvvvvvvvvvvvv?+vvv>>vvvvvvvvvvvvvvvv?v???>+|||~~||^|||vwrr}?^`     <br></br>
.^^^vvvvvvvvvvvvvvvv?>>v?>+?vvvrzzzzzzzzwwr}vvvv?^||>?|?>+||^vw}}}}rr}?>|` <br></br>
.^^+vvvvvvvvvvvvvvv?>>??>>>?vvwyyyyyuuuyyyuv?vv?+++^|^|?}?~?zzr}}}}}}}}rr}-<br></br>
.^^+vvvvvvvvvvvvvvv>>>>>>>>>vzuuuuxzxzwzxuuz}>?>++>>++++++|?wzr}}}}rr}}}}}-<br></br>
.^^+vvvvvvvvvvvvvv?>>>>>>>+?xyuuuzzuuuuzrwzuu}vv??+>>>>+++|^|?rrrrrrrrrrrr-<br></br>
.^^+vvvvvvvvvvvvvv>>>>>>>>?xuuuyzxxzxuuyuxwwwzxxzw?>>+^|+^^^||>}wwwrr}vvvv`<br></br>
.^^^vvvvvvvvvvvvvv>>>>>>+?uyuuuuxzuuuuxuyyuuxwwrwwr>+|.|>^^^|^^^??>>^^||||.<br></br>
.^^^?vvvvvvvvvvvvv>>>>>+?uyuuuuuyuxxuuyyyyyuyyx}rww?+`.^+^^^|^^^||||||||||.<br></br>
.^^^?vvvvvvvvvvvvv>>>>+?uyuuxxxxxxxxzzzzxxxxwv?rrrv+~.`-~^^||^^^^^^^^|||||.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv????>>+>>+.  .    ~-.    ..    .`.                       <br></br>
-uuuuuuuuuuuuuuuxwr}vvv????>...     .`.    ..     `.                       <br></br>
`uuuuuuuuuuuuuxxxzww}vvv???^. .|~`.               .                        <br></br>
-uuuuuuuuuuuuuxxxzzwr}vvv??|. .???-...                       .             <br></br>
-uuuuuuuuuuuuuxxxzzzw}vvvv?- ..??>~~~``.`...                ..         ..  <br></br>
                  -uuuuuuuuuuuuxxxxxxzzwrrvvv`||`v?~~`-~-``.`|`|~...-.    ..  .          ..  <br></br>
-uuuuuuuuuuuuuxxxxxxxxxzwr}+?|~}|~-~--`...~++~`..-+`..`~`..`~.....``.....  <br></br>
-uuuuuuuuuuuuuuxzzw}vvv}rrr}?^+>~~~~+>~`..^+`....~^``~|-...-|`..`|^-.`|~.. <br></br>
`}wzxuuuuuuxzrv????>?vrrrrrvv>+~~~|~~|^|``^`...--~|`||`````~~..-^~`..|^-.. <br></br>
.????vrzz}v????????vrwrrrr}}v?|~~~~~~--~~~~``....->^`.``-`-^|`~|~`..`^|`.. <br></br>
`??????????????????}wrrrrr}rv>~~~~|^+>??~---``...`+|`...`-~>|~|`....~^-... <br></br>
`??????????????????vrrrrrr}rv^~~+?????wr?>+>>^`...`|~.....`^^~......^^.....<br></br>
`?????????????????>?rrrrr}vr}~-|?v?>?rxzrv?>>>+-`.`~~.....`^-......`+|..`^.<br></br>
.?????????????????>?vwrr}v?vv~.^v?>>}xxxxzwv>>+-...~~......`.......`+-.~^` <br></br>
.??????????????????>?}}vvv??v~`vrv?vxxxxxzzwvv>`...-|`.............`^-|~.. <br></br>
.??????????????????++>vvvv???^>zzvvxxxxxxzzzzrv|...`|`.............`+^-... <br></br>
.??>>>>+++>+>>>++>?>+>?vvv?>?>?zvvzxxxxxxxzxzrv>`...~`.............`|`.... <br></br>
.^||^^^^^>>>++++>>??>++?vv?>>>vv?wxzwxz}}zzrzrv>``````.................... <br></br>
.^^^^^^+>>++|^>>>>??>>+>?v?>>>}>?zzrrwwv}}z}}r}|```-^^`................... <br></br>
 -~~||+>>+^^+|>>>>>??>>++?>>?>>^?}wv?rrvvr}}>vr|```~+>|~-`................ <br></br>
 ````-~^+^+>+~|^^|~++^++++^^>+^>??v?>vv>>rw|-^v|-~-|>>|||~```..... ....... <br></br>
.````~~~~+>>>~~^+^|+>^-~++^|~~^+>>>???>>++>|~`|~-~~+?+~--~|^^~``..      .. <br></br>
.```-~~~~|+>>~~~+>>>?>~-~|||^|~~~~|~~||^^|+?v???>--^>^|^^^|^^^^>+`.     .. <br></br>
.```-~~~~-~++~~~|>>>>?>++|--|+^~~|^+>??vvvvvvvvvv|`^>|~~|||||^>>>~~~`..... <br></br>
.~~~^^||~~-~~~~~~^>>+>?>>+^^>??v}rrrrrrrr}}vvvvvv?++>+~^^^||^>?>^~+>??+^|-.<br></br>
.^>>??vv??>|-~~~~~++|>?>^^^>v?vwwwwwrr}}}}v????v????>||+>+~+??>+|?????????`<br></br>
.+??vvvvvvvv>~-~~~|^|^??^^^?v?vv}}vvv????vvvv?v??????^|||||+>>>|>vv}}v???v-<br></br>
.+??vvvvvvvvv?+~~~-|||>?^^^vvvvvv??vvvvvvv}rw}vvvv???>^^||^^^+^>}rr}}}???v-<br></br>
.+??vvvvvvvvvvv?~~`~||>?^|>rvvvvvvvv}wwwwrr}}rrr}}v???+||^^^^~~vr}r}}}?>?v-<br></br>
.^??vvvvvvvvvvvv?~`-|^>?^+rrvvvvvrwwwwwwwwwrr}vvvvv????>^||~``+?vvv???++??`<br></br>
.^>?vvvvvvvvvvvvv>``~^>?>?r}vvv?vv}wwwrr}}vvvv????v???>?~-```+>>??????+???`<br></br>
.^^?vvvvvvvvvvvvvv~```+???vvvvv?v?vvvv??vvv??>>vv}v??>>>-```|?>+?????>>+^|.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv????>>+>>+   .    -^+.  .+^    `?+                       <br></br>
-uuuuuuuuuuuuuuuxzr}vvv????+        .?~    ^`    .>-                       <br></br>
`uuuuuuuuuuuuuxxxzzw}vvv???|   ^~.   .            .                        <br></br>
-uuuuuuuuuuuuuxxxxzwr}vvv??~   vv?>|-`.                                    <br></br>
-uuuuuuuuuuuuuxxxxzzw}vvvv?`  .vvvvv??+^|-`..                              <br></br>
-uuuuuuuuuuuuuxxxxxxzwrrvvv.~-.vvvv????>>+^||^^^^|-....                    <br></br>
-uuuuuuuuuuuuuxxxxxuuxzzr}v|+`-vvv??v?+^>>>++>>>+++~|||~~~-----````....... <br></br>
-uuuuuuuuuuuuuuxzwrv?>>vvvv?>-^vvvvvwwv>|+>>+^||+^|||^^+|~~~|||||^^|||~~~~.<br></br>
.v}wxuuuuyuzw}?>^||~^?vvvvv??~?vvvvvvvvv>??+|^|>?^~|^++^^^|~~~|||||||||||~.<br></br>
.|^^+?vwrv>^^>>^|^|+vvvvvvv??+?vvvvvvv?vvvv?>^|^+|++||^++++^^^^|^||^^^^^||.<br></br>
.^^^^^|^||^^^+>>^|^?vvvvvv?v>>vvvvv}wzxxv???>>>>^~>>+|||^+>+^^|~~|||||||^^.<br></br>
.^^^^^^^^^^^^^+>>~^?vvvvvv?v?>vvrxuuuuuuxz}wz}>>^~+>>^^^||||^^^^^^^^^^||||.<br></br>
.^^^^^^^^^^^^^^>>^|+vvvvvv?vv>?vuyxwxuxxxxxxxzw?>~+>>^^^^^^^|^^^^^^^^^^^^|.<br></br>
.^^^^^^^^^^^^^^+>>|^?vvvvv>vv>+}yyzxuuuyyyyu}zz?||^>>^^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^^+>^|^vvvvv+?v>+wyuuxuuuuuuuu}xw>``^+>+^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^|^^^^^>>~|+vvvv>>v>>uuuuyuuuuuuuuuxw?`.`+>+^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^|^^^^^^>+||>vvv>+>+vyuuuuuuuuuuuuyxw?`..->+^^^^^^^^^^^^^^^^^^^^.<br></br>
.^^^^^^^^^^^|^^^^^>>^|^>vv>+++rxxuyxzuyxwuuxuxw>...-^+^^^^^^^^^^|^^^^^^^^^.<br></br>
.^^^^^^^^^^^|^^^^^+>+|||>v>>+>wrvxxwwxxwwzuzzzw~...-^+^^^^^^^^||^~-^^^^^^^.<br></br>
 `-~~|^^^^^^|-^^^^^>>^^||++>+>v++rwv?wwv?wz}>ww-...-+>^^^^^^||^^^~.`|^^^^^.<br></br>
 .....`-^^^^^`-~-``~^~|^|||~^++++?v>+vv+^?w|.+r-...->>|||~-|^^^^^~..`-----.<br></br>
 .......`^^^^`.-|~`~|-.`||^~``-|^++>>>++^~~..`^`...|>|```-|^^^^^^-........ <br></br>
 ........`|^^`..|^^^>+``.`~||`...```````-~^>?vv?+..~^--|^^^^^^^^^`........ <br></br>
 .........`||...`^^^+>>||-``-|-.``~^+???vvvvvvvvv~`^>^^^^^^^^^^^^`..````--.<br></br>
.~~~~-```...`....-^^^>>^^^^^^>??vvvvvvvvvvvvvvvvv?>>>^^^^^^^^^^^~`~^+>++>>.<br></br>
.>????????^`......~^^+>>^^^>v??vvvvvvv?????>>>?vvvvv?|^^^^^^^^^|`>>>>>>+>?`<br></br>
.>vvvvvvvvvv^`....`^^^>>|^^?v>+>????>>>++>>>?vvvvvvvv^^^^^^^^^^`^??vv?>+>v-<br></br>
.>vvvvvvvvvvv?|...`^^|>>^^^v?>>>>>>>>>>??vvvvvvvvvvvv>|^^^^^^^-~vvvvvv?+>v-<br></br>
.>vvvvvvvvvvvvv+...~^|>>^|>v>>>>>>??vvvvvvvvvvvvvvvvvv>^^^^^^--vvvvvvv?+?v-<br></br>
.>vvvvvvvvvvvvvv>..-^^>>^|v?>>>+>vvvvvvvvvvvv??????vvvv?^^~```>vvvvvvv?>vv-<br></br>
.+?vvvvvvvvvvvvvv+.`~^>>^^v?>>>+>>?vvvv???>>>>++>>?vvvvv~`...>??vvvvvv?vvv`<br></br>
.>>?vvvvvvvvvvvvvv-..`^>^|+>++++>++>>>>++++++>??vvvvvvvv-...|v?>vvv?????>+.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv????>~...~.        ..    ..    `^|...................... <br></br>
-uuuuuuuuuuuuuuuxzr}vvv-.`>?....     ..    .    ..+~...................... <br></br>
`uuuuuuuuuuuuuxxxzzw}}?``~v>...+^`....     ...........................`... <br></br>
-uuuuuuuuuuuuuxxxxzwr}?``+v+.`-????>~``...............  .... ....... ..... <br></br>
-uuuuuuuuuuuuuuxxxzzw}>`.>}|.`|v????>??+-``........    ................... <br></br>
-uuuuuuuuuuuuuuxxxxxzwvv^?}`^|-vv??????^~~------````............   ....... <br></br>
-uuuuuuuuuuuuuuuuxuuuxwvvvv>v>>wwrvvv?+~~~~~~~~----~||||~~~~------```..... <br></br>
`zzxxuuuuuuuuuxzwwr}v????vvrw??zwwwwzw>^-~||~------||^^+|~~~|||||^^|||~~~~.<br></br>
.???v}rwxzw}vv?+^||+??vvvvvrr?vzzzzzwwv?^^+~-~~^+~~|^++^^^|~~~|||||||||||~.<br></br>
.^^^+>???>+^^>>^|||?vvv??vvww}rwwwwwwwvv>>>^|~-~~-~~~|^++++^^^^|^^|^^^^^^|.<br></br>
.^^^^^^^||^^^^>>^|^?vvv???vzrrzzwwzxxxzw???>^~~~~~^>^|||^^^^^^^~~~|^^^^^^|.<br></br>
.^^^^^^^^^^^^^+>>~^>vvv???vzwrzzxuuuuuzwrv??v?^|||+>+^^^^|^^^|~~-----~--~|.<br></br>
.^^^^^^^^^^^^^|+>^|^?vv???vr}vvvzzzzxxzrrvvvvv?++|^>>^^^^^^^^^^^|-````````.<br></br>
.^^^^^^^^^^^^^^^>>~^?vvvvv?vv??}zzrrwrzrw}}}?v?+||^+>^^^^^^^^^^^^^|-``--`~.<br></br>
.~~~~|^^^^^^^^^|+>++^?vvv?????>}zzzwwr}}vvvv?vv+-~^+>^^^^^^^^^^^^^^^~+++>>`<br></br>
.-----~|^^^^^^^^|>?^|+????>>???wzzzzzzr}???vvvv+-`-^>+^^^^^^^^^^^^^^>>^>?>.<br></br>
.-------|^^|^^^^^|?>||>vvv????vzzzzzzzzw??vvvv?+----++^^^^^^^^^^^^+>>+>??>`<br></br>
.--------~~||^^^^|>>^~|>vv????}r}wzwrzzwvv}vvv?^-----^^^^^^^^^^^^+>>>????>`<br></br>
.------------~|^^|>?>|||>v??>?}vvwwr}rw}}rv?vv?|`---~~|^^^^^^^|^+>+>?????>`<br></br>
 ````-~||||^|~^+++^>>^|||+>???v??v}v?}rvv}v?>??~----^~~^^^^^^|^+?+^+>?????`<br></br>
 ..```-~^+>>>^+>+|-^^~|^|~|+>?>>>????vv??v}^~>?~---~>|-~||~~~^>>>+^^^+++++.<br></br>
 ````-||+>???^|+|-~^+~`~|~|~--|+>????????>>^|~^~---|>~`````~+>???+^^^^|||^.<br></br>
 `````|^|^>??^^^^||^>+-`--~||-`~^^++++^|^+??v?>>^--~|-```~+>?????+^^^^|-~~.<br></br>
 `````-|||^>>^^^^+^|+>>|~~`-~|~`~|^++>>?vvvvvvv??|-|+|`~^>???????^|^^|~~~~.<br></br>
.^||||~^^^|^^^^^^+>>>??+^^||^>>????????????vvvv?>>++>^^>????????>+>>>>|~||.<br></br>
.???????v??+|^^^^^>>????>>+>????????????>>?????v?????>?????????>+?>>^~-`-~.<br></br>
.?vvv????vvv?^|^^^+>>???>????>>>??>>>>>>>>??vvvvvvvvv?>????????+>??>++||~^.<br></br>
.?vvvvvv??vvvv>^^^^>>>???>?v?>>>>>>>>>>>????vvvvvvvvv?>???????>+???vvv????`<br></br>
.?vvvvvvvvvvvvv?^^^>>>???>?v???>>>????????????vvvvvvvv??>???>>+???vvvvv??v-<br></br>
.?vvvvvvvvvvvvvv?^^+>?????vv?????vvvvvvv??vvvvvv???vvvvv?>>+^|>??vvvvv??vv-<br></br>
.>vvvvvvvvvvvvvvv?^^>>????v????>?????vvvv?????>>>?vvvvvv>^^||>???vvvvv?vvv`<br></br>
.>>?vvvvvvvvvvvvvv+^^+???>??>++++++>??????>>+>>?vvvvvvvv+|||+??>vv????>>>+.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv????>`   -~.                  .-||~~~~~~~~--~^++^^++^+>>.<br></br>
-uuuuuuuuuuuuuuuxzr}vvv.  +vv?>~`..         ..`~~^+++^^^^^^^||~-|+>>>>>>??`<br></br>
-uuuuuuuuuuuuuuxxxzwr}?  `vvv????+^^|~-`.`-~||||~~|^^^^^|||||||~~~|^+>>>>>`<br></br>
-uuuuuuuuuuuuuxxxxzwr}+  |vvvv}>+???>+++|^^^|||||~~~~~~~~~~~~~||||||~~|+^>`<br></br>
-uuuuuuuuuuuuuuxxxzzwr+  +vvvv}v>+>+^>v>|^^^^||||||~~~~|||^||~~~|||||||~~|.<br></br>
-uuuuuuuuuuuuuuxxxxxzz?>`>vvvvv????>>>?^^^^^^^||||||~~~~~~|||^||~~~||||||~.<br></br>
-uuuuuuuuuuuuuuuuuuuuzv+>?v}wzzxxwvv?++|+^^^^^^^||||||||~~~~|||||||~~~~~||.<br></br>
`v}rwxuuyyyuuxwwrrr}r}v??vvzyxwxxxxxxr+^+^^^^^^^^||||^^+|~~~~|||||||||~~~~.<br></br>
.|^^^+>vr}v???+|^|~?vvv???vuywzuuyuyxxv>^^^^^^^^^^^^^++^^^|~~~||||||||^^|~.<br></br>
.^^^^^^^^||^^+>^|~|vvvv+>?vuyuxxxxxuxxv?|^|^^^^^^^|||^+++++^^^^|^^|^^^^^||.<br></br>
.^^^^^^^^^^^^^+>^|^?vvv+>>vyuuyyuxuuyx}v>++|~||||||||||^^||^^+^|||^^^^^^^^.<br></br>
.^^^^^^^^^^^^^^+>|^>vvv+>+vyyyyuuuyuuxrv?^>^^^|||^^^^^^^^^^^^|||||||||||||.<br></br>
.^^^^^^^^^^^^^^|+^||?vv>+>vwr}}}}v}wwwr?v^>+^^^^^^^^^^^^^^^^^^^^|~-~~~~~~~.<br></br>
.^^^^^^^^^^^||^^^>~>?vvvvvvvvvvvvv?vv>}+?^+>^^|^^^^^^^^^^^^^^^^^^^|-~~~~~|.<br></br>
.```-~^^^^^^^^||^+>?>?vv????????vvvvv?+>^-|>^|^^^^^^^^^^^^^^^^^^^^^^~>???v`<br></br>
 .....`|^^^^^^^||^>?>>+++>>>????vvvvvv?+`.-+>^^^^^^^^^^^^^^^^^^^^^^+??>vv?`<br></br>
 .......~^^^^^^^^|>>>>>??vvvvvvvvvvvvvvv``^+>^^^^^^|^^^^^^^^^^^^^^+v?>vvvv`<br></br>
 ........`-~||||^~?>>++?vvvvvvv???vvvvvv>^^^>^^^^^^^-|^^^^^^^^^^^+vv?vvvvv`<br></br>
 ..........``-~|||>>>+>+>?v?????vvvvvvvvvv^|>^^^^^^^~`^^^^^^^^^^>vvvvvvvvv-<br></br>
 ....`~|^++++>>>?>^^>+>>>+>?vvvvvv???vvvvv+|>+^^^^^^^.`^^^^^^^^?vvvvvvvvvv-<br></br>
 ..-^>>>???vvvvvv?>++^>>>+>?v???>>??vvvvvvv+++^^^^^^^-.`|^|--^?vvvvvvvvvvv-<br></br>
 `|>>>?vvvvvvvvv>+>>+^>>>++++++>?vvvvvvvvvvv?+^^^^^^^~..`..->vvvvvvvvvv?vv-<br></br>
.^>>>>vvvvvvvvvv>++>++^>>++>+++?vvvvvv?>?vvvv?^^^^^^^|...`>vvvvvvvvvvv?>??`<br></br>
.++>>>?vvvvvvvvvv?>+++++++^>>>+>>??>>++>vvvvvv?^^^^^^~.`>vvvvvvvvvvvv?++>+.<br></br>
.>>+>>>vvvvvvvvvvvvv???>>>+++++>>>++>>+>>???vvv?|^^^^^+vvvvvvvvvvvv?>>~`-~.<br></br>
`v?>+>>?vvvvvvvvvvvvvvvvv??+++++>>>>>++>>>?vvvvv?>+^>vvvvvvvvvvvvv?+|`.``` <br></br>
`vvvv?>+?vvvvvvvvvvvvvvvvvvv>+>++>++>+>>>>?vvvvvvv?vvvvvvvvvvvvv?>+~`~||-` <br></br>
`vvvvvv?>?vvvvvvvvvvvvvvvvvvv>+>>>>>>+>>>>>>?vv??vvvvvvvvvvvvvv?>+>?vvv??>.<br></br>
`vvvvvvvv??vvvvvvvvvvvvvvvvvvv?>>>>>??>>>>>>>??vvvv??vvvvvvvvv?++>vvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>??vvvvv?>>vvvvvvvvvv?>++?vvvvvvvv-<br></br>
.?vvvvvvvvvvvvvvvvvvvvvvvvv?v????????vvvvvvvv??>>?vvvvvvvvvvv?>>>?????vvvv-<br></br>
.>>?vvvvvvvvvvvvv??vvvvvvv?v>^^++++>vvvvv??>+++>?vvvvvvvvvvv?+++???>+++>>>.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv??+.`.   .                   .`~||~~~~~~~~--~^++^^++^+>>.<br></br>
-uuuuuuuuuuuuuuuxzrr>``...>?>^~`..     ......`-~|^+++^^^^^^^||~-|+>>>>>>??`<br></br>
-uuuuuuuuuuuuuuxxxzw+``..~vv????>+|->>-``-~|||||~~|^^^^^|||||||~~~|^+>>>>>`<br></br>
-uuuuuuuuuuuuuxxxxzz^`...+v}??v>^?>++>+~|^^^|||||~~~~~~~~~~~~~||||||~~|+^>`<br></br>
-uuuuuuuuuuuuuuxxxzz>+``.>vvvvvv+>+||^?+|^^^|||||||~~~~|||^||~~~|||||||~~|.<br></br>
-uuuuuuuuuuuuuuxxxxzrr??|?wwwr}vv?>+^|||^^^^^^||||||~~~~~~|||^||~~~||||||~.<br></br>
-uuuuuuuuuuuuuuxxxuxwv???}zwxuxuuzvv?^|^^^^^^^^^||||||||~~~~|||||||~~~~~||.<br></br>
`vv}rzxuuyyuuuxzzzwrv????rwzyuxuxxzzz}++^^^^^^^^^||||^^+|~~~~|||||||||~~~~.<br></br>
.|^^^^+?vv}r}v?>>>>?vvv?vvvzxzzuuuxxzw?>+^^^^^^^^^^^^++^^^|~~~||||||^^^^^|.<br></br>
.^^^^^^^^||^^^>>||+????>??vzzwwuuuzzzw??|||^^^^^^^^||^+++++^^^^|||+^|+^+>>`<br></br>
.^^^^^^^^^^^^^+?^|^??v????vzzzzzxxxxuxrv>||~~~~|||||~~~|^^++++^||^^^>>+>>+`<br></br>
.^^^^^^^^^^^^^+>>|>?vvv??>vzzzzzzrwvrwrv>|+|^^||^^^^^^^||^^^>++++^^+++++|~.<br></br>
.||~||^^^^^^|^^^>+|^???>>?vr}}}vvv?????+>^+^^^^^^^^^^^^^^|^>???>>+^|^^~-`` <br></br>
.-----~~|^^^^|||^>^+?vvvvvvvvvvvvvv>>>?^+^^>^^^^^^^^^^^^^>????????>^^~-``~.<br></br>
 `````---~|||^|~|+>?>?v???????vvvvv??>|||~|^^^^^^^^^^^^+>>>>????????+>>+>>`<br></br>
 .....``-----~||||>>++>>>???????vvvvv?>|----||^^^^^^^+>????????>>?>>?>^^^|.<br></br>
 .......`~~|^^|||~^~~^>>?vvvv????vvvvv?+--|-|`~^^^^^>?????????>+^++??|^+^^.<br></br>
 ....````~^>>>>+^~~```^>???????>>?vvvvv?~---|--~|^>>+>???????>+||^??+>>+>+.<br></br>
 ..````-|^^^+>>>>>>+|~--|>??>>>?????vvvv?>~-|--~+>??+^>????>+^|~^>?vv}}vvv`<br></br>
 .```~^++>????????????>|`-|^^+??????vvvvvv?+>^+?????>^+>??>^|--~|^^?wwwwrr-<br></br>
 ``|+>>>>??vvvvvvv??????|``~||^>>>?vvvvvvvv???>??????+^+>+|~-~^++++>vrwwww~<br></br>
 ~+>>>?????vvvvv?????????>>>>>-|>???vvvvvvvv??>>>>?>?+|^|``~+>>>>>>>>??}ww~<br></br>
.>??>>??????vvvv??????????????+^?vvvvvv?vvvvv?+>>>?>?>^|`-+vvvvvvvvvv?>^?}-<br></br>
.?????????????vvv???????????>>>?????????vv???v??>>>??>||+?vvvv??vvvvvv?>+>-<br></br>
.??????vvvvvvvvvvvv????>>>>+>?????????>>>>??vvv?>>????>???vvvvvv??????++>+`<br></br>
`vv?????vvvvvvvvvvv???????>>???????>>>>>>??vvvvv?????v???vvvvvvvvv?>^~`-|+.<br></br>
.?vvv????vvvvvvvvvv????????v??????>>>>>???vvvvvvvvvvv????vvvvvvvv??>^^|~~|.<br></br>
.??vvvvv??vvvvvvvvvv??????vvv???>>>>>????????vvvvvvv????vvvvvvvv???vvvv??>.<br></br>
`v?????vvvvvvvvvvvvvvvvvvvvvvv??>>????????>>??vvv??????vvvv?????>??vvvvvvv-<br></br>
`zwv>>???????vvvvvvvvvvvvvvvvv???vvvvvv?>???vvv??>>?????vvvvv?>++??????vvv-<br></br>
`wzz>|+?????????vvvvvvvvvvv???????????????????>>>>???+>???vvv???>?>??????v-<br></br>
`}rww>|^+?????????vvvvvvv??v?>?????>??????>>>++>???>^vv?????????v??++++>>>.<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv??^   .`.            .-~-----~||||~~~~~~~~--~^++^^++^+>>.<br></br>
-uuuuuuuuuuuuuuuxzrr|  `???>+~`.... .~~^^||||||||^^++^^^^^^^||~-|+>>>>>>??`<br></br>
-uuuuuuuuuuuuuuxxxzz~  ?vv?????>>+~-?}+^^||||||~~~|^^^^^|||||||~~~|^+>>>>>`<br></br>
-uuuuuuuuuuuuuxxxxzz` .vvvv}?^??^>+^+??^^^^||||||~~~~~~~~~~~~~||||||~~|+^>`<br></br>
-uuuuuuuuuuuuuuxxxzz^-`vvvvvv???^>^^+^+^^^^^|||||||~~~~|||^||~~~|||||||~~|.<br></br>
-uuuuuuuuuuuuuuxxxxw???vvvxzxzr}}?|++^^^^^^^^^||||||~~~~~~|||^||~~~||||||~.<br></br>
-uuuuuuuuuuuuuxxxxuw?+?vvwuwxuuuxz??>+++^^^^^^^^||||||||~~~~|||||||~~~~~||.<br></br>
.??vvrwxuyyuuuuxxxxr?>>vvwxwuuxxxx}}v?++^^^^^^^^^||||^^+|~~~~|||||||||~~~~.<br></br>
.|^^|^^+?vrxzw}vvvvvvv?vvvvvrzzuuu}}vv?>+^^^^^^^^^^^^++^^^|~~|~~||^^+>+++^.<br></br>
.^^^^^^^^^^+^^+?>>??>>>??vvvvvvxyu}}vv>++++^^^^^^^^|^^+++++^^^??+>?>>??v}v-<br></br>
.^^^^^^^^^^^^^^?++>>???vvvvvvvvvzzrrzrv?>^|||||||||||||^+>>++????v??vv?vvv-<br></br>
.^^^^^^^^^||^^+>++?vvvv????vvvvvv?v^?v}?^||^^^^^^^^^^^||^++>??vvvv?vvvvv?+`<br></br>
.---~|^^^^^^||>>+++>?>>??vvvvvvvv?>|^^^||^^^^^^^^^^^^^^^^|+vvvvvvvvvvv?>+^.<br></br>
 .....`-|^^^^^|^+++>>?vvvvvvvvvvvv?|^^^^^^|^^^^^^^^^^^^^^?vvvvvvvvvvv?>+++.<br></br>
 ........`-~~|||++>>+???????vvvvvvv>^+^^^^~-^^^^^^^^^^^>?vvvvvvvvvvv?>+|^^.<br></br>
 ............`+>+^++++>>>?vvvvv??vvv?>^^^^|.`~^^^^^^^>?vvvvvvvvvvvv?>+~~~~.<br></br>
 ......``-~^++++++~``|>+>?v??>++?vvvvv>~|^~...-^^^^+?vvvvvvvvvv?>?>>+~||||.<br></br>
 ....-|+>??vvvv??|....|+>>>++>++>??vvvv^.......`~+?vvvvvvvvvvv?++^^^|^^^^^.<br></br>
 ..-^>>?vvvvvvvvvv?+~`.`-+>>+^+>>++>?vvv+|`....->vvvvvvvvvvv?>|-~|^>?vvv??`<br></br>
 `~+>>>>>>?vvvvvvvvvvv?-..--`.+>>>?vvvvvvvv?+|>vvvvvvvvvvv?>^`...``?zzwwrr~<br></br>
.+>>+>>>>>?vvvvvvvvvvvvv-.....-+>>?vvvvvvvv?vvvvvvvvvvvvv?++~------|vwzzwz~<br></br>
.>>>>++>>>>?vvvvvvvvvvvvv????>``|>>>?vvvvvvvvvvvvvvvvvvv?>++^|||||||~|?}zz~<br></br>
.?v?>>>++>>>?vvvvvvvvvvvvvvvvv^`^?vvvvvvvvvv????vvvvvvvv>++?vvvvvvvv??^^?w|<br></br>
`vvvv????>>++>?vvvvvvvvvvvv?>>?vvvvvvvvvv??>>?vvvvvvvvv>++?vv?????vvvvv?^?~<br></br>
`vvvvvvvvvvvv?vvvvv????>>>>>>?vvvvvvv???>>>?vvvvvvvvvv?++>vvvvv?>>>>???vv?`<br></br>
`vvvvvvvvvvvvvvvvvv>+++++>+>vvvvvvv?>>>>>?vvvvvvvvvvv?>>>?vvvvvvvv?>+++>vv-<br></br>
.?vvvvvvvvvvvvvvvvv?+^^^++>vvvvvv?>>>>??vvvvvvvvvvvvv>>>?vvvvvvvvvvv??+++?-<br></br>
.+??vvvvvvvvvvvvvvv?>>++>?vvvvvv?>>>?vvvvvv??vvvvvv?>>>>vvvvvvvvvvvvvvv??>`<br></br>
`?>+>????vvvvvvvvvvvvvvvvvvvvvv>+>?vvvvvv?>>?vvvv?>>>>+?vvv?>>???????vvvvv`<br></br>
-uxv-~++++>>??vvvvvvvvvvvvvvv?>>??vvvvv?>>>?vv??>+>>>++?vvvvv?++++>>>>?vvv-<br></br>
-uuy^.`|+>+++>>?vvvvvvvvvv??>??vvvvvv?>>>>>>>>>>>>>>|`-^>??vvvv?>++++++>?v-<br></br>
-uuyz~..`^+>>?>>>?vvvvvv?????vvvvv??>+++++>>+++++++-`v???+>>?vvvv??++++++>.<br></br>


            </div>,

            <div>

`rwrrrrrrrrrrrrrvvv-.   ...        ....`-~----~~||||~|~~~|~~~-~^>+^+>+++>>.<br></br>
-uuuuuuuuuuuuuuuxz}-. .~???+^-`. ..`-^~^^||||||||^^+^^^^^^^^||~-|>>>>>>>??`<br></br>
`uuuuuuuuuuuuuuxxx}-. `vvv?????+~|~|v}+^^||||||~~~|^^^^^||||~||~~~|^+>>>>>`<br></br>
-uuuuuuuuuuuuuxxxx}`. ~vvvvv>^>?|^^^+??^^^^||||||~~~~~-~~~~~~~||||||~~^^^>`<br></br>
-uuuuuuuuuuuuuuxxxr>..|vv}rrv??>|+^++^+^^^^^|||||||~~~~|||^||~~~|||||||~~|.<br></br>
-uuuuuuuuuuuxzzzzzw}^+?vwxxxuxz}v>|++^^^^^^^^^||||||~~~~~~||||||~~~|||||~~.<br></br>
-uuuuuuuuuuuzrrwwww}??vvvwuxuuuuzr??>+++^^^^^^^^||||||||~~~~~|^|^^|||~~~||.<br></br>
`??vv}wxuyyuz}}}}}}v???vvrwxuuuuzxr}v?++^^^^^^^^^||||^^+~~~||^^|++^>++~~~~.<br></br>
.|^^|^^+>?}wrvvvvvvvvvvvvvvvruuuxxw}vv?>+^^^^^^^^^^^+++^+>^|^^^+++>>+>>^|~.<br></br>
.^^^^^^^^||^+^+?>>??????vvvvv}wxxuwrvv>+++^^^^^^^^^^^^+????>>>?????>>??>>>`<br></br>
.^^^^^^^^^^^^|^>>>??vvvvvvvvvvvvrxzwwrv?+^||||||||||~^>????????vv?>>???>>+`<br></br>
.~~||^^^^^^^||^>+>?vv???vvvvvvv?>?rv}v?>^|^^^^^^^^^+>>>>????vvvv??>>>>++|~.<br></br>
 ````--|^^^^||+>++>>????vvv?vvvv?^~|||||^^^^^^^^^^+??????>?vvvv??>+|^^|-``.<br></br>
 .....``-~~~|||^++>>>?vv?????vvvvv>`--||~||^^^^^+>?>>????vv?????>^^^^~````.<br></br>
 ........````-~|+++>+>???>>>???vvvv>+|-~----^^+>>>>?>>??vv?>>>>+++++|```--.<br></br>
 ........``-~^?????>+++>>>?????vvvvvv?>^~-``-+??>>>???vvv??>>++^^++|---~|~.<br></br>
 .....`-~^>???????>+^^>+>????>???vvvvv?>>^|~||+?>>>?vvv??>^^+++|||~~-~||||.<br></br>
 ...`~+???vvvvv??>^^^|+>>>>+>>>>???vvvv>|||||||^>??vvv???+^^++~~~~|||^^^^^.<br></br>
 .`~+>??vvvvvvvvvvv?>+^+>??>|+>>????vvvv?>^~~~|+?vvvv???vv??+~--~~+>?vv???`<br></br>
 -^>>>>???vvvvvvvvvvvvv>||^^|^?????vvvvvvv???>?vvvvv???vvvv??+|~--~?wwwwrr~<br></br>
.+>>>>>>>>?vvvvvvvvvvvvv|`-|^^>????vvv????vvvvvvvvv???vvv???>+++++|+rwzzwz~<br></br>
.>????>>>>>?vvvvvvvvvvv???????++??>>????vvvvvvvvvv????vvv?>>>+++>>>>>v}wzz~<br></br>
.?v??????????vvvv???????vvvvvv?^>?????vvvvvvv?vvv????vvv??????????vvv???}w|<br></br>
.?vvvvv???????vvvv?????vvvvv????????vvvvvv???vvv????vvv????vvv??>???vvv}vv~<br></br>
.??vvvvvvvvvvvvvvvv???????????????vvvvv????vvvv????vvv????vvvvvv???>???}r}-<br></br>
.>???????????vvvvvv????????????vvvv???>>?vvvvv?????vvv?>?vvvvvvvvvv?????}r-<br></br>
.^^+???????????vvvv??????????vvvvv??>>>?vvv?????????v???????vvvvvvvvv??>?v-<br></br>
`v|~|+??????????vvvv??????vvvvvv?>>>>???????????>>?>>>??vv???????????vv?vv`<br></br>
`wv|~-~^>???vv????vvvvvvvvvvvvv?>>>???????>>???>|~|>>>>?vvv??>>>>>>????vv}-<br></br>
-uuv~~~--~^>>????????vvvvvvv??>>>???????>>>??+|~`^v}v?????????>+++++>>>?v}-<br></br>
-uuy?`-|||||^+>>???????????>>>???????>>>++^^~```-v}v????????????>>?????v}r-<br></br>
-uuuu?|--|||^+^^+>???????????????>>^^^^+||||~```^vv?>}}vvvvvvvv}}}}vvvvvvv`<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}vv?.   ...        .^?>--~-----~~||||~|~~~~~---~+>+^++++>>^.<br></br>
-uuuuuuuuuuuuuuuxz}.  ~???>^-``. .+v}?|^^||||||||^++^^^^^^^^||~~^>>>>>>???`<br></br>
`uuuuuuuuuuuuuuxxxv  `vv?v????>^~++>vv^^^||||||~~~|^^^^^||||~||~~~|^+>>>>>`<br></br>
-uuuuuuuuuuuuuxxxuv  -vvvvv?>^|?^++^+??^^^^||||||~~~~~~~~~~~~||||||~~~^++>`<br></br>
-uuuuuuuuuuuuuuuuzv- -vv}rwwv>>^^+++^^+^^^^^|||||||~~~~||||||~~||||||||~~|.<br></br>
-uuuuuuuuuuuzrrrr}??|^v?zuwxxxx}>++++^^^^^^^^|||||||~~~~~||||||~~~~|||||~~.<br></br>
-uuuuuuuuuuu}>?v?????vvvv}zuuuxxv???>+++^^^^^^^|||||||||~-||+>>+>>>??|~~||.<br></br>
`???vvrzxuyuv>+>>>???vvvvvvxxuuu}rr}v?++^^^^^^^^^||||^^^~^?????>???v??|-~~.<br></br>
.|^^^|^^+?vv?++?vvvv??vvvvvvryyu}zw}vv?>+^^^^^^^^^^^+++^??vvvv?vvvv?+??^|~.<br></br>
.^^^^^^^^|||?+^>>?>>?vvvvvvv?rxuwxzrv?>+++^^^^^^^^^^^^>vvvvvvvvvvv?+^>?+||.<br></br>
.^^^^^^^^^||^^^++?vvvvvvvvvvvvv?vzxzwwv>^|||||||||||~?vvvvvvvvvvv?+>>?>|^|.<br></br>
.``-~|^^^^^^~^^++>??????vvvvvvv+|?wxzv+^^^^^^^^^^^^>?vvvvvvvvvvv?>^|^^~|||.<br></br>
 .....`-|^^|^>++++++?vvvvv?>?vvv>-`^|~~|^^^^^^^^^^?vvvvvvvv?vvv?>|~~~~~~~~.<br></br>
 ........```|++++++>>???>+^+>?vvvv~..`~``~^^^^^^>?vvvvvvvvv>>>>>|~||||||~~.<br></br>
 ............`~~>>++++>>+>++++??vvv?+-.`..`~|^>?vvvvvvvvvv?||||||||||||||~.<br></br>
 ......``~|+??vvvv?>+++++^+>>>?vvvvvvv?|`..`+?vvvvvvvvvv?>|~~|^|||||||||||.<br></br>
 ....-^+??vvvvvvvvvv?>+++++>>>?vvvvvvvvv?>+vvvvvvvvvvv?>^~``--~^^^^^||||||.<br></br>
 ..`^>??vvvvvvvvvvvvvv?>>>+^>>>??vvvvvvvvvvvvvvvvvvvv?>+|````--|^^^^^^^^^^.<br></br>
 `~+>>vvvvvvvvvvvvvvvvvvvvv>-+???vvvvvvvvvv???vvvvvv?>+>v?>+~--~~|>>?vv???`<br></br>
.^>>>+>???vvvvvvvvvvvvvvv????vvvvvvvvvvv??>?vvvvvvv?>+>vvvvvvv??+|^}wwwwrr~<br></br>
.>??>>+++>?vvvvvvvvvvvv?>>?vvvvvvvvv??>>+>?vvvvvvv?>++?vvvvvvvvvvv>?zzwwwz~<br></br>
.?vvv?>>+++>?vvvvv??vv?>>?vvvvvvv??>>++>?vvvvvvvv?>>+?vvv??>???vvvv?vzzzwz~<br></br>
.?vvvvvvv????vvvv>+++++>vvvvvvv?>>>>+>?vvvvvvvvvv>>>>vvvvvv?>>+>>>?vvvwzww~<br></br>
.?vvvvvvvvvvvvvvv?+^^+>?vvvvvv?>>>>??vvvvvvvvvvv>>>>vvvvvvvvvv?>++>?vvvwww~<br></br>
.>???vvvvvvvvvvvvvvvvvvvvvvvv?++>?vvvvv???vvvvv?>>>?vvvvvvvvvvvvv??>?vv}rr~<br></br>
 ~^+>>>>>>>>??vvvvvvvvvvvvv?>+>?vvvvv??>?vvvv?>>>>>vvv???vvvvvvvvvvvv?vvr}-<br></br>
.-.`|++++++>>>?vvvvvvvvvv??>??vvvvv?>>>?????>>>>>+>?vvvv?>>??vvvvvvvvvvvr}-<br></br>
-w-..-^>>>>>>+>>?vvvvv?????vvvvvv?>>>>>>>>>>>>>>~-^>>?vvv?>+++>??????vvv}r-<br></br>
-uw~-`.`^>>>???>>>?vvvvvvvvvvvv?>>>>>>>>>>>>>>+-..-?>+>??vv??+^^+++++>???r~<br></br>
-uyr^||-`-|+>>???>+>??vvvvvv??>>>>>>>>>>>>>+|-...^zuxr?>+>>???>>+++++++>?}~<br></br>
-uuy}^^^^|||^^++>>>+++>>>>>>>>>>>>+>>>>>^|||`...`ruxxuuz}v?>+>>>>?v}}v}rww~<br></br>
-uuuyw?|~^^^^^^^||^^^||^+>>>+++^||~~|||^|||^~...^xzzzzzzzzzrvvv}wzzzzwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrr}}v>~.   ...        .^?+--------~|||||~|~~~~~---|++^^++^++^~.<br></br>
-uuuuuuuuuuuuuuux?|   |??>>^-... `+v}?|^|||||||||^++^^^^^^^^|~~|+>>>>>????`<br></br>
`uuuuuuuuuuuuuxxxv^  -vv?????>^~~++>vv^^^|||||~~~||^^^^^||||~|~~~|^+>>>>>?`<br></br>
-uuuuuuuuuxzzzzzzz?  ~vvvvv>>^|>^++^>v>^^^|||||||~~~|~~~-~~~~||||||~~|+++>`<br></br>
-uuuuuuuuuxwwzzzzwv~ -v}rwwz}?>^^+++^++^^^^|||||||~~~~~~|||~~~~|||||||~~~|.<br></br>
-uuuuuuuuuzww}}}}v??^^v?zuzuxxzv>++++^^^^^^^^||||||~~~~~~|~~|^|~~~~|||||~~.<br></br>
-uuuuuuuuuzwv>???????vvvvrzuuuzxv??>>+++^^^^^^^||||~|~~~~~^^>?>+>>>++|~~||.<br></br>
`???vvrzxuuz?>>??????vvvvvvxuxxxrr}}v>++^^^^^^^^|||||^++~>?????>??>?+>~-~~.<br></br>
.^^^^^^^+>?v?+>?vvvv??vvvvvvwuuuwzw}v??>+^^^^^^^^||^++++?vvv???????>~^>||~.<br></br>
.^^^^^^^^|||>+^>>???????vvvvvrzuwxzrv?>+++^^^^^^^^|^^+>vvv????????+~~|>^||.<br></br>
.^^^^^^^^^||^^^++?vvv????vvvvvvv}zxzw}?>^|||^^++++^^^?vv?????????+~||++|^|.<br></br>
.```-~|^^^^^|||++>???????vvvvvv>+vzxr?^|^^^+>>>>>>+>??????vv????+|~~||~|||.<br></br>
 .....``-~~~|^||+^++???vv????vvv>|~^~--~^+>>>>>>+>?v???????>>?>^~-~~~~~~~~.<br></br>
 ........``-^>>>>>>>>???>>+>>?vvvv^```~~~|+>>>>>>vvv???????|~~~~-~||||~~~~.<br></br>
 .......````-|^+???>>>>++>+++>??vvv?>~----~^++>?vvv???????^--~~~|||||||||~.<br></br>
 .....`-~^+>??vvvv?????>^^>????vvvvvvv?^~``~>?vvv???????>^~-~|^|||||||||||.<br></br>
 ...`~^>??vvvvvvvvvv?>>>>>>????vvvvvvv?v?>>vvvvv???????+^|----~^^^^^||||||.<br></br>
 ..-+>>????vvvvvvvvvv?>???>>??>???????vvvvvvvv????vvvv??>|~~||~|^^^^^^^^^|.<br></br>
 `|>???v????vvvvvvvvvvvvvvv>~+>?????vvvvvvvv?????vvv????v??>^||^~^>?vvv??>.<br></br>
.^????????vvvvvvvvvvvvvvvv??>?????vvvvvvv????????vv?>>>vvvvvvv??>^>rwwwr}v-<br></br>
.>?????????vvvvvvvvvvvv?>>??????vvvv??>>>???????vv????vv????vvvvv??vzzwwwz~<br></br>
.?vvv??>>>>>vvvvvv??vv?>>?v??vvvv??>>+>??vv?????v???>?vvv??????vvvvvrzzzwz~<br></br>
.>????????????vvv?>>>>>>???vvvv??>>>+>vvv????????????vvvvvv??>>???vr}}wwww~<br></br>
.+????????????????>>>>??vvvvvv?>>+>>?vvv?????????????vvvvvvvvv?????v}}vwww~<br></br>
.|^+>???????????????vvvvvvvvv?++>???????>??????>>>>?vv??vvvvvvvvvv??vvvrrr~<br></br>
 -~~||^+>>>>>????????vvvvvv?>+>???????>>????>^|^>+>?vv?????vvvvvvvv}vvv}r}-<br></br>
.-.`-~~~~|^+>>????????????>+>??????>>>>>?>+^||>??>>>????>+>????vvvvv}}vvr}-<br></br>
-r~``-~||~|^||^+?????????????????>+^^^^>^~~~~>??>+????????>++>???????v}}rr-<br></br>
-uw|~-`-~^^^++++^+>????????????>+^||^++>+|~~|??+||+vv??vvv}vv?????????vvvr~<br></br>
-uyr+^~~--||^^++++^^>???????>+^^^^||^++>+^|~~>^||?zxxwv????vvv???????????}~<br></br>
-uuyr>^||~~~~||^^^^^|^+++>>+^^^^^^|^+^+>^~~-|^^|^wuxxxxzw}v??????vv}}}rwww~<br></br>
-uuuyzv>^^~~~~|^^|||||||^+>+^^^|~~-~|||+~-~+>^||?zzzzzzzzzzr}}}rwwzzwwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv|     ..      `~|>??^-------~||~|~~~~~~~~--~|>+^++^+++^~` <br></br>
-uuuuuuuuuuuuuuxx^  .>???>^|~.`^+^?vv+||||||||||^++^^^^^^^^||~|+>>>>>>>>?>`<br></br>
-uuuuuuuuuxzxxxxx|  +}vv?^>?>|^++^+?}?|^||||||~~||^^^^^^^||~~~--~^+>>>>>>>`<br></br>
-uuuuuuuuu}??vvvv?>->vvvvv?>+^++++^+?v^^^^||||||~~~||~~~^>^^+^+|~|~|^+>>>>`<br></br>
-uuuuuuuuuv>>>>>?vvvvv?xxzzwr?+>++++^>+^^^^||||||~~~-|?????>???v?|||~~~~^+`<br></br>
-uuuuuuuuz?^>?vv???vvvvvvxuuxz?>+++++^^^^^^^^|||||~~+v?v?v??vvv?>+~||||~~~.<br></br>
-uuuuuuuyw?^+>>>??vvvvvv?zxxuxv}?>>>+++^^^^^^^^|||~>vvvvvvvvv?>+|>>~|||~|~.<br></br>
`????vvrwzv^+>?vvvv??vvvvvvxyx}zw}}v?>++^^^^^^^^||?vvvvvvvvv?>++++^~~~~~~~.<br></br>
.|^^^^^^^+>^^+>?????vvv?vv?vxxrxzwr}v?>+^^^^^^^|+?vvvvvvvvv?>+~~|~||||||~~.<br></br>
.^^^^^^^^^|++^^++?vvv?++?vvvvv}xxxw}v?>>++^^^^>?vvvvvvvv??>>>~~||||^^^^|||.<br></br>
.~||^^^^^^^^+^++++??>+++>??vv??xxxzwrv>+^|||+?vvvvvvvvv?+>>+^^||^^^^^^^^^|.<br></br>
 ...`-~||||||||^++++++^>++>?vvvvwzxr>|~~|^>?vvvvvvvvv??^^+>++^^||^^^^|||||.<br></br>
 ......`....`-~||^++++^>>>?vvvvv?}v~`..`>?vvvvvvvvv?>+|~^^^^^^^|||~~~~~~~~.<br></br>
 .......`~^????vv??>+++>>>>??vvvvvv??>?vvvvvvvvvvv?>+|~|^^|||||||||||~~~~~.<br></br>
 .....`|?vvvvvvvvvvv???||++>>?vvvvvvvvvvvvvvvvvvv?>+^```~|^^|||||||||||~~~.<br></br>
 ....~>?vvvvvvvvvvvvvvv>`~?vvvvvvvvvvvv???vvvvvv?>+^`---~~~~||||||||||||||.<br></br>
 ..`^>>????vvvvvvvvvv?>>>vvvvvvvvvv??>>?vvvvvvv?>++>++^^^^++||^^^^^|||||||.<br></br>
 .`+>+++>>>>vvvvvvvvv>>?vvvvvvv??>>++>?vvvvvvv>>>+?vvvvvvvvvv?>^^^^^^^|^^|.<br></br>
 ->?v??>>>>>>?vv??vv?>vvvvvvv?>>>++>?vvvvvvvv>>>>?v??vvvvvvvvvv?+>>?vv???>.<br></br>
 |vvvvvvv???>>???????vvvvvvv?>>++>?vvvvvvvvv>>>>>vvv??>>????vvvv?>vwwwwr}v`<br></br>
 ^?vvvvvvvvvvvvvvvvvvvvvvvv>++>>?vvvvv??vvv?>>>+?vvvvvv??>++>?vvv?vwzzwwww~<br></br>
 |>>????????vvvvvvvvvvvvvv?+>??vvvvv?>?vvv?>>>>+?vvvvvvvvv?????vvv?wzzwwww~<br></br>
 `+>>>>>>>>>>?vvvvvvvvvv?>>?vvvvv??>+?vv??>>>>+>?vvvvvvvvvvvvvvvvv?wzzwwww~<br></br>
 .`^+>>+++>>>>>?vvvvvvvvvvvvvvv?>>>>???>>>>>>+>>?vvv??vvvvvvvvvvvv?wzwwwww~<br></br>
 ...`|+>>>+++++>>??vvvvvvvvvv?>>>>>>>>>>>>>+`-^>>??vv?>>??vvvvvvvvvwwwwrrr~<br></br>
 .....`~+>>>???>>>>>>???????>++>>+++>>>>>>|`...^>+>?vvv?>+>?????vvvwwwrr}}-<br></br>
.-``....`-^+>>??>>>>>>+++^^^+++>^++>>>>>^`...~vzzv?>>>???>++++>>???vzwrr}}-<br></br>
-r^||~-`....`-~|++++^^^+>>>>>+^^^^|~---^~....vuxxxxwv?>+>+++>>????vwzzzwr}-<br></br>
-ur^^^^^|~~~~~||^^^^^||||+>+||||||`.`||>|...`wuxxxxxxxzwwrrrzxxxzzzzzwwwwr~<br></br>
-uyr+|^^^^^^^^^^^^^^^^^^|^>^^^^^^^.`^^^>^|`.~xuxxxxxxxxxxxxxxxzzzzzwwwrrwr~<br></br>
-uuyxv>~--~~|||^^^^^^^^^|+?^^^^^^|`|^|^>|-`.vuxxxxxxxxxxxxzzzzzzzzzwwwwwww~<br></br>
-uuuuyuzv+`..`~^^^^^^^^^^+>^|||^^~~|||^>`..>zzzzzzzzzzwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}}vv| .  ..       .`-|||```----~~||||~~~~~~~--~~^>^^++^++|-`` <br></br>
-uuuuuuuuuuuuuuxz+  `>?++^-`` .||~+++~~~|||||||^^+^^^^^^^^^|||+>>>>>>>>>>+`<br></br>
-uuuuuuuuuxxxxxxx^  >rv+>~^++|^^|~|+>^^||||||~~~|^^^^^^^^||~~~--|+>>>>>>>>`<br></br>
-uuuuuuuuurv}r}}}v?|>}v++>?>>^+++^^^??^^||||||~~~~||^||~|+^^^|^|~~|^+>>>>>`<br></br>
-uuuuuuuuu}?????vv}}vv?rw}wwr?+>+++^^>+^^^||||~~~~~~-~>>>>>+>??v>~|~~|~|+>`<br></br>
-uuuuuuuuzv>v}}vvvv}}v??vzxxzz?>+++^^^^^^^^|||~~~~~~^?>????????+^^~|||~~~~.<br></br>
-uuuuuuuuzv>>???vv}r}}v??wzxuxvv?>>>+++^^^^|||~~~~~^?????????>^~~^^||||~|~.<br></br>
`vvvvv}rzx}???v}r}}vv}vvv?vxuz}zr}vv?+^^|||||||||^>?????????>^|||||~~~~~~|.<br></br>
.^+^^^^^^>?>???vvv?v}}v?vv?vxzrxzwr}v?>^||||^^^|+??????????>^|-~~~|||||~~~.<br></br>
.^^^^^^^^^|^+++>>v}}v?>>?vvvvv}xxzrv??>+^||^^^>?????vvv?>+^||-~^|||||^^||~.<br></br>
.~||^^^^^^|^^^+++>??>>>>???vv?vzzwr}v?+^||~|+?vv?????vv>^^^^|^|^^^^^^^^^^|.<br></br>
 .```-|^^^^^^^++>+>>>>>>>>?vvvvvrwwr>~~||^>?vvvvvv????>^^+>++^|||^^^^|||||.<br></br>
 `--```--``--|^^|^+>>>>????vvvv??v?|`..-+?vvvvvvvvv?+|~~^^^^^^^|||~~~~~~|~.<br></br>
 --``````~+????????>>?>>??>?????????>+>?????????vvv>+|~|^^||||||||||~~~~~~.<br></br>
 --```-|>?vvvvvvvvvv???+^+>>>???????????????????v??>>---~|^^|||||||||||~~~.<br></br>
 `-``~>?vvvvvvvvvvvvvvv>`~??vv????????????????vv?>>+||||||~~|^||||||||||||.<br></br>
 ``-^>>????vvvvvvvvvv?>>>?vv???v?????>+>?????v?>>>>?>>>+++>+||^^^^||||||||.<br></br>
 `|>?>>>>>>?vvvvvvvvv>+?vv???v??>>+^^+?v??????>+>+?vvvvvvvvvv?>^^^^^^|||||.<br></br>
 ~??v???>>>>??v???vv?>?vv??v?>+++^^+?vv??????++++>v??vvvvvvvvvv?+>>?v??>>+.<br></br>
 ^vvvvvvvv???>???????vvvv???>+++^+?vvv??????+++++?v?????????vvvv?>vwwwr}v?`<br></br>
.+?vvvvvvvvvv???vv??vvvv???>+++>?vv?v??????>^++^?v?vvvv???>>??vvv?vzzwwwww~<br></br>
 +???v???????vvvvvvvv?????>^+>?vvv???+?vv??+++^^????vvvvvv?????vvv?wzwwwww~<br></br>
 `>>>>>>>>>>>??vvvv??????>>?vvvv??>+^>v??>++++^+?????vvvvvvvvvvvvvvwzzwwww~<br></br>
 .-^+>>+++>>>>>????????????v?v??+^^+>??>>++++^^^>??????vvvvv??vvvvvwwwwwww~<br></br>
 `..`|+>>>+++^^^^+????????????+^^^^++++++++^``|||+????>>??vv????vvvwwr}rr}~<br></br>
 -~-`.`|+>++>>>+^^^^+>>>>>>>+^^^^||||^^^^^~`...|^|^>????>+>??????vvwwr}}}}-<br></br>
.+^|~-..`-~|^+>>+^^|^^|||^||||||~||^^^^^~`...-?}}?>|^+>??>++++>>???vwwr}}}-<br></br>
-r>>>+^`....``-~||||~~|^++^^^||~~|~-`-`^~.`--vxzwww}v?>>>>++>>????vrwwrrr}-<br></br>
-uw>>>>>^~----~|^^||||~~||^|||~~||```||>~.-~~wuxxxxxxxzwwwrrwzxxzzzwwwwwwr~<br></br>
`xuw?>>>??+|||||^^^^^^^||^>^^|||^|`-|||>|+|~^xuxxxxxxxxxxxxxxzzzzzzwwrrrwr~<br></br>
-uuuxv?+^~-~~|||^^^^^^^||^>^^|||^|`||||>+^~~vuxxxxxxxxxxxxzzzzzzzzwwwwwwww~<br></br>
-uuuuyuwv^`..`~^^^^^^^^||+>^|||||||||||>|~~?xzzzzzzzwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}v?vvvvvv?>.`-             .--~~|||~~~~~~~~~--~+>+++>^^|-```` <br></br>
-uuuuuuuuuuuuuxzrr}}vv?``..           .-|||~||^^^^^^^^^^^^^|+>>>>>?>>>>^~-.<br></br>
-uuuuuuuuuuuuuxxzr}vvv> .  -+++~`.  .`|||^||~||~|^^^^^^^^|||~~|+>>>>>>>>>>`<br></br>
-uuuuuuuuuuuuuxxxzww}}?   ^v??????+|>^^>???vvv??>|^^^|||||||~~~||^+>>>>>>?`<br></br>
-uuuuuuuuuuuuuxxxxzwr}? ~.?vv???vvvv??vvvvvvvv?+?^~~~~~~~~~~|||||~||^^^>>>`<br></br>
-uuuuuuuuuuuuuxxxxzzr}?-?>vvv?vv???vvvvvvvvvv?>^>?|~~~|||||~~~|||||||~||^+.<br></br>
-uuuuuuuuuuuuuxxxxxxzw}-??vzxv????vvvvvvvv??>++>+|~~~~~~|||||||||||||||~~~.<br></br>
-uuuxxxxuuuuuuxxxxxxxxv>+>vr}vvvvvvv???>>>>++~~|~~|||||~~||||||||~~~~~~|||.<br></br>
`v?>>++++?vwuuuuxxxxuw????vvvvvvvvv?>>>>>++>?>|||||^^^||~~~||||||||||~~~~~.<br></br>
.^^^^^^^^^^^>vv}wwzzrvvvvvvvvvvvv?>++++++++vvv?|^^^+++^^^|~|||||||||^|||~~.<br></br>
.||~~~~|^^^^||~|>?vvvv??vvvvvvv?>++^^^+++++?vvv?^|^^++++++^^^||^^^^^^^^^^|.<br></br>
 `-|+>>>??????vvvvv???vvvvvvvv>>+++?>>++++>>?vvv??^^^++>>>>+^|||^^^^^|||||.<br></br>
.?vvvvvvvvvvvvv??>>?vvvvvvvv?>>>>>>?>|>>>+>>>>?>>v?^|^^++++^^^||||||~~~||~.<br></br>
.vvvvvv??????>>+>?vvvvvvvv?>>>>>^^+|`.`|^^~|++++>vvv>|~~|||||||||~~~~~~~~~.<br></br>
.vvvv?>>>>>>>+>?vvvvvvvvv>>>>>>~..`..-~||+~||+>>?vvvv?>|~~|||||||||||~~~~~.<br></br>
.vvv?>>>>++>??vvvvvvvvv?>>>>>+||~~~~|^^^|+^^^^>+>vvvvvvv?^~||||||||||||||~.<br></br>
.??>++>>>??vvvvvvv?vvv?>>>>>+||^^|^^^^^^|+^^^^|++>vvvvvvvv?+^^|^^|||||||||.<br></br>
.?v???vvvvvvvvvv?>?v?>>>>>>^|^^^^^|^^^^^^+^^^^^|+>>vvvvvvvvvv?>+^^^|||||||.<br></br>
`vvvvvvvvvvvvv?>+>v?>>>>>>+|^^^^^^||^^^^^+^^^^^^^>>>vvvvvvvvvvv??+>??>>++^.<br></br>
`vvvvvvvvvvv?>>>>??>>>>>+++|^^^^^^^|^^^^^>^^^^^^^|++?vvvvvvvvvv???vv}r}v?>.<br></br>
`vvvvvvvvvv?++>>?>>>>>>+|++|^^^^^^|^^^^^^>^^^^^^^||>>vvvvvvvvvvv?>?vvvrwr}-<br></br>
.?vvvvvvvv?+>>>>>>>>>++||++^^^^^^^|^^^^^>+^^^^^^|~`+>?vvv???vvvvvv?vvv?www~<br></br>
.>>>?????>>>>>>>>>+^^|^^^>+^^^^^^^|^^^^^>^^^^^^^~~.|>>?vvv>>>?vvvvv?vvv}ww~<br></br>
.>>>>+++++>>>+++||||^^^^|>^^^^^^^-~^^^^++^^^^|~``~.|>>>?vvv>>+>?vvv???v}rr~<br></br>
.++++>>>>>+|-``...````---+~-----`.`~|||+^|||-`.....~>>>>>?v?>>++?vvv?>?vr}~<br></br>
`rwr?++++|`.............`~............``.``.........|>+>>>??>>>+>vvvvv+?}}-<br></br>
-uyyy}-`......`.........~`............`....~`........-|^+>>>>>>>>?vvvv??r}~<br></br>
-uuuuyw~......~|-``....`|`.......`...``.`.|xzv?>^||^>?}r?>++++++>>>>>>>?r}~<br></br>
-uuuuuyx?|`...`|^^|~```~^-~~|-..`||||^||`.}uuuuuxxxxxxxxxzwr}}}}rww}vvvwrr~<br></br>
-uuuuuuuyy>....`~^^^^^|>+^^^^`..-|^|>+|``vuuuxxxxxxxxxxxxxxxxxxxzzzwwrwrrr~<br></br>
-uuuuuuxr>`.-~~~~|^^^^^>^^^^~.`~^^^^+-.|wuxxxxxxxxxxxxxxxzzzzzzzzzwwwwwwww~<br></br>
-uuuuuu}~..``|+^^^^^^^+>^^^|`-|^^^|+|`.+wzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}v?vvvvvv?>.`-             .`--------------~~~|>>+^++|-`````` <br></br>
-uuuuuuuuuuuuuxwrr}}}v?``..           .`-~~~~||||||^^^^^||^^??>>>>>>>+|-`` <br></br>
`xuuuuuuuuuuuuxxwr}vvv> `  ~+>+~`.  .`~~~~~~-~~~|^^^^^^^^||~|^>>>>>>>>?>>^.<br></br>
`xuuuuuuuuuuxxxxzzwr}}?   ^v??????+|>||+>?>?????>^^^^^^|||~~~-~|^>>>>>>???`<br></br>
-xuuuuuuuuuuxxxxzzwr}}?.~.?v????vvvv?????????v?+?^~|~~~~~~~||||||||^++>>>>`<br></br>
-uuuuuuuuuuuxxxxzzww}v>-?>vvv?vv????vvv????v??>^>?|~~|||||~~~||||||~~~||+>`<br></br>
-uuuuuuuuuuxxxxxzzzzwrv-??vzx?????vvvvvv????>++>+|~~~~~~~|||~~|||||||~~~||.<br></br>
-uuuuxxxxuuuxxxzzxxxxxv>>>vr}?vvvvvv???>>++^^~~|~~~~|~~~~~~~||||~~~~~~||||.<br></br>
`v??>+++>?vwuuxxxxxxuwvv??vvvvvvv}v?>>>+++^>?>||||||^^~~~~~~|||||||~~~~~||.<br></br>
.^^^+^^^^^^^>vv}wwzzwvvv}}vvvvv}v?>>>++^^^^?vv?|||^^^^^^~~~~||||||^^^||~~~.<br></br>
.||~~~||^^^^||~|>?v}}v?vvvvvvvv?>+^|^+++^^^>vvv?^||^++++^^^^||^|^^^^^||^||.<br></br>
 `-|++>>???????vvvv????vvvvvvv??>>+?>>+^^^++?vv???||^+>>>>+^|~||^^^^^^^|||.<br></br>
.?vvvvvvvvvvvv???>+??vvvvvvv?????>???|>>>^+++>>+>v?^|^++++^^^|~~||||~|||||.<br></br>
`vvvvvv?????>>+++?vvvv??vv?>>>>>+>>+-.-^++||+^^^+vvv>|~~||||||~|~~~~~~~~~~.<br></br>
.vvvv?>>>>>>+++?vvvvvvvv?>+>>>>~.``..-~||+|||+++>vvvv?+|~~|||||||||||~~~~~.<br></br>
.vvv?>>>>+++>?vvvvvvvvv?>+++>+||~~~~|||||+^^^|++>vvvvvv??^~||||||||||||~~~.<br></br>
.??>>>>>>>?vvvvvvv??vv?>>++>+||^^||^|||||+^^^^|++>?vvvvvvv?+^||||||||||||~.<br></br>
.?v???????vvvvvv?>?v?>>>>+>^|^^^^^||^^^^^+^^^^^|+>>?vvvvvvvvv?>+|^^|||||||.<br></br>
`vvvvvvv???vv??>+>v?++>>>>+|^^^^^^||^^^^^+^^^^^^^++>?vvvvvvvvvv??>>?>+++^|.<br></br>
`vvvv??????v?>++>?>+++>>+++|^^^^^^^|^^^^^>^^^^^^^|++?vvvvvvvvvv???vv}}v?>^.<br></br>
`vv???????v?++++>>>++>>>|++|^^^^^^|^^^^^^>^^^^^^^||>>vvvvvvvvvvv?>?vvvrw}v-<br></br>
.?v???vv??>++++++++>>++||++^^^^^^^|^^^^^>+^^^^^^|~`+>?vvv???vvvvvv?vvv?www~<br></br>
.>>>>>???>+++++++++^^|^^^>+^^^^^^^|^^^^^>^^^^^|||~.|>>?vvv>>>??vvvv?vvvvrr~<br></br>
.>?>>>>>>++++^^^|||||||^|>^^^^^^^-~+^^^++|^^||~``~.|>>>?vv?>>+>?vvv???vv}}~<br></br>
.>>>>???>>^~``....`````--^~----``.`~|||+|||~-`.....~>>>>>?v?>>++?vvv?>?v}}~<br></br>
`wzw?>>>>^`.............`~............``.``.........|>+>>>??>>>+>vvvvv+?}}-<br></br>
-uuuyr~--````.`.........-`............-....~`........-|^+>>>>>>>>??vvv??}}~<br></br>
-uuuuyw|`````.-|-``....`~`...``..`...``.``^xwv>+^||^>?}r?>>+++++>>>>>>>?}}~<br></br>
-uuuuuyxv|`.`.`|^^|~```~^-~-|-`.`||~|^|^~`ruuuuxxxxxxxxxxzwrr}}rrrr}vvvrrr~<br></br>
-uuuuuuuyy>....`~^^^^^|>+^^^^`..-|^|>>^--vuuuxxxxxxxxxxxxxxxxxxzzzwwrrwrrr~<br></br>
                  -uuuuuuuw?-`-~~~~|^^^^^?^^^^~.`~^^^^+-`^wuxxxxxxxxxxxxxxxzzzzzzzzwwwwwwwww~<br></br>
-uuuuuu}^`---^+^^^^^^^+>^^^|`-|^|||+|-`>wzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}}vvvvvvv??>^||||~`.                    ..`-~|>>^^|-`````--`` <br></br>
-uuuuuuuuuuuuxzwrrrrr}vv????>>|-``.                ..`~||^+??>>>>+^|~-----.<br></br>
`xuuuuuuuuuuuzw}}}}}}vvvvv???+~``~|`.           ..`~|^^|||^+>>>>>>>>+^|~~- <br></br>
`xuuuuuuxxzzwrrrvvv???????>+|`...`~~-`        .`~|^^^^^^|||||^+>>>>>??>>>+.<br></br>
-uuxxzzwwrr}}}v??>>????>>>>+|`.`.`-|~-      .``-|++++|||~~~~||||^+>>>>>>>>`<br></br>
`zwwrr}}vvvvv??????>>>>>++>>>|`-|~~|~-`. .`-~~~|^++>>>?>^+|~|||||||^^++>>?`<br></br>
`}}vvvvvvvv?>>>?>>>???>+++>>>+^+>??>>^~``~||||--~^>^>?>+?^^|~||||||||||^+>`<br></br>
`vvvvvvv??>>>>>++?rwwr}vvvv?^>>>?vv??>>^^^^^||~----^>+|>^-~+^~~~||||~|||||.<br></br>
`vvvvv?>>>>>>+>?rwwwwwrrrr}++>?rxzww}?||^^^^^||~^+++++++|~~~>>|~|~~~~|||||.<br></br>
`vvv?>>>>>>>>>?}zwwwwzzwwr>>+>}ur+>vrr+^^^^^^|||+>>>>>>>>+||>??^||||~~~~||.<br></br>
`v??+>>>>>+^^+>>vuuuuuuux}vv+?uz++^~>w>^^^^^^|~~|^^>>>>?>+>^>???+^|||||~~~.<br></br>
.?>>>>>>+^|^^^^++??????>>vvv+wyzrvv?rv^+^^^^^^|~---|+^>>+^+>^|^>??>||||||~.<br></br>
.>>>>>>+^||^^^^^^||||~>|~?vvvuuyuwzux}v??>>+^^|~~~~-~^+++^|>>+>????+~|||~~.<br></br>
.>>>???>?+|^^^^^^^|-``^|~|>?vvrwxxxz}?vrr}v?>^|~~~~~~~~~~~-^>???>>>?|~~~~~.<br></br>
.>>??????^^^^^^^^^^^|~^|`~|^^++???v?|`-^?v?+|||~~~~~~~------^??v?>>>>^~~~~.<br></br>
.>>??vv?>|-^^^^^^^^^^^>>~~-~-``-|^^~..``-||^^^^^^^^^^^|~~---~???v?>>>>>|~~.<br></br>
.>??vv?>``-^^^^^^^^^^^>>^^^|~~`.......``-|^^^^^^^^^^^^^^||~``+???vv?>>>>^~.<br></br>
.+>>?>|```-^^^^||^^^^^>>^^^^^^|-```--`~^|^^^^^^^^^^^^||++>>>++???vvv??>>>>.<br></br>
.+++^-````-^|~``-^^^^^+>^^^^^^^^|^^^^^++^^^^^^^^^^^~-^?vvv??v?>???vvvv????`<br></br>
.+^-`..````-``..`^^^^^+>^^^^^^^^|^^^^|++^^^^^^^^|-`.^vvvvvv???>???vvvvv???`<br></br>
.`.....```......`|^^^^+>^^^^^^^^|^^^^|>^^^^^^^^^`..+vvvvvvv???>>??vvv??vv?`<br></br>
 ...............``|^^^>+^^^^^^^^|^^^||>^^^^^^^^|..-???vvvvv???>>??vvv?>?vv`<br></br>
.|```..`|`.......``-~^>^^^^^^|^^^^^||++^^^^^~-`-..->>>>??????>+>??vvvv?>?v-<br></br>
-uxwwrwzu>...........`^-~~--``-`|^^^^>^^^|~-````..`+?>>>>>>>>>>>???v?v?>?v-<br></br>
-uuuuuuuyr..``.......``.........``--||--``....`...`^?vvv???????>>??????>>?`<br></br>
-uuuuuuuuu>.``-~``...`..............`.............``^??vvvvvv???+>?????>>>`<br></br>
-uuuuuuuuyu>``-^^|-`~~``````..``...```--~|-..........`^>>??????>++>>>>>>>+.<br></br>
-uuuuuuxr}}v~.`~^^^|>+|||~~```~~--~^|||^?xxv+~---~~^>?v}v????????>>+>>>+++.<br></br>
-uuuuuuu?~```.``~^^^>^^^|||`-^^||^?>^+?wuuuuuzwwwzzxxxxxxxxzwwwwr}v???????`<br></br>
-uuuuuuuur>|||||||^++^^^^^~~^||~^?++vxuuuuuuxuuxxxxxxxxxzzzzzzzzzwrrrrrwww~<br></br>
-uuuuuuuuuuwv+|++^^>+^^^^^|^^||~^--^??v}xuxxxxxxxxxxxxxzzzzzzzzzwwwwwwwwww~<br></br>
-uuuuuuuuuuuux}?>>>?>>++^++^|||+>>?>?vrzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}}}}}}vvv?>^|||||`                        .->+>^-```````~-`~` <br></br>
-uuuuuuuuuuuuxzzzzzwrrv??>+++^.                      .`~|+????>+|~---~~-~-.<br></br>
`xuuuuuuuuuuuxwrr}}}v??>>>+++`                     .-||||^?>>>>??>>^~-----.<br></br>
`xuuuuuuuuuuuuxzr}vvv?>???>+^.                  .`|^^^^||~|^>>>>>>>??>>^|-.<br></br>
`xuuuuuuuuxwwrr}vvvvvvvvv??+|                 .-^^^^^|||||~-~|+>>>>>>????>`<br></br>
`uuuxzzw}vvvvvvvvvvvvv??>+>?>^..~`.        .`-~~~~~~~~~~~||||||||^++>>>>>>`<br></br>
`w}}vvvvvvv?????????>>+++^+??>^?v??^-`. .-~|||||~~~~||||~~~|||||~~~||^>>??`<br></br>
`vvvvvvvv?>>>>>>>>>>++++>+++>>?vv?>??+~~^^^^||||~~~~~~||||||~~|||||~~||||^.<br></br>
`vvvvv?>>>>>++>+^+++++^++^^^+?vvv?>?>>~^^^^^^||>??????>>>|||||~~~~~~||||||.<br></br>
`vvv?>>>>>>+?wz}?>>>>???>+^++?rzzzrv>|^^^^^^|+????v?vvvvv?||||||||~~~~||||.<br></br>
`??>>>>>>+|^>?rxuuxzxuuuur??+vuv~?}wv^^^^^^^||>+?v??v??v?>?^||||^^^||~~~~|.<br></br>
.>>>>>>+||^^^||>vvvvvvvv??v?+zz?|`.?v++^^^^^^|^>^++??+>?^+??^|^^^^^|||||~|.<br></br>
.>>>>>?^|^^^^^^^~||||^>~~?v?vyuxww?}v?>>>++^^^~^+^+^+^^++++?v>^^^^|^||||~~.<br></br>
.>>??vv^|^^^^^^^^^|~-|+||+?vrxuuzuurvwwr}v?>+^|^+^++>>>+>>>??v?|~~~~~|~~~~.<br></br>
.?vvvv?^|^^^^^^^^^^|~~|`~|^+^??}}rr?.~?v}v?^|||~~~~~~|^+++++>?vv^-~~~~~~~~.<br></br>
`vvvvv?||^^^^^^^^^^^^+>~--~|-`|^++>~.--|+|||^^^^^^^^^|~~+>+>>vvvv+~~~~~~~~.<br></br>
`vvvvv+|^^^^^^^^|^^^^+>^^^|||-..```..~.`|^^^^^^^^^^^^^^^|>>+>?vvvv?|~||||~.<br></br>
.>???^-|^^^^^^^^|^^^^+>^^^^^|^|`..```|-~||^^^^^^^^^^^^^^|^>>>>vvvvvv>|||||.<br></br>
.+++-.`^^^^^^^|~|^^^^+>^^^^^^^^^^^^^+>^^^^^^^^^^^^^^|~^>??>>>>?vvvvvv?+|||.<br></br>
.+|`...|^^^^~-``|^^^^>+^^^^^^^|^^^^^>^^^^^^^^^^^|--`->vvvv?>>>>vvvvvvvv?^|.<br></br>
.`.....``-``...`^^^^^>^^^^^^^^|^^^^^>^^^^^^^^^^|...^vvvvvvv>>>>?vvvvvvvvv+.<br></br>
 ...............|^^^+>^^^^^^^^|^^^^>+^^^^^^^^~^~..-?vvvvvvv>>>>?vv?vvvvvvv`<br></br>
.~```...-.......`-~|>+^^^^^^|-|^^^^>^^^^^^^|``^-..^>???vvvv?+>>>vvv>?vvvvv-<br></br>
-uzwwwrwx|.........`^~~~---`.`|^^^>+^^^^|~-`.~^`.`>?>>>>>>+++>>>vvv?+?vvvv-<br></br>
-uuuuuuuy}.`--`....--..........`~^+||~~``....``..`>vv???????>>>>?vvv>+?v?v-<br></br>
-uuuuuuuuy>.|^^|-``|`...........`-................^?vvvvvvvv?>>>>?vv?++??>`<br></br>
-uuuuuuuuyu^`|^^^|^+|``--`......-`..-~>}r+`.......`++>??vvvvvv?+>>>??>+>?+.<br></br>
-uuuuuuw+~~-.`|^^|>^^^^^^``~|~-~-.`vxuuuuuzv??????vvv????v????>+++>>>>>+++.<br></br>
-uuuuuuuzv^||~~^^^>^^^^^~-^^^^+>^^ruuuxxuuuuuuuuxxxxxxxxxxz}v???>++>>>>?vw~<br></br>
-uuuuuuuuyuz}>+^|+^^^^^^|^^^^^+~+wuuuuuuuuuuxxxxxxxxxxxxzzzxxxzzw}}}}rrwwz|<br></br>
-uuuuuuuuuuyuv???>>>+^^^^^^^^>~`.-?wuuuuuuxxxxxxxxxxxxzzzzzzzzzwwwwwwwwwww~<br></br>
-uuuuuuuuuxwrvvv??vvvv??>>>>+>>vv}wzxxxzxxzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}}}}}}vv?+|||||-                               ....``--------.<br></br>
-uuuuuuuuuuuuuxxxxxzw}?>++++.                            .~|-```--~||~||~~.<br></br>
`xuuuuuuxxxxxxwwwwwr}v??>++|                            `+^|^^^|~---~-----.<br></br>
`xuuuuuuuuuuuxwr}}}v??>>>>>`                         ..`-|^^+>>>++^|~-----.<br></br>
`xuuuuuuuuuuuuxzr}vv??>??>>.                       .`--```~+>>>>>>>>++^|~-.<br></br>
`xuuuuuuuuuuuuxxzwr}vv??>>+.                    .`````-~~~||^^+>>>>?>>>>>>.<br></br>
-uuuuuuuuuuuuuxxzzwrvvv???>.                ...`````-~~~||||||||^^+>>????v-<br></br>
-uuuuuuuuxxxzwwrr}vvvvv???>`  ~+|-..    ..`````--~~||||||~||||||||||^+>???-<br></br>
-xxzzwwrrrrr}rrvv?vvv}r}}v?+-~>>++||-.-~--~~~~~~~||||||||||~~||||~||||||||.<br></br>
`rr}}}}}rrrrrv??>??v?v}xzxv|?v??+|||-~|||||||||||^|~~|||||||||~~~~~|||||||.<br></br>
`vvv????vvvvv??>>?????}xuz?^?}wrrv?||^^^^^^^^^|^^^^||~~~||||||||||~~~|||||.<br></br>
`v??>++++++>??>>??>>>vzw?v?+?uv|>vv?++^^^^^^^^|^>>>>>+^^|||||||^^|||||~|||.<br></br>
.>+++^||^^++^+>++++^^+?^^v?+wuv?+-??>+^^^^^^^+???v??vvv?+~||^^^^^^||||~~||.<br></br>
.+^^^||||||^||||^||||^>^^???uxwvrr}}rvv??>+^^??+?v>vv???>+~|^|||~||||||~~~.<br></br>
.++?>^|^^^^^^^^^^^^|-~|-^|>vwwxrww>->}rr}?>|~^||++|>>^?>|?+~~~~~~~~~~~~~~~.<br></br>
.???^||^^^^^^^^|^^^^|+|-~~|>>>vv??~.-|>?+^|~~~||~|~~~~|~-|+>^~~~~~~~~~~~~~.<br></br>
.>>+|^|^^^^^^^^^^^^^^>^||~~||~~^|~`.-`-|^^^^|~~~||~~~~~~|~|>?+~||||||~~~~~.<br></br>
.++^||^^^^^^^^^|^^^^+>|^^^||||`....`-`-^^^^^^^|||~~--~|~~~~+??^||||||||||~.<br></br>
.+^|^|^^^^^^^|||^^^^>^^^^^^^|||~~~~+||^^^^^^^^^^^^||~-~||||>???+||||||||||.<br></br>
.^--|^^^^^^~~||~^^^^>^^^^^^^^^^^^|+>^^^^^^^^^^^^^^|-`|+|||^+?vvv?>>^^^||||.<br></br>
 ...~||||||-~-`|^^|++^^^^^^||^^^^^>^^^^^^^^^||^|```->?v>>>>>?vvvvvv?++^^^^.<br></br>
 ...```----``.`|^^^>^^^^^^|~~^^||>+^^^^|||^|`|^`.`~?vvvv?>>???vvvv}}v??>++.<br></br>
.~-`-`---.....`-|~^>|^^^^|-``|||++|^^^||||~`~^~``~>?vvvv?>>?>?vvv?v}}}vv?+.<br></br>
`zr}}}}}r>`-`...``|~~~~~-`..`~|^>^^^^||~~`.`||-`->?>????>+>>>>vvv?v}}}v??+.<br></br>
-uyyyyyyyu?^^-``..-.........`-|>^|||~~`....-~-``~?vv???>++>>>>?vv>?vv}vvv?`<br></br>
-uuuuuuuuuz>|^^^~|~```........~-``````||`.....`.-?vvvvvvv?++>>>?v?>???vvvv-<br></br>
-uuuuuuwv?>~`|^^|>^^^|-``-`..~~~|^>?}wzzrv>-````-++??vvvvv??>>>>?v?>>>}vvv-<br></br>
-uuuuuux}?+|~~||++|^^^--|||^++?zxxuuuuuuuuuzwr}}v??+>????vvv??>>>??>+?wr}}-<br></br>
-uuuuuuuux}>>+||>^^^^|~||~|+?zuuuuuuuxxxuxxxxxxzzzzwrv?>>>>?>>>+>>>?vrwzzz|<br></br>
-uuuuuuuuxr?v??+>^^^^|~~+++^|}uuxxuuuuuuuuxxxxxxxxxxzzzwrvvvv????vv}rrrwzx|<br></br>
-uuuuuuuur|~?v?>????>^|~-|^^+>}uuuuuuuuuuxxxxxxxxxxxxzzzzzzzzwwwwrrrwwwwwz|<br></br>
-uuuuuuxzv+^^+^+?>>>>+?+-^?zxzzzxxxxxxxxzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrr}}}}}}}}}}}}}}v?>^||^^||^`                                  .----```` <br></br>
-uuuuuuuuuuuuuuuxxzwwv>++++++-                                 .-~||||~|~~.<br></br>
`xuuuuuuuxxxxxzxzzzwrv?>++^+-                                .`--~|-~~-~~|.<br></br>
`xuuuuuuuuuuxzrrrrr}v??>>++|                               .|>+|----------.<br></br>
`xuuuuuuuuuuuxzw}vvv?>>?>>+.                             `^>>??>>>+|~-----.<br></br>
`xuuuuuuuuuuuuxzwrvvv???>>^                           .`-|+>>>>>>>???>+|~-.<br></br>
-uuuuuuuuuuuuuxxzwr}vv??>>-                        .`-|||||||^>>>>>>>>??v?`<br></br>
-uuuuuuuuuuuuuxxxzw}vvv???-                     .`~||~~||||||||~|^+>???vv?`<br></br>
-uuuuuuuuuuuuxxxxxzwr}vvv?`  .             ..`-~~|||||||~~|||||~~~||^+>>>?`<br></br>
                  -xxxxxuuuuuuxxxxxxxxxzzwww|.~??^`..  ...`~~|||||||~~|||||||~~|~~|||||||~~|.<br></br>
.>+++^+>?}zuuxxxxxxxxxxxxx??vv???>^-|^^^^^^^^|^^^^~~~~|||||||||~~~|||||||~.<br></br>
.|||||||||^?ruuuuuuuuuuuxv?vvv?>>+~++^^^^^^^^^^++^^^|~||||||||||||~~~|||||.<br></br>
.^^^^^^^^^^|^?r}}vvv?vv??v>vx}rwv>+>+^^^^^^^^^^^+++++++^|||^^^^^^^||~~||||.<br></br>
.^^^^^^^^^^^^^||^^||^>~|v?^rr|~^?rwv??>>++^^^^^^^+>>>>+^|||^^^|||||||~~|||.<br></br>
.^^^^^^^^^^^^^^^^^^^+>~+v??uxw}?v+?rwrr}?>+^^^^^^^^^^^|^||||~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^|~-^|-^?>?wxxxx?..~?v}v?+^^^^^^^^^^||||||||~~~~~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^^^^^^~+~`-^++>???v|.--|^~|^^^^^|^^^^^^^^||||||||||~~~~~~~~~~.<br></br>
.^^^^^^^^^^^^^|-^^^^>^^~-|-`---`.``.`-||^^^^^^^^||^^^^^^^||||||||||||||||~.<br></br>
                  .|^^^^^^^^^^~-.-^^^++^^^^||~``--~^|||^^^^^^^^^^^^^|^^^^^^^^^^^||||||||||||.<br></br>
 `~|^^^^^|-`..`^^^^>^^^^^^^^^^^^+^^^^^^^^^^^^^^^^^|~^+++^+>++>>>>+^|||||||.<br></br>
 ...````.....`|^^^^>|^^^^^~`^^^^+^^^^^^^-~^^^^^^||^^|^+^?vvvvvvvv?>+^^^^^|.<br></br>
 `.....``....-^^^^>+^^^^^^`.|^^+^^^^^^^-.~^^^|||>?vvv?>?vv?vv?vvvv}v?>++^^.<br></br>
`wr}v}}zr~-`..``-~+||||||-.`|^+^^^^^^^-..~^^|~~?vvvvvv??v??v??v?v??r}v?>^^.<br></br>
-uyyyyyuyr^^|-`..--........-|^^^^^^|~`..`^^~``>vvvvvvv?+++>?>???v++r}}v>++.<br></br>
-uuuuuuuyy}|^^^-`~..........----~-`......``..^>?vvvvvv++^^^+++>+>+>v}}v???`<br></br>
-uuuuuuxv?+`~^^^+|--~-.....``..`....`.......~>++>??vvvv?++^+++++>>vvv}vvvv-<br></br>
-uuuuuuuw?^~-||^>|^^^-`-|~~``>rr}}}rw}v???>`|???>>>>>??>+++>++??vvvv?vvvvv-<br></br>
-uuuuuuuuxr?>^|>^^^^^~|^^^+vzuuuuuuuuuuuuuuwv>>???????>>++>>>>v?vvv??}r}}v-<br></br>
-uuuuuuuur~>vv>>>+^|~~~-`.`vuuxxxuuuuxxuuuuuuxwv??????>>>+>>>??+?v?+vwzzzz~<br></br>
-uuuuuuuu}^^>?>???>|+|``?vrwxxuuuuuuuuxuuuuxxxxxxxzw}v??>++++>++>>+?rwzzzx|<br></br>
-uuuuuuuv>~-??^>?>?>+|`~>}uuuuuuuuuuuuuuuxxxxxxxxxxxxxxxz}vvv}vv???rwwwwwz|<br></br>
-uuuuuu?+>+^>~.~|^++v+~~>wuuxxxxxxxxxxxxzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrr}}v>++++++^++^||||||||||||^^^^|~````.                      <br></br>
-uuuuuuuuuuuuuuuxxwv?>++>>+++++++++>++++>>++++++|-`.                       <br></br>
-uuuuuuuuxxxxxxxxzzwv>++++^^^^^^^^^+++++++++^^|-.                          <br></br>
-uuuuuuuuxxxxxxxzzzw}?>>+^^^^^>^|~|^++++^^^~-`.                            <br></br>
-uuuuuuuuuuuxzrrrr}v??>>+++^^+??+~||||||^|-.                               <br></br>
-uuuuuuuuuuuuxzr}vv??>??>+++++vv>|||||~``.                                 <br></br>
-uuuuuuuuuuuuuxzw}vv???>>>++++>v?|||~`.                                    <br></br>
-uuuuuuuuuuuuxxxzwrvvv?>>>>++++>?+-.                                       <br></br>
-uuuuuuuuuuuuxxxzzrvvv????>>+++|`.                                         <br></br>
-uuuuuuuuuuuuxxxxxzwr}vvvvv?>~.                                            <br></br>
                  -uuuuuuuuuuuuxxxxxxxxxzzxwv|.                                      .....``.<br></br>
-uuuuuuuuuuuuxxxxxxxxxxz>`                                 ...```-~|||||~~.<br></br>
                  -uuuuuxzwrrrwzxuxxxxxxx~                          ...``-~~||^|||||~||||||~.<br></br>
`uuz}v>+++++++?ruuuuuu?                    ...`~|^+++^||^^^^^^^|||~~|^||||.<br></br>
`}v+++++++++++++?vvvvv~`^-.       ..-~|||~||^^^^++++^^^||||||||||||~~|||||.<br></br>
`?^^>>++++++++++^|+^|^+??>>|~`|+>??vv}v?>+^^^^^^^^|||||||~~~~~~~~~~~~~~~||.<br></br>
.>`~++^^^^^^^^+++++>??>??++^~-^~^+>?v}v>>+++++^^^^^|||||||||~~~~~~~~~~~~~~.<br></br>
`?``|^^^^^^^^^^^^^^?v??rvv?>-`|~|^^^>??>>>>>>>^^^^^^^^|||||||||||~~~~~~~~~.<br></br>
`>`.`|^^^^^^^^^~``->v?vz?>?v-`-^+^^^^|>>>>>+++++^^^^^^^^^^|||||||||||||||~.<br></br>
.>~...`-~||||~~^|^--+?zuzwr>.``|^+^^+~>???>>>>++++++^^^^^^^+^^^^^|||||||||.<br></br>
`zw?^-`...```.`^|+^|||+>???~```~++++^`?}v???>>>>>+++++++>?v}}v?>+^^^^|||^|.<br></br>
-uuuuzr?+.....`^^^^^++^~---~^^^^^++^-.+vv????>>>>>>++++>>vwzwwr}v?>++^^^^^.<br></br>
-uuuuuuuu>~-...`^|^^^^|-^^^^^|~``~~``+v?vv??v???>>?>+>>>?vrzwwwwr}v?>+^^^^.<br></br>
-uuuuuuuxw^|~`.`````````|||-`......`^>??>?v?vv???vv?>?}}rwwzwwwwr}}v?+++++.<br></br>
-uuuuuuuz?~~^||~``````....-~+???vvvvvvv?>+>>>>>????}zzzzzzzwwwrr}}vv??>>>>`<br></br>
-uuuuuuuuz+|^^^||||~-`.`^vzxuuuuuuxxxzzwwr}}rwwwzzzzzzzzzzwwwrr}}}vvvv????-<br></br>
-uuuuuuuux^^?>+^|~-`.`|>xuuxxxxxxxxxxxzwwzzzxxxxxxzzzzzzzwwwrr}}}}}vvv????-<br></br>
-uuuuuuuur-|>>>?>^|-`ruuxxxxxxxxxxxxxxxxxxxzzzxxxxxxzzzzzzzwwwrr}rrr}}v???-<br></br>
-uuuuuuuv>||-~^^|||^?xuuxxxxxxxxxxxxxxxxxxxxxzzzxxxxxxxxzzzzwwwwrrwzzzzw}}~<br></br>
-uuuuyuv+>>>^~|~~-|vyuuuuuxxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzzwwrrrrrrrwzzzzzz|<br></br>
-uuuur?>>>>>>>>>>>>}xuuuuuuuuuuuuuuuuuxxxxxxxxxxxxzzzzzzzzwwwwwwrrrrwwzzwz|<br></br>
-uuyr^+>>>>>>>>>>>++vuuuuuuuxxxxxxxxxxzzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrr}}v??>^|~|>+~--------~~~~|||~-```..                        <br></br>
-uuuuuuuuuuuuuuuxzv?>>>>>>>?>^^^^++++++++++>+^-`.                          <br></br>
-uuuuuuuxxxxxxxxxzrv>++>>+^++++++++++++++++^|`                             <br></br>
                  -uuuuuuuxxxxxxxxxzzrv+++^^^^^^^^^^+++++++^-.                               <br></br>
-uuuuuuuuuxxxzzzzzw}v?>++^^^^?+|~|^^^+^~`                                  <br></br>
-uuuuuuuuuuuxwrr}}vv?>>>++^^+??>~|||~`.                                    <br></br>
-uuuuuuuuuuuuxzr}vv?>??>>++++?v?|||`.                                      <br></br>
-uuuuuuuuuuuuxxzw}vv??>>>++++>??|`.                                        <br></br>
-uuuuuuuuuuuuxxxzw}vvv???>++++^|.                                          <br></br>
-uuuuuuuuuuuuxxxzw}vvv???>>>+-.                                            <br></br>
-uuuuuuuuuuuuxxxxxzzwrr}}r}^.                                          .`` <br></br>
-uuuuuuuuuuuxxxxxxxxxxxxu}~                                  ..```------||.<br></br>
                  -uuuuuuuuuuuuxxxxxxxxxuz+                            ..`-~~|~~~~||||||~~|~.<br></br>
                  -uuuuuuuuuuuuxxxxxxxxuv`                      .`-~|^||^^^^^^^|||~||||||~||.<br></br>
-uuuuuuuuuuuuuuxxxxxx+                   .`-~|+>>>>^|||^^^^|^|||~~||||||||.<br></br>
-uuuuyuw}v}}wxuxxxzx+              .`|^^|^^^^^^^^^^^^|||~~~~~~~~~~~~|||^||.<br></br>
-uuxw?+^^^^^^+?xuxuz.          .-+?v}v?>++^^^^^^^||||||||~~~~~~~~~~~~~~|||.<br></br>
`}}v||^^^^^^^^|+>>>?|>+-...`^?vv}}vvv?>>>>>>+^^^^^^|||||||||~~~~~~~~~~~~~~.<br></br>
`?+~-^^^^^^^^^^^||+>?v??+~??}rrr}vv??>>>>>>>++^^^^^^^^|||||||||||||||||||~.<br></br>
.??~.~^^^^^^^^||^+?^vvv?^`--^+>vvvv}}???>>>+++++++^^^^^^^^^||||||||||||||~.<br></br>
.??+..~^^^^^^^~``|?+zv?v>.`|^^^^vwwwwr}v??>>>>+++++++++>?vv??>^^^^||||||||.<br></br>
.>>>~..`--~---^|~~~>rzwr`.`~^^^|`?r}vvvv??>>>>>>>+++++>?rzwrrv?>+^^^^^^^^^.<br></br>
-xzwwv+~-`...`|+^^^~~||^~|^^^^^-.>vv}}r}vv????>>>>>>>>>?rzzwwwr}v?>+^^^^^^.<br></br>
-uuuuyyuuv~`..`~||||~|^|^^|--~`.|>???vvrwwrv>+???????vvrwzzwwwr}}v?+++++^^.<br></br>
-uuuuuuuzv||`.`...`..-```....`-|??>??vvvvvv?+?}vvrwwzzzzzzwwwr}}}v?>>>>+++.<br></br>
-uuuuuuuz?~|||~---``..`^>?vv}wzrvv?>>>??????rzwwwzzzzzzzwwwrr}}}}vv?????>>`<br></br>
-uuuuuuuux^++^||~--..?zuuuuuuuuuuuuzrrrwwzzuxxxxzzzzzzzzwwwr}}}}}vvv???v??-<br></br>
-uuuuuuuyz~^>>?>^~.+?zuxxxxxxxxxxxxuuuxxzzzzzxxuxxzzzzzzzwwwr}}rrr}}v?????-<br></br>
-uuuuuuyz?~|-|^^|~>xuuxxxxxxxxxxxxxxxxxxxxxzzzxxxxxxxxzzzzwwwrrrwzzzwrrv??-<br></br>
-uuuuuyw>>>>|^|~`?uuuuuuuxxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzwwrrrrrrrwzzzzzzrr~<br></br>
-uuuyz?>>>>>>>>>^?xuuuuuuuuuuuuuuuuuuxxxxxxxxxxxzzzzzzzzwwwwwwwwwrwwzzwwww~<br></br>
-uuuw?+>>>>>>>>>>+?zuuuuuuuuxxxxxxxxzzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrr}v?>^||^^|||~~~~------~^-~||~`                          <br></br>
-uuuuuuuuuuuuuxuxxw}v?>+^??^||||~~~||||^^+^~~-`.                           <br></br>
-uuuuuuuxxxxxxxxxw?>>>>>+>?>^^^^++++++++++^`.                              <br></br>
-uuuuuuuxxxxxxxxxw}?>+>>+^++++++++++++++|`                                 <br></br>
-uuuuuuuuuxxxxxxzzw}>++^^^^^^^^^^+++>^~.                                   <br></br>
-uuuuuuuuuxxxzzzzwrv?>++^^^^?>|~|^^|-.                                     <br></br>
-uuuuuuuuuuxxwrr}}v??>>+++^+?v>||~.                                        <br></br>
-uuuuuuuuuuuuxwrvv??>?>>++++?v?~`                                          <br></br>
-uuuuuuuuuuuuxxzr}vv?>>>>++++?^                                            <br></br>
-uuuuuuuuuuuuxxzwrvvv???>>++~.                                             <br></br>
-uuuuuuuuuuuuxxxzwr}vv????>`                                             . <br></br>
                  -uuuuuuuuuuuxxxxxxxzzwwwz?.                                   .....`-~|~-~.<br></br>
                  -uuuuuuuuuuuxxxxxxxxxxux+                            ...````-~|||||~-~~~|~.<br></br>
                  -uuuuuuuuuuuuxxxxxxxxuw-                      ..``-~||^^^^||~~|||||||~~~||.<br></br>
-uuuuuuuuuuuuuxxxxxxuv.                   .`~^+>++^||^^^^^^|||~|^||||||~~|.<br></br>
                  -uuuuuuuuuuuuuuxxxxuv.              .``-~|^^^++++^^|||||||||||~~||||^|||||.<br></br>
-uuuuuuuuxxxuuuxxxx}.           .~+?v?++^^^^^^^||||||~~~~~~~~~~~~~~|||||||.<br></br>
-uuuuur?>>>>?vzuxuz`        .-+?v}}v?>>++>+^^^^^^||||||||~~~~~~~~~~~~~~~~~.<br></br>
`xxz}+^^^^^^^|+vv}?.-`.  .~?}wr}vvv?>>>>>>>+^^^^^^^||||||||||~~||~|||~~~~~.<br></br>
.vv?|~^^^^^^^^^|||>??>>~^?}rr}vvvvv?>>>>>++++++^^^^^^^^||||||||||||||||||~.<br></br>
`??>``^^^^^^^^|^^?>?v?>~-~|+?wrvrwww}v??>>>>+++++++^+++>>>>+^^||||||||||||.<br></br>
.???~.`~^^^^^^---?>zv??`.~^^|>rr}}}vv}v??>>>>>>++++++?vww}v?>+^^^^^^^^^^^|.<br></br>
`zv??|...`---|^|~~^}rr>.`~|^^|>}}vvvv}v?????>>>>>>>>>?}zzwww}v?>>+^^^^^^^^.<br></br>
-uyuuuwv?~`..-^^^^|~~~~|^^^^^~^?}v}}}rwr}vv???>?????vvrzzwwwr}}v>+++++++++.<br></br>
-uuuuuuyy}|-..`--~~`|-~|~`--`^?>>?vvv}}v+vr}vvvvrwwzzzzzwwwwr}}v?>>>++++++.<br></br>
-uuuuuuux?~||```......`~~^>>??v?>>??????vwwwwwwwzzzzzzzwwrr}}}vvv????>>>>>`<br></br>
-uuuuuuuuz^^^||~-~``>vzxuuuuuxxxw}}}}rrwzxxxxxzzzzzzzzwwwr}}}}vvv???v?????-<br></br>
-uuuuuuuux|++??^-.~?uuuuxxxxxxxxuuuuxxxzzzxxxuxxzzzzzzzwwwr}}rr}}v????????-<br></br>
-uuuuuuux?~~-^^^~+xuuuxxxxxxxxxxxxxxxxxxxxzzzxxxxxxxxzzzwwwrrrwzzzwr}v????-<br></br>
-uuuuuyz?>>>^^|-+uuuuuxuxxxxxxxxxxxxxxxxxxxxxxxzzzzzzzzwrrrrrrwwzzzzzzr}vv-<br></br>
-uuuuu?>>>>>>>>>+wuuuuuuuuuuuuuuuuuuxxxxxxxxxxxzzzzzzzwwwwwwwrrrwwzzzwr}}}~<br></br>
-uuur?+>>>>>+++>+>vzuuuuuuuuxxxxxxxzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwwwrrr~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrr}vv>+^||||||||~``--|~||||||~.                           <br></br>
-uuuuuuuuuuuuuuuuuxzwv?++>++++^^^^|~~~~|>^^^~`.                            <br></br>
-uuuuuuuuxxxxxuxxzr}v>+^>?^|||~~~~~|||^^^~`.                               <br></br>
-uuuuuuuuuxxxxxxzv?>>>>+>?>^^^^^^++++++|`                                  <br></br>
-uuuuuuuuuxxxxxxzrv>++>+^^+++++++++++~.                                    <br></br>
                  -uuuuuuuuuxxxxxxzzr?+++^^^^^^^^^+++~.                                      <br></br>
-uuuuuuuuuxxzzzwww}v?>+^^^^>>|~|^~.                                        <br></br>
-uuuuuuuuuuuxwr}}v??>>>++^^?v>|-.                                          <br></br>
-uuuuuuuuuuuuxwrvv????>>+++?v?`                                            <br></br>
-uuuuuuuuuuuuxxzr}vv?>>>++++^.                                             <br></br>
-uuuuuuuuuuuuxxzw}vvv???>>^`                                               <br></br>
-uuuuuuuuuuuxxxxxwr}vv?v?^.                                    ..```-|^+>?`<br></br>
                  -uuuuuuuuuuuxxxxxxxxxzzz+                             ...```-~~~~~|^^|~||+`<br></br>
                  -uuuuuuuuuuuxxxxxxxxxuz|                        .`-~~|||~~~||||||~~~~|||~~.<br></br>
                  -uuuuuuuuuuuuxxxxxxxuw`                   .`-~|^||^^^^^^|||~~|||||~~~~||||.<br></br>
-uuuuuuuuuuuuuxxxxxur.               ..`-~^>>>>+^||^^^^^^|||~||||||||~||||.<br></br>
-uuuuuuuuuuuuuuxxzxw`            `|>>^^^^^^^^^^^^|||~~~~~|||~~~~||^|||||||.<br></br>
-uuuuuyuxxzxuuuxxxz-         .~>?v}v>++++^^^^||||||||~~~~~~~~~~~~~||||||||.<br></br>
-uuuuzv>++++?vxxxu>       .^?}r}vvv>>>>>>+^^^^^^||||||||||~~~~~~~~~~~~~~~|.<br></br>
`wzrv||^^^^^^|>???|^~`..^vrr}}vvv??>>>>>>++^^^^^^^^|||||||||||||||||||~~~~.<br></br>
.??>~-^^^^^^^^^^|>?v?>|+?vrzw}v}rw}???>>>++++++^^^^^^^^^^^^||||||||||||||~.<br></br>
.???|.-|^^^^^|~|>??v??~`-|^>wwrrr}}}}???>>>>+++++++>?}}v?>+^^^^|||||||||^>`<br></br>
`}?>+...`~|^^|~-~+wz}?`.~^^|>rr}}vvv}????>>>>>>>++>?rzwww}v?>++^^^^^^^^^+^.<br></br>
-uuxzr?^~`..`|^^|~^>?~`~|^^^`?}w}}}rrr}v????>>?????v}zzwwww}v?>+++++^+++++.<br></br>
-uuuuuyyuv~..`-||^-~~~^^~~-`~>??vvv}rv>v}}vv?v}}rwwwwzwwwwr}}v>++++++++++>`<br></br>
-uuuuuuuuv||`...``.`.````--|??+>?????>?wwrwwrwzzzzzzzwwwrr}}}v????>+>++++>`<br></br>
-uuuuuuuuw^^|~~~-`.-+vwwzxxzzz}??vvvvrzzxxxzzzzzzzzzwwwr}}}}vvv???v????>>?-<br></br>
-uuuuuuuux|^+>^|``+ruuuuuxxuuuuuuuuxxxzzxxxxxxxzzzzzzwwwr}rrr}}v??????????-<br></br>
-uuuuuuuuv~~~|^~~vyuuuxxxxxxxxxxxxxxxxxxzzzxxxxxxxxzzzwwwrrwwzzwr}vv??????-<br></br>
-uuuuuuu?>>+|^~`vyuuuuuuxxxxxxxxxxxxxxxxxxxxxzzzzzzzzwrrrrrrwwzzzzzw}vvv?v-<br></br>
-uuuuuv>>>>>>>>+>xuuuuuuuuuuuuuuuuxxxxxxxxxxxzzzzzzzwwwwwwwwwwwzzwwwr}vv}}~<br></br>
-uuur?>>>>>>^^+++?vzuuuuuuuuxxxxxxzzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwrrrrw~<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrrrrrrr}?>^|||||||~``--+>>>>>>+>+-.                            <br></br>
-uuuuuuuuuuuuuuuuuuxwv>>+++++++^|~~~>+^++++^`.                             <br></br>
-uuuuuuuuuuuuuuuuxxw}?+++++++^^^||~~~~|+|-.                                <br></br>
-uuuuuuuuuxxxxuxzw}v?+^+?+^||||||||||^|`                                   <br></br>
-uuuuuuuxxxxxxxxw?>>>>>>?>^^^^++++++^`                                     <br></br>
-uuuuuuuxxxxxxxxz}?++>>^^+++++++++^`                                       <br></br>
-uuuuuuuxxxxxxxxzw}>++^^^^^^^^^+^`                                         <br></br>
-uuuuuuuuuxxzzwww}v?>++^^^>?^||-.                                          <br></br>
-uuuuuuuuuuxzwr}v??>?>+++^?v?~.                                            <br></br>
-uuuuuuuuuuuxxwrvv???>>+++>?^   .                                          <br></br>
-uuuuuuuuuuuxxzwrvv??>>>++^`                                               <br></br>
-uuuuuuuuuuuxxxzwvvv???>>|.                                     ..`-~~~``` <br></br>
-uuuuuuuuuuuxxxxxzwr}v}}^                             ....``-~|^^^++>>??+|.<br></br>
                  -uuuuuuuuuuxxxxxxxxxxxx+                        .``---~||||||~~-~||~~~~^^^.<br></br>
                  -uuuuuuuuuuuxxxxxxxxuz~                   ..`-~||^^^^|||~~|||||~~~~||~~~|^.<br></br>
                  -uuuuuuuuuuuuxxxxxxuw`                .`-^++++^||^^^^^^||~~|||||~|~~|||~||.<br></br>
                  -uuuuuuuuuuuuuxxxxxz`            .`-~|^^^^++++^^||^|||||||~~||||||||||||||.<br></br>
-uuuuuuuuuuuuuxxzzz~          .|?vv>+^^^^^^^||||||~~~~~~~~~~~~~|||||||||||.<br></br>
-uuuuuuuxzwzxxxxxu>        `+?v}}v?>>+>>+^^^^^|||||||~~~~~~~~~~~~~~~~|||||.<br></br>
-uuuxwv?+^^+>vzwzv.     `+?}r}vv??>>>>>>+^^^^^^^||||||||||||||~~~~~~~~~||~.<br></br>
`}rv?|~^^^^^^^^^^>>>|`~?rzwrvvvv}v??>>>++++++^^^^^^^^|||||||||||||||||~~+|.<br></br>
.???+.~^^^^^^^||??v?>~~|+?rwr}rwww}v???>>>+++++++>>????>^^^|||||||||||^+>^.<br></br>
.???>..-|^^^^~`->>w??|.~|^^vrr}}vvvv???>>>>>>++++>vwwwrv??+++^^^^^^|^++^^|.<br></br>
-uurv?~..``--^|~`^vv>.`~^^|~vrr}v}rr}v????>>>>>>>?vwzwwwr}v?>++^^^^+^^^^^^.<br></br>
-uuuuyuw}+`..~|^^~-~-~^|~|`-??}r}}}}v}}vvv??vvv}rrwwzwwwr}}?>+++++++++>>+>`<br></br>
-uuuuuuuyv|~`..`-``-`-`..`->>>>??v?>>}rrrrrrwzzzzzzzwwrr}}vv????>+++++++>?`<br></br>
-uuuuuuuuw^^|--``..~>v}rrrwwr????vvvwzxxxzzzzzzzzzwwwr}}}}vvv???????>>>??v-<br></br>
-uuuuuuuux|^^^~-`-?uuuuuuuuuuuuuuxxxxzzxxxxxxzzzzzzwwwr}rrr}vv???????????v~<br></br>
-uuuuuuuuv~~~^|-^zuuuxxxxxxxxxxxxxxxxxxzzzxxxxxxxzzzzwwrrwwzzwr}v?????????-<br></br>
-uuuuuuu?+>+|^~~xuuuuuxxxxxxxxxxxxxxxxxxxxxxzzzzzzzwwrrrrrwzzzzzzw}vvvvv??-<br></br>
-uuuuur>+>>>>>>+vuuuuuuuuuuuuuuuuxxxxxxxxxxzzzzzzzwwwwwwwwwwwzwwwwr}vvvvvv~<br></br>
-uuur?>>>>>+|^++>?vwuuuuuuuuxxxxzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrw~<br></br>


            </div>,

            <div>

                  `rrrrrrrrrrrrrrrrr?+^||||||~-``-??+||~--``````````````.                    <br></br>
-uuuuuuuuuuuuuuuuux}?>^+++++^~--|>?vv??????^~------``.                     <br></br>
-uuuuuuuuuuuuuuuuuxzv>+^+++++^|~~-^?>++>>>>|~----`..                       <br></br>
-uuuuuuuuuuuuuuuuxzwv>+++++++^^^~~~~~^+^+++^|~-`.                          <br></br>
-uuuuuuuuxxxxuuxzr}v>+^?+^|||||||||||^^~^^^^~`.                            <br></br>
-uuuuuuuuxxxxxxz}?>>>>>?>+^^^++++++++++^|-`.                               <br></br>
-uuuuuuuxxxxxxxzrv>++>+^++++++++++++++++-.                                 <br></br>
                  -uuuuuuuuxxxxxxzzr?++^^^^^^^||++++++^~`.                                 . <br></br>
-uuuuuuuuuxxzwwwrv??>+^^^>?^~||^^^^~.                                .`-`` <br></br>
-uuuuuuuuuuxzr}vv?>?>>++^>v?||||~-.                               .|>??+|- <br></br>
-uuuuuuuuuuuxzw}vv??>>++++??^^|`.                               .-~|||^^++.<br></br>
-uuuuuuuuuuuxxzw}vv?>>>++++?>~.                           ..~^^~~||~~|||^^.<br></br>
-uuuuuuuuuuuxxxzr}vv???>>++^`                          ..~>>>???vv??>^||^^.<br></br>
-uuuuuuuuuuxxxxxxzzwrrwrv?~.                       ..`-~||~|^^++^^+>?>^^+^.<br></br>
-uuuuuuuuuuxxxxxxxxxxxxu}-                     .`-~~~~|||||~~~~||~~~~^+++|.<br></br>
                  -uuuuuuuuuuuxxxxxxxxxxx?.                 ..`-||^^^^|||~|||||~~~~||~~~^^^~.<br></br>
-uuuuuuuuuuuuxxxxxxxuw|               ..~^+>+^||^^^^^|||~|||||||~||||||^^~.<br></br>
-uuuuuuuuuuuuuxxzzxu}`            ..`~|^^^^^^^||||~~~~||~~~||^|||||||||||~.<br></br>
                  -uuuuuuuuuuuxuxxxzxv.         .`^++++++^^^^||||||~~~~~~~~~~~~|||||||||||||.<br></br>
-uuuuuuuuuuuuxxxxx>        .~>vv?>>>>>>^^^^^^||||||||~~~~~~~~~~~~~~~||||||.<br></br>
-xuuuuyyuxzxuuuxz^       `+v}vv??>>>>>++++^^^^^^|||||||||||||||||||~~||~||.<br></br>
-uuuuuzv>+^+?rww^     `^vwzr}rww}v??>>>++++++^^++>>++^^^||||||||||||^>>||~.<br></br>
`rrr}?||^^^^^|-|?>^`^vzxzwwwrrrvv}v??>>>>>+++++>vww}}?>+^^^^^^^|||^+>^||||.<br></br>
.????+`|^^^^^|-|vv>~~|>vxxzwr}}v}}}v????>>>>>>>>vwzwww}v?>+^^^^^^^^^^^||||.<br></br>
`vv}}?|``-~-|^~-?v?~^^^^wuuuxzr}}rrrr}vv???v?vv}rwzwwwr}}?+++++++++>>++++>`<br></br>
-uyyyuuxr+`.`~^|~~-|~||-?vwzzxxw??rrrrrr}}}wzzzzzzwwwr}}v???>>+++++++>>??v~<br></br>
-uuuuuuuy}|~..```.`-``-+>>????v?>}zwzzzzzzzzzzzzzwwr}}}vvvv??????>>>???vv}~<br></br>
-uuuuuuuux|^~|~`.+rzwwwww}vv}rzzxxzzzxxxxxxzzzzzzwwr}}rr}vv??????????vvv}v-<br></br>
-uuuuuuuyr~|^~-|ruuuuuuuuuuuuuuuuxxxxzzzxxxxxxxzzzwwwrrwwwwr}vv???????????-<br></br>
-uuuuuuur>+^^~~zyuuuuuxxxxxxxxxxxxxxxxxxxxzzzzzzzwwrrrrrwzzxzzwr}vv?v?????-<br></br>
-uuuuux?+>>>+>+}uuuuuuuuuuuuuuuuxxxxxxxxxxzzzzzzzwwwwwwwwwzzzzwr}}vvvvvvvv-<br></br>
-uuuu}>>>>>?|++>vzuuuuuuuuuuxxxzzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwrrrrrwrrr~<br></br>


            </div>,

            <div>

                  `rrrrrrrrrrrrrrrv+^||||||~-`````|+^||~-``````````````..                    <br></br>
-uuuuuuuuuuuuyyuur?>+++++++~~~~>?>++^|||||~~~-----``.                      <br></br>
-uuuuuuuuuuuuuuuuxwv>+++++++|--|+>?v?>??>>|~~--``.                         <br></br>
-uuuuuuuuuuuuuuuuxzr?>++^+++^||~~^>+++>>>+|~-`.                            <br></br>
-uuuuuuuuxxxuuuuxxw}?++++++^^^|~~~~|+^^^^^|-`                              <br></br>
-uuuuuuuxxxxxxxzr}v>+^>>^|||||||||^^+^|||~`                                <br></br>
-uuuuuuuxxxxxxxwv>>+>+>>+^^+++++++++++^-.                                  <br></br>
                  -uuuuuuuxxxxxxxzr?++++^^+++++++++++++^`                                    <br></br>
                  -uuuuuuuxxxxxzzzwv?++^^^^+^||^++++^|`.                                  .. <br></br>
-uuuuuuuuuxzwwrrv??>+++^+?>|||||^|`.                              .`-`----.<br></br>
-uuuuuuuuuuxzr}v??>?>++++??^|||~`.                            ..-^+>+^|~~~.<br></br>
-uuuuuuuuuuuxzw}vv?>>>+++>?+^|~.                         ...``~~~~~~|^^^^~.<br></br>
-uuuuuuuuuuuxxzwvvv??>>+++++|`.                       `-+>??>>+++^^^||^^+^.<br></br>
-uuuuuuuuuuxxxxzwr}vvv??>+^|`                     ..`-|^+++>>?????>^^^+^^^.<br></br>
-uuuuuuuuuuxxxxxxxxxxxxwv>^`                   .`-~|||||~||||||||^+^^++^^^.<br></br>
                  -uuuuuuuuuuxxxxxxxxxxxuw?+.                ..-~|^||~~|||||~~~~||~~~^^+^||^.<br></br>
-uuuuuuuuuuuxxxxxxxxxz}?~               .-|^||^^^^^||~~||||~|~~|||~|^^|~|~.<br></br>
                  -uuuuuuuuuuuuxxxzxxxz?^.             .`|+++^||||||||||~~|||||||||||||||~~~.<br></br>
                  -uuuuuuuuuuuuuxxzzzw?`            .-~^^^^||||||~~~~~~~~~~~|||||||||||||~~~.<br></br>
-uuuuuuuuuuuxxxxxx}^.          .~+>>>+^^^^^|||||||~~~~~~~~~~~~~~~||||||~~~.<br></br>
-xuuuuuuuuuuuuxxxv-         .~>?>>>>>++^^^^^^^|||||||||||||||~~~~~||~||||~.<br></br>
-uuuuuuuzwrrzxxx?.      .-+?}rw}???>>++++++^^^^^+^^^^||||||||||||||+^^^|||.<br></br>
-uuuxzr?>^^^>?v?`...`~>v}wwrrr}}}v??>>>>>+++++>v}}v?>+^^^^^|||||^+++^|||||.<br></br>
`vvv??~~^^^^^~-+?>-->}xxxzwr}}vv}v?????>>>>>>>?rwwww}v?>+^^^^^^^^^^^||||||.<br></br>
`vvv??~`~~||^~-+v?||^+rwxuuxzr}}rrrrvv???????vvrzwwwr}}?+++++++++++++^^+>>`<br></br>
-uuuuxrv>~..-~||^||||~+vwxxxxzwrvvrrrr}}vrwwzzzzwwwr}}}???>>+++++++>>??vvv~<br></br>
-uuuuuuyyr-`.```..`..`^>?v}rv>vw}wwzzzzzzzzzzzzwwrr}}}vvv???>?>>>>???vv}vv~<br></br>
-uuuuuuuux^|-~-.`?vv?vvvvvv}}wxxzzzzxxxxxxzzzzzzwwr}rr}vv??????????vv}}vvv-<br></br>
-uuuuuuuyw~~^~`?zyuuuuuuuuuuuuxxxxxxzzzxxxxxxzzzzwwrrwwwwr}vv????????????v-<br></br>
-uuuuuuuw>^||~>uuuuuuxxxxxxxxxxxxxxxxxxxxzzzzzzzwrrrrrwzzzzzwrvvvv???????v-<br></br>
-uuuuuxv>>>+++>xuuuuuuuuuuuxuuuuxxxxxxxxxzzzzzzzwwwwwwwwzzzzwr}}vvvvvvvvvv-<br></br>
-uuuuw?>>+?}+^+?wuuuuuuuuuuuxxxzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwwwrrrrrwrr}}}~<br></br>


            </div>,

            <div>

`rrrrrvrrrrrrr}?^^||||~-```````````````````````````.                       <br></br>
                  -uyyyuxyyyyyyyux?++++++^|~`-~-~|+^^^|~-----------`.                        <br></br>
-uuuuuuuuuuuuuuux?>+^^^++^~-~~?v>+^|~----------`.                          <br></br>
-uuuuuuuuuuuuuuuuxv?+^^++++|--|>?vv?????^~---`.                            <br></br>
-uuuuuuuuuuuuuuuuxzv>+^++++^|~~-^?++>>>>^~-`.                              <br></br>
-uuuuuuuuuuuuuuuxzrv>+++++^^^|~~~~|+^^+++|`.                               <br></br>
-uuuuuuuxxxxxuxz}v?>^>?^|||||||||^^+|||||`                                 <br></br>
-uuuuuuuxxxxxxx}?>>>>>>>+^+++++++++++^~`.                                  <br></br>
-uuuuuuuxxxxxxzw}?+++^^^+++++++++++++^`                                    <br></br>
-uuuuuuuxxxxzzzwr?>+^^^^>^||^+++^^||-.                                  .. <br></br>
-uuuuuuuuuxzwrr}v?>>++^+??^|||||^^|-.                            ....``-~-.<br></br>
-uuuuuuuuuuxzr}v???>>+++?v+||||||~`                           ..-^^^|~--||.<br></br>
-uuuuuuuuuuuxzw}vv?>>>+++?>^^||~`.                       ...`~~~~~|^++^~-~.<br></br>
-uuuuuuuuuuxxxzrvvv??>>++++^^^|`                      .-^?????>+^|||^^+^^|.<br></br>
-uuuuuuuuuuxxxxzzwr}}}v?>>+^^~.                    .`-~^^^+++>???^^^+^^+++`<br></br>
-uuuuuuuuuuxxxxxxxxxxxxwrv>^`                   .`-||||~~~~||~~~^^++^|^>>?`<br></br>
                  -uuuuuuuuuuxxxxxxxxxxxxxwv^.                 .-||||~|||||~~~|||~~|^^|||^^+`<br></br>
                  -uuuuuuuuuuuuxxxxxxxxzzzv~               .``|^^^^|||~|||||||~||||||^~~~~~~.<br></br>
-uuuuuuuuuuuuxxzzzxxxz}^.             ..-|^|||~~~~~~~~~~||||||||||||~~~~~~.<br></br>
                  -uuuuuuuuuuxxxxxzzzzr+.            .`~|^^||||||~~~~~~~~~~~|||||||||||~~~~~.<br></br>
-xuuuuuuuuuuuxzxxz}+.           .`|>++^^^^^|||||||||||~~~~~~~~~~||||||~---.<br></br>
-uuuuuuuyuuuuuxxr^.        .`^>>?>>+++++^^^^^^^^|||||||||||||||~|+|||||~~~.<br></br>
-uuuyuuurvvvzxu?      .`~>?}www}v??>>>+++++++?vv??+^^^|||||||||+>+^|||||||.<br></br>
`wwww}?^^|^^^^++|-.|?}wzzwr}}vvvv??>>>>>>>++>vwwwrv?>++^^^^^^^++^^||||||||.<br></br>
.?????~`^^^^^~~??^~^>wuuxxzwr}}rrr}v?????????}zzwwwrv?+++++++++++^^^^^+>^^.<br></br>
`wwrwr?^~--`~|~>>^|^|+wxxuxxzwr}}rrr}}vvrwwzzzzwwwr}}?>>>+++++++>>>??vvv??`<br></br>
-uuuuuyuz}`..`-``-```|?vrzzvvzwwwwwzwzwzzzzzzzwwr}}}vvv???>>>>>>>?vv}}vvvv-<br></br>
-uuuuuuuyz^~`-`.~????v???vvvrxzzzzzxxxxxzzzzzzwwr}}r}vv??????????vv}}vvv?v-<br></br>
                  -uuuuuuuyz|~|~-vuuuuuuuuuuuuuxxxxxxzzxxxxxxzzzzwwrrrwwwrvv???????????????v-<br></br>
-uuuuuuyz?^||~vyuuuuxxxxxxxxxxxxxxxxxxxxzzzzzzwrrrrrwzzzzzw}vvvv?????????v-<br></br>
-uuuuuu}>>>+^+vuuuuuuuuuuuxxuuuxxxxxxxxzzzzzzzwwwwwwwwwzzzwr}}vvvvvvvvvvvv-<br></br>
-uuuuz?>>+vw>^+vuuuuuuuuuuuuxzzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwrrrrrwwrr}v}v}~<br></br>


            </div>,

            <div>

`rrrrr}}rrrrr}?+^||||~-``````````````````````````...                       <br></br>
-uyyyuzuyyyyyyzv>++^++^~--------~~~~~~-----------`.                        <br></br>
-uuuuuxuuuuuuuuzv++^^^+^|~---~+>+^||~-----------`.                         <br></br>
-uuuuuuuuuuuuuuuz?>+^^+++^~~-+?>>>+^||||~-----`..                          <br></br>
-uuuuuuuuuuuuuuuxzv?+^^+++^~-~^>???>??>+~~--`...                           <br></br>
-uuuuuuuuuuuuuuuxzr?>++++++^|~~|+^^++++^|~-`...                            <br></br>
-uuuuuuuuuxuuuuxzrv>++>+^^|^||~~~|+^^^^^|-....                             <br></br>
-uuuuuuuxxxxxxz}??>++?>^|||^^^^^^++^^|~~`...                               <br></br>
-uuuuuuuxxxxxxz}?>+>+++++++++++++++++|-...                                 <br></br>
-uuuuuuuxxxxxxzz}>++^^^^^^^^+++++^^^^-..`                                  <br></br>
-uuuuuuuxxxzwwwrv?>++^^>>^||^^^^^^|~`...                                .`.<br></br>
-uuuuuuuuuxzw}}v?>>>++^>v>||||||^^|`..                           ..  .``~~.<br></br>
-uuuuuuuuuuxxw}vv?>>>+++?>^|||||~-``.                              .`-||~-.<br></br>
-uuuuuuuuuuxxzw}vv??>++++>^^^||~`..                      .....   .`|+^||^~.<br></br>
-uuuuuuuuuuxxxzwr}vv??>+++^^^|`..                       .......-|^^^^^^^+|.<br></br>
-uuuuuuuuuuxxxxxxxzzxzrvv>++|-..                          ..`-^^^++^^++>>>.<br></br>
-uuuuuuuuuuxxxxxxxxxxxxzrv+-..                        ..``-~~~|^^^^||++>>?`<br></br>
-uuuuuuuuuuuxxxxxxxxxxxxr>`.              ..      ..`-~~||~|||~|^^~|~~~~||.<br></br>
                  -uuuuuuuuuuuuxxzxxxxzzwv^`                .  ...`-~~~|||||||||||||~~~-----.<br></br>
                  -uuuuuuuuuuuuxxxzzzxzv|.                 ..`-~~~~~~~~~~~|||||||||||~~~~~~~.<br></br>
                  -xuuuuuuuuuuxxxxzzr?~.             ...`-~|||||||~~~~~~~~~~~~~~|~|||~~~~-~~.<br></br>
                  -uuuuuuuuuuuuxxxz?`          ..`-~|^^+++^^^^|||||||||||||||||~|||^|||~~~~~.<br></br>
-uuuuuuuuxzxuuu?`       .`~^??vv?>>>>+++++++>??>+^^^||||||||||++^^||||||||.<br></br>
`xxxxzr?>++>?v?`. ..~+?v}rrr}v}v??>>>>>>+++>vwww}??++^^^^^^^^++^||||||||||.<br></br>
.?vv??^`^^^^|`|?+~^?rxuuxzwr}}}r}v????>?>>??}zzwwr}v>+++^^++++^^^^^^^+^^^>`<br></br>
`r}}}v?~~~~~|-|?>||^+}xxuuxzwr}}rrr}vvv}rwwzzzwww}}v?>>+++++++>>>>??vv?>??`<br></br>
-uuuuuuxr?`.`---`~`-`>}wzwrwwzwwwwwwwwwzzzzzzwwr}}}vv???>>>>>>>??vv}vvvv?v-<br></br>
-uuuuuuuyx^~`-..|>>>????vv}zzxzzzzxxxxxzzzzzwwwr}}}}vv?????????vv}}vvvvv?v-<br></br>
-uuuuuuuyz|~|-~}uuuuuuxxxuuuuxxxxxzzxxxxxxzzzzwwrrwwwrvvv????????????vvv??-<br></br>
                  -uuuuuuyz?^||~ryuuuxxxxuuuxxxxxxxxxxxxxxzzzzzwrrrrrwzzzzwrvvv?????????????-<br></br>
-uuuuuur>>>+^+vuuuuuuuuuuuuuxuxxxxxxxxzzzzzzzwwwwwwwwzzzwr}}vvvvvvvvvvvv?v-<br></br>
-uuuuxv>>>}z?^+vuuuuuuuuuuuuxzzzzzzzzwwwwwwwwwwwwwwwwwwwwwwrrrrrwrr}vvv}vv-<br></br>


            </div>,

            <div>

                  `rrrrrrvvrrrv^|||||~~-````-~~--~~-``````````..                             <br></br>
-uyyyyxwuyyyuv>++++++|```------~~~~----------``....                        <br></br>
-uuuuuwuuuuuuu}>+++++^~``------~~~~~------------`.                         <br></br>
-uuuuuuuuuuuuuu}>^^^^+^|~-~-~^>+^^|~-----------`.                          <br></br>
-uuuuuuuuuuuuuuu}>>^^++++~--+??>>^|||~~------`.                            <br></br>
-uuuuuuuuuuuuuuuxw?>+^++++~-~|>vv?????^~----`                              <br></br>
-uuuuuuuuuuuuuuuxwv>++++++^|~-~^^^++++^|~-`.                               <br></br>
-uuuuuuuuxxxuuxzwv?++>+^^||||~~~|+|^^^^|~`.                                <br></br>
-uuuuuuuxxxxxxwv?>>+>?+|^^^^^^^+++^|~~~-.                                  <br></br>
-uuuuuuuxxxxxxzv?++>^+++++++++++++++|~`.                                   <br></br>
-uuuuuuuxxxxxzzwv>+^^^^+^|^+++++^^|^~.                                  .`.<br></br>
-uuuuuuuuxxwwrrv??>++^+?>||||^^^|||`.                                 .`~~.<br></br>
-uuuuuuuuuuxw}v???>>+++??|||||||^~.                                 .``~~~.<br></br>
-uuuuuuuuuuxxw}vv?>>>++>?+^||||~`                                .``-|~-~~.<br></br>
-uuuuuuuuuuxxzw}vv??>>++++^^^|`                               .`~^^^||^~~-.<br></br>
-uuuuuuuuuuxxxxzwwr}}v?>+++^-.                             .~~|^+^^++++|~~.<br></br>
-uuuuuuuuuuxxxxxxxxxxxwr?>~.                           ..`-~|^^+^|^>>?????`<br></br>
-uuuuuuuuuuxxxxxxxxxxxxw?`                         .``-~~||~~|^^|||^^^+>>>`<br></br>
-uuuuuuuuuuuxxxxxxxxxx}^                      ..``~|||||||||||||~~~-------.<br></br>
                  -uuuuuuuuuuuuxxzzzxxr+.                  ..``-~~~~~~~|||||||||||~~~~~~~~~~.<br></br>
                  -xuuuuuuuuuxxxxxwww?.               ..`-~|||||~~~~~~~~~~~~~~|||||~~~~~~~~~.<br></br>
                  -uuuuuuuuuuuuxxxx?`           ..`-~|^^^^^^||||||||||||||~~~~~|||||~~~~~~|~.<br></br>
-uuuuuuuyyuuuuuw|        .`^>???>>>+++++^+++>++^^|||||||||||^+^^||||~~~|>>`<br></br>
-uuuuuuuw}}zxx}.    .-^?v}r}}}v??>>>>>++++?}wrv?>+^^^^^^||^++^^||||||||^>?-<br></br>
`wwr}}v+^^^^|^^|.`>vwxuxzwrv}}rvv???>>>>>>?wzwww}v?++^^^^^++^^^|^|^^^^+??v-<br></br>
`vvv???-~|^^~-??~|+vzxxuuxzwr}rrr}vv?vv}rrwwwwwr}}?>++++++++>>>>?????>>???-<br></br>
-uuxxxzw?|.``-|~~~~~?wzxxzzzzwwwwwwwwwzzzzzzwwr}}vv???>>>>+>>>?vv}vvv?????-<br></br>
-uuuuuuuyz|```.-+^^+???v?vxxxzzzzxxxxxzzzzzwwr}}}}vv??????>??vv}}vvvvvvvv?`<br></br>
-uuuuuuuyz~~~`>xuuuxzzzwzxuxxxxxxzzxxxxzzzzzwwrrrwwr}vv?????????????v?????`<br></br>
-uuuuuuyx?^|~+uuuuuuuuuuuxxxxxxxxxxxxxxzzzzwwrrrrwzzzzwrvvv?????????????>?`<br></br>
-uuuuuuz?+>>^>zuuuuuuuuuuuxxxxxxxxxxxzzzzzzwwwwwwwwzzzwwr}vvvvvvvvvvvvv???-<br></br>
-uuuuu}>+?wu?+>wuuuuuuuuuuuuxzzzzzzwwwwwwwwwwwwwwwwzzwwwwwr}rrwrr}}}}vvvvv-<br></br>


            </div>,

            <div>

`rrrrrr}v}rr>||~~~---````-~~~-----````````....                             <br></br>
-uyyyyuwxuyyr>+++^^^|-``--~|~~~|~~--------``.....                          <br></br>
                  -uuuuuwzuuuuur?++++++~```------~-------------``..                          <br></br>
                  -uuuuuzuuuuuuuw?++^++^|------~|~~~~-----------`.                           <br></br>
                  -uuuuuuuuuuuuuuw?++^^++^|~-~+?+^||~~~-------`.                             <br></br>
-uuuuuuuuuuuuuuuwv>^^++++|~~^>???>+++^~----`                               <br></br>
-uuuuuuuuuuuuuuuxr?>++++++|~~|>>++>>>^|~~`.                                <br></br>
-uuuuuuuuuuuuuuxz}?++>+^^^^|~~~~^+^^^^^|`.                                 <br></br>
-uuuuuuuxxxxxxz}v?++>>^|||||||^^+^^|||~.                                   <br></br>
-uuuuuuuxxxxxxr?>+>>+++++++++++++++^~`.                                    <br></br>
                  -uuuuuuuxxxxxxzr?++^^^^^^^++++++^^^|.                                    ..<br></br>
-uuuuuuuuxxzwww}?>++^^+>^|^^^^^^^|-.                                   .~|.<br></br>
-uuuuuuuuuxzr}v?>?>++^>?>||||||^|`                                   .`-~|.<br></br>
-uuuuuuuuuuxzw}v??>>+++>>^^|||~`.                                ..`-~~-~|.<br></br>
-uuuuuuuuuuxxzrvv??>>++++^^^|-.                               ..-||||~|~~~.<br></br>
-uuuuuuuuuuxxxzwr}vv??>+++^-.                              .`~|^+^^^+^|---.<br></br>
-uuuuuuuuuuxxxxxxxxxxw}v>|.                            ..-~|^++^^^+>>>>+>>`<br></br>
-uuuuuuuuuuxxxxxxxxxxxzv-                         ..`-~~~||||^^^|^^++>>???`<br></br>
-uuuuuuuuuuuxxxxxxxxxz>.                     ..``~||||||||||||||~~~~~~~~~~.<br></br>
                  -uuuuuuuuuuuuxxzzzxxv`                   .``-~~~~~~||||||||||||~~~~~~~----.<br></br>
                  -xuuuuuuuuuxxxxxwzw+                .``~~||||~~~~~~~~~||||||||||~~~~~~~~~~.<br></br>
                  -uuuuuuuuuuuuxzxx?.           ..`-~^^^^^^|||||||||||||~~~~~||||||~~~~~~~~|.<br></br>
-uuuuuuuuuuuuuuz^        .-+??>>>>++++^^^^^+^^^||||||||||||^^^||||~~~|^^>?`<br></br>
-uuuuuuyxwwxuur`    .-^?v}r}}v??>>>>+++++>vr}v?+^^^^^||||^++^|||||||||^>vv-<br></br>
`zzzzww?+^^++>|` `^vwxxzzr}v}}vv???>>>>>>?}zwww}?>++^^^^^^^^^^^|^^^^^+>???-<br></br>
`vvv???~~^^^-~v?-^?rxxuuuxwr}rrrr}vv?vv}rrwzwwr}}?>+++++++++>>>>???>>>>???-<br></br>
-uuxxzwr?|``--^~~~~|}zxxxzxzwrwwwwwwwwzzzzzwwr}}vv???>>>>>>>>?vv}vv???????-<br></br>
-uuuuuuuyw~```.~+^^>??vv?}xxzzzzxxxxxzzzzzwwr}}}}vv?????????vvv}vvvvvvv???`<br></br>
-uuuuuuuyz~~~-?xuuuxwwwwzuxxxxxxxzxxxxxzzzzzwrrrwwr}vv???????????vv???????`<br></br>
-uuuuuuyx?^|~>yuuuuuuuuuuuxxxxxxxxxxxxzzzzzwrrrrrwzzzwr}vv????????????????`<br></br>
-uuuuuuz?+>>^>zuuuuuuuuuuuuxxxxxxxxxzzzzzzzwwwwwwwwzzwrr}}v}vvvvvvvvvv????-<br></br>
-uuuuur>+?zu?+>wuuuuuuuuuuuuxzzzzzwwwwwwwwwwwwwwzzzzzzwwwrrwwwrr}}}}}vvvvv-<br></br>


            </div>,

            <div>

`rrrrrrr}vv?||~--```````-||||-``````````...                                <br></br>
-uyyyyyxwzuz>^^^||~~~``-|^++^||~~-------``...                              <br></br>
-uuuuyurxyuu}+++^^^^~``--~~~~|||~---------`....                            <br></br>
-uuuuuwxuuuuu}>++^++^-``------~-------------`.                             <br></br>
-uuuuuxuuuuuuu}>+^^^^|~-----~|||~~----------.                              <br></br>
-uuuuuuuuuuuuuu}>+^^^++|-~~>?>^||~~-------`.                               <br></br>
-uuuuuuuuuuuuuuur?>^^+++^~-^>?v?>>>>^~--`.                                 <br></br>
-uuuuuuuuuuuuuuxz}?++++++^|~~^+++>>>^|~`                                   <br></br>
-uuuuuuuuuuuuuxzrv>+>+^^^^|~~~~^^|^^+|`                                    <br></br>
-uuuuuuuxxxxxx}??>+>?+||||^^^^+++||~-.                                     <br></br>
-uuuuuuuxxxxxz}?++>+++++++++++++++|`                                     . <br></br>
-uuuuuuuxxxxxzz}>+^^^^+^|^++++^^^~.                                    .-^.<br></br>
-uuuuuuuuxxwwr}v?>++^+?>||||^^^|`                                     `|^^.<br></br>
-uuuuuuuuuxzr}v?>?>+++??^|||||`.                                   .`-~|^^.<br></br>
-uuuuuuuuuuxzw}v?>>>+++?^^^|-.                                 ..`~-~~~~||.<br></br>
-uuuuuuuuuuxxzrvv???>++++^~.                               ..-|^^^^|~---~~.<br></br>
-uuuuuuuuuxxxxxzzwww}??>^`                             ..-|^++^++>>+|||||^.<br></br>
-uuuuuuuuuxxxxxxxxxxxz}|                          ..`-~|~|^^^||++>????????`<br></br>
                  -uuuuuuuuuuxxxxxxxxxuv`                     ..``~||||||||||^|~~~|||^^^^^^^.<br></br>
                  -uuuuuuuuuuuxxzzxxxz+                   ..`-~||~~||||||||||||~~~------`-`` <br></br>
-xuuuuuuuuuuxxxzzz}-               .``~~|||~~~~~~~~||||||||||~~~~~~~~~~~~~.<br></br>
-uuuuuuuuuuuxxxzz>.          ..`~|^^^^^|||||||~~~~~~~~~~~|||||~~-~~~~~~~~|.<br></br>
-uuuuuuuuuuuuxxz^        `|>>>>>>++++^^^^^^^||||||||||||~|^^||||~~~~^++>>?`<br></br>
-uuuuuuyyuuuuuw-     `|?vrrr}v?>>>>+++++>vvv?+^^^^||||||+++^||||||||+?v}vv-<br></br>
`xuxxzzr?>>???|. `^?rxzwr}vv}v???>>>>>>>?wzww}v>++^^^^^^^^^||||||^^+??vv??-<br></br>
`vvvv??|-^^|-|v^`+vxuuuuxwr}}rr}vv??vvv}rwzwwr}v>++++++++++++>>?>>+>??????`<br></br>
`xxzzwr}>~`--~>~~~~>wxxxxxzwrrrwrrrrwzzzzzwrr}}v???>>>+++>>?vv}vv?????????`<br></br>
-uuuuuuyyw~```.^^^^>??vv?zxzzzzxxxxxzzzzzwwr}}}vv??????>??vv}}vvvvvvv?>>>>`<br></br>
-uuuuuuuyz~~~|vuuuxxrrr}zuxxxxxzzxxxxzzzzzwwrrwwr}vv??????????vvvv????????`<br></br>
-uuuuuuyu?^~~vyuuuxuuuuuuxxxxxxxxxxxxzzzzwwwrrrwzzzwr}vv??????????????????`<br></br>
-uuuuuux?+>>^?zuuuuuuuuuuuxxxxxxxxxzzzzzzwwwwwwwwzzzwr}}vvvvvvvvvvvv??????-<br></br>
-uuuuuw?+?zu?+?zuuuuuuuuuuxxxzzzzwwwwwwwwwwwwwwwwzzzwwwrrrwww}}}}vvvvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrrrr}?+|~-```````.``~~~~```````````..`^^^++++|.                       <br></br>
-uyyyyyuzrwv+^||~~---``-^+++|~~-~------`.`>?>>++^`.                        <br></br>
-uuuuuurruyx>^^^^|||-``-~^^^~||~~-------`^?>+>>|                           <br></br>
-uuuuyzwuuuur>++^^+^~``-----~~~~--------`>?>>>+.                           <br></br>
-uuuuuwuuuuuuw?++^++|-``-----~~~~-------`+?>`..                            <br></br>
-uuuuuuuuuuuuuw>^^^^+^|--~~^>^||~~-------~+-                               <br></br>
-uuuuuuuuuuuuuuw?>^^+++^~-|???>+^^||~---`                                  <br></br>
-uuuuuuuuuuuuuuxw?>+^+++|~~~+??>???+~~-. .                                 <br></br>
-uuuuuuuuuuuuuuxwv>+++++^^|~~||++^++^-. ..                                 <br></br>
-uuuuuuuxxxuuxw}v>^>>^||||||||^+||^|`  . . .                               <br></br>
-uuuuuuuxxxxxzv>>>>>>+^++++++++++|`   .  . .                               <br></br>
                  -uuuuuuuxxxxxzw?+++^^^^^^+++++++|.    .  ...                            .`.<br></br>
-uuuuuuuxxzzwwrv?>+^^>>||^^^^^|`  .. .   ...                          .~^~.<br></br>
-uuuuuuuuuxwr}v??>++^>v+|||||-.   ....    ..                        .-|^^^.<br></br>
-uuuuuuuuuuxw}v??>>+++?>^^|~.   .. ..      .                    ..`-~~||^+`<br></br>
-uuuuuuuuuuxzw}vv??>++++^|`   . .  ..                      ..`~~|||~-~~~|^.<br></br>
-uuuuuuuuuxxxxzwr}}}?>>+-. .  . .  .                  ..`~|^++++++|~---~|~.<br></br>
-uuuuuuuuuxxxxxxxxxxzw?`   .  .                  ..``~~|^^+^|^+>?????????>.<br></br>
-uuuuuuuuuuxxxxxxxxxx?.  .                  ..`-~||||||~|^^||||^^+++>>>++^.<br></br>
                  -uuuuuuuuuuuxxxxxxxr~ .                ..`-~~||||||||||||||~~~--------```` <br></br>
                  -xuuuuuuuuuuxxzzzxv.              ..`-~~||~~~~~~~~||||||||||~~~~~~~~~~~~--.<br></br>
-uuuuuuuuuuuxxxzw>  .        .`-~|^^^^||||||~~~~~~~~~~~~|||||~~~~~~~~~~~|~.<br></br>
-uuuuuuuuuuuuxxz|        `|^+>>>+++^^^^^^||||||||||||||~||^|||~~~~|^^++>>^.<br></br>
-uuuuuuuyyuuuuw`     .~>vrrrv?>>>>+++++>?v??+^^||||||||++^^|||||||^>?}}v?>.<br></br>
-uuuxxxzv??vv?-  .~?}zzw}}vvv???>>>>>>>?rzwrv?>+^^^^^^^+^^|||||||^+?vvvv??`<br></br>
`v}vv??^-^^~`^?~->}uuuuxzr}}rr}vv????vv}wzwwr}v>++++++++++++>>?>++????????`<br></br>
`zxzzr}v+~`~-~?-~~~?wxxuxxzrrrwrrrrwzzzzzwwr}}v???>>>+++>>?vvvvv????????>>`<br></br>
-uuuuuuyyw~`-`.^^^^???}vvxxzzzzxxxxzzzzzwwr}}}vv?????>>??vv}}vvvvvv??>>>++`<br></br>
-uuuuuuuyz~~-|vuuxxxr}}}zuxxxxxzxxxxzzzzzwwrrrwr}vv????????vvvvv????????>>`<br></br>
-uuuuuuuu?|~~}yuuuuuuuuuuxxxxxxxxxxxzzzzzwwrrrwzzzwrvvv????????????>??????`<br></br>
-uuuuuux?+>>^?xuuuuuuuuuuuxxxxxxxxzzzzzzwwwwwwwwzzzwr}}v}}vvvvvvvv????????-<br></br>
-uuuuuw?+?zu?+?zuuuuuuuuuxxxzzzzzwwwwwwwwwwwwwzzzzzwwwrrrwwr}}}}vvvvvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrrrrr}?|-`````......````````````.... ^}}}rrwwv-                     . <br></br>
-uyyyyyyuz}>^|~~---````-|^^^~~--------`..~uyuxxxw+.                        <br></br>
-uuuuuuxrwxv^^^|~~~~-`-|^++^~~~-------`..vyuzuu}.                          <br></br>
-uuuuuurzuyz>^^^^^||``--~||~|||~--------`zuuwxu^                           <br></br>
                  -uuuuuwxyuuuw?+++++^-``-----~~~---------`ryz-.`                            <br></br>
                  -uuuuuzuuuuuuz?+^^++|-`----~|~~~~--------^w?                               <br></br>
                  -uuuuuuuuuuuuuz?+^^^++|-~-+?+^||~-------.                                  <br></br>
-uuuuuuuuuuuuuuzv>^^+++^--^>?v?>>>+~--`..^  -                              <br></br>
-uuuuuuuuuuuuuuxw?>+++++^~~~+>+>>>+|~. .|? .-                              <br></br>
-uuuuuuuuuuuuuxwv?+>+^^^^|~~~|^^^^+|.  ~.?.~`                              <br></br>
-uuuuuuuxxxxxzv?>++?>^|^^^^^^++^|~`   ~. ?~+.                              <br></br>
-uuuuuuuxxxxxzv>+>+++++++++++++>~. ~ -~  ??>                             . <br></br>
-uuuuuuuxxxzzzwv>+^^^+^|^+++^^|`  |>.>   |v>                          .-`. <br></br>
-uuuuuuuuxzwr}v??>+^^??||||^^-  `-+^^~   .?+                        .~++|` <br></br>
-uuuuuuuuuxzrvv??>+++??^|||-. .-++.++     -^                     .`-|^^^>^.<br></br>
-uuuuuuuuuxxzrvv??>+++>+^~...`|`?` >-      .               ..``-~~~~~||+>+.<br></br>
-uuuuuuuuuxxxzr}vv??>++^`  |-.|.~  |                  .``-|^+^^+|~----~|~` <br></br>
-uuuuuuuuuxxxxxxzzxzr}+..``|` |                  ..`-~|^++^^^+>>>+++>>?>|- <br></br>
-uuuuuuuuuxxxxxxxxxuz+..-^.`  .             ..`-~|||||~|^^|||^+>>>>???>>^-.<br></br>
-uuuuuuuuuuuxxxxxxx}` ^`.~             ..`-~~||^||||||||||~~~---~~~~---``` <br></br>
                  -xuuuuuuuuuuxxzzzx?..-`           .``-~~~~~~~~~~||||||||||~~~~~~~~~-------.<br></br>
                  -uuuuuuuuuuxxxxzw+ .|.       .`-~|^^^|||||~~~~~~~~~~~~|||||~~~~~~~~~~~~~~~.<br></br>
-uuuuuuuuuuuxxxz|   .    `~|++>+++^^^^^|||||||||||||~~~|||||~~~~~|||^+>^|~.<br></br>
-uuuuuuuyyuuuuw-     .~>vrr}??>>>++++++???>^^|||||||||^+^^||||~~|+?vvvv>^|.<br></br>
-uuuuxxzv?vvv?`  .->vwwr}vv}v??>>>>>+>?rzwrv?>+^^^^^^++^||||||||^>?vvvv?+^.<br></br>
`v}vv??+-^^~`^?~~>ruuuxzw}}rr}vv?????vvrzwww}v>++++++++++^++>>++>??????>>+.<br></br>
`zzzwrvv^~`~-~?-~~|?zxuuxzwrrrwr}}rwzzzzwwr}}v??>>+++++>??vvvv?????????>>+.<br></br>
-uuuuuuyur~`-`.^^+^??v}vvxzzzzxxxzzzzzzwwr}}}vv?????>>??v}}vvvvvvv?>>>>+++.<br></br>
-uuuuuuuyz|~-|}uuxxxr}}}xuxxxxxxxxxxzzzzwwrrrrr}vv???????vvvvvv??????>>>>+.<br></br>
-uuuuuuuu?|~~ryuuuuuuuuuuxxxxxxxxxxzzzzzwrrrrwzzzwrvvv???????????>>???????`<br></br>
-uuuuuux?+>?+?xuuuuuuuuuuuxxxxxxxzzzzzzwwwwwwwwzzzwr}}v}vvvvvvvvv????????v-<br></br>
-uuuuuz?+?zu?+?zuuuuuuuuxxxxzzzzwwwwwwwwwwwwwzzzzzwwwrrwwwr}}}}vvvvvvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrr?|-```..........``````.....~^|~?r}?>}??+`              ..    .` <br></br>
-uyyyyyyyuw?+|~-````````-~~~-------``->^.~xyuzzuv~.              ....    . <br></br>
-uuuuuuuzrr>^|~~----``-|+++|~~--------+^~vuxzwzz`                  ..      <br></br>
-uuuuuuwwuu}^^^^|~~~``-|^+^|||~------`|++xx}vvv~                           <br></br>
                  -uuuuyzwuuuuv+++^^^|``--~~-~||~-------|++wyx|..                            <br></br>
-uuuuuwuuuuuuv>+^++^~``--------------|>^^?v~                               <br></br>
                  -uuuuuuuuuuuuuv+^^^+^|--~~^+|||~-----^+^^-                                 <br></br>
-uuuuuuuuuuuuuxv>+^^++^~-~???>+^^|~~-^^...                                 <br></br>
-uuuuuuuuuuuuuux}?+^+++^~~~+??>??>^~`.. ..                                 <br></br>
-uuuuuuuuuuuuuxz}?+++++^^|~~||+^^+^`.  . . .                               <br></br>
-uuuuuuuxxxxxz}v?++?^|||||||^^^||-... .. ...                               <br></br>
-uuuuuuuxxxxxw?>+>+>++++++++++>^`  - ..  ...                             . <br></br>
                  -uuuuuuuxxxxxzr?++^^^^^^+++++^~.  -.`-   .`.                          .. . <br></br>
-uuuuuuuuxzwwrv?>++^+?^~|^^^~. ..`` |~    ..                       .`~|-.. <br></br>
-uuuuuuuuuxw}v???>+++v>|||~`  .--~ .`.    ..                     .`|^^+^|` <br></br>
-uuuuuuuuuxxz}v??>>++>>^^-.  `~.^. ..                        ..`-~~||^+>+` <br></br>
-uuuuuuuuuxxxw}vv??>+++^. .`..`.~  .                  ...`-~|^^|~----~|~`. <br></br>
-uuuuuuuuuxxxxxzzzzwvv^....`. ..                 ..``~^^++^+>>>+^^^^+>^~`. <br></br>
-uuuuuuuuuxxxxxxxxxuz^ .`. .                ..`-~||||||^+^|^+>>???????>+~. <br></br>
-uuuuuuuuuuuxxxxxxx}` `...             ..`-~||^^||||||||^|~~~~~~~~~~~-```. <br></br>
                  -uuuuuuuuuuuxxzzxx?. ..           .``-~~|||~~~~||||||||||~~~~~~~-----````` <br></br>
                  -uuuuuuuuuuxxxzzw+ ..        .`-~|^^^||||~~~~~~~~~~~~|||||~~-~~~~~~~~~~---.<br></br>
-uuuuuuuuuuuxxxz|        .-|+>>++^^^^^||||||||||||~~~~|||||~~~~~||||++^|~~.<br></br>
-uuuuuuuyyuuuuw-     .~>vr}v?>>>++++^+>?>>^^|||||||||^++||||~~~|^>?vv?>^|~.<br></br>
-uuuuuuuzrrwwv`  .->vrwr}vvv??>>>>>++>vwwrv?>+^^^^^^^+^||||||||^>v}}vv?+^|.<br></br>
.vrrr}v?^^^^~^>`^vwuuuxzr}}rrvv??????v}zzwwrv?+++++++++^+++>>++>?v????>>+|.<br></br>
`zzwwrvv^~-~`^?`|^?rxuuxxwrrrwrr}}wzzzzzwwr}v??>>+++++>>?vvvv?????????>>+>`<br></br>
-uuuuuuuur~`-.`^^|>?vv}wwxzwzzxxxzzzzzzww}}}}v?????>>??vv}}vvvvvv?>>>>++++.<br></br>
-uuuuuuuyz|~-^ruxxxzrrwzxxxxxxzxxxxzzzzzwrrrwr}vv????????vvvvv??????>>>+++.<br></br>
-uuuuuuuu?|~~wyuuuuuuuuuuuxxxxxxxxxzzzzwrrrrwzzzwr}vvv??????????>???????>>`<br></br>
-uuuuuuuv+>?+?uuuuuuuuuuuxxxxxxxxzzzzzzwwwwwwwzzzwr}}}}}}vvvvvvv???????vvv~<br></br>
-uuuuuz?+?xu?+vuuuuuuuuuxxxxzzzwwwwwwwwwwwwzzzzzzzwwwrwwww}}}}}vvvvvvvvvv}-<br></br>


            </div>,

            <div>

`rrrrrrrrr?|-~-`....````...........  .?}?v}r}^|?..............`~|||~-````` <br></br>
                  -uyyyyyyyyu?|~~-``````````------```..|yw`~zyuwwu+.....         .-|^^|~-~~~.<br></br>
-uuuuuuuuzv>^~~--````.`~|^|~--------`^yx}rwzr?vy>.                .~|~--~|.<br></br>
-uuuuuuxwwz?^^|~--~-``~^+>^~~~-------`?uuyr++^>|                    .-~~~|.<br></br>
                  -uuuuyurxuyr+^^^^||-``-~||~|||~------`?xxuyz?`                        `~~-.<br></br>
-uuuuuwxuuuuv>++^++~``-----~~~------`?yzxuv`                           `~-.<br></br>
-uuuuxzuuuuuur>^^^+^~-----~|~~~~-----wxwux`                             `-.<br></br>
-uuuuuuuuuuuuur>+^^++^~--^?>^||~-----ru|`.                              .`.<br></br>
-uuuuuuuuuuuuuxr?+^^++^~-|>?v?>?>+~-.`-                                 .. <br></br>
-uuuuuuuuuuuuuuz}>+++++^|~~^++>>>+|..                                   .. <br></br>
-uuuuuuuuuuuuxzr?++>^^|||~~~|^^^^~.`>  .                                .. <br></br>
-uuuuuuuxxxxx}?>>+>>^^^^^++++++~. .~^...                                   <br></br>
-uuuuuuuxxxxxwv>++^^++++++++++-  .~ +`-                              ..    <br></br>
-uuuuuuuxxzzzw}?++^^>+||^+^^-. -.~. ^>-                            `|-.    <br></br>
-uuuuuuuuuzr}v??>++^?v^|||~. .`>--  .?~                          `|++^~`   <br></br>
-uuuuuuuuuxzrvv?>>+++?+^|` ..++~^.   +~                       .`-||^^>>~.. <br></br>
-uuuuuuuuuxxzrvv??>++++|. --`>-||    .`                 .``-~||~-~~~|^|``. <br></br>
-uuuuuuuuuxxxxzwrr}v?>~ ..|~ ~ ~`                 ..-||^++++>+^~~~~|^-`... <br></br>
-uuuuuuuuuxxxxxxxxxxr~ .|.``   .            ..``~~|||^^+^|^>>????????>|`.. <br></br>
-uuuuuuuuuuxxxxxxxx}` `.|              ..`-~|||||~||~|^^|~~~||^^^^^||-``.. <br></br>
                  -uuuuuuuuuuuxzzxxx?. `. .         .``-~~|||~~|||||||||||~~~~------```````` <br></br>
                  -uuuuuuuuuxxxxzzz+ `.        .`-~||||||~~~~~~~~~|||||||||~~~~~~~~~~~~-----.<br></br>
-uuuuuuuuuuuxxxw~  .     .`~^>++^^^^||||||||||||~~~~~|||||~-~~~~|~|^^|~~~~.<br></br>
-uuuuuuuuuuuuuw-     .->v}v?>>>++++^^+>>+^^||||||||||+^||||~~~|+?????^|~||.<br></br>
-uyyuuuuyxzuu}`   `+?}rr}v}??>>>>>+++?rwrv?+^^^^^^^^+^||||||||^?v}}v?>^||~.<br></br>
.vwwwwr}>^++^^|`>rxuuxzw}}rr}v???????vwzwwrv?+++^++++^^^^+++^+??v????>+^||.<br></br>
`wrr}}v?^~~~`>>-^?zxuuuxzrrrrrr}vrwzzzzwwr}}??>>+++++>>?vvvv????????>>+++>`<br></br>
-uuuuuuuur~`-.-^^|?vr}vxxzwzzxxzzzzzzzwwr}}vv?????>>??v}}vvvvvv??>>>++++++.<br></br>
-uuuuuuuyx|~-^wuxzw}rrzuxxxxxxxxxxzzzzzwrrrrr}vv???????vvvvv???????>>>++++.<br></br>
-uuuuuuuu?^||xuuuuuuuuuuuxxxxxxxxxzzzzwwrrrwzzzwrvvv???????????>??????>>>>`<br></br>
-uuuuuuuv+??+vuuuuuuuuuuuxxxxxxxzzzzzzwwwwwwwzzzwr}}}}}vvvvvvvv???????vvv}~<br></br>
-uuuuux?+?xu?+}uuuuuuuuxxxxxzzwwwwwwwwwwwwwzzzzzzwwwwwwwr}}}}vvvvvvvvvv}}}-<br></br>


            </div>,

            <div>

`rrrrrrrr}+~^^-````````..........  .``?v???v?~|?.`.........```-~~~~~-````` <br></br>
                  -uyyyyyyyyz?~~~-`````````````````....|xw`|wxwvvr|.......     ..`-|^|~--~~~.<br></br>
-uuuuuuuyu}>^~--```...``-~~-------```>yxrww}}>?z+.               ..----~|^.<br></br>
-uuuuuuuz}}+^|~~----``|+++^~--------|~?wuyr>?^+~                    .-~~||.<br></br>
                  -uuuuuuwwuyv^^^^|~~-`-~|+^|||~~-----^~?zxxuw?`                        `~~~.<br></br>
                  -uuuuywzyuux?+++^+^-``--~--~~~-------vyxzz?`                           `~~.<br></br>
                  -uuuuxzuuuuux?++^++|-``----~--------|wxwzv`                            .~-.<br></br>
-uuuuuuuuuuuyx?^^^^+^~`~~+>^||~----|^wu^..                             .`-.<br></br>
-uuuuuuuuuuuuuxv>+^+++|-->???>++^~-|~-~                                 .`.<br></br>
-uuuuuuuuuuuuuxzv>+++++|~~|?>>>>>^-..                                   .. <br></br>
                  -uuuuuuuuuuuuuxr?+++^^^^|~~~|+^^^-  `                                  ... <br></br>
-uuuuuuuxxxxx}??>+?>^||^^^^^++^-. ..`                                  ... <br></br>
-uuuuuuuxxxxxr?++>^+++++++++++-   - ~..                               ..   <br></br>
-uuuuuuuxxxxzz}>+^^^+^|^+++^-. .... ``.                            ``.     <br></br>
-uuuuuuuuxzrrv??>++^??||||~. ``~..  .`.                          `|+^~.. . <br></br>
-uuuuuuuuuxzrv??>>++>?^^|` ..^|..    ..                       .`~^^^+?~... <br></br>
-uuuuuuuuuxxz}vv??>++++|. ```-`..     .                 ..`-~~~~~~~|>+`... <br></br>
-uuuuuuuuuxxxzwr}}v?>+- ..`- . ..                 .`~~~^^^+++^~---~|~..... <br></br>
-uuuuuuuuuxxxxxxxxxz}-  -...                ..``-~||^++^|^>?????????>~`... <br></br>
-uuuuuuuuuxxxxxxxxx}` ..`              ..`-~|||||~||||^|~~||^++++++^|``... <br></br>
                  -uuuuuuuuuuuxzxxxx?. ..           .``-~||||~||||||||||||~~-------````````` <br></br>
-uuuuuuuuuxxxxzzz> ..        .`-~||||||~~~~~~~~|||||||||~~~~~~~~~~~-------.<br></br>
                  -uuuuuuuuuuxxxxw~  .     .`~++++^^^^|||||||||~~~~~~~|||||~-~~~~~~|^^~~~~~~.<br></br>
-uuuuuuuuuuuuuz-     .->vvv?>>++++^^^+>+^^||||||||||^+||||~~~~+?????^||~||.<br></br>
-uuuuuuuyuxuur`   `+?}rr}}v??>>>>+++>vwrv?>^^^^^^^^++^|||||||^>v}}v?>^||~-.<br></br>
`vwzzzwr?+>>+^-`>rxuuxwr}}r}v????????rzwwr}?++^^^+++^^^^+++^^>?v????>+^|~~.<br></br>
-zrr}}v?^-~~`?>~+?xxuuxxwrrrrr}}}rwzzzwwr}}v?>>+++++>>??vvv????????>>+++>>`<br></br>
-uuuuuuuur~`-.-^|^?}r}}xxzwzzxzzzzzzzwwr}}}vv????>>>?vv}}vvvvvv?>>>>++++++`<br></br>
-uuuuuuuyx|~-+zuxzw}rwzuxxxxzxxxxxzzzzwwrrrrrvv???????vvvvvv??????>>>+++++.<br></br>
-uuuuuuuu?^||xuuuuuuuuuuuxxxxxxxxxzzzzwrrrrwzzwrvv????????????>??????>>>>>`<br></br>
-uuuuuuuv+?>+vuuuuuuuuuuxxxxxxxzzzzzzzwwwwwwzzzwr}}v}}vvvvvvvv???????v}}v}~<br></br>
-uuuuux?+?uu?+}uuuuuuuuxxxxxzzwwwwwwwwwwwwzzzzzzwwwwwwww}}}}}}vvvvvvv}}}vv-<br></br>


            </div>,

            <div>

`rrrrrrrr?^?v^``````.............  .}}?+|`..````````````~|-``-~~~~~-`````` <br></br>
-uyyyyyyyu?^^+~-````````...``.......`^vyux}>~~````..```..``-~^^^^|~~--~--~.<br></br>
-uuuuuuuuyz?~~--``..``````-----```..+zuuuu}??```.......     .`-~^^~--~~||^.<br></br>
-uuuuuuuuw?+|~--````.`-|^^~---------xx^>zyr?z+.`..              .`~~~~~|^+.<br></br>
-uuuuuuxrww+^^|~--~``-^+>+|~~------~zu}}}}??x+                     .-~|||^.<br></br>
-uuuuyxrxyyv^^^^^^|``--~|~~||~-----`>uuy}?+~`                        -~~||.<br></br>
-uuuuuwuuuuuv>++++^-``--------------vxxxw+.                          .-~--.<br></br>
-uuuuxxuuuuuu}+^^^^^~`-~-~||~~~---`?yzyw.                             `-~-.<br></br>
-uuuuuuuuuuuuu}>+^^++^--|?>+^|~~---}x+>-                              .-~-.<br></br>
-uuuuuuuuuuuuuxr?+^+++^~~^>v?>??>~```                                 ..`-.<br></br>
                  -uuuuuuuuuuuuuxw?++++++^|~~|^+++^`.                                   ...`.<br></br>
-uuuuuuuxxxuxw}?>+?+|||||||^^^|-..+                                    ... <br></br>
-uuuuuuuxxxxxv>+>>>+++++++++>^. .`^..                                   .. <br></br>
                  -uuuuuuuxxxxzwv++^^^^^^++++^` ..~ |-`                              .     . <br></br>
-uuuuuuuuxwwr}??>+^+?^~||^~. `|~. `>.                           .-|~..     <br></br>
-uuuuuuuuuxw}v??>+++v?||~. ``||.   >.                         .-^^^+^`...  <br></br>
-uuuuuuuuuxxw}v?>>+++>+~. .~-.|    ..                   ...`--~~||^>+`.... <br></br>
-uuuuuuuuuxxxw}vvv?>++- .-.^. .                   .```~|^^^+|-----|~...... <br></br>
-uuuuuuuuuxxxxxxxxzrv` .-- `                ..``-~|^+++^^>>>>>>>??>^`..... <br></br>
-uuuuuuuuuxxxxxxxxu}` .`..             ..`-~||||||~~^^^~|^++>>>>>>+^-..... <br></br>
                  -uuuuuuuuuuxxxxxxx?. ...          .``~~||||||||||||||||~~-------```.`````. <br></br>
-uuuuuuuuuuxxxzzx> ...       ..`~|||||~~~~~~~~|||||||||~~~~~~~~~~--------` <br></br>
                  -uuuuuuuuuuxxxzw^  .     .`~+++^^^^||||||||~~~~~~~~|||||~-~~~~~~~||~~~~~|~.<br></br>
-uuuuuuuuuuuxxz~     .~>vv?>>++++^^^^+^^|||||||||||^+||||~~~|+>>??>^||~||- <br></br>
-uyuuuuuyyuuuw-   `^?}rr}}v?>>>>++++?rrv?+^^^^^||^++^|||||||^?vr}v?+|||~`-.<br></br>
-}rxxzzz}>???|.`?rxuxzr}v}}vv??>????}zzzw}?>+^^^+++^^^^^+^^^>?vv???>+^|~||.<br></br>
-x}}}vvv>`|~`?+|?zuxuuxzwrrrr}vv}wwzzzwwr}v?>>++++++>??vvv?>??????>>+++>++.<br></br>
-uuuuuuux}~``.~^|?vrrvxxzwwzzzzzzzzzzwrr}}vv????>>>??v}}vvvvvv?>>>>+++++++`<br></br>
-uuuuuuuyx|-->zxzw}}rzuxxxxxxxxxxzzzzzwrrrrrvv???????vvvvvv??????>>+++++++.<br></br>
-uuuuuuuuv^~^uuuuuuuuuuuuxxxxxxxxzzzzwwrrrwzzzw}v???????????>>??????>>>>>?`<br></br>
-uuuuuuuv+?>+}uuuuuuuuuuxxxxxxxzzzzzzwwwwwwwzzzw}}v}}vvvvvvvv???????v}}}}r~<br></br>
-uuuuux?+vux?+wuuuuuuuxxxxxxzzwwwwwwwwwwwzzzzzzzwwwwwww}}}}}}vvvvvvv}}}}vv-<br></br>


            </div>,

            <div>

`rrrrrrrr>>v}~`````..............  .vv>^~`..````````----^^-``-~~~~-```````.<br></br>
-uyyyyyyyw+^?>-````-``..............~^vxzwv+~~`-`````-`.`~~-|^^^^|~------~.<br></br>
-uuuuuuuuyz>~~~-`..````````-````....^zuuuuv>?`.........    ..`-|||~--~~~|^`<br></br>
-uuuuuuuuzv>|~--````..`~~~--~~----``zx^>zu}?r+``..              .`---~|^+>`<br></br>
-uuuuuuurrv^^|~----``~^+++~~-------~zzv}}v??w^                     .-~||^+`<br></br>
-uuuuuurzuu?^^^^||~``-~^^|||~~------>uuu}?>|`                       .-~~|^.<br></br>
-uuuuuwxyuyx?+++++|``-----~~~-------}xxxw>.                          .-~~~.<br></br>
                  -uuuuxxyuuuyx?+^^+^|-`----~~~~----`?yzyr.                             ----.<br></br>
-uuuuuuuuuuuux?+^^^+^~--+?+^|~-----}z+>`                              `---.<br></br>
-uuuuuuuuuuuuuzv>^^+++~-|>?v?>?>^~```                                 .`~~.<br></br>
-uuuuuuuuuuuuuxw?++++++|~~^+++>>^..                                   ..`-.<br></br>
-uuuuuuuxxuuxzrv>+>^^||||~~|^||-.`| .                                 ...` <br></br>
-uuuuuuuxxxxzv>>>>>+^^^+++++>|. .`~ `                                   .. <br></br>
                  -uuuuuuuxxxxzw?++^^^^^^+++++` . ` -`.                                    . <br></br>
-uuuuuuuxxzww}v>++^+?||^^^~. `~`  `+.                           .-~.       <br></br>
-uuuuuuuuuzr}v??>+++v>||~` .`^~.   ^.                         .-^+++`..... <br></br>
-uuuuuuuuuxzwvv?>>++>>^~. .--.-    ..                    ..``-|||^>?-..... <br></br>
-uuuuuuuuuxxzr}vv?>+++- .-.~. .                   ...`~|^^^^-----~|`...... <br></br>
-uuuuuuuuuxxxxxzzzw}?` .-- `                ...`-~^+++^^>>?>+++>>>^`...... <br></br>
-uuuuuuuuuxxxxxxxxu}` .`..            ...`-~||||||~|^^~|^+>>>???>>+~`..... <br></br>
                  -uuuuuuuuuuxxxxxxx?. ...          .`-~||||||||||||||||~~--------``...``... <br></br>
-uuuuuuuuuuxxxzzx> ...       ..`~||||~~~~~~~~|||||||||~~~~~~~~~~--````---` <br></br>
                  -uuuuuuuuuuxxxzw^  .     .`|+^^^^^||||||~~~~~~~~~~||~||~--~~~~~~||~~~~~~^` <br></br>
-uuuuuuuuuuuxxz~     .~>???>>+++^^^^^^^^|||||||||||+^|||~~~~^>>??>^||~|^~. <br></br>
-uyuuuuuyyuuuw-   `^?}rr}}??>>>>+++>vr}v>+^^^||||^++||||||||>v}r}?>||||-`-.<br></br>
-rrxxzzz}????|.`?}xuxzr}}}}v???>>>>?rzzwrv?+^^^^+++^^^^^^^^>?vv???>+^|~~||.<br></br>
-xr}}vvv>`|~`?+|?zuxuuxzrrrrr}vv}rwzzwww}}v>>++++++>>?vvv?>>?????>>++++>++.<br></br>
-uuuuuuux}~``.~^|?vrrvxxzwwzzzzzzzzzzwr}}vv?????>>??v}}vvvvvv???>>++++++>>`<br></br>
-uuuuuuuyx|-->zxzw}}rzuxxxxxxxxxxzzzzwwrrrr}vv??????vvvvvv??????>>>+++++++.<br></br>
-uuuuuuuuv^~^uuuuuuuuuuuuxxxxxxxxzzzzwwrrrwzzw}vv???????????>??????>>>>>>?`<br></br>
-uuuuuuuv+?>+}uuuuuuuuuxxxxxxxxzzzzzwwwwwwwzzzwr}vv}}vvvvvvv???????v}}}rrr~<br></br>
-uuuuux?+vux>+wuuuuuuuxxxxxxzzwwwwwwwwwwwzzzzzzwwwwwwwr}}}}}vvvvvvv}}}}vv?`<br></br>


            </div>,

            <div>

`rrrrrrrrv}wv-``````.............  .|` .``-.......`~~-~||-`-~|~----`````-~.<br></br>
-uyyyyyyyv>v}^`--````............. >uv^~`..-~---```----|^~-~|^^^|~~-----~+`<br></br>
                  -uuuuuuuuuv|~^~``.``````.````.......~?yyxv^~-..`....`.....`-~|^^|~--~--~^?`<br></br>
-uuuuuuuyur>|~--``...```--------``.`rzwux??>````...           .`---~~|^+>?-<br></br>
-uuuuuuuz}?^|~--``-``-|^+|~-------`?u^-zx??x-...                 .`~~|^^>?`<br></br>
-uuuuuuwrxz+^^|~~~-``~^++||~~------+xzw??>}v.                      `~~|^+>`<br></br>
-uuuuyzwuuy}+++^^^~``--~~~||~-------}uuw?+`                         .~~~~|.<br></br>
-uuuuxwuuuuu}>+^++^-`-------------~wuxx>.                            `---~.<br></br>
-uuuuuuuuuuuu}+^^^+^~`~~^>^||~----?y}z?                              `---~.<br></br>
-uuuuuuuuuuuuur?+^+++|-->???+++^~-~?..                               .`~~~.<br></br>
-uuuuuuuuuuuuuxr?+^+++^~~|?>>>?>~.                                   ..`~^.<br></br>
-uuuuuuuuuuuuxzv>+>^^^^|~~~|+^^- ~.                                  ...`|.<br></br>
-uuuuuuuxxxxzv?>+>?^^^^^^^+++-. `+..                                  ...`.<br></br>
-uuuuuuuxxxxz}>++^^++++++++^` ...-``                                    .. <br></br>
-uuuuuuuxxzzzr?>+^^>+||^+^-. |`. .+`                            .`.     .. <br></br>
-uuuuuuuuxzr}???>+^?v^||~...`+`   +`                         .`|++|.     . <br></br>
-uuuuuuuuuxz}v??>+++?+^~. ~``|.   ..                     ..`-~|^^>?-...... <br></br>
-uuuuuuuuuxxw}v???>+++` .-~- `                   ....`~~~||~~~~~|+-`...... <br></br>
-uuuuuuuuuxxxxzwww}v?` .``-.                ..``-^+^++^>>>+^|^^++~........ <br></br>
-uuuuuuuuuxxxxxxxxu}` ..` .           ...`~~~||||~|^^||+>>???????>~....... <br></br>
                  -uuuuuuuuuuxxxxxxxv..`.           .`-~||||||||||||||^~~~-~~~~~~-``.`...... <br></br>
                  -uuuuuuuuuuxxzzzx? ``        ..`~||||~~~~~~~|||||||||~~~~~~~-----```````.. <br></br>
                  -uuuuuuuuuuxxxzw+  .     .`|^^^^^|||||~~~~~~~~~~~~||||~-~~~~~~~|~~-~~~~|.. <br></br>
-uuuuuuuuuuuxxz~     .~>???>>++^^^^^^^|||||||||||~^^|||~~~~^++>?>^||~|^-.`.<br></br>
-uyuuuuuyyuuuw-   `^?}rr}v?>>>+++++?}vv>+^^^||||^++^|||||||+v}r}?>||||``~~.<br></br>
-uwzuuxxxvv}v- ->}xxzw}v}}vv??>>>>?vzzwrv?+^^^^^++^^|^^^^^+?vvv??>+^|~~|||.<br></br>
-uwv}vvv?`|--?|>ruuuuxzwrrrr}}vvrwwzzwwr}v>>++++++>>??vv?>>?????>>+++>>++^.<br></br>
-uuuuuxxxv~```||>vrrvxxxzwwzzzzzzzzzwrr}}vv???>>>??v}}vvvvvv???>>++++++>>+.<br></br>
-uuuuuuuyu|--?xxzr}}wuxxxxxxxxxxzzzzzwrrrr}vv??????vvvvvv??????>>>++++++++`<br></br>
-uuuuuuuuv|~>yuuuuuuuuuuxxxxxxxxxzzzwwrrrwzzzrvv???????????>??????>>>>>>?>`<br></br>
-uuuuuuuv+?>+ruuuuuuuuuxxxxxxxzzzzzzwwwwwwzzzwr}}v}}}vvvvvv???????v}}}rrrv-<br></br>
-uuuuuu?+vyx>>zuuuuuuuxxxxxxzzwwwwwwwwwwzzzzzzzwwwwwwr}}}}}vvvvvvv}}}}vv??-<br></br>


            </div>,

            <div>

`rrrrrrrr}rw?~-`````.............  .|` .``-......`-~~-~~~-`~||~---``````~~.<br></br>
-uyyyyyyu??wr~`---``.............. >u?^~`.`----```-~~~|+^--~^^^||~------|>`<br></br>
-uuuuuuuuz>~+^-````-```...`.........~?uuzv^~-```...``...```~~|^^~------~>v`<br></br>
-uuuuuuuuyz>~~--`..```````----```..`rzwux??>```...           ..``--~||^^?v-<br></br>
-uuuuuuuxr?+|~-`````.`~^^~--------`?u^-zx??x~...                 .`~|^^>>?`<br></br>
-uuuuuuz}zr^^||~---`-|+>+|~~-------+xzw??>}v.                      `~|^+>?`<br></br>
-uuuuyzruyu?^+^^^^-``-~|~~||~-------}uuw?^`                         .~~||^.<br></br>
-uuuuuwuuuuuv>++++|``-------------~wuxx>.                            `--~^`<br></br>
-uuuuuuuuuuuuv^^^^^|-`~~|^|||~----?y}z?                              `--~~.<br></br>
-uuuuuuuuuuuuuv>^^+++~-~??>>^^|~--~?..                               `~~~|.<br></br>
-uuuuuuuuuuuuux}>+^+++~-~>??>??>-.                                   .`~|+`<br></br>
-uuuuuuuuuuuuxz}>++++^^|~~~^^^^- ~.                                  ..`~>.<br></br>
-uuuuuuuxxxxzv?>+>>^|||^^^^+^`. `+..                                 ....-.<br></br>
-uuuuuuuxxxxzv>+>+++++++++>+` ...-``                                   ..`.<br></br>
-uuuuuuuxxzzzr?>+^^+^|^+++~. |`. .+`                            ..      .. <br></br>
-uuuuuuuuxwr}??>++^??|||~...`+`   +`                         .`|^^`      . <br></br>
-uuuuuuuuuxw}v?>>++>?+^-. ~``|.   ..                     ..`-|^^+?^....... <br></br>
-uuuuuuuuuxxw}v??>++++` .-~- `                   ...``-~~^~-~~~~+^``...... <br></br>
-uuuuuuuuuxxxzzwwwv?>` .``-.                ..``~+^^+^+>>>^|||^^^`........ <br></br>
-uuuuuuuuuxxxxxxxxu}` ..` .           ...`~~~|||||^+^~^>>????????+`....... <br></br>
                  -uuuuuuuuuuxxxxxxxv..`.           .`-~||||||||~||||^|~~~~~||~~~-``........ <br></br>
                  -uuuuuuuuuuxxzzzx? ``        ..`~||||~~~~~~||||||||||~~~~~~----`````````.. <br></br>
                  -uuuuuuuuuxxxxzw+  .     .`|^^^^^|||||~~~~~~~~~~~||||~~-~~~~~~~~~--~~-^`.. <br></br>
-uuuuuuuuuuuxxz|     .~>>>>>>++^^^^^^|||||||||||~|^||||~~~|+++?>^||~~^^.``.<br></br>
-uyuuuuuyyuuuw-   `^?}rr}v?>>>+++++?}v?>^^^|||||^>+|||||||^?}r}v?^||^~`-~~.<br></br>
-uwzuuxxxvv}v- ->}xxzw}v}}v???>>>>?}zzw}v>+^^^^^+^^||^^^^^?vvv???+^|~~||||.<br></br>
-uwv}vvv?`|--?|>ruuuuxzw}rrr}vvvrwwzzwwr}v>>+++++>>>?vv?>>?????>>>+++>+++^.<br></br>
-uuuuuxxxv~```|^>vrrvxxxwwwzzzzzzzzzwr}}}v????>>>??v}}vvvvv????>++++++>>++.<br></br>
-uuuuuuuyu|--?xxzr}}wuxxxxxxxxxxzzzzwwrrrr}vv??????vvvvvv??????>>+++++++++`<br></br>
-uuuuuuuuv^~>yuuuuuuuuuuxxxxxxxxxzzzwwrrrzzzw}v???????????>>??????>>>>>??>`<br></br>
-uuuuuuuv+?>+ruuuuuuuuuxxxxxxxzzzzzzwwwwwwzzzwr}v}}}vvvvvvv??????v}}}rrr}?-<br></br>
-uuuuuu?+vyx>>zuuuuuuuxxxxxxzwwwwwwwwwwwzzzzzzzwwwwww}}}}}}vvvvvv}}}}vv???`<br></br>


            </div>,

            <div>

`rrrrrrrrwww}?^~-````.................``-~-. ...`~~---~-`-~|~~---``````-~~.<br></br>
                  -uyyyyyyuvwuv-----``...............+?`...``````..-~|||++~-~|^^||~~-----|+^.<br></br>
-uuuuuuuuv^+?^-``````..............??v}?^`-|---`````..`~-`~|^^^|-------^?>.<br></br>
                  -uuuuuuuuyr^~~~``.````````-````....`vxyyxv+``........     ...``---~~~||???`<br></br>
-uuuuuuuuw?+|---```..`-~~~-~~----``rr~vuw+}+``..                .`~|^++>?v-<br></br>
-uuuuuuxrrv^^|~----`-^+++|~-------`wz?vr}?x>                      .~||+>??-<br></br>
-uuuuyx}xyx>^^^^||``-~|^|||~~-----`+uyz?>|-                        .~|^^+?-<br></br>
-uuuuurxyuyw>+++++~``-----~~------~}xxw?`                           .~-~|>`<br></br>
                  -uuuyxxyuuuyz?^^^+^~`-~-~||~~----`vuwy^                             .--~|+`<br></br>
-uuuuuuuuuuuuz?+^^++^--|??+^|~---->?`-                              .~~~|^.<br></br>
-uuuuuuuuuuuuuzv>^^++^--^?v????^`.                                  .`~|^+.<br></br>
-uuuuuuuuuuuuuz}>+++++^|~~|^+++`.~                                  ..`~>>`<br></br>
-uuuuuuuxxxuz}v>+>>|||||||^+^`. ~+ .                                ....-|.<br></br>
-uuuuuuuxxxxw?++>+>+++++++>^`  `.^..                                  ..`~.<br></br>
                  -uuuuuuuxxxxzr?+^^^+^|^+++~. ~.. |+.                                   ..`.<br></br>
-uuuuuuuuxwr}v?>++^?>|||~.  -+.  `?                          .`||`      .. <br></br>
-uuuuuuuuuzw}v?>>++>?^^-  |``|    `                      ..`-|^^?>........ <br></br>
-uuuuuuuuuxzrvv??>+++^` .-|`..                     .``--~|-~~~~^>~`....... <br></br>
-uuuuuuuuuxxxzwrr}??+. .~.~.                ..`-^+|^+^+>>+~~~~|^`......... <br></br>
-uuuuuuuuuxxxxxxxxxv..-`-              .``-~~|||~^++||>>????????>-........ <br></br>
                  -uuuuuuuuuuxxxxxxuv.`|`.          .`-~|||^||||||||^^~~~~~|||||~-``........ <br></br>
                  -uuuuuuuuuuxxzzxx?.`~.       ..`~||||~~~~~||||||||||~~~~~-----`````````... <br></br>
                  -uuuuuuuuuxxxxwz+ .`     .`||^^^^||||~~~~~~~~~~~|||||~~~~~~~~~~~---~-|-..` <br></br>
-uuuuuuuuuuxxxz|     .~+>>>>++^^^^^^|||||||||||~~^^|||~~~~^+^>>+||~~^^`.`-.<br></br>
-uuuuuuuuyuuuz~   `|?vrr}v?>>>++++>vv?>^^^|||||^+>^|||||~|>v}}v?^~|^|``-~~.<br></br>
-uxzuuuuxwrw}` ->}xxzr}v}}v??>>>>>?wzwrv?+^^^^^++^|||^^^^>?vvv??>^|~-~||||.<br></br>
-uxv}}vv?-|~~>~?xuuuuxwr}rrr}vv}rwwzwwr}v?>++++++>>??v??>?????>>>++++++^^^.<br></br>
-uuuxxxxzv~``-||?}w}ruxzwwzzzzzzzzzwwr}}vv???>>>>?v}}vvvvv????>>++++>>>+++.<br></br>
-uuuuuuuyu^`-?xzw}}}zuxxxxxxxxxxzzzzwrrrr}vv??????vvvvvv??????>>+++++++++>`<br></br>
-uuuuuuuyv|~?yuuuuuuuxuxxxxxxxxxzzzzwwrrwzzwrvv??????????>>??????>>>>>??>>`<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxxxzzzzzzwwwwwwzzzzr}}v}}}vvvvvv??????v}}}rrr}??`<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxxxzwwwwwwwwwwzzzzzzzwwwwwwr}}}}}vvvvvvv}}}vv????-<br></br>


            </div>,

            <div>

`rrrrrrrrwwrrv>^~-`````..............---~~-...`-|-----~``~||~~`--``````-~~.<br></br>
-uyyyyyyuwxyv~~~--``...............>?`.``~`.....`~|^|^+^~~|^^|~|~-----~^^^.<br></br>
-uuuuuuuu?^?v~``````...............??v}?^`~|~--```-``-^|`-~|^^^~------~>?^.<br></br>
                  -uuuuuuuuuv|~|-`..```````````..... `vxyyxv+`........       ..``---~~~~^???`<br></br>
-uuuuuuuyxv+|---``...``---------```rr~vuw+}+``..                .`~^++>??v~<br></br>
-uuuuuuuw}?^|~-----`-|++^~~-------`wz?vr}?x?                      .~|^>??v-<br></br>
-uuuuyu}zyz+^^^|~~``-~^+|||~~-----`+uyz?>|-                        .~^^+?v-<br></br>
-uuuuurxyuyr+++^+^-``----~~~------~}xxw?`                           .~~~^>`<br></br>
-uuuyxzyuuuyr>+^++|-`----~~~~----`vuwy^                             .--~^>`<br></br>
-uuuuuuuuuuuyr>+^^++|--+?+^|~----->?`-                              .~--^^.<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>vv????|`.                                  .~||++.<br></br>
-uuuuuuuuuuuuuz}>+++++^~~^^++>+`.~                                  .`~+>>`<br></br>
-uuuuuuuxxuuzrv?^>+||||||||+|-. ~+ .                                ...-|+.<br></br>
-uuuuuuxxxxxw?>>>>>+++++++>^`  `.^..                                 ...-^.<br></br>
                  -uuuuuuxxxxxzr?+^^^+^^++++~. ~.. |+.                                  ...~.<br></br>
-uuuuuuuuxwr}v?>+^+?+||||.  -+.  `?                          .`|`.     ..`.<br></br>
-uuuuuuuuuzrv??>>++??^|-  |``|    `                       .`~^^+?`.......` <br></br>
-uuuuuuuuuxzrvv??>++>^` .-|`..                     .``-`|~-~~~|>+`.........<br></br>
-uuuuuuuuuxxzwr}}v??+. .~.~.                ..`~>^|++++++|~~~~||...........<br></br>
-uuuuuuuuxxxxxxxxxxv..-`-              .``-~~||||^+^|^>????????>|.......`. <br></br>
                  -uuuuuuuuuxxxxxxxuv.`|`.          .`-~|||^|||~||||^|~~~~|^^^^|~-``........ <br></br>
                  -uuuuuuuuuuxxzzxx?.`~.       ..`~~|||~~~~~||||||||||~~~------`````````.... <br></br>
                  -uuuuuuuuuxxxxzz+ .`     .`||^^^^|||~~~~~~~~~~~~||||~~~~~~~~~~~-----~|...`.<br></br>
-uuuuuuuuuuxxxz|     .~+>>>>++^^^^^||||||||||||~~^|||~~~~|+^^>>|~|~~+~.`-~.<br></br>
-uuuuuuuuyuuuz~   `|?vrr}?>>>+++++>vv?>^^^|||||^++||||||~^?}}}v>|||^~`-~~~.<br></br>
-uxzuuuuxwrw}` ->}xxzr}v}vv??>>>>>?zzwrv?+^^^^^+^^|||^^^^?vvvv??+^|-~|||||.<br></br>
-uxv}}vv?-|~~>~?xuuuuxwr}rr}}vv}rwwzwwr}v>>++++++>>??v?>>?????>>>++++++^^^.<br></br>
-uuuxxxxzv~``-||?}w}ruxzwwwzzzzzzzzwwr}}v????>>>??v}}vvvvv????>++++>>>+++^.<br></br>
-uuuuuuuyu^`-?xzw}}}zuxxxxzxxxxxzzzzwr}rr}vv??????vvvvvv?????>>>++++++++>?`<br></br>
-uuuuuuuyv|~?yuuuuuuuxuxxxxxxxxxzzzwwrrrwzzw}vv??????????>>?????>>>>>>?>>?`<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvvv??????v}}}rrrv??`<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxxzzwwwwwwwwwwzzzzzzzwwwwww}}}}}}vvvvvv}}}vvv???v-<br></br>


            </div>,

            <div>

`rrrrrrrrwrrwrv>+|~-````.........``-`-~|^~-.``-~~-``--``-|^|~``-```````-|^.<br></br>
-uyyyyyyuxyyv+|~--``...............>?``--~.....`~|^||^^~~|^^^|||~----~|^^^.<br></br>
-uuuuuuux??rv-`---`................?vv}?^--~---``----^+-`-~^^^|~------^>^~.<br></br>
-uuyuuuuuz>~^|-````-```..``....... `?xyyxv+`.``.....  .    ..``--~~--~>v?>`<br></br>
-uuuuuuuyu}+~~-``..`````-------``.`rr|vuw+}+``.                 .-|^^+??vr|<br></br>
-uuuuuuuzv>^|~-``-```|^+^~--------`ww?vw}?x?.                     .|^+>??v~<br></br>
                  -uuuuuurrur^^^|~~~``~|++^|~~------`+uyw?+|-                        .|++??v-<br></br>
-uuuuywzyuuv+++^+^``--~~~||~------~}xxw?`                           `~~^>?-<br></br>
                  -uuuyxzyuuuuv++^++~``----~~~-----`vuwy^                             .-~^+?`<br></br>
-uuuuuuuuuuuuv+^^^+^~-~+?^^|~----->?`-                              .--|^+`<br></br>
-uuuuuuuuuuuuu}>+^+++~`^?v?>>>+~`.                                  `~~^++.<br></br>
-uuuuuuuuuuuuuzv>++++^|~~++>>>+..~                                  .-^>>+.<br></br>
-uuuuuuuxxuuxw}?^>+^||||~||+|-. ~+ .                                ..`^^+.<br></br>
-uuuuuuxxxxxr?>>>>>^^+++++>^.  `.^..                                ...`|^.<br></br>
-uuuuuxxxxxxz}>++^^+^^++++~  ~.. |^.                                  ..-^.<br></br>
-uuuuuuuxxwr}v?>+^+?^||^|.  -+.  `>                          .`-.      .`-.<br></br>
-uuuuuuuuuzrv??>+++?>||-  |``|    -                       .`~^^?^.......``.<br></br>
-uuuuuuuuuxzrv??>+++>^`  -|-..                     ..``-~-~~||+?|`.......` <br></br>
-uuuuuuuuxxxzwr}}v?>^. .-.~.                ..`^+~|+++++^----~^`.........`.<br></br>
-uuuuuuuuxxxxxxxxxzv..-`-              .``--||||^++^|>?????????+`.....`..`.<br></br>
                  -uuuuuuuuuxxxxxxxuv.`^`.          .`-~|~|^|||~||~|^~~~||^^^+^^|-`........`.<br></br>
                  -uuuuuuuuuuxxzxxx?.`~.       ..`~~|||||~~||||||||||~~~------`````````....`.<br></br>
                  -uuuuuuuuuxxxzzz+ .`     .-||^^^^|||~~~~~~~~||||||||~~~~~~~~~~~---~-|`..`-.<br></br>
-uuuuuuuuuuxxxz|     .-^+>>>++^^^^^||||||||||~~~|||||~~~~^^|+>^~~~~^+..`~~.<br></br>
-uuuuuuuuyuuuz~   `^?}rrv?>>>+++++?v??+^^||||||^>^|||||~|>v}}v?^~|^|``~~~~.<br></br>
-uxzuuuuxwrw}` ->}xxwrvv}v???>>>>>vzzw}?>+^^^^^+^||||^|^+?vvv??>^|~-~|||||.<br></br>
-uxv}}vv?-|~~>~?xuuuuzwrrrr}vvv}rwwzwwr}?>>++++++>>????>>?????>>+^++++^^^^.<br></br>
-uuuxxxxzv~``-||?}w}rxxzwwzzzzzzzzzwr}}}v???>>>>??v}}vvvvv???>>++++>>>++++.<br></br>
-uuuuuuuyu^`-?xzw}}}zuxxxxxxxxxzzzzwwrrrr}v??????vvvvvv??????>>+++++++++?>`<br></br>
-uuuuuuuyv|~?yuuuuuuuxxxxxxxxxxxzzzwwrrrzzzw}v??????????>>??????>>>>>?>>>>`<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxxzzzzzzzwwwwwwzzzwr}}}}}}vvvvv??????v}}}rrrv???-<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxzzzwwwwwwwwwwzzzzzzzwwwwww}}}}}vvvvvvv}}}vv???vv-<br></br>


            </div>,

            <div>

`rrrrrrrrwrrwrv?>+^|~~~`.......`-~~--|^+^--`-~~~-```--``~|^|-`-```````-~|+`<br></br>
-uyyyyyyuuyy}?^|~--```.............|^``~~~....`-|||||^|~~^+^|~|~-----|^^^+`<br></br>
-uuuuuuyx?vz?``---`................vv?>|-`---```-~~~|+^---|^^^|~-----~+>|~.<br></br>
-uuyuuuuur+|+|-````-```..``....... .?uyyw?~````..`.. ..   ..``---~---|??+>-<br></br>
-uuuuuuuyur^~~-``..``````----```..`rv+wu}?v``...               ..~|^^>?vvw|<br></br>
-uuuuuuux}>^~--````.`~^^|-~------`~uv>}z}vu|.                     -^+>??v}~<br></br>
-uuuuuuwrx}^^||~-~``~^++^|~-------`?uyr>+^|                        `^+>??v-<br></br>
                  -uuuuywwuyu?^+^^^|``--~~~||~-------vzxw?`                           -||+?v-<br></br>
-uuuyxwyuuux?+++++~``------------`vuxu`                             .~|+>?`<br></br>
-uuuuuuuuuuuu?^^^^+|--~^>^||~-----??~-                              .-~|+>`<br></br>
-uuuuuuuuuuuuxv>^^++^~-+???>>>^~`.                                  `~~++^.<br></br>
-uuuuuuuuuuuuuzv>++++^~~|>>>>?+..~                                  `|+>>+.<br></br>
-uuuuuuuxxuuxz}?+>+^^|||~~|^|-. |^ .                               ..`|^++.<br></br>
-uuuuuuxxxxxr?>>+?+^^^^+++>^.  `.^..                               ....~^^.<br></br>
-uuuuuxxxxxxzv>++^^+^+++++~  |`. ^|                                  ..`|^.<br></br>
-uuuuuuuxxwwrv?>+^+?||^^|.  ~+.  ~>                          .`.      ..`~.<br></br>
-uuuuuuuuxzrv??>+++v>||` .|`-|    -                       .`|^+?`..... .``.<br></br>
-uuuuuuuuuxz}v??>+++>^`  ~^-..                     ..``---~|||>?-........`.<br></br>
-uuuuuuuuxxxzr}vv?>>^. ``.|                 ..`^|~^+++++|----~|..........-.<br></br>
-uuuuuuuuxxxxxxxxxw?. `~. .            .``--||||^++|^>???>????>|......`..-.<br></br>
                  -uuuuuuuuuxxxxxxxuv..^..          .`-~~~^^|||||~|^^~~||^++++^^|-`........`.<br></br>
                  -uuuuuuuuuuxxzxxx?.`-.       ..`~~|||||~~||||||||||~~~------`````````....` <br></br>
                  -uuuuuuuuuxxxzzz+ .`     .-~|^^^|||~~~~~~~~|||||||||~~~~~~~~~~-----~|...`-.<br></br>
-uuuuuuuuuuxxxz~     .~^+>>>++^^^^||||||||||~~~~|||||~~~~^||>>|~~~~+~.`-~~.<br></br>
-uuuuuuuuuuuuw-   .^?}rrv?>>>+++++?v?>^^|||||||+>^||||~~|?v}}v>|||^~`-~~~~.<br></br>
-uuzuuuuuzwz}` ->}zzw}vv}v??>>>>>>}zwwv?>^^^^^^+^||||^|^>?vvv??+||--||||||.<br></br>
-uuv}}vv?~|~~>|vxuuuxzwrrrr}v?v}rwwzwwr}?>+++++++>>???>+?????>>>+^++++^^^^.<br></br>
-uuuxxzzzv~``-||?}w}zxxzwwzzzzzzzzzwr}}vv???>>>>?v}}vvvvv????>++++>>>+++^+.<br></br>
-uuuuuuuuu^`-?zzw}}}xxxxxxzxxxxzzzzwwrrr}vv??????vvvvvv?????>>>++++++++>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuuxxxxxxxxxxzzzzwwrrwzzwrvv??????????>>?????>>>>>??>>?>`<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxxzzzzzzzwwwwwwzzzr}}}}}}vvvvvv??????v}}}rrrv???-<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwwr}}}}}}vvvvv}}}vvv???vv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrww}v?>+^||~````..`-~~~~~|+++~-~-||~~-``--```|^^~-`-```````-~^>`<br></br>
-uyyyyyyyyyywv>^|~-````............|+`-~|~....-~|||~|^~~|^+^|~|~----~||^^>-<br></br>
-uuuuuuyx?}x?`----`................vv?>^~```````-~|~^+|--~^^^||~-----|+^||.<br></br>
-uuyuuuuuv^^>|-```-```............ .?uyyw?~`````.`.....   ..```------+??^?~<br></br>
-uuuuuuuyyr^~~-``..``````---```...`rv+wu}?v`....                `~|^|?vvvz|<br></br>
-uuuuuuux}>^~--````.`~|^~-~------`-uv>}z}vu|.                     -^+>??vr~<br></br>
-uuuuuuw}zv^^|~--~``~^>+^~~-------.?uyr>+^|                        `+>??vv-<br></br>
-uuuuywwuyu?^+^^^~``-~|~~^|~-------vzxw?`                           -|^>?v-<br></br>
-uuuyxwuuuyz?++++^-``------------`vuxu`                             .~^+>?`<br></br>
-uuuuuuuuuuyx?^^^+^~`-~^+^||~-----??~-                              .~~^+>`<br></br>
-uuuuuuuuuuuuxv>^+++^-->??>+++|-`.                                  `~|+^+.<br></br>
-uuuuuuuuuuuuuzv+^+++^~~^?>>??^..~                                  `^+>>+.<br></br>
-uuuuuuuxxuuxzr?+>+^^^|~~~^^^-  |^ .                               ..-++++.<br></br>
-uuuuuuxxxxxr?>>+?+^^^^+++>^.  `.^..                               ...`|^^.<br></br>
                  -uuuuuxxxxxxzv+++^++^+++++~. |`. ^|                                  ..-^^.<br></br>
-uuuuuuuxxwwrv?+^^>?||^^|.  ~+.  ~>                          .`.     ...-~.<br></br>
-uuuuuuuuxwrv??>++>v+||` .|`-|    -                       .`|^>>..... ..``.<br></br>
-uuuuuuuuuxz}v?>>++>>^`  ~^-..                     ...`---~||^?>`.......`-.<br></br>
-uuuuuuuuxxxzr}vv?>+^. ``.|                 ..-+~~^^++^+~----|~.........`-.<br></br>
-uuuuuuuuxxxxxxxxxw?. `~. .            .``-~|||^+++|+>???>????+-.....`..`-.<br></br>
                  -uuuuuuuuuxxxxxxxuv..^..          .`-~~|^^||~||~|^|~~|^^+++++^|`.........`.<br></br>
                  -uuuuuuuuuuxxzxxx?.`-.       ..`~~|||||~|||||||||||~~~------`````````....`.<br></br>
                  -uuuuuuuuuxxxzzz+ .`     .-~|^^^|||~~~~~~~~||||||||~~~~~~~~~~~-----~-...--.<br></br>
-uuuuuuuuuuxxxz~     .-^+>>>++^^^^||||||||||~~~~^|||~~~~|^|^>+|~~~~>`.`-~~.<br></br>
-uuuuuuuuuuuuw-   .^?}r}v?>>++++++?v?>^^||||||^+>|||||~~^?v}v?+~||^-`-~~~~.<br></br>
-uuzuuuuuzwz}` ->}zzw}vv}v??>>>>>?rzwrv?+^^^^^++||||^^|^?vvvv?>^^|-~||||||.<br></br>
-uuv}}vv?~|~~>|vxuuuxzwrrrr}v?v}rwwzwwrv>>+++++++>????>>?????>>+^++++^^^^^.<br></br>
-uuuxxzzzv~``-||?}w}zxxzwwzzzzzzzzzwr}}vv???>>>>?v}}vvvvv????>++++>>>+++++.<br></br>
-uuuuuuuuu^`-?zzw}}}xxxxxxxxxxxzzzzwrrrr}vv??????vvvvvv?????>>+++++++++>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuuxxxxxxxxxxzzzzwwrrwzzwrvv??????????>>?????>>>>>??>>>?-<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}}vvvvv???????v}}}rr}????-<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzzwwwww}}}}}}vvvvvv}}}vv????vv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv?>++^|-`---~~~~~~|^+>>^--~|+|--```--``-^^^~--````````~|+?`<br></br>
-uyyyyyyyyyyz}?+^|~`````..........`^+-~|^-...`~|||~~||~~^++^~~|~----~||^+?-<br></br>
                  -uuuuuuyxvwu?`---``.............. .vv??^~````..`~|||++~--|^^|||-----~^+||+-<br></br>
-uuyuuuuuv^>?~```````............. .?uyyw?|`--````....    ..```-----~>?+|?|<br></br>
-uuuuuuuuu}|~~-`..```````--```.....}v+wu}?v`....               .`~||^?v?vz|<br></br>
-uuuuuuuur?^~--````.`-~|~-------``-uv>}z}vu|.                    .~+>??v}w~<br></br>
-uuuuuuz}w?^^|~---``|+>+|~~------`.?uyr>+^|                        `+>??vv-<br></br>
-uuuuyzruyx>^+^^^~``-~||~^|~-------vzxw?`                           ~^+???-<br></br>
-uuuuuruuuyw>++++^``-------------`vuxu`                             .~^>??`<br></br>
-uuuuxuuuuuyz>^^^+^~`~~|^|||------??|-                              .~|+>?`<br></br>
-uuuuuuuuuuuuz?+^+++|--???>^^^~-`.                                  `~^+^+`<br></br>
-uuuuuuuuuuuuuz?+^+++|~~+??>??^..~                                  ~^+>++.<br></br>
-uuuuuuuuuuuuzr?+>+^^^|~~~^^^-. |^ .                               .`|++++.<br></br>
-uuuuuuxxxxxr?>>+?+|^^^^^++|.  `.^..                               ...-^^^.<br></br>
                  -uuuuuxxxxxxwv+++^++++++++~. |`. ^|                                 ..`|^^.<br></br>
-uuuuuuuxxzwwv?+^^>>||^^|.  ~+.  ~>                          ..      ..`~~.<br></br>
-uuuuuuuuxw}v??>++>v+||- .|`-|    -                       .-|^>~..  . .```.<br></br>
-uuuuuuuuuxz}v?>>++>>^`  ~^-..                     ...`-`~|||+?^........`-.<br></br>
-uuuuuuuuxxxwr}vv?>+^. ``.|                 ..~^-~^^+^^^-----^-.........`~.<br></br>
-uuuuuuuuxxxxxxxxzr?. `~. .            .``-~|||^++^|>>??>>???>|`.....`..`-.<br></br>
-uuuuuuuuuxxxxxxxuv..^..          .`-~~|^^||~||~|+|~|^^+>+>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxxxx?.`-.       ..`~~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz^ .`     .-~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------|`..`--.<br></br>
-uuuuuuuuuuxxxw~     .-|+>>++^^^^|||||||||||~~~~||||~~~~|^~+>^~~~~^+..`~~-.<br></br>
-uuuuuuuuuuuuw-   .^?}r}v?>>++++++???+^|||||||^++|||||~~+vv}v?|~|^|``~~~~~.<br></br>
-uuzuuuuuzwz}` ->}zzw}v}}v??>>>>>?wzw}v>+^^^^^+^|||||||+?vvvv?>|^~-~|||||^.<br></br>
-uuv}}vv?~|~~>~vxuuuxzr}rrrvv?v}rwwwww}v>++++++>>>????+>?????>>+^++++^^^^|.<br></br>
-uuuxxzzzv~``-||?}w}zxxwwwzzzzzzzzzwr}}v????>>>??v}}vvvv????>>+++>>>>++^++.<br></br>
-uuuuuuuuu^`-?zzw}}}xuxxxxxxxxxzzzzwr}rr}v???????vvvvvv?????>>+++++++++>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuuxxxxxxxxxxzzzzwwrrwzzw}v???????????>>?????>>>>>?>>?>?~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzzwwwwwzzzwr}}}}}}vvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+vyx?>zuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwww}}}}}}vvvvvvr}}vv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>++^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~--```........```|^~|^^-..`-|||~~~|~~~^+^^~~~~----~||^>v-<br></br>
-uuuuuuyxvzu>----``.............. .}v++~-```...`||||++~--|^^||~-----~^^||>~<br></br>
-uuyuuuuu?^>?~``````.............. `?uyxr>~---````..``.  ...``------~>?^|v|<br></br>
-uuuuuuuyuv~~~-`..```````-````....`}v>zu}??.....               .`~|~+vv?}z|<br></br>
-uuuuuuuur?^~---```.`-~|~-------`.^y?|}xr}u-.                    .~+>??vrz~<br></br>
-uuuuuuz}w?^|~~---``|+>+|~~------`.vuuv>+>+                        ->???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------vxuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>+++>^``-------------`vuxx`                             `|+>??`<br></br>
-uuuuxuuuuuyz>^^^+^-`~~|^|||------?>|-                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  `~^^^+`<br></br>
-uuuuuuuuuuuuuz?+^+++|-~>??>??|..- .                                ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^-  ^| .                               .`^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^^++|.. `.|..                               ...~^^^.<br></br>
                  -uuuuuxxxxxxw?+++^++++++++- .|.. +|                                 ..`|^^.<br></br>
-uuuuuuuxzzwwv?+^^>>~|^+|.  ^^.  ^^                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||- .~`-|   .-                      ..-|^>` .    .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ~+`..                     ...`--~|||+?|........`~.<br></br>
                  -uuuuuuuuxxxwrvv??++^. ``.|                 ..~^-~^^+^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?. .~` .            ..``~||^+++^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~ .          .`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxxxx?..-.       ..`-~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ .`     .-~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------~...`--.<br></br>
-uuuuuuuuuuxxxz|     .~|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuuuw-   `^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuzuuuuuzwz}` ->}zzr}v}}v??>>>>>?wzw}?>+^^^^^+^|||||||+?vvv??+||--~|||||^.<br></br>
-uuv}}vv?-|~~+~vuuuuxzr}rr}vv?v}rwzwwr}v>++++++>>>????+??????>>+^++++^^^^|.<br></br>
-uuuxxzzzv~``-||?}wvxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuyu^`-vxzw}}rxxxxxxxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>??????>>>>>?>>?>?~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>zuuuuuuuxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>++^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........```|^~|^^-..`-|||~~~|~~~^+^^~~~~----~||^>v-<br></br>
-uuuuuuyxvzu>----``.............. .}v++~-```...`||||++~--|^^||~-----~^^||>~<br></br>
-uuyuuuuu?^>?~``````.............. `?uyxr>~---````..``.  ...``------~>?^|v|<br></br>
-uuuuuuuyuv~~~-`..```````-````....`}v>zu}??.....               .`~|~+vv?}z|<br></br>
-uuuuuuuur?^~---```.`-~|~-------`.^y?|}xr}u-.                    .|+>??vrz~<br></br>
-uuuuuuz}w?^|~~---``|+>+|~~------`.vuuv>+>+                        ->???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------vxuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>+++>^``-------------`vuxx`                             `|+>??`<br></br>
-uuuuxuuuuuyw>^^^+^-`~~|^|||------?>|-                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  `~^^^+`<br></br>
-uuuuuuuuuuuuuz?+^+++|-~>??>??|..- .                                ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^-  ^| .                               .`^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^^++|.. `.|..                               ...~^^^.<br></br>
                  -uuuuuuxxxxxw?+++^++++++++- .|.. +|                                 ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^+|.  ^^.  ^^                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||- .~`-|   .-                      ..-|^>` .    .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ~+`..                     ...`--~|||+?|........`~.<br></br>
-uuuuuuuuxxxw}vv??++^. ``.|                 ..~^-~^^+^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?. .~` .            ..``~||^+++^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~ .          .`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxxxx?..-.       ..`-~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ .`     .`~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------~...`--.<br></br>
-uuuuuuuuuuxxxz|     .~|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuuuw-   `^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuzuuuuuzwz}` ->}zzr}v}}v??>>>>>?wzw}?>+^^^^^+^|||||||+?vvv??+||--~|||||^.<br></br>
-uuv}}vv?-|~~+~vuuuuxzr}rr}vv?v}rwzwwr}v>++++++>>>????+??????>>+^++++^^^^|.<br></br>
-uuuxxzzzv~``-||?}wvxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuuu^`-vxzw}}rxxxxxxxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>??????>>>>>?>>?>v~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>zuuuuuuuxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---``........```|^~|^^-..`-|||~~~|~~~^+^^~|~~----~||^>v-<br></br>
-uuuuuuyxvzu>----``.............. .}v++~-```...`||||++~--|^^||~-----~^^||>~<br></br>
-uuuyuuuu?^>?~``````.............. `?uyxr>~---````..``.. ...``------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````....`}v>zu}??.....               .`~|~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|~-~-----`.^y?|}xr}u-.                    .|+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------`.vuuv>+>+                        ->???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------vxuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>++++^``-------------`vuxx`                             `|+>??`<br></br>
-uuuuxuuuuuyw>^^^+^-`~~|^|||------?>|-                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  `~^^^+`<br></br>
-uuuuuuuuuuuuuz?+^+++|-~>??>??|..- .                                ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^-  ^| .                               .`^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^^++|.. `.|..                               ..`~^^^.<br></br>
                  -uuuuuuxxxxxw?+++^++++++++- .|.. +|                                 ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^^|.  ^^.  ^^                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||` .~`-|   .-                      ..-|^>` .    .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ~+`..                     ...`--~|||+?|........`~.<br></br>
-uuuuuuuuxxxw}vv??++^. ``.|                 ..~^-~^^^^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?. .~` .            ..``~||^+++^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~ .          .`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxxxx?..-.       ..`-~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ .`     .`~||^^|||~~~~~~~|||||||||~~~~~~~~~~------~...`--.<br></br>
-uuuuuuuuuuxxxz|     .~|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuuuw-   `^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuzuuuuuzwz}` ->}zzr}v}}v??>>>>>?wzw}?>+^^^^^+^|||||||+?vvv??+||--~|||||^.<br></br>
-uuv}}vv?-|~~+~vuuuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uuuxxzzzv~``-||?}wvxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuuu^`-vxzw}}rxxxxxxxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>xuuuuuuuxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---``........```~~~|^^-..`-|||~~~|~~~^+^^~~~~----~||^>v-<br></br>
-uuuuuuyxvzu>----``.............. .rv|^--```...`||||++~-~|^^||~-----~^^||>~<br></br>
-uuuyuuuu?^>?~``````.............. `?uuzv+~---````..``.....``-------~>?^|v^<br></br>
-uuuuuuuuuv~~~-`..```````-````.....v}vxuv?+.....               .-~|~+vv?}z|<br></br>
-uuuuuuuur?^~---```.`-~|~-~-----`.^y?^wuwww`.                    .^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------``ruuv?^>|                        |>???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------?xuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>++++^``-------------`vuuw.                             `|+>??`<br></br>
-uuuuxuuuuuyw>^^^+^-`~~|^|||------?>>-                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>??>??|..`                                 .|^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^- .+-                                 .`^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^^++|.  ``~.                                ...~^^^.<br></br>
                  -uuuuuuxxxxxw?+++^++++++++- .~` .+`                                ...`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^^|.  ^|.  +-                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||` .``~-   .`                      ..-|^>` .    .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ||`..                    ....`--~|||+?~........`~.<br></br>
-uuuuuuuuxxxw}vv??++^. .``|                 ..|^-~^++^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?...~.              .```~|||+++^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~..         ..`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxxxxx>.`-.       ..-~~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ ``     .-~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------~...`--.<br></br>
-uuuuuuuuuuxxxw~     .~|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuxuw-   .^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuxuuuuuxzxr` `>}zzw}v}}???>>>>>?wzw}?>+^^^^^+^|||||||+?vvv??+||--~|||||^.<br></br>
-uuvv}}v?~^|~^|}uuuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uuuxxzzz?~``~~|vrwvxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuyu^`-vzzrv}ruxxxxxxxxxxzzzzwr}rr}v??????vvvvvv??????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>??????>>>>>?>>?>v~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrrwrvv??>>+^~~~~~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---``........```~~~|^^-..`-|||~~~|~~~^+^^~~~~----~||^>v-<br></br>
-uuuuuuyxvzu>----``.............. .rv|^--```...`||||++~--|^^||~-----~^^||>~<br></br>
-uuuyuuuu?^>?~``````.............. `?uuzv+~---````..``.....``-------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````.....v}vxuv?+.....               .-~|~+vv?}z|<br></br>
-uuuuuuuur?^~---```.`-~|~-~-----`.^y?^wuwww`.                    .^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------``ruuv?^>|                        |>???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------?xuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>++++^``-------------`vuuw.                             `|+>??`<br></br>
-uuuuxuuuuuyw>^^^+^-`~~|^|||------?>>-                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>??>??|..`                                 .|^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^- .+-                                 .`^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^^++|.  ``~.                                ...~^^^.<br></br>
                  -uuuuuuxxxxxw?+++^++++++++- .~` .+`                                 ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^^|.  ^|.  +-                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||` .``~-   .`                      ..-|^>`      .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ||`..                    ....`--~|||+?|........`~.<br></br>
-uuuuuuuuxxxw}vv??++^. .``|                 ..|^-~^++^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?...~.              .```~|||+++^|>???>>???>~.....``..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~..         ..`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxxxxx>.`-.       ..-~~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ ``     .-~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------~...`--.<br></br>
-uuuuuuuuuuxxxz|     .-|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuxuw-   .^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuxuuuuuxzxr` `>}zzw}v}}???>>>>>?wzw}?>+^^^^^+^||||||^+?vvvv?+||--~|||||^.<br></br>
-uuvv}}vv~^|~^|}uuuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uuuxxzzz?~``~~|vrw}xxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuyu^`-vzzrv}ruxxxxxxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>??????>>>>>?>>?>v~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzwwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---``........```~~-|^^-..`-|||~~~|~~~^+^^~~~-----~||^>v-<br></br>
-uuyuuuyxvzu>----``.............. .rv||--```...`||||++~--|^^||~-----~^^||>~<br></br>
-uuuyuuuu?^>?~``````.............. `?uuzv+~---````..``.....``-------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````.....v}vxuv?+.....               .-~|~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|~-------`.^y?^wuwww`.                    .^+>??vrz~<br></br>
-uuuuuuz}w?^|~~---``|+>+|~~------``ruuv?^>|                        |>???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-------?xuz?`                          .|^>?v?-<br></br>
-uuuuuruuuyw>++++^``-------------`vuuw.                             `|+>??`<br></br>
-uuuyxuuuuuuw>^^^+^-`~~|^|||------?>>~                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~-`.                                  -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>??>??|..`                                 .|^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~^^^- .+-                                 .`^++++.<br></br>
-uuuuuuuxxxxr?>>+?^|^^^^^++~.  ``~.                                ...~^^^.<br></br>
                  -uuuuuuxxxxxw?+++^++++++++- .~` .+`                                 ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^+|.  ^|.  +-                          ..      ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^||` .``~-   .`                      ..-|^>` .    .```.<br></br>
-uuuuuuuuuxw}v?>>++>>^`  ||`..                    ....`--~|||+?~........`~.<br></br>
-uuuuuuuuxxxw}vv??++^. .``|                 ..|^-~^++^+^-----^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxzr?...~.              .```~|||+++^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxxxuv..~..         ..`-~~|^^||~||~^^~~|^^+>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxxxxx>.`-.       ..-~~|||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxzzz+ ``     .-~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxzxz|     .-|+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuxuw-   .^?}r}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|||^|``~~~~|.<br></br>
-uuxuuuuuxzxr` `>}zzw}v}}v??>>>>>?wzw}?>+^^^^^+^|||||||+?vvv??+||--~|||||^.<br></br>
-uuvv}}v?~||~^|}uuuuxzr}rr}vv?v}rwzwwr}v>++++++>>>????+>?????>>+^+++^^^^^|.<br></br>
-uuuxxzzz?~``~~|vrw}xxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuyu^`-vzzrv}ruxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuuyv|~?yuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuuv+v?+wuuuuuuuuxxxxxzzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuu?+}yu?>xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyx}?+^|~---`.........``-~~~|^^-..`-|||~~~|~~~^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............. .>v?+^--```...`||||++~-~|^|||~-----~^^||>~<br></br>
-uuyuyuuu?^>?~``````............. `^}yuzv^~---````..``...``---------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````....+v?ruw?v|..... .            ..-~|~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|~-------`~vw??xxwxv...                  .`^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------`>zyw?+|+`                       .|>???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-----|>ruw?`                           `^^>?v?-<br></br>
-uuuuuruuuyw>++++^``------------->}yr-                              -|+>??`<br></br>
-uuuyxuuuuuuw>^^^+^-`~~|^|||-----++^`.                              `~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~`.                                   -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>????>` .                                   ~^+>++.<br></br>
-uuuuuuuuuuuuzr?+>+^^^|~~~^|`. .`.                                 .`^++++.<br></br>
-uuuuuuuxxxxr?>>+?^|^^^^^+~.  ...`                                 ...~^^^.<br></br>
-uuuuuuxxxxxw?+++^+++++>^`. ... .`                                  ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|^~.  .-`  ..                                   ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v+|.  .`.-.                              ..`. .  . .```.<br></br>
-uuuuuuuuuxw}v?>>++?^.. .~. .                            ...`^?~........`~.<br></br>
-uuuuuuuuxxxw}vv??>~.... ..                   .`..`---~-``---^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxw^.  `.              ..```-~~~^++^|>???>>???>~.....``..--.<br></br>
-uuuuuuuuuxxxxxxw+` ..           .``-~~|^|||~||~^+~~|^^+>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxxr|. ..       ..`-~||||||~||||||||||~~~------`````````....`-.<br></br>
-uuuuuuuuuxxxx}~. .     .`~~|^^^|||~~~~~~~|||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxxr~.     `|^+>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuuw|.  .->?}r}v?>>++++++???+^|||||||^>+|||||~|>vv}v>|||^|``~~~~|.<br></br>
-uuuuuuuuxxz^ .~?rzzr}vv}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-ux}wzw}?~|~|~>wuuuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uxwzwr}?>-``-->rwwwxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuuuuyr-.~}wr}vvwuxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuux?|-vyuuuuuuuuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuu?+}?>zuuuuuuuuxxxxxzzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv+}yx>?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyx}?+^|~---`.........``~~-~|^^-..`-|||~~~|~~~^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............. `w??>+--```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuyuyuuu?^>?~``````............. ~vuyuw?~~---```-`.-|~-|^^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````...`rv?xxv?v``.....`..  ..``-~~---~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|~-------`+uvvwzwwy+...                .`-|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------`ruyv>^^^.                     .-|^+???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~-----+wxu}|                           `|^^>?v?-<br></br>
-uuuyuruuuyw>++++^``------------~xwuv                              `-|+>??`<br></br>
-uuuyxuuuuuuw>^^^+^-`~~|^|||-----}^|.                               -~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|~.                                    -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>????+..- .                                 ~^+>++.<br></br>
-uuuuuuuuuuuuzr?+>+^^^|~~~^~.  |-`.                                 `^++++.<br></br>
-uuuuuuuxxxxr?>>+?^|^^^^^^- ` ~.~`                                 ...~^^^.<br></br>
-uuuuuuxxxxxw?+++^++++>>|. `^`- ^`                                  ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~^^- ..--~  ~`                                   ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v+~. .^`.^.  ..                            .. .  . .```.<br></br>
-uuuuuuuuuxw}v?>>++?|.`.-^. `                              .`|?~........`~.<br></br>
-uuuuuuuuxxxw}vv??>` `~` `                     .....``--``--~^`.........-~.<br></br>
-uuuuuuuuxxxxxxxxw- ..-.              ..```-~-~^^+^|>???>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxur` `` .         .`-~~|||^|||~|||^+~~|^^+>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxxx}. ``       ..-|||||||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxxv. ..    .`~^^^^^||||~~~~~~~|||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxxv.     `^>>>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuur.   -?}rrr}v?>>++++++???+^|||||||^>+|||||~|>vv}v>|||^|``~~~~|.<br></br>
-uuyuuuyuzuz` `>rxxzr}vv}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uzvzxz}>^^~^-vuuxuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uw?}v??^^````-?zzwxxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuyuuuuy?`.+w}vvv?zuxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuyw>|~ryuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuu?>r>?xuuuuuuuuxxxxxzzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv+ryx>?uuuuuuuxxxxzzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~~||~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyx}?+^|~---`.........``|--~|^^-..`-|||~~~|~~~^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............. +w??>+--```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuyuyuuu?^>?~``````..............^ryyuw?~~---```-`.-|~-|^^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````...^w?vuu?vv``.....`..  .``-~~~---~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|--------`?u?vzzrzu|`..              ...`~|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------~zyu?>|>|                      `~|^+???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~----~?zuz?`                          .-||^>?v?-<br></br>
-uuuyuruuuyw>++++^``------------>uzu|                              `-|+>??`<br></br>
-uuuyxuuuuuuw>^^^+^-`~~|^|||---->?`-                               .-~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|`.                                    -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|-~>????~ ..                                   ~^+>++.<br></br>
-uuuuuuuuuuuuzr?+>+^^^|~~|^-  .-..                                  `^++++.<br></br>
-uuuuuuuxxxxr?>>+?^|^^^^^~. . `.`.                                  ..~^^^.<br></br>
-uuuuuuxxxxxw?+++^++++>^`  ``.. ^.                                  ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>~|~.  .``.  `                                    ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v^.  .`..-                                       . .```.<br></br>
-uuuuuuuuuxw}v?>>++>`   .` ..                                `~`........`~.<br></br>
-uuuuuuuuxxxw}vv??^. ...                            .......``^`.........-~.<br></br>
-uuuuuuuuxxxxxxxu?... `                .....```~|^|~>>??>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxu?. .           ..`-~~~~|^|||||||^+|~|^^+>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxx? ..        .`~|||||||||~||||||||||~~~------`````````....`-.<br></br>
-uuuuuuuuuxxxx>  .     .-|+^^^^||||~~~~~~~|||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxx?     .-+??>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuuuuuu?   .|?}rrr}v?>>++++++???+^|||||||^>+|||||~|>vv}v>|||^|``~~~~|.<br></br>
-uuuuuuyuxuv. -?wxxwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uxwuuxv+^|||-vuxxuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-u}?}vv+^|````-vzxzxxxxwwwzzzwzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uuuuxxuu>..>wvvvv?xxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuyr+~~zyuuxxxuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuux?>r>?xuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv^ryz>?uuuuuuuxxxxzzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........`-|--~|^^-..`-|||~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............. ?r??>+--```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuyuuuuu?^>?~``````............ .>xyyxr>~~---```-`.-|~-||^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````...?z?}xz?vv``.....``....`||^^|~--~~~~+vv?}z|<br></br>
-uuuuuuyur?^~--````.`-~|--------`wz?}zzvxx-`...           ..``--~|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~------+uyx>>~>~                     .~||^>???vv`<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~----|vzxw>.                          `~|^^>?v?-<br></br>
-uuuyuruuuyw>++++^``------------ruzx`                             .--|+>??`<br></br>
-uuuyxuuuuuuw>^^^^^-`~~|^|||---`}?`-                               `-~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^|`                                     -~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|~~>???>` | `.                                 ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~||`  -^`~                                  `^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^+^- .`.~.+`                                  ..~^^^.<br></br>
-uuuuuxxxxxxw?+++^++++>|.  +-~ .>                                   ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>||` ..-~^. .|                                    ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v|.  |~.-|   .                                     .```.<br></br>
-uuuuuuuuuxw}v?>>+++.` `~` ..                                .``........`~.<br></br>
-uuuuuuuuxxxw}vv??~ .``..                                 ..`|`.........-~.<br></br>
-uuuuuuuuxxxxxxxx> `..-                .....```-||~~+>>>>>???>~......`..--.<br></br>
-uuuuuuuuuxxxxxx> --          ...`-~~~~|||||||||^+|~|^++>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxxx> -`       ..-|^^|||||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxxx+ `.    .`~^++^^|||||~~~~~~~~||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxx+     `|???>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuu>   `+vrrrr}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuyuxu? .^vzxxwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuxuuuv++~^~|}uxxuuxzr}rr}vv?v}rwwwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-uv?}v?^|~````~rxxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uxxzzzux^`.?rv?vv?xxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuyv^~|zyuxxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuux>>r>?uuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv^ryz>vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........`~|---|^^-..`-|||~~~|~~|^+^|~|~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``..............vr?v?+--```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``````............ `?xyyur>~~---```-`.-|~-||^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````...vr>wuz>}v``.....``....`|^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|--------`ww?rzwvxz``...          ..`-`--~|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~-----`>uyz>+|?~                    ..~||^>???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~----+rzxr+.                         .`~|^^>?v?-<br></br>
-uuuyuruuuyw>++++^``-----------~xxxw.                             `--|+>??`<br></br>
-uuuyxuuuuuuw>^^^^^-`~~|^|||----r^-`                               `-~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^~.                                    .-~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|~~>??>+. | `                                  ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~~.  -^``                                  `^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^+|` ...-`^.                                  ..~^^^.<br></br>
-uuuuuxxxxxxw?+++^++++>~.  +`- `^                                   ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>||` . `~|. .`                                    ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v~   `` -~                                       . .```.<br></br>
-uuuuuuuuuxw}v?>>++|.` .`. ..                                 ..........`~.<br></br>
-uuuuuuuuxxxw}vvv?- ... .                                  ..-..........-~.<br></br>
-uuuuuuuuxxxxxxxz| . ..                 .....`.`~~~-^+>>++>??>~......`..--.<br></br>
-uuuuuuuuuxxxxuz~ -.          ...`--~~~|||||~|||^+|~|^++>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxw- ..       ..-|^^|||||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxuw- .     .-|^++^^|||||~~~~~~~~||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxw`     `^???>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuux~   `>vwrrr}v?>>+++++>???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuuuuu+ .^}xxxwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuuuuuv++~^-^ruxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-u??rv?||-````|wxxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uzzzzzxz|``v}??vvvuxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuyv^~^xyuxxxxuxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuux>>r>vuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv+ryz>vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........`|^---|^^-..`-|||~~~|~~|^+^|~|~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............ .}vvv?>~-```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``````............ `vuyyx}>~~---```-`.-|~-||^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````...}}>wuz>}?``.....``....`^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|---------z}?rzwvuz``...  ..     ..`-|----|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~-----`?uyz>+^?~                    .`|||^>???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~----?wzx}^.                         .-~|^^>?v?-<br></br>
-uuuyuruuuyw>++++^``-----------+ywxr                              `--|+>??`<br></br>
-uuuyxuuuuuuw>^^^^^-`~~|^|||---|w~~.                               --~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^~.                                    .~~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|~~>??>^. ^ `                                  ~^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~~.  ~+``                                  `^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^+|. `.``~+                                   ..~^^^.<br></br>
-uuuuuxxxxxxw?+++^+++++~  .>`- ~^                                   ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>||` ..`^~. ``                                    ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v~  .~` |-                                       . .```.<br></br>
-uuuuuuuuuxw}v?>>++~.- .~. `.                                  .........`~.<br></br>
-uuuuuuuuxxxw}vvv?` .~` `                                   .`..........-~.<br></br>
-uuuuuuuuxxxxxxxz~.` ~.                  ......`-~--^+>>++>??>~......`..--.<br></br>
-uuuuuuuuuxxxxuw` ~.          ...``-~~~~||||~|||^+|~|^+>>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxxr`..        .`-|^^|||||||~||||||||||~~~------`````````....`-.<br></br>
-uuuuuuuuuxxu}...     .~|^++^^|||||~~~~~~~~||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuuxr.    .-+???>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuz`   ->}wrrr}v?>>+++++>???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuuxuu^ .+}xxzwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuuuuu?^+|+-+wuxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-u?vrv?||-`-``+zxxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uwwwwzxw~``vv??v?}uxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuyv^~^xyuxxzxuxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuux>?r>vuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuuv+ryz>vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........`||---|^^-..`-|||~~~|~~|^+^|~|~~----~||^>v-<br></br>
-uuyuuuyxvzu>----``............ `rvvv?>~-```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``````............ -}uyyx}>~~---```-`.-|~-||^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````..`wv>zuz>w?``.....```...`^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|-------`-xv?wzr}yr``...  ...    .`-~|----|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~-----`?uyz>+>?-                   .`-|||^>???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~----?zzxv|.                         `-~|^^>?v?-<br></br>
-uuuyuruuuyw>++++^``----------->ywuv                             .---|+>??`<br></br>
-uuuyxuuuuuuw>^^^^^-`~~|^|||---|}`~.                              .--~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^-.                                    `~~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|~~>??>~ .| .                                 .|^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~`   |^..                                  `^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^+~. `.``|~                                   ..~^^^.<br></br>
                  -uuuuuxxxxxxw?+++^+++++`  .?`- |`                                   ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>|-. . .^-  ..                                    ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v`  .`. |`                                       . .```.<br></br>
-uuuuuuuuuxw}v?>>+^`.` ..  `                                    ........`~.<br></br>
-uuuuuuuuxxxw}vvv>. .`. .                                    ...........-~.<br></br>
-uuuuuuuuxxxxxxx}` . `                   ......``-``|^^^^+>??>~......`..--.<br></br>
-uuuuuuuuuxxxxuv. .           ...``----~|||~~|||^+|~|^+>>>>>+^~`........``.<br></br>
                  -uuuuuuuuuuxxx?.          .`-|^^|||||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxu? .      `~|+++^^^||||~~~~~~~|||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuux?     .~>???>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuuv.   ~?rwrrr}v?>>+++++>???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuuxuz- `>rxxzwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uyyuyx?^^~+->zuxxxuxzr}rrrvv?v}rwzwwr}v>+++++++>>????+>?????>>+^+++^^^^^|.<br></br>
-u?vw}?~|`````>xxxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-urrwrwxw-``}v?v}?ruxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuy?^~+uyuxxzxuxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuz>?r+vuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuyv+ryz>vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvv??>>+^~~~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr?+^|~---`.........`^~---|^^-..`-|||~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>-----`............ ~w?vv?>~-```...`||||++~--|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``````............ |ryyyxv>~~---```-`.-|~-||^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`..```````-````..`z?>zuw?w?``.....```...-^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--````.`-~|-------`|xv?wzrry}```... ....  .`-|^+~---|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~---``|+>+|~~-----`vuyw?+>>-                 ..`-~|||^>???vv-<br></br>
-uuuuyzruyz+^^^^|~``-~||~^|~---~vxzxv~.                        .`~~||^>?v?-<br></br>
-uuuyuruuuyw>++++^``----------`?yry?                             .---|+>??`<br></br>
-uuuyxuuuuuuw>^^^^^-`~~|^|||---^v`~                               .--~^+>?`<br></br>
-uuuuuuuuuuuuw?+^+++|-~??>+^^`.                                    -~~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^+++|~~>??>- .^ .                                 `|^+>++.<br></br>
-uuuuuuuuuuuuxr?+>+^^^|~~~`   ^+-.                                  `^++++.<br></br>
-uuuuuuxxxxxr?>>+?^|^^^^-  -.-`+|                                   ..~^^^.<br></br>
                  -uuuuuxxxxxxw?+++^++++^`  `?.- +`                                   ..`|^^.<br></br>
-uuuuuuxxzzwwv?+^^>>|-  ..`+-  -                                     ..`~|.<br></br>
-uuuuuuuuxw}v??>++>v`  -~. +.                                       . .```.<br></br>
-uuuuuuuuuxw}v?>>+^`.`.``  -                                    ........`~.<br></br>
-uuuuuuuuxxxw}vvv+. `|. .                                    ...........-~.<br></br>
-uuuuuuuuxxxxxxxv... ~                    ......````~||^^^+>?>|......`..--.<br></br>
-uuuuuuuuuxxxxu? .`           ...```---~||~~~|||^+|~|^+>>>>>+^~`........``.<br></br>
-uuuuuuuuuuxxx>..        ..`~|^^|||||||~||||||||||~~~------`````````....`-.<br></br>
                  -uuuuuuuuuxxx+ `     .-|^+++^^^||||~~~~~~~~||||||||~~~~~~~~~~------|...`--.<br></br>
-uuuuuuuuuux+     `^?v??>>>++^^^^|||||||||||~~~|||||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuuuuu?   .+vwwrrr}v?>>++++++???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuuxuw. -?zuxzwr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uyyuyz>^^|^-?xuxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
-uv}w}>~~`````?xxxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-u}}wrwx}``~}??v}?ruxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuuuy?^~>uyuxzzxuxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuuz>?r+vuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuuy}^wyz+vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvvv???>^~-~|~|~~~~~^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyx}??}???v??>~`......`~----~~-`..``-----~||~|^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>`--|>?????>>>`.... .^--``..        ....-~~-~|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``^?>^>??+|?>...   .-~~~~-`.....       ...`|^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~-`>>-.>???+?>.    .-`.~~~`-`              ..`||~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--`>??>+^|~``.     .~``-~--|-                ..-----|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~-->??v?>~.        -~~~`.``.                   .-~|~|^>???vv-<br></br>
-uuuuyzruyz+^^^^|^>>>>-....    .-~-~`.                         .-~~|^^>?v?-<br></br>
-uuuyuruuuyw>++^?v>?+.         `|`~`                            .`---|+>??`<br></br>
-uuuyxuuuuuuw>^^?v??^  .....   .` .                              .`--~^+>?`<br></br>
-uuuuuuuuuuuuw?+?}>`. .......                                     .-~~^^^+`<br></br>
-uuuuuuuuuuuuuw?+|...`. .`...  .                                   `|^+>++.<br></br>
                  -uuuuuuuuuuuuzr?`.-..~.       ..                                   .`^++++.<br></br>
-uuuuuuxxxxxr?>-.~+.-`..       ..                                  ...~^^^.<br></br>
                  -uuuuuxxxxxxw?-..||`|..    .   .                                    ..`|^^.<br></br>
-uuuuuuxxzzzw>`.`|-^`      ..                                       ...`~|.<br></br>
                  -uuuuuuuuxwr?`~-~`-?.   .  .                                      . . .```.<br></br>
                  -uuuuuuuuuxw~`|^+.``                                           .........`~.<br></br>
-uuuuuuuuxu?`>~++.   .                                        ..........-~.<br></br>
                  -uuuuuuuuuw~|?|^~    .                              ........-+|......`..--.<br></br>
-uuuuuuuuu>|?|-.                    .  ....................~+^~`........``.<br></br>
-uuuuuuuuw~>+~.             ......................      .``-````````....`-.<br></br>
-uuuuuuuy?~>|.        ............        ...........``-~~~~~------|...`--.<br></br>
                  -uuuuuuyz||~.      ..`------------```-----------~~||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuy}~|.   .~+?vvv}}v?>>>++++>???+^^^^||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuuyv|~..~vzxxxzr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uyyuz}>^^`+?rxuxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
`w}w}>^`-```|?vrzxxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
`zr}}}w?+.->v?v}wwzuxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuyu}+~|}yuuuxxxuxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuurv???>}uuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuwvrvzx?+}uuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvvvv???+~-~|~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyx}??uzzuuux}>`......```-----``..```-``-~||~|^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu>``-^}xuuyxr}r~.....                    `-~-~|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^>?~``?zv?wxxv?zz...                         ..~^^^~------~>?^|v|<br></br>
-uuuuuuuuuv~~~--zz|.}xux}ur`                              .~|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^~--`}yxzr?>^`~                                  `----|^+>??vrz~<br></br>
-uuuuuuz}r?^|~~-~vuyuv>-                                     .~-|~|^>???vv-<br></br>
-uuuuyzruyz+^^^^~>zxxr-                                       .~~~~|^^>?v?-<br></br>
-uuuyuruuuyw>++^?uzyw.                                         `-----|+>??`<br></br>
-uuuyxuuuuuuw>^^vuruv                                           `~~--~^+>?`<br></br>
                  -uuuuuuuuuuuuw?^vy?`. .                                         .`~~~~^^^+`<br></br>
-uuuuuuuuuuuuuw?+^.  `.                                         ...`|^+>++.<br></br>
                  -uuuuuuuuuuuuxr?. -  ~                                           ...`^++++.<br></br>
-uuuuuuxxxxxr?>. `? ~`                                            ....~^^^.<br></br>
                  -uuuuuxxxxxxw?.  +^.^                                               ..`|^^.<br></br>
                  -uuuuuuxxzzzz^  .+.^-                                                ..`~|.<br></br>
-uuuuuuuuxwr> `.~``>                                            .   . .```.<br></br>
                  -uuuuuuuuuxr. `~+ `~                                           `........`~.<br></br>
                  -uuuuuuuuxu^ |.~| ..                                          ..........-~.<br></br>
-uuuuuuuuu} .^...                                           .+|......`..--.<br></br>
                  -uuuuuuuuu~.-.                                             `+^~`........``.<br></br>
                  -uuuuuuuyr ``                                           ..`-````````....`-.<br></br>
                  -uuuuuuuy| -.                                      ...`-~~~~~------|...`--.<br></br>
                  -uuuuuuyw             ......```````````````````-~~||~~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuy?      .-^>??v}}v?>>>++++>???+^^^^^|||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuyuy>  .->vwxxxzr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuyuv?>^^.+xuuuxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
`vrz?-~``-``>zz?}xxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
`}}vv}w~..^vvvv}zxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuyu>--+uyuuuuuuxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuu?>wv^?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+wyur>+ruuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrwwrvvvv??>+~-~|~|~~~~~^^++|~``-|^|--```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyxr??uzzuuxzv>`........```````.....`````~||~|^+^|~~~~----~||^>v-<br></br>
-uuyuuuyxvzu?-~|+}xzuyz}vv- .                       .`--~|^|||~-----~^^||>~<br></br>
-uuuuuuuu?^??``-?xv?wxz?>}}.                            .~^^^~------~>?^|v|<br></br>
-uuuuuuuuu}^||~~zz|.vww}?wv`                              `~|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?+^||~rxwrv>+|`~                                  `----|^+>??vrz~<br></br>
-uuuuuuz}r?^^^^|^vzzw?+`                                     .~~|~|^>???vv-<br></br>
-uuuuyzruyz++>++|>}rrv`                                       .~~~~|^^>?v?-<br></br>
-uuuyuruuuyw??>+?w}zv                                          `-----|+>??`<br></br>
-uuuyxuuuuuyz?++?wvw?                                           `~~--~^+>?`<br></br>
                  -uuuuuuuuuuuuw?|?z+`. .                                         .`~~~~^^^+`<br></br>
-uuuuuuuuuuuuzv>|~   ..                                         ...`|^+>++.<br></br>
-uuuuuuuuuuuw}v+. `  -                                           ...`^++++.<br></br>
-uuuuuuxxxxzv>>. .^ `.                                            ....~^^^.<br></br>
                  -uuuuuxxxxxwv?.  ~~.~                                               ..`|^^.<br></br>
-uuuuuuxxxw}}|  .~.-`                                                ..`~|.<br></br>
-uuuuuuuux}v> `.`..~                                            .   . .```.<br></br>
-uuuuuuuuxr}. .`- .`                                           `........`~.<br></br>
                  -uuuuuuuuww^ -.``                                             ..........-~.<br></br>
                  -uuuuuuuuwv .-..                                            .+|......`..--.<br></br>
                  -uuuuuuuuz~ .                                              `+^~`........``.<br></br>
                  -uuuuuuuxv ..                                           ..`-````````....`-.<br></br>
                  -uuuuuuux~ .                                         .`-~~~~~------|...`--.<br></br>
-uuuuuuu}             . ..................``````---~-~~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuy?      .`-^>?????>>++++++>???+^|||||||^>+|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuyuy+  .->vwzzzzr}v}}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuyuv?>^^.>xuuuxxxuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
`vrz?-~`-~``>zw?}xxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
`}}vv}w~..^vvvv}zxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuyu>--+uyuuuuxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuu?>wv^?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+wyur>+ruuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrvr}v}rrrrr}>^^~~~|||~~~`.``.       ..`...`--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyuuzxyyuyxzv}+.......                      .`-~|^+^|~|~~----~||^>v-<br></br>
-uuyuuuyxvwxzvrzux}-vz| .                             .`~|^|||~-----~^^||>~<br></br>
-uuuuuuuu?}x? -zuuuxxu?                                 .~^^^~------~>?^|v|<br></br>
-uuuuuuuuuwxz}wwzwv+?-                                    ~^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?zuxux?~|.                                       -^~---|^+>??vrz~<br></br>
-uuuuuuz}r?+vxuuyr`                                         -~~-~~|^>???vv-<br></br>
-uuuuyzruyz+>zxx?.                                           ~~~~~~|^^>?v?-<br></br>
-uuuyuruuuyrzzuy^                                             `|-----|+>??`<br></br>
-uuuyxuuuuuxxwxu^                                             ..`~~--~^+>?`<br></br>
                  -uuuuuuuuuuxuu?-                                              ...`~~~~^^^+`<br></br>
-uuuuuuuuuuxw?.                                                ....`|^+>++.<br></br>
                  -uuuuuuuuuuu~ `                                                  ...`^++++.<br></br>
                  -uuuuuuxxxuv .~                                                .. ....~^^^.<br></br>
                  -uuuuuxxxxz|`.~.                                                    ..`|^^.<br></br>
                  -uuuuuuxxx?-`.~.                                              .      ..`~|.<br></br>
-uuuuuuuux-~- ~.                                             .>` .  . .```.<br></br>
-uuuuuuuu} -~ `                                              ~v|........`~.<br></br>
                  -uuuuuuuu^ ``                                               .^`.........-~.<br></br>
-uuuuuuuz``.                                               |?>~......`..--.<br></br>
-uuuuuuyv.`.                                              ~>+^~`........``.<br></br>
-uuuuuuy>.`.                                            .`-`````````....`-.<br></br>
                  -uuuuuuy~`                                            .`~~~~~------|...`--.<br></br>
                  -uuuuuuu`.                                      ....`-~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuux.         ..```-~--~~~~~~|>?>|~~~~~~~~|++|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuyyx.  .`^?v}wwwr}vv}v??>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuuuw}?^--?zuuxxuuuxzr}rr}vv?v}rwzwwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
`}zuv~-.^~-`?zr?ruxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
`v?v>?v|.`^}vv}zxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuxuyy?``^uyuuuuuuxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuv+vv+>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+ryyz?+vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrvr}v}rrrrr}>^^~~~|||~~~`.``.       ..`...`--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyuuzxyuuyxzv}+.......                      .`-~|^+^|~|~~----~||^>v-<br></br>
-uuyuuuuzvwxzvrzux}-vz| .                             .`~|^|||~-----~^^||>~<br></br>
-uuuuuuux?}x? -zuuxzzx?                                 .~^^^~------~>?^|v|<br></br>
-uuuuuuuyuwxz}wwzwv+?-                                    ~^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?zuuxz?~|.                                       -^~---|^+>??vrz~<br></br>
-uuuuuuz}r?+vzxuu}`                                         -~~~~~|^>???vv-<br></br>
-uuuuyzruyz+>wzz?.                                           ~~~~~~|^^>?v?-<br></br>
-uuuyuruuuyrzwxu^                                             `|-----|+>??`<br></br>
-uuuyxuuuuuxx}zx^                                             ..`~~--~^+>?`<br></br>
                  -uuuuuuuuuuzxx?`                                              ...`~~~~^^^+`<br></br>
-uuuuuuuuuxzr?.                                                ....`|^+>++.<br></br>
                  -uuuuuuuuxxx~ `                                                  ...`^++++.<br></br>
                  -uuuuuuuxzxv .~                                                .. ....~^^^.<br></br>
                  -uuuuuxxxzz|`.~.                                                    ..`|^^.<br></br>
                  -uuuuuuxxz?-`.~.                                              .      ..`~|.<br></br>
-uuuuuuuxz-~- ~.                                             .>` .  . .```.<br></br>
-uuuuuuuu} -~ `                                              ~v|........`~.<br></br>
                  -uuuuuuuu^ ``                                               .^`.........-~.<br></br>
-uuuuuuuz``.                                               |?>~......`..--.<br></br>
-uuuuuuuv.`.                                              ~>+^~`........``.<br></br>
-uuuuuuu>.`.                                            .`-`````````....`-.<br></br>
                  -uuuuuuu~`                                            .`~~~~~------|...`--.<br></br>
                  -uuuuuux`.                                      ....`-~~|||++|~~~~^|..-~~-.<br></br>
-uuuuuuz.         ..```-`----~~~~|>>+|~~~~~~~~|+^|||||~~>vv}v>|~|^|``~~~~|.<br></br>
-uuuuyyx.  .`^?v}rwwr}vvv???>>>>>?wzw}?>+^^^^^+^||||||^+?vvv??+||--~|||||^.<br></br>
-uuuuw}?^--?zuuxxuuuxzr}rr}vv?v}rwzzwr}v>+++++++>>????+>?????>>+^++++^^^^|.<br></br>
`}zuv~-.^~-`?zr?ruxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
`v?v>?v|.`^}vv}zxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuxuyy?``^uyuuuuuuxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuv+vv+>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+ryyz?+vuuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrr}vrwrrrrv^|v??>>+|`.                     ..```--``~^^|-`-````````~^+?`<br></br>
-uyyyxuuxxuyuz-`}x>``.                           .`~||~|^+^|~|~~----~||^>v-<br></br>
-uuyuxxv.~xxyxxxxy?                                .-^~--|^|||~-----~^^||>~<br></br>
-uuyxxx}>?zxyuuwz+                                   .`-||^^^~------~>?^|v|<br></br>
-uuuuzuuuxxwvv>`.                                      .~^^^|~--~~~~+vv?}z|<br></br>
-uuuyxzuuuyx??|                                          ~>+^~---|^+>??vrz~<br></br>
-uuuuyuwrxuuy?                                            ~+^~~~|~|^>???vv-<br></br>
-uuuuyz}wxxz?                                              .-|~~~~~|^^>?v?-<br></br>
-uuuyurzxxyx`                                               ..`|-----|+>??`<br></br>
-uuuyxuzxwuy|                                                ...`~~--~^+>?`<br></br>
                  -uuuuuuzxz?~                                                  ...`~~~~^^^+`<br></br>
-uuuuuuzxw.                                                    ....`|^+>++.<br></br>
                  -uuuuuuuu?                                                       ...`^++++.<br></br>
                  -uuuuuuux`                                                    ... ....~^^^.<br></br>
-uuuuuxu}..                                                  .      ..`|^^.<br></br>
-uuuuuuu>`.                                                 `^.      ..`~|.<br></br>
-uuuuuux``                                                  -^>` .  . .```.<br></br>
                  -uuuuuuw.`                                                 .|+?|........`~.<br></br>
                  -uuuuuy?``                                                 .-^`.........-~.<br></br>
-uuuuuy>~`                                                |??>~......`..--.<br></br>
-uuuuuu||                                                ->>+^~`........``.<br></br>
                  -uuuuuu--                                               `--`````````....`-.<br></br>
                  -uuuuux~~                                             .~~~~~~------|...`--.<br></br>
                  -uuuuux`.                                          .`-~~|||++|~~~~^|..-~~-.<br></br>
-uuuuux`                                      ...`~|||~|>vv}v>|~|^|``~~~~|.<br></br>
-uuuuuy~   ..`-||^+>++++++^||~~~~^>>>>+|----~|^^||||^||+?vvv??+||--~|||||^.<br></br>
-uuuuxx>-->?wuuzxuuuuzwrrwr}vvv}rwzzzwrv>>+++++>>>????+>?????>>+^++++^^^^|.<br></br>
-wzy}|~`^```?wv?zuxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-v+v>^>|..^}??rxxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uwrzuy}``|uyuuuxxuxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuur>?v>>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+vuyu}>?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrr}vrwrrrrv^|v??>>+|`.                     ..```--``~^^|-`-````````~^+?`<br></br>
-uyyyxuuxxuyuz-`}x>``.                           .`~||~|^+^|~|~~----~||^>v~<br></br>
-uuyuxxv.~xxyxxxxy?                                .-^~--|^|||~-----~^^||>~<br></br>
-uuyxxx}>?zxyuuwz+                                   .`-||^^^~------~>?^|v|<br></br>
-uuuuzuuuxxwvv>`.                                      .~^^^|~--~~~~+vv?}z|<br></br>
-uuuyxzuuuyx??|                                          ~>+^~---|^+>??vrz~<br></br>
                  -uuuuyuwrxuuy?                                            ~+^~~~|~|^+???vv-<br></br>
-uuuuyz}wxxz?                                              .-|~~~~~|^^>?v?-<br></br>
-uuuyurzxxyx`                                               ..`|-----|+>??`<br></br>
-uuuyxuzxwyy|                                                ...`~~--~^+>?`<br></br>
                  -uuuuuuzxz?~                                                  ...`~~~~^^^+`<br></br>
-uuuuuuzxw.                                                    ....`|^+>++.<br></br>
                  -uuuuuuuu?                                                       ...`^++++.<br></br>
                  -uuuuuuux`                                                    ... ....~^^^.<br></br>
-uuuuuxu}..                                                  .      ..`|^^.<br></br>
-uuuuuuu>`.                                                 `^.      ..`~|.<br></br>
-uuuuuux``                                                  -^>- .  . .```.<br></br>
                  -uuuuuuw.`                                                 .|+?|........`~.<br></br>
                  -uuuuuy?``                                                 .-^`.........-~.<br></br>
-uuuuuy>~`                                                |??>~......`..--.<br></br>
-uuuuuu||                                                ->>+^~`........``.<br></br>
                  -uuuuuu--                                               `--`````````....`-.<br></br>
                  -uuuuux~~                                             .~~~~~~------|...`--.<br></br>
                  -uuuuux`.                                          .`-~~|||++|~~~~^|..-~~-.<br></br>
-uuuuux`                                      ...`~|||~~>vv}v>|||^|``~~~~|.<br></br>
-uuuuuy~   ..`-||^+>++++++^||~~~~^>>>>+|----~|^^||||^||+?vvv??+||--~|||||^.<br></br>
-uuuuxx>-->?wuuzxuuuuzwrwwr}vvv}rwzzzwrv>>+++++>>>????+>?????>>+^++++^^^^|.<br></br>
-zzy}|~`^`-`?wv?zuxxxxxwwwzzzzzzzzwwr}}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-v+v>^>|..^}??rxxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uwrzuy}``|uyuuuxuuxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuur>?v>>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuuv+vuyu}>?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrr}vrrrrr}v}v?>++^~~~|~|~~~``-|^|-..`~^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyuxuyyyyxx-`ww|-``....                ..`~||~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyyuzxzv}zuuzv?wuv..                          .`~^++~--|^|||~-----~^^||>~<br></br>
-uuuyxxxv.|xxyuuuuu?                               .`|~-||^^^~------~>?^|v|<br></br>
-uuuyxxuxzzzzxzr?v-                                   .`^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuzuuuxur+^^.                                       .|>+^~---|^+>??vrz~<br></br>
                  -uuuuuuwzuuuyxv`                                          -+^~~~|~|^+???vv-<br></br>
-uuuuyzrxwxxxr`                                            .-~~~~~~|^^>?v?-<br></br>
-uuuyuruxzxux-                                               .`|-----|+>??`<br></br>
-uuuyxuyxxzyy+                                               ...`~~--~^+>?`<br></br>
                  -uuuuuuuzxwww~                                                ...`~~~~^^^+`<br></br>
-uuuuuuzzuz|                                                   ....`|^+>++.<br></br>
                  -uuuuuuxzzx-                                                     ...`^++++.<br></br>
                  -uuuuuuuxxx.                                                   .. ....~^^^.<br></br>
                  -uuuuuuxxxz.`                                                       ..`|^^.<br></br>
-uuuuuuxxx} ~.                                                .      ..`~|.<br></br>
-uuuuuuuuxv ^`                                               .>` .  . .```.<br></br>
                  -uuuuuuuuu? |.                                               |v|........`~.<br></br>
-uuuuuuuuy> |                                               .^`.........-~.<br></br>
-uuuuuuuuu-.~                                              ~?>~.....``..--.<br></br>
                  -uuuuuuuyr ~.                                             -++^~`........``.<br></br>
                  -uuuuuuuu~.|.                                           .`-`````````....`-.<br></br>
                  -uuuuuuyv ~`                                          .`~~~~~------|...`--.<br></br>
                  -uuuuuux`..                                        .``~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuyv.                                      ..`-~||~~>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyv                                 ..``~||||||^|^+?vvv??+||--~|||||^.<br></br>
-uyuuww?~-+>vw}vrr}}v??>+^^^^^+>>???vvv?+++++++>>>????+>?????>>+^++++^^^^|.<br></br>
-rvu}~-`+~``>w?vuuuuuuxzzzzzzzzzzzzwrr}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-r>?>>?+``^v??rxxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uxwxyyr-`+uyuuuxuuxxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuur+?v+>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}}vvvvv??????v}}}rrrv????-<br></br>
-uuuuv+}yyx}>?zuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrr}vrrrrr}v}v?>++^~~~|~|~~~``-|^|-..`~^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyuxuyyyyxx-`ww|-``....                ..`~||~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyuuzxzv}zuuzv?wuv..                          .`~^++~--|^|||~-----~^^||>~<br></br>
-uuuyxxxv.|xxyuuuuu?                               .`|~-||^^^~------~>?^|v|<br></br>
-uuuyxxuxzzzzxzr?v-                                   .`^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuzuuuxur+^^.                                       .|>+^~---|^+>??vrz~<br></br>
                  -uuuuuuwzuuuyxv`                                          -+^~~~|~|^+???vv-<br></br>
-uuuuyzrxwxxxr`                                            .-~~~~-~|^^>?v?-<br></br>
-uuuyuruxzxux-                                               .`|-----|+>??`<br></br>
-uuuyxuyxxzyy+                                               ...`~~--~^+>?`<br></br>
                  -uuuuuuuzxwww~                                                ...`~~~~^^^+`<br></br>
-uuuuuuzzuz|                                                   ....`|^+>++.<br></br>
                  -uuuuuuxzzx-                                                     ...`^++++.<br></br>
                  -uuuuuuuxxx.                                                   .. ....~^^^.<br></br>
                  -uuuuuuxxxz.`                                                       ..`|^^.<br></br>
-uuuuuuxxx} ~.                                                .      ..`~|.<br></br>
-uuuuuuuuxv ^`                                               .>` .  . .```.<br></br>
                  -uuuuuuuuu? |.                                               ^v|........`~.<br></br>
-uuuuuuuuy> |                                               .^`.........-~.<br></br>
-uuuuuuuuu-.~                                              ~?>~.....``..--.<br></br>
-uuuuuuuyr ~.                                             ->+^~`........``.<br></br>
                  -uuuuuuuu~.|.                                           .`-`````````....`-.<br></br>
                  -uuuuuuyv ~`                                          .`~~~~~------|...`--.<br></br>
                  -uuuuuux`..                                        .``~~|||++|~~~~+|..-~~-.<br></br>
-uuuuuyv.                                      ..`-~||~~>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyv                                 ..``~||||||||^+?vvv??+||--~|||||^.<br></br>
-uyuuww?~-+>vw}vrr}}v??>++^^^^+>>???vvv?+++++++>>>????+>?????>>+^+++^^^^^|.<br></br>
-rvu}~-`+~``>w?vuuuuuuxzzzzzzzzzzzzwrr}v???>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-r>?>>?+``^v??rxxxxxxxxxxzxxxxxzzzzwr}rr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uxwxyy}``+uyuuuxuxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuur+?v+>ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}}vvvvv??????v}}}rrrv????-<br></br>
-uuuuv+}yyx}>?zuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrv}rrrrr}v?>++^~~~|~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyxuyyyyuz??w?```.........```--~|^^-..`-~|~~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuzuxxxxuuw-~wu?........           ....````~|||++~--|^|||~-----~^^||>~<br></br>
-uuuuyuxx}.`wxyxuuzuu-...                    ..`--``-~~-||^^^~------~>?^|v|<br></br>
-uuuuyuxzz??zxyuuruuw`                            .....`|^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuzuuuuxzr?+`+-                                 ..`~|>+^~---|^+>??vrz~<br></br>
                  -uuuuuywzuuuyx?^~.                                     .`-|+^~~~|~|^+???vv`<br></br>
-uuuuyzrxxxuuuy^                                         .```~~~~-~|^^>?v?-<br></br>
-uuuyuruyuwxxz|                                            .`.`|-----|+>??`<br></br>
-uuuyxuuyxxxyu-                                             .-..`~~--~^+>?`<br></br>
-uuuuuuuuzxzuy>                                              .`..`~~~~^^^+`<br></br>
-uuuuuuuzuxwr+.                                               `....`|^+>++.<br></br>
                  -uuuuuuuzxxzz.                                                .` ...`^++++.<br></br>
                  -uuuuuuuxxxxw.`                                                -. ....~^^^.<br></br>
                  -uuuuuuxxxxxz`|.                                               -.   ..`|^^.<br></br>
                  -uuuuuuxxzzzw~~`                                               `     ..`~|.<br></br>
-uuuuuuuuxw}v^^~                                              .` .  . .```.<br></br>
                  -uuuuuuuuxxwr^^|                                              ~~........`~.<br></br>
                  -uuuuuuuuxxxz~-^                                             ``.........-~.<br></br>
                  -uuuuuuuuxxuz`~-.                                          .^?~......`..--.<br></br>
-uuuuuuuuuxu>~+..                                         .|+^~`........``.<br></br>
                  -uuuuuuuuuyv-`|                                         .`-`````````....`-.<br></br>
                  -uuuuuuuuuv`^ .                                        `~~~~~------|...`--.<br></br>
                  -uuuuuuuyv`..                                       .`-~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuy?..                                      .`~||~~>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyx.                                    .`-~|||||^+?vvv??+||--~|||||^.<br></br>
-uuuur}?~`|+>>-``..                  ..``-~|^+>>>>????+>?????>>+^+++^^^^^|.<br></br>
-}?z}~``^|``>}?rxzzr}vv?????>>????vvvvvvv???>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-zv???v>``|v??ruxuuuuuuuxxxxuuxxxxzwrrrr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuxuyy}``>yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuv+??+?ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuux?+ryyxv>?zuuuuuuxxxxzzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrv}rrrrr}v?>++^~~~|~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyxuyyyyuz??w?```.........```--~|^|-..`-~|~~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuzuxxxxuuw-~wu?........           ....````~|||++~--|^|||~-----~^^||>~<br></br>
-uuuuyuxx}.`wxyxuuzuu-...                    ..`--``-~~-||^^^~------~>?^|v|<br></br>
-uuuuyuxzz??zxyuuruuw`                            .....`|^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuzuuuuxzr?+`+-                                 ..`~|>+^~---|^+>??vrz~<br></br>
                  -uuuuuywzuuuyx?^~.                                     .`-|+^~~~|~|^+???vv`<br></br>
-uuuuyzrxxxuuuy^                                         .```~~~~-~|^^>?v?-<br></br>
-uuuyuruyuwxxz|                                            .`.`|-----|+>??`<br></br>
-uuuyxuuyxxxyu-                                             .-..`~~--~^+>?`<br></br>
-uuuuuuuuzxzuy>                                              .`..`~~~~^^^+`<br></br>
-uuuuuuuzuxwr+.                                               `....`|^+>++.<br></br>
                  -uuuuuuuzxxzz.                                                .` ...`^++++.<br></br>
                  -uuuuuuuxxxxw.`                                                -. ....~^^^.<br></br>
                  -uuuuuuxxxxxz`|.                                               -.   ..`|^^.<br></br>
                  -uuuuuuxxzzzw~|`                                               `     ..`~|.<br></br>
-uuuuuuuuxw}v^^~                                              .` .  . .```.<br></br>
                  -uuuuuuuuuxwr^^|                                              ~~........`~.<br></br>
                  -uuuuuuuuxxxz~-^                                             ``.........-~.<br></br>
                  -uuuuuuuuxxuz`~-.                                          .^?~......`..--.<br></br>
-uuuuuuuuuxu>~+..                                         .|+^~`........``.<br></br>
                  -uuuuuuuuuyv-`|                                         .`-`````````....`-.<br></br>
                  -uuuuuuuuyv.^ .                                        `~~~~~------|...`--.<br></br>
                  -uuuuuuuyv`..                                       .`-~|||++|~~~~+|..-~~-.<br></br>
-uuuuuuy?..                                      .`~||~~>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyx.                                    .`-~|||||^+?vvv??+||--~|||||^.<br></br>
-uuuur}?~`|+>>-``..                  ..``-~|^+>>>>????+>?????>>+^+++^^^^^|.<br></br>
-}?z}~``^|``>}?rxzwr}vv?????>>?????vvvvvv???>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-zv???v>``|v??ruxuuuuuuuxxxxuuxxxxzwrrrr}v??????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuxuyy}``>yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuuv+??+?ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuux?+ryyxv>?zuuuuuuxxxxzzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrr}}}}rrr}}v?+^^~~~|~|~~~~|^+>>+|--|^+^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyuxxyyuuuxr>`............``.`~~~~~~|^^~~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyx}zzuyuuxz}^?z?.....                ..`~^^^^++~--|^|||~-----~^^||>~<br></br>
-uuuuuuyu??wr?rzux}|?wu+ .                       .`~|^~-||^^^~------~>?^|v|<br></br>
-uuuuuuuyxwx^ ^xuuuuxxr~                            .`~~|^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuyurrxxwzwzzxr^+.                                .|^>+^~---|^+>??vrz~<br></br>
-uuuuuuz}wvxyuxur^|~                                     `++|~~~|~|^+???vv`<br></br>
-uuuuyzruyz?ruuxxr?.                                      .||~~~~-~|^^>?v?-<br></br>
-uuuyuruuuyw?vzr}?.                                         |-`~-----|+>??`<br></br>
-uuuyxuuuuuywzzzv.                                           ^`.`~~--~^+>?`<br></br>
                  -uuuuuuuuuuuzxzxz.                                           .^..`~~~~^^^+`<br></br>
-uuuuuuuuuuxzw}v+                                             --...`|^+>++.<br></br>
                  -uuuuuuuuuuxzwv`                                              .+....`^++++.<br></br>
-uuuuuuuxxxx}?+ .                                              +. ....~^^^.<br></br>
                  -uuuuuuxxxxxw?` ~.                                             ^.   ..`|^^.<br></br>
                  -uuuuuuxxzzzwv..-.                                             +.    ..`~|.<br></br>
-uuuuuuuuxw}v> ...                                            .| .  . .```.<br></br>
                  -uuuuuuuuxxwr~`.`                                             |~........`~.<br></br>
                  -uuuuuuuuxxxv.-                                              `~.........-~.<br></br>
-uuuuuuuuxxu~..                                             .>~......`..--.<br></br>
                  -uuuuuuuuuu?..                                             .|^~`........``.<br></br>
                  -uuuuuuuuyw.`                                             `~````````....`-.<br></br>
                  -uuuuuuuux-..                                           .~|~~------|...`--.<br></br>
                  -uuuuuuuu^ .                                          .-^||++|~~~~+|..-~~-.<br></br>
-uuuuuuy?.                                          .~||>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyy`                                        .`~||^+?vvv??+||--~|||||^.<br></br>
-uzuyrv?~-~+?v>??>>^|~-`..                 ..`-|+>????+>?????>>+^+++^^^^^|.<br></br>
-r>rv-``~^``^v?wuuuuxxzrr}}}}vvv???>>>>>>>>>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-xr??}w?``|v?vwuxxxxxxxxxxxxxuxxxxzzwrwr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuyy}-`>yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuxv+??+?ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuw>>wyyxv+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrr}}}}rrr}vv?+^^~~~|~|~~~~|^+>>+|--|^+^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyuxxyyyyuxr>`............``.`~~~~~||^^~~~~|~~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyxvwzuyuuux}^?x?.....                ..`~^^^^++~--|^|||~-----~^^||>~<br></br>
-uuuuuuyu??xr?}zux}|?zy+ .                       .`~|^~-||^^^~------~>?^|v|<br></br>
-uuuuuuuyuwu^ ^xuyuuxuw~                            .`~~|^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuyurrxzwzzxuxr+>.                                `|^>+^~---|^+>??vrz~<br></br>
-uuuuuuz}rvxyuuur+^~                                     `>+|~~~|~|^+???vv`<br></br>
-uuuuyzruyz>rxuyyw?.                                      .^|~~~~-~|^^>?v?-<br></br>
-uuuyuruuuyw>vxxzv.                                         |-`~-----|+>??`<br></br>
-uuuyxuuuuuyrwuur.                                           ^`.`~~--~^+>?`<br></br>
                  -uuuuuuuuuuuzxxyu`                                           .^..`~~~~^^^+`<br></br>
-uuuuuuuuuuxzzwr>.                                            --...`|^+>++.<br></br>
-uuuuuuuuuuxzx}`                                              .+....`^++++.<br></br>
-uuuuuuuxxxx}?+ `                                              +. ....~^^^.<br></br>
                  -uuuuuuxxxxxwv- |.                                             ^.   ..`|^^.<br></br>
                  -uuuuuuxxzzzwv..~.                                             +.    ..`~|.<br></br>
-uuuuuuuuxw}v> .`.                                            .^ .  . .```.<br></br>
                  -uuuuuuuuxxwr-`.`                                             |~........`~.<br></br>
                  -uuuuuuuuxxxv.~                                              `~.........-~.<br></br>
-uuuuuuuuxxx-..                                             .>|......`..--.<br></br>
                  -uuuuuuuuuu?..                                             .|^~`........``.<br></br>
                  -uuuuuuuuyw.`                                             `~````````....`-.<br></br>
                  -uuuuuuuux-..                                           .~|~~------|...`--.<br></br>
                  -uuuuuuuu| .                                          .-^||++|~~~~+|..-~~-.<br></br>
-uuuuuuy?.                                          .-||>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyy`                                        .`~||^+?vvv??+||--~|||||^.<br></br>
-uzuyrv?~-~+?v>???>+^|-`...                 .`-|+>????+>?????>>+^+++^^^^^|.<br></br>
-r>rv-``~^``^v?wuuuuuuxwwrrr}}vv????>>>>>>>>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-xr??}w?``|v?vwuxxxxxxxxxxxxxuxxxxzzwrwr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuuyy}-`>yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuxv+??+?ruuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuw>>wyyzv+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrr}w}}}}vv?+^^~~~|~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyuzrzuyuuxr?-............``.`--`--~|^^|~~~||~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyxvzuvwyyuuxrvrr|.....                 ..-|^^++~--|^|||~-----~^^||>~<br></br>
-uuuuuuyu?^?zzwzxux}~^xz-..                       .`~^|-||^^^~------~>?^|v|<br></br>
-uuuuuuuyu}vr? .ruyuxxxw+                             `|^|^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?rxz??zxuuwv?~                                -^++^----|^+>??vrz~<br></br>
-uuuuuuz}r?>xuuux}>+~.                                   .^+|~~~|~|^+???vv`<br></br>
-uuuuyzruyz+>wuuyuv?`                                      ~+~~~~-~|^^>?v?-<br></br>
-uuuyuruuuyw>>vxxzr~                                        ~|`~-----|+>??`<br></br>
-uuuyxuuuuuyw?}uxv-                                          |-.`~~--~^+>?`<br></br>
-uuuuuuuuuuuuwxuy}.                                           ^..`~~~~^^^+`<br></br>
-uuuuuuuuuuuuzzw}?                                            `|...`|^+>++.<br></br>
-uuuuuuuuuuuxzx>                                              .>....`^++++.<br></br>
-uuuuuuuxxxxr?>. .                                             >` ....~^^^.<br></br>
                  -uuuuuuxxxxxwv` ``                                             +`   ..`|^^.<br></br>
-uuuuuuxxzzwwv..-`.                                            >.    ..`~|.<br></br>
-uuuuuuuuxw}v~.....                                           .+.   . .```.<br></br>
                  -uuuuuuuuuxz?.`.  .                                           ||........`~.<br></br>
                  -uuuuuuuuxxx~-`                                              .|.........-~.<br></br>
                  -uuuuuuuuxuv `.                                             .+|......`..--.<br></br>
                  -uuuuuuuuux``                                              .||~`........``.<br></br>
-uuuuuuuuy> `                                             .|`.``````....`-.<br></br>
                  -uuuuuuuyr.                                             .-|--------|...`--.<br></br>
                  -uuuuuuuu-                                            .`^^~++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                                           .-||>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyy~                                        .`~||^+?vvv??+||--~|||||^.<br></br>
-xzuyr??~~-+?v?vv???>+^~-`.....             ...~^>????+>?????>>+^+++^^^^^|.<br></br>
-w>}v-``~^``|v?zuuuuuuxzwwzwrrrr}vv?????>>>>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uw?vrz?``|v?vwuxxxxxxxxxxxxxxxzzzzwwrrw}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv--?yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuxv+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuw>>wyyzv+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrr}w}}}}vv?+^^~~~|~|~~~~|^+>>+|--|^>^~-```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyuz}zuyuuxr?~............``.`-``--||^^|~~~||~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyxvzu?ryyuuuzvrw^.....                 ..-|^+++~--|^|||~-----~^^||>~<br></br>
-uuuuuuyu?|?zxwwxuuw-|zx|..                        `~^|-||^^^~------~>?^|v|<br></br>
-uuuuuuuyyvvxv .ruyuxxxu>                             -^^^^^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?}xz??zxuuzv}|                                -+++^----|^+>??vrz~<br></br>
-uuuuuuz}r?+zuuuur?>^.                                   .^+|~~~|~|^+???vv`<br></br>
-uuuuyzruyz++wuuyuvv~                                      -+~~~~-~|^^>?v?-<br></br>
-uuuyuruuuyw>+?xxuu^                                        ~^`~-----|+>??`<br></br>
-uuuyxuuuuuuw>}uxz|                                          ~-.`~~--~^+>?`<br></br>
                  -uuuuuuuuuuuuwzxyx.                                           ^`.`~~~~^^^+`<br></br>
-uuuuuuuuuuuuzzwwv.                                           `^...`|^+>++.<br></br>
-uuuuuuuuuuuxzu?                                               >....`^++++.<br></br>
-uuuuuuuxxxxr?>. ~.                                            >` ....~^^^.<br></br>
                  -uuuuuuxxxxxwv` `^.                                            +`   ..`|^^.<br></br>
-uuuuuuxxzzwwv..-`.                                            >.    ..`~|.<br></br>
-uuuuuuuuxw}v-.``.`                                           .+.   . .```.<br></br>
                  -uuuuuuuuuxz?`-`. .                                           ~|........`~.<br></br>
                  -uuuuuuuuxxx-^`                                              .^.........-~.<br></br>
                  -uuuuuuuuxuv.-.                                              +|......`..--.<br></br>
                  -uuuuuuuuuz`-                                              .||~`........``.<br></br>
-uuuuuuuuy> `                                             .|`.``````....`-.<br></br>
-uuuuuuuy}.                                             .-|~-------|...`--.<br></br>
                  -uuuuuuuu`                                            .-^^~++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                                           .-||>vv}v>|||^|``~~~~|.<br></br>
                  -uuuuuyy~                                        .`~||^+?vvv??+||--~|||||^.<br></br>
-xzuyr??~~`+?v?vvv???>^|~-`......           ...-^>????+>?????>>+^+++^^^^^|.<br></br>
-w>}v-``~|``|??zuuuuuuxzzzzwwwrrr}vv?????>>>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uwvvrz?``|v?vwuxxxxxxxxxxxxxxxzzzzwrrrw}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-`?yyuuuxxxxxxxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuxv+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuuw>>wyyzv+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}}vv?>+^|~~~~~|~~~~|^+>>+|--|^>^--```--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyyzxxrxxxw}>-........`..```````.```-~~~~~~||~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyxvzu?+vruuuuzzw>......                  .`-|^^~--|^|||~-----~^^||>~<br></br>
-uuuuuuyu?^?v?zuuuuuv~-wv...                        .-~~~|^^^~------~>?^|v|<br></br>
-uuuuuuuyu}^?w?~-}uuxw}zw|                            .-^^|^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?>vxw?+rxyuxz}?.                              .|>^^~---|^+>??vrz~<br></br>
-uuuuuuz}r?^?xuuuzrv?>~|.                                 ->^-~~|~|^+???vv`<br></br>
-uuuuyzruyz++?xuuuz?^-                                     .>|-~~-~|^^>?v?-<br></br>
-uuuyuruuuyw>+?rxuur+.                                      .>-~-----|+>??`<br></br>
-uuuyxuuuuuuw>>}xxv~                                         `+.`~~--~^+>?`<br></br>
                  -uuuuuuuuuuuuwvuuu?                                           ^-.`~~~~^^^+`<br></br>
-uuuuuuuuuuuuxzxrw?                                           `>...`|^+>++.<br></br>
-uuuuuuuuuuuxxwv>..                                            >....`^++++.<br></br>
-uuuuuuuxxxxr?>`....                                           >- ....~^^^.<br></br>
                  -uuuuuuxxxxxwv~ .-..                                           +-   ..`|^^.<br></br>
-uuuuuuxxzzwwv..--..                                           >`    ..`~|.<br></br>
-uuuuuuuuxw}v````..                                           .+.   . .```.<br></br>
                  -uuuuuuuuuxz^ .`                                              ~~........`~.<br></br>
                  -uuuuuuuuxuv.~..                                             .^.........-~.<br></br>
                  -uuuuuuuuxx-.`                                               +~......`..--.<br></br>
                  -uuuuuuuuu? `                                               ||``........``.<br></br>
                  -uuuuuuuuz.                                               .^~.``````....`-.<br></br>
-uuuuuuuy>                                               `^~-------|...`--.<br></br>
                  -uuuuuuux.                                             .|^~++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                                            .~|>vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                                         .`|^^+?vvv??+||--~|||||^.<br></br>
-xwuy}??~~`+>v?vvvvv??>^^|~-````.....        ..`|+????+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|``|??xuuuuuuxzzzzzzwzwwwr}vv???>?>>>>??v}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwz?``|v?vwuxxxxxxxxxxxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-`>yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>wyyz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv??>+^|~~~~~|~~~~`-`.        .```-`--``~^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyywuxvzzzrv>~..........                ..`||~|^+^|~~~~----~||^>v-<br></br>
-uuyyuuyxvzu>~>vxuyyuxw?`..                          .`-|^^|||~-----~^^||>~<br></br>
-uuuuuuyu?^>??zyuuuuz|`r}.                              .`^^|~------~>?^|v|<br></br>
-uuuuuuuyuv~+xz^`vxuxw}}|                                  `^~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^>ux?+}zyyy}.                                    .~^--|^+>??vrz~<br></br>
-uuuuuuz}r?^^zuuxzwr}?.                                       -^~~|^+???vv`<br></br>
-uuuuyzruyz+^>xuuyx?^                                          -+-~|^^>?v?-<br></br>
-uuuyuruuuyw>+>vxuuu-                                           ||---|+>??`<br></br>
-uuuyxuuuuuuw>^?xxz>                                            .>---~^+>?`<br></br>
                  -uuuuuuuuuuuuw?xxyz.                                             ^^~~~^^^+`<br></br>
-uuuuuuuuuuuuuwxwu}                                              ~>`|^+>++.<br></br>
-uuuuuuuuuuuux}wz-.                                              `>``^++++.<br></br>
-uuuuuuuxxxxr??|-.`                                              .>...~^^^.<br></br>
-uuuuuuxxxxxwv| .||`                                             .>...`|^^.<br></br>
                  -uuuuuuxxzzzwv`.||``                                             ~+  ..`~|.<br></br>
-uuuuuuuuxw}v.`~-~`.                                             >- . .```.<br></br>
                  -uuuuuuuuuxz|.-|...                                             ~+......`~.<br></br>
                  -uuuuuuuuxuv.^``                                               `+`......-~.<br></br>
                  -uuuuuuuuuz``-          .                                     -^.....`..--.<br></br>
-uuuuuuuuu> -            ...                                `|~`........``.<br></br>
                  -uuuuuuuuz..              .``.                           ..--```````....`-.<br></br>
-uuuuuuuy> .               .`~`.......               ..`-..`-------|...`--.<br></br>
                  -uuuuuuuz.                   .``....`----~-`.``---~|^^|`.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      ..``-`````++~-~~~~|||-`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                          ..```-~------`...`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--````............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzxzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv??>+^|~~~|~|~~..                   ..`~++|-`-````````~^+?`<br></br>
-uyyyyyyyyyywuxvzzzrv>~......                       ....-|+^~~~~----~||^>v-<br></br>
-uuyyuuyxvzu>~>vxuyyuxw?..                               .`-||~-----~^^||>~<br></br>
-uuuuuuyu?^>??zyuuuuz|`>`                                 ...-~-----~>?^|v|<br></br>
-uuuuuuuyuv~+xz^`vxux}-..                                   . .-~~~~+vv?}z|<br></br>
-uuuuuuuur?^>ux?+}zyx^.                                      . .-^^+>??vrz~<br></br>
-uuuuuuz}r?^^zuuxzwr|.                                         . -|^+???vv`<br></br>
-uuuuyzruyz+^>xuuyu^.                                           . ~||^>?v?-<br></br>
-uuuyuruuuyw>++vxuz~.                                           . .~-~+>??`<br></br>
-uuuyxuuuuuuw>^?xxv.                                             . -~~^+>?`<br></br>
                  -uuuuuuuuuuuuw?xxyv                                              ..`|~^^^+`<br></br>
-uuuuuuuuuuuuuwxwuv                                               ..|^+>++.<br></br>
-uuuuuuuuuuuux}wz-.                                               ..-^++++.<br></br>
                  -uuuuuuuxxxxr??|-.-.                                              .`..~^^^.<br></br>
                  -uuuuuuxxxxxwv| .||`                                              ``..`|^^.<br></br>
                  -uuuuuuxxzzzwv`.||``                                             .|. ..`~|.<br></br>
-uuuuuuuuxw}v.`~--`.                                             -. . .```.<br></br>
                  -uuuuuuuuuxz|.-|...                                             .`......`~.<br></br>
                  -uuuuuuuuxuv.^``       .                                     . .-`......-~.<br></br>
                  -uuuuuuuuuz``-         ...       .`.     ..              .... ``.....`..--.<br></br>
-uuuuuuuuu> -           ....             |^-`    .............-`........``.<br></br>
                  -uuuuuuuuz..              .`~.           `^-`   ...... ..``..```````....`-.<br></br>
-uuuuuuuy> .               .`|`..````....`|`......``.`-|^`.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`-|^^|^|-`~|^^^+>>?>~.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+||^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzzzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv??>++|.                                       ~-`````~^+?`<br></br>
-uyyyyyyyyyywuxvzzzrv^.                                         -|--~||^>v-<br></br>
-uuyyuuyxvzu>~>vxuyyz-                                           `|-~^^||>~<br></br>
-uuuuuuyu?^>??zyuuuu~                                             .~~>?^|v|<br></br>
-uuuuuuuyuv~+xz^`vxv                                               `>vv?}z|<br></br>
-uuuuuuuur?^>ux?+}x+                                                ^??vrz~<br></br>
-uuuuuuz}r?^^zuuxzz-                                                .>?vvv-<br></br>
-uuuuyzruyz+^>xuuyu~                                                 ^>?v?-<br></br>
-uuuyuruuuyw>++vxuuv.                                                ~+>??`<br></br>
-uuuyxuuuuuuw>^?xxr^                                                 ~^+>?`<br></br>
                  -uuuuuuuuuuuuw?xxyw                                                 .~^^^+`<br></br>
-uuuuuuuuuuuuuwxwur   ..                                            -^+>++.<br></br>
-uuuuuuuuuuuux}wz-..  `...                                         .`^++++.<br></br>
                  -uuuuuuuxxxxr??|-.~`  `......                                 .    ...~^^^.<br></br>
                  -uuuuuuxxxxxwv| .||` .`........ ~v~                    ....``~~.   ...`|^^.<br></br>
                  -uuuuuuxxzzzwv`.||`` .``....... .v^ ....`.    .^`   `--~~~-~~~-   .. ..`~|.<br></br>
-uuuuuuuuxw}v.`~--`. ..`........ ?^  ..>?>~^~ `?~ ..-~-~~~-~~~`  .. . .```.<br></br>
-uuuuuuuuuxz|.-|...   .......... >+  . `??>?+  >| ..~~-~~--~~~` ........`~.<br></br>
-uuuuuuuuxuv.^``      ...........~?  .. -?>?+ `>- .--~~~-`~|^^..........-~.<br></br>
-uuuuuuuuuz``-         ..........`}^.....vv??..`..~||^^~`+>?>`.......`..--.<br></br>
-uuuuuuuuu> -           ...`.......`.....wu}}...`^>+>>~`>??>. ``........``.<br></br>
-uuuuuuuuz..             ..-~`...........?ur?..`~|^>>~`+??^..```````....`-.<br></br>
-uuuuuuuy> .               .`|-.`-~~~~~~~>wv>|^+>>>>^|>?+-.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`~^++>>+|~++>>>>>>?>-.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+|~^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzzzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyyz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv??>~..                                        ..   `-~^+?`<br></br>
-uyyyyyyyyyywuxvzzzr|.                                          ..  .~|^>v-<br></br>
-uuyyuuyxvzu>~>vxuyy>                                             .  `^||>~<br></br>
-uuuuuuyu?^>??zyuuuu~                                              . .+^|v|<br></br>
-uuuuuuuyuv~+xz^`vxr-.                                              ..^?}z|<br></br>
-uuuuuuuur?^>ux?+}x?~-.                                             ..+vrz~<br></br>
-uuuuuuz}r?^^zuuxzz^``..                                             ~?vvv-<br></br>
-uuuuyzruyz+^>xuuyu^..                                              .^>?v?-<br></br>
-uuuyuruuuyw>+>vxuux~                                                ~+>??`<br></br>
-uuuyxuuuuuuw>^?xxz?                                    ......   .   -^+>?`<br></br>
                  -uuuuuuuuuuuuw?xxyz. ..          .............      ...........   . .~^^^+`<br></br>
-uuuuuuuuuuuuuwxwur. .``.         ............     ............     -^+>++.<br></br>
-uuuuuuuuuuuux}wz-.. .~`....    .. .........       ............   ..`^++++.<br></br>
                  -uuuuuuuxxxxr??|-.~` .|`....... `~. .......         ..........`.  ....~^^^.<br></br>
-uuuuuuxxxxxwv| .||` .~`........^x>....             .````~~^+>>.    ..`|^^.<br></br>
-uuuuuuxxzzzwv`.||`` .``........`z?....-~.....`?~...|>>>>?>>>>>.     ..`~|.<br></br>
-uuuuuuuuxw}v.`~--`. ..`.........r?....vz}>?>.~z+...+?>>>>>>>?^  .  . .```.<br></br>
-uuuuuuuuuxz|.-|...   ...........vv....|wwrw?..v>..~>>>>>+>>>?- ........`~.<br></br>
-uuuuuuuuxuv.^``      ...........|v.....^wvwv.~}^.`>>>>?+~>>?+..........-~.<br></br>
-uuuuuuuuuz``-         ..........`}^....`zz}r.`-.`+?>>?+->>?>........`..--.<br></br>
-uuuuuuuuu> -           ...`.......`.....wu}}...`^>+>>~`>??>. ``........``.<br></br>
-uuuuuuuuz..             ..-~`...........>ur?..`~|^>>~`+??^..```````....`-.<br></br>
-uuuuuuuy> .               .`|-.`-~~~~~~~>wv>|^+>>>>^|>?+-.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`~^++>>+|~++>>>>>>?>-.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+|~^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzzzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??^?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv?`                                                   ~|+?`<br></br>
-uyyyyyyyyyywuxvzz}^.                                                 |^>v-<br></br>
-uuyuuuyxvzu>~>vxuyyv++|.                                           .`^||>~<br></br>
-uuuuyuyu?^>??zyuuuu}`.>>.        ....``````````````..  ..```........~>^|v|<br></br>
-uuuuuuuyyv~+xz^`vxux?+>?~      .......````````````````.. .`........`|>?}z|<br></br>
-uuuuuuuur?^>ux?+}zyyuv?>.      ...`````----`````````````. .`....```-~>vrz~<br></br>
-uuuuuuz}r?^^xuuxzzr}v>>.     ..````-``````-||-````````````. ......`~+?vvv-<br></br>
-uuuuyzruyz+^>xuuyx??+. ....  .`````.........-^|-`..````````. ....`-|^>?v?-<br></br>
-uuuyuruuuyw>++vxuuy}. ......`-................-~-..`````````. ``----|+>??`<br></br>
-uuuyxuuuuuuw>^?xxz?  .......``..````..`..```.........`~|^++>+..`~~--~^+>?`<br></br>
-uuuuuuuuuuuuw?xxyz. ...........``````~?|~|+^|``....|^+>>?>>>?~ .`~~~~^^^+`<br></br>
-uuuuuuuuuuuuuwxwuw. .``........``````-^`-^^|^`....->>>>+>+>>?+....-|^+>++.<br></br>
-uuuuuuuuuuuux}wz-.. .~`........^+````````-``......`>>>>+>>>>>>. ...`^++++.<br></br>
-uuuuuuuxxxxr??|-.~` .|`........>x~`````````........~>>>+>>>>>>`  ....~^^^.<br></br>
-uuuuuuxxxxxwv| .||` .~`........^y?...`.............->>>+>>>>>>.    ..`|^^.<br></br>
-uuuuuuxxzzzwv`.||`` .``........`z?....-~`....-?|...|?>>>>>>>>>.     ..`~|.<br></br>
-uuuuuuuuxwrv.`~--`. ..`.........r?....vz}>?>.~z+...+?>>>>>>>?^  .  . .```.<br></br>
-uuuuuuuuuxz|.-|...   ...........vv....|wwrw?..v>..~>>>>?+>>>?- ........`~.<br></br>
-uuuuuuuuxuv.^``      ...........|v.....^wvwv.~}^.`>>>>?+~>>?+..........-~.<br></br>
-uuuuuuuuuz``-         ..........`}^....`zz}r.`-..+?>>?+->>?>........`..--.<br></br>
-uuuuuuuuu> -           ...`.......`.....wu}}...`^>+>>~`>??>. ``........``.<br></br>
-uuuuuuuuz..             ..-~`...........>ur?..`~|^>>~`+??^..```````....`-.<br></br>
-uuuuuuuy> .               .`|-.`-~~~~~~~>wv>|^+>>>>^|>?+-.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`~^++>>+|~++>>>>>>?>-.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+|~^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzxzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv?-  .. . .....                         ...           ~|+?`<br></br>
-uyyyyyyyyyywuxvzz}+~`.                                 ...          .|^>v-<br></br>
-uuyuuuyxvzu>~>vxuyyuw}?.            ......``````...   ...````......`|^||>~<br></br>
-uuuuyuyu?^>??zyuuuuz|`rr`... ....`-|^^++>>>>>>>>>?>^-..`|^^^|------~>?^|v|<br></br>
-uuuuuuuyyv~^xz^`vxuxrvwy>.......`--~|^+>>>>>>>>>>>???>|..~^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^>ux?+}zyyuuuw- ......-|>>????????>>>>>>>>???>|.`^~---|^+>??vrz~<br></br>
-uuuuuuz}r?^^xuuxzzr}?>?. .....|>????>>+^^^>v}?>>?>+>>>>??>`.-~-~||^+???vv-<br></br>
-uuuuyzruyz+^>xuuyx??+. ......~?>+>^~```````-?wr??~~?>>>>???`.`~~~~|^^>?v?-<br></br>
-uuuyuruuuyw>++vxuuy}. ......`+|`--```````````|?v?``>??>+>??>`.~-----|+>??`<br></br>
-uuuyxuuuuuuw>^?xxz?  .......``.```````-``~~|-`.``..`|^>>>>>>+..`~~--~^+>?`<br></br>
-uuuuuuuuuuuuw?xxyz. ...........``````~?|~^>+^``....|^+>>>>>>?~ .`~~~~^^^+`<br></br>
-uuuuuuuuuuuuuwxwuw. .``........``````-^`-^^|^`....->>>>+>+>>?+....-|^+>++.<br></br>
-uuuuuuuuuuuux}wz-.. .~`........^+````````-``......`>>>>+>>>>>>. ...`^++++.<br></br>
-uuuuuuuxxxxr??|-.~` .|`........>x~`````````........~>>>+>>>>>>`  ....~^^^.<br></br>
-uuuuuuxxxxxwv| .||` .~`........^y?...`.............->>>+>>>>>>.    ..`|^^.<br></br>
-uuuuuuxxzzzwv`.||`` .``........`z?....-~`....-?|...|?>>>>>>>>>.     ..`~|.<br></br>
-uuuuuuuuxw}v.`~-~`. ..`.........r?....vz}>?>.~z+...+?>>>>>>>?^  .  . .```.<br></br>
-uuuuuuuuuxz|.-|...   ...........vv....|wwrw?..v>..~>>>>?+>>>?- ........`~.<br></br>
-uuuuuuuuxuv.^``      ...........|v.....^wvwv.~}^.`>>>>?+~>>?+..........-~.<br></br>
-uuuuuuuuxz``-         ..........`}^....`zz}r.`-.`+?>>?+->>?>........`..--.<br></br>
-uuuuuuuuu> -           ...`.......`.....wu}}...`^>+>>~`>??>. ``........``.<br></br>
-uuuuuuuuz..             ..-~`...........>ur?..`~|^>>~`+??^..```````....`-.<br></br>
-uuuuuuuy> .               .`|-.`-~~~~~~~>wv>|^+>>>>^|>?+-.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`~^++>>+|~++>>>>>>?>-.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+|~^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzxzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrrrrrrr}}vv??>+^|~~~~~~~~~--`..               ...`^^|-`-````````~^+?`<br></br>
-uyyyyyyyyyywuxvzzzrv>-........``````......```.....-|~~|++^|~|~~----~||^>v-<br></br>
-uuyuuuyxvzu>~>vxuyyuxw?`.......   ...`-~^+>>>>+^|`..-~--|^|||~-----~^^||>~<br></br>
-uuuuyuyu?^>??zyuuuuz|`r}`... ....`~|^^++>>>>>>>???>^`..`|^^|~------~>?^|v|<br></br>
-uuuuuuuyuv~^xz^`vxuxrvwy>.......`---|^+>>>>>>>>>>>???>|..~^|~--~~~~+vv?}z|<br></br>
-uuuuuuuur?^>ux?+}zyyuuuw- ......-|>>????????>>>>>>>>>??>|.`^~---|^+>??vrz~<br></br>
-uuuuuuz}r?^^xuuxzzr}?>?. .....|>????>>+^^^>v}?>>?>+>>>>??>`.-~-~||^+???vv-<br></br>
-uuuuyzruyz+^>xuuyx??+. ......~?>+>^~```````-?wr??~~?>>>>???`.`~~~~|^^>?v?-<br></br>
-uuuyuruuuyw>++vxuuy}. ......`+|`--```````````|?v?``>??>+>??>`.~-----|+>??`<br></br>
-uuuuxuuuuuuw>^?xxz?  .......``.```````-``~~|-`.``..`|^>>>>>>+..`~~--~^+>?`<br></br>
-uuuuuuuuuuuuw?xxyz. ...........``````~?|~^>+^``....|^+>>>>>>?~ .`~~~~^^^+`<br></br>
-uuuuuuuuuuuuuwxwuw. .``........``````-^`-^^|^`....->>>>+>+>>?+....-|^+>++.<br></br>
-uuuuuuuuuuuux}wz-.. .~`........^+````````-``......`>>>>+>>>>>>. ...`^++++.<br></br>
-uuuuuuuxxxxr??|-.~` .|`........>x~`````````........~>>>+>>>>>>`  ....~^^^.<br></br>
-uuuuuuxxxxxwv| .||` .~`........^y?...`.............->>>+>>>>>>.    ..`|^^.<br></br>
-uuuuuuxxzzzwv`.||`` .``........`z?....-~`....-?|...|?>>>>>>>>>.     ..`~|.<br></br>
-uuuuuuuuxw}v.`~-~`. ..`.........r?....vz}>?>.~z+...+?>>>>>>>?^  .  . .```.<br></br>
-uuuuuuuuuxz|.-|...   ...........vv....|wwrw?..v>..~>>>>?+>>>?- ........`~.<br></br>
-uuuuuuuuxuv.^``      ...........|v.....^wvwv.~}^.`>>>>?+~>>?+..........-~.<br></br>
-uuuuuuuuxz``-         ..........`}^....`zz}r.`-.`+?>>?+->>?>........`..--.<br></br>
-uuuuuuuuu> -           ...`.......`.....wu}}...`^>+>>~`>??>. ``........``.<br></br>
-uuuuuuuuz..             ..-~`...........>ur?..`~|^>>~`+??^..```````....`-.<br></br>
-uuuuuuuy> .               .`|-.`-~~~~~~~>wv>|^+>>>>^|>?+-.`-------|...`--.<br></br>
-uuuuuuuz.                  ..--`..`~^++>>+|~++>>>>>>?>-.`-++|~~~~+|..-~~-.<br></br>
-uuuuuuy?                      .``-~-`--`>+|~^^^^+>+|`..+vv}v>|||^|``~~~~|.<br></br>
                  -uyuuuyy|                         ..``-~~^|||||~-`..`-|+?vvv??+||--~|||||^.<br></br>
-xwxy}??~~`+>v?}}}}}vv?>>+^|~--```.............`-^>???+>?????>>+^+++^^^^^|.<br></br>
-z>vv~-`-|-`~>?xuuuuuuxzzzxzzzzzzzwr}vv????>>>>??}}}vvvv????>>+++>>>+++^++.<br></br>
-uzvvwxv``|v?vwuxxxxxxxxxzxxxxxzzzzwrrrr}vv?????vvvvvvv?????>>++++++++>>>>`<br></br>
-uuuyyyv-->yyuuuuuuuxuxxxxxxxxxzzzwwwrrwzzw}v??????????>>>?????>>>>>?>>?>v~<br></br>
-uuuuz?+??+?wuuuuuuuuuxxxxxxzzzzzzzwwwwwzzzwr}}}}}vvvvvv??????v}}}rrrv????-<br></br>
-uuur>>zyuz?+?xuuuuuuxxxxxzzzwwwwwwwwwzzzzzzzwwwwwr}}}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrw?--|}}}}}}vvv?^~-`...````..................`.. .`||~--````````~^+?`<br></br>
-yyyyuyuuwwxzuuzuzrxzv-.........``-~~~~--~~~~------|>+|--`-~~~~-----~||^>v~<br></br>
-uyyuuuuuzuu}>?v}v}uv>^~......``--```-~|^+>>>>>+++|~~^>>+|`.`-~-----~^^||>~<br></br>
-uyuuuuuuwvrrvvrww}?+`.+>...``````-~^^++>>>>>>>>>??v?^~^>??|`.``----~>?^|v|<br></br>
-uyuuuuuuuwvvuy?|++>>++>?~....``-||^^++>>>>>>>>>>>>?vvv?++??+`..-~~~+vv?}z|<br></br>
-uyuuuuuurvvvuu>`^+>??>?>`..`~~~~^^+++^||||+>>>>>>>??vv}}?+>?>~..~^+>??vrz~<br></br>
-uyuuuuz}rrvvxv++++++|-|...~~~~^^^^+>>^^^|~-|+>>>>>+>?vvv}v>+>>^``~^+??vvv`<br></br>
-uyuuyzrxuz}v|^>>??|-`. ..-~~--^+>vv?+--~|^^^+++>?+^>>?vvvvv>^>>^``~|^>?v?`<br></br>
-uyuyxruuuuz|.`|>???+....`-`.`||`-^|~``````-~^>?+^||>>>>??vvv>+??^..`~+>??`<br></br>
-uuuuxuyuuuv--`~>>>+  ...`...``.```````-``--~`--|-`-|++>|>}v}v^^>?~.`~^+>?`<br></br>
-uuuuuuuuuw|+^->>?v. ...........``````-^~~~|||`-````|^>+~+}}}}?|+>?`.-^^^+`<br></br>
-uuuuuuuux+|++^?>vv. ..`....`..```````-~|+>||+~-`..`||||~~+>v}v^^>>|.-++++.<br></br>
-uuuuuuuu?-^++^>>~`...``........~|``````^~-~-|~-`..`~----^?vv}}+~+>+.`^+++.<br></br>
-uuuuuuuz~-^|~|```~...``......`.?r~`````~```--`-`...`^~-`>}v}}}^~^+>..-^^^.<br></br>
-uuuuuxu?`-^^~` `|~...``.......`vuv.```````.........-?>|-?}v}}}^~^^^`.`^^^.<br></br>
-uuuuuux>--^^~..|^`...``........+x}`...`-`.....|`...~>>+|vvv}}v^~^^^...`||.<br></br>
-uuuuuur>``~|..``~`.............-zw-..`>?+~|~.`>-...~>>++vvv}}v^|^^^. .```.<br></br>
-uuuuuuv?``~` .` .` ............~ww`.`^?rv>>|..^~..`^>>+?v?v}v?^^++|....`~.<br></br>
-uuuuuu??--` ...  .. ...........>vv...|^?wv>~.`^-..~>>>>?>?}v?^^>>>~....-~.<br></br>
-uuuuux??-- .. .      .........|?+^`..`^>zr+^..`..-+>>>>^?}v>++>+++`....--.<br></br>
-uuuuuz??^.     .      ......-+?~......-?wr^|....-^>>>^|>vv||^>>++~.....`-.<br></br>
-uuuuuxv?+      ..      ...`~??^........^}w+~...`|>>>^|>vv~`^>>>>+......`-.<br></br>
-uuuuuxvv`       ...  ..  .`-|-`.```````~}z?``-~+>>?+^?vv~.^>>>>>-.....`--.<br></br>
-uuuuux++         .`.  ... .`~~~-`.```---vv+.-~|++>>>?v>-~>?v?>>~.`-..-~~-.<br></br>
-uuuuuyv`           .`. ......`-~||||~~~-?v>~|^^^+>??>^~?rrwrv>~`````~~~~~.<br></br>
-uyuuuyw.            .``...`.....``-~||||>v>|~~~|^++>>>?}rrrr?~```-~|||||^.<br></br>
-uwxyw?>`. ..```````-~-~+??>+^||~~-------|`..-|^>???vvvvrrrv+~-~^+++^^^^^^.<br></br>
-u??}^-``~.....`-----~~|^>vwzxzzzww}v??>?>>||+++?vvrrrrr}?+~-~^++>>>+++^++.<br></br>
-uxvvrx}``-v????+|~-----~|^+?vwzzzzzwr}v?v?>>>?v}rrrrv?>^~~^+>++++++++>>>>`<br></br>
-uuuuuyw~`^xyuuuuzrv?>^~~--~||^>?v}}rrwzzzzwr}}vv??>+|~~^+>????>>>>>?>>?>v~<br></br>
-uuuuu}>>?+>ruuuuuuuuuxw}v?+^~~~~||^^+>????>>+^^||||^>?v??????v}}}rrrv????-<br></br>
-uuuxv>vuyxv+?zuuuuuuxxxxxxxwr}v??>>++^^^^+++>>>??vvv}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrr}}r+  .v}rrrrrrr}^~`.   .......`~^+++++++++++++-.   -|~--````````~^+?`<br></br>
-yyyyxuxx}vwzxyyuzvyyw`. .......-^+>????>>>>>>>>????}}?~. .`-|~-----~||^>v~<br></br>
-uyyyuzuuuuxxv??>->z>. ......`|>?>>++++>>>>>>>>>>?????wz}^. .`~-----~^^||>~<br></br>
-uyuuyxzuuuuyr>?vv>.  .....`|+^~--|^+>>>>>>>>>>>>?v}}v?rzz}|  .`----~>?^|v|<br></br>
-uyuuuyuxxuuuyyy}-  .......```-|+>?????>>>>>>>>>>>?v}rr}wzzzv` .`~~~+vv?}z|<br></br>
-uyuuuuuur}zxxx?.  ........`~>????>+^~-````|+>?>>>>?vwrwzzzzxz>. -^+>??vrz~<br></br>
-uyuuuuz}vzxux+   ........->??>>^~~^++++^~`..`|>?>>>?}zrwwzzzzxv. `^+>??vv`<br></br>
-uyuuyzrxxzxx|   .........+?>^--^>wzzv-~^>??>|.`^>?>>>?wwrrzzzzx}. -|^>?v?`<br></br>
-uyuyxruxzxz|   ...`.....`+~`.~^-~>>|-`````-^?v?-->??>>?vrwwzzzzxv..`~+>??`<br></br>
-uuuuxuyuzx>   ...`~.....`...~-```````````````-^>+~>??>>`>xzzzzwzx> .-^+>?`<br></br>
-uuuuuuuux}    ...+-........ .``````````-~-`~|--`-~|+^>|`^zxzzzrzzz` `^^^+`<br></br>
-uuuuuuuux`   ...|>`........| ``````````+??-^>+^-````````.^>rzzwrzx? `+>++.<br></br>
-uuuuuuuu+    ..`>>.........`.``````````>^~|~?>+^````....~}rzzzrvzzr..^+++.<br></br>
-uuuuuuuw.    ..|?+..........``-??~`````^-``^+|^-`...~`..>urzzz}vzzz` `^^^.<br></br>
-uuuuuxu+     ..^?+.........`.`^yyw````````````.....`?>`.?xrzzz}vzzz~ .^^^.<br></br>
-uuuuuuz`     ..^?+............`}uu|.````````.......`>?^-}zrzzzrvzzz- .`||.<br></br>
-uuuuuuv~     ..`>+.............^uu>..~|`...........`>?|^}zrzzzzvzzw` .```.<br></br>
-uuuuuu?~   . ...~>`............>uu^.~wwr>`.........~>>^?w}wzwzwrzz}. ..`~.<br></br>
-uuuuux^~     ....~-...........`zu?..`rwwrv|........+>>>vvvxwrz}wzx> ...-~.<br></br>
-uuuuuz~|      |`..`...........?yv....|wzw?`.......^>>>???zr?zwwzzz` ...--.<br></br>
-uuuuuz-|.     `>...........`^}u?......>uw?.......|>>>>??rr~vzzzzx?  ...`-.<br></br>
-uuuuyz^~`      -?....`...-.|uur`.......}xv`.....^?>>>>>}w-|zzzzxw` ....`-.<br></br>
-uuuuuz|~        |?-..-|..`^^^^-........|ux?...-^+>>>>?}z|~}zzzzz| ....`--.<br></br>
-uuuuux``         -v+..|>~..^>+|~``......rx?..`~^+>>>?rr^>rzzzzz^ .`..-~~-.<br></br>
-uuuuuy^           .?v+`~v?|`-^>?>>>+^^^|?u?~|^+++>?rz}?}zzzzxw|  .``~~~~~.<br></br>
-uuyuuyv             -vr?^?}?>^^+>>??>>>+?xv^~|^?vwzzwrzzzzzzv` .`-~|||||^.<br></br>
-uzzyxv?..             ~vwr}ww}v?????????v+||?v}r}v?}zzzzzz}|  `^+++^^^^^^.<br></br>
-uv>r>-``~`.             `>}zxxzzwwv?>++>>+^~^||+?vrzzxxwv|  .~++>>>+++^++.<br></br>
-uuv?}zw-``vv>?^`.         .-?}zxxzzw}v?>v>+>>?}zzxxzr?^` .-^>++++++++>>>>`<br></br>
-uuuuuyx^`~wyuuuuz}?+-.       .`^?v}rwzzxzzzzzzw}v?^-. .`|>????>>>>>?>>?>v~<br></br>
-uuuuuz>+?>+vxuuuuuuuuzrv?^-`.      .`-~~|~~-`..    .|??v?????v}}}rrrv????-<br></br>
-uuuur+>zyxv+>ruuuuuuuuuuxxxw}v??>^~-..   ...``~|>??v}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrr}}r+  .v}rrrrrrr}^~`.   .......`~^+++++++++++++-.   -|~--````````~^+?`<br></br>
-yyyyxuxx}vwzxyyuzvyyw`. .......-^+>????>>>>>>>>????}}?~. .`-|~-----~||^>v~<br></br>
-uyyyuzuuuuxxv??>->z>. ......`|>?>>++++>>>>>>>>>>?????wz}^. .`~-----~^^||>~<br></br>
-uyuuyxzuuuuyr>?vv>.  .....`|+^~--|^+>>>>>>>>>>>>?v}}v?rzz}|  .`----~>?^|v|<br></br>
-uyuuuyuxxuuuyyy}-  .......```-|+>?????>>>>>>>>>>>?v}rr}wzzzv` .`~~~+vv?}z|<br></br>
-uyuuuuuur}zxxx?.  ........`~>????>+^~-````|+>?>>>>?vwrwzzzzxz>. -^+>??vrz~<br></br>
-uyuuuuz}vzxux+   ........->??>>^~~^++++^~`..`^>?>>>?}zrwwzzzzxv. `^+>??vv`<br></br>
-uyuuyzrxxzxx|   .........+?>^--^>wzzv-~^>??>|.`^>?>>>?wwrrzzzzx}. -|^>?v?`<br></br>
-uyuyxruxzxz|   ...`.....`+~`.~^-~>>|-`````-^?v?-->??>>?vrwwzzzzxv..`~+>??`<br></br>
-uuuuxuyuzx>   ...`~.....`...~-```````````````-^>+~>??>>`>xzzzzwzx> .-^+>?`<br></br>
-uuuuuuuux}    ...+-........ .``````````-~-`~|--`-~|+^>|`^zxzzzrzzz` `^^^+`<br></br>
-uuuuuuuux`   ...|>`........| ``````````+??-^>+^-````````.^>rzzwrzx? `+>++.<br></br>
-uuuuuuuu+    ..`>>.........`.``````````>^~|~?>+^````....~}rzzzrvzzr..^+++.<br></br>
-uuuuuuuw.    ..|?+..........``-??~`````^-``^+|^-`...~`..>urzzz}vzzz` `^^^.<br></br>
-uuuuuxu+     ..^?+.........`.`^yyw````````````.....`?>`.?xrzzz}vzzz- .^^^.<br></br>
-uuuuuuz`     ..^?+............`}uu|.````````.......`>?^-}zrzzzrvzzz- .`||.<br></br>
-uuuuuuv~     ..`>+.............^uu>..~|`...........`>?|^}zrzzzzvzzw` .```.<br></br>
-uuuuuu?~   . ...->`............>uu^.~wwr>`.........~>>^?w}wzwzwrzz}. ..`~.<br></br>
-uuuuux^~     ....~-...........`zu?..`rwwrv|........+>>>vvvxwrz}wzx> ...-~.<br></br>
-uuuuuz~|      |`..`...........?yv....|wzw?`.......^>>>???zr?zwwzzz` ...--.<br></br>
-uuuuuz-|.     `>...........`^}u?......>uw?.......|>>>>??rr~vzzzzx?  ...`-.<br></br>
-uuuuyz^~`      -?....`...-.|uur`.......}xv`.....^?>>>>>}w-|zzzzxw` ....`-.<br></br>
-uuuuuz|~        |?-..-|..`^^^^-........|ux?...-^+>>>>?}z|~}zzzzz| ....`--.<br></br>
-uuuuux``         -v+..|>~..^>+|~``......rx?..`~^+>>>?rr^>rzzzzz^ .`..-~~-.<br></br>
-uuuuuy^           .?v+`~v?|`-^>?>>>+^^^|?u?~|^+++>?rz}?}zzzzxw|  .``~~~~~.<br></br>
-uuyuuyv             -vr?^?}?>^^+>>??>>>+?xv^~|^?vwzzwrzzzzzzv` .`-~|||||^.<br></br>
-uzzyxv?..             ~vwr}ww}v?????????v+||?v}r}v?}zzzzzz}|  `^+++^^^^^^.<br></br>
-uv>r>-``~`.             `>}zxxzzwwv?>++>>+^~^||+?vrzzxxwv|  .~++>>>+++^++.<br></br>
-uuv?}zw-``vv>?^`.         .-?}zxxzzw}v?>v>+>>?}zzxxzr?^` .-^>++++++++>>>>`<br></br>
-uuuuuyx^`~wyuuuuz}?+-.       .`^?v}rwzzxzzzzzzw}v?^-. .`|>????>>>>>?>>?>v~<br></br>
-uuuuuz>+?>+vxuuuuuuuuzrv?^-`.      .`-~~|~~-`..    .|??v?????v}}}rrrv????-<br></br>
-uuuur+>zyxv+>ruuuuuuuuuuxxxw}v??>^~-..   ...``~|>??v}}}}vvvvv}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rrrrrrr?-`-???>vr?++^`..........`--~|+++++++++++++^+|~~``....````````~^+?`<br></br>
-yyuuuyuuwrvv}wzw?|>?+.......`-~^+>>>>>>>>>>>>>>>>??v}v?^|~`..``----~||^>v~<br></br>
-uyyuuuuuyuuuwr?-.->-......`-~^++^^^^+>>>>>>>>>>>>????}zr?+^~....`--~^^||>~<br></br>
-uyuuyuxxuuuyw^`~~-. ....```-~~-~|^+>>>>>>>>>>>>>??vvv?vrzr?^|`...`-~>?^|v|<br></br>
-uyuuuyuxxuuz?>>^. ........``~|+>>???>>>>>>>>>>>>>??v}r}}}rz}>^-..``+vv?}z|<br></br>
-uyuuuuuxzzr>++~. ........`~^>??>>+|~-````~^>>?>>>>??}rwzzwrwzv+~`--|??vrz~<br></br>
-uyuuuuxrzw>++- ........`~^>?>+|-```-~--``...`^>?>>>?v}rwwzzwwx}+~.`-^?vvv`<br></br>
-uyuuyzwzz>+>` .........-~>>^-`-^>}rwv++>>>+|`.`^?>>>>?vrwwzzzzxr>~.``+?v?`<br></br>
-uyuyuruz>++` ..``.....`~~~`.`~^^?vv?^``--~^>?>|`~>?>>>??vwzzzzzx}+~..->??`<br></br>
-uuuuxuu>^+- ...~`.`...``....````---`````````-|>>^-+?>>?|>}wzzzzzxv^`.`|>?`<br></br>
-uuuuuy?^>^  ..--.-`...........``````````--`-~``-|^^>>>+|+vr?vxwzzz>|.``^+`<br></br>
-uuuuur~++. ..`~``~........-^ .`````````|??|~>^^|``--~~|~->+>vxwrzzv^-`-^+.<br></br>
-uuuuu>|>` ...-~-~~........~~ .`````````^?^|~+?+^~```...`~????ww}zzw+~`-~+.<br></br>
-uuuuz~+^  ...~~~~-..........```>>-`````|+`-^>>+~|```-`..~?+>?ww}zzz>^.`-^.<br></br>
-uuuuv~+-  ..`~~|~-.........```+uu}-`````-```--```..`||`.|>++vxw}wzz>^```^.<br></br>
-uuuuv~^.  `.`~~|~-.......`....~wuu^.`````````......`>?-~?>+>vxwv}zz>^`.`|.<br></br>
-uuuu?||.  `.`-~~~-.............?uu?..`-`...........`>>-^v}rwzzz}}zz+^`..`.<br></br>
-uuuu?^`.  `.`-~-`~.............>uu> ~vv?~..........~?>~?vvzzzzzrrzr^^...~.<br></br>
-uuuuv+..  .``~~~.``............}ur~.>www?^`........+?+^??vzzwzwrzzv^|..`~.<br></br>
-uuuu}?..  .`-|^|`.............?yr~..|?wz}|........~>>+???wrvzzwwzz>^-..--.<br></br>
-uuuuw?..   .`|~>~..`.......~>wu?`...`>}ur~.......->>>>??}v>}zzzzzv^^...`-.<br></br>
-uuuuw+...   ``~|?~.-.```.`-vuz>......-?zz^......-+>>>>>vv^?wwwzzw>^- ..`-.<br></br>
-uuuux^..    ..`~^?~-``-~-`~>+-`.......^vur~...`-^>>>>>?v>?rrrzzz?^|...`--.<br></br>
-uuuuy>        .`~|?+~``|^~-~~-```......>zu?...-^>>>>?vv?vrw}zzz?^^...`~~-.<br></br>
-uuuuyr.        ..-~??>~-+?+~-~||~~----`+zuv`~^>>>>?vvvvrww}wzz?^|...`~~~~.<br></br>
-uuyuuy>         ..`-|?r?|+??>^^+>+^^^|||ruv^^++>?vvv}rwwr}wz}>^|...`||||^.<br></br>
-uxzyuv?.         .``--^v}v??vv????????>>}}?~+>?vv??}zw}rwzr?^^|``-~^^^^^^.<br></br>
-u}>r?--``         ..`-~~+?}}}}}}vv??>++>v?+~+++>?}wzzwwz}?^^|-`--|+>++^++.<br></br>
-uu}?vzw~``^-.....    .-~|^+>?v}rwrr}vv????^^??}rrrwzwv?>^^^|----|++++>>>>`<br></br>
-uuuuuyu+`-}z?|~-~~~`....`-~||^>??v}}v}}vrw??vvv????>+^^+^|~~~~|+>>>?>>?>v~<br></br>
-uuuuux?+?>+vxzv+~-|^++^~-``--~~|||||^||^+^~||^^^^^^^>>^|~~~~^?}}}rrrv????-<br></br>
-uuuuz?>wyu}>>ryxr?|-~|^+++^^||^^++++^|||||^^+>>>>>+^||||||+?v}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rr}wwrrrrv??+-.>}|      .......`|++++++++++++++++++v}vv>`   ..```````~^+?`<br></br>
-yyxxyyyyyu?^+?vv-  . .......-^>???>>>>>>>>>>>>>>>>>?rzzzw?-  ..`---~||^>v~<br></br>
-uyyuxuuyyyyuux>   .......`|+>+^||||^+>>>>>>>>>>>>>>>??rzzzz?`  .`--~^^||>~<br></br>
-uyuyuuxzxxuuw|   .......`|~```~^>>>>>>>>>>>>>>>>>>>????vwzzxw^   .-~>?^|v|<br></br>
-uyuuuyzwzxur`   ...........~+>?????>>>>>>>>>>>>>>>>>vr}v?vwzzz>.  .^vv?}z|<br></br>
-uyuuuuzzuy}.   .........`|>????>+^~-````-|+>??>>>?>>?wrzwvv}zzx?.  `??vrz~<br></br>
-uyuuyxxrur.   .........->???>^-`.....`......-^>>>>>>>?rrwzzrrzzxv.  -?vvv`<br></br>
-uyuuywzwr`   ...`......+?>^-..`|?v}rv????>|`..`+?>>>?>>vwzzzzzzzxv.  |?v?`<br></br>
-uyuyurzw`   ...-~.....`>^`..`||>www}>`--~^>??+`.~>?>>??>?wzzzzzzzx?  .+??`<br></br>
-uuuuxux`   ...`>`.....-`....````-~-`````````~>?>|-+?>>??>?wzwzzzzzx^  `>?`<br></br>
-uuuuuy+    ...+>...........`..`````````````````-^>^>????>^?~+zzzzzzr. .|+`<br></br>
-uuuuu}    ...~?^..........>? ..`````````>?>`+^|^-`-|^>>>+v|`^xzrzzzx^  -+.<br></br>
-uuuuu~   .`.`>?~..........>+ .`````````-v>^~~?+^^-````--~~---rz}zzzxv  `+.<br></br>
-uuuur    .~.~??~............```~~``````-?`-~>?>^>-`````.....-rxrwzzzw. .^.<br></br>
-uuuu?    .|.^??~.........`..``>xxv-`````~```~~`~-`````.`....>xzr}zzzz- .|.<br></br>
-uuuu+    `>.^??~.........-`...>uuy+.````````````....+>.|+...?xz}?zzzx~ .|.<br></br>
-uuuu^    `?`^>?^...............ruuv..````````......`>>`^??}rwzz}?zzzz~ .`.<br></br>
-uuuu^    .?~^?>+...............>uuv.|?>^...........-?>`>??zxzzz}vzzzz` .~.<br></br>
-uuuu^.   .+>>v>>`..............?uu>`wwwz>..........+?^~?>vzzzzz}zzzz}. `~.<br></br>
-uuuu+`    -??}>?^............`vyx?..vzwwv.........->>^>>?r}zzzrwzzzx>  `-.<br></br>
-uuuuv-    .^>r?>>```.``...`^?zuv|...-xux}........`>>>>>?v?}zwzwzzzzw` .`-.<br></br>
-uuuy}.     .?+}?>+`|..|-..^yu}~......|xur`.......^?>>>??>?w}}rzzzzx>  .`-.<br></br>
-uuuuz.      -|+}?>^^|..+^``?>`........vuuv`.....^>>>>>>>vwrv?zzzzxv. .`--.<br></br>
-uuuuu~       ``^v?>+?|.`>>^`...........ruu>...`+?>>>>>?rzr}?wzzzxr`  `~~-.<br></br>
-uuuuyv        .-~??>>v+`->?+~-``.......>yuv.`^>?>>>>>vzzrr?vzzzxr-  .~~~~.<br></br>
-uuyuuy|        .++>???}?|~+>>+^++^|~---|zuv+>>++>>>>vww}v?}zzzx}`  .~|||^.<br></br>
-uxzyu}?.        .^v?v??}v>^^+?>>????>>>+ryw^||+>>>?}zr??vwzzzz?.  .^^^^^^.<br></br>
-u}>r?--`.         `?}rr}zwv?>>??>>>>>>+>wv>|>>??v}wzzvrzzzxzv~   .+>++^++.<br></br>
-uu}?vwz^``-.     .  `?wxzzzw}v?vvv?v???v>?^^vvv}vv}wzzzzxzv~.   `^>++>>>>`<br></br>
-uuuuyyu?--vw^.        `>}zxxzzzwrrrv???^vv|~++>>vrzzzxzr>-    `^>>>?>>?>v~<br></br>
-uuuuuu?^??+?zw>`        .-+vrwzxxzwr}?>???+??}wzxxzwv>~.    `+}}}rrrv????-<br></br>
-uuuux?+}yu}>>}yxv^.         .`|>?v}wwwwrrrrwwww}?>~`    ..->v}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rr}wwrrrrv??+-.>}|      .......`|++++++++++++++++++v}vv>`   ..```````~^+?`<br></br>
-yyxxyyyyyu?^+?vv-  . .......-^>???>>>>>>>>>>>>>>>>>?rzzzw?-  ..`---~||^>v~<br></br>
-uyyuxuuyyyyuux>   .......`|+>+^||||^+>>>>>>>>>>>>>>>??rzzzz?`  .`--~^^||>~<br></br>
-uyuyuuxzxuuuw|   .......`|~```~^>>>>>>>>>>>>>>>>>>>????vwzzxw^   .-~>?^|v|<br></br>
-uyuuuyzwzxur`   ...........~+>?????>>>>>>>>>>>>>>>>>vr}v?vwzzz>.  .^vv?}z|<br></br>
-uyuuuuzzuy}.   .........`|>????>+^~-````-|+>??>>>?>>?wrzwvv}zzx?.  `??vrz~<br></br>
-uyuuyxxryr.   .........->???>^-`.....`......-^>>>>>>>?rrwzzrrzzxv.  -?vvv`<br></br>
-uyuuywzwr`   ...`......+?>^-..`|?v}rv????>|`..`+?>>>?>>vwzzzzzzzxv.  |?v?`<br></br>
-uyuyurzw`   ...-~.....`>^`..`||>www}>`--~^>??+`.~>?>>??>?wzzzzzzzx?  .+??`<br></br>
-uuuuxux`   ...`>`.....-`....````-~-`````````~>?>|-+?>>??>?wzwzzzzzx^  `>?`<br></br>
-uuuuuy+    ...+>...........`..`````````````````-^>^>????>^?~+zzzzzzr. .|+`<br></br>
-uuuuu}    ...~?^..........>? ..`````````>?>`+^|^-`-|^>>>+v|`^xzrzzzx^  -+.<br></br>
-uuuuu~   .`.`>?~..........>+ .`````````-v>^~~?+^^`````--~~---rz}zzzxv  `+.<br></br>
-uuuur    .~.~??~............```~~``````-?`-~>?>^>-`-```.....-rxrwzzzw. .^.<br></br>
-uuuu?    .|.^??~.........`..``>xxv-`````-```~~`~-`````.`....>xzr}zzzz- .|.<br></br>
-uuuu+    `>.^??~.........-`...>uuy+.````````````....+>.|+...?xz}?zzzx~ .|.<br></br>
-uuuu^    `?`^>?^...............ruuv..````````......`>>`^??}rwzz}?zzzz~ .`.<br></br>
-uuuu^    .?~^?>+...............>uuv.|?>^...........-?>`>??zxzzz}vzzzz` .~.<br></br>
-uuuu^.   .+>>v>>`..............?uu>`wwwz>..........+?^~?>vzzzzz}zzzz}. `~.<br></br>
-uuuu+`    -??}>?^............`vyx?..vzwwv.........->>^>>?r}zzzrwzzzx>  `-.<br></br>
-uuuuv-    .^>r?>>```.``...`^?zuv|...-xux}........`>>>>>?v?}zwzwzzzzw` .`-.<br></br>
-uuuy}.     .?+}?>+`|..|-..^yu}~......|xur`.......^?>>>??>?w}}rzzzzx>  .`-.<br></br>
-uuuuz.      -|+}?>^^|..+^``?>`........vuuv`.....^>>>>>>>vwrv?zzzzxv. .`--.<br></br>
-uuuuu~       ``^v?>+?|.`>>^`...........ruu>...`+?>>>>>?rzr}?wzzzxr`  `~~-.<br></br>
-uuuuyv        .-~??>>v+`->?+~-``.......>yuv.`^>?>>>>>vzzrr?vzzzxr-  .~~~~.<br></br>
-uuyuuy|        .++>???}?|~+>>+^++^|~---|zuv+>>++>>>>vww}v?}zzzx}`  .~|||^.<br></br>
-uxzyu}?.        .^v?v??}v>^^+?>>????>>>+ryw^||+>>>?}zr??vwzzzz?.  .^^^^^^.<br></br>
-u}>r?--`.         `?}rr}zwv?>>??>>>>>>+>wv>|>>??v}wzzvrzzzxzv~   .+>++^++.<br></br>
-uu}?vwz^``-.        `?wxzzzw}v?vvv?v???v>?^^vvv}vv}wzzzzxzv~.   `^>++>>>>`<br></br>
-uuuuyyu?--vw^.        `>}zxxzzzwrrrv???^vv|~++>>vrzzzxzr>-    `^>>>?>>?>v~<br></br>
-uuuuuu?^??+?zw>`        .-+vrwzxxzwr}?>???+??}wzxxzwv>~.    `+}}}rrrv????-<br></br>
-uuuux?+}yu}>>}yxv^.         .`|>?v}wwwwrrrrwwww}?>~`    ..->v}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rr}wwrrrrv??+-.>}|      .......`|++++++++++++++++++v}vv>`   ..```````~^+?`<br></br>
-yyxxyyyyyu?^+?vv-  . .......-^>???>>>>>>>>>>>>>>>>>?rzzzw?-  ..`---~||^>v~<br></br>
-uyyuxuuyyyyuux>    ......`|+>+^||||^+>>>>>>>>>>>>>>>??rzzzz?`  .`--~^^||>~<br></br>
-uyuyuuxzxuuuw|   .......`|~```~^>>>>>>>>>>>>>>>>>>>????vwzzxw^   .-~>?^|v|<br></br>
-uyuuuyzwzxur`   ...........~+>?????>>>>>>>>>>>>>>>>>vr}v?vwzzz>.  .^vv?}z|<br></br>
-uyuuuuzzuy}.   .........`|>????>+^~-````-|+>??>>>?>>?wrzwvv}zzx?.  `??vrz~<br></br>
-uyuuyxxryr.   .........->???>^-`.....`......-^>>>>>>>?rrwzzrrzzxv.  -?vvv`<br></br>
-uyuuywzrr`   ...`......+?>^-..`|?v}rv????>|`..`+?>>>?>>vwzzzzzzzxv.  |?v?`<br></br>
-uyuyurzw`   ...-~.....`>^`..`||>www}>`--~^>??+`.~>?>>??>?wzzzzzzzx?  .+??`<br></br>
-uuuuxux`   ...`>`.....-`....````-~-`````````~>?>|-+?>>??>?wzwzzzzzx^  `>?`<br></br>
-uuuuuy+    ...+>...........`..`````````````````-^>^>????>^?~+zzzzzzr. .|+`<br></br>
-uuuuu}    ...~?^..........>? ..`````````>?>`+^|^-`-|^>>>+v|`^xzrzzzx^  -+.<br></br>
-uuuuu~   .`.`>?~..........>+ .`````````-v>^~~?+^^-````--~~---rz}zzzxv  `+.<br></br>
-uuuur    .~.~??~............```~~``````-?`-~>?>^>-`-```.....-rxrwzzzw. .^.<br></br>
-uuuu?    .|.^??~.........`..``>xxv-`````-```~~`~-`````.`....>xzr}zzzz- .|.<br></br>
-uuuu+    `>.^??~.........-`...>uuy+`````````````....+>.|+...?xz}?zzzx~ .|.<br></br>
-uuuu^    `?`^>?^...............ruuv..````````......`>>`^??}rwzz}?zzzz~ .`.<br></br>
-uuuu^    .?~^?>+...............>uuv.|?>^...........-?>`>??zxzzz}vzzzz` .~.<br></br>
-uuuu^.   .+>>v>>`..............?uu>`wwwz>..........+?^~>>vzzzzz}zzzz}. `~.<br></br>
-uuuu+`    -??}>?^............`vyx?..vzwwv.........->>^>>?r}zzzrwzzzx>  `-.<br></br>
-uuuuv-    .^>r?>>```.``...`^?zuv|...-xux}........`>>>>>?v?}zwzwzzzzw` .`-.<br></br>
-uuuy}.     .?+}?>+`|..|-..^yu}~......|xur`.......^?>>>??>?w}}rzzzzx>  .`-.<br></br>
-uuuuz.      -|+}??^^|..+^``?>`........vuuv`.....^>>>>>>>vwrv?zzzzxv. .`--.<br></br>
-uuuuu~       ``^v?>+?|.`>>^`...........ruu>...`+?>>>>>?rzr}?wzzzxr`  `~~-.<br></br>
-uuuuyv        .-~??>>v+`->?+~-``.......>yuv.`^>?>>>>>vzzrr?vzzzxr-  .~~~~.<br></br>
-uuyuuy|        .++>???}?~~+>>+^++^|~---|zuv+>>++>>>>vww}v?}zzzx}`  .~|||^.<br></br>
-uxzyu}?.        .^v?v??}v>^^+?>>????>>>+ryw^||+>>>?}zr??vwzzzz?.  .^^^^^^.<br></br>
-u}>r?--`.         `?}rr}zwv?>>??>>>>>>+>wv>|>>??v}wzzvrzzzxzv~   .+>++^++.<br></br>
-uu}?vwz^``-.        `?wxzzzw}v?vvv?v???v>?^^vvv}vv}wzzzzxzv~.   `^>++>>>>`<br></br>
-uuuuyyu?--vw^.        `>}zxxzzzwrrrv???^vv|~++>>vrzzzxzr>-    `^>>>?>>?>v~<br></br>
-uuuuuu?^??+?zw>`        .-+vrwzxxzwr}?>???+??}wzxxzwv>~.    `+}}}rrrv????-<br></br>
-uuuux?+}yu}>>}yxv^.         .`|>?v}wwrwrrrrwwww}?>~`    ..->v}r}vvv???vvv-<br></br>


            </div>,

            <div>

`rvrrrrrrr++>>?r+.     ......`|+++++++++++++++++++++>vvvv>`    .``````~^+?`<br></br>
-yuzxuyyyyyxxur~  .  ......~+???>>++>>>>>>>>>>>>>>>>>?}rzxw?`   .`--~||^>v~<br></br>
-uyyuxzxxuuux>.    ......`+>+^~-~|+>>>>>>>>>>>>>>>>>?>>??}wxw>.   .-~^^||>~<br></br>
-uyuyxzuxxuw-     ......`~-`.-|+>>>>>>>>>>>>>>>>>>>>>v}?>>?vzxr~   .`??^|v|<br></br>
-uyuuwuwuur`    ..........`|>>?>>??>>>>>>>>>>>>>>>>>>?rzrv?>?zxz>   .?v?}z|<br></br>
-uyuuzury}.    .........-+>????>+|-``.``-|^>>??>>>?>>>?vwzw}?vzzx?.  .>vrz~<br></br>
-uyuuzzzv.    .........~????>^-`............`~+?>>>>>>>?vrzzzv}zzx?.  .?vv`<br></br>
-uyuuzxr`    ...`.....`>?>^-...-^>??????>^~`...~>?>>>>>>>?}zzzwzzzx?   -v?`<br></br>
-uyuyxw^    ...~|.....^>|`..`|++rzzzw>~|^>???+`..|>?>>>?>>?}zzzzzzzx^   ^?`<br></br>
-uuuuu?    ...`>`....`|`...``.``~|^|-```````~+??^``+?>>??>>vzzxzzzzzw`  .>`<br></br>
-uuuuz.    ...>>...............````````````````-^>+|+?>>>?>^}v?zzzzzxv.  |`<br></br>
-uuuy? .  ...|?^..........`v` .``````````~+>-~^^-~~~|+>>?>^??`-wrzzzzx|  ``<br></br>
-uuuu-    ..`>?|..........~}| .``````````>??~~>?>+-```-~||^>~~~rwwzzzxv  ..<br></br>
-uuuw.   ...|??~............`````-```````?|-^~+?^|>``````......?z}zzzzw.   <br></br>
-uuyv    ...>??-............```+wzv-`````^-``|+^|^~``````......rzvzzzzz~   <br></br>
-uuy?    `.-???-.........~-...`vuuu^``````````````...-~.^>`.. -zw?wzzzx|   <br></br>
-uuy>    ~.~?>?|...............`ruu?.``````````......>>.+?>~^+?zr?zzzzx~   <br></br>
-uuy+    ~`^?>?+................?uu}.`||-...........`>>`>???zxzz}vzzzzz-   <br></br>
-uuy+    ~~+?>>>`...............+uyv`rwwr|..........~?+~>>>vzzzz}wzzzzr. . <br></br>
-uuy>    `+?v>>?|...`.........`vuu}~|zwww?..........+?++>>?rzzzz}zzzzx?  . <br></br>
-uuyv    .+v}>>>>`..`^`....~+?zuz?..`rxzzv.........~>>>>>>vwzzzwwzzzzz~  ..<br></br>
-uuuz.    `}w>>>?^...~>`...zyz?~`...`?ruuv........->>>>>>?vrwwwzzzzzxv.  `.<br></br>
-uuuy^     >x?|>>?^...+>~..>?|.......`~zux>......->>>>>>??}w}v}zzzzzz~  .-.<br></br>
-uuuyr     .?x+~??v|..`>?+-............?uuu+....|>>>>>>>>}zrv?zzzzzx>  .`-.<br></br>
-uuuuy+   . .?w`|?v}-..`>?>^~`.........`zuu}..-+?>>>>>>?}wwr?rzzzzx?   `~~.<br></br>
-uuyuyx-      >v-|?rv~`.->>|^>+~``......>uur~+??>^+>>>>v}rr?vzzzzx?.  `||^.<br></br>
-uuzuu}>.      ~v>~?w}+`.`^>^||++++^||^+>zuv++^|^>>>>??}}??vzzzzz>   `^^^^.<br></br>
-uw+}?--`.      .?v>vzw?|`.`~+>>+>>>?>>+|}yv~^+>>>>?vrzv?vwzzzxr|   -++^++.<br></br>
-uu}?vwz^`.       ~vvvwzw}?^```|>>>>>>>>?zv>|>???vrwzzw}zzzzxw>.  .~++>>>>`<br></br>
-uuuuyyu?--?~      .|vwxzzzzrv?+^+>>>>>?v+?^|}rrrwzzzzzzzzzw>`   `+??>>?>v~<br></br>
-uuuuuu?^>?>?>-       `>}zzzzzzzw}v?>+?>^?v|-+>?vwzzzzzzwv^.   .+}rrrv????-<br></br>
-uuuuu?^vuu}>>vv~       .->vwwwwww}v??>^+?>|^?vrzwwwwwv+`    .^v}vvv???vvv-<br></br>


            </div>,

            <div>

`rvrrrrrrr++>>?}+.     ......`|+++++++++++++++++++++>vvvv>`    .``````~^+?`<br></br>
-yuzxuyyyyyxxur|     ......~+???>>++>>>>>>>>>>>>>>>>>?}rzxw?`   .`--~||^>v~<br></br>
-uyyuxzxxuuux>.    ......`+>+^~-~|+>>>>>>>>>>>>>>>>>?>>??}wxw>.   .-~^^||>~<br></br>
-uyuyxzuxxuw-     ......`~-`.-|+>>>>>>>>>>>>>>>>>>>>>v}?>>?vzxr~   .`??^|v|<br></br>
-uyuuwuwuur`    ..........`|>>?>>??>>>>>>>>>>>>>>>>>>?rzrv?>?zxz>   .?v?}z|<br></br>
-uyuuzury}.    .........-+>????>+|-``.``-|^>>??>>>?>>>?vwzw}?vzzx?.  .>vrz~<br></br>
-uyyuzzwv.    .........~???>>^-`............`~+?>>>>>>>?vrzzz}}zzx?.  .?vv`<br></br>
-uyyuwx}`    ...`.....`>?>^-...-^>??????>^~`...~>?>>>>>>>?}zzzwzzzx?   -v?`<br></br>
-uyuyzw^    ...~|.....^>|`..`|++rzzzw>~|^>???+`..|>?>>>?>>?}zzzzzzzx^   ^?`<br></br>
-uuuuu?    ...`>`....`|`...``.``~|^|-```````~+??^``+?>>??>>vzzxzzzzzw`  .>`<br></br>
-uuuuz.    ...>>...............````````````````-^>+|+?>>>?>^}v?zzzzzxv   |`<br></br>
-uuuy? .  ...|?^..........`v` .``````````~+>-~^^-~~~|+>>?>^??`-wrzzzzx|  ``<br></br>
-uuuu-    ..`>?|..........~}| .``````````>??~~>?>+-```-~||^>~~~rwwzzzxv  ..<br></br>
-uuuw.   ...|??~............`````-```````?|-^~+?^|>``````......?z}zzzzw.   <br></br>
-uuyv    ...>??-............```+wzv-`````^-``|+^|^~``````......rzvzzzzz~   <br></br>
-uuy?    `.-???-.........~-...`vuuy^``````````````...-~.^>`.. -zw?wzzzx|   <br></br>
-uuy>    ~.~?>?|...............`ruu?.````-`````......>>.+?>~^+?zr?zzzzx~   <br></br>
-uuy+    ~`^?>?+................?uu}.`||-...........`>>`>???zxzz}vzzzzz-   <br></br>
-uuy+    -~+?>>>`...............+uyv`}wwr|..........~?+~>>>vzzzz}rzzzzr. . <br></br>
-uuy>    `+?v>>?^...`.........`vuu}~|zwww?..........+?++>>?wzzzz}zzzzx?  . <br></br>
-uuyv    .+v}>>>>`..`^`....~+>zuz?..`rxzzv.........~>>>>>>vwzzzwwzzzzz~  ..<br></br>
-uuuz.    `}w>>>?^...~>`...zyz?~`...`?ruuv........->>>>>>?vrwwwzzzzzxv.  `.<br></br>
-uuuy^     >x?|>>?^...+>~..>?|.......`~zux>......->>>>>>??}w}?}zzzzzz~  .-.<br></br>
-uuuyr     .?x+~??v|..`>?+-............?uuu+....|>>>>>>>>}zrv?zzzzzx>  .`-.<br></br>
-uuuuy+     .?w`|?v}-..`>?>^~`.........`zuu}..-+?>>>>>>?}wwr?rzzzzx?   `~~.<br></br>
-uuyuyx-      >v-|?rv~`.-?>|^>+~``......>uur~+??>^+>>>>v}rr?vzzzzx?.  `||^.<br></br>
-uuzuu}>.      ~v>~?w}+`.`^>^|^++++^||^+>zuv++^|^>>>>??}}??vzzzzz>   `^^^^.<br></br>
-uw+}?--`.      .?v>vzw?|`.`~+>>+>>>?>>+|}yv~^+>>>>?vrzv?vwzzzxr|   -++^++.<br></br>
-uu}?vwz^``       ~vvvwzw}?^```|>>>>>>>>?zv>|>???vrzzzw}zzzzxw>.  .~++>>>>`<br></br>
-uuuuyyu?--?~      .|vwxzzzzrv?+^^>>>>>?v+?^|}rrrwzzzzzzzzzw>`   `+??>>?>v~<br></br>
-uuuuuu?^>?+?>-       `>}zzzzzzzw}v?>+?>^?v|-+>?vwzzzzzzwv^.   .+}rrrv????-<br></br>
-uuuuu?+vuu}>>vv~       .->vwwwwww}v??>^+?>|^?vrzwwwwwv+`    .|v}vvv???vvv-<br></br>


            </div>,

            <div>

`rvrrrrrrr++>>?}+.     ......`|+++++++++++++++++++++>v}vv>`    .``````~^+?`<br></br>
-yuzxuyyyyyxxur|     ......~+???>>++>>>>>>>>>>>>>>>>>?}rzxw?`   .`--~||^>v~<br></br>
-uyyuxzxxuuux>.    ......`+>+^~-~|+>>>>>>>>>>>>>>>>>>>>??}wxw>.   .-~^^||>~<br></br>
-uyuyxzuxxuw-     ......`~-`.-|+>>>>>>>>>>>>>>>>>>>>>v}?>>?vzxr~   .`??^|v|<br></br>
-uyuuwuwuu}`    ..........`|>>?>>??>>>>>>>>>>>>>>>>>>?rzrv?>?zxz>   .?v?}z|<br></br>
-uyyuzury}.    .........-+>????>+|-``.``-|^>>??>>>?>>>?vwzw}?vzzx?.  .>vrz~<br></br>
-uyyuzzwv.    .........~???>>^-`............`~+?>>>>>>>?vrzzz}}zzx?.  .?vv`<br></br>
-uyyuwx}`    ...`.....`>?>^-...-^>?????>>^~`...~>?>>>>>>>?}zzzwzzzx?   -v?`<br></br>
-uyuyxw^    ...~|.....^>|`..`|++rzzzw>~|^>???+`..|>?>>>?>>?}zzzzzzzx^   ^?`<br></br>
-uuuuu?    ...`>`....`|`...``.``~|^|-```````~+??^``+?>>??>>vzzzzzzzzw`  .>`<br></br>
-uuuuz.    ...>>...............````````````````-^>+|+?>>>?>^}v?zzzzzxv   |`<br></br>
-uuuy? .  ...|?^..........`v` .``````````~+>-~^^-~~~|+>>?>^??`-wrzzzzx|  ``<br></br>
-uuuu-    ..`>?|..........~r| .``````````>??~~+?>+-```-~||^>~~~rwwzzzxv  ..<br></br>
-uuuw.   ...|??~............`````-```````?|-^~+?^|>``````......?z}zzzzw.   <br></br>
-uuyv    ...>??-............```+wzv-`````^-``|+^|^~``````......rzvzzzzz~   <br></br>
-uuy?    `.-???-.........~-...`vuuy^``````````````...-~.^>`.. -zw?wzzzx|   <br></br>
-uuy>    ~.~?>?|...............`ruu?.``````````......>>.+?>~^+?zr?zzzzx~   <br></br>
-uuy+    ~`^?>?+................?uu}.`||-...........`>>`>???zxzz}?zzzzz-   <br></br>
-uuy+    -~+?>>>`...............+uyv`}wwr|..........~?+~>>>vzzzz}rzzzzr.   <br></br>
-uuy>    `+?v>>?|...`.........`vuu}~|zwww?..........+?^+>>?wzzzz}zzzzx?  . <br></br>
-uuyv    .+v}>>>>`..`^`....~+>zuz?..`rxzzv.........~?>>>>>vwzzzwwzzzzz~  ..<br></br>
-uuuz.    `}w>>>?^...~>`...zyz?~`...`?ruuv........->>>>>>?vrwwwzzzzzxv.  `.<br></br>
-uuuy^     >x?|>>?^...+>~..>?|.......`~zux>......->>>>>>??}w}?}zzzzzz~  .-.<br></br>
-uuuyr     .?x+~??v|..`>?+-............?uuu+....|>>>>>>>>}zrv?zzzzzx>  .`-.<br></br>
-uuuuy+     .?w`|?v}-..`>?>^~`.........`zuu}..-+?>>>>>>?}wwr?rzzzzx?   `~~.<br></br>
-uuyuyx-      >v-|?rv~`.-?>|^>+~``......>uur~+??>^+>>>>v}rr?vzzzzx?.  `||^.<br></br>
-uuzuu}>.      ~v>~?w}+`.`^>^|^++++^||^+>zuv++^|^>>>>??}}??vzzzzz>   `^^^^.<br></br>
-uw+}?--`.      .?v>vzw?|`..~+>>+>>>?>>+|}yv~^+>>>>?vrzv?vwzzzxr|   -++^++.<br></br>
-uu}?vwz^``       ~vvvwzw}?^```|>>>>>>>>?zv>|>???vrzzzw}zzzzxw>.  .~++>>>>`<br></br>
-uuuuyyu?--?~      .|vwxzzzzrv?+^^>>>>>?v+?^|}rrrwzzzzzzzzzw>`   `+??>>?>v~<br></br>
-uuuuuu?^>?+?>-       `>}zzzzzzzw}v?>+?>^?v^-+>?vwzzzzzzwv^.   .+}rrrv????-<br></br>
-uuuuu?+vuu}>>vv~       .->vwwwwww}v??>^+?>|^?vrzwwwwwv+`    .|v}vvv???vvv-<br></br>


            </div>,

            <div>

`v}rrrrrrrrwv`        .....`|+++++++++++++++++++++++++>vvv+.     .````~^+?`<br></br>
-yuuuxxuuuyv`      .......|>?>>+^^+>>>>>>>>>>>>>>>>>>>>>?}zr>.    .`~||^>v~<br></br>
-uyyxzwxxx+       ......`^+|---|++>>>>>>>>>>>>>>>>>>>>?>+>?vzr^.    `^^||>~<br></br>
-uyuxzxuz|      ........`.`-^>>?>>>>>>>>>>>>>>>>>>>>>>?wv?>+>}x}~    -?^|v|<br></br>
-uyuxwux~     ..........`|>??>??>>>>>>>?>>>>>>>>>>>>>>>wxwv?>>vxz>    ~v}z|<br></br>
-uyuuwz|     ..........|>?>?>>^~``..``-~|^+>>?>>>>>>?>>>vzzzr?>}zx?.   |rz~<br></br>
-uyuxw|     ..........^?>?>^-`.............``|>?>>>>>??>+?wzzz}?zzx?    +v`<br></br>
-uyyx?     ....`.....-??>|`...``-|^^++^~-`....`|>?>>>>???>>}zzzzzzzx>   .?-<br></br>
-uyyu`    ....~~.....+>|...`~||?zzxzw?>?????+-..`^>?>>>??>>>rzzzzzzzz~ . ``<br></br>
-uuyv     ...->`....-^`...`....-^>>+~`````-|+??+`.-+?>>>>>>>?zzzzzzzz}.    <br></br>
-uuy|    ...`>+.....`..... ...````````````````-|>>|-^>>>>>>>>vw}wzzzzx>    <br></br>
-uuz.   ...`>?~..........`vv ..```````````|^~`~~``~^^>??>??^+v|`vzzzzzw`   <br></br>
-uyv    ...^?>`..........-rr. ```````````~v?>->?+?+``-~^^+|>v|`-vwzzzzx>   <br></br>
-uy>    ..`>>>.............``````````````+?|^~|?+>>|``````````-`}rzzzzzr.  <br></br>
-uy^   .`.^?>>..............```|vr?-`````+^``~??++?^``````......}}wzzzzz`  <br></br>
-uy|   .-`>>>>`.........~`...``vuuy+``````````--`--``...`-`....`wv}zzzzz-  <br></br>
-uu|   .-~?>>?-.........`......|ruu?.``````--````....^~.>??|...~w?rzzzzz-  <br></br>
-uy|   .~^?>>?^.................vuur..```````.......`>^`>>>?+vrw}?zzzzzz`  <br></br>
-uy>   .~+?>>>>`................^xur`>vv?`..........-?^-?>>>>wzzvvzzzzz}.  <br></br>
-uyv    ~>?>>>?^..`~...........>wux?>zwwz+..........|?^|?>>>?zzz}wzzzzx?   <br></br>
-uuz.   -?v>>>>>`..~^.....`+>>wyur^.?zzww>.........`>>+>>>>>rzzzrzzzzzz-   <br></br>
-uuy^   .?}>>>>>+...>+`...?yuw?^-..v}ruux?.........+>>>>>>>?zwrzzzzzzxv    <br></br>
-uuur.   |w>|>>>?^..-?>~..|v?`.....||`vuuw`.......^?>>>>>>>rzrwzzzzzzz`    <br></br>
-uuuy+    v}^->??>|..|??^`............~zuuw-....`+?>>>>>>>vwrv}zzzzzx+     <br></br>
-uuuux`   .}v-~?}?>-..+??+|`...........?yuyv...|>?>>>>>>>vrr}>rzzzzxv    `.<br></br>
-uuyuyw.   `}?.-vw?>-.`>?+|+|`.........`xuuv.~>??>++>>>>?vwz??zzzzx}.   .|.<br></br>
-uuzuyr^.   .v?.`vz?>`.`>?^``|||-`......?yuv+?>+^^>>>>>>?rr??rzzzxr`    -^.<br></br>
-uz>vv~-`    .?}|-rw?>`..~++~``-|+^^^^^+>zuv|~~|+>>>>>??rv?vwzzzx}`    `++.<br></br>
-uuw?vwz>..    |}?~vzrv>|`.`^>>++^++>?>>^wy}+>>>>>>??}w}vvwzzzzxv.    ->>>`<br></br>
-uuuuyyyv--`    .>v>?wzwv?>^-`~^>>?>>>>>}z?>|????v}wzw}}zzzzzxr^    .~>?>v~<br></br>
-uuuuuuv^>?+~.    .>vrzzzzr}vv>+||^>>>>?v^?+|vvvrrrrrwzzzzzzr>.    .>v????-<br></br>
-uuuuuv+?xur?^`     .+}zzzzzzwwr}v??>??+|?}+~+|^>?v}wwwwwwr>.     `????vvv-<br></br>


            </div>,

            <div>

`v}rrrrrrrrwv`        .....`|+++++++++++++++++++++++++>vvv+.     .````~^+?`<br></br>
-yuuuxxuuuyv`      .......|>?>>+^^+>>>>>>>>>>>>>?>>>>>>>?}zr>.    .`~||^>v~<br></br>
-uyyxzwxxx+       ......`^+|-`-|++>>>>>>>>>>>>>>>>>>>>?>+>?vzr^.    `^^||>~<br></br>
-uyuxzxuz|      ........`.`-^>>?>>>>>>>>>>>>>>>>>>>>>>?wv?>+>}x}~    -?^|v|<br></br>
-uyuxwux~     ..........`|>??>??>>>>>>>?>>>>>>>>>>>>>>>wxwv?>>vxz>    ~v}z|<br></br>
-uyuuwz|     ..........|>?>?>>^~``..``-~|^+>>?>>>>>>?>>>vzzzr?>}zx?.   |rz~<br></br>
-uyuxw|     ..........^?>?>^-`.............``|>?>>>>>??>+?wzzz}?zzx?    +v`<br></br>
-uyyx?     ....`.....-??>|`...``-|^^++^~-`....`|>?>>>>???>>}zzzzzzzx>   .?-<br></br>
-uyyu`    ....~~.....+>|...`~||?zzxzw?>?????+-..`^>?>>>??>>>rzzzzzzzz~ . ``<br></br>
-uuyv     ...->`....-^`...`....-^>>+~`````-|+??+-.->?>>>>>>>?zzzzzzzz}.    <br></br>
-uuy|    ...`>+.....`..... ...````````````````-|>>|-^>>>>>>>>vw}wzzzzx>    <br></br>
-uuz.   ...`>?~..........`vv ..```````````|^~`~~``~^^>??>??^+v|`vzzzzzw`   <br></br>
-uyv    ...^?>`..........-rr. ```````````~v?>->?+?+``-~^^+|>v|`-vwzzzzx>   <br></br>
-uy>    ..`>?>.............``````````````+?~^~|?+>>|``````````-`}rzzzzzr.  <br></br>
-uy^   .`.^?>>..............```|vr?-`````+^``~??++?^``````......}}wzzzzz`  <br></br>
-uy|   .-`>>>>`.........~`...``vuuy+``````````--`--``...`-`....`wv}zzzzz-  <br></br>
-uu|   .-~?>>?-.........`......|ruu?.````````````....^~.>??|...~w?rzzzzz-  <br></br>
-uy|   .~^?>>?^.................vuur..```````.......`>^`>>>?+vrw}?zzzzzz`  <br></br>
-uy>   .~+?>>>>`................^xur`>vv?`..........-?^-?>>>>wzzvvzzzzz}.  <br></br>
-uyv    ~>?>>>?^..`~...........>wux?>zwwz+..........^?^|?>>>?zzz}rzzzzx? . <br></br>
-uuz.   -?v>>>>>`..~^.....`+>>wyur^.?zzww>.........`>>+>>>>>rzzzrzzzzzz-   <br></br>
-uuy^   .?}>>>>>+...>+`...?yuw?^-..v}ruux?.........+>>>>>>>?zwrzzzzzzxv    <br></br>
-uuur.   |w>|>>>?^..-?>~..|v?`.....||`vuuw`.......^?>>>>>>>rzrwzzzzzzz`    <br></br>
-uuuy+    v}^->??>|..|??^`............~zuuw-....`+?>>>>>>>vwrv}zzzzzx+     <br></br>
-uuuux`   .}v-~?}?>-..+??+|`...........?yuyv...|>?>>>>>>>vrr}>wzzzzxv    `.<br></br>
-uuyuyw.   `}?.-vw?>-.`>?+|+|`.........`xuuv.~>??>^+>>>>?vwz??zzzzx}.   .|.<br></br>
-uuzuyr^.   .v?.`vz?>`.`>?^``|||-`......?yuv+?>+^^>>>>>>?rr??rzzzxr`    -^.<br></br>
-uz>vv~-`    .?}|-rw?>`..~++~``-|+^^^^^+>zuv|~~|+>>>>>??rv?vwzzzx}`    `++.<br></br>
-uuw?vwz>..    |}?~vzrv>|`.`^>>++^++>?>>^wy}+>>>>>>??}w}vvwzzzzxv.    ->>>`<br></br>
-uuuuyyyv--`    .>v>?wzwv?>^-`~^>>?>>>>>}z?>|????v}wzw}}zzzzzxr^    .~>?>v~<br></br>
-uuuuuuv^>?+~.    .>vrzzzzr}}v>+||^>>>>?v^?+|vvvrrrrrwzzzzzzr>.    .>v????-<br></br>
-uuuuuv+?xur?^`     .+}zzzzzzwwr}v??>??+|?v+~+|^>?v}wwwwwwr>.     `????vvv-<br></br>


            </div>,

            <div>

`v}rrrrrrrrwv`        .....`|+++++++++++++++++++++++++>vvv+.     .````~^+?`<br></br>
-yuuuxxuuuyv` .    .......|>?>>+^^+>>>>>>>>>>>>>?>>>>>>>?}zr>.    .`~||^>v~<br></br>
-uyyxzwxxx+       ......`^+|-`-|++>>>>>>>>>>>>>>>>>>>>?>>>?vzr^.    `^^||>~<br></br>
-uyuxzxux|      ........`.`-^>>?>>>>>>>>>>>>>>>>>>>>>>?wv?>+>}x}~    -?^|v|<br></br>
-uyuxwux~     ..........`|>??>??>>>>>>???>>>>>>>>>>>>>>wxwv?>>vxz>    ~v}z|<br></br>
-uyuuwz|     ..........|>?>?>>^~``..``-~|^+>>?>>>>>>>>>>vzzzr?>}zx?.   |rz~<br></br>
-uyuxw|     ..........^?>?>^-`.............``|>?>>>>>??>+?wzzz}?zzx?    +v`<br></br>
-uyyx?     ....`.....-??>|`...``-|^^++^~-`....`|>?>>>>???>>}zzzzzzzx>   .?-<br></br>
-uyyu`    ....~~.....+>|...`~||?zzxzw?>?????+-..`^??>>>??>>>rzzzzzzzz~ . ``<br></br>
-uuyv     ...->`....-^`...`....-^>>+~`````-|>??+-.-+?>>>>>>>?zzzzzzzz}.    <br></br>
-uuy|    ...`>+.....`..... ...````````````````-|>>|-^>>>>>>>>vw}wzzzzx>    <br></br>
-uuz.    ..`>?~..........`vv ..```````````|^-`~-``~^^>??>??^+v|`vzzzzzw`   <br></br>
-uyv    ...^?>`..........-rr. ```````````~v?>->?+?+``-~^^+|>v|`-vwzzzzx>   <br></br>
-uy>    ..`>>>.............``````````````+?|^~|?+>>|``````````-`}rzzzzzr.  <br></br>
-uy^   .`.^?>>..............```|vr?-`````+^``~??++?^``````......}}wzzzzz`  <br></br>
-uy|   .-`>>>>`.........~`...``vuuy+``````````--`--``...`-`....`wv}zzzzz-  <br></br>
-uu~   .-~?>>?-.........`......|ruu?.````````````....^~.>??|...~w?rzzzzz-  <br></br>
-uy|   .~^?>>?^.................vuur..```````.......`>^`>>>?+vrw}?zzzzzz`  <br></br>
-uy>   .~+?>>>>`................^xur`>vv?`..........-?^-?>>>>wzzvvzzzzz}.  <br></br>
-uyv    ~>?>>>?^..`~...........>wux?>zwwz+..........^?^|?>>>?zzz}rzzzzx?   <br></br>
-uuz.   -?v>>>>>`..~^.....`+>>wyur^.vzzww>.........`>>+>>>>>rzzzrzzzzzz-   <br></br>
-uuy^   .?}>>>>>+...>+`...?yuw?^-..v}ruux?.........+>>>>>>>?zwrzzzzzzxv    <br></br>
-uuur.   |w>|>>>?^..-?>~..|v?`.....||`vuuw`.......^?>>>>>>>rzrwzzzzzzz`    <br></br>
-uuuy+    v}^->??>|..|??^`............~zuuw-....`+?>>>>>>>vwrv}zzzzzx+     <br></br>
-uuuux`   .}v-~?}?>-..+?>+|`...........?uuyv...|>?>>>>>>>vrr}>rzzzzxv    `.<br></br>
-uuyuyw.   `}?.-vw?>-.`>?+|+|`.........`xuuv.~>??>++>>>>?vwz??zzzzx}.   .|.<br></br>
-uuzuyr^.   .v?.`vz?>`.`>?^``|||-`......?yuv+?>+^^>>>>>>?rr??rzzzxr`    -^.<br></br>
-uz>vv~-`    .?}|-rw?>`..~++~``-|+^^^^^+>zuv|~~|+>>>>>??}v?vwzzzx}`    `++.<br></br>
-uuw?vwz>..    |}?~vzrv>|`.`^>>++^++>?>>^wy}+>>>>>>??}w}v}wzzzzxv.    ->>>`<br></br>
-uuuuyyyv--`    .>v>?wzwv?>^-`~^>>?>>>>>}z?>|????v}wzw}}zzzzzxr^    .~>?>v~<br></br>
-uuuuuuv^>?+~.    .>v}zzzzr}}v>+||^>>>>?v^?+|vv}rrrrrwzzzzzzr>.    .>v????-<br></br>
-uuuuuv+?xur?^`     .+}zzzzzzwwr}v??>??+|?v+~+|^>?v}wwwwwwr>.     `????vvv-<br></br>


            </div>,

            <div>

`rr}rrrrw?.      `++++|-....`|++++++++++++++++++++++++>?vvvvvvvvv}?.       <br></br>
-yyyxzxuv.     `^??>^-....~>>?>>++>>>>>>>>>>>>>>>>>>>>>??wxzzzzzzzx}~      <br></br>
-uyyzux>     .|??>^`....`+>+|||^+>>>>>>>>>>>>>>>>>>>>>>>>>vr}wzzzzzzz^     <br></br>
-uyxru+     `+?>^`.....`|~-~^>>???????>>>>>>>>>>>>>>>>>???>????wzzzzzw-    <br></br>
-uyzz+     `??+`........-^>???>>+|||||~--~^+>>>>>>>>>>>?wrv????>rzzzzzr.   <br></br>
-uyx>     `??|........-+??>>^~`.............`~+>>>>>>>>>?wxwv?vv?wzzzzxv.  <br></br>
-uy?     `>>-........~??>^-......`````.........~>>>>>>>>>?}zzwv}v?zzzzzx?  <br></br>
-yr.    `>>-........`>>~`..`~|>v}rwr????>+~`.....^>>>>>>?>?}zzzrz}wzzzzzz^ <br></br>
-z`    `>>~...``....|~...`--`.|vr}v>``-~|+???+-...->?>>?>?>?}zzzzzwzzzzzzw`<br></br>
-|    .+>|...`^.........``  ..``--``````````~+??+`.->?>>>>>>?rzzzzzzzzzzzx|<br></br>
      ~?+....>~.......... .-....```````````````-^>+|+??+>>>>?vwwrzzzzzzzzz|<br></br>
     .>?`...^>.......... ~rw. `.````````````|~-`-``~^>>???>>|^v^-wzzzzzzzz|<br></br>
     `?>...-?|...........|rz| .````````````|vv>~>>^+|`-|^+>|^v^`-wz}wzzzzz|<br></br>
     ~?>...+?-.............````````````````>?>+-|>>>^``````-~~-~~?zv}zzzzz|<br></br>
     ~?>..|?>`.............``````>vv>``````?^`-^+?+^?~`````......+z?vzzzzz|<br></br>
     |?>``>>>-........`~~`..````~xuyx-`````^-``-|~-^~```..`.....`wz?}zzzzz|<br></br>
     |?>`~?>?~.........~-.....```?xuw.````````````.....`^+>+`.. ~xw?wzzzzz|<br></br>
     |??`^?>?^.................. +uuu-.```````.......`~|?>??>+?>?zw?zzzzzz|<br></br>
     ~??~+?>>>`.................`>zux`...............~-+?>>>>rxxzzr}zzzzzz|<br></br>
     `>v++>>>?|...........|>~-^}xuxuw>?vv?~..........+`>>>>>?wzzzzwwzzzzzz|<br></br>
     .>v?>>>>>>`..--......wyuxuuuzw}?wwwwz>.........~>->>>>>vzzwwzzwrzzzzz|<br></br>
      |?v>>>>>>^...+|.....?v?>+}}}}-`wxxzz>........`>+^?>>>?wzz}zzz}wzzzzx|<br></br>
..    `?v?>^>>>?~..`>+`.......^?vv-..?uuux>.......`>?^>>>>>}zz}wzzwwzzzzzr.<br></br>
~>     |?r?|+???>-..|?>~.......`-`...-ruuu?......->>>>>>>>vzzr}zzzzzzzzzz~ <br></br>
-x-    .+}}+`>?v>>-..+??+-............?xuuu^....~>?>>>>>+?}wr}}zwwzzzzzx>  <br></br>
-u}.    .?zv~-?r?>>~.`>>>?>|`..........vuuyv..-+?>++>>>>??vwr?}wrzzzzzx?   <br></br>
-uu?     `vxv`~vw?>>^.->+|+??+~`.......-xuur-+?>^|+>>>>>??rr??rrzzzzzx?    <br></br>
-uw>~     `wxv`|rzv?>+~~>>~-|+??+|~````.?uur+^~~+>>>>>??vr}vvrwzzzzzz>     <br></br>
-uuw?^     -rxr^>zzr??>>>??>|--|+>>>>>>>?uuv|^>>>>>>?vv}}}}wrwzzzzzw^      <br></br>
-uuuuy?     `vxz?vzzzrv?>>>??>>+^^++>>>>?xuv>>>>>?vrwrrwwzwrzzzzzx}-       <br></br>
-uuuuuu+     .+wxwrzzzzw}??>>>??>>>>>>>>?xuvvv}}wzzwrwzzzzwzzzzzw>.      ``<br></br>
-uuuuuv++.     `vzzzzzzzzzw}v?????>>>?>>vxzv>v}}rwwwwwwwwwwwwwwv-       `v~<br></br>


            </div>,

            <div>

`rr}rrwwwv.      `++++^~....`~++++++++++++++^+++++++++>?vvvvvvvvv}?.       <br></br>
-yyyxzxuv.  .  `^??>+-`...~+??>>>>>??????>>>>>>>>>??>>>??wzzzzzzzzx}~  .   <br></br>
-uyyzuu>     .|??>^`....-+>+|||^+>>>>>>>>>>>>>>>>>>>>>>>>>vrrwzzzzzzx^     <br></br>
-uyxwu+     .+?>^`.....-|~-|^>>???????>>>>>>>>>>>>>>>>>??>>>??vrzzzzzw-    <br></br>
-uyzz+     `>?+`........-^>???>>+^||||~--~^+>>>>>>>>>>>?w}??>??>}zzzzzr.   <br></br>
-uyx>     `>?|........-+???>+~`.............`~+>>>>>>>>>?wzwv?vv?wzzzzxv.  <br></br>
-uy?     `??-........~>?>^-`.....`````.........~>?>>>>>>>?}zzwv}vvzzzzzx?  <br></br>
-y}.    `??~........`>>|`..`~|>v}rwr????>+~`....`^>>>>>>>>?vzzzrzvwzzzzzx^ <br></br>
-z`    `>?~...``....|~...`~-`.^vr}v>``--|+>??+-...~>?>>>>>>?}zzzzzrzzzzzzw`<br></br>
-|    .+?|...`^.........``  ..``--``````````~+??+-.->?>>>>>>?rzzzzzzzzzzzx|<br></br>
      |?+...`>~.......... .-`...```````````````-^>>|+?>>>>>>?vwwrzzzzzzzzz|<br></br>
     .>?-...^>.......... ~}w. `.````````````||-````~^>??>>>>|^v^-wzzzzzzzz|<br></br>
     `?>...-?|...........|rz| .````````````|vv>~>+^+|`~|^+>|+v^`-wzrwzzzzz|<br></br>
     -?>`..+?-.............````````````````>?>+-|>>?+`````--~~~~~?zv}zzzzz|<br></br>
     ~?>`.|?>`.............``````>vv>``````?^`-^+?++?~`````......+z?vzzzzz|<br></br>
     |?>.`>>>-........`||`..````~zuyx-`````^~``-^~-^~``...`.....`wz?}zzzzz|<br></br>
     |?>.~?>?~.........~`....````?xuw.````````````.....`^+>+`.. |xw?wzzzzz|<br></br>
     |??`^?>?^.................. +xuu~.````````......`~|?>>>>+?>?zwvzzzzzz|<br></br>
     ~??~+?>>>`.................`>zuu`...............~-+?>>>>rxxzzw}zzzzzz|<br></br>
     `>v++>>>?|...........|>|-^}xuxxw>?vv?~..........+`>>>>>?wzzzzwwzzzzzz|<br></br>
     .>v?>?>>?>`..`-......wyuxuuuxw}?wwwwz>.........~+-?>>>>vzzwwzzwrzzzzz|<br></br>
      ^?}?>>>>?^...+|.....???>+}}}v-`wuxzz>........`>^^?>>>?wzz}zzz}zzzzzz|<br></br>
..    `>v?>^>>>>~..`?+`.......^vvv-..?uuux>.......`>>^>?>>>}zz}wzzwwzzzzzr.<br></br>
->     ^?r?|+>??>-..|?>|.......--`...-ruuu?......->>>>>>>>vzzr}zzzzzzzzzz~ <br></br>
-x`    .+}}>->?v>>-..>??>~............?xuuu^....~>?>>>>>>?}wr}}zwwzzzzzx+  <br></br>
-y}.    `?zv~->}?>>-.`>>>?>|`..........vuuuv..`+?>++>>>>??vwr?rwrzzzzzx?   <br></br>
-uy?     `vzv`~vw?>?^`->+|+??+~`.......-xuur~+>>^|+>>>>>>?rrv?rrzzzzzx?  . <br></br>
-uw>~     `rxv-|rzv>>>|~>>|-|>??>|~````.?yur+^|~+>>>>>??vr}vvrwzzzzzz>     <br></br>
-uur?^     -rxw^>zzr???>>??>|--|+>>>>>>>>uuv|+>>>>>>?vv}r}rwrwzzzzzw|      <br></br>
-uuuyy>     `vxz?vzzzrv?>>>???>++++>>>>>>xuv>>>>>?vrwrrwwzwrzzzzzx}-       <br></br>
-uuuuuu^     .+wxwrzzzzw}??>>>>>>>>>>>>>?xuvvv}}wzzzrwzzzzwzzzzzw>.      ``<br></br>
-uuuuuv++.     `vzzzzzzzzzw}??>?>>>>>>>>vxxv>v}rrwwwwwwwwwwwwwwv-       `v~<br></br>


            </div>,

            <div>

`rr}rrwwwv.      `++++^~....`~++++++++++++++^+++++++++>?vvvvvvvvv}?.       <br></br>
-yyyxzxuv.  .  `^??>+-`...~+??>>>>>??????>>>>>>>>>??>>>??wzzzzzzzzx}~  .   <br></br>
-uyyzuu>     .|??>^`....-+>+|||^+>>>>>>>>>>>>>>>>>>>>>>>>>vrrwzzzzzzx^     <br></br>
-uyxwu+     .+?>^`.....-|~-|^>>???????>>>>>>>>>>>>>>>>>??>>>??vrzzzzzw-    <br></br>
-uyzz+     `>?+`........-^>???>>+^||||~--~^+>>>>>>>>>>>?w}??>??>}zzzzzr.   <br></br>
-uyx>     `>?|........-+???>+~`.............`~+>>>>>>>>>?wzwv?vv?wzzzzxv.  <br></br>
-uy?     `??-........~>?>^-`.....`````.........~>?>>>>>>>?}zzwv}vvzzzzzx?  <br></br>
-y}.    `??~........`>>|`..`~|>v}rwr????>+~`....`^>>>>>>>>?vzzzrzvwzzzzzx^ <br></br>
-z`    `>?~...``....|~...`~-`.^vr}v>``--|+>??+-...~>?>>>>>>?}zzzzzrzzzzzzw`<br></br>
-|    .+?|...`^.........``  ..``--``````````~+??+-.->?>>>>>>?rzzzzzzzzzzzx|<br></br>
      |?+...`>~.......... .-`...```````````````-^>>|+?>>>>>>?vwwrzzzzzzzzz|<br></br>
     .>?-...^>.......... ~}w. `.````````````||-````~^>??>>>>|^v^-wzzzzzzzz|<br></br>
     `?>...-?|...........|rz| .````````````|vv>~>+^+|`~|^+>|+v^`-wzrwzzzzz|<br></br>
     -?>`..+?-.............````````````````>?>+-|>>?+``````-~~~~~?zv}zzzzz|<br></br>
     ~?>`.|?>`.............``````>vv>``````?^`-^+?++?~`````......+z?vzzzzz|<br></br>
     |?>.`>>>-........`||`..````~zuyx-`````^~``-^~-^~``...`.....`wz?}zzzzz|<br></br>
     |?>.~?>?~.........~`....````?xuw.````````-```.....`^+>+`.. |xw?wzzzzz|<br></br>
     |??`^?>?^.................. +xuu-.````````......`~|?>>>>+?>?zwvzzzzzz|<br></br>
     ~??~+?>>>`.................`>zuu`...............~-+?>>>>rxxzzw}zzzzzz|<br></br>
     `>v++>>>?|...........|>|-^}xuxxw>?vv?~..........+`>?>>>?wzzzzwwzzzzzz|<br></br>
     .>v?>?>>?>`..`-......wyuxuuuzw}?wwwwz>.........~+-?>>>>vzzwwzzwrzzzzz|<br></br>
      ^?}?>>>>?^...+|.....???>+}}}v-`wuxzz>........`>^^?>>>?wzz}zzz}zzzzzz|<br></br>
..    `>v?>^>>>>~..`?+`.......^vvv-..?uuux>.......`>>^>?>>>}zz}wzzwwzzzzzr.<br></br>
->     ^?r?|+>??>-..|?>|.......--`...-ruuu?......->>>>>>>>vzzr}zzzzzzzzzz~ <br></br>
-x`    .+}}>->?v>>-..>??>~............?xuuu^....~>?>>>>>>?}wr}}zwwzzzzzx+  <br></br>
-y}.    `?zv~->}?>>-.`>>>?>|`..........vuuuv..`+?>++>>>>??vwr?rwrzzzzzx?   <br></br>
-uy?     `vzv`~vw?>?^`->+|+??+~`.......-xuur~+>>^|+>>>>>>?rrv?rrzzzzzx?  . <br></br>
-uw>~     `rxv-|rzv>>>|~>>|-|>??>|~````.?yur+^|~+>>>>>??vr}vvrwzzzzzz>     <br></br>
-uur?^     -rxw^>zzr???>>??>|--|+>>>>>>>>uuv|+>>>>>>?vv}r}rwrwzzzzzw|      <br></br>
-uuuyy>     `vxz?vzzzrv?>>>???>++++>>>>>>xuv>>>>>?vrwrrwwzwrzzzzzx}-       <br></br>
-uuuuuu^     .+wxwrzzzzw}??>>>>>?>>>>>>>?xuvvv}}wzzzrwzzzzwzzzzzw>.      ``<br></br>
-uuuuuv++.     `vzzzzzzzzzw}??>?>>>>>>>>vxxv>v}rrwwwwwwwwwwwwwwv-       `v~<br></br>


            </div>,

            <div>

            -+++++++++>+^-...`~^++++++++++++++++^+++++>v}vv}vvvvvvvvvvv}?  <br></br>
       .  .^??>>>>??>+|`..-^>>?>>??????>>>>???>>>>>>>>>?v}}rwzzzzzzzzzzzx?.<br></br>
       . `>?>>>>?>+|`...-+>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vvvrzzzzzzzzzzx|<br></br>
        `>?>>>?>^`....`^+||^+>>???>>+^^^+>>>>>>>>>>>>>>>??>?}rvvwzzzzzzzzz|<br></br>
       `>?>>>?+`......--|>??>>+^~-`````...`~^>??>>>>>>>>vrv?>vzr?rzzzzzzzz|<br></br>
      `>>>>?>~........|>?>^~`................`-^>?>>>>>>?wrrv>vzr?wzzzzzzz|<br></br>
     .>?>>?>`........+>|-....-|^>>>+>+^~`.......`+?>>>>?>?wrwr?rx}vzzzzzzz|<br></br>
     ~?>>?+`........||`..`~|>??vwx}~~^+>???>~.....~>>>>>>>?wrzz}zzvwzzzzzz|<br></br>
    .>>>?>`...-.....`..`||`.. .++^-``````-|>?v>~...->?>+>>>?wrzzzzzzzzzzzz|<br></br>
    |?>>>`...|-.......`-. ..`````````````````-+??^`.`+>>+>>>vrwzzzzzzzzzzz|<br></br>
   .>?>>-...-+........`. .`|`  ..```````````````~+>+~~>>>>>>?wwwwwzzzzzzzz|<br></br>
   ~?>?+...`>|...........+zu` .`.`````````````-````-^^>>?>>>>>^v||wzzwwzzz|<br></br>
   +?>?|...^?`...........+wx>  .`````````````^??|~+|~|~~|+>>^|v^`-wzzwrzzz|<br></br>
  .>>>?|..`>+..............``````````````````??v+~^>?>~````-~^+|^|}zzrvzzz|<br></br>
  .>>>?|..^?+..............```````~vwr+``````v+-|~|>++>``````..`..^}zrvzzz|<br></br>
  .>>>?|.`>?+.........-~~`..``````vuuyw``````?-``|+^++|``........`}zz}?zzz|<br></br>
  .>>>>^.^?>>`........|~`...``````^zuu>.`````-`````.....`-|^`....?xzz}vzzz|<br></br>
  .>>?>+`>>>>-................```.~zuy?.````````......~>>???>~``.vxzz}wzzz|<br></br>
  .>>v?>~>>>?|............^v?-.``-^zuyv...............^>>>>>>>>>}zzzzwzzzz|<br></br>
  .>>v?>^>>>>+...........`ruyuzzxuuxxu+ ..............->>>>>>>>?zzwzzzwzzz|<br></br>
   ^?vv>>>>>>?~...`.......>vvrvzxxxzww?>??v`..........-?>>>>>>>vzwrzzw}zzz|<br></br>
   `??r??+>>>>>`..-`......--`~?xzrr+?rzzwwr`..........^?>>>>>>?v}rzzzrwzzz|<br></br>
   .>?r??^^?>>?^...+-..^?}zzwzzv>|`.^ruuxzr`........~`>?>>>>>>vrrzzzwrzzzz|<br></br>
    -?v}?>`>??>>-..->|.>wxwwv+`......vxuuur........|~|?>>>+>?rwrzzzz}zzzzz|<br></br>
     ^?r?>-~>v?>>-..^?>--~~`.........|zuuu?.......|>~>>>>>?rzwv}zzzwwzzzzz|<br></br>
     .>vw?+.^?w?>>~..>??+-............?zuuu^....~>>+>>>>>}zzwr?wzzrzzzzzzz|<br></br>
      `>wr?~`>rz?>?+-`+>??>^`.........`ruuuv.`|>+^>>>>>?wzzr}?}zwrwzzzzzzz|<br></br>
..     `?zr?~`?zzv>>?+^+^>??>>^-`......}xuu}+>+||>>>?vrzzwr}vrzwrzzzzzzzzr`<br></br>
-}.     `rxwv^-vzz}?>>>>>^|+>???>>+^^^|vzuu?^^^>???vrzzzrr}rzzrwzzzzzzzx}` <br></br>
-yv.     -rxzzv+rzzwv?>>>?>|~|+>>?????>?wuu?>>?vv}wzzzwrwzzzwrwzzzzzzzx?.  <br></br>
-uyv.     .vzzzw}wzzzz}?>>??>+^^+>>>>>>>}ux???vrwzzzrrzzzzzzwzzzzzzzzw^    <br></br>
-uuy}.     .>zzzzzzzzzzzrv?????>>>>>>>>>vxw}wwwwwwrrwwwwwwwwwwwwwwwwv`     <br></br>


            </div>,

            <div>

            -+++++++++>+^-...`~^++++++++++++++++^+++++>v}vv}vvvvvvvvvvv}?  <br></br>
       .  .^??>>>>??>+|`..-^>>?????????>>>>???>>>>>>>>>?v}}rwzzzzzzzzzzzx?.<br></br>
       . `>?>>>>?>+|`...-+>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vvvrzzzzzzzzzzx|<br></br>
        `>?>>>?>^`....`^+||^+>>???>>+^^^+>>>>>>>>>>>>>>>??>?}rvvwzzzzzzzzz|<br></br>
       `>?>>>?+`......--|>??>>+^~-`````...`~^>??>>>>>>>>vrv?>vzr?rzzzzzzzz|<br></br>
      `>>>>?>~........|>?>^~`................`-^>?>>>>>>?wrrv>vzr?wzzzzzzz|<br></br>
     .>?>>?>`........+>|-....-|^>>>+>+^~`.......`+?>>>>?>?wrwr?rx}vzzzzzzz|<br></br>
     ~?>>?+`........||`..`~|>??vwx}~~^+>???>~.....~>>>>>>>?wrzz}zzvwzzzzzz|<br></br>
    .>>>?>`...-.....`..`||`.. .++^-``````-|>?v>~...->?>+>>>?wrzzzzzzzzzzzz|<br></br>
    |?>>>`...|-.......`-. ..`````````````````-+??^`.`+>>+>>>vrwzzzzzzzzzzz|<br></br>
   .>??>-...-+........`. .`|`  ..```````````````~+>+~~>>>>>>?wwwwwzzzzzzzz|<br></br>
   ~?>?+...`>|...........+zu` .`.`````````````-````-^^>>?>>>>>^v||wzzwwzzz|<br></br>
   +?>?|...^?`...........+wx>  .`````````````^??|~+|~|~~|+>>^|v^`-wzzwrzzz|<br></br>
  .>>>?|..`>+....... ......``````````````````??v+~^>?>~````-~^+|^|}zzrvzzz|<br></br>
  .>>>?|..^?+..............```````~vwr+``````v+-|~|>++>``````..`..^}zrvzzz|<br></br>
  .>>>?|.`>?+.........-~~`..``````vuuyw``````?~``|+^++|``........`}zz}?zzz|<br></br>
  .>>>>^.^?>>`........|~`...``````^zuu>.`````-`````.....`-|^`....?xzz}vzzz|<br></br>
  .>>?>+`>>>>-................```.~zuy?.````````......~>>???>~``.vxzz}wzzz|<br></br>
  .>>v?>~>>>?|............^v?-.``-^zuyv...............^>>>>>>>>>}zzzzwzzzz|<br></br>
  .>>v?>^>>>>+...........`ruyuzzxuuxxu+ ..............->>>>>>>>?zzwzzzwzzz|<br></br>
   ^?vv>>>>>>?~...`.......>vvrvzxxxzww?>??v`..........`?>>>>>>>vzwrzzz}zzz|<br></br>
   `??r??+>>>>>`..-`......--`~?xzrr+?rzzwwr`..........^?>>>>>>?v}rzzzrwzzz|<br></br>
   .>?r??^^?>>?^...+-..^?}zzwzzv>|`.^ruuxzr`........~`>?>>>>>>vrrzzzwrzzzz|<br></br>
    -?v}?>`>??>>-..->|.>wxwwv+`......vxuuur........|~|?>>>>>?rwrzzzz}zzzzz|<br></br>
     ^?r?>-~>v?>>-..^?>--~~`.........|zuuu?.......|>~>>>>>?rzwv}zzzwwzzzzz|<br></br>
     .>vw?+.^?w?>>~..>??+-............?zuuu^....~>>+>>>>>}zzwr?wzzrzzzzzzz|<br></br>
      `>wr?~`>rz?>?+-`+>??>^`.........`ruuyv.`|>+^>>>>>?wzzr}?}zwrwzzzzzzz|<br></br>
..     `?zr?~`?zzv>>?+^+^>??>>^-`......}xuu}+>+||>>>?vrzzwr}vrzwrzzzzzzzzr`<br></br>
-}.     `rxwv+-vzz}?>>>>>^|+>???>>+^^^|vzuu?^^^>???vrzzzrr}rzzrwzzzzzzzx}` <br></br>
-yv.     -rxzzv+rzzwv?>>>?>|~|+>>?????>?wuu?>>?vv}wzzzwrwzzzwrwzzzzzzzx?.  <br></br>
-uyv.     .vzzzw}wzzzz}?>>??>+^^+>>>>>>>}ux???vrwzzzrrzzzzzzwzzzzzzzzw^    <br></br>
-uuy}.     .>zzzzzzzzzzzrv?????>>>>>>>>>vxw}wwwwwwrrwwwwwwwwwwwwwwwwv`     <br></br>


            </div>,

            <div>

            -+++++++++>+^-...`~^++++++++++++++++^+++++>v}vv}vvvvvvvvvvv}?  <br></br>
       .. .^??>>>>??>+|`..-^>>?????????>>>>???>>>>>>>>>?v}}rwzzzzzzzzzzzx?.<br></br>
       . `>?>>>>?>+|`...-+>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>>?vvvrzzzzzzzzzzx|<br></br>
        `>?>>>?>^`....`^+||^+>>???>>+^^^+>>>>>>>>>>>>>>>??>?}rvvwzzzzzzzzz|<br></br>
       `>?>>>?+`......--|>??>>+^~-`````...`~^>??>>>>>>>>vrv?>vzr?rzzzzzzzz|<br></br>
      `>>>>?>~........|>?>^~`................`-^>?>>>>>>?wrrv>vzr?wzzzzzzz|<br></br>
     .>?>>?>`........+>|-....-|^>>>+>+^~`.......`+?>>>>?>?wrwr?rx}vzzzzzzz|<br></br>
     ~?>>?+`........||`..`~|>??vwx}~~^+>???>~.....~>>>>>>>?wrzz}zzvwzzzzzz|<br></br>
    .>>>?>`...-.....`..`||`.. .++^-``````-|>?v>~...->?>+>>>?wrzzzzzzzzzzzz|<br></br>
    |?>>>`...|-.......`-. ..`````````````````-+??^`.`+>>+>>>vrwzzzzzzzzzzz|<br></br>
   .>??>-...-+........`. .`|`  ..```````````````~+>+~~>>>>>>?wwwwwzzzzzzzz|<br></br>
   ~?>?+...`>|...........+zu` .`.`````````````-````-^^>>?>>>>>^v||wzzwwzzz|<br></br>
   +?>?|...^?`...........+wx>  .`````````````^??|~+|~|~~^+>>^|v^`-wzzwrzzz|<br></br>
  .>>>?|..`>+..............``````````````````??v+~^>?>~````-~^+|^|}zzrvzzz|<br></br>
  .>>>?|..^?+..............```````~vwr+``````v+-|~|>++>``````.....^}zrvzzz|<br></br>
  .>>>?|.`>?+.........-~~`..``````vuuyw``````?~``|+^++|``........`}zz}?zzz|<br></br>
  .>>>>^.^?>>`........|~`...``````^zuu>.`````-`````.....`-|^`....?xzz}vzzz|<br></br>
  .>>?>+`>>>>-................```.~zuy?.````````......~>>???>~``.vxzz}wzzz|<br></br>
  .>>v?>~>>>?|............^v?-.``-^zuyv...............^>>>>>>>>>}zzzzwzzzz|<br></br>
  .>>v?>^>>>>+...........`ruyuzzxuuxxu+ ..............->>>>>>>>?zzwzzzwzzz|<br></br>
   ^?vv>>>>>>?~...`.......>vvrvzxxxzww?>??v`..........`?>>>>>>>vzwrzzz}zzz|<br></br>
   `??r??+>>>>>`..-`......--`~?xzrr+?rzzzwr`..........^?>>>>>>?v}rzzzrwzzz|<br></br>
   .>?r??^^?>>?^...+-..^?}zzwzzv>|`.^ruuxzr`........~`>?>>>>>>vrrzzzwrzzzz|<br></br>
    -?v}?>`>??>>-..->|.>wxwwv+`......vuuuur........|~|?>>>>>?rwrzzzzrzzzzz|<br></br>
     ^?r?>-~>v?>>-..^?>--~~`.........|zuuu?.......|>~>>>>>?rzwv}zzzwwzzzzz|<br></br>
     .>vw?+.^?w?>>~..>??+-............?zuuu^....~>>+>>>>>}zzwr?wzzrzzzzzzz|<br></br>
      `>wr?~`>rz?>?+-`+>??>^`.........`ruuyv.`|>+^>>>>>?wzzr}?}zwrwzzzzzzz|<br></br>
..     `?zr?~`?zzv>>?+^+^>??>>^-`......}xuu}+>+||>>>?vrzzwr}vrzwrzzzzzzzzr`<br></br>
-}.     `rxwv+-vzz}?>>>>>^|+>???>>+^^^|vzuu?^^^>???vrzzzrr}rzzrwzzzzzzzx}` <br></br>
-yv.     -rxzzv+rzzwv?>>>?>|~|+>>?????>?wuu?>>?vv}wzzzwrwzzzwrwzzzzzzzx?.  <br></br>
-uyv.     .vzzzw}wzzzz}?>>??>+^^+>>>>>>>}ux???vrwzzzrrzzzzzzwzzzzzzzzw^    <br></br>
-uuy}.     .>zzzzzzzzzzzrv?????>>>>>>>>>vxz}wwwwwwrrwwwwwwwwwwwwwwwwv`     <br></br>


            </div>,

            <div>

     ..`````~^++++++++++^|-``-~|^++++++++++^+++++++++^>?vvvvvvv}vvvvvv^~-  <br></br>
   ..`````-^^>?>?????>+|-``~|+>>>>>>>???>???>>>>>>>>>>>??vrwzzrwzzzzzzw?^~ <br></br>
  ..`````~^|+?>>>>>^~`..`|+>>>>>>>>>+^^^++>>>>>>>>>>>>>>>>?vrrwr}wzzzzzz?^.<br></br>
 ..`````~|~+?>?>+|-`..`^>+++>>>>>>>++^||~~~|^^+>>>>>>>>>??>>vwrrw}rzzzzzz?.<br></br>
 .``````~~+??>|`.....-^^^+^|~~-````.......``-~~|^>>>>>>>?v}?>vzw}wr}wzzzzz~<br></br>
 `````.-~>??+`......-~~|~--------`--```.......`-~~^>>>>>?vrwv>vzw}zr}zzzzz|<br></br>
 ```..-|>?>^`......``-~-~~^+++^|^^+^++++^|-`....`-~|>>>>>?}wz}?}zrrz}wzzzz|<br></br>
 ``..`^>?>|.........`-~~--^^~``~>?>--~~^+>>>+~`...`~~>>>>>?rwzrvzzrzzrzzzz|<br></br>
 `...+??>~....`.....-~``-`.  .`||~-```````-|>??+-..`~~+>>>>?rwzrwzzzzwwzzz|<br></br>
....^?>>~....``....--.``. ........```````````-|>?>|..-~+>>>>vwzwzzzzzzzzzz|<br></br>
 ..`??>|`..`--....--.`` ..`^~   --.`````````````-|+>|-^^>>>>?rzzzzzzzzzzzz|<br></br>
 ..^??^-...-~`....`.``...>zy~   -`.```````````-````-~|+>>??>>?v?+?vrzzzzzz|<br></br>
 .`+??|`..-|~`...........>wu?.  ..```````````|++~|+|-~~-|^>>++>^+~?vzwwzzz|<br></br>
 .`>>>|`.`||-..........`..`--`~``````````````++>|?+?+>|~|-`-~|^>>^?vzwrzzz|<br></br>
 .->>>^`.-+|-.............```~v+``-???>??+```>|`|?>>^|>||-````````|?}}}zzz|<br></br>
 .~>>>+``|>|-........`~|~`.```?v>->}}xxv}}|``+-`->|~|^+|-|```.....+?r}}zzz|<br></br>
 .~>>>>--+?|~.......`^>+~`..^??v}?~?vz}?vv~``-``~^```--`~-``.....~>vxwrzzz|<br></br>
 .~>>?>~~>?^~`...``...`~-`..-??vvv^?vwr?v>.```````....`~~~^++~...|>rzwwzzz|<br></br>
 `->+>?^^>?+|`...->`......~>+^??vvvwrx}??>............`~~|>>>??+>??wzwzzzz|<br></br>
.`->>+?>+>>>~~....+>~....`?v}vr}rzuuuuv>?|............`~~|>>>?v}zzzzzzzzzz|<br></br>
 ``+>|??>>>>^~`...`^>>|`..~^+>>???}xxxr}}+|...........`~~|>>>?vrzwwzzzwzzz|<br></br>
 ``|?^+>?>>>>~~...`..-+?>^|-``^??++~>v}wrvv+-.........-~~^>>>?vwwwzzzwzwzz|<br></br>
 ``->>^>?++>>+~-.`.-`..^?wzzwwr?~``~>}zzzzw>`.......`.~~|>>>>vrwwzwzzwzwzz|<br></br>
 ```|?++>>|>??^~````~`.~+>>???|....~+}zxuuz>`......```||>>>>vwwwzrrzwzwwzz|<br></br>
 ````+?>>?^^>??|~``--~~````........`>?zuuuw>......`~`^^>>>?vwwwwr}zzzzwzzz|<br></br>
 ````->?>>>^??v?|-`~~^|~-`..........|?}xuuu+....`~~-|>>>>vv}wwrrvrwzzzzzzz|<br></br>
 `````~>???+>?vv?+|~|^+^|~~``........+?wuuu+..`~|~~+>>>?v}}rw}rv}wzzzwzzzz|<br></br>
.``````~?vv???v}v?>>^++>++^|~-``.....-?wuuu?-~~||+>>??vvv}rwrr}}wzzwwzzzzw`<br></br>
.^``````~vvvvv?}}vv?>>>>>++>>+||~~--`~vrxuu}|^++>???}wvv}wwwr}rzzzwwzzzzw| <br></br>
.>|``````|vvvwrvrr}v??>>>?>+^+>>>>>++?w}xuu}>????v}ww}}wzzwrrwzzzwwzzzz}~  <br></br>
.>>^``````~???rzrwwr}v??>>>?>>++>>>>>?w}zuuv???v}wzr}wzzzwwzzzzzwwzzzz?`   <br></br>
.>>>^.``````>v?vzzzzwr}vv?????>>>>>>>?r}rxx}vv}wwrrrwwwwwwwwwwwwwwww}+.    <br></br>


            </div>,

            <div>

    `++++++++~^+++++++++>++^||~||^+++++++++^+++++++++^+>vv}vv?v}vvvv}v`    <br></br>
 ..~>?>>>??>~+?>>>?????>^-``~^+>>>>>>>?????>>>>>>>????>>>vrzzw?vzzzzzxr~   <br></br>
 .|?>>>>?>^`|?>>>>?>+|`..-^>>???>>>+^|~~|+>>>??>>>>>>>>?>>>vwzwv?rzzzzzz^  <br></br>
 |?>>>>?+-.|?>?>>+|`..`|>???>>++++++++^~`..`-|+>?>>>>>>?>?>+?wzzr?vwzzzzz+ <br></br>
.?>>>?>|..^??>|``....^??>^~`...................`~+?>>>>>>>}?>?wzzzv?wzzzzz-<br></br>
.>>>?+`.`^??^`.....`+>|`...`~^>>>+++^|~`..........~>>>>>>>vzv+?zzzxv?zzzzz|<br></br>
.>>>|..`>?>~.......~`..`^?}zzwv?+~|^+>??v?>~.......`+?>>+>?zz}>vzzzzv}zzzz|<br></br>
.>>-..~>?>`..........`>?+~?>-.  .```````-|>?v?~......+??>+>}zzv?wzzzwvzzzz|<br></br>
.>`.`^??+`..........^?|``.  .```````````````-+vv+`....|?>>>?}zzvrzzzzwwzzz|<br></br>
.~.`>??+....`......>+```....`.....`````````````|?v>-..`|?>>>?zzrwzzzzzzzzz|<br></br>
...^?>>`...`-.....+^```..`|?>   |~.``````````````-^>+-~~>>>>>}zzzzzzzzzzzz|<br></br>
 .`>>?~...`^......~```..-vuy+   ^- `````````````````-|^>>??>>?w>>v>vzzzzzz|<br></br>
 .^?>>`...^~.........``.`vzur-   .```````````````^+~`--`~^>>?>~^v|.^xzzzzz|<br></br>
 .>>>>`..-?-...... ..``````~~~^.````````````````-v>?`+^^+~`-~||??>>?zzzzzz|<br></br>
 `?>>?-..+>`.......`..```````^y?`````-?ww?``````-v?+|->>+~```````-~~?}zzzz|<br></br>
 -?>>?|.-?>`.........`||~`````vx?~``-}yuuy^`````|?-`~|+>^>`````....`vvzzzz|<br></br>
 ~?>>>^.^?>`........^???+-``?vzxyr``-vxuux|`````|>```^^~^-`........?xzzzzz|<br></br>
 |??+>>`>??-.....`~...~>+-``+wxxuu+``?xuuv.`-```````......-|+|.....vzzzzzz|<br></br>
.+??^|?|>>?|`....|r~ .```````>uxxuz}>rwxu}.```...........`>?>?v|~~~rzzzzzz|<br></br>
.>??+`>>>>>+``...`}z>. ...````>>>vuyuuwzuv...............`>>>?rwzzzzzzzzzz|<br></br>
.?>>>.^?>>>>-.....`vzw?~..........?xuuuux~...............`>>>?}zzzzzzzzwzz|<br></br>
.?>>>``>>>>?>........^ruwv+~`......`+??}}>?}+............~?>>?wzzzwwzzzrzz|<br></br>
.?>>?+.|?>>>?^...`.....^vwzzr}v^..`?vvrrwwww^...........->>>>}zzzzvzzzwrzz|<br></br>
.?>>>?~`>>>>>>~..-~.......`~>v>....?z}rzxxxz-..........->?>>vzzzzvvzzzwwzz|<br></br>
.?>>>>>`|?>?>>>-..^^`..............-zrwuuuuw.........-~>?>>?}zzzv?wzzzrwzz|<br></br>
.?>>>>>>->>vv>>>`..>>|`.............?uwuuuu>.....`..-+>?>>>?rzz}>vzzzzwzzz|<br></br>
.>>>>>>?^^>?zv>>>|``+?>|`............}zzuux`....--`^>>>>>>>}zzr>?wzzwrzzzz|<br></br>
 ~?>>>>>>+>>rz}?>?>+~^??>+~`.........|xzuuu?..`^||>?>??>>?}zz}??wzzwrzzzzz~<br></br>
  |?>>>>>>++vzzr?>>>>>>>>??>+~``.....-xzuuuw|+>+>?>>v}>>vwzzv?rzzzwrzzzzx> <br></br>
  .^?>>>>>>|+rzzw?>>>>>>>>>>??>>++^^^?uwuuuz??>>>>?rr??rzzwv}zzzzrrzzzzx>  <br></br>
    ~>>>>>>>|~vzzzr??>>>>>>>>>>>>>??>vuwuuu}>>>??}r}vrzzzwrwzzzzwrzzzzz+   <br></br>
     ->>>>>>?+-?zzzzrv?>>>?>>>>>>>>>>?xwzxxv>?v}}r}rwwwwrrwwwwwwwwwwwr~    <br></br>


            </div>,

            <div>

    `++++++++~^+++++++++>++^||~||^+++++++++^+++++++++^+>vvvvv?v}vvvv}v`    <br></br>
 ..~>?>>>??>~+?>>>>????>^-``~^+>>>>>>>?????>>>>>>>???>>>>vrzzw?vzzzzzxr~   <br></br>
 .|?>>>>?>^`|?>>>>?>+|`..-^>>???>>>+^|~~|+>>???>>>>>>>>?>>>vwzwv?rzzzzzz^  <br></br>
 |?>>>>?+-.|?>?>>+|`..`|>???>>++++++++^~`..`-|+>?>>>>>>?>?>+?wzzr?vwzzzzz+ <br></br>
.?>>>?>~..^??>|``....^??>^~`...................`~+?>>>>>>>}?>?wzzzv?wzzzzz-<br></br>
.>>>?+`.`^??^`.....`+>|`...`~^>>>+++^|~`..........~>>>>>>>vzv+?zzzxv?zzzzz|<br></br>
.>>>|..`>?>~.......~`..`^?}zzwv?+~|^+>??v?>~.......`+?>>+>?zz}>vzzzzv}zzzz|<br></br>
.>>-..~>?>`..........`>?+~?>-.  .```````-|>?v?~......+??>+>vzzv?wzzzwvzzzz|<br></br>
.>`.`^??+`..........^?|``.  .```````````````-+vv+`....|?>>>?}zzvrzzzzwwzzz|<br></br>
.~.`>??+....`......>+```....`.....`````````````|?v>-..`|?>>>?zzrwzzzzzzzzz|<br></br>
...^?>>`...`-.....+^```..`|?>   |~.``````````````-^>+-~~>>>>>}zzzzzzzzzzzz|<br></br>
 .`>>?~...`^......~```..-vuy+   ^- `````````````````-|^>>??>>?w>>v>vzzzzzz|<br></br>
 .^?>>`...^~.........``.`vzur-   .```````````````^+~`--`~^>>?>~^v|.^xzzzzz|<br></br>
 .>>>>`..-?`...... ..``````~~~^.````````````````-v>?`+^^+~`-~||??>>?zzzzzz|<br></br>
 `>>>?-..+>`.......`..```````^y?`````-?ww?``````-v?+|->>+~```````-~~?vzzzz|<br></br>
 -?>>?|.-?>`.........`||~`````vx?~``-}yuuy^`````|?-`~|+>^>`````....`vvzzzz|<br></br>
 ~?>>>^.^?>`........^???+-``?vzxyr``-vxuux|`````|>```^^~^-`........?xzzzzz|<br></br>
 |?>+>>`>??-.....`~...~>+-``+wxxuu+``?xuuv.`-```````......-|+|.....vzzzzzz|<br></br>
.+??^|?|>>?|`....|r~ .```````>uxxuz}>rwxu}.```...........`>?>?v|~~~rzzzzzz|<br></br>
.>??+`>>>>>+``...`}z>.....````>>>vuyuuwzuv...............`>>>?rwzzzzzzzzzz|<br></br>
.?>>>.^?>>>>-.....`vzw?~..........?xuuuux~...............`>>>?}zzzzzzzzwzz|<br></br>
.?>>>``>>>>?>........^ruwv+~`......`+??}}>?}+............~?>>?wzzzwwzzzrzz|<br></br>
.?>>?+.|?>>>?|...`.....^vwzzr}v^..`?vvrrwwww^...........->>>>}zzzzvzzzwrzz|<br></br>
.?>>>?~`>>>>>>~..-~.......`~>v>....?z}rzxxxz-..........->?>>vzzzzvvzzzwwzz|<br></br>
.?>>>>>`|?>?>>>-..^^`..............-zrwuuuuw.........-~>?>>?}zzzv?wzzzrwzz|<br></br>
.?>>>>>>->>vv>>>`..>>~`.............?uwuuuu>.....`..-+>?>>>?rzz}>vzzzzwzzz|<br></br>
.>>>>>>?^^>?zv>>>|``+?>|`............}zzuux`....--`^>>>>>>>}zzr>?wzzwrzzzz|<br></br>
 ~?>>>>>>+>>rz}?>?>+~^??>+~`.........|xzuuu?..`^||>?>??>>?}zz}??wzzwrzzzzz~<br></br>
  |?>>>>>>++vzzr?>>>>>>>>??>+~``.....-xzuuuw|+>+>?>>v}>>vwzzv?rzzzwrzzzzx> <br></br>
  .^?>>>>>>|+rzzw?>>>>>>>>>>??>>++^^^?uwuuuz??>>>>?rr??rzzwv}zzzzrrzzzzx>  <br></br>
    ~>>>>>>>|~vzzzr??>>>>>>>>>>>>>??>vuwuuur>>>??}r}vwzzzwrwzzzzwrzzzzz+   <br></br>
     ->>>>>>?+-?zzzzrv?>>>?>>>>>>>>>>?xwzxxv>?v}}r}rwwwwrrwwwwwwwwwwwr~    <br></br>


            </div>,

            <div>

.~|~~+++++||+^+++++++++++++^^|^^^++++++++++^+++++++++^+>?vv}v?v}vvvvvv^~||.<br></br>
.+^+>?>?>^|>^>??>>>>???>+|~|^+>>++^^^+>>>???>>>>>>>???>>>?}wzwvvwzzzwzw?^+.<br></br>
.|+?>>>>|~^|+?>>>>?>>^~-~^+>????>>>+^~---~|^>>?>>>>>>>>>>>>vrzzv?}zzzwwz?^.<br></br>
.^?>>>+~--~+>>>>>+|``-|+>>+^^||||||~~~~-`...`-|+>?>>+>>?>?>>?rwzw??rzzwwz?.<br></br>
.>>>>^~``~+>>+~`...`~|^||~~~~||||~~~--`.........`|>>>|^>>>v?>?wwzzv?rzzwzz~<br></br>
.>>>|-..~+>>~.....``~|^^^^+>?>~`~|^^^^^^|~`.......`^>>|~>>?wv+?zwzz}?wzzzz|<br></br>
.>+~`..~>>^`.......-^|^>?vrv^`..``-~||^+>>>+|`......|>?^|>?rzv>}zwzzvvzzzz|<br></br>
.+~`.`^>>~........~|`-?v?^-.....````````-~|+>?>~`....-+?^^>?zxv?zzwzz?rzzz|<br></br>
.~`.`+?>~........~-.~^~`.  ..```````````````-|>??^`...`^?++>}zzvrzwzzrvzzz|<br></br>
.`.`>?+-........~-.||``.......    ..```````````~+??^`.``+?>>?zzrwzzwzzrwzz|<br></br>
 ..+?>~....``..--.|~``....^vv.  ..--.`````````````~+>^^-~>>>>}zzzzzzzzwwzz|<br></br>
 .~??^`...--......-```..-ryy?   ..~`.`+-````````````-~^?>??>>?wvv}??wzzzzz|<br></br>
 .+?>|`..-~`........```.~vzuz^.   ..`>v``````````||-`--`~^>??>>?v>`~?wzzzz|<br></br>
 `>>>|`.`|~`........```````-~||`````-v?`````````->|+|?>|^|-~~|^??+^+?zzzzz|<br></br>
 |>>>|`.~|~`.......``````````~v+`>+??v}??+~>?|``->^~+^>>^|^|`````-~~>vzzzz|<br></br>
.^?>>^``^|~`...``....`||~`````?v>?vvvzxvvw}v}v-`~>``^>++~+|||``....`+>vvwz|<br></br>
.^?>>+--+|~`...``...+???|-``+??vvv?vvwxvvxvvvv|`~^``^^~-^|~~-``....~>vzwzz|<br></br>
.+?>>+~~>|~`..~`.`-.-``++~``|?vvvv?vvwx}vv?vv+``````~`...``--`.....^?zzzzz|<br></br>
.>?>++^|>~~`..^+.~?-..-||~```^vvvvv?vzzxzr?v?-```.........|^^|?|```+?zzzzz|<br></br>
.?>>^|>>>||^..`>~.>?^````.````^^^>vvvzxxuw??>`.........```>?>|vwv???vzzzzz|<br></br>
.>>>^`+?>+~>-..-?+-+??+-....``````+vvvxuuv??>`.........``->?>^vzzzzzzwzzzz|<br></br>
.?>>>`~>>>||^...`^>+-->??>|-`......`~|^vr}ww>..........``^>?++}zzzzwwrzwzz~<br></br>
.??>>|.+>>>~^-.....|?>^^+>>>+^^-...|^|^+>vrw?-.......`.-|>>>+?wzzzrw}wzwzw~<br></br>
.>?>>>-`>>>>~~`..``.~???^|~-|+~....+??vrzzzz>`......`.`|>>>>?rzzzr}r}zwwzw|<br></br>
`???>>>`^>>?>|~`..--.~>>>++^^~...-^>z}wxxxxz^.......``|>?>>??wzzrvr}wzwzzw|<br></br>
`v???>>+->>??>+~`.`~~`-~^++^-....`+^vzwxuuu}-......`~+>>>>>?vzzwv}}rzzzzwz|<br></br>
.?v???>>^+??v??>^-`-~~-````.......~?>rxxuuu+....`-~+>>>?>>?vwzwvv}wzzwzzzz|<br></br>
 `?v???>>+>v}}v?>>^^^^|~~-`........|?vuxuux|..`||^>>>???>?rwzwvv}wzwwzwwzz~<br></br>
.`~vv???>>>?rr}v?>>>>>>>+^|~-`......+?xxuur>~+>+>>>>v}?>vwzzr}rrzzwwzwzzxv.<br></br>
 --^}vv???>+?ww}v?>>>>>>>>>>+^||~~-`?rxzuuxv+^+>>>?r}??}zzwrwwwzzwwwwzzzv^.<br></br>
 `~~+v}?>v?>>?}zr}v?>>>>>>++>>>>>>>>rwuxuuuv+>>?vrwvv}wzzwrzzzzzwwwwwzz?^^.<br></br>
 ``||+v}?+vv???vzzw}v??>>>?>>>>>>>>>}wzzxxxv>?vrw}v}rwwwrrwwwwwwwwrwww?|^^.<br></br>


            </div>,

            <div>

.v}v>+++++--+++++++++++++++^^++++++++++++++++++^^+++++++>?v}v?v}}vvvvvvvvv-<br></br>
`xw?>>>?>~`>??>?>>>>>??>>>>>>>>>+^|~|^+>>>????>>>>>???????vrzzvvwzzwrwzzzx|<br></br>
`}?>>>?+`.^?>>>>>>?>>+^>>????>??>>>+^~-```-|+>>?>>>>>>>>>>>?}wzr?vwzzrrzzz|<br></br>
.>>>>>|..|?>>>>>+^~``^>>+^|~------```````.....-|>>>>++>>>>>>?rrzzv?rzzrwzz|<br></br>
.>>?>-..->>>>+~`...~+|```~^>??vvv???>+~`.........->?>-~>>>??>?wrwzv?}zzrzz|<br></br>
.>?+`..`>>>+-.....--.|?vwwzxz}|``-|^>>?vv?^`.......|>>-`>>>rv>?zrwz}?rzzzz|<br></br>
.>^...`+>>~........~?vwzzzw?-   ..``````~+?v?^`.....-+?~`>>vzv>}zrzzv?wzzz|<br></br>
.^...`>?+`........>?~~v}v>. ..`````````````-^?}?-.....^?|~>>wxv?zwrzz?vzzz|<br></br>
....`>?^........`?>`````. .``````.````````````~?}v~....~?^+>}zwvrzrwz}?wzz|<br></br>
 ..`>?^.........?^`````..`.`~-      ````````````->v?~``.|?>+?zzrrzw}zz?rzz|<br></br>
 .`>?+....``...>^`````...`?zx`    >| ``````````````^???-.>>>>}zzzwzwwzrrzz|<br></br>
 .^??-...`|....`..````..+xyyz     ?-.-?~`````````````-+????>>?wzr}v?vzzwzz|<br></br>
 `>>>`...^-........````.+}zuy?`.  ..`vz-````````````-~``-|>???vr}v-`-wzzzz|<br></br>
 |?>>`..|+....``....`````.`-|+^-.```-xr`````````````+?>~~+~|~|+>?^~-~wzzzz|<br></br>
.>>>>`.`>~.......```````````````-v?rwuw```|v}^``````>>?^-|>+`````~~~~?xzzz|<br></br>
.?>>>-.~?`....````````~~~````````}xuuuz-`?uuyx~`````>?^|-|+>+``````.`-??rz|<br></br>
.>>>?|.^>`.....`````>??>~-```````^zxxux--zuuuy+`````?|``|^++^```.....+wwzz|<br></br>
.>>>>>`+>`....>-````~`.|>|````````vxxuz^`vuuu?``````+-```~``........~zzzzz|<br></br>
.>>>>>~+>`....?v.````.~?>|````````-|vuxxvwxux^``````......`~-.+~... ~zzzzz|<br></br>
.>>>|>+>>``~..`z>..```~~`.```````````vyuuuzxx^.........`~`??+`?w>|||>zwzzz|<br></br>
.>>>~|?>?|.^`..|xr|..````````````````-wuuuuuz-.........~~|??+-?zzzzzw}wzzz|<br></br>
.?>>+`+?>>``|...-?z}^....```````````..`?wxux?..........|~>>?+^vzzzzr}vzzzz-<br></br>
.??>>~`>>>+.-`....`?yz?|................->vr}>.......`.++>>?^>rzzzz}?}zzzr~<br></br>
.>v>>+`~?>?+........>uyur?>|~`...``-||?vrwwww~......-`->>>>>^?zzzzrv?zzzzr|<br></br>
`??v>>+`>>>?+`.......?xxzwwrw?...>www}wwwwwzv.......~`>>>>>>>}zzzrv?}zzzwr|<br></br>
-w?v?>>^~>>>?>|`.`-...+?}ww}+....-www}wzxxuu^......|^>?>>>>>?zzzr}?vzzzzrz|<br></br>
.?r?v?>>^>??>>?>~.~^`...`~|`......>xzrxuuuyv....`-^>>>>??>??rzzr}?}zzzzzzz|<br></br>
 .vw?v?>>>?rv>>>?>-^>^-............?uzxuuuw`..`|^>?>>?v?>v}}zzwv?rzzzzrwzz|<br></br>
`>-wwvv?>>>vzv>>>>>++?>>|-`.........}xzuuu?.~+++>>>>v}?>vwwwzzrvwzzzwrwzzz|<br></br>
.??>zz}vv?>?rw}?>>>>>>>>>>+^|~```...vxzxuuz+^~|>>>?rv??vwzrwzwrzzzzrrzzzzz|<br></br>
.>vv?wzv>}v>vwwwv?>>>>>>>+^^++>>>>>+zxzuuuy?|>??vrw??vrzzrrzzzzzzzrrwzzzzz|<br></br>
.>?r}?wz?+rr?rzwwwv?>>>>>??>>>>>>>>>zzwxxxxv>?vrwv?v}rwwrrwwwwwwww}rwwwwww~<br></br>


            </div>,

            <div>

.v}v>+++++--+++++++++++++++^^++++++++++++++++++^^+++++++>?v}v?v}}vvvvvvvvv-<br></br>
`xw?>>>?>~`>??>?>>>>>??>>>>>>>>>+^|~|^+>>>????>>>>>???????vrzzvvwzzwrwzzzx|<br></br>
`}?>>>?+`.^?>>>>>>?>>+^>>????>??>>>+^~-```-~^>>?>>>>>>>>>>>?}wzr?vwzzrrzzz|<br></br>
.>>>>>|..|?>>>>>+^~``^>>+^|~------```````.....-|>>>>++>>>>>>?rrzzv?rzzrwzz|<br></br>
.>>?>-..->>>>+~`...~+|```~^>??vvv???>+~`.........->?>-~>>>??>?wrwzv?}zzrzz|<br></br>
.>?+`..`>>>+-.....--.|?vwwzxz}|``-|^>>?vv?^`.......|>>-`>>>rv>?zrwz}?rzzzz|<br></br>
.>^...`+>>~........~?vwzzzw?-   ..``````~+?v?^`.....-+?~`>>vzv>}zrzzv?wzzz|<br></br>
.^...`>?+`........>?~~v}v>. ..`````````````-^?}?-.....^?|~>>wxv?zwrzz?vzzz|<br></br>
....`>?^........`?>`````. .``````.````````````~?}v~....~?^+>}zwvrzrwz}?wzz|<br></br>
 ..`>?^.........?^`````..`.`~-      ````````````->v?~``.|?>+?zzrrzw}zz?rzz|<br></br>
 .`>?+....``...>^`````...`?zx`    >| ``````````````^???-.>>>>}zzzwzwwzrrzz|<br></br>
 .^??-...`|....`..````..+xyyz     ?-.-?~`````````````-+????>>?wzr}v?vzzwzz|<br></br>
 `>>>`...^-........````.+}zuy?`.  ..`vz-````````````-~``-|>???vr}v-`-wzzzz|<br></br>
 |?>>`..|+....``....`````.`-|+^-.```-xr`````````````+?>~~+~|~|+>?^~-~wzzzz|<br></br>
.>>>>`.`>~.......```````````````-v?rwuw```|v}^``````>>?^-|>+`````-~~~?xzzz|<br></br>
.?>>>-.~?`....````````~~~````````}xuuuz-`?uuyx~`````>?^|-|+>+``````.`-??rz|<br></br>
.>>>?|.^>`.....`````>??>~-```````^zxxux--zuuuy+`````?|``|^++^```.....+wwzz|<br></br>
.>>>>>`+>`....>-````~`.|>|````````vxxuz^`vuuu?``````+-```~``........~zzzzz|<br></br>
.>>>>>~+>`....?v.````.~?>|````````-|vuxxvwxux^``````......`~-.+~... ~zzzzz|<br></br>
.>>>|>+>>``~..`z>..```~~`.```````````vyuuuzxx^.........`~`??+`?w>|||>zwzzz|<br></br>
.>>>~|?>?|.^`..|xr|..````````````````-wuuuuuz-.........~~|??+-?zzzzzw}wzzz|<br></br>
.?>>+`+?>>``|...-?z}^....```````````..`?wxux?..........|~>>?+^vzzzzr}vzzzz-<br></br>
.??>>~`>>>+.-`....`?yz?|................->vr}>.......`.++>>?^>rzzzz}?}zzzr~<br></br>
.>v>>+`~?>?+........>uyur?>|~`...``-||?vrwwww|......-`->>>>>^?zzzzrv?zzzzr|<br></br>
`??v>>+`>>>?+`.......?xxzwwrw?...>www}wwwwwzv.......~`>>>>>>>}zzzrv?}zzzwr|<br></br>
-w?v?>>^~>>>?>|`.`-...+?}ww}+....-www}wzxuuu^......|^>?>>>>>?zzzr}?vzzzzrz|<br></br>
.?r?v?>>^>??>>?>~.~^`...`~|`......>xzrxuuuyv....`-^>>>>??>??rzzr}?}zzzzzzz|<br></br>
 .vw?v?>>>?rv>>>?>-^>^-............?uzxuuuw`..`|^>?>>?v?>v}}zzwv?rzzzzrwzz|<br></br>
`>-wwvv?>>>vzv>>>>>++?>>|-`.........}xzuuu?.~+++>>>>v}?>vwwwzzrvwzzzwrwzzz|<br></br>
.??>zz}vv?>?rw}?>>>>>>>>>>+^|~```...vxzxuuz+^~|>>>?rv??vwzrwzwrzzzwrrzzzzz|<br></br>
.>vv?wzv>}v>vwwwv?>>>>>>>+^^++>>>>>+zxzuuuy?|>??vrw??vrzzrrzzzzzzzrrwzzzzz|<br></br>
.>?r}?wz?+rr?rzwwwv?>>>>>??>>>>>>>>>zzwxxxxv>?vrwv?v}rwwrrwwwwwwww}rwwwwww~<br></br>


            </div>,

            <div>

.v}v>++++|`|++++++++++++++++^^++++++++++++++++++^^++++++>vvvv?v}vvvvvvv}vv-<br></br>
`zw?>>?>^`~>?>>>>>>>??>>>>>>>>>+^|~~~~|^+>>?????>>>>>???>?vrwwv}wzzzwzzwzx|<br></br>
`}?>>>>~.`>>>>>>>>>>^^^>>??>>>>>>>>+|~-`.``~^+>??>>>++>>>>>?rww}?vwzzwwwwx|<br></br>
.>>>>+-..^>>>>>>+|-`~+>++^^||||^||~~-```......`|+>?>+||>>>>>?rwww??rzzwzwz|<br></br>
.>>>^`..~>>>>^-`..`~^||^+>?????>^+????>|-`.......`^>>|-~+>??>?wwwwv?}zzwzz|<br></br>
.>>|...->>>|`....`-|^?vrwzz}+-. ..-~|+>?v?>|`......-+>^-~^>v}>?wwwz}?}zzzz|<br></br>
.>|...->>+`......~|+}}zzz}+.    ...`````-|>?v>~.....`^>^~~^?z}>vzwwwv?rzzz|<br></br>
.~...->?^......`|~^v??vv>.  .```````````````~>vv^`....~>+||>rxv?wzwww??wzz|<br></br>
 ...->?|.......|-^|`-``. .``.........`-```````-+vv^..`.->+++vzzv}wwzw}?rzz|<br></br>
 ..`>?|.......|-^~````. .`.`||       .``````````-^vv^~`.-?>>?zzw}wwwww?vzz|<br></br>
 .`>?|.......-~|~`````.`.-vxu-    .^` ````~-```````|???~.|>>>}zzwwzzzw}vzz|<br></br>
 .|?+`....`.......````..>uyyx.    `~..^-`>v~`````````-^??>??>?zzwrv?}zw}zz|<br></br>
 `>?^....-`........````.>}zuyv`.  ...>?`>}+`````````--``-^>???}w}v^`-vzwzz|<br></br>
 |??^...--....`....``````.`-^>+~...|+}r>vv``````````|+?+|~~|~|+>?>~--vzzzz|<br></br>
.>>?+..-|`.......````````````````>+vxuzv}?~>v?>-````^^?^>~+>+-```-~||>wzzz|<br></br>
.?>>+`.^|`....``````.`~~~````````?vvwuxvvvvxyu}?-```^+?>|||>>~````````+?vz|<br></br>
.>>>>``+|....```````>?v>~````````~?vvuxvv}wuuu}}^```^-+--~^^^|```....-?}rz|<br></br>
.>>>>~->~...-+>-````^-.-+~````````>vvzxvvvruuwv>-```|-^``---``......`?zzzz|<br></br>
.>>>>+~>~...`?v>.````.-?>^````````-~>vruzxxxx}?|`````......``.|~....`vzzzz|<br></br>
.>>>^>^>~```.>>?^`````^+|-```````````?vzuuxuxv>`........``+>^-?r?---~vzwzz|<br></br>
.>>>~+>>|``-.`>?v?~``````.```````````-?}uuuux?|........-`^??+~?wxwwwrrrwzz|<br></br>
.?>>|`>?+~-~`..~???>~`..`````````````.`|?rzx}>`......`.~->>?^^?zzzzww}}zzz~<br></br>
.??>>`~>>|--`...`?>?v?+-.................-^>??......`~`^+>>?^>}zzzzwr}rzzw~<br></br>
`??>>+`^?>|-`....~??vz}v>|~```.....```~^>?v}w>......--->>>>>^?wzzzwrvvwzzw|<br></br>
`}??>>^->>>^-.....>??}zzwv+^+~...^??v?}rwwwzr-.....`^`+?>>>++vzzzwrvvrzzzw|<br></br>
`zvv?>>^^>>>>|`...^>>>?vv>+^-...`>www}wwzxxx>......^^^?>??>>?wzzww}vrzzzwz|<br></br>
.}wvv?>>^>??>>+~`.-||^^-.``......~vzzrxxuuur`...`-+?>>>??>?vrzzww}}rzzzzzz|<br></br>
 |}z}v>>>>?}?>>>+~~^|-`...........+}xzuuuuz-..-^^>>>>?vv?vwrzzwrrrwzzzwwzz|<br></br>
.~+zz}v?>>>rw?>>>>^^>>+|-`........`>zxxuuu>.~+>+>>>?vrv?vzzzzzwrwwzzzwzzzz|<br></br>
.>^?zzrv??>?wwv?>>>>>>>>>^|~-``....~wxxuuuv>+~~>>?vrr??}zzwwzwwwzzzwwzzzzz|<br></br>
.>??vzz}?v??}zw}??>>>>>>>++++++++++>uxxuuuz^-|>?vwz}?vwzzwwzzzzzzzwwwzzzzz|<br></br>
`??v}}zz}??v?wzzrv??>>>>>>+|^^++>>>vxzzxxxz?>?vrwrvvrwwwwwwwwwwwwwrwwwwwww~<br></br>


            </div>,

            <div>

.?}v>++++~`+++++^^+++++++++++^^^^++++++++++++++++^++++++?vvv??v}vvvvvvv}vv-<br></br>
`zw?>>?>~.+??>>>>>???>>>>>>>>>>+|~----~|+>>??????>>>>??>>?vrrrvvwxzzzzwrxz|<br></br>
`r?>>?+`.~?>>>>>??>+||+>??>>>>>>>>+^|~``..`-|+>??>>>^+>>>>>?rwrv?vzzzzzrrx|<br></br>
.?>>>^..`>>>>??>^~``^>+^+++>>???>>+^~-`.......`~+>??+-`+>>>>?}zrr??rzzzzrw|<br></br>
.>>>~...|>>?>|-`..~||+?v}rwr??>>+^?vvvv?+-.......`|>?>-`^>>???rzwrv?vzzzzz|<br></br>
.>>-...|??>~.....`|?}zzzzzzv`    ..``~^>?vv>~......`^??|.|>?}??wzwwv>vzzzz|<br></br>
.>-...~??^`......>vvzzzzzv~   ....```````-|>vv+`.....~>?^`^?w}>vzzwrv>vzzz|<br></br>
.-...~??~......`v?-vwrv?|  .```---```````````|?}?`....->?+-+}zv?}zzrr?>rzz|<br></br>
 ...->>~......`v+``---`. .``.........`-````````~?}?`.`.`>?+^vzzvvrzzrv>vzz|<br></br>
 ..`>>-.......?+``````. `..|??`    . .```````````~?}?^`.`>?>?zzwvrwzrr??zz|<br></br>
 ..>?-.......>>```````.`.+wuy~     v? ````|~```````~>vv^.->>>}zzrrwxzrv?zz|<br></br>
 .|?^....... `....```.``vyyyu`     ?-.``-vz|`````````-^?v???>?zzz}}vrwwvzz|<br></br>
 `>?~...`..........````.>}zuyw~. ....`..vy?`````````````-^>???rw}}>``?zrzz|<br></br>
                  ^??|..`-....``....``````.`~^??|``.+??vvxz-```````````??^`~|~^+???~-`+xzzz|<br></br>
.>>?|..|-..... .```````````````````+xxuuur`->}v-``````?^?~~+?~```-|||+}xzz|<br></br>
.>>>^.`>`.....``````.`-~~``````````-vxxxuv~wyuyr-`````??+^-+>^````````~??w|<br></br>
.>>>+.->`...`-`````->>v?~```````````^uxxu>?yuuuy>`````?~``|||+-``.....^vvw|<br></br>
.>>>>`|>`...^r|````-+~.`^~``````````-vzxu?>uuux}-````->```|~~`.......~wxzz|<br></br>
.>>>?~|>`...|uv``````.-??+-```````````>uuuzzzuz+``````........--.....?xzzz|<br></br>
.>>>>+^>`.`..w}```````+?+^`````````````ruuuuzxw~.........-^+^->}?....?wrzz|<br></br>
.?>>~>>>`.|`.-wr~`````-``..````````````|xuuuuuv........-.>??+~>rzrrrrwvrzz|<br></br>
.>>?~~??|.~^...?r>`.```````````````````.~vwzxz~......-.|->>?^|?wzzzzzw?zzz|<br></br>
.>>>>`^?>`.^`...~uw>`..````````````........->v......`^`|+>>?|+vzzzzzzvvzzz|<br></br>
`v>>>^`>?>`.-....>yyzv^~`...............`|>vrv......~^`+>>>>|?rzzzzzr?rzzz|<br></br>
`zv>>>^|>>>~.....`zuuzwrr>......`~|^>>?vrwwww+.....`>-^?>>>++vzzzzzz?vzzzz|<br></br>
`zw?>>>^+>>?+`....?xxwww?`......~wwww}wwwwzzv.....`++|?>??>>?rzzzzzvvzzzzz|<br></br>
`zzw?>>>+??>>>^`.`-|?vr>.........?wwwrzxxuux-...`->?+>>?v>?}}zzzzz}vzzzzzz|<br></br>
.vzzw?>>>>vv>>?>|`~+-``..........`rzwruuuux~..-^+>>>>?}r?vzwzzzzz}vzzzzzzz|<br></br>
 `vxzw?>>>?w}?>>?>~^?>|-..........~zuwuuuu^.~+>+>>>?vwr?rzzzzzzzrrzzzzzzzz|<br></br>
.+~wzzwv>>>vzr?>>>>+>??>>^~``......+yzxuux+>>|~+>?vwzv?wzzzzzzzrwzzzzzzzzz|<br></br>
.?+?zzzz}>>>rzzv>>>>>>>>>+>>>++^^^^?uzxxuuv-`->?}wzr?vzzzzzzzzzzzzzzzzzzzz|<br></br>
`??>vzzzz}>>?zzz}?>>>>>>>+|-~|^+>>>vxwzxxx}^>?vwzwv?rwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

.?}v>++++~`+++++^^+++++++++++^^^^++++++++++++++++^++++++?vvv??v}vvvvvvv}vv-<br></br>
`zw?>>?>~.+??>>>>>???>>>>>>>>>>+|~----~|+>>????>?>>>>??>>?vrrrvvwxzzzzwrxz|<br></br>
`r?>>?+`.~?>>>>>??>+||+>??>>>>>>>>+^|~``..`-|+>??>>>^+>>>>>?rwrv?vzzzzzrrx|<br></br>
.?>>>^..`>>>>??>^~``^>+^+++>>???>>+^~-`.......`~+>??+-`+>>>>?}zrr??rzzzzrw|<br></br>
.>>>~...|>>?>|-`..~||+?v}rwr??>>+^?vvvv?+-.......`|>?>-`^>>???rzwrv?vzzzzz|<br></br>
.>>-...|??>~.....`|?}zzzzzzv`    ..``~^>?vv>~......`^??|.|>?}??wzwwv>vzzzz|<br></br>
.>-...~??^`......>vvzzzzzv~   ....```````-|>vv+`.....~>?^`^?w}>vzzwrv>vzzz|<br></br>
.-...~??~......`v?-vwrv?|  .```---```````````|?}?`....->?+-+}zv?}zzrr?>rzz|<br></br>
 ...->>~......`v+``---`. .``.........`-````````~?}?`.`.`>?+^vzzvvrzzrv>vzz|<br></br>
 ..`>>-.......?+``````. `..|??`    . .```````````~?}?^`.`>?>?zzwvrwzrr??zz|<br></br>
 ..>?-.......>>```````.`.+wuy~     v? ````|~```````~>vv^.->>>}zzrrwxzrv?zz|<br></br>
 .|?^....... `....```.``vyyyu`     ?-.``-vz|`````````-^?v???>?zzz}}vrwwvzz|<br></br>
 `>?~...`..........````.>}zuyw~. ....`..vy?`````````````-^>???rw}}>``?zrzz|<br></br>
                  ^??|..`-....``....``````.`~^??|``.+??vvxz-```````````??^`~|~^+???~-`+xzzz|<br></br>
.>>?|..|-..... .```````````````````+xxuuur`->}v-``````?^?-~+?~```-|||+}xzz|<br></br>
.>>>^.`>`.....``````.`-~~``````````-vxxxuv~wyuyr~`````??+|-+>^````````~??w|<br></br>
.>>>+.->`...`-`````->>v?~```````````^uxxu>?yuuuy>`````?~``|||+-``.....^vvw|<br></br>
.>>>>`|>`...^r|````-+~.`^~``````````-vzxu?>uuux}-````->```|~~`.......~wxzz|<br></br>
.>>>?~|>`...|uv``````.-??+-```````````>uuuzzzuz+``````........--.....?xzzz|<br></br>
.>>>>+^>`.`..w}```````+?+|`````````````ruuuuzxw~.........-^+^->}?....?wrzz|<br></br>
.?>>~>>>`.|`.-wr~`````-``..````````````|xuuuuuv........-.>??+~>rzrrrrwvrzz|<br></br>
.>>?~~??|.~^...?r>`.```````````````````.~vwzxz~......-.|->>?^|?wzzzzzw?zzz|<br></br>
.>>>>`^?>`.^`...~uw>-..````````````........->v......`^`|+>>?|+vzzzzzzvvzzz|<br></br>
`v>>>^`>?>`.-....>yyzv^~`...............`|>vrv......~^`+>>>>|?rzzzzzr?rzzz|<br></br>
`zv>>>^|>>>~.....`zuuzrrr>......`~|^>>?vrwwww+.....`>-^?>>>++vzzzzzz?vzzzz|<br></br>
`zw?>>>^+>>?+`....?xxwww?`......~wwww}wwwwzzv.....`++|?>??>>?rzzzzzvvzzzzz|<br></br>
`zzw?>>>+??>>>^`.`-|?vr>.........?wwwrzxxuux-...`->?+>>?v>?}}zzzzz}vzzzzzz|<br></br>
.vzzw?>>>>vv>>?>|`~+-``..........`rzwruuuux~..-^+>>>>?}r?vzwzzzzz}vzzzzzzz|<br></br>
 `vxzw?>>>?w}?>>?>~^?>|-..........~zuwuuuu^.~+>+>>>?vwr?rzzzzzzzrrzzzzzzzz|<br></br>
.+~wzzwv>>>vzr?>>>>+>??>>^~``......+uzxuux+>>|~+>?vwzv?wzzzzzzzrwzzzzzzzzz|<br></br>
.?+?zzzz}>>>rzzv>>>>>>>>>+>>>++^^^^?uzxxuuv-`->?}wzr?vzzzzzzzzzzzzzzzzzzzz|<br></br>
`??>vzzzz}>>?zzz}?>>>>>>>+|-~|^+>>>vxwzxxx}^>?vwzwv?rwwwwwwwwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

.?vv>+++^`~+++++^^+++++++++++^^||^^^++++++++++++++++++++?vvv??v}vvvvvvv}vv-<br></br>
`wz?>>?+`->??>>>>>???>++>>>>>>+^|~-```-~^+>??????>>+>???>?}ww}vvwzzzzzzzzx|<br></br>
`r?>>?^`.^?>>>>>??>+||+>>>>>>>>>>>+^|~``..``~^>??>>>||+>>>>?rwrv?vzzzzzwwx|<br></br>
`?>>>~..->>>??>>^~`-+>++>>???????+>+^~`........-^>??>~`~>>>>?rzw}??rzzzzwz|<br></br>
.>>>-...^>??+|-`..~^^>v}rwwv>+^~`->??vv?>~`......`|>?>^`-+>???rzwrv?vzzzzz|<br></br>
.>>-...^??>-.....-+vrzzzzzw>.    ...`-~+?vv?|......`|>?+-`+?}??wzzwv>vzzzz|<br></br>
.>-...|??^......~?vrzzzzw?`   ...`````````~+vv>-.....->?>-->rr>vwzzr?>vzzz|<br></br>
.`...~?>~......^?+?}r}v>`  .`````-```````````|?}?~....`+?>-^vz}?wwzw}?>}zz|<br></br>
 ...~?>-......|>~`-~--`  .``...... ...`-```````~?}?-.`.`+?>^vzzv}wwzwv>?zz|<br></br>
 ..-?>`......~>~``````. `.`^?v-    `..```````````~?}?+`.`+?>?zzz}wwzwr??wz|<br></br>
 .`>>`......->^```````.`.>zyy^     >v .-``~-~~`````->vv^``>>>}xzwwwzzwv?wz|<br></br>
 .|?~...... .....````.``vyyyy`     +| .`->vv?-````````^?v???>?zzzrrvrww?wz|<br></br>
 `>?-...`.. .......`````?}zuyw~..... -``>}}v~```````````-|>???}w}}>``+z}zz|<br></br>
 ^??-..`-...```....``````.`~^??+-..|>v}rzwv>``````````+?+--||^+???~-`|zzzz|<br></br>
.>>?~..|`....  .```````````````````^vwxuuzv^^v}?~`````>?>>-+>|``-~|^^+vxzz|<br></br>
.>>?|.-+......``````.`-~~``````````->vxuuwvvxuuxv-````>v?^~^>^-```````-??r|<br></br>
.>>?^.|+....`-`````->>v?~```````````|vxuur}zuuuuw^````>?-`~|++~``.....-vvw|<br></br>
.>>?+`^+....>}~````-+~``|~``````````->}zxw?ruuuz?~```-+^``~^|~`......`vxzx|<br></br>
.>>>>-++....?u>``````.`??+-```````````^}uuxzzuxv-``````.......`-.....+xzzz|<br></br>
.>>>>^++....>z?``````-+?+^-````````````?zuuuxxx?`........`~||~>v>....+zrzz|<br></br>
.?>>~>>>`.~.`?w?-`````~~-`.````````````~}uuuuur|.......`.>??+~>}x}}}}rr}zz|<br></br>
.>>?~~?>~.||..|vv|`````````````````````.`?rzxz>......```-?>?^^?wzzzzzzvrzz|<br></br>
.>>?>`^?>``^`..-vzv|`.``````````````.......`+v~.....`-``^?>?|>?zzzzzzrvwzz|<br></br>
`?>>>^`>?^.``...>ryxv+-`.................`|?}}`.....~--~>>>>^?}zzzzzwvvzzz|<br></br>
`w?>>>||?>+`....>?xuxwr?^-.......``-~~+?v}wwz>.....`^-^>>>>++?zzzzzzv?rzzz|<br></br>
`zw?>>>^+>>>^`..>?rxzw}>~.......^}rrw}wwwwwz}`....`>^^>>??>>?}zzzzz}?rzzzz|<br></br>
`zzr?>>>+??>?>|`->??vv+-........`vwwwrzzxxux|....->>+>>?}??}rzzzzzrvwzzwzz|<br></br>
.vzzr?>>>>}?>>?>~`+?^............^wwwruuuux^..`|^>>>>?vw?vwzzzzzzwvwzw}wzz|<br></br>
 `}zzr?>>>?wv>>>>+~+>+~`..........>xxzuuuu+.~+>++>>?vwwv}zzzzzzzwrwzrvwzzz|<br></br>
`^|zzzwv>>>}z}?>>>>+>??>+|``.......?uxuuuz^>>|~^>?vrzrvrzzzzwzzwwzz}}zzzzz|<br></br>
`v>vzzzz}>>?wzw?>>>>>>>>+>>>>+^^^^~?uzxxuuv-``>?}wzw}}zzzzwrzzzzzrvwzzzzzz|<br></br>
`}vvrzzzz}+>vzzz}?>>>>>>+|--~^+>>?>rxzxxxxv~^>vrzw}vrwwwwwvrwwww}?wwwwwwww~<br></br>


            </div>,

            <div>

`?vv>+++|`^+++++^++++++++++++^|||||^^+++++++++++++++++++?vvv??v}}vvvvvvvvv-<br></br>
`wz?>>?^`^??>>>>>>???>++>>?>>>+^|-`````~|+>>????>>>+>???>?}zwv?vwzzzzzzzzx|<br></br>
`r?>>>|.`>>>>>>>??>^|^+>>>>>>>>>>>+^|~``...`-|>>?>>>|~^>>>>?ww}??vzzzzzzzz|<br></br>
`?>>>~..~>>>??>>^~`|+>>>?vvv}}}}v>??+|-........`|>?>>^`->>>>?rzwv??rzzzzzz|<br></br>
.>>>-..`>>??+|-`.`|^>v}wzzr?^|-```^>??v??^`.......~>??>-.^????rzzr??vzzzzz|<br></br>
.>>`...+??+-.....~?rzzzzzzw^     ...```~+?vv+`......|>?>|.^?v??wwzw??vzzzz|<br></br>
.>`...^?>|......>?vzzzzzw?.  ..```````````-^?}?~.....`+??|`>vr?}rwxr?>vzzz|<br></br>
.`...|?>-.....`?>~rwrv?^. .``````````````````~?}v|.....^??||?z}?z}zz}?>vzz|<br></br>
 ...|?>`.....`?^``~~-``  ``..`-`......`````````->}v|``..^?>^?zzvrw}zz?>?wz|<br></br>
 ..~?>`......?|```````..`.->vr^.  `+-..``````````->}v>`..^?>?wzz}zrwzw>?rx|<br></br>
 .`>+`......>>```````..``?xyy+     ^x..-````^|`````->v}+`.+>>}zzwwzrzzv>rx|<br></br>
 .^?-...... `....````.``}yyyy~     ^+ .```|rw~````````^?v?>?>?wzzrr}rzw>wz|<br></br>
 `>>`...`.. ......``````?}zuyz|....`.|```-zu|````````````|>???vw}}>`-^wvzz|<br></br>
 +?>`..-`...``....```````.`~+?v>~`.`-w}zr}uv``````````~?>~`~|^+??v|-`-wzzz|<br></br>
.>>>-..^..... ..``````````````.``````}zuuuu>`?r}|`````~?|?`++^``-~^+^^vxzx|<br></br>
.>>?-.~^....`.``````.`--~````````````^xuxxx>wyuyz~````-v?+~->^-````````??r|<br></br>
.>>?|.+^....```````->>v?|````````````-zxxurvyuuuy?````-v~`-~+|~``......?vr|<br></br>
.>>?^`>^....??`````->|`.~~````````````>rzuv?uuuxw|````~>```+||```.....?xxx|<br></br>
.>>>>-?^....rz```````.`>?+-````````````^xuuzwxuw~```````......``.....|xzzz|<br></br>
.>>>>^>^....rx-``````-+?>+-`````````````}uuuxzxr```.......`~|~>v>....|x}wz|<br></br>
.?>>~>>+.`~.-r}-``````~|~`.`````````````+uuuuuxv.........+>?+|>vz}vvv}wvzz|<br></br>
.>>?-~?>`.+-..>w>```````.```````````````.>rzxxr-......`.-?>?^^>rzzzzzx}vzz|<br></br>
.>>?+.+?+.`+...+yr^`.```---``````````......`^}>.....-`~.|?>?|>?zzzzzzz?}zz|<br></br>
`?>>?^`>?|.``..`zyu}^-...````.............`+vw~.....^`^`>>>>^>vzzzzzzv?wzz|<br></br>
`w?>>?||?>^`....ruuxw}}|............``~^?vrwzv.....-^~^+>>>+>?wzzzzzw>vzzz|<br></br>
`zr?>>>^+>?>|`.`zuuzwwv`........?vv}}vwwwwwww-....`>|>>>??>>?vzzzzzw?vzzzz|<br></br>
`zzr?>>>+??>?>~.>rzww?..........|wwww}wzzxxx^....->>+>>?r??rwzzzzzw?}zzrzz|<br></br>
.rzz}>>>>?v?>>?+-`?v?............?zwwruuuuu>..`~^>>>>?vzv?wzzzzzzzvrxw?wzz|<br></br>
 -wzz}?>>>vw?>>?>^~>>|`...........vxzwuuuu>.-+>+^+>>?rzvvzzzzzzzz}wx}?rzzz|<br></br>
`|+zzzw?>>>rzv>>>?>+>?>>^-`........ruzuuuw|>>^-^>??rzwvrzzzwrzzzwzw?vzzzzz|<br></br>
`w?wzzzzv+>?zzr?>>>>>>>>+>>>+^^|||~vuwxxux?~``+?vwzzrvwzzzwvwzzzz}?rxzzzzz|<br></br>
`zzrzzzzz}++}zzzv?>>>>>>^-`-|^>>??>rxwxxxu?`|>?rzww}}wwwwr?}wwwzv|rzwwwwww~<br></br>


            </div>,

            <div>

`?vv>+++|`^+++++^++++++++++++^|||||^^+++++++++++++++++++?vvv??v}}vvvvvvvvv-<br></br>
`wz?>>?^`^??>>>>>>???>++>>?>>>+^|-`````~|+>>????>>>+>???>?}zwv?vwzzzzzzzzx|<br></br>
`r?>>>|.`>>>>>>>??>^|^+>>>>>>>>>>>+^|~``...`-|>>?>>>|~^>>>>?ww}??vzzzzzzzz|<br></br>
`?>>>~..~>>>??>>^~`|+>>>?vvv}}}}v>??+|-........`|>?>>^`-+>>>?rzwv??rzzzzzz|<br></br>
.>>>-..`>>??+|-`.`|^>v}wzzr?^|-```^>??vv?^`.......~>??>-.^????rzzr??vzzzzz|<br></br>
.>>`...+??+-.....~?rzzzzzzw^     ...``-~+?vv+`......|>?>|.^?v??wwzw??vzzzz|<br></br>
.>`...^?>|......>?vzzzzzw?.  ..```````````-^?}?~.....`+??|`>vr?}rwzr?>vzzz|<br></br>
.`...|?>-.....`?>~rwrv?^. .``````````````````~?}v|.....^??||?z}?z}zz}?>vzz|<br></br>
 ...|?>`.....`?^``~~-``  ``..`-`......`````````->}v|``..^?>^?zzvrw}zz?>?wz|<br></br>
 ..~?>`......?|```````..`.->vr^.  `+-..``````````->}v>`..^?>?wzz}zrwzw>?rx|<br></br>
 .`>+`......>>```````..``?xyy+     ^x..-````^|`````->v}+`.+>>}zzwwzrzzv>rx|<br></br>
 .^?-...... `....````.``}yyyy~     ^+ .```|rw~````````^?v?>?>?wzzrr}rzw>wz|<br></br>
 `>>`...`.. ......``````?}zuyz|....`.|```-zu|````````````|>???vw}}>`-^wvzz|<br></br>
 +?>`..-`...``....```````.`~+?v>~```-w}zr}uv``````````~?>~`~|^+??v|-`-wzzz|<br></br>
.>>>-..^..... ..``````````````.``````}zuuuu>`?r}|`````~?|?`++^``-~^+^^vxzx|<br></br>
.>>?-.~^....`.``````.`--~````````````^xuxxx>wyuyz~````-v?+~->^-````````??r|<br></br>
.>>?|.+^....```````->>v?|````````````-zxxurvyuuuy?````-v~`-~+|~``......?vr|<br></br>
.>>?^`>^....??`````->|`.~~````````````>rzuv?uuuxw|````~>```+||```.....?xxx|<br></br>
.>>>>-?^....rz```````.`>?+-````````````^xuuzwxuw~```````......``.....|xzzz|<br></br>
.>>>>^>^....rx-``````-+?>+-`````````````}uuuxzxr```.......`~|~>v>....|x}wz|<br></br>
.?>>~>>+.`~.-r}-``````~|~`.`````````````+uuuuuxv.........+>?+|>vz}vvv}wvzz|<br></br>
.>>?-~?>-.+-..>w>```````.```````````````.>rzxxr-......`.-?>?^^>rzzzzzx}vzz|<br></br>
.>>?+.+?+.`+...+yr^`.```---``````````......`^}>.....-`~.|?>?|>?zzzzzzz?}zz|<br></br>
`?>>?^`>?|.``..`zyu}^-...````.............`+vw~.....^`^`>>>>^>vzzzzzzv?wzz|<br></br>
`w?>>?||?>^`....ruuxw}}|............``~^?vrwzv.....-^~^+>>>+>?wzzzzzr?vzzz|<br></br>
`zr?>>>^+>?>|`.`zuuzwwv`........?vv}}vwwwwwww-....`>|>>>??>>?vzzzzzw?vzzzz|<br></br>
`zzr?>>>+??>?>~.>rzww?..........|wwww}wzzxxx^....->>+>>?r??rrzzzzzw?}zzrzz|<br></br>
.rzz}>>>>?v?>>?+-`?v?............?zwwruuuuu>..`~^>>>>?vzv?wzzzzzzzvrxw?wzz|<br></br>
 -wzz}?>>>vw?>>?>^~>>|`...........vxzwuuuu>.-+>+^+>>?rzvvzzzzzzzz}wx}?rzzz|<br></br>
`|+zzzw?>>>rzv>>>?>+>?>>^-`........ruzuuuw|>>^-^>??rzwvrzzzwrzzzwzw?vzzzzz|<br></br>
`w?wzzzzv+>?zzr?>>>>>>>>+>>>+^^|||~vuwxxux?~``+?vwzzrvwzzzwvwzzzz}?rxzzzzz|<br></br>
`zzrzzzzz}++}zzzv?>>>>>>^-`-|^>>??>rxwxxxu?`|>?rzww}}wwwwr?}wwwzv|rzwwwwww~<br></br>


            </div>,

            <div>

`?vv>+++|.^+++++^++++++++++++^|||||^^+++++++++++++++++++?vvv??v}}vvvvvvvvv-<br></br>
`wz?>>?^`|??>>>>>>???>++>>>>>>+^|-`````~|+>>????>?>+>???>?}zwv?vwzzzzzzzzx|<br></br>
`w?>>>|.`>>>>>>>??>^|^+>>>>>>>>>>>+^|~`....`-|>>?>>>|~^>>>>?ww}??vzzzzzzzz|<br></br>
`?>>>~..~>>>??>>^~`|+>>>?vvv}}}}v>>?+|-........`|>?>>^`-+>>>?rzwv??rzzzzzz|<br></br>
.>>>-..`>>??+|-`.`|^>v}wzzr?^|-```^>??vv?^`.......~>??>-.^????rzzr??vzzzzz|<br></br>
.>>`...+??+-.....~?rzzzzzzw^     ...``-~+?vv+`......|>?>|.^?v??wwzw??vzzzz|<br></br>
.>`...^?>|......>?vzzzzzw?.  ..```````````-^?}?~.....`+??|`>vr?}rwxr?>vzzz|<br></br>
.`...|?>-.....`?>~rwrv?^. .``````````````````~?}v|.....^??||?z}?z}zz}?>vzz|<br></br>
 ...|?>`.....`?^``~~-``  ``..`-`......`````````->}v|``..^?>^?zzvrw}zz?>?wz|<br></br>
 ..~?>`......?|```````..`.->vr^.  `+-..``````````->}v>`..^?>?wzz}zrwzw>?rx|<br></br>
 .`>+`......>>````````.``?xyy+     ^x..-````^|`````->v}+`.+>>}zzwwzrzzv>rx|<br></br>
 .^?-...... `....````.``}yyyy~     ^+ .```|rw~````````^?v?>?>?wzzrr}rzw>wz|<br></br>
 `>>`...`.. .......`````?}zuyz|....`.|```-zu|````````````|>???vw}}>`-^wvzz|<br></br>
 +?>`..-`...``....```````.`~+?v>~`.`-w}zr}uv``````````~?>~`~|^+??v|-`-wzzz|<br></br>
.>>>-..^..... ..``````````````.``````}zuuuu>`?r}|`````~?|?`++^``-~^+^^vxzx|<br></br>
.>>?-.~^....`.``````.`--~````````````^xuxxx>wyuyz~````-v?+~->^-````````??r|<br></br>
.>>?|.+^....```````->>v?|````````````-zxxurvyuuuy?````-v~`-~>|~``......?vr|<br></br>
.>>?^`?^....??`````->|`.~~````````````>rzuv?uuuxw|````~>```+||```.....?xzx|<br></br>
.>>>>-?^....rz```````.`>?+-````````````^xuuzwxuw~```````......``.....|xzzz|<br></br>
.>>>>^>^....rx-``````-+?>+-`````````````}uuuxzxr``........`~|~>v>....|x}wz|<br></br>
.>>>~>>+.`~.-r}-``````~|~`.`````````````+uuuuuxv.........+>?+|>vz}vvv}wvzz|<br></br>
.>>?-~?>-.+-..>r>```````.```````````````.>rzxxr-......`.-?>?^^>rzzzzzx}vzz|<br></br>
.>>?+.+?+.`+...+yr^`.```---``````````......`^}>.....-`~.|?>?|>?zzzzzzz?}zz|<br></br>
`?>>?^`>?|.``..`zyu}^-...````.............`+vw~.....^`^`>>>>^>vzzzzzzv?wzz|<br></br>
`w?>>?||?>^`....ruuxw}}|............``~^?vwwzv.....-|~^+>>>+>?wzzzzzr>vzzz|<br></br>
`zr?>>>^+>?>|`.`zuuzwwv`........?vv}}vwwwwwww-....`>|>>>??>>?vzzzzzw?vzzzz|<br></br>
`zzr?>>>+??>?>~.>rzww?..........|wwww}wzzxxx^....->>+>>?r??rwzzzzzw?}zzrzz|<br></br>
.rzz}>>>>?v?>>?+-`?v?............?zwwruuuuu>..`~^>>>>?vzv?wzzzzzzzvrxw?wzz|<br></br>
 -wzz}?>>>vw?>>?>^~>>|`...........vxzwuuuu>.-+>+^+>>?rzvvzzzzzzzz}wx}?rzzz|<br></br>
`|+zzzw?>>>rzv>>>?>+>?>>^-`........ruzuuuw|>>^-^>??rzwvrzzzwrzzzwzw?vzzzzz|<br></br>
`w?wzzzzv+>?zzr?>>>>>>>>+>>>+^^|||~vuwxxux?~``+?vwzzrvwzzzwvwzzzz}?rxzzzzz|<br></br>
`zzrzzzzz}++}zzzv?>>>>>>^-`-|^>>??>rxwxxxu?`|>?rzww}}wwwwr?}wwwzv|rzwwwwww~<br></br>


            </div>,

            <div>

`?vv>+++-`+++++^++++++++++++^~~~~~||^+++++++++++++||++++>v}v?>?v}vvvvvvvvv-<br></br>
`wzv>?>~`+??>>>>?????>++>?>>>>++^~-````-~^+>>?????>^-~+???vzzv??rzzzzzzzzw~<br></br>
`zv>?+`.~?>>>>>>??>^^+>>>>???????>+|~-``....`~^>??>?>-.->?>?rzw?>?rzzzzzzz|<br></br>
`?>?+`..+>>???>>^~~^+>?vv}rr}}vv>|?v?>^-`......`-^>???^``+?>?}zzrv?vzzzzzz|<br></br>
.>>^...-????+|-`.`^?vrzzwv>~`.   .~|+>?v?>|`......`+>??>~.^?>?rzzz}>vzzzzz|<br></br>
.?^...`>??+-....-?rzzzzzzz?.     ...````~^???~......-+??>|.|???wrzzv+?wzzz|<br></br>
.^...`>?>~.....>vrzzzzzwv~  ..`````````````-+vv+`.....|>??^.>r?vzrzzv>?wzz|<br></br>
 ...`>?^`....`v?|wzwrv?-  .````````.``````````^v}+.....->??^~ww?ww}ww?>?wz|<br></br>
 ...+?|.....`v^``|^|-`. .``.`-^+^|~....-````````^v}+-`..`>>>+rzr}zwrz}>>}x|<br></br>
 ..|?|......v^```````` .`.^vwx>`..-??`.```````````^vv?-..->>?wzz}wzzrz?+vz|<br></br>
 .`>~......??````````..``vuuy?     .x? ``````-|-````|v}?~.|?>}zzwwzxrz}>?z|<br></br>
 .~>.......-.....````.``}yyyy+     .?. .````+zv-``````~>vv>?>?zzzwrr}rz??x|<br></br>
 .>+...`..  ......`````~vwuyyu>...`-..+|~|`^u}-``````````~>???}zrvv~`~}vvx|<br></br>
 |?+..`~...```....````````-|>?}v>|```.?xzuwzx-`````````^?^``~|+??v>-``}wrx|<br></br>
.>?+..^`...`. .``````````````.````````+zxxuur-?}?-`````|?^+-^^|`-~^++^vzwx|<br></br>
.>>>.`+..... ``-````.`---``````````````?uxxurryuyr-````~v?+~~+|```..```+?v~<br></br>
.>>>`~>....`-``````~++v?|``````````````?xxuxwuuuuy>````|?-`-~^+```.... |??~<br></br>
.>>>-^>`..`vr-`````~>|`.`~`````````````|vzu}}uuuur-````+^``-++~```....~rxx|<br></br>
.>>?|^>`..|xx-``````...+?+~`````````````|xuuwwuxr````````............`wxzz|<br></br>
.>>>++?`...vy>```````-+?>+|``````````````}uuuzzx}```.......```^?>....`wz}w|<br></br>
.>>+|>?-.-``wy>```````>+|~```````````````>uuuuuzv.......`|+>>~>vzv????zw?z|<br></br>
.>>>-|?^.-+.`?x?````````...```````````````?zxxzr-.....`.~?>?+^>}zzxxxzzv?z|<br></br>
.>>>^.^?~.+^..?y}|````````````````````.....-+?}~.....-|.^?>?^+?wzzzzzzw?vx|<br></br>
`?>>>|`>>`.|`.>yyx?|``````````````.........`+}?......~|.>>>>^>vzzzzzzz?>}x|<br></br>
`v?>>>||?+`...?uuxrrr-..................`^?rww~....-`>||??>>+?rzzzzzzv>?zz|<br></br>
.v}>>>>+>?>^`~uuuzwrv..........`^^^^++?vrwwww+....`+^>|>>??>?wzzzzzz}>?wzz|<br></br>
.?wv>>>>>?>?>>wzzww>...........`}wwwwrwwwwwz>....->>>>>?vv>?zzzzzzzr?vzzwz|<br></br>
.>rz?>>>>?v>>??v}v>.............|wwwrrxuuuu?..`~+>>>>>?rr?}zzzzzzzw?}zzrzz~<br></br>
 ~vzw?>>>>v}?>??>~|>|-...........>xwrwuuuu?.-^>>^+>>?vzrvwzzzzzzzwvrxr?rzz~<br></br>
.`>wzw}?>+?ww?>?>>^+?>>^-`........?uxzuuur|>?+~~>??}wzrrzwrzzwrzw}zzv?}zzz|<br></br>
`>^vzzzwv>^vzw?>>>>>>>>>>>+^|~~---~zxzxxur>^``^?vwzzw}wzvvwzwrzzwz}?}wwzzz|<br></br>
`?>?zzzzzw?^vzzv?>>>>>>+~~^>>>>>???zxwzxxx+.`^?vwwwr}wr??www}wwwzv?ww}wwww~<br></br>


            </div>,

            <div>

`?v}?>>>~->>>>>>>>>>>>??>??>+^^|^^^+>>>?????>??>>>^|+??>?v}v?>v}}}}}}}}}vv-<br></br>
`wx}???^`?vv?????????????vv?????>^|---~|^+>??vv????+-^???vrxx}?vwxxxxxxxxw~<br></br>
`zv???~`^v??????vv?>>???????vvvv??>+^|~-```-~^>??????~.~???vwxzv?vwxxxxxzx|<br></br>
`v??>-``>??vvv??>||>??vv}}rr}}vv>|?v?>+~```````-|>?vv?>``>???rxxwvvrxxxxxx|<br></br>
`vv>```|vvvv?+~`.->?vrzzwv>~`.   .~|+>?vv?^-``````~>????~.^???wxxxr?}xxxxx|<br></br>
`v>```~?vv?|```.~?rzzzwzzz?.     ...````~^???|``````~????^.^vv?zwzxr?}zxxx|<br></br>
`>```-?v?^`````?v}zzzzzwv~  ..`````````````-+vv>``````^???+.?w?}zrzx}?vzxx|<br></br>
.-``-?v>-````-v?|wzwrv?-  .````````.``````````^v}>````.-???+|wwvzzrzzv?}zx|<br></br>
.```>v+`````-v^``|^|-`. .``.`-^+^|~....-````````^v}+~~``-???>wxrrxzwzr??wx|<br></br>
 ``+v^`````-v^```````` .`.^vwx>`..-??`.```````````^v}v~..~???wzxrzxzwzv?}x|<br></br>
 `~?^``````??````````..``vuuy?     .x? ``````-|-````|?}?|.^??rxxzzxxwxr?vx|<br></br>
 `^?``````.-.....````.``}yyyy+     .?. .````+zv-``````~>vv?v?vzxxzrr}wzvvx|<br></br>
 -??```-``  ......`````~vwuyyu>...``..+|~|`^u}-``````````~+?vvrzw}v~`~}}}x|<br></br>
 +?>``~|``````....````````-|>?}v>|```.?xwuwzx-`````````^?^``~|+??v>-``}zrx|<br></br>
.??>``+~```.. .``````````````.````````+zxxuur-?}?-`````|?^+-+^|`-~^++|vzzu|<br></br>
.???`->````. ```````.`---``````````````?uxxurwyuyr-````~v?^~~+|```..```+v}~<br></br>
.???`|?`````-``````~++v?|``````````````?xxuxwuuuuy>````|?-`-~^^```.... |??~<br></br>
.?vv~+?`..-vr-`````~>|`.`~`````````````|vzu}}uuuur-````+^``-++~```....~wxx|<br></br>
.vvv+>?-..^xx-``````. .+?+~`````````````|xuuwwuxr````````............`wxzz|<br></br>
`v???>?-```vy>```````-+?>+|``````````````}uuuzzx}```.......`-`>?>....`wx}w|<br></br>
.??>^??~.~-`wy>```````>+|~```````````````>uuuuuzv.......`^???^?vxv????zzvz|<br></br>
`???~+v>`~?`-?x?````````...```````````````?zxxzr-....`-`+vvv?>vrxxxxxxz}vx|<br></br>
.???>`>v^`?>`.vy}|````````````````````.....-+?}~...``~^`>vvv>?vzxzzzzzz?}x|<br></br>
`v???+-??-`+-`>yyx?|``````````````.........`+}?.`````^+-?vvv>?}xzzzzzzv?rx|<br></br>
`rvv?v++v?~```vuuxrrr~..................`^?rww~````~~?++vvv??vwzzzzzz}?vzz|<br></br>
.}rvv?v??vv>-|xuuzw}v````````..`^^^^++?vrwwww>```.->+?+vvvv?vwzzxzzz}?vzzz|<br></br>
.vzrv??v?vvv??wzzwr>```````--``-}wwwwrwwwwwz?````~?????v}v?vzxzxzzxw?}zzzx|<br></br>
.?wzv????vv????v}v>`.```````````^wwwrrxuuuu?``-^>?vvv?vwrvrzzzzzzzw?rxzrzz~<br></br>
 +}xzv????}r?????|+?+~```````````?xwrwuuuu?`|>??>??vvrzw}wxxzzzzzwvrxr?rzz~<br></br>
.~?zxzrv??vzwv????>?v??+|-````````?uxzuuur+?v?^^?vvrzxwwxzwxxzwzw}zzv?}zzx|<br></br>
`?>rxxxzr?+vxzv????????????>+^^^^^|zxzxxur?>~`>?}wzxzrzz}}zxzwzzwz}?}wwzzx|<br></br>
`v?vzxxxxzv>}xzrv???????^^>????????zxwzxxx+`->?}wzww}wr?vwzwrwwwzv?ww}wwwz~<br></br>


            </div>,

            <div>

.?v}????^~???????????????????>>++>>>????????????>>^^>????v}v??v}}}}}}}}}vv~<br></br>
`wxrv}v>|v}}vvvvvvvvvvvv}}}}vvv???>^^^++>??vvr}v???>|>?vvvwxxrvrzxxxxxxxxz~<br></br>
`zrvvv^~>}vvvvvvvvv??vvvvvvvv}}vvv??>++^|~||^>?vvvvvv^`|?vv}zxz}vrzxxxxxzx|<br></br>
`rvv?|~~?vvv}}}v?>+?vvvv}rrr}}vv>|?vv?>^|~|||||^>?v}vv?-->vvvwxxw}}wxxxxxx|<br></br>
`}}?|~-+}}}}v?+|-|??vrzzwv>~`.   .~|+>?vv?+|~~~~||+?vvv?^`>vvvwzxxwvrxxxxu|<br></br>
`}?|~-^v}}v+|~~-^?rzzzwzzz?.     ...````~^?v?^-~||||+?vvv+`>vvvwwxxwvrxuxx|<br></br>
`?|~~^v}v>|~~~|?v}zzzzzwv~  ..`````````````-+v}>~~~~~~>vvv>-?wvrxwxxrvrxxx|<br></br>
.^|~^v}?^~||~^v?|wzwrv?-  .````````.``````````^v}?~~~~-|?vv>+zz}xxzxx}vrxu|<br></br>
.^|~?}?~~|||^v^``|^|-`. .``.`-^+^|~....-````````^v}>^^~-|?vv?wxwwxxzxzv}zu|<br></br>
.||>}>~~~|||v^```````` .`.^vwx>`..-??`.```````````^v}v^--^vvvwzxwzxxwxrvwx|<br></br>
.|^v>||||||??````````..``vuuy?     .x? ``````-|-````|?r?^->vvwzxzzxuzxwvru|<br></br>
.|>v^||||~`-.....````.``}yyyy+     .?. .````+zv-``````~>vv?}}}xxxzwwrwx}ru|<br></br>
.^vv^||^|~. ......`````~vwuyyu>...``..+|~|`^u}-``````````~+?vvrxw}v~`~rrru|<br></br>
.>v?||^>~~````....````````-|>?}v>|```.?xwuwzx-`````````^?^``~|+??v>-``}zwu|<br></br>
.?v?||?^|^-.. .``````````````.````````+zxxuur-?}?-`````|?^+-+^|`-~^++|vxzu|<br></br>
.vv?|^?|~|`. ```````.`---``````````````?uxxurwyuyr-````~v?^~~+|```..```+v}~<br></br>
`vvv|>v~-~~`-``````~++v?|``````````````?xxuxwuuuuy>````|?-`-~^^```.... |??~<br></br>
`v}}^?v~`-|vr-`````~>|`.`~`````````````|vzu}}uuuur-````+^``-++~```....~wxu|<br></br>
`}}}>?v|--+xx-``````. .+?+~`````````````|xuuwwuxr````````............`wxzz|<br></br>
`}vv??v^-~~vu>```````-+?>+|``````````````}uuuzzx}```.......`~~?v>....`wxrw|<br></br>
`vv?>vv^-^^|wu>```````>+|~```````````````>uuuuuzv.......->?}}>}rxv????zzvz|<br></br>
`vvv+?}?~+v||vx?````````...```````````````?zxxzr-....`||?rrrv?}wxxxxxxzr}x|<br></br>
`vvv?|?}>~??|~vy}|````````````````````.....-+?}~.``-~^?|?r}rvvrzxxxxzxzv}x|<br></br>
`}}}}?^vv||?^~?uux?|`.````````````.........`+}?`~~~~~>?^v}}}?vrxxxxzxz}vrz|<br></br>
`w}}}}??}v+~|~vuuxrr}|`.................`^?rww^-~~-^+???}}}}v}wxxxzzz}v}zz|<br></br>
`ww}}}}vv}}?^+xuuzw}v|||~~--````^^^^++?vrwwww>~~~-^??v?}}}}v}zxxxxzzrv}zzx|<br></br>
`rzw}}}}v}}}v?rzzwr>~~~~~||^^^||}wwwwrwwwwzz?~~~|+vvvvv}rrv}zxxxxxxwv}zxzx|<br></br>
`}zxr}vvv}rvvvvv}}>---~~|||||||~+wwwrrxuuuuv||^>?v}}}}rzw}wzxxxxzzwvrzzwzz~<br></br>
.?wxzr}vvvrwvvvvv+>v>^~~~~|||||^|?xwrwuuuuv|>?v}vv}}rwxzrzxxxxxzxwvrxr?wxz~<br></br>
.+}zxxw}vv}zz}vvvv?v}vv?+|~~||||^|vuzzuuur?v}?>?v}rwxxzzxzzxxzwzwrzzv?}zzx|<br></br>
`vvwxxxxwv?rxz}vvvvvvvvvvv??>>>>>>>zxzxxur??+|?vrwxxxzxxrrxxzwzzwz}vrzzzxx|<br></br>
`}v}xxxxxz}?rxxw}vvvvvvv>>?v}v}}vvvzxwzxxx>~^?v}zzzwrwrvvzzwrwwwwv?wwrwwzz~<br></br>


            </div>,

            <div>

`v}}}}}}vv}}}vvvvvvvvvvvvv}}}}}}}}}}}}}}}}}rrrr}vvvvv}rrrrr}}}}}}}}}}}}}}}-<br></br>
                  `xxxxxxxxxxxxzzzzzzzzzxxxxxxxxxxxxxxxxxxxxuuuyuzwwwwzuuxxxuxxxxxxxxxxxxxxx|<br></br>
                  `xxxxxxxxxxxxzzzzzzzzxxxzzzzzzzzzxzzxxxxxxuuuuuxzzwzxxzwwwzxxxxxxxxxxxxxxx|<br></br>
`xuuuuxxxxxxxxxxzzzzzzwrwrrr}}vv?^vwwzzzxuuuuuuuuuuuxwwwwrwwwwwzxxxxxxxxxx|<br></br>
-uuuuuuuuuuuuxxxxzwwwwwzwv>~`.   .-|^>?vrwzxuuxuuuuuzwwwrrrrrrrrzxxxxxxxxx|<br></br>
                  -uuuuuuuuuuuuuuxwrwwzwwzzz?.     ...````-^?vwzxuuuuuxwrrrrrrrrrrzxxxxxxxxx|<br></br>
-uuuuuuuuuuuuuzwv}zzzzzwv~  ..`````````````-^vwzxuuxxzwrrrrrrrrzxuxxxxxxxx|<br></br>
-uuuuuuuuuuuuzr>|wzwrv?-  .````````.``````````^vwwzxxxzrrrrrrrwzxxxxxxxxxx|<br></br>
-uuuuuuuuuuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzxxzwwwwwwwwwzxxxxxxxx|<br></br>
-uuuuuuuuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzxzwwwwrrwwwzxxxxzzx|<br></br>
-uuuuuuuuuuw>````````..``vuuy?     .x? ``````-|-````|?rwwwwwwwwwwzxxxxzzzx|<br></br>
-uuuuuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxxxxxzrrrzxzzz|<br></br>
-xuuuuuuur` ......`````~vwuyyu>...``..+|~|`^u}-``````````~+?vrzxwvv~`~}xzz|<br></br>
`zxxxxxxxw+```....````````-|>?}v>|```.?xwuwzx-`````````^?^``~|+??v>-``}xzz|<br></br>
`zzzxxxxxy>.. .``````````````.````````+zxxuur-?}?-`````|?^+-+^|`-~|++|vxxx|<br></br>
`zzzzxxxxx?. ```````.`---``````````````?uxxurwyuyr-````~v?^~~+|```..```>vv~<br></br>
`wwwzzzwwww~```````-++v?|``````````````?xxuxwuuuuy>````|?-`-~^^```.... |??~<br></br>
`wzwwwrr}rwvr-`````~>|`.`~`````````````|vzu}}uuuur-````+^``-++~```....-rzw~<br></br>
`wwwwwwrrw}zx~``````. .+?+~`````````````|xuuwwuxr````````............`}r}r~<br></br>
`wwwwwwwwwwwu>```````-+?>+|``````````````}uuuzzx}```.......`|?vr>.....}}}r~<br></br>
`wwrwwwwwwzwzu>```````>+|~```````````````>uuuuuzv......`|?}wzzzzz???>?}}}}~<br></br>
`zwwwzzzzzzzwzx?````````...```````````````?zxxzr-...-+vwzzzzwwwwrrrrrr}}v}~<br></br>
`zzzzxzzzzzzxzwu}|````````````````````.....-+?}~`^?vrwwwwwwwwwwrr}}}}}vvv}~<br></br>
`zzzxxxzzzzxxxzuyx?|`..```````````.........`+}vv}wwrrrrwwwwwwrrr}}}}vvvvv}~<br></br>
`zzxxxxxxxxxxxzuuxrr}?+-`...............`^?rwr}wrrrrrrrwrrrrrrrrr}}vvvvv}r~<br></br>
`xxxxuuuuuuxxwxuuzw}rxuuzrv?>>+||^^^++?vrwwwr}wwrrrrrrrr}}rrrrrrrrr}vv}}rw~<br></br>
`xxxxuuuuuuxxwwzzwrvzuxxuuyyyyuzrwwwwrwwwwwwrzzwwwwwwwrr}}}}}rrrr}}}vvvrrw~<br></br>
`xxxxuuuuuxxxxwrrrrzxxxxxuuuuuuurrwwrrxuuuuwxuxxxzzzzwrr}}}}}rrrvvvvvvv}}r~<br></br>
`xxxuuuuuuxxxxxxzzxxxxxxxxxxxuuuxwzwrwuuuuwxuuuuxxxxzzzwr}}}rr}}vvvvvvv}}r~<br></br>
`xuuuuuuuuxxxxxxxxxxxzzzzzzxxxxuuxwuzzuuuzxuuuuuxxxxxzzzwrrrrr}vv????vv}}r~<br></br>
`xuuuuuuuuuuuxxxxxxzzzzzzzzzzzxxxxzzxzxxuzxuxxxxxxxxxxzzzwwwwr}}vv?vvv}rrr~<br></br>
`xuuuuuuuuuuuxxxzzzzzzzzzzzzwwwwwzrzzwzxxxrzzzzzzzzzzzzwwwwwrr}}vvvv}}rrrw~<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}}}vvvvvvv}}}}}}}}}}}}}}}}}}}}}rrrrvvvvvv}rrrrr}}}}}}}}}}}}}}}~<br></br>
`xxxxxxxxxxxxxzzzzzzxxxxxxxxxxuuuuuxxxuuxxuuuyuw}}}}rxxxxxuxxxxxxxxxxuuuuu|<br></br>
                  `xxxxxxxxxxxxxzzzzzzxxxxzzzzzzzzxxzzxxxxxxuuuuuxwrrwxzwrrrzxxxxxxxxxxuuxxu|<br></br>
-xuuuuxxxxxxxxxxzzzzzzwwwrrrr}vv?^vwwzzzxuuuuuuuuuxuzrrr}}rwwwwzxxuuuuuuxu|<br></br>
-uuuuuuuuuuxxxxxxzwwwwwzwv>~`.   .-|^>?vrwzxuxxuuuuuzr}}}}}}}}r}zuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxwrwwzzwzzz?.     ...````-^?vwzxuuuuuxr}}}}}}}}}}zuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuzwv}zzzzzwv~  ..`````````````-^vwzxxxxxz}}}}}}}}}zuuuuuuuuuu|<br></br>
-uuuuxxuuuuuuzr>|wzwrv?-  .````````.``````````^vwwzxxzw}}}}}}}wxxxuuuuuuuu|<br></br>
-uuuxxxxuuuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzxzwwrrrrrrwwzxxuuuuuu|<br></br>
-uuuxxuuuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzxzwrrrrrrwwxuuuuxxu|<br></br>
-uuuuuuuuuuw>````````..``vuuy?     .x? ``````-|-````|?rwwwwrrrrrwxuuuuxxxu|<br></br>
-uuuuuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxxxxxzwwwxxxxx|<br></br>
-xuuuuuuur` ......`````~vwuyyu>...`-..+|~|`^u}-``````````~+?vwzxw}v~`~ruxx|<br></br>
`zxxxxxxuw+```....````````-|>?}v>|```.?xwuwzx-`````````^?^``~|+??v>-``}uxx|<br></br>
`zzzxxxxxy>.. .``````````````.````````+zxxuur-?}?-`````|?^+-+^|`-~|++|vuuu|<br></br>
`zzzxxxxxu?. ```````.`---``````````````?uxxurryuyr-````~v?^~~+|```..```>v}|<br></br>
`zzzzzzwwww~```````~++v?|``````````````?xzuxwuuuuy>````|?-`-~^^```.... |?v~<br></br>
`zxzzwr}}rwvr-`````~>|`.`~`````````````|vzu}}uuuxr-````+^``-++~```....-rxx|<br></br>
`zzzwwwrrw}zx-``````...+?+~`````````````|xuuwwuxr````````............`rzww~<br></br>
`zzwwwwwwzwwu>```````-+?>+|``````````````}uuuzzx}```.......`^?vw>.....rwww~<br></br>
`wwwwwwwwzzwzu>```````>+|~```````````````>uuuuuzv......`|?rzuuxxxv????rrrw~<br></br>
                  `zzzzzxxxxzxzzx?````````...```````````````?zxxzr-...-+vzuuxxxxxzzzzzzwrrrr~<br></br>
`xxxxxxxzzzxxzwu}|````````````````````.....-+?}~`^?}wzxxxxxxxxzzzwwwrrrr}r~<br></br>
`xxxxxxzzzzxxxzuux?|`..```````````.........`+}vvwzzwwwzzxxxzzzzzwwwrrr}}}r~<br></br>
`xxuuuuuxxxxuxzuuxrr}?+-`...............`^?rwr}zwwwwwwzzzzzzzzzzwwrr}}}}rw~<br></br>
`xuuuuuuuuuxxwxuuzw}rxuxzrv?>>+||^^^++?vrwwwr}wwwwwwwzzzwwzzzzzzzwwwrrwwzz|<br></br>
`xuuuuuuuuuxxwwzzwrvzxxxuuyyyyuzrwwww}wwwwwwrzxzzzzzzzzwwwwwwzzzwwww}}rwzz|<br></br>
`xxxuuuuuuxxzzwrrrrzxxxxuuuuuuuurrwwrrxuuuuwxuuuuxxxxxzwwrrwwzzz}}}}}v}wwz~<br></br>
`xuuuuuuuuxxzxxzzzzzzzzxxxxxuuuuuwzwrwuuuuwxuuuuuuuuuxxxwwwwzzww}vvvvv}wwz~<br></br>
-xuuuuuuuuxxxxzzzzzxxzzzxxxxxuuuuxzuzzuuuzxuuuuuuuxuuxxxzzzzzzwrvvvvvv}wwz~<br></br>
-xuuuuuuuuuuuxxxzxxxxxxxxzzzxxxuuuzzxzxxuzxuxxxxxxxxxxxxxxxzzzwwvvvv}rwzzz~<br></br>
`xuuuuuuuuuuuuxxxzxxxxxxxzzzzzzzzxwzzwzxxxrzzzzzzzzzzzzzzzzzzwrr}}}rrwwwww~<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}}}vvvvv}}}}}}rrrrrrrrrrr}}}}}}rrrrvvv???}rrrrrrr}}}rrrrrrrrrr~<br></br>
`xxxxxxxxxxxxxzzzzzzxxuuuuuuuuuuuuuxxxuuxxuuyyu}vvvv}xxxxxuuuxxxxxxuuuuuuy^<br></br>
`xxxxxxxxxxxxxzzzzzzxuuxxzzzzzzzxxzxxxxxxuuuuuuxr}vrzwr}}rwxxxxxxxxxuuuuuu|<br></br>
-xuuuuxxxxxxxxxzzzzzzzzwwrrrr}vv?^vwwzzzxuuuuuuuuuxuz}}}}v}rrrwzxuuuuuuuuu|<br></br>
-uuuuuuuuuuxxxxxxzwwwwwzwv>~`.   .-|^>?vrwzxxxxuuuuuw}}vvvvvv}}}zuuuuuuuuu|<br></br>
-uuuuuuuuuuuuxxxwrwwzzwzzz?.     ...````-^?vwzzxuuuuz}vvvvvvvvvvzuuuuuuuuu^<br></br>
-uuuuxxxxuuuuuzwv}zzzzzwv~  ..`````````````-^vwzxxxxxw}vvvvvvv}zuuuuuuuuuy^<br></br>
-uuuxxxxxxuuuzr>|wzwrv?-  .````````.``````````^vwwzxzzw}vvvvv}wxxuuuuuuuuy^<br></br>
-uuuxxxxxuuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzxzwr}}}}rrrwzxuuuuuuu|<br></br>
-uuuxxxuuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzzzr}}}}}rrwxuuuuuuu|<br></br>
-uuuuuuuuuuw>```-````..``vuuy?     .x? ```````~-````|?rwwrrrrrrrwxuuuuuuuu|<br></br>
-uuuuuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxxxxuxwwwxuuuu|<br></br>
-uuuuuuuur` ......`````~vwuyyu>...`-..+|~|`^u}-``````````~+?vwzuw}v~`~ruuu|<br></br>
`zzxxuuuuw+```....````````-|>?}v>|```.?xwuwzx-`````````^?^``~|+??v>-``ryuu|<br></br>
`zzxxxxuuy>.. .``````````````.````````+zxxuur-?}?-`````|?^+-+^|`-~|++|vuyy^<br></br>
`wzzxxxxxu?. ```````.`---``````````````?uxxurruuyr-````~v?^~~+|```..```>v}|<br></br>
`zzzxxzwrww~```````~++v?|``````````````?xzuxwuuuuy>````|?-`-~^^```.... |?v|<br></br>
`xxxzwr}v}wvr-`````~>|`.`~`````````````|vzu}}uuuxr-````+^``-++~```....~wuu|<br></br>
`xxzzwrr}wrzx-``````. .+?+~`````````````|xuuwwuxr````````............`wxzz|<br></br>
`zzzwwwwwzwwu>```````-+?>+|``````````````}uuuzzx}```.......`^?}z>.....wzzz|<br></br>
`zzwwwwwwzxwzu>```````>+|~```````````````>uuuuuzv......`^?rxuyuuuv????wzww~<br></br>
                  `xxzxxxxxxxxzzx?````````...```````````````?zxxzr-...-+vxuyuuuuuxxxxxxzwwww~<br></br>
-uuuuuuxzzzxxxwu}|````````````````````.....-+?}~`^?}zxuuuuuuuuuxxxzzzwwwww~<br></br>
-xuuuuxzzzzxxuzuux?|`..```````````.........`+}vvwzzzzzxuuuuuuuxxzzzzwwwrrw~<br></br>
-uuuuuuuxxxuuxzuuxrr}?+-`...............`^?rwr}zzzzzzzxuuuuxxxxxxzzwwwwwwz|<br></br>
-uuuuuuuuuuuxwxuuzw}rxuxzrv?>>+||^^^++?vrwwwr}zzzzzzzxxxxxxxxxxxxzzzwwzxxx|<br></br>
                  `xuuuuuuuuuxxrwzzwrvzxxxuuuyyyuzrwwwwrwwwwwwrxxxzzzxxxxxzzzzzxxxzwzzrrzxxx|<br></br>
`xuuuuuuuuxzzzwrrrrzzzzxuuuuuuuurrwwrrxuuuuwuuuuuuuuuuxxzzzzxxxz}v}rr}rzxx|<br></br>
-xuuuuuuuuxzzzzzzzzzzzzxxxxuuuuuuwzwrwuuuuwxuuuuuuuuuuuuzzzxxxxzrvv}}}rzxx|<br></br>
-uuuuuuuuuxxxzzzzzzzxzzxxxxxxuuuuuzuzzuuuzxuuuuuuuuuuuuuuxxxxxzw}}}v}}wzxx|<br></br>
-uuuuuuuuuuuuxxxzzxxxxxxxxxxxuuuuyxzxzxxuzxxxxxxxxxuuuuuuuuuxxzzr}}}rwzxxx|<br></br>
                  -uuuuuuuuuuuuuxxxxxxxxxxxxxxxzzxxxwzzwzxxxrzzzzzzzzzzzzzzzzxzzwwwrrwwzzzzz~<br></br>


            </div>,

            <div>

`v}}}}}}}}}}}}}vvvvv}}}}rrrrrrrrrrrrrr}}}}}rrrrv?????}}}rrrrrr}}rrrrrrrrrw~<br></br>
                  `xxxxxxxxxxxxxzzzzzzxxuuuuuuuuuuuuuuxxuxxxuuyyxv????vxxzzxxuuxxxxxuuuyyyyy^<br></br>
`xxxxxxxxxxxxzzzzzzzxuuuxzzzzzzzxxxxxxxxxuuuuuuz}vvvzw}}}}wxxxxxxxxuuuyyuu^<br></br>
-uuuuuxxxxxxxxxzzzzzzzzwwrrrr}vv?^vwwwzzxuuuuuuuuuxxzvvvvv}rrrrzxuuyyyyyyy^<br></br>
-uuuuuuuuuuxxxxxzzwwwwwzwv>~`.   .-|^>?vrwzxxxzxuyuuwvvvvvvvv}}}zuuyyyyyyy^<br></br>
-uuuuuxuuuuuuxxxwrwwzzwzzz?.     ...````-^?vwwzxuuuuz}vvvvvvvvvvzuuyyyyyyy^<br></br>
-uuuxxxxxuuuxuzwv}zzzzzwv~  ..`````````````-^vwzzxxzzwvvvvvvvvvwuyuuuyyyyy^<br></br>
-uuuxxxxxxuuuzr>|wzwrv?-  .````````.``````````^vwwzzzzrvvvvvvvwxxuuuyyyyyy^<br></br>
-uuxxxxxxuuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzzzw}}}}}}}rrwuuyyyyyy^<br></br>
-uuuxxxxuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzzw}}}}}}rrwuuyyyyuy^<br></br>
-uyuuuuuuuuw>````````..``vuuy?     .x? ```````~-````|?rwwrr}}}}rwxuyyyuuuy^<br></br>
-uyyyuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxuuuuxzwzuuuuy^<br></br>
-uuuuuuuur` ......`````~vwuyyu>...`-..+|~|`^u}-``````````~+?vwxuz}v~`~wyuy^<br></br>
`zzxxxxuuw+```....````````-|>?}v>|```.?xwuwzx-`````````^?^``~~+??v>-``ryuy^<br></br>
`wzzxxxuuy?.. .``````````````.````````+zxxuur-?}?-`````|?^+-^^|`-~|++|}yyy^<br></br>
`wzzxxxxxu?. ```````.`---``````````````?uxxurruuyr-````~v?^~~+|```..```>}r|<br></br>
`zzxxxzrrww~```````~++v?|``````````````?xxuxwuuuuy>````|?-`-~^^```.... |vv|<br></br>
`xuxzw}vv}wvr-`````~>|`.`~`````````````|vzu}}uuuxr-````+^``-++~```....~zyy|<br></br>
`xxzzwr}}wrzx-``````. .+?+~`````````````|xuuwwuxr````````............`zuxx|<br></br>
`zzwwwwwwzzwu>```````-+?>+~``````````````}uuuzzx}```.......`^?}z?....`zxxx|<br></br>
`zwwwwwwwzuzzu>```````>+|~```````````````>uuuuuzv......`^?wuyyyyu}v???zzzz|<br></br>
-xxxxxxxxxxxzzx?````````...```````````````?zxxzr- ..->}xyyyyyyuuuuuuuxzzzz|<br></br>
-uuuuuuxzwzxuxwu}|````````````````````.....-+?}~`^?}zxuyyyyyyyuuuuxxzzzwww|<br></br>
-uuuuuuzwwzxuuzuux?|`..```````````.........`+}vvwzzzzzxuyyyyuuuuxxxzzzwwww~<br></br>
-uuuuuuuxxuuuxzuuxrr}?+-`...............`^?rwr}zzzzzzzxuuuuuuuuuxxzzwwwwzx|<br></br>
-uyuuuuuuuuuxwxuuzw}rxuxzrv?>>+||^^^++?vrwwwr}zzzzzzzxuuuuuuuuuxxxxzwzxuuu|<br></br>
                  -uuuuuuuuuuxzrwzzwrvzxxxuuuyyyuzrwwwwrwwwwwwrxxxzzxxuuuuxxxxxuuxzwzzwwxuuu|<br></br>
-xuuuuuuuuxzzzwrrrrwzzzxuuuuuuuurrwwrrxuuuuwuyuuuuyyyyuuxzxxxuux}vvrrrwxxu|<br></br>
-uuuuuuuuuxzzzzzwwzzzzzxxxxuuuuyuwzwrwuuuuwxuuuuuyyyyyyuxxxuuuxxrvv}}}rxxu|<br></br>
-uuuuuuuuuxxzzzzzzzzzzzxxxxxuuuyyuzuxzuuuzxuuuuuuuuuuyyuuuuuuuxzr}}}}rzxxu|<br></br>
-uuuuuuuuuuuuxxzzzxxxxxxxxxxxuuyyyxzxzxxuzzxxxxxxxxuuuuuuuuuuuxxr}}rwzxxxu|<br></br>
                  -uuuuuuuuuuuuuxxxxxxxxuuuxxxxxxxxuwzzwzxxxrzzzzzzzzzxxxxxxxxxxzzwwwwzxxxxz~<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}}vvvvv}}}}rrrrrrrrrrrrrr}}}}}rrwrv?????}}}rrrrr}}}rrrrrrrrrw~<br></br>
                  `xxxxxxxxxxxxxzzzzzzxxuuuuuuuuuuuuuxxxuxxxuuyyxv????vxxzzxxuuxxxxxuuuyyyyy^<br></br>
`xxxxxxxxxxxxzzzzzzzxuuuxzzzzzzzxxxxxxxxxuuuuuuz}vvvzw}}}}wxxxxxxxxuuuyuuu^<br></br>
-uuuuuxxxxxxxxxzzzzzzzzwwrrrr}vv?^vwwzzzxuuuuuuuuuxxzvvvvvvrrrrzxuuyyyyyyy^<br></br>
-uuuuuuuuuuxxxxxzzwwwwwzwv>~`.   .-|^>?vrwzxxxzxuyuuwvvvvvvvv}}}zuuyyyyyyy^<br></br>
-uuuuuxuuuuuuxxxwrwwzzwzzz?.     ...````-^?vwwzxuuuuz}vvvvvvvvvvzuuyyyyyyy^<br></br>
-uuuxxxxxuuuxuzwv}zzzzzwv~  ..`````````````-^vwzzxxzzrvvvvvvvvvwuyuuuyyyyy^<br></br>
-uuuxxxxxxuuuzr>|wzwrv?-  .````````.``````````^vwwzzzzrvvvvvvvwxxuuuyyyyyy^<br></br>
-uuxxxxxxxuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzzzw}}}}}}}rrwuuyyyyyy^<br></br>
-uuuxxxxuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzzw}}}}}}rrwuuyyyyuy^<br></br>
-uyuuuuuuuuw>```-````..``vuuy?     .x? ```````~-````|?rwwrrr}}}rwxuyyyuuuy^<br></br>
-uyyyuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxuuuuxzwzuuuuy^<br></br>
-uuuuuuuur` ......`````~vwuyyu>...`-..+|~|`^u}-``````````~+?vwxuz}v~`~wyuy^<br></br>
`zzxxuxuuw+```....````````-|>?}v>|```.?xwuwzx-`````````^?^``~~+??}>-``ryuy^<br></br>
`wzxxxxuuy?.. .``````````````.````````+zxxuur-?}?-`````|?^+-^^|`-~|++|}yyy^<br></br>
`wzzxxuxxu?. ```````.`---``````````````?uxxurruuyr-````~v?^~~+|```..```>}r|<br></br>
`zzxxxzwrww~```````~++v?|``````````````?xxuxwuuuuy>````|?-`-~^^```.... |vv|<br></br>
`xuxzw}vv}wvr-`````~>|`.`~`````````````|vzu}}uuuxr-````+^``-++~```....~zyy|<br></br>
`xxzzwr}}wrzx-``````. .+?+~`````````````|xuuwwuxr````````............`zuxx|<br></br>
`zzwwwwwwzzwu>```````-+?>+~``````````````}uuuzzx}```.......`^?}z?....`zxxx|<br></br>
`zwwwwwwwzuzzu>```````>+|~```````````````>uuuuuzv......`^?wuyyyyu}v???zzzz|<br></br>
-xxzxxxxxxxxzzx?````````...```````````````?zxxzr-...->vxyyyyyyuuuuuuuxzzzz|<br></br>
-uuuuuuxzwzxuxwu}|````````````````````.....-+?}~`^?}zxuyyyyyyyuuuuxxxzzwww|<br></br>
-uuuuuuzwwzxuuzuux?|`..```````````.........`+}vvwzzzzzxuyyyyyuuuxxxxzzwwww~<br></br>
-uuuuuuuxxxuuxwuuxrr}?+-`...............`^?rwr}zzzzzzzxuuuuuuuuuxxzzzwwwzx|<br></br>
-uyuuuuuuuuxxwxuuzw}}xuxzrv?>>+||^^^++?vrwwwr}zzzzzzzxuuuuuuuuuxxxxzzzxuuu|<br></br>
                  -uuuuuuuuuuxzrwzzwrvzxxxuuuyyyuzrwwwwrwwwwwwrxxxzzxxuuuuxxxxxuuxzwzzwwxuuu|<br></br>
`xuuuuuuuuxzzzwrrrrwzzzxuuuuuuuurrwwrrxuuuuwuyuuuuyyyyuuxzxxuuux}vvrrrwxxu|<br></br>
-uuuuuuuuuxzzzzzwwzzzzzxxxxuuuuyuwzwrwuuuuwxuuuuuyyyyyyuxxxuuuuxrvv}}}wxxu|<br></br>
-uuuuuuuuuxxzzzzzzzzzzzxxxxxuuuyyuzuxzuuuzxuuuuuuuuuuyyyuuuuuuxzr}}}}rzxxu|<br></br>
-uuuuuuuuuuuuxxzzzxxxxxxxxxxuuuyyyxzxzxxuzzxxxxxxxuuuuuuuuuuuuxxw}}rwzxuxu|<br></br>
                  -uuuuuuuuuuuuuxxxzxxxxuuuxxxxxxxxuwzzwzxxxrzzzzzzzzzxxxxxxxxxxzzwwwwzxxxxz~<br></br>


            </div>,

            <div>

`}}}}}}}}}}}}}}vvvvv}}}}rrrrrrrrrrrrrr}}}}}rrwrv?????}}}rrrrrr}}rrrrrrrrrw~<br></br>
                  `xxxxxxxxxxxxxzzzzzzxxuuuuuuuuuuuuuuxxxxxxuuyyxv????vxxzzxxuuxxxxxuuuyyyyy^<br></br>
`xxxxxxxxxxxxzzzzzzzxuuuxzzzzzzzxxxxxxxxxuuuuuuz}vvvzw}}}}wxxxxxxxxuuuyuuu^<br></br>
-uuuuuxxxxxxxxxzzzzzzzzwwrrrr}vv?^vwwzzzxuuuuuuuuuxxzvvvvv}rrrrzxuuyyyyyyy^<br></br>
-uuuuuuuuuuxxxxxzzwwwwwzwv>~`.   .-|^>?vrwzxxxzxuyuuwvvvvvvvv}}}zuuyyyyyyy^<br></br>
-uuuuuxuuuuuuxxxwrwwzzwzzz?.     ...````-^?vwwzxuuuuz}vvvvvvvvvvzyuyyyyyyy^<br></br>
-uuuxxxxxuuuxuzwv}zzzzzwv~  ..`````````````-^vwzzxxzzwvvvvvvvvvwuyyuuyyyyy^<br></br>
-uuuxxxxxxuuuzr>|wzwrv?-  .````````.``````````^vwwzzzzrvvvvvvvwxxuuuyyyyyy^<br></br>
-uuxxxxxxxuuz}^``|^|-`. .``.`-^+^|~....-````````^vrwzzzw}}}}}}}rrwuuyyyyyy^<br></br>
-uuuxxxxuuux}^```````` .`.^vwx>`..-??`.```````````^vrwzzw}}}}}}rrwuuyyyyuy^<br></br>
-uyuuuuuuuuw>```-````..``vuuy?     .x? ```````~-````|?rwwrrr}}}rwxuyyyuuuy^<br></br>
-uyyyuuuur+-.....````.``}yyyy+     .?. .````+zv-``````~>vrwzxuuuuxzwzuuuuy^<br></br>
-uuuuuuuur` ......`````~vwuyyu>...`-..+|~|`^u}-``````````~+?vwxuz}v~`~wyuy^<br></br>
`zzxxuxuuw+```....````````-|>?}v>|```.?xwxwwx-`````````^?^``~~+??}>-``ryuy^<br></br>
`wzxxxxuuy?.. .``````````````.````````+zxxuur-?}?-`````|?^+-^^|`-~|++|}yyy^<br></br>
`wzzxxuxxu?. ```````.`---``````````````?uxxurruuyr-````~v?^~~+|```..```>}r|<br></br>
`zzxxxzwrrw~```````-++v?~``````````````?xzuxwuuuuy>````|?-`-~^^```.... |vv|<br></br>
`xuxzw}vv}wvr-`````~>|`.`~`````````````|vzu}}uuuxr-````+^``-++~```....~zyy|<br></br>
`xxzzwr}}wrzx-``````...+?+~`````````````|xuuwwuxr````````............`zuxx|<br></br>
`zzwwwwwwzzwu>```````-+?>+|``````````````}uuuzzx}```.......`^?}z?....`zxxx|<br></br>
`zwwwwwwwzuzzu>```````>+|~```````````````>uuuuuzv......`^?wuyyyyu}v???zzzz|<br></br>
-xxzxxxxxxxxzzx?````````...```````````````?zxxzr-...->vxyyyyyyyuuuuuuxzzzz|<br></br>
-uuuuuuxzwzxuxwu}|````````````````````.....-+?}~`^?}zxuyyyyyyyuuuuxxxzzwww|<br></br>
-uuuuuuzwwzxuuzuux?|`..```````````.........`+}vvwzzzzzxuyyyyuuuuxxxxzzwwww~<br></br>
-uuuuuuuxxuuuxwuuxrr}?+-`...............`^?rwr}zzzwwwzxuuuuuuuuuxxzzzwwwzx|<br></br>
-uuuuuuuuuuuxwxuuzw}}xuxzrv?>>+||^^^++?vrwwwr}zzzzwzzxuuuuuuuuuxxxxzzzxuuu|<br></br>
                  -uuuuuuuuuuxzrwzzwrvzxxxuuuyyyuzrwwwwrwwwwwwrxxxzzxxuuuxxxxxxuuxzwzzwwxuuu|<br></br>
`xuuuuuuuuxzzzwrrrrwzzzxuuuuuuuurrwwrrxuuuuwuyuuuuyyyyuuxzxxuuux}vvrrrwxxu|<br></br>
-uuuuuuuuuxzzzzzwwzzzzzxxxxuuuuyuwzwrwuuuuwxuuuuyyyyyyyuxxxuuuuxrvv}}}wxxu|<br></br>
-uuuuuuuuuxxzzzzzzzzzzzzxxxxuuuyyuzuxzuuuzxuuuuuuuuuuyyyuuuuuuxzr}}}}rzxxu|<br></br>
-uuuuuuuuuuuuxxzzzzxxxxxxxxxuuuyyyxzxzxxuzzxxxxxxxuuuuuuuuuuuuxxr}}rwzxuxu|<br></br>
                  -uuuuuuuuuuuuuxxxzxxxxuuuxxxxxxxxuwzzwzxxxrzzzzzzzzzxxxxxxxxxxzzwwwwzxxxxz~<br></br>


            </div>,

            <div>

.~|~~~~~--~~||||||||||^^||||||^+>+^|||^||||^+++~-~---|^^^^^^^^|^^^^^^+++++.<br></br>
.^^||^^^||^^^^^^^^^^++>++++++?vw}++^^^^^^^+>??>~~~~~~++^^+>+>>++>>>>>>>>>>`<br></br>
.|^|||||||^^^^^^^^^^+>>+^^>?vwzv^^^||^^^++>>>>?>^|~|+^|^^|^+>>>>>>>>>>>>>>`<br></br>
.^^^^^^^^^^^^^^+>>>>>++^+??+v}+-`.-|||^^+>>>>>>??>>>+||||||^^^^+>>>>>>>??>`<br></br>
.^++^^^^+++++??vvv???>>?>|~+^.   ....`-~||++>>>>???>^||||||||^^|+>>>>>>>>>.<br></br>
.++^^^^^+++>v}vvv???vwr>^??`       ......`-~|^+>>>>>+|||||||||||+>>>>>>>>>.<br></br>
.++^^^^^^^+?v}}}rwwr}rxr?~  ................`~|^+++++^|||~~~~~|^>>>>>>>>>>`<br></br>
.++^^^^^^^>vrwzrvwzzzrv>` .`..................`~||++++^|~~~~||^++++>>>>>>>.<br></br>
.++^^^^+^+>?v?+>?vr}v?|.```...````..  ..........`~||^^^^|||||^^|||++>>>>>>.<br></br>
.++^^^^+++>>>|~^+>>++>`--.`~^+`.  .--. ........````-~||^^^>>^||^||+++>>>++.<br></br>
.+++++++>>>|``--~^^+^``-.~+>>-      >- .......````...-~||>rw>||||^+>>>>+++.<br></br>
.+++++++>^`. ..``-~|-.`.|>>>>`      |. .````-+^```.....`-|>?+++^^^|^^++++>.<br></br>
.^+++++>>^..... ........|+>>>>-. ...  -````->^````........``~|^+^~~...^>>>`<br></br>
.^^^+?v???^...  ...........`-|^|~--|-`~>+>++>`````.....`-`....`--~`...+?>>`<br></br>
.|~^?}}vv?-    ...................-++||++>>>^.~^|......`-``.`......``.|>>>`<br></br>
.~|?v??+++~  .......................--`~>>>>^+>>>^......~-`..`.........-|^`<br></br>
.~^>+^^|||^.........``~~`..............~>+>>+?>>>>-.....-....``....... `||.<br></br>
.^^^|||||||~^.......-.. ...............`|+>^^>>>>^.....``...``.........^++.<br></br>
.^^|||||~|~^+.......  ..`............``.`>>>+^>+|.```........ .. ... .^^||.<br></br>
.|||||||||^|+`........````...........````^>??>>>^```........`~^+-    .^^||.<br></br>
.|||||||||^^^+`.......``.............````->??>>+~......`-|>>?>>>+|~~--||||.<br></br>
.+^^^^^+^^^+^^+-.....................````.~++>^|....`-^>?????>>++++^^|~||~.<br></br>
.>>++++^|^^^+^^+|....................``.....`-~..`~|^+>??>>>>>>+^^^|||~||~.<br></br>
.++++++^^^^^^+^++^``........................`~~|^+^^^++>>>>>>>++^|||||~~~~.<br></br>
.+++++++^^++^^|++^~~~-`.... .............`-~|||^^^^^^^+>+++++++^^|||||~~|~.<br></br>
.+++++++++++^|^++|~~|+>++^|~--````````~~||||||^^^^^^^^++^^++^^^^^|||~||^^|.<br></br>
.++++++++^^^|~~||~~~^++++>>>>>>^~||^^~|^^||^^+++^^^^+++^^^^^^^^^^|^|~~|^^^.<br></br>
.^^^^+++++^||^^~~~~||^^^+++>>>>>|~|||^>>>+>+>>>>>>>>>>+^||^^^^^^|~~~~~~|^|.<br></br>
.++++++++++^^^^^||||||^^+^++>>>?>^^^|+>+++^+>>>>>>>>>>>+^^^^^^^^~~~--~~|^|.<br></br>
.+++++++>>+>++++^^^^^|^^^^^++>>>?>+>+^>++^^+++++++++>>>>+++++^^|~---~~~|^|.<br></br>
.++>>>>>>>>>>>>++^^^^^^^^^^^++>>>>++>^^^+^^^^^^^^^^++++++++++^^^~~--~||^^|.<br></br>
.^++>>>>>>>>>>>++^^^^^^^^^^^^+>>>>+++^++++|^^^^^^^^^^^^^+++++^^^||||^^^++^.<br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          `^?>            .``````...        ...    .... <br></br>
                    ...   `|>v}+.          ..`````....     ....... ..      <br></br>
              ..```````.-^|-?}~          ...``````.............`..         <br></br>
                  `~|~~~~|||^~.`??.          ...````````............`..         <br></br>
           .-|~~~||->}}~`+?~            ..`````````..............          <br></br>
          .`~|>vrzr??vxv?^`...          ........``..............           <br></br>
          .~^}xuzwwwwzww?```..            ..........  ......               <br></br>
       .  .-^+>rxzxuxwvv>```..               ......                        <br></br>
           .-^+?v}rvvwv>~``...               .......     .``               <br></br>
           ..--~>??v?>+|```..          .......```...     `vr-              <br></br>
                  `~~|^++|~-`...           ....```.```...      `~.              <br></br>
                  .  .`~-.   .......         .....```````````..                       <br></br>
                  `~..-~`.                    -^?^-``````````-`.                        <br></br>
   .~+^|-.                      .`|vv>|````....``.                         <br></br>
                  .|^~`.                        ...`~|`.`....`..                           <br></br>
                  ...      ..                     .....``....`..  .                        <br></br>
                  ........`...............                    <br></br>
                  ........```....`````......                  <br></br>
                  ........`````````````.....                 <br></br>
                  ....````--``````````...                 <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...```............````.                  <br></br>
                  ..            ...``..... ...........                   <br></br>
                  ........       ...``.... ........                       <br></br>
                  .....   .....````.... ......                         <br></br>
                  ....   ...................                           <br></br>
                  ............                                 <br></br>
                  ....                 ........                                    <br></br>
                  .....`...                ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          `^?>            .``````...        ...    .... <br></br>
                    ...   `|>v}>.          ..`````....     ....... ..      <br></br>
              ..```````.-^|-?}~          ...``````.............`..         <br></br>
                  `~|~~~~|||^|.`??.          ...````````............`..         <br></br>
           .-|~~~||->}v~`+?~            ...````````..............          <br></br>
           `~|>vrz}??vxv>^`...          ........``..............           <br></br>
          .~^}xuzwwwwzww?```..            ..........  ......               <br></br>
       .  .-^+?rxzxuxwvv>```..               ......                        <br></br>
           .-^+?vrrvvwv>~``...               .......     .``               <br></br>
           ..--~>??v?>+|```..          .......```...     `vr-              <br></br>
             `~~|^+>|~-`...           ....```.```...      `~.              <br></br>
                  .  .`~-.   .......         .....``````````...                       <br></br>
                  `~..-|`.                    -^?^-``````````-`.                        <br></br>
   .-+^|~.                      .-|vv>|````....``.                         <br></br>
                  .|^~`.                        ...`~|`.`....`..                           <br></br>
                  ...      ..                     .....``....`..  .                        <br></br>
                  ........`...............                    <br></br>
                  ........```....`````......                  <br></br>
                  ........`````````````.....                 <br></br>
                  ....````---`````````...                 <br></br>
                  ..  ....````````...```````...                <br></br>
                  ...```............````.                  <br></br>
                  ..            ...``..... ...........                   <br></br>
                  ........       ...``....  ......                        <br></br>
                  .....    ....````...........                         <br></br>
                  ....   ...................                           <br></br>
                  ............                                 <br></br>
                  ....                 .......                                     <br></br>
                  .....`...              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          `^?>            .``````...        ...    .... <br></br>
                    ...   `|>v}>.          ..`````....     ....... ..      <br></br>
              ..```````.-^|-?}~          ...``````.............`..         <br></br>
                  `~|~~~~|||^|.`??.          ...````````............`..         <br></br>
           .-|~~~||->}v~`+?~            ...````````..............          <br></br>
           `~|>?}z}??vxv>^`...          ........``..............           <br></br>
          .~^}xuzwwwwzzw?```..            ..........  ......               <br></br>
       .  .-^>?rxzxuxwvv>```...              ......                        <br></br>
       .  ..-^+?vrr}vwv>~``...               .......     .``               <br></br>
           ..--~>??v?>+|```..          .......```...     `vr-              <br></br>
             `~~|^>>|~-`...           ....``..```...      `~.              <br></br>
                  .  .`~-.   .......         .....``````````...                       <br></br>
                  `~..-|`.                   .-+?+~``````````-`.                        <br></br>
    ~+^|~.                      .-|v}?|````....``.                         <br></br>
                  .|^~`.                        ...`~~`.`....`..                           <br></br>
                  ...      ..                     ..`...`....`..  .                        <br></br>
                  ........`...............                    <br></br>
                  ........````...`````......                  <br></br>
                  ........`````````````.....                 <br></br>
                  ....````---`````````...                 <br></br>
                  ..  ....````````...```````...                <br></br>
                  ...```............````.                  <br></br>
                  ..            ...``..... ...........                   <br></br>
                  .......        ...``....  ......                        <br></br>
                  .....    ....````...........                         <br></br>
                  ....   ...................                           <br></br>
                  ............                                 <br></br>
                  ....                 .......                                     <br></br>
                  ......`...              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          `^?>            .``````...        ...    .... <br></br>
                     ..   .|>v}>.          ..`````....     ....... ..      <br></br>
              ..```````.-^^-?}|          ...``````.............`..         <br></br>
                  `~|~~~~|||^|.`??.          ...````````............`..         <br></br>
           .-|~-~|~->}v~`+?~    .       ...````````..............          <br></br>
           `~|>?}z}??vx?>^`...          ........``..............           <br></br>
          .~^}xuzwwwwzzw?```..            ..........  ......               <br></br>
       .  .-^>?rxzxuuwvv>```...              ......                        <br></br>
       .  ..-^+v}rw}vwv>~``...               .......     .``               <br></br>
           ..--~>??vv>+|```.           .......```...     `vr`              <br></br>
             `~|^^>>^~-`...           ....``..```...     .`|.              <br></br>
                  .  .`~-.   .......         .....```````````..                       <br></br>
                  `~..-|-.                   .-+?+~``````````-`.                        <br></br>
    -+^|~`                      .-|v}?|````....``.                         <br></br>
                  .|^~`.                        ...`~~`.`....`..                           <br></br>
                  .`.      .                      ..`...`....`..  .                        <br></br>
                  ........`...............                    <br></br>
                  ........````...`````......                  <br></br>
                  ........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..  ....````````...```````...                <br></br>
                  ...```............````.                  <br></br>
                  ..            ...``..... ...........                   <br></br>
                  .......        ...``....  ......                        <br></br>
                  .....   .....````...........                         <br></br>
                  ....   ...................                           <br></br>
                  ...........                                  <br></br>
                  ....                 .......                                     <br></br>
                  ......`...              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          `|?>           ..``````...       ....    .... <br></br>
                     ..   .|>v}>.          ..`````....     .......  .      <br></br>
                  ..``````.`^^-?r|          ...``````.............`..         <br></br>
                  `~|~~~~|~~^|``??.          ...````````............`.          <br></br>
           .-|~-~|~->}v~`+?~    .       ..`````````..............          <br></br>
           `~|>?}z}??}x?>+`...          ........``..............           <br></br>
          .~^}xuzwwwrzzw?```..            ..........  ......               <br></br>
       .  .-^>?rxzxuuwvv>```...              ......                        <br></br>
       .   .-^+v}rw}vwv>~``...               .......     .`.               <br></br>
           ..-~~>??vv>+|```.           .......```...     `vr`              <br></br>
             `~|^^>>^~-`...           ....``..```...     .-|.              <br></br>
                  .  .`~-.   .......         .....``````````...                       <br></br>
                  .~..-|-.                   .-+?+~-`````````-`.                        <br></br>
    -+^~~`                      .-|v}?^````....``.                         <br></br>
                  .|^~`.                        ...`~~-``....`..                           <br></br>
                  .`.      ..                     ..``..`....`..  .                        <br></br>
                  ........`...............                    <br></br>
                  ........````...`````......                  <br></br>
                  ........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..  ....````````...```````...                <br></br>
                  ...```............````.                  <br></br>
                  ..            ...``..... ...........                   <br></br>
                  .......        ...``....  ......                        <br></br>
                  .....   .....````...........                         <br></br>
                  ....   ...................                           <br></br>
                  ...........                                  <br></br>
                  ....                 .......                                     <br></br>
                  ......`...              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          .|?>            .`````....       ....    .... <br></br>
                          .~>v}>.          ..`````....     .......  .      <br></br>
                  ..``````.`^^-?r|          ...``````.............`..         <br></br>
                  `~|~~~~|~~^|`.??.          ...````````............`.          <br></br>
           .-|~-~~~->}v~`^?|            ..`````````..............          <br></br>
           `~|>?}z}??}x?>+`...          ........``..............           <br></br>
          .~^}xuzwwwrzzw?```..            ..........  ......               <br></br>
       .  .-^>?rxzxuuwvv>```...              .......                       <br></br>
       .  ..-^+v}rw}vwv>~``...               .......     .`.               <br></br>
           ..--->??vv>+|```..          .......```...     `vr`              <br></br>
             `~|^+>>^~-``..           ....`...````..     .-|.              <br></br>
                  .  .`~-.  ........         .....``````````...                       <br></br>
     .~..-|-.                   .~+?>|-`````````-`.                        <br></br>
    -+^~~`                      .~^v}?>-```....``.                         <br></br>
                  .|^~`.                        ``..--|``....`..                           <br></br>
                  .`.      ..                     ..`~.......`..                           <br></br>
                  ........`................                   <br></br>
                  ........```.....````......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..            ...``....  ...........                   <br></br>
                  .......        ...``.... .......                        <br></br>
                  .....   .....````.... ......                         <br></br>
                  ....   ...................                           <br></br>
                  ............                                 <br></br>
                  ....                 .......                                     <br></br>
                  ..........              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........        .           <br></br>
                  .          .|?>           ..`````....       ....    .. . <br></br>
                          .~>v}>.          ..`````....     .......  .   .  <br></br>
                  ..``````.`^^~?r^          ...``````.............`..       . <br></br>
                  `~|~~~~|~~^|`.??`          ...````````............`.          <br></br>
           .-|~-~~~-+}v~`^?|    .       ..`````````..............          <br></br>
           `~|>?}z}??}x?>+`...          ........``..............           <br></br>
          .~^}xuzwwwrzzw?```..            ..........  ......               <br></br>
       .  .-^>?wxzxuuwvv>```...              ......                        <br></br>
       .  ..~^+v}rw}vwv?|``...               ......      .`.               <br></br>
           ..--~>??vv>+|```..          .......```...     `vr`              <br></br>
             `~|^+>>^~-``..           ....`...````..     .-^.              <br></br>
                  .   `~~.  ........         .....``````````...                       <br></br>
     .~..-|-.                   .~+?>|-`````````-`.                        <br></br>
    -^^~~`.                     .~^v}?>-```....``.                         <br></br>
                  .~^|`.                        ``..--|``....`..                           <br></br>
                  .`.      ..                     ..`~.......`..  .                        <br></br>
                  ........`................                   <br></br>
                  ........```......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..            ...``....  ...........                   <br></br>
                  .......        ...``.... .......                        <br></br>
                  .....   .....````.... ......                         <br></br>
                  ....   ...................                           <br></br>
                  ............                                 <br></br>
                  ....                 ........                                    <br></br>
                  ..........              . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........        .           <br></br>
                  .          .~?>           ..``````...       ...     .. . <br></br>
                          .~>v}?.          ..`````....     ....... ..   .  <br></br>
                  ..``````.`|^~?r^           ..``````.............`..       . <br></br>
             `~|~~~||~~^|`.>?`          ...````````............`.          <br></br>
           .-|~--~~-+}v~`^?|    .       ..`````````..............          <br></br>
           `~|>?}z}??}x?>+`...          ........``..............           <br></br>
          .~^}xuzwwwrwzw?```..            ..........  ......               <br></br>
       .  .-^>?wxzxuuwvv>```..               ......                        <br></br>
       .  ..~^+v}rw}vwv>|``...               ......      .`.               <br></br>
           ..--~>??vv?+|```..          .......```...     `?r`              <br></br>
             `||^+>>+~-``..           ........````..     .-^.              <br></br>
                  .   `~~.  ........         .....``````````...                       <br></br>
     .~..`|-.                   .~+?>|-`````````-`.                        <br></br>
    -^^~~`.                     .~^v}?>-```....``.                         <br></br>
                  .~^|`.                        ``..--|``....`..                           <br></br>
                  .`.      ..                     ..`~.......`..  .                        <br></br>
                  ........`................                   <br></br>
                  ........``.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..            ...``....  ...........                   <br></br>
                  .......        ...``.... .......                        <br></br>
                  .....    ....````.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............                                 <br></br>
                  ....                 ........                                    <br></br>
                  .........               . ..                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........        .           <br></br>
                  .          .~>>           ..``````...       ...     .. . <br></br>
                          .~>v}?.          ..`````....     ....... ..      <br></br>
                  ...`````.`|^~?r^           ..``````.............`..       . <br></br>
             .~|~~~||~~^|`.>?`           ..````````............`.          <br></br>
           .-|~--~~-+}v~`^?^    .       ..`````````..............          <br></br>
           `~~>?}w}??}x?>+`...          ........``..............           <br></br>
          .~^}xuzwwwrwzw?```..            ..........  ......               <br></br>
       .  .-^>?wxzxuuwvv>```..               ......                        <br></br>
       .  ..~^+vrww}vwv?|``...               ......      ...               <br></br>
           ..--~>??vv?+|```..          .......```...     `?r`              <br></br>
             `||^+>?+~~``..           ........```...     .-^.              <br></br>
                  .   `~~.  ........        ......``````````...                       <br></br>
     .~..`|-.                   .~>??+|-``-`````-`.                        <br></br>
    -^^~~`.                     `~+vrv>|```....``.                         <br></br>
                  .~^|`.                        --``~|~~`....`...                          <br></br>
                  .`.      .                    . ...|~......`..                           <br></br>
                  .......`................                    <br></br>
                  ........``.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  .....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..             ..``..... .........`.                   <br></br>
                  .......        ...``....  ......  .                     <br></br>
                  .....   .....````.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............                                 <br></br>
                  ....                 ........                                    <br></br>
                  .........               ....                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........        .           <br></br>
                  .          .~>>           ..`````....        ...    .. . <br></br>
                          .~>v}?.          ..`````....     ....... ..      <br></br>
                  ...`````.`|^~?r^           ..``````................       . <br></br>
             .~|~~~||~~^^`.>?`          ...````````............`.          <br></br>
                  .-|~--~~-+vv~`|?^    .       ..`````````..............          <br></br>
           `~~>?vw}??}x?>+`...          ........``..............           <br></br>
          .~^}zuzwwwrwzw?```..            ..........  ......               <br></br>
       .  .-^>?wxzxuuwvv>```..               ......                        <br></br>
       .  ..~^+vrwwr}wv?|``...               ......      ...               <br></br>
           ..--~>???v?+^```..          .. ....```...     `vr`              <br></br>
             `||++>?+~~``..           ........````..     .-+.              <br></br>
                  .   `~~.  ........        ......``````````...                       <br></br>
     .~..`|-.                   .~>??+|-``-`````-`.                        <br></br>
    `^^~~`.                     `~+vrv>|```....``.                         <br></br>
                  .~^|`.                        --``~|~~`....`...                          <br></br>
                  .`.      .                    . ...|~......`..                           <br></br>
                  .......`................                    <br></br>
                  ........``.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  .....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..             ..``....  .........`.                   <br></br>
                  .......        ...``....  ...... ..                     <br></br>
                  .....    ....````.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............                                 <br></br>
                  ....                 ........                                    <br></br>
                  .........                ...                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  .            ..........                    <br></br>
                  .          .~>>           ..`````....       ....    .. . <br></br>
                          .~+v}?.          ..`````....     ....... ..      <br></br>
                  ...`````.`|^~?r^           ..``````................       . <br></br>
             .~|~~~|~~~^^`.>?`          ...````````............`.          <br></br>
                  .-|~--~~-+vv~`|?^    .       ..`````````..............          <br></br>
           `~~+?vw}??}x?>>`...          .......```..............           <br></br>
                  .~^vzuzwwwrwzw?```..            ..........  .......              <br></br>
       .  .-^>?wxzxuuwvv>```..              ........                       <br></br>
       .  ..~^+vrwwr}wv?|``...               ......      ...               <br></br>
           ..--~>???v?>^```..          .. ....```...     `?}`              <br></br>
                  `||++??+~~``..           ........```...     .~+.              <br></br>
                  .   `~~. .........        ......``````````....                      <br></br>
     .-..`|~.                   .~>??+|-``-`````-`..                       <br></br>
    `^^~~`.                     `~+vrv>^```....``.                         <br></br>
                  .~+|-.                        --``~|~~`....`...                          <br></br>
                  .`.      .                    . ..`|~......`..                           <br></br>
                  .......`................                    <br></br>
                  ........``.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ....````---`````````....                <br></br>
                  ..   ...````````...```````...                <br></br>
                  ...````...........````.                  <br></br>
                  ..            ...``..... .........`.                   <br></br>
                  .......        ...``....  ...... ..                     <br></br>
                  .. .....    ....````.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               ....                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .          .~>>           ..`````....       ..      .. . <br></br>
                          .-+v}?.          ..`````....     ....... ..   .  <br></br>
                  ...`````.`|^~?r+           ..``````................       . <br></br>
             .~|~~~|~~~^^`.>v`          ...````````............`.          <br></br>
                  .-|~--~--+vv~`|?^    .       ..`````````..............          <br></br>
           `~~+?vwv??}x?>>`...          .......```..............           <br></br>
          .~^vzxzzwr}wzw?```..            ..........  .......              <br></br>
       .  .-+??wxzxuuwvv>```..              ........                       <br></br>
       .  ..~^+vrwzr}wv?|``...               ......      ...               <br></br>
           ..--~>???v?>^```..          ..   ...``...     `?}`              <br></br>
             `||+>??+~~``..           ........````..     .~+.              <br></br>
                  .   .~~. .........        ......```````````...                      <br></br>
     .-..`~~.                   .~>??>+|``-`````-`..                       <br></br>
    `^^~~`.                     ~|^v}}?+-`.....``.                         <br></br>
                  .~+|-.                       .||---^-^~........                          <br></br>
                  .`.      .                    .`.-`-^`.....`..                           <br></br>
                  .......-................                    <br></br>
                  .........`.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ......````---`````````...                 <br></br>
                  .   ...````````...```````...                <br></br>
                  ...````.........`.````.                  <br></br>
                  ..            ...``....  .........`.                   <br></br>
                  .......        ...``....  ...... ..                     <br></br>
                  . .....    .....```.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........                ...                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .          .->>           ..`````....       ..      .. . <br></br>
                          .-+?}?.          ..`````....     ....... ..   .  <br></br>
                  ....````..|^~?r+           ..``````................       . <br></br>
             .-|~~~|~~~^^`.>v-          ...````````............`.          <br></br>
                  .`|~--~--+vv~`|?+    .       ..`````````..............          <br></br>
           `~~+?vwv??}x?>>`...          .......```..............           <br></br>
          .~^vzxzzwr}wzw?```..            ..........  .......              <br></br>
       .  .-+??wxzxuuwvv>```..              ........                       <br></br>
       .  .`~^+vrwzr}wv?|``...               ......      ...               <br></br>
           ..--~>???v?>^```..          ..   ...``...     `?}`              <br></br>
             .||+>??+~~``..           ........````..     .~+.              <br></br>
                  .   .~~. .........        ......```````````...                      <br></br>
     .-..`~~.                   .~>??>+|``-`````-`..                       <br></br>
    `^^~~`.                     ~|^v}}?+-`.....``.                         <br></br>
                  .~+|-.                       .||---^-^~........                          <br></br>
                  .`.      .                    .`.-`-^`.....`..                           <br></br>
                  .......-................                    <br></br>
                  .........`.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ......````---`````````...                 <br></br>
                  .   ...````````...```````...                <br></br>
                  ...````.........`.````.                  <br></br>
                  ..            ...``....  .........`.                   <br></br>
                  .......        ...``.... ....... ..                     <br></br>
                  .......    .....```.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               ....                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........                    <br></br>
                  .          .->>            .`````....       ..      .. . <br></br>
                          .-+?}?.          ..`````....     ....... ..   .  <br></br>
                  .......`..~^~?w+.          ..``````.............`..       . <br></br>
             .-|~~~|~--^^`.>v-          ...````````............`.          <br></br>
                  .`|~-----+v?~`|?+.   .       ..`````````..............          <br></br>
           `~~+?vwv??}x?>>`...          .......```..............           <br></br>
          .~|vzxzzwr}wzw?```..            ..........  .......              <br></br>
       .  .-+??wxzxuuzv}>```..              ........                       <br></br>
       .  .`~^+vwwzr}wv?|``...               ......       ..               <br></br>
           ..--~>???v?>^```..          .    ...``...     `?}`              <br></br>
             .|^+>??+~~``..           ........````..     .~>.              <br></br>
                  .   .~~. .........        ......```````````...                      <br></br>
     .-..`~~.                   .~>??>+|``-`````-`..                       <br></br>
    `^^~~`.                     ~|^v}}?+-`.....``.                         <br></br>
                  ~+|-.                       .||---^-^~........                          <br></br>
                  .-.      .                    .`.-`-^`.....`..                           <br></br>
                  .......-................                    <br></br>
                  .........`.......```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ......````---`````````...                 <br></br>
                  .   ...````````...```````...                <br></br>
                  ...````.........`.````.                  <br></br>
                  ..            ...``....  .........`.                   <br></br>
                  .......        ...``.... ....... ..                     <br></br>
                  .......    .....```.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               ....                                        <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .          .-+>            .`````....        .      .. . <br></br>
                          .-+?}?`          ..`````....     ....... ..      <br></br>
               .......`..~^~?w>.          ..``````.............`..       . <br></br>
                  .-|~~~|~--^^`.+v-          ...````````............`.          <br></br>
                  .`|~-----+v?~`~?+.   .       ..`````````..............          <br></br>
           `~~+?vwv??}x?>>-...          .......```..............           <br></br>
          .~|vzxzzwr}wzw?```..            ..........  .......              <br></br>
          .-+?vwxzxuuzv}>```..              ........                       <br></br>
       .  .`~^+vwwzr}wv?|``...               ......       ..               <br></br>
           ..--~>???v?>^```..          .    ...``...     `?}`              <br></br>
             .|^+>??+~~``..           ........````..     .|>.              <br></br>
                  .   .~~...........        .`...````````````...                      <br></br>
     .-..`~~.                   .^????>>^``|````-`..                       <br></br>
    `^^~~-.                    `|^+rwwv>>-`..`.``.                         <br></br>
   ~+|-.                       -|--~|>|~^^.......                          <br></br>
  .-.      .                   `>^---`~|`....`..                           <br></br>
                  ....`..`~...............                    <br></br>
                  .................```......                  <br></br>
                  .........`````````````.....                 <br></br>
                  ......````---`````````...                 <br></br>
                  .   ...````````...```````...                <br></br>
                  ...````.........`.````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......         ...``.... ....... ...                    <br></br>
                  .......     ....```.... ......                         <br></br>
                  ....   ......`............                           <br></br>
                  ............ ..                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                             .-+>            .``````...               .. . <br></br>
                          .`+?}?`          ..`````....      ...... ..   .  <br></br>
               .......`..~^|?w>.          ..``````................       . <br></br>
                  .-|~~~|~--^^-.+v-          ...````````............`.          <br></br>
                  .`|~-----+v?~`~?+.   .       ..`````````..............          <br></br>
           `~~+?vrv??}x?>>-...          .......```..............           <br></br>
          .~|vzxzzwr}wzw?```..            ..........  .......              <br></br>
          .-+?vwxwxuuzv}>```..              ........                       <br></br>
       .  .`~^+}wwzw}wv?|``...               ......       ..               <br></br>
           ..-~~>???v?>^```..               ...``...     `?v`              <br></br>
             .|^+>??>~~``..           ........````..     .|>.              <br></br>
                  .   .-~...........        .`...````````````...                      <br></br>
     .-..`~~.                   .^????>>^``|````-`..                       <br></br>
    `^^~~-.                    `|^+}wwv>>-`..`.``.                         <br></br>
   ~+|-.                       -~--~|>^~^^.......                          <br></br>
  .-.      .                   `>^---`~|`....`..                           <br></br>
                  ....`..`~...............                    <br></br>
                  .................```......                  <br></br>
                  .........````````````......                 <br></br>
                  ......````---````````....                 <br></br>
                  ...`````````..```````...                <br></br>
                  ...`````........``````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......         ...``.... ....... ...                    <br></br>
                  ........     ....```.... ......                         <br></br>
                  ....   .....```...........                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                             .-+>.           .``````...        .      .. . <br></br>
                          .`^?}?`          ..`````....      ...... ..   .  <br></br>
               ..........~+|?w>.          ..``````.............`..       . <br></br>
                  .-~~~~|~--^^-.+v~          ...````````............`.          <br></br>
           .`|~----`+v?~`~?>.   .       ..`````````..............          <br></br>
           `~~+?vrv??}x?>>-...          .......```..............           <br></br>
          .~|vzxzzwr}wzw?```..            ..........  .......              <br></br>
          .-+?vwxwxuuzv}>```..              ........                       <br></br>
       .  .`~^+}wzzwrwv?|``...               ......       .                <br></br>
           ..-~~>???v?>^```..               ...``...     `?v`              <br></br>
             .|^+>??>~~``..           ........````..     .|>.              <br></br>
                  .   .-~...........        .`...````````````...                      <br></br>
     .-..`~~.                   .^????>>^-`|````-`..                       <br></br>
    `^^~~-.                    `|^+}wwv>>-`..`.``.                         <br></br>
   -+^~`                       -~--~|>^~^^.......                          <br></br>
  .-..     .                   `>^---`~|`....`..                           <br></br>
                  ....`..`~...............                    <br></br>
                  .................```......                  <br></br>
                  .........````````````......                 <br></br>
                  ......````---````````....                 <br></br>
                  ...`````````..```````...                <br></br>
                  ...`````........``````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......          ..``.... ....... ...                    <br></br>
                  ........     ....```.... ......                         <br></br>
                  ....   ......``...........                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .`++.           .``````...        .      .. . <br></br>
                          .`^?}?`          ..`````....     .......  .   .  <br></br>
               ..........~+|?w>.          ..``````.............`..       . <br></br>
                  .-~~~~~~--|^-.+v~          ...````````............`.          <br></br>
           .`|~----`+v?~`~?>.   .       ..`````````..............          <br></br>
           `~~+>vrv??}x?+>-...          .......```..............           <br></br>
          .~|vwxzzwr}wzw?```..            ..........  .......              <br></br>
          .-+?vzxwxuuzv}>```..              ........                       <br></br>
       .  .`~^+}wzzwrwv?|``...               ......       .                <br></br>
           ..~~~>???v?>^```..               ...``...     `?v`              <br></br>
             .|^>>??>~~``..           ........````..     .|?.              <br></br>
                  ..  .-~...........       ..``..`~~-````````...                      <br></br>
     .-..`~~`                   `>???v???+`~~```-`..                       <br></br>
    `^^~~-.                    `|>+}zww?+>|`...``.                         <br></br>
   -+^~`                      .+||~>>^++~|^|.....                          <br></br>
                  .-`.     .                   `--`+...|-~-`.`..                           <br></br>
                  .|?-`-+-`-+`............                    <br></br>
                  .``...`..........```......                  <br></br>
                  .........````````````......                 <br></br>
                  ......````---````````....                 <br></br>
                  ...`````````..```````...                <br></br>
                  .   ...`````........``````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......          ..``.... ....... ...                    <br></br>
                  ........     ....```.... ......                         <br></br>
                  ....   ......``...........                           <br></br>
                  ............  .                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .`^+.           .``````...        .      .. . <br></br>
                           `^?}?`          ..`````....      ...... ..   .  <br></br>
               ..........~^|?w>.          ..``````................       . <br></br>
                  .-~~~~~~--|^-.^v~          ...````````............`.          <br></br>
            `|~----`^v?~`~?>.   .       ..`````````..............          <br></br>
           `~~+>vrv>?}x>+>-...          .......```..............           <br></br>
          .~|vwxzzwr}rxw?```..            ..........  .......              <br></br>
          .-+?vzxwzuuzv}>```..              .......                        <br></br>
       .  .`~^+}wzzwrwv?|``...               ......       .                <br></br>
           ..~~~>???v?>^```..               ...``...     `?v`              <br></br>
             .|^>>??>~~``..           ........````..     .^?`              <br></br>
                  ..  .-~...........       ..``..`~~-````````...                      <br></br>
     .`..`~~`                   `>???v???+`~~```-`..                       <br></br>
    `^^~~-.                    `|>+}zww?+>|`..``-.                         <br></br>
   -+^~`.                     .+||~>>^++~|^|......                         <br></br>
                  .-`.     .                   `--`+...|-~-`.`..                           <br></br>
                               .|?-`-+-`->`............                    <br></br>
                  .``...`..........``.......                  <br></br>
                  .........````````````......                 <br></br>
                  ......````---````````....                 <br></br>
                  .                        ...`````````..```````...                <br></br>
                  .   ...`````........``````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......          ..``.... ...........                    <br></br>
                  ........     ....```.... .......                        <br></br>
                  ....   ......``...........                           <br></br>
                  ............ ..                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `^+.           .``````...               .. . <br></br>
                           `^?}?`          ..`````....      ...... ..   .  <br></br>
               ..........~^|>w>.          ..``````................       . <br></br>
                  .-~~~~~~--|^-.^v|           ..````````............`.          <br></br>
            `|~----`^v?~`-?>.   .       ..`````````..............          <br></br>
           `~~^>vrv>?rx>+?~...          .......```..............           <br></br>
                  .~|vwxzzwrvrxr?```..            ..........  .......              <br></br>
          .-+?vzxwzuuz}}>```..              .......                        <br></br>
       .  .`~^>}zzxwrwv?|``...               ......       .                <br></br>
           .`~~~>???vv>^```..               ...``...     `?v`              <br></br>
             .|^>>?v>~~``..           ........````..     .^?`              <br></br>
                  .  .-~`....`.....       ..``..`~~-````````...                      <br></br>
     .`...~~`                   `>???v???+`~~```-`..                       <br></br>
    `^^~~-.                    `|>+}zww?+>|`..``-.                         <br></br>
   -+^~`.                     .+||~>>^++~|^|......                         <br></br>
                  .-`.     .                   `--`+...|-~-`.`..                           <br></br>
                               .|?-`-+-`->`............                    <br></br>
                  .``...`..........``.......                  <br></br>
                  .........````````````......                 <br></br>
                  ......````---````````....                 <br></br>
                  ...`````````..```````...                <br></br>
                  .   ...`````........``````.                  <br></br>
                  ..            ...``....  ........``.                   <br></br>
                  ......          ..``.... ...........                    <br></br>
                  ........     ....```.... .......                        <br></br>
                  ....   ......``............                          <br></br>
                  ............ ..                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               .....                                       <br></br>
                  .......``..                                                          <br></br>
                  ............                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `^+.           .``````...        .      .. . <br></br>
                           `^?}v`          ..`````....      ...... ..   .  <br></br>
               ..........-^|>w?.          ..``````................       . <br></br>
                  .-~~~~~~--|^-.^v|           ..````````............`..         <br></br>
            `|~-----^??~`-?>.   .       ..`````````..............          <br></br>
           `|~^>?}v>?rx>+?~...          .......```..............           <br></br>
                  .~|vwxzzwrvrxr?```..            ..........  ........             <br></br>
          .-+?vzxwzuuz}}?```..              .......    .                   <br></br>
       .  .`~^+}zzxwrwv?|``....              ......       .                <br></br>
           .`~~~>???vv>^```..                ..``...     `?v`              <br></br>
             .|^>>?v>~~``..           ........````..     .^?`              <br></br>
                  .  .-~`....`......       .....`--`````````...                      <br></br>
                  .`. .~~`                   .-|~--~^+^~~~~-`-`..                       <br></br>
    .|^~~-.                    .`~-~^^|~^>>++^^|~..                        <br></br>
   -+^~`.                     .````````-^+>+^^^^`.                         <br></br>
  .-`.     .                   .`-|+~`~?>>+^~||~~`.                        <br></br>
                               .~?>+>>+??v+++~````.....                    <br></br>
                               .~+^^|+>>>+^+-``---``.`...                  <br></br>
                  .`|^^|-|^|~``.`|--~||`.....                 <br></br>
                  ..`~~`````...`--````.``...                 <br></br>
                  `~```-|-``--`...`````...                <br></br>
                  ..  ..````.`-``~|`..``````..                 <br></br>
                  ..            . .``^`.....`......``.                   <br></br>
                  .....           ...`|...  ...... ...                    <br></br>
                  ........     ....``..... ......                         <br></br>
                  ....   ......``..`.........                          <br></br>
                  ............ ..                              <br></br>
                  ....                 ........                                    <br></br>
                  .........               ......                                      <br></br>
                  .......``..                                                          <br></br>
                  ........`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `^+.           .``````...        .      .. . <br></br>
                  `^?vv`          ..`````....      ...... ..   .  <br></br>
                .........-^^>w?.          ..``````.....  .........       . <br></br>
                  .`~~~~~~--|^-.|v|           ..````````............`..         <br></br>
            `~~-----^??~`-?>.   .       ..`````````..............          <br></br>
           `|~^>?}v>?rz>+?~...          .......```..............           <br></br>
                  .~|vwzzzwrvrxr?```..            ..........  ........             <br></br>
          .-+?vzxwzuuz}}?```..              .......    .                   <br></br>
       .  .`~^+}zzxzrwv?^``...               ......       .                <br></br>
           .`~~~>???vv>+```..                ...`...     `?v`              <br></br>
             .|^>>?v>~~``..           ........````..     .^?`              <br></br>
                  .  .-~`....`......            .```````````...                      <br></br>
                  .`. .~~`                        ..``-|^^|~--`..                       <br></br>
    .|^-~~.                          ..`|>?????+~..                        <br></br>
   -^^~`.                         .....->??>>???|.                         <br></br>
  .~`.     .                    .-^++|?rrrr}?+++>^.                        <br></br>
                  .~????vwwwwwww?~|~-`....                    <br></br>
                               .|???>wxzwwwrv?-||^^~.`...                  <br></br>
                               .->??>?rr}v>+`|>~^?vv|.....                 <br></br>
                                .`|>>|+??+.`~.~~-~``.``...                 <br></br>
                                   ^>--`~>~``~~-`....```...                <br></br>
                              ..  ..``~-``~~->>`..``````..                 <br></br>
                     .            . `-~}+-``..`......``..                  <br></br>
                   .....            `.^}~... ...... ...                    <br></br>
                  ........     .....`.`... ......                         <br></br>
                  ....   ......``.``.........                          <br></br>
                  ...............                              <br></br>
                  ...                  ........                                    <br></br>
                  .........               ......                                      <br></br>
                  ......``..                                                          <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `^+.           .``````...        .      .. . <br></br>
                  `|?vv`          ..`````....      ...... ..   .  <br></br>
                .........-^^>w?.          ..``````.............`..       . <br></br>
                  .`~~~~~~--|^-.|v|           ..````````............`..         <br></br>
            `~~-----+??~`-?>.   .       ..`````````..............          <br></br>
           `|~^>?}v>?rz>+?~...          .......```..............           <br></br>
                  .~|vwzzzwrvrxr?```..            ..........  ........             <br></br>
          .-+?vzxwzuuz}}?```..              .......    .                   <br></br>
       .  .`~^+}zzxwrwv?^``...               ......       .                <br></br>
           .`~~~>???vv>+```..                ...`...     `?v`              <br></br>
             .|^>>?v>~~``..           ........````..     .^?`              <br></br>
                  .  .-~`....`......            .```````````...                      <br></br>
                  .`. .~|`                        ..``~|^^|~--`..                       <br></br>
    .|^~~~.                          ..`|>?????+~..                        <br></br>
   -^^~`.                        ......->??>>???|.                         <br></br>
  .~`.     .                    .-^++|?rrrr}?+++>^.                        <br></br>
                  .~????vwwwwwww?~|~-`....                    <br></br>
                               .|???>wxzwwwrv?-||^^~.`...                  <br></br>
                               .->??>?rr}v>+`|>~^?vv|.....                 <br></br>
                                .`|>>|+??+.`~.~~-~``.``...                 <br></br>
                                   ^>--`~>~``~~-`....```...                <br></br>
                              ..  ..``~-``~~->>`..``````..                 <br></br>
                     .            . `-~}+-``..`......``..                  <br></br>
                   .....            `.^}~... ...... ...                    <br></br>
                  ........     .....`.`... ......                         <br></br>
                  ....   .....```.``.........                          <br></br>
                  ...............                              <br></br>
                  ...                  ........                                    <br></br>
                  .........               ......                                      <br></br>
                  ......``..                                                          <br></br>
                  ........`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `|+.           .``````...        .      .. . <br></br>
                  `|?vv-          ..`````....      ...... ..   .  <br></br>
                .........-^^>w?.          ..``````................       . <br></br>
                  .`~~~~~~--|^~.|v^           ..````````............`..         <br></br>
                  `~~-----+??~`-??.   .       ..`````````..............          <br></br>
           `~~^>?}v>?rz>+?~..           .......```..............           <br></br>
          .~|?wzzzw}vrxr?```..            ..........  ........             <br></br>
          .-+?}zxwzuuz}r?```..              ........   .                   <br></br>
       .  .`~^+}zzxzrwv?^``...               ......                        <br></br>
           .`~~|>???vv?+```..                ...`...     `?v`              <br></br>
             .|^>>?v>~|``...          ........````..     .+?`              <br></br>
                  .  .-~`...```.....            .```````````...                      <br></br>
                  .`. .~|`.                       ..``-|^+^^|~`..                       <br></br>
    .|^-~~.                          ..`~+?????>+~.                        <br></br>
   -^^~`.                         .....`|>?>>???+|--.                      <br></br>
  .~`.     .                    .`-~~`-^>vvv?>++++~`..... .                <br></br>
                               .`|^^^|^vwwwwwv>>|~~`...... .               <br></br>
                               .-^+++?rwwwww}v??|~~-.-~||- .               <br></br>
                               .`|>?>?}rrrvv??v~^>^+-`--`. ..              <br></br>
                                .`+>>+?v}v>?>-~``|--.`-.....               <br></br>
          .                     ..~>>+^||?+~-````.``--.....                <br></br>
                              .. .`|+++|``~-->|...---`....                 <br></br>
                  .           ...~|^?^-``.`|```~|~-`..                  <br></br>
                  .....            ..-?|``..--`-`.``..                    <br></br>
                  ........     .......``...|~```.                         <br></br>
                  ....   .....`````..`..~-`...                         <br></br>
                  ...........`..... ..                         <br></br>
                  ...                  ........   .....                            <br></br>
                  .........               ......                                      <br></br>
                  ......``..                                                          <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  `|^.           .``````...               .. . <br></br>
                  .|?vv-           ..````....      ...... ..   .  <br></br>
                .........-^^>w?.          ...`````................       . <br></br>
                  .`~~~~~~--|^~.|v^           ..````````............`..         <br></br>
                  `~~-----^??~`-??.   .       ..`````````..............          <br></br>
           `~~^>?v?>?rz>^?~..           .......```..............           <br></br>
          .-|?rzzzw}vrxr?```..            ..........  ........             <br></br>
          .-+v}zzwzuuz}r?```..              .......    . .                 <br></br>
       .  .`~^+}zzxzrwv?^``...               ......                        <br></br>
           .`~~|>???vv?+-``..                ...`...     `??`              <br></br>
             .|^>>?v?||-`...          ........```...     .+?`              <br></br>
                  .`~`...````....            .```````````...                      <br></br>
      `. .~|`.                       ...``~^+>>+|-..                       <br></br>
    .|^-~~`                         ....-^>??????+`                        <br></br>
   `^^~`.                           ....->??>>??>>+>~                      <br></br>
  .~`.     .                      .   ..|??v??>++++^~```...                <br></br>
                  .......`?wwwwwwzv||^~`````..`               <br></br>
                               ...`~+>?wwwwwwwzz^~~~|>?v}> `.              <br></br>
                               ..`^???vrwwwwwwr++>--~++?+- ..              <br></br>
                                .`>??>vxzwww}??-~^^^~~+-.....              <br></br>
          .                     .`+???>vv}}v?-`->`-~||`....                <br></br>
                              .. .~>???+++>>+>````||~`....                 <br></br>
                     .           ..-^+>>+|--`|+--~>>+~`..                  <br></br>
                   ....            ..``>+--.~>>|+-`~~..                    <br></br>
                  ........     ....``.`~`..vv~^+-.                        <br></br>
                  ....   .....```....~..??^`.`.                        <br></br>
                  ...........~`.-~`...                         <br></br>
                  ..                  ........   .......                          <br></br>
                  .........               ......                                      <br></br>
                  ......``..                                                          <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .|^.           .``````...               .. . <br></br>
                           .|>vv-           ..````....      ...... ..   .  <br></br>
                  .........-^^?w?.          ...`````................       . <br></br>
                  .`~~~~~~--|^~.~v^           ..````````............`..         <br></br>
            `~~-----^??~``>?`   .       ..`````````..............          <br></br>
           `|~^>?v?>?rz>^?|..           .......```..............           <br></br>
          .-|?rzzxw}vrxr?```..            ..........  ........             <br></br>
          .-+v}zzwzuuz}r?```..              .......    ...                 <br></br>
       .  .`~^+}zzxzrwv?^``....              ......                        <br></br>
           .`~~|>????v?+-``..                ...`...     `??`              <br></br>
             .~^>??v?||-`...          ........```...     .+?`              <br></br>
                  .  .`~`...````....            .```````````...                      <br></br>
      `. .-|`.                       ...``~^+>>+|-..                       <br></br>
    .|^-~~`                         ....-^>??????+`                        <br></br>
   `^^|`.                           ....->??>>??>>+>~                      <br></br>
  .~`.     .                      .   ..|??v??>++++^~```...                <br></br>
                  .......`?wwwwwwzv||^~`````..`               <br></br>
                               ...`~+>?wwwwwwwzz^~~~|>?v}> `.              <br></br>
                               ..`^???vrwwwwwwr++>--~++?+- ..              <br></br>
                                .`>??>vxzwww}??-~^^^~~+-.....              <br></br>
          .                     .`+???>vv}}v?-`->`-~||`....                <br></br>
                              .. .~>???+++>>+>````||~`....                 <br></br>
                     .           ..-^+>>+|--`|+--~>>+~`..                  <br></br>
                   ....            ..``>+--.~>>|+-`~~..                    <br></br>
                  ........     ....``.`~`..vv~^+-.                        <br></br>
                  ....   .....```....~..??^`.`.                        <br></br>
                  ...........~`.-~`...                         <br></br>
                  ..                  ........   .......                          <br></br>
                  .........               ......                                      <br></br>
                  ......``..                                                          <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .~^.           .``````...        .      .. . <br></br>
                           .~>vv-           ..````....      ...... ..   .  <br></br>
                  .........`^^?w?.          ...`````...... .........       . <br></br>
                  .`~~~~~~--|^~.~v+           ..````````...............         <br></br>
            `~~-----^??~``>?`   .       ..`````````..............          <br></br>
           `|~^>?v?>?rz>^?|..           .......```..............           <br></br>
          .-|?rzzxw}vrxr?```..            ..........  ........             <br></br>
          .~+v}zzwzuuz}r?```..              .......    ...                 <br></br>
       .  .`~^>}zzxzwwv?^``...               ......                        <br></br>
           .`~~|>????v?+-``..                ...`...     .??`              <br></br>
             .~^>??v?||-``..          ........```...     .+v`              <br></br>
                  .  .`~`...````....            .```````````...                      <br></br>
                  `. .-|`.                       ...``-|^+++|-`.                       <br></br>
    .|^-~~`                         ....`~+??????>~.                       <br></br>
   `^^|`.                           ....-^??????>>>+|-.....                <br></br>
  .~`.     .                      .   ..-^????>>++++^-`-`.....             <br></br>
                               ........|>?}rrrrv+^^|-``````~. .            <br></br>
                               ...``~|^?}wwwwwwwv>~~~|>vvv-|` .            <br></br>
                               ...~^+>?vwwwwwwwvv?~~-~|>^|... ..           <br></br>
                                .`|>???rwwwwr}}??~~+^||-``...              <br></br>
          .                     .`^???>?}rrr}??+>``-~~-``..                <br></br>
                              .. .~>???>??????|-..`-`-```                  <br></br>
                     .           .`^>>>>+^-``|^~~`~|||||`                  <br></br>
                   ....           .`|^+?>^|`-~~-^|-~~-~-`.                 <br></br>
                  ........     ....`-~|^|~`+~`~+~```. .                   <br></br>
                      .....   ....```````^~`|`~>-````                      <br></br>
                  ...........``.`..~-`...                      <br></br>
                  ..                 .........   .....~-.. .                      <br></br>
                  .........               ......       .. .    .                      <br></br>
                  ......``..                            .......                       <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ..........        .           <br></br>
                  .~^.           .``````...        .      .. . <br></br>
                           .~>vv~           ..````....      ...... ..   .  <br></br>
                  .........`^^?w?`          ...`````.....  .........       . <br></br>
                  .`~~~~~--`~^~.~v+           ..````````...............         <br></br>
            `~~-----^?>~``>?`   .       ..`````````..............          <br></br>
           `|~^+?v?>?rz+^?|..           .......```..............           <br></br>
          .-~?rzzxw}v}xr?```..            ..........  ........             <br></br>
          .~+vrzzwzuuz}r?```..              .......    ...                 <br></br>
       .  .`|^>rzxxzwwv?^``...               ......                        <br></br>
           .`~~|>????v?+-``..                ...`...     .??.              <br></br>
             .~^>??v?||-``..          ........```...     .>v`              <br></br>
                  .  .`~`...````....            .```````````...                      <br></br>
                  `. .-|-.                       ...```~^+++^~`.                       <br></br>
    .|^-~~`                          ...`-^>??????^`.                      <br></br>
   `^^|-.                           ....`~>??????>>>>?~.```..              <br></br>
  .~-.     .                      .   ..`|???>>>+++++|-|-``..`             <br></br>
                               ........``+vvv}}v?>^^^--`~|>?`.`            <br></br>
                  .......`^vwwwwwwzuw^~|~^?vwrv+ `.           <br></br>
                               ....`-|>?wwwwwwwzw}?~~~~++?>~. ..           <br></br>
                                 .`^>???rwwwwwww}|~?>+||++.....            <br></br>
          .                      .~?????rzwwww}?>^~^`|^^|-...              <br></br>
                              .. .|????>vzrr}vv+.`~^.-~|-..                <br></br>
                     .           .~>????>>>???>>+```-^>+|.                 <br></br>
                   ....           .~>???>++~~~--+>~~-^++^.                 <br></br>
                   ........      ..`-|^++++^~.`~?^^>|`...                  <br></br>
                      .....   ....```````>>-` ~r?~?>|.                     <br></br>
                  ............~`..`r?+~`-.                     <br></br>
                  ..                 .........    .`.`v?^` `.                     <br></br>
                  ........               ......       ~`.`....`                      <br></br>
                  ......``..                           .`......                       <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .~^.           .``````...        .      .. . <br></br>
                           .~>vv~           ..````....       ..... ..   .  <br></br>
                  .........`^^?w?`          ...`````.....  .........       . <br></br>
                  .`~~~~~--`~^~.~v+           ..````````...............         <br></br>
            `~~-----^?>~``>?`   .       ..`````````..............          <br></br>
           `|~^+?v?>?rz+^?|..           .......```..............           <br></br>
          .-~?rzzxw}v}xr?```..            ..........  ........             <br></br>
          .~>vrzzwzuuz}r?```..              .......    ....                <br></br>
       .  .`|^>rzxxzwwv?^``...               ......                        <br></br>
           .`~~|>????v?+-``..                ...`...     .??.              <br></br>
             .~^>??v?||-``..          ........```...     .>v`              <br></br>
                  .  .`~`..`````....            .```````````...                      <br></br>
                  `. .-|-.                       ...```~^+++^~`.                       <br></br>
    .||-~~`                          ...`-^>??????^`.                      <br></br>
   `^^|-.                           ....`~>??????>>>>?~.```..              <br></br>
  .~-.     .                      .  ...`|???>>>+++++|-|-``..`             <br></br>
                               ........``+vvv}}v?>^^^--`~|>?`.`            <br></br>
                  .......`^vwwwwwwzuw^~|~^?vwrv+ `.           <br></br>
                               ....`-|>?wwwwwwwzw}?~~~~++?>~. ..           <br></br>
                                 .`^>???rwwwwwww}|~?>+||++.....            <br></br>
          .                      .~?????rzwwww}?>^~^`|^^|-...              <br></br>
                              .. .|????>vzrr}vv+.`~^.-~|-..                <br></br>
                                 .~>????>>>???>>+```-^>+|.                 <br></br>
                   ....           .~>???>++~~~--+>~~~^++^.                 <br></br>
                   ........      ..`-|^++++^~.`~?^^>|`...                  <br></br>
                      .....   ....```````>>-` ~r?~?>|.                     <br></br>
                  ............~`..`r?+~`-.                     <br></br>
                  ..                 .........    .`.`v?^` `.                     <br></br>
                  ........               ......       ~`.`....`                      <br></br>
                  ......``..                           .`......                       <br></br>
                  .......`...                                                          <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .~^.           .``````...        .      .. . <br></br>
                           .~>vv~           ..````....       .....  .   .  <br></br>
                  .........`^^?w?`          ...`````.....  .........       . <br></br>
             .`-~~~~--`~^|.~v>           ..````````...............         <br></br>
            .~~-----^?>~``>?`   .       ..`````````..............          <br></br>
                  `|~^+?v?+?rz+^?|..           .......```..............           <br></br>
          .-~?rzzxw}v}xr?```..            ..........  ........             <br></br>
          .~>vrxzwzuuz}r?```..              .......    ....                <br></br>
       .  .`|^>rxxxzwwv?^``...               ......                        <br></br>
           .`~~^>?v??}?+-``..                ...`...     .>?.              <br></br>
            ..~^>??v?||-``..          ........```...     .>v`              <br></br>
                  .`~`..`````....            .```````````...      .               <br></br>
                  `. .-|-.                       ...``-~^+++^~`.                       <br></br>
                  .||-~~`                          ...`~+???????^``..                    <br></br>
   `^+|-.                           ....-^???????>>>??~.```...             <br></br>
  .~-.     .                      .  ..`-^??>>>>+++++|-~-``....            <br></br>
                               .........`+vvv}vv?>^^^-``-^+?|...           <br></br>
                               .........>wwwwwwzuz>~|~-?vrrv>...           <br></br>
                               .....-^|^wwwwwwwwzwv|~~-^^>>~.....          <br></br>
                                 .`|>?>?rwwwwwwwr>`>?+|||+`.....           <br></br>
                                 .~>????rwwwwwrv?+~|-~^+^`...              <br></br>
                              .. .|????>vzwrr}v>`--^`.-~~..                <br></br>
                                 .|?????>?>????>+`...~++^`.                <br></br>
                   ....          .`^>???>+^~~~-->?|~~|>>+~.                <br></br>
                   ........      ..`|+>>++++~```~+++^-..`.                 <br></br>
                      .....   ....```-~~|>>|-..??>^>^..                    <br></br>
                              ...........~+-...+v>---.                     <br></br>
           ..                  ........   .`...|v>-.`..                    <br></br>
                  ........               ......       `-..-`....                     <br></br>
                  ......``..                            `...... .                     <br></br>
                  .......`...                             .                            <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-^.           .``````...        .      .. . <br></br>
                           .->vv~           ..````....      ......  .   .  <br></br>
                  ........ `^+?wv`          ...`````.....  .........       . <br></br>
             .`-~~~~-``~^|.-v>.          ..````````............`..         <br></br>
            .~~-----^?>~``>?`   .       ..`````````..............          <br></br>
                  .~~|+?v?+?rz+|?^..           .......```..............           <br></br>
          .-~?}wzxw}v}xr?```..            ..........  .........            <br></br>
          .~>vrxzwzuuz}r?```..              .......    ....                <br></br>
       .  .`|^>rxxxzwwv?^``...               ......                        <br></br>
           .`~~^>?v??v?+-``..                ...`..      .>?.              <br></br>
            ..~^>??v?||-``..          ........```...     .>v`              <br></br>
                  `~`..`````....            .```````````...      .               <br></br>
                  `. .-|-.                       ...``-|^+++^~`.                       <br></br>
    .~|-~~`.                         ...`~>???????^~~-.                    <br></br>
   `^+|-.                           ....->???????>>>?v^.`--`..             <br></br>
  .~-.     .                      .   .`~>??>>>>+++++|--~`-````            <br></br>
                               ........``+??vvvv?>^^^~`--^+?v^.`           <br></br>
                               .........+wwwwwwwux?~|~-?vv}vv> `.          <br></br>
                               ....`-+-~zwwwwwwzzwr+~-~+++>|-..`.          <br></br>
                                 `|>??>?rwwwwwwwwv->?+^||>^.....           <br></br>
                                 .^>????rwwwwww}??~||-^^^|`..              <br></br>
                              .. .|????>rxzwr}v?-|~^+.--|`..               <br></br>
                                 `^????>>v?vvvv?+~````^>+~.                <br></br>
                   ....          .->????>++^^^^~>?+-~~>>>+-                <br></br>
                   ........      ..-+>??>+++|~```+>++~````.                <br></br>
                  ....   .....```~|^+?++~-.~?v|??`.                    <br></br>
                             ............>v^`~`-}?|>|`.                    <br></br>
                  ..                 .........   ``.-``r?+|.`.                    <br></br>
        ........                .....       .^.`>?|-.`.                    <br></br>
                  ......``..                            |`...  .`                     <br></br>
                  .......`...                             .......                      <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-|.           .``````...        .      .. . <br></br>
                           .->vv~           ..````....      ......  .   .  <br></br>
                  ........ `^+?wv`          ...`````.....  ......`..       . <br></br>
             .`-~~~~-``~^|.-v>.          ..```````.............`..         <br></br>
            .~~-----^?>|``>?-   .       ..`````````..............          <br></br>
                  .~~|+?v?+?rz+|?^..           .......```..............           <br></br>
          .-~?}wzxw}v}xr?```..            ..........  .........            <br></br>
          .~>vrxzwzuuz}r?```..              .......    ....                <br></br>
       .  .`|^>rxxxxwwv?^``....              ......                        <br></br>
           .`~~^>?v??}?+-``..                ...`..      .>?.              <br></br>
            .`~^>??v?||-``..          ........```...     .>v`              <br></br>
                  `~-..`````....            .```````````...      .               <br></br>
                  `. .-|-.                       ...``-|^+++^~`.                       <br></br>
    .~|-~~`.                         ...`|>???????^~|-.                    <br></br>
   `^+|-.                           ....~>???????>>>??|`---`...            <br></br>
  .~-.     .                      .   .`~>??>>>>+++++|--~``.`...           <br></br>
                               ........`-+??vvvv?>^^^~`--+>vv> .           <br></br>
                               .........^rwwwwwwux?~|~`>vvrv?>...          <br></br>
                               ....`-``-wwwwwwwwzwr>~--|^+>|-....          <br></br>
                                .-+>>++?wwwwwwwwwv-+?>+|~>^.....           <br></br>
                                .`^>????rwwwwww}v?|~|-^^^|``.              <br></br>
                              .. .|?????rxzwwr}v~|~|>.-~|`..               <br></br>
                                 `^????>>vvvvvv?^-````|>+|.                <br></br>
                   ....          .~>????>++^^^^|+?+--~+>>+|.               <br></br>
                   ........      .`|>???>+++|~```|>++|-```.                <br></br>
                      ....   .....``-|++>>++|~.-?v^>?``.                   <br></br>
                             ........````>?^`~.`v?|+|`.                    <br></br>
           ..                 .........  .~~.~..}v+^...                    <br></br>
       .........               ......       .^..>v^~...                    <br></br>
                  ......``..                            ~- ..   ..                    <br></br>
                  .......``..                             `.  ....                     <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-|.           .``````...        .      .. . <br></br>
                  .-+vv~           ..````....      ......  .   .  <br></br>
                  ........ `^+?rv`          ...`````.....  .........       . <br></br>
             .`-~~~~-``~^|`-v>.          ..```````.............`..         <br></br>
            .~~-----^?>|``+?-   .       ..`````````..............          <br></br>
           .~~|+>??+?rz+|?^..           .......```..............           <br></br>
          .-~?}wzxw}v}xr?```..            ..........  ........             <br></br>
          .~>vwxzwzuuz}r?```..              .......    ....                <br></br>
       .  .`|^>rxxxxwwv?^``....              ......                        <br></br>
           .`~~^>?v??}?+-``..                ...`..      .>>.              <br></br>
            .`~^>??v?||-``..          ........```...     .>v`              <br></br>
                  `~-..`````....            .```````````...     ..               <br></br>
                  `. .`|-.                       ...``-|++++^~`.                       <br></br>
    .~|`~~`.                         ...-|>???????^~^|`   ...              <br></br>
   `^+|-.                           ....~>???????>>>??^`-~-`.`.            <br></br>
  .|-.     .                      .  ..`|???>>>>+++++|~---``|``.           <br></br>
           .                   ........`->??vvvv?>^^^~`~->?vrv.`.          <br></br>
                               .........|}rwwwwwux?~~-`>rvrv?>` `          <br></br>
                               ....`|.``rwwwwwwwzww?~-`^++>^~...`          <br></br>
                               .-^?>>|^>wwwwwwwwwr-+?>+||+>`....           <br></br>
                                ^>>?????rwwwwwwrv?+~|~^^^^-`.              <br></br>
                              ....^?????}xzwwr}v+^|~>``~~~..               <br></br>
                                 `^????>?vv}vvvv^~````~>+^`                <br></br>
                   ....          .|?????>++>>>>^^v>~--^>>>^.               <br></br>
                   ........      .`^>???>+++|~~-`~>++|~````.               <br></br>
                      ....   .....``~^>>>>++^~.->}>^v~`.                   <br></br>
                             .......`````??+-|.-vv^+>`..                   <br></br>
           ..                 .......... .>+.|..vv++` `                    <br></br>
                  .........               ......       .^..+v+^. `                    <br></br>
                  ......``..                            ~| .`.  .`                    <br></br>
                  .......``..                             -........                    <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-|.           .``````...        .      .. . <br></br>
                  .-+vv|           ..````....       .....  .   .  <br></br>
                  ........ `|+?rv`          ...`````.....  .........       . <br></br>
             .`-~~~~-``~^|`-v>.          ..```````.............`..         <br></br>
            .~~~----^>>|``+?-   .       ..`````````..............          <br></br>
           .~~|+>??+?rz^|?^.            ......````..............           <br></br>
          .-~?}wzxw}v}x}?```..           ...........  .........            <br></br>
          .~>vwxzwzuxxrr?```..              .......    ....                <br></br>
       .  .`|^>rxxxxwwv?^``....              ......                        <br></br>
           .`~|^?vv?v}?>-``..                ...`...     .>>.              <br></br>
            .`~^>??v?||-``..          ........```...     .>}`              <br></br>
                  `~-.``--```...            .```````````...     ..               <br></br>
                  .. .`|-.                       ...``-|++++^~`.                       <br></br>
    .~|`~~`.                         ...-|>???????^~^|`   ..               <br></br>
   `^+|-.                           ....~>???????>>>??|`--`. ..            <br></br>
  .|-.     .                      . ...`|>??>>>>+++++|~--```~`..           <br></br>
           .                   ........`~>??vvvv?>^^^~`-`+?v}v...          <br></br>
                               .........~vrwwwwwux?~~~`+}v}v?+` .          <br></br>
                  .....`.``rwwwwwwwzww?~-`+^^+|-...`          <br></br>
                               .-^?|~||>wwwwwwwwwr~^?>+^|++`....           <br></br>
                              ..+?>+>???rwwwwwwrv?^-~~^^^^-`.              <br></br>
                              ...`^>????}xzwwr}v>^|~>``-~~..               <br></br>
                                 .^????>>}v}}vvv^-.````++^-.               <br></br>
                   ....          .|?????>++>>>>^|?>~--|>>++`               <br></br>
                   ........      .`^>???>+++|~~-`->++|~````.               <br></br>
                      ....   .....`-|+>>>>+>^~.`^v?|v~`.                   <br></br>
                      ..     ......```--`??+~|.`?v^^>-..                   <br></br>
           ..                 .......... .>+.~..?v++` .                    <br></br>
       .........               ......       .^.`^v>^` .                    <br></br>
                  ......``..                            ~| .`..  .                    <br></br>
                  .......``..                             -........                    <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-|.           .``````...        .      .. . <br></br>
                  .-+?v|           ..````....       .....  .   .  <br></br>
                  ......  `|+?rv`          ...`````.....  .........  .    . <br></br>
                  .`-~~~~-``-^|`-v?.          ..```````.............`..         <br></br>
            .~~~----^>>|``+?-   .       ..`````````............`.          <br></br>
           .~~|^>??+?rw^|?+.            .......```..............           <br></br>
          .-~>vwzxwvv}x}?-``..           ...........  .........            <br></br>
                  .~+vwxzwzxxxrr?```..              .......    ....                <br></br>
       .  .`|^>rxxuxzwv?^``....              ......                        <br></br>
           .`~|+?vv?v}?>-``..                ...`...     .>>.              <br></br>
            .`~|>??v?||-``..          ........```...     .?}`              <br></br>
                  `~-```--```...            .```````````...     ..               <br></br>
      .. .`|-.                       ...``~^+>>+^~`.                       <br></br>
    .~|`~~`.                         ...-^>???????^|++-     ..             <br></br>
   `^+|~.                           ...`|????????>>>?>~`-`... .            <br></br>
  .|-..    .                      . ...`+???>>>>+++++|~````-+| ..          <br></br>
           .                   ........`|>???vv??>+^^|.-->v}r}~ .          <br></br>
                               ........`-vrrwwwwxuv~~~`|r}vv?+- ..         <br></br>
                                ..`....`vzwwwwwwzww?~-`>>+>>`. ...         <br></br>
                              .`^+?``~~>wwwwwwwwww+|>>>>^^+^....           <br></br>
                             .?????~+???}wwwwwwr}?^`~~|^^^-``              <br></br>
                              -|>+^+????vzzwwwrv?^^~^|.--~-..              <br></br>
                                ..^????>>}}}}vvv?~..```^>^^`.              <br></br>
                   ....          `^?????>++>>??>^?>|---++++^.              <br></br>
                   ........      .->????>>++|~~~~-+>+^~-```.               <br></br>
                      .....   ....`~+????>++^|`.-?v~?+``.                  <br></br>
                      ..     ......``~||~??+~~`~?r>|?+``.                  <br></br>
           ..                 .......... .>>`-`||v>++...                   <br></br>
       .........               ......      ..|.~-?>>^..`                   <br></br>
                  ......``..                            -| .~|~. .`                   <br></br>
                  .......``..                             `.......`.                   <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .`|.           .``````...        .      .. . <br></br>
                  .`+?v|           ..````....       .....  .   .  <br></br>
                  ....... .|+?rv-          ...`````.....  .........  .    . <br></br>
                  ..--~~~-``-^^`-v?.          ..```````.............`..         <br></br>
            .~~~----^>>|``+?~   .       ..`````````............`.          <br></br>
           .~~|^>??^?rw^|?+.            .......```..............           <br></br>
          .-~>vwzxwvv}x}?-``..           ...........  .........            <br></br>
          .~+}wxzwzxxxrw?```..             ........    ....                <br></br>
       .  .`|^>rxxuxzwv?+``...               ......                        <br></br>
           .`||+?vv?v}?>-``..                ...`...     .>>.              <br></br>
            .`~|>??v?||-``..          ........```...     .?}`              <br></br>
                  .~-```--```...            .```````````...     ..               <br></br>
                  .. .`|~.                       ...``~|+++^|~`.                       <br></br>
    .~|`~~`.                         ...-|>>>>>>?>|~^^`     ..             <br></br>
   `^+|~.                           ...`|>>>>>>>>++>>>~`-`... .            <br></br>
  .|~`.    .                      . ...`^>>>>>>>++>>+|~`````^~ .           <br></br>
           .                   ........`|>>>?????>+^^^.`-+?vvv- .          <br></br>
                               ........`-?v}rrrrzxv|~~`|}v??>|` ..         <br></br>
                                ..`....`vwwwrrrrrww?~-->+|+^`. ...         <br></br>
                             ..`|^>``-~+rrwrrrrrrr^|>>>>^^+|....           <br></br>
                             .????>~+>>?}wwrrrrrv?^`~~|^^^-...             <br></br>
                             .-|>+|+>??>vzzwrrrv?^^~+|.--~`..              <br></br>
                                ..^>>??>>}}v}}vv?~`.```^>^^`.              <br></br>
                   ....          `^>>???>++>???>^?>~`--++++^.              <br></br>
                   ........      .-+????>>++|-~~-`^>^|~`````..             <br></br>
                      .....   ....`~+>???>+>^~``~?v~?+``.                  <br></br>
                      ..     ......``~||~??>||`^?r>|?+-`.   ..             <br></br>
           ..                 .......... .>>`-`^^v>>+...       ..          <br></br>
                  ........                ......      ..^`~~???+..`                   <br></br>
                  ......``..                            ~|..||~` .`                   <br></br>
                  .......``..                           . `.....```.                   <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .`~.           .``````...        .      .. . <br></br>
                  .`^?v^           ..````....       .....  .   .  <br></br>
                  ....... .|+?rv-          ...`````.....  .........  .    . <br></br>
                  .--~~~-``-^^``v?.          ..```````.............`..         <br></br>
            .~~~----^>>|``^?~   .       ..`````````............`.          <br></br>
           .~~|^>?>^?ww^~?+.            .......```..............           <br></br>
          .-~>vrzxwvv}x}?-``..           ...........  .........            <br></br>
          .~+}wxzwzxxxrw?```..             ........    ....                <br></br>
       .  .`|^>rxxuxzwv?+``....              ......                        <br></br>
           .`||+?vv?v}?>-``..                ...`...     .++.              <br></br>
            .`~|>??v?^^-``..          ........````..     .?}`              <br></br>
                  .~-```---``...            .```````````...     ..               <br></br>
                  .. .`|~.                       ..````````````..                      <br></br>
                  .~|`-~-.                         ...``````---`.......                  <br></br>
                  `^+|~`                           .........`~  .|+|.  .                  <br></br>
  .|~`.    .                      . . ..`....--.-~zzr>. .                  <br></br>
                  .                   ........`....``..~^wrr?` .                  <br></br>
                  ...........~v+ ..-+rv^??-`..                <br></br>
                             .. .........-}?^`` .~?>~|++``.                <br></br>
                           `>?~. .....`-~+?++^~`.``->-++|~--~||`           <br></br>
                          .+???>`. ..-^>??+++|~~~~~~>?^~~~>++++~           <br></br>
                          .+????^` .~>????>++++>>^->?`~-``^+~~`.           <br></br>
                           `-^-.  .`^?????>+vu}vv?^.`~++~.-|~~...          <br></br>
                   ....     ..    .`+?????+vwxx}vvv???-``.-~^|-``.         <br></br>
                   ........      ..`^??????rwwxuvvvv?+.`|+?+^?>^. ...      <br></br>
                  .....   ....``-+?????rwwwzrvvvvv^??+~~+||^`..  ..    <br></br>
                      ...    ......```~^+>>rwwwwwrvvvvv>-~`-vv|??>|.  -    <br></br>
                  ..                 .............?wwwwwwrvvv?~|~.-^|?vvv?. `.    <br></br>
       ........                ......      .>v}rrwww}>|||~-`````~+- ..     <br></br>
       ......``..                         .~>???????>+++^+^~-``... ..      <br></br>
      .......``..                         .->????>>>?>+++++>+```````       <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .`~.           .``````...        .      .. . <br></br>
                  .         `^?v^           ..````....       .....  .   .  <br></br>
                  ....... .|+?rv-          ...`````.....   ........  .    . <br></br>
                  .--~~--``-|^``v?.          ..```````.............`..         <br></br>
            .-~~----^>+|-.^?~   .       ..`````````............`.          <br></br>
           .~~|^>?>^?ww^~?+.            .......```..............           <br></br>
          .-~>vrzxwvv}x}?-``..           ...........  .........            <br></br>
          .~>}zxzwzxxxrw?```..             ........    ....                <br></br>
       .  .`|^>rxxuxzwv?+``....              ......                        <br></br>
           .`||+?vv?vr?>-``..                ...`...     .++.              <br></br>
            .`~|>??v?^^~``..          ........````..     .?}`              <br></br>
                  .~-``-~--``...            .```````````..      ..               <br></br>
                  ..  `|~.                       ..`````````````..                     <br></br>
                  .~|`-~-.                         ...`````---`......                    <br></br>
                  .^+^~`                           .......--. .-||.  .                    <br></br>
  .|~`.                           . . ..`..-. `~r}v>` ..                   <br></br>
           .                   ........`...``.^`r}vv?..`..                 <br></br>
                  ............~`.|`vrv|??^-`..   .            <br></br>
                  ..........~v~.`.`|v?~~^+-````|^^`           <br></br>
                         .>||   ........`}?^~~...``|~^>^~~>>+++`           <br></br>
                        ~????`     ...``~v+++|-`---->v|---|^|~-.           <br></br>
                       .+????~..   ..`-|+>++^~~~|~`+v--||-`~|~~`...        <br></br>
                       .`|^^~~.    .`~+???>++^??v?|..~|>^~.-~^^~``....     <br></br>
                   .... ..         .-+?????>+zxvvvv?+>?|```|?^>?v^.   ..`  <br></br>
                   ........      ...~>????>^vxuwvvvvv?>~.^??^~^|-|>|~.  -. <br></br>
                      .....   .....`~>?????>}wrzx}vvvvv?+?|~`->v^?}v?+  `. <br></br>
                      ..     .......`|>?????vrwrrzvvvvvvv?-~-.|>|>+>>^  `  <br></br>
           ..                 .......`-^>????rwwwrrr}vvv}+~~~``----``. ..  <br></br>
       ........                .........`-~~->rwwwwwwwww?|||||~-````....   <br></br>
       ......``..                            .~?vv}}}vv>++++++^^|``....    <br></br>
      .......``..                            .->>>??>>>>>>++>>>>~          <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  `~.           .``````...        .      .. . <br></br>
                  `^?v^           ..````....       .....  .   .  <br></br>
                  ....... .~+?rv~          ...`````.....   ........  .    . <br></br>
                  .--~~--``-|^``v?.          ..```````.............`..         <br></br>
            .-|~----^>+|-.^?~   .       ..`````````............`.          <br></br>
           .~||^+?>^?ww^~?+.            .......```..............           <br></br>
          .-~>vrzxwvv}x}?-``..           ...........  .........            <br></br>
          .~>}zxzwwxxxrw?```..             ........    ....                <br></br>
       .  .`|^>rxxuxzwv?+``....              ......                        <br></br>
           .`||>?vv?vr?>-``..                ...`...     .++.              <br></br>
            .`~|>??v?^^~``..          ........````..     .?r`              <br></br>
                  .~-``-~~-``...            .```````````...     ..               <br></br>
                  ..  `|~.                       ..```````````...                      <br></br>
                  .~|`-~-.                         ...``````--`...                       <br></br>
                  .^+^~`.                          ......`--`.    ..                      <br></br>
                  .|~`.                           . . ..`--. .^??|. ..                     <br></br>
           .                   ........``~   |rr}}?` .--.                  <br></br>
                  ..........`..-.vrrwrv?^--.  .^^-            <br></br>
                               ..........`` ---wr?`^>>``~-|?>>^.           <br></br>
                       ..|`     .........-`. ~-~?>||-^^^~~>+|^- ...        <br></br>
                     .^>>v>`       ...``~}|...````-~~?^~-``~~~-``-`.       <br></br>
                    .?????>.  ..   ...``?v+|--`.`---v?---|`.~~^~`|+`     . <br></br>
                    .|^>?>|.       ..``.+?++|~~~~|~`>``^>|-.-~+^vv}^^^-`   <br></br>
                   .....`|-        ..```->++^|~^??+|``+>+```|v+-^~`|rv}v>  <br></br>
                   ........      ....`-~>?>++^?rvvvvv???+.`>}^`|+?++?>?+>  <br></br>
                      .....   ....````|>????>^zu}vvvvvvv??^v|~-``>^~|-~``  <br></br>
                      ...    ........`^?????>+}xuw}vvvvvvvv}?~~`.`--`-``.. <br></br>
           ..                 .......`|??????>vr}rzxr}vvv}rx?~~~-`-```-``. <br></br>
       . ......                .......`^???????rw}v}}}}rrwww+|||^^|~^`.    <br></br>
       ......``..                      .~+>?????}wwwwwwwwwv++++++>>??.     <br></br>
      .......``..                       ..`~~|~``+v}rrr}v>^>>>+++>^`       <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  `~            .``````...        .      .. . <br></br>
                  `^?v^           ..````....       .....  .   .  <br></br>
                  ......  .~+?rv~          ...`````.....   ........  .    . <br></br>
                  .`-----``-|^``v?.          ..```````.............`..         <br></br>
            .-|~---~^>+|-.^v|           ..`````````............`.          <br></br>
           .~||^+?>^?ww|~?>.            .......```..............           <br></br>
          .-~>vrzxwv?}x}?-``..           ...........  .........            <br></br>
          .~>}zuzwwxxxrw?```..             ........    ....                <br></br>
       .  .`|^>rxxuuzwv?+``....              ......                        <br></br>
           .`||>?vv?vr?>-``..                ...`...     .+^.              <br></br>
            .`~|>??v?^^~``..          ........````..     .?}`              <br></br>
                  .~-``-~~-``...            .```````````...     ..               <br></br>
                  ..  `~~.                       ..```````````...                      <br></br>
                  .-|`-~-.                         ...````````-..                        <br></br>
                  .|+^~`.                          ..............                         <br></br>
                  .|~`.                           . . ..`....`.....                  ..    <br></br>
                  .       .                    .... ..`..  ..`....   -^~`    ..  ...  ..  <br></br>
                               .........      ..-^~`.`>>^~. .``~`..      . <br></br>
                               ........ .>>~-..|?~.`-~>^~~--|..`>+`-->+?`  <br></br>
                      .  .      ...... .vvvvvv?}?>.`|~+|~~~~^~-?+>^>}????. <br></br>
                   .~~>+>v-        .. .~?}v?w?--|+~|~~-...-~|+^~~`.->}>>-. <br></br>
                   `??????^`  ..   .. .-`|+z??~-^-|?|~.|^`.`|?|-^^>r^|``.. <br></br>
                  .`|????+.        ..``.~|-|-^^~~-~}^`^>?|``+v-~~~+^````--.<br></br>
                    .`|+-.         ..`~|``--```.`-`}+.|+?~`.^?-~~-``-~|~`` <br></br>
                   ....-...      ....``~~-~-```-~--. ~???>-``+|~||~|^^^|>> <br></br>
                      .....   ....`````^>^^^^|^^~+|~+vvvv??v????~|||^+++}+ <br></br>
                      ...    ........``-v>>>^|||+vvvvvvvvvvvv??v?||^++>?+  <br></br>
                              ..........~vvv?+^^?r}vvvvvvvvvv??}r^+++++`   <br></br>
         ......                ...........^??>>>>xxxxxxxzzzz}vvwv^>>>>^.   <br></br>
       ......``..                         .^???>+vwrrrrwwwr}}}}v+>???>+-.  <br></br>
      .......``..                         .|>>>>>+?}rrr}rrrrr}?>>>>>>>^``. <br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  `~            .``````...        .      .. . <br></br>
                  `^?v^           ..````....       .....  .   .  <br></br>
                  ......  .~+?rv~          ...`````.....   ........  .    . <br></br>
                  .`-----``-|^``v?.          ..```````.............`..         <br></br>
                  .-~~---~^++|-.^v|           ..`````````............`.          <br></br>
           .~|||+?>^?ww|~?>.            .......```..............           <br></br>
          .-~>vrzxwv?}x}?-``..           ...........  .........            <br></br>
          .~>}zuzwwxxxrw?```..             ........    ....                <br></br>
       .  .`|+>rxxuuzwv?+``....              ......                        <br></br>
           .`||>?vv?vr?>-``..                ...`...     .^^.              <br></br>
            .`~|>??v?^^~``..          ........````..     .?r`              <br></br>
                  .~-``-~~-``...            .```````````...     ..  .....        <br></br>
                  ..  `~~.                       ..```````````...        `.  .....     <br></br>
                  .-|`-~-.                         ...````````-..         `. .--. ....   <br></br>
   .|+^~`.                          ..............         `. .^}}vv~`  `  <br></br>
  .|~`.                           . . ..`....`...      .-~``.`>vrrwv.^..`  <br></br>
   .       .                    .... ..`..........``.  `~^>?+>?vwww+`^ ~`  <br></br>
                               .................`^>?+`.-`.|}?~.~wrv`^``-|  <br></br>
                               ........``````````^>^+?+~|-->^+?>rz^-~`|.|~ <br></br>
                   -`.`         ........`````````~+^||+~~|^>~~>~.`..`^-~+v~<br></br>
                .|+}?>?+~.         ....`````~|`.-~~~~-````->}|`-...`|~^>+?|<br></br>
                .~????>v}^    ..  ...```````|+|..~~--.-^^```>r~-~--||||+>>~<br></br>
                 .~?????|-        ..``.    .^w??||^~-.^?>^``..`^>>^~~~^++>`<br></br>
                  .?^|-..          .-  `|>>??}+^^-??~`|^|>>|.~?vvvvr+|++>?`<br></br>
                  .~.  ....      ...`  }}x}uv``~^||?}>`|?????vvvvvvuu?^???`<br></br>
                      .....   .....`` `zvxvu}+>>^~--+? `??vvvvvvvvxxuu+>??`<br></br>
                  ...    ......`` ++|++??~^^~ -|-. .?vvvvvvvvuxvwy?+??`<br></br>
                              ......- .~--~-~-``  -|~>.?vvvvvvvruzv}zw????`<br></br>
         ......                ......`...........`||~>^?vvvvv}wzrv}rwv????-<br></br>
       ......``..                    ....`-```-|^+||~vv?}vvvv}rrrwwwv?????`<br></br>
      .......``..                         ..||^+>+^^^?}?}r}}}rrrrrr?`-~~||.<br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  `-            .``````...        .      .. . <br></br>
                  `^?v^           ..````....       .....  .   .  <br></br>
                  ...... .~+?r}~          ...`````.....  .........  .    . <br></br>
                  .`-----```|^``v?.          ..```````.............`..         <br></br>
                  .-~~---~^++|-.|v|   .       ..`````````............`.          <br></br>
           .~|||+?>|?ww|~?>.            .......```..............           <br></br>
          .-~>vrzxwvv}x}?-``..           ...........  .........            <br></br>
          .~>}zuzwwxxxrw?```..             ........    ....                <br></br>
       .  .`|+>rxxuuzwv?+``....              ......                        <br></br>
           .`||>?vv?vr?>-``..                .............^^.              <br></br>
            .`~|>??v?^^~``..          ........`````..   .-?r-              <br></br>
                  .-~`-~~~-``...            .``````````.  .`~-  `|~`.`..     ..  <br></br>
      ..  `~~`                       ..``````-`. .|?>^`|>. `.`^^++~...`~~-.<br></br>
    .-|`-~-.                         ...````--  .^?????-`>` -.-+^^^^|+>>>>`<br></br>
   .|+^~`.                          ........`~  .`>??vvv^ ^` -`^++++++>>>>`<br></br>
  .|~`.                           . . ..`....-`  .-??>?vvv-| `~++^+>|+>>>>`<br></br>
   .       .                    .... ..`......`. .~?-.`???+..`|++^^^^+>>>>`<br></br>
                               ..................+?>^-^?>`.-~~~~^^^|+^+>>>`<br></br>
                 .`            ........``````.`-~-|???~~|--~~~|+vwwwzr}??>.<br></br>
                 |?~>`          ........``````....``~^+^+>>>``????vzwwzzrv`<br></br>
               .>??vv>`.           ....`````.....`-~||~~||+^ `+++>+vz}}}rw|<br></br>
             .|+??????+...    ..   ...`````..``-~^|~~`..`-`...^>>>>>}w}}}r~<br></br>
             `~~+?????+..          .........^?>>>+^-`.-+>>-^+-+????>?z}}}r~<br></br>
                .`~>++`            ........`^++^+^^~..|?++^>>+vvvvvv?zr}rr~<br></br>
                   ``  ....      ......````````-~~-~~..`~|`^>?vvvvv}}wrrrr~<br></br>
                      .....   .....`...-``.`...`---~||..``.`>>vvvv}}}wwrrw~<br></br>
                      ..     ......`. .........``.`-||~>??v^  `??vvvrwrrrw~<br></br>
                              ...............```. `^+^`+>++^.. ^vvv}wwwrr}-<br></br>
         ......                .......        .`-+?v>|-?>..`~-~|+?v}vv??>>-<br></br>
       ......``..                             .--+?v~`|v?~..`-|~~|?v?>>>>?-<br></br>
      .......``..                            .~..-+?~->?v?~...|+--~^++>>>>`<br></br>


            </div>,

            <div>

                  ...........       .           <br></br>
                  .-            .``````...        .      .. . <br></br>
                  `|?v^           ..````....       .....  .   .  <br></br>
                  ...... .~+?r}~          ...`````.....  .........  .    . <br></br>
                  .`-----```|^-`v?.          ..```````................         <br></br>
                  .-~~---~^++|-.|v|           ..`````````............`..         <br></br>
           .~|||+?>|?ww|~?>.            .......```.........````-|-.        <br></br>
          .-~>vrzxwvv}x}?~`...           ...........  ....`|^^||||~```     <br></br>
          .~>}zuzwwxxxrw?```..             ........   .`-~^+>>>>>>>>>^`.   <br></br>
       .  .`|+>rxxuuzwv?+``....              ......   .|>>????????????>|-` <br></br>
           .`||>?vv?vr?>-``..                ...`... .~>>???????????????>~ <br></br>
            .`~|>??v?^^~``..          ........```....`+?>?????????????>>?+.<br></br>
            .~~``-~~--`...            .````````.`|+>?>>>>>>??????????>>>>?`<br></br>
      ..  `~~`        ..             ..``````|||>>>>+++++++>>???>>>???????`<br></br>
     -|`-~-.                         .`. ..`-|~-``|>>+|^^^+++>>?vv????>??>`<br></br>
   .|+^~`.                          .-.  .`````.`~||^++||~^^^?rxuuuzzr}v?+.<br></br>
  .||`.                           ..~.  `--^>?v?+`.-|~^^||~?wuuxzwwwwwwwzr-<br></br>
                  ..       .                    ...--  |w??v||+~|^- ..`+|~vyuuuzwwwwwwwwwz~<br></br>
                  .          ....|  .wvvyvruwvvv^`  .+~vxzzzxxuuzwwwwwww~<br></br>
           `|-.`|-`|-          ...--  |x?zr?xwvwwv^` .~|~?r}wwwwwzxuxzwwww~<br></br>
           .?v??????~.          ..-`  .-^r?}u+-`^^v?.~~~|`>vvvrwwwwwzxzzww~<br></br>
            `v??????v|            ``.   .``?v>``-^+?~|>r?  .^?vv}wwwwwwzww~<br></br>
            ~???????`         ..   .```..  .^?+?}^`|+?>^`.|~+?vvvrwwwwwwrr~<br></br>
            .^???>+-`              ...`````-???v>~+++~-`~++>v>?vvv}rrwr}vv`<br></br>
             ||~r~. ...            .......`^^+~..|^|--.`??^>??>>??vvvvv?vv`<br></br>
             .  `  ........      ....`.....-``..||~~~`.^>^|^--++^..-??>|?v`<br></br>
                      .....   .....```.........`|^+^|-.-~-`-```--   .`|>+~.<br></br>
                       ..    ................  `+?>+^~-````--->z?--`-~~~~~.<br></br>
                              ..........      ->>++++^~~|||~+??^-|^^^+>>+~.<br></br>
         ......                .......       .>>^^|||~-~||^^+|++~`....`|>>.<br></br>
       ......``..                             .-~~-``-~~-`|?>|?rv^>+~. `~^`<br></br>
      .......``..                               ...  .--..v?+~~>vrzv+v..~- <br></br>


            </div>,

            <div>

                  .... `+~-----~~|||^^^~`......```````````````-~-~-.  ..       <br></br>
            `-`..`-~|~-````---~|^|^^+^|-````--~~~~~~~---``-||||-|>??|  . . <br></br>
                  .~-.     ..``-`.  ..-^^~||||^^^~-``-~~~~----``````~||?vv??v+.    <br></br>
         `~`   ..`-~-`...`~||^^-``-~~|||||^~-~~~~~~---```-^>?vv}}}}}vv?^-. <br></br>
        `~.  .`--||>??vv?|``^+~`.````-~||||^|~~~~~~--``^?}rrrr}}vvvvvvvvv?`<br></br>
       -~.  .+??>|.  `~+?vv?~.`--``````-|^^^|~~~~~~--|?rr}}}}vv???????????-<br></br>
      `-.   |wwrv????v>  `^>??|.`~~`````~|^^|~~~~~~->}r}vvvvv???vv????????`<br></br>
     `-    ~rwzv}xuxzwv-.....|??~ `~~````~|||~----->}}}}vvvvvvvvvvv?v?????`<br></br>
    .-.   `vwz}ruxxxrvrz}?+~  .~?+ .-|-``-|||~----^}}}}}rrwzzzzwrrr}}v????`<br></br>
    `.   .>v}??xuxxrvwxxrv>|``...>~ .`````~|~-----v}vv}}wzzzxxxxxzrrr}}vvv`<br></br>
   .`    .?v>^}wzzv?wxwrv^|?v?>|..` .`..``--`---`+r}v?v}rrrrwwzzxxxzzrrr}v`<br></br>
   `.    .~+-+}rwv>rzr}v+>vv???>|``.``````````-||vv>>?}rrrrrr}rrwzxxxzr}r}-<br></br>
   .`     .`||?v?|?}vv?>?????>^-`-..``---~||^>?v??>???v}}}rrrrrrrrwwzxwrrr-<br></br>
    .`.   .-?+-``-~>??~`.`-^>|`.``.```-~|^^|>vv?>+|>+++++>v}}rrr}}}}rwwzzw-<br></br>
     ~^`....+>+~|+|^-~~`.. .`-``+>.`-|^~---~>>|~-.~+>>+^+^>?v}rrrrrrrrrwzz-<br></br>
   .|+^|-`.-|>>>>>>>+^|..``..`->??``~^~--~~++-```....~|`~^++?}wwwwwwwwwrwr`<br></br>
  .||`.  .`|>>>>>>>>>^.. ...```>v?``~-`~^||>^|||-. .  .`-^+?vrwwwwwwwwwwr}-<br></br>
  ..      `^>>>>>>+^++>~``-~-`..?+`~~.`^>+^|--~`-`.-|`+?~|?}}}}rrrrrrrrr}}-<br></br>
         .~^>+>++>^+^--`-+??|``.`--~`.->+++^^++||~|~.-^>~>vv}}}}}}}}}}}}}}-<br></br>
       .`~||>>+>>>>>^.`|??>~``````...`|>^++^+^^|+??|.-|^>vv?vv?v}}}}rv>v}}-<br></br>
           .}v+>?>>>>?????+``````.``.--~|+^^+~.`>?v>|>~|^|+vvv>~~+v}v>?v}}-<br></br>
            .```~``-~~``..``--``..`--````--|^` -???>-|+>|.~-~`....`-~?}}}?.<br></br>
                  .``....      .`-~-..``~--`````--.~?v^|~-^+|`.`......`~|?www?~.<br></br>
             .......       .``....-----~--|~` ~?^.`-~-`..~~-~````>?vzxz>-`.<br></br>
             ...`.   .           .``-~~~|~--.....-``.`..---~``.`>>?wr}+``-.<br></br>
                  .   .......      ...|^^+^|~`........``.````-``~^^`|--`..``.<br></br>
                  .....   ....`|^^+^^^^|~``-~~|||~````.```-`.      .`-.<br></br>
                       .     .....~^^^^^^|~~|~`-^^^||~~--~~|^|+rv?>>+|. .~.<br></br>
                              ...`|^^^||--```-|^^^|||~^>vv?v}>?v??>-|vv~...<br></br>
        .......                 .-^^|~-```````^|||~~-|^|++++++v}}v?|`.>?|. <br></br>
       ......``..               ..`-`````````.`~~~~--++~-~~?rwzxz}}>`  |?| <br></br>
      .......``..                 .....```..  .``--`+?^~..^+?}}rwwv??+..|+.<br></br>


            </div>,

            <div>

     -^|``.  .|?vr?- .~^^|~--~|^^^^^|~---------~vr}}vvvvvvvvvvvvvvvvvvvvvv-<br></br>
    ~rzwr?~`.`..`>rxv- `^++^|~|+>>>>+^^^|||||~^ruuuuzwwwwwrrrwwwwwwwwrrrrw~<br></br>
   |wzww}}zz}?^.   -?z?` -^+^~-~^++++^|^^|||~^zuxxxxxxxxxxxzzwwwwwwwwwrrrr~<br></br>
  |zwwrvrzzzv>^||~-- `?r> `^++^~~|+++^|||||||wuuxxxxxuuuuuuuuyuxwwwwwwwrrr~<br></br>
 `rzwvvwzzw?|>rxxzr?`  `?v`.|>++||^++||||||~vuuxxzwwwzzxuyyyyyyyuzzwwwwwww~<br></br>
 ^ww??wzz}>+}uuxxz?^~-`.`+}. ~+++||^|||||||+xuxwrrrrrrwwwzxuyyuuyuuuxwwwww~<br></br>
 ~>?>wzw?+vwxxxur??}xxr?+`+^ `|~~~||||~~|||}uzwrrrrrwwwwwwwwzuuyyuuyuzwwww~<br></br>
 `||+v}>?rwzxzx}vzuuuxxz}?|. ~^|~-~~|||||^+wv?vvv}rwwwwwwwwwwwzxxuuuuzwwww~<br></br>
 `~~^+|^}wwxuv?}uuuxwzwv?v^.`--~|||^++++>?vv^?????rwwwwwwwwwwwwwwwzuuzwwww~<br></br>
 .`-~|~|^?wz}v???vvrvv????`-~---|++++>?}wzv?->????vrrrrwwwwwwwwwwwwwzzuzww~<br></br>
   .--|^^+v>~???>??????>?+-|||^++++^>rww}v>?.+>??vvvvvvv}wwwwwwwwwwwwwuzww~<br></br>
   ....-||^``v???????v?^|-^^^>>>>+^^+v}?>+^>.^.`~?vvvvvvvrwwwwwwwwwwwwzzww~<br></br>
      `-~|+??v???????v??v?>+>>>>+++^>r>^+^|^.`.  .+~~vvvv}rwwwwwwwwwwwwwr}-<br></br>
 `.    .~>?v?????????????^~^+>+++^^>r?^^^||~-     `^+vvvvv}rwwwwwwwwwr}}vv-<br></br>
  .``. .||?????????????+~-~~|^++>+^?}^||~~||- `v~|v}v?vvvvvvv}}}}}}}}vv}}v-<br></br>
   .||~|????????????v??|~-`-~||+>>+^|~||~~||~`^>~?vv?+>?vvvvvvvvvvvvvv}}}}-<br></br>
  .||~^>>>>??}v????vv?ww| |+~||^>>^|||||~~|-.`++.??????v>??>?v}}}vvv}v>>?v-<br></br>
  ..    ..?w}v}v?????>?~.+??~||^>^~||||||~``^??>^?>?vvvv>>v?~-~^?}vvvv?>?v`<br></br>
         `w}v+>????+~~` +??||^+^|||~|||||..^v??+^??+?^>??+^~    .|>+|`-?v?.<br></br>
          ... `-..|-`-.|??||^++~~~~~~~~|~ .+??>+>>>+||+>v.          .^?>^`.<br></br>
                 .~~-``?v^||^^~~~~~~~-~|`.~>?>+^^+>>>+`---`.....``~?vw?^|`.<br></br>
               .`|^^|`~?|-~^^~~~~~~|~-^|. ~++~^>>>>>^.`~~~~---.~^|vzzr+^|^.<br></br>
                  .~`~|^^|--`-~^~---~~~|-^+|. `+^~|^+~|~.`~~--~--`~^|?zzx?+|^|.<br></br>
                  .~|`-~-...`~||-----~||||^^~-`. .-~-...`~~-`~~~`~++?zrv?|^~~~.<br></br>
               .||`......-^~~--~|^+>+^|~|||-. ...--~~~--~~~-|+^^?+||||~|~~.<br></br>
                .-........``.`^++^>+++++|~~||~~~-~----~~~~-~^~----`..  .-~.<br></br>
                      .....  ~+++>+++>>>+^|~~~~~~~-~~~~~|^~?????>~-`.    `.<br></br>
                       ..   -+++>+++++^^+++^~~~~~~~^>?vrwz>wzwwzw?+?v>`    <br></br>
                           .^++>++++^|||||^|-~~~~~>?rzzzw}?zwwxuuur|-?w>.  <br></br>
         ......           .|++>+++^|||||~~~~-~~~-~~|`+?>>?rxuuuxux}>  |}}~ <br></br>
       ......``..         `^^>+^||||||~~~~~--~~~|?>?|`>+|vwuuuuuzv???^~-??`<br></br>
      .......``..         `------~~~~-~~~~~---->}}?^+|``-~|>wxz}?>?wzw| `>.<br></br>


            </div>,

            <div>

     |>^``   .^?rwv-  ~^^|~---|^^^^^|~---------~vwww}}vvvvvvvvvvvvvvvvvvvv-<br></br>
  . ~zuxzv|`.``.`>wuv- `+>+^|~~^>>>>+||||||||~^wyyyyzzwwwwwwwwwwwwwwwwwwww|<br></br>
   ^xuuuwzuxwv>.   -vxv`.~+>+|-~^++++|||~~||~^zyyyyyyuuuuuuxxzwwwwwwwwwwww~<br></br>
  ^uuuxrzuxuz?>^^~`` -vz?.`^>+^~~^+>+|~~|||||zyyyyyyyyyyyyyyyyuxwwwwwwwwww~<br></br>
 `zuur}xuxx}>?wuuxr?`  `?}`.|>>+^|^++|~~|~|~}yyyuxzzzxxxuyyyyyyyuzzwwwwwww~<br></br>
 >uu}vxuuw??wuuuuzv?|~~.`+}. ~+++||^||~||||+yyuzwwwwwwwwwzxuyyyyyyyuxwwwww~<br></br>
 +?v?uuxv?}xuuxuw??rxuzv?-+| `||~~||||~~|||}yxzwwwwwwwwwwwwwzuyyyyyyyzwwww~<br></br>
 ~>>?rw?vzuxxzz}vzuuuxuuw?^. ~|~~~~~||||^++z}vv}}rwwwwwwwwwwwzxxuuyyyzwwww~<br></br>
 -^^+>+>zuxuuv?}uuuxwxwv?v^ `~~~~|^+++++>?v}+?vvvvrwwwwwwwwwwwwwwwzuyzzwww~<br></br>
 .-~|^+>?vzzv?????vrv?>>>>`-|~~~|+>>+>vrxuvv~??vvvv}rrrrwwwwwwwwwwwwxzuzww~<br></br>
   .-~>>>>?+~???>>??>>>+>^-~~~|++>+^?wxxw}??.>?vvv?vvvvv}wwwwwwwwwwwwwuxww~<br></br>
   ....~+^|``v???>??>??|~`|~|++>>++>>}w?>>>?.+.`~?vvvvvvvrwwwwwwwwwwwwzzww~<br></br>
      `~|++?vv???????v????+^>>++++>>?z?>>>^^.`.  .+-~vvvvvrwwwwwwwwwwwwr}v`<br></br>
 ``.   .|??v?????????????^-|++>>++>?zv>+||~~-     .^+vvvvv}rwwwwrwwwwr}vvv`<br></br>
  .``. .>+vv???????????+~~~||^+>++^vr^|~~~||` `v|^v}??vvvvvvvvvvvv}}vvvvvv`<br></br>
   .|~~|?vv?????????v??|~``-|||^+>+^|~||~~~~-.^>~v}vv+>?vvvvvvvvvvvvvvvvvv`<br></br>
  .||~^>?????}vv???v}?zz^ ^+~|||+>^|||||~-~-.`>>.??vvvvv>>?>?vvvvvvvv?^>?v-<br></br>
  ..    ..?w}vrv?????>>~.+v?~||++^~||||~~~.`+vv>^???vvv?+>v?~-~^?vvvvv>+?v-<br></br>
         `zrv+>?>>>+~~` +??~~|++|||~~~~~|.`+v?v>+??>?+>>?>^~    .|>+~`-???.<br></br>
          ... .`..~-~~.|??|~|++|~~~~~~~|- `>v??>?>?+^|+?v.           ^?>^`.<br></br>
                 .~~~`.??|~|++||||~~~-~|`.~?v?>+^^>++^`~--`.....--|?vzv+^-.<br></br>
               .`^^^|`|?^-|^+|~|||||~-^~..^>>+>>+++>^.`~|~~~||-+?>wuuz+^|^.<br></br>
              `^~|^^^^~~~~|+|~||||||-^+|. ->>+^^+~~~.`||--~~~`^?>vuuu?^||^.<br></br>
              .^^-~|~``.-|^^~||~~||^||^^|-``..~~- .`-||~-~~~`|>>?xrv>~~--~.<br></br>
               .^^-`..`..-+||~~|^++?>^|~~|~-. ..`-~~|~~~~|~-^>>+?+~~~~~|~~.<br></br>
                .-`.......``.`+>++?>++++~-~||~~~||~~~~~~~~-|+|---``.   .~|.<br></br>
                      ....   |>+>?>++>+>+^~-~~~~~~-~~~~~|^~??vvv?~-`.    ..<br></br>
                      ...   ->+>?++>+^^^+>+|`-~-~~~^>?}wxu>zuxzzw?+?v?`    <br></br>
                           .++>?++++||~||^^^-~|~~~?vwuxzwv?wwzuuuur^-?w?`  <br></br>
         ......           .^>>?+++^|||||~~~~-~|~-~~^`+?>??rxuuuuuxv>  ~}}| <br></br>
       ......``..         -^+?++^|||~|~~~~~--~~~|?>v+`>+|vzuuuuuw?>?v>|-??`<br></br>
      .......``..         `~--~~~~~~~-~~~-~~~~-?rr?+^|``~++>zxzv>>vzxz^ `?`<br></br>


            </div>,

            <div>

.~||~>??>+`.  .`+v}+. `^^^~-~||^^^||~~----+>>>+>}wwwrr}}}vvvvvvvvvvvvvvvvv-<br></br>
.^^^>zuuxw?-``` .-?wr| .+>>+||^+>+++^|||~+????vxyyyyuxxxxzzzzzwwwwwwwwwwww|<br></br>
.^^?xuuxwrwzzzv~.  .+zv. |>++||^+++^^||~|????vxuxxxxuuuyyyuuuxzwwwwwwwwwww~<br></br>
.|?uuxz}rxuuxwr?>-.. `vw~ -+>+^|^^^^^|~~+??>?wzzzzzzzzzxxuyyyyyuzwwwwwwwww~<br></br>
.+xuzv}xuuxzrrwwwrr}|  ~>~.`^++^^|^^^|~~>+>?rzzzzwwwwwwwzzxuyyyyuuxzwwwwww~<br></br>
 ?xw}ruuuxwrwwrzxuzr?>+`-`~..~^^^||^|~~|^-|>zwwwwwwwwwwwwwwzxuuuyyyuwwwwww~<br></br>
.>>vwuuxwrrrrwwxxrwzxzzv> -~.`||||||||^|``|vwrrrrwwwwwwwwwwwwzzzxuyuuzwwww~<br></br>
 |>>?rzrrrrzxz}v}zxzwrrw>-~`.~||^^^^++>+``+}vvvvv}rwwwwwwwwwwwwwwzxuuxwwww~<br></br>
 ~++>>>?v?vrzv?vvrwr}}v?-^~.-|^^^^+>???>`|vv?vvvvv}rrrrrwwwwwwwwwwwzxxzwww~<br></br>
 .|^^+>>^+?|>v???+?vv?>~~|`-^^^^^+>???v}^^v|.+vvvvv}}}}}wwwwwwwwwwwwwzxwww~<br></br>
 ..`^>`^???^~v?v?????>+^|~~^^^^+++>??}rr>`|| .~v???vvvvv}wwwwwwwwwwwwwzwrr~<br></br>
  .`||.|>++>+v?????????|~~^|^++>+>??>v}?+..` . .``+vvvvvvrwwwwwwwwwwwwrrrr-<br></br>
 ...``~`^???????????????v?+|+++++vv>?v>^^-.. .   -+++vvvvvrrrrrrwwwrrrr}}v`<br></br>
.~--...`+?vv?????????????|~+++++>v?+?+|||~`.^-..``+>>vvvvvv}}}}}}rrrr}vvvv`<br></br>
 `-~--`|??v????????????^-~|^^+++++^++||~~~---``v>>}v??vvvvvvvvvvvvvvvvvvvv`<br></br>
 ..`|~-|????vv?????v}?v?^--~|^++++^^|~~|~```-``?+v}vvv????>?vvvvvvvvvv>?vv`<br></br>
  `+^|^+?vvv?vvv???vvvxx?.`-|^^^^^^|~~~-```~-|?>`?vvvv?????--|^>vvvvv?^>?v-<br></br>
   .....^>}wv?vv????>+>|.`~|^^|^^||~~|~.-~-|??>>?>v?>??v|~~.   .->>^||+?v?`<br></br>
        .`>>?+^|^>>~~~-.`-~^^|^^|||~~|`.--|?v?v>>>?|+|??-...     ....~~||-.<br></br>
            ......`~`-.`-~|^|^^|~~~~|~.```+???>+?>>++--~|.        .`~>^~~-.<br></br>
              ... |+-..--|^|^^|||~~||-.-`~^>??>+^+>+|..~~-``````~^?}r?+^^-.<br></br>
            .`.. |??^.`~|^||^||||~~^|`...`+>>++>++^~..-~~~~~~||+?}xuv>^^^+.<br></br>
          ...`~|`+??-`.`^^|^|~|||~^^||~...`|^^|~```..-~~~-~~~|+>vzx}>^|~~~.<br></br>
         .....-^^-|^...`||^^|||||||^^^^|`.  .......`~~~~~~~-~+>?vv>+^~~~~~.<br></br>
         .``...`~~`......~^|||^^+>++^|||~~-`...``~~~~~~~~~-~^+^|^|~``..`~~.<br></br>
          .``.. .```.....``~||+>>+>>++^|~~~||~~|~~~~~~~~~~-||~~~`..     .`.<br></br>
           ....       ....`~~^>>+>++>>++^|~~~~~~~~~~~~|^^^?}r}}v+|^|`.     <br></br>
                      .. .-~^>++>>++++++++^~-~~~~~^>?vwzz}vzxxxuzv+>??|.   <br></br>
                         `-~>>+>+++^||||^^|~~~~~^>vrxuxwv}vzxuuuuur^.|vv~. <br></br>
         ......         .--+>+>++^||||||~~~~~~~``~~`~>?>?rzuuuuuuw?+. `>}+ <br></br>
       ......``..       ``^++>+^||||~~~~~~~~~~~^^v?+~~>??}xuuuux}??vw?^.-?`<br></br>
      .......``..      ...~^|~~~~~~~--~~~-~~~|^?r}?|-``~||~?zzr?>>}xxx>  `.<br></br>


            </div>,

            <div>

`}rrrvvr}?>^`   `?wv` .|^^|-~^^^^|~-------vwwwrwrwwrwwrwwrr}vvvvvvvvvvvvvv-<br></br>
`xuuwzuuuxrv+-`...^}z+ .^>>+|^>>>+||||||~vyyyyyyyyyyyyyyyyyyyuzwwwwwwwwwww|<br></br>
`xxwxuuxr??wxuzv?~  |w}. |>++^^+++||||~~+uyyuuuxzzzzxuuyyyyyyyyxwwwwwwwwww~<br></br>
`zwxuxrvvzuuuuux}^.. `}w. |>++^^+^|~~~|~vyxzwwwwwwwwwwwzzxyyyyyyuxzwwwwwww~<br></br>
`wuur?vxuuxxuxv>?vww?>+??  ^++^|^^|~~~~~wwwwwwwwwwwwwwwwwwzuyyyyyyyxwwwwww~<br></br>
`ww?vzuuxxux}??}xuuuuxv|+. ~||~~~~~~~~~|v?v}}}}rwwwwwwwwwwwwzxuuuyyxwwwwww~<br></br>
.?+?xuxxuxr??vvzuxxuxrwv^ `||~~~|^^^^^+>++vvvvvv}wwwwwwwwwwwwwwwwxyxzzwwww~<br></br>
.++>?rxxw??}uw??rzw}?>??-.~|~~~^>>++?vzr-|vvvvvvv}}rrwwwwwwwwwwwwwzxxyzwww~<br></br>
.^?>>>?v^~+?r}??>??>>>>>.~||~|++++?rxuuw`-v?vvvvvvvvvvv}wwwwwwwwwwwwzuzwww~<br></br>
.|+>>>>+|||.|v???^^??>>`~|~~+>>+^>rzzzrv..v``^vvvvvvvvvvrwwwwwwwwwwwwzzwww~<br></br>
 |^+>v`|???+>}vvv????>^^~~|+>>++>>}xv???- ~-  `???>vvvvv}wwwwwwwwwwwwwwr}v`<br></br>
 -+^}}||>+?rv??????????^|^>>+++>>?x}>>>>^  .   ...~vvvvvvrwwwwwwwwwwwr}vvv`<br></br>
 .~?}>?~+?}???????????????>^+++>?zz>>+^||`   .  ~??vvvvvvv}}}}}rrrrr}vvvvv`<br></br>
.>>v>>??>?v?????????????>||>+++^vu?^|~~||-..vw^>}r}??vvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>????vvv??????????v??^`~|^++>++>|||~~|~~`~>>^v}v}>>?vvvvvvvvvvvvvvvv?vvv-<br></br>
.>>+>>>++>??vv?v???vrvrr?~~|^+>+^||||~~~-``^?^`vvvvvv}v>>?|>vvvvvvvvv?^+?v`<br></br>
 .|>>>++>}zrvr}v???v??zzz+~|^++|~|||~-~`.~>vv>????vvvv?+?v>..`-+vvvvv?++?v-<br></br>
   .|>>>wuzr?>?????>^^>+~~|^++|||~|~~~~ ~?vvv>^?v+v?+>>v|-.     .^^-``+vv?.<br></br>
     ...`~`~>^~~~>+->>>~~|^>+|~|~~~~~|` ~vv?v???>^?||+v}.           .>?^~-.<br></br>
            `|~~~`->??~~|^++|~~~|~~~|~..~vvv?++^>>^+>~~-`......``->v}v+~~-.<br></br>
             .~|- ^v?~~~|++|~~~~|~-||- -+??+>>++^>>+~.-|~-~||-+?>?uux>+~|~.<br></br>
           ..+|-.~??+-||+>^~|~~||~~+^`  -??+++++++^-.~|~-~~~-|?>>zuu?^~^+>`<br></br>
         .`.+++|`>v?~~|+>^||||~~|~^>+~` ~>|-|^^-....~|--~~~-->>>}uz?|~----.<br></br>
         ~|--^^^^-~^~|^++~|||~~||+|^+||~`.  .`...`~||~~~~~~`>?>?v>^~|||||~.<br></br>
        .|^^~~|~```.`~++|~~~|^++?>^|~~~||~`..`~~||~~~~~~~|`^>^||~~-`....-|.<br></br>
         .~^^|```````.-~~~-^>>+?>++++|~-~||||||~~~~~~~~~~-~^~~|~`.       ..<br></br>
           `||````.... ...~>>+?>+++>>>+|--~~~~~~--~~~|^>>+wzzzwv>>>>|.     <br></br>
            ..        .. `+>>?>+>>+^^++>+^--~~~~~|>?rzxuzvuzwzxuz}>>?r?`   <br></br>
                         |>+?>+>+^|||||^++~-|~~~?}wxuxzr?}wzuuuuuuv^ `?r>. <br></br>
         ......         ->+?>+++|||||||~~~-~||~`|||`??>?vxuuuuxux}>+|`~|v?.<br></br>
       ......``..      .^>?>++||||||~~~~~--||~-??}v~->??vwuuxuuw?>?wuv+.`>`<br></br>
      .......``..      .~~^+^~~~~~~~--~~--~~~+v}}?>~``~++~~}xzv>>?wxzx?  ..<br></br>


            </div>,

            <div>

`}rrrvvr}?>^`   `?wv` .|^^|-~^^^^|~-------vwwwrwrwwrwwwwrrr}vvvvvvvvvvvvvv-<br></br>
-xuuwzuuuxrv+-`...^}z+ .^>>+|^>>>+||||||~vyyyyyyyyyyyyyyyyyyyuzwwwwwwwwwww|<br></br>
`xxwxuuxr??wxuzv?~  |w}. |>++^^+++|~~~~~+yyuuuuxzzzzxuuyyyyyyyyxwwwwwwwwww~<br></br>
`zwxuxrvvzuuuuux}^.. `}w. |>++^^+^|~~~|~vyxzwwwwwwwwwwwzzxyyyyyyuxzwwwwwww~<br></br>
`wuurvvxuuxxuxv>?vww?>+??  ^++^|^^|~~~~~wwwwwwwwwwwwwwwwwwzuyyyyyyyxwwwwww~<br></br>
`ww?vzuuxxux}??}xuuuuxv|+. ~||~~~~~~~~~|v?v}}}}rwwwwwwwwwwwwzxuuuyyxwwwwww~<br></br>
.?+?xuxxuxr??vvzuxxuxrwv^ `||~~~|^^^^^+>++vvvvvv}wwwwwwwwwwwwwwwwxyxzzwwww~<br></br>
.++>?rxxw??}uw??wzw}?>??-.~|~~~^>>++?vzr-|vvvvvvv}}rrwwwwwwwwwwwwwzxxyzwww~<br></br>
.^?>>>?v^~^?r}??>??>>>>>.~||~|+>++?rxuuw`-v?vvvvvvvvvvv}wwwwwwwwwwwwzuzwww~<br></br>
.|+>>>>+|||.|v???^^??>>`~|~~+>>+^>rzzzrv..v``^vvvvvvvvvvrwwwwwwwwwwwwzzwww~<br></br>
 |^+>v`|???+>}vvv????>^^~~|+>>++>>}xv???- ~-  `???>vvvvv}wwwwwwwwwwwwwwr}v`<br></br>
 -+^}}||>+?rv??????????^|^>>+++>>?x}>>>>|  .   ...~vvvvvvrwwwwwwwwwwwr}vvv`<br></br>
 .~?}>?~+?}???????????????>^+++>?zz>>+^||`   .  ~??vvvvvvv}}}}}rrrrr}vvvvv`<br></br>
.>>v>>??>?v?????????????>||>+++^vu?^|~~||-..vw^>rr}?vvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>>????vvv??????????v??^`~|^++>++>|||~~|~~`~>>^v}v}>>?vvvvvvvvvvvvvvvv?vvv-<br></br>
.>>+>>>++>??vv?v???vrvrr?~~|^+>+^||||~~~-``^?^`vvvvvv}v>>?|>vvvvvvvvv?^+?v`<br></br>
 .|>>>++>}zrvr}v???v??zzz+~|^++|~|||~-~`.~>vv>????vvvv?+?v>..`-+vvvvv?++?v-<br></br>
   .|>>>wuzr?>?????>^^>+~~|^++|||~|~~~~ ~?vvv>^?v+v?+>>v|-.     .^^-``+vv?.<br></br>
     ...`~`~>^~-~>+->>>~~|^>+|~|~~~~~|` ~vv?v???>^?||+v}.           .>?^~-.<br></br>
            `|~~~`->??~~|^++|~~~|~~~|~..~vvv?++^>>^+>~~-`......``->v}v+~~-.<br></br>
             .~|- ^v?~~~|++|~~~~|~-||- -+??+>>+^^>>+~.~|~-~||-+?>?uux>+~|~.<br></br>
           ..+|-.~??+-||+>^~||~||~~+^`  -??+++++++^-.~|~-~~~-|?>>zuu?^~^+>`<br></br>
         .`.+++|`>v?~~|+>^~|||~~|~^>+~` ~>|-|^^-....~|--~~~-->>>}uz?|~----.<br></br>
         ~|--^^^^-~^~|^++~|||~~||+|^+||~`.  .`...`~||~~~~~~`>?>?v>^~|||||~.<br></br>
        .|^^~~|~```.`~++|~~~|^++?>^|~~~||~`..`~~||~~~~~~~|`^>^||~~-`....-|.<br></br>
         .~^^|```````.-~~~-^>>+?>++++|~-~||||||~~~~~~~~~~-~^~~|~`.       ..<br></br>
           `||````.... ...~>>+?>+++>>>+|--~~~~~~--~~~|^>>+wzzzwv>>>>|.     <br></br>
            ..        .. `+>>?>+>>+^^++>+^--~~-~~|>?rzxuzvuzwzxuz}>>?r?`   <br></br>
                         |>+?>+>+^|||||^++~-|~~~?}wxuxzr?}wzuuuuuuv^ `?r>. <br></br>
         ......         ->+?>+++|||||||~~~-~||~`|||`??>?vxuuuxxux}>+|`~|v?.<br></br>
       ......``..      .^>?>++||||||~~~~~--||~-??}v~->??vwuuxuuw?>?wuv+.`>`<br></br>
      .......``..      .~~^+^~~~~~~~--~~--~~~+v}}?>~``~++~~}xzv>>?wxzx?  ..<br></br>


            </div>,

            <div>

`rr}v??}rvv?+..  ~v?. `^^^|~|^^||~~-|>+++^vr}}}}}}}}}}rwwwrr}}vvvvvvvvvvvv-<br></br>
-xzwwxuuuuzwv+|...`?v` `+>>+^++^+^|~+^+>>vxzzzzzzzzzzzzxuyyyyuxzzzwwwwwwww|<br></br>
`wxxuuuxzrrwzzwr}?` ~?~ `^>++^|^^^|~~`|>?zzzwrwwwwwwwwwzzzuyyyyuzzwwwwwwww~<br></br>
-xxxuxzrrwzxuuuuzv||``+`..|+++||^^|--`-~vzwwr}rwwwwwwwwwwwzxuuyyyuxwwwwwww~<br></br>
`zuuzrrzxuuuuxzv}wzz}>~.~..|^|||^||~-.--v}}rrrrwwwwwwwwwwwwzzzzxuyyzwwwwww~<br></br>
`zxr}}rwxuuxzrvv}zzrr}~`|..~~|^^^+>>-.`~vvvvvv}rwwwwwwwwwwwwwwwwzxuxzwwwww~<br></br>
`r}vv?v>?}wrrrvv}v}}r?|^~.-~|^^>>??>|.`--+vvvvvv}rrrrrwwwwwwwwwwwwzzxxwwww~<br></br>
`vvv}wr}|.~vwr??vv?v>~|^``~^^|^>??>>>|-- .~?vvvvvv}}}}rwwwwwwwwwwwwwzuwwww~<br></br>
`|?}?>?v+`.-vv????>^|^^|`|^^|^+?>>?vrv~-  ..>vvv?vvvvvvrwwwwwwwwwwwwwzwrww~<br></br>
`|`^+??>||+->v?v??????|-~^^^++>???v}rv|`  . .`~+^vvvvvvvrwwwwwwwwwwwrrrrrr-<br></br>
.?~..^`|??v??vvvv?????+^~^^++>???>vv>^-..     .++??vvvvv}rrrwwwwwwwrrrr}vv`<br></br>
 |+^>|`^>vvvv??????????+^+>+^>vv>>?+|^|`..`. .`|~~+vvvvvv}rrrrrrrrrrr}vvvv`<br></br>
.-|?v??+?vv???????????????+^++?>^>>^^||~-|~..~-`??>?vvvvvvv}}}}}}}}}vvvvvv`<br></br>
.>>?+>>??v??????????????^~^>>+^^+>^||~~-`-..>r??}vvvvv????vvvvvvvvvvv>+>vv`<br></br>
.>>?>??????vv??????v}??>|^+++^^^^^~~~-```--+}?+vv}}v?????||>>?vvvvvvvv>>vv-<br></br>
.+^+>>+?v???}}v???v}}?wz}>^||^^^^~~~-.-`~-|>>-~?vvvvv?>>>-...`~>vvv?^-~>vv`<br></br>
 `~+++vwrzz??vvv??????wwz?^|^^^|~~~~.`~`~+??>??+>}v+??v`.`.    .`~-``+>??>.<br></br>
 ..`|^?vrw}?+++>??>^|-|^^|~^^^||~~|~.``.+?v?v?+>?>?^^??-.        ...```~~-.<br></br>
 ..    .-~``-`.`|>^.``~^||^^^|~~~||-.`.->v???>??>|>|-~^^.        ```||----.<br></br>
        .......``-```~|^||^^||~~||~`.~`|+???>++^>>^|``~~````.``-|?vrv+^|~~.<br></br>
         ... .--`.`-~|^||^^|||~~^|~`...^??>>>>+^^^~.``~~~~~~||+?vrux?^^|||.<br></br>
       ......>^-.`--|^||^^||||~|^|~~.  -|>>^+^-```..`~~~~~~~~+vv?w}?^|~|^^.<br></br>
      ..``-`|>?^.`-|^||^^|~|||~|^^^^|`...`-~--`...``~~~-~~~|+??+??>^||~~~~.<br></br>
    ....`|^|~|^-`.`~^||^^|||||^||^||^|~`.    ..``-~~~~~~~~^^>+^^+|~````-~~.<br></br>
   .....`^^|~--....`-~^^||^^^+++>+^~~~~|~-```-~~|~~~~~~~~~-~|~~-`..     .. <br></br>
   .```...-||~`......`~~|^^+>>+>>+++^~~~~|||||~~~~~~~~~~~|^>>>+-``.        <br></br>
    ..``...`~~``..... .-|~^>>+>>+++>>+^~-~~~~~~~~~~~|^++>vxxzzzv?>>>^-...  <br></br>
      ..... ...       `~~|>>+>>++++^++>+^~~~~~~~|+?vwxu}}}xzzxuuxr>^~??-.  <br></br>
                     .-~-+>+>>++^^|||^^+^|~~~|^+vwzuuxr?vwzxuuuxuxv~  -?>. <br></br>
         ......      `--^>+>>++^|||||||~~~~~~|~`^++|???vwuuuuxxxwv>>>~|.-+.<br></br>
       ......``..   .-`|>+>++^||||||~~~~~~~~|^|????||>>?vruuuux}?>vzur+. ..<br></br>
      .......``..  .---^^^^^|~~~~~~~~~~--~~^+?}v?+^|``^>~.~}xw?>>vzzzx?    <br></br>


            </div>,

            <div>

`rr}?>?vrrrr}?+` .?> .|^^^||^^|~---->r}}}vv}vvvvvvvvv}rwwwwrrrr}vvvvvvvvvv-<br></br>
-z}vrzuuuuuuuzv-...>?..^>>>+++^||||~}rrwrrwwwwwwwwwwwwwzxuyyyyyyuxzwwwwwww|<br></br>
`}zuuuxxuxzrvvrwzw?>?>..^>>>+^|~|~~~v?vwwwww}vrwwwwwwwwwwwxyyyyyyyuzwwwwww~<br></br>
-uuuxuuxr}}zuuuuuuuz?|| .^^^^|-~~~~~v|?v}rrr}?rwwwwwwwwwwwwzxuuyyyyzwwwwww~<br></br>
`xxxuxwrzxuuuxuwzuuxw?- `~~~~--~||||v`?vvvvvvv}wwwwwwwwwwwwwwwwzxuyzxwwwww~<br></br>
`xxxv??}zuuxxuw??rv??>..~~~~~|++>?rw?.+?vvvvvvv}rrwwwwwwwwwwwwwwwzxzuxwwww~<br></br>
`xu}^^^-~?wxuwv??+++>|.~~~~~^+?}xuux?.`~`>vvv?vvvvvvv}rwwwwwwwwwwwwwuuwwww~<br></br>
`w}vrw?+. `?vv????^>+`~||~~^++?rrzwv?-    `+vvvvvvvvvv}wwwwwwwwwwwwwzzwwww~<br></br>
`^?zv>+?>-..?v?vvv>|~||~~|+++>?zw}?>>^      ^?vv?vvvvvvrwwwwwwwwwwwwwwr}}r-<br></br>
`+`^^?v?^+??vvvvvvv???~||^++>>ruw?>>>+.      ..|-vvvvvvvrrwwwwwwwwwwrvvvvv`<br></br>
-r|  -++??vv????????????^+^+>vxx?>>+|~.       `^^vvvvvvvvv}rrrwwwwr}vvvvvv`<br></br>
`?>+~.`>>rv????????????>??>+^rur>+|~~~~. -?|.+vrvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>+????vv}???????????????>^^+>v>|~~~|~~--vw>>}rvr?>?vvvvvvvvvvvvvvvvvvvvvv`<br></br>
.?>>+>???v???????????v?>||+>>+^~~|~~~~~``++~^vvv}???}?>???vvvvvvvvvv?^~^?v`<br></br>
.?>>^^++>??vr??????vwv???>++^|~||~~~~-.`^???w?vvv}}}v?+>>``^+>vvvvvvv?++vv`<br></br>
.+^++^^}z}??}rv???vrv?wxuw>^~~~~~~-~`.|+vv?v+|v?vvvv?+|>?`    .^?v?>~.~?vv`<br></br>
.>^^++ruuxr+????????>>rwwz>~||~|~~~~ `>?vvv?^?r>+wv|+>}`        ..   |vv?^.<br></br>
.?>|^~?}vv?~~^|^>>+|~-^+>^~~|||~~~|~ `~???v???>+?v>^^}v.           `?^~~~~.<br></br>
.?|.   +>|~|-` ~+++~~~^>^~~~||~~~||` .-?vv?+>^>>+^^+~|~-`````.|||vwzx^~~~~.<br></br>
 .     .||||~ ->>+~~|^>^~~~~||~-~||` ||??>?>++^+>>^|.~|~-|||-+?>?uuuv~~~~~.<br></br>
        .~||..??>|||^++||||~||--^||. `|>v>+>+++^+^~.~|~-~|~-|>>?rwzw+|||^|.<br></br>
       `||~` >?>^|||++||||||||~|>^|`  `v?+^^^^`.`..~|~-~~~-~>?v}vv?^~~~|||.<br></br>
     .`+>>^-`>?+~||++|~||||||~~^++||-.`~`.`^|-..`~~|~-~~~--?vv?+|~~|||||~~.<br></br>
    -~-^^^^^|`~|||^>^~|||~~||^>^|||~||-.   ..`~||~~~~~~|~~?v>^~~~~-`..`-~~.<br></br>
   -^^|-|^^~``.~|^++|~||~|^+>?>++^|~~~||~--~||~~~~~~~~~~~~|~~~~`.        . <br></br>
   ~^^+^~-``````-^>+||~~^++>?>+++>++|~~~~||~~~~~~~~~~~~-~^>?vv?|~~`.. ..   <br></br>
    `^++^-`---``..```..|>+>?>+++>++>>^|~-~~~--~~~~~~^+>+vyuuxzzvv?>>?^.... <br></br>
     .~^-```...       ~>+>?>+++++^^++>>+|`~~~~~~+?}wxuy?}xwzxuuuuz?|.^?^.  <br></br>
       ...           `+++?>+>>+^|~|||^++|`~|~|>}xyyuxwv+wzxuuuxxuw?~..`^>- <br></br>
          .....     .^>+?>+>+^|||||||~~~--~~~^^|?}}??>?ruuuuxxuxv?+?v+>..+`<br></br>
       ......`...   ~>+??+++||~||||~~~~~-~|~|?>?+|+>?+>>?rxuuuw?>>ruuw+.   <br></br>
      .......``..  .++??++^|~~~~~~~-~~~--~~?}}}v^~~>||v?~.^}x}?>?wxzzxv .  <br></br>


            </div>,

            <div>

`rr}?>?vrrrr}?+` .?> .|^^^||^^|~---->r}}}vv}vvvvvvvvv}rwwwwrrrr}vvvvvvvvvv-<br></br>
-z}vrzuuuuuuuzv-...>?..^>>>+++^||||~}rwwrrwwwwwwwwwwwwwzuuyyyyyyuxzwwwwwww|<br></br>
`}zuuuxxuxzrvvrwzw?>?>..^>>>+^|~|~|~v?vwwwww}vrwwwwwwwwwwwxyyyyyyyuzwwwwww~<br></br>
-uuuxuuxr}}zuuuuuuuz?|| .^^^^|-~~~~~v|?v}rrr}?rwwwwwwwwwwwwzxuuyyyyzwwwwww~<br></br>
`xxxuxwrzxuuxxuwzuuxw?- `~~~~-~~||||v`?vvvvvvv}wwwwwwwwwwwwwwwwwxyyzxwwwww~<br></br>
`xuxv??}zuuxxuw??rv??>..~~~~~|++>?rw?.+?vvvvvvv}rrwwwwwwwwwwwwwwwzxzuxwwww~<br></br>
`xu}^^^`-?wxuwv??+++>|.~|~~~^+?}xuux?.`~`>vvv?vvvvvvv}rwwwwwwwwwwwwwuuwwww~<br></br>
`w}vrw?+. `?vv????^++`~||~~^++?rrzwv?-    `+vvvvvvvvvv}wwwwwwwwwwwwwzzwwww~<br></br>
`^?zv>+?>-..?v?vvv>|~||~~|++^>?zw}?>>^      ^?vv?vvvvvvrwwwwwwwwwwwwwwr}}r-<br></br>
`+`^^?v?^+??vvvvvvv???~||^++>>ruw?>>>+.      ..|-vvvvvvvrrwwwwwwwwwwrvvvvv`<br></br>
-r|  -++??vv????????????^+^+>vxx?>>+|~.       `^^vvvvvvvvv}rrrwwwwr}vvvvvv`<br></br>
`?>+~.`>>rv????????????>??>+^rur>+|~~~~. -?|.+vrvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>+????vv}???????????????>^^+>v>|~~~|~~--vw>>}rvr?>?vvvvvvvvvvvvvvvvvvvvvv`<br></br>
.?>>+>???v???????????v?>||+>>+^~~|~~~~~``++~^vvv}???}?>???vvvvvvvvvv?^~^?v`<br></br>
.?>>^^++>??vr??????vwv???>++^|~||~~~~-.`^???w?vvv}}}v?^>+``^+>vvvvvvv?++vv`<br></br>
.+^++^^}z}??}rv???vrv?wxuw>^~~~~~~-~`.|+vv?v+|v?vvvv?+|>?`    .^?v?>~.~?vv`<br></br>
.>^^++ruuxr+????????>>rwwz>~||||~~~~ `>?vvv?^?r>+wv|+>}`        ..   |vv?^.<br></br>
.?>|^~?}vv?~~^|+>>+|~-^+>^~~|||~~~|~ `~???v???>+?v>^^}v.           `?^~~~-.<br></br>
.?|.   +>|~|-` |+++~~~^>^~~~|||~~||` .`?vv?+>^>>+^^+~|~-`````.|||vwzx^~~~~.<br></br>
 .     .||||~ ->>+~~|^>^~~|~||~-~||` ||??>?>++^+>>^|.~|~-|||-+?>?uuuv~~~~~.<br></br>
        .~||..??>|||^++|||||||--^||. `|>v>+>+++^+^~.~|~-~|~-|>>?rwzw+|||^|.<br></br>
       `||~` >?>^|||++||||||||~|+^|`  `v?+^^^^`.`..~|~-~~~-~>?v}vv?^~~~|||.<br></br>
     .`+>>^``>?+~||++^~||||||~~^++||-.`~`.`^|-..`~~|~-~~~--?vv?+|~~|||||~~.<br></br>
    -~-^^^^^|`~|||^>^~|||~~||^>^|||~||-.   ..`~||~~~~~~|~~?v>^~~~~-`..`-~~.<br></br>
   -^^|-|^|~``.~|^++|~||~|^+>?>++^|~~~||~--~|||~~~~~~~~~~~|~~~~-.        . <br></br>
   ~^^+^~-``````-^>+||~~^++>?>+++>++|--~~||~~~~~~~~~~~~-~^>?vv?|~~`.. ..   <br></br>
    `^++^-`---``..```..|>+>?>+++>++>>+|~-~~~--~~~~~~^+>+vyuuxzzvv?>>?^.... <br></br>
     .~^-```...       ~>+>?>++>++^^++>>+|`~~~~~~+?}wxuy?}xwzxuuuuz?|.^?^.  <br></br>
       ...           `+++?>+>>+^|~|||^++|`~|~|>}xyyuxwv+wzxuuuxxuw?~..`^>- <br></br>
          .....     .^>+?>+>+^|||||||~~~--|~~^^|?}}??>?ruuuuxxuxv>+?v+>..+`<br></br>
       ......`...   ~>+??+++||~|||||~~~~-~|~|?>?+|+>?+>>?rxuuuw?>>ruuw+.   <br></br>
      .......``..  .++??++^|~~~~~~~-~~~--~~?}}}v^~~>||v?~.^}x}?>?wxzzxv .  <br></br>


            </div>,

            <div>

.>>>^~|+>>>++|+~ `>>.`||~~~~~~~~^^^^?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>>++>>????>>>?^`.->?``^^^^|||~~|^^^vrrrrrrrwwwwwwwwwwwwwzzzzzzzzwwwrrrrrr~<br></br>
.+>??>>>>>>+^+?v}||??>``|^^||~-~~~~~v?vrrrrr}}rwwwwwwwwwwwwzzzzzzzwrrrrrrr~<br></br>
.>>>>>>++++>>?wrr+~??||.`|~~~~-~---|v>vv}}}}vvrwwwwwwwwwwwwwwwzzzzwrrrrrrr~<br></br>
.>>>>>+>>???vxuw}v~-?+. .``-~--~~--+v^?vvvvvvv}rwwwwwwwwwwwwwwwwwwrrrrrrrr~<br></br>
.>>>^|^>???zuuz}vv~.`|.   ...~+^>??}v-?vvvvvvvv}}}rrrwwwwwwrrrrrrrrrrrrrrr-<br></br>
.>>+--|~~>zuxxw}}>^...  ..  `+>v}rv}?.|+|?vvvvvvvvvvvv}rrrrrrrrrrrrrrrrrr}-<br></br>
.+^^^>>^`->rzz}vv?++. .... ~>+?vvvv>` ``.`~?vvvvvvvvvvvrrrrrrrrrrrrrrr}v>^.<br></br>
.`|+^^++>^~^}r}v??>^~`~`..|????}vv?^^~```..`>vvvvvvvvvv}rrrrrrrrrrrrrr}vv?`<br></br>
.-.-^+>++>vvwr}vvvv???`~++??vv}wrv?>>?|`..``-||>+vvvvvvv}}rrrrr}rrrr}vvvv?`<br></br>
.+-..~^+??}w}}vv????v????++??v}}v???>+|``....`^??vvvvvvvvv}}rr}}}}}vvvvv^` <br></br>
.|^^~`-+>vv}}}vv?????vv??????vrv??+||||`-~>~`?}}v???v^|>??>++??vvvvvvv>-``.<br></br>
.|^^++>??vvvvvv??vv?v??????>+>??^||~~~~~^vv>?rrvv?>?v+>|``...``-|+??>~`.`>-<br></br>
.^^|--+>?vvvv??????vv???>+++++^|~~~~~~|~|>?>?vvvv??v}v?-..``````..``.. .|v`<br></br>
.+++^|~~^>vrzv?????v}v???+^+^|~~|~~~|~|+?v??v??v?vv??+-..  ....``````..-?v-<br></br>
.^^++^^>?>>?ww?+>??vv?}wrv+~-~~~~~~~|~??v???^^v?vv??|. ..      .......^v}}-<br></br>
.+^^^^????>-|+^~^?v???}xw}+~~-~|~-~~`^??????>v}^>}vvv--.            .?r}v?`<br></br>
.++^^|+>>++^-``^vvvv?|^>v?~~~~~~-~++-+>??+|?rr^~????>``          ..->??>>>`<br></br>
.+^-``.~||^+|`-vvvv??--^||^~~~~-|+>|~^>}w?|vv?>?>+>>-`--`---``-`|?v}r?>>>>`<br></br>
.-``````~^^^~.~vv?>++~```--~~-~~~~-`-}vww}v?>+++>+-`.~|~-|||~|^|?wrr?++++>`<br></br>
 ```````-^^|`.-|||~^^+~....```````..`+?vzrv>++++++..~~~~~|~~~^|+vvv}?++>>^.<br></br>
 ```````-||-.```~|~|^^~-`......`|`....~vv?>++^~``. ~|~~~~~~~^^^>????+^^^|~.<br></br>
                  ``````-|~`..`--|||^^|~|-.....`++^~-`.-^-`-||-`.-.`|~~~~~~~^+^|+^^+^^^^~~~.<br></br>
 .`````--```...~||^^^~||~..-`->^~`````.......`~|-.-~~~~~~~^+^~~^^+~-``-~~|.<br></br>
 ...```````....~||^^|~||~`-~+>|++~`..........~~`.`~~~~~~~~~~~-~^~......... <br></br>
                  ...``--`.......-|^^||~~^^``++^^+?+``....`-```..-||~~~~~~|^^++?^~~`````.   <br></br>
 ....--~`........`---``|^^-`.`-~>?||-```-~~-..`~~~~~~|^|^?>>>>?>>^|~|~. .. <br></br>
 ....``-.............`^+^^|....->|--~^^^|````~|||^+>>>?^^>>>>?>>>>^|->v>``.<br></br>
 ...````....``.......~>+++^~-`.`-`.....`..`~~|^+>>>>>>+~>>>>>>>?vrr?+^^+?+.<br></br>
 ...``-`````````....`^>+>+^++|-`.....  ..`~~~|~~^++^^|^+>>>>>vwxzr}rrv}>+v-<br></br>
 ...`--``````````...~++>>++^||||~~~``.`--~~~~^||~~|^^||~|+>>?vxwr}wxxx}^-~.<br></br>
 ..`---`````----``.`^+^>++^||~~~~~~-~~~-~~~^+++^~--|-~^|-`+rx>?}}wzzzx}~|-.<br></br>


            </div>,

            <div>

.~~~-`````````>?~^??????????????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
.||~-`````--``>}>`vvv?vvvvvvvvvv}}}}}}}}}}}}rwwwwwwwwwwwwwwwwwwwwwwr}vvvvv`<br></br>
.|~~-`.`..``--~??.|vv>?vvvvvvvvvvvvvvvvvvvvvvrrrrrrrrrrrrrrrrrrrrrrvvvvvvv`<br></br>
.-````..```-~^??>` +?^>?vvvvvvvvv?vvvvvvvvvvvvrrrrrrrrrrrrrrrrrrrrvvvvvvvv`<br></br>
 ````.`-~~~|>}w}>^  ^+.`|>?vvv?vv??vvvvvvvvvvvv}rrrrrrrrrrrrrrrrr}vvvvvvvv`<br></br>
 ````.-|||^v}vv}?+.  |.   ..~?vv???vvvvvvvvvvvvvvv}}}rrrrrr}}}vvvvvvvvvvv?`<br></br>
 `````|||+}}vvvvv+|.        -v???v???^vvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvv?+.<br></br>
 ```.-||+vvv}}}?+~|>. .    ^}v?????>..vvvvvvvvvvvvvv????>>>>>>>>???????~.. <br></br>
 ````||~^>???vv^-~+??`>+-.+rvv?????+^|?vvvvvvvvvvvvv?>>>>>>>>>>>>>>>>>>+^~.<br></br>
 ```~||---+v}vv|~^>???>vvv}}}vvvvvv?>vv??vvvvvvvvvvv?>>>>>>>>>>+>>>>>>>>>+.<br></br>
.--~||~--->rxzrv>>??vvv}vvvvvv????vvvvvvv??vvvvvvvv???>>>>>>>>>>>>>>>>>+-  <br></br>
.~~^^^~--->zxzrv+>?+?r}v???vvv??????vvv?v????vvv?????-`~+>|-`~^>>>>>>+-. ..<br></br>
.~^++^||~-?vvvvv?v?>vv?????vvv????v?????????vr}v?>???^^`       ..`~|-.   ^~<br></br>
.|+++^^^||rwv>????>?v??????????????????????v}vvv????v}v`                -v`<br></br>
.^+++++^^+wxx}++>?+??vv???????????????????????????>>>+~.               -?}~<br></br>
.+++++++++>?rr>~^+>>vvv}????????????????????????????~               .~?rzx|<br></br>
.+++++++++^|^+~-|?}v?vvxv??????v???vvv??????v}v|>?vuz`          .`-^+zxzzz|<br></br>
.++++++++^^^+|->zuxxw?vvrv????????wxv????^-vwz^`?>ruv  .....````|+>?>wzzzz|<br></br>
.++++++++^^+|`?uuuuxu???>v}vvv??vrz}?vvrw?-wr}???>vv|.````----``+++?>wzzzz|<br></br>
.+++++++^^^^-`>xzwzw}>^+??vvvvvv????vuxuuxv??>>>?>^``---.``-----^^+>?rrrrz~<br></br>
.+++++++^^+|--~^+^|?>|`>>>??????>>?+vzxzxuz>>??>??`.---``-~~~~~~^^^?w}}rr?.<br></br>
.+++++++^^^|~~~~~~~~~~~^` ~>??>>>+>>?v?vv}?>>??>?>.`-----~|||~-|^^>wv??>^|.<br></br>
.++++++^|^|||~~~~~~~~~~|- .^>>?vv?????>>>>>>???>>`.~^^^^^||||~-^^>vv??+^^|.<br></br>
.|^++++^|~|||~~~~~~-~~~~~.`|^^?v?>>>??>>>>>??>>>~ -+++^^+|~~~~~^??>>>^|^^|.<br></br>
.~~||^+^|`~|||~~~~~-~~~~~.`-^^^v???+++>>+++|`^|. `^^^^^^^^~~~-|??^+^||^^|- <br></br>
 -|||^++~``|||--~~~-~~~~~`  -+++?v?>+>>++^^... .-||^^|^^^^~~~-??^^~~^+^~.  <br></br>
.~|||+++-``~|~--~~~-~||||~.  ..~v}??+^|^^+^`. .`~~||~~||||~~~~^|^~~|^|`..`.<br></br>
.~|||+++~``-~~--~~~-~^^~~~`    -v?>~~|^+^|`...`~~||~~~~~~~~~~~~~~~|>vv}v>?`<br></br>
.~||^++^|-``-~~~~~~~~^+^--`..  `>>`   ... ..`-~~|||~~~~~~~~~~~~^?vwxxzwv??`<br></br>
.~||^++^|~``~~~|~~~~~+++|----``...      ..``-~|||||~~~~~~~~~~>vwwrr}v}wzzw~<br></br>
.~||+++|~~-`--~||~~~~^++>^~-----`````..`-``-~||~~~~~~~~|~~--|>rr}}}vvrwwrw~<br></br>
.~|^+++||~~```-~||~`-^~^^||~------~~|~~~-`-~~~~~~~~~~~~~~+?vr|?}}vv}rwwrwz|<br></br>


            </div>,

            <div>

.|+>???????>??????????????????????>???????????????????????????????????????`<br></br>
   ..`-|^>vv?vvv}vvv}+^vvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
          .>?vv+>?v}?..vvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
            `>v~ .`^-  ^vvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv?vvvvvvvvvvv`<br></br>
                  ~+    .~`.?vvvvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvv?vvvvvvvvvvvv`<br></br>
               .   .^?v??vvvvvvvvvvvvvvvvvvv?v}v???>?vvvvvvvvvvvvvvvvvvvv| <br></br>
                   |?vvvwvrvv?vvvvvvvvvvvvvvv}v?????>vvv?>|^>??vvvvvvvv>.  <br></br>
                  `?vvvrwvrww>>>????vvvvv??rv???????|`-`       ..`-||^-    <br></br>
                 .+vvvvz}vvww?>+>>>>?vrr>?rv????????|                      <br></br>
            ~~`. ~?vvvwwvvvwwv?>>>>>??v?vz}v}vvv????+                      <br></br>
.`.        |uuxz?+vvv}zvvvvrwvv??????rwwr}}}}}}rrrv?+.                     <br></br>
.~|-`      wyuuz^?r}vrrvvvv}wvv???}ww}v??????????v}rv-`-|^`              ~`<br></br>
.~|||~`.  -wxuu?^r`-???vvvv}wr}v>wzv????????????????v}vv?|            .~+v+<br></br>
.~||||~~-`+>?rr^??  |w?>???vvv}}zr????????????????????v?-          `|>??>?|<br></br>
.~||||~~~~+>>>+>?v~ vy}?>>>????r}??????????????????????????>~  ..`^???>>>?~<br></br>
.~|||~~~~~``+++?v>}~zxxu?>>>>>?}???????????????????????????^-`~~~-+>>>>>>>.<br></br>
.~||~~~~|~  ~+>vv?>wzzyu`-?>>+????v???????????????????????+^||~~~-^>>>>>>?`<br></br>
.~||~~~~|. `??vvvv??wryv  `v?}????wr??????????????????????vv???+^~|>>>>>?>`<br></br>
.~||~~~|-  >+?vvvvvv?wx+ `vzxxv???}yz???v????????????????>?????>+~~>>>>>>?|<br></br>
.~|~~~||. ~>^>vvvvv}?>w+?xyuuuur???xyw??}v???vv???vv????>>++^~~~~~~>>>>>?z|<br></br>
.~|~~~|~ .>+^?vvvvvrv??xyyuuyzyx>???rxz?????????rzzv???>||~~~~|||-~>>>>>ru|<br></br>
.~|~~||` |>>+?vvvvv}vvvwuzxwurzv^>????v??????}wzywv???>}|~||||~~~-~>>>>vuz-<br></br>
.~~~~|~  ~>?>^vvvvv}vvvvv?vvv}v?+^>>?????????vr}v??vwwzxw+~|~~~~~-|>>>?xx?`<br></br>
.~~~~|-  .+>?+>vvvvv}vvvvvvrv>^|+++++>???????????>vyuyyuuv~|~~~~~-|>>>wuv>`<br></br>
.~~|||~.  |+>>^?vvvvvvvvv??v?^+++^>>+^++>>>>>>>>++zwxuxuyx|~~~~~~-|>>}uv>>`<br></br>
.~~||||`  .+>>->vvvvvvv???++++++>+??^^>>>>+^^+|>~^v}rruuur~~-~~~~~|>vu}>>>`<br></br>
.~|||||~.  ~>-.~?vvvvv}}vv???>++++>++++>>++>>~ `-~||~+??>~~~~~~~~-|?xr>>>|.<br></br>
.~||||||`   ~...+vvvvv?>???vv?>>>>>+>>>||>>+`  `||~~~~-~~~~|~~~~|->xw>>>^~.<br></br>
.~||||||~.  .`.^+>}vvv+++++>>>>>>>+>>+-.`>|.  -||||||~~~~~~|~~~~|~?r?>>|~|.<br></br>
.~|||~|~|-  -``?+^?}vv???>>>>>>>>+>>+~...`  .-|||||~~~~~||||~~~~~~~~~||~|~.<br></br>
 ~||~~~~||. `.|>>>~>vvvv}?+>>>>>+>++>+`.   .~|||~~||~~||~~||~~|~~~~~~~~~~^`<br></br>
 -||||||||~   .+??>``?rzrvv?+++^+^>>>^`.  .~~~~~~~~~~~~~~~~~~~~~~~~~~~~+vz|<br></br>


            </div>,

            <div>

.|+>???????>??????????????????????>???????????????????????????????????????`<br></br>
  ...``~^>vv?vvv}vvv}+^vvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
          .>?vv+>vv}v..vvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
            `>v~ .`^-  +vvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv??v?????????`<br></br>
                  ~+    .~`.?vvvvvvvvvvvvvvvvvvvvvvvv???vvvvvvvv??????????????`<br></br>
               .   .+?v??vvvvvvvvvvvvvvvvvvv?vvv???>?vvvvvvv?????????????~ <br></br>
                   |?vvvrvrvv?vvvvvvvvvvvvvvv}v????>>vvv?>|^+>?????????+.  <br></br>
                  `?vvvrwvrwr>>>???vvvvvv??}v???????^`~`.      ..`-~~|`    <br></br>
                 .+vvv}z}vvrr?>>>?>>vvr}>?rv????????^                      <br></br>
            ~~`..|?vvvwwvvvrwv?>??>>??v?vz}v}vvv????+.                     <br></br>
.`.        |uuxz?+vvv}zvvvvrwvv?v????}rr}}}}}}}rrrv?>`                     <br></br>
.~||`.    .wyuuz^?r}vr}vvvv}wvv???}wwvv??????????v}rv-`~|+`              ~`<br></br>
.~|^^^~`  ~rzuu?^w`-???vvvv}wr}v>wwv????????????????v}vv?^            `|+v+<br></br>
.~|^^^^^-`>^+v}^??  |w?????vvv}}zr????????????????????v?~         .`|>??>?|<br></br>
.~||||||~~+^^^^??v~ vy}?>>?????r}??????????????????????????>|. ..`^???>>>?~<br></br>
.~|||~~~~~``~|+vv?r|xxxu?>>>???}???????????????????????????^-`~~~-^??>>>>>.<br></br>
.~|||~~~~~  -^?v}v?rzzuu`-?>>>????v???????????????????????+||~~~~-^???>>>?`<br></br>
.~|~~~~~~` `?vvvv}??rryv  `v?r????wr??????????????????????vv???+^~|>>>>>>>`<br></br>
.~|~--~~-  >+?vvv}vv>wz+ -vzxz????}yw???v??????????????????????>+|~>>>>>>?|<br></br>
.~|~-`~~. ~>^>vvv}vv>>w>vxyyuxur???xyw??}v???vv???vv?????>>>^|~~~~|??????x|<br></br>
.~|~`-~- .>++?vvvvv}v??xyuuuyzyx????rzz?????????}zzv???>|~~~~~~~|-~????>ru|<br></br>
.~~---~` ^>>+?vvvvv}vvvwuzxwuwx}+????vv??????}wwuwv???>}|||||~~~~-~?>>>vxz-<br></br>
.~~--~-. ~>?>+vvvvvvvvvvv?vv}}}v+>>??????????v}}v??vwwzxw>||~~~--`~>>>?zz?`<br></br>
.~~--~`  .>>?+?vvvvv}vvvvvvrv>>++>>+>>???????????>vyuyyuuv~~~~~-~-~+>>wxv>`<br></br>
.----~~.  |+>>^?vvvvvvvvv??v?^+++^>>+^++>>>>>>>>++zwxuzuyx|~-~~~~-~++}uv>>`<br></br>
.--~~~~`  .+>>->vvvvvvv???++^+++>+??^^>>>>+^|+|>|^v}rrxuuw||~|^||~|+?u}>>>`<br></br>
.-~||~~~.  ~>~.|?vvvvv}}vv???>++++>++++>>+++>~ `--~|~+>?>||^^^^^|-|?zr>>>^.<br></br>
.~|||~~|-   ~`..+vvvvv?>???vv?>>>>>+>>>||>>+`  `~~~----~~|^^^^^^^-+zr>>>^~.<br></br>
.~|||~~~~.  `~.^+>}vv?+++>>>>>>>>>+>>+-.`>|.  `~~~~~~--~|||^^^^^^~?}>>>^~|.<br></br>
.~||||~~~- .~``?+^?}vv????>>>>>>>+>>+~...`  .-~-~|~~~-~~||||||^||~~~~|||||.<br></br>
 `~||||~~|` `.~>>>~>vvvv}?+>>>>>+>++>+`.   .~~~~~|||~~||~~||||||~~~~~~|||+`<br></br>
 `-|||||~~~.  .+??>``?}zrvv?+++^+^>>>^`.  .~|~~~~~~~~~~~~~~~~~~~~~~~~~~+vx|<br></br>


            </div>,

            <div>

.??>?^ ~????????>????????????+?????????????????????????????????>>?????????`<br></br>
.+vvvv- .+v?>vvv?vvvvvvvvvvvv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
                  .~???.  .^.^vvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??`<br></br>
                  `^^     `+?vvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?~```........  <br></br>
       .    .+>??v?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??????>>?vv?|               <br></br>
            ~>?vvvv}}vvvvvvvvvvvvvvvvvvv???>?vv^     .?}v??^               <br></br>
           `>?vvvrrvwwr?>>>>?????vvv}?>>>>>+>vv?.   ^}v????>               <br></br>
           |?vvvvz}?rww?++>>>>>+vwwwvv>+>>>+?vvv~.`vr???????.              <br></br>
          .>?vvvwwvv}ww}?>>>>>+?wwwvv}>+>>???vvv?vwv????????`              <br></br>
   .||~~`.~?vvv}zvvvvwwrv?>>>>>rww}vvv?+>+??vvvv}wv?????????-              <br></br>
   vyuuuuv+?vvvwwvvvvrwwvv????}wwrvvv???>++??}}}rrrrrr}vv???~            `.<br></br>
  >yyuuux+?z}v}zvvvvvvwwvvvvvvwwrvvvv??????}}}vv?????vvv}r}?~         .~+?`<br></br>
 .zuuuyyr|}+^}wrvvvvvvww}vvvv}rrvvvvv?>?}w}v??????????????vr?~^+>>^.`^>???`<br></br>
 -}zxuuu?+}. .?w}vvvvv}wrvv>??vvvvvv}v?r}???????????????????vrv??+|>??>>??`<br></br>
 +???vww+?v   vuwwvvvvvwwvvv>>???????}wv??????????????????????v?|`->>>>>??`<br></br>
.>?>>>?+++}> .xywxzwvvvrw??vw?+>>???vr??????????????????????????>?^>>>>>??`<br></br>
.+>>>>>^>??w||yuzyx^vwvv?++>rv?>>>>>}???????????????????????????????>+>>??`<br></br>
.~||^>>+?vv?wvyxxyw  |}}???>?r}|~>+?v???rv???????????????????????>^~>?>>??`<br></br>
.||~` ->?vvv?wzzuy?    zyyuuz|. |v}?????wuv??????????????????????+^|>>>>??`<br></br>
.~|-  >v?vvvv>rruy^  `?zzzzuz..vzxzwv???}yu}??vv?????????????????vvvv?????`<br></br>
.~|. .vvvvvvvvvvwx|^?v>????>vwwyyyuyuv???xyu???}????vv??????v?????????????`<br></br>
.|`  `vvvvvvvvr??}v?>++vvvv}xyyxuyzxyr????wuu}?????????vvrwzr????????>>++>`<br></br>
.~   ~>vvvvvvvrvv???>>?}vvvuuxyxxyxzu?>?????v}??????v}vuyuzv???>>>+^^++>>>`<br></br>
..  .+>vvvvvvv}vvvvvv?v}vv?vuwzzwwwwv|>>???????????vzxuzrv?????|~~~-|?>>>>`<br></br>
    `>?vvvvvv}vvvvvv???v}v+|>??>>?vv?^+>>>????????????????vv??w>~||~~>>>>>`<br></br>
   -++?vvvvvvvvvvvvv?vvv}?^+^^>>>+++++>+++>>>????????????zyuyuxzv~~~->>>>>-<br></br>
  ~?>^>vvvvv?vv}vvvvvv???++++>>>>?>+++++++++++>>>>>>>>+>xyzuyuyuw~~~->>>>v^<br></br>
  >>>^+?vvvvvr}vvvvvv??>++>>>>>>>>>^+>>>>>>+>>>>+~|>+^>vwzuuzxuuyv~~-+>>?u|<br></br>
  +>>>^?vvvvv}}vvvv}}v??>>>>+>++>>++>>>>>>>|+>>^-~-~+>+?}v}wuxzyx>~~->>>zu|<br></br>
  ^?>>++vvvvv}}vvvv}vvvvvvv?>>>>>++>>>>>>>++^+- `||~-|~-^~-^?}zv|~|~-+>wuu|<br></br>
  `?>>>|>vvvv}rvvvv?>>??????>>>>>+>>>>>>>>+>^|. -|~||~~~~~~~~|~~~||~-+ruux~<br></br>
   +>>>+^?vvvvwvvvv>++++>>>>>+++++++++>>+^>>+^  -~~~~~~~-~~~~~~~~~~~-^??v?.<br></br>


            </div>,

            <div>

.??>?^ ~????????>????????????+???????????????>?????????????????>>?????????`<br></br>
.+vvvv- .+v?>vvv?vvvvvvvvvvvv>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
                  .~???.  .^.^vvvvvvvvvvvvvvv?vvvvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvv????`<br></br>
                  `^^     `+?vvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvvv?~``.........  <br></br>
       .    .+>??v?vvvvvvvvvvvvvvvvvvvvvvvvvvvv??????>>?vv?|               <br></br>
            ~>?vvvv}}vvvvvvvvvvvvvvvvvvv???>?vv^     .?}v??^               <br></br>
           `>?vvvrrvwwr?>>>>?????vvv}?>>>>>+>vv?.   ^}v????>               <br></br>
           |?vvvvz}?rww?++>>>>>+vwwwvv>+>>>+?vvv~.`vr???????.              <br></br>
          .>?vvvwwvv}ww}?>>>>>+?wwwvv}>+>>???vvv?vwv????????`              <br></br>
   .||~~`.~?vvv}zvvvvwwrv?>>>>>rww}vvv?+>+??vvvv}wv?????????-              <br></br>
   vyuuuuv+?vvvwwvvvvrwwvv????}wwrvvv???>++?v}}}rrrrrr}vv???~            `.<br></br>
  >yyuuux+?z}v}zvvvvvvwwvvvvvvwwrvvvv??????}}}vv?????vvv}r}?~         .~+?`<br></br>
 .zuuuyyr|}+^}wrvvvvvvww}vvvv}rrvvvvv?>?}w}v??????????????vr?~^+>>^.`^>???`<br></br>
 -}zxuuu?+r. .?w}vvvvv}wrvv>??vvvvvv}v?r}???????????????????vrv??+|>??>>??`<br></br>
 +???vww+?v   vuwwvvvvvwwvvv>>???????}wv??????????????????????v?|`->>>>>??`<br></br>
.>?>>>?+++}> .xywxzwvvvrw??vw?+>>???vr??????????????????????????>?^>>>>>??`<br></br>
.+>>>>>^>??w||yuzyx^vwvv?++>rv?>>>>>}???????????????????????????????>+>>??`<br></br>
.~||^>>+?vv?wvyxxyw  |}}???>?r}|~>+?v???rv???????????????????????>^~>>>>??`<br></br>
.||~` ->?vvv?wzzuy?    zyyuuz|. |v}?????wuv??????????????????????+^|>>>>??`<br></br>
.~|-  >v?vvvv>rruy^  `?zzzzuz..vzxzwv???}yu}??vv?????????????????vvvv?????`<br></br>
.~|. .vvvvvvvvvvwx|^?v>????>vwwyyyuyuv???xyu???}????vv??????v?????????????`<br></br>
.|`  `vvvvvvvvr??}v?>++vvvv}xyyxuyzxyr????wuu}?????????vvrwzr????????>>++>`<br></br>
.~   ~>vvvvvv}rvv???>>?}vvvuuxyxxyxzu?>?????v}??????v}vuyuzv???>>>+^^++>>>`<br></br>
..  .+>vvvvvvv}vvvvvv?v}vv?vuwzzwwwwv|>>???????????vzxuzrv?????|~~~-|?>>>>`<br></br>
    `>?vvvvvv}vvvvvv???v}v+|>??>>?vv?^+>>>????????????????vv??w>~||~~>>>>>`<br></br>
   -++?vvvvvvvvvvvvv?vvv}?^+^^>>>+++++>+++>>>????????????zyuyuxzv~~~->>>>>-<br></br>
  ~?>^>vvvvv?vv}vvvvvv???++++>>>>?>+++++++++++>>>>>>>>+>xyzuyuyuw~~~->>>>v^<br></br>
  >>>^+?vvvvvr}vvvvvv??>++>>>>>>>>>^+>>>>>>+>>>>+~|>+^>vwzuuzxuuyv~~-+>>?u|<br></br>
  +>>>^?vvvvv}}vvvv}}v??>>>>+>++>>++>>>>>>>|+>>^-~-~+>+?}v}wuxzyx>~~->>>zu|<br></br>
  ^?>>++vvvvv}}vvvv}vvvvvvv?>>>>>++>>>>>>>++^+- `|~~-|~-^~-^?}zv|~|~-+>wuu|<br></br>
  `?>>>|>vvvv}rvvvv?>>??????>>>>>+>>>>>>>>+>^|. -|~~~~~~~~~~~~~~|||~-+ruux~<br></br>
   +>>>+^?vvvvwvvvv>+>>>>>>>++++++++++>>+^>>+^  -~~~~~~~-~~~~~~~~~~~-^??v?.<br></br>


            </div>,

            <div>

.+????????>?????????????+^??????????????>^???????????????????|.            <br></br>
 .^v}vvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvv+?vvvvv}}vvvvvvvvvv?`              <br></br>
   .+??vvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvv>?vvvv?^^|~--`````-`                <br></br>
    .+>>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?v??vvv-        ..         .+-      <br></br>
    ~>??vvv??vvvvvvvvvvvvvvvvvvvvvvvv??>++>vvvv-     .`.        .>}v+      <br></br>
   .+?vvvvvvvr}vv?vvvvvvvvvvvvvvv?^>>+>>>>>vvvvv`  .``         ^r}??>      <br></br>
   ^>vvvvvvwv}www?+>>>>>>>>>vrrr}??+>>>>>>>vvvvv?.``         `vwv????.     <br></br>
  .>?vvvvvz}vvwwwr>>>>>>>>+?wwwr?v}v+>>>>>>vv?>++>~         >wv??????`     <br></br>
  |>vvvvvrzvvv}www?>>>>>>>+}www?vvvv>+>>>>>vv??vvv??-     `}r????????`     <br></br>
 .>?vvvvvzrvvvvwww}v?>>>>+?wwwv?vvvv>>>>>>+?vvvvv???v>. .>rv?????????`   ~`<br></br>
`+>?vvvv}zvvvvv}wwrvv?>???rww}?vvvvv>>>>>>+>?vvvv?>??vv~vwvvvv???????` .^?`<br></br>
.+>rwvvvwwvvvvvvwwwvvvvvvvwwr?vvvvvv?>>>>>+>??vvv?>v}}wrr}rrwwww}v???~^>?>`<br></br>
.+?uv}wvw}vvvvvv}ww}vvvvvrwwvvvvv?vv?>>?r>+>???vrw}vvv????????vv}ww}>|>>??`<br></br>
.+}x..^vrvvvvvvvvwwwvvvvvww}vvvvv?>?vvvrrv?>w>}w}v????????????????v}rvvv??`<br></br>
.>u?   ^xwrvvvvvv}ww}vvvrwrvvvvvvv?+>????vv}rr}??????????????????????vr???`<br></br>
.>u^   vyxwzr}vvvvwwv??}wwvvvvvvrzxv+>>?????rv????????????????????????vv?+.<br></br>
.>vr.  wyxxux}r}vv}}+++?wvvvvwwzxzyu`|>>>>?vv??????????????????????????v?>`<br></br>
.v?vr..xyzxyy~`?rrv>++++?vrwv+wuyzyx. ~>>>>v???rv????????????????????????v-<br></br>
`vv?}}|uyzxyy-  .>rwwwwrrr>`  }yyzyw  +>^+????vuxv????????????????????????`<br></br>
`vvv>vzruwuyx.    ~yyyyyy|    vyyzyr-v}?}v????vuyxv???????????????????????`<br></br>
`vvvv??zw}yyw   .^v}}rrrwv^.  vyyrzvwxwzxz}????xyyxv??}v??????????????????.<br></br>
`vvvvv?vvwwuv `>vv+????>^?rwv|vuz}wzuuyyuyyz???vuyyv???}????vv????????????~<br></br>
`vvvvv}r??}z}v}?>>?vvvv?+>??vwzzwuuyyxyyzxyu????vzuuz??????vv??????vvrv???-<br></br>
`vvvvv}rvv??v?>+>vvvvvv>++vvvvvvuyzyywuyxzyz>?????v}w}????????>}wzuux}????`<br></br>
`vvvvv}rvvvvvvv??vvv}vv^+>?vv??>zyxzyzwxz}z?+>?????????????}zxzyyyxr????>>.<br></br>
`vvvvv}}vvvvvvvvvvv?rv?|+++>>>>+>rw}v}??}v}?+>>????????????v}rwr}v?????+>+.<br></br>
`vvvvv}}vvvvvvvv?vv?r}?^^^^>>>>>++>++++>?>++>>>>>????????????????????>v~~>`<br></br>
`vvvvv}rvvvvvvvvvvvv??>^+++>>>>>>>++>>>>>>+>>++^^>>?????????????vzxur}w?->`<br></br>
`vvvvvvvvvvvvvvvvv?>^^^+>>>>>>>??>++>>>>>>^^>>` .+++>>>>???>?>+vyuzyyyzu?+`<br></br>
`vvvvvvrvvvv????vvv?>++++++>>>>>>>++>>>>>>>^>>.  +>>>>>^|+>>+>?xxzuuzyuzv|`<br></br>
`vvvvvvr}?>??vvv}v?>^|~~^+^^~|^+>>+>>>>>>>++>>.  |>>>>|--~+>+v}}wxxxyuuyv-`<br></br>
.vvvvvv}rvvvvvv}}vvvvvvvv?>>+^~--|^+++>+++|+>>.  ~>++~-~~~`^>+}v?}wxzwxx>`.<br></br>


            </div>,

            <div>

.+????????>?????????????+^??????????????>^???????????????????|.            <br></br>
 .^v}vvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvv+?vvvvv}}vvvvvvvvvv?`              <br></br>
   .+??vvvv?vvvvvvvvvvvv?vvvvvvvvvvvvvv>?vvvv?^^|~---``````                <br></br>
    .+>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?v??vvv-        ..         .+-      <br></br>
    ~>??vvv??vvvvvvvvvvvvvvvvvvvvvvv???>>+>vvvv-     .`.        .>}v+      <br></br>
   .+?vvvvvvvr}vv?vvvvvvvvvvvvvvv?^>>>>>>>>vvvvv`  .`.         ^r}??>      <br></br>
   ^>vvvvvvwv}www?+>>>>>>>>>vrrr}??+>>>>>>>vvvvv?.``         `vwv????.     <br></br>
  .>?vvvvvz}vvwwwr>>>>>>>>+?wwwr?v}v+>>>>>>vv?>+>>~         >wv??????`     <br></br>
  |>vvvvvrzvvv}www?>>>>>>>+}www?vvvv>+>>>>>vv??vvv??-     `}r????????`     <br></br>
 .>?vvvvvzrvvvvwww}v?>>>>+?wwwv?vvvv?>>>>>+?vvvvv???v>. .>rv?????????`   ~`<br></br>
`+>?vvvv}zvvvvv}wwrvv?>???rww}?vvvvv?>>>>>+>?vvvv?>??vv~vwvvvv???????` .^?`<br></br>
.+>rwvvvwwvvvvvvwwwvvvvvvvwwr?vvvvvv?>>>>>+???vvv?>v}}rrr}rrwwww}v???~^>?>`<br></br>
.+?uv}wvw}vvvvvv}ww}vvvvvrwwvvvvv?vv?>>?r>+>???vrw}vvv????????vv}ww}>|>>??`<br></br>
.+}x..^vrvvvvvvvvwwwvvvvvww}vvvvv?>?vvvrrv?>w>}w}v????????????????v}rvvvv?`<br></br>
.>u?   ^xzrvvvvvv}ww}vvvrwrvvvvvvv?+>????vv}rr}??????????????????????vr???`<br></br>
.>u^   vyxwzrvvvvvwwv??}wwvvvvvvrzxv+>>?????rv????????????????????????vv?+.<br></br>
.>v}.  wyxxux}r}vv}}+++?wvvvvwwzxzyu`|>>>>?vv??????????????????????????v?>`<br></br>
.v?vr..xyzxyy~`?rrv>++++?vrwv+ryyzyx. ~>>>>v???rv????????????????????????v-<br></br>
`vv?}}|uyzxyy-  .>rwwwwrrr>`  }yyzyw  >?^+????vuzv????????????????????????`<br></br>
`vvv>vzruwuyx.    ~yyyyyy|    vyyzyr-v}?}v?????uyxv???????????????????????`<br></br>
`vvvv??zw}yyw   .^v}}rrrwv^.  vyyrzvwxwzxz}????xyyxv??}v??????????????????.<br></br>
`vvvvv?vvwwuv `>vv+????>^?rwv|vuz}wzuuyyuyyz???vuyyv???}????vv????????????~<br></br>
`vvvvv}r??}z}v}?>>?vvvv?+>??vwzzwuuyyxyyzxyu????vzuuz??????vv??????vvrv???-<br></br>
`vvvvv}rvv??v?>+>vvvvvv>++vvvvvvuyzyywuyxzyz>?????v}w}????????>}wzuux}????`<br></br>
`vvvvv}rvvvvvvv??vvv}vv^+>?vv??>zyxzyzwxw}z?+>?????????????}zxzyyyxr????>>.<br></br>
`vvvvv}}vvvvvvvvvvv?rv?|+++>>>>+>rw}v}??}v}?+>>????????????v}rwr}v?????+>+.<br></br>
`vvvvv}}vvvvvvvv?vv?r}?^^^^>>>>>++>++++>?>++>>>>>????????????????????>v~~>`<br></br>
`vvvvv}rvvvvvvvvvvvv??>^+++>>>>>>>++>>>>>>+>>++^^>>?????????????vzxur}w?-?`<br></br>
`vvvvvvvvvvvvvvvvv?>^^^+>>>>>>>??>++>>>>>>^^>>` .+++>>>>?????>+vyuzyyyzu?+`<br></br>
`vvvvvvrvvvv????vvv?>+++++++>>>>>>^+>>>>>>>^>>.  +>>>>>^|+>>+>?xxzuuxyuzv|`<br></br>
`vvvvvvr}?>??vvv}v?>^|~~^+^^~|^+>>+>>>>>>>++>>.  |>>>>|--~+>+v}}wxxxuuuyv-`<br></br>
.vvvvvv}rvvvvvv}rvvvvvvvv?>>+^~--|^+++>++>^+>>.  ~>++~-~~~`^>+}v?}wxzwxx>-.<br></br>


            </div>,

            <div>

.?????>??????????????>-???????????????+^?????????????>+|`...               <br></br>
`vvvvvv?vvvvvvvvvvvvv?+vvvvvvvvvvvvvv?|vvvvvvvvv?>|-`.  ...                <br></br>
.?vvvvvvvvvvvvvvvvvvv>?vvvvvvvvvvvvvv|?v?vvvv?`.      .`.                  <br></br>
.++>?vvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv???>+vvvvv|     .``                .-  <br></br>
.>?vvvv??vvvvvvvvvvvvvvvvvvvvvvvv??>>>>>+vvvvvv+   `-.                ^r}. <br></br>
.?vvvvvv}rrr}??????vvvvv??vvv}?|+>>>>>>>+?vvvvv}>.-.                |}rv?` <br></br>
`vvvvvvz}vwww}+++>>>>>>>+?www}>v+>>>>>>>+?vvvv??+`                `vrv???~ <br></br>
`vvvvvrwvvrwww?>>>>>>>>>>wwww??vv?>>>>>>>?v??>>>??^   -         .?wv?????| <br></br>
`vvvvvz}vvvwwwr?>>>>>>>+vwwwv?vvvv>>>>>>>?vvvvvvvvv~ .??`      |r}???????+.<br></br>
`vvvvrwvvvvrwwwv?>>>>>>?www}>vvvvv?>>>>>>>vvvv?vvvvv..??v^ ..|vzv????????+.<br></br>
`vvvvz}vvvvvwww}vv?>>??}www??vvvvv?>>>>>>>vvvv??vvvv~`???}?`^wwv?????????+.<br></br>
`vvv}wvvvvvvrwwrvvvvvvvwww??vvvvvv?>>>>v>+vvvvv>??v?-~?vv}z}wwwwwwr}vv???+.<br></br>
`r}vwrvvvvvvvwwwvvvvvvrww}>vvvvvvv?++?rz?|vxzwwv???vr}rr}vvvvvvvvv}rzzw}?^.<br></br>
 ~vr}vvvvvvvv}wwrvvvvvwww?vvvvvvvvvr>??vv?vzzxxu??}w}v???????????????v}wz}`<br></br>
   +xw}vvvvvvvwwwvvvvrww??vvvvvv}w}~.->????vv}ww}r}v????????????????????v}|<br></br>
   ?yxww}vvvvv}wwv??vww}?vvvvvrwzu?   ->>??????}rv????????????????????????`<br></br>
   vyyzuxzr}vvvrw?++?wrvvvvrwzzwyy?    |>>>>??vv??????????????????????????`<br></br>
   wyywyyx|>}}vv?^+++?vv}w}+ruyzuy?   >}+>>>>>v???vv??????????????????????`<br></br>
.`.xyuwyyz. .^v}vvvv??}w?-  ryyzuy? .vr>++>>+?????zxv?????????????????????`<br></br>
`r+zyxzyy}     ~uyyyyyz.    vyyzuyv~rv++>+^???????uyxv????????????????????`<br></br>
.>rwxxxyy?     ^uuuuuux|.   vyyzuzww?+>v}}rz}?????xyuxv??vv???????????????`<br></br>
`v>vzrzyy>  `+v?>>??>??rr?|.vyyrwwv++>zxuuuuux}???ryyyr>??rv??????????????`<br></br>
`vvr?vwzx+|?}?>>?vvv?+^>?vwrrzzzv>++vuuuyyyxyyyv???ryyzr??vv???vv?????????`<br></br>
`vvrv??}zw}?++>vvvvvv^+?vv??vr}>++vxyyyuzyywuyyv????vwuyr??????????????vvr~<br></br>
`vvr}vv???+>>>vvvvvv?^+>?vvv?>>>>>zyxxyywyyzxux>???????v}??????????rwzxuyx-<br></br>
`vvwvvvvvvv?>vvv?vvv?^+>>v??>>>>>+}yywuuwzzwrwv+>?????????????vwuxuyyyyx}?`<br></br>
`vvwvvvvvvvvvv???v}v>^+++>>>>>>>>>^?}v?vv>}}vv?+>>?????????????vvrrwr}v???`<br></br>
`vvrvvvvvvvvv?v??vwv+|^^^>>>>>>>>>++++++++>>?++>+>>>?????????????????????v~<br></br>
`vvrvvvvvvvvvvvvv?v?^++++>>>>>?>>>+>>>>>>>+>>+>>+^~+>>??????????????}zxurz|<br></br>
`vv}vvv}vvvvvvvv?+^^^+>>>>>>>>???>+>>>>>>>++^>~.. .+>++>>>?????????zyyuyyy|<br></br>
`vvv}vvvvv???????>+>>+^|^^+>>>>>>>+>>>>>>>+++>.   .+>>>>>>>++>>>++}yyxxyyy^<br></br>
`vv}wvv?????vvvv?>|~~|^^+^~~~^+++^+++++++>++>>.   .~++>>++^-`^++vvwwwzuzwu|<br></br>


            </div>,

            <div>

.?????>??????????????>-???????????????+^?????????????>+|`...               <br></br>
`vvvvvv?vvvvvvvvvvvvv?+vvvvvvvvvvvvvv?|vvvvvvvvv?>|-`.  ...                <br></br>
.?vvvvvvvvvvvvvvvvvvv>?vvvvvvvvvvvvvv|?v?vvvv?`.      .`.                  <br></br>
.++>?vvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv???>+vvvvv|     .``                .-  <br></br>
.>?vvvv??vvvvvvvvvvvvvvvvvvvvvvvv??>>>>>+vvvvvv+   `-.                ^r}. <br></br>
.?vvvvvv}rrr}??????vvvvv??vvv}?|+>>>>>>>+?vvvvv}>.-.                |}rv?` <br></br>
`vvvvvvz}vwww}++>>>>>>>>+?www}>v+>>>>>>>+?vvvv??+`                `vrv???~ <br></br>
`vvvvvrwvvrwww?>>>>>>>>>>wwww??vv?>>>>>>>?v??>>>??^   -         .?wv?????| <br></br>
`vvvvvz}vvvwwwr?>>>>>>>+vwwwv?vvvv>>>>>>>?vvvvvvvvv~ .??`      |r}???????+.<br></br>
`vvvvrwvvvvrwwwv?>>>>>>?www}>vvvvv?>>>>>>>vvvv?vvvvv..??v^ ..|vzv????????+.<br></br>
`vvvvz}vvvvvwww}vv?>>??}www??vvvvv?>>>>>>>vvvv???vvv~`???}?`^wwv?????????+.<br></br>
`vvv}wvvvvvvrwwrvvvvvvvwww??vvvvvv?>>+>v>+vvvvv>??v?-~?vv}z}wwwwwwr}vv???+.<br></br>
`r}vwrvvvvvvvwwwvvvvvvrww}>vvvvvvv?++?rz?|vxzwwv???vr}rr}vvvvvvvvv}rzzw}?+.<br></br>
 ~vr}vvvvvvvv}wwrvvvvvwww?vvvvvvvvvr>??vv?vzzxxu??}w}v???????????????v}wz}`<br></br>
   +xw}vvvvvvvwwwvvvvrww??vvvvvv}w}~.->????vv}ww}r}v????????????????????v}|<br></br>
   ?yxww}vvvvv}wwv??vww}?vvvvvrwzu?   ->>??????}rv????????????????????????`<br></br>
   vyyzuxzr}vvvrw?++?wrvvvvrwzzwyy?    |>>>>??vv??????????????????????????`<br></br>
   wyywyyx~>}}vv?^+++?vv}w}+ruyzyy?   >}+>>>>>v???vv??????????????????????`<br></br>
.`.xyuwyyz. .^v}vvvv??}w?-  ryyzuy? .vr>++>>+?????zxv?????????????????????`<br></br>
`w+zyxzyy}     ~uyyyyyz.    vyywuyv~rv+>>+^???????uyxv????????????????????`<br></br>
.>rwxxxyy?     ^uuuuuux|.   vyyzuzww?+>v}}rz}?????xyuxv??vv???????????????`<br></br>
`v>vzrzyy>  `+v?>>??>??rr?|.vyyrwwv^+>zxuuuuux}???ryyyr>??rv??????????????`<br></br>
`vvr?vwzx+|?}?>>?vvv?+^>?vwrrzzzv>++vuuuyyyxyyyv???ryyzr??vv???vv?????????`<br></br>
`vvrv??}zw}?++>vvvvvv^+?vv??vr}>++vxyyyuzyyzuyyv????vwuyr??????????????vvr~<br></br>
`vvr}vv???+>>>vvvvvv?^+>?vvv?>>>>>zyxxyywyyzxux>???????v}??????????rwzxuyx-<br></br>
`vvwvvvvvvv?>vvv?vvv?^+>>v??>>>>>+}yywuuwzzwrwv+>?????????????vwuxuyyyyx}?`<br></br>
`vvwvvvvvvvvvv???v}v>^+++>>>>>>>>>^?}v?vv>}}vv?+>>?????????????vvrrwr}v???`<br></br>
`vvrvvvvvvvvv?v??vwv+|^^^>>>>>>>>>++++++++>>?++>+>>>?????????????????????v~<br></br>
`vvrvvvvvvvvvvvvv?v?^++++>>>>>?>>>+>>>>>>>+>>+>>+^~+>>??????????????}zxurz|<br></br>
`vv}vvv}vvvvvvvv?+^^^+>>>>>>>>???>+>>>>>>>++^>~.. .+>++>>>?????????zyyuyyy|<br></br>
`vvv}vvvvv???????>++>+^|^^+>>>>>>>+>>>>>>>+++>.   .+>>>>>>>++>>>++}yyxxyyy^<br></br>
`vv}wvv?????vvvv?>|~~|^^+^~~~^+++^+++++++>++>>.   .~+++>++^-`^++v?rwwzuzwu|<br></br>


            </div>,

            <div>

.??>?????????????> ^???????????????>-????????????>+~.   .`.                <br></br>
`vvvvvvvvvvvvvvvv?.?vvvvvvvvvvvvvvv~+}vvvvvvv?>|-.     ``.                 <br></br>
`vvv?vvvvvvvvvvvv?-vvvvvvvvvvvvvvv?|v?>?vvvvv|       .`.                   <br></br>
`vvvvvvvvvvvvvvvv?>vvvvvvvvvvvvvv???>++?vvvvvv+.   .`.                     <br></br>
`vvv?vvvvvvvvvvvvvvvvvvvvvvv???>>>>>>>>>vvvvvvv?`.-`                       <br></br>
`vv?v}wrrv>????????????vv}rv^^+>>>>>>>>>vvvvvvv?+`                       ..<br></br>
`v?}}vwwwr>>>>>>>>>>>+>rwwr?v?+>>>>>>>>>vv??>^-`..                      ^r|<br></br>
`v}zvv}wwwv>>>>>>>>>>+vwwwv?vvv>>>>>>>>>?v?????vvv+       .           ~}rv`<br></br>
`vwrvvvrwwwv>>>>>>>>+?wwwr?vvvvv?>>>>>>>?vvvvvvvvvv^     .?^  `|>-  `vwv??`<br></br>
`}zvvvvvwww}v?>>>>>>>}www?vvvvvv?>>>>>>>>vvvvvvvvvvv^    .?v?|`. ..>w}????`<br></br>
`rrvvvvvrwwwvv?>>>>?vwwwv?vvvvvv?>>>>+>>>vvvvvvvvv?vv`   `??v}-  ~}r??????`<br></br>
`w}vvvvvvwww}vvv?vvvrwwr?vvvvvvv?>>+?}w++?}}vvvvvv>??+-. ~???vw^>zrvvvv???`<br></br>
-zvvvvvvv}wwwvvvvvvvwww??vvvvvvv?+?rv>x?^?uuxxxzv~.+??vv++vrwwwwwwwzzzzzwr-<br></br>
`rvvvvvvvvrww}vvvvvrww}?vvvvvvvvvrv~ .w?>?rzzxxuw. `??vrzw}vvv??????vvv}rz|<br></br>
`}?vvvvvvvvwwwvvvvvwww?vvvvvvv}wz-   .r?>??vv}rww+`.?rw}v?????????????????-<br></br>
`zwrvvvvvvvrwwrvvvrwwvvvvvvvrwzxx.   .r?++>>????v}r}rv????????????????????`<br></br>
-uzwzw}vvvvvwwv++?rwrvvvvrwzzwyyx.    r?+^+>>>????v}??????????????????????`<br></br>
-uxuuxw}w}vv}r>+++?wvvvww?vuyzuyx.  .?}+>+?>>>>>>?v???????????????????????`<br></br>
-uxxyyw.-?rrv?+>>>>?}zr+. ?yyzuyu` -rv+>>+?}+>>>>????}w???????????????????`<br></br>
-yxxyyw.  .|?zzzxxxu}|.   >yyzuyy-+r?+>>>+>?>+>>?????zyz??????????????????`<br></br>
-yxuyyr..    wuuyyyy>     ^yyzuyzr}>>>>>>++>??v??????xyuz?????????????????`<br></br>
`zxuyy} .  -?r}v}}}rr}>-. ~yyxzzw?+>>>>>>?}rwzz}?????zyyuw???v????????????`<br></br>
`wrzyyv .+v}?+????>+^?rzw?>xuwr}>>>>>>>?wxuyuuuyu}???}yyyx???vr???????????`<br></br>
.?}wwz}?rv>+?v?}vv?++???v}zzzw?+>?>>>>vuyyuyyuxyyu????}uyxrv??v???vvvv????`<br></br>
`v??}w}?>++>vv?vvv?++>vvvv???>>>>>>>?xuuyyzyyuzyyx?????vrxyx??????????????`<br></br>
`vvv??>+>>>vvv?vvv?^>+?vvv?>>>>>>>>+vyuxyyxuyuzuyv>???????vrv??????????}rz~<br></br>
`vvvvvv??>vvvv?v}v>+>>>??>>>>>>>>>>>?uyxxuxwwwrwr?>>??????????????rzxruyyy^<br></br>
`vvvvvvvvvv?vv??}v+++^+>>>>>>>>>>>>>+>rr???>?}}v??+>>?????????????v}wzxxxx|<br></br>
`vvvvvvvvvv?vv??}v++^^^>>>>>>>>>>>>>>++>++++??. ~>+>>>>???????????????????`<br></br>
`vvvvvvvvvvv?vv??>^++++>>>>+??>>>+>>>>>>>>++??.`>>>+^+>>????????????????v}~<br></br>
`vvv}}vvvvvvv?>^^+^+>>>>>>>>>????>>>>>>>>>++??-^~-..-^>+>>>????????????wuy^<br></br>
`vvvvvvvvvv?vv?>>>>>>+^+>>>>++++++++++++++^^>>`     -~>+++++++>>>>>>>>wuuu|<br></br>


            </div>,

            <div>

.??>?????????????> ^???????????????>-????????????>+~.   .`.                <br></br>
`vvvvvvvvvvvvvvvv?.?vvvvvvvvvvvvvvv~+}vvvvvvv?>|-.     ``.                 <br></br>
`vvv?vvvvvvvvvvvv?-vvvvvvvvvvvvvvv?|v?>?vvvvv|       .`.                   <br></br>
`vvvvvvvvvvvvvvvv?>vvvvvvvvvvvvvv???>++?vvvvvv+.   .`.                     <br></br>
`vvv?vvvvvvvvvvvvvvvvvvvvvvv???>>>>>>>>>vvvvvvv?`.-`                       <br></br>
`vv?v}wrrv>????????????vv}rv^^+>>>>>>>>>vvvvvvv?+`                       ..<br></br>
`v?}}vwwwr>+>>>>>>>>>+>rwwr?v?+>>>>>>>>>vv??>^-`..                      ^r|<br></br>
`v}zvv}wwwv>>>>>>>>>>+vwwwv?vvv>>>>>>>>>?v?????vvv+       .           ~}rv`<br></br>
`vwrvvvrwwwv>>>>>>>>+?wwwr?vvvvv?>>>>>>>?vvvvvvvvvv^     .?^  `|>-  `vwv??`<br></br>
`}zvvvvvwww}v?>>>>>>>}www?vvvvvv?>>>>>>>>vvvvvvvvvvv^    .?v?|`. ..>w}????`<br></br>
`rrvvvvvrwwwvv?>>>>?vwwwv?vvvvvv?>>>>+>>>vvvvvvvvv?vv`   `??v}-  ~}r??????`<br></br>
`w}vvvvvvwww}vvv?vvvrwwr?vvvvvvv?>>+?}w++?}}vvvvvv>??+-. ~???vw^>zrvvvv???`<br></br>
-zvvvvvvv}wwwvvvvvvvwww??vvvvvvv?+?rv>x?^?uuxxxzv~.+??vv++vrwwwwwwwzzzzzwr-<br></br>
`rvvvvvvvvrww}vvvvvrww}?vvvvvvvvvrv~ .w?>?rzzxxuw. `??vrzw}vv???????vvv}rz|<br></br>
`}vvvvvvvvvwwwvvvvvwww?vvvvvvv}ww-   .r?>??vv}rww+`.?rw}v?????????????????-<br></br>
`zwrvvvvvvvrwwrvvvrwwvvvvvvvrwzxx.   .r?++>>????v}r}rv????????????????????`<br></br>
-uxwzw}vvvvvwwv++?rwrvvvvrwzzwyyx.   .r?+^^>>>????v}??????????????????????`<br></br>
-yuuuxw}w}vv}r>+++?wvvvww?vuyzuyx.  .?}+>+?+>>>>>?v???????????????????????`<br></br>
-yxxyyw.-?rrv?++>>>?}zr+. ?yyzuyu` -rv+>>+?}+>>>>????}w???????????????????`<br></br>
-yxxyyw   .|?zxxxxxu}|.   >yyzuyy-+r?+>>>+>?>+>+?????zyz??????????????????`<br></br>
-yxuyy}      zyyyyyy>     +yyzxyzr}>>>>>>++>??v??????xyuz?????????????????`<br></br>
`zxuyyv    -?r}}}}}rr}>-. ~yyxzzw?+>>>>>>?}rwzz}?????zyyuw???v????????????`<br></br>
`wrzyyv .+v}?+????>+^?rzw?>xxwr}>>>>>>>?wxuyuuuyu}???}yyyx???vr???????????`<br></br>
.?}wwz}?rv>+?v?}vv?++???v}zzzw?+>?>>>>vuyyuyyuxyyu????}uyxrv??v???vvvv????`<br></br>
`v??}w}?>++>vv?vvv?++>vvvv???>>>>>>>?xuuyyzyyuzyyx?????vrxyx??????????????`<br></br>
`vvv??>+>>>vvv?vvv?^>+?vvv?>>>>>>>>+vyuxyyxuyuzyyv>???????vrv??????????}rz|<br></br>
`vvvvvv??>vvvv?v}v>+>>>??>>>>>>>>>>>?uyxxuxwww}wr?>>??????????????rzxruyyy^<br></br>
`vvvvvvvvvv?vv??}v+^+^+>>>>>>>>>>>>>+>rr???>?}}v??+>>?????????????v}wzxxxx|<br></br>
`vvvvvvvvvv?vv??}v+^^^^>>>>>>>>>>>>>>++>++++??. ~>+>>>>???????????????????`<br></br>
`vvvvvvvvvvv?vv??>^++++>>>>+??>>>+>>>>>>>>++??.`>>>+^+>>????????????????v}~<br></br>
`vvv}}vvvvvvv?>^^+^+>>>>>>>>>????>>>>>>>>>++??-^~-..-^>+>>>????????????wuy^<br></br>
`vvvvvvvvvv?vv?>>>>>>+^+>>>>++++++++++++++^^>>`     -~>+++++++>>>>>>>>wuuu|<br></br>


            </div>,

            <div>

.?????????????+  >>??????????????>`?????????>|.        .`.                 <br></br>
                  `vvvvvvvvvvvvv? ~v?vvvvvvvvvvvvvv-+v??vvvvvv|         ``.                  <br></br>
.vvvvvvvvvvvvv?.?v?vvvvvvvvvvvvv?+?>>+vvvvvvv+.     `-.                    <br></br>
`vvvvvvvvvvvvv>`vvvvvvvvvvvvv???>>>>>+?vvvvvvv?~  .~`                      <br></br>
`vvvvvvvvvvvvv??vvvvvvvvvv??>>>>>>>>>>?vvvvvvvvv>--                        <br></br>
.vr}}v??????????????>vrrr??>+>>>>>>>>>>vvvvv??>|-.                         <br></br>
.?}wwwv???>>>>>>>>>+?rrw}?}v>>>>>>>>>>>??>^~`````-.                      ..<br></br>
`vvwwwr????>>>>>>>>>}www?vvvv?>>>>>>>>+?????vvvvvvv`            `       .>-<br></br>
`vv}wwwv???>>>>>>>+?rwwv?}vvvv?>>>>>>>>?vvvvvvvvvvvv`       ..|>|`     .+?`<br></br>
`vvvrwwrvv???>>>>>>}rwr?vvvvvvv?>>>>>>>>vvvvvvvvvvvv?`    .``.+??`    .+vr~<br></br>
`vvvvwrw}vvv?>>>>?vwww?vvvvvvvv?>>>+>?++vvvvvvvvvvvvv+.      .???v~  .|}wv`<br></br>
`vvvv}wwwvv}v???vvrwwr?vvvvvvvv?>+>vrz>^?wrrr}vvvvv>~.>>^`.  `???vz+`?rv??`<br></br>
`vvvvvrwwrv}}vvvv}www?vvvvvvvvv??}}+`wv+>xuuuuuuv|.   ^??v?+`|vv}rwzwxzwww~<br></br>
`vvvvvvwww}v}vvvvrwwv?}vvvvvvvvr}^.  ?r+>x}}wzxxz.    `????}wrwr}vvvvvv}}r~<br></br>
`vvvvvv}wwwvvvvv}www?vvvvvvv}wzv.    ~z+?uz???vvr?+~`. +?vwr}v????????????`<br></br>
`vvvvvvvrwwrvvvvwwwvvvvvvv}wwxur.    `x>^?xr>>>???v}r}v?r}v???????????????`<br></br>
`}vvvvvv}wwwvv??wwr?vvvvwzzzwyyw.    ?w++>zzr>>>??????v}v?????????????????`<br></br>
-xzwr}vvvrw}vvv?vw?vvvzw?zuywyyx`  .?r>>>+wzzr>>>>????v???????????????????`<br></br>
-uuz?}rv?vrvvvvv?v?}zr+..xuywyyu` `v}>>>>+}zwwv>>>>>?????}v???????????????`<br></br>
-uyx..-+?vvv}rrr}vz}^.  .wuywuyy~|wv+>>>>+>?>>>>>??>??v??xxv??????????????`<br></br>
-uyx`..  `?wwuuuuy?      vuyzuyzrz?+>>>>>+>>>>>+~+????v?vuuzv?????????????`<br></br>
-uyu-..   +xxuuuuuv|..   ?uyxxzzw>>>>>>>>+>???++?r}???v?}yuxzv???v????????`<br></br>
-uyu-...^v}???????vrrv>-.?yywrxv+>>>>>>>?>>vwzxuxxzrv?vvvyuuxw???}v???????`<br></br>
`uyu-~?r}?>??vvv?>+>?vrzrrzwzz?+>>>>>>>???wuuyyyyyuuu}?v?wyuu}????r???????`<br></br>
`wzwrwv>+>vv?}vv?>+>vv???v}w}>>>>>>>>???vuyyuuyyyxuuur????ruyxzv??v???vvvv`<br></br>
.vr}?>>>>?vv?}vv?>+>?vvvv?>+>>>>>>>>>??vyuuyuxyyyzuuuv?????v}zxz??????????`<br></br>
`vv?>>>>?vvv?vvv?>>>>?vv?>>>>>>>>>>>>??ryuxyyxxuurzxw?????????vv????????v?`<br></br>
`vvvv?>?vvvv??}v?>>>>>?>>>>>>>>>>+>>>>>?zyxzzwwrr}v}v>???????????????vwxuz|<br></br>
`vvvvvvvv?vv??}v?++^^+>>>>>>>>>>>+>>>>>>+v}v??^^v?>+>>>>??????????????v}wx|<br></br>
`vvvvvvvv???>?}v>+++^+>>>>>>>>>>>+>>>>>>>?>^>?~ .  |>>>>>>????????????????-<br></br>
`vvvvvvvvvvv????>+>++>>>>>>???>>>+>>>>>>>>>^??|   -++++^^>>?????????????vv-<br></br>
`}rvvvvvvv???>+^+^+>>>>>>>>>>>>??>+++++++++|>>|  .-``.``->++>>???????????v`<br></br>


            </div>,

            <div>

.?????????????+  >???????????????+.?????????>|.        .`.                 <br></br>
                  `vvvvvvvvvvvvv? -vvvvvvvvvvvvvvvv-+vv?vvvvvv|        .--.                  <br></br>
.vvvvvvvvvvvvv> ?vvvvvvvvvvvvvvv?+?>++vvvvvvv+.     -~.                    <br></br>
`vvvvvvvvvvvvv>`vvvvvvvvvvvvv???>>>>>+?vvvvvvvv|  `^`                      <br></br>
`vvvvvvvvvvvvv??vvvvvvvvvv?>>>>>>>>>>>?vvvvvvvvv>~~.                       <br></br>
.vr}}v??????????????>vrrw??>+>>>>>>>>>>vvvvv??>|`.                         <br></br>
.?}wwwv>+>>>>>>>>>>+?rwwv?}v>>>>>>>>>>>v?>^~-```-~`                      ..<br></br>
`vvwwwr?>>>>>>>>>>>+}www?vvvv?>>>>>>>>+????vvvvvvvv-            `       .>-<br></br>
`vv}wwwv?>>>>>>>>>+?wwwv?vvvvv?>>>>>>>>?vvvvvvvvvvvv-       ..^?|`      +?`<br></br>
`vvvrwwr?v>>>>>>>>>rwwr?vvvvvvv>>>>>>>>>vvvvvvvvvvvvv`    .``.>v?`     ^?r~<br></br>
`vvvvwww}vv?>>>>>?vwww??vvvvvvv>>>>+>?++vvvvvvvvvvvvv>.       ??vr~  .|}wv`<br></br>
`vvvv}wwwvvvv???vvrww}?vvvvvvvv>>+>}wx>+?wrrr}vvvvv>-.>?^-   `???vz^-?w}??`<br></br>
`vvvvvrwwrvvvvvvv}www?vvvvvvvvv??r}^`z?+?xuuuuuuv|.   |??v?+`~?v}rzzwxzwww~<br></br>
`vvvvvvwww}vvvvvvrwwv?vvvvvvvvvw}|.  vr+>x}}wzxxz.    .????}wrwwr}vvvvv}}r~<br></br>
`vvvvvv}wwwvvvvv}www?vvvvvvv}wzv     ^z+?yz>??vvr?+|`. |?vww}v????????????`<br></br>
`vvvvvvvwwwrvvvvwwwv?vvvvv}wzxu}     `x>^vuw>>>??vv}r}v?rrv???????????????`<br></br>
`}vvvvvv}wwwv??vwwr?vvvvwzzzwyyw     ?r++>xuw>>>>?????v}v?????????????????`<br></br>
-xzw}vvvvrw}vvv?}w?vvvzw?xuywyyz.  .vr+>>+wuur>>>>>???v???????????????????`<br></br>
-uuz?vr}vvrvvvvv?v?}zr+..uyywyyu. `}}+>>>+}xzx}+>>>>>????}}???????????????`<br></br>
-uyx. ->v}}}}}}}}vz}|.  .zyywuyy-|wv+>>>>+>????>+>>>?????xxv??????????????`<br></br>
-uyx.    `?yuuuuuy>      ryyzuyz}z?+>>>>>+>>>>>+-^>>????vyuxv?????????????`<br></br>
-uyu`     >yuuuuuyv|.    vyyxxwzr>>>>>>>>++>>>^^vwr>????vyyuz????v????????`<br></br>
-uyu`  .+}}???????vwwv+` ?yywrxv+>>>>>>>>+^vwzxuxzxwv???vyyyuw???}v???????`<br></br>
`uyu--?w}?+??vvv?+^>?vwzrrzwzz?+>>>>>>>>+?wuuyyyyyyyy}???wyyyr????r???????`<br></br>
`wxzrwv>+>vv?}vv?++>vv???v}w}>>>>>>>>>>+?uyyuuyyyxyyyr????ruyuxv??v???vvvv`<br></br>
.?r}?+>>>?vv?}vv?++>?vvvv?>+>>>>>>>>>>+vyuuyuzyyyzuyyv?????vrxuz??????????`<br></br>
`v??>>>>?vvv?vvv?+>>>?vv?>>>>>>>>>>>>>+}yuxyyxxuuwxuz>????????vv??????????`<br></br>
`vvvv?>?vvvv??}v>+>>>>?>>>>>>>>>>+>>>>>>zyxxxzwwrrrrv+>??????????????vwxuw|<br></br>
`vvvvvvvv?vv??}v>^+^^+>>>>>>>>>>>+>>>>>>+vrv?v+>}v?>?+>>??????????????v}wx|<br></br>
`vvvvvvvv???>?}v>^++^+>>>>>>>>>>>+>>>>>>>?>^>?| .  ~>>>>>>????????????????-<br></br>
`vvvvvvvvvvv????>+>++>>>>>>???>>>+>>>>>>>>>^??+   |>>>+^^>>???????????????`<br></br>
`}rvvvvvvv???>^^^^+>>>>>>>>>>>>??>+++++++++|>>>. -|~`.--~>++>>????????????`<br></br>


            </div>,

            <div>

.????????????`. .>??????????????|`~????????>~.         ..                  <br></br>
`vvvvvvvvvvvv-. ^vvvvvvvvvvvvvvv-->v?vvvvvvv-        ...                   <br></br>
`vvvvvvvvvvvv-``?vvvvvvvvvvvvvv?>+>>+?vvvvvvv>.    .`.                     <br></br>
`vvvvvvvvvvvv-+|vvvvvvvvvvvvv??>>>>>>>vvvvvvvvv|  `~.                      <br></br>
`vvvvvvvvvvvv>??vvvvvvvvv?>>>>>>>>>>>>vvvvvvvvvv>-`                        <br></br>
`}r}vv??????????????v}rrv?>>>>>>>>>>>>?vvvv??+|-.                          <br></br>
`}rwrv?>>>>>>>>>>>+?}ww}vvv?>>>>>>>>>>?v?+~`````-~`                      ..<br></br>
`}rwr}v?>>>>>>>>>>?vrwrvvvvv?>>>>>>>>>>v??vvvvvvvvv~           .`       .>`<br></br>
`vrrwr}??>>>>>>>>>v}ww}vvvvvvv?>>>>>>>>?vvvvvvvvvvvv~       ..~|^~     .+?`<br></br>
`v}rww}}??>>>>>>>?}wwrvvvvvvvv?>>>>>>>>?vvvvvvvvvvvvv~    ....`~?^.   .+?v-<br></br>
`vv}rwr}vv??>>>>?}rww}vvvvvvvv?>>>>>v?+?vvvvvvvvvvvvv?.       `^vv+. `~v}v-<br></br>
`vvv}rwr}vvv???v}rwwrvvvvvvvvv?>+>vv}w++vwwwrr}}vvv?~.`>+~.   `>??v?^^>?vv-<br></br>
`vvv}rww}}vvvvvv}rww}vvvvvvvvv??vv>``z?^?uuuuuuu}|`   .^?v?>-.-?vvvrzr}zwr-<br></br>
`vvvv}rwr}}vvvv}rww}vvvvvvvvvvr}>.   vv^?uzwwzxxx`     `???vv}v}rr}}}}}}rw|<br></br>
`vvvv}rrwr}vvvv}rwrvvvvvvvvvrx}-     ?r+vyu}??vvr?+|`.  ^vvv}}vv??????????`<br></br>
`vvvvv}rwwr}vv}rww}vvvvvvvwzxuw|     ^w>^}uxv>>???vv}}v>?vvvv?????????????`<br></br>
`}vvvvvrrwr}v?v}wrvvvvv}zzxxuyx^    `}v+^?uuzv>>>?????v}vv????????????????`<br></br>
`zwr}vv}rrr}vvv}r}vvvrz}}uyuuyu+.  `}v+>+>zuuzv>>>>???????????????????????`<br></br>
-uzw?v}vr}}vvvvvvv?rw?-.>uyuuyu>. ~r?+>>>+wxxxw?>>>>>????vvv??????????????`<br></br>
-yv+`-+?v}}}}}}vvrw?`  .>uyuxyy?.>w?+>>>>^??????+>>>?????}zwv?????????????`<br></br>
-yv^.  .-+}uuuuuuv`     ^xyuxyy}}w>+>>>>>^>>>>?>~`^>????vrxurv????????????`<br></br>
-yv^.    ~vyyuuyyv|.    ~wyuxxzxv>>>>>>>>+>>>+-`-?v?????vwuyu}v??v????????`<br></br>
-y}^.  `?r}v????vv}}?^` -ryuwzz?+>>>>>>>>+^???vrzxuwv???vwyyyxv??vvv??????`<br></br>
-yr+`|vwv>>?????>+>v}wrvvzzwxr>+?>>>>>>>>>vwxyyyyyyyyzv??vxyyxr??vvvv?????`<br></br>
`xwrwr?>>??vv}v?>+>?vvvv}rwzv>>>?>>>>>>>?}xyyuyyyuuyyx}???vxyyuv???vv?vvvv`<br></br>
`rwv?>>>?vvvv}v?>+>?vvvvv?>>>>>>>>>>>>>?ruyyyuuyyuuyyw}?????rxyu}???????vv`<br></br>
`??>>>>?vvvvvvv?>+>>?vvv?>>>>>>>>>>>>>+?xyuuyuuuyuuuu}????????vr}v????????`<br></br>
`vv??>?vvvvvvvv?>+>>>??>>>>>>>>>>>>>>>>>}yyxuuxzxzwwr?>??????????????vv}rr~<br></br>
`vvvvvvvvvv?vvv?+++++>>>>>>>>>>>>>>>>>>>>vww}}?>vv???>>>??????????????vrxu^<br></br>
`vvvvvvv?????vv?+^+^+>>>>>>>>>>>>>>>>>>>>?>+??^ ....|>>>>>???????????????v~<br></br>
`vvvvvvvvvv??v?>+>>++>>>>>>??>>>>>>>>>>>>>>^>?>.   ->>+^^>>???????????????`<br></br>
`}}vvvvvvvv??>+++++>>>>>>>>>????>>++++++++>|>>>.  `|~````^++>>????????????`<br></br>


            </div>,

            <div>

.????????????.  +???????????????`.?????????>`         .~.                  <br></br>
`vvvvvvvvvvvv. ~vvvvvvvvvvvvvvv?.>v?>vvvvvv?-        -~.                   <br></br>
`vvvvvvvvvvvv..vvvvvvvvvvvvvvvv>^>>++?vvvvvvv>.    `|`                     <br></br>
`vvvvvvvvvvv? >vvvvvvvvvvvvv??>>>>>>>?vvvvvvvvv| .~~                       <br></br>
`vvvvvvvvvvv?|vvvvvvvvvv??>>>>>>>>>>>>vvvvvvvvvv>|.                        <br></br>
`r}v??????????????>>vrwv>?+>>>>>>>>>>>vvvv??>^~`.                          <br></br>
`wwwv?+>>>>>>>>>>>+vwww??v?++>>>>>>>>>??>+~-```-~|`                      ~`<br></br>
`rwwr?>>>>>>>>>>>>?wwwv>vvvv>>>>>>>>>>?vvvvvvvvvvvv|           .-       -?`<br></br>
`vwwwv?>>>>>>>>>>>}www?vvvvvv?>>>>>>>>>vvvvvvvvvvvvv^       .`--?^     `??`<br></br>
`v}www??>>>>>>>>>?wwwv?vvvvvv?>>>>>>+>>?vvvvvvvvvvvvv^    ....  ?v^   `>>>`<br></br>
`vvwww}vv?>>>>>??rwww?vvvvvvvv>>>>+?r>+?vvvvvvvvvvvvvv-.       .??}>.`-+>v~<br></br>
`vvvwwwvvvv?>?vv}wwwv?vvvvvvvv>++?rvvv++rzzwwrr}?vv?|. ^>|`    ~???wv-->rw-<br></br>
`vvvrwwrvvvvvvvvrwwr>vvvvvvvvv??wv~ -z>^}uuxuuuur^`    `?vv?^. ^??vvzv?zw}-<br></br>
`vvvvwww}vvvvvvvwww??vvvvvvvvvwv~   .w?^vuuxwzxxx-      >???v}?vwwwwwwwwwz|<br></br>
`vvvvvwwwvvvvvvrww}?vvvvvvvvwx?      }v+}yyu??vvrv+|`.  `??vwwr}vv????????`<br></br>
`vvvvvrwwrvvvv}www?vvvvvv}wzzuv      }}+^wuux?>>??vv}}v?|>rrv?????????????`<br></br>
`vvvvvvwww}??>}wwv?vvvv}wzzzyyr     >z>+^vuxuz>>>>????vvr}v???????????????`<br></br>
`rvvvvv}wwvvv??ww?vvvwwvxuyzyyz.   >z>+>+?xxxuz>>>>>???vv?????????????????`<br></br>
`xrrrvvvr}vvvv?vv?vww?.`uyyzuyx.  ?z>+>>+>zxxzxr+>>>>>?????}??????????????`<br></br>
-y^.|?}}vvvvvvv??zr+.  .xyyzuyy`.vw>>>>>>+??????>+>>>>????}u}?????????????`<br></br>
-y^   .~?zuuuuuuu+.     wyyzxyu?w}+>>>>>>^>>>>??+`~>+?????zyuv????????????`<br></br>
-y>      wyyyyyyy^.     vyyxzzwx?+>>>>>>>^>>>+~..|?v??????uyuxv???????????`<br></br>
-y?   .+}w}vvvvvvwwv+`  ?yyxvzw>+>>>>>>>>+++~+?v}zxzv?????uyyuz???vv??????`<br></br>
-yv -?wr?+>????>^+?}wzr?vzzwx}+>?>>>>>>>>+^?wuuyyuuyyuv???wyyuu????rv?????`<br></br>
-x}vz}>+>??v}vv>++?v??v}wzzz?+>>?>>>>>>>>>ryyyuyyyuyyyz????zyyu}v??vv????v`<br></br>
`zwv>+>>vv??rvv>^>>vvvvv?>>>>>>>>>>>>>>>?zyyyyzyyyxuyyz?????}uyyz???????vv`<br></br>
.?++>>>?vv??}vv>^>>>vvv?>>>>>>>>>>>>>>>+ryxxyyxuyyxuyyv???????}wz}????????`<br></br>
`v?>>>?vvvv?vvv>+>>>>v?>>>>>>>>>?>>>>>>+?uyzuyxzuxwwzw>>????????????????vv-<br></br>
`vvv??vvvvv?v}v+^+++>>>>>>>>>>>>?>>>>>>>>vwxwrrvvrrr}v>>>??????????????ruy^<br></br>
`vvvvvv?>????}v+^^^^+>>>>>>>>>>>>>>>>>>>>?+>??>.~>|`|?>>>>??????????????v}~<br></br>
`vvvvvvvvv?>?}v++>++>>>>>>>?>>>>>>>>>>>>>>>^>?>.   `>>>>+>>???????????????`<br></br>
`vvvvvvvvvv??>>+++++>>>>>>>????>++++++++++>^>>>-  `+>++^~~+>>>????????????`<br></br>


            </div>,

            <div>

.????????????.  +???????????????`.?????????>`         .~.                  <br></br>
`vvvvvvvvvvvv. ~vvvvvvvvvvvvvvv?.>v?>vvvvvv?-        -~.                   <br></br>
`vvvvvvvvvvvv..vvvvvvvvvvvvvvvv>^>>++?vvvvvvv>.    `|`                     <br></br>
`vvvvvvvvvvv? >vvvvvvvvvvvvv??>>>>>>>?vvvvvvvvv| .~~                       <br></br>
`vvvvvvvvvvv?|vvvvvvvvvv??>>>>>>>>>>>>vvvvvvvvvv>|.                        <br></br>
`r}v??????????????>>vrwv>?+>>>>>>>>>>>vvvv??>^~`.                          <br></br>
`wwwv?+>>>>>>>>>>>+vwww??v?++>>>>>>>>>??>+~-```-~~`                      ~`<br></br>
`rwwr?>>>>>>>>>>>>?wwwv>vvvv>>>>>>>>>>?vvvvvvvvvvvv|           .-       -?`<br></br>
`vwwwv?>>>>>>>>>>>}www?vvvvvv?>>>>>>>>>vvvvvvvvvvvvv^       .`--?^     `??`<br></br>
`v}www??>>>>>>>>>?wwwv?vvvvvv?>>>>>>+>>?vvvvvvvvvvvvv^    ....  ?v^   `>>>`<br></br>
`vvwww}vv?>>>>>??rwww?vvvvvvvv>>>>+?r>+?vvvvvvvvvvvvvv-.       .??}>.`-+>v~<br></br>
`vvvwwwvvvv?>?vv}wwwv?vvvvvvvv>++?rvvv++rzzwwrr}?vv?|. ^>|`    ~???wv-->rw-<br></br>
`vvvrwwrvvvvvvvvrwwr>vvvvvvvvv??wv~ -z>^}uuxuuuur^`    `?vv?^. ^??vvzv?zw}-<br></br>
`vvvvwww}vvvvvvvwww??vvvvvvvvvwv~   .w?^vuuxwzxxx-      >???v}?vwwwwwwwwwz|<br></br>
`vvvvvwwwvvvvvvrww}?vvvvvvvvwx?      }v+}yyu??vvrv+~`.  `??vwwr}vv????????`<br></br>
`vvvvvrwwrvvvv}www?vvvvvv}wzzuv      }}+^wuux?>>??vv}}v?|>rrv?????????????`<br></br>
`vvvvvvwww}??>}wwv?vvvv}wzzzyyw     >z>+^vuxuz>>>>????vvr}v???????????????`<br></br>
`rvvvvv}wwvvv??ww?vvvwwvxuyzyyz.   >z>+>+?xxxuz>>>>>???vv?????????????????`<br></br>
`xrrrvvvr}vvvv?vv?vww?.`uyyzuyx.  ?z>+>>+>zxxzxr+>>>>>?????}??????????????`<br></br>
-y^.|?}}vvvvvvv??zr+.  .xyyzuyy`.vw>>>>>>+??????>+>>>>????}u}?????????????`<br></br>
-y^   .~?zuxuuuuu+.     wyyzxyu?w}+>>>>>>^>>>>??+`|>+?????zyuv????????????`<br></br>
-y>      wyyyyyyy^.     vyyxzzwx?+>>>>>>>^>>>+~..|?v??????uyuxv???????????`<br></br>
-y?   .+}w}vvvvvvwwv+`  ?yyxvzw>+>>>>>>>>+++~+?v}zxzv?????uyyuz???vv??????`<br></br>
-yv -?wr?+>????>^+?}wzr?vzzwx}+>?>>>>>>>>+^?wuuyyuuyyuv???ryyuu????rv?????`<br></br>
-x}vz}>+>??v}vv>++?v??v}wzzz?+>>?>>>>>>>>>ryyyuyyyuyyyz????zyyu}v??vv????v`<br></br>
`zwv>+>>vv??rvv>^>>vvvvv?>>>>>>>>>>>>>>>?zyyyyzyyyxuyyz?????ruyyz???????vv`<br></br>
.?++>>>?vv??}vv>^>>>vvv?>>>>>>>>>>>>>>>+ryxxyyxuyyxuyyv???????}wz}????????`<br></br>
`v?>>>?vvvv?vvv>+>>>>v?>>>>>>>>>?>>>>>>>?uyzuyxzuxwwzw>>????????????????vv-<br></br>
`vvv??vvvvv?v}v+^+++>>>>>>>>>>>>?>>>>>>>>vwxwrrvvrrr}v>>>??????????????ruy^<br></br>
`vvvvvv?>????}v+^^^^+>>>>>>>>>>>>>>>>>>>>?>>??>.~>|`|?>>>>??????????????v}~<br></br>
`vvvvvvvvv?>?}v++>++>>>>>>>?>>>>>>>>>>>>>>>^>?>.   `>>>>+>>???????????????`<br></br>
`vvvvvvvvvv??>>++++>>>>>>>>????>++++++++++>^>>>-  `+>++^~~+>>>????????????`<br></br>


            </div>,

            <div>

.??????????.  .>???????????????.-?????????>.          -`                   <br></br>
`vvvvvvvvv}`  +vvvvvvvvvvvvvv}>.???>vvvvvvv?~       .~`                    <br></br>
`vvvvvvvvvv` ~vvvvvvvvvvvvvvvv>>>+>+?vvvvvvvv?-   .~-                      <br></br>
`vvvvvvvvvv`.vvvvvvvvvvvvv??>>>>>>>>?vvvvvvvvvv>``~.                       <br></br>
`vvvvvvvvvv~~vvvvvvvvvv>>>>>>>>>>>>>>vvvvvvvvvvv>`                         <br></br>
`v???????????????>?}rw?>>+>>>>>>>>>>>?vvv?>+~`.                          ..<br></br>
`wv?+>>>>>>>>>>>>+vwwr>vv?^>>>>>>>>>>??>^~--~~~~~|~                      +-<br></br>
`wr??>>>>>>>>>>>+?www??vvvv>>>>>>>>>>>vvvvvvvvvvvvv+          .`|.      |?`<br></br>
`rw}?>>>>>>>>>>>>}wwr>vvvvvv?>>>>>>>>>?vvvvvvvvvvvvv+      .`~~`.`|.   ~??`<br></br>
`wwwv?>>>>>>>>>>?www??vvvvvvv>>>>>>>>>?vvvvvvvvvvvvvv>.  .``.    .??` ~>>>`<br></br>
.}wwrvv?>>>>>>?vrww}>vvvvvvvv>>>+>vzr+>vvvvvvvvvvvvvvv^ ..       -??}^`+>>`<br></br>
`vrww}vvv?>>?vv}www??vvvvvvvv>+>vwv^z?^?zzzzwwrr?vv?^.  ->|`     ^??vw^->?`<br></br>
`v}wwwvvvvvvvvvrww}?vvvvvvvvv?vzv~  }v^>xuxxuuuu}^`      >vv?^.  >???}z>?w|<br></br>
`vv}wwrvvvvvvv}www?vvvvvvvvvvz}~    ?r^>uuuuxwzxu~       -???vv?+vrwwwzzzz|<br></br>
`vvvrwwrvvvvvvwww}?vvvvvvvvwxv      >w+>zyuux?>?v?^~`.    >??vwzwr}vvvv?vv-<br></br>
`vvvvwwwvvvvv}www?vvvvvv}wzxy}      ?z+^>xuxuz?>??vvvvv?+-~vwwvv??????????`<br></br>
`vvvvrwww????www}?vvvvrwzzwyyz.    |x?+++zuxxuz>>>?????v}r}rv?????????????`<br></br>
`vvvvvwwrvvv>vwz?vvvwz}zuyzuyu.   |z?+>>^ruxxxuw>>>>>????vv???????????????`<br></br>
`rvvvvrwvvvvv>rv>vwzv``uyyzuyy~  ^z?+>>>+?uxxzzzv+>>>>>?v???v?????????????`<br></br>
.^vrr}v}vvvvv?>?wz?`  .wyyxuyy+ >z?+>>>>+>????>>?+^>>>>?????zw????????????`<br></br>
.  .~?}wzzwwzzzx?`     vyyxxyy??z>+>>>>>>^>>>>>?>|`|>>>????vyur???????????`<br></br>
`.     +yyyyyyyu`      ?yyuxywzr+>>>>>>>>^>>?>~..-|+???????}yyu}??????????`<br></br>
-`    ~vuxzwwwwww}>-.  ~yyyzrzv+>>>>>>>>>++>- `|^vwxzv>????ryyuxv???}?????`<br></br>
~| .+}zv>>??>?++>vrzwv>^wxwrz?+??>>>>>>>>>+>^?zxyyxxuuwv???}yyyuw???v}????`<br></br>
-??wzv+>??v}vv>++?v?v}wzzzzw>+>>?+>>>>>>>?>?zyuuyyyuyyyx????xyyyv????r????`<br></br>
`zr?>+>vv??}vv>++>vvvv????v>>>>>?+>>>>>>vv}uyyuzyyyzuyyu?????wuyuuv?>v???v-<br></br>
.>+>>>?vv??}vv>^+>?vvvv?>>>>>>>>?>>>>>>>vryxuyyzuyyxxyyx??????vrzuw???????`<br></br>
.>>>>?vvv??}vv>+>>>?v?>>>>>>>>>>?>>>>>>>?ryyzuyxxyuzwuuv>????????vv???????`<br></br>
`v?>>vvvvv?v}v>+>>>>>>>>>>>>>>>>?>>>>>>>??}yuzxzwwwrrr}?>???????????????vr~<br></br>
`vv?vvv?vv??}v+^+^|+>>>>>>>>>>>>?>>>>>>>>>+?}v?^~^}v??v>>>??????????????vw|<br></br>
`vvvvv??????rv+^>+++>>>>>>>>>>>>??>>>>>>>>>^>>?`  . .>?>>>>>??????????????`<br></br>
`vvvvvvvvv>???++>++>>>>>>>?v?>++>>++++++++>^+>>^   .+>++++++>>????????????`<br></br>


            </div>,

            <div>

.????????^`. .-??????????????^~-|>>????????-                               <br></br>
`vvvvvvvv>`..`?vvvvvvvvvvvvvvv>->?>?vvvvvvvv+.    .                        <br></br>
`vvvvvvvv?`..^vvvvvvvvvvvvvv??>>>>>>vvvvvvvvv?|. .                         <br></br>
`vvvvvvvv?``-vvvvvvvvv?????>>>>>>>>>?vvvvvvvvvv+. .                        <br></br>
`vvvvvvvvv>^+vvvvvv}}v>^+>>>>>>>>>>>?vvvvv?>+~``.                        . <br></br>
`v?????????????>>v}rrvv?+^>>>>>>>>>>>vv?>^-......                        `.<br></br>
`}v?>>>>>>>>>>>>?vrwrvvvv?+>>>>>>>>>>?v?>+^^++++++^.                    .+`<br></br>
`r}?>>>>>>>>>>>>v}rr}vvvvvv?>>>>>>>>>?vvvvvvvvvvvvv>.           .      .^?`<br></br>
`wrv?>>>>>>>>>>?vrwrv?vvvvvv?>>>>>>>>>vvvvvvvvvvvvvv?.            .   `^>>`<br></br>
`wwrv??>>>>>>>?v}ww}vvvvvvvv?>>>>>vv>>?vvvvvvvvvvvvvv+      .     -~..+v?>`<br></br>
`rww}vv?>>>>??vrrwrvvvvvvvvv?>+>?v?zv++}}}}}}}}vvvv>-`.     ``.  .~|^||?}?`<br></br>
`rwwrvvvv?>?vv}rww}vvvvvvvvvv>?v?|`>z>^vxxxxxzzr?^`.     `. .-~~`-|~>v>^vr`<br></br>
`vwwwrvvvvvvv}rwwrvvvvvvvvvv}}v>`. .w?^?uuuuuuux?~`..    `--~+|~^??>?}r?}x|<br></br>
`v}www}vvvvvv}rww}vvvvvvvv}rzxr~    vv+vyuuuuxuuw^`-~~-`..--~?v??}wzzzzzww~<br></br>
`vvrwww}vvvv}rwwrvvvvvvvvrrxuw>.    ?}+>zuuuu}v}r?|||||~~^??>?}wwr}vv?????`<br></br>
`vvvwwwwvvvvrrww}vvvvvvrw>^xyw+.   .?v>+?xxxxxv?v+??????>+?vrw}v??????????`<br></br>
`vvv}www}???vww}vvvvvrzxx^~ryx>.  .?v>>++zxxxuxv?~->????vvvvv?????????????`<br></br>
`vvvvrwrv????}wv?v}rv+ruu>~vyu>. .?v?>>>^ruxxxxz?~.->?????v???????????????`<br></br>
`r}vvvwv??????v?vrv>-.?uu?~?yy? .?v>+>>>+?wwrr}}v~..~>????????v}v?????????`<br></br>
`>?rr}vv??vvv??vv+`. .>xy?~>uy?`?v>+>>>>>+????>>?|...~>?????vvwr}?????????`<br></br>
`~ .~?}zxxxxxuuv`.   .^wy}|^xxv?v>>>>>>>>+>>>>>|~|``|+?????v}wur}v????????`<br></br>
`+     ?yyyyyyyw`     ~}yx^|wzr?+>>>>>>>>+>>^`. -+>?}}?v???vrxyxr}v???v???`<br></br>
->   .^}zwr}}}}rw}?~. .?yyvvz}?>>>>>>>>>>++>`.|?vwxuuz}}}v?vrzyuzrv?v?vv??`<br></br>
~> `>r}?>?????+^>v}wrv??xxxzv>+>?>>>>>>>>?>>?ruyyyuuuuxwr}?v}wuyuwv?vvvv??`<br></br>
-v?wr?+?v?}vvv>^>?vvvv}rzzw?>+>>?>>>>>>>?v?}uyuyuuyyyuyuw}??}rwuxzrv?v?v??`<br></br>
`zv?++?vv?}vvv>++>vvvvv????>>>>>?+>>>>>>vv}uyuuuuuyuuuyyrv???vrwzuz}?????v-<br></br>
.>>>>>vvvvv}vv>+>>>vvvv?>>>>>>>>?>>>>>>>vvzuyyuuyuyuuuuxv?????vvvrrvv?????`<br></br>
.>>>>?vvvv?}vv++>>>?v?>>>>>>>>>>?>>>>>>>?}rwxuuzzuxwwzz}>?????????????????`<br></br>
`?>>?vvvvv?vvv+^+>+>>>>>>>>>>>>>?>>>>>>>???vr}r>-|+v??vv>>???????????????v-<br></br>
`vv?vv??vv??}v+^+^^+>>>>>>>>>>>>??>>>>>>>>+>???~  ...-??>>>??????????????v-<br></br>
`vvvvv??????}v+^>>++>>>>>>?>>>>>??>>>>>>>>>+^>?~    .>?>>>>>??????????????`<br></br>
`vvvvvvvvv>???+^>++>>>>>>>????>+>?++++++++++|>>+.   ~~~-~---+>????????????`<br></br>


            </div>,

            <div>

.??????>. .~^??????????????>>^-+++?????????>-   ..                         <br></br>
`vvvvvv?. -+?vvvvvvvvvvvvv???>>>>>vvvvvvvvvvv>`..                        ..<br></br>
`vvvvvvv` |?vvvvvvvvv????>>>>>>>>>?vvvvvvvvvv?+.                         `.<br></br>
`vvvvvvv^-+?vvvvvv}}??>>>>>>>>>>>>>vvvvv?>^~..                          `-.<br></br>
.?????????>???>>}rr}?v>^+>>>>>>>>>>?vv?+||~~~~~|~.             .       .`+`<br></br>
.>>>>>>>>>>>>>+?rrwvvvv?+^>>>>>>>>>>vvvvv?????????-         ....      .`^?`<br></br>
`?>>>>>>>>>>>>>}rwrvvvvvv?>>>>>>>>>>?vvvvvvvvvvvvvv^     ....        .`|>>`<br></br>
`v?>>>>>>>>>>+?rwwvvvvvvvvv>>>>>>>>>>vvvvvvvvvvvvvvv>.  .          ...`>>>`<br></br>
`}v?>>>>>>>>>>}www?vvvvvvvv?>>>>>v}>+?vvvvvvvvvvvvvv>.            ..```|>>.<br></br>
`r}vv?>>>>>>?vrww}vvvvvvvvv?>>>?rvzv++}rrrrr}rr?vv+.           .+|`-~-`-^>`<br></br>
`ww}vvv?>>>?v}wwwvvvvvvvvvvv>>}}v`>z>^vxxxxxxxxv~..`.         ..^??>^```|?`<br></br>
`wwrvvvvv?vvvrww}vvvvvvvvvvvvrw|? .rv^>xuuuuuuux|  `+>+~`.   .`-+?????^`+v`<br></br>
`rwwrvvvvvvv}wwwvvvvvvvvvv}wzxu^+  ?w+>uyuuuuuuu>   ~+>???+~`-``^????}r}wz|<br></br>
`}www}vvvvvvrwwrvvvvvvvv}wrzyyy?^  +z>+?uuuuuuuu? . -????????v?+^vrzzwr}}}-<br></br>
`vrwww}vvvv}wwwvvvvvvvvwv|`|uyyv~ .?v>+^ruxxxxxur.  .^????v???vrwwrvv?????`<br></br>
`vvrwwwvvvvrwwr?vvvvvwz?.  .wyy}- >?>>>^vuxxxxxuw`..``+?????vv}rv?????????`<br></br>
`vvvwwwv???vrw}vvvvwvru>.  .vyyw`^v?>>>+>xuuxxxz}~`````>????vvv???????????`<br></br>
`vvv}wr?>>>?vw??vwr+`}y?`  .>yy}^v?>>>>>^}wrr}vv?|--```~>>??v????rr???????`<br></br>
`}}v?}v>+>++?v?}r?`.~ryv`.  |yz?v?+>>>>>+>???>>>>~~~~`..|>?????v}xzv??????`<br></br>
`wvrw}???vvvvrw?`..`~}yr`.  `zw}?++>>>>>>+>>?>^`-~|^^|>v?v????vvxxxwv?????`<br></br>
-x-.->wyuuuyyyz.     ?yu^.  ^z}>>>>>>>>>>+>+|.  `>v}wzzzr}????v}yuxz}???v?`<br></br>
-u>   >uyuuuuuxv+`.  `xyr. -w}>>>>>>>>>>>+^~. .?wxxyyyyuxxz}?vvvyyuxwv??vv`<br></br>
-u| `>v}vv???>>?}r}?+~?xu?+w}>+?>>>>>>>>>>>>^+wyyyuuyyuuuxxz?vvvuyuuz????}`<br></br>
-r~?}v>>>vvvv>+^?vv}rwrzzxxv+>>??>>>>>>>>v>>ryyuyyxuyyuxyuxw??vv}uuu}v?v?}`<br></br>
`zwv>+?v?v}vv?+^>vvvvv???v?+>>>??>>>>>>>vv??uyyuuyuuyyuuyux}???vv}xuuzvv?v`<br></br>
`v>>>>vvv?}vv?++>>vvvvv?>>>>>>>>?>>>>>>>vvvvwuyuuuuuuxxxxz}?????vvvrwwv???`<br></br>
.+>>>vvvv?}vv?++>>>vv??>>>>>>>>>?>>>>>>>?vv?vrwwv?rwwwwwrv???????????vv???`<br></br>
.+>>?vvvv??}v?++>>>>?>>>>>>>>>>>?>>>>>>>?v>^?v?|  .`?>>vv?>???????????????`<br></br>
.?>>vvvvvv?}v?++++^>>>>>>>>>>>>>??>>>>>>>>>+^>?|      .>??>>??????????????`<br></br>
`vvvvv??v??v}?++++^+>>>>>>>>>>>>??>>>>>>>>>>^>>>.    `??>>>>>?????????????`<br></br>
`vvvvv???>??}?++>>+>>>>>>>????>>??>>>>>>>>>>++>>-   .--``---~>????????????`<br></br>
`vvvvvvvvv???>++++>>>>>>>>>>>>????>>++++++++^|>>^.       .---?>>>?????????`<br></br>


            </div>,

            <div>

.???+``^..-??????????>>>>>>>+^^^>>???????>+~.                          ... <br></br>
`vvv?``?``?vvvvvvvv????????>>>>>??vvvv?>|~-```.                       ...`.<br></br>
`vvvv~|v-`?vvv}}rv?????>>>>>>>>>>??v?+>>>?????+.                     ...`>`<br></br>
`vvvv?vv^~???}rr}?v}?>+>>>>>>>>>>??vvvvvv?>^~```.                    ...+?`<br></br>
.>>??????>>>?vvv}vr}?v?>>>>>>>>>>>?vvv?>>+++>>???~.                   .|??`<br></br>
.>>>>>>>>>>+vvv}}}rvvvvv>>>>>>>>>>??vvvvvvvvvvvvvv+.         ..   .. .`+>>`<br></br>
.>>>>>>>>>>>vvvrv}}?vvvvv?>>>>+>?>>?vvvvvvvvvvvvv?^.                .-||+?`<br></br>
.?>>>>>>>>>?vv}rvrvvvvvvv??>>>?vv?++v}}}}vvvvvv?^```.             .`~~~||+`<br></br>
`v?>>>>>>>?vvvr}}}?vvvvvvv?>>?v++rv^?}}}}}}}}v+````..    ..    ...-~~~-+>|.<br></br>
`}vv??>>???v}rrvrv?vvvvvvv?vvv}>}wr?+vzxzzzzzxv....      .-~-`..-~|~~~-|>?`<br></br>
`r}vvv????v}rr}}rvvvvvvvvvwr?ru?v`vv>}zuuuuuuux|   ..     `~~~-^>+^||~`-+?`<br></br>
`rr}vvvvvv}rrw}}v?vvvvvvrw?~|ux-?.>}>>}uuuuuuuuv.  .`---`..`--~|?v??>+--??`<br></br>
`ww}}vvvvvrrrrv}vvvvvvrw?-`~>yz|>.|??|?uuuuuuuur.   .`~~||||||~~>??vv??vrz|<br></br>
`rwr}vvvv}rrw}v}vvvv}wv~`~~|?yw>>`-+?^^}uxxxxxuz|   ..`-~~|????>>?vv}wwr}}-<br></br>
`}rwr}vvv}rrw}}vvvvrr+`--. .>yr>>~`??>^>xuuxxxzw?. .....`-~^???vvvvvvv????`<br></br>
`}rww}v??vrwwv}?vrr?--|`    +y}?>.>?+>+^}zzwrr}rv``-~-....`~???v?vvv??????`<br></br>
`v}rw}v?>?vrr}vvw?~~-^>`    +ywx|+v>>>>^>}r}}}}v>~||||`.....|???vv????vv??`<br></br>
`vv}rvv>>>>vvrvv+~>|`??`    +uur>?>>>>>>+?v???+~||||~~~````|????vvv??vr}v?`<br></br>
`r}v?v?+>>>??v>>++^`~++-    |ur??>>>>>>>+>>+~`..~|~|^+>>?v?}}vv?vrrvv}r}v?`<br></br>
.vv}rw}}wwwzzv>^``++`^+|   .|}??>>>>>>>>>|~....  -+?vvrzzxr}}}v?}rr}}rr}vv`<br></br>
.}> `?xyuuuuyuv`. ~>||++. .~^??+>>>>>>>>>^+^.   |rxuuuxuuur}}}rrrrr}wrrr}v`<br></br>
.z? .~}uuuuxwrr}v>||~-??+`^???>?>>>>>>>>?>^|` .vuyuyyuuyyyuzr}}rrrr}zzrrr}`<br></br>
`}-->??vrvvv?++?vrr}v?wxx??v?+>?>>>>>>>>??+>>+^uyyxuyuuuyyuur}}v}}r}wwrrvv.<br></br>
`r?v?>??vvvv?>++?vvv}}}vvvv?+>>?>>>>>>>>?v?>}x??yyxuyuxuuuxxr}v?v}}rv}rrvw-<br></br>
`wv?>?vv?}vvv>++>vvvvv?>>?>>>>>??>>>>>>>vvv+rww^vzw}wxzxxxxrvv???v}}r}vv}}-<br></br>
.?>>>vvv?v}vv>++>>vvv??>>>>>>>>>?>>>>>>>?vv^?wzv-|-`-|>wwzw?v??????vv}v?vv-<br></br>
.+>>?vvvvv}vv>++>>>???>>>>>>>>>>?>>>>>>>???+^?vv~..``..|>>>???????????????`<br></br>
.+>?vvvvv?v}v?++>++>>>>>>>>>>>>>??>>>>>>>?>+^>>|.   ....+???>?????????????`<br></br>
.?>?vvvvv??}v?^^+^^+>>>>>>>>>>>>??>>>>>>>>>>^+>>.     .^??>+>>????????????`<br></br>
`vvvvv?????v}?++>+++>>>>>>????>>??>>>>>>>>>>+^>>~.    ```.````~>??????????`<br></br>
`vvvvvvvv???v?++>>+>>>>>>>????????>>>>>>>>>>>++?+..       .``.`>??????????`<br></br>
`vvvvvvvvv>>>>++++>>>>>>>>>>>>>>>?>>+++++++++^^>>-.        .--|??>????????`<br></br>


            </div>,

            <div>

...|>`-+????vvvvvvvvvvvvv??^|^>>+|`~|+>??>+|~`          .           ..~+++.<br></br>
`^.+?~|?vv}rrwwwwwrrrrrrrrv?>>???vvvvv??+|||||~.      .       ..```..`>???`<br></br>
`v?v?~~???rrrr}wzwrrrrrrrr}?>>>??vvv?>>>????vvv>-            .~>>^`..~^>>>`<br></br>
.????>>>>?}}}}vrwrzzwrrrrr}v>>>>?vvvvvvvvvvvvvvv?.       ...  .``..`~||^>?`<br></br>
.>>>>>>>+v}}}vvr}vwzzzwrrrrv?>>>??vvvvvvvvvvvv?|..    .`-``. .....`~~~~~^?`<br></br>
.>>>>>>>>}}}rv}}vv}}wzzwrrr}v}v++>v}}}}}}}}v?^`.`~^^--~`..  .....-||~~~~|^`<br></br>
.>>>>>>>?}}}rv}}vvvvrzzzwrrww}vv+^vwwwrrrrwr>-^?vv+-..     ....`~~+?>^~-|~.<br></br>
.>>>>>>?v}}r}v}vvvvv}wwrrrwwrr}zv++rzxxxxxxxx}v?~.        ....-~~~^+>>?+-~.<br></br>
`vvvv??vv}}r}v}vvvvvv}vvrwv}wxuv}}vwuuyyyuuuy}.         .-~``~|^^^+>>>?}v?`<br></br>
`wwwrrrrrrwwwwrv}rr}}}}ww>+ruuyrzuwrzuyyuuuuux+.````~~|^^?zww}}vvvvvvv}rrz~<br></br>
`}}r}}}}rrwwrwr}rrrwwzxzrrxuxuyrzzxrzyyyyyyyyyx+||^++^^^+>vvv}r}v?>>??vvvv-<br></br>
`}vvvvv}rrwrv}vv}}rwzxxxuuzvvuuvr>w}vwuuuuuuuuw-......`-~~------~~|^^+????`<br></br>
`}}vvvv}rrw}v}vrwwzxxuuuwv?^|}w??-??+^wuxxxxzwr^...``~||||~.``````-?????vv-<br></br>
`r}}vvv}rrwv}rrzxxxxxxr?+^-..w}??v}?>^?zzwr}}}}?`-~||||~~~~`.``````>??vvv}~<br></br>
`rw}vvv}rwwwwrwzzwrr}?^^^-. ^x}?wxr}v?^?}}}}}}v>~||~~~~~~~~-.``````+???vvv-<br></br>
`rw}v??vrwwwwvr}??v?|~~-`.``?x?^zw}r}}?>v????^-||||~~~~~|^^>?vv??vv???vv??`<br></br>
`rr}}vvvv}}vv?v?v?+~```...|-?z+vr}rrrr}???>|`..-|||~~|+>vvvvvrrrzxr}vvvv??`<br></br>
`}rr}??>>??v????>|~--|^|-~`~v?vv}rrrrrr}}?` ... `||~+?vv}}}}rzwzxzww}vvvv?`<br></br>
`w}vv?vv}}r}???>++>^^^|-`^~>?rwv}rrrrrrrr}>`     `~|rxxxuuuxuuxzwwwwxrvvvv~<br></br>
.+?wxxuuuuuxr>^+^+?>-`. `^>+?}vv}rrrrrrrrr}+.     `?uyuuyyuuuuuwwwwwz}vvv}|<br></br>
 .`-vuyuuxxzrvv?>|?v>...~????>???v}}rrrrrrzz?.  .``|wyuuuuxuuuuzzww}vvvvvw|<br></br>
 ^+^?r}vvvv?>>?}rrwxx}??????++??>??v}rrrrwzxx?.`````|v}}wxxzxxxzrrv?vvvvvr|<br></br>
`v????>v}}}v>+>?vvvvv}vvv??+>>>?>>>?v}rrwwwzxw?}>````->>>?vxxxz}vv??vvvvv?`<br></br>
`v??>???}vvv>++>?vvvvv?>>>>>>>>?>>>>?v}rwwwzzx}}z?`.`.`>>++?v}}vv????vvvvv-<br></br>
`?>>?vv?vvvv>++>>?vvv?>>>>>>>>>>?>>>>?v}wwwwzxzv}w?....`++^^+>????????vvvv-<br></br>
.+>?vvv??}vv?++>>>?v?>>>>>>>>>>>?>>>>>?vrwrrzxxr???-   .`+>?v???????????vv`<br></br>
.+>?vvvv?vvv?++>>>>>>>>>>>>>>>>>??>>>>>?vrrrwzxxv???|.   .^>>++>??????????`<br></br>
.+?vvvvvv?}vv+^++^+>>>>>>>>>>>>>>?>>>>>>?vrrrzzxw????+`  ..`````~+????????`<br></br>
.>?vvvvvv?v}v>^+++^>>>>>>>????????>>>>>>>?v}rwzxx}????>+`. ..`...+????????`<br></br>
`vvvv??????v}>+>>>+>>>>>>>???>>????>>>>>>>?v}rzxxz??????^.  .--`~?????????`<br></br>
`vvvvvvvv????>++++>>>>>>>>>>>>>>>>?>>>>>>>>?vrzxxxr??????+`. .~|??????????`<br></br>
`vvvvvvv??>+++++>>>>>>>>>>>>+++++>>++++++++>?vrwwzw???????>`. .>???>>>????`<br></br>


            </div>,

            <div>

.. ~???????v}}rrrrrrrrrrrr?+^^>>^-``~^+??????^.        `-.       .``-+++++.<br></br>
`|.|vvvvv?}wwzuuuuuuuuuuuur?>>?v??vvvvvvvvvvvv?~     .`.      -^>?>|~|>???`<br></br>
`????>>>+?wwwr}xuuuuuuuuuuzv>>>vvvvvvvvvvvvvvvvv>.  ..       `vuxw?|~-|>>>`<br></br>
.>>>>>>>+vwwwv?rzuuuuuuuuux}?>>>vvvvvvvvvvvvvvvv?`       .`. .|>+^||~~-|>?`<br></br>
.>>>>>>>+}www??v}wxuuuuuuuuw??>+?vvvvvvvvvvvvv?~     .-^???~.-~~~|~~~~~-|>`<br></br>
.>>>>>>>>rwwr>vvvv}wxuuuuuuxzxw++?}rrrwwwwwv>|.  .~+?v??^`..~~~~~~^^~~~~~|`<br></br>
.>>>>>>>?www}+vvvvv}wuuuuuuyyz}v++ruuuuuuuux>`|>?vv?^`.   .~|~~~~~?wv+~~~-.<br></br>
.>>>>>>?vwww?>vvvvv}rzxwzuyyuurz?|?xuuuuuuuyurv?^`.     .`~~~~~~~~>vv}v>~~.<br></br>
`rr}}}}rrwww??vvvvvvv}}vwurxxxuwzvryyyyyyyyyyv.       .`~|^^^^++++?vvv}wrv`<br></br>
-xuuuuuuxxzzwzzzzwwrrr}zz>+wxuyuyuuuyyyyyyuuuz+|^++>?vrrwwwzzzxxuuuxxxxxxu^<br></br>
`rwwwwwwzzww}wzzzzzzxuuuwzuuuyyyyyuuyyyyyyyyyyx}rwwwzwwwrrrr}}}}}vv}rrr}}}~<br></br>
`vvvvvvvwww}>v}}}rwxuyyyyyyyyxyxurrwwxuuuuuuuur~|^^^^^||||||~~~~||->??????`<br></br>
`vvvvvv}www?>}rwxxuyyyyyyuxv+>zr}+>>>>zuuuxzw}?^~||~~~~~~~~~~~~~~~-^>?????`<br></br>
`rvvvvvrwww?vzuuyyyuuxwr}v^..}}zv}}v?^?w}v??>>>>~|||||||~~~||~~~~~~|>???vr|<br></br>
`rwv?vv}wwzzxuuuxw}vv????~. >wz}wxuxw?+>>>>>>?>^~||~~~~|~~~~~||~-~~~>>?}r}-<br></br>
`rwv>>?rzxxxxxwv????>^||-.||}zv?wxuuuzv??>>?>|-|~||~|~~~|>>vrww}?v?>??}v??`<br></br>
`ww}v}rwwwr?}}?>??>^-``..`>>wr+?wxuuuuxrv?>^.  `|~~|~|>vzyyyuuyyuxwwvv????`<br></br>
`wz}v??>>??vv????>~``~++~>|vz+?vwxuuuuuux?.     `||~>zyyyyyxuyyyyuuuw?????`<br></br>
`w}?vv}wzxx}>????+^^+>+~`vvx?vzzzxuuuuuuuw?`     `|^uyuuyyyzyyyyxuyyyv???v~<br></br>
.+?zuuuuuuuzv??>++^^|-. |ru??}wwzxuuuuuuuuyx>     .^uyuxyyzxyyyxxyyyz????}^<br></br>
   >yyyuuxwrvvvvvv?+^~--vx?+>>??vzxuuuuuuuuyu+     .vyxzuzwuuuzruyuz?????r^<br></br>
 -?rw}????>^^^?vv}wzxxxxxv^>>>??>?vrxuuuuxuuyu+     .?v?}zwwzwrwxxw??????}^<br></br>
`wz?+>>vvvv>++>?vv???????>>>>>>?>>>?}xuuuxuuyyu?.    .^||^?r}rrrwv>???????-<br></br>
-}>+>v?v}vv?+^+>?vvvvv>>>>>>>>>?>>>>?}xuuuuuuyyuv~    .^|~|>??v}v>>???????`<br></br>
.+>>vvv>}vvv+++>>?vv?>>>>>>>>>>>?+>>>?rxuuuuuyyyuw+    .^|~-~^?v?>>???????`<br></br>
.+>?vvv??}vv>++>>>??>>>>>>>>>>>>?>>>>>?rxuuuuuyuuux?.   .|^??????>>???????`<br></br>
.+>vvvvv?}vv?++>>>>>>>>>>>>>>>>>??>>>>>?wuuuuuyyuuuu}~   `^^^++>>>>>??????`<br></br>
.+?vvvvv??}v?+^+^^+>>>>>>>>>>>>>>?>>>>>>?wuuuuuyuyuuuw+.   ~|~~-~~+>>?????`<br></br>
.>vvvvvv??v}v>^+++^>>>>>>>?????????>>>>>>vwuuuuuyyuuxux?`  .|^||~+?>>?????`<br></br>
`vvvv>>>>??v}>+>>++>>>>>>>>>>>>>????>>>>>>vwxuuuyyyuuxuxr~  .||~|??>>>????`<br></br>
`vvvvvvvv??>?>++++>>>>>>>>>>>>>>>>?>>>>>>>>vwuuuyyyyuuuuuz>. .~~????>>>>??`<br></br>
`vvvvvvv??>+++++>>>>>>>>>>>>++++++>++++++++>vwxxuuuuxxxxxxz?. .+????++++>>`<br></br>


            </div>,

            <div>

 ..^???????v??????vv???vvv?^^>>?>|~~^+>??????+.         .        .```|++++.<br></br>
.|`>vv?v}}rw}}rwzxxzr}}}r}v?>?vvvvvvvvvvvvvvvvv|      .       `-|^^|~~^>??`<br></br>
.?>?>>+?v}wr}}}zuuuzr}}}}}v?>>?vvvvvvvvvvvvvvv?~.            .^??>+|~~~^>>`<br></br>
.>>>>>+?vrwr}vvrzxxw}}}}v}v?>>>?vv}}}}vvvvvvv>-`.         ...`|^^|||~~~~^?`<br></br>
.>>>>>>vvrwr}vvvv}rwr}}}vv}rrv>>vrrrrr}}}}v?|`..       ..`-``~~~||~~|~|~~^`<br></br>
.>>>>>>vvww}v?vvvv}}r}}}}rzx}u}++}zzzzzzzxz+..    ...`.....`~~~~~~||~~+>^~.<br></br>
.>>>>>?v}ww}v?vvvvvv}r}}wxxrv}w?^?zuuuuuuuuz- ...``...   .`||~~~~~^>+^|~^^.<br></br>
.>>>>>?}rwr}v?vvvvvv}r}rwzwwr}rw>+ruuuuuuuuy}``..       .-|~~||~~~|~|>v+||.<br></br>
`???vv}rwwr}vvvvvvvvv}rv}rvrr}rwv?vuuuyuuuuuu^      ..`-~|||||^^^^^^^>vvv?`<br></br>
`}}rwwwwwwr}}rrrr}}}rr}}}?>vrrwzzvvwuuuuuuuuu}`.`-~|^++>>>>>>>>???????vv}}~<br></br>
`}}}}}rwwwr}}rrrrrwwzzww}}wwwwwzz}}}xyuuuuuuuu?^>>??>>>>??>>>>>>>>>>??vvvv-<br></br>
`v}}}}rrwww}v}}}rzxxzzzzxxxzwrrz}?v}wuyuuuuxxzv~+>>+++>>>>>+++>>++>?vvvvvv-<br></br>
`}}rrrrrwwwvv}}rzxxxzwwwwwrv??rr?>???vxxxzwrvv?^|^^||||||||||||||||--+???v`<br></br>
`}vvv}rwww}vvrwxxzwr}}vvv?>^|vrv????>^?vvv??>>+|~|~~~~~~~~~~~~~~~~~..`>??v-<br></br>
`rrv?vvrww}}wzzw}v???????+^|?wr?vv}vv?+??v}?+~|||||~~||~~~~^+>>>>>^|+^>?v}~<br></br>
`rr?>>?}ww}rzw}?????>>++^|^^rr??vvvvvv??v}v^...~|||~~~~|>?vwxxxzrwr}vvrvvv`<br></br>
`r}v??vv}rvvv??????>^^|||^+?w?>?vvvvvvv??|`.   .~||~~|?wuyyyyuyyuuuxzz}???`<br></br>
`r}??????vv??????>+^^+>>+>+}v+??vv}}vvv}+`      .-|~^?yyyyyuyuyyyyuuxrrv??`<br></br>
`}}v}rzxxuz?>????>>>>>>+^vvv??vvv}r}}}v}}v?`      -|?vuuyuyuuuyyuuuyyw}v??-<br></br>
.>vzuuuuuuuw???>>>+++^^~>r}?>vvvvwxzr}}rwwzr+      -??wuuuxxuuyuxuuyx}v??v~<br></br>
.~-?uuuxzw}v?v}}}vv??>+>}}?>>>?>?wxxr}}rwrwzw^      |???vv}zzzzwxxuzvv???v~<br></br>
.^vr}v????>+^+?vv}rrwwww}?+>>>??>?vv}}v}rrrwzw~      `^^^+++}wrrwzwv?????v~<br></br>
`r}>>??vvvv>++>vvvvvv??>>>>>>>>?>>>>?vv}rrrrzzr-      `|||^+vrrww}??vvv???-<br></br>
`v++?v?vvvv?+^>>?vvvv?>>>>>>>>>?>>>>?vvvrwzxuxz}-      `~||>vwzzwv??vvv???`<br></br>
.+>>vv??}vvv+++>>?vv?>>>>>>>>>>>?>>>>?v}wxuuuxzzv~.     .~^>vv}}}vvv}vv???`<br></br>
.+>?vvv?v}vv>++>>>??>>>>>>>>>>>>?>>>>>?vrwwwrrzzz?~.     .|v}}vvvvvv}}vv??`<br></br>
.+?vvvvv?}vv?+^>++>>>>>>>>>>>>>>>?>>>>>?vvvvvvrzzw>|`.    .^?vvvvvvv}}vv??`<br></br>
.>?vvvvv??}v?+^++^+>>>>>>>??>>>>>?>>>>>>?v}}vv}wzz}^|-.    .^????vvv}vvv??`<br></br>
.?vvvvvv??v}v+^>>++>>>>>>>?????????>>>>>?vwzw}}rzzzv^^~`    `+???}}vvvv???`<br></br>
`vvvv???>??v}>+>>++>>>>>>>>>>>>>>??>>>>>?vrzxzwwzzzz?|^|`.   .|>v}}vvvv???`<br></br>
`vvvvvvvv??>>>+++>>>>>>>>>>>>>>>>>?+>>>>?v}wzuuuuxxzw>|^|~.   .~v}}vvv????`<br></br>
`vvvvvvv??++++>>>>>>+++++>>>++++++>++++>?vvvrzxxuxxzwv^^^^|.   ^vvvvvv?>>>.<br></br>


            </div>,

            <div>

 -????????vv>++^^>>>+^^>??>^^?>???????????????-               .-------++++.<br></br>
.>vv??>vwwww????v}r}?>>???>>>?vvvvvvvvvvvvvvvvv|             .~||||~~~|>??`<br></br>
.>>>>+>vwww}>v}rwzwv?>>>>>>>>>?vvvvvvvvvvvvvv?|.            `~||||~~~~-|>>`<br></br>
.>>>>>>}wwwv>vv}rrrv>>>>>>>>?>>vv}}rrr}}}v??+`             `||~~~~~~~~~-|>`<br></br>
.>>>>>>}wwwv>vvvvvvv?>>>>>>}xv+>rxuuuuuxuu?-.             `|||~~|||||~^~||.<br></br>
.>>>>>?rwww??vvvvvvvv>>>+?}w?z?^>zuuxxxxuux|            .-|||~~~||||~|v}?|.<br></br>
.>>>>>?wwwr??vvvvvvvv?>+?}v>|?r>|?uuuuuuuuyr.          .-|||||||||||~|?v}v`<br></br>
.>>>>?vwwwr>?vvvvvvvvv?vv?>>?+vv+>xyyyyuuuuu>       .`~|~~~~||||||||||???v-<br></br>
.>>>?v}www}>vvvvvvvvvvvv??????>v>+?xuuuuxxxuw`    .-~||~~~~~~~|||||||~????`<br></br>
.??vvv}wwwv>vvvvvvvvvv????????>??+^?xxxxxxxxu? .`~||~~~~~~~-~~~~|||||-+???`<br></br>
`vvvvv}wwwv?vvvvvv}}v?????????>?v+>^}uuuuuuuuw~~||||||||^^||||||^^^^^~+??v`<br></br>
`rrrrrwwww}}}}}rwzzwv???vv????>?>>??}uyuuuuzrv>|>>>+++>>>>>>>>>>>>>>>>?vvv-<br></br>
`rwwwwwwww}}}}}vrrv??????????>?v+>???}xzw}v?>>>|^^^^||^^^^^^^^^^^^^^^|^??v-<br></br>
`vvvv}wwww??vvvvv???????????>>v>>>>>>^??????>>^~||~~~~~~~~~~~~|^^|~~~-->??`<br></br>
`rv???}wwr>vvvv?????????????>v?+>>>>>++?vrw?~`~||||~~|~~~|+?vrzxxwvvv?>>?}~<br></br>
`rv+>>?vrr>?vv?????????>>>>+vv+>>>>>>>^>vr?-  .~|||~~~~>}wxyyyyuyyuuxxw}}v`<br></br>
`w?+>>>?vv?v???????>>>>>>>+vv++>>>>>>>>^+`      -||~~~?yyyyyyuuyyyyyuux}??`<br></br>
`}>^+>??v}}?>????????????>v}++>>>>>>>>>+..       `||~^wyyuuyyxuyyyuuyyyr??`<br></br>
`vvvwzxuuur>>???????????>?}>>>>>>???>>>??>>~.     `|~|wuyuyuzxyyyuzuyyu}??`<br></br>
.?vxuuuuyyu}????>>>>>>>>vr>>>?>>?vrv?>>vv?>?+      `|~?}rrzzwxuxzruyuxv???`<br></br>
.>>ruuxzrv???v}rr}}vvvv}w>>>>>?>?vr}?>>vvv>>>~      .||^^+?vvrwwwwzxwv????`<br></br>
.>vv?>>>??>+^+???vvvv}}}?>>>>>?>>???>>>?vv?+>?`      .~|~~~-?rrrrrwv>?????`<br></br>
`}?^>>vvvvv+++>vvvvvv??>>>>>>>>?>>>>>>>>vv?>??>.      .~||||>??vrr}>?vvv??`<br></br>
`>+>?v?}vvv>++>>?vvvv?>>>>>>>>>?>>>>>>>>?}}rr}?^        ~|^>vrrr}}v?vvvv??`<br></br>
.+>?vv?v}vv?+^>>>?vv>>>>>>>>>>>>?>>>>>>?vrwwr}??~        ~+}zzwrrrr}}}vv??`<br></br>
.+>vvvv?vvvv+++>>>>>>>>>>>>>>>>>?>>>>>>??vvv?+>?>`        ~vrrrrrrrrrr}v??`<br></br>
.+?vvvv??}vv>+^++^>>>>>>>>>>>>>>>?>>>>>>>>>>>+^>>>.        |}wwwwwwrrrvv??`<br></br>
.>vvvvv???}v?+^+^^+>>>>>>>???>>>>?>>>>>>>>>>>>^+>>^         +wwwwwzzrrvv??`<br></br>
.?vvv?vv??v}v++>>+^>>>>>>>>?????????>>>>?v}v??>|>>?|         +wzwzwwrrv???`<br></br>
`vvv????>??v}>++>++>>>>>>>>>>>>>>>>+>>>>?}wrr}v???>?`         |}zwwwwrv???`<br></br>
`vvvvvvvv??>>>+++>>>>>>>>>>>>>>>>>>+>>>>vrwwwwwww}v?>.         |rwwwwrv>>?`<br></br>
`vvvvvv???++++>>>>>>^^^^^^+++++++++^+++>vrrrrrrzzzwv?^         ^vv}rrv?>++.<br></br>


            </div>,

            <div>

.????>??vv?>^^||^^^^^^^^^^^|>?????????????????|              `--------|+++.<br></br>
`????>vwww}??v?>++>>>>>>>>>>>vvvvvvvvvvvvvvvv?-             `~|||||~~~~+??`<br></br>
.>>>>>vwwwv?vvvvv??>>>>>>>>>>>vvvvvvvvvvvvv?|.            .`||||~~~~~~~-^>`<br></br>
.>>>>>}wwwv?vvvvvvv?>>>>>>?}?+>vv}}rrwwwzv|.             .`|||||~~~~~~~~->`<br></br>
.>>>>?}wwrv?vvvvvvvv>>>>>?}rr++?zxxxxuuuux^             .-|||~~~|||||~~|^?-<br></br>
.>>>>?rwwr??vvvvvvvv?>>>?v?>??+^vuuuuuuuuyz`           .-|||~~~~||||||~^>}-<br></br>
.>>>>?rwwr??vvvvvvvvv>?vv???>v>+>uyyyyyyuuyv         .-~~~||||||||||||~^+v-<br></br>
.>>??vrwwr?vvvvvvvvvvvvv??????v++vyuuuuuxxux^     .`-~||~~~~~||||||||||+>?-<br></br>
.>??vvwww}?vvvvvvvvv}}???????>v?+^?xxxxxxxxur.  .`~||~~~~~~~~~||||||||~|>?`<br></br>
.?vvv}www}?vvvvvvvv}v????????>??>>^vuxxxxxxux>`-~||~~~~~||~~~~~|||||||~`>?`<br></br>
`vvvv}www}?vvvvvvv}v???????????>>>++wuxuuxxzwv|||||~~~~~|||~~~~~||||||-.>?`<br></br>
`vvvv}wwwv?vvvvvv}v?????????>?>+>>>^?xxzzr}???+~|||~~~~~||||~~~~~~||||-.+?`<br></br>
`vvvv}wwwv?vvvv}v?????????????>>>>>>^?vv??>>>+|~|~~~~~~~|~~~~~~~~~~~~|-.^?`<br></br>
`vvvvrwww??vvvvv???????????>?>>>>>>>>^>>>>?>--||||~~~~~~~~~~^+?v}}v?+++|^>`<br></br>
`v???vrww??vvv????????????>??>>>>>>>>++??>|.  -|||~~~~|~^?vwxuyyuyyuzwwrvv-<br></br>
`v>>>>vrw?>}v???????????????>+>>>>>>>>^>+`     `||~~~~~>xyyuyyyuuyyyyuxzrv-<br></br>
`v>>>>>?v?v??????????????>v?+>>>>>>>>>>~        .||~~~|ryyuuyyxxyyyyuyyurv`<br></br>
`?^+>??vrr?>????????????>?v>>>>>>>>>>>>?`-.      .~||~|}yyxuyuzyyyyxxyyywv`<br></br>
.v}wxuuuuxv>>>?????????>?}>+>?>>>>>>>>?}?>?^.     .~|~~>wzwxxwxyuuzzyyyxv?`<br></br>
.?}xuuuyuuzvv??????>>>>?w?+>>?>>>>>>>>?}v?>?>.      ~||~^>>?}rwzzwruuxwv??`<br></br>
.>?xuzrvv?>+>v}}rrrrrrwz?>>>>>?>>>>>>>>vvv>>>>.      -|~~~~||?rrrrwwwv????`<br></br>
.?}?++>???>++>vv?????v??>>>>>>?>>>>>>>>?vv>+??+       `||||~|>?vv}}}?>????`<br></br>
`}>+??rvvv?+++>vvvvvv?>>>>>>>>>?>>>>>>>>v?>^>??^       `|||~~|~|+?v?+>????`<br></br>
.+>?v?v}vvv>++>>?vvv?>>>>>>>>>>?>>>>>>>>?>>>^???~       .||~~+?????>>?????`<br></br>
.+>vvv?}}vv>++>>>?v?>>>>>>>>>>>>?>>>>>>>>>>>++?>?`       .~^|+>>>>>+>?????`<br></br>
.+?vvv??}vv?+++>>>>>>>>>>>>>>>>>??>>>>>>>>>>>^>?>>.       .-~``--~^^>?????`<br></br>
.>?vvvv??}vv>+^+^^>>>>>>>>>>>>>>>?>>>>>>>>>>>>^>>?+         `|||~~-->>????`<br></br>
.>vvvvv??v}v?+^++++>>>>>>>?v???????>>>>>>>>>>>+^>>?~         `||||^??>????`<br></br>
.?vvv?vv??v}v++>>++>>>>>>>>>????????>>>>>>>>>>>^>>>>.         .|~|???>>???`<br></br>
`vvv???????vv>+++++>>>>>>>>>>>>>>>>+>>>>>>>>>>>^>>>>+.         .-????>+>??`<br></br>
`vvvvvvvv??>+++++>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>+>>>>?|         .>?????>>>>.<br></br>
`vvvvvv??>+++>>>>>>^~--~~~~|||+++++^+++++++++>++>>>>>>`        ^????>>>++>.<br></br>


            </div>,

            <div>

.???>>?vvv>^+^||^^^^^^^^^^^^>?????????????????~            .----------|+++.<br></br>
.??>>+rwww??vv?>++>>>>>>>>>>?vvvvvvvvvvvvvvvv>.           .~|||||||~~~~+??`<br></br>
.+>>>>rwww>?vvvvv?>>>>>>>>>>>?vvvvvvvvvvvvv?`            .~|||||||~~~~~-^>`<br></br>
.>>>>>rwwr+vvvvvvvv>>>>>+?wr++?}}rrwwwzxz?~             .~|~~~||~~~~~~~~->`<br></br>
.>>>>?www}+vvvvvvvv?>>>+?wrvv+^vuuuuuuuuux|            `~|||~~~~||||||~~-}|<br></br>
.>>>+?www}+vvvvvvvvv?>+?r??>v?+^}uuuuuuuuyz`         .-~||||~~~~|||||||||}~<br></br>
.>>>?vwwwv>vvvvvvvvv?+v}????>v++?yyyyyuuuuuv       .-||~~~|||||||||||||~^v-<br></br>
.>>?v}wwwv>vvvvvvvvv?vv?????>??++}uuuuxxxxux^   .`~|||||~~~~~|||||||||||^?`<br></br>
.>?vv}www?>vvvvvvvvv}v???????>v>+|vuxxxxxxxu}..-~||~~~~~~~~~~~||||||||||^?-<br></br>
.?vvvrwww??vvvvvvvv}?????????>v>>+^ruxxxxxuuz+~||||~~~~~||~~~~~~||||||||^?-<br></br>
`vvvvrwww??vvvvv?v}????????????+>>++zuuuuxw}?>|||||~~~~~|||~~~~~|||||||~|?`<br></br>
`vvvvrwww??vvvv?}}??????????>v>>>>>^?xzrv?>>>>+~|||~~~~~||||~~~~~~~||||||?`<br></br>
`vvvvwwwz??vvv?}v>?????????>??^>>>>>^??>>>>?>+~~~~~~~~~~|~~~~~~|^^|~~~~~|>.<br></br>
`vvvvwwwz??v?v}????????????>v>>>>>>>>^?????+``||~~~~~~~~~~~|>v}zxxzr???>^>.<br></br>
`????vrwz???vv????????????>v?+>>>>>>>++??>~   `|||~~~~|~^}wzyyyyuyyyuwwzrv-<br></br>
`?+>>>?}z>?vv????????????>?}+>>>>>>>>>^>^.     .||~~~~~+xyuxyyyzuyyyyuxxr}-<br></br>
`>>>>>>?v?v?>????????????>r>^>>>>>>>>>>-        .||~~|~vyyzxyyuzyyyyxuyyuv`<br></br>
.^+>>?vrzw?>????????????>}v+?>>>>>>>>>>?-^-      .~|~|~?yywuyuruyyyxzyyyuv`<br></br>
.?}zxuuuux?>>>?????????>vr+>??>>>>>>>>?}?>?+.      ~^|~~vzrxxwwyuuzryyyuv?`<br></br>
.?wuuuyyuuwvvv?????>>>>vz>>>>?>>>>>>>>?vv?>?>`      -^|~~^|>}}rwzw}zuxwv??`<br></br>
.>vuxzrv?>++>?v}rrrrwwwz?+>>>>?>>>>>>>>vvv>>>>.      `||~~~~~?r}}}rwwv????`<br></br>
.?v?+>???v>++?vvv???????>>>>>>?>>>>>>>>?vv++??+.      `|||||~^??v}}}?+????`<br></br>
`v>>?vrvvv?++>>vvvvvv?>>>>>>>>>?>>>>>>>>v?+^>??^       .|||~~~~|^?v?+>????`<br></br>
.+>?v?v}vvv+++>>?vvv?>>>>>>>>>>?>>>>>>>>++>>^>>?|       .~|~|>?????>>>????`<br></br>
.+>vvv?}vvv>++>>>???>>>>>>>>>>>>?>>>>>>>>>>>++?>?`        ~|~^^+>>>+>>????`<br></br>
.+?vvv??rvv?++>>>>>>>>>>>>>>>>>>>?>>>>>>>>>>>^>?>>.        `|~~~~~^^+>????`<br></br>
.>vvvvv??}vv>^++^^>>>>>>>>>>>>>>>?>>>>>>>>>>>>^>>>+.        `|||||~~>>????`<br></br>
.?vvvvv??v}v?++>+++>>>>>>>?v????????>>>>>>>>>>+^>>?^         .|||||??>>???`<br></br>
.?vv??????v}?++>>++>>>>>>>>>>>>>>>?>>>>>>>>>>>>^>>>?|         .||~???>>>??`<br></br>
`vvv???????vv>++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^>>>>?-          ~?????>>>?`<br></br>
`vvvvvvvv??+++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>`         >?????>>>>.<br></br>
`vvvvvv??>+++>>>>>>|-```----~~^>++++++++++++++++>>>>>?+.       ^????>>>++>.<br></br>


            </div>,

            <div>

.++>>+^???+^+^|`````-|||||`.....`-----~~~~~~~>~.`...........~~~~~~~~~~|||^.<br></br>
`>++>+vvv}>?v??^`.``-+>+|`...``-~~~~~|^^^^^^|~`.`.........`|^^^^^^^^^^^+>>.<br></br>
.+||^>}vvv+?vvv?^|-``+^`......-~~--~^^^^^||~`....`.......`|^^^^^^^^^^^^^++.<br></br>
.+^|~>}}vv+?vvv?++|-`-....``..-|||+>>>>??^-......`......~^^^^^^^^^^^^^^^^+`<br></br>
.+++|+}}}v|?vvvv>||-`....```~.`^>>????????|...........`~^^^^^^^^^^^^^^^^^?`<br></br>
.+++^>v}}v|?vvvv?|||-...```~+~.`+??????????-........`-|^^^^^^^^^^^^^^^^^^>.<br></br>
.++^>?vv}v+??vvv?^^^^..``-^>++``|vv????????>`.....`~^+^^^^^^^^^^^^^^^^^^+>.<br></br>
.++>?vvvv????vv?~~||~-``-^+>++|`-???????????|..`-~^^+^^^^^^^^^^^^^^^^^^^^+.<br></br>
.>???vvvv??v??^``~~~||``~+>>>++--~>?????????>--^^+^^^^^^^^^^^^^^^^^^^^^^|+`<br></br>
.?v??v}vv>>vv+```-|^^|`~+>>>>++--~|>?????????+^^^^^^^^^^^^^^^^^^^^^^^^^^~^`<br></br>
`vv??v}v}?+vvv|`.`^^^|-+>>>>>+|`---^???????>>+^^^^^^^^^^^^^^^^^||~~^^^^^~~.<br></br>
`vv??}vv?|`+v}?-``-~~~+>>>>>++-.----+??>>>+++++^^^^^^^^^^^^^|-`....`^^^^~`.<br></br>
`??>++^|~`.->v??|-``-^>>>>>>+|.``---~+++++++>+^^^^^^^^^^|~-`..  ....~^^^-`.<br></br>
.-```||||````>??+^~-^>>>>>>++`.`-----|++++>+~|+^^^^^^|-`.....`-~>??v>>?+~`.<br></br>
.....`~||`..`^>??>++>>>>>>++|..`------^+++|`.`~+^^|-`.. .`-~>?}rwwwz}???>+.<br></br>
.`....`~^`-|++>>??>>>>>>>+++`..`------~+^`.....`-`..   .+??vwwwrrwwww}???>.<br></br>
.......`^^+>>>>>??>>>>>>>+>-.`.``-----``.......  .  ..`>wzrrrr}vwwwwrrv??|.<br></br>
.````-~^+++>>>>>>??>>>>>+>|.``.`------`|-^~....   .-`-->ww}rr}vwwwwr}wr??|.<br></br>
.|+>>>?>>>+++>>>>>??>>>+++`.`-.``-----~+|+>^-`.    |~--~?vvv}vvwwwr}www?|~.<br></br>
.+>>>>???>+++++++>>?>>++>-```-```-----^|||+>+-.    `-~---||+vvv}vv?v}}v?^|.<br></br>
.++>>>+|~-```||^++>?vv?>~```.`-`-------^||^+++`    ..-~-~~~~~>v?v?vvvv?v?^.<br></br>
.++|--``-~`..`~|~~|^??>~-```..-`-------|+|~^>+|    ```~^|~-``~+??vvv???vv?.<br></br>
.^``-^+~||-...`~|||^>>>|````.``---------+~--^+^.   .``.`~----``~>?v???vvvv`<br></br>
...-~~+^||~`.```-|||`~>+~```.``-|------`````-^^`.   ....`~~-|+??vvv???vvvv`<br></br>
 ``||~~+|||``.``.``...|>>-``````|-``````.``..~|..  .`.....~~^>>??>????vvvv-<br></br>
.`-^|~~~^|^~...........^>|.....`--``.........`|`.. .`......-~--~~|+>??vvvv`<br></br>
                  `~|||~~~~-`...........`++`.....```...........``....`.......`~-~|||+????vv`<br></br>
 `||~~`..```........`...-++^`...--```...............`.......`~+|~-~??>??vv`<br></br>
                  `~``....`~~-.`````....``-++|...````................|`...```...--~?vv????v`<br></br>
 ```.````~|>+------``..```|^-`..````.`.........`....+>``........~?vvv?????`<br></br>
                  `--~~^^+|^~-------`..`````||``.``.`.`.........-....`+`........`?vvvv?????`<br></br>
.~|^^^+^|~---------`.......`-``..`.............-`....^>........+v???????>>.<br></br>


            </div>,

            <div>

.^^+++^^++++^^+`....-+++>+.     `----~^^^^^^||+^v>++++++++++++++^^|~^^^^^^.<br></br>
.>+>??>++>>>>>?|    `???^.     `~||~|+>>>>>>>+?>}v?>>>>>>>>>>++++++>>>>>>>`<br></br>
.>++>??>++>>>>?+.....>>`      `|~~~~+>++++++>++>>w?>>>>+++++++>>>>++++++++.<br></br>
.>>++>??>+>>>>>>` ...-.      `||~~~^>++++++++>++^??++++++>>>>++++++++++++>.<br></br>
.>>>++???++>>>>?|   .       `||||~^>+++++++++++>+++++++++++++++++++++++++>.<br></br>
.>>>>++???++>>>?^....      `||||~^>++++++++++++++++++++++++++++++++++++++>.<br></br>
.>>??>^>??>++>>?+...`     .||||~|++++++++++++++++++++++++++++++++++++++++>.<br></br>
.>>>>>++>??>+>>^. ...    .~|~~~~+>+++++++++++++++++++++++++++++++++++++++>.<br></br>
.>>>>>>^+>??>+-  .....  .~||~~~|+++++++++++++++++++++++++++++++++++++++++>.<br></br>
.>>>>>>+++>??|    ....  -|||~~~|++++++++++++++++++++++++++++++>>>>>>+++++>.<br></br>
.>>>>>>>>++??>-   ...  -|||||~~|>+++++++++++++++++++++++++>>>>+^|~~+>++++>.<br></br>
.>>>>>>+|-.|??>`      `||||||~~|+>>+++++++++++++++++++>>>>+^|-`.   `+>+++>.<br></br>
.>^|-`.    .^??+.    `|||||||~~|+++++++++++++++++++>>+++|-`.        ~++++>.<br></br>
 .          .+??^.  .||||||||~~|+++++++++++++++++>>++|`.        .-^>^^>++>.<br></br>
             ->??^~~~|||||||||~^+++++++++++++++>>+|`.       .-+??vvv?~+++>.<br></br>
        ..`-~|^???^|||||||||||~|>++++++++++++++^-.      .-^+????????v+|+++.<br></br>
   ..`-~~~|||||+??>^||||||||||~~++>++++++++++>^      `|>?vv?>?????????~^^|.<br></br>
 `~~||||~~~~~|||+??>||||||||||~~+++>+++++++++>^    ^?vvv???????????vvv?~||.<br></br>
.|||||||~~~~~|||^>??+|||||||||~~+++++++++++++>^    vvv?????????vvvvvvv?|~|.<br></br>
.~||||||~~~~~||||^>??+|||||||~~|+++++++++++++>^    ?v??????vvvvvv???????~~.<br></br>
.~|||||||~~~~~~~||^>?>^||||||~~~>++++++++++++>|    ?v???vvvv???????????v>-.<br></br>
.~|||||||||~~~~~~||^??>^^^||~|~|>+++++++++>>>>|    ?vvvvv???>>?vv????????+.<br></br>
.~||||||||||||||||||+??>^||||~~|>++++>>>+>++++|    >}v?????vv?>?v?????????`<br></br>
.~|||||||||||||||||||+??+||||~~~>>>>>+++^^||||~    `>???????????>?v???????`<br></br>
.~|||||||||||||~-``..`+?>^~~~||~+++^^|||||||||~    ^v????????????>????vvvv-<br></br>
.~||||||||~~-`.       `>?^. ..`~+||||||||~|~~||.   -}??????????????vv}}vvv`<br></br>
.~|||||~-..         .. ->>-    .+|~|||||||||~||.   `}?????????vvv}}vv?????`<br></br>
.||~-`.        .`-~~~--~^>+^`   +^~||||||||||||.    vv????vvv}vv?????????v`<br></br>
 `.       ..`~|^^+^|~-~||+>>>`  ^^~||||||||||||`    ?v?vvvvv????????vvv??v`<br></br>
      .`-~|^++++>>>+~-~||^>>^|- ~^~||||||||||||-    +wvv?????????????????v`<br></br>
  ..`~^^+++>>>>+++++~-~|||^++|~.-^|||||||||||||~    .+???????????????????v`<br></br>
 -|^^++>+++++++++++^~-~~||||+^|.`^~~~~~~~~~~~~~~.    +????????????????????`<br></br>


            </div>,

            <div>

                  .|^+++^^^^^^|-`.  ...^++++` .....````-||~~~|||+^?+++++^-```~||||||~~|||||~.<br></br>
.++>>>>+++++++|`    .`|>^. .....`----||^^^+++^?>vv?????+```-^^^^^++++++++^.<br></br>
.+++>>>>+++++++|.    ``.     .`---``~|^^++^^+^^>>r?>>+|~~--~|++++++++++++^.<br></br>
.>+++>>>>+++++++`.....      .`--```|+++++++++^^^|?>|~~|++^|~~^+++++++++++^.<br></br>
.+++++>>>>++++^^-..         .```--|^^+++^^+++++++^||^+++^+^~~^+++++++++++^.<br></br>
.++++^+>>>++^^^^~....      ..`---~^^^+++^^^^++^|||^^^++^^^+^~|^++++++++++^.<br></br>
.+++++^>>>>+^^^+|...`.    .``..`-^+^^^^++^^^^||^++^^^^++++++^^^+++++++>++^.<br></br>
.+++++^+>>>>^^+|......   .-`....~^^+^^++^^|||^+++++^^^+++++++++++++++>>+^|.<br></br>
.+++++^^+>>>+^`  .....  .~~~-```~~|^^+^^|^^+++++++++^+^++++++++++++++>>+^^.<br></br>
.+++^^^^^+>>>|.  .....  ~|||~~--~~~~|||^++++^^++++++++++++++++++>>>>+>>+>+.<br></br>
.++++++^^++>>>-.  .... -|||||~~~^^|~~~|^++++++++++++++++++++>>++^~|+>>>>>+.<br></br>
.^^^++^|~`.|>>+`.     -||||||~~|++^^||~~~|^+++++++++++++++++^~`..  `+>>++>`<br></br>
.^|~`..    `^>>+`    `|||||||~~|+^+++^^^|~~|^+++++++++++^~`...      |>++>>`<br></br>
 .          `^>>^.  `|||||||||~|++^+++++^^||~|^+++++^|-...      .`~^~|^^++`<br></br>
            .->>>^~~~|||||||||~^+++^++++++++^^^++^|-..     ..`|^+++^~`|^^^.<br></br>
       ..``-~|^>>>^~~---~~~~~~~|+++++++++++++++|-..     .`~|^+^+^|~~|-~^^^.<br></br>
   .``-~~||||||+>>>^||~~---`--`-|^^^^^^^++++++^.    ..-|+>>+^^|~~~~~~~`~|~.<br></br>
.~||||||~~~~~|||+>>>^^||||~~~~-~^^^^^||^++++++^.   |++++++++^~~~~~~||^|~~~.<br></br>
.+^|||||~~~~~~|||+>?>^||||||||~~^+++++++++++++^.  .?+++++++|~||^^^++^^^~~~.<br></br>
.^+^||||~~~~~~~||^>>>+||||||||~|+++++++++++++>^.  .>+++>^|~+??>>+||~~||^-~.<br></br>
.|^+^||||~~~~~||||^>>>^|||||||~|++++++++^^^+++^.   >++^|^+>>>>>+^||^^^^^|-.<br></br>
.|^++^||||||||||~||^>>>^^^||||~|++++++++^||^++^.   >>++>+^||~|>?>+^^^^^^+|.<br></br>
.|^^++||||||||||||||+>>+^||||~~|++++++++^|~-~~`    ^>+||||^^^||^>+^^^^^^++.<br></br>
.|^^^+^||||||||||||||+>>+||||~~~+++++++^|~-```.    `^||^^^^^^^^~~^^^^^^^++.<br></br>
.|^^^^+^||~|||~~-``..`+>>^~~~||~+^^^^|||~~~~~~-    ~?^^^^^^^^^^^|~|^^^^+>>`<br></br>
.|^^^^++^||~-`..      `+>^. .``~+|~|||~~~~~~|||.   `?||^^^^^^^^^^||+>??>>+.<br></br>
.|^^^^^^|`..       ....->>-.   .+|~~|||~~~~|~||.   .>||||||||^^+>??>+^||~~.<br></br>
.^^|~-...     ..`-~~~--~^>+^`  .^|~|||||~~~~~||`    >|~~~~|^>>>>+|||||^^||.<br></br>
.-`.     .``-~|^^+^|~-~||+>>+-. ^|~|||||~~|||||`    +^|^+>>+^~~~~|^^^^||||.<br></br>
     .``~^++++++++++~-~||^+>^|~.~^~|||||||||||^~    |?>>^|~~~||^^^^^||||~|.<br></br>
  .`~|^+++>>>++++++^~-~~|||++^|.-^~~|||||||^^^^|.   .||||^++>>?????>+|||||.<br></br>
.~^^+++>++>>>>+++++^~-~~~||^>+^``^~~~~~~~~||||~-.    |++>>>?????????>^||||.<br></br>


            </div>,

            <div>

.^^^^^^^^^^^~        ~>>>>>>>>>>>>>>???+~.-+>>??>>>>>>>.   .------------`. <br></br>
.>>>>>>>>>>>>^`       -?vv?vvvvvvvvv?+~~+?v???vv???vvvv+    -|||||||||~~-. <br></br>
.+>++++++++++>>|.      .^?v????vv?+~~^?vv??????????vv>~`~   .~||||~~~~~~~. <br></br>
.+>++++++++++++^~`       `>vvv?>~~^>?????????????v?>~`^??+   -||||~~~~~~~. <br></br>
.+>++++++++++^||~|~`       `+|~+?vvv???????????v?^--+????v^  .~|~~~~~~~~~. <br></br>
.+>++++++>++^|||||||~`.      `?vvvv??????>?vv?>|--^???????v~  `~~~~~~|?>-. <br></br>
.+>+++++++^|||~~~~~|||~`.     .|?vv????????>+--^???>?????v?^```~~~~|^+r>`  <br></br>
.+>>>++^^|||~~|~~~||||||~-.     .^?vvvvv?>|`|>?v??v?>?vv?>~~||~~|^^++>}~   <br></br>
.+>+^^|||>^|~~||||||||||||~`.     .^??>|~^>?vv?????vv??+~~~~~~|^^++>+??-`. <br></br>
.>++|||||+^|~~||||||||||||||~-`.    .`->v}}vv?????vv?+~~||~||^+++++++?>>??.<br></br>
.^++++^||||||||||||||||||||||||~-.    .~>?vvvv?vvv?^~~|||||^++>++++>+?}w?|.<br></br>
.~~~|^^+^||||||~||||||||||||||||||~`.    ->???v?>|~~||||^^++++++>>++|?u?>z|<br></br>
.~~~~~--~|^^^||~~~~|||||||||||||||||~-`.   `^+^~~|||~||^++++++>>+^|^++^>xy|<br></br>
.||~~~~~~--`-~||^^||||~~|||||||||||||||~-`.   .~|~~||^++++++>++^|^++^. .->`<br></br>
.||~~~~~~|~~~-```-~~|||||||||||||||||~~~||~~-`.~|||^++++++>>+||^++|`.    . <br></br>
.+||~~~~~~~||||^~-``...``--~~~~||||||~~~~~~~~~~~|^++++++>++|~|++~.      .. <br></br>
.++||~~~~~~|~||+||^^||-``............````-~~~||^+>+++>>++||^+^-.        .. <br></br>
-v++^|~~~~~~~|||||^?>+|||||~~--```......-~~|~^++++++>+^||^++~.        .... <br></br>
`r?^^|~~~~~~~~~~||^>?>||||||||||||||||~~~|||^+++++++^||^++~.  ..     ..... <br></br>
.>r>^|~~~~~~~~~~~||^++|||||||||||||||||~-|||^+++>+^||^++~. `+|`.... ...... <br></br>
.+?r+^~|~~~~~~~~~~|||||~|||||||||||||||~..-||^++^||^+>|.  .`~>>~.......... <br></br>
.++v}+^~|~~||~~~~~~~~~~~~|||||||||||||||.  .-~~||^+>^`  .....`|>>-........ <br></br>
.++>}v+||||||~~~~~~~~~~~~~~|||||||||||||.    . .~+>|  .........-+`........ <br></br>
.+++>w?+|||||||||||~~~~~~~~|||||||||||||` ..    .`-^. .................... <br></br>
                  .++++?w?+^||||||||||||||~~~~~~~|||||||||``||~-`.   .`..................... <br></br>
.++>>+vw>+^|~||||||||||||~~~~~~~~~~|~~~|`-|||||~~`    .................... <br></br>
.>++>++vw>+^|||||||||||||~~~~~~~~~~|~~~~--~~~~~~|~.      ................. <br></br>
.>+++>+>}r>^|||||||||||||~~~~~~~~~~~~~~~~-~~~~~~||.          ............  <br></br>
.+++++++>r}+^|~||||||||||||||||~~~~||~~|~~~~||||||`            ........    <br></br>
.++++++++>w}+^~|||||||||~|||||||||~|||||~|^+++++++- ...      .........     <br></br>
.+>+++++++?zv+^||||||||||||^>>^|||~|||||~|^+++>^-...........``~^+>^`.      <br></br>
.+>++++++++vzv+|||||||||||^>??>^|~~~~~~~~|^^+^`. .......``~^>vrr}v?^`.     <br></br>


            </div>,

            <div>

.|||||||||||-        -^^^^+>>>>>>>+^^++|`.`|^>>>>++>>+|.   .-````````````. <br></br>
.++^^++++++++^.       `+????????>>>>+~--|+>>+>????????>-    -~~~~~-~~----. <br></br>
.^^^^^+++++++++~.      .|???>>>>>+|``~+>>>>+++>>?????+``-   .---------```. <br></br>
.++^^++++++++++^~`       `+>>>+|--~+>>++>>+++++>???^`-+>>|   `~--------`-. <br></br>
.++^^++++++++^|~~~~`.      `|-`^?>>>>+>++>>+++>>+|`->??>++~  .~~---------. <br></br>
.++++^+++++^|~~~||^^|`.      .+????+>>>>+|+>>>^-``^?????+++-  `------~?>`. <br></br>
.++++++++^|~~~||^||||~-`.     .-+>>>+>>+>>^^|``~+++^>??>+++|`.`--``~|+r+.  <br></br>
.>>>>++^^|||||^^^|~~~~~~~`.     .~>>>>+++^-`-^+++++>>>>+^~``--``--~^^>r~.  <br></br>
.>>++^^^^>+|^^^^|~~~~~~~~~~`.     .~+>^--^^+>>+++++>>+^~```````-~|^^^>?--` <br></br>
.>>>+^^^+>+^|^||~~~~~~~~~~~~~-..    ..`^?vv>++++++++^~```````~|^^^^^^?>+>+.<br></br>
.+>>>>+^^^^||~~~~~~~~~~~~~~~~~~~`.    .-^>>+++++>>+~``````-~|^^^^^^+^?v}?~.<br></br>
.+^^^^+++^^||~~~~~~~~~~~~~~~~~~~~~-`.    `|+++>>^-`````-~|^^++^++++^|?z?>r|<br></br>
.^^|||~~||^^^~~~~~~~~~~~~~~~------~~~`..   .~|~-`````--~^+++++++^^~|++|>wz~<br></br>
                  .|||||||~~-`-~~|||~~~~~~~~~----------~~--..   .-```-~|^^++++++^|||^+^. .-+`<br></br>
                  .^||||||||~~-`.```-~||||~~~~~---------~~~~--`..``--|^+++++++^|~|++|`     . <br></br>
                  .+^|||||~~~~~--~~-``...```------~~~~~------`````~|^^++++++^|~~^^~.      .. <br></br>
                  .++^|||~~~~~--~^~~|^|~-``................`````~|^+++++++^~~|+^-.        .. <br></br>
`?++^||~~~~~-----~^?>^|~~~---````........``--~^+++++++^~||++-.        .... <br></br>
`r?^^|~~~~~~-`----|>>+|~------~~~~~~~---``-~|^++++++^~~^++-. ....    ..... <br></br>
.>r>^~~~~~~--`----~~^^~~~------------````-~~^+++++^~~^++~. `^~`.... ...... <br></br>
                  .+?v+|~~~~~---------~~-------------``---..-~~|^+^||^++|.  .`~++~`......... <br></br>
.++?v^|~~~-----------------------``----~.  .--~~~^++^`  ......~>+`........ <br></br>
                  .+++v?^|~-----------------------``---~~~.    . .~^+|  .........`^`........ <br></br>
.+++>}?^~~~~~----------------------~~~~~. ..     .-|. .................... <br></br>
.^+++>v>^|~~~~~~~~---------------~~~~~~~``~~-``.   .`..................... <br></br>
.++++^>v>^|~~~~~~~~-------------~~~~~~~~``~~~~~~-.    .............``````` <br></br>
                  .+^^+++?v+^|~~~~~~~~~-----------~~~~~--~``-~~~~~~-.      .......``--`````. <br></br>
                  .++^^+++vv+^~~~~~~~~~~----------~~~~~~-~-----~~~~~.          .`------`.... <br></br>
                  .++^^++++vv+|~~~~~~~~~----------~~~~~~~~~-~-~~~~~~`         ...````--`.... <br></br>
.++++^+++>v?^|~~~~~~~~~~--~~~~~~~~~~~~~~~~|^^^^^+^` ...    ...````````.... <br></br>
.+++++^+++>}?^|~~~~~~~---~~^>>^|~~~~~~~~~~^^+++^-...........`~^>???^`..... <br></br>
.+++++^^+++?v?^|~~~~~---~~^>>>>|~~~-~~---~^|^^`. .........`~>vrr}vv>-..... <br></br>


            </div>,

            <div>

         .................`^++++^~......... `+++>~.^>`                     <br></br>
       ................`~+>>?>>|`............`+^-|>??+                     <br></br>
      ...............-|>>>?>|`.................->???^~`                    <br></br>
     ..............~^>>>>>^-.................. .>?+-|>>.                   <br></br>
      ..........`|+>>>>>|`...................   .~|>>+|.                   <br></br>
 ...   .......`~+>>>>+~`....................     .?>^-^|            ...... <br></br>
.???>^~`.....~>?>>>+-....................         `~^?>`            .+||+^.<br></br>
.????????>-`^>>>>^-.....................           ^>|`              ^v?v}-<br></br>
.?vv}}}}vv?>>>>>|`....................             .`.               .?v?v-<br></br>
`uyyyyyyyuzv+>>~....................                                  `>>|.<br></br>
-uyyyyxw}}xv>`....................                                   ...`. <br></br>
                  -uuwvv????v??...................                                   ....... <br></br>
`}??????????|.................                                ............ <br></br>
                  `??????????~`. ............                               ...............|.<br></br>
                  `????????+~``    ........                              ..................?-<br></br>
`???????+`.`.     ......                            .......``............>|<br></br>
                  `??????^```..                                     ..........`-...........``<br></br>
                  `?????|`````.                                   .............-|`.......... <br></br>
`???>-`````.                                 .................~+`......... <br></br>
                  `??|```````                                ....................^+......... <br></br>
                  `?|```````.                               .....................`+`........ <br></br>
                  .~```````.                             .........................-|........ <br></br>
                  ```````.                             ...........................-........ <br></br>
                  `````.                             ...................................... <br></br>
                  ````.    .......                 ...............................   ....   <br></br>
 ``.    ...........              ..............................  .|v??v}rr~<br></br>
 ..    .............             ............................  `?zuux?>??v~<br></br>
     .................              .......................  `?xuuxxuw>++>`<br></br>
   ...................           .... ...................  .?xuxxxxxxuv>++`<br></br>
  ....................           ........................  ?yuxxxxxxxuz?>+.<br></br>
 .....................          .``......................  |vrzxuuxxux}>>>`<br></br>
 ....................           `~-``....................  .++>?vwzzwv>>>>`<br></br>


            </div>,

            <div>

                  .................`^^^^^^~......... `^^^+-.|^`                     <br></br>
       ...````````````.`~+>>>>+~`...````.....`^|-~>??+        ....         <br></br>
      ..```````````.`-|>>>>>~`.......```.......-+>>?^~`      ....          <br></br>
     ..````````````~^>>>>>|`..........``...... .+>^-~>>.                   <br></br>
     ..````````.`|+>>>>>~`............``.....   .-~>>+|.                   <br></br>
 `..   ..````.`|+>>>>+~`..............``....     .>>|-^^  ..        .`.``. <br></br>
.>?>+^~`....`~+>>>>^-....................         `-^>>`            .>^+>>.<br></br>
.>??????>+-`^>>>>+-......................          |>~.              +v?v}-<br></br>
.?vv}}}vv??>>>>>|`....................             .`.               .?v?v-<br></br>
`xuyyuyyyuz?+>+~....................                                  `+>|.<br></br>
                  -uuuuuzrvvz?+`...................                                    ..... <br></br>
-uxrv??>>>?>>...................                                   ....... <br></br>
`v?>>>>>>>>+~.................                            ................ <br></br>
.??>>>>>>>>-.  ............                              ................^.<br></br>
`??>>>>>>^`.`    ........                             ...................v-<br></br>
`??>>>??^`...     .....                             .......``........``..>|<br></br>
                  `??????^```..                                     ..........`-...........``<br></br>
                  `?????|.````                                    .............-|`.......... <br></br>
                  `???+-.````.                                 ..............``.~+`......... <br></br>
                  .??~``````.                                ................`...^+......... <br></br>
.>~.``````.                               ......................+`........ <br></br>
                  .-.``````.                             .........................`|........ <br></br>
                  ``````..                            ............................-........ <br></br>
                  `````.     ..                      ...................................... <br></br>
                  ```..    ..`.....                ...............................   ....   <br></br>
 `..    ...``````....            .............................   .|v?>vv}}-<br></br>
 ..    ....```````...             ...........................  `?wxxz>^>?v-<br></br>
     ......````.......              ......................   `?zxzzzzr>^^+`<br></br>
   .......``````.````.           ....  ..................  .>zxzzzzzzzv+^^`<br></br>
 .........```````````.           ........................  ?uxzzzzzzzzw>+^.<br></br>
 ......````````````...          .``......................  ~?}rzzzzzzzv+++`<br></br>
 .....```````..```.....         `~-``....................  .+^+>vrwwr?++++`<br></br>


            </div>,

            <div>

.>++++++++++++++++++++++^+^        .^++`.......  .^>>-  ..`~^++>>+^~..     <br></br>
.???????>>>>>>>>>>>>>>>>>>>.      . |>>-........  ~??>-`-|+>?????+|`.      <br></br>
.?>>>>>>>>>>>>>>>>>>>>>>>?+       ...>>|........  `+vv?+>>?????>^-..       <br></br>
.?>>>>>>>>>>>>>>>>>>>>>>?+.       .. |>>`.......  ->?v}v??????+~`.         <br></br>
.?>>>>>>>>>>>>>>>>>>>>?>~          ...>>`.......  `?}vv}?????+~`.          <br></br>
.?>>>>>>>>>>>>>>>>>>??+`           .. ~?^........  +v}vvv???>|-``----````` <br></br>
.?>>>>>>>>>>>>>>>>>?>-             ... |>`......   -?v}vvv????>>???????>?>.<br></br>
.>>>>>>>>>>>>>>>>?>~.              ... .^|......   .?v}}}v??v}vvv}}vv}}vv}~<br></br>
.+++>>>>>>>>>>??+-.                 ... .|`.....    |?vvvv??vvvvvvvvvvvvv}-<br></br>
.>>>>>>?????>>+`                   ..... .`.....    ~+?????+|++++++++++++^.<br></br>
 ..```-----``.                    .......  .....    `||+++>+-............. <br></br>
                  ...........  ....    .`-|||^^-...        .. <br></br>
                             ...............  ..    ..`~||>+|`..  ....`... <br></br>
                           ...................      .~-~|^^||~`.....->?^|^.<br></br>
                  ...                    ..................`^+`..    .^|~~||~~~-`..`^?vvvvv~<br></br>
 .....               ...................`^>?>|`...   -~-~~||~~~-..`>vr}vvw|<br></br>
.-........            ................`~>?>|`....... .-`-~~~~~~~`.`~+?????`<br></br>
.~`............         ............`|>?>|`.......... ..`-~~~~~~-`..``----.<br></br>
 `.................       .........~>?>^`.............. .-~~~~~~~-`....... <br></br>
 ....................       .....-+?>^`................. .`~~~~~~~-`...... <br></br>
 .````................        .`^>>^-.......................-~~~~~-``````. <br></br>
 ``-~~~`...............        .|>~.................    .....`~~~~~-`````` <br></br>
                  `--~~~^^`.    . ..              . .................       .  .`~~~~-````` <br></br>
                  .--~~~|^|^-`|-.                      ............               ..``--```` <br></br>
.---------`?yu??>|-```....             ..........                   ...`-` <br></br>
 ---------+uxxzxzr}????+~````..           ..                           .~+.<br></br>
 --------`?yxxw}}?+^^++^^|||~~~~.                                        `.<br></br>
 `---------?}}v?>^+>>+^^^|~---~~|~`                                        <br></br>
 `-````---~^+++^+>>>>+^^|~~~~~~~~~~~`.                                     <br></br>
 ```````-^>>>++^>>>>>^^^~~|~~|||---~~``.                                   <br></br>
 ``````-??>>+^^??>>>^^^^~~|~~|||~~`-`.`~-.                                 <br></br>
 `````-vw>>^^|~v?>>>^^^^~~|~~||~-~~--``~~~-.                               <br></br>


            </div>,

            <div>

.>++++++++++++++++++++++^+^       ..^++`.......   ~>>~. ..-~^+>>>+^-..     <br></br>
.???????>>>>>>>>>>>>>>>>>>>.      . |>>-........  -?v?~`~^>??????+~..      <br></br>
.?>>>>>>>>>>>>>>>>>>>>>>>?+       ...>>|........  `+vv?>>>?????>^-.        <br></br>
.?>>>>>>>>>>>>>>>>>>>>>>?+.       .. |>>`.......  ->?v}v?????>+~`.         <br></br>
.?>>>>>>>>>>>>>>>>>>>>?>~          ...>>`........ `?}vv}v????+~`.........  <br></br>
.?>>>>>>>>>>>>>>>>>>??+`           .. ~?^........  +v}vv}???>|-----------` <br></br>
.?>>>>>>>>>>>>>>>>>?>-             ... |>`......   -?v}vvv????????????????`<br></br>
.>>>>>>>>>>>>>>>>?>~.              ... .^|......   .?v}}}v??v}}vv}}vv}}vv}~<br></br>
.+++>>>>>>>>>>??+-.                 ... .|`.....    |?vvvv?>?vvvvvvvvvvvvv-<br></br>
.>>>>>>?????>>+`                   ..... .`.....    ~+?????+|^^^^^^^^^^^^^.<br></br>
 ..```-----``.                    .......  .....    `||+++>+-............. <br></br>
                  ...........  ....    .`-|||^^~..         .. <br></br>
                             ...............  ..    ..`~||>+|-.    ...`... <br></br>
                           ...................      .~-~|^|||~`.....->?+^+.<br></br>
 ...                    ..................`^+-..    .^|~~||~~~~`..`|?}}vv}~<br></br>
 .....               ...................`^>?>|`...   -~-~~|~~~~-..`+vr}vvr|<br></br>
.-........            ................`~>?>|`....... .-`-~~~~~~~`..-^>>>>?`<br></br>
.~`............         ............`|>?>|`.......... ..`-~~~~~~-`..````--.<br></br>
 `.................       .........~>?>^`.............. .-~~~~~~~-`....... <br></br>
 ....................       .....-+?>^`................. .`~~~~~~~-``..... <br></br>
 .``--................        .`^>>^-.......................-~~~~~~-`````. <br></br>
 ``-~~~`...............        .|>~.................    .....`~~~~~-`````` <br></br>
                  `-~~~~^^`.    ....              . .................       .  .`~~~~--```` <br></br>
                  .--~~~|^|^-`|-.                      ............               ..``--```` <br></br>
.---------`?yu??>|-```....             ..........                   ...`-` <br></br>
 ---------^uxxzxz}}????+~````..           ..                           .~+.<br></br>
 --------`?yxxw}}?+^^++^^|||~~~~.                                        `.<br></br>
 `---------?}}v?>^+>>+^^^|~---~~|~`                                        <br></br>
 `-````---~^+++^+>>>>+^^|~~~~~~~~~~~`.                                     <br></br>
 ```````-^>>>++^>>>>>^^^~~|~~|||---~~``.                                   <br></br>
 ``````-??>>+^^??>>>^^^^~~|~~|||~~`-`.`~-.                                 <br></br>
 `````-vw>>^^|~v?>>>^^^^~~|~~||~-~---``~~~-.                               <br></br>


            </div>,

            <div>

 ................................ ...........`    ~>>|. .`-|++>>>+^-.      <br></br>
 ........................................... -.   `???^-~^>??????+~..      <br></br>
 .............................................~   .>vvv?>>?????>|-.        <br></br>
 ................................ ....  ..... ``.`~>v}}v?????>+~..         <br></br>
 ...............................          ...  ~-|+>v}}}v????+~`.........  <br></br>
 ...............................               .|++>v}}}}v??>^~-~~~~~~~~~- <br></br>
 ............................                   -+>^?v}}r}????????????????`<br></br>
  .........................                    `>~|^+?v}}}v?v}}vv}}vv}}vvr~<br></br>
    .......................                ..`|+>^.~^>?vvv?>?vv??vv??vv??v-<br></br>
     ......................            -|^?v}}vv>>. `|>?>>?>||||||||||||||.<br></br>
      ...................            .^?vrxuuuxrv?`  `|+^^>+~............. <br></br>
       ...............             .^>?rxwrzuxxxz^ .|`~||^^^~`.         .. <br></br>
        ..........                `+?}xwzuxrwxuz>  ~+^~|^|>+|-.   ....-... <br></br>
          ....  ..              .^?vwxxxxxxuz}?.  .|^+^~^^~||~`.....->?>++`<br></br>
           ..                  ->vwuxxxxxuux?`  .`-|^^^^~||-~~~`...|?}}vv}~<br></br>
..                          .-^>?wuxxxxxxxv-   `~|^^^^^^^~^|-~~-`.`^?}}vvr~<br></br>
`+.                        `^>?}xzwzwzuuv-   `~^++++++^^^|~^~-~~-..`|>>>>>`<br></br>
.~`                      .|>+vxxxxxzww}^   .`|^^+++++++^^^||^~`~~`...`````.<br></br>
 ...                    ->>?vrxxxxxuz>.  `-~||^^^^^^+++^^^^~|^-`--`....... <br></br>
 ....                 .^>?rxzwxxxux>`  .-~^+^^^^^^^^^^++^^^|-|^~`--``...`. <br></br>
 ```-.                -?+}uxxxxuu}~  .`-|^^+++++^^^++++++^^^|~|+-.`-`````. <br></br>
 ``-~~-`..            ^?>?rxuuur^  .```|^^^++++++++++++++++^^^~^^-.``````` <br></br>
 `-~~~~^^|~`.       .|>>++>?rw>.  .`-|^^^^^+++++++++++++++++^^|~^^`.`````` <br></br>
.--~~~|^||~-?v^-`.-^>+>>>??>|.  .````|^^^^^++++++++++++++++++^^~~^^``-```` <br></br>
.---------`?yy?vr}v?v>^++^-...``--```-~^^++++++++++++++++++++^^~`|+^`````` <br></br>
 ---------^uxxzxr}}v}v+``.  .`------```~^++++++++++++++++++++|-``~^^^````` <br></br>
 --------`?yxxw}}?+^^++|-`......`-----``-|^++++++++++++++++^~```~^^^^-.``` <br></br>
 `---------?}}v?>^+>>+^+^~`````...```--```-|^^^^^^++^^+++^~-``-|++^^^-```` <br></br>
 `-````---~^+++^+>>>>+^^|~~~-``.````````````~^^^^^^^^^^^~-```~^^^^^~~|```` <br></br>
 ```````-^>>>++^>>>>>^^^~~|~~|~-``.``````````-|^^^^^^^|-```-~^^^^|~--^^``` <br></br>
 ``````-??>>+^^??>>>^^^^~~|~~|||~~`````````````-|^^^^~````-|^^^^||^>+++~`` <br></br>
 `````-vw>>^^|~v?>>>^^^^~~|~~||~-~--``````````````--`..``~^^^^||^+^^>++-`` <br></br>


            </div>,

            <div>

.??vv???????????????????>???v???^~|+>>  .`---~~-~~~~>^. .`-|++>>>+^-.      <br></br>
`rrrrrrrrrrrrrrrrrrrrrrr}vv}v?^++^^|^~  .~|||^^|||^|??^-~^>??????+~..      <br></br>
`}rrrrrrrrrrrrrrrrrrrrr}}v?+^^|~~|~..  ..~~~||^||||->v??>>?????>|-.        <br></br>
`}rrrrrrrrrrrrrrrrrrrrrv>+^^|^^|`.  ..``---~~||||~-->}vv?????>+~..         <br></br>
`}rrrrrrrrrrrrrrrrrrr}??+|^^^|~.  ..`~||~~~~-~||||``>r}}v????+~`.......... <br></br>
`}rrrrrrrrrrrrrrrrrv??^|^^^^-.   .--~||||||~~--~|^|`^v}}}v??>^~~~|||||||~~.<br></br>
`}rrrrrrrrrrrrrrrr?+^^||^^~`   .`~|~~~||||||||~-|^^|`?}}r}????????v???????`<br></br>
`}}}rr}}}}}rrrrr}v+|^^^^~`   .`~~||||||||||||||~~|^^~^}v}}v?v}}vv}}vv}}vvr~<br></br>
`r}}}r}}}}rrr}?v>^^^^^|`   .`~|||||||||||||||||^^||^|`?vvv????????v??????v`<br></br>
`rr}}}}}}r}???+|^^^^~`.  .`-~|||||||||||^^^^^^^^^^|||`^?>>?>|~~|~||~~~~~~~.<br></br>
`zw}}}}rv>>?^^^^^|-.   ..`~|||||||||||^^^^+++++^^^|~|~-^^^>+~............. <br></br>
.vzrv}}v}?|||^^|`.   .`-~~|~~||||~~|^^^++++++++++^~|^|`-||^^|`.         .. <br></br>
.-?zv>?>>?>|~~-.   .`~||||||||||~~|^^+++++++++++^~~||~.`~|>+|~..  ....~`.. <br></br>
.~->rv+^||^^-.   .`~~||||||||||||^^^^++++++++^|--~|~~-``-~|||~-.....->?>++`<br></br>
.|~-|vr+||-.   .`-||||||||||~~||^^^+++++++++^|~-`--^>^-``-~~~~~`...|?}}vv}~<br></br>
.`~~-->^`.   .`--~~~~||||~~~||^^^+++++++++^|~~~^+|`~~-`.`----~~~`.`|?}}vvr~<br></br>
   ...`.    .~||~~~~~||||~||^^^^++++^^^+^~~||~+}?``~~-...````~~~-`.`~+>>>>`<br></br>
 `..     ^v~`~|||~~~~|||||^^^^++++++^|--```-~?}?``-^>^-`..```-~~~-...`````.<br></br>
 ```.....`?r+`~|||~~~|||^^^^^++++++++^-..`|^>>|..-~^+^~`.``````-~-`....... <br></br>
 ..........^}?-`||~|~||^+++++++++++^|||~`^?v?. ..`-~~~``^>``````-~-```..`. <br></br>
 ...........`?v|`~|||~|^^++++++++^||||^|^>>>?>>`.``--``~>?+``````---`````` <br></br>
 .`...........^}?`-~~||^^^+++++^|-`->vvvv?vwzxuw>.`^^-~+??v~.`````---````` <br></br>
 `---``.`......`?v^`-|^^^^^^++^-..-~>>^>?}z}?}r??+|+??????v?-+-...`---```` <br></br>
.--~~~~^+|`.``...|vv|-|^^^^|--~~>>?+>?-^rwz?vv>??v??v???????v?~?v?~`--```` <br></br>
.------^^^~|}v+~-.-?r?~-~---.`|>zxwv?vvwzux^???????zxv?????????vvv^.`-```` <br></br>
.------~~--rux}}r?|`->>~`.....-^++^>+++>vrv-|~>>+>vvv+v>??????v??>`.`````` <br></br>
.---------~xuxxxz}?^-`.`.....`-`.``..`````>^`++??v?>->v`^`?>+?v}?~...````` <br></br>
 `---------vxxxwv>^+>>^^~~~```````````````...`v}vv?+^>???+v|^>?v}-``-||~`` <br></br>
 `-````----->???>+>>>>>+^^~~~---````````````.|v?>+>?vvv??>++++>>?>>^^~^+-` <br></br>
 ````````-|>>++++>>>>>>^^|~~~~||--`-`````````~+>?rwzzzzwrv?^^>??+>~~>>+>^` <br></br>
 ```````~+>>>>^++?>>>>+^|~|~~|||~|~-`..````````?rzxxxzzzzw}v+++~-^>++>+++- <br></br>
 ``````~>v?>>^^+>v>>>+^^~~|~~||~-~~-```````````.>rwzwwwwwzw}>||>++>+++>~-` <br></br>


            </div>,

            <div>

                    `+-.  .->^.   .`~|~`..        `>?+`..`~^+>>>>+^-.      <br></br>
    ....`--..```.....^?~..~?>`   .`|>?>^`.        .>vv>||+>?????>^-..      <br></br>
 ...`|^++>?>>>>>+++|-`>v>>?>`   ..-|+++|`.        .+vv}v?>>????+|`.        <br></br>
.+^^>vvv?vv}vvvvvvvv?~-vrr?`    ..-|+>+|`.     .`-|>v}}}?????>+~`.         <br></br>
`vvv}rrvvv}}vv}}vv}}?^^vrr?`    ..-^>?+|`.. ...-|^+>v}}}v????+~`.......... <br></br>
`v}rwwrv???????????>+>?v???+`   ..-|++^~`....`~^+>>>?v}}}v??>+^||^^^^^^^^|.<br></br>
.?v}}r}v?+|-----````+??+`->?+`  ..-|>>+|-``-~|^+>??+>v}}}}v??vv??vvv?vvv?v-<br></br>
.^?vvvvvv?+~`......|??^...-+>^-...-^>?>^|||||^^>>??++?v}}}v?v}}vvv}vvv}}v}~<br></br>
 `|>?vvvv??>|-....|>>|.....-+>+~``-|+++^^^^|||^>??>++>?vv???>?????????????`<br></br>
 .`~+???????>+|~~^>+~.  .  .-^+^|~|^>?>+^|^+^|^++++^^^+?>>?>|--~~~~~~~~-~-.<br></br>
  ..~+???????>>???>^`       .~++>>+>????vvvrw>||^^^^|~~^^^++|`............ <br></br>
  ...~+????????vvv?|`..    ..-+>???>?}zzwwwrrrv+||||-``~||^^|`.         .. <br></br>
 ....`|>??????vvv?>+^~-`....`-|>???vuuzzzzzzwwww?|||~-`~||+>|~`.  ....~-``.<br></br>
.^^||^?>????vvvv?>+++^^|~~~---~^>??xzzwwwwzzzwwww?^+>+|~|||||~-.....-+??>>`<br></br>
`vvvvv}v???vvvv?>++++++>+++^|~~~^+}zwwwwrrrwwwwwwv|>?>^|||||~~~-...-?}rvvv~<br></br>
`rr}vwwv????v??>++++>>>>>>>++^||||vzwwr}vvvv}rwww>~^^^|~~|||~~~~`..~>v}vvv~<br></br>
.???????>++>??>++>>>>>>>>>>>>++^||>}}v}}vvvv}}wwv-~|^^~--~|~~~~~-`.`-^++++`<br></br>
.-----~||~|^^++>>>>>|~~-`^+^+>++>^~>?v>?>???v}w?-~^>?+^~--~~~~~~~-`..`````.<br></br>
 ....`-~~~~~~|^+>>>|^>>>>>^^^>}zxz?|?vv???>>??|.-~^>>+^~--~~~~~~~~-`...... <br></br>
 ..``-~~~~~--~|^+>?++>>??>^^|vyuuuuxrrv?vvvv?..`-~|^^^|-^>-~~~~~~~-``````. <br></br>
 ``--~~~~~---~|^^+>>>>>?rw?>?vxuuuuyxvv>??v?v?>-`~~~~~~~??+-~~~~~~~-`````` <br></br>
 `--~~~~~~|+>>>>>?>>>?wxzzwzz}vzuuuxv}rr}?rzxxyz?``^^~~>??v^~~~~~~~~-````` <br></br>
.--~~~~~|?v?>>vwxxzrrzzwwwwrv?^>rzxz}?+>vrx}vrw?v>|+?????v}?~>~----~--```` <br></br>
.-~~~~~^>?>+>wuzxzwwwwwzxuw}>|>wzrrv}v-+wzu?v}>??v??vv?????vvv-v}v|----``` <br></br>
.-----~++^|^xyz?wzzzzuuuxrv>|^?uyuzrrxwzzyx^??v??vvxyr???????v?vvv^----``` <br></br>
.------||~-}yyr}zxz}}rwr}v+^|||>>>>???vvwwv-~~>>^>vvv+v>?????v}???-----``` <br></br>
 `--------~xyxuxxrv?+^+>+^^^|~|~~~~------`>|`>+?vv??->}`^`??>?}zv~```````` <br></br>
 `````----`vuuxw}?++>>++^^||~||~~||~|~~-|~----vwvvv>^>???+v^+>?}z-`--^^|`` <br></br>
 ``````----->v??>+>>>>>+^|~~~~~||~~||~|~~~~~-^vv?+>?v}vv??>>>+>???>+>~>>-` <br></br>
 ````````-|>>+^+++>>>>>^|~~|~~||~~|||-~|~~~~~^>>vwuyyyuuxwv>>>??>?^|>>>>+` <br></br>
 ```````-^>>>>+^^?>>>>+^~~|~~|||~||~`.`~|~|~~~~vxuyyyyyyyuxv>>+^^+?>>?>>>- <br></br>
 ``````-+r?>>^^^>v>>>+^|~~|~~~~~-~~-```-~~~~~~--?zxxxxxxxuxr?^^?>>>>++>|-` <br></br>


            </div>,

            <div>

                    `+~.  .~>|.  ..`~|~-..        `+?>-..-~^+>>>>+^-.      <br></br>
   .....-~~``````....^?|..^?>`  ..-|>?>^-.        .+vv?^^+>?????>^-..      <br></br>
 ...-^++>??>>>>>>>+^-`>v>>?+.   ..-|^++|`.       ..+v}}v?>?????+|`.        <br></br>
.>^+?v}vvv}}}v}vvv}v?~-vwr>.    .`-|+>+|`.     .`~|+v}}}??????+~`.         <br></br>
`vv}}wrvvv}vvv}vvv}v?^+vrr?`    ..-^>?+|`.. ...-|++>v}}}v????+~`.......... <br></br>
`v}rwwrv?>?>>>>>>>>>^>vv>??+`   ..-|^+^|`...``~^+>>+?v}}}v??>+^^^+++++++^^.<br></br>
.?v}}r}v?+~-````````+??|.`+?+`. ..-|+>vv>``-~|^+>??+>v}}}}v??vvv?vvvvvvvvv-<br></br>
.^?vvvvvv?+~`.... .^??~...-+>+~...^?v}r}v??+^|^>>??++?v}}}v??}}vvv}vvv}}v}~<br></br>
 `|>?vvv???>^-`..`|>>~.....-+>+~|vr}}rrrrrrr}?>>??>++>?v????>?????????????`<br></br>
 .`~>???????>+^~|+>+-.     .-^+vzwrrwwwwwwwwwwr?+++^^^+>>+?>|------------`.<br></br>
  ..~+???????>????>|.   .   .-?xzzwrrwwrrrrwwrrr?^^^|-~|^^++|`............ <br></br>
 ....~+????????vvv?|`..    ..`wwrrrr}rrrrwwwwwrr}^^|-``~||^+|-.     .   .. <br></br>
 ...``^>?????vvvv?>+^~-`....`-wwrr}vr}}}}rrrwwwww>||~~-~||+>^~`.  ....|~``.<br></br>
.++^^+??????vvvv?>++^^^^|~~---?}rrvr}vvvrvv}wwwww>^+>+|~|||||~~`....-+??>>`<br></br>
`vvvv}}v???vvvv?>+^+^^^+>++^|~~?v?>v???>r^?}rrrrv|^>?>^|~|||~~~-...->vr}vv~<br></br>
`rrv}wrv????v?>++|~>+^^^>>>++^||+>vvvvvvvv}vv}r}^`||^^~~~~||~~~~`..->v}vvv~<br></br>
.??????>+++>?>^++++>>++^^>>>>++^|?}}}}}}}}}v?}v|``??>^~--~~~~~~~-`.`-^++++.<br></br>
.-----~|~~|^^^^+>>>>>>+^|^+++>++^>vvvv?vv}v?+|```->?vv+~--~~~~~|^~`...````.<br></br>
 ....`-~~~~~~^>+^>>>>>?>?>+^^>vwzv????v??v>-`..`-`+????^`-~~~->vvr>`...... <br></br>
 ..``-~~~~~--|>>>>>>>>>>>????zyxzxw}vv?v?>|-`-|---|???????>---?v??|``````. <br></br>
 ``-~~~~~~-~^+>>>>>>>>>?vr?vxuuxxxxrz>+>vr}--->|^+^??>??????>~~???|-`````` <br></br>
 `-~~~~~~~^>>>>>>>>>>?ruyxzuuxxuuyz}v}rrvwzv>+????>??>???????>-vv>~--````` <br></br>
.--~~~~~|>?>>>?rzxzwwxxzzrrxxxzzuur}rv??vzru>>??v??????>?>??>v?}r}|---```` <br></br>
.-~~~~~|>>??+?xuuuuxzzwzwzw}vvrxuu}}v?`-wxrz?+>??vv>-v}~>~??~??}zz^----``` <br></br>
.------^>>>?>>?zwzzwzzzzxxzx??rzzzvvrz}xyxvv}>??????+>??????>???}z|----``` <br></br>
.------|+++>>+?wvwzwwxxxxxzv||>???>?v?}rzr>|-`+>>vvvvwz}????????v>``----`~.<br></br>
 `-------~|>+>wu}wwwvz}?>++|~~~~--~---~~-~^`->>v?v??>vw?>>?????v?~-^++~`-`.<br></br>
 `````-----~>zuuwxv}wv>^^^^|~~|~~||~~~-~-`^^++>?>?>>>>>>?>>^>>?????+~~^--~.<br></br>
 ``````------?xxwww}?>++>+^~~||~||~||~|~~-~+>+?v}}wwzzzzr}?+^^>>^|^|||+>~~.<br></br>
 ````````-~~^^+?vv?>++>>>^|~~|~||~~||-~|~|~-|vrrwxuyyyuuxzzv^^^>~^^+>>>>+|.<br></br>
 ```````-~|+>>>+^^^+>>>>+||~|~~||~||-``~|~|~~~+ruuyyyyyyyuxxv+++>>>>>>>>>|.<br></br>
 ```````-^?v>>>>+>++v?>+||~|~~~~~-~~````~~~~~~-~vxxxxxxxxuxzv^++>>>>++++|` <br></br>


            </div>,

            <div>

                    `+~.  .^>~.  ..-|^|-..        .+?>|.`-|^+>>>>+|-.      <br></br>
   ....`~|~````````..^?^.`+?^.  ..-|>?>^-.        .^?v?+^>>?????>^-..      <br></br>
 ..`|+>>>???????>>>+~`+v???^.   ..-|^++|`.       ..^v}}v?>?????+|`.        <br></br>
.>+>?}}vvv}}}v}vvv}v?~~vwr>.    .`-|+>+|`.     .`~^+v}}}v?????+~`.         <br></br>
`vv}rw}vvvvvvvvvvv}v?^>vr}?`    ..~?}?^|`. ....-|++>?}}}}v???+~`...`````.. <br></br>
`v}rww}?>>>>>>>>+>>+^?v?>>?>`.   `?zw}?>^`..`-~^+?>+?v}}}}v??>++++>>+>>>++.<br></br>
.?v}}}}v?+~`````...->??~.`+?+-`^vrwwwwzzwrv?^|^+>??+>?}}}}v??vvvvvvvvvvvvv-<br></br>
.^?vvvvvv?+~`... .`+?>~...`++?wzwwwzzxxzzzzww?^>>??++?vv}vv??v}vvv}vvv}}v}~<br></br>
 `|>?vv????>^-`..`^>+-.....`vyxzzzzwwwwwwwwwwwv>>>>+^+?v????+>??>???>?????`<br></br>
 .`~+???????>+^|^>>^-.     |uxzwwwwwwwwwwwwwwww?^++^^|+>>+>?^-```````````` <br></br>
  ..~+???????>??v?>|.   .  vxwwwwr}}v}}rrwwwwwwr^^^|~--|^^++|`............ <br></br>
 ....~+???????vvvv>|-..    rzwwr}vvvv}}r}}wwwwwr+^^|-``-||^+^-.      . ... <br></br>
 .```-^>?????vvvv?>+^~-`...>wwwvvv?v>}?^}vrwwwr}+^^||~-~||++^~-.  ....|^``.<br></br>
.++^^>??????vvv?>^^^^^^^|~~-?}}+v>?v>v?>}vv}}}}v||^>>>^~|||||~~`....-+??>>`<br></br>
`}}vvr}????vv?>+`^^^^^++>++||??vvvvv}vv}rv??vr}^-~^>?>^|~|||~~~-. .`>vr}vv~<br></br>
`rrv}wr?>???>+>-^+++^^^+>>>>+|?r}r?^^^?r}vv?v?~~>+^|^^|~-~|||~~+++|~+?}vvv-<br></br>
.??>???>+++>^|^^>>>>++^^+>>>>+?rr|```~^}}v?>|```??v?>^|``-~|||>xxzx>~+++^^.<br></br>
.````-~|~~||+|^+>>>>>?>>??>>+++?}>vrr}vvv?^-```.^???v}?~^>|~~~>uzrw`.`````.<br></br>
 ....`-~~~~~>>++>>>>>>>?v}rrv??>+>>v}v??>^|-```.~>???vv???v>>|->xz}|...... <br></br>
 .``-~~~~~~~+>>>>>>>>>>>+>>?zuuu}vv}?v?+||~-->>>^???????????v?|+xzv?.```.. <br></br>
 ``-~~~~~~~^>>>>>>>>>>>>vv?xuyyuuxz?`+?v?|~-`~>>??????????????vvzxx}`````` <br></br>
 `-~~~~~~~+>>>>>>>>>>>}uyxxuxwww}rwvv}}}yr-|+++>???????v>+>^v^?vrwzw~````` <br></br>
.--~~~~~|>>>++?rzzzrrxuxxruxzzxuuv}}rrrvwx>~++>>?????.-z?`>`??^vvvrz|-```` <br></br>
.-~~~~~-+>????vvxuuuxzzwwwuuuuuuxvv}v|~vzz}+?+>>?????-.?v????v??}vr?---``` <br></br>
.------~>>?vvv>^>wzwwwwwrzuuuuuuz}vww?>xur?+??v??????????????????v+`---~~|.<br></br>
.------~+>>>>>+^|vwwwzxxzxyyuuuuzv?vvr}wxv~``|?v}rrr??}rv???????>?-~^>>>^+`<br></br>
 `-------|^^^^^^>?rzxwzuzvvv?vvv?|-~~|||>^`~????>>>>>?vvv>>???>+?????+^^-~.<br></br>
 `````-------^}rzrwzzzzw?^|~`-~~~~~~~---`?>?}v??vrwwwwrr}v+|+>>???++---++^.<br></br>
 ``````-------?uywxxzuwv++|~~~|~||~||~~~~~??vv}wzzxxxxxxzzw?^^^+~--+^^>+>>.<br></br>
 ````````----~|>}zxux}?+++|~~|~~||~||~~~|~-^?zxzxuuyyyuuuxzz?+^~>|+>>>>>>?`<br></br>
 ``````````-^>>+^^+++++>>||~||~|||~||``-~||~-^vxyyyyyyyyyyuxw?+>>?>>>>>>>>-<br></br>
 `````````->v?>>>+^^^??>^||~~~-~~~-~-..`~~~~~~-^vxxxxxxxxxxxwv^>+>>>>>+>+|.<br></br>


            </div>,

            <div>

        ...         `+~.  `+>-   .`-|^|~`.        .^>>^``~|++>>>>+|-.      <br></br>
   ...``|^|------``..|?^.`>?|.  ..-|>?>^-.        .^?vv>+>>?????>^-..      <br></br>
.`.-^>?????????????>|`+v???~.   ..-|^++|`.      ..`^?}}v?>>????+~`.        <br></br>
.?>?v}}vv}r}}}}vvv}v?~~vwr>.    .`-|>>+|`.     .`~^+v}r}v?????+~`.         <br></br>
`v}rww}v?vvvvvvvvvvv>^>v}}?`    ..~v}?+|`. ...`~^++>?}}}}v???>|``````````` <br></br>
`v}rwr}?>>+++++++++^^?v?+>?>-.   `vzz}?>^`..`-|^+??+>v}}r}v??>>>+>>>>>>>>>.<br></br>
.?v}}}}v?+~``......~??>-.`+?>-`+vrwwwwzzwrv?^|^+>??++?}}}}}??v}vvv}vvv}}vv-<br></br>
.^?vvvvv??+~`..  .-+?>`...`++?zzzzzzzxxxzzzww?^>???++?vvvvv??v}vvv}vvv}}vv~<br></br>
 `|>?v?????>^-`..-+>^` ....`vyuzzzzwwwwwwwwwwwv>>>>+^+?v????+>>>>>>>>>>>>>.<br></br>
 .`~>???????>>^^+>>^`      ^yuzwwwwwwwwwwwwwwww?^++^^|^>++>?^-```````````` <br></br>
  .`~+??????????v?>~.   .  vxwwwr}}}v}}rrwwwwwwr^^^|~--|^^^+|`............ <br></br>
 ...`~+???????vvvv>|-`..   rzwwr}vv}v}}r}}wwwwwr+^^|-``-||^>^~.     .. ... <br></br>
 ````-+>?????vvvv?>+^|-`...>wwwvvv?v>}?^}vrwwrr}^|^||~--||^+^|-.  ....|+-`.<br></br>
.>+++??????vvvv?>^^^^^^^||~~?r}^v>?v>v?>}vv}}}}v||^>?>^~|||||~~`....-+?v??`<br></br>
`}}vvr}???vvv?>+`^^^^^+>>++||??vvvvv}vv}rv??vrv|-~^>?>^|~|||~~~-...`+vwrvv~<br></br>
`r}v}w}?>???>+>-^+++^^^>>>>>+|?r}r?^^^?r}vv?v?~->+||^||--~|||~~+++|~^?vvvv-<br></br>
.>>>>>>++^+>^~^^>>>>++^^+>>>>+?rr~```~^}}v?>|```??v?>^|``-~|||>xxzx>~^+^^^.<br></br>
 `````~|~~||+^^+>>>>>?>>??>>+++?}>vrr}vvv?^--``.^???v}?~^>|~~~>uxww`.````` <br></br>
 ...``-~~~~~>>++>>>>>>>?v}rwv??+^>>v}v??>^~-```.~>????v???v>>|->xz}|...... <br></br>
 .``-~~~~~~~+>>>>>>>>>>>+>>?zuuuvvv}?v?^||~-->>>^???????????v?|^xzv?.```.. <br></br>
 ``-~~~~~~~^>>>>>>>>>>>>vv?xuyyuuxz?.+?v?|--`~>>??????????????vvzxxr`````` <br></br>
 `-~~~~~~~+>>>>>>>>>>>}uyxxuxwww}wwvv}}}y}-~+++>???????v>+>^v^?vrwzz~````` <br></br>
.-~~~~~~|>>>++?rzzzrrxuxxruxzzxuuv}}rrrvwx>-++>>?????.-z?`>`??^vvvrx|--``` <br></br>
.-~~~~--+>????vvxuuuxzzwwwuuuuuuxvv}v|~vzz}+?+>>?????-.?v????v??}vr?---``` <br></br>
.------~>>?vvv>^>wzwwwwwrzuuuuuyz}vww?>xur?+??v??????????????????v+`---~~|.<br></br>
.------~+>>>>>+^~vwwwzxxzxyyyyuuzv?vvr}wxv~``|?v}rrr??}rv???????>?-~^>>>^+`<br></br>
 `-------|^^^^^|>?rzxwzuzvvvvvvvv|-~~|||>^`~????>>>>>??vv>>???>+?????+^^-~.<br></br>
 `````-------^}rzrwzzzzw?^|~`-~~~~~~~---`?>?}v??vrwzzzwr}v+|+>>???++---++^.<br></br>
 ``````-------?uywxzzuwv++|~~~|~||~||~~~-~??vv}wzzxxxxxxzzw?^^^+~--+^^>>>+.<br></br>
 ````````----~|>}zxux}?>++|~~|~~||~||~~~|~-^?zxzxuuyyyuuuxzz?+^~>|+>?>>>>?`<br></br>
 ``````````-^>>+^^+++++>>||~||~|||~||``-~||~-^vxyyyyyyyyyyuxw?+>>?>>>>>>>>-<br></br>
 `````````->v?>>>+^^^>?>^||~~~-~~~-~-..`~~~~~~-^vxxxxxxxxxxxwv^>+>>>+>+>+|.<br></br>


            </div>,

            <div>

        ........    `+|.  `>+`   .`-^+^~`.        .^>>+``~|^+>>>>+|`.      <br></br>
  ...`-~^+|~~~~~---..|?+`~??~.  ..-|>?>^-.        .|?vv?>>>?????>^-..      <br></br>
.``~>??????v???????>|`+vvv?-.   ..-|^+^|`.      ..`^?}}}?>>????+~`.        <br></br>
`?>?vr}vv}r}}}rvvvrv?~|}wr+.    .`-|>?+|`.     .-|^+v}rrv????>+~........   <br></br>
`v}rww}v?vvv?vvvvvvv>^?v}v>`    ..~v}?+|`. ...`~^+>>?}}}}v???>|-`````````` <br></br>
`v}rwrv?>++^^^^^^^^|+?v?|+?>-.   `vzz}?>^`..`-|^+??+>v}}r}v????>>>??>???>>.<br></br>
.?v}}}}v?+-`.......|??>`.`+?>-`+vrwwwwzzwrv?^|^>>??++?v}}}}??v}vvv}}vv}}vv-<br></br>
.^?vvvvv??+~`..  .~>?+`...`++?zzzzzzzxxxzzzww?^>???++>vvvvv??v}vvvvvvvvvvv-<br></br>
 `|>???????>^-`..~+>^` ....`vyuzzzzwwwwwwwwwwwv+>>>+^+??????++>>>>>>>>>>>+.<br></br>
 .`|>???????>>^+>>>^`      ^yuzwwwwwwwwwwwwwwww?^++^^||+++>?^-```````````. <br></br>
  .`~>?????????vv?>~.      }xwwww}}}v}}rrwwwwwwr^^^|~-`~^^^+^-.   ........ <br></br>
 ...`~>???????vvv?>|-`..   rzwwr}vv}v}}r}}wwwwwr+^^|-``-||^>^~`.    ...... <br></br>
 ````~+>?????vvv?>++^|~```.>wwwvvv?v>}?^}vrwwrr}^|^||~~~||^+^|~.  ....~>|-.<br></br>
.>>+>??????vvvv?>^^^^^+^^|~~?r}^v>?v>v?>}vv}}}}v||^>?>^||||||~~-....-+?v??`<br></br>
`}}v}r}???vvv?>+`^^^^^+>>>+||??vvvvv}vv}rv??vrv|-~^+>>^|~|||~~~~`..`^vrrvv~<br></br>
`r}v}w}?>???>+>-^+++^^^>>>>>+|?r}r?^^^?}}vv?v?-->+||^||--~|||~~+++|~|?vvvv-<br></br>
.>>>>>>+^^+>^|^^>>>>>+^^+>>>>+?rr~```~^}}v?>|```??v?>^|``-~|||>xxzx>~^^^^^.<br></br>
 `````~~~~||+^^+>>>>>?>>??>>+++?}>vrr}vvv?^--``.^???v}?~^>|~~~>uxww`.````` <br></br>
 ...`--~~~~~>>++>>>>>>>?v}rwv??+^>>vrv??>^~-```.~>???vv??vv>>|->xz}|...... <br></br>
 .``-~~~~~~~+>>>>>>>>>>>+>>?zuuxvvv}?v?^||~-->>>^???????????v?|^xzv?.```.. <br></br>
 ``-~~~~~~~^>>>>>>>>>>>>vv?xuuyuuxz?.+?v?|--`~>>??????????????vvzxxr`````` <br></br>
 `-~~~~~~~+>>>>>>>>>>>}uyuxuxwww}wwvv}}}y}-~+++>???????v>+>^v^?vrwzz~````` <br></br>
.-~~~~~~|>>>++?rzzzrrxuxzruxzzxuuv}}rrrvwx>-++>>?????.-z?`>`??^vvvrx|--``` <br></br>
.-~~~~--+>????vvxuuuxzzwwwuuuuuuxvv}v|~vzz}^?+>>?????-.?v????v??}vr?---``` <br></br>
.------~>>?vvv>^>zzwwwwwrxuuuuuyz}vww?>xur?^?????????????????????v+`---~~|.<br></br>
.------~+>>>>>+^~vwwwzzxzxyyyyuuzv?vvr}wxv~-`|?v}rrw??}rv???????>?-~^>?>^+`<br></br>
 `-------|^^^^^|>?rzxwxuzv?vvvvvv|-~~|||>^`~????>>>>>>?vv>>???>+?????+^^-~.<br></br>
 `````-------^}rzrwzzzzw?^|~`-~~~~~~~---`?>?}v??vrwzzzwr}v+|+>>???++--~++^.<br></br>
 ``````-------?uywxzzuzv++^~~~|~||~||~~~-~??vv}wzzxxxxxxzzw?^^^+~--+^^>>>+.<br></br>
 ````````----~|>}zxux}?+++|~~|~~||~||~~~|~-^?zxzxuuyyyuuuxzz?+^|>|+>?>>>>?.<br></br>
 ``````````-^>>+^^+++++>>||~||~|||~||``-~||~-^vxyyyyyyyyyyuxw?+>>?>>>>>>>>-<br></br>
 `````````->v?>>>+^^^??>^||~~~-~~~-~-..`~~~~~~-^vxxxxxxxxxxxwv^>+>>>+>+>+|.<br></br>


            </div>,

            <div>

       ...........  `+^. .~>^.   .`~^+^~`.        .|>>>-`~^++>>>>+|`.      <br></br>
  ..`-~|+>^|||||~~~`.|?>-|?>-   ..-|>>>^-.        .~?vv?>>>?????>^-..      <br></br>
.-`^??????vv??v?????^`+vvv?`    ..-|++^|`.      ..`^?}}}v?>????+~`.        <br></br>
`??v}r}vv}r}}}rvv}rv?~^}wr^.    .`-^>?+|--``. ..-|++?}rr}?????+~`......... <br></br>
`vrwwrv??vv??vvv?vv?++?vvv>-.   ..~?vv?+^^^++^`~^+>>?v}}}v???>|-`--------` <br></br>
`vrrwrv?>^^|||||||~~+??>~+?>~.  .|}zwwvv?++>????>??>>v}}r}v?????>?????????.<br></br>
.?v}}}}v?^-`.......^??+`.`^?>~.+rzzwwwwwwwrvvvvvvv?++?v}}}}??v}}vv}}vv}}vv-<br></br>
.^?vvvvv??+~`..  .~>>^....`+>?ruxxzzxxzzxzzww}vv}}}?^>vvvvv??vvvvvvvvvvvvv-<br></br>
 `|????????>^~`.`|++|. ..  `?zuxzzwwwwwwwwwwwwrv}}vv>^>?????>^+++++>++++++.<br></br>
 .`|>???????>>++>>>|.      `vuzwwwwwwwwwwwwwwww}vvvvv||++^+?+-............ <br></br>
  .`~>?????????vv?>-.      |vzwwwrrrr}}}rrrwwwwwvvvvv|`~|^^+^-.   ........ <br></br>
 ...`|>???????vvv?>|-`..  .+vzwwrrr}}}}}v}}rrrwwvvv??|`-||^>+~`.    ...... <br></br>
 ````|>??????????>++^|~```.|vww}r}}v?rv>vv}}}r}rvv???|~~||^+^|~`. ....~>^-.<br></br>
.>>>>??????>+^>++^^^^^+^^||~^v}?v?v}|vv?}vv}}}}rv??vv>+||||||~~-....-+?v??`<br></br>
`rvv}w}?>>>>|+>+~^^^^+>>>>+^|+?vvvvv^r??r??v>v}v??????^|~|||~~~~`..`^vrw}v~<br></br>
`rvv}rv?>+>+++>^^++^^^+>>>>>+|>vvvv?>>?}}??v+?>?v?^~|+|~-~||~~~^^|-`~>vv??-<br></br>
.>>+>>+^~~^>>+>+>>>>++^++>>>>+>vv>>++>?}}vv??^`^v?+>>^^-``~~~~^v?v?~`~||||.<br></br>
 ````-~|^^^^++>>>>>?>????>>++++???v}}}v}}vv?>```-+>?}?>~`~~~~~^v???`....`` <br></br>
 ...`--~+++^+>>>>>>>>>??}r}vvvvv>??vrrv?vv?>|```.>?vv?????v>>^~^??>-...... <br></br>
 .``-~~~+++^>>>>>>>>>>>>>>?}uuuuw}vv>?v?>+^|``--.>?????v??????>>v?+^````.`.<br></br>
 `-~~~~^+++>>>>>>>>>>>>>?}ruuuuuuzz?`+>vv?|``|+^^???????v?>?>>}>v}?>-````|`<br></br>
.--~~~^+++>>>>>>>>>>?vwuyuxxzwzz}rr}}r}}yzv|`+???????v}rv?|>v^?v?vr?--`-`^`<br></br>
.-~~~|++++>>>>?vvvrzxuuuuzuxxxuuw}}rrr}}xx}^`~+>?????>?uw>?+?v?vvvv}~--``|`<br></br>
.-~~~^^^>>?}}}wwuyuuuxzxxxuuuuuxwv}}v+>ruww>>+>>????v?>}r????vv?vvvv|--`~>`<br></br>
.---|^^+>>vrrwv?vzxxxzzxxuuuuuuzw}}?+^vuuww++??>>??vvvv??????????v?^|~-|??`<br></br>
.---~^^^>???>????rzxzzxuuuyyyyuzwvrv>rxxu}>-~^vvvvvwv?}}v?????????++>>>??v~<br></br>
 `---^^^^+>?^^>?}rzxxxxuxwr}rrrvv>>>>???}?~-^^?v??vv>>??????????v?????>^|+`<br></br>
 ````-|^^^^^||?zxzzzuxxw?>^^||^|||~|||~|^+^^>?vvv}rvvvv???>>?????>^^|~|+>>`<br></br>
 ``````-|^^||~?uuxxzxxr>^^|~~~~~~~~~~~~--^+>vv}vvvv}rrrrr}v?^||^-`-+>>>>+^.<br></br>
 ````````-^|~~>vrxuuz}+^+^|~~|~~|~~||~~~-|+>vv?}zxuuuuuuxxzzv++~^^+>>^^+^^.<br></br>
 `````````~+>????vv?>++>+||~||~||~~|~--~~~|^^+}xuuuuuuuuuuxzzv>+++++|^++>+`<br></br>
 ```````-||+??????^^^>?>^|~~|~~~~~~~-``-~~~~~+>?wxxxxxxxxxxzzr?>+>^>++++>|.<br></br>


            </div>,

            <div>

      ..``......... `+^. .|>|.   .`~^>^~`.         ~>>>|-|^+>>>>>+|`.      <br></br>
 ..`-~|^>>^^^^^^||~``|?>~+?>`   ..-|+>>^-.        .~?vvv>>>?????>|`..      <br></br>
.-~+?vv??vvvvvv???v?^`+}}v>`    ..-|+++|`.     ...`^?}}}v?>???>+~`.        <br></br>
`?vvrr}vv}r}}}}vv}rv>-+}w}^.    .`-^>?+|~^^|` .`-|++?}rr}v????+~`......... <br></br>
`}rwwrv???????v?????+>vvvv?-.   ..-+?vv?vrrzwv-~^+>>?v}}}}v??>^~--~~~~~~~-.<br></br>
`vrrrrv?+^|~~||~~~-~>??+-^?>~.  .>rzzwwww}rrww}v???>>?}}rrv???????????????`<br></br>
.?v}}}vv?^-`......`+??|..`^?>~.+zxzwwwwwwwwwwwwwwr?++?v}}}}v?vr}vv}}vv}rvv-<br></br>
.+?vvvvv??+~`..  .|>>|....`^>+vyuuxzxxxxzzzzzwwwwww?^>?vvvv???vvvvvvvvvvvv`<br></br>
 `^????????>^~```^>+~. ..  `|vyuxzwwwwwwwwwwzzzwwwww?^>??>??>|^++++++++++^.<br></br>
 .`|>???????>>+>??>~.       -xzwwwwwwwwwwwwwwwwwwwwwr|~^+^+?+~............ <br></br>
  .`|>????????vvv?+-.       +uwwwwwwrr}}v}}rrwwwwwwww+`-||^^^~..    ... .. <br></br>
 ...`|>??????vvvv?+|-`..... +xwwwwwr}}}vvvvvv}rwwwwww>``||^>+|-.    ...... <br></br>
 ````^>???????>>?>++^|~-````|}rwwwr}r}}}vvvvv}}wwwwww+~~||^^^|~`. ....~>+-.<br></br>
.?>>?v????>>^~+^^^^+++++^^|~-?}}}r}v+?}vvvv}}r}rwwww}>+||||||~~~`...`+?vv?`<br></br>
`r}vrwv?>>>>`|>+^^^^+>>>>>+^||?v?vv?-r>?v??}>v}wwwwv>?^|~|||~~~~-..`|?rw}v~<br></br>
`}vv}rv>?++|^+>>>+^^^>>>>>>>+^|>??v}vv?v}??v^vvrrrv||?^~-~||~~~~~`..-+?v??`<br></br>
.++++++|--^>>>>>>>>++^+>>>>>>++^+?vrrr}vvvvv}??}v>~^++>---~~~~~~~-`.``~||~.<br></br>
 ````-~|++++>>>>>>>v>?v?>>+++>>+^?vvvvv}}rrrr>+^`~+>?>+|--~~~~~~~~-`...... <br></br>
 ...`--~>>>++>>>>>>>>?vrzz}?vwxw????rww?v}}}v`.``>vvv>+?????>+^~~~~-`..... <br></br>
 .`-~~~|>>>>>>>>>>>>>>>>>vzuyyuyu}vv+>vvvvv?~````|???????????vv?+~~-``````.<br></br>
 `-~~~~+>>>>>>>>>>>>++??>zyuuuuxxww?`>^?}}>`|+^||>??????v+~?-|}^>?|--````?|<br></br>
 -~~~~+>>>>>>>>>>>>>}uyxxuzr}rwwv}v}rr}vuuu+`+???????vxuuv`??^?v>vv|---~~v~<br></br>
.-~~~^>>>++??vvv??vxyuxzuxzzxuuxv}}rrr}ruxx?--+>?????wyyyv???vv???v?~~~`.?|<br></br>
.-~~|>>>>^vxuuyyyuuxzzwuuuuuuuyw?}}}v?vzywz?+++>????rzyxzv??vrv????v?~-`>x|<br></br>
.---^>>???xuyuuxxzzzzzwuuuuuuuu}}vv|.`xyzry>^>+>>??vzrw}v??????????vv?-+xu|<br></br>
.---|>>>?r}v}wzzzzzzzwzyuuuuuyu}rvxv+}yyurv|>>vv???wvv}v??????????????>}wu|<br></br>
 `--~>>>>?vr^^}zzzxxxxzxuuuxxxzvvvr}wwwzu}+~|~?vvvrr???>???????????>?vv??}-<br></br>
 ````-^>>>>>^^>xxxzzuuz}???????+^^^+++^>v+~-~+vvvv}??>>^+>>???????>>??????`<br></br>
 `````-~^>>+^^>xxxzzurv>^|~-~~-~~~~----`~?^+v}v?+>??vvvvv???>+>>>>>??vv?^~.<br></br>
 ````````~v?>^}urzuu}v^^^||~||~||~||~~~~`>}vr>^?rzxxxxzzzzzw}>>>+>??+~~+-~.<br></br>
 `````````+}zxuuuux}?^+>^|~||~~||~||~|~~|-+?}vzxxzxxuuuuxzzzxr?+^|+^-~|+>+.<br></br>
 ```````~++^>?}xzr?+^+>+|~~|~-~~~-~~--~~~~-~^rzzxxxxxxxxxxzzzz}?++~>^+>+?+.<br></br>


            </div>,

            <div>

     ...-`......... .^^. .^>~.   .`~^>^~`.         ->>>^~|^+>>>>>+|`.      <br></br>
 ..`~|^^>>+++++^^^|-`|?>|>?+.   ..-|+>+|-.        .~?v}v?>>?????>|`..      <br></br>
.~|>vvv??v}vvvvv?vv?^`+}rv+.    ..-|+++|`.     ..`-^?}}}v?>???>+~`.        <br></br>
`?v}rr}vv}}}v}}vv}rv>~>rw}|.    .`-^>?>^~|^+^|~--^++?}rr}v????+~`......... <br></br>
`}rwwrv????????????>+?vv???~.   ..->vrr}rwwwrv??v>>>>v}}}}v??>^~~~~~~||||~.<br></br>
`vrrrrv?+|~~~~~~---~>v?|`^?>~.  .>wzzwwwwrwwrrr}}v?>+?}}rr}???????????????`<br></br>
.?v}}}vv?^-`......`+??~...^>>|.|wxzwwwwwwwwwwwwwwrrv??v}}}}v?vr}vv}r}vvr}v-<br></br>
.+?vvvvv??+~`.. .`^>>~....`^>+?uyuxxxzzxxzzzzwwwwwwrv??vvvv???vv??vvv?vvv?`<br></br>
 `^????????>^~``-^>+-. ..  `|?uuxzwwwwwwwwwwwzwwwwww}v???>>?>||^^^^^^^^^^|.<br></br>
 .`|>???????>>>>??+-.       `rxwwwwwwwwwwwwwwwwwwwwwwvv+^^^>>~............ <br></br>
  .`|>????????vvv?+`..      -zwwwwwwwrr}}}}rrwwwwwwwwv?+~||^^~`.      . .. <br></br>
 ...`|>???????vvv?+|~`......~wwwwwwwr}}vvvvvvvrrwwwwwv>+~|^>+|-.    ...... <br></br>
.----+>???>+++>>?>++^|~-````-?rwwwwrr}r}}vvvvvv}rwwww??^~|^^^|~`. ....~>>~.<br></br>
.????v?>>>>|+|+^^^^+++++^^|~~+}}}r}v+vrvv}vvv}v}rrwwrv?^~|||||~~`...`^?vv?`<br></br>
`rv}wr?++>^^~+>+^^^^>>>>>>+^|~?v????|}|>}??r?v}rrrwrvv^^~~||~~~~-...~?}w}v~<br></br>
`}vv}v?^>^^^^>>>++^^+>>>>>>>+^|>??vvvv|}v|?v~v??}w}?++|^--~|~~~~~`..-^?v??`<br></br>
.++++^--~|+>>>>>>>>+^^+>>>>>>++^^?vv}}}vvv}v?v^>v?^|++^|--~~~~~~~-`.``-~|~.<br></br>
 ````-^^+^++>>>>>>????v?>>+++>?>+?vv??vv}}}}}??>~-|>?>^^-`-~~~~~~~-`...... <br></br>
 ..``-++>>>>>>>>>>>>>?vrzz}vrxuxv???rzw?v}}}}>|``~+>>++?>>??>>^~~~~-`..... <br></br>
 ``-~~++>>>>>>>>>>>>>>>>?}xyyuuyu}v?++?}?vv?>|-``~???v}}}v???>??^~~--`````.<br></br>
 `-~~^+>>>>>>>>>>>>>+>>>?xuuxuuxzww?-++?vv?|+^--`^???}rwz?|~++>+?+>|--```|`<br></br>
.-~~^+>>>>>>>>>>>>>?wuuxuxzr}wzwvvv}rr}vzzz?->???????wuxxv>>??????v>|--`-^`<br></br>
.-~|+>>>>+>??????vwuyxxxuxxxuuuwv}}rrr}}uuuv.|>>???vvwuw?+???v}v????>^-``~`<br></br>
.-~^+>>>+?wuuuuuuuuxzwxuuuuuuuu}v}}}v?vxyzzv.~+>???}rwxw}v??vrv????v?>~-~>.<br></br>
.-~^+>>>?vxuuuuxxzzzzzxuuuuuuuzv}}?-.|xyxwy?|++>>>v}vv}v?????????????v^+>>`<br></br>
.--^+>>?>vwwrzzzzzzxwxuyuuuuuuwrrrv+^wyuxwr~+>v?>?}r??vv?????????????v?vvv`<br></br>
 `-~^>>>?>?w??rzzxxuxxuuuuuuxxr}v}w}wzxuur>-~-vvvvw}????????????????vvv?vv`<br></br>
 ``-~+>>??>>??vxxuxxuuxw}v?vvv?>>>>???>?}>|~-~?vvvv???>^>????????????>????`<br></br>
 ```-~^++??++?}uxwzxxrv>^|~||~~~-~------^>|~>vv?>>>>>>>>>>>>>>?????>>>??>>`<br></br>
 ````-~^^+>+^+wzzzux}?^^^|~~|~~|~~~~~~~``?v?}?+>?v}rwwzzwwrv?>^>++?>>>>?+^.<br></br>
 ``````~^|^>?}xxuux}>^+>^|~||~|||~||~|~~~~??r??}wzxxuuuuxzxxzw?++^+>+^||^+.<br></br>
 ```````|^+^>rzuxwv>^+>+|~||~-~~~-~~-~~~~~~^>?rzzzxxxxxxxxzwwzw?+^|^^^||^+`<br></br>


            </div>,

            <div>

    ...`--......... .^+` `+>-.   .`~^>^~`.         `>??+~^^+>>>>>+~`.      <br></br>
 ..-|^++>?>>>>>+++^~`|??+??^.   ..-|+>+|-..       .-?v}v?>>?????>|`.       <br></br>
.|^?vvv?vv}vvvvvvvv?^`+rwv^.    .`-|+>+|`.      .`~^?}}rv?????>+~`.        <br></br>
`vvrwrvvv}}vv}}vv}}v>~?rwv|.    .`-^>?>+^|+v}v>~~^+>?}rr}v????+~`......... <br></br>
`}rww}v????????????>+?v????~.   ..->vwwwwzw}}}wwr>>>>v}}}}v??>+|~~||||^^^|.<br></br>
`vrrr}v?+|~-----```~???~`|??|.  .>wzwwwwwwww}}rrwvv>+?v}}r}v???v???v??vvv?`<br></br>
.?vv}vvv?^-..... .->?>-...|>>|.-wuzwwwzzwwwwwwrrrrwr?>v}}}}v?v}}vvvr}vvr}v-<br></br>
.+?vvvvv??+~`.. .`+>+`.....^>+>xyuuxzzzxxzzzzzzwwwwwwv?vvvv???vv???v???v??`<br></br>
 `^????????>+~``~+>^`  ..  `^+wuxzwwwwwwwwwwwzzzzwwwwwv>?>>?>||||||^^||||~.<br></br>
 .`|>???????>>>??>+-.   .   `?uwwwwwwwwwwwwwwwwwwwwwwww>^^^>+~............ <br></br>
  .`|>????????vvv?^`..       }xwwwwwwwrrr}}}rwwwwwwwwww?~||^^~`.     .   . <br></br>
 ...`^????????vvv?+|~`.......vzwwwwww}}}}vvvvvv}wwwwwwwv~|^>>|~.    ....`. <br></br>
.---~>>???>^^^+>>>++^|~-```-`>}rwwww}}rr}}vvvvvvvwwwwww?~||^^|~-. ...`->?|.<br></br>
.???vv?>>>+`^+^^^^++++++^^|~~|v}}}}v?}}}}rvvvvvv}}wwww}?~|||||~~`...`^?vv?`<br></br>
`rv}wr?+>>-||>+^^^^+>>>>>>+^|~+v????vv-+}vv}vv}rvrwwwr^?~~||~~~~~...->}wrv-<br></br>
`vvv}??^+|~|+>>+^^^^>>>>>>>>+^|+???vvv~vv+vv>v??vrwwv|->--~|~~~~~-..`^????`<br></br>
.^^^+~-`|>>>>>>>>>++^^>>>>>>>++^|>vv}}vv?v}v?v^^v}v>^+|>--~~~~~~~~`..`-~~~.<br></br>
 ````-^^+^++>>>>>?v>vvv?>+++>>??>?v???vv}vvv}vv?+|~+??+>-`-~~~~~~~-`...... <br></br>
 ..``->>>++>>>>>>>>>?vrzxzvvzuuu}v??rzz??}}rrr}-`-|+^|^>+>>>>>^~~~~-`..... <br></br>
 ``-~~>>>>>>>>>>>>>>>>>>?wuyyuuyx}v?^^?r?vvvvv?``-??>vzxxv+??|>v||~--````. <br></br>
 `-~~+>>>>>>>>>>>>>++>>+}uuxzxuxz}w?~>+?vvvv?+```-???xyyyr+.?>~??^?^--```` <br></br>
.-~~+>>>>>>>>>>>>>?}xyzwuzw}}rxrvvv}rr}?vv}v+>>>>???vzyzwvr>???>v?vv>--``` <br></br>
.-~^>>>++>?????>>}uyyxzuxzzxuyyvv}rrrr}}uyx}->?>???}wrzv--???vwr????v>-``` <br></br>
.-~+>>>+>ruuuuxxuyuxzruuuuuuuyz?v}rrvvvxyzw}`-^+>??zrr}v?????}vv?????v|^`` <br></br>
.-~>>>>>vyuuuuuxxzzzwzuuuuuuuy}v}v+`.>uyxruv|^^>>>rw??v????????>?????vv?`` <br></br>
.--+>>??>vxuxzzzzzzzrxyuuuuuuu}w}r>`|xyyzrz|^>??>?zv?????????????????v??>>.<br></br>
 `-~>>>?}+>zzzzzzzzzzzuuuuuuuxvr?rwvwzxuur?~-~vvvvrvvv?????????????????vv?`<br></br>
 ```^>>>vr>?wzzzzxuuuuxzw}rr}}???v?v}}vrz?>^|-^?v?vvv??>?????????????>?v?>`<br></br>
 ````^>>>v?>>wxux}zuzrv?>^|++|~~-~~~-~-|>>|~^?vv?v?>+++^^++>>??????>>+>??v~<br></br>
 ````-^>>>>^^>zruzuw}?^^|~~~~~~|~~~~~~-`-v??}v?>+??vrwwwwr}v?+|+>>>+>vvv??.<br></br>
 `````-^>+^^|?xwuuwv>^++|~~||~||~~||~|~~-^vvw?^?rzxxxxxxzzzxzwv+++>?v>^~->.<br></br>
 ``````-|^^^?uuuuwv>^+>^|~||~~~~~~~~-~~-~-|?v^}zzwzzxxxxxzzwwzzv+^+~+~---|-<br></br>


            </div>,

            <div>

   ....`~-``````.....^+`.`++`    .`~^>^~`.         `>??>|^++>>>>>+~`.      <br></br>
 .`~^++>??>>>>>>>++~`|??>??|.   ..-|+>+|-..       .-?v}}????????>|`.       <br></br>
.^>?}}vvv}}vv}vvvvv?^`>rwv|.    .`-|>>+|`.     .`-|^?}}r}?????>+~`.        <br></br>
`v}rwrvvv}}vv}}vv}}v+|?rwv|.    .`-^>?>>+^>v}v?+>????vrr}v????+~`......... <br></br>
`}rww}?????>>?????>++?v????|.   ..->vwzzwzwrrrwwr??vvv}}r}v??>+^||^^^^++^^.<br></br>
`vrrr}v?^~-````````|??>-.|??|.  .>wxwwwwwwwwrrrww}}vv?v}}r}v??vv??vvv?vvv?`<br></br>
.?vvvvvv?^-....  .~>?>`...|>>^`-ruzzzzzzwwwwwwwwwrwrvvvv}}}v?v}}vvv}}vv}}v-<br></br>
.+?vvvvv??>|`.. .-+>^`.....^>+>xyuuxzzzxxzzzzzzwwwwww}v}vvv??>????????????`<br></br>
 `^????????>+|--|++^`  ..  `^+wuxzwwwwwwwwwwwzzzzwwwww}vv???>|~~||||||~~~~.<br></br>
 .`^>???????>>????^`    .   `?uwwwwwwwwwwwwwwwwwwwwwwww??>^>+~............ <br></br>
  .`|>????????vvv?^`..      .vxwwwwwwwrrrr}rrwwwwwwwwwwv>>|^^|`.     .   . <br></br>
 ...-^???>>>??vvv?+|~`.......?zwwwwwwr}}}}vvvvv}wwwwwwwv>>|>>|~`    ....`. <br></br>
.~--|>>>>++^^^+>>+++^^|--``-`+}rwwww}}}}}}vvvvvvvrwwwww?^>|^^||-.....`->?^.<br></br>
.???v?>++^+`++^^^^++++++^^||~~?}}}}vv}}}}rvvvvvv}}rwwwr?^^|||||~-...`^?}vv-<br></br>
`rv}wv>+++-|^>+^^^^+>>>>>>++|~^vv???vv->}vv}vv}}v}wwwr>?~~||~~~~~`..->vwrv-<br></br>
`vvvv>>^+||^+>>+^^^^>>>>>>>>+^|^>??vvv~v>^v?>v??vrrwv+~?~-~|~~~~~-..`|>???`<br></br>
.^^^~`--^>>>>>>>>>++^+>>>>>>>+++^>vvv}vv>v}??v|+vvv?++|>~-~~~~~~~~`..``-~~.<br></br>
 ```-|+^+++>>>>>>???vvv?>+++>?v???v????v}vvv}vvv^~~^??>?^~-~~~~~~~~`...... <br></br>
 ..`|^>>>++>>>>>>>>>?}wxxwvvzuuurv??rzzv?}}}}}}|`-~^^>????>?>>~~|~~-`..... <br></br>
 ``~^^>>>>>>>>>>>>>>>>>>?zuyyuuyu}v?+|?r?vvvvv?-``>?>}xxxv>>?^^?+^-~-````. <br></br>
 `-|^>>>>>>>>>>>>>>++>+>ruuxzxuzz}w?|>+?vvvv?>~```??vxyuwv>`>>+??+?+~-```` <br></br>
.-~^+>>>>>>>>>>>>>?rxuzzuzw}rwxrvvv}rr}?vv}v>???>??vvzuzr>}?????v??v>~-``` <br></br>
.-|+>>>++>?????>?}uyuxxuxxxuuyuvv}rrrr}vxuzr|>?????}w}wv~~???vzwv???v>~``` <br></br>
.~|>>>>+?wuuuuxxuyuxzwuuuuuuuyw?v}rrvvvxuxwr`-++>?vwv}}v?????}vv?????v++-` <br></br>
 ~^>>>>>vyuuuuuxzzzwwxuuuuuuuu}v}v+`.?uyxruv`~^>>?rr??v??????????????vv?`` <br></br>
.~^>>>>?>}uuxzzzzzzzrxuuuuuuuu}wr}|`^xyyzrz^|>??>vzv?????????????????v?v>>.<br></br>
.-|^>>>??>?zzzzzzzzzzxuuuuuuuzv}vrw}wxuuur?-~~v}vv}vvv?????????????????}vv`<br></br>
 `-|+>>>?v>?wzzzzxuuuuxzwrrrr}??vv?v}}}wxv+^|~^?v?vvv?v???????????????????`<br></br>
 `-~|+>?>??>?wxuurxuxw}v?+^>+^|~-~||||~+?>~~~>?v???>>+>+^^+>?????????>>>?v-<br></br>
 ``~||+?>>+++?xwzxuwv>^^|~~~~~~~~~~--~-`-v?+vv?>+>??vv}}}vv?>+^+>>>+>vvvv?.<br></br>
 ``~|||+>+^++?xzuuwv>^+^|~~||~||~~|~~|~~`>v?r?^>?}wzzxxxzzzzzwv++++?v>+^|?.<br></br>
 ``~||~||^^>vuuuuwv+^+>^|~||~~~~-~~~-~~-~-^?v^?}wwzzxxxxxzwwwzzv+^+~>^~~-|`<br></br>


            </div>,

            <div>

   ...`-|~````````...^+`.~>+`    .`~^>^~`.         `>??>^^++>>>>>+~`.      <br></br>
 .`^>>>>???????>>>>|`|?????-.   ..-|+++|-..       .-?v}}v?>?????>|`.       <br></br>
.+>v}}vvv}}}v}vvv}}?+`>rwv~.    .`-^>>+|`.     `~|^^?}}r}?????>+~`.        <br></br>
`vrww}vvv}vvvvvvv}}v+^?rrv|.    .`-^>???vvvvv?vwwww}?v}r}v????+~`.....``.. <br></br>
`}rwr}?>>>>>>>>>>>>^+?v?>??|.   ..->}wzzwwwwwzw}}wwww?}}r}v??>>+^^+++++++^.<br></br>
`vrrr}v?^~`````````^??+`.|??^.  .+wxwwwwwwwwwwwwrrwww?v}}r}v??vvv?vvvvvvvv`<br></br>
.?vvvvv??^-...   .|>?+`...|>>^`.vyuxxzzzwwwwwwwwwwrrrwvv}}}v?v}rvvv}}vv}}v-<br></br>
.+?vvvv???>|`....~+>^. ....|>+^ryyuuxzzxxzzzzzzwwwwwwww}?????>????????????`<br></br>
 `^????????>+|-~^++|.  ..  `^^?uxzwwwwwwwwwwwwzzzzwwwwwwv????|-~~~~~~~~~~-.<br></br>
 .-^>????????>????^`    .   `|wxwwwwwwwwwwwwwwwwwwwwwwwww?^>>|`........... <br></br>
  .`|>????????vvv?^`..     ..|xwwwwwwwwwwwwrrwwwwwwwwwwwwv|^^|`.      .  . <br></br>
 ...-^??>>>+??vvv>+^~-`....``~rwwwwwwwwr}}}}}vvvrwwwwwwww}|>>^~`.   ....-. <br></br>
.~~~^>>>>^^+^^+>>>++^^|~------v}rwwwwrvv}r}}vvvvvv}wwwwwwv|^|||~.....`->?+.<br></br>
`???v?+>+`^>^^^^^++++++++^||~~>}vvv}vvvv}rrrvvvvvvvrwwwww+|||||~-...`|?}}v-<br></br>
`r}}?+>+`||+>+^^^^+>>>>>>>++^|~?v?v???v}vv}rvvvvvv}}wwww?-||~~~~~`..`+vrr}-<br></br>
`vv+>^+^|^+>>>+^^^^>>>>>>>>>++^|>?v??vvv.^r?vv}vvrvrww}?>`~|~~~~~-`.`~+???`<br></br>
.^|~--|>>>>>>>>>>>+^++>>>>>>>+>>+??vvvvv|}v^vv??v>vr}?+?>--~~~~~~~-`.``-~-.<br></br>
 `.~^+>^+>>>>>>>???vvv??>++>?vwwr}??????vv?}}v>}>+??>}xuur--~~~~~~~-`..... <br></br>
 ..->>>++>>>>>>>>>>?vwxuxv?zuuuywv??rzzr>}}vvv}}v?~~^zyyyw>>?>-^^-~~``.... <br></br>
 ``~>>>>>>>>>>>>>>>>>>>?}xyyuuuyurv?+`+w?vv}}}}}v`~|?xuzxwv`~?|+r>`|-````. <br></br>
 -~+>>>>>>>>>>>>>>^^^++vxuzwwxuzzv}?+??>?v?v?vvv>`+vwwwv->uv|????}>v?~```` <br></br>
.-|>>>>>>>>>>>>>>>vzxw}xzwr}wzxvvvvrrr}?vvvvvvv?^^?www}v?.>v??v}v???vv~``` <br></br>
.->>>>+>>>??>>>>?zyyxzuxuuuuyyw?}}rrr}}vvvvv??????vzvv}}v>>???zxz????vv-`` <br></br>
 |>>>>^vwzxxxzzzyuxzwuuuuuuuuuvv}rrrvvvxxxzz|+>+>?zr>?v??????vv???????v??~ <br></br>
 ^>>>>>zyuuuuuuxzzzrxuuuuuuuyz?vv?^-`vxyuwxw``|>>rzv???????????????????rv^.<br></br>
 ^>>>>>>zyuuxzzzzzzruuuuuuuuywrrr^ .>uuyrwu>-^???w}vvv?????????????????rvv`<br></br>
.~>>>>??+vxzzzzzzzz}uuuuuuuuu}rvwwvvzuuyx}?->>v}}v?vvv?vv??????????????}vv`<br></br>
 `|>>>>r>~rzzzzzzzxxxzzzwzwwwvvv}rv}rwxuyr>>++|vv?vv??wx???????????????>?v-<br></br>
 `->>>>w?|+wzzzxxuuuxzww}?????>>^^>???>vw>|^-~+?vvv???vv>+>???????????>>vv-<br></br>
 ``+>>>r?+|?xuuzvuxrv>++^~~|~~~~-~-`---`^?+~>vvv??+++>????>>>>>>>>>>>>>???-<br></br>
 ``+>>>>>^>zurwuux}v^^^|~~||~~||-~|~~~~--vv?rr?>|+vrzzzxxxzzwrv>|+>>>??vvv`<br></br>
 ``+>>>+^^?wuxxuxvv^^++|~~||-~~~-~~~~~-~-|vv}?-+}zzzwwzwwwwwzzzr?+>>v}?+-`.<br></br>


            </div>,

            <div>

  ...``~|~`-----```.`^+-.^>^.    .`~^+^~`.         .+???+++>>>>>>+~`.      <br></br>
 .~+??>???????????>^-|?v?v>`    ..-|+++|-..       .-?v}}v?>?????>|`.       <br></br>
.>?vr}vv}r}}}}vvv}}v+`>wwv-     .`-^>>+|`.     `~^++?v}r}v?>??>+~`..       <br></br>
`}rww}vvvvvvvvvvv}v?++vrrv|.    .`-^>???vvvvv??wwww}?v}rr}????+|``.``````. <br></br>
`}wwrv?>>>>>>>>+>>+|>vv>+??^.   ..->}wzzwwwwwzw}}wwww?}}}r}???>++++>>+>>>+.<br></br>
`vrr}}v>^-````....`+??+`.~>?^`  .+wxwwwwwwwwwwwwrrwww?v}}rrv??v}vvvvvvvvvv`<br></br>
.?vvvvv??^-...   .^??^....|>>^`.vyuxxzzzwwwwwwwwwwrrrwvv}}}v??}}vvv}}vv}}v-<br></br>
.+?vv?v???>|`...`|>>|.... .|++^ryyuuxzzxxzzzzzzwwwwwwww}?????>????????????`<br></br>
 -+????????>+|~|+>+~.   .  `^^?uxzwwwwwwwwwwwwzzzzwwwwwwv??>?^------------.<br></br>
 .-^>???????>?????|.   ..   `|wxwwwwwwwwwwwwwwwwwwwwwwwww?^+>|`........... <br></br>
 ..-^>???????vvvv?^`..     ..|xwwwwwwwwwwwwrrwwwwwwwwwwwwv|^^|-.      .  . <br></br>
 ..`~+??>>>+??vv?>+^~-`....``~rwwwwwwwwr}}}}}vvvrwwwwwwww}|>>^~`.   ....~` <br></br>
.~~~+?>>>^^+^^+>>+++^^|~------v}rwwwwrvv}r}}vvvvvv}wwwwwwv|||||~`....`->?>.<br></br>
`??vv?+>+`^>^^^^^+++++>++^||~~>}vvv}}vvvrrrrvvvvvvvrwwwww+||||~~-...`|?}}v-<br></br>
`}}}?+>+`||+>+^^^^+>>>>>>>++^|~?v?v???v}vv}rvvvvvv}}wwww?-||~~~~~`..`^?rr}-<br></br>
`vv+>^+^|^+>>>+^^^^>>>>>>>>>++^|>?v??vvv.^r?vv}vvrvrww}?>`~|~~~~~~`.`-^???`<br></br>
.||~--|>>>>>>>>>>>+^++>>>>>>>+>>+??vvvvv|}v^vv??v>vr}?+?>--~~~~~~~-`..``--.<br></br>
 `.~^+>^+>>>>>>>???vvv??>++>?vwwr}??????vv?}}v>}>+??>}xuur--~~~~~~~-`..... <br></br>
 ..->>>++>>>>>>>>>>?vwxuxv?zuuuywv??rzzr>}}vvv}}v?~~^zyyyw>>?>-^^-~~-`.... <br></br>
 ``~>>>>>>>>>>>>>>>>>>>?}xyyuuuyurv?+`+w?vv}}}}}v`~|?xuzxwv`~?|+r>`|-````` <br></br>
 -~+>>>>>>>>>>>>>>^^^++vxuzwwxuzzv}?+??>?v?v?vvv>`+vwwwv->uv|????}>v?~```` <br></br>
.-^>>>>>>>>>>>>>>>vzxw}xzwr}wzxvvvvrrr}?vvvvvvv?^^?www}v?.>v??v}v???vv~``` <br></br>
.->>>>+>>>>?>>>>?zyyxzuxuuuuyyw?}}rrr}}vvvvv??????vzvv}}v>>???zxz????vv-`` <br></br>
 |>>>>^vwzxxxzzzyuxzwuuuuuuuuuvv}rrrvvvxxxzx|+>+>?zr>?v??????vv???????v??~ <br></br>
 ^>>>>>zyuuuuuuxzzzrxuuuuuuuyz?vv?^-`vxyuwxw``|>>rzv???????????????????rv^.<br></br>
 ^>>>>>>zyuuxzzzzzzruuuuuuuuywrrr^ .>uuyrwu>-^???w}vvv?????????????????rvv`<br></br>
.~>>>>??+vxzzzzzzzz}uuuuuuuuu}rvwwvvzuuyx}?->>v}}v?vvv?vv??????????????}vv`<br></br>
 `|>>>>r>~rzzzzzzzxxxzzzwzwwwvvv}rv}rwxuyr>>++|vv?vv??wx???????????????>?v-<br></br>
 `->>>>w?|+wzzzxxuuuxzww}?????>>^^>???>vw>|^-~+?vvv???vv>+>???????????>>vv-<br></br>
 ``+>>>r?+|?xuuzvuxrv>++^~~|~~~~-~-`---`^?+~>vvv??+++>????>>>>>>>>>>>>>???-<br></br>
 ``+>>>>>^>zurwuux}v^^^|~~||~~||-~|~~~~--vv?rr?>|+vrzzzxxxzzwr}>|+>>>??vvv`<br></br>
 ``+>>>+^^?wuxxuxvv^^++|~~||-~~~-~~~~~-~-|vv}?-+}zzzwwzwwwwwzzzr?+>>v}?+-`.<br></br>


            </div>,

            <div>

  ..```~^~------```.`^>~`^>~.   .|^+>?>^-`.        .^???>+++>>>>+^~`.      <br></br>
 `|>?????vv????????+-|?vvv+..`-|+>???v??>+^|-`.  ..~>v}rv?>?????+~`.       <br></br>
.?v}r}vv}r}}}r}vv}}?^-?wwv~-^+++++>?vv??>++^+^^|~^++>v}r}v????>^~`......   <br></br>
`}wwr}vvvvvvvvvvvvv?+>v}}}v?++++>>??}r}}rrrr}v}ww}}v?v}rr}v???+|-````````` <br></br>
`}wwrv?>>+++++++++||?vv>?wwv?+++++>vrwwwwwwwwwwwrw}r}?v}}r}v??>>>+>>>>>>>>.<br></br>
`vrr}}v>^-``......`>??^|?}wwv?+^+?rwwwwwwwwwwwwwrwr}v?v}}rrv??v}vvvv}vvv}v-<br></br>
.?vvvvv??^-`..  .`^?>|`???vvvv?>}zwwwwwwwwwwwwwwwww}vvvv}}vv??v}vvv}}vvv}v-<br></br>
.+?vv?????>|-...`^>+~.`??+>vvvvrzwwwwrwwwwwwwwwwwwwr}vvv?????>>??????????>`<br></br>
 -+????????>+^|^>>+-. `?>++?v?vzwrrrrrrrrrwwwwwwwwwwvvvvv?>>?^-`------``-` <br></br>
 .-^>??????????v?>|.  .>>+^+>?zw}rrrrrr}rrrwwwwwwwwrv???v?^++|`........... <br></br>
 ..-^>>>?????vvvv?|-...-|^+^|?x}}rr??w}+}rrrwwwwwrrrv?>>??|^+|~.      .. . <br></br>
 ..-|^^++++>?vvv?>+^~-``~|^+~^wvvrrvvwr?v}}v}vv}}rr}??>>??|+>^~-.   ....~` <br></br>
.~~|~-^++^^^^+>>>+++^^^~|^++>>vv}}rrr}}v}r}vvvvvvv}vvv???>|||||~`....`->?>.<br></br>
.^^+~|+>+-^+^^++^++++>>>+++???v}}}rrr}}}rr}vvvvvvv??vvvv?|||||~~~`..`|?}rv-<br></br>
`>>+>>+>^^^^++^^^^+>>>>>>>>vvv?}v>+^|^vr}}}}vvvv?>+>???}+-||~~~~~-..`|?}r}-<br></br>
.+>+>+>>+>>>>>++^^+>>>>>>>>v}v?~~~-`-~+}|?w??v?>^+^>???^^`~||~~~~~`.`-|>??`<br></br>
.~^^^^+>>>>>>>>>>++^+>>>>>>?vvv-|???vvvv>}v^>+~|^~^???++|~-~~~~~~~-`..``--.<br></br>
.|~|+>>>>>>>>?>>v??vv??>+++>?vw}ww}}vvvvvv??+|~+~-~^?vwwr?|`~|||~~~-`..... <br></br>
.||^>>>>>>>>>>>>>??vrzxzr??v}rrw}vvvrr}}>??+^^^^~~~||}rwzw????>??+++|`.... <br></br>
.|^+>>>>>>>>>>>>>>>>>>?vzuuuxzwzrrw}v?>v>++>+^^^~`~~^vr}r}v^>?>?v?++|||~`. <br></br>
.^^>>>>>>>>>>>>>>>++>>>}uuuuuuuxxrv|+>??^^||~~~|-`|+?v}v>>}?>????v???|~|^- <br></br>
.^+>>>>>>>>>>>>>>?vwwrzuxxxzxxuzww?+?vrrvv+^||+>+>?vvvvv?^?v??vv>????}?``^`<br></br>
.^>>>>>>>>>>>>??ruyyuuyuuxxxxxw}zw}}}}}vzx}+~-^>???vvvvvv????>zu?+??>?w+.-.<br></br>
.^>>>>>vvvvv}zxuyuxzwuuuxxxxuurv}rrr}}rwxxxv-`-|>?vv?vvv?????v}}v?????v}+^.<br></br>
.+????vxuuuuyyuxzzzwxuuuuuuuuuvv}v???rxxxzxr``+>>?v??vv???v}}r}v???????vv>.<br></br>
.>?????vuuuuxzzzzzxruuuuuuuuux}}r?|^>}}wxzx}-|?>?????v????vrrr}v????v??vvv`<br></br>
.+>???vv?rxzzzzxxxxwuuuuuuuuuz}vwwrrv??zuwv?||v?v??????vvvv}}vvv???????vvv`<br></br>
.>>>???w??wxxxxxxxxxuuuuxxxxxzv}}wzww}wuuz??|~|v}v????v}vvvvvv??????????vv`<br></br>
.|+?>>?zv?vxxxxxxuuuuuxxxwwwrr??>??vv??}w}?|-~~+?vvvvv}}v?>?????????????vv`<br></br>
.|^>>>?w???ruuuxwuuxwv????>>>>+~~|~~~|~~>v^~^^>v??>+>?vvv???>>?????????vvv`<br></br>
.||+>>>???vxxzxuuxw}?+^|||^^|||~~||~~~~-`vv>?vv?+?v}r}}vvvvv???>^>????vv??`<br></br>
.||^+>>>??}xxzuuz}?^^+^|~|||~~~~-~~~~~-~`~vv??>>}r}v??v}}rrrr}}v?>>>>?>|-`.<br></br>


            </div>,

            <div>

 ..```-|^~~~~~----`.`+>|-+>^.   `vvvvv?>^~`.   .   .^???>+++>>>>+^~`.      <br></br>
 -+?????vvv??v?????+-|v}vv>`.|?}wzzzzzzzzwrv?^`....~>v}r}?>?????+~`.       <br></br>
.?vrr}vv}r}}}rvvvr}?^-?ww}^>wxzzwwwwwwwwwwwwwwr?~|^+>v}r}v?>??>^-......... <br></br>
`}wwrv??vvv?vvv?vvv?+>vrrrzxwwzzxzzxxxxxzzzzzwwzr???>?}}r}v???>|-`````---` <br></br>
`}wwrv?+++^^^^^^^^||?vv?ryxxxxzzwwwwwwwwwwwwwwzzww??>?v}}r}v??>>>>>??>>??>.<br></br>
`v}}}vv>|-`.......->??+?uuxxzwwwwwwwwwwwwwwwwwwwwwr?>>v}}rr}??v}vvv}}vvv}v-<br></br>
.?vvvvv??^-`..  .`+?>^-zxzwwwwwwwwrrrrrwwwwwwwwwwwwv>+?v}}vv??v}vvvv}vvv}v-<br></br>
.+????????>^-`..-+>+-.^uwwwwwwrr}vvvv}}}rrwwwwwwwww}>++?v????++>>>>??>>>>>.<br></br>
 -+????????>+^|+>>+-. +uwwwwr}}vvvvv}}}r}}rwwwwwwwwr+||+>>+>?+-``````````` <br></br>
 .-^>??????????v?>~.  ~xwwww}}}}vvvv}w}?vr}rwwwwwwrv^~--|^^^+^-........... <br></br>
 .`~+++>>????vvvv>|-...vrwwr?}w??vr|+xv v}r}wwwrr}}v|-``~||^+^~`.     .... <br></br>
 .`^>^^^^^+>?vvv?>+^~-`~v}r}+>z>?}r>?w}>v}}v}}}}vv}?~~--~|^+>^|-.   ....|~.<br></br>
.^+>|.^^^^^^^>v?>+++^^^~+v}vvv}vvvvvvvv}}rv?vvvvv}v^+>+|~||||||~`....`-+??`<br></br>
.+>^.|+>+^^^^^>>+++++>>>+>?vrr}}}}www}rrrrv?v??v}v+^??>>~|||||~~~`..`~?}r}-<br></br>
.+>~+?+>>+^^^^^^++>>>>>>>?>}rrrx}^~~~-vrrrvv??vv?~-|^+^?|-|||~~~~-..`|?}r}-<br></br>
.^>+>>>>>>>>++^^^>>>>>>>>>?vrrv|-.. ..|rrrvv??+~.`-~|||~|--~|~~~~~`..`|>?>`<br></br>
.^>>>>>>>>>>>?>>+^++>>>>>>>?}r}`|???v}?}r}v?-`````~^+>+-`+-~~~~~---`..``--.<br></br>
.>+^>>>>>>>>>v?>}r}v??>+++>+>?}v}wwwr}}}vv?|`````-|^?vvv>+>-++^||~~-`..... <br></br>
.>++>>>>>>>>>>>>?v}wzxxwv?>>+^+??vv}rv???+~-`````-~|^??vv}vvvvvvvvv?>~`... <br></br>
.>>>>>>>>>>>>>>>>>>>>?vwuxuxwv?v?rwwzx>|||~--````-~|-+?vv??v????????vvv>-. <br></br>
.>>>>>>>>>>>>>>++++>>>?zuuuuyyuuxw?-^>??|~~~-````-~~~??????vv??????????vv+.<br></br>
.>>>>>>>>>>>>>>+>v}vvvuyuuuuuuuuxu>.|+vxv?|~-`~+??????????????w?`+?^~ww^+}|<br></br>
.>>>>>>>>>>>>>vzyyyyxuyuuzwzr}}zxz}v}}}vuyr~~~~^>>>????????~|^wx~.?^`>xv.?-<br></br>
.>>>????????vzyyyuxzwuuzwwrzzxzvv}rrrr}vzuu>---~^+>????????^??vwr+???+?v+v-<br></br>
.>vwxuuuuuuuyyuxzzzwxuxxuuuuuyrv}rr}rrr}wxzr`^+>+>>???????}uuuxrvvv?vvv??v`<br></br>
.vuuuuuuuyuuxzzzzzxwuuuuuuuuuuvv}}}}>|^?uwwu~~++>>>>?????vwuuxx}???v}vv???`<br></br>
`??wuuuuxxzzzzzzzzzzuuuuuuuuuuvv}}wz|``vyzrx||?>??>>>????rwuxw}???????????`<br></br>
`w}?vxxzzzzzzzzzzxxzuuuuuuuuuu}rvzxrwv}uuu}v|~^?}r??>???}}}r}vvvvv????????`<br></br>
.>wr>?wzzzzzzxxxxuuzuuuuuuuuuuvvvrv}wrrwzx}+|^-`?v}}rrr}rv????vvvv????????`<br></br>
.>>>++?zzzzzxuuuuuuuzzzxzzzwwrv^^^+>>>+>v}+||`^v????v}rz}}vv????vv????????`<br></br>
.>+^+++wxxxuxzuuuxwr}}v>^+>>+^|--~~~~~~~-??+^v}vvvvvv??>>>>++>????????????`<br></br>
.+^^^??vuuuuw}uxr}?+++^|||||~~~~-~~~~~-~`~vv??}vvv?+|+??vvvvv???>>>+>>>>>>.<br></br>


            </div>,

            <div>

 .`--~~^^|~|||~~~~-``^>^^>vr}vvvvvvvvvvvvvvvvvv?`  .|????++++>>>+^~`.      <br></br>
.~>?v???vvvvvv???v?>-~v}rxzzwzzzzzzxxxxxxxxxzzwww?`~+v}r}v?>????+~`.       <br></br>
.?vrr}vv}r}}}}vv}r}?||?wuzzxxxxxzzzzzzzwwzzzzzzzzzv+>vrrrv????>+~`........ <br></br>
`}wwrv??vv??vv??vv?>+?ryyyuxzwwwwwwwwwwwwwwwwwwwwwwv+?}}r}v???>^~-------~-.<br></br>
`}wr}v?+^^^|^^||||~+?vyuuxzwwwwwwwwwwwwwwwwwwwwwwwww>?v}}r}v?????>????????`<br></br>
`v}}}vv>|-`.......~??zuwwwwwwwwwrr}}}}rrwwwwwwwwwwwwv+?}}}}}??v}}vv}}vvv}}-<br></br>
.?vvvvv??^~`..  .->>?uzwwwwwwrvvvvvvvv}}}rwwwwwwwwww}+?vvvvv??vvvvvvvvvvvv-<br></br>
.>????????>^-`..~++^^uwwwwwr}vvvvvvvr}}rr}}wwwwwwwwwr++??????>+>>>>>>>>>>>.<br></br>
 -+????????>+^^+>>^``zzwwww}r}rvv}vvr}v?}r}}wwwwwwwrv||^>++>?+-``````````` <br></br>
 .-^>?????????vv?>~. >wwwwrv}rv?vvv`vzv >}rvwwwwrr}}v~--|^^^+^-........... <br></br>
 `^+^^++>>???vvvv>|-.`?}rw}+>w?^vvv-vwv^vvrvrr}}vvv}?-``-||^+^~`.     .... <br></br>
.>^-^^^^^^^?vvvv?>+^|-~v}}?vv}vv}}v?vvvv}r}??vvvvv}v^|~-~||+>^|~..  ....||.<br></br>
.>``>+^^^^^^?vv?>++++^||?v}}}vvvvvv}}}}rrr}?v???}}v++>+^~||||||~-....`-+??`<br></br>
.`->>>>^^^^^^??++++++>>>+?r}rr}wrrr}v}rrrr}vv??v}v^^>?>^|~||||~~~`...~>vr}-<br></br>
 `v?+>>>+^^^^^+>++>>>>>>>?r}}}rv|^|||>}rrr}vv???+--~^+^|~-~||~~~~~`.`~>vr}-<br></br>
.+>>>>>>>>>++^^+>>>>>>>>>>}r}r?+v}}}}vvrrrvv?+|`.`-~+??>|--~~~~~~~-..`~+>>`<br></br>
.+>>>>>>>>>??>>+^+++>>>>>>>vrr}rr}vv}rrr}vvv~`````~^^?vvvv?>|~~-^^-`...```.<br></br>
.^+>>>>>>>>??>?wrrvv?>+++++>?v}}}}vvvv}vvv?|`````-|+++v????vvv>|>v?+```... <br></br>
.^+>>>>>>>>>>>>??vrzuuzv??+|^+>???vv}????+~-`````-~|^|????vvvv}vvvvv??v>`. <br></br>
.>>>>>>>>>>>>>>>>>>>?vzxuuur??}>}wzzzz^|||~--`````-~~-+????????????????v?>.<br></br>
.>>>>>>>>>>>>+++++>>>ruuuuuyuxxxrv|->>??|~~~-`-+>>>>>>???????????????????v-<br></br>
.>>>>>>>>>>>>+^>?v??zyuuuuuuuuxwu} .?^ru??|~---~??????????????????????????`<br></br>
.>>>>>>>>>>>>vzyyyuwyuuuxxurr}zxww>?vv}vxyw|~~~--+>>????????????vv~|??~-}}.<br></br>
.>>>>>>+>>>?zyyyyuwuuuzr}}rrww}rv}rrrr}vxxuv---|^|+>?????????>.^xx+ -?. ?z|<br></br>
.vwzxxxwrrzyyyuxxwwyuzzuxxuuyz?}}rrrrrrvvuwz~|>>>+>>?????????`^ vxz->??+`}~<br></br>
-uuuuuuyyyyuxzzzxruuuuyuuuuuy}v}rrrr}v}}wx}y?`|+^>>>>????????~`~`z}???????`<br></br>
-uuuuuuuuxzzzzzzzwuuuuuuuuuuuv?vv}}?`..+uzwyv`^?+>>>>>????????>?|??????vv?`<br></br>
.?ruuxxzzzzzzzzxwruuuuuuuuuuuv}}vwur+|+ruu}zv^v?>?v>+>>??????}xuxxw???????`<br></br>
.+^?zzzzzzzzzzzzwruuuuuuuuuuu}rvrxz}xvwuuyw}>|~^?r}vv????????zyyuxwv??????`<br></br>
.++^?zzzzzzzzxxuuwyuuuuuuuuuuvvvr}vrrrrrwxx>|++|`>?v}rwzzzz}vxyyuxv???????`<br></br>
.+^|?zzzzzxuuuuuuzwzxxxxzzwwwv>||^>?v?++v}>|+~~+v??v??vwxzwvvrzr??????????`<br></br>
.^^+>wxxuuxwxuuuwrv?>>?>????>+~-~-```````??~`^vrv?vv?vv?+|||~~~|^>>++>>>>>.<br></br>


            </div>,

            <div>

 .`~|||^+||||^^|||~``^>+^>vr}vvvvvvvvvvvvvvvvvv?-  .|????++++>>>+^~`.      <br></br>
.^?vvv?vv}vvvvvvvv?>-~v}rxzzwzzzzzzxxxxxxxxxxzwww?`~+v}rrv?>???>+~`.       <br></br>
`v}wrvvv}}}v}}vv}rv?||?wuzzxxxxxzzzzzzwwwzzzzzzxzzv+>v}rr}????>+~`........ <br></br>
`rwwrv?????????????+>?ryyyuxwwwwwwwwwwwwwwwwwwwwwwwv+?}rr}v???>^~--~~~~~~~.<br></br>
`}rr}v?+^|||||~~~~-+?vyuuxwwwwwwwwwwwwwwwwwwwwwwwwww??v}}rrv??????????????`<br></br>
`v}}}v?>|`........|??zuwwwwwwwwwrr}}}}rrwwwwwwwwwwwwv+?v}}}}??vr}vv}}}vv}}-<br></br>
.?vvvvv??^~`..  .~>>?uzwwwwwwrvvvvvvvv}}}rwwwwwwwwww}+>vvvvv???vvvvvvvvvvv-<br></br>
.>????????>^~`.`|++^^uwwwwwr}vvvvvvv}}}rr}}wwwwwwwwwr++??????>^++++>>>++>+.<br></br>
 -+????????>>++>>>|``zzwwww}}}rvv}vvr}v?}r}}wwwwwwwrv||^+++>?+-..`````..`. <br></br>
 .-^>?????????vv?>~. >wwwwrvvrv?vv?`vzv >}rvwwwwrr}}v~-`~^^^+^~....  ..... <br></br>
 `^+^^++>>???vvv?>|-.`?}rw}+>z?+vvv-vwv^?vrv}r}}vvv}?-``-||^>+|`.     .... <br></br>
.>^-+^^^^^^?vvvv?++^|-~v}}?vv}vv}}v?vvvv}r}??vvvvv}v^|~-~||^+^|~`.  ....|^.<br></br>
.>.`++^^^^^^?v??+++++^||?v}}}vvvvvv}}}}rrr}?v???}}v++>+^|||||||~-....`-+??`<br></br>
.`->>>>^^^^^^??+++++>>>>+?r}rr}wrrr}v}rrrr}vv??v}v^^>?>^|~|||~~~~-...->vr}-<br></br>
 `v?+>>>++^^^^+>++>>>>>>>?r}}}r}|^|||>}rrr}vv???+--~^^^|~-~||~~~~~`..->vr}-<br></br>
.+>>>>>>>>>++^^+>>>>>>>>>>}r}}?+v}}}}vvrrrvv?+|`.`-~+??>|--~~~~~~~-`.`-^>>`<br></br>
.+>>>>>>>>>??>>+^+++>>>>>>>vrr}rr}vv}rr}}vvv~`````~^^?vvvv?>|~~-^^~`...```.<br></br>
.^+>>>>>>>>??>?wrrvv?>+++++>?v}}}}vvvv}vvv?|`````-|^++v????vvv>|>v?+```... <br></br>
.^+>>>>>>>>>>>>??vrzuuzv?>+|^+>???v}}????+~-`````-~|^|????vvvv}vvvvv??v>`. <br></br>
.>>>>>>>>>>>>>>>>>>>?vzxuuur>?}>}wzzzz^|||~--`````-~~-+????????????????v?>.<br></br>
.>>>>>>>>>>>>+++++>>>ruuuuuyuxxxrv^->>??|~~~-``+>>>>>>???????????????????v-<br></br>
.>>>>>>>>>>>>+^>vv?>zyuuuuuuuuxwu} .?^ru??|~---~??????????????????????????`<br></br>
.>>>>>>>>>>>>vzyyyuwyuuuxxurr}zxww>?vvvvxyw|~~~--+>>????????????vv~|??~-}}.<br></br>
.>>>>>>+>>>?zyyyyuwuuuzr}}rrww}rv}rrrr}vxxuv--~~^^+>?????????>.^xx+ -?. ?z|<br></br>
.vwzxxxwrrzyyyuxxwwyuzzuxxuuyz?}}rrrrrrvvxwz~|>>>+>>?????????`^ vxz->??+`}~<br></br>
-uuuuuuyyyyuxzzzxruuuuyuuuuuy}v}rrrr}vv}wx}y?`|+^>>>>????????~`~`z}???????`<br></br>
-uuuuuuuuxzzzzzzzwuuuuuuuuuuuv?vv}}>`..+uzwyv`^?+>>>>>????????>?|??????vv?`<br></br>
.?ruuxxzzzzzzzzzwruuuuuuuuuuuv}}vwur+|+ruu}zv^v?>?v>+>>??????vxuxxw???????`<br></br>
.+^?zzzzzzzzzzzzwruuuuuuuuuuu}rvrxz}xvwuuyw}>|~^?r}vv????????zyyuxw???????`<br></br>
.++^?zzzzzzzzxxuuwyyuuuuuuuuuvvvr}vrrrrrwxx>|++|`>?v}rwzzzz}vxyyuxv???????`<br></br>
.^^|?zzzzzxuuuuuuzwzxxxxzzwwwv>||^>?v?++v}>|+~~+v??v??vwxzwvvrzr??????????`<br></br>
.^^+>wxxuuxwxuuuwrv?>>?>????>+~-~-```````??~`^vrv?vv?vv?>|||~~~|^>>++>>>>>.<br></br>


            </div>,

            <div>

 .~|^||^+^^^^^^||||``^>?r}vvvvv}}v}rrrrrrrr}}vvvv?..|????>+++>>>+^~`.      <br></br>
.+?vvv?vv}vvvvvvvvv>-~vuzwzxxuuxxxxxxzzzzzzxxzzxzw}^+v}rrv?>????+~`.       <br></br>
`vrwrvvv}}vv}}vv}rv?|?yuuuuxzwwwwwwwwwwwwwwwwwwwwwwr>?}rr}v???>+~`........ <br></br>
`rww}?????????????>^?uyyuxwwwwwwwwwwwwwwwwwwwwwwwwwwv>v}rr}v??>^|~~||||||~.<br></br>
`}rr}v>^|~~~~~~---~>ruzzwwwwwwwwwwrrrrwwwwwwwwwwwwwww>?}}rr}??????????????`<br></br>
`v}}}v?>|`.......`^?xzwwwwwwwr}vvvvvvv}}}wwwwwwwwwwww?>v}}}}v?vr}vvvr}vv}}-<br></br>
.?vvvvv??+~`..  .|>>uwwwwww}vvvvvvvv}}}r}v}wwwwwwwwwwv+?vvvvv??vvv?vvvvvvv`<br></br>
.>????????>^~`.`^++|xzwwww}v}}}vvvvr}}}rrrv}wwwwwwwww?^>?????>^^+++++++++^.<br></br>
 ~+????????>>+>>>>~.?zwwwwv}}}rvv}??}w?|?}rvwwwwwwr}}?~|++++?+~........... <br></br>
 `|+>?????????vv?>-.`vrwww?>}w^?vv> wxv >}rvrwrr}}vv}+``~^^^+^~..     .... <br></br>
.++^^^+>>>??vvvv?>|-`|}}r}v~rw+?}}v>}rv?v}rvvvvvvvv}v~``-||^>+|-.     .... <br></br>
.~`+^^^^^^>vvvv?>++^|-+v}?v}vv?vvvvvvvv}rr}??v??}vv}>||~~||^+^|~`.  ....~+.<br></br>
 .`>>^^^^^^?vv?>++++++^+?vr}}}}vvvv}rrrrrr}?v???v}}?+?>^|||||||~~`....-+?v`<br></br>
 |?>>>^^^^^^>?>+++++>>>>>}}}}}vvvv}vv}rrrr}vv???}v^|+?>^|~|||~~~~-...-+vww~<br></br>
`v>+>>>++^^^^+>+++>>>>>>>r}}}}}}vv}}}v}rrr}vv???+`-~~>??+~~~~~~~~~`..-+v}}-<br></br>
.>>>>>>>>>++^^^>>>>>>>>>>?}r}}}}}vv}}}}rr}vv?^~`.`-~||??vv?>^~~~~~~`.`-|+>`<br></br>
.>>>>>>>>>??>>+^+++>>>>>>+?}}}}}}}}}rrr}}vvv|`````~^>>>????vvv>|~-+?-..```.<br></br>
.+>>>>>>>>?v>?rr}vv??>++++>>?}}}}}v??v}vvv?^`````-|^>+^??????vv}?|>?v+..^- <br></br>
.+>>>>>>>>>>>>??v}wxuxr?>>^|^+>>??vvv>???+~--````-~|^>+>???vvvvvvv?vvv?>??`<br></br>
.>>>>>>>>>>>>>>>>>>??wxxuuxv+v?>wwzzxz^|||~--``-~~~~~|>>????????????????vv~<br></br>
.>>>>>>>>>>>+++++>>>vuuuuuyyuwxw}v^~>>v?|~~~--``>????>>???????????????????`<br></br>
.>>>>>>>>>>>+^>???>ryuuuuuuuyuwzu? .>~ru?>~~~---->??>>????????????????????`<br></br>
.>>>>>>>>>>>?wuyyuwyuuuuuuzrrwxwx}+?vv}vxyz^~~~--`^+>???????????????????>v`<br></br>
.>>>>>>>>>>}yyyyywxuuuw}}rr}}rwwv}rrrr}vxuu}--|^++^>>??????????^?rw^.`?~ v^<br></br>
`vrwww}vv}xyyyuxwryuzwzwwxuuy}v}}rrrrr}vvurx^-+?>>>>>?????????` `xzr .?+.`-<br></br>
-uuyyyyyyyyuxzzz}xuuxuyuyuuuu?v}rrrrrrr}ruvuv``|++>>>>????????|  >uz>????^.<br></br>
-uuuuuuuuxzzzzzzruuuuuuuuuuuz?vvv}}?^~^?zu}yw`~>?+>>>>>????????^ .rv??????`<br></br>
.}uuuxxzzzzzzzzwwyuuuuuuuuuyw?}}}}x?..`?yu}xw|?r>>?>+>>>????????>>????????`<br></br>
.^vzzzzzzzzzzxzwwuuuuuuuuuuyw}r?}uurz?wyuyw}v+|+>w}}>?>>>???????vvvv?????>.<br></br>
.^^vzzzzzzzzzxxxwyuuuuuuuuuyzv}vzwv}w}wwxuuv>~~>|>v?rwwwr}}}vvvxuxxx??????`<br></br>
.^^rzzzzzzzxuuuuwuuyuuuuuxxzw???v?v}r}vvrw}^^++`|??????vwzzzzrwyyuxzv?????-<br></br>
.^>?zzzxxuuuuuuuwv}v}wwrrrrrr?--~~||||~-^?>^~`~?}??}???vv?>++^+>?vw?+>????`<br></br>


            </div>,

            <div>

 `~^^^^^+>+^++^^^^|``^>?r}vvvvv}}v}rrrrrrrr}}vvvv?..|????>+++>>>+^~`.      <br></br>
.>v}vvvv}}}v}vvvv}v>-~vuzwzxxuuxxxxxxzzzzzzxxzzxzw}^+v}rrv?>???>+~`.       <br></br>
`}rw}vvv}}vv}}vv}}v?~?yuuuuxzwwwwwwwwwwwwwwwwwwwwwwr>?}rr}v????+~`........ <br></br>
`rwr}?????????????>^>uyyuxwwwwwwwwwwwwwwwwwwwwwwwwwwv?v}rr}v??>+^|||^^^^^|.<br></br>
`}rr}v>^~~------``~>ruzzwwwwwwwwwwrrrrwwwwwwwwwwwwwww??}}rr}???vv???vv??vv`<br></br>
`v}}vv?>|`.......`+?xzwwwwwwwr}vvvvvvv}}}wwwwwwwwwwww??v}}}}v?v}}vvvr}vv}r-<br></br>
.?vvvv???+~`.. .`^>>uwwwwww}vvvvvvvv}}}r}v}wwwwwwwwww?+?vvvv???vv???vv??vv`<br></br>
.>????????>^~``-^>^|xzwwww}v}}}vvvvr}}}rrrv}wwwwwwwww?^>?????>||^^^^^^^^^^.<br></br>
 ~+????????>>>>?>+~.?zwwwwv}}}rvv}??}w?|?}rvwwwwwwr}}?~|^+^+?>~........... <br></br>
 `|+>????????vvv?+-.`vrwww?>}w^?vv> wxv >}rvrwrr}}vv}+``~|^|^^~`.     .... <br></br>
.++^^^+>>>??vvvv?+|-`|}}r}v~rw+?}}v>}rv?v}rvvvvvvvv}v~``-||^>+|-.     .... <br></br>
.~`+^^^^^^>vvvv?>++^|-+v}?v}vv?vvvvvvvv}rr}??v??}vv}>||~~||^+^|~`.  ....~>.<br></br>
 .`>>^^^^^^?vv?>++++++^+?vr}}}}vvvv}rrrrrr}?v???v}}?+?>^|||||||~~`....-+?v-<br></br>
 |?>>>^^^^^^>?>+++++>>>>>}}}}}vvvv}vv}rrrr}vv???}v^|+?>^|~|||~~~~~...`+vww~<br></br>
`v>+>>>++^^^^+>+++>>>>>>>r}}}}}}vv}}}v}rrr}vv???+`-~~>??+~~~~~~~~~`..`^?}}-<br></br>
.>>>>>>>>>++^^^>>>>>>>>>>?}r}}}}}vv}}}}rr}vv?^~`.`-~||??vv?>^~~~~~~`.``~++.<br></br>
.>>>>>>>>>??>>+^+++>>>>>>+?}}}}}}}}}rrr}}vvv|`````~^>>>????vvv>|~-+?-...``.<br></br>
.+>>>>>>>>?v>?rr}vv??>++++>>?}}}}}v??v}vvv?^`````-|^>+^??????vv}?|>?v+..^- <br></br>
.+>>>>>>>>>>>>??v}wxuxr?>>^|^+>>??vvv>???+~-`````-~|^>+>???vvvvvvv?vvv?>??`<br></br>
.>>>>>>>>>>>>>>>>>>??wxxuuxv+v?>wwzzxz^|||~--```~~~~~|>>????????????????vv~<br></br>
.>>>>>>>>>>>+++++>>>vuuuuuyyuwxw}v^~>>v?|~~~--``>????>>???????????????????`<br></br>
.>>>>>>>>>>>+^>???>ryuuuuuuuyuwzu? .>~ru?>~~~---->??>>????????????????????`<br></br>
.>>>>>>>>>>>?wuyyuwyuuuuuuzrrwxwx}+?vv}vxyz^~~~--`^+>???????????????????>v`<br></br>
.>>>>>>>>>>}yyyyywxuuuw}}rr}}rwwv}rrrr}vxuu}--|^+++>>??????????^?rw^.`?~ v^<br></br>
`vrwww}vv}xyyyuxwryuzwzwwxuuy}v}}rrrrr}vvurx^-+?>>>>>?????????` `xzr .?+.`-<br></br>
-uuyyyyyyyyuxzzz}xuuxuyuyuuuuvv}rrrrrrr}ruvuv``|++>>>>????????|  >uz>????^.<br></br>
-uuuuuuuuxzzzzzzruuuuuuuuuuuz?vvv}}?^~^?zu}yw`~>?+>>>>>????????^ .rv??????`<br></br>
.}uuuxxzzzzzzzzwwyuuuuuuuuuyw?}}}}x?..`?yu}xw|?r>>?>+>>>????????>>????????`<br></br>
.^vzzzzzzzzzzxzwwuuuuuuuuuuyw}r?}uurz?wyuywrv+|+>w}}>?>>>???????vvvv?????>.<br></br>
.^^vzzzzzzzzzxxxwyuuuuuuuuuyzv}vzwv}w}wwxuuv>~~>|>v?rwwwr}}}vvvxuxxx??????`<br></br>
.^^rzzzzzzzxuuuuwuuyuuuuuxxzw???v?v}r}vvrw}^^++`|??????vwzzzzrwyyuxzv?????`<br></br>
.^>?zzzxxuuuuuuuwv}v}rwrrrrrr?--~~||||~-^?>^~.~?}??}???vv?>++^+>?vw?+>????`<br></br>


            </div>,

            <div>

 -^+++++>>++++++++^``^>?r}vvvvv}}v}rrrrrrrr}}vvvv?..|?????++>>>>+^-..      <br></br>
.?v}vvvv}}}v}}vvv}v>-~vuzwzxxuuxxxxxxzzzzzzxxzzxzw}^+v}rr}?????>+~`..      <br></br>
`}ww}vvv}vvv}vvv}}v?~?yuuuuxzwwwwwwwwwwwwwwwwwwwwwwr>?}rr}v????+~`........ <br></br>
`rwr}?>???>>??>>>>+^?uyyuxwwwwwwwwwwwwwwwwwwwwwwwwwwv>v}rr}v??>+^^^^^^^^+^.<br></br>
`}rr}v>^~--```````~>ruzzwwwwwwwwwrrrrrwwwwwwwwwwwwwww??}}rr}???vvv??vv?vvv`<br></br>
`v}vvv?>|`..... .->?xzwwwwwwwr}vvvvvvv}}}wwwwwwwwwwww??v}}}}v?v}rvvv}}vv}r-<br></br>
.?vvvv???+~`.. .`^>>uwwwwww}vvvvvvvv}}}r}v}wwwwwwwwww?+?vvvv????v???vv??vv`<br></br>
.>????????>^~``~+>^|xwwwww}v}}}vvvv}}}}rrrv}wwwwwwwww?^>??>>?>|||||^^^||^|.<br></br>
 ~>????????>>>???+-.?zwwwwv}}}rvv}??}r?|?}rvwwwwwwr}}?~|^+^^?+~........... <br></br>
 `|+>????????vvv?+-.`vrwww?>}w^?vv> wxv >}rvrwrr}}vv}+``~|^|^^|`.     . .. <br></br>
.++^^^+>>>??vvvv?+|-`|}}r}v~rw+?}}v>}rv?v}rvvvvvvvv}v~``-||^>+|-.     .... <br></br>
.~`+^^^^^^>vvvv?>++^|-+v}?v}}v?vvvvvvvv}rr}??v??}vv}>||~~||^+^|~-.. ....~>`<br></br>
 .`>>^^^^^^?vv?>++++++^+?vr}}}}vvvv}rrrrrr}?v???v}}>+?>+|||||||~~`....-+?v-<br></br>
 |?>>>^^^^^^??>+++++>>>>>}}}}}vvvv}vv}rrrr}vv???}v^|+?>^|~|||~~~~~`..`^vrw~<br></br>
`v>+>>>++^^^^+>++>>>>>>>>r}}}}}}vv}r}v}rrr}vv???+`-~~>??+~~~~~~~~~-..`|?v}-<br></br>
.>>>>>>>>>++^^^>>>>>>>>>>?}r}}}}}vv}}}}rr}vv?^~`.`-~||??vv?>^~~~~~~`..`~^+.<br></br>
.>>>>>>>>>??>>+^+++>>>>>>+?}}}}}}}}}rr}}}vvv|`````~^>>>????vvv>|~-+?-...``.<br></br>
.+>>>>>>>>?v>?rr}vv??>+++++>?}}}}}v??vvvvv?^`````-|^>++??????v}}?|>?v+..^- <br></br>
.+>>>>>>>>>>>>??v}wxuxr?>+^|^+>>??vvv>???+~--````-~|^>+>???vvvvvvv?vvv?>??`<br></br>
.>>>>>>>>>>>>>>>>>>??rxxuuxv+v?+wwwzxz^|||~--```~~~|~|>>????????????????vv~<br></br>
.>>>>>>>>>>>+++++>>>vuuuuuyyuwxw}v^~+>v?|~~~--``>????>>???????????????????`<br></br>
.>>>>>>>>>>>+^>???>ryuuuuuuuyuwzu? .>~ru?>|~~---->??>>????????????????????`<br></br>
.>>>>>>>>>>>?wuyyuwyuuuuuuzrrwxwxr+?vv}vxyz^~~~--`^+>???????????????????>v`<br></br>
.>>>>>>>>>>}yyyyywxuuuw}}rr}}rwwv}rrrr}vxuu}--|^+++>>??????????^?rw|.`?~ v^<br></br>
`vrwww}vv}xyyyuxwryuzwzwwxuuy}v}}rrrrr}vvurx^-+?>>>>>?????????` `xzr .?+.`-<br></br>
-uuyyyyyyyyuxzzz}xuuxuyuyuuuu?v}rrrrrrr}ruvuv-`|++>>>>????????|  >uz>????^.<br></br>
-uuuuuuuuxzzzzzzruuuuuuuuuuuz?vvv}}?|~^?zu}yw`~>?+>>>>>????????^ .rv??????`<br></br>
.}uuuxxzzzzzzzzwwyuuuuuuuuuyw?}}}rx?..`?yu}xw|?w>>?>+>>>????????>>????????`<br></br>
.^vzzzzzzzzzzxzwwuuuuuuuuuuyw}rv}uurz?wyuywrv+|+>w}}>?>>>???????vvvv?????>.<br></br>
.^^vzzzzzzzzzxxxwyuuuuuuuuuyzv}vzwv}w}wwxuuv>~~>|+v?rwwwr}}}vv}xuxxx??????`<br></br>
.^^rzzwzzzzxuuuuwuuyuuuuuxxzw?????v}r}v?rw}^^++`|??????vwzzzzwwyyuxzv?????`<br></br>
.^>?zzzzxuuuuuuuwv}v}rwrrrrrr?--~~||||~-^?>+~.~?}??}?>?vv?>++^+>?vw?+>????`<br></br>


            </div>,

            <div>

 ~+>>++>>?>>>>+++++-.+}}vv}rrrrr}}}}}vvvvvv}}vv}}vv^->????>++>>>+|-..      <br></br>
.?}}vvv}r}}}}}vv}}v>^xuxuuuxzzwwwwwwwwwwwwwwwwwwzwww??}rr}v????>+~`..      <br></br>
`rww}vvvvvvvvvvvvvv>zyyyuzwwwwwwwwwwwwwwwwwwwwwwwwwww?vrrrv????+|-`..````` <br></br>
`rwrv?>>>>>>>>>>>+|?yuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv?}}}}v??>>++^+++++>+.<br></br>
`}r}}?>|-```````.`|zuwwwwwwwwwr}}vvvv}}rwwwwwwwwwwwwwr?v}}r}v??vvv?vvvvvvv-<br></br>
`v}vvv?>|-....  .~>xzwwwwwwr}vvvvvvv}v}}v}wwwwwwwwwwww??}}}}v??}rvvv}}vv}r-<br></br>
.?vvvv???+~`.. .-+>xzwwwww}vv}vvvvvr}}}rr}vrwwwwwwwwww??vvv???>???????????`<br></br>
.>????????>^~--|+>|rzwwwwrvr}rv}vv}}}rvv}rrvwwwwwwwwr}>^>?>>??|~~||||||~|~.<br></br>
 ~>????????>>>???+`~rwwww}v}rvvvvv~+zu^ v}rvrwwwwr}}v}^-|^^^>>|`.......... <br></br>
.||+>????????vvv?^`.>}rwwr~?z+~v}}-^xx?^v}r}}r}}}vvv}v-`-|||^^|`.     . .. <br></br>
.+^^^^++>???vvvv?+|--?}}}v?}wvv}rvvvvvvvvr}vvvvvvvvv}>```||^>>^~`.    .....<br></br>
 .++^^^^^?vvvvv?>++^|~?}vv}vvvvvvvvv}v}}rr}v?v??vvv}?^^|~||^^^|~-.. ....~>`<br></br>
 ~+>+^^^^^?vv??>++++++^>vr}}}rr}}}}}rrrrrr}vv???v}}?+>>+|~|||||~~-....-+?v-<br></br>
`?>>>+^^^^^???+++++>>>>>v}}}r}v}vv}vv}rrrr}vvv??vv+~+^???^~||~~~~~`..`|?rw~<br></br>
`+>>>>>+^^^^+>>++>>>>>>>vr}rr}}}vv}r}vrrrr}vv???^``~|~>??v??+~~~~~-`.`~>v}-<br></br>
.>>>>>>>>+++^^+>>>>>>>>>>vrrr}}}}vv}}}}rr}vv?|-`.`-~^^^????vvv?|-~~--~`-|^.<br></br>
.>>>>>>>>?v>>>^++++>>>>>>>v}rr}}}}r}}r}}}vvv^`````~^>?+>??????vrv+-`+?+..`.<br></br>
.>>>>>>>>?v>?rwr}vv?>++++++>v}}}}}vv?vvvvv?^`````-~^+>^+>??????vrwv^>?v?`. <br></br>
.>>>>>>>>>>>>??v}wxuuw?>>+|^^>>>?vv}v>>?>+~--`````-~|^~+>>??vvvvvvvvvvv}v^.<br></br>
.>>>>>>>>>>>>>>>>>??rxuuuuw?+v|?zwzzxz^||~~--```+>>++|~~????????????????vv~<br></br>
.>>>>>>>>>>+++++>>>vxuuuuuyyxzzr}v|^>?v?|~~~---`->??vv>???????????????????`<br></br>
.>>>>>>>>>>>^+>?>+vyuuuuuuuyyxwxz| `>|ru?+~~~-----+??>????????????????????`<br></br>
.>>>>>>>>>>?}xyyuwuuuuuuuurzwxxwu?|?v?}}zur^~~---`-+>>????????????????????`<br></br>
.>>>>>>>>>vuyyyyzxyuuxwrww}}}wzz}}rrrr}vuuyw~-+>>?>+>>?????????????v++??>~.<br></br>
`v}}v????ryyyyuxruuuwwrrzxuuuv}v}rrrrr}}vuwu?`|>>>>>>>?????????+`?uz? .?-  <br></br>
-uyyuuuuyyuuxzzrzuuzxuuyyuuyw?vrrrrrrrr}}u}xr-`~+^>>>>????????>  .xzu~-??~.<br></br>
-uuuuuyuuxzzzzz}uuuuuuuuuuuy}?v}}r}v???vzuvyx^+>?>>>>>>????????`  ^uw?????`<br></br>
-uuuuxxzzzzzzzzwyuuuuuuuuuuyv?vv}}w|  .?uuruu>>r}+>>>>>>????????~``v??????`<br></br>
.vxzzzzzzzzzzxwwyuuuuuuuuuuuv}rvwxy}?+vuuyw}w>>v??vr?>>+>???????v?????????`<br></br>
.>zzzzzzzzzzzxwwyuuuuuuuuuuu}}}vxxrrzvzxuuuvv|~~~vr}}wwr}vvvvv????????????`<br></br>
.+wzzzzzzzzxuuuwyuuuuuuuuuuur}v}rvvrrrrrwzuv^~?+^`>?vvv}wxxxxz??vwwzwv????`<br></br>
`>?zzzzzxuuuuuuzwzxxxuxxzzww}?||^>????>>}}?++>`.^vvv????v}rr}v??rwwwx}????`<br></br>


            </div>,

            <div>

.|>>>>>>??>>>>>>>>+-.+}}vv}rrrrr}}}r}vvvvvv}}vv}}vv^->????>+++>>+|-..      <br></br>
`v}rvvv}r}}}}}vv}}v+^xuxuuuxzzwwwwwwwwwwwwwwwwwwzwww??}rr}v????>+~`... ... <br></br>
                  `rwrvvvvvvvvvvvvvv?+zyyyuzwwwwwwwwwwwwwwwwwwwwwwwwwww?vrrrv????+|-```````` <br></br>
`rwrv?>>>++>>++++^~?yuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv?}}}}v???>>>++>>>>>>.<br></br>
`}r}v?>|-````....`^wuwwwwwwwwwr}}vvvv}}rwwwwwwwwwwwwwr?v}}r}v??v}vvvv}vvv}-<br></br>
`vvvvv?>|-...   .|>xzwwwwwwr}vvvvvvv}v}}}}wwwwwwwwwwww??}}}}v??}rvvv}}vvvr~<br></br>
.?vvvv???+~`....|>>xzwwwww}vv}vvvvvr}}}rr}vrwwwwwwwwww??vvv???>???????????`<br></br>
.>????????>^|-~^>+|rzwwwwrvr}rv}vv}}}rvv}rrvwwwwwwwwr}>^>?>>??^-~~~~~~~~~~.<br></br>
 ~>????????>>????^`~rwwww}v}rvvvvv~+zu^ v}rvrwwwwr}}v}^-|^^^>>|`.......... <br></br>
.||+>????????vvv?^`.>}rwwr~?z+~v}}-^xx?^v}r}}r}}}vvv}v-``|||^^|-.       .. <br></br>
.+^^^^++>???vvvv?+|-`?}}}v?}wvv}rvvvvvvvvr}vvvvvvvvv}>-``~|^>>^~`.   ......<br></br>
 .++^^^^^?vvvvv?+++^|~?}vv}vvvvvvvvv}v}rrr}v?v??vvv}?^^|~~||^^||~`. ...`->`<br></br>
 ~+>+^^^^^?vv??+++++++^>vr}}}rr}}}}}rrrrrr}vv???v}}?+>>+|~|||||~~-....-+?v-<br></br>
`?>>>+^^^^^??>+++++>>>>>v}}}r}v}vv}vv}rrrr}vvv??vv+~+^???^~|||~~~~`..`|?}w~<br></br>
`+>>>>>+^^^^+>>++>>>>>>>vr}rr}}}vv}r}vrrrr}vv???^``~|~+??v??+~~~~~-`..~+?v-<br></br>
.>>>>>>>>+++^^+>>>>>>>>>>vrrr}}}}vv}}}}rr}vv?|-`.`-~^^^????vvv?|-~~--~.-|^.<br></br>
.>>>>>>>>?v>>>^++++>>>>>>>v}rr}}}}r}}r}}}vvv^`````~^>?+>??????vrv+-`+?+..` <br></br>
.>>>>>>>>?v>?rwr}vv?>++++++>v}}}}}vv?vvvvv?^`````-~^+>^>>??????vrwv^>?v?`. <br></br>
.>>>>>>>>>>>>??v}wxuuw?>>+|^^>>>?vv}v>>?>+~--`````-~|^~+>>??vvvvvvvvvvv}v^.<br></br>
.>>>>>>>>>>>>>>>>>??rxuuuuw?+v|?zwzzxz^||~~--```+>>++|~~????????????????vv~<br></br>
.>>>>>>>>>>+++++>>>vxuuuuuyyxzzr}v|^>?v?|~~~---`->??vv>???????????????????`<br></br>
.>>>>>>>>>>>^+>?>+vyuuuuuuuyyxwxz| `>|ru?+~~~-----+??>????????????????????`<br></br>
.>>>>>>>>>>?}xyyuruuuuuuuurzwxxwu?|?v?}}zur^~~---`-+>>????????????????????`<br></br>
.>>>>>>>>>vuyyyyzxyuuxwrww}}}wzz}}rrrr}vuuyw~-+>>?>+>>?????????????v++??>~.<br></br>
`v}}v????ryyyyuxruuuwwrrzxuuuv}}}rrrrr}}vuwu?`|>>>>>>>?????????+`?uz? .?-  <br></br>
-uyyuuuuyyuuxzzrzuuzxuuyyuuyw?vrrrrrrrr}}u}xr-`~+^>>>>????????>  .xzu~-??~.<br></br>
-uuuuuyuuxzzzzz}uuuuuuuuuuuy}?v}}r}v???vzuvyx^+>?>>>>>>????????`  ^uw?????`<br></br>
-uuuuxxzzzzzzzzwyuuuuuuuuuuyv?vv}}w|  .?uuruu>>r}+>>>>>>????????~``v??????`<br></br>
.vxzzzzzzzzzzxwwyuuuuuuuuuuuv}rvwxy}?+vuuyw}w>>v??vr?>>+>???????v?????????`<br></br>
.>zzzzzzzzzzzxwwyuuuuuuuuuuu}}}vxxrrzvzxuuuvv|~~~vr}}wwr}vvvvv????????????`<br></br>
.+wzzzzzzzzxuuuwyuuuuuuuuuuur}v}rvvrrrrrwzxv^~?+^`>?vvv}wxxxxz??vwwzwv????`<br></br>
`>?zzzzzxuuuuuuzwzxxxuxxzzww}?||^>????>>}}?++>`.^vvv????v}rr}v??rwwwx}????`<br></br>


            </div>,

            <div>

.^>?>>>??????>>>>>+-.+}}vv}rrrrr}}}r}vvvvvv}}vv}}vv^->????>+++>>+|`..      <br></br>
`vrrvvv}r}}}}}vv}}v>^xuxuuuxzzwwwwwwwwwwwwwwwwwzzwww??}rr}v?????+~`....... <br></br>
`rwrv??vvv?vvv?vvv?+zyyyuzwwwwwwwwwwwwwwwwwwwwwwwwwww?vrrrv????>|-````-`-` <br></br>
`rwrv>++++^+++^^^|~?yuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv?}}}}}v??>>>>>>?>>>>.<br></br>
`}}}v?>|-`.......`^wuwwwwwwwwwr}}vvvv}}rwwwwwwwwwwwwwr?v}}rrv??v}vvvv}vvv}~<br></br>
`vvvvv?>|-...   .^>xzwwwwwwr}vvvvvvv}v}}}}wwwwwwwwwwww??}}}}v??v}vvvv}vvv}~<br></br>
.?v??????+~`...`|>>xzwwwww}vv}vvvvvr}}}rr}}wwwwwwwwwww??vvv???>>??????????`<br></br>
.>????????>^|~|+>+|rzwwwwrvr}rv}}v}}}rvv}rrvwwwwwwwwr}>^>?>>??^---------~-.<br></br>
 ~>????????>?????^`|wwwww}v}rvvvvv~+zu^ v}rvrwwwwr}}v}^-|^^^>>^`.......... <br></br>
.|^+>???????vvvv?^`.>}rwwr~?z+~v}}-^zx?^v}r}}r}}}vvv}v-``|||^^|-.       .. <br></br>
.++^^^++>??vvvv?>^|-`?}}}v?}wvv}rvvvvvvvvr}vvvvvvvvv}>-``~|^>>^~`.    ...`.<br></br>
 .++^^^^^?vvvv?>+++^|~?}vv}vvvvvvvvvvv}}rr}v?v??vvv}?^^|~~||^^||~`. ...`->`<br></br>
 ~+>+^^^^^?vv?>+++++++^>vr}}}rr}}}}}rrrrrr}vv???v}}?+>>+|~|||||~~~....`^?v-<br></br>
`?>>>+^^^^^??>+++++>>>>>v}}}r}v}vv}vv}rrrr}vvv??vv+~+^???^~~|~~~~~-..`~?}w~<br></br>
`+>>>>>+^^^^+>>+>>>>>>>>vr}rr}}}vv}r}vrrrr}vv???^``~|~>??v??+~~~~~~`..-^?v-<br></br>
.>>>>>>>>+++^^+>>>>>>>>>>vrrrr}}}vv}}}}rr}vv?|-`.`-~^^^????vvv?|-~~--~.`~|.<br></br>
.>>>>>>>>?v>>>^++++>>>>>>>v}rrrr}}r}}r}}}vvv^`````~^>?+>??????vrv+-`+?+..` <br></br>
.>>>>>>>>?v>?rwr}vv?>++++++>v}}}}}vv?vvvvv?^`````-~^+>^>>??????vrwv^>?v?`. <br></br>
.>>>>>>>>>>>>??v}wxuuw?>>+|^^>>>?vv}v>>?>+~--`````-~|^~+>>??vvvvvvvvvvv}v^.<br></br>
.>>>>>>>>>>>>>>>>>??rxuuuuw?+v|?zwwzxz^||~~--```+>>++|~~????????????????vv~<br></br>
.>>>>>>>>>>+++++>>>vxyuuuuyyxzzr}v|^>?v?|~~~---`->??vv>???????????????????`<br></br>
.>>>>>>>>>>>^+>?>+vyuuuuuuuyyxwxz| `?|ru?+~~~-----+??>????????????????????`<br></br>
.>>>>>>>>>>?}xyyuruuuuuuuurzwxxwu?|?v?}}zur^~~---`-+>>????????????????????`<br></br>
.>>>>>>>>>vuyyyyxxyuuxwrww}}}wzz}}rrrr}vuuyw~-+>>?>+>>?????????????v++??>~.<br></br>
`v}}v????ryyyyuzruuuwwrrzxuuuv}}}rrrrr}}}uwu?`|>>>>>>>?????????+.?uz? .?-  <br></br>
-uyyuuuuyyuuxzzrzuuzxuuyyuuyw?vrrrrrrrr}}u}xr-`~+^>>>>????????>  .zzu~-??~.<br></br>
-uuuuuyuuxzzzzz}uuuuuuuuuuuy}vv}}r}v???vzxvyx^+>?>>>>>>????????`  ^uw?????`<br></br>
-uuuuxxzzzzzzzzwyuuuuuuuuuuyv?vv}}w|  .?yuruu>>r}+>>>>>>????????~`-v??????`<br></br>
.vxzzzzzzzzzzxwwyuuuuuuuuuuuv}rvwxy}?+vuuyw}w>>v??vr?>>+>???????v?????????`<br></br>
.>zzzzzzzzzzzxwwyuuuuuuuuuuu}}}vxxrrzvzxuuuvv|~~~vr}}wwr}vvvvv????????????`<br></br>
 +wzzzzzzzzxuuuwyuuuuuuuuuuur}v}rvvrrrrrwzuv^~?+^`>?vvv}wxxxxz??vrwzwv????`<br></br>
`>?zzzzzxuuuuuuzwzxxxuxxzzww}?||^>????>>v}?+^>`.^vvv????v}rr}v??rwwwx}????`<br></br>


            </div>,

            <div>

.+??>>>???????>>??>~`vwrwwwwwwwwwwr}}}}}}}}vvvvvvvvvvvv???>+++>>+|`..      <br></br>
`}rrvvv}}}}}}}vvr}v^vyyyyyuuuuxxxuuuuuuxxxxxxxzwzzwwwwwrvrv?????+~`....... <br></br>
`rw}v??vv??vv??vvv>?yyuxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}v???>^~-`------.<br></br>
`rr}v>++^^^^^^|||~~wyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvr}v?????>>??????`<br></br>
`}}}v?>|``.......`>yxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}rv??v}}vvv}}vv}~<br></br>
`vvvvv?>|-...  .`^vyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vvv??v}vvvv}vvv}~<br></br>
.?v??????+|`...`^+?uwwwwwwwwwwrr}}}vvvv}}rrwwwwwwwwwwwwww}????>+???>???>??`<br></br>
.>????????>+|~^++++rwwwwwwwwwr}r}}}vvvvvvvv}rrwwwwwwwwwwwv+>>?+-`---------.<br></br>
.|>??????????????|-v}rwwwwwwwv}r}}}}vvvvvv}v}}}wwwwwwwwww?|^+>^-.......... <br></br>
 `^>????????vvvv?|`>}}}rwwwwrvrr}}}}vvvvv}}}rr}wwwwwwwr}v^||^^|~`.      .. <br></br>
 `~^>??????vvvv?>^|~v}v}}rww}}rwwrrrv}}vrr}}rr}wwwwrr}v}?-|^>>+|-.. .   .`.<br></br>
.~|>??????vvvv?>+++^+v}vvvvvvr?|rxv?vv?vvvwr}}v}}}}}vv}v|~|||^||~`..>>^~~>`<br></br>
.^^v????vvvvv?>++++++>v}v???vz`.wu^~r}}? ?xv`rv}vvvv}}?^^|||||||~~`.+??vvv-<br></br>
.^^+????vvvv?>+++++>>>>??v?v}v??rr?v}rv?^}w?+r??vv}}v>^+|~~|||~~~~-.|????v-<br></br>
.>>^^+>?????>+++>>>>>>>>>?vrr}}vvvvvvvvv}vvvr}v?v}v>||^|~--~||~~~~~`~>????`<br></br>
.>>>>^^^+>>>>>>>>>>>>>>>>?}rrrrrrrr}v}}}r}}rrr}??+~~^++^|---~~~~~~~-->>???`<br></br>
.>}r>>?>+^^^+>>>>>>>>>>>>+vrrrrrv}wwrww}}}}rrrr^``~^>??+|~--~~~~~~~~-+>>??`<br></br>
.>rz?>}xwv??++++>>>>>+++++?}rrr}?????vv?v}}rrrr~`-~|++++|~-`~~~~~~~~-+>>>?`<br></br>
.>>>>>?vrzuuz}v>+++++?vv?>+?}}r}rwrrv}}}}rr}rr?``-~||||||~-`^??>+^~-`|>?vv-<br></br>
.>>>>>>>>>?vzyyx}?+?zuyyyur?vvv}}}r}?vvvv?>?}?```-~~~~~~~~--`+??????^?vvvv`<br></br>
.>++++>>>>>>>?wyywryyuuuuuyuw}v?vvv?}wwzxuz|~````----~~~----`->????>??????`<br></br>
.>++>>+^++>>>>>?rxyuuuuuuuuuuzzr????v}?>vzx}vv?|``-`^~----````~>>>>???????`<br></br>
.vwxuuxw}?>^>>>>ryuuuuxxxuzwuuzuzv?v?` `++xyyyyx^`->r^~-```----^>>>rv?????`<br></br>
-uyyyyyyyxr?^+>}yuuxwrrr}wrvrzzwuxr??~^>>>yuuuuyz~?}vvvv>>?????>>>>?}v????`<br></br>
-uyyyyyyyyxrrwzuuuuuuuuuxxxuuux}}zw}}rrrr}rxuuuuyvv}vvvrv>>??>>>>>>???vv??`<br></br>
-uyyyyuuxxxuuzzyuuuuuuuuuuuuuy}?v}}rrrrrrvvxxyxuz}vv}}r}|+>>>+>>>>>?????v?`<br></br>
-uyuuuuuuuxzxwxuuuuuuuuuuuuuux?vvrrrrrrr}}vxwuwzw?vv}r}w-`^>+>>>>>>??????v-<br></br>
`xxxuuuxxxxxxwuuuuuuuuuuuuuuyr?vv}rrrv}rrvwx}}vw}?vvvvzu^`.~>>>>>>>???????-<br></br>
`xxxxxxzxxxxxzuuuuuuuuuuuuuuuv}}}vr?-`-+vwyuv}vr?vvvv}ww>|+^>>>>>>>???????`<br></br>
`zxxxxxxuuxxzzuuuuuuuuuuuuuuuvrrvry? . .ryuywvrr?vvv?}rr>>>>>>>>>>>>??????`<br></br>
`zxxxxxxxzxuuzyuuuuuuuuuuuuuuv}}vuyz?~+vuuuuy}}rvvvv?}rv+>>+>>>>>>>>??????`<br></br>
`zxxxzzxuuuuuzwxxuuuuuuuuuuuzv}vwuzwv?uxxxxxxxv}}?v?v}}?^+?>++++>>>>>?????`<br></br>


            </div>,

            <div>

.>???>????????>???>~`vwwwwwwwwwwww}vvvvvvvvvvvvvvvvvvvvvv??+++>>+|`..      <br></br>
`rw}vvvr}}}}rvv}r}v^vyyyyyyuuuuuuyyyyyuuuuuuuuxzzxwwwwwww}v?????+~`....... <br></br>
`ww}??????????????>?yuzwzzwwwwwwwwwzxzzzzzzzzzzzwwwwwwwwwwvv???>^~--~~~~~~.<br></br>
`rr}?>^^||||||~~~-^xyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv?????????????`<br></br>
`}}}v?+~`........-vyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv}??vr}vvvr}vv}~<br></br>
`vvvvv?>^-...  .-+}yzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?v??v}vvvv}vvv}-<br></br>
`?v??????+|-...~^+?xzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???>+>>>>>>>>>>.<br></br>
.?????????>+^|+>+^?}rwwwwwwwwwwr}}}}}}}rrrwwwwwwwwwwwwwwwwr+>?+-`````````` <br></br>
.|>???????????v?>~+}v}wwwwwwwwwv}}}}vv?vvvvvvv}}rwwwwwwwww}|+>^~.......... <br></br>
 `^>????????vvvv?|-v}v}}rwwwww}}}}}}}}vvvvvvvvv}vwwwwwwwww?|^+^~`.      .. <br></br>
 `~^>??????vvvv?>^|+}vvv}}rwwrvrr}}}}rvvvvvv}}}r}wwwwwwwrv||+>+|~..  ....`.<br></br>
.-^>??????vvvv?>+++|?}vv}vvv}v}}rwrrrrvvvv?}}}rrvwwwrr}}}+~|||||~-.....`->`<br></br>
.?vv????vvvvv?>++++++?}}v????v}r}~>zz?}?v?vvrr}v?}}}vv}}>~|||||~~~`...`^?}~<br></br>
`}wr????vvvv?>++++>>>>>v?vv??}}x+ vxz ?vvr||zv.vv}vvv}v+~~~|||~~~~-`..->vr~<br></br>
.+vv?>>?????>+++>>>>>>>>>v??v}}vv>}rv>}}v}|?z?+}vv}}v?|~~--~|~~~~~~-..`|>?`<br></br>
.>^|^^^^+>>>>>>>>>>>>>>>>>?v}}r}vvvv?v}}}?vvvv}vv}v?+++^|---~~~~~~~~`..`-~.<br></br>
.>???+^|~|^++>>>>>>>>>>>>>>vv}rrrrrr}vvvv}r}}}rr>|~|>??+|~--~~~~~~~~-`.....<br></br>
`?>zyuz}?+^|^+>>>>>>+++++>>vvrrrrrv}wwrrr}}}}rrr|`~|+++^|~--~~~~~~~+|~`... <br></br>
.>>?rxyyyuwv>+^^+>++?v}}?>+?v}rrrv???vv}wrrw}}r}~--?+>?vv+---~~~~~->????+|.<br></br>
.>>>>??vwuyyu}?+^|?wuyyyyur?vv}r}vv???>????vrw}>`-->?vvv}r>`-~~~~~~->?????`<br></br>
.>>>>>>>>?vzyyu}>vuyuuuuuuyx??vv}rrwrv>}rrrv>+>||-``^v}r}r}``-~~~~~-|>>>?>.<br></br>
.^^^+>>>>>>>vzyxwyuuuuuuuuuyuvvvvvv}}}vwwwzuuv>uuz?|v}}rrrv^`-~~~~~~-^>++>.<br></br>
`v??>+++>>>>>>?wyuuuuxzzzxuzyx}rv??????>~~?wzwuyuyy}v}r}rrvr``---~~~|~^+>>.<br></br>
-yyuzv?>^+>>>?vuuuuzwwww}}rvrxr}rrv?vv^  |^>xuuuuuuvv}}}}vvy+^>>?????>>>>>.<br></br>
-uyyyuwv?+?}wrxuuuuuuyyuxxxxxxxzr}rv?v???v^}yxuuuuwxv?vvv?zyv>??????>>>>>>.<br></br>
-uyyyuurwxxxzwyuuuuuuuuuuuuuuuyv?vv}}rrrrr}}wwyxzuwu?vvv?}uuw-+>>>>+>>>>>>.<br></br>
-uxxxxxxxzzxrxuuuuuuuuuuuuuuuyw?v}rrrrrrrrv?rryrwwxz?vvvvzwzz``^>>++>>>>>>.<br></br>
`zxxxzxxxxxxruuuuuuuuuuuuuuuuuv?vv}rrrrrr}}}z}wvw}wv>vv?rrrrr-``~++>>>>>>>.<br></br>
`zxzxxxxxzzzryuuuuuuuuuuuuuuuxvvv?r}v?>vr}}xzvvrrv}r???}rrrr}``--~+>>>>>>>.<br></br>
`zzxzzzxxxzzwyuuuuuuuuuuuuuuyr}rrvyv....|rxyu}vrrv}r}}rrrrrrv|+>>+>>>>>>>>.<br></br>
`zxzzzzzxuxr}yuuuuuuuuuuuuuuy}}rvwy? ...vyuuuuvrrv}rrrrrrrrr>>>>>+>>>>>>>>.<br></br>
-xxzzxuuuuxvv?zxuuuuuuuuuuuuuv}}vxxw+.-^xxxxxxwv}}}}}}}}}}}>^+>++^++++++>+.<br></br>


            </div>,

            <div>

.>????????????>???>~`vwwwwwwwwwwww}vvvvvvvvvvvvvvvvvvvvvv??+++>>+|-..      <br></br>
`rw}vvvr}}v}}vvv}}v^vyyyyyyuuuuuuyyyyyuuuuuuuuxzwzwwwwwww}v?????+~`....... <br></br>
`rw}??????????????+?yuzwzzwwwwwwwwwzxzzzzzzzzzzzwwwwwwwwwwvv???>^~~~~~|||~.<br></br>
`rr}?>^||~~|~~~~~-|xyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv?????????????`<br></br>
`}}}v?>|`........-vyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv}??vr}vvvr}vv}~<br></br>
`vvvvv?>^-`..  .-+}yzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww?v??vvvvvvvvvvv-<br></br>
`?v??????+|-`.`~^+?xzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???>^>>>>>>>>>>.<br></br>
.?????????>+^^+>+^?}rwwwwwwwwwwr}}}}}}}rrrwwwwwwwwwwwwwwwwr+>?+-`````````` <br></br>
.|>???????????v??~+}v}wwwwwwwwwv}}}}vv?vvvvvvv}}rwwwwwwwww}|+>^~.......... <br></br>
 `^>????????vvvv?|-v}v}}rwwwww}}}}}}}}vvvvvvvvv}vwwwwwwwww?|^+^~`.     ... <br></br>
 `~+???????vvvv?>+|+}vvv}}rwwrvrr}}}}rvvvvvv}}}r}wwwwwwwrv||+>+|~`.  ....`.<br></br>
.~+>?????vvvvv?>+++|?}vv}vvv}v}}rwrrrrvvvv?}}}rrvwwwrr}}}+~|||||~-.....`->`<br></br>
.vvv????vvvvv?>++++++?}}v????v}r}~>zz?}?v?vvrr}v?}}}vv}}>~|||||~~~`...`|?}~<br></br>
`}wr????vvvv?>++++>>>>>v?vv??}}x+ vxz ?vvr||zv.vv}vvv}v+~~~|||~~~~~`..`+vr~<br></br>
.+vv?>>?????>+++>>>>>>>>>v??v}}vv>}rv>}}v}|?z?+}vv}}v?|~~--~|~~~~~~-`.`~+?`<br></br>
.>^|^^^^+>>>>>>>>>>>>>>>>>?vv}r}vvvv?vv}}?vvvv}vv}v?+++^|---~~~~~~~~-..``~.<br></br>
.>???>^|~|^++>>>>>>>>>>>>>>vv}rrrrrr}vvvv}r}}}rr>|~|>??+|~--~~~~~~~~~`.... <br></br>
`?>zyuz}?+^|^+>>>>>>+++++>>vvrrrrrvvwwrrr}}}}rrr|`~|+++^|~---~~~~~~+|~`... <br></br>
.>>?rxyyyuwv>+^^+>++?v}}?>+?v}rrrv???vv}wrrw}}r}~--?+>?vv+---~~~~~->????+|.<br></br>
.>>>>??vwuyyx}?+^|?wuyyyyur?vv}r}vv???>????vrw}>`-->?vvv}r>`-~~~~~~->?????`<br></br>
.>>>>>>>>?vzyyu}>vuyuuuuuuyx??vv}rrwrv>}rrrv>+>||-``^v}r}r}``-~~~~~-|>>>?>.<br></br>
.|^^+>>>>>>>vzyxwyuuuuuuuuuyuvvvvvv}}}vwwwzuuv>uuz?|v}}rrrv^`-~~~~~~-^>++>.<br></br>
`v??>+++>>>>>>?wyuuuuxzzzxuzyx}rv??????>~~?wzwxyuyy}v}r}rrvr``---~~~|~^+>>.<br></br>
-yyuzv?>^+>>>?vuuuuzwwww}}rvrxr}rrv?vv^  |^>xuuuuuuvv}}}}vvy+^>>?????>>>>>.<br></br>
-uyyyurv?+?}wrxuuuuuuyyuxxxxxxxzr}r}?v???v^}yxuuuuwxv?vvv?zyv>??????>>>>>>.<br></br>
-uyyyuurwxxxzwyuuuuuuuuuuuuuuuyv?vv}}rrrrr}}wwyxzuwu?vvv?}uuw-+>>>>+>>>>>>.<br></br>
-uxxxxxxxzzxrxuuuuuuuuuuuuuuuyw?v}rrrrrrrrv?rryrwwxz?vvvvzwzz``^>>++>>>>>>.<br></br>
`zxxxzxzxzxxruuuuuuuuuuuuuuuuuv?vv}rrrrrr}}}z}wvwvwv>vv?rrrrr-``~++>>>>>>>.<br></br>
`zxzxxxxxzzzryuuuuuuuuuuuuuuuxvvv?r}v?>vr}}xzvvrrv}r???}rrrr}``--~+>>>>>>>.<br></br>
`zzxzzzxxxzzwyuuuuuuuuuuuuuuyr}rrvyv....|rxyu}vrrv}}}}rr}r}rv|+>>+>>>>>>>>.<br></br>
`zxzzzzzxuxr}yuuuuuuuuuuuuuuy}}rvwy? ...vyuuuuv}rv}rrrrrrrrr>>>>>+>>>>>>>>.<br></br>
-xxzzxuuuuxvv?zxuuuuuuuuuuuuuv}}vxxw+.-^xxxxxxwv}}}}}}}}}}}>^+>++^>+++++>+.<br></br>


            </div>,

            <div>

.?????????????????>-+wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvv??>+++>+|`..      <br></br>
`rw}vvv}}vv}}vvv}v?>uyyuuuyyyyyyyyyyuuuuuxxxxxxzwzzwwwwwwwvv????+~`....... <br></br>
`rrv?????????????>+wyuzwwwwwwwwwwzzxuuuxxxxxxxxzwzwwwwwwwwrvv??>^|~~||||^|.<br></br>
`rr}?>^|~-~~~---``?yyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}????????vv???`<br></br>
`}}vv?>~`........|ryxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvv?vr}vvvrrvv}~<br></br>
`vvvvv?>^-`..  .|+}yzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?v??vvv?vvvvvv-<br></br>
`?v??????+|-`.`|+^?wzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}>?>^+++++>>+++.<br></br>
.?????????>+^^>>+|vvrwwwwwwwwwwwr}}rrwwwwwwwwwwwwwwwwwwwwwwv^?>~`.```````` <br></br>
.^>??????????vv?>-?}v}rwwwwwwwwrv}}}}vvv}}rrwwwwwwwwwwwwwww?|+^~.......... <br></br>
 `^>????????vvv?>~^}}v}}wwwwwwwv}}}}vvv?v??vvvv}}vwwwwwwwwr^^+^|`.     ..  <br></br>
 `~+??????vvvvv?+^~?}vvv}}rwww}}r}}}}}rvvvvvvvvvrvwwwwwwww?|+>+|~`.  ......<br></br>
.|>>?????vvvvv?>++^^v}vvvvvv}}vrr}rr}}r}vvvvv}}}rvwwwwwrrv~|||||~-.....`->`<br></br>
`v}v????vvvv??>++++++v}vv???v?}r}zv?wrrvvvv?}rrw}vrr}}}}?~~||||~~~-...`|?v~<br></br>
`ww}????vvv??+++++>>>>v}??v???}vx> |xz~?vv?}>r}>vv}vv}}?|~~~||~~~~~`..`^?r~<br></br>
.vvv?>>????>>++>>>>>>>>??v??vv}}w?.vzr.?}vr~|x? }v}}v?+~~--~~|~~~~~~`.`-+?`<br></br>
.^|^^^^^+>>>>>>>>>>>>>>>+?v?vv}rvv?vvv?}}v??vr?vv}v?++>^|---~~~~~~~~-`.``-.<br></br>
.vv?>^|~~|^+>>>>>>>>>>>>>>?vvv}rr}}}}vvvvv?vvvvr?+~|+?v?+~--~~~~~~~~~`.... <br></br>
.vyyuz}?+|||^+>>>>>>+++++>+vvvrrrrr}rr}vvv}rrrrr?`~+?}v}r?~`-~~~~~~~~-^+^~.<br></br>
.>}xyyyyxr?+^|^++>+>?rr}?>^?vv}rrrv?vrwwwr}r}}}r?`^^v}}}}r?^`~~~~~~~~~~???`<br></br>
.>>??}zuyyyzv>+^|^vzyyyyyxv?vvvrr????????vvrwz}}+``+}rrr}}vr>`~~~~~~~~-^>>.<br></br>
.>>>>>>?vzyyyzv+>wyyuuuuuuyw?vvv}vv????^?vv?>>v?^+-?rr}r}rv}z|-~~~~~~~~->>.<br></br>
.+>>>>>>>>?ruyuvxyuuuuuuuuuyz?vvvv}r}}v?wwwxuz>^uy}vrrrrrrvvyv`~~~~~~~~-~+.<br></br>
.++^+>>>>>>>?rruyuuuxzwwwzuzyrvvvvvvvvv?v??}xuzvyuuwvv}}}v?zuu|----~~~|^|+.<br></br>
`wvv?++>>>>>>>wyuuuzzzzz}vrvwx}r}v?????+. `+?zzyuuyzwv?vv?ryuyv^+>???????>.<br></br>
-yurvv>^+?vrzryuuuuuyuyuxzxxxxxxwr}v???++^?~}uuuuxuwz}?v?}uuuyw+????????>>.<br></br>
-uyyrv}rzxxxrxuuuuuuuuuuuuuuuuyz?vvrvv}rrr}?z}uyzwuww}??}yyxwxx`~>>>>>+>>>.<br></br>
-xxxxxxxxzzz}uuuuuuuuuuuuuuuuuuvvvr}rrrr}r}vvvxu}rzzwv?ryuzrrwu~.~>>>++>>>.<br></br>
`xxxxzxxxxxwwyuuuuuuuuuuuuuuuyr?vv}rrrrr}}rv}}zw}r}wuzzyxwrrrwx-```^>+>>>>.<br></br>
`zxxxzxzzzxrxuuuuuuuuuuuuuuuuuvvvvv}r}v}rrr}x}}vrr}vxyuxrrrr}wr`````|+>>>>.<br></br>
`zxzzzzxzzx}uuuuuuuuuuuuuuuuyzvrrvrr-``~+v}uyw?rrr}vwxxr}rr}r}~`~+>>+>>>>>.<br></br>
`zxxzzzxxzwvzyuuuuuuuuuuuuuuyr}r}vy>....|zuyuyr}rrrv}rr}rrrrv>+>>>>>+>>>>>.<br></br>
`zzxxuuyzvv^+}zxuuuuuuuuuuuuuv}}vwu>....}uxxxxx?v}}}v}}}}}}?++>>+++^^>++>+.<br></br>


            </div>,

            <div>

.?v????v??????????>-+wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvv??>+++>+|`..      <br></br>
`rw}vvv}vvv}}vvv}v?>uyyuuuyyyyyyyyyyuuuuuxxxxxxzwzzwwwwwwwvv????+~`....... <br></br>
`rrv?>???>????>??>^wyuzwwwwwwwwwwzzxuuuxxxxxxxxzwzwwwwwwwwrvv??>+^||^^^^^^.<br></br>
`rrv?>|~-----`````?yyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}v??vv???vv??v`<br></br>
`v}vv?+|`........^ryxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvv?v}rvvv}rvv}~<br></br>
`vvvv??>^-`.. ..|+}yzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?v??vvv??vvv?v-<br></br>
.????????+^-``-^+^vwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}>?>^^^+^^++^+^.<br></br>
.?????????>>++>>+|vvrwwwwwwwwwwwr}}rrwwwwwwwwwwwwwwwwwwwwwwv^?>~`......... <br></br>
.^???????????vv?>-?}v}rwwwwwwwwrv}}}}vvv}}rrwwwwwwwwwwwwwww?|^^~`. ....... <br></br>
 -^>???????vvvv?+~^}vv}}wwwwwwwv}}}}vvv?v??vvvv}vvwwwwwwwwr^^>+|-.     ... <br></br>
 `|>??????vvvv??+^|?}vvv}}rwww}}r}}}}}rvvvvvvvvvrvwwwwwwww?|^>+|~`.   .....<br></br>
.^>??????vvvv?>+++^^v}vvvvvv}}vrr}rr}}r}vvvvv}}}rvwwwwwrrv~||||||~`....`->`<br></br>
`v}v???vvvvv?>+++++++v}vv???v?}r}zv?wrrvvvv?}rrw}vrr}}}}?~~||||~~~-...`~?v~<br></br>
`ww}?>?vvvv?>++++>>>>>v}??v???}vx> |xz~?vv?}>r}>vv}vv}v?|~~~||~~~~~-..`|?}~<br></br>
.vv?>>>>???>+++>>>>>>>>??v??vv}}w?.vzr.?}vr~|x? }v}}v?+~~---~~~~~~~~`.`-^>`<br></br>
.^||^||^+>>>>>>>>>>>>>>>+?v?vv}rvv?vvv?}}v??vr?vv}v?++>+|~--~~~~~~~~-`..`-.<br></br>
.vv?>^|~~|^+>>>>>>>>>>>>>>?vvv}rr}}}}vvvvv?vvvvr?+~|+?v?+~--~~~~~~~~~-.... <br></br>
.vyyuz}?+|||++>>>>>>+++++>+vvvrrrrr}rr}vvv}rrrrr?`~+?}v}r?~`-~~~~~~~~-^+^~.<br></br>
.>}xyyyyxr?+^|^++>+>?rr}?>^?vv}rrrv?vrwwwr}r}}}r?`^^v}}}}r?^`~~~~~~~~~~???`<br></br>
.>>??}zuyyyzv>+^|^vzyyyyyxv?vvvrr????????vvrwz}}+``+}r}r}}vr>`~~~~~~~~-^>>.<br></br>
.>>>>>>?vzyyyzv+>wyyuuuuuuyw?vvv}vv????^?vv?>>v?^+-?rr}r}rv}z|-~~~~~~~~->>.<br></br>
.+>>>>>>>>?ruyuvxyuuuuuuuuuyz?vvvv}r}}v?wwwxuz>^uy}vrrrrrrvvyv`~~~~~~~~-~+.<br></br>
.++^+>>>>>>>?rruyuuuxzwwwzuzyrvvvvvvvvv?v??}xuzvyuuwvv}}}v?zuu|----~~~|^|+.<br></br>
`wvv?++>>>>>>>wyuuuzzzzz}vrvwx}r}v?????+. `+?zzyuuyzwv?vv?ryuyv^+>???????>.<br></br>
-yurvv>^+?vrzryuuuuuyuyuxzxxxxxxwr}v???++^?~}uuuuxuwz}?v?}uuuyw+????????>>.<br></br>
-uyyrv}rzxxxrxuuuuuuuuuuuuuuuuyz?vvrvv}rrr}?z}uyzwuww}??}yyxwxx`~>>>>>+>>>.<br></br>
-xxxxxxxxzzz}uuuuuuuuuuuuuuuuuuvvvr}rrr}}r}vvvxu}rzzwv?ryuzrrwu~.~>>>++>>>.<br></br>
`xxxxzxxxxxwwyuuuuuuuuuuuuuuuyr?vv}rrrrr}}rv}}zw}r}wuzzyxwrrrwx-```^>+>>>>.<br></br>
`zxxxzxzzzxrxuuuuuuuuuuuuuuuuuvvvvv}r}v}rrr}x}}vrr}vxyuxrrrr}wr`````|+>>>>.<br></br>
`zxzzzzxzzx}uuuuuuuuuuuuuuuuyzvrrvrr-``~+v}uyw?rrr}vwxzr}rr}r}~`~+>>+>>>>>.<br></br>
`zxxzzzxxzwvzyuuuuuuuuuuuuuuyr}r}vy>....|zuyuyr}rrrv}rr}rrrrv>+>>>>>+>>>>>.<br></br>
`zzxxuuyzvv^+}zxuuuuuuuuuuuuuv}}vwu>....}uxxxxx?v}}}v}}}}}}?++>>+++^^>++>+.<br></br>


            </div>,

            <div>

.vv????v???v????v?>-+wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvv??>+++>+|`..      <br></br>
`wrvvvv}vvvvvvvv}v?>uyyuuuyyyyyyyyyyuuuuuxxxxxxzwzzwwwwwwwvv????+~`....``. <br></br>
`rrv?>>>>>>?>>>>>+^zyuzwwwwwwwwwwzzxuuuxxxxxxxxzwzwwwwwwwwrvv??>++^^^+++++.<br></br>
`rrv?+|-`````````-?yyzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}v??vvv??vvv?v-<br></br>
`v}vv?+~`..... .`^ryxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv?v}rvvv}rvv}~<br></br>
`vvvv??>^-`.. .`^+}yzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}?v??vv???vv??v`<br></br>
.????????>^~``~^+^vwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}>?>||^^^^^^^^^.<br></br>
.?????????>>+>?>+|vvrwwwwwwwwwwwr}}rrwwwwwwwwwwwwwwwwwwwwwwv^?>|`......... <br></br>
.^??????????vvv?>-?}v}rwwwwwwwwrv}}}}vvv}}rrwwwwwwwwwwwwwww?|^^|`.  .. ... <br></br>
 -^????????vvvv?+~^}vv}}wwwwwwwv}}}}vvv?v??vvvv}}vwwwwwwwwr^^>+|-.      .. <br></br>
 `|>??????vvvv?>++|?}vvv}}rwww}}r}}}}}rvvvvvvvvvrvwwwwwwww?|^>+|~-.   .....<br></br>
.>???????vvvv?>+++^^v}vvvvvv}}vrr}rr}}r}vvvvv}}}rvwwwwwrrv~||||||~`....`-+`<br></br>
`v}v???vvvvv?>+++++++v}vv?????}}}zv?wrrvvvv?}rrw}vrr}}}}?~~||||~|~~`...~>v~<br></br>
`wwv?>?vvvv?>++++>>>>>v}??v???}vx> |xz~?vv?}>r}>vv}vv}v?|~~~||~~~~~-..`~?}~<br></br>
.?v?>+>>???>++>>>>>>>>>??v??vv}}w?.vzr.?}vr~|x? }v}}v?+~~---~~~~~~~~`..`|>`<br></br>
.^|^^||^++>>>>>>>>>>>>>>+?v?vv}rvv?vv??}}v??vr?vv}v?++>+|~--~~~~~~~~~`..``.<br></br>
.vv?>^|~~|^+>>>>>>>>>>>>>>>vvv}rr}}}}vvvvv?vvvvr?+~|+?vv+~---~~~~~~~~-`... <br></br>
.vyyuz}?+|||++>>>>>>+++++>+vvvrrrrr}rr}vvv}rrrrr?`|+?}v}r?~`-~~~~~~~~-++^~.<br></br>
.>}xyyyyxr?+^|^++>+>?rr}?>^?vv}rrrv?vrwww}}r}}}r?`^^?}}}}r?^`-~~~~~~~~~???`<br></br>
.>>??}zuyyyzv>+^|^vxyyyyyxv?vvvrr????????vvrwz}}+``+}r}r}}vr>`~~~~~~~~-^>>.<br></br>
.>>>>>>?vzyyyzv+>wyyuuuuuuyw?vvv}vv????^?vv?>>v?^+-?rr}r}rvvz|-~~~~~~~~->>.<br></br>
.+>>>>>>>>?ruyuvxyuuuuuuuuuyz?vvvv}r}}v?wwwxuz>|uyvvrrrrrrvvyv`~~~~~~~~-~+.<br></br>
.++^+>>>>>>>?rruyuuuxzwwwzuzyrvvvvvvvvv?v??}xuzvyuuwvv}}}v?zyu|----~~~|||+.<br></br>
`wvv?++>>>>>>>wyuuuzzzzz}vrvwx}r}v?????+. `+?zzyuuyzwv?vv?ryuyv^+>???????>.<br></br>
-yurvv>^+?vrwryuuuuuyuyuxzxxxxxxwr}v???++^?~}uuuuxuwz}?v?}uuuyw+????????>>.<br></br>
-uyyrv}rzxxxrxuuuuuuuuuuuuuuuuyz?vvrvv}rrr}?z}uyzwuww}??}yyxwxx`~>>>>>+>>>.<br></br>
-xxxxxxxxzzz}uuuuuuuuuuuuuuuuuuvvvr}rrr}}r}vvvuu}rzzwv?wyuzrrwu~.~>>>++>>>.<br></br>
`xxxxzxzxxxwwyuuuuuuuuuuuuuuuyr?vv}rrrrr}}rv}}zw}r}wuzzyxwrrrwx-```^>+>>>>.<br></br>
`zxxxzzzzzxrxuuuuuuuuuuuuuuuuuvvvvv}r}v}rrr}x}}vrr}vxyuxrrrr}wr`````|+>>>>.<br></br>
`zxzzzzzzzx}uuuuuuuuuuuuuuuuyzvrrvrr-``-+v}uyw?rrr}vwxzr}r}}r}~`~+>>+>>>>>.<br></br>
`zxxzzzxxzwvzyuuuuuuuuuuuuuuyr}r}vy>....|zuyuyr}rrrv}rr}rrrrv>+>>>>>+>>>>>.<br></br>
`zzxxuuyzvv^+}zxuuuuuuuuuuuuuv}}vwu>....}uxxxxx?v}}}v}}}}}}?++>++++^^>++>+.<br></br>


            </div>,

            <div>

`vv???vv???v????v?>->wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvv??>++>+|`..      <br></br>
`wrvvvvvvvvvvvvvvv>?yyyuuuuyyyyyyyyyuuxxxxxxxxxzwwwwwwwwww}v????+|```````` <br></br>
`wrv>>>>>>>>>>>>+^^xyuwwwwwwwwwwzzxuuuuuuuxxxxxzwwwwwwwwwwwvv??>>++++>>>>>.<br></br>
`rrv?>|-```````.`-?yuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}vv??vvv?vvvvvv-<br></br>
`v}vv?>|`....  .-+}yxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv??}r}vv}r}v}~<br></br>
`vvvv??>^~`....`^+vuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr?v?>??????v???`<br></br>
.????????>^~``~++^vwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???^~|||||^|||.<br></br>
.?????????>>>>?>+~vvrwwwwwwwwwwwrrrrwwwwwwwwwwwwwwwwwwwwwwwr+>>|`......... <br></br>
.^??????????vvv?+`?}v}rwwwwwwwww}}}}}}rrrrwwwwwwwwwwwwwwwwwv|^^|`.. ..  .. <br></br>
 -+????????vvvv?+~^}vv}}wwwwwwwr}}rr}}vvvvv}}}rrrrwwwwwwwww>|>>^~`.     .. <br></br>
 `^>??????vvvv?>++|?}vvv}}rwwwr}}}}}}}r}vvvvvvvv}}rwwwwwww}^^++|~-.   .....<br></br>
.>??????vvvvv?>+++^+v}vvv}v}}}v}rrrr}rr}vvvvvvv}}}rwwwwww}^||||||~`....`-+`<br></br>
`}}v???vvvvv?>++++++>v}vvv?vvvv}rw}vwwrrvvvv}}rw}}}rrrrrv|~||||~|~~`...~>v~<br></br>
`wwv?>?vvvv?>++++>>>>>v}??v???vvzv~-?x>?vvv}?wr?rv}vv}v?|~~~||~~~~~-..`~>v~<br></br>
.?v?>+>>???>+>>>>>>>>>>??v??vvvvr}.-vz>-?r}^+vv->}}}vv?>+|--~|~~~~~~-..`~+`<br></br>
.~|^^||^++>>>>>>>>>>>>>>>?v?vvv}v}?^}rv+}vvv|vv|>}v?>???>+^~-~~~~~~~~`..``.<br></br>
.v?>+|~~||^+>>>>>>>>>>>>>>?vvv}}}}}}vvvv}}vvvv}v?+~^>}}}?>>+-~~~~~~~~-`... <br></br>
`wyuz}?+^||^+>>>>>>>+++++>+?vv}rrrr}rr}vvv}}}}}r?``|v}}}rv>^+^~~~~~~~---`~.<br></br>
.?zuyyyx}?>+^^^+>>+>vww}?>^?vv}rrr}??wwrr}}rr}}rv--~vr}}}rv>+?|~~~~~~~``-^.<br></br>
.>??vwxyyuwv>>+^|+vxyyyyyxv?vvvrrv>|-+v}}r}wwzr}?``|}}rrrr}r???~~~~~~~-``` <br></br>
.>>>>>?vrxyyw?>+?zyuuuuuuuyw?vv}vvv?>>>+>vv???vr>^|+>?}}}}vvx??+~~~~~~~``` <br></br>
.>>>>>>>>?vzyxvvuyuuuuuuuuuyw?vvvvv}}vv?}wwzxw?^zuzz}vzrrvvryw??~~~~~~~~`. <br></br>
.^+>>>>>>>>?vwwuuuuuxzzwwzuxyrvvv}vvvvv?vvv}zuuvvuuxzwzrrv?xyu??^----~~||~.<br></br>
`v?+++>>>>>>??xuuuuzzzxzrvwvwxwrvvv}}}}v|.`~?zxxzuuuzw}}vvzyuywv?+>>??????`<br></br>
-xx}>+++>?}wwzyuuuuuyuuuxzxxxxxxzrv?????|~~|>zuuyuuxzzvv}zyuuyzvv+????????`<br></br>
-uxzrvrwxuuxwuuuuuuuuuuuuyuuuuyu}vv}vv}rrr}?vzxyuzzxwzvrzyuuxzz?v-~>>>>>>>.<br></br>
-xuxuuuxxxxzzuuuuuuuuuuuuuuuuuurvv}}rrrrrrrvv}zuzrwzzzwxuuuwrwz?>`.->>>>>>.<br></br>
-xuxxxxxxxxwxuuuuuuuuuuuuuuuuuxvvv}rrrrr}}r}}rwzrrrwzxuuuxwrrrz>|``.-+>>>>.<br></br>
-xxxxxxxxxxwuuuuuuuuuuuuuuuuuurvvvv}r}}rrr}}zw}}}r}}zuuuzrrrrrr|```..`+>>>.<br></br>
-xxxxxxxxxxwuuuuuuuuuuuuuuuuuu}rrv}w^~-|?v}xuxv}rr}vrwwwrrrrr}^````-|^+>>>.<br></br>
-xuxxxxxxzr}xyuuuuuuuuuuuuuuyz}rrvu?....-wxyuuz}}rrv}}}rrrrrv^`~^+>>>>>>>>.<br></br>
`zxxuuxzw??^>rxuuuuuuuuuuuuuur}}vrx^....vuxxxxxv^>v}}}}}}}}?||+>>>>++>++>+.<br></br>


            </div>,

            <div>

`vv???vv???v????v?>-?wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvv??>++++|`..      <br></br>
`wrv?vvvvvvvvvvvv?>?yyyuxuuyyyyyyyyyuxxxxxxxxxxzwwwwwwwwwwwvv???+|-``````` <br></br>
`wr?>+>+++++++++^~^xyuwwwwwwwwwwzzxuuyyuuuuuuuuzwwwwwwwwwww}v???>>>++>>>>>.<br></br>
`rrv?+|-```......~vyuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv??v}vvvv}vvv-<br></br>
`v}vv?>|`....  .-+vyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvv??}r}vvvr}vv~<br></br>
`vvvv??>^~`....-++vuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???>??????????`<br></br>
.????????>^~--|++^vrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???^~~|||||||~.<br></br>
.?????????>>>??>+|vv}wwwwwwwwwwwwwrrwwwwwwwwwwwwwwwwwwwwwwww+>>^`......... <br></br>
.^??????????vvv?+`?}v}rwwwwwwwwwr}}}}rwwwwwwwwwwwwwwwwwwwwwr^^^|-..     .. <br></br>
 -+????????vvvv?+~+}vv}}wwwwwwwwv}rr}}vvv}}rrrwwwwrwwwwwwww?|>>^~`.    ... <br></br>
 -^>??????vvvv?>+^^v}vvv}}rwwwwv}}}}}}}}vvvvvvvv}}}wwwwwwww^|++||~.. ......<br></br>
.???????vvvvv?>+++^>}vvvv}}}}rvvr}}}}}rrvvvvvvvvvrvwwwwwww>~|||||~-....`-+`<br></br>
`}}????vvvvv?>++++++?}}v}v?vvv?}r}wwrrrrvvvvvv}rr}vwrrrr}>-||||~~~~`...->v~<br></br>
`wrv?>?vvv??>++++>>>>?}}??v?v?vv}zr~|xwvvvvvvrrrwvv}}vvv+--~||~~~~~~`..-+v-<br></br>
`v??>+>>???>+>>>>>>>>>>v?v????vv}y- ?xx`?rvvv>z?|rv}vvv}v>~`~|~~~~~~-`.`-^.<br></br>
.||^^||^+++>>>>>>>>>>>>>>vv?vvv}v}?-rw}-vvvr-?w~+v}v?vv}}r}+-~~~~~~~~-..``.<br></br>
`??+|~~~||^+>>>>>>>>>>>>>>?vvvv}r}vvvv?v}rv??}v?v>+?vr}}}}r}~~~~~~~~~~`....<br></br>
-yyxwv>^|~|^+>>>>>>++++++>+?vvv}rrrrr}vvvvvv}}}rv``^vrrr}}}vv>~~~~~~~~-`.|`<br></br>
`xyyyyuwv>+^|^^+>++>vww}v>+vvv}rrrr??ww}vv}}}}}rr|-~}rrr}}}vrx^~~~~~~~~-``.<br></br>
.>vrxuyyyx}?>+^^|>}uyyyyyx}?vvvrr}+. |vrwr}wzzr}}-`|}}rrrr}vryw~~~~~~~~~-` <br></br>
.>>>>?vwuyyu}>+^?xyuuuuuuuyw?vv}v???||+>^?v??vrx?|+~-|v}vvvvxuy?-~~~~~~~-` <br></br>
.>>>>>>>?}xyyz>}yyuuuuuuuuuyw?vv?????????rwwz}?+}xyxv?x}vv?ryuyz~~~~~~~~~- <br></br>
.^>>>>>>>>?}xxwyuuuxzzwwwzuxy}vvvrv?????vwrrzuuv|uuyuzx}rvvuuuuy>-----~~~~.<br></br>
`?>^>>>>>>>>?}uuuuuzzzxxwvw}zxr}v?vwwwwr?~`-?zxwwyuyzxz}}vxyuuuyr|+>>?????`<br></br>
`r}v+^>>?v}wrxuuuuuuuuuuuzxxxxxxzr?????v-`.+~}zxyuxywxr}ruyuuuxux|>???????`<br></br>
-urvv?}wxxxzwyuuuuuuuuuuuyuuuuuyrvvv??vr}}v?+uwyyzruwx}wuuuuxwrxu-->>>>>>>.<br></br>
`xxzxxxxxxxrxuuuuuuuuuuuuuuuuuyz?v}}}rrrrrr}}w}uu}rwwuuuuuuzr}rzx-``+>>>>+.<br></br>
`xxxxxxxxzz}uuuuuuuuuuuuuuuuuuuvvv}rrr}}rrr}vr}xr}rr}uuuuuw}rrrx?.```^>>++.<br></br>
`xxxzzzzxxzryuuuuuuuuuuuuuuuuyz?vvv}rrrrrr}v}z}}vrrr}zxuuw}r}}rv``````|++>.<br></br>
`zzzxxxxxzzwyuuuuuuuuuuuuuuuuu}rr}vr>^|>?v}wuurvrrrr}vrww}rrrr?````-|^++>>.<br></br>
`xxxxzxxzw}}uyuuuuuuuuuuuuuuyxvrrvz?....`}zyyuuvvrrrr}vrrrrrv+`-|+>>>>>+>>.<br></br>
`zzxuuzvv?>^?wxuuuuuuuuuuuuuurv}vvx-....?uxxxxuw-^?}}}}}}}}v^~+>>>>++>++>+.<br></br>


            </div>,

            <div>

`vv???vv???v???vv?>-?wwwwwwwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvv??>+>>+|`..      <br></br>
`wrv?vvvvvvvvvvvv?>?yyyuxuuyyyyyyyyyuxxxxxxxxxxzwwwwwwwwwwwvv???>|-````--` <br></br>
`rr?>+++^^++^^^^|~^xyuwwwwwwwwwwzzxuuyyuuuuuuuuzwwwwwwwwwww}v???>>>>>>?>>>.<br></br>
`rrv?+|-``.......|vyuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv??v}vvvv}vvv-<br></br>
`v}vv?>|`...   .~+vyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvv??v}}vvv}}vv-<br></br>
`vvvv??>^~`....~++vuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???>??????????`<br></br>
.????????>^~-~^++^v}wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww???^~~~~~~||~~.<br></br>
.?????????>>???>+~vv}wwwwwwwwwwwwwrwwwwwwwwwwwwwwwwwwwwwwwww+>>^`......... <br></br>
.^??????????vvv?+`?}v}rwwwwwwwwwr}}}}rwwwwwwwwwwwwwwwwwwwww}|^^|-..     .. <br></br>
 -+????????vvvv?+~+}vv}}wwwwwwwwv}rrr}vvv}}rrrwwwwrwwwwwwww?|>>^~`.     .. <br></br>
 ~+>?????vvvvv?>+^^v}vvv}}rwwwwv}}}}}}}}vvvvvvvv}}}wwwwwwww^|++^|~`. ......<br></br>
.???????vvvvv?>+++^>}vvvv}}}}rvvr}}}}}rrvvvvvvvvvrvwwwwwww>~|||||~-.....-+`<br></br>
`}}????vvvvv?>++++++?}vv}v?vvv?}r}wwrrrrvvvvvv}}r}vwrrrr}>-||||~~~~`...-+v~<br></br>
`wr?>>?vvv??>++++>>>>?}}??v?v?vv}zr~|xwvvvvvvrrrwvv}}vvv+--~||~~~~~~`..-+v-<br></br>
`v?>>++>???>+>>>>>>>>>>v?v????vv}y- ?xx`?rvvv>z?|rv}vvv}v>~`~~~~~~~~-`.`-^.<br></br>
.||^^||^++++>>>>>>>>>>>>>vv?vvv}v}?-rw}-}vvw-?w~+v}v?vv}}r}+-~~~~~~~~-...`.<br></br>
`??+|~~~~|^+>>>>>>>>>>>>>>?vvvv}r}vvvv?v}rv??}v?v>+?vr}}}}r}~~~~~~~~~~`... <br></br>
-yyxwv>^|~|^+>>>>>>++++++>+?vv}rrrrrr}vvvvvv}}}rv``^vrr}}}}vv>~~~~~~~~~`.|`<br></br>
`xyyyyuzv>+^|^^+>++>vww}v>+vvv}rrrr??ww}vv}}}}}rr|-~}rrr}}}vrx^~~~~~~~~-``.<br></br>
.>vrxuyyyx}?>+^^|>}uyyyyyx}?vvvrr}+. |vwwr}wzzr}}-`|}}rrrr}vryw~~~~~~~~~-` <br></br>
.>>>>?vwuyyu}>+^?xyuuuuuuuyw?vv}v???||+>^?v??vrx?|+~-|v}vvvvxuy?-~~~~~~~-` <br></br>
.>>>>>>>?}xyyz>}yyuuuuuuuuuyw?vv?????????rwwz}?+}xyxv?x}vv?ryuyz~~~~~~~~~- <br></br>
.^>>>>>>>>?}xxwyuuuxzzwwwzuxy}vvvrv?????vwrrzuyv|uuyuzx}rvvuuuuy>-----~~~~.<br></br>
`?>^>>>>>>>>?}uuuuuzzzxxwvw}zxr}v?vwwwwr?~`-?zxwwyuyzxz}}vxyuuuyr|+>>?????`<br></br>
`}}v+^>>?v}wrxuuuuuuuuuuuzxxxxxxzr?????v-`.+~}zuyuxywxr}}uyuuuxux|>???????`<br></br>
-urvv?}wxxxzwyuuuuuuuuuuuyuuuuuyrvvv??vr}}v?+uwyyzruwx}wuuuuxwrxu-->>>>>>>.<br></br>
`xxzxxxxxxxrxuuuuuuuuuuuuuuuuuyx?v}}}rrrrrr}vw}uu}rwwuuuuuuzr}rzx-``+>>>>+.<br></br>
`xxxxxxxxzz}uuuuuuuuuuuuuuuuuuuvvv}rrr}}rrr}vr}xr}rr}uuuuuw}rrrx?.```^>>++.<br></br>
`xxxzzzzxxzryuuuuuuuuuuuuuuuuyz?vvv}rrrrrr}v}z}}vrrr}zxuuw}}}}rv``````|>+>.<br></br>
`zzzxxxxxxzwyuuuuuuuuuuuuuuuuu}}r}vr>^|>vv}wuurvrrrr}vrww}rrrr?````-|^++>>.<br></br>
`xxxxzxxzw}}uuuuuuuuuuuuuuuuyxvrrvz?....`}zyyuuvvrrrr}vrrrrrv+`-|+>>>>>+>>.<br></br>
`zzxuuzvv?>^?wxuuuuuuuuuuuuuurv}vvx-....?uxxxxuw-^?}}}}}}}}v^~+>>>>++>++>+.<br></br>


            </div>,

            <div>

`vv???v????????vv?+?}wwwwwwwwrrrrr}vvvvvvvvvvvvvvvvvvvvvvv??>+>>+|`....... <br></br>
`w}???vv??vvv?vvv?vzyyyuuuuuxxzzzzzzzzwwwwwwwwwwwwwwwwwwwwrvv???>^~-`---~-.<br></br>
`r}?>+^^^^^^^|||~?wuuxzwwwwwwwwwwwwzzzzzzzzwwzzwwwwwwwwwwwr}vv?????>>?????`<br></br>
`r}v?+|``.......`}zuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv??v}}vvv}}vv-<br></br>
`v}vv?>|`...  ..?wzxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvv??v}}vvv}}vv-<br></br>
`vv????>^~`...`~vwrwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwr???>>?????????`<br></br>
.????????>^|-|++?rrrwwwwwwwwwwwwwwwrrrrrrrrrrrrrwwwwwwwwwww}???+---~~~~~~-.<br></br>
.?????????>>???>>?rrrwwwwwwwwwwrrrrrrrrrrrr}}}}}rrwwwwwwwwwv+>>^-......... <br></br>
.^?????????vvvv?+^vrrrwwwwwwwwrrr}rrrrrrrrrr}r}rrrrrwwwwwwwv|^^|~..    ... <br></br>
 ~+???????vvvvv?^^?}}rrrwwwwwrrr}rr>^rwr?v}v}}}rrrrrwwwwww}>|>>^|-.    ... <br></br>
 ~+??????vvvvv?>+^?v}}}rrrrrrr}}}z>`-wzx|-}v}vv|?wvrrwwwrr?^|^^||~`. ......<br></br>
.???????vvvv??>++++?}vvv}}}}}vv}rwv|-rzx^+r}}}>`?x+vrrrrr?|||||||~~`...`~+`<br></br>
`r}????vvvv??++++++>v}vv}vvvvvv}r}rrv}wwvvvvvv}vvw?}}rr}?~-||||~~~~-...`+v-<br></br>
`w}?>??vvv??>+++>>>>>?}}v?v???vv}zr+>wrv}vvvv}}}}vv}}vv>~~~~~|~~~~~~`..`^?-<br></br>
.??>>++>??>>+>>>>>>>>>?v?vv???vvrx+`vxx^vw}}v?zv>w}}}v?>+|-`~~~~~~~~-`.`-|.<br></br>
.|~||||^+++>>>>>>>>>>>>??vv?vvv}}rv+}wr?zxzz>vx+?}}}vv???>+~-~~~~~~~~-`..`.<br></br>
.?>+^|||~|^+>>>>>>>>>>>>>>?vvvvvv?vrr}>|>v}}vr}vvv?v}}}v?>>+-~~~~~~~~~-`.. <br></br>
`v???>+^|~|^+>>>>>>++>>>>+>?vvvrww}vvwwv|+????>??>+?}v???>>^+^~~~~~~~~~-``.<br></br>
`wv?????>+^^^^++>>>?rzzwrv?vvv}wwzx}?vzzv?v?????v?+>}?>>>?vvrz?>>^~~~~~-`. <br></br>
`vv???????>++^^^^vzuyuuuuuzwv??v}wv+++?xwvvv}wv?v>+>?>>?v}}}rzw??>~~~~~~-` <br></br>
`???>>???????>>>vxxzxxxxxxxw>||???vvv?^v?????vzwv>?>>?r}v}}vzxxv>>|~~~~~~` <br></br>
`????>>>>???zw?ruxxxxxxxxzxxw?v?|+}}rr?vv}}rrrv?}wxuwrxr}vvwxxxz?>|~~~~~~- <br></br>
.????>>>++>?zxxuxxxxzzwwwwzzxr>~+v}}vvvrwxzzxzz}?xuuuxzrr}}xxxxxv+-----~~~.<br></br>
.>vv?>>>>++>vzxwzxxzzzzzwrw}}rvv}vv}}rrrv>^+?rwzzyuuuxwwrrzxxxxxr^-~~~|+??`<br></br>
.?}xv?>>+>vxxuzzwwxxxxxxxzzzwzxxwwv?v??v+||?+ruuyuuuxuw}wxxxxzwzr?~``--~+?`<br></br>
`?vzv>???zuuxuw}v?vzxxxxxxxxxuuuwr}vv?vwwwrvvuxuuxxxzuzzxxxzzr}rr?>```...-.<br></br>
`????vrrzuuxuxwv>???}wzzzxzuuuuuwww}}wzzxzzxzxzuxzzwwuxuuzwr}}}rw+?-...... <br></br>
`vrwxuxxuuuzux}v?>??v}zzuuuuuuuwzwzzxxxxzzxzzxzurw}rrxuuxw}}}}}w?^?+...... <br></br>
-xuxxxzxuuuzurvzzr}wwzxxuxuuuuxrrwzxxxxzxxxzzuzzv}rr}zuxzrv}}}}v^>?>``.... <br></br>
`xxuuxxuuuxwxrwzzzzwzxzxxxuuuuzzzzwxrvv}zzzxuuxwv}rrvwzwrv}}}}?|>?>>`..... <br></br>
-uuxxxxxzzr}xxwzzzwwxxxzxuuuuxwxxwxr>????zxuuuyrvrrr}zwr}v}}v>|????>...... <br></br>
-xuuuuzrrv?>?wwwzwwxzzzxuuuuxzwzwwx?>???}uxxxxuw+>v}vwr}}v}v>^?????|...... <br></br>


            </div>,

            <div>

`v?????????????v??+}wwwwwwwwrrrr}}}}}}}}}}}}}}}vvvvvvvvvv?v?>+>>+|`....... <br></br>
`rv???????v??????>wyyyyyyuuxzzzwwwwwwwwwwwwwwwwwwwwwwwwwwrv}v???>^~--~~~~~.<br></br>
`r}?+^^||||||~~~-vyyuxzxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}v????????????`<br></br>
`r}v?+~`........~uyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvr}??v}}vvv}}vv-<br></br>
`vvvv?>|`...  .`vyuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvvv??v}}vvv}}vv-<br></br>
`vv????>+~`...`|wyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv???>+??>>????>.<br></br>
.????????>+|~|++vuxwwwwwwwwwwwwwwrrr}}}vvvvvvv}rrwwwwwwwwww?>??+~`-------`.<br></br>
.?????????>????>?}wwwwwwwwwwwww}}}}}}}}}vvvvvvvvv}rwwwwwwww>^+>^-......... <br></br>
.+?????????vvvv?+v}rwwwwwwwwww}vr}rwrwwrrvvvvvvv}}r}wwwwwwr^|^^|~`.     .. <br></br>
.~>???????vvvvv>^?}}}rwwwwwwr}v}}rw~`wxxv}vvv?vwrrr}rwwwwr?|^>>+|-.     .. <br></br>
.^>??????vvvv??++^v}v}}rrwr}}vvr}x|  wxu`.w}z}v~>zv}vrrr}v|||^^||~-........<br></br>
`v?????vvvvv?>+++^>vvvvv}}}}}vvr}r?. }xx-^r}rr+ +x-?}vv}v^~||||||~~`....~+`<br></br>
`r}?>?vvvvv?>++++++?}}vv}vvvv?v}rvv}?}rrvv}}}}v?vw?}vv}}+~~~|||~~~~-...`^v-<br></br>
`w}?>???vv?>++++>>>>?v}vv?v???vvrr}}vvvvvvvvvvvv?vv}v}v^~~--~|~~~~~~`..`|?`<br></br>
.?>>+++>??>>+>>>>>>>>>v}?vv??vvvrrrrrrwwwrr}vv}}}}}r}?+||~--~~~~~~~~~`.``~.<br></br>
.^^||||^+++>>>>>>>>>>>>v?vv?vvv}rrr}?vwxyyuzwwwwr}}rr?>>^|---~~~~~~~~-`... <br></br>
.>>^^^||~|^+>>>>>>>>>>>>>??vvvvv>+?ww}^.~?vrrrr}v}rrrv?>+|~--~~~~~~~~~-`.. <br></br>
.>>>+^^^|||^+>>>>>+++>>>>+??vvv}ww?+?uuv.`^^+|-`|rrrr>^^^|~--~~~~~~~~~~-`. <br></br>
-w?>>+^^^^||^+++++?vwzxzzr}??v}zzxyx?+zuv??++^~->rrrv~||~^>vv}vvv>-~~~~~`` <br></br>
-yx?>>^^^^^|||^+>ruyuuuuuuuuv>+?}xzzx>+ur????r>-vrr}^~|+?v}}vv}rr}|~~~~~-` <br></br>
-uyx?>+^^^^^|?}rwxw}rrrrr}}r^..^+?wwzz~rv????vuvrrvv}z}vv}r}}}}r}r>-~~~~~` <br></br>
-yyyv>>^^^^^vwwzxr}rrrrrr}rrr??>`~zzxx+v}vvvv}wr}vruyyx}}r}r}rrrrr>-~~~~~- <br></br>
.wyyz>>+^^^+}wuw}rrr}rrrr}vv}}|.->}r}}vzuuxxxr}v}zuuuxr}}rrrrrrrr}~~~~--~~.<br></br>
.>xyu?>>^^|?xyr?vr}rrrrrrr}v?v>?r}???vvvvvvvvvvwuyuuyw}rrrrrrr}}v?------>?`<br></br>
.>}yyv>>^^vuyxvv??rrrrrrr}}rvwzwrrvvvv?vvvvvvwuuuuuuuuw}}rrr}vvv?v^------^.<br></br>
.>?x}?>>+ryuuzv+`.|vrr}}}}}rwuxuzr}?v?vrrwrruuuuuuyzwyyw}}}vvvvv?wr`-----`.<br></br>
.>>>>?vvwyuuywv~ ..`^?vvv}}uuyxuuuzvvwzxuzzuuuuuzuz}wuwxu}vvvvvv?vy+`----` <br></br>
.?vrzuxzyuuuyr>^` .`-^vvzxxuuuuxyuzxuuuuzxuuuuuurzvrrrxxrv?vvvvv?ryv`-```` <br></br>
`xxxxxwuuuuux?+vv>+??}rruwuuuuyzzzuyuuuzuuuuuuuuvvrr}wurrvvvvvvvvuyz`````` <br></br>
`xuuuxzyuuzrr??vvvv?vr}rrzyuuuxzxuuuuxzuuuuuuuuxv}rrvxxr}?vvvvvvuyuw`````` <br></br>
-uxzxzzxwrr}}rvvvv?v}}}}wuuuuzzuuuuuzxuuuuuuuuyzv}rrvuxrv?vvvvvxuzr?`````` <br></br>
-uuuux}r}rr}}rvvv?vr}}}zyuuuwzxxxxxwzxxxxxxxxxxwv}}}vzw}}?vv??wwr}r+`````` <br></br>


            </div>,

            <div>

`v?????????????v?>?rwwwwwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvvv??v?>+>>+|`....... <br></br>
`rv??????????????vuyyyyyuuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwww}}rv???>+|~~~||||.<br></br>
`r}?+^|~~~~~~~--^zyyuuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}v????????????`<br></br>
`r}v?+~`........?yuxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvr}??vr}vvv}rvv-<br></br>
`vvvv?>|-...  .-wyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvvv??vvvvvvvvvv-<br></br>
`vv????>+|`...-+xyzwwwwwwwwwwwwwwwwrrrr}}}}}rrrwwwwwwwwwwww????>+>>>>>>?>>.<br></br>
.????????>+||^+>zuzwwwwwwwwwwwwwrr}}}}}}vvvvvvv}rwwwwwwwwww?>??+~````````` <br></br>
.??????????????>vwwwwwwwwwwwwwr}}}r}vrrrvvvvvvvv}}rwwwwwwww+^+>^~......... <br></br>
.+?????????vvvv>>}rwwwwwwwwwwr}}}wv|~wzx?-?vv}v}vvr}wwwwwwv||^^^~`.     .. <br></br>
.~>???????vvvv?>+v}}rwwwwwwrrvvr}x>. ?ux}|?}}}}w|?r?}wwwrr+|^+>+|~..    .. <br></br>
.^>??????vvvv?>+^>vv}}rrrrr}}vvr}z>``?zx^-r}wr}>?v}^?rr}}?~||^^||~-........<br></br>
`v?????vvvvv?>++++?vvvvv}}}}}vv}rr?~`vwz|+}}vv>.>z|?}vv}v|~||||||~~`....-+`<br></br>
`rv?>?vvvvv?>+++++>?}vvv}vvvv?v}r}r}v}ww}}}}}}v?vw?}}}vv^~~~|||~~~~~`..`^?-<br></br>
`rv?>???vv?>++++>>>>?vvvv?????vvrr}}}}}}}}}vvvv}v}rr}}}+~~--~|~~~~~~-..`|?`<br></br>
.++++++>??>>>>>>>>>>>?v}v?v????v}}rrrrrzxxzwr}}}}}}r}v?+|~--~~~~~~~~~`..`~.<br></br>
.^^||||^^++>>>>>>>>>>>?vvvv?vv??wwr}v??vvvrzxxzzw}}rr}}?^|---~~~~~~~~~`... <br></br>
.+++^^||~|^+>>>>>>>??>>>??v}rwwv>}rw}?^``~+?v?v?>?rrr}}?+|~--~~~~~~~~~-`.. <br></br>
.+>++^^^|||^+>?vvvv}}v?++|?}rwwzvvr}vrr?|^>>>||~|vrrrv>^^|~---~~~~~~~~~-`. <br></br>
`?>+++^^^||>?vv}}}}rzzxv??>?}}rzzvxx}>}rv??>?>+>>}rr}>||~~|^^+>>>^~~~~~~-` <br></br>
`??>++^^^^>??vvvvwxwxzzw}rr?vv?vwvwzzvvzrv???}?v}rr}?||+>>>+||+>?>~~~~~~-` <br></br>
`???>++^|^>>?}wwwzw}vv}v>+^|?>>v}?rwww?wrrrrrwx}}r}}}zzzzxxw???vvv?^~~~~~- <br></br>
`???>^|~~-+?}wwzzrrr}?????v}rv}}^~rrwz?}r}}}}rwr}v}zuyuxzwr}}}}}}}?>+~~~~- <br></br>
.????^~-~~~?wwzwrrrrrvrwxxzzzr>^^>vvvv}wzzzzzr}vvwxuuuxxr}vv}rr}}v?>>|----.<br></br>
.>???>~--~`^wxrvv}vvvzxzzxxzwr?vr}????vvvvvvv??}xuuuuxxxz}}}r}}}}v>>?^-```.<br></br>
.>??xr>^~~?rwrvvv?v}zxzrwxxxzzwwrr?vvv?vvvvvv}zuuuuuuxxwrr}}}}}}vr?++~--`. <br></br>
.>>rw???>}zzzrv?+|>zxxr}zxzxxxzuwrv?v?}rrrr}zuuuuuuuzzurrr}}}vvvvw}|~----` <br></br>
.>?v?v}vrxzwwrv+-|+?}}vzzxzuuuzuuxwvrwzzxzzuuuuuxuuwrzxxwrvvvvvvvvz>-`---` <br></br>
`vrzxuzrzzzzw}?>^|>?v?rzuuuuuuzuuxxuuuuuxxuuuuuuzzw}rruur}vvvvvvv}zv`-```` <br></br>
`xuuxzwwzwwww??}}v}wrwxxuxuuuuxzxuuuuuuzxuuuuuuurv}r}wxwzwvvvvvvvwww~````` <br></br>
-uuuxzwwzwwrr?}}}rwrwxzxxxuuuxxuuuuuuxzuuuuuuuuu}}rr}wxzwvvvvvvvwzww>````` <br></br>
-uxzzwrwwrrrrr}rr}rwzzzzxuuxxuuuuuuuzzuuuuuuuuyx}v}}vzuzr>vvvvvwwrvv>~```` <br></br>
-xxxzzrrr}rr}r}v}wzxxxzxuuxxxxxxxxxwzxxxxxxxxxxzrv}}vzzw}>vv??}r}}??^|```` <br></br>


            </div>,

            <div>

`v?????????????v?+vwwwwwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvvvv??v??+>>+|`....... <br></br>
`rv?????????????>}yyyyyyuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvrrv???>+||~|||^|.<br></br>
`r}?+^|~--~~---`+uyyuuuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvr}v?????????v??`<br></br>
`r}v?+~`........}yuxzwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}}??vrrvvv}rvv-<br></br>
`vvv??>|-...  .~uyzwwwwwwwwwwwwwwwwwwwwrrrrwwwwwwwwwwwwwww}?vv???vvvvvvvvv`<br></br>
`vv????>+|-...->yuwwwwwwwwwwwwwwwwr}}vvvv??vvv}rwwwwwwwwwwr>???>^+>>>>>>>+.<br></br>
.????????>+^^++?yuwwwwwwwwwwwwww}v}}}}}vvvvvvv?vvrwwwwwwwwr++>?+~````````` <br></br>
.??????????????>wuwwwwwwwwwwww}v}}rvvr}rvvv?vvvv}v}rwwwwww}|^+>^~......... <br></br>
.+?????????vvvv>?rwwwwwwwwwwrvvr}r?..zzx+.?vv}?r?v}vrwwwww?||^+^|`.     .. <br></br>
.~>???????vvvv?>>}}rwwwwwwr}}v}rvu|  ^uww.?rvr}}.^v>vrwr}}^||+>+|~`.    .. <br></br>
.+>?????vvvvv?>++v}v}}rrr}}v}?}r}rr?++rrrvvv}}}}v?v`>v}}}?-||^^|||-........<br></br>
`v?????vvvvv?>++++v}vvvvvv}}v?v}r}vvvvvvvvvvv?vvvvvvvvv}v|~||||||~~`....-+`<br></br>
`rv???vvvvv?>+++++>v}vvvvvvvv?v}rrr}}}}}rrr}}vvv}}}}}}v?^|~~|||~~~~~`..`|?-<br></br>
`rv?>???v??>++++>>>>v}vvv?????v}rrrrrrr}}}}}}rrrrrrrr}r?~~--~|~~~~~~-..`~>`<br></br>
.^^++++>??>>>>>>>>>>>v}}v?v????v}}rrrr}zuuxwwr}}}rrrr}r?|~-`-~~~~~~~~-..`-.<br></br>
.^^||||^^++>>>>>>>>>>>?vv?v?v?^>ww}}r>~||+?rzzzww}}r}}rv^|~--~~~~~~~~~`... <br></br>
.++^^^|~~|^+>>>>>>>?>>+>??vwxuu?^zxw?+^`..`^^^>^~+rrr}r?^^~--~~~~~~~~~-`.. <br></br>
.+^^^^^|~~|^+?vvvv}rrv?+^~vzxuuu>>xx}???????>^^^-?rrrrv|^|~---~~~~~~~~~-`. <br></br>
.^^^^^^^^~|?vrrrrr}}rrr+.`~>rwwzw~wxz?????????>v+}rrr}^~~~~---~~~~~~~~~~-` <br></br>
.+^^^^^+^>}rrrrrrr}v}}}???>-vwrrx^vxrrwrvv????vx}rrr}+|+++^~``--~~~~~~~~-` <br></br>
.>^^^^^|~+rrrrrr}}r}?v}?`.  vwrwx|}w}rrwzzzzzzz}}rrvvwuuyyur>^>?vv??|-~~~- <br></br>
.>>^+~....?rr}}r}vrrv||--^?vrrrw?|vv}rrr}}}}}}}rr}vruyuuxwr}}}}vv}}rv~~~~-.<br></br>
.>>+^` ...-}r}v}r}}}}?}ruyyyu}v}???vvvv}}}}}}}}vvvzuuuuurvvv}r}}vv}rr>-~--.<br></br>
.>>+->. ...-v}}vvvvv?zuwwuuuyzvrw}??????vvvvv??vwuuuuuuyxvv}}}}rrrrrr?-~--.<br></br>
.>>|}y}|-~>????????vzyz}}uuuuxrrrr?vvv?vvvvvvvrxuuuuuzxw}}}v}rrrrr}}v+---- <br></br>
.>>vyuyxvvvvvvvvv?vxyuvvuuuuuxwxrrv??v}rrrrvrxuuuuuyz}xvrrr}}rrr}}vvv~---` <br></br>
`vvyuuzvv}vvvvvv?vzuyw?xuuuuuuryxwvvwwwwzwrxyuuuxzyz}ruwv}rrrrr}vvvv>`---` <br></br>
`rxuyzr?v}vvv?vv}rzyuvzuuuuuuuruxwzyyuuuzwuuuuuyzzzv}ruu}}}rr}vvvvv?`````` <br></br>
.wyyxwr?vv??v}rrrruuzzuuuuuuuywwxuyuuuuwzuuuuuuywv}r}wzwxxv}}vvvv??}>````` <br></br>
`uuxrr}?vv}rrr}}rwzzxuuuuuuuxzxuuuuuuzwuuuuuuuuywvrr}}zux}v}vvvvvv?zw````` <br></br>
-xzrrvv}rrrrrrrr}}wuuuuuuuuzzuyuuuuuzzuuuuuuuuuywvv}vryuw?v}vvvvvv?rx^```` <br></br>
`wr}}}rrrrr}}rr}rxyuuuuuuuwxuxxxxxzwzxxxxxxxxxxxw?}}vzxxr>vvvvvvvv?}r?```` <br></br>


            </div>,

            <div>

`v???????????????+vwwwwwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvvvv??v??>>>+^-`...... <br></br>
`rv?????????????>vyyyyyyuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvrr}???>+^^^^^^^^.<br></br>
`rv?+|~------```+uyyuuuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvr}v???vv???vv??`<br></br>
`}vv?+~`........}yuxzwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}}v?v}rvvv}rvv-<br></br>
`vvv??>|-...  .~uyzwwwwwwwwwwwwwwwwwwwwrrrrwwwwwwwwwwwwwww}?vv???vvv?vvvvv`<br></br>
`vv????>+|-..`~>yuwwwwwwwwwwwwwwwwr}}vvvv??vvv}rwwwwwwwwwwr>???>^+++++>>>+.<br></br>
.????????>+^^++?yuwwwwwwwwwwwwww}v}}}}}vvvvvvv?vvrwwwwwwwwr++>?>~````````` <br></br>
.???????????vv?>wuwwwwwwwwwwww}v}}rvvr}rvvv?vvvv}v}rwwwwww}|^^+^~`........ <br></br>
.+?????????vvvv>?rwwwwwwwwwwrvvr}r?..zzx+.?vv}?r?v}vrwwwww?|^^+^|-.     .. <br></br>
.|>??????vvvvv?>>}}rwwwwwwr}}v}rvu~  ^uww.?rvr}}.^v>vrwr}}^||+>>|~`.   ... <br></br>
.>>?????vvvvv?>++v}v}}rrr}}vv?}r}rr?++rrrvvv}}}}v?v`>v}}}?-||||||~~`.....`.<br></br>
`v????vvvvvv?>++++v}vvvvvv}}v?v}r}vvvvvvvvvvv?vvvvvvvvv}v|~||||||~~-....-^`<br></br>
`rv???vvvvv?>+++++>v}vvvvvvvv?v}rrr}}}}}rrr}}vvv}}}}}}v?^|~~|||~~~~~`..`|?-<br></br>
`}v?>???v??>++++>>>>v}vvv?????v}rrrrrrr}}}}}}rrrrrrrr}r?~~--~|~~~~~~-`.`-+`<br></br>
.^^++++>?>>>>>>>>>>>>v}}v?v????v}}rrrr}zuuxwwr}}}rrr}}r?|~-`-~~~~~~~~-`.`-.<br></br>
.^^||||^^++>>>>>>>>>>>?vv?v?v?^>ww}}r>~||+?rzzzww}}r}}rv^|~--~~~~~~~~~-... <br></br>
.++^^^|~~|+>>>>>>>>?>>+>??vwxuu?^zxw?+^`..`^^^>^~+rrrrr?^^~---~~~~~~~~~`.. <br></br>
.+^^^^^|~~|^+?vvvv}rrv?+^~vzxuuu>>xx}???????>^^^-?rrrrv|^|~---~~~~~~~~~~`. <br></br>
.^^^^^^^^~|?vrrrrr}}rrr+.`~>rwwzw~wxz?????????>v+}rrr}^~~~~---~~~~~~~~~~-` <br></br>
.+^^^^^+^>}rrrrrrr}v}}}????-vwrrx^vxrrwrvv????vx}rrr}+|+++^~``--~~~~~~~~~` <br></br>
.>^^^^^|~+rrrrrr}}r}?v}?`.  vwrwx|}w}rrwzzzzzzz}}rrvvwuuyyur>^>?vv??|-~~~- <br></br>
.>>^+~....?rr}}r}vrrv||--^?vrrrw?|vv}rrr}}}}}}}rr}vruyuuxwr}}}}vv}}rv~~~~- <br></br>
.>>+^` ...-}r}v}r}}}}?}ruyyyu}v}???vvvv}}}}}}}}vvvzuuuuurvvv}r}}vv}rr>-~~- <br></br>
.>>+->. ...-v}}vvvvv?zuwwuuuyzvrw}??????vvvvv??vwuuuuuuyxvv}}}}rrrrrr?-~-- <br></br>
.>>|}y}|-~>????????vzyz}}uuuuxrrrr?vvv?vvvvvvvrxuuuuuzxw}}}v}rrrrr}}v+----.<br></br>
.>>vyuyxvvvvvvvvv?vxyuvvuuuuuxwxrrv??v}rrrrvrxuuuuuyz}xvrrr}}rrr}}vvv~---` <br></br>
`vvyuuzvv}vvvvvv?vzyuz?xuuuuuuryxwvvwwwwzwrxyuuuxzyz}ruwv}rrrrr}vvvv>`---` <br></br>
`rxuyzr?v}vvv?vv}rzyuvzuuuuuuuruxwzyyuuuzwuuuuuyzzzv}ruu}}}rr}vvvvv?`````` <br></br>
.wyyxwr?vv??v}rrrruuzzuuuuuuuywwxuyuuuuwzuuuuuuywv}r}wzwxxv}}vvvv??}>````` <br></br>
`uuxrr}?vv}rrr}}rwzzxuuuuuuuxzxuuuuuuzwuuuuuuuuywvr}r}zux}v}vvvvvv?zw````` <br></br>
-xzrrvv}rrrrrrrr}}wuuuuuuuuzzuyuuuuuzzuuuuuuuuuywvv}vryuw?v}vvvvvv?rx^```` <br></br>
`wr}}}rrrrr}}rr}rxyuuuuuuuwxuxxxxxzwzxxxxxxxxxxxw?}}vzxxr>vvvvvvvv?}r?```` <br></br>


            </div>,

            <div>

`????????????????+vwwwwwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvvvv??v??>>>+^-`...... <br></br>
`}v?>??>>???>>>?>vyyyyyyuxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwvrr}v??>>^^^^^^+^.<br></br>
`rv?+|~-````````+uyyuuuzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrvr}}???vvv??vvv?`<br></br>
`}vv?+~`........ryuzzwzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}}v?v}rvvv}r}v-<br></br>
`vvv??>|-...  .|uyzwwwwwwwwwwwwwwwwwwwwrrrrwwwwwwwwwwwwwww}?vv???vvv??vvv?`<br></br>
`v?????>+|-`.`|?yuwwwwwwwwwwwwwwwwr}}vvvv??vvv}rwwwwwwwwwwr>???>^^+++++++^.<br></br>
.????????>+^+>>?yuwwwwwwwwwwwwww}v}}}}}vvvvvvv?vvrwwwwwwwwr++>?>|`....```. <br></br>
.???????????vv?>wuwwwwwwwwwwww}v}}rvvr}rvvv?vvvv}v}rwwwwww}|^^+^|`........ <br></br>
.>????????vvvvv>?rwwwwwwwwwwrvvr}r?..zzx+.?vv}?r?v}vrwwwww?|^^+^|-.     .. <br></br>
.|>??????vvvvv?+>}}rwwwwwwr}}v}rvu~  ^uww.?rvr}}.^v>vrwr}}^||+>>|~`.   ... <br></br>
.>>?????vvvvv?>++v}v}}rrr}}vv?}r}rr?++rrrvvv}}}}v?v`>v}}}?-|||||||~`.....`.<br></br>
`v????vvvvvv?>++++v}vvvvvv}}v?v}r}vvvvvvvvvvv?vvvvvvvvv}v|~|||||~~~-....-^`<br></br>
`rv???vvvv??>+++++>v}vvvvvvvv?v}rrr}}}}}rrr}}vvv}}}}}}v?^|~~|||~~~~~`..`~?-<br></br>
`}?>>???v??>++++>>>>v}vvv?????v}rrrrrrr}}}}}}rrrrrrrr}r?~~--~||~~~~~~`..-^`<br></br>
.^^++++>?>>>>>>>>>>>>v}}v?v????v}}rrrr}zuuxwwr}}}rrr}}r?|~-`-~~~~~~~~-`.``.<br></br>
.^^||||^^++>>>>>>>>>>>?vv?v?v?^>ww}}r>~|^+?rzzzww}}r}}rv+|~--~~~~~~~~~-... <br></br>
.++^^^|~~|+>>>>>>>>?>>+>??vwxuu?^zxw?+^`..`^^^>^~+rrrrr?^|~---~~~~~~~~~-`. <br></br>
.+^^^^^|~~|^+?vvvv}rrv?+^~vzxuuu>>xx}???????>^^^-?rrrrv|^|~---~~~~~~~~~~`` <br></br>
.^^^^^^^^~|?vrrrrr}}rrr^.`~>rwwzz~wxz?????????>v+}rrr}^~~~~----~~~~~~~~~-` <br></br>
.+^^^^^+^>}rrrr}rr}v}}}???>-vwrrx^vxrrwrvv????vx}rrr}+|+++^~``--~~~~~~~~~` <br></br>
.>^^^^^|-+rrrrrr}}r}?v}?`.  vwrwx|}w}rrwzzzzzzz}}rrvvwuuyyur>^>?vv??|-~~~- <br></br>
.>>^+~....?rr}}r}vrrv||--^?vrrrw?|vv}rrr}}}}}}}rr}vruyuuxwr}}}}vv}}rv~~~~- <br></br>
.>>+^` ...-}r}v}r}}}}?vruyyyu}v}???vvvv}}}}}}r}vvvzuuuuurvvv}r}}vv}rr>-~~- <br></br>
.>>+->. ...-v}}vvvvv?zuwwuuuyzvrr}??????vvvvv??vwuuuuuuyxvv}}}}rrrrrr?-~-- <br></br>
.>>|}y}|-~>????????vzyz}}uuuuxrrrr?vvv?vvvvvvvrxuuuuuzxw}}}v}rrrrr}}v+----.<br></br>
.>>vyuyxvvvvvvvvv?vxyuvvuuuuuxwxrrv??v}rrrrvrxuuuuuyz}xvrrr}}rrr}}vvv~---` <br></br>
`vvyuuzvv}vvvvvv?vzyuz?xuuuuuuryxwvvwwwwzwrxyuuuxzyz}ruwv}rrrrr}vvvv>`---` <br></br>
`rxuyzr?v}vvv?vvrrzyuvzuuuuuuuruxwzyyuuuzwuuuuuyzzzv}ruu}}}rr}vvvvv?`````` <br></br>
.wyyxwr?vv??v}rrrruuzzuuuuuuuywwxuyuuuuwzuuuuuuywv}r}wzwxxv}}vvvv??}>````` <br></br>
`uuxrr}?vv}rrr}}rwzzxuuuuuuuxzxuuuuuuzwuuuuuuuuywvrrr}zux}v}vvvvvv?zw````` <br></br>
-xzrrvv}rrrrrrrr}}wuuuuuuuuzzuyuuuuuzzuuuuuuuuuywvv}vryuw?v}vvvvvv?rx^```` <br></br>
`rrr}}rrrrr}}rr}rxyuuuuuuuwxuxxxxxzwzxxxxxxxxxxxw?}}vzxxr>vvvvvvvv>}r?```` <br></br>


            </div>,

            <div>

`??>>???????????>>rwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvv??v?v?>+>+^-`.````` <br></br>
`}?>>>>+>>>>++++>xyyyyyyxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}rr}v???>++++>>>+.<br></br>
`}v?+~-`````...`wyyyyyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}}}v??vvv??vvvv`<br></br>
`}vv?+~`.... ..>yyyuuuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv}}}v?v}r}vv}r}v-<br></br>
`vvv??>^-`.. .`ryyuzzzwwwwwwwwwwwwwrr}}vvvvv}rwwwwwwwwwwwwvvvvv???vv???vv?`<br></br>
`v?????>+|-``~^xyxwwwwwwwwwwwwwwr}}}}}}}vvv????vrwwwwwwwwwv?????^|^^^^^++^.<br></br>
.????????>>>>>>xyzwwwwwwwwwwwwrvv}r?>xzx?->}v}?v?>}wwwwwwwv++>?>|`........ <br></br>
.??????????vvv?zyzwwwwwwwwwrr}vv}x?  ^uzx>+xvrr}w>??vwwwww?|^^+^|`........ <br></br>
.>????????vvvv?vuzwwwwwwww}}vv}r}uz?>>rzrrv?}}v}}}}v^}wwr}+||^++|~.     .. <br></br>
.^???????vvvv?>+vrrwwwwwr}v}vvrr}vv}}vvv?vvvvv????vv}}vr}v~||^>>^~-.   ... <br></br>
.?>?????vvvv?>++?}}}}rr}vvv}vvrrrr}}}}}}}}}}}}}vv}}}vvvvv+~|||||||~`.....`.<br></br>
`v????vvvvv?>+++^v}vvvvvvv}}vv}}rrrrrrr}rzxzzwr}}}rrrrr}v||~|||||~~~`...`^.<br></br>
`}????vvvv?>+++++>vv}vvvvvvv??v}rrrrrrv?v?????vvv}}}}}}}r?~~~||~~~~~-...->`<br></br>
`v?>>?????????>?v}}vv}v}v?vv^^vv}rrrrv~~-`-^+^|~-+>>>}}}rv--~~|~~~~~~`..`^.<br></br>
.^^+^+??v}}rrrrrrrr}?v}vrzuuu>>zw}rr}| .   .^+++^~-`~}r}rv-`-~~~~~~~~-`..`.<br></br>
.-```vrrrrr}rrrv}}}r~`-^zxxxxz~rxw}}v--~`..`^+~^>+~-|}r}r?~--~~~~~~~~~-`.. <br></br>
 .`.-rr}rrrv}rrvvv}v+++^>wzzzx>?xxr}??????????>|^+^~|rrrr+|---~~~~~~~~~-`. <br></br>
 .`.-}r}vrrvv}}}??v>|+^|>wrwzx>?xxr}???????????>?v>~^rrr?~~~--~~~~~~~~~~`` <br></br>
 ....vrrv}r}v}}}-...   .vwrrwx~vxz}}?}???????????rxr?rrv~~~-`--~~~~~~~~~-` <br></br>
 ...^?}r}v}}vvvv|~~~^?>?rrrwz?^xzv}}vu}v??????????wu}rv>>??>+|--~~~~~~~~~- <br></br>
.^^}u}?v???v????v?vvvyyxwr}v++r}vvvv}rzuuxxzwwwwwzxw}vruyyyyyuv`~~~~~~~~~- <br></br>
.>zxr?vvvvvvvv???vvrvuuuuuzvv}vv?vvvv}}}rwxuuuuuxz}vvzyuuuuuuuw^-~~~~~~~~- <br></br>
`xz}}vvvvvvvvvv?wuwxvxuuuuuyu}}r}v?vvvvvvvvv}}}vvv?}xyuuuuuuyw}?`~~~------ <br></br>
`w}rv}}vvvvvvvv}uzzwvuuxuuuuyrvrr}???????vvvv?v??vzuuuuuuuxzw}}vvvvv??~--- <br></br>
`r}r?vvvv??v}zxyxwxrvxrzuuuuyw}rrr?vvvv?v}v}}}vvwuuuuuuwur}}}}v}vvvv}r?--` <br></br>
`vvvvvv}rrrrxyyu}vrrvvwuuuuuywzrrr??vv}rrrrr}}zuuuuuyu}zyzvvv}}rr}vvvrr|-` <br></br>
`}rrrrrr}}}ruyuwv}vvvwyuuuuuywxxr}rzuuxxxxwwxuuuuuuyx}rxxrr}}r}}}}rrr}}|-` <br></br>
`rr}}}}}}}}wuxzwv}v}uyuuuuuuyzzxwxyyuuyyxwxuuuuuuxuz}}w}}}r}vv}r}}r}vvv~-` <br></br>
`rr}}}rr}}ruuzr}}v}uuuuuuuuuuwwuuuuuuuuzzuuuuuuuurr}r}x}}}rrr}}rr}vvvv?`-` <br></br>
`rr}}rrrrrwzr}}}vruuuuuuuuuzzxuuuuuuuxwxuuuuuuuuy}rrrrzrv}rrrrr}vvvvvv^``` <br></br>
`rr}}rrr}vv}}rrvwuuuuuuuuxwxuuuuuuyuzxuuuuuuuuuuyr}r}}}}}}}rrr}vvvvvv>```` <br></br>
`rr}}r}vv}rrr}vwyuuuuuuuzzuuuxxxxxzwxxxxxxxxxxxxxw?vvvrzw}vr}vvvvv?>~````` <br></br>


            </div>,

            <div>

`??>>???????????>>rwwwwwwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvv??v?v?>+>+^-`.````` <br></br>
`}?>>>>++>>>++++>xyyyyyyxzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}rrrv???>>>+>>>>>.<br></br>
`}v?+~-`````...`wyyyyyxwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwv}}}}v??v}vvvv}vv-<br></br>
`}vv?+~`.... ..>yyyuuuwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrv}}}v??}r}vvv}}v-<br></br>
`vvv??>|-`.. .`ryyuzzzwwwwwwwwwwwwwrr}}vvvvv}rwwwwwwwwwwwwvvvvv?>??????v??`<br></br>
`v?????>+|-``~^xyxwwwwwwwwwwwwwwr}}}}}}}vvv????vrwwwwwwwwwv?????^~|||^^^^|.<br></br>
.????????>>>>>>xyzwwwwwwwwwwwwrvv}r?>xzx?->}v}?v?>}wwwwwwwv+++?>|`........ <br></br>
.??????????vvv?zyzwwwwwwwwwr}}vv}x?  ^uzx>+xvrr}w>??vwwwww?|^^+^|-........ <br></br>
.>????????vvvv?vuzwwwwwwwwr}vv}r}uz?>>rzrrv?}}v}}}}v^}wwr}+||^++^~`.    .. <br></br>
.^???????vvvv?>+vrrwwwwwr}v}vvrr}vv}vvvv?vvvvv????vv}}vr}v~||^>>^|~..  ... <br></br>
.?>?????vvvv?>++?}}}}rr}vv}}vvrrrr}}}}}}}}}}}}vvv}}}vvvvv+~|||||||~-.....`.<br></br>
`v????vvvvv?>+++^v}vvvvvvv}}vv}}rrrrrrr}rzxxzwr}}}rrrrr}v||~|||||~~~`...`|.<br></br>
`}????vvvv?>+++++>vv}vvvvvvv??v}rrrrrrv?v??????vv}}}}}}}r?~~~||~~~~~-...-+`<br></br>
`v?>>?????????>?v}}vv}v}v?vv^^vv}rrrrv~~-`-^+^|~-+>>>}}}rv---~|~~~~~~`..`|.<br></br>
.^^+^+??vv}rrrrrrrr}?v}vrzuuu+>zr}rr}| .   .^+++^~--~}}}}v-`-~~~~~~~~~`..`.<br></br>
.-```vrrrrr}r}rv}}}r~`-^zxxxxz~rxw}}v--~`..`^+~^>+~-|}r}r?~---~~~~~~~~-`.. <br></br>
 .`.-rr}rrrv}rrvvv}?+++^>wzzzx>?xxr}??????????>|^+^~|rrrr+|---~~~~~~~~~-`. <br></br>
 .`.-}r}vrrvv}}}??v>|+^|>wrwzx>?xxr}???????????>?v>~^rrr?~~~--~~~~~~~~~~`` <br></br>
 ....vrrv}r}v}}}-...   .vwrrwx~vxz}}?}???????????rxr?rrv~~~-`--~~~~~~~~~-` <br></br>
 ...^?}r}v}}vvvv|~~~|?>?rrrwz?^xzvv}vu}v??????????wu}rv>>??>+|--~~~~~~~~~- <br></br>
.^+}uv?v???v??????vvvyyxwr}v++r}vvvv}rzuuxxzwwwwwzxw}vruyyyyyuv`~~~~~~~~~- <br></br>
.>zxr?vvvvvvvv???vvrvuuuuuzvv}vv?vvvv}}}rwxuuuuuxz}vvzyuuuuuuuw^-~~~~~~~~- <br></br>
`xz}}vvvvvvvvvv?wuwxvxuuuuuyu}}r}v?vvvvvvvvv}}}vvv?}xyuuuuuuyw}?`~~~------ <br></br>
`w}rv}}vvvvvvvv}uzzwvuuxuuuuyrv}r}???????vvvv?v??vzuuuuuuuxzw}}vvvvv??~---.<br></br>
`r}r?vvvv??v}zxyxwxrvxrzuuuuyw}rrr?vvvv?v}v}}}vvwuuuuuuwur}}}}v}vvvv}r?--` <br></br>
`vvvvvv}rrrrxyyu}vrrvvwuuuuuywzwrr??vv}rrrrr}}zuuuuuyu}zyzvvv}}rr}vvvrr|-` <br></br>
`}rrrrrr}}}ruyuwv}vvvwyuuuuuywxxr}rzuuxxxxwwxuuuuuuyx}rxxrr}}r}}}}rrr}}|-` <br></br>
`rr}}}}}}}}wuxzwv}v}uyuuuuuuyzzxwxyyuuyyxwxuuuuuuxuz}}w}}}r}vv}r}}r}vvv~-` <br></br>
`rr}}}rr}}ruuzr}}v}uuuuuuuuuuwwuuuuuuuuzzuuuuuuuurr}r}x}}}rrr}}rr}vvvv?`-` <br></br>
`rr}}rrrrrwzr}}}vruuuuuuuuuzzxuuuuuuuzwxuuuuuuuuy}rrrrzrv}rrrrr}vvvvvv^``` <br></br>
`rr}}rrr}vv}}rrvwuuuuuuuuxwxuuuuuuyuzxuuuuuuuuuuyw}r}}}}}}}rrr}vvvvvv>`-`` <br></br>
`rr}}r}vv}rrr}vwyuuuuuuuzzuuuxxxxxzwxxxxxxxxxxxxxw?vvvrzz}vr}vvvvv?>~````` <br></br>


            </div>,

            <div>

`???????????v~.`.`^|vwrww>~~~~?v?vvvvvvvvvv???????????v?vvvv?>>>>>~``````` <br></br>
`}vvvvvvvv?vr^..`+>+vyuyyv~||?wrrrrrrrrrrrrrrrrrrrrrrrrr}wzzwv????>>>>>??>.<br></br>
`vvvv??????>??~.^>++>uuzz}^>vwrrrrrr}rrrr}}}rrrrrrrrrrrrr}wwww}??v}vvvv}}v-<br></br>
`v}r}v??>>++||^?rv???zzwwwrwr}}}rr}}}}}}}}rrrrrrrrrrrrrrrr}wwwrvvv}}vvv}}v-<br></br>
`vrrr}vv?>+^^|^}xuxzwzrrrrr}}}rrrrrr}}}vv}}}}rrrrrrrrrrrrr}rrrrvvv????????`<br></br>
`vrrr}}}vv?>>??wzwr}}}}}rrrrrrrrr}}}}}}}v}}vvvv}}rrrrrrrrr}}}}}}v??^||||^|.<br></br>
.vrr}r}}}}}v}}vzxwrrrrrrrrrrrrr}rwwvvxxxv>vwrw}rr}wzzrrrrr}vvv}}v?>+`..... <br></br>
.}rr}}}}}}rrrr}zxwrrrrrrrrrrrrwzxyr^|?xzz?>rv}}v}?}}wzrrrrvvvvvvv?>+~  ... <br></br>
.v}}}}}}}rrwww}}zwrrrrrrrr}}wwzw}rv>+^????>>?????>v}?wv}r}??vvvvv?>^^`  .. <br></br>
.?}}}}}}rrwwwr}v}rrrrrrr}}}rr}???????????????????>}rw?>?}}???v}}v??+^~. .. <br></br>
`?v}}}rrrwrwr}}vv}}}}r}}}}}r}??????????????????????v?>>>??>??vv?????+^...`.<br></br>
`v?v}rrwwrrr}vvvv}}}}}}}}v}rv???????>>??>^+++>??????????>>>?????????>+-.`^.<br></br>
`v??v}rrrrr}}vvvvv}}}}}}}?????????+^^^>?>~~~-~~^??????????>>?????????>^.-+`<br></br>
`v???vv}rr}}}}v}}}}}}}}}v>??++???^^++^~~-`.--`...+>>>????>++>?????????+.`~.<br></br>
.>>>???v}}}rrrrrrrr}v}}}vv}}}>>v+|+>>`.`.. .--``.`||^????>|+>?????????>..`.<br></br>
.^^|^v}}}}r}rrr}}}}r?>>?vv}}vv|?>>+>^``~`...--`-``~^^???v+-++>????????>`.. <br></br>
.|^|^vvv}}}}rrr}v}}v????>vvvvv+|??>>|^^^^|^^^^~```~^^????|-++>????????+-`. <br></br>
.|^|^vvvv}}}}rr}vvv?????>vvvvv>|??>+~^^^^^^^^^^||++^+??v>--^++>???????|--` <br></br>
.||~~v}vvv}}}}rr>^^|^||^?vvvvv|???+>+?++^^^^+>>??}w}??vv^-`|++>>?>>??>~~-` <br></br>
.~~~>vvvvvvvv}}}?????vvvvvvvv>^vv+??vwv??>>>??????}wvvv}v?>>??>>??>>?+-~--.<br></br>
.^+vwvvvvvvvvvvvvvvv}xxzvv??++v????vvvrwwwr}}}}}rrw}vv}zyyyxxx}>>>>>>^|+>?`<br></br>
.vzxrvvvvvvvvvvvv}}r}zxxr}}vv}v???vvvvvvv}rwwwwwrrvvvrzwxuuzxxw?>>>>>>????`<br></br>
`xxzzr}vvvvvvvvvzxzz}zxxw}xyyz}}vv????vvvvvvvvvvv??vwzzwwyuzxwrv>>>>+?????`<br></br>
`zzzwrr}}vvvvvvrxzzw}zzzzryuurv}}v????????????v??vrzzwwrzyuww}}}v}}}rr????`<br></br>
`wzzr}rr}}}vvrwxzzzw}zwzxwuuyr}}}}?v??vvvvvvvvvvrwwwwzzzuxr}}}}}}}v}zxw???`<br></br>
`wwwwwwwwwzwrzzzrrww}rwxxzxyywr}}}v?vv}}}}}}vvrwzzzxuuxuuzv}v}}}r}}wzxx???`<br></br>
`wzzzzzzwwwzwzwrvr}rrwxxxxzuyuxzr}}rwwwwwwwrzxuuuuyyuzzxzr}}}r}}}}zxxxxv>?`<br></br>
`zzwwwwwwwwzzrrrv}}rxxxxxxxzuuuuuuuuuuuuuxuyyyyyyuuzrwwr}}r}}}r}}zxxzzz?>?`<br></br>
.rzwwwwwwwzxzrr}}v}zxxxxxxxxzzzuuuyyyyyuuyyuuuuxxwwrwwzr}}}rrr}}wxzzwzr?>?`<br></br>
.vzwwzzzzzzx}v}}v}zzzzxxxxxzzxxxxxxxxzzxxxxxxxxxxrwwwwzw}}rrr}}wzzwzzzv>>?`<br></br>
.vzwwzzzwwww}}vvrzzzzzzzxzzxxxxxxxxxzzxxxxxxxxxxxwrwwwwwrrrr}rwzzzzzzr?>>?`<br></br>
.?zwwzwwrwzrrrvrzwzzzzzzwwxxxzzzzzwwzzzzzzzzzzzzzw}}}}wwwrv}rrrwwww}??>>>>`<br></br>


            </div>,

            <div>

`v??vvvv???vw-      ?wvrw-    ?r>??vvvvvvv??>+>>>>>>????vvv???????^-``---` <br></br>
`rv}rrrrrr}wy+      +yxxy>   >u}}}rrrrrr}}}}}v}}}}}}}rrrrrrrrvvvv????>????`<br></br>
`vv}}}rrrrr}zx+.    .xuzy}.~vzvvr}}vvvvvv???}vrrrrrrrrrrrrrrrr}v?v}}vvv}}v-<br></br>
`vv}r}}}}}rrvvr}?^~--ruzxzrw}??v}vv?vv???vvv}}rrrrrrrrrrrrrrrrr}vv}}vvv}}v-<br></br>
`v}}rr}rrrrrrv?v}rrwwrrr}vv??v}}}}}}}}vvv}}}}}}}}}}}}}rrrrrrrrrrrv????????`<br></br>
.v}}rrrrrrrrr}vvvvvvvvvvvvv}}}}rr}}}}}}}}}}}}}}}}}rr}}r}}}}rrrrrrrv+||||^|.<br></br>
.v}rrrrrrrrrrrrr}}}}}}}}rrrr}}rwwzzzzxxxxxzzzzzxxuyyur}}}}}rrrrrrrrv`..... <br></br>
.}}rrrrrrrrrrrrrrrrrrrrrr}}}ruyyyyyyuzwr}vv????v??ruyuvr}}}}rrrrrrrr?..... <br></br>
.}}rrrrrrrrrrrrrrrrrrrrrr}}ryuuwv?>>^^^||||||||~-`?zux|v}}}}}rrrrrr}r| ... <br></br>
.v}rrrrrrrrrrrrrrrrrrrrrrr}uur^||^+++>>>>>>>>>>++^rzx>`^}}}}}}rrrrrrrv. .. <br></br>
`v}rrrrrrrrrrrrrrrrrrrrrr}rzz>^>>>>>>>>>|-~|^+>+++>>^-~->r}}}}rrrrrrrr~ .`.<br></br>
`vv}}rrrrrrrrrrrrrrrrrrrrvwxw++>++^~``^+.    .|++++++|~~~v}}}}}}rrrrrr>.`^`<br></br>
`vvvv}}rrrrrrrrrrrrrr}}r}|>?>^+++-.   ~+~.     `^+++>+^~-?r}}}}}rrrrrrv.`+`<br></br>
`??vvvv}rrrrrrrrrrr}}}}}?^++++++-   ..~|~`...   .^+++++^~~}}}}}}rrrrrrv.`~.<br></br>
`v??vvvv}rrrrrrrrr}}}}}}>^>>+++|   ...~|~`.....  `++++>>^-?r}}}}}}rrrr}`.`.<br></br>
`vv??vvvv}rrrrrrrr}}}}}}>++>+++`  ....`--........ |>++>>>^+r}}}}}}}rrrv`.. <br></br>
`vvvvvvvvvrrrrrrrr}}}}}}>++>+++. ................ ->++>>+++}}}}}}}}rrr?`-. <br></br>
`vvvvvvvvvvrrrrrrr}}}}}}>++>+>>`................``~++++++++vr}}}}}}rrr+-~` <br></br>
`vvvvvvvvvv}rrrrrr}}}}}}>+>++>>^....`--`....`~^>????>+>?v>|?r}}}}}}}r}~~~` <br></br>
`vvvvvvvvvvv}rrrrr}}}}}}>++++>+>|`~+???>>+^+??????????vyuw??rrrrr}}}r?`~--.<br></br>
`vvvvvvvvvvvvrrrrr}}}}}}>+++^++++>?????????????????????}uyy}}rrrrrrrr?>vrz|<br></br>
`wwr}vvvvvvvv}rrrr}}}}}}?+>vr}?>????????????????????????wux}}rrrrrrrrzyyyy|<br></br>
`wwwwww}vvvvvv}rrr}}}}}r?+ruyxv????????????????????????>?yu}r}}rrrrrvuuuuy|<br></br>
`wwwwwwww}vvvv}rrr}}}}}}v?yuuv?????????????????????????>vyxv}}}}rrr}wyuuuy|<br></br>
`wwwwwwwwwrvvvv}rr}}}}}}}vxuyv???????????????????????v}xyur}}}}}}r}ryuuuuy^<br></br>
`wwwwwwwwww}vvvv}}}}}}}}}vwyu}????????????????????}wxyyyxr}}}}}}r}}uuuuuuy^<br></br>
`wwwwwwwwwwwvvvvv}}}}}}}}r}xyyzrvvv??????vvv}rwzxuyyyuzr}r}}}}}}rvxuuuuuuy^<br></br>
`wwwwwwwwwwwv?vvvvv}r}}}}}r}zuyyyyuuxxxxuuyyyyyyyuuzw}}r}}}}}}r}vzuuuuuuuy^<br></br>
.}wwwwwwwwwwv?vvvvvv}rr}}}}r}rzzxuuyyyyyyyyuuxzwwrr}rrr}}}}}}r}vzuuuuuuuuy^<br></br>
 ?wwwwwwwwwwv?vvvvvvv}r}}}rrrrr}}}rwwwwwwwrrr}}}}rr}}}}}rrrrrvvwuuuuuuuuuy^<br></br>
 >wwwwwwwwwwvv?vvvvvvvvv}}rrrrrr}}}}}}}}}}}}}}}}}}}rrrrrrrr}vvwuuuuuuuuuuy^<br></br>
 >zwwwwwwww}r}vv?vvvvvvvvv}rr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}vv}}zxxxxxxxxxxx|<br></br>


            </div>,

            <div>

.?vvv?-.  +wrr}v}vv}v?>>????>?????>?????vvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvv-<br></br>
`v}wwzw}v??zzzzwzww}vvvv}}}}}}vvvvvv}rrwwwwwwwzzxzxzwwrrrrrrrrrrrrrrrrrrrr~<br></br>
`vvv??v}rrrrrr}}vvvvvv}}}}}}}rrrrrrrrrrrrwzxxxxuuyyyuww}}rrrrrrrrrrrrrrrrr~<br></br>
`}vvvv????vvvvvvvv}}}rrrrrrrrwwwwwwwwzzzxxxxxzzwwzuuuuwvv}rrrrrrrrrrrrrrrr~<br></br>
`}r}}}}}}}}}}}}}}}}rwwwwwzxxuuuuuuxxzwrv??>>^^||-^wxuyx~?vv}rrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrr}wzwrwuuuuuxzw}v?>+||||||^^^^^^|~>wwww`~?vv}rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrr}rwzrwuuxzrv>^|||^^+++>>>>>>++++++^}rw?--~?vv}rrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrr}wwrruzr}+~~~^++>>>>>>>>>+++++++++^>??+||~|?vv}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}rww}xuzw?~^+>>>>>>>>>>>+|~-`--~|^^++++++^|~^vvv}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}www}uzwr++>>>>>>++++>>>^.     ..`~|^+>>>>+|~^vvvrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}wwrrzwwv^+>+>++|`...|>>^.        .`||+>>>>+||+vv}rrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrww}rzrwv^>+++^-.    `+^^` ....     `||^+>>>+^~>vv}}rrrrrrr~<br></br>
`}rrrrrrrrrrrr}rwwvrrrv+++++|`     .`~-~~`.......   `||+>+>>+|~?vv}}rrrrrr~<br></br>
`}rrrrrrrrrrrrr}}r?~+>+++>+^~    ...`^^|~|`........  -|^++>++^~|vvv}}rrrrr~<br></br>
`}rrrrrrrrrrrrrvvv>~++>>+>+|`   ....`|`~~|-......... .|^>^^>++|~+vvv}rrrrr~<br></br>
`}rrrrrrrrrrrrr}vv>|+>>>+>+|.  ......~>?>~`...........~^>+|^++^|~>vvv}rrrr~<br></br>
`}rrrrrrrrrrrrr}vv>|+>>>>>+|. .........`..............~^>>^|+++|~|?vv}rrrr~<br></br>
`}rrrrrrrrrrrrr}vv>~+>>>>>+|` ........................~^+>+|^^^|~~+?vv}rrr~<br></br>
`}rrrrrrrrrrrrr}vv?|+>>>>>+|~.....................`~~~|^++>?v?>>|-~?vv}rrr~<br></br>
`}rrrrrrrrrrrrrr}v?|+>>++>+^|-.................`~|+>???>++rxuxxuxv^>}vvrrr~<br></br>
`}rrrrrrrrrrrrrr}vv|^++++>>+^|`.......````...`~^>>???????>?ruyuuyyxv}wrrrr~<br></br>
`}rrrrrrrrrrrrr}}vv^|++^^+>>+^^-...`-|^+++^|~~+>???????????+vrzyyuxzvzwrrr~<br></br>
`}rrrrrrrrrrrrr}}vv>~^++||^>>>+^|`-^>>??????>>??????????????>>wuuuux}rwwrr~<br></br>
`}rrrrrrrrrrrrr}}vv?~||^^~~|++++^^>???????????????????????????>}uuuuzrwwrr~<br></br>
`}rrrrrrrrrrrrrr}vv?~~|~||-|>?}?>??????????????????????????????>}zzuurwwrr~<br></br>
`}rrrrrrrrrrrrrrr}vv>`~~~-^}wzw???????????????????????????????>+?wuuxrwwwr~<br></br>
`}rrrrrrrrrrrrrrrr}vv|`~-~rxxz}??????????????????????????????>>vxuuuv}wwwr~<br></br>
`}rrrrrrrrrrrrrrrrrvvv~-->uyuyw??????????????????????????????rxuuux}rwwwwr~<br></br>
`}rrrrrrrrrrrrrrrrr}vvv~`?yyyyx???????????????????????????}zuuuuxzrwwwwwwr~<br></br>
`}rrrrrrrrrrrrrrrrrr}vv?~?uxxuyv??????????????????????v}wxuuuuxzwwzwwwwwrr~<br></br>
`v}rrrrrrrrrrrrrrrrrr}vv??zuuuu}???????????????????vrzuuuuuuxwwwzwwwwwwwrr~<br></br>
`vv}}rrrrrrrrrrrrrrrrr}vv}ruuzzr>???????????????}rzxxxxxxxwrrwwwwwwwwr}}}}~<br></br>


            </div>,

            <div>

`?^-`>}vvvv}}v?>>????????????????vvv????vrwwwwwwwwrrv??vvvvvvvvvvvvvvvvvvv-<br></br>
`zxzzzzzzzwrvv?vvv}rrrrrrrrrrr}}}}vv}rzuyyyyyyuuuyyyyurrrrrrrrrrrrrrrrrrrr~<br></br>
.vvvvvvvvv??vv}}}rrrr}}vvvvvvv}rwwxxuyxzrv?>^|~-?zzzxxu+vrrrrrrrrrrrrrrrrr~<br></br>
`?v?????vv}}}rrrrrr}vvrwzxxuyyyyxwv?>^~~-------``>wr}wz-`?rrrrrrrrrrrrrrrr~<br></br>
`}}}}}}}rrrrrrrrr}vruyyyyyyuzv?+|~~|^^++++++++++^~?zxxz-.`+rrrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrrvzyyyyyzv>|--|^++>>>>>>>>>>++++++^}rrv~--`^}rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrvryyyx?^-`-|^+++>>>>>>>>>>>>>>+++++^++++^|~-^}rrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}vuyxw>.`~|+>>>>>>>>>>>>>+^^|||^^++>>++>>>+^|-^}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}wyuxw~~|+>>>>>>>>>>>>>>^`      ..`|+>>>>>>>+^-^}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}uyux}-^>>>+>>>>+^||^>>>-           .|>>>>>>>>^-+rrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrvuzzz?~>>>++>>+-.    |+>|.            -+++>>>>>^~>rrrrrrrrr~<br></br>
`}rrrrrrrrrrrr}vxxzww>++++++|.      ~||^-.........    `+>>>>>++|~?}}rrrrrr~<br></br>
`}rrrrrrrrrrrr}?wrrxz>+++>+~     ...-|~+^~..........   |>>>++>>+|~?}}rrrrr~<br></br>
`}rrrrrrrrrrrrr>?vvv++++>>+.    ....++^|||~........... `>>>+|+>>^-~?}rrrrr~<br></br>
`}rrrrrrrrrrrrr>.-^+>>++>>|    .....^``~|~^........... `>>>>||>>+~-|?rrrrr~<br></br>
`}rrrrrrrrrrrrr?`~+>>>+>>>~   ......-^???^-............`>>>>+-^>+|~-+}rrrr~<br></br>
`}rrrrrrrrrrrrrv~~+>>>>>>>-  ........````..............`>>>++--++|~~~?rrrr~<br></br>
`}rrrrrrrrrrrrr}~~+>>>>>>>~  ..........................`>>>>+-`^+|~~~|}rrr~<br></br>
`}rrrrrrrrrrrrrr^~+>>>>>>>^  .......................```-+>++>?}r?|^|``?rrr~<br></br>
`}rrrrrrrrrrrrrr>~+>>>>>>>>` .....................~^+>>>++|vxuxzxxxzv||rrr~<br></br>
`}rrrrrrrrrrrr}r?-^++^+>>>>+...................`~+>???????+>}zyyyuuyyu}vrr~<br></br>
`}rrrrrrrrrrrr}vv~~++||>>>>>+-.........`````..-^>???????????+>ryx}wyyyuv}r~<br></br>
`}rrrrrrrrrrrrrvv|-^>^~|+>>>>+|`....`|+>>>>+^||>??????????????>?}xyyuwxz}r~<br></br>
`}rrrrrrrrrrrrr}?+-|++|-~^+>>>>>^-`^>????????>+????????????????>+vuyuuxurr~<br></br>
`}rrrrrrrrrrrrrr}?~-~^+|`-~^+++++^>??????????????????????????????+>ryxxyz}~<br></br>
`}rrrrrrrrrrrrrrrv|--~|^~```~+v}+????????????????????????????????>+^vuzxu}~<br></br>
`}rrrrrrrrrrrrrrrr?`-~~~|~-?wzuw>????????????????????????????????>>>^vxyy}~<br></br>
`}rrrrrrrrrrrrrrrrr+`~~~-`>zzxx}?????????????????????????????????>+?wyyyyr~<br></br>
`}rrrrrrrrrrrrrrrrrr+-~~-|xuxxzv????????????????????????????????+?ryyyyyyr~<br></br>
`}rrrrrrrrrrrrrrrrrrr+-~`+yyyyyr??????????????????????????????>?wyyyyyyyw}~<br></br>
`}rrrrrrrrrrrrrrrrrrr}+-`>yxxuyz?????????????????????????????vzyyyyyyyur}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrrr+`?zzxxux???????????????????????????vzuuuuuuuxrv}}}~<br></br>


            </div>,

            <div>

`?^-`>}vvvv}}v?>>????????????????vvv????vrwwwwwwwwwrv??vvvvvvvvvvvvvvvvvvv-<br></br>
`zxzzzzzzzwrvv??vv}rrrrrrrrrrr}}}}vv}rzuyyyyyyuuuyyyyurrrrrrrrrrrrrrrrrrrr~<br></br>
.vvvvvvvvv??vv}}}rrrr}}vvvvvvv}rwwxxuyxzrv?>^|~-?zzzxxu+vrrrrrrrrrrrrrrrrr~<br></br>
`?v?????vv}}}rrrrrr}vvrwzxxuyyyyxw}?>^~~-------``>wr}wz-`?rrrrrrrrrrrrrrrr~<br></br>
`}}}}}}}rrrrrrrrr}vruyyyyyyuz}?+|~~|^^++++++++++^~?zxxz-..+rrrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrrvzyyyyyzv>|--~^++>>>>>>>>>>++++++^}rrv~--`^}rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrrvryyyxv^-`-|^+++>>>>>>>>>>>>>>+++++^++++^|~-^}rrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}vuyxw>.`~|+>>>>>>>>>>>>>+^^|||^++>>>++>>>+^|-^}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}wyuxw~-|+>>>>>>>>>>>>>>^`      ..`|+>>>>>>>+^-^}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrr}uyux}-^>>>+>>>>+^||+>>>-           .|>>>>>>>>^-+rrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrrvuzzz?~>>>++>>+-.    |+>|.            -+++>>>>>^~>rrrrrrrrr~<br></br>
`}rrrrrrrrrrrr}vxxzww>++++++|.      ~||^-.........    `+>>>>>++|~?}}rrrrrr~<br></br>
`}rrrrrrrrrrrr}?wrwxz>+++>+~     ...-|~+^~..........   |>>>++>>+|~?}}rrrrr~<br></br>
`}rrrrrrrrrrrrr>?vvv++++>>+.    ....++||||~........... `>>>+|+>>^-~?}rrrrr~<br></br>
`}rrrrrrrrrrrrr>.-^+>>++>>|    .....^``~|~^........... .+>>>||>>+~-|?rrrrr~<br></br>
`}rrrrrrrrrrrrr?`~+>>>+>>>~   ......-^???^-.............+>>>+-^>+~~-+}rrrr~<br></br>
`}rrrrrrrrrrrrrv~~+>>>>>>>-  ........````..............`+>>++--++|~~~?rrrr~<br></br>
`}rrrrrrrrrrrrr}~~+>>>>>>>~  ..........................`+>>>+-`^+|~~~|}rrr~<br></br>
`}rrrrrrrrrrrrrr^~+>>>>>>>^  .......................```-++++>?}r?|^|``?rrr~<br></br>
`}rrrrrrrrrrrrrr>~+>+>>>>>>` .....................-^+>>>++|vxuxzxxxzv||rrr~<br></br>
`}rrrrrrrrrrrr}r?-^>+^+>>>>+...................`~+>???????+>}zyyyuuyyu}vrr~<br></br>
`}rrrrrrrrrrrr}vv~~++||>>>>>+-.........`````..-^>???????????+>ryx}wyyyuv}r~<br></br>
`}rrrrrrrrrrrrrvv|-+>^~|+>>>>+|`....`|+>>>>+^||>??????????????>?}xyyuwxz}r~<br></br>
`}rrrrrrrrrrrrr}?+-~++|-~^+>>>>>^-`^>????????>+????????????????>+vuyuuxurr~<br></br>
`}rrrrrrrrrrrrrr}?~-~^+|`-~^+++++^>??????????????????????????????+>ryxxyz}~<br></br>
`}rrrrrrrrrrrrrrrv|--~|^~-``~>v}+????????????????????????????????>+^vuzxu}~<br></br>
`}rrrrrrrrrrrrrrrr?`-~~~|~-?wzuw>????????????????????????????????>>>^vxyy}~<br></br>
`}rrrrrrrrrrrrrrrrr+`~~~-`>zzxx}?????????????????????????????????>+?wuyyyr~<br></br>
`}rrrrrrrrrrrrrrrrrr+-~~-|xuxzzv????????????????????????????????+?ryyyyyyr~<br></br>
`}rrrrrrrrrrrrrrrrrrr+-~`+yyyyyr??????????????????????????????>?wyyyyyyyw}~<br></br>
`}rrrrrrrrrrrrrrrrrrr}+-`>yxxuyz?????????????????????????????vzyyyyyyyur}r~<br></br>
`}rrrrrrrrrrrrrrrrrrrrr+`?zzzxux??????????????????????????>vzuuuuuuuxrv}}}~<br></br>


            </div>,

            <div>

`}rr}vv?>>????????????vvvv??????vrwwwww}v?>^``?}vv}r}???vvvvvvvvvvvvvvvvvv-<br></br>
`ww}vv??vvv}rrrrrrr}}}vvvv}}rwzuyyxwv?+|~-`````|}xzwrwu?+rrrrrrrrrrrrrrrrr~<br></br>
.???vvvv}}rrrrrr}}vvvrwzxuyyyyzv?^~--~~|^^^^^|~-~vzzwzz+.|vrrrrrrrrrrrrrrr~<br></br>
`vvv}}rrrrrrrrrvv}zuyyyyyyxr?^---~|+++>>>>>>>++++^?xxz}~``-?rrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrv}uyyyyyyzv>~`-~|^+>>>>>>>+++++++^++^>?>+^^|-->rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrvryyyyyxv+-`-~|++>>>>>>>>>+>>>>>>>>++++++>>>+^|-+}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrr}}yyyuz?~`-~|++>>>>>>>>>>>>>>>+^|||^++>>>++>>>>>+~^}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrvzyyxr~`--|++>>>>>>>>>>>>>>+~..     .`-^>>>>>>>>>+~|vrrrrrrrrr~<br></br>
`}rrrrrrrrrrvuyuzr--~|+>>>>>>>>>>>>>>>>-            `|>>>>>>>>>|~vr}rrrrrr~<br></br>
`}rrrrrrrrr}}yuuxw+-|+>>+>>>>+^-``~^>>>`              `+>>>>>>>+~~vv}rrrrr~<br></br>
`}rrrrrrrrr}}xxxuuv-+>>+>>>>~.     .^++^..........     .^>>>>>>>+~|?vrrrrr~<br></br>
`}rrrrrrrrr}vuuzzwz?++++>>+`       `+|~+^`...........   .+>>+^>>>+~~?vrrrr~<br></br>
`}rrrrrrrrr}|vzwwwxw+++>>+`     ...~^+|>>^`...........   ->+>||>>>^-~?}rrr~<br></br>
`}rrrrrrrrrr|`vwwrv+++>>>~    .....^>|```~^............  .+>>|`^>>+~-~?}rr~<br></br>
`}rrrrrrrrrr^`-|^+++>++>+.   ......|~.-|^-+`............ .^>>^`-^>+|~-~vrr~<br></br>
`}rrrrrrrrrr?---^+>>>+>>^   .......`|^vvv+|............. .^>>^-`-+>|~~-+}r~<br></br>
`}rrrrrrrrrr?---^+>>>>>>|   .........-~~-`.............. .+>>^~``|+|~~~-?r~<br></br>
`}rrrrrrrrrrv~-~^+>>>>>>|   .............................`>>>|-`-^>+-~~-~v~<br></br>
`}rrrrrrrrr}r^-~^>>>>>>>^.  .............................^>>+~>vzxzz?~~``+~<br></br>
`}rrrrrrrrr}}?`~^>>>>>>>>`  ........................-|^^^+++~vuxzrwzzwz}^`.<br></br>
`}rrrrrrrrrrvv~~^>++>>>>>+.  .....................-^+>????>+^>vrrxyuuyuyxw~<br></br>
`}rrrrrrrrrrvv^~^>+|^>>>>>+`  ..................~^>??????????>++>wyuz}wuyy^<br></br>
`}rrrrrrrrrr}?>-|+>^-^>>>>>+~. ........`````..-|+>?????????????>++?rrxyyux|<br></br>
`}rrrrrrrrrrrv?~-^>+~-|+>>>>>+~`.....~^+>>++^^^^>???????????????>>++vyyxwx^<br></br>
`}rrrrrrrrrrr}v+-~^+^---^+>>>>>++~`~+>??????>+^+??????????????????>>+?zuuy|<br></br>
`}rrrrrrrrrrrr}?~-~^+^-`-~|+>>>>>++????????????????????????????????>>^+wyx|<br></br>
`}rrrrrrrrrrrrrv^--~|+^-``--~|+>++??????????????????????????????????>>++wy^<br></br>
`}rrrrrrrrrrrrrrv----~^|--`~?}zxv>??????????????????????????????????>>>+>x|<br></br>
`}rrrrrrrrrrrrrrr?--~~~~~--rzwxxv>?????????????????????????????????>>>>+>z^<br></br>
`}rrrrrrrrrrrrrrrr?-~~~~-~vxxxxx???????????????????????????????????>>+>}uy^<br></br>
`}rrrrrrrrrrrrrrrrr>-~~~`vyxzzxx??????????????????????????????????>+>}uyyy^<br></br>
`}rrrrrrrrrrrrrrrrrr?-~~`vuuuuuu}???????????????????????????????>++vxuuuuu|<br></br>


            </div>,

            <div>

`}rr}vv?>>???????????vvv????????vrwwwwr}v?>^``?}vv}r}???vvvvvvvvvvvvvvvvvv-<br></br>
`ww}vv??vvv}}rrrrrr}}}vvvv}}rwzuyyxwv?+|~-`````|}xzwrwu?+rrrrrrrrrrrrrrrrr~<br></br>
.???vvvv}}rrrrrr}}vvvrwzxuyyyyzv?^~--~~|^^^^^|~-~vzzwzz+.|vrrrrrrrrrrrrrrr~<br></br>
`vvv}}rrrrrrrrrvvrzuyyyyyyxr?^---~|+++>>>>>>+>+++^?xxz}~``-?rrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrv}uyyyyyyzv+~`-~|^++>>>>>>++++++++++^>?>+^^|-->rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrvryyyyyxv+-`-~|++>>>>>>>>>+>>>>>>>>++++++>>>+^|-+}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrr}}yyyuz?-`-~|^+>>>>>>>>>>>>>>>+^|||^++>>>++>>>>>+~^}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrvzyyxr~`--|++>>>>>>>>>>>>>>+~..     ..-^>>>>>>>>>+~|vrrrrrrrrr~<br></br>
`}rrrrrrrrrrvuyuzr--~|+>>>>>>>>>>>>>>>>-            `|>>>>>>>>>|~vr}rrrrrr~<br></br>
`}rrrrrrrrr}}yuuxw+-|>>>+>>>>+^-``~^>>>`              `+>>>>>>>+~~vv}rrrrr~<br></br>
`}rrrrrrrrr}}xxxxuv-+>>+>>>+~.     .^++^..........     .^>>>>>>>+~|?vrrrrr~<br></br>
`}rrrrrrrrrvvuuzzwz?++++>>+`       `+|~+^`...........   .+>>+^>>>+~~?vrrrr~<br></br>
`}rrrrrrrrr}|vzwwzxw+++>>+`     ...~^^|>>^`...........   ->+>||+>>^-~?}rrr~<br></br>
`}rrrrrrrrr}|`vwwrv+++>>>~    .....^>|```~^............  .+>>|`^>>+~-~?}rr~<br></br>
`}rrrrrrrrrr^`-|^+++>++>+.   ......|~.-|^-+`............ .^>>^`-^>+|~-~vrr~<br></br>
`}rrrrrrrrrr?---^+>>>+>>^   .......`~^vvv+|.............  ^>>^-`-+>|~~-+}r~<br></br>
`}rrrrrrrrrr?---^+>>>>>>|   .........-~~-`.............. .+>>^~``|+|~~~-?r~<br></br>
`}rrrrrrrrrrv~-~^+>>>>>>|   .............................`>>+|-`-^>+~~~-~v~<br></br>
`}rrrrrrrrr}r^-~^>>>>>>>^.  .............................^>>+~>vzxzz?~~``+~<br></br>
`}rrrrrrrrr}}?`-^>>>>>>>>`  ........................-|^^^+^+~vuxzrwzzwz}^`.<br></br>
`}rrrrrrrrrrvv~~^>++>>>>>+.  .....................-^+>????>+^>vrrxyuuyuyxw~<br></br>
`}rrrrrrrrrrvv^~^>+|^>>>>>^`  ..................~+>??????????>++>wyuz}wuyy^<br></br>
`}rrrrrrrrrr}?>-|+>|-^>>>>>+~. ........`````..-|+>?????????????>++?rrxyyux|<br></br>
`}rrrrrrrrrrrv?~-^++~-|+>>>>>+~`.....~^+>>+++^^^>???????????????>+++vyyxwx^<br></br>
`}rrrrrrrrrrr}v+-~^+^---^+>>>>>++~`~+>??????>+^+??????????????????>>+?zuuy|<br></br>
`}rrrrrrrrrrrr}?~-~^+^-`-~|+>>>>>++????????????????????????????????>>^+wyx|<br></br>
`}rrrrrrrrrrrrrv^--~|+^-``--~|+>++??????????????????????????????????>>++wy^<br></br>
`}rrrrrrrrrrrrrrv----~^|~-`~?}zxv>??????????????????????????????????>>>+>x|<br></br>
`}rrrrrrrrrrrrrrr?--~~~~~--rzwxxv>?????????????????????????????????>>>>+>z^<br></br>
`}rrrrrrrrrrrrrrrr?--~~~--vxxxxx???????????????????????????????????>>+>}uy^<br></br>
`}rrrrrrrrrrrrrrrrr>-~~~`vyxzxxx??????????????????????????????????>+>}uyyy^<br></br>
`}rrrrrrrrrrrrrrrrrr>-~~`vuuuuuu}???????????????????????????????+++vxuuuuu|<br></br>


            </div>,

            <div>

`}rr}vv?>>???????????vvv????????vrwwwwr}v?>^``?}vv}r}???vvvvvvvvvvvvvvvvvv-<br></br>
`ww}vv??vvv}}rrrrrr}}}vvvv}}rwzuyyxwv?+|~-`````|}xzwrwu?+rrrrrrrrrrrrrrrrr~<br></br>
.???vvvv}}rrrrrr}}vvvrwzxuyyyyzv?^~--~~|^^^^^|~-~vzzwzz+.|vrrrrrrrrrrrrrrr~<br></br>
`vvv}}rrrrrrrrrvvrzuyyyyyyxr?^---~|+++>>>>>>+>+++^?xxz}~``-?rrrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrrv}uyyyyyyzv+~`-~|^++>>>>>>++++++++++^>?>+^^|-->rrrrrrrrrrrrr~<br></br>
`}rrrrrrrrrrrvryyyyyxv+-`-~|++>>>>>>>>>+>>>>>>>>++++++>>>+^|-+}rrrrrrrrrrr~<br></br>
`}rrrrrrrrrr}}yyyuz?-`-~|^+>>>>>>>>>>>>>>>+^|||^++>>>++>>>>>+~^}rrrrrrrrrr~<br></br>
`}rrrrrrrrrrvzyyxr~`--|++>>>>>>>>>>>>>>+~..     ..-^>>>>>>>>>+~|vrrrrrrrrr~<br></br>
`}rrrrrrrrrrvuyuzr--~|+>>>>>>>>>>>>>>>>-            `^>>>>>>>>>|~vr}rrrrrr~<br></br>
`}rrrrrrrrr}}yuuxw+-|>>>+>>>>+^-``~^>>>`              `+>>>>>>>+~~vv}rrrrr~<br></br>
`}rrrrrrrrr}}xxxxuv-+>>+>>>+~.     .^++^..........     .^>>>>>>>+~|?vrrrrr~<br></br>
`}rrrrrrrrrvvuuzzwz?++++>>+`       `+|~+^`...........   .+>>+^>>>+~~?vrrrr~<br></br>
`}rrrrrrrrr}|vzwwzxw+++>>+`     ...~^^|>>^`...........   ->+>||+>>^-~?}rrr~<br></br>
`}rrrrrrrrr}|`vwwrv+++>>>~    .....^>|.``~^............  .+>>|`^>>+~-~?}rr~<br></br>
`}rrrrrrrrrr^`-|^+++>++>+.   ......|~.-|^-+`............ .^>>^`-^>+|~-~vrr~<br></br>
`}rrrrrrrrrr?---^+>>>+>>^   .......`~^vvv+|.............  ^>>^-`-+>|~~-+}r~<br></br>
`}rrrrrrrrrr?---^+>>>>>>|   .........-~~-`.............. .+>>^~``|+|~~~-?r~<br></br>
`}rrrrrrrrrrv~--^+>>>>>>|   .............................`>>>|-`-^>+~~~-~v~<br></br>
`}rrrrrrrrr}r^-~^>>>>>>>^.  .............................^>>+~>vzxzz?~~``+~<br></br>
`}rrrrrrrrr}}?`-^>>>>>>>>`  ........................-|^^^+^+~vuxzrwzzwz}^`.<br></br>
`}rrrrrrrrrrvv~~^>++>>>>>+.  .....................-^+>????>+^>vrrxyuuyuyxw~<br></br>
`}rrrrrrrrrrvv^~^>+|^>>>>>^`  ..................~+>??????????>++>wyuz}wuyy^<br></br>
`}rrrrrrrrrr}?>-|+>|-^>>>>>+~. ........`````..-|+>?????????????>++?rrxyyux|<br></br>
`}rrrrrrrrrrrv?~-^++~-|+>>>>>+~`.....~^+>>+++^^^>???????????????>>++vyyxwx^<br></br>
`}rrrrrrrrrrr}v+-~^+^---^+>>>>>++~`~+>??????>+^+??????????????????>>+?zuuy|<br></br>
`}rrrrrrrrrrrr}?~-~^+^-`-~|+>>>>>++????????????????????????????????>>^+wyx|<br></br>
`}rrrrrrrrrrrrrv^--~|+^-``--~|+>++??????????????????????????????????>>++wy^<br></br>
`}rrrrrrrrrrrrrrv----~^|~-`~?}zxv>??????????????????????????????????>>>+>x|<br></br>
`}rrrrrrrrrrrrrrr?--~~~~~--rzwxxv>?????????????????????????????????>>>>+>z^<br></br>
`}rrrrrrrrrrrrrrrr?--~~~--vxxxxx???????????????????????????????????>>+>}uy^<br></br>
`}rrrrrrrrrrrrrrrrr>-~~~`vyxzxxx??????????????????????????????????>+>}uyyy^<br></br>
`}rrrrrrrrrrrrrrrrrr>-~~`vuuuuuu}???????????????????????????????+++vxuuuuu|<br></br>


            </div>,

            <div>

`v?>vrwwwrr}~`````````-~~|||||||^^^^^^^^^^^^|~--````````````?vvvvv??vvvvvv-<br></br>
`rv}uuxxxxzz~`----~||++>>>>>>>>>>>>>>>>>>>>>>>++^|~~-~~~~~-|zxxuuurvrrrrrr~<br></br>
`v~wuuuuxzx?`-~~~^++>>>>>>>>>>++++++++++++++++>>>>+^|~---~~`>wwwzw}vvrrrrr~<br></br>
`^`vwzzxzzz^-~|^+>>>>>>>>>>>>>>>++++++++>>++++++++++++^~---`>xzxxxzx}rrrrr~<br></br>
 `-rrwwzxuw||^++>>>>>>>>>>>>>>>>>>>>>>>>+++++++++++>++^|||~-`?wwwwzxw?rrrr~<br></br>
.-`^vv}}rv~|+++>>>>>>>>>>>>>>>>>>>>>>>>>>+++++++++>>>>>+^|||->uxxxzw?`vrrr~<br></br>
 `-------~^++>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>++++>>>>>>>>+|||?}r}?>-`^rrr~<br></br>
 `-~~--~^+>>>>>>>>>>>>>>>>+^|||^+>>>>>>>+^|^^++>>>>>>>>>>>>>+^||~-----`?rr~<br></br>
 --~-~|+>>>>>>>>>>>>>>>^~`.     ..|>>>>>+-.  .`~^>>>>>>>>>>>>>+++|~~~~-|}r~<br></br>
.---~^>>>>>>>>>>>>>>>|`.          .^>>++^++~.   .`|+>>>>>>>>>>>>++^-~~~->r~<br></br>
.--~+>>++>>>>>>>>>>^`              `+>>?|++?^~.    .|>>>>>>>>>>>>>>^~~~--}~<br></br>
.-~++>+^+>>>>>>>>+-                 |++>>>??v?|~     `^>>>>>>>>>>>>>^~~~-?|<br></br>
 ~^++^^+>>>>>>>>^.         .........`++++?~.. `+~     .~+>>>>>>>>>>>>^~~-|-<br></br>
.|++|^>>>>>>>>+|.      ..............+>>~   .. ~+-      ->>>>>>>>>>>>>^~~-.<br></br>
.^|~|>>>>>>>>>|.     ................|>>^~|^????+^       |>>>>>>>>>>>>+~~- <br></br>
.~-|>>>>>>>>>|     ...................|++>v}vv?>+~       .+>>>>>>>>>>>>^~- <br></br>
.-~>>>>>>>>>+.   ......................~^+>>+++^-..       |>>>>>>>>>>>>+~- <br></br>
.-^>>>>>>>>>`   .........................`---``.....      `>>>>>>>>>>>>>|- <br></br>
 ~+>>>>>>>>^   ......................................     .+>>>>>>++>>>>^- <br></br>
 |>>>>>>>>>-   ......................................     .^>>>>>>+|+>>>+~ <br></br>
.+>>>>>>>>+.  ........................................     ^>>>>>>+-|+>+^~ <br></br>
.+>>>>>>>>^   ........................................     ^>>>>>>+~-^>+~- <br></br>
.+>>>>>>>>|  .........................................     ^>>>>>>+--|+^-- <br></br>
.+>>>>>>>>~  ..........................................   .+>>>>>>^`-~^|-- <br></br>
.+>>>>>>>>- ...........................................   ->>>>>>+~--~|--- <br></br>
.+>>>>>>>>` ...........................................  .^>>>>>>^~--~~-~- <br></br>
 |>>>>>>>>` ..........................................   `+>>>>>+~-`----~- <br></br>
.^++++>>>>- ..........................................  .^>>+>>+|-------~- <br></br>
`xzwrv>++++|^^^^^+^^^|-..............................   ~>>>+>>^~-----~-~-.<br></br>
`xxxx}++>>>>>>>>>>>>>>>+~`...........................  `+++>>>^~~----~~~-~`<br></br>
`xxw?^+>>>>>>>>>>>>>>>>>>>|`...........``-~^^^^|~`.. .~++++++^~~~-~--~~~-|`<br></br>
`x}+^>>????????????>>>++>>>+~.......`~|^++++++++++^~`|+>??>>^------------|`<br></br>


            </div>,

            <div>

                  ```````````-~||||||||||||||||^^^^|^^^^^^^^^^^^^^^^^|~-``````````````````` <br></br>
.-~~~~~~~~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++|~-~~~~~~~--~~~~~~.<br></br>
.-~~~~~~|^+>>++>>>>>>>>>>>>>>>+++++++++++>++++++++>>>>>>>+|~-~~~~~----~~~~ <br></br>
.~~~~~~^+++>>>>>>>>>>>>>>>>>>>>>++++++++>>>>>>>>>>>>>>>>>>>+^|~~~~~~~~~~~- <br></br>
.----~^+++>>+++>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>+^|~~~~~~~~~- <br></br>
 ---~^+>++^^++>>>>>>>>>>>>>>>>>>>>>>>>>+>>>++>>>+++>>>>>>>>>++++^|~~~~~~~- <br></br>
 `-^>>+^||+>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>+>++++++>>>>>>>>>>>+^^++^~~~~~~- <br></br>
 ~++^~-~+>>>>>>>>>>>+^|~-``````-~+>>>>>>+++++>+>>>>>>>>>>>>>>>>^|^+^|~~~~- <br></br>
.^|~-~^+>>>>>>>>+^~`.            .^>>+++^^^?>^>+>>>>>>>>>>>>>>>>+||^+|~~~- <br></br>
.---~+>>>>>>>>^~.                 .^>+>>?++>??+++>>>>>>>>>>>>>>>>>^~|+^|~- <br></br>
.-~^>>>>>>>>^`.                    .^+>|+v>+???v++++>>>>>>>>>>>>>>>+|~^+|- <br></br>
 ~^>>>>>>>^`                        `++>++?wv^-`.|+~-+>>>>>>>>>>>>>>>|-|+^.<br></br>
.^++>>+>>~.           ............. .|>>>+|.     .^+..~>>>>>>>>>>>>>>>^-~^.<br></br>
.+>>>>>^`        ....................`+>>>`  ..`..->^  -+>>>>>>>>>>>>>>^-~.<br></br>
.+>>>>|.       .......................|>>>|-~^???v}++.  ->>>>>>>>>>>>>>>^|.<br></br>
.+>>>|.      ..........................|+>+>?vv}vv>+-    ~>>>>>>>>>>>>>>>^.<br></br>
.+>>^.     .............................-^++?v?>++|`     .^>>>>>>>>>>>>>>+.<br></br>
.+>+.     ...............................`-|||||~`        `>>>>>>>>>>>>>>+.<br></br>
.+>`     .........................................         ^>>>>>>>>>>>>>+.<br></br>
.+~     ..........................................         `>>>>>>>>>+>>>+.<br></br>
.|.    ............................................        .+>>>>>>>>+>>>+.<br></br>
.`    .............................................         |>>>>>>>>^++>+.<br></br>
     ...............................................        ~>>>>>>>>~|+>+.<br></br>
     ...............................................        ->>>>>>>+--^>+.<br></br>
    ................................................        ->>>>>>>+--|>+.<br></br>
 ``````.............................................        ->>>>>>>|--|>+.<br></br>
.+>>>+++|-..........................................        ~>>>>>>^~`-~+^ <br></br>
.+>>>>>>>>+~........................................       .^>>>>>+~-`-~^- <br></br>
.+>>>>>>>>>>+-......................................       .+>>>>+|~~--~~- <br></br>
.???>>>>>>>>>+|.....................................       ~>>>>+|~~--~~-` <br></br>
.??????>>>>>>>>^`...................................      .+>>>>^~~~--~--- <br></br>
`????????>>>>>>>^`..................................      ~++++^---------` <br></br>


            </div>,

            <div>

                  ```````````-~||||||||||||||||^^^^^||^|^^^^^^^^^^^^^|~-``````````````````` <br></br>
.-~~~~~~~~~^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++|~-~~~~~~~--~~~~~~ <br></br>
.-~~~~~~|^+>>>+>>>>>>>>>>>>>>>+>+++++++++>>>>>>>++>>>>>>>+|~-~~~~~----~~~- <br></br>
.~~~~~~^+++>>>>>>>>>>>>>>>>>>>>>++++++++>>>>>>>>>>>>>>>>>>>+^|~~~~~~~~~~~~ <br></br>
.----~^+++>>+++>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>+^|~~~~~~~~~- <br></br>
 ---~^+>++^^++>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>++++^|~~~~~~~- <br></br>
 `-^>>+^||+>>>>>>>>>>>>>>>>>>>>>>>>>>>>++>>>>>>>++>>>>>>>>>>>+^^++^~~~~~~- <br></br>
 ~++^~-~+>>>>>>>>>>>+^|~-``````-~+>>>>>>+++++>+>>>>>>>>>>>>>>>>^|^+^|~~~~- <br></br>
.^|~-~^+>>>>>>>>+^~`.            .^>>+++^^^?>^>+>>>>>>>>>>>>>>>>+||^+|~~~- <br></br>
.---~+>>>>>>>>^~.                 .^>+>>?++>??+++>>>>>>>>>>>>>>>>>^~|+^|~- <br></br>
.-~^>>>>>>>>^`.                    .^+>|+v>+???v++++>>>>>>>>>>>>>>>+|~^+|- <br></br>
 ~^>>>>>>>^`                        `++>++?wv^-`.|+~-+>>>>>>>>>>>>>>>|-|+^.<br></br>
.^+>>++>>~.           ............. .|>>>+|.     .^+..~>>>>>>>>>>>>>>>^-~^.<br></br>
.+>>>>>^`         ...................`+>>>`  ..`..->^  -+>>>>>>>>>>>>>>^-~.<br></br>
.+>>>>|.       .......................|>>>|-~^???v}++.  ->>>>>>>>>>>>>>>^|.<br></br>
.+>>>|.      ..........................|+>+>?vv}vv>+-    ~>>>>>>>>>>>>>>>^.<br></br>
.+>>^.     .............................-^++?v?>++|`     .^>>>>>>>>>>>>>>+.<br></br>
.+>+.     ...............................`-|||||~`        `>>>>>>>>>>>>>>+.<br></br>
.+>`     .........................................         ^>>>>>>>>>>>>>+.<br></br>
.+~     ..........................................         `>>>>>>>>>+>>>+.<br></br>
.|.    ............................................        .+>>>>>>>>+>>>+.<br></br>
.`    .............................................         |>>>>>>>>^++>+.<br></br>
     ...............................................        ~>>>>>>>>~|+>+.<br></br>
     ...............................................        ->>>>>>>+--^>+.<br></br>
    ................................................        ->>>>>>>+--|>+.<br></br>
 ``````.............................................        ->>>>>>>|--|>+.<br></br>
.+>>>+++|-...........................................       ~>>>>>>^~`-~+^ <br></br>
.+>>>>>>>>+~........................................       .^>>>>>+~-`-~^- <br></br>
.+>>>>>>>>>>+-......................................       .+>>>>+|~~--~~- <br></br>
.???>>>>>>>>>+|.....................................       ~>>>>+|~~--~~-` <br></br>
.??????>>>>>>>>^`...................................      .+>>>>^~~~--~--- <br></br>
`????????>>>>>>>^`.................................       ~++++^---------` <br></br>


            </div>,

            <div>

                  ```````~~~~---~|||||||||||||||||||||^|^^^^^^^^^||^^^^^^^|~||~-``````````` <br></br>
.-~~~~|||~~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++^~-~~~~~~~~ <br></br>
.-~~~~~~|^++>>>>>>>>>>>>>>>>>>+++++++++++>>>>>>>++>>>>>>+++>>++++|--~-~~~~ <br></br>
.~~~~~|+>>>>>>>>>>>>>>>>>>>>>>>>++++++++>>>>>>>>>>>>>>>>>>>>>>>++^^~~~~~~~ <br></br>
.---|++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>++^|~-~~- <br></br>
 -~^>>>>>>>>>>>>>>++^|^^++>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^|~~- <br></br>
.|+>>>>>>>>>>>>^~`..    ...`-|^+>>>>>>++++++++^+>>>>>>>>>>>>>>>>>>++>+^^~- <br></br>
.^>>>>>>>>>+^-.               ..-+>>>>+++^^^>+~|++>>>>>>>>>>>>>>>>>+++>+^|.<br></br>
.^>>>>>>>+|`.                    `+>>>+++?+|?+?>^++++>>>>>>>>>>>>>>>+^^>+^.<br></br>
.^>>>>>+|.                        .^>>+v`~v?>+??vv>^>++>>>>>>>>>>>>>>>||++.<br></br>
.^>>>>|.                           .|>+>???>?wv++~..^>++>>>>>>>>>>>>>>>^~^.<br></br>
.^>>^`                 ..           .^>+++++^`      .^+++>>>>>>>>>>>>>>>^~.<br></br>
.++`            .................    .^>>>>>~ ..      |>++>>>>>>>>>>>>>>>| <br></br>
.~.         ........................  .+>>>>+. .|^|-|v?+++>>>>>>>>>>>>>>>+.<br></br>
         ..............................`+>>>>~~?????ww?++>>>>>>>>>>>>>>>>+.<br></br>
      ...................................|+>>++???}xv++|.|>>>>>>>>>>>>>>>+.<br></br>
    ......................................`|++^>rzw?++~. .^>>>>>>>>>>>>>>+.<br></br>
  ..........................................`~|^+>+^|`    .+>>>>>>>>>>>>>+.<br></br>
 ...............................................```.       ->>>>>>>>>>>>>+.<br></br>
 ..............................................             ^>>>>>>>>+>>>+.<br></br>
 ...............................................            `>>>>>>>>++>>+.<br></br>
 ................................................            ^>>>>>>>>++>+.<br></br>
 ................................................            ->>>>>>>>+^>+.<br></br>
 .................................................           `>>>>>>>>>|++.<br></br>
 .................................................           .+>>>>>>>>~^+.<br></br>
.~`................................................          .+>>>>>>>+-~+.<br></br>
.+>|`..............................................          .+>>>>>>>+--^.<br></br>
.+>>+|`............................................          .>>>>>>>>^--|.<br></br>
.+>>>>+-............................................         .>>>>>>>+|~-~.<br></br>
.+>>>>>+`...........................................         `>>>>>>>^~--~.<br></br>
.+>>>>>>^...........................................         ->>>>>>+~~--~.<br></br>
.?>>>>>>+-.........................................          |++++++~----- <br></br>


            </div>,

            <div>

                  ```````~~~~--~~|^|||||||||||||||||||^^^^^^^^^^^||^^^^^^^|~||~-``````````` <br></br>
.-~~~~|||~~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++^~-~~~~~~~~ <br></br>
.-~~~~~~|^++>>>>>>>>>>>>>>>>>>+++++++++++>>>>>>>++>>>>>>+++>>++++|--~-~~~~ <br></br>
.~~~~~|+>>>>>>>>>>>>>>>>>>>>>>>>++++++++>>>>>>>>>>>>>>>>>>>>>>>++^^~~~~~~~ <br></br>
.---|++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>++^|~-~~- <br></br>
 -~^>>>>>>>>>>>>>>++^||^++>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^|~~- <br></br>
.|+>>>>>>>>>>>>^~`..    ...`-|^+>>>>>>++++++++^+>>>>>>>>>>>>>>>>>>++>+^^~- <br></br>
.^>>>>>>>>>+^-.               ..-+>>>>+++^^^>+~|++>>>>>>>>>>>>>>>>++++>+^|.<br></br>
.^>>>>>>>+|`.                    `+>>>+++?+|?+?>^++++>>>>>>>>>>>>>>>+^^>+^.<br></br>
.^>>>>>+|.                        .^>>+v`~v?>+??vv>^>++>>>>>>>>>>>>>>>||++.<br></br>
.^>>>>|.                           .|++>???>?wv++-..^>++>>>>>>>>>>>>>>>^~^.<br></br>
.+>>^`                 ....         .^>+++++^`      .^+++>>>>>>>>>>>>>>>^~.<br></br>
.++`            .................    .^>>>>>~ ..      |>++>>>>>>>>>>>>>>>| <br></br>
.~.         ........................  .+>>>>+. .|^|-|v?+++>>>>>>>>>>>>>>>+.<br></br>
         ..............................`+>>>>~~?????ww?+^>>>>>>>>>>>>>>>>+.<br></br>
      ...................................|+>>++???}xv++|.|>>>>>>>>>>>>>>>+.<br></br>
    ......................................`|++^>rzw?++~. .^>>>>>>>>>>>>>>+.<br></br>
  ..........................................`~|^+>+^|`    .+>>>>>>>>>>>>>+.<br></br>
 ...............................................```.       `>>>>>>>>>>>>>+.<br></br>
 ..............................................             ^>>>>>>>>+>>>+.<br></br>
 ...............................................            `>>>>>>>>+++>+.<br></br>
 ................................................            ^>>>>>>>>^+>+.<br></br>
 ................................................            ->>>>>>>>+^>+.<br></br>
 .................................................           `>>>>>>>>>|++.<br></br>
 .................................................           .+>>>>>>>>~^+.<br></br>
.~`................................................          .+>>>>>>>+-~+.<br></br>
.+>|`..............................................          .+>>>>>>>+~-^.<br></br>
.+>>+|`............................................          .>>>>>>>>^--|.<br></br>
.+>>>>+-............................................         .>>>>>>>+|~-~.<br></br>
.+>>>>>+`...........................................         `>>>>>>>^~--~ <br></br>
.+>>>>>>^...........................................         ->>>>>>+~~--~ <br></br>
.?>>>>>>+-..........................................         |++++++~----- <br></br>


            </div>,

            <div>

                  ```````~~~~---~|^^^||||||||||||||||^^^^^^^^^^^^||^^^^^^^|~||~-``````````` <br></br>
.-~~~~|||~~|^+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++++^~-~~~~~~~~ <br></br>
.-~~~~~~|^++>>>>>>>>>>>>>>>>>>++>>>++++++>>>>>>>++>>>>>>+++>>++++|--~-~~~~ <br></br>
.~~~~~|+>>>>>>>>>>>>>>>>>>>>>>>>>>>+++++>>>>>>>>>>>>>>>>>>>>>>>++^^~~~~~~~ <br></br>
.--~|++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>++^|~-~~- <br></br>
 -~^>>>>>>>>>>>>>>++^||^++>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>>>>>>>>>>+^^|~~- <br></br>
.|+>>>>>>>>>>>>^~`..    ...`-|^+>>>>>>++++++++^+>>>>>>>>>>>>>>>>>>+>>+^^~- <br></br>
.^>>>>>>>>>+^-.                .-+>>>>+++^^^>+~|++>>>>>>>>>>>>>>>>++++>+^|.<br></br>
.+>>>>>>>+|`.                    `+>>>+++?+|?+?>^++++>>>>>>>>>>>>>>>+^^>+^.<br></br>
.+>>>>>+|.                        .^>>+v`~v?>+??vv>^>++>>>>>>>>>>>>>>>||++.<br></br>
.+>>>>|.                           .|>+>???>?wv++-..^+++>>>>>>>>>>>>>>>^~^.<br></br>
.+>>^`                 ....         .^>+++++^`      .^>++>>>>>>>>>>>>>>>^~.<br></br>
.++`            .................    .^>>>>>~ ..      |>++>>>>>>>>>>>>>>>| <br></br>
.~.         ........................  .+>>>>+. .|^|-|v?+++>>>>>>>>>>>>>>>+.<br></br>
         ..............................`+>>>>~~?????ww?+^>>>>>>>>>>>>>>>>+.<br></br>
      ...................................|+>>++???}xv++|.|>>>>>>>>>>>>>>>+.<br></br>
    ......................................`|++^>rzw?++~. .^>>>>>>>>>>>>>>+.<br></br>
  ..........................................`~|^+>+^|`    .+>>>>>>>>>>>>>+.<br></br>
 ...............................................```.       `>>>>>>>>>>>>>+.<br></br>
 ..............................................             ^>>>>>>>>+>>>+.<br></br>
 ...............................................            `>>>>>>>>+++>+.<br></br>
 ................................................            ^>>>>>>>>^+>+.<br></br>
 ................................................            ->>>>>>>>+^>+.<br></br>
 .................................................           `>>>>>>>>>|++.<br></br>
 .................................................           .+>>>>>>>>~^+.<br></br>
.~`................................................          .+>>>>>>>+-~+.<br></br>
.+>|`..............................................          .+>>>>>>>+--^.<br></br>
.+>>+|`............................................          .>>>>>>>>^--~.<br></br>
.+>>>>+-............................................         .>>>>>>>+|~-~.<br></br>
.+>>>>>+`...........................................         `>>>>>>>^~--~ <br></br>
.+>>>>>>^...........................................         ->>>>>>+~~--~ <br></br>
.?>>>>>>+`..........................................         |++++++~----- <br></br>


            </div>,

            <div>

                  `--~~~~~||~~-~~|^||||||^|||||^^|||^^^|^^^^^^^^^^^^^^^^^^|~|||~~~~~~~~~~--.<br></br>
.|^^^^^^^^^+++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++>+^^^^^^^^^^.<br></br>
.^^^^^^^^++++^|~~~~~~~~~~|^>>>++>>+^|^^++>>>>>>>++>>>>>>+++>>++>++^^^^^^^|.<br></br>
.^^^^^^+>>^~```````````````^>>>>>>+`````-|^+>>>>>>>>>>>>>>>>>>>>++++^^^^^|.<br></br>
.^^^++>>|-``````````````````|>>>>>>^~``````-~^+>>>>>>>>>>>>>>>>>>>+++^^^^|.<br></br>
.|^+>+^~````````````....`````|>>>>>>>+|~~-````-|+>>>>>>>>>>>>>>>>>>>++^^^^.<br></br>
.^+>+~`````````...        ....|+>>>>>>>>++^|~`.``~^+>>>>>>>>>>>>>>+>>+++^|.<br></br>
.+>^-```````...               .`|>>>>>++++++>^~-````~^+>>>>>>>>>>>>++>>+^|.<br></br>
.^~```````..                    .~+>>>+++>+^?~^?^~`.``~^>>>>>>>>>>>>+^+>+^.<br></br>
.-``````..                      .`-+>+>v-|v>|?>v??+-````|+>>>>>>>>>>>++^++.<br></br>
 .````..                        .``--.`vv>>+>}?^^~``~````-|>>>>>>>>>>>>^|+.<br></br>
 .``..              .............````~~^?>++^-...````~`````~+>>>>>>>>>>>^|.<br></br>
 .`.          ....................```-+>++>>+--`````.`-`.````|>>>>>>>>>>>|.<br></br>
 .         .......................--``-+>+>^-. .`^^~^?+```````-+>>>>>>>>>+.<br></br>
        ..........................-~~--~++`....~|^>?vv>~.```````|+>>>>>>>+.<br></br>
     .............................`~~~--~|--|^>?>+>}?++~ .```````~+>>>>>>+.<br></br>
   ................................`~~~~~-|>>?>?}r}>++^.  .```````~+>>>>>+.<br></br>
  ..................................-~~~~~~|+>>>>??>^~`.   ````````~+>>>>+.<br></br>
 ....................................-~~~~~~|^|^+>^~``.    .````````-+>>>+.<br></br>
 ....................................`--~~~~~||~-`.`...     .````````~+>>+.<br></br>
 ......................................``-------`.....      .`````````~>>+.<br></br>
 ..........................................`````...          .`````````|>+.<br></br>
                  .................................................           .``````````++.<br></br>
                  ..................................................          .`````````.|+.<br></br>
                  ..................................................          .`````````.`^.<br></br>
                  `.................................................          .`````````..|.<br></br>
                  -~`...............................................          .`````````..~.<br></br>
                  -~~-`..............................................         .-```````...-.<br></br>
                  .^^~~~-`............................................         .-```````...`.<br></br>
.+>+^~--............................................         .-``````....`.<br></br>
.+>>>+|~-...........................................         `-`````.....`.<br></br>
.>>>>>+|~`..........................................         `-`````.......<br></br>


            </div>,

            <div>

                  .............................|^^||^^^^^` ................................ <br></br>
 .............................^>>>>>>>>>^................................. <br></br>
 .............            ....-+>>++^^+++~................................ <br></br>
 ..........                 ..`+>>+||||||^-............................... <br></br>
 .......                    ..`+>>>+^||||||`.............................. <br></br>
 ......                   ....`+>>>>>++^|||~-. ........................... <br></br>
 ....                .........`+>>>++>>>>+^^^|`........................... <br></br>
 ..             ..............`+>>>>>+>>>+++++^-.......................... <br></br>
 .            ................`^++>++++++^>??^+>|`........................ <br></br>
           ...................`|+?>??>>>|?>}> |?++-....................... <br></br>
        ......................~|?` ?r+~??> ?r|?>>>+-...................... <br></br>
      ........................||+~.-wv|>>>>???+>>>>+-..................... <br></br>
     .........................|^|+>>??>+^+>>>++++>>>^`.................... <br></br>
   ..........................`||^^+>+++>>>>+^|~~|++>>~.................... <br></br>
  ...........................`|^^^+>>++++^`.`~||||+>>+`................... <br></br>
 ............................`|^^^++>>-......|>>++^+>>~.........  ........ <br></br>
 ............................`|^^^^+>>`~>>???>>>>>>>+>|........... ....... <br></br>
 ............................`|^^^^^+>+???????>>>>>>+>|............ ...... <br></br>
 .............................~^^^^^^++???????>>>>>>+>~............  ..... <br></br>
 .............................`|^^^^^^+>??????>>>++++^`.............  .... <br></br>
 ..............................-|^^^^^^+?????>+++++^|-...............  ... <br></br>
 ...............................-|^^^^^^^>?>>^^+^^^|-................  ... <br></br>
                  ...............................`-~|^^^^^^^|~|^^^|~`..................  .. <br></br>
                  .................................``-~|^^^^|^^^|~-`.................... .. <br></br>
                  ....................................``--~~~~--`.......................  . <br></br>
                  .........................................`............................  . <br></br>
                  ......................................................................  . <br></br>
                  ......................................................................    <br></br>
                  .......................................................................   <br></br>
                  .......................................................................   <br></br>
                  .````..................................................................   <br></br>
                  .````..................................................................   <br></br>


            </div>,

            <div>

                  ....`|^^^^~``...                             <br></br>
                             .`-~-|>>>>>+~-``.                             <br></br>
                           ...`~~~+>>>+++^-``.                             <br></br>
                              .-~+>+>>>++>|``.                             <br></br>
                           ....-^>>+>>>>>>+|`.                             <br></br>
                     ..........|>>>>>>>+>>>+^-. .                          <br></br>
                  ............`+>>+++>>>>+++>+-.....                       <br></br>
               ...............~+>>>>++>>>++++>+-......                     <br></br>
           ..................`|+>++++++>++>>>^>>-.......                   <br></br>
        .....................||~>v?>>>+^^??w?.^?^~........                 <br></br>
       .....................-+. >>~>}^~?>>+r+.-v>+~`.......                <br></br>
     ......................`-^|.??~.r?>| >}r~`>>+>^-`........              <br></br>
   .......................`~-~>+?????>>>|?vv>>?+>>>|-.........             <br></br>
  ........................`~~|+>++++|++>>>>>>>>>>>>+~`..........           <br></br>
 ........................`~~~|+>+++>>++++^~-|+>>>>>+~-...........          <br></br>
 ........................`~~~|+>>>>>>>|`-~---^>>>>>>^~`...........         <br></br>
 ........................-~~~|+>>>^-`~~|>>>>>>>>>>>>+~`............        <br></br>
 ........................-~~~|+>>>~.|+>?>>>??>>>>>>++~`.............       <br></br>
 ........................-~~~~+>>>|^>????>>???>>>>+++~`..............      <br></br>
 ........................-~~~~|+>>>>>>>??>>??>>>>++++-................     <br></br>
 ........................-~~~~~^+>>?>>>>?????>+++++>^`................     <br></br>
 ........................`~~~~~~^++>>???>>>>>+++>>++-................. .   <br></br>
 ........................`-~~~~~|^^>>???++++++>+++^-....................   <br></br>
 .........................``-~~~~~||^+++>>>+++>+^~`....................    <br></br>
                  ..........................``--~~~~~~~~|^^^^^^^|`.......................   <br></br>
                  ............................``--~~~~--~~~~~~~``........................   <br></br>
                  .............................````---------```.......................... . <br></br>
                  ................................``````````............................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  .......................................................................   <br></br>


            </div>,

            <div>

                  ~^^^|^^^^^`                             <br></br>
                          ........`+>>>>>>>>+. ...                         <br></br>
                     .............^>>>+++++>+............                  <br></br>
                .................|>+>>>++++>+`...............              <br></br>
              ..................~>>+>>>>++++>~..................           <br></br>
           ....................`>>>>>>>+>>>>>+....................         <br></br>
         .....................`+>>+++>>>>>++>>~.....................       <br></br>
       .......................^>>>>>++>>>++++++-......................     <br></br>
      .......................~>>>++>++>+++++>>+^`.......................   <br></br>
    ........................-v>|>v?>+++^+?v}????^`.......................  <br></br>
   .........................^r  +zv>?~~?>?zx` .r++........................ <br></br>
  .........................|+v+ vz?.???~ >xu`.??+>|....................... <br></br>
 .........................`>++??}}v?>^>?+vzw???+>>+`...................... <br></br>
 .........................^>>>>>>+++~^++>>>>+++>>>>^...................... <br></br>
 ........................`>>>>>>+++>>+>++++++>>>>>>+`..................... <br></br>
 ........................|>>>>>>>>>>+>>+>>>+++++>>>>|..................... <br></br>
 ........................^+>>>>>>>^..`^+>>>+++++>>>>+..................... <br></br>
 .......................`++>>>>>>>` ~>??+>>++++++>>++`.................... <br></br>
 .......................`++>>>>>>>-+????>+>++++++++++`.................... <br></br>
 .......................`+>>>>>>>+>??????+>++++++++>+..................... <br></br>
 .......................`^>>>>>>>+>??????>+++++++++>^..................... <br></br>
 ........................~+>>>>>>>+??????+>+++++++>+`..................... <br></br>
 ........................-^>>>>>>>+>????>+>+++++>>+-...................... <br></br>
 ........................`~^+>>>>>>+>>>>+>>++++>+^-....................... <br></br>
 ..........................-|^+>>>>>++^+++>>>>>+~`........................ <br></br>
 ..........................``~|^+>>>>+^+>>>>+^|-.......................... <br></br>
                  ............................`-~~^^^+++++^^|~-`........................... <br></br>
                  ...............................`-~~~~~~~--``............................. <br></br>
                  ..................................``````................................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ~^^^|^^^^^`                             <br></br>
                          ........`>>>>>>>>>+. .                           <br></br>
                     .............^>>>+++++>+............                  <br></br>
                .................|>+>>>++++>+`...............              <br></br>
              ..................~>>+>>>>++++>~..................           <br></br>
           ....................`>>>>>>>+>>>>>+....................         <br></br>
         .....................`+>>+++>>>>>++>>~.....................       <br></br>
       .......................^>>>>>++>>+++++>+-......................     <br></br>
     ........................~>>>++>++>+++++>>+^`.......................   <br></br>
    ........................-v>|>v?>+++^+?v}????^`.......................  <br></br>
   .........................^r  +zv>?~~?>?zu` .r++........................ <br></br>
  .........................|+v+ vz?.???~ >xu`.??+>|....................... <br></br>
 .........................`>++??}}v?>^>?+vzw>??+>>+`...................... <br></br>
 .........................^>>>>>>+++~^++>>>>+++>>>>^...................... <br></br>
 ........................`>>>>>>+++>>+>++++++>>>>>>+`..................... <br></br>
 ........................|>>>>>>>>>>+>>>>>>+++++>>>>|..................... <br></br>
 ........................^+>>>>>>>^..`^+>>>+++++>>>>+..................... <br></br>
 .......................`++>>>>>>>` ~>??+>>++++++>>++`.................... <br></br>
 .......................`++>>>>>>>-+????>+>++++++++++`.................... <br></br>
 .......................`+>>>>>>>>>??????+>++++++++>+..................... <br></br>
 .......................`^>>>>>>>+>??????>+++++++++>^..................... <br></br>
 ........................~+>>>>>>>+??????+>+++++++>+`..................... <br></br>
 ........................-^>>>>>>>+>????>+>+++++>>+-...................... <br></br>
 ........................`~^+>>>>>>+>>>>+>>++++>+^-....................... <br></br>
 ..........................-|^+>>+>>++^++++>>>>+~`........................ <br></br>
 ..........................``~|^+>>>>+^+>>>>+^|-.......................... <br></br>
                  ............................`-~~^^^++++++^|~-`........................... <br></br>
                  ...............................`-~~~~~~~--``............................. <br></br>
                  ..................................``````................................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ...`|^^^||~```.                             <br></br>
                       .........-~|>>>>>++~~-........                      <br></br>
                   ...........`-~~+>>>+>+^~~-..............                <br></br>
               ...............-~~^>>>>>>+^~~-.................             <br></br>
            .................`~~^>>>>+++++~~~`...................          <br></br>
          ..................`~-|>>>>+>>+++^~~-.....................        <br></br>
        ...................`-~|+>>+++>>>>>+~~~`......................      <br></br>
       ....................-~~+>>>+++++>>++^~~-`......................     <br></br>
     .....................`-~^>>>++++>?>+>++|~~-........................   <br></br>
                  .....................`--|?+~+???vv+.`+v?+~||-........................  <br></br>
   .....................`|~^?v` ^w?>rv^`->w}- .>~-........................ <br></br>
  ......................~. ?v?~`v}``rr?..?}}`.^|-~`....................... <br></br>
 ......................`~ .vv->??}??v???+?vv>>>^~~-....................... <br></br>
 ......................`~|+??>++>+>>^+>+>>>>>>>>^~~-...................... <br></br>
 .....................`~-|>>>+++>+++>>>>++++>>>>+^~-...................... <br></br>
 .....................-~~^>>>>++>>??>+>>>>>>>>>>++|~`..................... <br></br>
 ....................`-~~+>>++??v?>-..~+>>>>>>>>>>+~-..................... <br></br>
 ....................`~~|+++?v?^-`  .`~|+>>>>>>>>>+~-..................... <br></br>
 ....................-~-|+>+?>`.``.-|+>?>+>>>>>>+++|-..................... <br></br>
 ....................-~-~+>+-````-+??????>+++++++>+|-..................... <br></br>
 ....................~~-~+>>|```|>???????>+++++++>+~-..................... <br></br>
 ....................-~~~^+>+``|?????????>+++++>++^-...................... <br></br>
 ....................`~~~|+>>||>??>??????>>++++>+^~`...................... <br></br>
 .....................--~|^^+>?>>????vvvv?+++>++^-`....................... <br></br>
 ......................`-~~|^++?vv???>>>?>+>>+^|-......................... <br></br>
 .......................``-~|^^+>>++^^^++>>++|~`.......................... <br></br>
                  ........................```-~|^^+++++++++^|~``........................... <br></br>
                  ..........................````-~|||||||~~-`.............................. <br></br>
                  ...............................`````````................................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  .....~^|||^^|~-.........                        <br></br>
                    ...........-+>>>>>>>+|................                 <br></br>
                ..............-+>+>>>>>>+|...................              <br></br>
              ...............`^>++>>>>>>+|......................           <br></br>
           .................`|>>>>>>>>>>+^`.......................         <br></br>
         ..................`|>>>>>>>++>+>+-.........................       <br></br>
       ....................-+>>>>>>++++>>+|...........................     <br></br>
     .....................`+>>++>>++>>++>+^~...........................    <br></br>
    .....................`|+++++>>>++>????>^`...........................   <br></br>
   ......................~+++++>>?v???^`.^}+|............................. <br></br>
                  .....................`|??vv??-^v?+vv^. ^v+^-............................ <br></br>
 ....................```?-.vz>^+vv.|}w?.|v>>+^-........................... <br></br>
 ....................`..?`.}w-+?>v??wrv??>>>>>+~.......................... <br></br>
 ....................`-~vv?}v?+^++>>>>>++>>>>>+^-......................... <br></br>
 ....................`^>>>>+++^++++++>>>++>>>>>+^-........................ <br></br>
 ...................`-++>>>>+++>??vv>^+>++>>>>>>+|`....................... <br></br>
 ...................`|+>>>++>?vrw}?|`.-+>+>>>>>>>^~....................... <br></br>
 ..................``^>>>>>?vwv|.      .^>>>>>>>>+|....................... <br></br>
 ..................`-+>+>????^      .`~^++>>>>>>>>^`...................... <br></br>
 ..................`~+>+++^~.    .~^^>???>+>>>++>>^`...................... <br></br>
 ..................`~+>>+~~~.   ~?????????++++++>+|....................... <br></br>
 ..................`-+>>>^~~-  -??????????+++++>>^-....................... <br></br>
 ..................`-^>>>+|~~`-???????????+++++>+~........................ <br></br>
 ...................`~^+>>+|??????vv}rwwz}+>+>+^-`........................ <br></br>
 ....................`~|+>++???vrw}vv}}}v?+>>+|-`......................... <br></br>
 .....................`-~^+++???>>^^^^++++>+^~-`.......................... <br></br>
 .......................-~|^^+++++++>>>>++^|-`............................ <br></br>
                  .........................`-~||^^^+++++^|~-`.............................. <br></br>
                  ............................``--------``................................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  .......-^|||^^|-`...............                    <br></br>
                  ...........~+>>>>>>>|~....................               <br></br>
               .............-+>>>>>>>>^-`.....................             <br></br>
            ...............-+>>>++>>>>+~`........................          <br></br>
         .................-^>>>>>>>>>>+~-..........................        <br></br>
       ..................`|>>>>+>>>>++>^-`...........................      <br></br>
      ..................`~+>>>++++++++++~`............................     <br></br>
    ...................`~^>>+++>>>>>>>>+^-`.............................   <br></br>
   ....................-^+++>??v^|+??+??+|`..............................  <br></br>
  ....................-|++^>??}z^~^.~``v>+~`.............................. <br></br>
 ....................-^>>+>v.>w?^|+||.^v>>+-`............................. <br></br>
 ..................`->v^?r??`vr+?>}?`^?>>>>+-`............................ <br></br>
 ..................~ ?>-?z|+??}vvr}???>>>>>>+~`........................... <br></br>
 ..................~ v}?wv?>++++>++>++>>>>>>>+~`.......................... <br></br>
 .................`~+?v?+>>^+++>++++>>++>>>>>>+~`......................... <br></br>
 .................-~++++++++>?}wwrv?>>>>>>>>>>>^-......................... <br></br>
 ................`-^>>>>+>?vwxyyxw}?|-^+>>>>>>>>~......................... <br></br>
 ................-~+>++>?}wzw?^`.     .~+>>>>>>>+-........................ <br></br>
 ................-|>>+?vru}^.          .|+>>>>>>+~........................ <br></br>
 ................-|>+?}}}?~          .-|^^+>>>+>+|........................ <br></br>
 ................-|>++|~-       `~~~~^???++++++>+~........................ <br></br>
 ................-|>>>|``.    .^?????????>+>>>>>^-........................ <br></br>
 ................-|+>>+-``. `->???????????+>>>>^~`........................ <br></br>
 .................-|+>+^```>w}????vv}rwwzv++>+^~`......................... <br></br>
 .................`-^+>+~|}}rrrzxuuyyyyyu?+>+|~-.......................... <br></br>
 ..................`~|++^>}}rzw}v???vvv}?++^|~-`.......................... <br></br>
 ...................`-~|^^>?>>+++^^++++++^|~~`............................ <br></br>
                  .....................``-~||^+++^++++++^~~-``............................. <br></br>
                  ........................```-~|||||~~--```................................ <br></br>
                  ..............................````....................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ....... `|||||^^|..................                   <br></br>
                ............`+>>>>>>>>`.....................               <br></br>
             ..............`^>>>>>>>>>~........................            <br></br>
           ................+>>>>++>>>>^...........................         <br></br>
         .................^>>>>>>>>++>+`...........................        <br></br>
       ..................|>>>>++>>>>>>>~.............................      <br></br>
     ...................~>>>>+++++++>>>+..............................     <br></br>
    ...................-+++>+^+>????++>>~...............................   <br></br>
   ...................`+++>++v}w^--?w>+>+`...............................  <br></br>
  ...................`++++^>vvzu`   zv+>>+`............................... <br></br>
 ....................^>?>|^v.vxy^  ^z>>>>>^............................... <br></br>
 ..................-+?zv>vv} vxy?`?}>+>>>>>^.............................. <br></br>
 .................`> ?x?^v+??vww}v?++>>>>>>>^............................. <br></br>
 .................`>.rwr}+++>>+++++>>>>>>>>>>^............................ <br></br>
 .................-??}v?~+>>+++>+++>>>>>++>>>>|........................... <br></br>
 .................^++++++>++?vzuz}?>>>>>>>>>>>>~.......................... <br></br>
 ................~+>>>>++>vzyyyuxzrv^|^+>>>>>>>>`......................... <br></br>
 ................^>>>+^+vxyzv>|`.     `|+>>>>>>>^......................... <br></br>
 ................+>>>+?zuuv.           .~+>>>>>>+`........................ <br></br>
 ...............`+>>+vuxx}-            .~^+>>>+++`........................ <br></br>
 ...............`+>>++-``       .....`+??^>>>>+>+`........................ <br></br>
 ................+>>>+`       .^?????????>+>>>>>|`........................ <br></br>
 ................^>>>>+.    --???????????>+>>>>^~......................... <br></br>
 ................`+>>>+|  `}w?????vv}rrwzv+>>+^~`......................... <br></br>
 .................~+>>>+`|xu?vrzxuyyyyyyy?+>+|--.......................... <br></br>
 ..................~+>>>+?uuuuz}vvv}rwzz?++^~--........................... <br></br>
 ...................-^+>>+?v?>+^^^+++++++^|~~`............................ <br></br>
 ....................`-|^++++++^|^+>+++|~--``............................. <br></br>
                  .......................`~~||^^^^^^|~~-```................................ <br></br>
                  ...........................````````...................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ..........-^|||^^|-`................                   <br></br>
                .............-+>>>>>>>|~-....................              <br></br>
             ...............-|+>>>>>>>^--.......................           <br></br>
          .................-~+>>++>>>>+~~`........................         <br></br>
        ..................-~|>>>>>>++>+~~-..........................       <br></br>
      ...................`~~+>>+>>>>+++^~~`...........................     <br></br>
     ...................`~~^>++++++++>++~-~`...........................    <br></br>
    ...................`--^>+++>>>>>++>>+~--`...........................   <br></br>
  .....................--|++>??v|--+}?v>|~^|-`...........................  <br></br>
 .....................-~^>??vvrr`..-v}}>..`>--`........................... <br></br>
 ....................-|>~^vr`+rx| -~^v}?..+>``-`.......................... <br></br>
 ..................`-|??.^zw`~+rv~??.vvv~>v+----`......................... <br></br>
 ..................~ ^v?~|vwv~>rv???>????>+>+~-~-......................... <br></br>
 ..................~ >v?v????>?>++++>>++++>>>+~~~-........................ <br></br>
 .................`~|???^+>>+++>+^+>>+>>>>>>>>^~~~-....................... <br></br>
 .................--~+++>>++>?v}v??>+>>>>>>>>>>^~~~`...................... <br></br>
 ................`-~|>>>+>?}rwrrrrr}?vv}?+>>>>>>|~~-...................... <br></br>
 ................-~~^++>?}rv??>+^|~```--~~++>>>>+~~~`..................... <br></br>
 ................-~-^+?v}}?`.^|.       .-~|+>>>>+|~~`..................... <br></br>
 ................-~-^?}v}?~...   .`--`-|+>>+>>>>+|~~-..................... <br></br>
 ................-~-^+|~-````. .~||||^???>?>>>>>+|~~-..................... <br></br>
 ................-~-|>~```````|>???????????>>>>>+~~~`..................... <br></br>
 ................-~~~++-````|~?????????????>>>++^~~-...................... <br></br>
 .................-~~|+^`.-vv>?????vvv}rrv>>>++^|~-`...................... <br></br>
 .................`-~~|+||}}???}wwzxxxxxxv>++^^^~``....................... <br></br>
 ..................`-~~|^>r}rrv?v}rrrrwzrv>+^^|-``........................ <br></br>
 ...................``-~|^>??>++++++++++++^^^~``.......................... <br></br>
                  .....................``-~|^^+++++++++++^||~``............................ <br></br>
                  ........................``-~||^^^^^|||~-``............................... <br></br>
                  .............................`````````................................... <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ..........`^|||^||^^-.................                 <br></br>
               ...............^>>>>>>>>>^......................            <br></br>
            .................`++>>>>>>>>+`.......................          <br></br>
          ...................^++++>>>>>>>|.........................        <br></br>
        ....................-+>>>>>>++>>>+`..........................      <br></br>
      .....................`+>>>>>>>+++>>>|...........................     <br></br>
    .......................|+++>>>>++>++++>~...........................    <br></br>
   .......................~>+>++>>>++++>??>+-...........................   <br></br>
  .......................`^+++>++>++?vw?+>}?+-............................ <br></br>
 .......................|?vvv?>+++^}?wx?  ~z++-........................... <br></br>
 ......................>~>xz~~?v+~}^.zx}  ?}|^^-.......................... <br></br>
 .....................|+ -xx|  ?r?}| rzx~?}>^^>>-......................... <br></br>
 .....................~v~.wxv.~}?^+v?vr}v?+>>>>>+`........................ <br></br>
 ....................`+>??v}}??>++++>+++++>>>>>>>+`....................... <br></br>
 ....................|++++>++++++|+>>++>>>>>>>>>>>^....................... <br></br>
 ...................`+>>>>>>>>>++++++>>>+>>>>>>>>>>~...................... <br></br>
 ...................-+>>>>>>>+++>?vrzxux?++>>>>>>>>+`..................... <br></br>
 ...................~+>>>>>>+?vwrv?>||~-`.|+>>>>>>>>-..................... <br></br>
 ...................|+>>>>>>+zw~       `-`-+>>>>>>>>|..................... <br></br>
 ...................|++>>>>>+-. .~+>?>?????+>>>>+++>^..................... <br></br>
 ...................|>+>>>>>>`.-???????????>>>>>>>>>+..................... <br></br>
 ...................->+>>+>>+>v????????????>>>>>>>>>|..................... <br></br>
 ....................|+>++>>>+?????????????>+>>>+>>^`..................... <br></br>
 ....................`^+>>>>>+>????????????>++++>+^-...................... <br></br>
 .....................`^+>>>>>+?????vvvvvv?+>>>>+|-`...................... <br></br>
 ......................-|+>>>>>+?}rr}}rzxw>+>>+^~-`....................... <br></br>
 .......................`~^+>>>>++++^+++>++>+^|~`......................... <br></br>
 ........................`-~^++>>>+^|^+>++^|~~-`.......................... <br></br>
                  ..........................`-~~|^^^^^^^|~~--``............................ <br></br>
                  .............................``-------``................................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ............-^|||^|^^^~..................               <br></br>
               ................|>>>>>>>>>+`.....................           <br></br>
            ...................^>>>>>>>>>>|.......................         <br></br>
         .....................`+>+>>>>>>>>>-........................       <br></br>
       .......................|+>>>>>>>>>>>+`........................      <br></br>
     ........................-+>+>>>>>>>>>>>^`.........................    <br></br>
    ........................`^++++>>>>++>++>>^`.........................   <br></br>
   .........................|+>>>>+>>>++>+^+++^``........................  <br></br>
 ..........................-+>+>>>++>+++>?vvv}v|``........................ <br></br>
 .........................+vvvrvv?v?>+^??}w?`|??..-....................... <br></br>
 ........................>v-.-vv?`?r?|v+.}zv .`z---`...................... <br></br>
 ........................??  .vvv..?z??~.?xr..vr+>|``..................... <br></br>
 .......................~^v?|`}zw~|rv^+}??}wvvv>>>+~`..................... <br></br>
 ......................~+++>??vv}vv?>>^+>>++>+++>>>+``.................... <br></br>
 ......................|+>>+++++++++++^+>+++>>>>>>>>~`.................... <br></br>
 .....................`^>>>>>>>>>>++++>>>>+>>>>>>>>>+-`................... <br></br>
 .....................-^>>>>>>>>>>>>?vvvv?>||+>>>>>>>|`................... <br></br>
 .....................~+>>>>>>>>>^||~-```...`^>>>>>>>+-................... <br></br>
 .....................~+>>>>>>>>>+>>?>???>+-.~+>>>>>>+~................... <br></br>
 .....................-^>>>>>>>>+???????????>~+>>>>>>>~................... <br></br>
 .....................~^>>>>>>>>>????????????>+>>>>>>>~................... <br></br>
 .....................-|+>>+>>>>>????????????>+>>>>+>+~................... <br></br>
 ......................~^+>+>>>>+???????????>>>>>>+>+|`................... <br></br>
 ......................`~^+>>>>>+???????????>>>++>>+|-.................... <br></br>
 .......................`|++>>>>>>??????????+>>+>++|-..................... <br></br>
 ........................`|^+>>>>+>?vv}}}v?>>>>>+^|-...................... <br></br>
 .........................`~|^+>>>>+++++>>+>>++^|~`....................... <br></br>
 ..........................`-~|^++>>+^^^++++^|~~-`........................ <br></br>
                  ............................``-~|^^^^^^^||~--`........................... <br></br>
                  ...............................```------```.............................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ............`||||^^|^|^|.................               <br></br>
               ................`>>>>>>>>>>>-....................           <br></br>
           ....................`>>>>>>>>>>>+.......................        <br></br>
         ......................~>++>>>>>>>>>|.......................       <br></br>
      .........................+>>>>>>>>>>>>>-........................     <br></br>
     .........................~>>+++>>>>>>++++`........................    <br></br>
   ..........................`+>+++>++>>>>+++++-........................   <br></br>
  ...........................^>++++>>++>>>+>+^+>|......................... <br></br>
 ...........................|+>>??>>++^+>+++?vv>??~....................... <br></br>
 ..........................~+v}?>>zzrv?|+^+v?zx| -v....................... <br></br>
 .........................-+>x`  .xxx|>}+^}| vzx.^?|...................... <br></br>
 ........................`+++w>  .zxu~ ?r?}+ |zxvv+>|..................... <br></br>
 ........................^>>++vv>~zzx>^}?+>v??}v?+>>>-.................... <br></br>
 .......................~+>>>++>?v}}}v?>+>^-+>+++>>>>^.................... <br></br>
 .......................+>>>>>>>++++++++>++++>>>>>>>>>-................... <br></br>
 ......................->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>^................... <br></br>
 ......................|>>>>>>>>>>>>>>>>^`..`^>>>>>>>>+`.................. <br></br>
 ......................^>>>>>>>>>>>>>>+>?>|. `>>>>>>>>+-.................. <br></br>
 ......................^>>>>>>>>>>>>>+>?????-.+>>>>>>>+-.................. <br></br>
 ......................|>>>>>>>>>>>>+>???????`^>>>>>>>+~.................. <br></br>
 ......................->>>>>>>>+>>>+????????>^>>>>>>++~.................. <br></br>
 .......................^>+>>>>>++++>?????????+>>>>>>+>-.................. <br></br>
 .......................~+>>>>>>>+++>?????????+>>>>>>>+`.................. <br></br>
 .......................`|+>>>>>>>>++????????>+>+>>>>+-................... <br></br>
 ........................`|+>>>>+>>>+>??????>+>>>>>>+~.................... <br></br>
 .........................-|+>>>>>>>>+>????>+>>>>>+^~..................... <br></br>
 ..........................-~|+>>>>>>>+++++>>>>>+^~`...................... <br></br>
 ...........................`-~|^+>>>>+||^>>>++^~-`....................... <br></br>
                  .............................`-~~|^^^++++^^|~--`......................... <br></br>
                  ................................``---~~~---``............................ <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ............`||||^^|^|^|.................               <br></br>
               ................->>>>>>>>>>>-....................           <br></br>
           ....................->>>>>>>>>>>+.......................        <br></br>
         ......................~>+>>>>>>>>>>|.......................       <br></br>
      .........................+>>>>>>>>>>>+>~........................     <br></br>
     .........................~>>+++>>>>>>++++`........................    <br></br>
   ..........................`+>++++++>>>>++++>~........................   <br></br>
  ..........................`+>++>>>>++>>>+>>>>+-......................... <br></br>
 ...........................+>|^+>??>++>++++?}}>>?|....................... <br></br>
 ..........................~>??+^+zzr?+^++>v>wx^ ~v`...................... <br></br>
 .........................~+>x-  .xxx~+}+^v^ vzx.^?^`..................... <br></br>
 ........................-+++r>...xxu| ?}?v+.|zz??+>|..................... <br></br>
 .......................`^>>++vv+~zzz>|v>+>???vv?+>>+-.................... <br></br>
 .......................|>>>>++>?vvvvv?>++^-+>+++>>>>+`................... <br></br>
 ......................`+>>>>>>>+++++++>>++^++>>>>>>>>-................... <br></br>
 ......................~>>>>>>>>>>>>>>>>+++^^^+>>>>>>>^................... <br></br>
 ......................|>>>>>>>>>>>>>>>+^`...~^+>>>>>>+`.................. <br></br>
 ......................^>>>>>>>>>>>>>>+>?>^. .^+>>>>>>+-.................. <br></br>
 .....................`^>>>>>>>>>>>>>>>?????~.|+>>>>>>+~.................. <br></br>
 .....................`^>>>>>>>>>>>>>>???????-|+>>>>>>+~.................. <br></br>
 ......................->++>>>>>+>>>+????????>+>>>>>>>>~.................. <br></br>
 ......................`^>+>>>>>++++>?????????+>>>>>>+>~.................. <br></br>
 .......................~+>>>>>>>+++>?????????+>>>>>>>+`.................. <br></br>
 .......................`|+>>>>>>>+>>????????>+>>>>>>+-................... <br></br>
 ........................`|+>>>>>>>>>???????>+>>>>>>+~`................... <br></br>
 .........................-|+>>>>>>>>>?????>>>>>>>+^~`.................... <br></br>
 ..........................-~^+>>>>>>>>>>>>>>>>>+^|-...................... <br></br>
 ...........................`~~|++>>>>+^^+>>+++^|-`....................... <br></br>
                  .............................`-~||^^^++++^^|~--`......................... <br></br>
                  ................................``---~~~---``............................ <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  .............`^||^||^^|^|^|..................             <br></br>
             .................->>>>>>>>>>>>>-....................          <br></br>
          ....................~>>>>>>>>>>>>++`......................       <br></br>
        ......................^>+++>>>>>>>+++^.......................      <br></br>
      .......................`+>+++++>>>>+^>++^........................    <br></br>
    .........................^>++>>+++>>>+^>>++^........................   <br></br>
   .........................~++>>>+++^+>>+++>?v???-......................  <br></br>
 ..........................~>v}vvvwrv?+^++^vrzx| -}`...................... <br></br>
 .........................-+zv.  ~uzw>v?||v?`zxz. v~...................... <br></br>
 ........................`++w?   `yxx..rvvw- >zu?>?+~..................... <br></br>
 .......................`+>+>w?- `yzu` r?+?}>+zww?+>>~.................... <br></br>
 .......................^>++++vr}vxzz?vv+>++???>>+>>>>-................... <br></br>
 ......................->>>>>>++>?????>+>+^-`+++>>>>>>+`.................. <br></br>
 ......................+>>>>>>>>>+++++++>++^+>>>>>>>>>>^.................. <br></br>
 .....................~>>>>>>>>>>>>>>>>>>>++||+>>>>>>>>>~................. <br></br>
 .....................^>>>>>>>>>>>>>>>>>|`.   `>>>>>>>>>+................. <br></br>
 ....................`+>>>>>>>>>>>>>>>+>+~.    ^>>>>>>>>>`................ <br></br>
 ....................`>>>>>>>>>>>>>>>+>????+.  ~>>>>>>>>>~................ <br></br>
 ....................`+>>>>>>>>>>>>>+>???????- ->>>>>>>>>|................ <br></br>
 .....................+>>>>>>>>>>>>>>?????????``>>>>>>>>>^................ <br></br>
 .....................|>>>>>>>>>+>>+??????????>|>>>>>>>>>^................ <br></br>
 .....................-+>>>>>>>>++>+???????????+>>>>>>>>>|................ <br></br>
 .....................`|+>>>>>>>>++>??????????>+>>>>>>>>>-................ <br></br>
 ......................-^>+>>>>>>>>+??????????+>>>>>>>>>^................. <br></br>
 ......................`~^>>>>>>>>>+?????????>>>>>>>>>+^-................. <br></br>
 .......................-~|+>>>>>>>+>???????>+>>>>>>>+|-.................. <br></br>
 ........................--~+>>>>>>>>>?????>+>>>>>>+^~-................... <br></br>
 .........................-~~^++>>>>>>+>>>>>>>>>>+^|-`.................... <br></br>
 ..........................`-~~|^++>>>++++>>>>++|~--`..................... <br></br>
                  ............................`~~~~||^^^~~|^^||~---`....................... <br></br>
                  ..............................``---~~~~~~~~--``.......................... <br></br>
                  ...................................```````............................... <br></br>


            </div>,

            <div>

                  ...............-^|||^||^^|^^|||...................           <br></br>
           ..................+>+>+>>>>>>>>+>+|.....................        <br></br>
        ....................`+++>>++>>>+>++>++~......................      <br></br>
      ......................^+++++>+++>>>++>>++-.......................    <br></br>
    .......................-++>>>>+++^+>>++++?v???|.....................   <br></br>
  ........................`^?r}vvww}v?^+++^?rzx+ `?~...................... <br></br>
 .........................|vz-   vuzr?}>^|?v`wxu` +^...................... <br></br>
 ........................|+w}    ?yzr ~wvvw+ >xuv-v^`..................... <br></br>
 .......................~>+>zv`  ?yzx. z?>?w+|zwz}>>+`.................... <br></br>
 ......................-+>+++vrv+vuwx|?}+++>v}vv?>>>>+-................... <br></br>
 .....................`+>>>>>+>?vv}}}v?++>+^`|+++>>>>>+`.................. <br></br>
 .....................^>>>>>>>>++++++++>>++|`^>>>>>>>>>+`................. <br></br>
 ....................|>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>+`................ <br></br>
 ...................`+>>>>>>>>>>>>>>>>>>>>+|-`.`+>>>>>>>>^................ <br></br>
 ...................|>>>>>>>>>>>>>>>>>>+~.      ~>>>>>>>>>~............... <br></br>
 ...................+>>>>>>>>>>>>>>>>>>>|`.     `>>>>>>>>>+`.............. <br></br>
 ...................+>>>>>>>>>>>>>>>>+?????+`   .+>>>>>>>>>~.............. <br></br>
 ..................`+>>>>>>>>>>>>>>>+????????|  .+>>>>>>>>>+.............. <br></br>
 ..................`^>>>>>>>>>>>>>>+??????????^ .^>>>>>>>>>+`............. <br></br>
 ..................`^>>>>>>>>>>>>>>>???????????| ^>>>>>>>>>+-............. <br></br>
 ..................`|+>>>>>>>>>>+>+?????????????`+>>>>>>++>+-............. <br></br>
 ..................`~^>+>>>>>>>>+++?????????????>+>>>>>>++++`............. <br></br>
 ...................~|+>>>>>>>>>++>?????????????+++>>>>>>>>^.............. <br></br>
 ...................-~^>+>>>>>>>>+>?????????????+++>>>>>>>>~.............. <br></br>
 ...................`-~^>>>>>>>>>+>????????????+>>>>>>>>>>^............... <br></br>
 ....................`~~^+>>>>>>>>+???????????>+>>>>>>>>>^`............... <br></br>
 .....................`~-|+>>>>>>>+>??????????+>>>>>>>>+|`................ <br></br>
 ......................`~~~^+>>>>>>+>????????+>>>>>>>>+~`................. <br></br>
 .......................`-~~|+>>>>>>>>>???>>+>>>>>>>+|-`.................. <br></br>
 .........................`--~|^+>>>>>+>>>>>>>>>>>+|~-`................... <br></br>
 ..........................`-~~~~|^+++^^^++>+++^|~~-`..................... <br></br>
                  ............................`------~~~--~||~~----`....................... <br></br>


            </div>,

            <div>

                  ...............-^|||^||^^|^^|||...................           <br></br>
           ..................+>+>+>>>>>>>>+++|.....................        <br></br>
        ....................`+++>>++>>>++++>++~......................      <br></br>
      ......................^++++>>+++>>>++>>++-.......................    <br></br>
    .......................-++>>>>+++^+>>++++?v???|.....................   <br></br>
  ........................`^?r}vvww}v?^+++^?rzx+ `?~...................... <br></br>
 .........................|vz-   vuzr?}>^|?v`wxu` +^...................... <br></br>
 ........................|+w}    ?yzr ~wvvw+ >xuv-v^`..................... <br></br>
 .......................~>+>zv`  ?yzx. z?>?w+|zwz}>>+`.................... <br></br>
 ......................-+>+++vrv+vuwx|?}+>+>v}vv?>>>>+-................... <br></br>
 .....................`+>>>>>+>?vv}}}v?++>+^`|+++>>>>>+`.................. <br></br>
 .....................^>>>>>>>>++++++++>>+^|`^>>>>>>>>>+`................. <br></br>
 ....................|>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>+`................ <br></br>
 ...................`+>>>>>>>>>>>>>>>>>>>>+|-`.`+>>>>>>>>^................ <br></br>
 ...................|>>>>>>>>>>>>>>>>>>+~.      ~>>>>>>>>>~............... <br></br>
 ...................+>>>>>>>>>>>>>>>>>>>|`.     `>>>>>>>>>+`.............. <br></br>
 ...................+>>>>>>>>>>>>>>>>+?????+`   .+>>>>>>>>>~.............. <br></br>
 ..................`+>+>>>>>>>>>>>>>+????????|  .^>>>>>>>>>+.............. <br></br>
 ..................`^>>>>>>>>>>>>>>+??????????^ .^>>>>>>>>>+`............. <br></br>
 ..................`|>>>>>>>>>>>>>>>???????????| ^>>>>>>>>>+-............. <br></br>
 ..................`|+>>>>>>>>>>+>+?????????????`+>>>>>>++>>-............. <br></br>
 ..................`~^>+>>>>>>>>+++?????????????>+>>>>>>++++`............. <br></br>
 ...................~|+>>>>+>>>>++>?????????????+++>>>>>>>>^.............. <br></br>
 ...................-~^>+>>>>>>>>+>?????????????+++>>>>>>>>~.............. <br></br>
 ...................`-~^>>>>>>>>>+>????????????++>>>>>>>>>^............... <br></br>
 ....................`~~^+>>>>>>>>+???????????>+>>>>>>>>+^`............... <br></br>
 .....................`~-|+>>>>>>>+>??????????+>>>>>>>>+|`................ <br></br>
 ......................`~~~^+>>>>>>+>????????+>>>>>>>>+~`................. <br></br>
 .......................`-~~|++>>>>>+>>???>>+>>>>>>>+|-`.................. <br></br>
 .........................`--~|^+>>>>>+>>>+>>>>>>>+|~-`................... <br></br>
 ..........................`--~~~|^+++^^^++>+++^|~~-`..................... <br></br>
                  ............................`------~~~--~||~~~---`....................... <br></br>


            </div>,

            <div>

                  ..............-||||||||^|||^|||^|...................          <br></br>
         ..................+>>>>>>>++>>>>++>>+^`....................       <br></br>
      ....................~++>>>?>++++>>>++++>??>?+...................     <br></br>
    .....................`+?rvvv}zr}v>^+++^?}wx? .>?....................   <br></br>
  .......................^?u~   `uzx}v}+^~?w`zzy> ~v ..................... <br></br>
 .......................|+?u`   .zuzv +zvrwv >yzu|v?`..................... <br></br>
 ......................|>>^vw|   ?yzx. xv>?z>~zzwz?+>~.................... <br></br>
 .....................~>>>++?r}>->ywu|?z>++>}wwwv?+>>>|................... <br></br>
 ....................`+++>>>++??v}wrrv?>+>>+~~>>+>>>>>>^.................. <br></br>
 ....................^>++>>>>>>++>>>++++>+^|`.^>>>>>>>++^................. <br></br>
 ...................|>>>>>>>>>>>>>>>>>>+>++^|^>>>>>>>>>>>+`............... <br></br>
 ..................`+>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>+^`.............. <br></br>
 ..................^>>>>>>>>>>>>>>>>>>+>>+^|~-``^>>>>>>>>+>+.............. <br></br>
 .................`>>>>>>>>>>>>>>>>>>>>+-.       |>>>>>>>>>>^............. <br></br>
 .................~>>>>>>>>>>>>>>>>>>>>^-.       .>>>>>>>>++>~............ <br></br>
 .................~>>>>>>>>>>>>>>>>>+>????>|.     ^>>>>>>>>+++`........... <br></br>
 .................|>>>>>>>>>>>>>>>>+>????????|    |>>>>>>>>+++~........... <br></br>
 ................`|>>>>>>>>>>>>>>>>+??????????+.  ~>>>>>>>>>>+|........... <br></br>
 ................`|>>>>>>>>>>>>>>>+????????????>. ~>>>>>>>>>>++........... <br></br>
 ................-~+>+>>>>>>>>>>>+>?????????????+ ~>>>>>>>>++++`.......... <br></br>
 ................`~^>+>>>>>>>>>>>+???????????????-~>>>>>++>++++........... <br></br>
 ................`~|+++>>>>>>>>>>>???????????????>^>>>>>++++>>+........... <br></br>
 ................`-~^>>>>>>>>>>>+>????????????????+>>>>>>>+++>^........... <br></br>
 .................-~~+>>>>>>>>>>+>???????????????>+++>>>>>>+++-........... <br></br>
 .................`--|+>>>>>>>>>+????????????????>+>>>>>>>>>+~............ <br></br>
 ..................---|+>>>>>>>>+????????????????+>>>>>>>+++|............. <br></br>
 ...................--~|+>>>>>>>+>??????????????>+>>>>>>>++|`............. <br></br>
 ...................`-~~|+>>>>>>>+?????????????>+>>>>>>>+^~`.............. <br></br>
 ....................`---~^+>>>>>>+???????????>+>>>>>>>+|-`............... <br></br>
 ......................`---~^+>>>>>+>>??????>>+>>>>>>+^~-`................ <br></br>
 .......................`--~~~^+>>>>>>+>>>>+>>>>>>>+^|-``................. <br></br>
                  .........................-~----~|^++++++++++++++^|~--`................... <br></br>


            </div>,

            <div>

                  ..............-||^^||||^|||^|||^|...................          <br></br>
         ..................+>+>>>>>++>>>>++>>+^`....................       <br></br>
      ....................~++>>>?>++++>>>++++>????>...................     <br></br>
    .....................`+?r}vvrzw}v>^++^^?}wx? .>?....................   <br></br>
  .......................^?u|   `xxx}vr+^~?z-wxy? ~} ..................... <br></br>
 .......................|+?u`    wuzv +zvrwv >yzu^v?`..................... <br></br>
 ......................|>>+vw|   vyzx. zv>?z?-zzzzv+>~.................... <br></br>
 .....................~>+>++?w}>-?ywu|?z>++>}wwrv?+>>>|................... <br></br>
 ....................`+++>>>++?v}rwrrvv>+>>+~~++++>>>>>^`................. <br></br>
 ....................^>++>>>>>++++>>++++>+^~` ^>>>>>>>>>^`................ <br></br>
 ...................|>>>>>>>>>>>>>>>>>++>++^^^>>>>>>>>>>>+`............... <br></br>
 ..................`+>>>>>>>>>>>>>>>>>++>>>>>>>>>>>>>>>>>>^`.............. <br></br>
 ..................^>>>>>>>>>>>>>>>>>>+>>+^|~```|>>>>>>>>>>^.............. <br></br>
 .................`>>>>>>>>>>>>>>>>>>>>+-.       |>>>>>>>>>>^............. <br></br>
 .................~>>>>>>>>>>>>>>>>>>>>^-.       `>>>>>>>>>>>~............ <br></br>
 .................~>>>>>>>>>>>>>>>>>+>????>|.     ^>>>>>>>>>>+`........... <br></br>
 ................`|>>>>>>>>>>>>>>>>+>????????^.   |>>>>>>>>>>>~........... <br></br>
 ................`|>>>>>>>>>>>>>>>>+??????????>.  ~>>>>>>>>>>+|........... <br></br>
 ................`|>>>>>>>>>>>>>>>+????????????>. ~>>>>>>>>>>>+........... <br></br>
 ................-~+>+>>>>>>>>>>>+>?????????????+ ~>>>>>>>>++++........... <br></br>
 ................`~^>+>>>>>>>>>>>+???????????????-~>>>>>++>++++........... <br></br>
 ................`~|+++>>>>>>>>>>>???????????????>^>>>>>+++++>^........... <br></br>
 ................`-~^>+>>>>>>>>>+>????????????????+>>>>>>>+++>^........... <br></br>
 .................-~~+>>>>>>>>>>+>???????????????>+++>>>>>>+>>-........... <br></br>
 .................`--|+>>>>>>>>>+????????????????++>>>>>>>+>>|............ <br></br>
 ..................---|+>>>>>>>>+>???????????????+>>>>>>>>++^............. <br></br>
 ...................--~|+>>>>>>>+>??????????????>+>>>>>>>>+|`............. <br></br>
 ...................`-~~|+>>>>>>>+?????????????>+>>>>>>>>+|`.............. <br></br>
 ....................`---~^+>>>>>>+???????????>+>>>>>>>+^~`............... <br></br>
 ......................`---~^+>>>>>+>>??????>>+>>>>>>+^~-`................ <br></br>
 .......................`-~~~~^++>>>>>+>>>>>+>>>>>>+^|-``................. <br></br>
                  .........................-~----~|^++++++++++++++^|~--`................... <br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+ >vvvvvvvvvvvvv>..>vv?????~||||||~~- <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwr}vv???>>>>+- +>?vrwwwwwwww?.-vwwrvvvv?^+++++++++.<br></br>
`wwwwwwwwwwwwwwwwwww}v?>+^^^^^^+++>?>.^?vvv?wwwwww}|.>wwrvvvvvv>^+++++++++.<br></br>
`wwwwwwwwwwwwwwwwrv?+^^++++>????vvvv^.vv?vv?vwwwwv``vwrvvvvv?v?^++++++++++.<br></br>
`wwwwwwwwwwwwwwwr?vvv?vvvv?vvvvvvvv>.^vvvvvv?rww>.+rrvvvvvvv??++++++++++++.<br></br>
`wwwwwwwwwwwwwwwr?vvvvvvvvvv??vvvvv~.?vv}^.|vv}~.?rvvvvvvvvvv+^+++++++++++.<br></br>
`}rrwwwwwwwwwwwwv?vv>??vvvvvv>>vvv>`|vrru-.v}+.-?vvvvvvvv?vv?^+++++++++++>.<br></br>
.?vvv}}rrwwwwwrv??vv  .|wv}v??+^>?|.-.rxx}}v|`~^>?vvvvv?????~^^^+++++++>}w-<br></br>
`v?vvvvvvvvvvvv???v}?|.`uzyv .-vvw>~~ +xrr?-|v?-^>?vvvvv?v+-~~~~~|^+++>vwz~<br></br>
`??vvvvvvvvv??????vvv}vvwwuv  ^?>?`+v?>rv+->vvv>~^+?vvvv?+^|~~~~~~~~?>+vww-<br></br>
.?vv?vvvvvvv>+>?v?vvvvvvvv}v+??+v?|v????^|?vvvvv^||+vvv?++>+|~~~~~~~~~?w}?.<br></br>
`vv?vvvvvvvv^|~^v?vvvvvvvv?????vv^?vvv>~|vvvvvv?v|~^vv+~+>+++|~~~~~~~~>+~~.<br></br>
.>?vvvvvvvv?|^^~vvvvvvvvvvvvvv??^`>>+-`>^?vvvvv^v?->?~`~|^++++~-~~~~~~~^>+.<br></br>
.--^?????vv?^+^~?}?vvvvvvvvvvv?+||+^|~+?>????vv?^?|^^+-~~~|+++^-~~~~~-^??v`<br></br>
.~-->vv??vvv+^||?wrv?vvvvv?>?v?>>>+^+???>?}}?vvv?v>|++^`~~~|^++~-~`---~>??`<br></br>
.-^vv?|~^>??v+||+vr}>?vv????v?????????v}wwyywzvvvvv++++|-~~~~^+~--``~~??+|.<br></br>
.>??>+>?>-~-^>|^|~+???vvvvvv}wwz}zuuruyuxvv?^`.-vvv?^++?|-~~~~|~~|>?v}}^-` <br></br>
`vv?>??+^~|-|++|+|^?vvvvvv}uzyuxr}}v??>+|-.     ?vvv>?vrv--~~~|>v}rzzr>~~~.<br></br>
.^^|~-|~~~~-|+++^~+?vvvvv||~-^^^+||>?>>?>>+^` .->vv}rwww?|.~+?v}rwzw}v?v>|.<br></br>
.|~-`|^~~~~-~++++|^>vvv>?. .~^+>??>???????>+^>}v>v}wzwrv?>>v}rrrzzwrwwwwv}~<br></br>
.--~-~~~~~~~`++++|^++??+?. ~+>?????++??????>}xzw}wzzwrr}rrrr}rwzzwwzzrwwzw~<br></br>
.~~~~-~~~~~~-|+++|^+^+>^?|>+^>??vrwzxxuuuuxxzwwwzxzr}rrrrr}}rzxxzzzwww}}zw~<br></br>
.~~~~-~~~~^vvvvvv???+>>++vrrwwxuxxxxxuuxxxzzwwwzzzr}}rrr}}}wzzwxxzzzxzwwwr~<br></br>
.~~~--~~-~~^vwzzzzwrr}vv?vvxzzzxzwzzzzzwzzxzr}zxxw}}}rrr}rwzz}vr}}}vvvvv??`<br></br>
.~^???>>>+^^vrrrwrwwwzzxxxzzzzzzzwrzxzzzxxxzv+}rvv}}}vvvvvvvvvvvvvvvvvvvvv`<br></br>
`rrzzzzzwwrwr+>v?}}}}rrwwzzxxxxxxw^+wxx}}zwwwvv????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`xxxxxxxxxxxxwrv}zwwrrrrrrrrwxxxxzv?}vvvvv??????vvvvvvvvvvvvvv??>????v??vv`<br></br>
`zzzxxxxuxxxxxxxxxxxxzzwzzw}}v????v??vvvvvvvvvvvvvvvvvvvvvvvvv????????>>?>`<br></br>
`rrw}zx}vwxxxxxxxzwwrvvvv???vvvvvvvvvvvvvvvvvvvvvvvv????????>++>+++^^^^^++`<br></br>
`}}rvrwv^?xzwr}vvvvvvvvvvvvvvvvvvv??????????????????????>+++++^|^^^+++>>??`<br></br>
`r}}}vvvvv??vvv?vvvvvvvvv??>>>>++++^^^+>??????????????>+^^++>>>++^>>??????`<br></br>
`}vvvvvvv??vvv??vvvvv??+^^^+^+++>>>????????????????>+^^^++>>>>????>???????`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+ >vvvvvvvvvvvvv>..>vv?????~||||||~~- <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwr}vv???>>>>+- +>?vrwwwwwwww?.-vwwrvvvv?^+++++++++.<br></br>
`wwwwwwwwwwwwwwwwwww}v?>+^^^^^^+++>?>.^?vvv?wwwwww}|.>wwrvvvvvv>^+++++++++.<br></br>
`wwwwwwwwwwwwwwwwrv?+^^++++>????vvvv^.vv?vv?vwwwwv``vwrvvvvv?v?^++++++++++.<br></br>
`wwwwwwwwwwwwwwwr?vvv?vvvv?vvvvvvvv>.^vvvvvv?rww>.+rrvvvvvvv??++++++++++++.<br></br>
`wwwwwwwwwwwwwwwr?vvvvvvvvvv??vvvvv~.?vv}^.|vv}~.?rvvvvvvvvvv+^+++++++++++.<br></br>
`}rrwwwwwwwwwwwwv?vv>??vvvvvv>>vvv>`|vrru-.v}+.-?vvvvvvvv?vv?^+++++++++++>.<br></br>
.?vvv}}rrwwwwwrv??vv  .|wv}v??+^>?|.-.rxx}}v|`~^>?vvvvv?????~^^^+++++++>}w-<br></br>
`v?vvvvvvvvvvvv???v}?|.`uzyv .-vvw>~~ +xrr?-|v?-^>?vvvvv?v+-~~~~~|^+++>vwz~<br></br>
`??vvvvvvvvv??????vvv}vvwwuv  ^?>?`+v?>rv+->vvv>~^+?vvvv?+^|~~~~~~~~?>+vww-<br></br>
.?vv?vvvvvvv>+>?v?vvvvvvvv}v+??+v?|v????^|?vvvvv^||+vvv?++>+|~~~~~~~~~?w}?.<br></br>
`vv?vvvvvvvv^|~^v?vvvvvvvv?????vv^?vvv>~|vvvvvv?v|~^vv+~+>+++|~~~~~~~~>+~~.<br></br>
.>?vvvvvvvv?|^^~vvvvvvvvvvvvvv??^`>>+-`>^?vvvvv^v?->?~`~|^++++~-~~~~~~~^>+.<br></br>
.--^?????vv?^+^~?}?vvvvvvvvvvv?+||+^|~+?>????vv?^?|^^+-~~~|+++^-~~~~~-^??v`<br></br>
.~-->vv??vvv+^||?wrv?vvvvv?>?v?>>>+^+???>?}}?vvv?v>|++^`~~~|^++~-~`---~>??`<br></br>
.-^vv?|~^>??v+||+vr}>?vv????v?????????v}wwyywzvvvvv++++|-~~~~^+~--``~~??+|.<br></br>
.>??>+>?>-~-^>|^|~+???vvvvvv}wwz}zuuruyuxvv?^`.-vvv?^++?|-~~~~|~~|>?v}}^-` <br></br>
`vv?>??+^~|-|++|+|^?vvvvvv}uzyuxr}}v??>+|-.     ?vvv>?vrv--~~~|>v}rzzr>~~~.<br></br>
.^^|~-|~~~~-|+++^~+?vvvvv|||-^^^+||>?>>?>>+^` .->vv}rwww?|.~+?v}rwzw}v?v>~.<br></br>
.|~-`|^~~~~-~++++|^>vvv>?. .~^+>??>???????>+^>}v>v}wzwrv?>>v}rrrzzwrwwwwv}~<br></br>
.--~-~~~~~~~`++++|^++??+?. ~+>?????++??????>}xzw}wzzwrr}rrrr}rwzzwwzzrwwzw~<br></br>
.~~~~-~~~~~~-|+++|^+^+>^?|>+^>??vrwzxxuuuuxxzwwwzxzr}rrrrr}}rzxxzzzwww}}zw~<br></br>
.~~~~-~~~~^vvvvvv???+>>++vrrwwxuxxxxxuuxxxzzwwwzzzr}}rrr}}}wzzwxxzzzxzwwwr~<br></br>
.~~~--~~-~~^vwzzzzwrr}}v?vvxzzzxzwzzzzzwzzxzr}zxxw}}}rrr}rwzz}vr}}}vvvvv??`<br></br>
.~^???>>>+^^vrrrwrwwwzzxxxzzzzzzzwrzxzzzxxxzv+}rvv}}}vvvvvvvvvvvvvvvvvvvvv`<br></br>
`rrzzzzzwwrwr+>v?}}}}rrwwzzxxxxxxw^+wxx}}zwwwvv????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
`xxxxxxxxxxxxwrv}zwwrrrrrrrrwxxxxzv?}vvvvv??????vvvvvvvvvvvvvv??>????v?vvv`<br></br>
`zzzxxxxuxxxxxxxxxxxxzzwzzw}}v????v??vvvvvvvvvvvvvvvvvvvvvvvvv????????>>>>`<br></br>
`rrw}zx}vwxxxxxxxzwwrvvvv???vvvvvvvvvvvvvvvvvvvvvvvv????????>++>+++^^^^^++`<br></br>
`}}rvrwv^?xzwr}vvvvvvvvvvvvvvvvvvv??????????????????????>+++++^|^^^+++>>??`<br></br>
`r}}}vvvvv??vvv?vvvvvvvvv??>>>>++++^^^+>??????????????>+^^++>>>++^>>??????`<br></br>
`}vvvvvvv??vvv??vvvvv??+^^^+^+++>>>????????????????>+^^^++>>>>????>???????`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?- ^vvvvvvvvvvvvv>+>~.>?~--~||||~~~-` <br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwrrrrrrr}}}v`.vrrwwwwwwwww}??v|~vw}|||+++++++++^.<br></br>
`wwwwwwwwwwwwwwwwwwwwrr}}}}}}}}}}}}}}>.~rrrrwwwwwwwv?v?-+rrr?~|^++++++++++`<br></br>
`wwwwwwwwwwwwwwwwwr}}}}}}}}rr}}}}}}v?~.>vvv}rwwwwr??v+-?wr}v?~|^+++++++++?`<br></br>
`wwwwwwr}}rwwwwww}rrrrrr}vv??????>?+-``>???v}www}?vv~|}rr}vv>~^++++++++^>?`<br></br>
`wwrwwwv}}}}wwwwwrrr}v??>>>>>>?????>|`+vv?^>vrrv?v>-?rr}vv??^|+++++++++^>?`<br></br>
`}}rrw}vv}}v}wwwr}r?>??????????vvvv>^`v}r>^vvvvvv||vr}vvvvv>|+++++++++++|>`<br></br>
.?vv}vvvv}}v}wwrr}}?||^>}v}vv???vv?+``}rrvv?>+v>->}}vvvvvv?~|++++++++++>>+`<br></br>
 ~?v?vvvvvv?rrrrr}v??>~^wrwv^+|?v}?+.`?rrr- ^?^`>?vvvvvvvv|-~~||^^++++>>+?~<br></br>
 `?vvvvvv???vv}v}vvvv?.`^>wv~|?>>?+^-?>wrz`|?||^+??v??v?v^~~~~~~~~|^+++>+v-<br></br>
.?vvvvv???vv????vvvvvv>~.`xw}?^^???|?-.r}}v>|?v++>??v?v?+++|~~~~~~~~~|^>^?`<br></br>
`vvvvvv>>vvv?????vvvvvv}v?w}x? `v>?~??+?vv^+vvv>>+??vv+|+>+^|~~~~~~~~~~-`-`<br></br>
.?vvvv?>???v???+>??vvvvvvvv}w?+?+>+^?>>?+~>vvvv?>+>??|`~^+++^|~~~~~~~~|^^|`<br></br>
.+>?v??vvv?????^>?>vvvvvvvvvvv?>??|????+|?vvvvvv?^>>^``~~|^++^~~~~~-~^+>?v-<br></br>
.++>?v?vvvv????|>??v}vvvvvvvvvv???>>>?|+??vvvv?}v??>>+``~~|^++|~~--|^|+?vv`<br></br>
.+?vv?++??vv???|^>?v}vvvvvvvvv?vv??+++^?}v}}vr????????>^~~--|^|---|^^|>>??`<br></br>
.?vv???^+|^>????>+^?}rv?vvvvvv}v??????v}v?vv?>+>?vv?>??v?++^^|~~|+>??>?>++.<br></br>
`vvvvvv>|``->?>>????vv?????vvr}?vvv????vv}r}v?^+?v?>+>???|~|^+>?vvv}}v?>>|.<br></br>
.?????>>^`-~+++>?????vvv?>?>>?>+++?}wzxxxzwrv??+?vv?>?>>+^-~?vvvvvvvvv??^-.<br></br>
.>>+|^+^^^++???>>>>?vvv?v+-`~|^>vwzxxxxxxzww}wrvvvv?????v}v???vv???????}rw~<br></br>
.-`.``````-~+????????>>+>``~^>vrxxxzwzwwwwwwzxzzr}v??v}wwwv>?vrwwrrwwwzzzz|<br></br>
.-~-`````...`+???>>+^|||^~>>?zxxxzwrrrrrrr}}rwzwwrvvrwzwr}vvrrwwrrxzzxzzxz|<br></br>
.^?>|-~~--~^~~|||+++|^^^>vzzzzw}}v}}vvv}wrvrwzwwwwvvzzwr}}rrwwwwzxrwzzzwzw~<br></br>
.|+^~|+>>>>?????vvv???>>>?vrw}vv???vv??rwwwzzrwzzwwrrw}}}}}r}vv}}vvv?vv??v`<br></br>
.~~|||^>?vvv??rrzzzwwrr}}vvvrwwrvv?}zwwzxx}}rvrzww}v}vv?????vvvvvvvvvvvvvv`<br></br>
.>+^^|||^+>?vvvv}wwwwzzzzzzzzzzwzrvvzxxwwz}}r}}v???????>?vvvvvvvvvvvvvvvvv-<br></br>
`zwwr}vvvvvvrwr}}rrrvwzzzzzzzxxxzw}vvvvv????v??????vvvvvvvvvvvv?????vv???v`<br></br>
`wxxzxxxxxxxzzzzwwwwwrrrwwwr}vvv?????vvvvvvvvvvvvvvvv??vvvvvvv????????????`<br></br>
.>wzzzxzwxxxxxxxzwr}vvv????????????vvvvvvvv???????????????????????????????`<br></br>
`vwzzzzw}rzwrr}vv??v???????vvvvvvv?vvvv?????vvvvv?????????????????????????`<br></br>
`r}}}}vvvv??vvvv??????vvvv??????????????????????????????????????>>>>??????`<br></br>
`v??vvvvvv?vvvvvvvvvvv??????????????????????????????>>>>>>>>>>>>>>>??>>>??`<br></br>


            </div>,

            <div>

`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv+ |vvvvvvvvvvvvvvv>- >v~||||||~~~--`.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww| vwwwwwwwwwwwwww?``vz}^+++++++++++^.<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww}.-wwwwwwwwwwwww}+.^rww?|++++++++++^>`<br></br>
`wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwrr}}vv> >vv}rwwwwwwwwv~`?wwrv?^++++++++++>v-<br></br>
`wwwwww}vv}wwwwwwwwwwwwr}vv?>>>++^^+^-.+>???rwwwwww?`|}ww}?v>++++++++++^vv-<br></br>
`ww}rwr?vvvvwwwwwwwrv?>+^||^++>>?????.^v?vvvvwwwwr+`?wwrvv??^++++++++++^?v`<br></br>
`v}}}wv?vvvvvwwwwww??????????vvvvvvv>.?vvvvv?}wwv~~}wrvvvvv+^+++++++++++|?`<br></br>
.???vv???vv?vwwwwwr?vvvvvvvvv??vvvvv-~vvvvv???r?`>r}vvvvvv?|+++++++++++++|.<br></br>
 ~?v?vvvvvv>rwwwwwv??v?vvvvvvv??vvvv.>vv}r` +?~`>vvvvv??vv|-~||^^+++++++^?-<br></br>
.`?vvvvvv?+?}}}rr}v??> .-+}vvvv>>??^`??wru-|?~`|>vvv??v?v^~~~~~~~~|^++++^v-<br></br>
.?vvvvv?+>vvvvvvv?v??v>- .uwx?|+>?v~?- wwzv+-??~^?vvv???^+^~~~~~~~~~~|^+|?-<br></br>
`vvvvvv+|vvvvvv????vvvv}v?xry? `v??`??>}w?~^vvv+|+?vvv>^+++|~~~~~~~~~~--~+-<br></br>
`vvvvv?^>??vvvv>|>?vvvvvvvv}z?+?+?>^???v>->vvvv?|~?vv+-~^+++|~~~~~~~-~+>^^-<br></br>
`vvvv?>vvv??vvv+^^^vvvvvvvv??v??vv|?vv?^|?vvvvvv?|??^`~~~^+++|~~~~~-+?>+>?-<br></br>
`vvvv?>vvvv??v?|+^~v}vvvvvvvv?v???>???-^???vvv+}?????^`-~~|+++~~~-~>???vvv`<br></br>
`vvvvv??vvvv???^^|^?wvvvvvvvvv?vv?>^^|~>v????v????????>+^^~~++^||+>??>>+?v`<br></br>
`vvvvv?|>?vvv????>^>rwv>vvvv??v?+||^^+?????vvvvv?vv??vvvvvvv??>>?????+>?vv-<br></br>
`vvvvvv?+++>?????vvvvv?>??>????+??????vvrzuuxw?v??>~^+>>+>??????vvvvvvvvv?`<br></br>
`vvvvvvvv>>?>+^>??????????vv???>>>?rxuyyyuxxzrr?vvv^^^|~|^^+vvvv????vvv?+|.<br></br>
.??????vvvvvvv?>??>?vvvvv?+|~|~+vxyyyyyyyxxuwxrvrv?>^+>?rzv>????>>+++^>}wz~<br></br>
.~|^+++++>>??vvvvvvv?+++^|~~|>vwyyyyuxzzzzzzxuxzwv?+>?vwzw?~>v}}rrrwwwxxxx|<br></br>
.~|~--------|>????>+^~~~~|>??uyyuzrvvvvvvvvv}wzwrv?vrwzzrv?v}r}}vrxxxxxxxx|<br></br>
.?w}|-~~--~~-----|||~^^^>vxxzzrvv?vv??>vrv?}wzwrrr?vxxzr}}rrrrrrwx}wzzzzzz~<br></br>
.+?>~+vv}}}v?^+>????++^^+>v}rv??+^>??^>rwrrzzrrwwzwrww}r}}}vvvvv}v???????v`<br></br>
.-~~~|>?vrzz?+r}zzzwwrvvv???}ww}??>vwrwzxxvvzrwzzz}?}vv??????v?vvvvvvvvvvv`<br></br>
.^~~~~---|^>?rw}wwzzzxxxzzzwzzzrwrrwxxxxzwvv}}}vv????>++?vvvvvvvvvvvvvvvvv-<br></br>
`wrrv???????}wrr}}}r?wzxxxxxxxxxzw}vvvvv???vvv??????vvvvvvvvvvvvvvvvvv???v`<br></br>
.rxxzxxxxxxxzwwwrrr}}}}}rrrr}}vv?????vvvvvvvvvvvvvv?v??vvvvvvvv???????????`<br></br>
.|zxzxxxxxxxxxxxzw}vvv??????>>>????vvvvvvvv???????????vv??????????????????`<br></br>
`vwxxxxzzzwrr}}vv???>++>?????vvvvvvvvvv?????vvvvvv???????????????????????>`<br></br>
`}}}}}vvvv??vvvv???>>??vvvvvvvvvvvvvvvvvv?????????????????????????>>?>>>?>`<br></br>
`???vvvvvvvvvvvvvvvvvvvvvvvv???????????????????????????????>>>>>>>???>>>??`<br></br>


            </div>,

            <div>

.?vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?--vvvv?->vvvvvvvvvvvvvvv?^..+-`````||~``-~.<br></br>
.vwwwwwwwwwwwwwwwwwwwrrrwwwwwwwv`?wwwwv^}wwwwwwwwwwwwww}>~`^r>|^~``-|+>>+^.<br></br>
.vwwwwwwwwwwwwwwwwr}}}}}rwwwwww>~rwwww>?wwwwwwwwwwwwwwv>>^|rr?+++^-``-|++?`<br></br>
.?rrrrwwwwwrr}}}}}}v}}vvrwwwww}~?wr}}}+vrrrwwwwwrwww}>>v+~}r}>+++++~```-?v`<br></br>
.?}rwwwr}vv}}}}}rr}}}vv}rrr}}}+.??>>>?>vv}}}wwrv?v}?|^vv+vrrv>++++++|``+v?`<br></br>
.>?}rr}?vvvv}}}}vvvvvvvvvvvv}v-~???v?|?vvvvvvw}?vv>~?vvvvvvv+^+++++++--~?v-<br></br>
.?vv}rv?vvvv}}v?vv}}}}}}vvv??>-?vv??+`>????v?}wv?|^v}???vv?>|+++++++^``-^?-<br></br>
.?vvvvvvvvvvvvvrwww}}v???????+>v????+|??vvv???w?-+v}v????vv||+++++++^~^>+>`<br></br>
.+?vvvvvvvv???rwwwr}vv?vvvvvvv?????v~^?vv}|.>?>?>??vvv????|-~|||^^+++>>>+?-<br></br>
.|?vvvvvvv?vvvv}rr}}v?`~|>vvv??>>>?>`+?rrz|^>>+v>>???vv>?|..`~|~~~|^+>>>+v-<br></br>
.?vvvvv???vvvvvvvvvv?v+~`~rvv+~^>?v~^--rrzv`.??|->?>??v?^``..`|^^^^^^^++|?-<br></br>
.??????+^?????vvvvvvvvv}v?v?v+ -vv>`^?^+zr?|v?+-~^?>>?>>>+^-..`~~^^|~-`-~+`<br></br>
.?????>+>>?????>>?vvvvv??????>>v>?+|??`.v?vv>>v?||>?>|`-+>>+^~-..`-~~|+++^`<br></br>
.?????>???>????+^++??>????>>?v?>??~????|>v?>?vvv>|+++-..`~^>>+^^|~--^>>+>?`<br></br>
.?????>????????+++^?v?vvv?>??>?>+>+???^>?+>vvv?v?+>+++`....-|^|^||^>>>>???`<br></br>
.?????>>????>>?^^^^?v????????>>>>>????>+>?vvvv??v+>>>>+^|~-`~~|||+>>>>++??`<br></br>
.?????>^>????>??>>+>?v?>???>>>>>^|^+++^>???vvvv??>?>>>>>????>>++>>>>>+>???`<br></br>
.??????>+++>>>>>>??>??>+>>++>>>???>>>>?vvv}rr}vvv?+^^+>>>+>???>>>>>???vvvv`<br></br>
.+^|^+>>?>>>>++>>>>>>>>>>??>?v?>>>?v}rrrwzzxw}}?vv?+>?????^~>??????vvvvv??`<br></br>
.|~``---~|+>>>>+>>>>?>>>??+>>??vwxuuuuuyyuuuzw??vv>>?vvvr}^-+?vvvvvv??v}rw~<br></br>
.`......``-`-~~~~+??>>>+>>|^+}rxuyyyuuuuxxxzzzr?vv??vv}rv+^+vrwwrrrrrrwzwz~<br></br>
.~~-`````..````-`|>++>??>>+^?zwxuxxxzzzwrwwwwwz}rr}}rww}?v}rwwwr}rrrwwwwww~<br></br>
.|^^^^^+^|||-```..|^^^>?v}wrzv}zwrrwrr}}wrrwzzwwzz}}wwrrrzzzzwrrrwv}rrrrrr~<br></br>
.|||+?}rrwr}v???>>??>>+>?v}rwr}w}vvvv?vwwrwzzwwzzzwwwrrwr}vvvvvv}vvvvvvvvv-<br></br>
.~~~~^?vr}?r}vwwzzzwwr}vvvv?}zwr}vv}rrzxr}rrzwwzwrvv}vv????vvvvvvvvvvvvvvv-<br></br>
.^~~~~-~|^+?}wzwzzzwzxxxzzzrwzzwzwwwzwwwwrvvvvvvv???v???vvvvvvvvvvvvvvvvvv`<br></br>
`wr}v???????}rrrrwr}rzxxxxxxxzwww}vvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
`zxxzxxxxxxxzzzwwwwwr}vv}vvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????`<br></br>
.vzxxxxxxxxxzzzzw}v?????????????v?vvvvvvvvvvvvvvvvvvvvvvv?????????????????`<br></br>
`vwzzzzwwrr}}vvvvv?v????vvvvvvvvvvvvvvv????????????vv?????????????????????`<br></br>
`vvvv}vvvvvvvvvvv????vvvvvvvvv?????>>??>>>>???????????????????????????????`<br></br>
`??vvvvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>?????????????????????????????????????`<br></br>


            </div>,

            <div>

 ^vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?..?vvvvvvvvvvvvvvvvvvvvvvv^ -|~|||^>??|`~~.<br></br>
 >zwwwwwwwwwwwwwwwwwrvvvrwwwwww? ^zwwwwwwwwwwwwwwwwwwwwww?.`rv|^|^++?vvv??`<br></br>
.>wwwwwwwwwwwrrrrrv?vv?vvwwwwwr-.}wwwwwwwwwwwwwwwwwwwwwwv`.}w}^++||^+>??vv-<br></br>
.^}}rrwwwww}vvvvvvv?vv?>vwwwwwv +zw}}wwwwwwwwwwr}rww}v}v-.vwwv^+++^~^+^>vv`<br></br>
.^?rrwwrrvvvvvvvvvvvv?^?wwwwww+.vv???}wwwwwwwwv+^+?v????`?zww?++++++~|^???`<br></br>
.^+}vvvvvvvvvvvv??>>>?}wwwwwwr`-vv?v??vvvvv?vwv|>???>>+?v?}rv+++++++^|+>+?-<br></br>
.>?vvvvvvvvvvv>^+?v}rwwr}v??>~ ?v??+^>+|^>>??vw^|>vv?|^>vv?v?^++++++||^+?v-<br></br>
`vvvvvvvvvvv?+?vwwww}?>+^^^+>-^v?+^^v?^+v?vvv?w?^>?vv?^^>vv?^+++++^++^?vvv-<br></br>
`vvvvvvvvvvv+|}wwwww???vvvvv?vv?++^v?|^vvvvvv?vr+^>?vv+^+>????>^+>?vvvvvvv-<br></br>
`vvvvvvvvvvvv>>}rrwwvvvvvv??v?>++^??+|?vvvv?+v?w>|^+?vv^++++>vvv??vvvvvvvv-<br></br>
`vv???vvvvvvvvvvvvvv??+??vv?+++^>??+^^vvvvv .v?+`^^^+?v?+^^++>vvvvvvvvvvv?`<br></br>
.>+++++++>>>??vvvvvvvvvvv?+++^+?vv>^~>>|wzz|v>|`-|+^^+?vv??>++>??vv???>>+^.<br></br>
.++++++++++++++>??vvvv???+^++>?v?>+^??. rwr?|^?>|||+^^^?vvvvv?>^^+???>^^+^.<br></br>
.++++++++++++++++++>+++>>>^^>?>++^|>?>?+v}+~?vv?||||+^||+?vvvvvv????+^^++^.<br></br>
.+++++++++++++++++>>>??v?+^+>+^^|^^vv????|+vvvvv^|^~|+++^^^>?vvvvvv>^^|^+^.<br></br>
.++++++++++++++^^^^++>>+^+++^^++^^?vvvv+^?vvvv??v|^^^^^^+++^^^+>>+^||^+++^.<br></br>
.++++++++++++++++++++^^++++^^^~++|+>>+-+????vv?+?|++|^|^^^^^^^^^^^^^^++>>>`<br></br>
.|++++++++++++^^^^^^^^+^++^||^+vv>^||^>??????vvvv?^+++>?>^++++^|^||^^+?vv}~<br></br>
.^|~~||^++++++++++^^^^^^++^^>v??????????v}zz}vvvvv|+?vwzwv+~|^++>>?vv}rrrr~<br></br>
.+^~~-----~~|^^++++++^||^+^?vvwzxxxxxxuyyyuzz}>?v?~?rzwrr?~-^?vwzwrr}}rr}}~<br></br>
.^|~~~~~~~|~----`|+^^>>+??^+?uxuzuyyyyyuuxzrwrv+???}zwr}>~^?wzxxrrr}}}rrrr~<br></br>
.~~~~~~~~-~~~~~~-~>++vrrv?^-vr?wxxuuuuuxzxuxzzwvrwzwrrr>+vwzxxzw}}vvv}}}}}-<br></br>
.~~~>????>+^~~~~-`^++^?rwrvvz+?yuxxxxxzzzzzzzwzzzzzr}}v}rxxxxwr}}vvvvvvvvv-<br></br>
.~~~?wwzzzr}vv??++???>>?vrzzwwxuxzwwrwwzwrzwwzzxxzzzz}rwrvvvvvvvvvvvvvvvvv-<br></br>
.~~~~+?}wv|vwxxzwzzwwwr}rrw?}zzwwwzwrrzxv?xzzzwwrvv?v}v???vvvvvvvvvvvvvvvv-<br></br>
.^~~~--~+>?rwzzxxxzwxxxxzzz}wxxxzzzwwrr}vvvvv??vv??vvvvvvvvvvvvvvvvvvvvvv?`<br></br>
`wr}v???>>?}}rrrwzw}xxxxxxxxzw}}vvvvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>++.<br></br>
`xxxxxxzzzzzzxzzzzzzr}vvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>>>>???`<br></br>
`zxxxxxxxxzzwwrr}v>>>>>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????vv??`<br></br>
`rwwrwr}}}}vvvvvvvvvv}}}vvvvvvvvvvv?????>>>>???????vvvvv????vvvv??????????`<br></br>
`v??vvvvvv}}v?vvvvvvvvvvvvvvv???>>+^|^^^+++>??????????????????????????????`<br></br>
`??v}vvvvvvv?vvvvvvvvvvvvv??>^^^++++|>>???????????????????????????????????`<br></br>


            </div>,

            <div>

 -+++>?vvvvvv?????????vvvv?|~?vv|~vvvvvvvvvvvvvvvv????????~...~|^+>???>^|^.<br></br>
 |????}rrrrrrrr}}}}r}vvv}}?|>rwv|?wwwwwwwwwwwwwwr}}}rr}}}?`.|^-|+>>??vvvv?`<br></br>
.^???v}}}}}}}vvvvv?????vvv+|v}}?^rwwwwwwwwwwwwwwrvv}rr}v?`.|>?`--~|++>??vv-<br></br>
.^v}}}}}}}vv??????????>>vv>?v}v`>rrrrwwwwwwwwwwwr?v}}v}v^~v}}?``~^^+>>>?vv`<br></br>
.+v}}}}}}vvvvvvvvvvvv??v}}rr}r?-vv?v}rwwwwwwwwrv?|?vvvv?|vrrr?+^^^+++??vv?`<br></br>
.+?vvvvvvvvvvvvv?????v}rrrrrr}|^vv?vv}r}}}}}rw}v>+>>???vvv}}v>++++++^>????-<br></br>
.?vvvvvvvvvvvv???vvv}rr}}vvv??-?v???vvvvvvvv}rw?|+??>|^+???v?++++^^^|>???v-<br></br>
`vvvvvvvvvv??>?v}}}}vv??????>||?>>?v}}vv}}}}}vw>^+?????>????++^++^^+>>?vvv-<br></br>
`vvvvvvvvvvv?>v}vvv}vvvvv??????>+>?v?>?vvv??v?vv^^+???????????>^^+>??vvvvv-<br></br>
`vvvvvvvvvvvv??}}}}rvvvvvvvvv?>+++??>+????v?>vvv+|^+?????????vvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvv>?vvv?+^^^>vv???vvv}v``vv>-^^^>?????????vvvvvvvvvvvv`<br></br>
.??????????????vvvvvvvvvv?+^+++?vv??>??+rww^v>^|^^+^^>?vvv??>+>???v?????>+.<br></br>
.???????????????????vvv?>>>>>>>????>v?`.rwr}`.?>|-|+^^+???????>+^+>>?>^|~-.<br></br>
.+>>????>>>>??????????????+^>>+>|+>>?>?>>?v?^?v>`-~|^^^^+>???????>>+|-````.<br></br>
.|^+>>>>>>>+++++>>????vv?+^++^^^ .-}v?v^.`v}v?>?+|^~|+++^^^++>>>+^|`.`````.<br></br>
 .-~|^+++++++++^^^^+>>++^++^++?^.~?vv??>^+v?>?vvv|^||~~|^^~``..`|^^^+>>|-`.<br></br>
 .`````-|^+++++++++++^++++^^^+^>>+>???+>?>>?vvv??+^+^-..````.`~+??????^-~|.<br></br>
 .`````````-~|^^^^^^^^+^+>??>>>?>++>>>?>>???vvvv?>^+>+~-````~>??????+~~>v}-<br></br>
.||~-````...``|>>>++^|^^?>>??v????+++>+>v}rr}vvvv?+?vv>+^|`-+?vvv?v?>?vrww~<br></br>
.^^^|~---``````-+????>^|>???vvrwrrrr}}rzxuuxrv????>?v?++>+|+?}wwwrr}}rwwww~<br></br>
.|||~~~~~~|~||-``->??v?>??>^>xzxxuxxxxxxxxxzr???vv??v??}v>?rzzzzwwwwrrwrrr~<br></br>
.||||~~~~-~~||~~~``^?}wr}?^-?r}zxxuuuuuxzxxwwwwrr}vv}wwv?vzzxzzzzwr}r}}vvv-<br></br>
.|^+?vvv?+|~~~~~~`.`+?}wwwvvzv}uxzzzzzzzzzzzzzzzwwwwwzwrrzxzxzwr}vvvvvvvvv-<br></br>
.~|+vwzzzw}v?++++>>??v?vrvwzwzxxzzwrrwzwrwzzzzzzzzzww}}r}vvvvvvvvvvvvv????`<br></br>
.~~~|>vrwrvrzzwwwwwzwwr}}vrvwzzzzzwwrwzwvvzwwwwr}v??vvvvvvvvvvvvvvvv??vvvv`<br></br>
.~~~~~~^>?vwwzxxxzzzxxzzzww}wzzzzwwrvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`}vv????vvv}rrwzwrrwzzwwwwr}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
`xxzzzzzzzzxxzzzrrrr}vvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????`<br></br>
`zxzxxxzzzwwrr}}vv?????vvvvvvvvvvv??????vvvvvvvvvvvvvvvvv?????????????????`<br></br>
`}r}}}}}}}}vvvvvvvvvvvvvvvvvvvvvvv?????????????????vv?????????????????????`<br></br>
`v??vvvvvvv}vvvvvvvvvvvvvvvvvv?????>>>>>??????????????????????????????????`<br></br>
`vvv}vvvvvvvvvvvvvvvvvvvvvv??>>>>>>>>????????????????????????????????>????`<br></br>


            </div>,

            <div>

 `||||~?vvvv????????????vv?` -vvvvvvvvvvvvvvvvvvv???+???>+|-- .~|>????????`<br></br>
.|++++^vvvvvvvvv???vvvvv??>. ^}wwwwwwwwwwwwwwwwww?v>?vvv??>^. -+?????vvvvv`<br></br>
.+^|^>???vv???????+^+>?vv>- `??vwwwwwwwwwwwwwwwwr?|^vvv>+^^- `~|+>>+^+??vv`<br></br>
.^+?vvvv??>>^+>>>??>+^^+?>~-^????vvrwwwwwwwwwwwww}~?vvvvvvvv??^|^?vv?????v-<br></br>
.vvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv??}wwwwwwwwwwww>~?vvvvvvvvvvvv??vvvvvvvv`<br></br>
`vvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvv?vrwwwwwwwwww}|-|+??vvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??vwwwwwwwwwww?~~|||^^+>?vvvvvvv????vvvv`<br></br>
`vvvvvv??????????????vvvvv??>+^|||?vwwwwwwwwrr}v|^+^^>???>>>?vvvvv??????vv-<br></br>
`vvvvvvvvvvvvv???>>?vvvv???>???>^+?vv???????????+|++^|>vvvvv?vv???>^+??vvv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>++>?????????vvv?^^^^>>^>vvvvvvvvvvv?vvvvvv-<br></br>
`vvvvv??vv?vvvvvvv??vvvvvvv?>^^|^?vvvvvvvvvvvv}v>|^^+??+?vvvvvvvv?vvvvvvvv-<br></br>
`vvvvvvvvv???????vvvvvvvv?+^+++^>vvvvvvvvvvvv?>v?+^^^+?vvvvv?>>>>????????>.<br></br>
.vvvvvvvvvvvvvv??>>>?vv?>>??>+^^vvv?vvv?v}rw? .??~`^^^+>>>>>???++^^^+++^||.<br></br>
.+>??vv???????vvvvvvvvv?v??+^^|+>+?v>>>??+vxv^?>-.`~^^++^^^+>>^||^^^~~~|++.<br></br>
.-^++>>????>^^+++>?vv????+^^^^|>- .?}??v. ?z}?~->+||~+++++^^|~~-~~~--|++++.<br></br>
.-|^||^++++++++^^^^+>>++^^^^^+?v~`>???>??+??||?vv>||||~~|^|-```-|?????vv>+.<br></br>
.~^++^|~||^++++++++^^+++^+++^+>>????vvv??>^^?vvv??^^+^`---|^|~|>vwzwwwv>++.<br></br>
.~^+++|^+|--~~|^^^^^^^++++>vv??+|^>?vvv?^^?vvvvv??^^+>^~~~|^^>vwxzwwv>~|?v`<br></br>
.~|++^~^^|~~~^^>???>+|||>v>vvvv???>|~||~+vv??vvvv?^>vv+~~~~~>}wxzwrv+^?vww~<br></br>
.~~^+|~~~~~~|+^|>}wwrv>|+vvv?v}vv????>>?vrwzw??vv?v}v>`~|^+?rwzzrrrvvvrzzz~<br></br>
.~~^^~~~~~~~|+|~~|?rzzwv??v>~?zzxxxw}rrwzzxuu?|v}r}?>+^?}wwzzzzwwwzzzwzzrr~<br></br>
.|^>+^~~~~~~|^~~~~~|vzzzr}?~-?wzzxxxxxxxzzxwr}rww}??vrzw}}rwzzzxxxxzzrvvvv-<br></br>
.?rwwrrv?^~~~|~~~~~-~}wzzzrvrwzzzzzwwwwzzxxxxxxzrrrwzxxxrrzwwzwwrvvvvvvvvv`<br></br>
.|>vrzzzzw}?>^~|^>???}wwwr?wzzzzzzzrrrwwvrzxxxxzzzzwr}vvv??vvvvvvvvvv??>>>`<br></br>
.~~~^?rw}rxxzw}}rrwwwwrrrv?rzzzxxxzrrrwwvvrrrr}}vvv????vvvvvvvvvv????????v`<br></br>
.-~~-~^>v}}rwzxxxxwzxxxzwwwwwwzwwrr}???????vvvvvvvvvvvvvvv?????vvvvvvvvvvv-<br></br>
.???>???rwr}rrwxzrvwzwrvvvv???vvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???v-<br></br>
                  `zzzzzzwwwzxxzzzrvvvv???vvvvvvvvvv??+????vv?????vvvvvvvvvvvvvvvvvv????????`<br></br>
`wzzzxxzzwrr}vvvvvvvvvvvvvvvvv?????>+>>>????????vvvvv?????????????????????`<br></br>
                  `vvvvvvvvvvvvvv?vvvvvvvvvvvvvv???????????vvvvvvv??????????????????????????`<br></br>
                  `vv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????????????????????????????>>>>>>.<br></br>


            </div>,

            <div>

 .```-`+>+>>>>+^+++++^^++>>~ .~?vvvvvv???????????>>>^>>>>+~-| .~-^>>>>+++>`<br></br>
 -~~~~->>>>??>>>>+>>>>>??vv+..?wwwwwwwwwwr}vvvvvv??>>????>+>` -^>>>>>?????`<br></br>
 --`~|^+>>>>++^^^^~``+v}r}v-.`vrwwwwwwwwwwwwrvvvv>^|??>+^|^` `~~|++^^^+>>?`<br></br>
.-~|+>>>>+^~-~~~|||>?vvvvv?~.>r}}}rrwwwwwwwwwwrv??-~+++++>--+^-``^>?>>>>>?`<br></br>
.+++++^|||~~|+++++?rr}}}}}}>|}}}}}}}rwwwwwwwwwwwv^.`|+++>~~+++++^|^>????>?`<br></br>
.++++++++^|~^++++?r}}}}rrrv^?wr}rrrr}wwwwwwwwwwww+``-||+~|++++++++^^^>??>?`<br></br>
.^^^^^^++++^^+++?rrr}}}}}}?^rrr}rwrrrzwwwwwwwwww}?|--`-``~|^+^|~~~~~-~^+>>`<br></br>
.^|~-~~~||||||||vvvv?vvvvv+?vvvvvvv}wwwwwwwwwr}}>>>>>|||||~-~~-~||~|~||^>>`<br></br>
.>^||~~~~|^^^+^+??????????^????vvvv}}}}}}}v}v??v?+++|-?v??+^~~||~~~~|>????`<br></br>
`vv?>^~~~|^+>>?vvv??????v??vvvv?vvvvvv}}}}}???vv?>>^|+^>vv?^^||^>????????v-<br></br>
`vvvvv??????vvvvvvvvvvvvvvvv??>?vv}}}}}r}}v????vv?>>?????vv?^^>???>????vvv-<br></br>
`vvvvvvvvvvvv???vvvvvvvvvv?>>>^?vr}}}}}}}rv???>????????vvvvv?>>????vvvvvv?`<br></br>
.?vvvvvvvvvvvvvv?????vvv?????>+?}rr}}rr}rr???.`??>+??????????v??????????>+.<br></br>
 ^??vvvvvvvvvvvvvvvvvvvvvv?>^^++>^?v?vvv}????~??>^+>?>>???????????>>>>>+^+`<br></br>
 ~???>>????????????vvv?v??+^+^+?|..>v??v~.^v?>^+???>^+++>>???>>+>>|~||^^++`<br></br>
 .-~~|+++++++>>>>>++>>>>>+^^^+?}^->v?v???>>?|~>???>?>>++>?>+|~~~|>~??vvv>+`<br></br>
 ..````-|||~|^^++>+^^^++^++^|>?~>??+v?^??>^^>????>^^++|````-`.`~++>vvv?>^^`<br></br>
 ..``-`-|~-`--```~~|^^++++>???>+++>^v??>~^>>||>?>>||^^~`....`-+??>???^``|+`<br></br>
 .`-||~~|~~-~^||~|~``~||^???vv?>??>^|+>|~>^--~|++~``~|~-~`..~>?v???>|-|>??`<br></br>
.-~|^|~~~~~-~^^~|++^^>+^^vvv??vv?>??>??>>>+++~-|~`~||``~~|+?}vv???????????`<br></br>
.~~||~~~~~~-~^|~~~|^+???>vv?+vwwwzzz}v}vv?+>>^.-|^^-..~>vwwwrrr}}}rwwvvvvv`<br></br>
.~~~~~~~~~~~~|~~~~~~^?v???>++vwxxuuz}rzrv>>>^^^^+^--|?rzrr}wzzzzzzzwrr}vvv`<br></br>
.|+^^^^^|~~~~|~~~~~~^v}}v??vzzxxzzxzrwwr}}?>>???>>v}wzxzr}wwwwrr}vvvvvvv??`<br></br>
.~|^^++>+^^^||~~~~||+vrwr}?vvvrwvvrrwwwwvv?vrwwrrrwrr}vvvvvvvvvvv?????????`<br></br>
.~~~~|^+^^+>++^^^^^^>vrwwr}wwwwzzzzwwwwwvv}rr}}}vvv???vvvvvvvvvv??vvv?????`<br></br>
.~~~~~||||^^^++>>>^+?}wwzwzwwwwrrr}}vv????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.^^||||^^^^^^^+++^|+?vvvvv??????????>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.~-`-~|^+^+++++++^++>>>???vvvvvvvv??>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
 ````~>vv?????vvvvvv}}}vvvvvvvvvvv?????????vvvvvvvvvvvvvvvv???????????????`<br></br>
.-~|>??vv}}}}}vvvvvvvvvvvvvvvvvvvvvv???vvvvvv???vvvvv??????????>??????????`<br></br>
                  .vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vv????????????????????????`<br></br>
`vvvvvvvvvvvvv???????????????????????????????????????????????????????>>>>>`<br></br>


            </div>,

            <div>

 ..........................-~` ~vvvvvv?>^-``````-----------~-. .~|~~~~~~~~.<br></br>
 ```````````````.......`^?}wv`.vwwwwwwwzzrv>|-~~~|||||||||~~` .|^^^|||||||.<br></br>
 ````````````````-~~~^?rzwww> |wwwwwwwwwwwwzw?^~~|||||~~~~~` .~~~|||||||||.<br></br>
.~----``````.-|^++++vwzwwww}-.vwwwwwwwwwwwwwwww?------~|^~..~^||~-~~~~~~~|.<br></br>
.++++++|~``-~+++++>vwwwwwww?.+wwwwwwwwwwwwwwwwwzv+^^^+++^`.|++++++|~-~~~~~.<br></br>
.+++++++++^~|+++++}wwwwwwww^.rxwwzxxxzwwwwwwwwwwwr>++++^.`^++++++++^~`--~~.<br></br>
.^^^^^^+++++^++++vwwwwwrrrv`>zzwwxuuuuuuuuxxzwwwrwv+^^^``+++++|~~~~~~-``--.<br></br>
.~~~--~|^+++++++>}vvvvvvvv+-vrrwwwwwwzxxuuuuxw}v??>??+``^+++^~--~~||~-~|||.<br></br>
.^|~~~~~|^+++++^>?vv?>>>>>~^???}wwwwwwwwwwwzwvvvv?>+^-^v?>^^|||^|~--~+>???`<br></br>
`vv?|~~---|^+^>?vv?>+^^>??>?vvvv}wwwwwwwwww}>^?vvvv>-~^>vv?|||~`-|^^+>????`<br></br>
`vvvvv????>?vvvvvvvvvvvvvvvvvv??rwwwwwwwwww?^+>?vvvv?vv?vvv?|``|^+^^+>??vv-<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv??>+>rwwwwwwwwwr>|>???vvvvvvvvvvvv?>???vvvvvvvv-<br></br>
.?vvvvvvvvvvvvvvv?????vvvv???>+?rwwwwwwwww}+^+?vvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.|?vvvvvvvvvvvvvvv?????vvvv?+^^>>??vv}}rrw}+^^>vvvvv?v??vvvvvvvvvvv?vvvvvv-<br></br>
.`>v??>>>>>??vvvvvvvvvvvvv?+^^>v??v???>>?vv+|^+?vvvv?>>>???vvvvvvv?^vvvvvv-<br></br>
..`++++++++++>????>++>>>??>^^^?v??vv?vv???v>||^+>>??v?vvvvvvvvvvv?^^vvvvvv-<br></br>
.`.|++>++^|~~|^^+>>+^^++++^|~>v^~??+>?||>+^+>||++^^^+>????>+++>>>-.^>>???v`<br></br>
.^..^^|-`---~^~---|^|^^++++>?^|^^>>^+?^+-->^-``~^^|||^^||||~|||||``^^+++++.<br></br>
.|`.`---~~~-~|~~~~||-`|^^+??vv>|+??+++??~~`-|~~~|~-------``^++++++^^^^^^^^.<br></br>
 `-~~~~~~~~`-~~~~~|~~`^^^?vvv???+^++>?v>~-.~~~~||~~~~~-~~~^?vv>^++>>??+^||.<br></br>
.~~~~~~~~~---~~~~~~~-`^++?v??rwr}v}ww???^~`~~-~~~~-``-~|?}ww}??vvv}rrv???>.<br></br>
.~~~-~~~~----~~~~~~~~`~^^+^^vzzxyyyxv>}}?`-~~-~~~~---|?wzwrrwzzzwwr}vvvvvv-<br></br>
.-~~~~~~-----~~~~~~~>vv?>|~>zxxxzzzzr}r}??+--~||~|+vrwzwwrrwwr}vvvvvvvv???`<br></br>
.~~~~~~~~---~~~~--~~~>vwrv???>?vv??}wzwrw}++?v}vvv}}}vvvvvvvvvvv??>>>?????`<br></br>
.~~~~~~~~~~~~~~~~~~--^?rwwzww}}wwwrzzzzw}vvvvvvvvv??vvvvvvvvvvv????vvvvvvv`<br></br>
.~~~~~~~~~~~~~~~~~--~+?rwwwwzwwr}}vvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.-------~~~~~~~~----~+?v}v??>>????>>>>????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
                  ..`-~~~~--~~~~~^^+????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
      .|?>++????vvvv}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????>++++++>`<br></br>
 ..`~>vvv}}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvv?v?????>++++>>>??????`<br></br>
.vv}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vv?????>>?????????????????`<br></br>
`vvvvvvvvvvvv????>??????????????????????????????>>>???????????????????????`<br></br>


            </div>,

            <div>

 ..........................`~-..^??????>^-``````-----------~~~ .~|~~~~~~~~.<br></br>
 `````..........``..`..`~>?vv~ |vvvvv}}}}}?>|-~~||||||||~~~|^..~||^^^^^^||.<br></br>
 `````..........``-~-~+vv}}v>`.>vvvvvvvvv}}}}?^~~|||||||~~|^..~~~|||||||||.<br></br>
 --````......`-~||||>v}}vvvv^.^?vvvvvvvvvvvvv}}?~-----~~~~|.`|~~~-~~||||~|.<br></br>
.^^|||~-`...`~||||~?}vvvvv}?`~?vvvvvvvv}vvvvvv}}v^|~~|^^^~.-^|||^|~--~~~~~.<br></br>
.^^|||||^|~``~~||~?vvvvvvvv>`?}wrwzwwr}}vvvvvvvv}v+|||^|`.~^||||||||-`--~~.<br></br>
.~~~|^^+++++^^^|~>vvvvv??v}?+vwwwzxxxxuxw}}}}v}vvvv^|||..|^^||~``````````-.<br></br>
.~~|||^^^+++++++|?>>>>>?v}v^?vwwwwwwwwzzxxr}}v?>>>+>?+.`~|||~-`..`-----~||.<br></br>
.|||^^|||^+++++^^++>>^?vvv?^+vv}wwwwwwwwwwwvv>++>+|~~`->>+|--`---```~^+>??`<br></br>
.+^^||~~~~|^^^^+>>+~`>??vv???}}v}wwwwwwwwwwr+`|>>>>~.``~^>>~`-```~^^^+>>>?`<br></br>
.+++>+++^^^^+^^+>>>+>}}}}}}?v}}vrwwwwwwwwww}>~~|>>^++>>+>>>>|``~||||^+>>??`<br></br>
.?????????>+++^^+>>>?}vvvvv>vvv?}wwwwwwwwww?+^?>~+>>>>>+++++>+^+>>????????`<br></br>
`??vv??vvvv?++++^|||?vvv????????rwwwwwwwwr}>>^>>>??>>>++^^^^+>????????????`<br></br>
`>?vvvvvvvvv?++++^+???vv?????+>?vvv}}rrr}v}?>+|>vvv>^^||+???>>>>???>>?????`<br></br>
`^?v??????????vvv?vvvvvvvv??>^>}v}}}vvv?>>??>>>?vvvv>^^+>>>???>>???|>?????`<br></br>
`^~>+????>?????vv??????????>+|>vvvv}}v}?>???>+>>???vvvvv?v????vvvv?-??????`<br></br>
.+`^+????+^>++>>>???>>++>>>^~|?+~??>>v>^+^^>?++>>>>>??????????>>>>~+>++>?v-<br></br>
.>|`|>>+|-.``-```-||^^^^++^^>^~^+>?>^?^^~-++~``^>>++>>++++>>>>>>+|~>>>>???`<br></br>
.>+`.--```...--`--~|~`-^^|????>>+>?^|+??-``-~---~~`.`..---~>>?????>>>?????`<br></br>
.----``..````~~~~~|||-`^^>v?v?>?+|+^^^+~..`|~~~~|~`......`->vvv?????vv??>?`<br></br>
 .......`----~~~~~~~~``^^>v??}r}}vvvv^~|```|~-~~~-`....`|+?vvvv}}vrww}??v?`<br></br>
 `.````--~---~~~~~~~~-`~^|+^vzxxuzw}?~^+^`~|~--~~~-```^?vvv}wzzzwwr}vvvvvv-<br></br>
.~~-`.......`-~~~~~~|+++>|-|vwwwrv}v?^>>???~-~|^|~~^+>??vv}rrr}vvvvvvvvv??`<br></br>
 `............`----~~|^>??>>+++??>++??v}ww?+?vrrv????vvvvvvvvvvvv?????????`<br></br>
 ..............-`.`---|+???vv??????vrwwwr}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 -``-`....-~`.`^-`-~|^+>>???????>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??`<br></br>
.~|~||~-`.`||--|~~||^>?vv?>>^^+??????????vvvvvvvvvvvvvvvv??????vvvvvvvvv??`<br></br>
                  `..``--~~~~||||^^^^^^+??vvvvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvvv???`<br></br>
 .....`^??>+>???vvvvv}}}vvvvvvvvvvvvvvvvvvvvvvvv???vvvvvv?vv???????>>????>`<br></br>
 ``-~+?vvvv}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvv?????????>>>?????????`<br></br>
.vvv}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????v?v???????????????????????`<br></br>
`vvvvvvvvvvvv?????????v?????????>>??????????????>>????????????????????????`<br></br>


            </div>,

            <div>

                  .....................`````````` .`----------~~~---~~~~~||||||- `^^^^^^^^^.<br></br>
 ..........```````````--------|`.-~~|||||||||^^^||||^^^^^^^^+~.-+>>>>>>>^^.<br></br>
                  .........```````````---------~.`~~|||||||||||^^^^^^^^^^^^^^`.~+^^^^^^^^||.<br></br>
                  ........````````````--------~`.~~~|||||||||||^^^^^^^^^^||~..|^^^^^^^^^^^^.<br></br>
                  .........`````````---------~~..~~~~~~|||||||||||||||||||-.`^^|^|||^^^^^^|.<br></br>
                  ......``````````````--~~~~~|-`vvvvvv?+~~~~~||||||||||||-.-^^|||||||||||||.<br></br>
 ...-~|^++++^|-```-|^++++>vw}`?zwwwwwzzr?^||~-~~~~~~~~~..-~~|~~~||||||||||.<br></br>
 `-^+++++++++++^|~^++++>vwww?-rwwwwwwwwwxw?++^~`-~~~-`..-~~~~~~~~~~|||||||.<br></br>
.^+++++++++++++++++++^?wwwwr|>wwwwwwwwwwwwz?^++|~||~..-~---~~~~~~~~~~~~|||.<br></br>
.|^^++^^^^|||^+++++++>rwwwwv~}wwwwwwwwwwwwwz?+++++^``^>++|--~~~~~~~~~~~~||.<br></br>
 -~|~-~~^|^^^||^++++^vwwwww>?wwwwxuuxzzzzwwwz>+++|.-+++++++~`---~~~~~~~~~~.<br></br>
.^+^^^++++|~|^^|^++|^r}vvv?^vrwwwwzzzzxxxzz}v?>>-.~++++^||||``-----------~.<br></br>
`?????>????>-|+^~|^-|vvv?>||??rwwwwwwwwwwrv???+-`>+^+^||~~~~~```-`-------~.<br></br>
`vvvvvvv???v>-~|~-~+vvvv>||?v?vwwwwwwwww}^^vv?|~^vv>^^^~~^+^|~`.```-------.<br></br>
`vvv?vvvvvvv??????vvvvvvvvvv?+>wwwwwwwwr+~+?vvvvvvvv>^|^>^-|+|--~~-``````-.<br></br>
.?v?+vvvv??vvvvvvvvvvv????vv?^^?v}}rrww}^~>vv??vvvvvvvvv??>>>???vv?|`````-.<br></br>
`vv?|vvvv?^vvvv????vv??>???>^~>???>>>}wr>~+?v????vvvvvvvvvvvvv????vv?|`->v-<br></br>
`???~?vvvv~|?>++||||^++^^^^|`|~^?>>>??+|^||^^+???????v??vvvvvvv?>>>??v?vww~<br></br>
.?vv>^vvv?|.`~~-----|~`-^|+>+||>>-|~`^>+|||^~~~~---|^-`?vvvv?vvvvv?v?rzzwr-<br></br>
.????|+>>+|``-~~~--~||``^+????^+++>|`-~--~^+|~~~--`~^~|+>>+|>v}}v}}rwzzwr}~<br></br>
.^++++|~~--~~~~~~~--~~-`^+??v}}vvv>|``~~-~-~~-~~~-``|+++^``|?}wwrwzzzwr}vv-<br></br>
.++++-``-~~~~~~~~~~~-~-`|||+}xuuz?+^``~~-~+?^---~~--`-|^^^>vwzzwwrr}v???vv-<br></br>
`}v?|.  .....`~~~~~~---.~^~|>?>??+^-``--+vw?~-|+?+-`````~?}}}}vvv?vvv?vvvv-<br></br>
                  .^`           ..-~~--~~-`~~~~~||~~-`-~^?rzr??vrww?|^+???vvv??????vvvvvvvvv`<br></br>
          .    -`..`---~```~|||~```~>vvrrr}}}}vvvvv}vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.^~-^~.   -?|  ??`^vv>~~~-```-`..`~>???vvvvvvvvvvvvvvvvvvvvv???????vvvvvvv`<br></br>
.?}}r}v>~..?r?^v}}zzww}vv?+^~-~^>??v?vvvvvvvvvvvvvvvvv????>>>>???vvvvvvvvv`<br></br>
`wwrwzxxwv??}zrrrrvvv????vvvvvvvvvvvvvvvvvvvvvvvv??>>>>???vvvvvvvvvvvv???v`<br></br>
`zzzzzzwrvv???vvvvvvvvv}}vvvvvvvvvvvvv????vvv????>??vvvvvv????????????????`<br></br>
`}vvvvvvv?vvvvvvvvvvvvv???????????vvvvvvvvvv??vvvvvvv?????????????????????`<br></br>
`vvvvvv}vvvvvvvvvvvv??????v?vvvvvvvvvvvvvvvvv?????????????????????????????`<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????????????????????????????????????`<br></br>


            </div>,

            <div>

                  ..........````````````````````|..`--~~~|||||||^^^^^^^^^^++++^|..||~~~~~~~.<br></br>
 ..````````````----------~~-~--^.`|||^^^++++++++>>>>>>>>>>>+++`-+++^^^||||.<br></br>
 ...``````````-----------~~~~-|`.~^|^^^^^^+++++++++++++++>++|.~+++^^||~~||.<br></br>
                  ...````````------------~~~~~~|.`~|||^^^^^^^^^^++^^+++++++^`.|++^^^^||~~~|.<br></br>
                  ...````````-----------~---~~|~.`~~~~~||^^^^^^^^^^^^^^^^|~.`|^^^^^^^||~~~~.<br></br>
 ..```````````---------~~~--~|`>vvvv??+|~~||||||||^^^^|~-.`^^||^^^^|||~~~~.<br></br>
 ...`-~|^^^^|~~````~|^^^^+?v}|^}rrrrrrwr?|~~~--~||||||~`.~||||||||||||~~~~.<br></br>
 .`~^^^^^^^^^^^^~`~^+^^|?}rrv~?}}}}}}}}rww?^^^|`-~~~-~`.~|||||||||||||||||.<br></br>
 ~^^^^^^^^^^^^^^^^^^^^^vrr}}>^}}}}}}}}}rrrwv|^^|-~~~-``--~~|||||||||||||||.<br></br>
.~|||||||~~~-|+++++++|vr}}}v|?}}}}}}}}rrr}rwv~^^^^|-`|^^^~--~~~~~~||||||~~.<br></br>
 `-~-`-~~~^^^||^+++++?}}}v}?^v}r}rwzwwwww}}}w?|^^|``|^^^^^^~-~~~~~~~~~~~~~.<br></br>
.|^^++^+^^|||^^|^++^~?v????|>v}}}}}}rwzzwrr}??+>~`-^^^^|~~~~``-~~~~~~~~~-~.<br></br>
.>>>???>++^+~|++^^^|->??>>+^??rwwwr}}v}}}}???>+|`+>|^^~------```---~~~~~--.<br></br>
.????vvv>|^>>~~^|~~|?????>>??vvrwwwwr}vvv|~>??|-~?v?||~--~^^|~`.```-------.<br></br>
.vvv??vv??>>>>+>+>??????vvvv???rwwwwwrv}+.-|????????>|~~>+-~^^--~~~-------.<br></br>
.>?v+?vv?>+??vvv???>>>+>?????++v}}rrrrr}|`~>?>>>?????vv??>+>>>?????^`````-.<br></br>
.>?v+vvv>?+?vvvv??>>>>>?????+~?vvv????r}?~||>>^^^+>????????????>>>???|``^?-<br></br>
.>>?>?}v?v>~???>+^^^+>?>>++^-~+^?????>>^++^|~~^>+^^^>??+???????>>+^+??>?v}-<br></br>
.+>??+}vv?>``^|-`-``~|~-|||+++^>?^^^`~>>^~|^|-```..`~~`+????>>??????>?v}vv`<br></br>
.+>??>>?>>^~``----``~~~.-|>?>?+|^^^^.`~-`-~||----``-~--|^^^~|???????vvvv??`<br></br>
.|^>>>>^~-``----``..``-`~^?vvvv??>^-.`~~-----``-````~|^||`.-+?vv?vvvvv????`<br></br>
.^^+>^---~~---`.......`.`|~>?wzwr>|``.-~--~+^```---```-|||^>?vvvv????????v-<br></br>
`v?>^`....`..`..........^?+-~|~||~`.....-+?>-``^?>``.`.``+?????v??vvvvvvvv-<br></br>
.^`    . ..    .......`||>^-`..........`^?>~|+>vv?~~^>??vvvv??????vvvvvvvv-<br></br>
         .     .`  ...`~~~-```--```-+>???>>>???v?vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ~~`^+~.  .|-  -+.`+?>||~~`..````-~>??vvvv??vvvvvvvvvvvvvvvvv??????vvvvvvv`<br></br>
 |>??v}v~. `++`|v?vvvvvvv?>|~-~|>???v?vvvvvvvvvvvvvvvv??????>>???vvvvvvvvv`<br></br>
`vv??v}wv^~-|?v}rv??>>>????vvvvvvvvvvvvvvvvvvvvvvv???>???vvvvvvvvvv???????`<br></br>
`rrrrrrrv>++>?vvvvvvvvvvvvvvvvvvvvvvvvv???vvv????????vvvvv????????????????`<br></br>
                  `vvvvv?vv???vvvvvvvvvvv???????????vvvvvvvvvv???vvvvvv?????????????????????`<br></br>
                  `vvvvvvvvvvvvvvvvvvvv?????v?v???vvvvvvvvvvvvv?????????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????????????????????????>>????????`<br></br>


            </div>,

            <div>

                  ````````````------~~~~~~~|||^^^^``+^+++++++++++^^^||~~~-----``-.......... <br></br>
.```---------~~~~~~||||^^^^++++++.~?????????????>>>+^|||~~~--``........... <br></br>
 ```---------~~~~~|||||^^^^^^+++|.+>>>>>>>>>>>>>>>>+^||~~---`..```........ <br></br>
 ``----------~~~~~~|||||^^^^^+++`->>>>>>>>>>>>>>>>>+^^|~--``.`````........ <br></br>
 ````---------~~~~~||||||^^^^^^|.|>>>>>>>>>>>>>>>>>++^^|-`..``````........ <br></br>
                  ````---------~~~~~|||||||^^^^^-`^+++++++++++++++++++^^~```````````....... <br></br>
                  ```---------~~~~~~||||||||||^^`-^^^^^^^^+++++++^^^^^|``-~~-```````....... <br></br>
                  ``---------~~~~~~||||||||||||~`|^^^^^^^^^^^^^^^^^^^~``~|~~---``````...... <br></br>
 ``--------~~-~~~~~~~~~~~~~~~|-`|||||||>?+^|^^^^^^|-`~|||~~~~---`````````. <br></br>
 ``-----~~---~|^^+^^^~--~----~`-|~~|||>}v+|||||||~--|||||~~~~~-----```````.<br></br>
 ```---~~-~^++++++++++^~~|^^^-`---~~~>}r?||~~||~-`~||||||~~~~~~~~~~-------.<br></br>
 ```+?^~-|++++++++++++++++++^-+>+|~~|>vv>~~~~~-`-~||||~~~~~~~~~~~~~~~~~~~~.<br></br>
 ```|vv+-^^^++++++++++++++?}?>wwwrv?+++^~-~--``-~~~~~~~~~~~~~~~~~~~~~|||||.<br></br>
 -~``>}v|-~|^^^+++++++++>}ww?vwwwwwwr>++^---~~~~`-~~~~-----~~~~~~~||||||^^.<br></br>
 ^v?~-?r>~~-````-|+^^^^^vr}?|?rwwzwwwr>^^^^+++++|-`-~--------~~~~~||||||^^.<br></br>
 .>r}>??~`.~^|>?+-~|~~-~?v?^^?vrwzzwzz?+~-^+++++++|`----------~~~~~~~|||^^.<br></br>
 ..?ww}?`.^??>?vv?---~^?vvvv?>vwwwwww>+???>^+>+^^|~``````````------~~~||^^.<br></br>
 ..`vzzw>+?vvvvvvv???vvvv??>||?v}www}|>v??v?||~~-~~`.`````````````---~~~~|.<br></br>
 ...|}zrvv?+>???vvvv>^^^||^|`^?>>vv?|`|+>??v?>||+~....``..``````````----~~.<br></br>
 ..-~?}v??+^vvv??v?^`.`-``+>+^~``~--~~~~~-~>?vvvv?>^`..............``````-.<br></br>
 ..^}}}v?>|`^>??+~.   .`.~>?v?^-.`-||~||~-`.|?v?+>?v?`................``~~.<br></br>
 ...>rw}vv?>|+|`.        `|>??^``---`.`-~~-`.~+>????v^............`-|>????`<br></br>
 ....^v}rrzzz?.        `>ww^... ...     .`--`.->?v?++|.``---`--|>?vv}vvvvv-<br></br>
 .....-vr}w}~         `vwww~                   .-~`-|>?vvvv???vv}vvvvvvvvv-<br></br>
 ...|>~~?}>.          `?rr}~...`^>>>+|||-. .-|^>>>??vvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ...-v}??+        ....^vv}v`...~vv?v?v}vv???v??vvv??vvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....`?r>.      `>+-..|vvvv~`-~|+>???vv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 .....`>`     -+v?~..`~>>?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????`<br></br>
.-~~-````..`|>vvv????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????????`<br></br>
.>>??>>?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????????`<br></br>
                  .?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv??????????????????????????????`<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????????????????????????????`<br></br>


            </div>,

            <div>

 ````------~~~~~||||||^^^^^++++++|`>>+++++^^^||~--``...........`` ........ <br></br>
.`--~~~~~~||||^^^+++++++>>>?????>||???????>>>>+^|~~-``........-........... <br></br>
 `---~~~~~||||^^^^+++++++>>>>??>>->?>>??>>>>>>+^^|~-``......``............ <br></br>
 ``---~~~~~|||||^^^^++++++>>>>>>^->+>???>>>>>>+^^^|~-``....`.............. <br></br>
 ``-----~~~~~~||||^^^^++++++>>>>~|++>>>>>>>+++++^^||~-``.``............... <br></br>
 ``-----~~~~~~|||||^^^^^+++++>>+-++>>>>>>>+++^^^^||~~~~-`................. <br></br>
                  ``-----~~~~~~|||||^^^^^^^^++++|~^^++++++++++^^^||~~~~~```................ <br></br>
                  ``------~~~~||||||||^^^^^^^^^^-|^^^^++^+++^^^^|||~~~-``````.............. <br></br>
                  ``--~~~~~~~~~~~~~~~~~~|||||||~`||^^^^^+??^^^^^^||~-``--`````............. <br></br>
 `-~~~~~~~~--~|^^+^^^|~------~--||||||+v}>|||||||~---~~~---``````......... <br></br>
                  `-~~|~~~~-|+++++++++++^~|^^^|`---~~~^vrv^||||||~-~||~~~~~---`````````````.<br></br>
 ``~|?>~-~++++++++++++++++++>~^>>^~~|^?v>~~~~~---~|||~~~~~~~~~~~------~~~~.<br></br>
 ```~?}?-^^^^+++++++++++++?v}^rwww}?>++^~----``-~|~|~~~~~~~~~~~~||||||||||.<br></br>
 -~``|v}^-~|^^^^+++++++++vzzv?wwwwwwr?+++~--~~~~`-~~~~~~~~~|||||^^^^^+++^^.<br></br>
 ~??+`+}?~~-````-~^^|^^|?r}v+?rwwzwwrw?^^^^^++++^~--~~--~~~~||||^^^^++++^^.<br></br>
 .~vr?+>|``~^^+?+--|~--`???+|vvrwzzwrzv>|-|+++++++|`----~~~~||||^^^^++++++.<br></br>
 ..|}r}?~.^???>vv?|-~~|>vvvv??>rwwrrw?^????^^++^|~~``----~~~~~|||^^^++++++.<br></br>
 ...+rww?^??vvvvvvv??vvvvv??+-+vv}rrw^>vv?vv+|~~---``````----~~~||^^^^++++.<br></br>
 ...`?wzvv?^>????vvv?~^|||^^-~>+^>??^`~^+>?vv?^|+^``..```````---~~~|||^^++.<br></br>
 ..`-^vv???~?vv?>v>|`..``.^>^|~`.--`~~~~~~-+?vvv???+-.....```````---~~~|||.<br></br>
 ..`?vvv?>+`^>??+-.   ....^>??|-..``-`-~~-`.->vv++>??-..........``````-~||.<br></br>
 ...~?}r}vv?+>^`.       .`^>??^`...``...`-``.`^+??>>?>............`-|>????`<br></br>
 ....-?}rrwwzv`        `+rxv`.....      ..```.`|???>|^````----~|>?vvvvvvvv-<br></br>
 .....-?rrr}+.        .vwwx?.                  .`-``|+>vvvv???vv}vvvvvvvvv-<br></br>
 ...-?+|?v?`         ..?rr}> . .~++>+^|^~. .`~^+>>???vvvvvvvvvvvvvvvvvvvvv-<br></br>
 ....>rv>^.        ...`v?}}|...`?}?vv?v}v?>?vv?vvv???vvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....`?}>.      `++-..`}}vv^``-~+????vv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ......+|     `+vv^...->>??>???vvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvv???????`<br></br>
                  .``..```-..`~+?vv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????`<br></br>
.>>>+++>?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????????????????`<br></br>
                  .?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvv??????????????????????????????`<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????????????????????????????`<br></br>


            </div>,

            <div>

                  .~|||^^^^+++++++++++++++++++||||~~-``........................... .-`  .... <br></br>
.^++++>>>>?????????????????>+++^^||~`...........................``........ <br></br>
.^^+++++>>>>>??????????????>>+++^^|~..``......................``.  ....... <br></br>
.|^^^^+++>>>>>>>???????????>>+++^^|`.--`.....................-`. ......... <br></br>
.|||^^^^++++>>>>>>?????????>>+++++-.-~-``..................-`. ........... <br></br>
.||||||^^^+++>>>>>>>????????>>+++~`-~~~-```..............``. ............. <br></br>
.~|~||||^^^+++++>>>>>>>>>>>>>>++^`-|~~~--``............................... <br></br>
.`-~|||||^^^^+++++>+>>>>>>>>>>>+--||~---`````.........`................... <br></br>
 `-~||||||^^|||||^^^+^^^^^^+++>|`|^|~~--``````............................ <br></br>
                  .-~~~|||||~~|^++++^|~~|^^^^|||~`-|||||~~-``````...`....................... <br></br>
.-~~||||~~^++++++++++++++++>??|^>^^|~~~~--```````......................... <br></br>
.~~||||~-++++++++++++++++?}wwvvww}?++^~~||||~``````````.........``````````.<br></br>
                  .~||||~-~||^++++++++++++vwwwvvwwwww?++++++++^||~---``````````------~~~~~||.<br></br>
.~~~~~~-`-|||||^++^+++^?wwwr?wwzzzw}++++++^^^+++~-~~~~~~~~~|||||^^^^^^^^^^.<br></br>
.----~~`--```-~`-|~~|~`+r}}?}wwwwwwr++++^^^++^||~`~|||^|^^^^^+++^+++++++^^.<br></br>
.-----````^>>???~`````~>v?^^vwwwwww?|++|~|~---````~||^^^^^++++++++++++++++.<br></br>
.``````..>vvvvvv?>>>??vvvv??>???vv>-+?|>?>|+??^`-``|||^^^^+++++++++>>>>+++.<br></br>
 ````~?>>?????vvvvv????vv?>||~``-``.|??vvv?vv???v?||||^^^^+++++++++>>>>+++.<br></br>
 ....-?vvv>>?v?>v?>`...-^|++>^`..````||||~+vv?>????|~||||^^^++++++++++++++.<br></br>
 ....`>>>>|^???^+|.       -+|`.````..``--``|>>vv>^?+-~~~~~||^^^^+++++++++^.<br></br>
 .....|?vv>~^>?|`       .``.           ......-|~..`````---~~~|||||^^++>>++.<br></br>
 .....`?}wxxzv~.      `?ww}}}?^`.         .............`````--~|^+??vvvvvv-<br></br>
 ..`-~>}rrwr>.      ~vzxxwwzxxz}|           ...........`--~+??vvv}}vvvvvvv-<br></br>
 ..^v}}rwzv`     .|vxuxzrrzv+-.              ......`~^>??vvvvvvvvvvvvvvvvv-<br></br>
 ...->vrw?.      `^~`~^}w}>.    .`~+>>??+`...`-^>???vvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ..-^+?}^             `?rr>.. `>}wzzwr}}?+|+>?v}vvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ..`?rw?       .`|-...|}rrv`...~>?????????vvvvvvvvvvvvvvvvvv????????vvv???`<br></br>
 ...~vr}~    ->?}}>-..~vv?>^+>???vvvvvvvvvvvvvvvvvvvvvvvv?>>>???vvvv??????`<br></br>
 ....-vrwv>?v}vvv?>>??vv?vvv}}}vvvvvvvvvvvvvvvvvvvvvvvv??>???v????????????`<br></br>
.~-```-^?vvv??>?vv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????????????????`<br></br>
.>?>>>?vvvvvvvvvvvvvvvvvvvvvvvv?>>?vvvvv?????>>>>>>>>?????????????????????`<br></br>
`vvv}vvvvv????????vvvvvvvvvv???>>>????>>++^|~-|+>>????????????????????????`<br></br>


            </div>,

            <div>

.+>>>>>>?????>>>++++++^^~-```..........              ........... .`~. .... <br></br>
.>????????????????????>>^|~-```.......  .........................-`....... <br></br>
.>>???????????????????>>+^~-```................................--. ....... <br></br>
.+>>>>?????????????????>>+|~-``............................ .`-. ......... <br></br>
.+++>>>>>???????????????>>+^~-``......................... .`-.  .......... <br></br>
.^^+++>>>>>?????????????>>>+^~-```.......................`-`  ............ <br></br>
.~|^^++++>>>>???????????>>>>>^|-``......................-`. .............. <br></br>
.~|^^^^++++>>>>>???????>>>>>>>+^~................... .``. ................ <br></br>
                  .~|^^^^^^+++^^^^^^^++++^^^^++++^`-~`................``. .................. <br></br>
                  .~|||^^^^^|~|^^+++^||~|^^^^^|||-`||--``............`. .................... <br></br>
.~~~|||||~|++++++++++++++++>??^|>+^||-``.........`........................ <br></br>
.~~|||||-|>+++++++++++++^>vrwr?rwwv+++|-~||~~`.`.......................... <br></br>
.~||||~~-~|^++++++++++++?rwww>}wwww}+++++++++^|~.......`````````````--``--.<br></br>
                  .~~~~~~~``~|||||++^^++^^wwww?vwwzzwz?++++++^|^++^````---~~~~~~~|||||||||||.<br></br>
.--~~~~-`-````~-`~~~~|-`vr}v?wwwwwwzv^+++^|^^^|||-~|||^^^^^^^^^^++++++++++.<br></br>
.--~~~-``.~>+>??+`````~^?v>~?}wwwww}^+>^|||-`-````~+^^++++++++++++++++++++.<br></br>
.``----`.^??vvvv?>+>??vvvv?>>+???v?|-?+^v?^^??>~`--++++++++++++++++>>>>+++.<br></br>
 `````+?+?????v?vvv????vv?>|~|-`~```-??vvv??v?????++++++>>>>>>>>>>>>>>>>++.<br></br>
 `````^vvv?^??v+vv?~...`|||^>^~..````~||~~~?vv^>?>v>++++>>>>>>>>>>>>>>>+++.<br></br>
 ....`^?>>>~???>^+~.      .|^-..```...``-`.~>>?v?|??^++++++++++>>>>>>++++^.<br></br>
 .....`?vv?^^>>>-.       `-`.          ......`||-.`~~|^^^+++++++++++++>>++.<br></br>
 .....`>vrzxxw>.      .+rzr}}v>~.         .......````---~~~||^^^+>??vvvvvv-<br></br>
 ...`-^vrrrwv-      `?wxxzwwxuxw?.           ........``--~~^??vvv}}vvvvvvv-<br></br>
 ..-?v}}rww+      `?zuxxwrzw?|`              ......`-^+>??vvvvvvvvvvvvvvvv-<br></br>
 ...`^?}w}~      `^|`~|?z}v-.    .-^>>??>~....`~+>???vvvvvvvvvvvvvvvvvvvvv-<br></br>
 ..`|^>v?.             >rrv`...^vrzzwr}}v>|^>?v}}vvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ...^}wr`       `~~`...vrrr^...`+?????????vvvvvvvvvvvvvvvvvvv????????vv???`<br></br>
 ....?rw?.   `+?vr?|...?v??>^>>??vvvvvvvvvvvvvvvvvvvvvvvv??>>???vvvv??????`<br></br>
 .....?}wr?>?}}vvv>>>??v??vv}}}vvvvvvvvvvvvvvvvvvvvvvvv??>???v????????????`<br></br>
 .....`|>vvv??>>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????`<br></br>
.++^+>??vvvvvvvvvvvvvvvvvvvvvvvv>>?vvvvv??????>>>>>>>>????????????????????`<br></br>
`vv}}}vvvv????????vvvvvvvvvv????>>????>>++^^|-~^>>>???????????????????????`<br></br>


            </div>,

            <div>

.+>>>>++++^||~-``.......................  ..                         ..`.  <br></br>
.?v??????>>+^||-```..................................................``... <br></br>
.?vv?????>>>+^|~-``...............................................``.. ... <br></br>
.?v????????>++^|-``...................... ......................``........ <br></br>
.??????????>>+^^|~-`.................... ........................ ........ <br></br>
.??????????>>>+^^|~-``................. .................................. <br></br>
.???????????>>+++^|~-``................................................... <br></br>
.>???????????>>>>+^^|-``.................................................. <br></br>
.>>>>???????>+^^^^^|~~~~--```````..       ................................ <br></br>
.+>>>>>>>>^^^^+++^|~^++++>???v?>^~-`-~~~-`.........  ..................... <br></br>
.+++++++^|^+++++++++++++?}wwwwwv>>++++++++^`.............................. <br></br>
.|^^^^^^|++++++++++++++>rwwwwwvvww?+++++++^~... .......................... <br></br>
.~||||^~-~|^++++++++++^vrwwwr?vwzz}+++++^|~`.............................. <br></br>
.~~||||-`~|~~-~|^^||^|~?}wwrv}rwww?+++^|~````..........``````````````````` <br></br>
.-~~~~~`-````-||-~~~---`+vv?}wwrr?|^+|-|+>>>??>|-------~~~~~~~~~~~~~~~~~~~.<br></br>
.--~~--``|>?????+```-~+>?+|+??>+|~|++^>??vv?????>^^^^^^^^^^^^^^^^^++++++^^.<br></br>
.---~--`^?vvvvvvv???vvvvv?>+|...`~^^?vv??vv>?>^>>++++++++++++++++++>>>>>++.<br></br>
.------->v?+>??vvv?>^>????|`...`.`^???+|~|~|^|~|^++>>>>>>>>>>>>>>>>>>>>>++.<br></br>
.-------+??>?v?>>>+~.  ..     ....`~||||^|||^^++++>>>>>>>>>>>>>>>>>>>>>>++.<br></br>
.```````~|^~^+>+^`                 .`-~|^^^^++++>>>>>>>>>>>>>>>>>>>>>>>>+^.<br></br>
 ````--`-+?vv}?-      `^?vv?^-.      ..-~|||^^+++++++>++++>>>>>>++++^++^+?`<br></br>
 ...`>vv?vrz}|     .+vrxxxzzzwv>`       .`--~|||^^+++++++++++++++>??????vv-<br></br>
 ....`^vwzz?.    .+rxxxxxwzzzzxxr^        ..``---~~||||||^^+>?vvvv}vvvvvvv-<br></br>
 ......`>?|    .^vwzxzzzzxxzxz?|.      ..........```-|+>??vvvvvvvvvvvvvvvv-<br></br>
 .........     ->?}wzzwwzwwv>`    .~+???~......`-|>??vvvvvvvvvvvvv????vvvv-<br></br>
 ....~>?`          .``-+v}?~    `>}zzw}??+|^>??vvvvvvvvvvvvv??>>???vvvvvv?`<br></br>
 .....|?~.  ......     `?}?`...-?}r}vv???vvvvvvvvvvvvvvvv??>??vvvvvvv??>>>`<br></br>
 ..`|>?vrrrrwwwrr}v?~..~?v}>~~^>??vvvvvvvvvvvvvvvvvvvvvv???vvvvvvvv???????`<br></br>
 ...~+?}rwwwzzzzw}v>^~|>?vvvv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????`<br></br>
 ... .+v}}vvvv??>???vv}vvvvvvv??vvvv????>>>>>>>???????????????????????????`<br></br>
.```-~+?vvvv???????vvvvvvvvvvv???>>>>>>>????????vv????????????????????????`<br></br>
`vvvvvvvvvvvvv?????vvvvvvvv??>>>>>>???????????????????????????????????????`<br></br>


            </div>,

            <div>

.>>+^^|-``.........  ...................... `. ......................  .-- <br></br>
.???>>+|-```...............................`. ..................... ..--.. <br></br>
.????>+^~-``..............................`. .................... .```. .. <br></br>
.????>>+|~``.............................`.......................```. .... <br></br>
.?????>>^|-``..................................................``.. ...... <br></br>
.??????>+^|-``...............................................`.. ......... <br></br>
.???????>>+~-``............................................... ........... <br></br>
.????????>>+|-```.......    ..    ........................................ <br></br>
.??????????>>|~````...``--``..````...  .   ............................... <br></br>
.>?????????+^^^++++|-^+++>+>?vv??+-~`-~~~~`. ............................. <br></br>
.>>>??????^^++++++++++++++vwwwww}+?>+++++>++-....... ..................... <br></br>
.+++>>>>>^+++++++++++++++}wwwwwv?rwv+++++++^|. ........................... <br></br>
.^^^^^^^|-~~^++++++++++^?wwwwwv?zzww>++++^|~`............................. <br></br>
.~|||||^|`-|~---~^^||^||+rwww??wwww}+++^|^~-`-`........................... <br></br>
.~~||||||-~.```||-~~~-`-`>vv?vwwrrv^^+^-|+??>??>~```````````````````````.. <br></br>
.-~~~~~~-`|?????v>```-~>>?>~^??>+^-^+>^>??vv????v>~||||||~||||||||||||~~~-.<br></br>
.---~~~~--?v?vvvvv???vvvvv?+>|`..``?+>vv?+?v+??^>>^++++++++++++++++>>>>+^^.<br></br>
.--------^vv^>?v?vv?>+^>?v?^`...-``^???+||^^-+^-|++>>>>>>>>>>>>>>>>>>>>>+>.<br></br>
.-------`^??^?vv+>+>^.  .`.    ...`|^+++>>+^^^++>>>>>>>>>>>>>>>>>>>>>>>>>>.<br></br>
.``---```~|^~|+>++~.                `|+>+>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++.<br></br>
.`````-~-`^?vv}v^.     .^vvv?>-.      .~+>+>>>>>>>>>>>>>>>>>>>>>>++++++>^+`<br></br>
 `````+vv?vrzw>.    .^}rzxxzwxw}?~.     .-^+++++++++++++++++++++++>>?????v-<br></br>
 `.....^vwzz}~     ^ruxxxxwwzzwxxz?.       -^^^^^^^++++^^^^++???vvvvvvvvvv-<br></br>
 .......`^v>.   .^}zzzxwzxxxzzxv^.      ....`---~~~~||^+>??vv}}vvvvvvvvvvv-<br></br>
 ..........     `?v}rzzrrzww}?-    .~>???^`...```~^>??vvvvvvvvvvvv??????vv-<br></br>
 .....->?|          .``->vr}|    `?}zzwrv?+~|>??vv}}vvvvvvvvv??>>???vvvvvv`<br></br>
 ......|v>.  .......     ?rv`. .`vrr}vv????vv}vvvvvvvvvvvv?>>??vvvvvvvv?>>.<br></br>
 ....^??vrr}rwwz}r}v?|`..v}}v-~^+??vvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvv?????`<br></br>
 ....~+?}rwwwzzzzwr}?|||+vvvvv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????`<br></br>
 .... .|v}}}vvvv?>>??vvvvvvvvvv??vvvv????>>>>>>?????vvv???????????????????`<br></br>
 .````~^?vvvv???????vvvvvvvvvvvv??>>>>>>?????v???vv???????????????????????`<br></br>
.?vvvv}}vvvvvvv?????vvvvvvvv???>>>>>??????????????????????????????????????`<br></br>


            </div>,

            <div>

                  ..................  .......................... .`. ...................... <br></br>
                  ..............................................``.......................~~.<br></br>
                  .............................................`. ....................`~-.. <br></br>
                  ...........................................``. ...................`-`. .. <br></br>
                  `.........................................`. ...................-`....... <br></br>
                  .``......................................``. .................``.. ....... <br></br>
                  .-``....................................`. .................``............ <br></br>
                  .~~```............ ......   .... .........................`.. ............ <br></br>
.^^~-``...........`|^^^^|-~+>>+~`....`.. ................................. <br></br>
.>>+|~-`..-|^++++^++++++>}wwwwwr?^^~-^+|`................................. <br></br>
                  .++++^|-`|++++++++++++++rwwwwwzzz?`~++++~. ............................... <br></br>
.++++++|~++++++++++++++^wwwwwwzw?>^+++^|~-`............................... <br></br>
.+++++^|`~|^++++^^^^||^-?wwwwwv>vv++^~~~|^~.   ........................... <br></br>
.++++^^|`|-```-~|~~~`~|`|?}rr>>v?~~|`+vv>>^~-............................. <br></br>
.^^^^^^~``|+>?>|`--```-```|+-~^-`--``?v?+>>>^?^........................... <br></br>
.|||||||.|????v?+^^^+????~.. .``..|???>>v??v+~^`.......................... <br></br>
.~~|||||+?v???vvvv?vvvvv?|~`-....`?^>?>^^|+>-----~~~~~~~~~~---`````....... <br></br>
.-~~~~~~>?v>?vv++?>``|>?>+~.--~|~->??+|^^^^^+++++++++++++++++^^^~~~-````.. <br></br>
.----~^+~~^~|>+>}}|    .`.   .`~^^^+++++++>>+>>>>>>>>>>>>>>>++++++^|~~-``` <br></br>
.---`^}r}?^-`>vv^       ..      .`|++++>>>>>>>>>>>>>>>>>>>>>>>>>+++++^^|--.<br></br>
.````->}wwr?+?^     .|^vrv|```     .~+++>>>>>>>>>>>>>>>>>>>>>>>>>++++^^+>?-<br></br>
 ``````~?rzz+     `?zz}rxzv>>r}+`    .|+>>>>>>>>+++>>>>>>>>+++++++>>???v}}-<br></br>
 ```````|?v~    .+rxxxzrzxzr}zwvv?.    ~+++++++++++++++^^^+>>???v?vvv?vvvv-<br></br>
 ...`--+v?.    |wxrwxzzzzxzrwzww}+.   .~|^^^^^^^^|||||^+>?vvv???v?vvvvvvvv-<br></br>
 ..`>}}}}~    .+vwxxxxwzxwrrzxr>.   ..```-------`~^>?vvvvv????vvvvvvvvvvvv`<br></br>
 ...`|?rv.       .`^?vvv}r}wv^.  -+^>?>`..`-|+>??vv}vvvvvvvvvvvvvvvv????>?`<br></br>
 ..``-^vw}v?+~-`..     .`v}>.  .?wr}}v?|^>?v}vvvvvvvvvvvvvvvvvvv??>>+>??vv-<br></br>
 ..^?}wwzzzzzxwrw}v?^....vr+...-?v????v?????>>??vvvvvvvvvvvvv???????vvvv??`<br></br>
                  ...^vrrrrwwwzzzxwwr?|``-?v???????????????vvvvvvvvvvvvvvvvv????vvv????????`<br></br>
 ...|vwrrr}}}vvvv??>>>??vvvvvv????vvvvvvvvvvvvvvvvvvvvv?????v?????????????`<br></br>
                  ...|vvvvvvvvvvvvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????`<br></br>
.^+?vvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????????????????>>.<br></br>


            </div>,

            <div>

                  ..................  .......................... .`` ...................... <br></br>
                  ...............................................`.......................-~.<br></br>
                  ..............................................`......................-~`. <br></br>
                  ............................................`. ...................`-`.... <br></br>
                  ..........................................`.....................--`...... <br></br>
                  .........................................`. ..................``......... <br></br>
                  .......................................`...................``.. ......... <br></br>
                  .................. .....   .... ......`..................`............... <br></br>
 ..................~^^^^^~~^>>>|`...`.`. ................................. <br></br>
                  .........`~^++++^^++++++vrwwwwwv+^^-~+^-................................. <br></br>
                  .`.......-+++++++++++++^vwwwwwwzzr--^+++^` ............................... <br></br>
.~`......^+++++++++++++|vwwwwwwzv>^++++^|~`............................... <br></br>
.^~``....-|^+++++^^^||^~+rwwwwr>?v^++~~~~^^`    .......................... <br></br>
.+^^~-`..~~````-|~~~--|-~>vrw?>?v|~~-~?v?>+|~`. .......................... <br></br>
.^^^^||~``~+^??+`---``-```~+~-+~`--`.+v?++>>+>?`.......................... <br></br>
.|^^^|||``?v????>^^^+>???+`....`..`>??>>?v?v?-^`.......................... <br></br>
.||||||||?v???vvvv??vvvvv^|```...`+>^??+|^|>|...`......................... <br></br>
.|||||||^?v?>vv>+>?~`~>?>+^.`--~~`^???|^|||||||||||~~~--```.`............. <br></br>
.-~~~|++||||~>>+vr>.   ...   .`-^+|+++++++++++++>>>>>+++^|~--```.......... <br></br>
.----|vwrv+~`^vv>.       .       .~++++++>>>>>>>>>>>>>>>>>++^|~-``......   <br></br>
.````-^vwzwv>>>.    .~|?}r>-``.    .-^++>>>>>>>>>>>>>>>>>>>>>>++|~``...`|>`<br></br>
.``````->}zxv.     ^rxr}zx}?>vw?~.    -+>>>>>>>>>>>>>>>>>>>>+++++++>>??v}}-<br></br>
 ```````~>v+     ~vxxxxrwxzw}wz}vv|    `>>>++++++++++++++^+>>???vv?vv??vvv-<br></br>
 ````--|v}~    `vxwrzzzzwxzrwzwwr?`   .~+++++++^^^||||^^>?vvvv???v?vvvvvvv-<br></br>
 ```+v}}r>     ~?rzxxxwwxzrrwxz?`   .`-~~|||~~-~--|>??vvvv????vvvvvvvvvvvv`<br></br>
 .`.`~>}r`        `|>?vv}r}rr>`  .^^>??|```~|^>??vv}vvvvvvvvvvvvvvvvv???>?`<br></br>
 ..`--~?wrv?>|-`..      .>}v`   +}w}}v?^|>?v}vvvvvvvvvvvvvvvvvvvv??>++???v-<br></br>
 ..~?vrwwzzzzxz}zr}v>`.. >wv....>v???????????>??vvvvvvvvvvvvvv??????vvvv??`<br></br>
 ...-?rrrrwwwwzzxzwrv+-``>v????>??????????vvvvvvvvvvvvvvvvv?????vvv???????`<br></br>
 ...-?wrrr}}}vvvv??>>>??vvvvvv?????vvvvvvvvvvvvvvvvvvvv???????????????????`<br></br>
 ...`?}vvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????`<br></br>
.|+>?vvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????????????????>>.<br></br>


            </div>,

            <div>

                  .................................................`. ................`.... <br></br>
                  ...............................................``................``...... <br></br>
                  ..............................................`................`......... <br></br>
                  ............................................`................`........... <br></br>
                  ..........................................`.............................. <br></br>
                  ....................     ................................................ <br></br>
 ....................~>??^. .............................................. <br></br>
 ...................>wwzzz}`.............................................. <br></br>
 .......... .... ..`}wwwwwz> ............................................. <br></br>
 .........`~^^^|~|^^+v}}rrv+|^++^`........................................ <br></br>
 ........`+>++++>>+^|^~~--|+++>+|-........... ............................ <br></br>
                  ........^+^+++++^+^+?|``-^++^|`... ...... ............................... <br></br>
 ........`~^|~~~~-||-~||~-^+~.`-~^>` ...  ................................ <br></br>
 .........-``~++-`--```-```. ..>vv?>. .................................... <br></br>
                  `.........`????^```--`.``. .. |v?|`...................................... <br></br>
.~-`........>v>???>^+>~.......^??`........................................ <br></br>
.~~--```....`~~>+?>?vv?`  ....|>|......................................... <br></br>
                  .~~|~~~-```....`..  `|`     ..`````````````............................... <br></br>
.~|||||~~~-|>+~-.    `+?~.    .`|||||||||||||~~--````..................... <br></br>
.~~~~~|^^~~^vw}`   .|+vwr??+`   .|+++++++++++++++^|~--```.............   . <br></br>
.----~~?v?>|^v^   `vv?vwzwwzv`   .^+++>>>>>>>>>>>>>++^|~-``......    .`|>?`<br></br>
.``----->vrv>`   -vrzwrrzzwzv.  .^++++++>>>>>>>>>>>>>>>++^~-`....`~^>?v}}}-<br></br>
.```->v?>?vzv    ?zzwwwrzzzw~   >v?++>??>>>>>>>++++>>++^^^^+++>??vv}}}vvv}-<br></br>
 ````~?rwwrwv.   .~vzwwwzzxw.  -r}?vv}rv>++++^^^||||^^>??vvvv???v}vvvvvvvv-<br></br>
 `-~~~~>vrwwzw?|.  .>zxwzwzv   ?rrrwwr?+^^|~~~~~~^>??vv??>?vvvvv???vvvvvvv-<br></br>
 `~vrr}v}r}rwzxxw?`  ~vrrw}`  -zzzwr}>----~^+??vv}}vvv???vvv???>??vvvvvvvv-<br></br>
 ``->v}rrwzzzzrzzxz?`  .~?v.  `?vrr}v>^>??v}}}vvvvvvvvvvvv?>>?vvvvvvvvvvvv`<br></br>
 ....`^v}}rwwzwxzwrrv.  .>}^---~+???????vvvvvvvvvvvvvvv????vvvvvvvvvvvvvvv`<br></br>
 ...`>vrwzzzzxxxxw}}?~~|>?vvvv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vv??`<br></br>
 .`>vrrrrwwwr}vvvvvvv????vvvv?vvvvvvvvvvvvvvvvvvvv??vvv???????????????????`<br></br>
 ..~?vvvv?v????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????????>+||.<br></br>
.+??vvvvvvvvv???vvvvvvvvv???>>>????????????????????????????????????>++^^+>`<br></br>


            </div>,

            <div>

                  ..................................................`..................`... <br></br>
                  ................................................``...........```````..... <br></br>
                  ...............................................`..............`````````.. <br></br>
                  .............................................`...............``...``````` <br></br>
                  ...........................................`........................```.. <br></br>
                  .....................     ...............`............................... <br></br>
 .....................~>??^............................................... <br></br>
 ................... >wwzzzr-............................................. <br></br>
 ........... .... ..`vwwwwwz> ............................................ <br></br>
 ..........`~^^^|-|^^+v}rrrv+|^++^`....................................... <br></br>
 .........`+>++++>>+^|^~~--|+++++|-..........  ........................... <br></br>
                  .........^+^+++++^+^^?|``-^++^|`.`. ...... .............................. <br></br>
 .........`~^|~~~~-||~~|||-^+~..-~^>`.... ................................ <br></br>
 ..........~``~++-`--```-```. ..+vv?>. ................................... <br></br>
                  ...........`????^``---`.``. .. |v?|`..................................... <br></br>
 ............>v>???>^+>~.......^??`....................................... <br></br>
 ............`~->+?>?vv?`  ....|>^........................................ <br></br>
                  .``........... ....  `|`     ............................................. <br></br>
.~~--```....->^-`.    `+?~.     .```````.................................. <br></br>
.~~~~~~~|~--|vw}`   .|+vww??+`   .~^^|||~~-```..........................   <br></br>
.--~~~~~?v?>|^v^   `vv?vwzwwzv`   `++>>>>>>+^|~-```...............    ..|>`<br></br>
.`----~~~>}rv>`   -}rzwrrzzwzv.   ^>+>>>>>>>>>++^|~``......     ..`~+>?v}}-<br></br>
.````~?v???}zv    >zzwwwwzxzw|   >}?>>???>>>>>>>>>+^|~-`...`-~^+??vv}}}vv}-<br></br>
 ```.`|?rwwww}.   .~vzwwwzzxz.  -r}?vvrr?>+++^^^^^||~~|+??vvvvv??v}vvvvvvv-<br></br>
 ``-||--+vrwzzw?^.  .>zxwzww}   ?rrwww}?^||~---~~~^>??vvv?>?vvvvv???vvvvvv-<br></br>
 .`~vr}vv}r}rrzxxw?-  -vrrw}-  -zzzwr}>-```~|+??vv}}vv???vvvv???>??vvvvvvv-<br></br>
 ..`->v}rrwzzzzrzzxz?`  .-?v.  `?vrr}v>^>??v}}}vvvvvvvvvvvv?>>?vvvvvvvvvvv`<br></br>
 .....`^v}}rwwzwzzwrrv.  .>}+---~+?v??????vvvvvvvvvvvvvv????vvvvvvvvvvvvvv`<br></br>
 ....`>vrwzzzzxxxxz}}?~~|>?vvvv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????vv?`<br></br>
 ..`>vrrrrwwwr}vvvvvvv????vvvv?vvvvvvvvvvvvvvvvvvvv??vvvvv????????????????`<br></br>
 ...~?vvvv?v????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????>+^.<br></br>
.|>??vvvvvvvvv???vvvvvvvvv??>>>>????????????????????????????????????>++^+>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  .........................................................``````.......... <br></br>
                  ...............................................`.....````````.`````````.. <br></br>
                  ....................................................````````````````````` <br></br>
                  ....................................................````..```````````````.<br></br>
                  ..................    ............................`.....`````````````````.<br></br>
 ................ `^>>~....................................```````````````.<br></br>
 ............... -}wwww>......................................````````````.<br></br>
 ...........     ~}wrrw?.............................................````` <br></br>
                  ..........`-~~-`~^+|~^|-~||`...........................................`` <br></br>
 .........-+++++++|`-.`++++>>-............................................ <br></br>
                  .........|^+++^|+^~``-|+--`-............................................. <br></br>
                  ..........~|``~`~|~.`-`-.^~~............................................. <br></br>
                  ..........`.|+?|.`-`.-` .^?+............................................. <br></br>
                  ........... ~v?v?|`..`.  |v-............................................. <br></br>
 ............ `~>???>^. . +>` ............................................ <br></br>
 ..................~?>.   .`.............................................. <br></br>
                  ................            ............................................. <br></br>
                  ................  .~...    .............................................. <br></br>
                  .-```````-----~~.  ^v?~^?-  .`.....`...................................... <br></br>
.~~||~~~|?vv?>>^  .?rrvvrr^  -^+?^~|~--```........................   ..~^>`<br></br>
.~~||^^^^>}rrw}>  ~wrwrrrrv  `?vv>>>>++^|~-``..............    ...-~+?v}}}-<br></br>
.--~~|^^^+?}rrrv  .}zwzrrw?  -}}?>>>>>>>>+^|~-`.......   ...`~|>?vv}}}vvvv-<br></br>
 `````-|>>>?}}v}+  .vxzzzz>  ~w}v??vv?>>>>+++^|~-`...`~^>??vvvvvvvvvvvvvvv-<br></br>
 `-||~-~?}r}rrr}w?. .?xzzx?  `r}rrw}?++^|||~~~--~^>?????vvvvvvvvvvvvvvvvvv-<br></br>
 .~vrr}v?vrwzzzzzx}.  ^wrz?  .v}rwrv>~-``-~|>??vvvv??vv???vvvvvvvvvvvvvvvv-<br></br>
 ..~?}zzzzwwwzwzzxx}.  -?}>   `.-?rv>|^+??v}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....~?}rwzzzxzzzwrr> ...?v^```~>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....~vrrrzzxxxxxzr}v~-~|?vv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvv???`<br></br>
 ..-^?}rrrwr}}}vvv?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????>>+>>`<br></br>
  `?v}v?vv???vvvv?vvvvvvvv????????vvvvvvvvvvvvvvvvvvv????????????>>>+>>??>`<br></br>
.^>?vvvvvvvvvvvvvvvvv??>??????????????????????????????????????>++>????????`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  .................................................`....................... <br></br>
                  ...............................................`......................... <br></br>
                  .............................................`````````................... <br></br>
                  ...........................................`.```````````................. <br></br>
                  ..................    ......................`````````.``````````````..... <br></br>
 ..................|>?^....................````````...``````````````````.. <br></br>
                  .................vwwwzv...................````.....`````````````````````. <br></br>
 ............... .vwwrw}..........................````````````````````````.<br></br>
                  ...........-~~-`-|+^~||-~||-.......................``````````````````````.<br></br>
 .........`++++++>+-``.^+++>>|............................````````````````.<br></br>
                  .........-^++++|+^|``-~+~``-`...............................````````````` <br></br>
                  ..........-^-`---|~`.``-.~^~` ................................```````````.<br></br>
 ............-+>>.`--.`-. -?>- .................................``````````.<br></br>
                  ........... `?v??+``.``. `v^....................................`````````.<br></br>
 ............ .`+>??>+`   -?| ...................................`````````.<br></br>
 .............. ...`>?-   .``....................................`````````.<br></br>
                  ................            .....................................```````` <br></br>
                  ................  .|`..    ..........................................``.. <br></br>
                  .........``.....  -r?-`^|   ............................................. <br></br>
.``---~~~^vv?>^|.  >rrv>vr?   .~>^.....................................~^>`<br></br>
.~|^^++++>vwwwwv. .}www}rrr`  +vr?`..........................  ...-~^?v}}}-<br></br>
.|^^+++>++>}rrzz`  >xwzwrw}. .}wr?|~-`...............    ...`-|+??vv}}}vv}-<br></br>
.~~||^++??>?v}}wv.  >zzzwz}  .wzr??vv>|~``......    .`~^>???vvvv}vvvvvvvvv-<br></br>
.`-+>++^?}r}rrrrzw~  |wxzxr.  }zwrr}?+^|~-``..`.`~+??????vvvvvvvvvvvvvvvvv-<br></br>
 .`?rwrv?v}wzzzzzxz|  `}rwr.  ?wrww}?~`..``~^?vvvvv??vv???vvvvvvvvvvvvvvvv-<br></br>
 ..`>vwzzzzwwzwzzxxz~  .?}v.  .`.>r}?||+>?v}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....`+vrwzzzxxzzwwrv....>v?```-^???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
 ....`?}rrwzzxxxxzw}v^`~~>vv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvv???`<br></br>
 ..`|>vrrrrrr}}vvv??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????>>++>.<br></br>
 .`>v}v?vv???vvvvvvvvvvvvv????????vvvvvvvvvvvvvvvvvvvv????????????>>+>>??>`<br></br>
.^>?vvvvvvvvvvvvvvvvvv?>>?????????????????????????????????????>++>????????`<br></br>


            </div>,

            <div>

 ..........................................-^>?????+~`...-^~-.....^??+.... <br></br>
 .........................................`|??vvvv????|`.~`.......v}v-.... <br></br>
 .........................................`~||+>??vvvv?|`........+}+`..... <br></br>
 .........................................-~``.->vvvvv?>+^|^~`..>v~....... <br></br>
 .........................................`...`~^>??vv?vvv??^`.^>`........ <br></br>
 ....................................````...``||-`|>vvvv?>>>~`-^.......... <br></br>
 ..............`-`...`.............```....``````.`|>>???^||~`............. <br></br>
                  .............+rrr?`````................``````````^+^^||~~-`.............. <br></br>
                  .............^?^^?`````..............```..```````---````-```............. <br></br>
                  ..............`-`.````....................```````````````````............ <br></br>
                  .........~++++^`..-^^^~.....................```````````````````.......... <br></br>
                  ........`^^+^|||-`~|---...........................```````````````........ <br></br>
                  ..........~--~-`-``.`~..............................``````````````....... <br></br>
 ............~?>~`.`..>| ...............................``````````````.... <br></br>
 .............->?>~.  `^..................................`````````````... <br></br>
                  ................|?^...`-.................................``````````````.. <br></br>
                  ...............  ``... ..................................````````````````.<br></br>
                  ...............      .    ...............................````````````````.<br></br>
                  ............. .       ..   .................................`````````````.<br></br>
                  ............^^.   ..  .... ....................................``````````.<br></br>
.`````-++||~~?}?-  .^` ..`. ........................................`-^>>?`<br></br>
.||||^^?vv}}vvrrv- `vv` `~~~+~...................................-^>?vvvvv`<br></br>
.>??>>++?v}wzwwr}?  ?zv. |???>|-`..................... ....`-|+??vvv???vvv-<br></br>
.>v}v????vv}wzzzrv. ^zx? .?v???+~-``...........   ..`-|+>??vv}}}vvvvvv}}}}-<br></br>
.~+vrrr}v}}}}wzzzz+  ?xx^ .+??}?>>+^-`.........`~|>?v}vvv}}}vvvvvvvvvvvvvv-<br></br>
 ``+v}rwwwwwrrzzxxr. `}xz~  -~--|||-``.``||^>????vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ..-+?}rwzzzzwzxxzx|  `^?z-    .`-|^^+??v}v}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?`<br></br>
 ..|?v}rrrwwzxxxzwr?    .}v|``-^>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>>?`<br></br>
 -~|?v}rwwwwwzxxzw}v~`-^>?vv??vvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvv??>>????v`<br></br>
 |?vrrwzwzwr}}}}v???vv}}}vvvvvv??vvvvvvvvvvvvv???????vvv???????>++>???????`<br></br>
  .+vvvvvvv??vvvvvvvvvv????????vvvvvvvvvvvv??????vvvvvvv???>>>????????????`<br></br>
.^>??vvvvvvvvvvvvvvvv???vvvvv???vvvvv?????>>???????????>++>>??????????????`<br></br>


            </div>,

            <div>

 .........................................`|>??????+|-...-^~-.... >}v>.... <br></br>
 .........................................->vvvvvvv?v?^`.~`.......rzr~.... <br></br>
 ........................................`~+^+>??vvvvv?^.........>w>`..... <br></br>
 ........................................`-~``.~?vvvvvv>>+++|`..?r~....... <br></br>
                  .............................................`~+??vvvvvvvv?+`.+?-........ <br></br>
                  ............................................`||--^?vvvv????|`-+.......... <br></br>
 ..............`~`................................|>????^||~`............. <br></br>
 .............>wzw?.....```````````..............`|+~-----`............... <br></br>
                  .............+v++?....```````````...```...........`...................... <br></br>
                  ..............`~`.````.````````...````..````````......................... <br></br>
                  .........|++++|-``~+++~..```....```...````````````....................... <br></br>
                  ........`^^+^|||``~^-~-........`....````````````````..................... <br></br>
                  ..........~--|-`-``.-^`.............``````````````````................... <br></br>
 ............|?>|```..?^..................```````````````................. <br></br>
 .............`+?>~. .~>`..................```````````````................ <br></br>
                  ................~?^.. ~|....................````````````````............. <br></br>
                  ...............  ``. .........................````````````````........... <br></br>
                  ...............      ..........................`````````````````......... <br></br>
                  ............. .       .........................``````````````````........ <br></br>
                  .......  . .++`       ..... ....................``````````````````...   . <br></br>
 ......++-`..?wv~  .-.  ... ..........................`````````````..`|>>?`<br></br>
.------>}}}v??}ww| .??.  ..~?+..........................`````..``~+>?vvvv?`<br></br>
.>??>+++?vrzxwrrzv  >z?   -}w?............................`-~^>?vvv?????vv-<br></br>
.?}wrv???v}}wxzzrz` |zz>  |zwv|.....................`~|+>??vv}}}vvv?vv}}}}-<br></br>
.+?}wwzw}}r}rwxxzz>  }xx| .?wrv`.........   ...-~^??v}}}}}}}vvvvvvvvvvvvvv-<br></br>
.^+?v}wzzwwwrrzxxx}  -zxz~  |+`........``|^^>?????vvvv}}vvvvvvvvvvvvvvvvvv-<br></br>
.`~>?v}rwzzzzwzxxzz~  ~+?z`    ..`~|^>?vv}v}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?`<br></br>
 ..>}rwwrrwwzxxxzw}?    .}v~``~^???vvv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>?`<br></br>
.~^+vrrrwwwwwzxxzw}v-`-^>?vv??vvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvv??>>>???v`<br></br>
.+vwwzzzwwwr}}}}v???vv}}}vvvvvv??vvvvvvvvvvvvv??>>>??vvv???????>++>???????`<br></br>
  .+vvvvvvv??vvvvvvvv????????vvvvvvvvvvvvvv??>???vvvvvvv???>>>????????????`<br></br>
.^>??vvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvv?????>>???????????>^^>>??????????????`<br></br>


            </div>,

            <div>

 ..........................................`-||||||~-`.`.....`|~>>~.`````~.<br></br>
 ....................................`````-~^++>>>>++~-`.....?v^^?^.`..`...<br></br>
 ....................................```-|+^+>>???>^||~`....~v??>^~|`..... <br></br>
 ....................................``--|^>??????^~-~-`~?vvvv??vr?+^`.... <br></br>
 ....................................-~--~^>????>>+|^?vv}}vvv??vv???^..... <br></br>
 ...................................`-~--|???????~-+?>||-`~v?>~~```......` <br></br>
                  .......................................`~+??vvv?+|~`.```.?v?`..........``.<br></br>
 .............`^^|-.....................`|?????v??>+||-``^?^.............` <br></br>
 ............`vr}?~...``................`~^>??????????^`~?`............... <br></br>
 ............`>+++...`````````.............`~>???>>??>-.|`................ <br></br>
 .........`---`-`..`-`````````....``........`~+>>^^^|-.................... <br></br>
 ........`^+++^~``~++^`.````....````...``....`-^>^-`...................... <br></br>
                  ........`~||-`-``-~```.......`......```````...````....................... <br></br>
                  ............++```..`|-`..........```````````............................. <br></br>
                  ............-|+|~`..^``............````````````.......................... <br></br>
                  ..............-|^|..`~`................``````````........................ <br></br>
                  ..............  -|...`..................```````````...................... <br></br>
                  ......... ....      ....................`````````````.................... <br></br>
                  ........--...|`      ....................```````````````................. <br></br>
 ........^?>~+v?. ..   .....................``````````````............   . <br></br>
 .....^>^~???v}r^ .^-  ........................```````````..........`-|^>?-<br></br>
 `....^vrvv}rwrr}. ?v. `^.........................`````````.``~^^+>???vvvv-<br></br>
.|^+^^^?}rwwwzzww~ ^wv..+-~+`...........................``~>????????vvvvvv-<br></br>
.>????>?v}wwwzzxx? .vu? .|vr>>>^~.................`-~^>??vvvv}vvvvvvvvvvv}-<br></br>
.+>v}}vvv}}rwwwzzw. ~wx> .>}}wr?~......  ...``~|>?vvv}}}}vvvvv}}vvv????vvv-<br></br>
.>>?vrwwwwwwwwwwwz|  >wz^ .-~`+?~.....-^^>>>??vvvvvvv}vvv}vvvv??????vvvvvv-<br></br>
.?vvv}rwwwzzzzxzww?  .-+v|   `^^|^>???vvvv?vvvvvvvvvvvvvv??????vvvvvvvvvvv`<br></br>
.|+vrrwwwwwzxxxzwr}`   .>v^~~+???vvvvvvvvvvvvvvvvvvvv???>>>??vvvvvvvvv????`<br></br>
.~|>}}}}rwzzzzzzw}}>~|^>??vvvvvvvvvvvvvvvvv?????vv?????vvvvvvvvvvv????????`<br></br>
.?}rrrrr}rr}v?vvvvvvv??v?vvvvvvvvvvvvvv??????vvv????vvvvvvv???????????????`<br></br>
.>??vvvvvvvvvvvvv?????vvvvvvvvvvvvvvvv???vvvv???vvvvvvvv??????????????????`<br></br>
                  .??vvvvvvvvvvvvvvvvvvvvvvv??vvv???????????????????????????????????????????`<br></br>


            </div>,

            <div>

                  ....................................```````````````````.....`+^vv~ ..``~^.<br></br>
 ..................`````````````````````-~~~~~~~^^|~-```.....vx?>r| ..``.`.<br></br>
 ..................````````````````````-|>>+>>>>>>|`.``.....|xwwv^`|`..... <br></br>
 .....................````````````````~|^+>?vvvv?^``....~?wwwzwr}wv?>`.... <br></br>
 .......................`````````````|^^|^>vvvv?>>^`-vr}ww}}rr}}wwr}?.  .. <br></br>
 ..........................`````````-|^|^>vvvvvv?~-+>+-```-}r?|~--`......`.<br></br>
 ...................................````-^??vvvvv>|-. ....vz}-..```````---.<br></br>
 ..............``.......................->?vvvvvv?>^||--`+r>-```````------.<br></br>
 ............-}ww?......................-^>?vvvvvvvvvv?~^}-..````````-----.<br></br>
 ............-v>>?........................``|?vvv??vv?+`+-.`````````-----~.<br></br>
 .........`````~-.``-`.......................~>??>>>>^-.....````````-----~.<br></br>
                  ........-++++^~``~+++`````...................-+?+-``........````````-----.<br></br>
                  ........-^^|~-~-`-~--`````..``.................``.............```````````.<br></br>
 ..........``>+````.^>`..`````........................................````.<br></br>
 ............|^>~-`.`?-.``...```````...................................... <br></br>
 .............`|>>~..->`....``````````.................................... <br></br>
                  ..............  ++...-.....`````````````................................. <br></br>
                  ......... ....      ..........````````````............................... <br></br>
                  ........++` `?|     ...........``````````````............................ <br></br>
 ...... .?z}>+r}` .`  ..........`````````````````....................    . <br></br>
 .....+?|~vww}}w> -v-  ``.........````````````````............ .....`~|^>?-<br></br>
 .....^rwv?rzzrrr .rv. >}`............`````````````.........`-^>>>????vvvv-<br></br>
 ```..`?rzwrrzxwz~ ?z? `}>||..............`````````......`|?v}}v????vv}vv}-<br></br>
.|^+^^|>v}zxwwxxx? `zu? `vwv|>???................`-|^>??vv}}}}v?vv}}vvvv}}-<br></br>
.+>v}vv?v}}wzwwzzr. ?xx+ `}zvrwr>..........`-~|+?vv}}}}}vvvvvv}}vv?????vvv-<br></br>
.>??vrwwwwwwwzwrrw~ .vww| .>~`?r^....`~++>>>??vv}}vv}}vv}}}vvv??>+??vvvvvv-<br></br>
`rrrrrwwwwzzwzxw}r>  `|>r-   ~v?+>??vv}vv???vv}}vvvvvvvv???>>??vvvvvvvvvvv`<br></br>
.>?}rwwwwwwzxxzwrr}.   `v?~~|?vvvvv}}}vvvv}}vvv?vvvvv?>>+++>?vvvvvvvvvvvvv`<br></br>
.~^?}r}vrwzxzzzwr}v>~^>???vvv}vvvvvvvvvvvv??>+>?vv??>??vvvvvvvvvvv??v?????`<br></br>
`}rrrr}v}}}v????vvvv??????vvvvvvvvvvvvv?>>>>?vvvv??vvvvvvvv???????????????`<br></br>
`}}vvvvvvvvvvvvvv?>>??vvvvvvvvvvvvvvv?>>??vvvvvvvvvvvv????????????????????`<br></br>
`vvvvvvvvvvvvvvv???vvvvvvv??vvv?????>>????????????????????????????????????`<br></br>


            </div>,

            <div>

                  ...........................```````````````````---^+.```. ....--^|`...``-~.<br></br>
 .............``````````````````-`------~~~~~~~~|^?>`.-`.....+>~-|`~~.``.`.<br></br>
 .............````````````````````-----~|^+^++++++>~..`.....`>?v?|.`|+`..  <br></br>
 ............`````````````````````----~||^^+????>^~`....`^^~^|?rvv?^-`~`...<br></br>
 .............``````````````````````-~|||^>????>^+|``^^|v}?`^||>}vv?~..... <br></br>
 ...................````````````````-~||^>?????>>-`-~-->~+>~-~`.`~-`..`..`.<br></br>
 .......................````````````-~~~^????????~`.. ^>>~^^-`.........```.<br></br>
 ..............``..........````````~--`~>?vvv??>?+|+>>????}>~``........``-.<br></br>
 ............`???^................`-``-^??vv????vvvvvv???}?>+`.......``--~.<br></br>
 ............>rvv+..................`~>??????>??^~^>?v?^|-``..`````------~.<br></br>
 ............~~||`...................-^>>???>+++>++^??^-`````------------~.<br></br>
 ....``..`~|||--`.~^|-................`~+>>>>>??v?^~>-```````-------------.<br></br>
 ....```.~++^~~-``^|~`..................`|+>>??>>|`~`.````````````------``.<br></br>
 .....``.``-`|-````.~~....................-^>>+^~.......``````````````````.<br></br>
 ...........`>|~``..++`.....................`--`...............```````````.<br></br>
                  ............`~^+|..`~~`..............................................````.<br></br>
                  ...............`+-..`-...``````.......................................... <br></br>
                  ..............  .  ......````````........................................ <br></br>
                  ........``..^?`    .......```````````.................................... <br></br>
 .......`>>|~?w?. .  .-`....```````````................................  . <br></br>
 .....`^??vvv}rr- -|..|^|`...`````````````....................  . ...`-~^?-<br></br>
 .... .|?vvrwwrw> -r^ `v}^........`````````...............  .`~^++>???vvv}-<br></br>
 ..^^~`~^vwrwzwwv..}w~ ^w?+>`.......````````..........``--|>?vvvvv?vvvvvv}-<br></br>
.-~>?????vwwwwxxz~ |xr` +}w}>^~~~......``````.....-^>??vvv}vvvvvvvv}vvvvv}-<br></br>
.+>?vv}}rrrwzwwzx?  vx}. ^rrv>+^-.........``-~|>?v}}}}}}}vvvvv?vvvv???vvvv-<br></br>
.>?vvrwwwwzzzzwwwr. `rz?. `|`.-|`...`-|>>>???vv}}}vv}vvvv?v????????vvvvv?v`<br></br>
`vv}rrwwwwwzzzzw}r^  `~?>.  .~+++>?vvvvv??vvvv}}}vvv??????????vvvvvvvv?vvv`<br></br>
.v}rrrwwwzzzxxzwrrv.   |v>~^>vvvvvv}}vvvv}vvvvvvvvv??????????vvvvvvvvvvvvv`<br></br>
.^?v}r}}wwzxzzwr}vv>^>>??vv}}vvvvvvvvvvv????????vv????vvvvvvvvvvvv??v?????`<br></br>
.+?}rr}}vv}vv??vvvvv???vvvvvvvvvvvvvv???>????vvvv?vvvvvvvvv???????????????`<br></br>
.>??vvvvvvvvvvvvv????vvvvvvvvvvvvv???????vvvvvvvvvvvvv????????????????????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvv??????????????????????????????????????????????>>`<br></br>


            </div>,

            <div>

                  ...................```````````````````````------~??.````  ........-.````` <br></br>
 ..`````.....`````````--------~~~----~~~~~~~||||~|}v`.-`.. ........v?```.^`<br></br>
 .....````````````````---------------~~~~~~~||||~^v^`...... ..|?>^`.?v|`.  <br></br>
 ....````````````````----------------~~~~~~~~|||||^``. ...  . +}??>` `>~`..<br></br>
 ...`````````````````----------------~~~|++|~||~-|-`....>?^ ...^v??+. .`-` <br></br>
 ...``````````````````````----------~|||^>>^|~-`~-`...~}>}r. ....||~.`-```.<br></br>
 ....```````````````````````````--~|++++>???+|~~~.....vxr>~..............`.<br></br>
 .......``````..`````````````````-^>>++>?vvv?+-`|||>??zwr}v?^--.........`-.<br></br>
 ............`~|-`````````````````~^++>?vvvv?~->r?v}rwr}}wwrv-  ......`-~~.<br></br>
 ............?wwr-..............``.`~>vvvvvv?|+-....|rr?~~-`....``-------~.<br></br>
 ............|>+>`..................-+?vvvvvv+~~~|^-vr?~-------~~--------~.<br></br>
 .........`----|-`~|-................-^?vvvvvvvvvv>+}|--~~----------------.<br></br>
 ........|++>^|---+^^-.................`^?vv}}vvv?^+|-------------------`-.<br></br>
                  ```````.`~|~~`-`-`-|`..................`~+?vvv??^------------------------.<br></br>
                  `````````..~?|-``.`?^....................`~^++^~-`````````---------------.<br></br>
                  .`.`````````-+?+`..^+........................```````````````````````````-.<br></br>
                  .........``````+?`..|-.................................``````````````````.<br></br>
                  ...........``.  `..`.``.................................................. <br></br>
 ...........`?>.    ```````............................................... <br></br>
                  .......++`.`vr+  .  `?^`````............................................. <br></br>
 ......`}w}?+}rr. |` ~w?+^```````..............................   ....`~|>`<br></br>
 ....  .^}rwrrrw| ?r` ?rr?`-```````...................      .`-~|+>??vv}}}-<br></br>
 ..?v>~..>rrwwrr? -xr. ?z?>v~```````..............  ..-|^||+????v}v}}vvvv}-<br></br>
 ..>rwrv+?r}}wxzw` vxv..vrr}?|````````........  ..~+?v}}v}}v??vvvvvvvvvvvv-<br></br>
.```+}rwzwwzzwzxu? `wxv .?wvv~...```....`````-|+?v}}}}v}vvvv??>?vvvvvvvvvv-<br></br>
.|>vv}rrrwzxzzwwzr. +wz?  |`.......`~||>>>??v}}}}}vv}vvv?>????vvvvvvvvvv??`<br></br>
.??vrwwwwwwzzzzw}r|  -~r^   .-~|+??v}vv???v}}vv}}}v??+^>?vvvvvvvvvvvv???vv`<br></br>
`rwrrr}rwzzxxxzrrrv.  .??^|>v}vv}}}}vvvv}}vvvvvvv?????vvvvvvvvvvvvvvvvvvvv`<br></br>
.+vr}r}rwzzxzzwrvvv??????vv}}vv}vvvvvvv??>>?vv??vvvvvvvvvvvvvvvvvv??v?????`<br></br>
 .~vr}}}vvvvv??vvvv??vvvvvvvvvvvvvv??>>>>?vvvvvvvvvvvvvvvvv???????????????`<br></br>
 -|>vvvvvvvvvvv?????vvvvvvvvvvvvv??>???vvvvvvvvvvvvvv?????????????????????`<br></br>
`v}}vvvvvvvvvvvvvvvvvvvvvvv?????????v?????????????????????????????????>>>+.<br></br>


            </div>,

            <div>

 ...................``````````````````--~~~~~~~~~|>>.-^+~.   ....`~~`````` <br></br>
 ..````````````````````--------~~-~~~~~~|||^^^||^^?>-++~`.```..`...??~```|.<br></br>
 .....````````````````--`---------~~~~~~|||^^^^|+>?-~.``..-.^+----`.>?|``. <br></br>
 ....````````````````----------------~~~||||||?v^^-.`..... .^>+>^^~`-++~-`.<br></br>
 ...`````````````````----`-----------~~~|^^|?^>+``.``...|^|.``.|>^-~~~`--- <br></br>
 ....``````````````````````---------~~~|^^+^?~``-`....`+-~>+....-|``.-~---.<br></br>
 ....```````````````````````````---~|^|^+>>++``--....`vv?|.`|~...`>` .```-.<br></br>
 .......`````````````````````````-~^^||^>?>?>~..--`~~+zvv?>~`^|..|?-...``-.<br></br>
 ...........`.`-``````````````````-|^||^>???^-`|v?||+^+vvvv+~`.......``--~.<br></br>
                  ............+vv?`........``````----|+??????^`??--. `+^-~~`..``````------~.<br></br>
 ...........`vvv?`...........`~~~~~^>?vv????>+v?>|-`~+~`....``````--------.<br></br>
 ...........`~~|-.```........`--~^>>???>??vv}}}vv}v?^.....````.``````````-.<br></br>
 ........-|^^|-`.-^^~..........`^>????>^+^^>??vv?^~|`....`..``````````````.<br></br>
 ........~^^~--`.~~-`...........-|+>>>>+^++>>?v>|````````````````-````````.<br></br>
 .`........`~^``.`.|>`............`~^>>>??>^|>^--````````````````-````````.<br></br>
 .````......~^^|-..~+-...........`-|^+>>>>^-`-.```````````````````````````.<br></br>
                  ............`~-+|..-~...........`~~-`-~~-`..........`````````````````````.<br></br>
                  ...........`.. `` ....................................................... <br></br>
                  ............~-     ..`................................................... <br></br>
                  .......-~`-~++.    .~v^...................................................<br></br>
 ..```.`++>??v?~ .`  +zv|`........................................`-~|^+>?-<br></br>
 ..`|^||+>??vww?..?^ .vr?|.`........................... ....``~^+>???vvvv}-<br></br>
 ..-+??>+?}}rwwr` ^z^ `}v+||`...................... ..-|++>??vvvvvv}}vvvvv-<br></br>
 ..^?v}}v}rrrwwx> `}w- -v}?+|-`..................`~+?vvvv}}vvvvvvvvvvvvvvv-<br></br>
 `-^v}rrwzzzzwwzr` ^zv` `v?+^````.......`-||^^>>?v}}}vvvvvvvv??vvvvvvvvvvv-<br></br>
.+v}}rwwwwzzzzwzz+ .?w?. .`......``-|^+??v?vv}}}}vvvvv???????vvvvvvvvvvvvv`<br></br>
.|?}wwwwzzwwzxzrrv. .`+?`  .`~^+>???vvvvvvv}vvvvvv??????vvvvvvvvvvvvv????v`<br></br>
.+?}rrrrwwzzxxzwrr^  .-??++?v}}vvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvv????????vv`<br></br>
.^?}rrwwwwzzzw}}vvv?>????vv}vvvvvvvvvv?????vvvvvvvvvvvvvv?????????????????`<br></br>
 `+vrrr}vvvvv??vvvvvvvvvvvvvvvvv?????????vvvvvvvvvvvv?????????????????????`<br></br>
.~^>vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvv????????????????????????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvvv?????????????????????????????????????????????>>`<br></br>


            </div>,

            <div>

 ...................``````````````````--~~~~~--~|^||~^?v>-.  ````|>^--~~~~.<br></br>
 ..````.`````````````````````--~~~~~~~|||^^^^||^+++|>}?^``+^|.````-|>^||||.<br></br>
 .....```````````````````````----~~~~|||^^^^^^^|>?|`^-``.`+-?r`..```~>||||.<br></br>
 ....``````````````````````------~~~~||||||^^|vz+^..```--. .vy>.````?w^|||.<br></br>
 ...```````````````````````--------~~~||||||}+??`..```````~--~-``..`?v^|||.<br></br>
                  ....````````````````````````-------~~~~~|~^w|.`-. ...`.. ~r|..`|^`.`^^|||.<br></br>
 ....```````````````````````````-----~~~~~~~>`.-`. ..|v>+- -}>```~z| `~||~.<br></br>
 .........``````````````````````````-----``|~`.... . |r??>- .?+`.>x^..`--~.<br></br>
 ...................````````````````--````-^```-??....-vv?>`..`````.`----~.<br></br>
 ............-^|`.......```````-~~||||^^~-+`..rw+>. ....||`.`-`````------~.<br></br>
 ...........~www?...........`~+>>>>??vv?--|~->uz}^--.............````````-.<br></br>
 ............^^|-...........`-+>>?vvvv?+~+vvwzw}rzwv` ..........``````````.<br></br>
 ........`~~~~~``~^|`.........`^?vvvvvv++-..`+}}+^~`.. ......`````````````.<br></br>
 ........^++^~~``||~-..........-+?vvvvv?>+|-.|w^...........```````````````.<br></br>
 ........`--~|``.``>|...........`~^>?vvvvv?+-?^...........``````````````.. <br></br>
 ...........^>+-`` +?............-+??vvvvv?+~|..........``````````........ <br></br>
 ............`^>>` .^~...........~>>+^>>>+|-.............................. <br></br>
                  ...............+- ..`............``...................................... <br></br>
                  ..............    ...`................................................... <br></br>
 .........`>|..     .+v^................................................`-.<br></br>
 ..+>|-`..`?rv^. `` .?zv`.................................... ...-|^++>?v}-<br></br>
 ..|rw}v>-~^?zz> ~r~ +zr|..................................`~^>???vvvv}vvv-<br></br>
 ...~vzzwvv}vrzw..}w` ?z>``...........................`-^>?vvvvvvvvvvvvvvv-<br></br>
 ..~|^vwzzwrrrrx^ ?u}..vrv~....................`~^>?????vv}vvvvvvvvvvvvvvv-<br></br>
 `-?wr}}rzxxwwwz} .ww?  ?w^.............`~|++?vv}}}vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?rrrrrwwrwwwwwzx- ~rr>  -.......``~^>??v}}}}}vv????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
 `>rwzzzzzzrwzw}w?  `.?>. .`~|+??????vvvvvv??????vvvvvvvvvvvvv????vvvvv?vv`<br></br>
 .-v}rrrrwzzxzzr}r- ..>?>>vv}}}}vvvvvvvvvvvvvvvvvvvvvvvv?????????v????????`<br></br>
 |+vrwzzzwwww}vvvvv????vv}}vvvvvvv??vvvvvvvvvvvvvvv???????????????????????`<br></br>
 ~>vrwr}vvvv?vvvvvvvvvvvvvv?????????vvvvvvvvvvv???????????????????????????`<br></br>
                  .|+?vvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvv??????????????????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvv????v??????????>?????????????????????????????`<br></br>


            </div>,

            <div>

                  ..........................`````````````--------------|^-`.  .````-`.````` <br></br>
                  ..``................``````````-~~~~~~~~~~~~~~||~~|-|+|-``++~.-|||~~~````` <br></br>
                  .....``..........```````````----~~~~~~|||||||~~^+-.~``+^~+-+~ .``~|^+-``` <br></br>
 .....``.....``````````````------~~~~~~~~||~|~>?~~`-~`~^~` .??~..```+?^-``.<br></br>
 ....``````````````````````--------~~~~~~|~~?|+^|^`|`.````-...+?...`^>|^``.<br></br>
 ....````````````````````````-------~~~~~~~~?~>|?+....``..~?``+>~~`.`~??-`.<br></br>
 ....```````````````````````````-----~~~~~--|->```...~>~-. `v^``.-v- `^?``.<br></br>
 ..........````````````````````````---`-```-`--..... `>|+^~.`?^`.~?~..`^``.<br></br>
 ..................`````````````````````````.~``-|... .?v?>` `^~`..^?..```.<br></br>
 ............-^|`....```````````--~~--~-``-.~-??-|~.....+>~-`..-```>+.`.``.<br></br>
 ...........~www?...........``~|+>>>??>+-``~~|?}r||~ ....`~-`..````..````` <br></br>
 ............^^|~............`-|^>??vv?>|~^+??}wwr+-. ..........`````````` <br></br>
 ........`~~~~~``~^|`..........`|??vvvv?+~~|+?r}??>>~. ..............```.. <br></br>
 ........^++^~~``||~-..........`+?vvvvvv?>+``~v?++^|~`........`........... <br></br>
 ........`--||``.``>|..........`|>??vvvvvv?^->?~`......................... <br></br>
 ...........^>+-`` +?...........`-^??vvvvvv>^^............................ <br></br>
 ............`^>>- .|~...........`~+>????>^-`............................. <br></br>
                  ............. .+~ ..`.............```~-``................................ <br></br>
                  ....`-`...``..    ....................................................... <br></br>
 ....`^+|``|>^`     .`^>`...............................................`-.<br></br>
 ..`-`->>+^+?}v` ``  ~v}~........................................`~^+>??vv-<br></br>
 ..`^++?v?v?vwx> ~r- ~rw+..................................`~|^+>vvvvvvvvv-<br></br>
.~~~|>??v}}wwwzw..rw` ?x?-...........................``-^??vvvvvvvvvvvvvv}-<br></br>
 -^????vvwrrwzzx^ ?u}..vrv^....................`|>????vvv}}vvvvvvvvvvvvvvv-<br></br>
 .`>}wwrwwzwwwzz} .wz?  ?w>`...........```-~^>vvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>vv}rrwwwwwwzzzx~ ~rr>  -.......`-|^>??vv}}vvvv??vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ~vwwwrwwwzzzzzwwv  `.?>. .`~^^>>>?vvvvvv???????vvvvvvvvvvvvvv???vvvvvvvvv`<br></br>
.|?}rrwwwwzxxzzrrr- .`>?>>vv}vvvvvvvvvvvvvvvvvvvvvvvvvvv????????vvvvvvvv??`<br></br>
.>v}rwzzzzwww}vvvvv?vvvvv}}vvvvvvvvvvvvvvvvvvvvv????????????vvvvvvv???????`<br></br>
.>v}rr}}vvvv?vvvvvv}}}}vvvv??????vvvvvvvvvv?????????????vvvv??????????????`<br></br>
.^>?vvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvvv?????????????vvv???????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvv????v??????????>?????????????????????????????`<br></br>


            </div>,

            <div>

                  ..........................``...````````````````.......................... <br></br>
 ....................```````````-----````````````````...`~+>- ~>++^~`..... <br></br>
 ...................``````````````````````````````..```vvv?~.  `-~|^>+`... <br></br>
 .................```````````````````````````````.|^+`^+~-`.>~~..````~^`.. <br></br>
 ...............````````````````````````````````+}^>`.```-`.  ?w...```~^.. <br></br>
 .............```````````````````````````````-}^}}`..`-```-`.~?}-`````vw`. <br></br>
 .............```````````````````````````````>z-```..``.....>-`````..`?}`. <br></br>
 ............````````````````````````````````|>.```. ...||~.~?-..`~-..`>.. <br></br>
 .................````````````````````````...|``.... . ^v?>- ->~`..?w..... <br></br>
 ............-^|`..```````````````--``......+~``.-?- ..`?v?>` .-``-v?..... <br></br>
 ...........-rww>..............`~++^~--~-`.||``vz^?^ ....^?>-.`````....... <br></br>
                  ............^||~...............`~~^??v?^-.-``vxzv`...........``````...... <br></br>
 ........`~~~~~``~^|`............-^?vvv?++??vrwr}}}r?` ................... <br></br>
 ........^++^~~``||~-..........`^??vvvvv??>-``?r}vv?+`  ......`........... <br></br>
 ........`--||`````>|..........~>??vvvvv??>|`>r?`...........`............. <br></br>
 ...........^>+~`` +v..........`--|>??vvvvv>>>`........................... <br></br>
 ............`^>>- .^~.............|>?vv??^--............................. <br></br>
                  .....  ...... .+~ ..`..............`~^^-``............................... <br></br>
                  ....-+-. .~^.     ....................................................... <br></br>
 ....~}r?^.`vv^     ...?|................................................`.<br></br>
 .....~}zr?-^wx- ``  .^r^.........................................~+>>???v-<br></br>
 ......+rzxw}rz? ~}- `vz>.....................................-|>v}vvv??vv-<br></br>
.???^-.`?}rxxwww..wr` ?x?-..........................`-|+>>????vvvvvvvvvvv}-<br></br>
.?}ww}?+>}rwzxxu^ ?ur..v}v>`...................`~+?vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 .`^vzzzwrrrrzzxv .wz? .?r?`...........```-~^>?vvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.+??v}rzzzwwwzxzx~ -}w>  -...`````~|+>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 +rzwr}rwrzxzzzzzv  `.?>...~|||^+>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`vrwrrwwwwzxxzwrrr~ .`>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vv?vv?v??`<br></br>
.vrrrrwzzzzwr}}}vvvvvvvvvvvvvv??vvvvvvvvvvvvvvv???vvvvvvvvvvvvvvvvvv??????`<br></br>
.v}}}r}}vvvvvvvvvvvvvvv????vvvvvvvvv????vv?????vvvvvvvvvvv?v?v????????????`<br></br>
                  .+?vvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvv????vvvvvvvvvv?????????????????????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????>>>>>????????>.<br></br>


            </div>,

            <div>

                  ........................`.``...```..```...........``-`..   .....`........ <br></br>
                  ..................````````````````````````````````--`..~^++` -^^||~`..... <br></br>
 .................`````````````````````````````~`....``^?+^>`  .--~>+|`... <br></br>
 ................```````````````````````..``-+~`..~||`|^`..`>-~..`-+|`~`.. <br></br>
 ...............`````````````````````.....-|-~-.|?^+`.```|-.  >v..`--``~.. <br></br>
 ...............`````````````````````.....~~.`v|>>`..```.|>~.-?v~`.```??`. <br></br>
 ...............```````````````````.......``.|v`....`~~`..`->-..->`..`+?.. <br></br>
                  ...............`````````````````.........``.-|......``-||`.~?-.-~``..`+.. <br></br>
 ................```````````````.........``..~-~.... .`+v>^` `+~`..?v..... <br></br>
 ............-^|`..``.........```````.......|>|-`.|` ..`?>^|`..-```??..... <br></br>
 ...........-rww?.............``~|^^^~--```~>+^vv|^- ....~+^`..```........ <br></br>
 ............^||-..............`-^>????>^~-+^^vzz}~...........``.......... <br></br>
 ........`~~~~~``~^|`...........-^?vvvv?^|+>?rwvv???+..................... <br></br>
 ........^++^~~``||~-.........`~>?vvvvvv>+|`-~>??>>^|.  ......`........... <br></br>
 ........`--||`````>|........``^???vvvvv?>^|~+?^`......................... <br></br>
 ...........^>+~`` +?..........``-^>????v??++^............................ <br></br>
 ............`+>>- .^~............`|+????>~``............................. <br></br>
                  ......``..... .+~ ..`..............`-~-``................................ <br></br>
                  ....`+|~-`~~.     .`..................................................... <br></br>
 ....`>vv?^^v?|     ```?>`...............................................`.<br></br>
.-``..-vwwv>?wz- `` .`?r?`........................................-|^^>??v-<br></br>
.~~~--~?rwwr}rx? ~}- -}z?|...................................`~+?vvvvvv?vv-<br></br>
.?vv?+^+v}wzzwww..ww` ?xv^`.........................`-~|^+>???vvvvvvvvvvvv-<br></br>
.|?}r}v?vrrwzzzx^ ?ur..vrv+`...................`-^?vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 `-^vrwwwwwrrzzxv .rz?  ?r?`..```.......`-~~|>?vvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?vvv}rwwwwwzzzzx~ -}w>  -...`````~|+??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?rwwrrwwwzzzzzzzv  `.?>...~^||^>?vvvvvvvvvv}vvvvvvvvvvvvvvvvvvvvvv?????vv`<br></br>
                  `vrwrrwwwwzxxzwwrr~..-???????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvv?`<br></br>
.vrrrrwzxzzwr}}vvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vv??????`<br></br>
.v}}rr}}vvvvvvvvvvvvvvvv??vvvvvvvvvv????vvvvvvvvvvvvvv?????v??????????????`<br></br>
.>?vvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvv??????????????????????????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????>>>>>?????????`<br></br>


            </div>,

            <div>

                  ................`````````.``...```..```...........|?v+-.  .````-+^```````.<br></br>
                  .......`````````````````````````````````````.`.`~~v?^`.???|.`````|^``````.<br></br>
 .......```````````````````````````````````..-~?^`--```.^``z> ..``-?^`````.<br></br>
                  ............````````````````````..........`^x?|`.````--. `v?```.`^x?.````.<br></br>
 .............`````````````...............>?+?^...`````.-+-`````..->>.````.<br></br>
 .........................................??.``` ...`~`..?}~.`-?>-.`~.````.<br></br>
 .........................................~-.```. ..v??>` ^v>`..+z. ..````.<br></br>
 .........................................-`........?}??>- .+-`.>?......``.<br></br>
 ....................................... ---``^}> .. |v?>|..``-`.........`.<br></br>
 ............-^|`.............``........`~`.-zv>|  .. .-`..````..........`.<br></br>
 ...........-www?............`~|``-^>>~.`-~`vxw?^~`....................... <br></br>
                  ............^^|-..............`|?vvv?^~?vvwwrvrzr?. .......`............. <br></br>
 ........`~~~~~``~||`..........->vvvv?++`..`>rv^~-`....................... <br></br>
                  ........^++^~~``||~-.......-|+?vvvvvv?+`.. +r~........................... <br></br>
 ........`--~|``.``>|.....`^++>?????vvv?~`..?~............................ <br></br>
 ...........^>+~``.+?......``..``~>??+|~`..`|............................. <br></br>
 ............`+>>- .+~............-~`..................................... <br></br>
 .....>v?|.... .>~ -~`.................................................... <br></br>
   ...?rxw?|..     >}^..~................................................. <br></br>
 -`.  .>wxzwv?+    .}}>>r>.............................................`-^`<br></br>
`r}?~.`?}rzxzzw- `` ~}rwrv`.......................................`-|>?vvv-<br></br>
`rwwrv}wwrrrwzx? |}- +zzr}~................................`~|+>??vvvvvvvv-<br></br>
.?}zzzwwrrww}rzw..wz` ?xr}~......................  ...`-|>?vvvvvvvvvvvvvvv-<br></br>
 .->vrwwwzzzwwwx^ >x}. vw}+...................`-||^+?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.^^^+?}rwzzwrwzx} .}z?  ?r?~...........``~^>>?vv}}}v}}v}v}vvvvvvvvvvvvvvvv-<br></br>
`rwvv}rrrrzzzzzzx~ ~}r+  -..``````-|+>????vvvvvvvvvvvvvvvvvvv???vvvvvvvvvv`<br></br>
`rr}rwwwwwzzzxxxxv  ..?+...|^|+??vv}vvvvvvvvvvvvvvvvvvvv??????vvvvv?????vv`<br></br>
`rrrrzzzwzxxzzwwrr-.`->?>>??vvvvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvv????????vv?`<br></br>
`rrrrwzzxxzww}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvvv??????vv?????`<br></br>
`rrwwwrr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????vvvvv?????????????????????`<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvvv??????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????>>>>>>>>?????`<br></br>


            </div>,

            <div>

 ................`````````.``...```...``...........~>?>~`   .```-+^```````.<br></br>
 ..````````````````````````````````````````````.`~~?>>~`>>>~..````~^------.<br></br>
 ..`````````````````````````````````````````.--?^````-`.|`-}>...``-?^-----.<br></br>
                  ..``````````````````````````````````......`^w?+`.````--. .??|-`.`|r?~----.<br></br>
 .....`````````````````````...............+?>?+...`````.-+``````..`^>-```-.<br></br>
 .........................................?>````.....-`..+v+`.-?+- `~`````.<br></br>
                  .........................................~-.`.`. ..???+- ~v?-`.^r`. .````.<br></br>
 .........................................```.......+vv?>~..||-.^>-`..````.<br></br>
 ....................................... `--``|?>... ~???^...`-`.....`````.<br></br>
                  ............-^|...............`.........~`.`rv?|......``..`````.....`````.<br></br>
 ...........-www?.............~|-`~^>>~``---?zzv+~`.....................``.<br></br>
 ............^^|-.............``|?vvv?+~>?vrww}rww?. .......`............`.<br></br>
 ........`~~~~~``~||`..........->vvvvv>>-..`+}}+|-`......................` <br></br>
 ........^++^~~``||~-.......`~|>vvvvvv?>-`..|v|........................... <br></br>
 ........`--~|``.``>|.....`||^+>?>??vvv?>~``>~............................ <br></br>
 ...........^>+~`` +?......``..``->??>>+^-``~............................. <br></br>
 ............`+>>- .+~...........`||-`````................................ <br></br>
 .....^>^-.... .+~ `-`.................................................... <br></br>
                  .....^?v?+-..     ^?~..`................................................. <br></br>
 `..  .^?v}v?>|    .?v?>?^............................................`-|^.<br></br>
.??>^``+??vwzr}- `` ~}rrv>`.......................................`^??vvvv-<br></br>
.vvvr}}r}vvv}zx? |}- +zzv?`...............................``-|+>??vv}vvvvv-<br></br>
.+?v}wwwr}rr}rzw..wz` ?uv?-...........................`|>?vv?vv}}}vvvvvvvv-<br></br>
 .`>?vrwwzzzwwwx^ >x}. vw}|..............  ...``-~~|>?vvvvvvvvvvvvvvvvvvvv-<br></br>
.^^+>v}rwzzzwwzx} .}z?  ?r?`...........`-~+??vvv}}}}}vvvvvvv}}}vvvvvvvvvvv-<br></br>
`rr}}}rrrrzzzzzzx~ ~}r+  -.......`-|+>>???vvvvvvvvvvvvvvvvvvv????vvvvvvvvv`<br></br>
`}rrrwwwwwzzzxxzxv  ..v+. .|||>??v}}v??vvvvvvvvvvvv?vvvv??????vvvvvvvvvvvv`<br></br>
`v}rrwzwwzxxzzwwrr-..`+>>>?vvvvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvv??????v?`<br></br>
.v}}rwzzxxzww}vvvvv?vv??vv}vvvvvvvvvvvvvvvvvv????vvvvvvvvvv????????vvv????`<br></br>
`v}rwwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????>??vvvvvv???????????????????>`<br></br>
`???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????vvvvv????????????????>>>>>>?>`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv?vv????>????????????????????????>>>>>>>>>????`<br></br>


            </div>,

            <div>

 ...............................```...``..```````````-^>|-.  .```~||-`````.<br></br>
                  .```````````````````````````````````````````````-~`|?+|-`++~ -||||^+~-~~~.<br></br>
 .`````````````````````````````````````````````-^+~`|-`>^-+-^| .``-~|>~~~~.<br></br>
 .````````````````````````````````````````````>v|~`-|`~|-` .??|..```+?|~--.<br></br>
 .``````````````````````````````````````````?|>+||`~`.````-...+?...`^>~~--.<br></br>
 ..```````````````````````.......``..``````-?~>~+^....``..-?-`+>-~`..~>>--.<br></br>
 ..```````````........................``````|-+``... ->|-`.`?^`..-v- .|>--.<br></br>
 ..........................................-`-`..... `>^+^~.`?^`.~?~..`|`-.<br></br>
 ..........................................`.--`~|....`?v?>`.`|-`..^>...`-.<br></br>
 ............-^|.................```````..-`-`??~|~. ...+>|-...-```++.````.<br></br>
 ...........-www?.............`-|+++>>+^```~~|vrw^~- ....`~-`...``....````.<br></br>
 ............^^|-.............`~|+>??v?>|||>??}wwr+-. ..............``````.<br></br>
 ........`~~~~~``~||`...........~>?vvvv?>|~|^?rr??>>|. ............```````.<br></br>
 ........^++^~~``||~-...........^?vvvvvv?>|`.-v>^||~-.............````````.<br></br>
 ........`--~|``.``>|..........`~+>?vvvvv?>~`>>-...................```````.<br></br>
 ...........^>+~``.+?............`|>??vvvv?^|^............................ <br></br>
 ............`+>>` .^~............~^+>???+~`.............................. <br></br>
                  ............. .+~ ..`..............``--`................................. <br></br>
                  ..............    ...`................................................... <br></br>
                  ...  .....+~..     .^v+...............................................`-~.<br></br>
 ..|+~`. ..>}v^. `` .?wv`.......................................`-|+>>?vvv-<br></br>
 ..~}w}v^``~?zz> ~}- +zr~..................................`-|>??v??vvvvvv-<br></br>
                  .. `vzzwvvvvrzw..rr` ?x^.............................`~+?vvvvvvvvvvvvvvvv-<br></br>
  .||^vwzzwrrrrx^ ?ur..vwv`............... .....-|+>>???vv}vvvvvvvvvvvvvvv-<br></br>
.~|?wr}}rzxzwwwz} .wz? .?r|........  ...`~^+>??vv}}vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.vrrrrwwwrwwwwwzx~ ~rr>  -...  ...`~^>??v}}}}vvvv???vv?vvvvvvvvvvvvvvvvvvv-<br></br>
 ~?wwzzzzzzrwzw}w?  `.?>. .`-|+>?????vvvvvvv??????vvvvvvvvvvvvvvvvvvvv???v`<br></br>
 .^v}rrrrwzzxzwrrr~ ..>??>?v}}}vvvvvvvvvvvvvvvvvvvvvvvvv??????????????????`<br></br>
 |>vrwzzzwwww}vvvvv????vvvvvvvvvvv?????vvvvvvvvvvvvv?????????????????v????`<br></br>
 ~>vwrr}vvvv??vvvvvvv}vvvvvv??????????vvvvvvvvv???????????????????????????`<br></br>
                  .|+?vvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvv??????????????????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????????????????????????????>?????`<br></br>


            </div>,

            <div>

                  ...............................```....`````````````````````````------````.<br></br>
 .`````````````````...........``````````----------~~~~~~~^>?~ ~>>>>^|~~~~~.<br></br>
 ..``````````````````...........````````-------~~~~~~~-vvv?~.  `--|^>>^|||.<br></br>
 .``````````````````````.....```````````------~~~-^++-^+~-`.>~~..````~^~--.<br></br>
 .``````````````````````````````````````-------->}^>`.```--. .?w...```-^--.<br></br>
 .````````````````````````````````````````---|}^}}`..`````--.~?}~`````vr--.<br></br>
 .```````````````````````````````````````````>w````. ``.....+-`````..`?}--.<br></br>
 ..``````````..................``````````````|>.```. ...||-.~?-`..~-..`?--.<br></br>
 ................................``````````..|``.... . ^v?>~.`>~`..?w..`--.<br></br>
 ............`^|................``````......+~``.->- ..`?v?>- .~-`-v?.``--.<br></br>
 ...........-rww>...............-^+^~--|~``||``vw^?^ ....^?>-..``-`..`----.<br></br>
                  ............^^|-...............`-~^??v?^-`-``vxwv`...........```..``-----.<br></br>
 ........`~~~~~``~||`............`|?vvv?+>??vrw}}}rr?` ...........````````.<br></br>
 ........^++^~~`.||~-...........~>?vvvvv??+-``?r}vv?+`  ......``.`````````.<br></br>
 ........`--||``.``>|..........->??vvvvv??+|`+}?`...........`.````````````.<br></br>
 ...........^>+~`` +?...........``~+???vvv?>>>`.............``````````````.<br></br>
 ............`+>>- .^~.............~>?vv??|``...........................``.<br></br>
                  ............. .+~ ..`..............`~^|``................................ <br></br>
                  ..............    ...`................................................... <br></br>
 ... .....`>|..     .^v+...............................................`-~.<br></br>
 ..~^-`....>}v^. `` .?wv-.......................................`-|^++>vvv-<br></br>
 ..-v}v?>-~^?zz> -}- ^zw|..................................`~^>???vvvv}vvv-<br></br>
 ...`?rrrvv}vrzw..rr` ?x+............................```|>?vvvvvvvvvvvvvvv-<br></br>
 ..|^^?rwzwrrrrx^ ?ur..vrv-................. ..`|>??????v}}vvvvvvvvvvvvvvv-<br></br>
 -~?wr}}rzxzwwwz} .wz? .?w^........  ....`~|^>?}}vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?rrrrwwwrwwwwwzx~ ~rr>  -...  ...`~^+??vv}}}vv???vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 ~?wwzzzzzzrwzw}w?  `.?>. .`~|^>?>???vvvvv??????vvvvvvvvvvvvv?????vvvvvvvv`<br></br>
 `>}}rrrrwzzxzzrrr- ..>??>?v}}}vvvvvvvvvv?vvvvvvvvvvvvv?????????vvvvvv??v?`<br></br>
.^?vwwzzzwwww}vvvvv???vvv}}vvvvvv??vvvvvvvvvvvvvvv???>>>????vvvvvvv???????`<br></br>
 ^?vwwr}vvvv?vvvvv}}}}}}vvv??????vvvvvvvvvvvvv????>>????vvvvv?????????????`<br></br>
.|+?vvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvvvvvvv??>>????vvv???????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvv?vvvv?????????>>?????????????????????????????`<br></br>


            </div>,

            <div>

                  ..........................``````````````--`-----~~~-~|^~`...```-~~~-~||~~.<br></br>
 .....................````````---------~~~~~~~|||||~|+^~-~+>~ ~++^+^^^^^^|.<br></br>
 ....................`````````-----------~~~~~|~|^~`~-`?>+>-~. .`-~|+>^|||.<br></br>
 ..`.................````````-------~~---~~~~~+?~~~|^`|^~` .>^~..```|+^~||.<br></br>
 .``````...........``````````------~~~~~~~~~>~^|^?~^`.````-...>v...`~|-|~~.<br></br>
 .```````````````````````````------~~~~|||~~>|?|??....``..->`-?v~-`.`-?v--.<br></br>
 .```````````````````````````------~~~~||||~|^?```.. `|``..`?|``.`>` `+?--.<br></br>
                  .````````````````````````````-----~~~~~~--~-~|..`.. `|-^^-.-?|`.-+-..`+--.<br></br>
 ..``````````....``````````````-----~--`````.~```-.. ..>v?>` `+~`..>v..`--.<br></br>
                  ............-^|`..`````````````~~||~--````.|-+^`-^` ...??+|`..-```??.``--.<br></br>
 ...........-rww?.......``````~|^>>>>++^-``||~+vr^+~ ....~+^`..``-`..`-~--.<br></br>
 ............^^|-..........```-~|+>??v?>^~-^^^vzw}~`. ........``````------.<br></br>
 ........`~~~~~``~||`...........~+?vvvv?+^++?vw}v???+` ..........```-`````.<br></br>
 ........^++^~~`.||~-..........`+?vvvvvv??+-`-?v??>+|`........````````````.<br></br>
 ........`--||``.``>|..........-+???vvvvv??^`>v+-`..........`.````````````.<br></br>
 ...........^>+~``.+?...........``|>??vvvv?>++`.``````````````````````````.<br></br>
 ............`+>>- .^~............-^>?v???^-`......```````````````````````.<br></br>
                  ............. .+~ ..`..............`~||-`...................```````````.` <br></br>
 ....~>~...|+`.    ..........................................```````````.. <br></br>
 ....~}r?^`~v}+     ..`?^...................................``````....```-.<br></br>
 .....|}zw?|^wx~ --  .+w+........................................`|+>????v-<br></br>
 ......+wwzwv}z? |r- `vw>.................................``--~|>vv}vv???v-<br></br>
.??>|`..>}rzzwzw..wr` ?x?`..........................``|+>?????vvvvvvvvvvv}-<br></br>
.+vwwv?^+vrwzxxx^ >ur. v}?+....................`~+???vvvvvvvvvvvvvvvvvvvvv-<br></br>
 ..|vzzwwrrrrzxx} .rz?  ?w>........  ....`-~^>?vvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>??vrrwwwwwzzxzx~ -}w>  -...  ...`~^>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?wwwrrwrrzzzzzzzv  `.?>...`~||^+>?vvvvv???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`}rwwwwwwwzzzzwwrr-..`?v??vvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvv?????vv`<br></br>
`rwwwwwzzzzwr}}}vvv?vvvv}vvvvv??vvvvvvvvvvvvvv?????vvvvvvvvvvvvvvvv???????`<br></br>
.vrrrr}}vvvvvvvvvv}}vvvvv????vvvvvvvvvvvvv??????vvvvvvvvvvvvvvv???????????`<br></br>
.>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvvvv?????????????????????>`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv????????????????????????????????>>????????>>>>.<br></br>


            </div>,

            <div>

 ...............````````````````````---------~~~~~~~~+?v>-.  ````|>+~^^^|~.<br></br>
 ..............``````````-----------~~~~~~~~||||||^|>}?|``+^|.`--`-^>++++^.<br></br>
 ...............`````````````----------~~~~~~|||>?^`^-``.`+-?r...```~>^+++.<br></br>
 ...............``````--------------~~-~~~~~|~vz+^..```--. .vy>.````?r|^^^.<br></br>
 ..............```````-----------~~~~~~~~~~|}+??`..```````~--~-```.`>v||^^.<br></br>
                  .............````````--------~~~~~~~~~||||+w~.``. ...... ~r|```|^`.`^|||^.<br></br>
 ..```........```````------~~~~~~~~~|~~|||||>`.``.  .|v>>- ~}>```~z| `-|||.<br></br>
 ..`````.....`````````-----~~~|||||||~|||-`|~`.... . |r??>~..?+`.>x^..`~||.<br></br>
 ..```````..`...```````----~~~~|||||||~-`-|^``--??. ..-v?>>` .`--````~~~~~.<br></br>
 ..`````````.~+^`.```````-----~~^^^+^^+>^|+`..rw+>. ....||`.```````-~~~~~~.<br></br>
 ...........~www?.``````````-|>?????vvv?~~~~->uz}^--............``-~~~~~-~.<br></br>
 ...........`^^|-....````````|>>>?vvvv?+~+?vwww}}zwv` .......`.`----------.<br></br>
 ........`~~~~~``~^|`..```````-+?vvv}}v>+-..`+r}+|~-.. ......-`-`````-----.<br></br>
 ........^++^~~``||~-.......```~??vv}vv?>+|~`^w+```````````````````````---.<br></br>
 ........`--~|``.``>|..........`-|+??vvvvvv>~?^`--````````````````````````.<br></br>
 ...........+>+~``.+?............-+??vvvvv?>|^````````````````````````````.<br></br>
 ............`+>>` .^~..........`|??>>>>?>^-````````````````````````````.. <br></br>
                  ............. .+~ ..`...........`-````````...``````````````````````````.. <br></br>
 ....~>~`..|+`.    ....................................``````````````..... <br></br>
 ....~}rv+`~vv^     ..`?^..............................```````````......``.<br></br>
 .....|}zwv|+wx~ --  .>w>..............................````````..-|++>>??v-<br></br>
 ......>wzzr}}x? |}` `vz?`.................................```~>?vvvvv??vv-<br></br>
.??>^`..?rrzzwww..wr` ?x?`.........................`~^+++>>>??vvvvvvvvv}vv-<br></br>
.+vww}?++vrwzxzu^ ?u}. v}v+....................`-^?v}}vv?vvv}}vvvvvvvvvvvv-<br></br>
 ..|vwzwwrrrrzxx} .wz?  ?w?......... ....`-~|>??vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.+??v}rwwwwwwzxzx~ -}w>  -..  ....`~^>??vv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?wwwr}wwrzzzzzzzv  `.?>...`~~~|+?vv?????vvvvv}vvvvvvvvvvvvvvvvvvvvv?vvvvv`<br></br>
`}rwrwwwwwzzxzwrrr-..`????vv?vvvvv???vvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvv`<br></br>
.}wwwwwzzzzwwr}}vvvvv}}vvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvvvv??vvvvv??????`<br></br>
`}rrrr}}vv?vvvvvvvvvv?????vvvvvvvv???????vvvvvvvvvvvvvvv???vvvvv??????????`<br></br>
.??vvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvv??????????????>>>>???????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvvv???>??????????????????????????>>>>++>>>????????`<br></br>


            </div>,

            <div>

 .......``````````````````---~~~~~~~~~|||||^^^^^++++|+?v>-.  ````|>+~~--`-.<br></br>
 .````````````----------~~~~~|||||^^^^^^+++>>>>>>>>^>}?|``+^|.`--`-^>^~~--.<br></br>
 ...```````````-----------~~~~|||||^^^^^+++++++^>?^`^-``.`+-?r...```~>~~--.<br></br>
 ....`````````------------~~~~||||||^^^^+++++^vz+^..```--. .vy>.``.`?r~~--.<br></br>
 ....`````````----------~~~~~~~||||||^^^^^^^r+??`..```````~--~-```.`>v~---.<br></br>
                  ....`````````---------~~~~~~~~||||||||^^^^+w~.``. ...... ~r|```|^`.`^----.<br></br>
 ....````````----------~~~~~~~|||||||||||^||>`.``.  .|v>>- ~}>```~z| ``---.<br></br>
 ..`````````-------~~~~~~~~~~||||||||||||~`|~`.... . |r??>~..?+`.>x^..`---.<br></br>
 ..`````````-```--~~~~~|||||||||||||||~-`-|^``--??. ..-v?>>` .`--````~~--~.<br></br>
 ..``````````|+^--~~~||||||||||^++++^^+>^|+`..rw+>. ....||`.```````-~~~~~~.<br></br>
 ..`````````|www?`--~~~~|||~|+??????vvv?~~~~->uz}^--............``~||~~~~~.<br></br>
 ....``````.`^^|~``-----~~~~|+>>??vv}v?+~+?vwww}}zwv` .........`~~~~~~~~~~.<br></br>
 ........`~|~~~``~^|-`-------`~>vvvv}}v>+-..`^r}+|~-.. ......--~~~~~~~~~~~.<br></br>
 ........^++^~~``||~-``````.``~^?vvv}}v?>+|~`^w+```````````----------~~~~~.<br></br>
 ........`--~|``.``>|.`..``````-~^>??vvvvvv>~?^`-````````````------------~.<br></br>
 ...........+>+~``.+?..``````````|>?vvvvvvv>|^```````````````------------~.<br></br>
 ............`+>>`..^~......````-+???>???>+~`````````````````````````````-.<br></br>
                  .....?v?~.... .+- ~|`.......````-~~-----`````````````````````````````````.<br></br>
 ....`?wxw?^`.     >r+..~`.........````````````````````````````........... <br></br>
.~-....?wxzwv?>.   .}}>?r>...................``````````````.............-|.<br></br>
`rr?^`-vrwzzzww~ `` ~}rwr}-...................``````````...........`-|>vv}-<br></br>
`wwwr}}wwrrrwzx? ~r- +zzrr|..................``````````...``~^>>???v}}}vvv-<br></br>
.?rwzzwwrrwwrrzw..wz` ?xr}|.........................```-|>?vvvvvvvvvvvvvv}-<br></br>
 .`>vrwwwzwzwwwx^ >xr..vwr+..................`-|^>>??vvvvvvvvvvvvvvvvvvvvv-<br></br>
.||-~?}rwxzwrwzx} .}z?  ?r?-........ ..`-~|++?vv}}vvv}vvvvvvvvvvvvvvvvvvvv-<br></br>
`rwvv}rrrwzzzzzzx~ -}r>  -.... ...-|+??v??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?v-<br></br>
`rwrrwwwwwzzzxxxxv  `.?>...~--^>?v}}}}vvvvvvvvvvvvvvvvvv????vvvvvv???>???v`<br></br>
`wwwrzzzwwxxzzwwww~.`-??>>>??vvv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>???vvvv`<br></br>
`wwwwwzzxxzwr}}vvvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvv?????vvv?????`<br></br>
`rwwwwwr}vvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvv????vvvvv??????????vv??????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvv???vvvvvv????>>>????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvv???????????>>>>>???????????????????????`<br></br>


            </div>,

            <div>

 ```````--`````-~~~~~~~~~~~~~|^^^^^+++>>>>>>>>>>>+++|+?v>-.  ````|+|...... <br></br>
 ``-----------~~~|||||||^^^^^+++++>>>??????????????^>}?|``+^|.`--`-|>`.... <br></br>
 ```````--------~~~~~~|||||^^^^+++++>>>????????+??^`^-``.`+-?r...```~+.... <br></br>
 ```````-------~-~~~~~||||||^^^^++++>>>>>>>>?+vz+^..```--. .vy>.``.`?r.... <br></br>
 `````````------~~~~~~|||||||^^^^^++++>>>>>+r+??`..```````~--~-```.`>v. .. <br></br>
 `````````--------~~~~~||||||||^^^^^+++++>++w~.``. ...... ~r|```|^`.`^.... <br></br>
 `````````--------~~~~~~|||||||^^^^^^^^+++^|>`.``.  .|v>>- ~}>```~z| ..... <br></br>
 `````---------~~~~~~~||||||||||||||||^^|~`|~`.... . |r??>~..?+`.>x^...... <br></br>
 `````---------~~~~~~~~|||||||||||||||~-`-|^``--??. ..-v??>` .`--``````... <br></br>
 `````----~~~^>+~~~|~~~~~~|||||^+++++^+>^|+`..rw+>. ....||`.```````---````.<br></br>
 ``````----`|www?-~||~~~~~~~~^??????vvv?~-~~->uz}^--............``~~---`--.<br></br>
 ````````````^^||-~~||||~~~~|+>>??vv}v?+~+?vwww}}zwv` .........`~~~~~~~~~~.<br></br>
 ``.``````~|~~~``~^^~~|~~~~--`~>vvv}}}v>+-..`^r}+|~-.. ......--~~|||||||~~.<br></br>
 ....```.^++^~~``||~-`---````-|+?vvv}}v?>+|~`^w+````````---~~--~~~~~~|||~~.<br></br>
 ........`--~|``.``>|``````---~|~^>??vvvvvv>~?^`-````-------------~~~~~~||.<br></br>
 ...........+>+~``.+?..``````````|>?vvvvvv?>|^```````--------------~~~~~~|.<br></br>
 ............`+>>-..^~....``````-+???>???>+~-``````````-````````-----~~~~~.<br></br>
                  .....?v?~......+- |~`...````````-|~-----```````````````````````````-----~.<br></br>
 ....`?wxw?^`.     >r+.`|`..``````````````````````````````......`````````-.<br></br>
.~-....?wxzwv?>    .}}>?r?`........```````````````````...............`-|^>`<br></br>
`rr?^`-vrrzzzww~ `` ~}rwr}-.............```````````...............`|?vvvvv-<br></br>
`wwwr}}wwrrrwzx? |r- +zzrr|............````````````.......``~|+>??v}}vvvvv-<br></br>
.?rwzzwwrrwwrrzw..wz` ?xr}|............`````````.....`-|>????vv}}}vvvvvvv}-<br></br>
 .`>vrwwwzwzwwwx^ >xr..vwr+................```-~~||^>?vv?vvvvvvvvvvvvvvvvv-<br></br>
.||-~?}rwxzwrwzx} .}z?  ?r?-...........`~|+?vvvv}}}}}}vvv}}}}}vvvvvvvvvvvv-<br></br>
`rwvv}rrrwzzzzzzx~ -}r>  -.......`-|+>>???vvvvvvvvvvvvvvvvvv??>???vvvvvvvv-<br></br>
`rwrrwwwwzzzzxxxxv  `.v>. .~~~+??v}}???vvvvvvvvvvvv?vvvv??>>>??vvvvvvvvvvv`<br></br>
`wwwrzzzwwxxzzwwww~.``>?+>?vvvvvvvvvv}}}vvvvvvvvvvvvv????vvvvvvvvvvvvvvvvv`<br></br>
`wwwwwzzxxzzw}vvvv?vvv??vv}vvvvvvvvvvvvvvvvvv????????vvvvvvvvvvvvvv??vv???`<br></br>
`rwwwwwr}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>>?vvvvvvvvvvvvvvvv???????>>>`<br></br>
`vvvvvvvvvvvvvvvvvv???vvvvvvvvvvvvvv??>>>???vvvvvvvv????????????>>+++++>>>`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>??????????????????????>>++++++>>>???`<br></br>


            </div>,

            <div>

.--~~~~~||||^^^^^+++++++>>>>>>>>>>++++^^^||~-`...........     ..     ..... <br></br>
.~~|||||^^^++++>>>>>>>>>??????????????>>>++^|-```........~^>- ~>++^-...... <br></br>
.~~~~~|||||^^^^+++>>>>>>?????????????>>>>>+^|~-``.....`?}v?~.  `--|^>^`... <br></br>
.-~~~~~~~|||^^^^^+++>>>>>?????????????>>>>>+^|~-``||+-^>~`..>~~..````~^` . <br></br>
.----~~~~~|||||^^^^++++>>>>>??????????>>>>>>+^|`>}^>`.```--. .?w...```~^.. <br></br>
.-----~~~~|||||||^^^^+++>>>>>>????????>>>>>>+^}^}v`..`````-`.~?}~`````vr.. <br></br>
.-------~~~~~|||||^^^^^+++++>>>>>>>>>>>>>>+++?w-```. ``.....+-`````..`>}.. <br></br>
.``-----~~~~~~|||||^^^^^^++++++++>>>>>>>++^^|^>.```. ...||-.~?-```~-..`>.. <br></br>
.-------~~~~~~~||||||^^^^^^^^^^+++++++++^|-``^``.... . ^}?>- ->~`..?w..... <br></br>
.-----~~~~~~~^>+||||||||^^^^^^^^++++++^~-```+~``.-?- ..`?v?>` .~-`-v?.. .. <br></br>
.--~~~~~~~|~^rww?~|||||||||||||^>???>^^+>^|^|.`vw+?^ ....^?>`..``-`....... <br></br>
.--~~~~~||~~~+^|^~~~||~~~~~~~||^+++>?vv?>|`-``vxwv`...........````.`..````.<br></br>
.--~~~~~~~|||~~``~^^~-~~~~~~~~--~^?v}}}?++??}rwr}}rr?` ..........````---~~.<br></br>
.````--~-^++^~~`.||~-`~~~~~-`-~^?vvv}}}v??>-``?r}vv?+`  ......``-~~||||^^^.<br></br>
 ``````-----~|``.``>|`~--```-~|>?vvvvvvv??>^~>r?~``..``..```--~~|^^+++++++.<br></br>
 ```````````+>+~``.+?-```------|^^+??vvvvvv?>>~`--~~~~~~~~~||^^^^+++++++++.<br></br>
 ....````````-+?>-.`^|````````````~>?vvvv?>~-`---------~~~~|||||^^++++>>++.<br></br>
 ..........```. +~ ``-`````````````~^>>>^|~```--```------~~~~||||^^+++++++.<br></br>
                  ............`.    .``~```````````````````````````````````---~~~||^^^+++++.<br></br>
 .........`>^`.  .  `>}>`````````````````````````````````````----~~~^^^++>`<br></br>
 ..^>|`....?rv^. `` .vz}~````````````````````..................``~+???vvvv-<br></br>
 ..|rw}v+-~|?zz> ~r~ +zr|......````````````................`-|+>?vv??vvvvv-<br></br>
 ...~vzzwvv}vrzw..rr` ?z+`......```````````...........~^>?vvvvvvvvvvvvvvvv-<br></br>
 ..|^^vrzzzrrrrx^ ?ur..vwv~.....```````.........`~^+>?v?vvvvvvv}vvvvvvvvvv-<br></br>
.-~?rrrrrzxxwwwz} .wz? .?w^...........``~+>???v?v}}}vvv?vvvvvvvvvvvvvvv?vv-<br></br>
.?rrrwwwwrwwwwwzx- ~rr>  -.......``|+>?v}}v}}}}}vv???????vvvvvvvvvvvvvvvvv-<br></br>
 `>rwzzzzzzrwzw}w?  `.?>. ..-|+????????vvvvvvv?????vvvvvvvvvvvvvvvvvv?????`<br></br>
 .-v}rrrrwzzxzzwrr~ ..+??>?vv}}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvv????????????v`<br></br>
 ~+?rwzzzwwww}vvvv??????vvvvvvvvvvv???>?vvvvvvvvvvvvvvvv????>>>?????vvv???`<br></br>
.?vrwwr}vvvvv??vvvvvvvvvvvvvvv???>>???vvvvvvvvvvvvvv???>>>>?????v?????????`<br></br>
.??vvvvvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvv??v???>>>>??????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvv???????????>>>>>???????????????>>????`<br></br>


            </div>,

            <div>

.||^^^^^+++>>>>>>>>>>>>>>>>+^^^|~``.....................      ..     ..... <br></br>
.^+++>>>>????????????????????>>+^~-``.................. .-^>- ~>++^-...... <br></br>
.^^^^+++>>>???????????????????>>^|-```............  ..`?}v?~.  `--|^>^`... <br></br>
.|^^^^^^++>>>??????????????????>>^~-```...........~|+-^>~-`.>~~..````~^` . <br></br>
.||||^^^^+++>>>>>???????????????>>+|-```........+}+>`.```--. .?w...```~^.. <br></br>
.||||||^^^^++>>>>>???????????????>>+|-``.....`v^}v`..`````-`.~?}~`````vr.. <br></br>
.~~~~|||||^^++++>>>>>?????????????>>+^~``....+w-```. ``.....+-`````..`>}.. <br></br>
.~~~~~|||||^^^^+++>>>>>>?????????>>>>+^|-`...~>.```. ...||-.~?-```~-..`>.. <br></br>
.~~~~~~|||||^^|^^+++++>>>>>>>>>>>>>>>>++^~`..|`..... . ^}?>- ->~`..?w..... <br></br>
.~~~~~~~|||||^>>^^^^^^++++++++++>>>>>>+~-```+~``.-?- ..`?v?>` .~-`-v?.. .. <br></br>
.~~~~~~~||||^rww?~|||^^^^^^^^^++????>^^+>^|^|``vw+?^ ....^?>-..``-`....... <br></br>
.~~~~~~~||~~~+^||~~~||||||||^^^^+++>?vv?>|`-``vxw}`...........````.`...... <br></br>
.~~~~~~|~~|||~~``~^^~~||||||||~-~+?v}}}?++??}rwr}}rr?` ..........```````--.<br></br>
.~~|||||-^++^~~`.||~--~~~~~---~^?vvv}}}v??>-``?rrvv?+`  ......``-~~~~~||||.<br></br>
.~~~~~~~~---~|``.``>|`~--```-~^?vvvvvvvv??>^~>r?~-`..``.```-~-~|^^^^^^^^++.<br></br>
.``-----~-``+>+~``.+?-```-----~^^^+??vvvvvv?>>|-~~~~|||||^^+++++++++++++++.<br></br>
.````````---`~+?>-.`^|````````````|>?vvvvv?|-`~~~~~||||^++++++++++++++>>++.<br></br>
 .````````````. +~.``-````````````-|+>??>^~-----~~~~|||^^+++++++++++>>>>++.<br></br>
 ....``````````    .`-~`````````````----```````----~~~||^^++++++++++>>>>++.<br></br>
 .........->^`.  .  `>r?````````````````````````````--~~~|^^^++++++++++++>`<br></br>
 ..^>|`...`?rv^. `` .vz}~```````````````..........``````--~~||^++>>??>?vvv-<br></br>
 ..|rw}v>-~|?zx+ ~r~ +zr|.```````````.....................`~^>??????vv}vvv`<br></br>
 ...~vzzwvv}vrzw..rr` ?x+-```````````................```|>?vvvvvvvvvvvvvvv-<br></br>
 ..^^+vrzzzrrrrx^ ?ur..vwv~``````````..........-|>??????v}}}vvv}vvvvvvvvvv-<br></br>
.-~?rrrrrzxxwwwz} .wz? .?w^.```````.....``~|^>v}}}vv?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.?rrrwwwwrwwwwwzx~ ~rr>  -.......``~^+>?vv}}}vv????vvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 `+rwzzzzzzrwzw}w?  `.?>. .`~|^>?>>??vvvvv???>>??vvvvvvvvvvvv??????vvvvvvv`<br></br>
  `v}rrrrwzzxzzwrr- ..>v?>vv}}}vvvvvvvvvv??vvvvvvvvvvvv???>>>???vvvvvvvvv?`<br></br>
 -^?rwzzzwwww}vvvvv???vvv}}}vvvv???vvvvvvvvvvvvvvv???>>>????vvvvvvvvv?????`<br></br>
.?}rwwr}vvvv?vvvvv}}}}}vvvv??>>??vvvvvvvvvvvvvv??>+>>???vvvvvvvv??????????`<br></br>
.??vvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvvv?>>>>??vvvv???????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???????????>>>????????????????????????????`<br></br>


            </div>,

            <div>

.>>>>>>>>>>>>>>++^^^|~``..........................   ->?>-.  ````|+~. .... <br></br>
.??vvvvvvv???????>>>+|~```.....................   ``+}?^``+^|.````-|>`.... <br></br>
.???vvvvvv????????>>+^|-```.................. .`^>|`^-`-.`+-?r...```~+.... <br></br>
.??????????????????>>+^~-```..................?z+^..```--` .vy>`````?r.... <br></br>
.>>?????????????????>>+|~````...............v>??`..```````~--~-``..`>v. .. <br></br>
.>>>>>>??????????????>>+|~```............. -w|.`-` ...... ~}^```~|`.`^.... <br></br>
.++>>>>>??????????????>>+^~-``..............>`.``. ..|v>+- -}>```-z| ..... <br></br>
.++++++>>>>????????????>>>+|-``........... ~~`.... . |r??>~..?+`.>x^. .... <br></br>
.|^^^^+++>>>>>>????????>>>>+^~``......... `^```-??....-v??>` .`--``....... <br></br>
.~~^^^^^++++>>??>>>>>>>>>>>+++^~~--``-~-..^`..rr+>. .. .|^`.``````........ <br></br>
.~~||^^^^^^^+www?++++++++++++>???????v?+``~-->xw}^-- ..................... <br></br>
.~~|||^^|^||~+^|^^^^++++++^^+>?>??vvvv?+~+??wzw}}zwv` .......`............ <br></br>
.~~|||||~~|||~~``~^^~|^^^^^|~~~>vvvv}}v>+-..`+}}>^~-.. ......`............ <br></br>
.~~|||||-^++^~~``||~`-^^||--~|^+?vv}}}v?>+|`.|w|...........```````````````.<br></br>
.~~~~~~~~---~|``.``>|-~~-~~||||^+>??vvvvvvv>~?^````````----~~~~~||||||||||.<br></br>
.~~~~~~~~-``+>+~``.+?`-~~~~~~~~~~|??vvvvvvv?^+-~~~~||||^^^^^^^++++++++++++.<br></br>
.--~~--------~+?>-.`^|`-----~~~~^>?????????+^||^^^^+++++++++++++++++++>>++.<br></br>
.`````-------`. +-.``-```-------~|||||^^^^||||^^+++++++++++++>>>>>+++>>>++.<br></br>
 ````^?^-`-+>~.    .```--````------------~~~|||^++++++++++++>>>>>>>+>>>>++.<br></br>
 ..``^}r?+~^vv>  .  ``~v>``````````````----~~~||^^+++++++++++>>>>>+++>>++>`<br></br>
 ...``^}wrv+>wx~ |~ .`>w?```````````````````---~~|^^^+++++++++++++>??vvvv?`<br></br>
 .....`>rwzr}rz? ~r- -rz?``````.............`````--~~||^^+++>>>+>vv}vvv???-<br></br>
.??>|`.`?}rzxwww..ww` ?x?-`````....................`-~^>???v??vvvvvvvvvvv}-<br></br>
.>vwwv?+>}rwzxzx^ ?ur. v}v>````................-|>????v}}vvvvvvvvvvvvvvvvv-<br></br>
 .`^vzzwwrrrrzxxv .wz?  ?w?`.``.........``-~^??vv????vvvvv}vvvvvvvvvvvvvvv-<br></br>
.+???}rwzwwrwzxzx~ -}r>  -........-|^>??vvvvvvvvvvv}}vvvvvvvvvvvvvvvvvvvvv-<br></br>
 +rzwr}wwwzxzzzzzv  `.v>. .`~|^++>?vvvvv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`vrwrrwwwwzzxzzwrr-..`>v??vvv}vvv}vvvv?vvvvvvvvvvv??vvvvvvvvvvvvvvvv????v?`<br></br>
.vrrrrwzzzzwr}}}vvv?vvvv}}vv????vvvvvvvvvvvvv??????vvvvvvvvvvvvvvvv???????`<br></br>
`vrr}r}}vvvv?vvvvv}}}vvvv?????vvvvvvvvvvv??>>>???vvvvvvvvvvvvvvv??????????`<br></br>
.??vvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvv???>>???vvvvvvvvv?v??????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????>>>>??????????????????????????????>>>+.<br></br>


            </div>,

            <div>

.>>>>>++^^|-`.....................................   ->?>-.  ````|+~. .... <br></br>
.???????>>+|-```...............................   ``+}?^``+^|.````-|>`.... <br></br>
.????????>>|~-``............................. .`^>|`^-`-.`+-?r...```~+.... <br></br>
.>????????>+|-``..............................?z+^..```--` .vy>```.`?r.... <br></br>
.??v???????>^~-```..........................v>??`..```````~--~-``..`>v. .. <br></br>
.?vvv??????>>^|-``........................ -w|.`-` ...... ~}^```~|`.`^.... <br></br>
.???????????>+^|-``.........................>`.``. ..|v>+- -}>```-z| ..... <br></br>
.?????????????>+|-```..................... ~~`.... . |r??>~..?+`.>x^. .... <br></br>
.??????????????>+^~-`.................  . `^```-??....-v??>` .`--``....... <br></br>
.??>????????????>>+|~`.............``-~-..^`..rr+>. ....|^`.``````........ <br></br>
.>>>>>>>???>>wwwv+>>+|-`......-||^+>?v?+``~-->xw}^-- ..................... <br></br>
.+++++++>>>+^+^|^^^+++^|~`...`|++>?vvv?+~+??wzw}}zwv` .................... <br></br>
.||^^^^^^||^|~~``~^^~|^^|~-`...~??vvvvv>+-..`^}}>|~-.. ......`............ <br></br>
.~~|||||-^++^~~``||~-~^^|~-`-``~>?vvvvv??+|. |w|.......................... <br></br>
.~~~~~||~---~|``.``>|-|~-~~~~~---~+??vvvvv?^`?^``.````````````````````````.<br></br>
.-~~~~~~~-``+>+~``.+?--~~~~~~~---|>?vvvvvvv?^+-~~~~~~~~|||||||||||||||||||.<br></br>
.-----------`~+?>-.`^|----~~~~~~^>????????>+||^^^^^^^++++++++++++++++>>>++.<br></br>
.-----------``. +-.``----~~-~~~~|^^++^^++++^^++++++++++>+++>>>>>>>>>>>>>++.<br></br>
.`---+?+~`-+>~`    .---~~----~-~~~~|||^+++++++>>>>>>>>>>>>>>>>>>>>>>>>>>++.<br></br>
.``-`^rrv>~^}v>  .  ``~v>-------~~~~~||^^++++++>>>>>>>>>>>>>>>>>>>>>>>>>+>`<br></br>
 `````^rwrv+>wx~ |~ .`>w?````````---~~~|^^^+++++>>>>>>>>>>>>>>>++>>?????vv-<br></br>
 .....`>rwzr}}z? ~r- -rz?```.```..`````-~~~|^^^+++++++++++++++>??vvvvv??vv-<br></br>
.???^`.`?}rzxwww..ww` ?x?-`............````--~~||^^+>>>???>>??vvvvvvvvvvvv-<br></br>
.>vwwv?+>}rwzxzx^ ?ur. vrv>...................`-|>?vv}v???vv}}vvvvvvvvvvvv-<br></br>
 .`^vzzwwrrrrzxxv .rz?  ?w?-............``--^>???vvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.+??v}rwzwwrwzxzx~ -}r>  -........-~^???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 +rzwr}wwwzxzzzzzv  `.?>...-~~~|+?vv?????vvvvv}vvvvvvvvvvvvvvvvvvvvv?vvvvv`<br></br>
                  `vrwrrwwwwzzxzwwrr-..`???vvv?vvvv?????vvvvvvvvvvvvvvvvvvvvvvvv???????vvvvv`<br></br>
.?rwrrrzzzzwr}}}vvv?vvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvvvvvvvvvvvvv?????`<br></br>
`v}}}r}}vv?vvvvvvv}vvv????vvvvvvvv???????vvvvvvvvvvvvvvvvvvvvvvv??????????`<br></br>
.???v?vvvvvvvvvvvvvvvvvvvvvvvv??????vvvvvvvvvvvvvvvvvv?v???????>++>???????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvv??>>???v??????????????????????>>>+^++>>????????`<br></br>


            </div>,

            <div>

                  .|~``....................................................    ............. <br></br>
.^^-```..................................................-^>- -++^|-...... <br></br>
.^^~-``............................................ ..`?v?>~`. `--~^+^`... <br></br>
.++|~-``........................................ .-||`|+~`..>|~..```-|^`.. <br></br>
.>>^|-```.......................................+v^>`.```--. .?w...`---|.. <br></br>
.>>>^|-```...................................`v^vv`..`````-~.~?}~``.``?v.. <br></br>
.>>>+^|-```................................. |r-```. ```....>-````-..`+v.. <br></br>
.>???>^|-````............................... -+.```. ..`||-.~?~`..~-..`+.. <br></br>
.????>>+^~-```............................. .|-`.`.. . +v?>- `>~`..?r..... <br></br>
.??????>+^~-`~^|.......................   ..^---`->- ..`?v>+` .~-`-v?.. .. <br></br>
.????????>^|^rww?...............`-|~-`--...~|`-vw^?| ....|?>-..``-`....... <br></br>
.>???????>>+^+||~................`~^???>|`.~-`vxw}-...........```......... <br></br>
.>>>>>>>+^^^^~~``~^|`............`^?vvv?++>?v}w}vvvv?` ................... <br></br>
.^+^^+++~^++^~~`.||~-...........|>?vvvvv??+```?vvv?>^`  ......`........... <br></br>
.||||^^^|-`-||``.``>|``........~>??vvvvv??>|`+}>`......................... <br></br>
.~~|||||~~`->>+~``.+?-``````...---|>??vvvvv?>>-````````````````````....... <br></br>
.--~~~~||~~~~|+?>-``^|--`````````-^??vvvvv?+|~||||~~~|||~|||||||~~~---````.<br></br>
.----|?v?>|~~~` +~.+^~~~~~~~~~~~||^>>????>>+++^+++++++++++++++++++++^^||--.<br></br>
.----|vrw}v>^~`    >r?|~>|~~~|||^^^^++>>>+++++++++++++>>>+>>>>>>>>>>>>>+||.<br></br>
.^|-``^vwzwr}v?. . .vv??r?~~~||||^^^++++++++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>
`}v?+~|vrwwwwww~ -- ~}}wrv|-~~~~||^^^++++++++++>>>>>>>>>>>>>>>>>>>>>>???vv-<br></br>
`r}}}vvrwrrrwzx? ~r- +wz}v|``----~~|^^^+++++++++>>>>>>>>++++>?????vvvvvvvv-<br></br>
.?}wwr}}rrwwrrzw..ww` ?xrv|...`````-~~|||^^++^++++++++++>?vvvv?vvvvvvvvvvv-<br></br>
 .~?vrrrrwzzwwwx^ >xr..vw}+.........````--~~~|^>>???vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.|~~+?}rwxzwrwzx} .vz? .?w?-...........`-~^++??v}}vvv}vvvvvvvvvvvvvvvvvvvv-<br></br>
`rrvv}rrrwzzzzzzx~ -vr>  -.......`-|+??v?vv?vvvvvvvvvvvvvvvvvvvvvvvvvvvv?v-<br></br>
`rrrrwwwwzzzzxxxx}  `.?>...~--^>?v}}}}}vvvvv}vvvvvvvvvvvvv??vvvvvv??>>???v`<br></br>
`wwwwzzzwzxxxzwwww~.`-?v>>>??vvv}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?>>>>??vvvv`<br></br>
`rwrrwzzxxzzw}}vvvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv?>>??vvvv????`<br></br>
`rwwwwwr}vvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvv??????????vv??????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvvvvvvv???>>>????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvv???vvvvv????????????????>>>>>???????????????????????`<br></br>


            </div>,

            <div>

                  ........................................................    ............. <br></br>
 ........................................................-^>- -++^^-...... <br></br>
                  .................................................. ..`?vv?~.  `--~^+^`... <br></br>
 ............................................... .-||`|+~`..>~~..````~^`.. <br></br>
 ...............................................+v^>`.```--. .?w...```-|.. <br></br>
 ............................................`v^vv`..`````--.~?}~``.``vr.. <br></br>
 ........................................... |r-```. ``.....>-`````..`?}.. <br></br>
 ........................................... -+.```. ...||-.~?~`.`~-..`>.. <br></br>
.`...........   ........................... .|-`.`.. . +v?>- `>~`..?r..... <br></br>
.-``.........`|~........................  ..^--``->- ..`?v>+` .~-`-v?.. .. <br></br>
.|~``...... `rww> ..............`-~-```-...~|``vw^?| ....|?>-..``-`....... <br></br>
.>+|~``.... .^^|- ...............`-^>??>|`.~``vxw}`...........```......... <br></br>
.>>>+|-`..-~~~~``-||`.......... .`|?vvv?++??v}w}vv}}?` ................... <br></br>
.>>>>>+^-^++^~~``||~-....... ...~>?vvvvv??+-``?}}vv>^`  ......`........... <br></br>
.+++++++|-`-||``.``>|.... .....->??vvvvv??>|`+}>`......................... <br></br>
.|^^^^^^|~-->>+~``.+?..........``-|>??vvvvv>>+` .......................... <br></br>
.~~|||^^^^|~~|+?>-.`^~...........`~>?vvvv?>|~``````````................... <br></br>
.~~||^v}v?^~~~` +~.+^~`````-----~~|+>????>^|~|||||||||||~--````........... <br></br>
.----|vrzwv?^~`    >w?||>^||^^^^++++++>>>>+++++++>>>>>>>>++^||~-```.....   <br></br>
.+^-``|vwzzw}v?. . .}}?vwv++++++++++++++>>>+++>>>>>>>>>>>>>>>>+^|~-````~|+`<br></br>
`}}v>|^vrwzzzww~ -- ~rrwr}>^++++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>++?vvvvv-<br></br>
`rrwr}}wwrrrwzx? ~r- >zzrr?^+++++++++>>>>>>>>>>>>>>>>>>+++>>>????vv}}vvvvv-<br></br>
.?}wzzwwrrwwrrzw..ww` ?xrr>||^^^+++++++++++++++++++++++>??????v}}}vvvvvvvv-<br></br>
 .~>vrwwwzwzwwwx^ >xr..vw}>``--~|^^^^++^^^^+++>>>>>??vvvvvvvvvvvvvvvvvvvvv-<br></br>
.^^|+?}rwxzwrwzx} .vz? .?w?~...`````-~~^++>?vvvvv}v}}}vv}}}}}}vvvvvvvvvvvv-<br></br>
`rrvv}rrrwzzzzzzx~ -vr>  -.......`-^+>>???vvvvvvvvvvvvvvvvvv?????vvvvvvvvv-<br></br>
`rwrrwwwwzzzzxxxx}  `.?>. .|~~^??v}}v??vvvvvvvvvvvv??vvv??>>>??vvvvvvvvvvv`<br></br>
`wwwwzzzwzxxxzwwww~.``+>+>?vvvvvvvvvvv}vvvvvvvvvvvvvv?????vvvvvvvvvvvvvvvv`<br></br>
`rwrrwzzxxzzw}vvvv?vvv??vv}vvvvvvvvvvvvvvvvvv????????vvvvvvvvvvvvvvvvvv???`<br></br>
`rwwwwwr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv??>>>>??vvvvvvvvvvvvvvvv??????>>>+.<br></br>
`vvvvvvvvvvvvvvvvvv???vvvvvvvvvvvvvv??>>>>???vvvvvvvvvvv????????>>>>+++>>>`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>>>???v??????????????????>>++++++>>????`<br></br>


            </div>,

            <div>

 ................................................  ->?^..   ....`~-....... <br></br>
 ................................................``>+~`.>>>|..`~~-|^`..... <br></br>
                  ............................................``+-.`..```^-~r+ ..``-?|`.... <br></br>
 ...........................................~}+~. `--`~~. .>?``..`|v>.`... <br></br>
 .........................................|>|>~..`-~`.```^`...~^ .`^^..`.. <br></br>
                  ........................................ +^.`^-.`...-`..+v~.`|?^`.`-.``.. <br></br>
 .........................................``.-^`....>>+|. |?>-..|}. ..``.. <br></br>
 .........................................`...`.....|?>^+~..^^`.^+........ <br></br>
 ............   ......................  .`...-|?| .. ~>??+`..`|-...`-..... <br></br>
 ............`|~...................  ....`..-}?+-......`~``-`..``..``..... <br></br>
 .......... -www? ............````-|^|-.```-?}v?>~`.......``.............. <br></br>
 .........  .^^|- ..............-+????>|>>>vvv}wzr+. ..................... <br></br>
 .........~~~~~``-||`........ ..~>vvvv?>~``->r}>^|~-`..................... <br></br>
.-`......^>>^~~``||~-........`-^?vvvvvv?|`..|?~``````..................... <br></br>
.~-``....`--~|`````>|... ..`--|+????vvv?^`.`>~............................ <br></br>
.~~~~-``....+>+-``.+?...........`-^>?>+^~``-|............................. <br></br>
.~|||~~-```..-+?>-..+~............-~~~-```................................ <br></br>
                  .~||||++^|~```..+~ ``-..`````````````---```````````....................... <br></br>
.~~|||+>>>+|~-.    -^|~-||||||||||^^^^^^^^^^^^^^||~----`````............   <br></br>
.~~~~~|^>>?v?>|  . .>vv?>>++++++++++++>>>>>>>>>>>>++^||~--````.........``-.<br></br>
.|^??>^^+^>}wrv` |~ -}wr??++++++>>>>>>>>>>>>>>>>>>>>>>++^|~-```.`-^>??vvvv-<br></br>
.||?wwr}v>??vwx> ~r~ +zwv?+++>>>>>>>>>>>>>>>>>>>>>>>>>>>>>+++^+>?vvvvvvvvv-<br></br>
.|||>rzzw}}}}rzw..ww` ?xv?++++>>>>>>>>>>>>>>>>>>++++++>???vvvvvvvvvvvvvvvv-<br></br>
 `->>?}wzzzwrwwx^ ?ur. vw}>||^^^+++++++++++++++^+>>??vv??vvvvvvvvvvvvvvvvv-<br></br>
.||?v}}rwzxzwwwz} .rz?  ?w?~--~~|~~~~~|^+>??vvvvv}}}vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.vrrrrwwrrwzzzzzx~ ~}r>  -......`-~|>>??vvvv}}}}vvv?????vvvvvvvvvvvvvvvvvv`<br></br>
.>vrwzzzzzzwzxzwzv  `.?+. .`-|+??vvv???vvvvvvvv?????vvvvvvvvvvvvvvvvv?????`<br></br>
.-+}rwwwrwzzxzwwrr- .`>??>?vv}}v}vvvv}vvvvvvvvvvvvvvvvvvvvvvvv???????????v`<br></br>
 |>vrwzzzwwww}vvvvv?????vvv}vvvvvvvvv???vvvvvvvvvvvvvvvvv???>>>?????vvv???`<br></br>
.^?vwwr}vvvvvv?vvvvvvvvvvvvvvvv???????vvvvvvvvvvvvvvv???>>????????????????`<br></br>
.^>?vvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvv???vvvvvvvv??>>>???????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvv???????????????>>>>??????????????>+>????`<br></br>


            </div>,

            <div>

                  ................................................  ~?v+`.  .````-+^....... <br></br>
                  ...............................................`-~v?|`.???~.`````|^``````.<br></br>
 ............................................--?|`--`-`.^``z>..```-?^.````.<br></br>
                  ...........................................^u?|`.```---. `vv```.`^x?.````.<br></br>
 .........................................>v>v^...-````.-+`````` .->>..``` <br></br>
 ........................................ ??.```.....~`..>}~.`-?>`.-~..``` <br></br>
 .........................................~~.```. ..v??+` ^}>-..+z. ...... <br></br>
 ....................................... .-`..... ..>}??>- .+~`.>?........ <br></br>
 ............   ......................   ~-```^}> .. |v?>|..``-`.......... <br></br>
 ............`|~................... ... `|`.-zv>|  ....-`..``````......... <br></br>
                  .......... -www? ............--``-^+^-.`--`?zw?+~` ...................... <br></br>
 .........  .^^|- ..............~??vv?^~?vvwwr}rzr?. .......`............. <br></br>
 .........~~~~~``~||`.......  .`+?vvv?++-..`>rv^~-`....................... <br></br>
 ........^>>^~~``^|~-.......`-|>?vvvvv?+`.. +r- .......................... <br></br>
 ........`--~|`````>|... ..~|^+>????vv??`...?~ ........................... <br></br>
 ...........+>+-``.+?. ..........`+?>|-`...`|............................. <br></br>
 ............`+?>-..>~ ...........``........ ............................. <br></br>
                  .```.......... .+~ ..`.................................................... <br></br>
                  .-~~-``````..`.    .`-~-`````````````````................................  <br></br>
.~||~~~~~-|?>|`  `  ~?}?^^^^^^^^^^^^^^^^^|~--````...............       .`-.<br></br>
.~|??>+|~~|vw}?. ^~ `}wr?+++++++>>>>>>>>>>>++^|~-```........   .``~~|^>?v}-<br></br>
.~~>rwrv?^+?vwx> |r~ +zw?++++>>>>>>>>>>>>>>>>>>++^~-``.....`~^>???v?vv}vvv`<br></br>
.-`-^vzzw}}}}rzw..ww` ?x?>+++>>>>>>>>>>>>>>>>>>>>>+^^|~~^>?vvvvvvvvvvvvvvv-<br></br>
.``++>vrzxzrrrrx^ ?u}. vw}?++>>>>>>>>>>++++++++>????????v}}}vvv}vvvvvvvvvv-<br></br>
.||vwrrrrzxzwwwz} .rz?  ?w?|^+++++^^++++^^+>>?v}}vvv?vvvvvvvvvvvvvvvvvvvvv-<br></br>
.vrrrrwwwrwzwwzzx- ~rr>  -..`---~~|+>???vv}}}vv????vvvvvvvvvvvvv?vvvvvvvvv-<br></br>
 ~?rwzzzzzwrwzwrw?  `.v+. .`~^+??>>??vvv}vv???>??vvvvvvvvvvvv??????vvvvvvv`<br></br>
 .|vrrrrrwzzxwwwrr- ..>v>>?v}}}vvvvvvvvvvv?vvvvvvvvvvvv????>>???vvvvvvvvvv`<br></br>
 ~+?rwzzzwwww}vvvvv???vvv}}}vvvv???vvvvvvvvvvvvvvv???>>>????vvvvvvv?v?????`<br></br>
 ~>vwwr}vvvv?vvvvv}}}}vvvvv???????vvvvvvvvvvvvv??>>>>???vvvvv?????????????`<br></br>
.|+?vvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvvv?>>>>?????????????????????????`<br></br>
`}}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????>>>????????????????????????????`<br></br>


            </div>,

            <div>

 ...................................................`^>>~.   ````^+~...... <br></br>
                  .................................................`~+v+-~-+|`.`-``-^^`.... <br></br>
 ..............................................~|^~`~-`.``|~>?...``|~|..`` <br></br>
 ...........................................`|?}|~..````.. `vw^````>??`.``.<br></br>
 .........................................`~?>?^`..``````----~``...~>?```` <br></br>
 .........................................-~>-.`.........`+v~``~|~..`~```` <br></br>
 ..........................................`~`.`.. .~^v>^`.^v+`.`|?- ...``.<br></br>
 ..........................................-`........+}??^`.`>^..+v~....`` <br></br>
 ............   ..........................`~``-|>|....|v?>|...`-`......... <br></br>
                  ............`^~.....................``...|``|vv^~. ....~~`.`````......... <br></br>
 .......... -www? ............~|~~|+??>~.`--^?xr?~``...................... <br></br>
 .......... .^^|- ............`-^>vvvv>^|>?vwwrrrw}^. .................... <br></br>
 .........~~~~~``~||`..........`>vvvvv?>|`..->}v^~~`...................... <br></br>
 ........^>>^~~``||~-..........->?vvvvv?+~...+v`  ........................ <br></br>
 ........`--~|``.``>|..........`~|+??vvv??^`->- .......................... <br></br>
 ...........+>+-``.+?. ..........`^>?????>^`--............................ <br></br>
 ............`+?>-..+~ ...........~|-```--... ............................ <br></br>
                  ........... . .+~ ..`.................................................... <br></br>
                  ```.-|-...-~`.    .````.................................................. <br></br>
.-~-`|v??+|>v?^  `  -^>?+~~~~~~~--``````..........................   ...`~.<br></br>
.~|^^^>vvvv?vwr` ^| `?vr?+++++++++^^^|~~--````.............     ..-^>>??vv-<br></br>
.~~^>>?v}v}}}wx? ~r- ^wzv++>>>>>>>>>>>+^^|~~-````......  ..`-~~|+?vvvvvv?v-<br></br>
.>?>>>??}}rwwwww..ww` ?xv>+>>>>>>>>>>>>>>>++^|~-``.```-^>??vvvvvvvvvvvvvv}-<br></br>
.+?vvv?vvrwwwzzx^ ?u}. vwr?>>>>>>>>>>>>>+++++^^^+>????vv}}vvvvvvvvvvvvvvvv-<br></br>
.`->}wwrrwwwwwzx} .rz?  ?w?^^++++++^^^||^^++>?vvvvv??vvvvvvvvvvvvvvvvvvvvv-<br></br>
.?vv}rwwwwwwwzzzx~ ~rr>  -..`~~~~~|+>>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.+vwwwwwwwzzzzzwzv  `.?+. .-|^+>>>?vvvvvv?????vvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.>vrrrrwwwzxxzzrrr-..`>v??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvvvvvv?vvvv`<br></br>
.?v}rwwzzzwwr}vvvvv?vvvvv}}vvv??vvvvvvvvvvvvvv????????????vvvvvvvv????????`<br></br>
.>?vrrr}vvvv?vvvvv}}}vvvvv??????vvvvvvvvvv?????????????vvv?v??????????????`<br></br>
.^>?vvvvvvvvvvvvvvvvvvvv??vvvvvvvvvvvv????????????????????????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?v?????????????????????????????????????>>>.<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
 .......................................................`~+>~ ~++^^~`..... <br></br>
 ................................................`....`?v?>~`. ``-~^++`... <br></br>
 .............................................`-``~|^`^+~`..>~~..```-|^`.. <br></br>
 ...........................................-```+v^>`.```--. .?r...```~^.. <br></br>
 ........................................````-v^vv`..`````-~.~?}~`````v}`. <br></br>
 .........................................```^r-```. `-`....>-````-..`+v`. <br></br>
 ..........................................`.~+.```. ..`||-.~?~`..~-..`>`. <br></br>
 .............  .............................|``.`.. . +}?>- `>~`..?}....` <br></br>
 ............`|~...................`........^~--`->- ..`?v>+` .~``-??...`` <br></br>
 .......... `www> ..............-~^^-`-~-..||`-vw^?| ....|?>-..``-`...````.<br></br>
 .......... .^^|- ..............``~^>???^`.-`-vxw}-...........```...``````.<br></br>
 .........~~~~~``~||`............`^?vvv?+^>?v}w}vvvv?` .............``````.<br></br>
 ........^>>^~~`.||~-....... ...~>?vvvvv??+```?vvv?>^`  ......`...````````.<br></br>
 ........`--||``.``>|..........->??vvvvv??>|`>v>`.................````````.<br></br>
 ...........+>+-``.+v. .........``-+???vvv?>>+. ...................```````.<br></br>
 ........... `+?>- .^~ .........  .~>?v??>|`` .....................``````` <br></br>
                  .....  ....   .+~ ..`. ............`-|~`............................`````.<br></br>
                  ....-+~...|^..     ....................................................`` <br></br>
 ....|}r?+-^}v>. .  ..`?+...............................................`-.<br></br>
.`---->rzwv>?wx~ ~~ .^?r?~--````.................................`|+++>??v-<br></br>
.~||||+vwwzwrrx? ~w- ^wzv>+++^|~~-```..............      .....-^?v}vvv??vv-<br></br>
`vvv?>^>vrwzxwww..ww` ?xv?>>>>>>++^|-```........  ..-~^++>+>??vvvvvvvvvvvv-<br></br>
.?}wwrv?vrrwzzzx^ ?ur..vw}v>>>>>>>>>++^|-`.....``^?v}}vvvvvv}}vvvvvvvvvvvv-<br></br>
.-~>}zzwwwwrrzxx} .rzv .?w?+++++++++^^^|||||^>??vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>??v}rwzwwwwzxzx~ -}r>  -..-~~~-~^>>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
 >rwwr}wwrzzzzzzzv  `.?>...~|||^+?vv????vvvvvvvvvvvvvvvvvvvvvvvvvvv??vvvvv`<br></br>
`vrwrwwwwwzzxzwwrr-..`>?v?v??vvvvv???vvvvvvvvvvvvvvvvvvvvvvvvv???????vvvvv`<br></br>
`}wwrrwzzzzzwr}}vvvvv}vvvvvvvvvvvvvvvvv????vvvvvvvvvvvvvvvvvv????vvv??????`<br></br>
.v}}}r}r}v?vvvvvvvvvvv????vvvvvvvv???????vvvvvvvvvvvvvvvvvvvvvvv??????????`<br></br>
.^???vvvvvvvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvvvvvv??????????>>>???????`<br></br>
`v}vvvvvvvvvvvvvvvvvvvvvvvvv??????vv??????????????????????>>++>>>?????????`<br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
 .......................................................`~+>- ~+>+^~`..... <br></br>
                  .....................................................`?vv?~.  `--~^++`... <br></br>
 .................................................~^^`^+|-..>~~..````~^`.. <br></br>
 ...............................................+}^>`.``--`.  ?w...```~^.. <br></br>
 .....................................```````-v^}v`..`-``---.~?}-`````vr`. <br></br>
 ...................................````````.+r-```. ``.....>-`````..`?}`. <br></br>
 ..................................`````````.~+.```. ...||-.~?-```~-..`>.. <br></br>
 ............. .....................``````...|`..... . ^}?>- ->~`..?r..... <br></br>
 ............`|~................``````......+-``.->- ..`?v?>` .~``-v?..... <br></br>
 .......... `www>...............-|+^-`-~-..||``vw^?^ ....^?>-.```-`....... <br></br>
 .......... .^^|- ..............``~^>???^`.-``vxw}`...........```......... <br></br>
 .........~~~~~``~||`............`^?vvv?++??vrw}}}}}?` .............`..... <br></br>
 ........^>>^~~`.||~-....... ...~??vvvvv??+-``?}}vv?^`  ......`..``````... <br></br>
 ........`--||``.``>|..........~>??vvvvv??>|`>r>`...........`.``````````.. <br></br>
 ...........+>+-``.+v. .........``~>???vvv?>>>. .............```````````.. <br></br>
 ............`+?>- .^~ .........  .~>?v???|`` ..............`````````````` <br></br>
                  .....-~-..... .+~ .``.. ...........`~|~`....................`````````````.<br></br>
 ....`>??+||-.     `|`..`.........................................```````` <br></br>
 `...`+v}v??v?>.   .^^~??~.............................................``~.<br></br>
`??+|~^vwwr}}zx~ -- `?vrv>`......................................`-~|^>?vv-<br></br>
`vvvv??}wwwwwwx? ~w- +wz}?^-```.............................`-|>??vvvvvvvv-<br></br>
`v}}}}vv}rwzwwzw..ww` ?x}v>^|~~-```............    ..`--~+???vvvvvvvvvvvvv-<br></br>
.?v}rr}}}wwwzzzx^ ?xr..vw}v>++^^|~-```...... ..`~+??vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.+>?vrwrwwzwrwzx} .rzv .?wv+++++^^|~-````-||^>?vvvvv}}}vvvvvvvvvvvvvvvvvvv-<br></br>
.?vvvrrwwwwzzzzzx~ -}r>  -..-~~~-~|+>???vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
.vwwrrrwwwwzzzzxxv  `.?>...||~^+>?vvvvvvvvvv}}vvvvvvvvvvvvvvvvvvvvv?????vv`<br></br>
`}wwwwzwwwzxzzzwrw~.`-???????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????vvvv`<br></br>
`}wrrwwzxzzww}}vvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv?????vvvv????`<br></br>
`}rrrrrr}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvv??????????vv??????????`<br></br>
                  .??vvvvvvvvvvvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvvv???????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????>>>>?????????????>??????????`<br></br>


            </div>,

            <div>

 ..................................................~>?^`.  .`````^|....... <br></br>
 ...............................................`--?>|`.>??|..`~--|^``````.<br></br>
                  ............................................--?|.`````.^`-w+ ..``-?^...``.<br></br>
 ..........................................`|z>|` ---`-~. `v?.`..`|r>.`... <br></br>
                  .........................................^?^?|..`--`.```+-...~| .-++..`.. <br></br>
 .........................................?>.`~`.`...-`..>v~.`|?+`.`-.``.. <br></br>
 .........................................~~.-|.. ..??>^` |v>-..^w.  .``.. <br></br>
 .........................`````````.......``..`.... +v>++- .^|`.+>.....`.. <br></br>
                  ............. ..........````````````....-``.-^?| .. ~???^...`|-...`-..... <br></br>
                  ............`|~........``````---```.`````..-w?+~ .....--``-`..``..``..... <br></br>
 ...........-www?..........```|^|~|+>>~```-`?rv?+~`..... ..`.............. <br></br>
 ............^^|-..............`|?vvv?+~>??}}vvwzr>. .......`............. <br></br>
 .........~~~-~``~^|`..........`^?vvvv>>-..->w}+|~-``..................... <br></br>
 ........^>>^~~`.||~-.......`-|>?vvvvv?>~``.+v|-~~~--`.................... <br></br>
 ........`--||``.``>|.....`~|^+>????vvv?>|-`>^|-```````.``................ <br></br>
 ...........+>+-``.+?. ....`....`-+??>+^||~-|-`..````````````............. <br></br>
 ........ ...`+?>- .^~ ...........````-````......``````````````........... <br></br>
 .....>v?|. .. .+~ -~`.. .........................```````````````......... <br></br>
  ....?wxw?|..     +}^..-............................`````````````........ <br></br>
 -`.  .?wzzwv?+    .}}+>}>.............................````````````...`-~+`<br></br>
`r}?^--?rrzzzwz~ `` ~}rwrv` ..............................``...```-|>?vvvv-<br></br>
`rwwwr}wwwrrwzx? ~r- +wzr}~...............................`-~^>???v}}vvvvv-<br></br>
                  `vrzzzzwwwwwrrzw..ww` ?xrr|...........................`~+??v?vvvvvvvvvvvvv-<br></br>
.+?v}rwwwzzzwwwx^ >xr. vwr>`...........    ...`-~||^>?vvvvvvvvvvvvvvvvvvvv-<br></br>
.????vrrwxzwrwzx} .}z?  ?r?^-``....  ..``~^>?vvv}}}}}}vvv}}}}}vvvvvvvvvvvv-<br></br>
`rr}}rrrrwzzzzzzx~ -}r+  -..--````-|+>????vvvvvvvvvvvvvvvvvv?????vvvvvvvvv`<br></br>
`rwrrwwwwzwzzxxxxv  `.?+...||~+??vv}vvvvvv}}vvvvvvvvvvvv???????vvvvv????vv`<br></br>
`wwwrzzzwwzxzzwwww~.`->?>>??vvvvvvvvvvvvvvvvvvvvvvvvvv???vvvvvvv??????vvvv`<br></br>
`rwrrwzzxzzwr}vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvv???vv?vvvvvvvvv?????vvvvv???`<br></br>
`rwwwwr}}vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????????vvvvv?????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvv?vvvvvvvvvvvvvvvv????????vvv?????????????????>>>>>>>>?`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv???>????????????????????????>>>>>>>>>????`<br></br>


            </div>,

            <div>

                  ..................................................-^+~`.. ..````--```````.<br></br>
 .........................................```````-->^~`.^>>^..-|^|^^-`--``.<br></br>
 .........................................```--^~``````~+||?-  .``~>+~-```.<br></br>
 ..........................................`~?|-`.~||`~|. .|>`-..`~?|`-```.<br></br>
                  .........................................|+~^-.`+|^`.`.`|`...+?..`~~.`-`` <br></br>
                  .........................................^^.`?~||...``..^?-.-+v|`.``.^+`` <br></br>
 .........................................``.~?`....~^|-..-^>`..~?. ..~^`. <br></br>
 .........................................`..`|.....`|~~^~..|>`.||`....~.. <br></br>
 ....................``````..............`...~~+`... `|>v+-..`^~`. |>..... <br></br>
                  ............`|~...````````````````.........~v+~`.-`...`+^~|`..-```+^..... <br></br>
 ...........-rww>..```````````~|||^++|~--``~??>??|~`.....`~~`..`.......... <br></br>
 ............^^|-....``````````-|>??v??+>^^??>vzw}~............`.......... <br></br>
 ........`~~~~~``~^|`..........`|?vvvvv?|-|^?r}??>>+|..................... <br></br>
 ........^>>^~~`.||~-.......`-~+?vvvvvv?+^~`|++>>>+^~`........`........... <br></br>
 ........`--||``.``>|......`-~^>???vvvvv?+^~^+>^```....................... <br></br>
 ...........^>+-``.+?..........``-+???????>+^|`.```....................... <br></br>
 ............`+?>- .^~............`~|++++^~``.``````...................... <br></br>
                  .....-~-..... .+~ .``...............`---``````````````................... <br></br>
 .....-|>+-....    `|`......................`````````````................. <br></br>
 ...  .`^+>?>~`    .^??+|`.....................```````````.............`-~.<br></br>
.^^>+-`~||+}w}>. `. `vw}>-.........................```````.......`~+??vvvv-<br></br>
.+>vrr}}?>>?vwz> ~}- +zw?|...........................`..```-~|+>?vvvvvvvvv-<br></br>
.???vwzzw}rr}rzw..rw` ?x?^`..........................`-^??vvvvvvvvvvvvvvvv-<br></br>
.+>?v}rwzzwwwwwx^ ?xr. vwv-.............  .....`-~|^>?vvvvvvvvvvv}}vvvvvvv-<br></br>
.??v}}rrwzzzwwwz} .rz? .?w+`......   ..`-|+??vvvv}}}}vvvvvvv}}vvvvvvvvvvvv-<br></br>
`}rrrrrwrwwwzzzzx~ ~}r>  -.......``|+>??vvvvvvv}vvvv????vvvvv???vvvvvvvvvv-<br></br>
`v}rwzwzzzwwzzzwzv  `.?+...`~|>??vvv???vvvvvvvv?????vvvvv?????vvvvvvvv????`<br></br>
.>?}rwwwwwzzxzzwrw~..`>?>>?vvvvvvvvvvvvvvvvvvvvvvvvvvv??vvvvvvv????????vvv`<br></br>
.>?}rwzzzzwww}vvvvv?????vvvvvvvvvvvvv??vvvvvvv??vvvvvvvvvv?????????vvvv???`<br></br>
.>v}rrr}}vvvvvvvvvvvvvvvvvvvvvvv????vvvv????????vvvvv?????????????????????`<br></br>
.>??vvvvvvvvvvvvvvvvvvvvvvvvv??vvvvvv????????vvvvv????????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvvv?v?vv????????????????????????????>>???>>>????`<br></br>


            </div>,

            <div>

                  ...............................`..`.````````````````````````````````-----.<br></br>
 .......................````````````````````````-------`-^>?~ ~>>>+^~-~~~~.<br></br>
 .......................``````````````````````````````-v}v?~.  `--~^>+|~~~.<br></br>
 ..........................``````````````````````.|^^`^+|-..>~~..````~^~~~.<br></br>
 ............................````````````````...>}+>`.``--`. .?w...```~+~~.<br></br>
 ...................................````````.-v^}v`..```----.~?}-`````vr--.<br></br>
 ............................................+}-```. ``.....+-`````..`?}--.<br></br>
 ............................................~+.```. ...||-.~?-```~-..`>--.<br></br>
 ............................................|``.... . ^}?>- ->~`..?w ..`-.<br></br>
 ............`^|.................```........+~``.->- ..`?v?>` .~``-v?...`-.<br></br>
 ...........~rww?...............-|+^-`-~`..||.`vw^?^ ....^?>-.```-`...```-.<br></br>
                  ...........`^^|~.`.`````````..``-~^????^`.-``vxwv`...........```....`````.<br></br>
 ........`~|~~~``~^|-`````````...-+?vvv?^+??}rw}}}}}?` ..............`````.<br></br>
 ........^>>^~~`.||~-.`````...-|>?vvvvvv??+-``?}}vv?^`  ......`........... <br></br>
 ........`--||``.``>|.`.....``~>?vvvvvvv??+|`>r?`......................... <br></br>
 ...........^>+-``.+v.....`````~~|^>??vvvvv?>>`........................... <br></br>
 ............`+?>- .^~............->?vvvv?+~-............................. <br></br>
 ............. .+~ ..`...........``~|+>>+|-............................... <br></br>
                  ..........  ..     ..............````````````............................ <br></br>
                  ..    ...`+~.      .^v^...........`````````````..................   ...`-.<br></br>
 ..|+~`.  .>}?~  `` .?zv`..............```````````..........   ..`~|^+>?vv-<br></br>
 ..|}w}?+``->wz+ -v- +zr-..................````````........`~^>??v??vv}vvv-<br></br>
.--`^vzzwvvvvrzw..}w` ?x^....................`````````-~^>?vvvvvvvvvvvvvvv-<br></br>
.+>???}wzzwrrrrx^ ?ur..vwv` ..................``|+>?????v}}vvvv}vvvvvvvvvv-<br></br>
.??}wrrrrzxzwwwz} .rz? .?w~.............`-|++??v}}vvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.vrwwwwwwrwwwwwzx- ~rr>  -........`~^>??vv}}}vvv????vvvvvvvvvvvvvvvvvvvvvv-<br></br>
.>vwwzzzzzwwwzwrw?  `.?>. .`~^+??????vvv}vv??????vvvvvvvvvvvvv????vvvv??vv`<br></br>
.|>}rrrrwwzzxzzwrr- ..+??>?v}}}vvvvvvvvvvvvvvvvvvvvvvvvv???????????????vvv`<br></br>
.^?vrwzzzwwww}vvvvv????vv}}vvvvvvv??vv?vvvvvvvvvvvv???????????????????????`<br></br>
 ~>vwwr}vvvv??vvvvvvv}vv}vvv????????vvvvvvvvvvv???????????????????????????`<br></br>
                  .|+?vvvvvvvvvvvvvvvvvvvvv?????vvvvvvvvvvvvvv??????????????????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvv?vv???????????>>?????????????????????????????`<br></br>


            </div>,

            <div>

                  .............................```````````----``````~^+|`.. .````-|~~~|||||.<br></br>
 ................````````````````````------~~---~~->^~`.+>>^..-|^|^^^^++++.<br></br>
 ...............`````````````````````--------~~>~``````-+|~?~  .`-~>>>+^++.<br></br>
 ...............````````````````````````--`-|v^~`.~~~`~|...|>`-..`~?+|^^^^.<br></br>
 .................````````````````````````^>|+-.-^|^`.``-^`...+?..`|^-~^^^.<br></br>
 ....................`````````````````````+^.`>~~^`..``..^?-.`+v|`.`~-??~|.<br></br>
 .......................``````````````````-`.~?`....~^|-..~+>`..~?. .-+?~~.<br></br>
 .............................``......`...``.`~.....`+|~^~..|>`.|^``.`-+--.<br></br>
 ........................................`...-~+`... `|>v+-`.-^-...>?..``-.<br></br>
 ............-^|............................~v+|`.``...-+^||`..```->>.```-.<br></br>
 ...........~www?.............`---|++^-````-??>??~~`.....`|-`..``-`.```-``.<br></br>
 .....``....`^^|~..............`~+?????++^^??>vzz}|...........`-``````--``.<br></br>
 ....```.`~|~~~``~^|-...``.....`|>?vvv??~-~|?wr??>>+~...........``````--``.<br></br>
 ........^>>^~~``||~-.```...`-|>?vvvvvv?^|-.|>++>++^~`........````````````.<br></br>
 ........`--||``.``>|.....`-~|+????vvvvv?+|~^^>|`...............``````````.<br></br>
 ...........+>+-``.+?....```````-~+??????>+^+~............................ <br></br>
 ............`+?>-..+~..........``-^^>>>+^-``............................. <br></br>
                  ............. .+~ ..`.....``````````~~~-`................................ <br></br>
                  .....`.....`..    ..........```````````.................................. <br></br>
 .....~~-`.|+^`     .->>-.....```````````.........................    ..`-.<br></br>
 ..``.`~|~-|?}?` `` .^v}|.......```````````.................   ..`-^+>>?vv-<br></br>
 ..`~^^>?+>>?wx> ~}- |ww^...........```````................`~|+>?vvvvvvvvv-<br></br>
.^^|~^>>vv}wrwzw..rw` ?x>`.............``````........``~^>?vvvvvvvvvvvvvvv-<br></br>
.>?vvv??vrwwwzzx^ ?ur. vrv~...................`-|>????vv}}}}vvvvvvvvvvvvvv-<br></br>
.>?vrwwwwwzwwwzz} .wz?  ?w^.............`-~|^??vvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.vr}rrwzwwwwwzzzx~ ~rr>  -........`~^>??vvvv}vvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?}wzwwwzwzzzzzwwv  `.?+. .`~^^+>>?vvv}vv??????vvvvvvvvvvvvvvv????vvvvvvvv`<br></br>
.?vrwwwwwwzzxzzwrr- .`>???vv}}vvvvvvvvvvvvvvvvvvvvvvvvv?????????vvvvv??vvv`<br></br>
.?v}rwwzzwwwr}vvvvv?vvvvv}}vvvvvvvvvvvvvvvvvvvvv????????????vvvvvvvvv?????`<br></br>
.>?vrr}}vvvv?vvvvv}}}}vvvvv???????vvvvvvvvv?????????????vvvv?vvv??????????`<br></br>
.^>?vvvvvvvvvvvvvvvvvvvv???vvvvvvvvvvv?????????????vvv????????????????????`<br></br>
`}}vvvvvvvvvvvvvvvvvvvvvvvvvvvv?????????????>?????????????????????????????`<br></br>


            </div>,

            <div>

                  ...........................``.``````````---------`|?v+`.  .````-++||^++++.<br></br>
 ..............`````````````````--------~~~~~~~~||~v>|`.???~.````-|^+>????`<br></br>
 .......`.````````````````--------------~~~~~^|?^`--`-`.^``z> .```-?>>>>>>`<br></br>
 .......``````````````````------------------+x?|`.```---. `v?.``.`^x?+>>>>.<br></br>
 .......`````````````````````-------------?v>v^...`````.`+``````..->?^++++.<br></br>
 .......`.```````````````````````---------v?.```.....~`..>}~``-?>-.-+^^^^+.<br></br>
 .........```````````````````````````----`|~.```. ..v??+` ^}>`..+z. .~^^^^.<br></br>
 ............`````````````````````````-`..-``.... ..?}??>- .^~`.>?.`-~~~--.<br></br>
 ..................``````````````````....~-``-^}> .. |v?>|..``-`..`~~~~~--.<br></br>
 ............-^|`............`--``...```-~`.-zv>| .....-`..````.``~-~-~---.<br></br>
 ...........~www?............`|^~-~+>>~.`--`?xw?^~` ...........`--------`-.<br></br>
 ...........`^^|-..............`^?vvv?^~?vvwwr}wzr?. .......``-~~~~-----``.<br></br>
 ........`~|~~~``~^|`..........~?vvvv?++`..`>wv^|-`......```--~---------``.<br></br>
 .``````.^>>^~~``^|~-......`~|+?vvvvvv?+`...+r~...`````-----------------`-.<br></br>
 .``````.`--~|``.``>|.....`^++>?????vvv?|`.`?|...```````````````--------``.<br></br>
 ......``...+>+-``.+?`...``--`.``~>?>+|~`..`|...........``````````````````.<br></br>
 ............-+?>-..>~............--`........................````````````. <br></br>
                  ...............+~ .`-`................................................... <br></br>
                  ....~+-...|^`.    .```-`````............................................. <br></br>
 ....-}}?^.~vv^     ``-v>```````.........................................`.<br></br>
 .....~}zw?~^wx- ~-  `>w>````````.............................. ..~^>>??vv-<br></br>
 .   ..^rwzrvrz? |r- -rz?``````````...................   .....`|>v}vvv??vv-<br></br>
.??>~. .>}wzzwww..ww` ?x?-`..```````............  ..``|+>>>???vvvvvvvvvvvv-<br></br>
.?vwwv?^^vrwzxxx^ ?ur. vrv+.....```````........`-^?vvv}vvvvv}vvvvvvvvvvvvv-<br></br>
.~|>}zzwwwrrrzxx} .rx?  ?w>...........```--~^>??vvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.?vvvrwzzwwwwzxzx~ -rr>  -........-|+>??vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>
.vwwwwrwwrzzzzzzwv  `.?+...-~||^>?vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv`<br></br>
`}wwwwwwwwzzxzwrrr- .`?????vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvv`<br></br>
`}wwwwwzzzzzw}}}vvv?v}vvvvvvvv?vvvvvvvvvvvvvvvv???vvvvvvvvvvvv???vvv??????`<br></br>
`v}}}}}rvvvvvvvvvv}}vvv??????vvvvvvvv???vvv?????vvvvvvvvvvv??vv???????????`<br></br>
                  .+?vvvvvvvvvvvvvvvvvvvvvvvvvvvv????vvv??????vvvvvvvvvv????????????????????`<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????????????????>>??????????`<br></br>


            </div>,

            <div>

                  .``````.................................``````````-^+~`    .....`--~~~~~-.<br></br>
 ``````........```````````````````--------~~~~~~||~?>|-`>>>~..`---~++>>>+|.<br></br>
 ``......`````````````````-----------~~~~~|||^|?^`-~-~``^--v|  .``->+>>>+~.<br></br>
 .......``````````````````------------~~~~~~^r>|-.-|~`--. .>?.`..`~v>|+>^~.<br></br>
 .......````````````````````---------~~~-->?^?|.`-~~`...`|`...~| .`|+|^+|-.<br></br>
 .......`.`````````````````````----------->+.`>-.-...-...+?-.`|?^`.`|~+>|-.<br></br>
 .........``````````````````````---------`~-.~+.....+>+-. ~?+`..|v. .`^>~`.<br></br>
 ........`````````````````````````-----`..``..-.....~>+^|`..|^`.|+...`-|-` <br></br>
 ...........`...``````````````````````..`-`..`|?-... -+>?|`..`|`...|+..``` <br></br>
 ............-^|`..........`.`---``..`````..~v>|`.... .-~-`-`..````^|.```. <br></br>
 ...........~www?............`~|~-|+>+~```--?v??>-`.......``....``````--`. <br></br>
 ...........`^||-..............`|>?vv?>^>++???vrw}|. .........```````--``. <br></br>
 ........`~~~-~``-||`..........`^?vvvv?>-``->r}+^|~-`...........````--```. <br></br>
                  ........^++^~~`.|~~`........`-^?vvvvv??~-`.|?^||||~-`....```````````````. <br></br>
 ........`--~|``.`.+~......``-|+????vvv?>|~-+^|~````````````````---``---`. <br></br>
 ..``````...+>+-``.+?...........`-^>>>++^||~^-```````````````````````````. <br></br>
                  ......```...-+?+`..^~............`-~~~--`...........```````````````````.. <br></br>
                  .....`-```.... ^- ```................`................................... <br></br>
 ....`>^|-`|^`.    `|````................................................. <br></br>
 ....`?vv>|+v?^    .|~~??-................................................ <br></br>
 .....-vwrv>?}w- ~- .^?}?|`.......................................--~^>??^.<br></br>
 .....`>}rwr}}w> |}- ~}w?^``............................ .....-^??vvvv???+.<br></br>
 ->?+-``?vrwwrrr..rr` ?z?^``....................  ..``~~|++>>?vv???vvvvv?+.<br></br>
 `+v}v?+?v}rwzzx^ >z}. ?v?^``.............   ..`-^??vvv??vvvvvvvvvvvvvvv?+.<br></br>
 ..~?}rrrrr}rwzzv .vw?  >}>`............`--~|+???vvvvvvvvv??vvvvvvvvvvvv?+.<br></br>
 ~>??v}rwwwrwzzzz~ -v}>  `........-|+>???vv?vv?vvvvvvvvvv?v??vvvvvvv?????^.<br></br>
.+vrrr}rrrwzwwwzwv  ..?^...`-~|^>?vvv????vvvvvvvvvvvvvvvvvvvvvvvvv???????^.<br></br>
.+vrrrwwwwwzzzwr}}-..`>???????vvvv????vvvvvvvvvvvvvvvvvvvvvvv???????????>^.<br></br>
.+vrrrrzzwwwrvvvvv??vvvvvv?vvvv?vvvvv???????vvvvvvvvvvvv????????????????>|.<br></br>
.|?}}}v}vv????vvvvvvv??????vvvvvvv????????vvvvvv????????????????????????>|.<br></br>
 `^?????vvvvvvvvvv??????????????>>???????????????????????????>++^^+>>>>>+|.<br></br>
                  .|++++++++++++++++++++^^^^^||~~~||^^^^^^^^^^^|||||||||||~~~--```--~~~~~~~` <br></br>


            </div>,

            <div>

                  .-------`.                                                                 <br></br>
                  .~|~~-`.........................```````---------~--~~----~^^` `~~~~~----.  <br></br>
                  .~``....................``````````----~~~~~|||||^^||^|-???+`.  ...`~|+^~`  <br></br>
                  .....................`````````````----~~~~~|||-^+^`~~`...^--......`~-.  <br></br>
  ......................``````````````-----~~~~-+v|+........  >}......`~.  <br></br>
                  ......................````````````````-----~v|??`.......`..-?v`.....??`  <br></br>
                  .........................``````````````````^v`.... .......^`........^?.  <br></br>
  ..........................`````````````````-|.....  ..~`. ->`...``...+.  <br></br>
                  ............................````````````...~`.....   |?^|. `+-...?v ...  <br></br>
                  ...........`|~................`````.......^`...`+` . `??+|. .-`.`??....  <br></br>
  ..........`v}v+...............`~^|```~-..~-..?v|>~  . .|>^`....`....`..  <br></br>
  ...........~~--...............``-|>??>~`.`..?}v?. ..... ...........``..  <br></br>
  ........---```.-~~`.............|>vvv>~|>>??v?????>` .............`....  <br></br>
  .......~^^~--`.~~-`..........`~+??vvv?+^|```+v??>+|.  ......`..........  <br></br>
  ........``--.....^-..........-+>???vv??+|~`^v+`........................  <br></br>
  ..........|^|`...^>...........``-^>??????+^^`..........................  <br></br>
  ...........`|+^`..~-.............-^>???>~``............................  <br></br>
  ....+>+~....  |` ```..............`---``...............................  <br></br>
  ....+v}v>~`..    |?~..`................................................  <br></br>
  .....^?v}v?+^.   .?>~^?|...............................................  <br></br>
  -^-.`>?vv}}vv- .` `??v?>`.........................................`~^^`. <br></br>
  ~????vvv??vvr> -?` |vv??-.................................`-~^^^+>???>`. <br></br>
  -?vvvv??vv??vv .vv` >r??-....................     ....`|+>>+>>>>?????>`. <br></br>
  .->?vvvvvvvvv}| +}v. ?v?~..............  ...``--~~^>>>>>>>>??????????>-  <br></br>
  ..`~??v}}v?vv}? .?}?  +?+`.............`-~|^>??????????????????>>>>?>>`  <br></br>
  -^>>???vv}}vv}r- `??^  `........`-~^++^+>>>>>?>>>>>>????>>>+++>>>>>>>+`  <br></br>
  -???vvvvvv}}}rr?  ..>^. .```~^>>??>>>>>???????>>>>>>>>+^^^^+>>>>++^^+^`  <br></br>
  ~??v}vvv}}}vvvvv`..`^^|~|^>>>>>>>>?????>>?????>>>>>>+++>>>>>>+^^^^^+++`  <br></br>
  -??vv}r}}vv??>>>>>??>>>>>>>>>>>>?>>>>>>>>>>>++>>>>>>>>>>>>++^^^++++++^`  <br></br>
  ~vvvv???>>>>??????>>>>>>>>>>>>>>>>>>>>++++^^+>>>>>>++^^^+++++++++++^^|`  <br></br>
                  .~^^^^^^^^+^^^^^^||||||||||^^^^^^^^|~~~~~|||||~~~-----~~~~~~~~------``.  <br></br>
                  .................................  ........       .                   <br></br>


            </div>,

            <div>

                  .~|||||~`.          .......                         ...................... <br></br>
                  .^^^|~`...........`````.............................-^^||^++-.~^^++|||||-. <br></br>
.~-`..........``---``..........`````````````````````~~->?>^`.. ``-~^>>>^-. <br></br>
                  .........``---`..............``````````````-````~~~`--~~..|--..`-`~|+^-. <br></br>
  .....``-~-``.................`````````````````~>~|..`.`-`.  +v..``~~^^-. <br></br>
  ..`----`......................``````````````>-^+....``````.`>?-```~|?v~. <br></br>
 ..`-``.............................````````.->`.... ````...|-..``-``|>?~. <br></br>
 ............................................`~..... ...-``.->`.``--``~>-. <br></br>
  . .........................................-`..... ..|>|~`.`^~`..>}..-`. <br></br>
  ............-`............................~`....~` ..`>>|~`..-```??.--`. <br></br>
  ..........`>??|................`--`.```..--..>?~|` ....~+^`..````.`~~~`. <br></br>
   ..........````.................`-^++^-`....>?++. .........```````~|~~`. <br></br>
   .......`-```..`~~`.............`|???^-~^^+>+^^^^^|`............``-~~~`. <br></br>
   . ....-~|~``..---`...........`-^+>???|~~...|+^||~-`........`..```-~~-`. <br></br>
     .  ...```.....``...........-|^+>??>^~-`.~+~.................```----`. <br></br>
           .--`... `-..............-|^^+>>^~-~.............``.....``----`. <br></br>
                  ..... .....`--. ...  ............`|^^|-`.......................``----`. <br></br>
                  ...`~`.....  -` `... .............```.....................```````----`. <br></br>
                  ....`~^|~`...    ~+`.....................................``````````---`. <br></br>
  .....`~+>>^||. . .+|`^^`............................```````````````-~~-. <br></br>
  .`...`~|>??>?- .. `^~>>~............................`````.....``-|>???^` <br></br>
  .~|~~|||+>???^ `>` -~^++. .............................```-~|++>???v??^` <br></br>
  .~||||~|>>>>??..??` ~+~|`...........................`~|+>>>>???????vv?|. <br></br>
  ..-||||^>?????~ |??..|^^`....................`````~>??>>>>>????????v?>`. <br></br>
  ...`-~|+??>???? .+?+  |>|`.............`-~^||^++++>????????????>???>+|`. <br></br>
  .---~~~^>??????` .+>|  `.........```---~||^||^^^^|^>?????>>+++>>>++^^|`. <br></br>
  .--~~|||^^++++>^  ..~`.  ....`--~~-```--~----~---`|???>>++>>???>+^^^|~`. <br></br>
  `~~|^^|^^^^^||||. ...`..``-``--``-------------````|??????????>^||||^^+~. <br></br>
  .-~||^^^^||~---```-````----```-----------`````````-????????>^|||||^+??|. <br></br>
  .~|||~~--------`---``````-----------````.....`````-??????>^|||~~|^>>+~.  <br></br>
  ...``````````````....``````````````...............`+>>+|----```-~||-`..  <br></br>
                  .....      ..        .                   .``.............       <br></br>


            </div>,

            <div>

.????v??>|`-----````~+vww}?~.                       >wwwwwwwwwwwwww?rwwwww~<br></br>
`}}}v?+|~~~~~---~^?rxuzv^`.                         ?yuxxuuuuuuuuuy}uyyyyy^<br></br>
`?>~--~~~----|>vzyu}>~.   ........................``>vvvv??vvv}}?vwvuyuuuy^<br></br>
 -~------~+?wuyzv^`   .......................``-....+?^-?z>>???r??w}uyuuuy^<br></br>
.-~~-~^?}xyu}>-.   ........................``|~. ...^v?>vrv??>vxv?w}uyuuuy^<br></br>
 -^>vzyyz?^`   ............................-`... ...|?>r}???v+>vvvwvuyuuuy^<br></br>
`ruyx}>`   .....................................  .~>}>>r}?+>z^^?vz}uyuuuy^<br></br>
-w?|.      .................................. ...  `?z}?+?v>>v>??vz}uyuuuy^<br></br>
           ..................................-``. . ~}v????????vvvz}uyuuuy^<br></br>
           .......`^|`...............```....`+~`... `>>>?????????vz}uyuuuy^<br></br>
             .....................`-~|~`.``-||-~-`. `>>>>?????????z}uyuuuy^<br></br>
             ...```...``....... ..`|^+^`... .~`.... `?>>??????????w}uyuuuy^<br></br>
             . .```......... .......`~|~~-..`` .... -?????????????w}uyuuuy^<br></br>
                  ....  ....  ..... ..`~|~-......... -?????????????wruyuuuy^<br></br>
                  ...  ..   ...................... -?????????????wwuyuuuy^<br></br>
                  .   . . ...................... `?????????????rwuyyyuy^<br></br>
                  ..      ....      ................ `?????????????wwuyyyuy^<br></br>
          ....  .``.  .  `~.                      . `>>>>>>??vvv}rxrxyyyyy^<br></br>
          .`---...`|- `` .|.  ..      ........    . `???v}}rwwwzzzuwxyyyyy^<br></br>
                  ..~||~~-~^..|` -~.............     ......~wwwzzzzwwzzzzuwxyyyyy^<br></br>
                  .--`~|^|~~|- ~|. -~.................``````^zzzzzzzzzwwzzuwxyyyyy^<br></br>
                  .~||||||||^| .~~. .. .........``----``````|wzzzzzzzwwwzzuwxyyyyy^<br></br>
                  .~||||||||||`  .-. ....``````--```````````^zwwwwwwwwwwwwuwxyyyz?.<br></br>
                  .`~|||^^|~~--...````-----`````````````````|wwwrrrrrrrrwwuwxyx?~-.<br></br>
                  .~||~~--`````````---`````````````````````.~rrrrrrrrwwwrwuwz?|-~~.<br></br>
                  .```````````````````.....``````````.......-wwwwwwwwwwwwzz?|~~~~-.<br></br>
                  `uuuuuuuuuuyx?~--~--^v|<br></br>
                  `uyyyyyyyyxv|`-~--|vuy^<br></br>
                                                    `uyuuyyyxv|--~--|?xyz>.<br></br>
                                                    `uyuyyxv|--~--~?xyz>.. <br></br>
                                                    `uyyxv|---~~|?xyz>`..` <br></br>
 ..                                                 `xxv|`----->rux?`..... <br></br>


            </div>,

            <div>

.???????>|`------```~+vww}?~.                       >wrrrrrrrrrrrrr?rrrrrw~<br></br>
`}}vv?+|~~------~+?rxuzv^`.                ........ ?yuxxuuuuuuuxzxvxuuuuu|<br></br>
`?>~--~~--``-|>vxyur?~.  .........................``>v}r}vvrrrwrv?rvxxxxxx|<br></br>
 --``-``-~+?wuyz?^`. ........................``-..`.>v>+vx?vvv}wv?}vzxxxxx|<br></br>
.~~`-~^?}xyu}>-.  .........................``|~.....+wvv}z}}}vrxv?}vzxxxxu|<br></br>
 -|+vzyyw?|`   ............................-`.......+v?wr}}}}??rv?}vzxxxuu|<br></br>
`ruyx}>-.  ................................`.......~>rvvww}??x??v?}vzxxxuu|<br></br>
`w?|.    ..................................`.......`?zwv?}wvvrvvv?rvzxxxuu|<br></br>
          ...................................-``... ^rr}}}rr}v}}v?}vzxxxuu|<br></br>
          .........-`. ..............```....`+~`....~vvvv}v}}}}}v?}vzxxxuu|<br></br>
          ...     .................`-|-`.``-||-~-`. ~vvvvvvvv}}}v?}vzxxxuu|<br></br>
    ...        ..`....``.....  ....`|||`... .~`.... ~vvvv}}}}}}}v?}vzxxxuu|<br></br>
               ............     ....`-~~-`..``......|}}}}}}}}}}}v?}vzxxxuu|<br></br>
                 ..........        ..`~~~`......... |}}vvvvvvvvvv?v}zxxxuu|<br></br>
                   ...  ..        ................. |}vvvvvvvvvvv?v}zxxuuu|<br></br>
                     .   .      ....................~}vvvvvvvvvvv?}rxuuuuu|<br></br>
                  ..      ...     ...................-vvvvvvvvvvv??rrxuuuuu|<br></br>
           ...   .`.     .`..      ................ -vvvvvvv}}}vvrxrxyuuuy^<br></br>
           .``....`-` .. .-.      ................. `vv}rwzzzwwwzzurxuuuuy^<br></br>
                  ..`-````-- .-. ``........................~wzxxxzwwwwzzxurxuuuuy^<br></br>
                  ....`---``~` ~-. `-................```-```^xxxzzwwwzzzzzxrxuuuuy^<br></br>
                  ..       ..````------ .-`. ...........```---``````-+xzzwwwzzzzwrrxrxuuuyy|<br></br>
                  ....     .```------``.  .`. .......```---``````````^zwwwwwzwwrrrrzrxuuyz?.<br></br>
                  ....   ..```````````......```....`------------``.~rwwwwwrrrrrrrzrxyz?~-.<br></br>
     ......`````....................-~--~~-----``...~zzzwwrrrrrr}rxww?~-~~.<br></br>
                  ..``.......................`-----```.......~zzwrrrrrrrrrww?|~~~~~.<br></br>
                  ......                    ........    ... -xxxxxxxxxxuz?~--~|~^v|<br></br>
                  ....                   .....     ..... `uuuuuuuuyxv|`--~~|vuy|<br></br>
               ....                 ....   ......   `uuxxxuyx?|-----|?xyz>.<br></br>
                 .....              ...  ......     `uuxuux?|----`~?xyz>`` <br></br>
                    ....               .....        `xuuxv|----`~?zyz>```-.<br></br>
 ..                   .....           ....          `zxv|`----->rux?`.```` <br></br>


            </div>,

            <div>

 ..................-+????????????????>|---```~+?}}vvvrrrrrrrrrrw}.         <br></br>
 ..............`|>?v}}}}}}}}}}}}v?>^|---~|>?rzxxzxrxyyyyyyyyyyyyx.         <br></br>
 .``........-^?v}}}}}}vv}}}}v?>^~----~+?}zxxxxuuyywxyuuuuuuuuuuyx.         <br></br>
 `+`....`-^?v}}}}}vv}}}}v?>^|----~+?}wxxxxuuyyyyyyzxyuuuuuuuuuuyx.         <br></br>
.?~..`-+?v}}}}}}}}}}vv?+|----~+?}w}vxxuuyyyyyyyuyyzxyuuuuuuuuyyyx.         <br></br>
`+`~+?v}}}}v}}}}vv?+|----~+?}r}?|. `uyyyuuuuuuuuyyzxyuuuuuuyyyyyz.         <br></br>
`??}}}}}}}}}vv?+|--`-~+?v}v?~.     `yyyyyyyyyyyyyyzzyuuuuuuyyyyyz.         <br></br>
`}}}}}}}}v?>|~-`-~+?v}v>~.         `xxxxxxxxzxxxxxwzyuuuuuuyyyyyz.         <br></br>
`v}}}v?>^~---~^?v}v>~.             `vvv}vrrr?vrrr}}zyuuuuuuyyyyyz.         <br></br>
`v?>^~-`-~^?vvv>~.    .......      `}v}}v}}v?}v}}r}zyuuuuuuuuuuyz.         <br></br>
.~---~^>vv?+-.     .........       `r}}v}}v}}}}vvw}wyuuuuuuyyyyyz.         <br></br>
.-^>?v?^`.       ..  .......       `}}vvvrrv}}vvvv}zyuuuuuuyyyyyz.         <br></br>
.|>^`.             .`.  .... ..... `ww}v?v}rvvvvvv}zyuuuuuuyyyyyz.       . <br></br>
..               .......     ..... -zxz}??v?vvvvvv}zyuuuuuuyyuuyz.     .`-.<br></br>
..               .......     ..``..`wwr}v?vvvvvvvv}zyuuuuuuuuuuyz.   .`--^`<br></br>
..                 ... .      .....`vvvvvvvvvvvvvv}zyuuuuuuuuuuyz. .`--^vu^<br></br>
..              .               .  `vvvvvvvvvvvvvv}wyuuuuuuyuuyy}.`-~>ryuw-<br></br>
..             ......   ..         `vvvvvvvvvvvv}}rryuuuuuuyyyw>--~>wyurvv-<br></br>
..              .```` . `.         `vvvvvvv}}rwzxxzryuuuuuyyr>-`~?zyx}vv}}-<br></br>
.`            .`.```-.``...        `v}rwzzxzzxxxxxzryuuyyu}+-`|?xyz??v}v}}-<br></br>
.-..          .`````-` `. . .......~zzzzxzzzzzzzzxwryyyuv^--^vuyw>|?}}}v}}-<br></br>
`}+-`.       .````````.............~zzzzzzzzzzzzzzwryxv^`-+}uyr+..^v}}}}}}-<br></br>
`zyw?~`..     .````................~zzzzzzzzzzzzzzzv?|`->ryy}^....-v}}}}}}-<br></br>
 `+}yxv^``.   .................... -xuuuuuuuuuuuuw?-`~?wyyv|......`?}}}}}}-<br></br>
 ...~vxyr>-`..                     -uyyyyyyyyyyw>-`|?zyuv~........`+}}}}}}-<br></br>
 .....`>zyz?|``.                   -uyuuyyyyyw>~`|vxyu?-...........>v}}}}}-<br></br>
 ........^}uu}+-`..                -uyuuyyu}+--+vuyx?-.............`?}}}}}-<br></br>
 ..........-?xyz?|-`.              -uyyyu}^-->}uyz?`...............~}}v}}}-<br></br>
 ............`+ryuv+-`..           -yyuv^-~>ryyz>`.................~}}}}}}-<br></br>
 ...............|vuyw?~-`.         -xv|`~?zyyw>`...................-v}}}}}-<br></br>
 .................-?zyxv^-`..      `|-^vxyyr+`.....................`v}}}}}-<br></br>
 ...................`+ryu}+~-`.  .`-~vzuuw^........................`vvvvvv-<br></br>


            </div>,

            <div>

 ..................-+????????????????>|`--```~+?}rvvvrrrrrrrrrrw}.         <br></br>
 ..............`|>?v}}}}}}}}}}}}v?>^|---~|>?rzxxzxrxyyyyyyyyyyyyx.         <br></br>
 .``........-^?v}}}}}}vv}}}}v?>^~----~+?}zxxxxuuyywxyuuuuuuuuuuyx.         <br></br>
 `+`....`-^?v}}}}}vv}}}}v?>^|----~+?}wzxxxuuyyyyyyzxyuuuuuuuuuuyx.         <br></br>
.?~..`~+?v}}}}}}}}}}vv?+|----~+?}w}vxxuuyyyyyuyyuyzxyuuuuuuuuyyyz.         <br></br>
`+`~+?v}}}vvv}}}vv?+|----~+?}r}?|. `uyyyyuuuuuuuuyzxyuuuyyyyyyyyz.         <br></br>
`??}}}}}}}}}vv?+|--`-~+?v}v?~.     `yyyyyyyyyyuyyyzzyuuuuuuyyyyyz.         <br></br>
`}}}}}}}}v?>|~-`-~^?v}v>~.         `xxxxxxuxzxxxxxwzyuuuuuuyyyyyz.         <br></br>
`v}}}v?>^~---~^?v}v>~.             `}rvrrv?rv}rwr}}zyuuuuuuyyyyyz.         <br></br>
`v?>^~-`-~^?vvv>~.    ......       -wvv}}vv}}}vww}}zyuuuuuuuuuuyz.         <br></br>
.~---~^>vv?+-.     ......          `}v?vw}vr}vr?v}}wyuuuuuuyyyyyz.         <br></br>
.-^>?v?^`.       ..  ...           `vr??rwvv}vvvvv}zyuuuuuuyyyyyz.         <br></br>
.|>^`.             .`.      ...... -xwv??vvvvvvvvv}zyuuuuuuyyyyyz.       . <br></br>
..               .......     ..`...-xwrv?vvvvvvvvv}zyuuuuuuyyyyyz.     .`-.<br></br>
..               .......   ....... `rvvvvvvvvvvvvv}zyuuuuuuuuuuyz.   .`--^`<br></br>
..                 ... .      ...  `vvvvvvvvvvvvvv}zyuuuuuuuuuuyz. .`--^vu^<br></br>
..                                 `vvvvvvvvvvvvvv}wyuuuuuuyuuyy}.`-~>ryuw-<br></br>
..             ......   ..         `vvvvvvvvvvv}v}rryuuuuuuyyyw>--~>wyurvv-<br></br>
..              .```` . `.         `vvvvvvvv}wzzzxzryuuuuyyyr>-`~?zyx}vv}}-<br></br>
.`            .`..``-.``...        `v}rwzzzzzxxxxxzryuuyyu}+-`|?xyz??v}v}}-<br></br>
.-..          .`````-` `. .........~zzzxzzzzzzxzzxwryyyuv^--^vuyw>|?}}}v}}-<br></br>
`}+-`.       .````````.............~zzzzzzzzzzzzzzwryxv^`-+}uyr+..^v}}}}}}-<br></br>
`zyw?~`.      .```.................~zzzzzzzzzzzzzzzv?|`->ryy}^....-v}}}}}}-<br></br>
 `+}yxv^``.    ................... -xxxxxxxuuxuuuw?-`~?wyyv|......`?}}}}v}-<br></br>
 ...~vxyr>~`..                     -uuuuuuuuyyyw>-`|?zyuv~........`+}}}}v}-<br></br>
 .....`>zyz?|``.                   -uuuuuuuyyr>-`|vxyu?-...........>v}}}v}-<br></br>
 ........^}uu}+-`..                -uyuuyyu}+-`+vuyx?-.............`?}}}}}-<br></br>
 ..........-?xyz?|-`.              -uyyyu}^-->}uyz?`...............~}}v}}}-<br></br>
 ............`+ryuv+-`..           -yyuv^-~>ryyz>`.................~}}}}}}-<br></br>
 ...............|vuyw?~-`.         -xv|`~?zyyw>`...................-v}}}}}-<br></br>
 .................-?zyxv^-`..      `|-^vxyyr+`.....................`v}}}}}-<br></br>
 ...................`+ryu}+~-`.  .`-~vzuuw^........................`vvvvvv-<br></br>


            </div>,

            <div>

 ..............................................-+?????????????>+-`--~||`   <br></br>
 ..........................................`^>vv}}}}}}}}}}v?>^~~|^^|-.     <br></br>
 ......................................`~+?v}}}}}}}}}}v?>^~--~+^-.         <br></br>
 ...................................`|>vv}}}}}}}}}vv?^~--~^>vwu^           <br></br>
 ................................`^?v}}}}}v}}}}v?+|--~^>?rxuyyy^           <br></br>
 .............................`~>v}}}}}}}}}v?>|~--~+?vwuyyyyyyy^           <br></br>
 ..........................`~>v}}}v}}}}vv>^~--~^>vwxyrxyuuyyyyy^           <br></br>
 ........```----`....`...-+?}}}}}}}}v?+|--~|+?rzuyyyyrzyuuyyyyy^           <br></br>
 ......-+vvvvvvv?^`.|``|?v}}}}}}v?>|~-~|^^~`?yyyyyyyywxyuuyyyyy^           <br></br>
.^....|vv}}}}}}vv}???>v}}}}}v?>^~--~|^~`.   >yyyyuuuyzzyuuuuuuy+           <br></br>
`}?~.|}vvv}}}}}}?v}vv}}}vv?+|--~|^|~`.      +zzwrwwzz}zyuuuyyuy+           <br></br>
.+?v+vv??v}}}}}}vv}?}v?>|--~|||~`.          ^r}r}wrrw}wyuuuyyuy+        .`.<br></br>
.~-~^>???vvvvvvvv??+^~--~||~`.              ^rvwrwr}}}wyuuuyyuy+      .`-+`<br></br>
.~----~~~~~|||||||~-~--|-.       ..     ... >z}}}}}}}}wyuuuyyuy+    .`-+}x|<br></br>
  .~-|>----------~?~~~.         .. .    ... ^r}vvv}}}}ryuuuuuuy^  .`-+}zrv-<br></br>
  .~-^w|-~~~~~~~-+r^~~.        ..        .  ~}vvvv}}}v}yuuuuyyy^.`->rz}vv}-<br></br>
  `~-+u?`-------`vw+~~.        ...  ..      ~}vvvv}}}}ryuuyyyz?-~?wz}vv}}}-<br></br>
  `~-?ux?????????xz?~~.       ...`....      ~}}rrwzzxzwyuyyw?--?wr>?v}}}}}-<br></br>
 .-->r}zzzzzzzzzzzr}~~.       ..``..........^xxxxxxxxzryyw>-~?z}^`?}v}}}}}-<br></br>
.-+v}|...`````````.>v>|`.     ..`...........^xzzzzzzzz}r>-~?zv|..>}}}}}}}}-<br></br>
.}}+................`>v?^`.   ............. |uuuuuuuxv|-|vzv~....|}}}}}}}}-<br></br>
.^....................`^?v+-..              |yyyyyu}^-^vz?-......-v}}}}}}}-<br></br>
 ........................~?v?~`.            |yyyuv|`^}z?`........`v}}}}}}}-<br></br>
 ..........................`>v?^`..         ~yxv|`+}w?`..........`v}}}}}}}-<br></br>
 ............................`^vv>~`.       `?~->rw>`............`v}}}}}}}-<br></br>
 .................``............~?}?|`..   .`~?rr+...............`v}}}}}}}-<br></br>
 .................`>`.............`>}v>~`.`|?w}^..................?}}}}}}}-<br></br>
 .................`v^................^vr?>?wv|....................?}}}v}}}-<br></br>
 ..................?v`.................~?}v~......................+}}}}}}}-<br></br>
 ..................?r^............................................+}}}}}}}-<br></br>
 ..................+rv`...........................................|v}}}}}}-<br></br>
 ..................|}}+...........................................~vvvvvvv-<br></br>


            </div>,

            <div>

 ..............................................-+?????????????>+-`--~||`   <br></br>
 ..........................................`^>v}}}}}}}}}}}v?>^~~|^^|`.     <br></br>
 ......................................`~+?v}}}}}}}}}}v?>^~--~++-..        <br></br>
 ...................................`|>vv}}}}}}}}}vv?+~--~^?vwu^           <br></br>
 .........................`......`^?v}}}}vv}}}}v?+|--~|>?rxuyyy^           <br></br>
 .............................`~>v}}}}v}}}}v?>^~--~+?vwuyyyyyyy^           <br></br>
 ..........................`~>v}}}}}}}}vv?^~--~^>vwxywxyuuuyyyy^           <br></br>
 ........```----`....`..`-+?}}}}}}}}v?+|--~|+?}zuyyyyrzyuuuyyyy^           <br></br>
 ......-+vvvvvvv?^``|``|?v}}}}}}v?>|~-~|^|~`?yyyyyyyywxyuuuyyyy^           <br></br>
`+....|vv}}}}}}vv}???>v}}}}}v?>^~--~|^~`.   >yyyyuuyyzzyuuuuuuy+           <br></br>
`}?~.|}v?v}}}}}}?v}vv}}}vv?+|--~|^|-`.      +zzzrwwwzrwyuuuyyyy+           <br></br>
.+?v+vv??v}}}}}}vv}?}v?>|--~|||~`.          ^w}r}rr}w}wyuuuyyyy+        .`.<br></br>
.~-~^>???vvvvvvvv??+^~--~||~`.              ^wvwwrrr}}wyuuuyyyy+      .`-+`<br></br>
.~----~~~~~~~~||||~-~--|-.       ..     ... +xr}}}rrr}wyuuuyyyy+    .`-+}x|<br></br>
  .~-~>----------~?~~~.         .. .    ... ^w}}}}rrr}ryuuuuuyy^  .`-+}zrv-<br></br>
  .~-^w|-~~~~~~~-+r^-~.        ..        .. ~}}}}}}}}v}yuuuuuyy^.`~>rz}vv}-<br></br>
  .~->u?`-------`vw+-~.        ...  ..      ~r}}}}}}rrryuuuyyz?-~?ww}vv}}}-<br></br>
  `~-?ux?????????xz?-~.       ...`....      ~r}rwwzxxzwyuyyw?--?wr>?v}}}}}-<br></br>
 .-->r}zzzzzzzzzzzr}~~.       ..``..... ....^xxxxzxxxzryyw>-~?z}^`?}v}}}}}-<br></br>
.-+v}|...`````````.>v>|`.     ..`...........^xzzzzzzzz}r>-~?zv|..>}}v}}}}}-<br></br>
.}}+................`>v?^`.   ............. |uuuuuuuxv~-|vzv~....|v}v}v}}}-<br></br>
.^....................`^?v+-..              |yyyyyu}^-^vz?-......-v}}}}}}}-<br></br>
 ........................~?v?~`.            ~yyyuv|`^}z?`........`v}}}}}}}-<br></br>
 ..........................`>v?^`..         ~yxv~`+}w?`..........`v}}}}}}}-<br></br>
 ............................`^vv>~`.       `?~->rw>`............`v}}}}}}}-<br></br>
 .................``............~?}?^`..   .`~?rr+...............`v}}}}}}}-<br></br>
 .................`?`.............`>}v>~`.`|?w}^..................?}}}}}}}-<br></br>
 ..................?^...............`^vr?>?wv|....................?}}}v}}}-<br></br>
 ..................?v`.................~?}v~......................+}}}}}}}-<br></br>
 ..................?r^............................................+}}}}}}}-<br></br>
 .... .............+}v`...........................................|v}}}}}}-<br></br>
 ..................|}}+...........................................~vvvvvvv-<br></br>


            </div>,

            <div>

`>....................................................`??????????????????^.<br></br>
`}+..................................................`^}}}}}}}}}}}}}v?>^~|.<br></br>
`}?`..................................................~+?v}}}}}}}v?+|~|||`.<br></br>
`v^................................................`|>?vv}}}}v?>^~~||-`.   <br></br>
`v?^`..........................................`~+?v}}}}}}v?+~-~|~-.       <br></br>
`}}}v>-.....................................`|>v}}}}}}}v?^~-~++`.          <br></br>
`}}}}}v?|................................`^?v}}}}}}v?>|--|>vwu?            <br></br>
.?vv}}}}}?^`..........................`|?v}}}}}}v?+~-~^>}zuyyy?            <br></br>
.~-~+?v}}}}?^`.....................`~>v}}}}}vv>^~-~+?rxruyyyyy?            <br></br>
`+^^~-~^?vv}}v+`......``-~~-..`~.`+?}}}}}v?>|~~~+vwuyyyruyuuuy?            <br></br>
--.`|^^|~-|+?v}v^...|>vvv}}??|>>>v}}}}v?^~~~|~`.?yyyyyyruyuyyy?            <br></br>
--    .-|^^~~~^?v?~>v?v}}}}vv}?v}}v?>^~~||~`.   >uuuxuuruyyyyy?         .-.<br></br>
--       .`~^^|~~^>???}}}}}}?v?v?+|~~|~-..      ^zwwwwwruyyyyy?       .`+v~<br></br>
--           .-||~~-^>???????+|~-~|~`.          |zwwwww}uyyyyy?     .-+v}v-<br></br>
--               -~-`--------^~~-..     ..    . ~xwrrww}uyuuuy?   .->vvvv}-<br></br>
--               --~~-------~v+~`             . `rrrwww}uyuuyy? .->v}vv}}}-<br></br>
--               --^v>>>>>>>vx>~`     ...  .    `}rrwwzrxyyyu}|->vvvv}}}}}-<br></br>
--             .`~>?>?????vvv??~-     ......    `wzxxxurxyuv|->v+>v}}}}}}}-<br></br>
--           .`~>?^...........^?+~`.  ..........`zxxxxxrzv|~>?+`|}}}}}}}}}-<br></br>
--         .`~>v+..............`+??~..          .zuuyyz>~~??^...^v}vvvv}}}-<br></br>
--       .`~?v+..................`^??|`.        .zyyz?~~??^.....`?}}}v}}}}-<br></br>
--     .`~?v+`......................|??+`..     .zw>-~??|........?}}}}}}}}-<br></br>
.`   .`~?v+`.................`........-?v>~..   .^-~??|..........>}}}}}}}}-<br></br>
.~`..~?v>`...................+-.........`>v?|`.`-|??|............^}}}}}}}}-<br></br>
`}v>?}>`.....................+?...........`^vv>+??~..............~}}}}}}}}-<br></br>
 `+?>`.......................|}|.............~??~................~}}}}}}}}-<br></br>
 ............................-}v`................................-}}}}v}}}-<br></br>
 ............................`vr^................................-}}}}v}}}-<br></br>
 .............``..............+}>|^-.............................-v}}}}}}}-<br></br>
 ............`^`..............`---~?~............................`v}}}}}}}-<br></br>
 ...........`>`....................-v-............................?}}}}}}}-<br></br>
 ...........+?.....................`v^............................>vvvvvvv-<br></br>


            </div>,

            <div>

`>....................................................`??????????????????^.<br></br>
`}+..................................................`^}}}}}}}}}}}}}v?>^~|.<br></br>
`}?`..................................................~+?v}}}}}}}v?+|~|||`.<br></br>
`?^................................................`|>?vv}}}}v?>^~~||-`.   <br></br>
`v?^`..........................................`~+?v}}}}}}v?+~-~|~-.       <br></br>
`v}}v>-.....................................`|>v}}}}}}}v?^~-~++`.          <br></br>
`}}}}}v?|................................`^?v}}}}}}v?>|--|>vwu?            <br></br>
.?vv}}}}}?^`..........................`|?v}}}}}}v?+~-~^>}zuyyy?            <br></br>
.~-~+?v}}}}?^`.....................`~>v}}}}}vv>^~-~+?rxruyyyyy?            <br></br>
`+^^~-~^?vv}}v+`......``-~~-..`~.`+?}}}}}v?>|~~~+vwuyyyruyuuuy?            <br></br>
--.`|^^|~-|+?v}v^...|>vvv}}??|>>>v}}}}v?^~~~|~`.?yyyyyyruyuyyy?            <br></br>
--    .-|^^~~~^?v?~>v?v}}}}vv}?v}}v?>^~~||~`.   >uuuxuuruyyyyy?         .-.<br></br>
--       .`~^^|~~^>???}}}}}}?v?v?+|~~|~-..      ^xwwrwzruyyyyy?       .`+v~<br></br>
--           .-||~~-^>???????+|~-~|~`.          |zrwwwwruyyyyy?     .-+v}v-<br></br>
--               -~-`--------^~~-..     ..    . ~xwrrww}uyuuuy?   .->v}vv}-<br></br>
--               --~~-------~v+~`             . -wwwwww}uyuuyy? .->v}vv}}}-<br></br>
--               --^v>>>>>>>vx>~`     ...  .    `rwwwzzrxyyyu}|->vvvv}}}}}-<br></br>
--             .`~>?>?????vvv??~-     ......    `wxxxxurxyuv|->v+>v}}}}}}}-<br></br>
--           .`~>?^...........^?+~`.  ..........`zxxxxxrzv|~>?+`|}}}}}}}}}-<br></br>
--         .`~>v+..............`+??~..          .zuuyyz>~~??^...^v}vvvv}}}-<br></br>
--       .`~?v+..................`^??|`.        .zyyz?~~??^.....`?}}}v}}}}-<br></br>
--     .`~?v+`......................|??+`..     .zw>-~??|........?}}}}}}}}-<br></br>
.`   ..~?v+`.................`........-?v>~..   .^-~??|..........>}}}}}}}}-<br></br>
.~`..~?v+`...................+-.........`>v?|`.`-|??|............^}}}}}}}}-<br></br>
`}v>?}>`.....................+?...........`^vv>+??~..............~}}}}}}}}-<br></br>
 `+?>`.......................|}|.............~??~................~}}}}}}}}-<br></br>
 ............................-}v`................................-}}}}v}}}-<br></br>
 ............................`vr^................................-}}}}v}}}-<br></br>
 .............``..............+}>|^-.............................-v}}}}}}}-<br></br>
 ............`^`..............`---~?~............................`v}}}}}}}-<br></br>
 ...........`>`....................-v-............................?}}}}}}}-<br></br>
 ...........+?.....................`v^............................>vvvvvvv-<br></br>


            </div>,

            <div>

.?????``???????>..........................................-???????+???????`<br></br>
`}}vvv??vvvvvvvv`.....................................````~v}}}}}}}}}}}}}}-<br></br>
`}vvvvvvvvvvvvv?`......................................```|}}}}}}}}vvvvvv}-<br></br>
`}}vvvvvvvvvvv?+....................................``````^}}}}}vvvvvvvvvv-<br></br>
.>?vvvvvvvvvv?>-................................`````````->vvvvvvvvvvv?+|~.<br></br>
.^^|^>?vvvvvv?>|`..............................```````````~>?vvvvvv?+|~~~`.<br></br>
 .-^^^||+?vv}}}vv>|`......................```.```````.`~+?v}}}v?>^~~~-`.  .<br></br>
    .`~^^|~|+?vv}}vv?|`................```````````..-+?}}}}v?>^~~~-.     ..<br></br>
        .-|^|-~^>?v}}}v>~`...........``````````..-^?v}}}v?+||+|`.        ..<br></br>
           .wr?+~-~^?vv}}?+-......`````````...`|?v}}vv>^|^>vww.          ..<br></br>
..         .xyux}v>|-~^>?v}}?|..````````....->v}}v?>^|^>vruyyx.          -.<br></br>
`?|.       .zuuywuuzr?^^||+?v}?^`-+??v?^-.|?vv?>^|||?}zyxzyyyx.       .`>}~<br></br>
`vv?|.     .zuuurxyyyw.`|++^|^>????v}}v?v?v?+|~~|~`.ryuuzzyyyx.     .`>v}v-<br></br>
`vvvvv^.   .zuuurxuuyw    .~^^|~||+vvvv?^+~~~|-`.   vzwzwzyyyx.   .->v}vv}-<br></br>
`vvvvvv?+`  wuuurxuuuw.      .`~---`---||```.    ...vzwwrwyuyx. .-?}}vv}}}-<br></br>
`vvvvvv?>?>`?xuurxuuuw.        `-??+++>vv-`       . ?zwwrryyyr`~?}}vv}}}}}-<br></br>
`vvvvvvv?`^?>^?xwxuuyz.       .^?>~||||~>?~  ... .. ?xxxzwyr>^?vvvv}}}}}}}-<br></br>
`vvvvvvv?|..^??^>wyyuz.     .|??-........|v?`...... vuuux?+^??|+vv}}}}}}}}-<br></br>
`vvvvvvv>`....^??+?wyz.   .~?>-.``.........^v>`     vyuv^^??|..?}}}vvvv}}}-<br></br>
`vvvvvvv>.......|??^>v. .~>>-...````.........+v+.   ?v^^??~....|v}}}}v}}}}-<br></br>
`vvvvvvv+...```...|?v^`~?>-........```........`>v>`.~^??~......`v}}}}}}}}}-<br></br>
`vvvvvvv+.```.......~??>-...........```.^-......-vrvv>-.........?}}}}}}}}}-<br></br>
`vvvvvv}+``.............................+?........~+-...........>}}}}}}}}}-<br></br>
`vvvvvv}+...............................~}|.....................^}}}}}}}}}-<br></br>
`v}}}}vv|.....................`.........`?>`~-.```..............~v}}}}}}}}-<br></br>
`vvvvvvv~.................`~..``..........`.~?~.`...............`v}}}}}}v}-<br></br>
`vvvvvvv-.................^>..``.........`...??.................`v}vv}v}??-<br></br>
`vvvvvvv`.................^v?>+>??+-``|>??>^+vv`.................?}vv}v}?>`<br></br>
`vvvvvv?`..................~>?>~`->????+~`~+?>|..................?}}}}}}?>`<br></br>
`vvvvvv?`..........................`--...........................^}}}}}}??-<br></br>
`vvvvvv>.....`......................`............................|}}}}}}v}-<br></br>
                  `vvvvvv+....``...................................................|vvvvvvvv-<br></br>


            </div>,

            <div>

                  .?????``????????..........................................`???????+???????`<br></br>
`}}}}}??}}}}}vvv`.........................................~}}}}}}}}}}}}}}}-<br></br>
`vvvvv}}}}}}}v}?`.........................................|}}}}}}}}}}}}}}}-<br></br>
`}}}}}}}}}}}}}v+..........................................|}}}}v}v}}}}}}vv-<br></br>
.>?v}}}}}}}vv?>-.........................................->vvv}vvv}}}v?>||.<br></br>
.^^|^>?v}}}vv?>|`........................................`~>?v}}}}v?+|~~~`.<br></br>
 .-^+^||+?vv}}}vv>|`..................................`~+?v}}}vv>^|~~-`.  .<br></br>
    .`~^^|~|+?vv}}}v?^`.............................-+?}}}}v?>^~~~-.     ..<br></br>
        .-|^|-~^>?v}}}v>~`.......................`^?v}}}v?+~|+~`.        ..<br></br>
           .wr?+~-~^?v}}}?+-..................`|?v}}vv?^~|>vww.          ..<br></br>
..         .xyyu}v>|-~^>?v}}?|......```.....->v}}v?>|~|>vruyyx.          -.<br></br>
`?|.       .zyuywuuxr?+^|^+?v}v+``+??v?^-.|?v}v>^|||?}zuxzyyyx.       .`>}~<br></br>
`vv?^.     .zyuyrxyyyw.`|++||^>v???v}}v?v?v?>|~~|~`.ryuuzzyyyx.      `>v}v-<br></br>
`vvv}v^.   .zyuywxyuyw.   .~^^|~||+vvvv?++~~~|-`.   vzzzwzyyyx.   .-?}}v}}-<br></br>
`vv}vv}v+`  wyyywxyuyz.      .`~---~~--||-``.     . vzzzwwyuyx. .-?}}vv}}}-<br></br>
`vv}}}v?>?>`?uyywxyuyz.        `-?v>>>>vv-`         ?zzzrryyyr`~?}}vv}}}}}-<br></br>
`v}}}}}}?`^v>^?xwuyuyz.       .^?>~^^^|~>?~  ...    vxxxzwyr>^?vvv}}}}}}}}-<br></br>
`v}}}}}v?|..^??^>wyyyz.     .|??-........|v?`...  . vuuux?+^??|+v}v}}}v}}}-<br></br>
`v}}}}}v>`....^v?+?wyx.   .~?>-............^v>`     vyuv^^??|..?}}v}}}}}}}-<br></br>
`v}}}}}}?.......|v?^>}. .~>>-................+v+.   ?v^^??~....|v}}}}}}}}}-<br></br>
`v}}}}}}>.........|?v^`|?>-.............`.....`>v>`.-^??-......`v}}}}}}}}}-<br></br>
`v}}}}}}>...........~??>-...............^-......-vrvv>-.........?}}}}}}}}}-<br></br>
`v}}}}}}+``.............................+?........~+-...........>}}}}}}}}}-<br></br>
`v}}}}}}+...............................~}|.....................^}}}}}}}}}-<br></br>
`}}}}}}}|...............................`?>.-`..................~v}}}}}}}}-<br></br>
`v}}}}}}~.................`~..............`.~?-.................`v}}}}}}}}-<br></br>
`v}}}}}v-.................^?.............`...??.................`v}}}}}}??-<br></br>
`v}}}vvv`.................+v?>+>??+-``|>???^+vv`.................?}vv}v}?>`<br></br>
`v}}}vv?`..................~>?>~`->??v?+~`~+?>|..................?}vv}v}?>`<br></br>
`v}}}vv?`..........................`--...........................^}vv}v}??-<br></br>
`v}}}v}>.........................................................|}}}}}}v}-<br></br>
`v}}}v}+.........................................................|vvvvvvvv-<br></br>


            </div>,

            <div>

.||||~--|||||||>`.......``````````````````````````.......|?+||||||~|||||~>-<br></br>
.+++++^^+++++++}>``........````````````````````.........-vv?^+++++++++++>r|<br></br>
.?+^+++++++++^>}v~....`~|........................--....`?vv?^+++++++++^>}w~<br></br>
`wv>^^+++++++^>vv?`..-?v?........................>v|...>vvvv^++++++++^>}ww~<br></br>
`rwwv?+^+++++|?vvv+.|vvv>........................?vv~.+vvvvv+++++++^+?rwww~<br></br>
`rwwww}?+++++|?vvvv>vvvv?........................?vv?^vvvvvv+++++^^>vwwrrr~<br></br>
`rrrwwwwr>--|^v}}vvvvvvv?`.......................?vvvvvvvv}}>++^^^?rwrr}}r~<br></br>
`}}}}rrw?````~v>>?v}vvv?~........................>vvvv}}v??v>~``^}rr}}}}}r~<br></br>
`}}}}}r?.....|?~.`~>?vvv?|`....................`~>vvvv>^`.`>?....^}}}}}}}r~<br></br>
`}}}}}}` ...`>?-   ..~+>?vv>-...............`^?vvv?>~`.   .+v`... ?r}}}}}r~<br></br>
`r}}}r?.....`?v+-.     +?+>???|`..........~>vv????^    . .~?v-... -r}}}}rr~<br></br>
`rrrr}>?....`?rv?^`... ?yrvv^^??+~``|^`.|???>>?vry? ....-+v}}~.....vr}rrww~<br></br>
`rwww}>?`...`vrr}v?^`. ?uwzy| .-~++~vv++?+~` ?uwwuv ..->v}rrr|....|?rrwwww~<br></br>
`rrwwr?v`...`vrr}rrv>| ?xrwx~    .~+?>>^`  . ?wv}uv ->v}}}}}r^....>vwwwrrw~<br></br>
`}wwww?v-...-}rr}}rr?~`~v}wx`   .`|^++^^-... >r}vv^~?}}}}}rrr+....?vwwww}r~<br></br>
 >wwwwvv^``.~rrr}}rr?-..`~vz`  `~|-````-|~`  >z?|~-.?r}}}}rrr>..`~vvwwwwwr~<br></br>
  -vzwwv?``.^}}}}}rr+-...`-^..-~-`````````~-.~+|-...>r}}}}r}}>```^v}wwwwxu~<br></br>
 ...^vwwv>..^}vvv}r}|~.....`~~-``........``~^|-.....|r}}}vvv}?.`-?vrwwzxv+.<br></br>
 ...``^vr?+.+vvvvv}v~~......````......`~...```......~}}}vvvvv?.`>vrwzw?-.`.<br></br>
 ..`~-`-^??^?rv?vv}?`-....``...........^`....`-`....-vvvvvvv?>.+vrw}>`.... <br></br>
 ..---~~`.``?r}>>?v?``..```....|-`-`..```^~...``-`...?}vvvv?vv^>?>|--`.... <br></br>
 .`--~~~`..`??v>+?v+`````......~+++++?>|^>`.....``--`>vvv??}wv`.``-~~-.... <br></br>
 .-^?|-~`..-vvv?>?v^...............`~~`.``........``.|vv?+>}}?..`--~~-.... <br></br>
 `>v}+-~`..~vvvvvvv|....................`-`..........~vvv>??v?`.`--~~~`... <br></br>
 >}}}?-~`..|vvvvvvv~....................```.`........~vvvvvvvv-.`-~-~~`... <br></br>
.v}}}?~~`..+vvvvvvv^.......`................```......^vvvvvvvv`.`^>-~~-... <br></br>
`v}}}v|-`..+vvvvvvvv|.....``.................``....->vvvvvvvv|..`?v|~~-... <br></br>
`v}}}}+-`..`>vvvvvvvv>`...``````````..`````````..~>vvvvvv?vv|...|v}?~~-... <br></br>
`v}}v}>-`....>vvvvvvvv?^`..`````..`````````````|?vvvvvvvv??~...`?v}v^~-... <br></br>
`v}}v}?-......^vv??vvvvv?|`.................`|?vvvvvvvvvv?`....^vvv}?~-... <br></br>
`v}}v}v~.......|??vvvvvvvv?>??>`...........^?vvvvvvvv??v^`....-vvv}}}>-... <br></br>
`v}}vv}?`.......-??vvvvvvvvv?^`............`~?vvvvvvvv?-......>vvvvvvv|... <br></br>


            </div>,

            <div>

.-------------->`.......````````-------------``````......^?^------------->-<br></br>
.|~~~~~~~~~~~~|}>``........`.``````````````````.........-v}?~~~~~~~~~~~~^r|<br></br>
.>~~~~~~~~~~~~+}v|....`||........................--....`?}}?-~~~~~~~~~~^}w~<br></br>
`wv+~~~~~~~~~~>}}?`..~?}?........................?}^..`?}}}v~~~~~~~~~~+}ww~<br></br>
`www?>~-~~~~~~?}v}>.^v}}?........................?}}~.>}}v}v|~~~~~~-~?wwww~<br></br>
`wwwwwv>~~~~~~v}}}v?v}v}?........................?}}?+}}}}vv^~~~~~-^vwwwww~<br></br>
`wwwwwwwr>```~v}}}}}}}}}?`.......................?}}}v}}}}}}+~~~~|?wwwwwww~<br></br>
`wwwwwwwv`...~v++?v}}}}v|........................?v}}}}vv??v>-`.^rzwwwwwww~<br></br>
`wwwwwwv.....|?-..~+?v}v?^`....................`|>v}v?>|`.`>?....+wwwwwwww~<br></br>
`wwwwww-.....+?`    .`^>?vv>-`..............`^?vvv?+-.    .+?.....?zwwwwww~<br></br>
`wwwww?`.....>v~.      +?+^>??|`..........~>vv??>?|       `+v`....~wwwwwww~<br></br>
`wwwww?v`....?}v+~.    ?yw}v~|>?+~..~~`.^?v?++?vwy?     `|?vv`....`}wwwwww~<br></br>
`rwwwr?v-....?}}}?^-.  ?yzxy| .`~++-vv^+?^-. vyzzyv   `^?v}}}-.```+vwwwwww~<br></br>
`rrww}?}-....v}v}}v?|` vyzxy-    .~+>++^.    ?xwwyr `+?v}}v}}~.``.?vwwwwrw~<br></br>
`}wwww?v`...`v}v}}}}?`-^}wxy`    `|^++^^-..  ?uz}}+|?}}}}}vv}|..``?vwwww}r~<br></br>
 >wzwwvv^...-}vvvv}}>..`~^ru` .`~~`....`~~`  ?uv^|-.?}vv}}vv}+...`?vwwwwwr~<br></br>
  `vzwwv?`..|}v}vv}}|....-|>.`~~`........`~~.|>^~...>}v}vvvv}>...|v}wwwwxu~<br></br>
 ...|vzwv>..^}v}}}}v`.....`~|~`............-^+-.....|}}}vvvv}?..`?vwwwxxv+.<br></br>
 .....|vwv>.+vv}}}}?`.......`.........`|............`v}}vvvv}?..>vrzzw?-.`.<br></br>
 .......|??+vrvvv}}?...................+`...........`?}}vvvvv?.+vrz}+. ... <br></br>
 .....`...``vwr??v}>...........|~--`..```^~..........?}}}}vv}v+>?>~... ... <br></br>
 ...``.....`vv}?+?}+...........~>>>>>?>|^>-..........>}}v??}z}`........... <br></br>
 ..~?-.....-}}vv?v}^...............`~~`..............^}}v+>rr?............ <br></br>
 .^v}^.....~}}}}}v}^.................................~}}v??vvv`........... <br></br>
 +}}}>.....^}}}}}}}|.................................~}}vvv}}}-...`....... <br></br>
.v}}}?`....>}}}}}}}+.................................+}}}}}}}v-..|>....... <br></br>
`v}}}v-....>}}}}}}}v|..............................->v}}}}}}}^..`?v-...... <br></br>
`v}}}}|....`?}}}}}}}}>`..........................~?v}}}}vv}v|...|}}>...... <br></br>
`v}}v}>......>}}v}v}}}v+`.....................`|?v}}}}}v}v?~...`v}}v-..... <br></br>
`v}}v}?.......+}}vv}}}}}?|`...`.............`^?}}}}}vv}}}?`....+}}}}?..... <br></br>
`v}}v}v~.......^?vv}}}}v}}???v?`...........^v}}}}}}}vvvv+`....-v}}}}}+.... <br></br>
`v}}vv}?`.......~?v}}}}}}}}}v+`............`|vvvvvvvvv?-......?}vvvvvv~... <br></br>


            </div>,

            <div>

                  `?v?~vvv????v^~^-``````````---------------------`````````~^~~|????????vv^v~<br></br>
`rwr+zzwrrrrr>|>^------``------------------------``----`~>>+~?rrrrrrrrwz>w|<br></br>
`rrr>zzrrrrrr?^>>~---`-~~`----------------------`-~-----+>>+|vr}rrrr}rzw>z|<br></br>
`www>wzrrrrrr}>>>^-``~^+|```-----------------````|+|---^>>>+?rrrrrrr}vrw?x|<br></br>
`wzz?wzrrrrrrr}?+>|`~++>|.`..````````````````....^>+~`|+>>>?}rrrrrr}}zw?}x|<br></br>
`wzz}ruwv}rrr}rr?++^+>+?>...-...............`-..-v?++|++>+?rrrrrrrrr}zz?zz|<br></br>
`wzzwvzrrr}}}}rwrv>>>>+vv-`+>`..............`?|`?v?+>++>>?rwrrrrrrrwxz?rzz|<br></br>
`wwzxvrxr}}}}}rrrrr?+++vv?>?+...............`????vv^++>>vrrrr}}}rwwzu}?xzz|<br></br>
`wwwzzvr}vv}}}r}vvv|-~^vvv??+...............`?vvvvv^~~|v}v}rr}}}}rzur+zzww~<br></br>
`wwwzz}?}v}}}rr}??` ..|>~^???^`............`^vvv?+?^`..+vvvrr}}v}}zw+wzwww~<br></br>
`wwwwzz?>w}}}rrr}>... ~^...-??>>~`.......`+??>|`..^-   .?v}rr}}vvwv>wzwwww~<br></br>
`wwwzzzx??w}}rwrr?^ ..|?>`.`rvv^+>|`~~.-+>??v...->?~  .`>rrww}}rv?>wzwwwww~<br></br>
`wzzzzwz}+?r}rwwwv>...^vr?|-}?}..`-`??~|-.?v}`~+vw}^ ..?vwwwrv?}+>wzzzwwww~<br></br>
`r}v??++^|-+}w}wwvv~.`>vwr?>>>v.  .|+>^`  >?^|+vrwr>.`~?vwwwrv+--|^>?v}wwz~<br></br>
.>++>>+>^^|-~?wrr}v>``vv}}+|``^..`-````--.~`.`|}}}v?~~+v}www}|-^^^+++++>?v~<br></br>
.+>>>>>+|--|^^+vrwvv|`??vv^|..`````....``-`. `^vvvv?~+vvwrv>+|~^^^>>>>>>>+`<br></br>
.^^++|~`..~^+>^`+vr}?^????``..```.....``..``.``>v???+vvv>~^>>+~.``~|++>>>?`<br></br>
.-`..````^|^+^`-~~|>>>?|??`.``...--```---..````^??^?^~|~~.`^>+^- ..```~^^^.<br></br>
 .......^>+|-..~+?|-.|?|?>.......```~~`-`......~??->^-|||`.`|+|^-.`````....<br></br>
 ..... `+++-.``+vv+~.+???^.............``......`????>~|||-...`|++```...... <br></br>
 .... .^^|`..`+vvv^-.>v??|.....``........``....`?????~++|-....`^>^........ <br></br>
 .....|^|`...`vvvv^-.>????-....``````-`````...`+????>-??~-.```..|^` ...... <br></br>
 ....-^^`....|vvvv+-.-?????-........``.......`>?????``vv^`.``...`^|....... <br></br>
 ...-^^~.....+}}vv+`..->????~````........`~~^?????+`.|}}?`.......-^- ..... <br></br>
 ..`++|......>}}v}>`...`+???????|........~???????^..`?}vv^`.......|^...... <br></br>
 `.|>+`.....`v}}}}v-.....|?????>`........~??????~...+v}v}?-.......-^- .... <br></br>
 .`>>^......|}}}}}}v~.....~>????>++>>>>>>??????~...^v}}}vv^.......`|^..... <br></br>
 `^>>-..... |}}v}}}}v^.....->??????????????>?>`..`+}}}}}v}>........`^-.... <br></br>
 ->>^`..... -vvvv}}}}v>-.....~>>????????????^`.`->v}}}}vv}^.........-|.... <br></br>
 ^>>-...... -?vvvvv}}vv?|`.....`-~~~~~-~-~-`.`-~?vv}}}v}}v`.........`|`... <br></br>
.>>^`...... -??vvvv}}}}}v+~--~-`...........`~|+v}}}}}v}vv?...........-~... <br></br>
.>>~.........>???vv}}}}}}v?^-``.............~>vvvvvvvvv?>- ..........`~... <br></br>


            </div>,

            <div>

`}r}|rrrvvvvv^-----------------------------------------------~vvvvvvvvrw^}~<br></br>
`xux>yyuzzzwz>~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~?zwwwwwwzyy>x|<br></br>
`zux?yyxwwwwwv-~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~}wwwwwwwzyu>x|<br></br>
                  `xuu?uyxwwwwww+-~~~~~~~---~~~~~~~~~~~~~~~~~~~~~--`-~~~~~~~~-?wwwwwwwwrxz?y|<br></br>
`xuu?xyxwwwwwwr+-~~~~~~^-.```````-`````-`````....|^~~~~~~~->wwwwwwwwwuz>ry|<br></br>
`xxu}ryx}rwwwwww+-~~~~-?>...-`..............`~..~v>-~~~~~->wwwwwwwwwrzx>uu|<br></br>
`zxuxvzrwwwwwwwww?~-~~-?v-`?v`..............-}+-v}?-~~~~->wwwwwwwwwzuw>rux|<br></br>
`wzxu}rywwwwwwwwwwr>--~v}??}?...............-vvv}}v---~~?wwwwwwwwwwxyv?uzz|<br></br>
`wzxuxvwzrwwwwwwwwr|..~v?v}}>...............`v}}}}v-..-vzwwwwwwwwwzyr^xxww~<br></br>
`wwzxurvz}wwwwwwww|...|>`~+??>-............`+vv?>|?|...?wwwwwwwrwzuw^zuzww~<br></br>
`wwwzxuv?yzwwwwwwv`...+^   `>???^`.......-+??^-.  ++...`rwwwwww}}ur>zxwwww~<br></br>
`wwzzxuuvvuwwwwww?>...??`  .zv?^>?+`-~.~>>>?v.  .~?>..`|?wwwwrwzwv?xxzwwww~<br></br>
`zxxuuxxz>?uzwwwwv>...v}v+`.xwx. .-->>~|` vzz..~?v}?..`??www}rrz??xuxzzwww~<br></br>
`zrv?>>>++|?zz}wwvv`.`v}}}+-?vx.  .|^+^`  vr?~|v}}}v`..?vwwwrr?|^++>?vrzxx|<br></br>
.+^++>>>>>>|^?wrrvv^.`}}}v-.-^?.`--....`~`^|`.`}}v}v`.-?}wzz}^^>>>>>++^+>v~<br></br>
.+>>>>>+^|^>>|^v}zv?~~}}}v-...--`.....`..--...`v}v}}`-??zwv>^^+>>>>>>>>>++.<br></br>
.>>+^~``.`+>>>^.|vwv?+vv}v`...........-`.......?}}vv^v?v+-^>>>^``-~|^+>>>>`<br></br>
.~`......+>>>|....-+??v^v?`......~~-`--~-......>}v+v+``.. `^>>>|......`~|+.<br></br>
 .......|>>>~...->`..+?+v?.......``-|^-``......|}v|?>.......~>>>-......... <br></br>
 ......~>>>-...~vv`..>}v}>.....................-v}vv?........-+>+`........ <br></br>
 .....`>>>-...-v}v`..?}}}+.....................`v}}}v.`-......`+>^........ <br></br>
 .....+>>~....?}}v`..?}v}v~...................`>}}}}?.+?.. ....`+>~....... <br></br>
 ....|>>|....~}v}v-..~v}v}v|.................-?}}}v?`.?v-. .....~>+....... <br></br>
 ...`>>^.....^}v}v-...-?v}}}^````........`~|+v}}vv?`.|}}>........|>~...... <br></br>
 ...+>+`.... +}}}}|....`?vv}}vvv^........|v}}}vvv>..`v}}v-.......`+>`..... <br></br>
 ..|>>~.....`?}}}}?`.....+}vv}}?`........|v}}v}v|...?}}}}?........->|..... <br></br>
 .`>>^......~}}}}}}v-.....|?}}}}?????????vvv}}v|...+}}}}}}~........^>`.... <br></br>
 .|>>`......~}}}}}}}v|.....-?}}vv}}}}}}}}}v?}?-...^}}}}}}}>........`>|.... <br></br>
 `>>^.......~}}}}}}}}v^......|??v}}}}}v}v}}v+`...^}}}}}}}}|.........|>.... <br></br>
 ^>>`.......~}v}}}}}}}}>`......-~||^^|~|~|~`...`>}}}}}}}}}-..........+-... <br></br>
.>>^........~}v}}}}}}}}}?~....................|v}}}}}}}}}v...........~^... <br></br>
.>>~.........?}}}}}}}}}}}v>`................~+vvvvvvvvvv?~............^... <br></br>


            </div>,

            <div>

`}r}>}}}vvvvv????????>^??????^--------------~>?????++??????>>?vvvvvvvv}r?}~<br></br>
-uuu?xxxwwwww}vvvvvvv>vvvvvv+~~~~~~~~~~~~~~~~|?vvvvv>vvvvvvvv}wwwwwwwzxuvx^<br></br>
-xuxvxxzwwwwwrvv??vv??vvvvv?^^~~~~~~~~~~~~~||^|vvvvv?>vvvvvvvrwwwwwwwzxx?x|<br></br>
-xuuvxxzwwwwwwv??vvv?vvvvvv+?>~~~~~~~~~~~~~>|v^>vvvvv+vvvvvv}wwwwwwwwwxzvu|<br></br>
`xxxvzxzwwwwwwwv?vvv?vvvv?>`~~------------`~-^~-?vvvv>vvvvvvwwwwwwwwwxzvrx|<br></br>
`wzzrwxzrwwwwwwwvvvv>vv}}?>`~````````````````~``?r}vv+vvvvvwwwwwwwwwwzzvxx|<br></br>
`wwzz}zwwwwwwwwww}vv+?v}rv?>>-``````````````-?^~}r}?v+vvvvwwwwwwwwwwzzvwzx|<br></br>
`wwzxrwuwwwwwwwwwww}?>vrr}}}>```.```````````-??vrr}v?>vv}wwwwwwwwwwzx}vxzz|<br></br>
                  `wwzxxrzzwwwwwwwwwwv?^?rrrrr?-`|-`........-`-?vrrrr?^?vrwwwwwwwwwwwxw?zzww~<br></br>
`wwzzxw}zwwwwwwwwwv???>}??vrr>-+>~......`+>-|vrrv?}????}wwwwwwwwwwzw?wzzww|<br></br>
`wzzxxur}uzwwwwww}????v?|~|>?~|>+^`.....~>>+~>?>|+vv????wwwwwwwwrzw?zxzzwz|<br></br>
`zzzzxxurwuzwwwwwrr?>^>+---|r++-`>+``~`|?~~+^`~~|+>?+??vrwwwwwwzzrvwr}vv??-<br></br>
`vvvvvvvv>vzzzzwr?+``.+>+^-~w?}~.-^.|^`~^.v}?|+>>+>+.`->?rwwwwzz??vvvv????-<br></br>
`??>++++++^>v}v??++``.~~>?-.^>}-  .`---`. vv?^-^>>>>```|+??v}}?^^++>>???vv-<br></br>
.~~~|||^^++++>???>^` .-^?^..`-?-.`..`````.>+-..~|>>^`.-^????>^+>>>>++^|~|^`<br></br>
.~||||||~-~^++++>?|`..~>^-.^^`?>`........`>>-.`~-|>^..-|?>>++++>>+^^|||||~.<br></br>
.||~~``...~||^+-`|+|``|+~-.+>`^>~.....`..^>+`~^~~-|+``-|-`^+>>+~```-~~||||.<br></br>
.``......~|||-``. .-~~>.--.>>-.~+>~````~>>|``>>^~-.+~..  .`^>>^-......``-~.<br></br>
 .......-|||-......  `|.-``>>+`.->+||^|>>~.`+>>>~-.~-  ....|^^||`......... <br></br>
 ......`|||`....--...`~-~`.^>>>|``~^||||`.->>>>^~~--`......~``||~`........ <br></br>
 .....`|||`....-~-...`~~~. .-^>>>-`.....`|>>>|`.-~~~-......`..`~|~........ <br></br>
                  .....~||`....-~~-...`~-~-..^^+++^||-`~^^++++^+--~~~` ``.......`~|`....... <br></br>
 ....-||-.....~-~-....-~-~-.~>++>>^|+>^^+++++??~~---. -~........`|~....... <br></br>
 ...`||-......~-~-.....`-~~~`>>+~~|^++^^|~~+?v>~--`. .~~` .......-|`...... <br></br>
 ...~|~......`~-~~......`--~->v>-..``-`..`+>v?~--`...-~~-.........~~...... <br></br>
.-`-||`......`~~~~-......`~--~v>.........-+?v|--....`~~~~`........`|-..... <br></br>
.||+^~...-`..--~-^>~..... .`--^v+|^^^^^^^+?v^--. ..`~~~~~~`........-|`.... <br></br>
 `+>>~...-~`.---|>>|-..... .`-->v>>>>>>>>?v~--... .-~~~~~||........-|-..`-.<br></br>
 .|>+|-...~~`--|>>>^--........-`?v>>>>+>?v^-`... .-~~~~~-|~......`-.-~`~||.<br></br>
 ``|^||-..-||~^>>>>|-~~`.........|>+~~|^|`.... .`~~~~~~~-^~.....`-`.`^^|||.<br></br>
.^|``|||`.--|>>>>>>--~~~-........ `^+||.  .....-~~~~~~~~~|~-...`-`..-++||` <br></br>
.>?|.~||~.~-`+>>>>^--~~~~-`....... .-`.......`~~~~~~~~~~``~~-..```.-~|+|`. <br></br>


            </div>,

            <div>

`}r}vvvvvvvvvvvvvvvv}v?rvvvvv>--------------^?vvvvr??rvvvvvvvvv}rrr}vvvv}r~<br></br>
-uuxzwwwwwwwwzzwwwwwzvuxwwww>~~~~~~~~~~~~~~~~|vzwwxu?zxzwwwwwwwxuuuzwwwwzx|<br></br>
-uuzwwwwwwrwwzzwwwwz}zyzwzzv++~~~~~~~~~~~~~||>|rwwzuzvuzwrwwwwwzzzzwwwwwww|<br></br>
-xuzwwwrrrrwwwwwrwwx}uuzxxz>}?-~~~~~~~~~~~~?^x^vzxyyy?xzwwwwwwwwwwwwwwwwww|<br></br>
`zzwwwwwrwwwwwwwrwwx}yxwzzv-^|~~~~~~~~~~~~~+|?^^rzxuy?zxwwwwwwwwwwwwwwwwww|<br></br>
`}}rwwwwwwwwwwwwwwzuvxzwwzv-~~~~~~~~~~~~~~~~~~~-vzwzu?xxwwwwwwwwwwwwwwwwwz|<br></br>
`}}rwwwwwwwwwwwwwwxu?wzwwww^~~~~~~~~~~~~~~~~~~~|rwwzx?uxwwwwwwwwrrwwwwwwwz|<br></br>
`rrwwwwwwwwwwwwwwzzu}?zwwwwv~~~-``---~~~--`~~~-?wwwxwvuzzwwwwwwwrrwrrwwwwz|<br></br>
`wwzzzwwwwwwwwwwwwzxu?}xwwwwv|~>|-.......`^~~-+rwwww?xxzzwwwwwwwwrrrrwwwww|<br></br>
`wwzzzzzzwwwwwwwwwzxux?zzwwww+`v}>......`?}~`>wwwww}wuzwzwwwwwwwrrrrwwwwwz|<br></br>
`xxxxxxxxxzwwwwwwwzuuyxvrrrwv.`+>?~....`+??~ >zwwx}wuuzwzwwwwwwwwrwzzzxzzz|<br></br>
`zzzzzxuuuuzwwwwzzxxrv?>^?vwv-~~ +>|-~~+?`-+.>ww}?>v}zxuuxzwwwzzzxw}v??>>+`<br></br>
.++++++>>?vwxuuxwv>+++>+^^+?}>>v-+>.-~`|?~v?-vz}>^++^^+?vwxxxxxwv>++++>>>>`<br></br>
.^^^^^^+++++>v?>^+>>+~``^>~.`~>?`.``...`.`v?>?~~>+^>>>>+^^>?}}>++>>>>>>>>>.<br></br>
 .......`~^>>+^+>+~`...~>~..-`??`..`-``..`??~...~>^-|^+>>>>+|++>>>>+|~```` <br></br>
 ..........`|>>^^~.....+~..??`vv.........`vr^`- .-+-...``-^>>>>>+~`....... <br></br>
 ............-++`.....~^...vv`?}+`......`?}v->?...-^......|+>>>|`......... <br></br>
 .............`^`.....|`..`v}~.+vv^...`|?}?`~v}-...~-.....`^>+-........... <br></br>
 ..............``.....`...-}}v-.^}?^^^+}}+.-v}}?....`......^>-............ <br></br>
 ..........................?v}v>``+????>~.|v}}v>...........^~............. <br></br>
 ...........................~?v}v^-....`~>}}v>-............`.............. <br></br>
 ..........................`??vvvv??^|>??vvv???`.......................... <br></br>
 ...........................>}vvvv??}}??vvvvv}?........................... <br></br>
 ...........................`vv?||>?vv??>~-+v}~........................... <br></br>
 ............................^v-...`~|-`...`v>............................ <br></br>
                  .|-...........................?^...........+?.............`............... <br></br>
.>>^`...`^-......~^...........`?`.........-?`.............~.........`..... <br></br>
 ~>>>|...|>~....`++-...........|>........^?`.............`|.......`-`...-^.<br></br>
 .->>>^...^>~..`+>+-............+?`.....>?`..............`^......~|`..-+>>`<br></br>
 ..`>>>|..|++`~>>>>`.............^v+`.|+^............... -+`....~^`..|>>>>.<br></br>
`?+`~>>>-.||++>>>>+...............~vv??`................ ->^...-^~.`^>>>>~ <br></br>
`}}v|^>>+`+^^>>>>>~.................|-. ................ ->>|..||`.^>++>-..<br></br>


            </div>,

            <div>

`rr}}vvvvvvvvvvvvvvv}v?rvvvvv>--------------^?vvvvr??rvvvvvvvvvrrrr}vvvv}r~<br></br>
-uuxzwwwwwwwwzzwwwwwzvuxwwww>~~~~~~~~~~~~~~~~|vzwwxu?zxzwwwwwwwxuuuzwwwwzx|<br></br>
-uuzwwwwwwrwwzzwwwwz}zyzzzzv++~~~~~~~~~~~~~|^>|rwwzuzvuzwwwwwwwzzzzwwwwwww|<br></br>
-uuzwwwrrrrwwwwwrwwx}uuzxxz>}?-~~~~~~~~~~~~?+u+vzxyyy?xzwwwwwwwwwwwwwwwwww|<br></br>
`zzwwwwwrwwwwwwwrwwx}yxwzzv-^|~~~~~~~~~~~~~+|?^^rzxuy?zxwwwwwwwwwwwwwwwwww|<br></br>
`}}rwwwwwwwwwwwwwwzuvxzwwwv-~~~~~~~~~~~~~~~~~-~-vzwzu?xxwwwwwwwwwwwwwwwwwz|<br></br>
`}}rwwwwwwwwwwwwwwxu?wzwwww^~~~~~~~~~~~~~~~~~~~|rwwzx?uxwwwwwwwwrrwwwwwwwz|<br></br>
`rrwwwwwwwwwwwwwwzzu}?zwwwwv~~~-``---~~~--`~~~-?wwwxwvuzzwwwwwwwrrwrrwwwwz|<br></br>
`wwzzzwwwwwwwwwwwwzxu?}xwwwwv|~>|-.......`^~~-+rwwww?xxzzwwwwwwwwrrrrwwwww|<br></br>
`wwzzzzzzwwwwwwwwwzxux?zzwwww+`v}>......`?}~`>rwwww}wuzwzwwwwwwwrrrwwwwwwz|<br></br>
`xxxxxxxxxzwwwwwwwzuuyxvrrrwv.`+>?~....`+??~ >zwwx}wuuzwzwwwwwwwwrwzzzxzzz|<br></br>
`zzzzzxuuuuzwwwwzzxxrv?>^?vwv-~~ +>|--~+?`-+.>ww}?>v}zxuuxzwwwzzzxw}v??>>+`<br></br>
.++++++>>?vwxuuxwv>+++>+^^+?}>>v-+>.-~`|?~v?-vz}>^++^^+?vwxxxxxwv>++++>>>>`<br></br>
.^^^^^++>+++>v?>^+>>+~``^>~.`~>?`.``...`.`v?>?~~>+^>>>>+^^>?}}>++>>>>>>>>>.<br></br>
 .......`~^>>+^+>+~`...~>~..-`??...`-``..`??~...~>^-|^+>>>>+|++>>>>+|~```` <br></br>
 ..........`|>>^^~.....+~..??`vv.........`vr^`- .-+-...``-^>>>>>+~`....... <br></br>
 ............-++`.....~^...vv`?}+`......`?}v->?...-^......|+>>>|`......... <br></br>
 .............`^`.....|`..`v}~.+vv^...`|?}?`~v}-...--.....`^>+-........... <br></br>
 ..............``.....`...-}}v-.^}?^^^+}}+.-v}}?....`......^>-............ <br></br>
 ..........................?v}v>``+????>~.|v}}v>...........^~............. <br></br>
 ...........................~?}}v^-....`~>}}v>-............`.............. <br></br>
 ..........................`??vvvv??^|>??vvv???`.......................... <br></br>
 ...........................>}vvvv??}}??vvvvv}?........................... <br></br>
 ...........................`vv?||>?vv??>~-+v}~........................... <br></br>
 ............................^v-...`~|-`...`v>............................ <br></br>
                  .|-...........................?^...........+?.............`............... <br></br>
.>>^`...`^-......~^...........`?`.........-?`.............~.........`..... <br></br>
 ~>>>|...|>~....`++-...........|>........^?`.............`|.......`-`...-^.<br></br>
 .->>>^...^>~..`+>+-............+?`.....>?`..............`^......~|`..-+>>`<br></br>
 ..`>>>|..|++`~>>>>`.............^v+`.|+^............... -+`....~^`..|>>>>.<br></br>
`?+`~>>>-.||++>>>>+...............~vv??`................ ->^`..-^~.`^>>>>- <br></br>
`}}v|+>>+`^|^>>>>>~.................|-. ................ ->>^..||`.^>++>-..<br></br>


            </div>,

            <div>

`rrrr}}vvvvvvvvvvvvvvvvv}vvvv?+~-----------~?vvvvv}vv}vvvvvvvvv}}}}vvv}}}r~<br></br>
-uyuuxzzwwwwwzzwwwwwwrzzzwwwv+~~~~~~~~~~~~~~~>rwwzzxrzzwwwwwwwwzxxzzwwzzzx|<br></br>
-uuuxzwwwwrwwzzwwwwwrzzxwzzr?>~~~~~~~~~~~~~^^>?wwzxwz}zwwwwwwwwwzzwwwwwwww|<br></br>
-uuxzzwrrrwwwwwwrwwzwzxxzxzvv?~~~~~~~~~~~~~?>z>}zuyzz}zzwwwwwwwwwwwwwwwwww|<br></br>
`zzwwwwwrwwwwwwwrwwzwzzzwwr>||~~~~~~~~~~~~~^|>|?wzxxz}zzwwwwwwwwwwwwwwwwww|<br></br>
`rrrwwwwwwwwwwwwwwzxwwrzwwr>-~~~~~~~~~~~~~~~~~~?wwwwz}zzwwwwwwwwwwwwwwwwwz|<br></br>
`rwwwwwwwwwwwwwwwwzx}w}zwwwv~~~~~~~~~~~~~~~~~~~?wwwrz}xzwwwwwwwwrrwwrwwwwz|<br></br>
`wwwwwwwwwwwwwwwwwzxwr}wwwwr+~~~```-----``-~~~^}wwwrxrxzzwwwwwwwrrwrrwwwwz|<br></br>
`wwzzzzzwwwwwwwwwwzxurrwzwww}+-+>+......`|>-~^vwww}wrzzzzwwwwwwwrrrrrwwwwz|<br></br>
`wzzzzzzzzwwwwwwwwzxuurzrwwwz>.?v?......~vv`-vwwwrrzzxwwwwwwwwwwrrwwwwwwwz|<br></br>
`xuxuuuuuxzzzwwwwwzuuuur}?rwr~.|`^^`..`~^|^`-}ww}wzxuxzzzwwwwwwwwwzzzzzzzz|<br></br>
`wr}}}}}rrrrrwzzzxxw}?>+^+>vr>->.|>``-`>>.^||}rv>?>?vrzxuxzzzzzw}}v??>>>>+`<br></br>
.++++++>>>????v}r?>++++^|^++>>>v~~|.``.~^^v?>???+++++++>?}xuz}v?>>++>>>>>>`<br></br>
.^^|||||^^^^+>>+^+>+^-`.~+|.`-+?-.``.```.~v>~~`~+^^+>>>>+^+???>++++++^^^^^.<br></br>
 .......``~||||+>>|`...`+~..^~?v-...```..-v?`` .~^~`-|^^+>>++++^^^|~-``... <br></br>
 ..........`-||~~|`....||..-v|>v|`......`+v?^>` .-~`....`|+>+^||~``....... <br></br>
 ............`~~......`|...|v>^>>>~....~>v?>>v> ..`-.....-+||||-.......... <br></br>
 ..............~`.....-`...|v?~~+??||||?v?^+?}?....``....``~||`........... <br></br>
 ..........................|?v?+^>>^^^+??++?v}?`....`......~|`............ <br></br>
 ..........................->?vv>^++^|^>>+?vv?|`...........~`............. <br></br>
 ...........................+?vvv?+>+^+>>?vv?>.............`.............. <br></br>
 ...........................^vvvvv?????????vv>-........................... <br></br>
 ...........................`?v?++++?v>+>>>vv|-........................... <br></br>
                  ``.........-................|v?`-|^++++~`~?^-............................ <br></br>
 .`-`.......-~`....-..........+>`..``-`...|```............................ <br></br>
                  ...`--......~~...`~..........`~|`.......|`.-................``.......``.. <br></br>
                  .|+|`.-|-~`..-|-.`||...........`-^-....`-..`..............`..-......`-`... <br></br>
.+>>>|.`|^^`.`|~-|+|............`.^^-`~`..-.............`.`.`~....`~~..`|>`<br></br>
.++>>?+``|+^``|^|+>~............``.-+~. ``..............``-.`~...-^~``^>>>`<br></br>
.>++>>?+--+>^-^>^>+`.............`-....``................-|`-~..-+|`~+?>>>`<br></br>
`v?>+>>>^-^+++>>^+|...............`~>>>```.............`.~>|~~.`+^`~>>>>>+.<br></br>
`v}v?>>>>~^++>>>^+^~````......```-~-^+~-~~~~~-``....`-~|~->^^|-^+~-+>>>>++`<br></br>


            </div>,

            <div>

`rrrrrr}vvvvvvvvvvvvv}r?r}vvvv>~-----------|?vvvvr?r}vvvvvvvvvvvvvvvvv}}}r~<br></br>
-uyyyyuxwwwwwzzwwwwrwurzuzwww>~~~~~~~~~~~~~~|?wwwxzrxzwwwwwwwwwwwwwwwwzxxx|<br></br>
-uuuuuzwwwwwwzzwwwwrzz}yzwzz?>~~~~~~~~~~~~~^^>vzzxuvzzwwwwwwwwwwwwwwwwwwwz|<br></br>
-uuuxzwrrrwwwwwwwwwwxrwyzxx}??~~~~~~~~~~~~~>>}>zzuywrxwwwwwwwwwwwwwwwwwwww|<br></br>
-zzzzwwwrwwwwwwwwwwzu}zuwwz?-~~~~~~~~~~~~~~~~|~vzzuz}xwwwwwwwwwwwwwwwwwwww|<br></br>
`wrwwrwwwwwwwwwwwwwzuv}zwww?-~~~~~~~~~~~~~~~~~-vzwzr}xwwwwwwwwwwwwwwwwwwwz|<br></br>
`wzwwwwwwwwwwwwwwwwzurvxwww}~~~~~~~~~~~~~~~~~~~}wwxvwxwwwwwwwwwwrrwrrwwwwz|<br></br>
`wzzzwwwwwwwwwwwwwwzuu?wzwww>~~~-`````````~~~-+wwwwvuzwwzwwwwwwwrrwrrwwwwz|<br></br>
`zzzzzzzzwwwwwwwwwwzuuwruwwwr>-+v?`.....->>`-+rwwwvwuzwwwwwwwwwwrrrrrwwwwz|<br></br>
`zzzzzxxxzwwwwwwwwwxuuuw}rrwz?.+vv`.....^}?.|wwwz}}uxwwwwwwwwwwwrrwwwwwwwz|<br></br>
-xuuuuuuuuxzzzwwwwzuuuuxv>rww^.|.~+~```^+`~.^wwrvvuuuzzzzzwwwzzzzzxzzzzzzz|<br></br>
`}vv?????vvv}wxxxxxwv?>++^+?wv-v^->`--`>^-?~?zv?^>>?v}wxuxxxxxzrv?>>+++++>`<br></br>
.++>>>>>>>>+++>?}?++++^|-~>^`^+?^.``...`.+v>v+|+^>>>+++>?vzx}?++++>>>>>>>>`<br></br>
.^|~------~|^+>+++>^~`...+|...^?~...`-`..^?^. .|+~|++>>>>++>+>>>>+^~------.<br></br>
 ............`-^>>~.....||..>|>}~........|}>`` .-~..``-|+>>>>>^~`......... <br></br>
 ...............`|~....`|...v+`?>`......`?}||v...`......`^>>^`............ <br></br>
 .................`....-...`v?.`?v+....>vv|`?}+..........~+-.............. <br></br>
 ..........................`}}+..?v??>>vv~`?}}?..........-`............... <br></br>
 ...........................^v}v|.-|^|^--|v}}v^........................... <br></br>
 ...........................`~?}}?+`..`^?v}v?-............................ <br></br>
 ...........................?vvvvv?v??vv?vv?>>............................ <br></br>
 ...........................~}vv???v}}v????v}>............................ <br></br>
 ............................>v|..`~>?|-..`vv............................. <br></br>
 -`........`|`...............`v>..........^v`............................. <br></br>
                  .-|~.......^+`...`^..........~v~........`?~..................`..........` <br></br>
 ...-^|`....`++`..`+...........|?-......`>-..................`-.......`-`. <br></br>
.^??-.|+~....|>|..-+............~?|....-^`..............`....^`.....`|-.`~.<br></br>
`v}}}?-->+-..->+-`|+.............`??~~+~................`...`+.....|^`~?v}-<br></br>
`vvv}}v>~>>~.`>+-+>~...............^v>` ................~...~+...`^^^?}}}}-<br></br>
`v}}}}}}?^>>~`>>-++................ .  ................ -``~^+..`+^>}}}vv}-<br></br>
`v}}}}}}}>+>++>>-+-...............``|++--``...........`~-|+~^+`.+++}}}}}}}-<br></br>
`v}}}}}}}v^>>>>+~~^^~-````````-~|^+^>v?+>+++^|~-`...-^+>+-+~|+||>^?vvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrr}vvvvvvvvvvvvv}r?r}vvvv>~-----------|?vvvvr?r}vvvvvvvvvvvvvvvv}}}}r~<br></br>
-uyyyuuzwwwwwzzwwwwrwurzuzwww>~~~~~~~~~~~~~~|?wwwxzrxzwwwwwwwwwwwwwwwwzxxx|<br></br>
-uuyuxzwwwwwwwwwwwwrzz}yzwzz?>~~~~~~~~~~~~~^^>vzzxuvzzwwwwwwwwwwwwwwwwwwwz~<br></br>
-uuuxzwrrwwwwwwwwwwwxrwyzxx}??~~~~~~~~~~~~~>?}>zzuywrxwwwwwwwwwwwwwwwwwwww~<br></br>
-zzzzwwwrwwwwwwwwwwzu}zuwwz?-~~~~~~~~~~~~~~~~|~vzzuz}xwwzwwwwwwwwwwwwwwwww~<br></br>
`wwwwwwwwwwwwwwwwwwzuv}zwww?-~~~~~~~~~~~~~~~~~-vzwzr}xwwzwwwwwwwwwwwwwwwww|<br></br>
`wzwwwwwwwwwwwwwwwwzurvxwww}~~~~~~~~~~~~~~~~~~~}wwxvwxwwzwwwwwwwrrwrrwwwwz|<br></br>
`wzzzzzzzwwwwwwwwwwzuu?wzwww>~~~-`````````~~~-+wwwwvuzwwzwwwwwwwrrwwwwwwwz|<br></br>
`zzzzzzzzwwwwwwwwwwzuuwruwwwr>-+v?`.....->>`-+rwwwvwuzwwzwwwwwwwrrwrwwwwwz|<br></br>
`zzzzzxxzzwwwwwwwwwxuuuw}rrwz?.+vv`.....^}?.|wwwz}}uxwwwzzwwwwwwrrwwwwwwwz|<br></br>
-xuuuuuuuuxzzzwwwwzuuuuxv>rww^.|.~+~```^+`~.^wwrvvuuuzzzzzwwwzzzzzxzzzzzzz|<br></br>
`}vv?????vvv}wxxxxxwv?>++^+?wv-v^->`--`>^-?~?zv?^>>?v}wxuuxxxxzrv?>>+++++>`<br></br>
.+>>>>>>>>>+++>?}?++++^|-~>^`^+?^.``...`.+v>v+|+^>>>+++>?vzx}?++++>>>>>>>>`<br></br>
.^|~------~|^+>+++>^~`...+|...^?~...`-`..^?^. .|+~|^+>>>>++>++>>>+^~------.<br></br>
 ............`-^>>~.....||..>|>}~........|}>`` .-~..``-|+>>>>>^~`......... <br></br>
 ...............`|~....`|...v+`?>`......`?}||v...`......`^>>^`............ <br></br>
 .................`....-...`v?.`?v+....>vv|`?}+..........~+-.............. <br></br>
 ..........................`}}+..?v???>vv~`?}}?..........-`............... <br></br>
 ...........................^v}v|.-^^|^--|v}}v^........................... <br></br>
 ...........................`~?}}?+`..`^?v}v?-............................ <br></br>
 ...........................?vvvvv?v??vv?vv?>>............................ <br></br>
 ...........................~}vv???v}}v????v}>............................ <br></br>
 ............................>v|..`~>?|-..`vv............................. <br></br>
 -`........`|`...............`v>..........^v`............................. <br></br>
                  .-|~.......^+`...`^..........~v~........`?~..................`..........`.<br></br>
 ...-^|`....`++`..`+...........|?-......`>-..................`-.......`-`. <br></br>
.^??-.|>~....|>|..-+............~?|....-^`..............`....^`.....`|-.`~.<br></br>
`v}}}?-->+-..->+-`|+.............`??~~+~................`...`+.....|^`~?v}-<br></br>
`vvv}}v>~>>~.`>+-+>~...............^v>` ................~...~+...`^^^?}}}}-<br></br>
`v}}}}}}?^>>~`>>-++................ .  ................ -``~^+..`+^>}}}vv}-<br></br>
`v}}}}}}}>+>+^>>-+-...............``|++--``...........`~-|+~^+`.+++}}}}}}}-<br></br>
`v}}}}}}}v^>>>>+~~^^~-````````-~|^+^>v?+>+++^|~-`...-^+>+-+~|>||>^?vvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrr}vvvvvvvvvvvvv}rvv}}vvvv+~---------~>vvvvvv}}}vvvvvvvvvvvvvvvv}rrrr~<br></br>
-uyyyyyuxzwwwwwwwwwwwxzxxxwwwv+~~~~~~~~~~~~~+}wwzwzzzzwwwwwwwwwwwwwwwzxxxx|<br></br>
-uuuuuxzwwwwwwwwwwwrwzzzxzzz}?|~~~~~~~~~~~^^??rwzxwrzzwwwwwwwwwwwwwwwwwzwz|<br></br>
-xxxxxzrrwwwwwwwwwwwzzzzxxzw?>~~~~~~~~~~~~|+>?vzzyxzwzwwzwwwwwwwwwwwwwwwww~<br></br>
-zzwwwwwwwwwwwwwwwwwzwzwwwwr^-~~~~~~~~~~~~~~~~>rwzzzwzwzzwwwwwwwwwwwwwwwww~<br></br>
                  `zwwwwwwwwwwwwwwwwwwxwwrwwwr+~~~~~~~~~~~~~~~~~?rwwrzwzwzzwwwwwwwwwwwwwwwww|<br></br>
                  `zwwwwwwwwwwwwwwwwwwxzwwwwww?~~~~-~~~~~~~~~~~|vwwwwwzzwzzwwwwwwwrrwrrwwwwz|<br></br>
`wzwwzzzzwwwwwwwwwwzxxrwwwww}>~~+~```````|~~|?}wwrzrxzwzzwwwwwwwrrwwwwwwwz|<br></br>
`wzzzzzzzwwwwwwwwwwzxuxzzrwww}~~vv-.....>?^`?}wwrwwzxzwwzwwwwzzwrrwwwwwwwz~<br></br>
`zzxxxxxxzzwwwwwwwwxuuuxzrrwzv.|>>|`...-+?^.?wwwwzwuzwwwzzwwwzzwwwwwzzzwwz|<br></br>
-xuxzwrrrrrrwwzzzzxuuuxwv??vwv`|``>-```^``~`?wv?vvzxxxzzxzzzzxxzzzzwwzzzzx|<br></br>
`v?>>>>>>>>>???vrzxr??>+^||+??-?>`|..`.|~^?-?v>+++>>?v}zuuxw}vv??>>++++>>>`<br></br>
.+>>>+++++++^^+>?>+^+^|-``^|.-~?+....`...>?|^-|+^+>>>>+>>?v??>+++^^^^^++++.<br></br>
.^|~-``````-~~||^^+^-....|~..|~?+....`...+v~. .||`-|^+>>++++^^|||~--`````` <br></br>
 .............`-||-`....--..-?^?>`......-?v^^. .-`...`-|++^|||~`.......... <br></br>
 ................-`....``...^?~^+>|...`+>?>>?^ .........`~||~`............ <br></br>
 ......................`....^v+-|?>^^^|??>+>v?`..........-|`.............. <br></br>
 ...........................|??>|^+^^^|+?>?vv>-..........``............... <br></br>
 ...........................`>?v?+^^|~^+>?vv?+`........................... <br></br>
 ```........................`>vvv??>>+>?vvvv?`............................ <br></br>
                  ..`--``....................`?vv????vvv???vv?`........................`-~-.<br></br>
 .....`--`...................+vv^^^>??>+||?v|`.....................`-~-`.. <br></br>
 ........`-`.................`??-.`-||-`.`>^-............``......`~~`..... <br></br>
                  ....-~-`..`|`................~+^`.......~`-............`~.....`~~`..`-`   <br></br>
                  -^+^~-~~~`.-+`...`-`..........-|^-....`-.`.............~`....~|-``~~~~`.. <br></br>
.>>>>>^~-~~~`|+...`|~...........``+^``-`.`.............-|`...|^--~~~-~~^+|.<br></br>
.?vv?+>>^~~~~^+~..`>-............`~^+~..`..............~~`..~^~-~~~-~+++??`<br></br>
`v}}}v?+>>|~~^>^``|+..............^~...................~~``~|+~~~-~+>+?vvv-<br></br>
`v}}}}}?+>>^~|>+-|+`....``........`|+|`.`............```~~|~^>-~~|>>>?????`<br></br>
`v}}}}}}v>??^|?+~++`.-~--````````.``---`----`.....`-~||--~|~>>-~~^|^???vv}~<br></br>
`v}}}}}}}?>?>>>+^+|``~`.  ........``|^^~--~~----`````--~--^~|~..~|+}}}}}}}-<br></br>
`v}}}}}}}v>?>?>+^^|-^`.`--~~~~|^+>>>?v??>>>>>+^||~-~||~|+^>++>+^>+vvvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrrrvvvvvvvvvvvvvvrrvvrvvvv>~---------~?vvv}}?r}vvvvvvvvvvvvvvvvv}r}}r~<br></br>
-uuuyyyyuxwrwwwwwwwwwzuxwyzww}>~~~~~~~~~~~~~>rwzurwxwwwwzzwwwwwwwwwwzzxxxx|<br></br>
`zzxuuuxzwwrwwwwwwwrwxu}uuzzr?^~~~~~~~~~~~+|v?zzuuvxwwwwwwwwwwwwwwwwwwwzwz|<br></br>
                  `zzzzxzrrwwwwwwwwwwwwxxryxzz?+~~~~~~~~~~~~|~+^rzxyrzzwwwzwwwwwwwwwwwwwwwww~<br></br>
`zwwwwwwwwwwwwwwwwwwwxzvzwww+-~~~~~~~~~~~~~~~-?zwx}zzwwzzwwwwwwwwwwwwwwwww~<br></br>
`zzwwwwwwwwwwwwwwwwwwxxvzwwz>~~~~~~~~~~~~~~~~~vzwxvxzwwzzwwwwwwwwwwwwwwwww|<br></br>
`zzwwwwwwwwwwwwwwwwwzxuvzwww}~~~~-~~~~~~--~~-^wwwx}xzwwzzwwwwwzwrrwrrwwwwz|<br></br>
`wzwwzzzzwwwwwwwwwwwzxuwwxrww?~~?|-....`-+~~|}wwwvzxzwwzzwwwwzzwrrwwwwwwwz|<br></br>
`wzzzzzzzwwwwwwwwwwzxxuurrrwww~`v}~.....vv-`}wwz}}uzwwwwzzwwwzzwwrwwwwwwwz~<br></br>
`wzxxuuuuxzzzwwwwwzxuuuuur}rw}`-~+^-..`|>^``}wrwwuuxzwwwzzwwwzxzwwzzzzzwwz|<br></br>
-xxzrvv??vvvrwzxxxxuuxwrv?+?wr|^+`>~~``+.|^|rw?>v}rzxxxxxxxxxuuxwwwwwwzzxx|<br></br>
`?>+++++++++++>?vrz}?>+^|~~|^>->?``....``?>->+++++++>?vrxuxrv?>>++++++>>>?`<br></br>
.+>>>+^^|||||^+++++^^|`...-~...>+...`-...>>. .|^~^+>>>++>?>++++^|~---~~|^^.<br></br>
.+|-`..........`-|+^.....`-..>-v?........>v``..~...`~^+>+++^-`............ <br></br>
 .................`-.....`...v-^?~.....`|v>->...`.....`^>|`............... <br></br>
 ...........................`}^.|}?```-v}+.>}~..........`................. <br></br>
 ...........................`vv~.|????>?|`>}}+............................ <br></br>
 ............................|vv>`..`..`|?}}v~............................ <br></br>
 ............................->v}v+^``|??}}?-............................. <br></br>
.~-``.......................`vvvvvvvvv}?vvv??-...........................`.<br></br>
 ``~||~`.....................?}v+>??v}?>++vv?........................`-|^|.<br></br>
                  .....`||-...................~vv...`^^`...?v`......................-^+|-`. <br></br>
                  ....|^``-|-..................+v^........-v-.............--......-^+~`.``. <br></br>
 ..-^v}v?~`-~..................+v-......`>`.............-^.....`^+~~^?v?.. <br></br>
 |?v}}}}}}?|`|`...`.``..........+v|....-+`...........`.`+~....|>^^?v}}v?~. <br></br>
.v}}v}}}}}}}?~^...``+`...........-v?~-^|..............`|+`..`^++?}}}}}vv}?.<br></br>
`v}}}}}}}}}}}v^|..`+|.............~?v+`...............`+^-.`++?v}vv}}}}}}}~<br></br>
`v}}}}}}}}}}}}?^`.~+..............-`.  ................+|~~++?}}}}}}}}}}}v-<br></br>
`v}}}}}}}}}}}}v^-`~. .`|+^|~~~~~~|++|||^^|-. ........`-~|^>+?}}}rrrvv??>++`<br></br>
`v}}}}}}}}}}}}}+~^|~`?}}???>>>+++++>????vvv??|`...`|++>+~~++vvvv?>+^^+??v}~<br></br>
`v}}}}}}}}}v}}}++|+-?}?-...```-----|^^^++?vvvvv?>|~~|||~||+++|--|>?v}}}}}}-<br></br>
`v}}}}}}}}}}}}?|^+~-v^`|>??vvvvvvvvvvvv}vvvvvvvv}}v??>^+?vvvvvvvvvvvvvvvvv-<br></br>


            </div>,

            <div>

`rrrrrrrvvvvvvvvvvvvvv}rvvrvvvv>~---------~?vvv}}?r}vvvvvvvvvvvvvvvvv}r}}r~<br></br>
-uuuyyyyuxwrwwwwwwwwwzuzryzww}>~~~~~~~~~~~~~>rwzurwxwwzzzzwwwwwwwwwwzzxxxx|<br></br>
-zzxuuuxzwwrwwwwwwwrwxu}uuzzr?^~~~~~~~~~~~+|v?zzuuvxwwzzwwwwwwwwwwwwwwwzwz|<br></br>
                  `zzzzxzwrwwwwwwwwwwwwxxryxzz?+~~~~~~~~~~~~|~+^rzxyrzzwwwzwwwwwwwwwwwwwwwww~<br></br>
`zwwwwwwwwwwwwwwwrwwwxzvzwww+-~~~~~~~~~~~~~~~-?zwx}zzwwzzwwwwwwwwwwwwwwwww~<br></br>
`zzwwwwwwwwwwwwwwwwwwxxvzwwz>~~~~~~~~~~~~~~~~~vzwxvxzwwzzwwwwwzwwwwwwwwwww|<br></br>
`zzwwwwwwwwwwwwwwwwwzxuvzwww}~~~~-~~~~~~--~~-^wwwx}xzwwzzwwwwzzwrrwwwwwwwz|<br></br>
`wwwwzzzzwwwwwwwwwwwzxuwwxrww?~~?|-....`-+~~|}wwwvzxzwwzzzwwwzzwwrwrwwwwwz|<br></br>
`wzwzzzzzwwwwwwwwwwzxxuurrrwww~`v}~.....vv``}wwz}}uzwwwwzzwwwzzwwrwwwwwwwz~<br></br>
`wzxxuuuuxzzzwwwwwzxuuuuur}rw}`-~^^-..`|>^``}wrwwuuxzwwwzzwwwzxzwwzzzzzwwz|<br></br>
-xxzrvv??vvvrwzxxxxuuxwrv?+?wr|^+`>~~``+.|^|rw?>v}rzxxxxxxxxxuuxwwwwwwzzxx|<br></br>
`?>+++++++++++>?vrz}?>+^|~~|^>->?.`....``?>->+++++++>?vrxuxrv?>>++++++>>>?`<br></br>
.+>>>+^^|||||^++++^^^|`...-~...>+..``-...>>. .|^~^+>>>++>?>++++^|~---~~|^^.<br></br>
.+|-`..........`-|+^.....`-..>-v?........>v``..~...`~^+>+++|-`............ <br></br>
 .................``.....`...v-^?~.....`|v>-?...`.....`^>|`............... <br></br>
 ...........................`}^.|}?```-v}+.>}~..........`................. <br></br>
 ...........................`vv~.|????>?|`>}}+............................ <br></br>
 ............................|vv>`..`..`|?}}v~............................ <br></br>
 ............................->v}v+^``|??}}?-............................. <br></br>
.~-``.......................`vvvvvvvvv}?vvv??-...........................`.<br></br>
 ``~||~`.....................?}v+>??v}?>++vv?........................`-|^|.<br></br>
                  .....`||-...................~vv...`^^`...?v`......................-^+|-`. <br></br>
                  ....|^``-|-..................+v+........-v-.............--......-^+~`.``. <br></br>
 ..-^v}v?~`-~..................+v-......`>`.............-^.....`^+~~^?v?.. <br></br>
 |?v}}}}}}?|`|`...`.``..........+v|....-+`...........`.`+~....|>^^?v}}v?~. <br></br>
.v}}v}}}}}}}?~^...``+`...........~v?~-^|..............`|+`..`^++?}}}v}vv}?.<br></br>
`v}}}}}}}}}}}v^|..`+|.............~?v+`...............`+^-.`++?v}vv}}}}}}}~<br></br>
`v}}}}}}}}}}}}?^`.~+..............-`.  ................+|~~++?}}}}}}}}}}}v-<br></br>
`v}}}}}}}}}}}}v^-`~. .`|+^|~-~~~~|++|||^^|-. ........`-~|^>+?}}}rrrvv??>++`<br></br>
`v}}}}}}}}}}}}}+~^|~`?}}???>>>+++^^+????vvvv?|`...`|++>+~~++vvvv?>+^^+??v}~<br></br>
`v}}}}}}}}}v}}}^+|+-?}?-...``------|^^^++?vvvvv?>|~~|||~||+++|--|>?v}}}}}}-<br></br>
`v}}}}}}}}}}}}?|^+~-v^`|>??vvvvvvvvvvvv}vvvvvvvv}}v??>^+?vvvvvvvvvvvvvvvvv-<br></br>


            </div>,

            <div>

`rr}}}r}vvvvvvvvvvvvvv}rvvvvvvv?|---------^?vvvv}vrvvvvvvvvvvvvvvv}}}}r}rr~<br></br>
                  -xxxuuuuxzwwwwwwwwwwwzxxzzxzwr?|~~~~~~~~~~~^vwzzzzzzwzzzzzzwwwwwwwzzzxxxxx|<br></br>
`zzzxxxzzwwwwwwwwwwrwzuzzxzzwv+~~~~~~~~~~~+^}}zzxzwxwwwzzwwwwwwwwwwwwwwwwz|<br></br>
`zzzzzwwwwwwwwwwwwwwwzxzzuzz}+~~~~~~~~~~~~~~^>wwxzzxwwwzzwwwwwwwwwwwwwwwww~<br></br>
                  `zwwwwwwwwwwwwwwwwwwwxxrrzwwv~~~~~~~~~~~~~~~~^rwzwwxwwwzzwwwwzzwwwwwwwwwww~<br></br>
`wwwwwwwwwwwwwwwwwwwwxxr}www}|~~~~~~~~~~~~~~~>rwwrwxwwwzzwwwwzzwwwwwwwwwww|<br></br>
                  `wwwwwwzwwwwwwwwwwwwwxuwrwwww?~~~--------~~~^vwwzwzxwwwzzzwwwzzwwwwwwwwwwz|<br></br>
`wwwwzzzzwwwwwwwwwwzzxuzzwwrwr?->>|....`+>`^vwwwvrxxwwwzzzwwwzxwwwwwwwwwwz|<br></br>
`wzzzzzzzwwwwwwwwwwzxxuuzwvrww?.>?^`...~??.+www}rzuzwwwwzzwwwzxzwwwwwwwwwz|<br></br>
`wzxxuuuuxxzzzzzzzxxuuuuuzrv}w>`~`|`..`^`|`+wr}zxuuxzwwwzzzzxxuxzzzzzzzwwz|<br></br>
`xxxzrvv????vvvrwzxuuxwv?>+>??^|>`|.``.~.+~>}?>?vvrzxxxxxzr}}}}}vv}rwzxxxx|<br></br>
`}v?>++++>+++++>>?vv?>+^|~-~~-.^?``.``.``?^`~+^+++++>?vvvv??>>+++++++>>?v}~<br></br>
`?>>>>+^|~~-~~~|||||^|-...`-.`|>?`.......?>. `|~-|^+>+++++^|||~~----~|^+^>`<br></br>
.+^^~`..........`-|-......`..?>>?|`....`|?>^`.``...`-^+^|||-`.........``~~.<br></br>
.~`................`.........v?^^>>--`->?>^?+...`......-|-...............`.<br></br>
 `..........................`v?^~>>++||?>|+v?...........`................. <br></br>
                  ............................vv?+|^^^^|+^+?v+`............................ <br></br>
 ............................-?vv>|~--^+?v??|.........................```` <br></br>
 ......```...................~?vvv>??>??vvv?`...................`---`````. <br></br>
 .....  ..```........`.......|vv>???vv?>>?v?-................`~~-`.... ... <br></br>
 .......`--``-.......`....```?vv^~|^>>+|~^v^`.............`-~-`..... ..... <br></br>
 ....`^>>^~~--~`.````-----```|??^~----`..|^-.........`...-~```--`...```... <br></br>
 ..`^???>>+|~---`---~-`...... ~>~`-|^~``-`-.........``.`~|~-~~~-~-``...... <br></br>
 .`>?v}vv?>>^---~-``...........~+`..`~^^|~.........`-``-^>~~~--|>>~`~^+|.. <br></br>
 |v}}}v}}}v?|-`.................~+-....~>|-``..`...-~~~~?^-~-~+>>?v>>>>|-. <br></br>
.v}}v}}}}}}}?``.....`............`+^``-`....`--`````~--+?|--~>>?v}}?>>>>?>.<br></br>
`v}}}}}}}}}vv+`....`-`............`^+~.........```-````^+~-~>>?vvvvvvvv}}}~<br></br>
`v}}}}}}}}vv?>^`..`^`.           ....      ...  .......~~|~^+?????????????`<br></br>
`v}}}}}}}}vv?>>`.~|. .`-~~-```````~-------`..  ..........`-`|>>>>??>>+^|~~.<br></br>
`v}}}}}}}}}vv?>||-...+>>+++^^|||~~~~^++++>>+|-..  ..````..`->>>>+|~-~~^+>>`<br></br>
`v}}}}}}}}}vv???>``.^?^``-`````````-~~~~~|+>>>++|-``....`--~~-```~^>>>>>>>.<br></br>
.?vv}}}}}}}}v?>>>|.`>-`^+^+++>>>>>>>>>>>>>>>>>>>>>>+^~-~+>>>^+++++>>>>>>>>.<br></br>


            </div>,

            <div>

`}vvvvvvvvvvvvvvvvvvvvv}rv?}vvv?^--------~^vvv}vvr}vvvvvvvvvvvvvvv}r}r}}rr~<br></br>
                  `zzwwwwzzwwwwwwwwwwwwzzxxruzww?|~~~~~~~~~~|^rzzurxxzwzzzzzzwwwwwwwxuuxxxxx|<br></br>
`zwwwwwwwwwwwwwwwwwrwzxu}xxzzv+~~~~~~~~~~~^^}rxuxruzwwwzzwwwwwwwwwwzzzwwwz|<br></br>
`zzzwwwwwwwwwwwwwwwwwzxx}uzzw^~~~~~~~~~~~~~~~>zzu}xzwwwzzwwwwzzwwwwwwwwwww~<br></br>
`zzwwwwwwwwwwwwwwwwwwzxzvzww}~~~~~~~~~~~~~~~~>wwx}zxwwwzzwwwwzzwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwwwwwwwwwwwzxxvwwww^~~~~~~~~~~~~~~-?wwzvxxwwwzzwwwwzzwwwwwwwwwww|<br></br>
`wwwwwzzwwwwwwwwwwwwwzxuvwzwwv~~~-```````~~->wwzxvuxwwzzzzwwwzzwwwwwwwwwwz|<br></br>
`wwwzzzzzwwwwwwwwwwzzzxux}z}wwv`+?|....`??.>wwww?zuxzwzzzzwwwzxwwwwwwwwwwz|<br></br>
`wzzzzxxzzwwwwwwwwzzzxxuuzv}rwv.|++`..`+?+.?zwz?ruuzwwwzzzzwzxxzwwwwwwwwwz|<br></br>
`wzxxuuuuxxxzxxxxxxxuuuuuuz?vwv->`^-.`~^.|`?z}}zuuuxzwwzzxxxuuuuxxzzzzwwwz|<br></br>
`zxuuxrv???????vvrzuuxwv?>+^|^^~v|`....``v`?v+>??vrzxuuxxwv???????v}wxuxzx|<br></br>
`xxr?>++>>>+++++++>??>^|~```~. ->`..``...+~.`^^+++++?v}?>+++++++++++>>?}xu|<br></br>
`}>+>>+^~``......``-~^-......->^}`.......v^..--.``~^+^^++|~``....``-|^+++?~<br></br>
.+++^-`......................?v-?>`....`>v-?`.`.....`|^`..............`~++.<br></br>
.^-`.........................v}-.?v^^|^v?`~}^............................~.<br></br>
.-``.........................v}?`.+??+++.-v}+............................. <br></br>
 `...........................?}}v~.....`^v}?`............................. <br></br>
 `````.......................`>v}v?+~~>vvv^`.........................``---.<br></br>
 ``````-```..................^??vv?v}}vvv???`..................`-~||~~~---.<br></br>
 .......`-~~`........~.......`v?|^>?v?+|-|v?................`~^^|-`....... <br></br>
 ......~?v?+||`...`-`^--~~^>??vv~..`-`...~v`..........`...`|^|~|^`........ <br></br>
 ....~?v}}}}v>^`.+?++?vvv??>>++>??|~....`?~.........`-`.`|^|+?vv?......... <br></br>
 ..-?v}}vv}}}}?^>vvv}v>~``.......`|>?>-`+`..........~-.`^+?v}}}vv>........ <br></br>
 .-v}}}}}vv}vvvvvv?+~`..............-+?v>-.....`...~^-`^?v}}}vv}}}^....... <br></br>
 |v}}}v}}}v}?|^|-`.....``..............~>vv>|```...|+^^?}}}vvvv}}}v`...... <br></br>
.v}}}}}}}}}}?........`|~.................`~+?vv>||^+>^?}}vvvvvvvvvv~.```~|.<br></br>
`v}}}}}}}}}v>`......|+-.....................`~^>?????+>?vvvvvvvvvvvvvvvv}}~<br></br>
`v}}}}}}}}v?`.....->^................................``-+?????>^|~~~~~~|||.<br></br>
`v}}}}}}}}v?~...->>`.......``............................................. <br></br>
`v}}}}}}}}}v?`.+v^.......`~-............................................`` <br></br>
`}}}}}}}}}}v?~|rv`......~+-.............................................`` <br></br>
.+?v}}}}}}v}?-|}}+....`>^`......-`........................................ <br></br>


            </div>,

            <div>

`}vvvvvvvvvvvvvvvvvvvvv}rv?}vvv?^--------~^vvv}vvr}vvvvvvvvvvvvvvvrrrr}}rr~<br></br>
                  `zzwwwwzzwwwwwwwwwwwwzzxxruzww?|~~~~~~~~~~|^rzzurxxzwzzzzzzwwwwwwzxuuxxxxx|<br></br>
`zwwwwwwwwwwwwwwwwwwwzxu}xxzzv+~~~~~~~~~~~^^}rxuxruzwwwzzwwwwwzwwwwzzzwwwz|<br></br>
`wwzwwwwwwwwwwwwwwwwwzxx}uzzw^~~~~~~~~~~~~~~~>zzu}xxwwwzzwwwwzzwwwwwwwwwww~<br></br>
`zwwwwwwwwwwwwwwwwwwwzuzvzww}~~~~~~~~~~~~~~~~>wwx}zuzwzzzzwwwzzwwwwwwwwwww~<br></br>
                  `wwwwwwzwwwwwwwwwwwzzzuxvwwww^~~~~~~~~~~~~~~-?wwzvxuzzzzzzwwwzzwwwwwwwwwww|<br></br>
`wwzwwzzwwwwwwwwwwwzzzuuvwzwwv~~~-```````~~->wwzxvuuxzzzzzwwwzzwwwwwwwwwwz|<br></br>
`wzzzzzxzwwwwwwwwwzzzzxux}z}wwv`+?^....`??.>wwww?zuxzzzzzzwwwzxwwwwwwwwwwz|<br></br>
`wzzzzxxxzwwwwwwwwzxxxxuuzv}rwv.|++`..`+v+.?zwz?ruuxzzzzzzzwzxxzwwwwwwwwwz|<br></br>
`wzxxuuuuuxxxxxxxxxxuuuuuuz?vwv->`^-.`~^.|`?zr}zuuuxzzzzzxxuuuuuxxzzzzwwwz|<br></br>
`zxuuxwv???????vvrzuuxwv?>+^|^^~v|`....`-v`?v+>??vrzxuuuxwv???????v}wxuxzx|<br></br>
`xxr?>++>>++++++++>??>^|~```~. ->`..``...+-.`^^+++++>v}?>+++++++++++>>?}zu|<br></br>
`}>+>>+^~``......``-~^-......->^}`.......v^..--.``~^+^^++|~``....``-|^+++?~<br></br>
.+++^-`......................?v-?>-....`>v-?`.`.....`|^`..............`~++.<br></br>
.^-`.........................v}-.?v^^|^v?`~}^............................~.<br></br>
.-``.........................v}?`.+??+++.-v}+............................. <br></br>
 `...........................?}}v~.....`^v}?`............................. <br></br>
 `````.......................`>v}v?+~~>vvv^`.........................``---.<br></br>
 ``````````..................^??vv?v}}vvv???`..................`-~||~~~--`.<br></br>
 .......`-~~`........~.......`v?|^>?v?+|-|v?................`~^^|-`....... <br></br>
 ......~?v?+||`...`-`^-~~~^>??vv~..`-`...~v`..........`...`|^|~|^`........ <br></br>
 ....~?v}}}}v>^`.+?++?vvv??>>++>??|~....`?~.........`-..`|^|+?vv?......... <br></br>
 ..-?}}}vv}}}}?^>vvv}v>~``.......`|>?>-`+`..........~-.`^+?v}}}vv>........ <br></br>
 .-v}}}}}vv}vvvvvv?+~`..............-+?v>-.....`...~^-`+?v}}}vv}}}^....... <br></br>
 |v}}}v}}}v}?|^|-`.....``..............~>vv>|```...|+^^?}}}vvvv}}}v`...... <br></br>
.v}}}}}}}}}}?........`|~.................`~+?vv>||^+>^?}}vvvvvvvvvv~.```~|.<br></br>
`v}}}}}}}}}v>`......|+-.....................`~^>?????>>?vvvvvvvvvvvvvvv}}}~<br></br>
`v}}}}}}}}v?`.....->^................................``-+?????>^|~~~~~~|||.<br></br>
`v}}}}}}}}v?~...->>`.......``............................................. <br></br>
`v}}}}}}}}}v?`.+v^.......`~-............................................`` <br></br>
`}}}}}}}}}}v?~|rv`......~+-.............................................`` <br></br>
.+?v}}}}}}v}?-|}}+....`>^`......-`........................................ <br></br>


            </div>,

            <div>

`}vvvvvvvvvvvvvvvvvvvvv}rv?}vvv?^--------~^vvv}vvr}vvvvv}}vvvvvvvvrrrr}}rr~<br></br>
                  `zzwwwwzzwwwwwwwwwwwwzzxxruzww?|~~~~~~~~~~|^rzzurxxzwzzzzzzwwwwwwzxuuxxxxx|<br></br>
`zwwwwwwwwwwwwwwwwwwwzxu}xxzzv+~~~~~~~~~~~^^}rxxxruxwwwzzwwwwwzwwwwzzzwwwz|<br></br>
`wwzwwwwwwwwwwwwwwwwwzxx}uzzw^~~~~~~~~~~~~~~~>zzu}xxwwzzzwwwwzzwwwwwwwwwww~<br></br>
`zwwwwwwwwwwwwwwwwwzzzxzvzww}~~~~~~~~~~~~~~~~>wwx}zuzzzzzzwwwzzwwwwwwwwwww~<br></br>
                  `wwwwwwwwwwwwwwwwwzzzzuxvwwww^~~~~~~~~~~~~~~-?wwzvxuzzzzzzwwwzzwwwwwwwwwww|<br></br>
`wwzwwzzzwwwwwwwwwzzzzuuvwzwwv~~~-```````~~->wwzxvuuxzzzzzzwwzzwwwwwwwwwwz|<br></br>
`wzzzzzxzwwwwwwwwwzzzzxux}z}wwv`+?^....`??.>wwww?zuxxzzzzzzwwxxwwwwwwwwwwz|<br></br>
`wzzzxxuxzwwwwwwwwzxxxxuuzv}rwv.|++`..`+v+.?zwz?ruuxzzzwzzzwzxxzwwwwwwwwwz|<br></br>
`wzxxuuuuuxxxxxxxxxuuuuuuuz?vwv->`^-.`~+.|`?zr}zuuuxzzzzzxxuuuuuxxzzzzwwwz|<br></br>
`wzuuxrv???????vvrxuuxwv?>+^|+^~v|`....``v`?v+>??vrzxuuuxwv???????v}wxuxzx|<br></br>
`xxr?>++>>++++++++>??>^|~```~. ->`..``...+~.`^^+++++>?}?>+++++++++++>>?}xu^<br></br>
`}>+>>+^~``......``-~^-......->^}`.......v^..--.``~^+^^++|~``....``-|^+++?~<br></br>
.+++^-`......................?v-?>`....`>v-?`.`.....`|^`..............`~++.<br></br>
.^-`.........................v}-.?v^^|^v?`~}^............................~.<br></br>
.-``.........................v}?`.+??+++.-v}+............................. <br></br>
 `...........................?}}v~.....`^v}?`............................. <br></br>
 `````.......................`>v}v?+~~>vvv^`.........................``---.<br></br>
 ``````-```..................^??vv?v}}vvv???`..................`-~||~~~--`.<br></br>
 .......`-~~`........~.......`v?|^>?v?+|-|v?................`~^^|-`....... <br></br>
 ......~?v?+||`...`-`^-~~~^>??vv~..`-`...~v`..........`...`|^|~|^`........ <br></br>
 ....~?v}}}}v>^`.+?++?vvv??>>++>??|~....`?~.........`-..`|^|+?vv?......... <br></br>
 ..-?}}}vv}}}}?^>vvv}v>~``.......`|>?>-`+`..........~-.`^+?v}}}vv>........ <br></br>
 .-v}}}}}vv}vvvvvv?+~`..............-+?v>-.....`...~^-`+?v}}}vv}}}^....... <br></br>
 |v}}}v}}}v}?|^|-`.....``..............~>vv>|```...|+^^?}}}vvvv}}}v`...... <br></br>
.v}}}}}}}}}}?........`|~.................`~+?vv>||^+>^?}}vvvvvvvvvv~.```~|.<br></br>
`v}}}}}}}}}v>`......|+-.....................`~^>?????>>?vvvvvvvvvvvvvvv}}}~<br></br>
`v}}}}}}}}v?`.....->^................................``-+?????>^|~~~~~~|||.<br></br>
`v}}}}}}}}v?~...`>>`.......``............................................. <br></br>
`v}}}}}}}}}v?`.+v^.......`~-.............................................` <br></br>
`}}}}}}}}}}v?~|rv`......~+-............................................... <br></br>
.+?v}}}}}}v}?-|}}+....`>^`......-`........................................ <br></br>


            </div>,

            <div>

`rrrrrrrv?vvvvvvvvvvvvvv}r?}vvv>~--------~>vvv}?r}}vvvvv}vvvvvvvvvvvv}rrrr~<br></br>
-uuyyyuuzwwwwwwwwwwwwwzzurxzzw?^~~~~~~~~~~+?wzxz}uxzwzzzzzwwrwwwwwwwwzxuuy^<br></br>
`xuuyuuxwwwwwwwwwwwwwwzxzwuzz}^^~~~~~~~~~~^>?wxyvwuxwwzzwwwwwwwwwwwwwwzxuu|<br></br>
                  `wzxuuxwrwwwwwwwwwwzzzzurxxww^-~~~~~~~~~~~~~~vzzxvuuzwzzzwwwwzzwwwwwwwwzzw~<br></br>
`wwwzzwwwwwwwwwwwwzzzzxu}zzww^~~~~~~~~~~~~~~~}wwxvuuxzzzzzwwwzxwwwwwwwwr}}-<br></br>
`wwwrrwwwwwwwwwwwwzzzzxu}vwww?~~~~~~~~~~~~~->wwwrvuuxzzzzzzwwzzwwwwwwwwr}}-<br></br>
`wzzwwwzzwwwwwwwwwzxxzxuxvuwww>~~~``````-~~^rwwxrzuuxxzzzzzwwzzwwwwwwwwwrr~<br></br>
`wzzzzxxzwwwwwwwwwzxzzxuuwrrwww~-v+....~v+.}wwrrruuxxzzzzzzwwxzwwwwwwwwwww|<br></br>
`wzzxxuuuxxzzzzzzzzxxzxuuuw}}zw~`-^-..`+^^.vwwr}uuxxxzzzzzzzzxxzwwwwwwwwwz|<br></br>
`wzzxuuuuxwrrrrwzxuuuuuuuzwv??}|?+~-.``|`?~}wvwuuuuxxxxxxxxzxxxxxxzzwwwwwz|<br></br>
`wzxuuwv>+++++++>>?}zxr?>+^|||.`>^......`>`|++???vrzuuzrv?>>>>>>??}zxxzzwz|<br></br>
`wxxv+^+>>+^|~~---~~^+^|-`..-.~-?-..``...?~ `|~|^+++??>+^|~~~~|^^+++?vzuzz|<br></br>
`zw>^>>^~``..........``......`v`?+......|v~~.`...``|+|`..........`-|+++?zu|<br></br>
-}++^|`......................~}~.??~```>v~~v........`...............`~++^}^<br></br>
.++^~`.......................|}?..>??>??-`v}`.........................`^>^`<br></br>
.^|-`........................~}}?`..````~v}?...........................`^>.<br></br>
.~~-````..............````---^vvvv>^`.~?vv+`...........................`~^.<br></br>
.~~~~~~~||~-.......-++^^^||~~~|>?v?vvvvvv?>^..................``-~~|||||||.<br></br>
 ........`|~~-.....`............`+??vv?>^?}^...............-~~~-``````````.<br></br>
 ......`^?vv>|~`......`-||~`......~>^-`..~?.............`~~-~^`........`+>.<br></br>
 .....~?}}}}}v+||`.~+>?>|`.........`>>`..+`......`.``..-||>v}v?`.........+-<br></br>
 ....|v}vv}}}}v??`|v+~`.....`........^?>^`.......-`~-`|+?}}}}}}^...........<br></br>
 ...^}}}}}v}}}?v~.?>.....`~-`.........`^?^`......-||~|?}}v}}}}v?.........-.<br></br>
 ..~v}v}}}}}}}v?>.?>..`~^~`.............`~^~`.....+^+v}}vv}}vv}v`........|-<br></br>
 .-v}}}}}}}}v}}??.-?>^^~`..................`~+-`..-|v}}}}}}}}}}?`..........<br></br>
 .>}}}}}}}}}}}}???~`?>....`-~`...............`~^+>^+vvvvvvvvvvv^--~~~~~|||.<br></br>
 .-?v}}}}}}}}}}}}}~~^?>--||~`....................``---~~~|||||~~~~--`````` <br></br>
 `--|>vvvv}}}}}}}?~`..^?>`.......```...................................... <br></br>
`vvvvvvvvvvvvvvv>|++^-`^?`..`+??>+vv>-.................................... <br></br>
.--`--~|^>?vvv??v``|^++++???v}v??->}}v>~-~|+?^~`.......................... <br></br>
 ``........-^?vvv>-..`-~~~|>^```~+|^>?vvvvvvvv}v?|``.....-|~--``.......... <br></br>
 ``...........`^?}}v+`.............>`........`|??~v?`.->?v}vvv???-........ <br></br>


            </div>,

            <div>

`rrrrrrrv?vvvvvvvvvvvvvv}r?}vvv>~--------~>vvv}?r}}vvvvv}vvvvvvvvvvvv}rrrr~<br></br>
-uuyyyuuzwwwwwwwwwwwwwwzurxzzw?+~~~~~~~~~~+?wzxz}uxzwzzzzzwwrwwwwwwwwzxuuy^<br></br>
`xuuyuuxwwwwwwwwwwwwzzzxzwuzz}^^~~~~~~~~~~^>?wxyvwuxzwzzwwwwwwwwwwwwwwzxuu|<br></br>
                  `wzuuuxwrwwwwwwwwwzzzzzurxxww^-~~~~~~~~~~~~~~vzzzvuxzzzzzzwwwzzwwwwwwwwzzw~<br></br>
`wwwzzwwwwwwwwwwwwzzzzxu}zzww^~~~~~~~~~~~~~~~}wwxvuuxzzzzzwwwzzwwwwwwwwr}}-<br></br>
`wwwrrwwwwwwwwrwwwzzzzxu}vwww?~~~~~~~~~~~~~->wwwrvuuxzzzzzzwwzzwwwwwwwww}}-<br></br>
`wzzwwwzzwwwwwwwwwzzxzxuzvuwww>~~~```````~~^rwwxrzuxxxzzzzzwwzzwwwwwwwwwrr~<br></br>
`wzzzxxxzwwwwwwwwwzzzzzuuwrrwww~-v>....~v+.}wwrrruuxxzzzzzzwwzzwwwwwwwwwww|<br></br>
`wzzxxuuuxxzzzzzzzzxxzzxuuw}}zw~`-^-..`^^^.vwwr}uuxxxzzzwzzzzxxzwwwwwwwwwz|<br></br>
`wzxxuuuuxwrrrrwzxuuuxxuuzwv??}|?+~-.``|`?~}wvwuuuuxxxxxxxxzxxxxxxzzwwwwwz|<br></br>
`wzxuuwv>++++++>>>?}zxr?>+^|||.`>^......`>`|++???vrzuuzrv?>>>>>>??}zxxzzwz|<br></br>
`wxuv+^+>>+^|~~---~~^+^|-`..-.~-?-..``...?~ `|~|^+++??>+^|~~~~|^^+++?vzuzz|<br></br>
`zw>^>>^~``..........``......`v`?+......|v~~.`...``|^~``.........`-|+++?zu|<br></br>
-}++^|`......................~}~.??~```>v~~v........`...............`~>+^}^<br></br>
.++^~`.......................|}?..>??>??-`v}`.........................`^>^`<br></br>
.^|-`........................~}}?`..````~v}?...........................`^>.<br></br>
.|~-````..............````---^vvvv>^`.~?vv+`...........................`~^.<br></br>
.~~~~~~~||~-.......-++^^^||~~~|>?v?vvvvvv?>^..................``-~~|||||~|.<br></br>
 ........`|~~-.....`............`+??vv?>^?}^...............-~~~-``````````.<br></br>
 ......`^?vv>|~`......`-|^~`......~>^-`..~?.............`~~-~^`........`+>.<br></br>
 .....~?}}}}}v+||`.~+>?>|`.........`>>`..+`......`.``..-~|>v}v?`.........+-<br></br>
 ....|v}}}}}}}v??`|v+~`.....`........^?>^`.......-`~-.|+?}}}}}}^...........<br></br>
 ...^}}v}}}}}}?v~.?+.....`~-`.........`^?^`......-||~|?}}v}}}}}?.........~.<br></br>
 ..~v}}}}}}}v}v??.?>..`~^~`.............`~^~`.....+^+v}}vv}}vv}v`........^-<br></br>
 .-v}}}v}}}}v}}??.-?>^^~`..................`~+-`..-|v}}}}}}}}}}?`..........<br></br>
 .>}}}}}}}}}}}}???~-?>....`-~`...............`~^+>^+vvvvvvvvvvv^--~~~~~||^.<br></br>
 .-?v}}}}}}}}}}}}}~~^?>--||~`....................``---~~~|||||~~~---`````` <br></br>
 `--|?vvvv}}}}}}}?|`..^?>`.......```...................................... <br></br>
`vvvvvvvvvvvvvvv>|++^-`^?`..`+??>+vv>-.................................... <br></br>
.--`--~|^>?vvv??v``|^++++???v}v??->}}v>~-~|+?^~`.......................... <br></br>
 `.........-^?vvv>-..`-~~~|>^```~+|^>?vvvvvvvv}v?|``.....-|~--``.......... <br></br>
 ``...........`^?}}v+`.............>`........`|??~v?`.->?v}vvv???-........ <br></br>


            </div>,

            <div>

`rrrrrrrv?vvvvvvvvvvvvvv}r?}vvv>~--------~>vvv}?r}}vvvvv}vvvvvvvvvvvv}rrrr~<br></br>
-uyyyyuuzwwwwwwwwwwwwzzzurxzzw?^~~~~~~~~~~+?wzxz}uxzzzzzzzwwrwwwwwwwwzxuyy^<br></br>
`xuuyuuxwwwwwwwwwwwwzzzxzwuzz}^^~~~~~~~~~~^>?wxyvwuxzzzzwwwwwwwwwwwwwwzxuy|<br></br>
                  `zzuuuxwrwwwwwwwwwzzzzxurxxww^-~~~~~~~~~~~~~~vzzzvuuzzzzzzwwwwzwwwwwwwwzzw~<br></br>
`wwwzzwwwwwwwrrwwwzzzzxu}zzww^~~~~~~~~~~~~~~~}wwxvuuxzzzzzzwwzzwwwwwwwwr}}-<br></br>
`wwwrrwwwwwwwwrrwwzzzzxu}vwww?~~~~~~~~~~~~~->wwwrvuuxzzzzzzwwzzwwwwwwwww}}-<br></br>
`wzzwwwzzwwwwwwwwwzzzzxuzvuwww>~~~```````~~^rwwxrzuxxxzzzzwwwzzwwwwwwwwwrr~<br></br>
`wzzxxxxzwwwwwwwwwzxzzzxuwrrwww|-v>....~v+.}wwrrruxxxzxzzwwwwzzwwwwwwwwwwz|<br></br>
`wzzxuuuxxxzzzzzzzzxxzzxuuw}}zw~`-^-..`^^^.vwwr}uuxxzzzzwwwwzxxzwwwwwwwwwz|<br></br>
`wzzxuuuuxwrrrrwzxuuuxxuuzwv??}|?+~-.``|`?~}wvwuuuuxxxxxxxxzxxxxxxzwwwwwwz|<br></br>
`wzxuuwv>++++++>>>?}zxr?>+^|||.`>^......`>`|++???vrzuuzrv?>>>>>>??}wxxzzwz|<br></br>
`wxuv+^+>>+^|~~---~~^+^|-`..-.~-?-..``...?~ `|~|^+++??>+^|~~~~|^^+++?vzuzz|<br></br>
`zw>^>>^~``..........``......`v`?+......|v~~.`...``|^~``.........`-|+++?zu|<br></br>
-}++^|`......................~}~.??~```>v~~v........`...............`~>+^}^<br></br>
.++^~`.......................|}?..>??>??-`v}`.........................`^>^`<br></br>
.^|-`........................~}}?`..````~v}?...........................`^>.<br></br>
.|~-````..............````---^vvvv>^`.~?vv+`...........................`~^.<br></br>
.~~~~~~~||~-.......-++^^^||~~~|>?v?vvvvvv?>^..................``-~~|||||~|.<br></br>
 ........`|~~-.....`............`+??vv?>^?}^...............-~~~-``````````.<br></br>
 ......`^?vv>|~`......`-|^~`......~>^-`..~?.............`~~-~^`........`+>.<br></br>
 .....~?}}}}}v+||`.~+>?>|`.........`>>`..+`......`.``..-~|>v}v?`.........+-<br></br>
 ....|v}}}}}}}v??`|v+~`....``........^?>^`.......-`~-.|+?}}}}}}^...........<br></br>
 ...^}}v}}}}}}?v~.?+.....`~~`.........`^?^`......-||~|?}}v}}}}v?.........~.<br></br>
 ..~v}}}}}}}v}v??.?>..`~^~`.............`~^~`.....+^+v}}vv}}vv}v`........^-<br></br>
 .-v}}}v}}}}v}}??.-?>^^~`..................`~+-`..-|v}}}}}}}}}}?`..........<br></br>
 .>}}}}}}}}}}}}???~-?>....`-~`...............`~^+>^+vvvvvvvvvvv^--~~~~~|||.<br></br>
 .-?v}}}}}}}}}}}}}~~^?>--||~`....................``---~~~|||||~~~---`````` <br></br>
 `--|?vvvv}}}}}}}?|`..^?>`.......```...................................... <br></br>
`vvvvvvvvvvvvvvv>|++^-`^?`..`+??>+vv>-.................................... <br></br>
.-``--~|^>?vvv??v``|^++++???v}v??->}}v>~-~|+?^~`.......................... <br></br>
 `.........-^?vvv>-..`-~~~|>^```~+|^>?vvvvvvvv}v?|``.....-|~--``.......... <br></br>
 ``...........`^?}}v+`.............>`........`|??~v?`.->?v}vvv???-........ <br></br>


            </div>,

            <div>

`rrrrrrrv??vvvvvvvvvvvvv}r?vvvv>~--------~?vv}}?r}}vvvvv}vvvvvvvvvvv}rrrrr~<br></br>
                  -uuuyyyyuzwwwwwwwwwwzzzzuwzxzz?+~~~~~~~~~|??wzuzruxzzwzzzzwwwwwwwwwwzxuuyy^<br></br>
                  `zxxuuuuxzwwwwwwwwwwzzzxzryzxv^^~~~~~~~~~~^??zxyvzuxzzzzwwwwwwwwwwwwwzxuuu|<br></br>
`wwwzxuxwrrrrrrwwwzzzzxuwzxww+-~~~~~~~~~~~~~~rzxz}uxzzzzzzzwwwwwwwwwwwwzzw~<br></br>
`wwwwwwwrrrwrrrrwwzzzzxurwwww^~~~~~~~~~~~~~~|rwzxvuuxzzzzzzwwzzwwwwwwwr}}v-<br></br>
`wwzzwwwwwwwwwrrwwzzzzxuwvwww?~~~~~~~~~~~~~-?www}}uuxzzzzzwwwzzwwwwwwwrr}}-<br></br>
`wzxxzzzwwwwwwwwwwzzzzxuu}uwww?~~~```````~~>wwwxrxuxxxxzzzwwwzzwwwwwwwwrrw~<br></br>
`wzxxxxxzwwwwwwwwwzzzzzxuzrrrww|`?>....~v^`wwwr}ruxxxxxzwwwwwzzwwwwwwwwwwz|<br></br>
`wzxxuuuxxxxzzzzzzxxxzwzxuz}}ww~.`|-..`^||`}wwvruuxxzzxzwwwwzxxzwwwwwwwwwz|<br></br>
`wzzxuuuuxwr}v}}rzuuuxxxxzw}??v|>>~`..`~-?^}}vwuuuuxxxuxxxzwzzxxxxzwwwwwwz|<br></br>
`wzxuuwv>++++++++>?vrz}?>>^|||..+~...`..`>`~^+>??vwxuurv?>>>>>>>?vrzxxzwzz|<br></br>
`wzur>^+>>+^~------~|^+^-`..-.|-?`..`...`?-.`|~|^++>?>^^||~~||^++>+>?wuuzz|<br></br>
`zx?^+>+|`............``.....`v->+......+v`|.-...`-^|-`.........`-^+>+?zux|<br></br>
-z>^^^-......................~}^.>?|``|?v`^v..`....`...............`|>++vu|<br></br>
`>+^|~`......................~}v-.+??>?>`-v}`........................`+>^?^<br></br>
.^|-`................`-------^vv?|`....`|v}?..........................`+>^.<br></br>
.^^^|||~-`...........`---~----~+???>~`^?vv^............................-^+.<br></br>
.~-````--~~~-.......`....``-`...`~>v}vvvv??|.................``---~~~||||^.<br></br>
 ........`^^~~-.....`-|++^~`......|??v?^~>}`.............`-~~-`.......```^.<br></br>
 ......`+v}}v^~~-?|`??^~`..........|v+...~>............`~^^>>^.........|+|.<br></br>
 .....-?}}}v}}?~?|.^?.....```.......-?>.`+.........``.-^>?v}}}~.........+?.<br></br>
 ....-v}}}}}}}}v?+.>?..`~||`.........`+?>`.......``~`->v}}}}}}>..........?~<br></br>
 ...`v}v}}}}}}}}?>.~?>>>|`.............`+>|......`|||>v}vv}}}}v`........|`.<br></br>
 ...?}}}}}}}}}}}v?~..v>....``............`|>~~`...^|>}}}}}}}}}v`........+?.<br></br>
 ..`v}}}}}}}}v}}}v.. |v~`-|~`..............`-^++++++vvvvvvvvvv?`````----`?~<br></br>
 ...|?vvv}}}}}}}}?. ..^??|`.....`...............`--|^++>>>>?>>>>>>>>>+++||.<br></br>
.|+???v?????vv}}}>|`.. -v|.`~^>>?>+`...................................... <br></br>
`vv???>??vvv????>|>+^|~`|???vv?+~v}v>-..````..........`~~-`-`............. <br></br>
 ``......`-+?vvv>-`~^++>+^>?>++>>>?v}v????vv?|....`|+?vvv}vvv?~........... <br></br>
 ...........`-^?v}?+-..````````..``.-~--`.`^?|~^>?v??^~``|?v}}}?+-........ <br></br>
 ...............`|>?vv?^-`.................-^+>+>^-........`|>v}}}?^-. ... <br></br>
.^-`................`~^>?vv?>^~`........|>>^-`.`..............`|?vv}v?~... <br></br>


            </div>,

            <div>

`rrrrrrr}??vvvvvvvvvvvvv}r?vvvv>~--------~>vv}}?r}}vvvvv}vvvvvvvvvvv}rrrrr~<br></br>
                  -uuuyyyyuzwwwwwwwwwwzzzzuwzxzz?+~~~~~~~~~|??wzuzruxzzwzzzzwwwwwwwwwwzxuuyy^<br></br>
                  `zxxuuuuxzwwrrrwwwzwzzzxxryzxv^^~~~~~~~~~~+??zxyvzuxzzzwwwwwwwwwwwwwwzxuuu|<br></br>
`wwwzxuxwrrrrrrrwwzzzzxuwzxww+-~~~~~~~~~~~~~~rzxz}uxzzzzzzzwwwwwwwwwwwzzzr~<br></br>
`wwwwwwwrrrrrrrrwwzzzzxurwwww^~~~~~~~~~~~~~~|wwzxvuuxzxzzwzwwwwwwwwwwwr}}v-<br></br>
`wzzzwwwwwwwwwrrwwzzzzxuwvwww?~~~~~~~~~~~~~-?www}}uxxxxzwwzwwwwwwwwwwwrr}}-<br></br>
`wzxxxzzzwwwwwwwwwzzzzzuu}uwww?~~~```````~~>wwwxrxuxxxxzwwwwwwwwwwwwwwwrrw~<br></br>
`wzxxxxxzzwwwwwwwwzxzzwzuzrrrww|`?>....-v^`wwwr}ruxxxxxzwwwwwwwwwwwwwwwwwz|<br></br>
`wzxxxxuxxxxzzzzxxxxxzwzxuz}}ww~.`|-..`^||`}wwvruuxzzzxzwwwwzzzzwwwwwwwwwz|<br></br>
`wzzxuuuuxwr}v}}rzuuuxxxxzw}??v|>+~`..`~-?|}}vwuuuuxzxuxxzzwzzxxxxzwwwwwwz|<br></br>
`wzxuuwv>++++++++>?vrz}?>>^|||..+~......`>`~^+>??vwxuxrv?>>>>>>>?vrzxxzwzz|<br></br>
`wxur>^+>>+^~------~|^+^-`..-.|-?`..`...`?`.`|~|^++>?>^^||~~||^++>+>?wuuzz|<br></br>
`zx?^+>+|`............``.....`v->+......+v`|.-...`-^|-`.........`-^+>+?zux|<br></br>
-z>^^^-......................~}^.>?|``|?v`^v..`....`...............`|>+^vu|<br></br>
`>+^|~`......................~}v-.+??>?>`~v}`........................`+>^?^<br></br>
.^|~`................`-------^vv?|`....`|v}?..........................`+>^.<br></br>
.^^^|||~-`...........`---~----~+???>~`^?vv^............................-^+.<br></br>
.~-````--~~~-............``-`...`~>v}vvvv??|.................``---~~~||~|^.<br></br>
 ........`^^~~-.....`-|++^~`......|??v?^->}`.............`-~~-`.......```^.<br></br>
 ......`+v}}v^~~-?|`??^~`..........|v+...~>............`~^^>>^.........|+|.<br></br>
 .....-?}}}v}}?|?|.^?.....```.......-?>``+.........``.-+>?v}}}~.........>?.<br></br>
 ....-v}}}}}}}}v?+.>?..`~||`.........`+?>`.......``~`->v}}}}}}>..........?~<br></br>
 ...`v}v}}}}}}}}?>.~?>>>|`.............`+>|......`|||>v}vv}}}}v`........|`.<br></br>
 ...?}}}}}}}}}}}v?~..v>....``............`|>~~`...^|>}}}}}}}}}v`........+?.<br></br>
 ..`v}}}}}}}}v}}}v.. |v~`-|~`..............`-^++>+++vvvvvvvvvv>`````----`?~<br></br>
 ...|?vvv}}}}v}}}?. ..^??|`.....`...............``-|^++>>>>>>>>>>>>>>+++||.<br></br>
.|+???v?????vv}}}>|`.. -v|.`~^>>?>+`...................................... <br></br>
`vv???>??vvv????>|>+^|~`~???vvv+~v}v>-...```..........`~~-`-`............. <br></br>
 ``......`-+?vvv>-`~^++>+^>?>++>>>?v}v????vv?|....`|+?vvv}vvv?~........... <br></br>
 ...........`-^?v}?+-..````````..``.-~--`.`^?|~^>?v??^~``|?v}}}?+-........ <br></br>
 ...............`|>?vv?^-`.................-^+>+>^-........`|>v}}}?^-. ... <br></br>
.^-`................`~^>?vv?>^~`........|>>^-`.`..............`|?vv}v?~... <br></br>


            </div>,

            <div>

`rrrrrrr}??vvvvvvvvvvvvv}r?vvvv>~--------~>vv}}?r}}vvvvvvvvvvvvvvvvv}rrrrr~<br></br>
                  -uuuyyyyuzwwwwwwwwwwzzzzuwzxzz?^~~~~~~~~~|??wzuzruxzzwzwzzwwwwwwwwwwzuuyyy^<br></br>
                  `zzxuuuuxzwwrrrwwwzwzzzxxryzxv^^~~~~~~~~~~+??zxyvzuxzzzwwwwwwwwwwwwwwzxuuu|<br></br>
`wwwzxuxwrwrrrrrwwzzzzxuwzxww+-~~~~~~~~~~~~~~rzxz}uxzzzzzzzwwwwwwwwwwwzzwr-<br></br>
`wwzwwwwrrrrrrrrwwzzzzxurwwww^~~~~~~~~~~~~~~|wwzxvuxzzxzzzzwwwwwwwwwwwr}}v-<br></br>
`wzxzwwwwwwwwwrrwwzzzzxuwvwww?~~~~~~~~~~~~~-?www}}uxxxxzwwwwwwwwwwwwwwrr}}~<br></br>
`wzxxxzzzwwwwwwwwwzzzzzxu}uwww?~~~```````~~>wwwxrxuxxxxzwwwwwwwwwwwwwwrrrw~<br></br>
`wzxxxxxxzwwwwwwwwzxzwwzuzrrrww|`?>....~v^`wwwr}ruxxxxxzwwwwwwwwwwwwwwwwwz|<br></br>
`wzxxxxxxxxxzzzzxxxxxzwzxuz}}ww~.`|-..`^||`}wwvruuxzzzxzwwwwzzzzwwwwwwwwwz|<br></br>
`wzxxxuuuxwr}v}}rzuuuxxuuzw}??v|>>~`..`~-?|}}vwuuuuxzxuxzzzwzzxxxxzzwwwwwz|<br></br>
`wzxuuwv>++++++++>?vrz}?>>^|||..+~......`>`~^+>??vwxuxrv?>>>>>>>?vrzxzzwzz|<br></br>
`wxur>^+>>+^~--`---~|^+^-`..-.|-?`..`...`?`.`|~|^++>?>^^||~~||^++>+>?wuxzz|<br></br>
`zx?^+>+|`............``.....`v->+......+v`|.-...`-^|-`.........`-^+>+?zux|<br></br>
-z>^+^-......................~}^.>?|``|?v`^v..`....`...............`|>+^vu|<br></br>
`>+^|~`......................~}v-.+??>?>`~v}`........................`+>^?^<br></br>
.^|~`................`-------^vv?|`....`|v}?..........................`+>^.<br></br>
.^^^|||~-`...........`---~--~-~+???>~`^?vv^............................-^+.<br></br>
.~-````--~~~-............``-`...`~>v}vvvv??|.................``---~~~||~|^.<br></br>
 ........`^^~~-.....`-|++^~`......|??v?^->}`.............`-~~-`.......```^.<br></br>
 ......`+v}}v^~~-?|`??^~`..........|v+...~>............`~^^>>^.........|+|.<br></br>
 .....-?}}}v}}?|?|.^?.....```.......-?>``+.........``.-+>?v}}}~.........>?.<br></br>
 ....-v}}}}}}}}v?+.>?..`~||`.........`+?>`.......``~`->v}}}}}}>..........?~<br></br>
 ...`v}v}}}}}}}}?>.~?>>>|`.............`+>|......`|||>v}vv}}}}v`........|`.<br></br>
 ...?}}}}}}}}}}}v?~..v>....``............`|>~~`...^|>}}}}}}}}}v`........+?.<br></br>
 ..`v}}}}}}}}v}}}v.. |v~`-|~`..............`-^++>+++vvvvvvvvvv>`````----`?~<br></br>
 ...|?vvv}}}}v}}}?. ..^??|`.....`...............``-|^++>>>>>>>>>>>>>>+++^|.<br></br>
.|+???v?????vv}}}>|`.. -v|.`~^>>?>+`...................................... <br></br>
`vv???>??vvv????>|>+^|~`~???vvv+~v}v>-...```..........`~~-`-`............. <br></br>
 ``......`-+?vvv>-`~^++>+^>?>++>>>?v}v????vv?|....`|+?vvv}vvv?~........... <br></br>
 ...........`-^?v}?+-..````````..``.-~--`.`^?|~^>?v??^~``|?v}}}?+-........ <br></br>
 ...............`|>?vv?^-`.................-^+>+>^-........`|>v}}}?^-. ... <br></br>
.^-`................`~^>?vv?>^~`........|>>^-`.`..............`|?vv}v?~... <br></br>


            </div>,

            <div>

`rrrrrrr}???vvvvvvvvvvvv}r?v}vv>~--------~?vv}vvr}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-xxxuuyyyuzwrwwwwwwwzzzzuwwxzz?+~~~~~~~~~|>vzzurzuxzwwzwzzwwwwwwwwwzuuyyyy^<br></br>
`zzwwzxuuxzwrrrrwwzzzzzxx}yzxr+^~~~~~~~~~~^??zuuvuxzzzzwwwwwwwwwwwwwzxuuuu|<br></br>
`wwzzwwzwrrrrrrrwwzzzzxuwwxww>-~~~~~~~~~~~~-^wzurwuxzzzzwwzwwwwwwwwwwzzzw}-<br></br>
`wzxxzwwwrrrrrrrwwzzzzxu}wzww>-~~~~~~~~~~~~~+wwzz}uxzxxzwwzwwwwwwwwwwr}}v}-<br></br>
`wzxxxxzwwwwwwrrwwzzzzzuw?wwwv~~~-~~~~~~~~~-vwwzvruxxxxzwwwwwwwwwwwwwr}}rw~<br></br>
`wzuxxxxzzwwwwwwwwzzzwwxuvxwww?~~-`.````-~~?wwwx}xuxxxxzwwwwwwwwwwwwwwrrwz|<br></br>
`wzxxxxxxzzwwwwwwwzxzwwzuz}rrwz^.?+....|v~-wwww}zuxxxxxzwwwwwwwwwwwwwwwwwz|<br></br>
`wzxxxxxxxxxxxxxxxxxxwwzxuxr}ww|.`~-..`^-|`rwwvwuuxzzzxzwwwwzzzzwwwwwwwwwz|<br></br>
`wzzxxxuuuwrvvvvvwxuuxxuuzw}?>?~>?``.`.~~?|}}vzuuuuxzxuxzwwwwzzxuxxzwwwwwz|<br></br>
`wzxuux}?>++++++++>?vw}??+^~~~..+|......`>`-^+>??}wxuz}?>>++++>>?vrxxzwwzz|<br></br>
`wzuxv++>>+|~-````-~~|^^-`..`.^`?~......`?`.-|-|^++>>+^||~~~~|^++>+>vxuxzz|<br></br>
`zur+^>+~`.............`.....`v-^>`.....>?-^.-...`-^~`..........`~+>+>}uux|<br></br>
-uv|^^|`.....................~r+.^v^--^v?.>v.......................-++^vuu|<br></br>
-v||~~-`...............`~---`|vv~.|>?+>^.^}v........................`|++?u^<br></br>
.^|-`..................````````~>+`....->}v^.........................`+>^v^<br></br>
.++^^^|||~-`............``---`...`+>|~>?v?~....................```````~^+^-<br></br>
.-`.....``-~~-.....-^.+>>^~``.....`?vv?vv??~...............`~||~~~~~~~~|++.<br></br>
 ........`^?>||`..-?-`?`...........`??>~`>v..........`..`~|~~`........--^+.<br></br>
 .......+v}}}v+|-.~>.+>..`~~-`......`?+..~|.........~-.~||+??+........`^||.<br></br>
 .....`?}}}}}}}?^`.>`~?^>+~`.........`>?`~.........~|`~^?v}}}}~........-v?.<br></br>
 ....`?}v}}}}}}}?|.^|``v`....`.........|?~........`^|`+v}}v}vv+.........-v-<br></br>
 ....?}v}}}}}}}}v+~+...>|`-~-`..........`>+^|-`...|+~>}}}}}}}}>....``..|~``<br></br>
 ...-vvvv}}}v}}}}+++.. .>v^`..............`~^+>>+^++^vvvvvvvv}^........`v+.<br></br>
 ...~>??>>?vv}}}}++~. ...v+`-^>|^~~............```-~>>>+>>>>>?>>>>>>>>+~|v`<br></br>
.+?v}}}}}vv???vv}^^`. ...`?vv??~?}}>`...........`~+?v?vvvvv?|`````````-~~|`<br></br>
`}v?>+^^^^+>?vvv?^++^```..`^^|+>+?}}v>++++~..-^???+~`.`~+?v}}v?^-......... <br></br>
.~`..........`|+???>+++++++>>++^|~-||^|~|??+>+>^`.........`~>?v}}v>-...... <br></br>
 ................`^>????>>^~`..........~^+|-.``.........`....``|+?}v...... <br></br>
.``.................`-|>??vvv????>>^|+>^-...............|+|-`....`}?...... <br></br>
`vv?>^~-`.................``--~~|^>+|-...................`-~^^-.`^?>...... <br></br>
.~^+>??v?>^.``.........................................`.....-?>~`^>...... <br></br>


            </div>,

            <div>

`rrrrrrrrv??vvvvvvvvvvvv}r?v}vv>~--------~?vv}vvr}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-xxxuuyyyuzwrwwwwwwwzzzzuwwxzz?+~~~~~~~~~|>vzzurzuxzwwzwwwzwwwwwwwwzuuyyyy^<br></br>
`zzwwzxuuxzwrrrrwwwwwzzxx}yzxr+^~~~~~~~~~~^??zuuvuxzwzzwwwzwwwwwwwwwzxuuux~<br></br>
`wwzzwwzwrrrrrrrwwwzzzxuwwxww>-~~~~~~~~~~~~-^wzurwuxzzzzwwzwwwwwwwwwwzzzw}-<br></br>
`wzxxxzwwrrrrrrrwwzzzzzu}wzww>-~~~~~~~~~~~~~+wwzz}uxxxxzwwzwwwwwwwwwwr}}v}-<br></br>
`wzuxxxzwwwwwwrrwwzzzzzuw?wwwv~~~-~~~~~~~~~-vwwzvruxxxxzwwwwwwwwwwwwwr}}rw|<br></br>
`wzuxxxxzwwwwwwwwwzzzwwxuvxwww?~~-`.````-~~?wwwx}xuxxxxzwwwwwwwwwwwwwwrrwz|<br></br>
`wxxxxxxxzzwwwwwwwzzzwwzuz}rrwz^.?+....|v~-wwww}zuxxzxxzwwwwwwwwwwwwwwwwwz|<br></br>
`wzzzzzxxxxxxxxxxxxxzwwzxuxr}ww|.`~-..`^-|`rwwvwuuxzzzzzwwwzzzzzwwwwwwwwwz|<br></br>
`wzzzzzuuxwrvvvv}wxuuxxuuzw}?>?~>?``.`.~~?|}}vzuuuuxzzuxzwwwwzzxuxxzwwwwwz|<br></br>
`wzzxux}?>++++++++>?vw}??+^~~~..+|......`>`-^+>??}wxuz}?>>++++>>?vrxxzwwzz|<br></br>
`wzuxv++>>+|~-````-~~|^^-`..`.^`?~......`?`.-|-|^++>>+^||~~~~|^++>+>vxuzzz|<br></br>
`zur+^>+~`.............`.....`v-^>`.....>?-^.-...`-^~`..........`~+>+>}uxx|<br></br>
-uv|^^|`.....................~r+.^v^--^v?.>v.......................-++^vuu|<br></br>
-v||~~-`...............`~---`|vv~.|>?+>^.^}v........................`|++?u^<br></br>
.^|-`..................````````~>+`....->}v^.........................`+>^v^<br></br>
.++^^^|||~-`............``---`...`+>|~>?v?~....................```````~^+^-<br></br>
.-`.....``-~~-.....-^.+>>^~``.....`?vv?vv??~...............`~||~~~~~~~~|++.<br></br>
 ........`^?>||`..-?-`?`...........`??>~`>v..........`..`~|~~`........--^+.<br></br>
 .......+v}}}v+|-.~>.+>..`~~-`......`?+..~|.........~-.~||+??+........`^||.<br></br>
 .....`?}}}}}}}?^`.>`~?^>+~`.........`>?`~.........~|`~^?v}}}}~........-v?.<br></br>
 ....`?}v}}}}}}}?|.^|``v`....`.........|?~........`^|`+v}}v}vv+.........-v-<br></br>
 ....?}v}}}}}}}}v+~+...>|`-~-`..........`>+^|-`...|+~>}}}}}}}}>....``..|~``<br></br>
 ...-vvvv}}}v}}}}+++.. .>v^`..............`~^+>>+^++^vvvvvvvv}^........`v+.<br></br>
 ...~>??>>?vv}}}}++~. ...v+`-^>|^~~............```-~>>>+>>>>>?>>>>>>>>+~|v`<br></br>
.+?v}}}}}vv???vv}^^`. ...`?vv??~?}}>`...........`~+?v?vvvvv?|`````````--~|`<br></br>
`}v?>+^^^^+>?vvv?^++^```..`|^|+>+?}}v>++++~..-^???+~`.`~+?v}}v?^-......... <br></br>
.~`..........`|+???>+++++++>>++^|~-||^|~|??+>+>^`.........`~>?v}}v>-...... <br></br>
 ................`^>????>>^~`..........~^+|-.``.........`....``|+?}v...... <br></br>
.``.................`-|>??vvv????>>^|+>^-...............|+|-`....`}?...... <br></br>
`vv?>^~-`.................``--~~|^>+|-...................`-~^^-.`^?>...... <br></br>
.~^+>??v?>^.``.........................................`.....-?>~`^>...... <br></br>


            </div>,

            <div>

`rrrrrrrr???vvvvvvvvvvvv}r?v}vv>~--------~?vv}vvr}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-xxxuuyyyuzwrwwwwwwwzzzzuwwxzz?+~~~~~~~~~|>vzzurzuxzwwzwwwzwwwwwwwwzuuyyyy|<br></br>
`zzwwzxuuxzwrrrrwwwwwzzxx}yzxr+^~~~~~~~~~|^??zuuvuxzwzzwwwzwwwwwwwwwzxuuux~<br></br>
`wwzzwwzwrrrrrrrwwwwzzzuwwxww>-~~~~~~~~~~~~-^wzurwuxzzzzwwzwwwwwwwwwwzzzw}-<br></br>
`wzxxxzwwrrrwrrrwwwzzzzu}wzww>-~~~~~~~~~~~~~+wwzz}uxxxxzwwzwwwwwwwwwwr}}v}-<br></br>
`wzuxxxzwwwwwwrwwwzzzwzuw?wwwv~~~-~~~~~~~~~-vwwzvruxxxxzwwwwwwwwwwwwwr}}rw|<br></br>
`wxuxxxxzwwwwwwwwwzzzwwxuvxwww?~~-`.````-~~?wwwx}xuxxxxzwwwwwwwwwwwwwwrrwz|<br></br>
`zxxxzzxzzwwwwwwwwzzzwwzuz}rrwz^.?+....|v~-wwww}zuxxzzxzwwwwwwwwwwwwwwwwwz|<br></br>
`wzzzzzzxxxxxxxxxxxxzwwzxuxr}ww|.`~-..`^-|`rwwvwuuxzzzzzwwwzzzzwwwwwwwwwwz|<br></br>
`wzzzzzxuxwrvvvv}wxuuxxuuzw}?>?~>?``.`.~~?|}vvzuuuuzzzxxzwwwwzzxuxxzwwwwwz|<br></br>
`wwzxxz}?>++++++++>?vw}??+^~~~..>|......`>`-^+>??}wxuz}?>>++++>>?vrxxzwwzz|<br></br>
`wzuxv++>>+|~-````-~~|^^-`..`.^`?~......`?`.-|-|^++>>+^||~~~~|^++++>vxxzzz|<br></br>
`zur+^>+~`.............`.....`v`+>`.....>?-^.-...`-^~`..........`~+>+>}uxx|<br></br>
-uv|^^|`.....................~r+.^v^--^v?.>v.......................-++^vuu|<br></br>
-v||~~-`...............`~---`|vv~.|>?+>^.^}v........................`|++?u^<br></br>
.^|-`..................````````~>+`....->}v^.........................`+>^v^<br></br>
.++^^^|||~-`............``---`...`+>|~>?v?~....................```````~^+^-<br></br>
.-`.....``-~~-.....-^.+>>^~``.....`?vv?vv??~...............`~||~~~~~~~~|++.<br></br>
 ........`^?>||`..-?-`?`...........`??>~`>v..........`..`~|~~`........--^+.<br></br>
 .......+v}}}v+|-.~>.+>..`~~-`......`?+..~|.........~-.~||+??+........`^||.<br></br>
 .....`?}}}}}}}?^`.>`~?^>+~`.........`>>`~.........~|`~^?v}}}}~........-v?.<br></br>
 ....`?}v}}}}}}}?|.^|``v`....`.........|?~........`^|`+v}}v}vv+.........-v-<br></br>
 ....?}v}}}}}}}}v+~+...>|`-~-`..........`>+^|-`...|+~>}}}}}}}}>....``..|~``<br></br>
 ...-vvvv}}}v}}}}+++.. .>v^`..............`~^+>>+^++^vvvvvvvv}^........`v+.<br></br>
 ...~>??>>?vv}}}}++~. ...v+`-^>|^~~............```-~>>>+>>>>>?>>>>>>>>+~|v`<br></br>
.+?v}}}}}vv???vv}^^`.....`?vv??~?}}>`...........`~+?v?vvvvv?|``````````-~|`<br></br>
`}v?>+^^^^+>?vvv?^++^```..`^^|+>+?}}v>++++~..-^???+~`.`~+?v}}v?^-......... <br></br>
.~`..........`|+???>+++++++>>++^|--||^|~|??+>+>^`.........`~>?v}}v>-...... <br></br>
 ................`^>????>>^~`..........~^+|-.``.........`....``|+?}v...... <br></br>
.``.................`-|>??vvv????>>^|+>^-..............`|+|-`....`}?...... <br></br>
`vv?>^~-`.................``--~~|^>+|-...................`-~^^-.`^?>...... <br></br>
.~^+>??v?>^.``.........................................`.....-?>~`^>...... <br></br>


            </div>,

            <div>

`rrrrrrrrv???vvvvvvvvvvv}r?v}vv>~--------~?vv}v}r}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-uxzzzxuyuxzrrwwwwwwwwwxuwrxzz?+~~~~~~~~~^>rzzuruuxzwwzwwwzzwwwwwwwxuyyyuy|<br></br>
`xzwzwwzzxxzrrrwwwwwwwwxxvuzzr^^~~~~~~~~~|^??zux}uxzzzzwwwzwwwwwwwwwxuuuuz~<br></br>
`zwwxxxwwrrrrrrwwwwwwwzuwruww>-~~~~~~~~~~~~-^wzurxuxzxxzwzzwwwwwwwwwwzxzr}-<br></br>
`wwzxxxxzwrrwwrwwwzzwwzuwrzww>-~~~~~~~~~~~~~+wwzwwuxxxxxzzzwwwwwwwwwwr}}}w~<br></br>
`wzxxxxxzwwwwwwwwwzzwwwxz?zww}|~~-~~~~~~~~~~vwwwvzuxxxxxzwzzwwwwwwwwwrrrwz|<br></br>
`wzxzzzxzwwwwwwwwwzzzwwzuvxwwwv|-|`.``.`-~~?wwwx}uuzzzxzzwzzwwwwwwwwwwwwzz|<br></br>
`wzzzzzzzwwwwwwwwwzzzwwzxx}}}rz^.?+....+v~|zwrr}zuxzzzzzwwwwwwwwwwwwwwwwzz|<br></br>
                  `wzwwzwzzzxxxxxxxxxxzwwzxuurvww+-`~`..`^~^~wwrvzuuxzwwzzwwzxzzzzwwwwwwwwwz|<br></br>
`wwwwzzxuuzrvvvv}wzuuxxuxw}v?+?~>?`....`^?^}v}xuuuxzwzxxzzwwwzxuuxzwwwwwwz|<br></br>
`wwwzxx}?>++++++++>?vr}?>+^~~|..+~...`..->.`^+>??vwxxwv?>>>+++>??}zxzwwwwz|<br></br>
`wwzx}+^++^|-``````-~|^+|`..-.+`?^......-?`.~-`~|^++>^|~---~~|^+>++?zxzwzz|<br></br>
`wxz>^+^-`.............``....`v-~>-....`?+~^.-....~~`..........`-^>++vuxxz|<br></br>
-uw>^^^-.................-~~-|?+`|v>^~>v+.??.`....................-++^?xux|<br></br>
-w^|-```....................```~+>~++^+~.?}?........................~++>xu|<br></br>
.+^|~--``..............`|~~~-`...`|`...~?}?`........................`^>+?u^<br></br>
.++^||~~||||-.......|>~>+````.....-?^^??v>~`.................``----~-~|+^v|<br></br>
.`.........-||~.....>|`v`.```......~vvv???v-..............-~~--```---~|+>+.<br></br>
 ........|?vv>||`...`|`?^+^~`.......~?+`.+?.............-~--`........`~|>>`<br></br>
 ......`?}}}}}v^|`...~``}^...`.......~>..|-......`....`-|>vvv>........-+|+`<br></br>
 .....-v}v}}}}}v^|.`....??~|~`........|?`........-...`|?v}}}v?`........+v+.<br></br>
 .....?}}}}}}}}}?|`^`....?>`...........~+^>|`...`|-`~^?vv}}}vv~........ ^v`<br></br>
 ..``~^>>??v}}}}v~^+`....`?+>?>~-~`......`~+?>~>?>>?v}v??vvvvv-.......`+`^`<br></br>
.^?v}}}}}vv???v}v~>+`. ...-?++^?}}?`.....`-^??+~`..`-^>?vvvvv?|+^^~`...>v?~<br></br>
`}}v??>>>>???????~+~..........``?v}v>+^~^>++^`..........`-|+?vv^+>>>+~``v}-<br></br>
.^-`........``~+>>+|^|-`.........`--~>?+~`.........`||~`.....?v`....`~-.`v~<br></br>
 ................`~^++>>>+^^+++++^^^>|`..............-~^^|`.~??-.........`.<br></br>
                  .~....................``--~~~||||++-...............`....`-?+~`?`.......... <br></br>
`>~v?>+^~--`.....................`.................~^~`...?|.+~.. ........ <br></br>
 ..--~~~~~-`.`......................................`~++~>^.-+... ........ <br></br>
                  ......... .....................................```...|v`..|-............ <br></br>
  ......... ............. ........................`~^-`>~...`............. <br></br>


            </div>,

            <div>

`rrrrrrrrv???vvvvvvvvvvv}r?v}vv>~--------~?vv}v}r}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-uxzzzxuyuxzrrwwwwwwwwwxuwrxzz?+~~~~~~~~~^>rzzuruuxzwwzwwwzzwwwwwwwxuyyyuy|<br></br>
`xzwzwwzzxxzrrrwwwwwwwwxxvuzzr^^~~~~~~~~~|^??zux}uxzzzzwwwzwwwwwwwwwxuuuuz~<br></br>
`zwwxxxwwrrrrrrwwwwwwwzuwruww>-~~~~~~~~~~~~-^wzurxuxzxxzwzzwwwwwwwwwwzxzw}-<br></br>
`wwzxxxxzwrrwwrwwwzzwwzuwrzww>-~~~~~~~~~~~~~+wwzwwuxxxxxzzzwwwwwwwwwwr}}}w~<br></br>
`wzxxxxxzwwwwwwwwwzzwwwxz?zww}|~~-~~~~~~~~~~vwwwvzuxxxxxzwzzwwwwwwwwwrrrwz|<br></br>
`wzzzzzzzwwwwwwwwwzzwwwzuvxwwwv|-|`.``.`-~~?wwwx}uuzzzzzzwzzwwwwwwwwwwwwzz|<br></br>
`wzzzzzzzwwwwwwwwwzzwwwzxx}}}rz^.?+....+v~|zwrr}zuxzwzzzzwzzwwwwwwwwwwwwzz|<br></br>
                  `wzwwzwzzzxxxxxxxxxzzwwzxuurvww+-`~`..`^~^~wwrvzuuxzwwzzzzzxxzzzwwwwwwwwwz|<br></br>
`wwwwzzxuuzrvvvv}wzuxxxuxw}v?+?~>?`....`^?^}v}xuuuxzwzxuxzwwwzxuuxzwwwwwwz|<br></br>
`wwwzxx}?>++++++++>?vr}?>+^~~|..+~...`..->.`^+>??vwxxwv?>>>+++>??}zxzwwwwz|<br></br>
`wwzx}+^++^|-``````-~|^+|`..-.+`?^......-?`.~-`~|^++>^|~---~~|^+>++?zxzwwz|<br></br>
`wxz>^+^-`.............``....`v-~>-....`?+~^.-....~~`..........`-^>++vuxzz|<br></br>
-uw>^^^-.................-~~-|?+`|v>^~>v+.??.`....................-++^?xux|<br></br>
-w^|-```....................```~+>~++^+~.?}?........................~++>xu|<br></br>
.+^|~--``.............``|~~~-`...`|`...~?}?`........................`^>+?u^<br></br>
.++^||~~||||-.......|>~>+````.....-?^^??v>~`.................``----~-~|+^v|<br></br>
.`.........-||~.....>|`v`.```......~vvv???v-..............-~~--```---~|+>+.<br></br>
 ........|?vv>||`...`|`?^+^~`.......~?+`.+?.............-~--`........`~|>>`<br></br>
 ......`?}}}}}v^|`...~``}^...`.......~>..|-......`....`-|>vvv>........-+|+`<br></br>
 .....-v}v}}}}}v^|.`....??~|~`........|?`........-...`|?v}}}v?`........+v+.<br></br>
 .....?}}}}}}}}}?|`^`....?>`...........~+^>|`...`|-`~^?vv}}}vv~........ ^v`<br></br>
 ..``~^>>??v}}}}v~^+`.. .`?+>?>~-~`......`~+?>~>?>>?v}v??vvvvv-.......`+`^`<br></br>
.^?v}}}}}vv???v}v|>+`. ...-?++^?}}?`.....`-^??+~`..`-^>?vvvvv?|+^^~`...>v?~<br></br>
`}}v??>>>>???????~+~..........``?v}v>+^~^>++^`..........`-|+?vv^+>>>+~``v}-<br></br>
.^-`........``~+>>+|^|-`.........`--~>?+~`.........`||~`.....?v`....`~-.`v~<br></br>
 ................`~^++>>>+^^+++++^^^>|`..............-~^^|`.~??-.........`.<br></br>
                  .~....................``--~~~||||++-...............`....`-?+~`?`.......... <br></br>
`>~v?>+^~--`.....................`.................~^~`...?|.+~.. ........ <br></br>
 ..--~~~~~-`.`......................................`~++~>^.-+... ........ <br></br>
                  ......... .....................................```...|v`..|-............ <br></br>
  ......... ............. ........................`~^-`>~...`............. <br></br>


            </div>,

            <div>

`rrrrrrrrv???vvvvvvvvvvv}r?v}vv>~--------~?vv}v}r}}vvvvvvvvvvvvvvvv}rrrrrr~<br></br>
-uxzzzxuyuzzrrwwwwwwwwzxuwrxzz?+~~~~~~~~~^>rzzuruuxzwzzwwzzzwwwwwwwxuyyyuu|<br></br>
                  `xzwzwwwzxxzrrrwwwwwwwzxxvuzzr^^~~~~~~~~~|+??zuuruxzzzzzwzzwwwwwwwwwxuuuuw-<br></br>
`zwwxxxwwrwrrrrwwzzwwwzuwruww>-~~~~~~~~~~~~-^wzurxuxxxxzwzzwwwwwwwwwwzxzr}-<br></br>
`wwzxxxzzwrrwwrwwzzwwwwxwrzww>-~~~~~~~~~~~~~+wwzwwuxzxxxzzzzwwwwwwwwwr}}}w~<br></br>
`wzxxxxxzwwwwwwwwzzzwwwxz?zww}|~~-~~~~~~~~~~vwwwvzuxzxxxzzzzzwwwwwwwrrrrwz|<br></br>
`wzzzzzzwwwwwwwwwzzzwwwzuvxwwwv|-|`.``.`-~~?wwwx}uxzzzzzzzzzzwwwwwwwwrwwzz|<br></br>
`wzwzzzzwwzwwzwwwzzzwwwzxx}}}rz^.?+....+v~|zwrr}zuxwwwwzzzzzzwwwwwwwwwwwzz|<br></br>
                  `wwwwzwzzzxxxxxxxxxzwwwzxuurvww+-`~`..`^~^~wwrvzuxzwwwwzzzzxxzzzwwwwrwwwwz|<br></br>
`wwwwzzxuuzrvvvv}wzxxxxuxw}v?+?~>?`....`^?^}v}xuuuxwwzxuxzwwwzxuuxzwwwwwwz|<br></br>
`wwwzxx}?>++++++++>?vr}?>+^~~|..+~...`..->.`^+>??vwxxwv?>>>+++>??}zxzwwwwz|<br></br>
`wwzx}+^++^|-``````-~|^+|`..-.+`?^......-?`.~-`~|^++>^|~---~~|^+>++?zxzwwz|<br></br>
`wxz?^+^-`.............``....`}-~>-....`?+~^.-....~~`..........`-^>++vuxzz|<br></br>
-uw>^^^-.................-~~-|?+`|v>^~>v+.??.`....................-++^?xxz|<br></br>
-w^|-```....................```~+>~++^+~.?}?........................~++>xx|<br></br>
.+^|~--``.............``|~~~-`...`|`...~?}?`........................`^>+?u^<br></br>
.++^||~~||||-.......|>~>+````.....-?^^??v>~`.................``----~-~|+^v|<br></br>
.`.........-||~.....>|`v`.```......~vvv???v-..............-~~--```---~|+>+.<br></br>
 ........|?vv>||`...`|`?^+^~`.......~?+`.+?.............-~--`........`~|>>`<br></br>
 ......`?}}}}}v^|`...~``}^...`.......~>..|-......`....`-|>vvv>........-+|+`<br></br>
 .....-v}v}}}}}v^|.`....??-|~`........|?`........-...`|?v}}}v?`........+v+.<br></br>
 .....?}}}}}}}}}?|`^`....?>`...........~+^>|`...`|-`~^?vv}}}vv~........ ^v`<br></br>
 ..``-^>>??v}}}}v~^+`.. .`?+>?>~-~`......`~+?>~>?>>?v}v??vvvvv-.......`+`^`<br></br>
.^?v}}}}}vv???v}v|>+`  ...-?++^?}}?`.....`-^??+~`..`-^>?vvvvv?|+^^~`...>v?~<br></br>
`}}v??>>>>???????~+~..........``?v}v>+^~^>++^`..........`-|+?vv^+>>>+~``v}-<br></br>
.^-`........``~+>>+|^|-`.........`--~>?+~`.........`||~`.....?v`....`~-.`v~<br></br>
 ................`~^++>>>+^^+++++^^^>|`..............-~^^|`.~??-.........`.<br></br>
                  .~.`..................``--~~~||||++-...............`....`-?+~`?`.......... <br></br>
`>~v?>+^~--`.....................`.................~^~`...?|.+~.. ........ <br></br>
 ..--~~~~~-`.`......................................`~++~>^.-+... ........ <br></br>
                  ......... .....................................```...|v`..|-............ <br></br>
  ......... ............. ........................`~^-`>~...`............. <br></br>


            </div>,

            <div>

`rrrrrrrrv????vvvvvvvvvv}rvv}vv>~--------~?vv}v}r}}vvvvvvvv}vvvvvvv}rrrrrr~<br></br>
-uuzzzzxuuzzrrrwwwwwwwzxuwrxzz?+~~~~~~~~~^>rzzuruuxzzzzzzzzzwwwwwwwxuyyyuu|<br></br>
                  -xzwwzzwzxxzwrrwwwwwwwwxxvuzzr^+~~~~~~~~~^+??zuxruxzzzzzzzzwwwwwwwwwxuuuuw-<br></br>
`zwwzxxzwwwwrrrwwzzwwwwxzruww>-~~~~~~~~~~~~-^wzxrxuzzxxxzzzzwwwwwwwwzxxzr}-<br></br>
`wwzxxxxzwrrwwwwwzzwwwwxzwzww>-~~~~~~~~~~~~~>wwzwzuxzxxxxzzzwwwwwwwwwr}}rw~<br></br>
`wwzxxxxzwwwwwwwwzzwwwwxx?zww}|~~-~~~~~~~~~~vwwwvzuzzzxxxxxzzwwwwwwwrrrwzz|<br></br>
`wwzzzzzwwwwwwwwwzzzwwwzu}xwwwv|-|-.``.`-~|vwwwx}uxzwwzzzzzzzwwwwwwwwwwwzz|<br></br>
`wzwzzzzwwwwwzzwwzzzwwwzuu}}}wz^.?+....+v~|zwrr}zuzwwwwzzzzzzwwwwwwwwwwwzz|<br></br>
                  `wwwwzwzzzzxxxxxxxxzwwwzuuurvww+---`..`^-^~wzrvzuxzwwwwzzzxuuxzzwwwwrwwwzz|<br></br>
`wwwwzzxuxzw}vvv}wzxxxuuxw}v?+?~>?``...`^?^vv}xuuuxzwzxuxzwwwzxuuxzwwwwwzz|<br></br>
`wwwwxxr?>++++++++>?vr}?>+|~~~..+~...`..->.`^+>??}wxxrv?>>>++>>?vrxxzwwwwz|<br></br>
`wwwx}>^+>+|~-``````-||+|`..`.+`?+......-?`.~-`~|^++>^|~---~~|^+>++?zxzwwz|<br></br>
`wzz?|++~`.............``....`v-->-....`?^|^.-....~~`..........`~+>++vuxzz|<br></br>
`xz>^^+~`................````~>+|^v?^|>v^.v?.`....................~++^?xxz|<br></br>
`w+|-``.................````````|+~^+|^-`?}?.......................`|++?ux|<br></br>
.+^^|~--``...........`-->|---`....~-...|?}?`........................`+>^vu|<br></br>
.++^|~~~~|||~`.......>~^?`````....`?^+??}?~.................``---~~--~|++r|<br></br>
.-.........-|~~`....`-`>+~~~-`.....-?vv??vv`.........`...`-~-```````-~|+++`<br></br>
 ........|?vv?||-....``|^?~`........-?^`.+?.........-`.`----`........~||>>`<br></br>
 ......`?}}}}}v+|-......|>-```.......~+..~`......`.--.`~^?vvv+........|^|>`<br></br>
 .....`?}}}}}}}v^^.-....`~>~``........|>~`.....`.-`-.`|?}}}}v?........`??|.<br></br>
 ...``>vvvv}}}}}?|-^`....`+^|^|```.....-||+|-``.`^|^+>?vvvvv}v-.........>>`<br></br>
 -|+>??????vvvv}v|++`.....-||++>??|.....-|>>>^`~^||+?????vvvv?`.......-+`~.<br></br>
.?vv}}vvvvv????vv|>+`. ....````^v}v^~-~~~-~~^^~`..```-|+>>?v??>>>^~`..`???.<br></br>
`vv?++^^^^^+++++>-^~`...........-|+>?>|`-~~~-`....`~~```.`^?>>>|^+>>^-.^vv-<br></br>
.-`..........`-~|~~-~~````````````---|^~`...........-~~~~--~~+>....`-~``+v-<br></br>
.-...............`-~~~|||~~~~|~~|~`-|-`..........``..`-->~`|-^^`........`^.<br></br>
`+.++|~--```..........``````-----~~``............``~-`.~~`>|``|..........`.<br></br>
.~`>+^^|~-``.....................`.................`~^^-.`>-.~`........... <br></br>
                  .````````..`..................................```.`^+~`||.`~............ <br></br>
                  ......``. ......................```............`-||-.-+`..-`............ <br></br>
 ..~|~-```............```.`~~--``~^+|-```-~~~~-``.`+>>^>~...`............. <br></br>


            </div>,

            <div>

`rrrrrrrr}????vvvvvvvvvv}rvv}vv>---------|?vv}v}r}}}}vvvvv}vvvvvvvv}rrrrrr~<br></br>
-uuxzzzzxuzwrrrwwwwwwwwxuzruzz?+~~~~~~~~~+>rzzuruuxzzzzzzzzzwwwwwwwxuyyyuu|<br></br>
                  `xxwwzzwwzxxwrrwwzwwwwwxuvuzzr^+~~~~~~~~~^+??zuxruxzzzxzzxzwwwwwwwwwxuuuur-<br></br>
`zzwzxxzwwrwrrwwzzzwwwwxzruww>-~~~~~~~~~~~~-^wzx}xuzzxxxxxzzwwwwwwwwzxxzr}-<br></br>
`wwwxxxxzwrrwwwwwzzwwwwxzwzww>-~~~~~~~~~~~~~>wwzrzuzzzxxxxxzwwwwwwwwwr}}rw~<br></br>
`wwzzxxxzwwwwwwwwzzwwwwxxvzww}~~~--~~~~~~~~~}wwwvxuzwzzxxxxzzwwwwwzwrrrwzz|<br></br>
`rwwzzzzwwwwzwwwwzzzwwwxu}zwwwv|-|-.``.`-~|vwwwx}uxwwwwzzzzxzwwwwwwwrwwzzz|<br></br>
`wwwzzzzwwwwwzzwwzzwwwwzuu}}}wz^.?>....+v~|zwrr}xuzwwwwzzzzxxzwwwwwwwwwzzz|<br></br>
                  `wwwwzzzzzzxxxxxxxzzwwwzuuurvwz+~~--..`|-^~wzrvzuxwwwwwzxxxuuxzzwwwwrwwwzz|<br></br>
`wwwwzwzxxzw}}vv}rzxxxuuxzrv?+>->?``...`^?|vvrxuuxzzzzxxxzwwzzxuuxzwwwwwzz|<br></br>
`wwwwxxr?>+++++++++>?}}?>+|~-~..+|...`..`>.`^+>?v}zuxrv?>>>>>>>?vwxxzwwwwz|<br></br>
`wwwxw>^+>+^~-``````-~|+|...`.+`?>......-?`.~-`-|^+>>^|~---~~|++>+>vxxzwwz|<br></br>
`wzz?|^+~`..............``--``v-`+~....-?^^+.-....~~`..........`~+>++}uzzz|<br></br>
`xx>|++~`.................``--^^++v?^|>v^`v?.`....................|+^^?uxz|<br></br>
`z?|~``.................`````...`^~|^||-`?}?.......................`|>^?ux|<br></br>
.++^|~---``..........`^-?|```.....~~..`^?}>`........................-+>|ru|<br></br>
.>+|~~-~~|||~`.......>-~?..````...`?+>?v}?|...........`.....``-~~~~--~^^>z|<br></br>
.-.........-||~`.....~-~?+^^~`.....`?v??>?v`.........~`..`--````````-~^++>`<br></br>
 ........|?vv?|~-.....-`^v`.........-?^`.^?.........|~.`--~~-.......`^^^>>`<br></br>
 ......`>}}}}}}>^~......`?|~-`.......~^..-`......`.~^.`|>v}}v^........+++>`<br></br>
 ......+}}}}}}}}>^`~`....`v`..........|>|~....-|~|^>~.+v}}}}v>........`??|.<br></br>
 ...`~^???vv}}}}v|~^`.....>?>?+~``.....`-~+^|>?|^>?vv?vvvvvv}?..........?+-<br></br>
 ~>?v}}}}v???vv}}^++-......|~|~?vv+....`~>??^`.....`-|+??vvvv>........~|-| <br></br>
`}}}}vv?????????v^>+`. .......`~vv}?^|^>^~~......`-`....``+}>????+-...`v??.<br></br>
`?>^-```...```-~^|^|`............`-+?+-..........`~^^|-`..+v|``-~^+>|`.>vv~<br></br>
 .. .............``-|^++>>++++>>+^>^`...............``-^>^^^^......`~|``??-<br></br>
.|..`..................``--~~~~-|~`..............--`....?``>..........`.`?`<br></br>
`?-v?>+^|~-`.....................................`-||``+^.+~.............`.<br></br>
                  ..````-````....................................`...`+?|.`^`... .......... <br></br>
                  ..............................................`~-`.++..`-.... .......... <br></br>
  ....`````.......................`-`.......`....`-+>^......... .......... <br></br>
.``^>+|~```...........``---^^^|~-^?v>^~-~|>>++|-`.`????+`................. <br></br>


            </div>,

            <div>

`rrrrrrrr}????vvvvvvvvvv}rvv}vv>---------|?vv}v}r}}}}vvvvv}vvvvvvvv}rrrrrr~<br></br>
-uuxzzzzxuzwrrrwwwwwwwwxuzruzz?+~~~~~~~~~+>rzzuruuxzzzzzzzzzwwwwwwwxuyyuuu|<br></br>
                  `xxwwzzwwzxxwrrwwzwwwwwxuvuzzr^+~~~~~~~~~^+??zuxruxzzzxzzxzzwwwwwwwwxuuuxr-<br></br>
`zzwzxxzwwwwrrwwzzzwwwwxzruww>-~~~~~~~~~~~~-^wzx}xuzzxxxxxxzwwwwwwwwzxxzr}-<br></br>
`wwwxxxxzwrrwwwwwzzwwwwxzwzww>-~~~~~~~~~~~~~>wwzrzuzzzxxxxxzzwwwwwwwwr}}rw~<br></br>
`wwwzxxxzwwwwwwwwzzwwwwxxvzww}~~~--~~~~~~~~~}wwwvxuzwwzxxxxzzwwwwwzwrrrwzz|<br></br>
`rwwzzxzwwwwzzwwwzzwwwwxu}zwwwv|-|-.``.`-~|vwwwx}uxwwwwzzzzxzwwwwwzwwwwzzz|<br></br>
`wwwzzzzwwwwzzzwwwzwwwwzuu}}}wz^.?>....+v~|zwrr}xuzwwwwwzzzxxzwwwwwwwwwzzz|<br></br>
                  `wwwwzwwwzzxxxuxxxzzwwwzuuurvwz+~~--..`|-^~wzrvzuxwwwwwzxxxuuxzzwwwwrwwwzz|<br></br>
`wwwwzzzxxzw}}vv}rzxxxuuxzrv?+>->?``...`^?|vvrxuuxzzzzxxxzwwzzxuuxzwwwwwzz|<br></br>
`wwrwxxr?>+++++++++>?}}?>+|~-~..+|...`..`>.`^+>?v}zuxrv?>>>>>>>?vwxxzwwwwz|<br></br>
`wwwxw>^+>+^~-``````-~|+|...`.+`?>......-?`.~-`-|^+>>^|~---~~|++>+>vxxwwwz|<br></br>
`wzz?|^+~`..............``--``v-`+~....-?^^+.-....~~`..........`~+>++}uzzz|<br></br>
`xx>|++~`.................``--^^++v?^|>v^`v?.`....................|+^^?uxz|<br></br>
`z?|~``.................`````...`^~|^||-`?}?.......................`|>^?ux|<br></br>
.++^|~---``..........`^-?|```.....~~..`^?}>`........................-+>|ru|<br></br>
.>+|~~-~~|||~`.......>-~?..````...`?+>?v}?|...........`.....``-~~~~--~^^>z|<br></br>
.-.........-||~`.....~-~?+^^~`.....`?v??>?v`.........~`..`--````````-~^++>`<br></br>
 ........|?vv?|~-.....-`^v`.........-?^`.^?.........|~.`--~~-.......`^^^>>`<br></br>
 ......`>}}}}}}>^~......`?|~-`.......~^..-`......`.~^.`|>v}}v^........+++>`<br></br>
 ......+}}}}}}}}>^`~`....`v`..........|>|~....-|~|^>~.+v}}}}v>........`??|.<br></br>
 ...`~^???vv}}}}v|~^`.....>?>?+~``.....`-~+^|>?|^>?vv?vvvvvv}?..........?+-<br></br>
 ~>?v}}}}v???vv}}^++-......|~|~?vv+....`~>??^`.....`-|+??vvvv>........~|-| <br></br>
`}}}}vv?????????v^>+`. .......`~vv}?^|^>^~~......`-`....``+}>????+-...`v??.<br></br>
`?>^-```...```-~^|^|`............`-+?+-..........`~^^|-`..+v|``-~^+>|`.>vv~<br></br>
 .. .............``-|^++>>++++>>+^>^`...............``-^>^^^^......`~|``??-<br></br>
.|..`..................``--~~~~-|~`..............--`....?``>..........`.`?`<br></br>
`?-v?>+^|~-`.....................................`-||``+^.+~.............`.<br></br>
                  ..````-````....................................`...`+?|.`^`... .......... <br></br>
                  ..............................................`~-`.++..`-.... .......... <br></br>
  ....`````.......................`-`.......`....`-+>^......... .......... <br></br>
.``^>+|~```...........``---^^^|~-^?v>^~-~|>>++|-`.`????+`................. <br></br>


            </div>,

            <div>

`rrrr}}}rr?????vvvvvvvvv}rvv}vv>~--------~?vv}v}r}}v}vvvvv}vvvvvvv}rrrrrrr~<br></br>
-uuxzzzzxuzwrrrwwwwwwwwxuz}uzz?+~~~~~~~~~+>wzzuruuxzzzzzzzzzwwwwwwzxuyyuuu|<br></br>
-xxzwzzzwzzxwrrwwwwwwwwxuvuzzr^+~~~~~~~~~^+??zuxruxzzzzzzxzzwwwwwwwzxuuux}-<br></br>
`xxwzxxxwwwwrrwwwwwwwwwxz}uww>-~~~~~~~~~~~~-+wzx}xxzzzzxxxxzwwwwwwwwzxxzr}~<br></br>
                  `zzwzxxxzwwrwwwwwwzwwwwxzrzww?-~~~~~~~~~~~~-?wwzrzuzwzzxxxxzzwwwwwwwwrrrww~<br></br>
`wwwzxxzwwwwwwwwwwzwwwwxuvzww}|~~--~~~~~~~~|}wwwvxxwwwzzxxxxzwwwwwzwrrwwzz|<br></br>
`rrwwzzzwwwwzzzwwwzzwwwxu}zwwwv|-|-..`.`~~|vwwwxruxwwwwzzzzxzwwwwwzwrwwzzz|<br></br>
`wwwwzwwwwwwzzzzwwzwwwwzuuvv}wz+.?+....>v-^zwrr}xxwwwwwwzzzxxzwwwwwwwwwzzz|<br></br>
                  `wwwwwwwwwzxxuuuxxxzzwwzuuurvwz+-~--..`|-^-wzrvxuxwwwwwzzxxuuxzzwwwwrwwzzz|<br></br>
`wwrrwwzxxzw}vvvv}zxxuuuxwrv?+>-+?``...`^?|vv}xuuxzzzzxxxzwwzzxuuxzwwwwwzz|<br></br>
`wwrwxxrv>+++++++++>?}v?>^|--~..+|...`..->.`^+>??}zuxrv?>>>>>>>?vwxxzwwwwz|<br></br>
`wwwxw>^+>+|~-``````-~|+|...`.+`?>......~?`.~-`-|^+>>||~---~~|+>++>vxxwwwz|<br></br>
`wzx?|++|`..............`.````?~-+~....-?|++.-....~-`..........`~+>++ruzwz|<br></br>
`xx>|++|`.................````~|+>??+|?v|`v?.`...................`|+^^vuzz|<br></br>
`z?|~`.................``|---``.`~~||~|``?}>.......................-^>^vux|<br></br>
.++^^|~~~-`...........^~++`````...|+..`+?}>.........................~++|zu|<br></br>
.++|~~---~|^|-.......`~~>^----`....?+>?v}>|`..........``...``-~~~~~~-|^^?z|<br></br>
.`.........-||~-.......-^++-``.....`>???>vv`.........|`..--````.````-~^>+>`<br></br>
 ........^vvvv+~~.......`+^-``......`>|..^>......`..^~.`--|^~.......`||+>>`<br></br>
 .......+}}}}}}?||.......`~>```......~^`.``.``..-~.~^.`~>v}}}^.......`>++>.<br></br>
 ...`--+?vvv}}}}?^`~`.....~+^|~```....~^^~``..`--|^?^~>vv}}}v>........-v?~.<br></br>
 -|+>??vvvvvvvv}v||+-.....`~-||>>>~..`-|++|--|^-~|^>>>?vv?vv}?.........`?+~<br></br>
.??vvvvvv???????v^>+~... ..`...^vv?|~~``-~^^~`.``````-~+v?vvv>``.....`+||~.<br></br>
`vv?>>++^^++^^^^>-||-..........`~>?>|-~|~``......``-~~-|~^>v????>^-...^v?+ <br></br>
                  .+|````.....```----``....``````````~^~`.......``..`~~++`~`~+-```-|+^-.`?v?`<br></br>
.|`.```..........```-~~~~~~~~~~|~-~~`..........`--`.`^-|>-~~~......`--.`??-<br></br>
.>`||~-``..............``````--`--`..............`~|~`.->``~............-?~<br></br>
.|`+^|~--```..................................```.->~`.|~.~`............`+~<br></br>
                  ..````````......................``............`-|||-|^-..~`............`^`<br></br>
.``-~--```........````--~`.--``~^^^~-``---~~-```|>^>>?>-`.`.............`+`<br></br>
.-````..`.`.`-|^+++>>>>>>+--```-`.`~|~-````-~~~^^-~>??>>>|```...-||^^^^^++`<br></br>
.>^|+^|~|^+^+>>>>>>?????vv?>>~`-~|>??>^~~-~~~~^>>??r}v?>>>>+-~~-~~~^+++>>+`<br></br>


            </div>,

            <div>

`rrrr}}}rr?????vvvvvvvvv}rv?}vv>~--------~?vv}v}r}v}vvvvv}}vvvvvvv}rrrrrrr~<br></br>
-uuxzzwwzxzwr}rrwwwwwwwzuz}xzz?>~~~~~~~~~+>wzxuruuxzzzzzzzzzwwwwwwzuuyyuuu|<br></br>
-uuzwwzzwwzzzrrrwwwwwwwxuvuzz}^+~~~~~~~~~^^??xuzwuxzzzzzzxxzwwwwwwwzxuuuz}-<br></br>
-uxwwxxxzwwwrrwwwwwwwwzuz}uww>-~~~~~~~~~~~~->wzx}uxzzzzzxxxzwwwwwwwwzxxw}}~<br></br>
                  `zzwzxxxwwwwwwwwwwzwwwwxzrzww?-~~~~~~~~~~~~-?wwzrxxzwwzxxxxzzwwwwwwwwrrrwz|<br></br>
`wwwzzxzwwwwwwwwwwzzzwwxuvzww}|~~--~~~~~~~~|}www}uxwwwwzxxxxzwwwwwzwrrwzzz|<br></br>
`rrwwzzwwwwwzzzwwwzzzwwzu}wzwwv^-|-..`.`~-^}wwwzruzwwwwwzzzxzwwwwwzwrwwzzz|<br></br>
`wwwwwwwwwwwzxxzwwzzzwwwxuvv}wz>.>+....>v`^zwrr}xxwwwwwwzzzxxzwwwwwwwwwzzz|<br></br>
`wwwwwwwwwzxxuuuxxxzzwwzxuu}vwz+-~-`..`^~^-wzr}xuzwwwwwwzxxuuxzzwwwwrwwzzz|<br></br>
`wwwrwwzxxzw}v??v}wxxxuuxw}v?^+`^?`....`+?|vv}xuuxzzzzxxxzwzzzxxuxzwwwwwzz|<br></br>
`wwwwxxwv>++++++^^+>?}v?>^|--~..^|...`..->.`^+>??}zuxr??>>>>>>>?vwxxzwwwwz|<br></br>
`wwzxw>^+>+|~```.```-~|^|...`.+`>>......|v`.~-`-|^+>+|~~---~|^+>++>}xxwwwz|<br></br>
`wzxv^+>|`..............`.`--->~~>|`...~?~++.-....~-...........`|+>^>wxwwz|<br></br>
`xu?|^^|`.................````.`^???+^?v~-}?.`...................`^+^^vuzz|<br></br>
`zv|~`.................-`^~~~-`..`~~~~~`-?}>.......................~^>^wuz|<br></br>
.+^^^^|||~-`..........+~->..```...|?..`+v}+........................`|+++uu|<br></br>
.++^~--`--|||~`.......|--?||--`....>>>?vv>|`..........-`...`-~~||||~~|+^vz|<br></br>
.`........`-||~-.......-`++.........^???>v}`.....`..`^`.`--```..````-~+>+>`<br></br>
 ........^vv}v>~|........~?~--`......+|..^>. ...``..^~.`-~+>^.......`||+>>`<br></br>
 .......^}}}}}}v^|....... |?`.`......~^`.``.^>+>?>~|+``|?v}}}|.......`?>+>.<br></br>
 ...`~^^>??v}}}}?^-~`......??>+~`~....-|+^>>^```~^>?vvvvvv}}v>........-v>~`<br></br>
.|?vv}}}}vv???v}}||+-... ..``--vv}+..`|>?>-.........`~|?v?}}}>.........`??^<br></br>
`}}}vv?>>>>>>>>>?|>+~..  ......~?vv>+^~``......`~~~-`..-v+vvv+~`.....`?~+|-<br></br>
`?>|``.......````.~^|`.......````|>^`............``~++|>^>???>>>>^`...???^.<br></br>
 .. ...............`-~|^^+>>?????|`...........`-.....>?.+`......`~+|`.-?v?.<br></br>
.>``-```......................```.............`~||-.`?-~+..........``..-??-<br></br>
`?~>++^~`........................................`~>>-.^|...............~?^<br></br>
                  .............................................`-`.-?-..``..... .........-?^<br></br>
 . ....```............... .......``............`|>?>-`..................`?^<br></br>
                  .|-~+^|~-`.. ....```--~|^~.|~-~^???+~~~~|+++|~-`+v?vvv?^`......`........~v|<br></br>
.>|`-`.....`-~>???vvvvvv}}++||^>|``|++~-+>+>>>>??++?vv}}v>~``.`~^>>?????vv^<br></br>
`v?|+|~~^>?v}vv}vv}rwzxxuuz??`.~~-^???>^^~---|>v}wzxz}??v}v?^+>++++?vvvvvv|<br></br>


            </div>,

            <div>

`rrrr}}}rrv?????vvvvvvvv}rv?}vv>~--------~?vv}v}r}vvvvvvvvvvvvvvvv}rrrrrrw~<br></br>
-uuxzwwwzxzwr}}rwwwwwwzxuz}xzz?>~~~~~~~~~+>wzxuruuxzzzzzzzzzwwwwwwzuuyuuuu|<br></br>
-uuzwwzzwwzzzrrrwwwwwwzxuvuzz}^+~~~~~~~~~^^??xuzwuxzwzzzzxxzwwwwwwwzuuuuz}-<br></br>
-uxwwzxzwwwwrrwwwwzzwwzuz}uww>-~~~~~~~~~~~~->wzx}uxzwzzzxxxzwwwwwzwwzxxw}}~<br></br>
                  `xzwwzxzwwwwwwwwwzzzzwwxzrzww?-~~~~~~~~~~~~-?wwzrxxwwwzzxxxzzwwwwzzwwrrrwz~<br></br>
`wwwwzzwwwwwwwwwwzzzzwwxuvzww}|~~-~~~~~~~~~|}www}uxwwwwzxxxxzwwwwzzwrrwzzz|<br></br>
`rrwwzwwwwwwzzzzwwzzzwwzu}wzwwv^-|-..`.`~-^}wwwzruzwwwwwzzzxzwwwwwzwrwwzzz|<br></br>
`wwwwwwwwwwwzxxzwwzwwwwwxuvv}wz>.>+....>v`^zwrr}xxwwwwwwzzzxxzwwwwzwrwwzzz|<br></br>
`wwwwwwwwwzxxuuuxxxzzwwzxuu}vwz+-~-`..`^~^-wzr}xuzwwwwwwzxxuuxzwwwwwrwwzzz|<br></br>
`wwwwwwzxxzw}v??v}wxxxuuxw}v?^+`^?`....`+?|vv}xuuxzzzzxxxzzzzzxxuxzzwwwwww|<br></br>
`wwwzxxwv>++++++^^+>?}v?>^|--~..^|...`..->.`^+>??}zuxr??>>>>>>>?vwxxzwwwwz|<br></br>
`wwzxw>^+>+|~-``.```-~|^|...`.+`>>......|v`.~-`-|^+>+|~~---~|^+>++>}xxwwww|<br></br>
`wzxv^+>|`..............`.`--->~~>|`...~?~++.-....~-...........`|+>^>wxwwz|<br></br>
`xu?|^^|`.................````.`^???+^?v~-}?.`...................`^+^^vuzz|<br></br>
`zv|~`.................-`^~~~-`..`~~~~~`-?}>.......................~^>^wuz|<br></br>
.+^^^^|||~-`..........+~->..```...|?..`+v}+........................`|+++uu|<br></br>
.++^~--`--|||-`.......|--?||--`....>>>?vv>|`..........-`...`-~~||||~~|+^vz|<br></br>
.`........`-||~-.......-`++.........^???>v}`.....`..`^`.`--```..````-~+>+>`<br></br>
 ........^vv}v>~|........~?~--`......+|..^>. ...``..^~.`-~+>^.......`||+>>`<br></br>
 .......^}}}}}}v^|....... |?`.`......~^`.``.^>+>?>~|+``|?v}}}|.......`?>+>.<br></br>
 ...`~^^>??v}}}}?^-~`......??>+~`~....-|+^>>^```~^>?vvvvvv}}v>........-v>~`<br></br>
.|?vv}}}}vv???v}}||+-... ..``--vv}+..`|>?>-.........`~|?v?}}}>.........`??^<br></br>
`}}}vv?>>>>>>>>>?|>+~..  ......~?vv>+^~``......`~~~-`..-v+vvv+~`.....`?~+|-<br></br>
`?>|``.......````.~^|`.......````|>^`............``~++|>^>???>>>>^`...???^.<br></br>
 .. ...............`-~|^^+>>?????|`...........`-.....>?.+`......`~+|`.-?v?.<br></br>
.>``-```......................```.............`~||-.`?-~+..........``..-??-<br></br>
`?->++^~`........................................`~>>-.^|...............~?^<br></br>
                  .............................................`-`.-?-..``..... .........-?^<br></br>
 . ....```............... .......``............`|>?>-`..................`?^<br></br>
                  .|-~+^|~-`.. ....```--~|^~.|~-~^???+~~~~|+++|~-`+v?vvv?^`......`........~v|<br></br>
.>|`-`.....`-~>???vvvvvv}r++||^>|``|++~-+>+>>>>??++?vv}}v>~``.`~^>>?????vv^<br></br>
`v?|+|~~^>?v}vv}vv}rwzxxuux??`.~~-^???>^^~---|>v}wzxz}??v}v?^+>++++??vvvvv|<br></br>


            </div>,

            <div>

`rrrrrrrrrv??????vvvvvv}}}vv}vvv?>^~~~~~+?vvvvv}}}vvvvvvvvv}vvvvvv}rrrrrrr~<br></br>
-uuuuuuuuuzwr}rrrrwwwwzzxzwxzzr}>|~~~~~~~+?wzxxzuxzzzzwzzzzzzwwwwwzuuyyyuu|<br></br>
-uuuxxxxxxxzzrrrrwwwwwzxxrurxwv>~~~~~~~~~^>}rxxxzxzwwwwzzzxzzwwwwwwzuuuuzv-<br></br>
-uuxzzxzzwwwwwwwwwzzzwzxzwxrz}?~~~~~~~~~~~|+}zwzzxzwwwwzzzxzwwwwwzwzxxxw}v-<br></br>
-uxzzzwwwwwwwwwwwzzzzwwzzzzrz}?~~~~~~~~~~~~^}zwwzxzwwwwzzzxzwwwwwzzwwr}}rr~<br></br>
-xzwwwwwwwwwwzwwwzzzzwwzxrz}wwv>~~~~~~~--~~?wwrwzxzwwwwwzzzxzwwwwzzw}rrwww~<br></br>
`rrrwzwwwwwwzzzzwwwwwwwwxwzr}wrv^^|....-|-?wwwrxzxwwwwwwwzzxzzwwwwzwrrwwww~<br></br>
`rrrwwwwwwwwzxxzzzzzzzzzxxww}rzv.||`...+>.?zr}wzuxwwwwwwwzxxxzwwwwzwwwwzww~<br></br>
`rwrwwwwwwzzxuuuzzwrrrwzxuzvv}}>-~``..`--~>wwzzuuxzzzwzzzxuuuxzwwwwwwwwzww~<br></br>
`wwwwwwzzzzzrv?>>>>>??????>+|-|`~+......|^`?vvrzzr}v??????vrzxxzzzzzwwwwww|<br></br>
`wwwzzzw}vv?+^^|~~~~|^|~-~-`....~-``....|^``~~|+>>>>+^|~~~||^>?v}wzzwwwwww|<br></br>
`wwzzw????>|-``....````-`..`..~`||.--..-|?|--``----~-````````-|>?vvrzzwwww|<br></br>
`wzz}??v?|`................-~`~-`~`.`--+>-~~ ..  `-............`^vv?vwzwww|<br></br>
`zxv????^`..................|-``-||^|^>+``>^ ........``..........|?v??rxww|<br></br>
`w}??>+~`..     .````........`````-|^-```^>|....-.`---~`   ....`.`|??v?wzw|<br></br>
`v???>|-`....````-----~..-...```--|^..`~^+~.....`.```---`........`~^????zz|<br></br>
`v???>-``~+>+|``......~-~>||~~```..~||^++~-....`..............`||-^?????}z|<br></br>
.?>??+--^>+^~`... ... .``~~.........-^^^~^>`..``...............-^`>???????-<br></br>
.???+`~>^+>>++|....... ..`|```......`~-`-^^....... .`....`-|-...`-????????-<br></br>
.??+~^^^+???^>>+`..... .. `^`...`---`-|-`--.~|~~|-```..`^>>>>-...`+???v???`<br></br>
`?^+|~~~|^+>^>>?^`.... ....+>^|-.`......`````-~-``|^+++>+>+>>|....-????rv>-<br></br>
`?>v?????>>+~^+>>`.`.......```.---`....```..  ..`--```-^>^+>?^````|>????vv^<br></br>
`wr}v???+^^||-~|^`````-`........---````...........-|~-``+-+>?>>+^+>???}vv?~<br></br>
`r}v???>+^^^|~`-~~^+>>>>~`....```-|~```````````...`->?>?>????vvvvv????}}rv`<br></br>
`??>???????>>>++>>??vvvvv?+|||^^|-``..```....``-``-+?}}?v????????vvv???}r}`<br></br>
`v???????????????????????>-``...```.....``.````...^vv}?v}???????????????}}~<br></br>
`}?vvvvv?????????????????-````````..```..``.`~~~~|+}v??vv???????????????v}|<br></br>
.?>?????????>???????????????>|`.`|```--``|..-+?vv?vr??????????>??????????}^<br></br>
.?>?????????>????????????>???+>^`^-....`|+`-^>vvvv}vv?????????>??????????}|<br></br>
`v??vvvv????>????????vvvv???~~>????>^~+>>++?>~~?}}vrrr}v????????????????vr^<br></br>
`vv???????????v}}}}}}rrrwwv?...`...`~~-`.```..-rrvv}rrrrrvv????vvvvvv}}}rr^<br></br>
`r}?vv?vvv}}}}rrrrrwwwzzxuu>..  . .^vv?-......-rzzzzzrvv}r}}vvvvvvvv}}}}rr|<br></br>


            </div>,

            <div>

`rrrrrrrrr}?????????vvv}vv}rvv}vv?>^|||^?v}rvvr}vvvvvvvvvvv}vvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyzrrrrr}}}rwwzzzzxzwxzw?|~||||||+vzuzzuxzzzwzzzzzxzzwwwwwzuuyyyuu^<br></br>
-uuuuuuuuuzwwwrrrrwwwwwwwzu}uzz?-~~~~~~~~~>wzyrxuzwwwwwzzxxzzwwwwwwzuuuuwv-<br></br>
-uuuuxxxxzwwwwwwwwzzzwwwwxzwxz}|~~~~~~~~~~|?wxxwuzwwwwwzzxxzwwwwzzzzxuxwvv-<br></br>
-uuxzwwwwwwwwwwwwwzzzwwwwxwwxz}|~~~~~~~~~~~>wzxruzwwwwwzzzxzzwwwwzzwwr}vvv-<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwxxvzww?||~-~-~--~~vzz}wuzwwwwwwzzxxzwwwwzzw}}}}}}-<br></br>
`r}}wwwwwwwwzzzzwwwwwwwwzxuvvzww+>+....~+~vwrzruuzwwwwwwwzzxxzwwwwzwrrrwr}~<br></br>
`v}}wwwwwwwwzxxzzzzzzzxxxxuyrrxw.~|`...+|.vzr}xuuzwwwwwwwzxuuzwwwwzwwwwwwr~<br></br>
`}}rwwwwwwzzxuuxzr}}v}rzxurvv?>?|^.`...`|~?wzuuuuuuxzzzzzxuuuxzwwwzwwwwwww~<br></br>
`}rrwwwwwwzuur?+|~-```--^^`.```.``......~``?vvvrwv>+^|||^+?vzuxwwwzwwwwwww|<br></br>
`rwwwwwwzxur>|-`.........^^+````..~~....+~--```||-`........`~>rxzwwwwwwwww|<br></br>
`wwwwwwwxx?^-............--^|``....^^.`^|>>^^^|^|~............-?zxzzwwwwww|<br></br>
`wwwwwwzu?`...............`~|.......|^^>|`.......?^............-+xuzzwwwww|<br></br>
`wwwwzxuv`......```.........>~``....`>^`......``||~`-||`........`>uuzzwwww|<br></br>
`wwwzxr?-.. ....`>??+`.......--~~``^+-......````v~>?v}}>......~^.|?wuzwwww|<br></br>
`wwwx}|`. .`|+>??v??v?-..`.```-~^||-........```+^`+?vvv?^~`....|-`^vuzwwww|<br></br>
`wwzu}-``^?}}v?+^^|^^^|~~^^|||--``...........``>`..``--~|^^~..-??`>uxzwwww|<br></br>
`wzxu?^^v}v>~`............................`~|~>>...........`...^?-vuzwwwww|<br></br>
`zxuv|>v?^-............`............```-~^|-||~`................|>zuzwwwww|<br></br>
`xuv>?v?+|~`..................``-|+^||+>>+^^~`...................~vuzwwzwz|<br></br>
                  -z?v^-``.... ............``-~|^^|~-`.`...````~+~..................^xzwwzzz|<br></br>
-r?}^```...............`~~`````.................~+|`.......------->rxwzxxx|<br></br>
`uw}v?>>|-`.. .....`~+v>~...........`--~-```......|>>|-`..`~^??v?vvzzwzxxx|<br></br>
`xxxxzw}v???>^-~^>??}zuz?|-...`-~^^+^^+>>>>>+|^|~~^^?}v?????v}rwzxxzwwzzxx|<br></br>
`zzwwzzxxzw}v}v?v}wxuuuuu}>^|||||^>>~`++^|~--^+>++>}uuxzzzxxxuuxzzwwwwzzxz|<br></br>
`zwwwwwwwzxuxxxxxxxxxzxuur>++~-`|+^`...|+^|^+^|`..?uuxxxxuuxxzzzwwwwwwwzzz|<br></br>
`zzwwwwwwwwzzxxxzwwwzzzxz+||^^+++~..`--``^|-->>>>>?xxzzzzxxxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwzzzwwwwwzzzxuux}>~.->-`~|^~~>`.|vxuuuuxzzzzzxxxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwwzzwwwwwwwzzzxuuv}?-?~`...->v`|?}yuzzzzzzzzzzxxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwwwzwwwwwwwwwzxr|^?v???>>^?v?>>v?|+wxwwzzzzzzzzzzzzzwwwwwwwwzz|<br></br>
`zzwwrrwzzwwrwwzzwwwwwwwzzu}   ....`~|~.. .   `xxwwzzzzzwzzzzwzzwwwwwwwwzz|<br></br>
`wzwwrrwzzwrrrwzzwwwwwwwwzu?       |}rv`      .zxwwwwwwwwwwwwwwwwwwwwwwwww|<br></br>


            </div>,

            <div>

`rrrrrrrrr}?????????vvv}vv}rvv}vv?>^|||^?v}rvvr}vvvvvvvvvvvvvvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyzrrrrr}}}rwwzzzzxzwxzw?|~||||||+vzuzzuxzzzwzzzzzzzzwwwwwzuuyyyuu^<br></br>
-uuuuuuuuuzwwwrrrrwwwwwwwzu}uzz?-~~~~~~~~~>zzyrxuzwwwwwzzzxzwwwwzwwzuuuurv~<br></br>
-uuuuxxxxzwwwwwwwwzzzwwwwxzwxz}|~~~~~~~~~~|?wxxwuzwwwwwzzxxzzwwwzzzzxuxwvv-<br></br>
-uuxzwwwwwwwwwwwwwwwwwwwwxwwxz}|~~~~~~~~~~~>wzxruzwwwwwzzzzzzwwwwzzww}}vvv-<br></br>
-uxwwwwwwwwwwwwwwwwwwwwwwxxvzww?||~-~~~--~~vzz}wuzwwwwwwwzxxzzwwwzzw}}}}}}-<br></br>
`r}}wwwwwwwwzzzwwwwwwwwwzxuvvzww+>+....~+~vwrzruuzwwwwwwwwxxxzwwwwzwrrrwr}~<br></br>
`v}}wwwwwwwwzxxzzzzzzzxxxuuyrrxw.~|`...+|.vzr}xuuzwwwwwwwzxuuzwwwwzwwwwwwr~<br></br>
`}}rwwwwwwzzxuuxzr}}v}rzxurvv?>?|^.`...`|~?wzuuuuuuxzzzzzxuuuxzwwwzzwwwwww~<br></br>
`}rrwwwwwwzuur?+|~-```--^^`.```.``......~``?vvvrwv>+^|||^+?vzuxwwwwzwwwwww~<br></br>
`rwwwwwwzxur>|-`.........^^+````..~~....+~--```||-`........`~>rxzwwwwwwwww|<br></br>
`wwwwwwwxx?^-............--^|``....^^.`^|>>^^^|^|~............-?zxzzwwwwww|<br></br>
`wwwwwwzu?`...............`~|.......|^^>|`.......?^............-+xuzzwwwww|<br></br>
`wwwwzxuv`......```.........>~``....`>^`......``||~`-||`........`>uuzzwwww|<br></br>
`wwwzxr?-.. ....`>??+`.......--~~``^+-......````v~>?v}}>......~^.|?wuzwwww|<br></br>
`wwwx}|`. .`|+>??v??v?-..`.```-~^||-........```+^`+?vvv?^~`....|-`^vuzwwww|<br></br>
`wwzu}-`-^?}}v?+^^|^^^|~~^^|||--``...........``>`..``--~|^^~..-??`>uxzwwww|<br></br>
`wzxu?^^v}v>~.............................`~|~>>...........`...^?-vuzwwwww|<br></br>
`zxuv|>v?^-............`............```-~^|-||~`................|>zuzwwwww|<br></br>
`xuv>?v?+|~`..................``-|+^||+>>+^^~`...................~vuzwwzwz|<br></br>
                  -z?v^-``.... ............``-~|^^|~-`.`...````~+~..................^xzwwzzz|<br></br>
-r?}^```...............`~~`````.................~+|`.......------->rxwzxxx|<br></br>
`uw}v?>>|-`.. .....`~+v>~...........`--~-```......|>>|-`..`~^??v?vvzzwzxxx|<br></br>
`xxxxzw}v???>^-~^>??}zuz?|-...`-~^^+^^+>>>>>+|^|~~^^?}v?????v}rwzxxzwwzzxx|<br></br>
`zzwwzzxxzw}v}v?v}wxuuuuu}>^|||||^>>~`++^|~--^+>++>}uuxzzzxxxuuxzzwwwwwzxz|<br></br>
`zwwwwwwwzxuxxxxxxxxxzxuur>++~-`|+^`...|+^|^+^|`..?uuxxxxuuxxzzzwwwwwwwzzz|<br></br>
`zzwwwwwwwwzzxxxzwwwzzzxz+||^^+++~..`--``^|-~>>>>>?xxzzzzxxxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwzzzwwwwwzzzxuux}>~.->-`~|^~~>`.|vxuuuuxzzzzzzxxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwwzzwwwwwwwzzzxuuv}?-?~`...->v`|?}yuzzzzzzzzzzzxzzzzwwwwwwwwzz|<br></br>
`zzwwwwwwwwwwwwzwwwwwwwwwzxr|^?v???>>^?v?>>v?|+wxwwzzzzwwzzzzzzzwwwwwwwwzz|<br></br>
`zzwwrrwzzwrrrwzzwwwwwwwzzu}   ....`~|~.. .   `xxzwzzzwwwwzzzwzzwwwwwwwwzz|<br></br>
`wzwwrrwzzwrrrwzzwwwwwwwwzu?       |}rv`      .zxwwwwwwwwwwwwwwwwwwwwwwwww|<br></br>


            </div>,

            <div>

`rrrrrrrrr}v????????vvvvvvv}vvv?vvv?????vvvv}v}vvvvvvvvvvvvvvvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyxwrr}}}}}rwwwwwzzxxwrwrv??>>>>?vwzwxzxzzzzwwzzzzzzwwwwwwxuuyyyuu^<br></br>
-uuuuuuyyuuxxw}}}rwwzzzwwzxwzwz}>|~~~~~~~^vzzzzxxzwwwwzzzxzzwwwwwwzxuuuur}~<br></br>
-uuuuuuuuxzzzwrrrwwzzwwwwzzz}zwv|~~~~~~~~~+}xwxzxzzwwwwzzzxzzwwwzzwzuuxwvv-<br></br>
-uuuuuuuxzwwwrrrwwwwwwwwwzzz}zwv^~~~~~~~~~^vzrxzxzwwwwwzzzzxzwwwwzwww}}vvv-<br></br>
-uuxzzwwwwwwwwwwwwwwwwwwwzxwwv}?^~~---~~-~-?}rzzuzwwwwwwwwxxxzwwwzzr}vvvvv-<br></br>
`r}}rwrrrwwwzxzwwwwwwwzzxxuw????||^....`~`+v?zzuuzzzwwwwwwxxxzwwwwwrr}}r}}-<br></br>
`vv}rwrrrwwzzxxzzzxxzzzzxuuyv???.`~-`..~-.>v}zuuuxzwwwwzzzxuuxwwwwwwrrrwr}~<br></br>
`}}}rwwwwwzzxuuuzrv?>++>???v}?^+|~``....~~>vwzwrvv???>??vrzuuxwwwwwwrrwwwr~<br></br>
`}}rwwwwwwzxxz}?^-`....-~-..-||-`....`.`~-`^++>??+^^|``.`~+?zuxwwwwwwzxxxx|<br></br>
                  `rrrwwwwwzxwv?^`..`````..~~|```...`~~-`.```....``..........-^vzxwwwwzxuuuu|<br></br>
`rwwwwwwzzv??^`.``...    ``~`````~^>^~|+^+^~-~`-``.... ..``.-~?}zzwzxxuuuu|<br></br>
`rwwwwwzxv>+>~```..........`~```.~~-^+^>+``--`...|-..  . ...`~|?vzzzxxuuuu|<br></br>
`rwwwzzxr>^>>-`..... ......`+-.. .`..~|~.  .`~|~~-`.`-|~-`.```+>>vxxuuuuuu|<br></br>
`wwwwzwv>+>?>`..`^>+-. ...`........--``.......`~?+>>>>>|`....~vv+?vzuuuuuu|<br></br>
`zxxxr?>>>>?vv>~+??>>>`.....``..`.`.``.........~-.``````....~??v?+?ruuuuuu|<br></br>
`xxxuw???v}rrrr?-`--~-....``-`.................|`........`|>???vv+?uuuuuxu|<br></br>
`zzxurvv}r}v?????+~`.......... ...............`~..`.`--~|>???>??v+}xxzzzzz|<br></br>
`xuur?v}}v??>>???????>+>``.... ... ..............```>v???????????vzxxzzzzz|<br></br>
-uyw}rr}v???+?????????>v+``.......`~~~^^^^|~`....``^??????????????ruuxxxzz|<br></br>
-xrw}v??????+????????????>~-~~~|^^^^~`~||~~~-~~`..>v??????>????????zzzzxxx|<br></br>
-z}zv???????>???????????vv?+^||~-~|~. .`-``....``-+v??????>???????vrxzzxxx|<br></br>
-uzwr}vv?????>??????vvrv?+~~-````-...``..`...-+>++?}vv?????vvv}}v}rzuxxuuu|<br></br>
`xxxxxzw}}}}vv?vvvv}rzxzv?>^~~-`.`-`-~~~~|``+>v?>>??}wr}}}rrrwwzzzxxxxxxxu|<br></br>
`xxxxxuuxzwrrrr}rrwzxxxxxrv???>^|-+~..`~^^`.|?v???vwxxxzxxxxxxzzzzzzzzzxxx|<br></br>
`xuuuuuuuxxxxzzzzzzzzzzxxwv???^|>>+^|`~>^^~++??>+^vuxzzzxxxxxzwwzzzzzzxxxx|<br></br>
`xuxxxxxxxxxxxzzwwwwwzzxzv??v+.`-`..```..```.-vvvv}xxzwwzzxxzwzzzzzzzxxxxx|<br></br>
                  `xxxxxxxxxxxxzzzwwwwwwzzzxxxz+. .. .~+^...  .-zuxxxxzzwwwzzzzzzxxxxxxxxxxx|<br></br>
                  `xxxxxxxxxxxxxzzwwwwwwwwzzzuu~``....`-`..` .`-zuzwzzzzwwwwzzwzzxxxxxxxxxxx|<br></br>
`xxzzzzzzzzzwwwwwwwwwwwwwzzw>.``.``....``..``.+xxwwzzzwwwwwwwwzzzzzzzzzzxx|<br></br>
                  `zzwwrwwzzwrrrwwwrrwwwwwwzxw-       ...       `uxzwzzwwwwwwwwwwzzzzzzzzzxx|<br></br>
                  `wwwrrrwwwwrrrwzzwwwwwwwwzxv`      ..`.       .zxwwwwwrrwwwwwrrwwwwwwzzzwz|<br></br>


            </div>,

            <div>

`rrrrrrrrrrv?????????vvvvvvv}rv?v}}}vvv}}rvvr}vvvvvvvvvvvvvvvvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyur}vvvv}}rwwwwwwzzurrxzw}?????vwxzvxzzzzzzwwzzzzzwwwwwwwxuuyyyuu^<br></br>
-uuuuuuyyyyyywvvv}wwzzzwwwzurrxz?|~~~~~~~^vxw}uzzwwzwwzzzxzwwwwwwwzuuuuu}}~<br></br>
-uuuuyyyyuxzz}vv}rwzwwzwwzxuvuzw|~~~~~~~~~>zy}xxzwwwwwwzzzxzwwwwzwwxuuxrvv-<br></br>
-uuuyyyyuxwrrr}rrwwwwwzzwzxuvuzw+~~~~~~~~~+rx}xuxwwwwwwzzzxxzwwwwzwwr}vvvv-<br></br>
-uuxzrrrwwwwwwwwwwwwwwzzzzxu}?>>+~----~~---^vvuuxwwwwwwwwwxxxzwwwzwrvvvvvv-<br></br>
`r}vvvvv}rwzzxzwwwwwwzxxxxuu>`...`|..`.....|-wuuxxzzzwwwwwxxxzwwwww}vvvvvv-<br></br>
`vvvv}}}rwwzzxxzzzxxzwwzxuuy?^....-~``.....~vuuuuxzwwwwzxxuuuxwwwwr}vvvvvv-<br></br>
`vvv}rrwwwwzxuuuz}?^~``-~|?}r>``|-``....-~->rrv?+|~---~^>vwuuxzwwwr}vv}vvv~<br></br>
`vv}}wwwwwwzxuuv^-..``~>+~.-|^+|-...```-~~|^|-~^^^^++|`..`|?wuxwwwrrwxuuxu|<br></br>
`v}}rwwwwwwzxu?-.`+?>>+^^|``.`.....~>>`` ....`.```````~^-^`~+ruzwwrwxuyyyy^<br></br>
`v}}rwwwwwwzu}`.^>|`...........`-^??+>>>+|-.............-++.~+zxwwwzxuyyyy^<br></br>
`v}}rwwwwwwxu>|+>^|`.........`~~`++|++|>?`~^|`.............||+vuzwwxuyyyyy^<br></br>
`v}}rwwwwwwxw^|~```.......`|^~....~...-+.  .-+>^-`...`~++~`++^ruzwzxuyyyuy^<br></br>
`}}}rwwwwwwxw-..``-`...``||-.......`.`-.......+>>>>+||~|^~..~?uxwwzuuuuuuy^<br></br>
                  `zuxzwwwwwwwxw?`|++~~|~-`...``...```-~```.....`^~..........-?zxzwwxuuuuuuy^<br></br>
                  `zxuuxzwwwwwwxuv|-~~-`....`~|```---`.--~~~~-``~+^-`.....`-+vxzwwwwxuuuuuuu|<br></br>
`wwwzxzzwwwwwwzux}?|````-~```................`--`-^||>+>?rxxwwwrrrwwzzzwww~<br></br>
`uuzwwwrrrwwwwwzzxxxzww}^>`.....................`+>+ryuuuxzwwrrrrwzzzzwrrw~<br></br>
-uyyuuxwwwwwwwwwwwwwzuuuv++-`.....`~~||^^^|~---`|>+vuuxzzwwwwwwwwwzuuuuxzw~<br></br>
-uyuuuuzwwwwwwwwwwwwwxuuur?++^~||^+>+-^>+^|||^-. `}uuxzzwwwwwwwwwwwwwzzzzz~<br></br>
-xxxxxzwwwwwwwwwwwwwwzzzzux}>++^|^>^`..-^~-````~-`?xuxzzwwwwwwwwwrwwwwwwzz~<br></br>
                  `zzzzzzwwwwwwwzzwwwwwzzwzz??^``-~^-.`~-``|`.|?zuxxxxxzzzwzzzwzzwwwzxuuuuuu|<br></br>
`zxzzzzzwwwwzzzzwwwwwwwwwzx}>>^`.~~.-|~-~^``v}yxzwzzzzzzzzxzzzzwwwzxxxxxuu|<br></br>
                  `xxxxxuxxzwwzzzzwwwwwwwwwwzxxur??^?~...~vv`-?xuzwwwzzzwzzxxxzzwwwzzzxxxxxx|<br></br>
-uyyyyyyuuxzwwzzwwwwwwzzzwwwxxvv}vv?>~?}??+vvruzwwwzzwwwzzxxzwwwzxxxxxxxxx|<br></br>
                  -uuuuuuuuxxxzzwwwwwwwwzzzzwzu? ```.````````-``xxwwwzzwwwzzxzwwzzxxxxxxxxxx|<br></br>
-xuxxxxxxxxxxzzzwwwwwwwwzzzzu+     .>v>.     .zuzwzzzzwwwzzzwzzxxxxxxxxxxx|<br></br>
-uuuuuuuuuuuuxxzwwwwwwwwwzzxx.      ~+~       }uzwzzzzwwwwwwwzxxuuuuuuuuuu|<br></br>
-xxxxxxxxxxxzzwrrrrwwwwwwwzur                 >yxwwzxzwwwwwwwwzxxxxxxxxxuu|<br></br>
                  `wwwwwwwwwwrrrrrrrrwwwrwwwzu?                 ~uxzwzzwwwwwwwrwwzxxxxxxxxuu^<br></br>
`rrrrrrrwwwrrrrzwwwwwwrwwwzu>                 .wxwwwwwrrwwwwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`rrrrrrrrrrv????????vvvvvvvv}rv?v}}}vvv}}rvvr}vvvvvvvvvvvvvvvvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyuw}vvvvv}rwwwwwzzzurrxzw}?????vwxzvxzzzzzzzzzzzzzwwwwwwwxuuyyyuy^<br></br>
-uuuuuuyyyyyyzvvv}wzzzzwwzzurrxz?|~~~~~~~^vxw}uzzwwzzwzzzxzwwwwwwwzuuuux}}~<br></br>
-uuuuyyyyuxzzrvv}rwwwzzwwzxuvuzw|~~~~~~~~~>zy}xxzwwzwwwwzzxzzwwwwwwxuuxr?v-<br></br>
-uuuyyyyuxwrrr}rrwwwwwzzzzxuvuzw+~~~~~~~~~+rx}xuxzwwwwwwwzxxxzwwwzwwr}vvvv-<br></br>
-uuxzrrwwwwwzwwwwwwwwwzzxxxu}?>>+~----~~---^vvuxxzwwwwwwwwxxxzwwwzw}vvvvvv-<br></br>
`r}vvvvv}rwzxxzzwwwwwzxxuuuu>`...`|..`.....|-wuuuxxzzzwwwwxxxzwwwww}vvvvvv-<br></br>
`vvvv}}}rwwzxxxzzzxxzwwzxuuy?^....-|``.....~vuuuuxzwwwwzxxuuuxwwwwr}vvvvvv-<br></br>
`vvv}rrwwwwzxuuuz}?^~``-~|?}r>``|-``....-~~>rrv?+|~---~^>vruuxzwwwr}vv}vvv~<br></br>
`vv}}wwwwwwzxuuv^-..``~>+~.-|^+|-...```-~~|^|-~^^^^++|`..`|?wuzwwwrrwxuuxu|<br></br>
`v}}rwwwwzwzxu?-.`+?>>+^^|````.....~>>`` ....`.```````~^-^`~+ruzwwrwxuyyyy^<br></br>
`v}}rwwwwzzzu}`.^>|`...........`-^??+>>>+|-.............-++.~+zxzwwzxuyyyy^<br></br>
`v}}rwwwwwzxu>|+>^|`.........`~~`++|++|>?`~^|`.............||^vuzwwxuyyyyy^<br></br>
`v}}rwzzwwwxw^|~```.......`|^~....~...-+.  .-+>^-`...`~++~`++^ruzwzxuyyyuy^<br></br>
`}rrrwwwwwwxw-..``-`. .``||-.......`.`-.......+>>>>+||~|^~..~?uxwwzuuuuuuy^<br></br>
                  `zuuxwwwwwwwxw?`|++~~|~-`...``....``-~```.....`^~..........-?zxzwwxuuuuuuy^<br></br>
                  `zxuuxzwwwwwwzuv|-~~-`....`~|```---`.--~~~~-``~+^-`.....`-+vxzwwwwxuuuuuuu|<br></br>
`wwwzxzzwwwwwwzux}?~````-~```................`--`-^||++>?rxxwrwrrrwwwzzwww~<br></br>
`uuzzwwrrrwwwwwzzxxxzww}^>`.....................`+>+ryuuuxzwwrrrrwzzxzwrrw~<br></br>
-uyyuuxzwwwwwwwwwwwwzuuuv++-`.....`~~||^^^|~---`|>+vuuxxzwwwwwwwwwzuuuuxzw~<br></br>
-uuuuuuzwwwwwwwwwwwwwxuuur?+>^~||^+>+-^>+^|||^-. `}uuxzzwwwwwwwwwrwwwwwzzz~<br></br>
-xxxxxzwwwwwwwwwwwwwwzzzzux}>++^|^>^`..-^~-````~-`?xuxzzwwwwwwwwwrwwwwwwzz~<br></br>
                  `zzzzzzwwwwwwwzzwwwwwzzwzz??^``-~^-.`~-``|`.|?zuxxxxxzzzzzzzzzzwwwzxuuuuuu|<br></br>
`xzzzzzzwwwwwwzzwwwwwwwwwzx}>>^`.~~.-|~-~^``v}yxwwzzzzzzzxxxzzzwwwzxxxxxuu|<br></br>
                  `xxxxuuxzzwwwzzzzwwwwwwwwwzxxur??^?~...~vv`-?xuzwwwzzzwzzxxxxzwwwzzzxxzxxx|<br></br>
-uyyyyyyuuxzzwzzwwwwwwwwwwwwxxvv}vv?>~?}??+vvruzwwwzzwwwzzxxxwwwzxxxxxxxxx|<br></br>
                  -uuuuuuuuuxxzzwwwwwwwwwwwwwzu? ```.````````-``xxwwzzzwwwwzzxzwzzxxxxxxxxxx|<br></br>
-xuxxxxxxxxxxxzzwwwwwwwwwzzxu+     .>v>.     .zuzwzzzwwwwwzzwzxxxxxxxxxxxx|<br></br>
-uuuuuuuuuuuuxxzwwwwwwwwwwzxx.      ~+~       }uzwzzzzwwwwwwwzxxuuuuuuuuuu|<br></br>
-xxxxxxxxxxzzwwrrrrwwwwwwwzur                 >yxwwzzwwwwwwwwwzxxxxxxxxxuu|<br></br>
                  `wwwwwwwwwwrrrrwrrrwwwrwwwzu?                 ~uuzwzzwwwwwwwrwwzxxxxxxxxuu^<br></br>
`rrrrrrrwwwrrrwzzwwwwwrrwwzu>                 .wxwwwwwrrwwwwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`rrrrrrrrrrv????????vvvvvvvv}rv?v}}}vvv}}rvvr}vvvvv}}}}}vvvvvvvvvv}rrrrrrr~<br></br>
-uyyyyyyyyuw}vvvvv}rwwwwwzzzurrxzw}?????vwxzvxzzzzzzzzzzzzzwwwwwwwxuuyyyuy^<br></br>
-uuuuuuyyyyyyzvvv}wzzzzwwzzurrxz?|~~~~~~~^vxw}uzzwzzzzzzzxzwwwwwwwzuuuux}}~<br></br>
-uuuuyyyyuxzzrvv}rwwzzzwzzxuvuzw|~~~~~~~~~>zy}xxxzwzwwwwzzxzzwwwwwwxuuxr?v-<br></br>
-uuuyyyyuxwrrr}rrwwwwwzzzxxuvuzw+~~~~~~~~~+rx}xuxzwwwwwwwzxxxzwwwzwwr}vvvv-<br></br>
-uuxzwrwwwwwzwwwwwwwwwzzxxxu}?>>+~----~~---^vvuuxzzwwwwwwwxxxzwwwzw}vvvvvv-<br></br>
`r}vvvvv}rwzxxzzwwwwwzxxuuuu>`...`|..`.....|-wuuuxxzzzwwwwxxxzwwwww}vvvvvv-<br></br>
`vvvv}}}rwwzxxxzzxxxzwwzxuuy?^....-|``.....~vuuuuxzwwwwzxxuuuxwwwwr}vvvvvv-<br></br>
`vvv}rrwwwzzxxuuz}?^~``-~|?}r>``|-``....-~~>rrv?+|~---~^>vwuuxzwwwr}vv}vvv~<br></br>
`vv}}wwwwzzzxuuv^-..``~>+~.-|^+|-...```-~~|^^-~^^^^++|`..`|?wuzwwwrrwxuuuu^<br></br>
`v}}rwwwwzwzxu?-.`+?>>+^^|``.`.....~>>`` ....`.```````~^-^`~+ruzwwrwxuyyyy^<br></br>
`v}}rwwwwzwzu}`.^>|`...........`-^??+>>>+|`.............-++.~+zxzwwzxuyyyy^<br></br>
`v}}rwwwwwzxu>|+>^|`.........`~~`++|++|>?`~^|`.............||^vuzwwxuyyyyy^<br></br>
`v}}rwzzwwwxw^|~```.......`|^~....~...`+.  .-+>^-`...`~++~`++^ruzwzxuyyuuy^<br></br>
`}}rrwwwwwwxw-..``-`. .``||-.......`.`-.......+>>>>+||~|^~..~?uxwwzuuuuuuy^<br></br>
                  `zuuxwwwwwwwxw?`|++~~|~-`...``...```-~```.....`^~..........-?zxzwwxuuuuuuy^<br></br>
                  `zxuuxzwwwwwwzuv|-~~-`....`~|```---`.--~~~~-``~+^-`.....`-+vxzwwwwxuuuuuuu|<br></br>
`wwwzxzzwwwwwwzux}?|````-~```................`--`-^^|>+>?rxxwwwrrrwwwzwwww~<br></br>
`uuxzwwrrrwwwwwzzxxxzww}^>`.....................`+>+ryuuuxzwwwrrrwzxxzwrrw~<br></br>
-uyyuuxzwwwwwwwwwwwwzuuuv++-`.....`~~||^^^|~---`|>+vuuxxzzwwwwwwwwzuuuuxzz~<br></br>
-uuuuuuzwwwwwwwwwwwwwxuuur?+>^~||^+>+-^>+^^||^-. `}uuxzzwwwwwwwwwrwwwwwwzz~<br></br>
`xxxxxzwwwwwwwwzwwwwwzzzzux}>++^|^>^`..-^~-````~-`?xuxzzzzzwwzzwwrwwwwwwzz~<br></br>
                  `zzzzzzwwwwwwwzzwwwwwwzwzz??^``-~^-.`~-``|-.|?zuxxxxxzzzzxxzzzzwwwzxuuuuuu|<br></br>
`xzzzzzzwwwwwwzzzwwwwwwwwzx}>>^`.~|.-|~-~^``v}yxwwwzzzzzzxxxzzzwwwzxxxxxuu|<br></br>
                  `xxxxuuxzzwwwwzzzwwwwwwwwwzxxur??^?~...~vv`-?xuzwwwzzzwwzxxxxzwwwzzzxxxxxx|<br></br>
-uyyyyyyuuxzzwwzwwwwwwwwwwwwxxvv}vv?>~?}??+vvruzwwwzzzwwzzxxzwwwzxxxxxxxxx|<br></br>
                  -uuuuuuuuuxxzzwwwwwwwwwwwwwzu? ```.````````-``xxwwzzzzwwwzzzwwzzxxxxxxxxxx|<br></br>
`xxxxxxxxxxxxxzzwwwwwwwwwwzxu+     .>v>.     .zuzwzzzzwwwwzzwzxxxxxxxxxxxx|<br></br>
-xuuuuuuuuuuuxxzwwwwwwwwwwzxx.      ~+-       }uzwzzxzwwwwwwwzxxuuuuuuuuuu|<br></br>
-xxxxxxxxxxzzwwrrrrwwwrwwwzur                 >yxwwzzwwwwwwwrwzxxxxxxxxxuu|<br></br>
                  `wwwwwwwwwwrrrrrrrrwwrrrwwzu?                 ~uuzwzzwwwwwwwrwwzxxxxxxxxuu^<br></br>
`rrrrrrrwwwrrrwzzwwwwwrwwzzu>                 .wxwwwwwrrrwwwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`rrrrrrrrrr}v?????vvvvvvvvvvvvv}}vvvv???v}rr}vvvvvv}}}}}vvvvvv}rrrrrrrrrrr~<br></br>
-uyuuyyyyyuw}vv}rwwwwzzwwzzzzxxzwzzwrrwwr}rxuxzzzwzxxxxzzzzzwwxuuyyyuuuuuy^<br></br>
-uuuuuuuuuuuuzv}wwwzzzzwwzzzxv+~|+>|~~|+^|-+xxzzzwzzzzzzzzxzzwzxuuuuuuuzvr~<br></br>
-uuuuuuuuuxzzw}rwwwzzzzwwzzx}^`...`~~~`...`|vuxxxzwzzzzzwzzxzwwzxuuuuuzv?v-<br></br>
-uuuuuuyuuwrrwwwwwwwwzzxzzzxz?```..~|`...``>vuxuxzzzzwwwwwzxxzwwzuuzrvvvvv-<br></br>
-uuuzwrrwwwwzzzwwwwwwxuuuuuuyw???||-..`^>+>vzyuuuuuuuxzzwwzxxzwwzzwvvvvvvv-<br></br>
`r}vvvvvvvrwxxxzwzzxw}v???vv?>|~-`.`|^--`-++>?v????v}wuxwwzzxzwr}vvvvvvvvv-<br></br>
`vvvvvvvvrwzzxxxxur>||~-````.....`~|+>|`.... ...`-|^++>vxxzzzzwr}}vvvvvvvv-<br></br>
`vvvvv}wzwwzzxxuu?|-..........-|+|>` .`>+^|`.........`|^+ruzzzwwr}vvvvvvvv~<br></br>
`vvvvv}wwwwwzxuzv^~`......`|+>>^`>+?~~~?--+??|`.....`..`+^rxzzwwr}}rzuuuuu^<br></br>
`vvvvvvv}}rwzxuw>``--` .`^?v>+~` >v|-~|~?-.-+??+`..-+??>+|?uxzwwrrzuyyyyyy^<br></br>
`vvvvvvvv}rwwzuw|.^>+>++??^``~...|v?+>???. `^~|>>~^+>+>+~+vuzwwwwzuyyyyyyy^<br></br>
`vvvvvvv}}rrwzxx?`...-~`.....`....>``-`^~...|..`-```..`-++zxwwwwwzuyyyyyyy^<br></br>
`vvvvvvv}}}rrwzxxv-... .....`.....`|..~-....`~.....``.`~|}uzwwwwwzuuyyyuuy^<br></br>
`v}}}}v}}}rrrrwwxuw?~.....`|-.......^^`......`~`.......^}uxwwwwwwzxuuuuuuy^<br></br>
`rzuuxzzwwwwwrwwzxxxz}??|^++-`.``-~~`~~||||-`-^^~~-|+?}uuzzwwwwwwzuuuuuuuy^<br></br>
`rzxuyyyyuuxwwwwzzwwzuuu}++---````.......````--`^+>uuuuuxzwwwwwwwwxuuuuuuu|<br></br>
`wrwzzxxuuxzwwwzzzwwwzxxuv+^`..................~+>xuxxzzzwwwwwwwrrwwwwwwww~<br></br>
                  `uuxzwwwwwwwrwwzzzwwwzxzzur?+|````-~|-|^^^|~~|++?uuxzzzzzwwwwwwwwzxxxzwrrw~<br></br>
-uyyuuuuxzzwrrwwwwwwwwzwwxuu}>+^||+>^.-+>^^^^|`.?zxzzzzzzzwwwwzxxuuuuuuxzw~<br></br>
-xuuuuuuyyuuxzwwwwwwwwwwwzr??|-`-|^~....-|~~>}rr}wzzzzzzzzwwwwzzwwwwwwwwwz~<br></br>
`zzzzzzzzxxxxxzwwwwwwwwwwwzzrv??+``~-^^^^``|}yuzzwwwzzzzzzwwrwwwwwwzzzzzzz~<br></br>
`zzzwwzzzzzzzzzzwwrrwwwwwwwwzxuuvv>?|``+?|}vzuxwwwwwzzzzzzwwrwwzzzxuuuuuuu|<br></br>
`xzzzzzzzzzzxzzzwwwrwwwwwwwwwzu}>?>+>^+?+>?>wuxwwwwzzzzzzzzwwzxxxxxxxxxxxu|<br></br>
                  `xxxxxuuuuuxxxxzzwwwwwwwwwwwwxu^    `+~     ?yxwwwwzzzwwzzwwzxxuxxxxxxxxxx|<br></br>
                  -uyuuuuuuuuuuuuuzwwwwwwwwwwwzxu~    -?^     |yxwwwwzxzwwwwwwzxxxxxxxxxxxxx|<br></br>
                  -uuxxxxxuuuuuuxxzwwwwwwwwwwzzxz.            .xxzwwwzxzwwwwwwzxxxxxxxxxxxxu|<br></br>
                  `xxxxxxxxxxxxxxxzwwwwwwwwwwzxuv              ruzwwwzxzwwwwwwzxxxxxxxxxxxxx|<br></br>
                  -xuuuuxuuuuuuuxzwwwwwrwwwwwzuu|              ?yxwwwzxzwwwwwwwzxuuuuuuuuuuu|<br></br>
                  `xxxxzzxxxzzzzwrrrrrrrrrwwwzuz.              |yuzwwzzwwwwwwwrwzxxxxxxxxxuu|<br></br>
                  `rwwrrrrwwwwwwrrrwwwrrrrwwzxuv                wuxwwzzwwwwwwwrwwxxxxxxxxxuu|<br></br>
`}rrrrrrrwwwwwrrrzwwwwrrwzzxx-      `vv.      >uzwwwwwrrrrwwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`}}rrrrrrwr}v?????vvvvvvvvvvvvv}}vvvv???v}rr}vvvvvv}}}}}vvvvvv}rrrrrrrrrrr~<br></br>
`xzzzxxxxuuw}vvvrwwwwzzwwzzzzxxzwzzwrrwwr}rxuxzzzwzxxxxzzzzzwwxuyyyyuuuuuy^<br></br>
`wwzzzzzzzzxuz}}wwwzzzzwwzzzxv+~|+>|~~|+^|-+xxzzzwzzzzzzzzxzzwzxuuuuuuuzvr~<br></br>
`zwwwwzzzzwrwwrrwwwzzzzwwzzx}^`...`~~~`...-|vuxxxzwzzzzzwzzxxwwzxuuuuuzv?v-<br></br>
`wwrwwwwwwr}rwwwwwwwwzzxzzzxz?```..~|`...``>vuxuxzzzzzzwwwzxxzwwzuuz}vvvvv-<br></br>
`rwzz}vvv}rrzzzwwwwwwxuuuuuuyw???||-..`^>+>vzyuuuuuuuxzzwwzxxzwwzzwvvvvvvv-<br></br>
`v}vvvvv?vrwxxxzwzzzw}v???vv?>|~-`.`|^--`-++>?v????v}wuxzwwzzzwr}vvvvvvvvv-<br></br>
`vvvvvv?v}wzzxxxxur>||~-````.....`~|+>|`.... ...`-|^++>vxxzzzwwr}vvvvvvvvv-<br></br>
`vvvvvvwzzwwzxxuu?|-..........-|+|>` .`>+^|`.........`|^+ruzzwwwr}vvvv}vvv~<br></br>
`vv??vvwwwwwzxuzv^~`......`|+>>^`>+?~~~?--+??|`.....`..`+^wxzzwwr}}rzuuuuu^<br></br>
`v?vvvvv}}rwwxuw>``--` .`^?v>+~` >v|-~|~?-.-+??+`..-+??>+|?uxzwwrrzuyyyyyy^<br></br>
`??vvvvvv}rwwzuw|.^>+>++??^``~...|v?+>???. `^-|>>~^+>+>+~+vuzwwwwzuyyyyyyy^<br></br>
`??vvvvv}}rrwzxx>`...-~-.....`....>``-`^~...|..`-```..`-++zxwwwwwzuyyyyyyy^<br></br>
`??v}vvv}}}rrwzxxv-... .....`.....`|..~-....`~....```.`~|}uzwwwwwzuuyyyuuy^<br></br>
`vvv}}v}}}}rrrwwxuw?~.....`|-.......^^`......`~`.......^}uxwwwwwwzuuuuuuuy^<br></br>
`vrzuxzzwwwwrrwwzxxxz}??|^++-`.``-~~`~~||||-`-^^~|-|+?}uuzzwwwwwwzuuuuuuuy^<br></br>
`}rwuyyyyuuxwwwzzzwwzuuu}+>---````.......````--`^+>uuuuuxzwwwwwwwwxuuuuuuu|<br></br>
`vvvrxxxuuxzwwwzzzwwwzxxuv+^`..................~+>xuxxzzzwwwwwwwrrwwwwwwww~<br></br>
                  `wwrrwwwwwwwrwwzzzwwwzxzzur?+|````-~|-|^^^|~~|++?uuxzzzzzwwwwwwwwzxxxzwrrw~<br></br>
`zzzxuuuxzzwrrwwwwwwwwzwwxuu}>+^||^>^.-+>^^^^|`.>zxzzzzzzzwwwwzxxuuuuuuxzz~<br></br>
`wzzzuuuyyuuxzwwwwwwwwwwwzr??|-`-|^~....-|~~>}rr}wzzzzzzzzwwwwzzwwwwwwwwzz~<br></br>
`rrrrwxzzzxxxxzwwwwwwwwwwwzzrv??+``~-^^^^``|}yuzzwwzzzzzzzwwrwwwwwwzzzzzzz|<br></br>
`}}}}}zzzzzzzzzzwwrrwwwwwwwwzxuuvv>?|``+?|}vzuxwwwwwzzzzzzwwwwwzzzxuuuuuuu|<br></br>
`w}}}}wzzzzzzzzzwwwrwwwwwwwwwzu}>?>+>^+?+>?>wuxwwwwzzzzzzzzwwzxxxxxxxxxxxu|<br></br>
                  `xwwwwzuuuuxxxxzzwwwwwwwwwwwwxu^    `+~     ?yxwwwwzzzwwzzwwzxxuxxxxxxxxxx|<br></br>
                  -uxzzzxuyuuuuuuuzwwwwwwwwwwwzxu~    -?^     |uxwwwwzxzwwwwwwzxxxxxxxxxxxxx|<br></br>
                  -uxwwwwxuuuuuuxxzwwwwwwwwwwzzuz.            .xxzwwwzxzwwwwwwzxxxxxxxxxxxxu|<br></br>
                  -xxwwrrwuxxxxxxxzwwwwwwwwwwzxuv              ruzwwwzxzwwwwwwzxxxxxxuuxxxxu|<br></br>
                  -uuzwwwzuuuuuuxzwwwwwrwwwwwzuu|              ?yxwwwzxzwwwwwwwzxuuuuuuuuuuu|<br></br>
                  `xxzwwwwzxzzzzwrrrrrrrrrwwwzuz.              |yxzwwzzzwwwwwwrwzxxxxxxxxxuu|<br></br>
`rwr}rrr}wwwwwrrrwwwrrrrwwzxuv                wuxwwzzzwwwwwwrwwxxxxxxxxxuu|<br></br>
`}rr}rr}}wwwwwrrrzwwwwrrwzzxx-      `vv.      >uzwwwwwrrrrwwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`}?>^+>+?ww}v?????vvvvvvvvvvvvv}}vvvv???v}rr}vvvvvv}}}}}vvvvvv}rrrrrrrrrrr~<br></br>
-uyurrw}vyyz}vvvrwwwwzzwwzzzzxxzwzzwrrwwr}rxuxzzzzzxxxxxzzzzwwxuyyyyuuuuuy^<br></br>
-uuuxxzwryyyuz}}wwwwzzzwwwwwxv+~|+>|~~|+^|-+xxzzzzzzzzxzzzxzzwzxuuuuuuuw}w|<br></br>
                  -uyuwwwwwzzzzwrrwwwwwzzwwwzxr|`...`~~~`...-|vuxxxzwzzzxzwwzxzwwzxuuuuuzv?v-<br></br>
`zwrr}rr}}}}rwwwwwwwwzzzzwzzz?```..~|`...``>vuuuxzzzzzzzwwzxxzwwzuuzrvvvvv-<br></br>
`}v}v???vvrrzzzwwwwwwxuuuxxuyw???||-..`^>+>vzyuuuuuuxxxzwwwzxzwwzzwvvvvvvv-<br></br>
`????>++>?vrzzzzwzzxw}v???v}?>|~-`.`|^--`-++>?v????v}wuxzwwzzzwr}vvvvvvvvv-<br></br>
.^>+++^+>vrwzzzzxur>||~-````.....`~|+>|`.... ...`-|^++>vxxzwzwwr}}vvvvvvvv-<br></br>
.?vv??v}}rrwwzzuu?|-..........-|+|>`..`>>^|`.........`|^+rxzzwwwr}vvvv}vvv~<br></br>
`vvv}vv}}rrwwwxzv^-`......`|+>>^`>+?~~~?--+??|`.....`..`+^wuzzwwr}}rzuuuuu^<br></br>
`vv>???vv}rwwzuw>``--`..`^?v>+~`.>v|-~|~?-.-+??+`..-+??>+|?uxzwwrrzuyyyyyy^<br></br>
`?++??>?v}rwwzuw|.^>+>++??^``~...|v?+>???. `^-|>>~^+>+>+~+vuzwwwwzuyyyyyyy^<br></br>
`v>>>>>>?}rrwzxx?`...-~-.....`....>``-`^~...|..`-```..`-++zxwwwwwzuyyyyyyy^<br></br>
`}?>>>?>?v}rrwzxuv-... .....`.....`|..~-....`~....```.`~|}uzwwwwwzuuyyyuuy^<br></br>
`}v??????v}rrrwzxuw?~.....`|-.......^^`......`~`.......^}uxwwwwwwzuuuuuuuy^<br></br>
`wr}vv}vvvwwwwwzzxxxz}??~^++-`.``-~~`~~||||-`-^^~~-|+?}xuzwwwwwwwzuuuuuuuy^<br></br>
`w}v}vrwwwxzwwwzzzwwzxuu}++---````.......````--`^+>uuuuxzwwwwwwwwwxuuuuuuu|<br></br>
`w}????}}rwzwwwzzzwwwzxxuv+^`..................~+>xuxzzzzwwwwwwwrrwwwwwwrw~<br></br>
-uu}v?????vwwwwzzzwwwwzzzuw?+|````-~|-|^^^|~~|++?uuzzzzzzwwwwwwwwzxxxzwrww~<br></br>
-uuwrrr}}vvwwwwzzwwwwwwwwzuu}>^^||^>^.-+>^^^^|`.?zxzzzzzzzwwwwzxxuuuuuxxzz~<br></br>
-uurrrwzrrrzxzwwwwwwwwwwwzr??|-`-|^~....-|~~>}rr}wzzzzzzzzwwwwzzwwwwwwwwww~<br></br>
`xx}vv}wvvvrxxzwwwwrwwwwwwzzrv??+``~-^+^^``|}yuzzwwwzzzzzzwwrwwwwwzzzzzzzz|<br></br>
`zxvvvvzvvvvwzzzwwwrwwwwwwwwzxuuvv>?|``+?|}vzuxwwwwwzzzzzzwwwwwzzzuuuuuuuu|<br></br>
`zx}?vvz}vvvwzzzwwwwwwwwwwwwwzu}>?>+>^+?+>?>wuxwwwwzzzzzzzzwwzxxxxxxxxxxxu|<br></br>
`xuz}}rxzv}v}xxzzwwwwwwwwwwwwxu^    `+~     ?yzwwwwzzzwwzzwwzxxuxxxxxxxxxx|<br></br>
                  -uyxrrwxywrrruuuzwwwwwwwwwwwzxu~    -?^     |yxwwwwzxzwwwwwwzxxxxxxxxxxxxx|<br></br>
-xux}}}wuwv}}zuxzwwwwwwwwwwzzuz.            .xxzwwwzxzwwwwwwzxxxxxxxxxxxxu|<br></br>
-xux}v}ruz}v}ruxzwwwwwwwwwwzxuv              ruzwwzzxzwwwwwwzxxxxuxxxxuxxu|<br></br>
                  -uuxrrrwuxrrrrzzwwwrrrwwwwwzuu|              ?yxwwzzxzwwwwwwwzxuuuuuuuuuuu|<br></br>
                  `xxzrzwrzzrrrrrrrwrrrrrrwwzzuz.              |yxzwwzzzwwwwwwrwzxxxxxxxxxuu|<br></br>
`wrwv>>?www?>?}rrwwwrrrrwwzxuv                wuxwwzzzwwwwwwrwwxxxxxxxxxuu|<br></br>
`rrrv>>?wwwr??vrrzwwwwrwwzzux-      `vv.      >uzwwwwwwrrrrwrrrwzzzzzzzzzz|<br></br>


            </div>,

            <div>

`?^`````~?wrv????????vvvvvvv}}???v}}v}}v???vr}vvvvv}}}}}}vvv}rrrrrrrrrrrrr~<br></br>
-uuzv+|^+~wz}vvvv}rwwwwwwwzzx?~``|?wrw?|-`|?uuzzzzzxxxxxzzwwxuyyyyyyuuuuuy^<br></br>
-uuyyuv}w?}uxz}v}rwwwzzzwwwzz+-...-^^>-...->xuzzzzzzzzxxzzwwwxuuyuuuuuuw}w|<br></br>
-uuuuuzxrvrzwwrrrwwwwzxxzwwzu}?>?``|^`.|>^>vuxxxxzzzzxxzwwwwwwxuuuyuuuwv?v-<br></br>
-uuuuzv}v}v}wzzwwwwwwxuuuxxxxw??+-```~??^>v}uuuuuuuxxxxzwwwzwwwzuuuw}vvvvv-<br></br>
`}vv??>>??vrwzxzwwwzxwv????>|-`...`+?>`..`~~>?vvvv}rzuuzwwwzzwwwzxrvvvvvvv-<br></br>
.>>+++++>?vrwzzwwzux?`.........`^?+-`~^+|`. ..````-|>}xuzwwzzwwr}vvvvvvvvv-<br></br>
.~|~^^^^^>vrwwwwxxv-.......`~|??v>|`...~+?+|`........~?}xxwwzwwr}vvvvvvvvv-<br></br>
.?|>?>+??v}rwwwzu}~~~`...`|?v?+++~~?`.~?`|>>?>`...`|^-~??uzwwwwr}vvvvv}vvv-<br></br>
`v?vvv}r}}}rwwwzu?~+>>>^>??+-`^^-+?~|^|~?~^^^??^~^>?}}?^^xxwwwwr}vvrzuuuuu^<br></br>
.?vvv>?vvv}rwwwxuv~.```~~`....~`.~v?~~>vv..|`-|+-~~~^>~++xxwwwwr}rxyyyyyyy^<br></br>
.???^|>>>v}rwwwxux?~`.. ......`..`?+??>?^..--..`---`--|^ruzwwwwrwxuyyyyyyy^<br></br>
`vvv^++>>?vrwwwzxux?|`.......~`...^~..`^....|`.......-|>uxzwwwwwzxuyyyyyyy^<br></br>
`v}}+|^+>>?}wwwzzxxxv^`...`~^-.....|``~......~|``...`->zxzwwwwwwzuuyyyuuuy^<br></br>
`}}r?>>????vrwwzzzzzxxrv^^+>~.......~^`````..->++|+>?}xxzwwwwwwzxuyuuuuuuy^<br></br>
`wxy}v?????}wwwzxzwwwzxuv^++^^-`~~||``~^^^+^|^|+^>xuuxxzwwwwwwwxuuuuuuuuuy^<br></br>
                  `wzxrv??vvvrxwwzxzwwwwzxu?++-````.............~+?uuzzwwzwwwwrrwwwxuuuuuuxx|<br></br>
`wwwv??>?vvvzzzwzzwwwwwzxu}>+|`....```--``..-^+?uuzzwwzzwwrrrrr}}}rwwwrrrw~<br></br>
-uuuw??>>???}wwwwwwwwwwwzxuur>^|~~|++-^>^^^||^+zuxzwzzzzwwwzzzzzzxuuxwrwww~<br></br>
-uyyuvvvv???vwwwwrrwwwwwwzwv?^|~-~^|`..-^|~~>?>?zzwwzzzwwwzuuyuuuuuuuuxzzw~<br></br>
-uuuuvvvrvv?vxzwwrrrwwwwwwzw}v??+`|~|^^~`-|vuuuzwwwwzzzzwwzxxzzwwwwwwwwwww~<br></br>
`zzxxv??}v???wzzwwrrwwwwwwwwzuuuvv>?-`|?^vvxuxzwwwwwzzzwwwwwwwwwwwzzzzzzzz~<br></br>
`zzzz???}r???vzzzwwwwwwwwwwwwzuz??+^||>+>?>zuzwwwwwwzzzwwwwzzzzzzxuuuuuuuu|<br></br>
                  `zzzxv??vz???vzzzzwwwwwwwwwwwzu?    +?-    ruzwwwwwzzzwwwzxxuuxxxxxxxxxxxu|<br></br>
`xuuurvv}ur???wxxxxzwwwwwwwwwzu+    `|.    ?uzwwwwwzzzwwwxxuuuuxxxxxxxxxxx|<br></br>
-uyuywvv}uxvvvwuuxzwwwwwwwwwzxu`           |uxwwwwwzxzwwwzxxxxxxxxxxxxxxxx|<br></br>
                  -xxxuw??vzuv?vvxzwwwwwwwwwwzxuw            `uxzwwwwzxzwwwzxxxxxxxxxxxxxxxx|<br></br>
                  -xuxuz???wur??vwzwwwwwwwwwwzxu?            .zuzwwwzzxzwwwzxxxuuxxxxxxuuxxu|<br></br>
-uuuuzrrrwuw}wwrwrrrrrwwwwwzuu`             }uxzwwzzzzzwwwzxxuuuuuuuuuuuuu|<br></br>
`xxzzzvv?}zz}??vrrrrrrrrwwzxuv       .      ^yuzwwwzzzzwrrwzxxxxxxxxxxxxuu|<br></br>
`wrrrw?~^vwwr>+vrrwwrrrrwwzux`      ?x^      wuxzwwzzzzwrrwzxxxxxxxxxxxxxu|<br></br>
`rrr}v>+>vwwwv?vrwzwwwwwwzxu?      .zu}      ^uxwwwwwwwwrrrrwwzzzzzzzzzzzz|<br></br>


            </div>,

            <div>

`r>  ...-?ww}????????vvvvvvv}}???v}}v}}v???vrr}v}}}}}}}}}vvv}rrrrrrrrrrrrr~<br></br>
-uu?~. ..`+v}vvvv}rwwwzwwwwzx?~``|?wrw?|-`|?uuxzzxxxxxxxzzwwxyyyyyyyuuuuuy^<br></br>
-uuyuw?|..`|?w}v}rwwwzxzwwwzz+-...-^^>-...->xuxxxzzxxxxxzwwwzxuyyyuuuuur}z|<br></br>
-uuuuyyywv?v}rrrrwwwwzxxzwwzu}>>?``|^`.|>^>vuuuxxzzzzxxzwwwwwwxuuuyyuuwv?v-<br></br>
-uuuuyyyyx}rwzzwwwwwwxuuuxxxxw??+-```~??^>v}uuuuuuuxxxxzwwwzwwwzuuuw}vvvvv-<br></br>
-uuuuzwww}v}wzzzwwzzxwv????>|-`...`+?>`..`~~>?vvvv}rzuuzwwwzzwwzxxrvvvvvvv-<br></br>
`r}}?>+++>v}wwzwwzux?`.........`^?+-`~^+|`. ..````-|>}xuxwwzzwwr}vvvvvvvvv-<br></br>
`vv+^|^|>?vrwwwwzxv-.......`~|??v>|`...~+?+|`........~?}xxwwzwwr}vvvvvvvvv-<br></br>
`vv|-`~|>?vrwwwwu}~~~`...`|?v?+++~~?`.~?`|>>?>`...`|^-~??uzwzwwr}vvvvvvvvv-<br></br>
`vv+^>^?vv}rwwwzu?~+>>>^>??+-`^^-+?~|^|~?~^^^??^~^>?}}?^^xxwwwwr}vvrxuuuuu^<br></br>
`?v?vv??vv}rwwwxuv~.```~~`....~`.~v?~~>vv..|`-|^-~~~^>~++xxwwwwr}rxyyyyyyy^<br></br>
`vv???????vrwwwxux?~`.. ......`..`?+??>?^..--..`---`--|^ruzwwwwrwxuyyyyyyy^<br></br>
`vvvv???|?vvrwwzxux?|`.......~`...^~..`^....|`.......-|>uxzwwwwwzxuyyyyyyy^<br></br>
`vvv}vv?||+?rwzzxxxxv^`...`~^-.....|``~......~|``...`->zxzwwwwwwzuuyyyuuuy^<br></br>
`}}rr}}v++>v}wzxxxzzxxrv^^+>~.......~^`````..->++|+>?}xxzwwwwwwzxuyuuuuuuy^<br></br>
`rxuuuxw?>>v}wzxxxzwwzxuv^++^^-`~~||``~^^^+^|^|+^>xuuxzzwwwwwwwxuuuuuuuuuy^<br></br>
`wzzuuuuv?>vvrwxxzzwwwzxu?++-````.............~+?uuzzwwwwwwrrrwwwxuuuuuuxx|<br></br>
`wwwwwzz??>?vv}wzzwwwwwzxu}>+|`....```--``..-^+?uuzzwwzzwwrrrrr}}rwwwwrrrw~<br></br>
-uuuxzww?>>+???rwwwwwwwwzxuur>^|~~|++-^>^^^||^+zuxzwzzzzwwwzzzzzxxuuxzwwww~<br></br>
-uyyyyuyr>?????vwrrwwwwwwzwv?^|~-~^|`..-^|~~>?>?zzwwzzzwwwzuuyuuuuuuuuxzzw~<br></br>
-uuuuuuuw???v???wwrrwwwwwwzw}v??+`|~|^^~`-|vuuuzwwwwzzzwwwzxxzzwwwwwwwwwww~<br></br>
`zzzzzzzr???r???}wwrwwwwwwwwzuuuvv>?-`|?^vvxuxwwwwwwzzzwwwwwwwwwwwzzzzzzzz~<br></br>
`zzzzzzzw???w???vzwwwwwwwwwwwzuz??+^||>+>?>zuzwwwwwwzzzwwwwzzzzzzxuuuuuuuu|<br></br>
                  `zzxxzzzz???zv??vzzwwwwwwwwwwzu?    +?-    ruzwwwwwzzzwwwxxxuuxxxxxzzxxxxu|<br></br>
                  `xuuuuuuuv??wz??vzxzwwwwwwwwwzu+    `|.    ?uzwwwwzzzzwwwxxuuuuxxxxxxxxxxx|<br></br>
                  -uuuuuuuuv??ryv?vrzwwwwwwwwzzxu`           |uzwwwwzzxzwwwzxxxxxxxxxxxxxxxx|<br></br>
-xxxxxxxxv??vu}?v}wwwwwwwwwzxuw            `uxzwwwzzzzwwwzxxxxxxxxxxxxxxxx|<br></br>
-xuxxxxxxvvv}uwv}}rwwwwwwwwzxu?            .zuzwwwzzzzwwwxxxxuuuuuuuuuuuxu|<br></br>
-uuuuuuuuwv?vuz}v}rwwwwwwwwxuu`             }uxwwwzzzzzwwwzxuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzr~|?zwrv}rwwwrrwwzxuv       .      ^yuzwwzzzxzwrrwzxxxxxxxxxxxxuu|<br></br>
`rrrrr}vv>^+?wr}}rwwwrwwwwzux`      ?x^      wuxzwzzxxzwrrwzxxxxxxxxxxxxxu|<br></br>
`}rrr}>^^^++?wrr}rzwwwwwwzxu?      .zu}      ^uxwwwwzzzwrrrrwwwzzzzzzzzzzz|<br></br>


            </div>,

            <div>

`+.    ..?ww}????????vvvvvvv}}???v}}v}}v???vrr}vv}}}}}}}}vvv}rrrrrrrrrrrrr~<br></br>
-uzv+`   .|}rvvvv}rwwwzzwwwzx?~``|?wrw?|-`|?uuxzxxxxxxxxzwwzxyyyyyyyuuuuuy^<br></br>
-uuyyu}^~+-+xz}v}rwwwzxxwwwzz+-...-^^>-...->xuxxxzzxxxxxzwwwzxuyyyuuuuurrz|<br></br>
-uuuuuywv}v?wwrrrwwwwxuuzwwzu}>>?``|^`.|>^>vuuuxxzzzxxxzwwwwwzxuuuyyuurv?v-<br></br>
-uyyyyy}}vv}rwzwwwwwwxuuuxxxxw??+-```~??^>v}uuuuuuuxxxxzwwwzwwwzuuuw}vvvvv-<br></br>
-uw}}v?>+>vvrwzwwwzzxwv????>|-`...`+?>`..`~~>?vvvv}rzuuzzwwzzwwzxxrvvvvvvv-<br></br>
.>^^^-|^^>?}wwwwwzux?`.........`^?+-`~^+|`. ..````-|>}xuxwwzzwwr}vvvvvvvvv-<br></br>
.--`~~++>>?}wwwwzxv-.......`~|??v>|`...~+?+|`........~?}xxwwzwwr}vvvvvvvvv-<br></br>
 `--`|^~|>v}wwwwx}~~~`...`|?v?+++~~?`.~?`|>>?>`...`|^-~??uzwzwwr}vvvvvvvvv-<br></br>
 ???>?vvvv}rwwwzu?~+>>>^>??+-`^^-+?~|^|~?~^^^??^~^>?}}?^^xxwwwwr}v}rxuuuuu^<br></br>
.????v??vv}rwwwxuv~.```~~`....~`.~v?~~>vv..|`-|^-~~~^>~++xxwwwwr}ruyyyyyyy^<br></br>
`?????~|>?vrwwwxux?~`.. ......`..`?+??>?^..--..`---`--|^ruzwwwwrwxuyyyyyyy^<br></br>
`vv??>-+++?vwwzxxux?|`.......~`...^~..`^....|`.......-|>uxzwwwwwzuuyyyyyyy^<br></br>
`v}}}?-`~^+?rwzxxuuxv^`...`~^-.....|``~......~|``...`->zxzwwwwwwzuyyyuuuuy^<br></br>
`}}rrv+++>>?}wzxxxzzxxrv^^+>~.......~^````...->++|+>?}xxzwwwwwwzxuyuuuuuuy^<br></br>
`rxuyz>+>>??vwzxxxzwwzxuv^++^^-`~~||``~^^^+^|^|+^>xxxxzzwwwwwwwxuyuuuuuuuy^<br></br>
`wzzux>>+>??vwzxxzwwwwwxu?++-````.............~+?uuzwwwwwwwrrrwwwxuuuuuxxx|<br></br>
`wwwww>>++>??wzwzzwwwwwzxu}>+|`....```-```..-^+?uuzzwwzzwwrrrrr}}rwwwwrrrw~<br></br>
-uyuxz?>>|>>>vwwwwwwwwwwzxuur>^|~~|++-^>^^^||^+zuxzwwzzzwwwzzzzzxuuuxzwwww~<br></br>
-uyyyyv>>??>>?wwwrrwwwwwwzwv?^|~-~^|`..-^|~~>?>?zzwwwzzwwwzuuuuuuuuuuuxzzw~<br></br>
-uuuuy}>>?v>>>wzwrrrwwwwwwzw}v??+`|~|^^~`-|vuuuzwwwwwzzwwwzxzzwwwwwwwwwwww~<br></br>
`zzzzxv>>?}>>>vzwwwrwwwwwwwwzxuuvv>?-`|?^vvxuxwwwwwwwzwwwwwwwwwwwwzzzzzzzz~<br></br>
`zzzzz}+>>w?>>?zzwwwwwwwwwwwwzuz??+^||>+>?>zuzwwwwwwwwwwwwwzzzzzzxuuuuuuuu|<br></br>
`zzxxxw>>>zv>>>wzzzwwwwwwwwwwzu?    +?-    ruzwwwwwzzzwwwxxuuuxxxzxzzzxxxu|<br></br>
`xuuuux?>>wz>>>}uxxzwwwwwwwwzxu+    `|.    ?uzwwwwzzzzwwwzxuuuxxxxxxxxxxxx|<br></br>
-uuuuuu?>>ry?>>?uxzwwwwwwwwzzxu`           |uzwwwwzzzzwwwzxxxxxxxxxxxxxxxx|<br></br>
-xxxxxx?>>vuv>>>wwwwwwwwwwwzxuw            `uxzwwwzzzzwwwzxxxxxxxxxxxxxxxx|<br></br>
-xuxxxu?>>?uw>>?}wwwwwwwwwwzxu?            .zuzwwwzzzzzwwzxuuuuuuuuuuuuuxu|<br></br>
-uuuuuuvrrvxzv}}vrrwwwwwwwwxuu`             }uxwwwzzzxzwwwzxxuuuuuuuuuuuuu|<br></br>
`zzzzzzv|->zz}||?wwwwwwrwwzxuv       .      ^yuzwwzzzxzwrrwzxxxxxxxxxxxxuu|<br></br>
                  `rrrrrr?``+www?+?rwwwwwwwzxux`      ?x^      wuxzwzxxxxwrrwzxxxxxxxxxxuxxu|<br></br>
`}rrv?>|~~+wwwv?vrzwwwwzzzxu?      .zu}      ^uzwwwwzzzwrrrrwwwzzzzzzzzzzz|<br></br>


            </div>,

            <div>

`>.     .?ww}v???????vvvvvvv}v+^|+v}}}}?>++vrr}}}}}}}}}}vvv}rrrrrrrrrrrr}r~<br></br>
-uz?|.    |vrvvvv}rwwwzzwwwzx?-..`>rzr+```^?uuuxxxxxxxxxzwwxuyyyyyyyuuuxxy^<br></br>
-uuyyxv^.`~`rzrv}rwwwzxxzrwzz?|``..^?+..``~vuuuxzzzzxxxxwwrzzxuuuyuuuuzvrz|<br></br>
                  -uuuuuyy??v?rwwrrwwwwzuuxwzxuw?v?~-|-.~????ruuuuxzzzxxxzwwwwwwzuuuyyuxv??v-<br></br>
-uuyyyyu}rvvrwzwwwwwzuuuxxzzw}?>|```|+>^~+vvxuuuuuuuuuxzwwwwwwwzuuuw}??vvv-<br></br>
-uuxwr}?+>?vrwzwwwzxx}>>++|~``...`->>^-`..``^>????v}wuuxzwwzzwwzxz}v??vvvv-<br></br>
`v>^^|`~|^?vrwwwwzuz+.........-|>?^`.`~>+|........`-?vzuxzwzzwwr}vv???vvvv-<br></br>
`^----|+>>?vrwwwwx?``......~???v??|-...+^??>-........|?ruxwwzwwr}vvv???vvv-<br></br>
`^.```~~~^?vrwwwxz^^?^-.`~>v?^^++~~?-`|?`^++??-...-+>^>>zxwwzwwr}vvvvv???v-<br></br>
`???>>?v??v}wwwzur^-^|++??+-..|+~^?~~|~^?-^^|+?^^>??}v+|vuzwwwwr}v}wxuuzzx|<br></br>
`?????v?vvv}wwwxux>-.``.``....``.-v?|^>v?..^``--`-``~~^^rxzwwwwr}wuyyyyyyy^<br></br>
`v?????~^?v}rwzxuuw^~``.......`...?+>?^>^..|`...`--``~^?uzwwwwrrwuyyyyyyyy^<br></br>
`vv???~~++>vrwzxuuur+~`.....`|`...||...|....|`......`~|wxzwwwwwwzuuyyyyyyy^<br></br>
`v}}}}^``~^+vwzxxxxxz?+~--~^^`.....-```......||-````-^rxzwwwwwwwxuyyyuuuuy^<br></br>
`}}rrr?+^>>>vwzxxxzzzxzw>^+>|`......-|`````..|+++^??}zxwwwwwwwzxuuuuuuuuuy^<br></br>
`rzuuyv+^>>>?rzxxzzwwwzur^++^^~`~|||-`~||^^^|||+^ruxxzwwwwwwwwxuuyuuuuuuuy^<br></br>
`wwzxy}+++>>>}zzxzwwwwwxu}++^``..............`^+ruxzwwwwwwwrrrwwwxuuuuuxxx|<br></br>
`wwwwwv>>^+>>?zwwwwwwwwzxuz?>+|....``--`````|+>ruxzwwwzwwrrrrrr}}rwwwwrrrw~<br></br>
-uyuxxw>>^^>>>rwwwwwwwwwzxuuz>^|~-|++`^+^|~~^|?zuzzwwzzwwwwzxxzzxuuuxzwwww~<br></br>
-uyyyyu>>>>>>+vwwwrwwwwwwzr??+|~-~^~`..-^|~|>??}xzwwwzzwwwxuuuuuuxxuuuxzzw~<br></br>
-uuuuuu?>>v>>>?zwwrrwwwwwwzzwrvv>-^^|+||`~+rxuuzwwwwwzzwwzzxzzwwwwwwwwwwww~<br></br>
`zzzzzx?>>??>>>wzwwwwwwwwwwwwxuy}v??~-+??}vuxzwwwwwwwwwwwwwwwwwwwzzzzzzzzz~<br></br>
`zzzzzz?>>?}>>^}zzwwwwwwwwwwwzuz^^|~~~|-||^xzwwwwwwwzwwwwwzzzzzzzxuuuuuuuu|<br></br>
`zxxxxxv+>?z>>>?xzzwwwwwwwwwwzuv    >v-   .zxwwwwwwzzwwwxxxuuuuxxzxzzzxxxu|<br></br>
-xuuuuyr+>?uv>>>xuxzwwwwwwwwzxu?    ..     vxwwwwwzzzwwwzxxuuxxxxxxxxxxxxx|<br></br>
-uuuuuyw+>>uz>>>rxzwwwwwwwwzzxu-           >uzwwwwzzzzwwzzxxxxxxxxxxxxxxxx|<br></br>
-xxxxxuw>>+wx?>>?zwwwwwwwwwzxuz.           ~yxzwwwzzzzwwzxxxxxxxxxxxxxxxxx|<br></br>
-xuuuuuw>>>ru?>??rwwwwwwwwwzuu?            `uuzwwwzzzzwwzxuuuuuuuuuuuuuuxu|<br></br>
-uuuuuuzvvv}u}vvv}rwwwwwwwwxuu-            .wuxwwwzzzzwwwzxxxuuuuuuuuuuuuu|<br></br>
`zzzzzzz>`.vzz?`^rwwwwwwwwzxu}      .|      >yxzwwzzzxzwrrwzxxxxxxxxxxxxux|<br></br>
`rrrrrr}|.`vwwr>?}wwwwwwwwzuu-      vy+     .xuxzwzxxxxwrrwwzxxxxxxxxxxuxx|<br></br>
`}rrv>+~`--?zwr?v}wzwwwzzzxuv      -xxr      ?xzwwwwzzzwrrrrwwwzzzzzzzzzww~<br></br>


            </div>,

            <div>

`?.     .?ww}v???????vvvvvvv}v+^|+v}}}}?>++vrr}}}}}}}}}}vvv}rrrrrrrrvvv}r}~<br></br>
-uz?~.    |vrvvvvvrwwwzzwwwzx?-..`>rzr+```^?uuuxxzzxxxxxzwwxuyyyyyyu}wuxwr-<br></br>
-uuyuzv~...`?zrv}}wwwzxxzrwzz?|``..^?+..``~vuuuxzzzzxxxzwwrzzxuuuyuw}xxv??-<br></br>
-uuuuyyyr+??vwwrrwwwwzuuxwzxuw?v?~-|-.~????ruuuxzzzzxxxzwwwwwwzuuyu}zzrv||.<br></br>
-uuuuyyyzrrv}wzwwwwwzuuuxxzzw}?>|```|+>^~+vvxuuuuuuuuuxzwwwwwwwzuu}?w}?+~|.<br></br>
-uyyuzzr?>?vrwzwrwzxx}>>++|~``...`->>^-`..``^>????v}wuuxzwwwzzwzxr>?}}?-~|.<br></br>
`r}?+^~-~^>vrwwwwzuz+.........-|>?^`.`~>+|........`-?vzuxzwwzwwr}>?}}vv>~|.<br></br>
`v|~-|-^+>?vrwwwwx?``......|???v??|-...+^??>-........|?ruxwwzww}?>vvv?vv>~.<br></br>
`v`..``~~^?vrwwwxz^+?+-.`~>v?^^++~~?-`|?`^++??-...-+>^>>zxwwzww}?v}vvvvv>|.<br></br>
`v+>?|???>v}wwwzur^-^|++??+-.`|+~^?~~|~^?-^^|+?^^>??}v+|vuzwwww}v?vwxxxzvv-<br></br>
`v?v??v?v}v}wwwxux?-.``.``....``.-v?|^>v?..^``--`-``~~^^rxzwwwr}}}}}wxuuux~<br></br>
`v?????+~>?vrwzxuuw^~``.......`...?+>?^>^..|`...`--``~^?uzwwwwrrwuuzwrrzxz~<br></br>
                  `vvv???`^++?rwzxuuur+~`.....`|`...||...|....|`......`~|wxzwwwwwwzuyyyuxwrv-<br></br>
`vv}}}v-`-|+vwzxxxxxz?+~--~^^`.....-```......||-````-^rxzwwwwwwzxuyyyyyyur~<br></br>
`}}rrrv^^+>?vwzxxzzwzxzw>^+>|`......-|`````..|+++^??}xxwwwwwwwzxuuyuuuuuu}~<br></br>
`rzuyux>+^>>?}zxxzwwwwzur^++^^~`~|||-`~||^^^|||+^ruxxzwwwwwwwwxuuyuuuuuuur~<br></br>
`rwzxuu?+^>>>?wzzzwwwwwxu}++^``..... ........`^+ruxzwwwwwwwrrrwwwxuuuuxxxv-<br></br>
`zzwwwz?>+^>>>rzwwwwwwwzxuz?>+|`...``--`````|+>ruxzwwzzwwrrrrr}}rrwwwrrrr?`<br></br>
-uyuuxzv>>|+>>vwwwwwwwwwzxuuz>^|~-|++`^+^|~~^|?zuzzwwzwwwwzzxxzxuuuuxzww}?`<br></br>
-uyyyyy}+>>>>>?wwrrrwwwwwzr??+|~-~^~`..-^|||>??}xzwwwzwwwwxuuuuuxxxxuxxz??`<br></br>
-xuuuuyw+>>v>>>rwwrrwwwwwwzzwrvv>-|^|+||`~+rxuxzwwwwwwwwwzzzzzwwwwwwwwwr??`<br></br>
`zzzzzxr>>>}>>+vzwwwwwwwwwwwwxuy}v??|-+??}vuxzwwwwwwwwwwwwwwwwwwwzzxzzx}??-<br></br>
`zzzzzzw+>>w?>+?zzwwwwwwwwwwwzuz^^|~~~|-~|^xzwwwwwwzzwwwwwzzzzzzzxuuuuurv?-<br></br>
`zxxxxxx>>>wv>>>wzzwwwwwwwwwzzuv    >v-   .zxwwwwwwzzwwwxuuuuuuxxzzzzxw???-<br></br>
-xuuuuuy?>+}x>>+vuxzwwwwwwwwzxu?    ..     vxwwwwwzzzwwwzxxxxxxxxxxxxxv???-<br></br>
-uuuuuuu?>>vy?>>?xzwwwwwwwwzxxu-           >uzwwwwzzzzwwzzxxxxxxxxxxxzvv??-<br></br>
-xxxxxxuv>>?u}+>>rwwwwwwwwwzxuz.           ~yxzwwwzzzzwwzxxxxxxxxxxxuw?v??-<br></br>
-xuuuuuuv>??xw>??vwwwzwwwwwzxu?            `uuzwwwzzzzwwzxuuuuuuuuuuur?vvv-<br></br>
-uuuuuuuvvv?zzv??vrwwzwwwwwxuu-            .wuxwwwzzzzwwwzxxxxxxuuuux}}}}}~<br></br>
`zzwwwwzr`.|zwr~-vwwwwwwwwzxu}      .|      >yxzwwzzzxzwrrwzxxxxxxxurv}}vw^<br></br>
`rrrrrr}?``|www??vwwwwwzwwzuu-      vy>     .xuxwwzxxxxwrrwwzxxxxxxxvv}}vz^<br></br>
`}rr}>|~`---rwwvv}wzwwwzzzxuv      -xxr      ?xzwwwwzzzwrrrrwwwzzzzrvvvvvw|<br></br>


            </div>,

            <div>

`v.     .?ww}v???????vvvvvvv}v+^|+v}}}}?>++vrr}}}}}}}}}}vvv}rrrr}}rr}vvvr}-<br></br>
-yz?-    .|vrvvvvvrwwwxxwwwzx?-..`>rzr+```^?uuuxzzzzxxxxzwwxuyyyuxuyuuuxwz|<br></br>
-uuyuw?~. .`?zrv}rwwwzxxzrwzz?|``..^?+..``~vuuuxzzwzzxzzwrrzzxuuyuuwzuxvvw|<br></br>
-uuuuyyyz+?vvwwrrwwwwzuuxwzxuw?v?~-|-.~????ruuuuxzzzxxxzwwwwwwzxxzwvvvv>|?-<br></br>
-uuuuuyyurr}rwzwwwwwzuuuxxzzw}?>|```|+>^~+vvxuuuuuuuuuxzzwwwwwrrrzr?+~||-+`<br></br>
-uuyuxzw}??vrwzwwwzxu}>>++|~``...`->>^-`..``^>????v}wuuxzwwwzwrrzv|~|+~-~`.<br></br>
`}rv>+|--^?vrwwwwzuz+.........-|>?^`.`~>+|........`-?vzuxzwwzwrrr>-^??^-~-.<br></br>
`v>~~~-|+>?vrwwwwx?``......|???v??|-...+^??>-........|?ruxwwwwr}v>|~|||~~-.<br></br>
`v^...`-~^?vrwwwxz^+?+-.`~>v?^^++~~?-`|?`^++??-...-+>^>>zxwwwwrr?^|~~~~||-.<br></br>
`v>^>+^??>?}rwwzur^-^|++??+-.`|+~^?~~|~^?-^^|+?^^>??}v+|vuzwwww}v>|>?vv???`<br></br>
`v???????}v}wwwxuu?-.``.``....``.-v?|^>v?..^``--`-``~~^^rxzwrwr}}r??}r}vvv-<br></br>
`vv?????|+?vrwzxuuw^~``.......`...?+>?^>^..|`...`--``~^?uzwwrrrrwwv??????v-<br></br>
`v}v???-~+>?rwzxuuur+~`.....`|`...||...|....|`......`~|wxwwwwrrrwwwr}}v??>`<br></br>
`vv}}}}^``|>vwzxxxxxz?+~--~^^`.....-```......||-````-^rxwwwwwwr}}rzrrvvvv?`<br></br>
`}}rwrr>^^>?vwzxxzzwzxzw>^+>|`......-|`````..|+++^?v}xxwwwwwwwzzzw}}}vvvv?`<br></br>
`rzuyuuv+^>>?vzxxzwwwwzur^++^^~`~|||-`~||^^^|||+^ruuxzwwwwwwwwxuuywvv?vvv?`<br></br>
`rwzxuy}+^+>>?rzzwwwwwwxu}++^``..... ........`^+ruxzwwwwwwwrrrwwwxr?v????>`<br></br>
`zzzwwzv>>^>>>vwwwwwwwwzxuz?>+|`...``--`````|+>ruxzzwwzwwrrrrr}}rrv>>>>>+^.<br></br>
-uyyuxzr>>^^>>?wwwwwwwwwzxuuz>^|~-|++`^+^|~~^|?zuzzzwzwwwwzzxxzxuuw???>?++.<br></br>
-uyyyyyx>>+>>>>rwwrwwwwwwzr??+|~-~^~`..-^|||>??}xwwwwzwwwzxuuuuuxxv????v++.<br></br>
-xuuuuux>>>v>>+vzwwrwwwwwzzzwrvv>-|^|+||`~+rxuxzwwwwwwwwwzzzzzwwwv>>>>v?+>`<br></br>
`zzzzzzz>>+v?>>?zwwwwwwwzwwwzxuy}v??|-+??}vuxwwwwwwwwwwwwwwwwwwwz?????w>+>`<br></br>
`zzzzzzz?>>vv>>+wzwwwwwzzwwwwzuz^^|~~~|-~|^xzwwwwwwzzwwwwwzzzzzzw?vv?wx?>>`<br></br>
`zxxxxxxv+>vw>>+vxzwwwwwwwwwzxuv    >v-   .zxwwwwwwzzwwwxuuuuuuu}????zr>>>`<br></br>
-uuuuuuy}+>?u?>>?uxzwwwwwwwwzxu?    ..     vxwwwwwzzzwwwzxxxxxxxv???vxv+>>`<br></br>
-uuuuuuur+>?ur+>>wzwwwwwwwwzxxu-           >uzwwwwzzzwwwzzxxxxxw????wz?>>?~<br></br>
-xxxxxxur+>>zz>>>vwwwzwwwwwzxuz.           ~yxzzwwzzzzwwzxxxxxu}???vzr>>>?|<br></br>
-xuuuuuuw>??wu???vwwzzzwwwwxuy?            `uuxzwwzzzzwwzxuuuuxv???wu}>?>v|<br></br>
-uuuuuuuw???rx}??vrwwzwwwwzxuu-            .wuxzwwzzzzwwwzxxxuwvvvvxxv???z^<br></br>
`zzwwwwwz^..}ww>-?rwwwwzwwzxu}      .|      >yxzzwzzzxzwrrwzxxvvvvruz????x^<br></br>
`rrrrr}}?-`-vwwv?vrwwwwzzwzuu-      vy>     .xuxzwzzxxxwrrwwzwvvvvzuw???vu^<br></br>
`}rrr?~~`--`?zwvv}rzwwwzzzxuv      -xxr      ?xzwwwwzzzwrrrrw}vvvvwzv>>?vz|<br></br>


            </div>,

            <div>

`r~     `?rwrv???????vvvvvvv}?~--~?r}r?||~|?rrr}vvvvvv}vvv}rrrrrrrrrrrrrrr~<br></br>
-ux?`    .^rr}vvvv}rwwxxwwwzx>~...~vzv-...|?uuuxzzzzzzzzwwzuyyyxxxuyyuuyyy^<br></br>
-uuyur?~. `+}rr}}rrwwzxxwrwxuv?++`.~?-.~^^^ruuuxzzwwzzzzwrwzzzrzxxzuuuzrzu^<br></br>
`uuuuuyyz>>v}wzwwwwwwzuuxzzuux?v?^-~`-?v?v?zuuuuxxxzzzzzzwwwrrrwzzwzuxv?vv~<br></br>
`xuuuuyyyw}}rwzwwwwzzxzzwrr}??^~`..|?>~`-+??wzzzxxxxuuxzzwwwwwrrwxrv}v???v-<br></br>
`xuuyuxzw??}rwzwwwzux?~-~-``....`^^^~|^|`...`~^+^+>vrxuxxwwwwwwwrvr}?v???v-<br></br>
`}wr??>^~|?vrwwwwzx}^. ....`~~>???|`..`+?+-`.......`+vwuxzwwwwrrv?wvv????v-<br></br>
`vv^|~|-+>?vrwwwwx}~|~....`+v?>?v>^~. .^|?v?+`....-``|?}uzwwww}v>|?vvv???v-<br></br>
`v?...``|^?vrwwwzu?|?>^~^>??^`|++||?~->^`+>^??^-`~>vv??>uxwwwr}?+`->vvvvvv-<br></br>
`v?|^>~>?+?}rwwzxu?~`-``|^-`..`|~^v--~-+?~^|||>||>>?v>^^xxwwrrrv+~`^?xuxuu|<br></br>
`vv?v????v}}wwwxuuw^-..`.~`...``.-v?+>?v?.`|.`-~--``-~+>uzwrr}v?>^~|?yyyyy^<br></br>
`vv?????+^?vrwzxuuu?^~`......`~...?^>?|>|..~`...````-^^zxwwrr}vv?>~`>yyyyy^<br></br>
`vvv???+`+>?}wzxxxuuv^-`....~^`...~~...~....|-......`|vuzwwwrr}v?+|~>zyuuy^<br></br>
`vvv}}}?``~>vwzxzzxxxwv>^^^+^`.....``--......^+|~-~|+vxzwwwww}v>^-|-`^xuuy^<br></br>
`}}rwrrv^^+>vrzxzwwwwzxuv^++^-....``-|-~~--`-^++|?}wxxwwwwwwwv?>^~--`vuuuy^<br></br>
`rwuyyuw>^+>?vwzzwwwwwwxx>++^^|-||||``~|^^+^^~|+>uuxzwwwwwwrv?>^+^~~|vuuuy^<br></br>
`rwwzuyx>+^>>>vwwwwwwwwzuz?++-`..... .......`~+>xuxzwwwwwwrr>||^^^^^^?zzzz~<br></br>
`zzzwwzw>>^+>>?zwwwwwwwwxuu}?>+-```-~~~~-``~^+vxuxzzwwwwrrrw>|||^||||vrrrw~<br></br>
-uyyuxzz?>+|>>>rwwwwwwwwwzxxw>||~-~+^`|+|~~-|+vzuzzzwzwwwwzxv^^^^~||>wrrrw~<br></br>
-uyyyyyy?>>>>>+vwrwwwwwwwzwv?>^|~~^-...-|||+>??rzwwzwwwwwzxyv^+^>~|^?xzzww~<br></br>
`xuuuuuuv>>??>>?wwrrwwwwzzxxuzrw?-||~|||-^?zxxzwwwwwwwwwwzxz+^^+>-^^vwwwww~<br></br>
`wzzzzzxv>>?v>>+rwwwwwwzzzzzzxuy}v??^~??vv}uzwwwwwwwwwwwwwzv^^^?+|^+wxxzzz~<br></br>
`wzzzzzxv+>?w>>+}zwwwwwzzwwwzzuw-```~|`.``+uzwwwwwwwwwwzzzz>^^^r+^^?uuuuux|<br></br>
`wzxxxxxw+>>x?>>?xzwwwwzwwwwzxu}    +?`   ~uzwwwwwwwwwwxuuz++^?z+^^}zzzzxx|<br></br>
`xuuuuuyx>>>x}>>>zxzwwwwwwwwzxu?           wxwwwwwwwwwwzxuv^^+zr^^>zxxxxxx|<br></br>
`zxuuuuux>>>wx>>>}zwwzwwwwwzzxu~           vuzwwwwzzwwwzzz+||+x?^|?uxxxxxx|<br></br>
`wzzxxxxx>>+}u?>>?wwzxzwwwwzxuw            ?yxxzzwzzzwwzxr|^^vx^^~?uxxxxxx|<br></br>
`wzxxuuux???vuv??vrwzxzwwwzxuy>            >yuxxzzzzzwwwx?^^>xr^^|zuuuuuuu|<br></br>
`zzzxxxuxv?>vxw?>?rwwzzwwwzuuu`            -uuuxzzzzzwwwr+^|?u?^|+uxxxxuuu|<br></br>
`vv}rwwwz?..?zwv-+rwwwwzzwzuuv      |?      }yuzzzzzzzww}?>^}u>^|?uxxxxxxu|<br></br>
`vvv}r}v?|``>zwr??}wwwwzzwzuu`     .zy+     |uuzzwzzxxzwrv?>zz+^|vxxxxxxxu|<br></br>
`vvv}v~-`--`+ww}v}rrwwwzzzxuv      |ux}      rxzwwwwzzwr}v??zr|||vzzzzzzzz~<br></br>


            </div>,

            <div>

`r|     `?}rrv???????vvvvvvv}?~--~?r}r?||~|?rrr}vvvvvv}vvv}rrrrrrrrrrrrrrr~<br></br>
-ux?-    `^}}vvvvv}rwwxxwwwzx>~...~vzv-...|?uuuxzzzzzzzzwwxuyyyuuuuyyuuyuy^<br></br>
-uxuxr?-. `+}rr}}}rwwzxxwrwxuv?++`.~?-.~^^^ruuuxzzwwzzzzwrwzzzzxuuuuuuzwzu^<br></br>
`xxxzzuuw>>v}wzwwwwwwzuuxzxuux?v?^-~`-?v?v?zuuuuuxxzzzzzzwwwwrwzuuuuuzv?vv~<br></br>
`zxxzxuyu}v}rwzwwwwzzxzzwrr}??^~`..|?>~`-+??wzzzxxxxuuxxzzwwwwrrxuzr}vvv?v-<br></br>
`zxxxxxxwvv}wwwwwwzux?~-~-``....`^^^~|^|`...`~^+^+>vrxuxxzwwwwwwww}v?vvv?v-<br></br>
`v}}???+|^?vrwwrwzu}^. ....`~~>???|`..`+?+-`.......`+vwuxzwwwwr}vv}vvvvv?v-<br></br>
`v?^~~|~^+?}rwwwzu}~|~....`+v?>?v>^~...^|?v?+`....-``|?}uzwwww}}v>vvvvvvvv-<br></br>
`v?`..``~+?vrwwwxu?|?>^~^>??^`|++||?~->^`+>^??^-`~>vv??>uxwwrrrv??>?vvvvvv-<br></br>
`v?~^>~+>>?vrwwzuu?~`-``|^-`..`|~^v--~-+?~^|||>||>>?v>^^xxwrrr}v???}zuuuuu|<br></br>
`v?+?????vv}rwwxuuw^-..`.~`...``.-v?+>?v?.`|.`-~--``-~+>uxwrr}v??vwzxyyyyy^<br></br>
`vv?????>+?vrwwxuuu?^~`......`~...?^>?|>|..~`...````-^^zuzwrr}v??vwzxyyyyy^<br></br>
`????>+^`|>?}wzxxxuuv^-`....~^`...~~...~....|-......`|vuzwwrr}v?^vzzxuyyuy^<br></br>
`???>??+``~>vwzxzzzxxwv>^^^+^`.....``--......^+|~-~|+vxzwwwwwv?+~+zzwzuyuy^<br></br>
`???}v?>--~>vrzxzwwwzzxuv^++^-....``-|-~~--`-^++|?}wxxwwwwwwr?^|`.?zwxuuuy^<br></br>
`v?}www}^||>?vrzzwwwwwwxx>++^^|-||||``~|^^+^^~|+>uuxzwwwwwr}?+-`.-}wwzuuuu^<br></br>
`v??v}w}|~-^>>vwwwwwwwwzuz?++-`..... .......`~+>xuxzwwwww}v?|-``-~vwrwxzzz~<br></br>
`rv???vv|^-~++?wwwwwwwwwxuu}?>+-```-~~~~-``~^+vxuxzzwwwww}?+|~~~||???}rrrw~<br></br>
-uzww}vv^^~`+>>}wwwwwwwwzxxxw>||~-~+^`|+|~~-|+vzxzzzwzwwwr??^|~~~>wr}wwrrw~<br></br>
-uzwwwwz>||~^>+?wwwwwwwwzzwv?>^|~~^-...-|||+>??rzwwzwzwwwwv?+|--~?}}wxzzww~<br></br>
`zw}}}}z?||++>>>wwwrwwwzzzxxuzrw?-||~|||-^?zxxzwwwwwwwwwz}>>|^-||?v?}wwwww~<br></br>
`zwvvvvr?~^^>+>+rzwwwwwzzzwwzxuy}v??^~??vv}uzwwwwwwwwwwww?+^^^~^^v}rzxxxzz~<br></br>
`zzr?vvrv|^|?+>+vzwwwwwzzzwwzzuw-```~|`.``+uzwwwwwwwwwwzr++|?^|^?rrwuuuuux|<br></br>
`zzwvvvwz++|v>>>?xzzwwwzwwwwzxu}    +?`   ~uxwwwwwwwwwwu}>++r^^+vvvwzxxxxx|<br></br>
`xuuzwwzu>+|vv+>+wxzwwwwwwwzzxu?           wuzwwwwwwwwwz?>^?v|+?}v}xxxxxxx|<br></br>
`xuuzrrwx?>|?r+>>vzwzzzwwwzzxxu~           vuxzzzwzzwww}++~v>~^?r}wxxxxxxx|<br></br>
`zxxzr}}z?>^>r>+>?wwzxzwwwzxxuw            ?yuxxzzzzzwwv>^>r^^^?r}wuxxxxxx|<br></br>
`xxxzr}rz???>w?+?vrwzxzwwwzuuy>            >yuxxzzzzzwrv?^vv|+>rrrxuuuuuuu|<br></br>
`zxxxr}}wv?>+}v>>?}wwzzzwwzuuu`            -uuuxzzzzzwrv?^w?|+?wwwxxxxuuuu|<br></br>
`rrrrv??v?..^vv?~+}wwwwzzwzxuv      |?      }uuxzzzzzzw}??w>^>vwrwxxxxxxxu|<br></br>
`v}}}v?>+~``^vv???}wwwwzzwzuu`     .zy+     |uuxzwzxxzww}}r++>vwwzxxxxxxxu|<br></br>
`v}}rv-..`--|}vv?v}rwwwzzwzuv      |ux}      rxzwwwwzwwr}}v+>+v}}wzzzzzzzz~<br></br>


            </div>,

            <div>

`r+    .~>}}?????????vvvvvvvr?~--~?r}r?||~|?rrr}vvvvvv}vvv}rrrrrrrrrrrrrrr~<br></br>
-uu?`  .`|?rvvvvvv}rwwxxwwwzx>~...~vzv-...|?uuuxzzzzzzzzwwxuyyyyyyyyyuuyuy^<br></br>
-uuuur>~``|?}rr}}}rwwzxxzwwxuv?++`.~?-.~^^^ruuuxzzwwzzzzwrwzzxuuuuuuuuwwxu^<br></br>
-uuuuuzz}??}rwwwwwwwwzuuxzxuux?v?^-~`-?v?v?zuuuuuuxzzzzzzwwwwwzxuuyyuzv?v}~<br></br>
-uuxzrrzz}v}rwzwwwwzzxzzwrr}??^~`..|?>~`-+??wzxzxxxxuxxzzzwwwwwzuuzw}vvv?v-<br></br>
-ux}}}}vv}}}rwwwrwzux?~-~-``....`^^^~|^|`...`~^+^+>vrxuxxzwwwwwzxwvvvvvv?v-<br></br>
`}v>?>+^+?v}rwwrwzu}^. ....`~~>???|`..`+?+-`.......`+vwuxzwwwwr}}vvvvvvv?v-<br></br>
`v?-^+^|>vv}rwwwzu}~|~....`+v?>?v>^~...^|?v?+`....-``|?}uzwwwwr}}vvvvvvvvv-<br></br>
`v?`|~~~>?v}rwwzxu?|?>^~^>??^`|++||?~->^`+>^??^-`~>vv??>uxwrrrr}}vvvvvvvvv-<br></br>
`v?~+>^+>>?vrwwxuu?~`-``|^-`..`|~^v--~-+?~^|||>||>>?v>^^xxrr}rr}}vrzuuuuux|<br></br>
`v?>???>?vv}rwwxuuw^-..`.~`...``.-v?+>?v?.`|.`-~--``-~+>uxr}rvv}}zuyyyyyyy^<br></br>
`vv>??>>>>vvrwwzuuu?^~`......`~...?^>?|>|..~`...````-^^zuzwrrvvvrxuyyyyyyy^<br></br>
`v???>+^|>?v}wwzzxuuv^-`....~^`...~~...~....|-......`|vuzwwrr}?>wuyyyyyyuy^<br></br>
`vv>+|||--|>vwwzzwzxuwv>^^^+^`.....``--......^+|~-~|+vxzzwrrwv||ruyyyuuuuy^<br></br>
`v}v?^^~``-^?}zzwwwzzzxuv^++^-....``-|-~~--`-^++|?}wxxzwwwww}+``^xuuuuuuuy^<br></br>
`}}rv+>?|-`-|>rzwwwwwwwxx>++^^|-||||``~|^^+^^~|+>uuxzwwwwwrv?`. |xuuuuuuuu|<br></br>
`rr}v>|+`..`~^vwwwwwwwwzuz?++-`..... .......`~+>xuxzwwwwrv?^-`..+zxuuxxzzz~<br></br>
`zzwv>--`-....+}wwwwwwwwxuu}?>+-```-~~~~-``~^+vxuxzzwwww}?~`-`--|}wwrrrrrw~<br></br>
-uyyz?~~`-`...-vwwwwwwwwzxxxw>||~-~+^`|+|~~-|+vzxzzzwzwwr?-`-```}uuuxzwwrw~<br></br>
-uyyx}++~.`.`.-?wrwwwwwzzxwv?>^|~~^-...-|||+>??rzwwzwzzww>---.`-rzxxxxzzww~<br></br>
                  `xxxzv+^~.-`-``+wwrwwwwzxzzxuzrw?-||~|||-^?zxxxzwwwwwwwwv~-~|.`|rwwwwwwwww~<br></br>
`zzzw}+~-.|````|}zwwwwwzzzwwzxuy}v??^~??vv}uzwwwwwwwwwww^-~^---?wzxxxxxxzz~<br></br>
`zzzzz?~~.||~``-vzwwwwwzzzwwzzuw-```~|`.``+uzwwwwwwwwwz}-~~v-~-}zuuuuuuuux|<br></br>
`zxzzz}+|`|++``->xzzwwwzzzzzzxu}    +?`   ~uxzwwwwwwwwx?-~>w~-+zzzzzzxxxxx|<br></br>
-uuuuuxv>`~+?+``^wxwwwwwwwzzzxu?           wuxzzzwwwwww^--}v~-vzxxxxxxxxxx|<br></br>
-uuuuux}>`-^vv``~vzwzzzwwwzxxxu~           vyuxxzwzzww}~`-r^-`rxxxxxxxxxxx|<br></br>
`xuuxxxr?``^?}|.->wwzxxwwzxuuuw            ?yuuxzzzzzwv~`?w~--wxxxxxxxxxxx|<br></br>
`xuuuuxz?-->?z>.->}wzxxzwzxuuy>            >yuxxzzzzzwv|-wv~~>uuuuuuuuuuuu|<br></br>
-xxxxxxz?-`^>z?``+}wwzzzzwxuuu`            -uuxxzzzzzwv++z?--vxxxxxxxxuuuu|<br></br>
`wwwwrrr?-  ^z?~`^}wwwwzzwzxuv      |?      }uxxzzzzzzrvvw+--}xxxxxxxxxxxu|<br></br>
`rrrrrvv>`  |w}?^>vrwwwzzwzuu`     .zy+     |uuxzwzxxzwrrr+-~wxxxxxxxxxxxu|<br></br>
`rrrr}|``. .|rrv?v}rwwwwwwzuv      |ux}      rxzwwwwzwr}rv^``}wzwwwzzzzzzz~<br></br>


            </div>,

            <div>

`r+     `?}r}v???????vv}vvv}r?-```>r}r?|~`|?rrr}vvvvv}}vvv}rrrrrrrrrrrrrrr~<br></br>
-uu?`   .^?rrvvvvv}rrwxxwwwxx>|...-vzv-...~?uuuxzzzzzzzzwwxuyyyyyyyuuuuyuy^<br></br>
-uuuur>-..^v}rrrrrrwwzxxzwwxuv???`.->`.|>>+wuuuxzzzwzzzzwrwzxxuuuuuuuuwzxu^<br></br>
-uuuuuuur?>vrwzwwwwwwzuuxxxuuuvv?^--`|?v?vvxuuuuuuxzzzzzzwwwwwzxuuyyuwv?v}~<br></br>
-uuuuxxuuwv}rzzwwwwzxuzwwr}v?>~-..`+?>-``^>?rwzzzzxxuuxzzzwwwwwzuuzw}vvv?v-<br></br>
-uuxxxzwr}v}rwwwwwzux?----``...`~+^|`~+^-...`-~||^>?rxuxxzwwwwwzxwvvvvvv?v-<br></br>
`}rv?>++^>v}rwwrwzu}|......`||??v?-`...^>>~`........+vwuxzwwwwr}}vvvvvvv?v-<br></br>
`v?|~~~~>?v}rwwwzur|^|....->v?+?v>~~. `^|vv?>-....--`^?ruzwwwwr}}vvvvvvvvv-<br></br>
`v?``.-~>?v}rwwzxu?~>++|>???|`~++||?~-+^`+>|>?+|-^>vvv+?uxwrrrr}}vvvvvvvvv-<br></br>
`vv|^>|^>??vrwwxuu?|````~^-`...|-^v--~`>?~^|||>||++??^^^uxwrrrr}}}rzuuuuux|<br></br>
`vv>??>>??v}rwwxuuz+|`..`~`...``.`v?>>?v?.`|..----```~+?uxr}r}}}}xuyyyyyyy^<br></br>
`vv???>>?>vvrwwzxuu?^|`......`~...?^>?|>~..~`...````~^^xuzwrr}v}zuuyyyyyyy^<br></br>
`vvv??>++??v}wwzzzxuv+~``..`~^`...~~...-....^-......`|}uxwwrw}v}xuyyyyyyuy^<br></br>
`vvv??>^~~^?vrwzwwzxuzv?+^+>^......`-~`......^+^|~~^>}xzzwrrw?>}xyyyyuuuuy^<br></br>
`}}}vv?|`.-+>vwzwwwzzzxuv^+++~....``~|-~~~-`-^++|?rzuxwwzwwwv+~?ruyuuuuuuy^<br></br>
`}rwzwz}~```-+vwwwwwwwwxu>++^^|~||||``~|^^^^|~^^?uuxzwwwwwr?+`.-vuuuuuuuuu|<br></br>
`rrr}}}v`..-`~?}rwwwwwwzuz?++-`..... .......`|+>uuxzwwwwrv?|`..^vxuuuxzzzz~<br></br>
`zzzrv??~`....-?}wwwwwwzxuur?>+~```-~~~~-``~^>vuuxzzwwww}>~```-|?wwwrrrrrw~<br></br>
-uyyuwvv^`.....^v}wwwwwzzxxzw>||~-~+^`|+|~~~^+vzxzzzwzww}>````-?zyuuxzwwrw~<br></br>
-uuuuxww?......~?vrwwwwzzxwv?>+|~~^`...-|~|+??vwxwwzwzzw}^``..~?zxxxxxzzww~<br></br>
`xxxxzr}>......`+?rrwwwzxzzxuxww?~^~~~~^~+?xxxxzwwwwwwwr>--```|?wwwwwwwwww~<br></br>
`zzzzwvv?`...`..^?rwwwwzzzwwzxuu}v??^~??vv}uzwwwwwwwwww?--|---+vzxxxxxxxzz~<br></br>
`zzzzzrv?--..``.~>}wwwwzzzzzzzuw`...~|`...>uzwwwzwwwwwr+`~^|-~>wxuuuuuuuux|<br></br>
`zxxxxz}v^|```~``?}wwwwzzzzzzxu}    ^?.   |uxzzzzzwwwwr^`^?+-+vxzzzzzxxxxx|<br></br>
-uuuuyuzw+^`~|~-`?rwwwwwwzzzzxu?          .zuxxxzzwwwwv-`>?|->rxxxxxxxxxzz|<br></br>
-uuuuuuzw+^~|^~-`+vrwzzwwzxxxxu~           ruuuxzzzwww?`->+-->zxxxxxxxxxzz|<br></br>
`xuxxxuzw>|~-+^-~|vrwzxzwzxuuuw            vyuuxzzzzwr?-+?+`~?xxxxxxxxxxxx|<br></br>
`xuuuuuxw>^^~>>^+^vrwzzzwzxuuy>            ?yuxxzzzzw}?^?v^`>}uuuuuuuuuuuu|<br></br>
-xxxxxxxw?^|~>^^~^vrwwzzzzxuuu`            ~yuxxzzzzzrv?vv|`>wuxxxxxxxuuuu|<br></br>
`wwwrrww}?. -+|>-^vrrwwzzwzuuv      ^v      ruxxzzzzzwr}}v~-?zxxxxxxxxxxxu|<br></br>
`rrrrr}v?-..->>v>?v}rrwzwwzuu`     .zy+     ^uuxzzzzzwrrrv~~vxxxxxxxxxxxxu|<br></br>
`rrrr}|```..-+?v?v}}rrwwwwzuv      ^ux}      rxzwwwzwrrrr?-`?wzwwwwzzzzzzz|<br></br>


            </div>,

            <div>

`r+     `?rrr}???????vv}vvv}r?-...>rrr?~``~>rr}}vvvvv}vvvv}rrrrrrrrrrrrrrr~<br></br>
-uu?`    .+rw}}vvv}rrwxxzwwxx?|...`?w?`...~?uuuxzzzzzzzzwwxuyyyyyyyuuuuyuy^<br></br>
-uuuur?-  `vrrwrrrrwwzxxzwzxur???-.~>``^?>>zuuuxxxzwzzzzwrwzxxuuuuuuuuwzxu^<br></br>
-uuuuuyuw?>}rzzwwwwwwzuuuxuuuuvv?^`--^v?>?vxuuuuuuxzzzzzzwwwwwzxuyyyuw?vv}~<br></br>
-uuuuuuyyzv}rzzwwwwzxuwrr}v?>^-`..`>?>-.`|+?vrwwwzzxuxxzxzwwwwwzuuzw}vvv?v-<br></br>
-uuuyuxxzvv}rwwwwwxux?-`-``....`|>+|`-+>~`...`-~~~^?}xuxxzwwwwwzxwvvvvvv?v-<br></br>
`}rwv?+^|^?vrwwwwxuv|`.....`^^??v?-`...^>>^-........+vwuxzwwwwr}}vvvvvvv?v-<br></br>
`vv+|~|-^>?vrwwzxuw++^`...~?v?^?v>-|..`^|vv?>~....~~-^?wuzwrrwr}vvvvvvvvvv-<br></br>
`vv-..``|>?vrwwxuu?~+^++>??>~.~^+|^?~-+|`+>|>?>^~+>vvv+vuxwrrwr}vvvvvvvvvv-<br></br>
`vv^|>~^>>vvrwwxuuv|`|``-|--...~-|v-`-`>?-^|||+~|++>?^^>uxwrrrr}v}rzxxxuux~<br></br>
`vv??????>v}rwzxuux+^````~`...``.`v?>>?v?.`|..-~~~`--|^}uxrrrrr}rxuuuuuuyy^<br></br>
`vv?????>+vvrwwzxxuv^|`.`....`~...?|+?|>~..~`...````~^+uuzrrrrrrwzxxxuxxxy^<br></br>
`vv}v?>>^??v}rwwwzxu}+|-`..`|^`...~~...-....^~`....`-^wuxzrrw}rwzzxzuuxwzy^<br></br>
`v}}}}v?->??v}wwwwzxuzv?+++>^......`-~` .....^+^|~|^?wxzzzwww?rzzxuxuuxzzu^<br></br>
`}}}wwrv-|+>?vrwwwwzzzxuv^+++~`...``~|-~~~-`-^++^vwzuxwzzzwrv^?zxxxuuuxzzu^<br></br>
`}rzuyyx+~-`-+vrwwwwwwwxu?++^|^~||||``-||^^^|~^^vuuxzwwzww}?^.`rxxxzxxxxxu|<br></br>
`rrwwzuu>^`.`-|v}rwwwwwzux?++~`..... .......`|+?uuxzwwwwrv>-..~}zzzzwzzwzw~<br></br>
`zzzzwww>|` ..`+v}rwwwwzxuuw?>+|```-~~~~-``|^>}uuxzzwwwrv>-````?rzwwvvrr}r~<br></br>
-uyyyuxx?^~.  ..^?}rwwwzxxxwr>|~~-~+^`|+|-~~^>vzxzzzwzwwv|````+wzuuuwrwzvv~<br></br>
-uyuyyyy}+~.  ..`|vrwwwzzxzv?>+^~~^`...-|~^>??vwxwwzzzzr?-`..`?rrzxxzzzz}v~<br></br>
`xxxxuuur^^`   ..`>}wwwzzzzxuxzz?~^~--~+|+?xxxxxzwwwwww?~`~.`-v}rwwzzrzzrr~<br></br>
`zzzzzzxr+^`  .. .~vwwwzzzzwzxuu}v??|~???vruzwwzzwwwwwv~-~|.-^wwwwxxzxxr}z|<br></br>
`zzzzzzxw++~. .`..-?wwwzzzzzzzuz`...~^` ..>uzwzzzzwwwz>`->^--vzzr}rrrwwrrw|<br></br>
`zxxxxxxx>>|` .~-.-?wwwzzzxzzxur    ^>.   ^yxzzxxzwwwz^`-r+-~wxxwvv}rrrv}w~<br></br>
-uuyyyyyy?>^~. ~|~|?rwwwzzxxzxu?          `uuxxxxzwww}|`+w~-^rrr}vv}}}vvvv-<br></br>
-uuuuuuuu?>++- -|^>vwzzwwzxuxxu-           wuuuxxzwwwv~.??`.>z}v}}}}}vvvvv-<br></br>
`xuxxxxuu?>+>- `^^?vwzxzwzxuuuw            vyuuxzzzwwv||z+``}zwr}}}}}}}}vv~<br></br>
-xuuuuuuu????| `>??vrwzzwzxuuy>            ?yuxxxzzzwv+?w~-^zzwrrrrrrrrrrr~<br></br>
-xxxxxxxxv?+>>..->vvrrwzwzxuuu`            |yuxxxzzzw}vr}|`>xzwrrrrrrrrrrr~<br></br>
`wwrrrrww}..|?..`^v}}rwwwwzuu?      >v      wuxxxzzzzr}rr+`vxxwr}}rrr}rrrr~<br></br>
`rrrrr}v?^``|v`.^?vr}rwwwwwux`     .xy+     +yuxzzzzzwrwr+-vxzrr}}}}}}}}}w|<br></br>
`rrrr}|```-`~?``>v}rrrwwwrzuv      >ux}     .wxzwwwzwrrr}+`>zwrrvvvvvvvvv}|<br></br>


            </div>,

            <div>

`r+     `?rrrrv??????vv}vvv}r?-...>rrr?~``~>rr}}vvvvv}vvvv}rrrrrrrrrrrrrrr~<br></br>
-uu?`    .+rzr}vvv}rrwxxzwwxx?|...`?w?`...~?uuuxzzzzzzzzwwxuyyyyyyyyyuuuuy^<br></br>
-uuuur?-  `?rrwrrrrwwzxxzwzxur???-.~>``^?>>zuuuxxxzwzzzzwrwzxxuuuuuuuxwzxy^<br></br>
-uuuuuyuw?+vrzzwwwwwzxuuuxuuuuvv?^`--^v?>?vxuuuuuuuxxzzzzwwwwwzxuyyyur?vvr~<br></br>
-uuuuuuyyzv}rzzwwwwzuuwrr}v?>^-`..`>?>-.`|+?vrwwwzzxuxxzzzwwwwwzuuzw}vvv?v-<br></br>
-uuuyyuxzvv}rwzwwwxux?-`-``....`|>+|`-+>~`...`-~~~^?}xuxxzwwwwwzxrvvvvvv?v-<br></br>
`}rwv?+^|^?vrwwwzxuv|`.....`^^??v?-`...^>>^-........+vwuxzwwwwr}}vvvvvvv?v-<br></br>
`vv+|~|-^+?vrwwzxuw++^`...~?v?^?v>-|..`^|vv?>~....~~-^?wuzwrrwr}vvvvvvvvvv-<br></br>
`vv-..``~>?vrwwxuu?~+^++>??>~.~^+|^?~-+|`+>|>?>^~+>vvv+vuxwrrwr}vvvvvvvvvv-<br></br>
`vv^|>~+>>?vrwwxuuv|`|``-|--...~-|v-`-`>?-^|||+~|++>?^^>uxwrrrr}}}wxuxxuux~<br></br>
`vv???????v}rwzxuux+^````~`...``.`v?>>?v?.`|..-~~~`--|^}uxwrwrr}rxuyyyyyyy^<br></br>
`vv?????>+vvrwwzxxuv^|`.`....`~...?|+?|>~..~`...````~^+uuxwrwrrwzxuuuuuuuy^<br></br>
`vv}v?>>|>?v}rwwwzxu}+|-`..`|^`...~~...-....^~`....`-^wuxzwww}rzxxuxuuuxxy^<br></br>
`v}}}}}v-|>?v}wwwwzxuzv?+++>^......`-~` .....^+^|~|^?wxzzzwwrvrrzuuuuuuxxy^<br></br>
`}}}www}--^??vrwwwwzzzxuv^+++~`...``~|-~~~-`-^++^vwzuxwzzzwrv?v}zuuuuuuxxy^<br></br>
`}rzuyyu>~--^>vrwwwwwwwxu?++^|^~||||``-||^^^|~^^vuuxzwwzzw}??~|rzxxxxxxuuu|<br></br>
`rrwwzxu>+``--|?}rwwwwwzux?++~`..... .......`|+?uuxzwwwwrv>|-.^}wzxxwzzwww~<br></br>
`zzzzwww>+|.`.`+?vrrwwwzxuuw?>+|```-~~~~-``|^>}uuxzzwwwrv?~`.`~vrzwwvvrr}r~<br></br>
-uyyyuuxv>+`.. .|>vrwwwzxxxwr>|~~-~+^`|+|-~~^>vzxzzzwzwwv>|-``vzwuuuwrwz}}~<br></br>
-uyyyyyy}++~.. .`->}wwwzzxzv?>+^~~^`...-|~^>??vwxwwwzzzr?~|``-rw?wxxzzzzr}~<br></br>
`xxxxuuur+>|`.  ..|vrwwzzzzxuxzz?~^~--~+|+?xxxxxzwwwwww?~`^-~|rrvrzzzrwzrw~<br></br>
`zzzzzzxr+>|-.   .`>}wzzzzzwzxuu}v??|~???vruzwwzzwwwwwv~-~^-~?zrrzxxxxxwrz|<br></br>
`zzzzzzxw+>^^. ..`.|vwzzzxxzzxuz`...~^` ..>uxwzzzzwwww+~-+~`-vrv}wwwwzzwwz|<br></br>
`zxxxxxxx>>+>- ..~|~vrwzzxxzzxur    ^>.   ^yxzzxxzwwwr|-~}~`|wr}}v}rwwwrwz~<br></br>
-uuuuyyyy?>+?>...+??}rwwzzxxzxu?          `uuxxxxzwwwv~`+v``^????>?wwwrrrr~<br></br>
-uuuuuuuu?>>v?`..^vvrwwwzzxuxxu-           wuuxxxzwwwv|`?+`.>v??>?}wwrrrrr~<br></br>
`xuxxxxxu?>>??-..~v}rwzwwzxuuuw            vyuxxxzzwrv^^r~``????>vwwwwwwrr~<br></br>
-xuuuuuuu???vv|..^v}rwwwzzxuuy>            ?yuxxxzzzwv+vv``^}vv??}zzzzzzzz|<br></br>
-xxxxxxxxv?>?v|`.-?}rrwwwwzuuu`            |yuxxxzzzw}vrv~`>r}v??}zwwwwzzz|<br></br>
`wwrrrrww}..^}+|.`?rrrwwwwzxuv      >v      wuxxxzzzwrrw}^`vrr???rzwwwwzzz|<br></br>
`rrrrr}v?^``^r>^-^vrrrwwwwwuu`     .xy+     +yuxxzzzzwrw}+-vrv???rwwwwwwwz|<br></br>
`rrrr}|`````|r?>^?vrrrwwwrwuv      >ux}     .wxzzwwwwrrrv>`>}v???}}}}}}}}r~<br></br>


            </div>,

            <div>

`w+     `?rrrrv??????vv}}}v}r?-...+}rr>-`.~>rr}}v}vvv}vvvvrrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^}zwrvvv}rrwxxzzzxu?+`..`?w?`...~?uuuxxxzzzzzwwzuuyyyyyyyuuuyuuy^<br></br>
-uuuur?-  .^}wwrrrrwwzuxzzzxuw??v|.-+``+??>zuuuxxxzzzzzzwrzzzxuuuuuuuxzxuy^<br></br>
-uuuuuyuw?+v}zzwwwwzzxuuuuuuuu}?>^``-+v?+?vxuuuuuuuxxzzzzwwwwwzxuyyyu}?vvr|<br></br>
-uuuuuuyyzvrrzzwwwzxuuw}}vv?>|-`..`>?>-.`~^>v}rwwwzxuuxzzzwwwwwzuuzw}vvv?v-<br></br>
-uuyyyuxzvv}rzzwwzxux?-````...``|?^~``+>|`...`-~~~^?}xuxzzzwwwwzxrv?vvvv?v-<br></br>
`}rwv?>^|^?vrwzzzxur^`.....`++??v?-`...^>?^~.......`>vruxzwwwwr}}vvvvvvv?v-<br></br>
`vv+|~|-^+?vrwzxxux+>+-.``~>v?^>v>-^..`^|vv>>|...`||~+?ruzwrrwr}vvvvvvvvvv-<br></br>
`vv-..``|+?vrwzxuu}|++^^>??>~.~^+||>|~+^`+>|+?>+^??v}v|vuxwrrwr}vvvvvvvvvv-<br></br>
`vv^|>~+?+?vrwzxuur^~~-``|-~`..~-|v-`-`??-^|||^-~^^>?|^?uxwwwrr}v}wxuuuuxz~<br></br>
`vv??????vv}rwwzxuu>+-.``~`....`.`v?>>?v>.`~..-~~-```^^wuxwwwrr}wuyyyyyyyy^<br></br>
`vv?????>^?vrwwwzxur+^```....`|...?|+>~>-..|-....``-~^>uuxwwwrrwxuyyyyyyyy^<br></br>
`vv}v?>?-+??vrrwwzxur>|-````|^`...-~..`-....^~`....`~+zuxxwwwwwzuuyyyyuuuy^<br></br>
`v}}}}}v-`~+v}rwwwzxux}?+++>^......`-~- .....^>^||^+vzxzzzzwr}zxuyyuuuuuuy^<br></br>
`}}}www}^^^>?v}wwwwzzzxu}^+++|`..``-~|~~~~-`~^++^rzxuxwwzzwr?vzxuyuuuuuuuy^<br></br>
`}rwuyyu>+^^+?v}wwwwwwzuu?++^|^~||||``-~|^^^||+^vuuxzwwwww}?+-?xuyuuuuuuux|<br></br>
`rrwwzxu?+^+|~|>vrwrwwwxux?++~`..... .......-^+vuuxzzzwwrv?^``?rwxxxxzzzww~<br></br>
`xxxxwww?>+^+`.|?v}}rwwzxuuzv>+|-``~|~|~---^^?wuuxzzzzwr}?~```?wwwwwrrrrrw~<br></br>
-uyyyyuuv>>|+- .-+?}rwwzxxxwr>|~~-~+|`~^~--~^?vzuzzwwzwrv?>|`~zuuuuuxzwwrw~<br></br>
-uyuyyyy}+>>>~. .-^v}wwzzxzv??>+|-^`.`.~|~^>??vzxzwwwzwr?+?+`>xzrwzzzzzwww~<br></br>
`xxxxxuur+>??|.  .`+vwwzzzzxuuxx?|^|--|+^>vuxxxxzwwwwwrv?+v^`?zw}rwwwwwwwz~<br></br>
`zzzzzzxr+>>}^.  ..`?rzzzxzzzxuu}v??^|????ruzwwzzwwwrrv?>+>|-}wwzxxxuuuxzz|<br></br>
`zzzzzzxw+>>w>-  .~.|vwzzxxzzxuz`.  ~+`   >uxzzzzzwwr}||~+``^zwrwxxxxxxuxx|<br></br>
`xxxxxxux>>>zv^. .?^^?rwzxxzzxur    ~+.   +uxzzxxzwrwv`.->..>rzrwwzxxxxxxx|<br></br>
-uuuuyyyy?>+wx>` .v}vvrwzxxxxxu?          `uuxxxzzwrr?`.~-..-~}wwrwxxxxxxx|<br></br>
-xuuuuuuu?>>vu?|..?rrrwwzzxxxxu~          .zuxxxzzwwr>``~..`|?rrrwzxxxxxxu|<br></br>
`xuxxxxuu?>+?yv~..>rrwwwzzxxuuw            ruxxxxzww}>-~|..`^rwwwzuxxxuuxu|<br></br>
                  -uuuuuuuu????ur^`.+rwrrwwzzxuy?            ?yuxxxxzwr?+?|..-+wwwwxuuuuuuuu|<br></br>
`xxzzzzzxv?>?zw?`.~}wrrrwwzxuu`            |uuxxxxzzwvv}?-.|>wwwwxuxxxxxuu|<br></br>
`wrrrrrrw}..^zw}-`|}wrrrrwwxuv      >r.     wuxxxzzzwrrwv|`|?wwwwxuxxxxxxu|<br></br>
`rrrrr}v?^``^wwr>~>}wwwrrrwuu`     .xy>     +yuxxzzzwwwwv^-~vwrrwxxxxxxxxx|<br></br>
`}rrr}|```-`|ww}??vrwwwwrrwuv      +ux}     .wxzzwwwwrrrv>~+v}}v}wwwwwwwww~<br></br>


            </div>,

            <div>

`w+     `?rrrrv??????vv}}}v}r?~...^}rr>-`.~+rrr}}}vvv}vvvvrrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^}zwrvvv}rrwxxzzzxu?+`..`>w?`...~?uuuxxxxzzzzwwzuuyyyyyyyuuuuuuy^<br></br>
-uuuur?-  .^}wwrrrrwwxuxzzzxuz??v^.-+``>??>zuuuxxxxzzzzzwwzzzxuuuuuuuxzxuy^<br></br>
-uuuuuyuw>+v}zzwwwwzzxuuuuuuuu}?>^-`->v?>?vuuuuuuuuxxzzzzwwwwwzxuyyyu}?vvw|<br></br>
-uuuuuuyyzvrrzzwwwzxuuw}vvv?>|-`..`>?>-.`~|>v}rwwwzxuuxzzzzwwwwzuuzw}vvv?v-<br></br>
-uuyyyuxzvv}rzzzzzxuuv~```....``^?^~``+>|`...``~-~^?}xuxzzzwwwwzx}v?vvvv?v-<br></br>
`}rwv?>^|^?vrwzxxuur^-.....`^+??v?~`...^>?^~.......`>?ruxwwwwwr}}vvvvvvv?v-<br></br>
`vv+|~|-^>?vrwzxuuu>>>~.``~>v?^>v>-+..`^~vv>?|...`^|~+?}uzwrrwr}vvvvvvvvvv-<br></br>
`vv-..``|+?vrwzxuur^++^^+??>|`~^^||>|~+^-++^+?>+^??}}v|vuzwwrwr}vvvvvvvvvv-<br></br>
`vv^|>~+>+?vrwzxuuw+||-``^-~`..~-|v-`-`??-^~|||`~||>?|^vuxwwwrr}v}wxuuuuxz~<br></br>
`vv??????v}}rwwzxuu>+-.``~-......`v?>??v>.`~..`-|~``-^^zuxwwwrr}wuyyyyyyyy^<br></br>
`vv?????>^?vrwwwzxur+^```....`|...?|+>~>-..|-....``-~^?uuxzwwrrzxuyyyyyyyy^<br></br>
`vv}v?>?-+>?v}rwwzxuw>|-````|^`...-~..`~....^~`....`~>xuxxzzwwzxuuyyyyuuuy^<br></br>
`v}}}}}v-`-+?vrrwwzxux}?>++>^......`-~-......^>+||^>vzxzzzzwrrxuuyyuuuuuuy^<br></br>
`}}}www}^^^>?v}rwzzzzzxu}^+++|`..``-~|~|~~--|^++^rxxuxwwzzw}?vuyyyuuuuuuuy^<br></br>
`}rwxyyu>^^>+?v}wwwwwwzuu?++^|^||||~``-~|^^|||+^}uuxzzwwwrv?~`vyuuuuuuuuux|<br></br>
`rrwwwxu?+^>+^^?vrrrwwwxux?++~`.............-^+vuuxzzzwwrv?~.`vwzxxxxzzzww~<br></br>
`xxxxzww?>+^>+`~?v}}rwwzxuuzv>+|-``~|~|~--~^^?wuuxzzzzwr}?-.``vwwwwwrrrrrw~<br></br>
-uyyyyuuv>>|++-.-+?}rwwzxxxwr>|~~-~+|`~^~--~^?vzuzzwzzwrv?|-`|xuuyuuxzwwrw~<br></br>
-uyuyyyy}+>>>+~  `+v}wwzxxz}v?>+|-^`.``~|~^>??}zxzwwwzwrv?~`.>xzzzzzzzzwww~<br></br>
`xxxxxuur+>??+|. .`^vrwzzxxxuuxx?|^^--|>^>vuxxxxzwwrrrr}}?|`.?zwwwwwwwwwwz~<br></br>
`zzzzzzxr+>>v+^.  .`>rzzzxxzzxuu}???^|????ruxwwzzzwrrrvvv?-~-rzzxuuxuuuxzz|<br></br>
`zzzzzzxw+>>w>+`  `~|?wzzxxzzxux`   |>`   >uxzzzzzwr}v|^~^``>xzzxxxxxxxuxx|<br></br>
`xxxxxxux>>>zv>|  |?>?}wzxxzzxur    ~+.   +uxzzxxzwr}?`.-~`.?zxxxzxxxxxxxx|<br></br>
-uuuuyyyy?>+wx>+. ^r}vrwzxxxxxu?          -uuxzxzwwr}+`.`...??zxxxxxxxxxxx|<br></br>
-xuuuuuuu?>>vu?>~.|}wrwwzzxxxxu~          .xuxxxzzwwv^..`..`?rxxxxxxxxxxxu|<br></br>
`xuxuuuuu?>+?uv+~.~}wwwwzzzxuuw            wuxxxxzzwv+```..^vxuuxxxxxxxuxu|<br></br>
-uuuuuuuu????ur?>.-vwrrrwwzxuy?            vyuxxxxzw}?^+-..?vxuuuuuuuuuuuu|<br></br>
`xxzzzzzxv?>?zwv^``?wrrrwwwxuu`            ~uuxxxxzzrvvv?``?}xxxxxxxxxxxuu^<br></br>
`wrrrrrrw}..^zwr~`|vwwwrrwwxuv      >w.     wuxxxzzzwrrrv~|v}xxxxxxxxxxxxu^<br></br>
`rrrrr}v?^``^www?^>vwwwwrrwuu`     .xy>     +yuxxzwwwwwwv||?wxxxxxxxxxxxxx|<br></br>
`}rrr}|```-`|ww}???}wwwwr}wuv      ^ux}     .zxzzwwrrrrrv>|?rwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`w+     `?rrrrv??????vv}}}v}r?~...^}rr>-`.~+rrr}}}vvv}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^rzwrvvv}rrwxxzzzxu?+`..`>w?`...~?uuuxxxxzzzzwwzxuyyyyyyyuuuuuuy^<br></br>
-uuuur?-  .^}wwrrrrwwxuxzzzxuz??v^.-+``>??>zuuuxxxxzzzzzwwzzzxuuuuuuuxzxuy^<br></br>
-uuuuuyuw>+v}zzwwwwzzxuuuuuuuu}?>^-`->v?>?vuuuuuuuuxxzzzzwwwwwzxuyyyxv?v}w|<br></br>
-uuuuuuyyzvrrzzzzwzxuuw}vvv?>|-`..`>?>-.`~|>v}rwwwzxuxxzzzzwwwwzuxzwvvv??v-<br></br>
-uuyyyuxzvv}rzzxzzxuuv~```....``^?^~``+>|`...``~-~^?}xuxzzzwwwwzz}v?vvvv?v-<br></br>
`}rwv?>^|^?vrwzxxuur^-.....`^+??v?~`...^>>^~.......`>?ruxwwwwwr}vvvvvvvv?v-<br></br>
`vv+|~|-^+?vrwzxuuu>>>~.``~>v?^>v>-+..`^~vv>?|...`^|~+?ruzwwrwr}vvvvvvvvvv-<br></br>
`vv-..``|+?vrwzxuur^++^^+??>|`~^^||>|~+^-++^+?>+^??}}v|vuxwwrwr}vvvvvvvvvv-<br></br>
`vv^|>~+>+?vrwzxuuw+||-``^-~`..--|v-`-`??-^~|||`~||>?|^vuxwwwrr}}rzuuuuuxz~<br></br>
`vv??????v}}rwwzxuu>+-.``~-......`v?>??v>.`~..`-|~``-^^zuxzwwrrrzuyyyyyyyy^<br></br>
`vv?????>^?vrrwwzxur+^```....`|...?|+>~>-..|-....``-|^?yuxzwwrrzxuyyyyyyyy^<br></br>
`vv}v?>?-+>?vrrrwzxuw>|-````|^`...-~..`~...`^~`....`~>xuxxzzwwzxuuyyyyuuuy^<br></br>
`v}}}}}v-`~+>vrwwzzxux}?>++>^......`-~-......^>+||^>vzxzwzwwrrxuuyyuuuuuuy^<br></br>
`}}}rww}^^^>>?}rwzwwzzxu}^+++|`..``-~|~|~~--|^++^rxxuzwwwww}?vuyyyuuuuuuuy^<br></br>
`}rwxuyu>^^>>>v}wwwwwwzuu?++^|^||||~``-~|^^|||+^}uuxzzwww}?>~`vyyuuuuuuuux|<br></br>
`rrwwwxu?+^>>++?vrrrrwwxux?++~`.............-^+vuuxzzzwwrv+`.`vwzxxxxzzzww~<br></br>
`xxxxzww?>+^>>+|>v}}rwwzxuuzv>+|-``~|||~--~^^?wuuxzzzzwrv>`.``vwwzwrrrrrrw~<br></br>
-uyyyyuuv>>|>>+-`>v}rwwzxxxwr>|~~-~+|`~^~--~^?vzuzwwzzwr}+```|xuyyuuxzwwrw~<br></br>
-uyuyyyy}+>>>>+~.`>v}wwzzxz}v?>+|-^````~|~^>??}zxzwwwwr}r>..`>xzzzzzzzwwww~<br></br>
`xxxxxuur+>??>>|. -+vrwzzxxxuuxx?|^^--|>^>vuxxxxzwwrrw}vz+..`?zwwwwwwwwwwz~<br></br>
`zzzzzzxr+>>v>>+. .->}wzxxxzzxuu}???^|????ruxwwzzzwrrrrvr+.--rzzxuuuuuuxzz|<br></br>
`zzzzzzxw+>>w>>+` .^+?rzxxxzzxux`   |>`   >uxzzzzzwr????~-`->xzzxxxxxxxxxx|<br></br>
`xxxxuuux>>>zv>>|..>v?vwzxxzzxur    ~+.   +uxzzxxzwr?+~^````}uxxxzxxxxxxxx|<br></br>
-uuuuyyyy?>+wx>>+`.?w}}wzxxxzxu?          -uuxzxzwwrv^`... `zxxxxxxxxxxxxx|<br></br>
`xuuuuuuu?>>vu?>+|-?wwrwzzxxxxu~          .xuxzxxzwwv^`... ~uxxxxxxxxxxxxu|<br></br>
`xuuuuuuu?>+?uv+>~`?wwrrwzzxuuw            ruxxxxzzwv+`....?uuuuxxxxxuuuxu|<br></br>
-xuuuuuuu????ur?v?->rwrrwwzxuy?            vyuxxxxzw}?^~`..ruuuuuuuuuuuuuu|<br></br>
`zzzzzzzzv?>?zwv++^>rwrrrwwxuu-            ~uuxxxxzzrvv?>--zxxxxxxxxxxxxuu^<br></br>
`rrrrrrrw}..^zwr~->?rwwwrrwxuv      >w.     wuxxxzzwwr}rv~^zxxxxxxxxxxxxxu^<br></br>
`rrrrr}v?^``^www?>?vrwwwr}wuu`     .xy>     +yuxxzwwwwwwv^+zxxxxxxxxxxxxxx|<br></br>
`}rrr}|`````|ww}?vvvrwwwr}wuv      ^ux}     .wxzzwrrrrrrv>|rwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`w+     `?rrrrv??????vv}}}v}r?-...|}rr>`..~+rrr}}}}}}}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^rzwr}vv}rrwxxzzzxu?+``.`>r?`.``~?uuuxxxxxxxzwwzuuuuuuyyyuuuuuuy^<br></br>
-uuuur?-  .^vwwwrrrrwxxxxxxxuz??v^.-^`->v??zuuxxxxxzzzzwrrwzzzxuuuuuuxzuuy^<br></br>
-uuuuuyuw>+v}zzzwwwwzxuuuuuuuu}?+|``~>v?>?vuuuuuuuuxxzzzwwwwwwzxuyyyxv?v}z|<br></br>
-uuuuuuyyzvrrwzzzwwxuuwvvv??+|`...`>?+~.`-~>v}rrrwzxuxxzzzwwwwwzuxzwvvv??v-<br></br>
-uuyyyuuxvv}rzzxxzzux?~.``....`-^?^-.`^>^`...``---|?}xuxzzzwwwwzz}vvvvvv?v-<br></br>
`}rwv?>+|^?vrwzxxxur^-.....`+>vvv?~`...|+>^|`......`>?rxzwwwwwr}vvvvvvvvvv-<br></br>
`vv+|~|-^+?vrwzxuuu>>>|.``|?v>|>v>-+..`^~vv>?|`..-^^~>?ruzwwrwr}vvvvvvvvvv-<br></br>
`vv-..``|+?vrwzxuur^^+^^^??+~`~^+||+||+^-++^+?>+^??}}?|}uzwwrrr}vvvvvvvvvv-<br></br>
`vv^|>~+?+?vrwwxxuw+||-``|~~`..--|v-`--??-^~|~~`~~|>+|^vuxwwwrr}}rzuuxuuxz~<br></br>
`vv??????v}}rwwzzxu?+~.``--....`.`v?>??v>.`~..`-|-``-^^zuxzwwrrrzuyyyyyyyy^<br></br>
`vv?????>^?vrrwwwxuw++-``....`|`..?|^>~>-..|-....``-|^?uuxzwwrrzxuyyyyyyyy^<br></br>
`vv}??>?-+>?vrrwwzxuw?^~-``-|^`...-~..`~...`+|``...`~>xxzzwzwwzxuuyyyyuuuy^<br></br>
`vvvvv}v-`-+>vrwwzzxuxrv>++>^......`-~-.....`^>+^^+>vxxwwwww}rxuuyyuuuuuuy^<br></br>
`}}}}wwr^^^>>?}wwzwwzzxur^+++|`..``-~|~||~~-|^++^wxuuxwwwww}??uyyyuuuuuuuy^<br></br>
`}rrzuyu>^^>>>v}wwwwwwzuu?++^|^||||~``-~|^^|||+|ruuxxzwwr}?>~`vyyuuuuuuuux|<br></br>
`rr}rrwx?+^>>>+?v}rrrwwzuuv++~`.............~+^vuxzzzzww}?^`.`vwzxxxxzzzww~<br></br>
`xxzzwrr?>+^>>+>>v}rrwwzxuuxv>+^~``~|||~--~^^?wuuxzzzzwrv>`.``vwwzwrrrrrrw~<br></br>
-uyyuuuxv>>|>>++`+v}rrwzxxxrr>|~~-~+|`~^~--~^?vzuzzwwzwrv^```|xuyyuuxzwwrw~<br></br>
-uuuuuuy}+>>>>>+`.+v}rwzxxx}v?>>|-^````~~~+>??}zxzwwwwr}}|..`>xzzzzzzzwwww~<br></br>
`zzzzxxu}+>??>>+` `+vrwzxxxxuuxxv^^^--^>+>}uuxxzzzwrrr}}w|..`vzwwwwwwwwwwz~<br></br>
`zzwwzzzr+>>v>>+- .->}wzxxxzzxuu}???+|????ruxzwwzzwrrr}}}^.--rzzxuuuuuuxzz|<br></br>
`zzzzzzzw+>>w>>++. ~>?}zxxxzzxux`   |>.   ?uxzzzzzw}???>`|`->xxxxxxxxxxxxx|<br></br>
`xxxxxuuu>>>zv>>>~ ~}?vrzxxzzxuw    -^.   >yxzzzxzw}?^|^````}uxxxzxxxxxxxx|<br></br>
-uuuuuuuy?>+wx>>+>.+r}vrzxxxxxu?          ~uuxzxxzwrv|`... `zxxxxxxxxxxxxx|<br></br>
-xxxxxuuu?>>vu?>+>^+rwrrwzxxxuu|          .xuxzxxzwwv|`... |uxxxxxxxxxxxxu|<br></br>
-xxxxxuuu?>+?uv+>+~+rwrrwzzxuuz.           wuxxxxxzwv^`....?uuuxxxxxxuuuuu|<br></br>
-xuxxuuuu????ur?vv++rwrrrwzxuy?            vyuxxxxzw}?+|`.`ruuuuuuuuuuuuuu|<br></br>
`zwwwzzzzv?>?zwv++?>}wwrrrwxuu`            |yuxxxzzwrvv?>--zxxxxxxxxxxxxxu|<br></br>
`rr}}rrrw}..^zwr~-??rwwwrrwxuv      ?w.     wuxxxzwwwrrrv~^zxxxxxxxxxxxxxu|<br></br>
`}r}}r}v?^``^www?>?vrwwwr}rxu`     .zy>     >yuxxzwwwwwwv^+zxxxxxxxxxxxxxx|<br></br>
`}r}}}|``-``|ww}?vvvrwwwr}wuv      ^uxr     .zxzwwrrrrrrv>|rwwwwwwwwwwwwww~<br></br>


            </div>,

            <div>

`w+     `?rrrr}??????v}}}}v}r?-...~vr}+...~^rrr}}}}}}}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^rzwr}vv}rwzxxzzzxu?>~-..>}?`.-`~?uuuxxxxxxxzwwzuuuuuuyyyuuuuuuy^<br></br>
-uuuur?-  .^vwwwrrrwzxxxxxxxuzvv}^``~`|?v??uuuxzzxxzzzzwrwzzzzxuuuuuuzxuuy^<br></br>
-uuuuyyuw>+v}wzzwwwwxxuuuuuxxz}>^~``^??+>?vxuuuuuuuxxzzzwwwwwwzxuyyyzvvvrz|<br></br>
-uuuuuuyyzvrrwzxzwwzuuwv???>|-`..`->?+~`.`-^?vv}}rwzuxxzwwwwwwwxuxzrvvv??v-<br></br>
-uuuyyyuxvv}rwzxxzzux?-......`~|>?|`.`^>+-....````~?}xuxzwwwwwwzzvvvvvvv?v-<br></br>
`}rwv?>+|^?v}wzxxxur^~`....`>??v}?~-...~^?>^`......`+?rxzwwwwwr}vvvvvvvvvv-<br></br>
`vv+|~|-^+?vrwzxxuu?>?^``~^??>|+v>~+..-^|vv>?+`..~++^??zxzwwrr}}vvvvvvvvvv-<br></br>
`vv-..`-|+?vrwzxxuz^^>|^|?>^~`-^^||+||+|~^+^+>>+>?v}}>|zuzwwrr}}vvvvvvvvvv-<br></br>
`vv^|>~>?>?vrwwzxux>^^|~`^||`..--|v~`--??-^~|~-`--~>^|^wuzwwwrr}}rzuuxuuzw~<br></br>
`vv????vv}}}rwwzzxuv^|`--~-....-.`v????v+.`~..--|``-|^?uxzwwwrrrzuyyyyyyyy^<br></br>
`vv????v?>vvrrwwwzxx>+~`.....`^`..?|^>~>-..|-.....`~||ruzwwwwwwzuuyyyyyyyy^<br></br>
`vv}??>>-+??vrwwzzzux?+|--`-^^`...-|..`~...`+|``..``|?uzwwwwwwzxuuyyyyuuuy^<br></br>
`vvvvvvv~-|>?vwwzwwxuuwv>+>>^......`-|-.....`+>+^+>?ruzwwrrw}rxuuyyuuuuuuy^<br></br>
`}}v}wwr^^+>>?}rzwwwwzxuw++++|`..``-~|~|||~-^++^+zuuuxzwww}v>?uyyyuuuuuuuy^<br></br>
`}}}wuux>^^++>vvrwwwrwwxuv++^^^||||~``-~||||||+^zuxxxxzwrv>^-`vyuuuuuuuuxz|<br></br>
`rr}}}vw>+^^^^>?v}rrrrwzuu}>+^-`...........`|++ruxzzzzzr}?|`.`vwzxxxzzwwrw~<br></br>
`zzzz}?v>+|~^^+?+?vrrrwzxuuu}>+^~`-||||~--|^^vzuuxzwzzwrv>`.``vwzzwrrrrrrw~<br></br>
-uyuxwww?^^-^+^?-~v}rrwzxxxrv>^~--~^~.-^~---^?vxuxzwwwwr?~```|xuyyuuxzwwrw~<br></br>
-uuuxxzxv^^^+++?^.~?}rwzxxxrv??>^-^````~~~+??v}zuxzwwr}r?`..`>xzzzzzzzwwww~<br></br>
`zzwwwrwv^^+>>>>+ .+?}rzxxxxxuuuv>++~~+>>?ruuxzzxxwww}vz>`-.-vzwwwwwwwwwwz~<br></br>
`wwwr}}}v^^+?+>++. `+vrzxuxzzzxu}??>+|>>??ruxzwwzzwrrrvw>^`-~rzzxuuuuuuxzz|<br></br>
`zzwwwrw}^++}>>+?- `>>vwxuxzzzxx`   ^?.   ?uxzwzzzwv???|`?`->xxxxxxxxxxxxx|<br></br>
`zzzzzwzw++^wv>>?? `v??}zxxzxxuz.   `~.   ?uxzzzxzwv>|+``|`-}uxxxxxxxxxxxx|<br></br>
`zxxzzzzz>++}z>>+v-~rrv}wxxxxuuv          |yuxzxxzwr?`... .|xxxxxxxxxxxxxx|<br></br>
`xzzzwrrz>++vu?>+?>|}wr}wzxxuuy^          `uuxzxxxzw?-.....|uxxxxxxxxxxxxu|<br></br>
-xxxxzwwx?+^?uv+>>+~}wrrrzzxuuz.          .xuxxxxxzwv^`..``?uuuuxxxxuuuuuu|<br></br>
-xxxxzwwx?+>>xr?vv>|vwrrrwwxuy?            }uuxxxzzw}?++-``ruuxxuuuuuuuuuu|<br></br>
`zwwwr}}rv+^+wwv++?>vwwrrrwzuu`      .     +yuxxxzwwr}vv>-~zxxxxxxxxxxxxxu|<br></br>
`rr}}}vvvv. ~}wr~-??}wwwrrwzuv      ?z.    .zuxxzzwwwwwr?~^zxxxxxxxxxxxxxu|<br></br>
`}}}v}v??|`.~vrw?>??}wwwr}rxu-     .zy>     ?yxxzwwwwwwwv|+zzxxzzzzzxzzzzx|<br></br>
`}r}}v|`````-v}}?vvv}rwww}ruv      ^uxr     `zxzwrrrrwwwv+~rwwwwwwwwwrrwrw~<br></br>


            </div>,

            <div>

`w+     `?rrrr}??????v}}}}v}r?-...~vr}+...~^rrr}}}}}}}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^rzzw}vv}rwzxxzzzxu?>~-..>}?`.-`~?uuuxxxxxxxzwwzuuuuuuyyyuuuuuuy^<br></br>
-uuuur?-  .|vwwwrrrwzxxxxxxxuzvv}^``~`|?v??uuuxzzxxzzzzwrwzzzzxuuuuuuzxuuy^<br></br>
-uuuuyyuw>+v}wzzwwwwxxuuuuuxxz}>^~``^??+>?vxuuuuuuuxxzzzwwwwwwzxuyyyzvvvrz|<br></br>
-uuuuuuyyzvrrwzxzwwzuuwv???>|-`..`~>?+~`.`-^?vv}}rwzuxxzwwwwwwwxuxzrvvv??v-<br></br>
-uuuyyyuxvv}rwzxxzzxx?-......`~|>?|`.`^>+-....````~?}xuxzwwwwwwzzvvvvvvv?v-<br></br>
`}rwv?>+|^?v}wzxxxxr^~`....`>??vv?~-...~^?>^`......`+?rxzwwwwwr}vvvvvvvvvv-<br></br>
`vv+|~|-^+?v}wzxxuu?>?^``~^??>|+v>~+..-^|vv>?+`..~++^??zxzwwrr}}vvvvvvvvvv-<br></br>
`vv-..`-^+?vrwwxxuz^^>|^|?>^~`-^^||+||+|~^+^+>>+>?v}}>|zuzwwrr}}vvvvvvvvvv-<br></br>
`vv^|>~????vrwwzxux>^^|~`^||`..--|v~`--??-^~|~-`--~>^|^wuzwwwr}}}rzuuxuuzw~<br></br>
`vv??vvvv}}}rwwwzxuv^|`--~-....-.`v????v+.`~..--|``-|^?uxzwwwrrrzuyyyyyyyy^<br></br>
`vv???vv?>vv}rwwwzxx>+~`.....`^`..>~^>~>-..|-.....`~||ruzwwwwwwzuuyyyyyyyy^<br></br>
`vv}v?>>->??vrwwzwzux?+|--`-^^`...-|..`~...`+|``..``|?uzwwrwwwzxuuyyyyuuuy^<br></br>
`vv}}vvv~~^>?vwwzzwxxuwv>+>>^......`-|-.....`+>+^+>?ruxwwrrr}}xuuyyuuuuuuy^<br></br>
`}}}rzzr^^^>??}rzwwwwzxuw++++|`..``-~|~|||~-^++^+zuuuxzwww}v>?uyyyuuuuuuuy^<br></br>
`}rrzuux>^^+^+vvrwrrrwwxuv++^^^||||~``-~||||||+^zuxxxxzwrv>^-`vyuuuuuuuuxz~<br></br>
`rrww}vr>>||~|+?v}rrrrwzxu}>+^-`...........`|++ruxzzzzzr}?|`.`vwzxxxzwwwrw~<br></br>
`zxxx}v?+^--|||?+?vrrrwzxuuu}>+^~`-||||~--|^^vzuuxzwzzwrv>`.``vwzzwrrrrrrw~<br></br>
-uyyxwwr>~~`||~>-~?}rrwzxxxrv>^~--~^~.-^~---^?vxuxzwwwwr?~```|xuyyuuzzwwrw~<br></br>
-uuuuzww?~~~^^|>^.~?}}rzxxxrv??>^-^````~~~+??v}zuxzwwr}r+`..`>xzzzzzzzwwww~<br></br>
`zxzzrv}?|~|+++>+..+?}rzxxxxxuuuv>++~~+>>?ruuxzzxxzww}}z|`-.-vzwwzwwwzwwwz~<br></br>
`zxzwvvv?|||>+>^+. `+vrzxuxzzzxu}??>+|>>??ruxzwwzzwrr}vw~^`-~rzzuuuuuuuxzz|<br></br>
`zxzwr}}}^++v>>+?- `>>vwxuxzzzxx`   ^?.   ?uxzwzxzwv???--?`->xxxxxxxxxxxxx|<br></br>
-xxxzw}r}^|~v?++?? `v??}zxxzxxuz.   `~.   ?uxzwzxxwv>^+.~^`-}uxxxxxxxxxxxx|<br></br>
-uuuxwrr}^||?r+++v-~rrv}wzxxxuuv          |yuxzxxxwr?-... .|zxxxxxxxxxxxxx|<br></br>
`xuuxrvvr+^|>w?++?>|}wr}wzxxxuy^          `uuxxxxxzw?-....`|uxxxxxxxxxxxxu|<br></br>
`xuuuwvvz?||>xv++>>~}wrrrzzxuuz.          .xuxxxxzzw?|`..``?uuuuuuxxuuuuuu|<br></br>
-xuuuz}}z?^^+z}>?v>|vwrrrwwxuy?            }uuxxxzwwv?++-``ruuxxxxuuuuuuuu|<br></br>
`zzwwwvvvv|-|}r?^+?>vwwrrrwzuu`      .     +yuxxxzwwr}vv>-~zxxxxxxxxxxxxxu|<br></br>
`rrrrrv??v. -vr}~-??}wwwrrwzuv      ?z.    .zuxxzzwwwwwr?~^zxxxxxxxxxxxxxu|<br></br>
`}rrrrv>>~..`?}r?>??}wwwr}rxu-     .zy>     ?yxxzwwwwwwwv|+zzxzzzzzzxzzzzz|<br></br>
`}rrr}|`..```?v}?vvv}rwww}ruv      ^uxr     `zxzwr}rrwwwv+~rwwwwrrwwwrrwrr~<br></br>


            </div>,

            <div>

`w+     `?rrrr}??????v}}}}v}r?~...-vr}^...-^rrr}}}}}}}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^}xzw}}}}rrzxxzzzzuv>|~..^v>``~-|?uuxxxxxxxxzwwxuuuuuuyyyyuuuuuy^<br></br>
-uuuur?-  .|vwwwrrrrwzxxxxxxuzvv}+``-`+?v??uuxzzzxxzzzzwrwzzzzxuuuuuuxxuuy^<br></br>
-uuuuyyuw>+v}wzzwwwwzxuuuxxxxz}+|-`-+??^>?vxuuuuuuuxzzzzwwwwwwzxuyyywvv}wx|<br></br>
-uuuuuuyyzvrrwzzzwwzuur???>+~`...-~>>^|`.`-^>?vvv}rzxxxzwwwwwwwxuxzrvvv??v-<br></br>
-uuuyyyuxvv}rwzxxzwxx?-.....`-^+??~`..~+>|....````~?vzuxwwwwwwwzwvvvvvvv?v-<br></br>
`}rwv?>+|^>?}wwxxxxw^|`....`????v?~`...~^??>-.....``+?rxzwwwwwr}vvvvvvvvvv-<br></br>
`vv+|~|-^+?v}wwzxuu?+?+~-|+??+|+v+~+..~^|vv+?>`..~>>+??xxzwwrr}}vvvvvvvvvv-<br></br>
`vv-..``~^?vrwwzzux+^>||~>>^~`-|^^|^^|+|~^+^+>>^>?v}v+|zuzwwrr}}vvvvvvvvvv-<br></br>
`vv^|>~+>+>vrwwzzxu>^~~|-^||`..--|v~``~v?-^-|~-`~-->~|^zuzwwwr}}}wxuuxuuzw~<br></br>
`vv??????v}}rrwwzxuv^^```--....-.`v????v+.-~..`~|-`-|^?uxwwwwwrrxyyyyyyyyy^<br></br>
`vv????v>>vv}rwwwzxx?+|`.....-^`..>~|+->-..^-.....`~^|wxwwrrwwwzuuyyyyyyyy^<br></br>
`vv}v?>>->??}rwwzwzux?+|~-`-^|`...-~..`~...`+|-``.`-^vuzwrrrrrzxuyyyyyuuuy^<br></br>
`v}}}}}v--~+?}wwzwwzxuwv>+>>^......`-|-.....`+>+^+>?wuxwwrrrv}xuuyyuuuuuuy^<br></br>
`}}}rwzr^^^>?vrrwwwwwwxuz++++^-..``-~~~|||~~^++^+xuuuuzwwr}?+?uyyyuuuuuuuy^<br></br>
`}rrwuyu>^^+++?v}rrrrrwzuv++^^^|||~~``-~|^^||^++zuxxxxzwrv+|`.vyuuuuuuuuxz~<br></br>
`rrrrwwz?+|^+^^?vv}rrrrwxur?+^~`...........`|++wuxzzzzzrv?~..`vwzxxxzwwwrw~<br></br>
`zxuuxzw?^~~|~|>>?vrrrrwxuuur>+^~--|^~||--|^+}xuuxzwwzwrv^`.``vzzzwrrrrrrw|<br></br>
-uyyyyyy?||`````.-?v}}rwzxx}v>^~--~^~.-^~--~+?vzuxzwwww}>-```|xuyyuuxzwwrw~<br></br>
-uuuuuuy}^|-`````.~?}}rwzxxw}v??^-|````|~~>??v}zuxzwwrrv-`..`>xzzzzzzzwwww~<br></br>
`zxzzxxx}^^|~```~.`>?}rwxuxxxuuu}>+>~~+>>?wuuxzzxxwwrvwv.--.-vzwwzzzzzzwwz|<br></br>
`zzzzzzz}^^|-``.~..^>vrzxuxzzzxuv?>>^|>>?>ruuzwwzzwrrvrv.+`-~rzxuuuuuuuxzx|<br></br>
`zxxzxxzw^++>^^|>|.|?>vwxuxzzzxx.   ^?.   vuxzwzxzwv??>`-v`->xxxxxxxxxxxxx|<br></br>
-xxuuuuux+^||~--^+.^}??}zxxzxxuz.   .`    ?yuzzzxzwv++^.^>`-}uxxxxxxxxxxxx|<br></br>
-uuuuuuux>^|||-~~>`+rrv}wzxxxuuv          +yuxzxxzwr>`..  `|zxxxxxxxxxxxxx|<br></br>
`xuxxxxxu>^|^^~||+^^}wr}rzxxuuy^          ~uuxxxxzwr?`...``|uxxxxxxxxxxxxu|<br></br>
`xuuuuuuu?^^+>|^|||~vwr}rwzxuuz.          `uuuxxxzzr?|``.``?uuuuuuuuuuuuuu|<br></br>
                  -xuuuxxxu?^^^?~^|+||vwwrrwzxuy?            wuuxxxzwwv??+-``ruxxxxxuuuuuuuu|<br></br>
`zzwwwwwzv^~~?~|`~>+vwwrrrwzuu`     ..     >yuxxxzwwr}}v^`~zxxxxxxxxxxxxxu|<br></br>
`rrrrrrrwv. -?^~-`??}wwwrrwzuv      ?z.    .xuxxzwwwwww}>-^zxxxxxxxxxxxxxu|<br></br>
`}rrrr}v?^`.-??+>>??}rwwr}rxu-     .xy>     ?yxxzwwwwwww?|+zzxzzzzzzzzzzzz|<br></br>
`}rrr}|````.-?vv?v}vrrwwwrruv      +ux}     `xxzwr}rrwwwv^~rwwwwrrrwwrrrrr~<br></br>


            </div>,

            <div>

`w+     `?rrrr}??????v}}}}v}r?|...`?wv|...-+rrr}}}}}}}vvv}rrrrrrrrrrrrrrrr~<br></br>
-uu?`    .^}xzw}}v}}rwxxzzzzuv?^^`.~v>.-^~|?uuxxzxxxxxzwwxuuuuuuyyyyyuuuuy^<br></br>
-uuuur?-  .|vwwwwrrrwzxxxxxxuxvv}?-`-->vvvvuuxzzzzxzzzzwrwzwzzxuuuuuuxxuuy^<br></br>
-uuuuyyuw>+v}rzzwwwwzxuuxxxxzwv+~``->?>|^>vxuuuuuuuxzzzwrrwwwwzxuyyurvv}wx|<br></br>
-uuuuuuyyzvrrwzzzwwwxu}>+++^-`...~|?+|^-.``|+??vvv}wxxzzwwwwwwwxuxzrvvv??v-<br></br>
-uuuyyuuxvvvrwwzxzwxz?-.....-~+>??~`..-+>|.....``.->vzuxwwwwwwwzwvvvvvvv?v-<br></br>
`}rwv?>+|^>?}wwzxzxw+^-....`????v?-`...-^??>~....```^?}xzwwwwwr}vvvvvvvvvv-<br></br>
`vv+|~|-^+?v}wwzzxuv+?>|~^>v?+|^?+~>..|^|vv+?>-`.~>?>?>xxzwwrr}}vvvvvvvvvv-<br></br>
`vv-..``|^?vrwwwzxx>^?^|-++|~``~^^||^^+||^+|+>+|>?v}v+|xuzwwrr}}vvvvvvvvvv-<br></br>
`vv^|>~>>+>vrwwwzzu>^||+~^^|`..``|v~``~v?-^-~~~`|`->~^+xuzwwwrr}}wxuuxuxzw~<br></br>
`vv??????v}}}rrwwzuv|^``---...`-.`v?>??v+.-~..`|^~~-||vuzwrrwwrrxyyyyyyyyy^<br></br>
`vv????v>^?v}rwwzzxu?+|`.`...-^`..>-|+->`..^-.....`-^^zxwr}rwwwzuuyyyyyuyy^<br></br>
`vv}v?>>-+>?}rwwzwzxuv>^~--~^|`...`-..`-...`+|-``.`~+}uzwr}rrrzxuyyyyyuuuy^<br></br>
`v}}}}}v---+?}wwzwwzzxz}?+>>+`.....`-|`.....`+>+^+?vzuxwwr}}?}xuyyyuuuuuuy^<br></br>
`}}}}wzr^^^>>?}rwwrrrwzuz++++^-..`--~~~|||~~^++^+xuuuuxzwrv?^?uyyyuuuuuuuy^<br></br>
`}rrwxyu>^^>>>?v}}rrrrrzu}++^^^|^||~``-~|^|||^++xuxxxxzw}?^~`.vyyuuuuuuuzz~<br></br>
`rrrrwwz?+^>>^~??vvrrrrwxuw?+^~`...........-^+>zuxzzzzwrv>-..`vwzxxxzwwwrw~<br></br>
`zxuuuzw?>+|~`^?>>vrrrrwzxuuw>++~`-|^~|~--|^>ruuuuzwwzwr?|`.``vzzzwrrrrrrw|<br></br>
-uyyyyyyv>>``....`>v}}rwzxxvv>^|--~^~.-^~--~>vvzuuzwwwrv^-```|xyuuuxzzwwww|<br></br>
-uuuuuuy}+>|`.....~?v}rwzxxwrv??+-^``-`^~~>??v}zuxzwwrrv``..`>xzwwwzzwwwww~<br></br>
`zxzzxxx}>>?|.... -??}rwxuxxxxuur?+>~~>>??zuuxzzxxwwrvz?.~-.-vzwzzzzzzzzwz|<br></br>
`zzzzzzxr+>>-.... -?>vrwxuxzzzxuv>>>^|>+>>wuxzwzzzwrwvw?.+`-~rzxuuuuuuuxzx|<br></br>
`zxzzxxxw+>>+|^^^~~??>?rzxxzzzxx.   +?.   ruxzwzxzrv??+-~v`->xxxxxxxxxxxxx|<br></br>
-xxuuuuuu>>>~-```..?}??vwxxzxxuw    .`    vyuzzzxzr?+>~.+?--}uxxxxxxxxxxxx|<br></br>
-uuuuuuuu?>+~~````.?rrvvwzxxxuuv          >yuxxxxzw}^`.. .-|zxxxxxxxxxxxxx|<br></br>
`xuxxxxxu?>+~~-~```^}wr}rzxxxuy^          ^yuuxxxzw}+`...``|uxxxxxxxxxxxxu|<br></br>
`xuuuuuuu?++~|~^-``~vwr}rwzxuux.          -uuuxxxzwr?|-````?uuuuuuuuuuuuuu|<br></br>
-xuxxxxxu???|-->~``~vwwrrwwxuu?           .zuuxxxzwrv??+``-ruxxxxxxxuuuuuu|<br></br>
`zwwwwwwzv?>|-`^-.-^vwwwrrwzux`     .`     ?yuxxzzwwrrr?|`~zxxxxxxxxxxxxxu|<br></br>
`rrrrrrrw}..||`-~`+?}wwwrrwzu?      ?z.    `uuxxzwwwwwwv+`^zxxxxxxxxxxxxxu|<br></br>
`}rrrr}v?^``^>`->???}wwww}rxu-     .xy+     vuxxzwwwwwwr?~+zzzzzzzzzzzzzzz|<br></br>
`}rrr}|`````|????v}}rwwwwrru}      >uxv     ~xzwwr}rrwwwv^~rwrrrrrrrrrrrrr~<br></br>


            </div>,

            <div>

`r?--~~|>vwwrrr???????vvvvvvv??++>?vr}?>>>??}}}}vvvvvvv?}rrrrrrrrrrrrrrrrr~<br></br>
-uuv+`.`|?}xuuz}vvvv}rwzzzzzzrvvv??vw}?vvvv}xxzzzzzwwww}zuuyyuuuyyyyyuuuuy^<br></br>
-uuyz-.. `?}zxzwr}}}rwwzzxxzzzr}w}????v}}rwxxxzzzzwwwwwrwxuuuuuuuuuuuxuuuy^<br></br>
-uuuuv~~~.`?zuuuwrrrwzxxzzxzzwrv???^+>++vvrxxxxzzzxzwwwwrwxxuuuuuuyurvvrzx|<br></br>
-uuuuyr+|-`->rxzwrrrzxwvvvvv????>v++^||-+??v}}}r}rwzzzzzwwzxxuuuuxzrvv??vv-<br></br>
-uuuyyyu}|``-^?wwwwwz}???????vv}vv^`..`|??>???????vvrzxzwwwzzxxxwvvvvvvv?v-<br></br>
`}rwr}v?>>>~>?>wzzzwvv?|-`^vrr}rrv>-...`??}rv?????|-^vzzwwwwwwr}vvvvvvvvvv-<br></br>
`vv>++>^>>??}}vwzzx}v}v~`~>rv???rv>|`.~|>wr?v?>???^^~?}xzzwwrr}vvvvvvvvvvv-<br></br>
`vv+||^^+>??}}}rwzzvvr?-`++-```-^~`||~^^`^+~~-`->rv>>??xxzwrrr}vvvvvvvvvvv-<br></br>
`vv>>?|^+^^?v}rrwwzv?v>~~|.....```-|||+?-`~```..`~?^`?vxxzwrrr}}}wxuuxuxzw~<br></br>
`vv?v?+>>>??v}rwwwzr??-.`-.....`..`~^++|`.``...``-^.`?rxzwrrrrrwuyyyyyyyyy^<br></br>
`vvvv?^+~~+?v}rwwwwz}?- .`...`-...`.-|``...-`.....`.`?xxwrrrrwzuuyyyyyyuyy^<br></br>
`vv}v?~~``^^?}rwwwwzxv-..-`.````.```..`-```-^-`.....`vuzwr}rwzxuyyyyyyuuuy^<br></br>
`v}}}vv?~~|-^?}rwwwwzz?>-----`--~`.......``-|>^-`~-+>zuzw}vv}zuyyyyuuuuuuy^<br></br>
`}}}}w}v>>|-|>?}rrrrrzw}v++????^~~---`~||~~^vvv?>}}vruxzrv?+?wyyyuuuuuuuuy^<br></br>
`}rrwxww?++v|^??}rrrrrwzxwvv???>^^||-.-|^^^+>?vvuxzzuuxwv>-|>>}yuuuuuuuuzz~<br></br>
`rrrrw}}>+?}>|~>}}rrrrwwzxzv???>++~`...``^>>?vvxuuxxzwrv>~.-~|}wzxxzwwwwrw~<br></br>
`zxuuuxzv???>^`+v?v}rrwwzzxxwv???>``````.|??vwxuuuzwrrv>~...||vzzzwrrrrrrw|<br></br>
-uyyyyyyw}v>+~``~^?v}}rrwzxr}v?>?>...``..`??v}rzuxwrrv?+`..+~|ruuuuxzzwwrw|<br></br>
-uuuuuuyz}}>^~`--|?????}wzxzw}}vv^...  ..`v}}}rzxxwrrv?^``-+^>v}zwwzzwwwww~<br></br>
`zzzzxxxwv?>>+-^. `~^>?vrzxzxxxux^.......-xuuzwzxzwr}vv+.`-^+vv}zzzzzzzzwz|<br></br>
`zxzzzzzw??>>|^` .-|+|?vwxxzzzxu}`...-`..`wuuzwwzzwrr}}>.~`^>wzxuuuuuuxxzx|<br></br>
`zxxxxxxzv?+?^??^`..|~>}wxxzzxxu~   `}-   vuuzwzzzwr}v+--^`^vxxxxxxxxxxxxx|<br></br>
-xxuuuuuur?>?^>?+...>>>vwzxzxxuz.   `>~   >uuxzzxzw}vv^~>|-+wuxxxxxxxxxxxx|<br></br>
-uuuuuuuurv+>^++-..~v}v}rwzxxuuv    ~||   -uuxxxxzwrv>^-`.->xxxxxxxxxxxxxx|<br></br>
`xuxxxxuu}v>>+~--``^vwrrrwzxxuy^   .|||.  .wuuxxxzwrv+~````?uxxxxxxxxxxxxu|<br></br>
`xuuuuuuurvv>>~|?-.^}wrrrwzxxux.   -|||-  .vuuxxxzwrv?+-``~}uuuuuuuuuuuuuu|<br></br>
-xuxxxxxxrrw?>|^?``+}wwrrwwzuu?   .||~~|.  >uuxxxzww}vv?|->wuxxxxxxxuuuuuu|<br></br>
`wwwwwwww}vv?v^~`.`?}wwwrrwzuu`   -|^+~|`  `zuxxxzwwrrrv???zxxxxxxxxxxxxxu|<br></br>
`rrrrrrrr}++?r~...`?}rwww}rzuv   .|~}z||~   ?uuxzzwwwwwrv?vzxxxxxxxxxxxxxu|<br></br>
`}rrrrr}}????}~..`-^?rwww}}zu~   .|+xx?~|.  -ruxzwwwwzzw}?vzzzzzzzzzzzzzzz|<br></br>
`}rrr}?>??>^|?+^+vvvvrwww}rxr    `~vxz}~|`  .>xzwr}rrwwrv??rrrrrrrrrrrrrrr~<br></br>


            </div>,

            <div>

`rrrrwwwwwwwwww?????????vvvvvvvvv}}}}vvvvvvvvvvvvv??????}wrrrrrrrrrrrrrrrr~<br></br>
-uuyuv+?wyyyyuzvvvvvvv}rwzzwwwwwzxuxxxxxxxzzzzzzwwr}}vvvxyyyyyyyyyyuyyuuuy^<br></br>
-uuur    >xyyxr}v?vvvrrwwzzwwwwwzzwwr}rwwxxzzzwwwwwr}}}vruyyyyuuuuuuxxuuuy^<br></br>
-uuuu+    `vyyyy}vvvrwwwwwzwwwwzzwr+|~|>wrxxzzzwwwwwrrrrrzuyyyyyuuyu}vvrzx|<br></br>
-uuuuyv`    |}zwvvv}rzzwwzzzwwzx}x>-~~~~?wruxzwwwwzzwwwwwzxuuuuuuxzrvv??vv-<br></br>
-uuuuyyx?`    |v}rrrwzxxxzuxzzzurr>--``-?rruuxzwwzxzzzzwwwzxuuuurvvvvvvv?v-<br></br>
`}rrzzw}}v?`-^`}wwwwwxx?^|?uuuuuuv}~`..-}vuuuuxzzz+~^vxzwwwwzw}vvvvvvvvvvv-<br></br>
`vvvvvvvvvvvrv?}wwwwzxz`.`+zr}}wuxv-~.`~?xxrvvvwuz|..?uzzwwr}vvvvvvvvvvvvv-<br></br>
`vvvvvvv?vv?v?v}wwwwzu}``>?~````^|.|~`|+`^^````~?x?``wuzzwwr}vvvvvvvvvvvvv-<br></br>
`vvvvv?^^^~|>?vrwwwwxu?.|^........ +>+>> .......->r--zuzwwwr}}vv}wxuxxuxww~<br></br>
`vvvv?|^^||>?v}rwrwwxu+.|-..........---`.........-?``wuzwrrr}}}zuyyyyyyyyy^<br></br>
`vvvv?~~`~~+>v}rwrrwxx~ |^..........`~`..........`~.`ruzwrrr}wxyyyyyyyyuyy^<br></br>
`vvvvv````~~+?}rwrrwxr`..>~.. `|-----~~|||~~-....~...vuzwr}rzuuyyyyyyuuuuy^<br></br>
`v}}}v???>>-~+?}wrrrzr~`.--`-->^+|``....``-|>?|```.`.ruzwv?vwuyyyyuuuuuuuy^<br></br>
`}}}}r???v+`|+>?v}rrwzr+|>?rxxuv+|``~`~|--~>xuuw??>|?uuzr?~~vyyyuuuuuuuuuu^<br></br>
`}rrwz?????w-|^>vrrwwwzwzuuxxuzv+^||~`-^^^^>vuuuuxrruuxr?+`|}uuuuuuuuuuxzw~<br></br>
`rrrrwv>??wy+~-?zwwwwwwwzzzwwxxxxz?>^-|^>}xxzxxxuuuxzw}?~`.+vwrwzzzzwwwrrw~<br></br>
`zxuuuxzwzzw>+~>}vv}rwwwwwwwwzzzxx||````-?yuzwwzuuzr}v+..  `}vrzxzwrrrrrrz|<br></br>
-uyyyyyyuuuv++~|^?vvvvrrrwzwwwzzur   ~-  |yxzwwzxzw}}>-`. `vv?vuuuuxzwwwrw|<br></br>
-uuuuuuuuuy?>>^++?}v^||?}wzwwwzzu?       `uuzwwzzzrrr?^```^xr??vzwwzzwwwwz~<br></br>
`zzzzzxxxxw>>>>}..--...|vzzwwzzzu^       .xuxzwwzzw}v?>`..~rzw?vzzzzzzzzzz|<br></br>
`zzzzzzzzz}>>>v^ .+^`.`+}zxzzzzxz.   |.   ruuzwwzzwr}r?~.``vzzzuuuuuuuxxxx|<br></br>
`zxxxxxxxx}>>>w}+.   .|vrxxzzxxu?   .u^   ?uuxzwzzzwr}?|~`-}uxxxxxxxxxxxxx|<br></br>
`xxuuuuuuy}>>?uur. ..~>vwzxzxxxu-   +yv   |uuxzzxxzwwr>+>`-}uxxxxxxxxxxxxx|<br></br>
-uuuuuuuuuw+>>ww?..`^?v}rwzxxuu}    wyx.  .xuuxxxzwwr}v?^`-}uxxxxxxxxxxxxu|<br></br>
`xuxxxxxxxu?+>>+^|-+vrrrrwzzxuy+   |yuy^   vuuxzxzwwrv?^-`-ruxxxxxxxxxxxxu|<br></br>
-xuuuuuuuuuw+>+?v~.>}wrrwwwzxux.   }yuu}   >yuxzxzwwr}?+~`>uxuuuuuuuuuuuuu|<br></br>
`xxxxxxxxxxx?>+vv `?rwrwwrwwxu?   -uuxzu-  -uuxxxzwwrrv?>^wxxxxxxxxxuuuuuu|<br></br>
`wwwwwwwwwwwv?vv`.`vrrrwrrrwxu-   vyuzwu?   }uuxxzwwrrrrvrwzxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrrwv+~...?}rrww}rwur   `uuxwwu}   ~uuxzzwwwwzwwwwzxxxxxxxxxxxxxx|<br></br>
`}rrrrrrwwwww?......+}www}}zu^   ^yuzwwxu`   vuxzwwwwzzzrrrwzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrr}?^-`-~v???}www}}xr    ?xzwrrwx+   -xzwrr}rwwr}}}}rrrrrrrrrrrrrr~<br></br>


            </div>,

            <div>

`rrrrwwwwwwwwwwv?????????vv??vvvvvvvvvvvvvvvvvvvvv??????}wrrrrrrrrrrrrrrrr~<br></br>
-uuyuv>?zyyyyuz}vvvvvv}rwwwrrwwwwzxxxxxxxxzwwzwwwr}}vvvvxyyyyyyyyyyyyuuuuy^<br></br>
-uuuw   .>xyyur}v?vvv}rwwwzrrwwwzxwwwrwwwxxzwzwwwwrr}vvvwuyyyyuuuuuuxxuuuy^<br></br>
-uuuu>    `vyyyyrvvv}wwwwwzwwwwzzww?+^^?wwxxzzwwwwwwrrrrrxuyyyyyuuyu}v}wzx|<br></br>
-uuuuyv.    +rzwvvv}rwwwwzzwwzzxwz?|~~~|vwwuxzwwwwzzwwwwzxuuuuuuuxz}vv??vv-<br></br>
-uuuuuyz>.   .^vrrrrwzzzzzxzzzxxwr?~-``~vrruuxwwwzxzxzzwwwzxuuuurvvvvvvv?v-<br></br>
`}rrzzwrv>|..-`}zzwwwzzv>>vuxuuuurr|`..~}}uuuuxzzz?+>}xzwwwzzw}vvvvvvvvvvv-<br></br>
`vvvvvvvvv??v?^vzzwwzxz|~^?zrvvrxxv--.`~vxxrvv}wuz>~~vuzzwwr}vvvvvvvvvvvvv-<br></br>
`vvvvvvv?vv???vvwwwwzxr||??^````|~.~~`|^`^^-``-|?xv||wxzzwwr}vvvvvvvvvvvvv-<br></br>
`vvvvv?+++^+?vv}rrwwzxv|>+`....... |^++| .......->w^^zxzwwrr}}vvrzuuxxuxww~<br></br>
`vvvv?^^^|^++v}}rrwwzx?~+~`.........```.........`|v||wxzwrr}}}}zuyyyyyyyyy^<br></br>
`vvvv?||`~~|+?}rrrrwzz^-+>-.........```..........~>~~ruzwr}rrzuyyyyyyyyuyy^<br></br>
`vvvvv~```~~+?vrrrrwzr~-|?>~..`~-~--`--~~~~--...-+~-~}uzwr}rxuyyyyyyyuuuuy^<br></br>
`v}}}v?>>++`-^>vrrrww}~||++-`->|+|-``.```-~~+>|`~|-~`vzzwv?}wuyyyyuuuuuuuy^<br></br>
`}}}}rvv??^``~^?v}rwrwv+^?v}wrw?>+~-~`~|~|^>rwz}??>|?wzzr?++}yyyuuuuuuuuuu^<br></br>
`}rrrzvv??>w|-|>vrrwwwwrrwwrrwzv>+|--.`||^+?}zwwwr}}xxxr?^`^ruuuuuuuuuuxzw~<br></br>
`rrrrwv???ru?|->rrrwwwwzzzzwzxxxxz?|-.`-|}xxxxxxxxxxzrv>-..>vrrwzzzzwwwrrw~<br></br>
`zxuuuxzrrrr>>^+v?}}rwwwwwzwzzzzxx~`....`?yuzwwzxzw}v?^.   -}}wzxzwrrrrrrz|<br></br>
-uyyyyyyuxu}>>^|^?}v}}rrrwwwwzzzuw   ~`  |yxzwwwwwrvv+`.. `}v?vuuuuxzwwwww|<br></br>
-uuuuuuuuuuv?>^++?vv>+>v}rwwwzzxuv   ..  `uuxwwwww}}}?~..`>xr?>vzwwwzwwwwz~<br></br>
`zzzzzzxzxw?>>>v..~~```^vrwwzzzxu^    .  .zuxzwwwwr}v?|`.`^rww?vzzzzzzzzzz|<br></br>
`zzzzzzzzzr>>>v| `++~.`+vwzwzzzxz.  .+.   vuuxwwzzwr}}>`.-|}zzxuuuuuuuxxxx|<br></br>
`zxxxxxxxx}>>>}v>.  .`~?rzzzzxxu?   `u+   >uuxzzzzwwrv^`-`|ruxxxxxxzxxxxxx|<br></br>
`xxuuuuuuyr>>?zuw` .`|>vwwzzxxxu-   >y}   ~uuxzzxxwwrv^^+-~ruxxxxxxxxxxxxx|<br></br>
-uuuuuuuuuz+>>rwv-``^?v}rwzxxuur   .wux`  .zuuxzxzww}v??|-~ruxxxxxxxxxxxxu|<br></br>
`xuxxxxxxxu?+>>>+||^?rrrrwwzxuy+   ^uuu+   ?yuxzxzwrvvv^``~wuxxxxxxxxxxxxu|<br></br>
-xuuuuuuuuuz>>+?v|~>vrrrwwwzxux.  .ruuu}.  ^uuxzxzw}v?>~``?uuuuuuuuuuuuuuu|<br></br>
`xxxxxxxxxxxv>+??`+?v}rwwrrwxu?   ~uxzzx~  `wuxxxzw}vv?+|>zxxxxxxxxxxuuuuu|<br></br>
`wwwwrrrrrrw}???```>v}}wwrrwxu-   vuxwwu?   ?uuxzwr}vvvv}wzxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrrwv+~...^v}rww}}wur   -uxzwwxr.  `zuxzwr}vvvwwwwzxxxxxxxxxxxxxx|<br></br>
`}rrrrrrwwwwwv~`....+vwww}}zu|   +uxzwwzu-   ?uxzw}vvvrwrrwwzzzzzzzzzzzzwz|<br></br>
`}rrrrrrrrr?>^~~~>>>?}rwwr}xr    ?xwrrrwx>   `zzwrvvv}rr}}}}}rrrrrrrrrrrrr~<br></br>


            </div>,

            <div>

`rrrrrrrrwwwwwwv???????????>???????vvvvvvv??????????????}wrrrrrrrrrrrrrrrr~<br></br>
-uuuuyyyyyyyyyx}vvvvvvvvvvvvvvv}}wzzxxzzzwrrr}vvvvvvvvvvuyyyyyyyyuuuuuuuuy^<br></br>
-uuuuuuwwuyyyur}v???????vvvvv}rrwzxxxzzzxxzwwr}vv??????vwuyyyyuuuuuuuuuuuy^<br></br>
-uuuuyv. `}yyyyyrvv????vvvv}rwwwzxwr???}wzxzwr}vvvvvvvvvruyyyyyyuuyxvv}wxx|<br></br>
-uuuuyv    >uuzwv????vvvvv}rwwzzxwr+~~~+rruxzwr}vvv}}wwzxuuyyyyuuxz}v??vvv-<br></br>
-uuuuuy?.   -}vv}}vvvvvvv}rwwzzzuw}|---~}rxuxzwrrwwwwwwwwzxuuuux}vvvvvvv?v-<br></br>
`}rrzzzw?`    ~ruuw}}}r}}rwzxxuuuurv-..?rwuuuxzwwwwwwwwwwwzzzr}vvvvvvvvvvv-<br></br>
`vvvvvvvvv+`   -xxzwwwwwwwxuzv??}zr?`.`~vxxw}}wxzzzzwwwwww}vvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvww++>-rzwwwwwwwzuv~...`-..~-^~.~|```~?zuxzzwwwwr}vvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvwwvw??vrwwwwrrwxz^`.......`--........-?uxxzzwwwr}vvvvvrzuuxuuzwr~<br></br>
`vvvvvvvv??+~+>?v}rwrwrrwxx^.........`. .......`>uuxxzzwwrvvvv}xyyyyyyyyyy^<br></br>
`vvvvvv?^^^-~|>?}rrrrrrrwxz>-```~``-`-`-``...``->xuxxxzwr}vvwzuyyyyyyyyyyy^<br></br>
`vvvvvv^^^|~|++?v}rrwzzzzxur+^~`-^~``.````--~~||}uuuxzxzw}vwzyyyyyyyyuuuuy^<br></br>
`v}}}}v^||-`-|~>v}rz}>?zuuxv|>+`+^--~`~|-~+-^>?|zxux?>^xwvvwuyyyyyuuuuuuuy^<br></br>
`}}}}rxw~^|-..`^?}rx?~+>?v?+~.`?zwv^~.-^>rwv`..+vvv>~-?xw??wuyyyuuuuuuuuuu|<br></br>
`}rrrzuu>?+rr`-+>?}wxv>+|~..`~wyuur..```.ruuz|..`~>?vwuzv|`?zxuuuuuuuuuzww~<br></br>
`rrrrww}?v>vyw~`^>?}wzxxxz}}zuuxxuv  .   vuuuxw}}zxxw}?+-.~v}rrwzzzwwwwrrw|<br></br>
`zxuuuuw?>>}zzv~-|rwwwwwwzzzzzzzxu^      ?yuzzzzwrr}+~-.. >rvwxxxzwrrrrrrz|<br></br>
-uyyyyyyurxuxzv++vuxxzzwr}rrwzzzxu`  v>  +yuzwwr}}vvv|    >rrv}uuuuxzwwwww~<br></br>
-xuuuuuuuyuuux?>^vvv}}zz}vvrwzzzur  -yz. `uuxzww}vvv?-.  ^zwv>>vwwwwwwwwwz~<br></br>
`zzzzzzxzzzzx?+>||+}???v+?v}wzzzu>  vyu^  ruuzwwwrrrv+`..?zww}?vzzzzzzzzzz|<br></br>
`zzzzzzzzzzzr>>>?}+?v?~|+v}rwzzxx. .xuu}  >yuxzzzzwrv^`.`>zzzzxuuuuuuuxxxx|<br></br>
`zxxxxxxxxxx?>>>z+ `^|`^vrwwzzzuv  >yuux` `uuxzzzzwrr>`.-|xuxxxxxxxxxxxxxx|<br></br>
-xuuuuuuuyyz>>>wu?..^++?rwzzzxxu|  wuxxu+ .zuxzzxxwr}~`-`-rxxxxxxxxxxxxxxx|<br></br>
-xuuuuuuuuuv>>>xuzv-.^?vrwzxxuuw  ~uuzwuv  vyuzzxzw}v?-?`-vuxxxxxxxxxxxxxu|<br></br>
`xuxxxxxxxur+>>v}?v~`>vrrrwzxuy>  vyxzwxz. ~uuxzzz}+`>?```vuxxxxxxxxxxxxxu|<br></br>
-xuuuuuuuuuuv^>>?v}?^+vrrrwzxux` .zuxzwxu-  ruxzzr?~....`-zuuuuuuuuuuuuuuu|<br></br>
`xxxxxxxxzzzz?>>?zx?.~?}wrrwxuv  |uxzwwzu?  ~uuxxw?-... `ruxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrw}>??v|..~?rw}}wxu-  vuzwwrwuw.  }uxzr>`....ruxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwww}v?-..~vvww}}wuv  .xxwrrrrxu|  +yuzv|....?uxxxxxxxxxxxxxxxxx|<br></br>
`}rrrrrrrwwwwwz^ .. `v}wwr}zu`  +uzrrrrrxuv  `xuwv^.. +zwwwzzzzzzzzzzzwwwz|<br></br>
`}rrrrrrrrrrrv?~`.-^+vrwwrruv   vxwr}}}rzxw.  }xrv^`.-?}}}}}}}}}rrrrrrrrrr~<br></br>


            </div>,

            <div>

`rrrrrrwwwwrrwwv??????????>>???????vvvvvvv????????????>?rwrrrrrrrrrrrrrrrr~<br></br>
-uyuuyyyyyyyyyxrvvvvvvvvvvvvvvv}}rwzxxzzzwrrr}vvvvvvvvvvuyyyyyyyyuuuuuuuuy^<br></br>
-uuuuuuwwuyyyuw}v???????vvvvv}rrwwxxxzzzxxzwwr}vv??????vzyyyyyuuuuuuuuuuuu^<br></br>
-uuuuyv. -}yyyyywv??????vvv}rwwwzxwr???}wzxzwr}vvvvvvvvvruyyyyyyuuyzvvrzxx|<br></br>
-uuuuyv    ?uuwwv????vvvvv}rwwzzxww>|^^?wrxxwwr}vvv}}wwzxuuyyyyuuxzv???vvv-<br></br>
-uuuuuy?.   ~}}v}}vvvvvvv}rwwzzzuw}^~~|^}rxuzzwrrwwwzzwwwxuuuyux}vvvvvvv?v-<br></br>
`}rrzzzw?`    |}uuw}}}}}}rwzxxuuuurv-..?rwuuuxzwwwzzzzzzwzxxzr}vvvvvvvvvvv-<br></br>
`vvvvvvvvv^.   ~xxzwwwwwwwxuzvvvrzw?`.`~vxxw}}wxzzzzzzzzww}vvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvr}^^>`rzwwwwwwwzuv~``.`-`.~-^~.||-`-|?zuxzzzzzwr}vvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvrwvw?>vrwwwwrrwxz+`.......`--........-?uxxzzwwwr}vvvvvrzuuxuuzwr~<br></br>
`vvvvvvvv??>|+>?v}rrrrrrwzx+`........`. .......->uuxxzzwwrvvvv}xyyyyyyyyyy^<br></br>
`vvvvvv?^^^-|^>?v}rrrrrrwxz>-```~``-`-`-``...`-~?xuxxxzwr}vvwzuyyyyyyyyyyy^<br></br>
`vvvvvv^^^|~|^+?v}rrwzzzzxuw+^~`-^~``..```--~~|^ruuuxzxzw}vwzyyyyyyyyuuuuy^<br></br>
`}}}}}v^||-`-|~>v}rz}>?wuuxv|>+`+^--~`~|-~+-^+?^zxxx??^xwvvwuyyyyyuuuuuuuy^<br></br>
`}}}}rzw~^|-..`^?}rx?|>>?v?+~.`?zwv^~.-^>rwv`..+vvv>|~?xr??wuyyyuuuuuuuuuu|<br></br>
`}rrrwuu>?^}r`-+>?}wxv?>^|`.`~wyuur..``..wuuz^``-|??}wxw?~`?zxuuuuuuuuuzww~<br></br>
`rrrwww}?v+?yw~`^>?vwzzxxzr}zuuxxuv      vuuuxw}}zxxrv?^`.~v}rrwzzzwwrwrrw|<br></br>
`zxuuuuzv>+vwwv~-|}}rrrwwwzzzxzxxu^      ?yuzzzzwrrv^~-.. >rvwxxxxwrrrrrwx^<br></br>
-uyyyyyyurxuzzv++vuxzwwwr}}rwzzzxu`  v>  +yuxwwr}}vv?~    >rrv}uuuuxzwwwww|<br></br>
`xuuuuuuuuuuuu?>+vvv}}zz}vv}wzzzur  ~yz. `uuxzww}vvv?-.  ^zwv>>vwwwwwwwwwz~<br></br>
`zzzzzzzzzzzx?>>||+}???v>?v}wzzzu>  vyy^  ruuzwwwrrrv+`.`vzww}?vxzzxxzzzzz|<br></br>
`zxzzzzzzzzzr>>>?}+?v?|^+v}rwzzxx. .xuu}  >yuxzzzzwrv^`.`>zzzzxuuuuuuuxxxx|<br></br>
`zxxxxxxxxxx?>>>z+ `^|-^vrwwzzzuv  >yuux` `uuxzzxzwrr>`.~^xuxxxxzxzxxxxxxx|<br></br>
`xuuuuuyuyyz>>>wuv..^++?}wzzxxxu|  wuxxu+ .zuxzzxzwr}~`-`~rxxxxxxxxxxxxxxx|<br></br>
-xuuuuuuuuuv>>>xuzv~.^?v}wzxxuuw  ~uuzzuv  vyuzzxzw}??-?`-vuxxxxxxxxxxxxxu|<br></br>
-xuxxxxxxxur+>>v}?v~`>vrrrwzxuu>  vyxzzuz. ~uuxzzw}>`>?```vuxxxxxxxxxxxuxu|<br></br>
-uuuuuuuuuuuv^>>?v}?^>?rrrwwxux. .zuxzwxu~  }uxzzr?^....`-zuuuuuuuuuuuuuuu|<br></br>
`xxxxzzzzzzzz?>>>zx?`~?}wrrrxuv  |uxwwwzu?  ~uuxzr?-....`ruxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrw}>???|..~?rw}}rxu-  vuwrrrwxz.  }uxz}>`...`ruxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwww}v?-..~?vww}}wuv  .xxwrrrrxu|  +yuwv|....?uxxxxxxxxxxxxxxxxx|<br></br>
`}rrrrrrrwwwwwz+ .. `?}wwr}zx`  +uzrrrrrxuv  `xuwv^.. +zwwwwwwwwwwwwwwwwww|<br></br>
`}rrrrrrrrrrrv?|`.-^+vrrrrrxv   vxwr}}}rzxw.  }xrv^..-?}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwrwrrwwv??????????>?>>>??????v????????????????>?rwrrrrrrrrrrrrrrrr~<br></br>
-uyuuyyyyyyyyyxrv?vvvvvvvvv?vvvvvv}rwzwr}vvvvvvvvvvvvv?vuyyyyyyyyyyyyuuuuy^<br></br>
-uuuuuuuuuyyyuw}v???????????vvv}}rrwxxzwrr}vvvv????vv??vzyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuuuuyyyyyyywv????????vvv}rwwwzuuuxzwwr}vvvvvvv??vvvruyyyyyyuuuzvvrzxu|<br></br>
-uuuuuuuuv?ryuzwv??vvvvvvvvvvrwwzzxzzzzzxzww}vvv?vvv}zxuyyyyyyyuuxwv???vvv-<br></br>
-uuuuyyyv   ?y}?v}vvvvvvvvvv}rwzxxrr?+?rwuxwr}vvwzxuyyyyuuuuyyuzvvvvvvvv?v-<br></br>
`}rrzzzwv.   >rwuyzvvv}vvv}rrwzxuxr?---v}xuzwr}}zuuuuuuuuuuxwr}vvvvvvvvvvv-<br></br>
`vvvvvvvv>.   |xyuuxzuux}}rwzxuuuuwv~.~}ryuuxzwrrwzxuuuzrvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvv-   .vyuuxxxzwrwxuwv?vr}+``-+}wv??wxwwwwwwr}vvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvww>....wxzwwwwwwzu}~......^||..``.`|rxwwwww}}vvvvvvvvvrxuuxuxzwr~<br></br>
`vvvvvvvvvvv}???~?wwwrrrwwxu?`.......|. .....`?uzzzzwwrr}}vvvvrxyyyyyyyyyy^<br></br>
`vvvvvvvvvvvv?}?>??}rrwwwwxu}-```````-````..`~?uxxxxxzw}r}wwwzuyyyyyyyyuyy^<br></br>
`vvvvvvvv?+^|`-^+>vrrww}wuuuz++|`|~``.``~|~~>+wuuurrxxw}v?>zyyyyyyyyuuuuuy^<br></br>
`}}}}}}}?|^^^-|^+?vrwx>|>wxw}^|`^?^~~`||+?~`~~}zzv^-wxwrrv?rxyyyyyuuuuuuuy^<br></br>
`}}}}rzx?|+^~~~+^?vrwzw>^|^|-`.?xuz~``-~vyw^.`^^+>?ruzr??>vwuyyyuuuuuuuuuu|<br></br>
`}rrrwxuu^``.-`-`-|vrwwzzw}??vzuuur  .  ?uuuv???wuxzwr?-.`vzxxuuuuuuuuxzww~<br></br>
`rrrwwwwz?``~-?..~^^>v}rwwzuuuxxxu?     |uuuuxzzwrr}>|``  ^vrrrwzzzwwrrrrw|<br></br>
`zxuuuuxw>^+v+zv~`|~~^?v}v}rwzzzxu~  >. .xuuzwrr}}w>   .. ?}}zxxxxwrrrrrwx^<br></br>
-uyyyyyyz??+>rwwz^`-|~vrv??vrwzzuw  |y>  }yuxwr}}r}~.`   >wvvwxuuuuxzwwwww|<br></br>
`xuuuuuuxv>>xyuux?^?wwuur}vvrwwzu?  ryz. >yuxzwrr}vv?.   ?u}v?+vwwwwwwwwwz~<br></br>
`zzzzzzzxxwwxzxw?++xyuuxwwr}wwzxx` ~uuy+ .xuuzwwwrv?|` .?zwzwv?vzxzxxzzzzz|<br></br>
`zxzzzzzzzzzzxr>>++v?vvrwwrwzzzuv  vuuu}  vyuxzzwr}}>`..vzzzzzxxuuuuuuxxxx|<br></br>
`zxxxxxxxxxxx}>>>?v^?v??vwwzxxxu| `xuuux` ^yuxzzwrv??-.`>uuuxxxxxxxxxxxxxx|<br></br>
`xuuuuuyuyyyr>>>vuy>^v?+?rwxuuuw  ?uuxxu> .zuxwwwr?vv`.`-rxxxxxxxxxxxxxxxx|<br></br>
-xuuuuuuuuuz>>>?uuu- ~>^?}wxuuu^ .xuxzzuw  ?yuzzwrv}|`~`->uxxxxxxxxxxxxxxu|<br></br>
-xuuuuuuxxux?+>?zx}?^`~^vrwzxu}  ?yuzwwxu~ `xuxzwrvv>?|`.|xuxuxxxxxxxxuuxu|<br></br>
-uuuuuuuuuuuu?^>??+?z?.^v}rwxu^ .zuzwwrzuv  ?yxzr?~?~~~.~?uuuuuuuuuuuuuuuu|<br></br>
`xxzzzzzzzzzzz?^>>rzx>->}rrwur  ^uzwrrrwxu` .xuxr?-.`..>wzxxxxxxxxxxxuuuuu|<br></br>
`wrrrrrrrrrrrww?|>vr?^|?rrrwu+  vxwrrrrrzu?  ?yxr?-...?uxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwww>?^`..-?rrrxx. .zzrrww}}wux. `xur?-.. ~xxxxxxxxxxxxxxxxxzx|<br></br>
`}rrrrrrrwwwwwwzr`.. .+?rwzz>  vzrrrrr}}wxuv .vuwv~. ~vzwwwwwwwwwwwwwwwwww|<br></br>
`}rrrrrrrrrrrrrr?...--^+?r}~..|v}}}}}vvwrrzr-.`vzv^..>}}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwrwrrwwv??????????>?>>>??????v??????????????????rrrrrrrrrrrrrrrrrr~<br></br>
-uyuuyyyyyyyyyuwv?vvvvvvvvv?vvvvvv}rwzwr}vvvvvvvvvvvvv?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuuuuuuyyyyw}v???????????vvv}}rwzxxzwrr}vvvvv???????vxyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuuuuyyyyyyyzv????????vvv}rwwwzuuuxzwwr}vvvvvvv??vvvwuyyyyyyuuuwv}wzxu|<br></br>
-uuuuuuuuv?ryuzz}??vvvvvvvvvvrwwzzxzzzzzxzww}vvv?vvv}zxuyyyyyyyuuxwv???vvv-<br></br>
-uuuuuyyv   ?y}?v}vvvvvvvvvv}rwzxxrr?+?rwuxwr}vvwzxuyyyyuuuuyyuzvvvvvvvv?v-<br></br>
`}rrzzzzv.   >rwuyzvvv}vvv}rrwzxux}?---v}xuzwr}}zuuuuuuyuuuxwr}vvvvvvvvvvv-<br></br>
`vvvvvvvv>.   |xyuuxzuux}}rwzxuuuuwv~.~}ryuuxzwrrwzxuuuzrvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvv-   .vyuxxxxzwrwxuwv?vr}+``-+}wv??wxwwwwwrr}vvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvvrw>....wxzwwwwwwzu}~......^||..``.`|rxwwwwr}}vvvvvvvvvwxuuxuxzwr~<br></br>
`vvvvvvvvvvv}???~?wwwrrrwwxu?`.......|. .....`?uzzzzwwrrr}vvvvrxyyyyyyyyyy^<br></br>
`vvvvvvvvvvvv?}?>??}rrwwwwxu}-```````-````..`~?uxxxxzzw}r}wwwzuyyyyyyyyuyy^<br></br>
`vvvvvvvv?+^|`-^+>vrrww}wuuuz++|`|~``.``~|~|>+wuuurrxxw}v?>zyyyyyyyyuuuuuy^<br></br>
`}}}}}}}?|^^^-|^+?vrwx>|>wxw}^|`^?^~~`||+?~`~~}zzv^-wxwrrv?rxyyyyyuuuuuuuy^<br></br>
`}}}}rzx?|+^~~~+^?vrwzw>^|^|-`.?xuz~``-~vyw^.`^^+>?ruzr??>vwuyyyuuuuuuuuuu|<br></br>
`}rrrwxuu^``.-`-`-|?rwwzzw}??vzuuur  .  ?uuuv???wuxzwr?-.`vwxxuuuuuuuuxwww~<br></br>
`rrwwwwwz?``~-?`.~^^>v}rwwzxuuxxxu?     |uuuuxzzwrr}>|``  ^vrrrwzzzwrrrrrw|<br></br>
`zxuuuuxw>^+?+wv~`|~~^?v}v}}rwwzxu-  >. .xuuzwrr}}w>   .. ?}}zxxxxwrrrrrwx^<br></br>
-uyyyyyyz??+>rwwz^`-|~vrv??v}rwzuw  |y>  }yuxwr}}r}~..   >wvvwxuuuuxzwwwww~<br></br>
`xuuuuuuxv>>xuuux?^?wwuur}vv}wwzu?  ryz. >yuxzwrr}vv?.   ?uvv?+vwwwwwwwwwz~<br></br>
`zzzzzzzzxwwxzxw?++xyuuxwwr}wzzxx` ~uuy+ .xuuzwwwrv?|` .?zwzwv?vzxxxxzzzzz|<br></br>
`zxzzzzzzzzzzxr>>++v?vvrwwwwzzzuv  vuuu}  vyuxzzwr}}>`..vzzzzzxxuuuuuxxxxx|<br></br>
`zxxxxxxxxxxu}>>>?v^?v??vwwxxxxu| `xuuux` ^yuxzzwrv??-.`>uuuxxxxzxxxxxxxxx|<br></br>
`xuuuuuyuyyyr>>>vuy>^v?+?rwxuuuw  ?uuxxu> .zuxwwwr?vv`.`-rxxxxxxxxxxxxxxxx|<br></br>
-xuuuuuuuuuz>>>?uuu- ~>^?}wzuuu^ .xuxzzuw  ?yuzzwrv}|`~`->uxxxxxxxxxxxxxxu|<br></br>
-xuuuuuuuuux?+>?zx}?^`~^vrwzxu}  ?yuzwwxu~ `xuxzwrvv>?|`.|xuuxxxxxxxxxuuxu|<br></br>
-uuuuuuuuuuuu?^>??+?z?.^v}rwxu^ .zuzwwrzuv  ?yxzr?~?~~~.~?uuuuuuuuuuuuuuuu|<br></br>
`xxzzzzzzzzzzz?^>>rzx>->}rrwur  ^uzwrrrwxu` .xuxr?-.`..>wzxxxxxxxxxxxuuuuu|<br></br>
`wrrrrrrrrrrrww?|>vr?^|?}r}wu+  vxwrrrrrzu?  ?yxr?-...?uxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwww>?^`..-?rrrxx. .zzrrww}}wux. `xur?-.. ~xxxxxzxxxzzxxxxxxzx|<br></br>
`}rrrrrrrwwwwwwzr`.. .+?rwzz>  vzrrrrr}}wxuv .vuwv~. ~vzwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrr?...--^+?}}~..|v}}}}}vvwrrzr-.`vzv^..>}}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwrwwwww}??????????>>>>>>??????????????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyyyyyyyyyuzv?vvvvvvvvv??vvvvvv}rr}}vvvvvvvvvvvvvv?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuuuv>ryyyyz}v???????v?????vvvv}wzzwr}vvvv??????????vuyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuy>  >uyyyyxv??????????vvv}rrwxxxzwr}}vvv???????vvvwyyyyyyyuuurv}wxxu|<br></br>
-uuuuuux|  ~zyzzr????vvvvvvvvv}rwzxuuuxzww}vvvvv?vvv}zxuyyyyyyuuuxwv??vvvv-<br></br>
-uuuuuuyu>  .vwvv}vvvvvvvvvvv}rzxuxwwrwzxwr}vvvvwzxuyyyuuyuyyyuzvvvvvvvv?v-<br></br>
`}rrzzxzr}?.`~>wuyxv?v}}vvvvv}wxuu}}^|?rzxwr}vvvzuyyuuuuy}rzzr}vvvvvvvvvvv-<br></br>
`vvvvvvvvvv?v>^vrv>^|?}}vvv}rwzuuu}v-`^}wuxzr}}vvxyuuuuzv??>>?vvvvvvvvvvvv-<br></br>
`vvvvv???>>||~~`-^^^~>???vrwzxxzzuxv-.^}uxzzxwr}ruuz}}}?+zw>}vvvvvvvvvvvvv-<br></br>
`vvvvv?^|^|--|||~^~|`~v}}}wxuv|``|~.|~-`^|-~>wzrrwrvvvvv?+^^>vvvvrwr}v>??r~<br></br>
`vvvvv?+-`~~||||`.`>??vrwwzux^......`-......`vuwrr}}}vv?~.  -??vv}}}rv???y^<br></br>
`vvvv?v??~.````````>vvrwzzzxu>-``````--```.`~?uxxzzr}r?  .  .}xxxxxxxuyyyy^<br></br>
`vvvv>?v????>.-~~-~?vrzv?wuuy?>+-^~-```-^~~>+wuuxvvzww+     +yyyyyyyyuuuuy^<br></br>
`}}}}v???vwzv}|`-~~>vwu+~+}rr>~`+r?^~.|^v?``~}rv?|+xwr`..   }yyyyuuuuuuuuy^<br></br>
`}}}}rzw}zyyuu+|^+>vvwzzv>+^-`-?uuz`.`.~uu}~-~|>vwxwzz`..  ~xyyyuuuuuuuuuu|<br></br>
`}rrrwzwzyuxzw}+|~v??wwzzzxzwrxuuu}    .zuuuzwzxxzwzu?``. -?rwuyuuuuuuxwrw~<br></br>
`rrwwwwvv>+>>^+`.|???vxxv^??vwzxxu>     vyuuxzwwwwwzw~-.~^+^>??zzzzwrrrrrz|<br></br>
`zxuuuuur>+vv|  .?z?vvvu? -|^?wzxu` .}. |yuuzwrrrrrw?--+v^>?v-?uxzwrrrrrzu^<br></br>
-uyyyyyyx^ .->| .~v}+>?wv.-+>vwxuv  ?y> .zuuzwrrrrwx>-`>vv+?>|vuuuxzzwwwww~<br></br>
`xxxuuux??~`^`    .?v++vw+?}}rzxu| `xuw  ?yuxzwrwzux|~~~r}|???wwwwwwwwwwwz~<br></br>
`zzzzzzwv??zx}`..`..??>+}}}wzzzuz. ?yuu~ -uuxzwrrr+>?+>+w?vv`?zzxxxxxzzzzz|<br></br>
`zxxzzzzxzxzzxw?... ^w>+?rvzxxxu? .xuuuv  ruuzwrv?+`-`. -|>?>wuuuxxuuuxxxx|<br></br>
`zxxxxxxxxxxxxxu?...>rv>>rvwxuuu` ?yuxux` >yuzwzxxz~`.....}yw>+vxxxxxxxxxx|<br></br>
-xuuuuyyuyyyyuuyz`.|>|>+>v}}xuu? .xuxzxu> .zuzwwzu?.......-}u?+??xxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuwr|  .`vvrrxuz. ?yuzwwuw  ?yxwwzw`...++ ..`wuxz}xxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuux+.~-`+rwxy> .xuzwwwzu^ `xxwww>....|.    ?uuuuxxxuxuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuu>.-+>vwuz. >uzrrrrwuw  ?yzw?~..  -|`->vzuuuuuuuuuuuuu|<br></br>
`xzzzzzzzzzzzzzzwwzzzw?v}vvwu> .zzrrrrrrzy^ .wxr?++|.^zxzxuuxxxxxxxxxuuuuu|<br></br>
`wrrrrrrrrrrrwwwwwwwwww?+?}xx. >zrrwwwwrwxw. ?uw??rwwxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzz}>vw}~ `wwrwwzzwrrwu? `}z?rwzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwww??}v``+}rrrrwwwwrrrww^.`v}rrrwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrv?v}vvrr}}}}}}}}}}}}}}vvv}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwrwwwww}?>????????>>>>>>??????????????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyyyyyyyyyuzv?vvvvvvvvv??vvvvvv}rr}}vvvvvvvvvvvvvv?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuuuv>ryyyyz}v???????v?????vvvv}wzzwr}vvvv??????????vuyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuy>  >uyyyyxv??????????vvv}rrwxxxzwr}}vvv???????vvvwyyyyyyyuuurv}wxxu|<br></br>
-uuuuuux|  ~zyzzr????vvvvvvvvv}rwzxuuuxzwrrvvvvv?vvv}zxuyyyyyyuuuxwv??vvvv-<br></br>
-uuuuuuyu>  .vwvv}vvvvvvvvvvvv}zxuxwwrwzxzr}}vvvwzxuyyyuuyuyyyuzvvvvvvvv?v-<br></br>
`}rrzzxzr}?.`~>wuyxv?v}}vvvvv}wxuu}}^|?rzxwr}vvvzuyyuuuuy}rwzr}vvvvvvvvvvv-<br></br>
`vvvvvvvvvv?v>^vrv>^|?}}vvv}rwzuuu}v-`^}wuxzr}}vvxyuuuuzv??>>?vvvvvvvvvvvv-<br></br>
`vvvvv???>>~~~~`-^^^~>???vrwzxxzzuxv`.^}uxzzxwr}ruuw}}}v>zw+}vvvvvvvvvvvvv-<br></br>
`vvvvv?^~^|--|||~^~|`~v}}rwxuv^``|~.|~-`^|-~>wzrrwrvvvvv?+^^>vvvvrwr}v>??r~<br></br>
`vvvvv?+-`~~||||`.`>??vrwwzux^..... `-......`vuwrr}}}vv?~.  -??vv}}}rv???y^<br></br>
`vvvv?v??~.```.````>vvrwzzzxu>-``````--```.`~?uxxzzr}r?  .  .}xxxuxxxuyyyy^<br></br>
`vvvv>?v????>.-~~-~?vrzv?wuuy?>+-^~-```-^~|>+wuuxvvzwz+     +yyyyyyyyuuuuy^<br></br>
`}}}}v???vwzv}|`-~|>}wu+~+}wr>~`+r?^~.|^v?``~}rv?|+xwr`..   }yyyyuuuuuuuuy^<br></br>
`}}}}rzw}zyyuu+|^+>vvwzzv>+^-`-?uuz`.`.~uu}~-~|>vwxwzz`..  ~xyyyuuuuuuuuuu|<br></br>
`}rrrwzrzyuxzw}+|~v??wwzzzxzrrxuuu}    .zuuuzwzxxzwzu?``. -?rwuyuuuuuuxwrw~<br></br>
`rrwwwwvv>+>+^+`.|???vuxv^??vrxxxu>     vyuuxzwwwwwzw~-.~^+^>??zzzwwrrrrrz|<br></br>
`zxuuuuur>+vv~  .?z?vvvu? -|^?wzxu` `}. |yuuzwrrrrww?--+v^>?v`?uxzwwrrrrzu^<br></br>
-uyyyyyyx^ .->| .|v}^>?rv.->>vwxuv  ?y> .zuuzwrrrrwx>-`>vv+?>|vuuuxxzwwwww~<br></br>
`xxxxuux??~`^`.   `?v++}w+?}}rzxu| `xuw  ?yuxzwrwzux~~~-r}|???wwwwwwwwwwwz~<br></br>
`zzzzzzwv??zx}`..`..??>+}}rzzzzuz. ?yuu~ -uuxzwrrr+>?+++w?vv`?zzxxxxxzzzzz|<br></br>
`zxxzzzzxzxzzxw?... |w++?rvzxxxu? .xuuuv  ruuzwrv?+--`. -|>?>wuuuxxuuuxxxx|<br></br>
`zxxxxxxxxxuxxxu?...>wv>>rvwxuuu` ?yuxux` >yuzwzxxz~`.....}yw>+vxxxxxxxxxx|<br></br>
-xuuuuuyuyyyyuuyz`.|>|>+>v}}xuu? .xuxzxu> .zuzwwzu?.......-}u?+??xxxxxxxxx|<br></br>
-xuuuuuuxxxuuuuuuwr^  .`vvrrxuz. ?yuzwwuw  ?yxwwzw`...++ ..`wuxz}xxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuux+.~-`+rwxy> .xuzwwwzu^ `xxwww>....~.    ?uuuuxxxuxuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuu>.-+>vwuz. >uzrrrrwuw  ?uzw?~..  -|`->vzuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzzwwzzzw?v}vvwu> .zzrrrrrrzy^ .wxr?++|.^zxzxuuxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwwwwwwww?+?}xx. >zrrwwwwrwxw. ?uw??rwwxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzz}>vw}~ `wwrwwzzwrrwu? `}xvrwzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwww??}v``+}rrrrwwwwrrrwr^.`v}rrrwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrv?v}vvr}}}}}}}}}}}}}}}vvv}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>>>>>>>>>>>????????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyyxxyyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvv??vvvvvvvvv?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuux`.+xyyyz}v???????v????vvvvvv}}}vvv??????????????vuyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuu?  `ryyyyxv?????????v?>vv}}rrwrr}vvvvvv???????vvvwyyyyyyyuuurv}wxxu|<br></br>
-uuuuuuyv. .vyzzr??v?vvvvv?v?+?}rwzxzzzw}vvvvvvv?vvv}zxuyuzuyyuuuxwv??vvvv-<br></br>
-uuuuyyyyz|.`>rvvrvvvvvvvvvv?vv}wzxxzzzzwr}v?vvvwzxuyyyyxvv?vuuzvvvvvv???v-<br></br>
`}rrzzwzr}v+v-v}wv?+>vvvvvvvv}vrzuw}?>vrxzw}vv?vzuyyuuuy?}z+?}}vv??vvv^?^v-<br></br>
`vvvv?+???>++^~~^^^~~????vvvvr}wxu}v-`+}zxzrv?v?vxyuuuuz??+|>v?vvvv}}v?vvv-<br></br>
`vvvv??||^~`-||~^||-`^??>}}}rwxxxuxv-.>ruuxxzwwvruuz}}}v+`  .???vvvvvvvvvv-<br></br>
`vvvv?v~`-~~^|^~`.->?>>vv}rrzz?|~++`~~`|>+^+}zr}}wrvvv}~ .   `v}wxuuuuxwrr~<br></br>
`vvv>vv?^.````-`.`>x}??vrrwwx?`.... `-......^zzwr}}}}r}.     >zuyyyyyyyyyy^<br></br>
                  `vvv??????vv~`||-~?w??vrzxzwxv~``-```--`````^zuzzwwrzy? .   .zyyyyyyyyyyyy^<br></br>
`vvvvvvv}vvvv|`-~|>?v}zw?vuuu}>?~^~-``-~^~|>>uuuv?rzzu}..  .?yyyyyyyuuuuuy^<br></br>
`}}}}}vvxuwxv>||^+?v}rxr~|?}}?~`|w?^-.|>r?..+vv?+^wwzx+``.`.?}xuyyuuuuuuuy^<br></br>
`}}r}}}uyyyu}+>+>?v+wwwxz}?>^-~?uuz..` ^yu}^^|>vzxzwx?```````^v}rxuuuuuuuu|<br></br>
`}rrr}vr?v>??``?vv?vv}rw}rzzzzuuuuv    .xuuuuxxxzwwxz~-`}wr>++|?|}yuuuxwrw~<br></br>
`rrwww}>|v??  .?xu?+>?wx>~v}}rzxxu>  .  vuuuzwwwwwzz?-~vrrv^|???~vzwrrrrrz|<br></br>
`zxuuyw>``~+|. |rxx>>>zu?.?v}rzxxu` -w. ^yuxzwrrrrw}~-vuz?-v}|>>>zwwrrrrzu^<br></br>
-uyyur??^.~-`  .~vz}>>?zr`|vrwzxuv  vy+ .zuxzwrrrrz>--vz}^>zv?v|zuxxzwwwww~<br></br>
`xxuz}}?vxuv```` .>zv>>vx?.?rwzxu~ ~uur  vyuxwrwwzr-|+?z> `++v>^wzwwwwwwwz~<br></br>
`zzzzzzzxxzxz?....+xw>>>ww~-v}zuw  vuuu~ ~uuzwwwwz+--}x? ..``rrv?}xxxzzzzz|<br></br>
`zxxzzzzzzzzxz?-..^}zr+>?u>.?rzu> -uuuuv  wuzwzxxr--+z^......vuv`>vuuxxxxx|<br></br>
`zxxxxxxxxxuxxuzv>?~^z???rr~?wuz. vuuxux` ?yzwzxu?-~v~...?`...?xwv>xxxxxxx|<br></br>
-xuuuuuyuyyyyuuuyuw`.-?}vvuvvxy+ -uuzwzu> `uxwwzr-`-`...`~. ..`zuxxxxxxxxx|<br></br>
-xuuuuuuxxxxxuuuuuuw``-v--}wwur  }uzwrwxz. ?uzwzv.-+`.. `~..`|vxxxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuw?+`^^>wxu| ~uxwrwrzu> `xuww?+~+>+`^wxrwxuuuuuxxxuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuyv.`>?ru}  }uwrrrrwxx` +uzr?-.?wwzxuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzwzzzzzzw|^>}zu| ~xwrrwrrrwu?  }uw?-`zxxxxxxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwwwwwwwwww}?}wx? `wwrwzzzwwrzx^ >uz?+vxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
                  `rrrrrrrrrwwwwwzzzzzzzwwwwr~.~?rrrwzzzzwrrzv`.+zwwzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwrrr}?vrwrrrwwwwwrrrrwv??rrrrrwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrr}}rr}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}??????????>>>>>>>>>>>>????????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyyxxyyyyyuzv?vvvvvvvv???vvvvvvvvvvvv????vvvvvvvvv?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuux`.+xyyyz}v???????v????vvvvvv}}}vvv??????????????vuyyyyuuuuuuuuuuuuu^<br></br>
-uuuuuu?  `ryyyyxv?????????v?>vv}}rrwrr}vvvvv????????vvvwyyyyyyyuuurv}wxxu|<br></br>
-uuuuuuyv. .vyzzr??v?vvvvv?v?+?}rwzxzzzw}vvvvvvv?vvv}zxuyuzuyyuuuxwv??vvvv-<br></br>
-uuuuyyyyz|.`>rvvrvvvvvvvvvv?vv}wzxxzzzzwr}v?vvvwzxuyyyyxvv?vuuzvvvvvvv??v-<br></br>
`}rrzzwzr}v+v-v}wv?+>vvvvvvvv}vrzuw}?>vrxzw}vv?vzuyyuuuy?}z+?}}vv??vvv^?^v-<br></br>
`vvvv?+???>++^~~^^^~~????vvvvr}wxu}v-`+}zxzrv?v?vxyuuuuz??+|>v?vvvv}}v?vvv-<br></br>
`vvvv??||^~`-||~^||-`^??>}}}rwxxxuxv-.>ruuxxzwwvruuz}}}v+`  .???vvvvvvvvvv-<br></br>
`vvvv?v~`-~~^|^~`.->?>>vv}rrzz?|~++`~~`|>+^+}zr}}wrvvv}~ .   `v}wxuuxuxwrr~<br></br>
`vvv>vv?^.````-`.`>x}??vrrwwx?`.... `-......^zzwr}}}}r}.     >zuyyyyyyyyyy^<br></br>
                  `vvv??????vv~`||-~?w??vrzxzwxv~``-```--`````^zuzzwwrzy? .   .zyyyyyyyyyyyy^<br></br>
`vvvvvvv}vvvv|`-~|>?v}zw?vuuu}>?~^~-``-~^~|>>uuuv?rzzu}..  .?yyyyyyyuuuuuy^<br></br>
`}}}}}vvxuwxv>||^+?v}rxr~|?}}?~`|w?^-.|>r?..+vv?+^wwzx+``.`.?}xuyyuuuuuuuy^<br></br>
`}}r}}}uyyyu}+>+>?v+wwwxz}?>^-~?uuz..` ^yu}^^|>vzxzwx?```````^v}rxuuuuuuuu|<br></br>
`}rrr}vr?v>??``?vv?vv}rw}rzzzzuuuuv    .xuuuuxxxzwwxz~-`}wr>++|?|}yuuuxwrw~<br></br>
`rrwww}>|v??  .?xu?+>?wx>~v}}rzxxu>  .  vuuxzwwwwwzz?-~vrrv^|???~vzwrrrrrz|<br></br>
`zxuuyw>``~+|. |rxx>>>zu?.?v}rzxxu` -w. ^yuxzwrrrrw}~-vuz?-v}|>>>zwwrrrrzu^<br></br>
-uyyur??^.~-`  .~vz}>>?zr`|vrwzxuv  vy+ .zuuzwrrrrz>--vz}^>zv?v|zuxxzwwwww~<br></br>
`xxuz}}?vxuv```` .>zv>>vx?.?rwzxu~ ~uur  vyuxwrwwzr-|+?z> `++v>^wzwwwwwwwz|<br></br>
`zzzzzzzxxzxz?....+xw>>>ww~~v}zuw  vuuu~ ~uuzwwwwz+--}x? ..``rrv?}xxxzzzzz|<br></br>
`zxxzzzzzzzzxz?-..^}zr+>?u>.?rzu> -uuuuv  wuzwzxxr--+z^......vuv`>vuuuxxxx|<br></br>
`zxxxxxxxxxuxxuzv>?~^z???rr~?wuz. vuuxux` ?yzwzxu?-~v~...?`...?xwv>xxxxxxx|<br></br>
-xuuuuyyyyyyyuuyyuw`.-?}vvuvvxy+ -uuzwzu> `uxwwzr-`-`...`~. ..`zuxxxxxxxxx|<br></br>
-xuuuuuuxxxxxuuuuuuw``-v--}wwur  }uzwrwxz. ?uzwzv.-+`.. `~..`|vxxxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuw?+`^^>wxu| ~uxwrwrzu> `xuww?+~+>+`^wxrwxuuuuxxxxuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuyv.`>?ru}  }uwrrrrwxx` +uzr?-.?wwzxuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzwzzzzzzw|^>}zu| ~xwrrwrrrwu?  }uw?-`zxxxxxxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwwwwwwwwww}?}wx? `wwrwzzzwwrzx^ >uz?+vxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
                  `rrrrrrrrrwwwwwzzzzzzzwwwwr~.~?rrrwzzzzwrrzv`.+zwwzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwrrr}?vrwrrrwwwwwrrrrwv??rrrrrwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrr}}}rr}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}??????????>>>>>>>>>?>>>???????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyx??yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyyyz}v???????v?v?>???vvvvvvvvv??????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????vvv}rrrr}vvvvv??v??????vvvwyz}zyyyuuurv+?}xu|<br></br>
-uuuuuyyz| .?uzzrvvv?vvvvvvvvvvv}rwzwwwr}vvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwvv??vvvvvvv?}vvv}rwxxxxzzwr}v?vww}zxuyyyyu?r}|vv}v}vvvvvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}vvv}wxzrv?}wxwr}v?r}vxyyuuyuz}^..^r?vv?vvv?vvv-<br></br>
`vvv??>^^^``~~-|^~```??+??rvv}}rxu}v-`>}xxwr}vvvvxyuuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^|~..->^^??+>vv}rzxuyxv-.>wyuxzwr}}ruzr}}}v-     |}vvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}wr++???v}wzv^|>>~~~-^>++?zzr}??>vvvv?.     ?}wxuuxuxwrr~<br></br>
`vv?????>??~`||~|?yuv???v}vrzv-.... ``.....`?xwrvvvvrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvv}}vv}v}?-||~~}v??vrzzwwxr~``-````-````->uxzzzwzuuyw`....>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???|`-^^?vv}wz>?zuuw+?~^~-```~+~|+?uuz??zzzuz~``.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuy}?++?v?>??r}wz^~+vv?~`|wv^-.~>w?..>v?>+>zwzx+`->--}yyw?vvrxuuuuy^<br></br>
`}}r?}uwrvv+^?>?v|+>rzwwzwv?+~|?uuz. . >yu}+^^?rzxwwx?`~?|`vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~..^>?yr^>>xxzv}xxxzuuuu}    `uuuuuxxzzwzxr--v~.|zx}+^???~}uxwrw~<br></br>
`rrwr?|-|^|. `^?vyv+>?uuv~??vrzxxu>  .  ruxzwrrwwwzr~`>?`|}}>~vv^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz-.>>vzxxx. |z. >yuwrrrrrr}^->r>~^}r?>r?}?|wwrrrzu^<br></br>
-yzv}?>vzw|``-. .-wz?>>wzv ^>>zxu?  ry+ .xuwrrrrrw+-|xyxzuuv-.+^v>?zzwwwww~<br></br>
`xxzxxuyuuuz`....`rzr+>?wz^ ?}zxu- |yuw  vuzwwwwwv--}uuuuv- ...|xw>>rzwwwz|<br></br>
`zzzzzzzzzzz?-...|rxzv>+?z}`^rzu}  ruuu~ |uxwwww}-`+wzzr|...`...?x>+?xzzzz|<br></br>
`zxxzzzzzzzzxzv|>+.?zxv?vvx+|rxu^ |yuxuv .wuzwzu?.~wuz?`...~>....vuvvuxxxx|<br></br>
`zxxxxxxxxxuxuuxzv..^ru}|^zr?zur  ruzzxx` >yzwzz>+ryx?~... -.  .`rxxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr^-`+w?.^rrxu~ ^uzwrwu? .xxwwr>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxxxxuuuuuuxz>`-v>+vzuv  wuwwwwxz. >uzwzzxz+`|v}wwxxxxuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?|+r?vxu` +uzwwwwwu? .wuwwzx+..vuuuuxxxxuuuxxxuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuzwwrwu? .wzrrwwwrxu` |uzwwz??zuxuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzwwwzzzzwrrrrxu` >xrrrwwwrwxv  ruwrrzxxxxxxxxxxxxxxxxxxuuuuu|<br></br>
                  `rrrrrrrrrrrrwwwwwwwwwwwrrzr+ -wwrwzzzzwrwx| +zwrwzxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzzzzzwwrw|`>}rrwzzzzzwwrwv~`>wrwzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwrrrr}rrrrrwwwwwwwrrrrr}}rrrrwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}??????????>>>>>>>>>??>>???????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyx??yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyyyz}v???????v?v?>???vvvvvvvvv??????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????vvv}rrrr}vvvvv??v??????vvvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .?uzzrvvv?vvvvvvvvvvv}rwzwwwr}vvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwvv??vvvvvvv?}vvvvrwxxxxzzwr}v??ww}zxuyyyyu?r}|vv}v}vvvvvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}v}v}wxzrv?}wxwr}v?r}vxyyuuyuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^^^``~~-|^~```??+??rvv}}rxu}v-`>}xxwr}}vvvxyuuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^|~..->^^??+>vv}rzxuyxv-.>wyuxzwrr}ruzr}}}v-     |}vvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}wr++???v}wzv^|>>~~~-^>++?zzr}??>vvvv?.     ?}wxuuxuxwrr~<br></br>
`vv?????>??~`||~|?yuv???v}vrzv-.... ``.....`?xwrvvvvrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvv}}vv}v}?-||~~}v??vrzzwwxr~``-````-````->uxzzzwzuuyw`....>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???|`-^^?vv}wz>?zuuw+?~^~-```~+~|+?uuz??zzzuz~``.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuy}?++?v?>??r}wz^~+vv?~`|wv^-.~>w?..>v?>+>zwzx+`->--}yyw?vvrxuuuuy^<br></br>
`}}r?}uwrvv+^?>?v|+>rzwwzwv?+~|?uuz. . >yu}+^^?rzxwwx?`~?|`vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~..^>?yr^>>xxzv}xxxzuuuu}    `uuuuuxxzzwzxr--v~.|zx}+^???~}uxwrw~<br></br>
`rrwr?|-|^|. `^?vyv+>?uuv~??v}zxxu>  .  ruxzwrrwwwzr~`>?`|}}>~vv^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz-.>>vzzxx. |z. >yuwrrrrrrr^->r>~^}r?>r?}?|wwrrrzu^<br></br>
-yzv}?>vzw|``-. .-wz?>>wzv ^>>zxu?  ry+ .xuwrrrrrw+-|xyxzuuv-.+^v>?zzwwwww~<br></br>
`xxzxxuyuuuz`....`rzr+>?wz^ ?}zxu- |yuw  vuzwwwwwv--}uuuuv- ...|xr>>rzwwwz|<br></br>
`zzzzzzzzzzz?-...|rxzv>+?z}`^rzu}  ruuu~ |uxwwww}-`+zzzr|...`...?x>+?xzzzz|<br></br>
`zxxzzzzzzzzxzv|>+.?zxv?vvx+|rxu^ |yuxuv .wuzwzu?.~wuz?`...~>....vuvvuxxxx|<br></br>
`zxxxxxxxxxuxuuxzv..^wu}|^zr?zur  ruzwxx` >yzwzz>+ryx?~... `.  .`rxxxxxxxx|<br></br>
-xuuuuyuyyyyyyuuuyr^-`+w?.^rrxu~ ^uzwwwu? .xxwwr>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxxxxuuuuuuuz>`-v>+vzuv  wuwwwwxz. >uzwzzxx+`|v}wzuxuuuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?|+r?vxu` +uzwwwwwu? .wuwwzx+..vuuuuxxxxuuxxxxxuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxwwrwu? .wzrrwwwrxu` |uzwwz??zuxxuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzwwwzzzzwwrrrxu` >xrrrwwwrwxv  ruwrwzxxxxxxxxxxxxxxxxxxuuuuu|<br></br>
                  `rrrrrrrrrrrrwwwwwwwwwwwrrzr+ -wwrwzzzzwrwx| +zwrwzxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzzzzzwwrw|`>}rrwzzzzzwwrwv~`>wrwzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwrrrr}rrrrrwwwwwwwrrrrr}}rrrrwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}??????????>>>>>>>>>??>????????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyx??yyyyyuzv?vvvvvvvvv??vvvvvvvvvv?vvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyyyz}v???????v?v?+???vvvvvvvvv????vvv???????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????vv??vvvv}}rr}}}}vv??v??????vvvwyz}zyyyuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvv?vvvvvvrxw}}}rrwwwzxxzvv??>?vrwr}zxuyz??+vuyuz}}}?vvvv-<br></br>
-uuuxvvyyu+^|vwvv??vvvvv}}vruuxxzzzxxxuuxw}vvvrzxuyyyyyyu?r}|vv}v}vvvvvv?v-<br></br>
`}rw}??r}v??++++^^||?v??rzr}ruuuuuxwvvwxxwrwxxyyuuyyuuyuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^^^``~~-|^~```??+?vzv}zuuuyzv~-?zuxxuuuuuxuyuuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~~..->^^??+>vv}wxyyyxv-`}xuuuuuuuzwuzr}}}v-     |}vvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}wr++vrvv}wz}}uux>~?}??+>zuuzr??>vvvv?.     ?}wxuuxuxwrr~<br></br>
`vv?????>??~`||~|?yuv??vzxwrzr~`?xx}??-...+zuuzw}}v}rwwv.    .vuyyyyyyyyyy^<br></br>
`vvvv}}vv}v}?-||~~}v??vrzuuuuw~`-?zxw>```~wxuuuuxzwwzuyr`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???|--^^?vv}wx>?xyyxv?|+}xxzv?>vuuuyyz??xwzuz~``.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuy}>++?v?>??r}wx>|+?}xxzwuuxuuzzxuuxuxv>+vzwzu+`->--}yyw?vvrxuuuuy^<br></br>
`}}r?}uwrvv+^?>?v|+>rzwwzz}?vxuuyuw-?x+?yyuuu}}zxzwwx?`~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~..^>?ur^>>xxzv?}rzuyuuur  --vyyyuxxzzwzzur--v~.|zx}+^???~}uxwrw~<br></br>
`rrwr?|-|^|. `^?vyv+>?uuv.|+?zuuuu?```?xuyuwwrwwzxxz^`>?`|}}>~vv^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux^~vvzuuux`~}zvxuuxwwzzzxxz>~>r>~^}r?>r?}?|wwrrrzu^<br></br>
-yzv}?>vzw|``-. .-wz?>>wzr-??vxuuzvzuuwzvxxwzzxxzz>-|xyxzuuv-.+^v>?zzwwwww~<br></br>
`xxzxxuyuyuz`....`rzr+>?wz?vxzzxx>wuuuxr`}uzwwwwwv--}uuuuv- ...|xw>>rzwwwz|<br></br>
`zzzzzzzzzzz?-...|rxz}>>wuuuxzzuv>zuuuu? +uxzzzz}-`+zzzr|...`...?x>+?xzzzz|<br></br>
`zxxzzzzzzzzxzv|>+.?zxvvuuyuuxuu+^vyuuu? .zuuuuu?.~wuz?`...~>....vuvvuxxxx|<br></br>
`zxxxxxxxxxuxuuxzv..^ru}^>xxxuuu}?wuuzxx`.vyuuuur?wyz?~... -.  .`rxxxxxxxx|<br></br>
-xuuuuyuyyyyyyuuuyr^-`+w>.^wzuyr?vuxzzzuwrzyuuuuuuuy}^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxxxxuuuuuuuz>`-v??wxy? .wxwwzzxuxrvyxzxuuxv||v}wzuxuuuuuxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuuu?|+wv}xz||vuwwwzzxuz^.zuwwzx+..vuuuuxxxuuuxxxxxuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxwwrwuzwzuzwwwwzzzu- ^uxwwz??zuxxuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzzwwwwzzwwwrrwuuxzxzrrrwwwrrxv.|zuzwwzxxxxxxxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwwwwwwwwwwwrrz?^?}zwrwzzzzwrwx+.~}zwwzxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzzzzzwwrw>+vwwrwzzzzzzwrwr>^?wrwzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwrrrrrrrrrrwwwwwwwrrrrrrrrrrwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}??????????>>>>>>>>>>>>>???????????????>vwwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vv}}vvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyyyz}v????vv???v?+vwzzwrvvvvvvvv???vv???????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv???vvvv?vv?rxxxzzwr}vvrw}v??vvv?????vvwyz}zyyyuuurv+?}xu|<br></br>
-uuuuuyyz| .?uzzrv???vv}v?v}xxxxxwrrrrrwxxrv?v}}}rzwrzxuyz??+vuyuz}}}?vvvv-<br></br>
-uuuxvvyyu+^|vwvvvvvvvvv}}vrxuuuxwwwzzxuuuzw}wxuuyyyyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^+??vv??}w}}ruyyuzwwwrwzxxxzxuuuxuyyyuyuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^^^``~~-|^~->?v?^?vwv}xyyuzrr>-~?zxuuxxzzwxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~~..`>??}v>+??}zuyuxzv-.+}zxuxzzzwruuxzr}v-     |}vvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}w}>?wzv?}zzwxuxw>`~+>??vwzzzr?rrzrvv?.     ?}wxuuxuxwrr~<br></br>
`vv?????>??~`||~^?yuv???wuwwur^?zx?^`-...-^?zzwwzzzzwwwv.    .vuyyyyyyyyyy^<br></br>
`vvvv}vvv}v}?-||~~}v?>??vwwzuw~~vz}?+-`..-+?wxxuuuxxuuyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???|--^^?vvvvv^>?vxx>->wz}>+|~+^>?zuyuwruuuyz~``.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuy}>++?v?>??rrrz?>>>??>+vuuuv?>vwv>?wuzr}wuxuu+`->--}yyw?vvrxuuuuy^<br></br>
`}}r?}uzrvv+^?>?v|+>rxzxxw?^^?vwxxzvrv`?xxzzuxzxuuuuy?`~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~..^>?yr^>?uuu?`~`^zxxxx}`|?-?uuyuuxuuuuuur--v|`|zx}+^???~}uxwrw~<br></br>
`rrwr?|-|^|. `^?vyv+>vyyv.`..?uuuy?.`>}ruuuzwwzzzxxx>^vr??r}>~vv^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>zyx|.^`?yuuz`.vw}xuxxzwwwzxxzv>vz?^^}r?>r?}?|wwrrrzu^<br></br>
-yzv}?>vzw|``-. .-wz?>?zxr.^+|xxur|?xx}vvxxzzzzzzz>-~xyxzuuv-.+^v>?zzwwwww~<br></br>
`xxzxxuyuyuz`....`rzr+>vxu?~vrxxz>>wuuw>`ruxzzwwz?--}yuuuv- ...|xw>>rzwwwz|<br></br>
`zzzzzzzzzzz?-...|rxz}+?xuy}?xxu}~?uuux> >uxzxxxw+|>xzzr|...`...?x>+>xzzzz|<br></br>
`zxxzzzzzzzzxzv|>+.?zxvvuuyu}wuu+-}yuuu? `zuuuuuw??zuz?`...~>....vuvvuxxxx|<br></br>
`zxxxxxxxxxuxuuxzv..^wx}^>xuxzuz^>zuuxxz- vuuuuuwwxyuv^..  -.  .`rxxxxxxxx|<br></br>
-xuuuuyuyyyyyyuuuyr?>>vz>.~z}vxr^}uuxxxur+vuuuuuuyyyx}vv>`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuuuuzv??}?>vw+^.`zuxxxxuu}?ruuuuuuyxr}wxxxuxuuuuuxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuuurv}xv?u}~-}uxxxxxuuur?xuxxuu?++ruuuuxxxuuuxxxuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuxxwzu}?wuxxxxxxxuu>`?uxzzz?>zuxxuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzwwwzzzzzwwwwuz?>wzzzzzzzzzur~+wuzzzxxxxxxxxxxxxxxxxxxxuuuuu|<br></br>
                  `rrrrrrrrrrrrwwwwwwwwwwwwwz?`~?zzzzzzzzzzzx+ `vzzzxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzwww>+vzwwwzzzxzzzwww?^?wwzzzzzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwwwrrwwwrwwwwwwwwwwwrrwwwrrwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?????????vrrrrrrrrr}}vv?>?????????????>?wwwwrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vv}rvvvzuuxwwzzxxuuxx}?v}}}w}vwwvv???}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyuuz}v??vvvv?vzuuvv}}rrrrwxxrvv}vvvvwwvvvv}rzuyyyuuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxvv??vvvvzuxvvrrrr}}v}zwv}vv??zxzrwzzxxuuyuuuyuuuuurv+?}xu|<br></br>
-uuuuuyyz| .?uzxuz}vvvvvvzuxrrrrr}vvvvv}}}vv?ruuxxzzzxuuyxxuxuuyuz}}}?vvvv-<br></br>
-uuuxvvyyu+^|vwvzuxwvvvvvzuxrrrr}vvvvv}rrwwr}wuxzxuuyyyyu?zuxxrvv}v??vvv?v-<br></br>
`}rw}??r}v??++>+?}xuzv??vwxxwwrr}}vvrwwxuuuuxwwr}xyyyuyuz}}zzzzww}vvvvvvvv-<br></br>
`vvv??>^^|``~~+??++ruxv+?vxuzwrrrwrwzzwrzxxuuxw}vxyyuuz}?^}wwzzuuwvvvvvvvv-<br></br>
`vv??v|.-|~||^||||~>vrxr++vxxwwwwzxx}^+?v}rrzxxr}uxzwr}v~>wwwwzuw}vvvvvvvv-<br></br>
`vv>?vv^..```````+}r}+?rw??wuzv???wv|``+>>>?}zxw???rvvvv?wwww}wwwxuxxuxwrr~<br></br>
`vv?????>??~`|^>vwyuv>>^vr?}yz>>>++~``-|^++|?zxwv}rrwwwwwwrv^-vuyyyyyyyyyy^<br></br>
`vvvvvvvv}v}?-||^vuxwv?>^v?vxuv~^||~`.~~--`.?xxzwwxuyyyu}?|`.>yyyyyuuyyyyy^<br></br>
`vvvvvvwvr???|`->}xuuuz}?++^}ux?|+~-`.``-.`~}uxr?}uyyyurvv+~-wyyyuuyuuuuuy^<br></br>
`}}}}}zyuy}>++?}zxrvwwrvvv>^?vrrv}?|```+v>^?zx}v}wyyyyxrvv--}yyw??vrxuuuuy^<br></br>
`}}r?}uwrvv+^?}uuv>+rxzw-|``->vxxz}... >rrrxxwzuyyyyyw?^?~`vyyr???v+}yuuuu|<br></br>
`}rr}??+??~. >zxyr^>?uyy> . .vwzuuv    ~wzuuuuyyyyuyw~-v~`+uuxrzxw}?wuxwrw~<br></br>
`rrwr?|-~^|. +xuuyv+>vyyv.`  +uuuyz|.` .rzzzzuuuxxuw|~vxrwxxwrxw>?>>rrrrrz|<br></br>
`zxw?>?..`|. ^xzrruv>>wux~ -.^yuux}wr}..vxxxxxzwwww+~?x}??rw?>}?}?~rwrrrzu^<br></br>
-yzv}?>vzw|``^rww?wz?>?zu} ~|`zwz>.vuu}vruxxzzzzzz>`~xyzzxuv~->^v>?zzwwwww~<br></br>
`xxzxuuyuyuz`.?wwvrzr>vxyu?-v}zzxv>ruuuv`}uxxxxxux}vwyuuuv~-|>>?zw>>rzwwwz|<br></br>
`zzzzzzzzzzz?--}wzxzz}?vruu>^xuy}``wxxx}?vzzzzzxw?vzyuux>...`...?x>+?xzzzz|<br></br>
`zxxzzzzzzzzxzv?zxwzzxv?}}u> ?yu| >uxxx?`?zxxuuuv-^zyuwwv>-~>....vuvvuxxxx|<br></br>
`zxxxxxxxxxuxxuxxxzw}zx}|^xw`.zv .wuxxxw. ?uuuuz>?wyx?>vwwrv^. .`rxxxxxxxx|<br></br>
-xuuuuyuyyyuuuuuuuuuuuzz>.-x> >- >yxxxxu> .wxxxw?vuu}>?vzxxux}?vzuxxxxxxxx|<br></br>
-xuuuuuuxxxxxxuuuuuuuuxvr>->}.  .zuuuxxxz` >uuuuxuuv>vrzuuyyyyyuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuuuyuur?uv. ?yuuuxxuyv.`zuuuuuxwzuyyyyyyyyuuuxuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuxuuxxuuuuyuuuz}ruuuuuuuuuu}vzyuuuuuuyyyyyyyuuuuuxuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwzzzzzzzzzxuuuz^|wxuuuuuuuuuyuzxuuuuuuuuuuuuuxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwzzxxzwwwzzzzxr}v?zxuuuxxxxxxx?`~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
                  `rrrrrrrrrwwwzxxxzzzzzzzzzz??zxxxuuxxxxxxxzz?+?zzzzzxzzzxzzzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrrwwwzzwwwwwwwzzwwwwwzwwwzxuxzzxzwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrwzrrrrrr}}}}rzxwrrww}}}}}}}}}}}}}rrwr}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>?vv????v}}}vvvvvvvvv????????????????>?rrrrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vwzrvvvzxzz}v}rrzzzzwvvvvv}}vvrrvv?vvryyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyuuz}v?vwz}v?vwxz????vvvvrzxwrrwwwr}wwvvvv}rwuuyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxvvrz}vvvwxw???vvvvv}wxz}}}}vrxuwrwwwzzzxyuzuyuuyyuw}+?}xu|<br></br>
-uuuuuyyz| .?uzxxw}}zrvvvwxzvvvvvvvvvvvvvvvv?vwxxzwrwxuuyzrz}zuyuxwr}?vvvv-<br></br>
-uuuxvvyyu+^|vwvrwwrww}vvwzzvvvvvvvv??vvvvv}v}xzwxuuyyyyu?wxvzrvvrrv?vvv?v-<br></br>
`}rw}??r}v>??????vv}wwv?v}ww}vvvvvvv}}}wzzzzw}wrvxyyuuyuz}???vw}wzrvvvvvvv-<br></br>
`v?v??>^^^|>vvv}v?>?vzr>?vwwrvvvvrvwwzzrwwwzzzrvvxyyuuz}?|+>>??zux}vvvvvvv-<br></br>
`vv??v|.^?vv>>||~~~>>vzr++vrw}vvvrw}?^>?vvv}rzz}}uxwr}}v-~>>>>vuxrvvvvvvvv-<br></br>
`vv>?vv???^-`````+}r}+?w}??rxr?>+>v>||->>>>?vwzr>?>vvvvv^?>>>+}wzxuxxuxwrr~<br></br>
`vv???vr}v?~`|^+?}yuv>>+v}>vy}|++++|-|~^+++^>wzr?vvvrwz}>?>|``vuyyyyyyyyyy^<br></br>
`vvvv}rrr}v}?-||^>zwv??>+}??zw^--`~|``~|---`>zzr}}zuyyyz>^-`.>yyyyyyuyuyyy^<br></br>
`vvvvvrxvr???|`->?rzwz}????^vz}?-~`-``-```^+vzzv>vuyyyx?+^-~~wyyyuuyuuuuuy^<br></br>
`}}}}}xyuy}>++?}r}vvwwv+^??+?vrrvv?-`-`^v>?vrr??vwyyyyv>>?--}yyw??vrxuuuuy^<br></br>
`}rr?}yzrvv+^??wz?>>wxzr.-``|?vzzzv  . +rrrwwvwuyyyyy}^|?~-vyy}??>v>ryuuuu|<br></br>
`rwr}??vv?~..>vryr^>?uyy+ . `}rwxxv    -wzuuxuyyyyyyw~-v||vuuzvvww}?zuxwrw~<br></br>
`wwwr?^+++|. ~}wwyv+>vyyv.` .?uuuy?..` .rzzwwzxxxxxw~-?xwxxx}?ww>?>>rrrrrz|<br></br>
`zxw?>?`~-|. -?v?}uv>+rux- -.+yuux|~?r. ?xzzzzwwrrr+^vuw??rw?>r?}?~rwrrrzu^<br></br>
-yzv}?>vxw|``|+??+wz?>>zu} ||`zzx? ~zu>-^xxzzzzzzx}>?uyxzxuv-`>^v>?zzwwwww~<br></br>
`xxzxuuyuyuz`.|>??rzr>?}uu>.?vzzxv>?xuw~.ruxxxxxuzv?wyuuu}~``||+zw>>rzwwwz|<br></br>
`zzzzzzzzxxz?-`^>vwxz}>?vxz-~xuyv.~zxxx?+>zzzzzzr||vuuuz>...`...?x>+?xzzzz|<br></br>
`zxxzzzzzzxxxzv>}}?}zxv?}vu^ ?yu| +uxxx?.`zxxxxuv.~wyxv?+|.~>....vuvvuxxxx|<br></br>
`xxxxxxxxxuuuuuxxw???wx}|^xr..z} .wxzzxw. ?uuuux>+ryx?|^?>+>~  .`rxxxxxxxx|<br></br>
-xuuuuuyyyyuuuuuuyx}??vw>.-x> +- >uxxxxu> .zxxxw>?xuv^>+?>vzwv?vzuxxxxxxxx|<br></br>
-xuuuuuuuuuuuuuuuuuux}?^v>`>}.  .zuuxxxxz` >uxxxxux+`+vrzxuuuyuuuuuuuuxuxx|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuuwvvz?+u?  ?yuuuuuuy? .wuuuuuv++ryyyuuuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuuuuuuuuuuuuuuuuuxxu?`^xuuuuuuuuu^`>uuuuurruyuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzzzzzzzzzzzzxxuw-.vxxxxxxxxxuw>>wuuxxuuuuuuxxxxxxxxxxuuuuuuxu|<br></br>
`rrrrrrrrrrrwwwzzzzwwwzzzzzv?>?zxuuxxxxxxxx>`~}xxxxxxxxxxxxxxxxxuuuuuxxxxu|<br></br>
                  `rrrrrrrrrwwwzzzzzzzzzzzzzz??wxxxuuxxxxxxxzz?+?zzxxxxxxxxxxxxxxxzzzzzzzzzz|<br></br>
`}rrrrrrwwwzzzwwwwwwwzzwwwwwzwwwzxxzwzzzwwwwwwwwwwwwzzzzwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrwwrrrrrrrrrrwwrrrrrr}}}}rwwrrrww}}}}}}}}}}}}}rrrr}}}}}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>?v???????>>>>>???vvv????????????????>?rrrrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyyzv?vwrvvvvvv??vvvvvr}}vvvvvvvvvvvvvvvvvvryyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw  |xyuux}v?vrrv????v?>??vv}vvvvvv}}}}}vvvvvvv?vvvxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}` +uyyuyxr}rwvvv??v???v?vvvvv}}}}}vvv}r}vvv??vvvwyz}zyyyyyuw}+?}xu|<br></br>
-uuuuuuyxv>+vuxuwrrwzwvvvvvvvvvvvvvvvvvvvvv????vr}vvrzxuyz??+vuyuxwr}?vvvv-<br></br>
-uuux}wyyy>+|vzwv??vwzrvvv?rvvvvvvvv???vvvvv?vwwrxxuyyuuu?rr|vv}vrrv?vvvvv-<br></br>
`}rwr}zz}v>??????+||vwv?vv?r?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vr}vvvr}vv-<br></br>
`vvvwz?^^^|+??>??>|`-?v>??rvvvvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?}rvvvvvvvv-<br></br>
`vv}rr|.^???>>|~`.->^+vv+^??vvvvvv??^|>?vvvvvvvv}uxw}}r}-     |}r}vvvvvvvv-<br></br>
`vv?vv?^^+|-```..+}w}+>vv?>v}v?>+>>|||->>>>?vvvv>?>vvv}v.     ?rzxuxxuxwrr~<br></br>
`vv???vvvv?~`|||^?yu?>>+v}+vuv~++++|~|~++++^>vvv?vvvrzzv.    .vuyyyyyyyyyy^<br></br>
`vvvv}rwr}v}?-|||~}v?^>>+}?>}v.`-`~|``~|---`>}vv}rzuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvrxvr???|--++?vvv?>>>?^?v>>-~`-``-```^+?vv?>?uyyyz|-`.-~wyyyuuuyuuuuy^<br></br>
`}}}}}xyuy}>++?v?>??rr?|~>?+?????v?-`-`^v>??????vwyyyy+-->--}yyw??vrxuuuuy^<br></br>
`}rr?}yzrvv+^?>?v|+>wuzr.```|??v}}v  . +r}vvvvwuyyuyy?-~?|-vyy}+>>v+}yuuuu|<br></br>
`wwr}???v?~..^>?yr^>?uyy+ . `vrrrw?    -rrxuxuyyuuuyw~-v~`>ux}++}w}^ruxwrw~<br></br>
`wzwr?^^++|. `^?vyv+>vyyv.`  ?uuuy+ .` .vrrrrwzzxxxw~-?}>?wr>~vz}v+>rrrrrz|<br></br>
`xxw?>?`--|. .|~.vuv>+rux~ -.+yuux. +}  >wrrrrrrrrr+|?zv++}}v}xrw?~wwrrrzu^<br></br>
-uu}}?>vzw|``-. .-wz?>>wx}.||`zzx? .}z| `zzwwwwwzz?^>uyxzuyxv??^v>?zzwwwww~<br></br>
`xxxxuuyuyuz`....`rzr+>?zx?`>vwzz- +zxv  ruuxzzzxv~~}yuyyw?|...|xw>>rzwwwz|<br></br>
`zzxxzzzzxxz?-...|rxz}>+?zw+?xxuv .wxxz` ^xzzzzzr~->xxuw^...`...?x>+?xzzzz|<br></br>
`zxxxzzzzzxxuzv|>+.?zxv?}vx^.vuu| >uxxx> .wzxxuuv.~zux?`...~>....vuvvuxxxx|<br></br>
`xxxxxxxxxuuuuuxwv..^wu}|^zr..z} .wxzxxw. ?uuuux>+ryx?~... -`  .`rxxxxxxxx|<br></br>
-xuuuuuyyyyuyyuuuyr^-`+w?`~x> +-.>uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuxuuuuuuuuuuuuxz?`-v?|>}.. `xuuuxxxz` >uxxxxxx+`^v}wzuuuuuuuuuuuuuuxx|<br></br>
`xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?yuuuuuuuv .zuuuuu>..vuuuuuuuuxuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuuxxxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzzzzzzzzzzzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxuuuuuuxu|<br></br>
`rrrrrrrrrrrwwwwwwwwwwzzzzz?-`>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxuuuuuxxxxu|<br></br>
`rrrrrrrrrwwwwwwzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzxxxxxxxxxxxxxxxzzzzzxxxxx|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwzzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzzxzzww|<br></br>
`}rrrrrrwrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}rrrwwwwzzwwrr}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}???????????>>>>>???vvv????????????????>vrrwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvv}rr}vvvvvvvvvvvvvvvvvv}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyuxrv?????????v?>???}rvvv???vvvv??vvvvvv???vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}`.?yyyuyuwr}v?????v???vvvvv??vvv??vv??v?vvvv?vvvwyz}zyyyyyurv+?}xu|<br></br>
-uuuuuuyxxx}vuxuzwwzzrvvvvvvvvvvvvvvvvvvvvvv?v>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuux}xyyy?+|vzz}??v}r}vvv?rvvvvvvvv???vvvvv?vwz}zxuyyuuu?rr|vv}v}v??v}}vv-<br></br>
`}rwrzux}v>?>++??+||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvrrvv-<br></br>
`vv}xuv+^^``~~-^>|``-??+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vvwxw|.-|-||^~-..->^^?v++??vvvvvv??^|>?vvvvvvvv}uxw}}r}-     |}vvvvvvvvvv-<br></br>
`vv?vv?~..`````..+}w}++>??>v}v?>+>>|||->>>>?vvvv>?>vvvrv.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?}+vuv~++++|~|~++++^>vvv??vvrzzv.    .vuyyyyyyyyyy^<br></br>
`vvvv}vvv}v}?-|||~}v?^>>^v>>}v.`-`~|``||---`>}vvv}zuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???|--++?vvv?>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuy}>++?v?>??rr?|~>>+?????v?-`-`^v>??????vwyyyy+-->--}yyw??vrxuuuuy^<br></br>
`}rr?}yzrvv+^?>?v|+>wuzr.```~??v}}v  . +r}vvvvwuyyyyy?-~?|-vyy}+>>v+}yuuuu|<br></br>
`wwr}??+v?~..^>?yr^>?uyy+ . .vrrrw?    -rrxuxuyyyuuyw~-v-.|zx}++rx}~}uxwrw~<br></br>
`wzwr?|-|+|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~vxz}+>rrrrrz|<br></br>
`xxw?>?..-|. .|~.vuv>+rux~ -.^yuux. +}  >wrrrrrrrrr^->r?|^}}}wuww?~wwrrrzu^<br></br>
-uur}?>vzw|``-. .-wz?>>wxw`||`zwz> .}z| `zzwwrrrww+-|xyxzuyyx}?^v>?zzwwwww~<br></br>
`xxxxuuyuuuz`....`rzr+>?zxw+?vwww` +zxv  ruuxwwwzv--}yuyyx}>`..|xw>>rzwwwz|<br></br>
`zzxxzzzzzzz?-...|rxz}>+?zz}wuxuv .rzzz` ^xzzwwzr~^?xxuw+`..`...?x>+?xzzzz|<br></br>
`zxxzzzzzzzxxzv|>+.?zxv?}vx^-}uu| >uxxx> .wzxxuuv`^xux?`...~>....vuvvuxxxx|<br></br>
`xxxxxxxxxuuxxuxwv..^wu}|^zr..z} .wxzxxw. ?uuuux>+ryx?~... -`  .`rxxxxxxxx|<br></br>
-xuuuuuyuyyyyyuuuyr^-`+z?-|x> +~`?uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuuuxxxuuuuuuuuxz?`~vv+>}.. -xuxuxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
`xuuuxuuuuuuuuuuuuuuuu?|+w|~u?  ?yuuuuuuuv .zuuuuu>..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuxuuuuuuuuuuuuuuuuxxuzxu+ `xuuxxxxxuu- |xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwzzzzzzzzxr`.?zzzzzzzzzxv. vxzzxxuxxxxxxxxxxxxxxxxuuuuxu|<br></br>
`rrrrrrrrrrrwwwwwwwwwwzzzzz?-`>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwwzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzxxzxxzxzzzzzzzxxxxx|<br></br>
`}rrrrrrrwwwwwwwwwwwwwwwwzzxxzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzxxxxxzww|<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrwwwwwrr}}}}}}}}}}}}}}}}}}}}}}}}rwwwzzzzzzzwr}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwrwrwv?????????>????>>?>??vv????????????????>vrrwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx?vyyyyyuzv?vvvvvvvvv}vvvvvv}}}vvvvvvvvvvvvvvvvvv}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw. |xyyuxrv????????v}?+???v}vvv???vvv???vvvvvv?v?vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}` |zyyyyxr}vv????vv???v?vvv??vvv??vv??v?vvvv?vvvwyz}zyyyyyurv+?}xu|<br></br>
-uuuuuuyx?-~vuxxwrr}}vvvvvvvvvvvvvvvvvvvvvvv?v>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuux}wyyu>+|vzw}??vv}vvvv?rvvvvvvvv???vvvvv?vwz}zxuyyuuu?rr|vv}v}v??vvvvv-<br></br>
`}rwr}wz}v??>++>?^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvv}}vv-<br></br>
`vvvrw?^++``~~`|+|``-??+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vvvrr|.|>~||^~~..->^^?v++??vvvvvv??^|>?vvvvvvvv}uxr}}}}-   . |vvvvvvvvvvv-<br></br>
`vv?vv?~.|-````..+}w}++>??>v}v?>+>>|||->>>>?vvvv>?>vvv}v.  .- ?}wxuxxuxwrr~<br></br>
`vv?????>?v~`|||^?yu?>>|?}+vuv~++++|~|~^+++^>vvv??vvrzzv.  ``.vuyyyyyyyyyy^<br></br>
`vvvvvvvv}}}?-|||~}v?^>>^v>>}v.`-`~|``||---`>}vvv}zuyyyw`..^`>yyyyyyyyuyyy^<br></br>
`vv}vvvzvr???|`-++?vvv>>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`-~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??rr?|~>>+?????v?-`-`^v>??????vwyyyy+-->~-}yyw??vrxuuuuy^<br></br>
`}rr?}ywrvv+^?>?v|+>wuzr.```~??v}}v  . +r}vvvvwuyyyyy?-~?|-vyy}+>+v^}yuuuu|<br></br>
`wwr}??+v?~..^>?yr^>?uyy+ . .vrrrw?    -rrxuxuyyuuuyw~-v-.|zx}++}}v~}uxwrw~<br></br>
`wzwr?|~|+|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~vzvv+>rrrrrz|<br></br>
`xxw?>?..-|. .|^.vuv>+rux~ -.^yuux. +}  >wrrrrrrrrr^->r?|^}rvvx}r?~wwrrrzu^<br></br>
-uu}}?>vzw|``-.`--wz?>>wx}.||`zwz> .}z| `zzwwrrwww+-~xyxzuyw?+?^v>?zzwwwwz~<br></br>
`xxxxuuyuuuz`....-}zr+>?zx?.>vwww` +zxv  ruuxwwwzv--}yuyyr>`...|xr>>rwwwwz|<br></br>
`zzxxzzzzzzz?-...|rxz}>+?zw|^xxuv .rzzz` ^xzzwwzr~->uxuw^...`...?x>>?zzzzz|<br></br>
`zxxxzzzzzzzxzv|>+.?zxv?}vu^.vuu| >uxxx> .wzxxuuv.~zux?`...~>....vuvvuxxxx|<br></br>
`xxxxxxxxxuuxxuxwv..^wu}|^zr..z} .wxzxxw. ?uuuux>+ryx?~... -.  .`rxxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr^-`>z?`~x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuuuxxuuuuuuuuuxz?`~v}+>}.. `zuuuxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuuu?|+w+^u?  ?yuuuuuuuv .zuuuuu>..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuuxxxxxuu- |xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzwzzzzzzwwwzzzzzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxuuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-`>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxuxxxxu|<br></br>
`rwrrrrrrrrwwwwwzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzxxzxxzzzzzzzzzxxxxx|<br></br>
`}rwwrrrrwwwwwwwwwwwwwwwwwzzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwzzzzzzzww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrrrwwwwwwwrr}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwrrrw}????????????v?>>??????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx?ryyyyyuzv?vvvvvvvv}w}v?vvvvvvvvvvvvvvvvvvvvvvvv}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw..|xyyyz}v????????vrv+??????????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxvv??????vv???v????????????v??v?vvvv?vvvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?rvvvvvvvv???vvvvv?vwz}zxuyyyyu?rr|vv}v}vv?vvvvv-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^>>``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.+v~||^~-`.->^^??+^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-   . |vvvvvvvvvvv-<br></br>
`vv>?v?~.?~````..+}w}++>??>vrv?>+>>|||->>>>?vvvv>?+vvvv?.  `v.?}wxuxxuxwrr~<br></br>
`vv?????>v}~`|||^?yu?>>|?}+vuv~++++|~|~++++^>vvv??vvrwzv.  >?.vuyyyyyyyyyy^<br></br>
`vvvvvvvv}}}?-|||~}v?|>>^v>>}v.`-`~|``||---`>}vvv}zuyyyw`.-}->yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???|`-++?vvv?>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`>+-wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?|~>>+?????v>-`-`^v>??????vwyyyy+-->^-}yyw?vvrxuuuuy^<br></br>
`}rr?}yw}vv+^?>?v|+>wuzr.```~??v}}v  . +r}vvvvwuyyyyy?-~?|`vyy}+>+?|}yuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .vrrrw?    -rrxuxuyyuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rwwr?^~|+|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..-|. .^>.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r?~^}r??r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-.^^-wz?>>wzv ||`zwz> .}z| `zzwwwrrww+-|uyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxxuuuuuz`...~|}zr+>?wz^ >vwww` ^zxv  }uuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rxz}>+?zr.`zxuv .rzzz` |xzwwwzr-`+xzzr^...`...?x>>?zzzzz|<br></br>
`zxxzzzzzzzzxzv|>+`?zxv?}vu^ ?yu| >uxxx> .wzxxuuv.~wux?`...~>....vuv}uxxxx|<br></br>
`xxxxxxxxxuuxxuxwv..^wu}|^xr..wv .wxzxxw. ?uuuuz>+ryx?~... -`  .`rxxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr^-`?x?`-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuuuxz?`~v}^>}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
`xuuuuuuuuuuuuuuuuuuuu?|+w>>u?  ?yuxxuuuuv .wuuuuu+..vuuuuuuuuxxuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuuxxxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzzwwwwzzzzzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxuuuuxu|<br></br>
`wrrrrrrrrrwwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rwwrrrrrrrwwwwwzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzxxzxzzzzzzzzzzzzzzz|<br></br>
`}rzzwrrrwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrwwrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrw}??????????????>????????????????????????vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx?vyyyyyuzv?vvvvvvvvv}vvvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuuw. |xyyyz}v?????v}vv}v+??????????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????vv?vv????????????????v??v?vvvv?vvvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?rvvvvvvvv???vvvvv?vwz}zxuyyyyu?r}|vv}v}v??vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^>+``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.|>~||^~~..->^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-   . |vvvvvvvvvvv-<br></br>
`vv>?v?~.|-````..+}w}++>??>vrv?>+>>|||->>>>?vvvv>?+vvvv?.  .- ?}wxuxxuxwrr~<br></br>
`vv?????>?v~`|||^?yu?>>|?}+vuv~++++|~|~++++^>vvv??vvrwzv.  ``.vuyyyyyyyyyy^<br></br>
`vvvv}}vv}}}?-|||~}v?|>>^v>>}?.`-`~|``||---`>vvvv}zuyyyw`..^`>yyyyyyyyuyyy^<br></br>
`vvvvv}zvr???|--++?vvv?>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`-~-wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?vv>??}}?|~>>+?????v>-`-`^????????vwyyyy+-->~-}yyw?vvrxuuuuy^<br></br>
`}rr?}yw}vv+^?>?v|+>ruzr.```|??v}}v  . +r}vvvvwuyyuyy?-~?|-vyyv+>+?|vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .vrrrw?    -rrxuxuyyuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rwwr?^-|^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..-|. .^^.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^`>r?~^}r??r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-.`--wz?>>wzv ||`zwz> .}z| `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxxuuuuuz`...`~}zr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rxz}>+?zr.`zxuv .rzzz` |xzwwwzr-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxxzzzzzzzzxzv|>+`?zxv?}vu^ ?uu| >uxxx> .wzxxuuv.~wux?`...~>....vuvvuxxxx|<br></br>
`xxxxxxxxxuuxuuxwv..^wu}|^xr..wv .wxzxxw. ?uuuuz>+ryx?~... -`  .`rxxuxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`>z?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuuuxxuuuuuuuuuxz?`~v?~>}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?|+w+^u?  ?yuxxxuuuv .wuuuuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuuxxxxxuu- ~xuuuu??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzzwwwwzzzzzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxuuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rwrrrrrrrrwwwwwzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzxxzxzzzzzzzzzzzzzzz|<br></br>
`}rwwrrrrwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>?????>??????????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuyw  |xyyyz}v?????vwv?v?>???v??????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????vv??v????????????????v??v?vvvv?vvvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvv?vvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv?vwz}zxuyyyyu?r}|vv}v}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^^^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~~..->^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..`````..+}w}++>??>vrv?>+>>|||->>>>?vvvv>?+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?}+vuv~++++|~|~++++^>vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvv}}vvvv}?-|||~}v?|>>^v>>}?.`-`~|``||---`>vvvv}zuyyyw`..``>yyyyyyyyuyyy^<br></br>
`vvvvvrzvr???|--++?vvv?>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.-~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?vv>??}}?|~>>+?????v>-`-`^????????vwyyyy+-->--}yyw?vvrxuuuuy^<br></br>
`}rr?}yw}vv+^?>?v|+>ruzr.```|??v}}v  . +r}vvvvwuyyuyy?-~?|-vyy}+>+?|vyuuuu|<br></br>
`rrr}??+??|..^>?ur^>?uyy+ . .vrrrw?    -rrxuxuyyyuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^`>r?~^}r??r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwww~<br></br>
`xxzxuuuuuuz-....`rzr+>?wz| >vwww` ^zxv  }uuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzxv-...|rxz}>+?zr.`zxuv .rzzz` |xzwwwzr-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxxzzzzzzzzxzv|>+`?zxv?}vu^ ?uu| >uxxx> .wzxxuuv.~wux?`...~>....vuvvuxzxx|<br></br>
`xxxxxxxxxuuxuuxwv..^wu}|^xr..wv .wxzxxw. ?uuuuz>+ryx?~... -`  .`rxxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuuuxxuuuuuuuuuxz?`-v+.+}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?yuuxxxxuv .wuuuuu+..vuuuuuuuuuuuuuxxxxxuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuuxxxxxuu- ~xuuuu??zuuuuuuuuuuuuuuxuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwzzzwzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wwrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxx>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzxxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>???>>>??????????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>vyyyyyuzvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuu^<br></br>
-uuuuyw  |xyyyz}v?vvv?v}v?v?>??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????vv???????????????????v??v?vvvv?vvvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvv??v??vvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvuyu++|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vv}v}}v?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vvvvvvvvvv-<br></br>
`vvv??>^^^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}w}+^>?>>vrv?>+>>||^->>>>?vvvv>?+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv~++++|~|~++++^>vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvv}}vvvv}?-|||~}v?|>>^v>>}v.`-`~|``||-`-`>vvvv}zuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvv}zvr???|--^^?vvv>>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?|~>>+?????v>-`-`^?>??????vwyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rrv}uw}vv+^?>?v|+>ruzr.```|??v}}v  . +r}vvvvwuyyyyy?-~?|-vyy}>>^?|vyuuuu|<br></br>
`rrrr??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuxuyyyuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>?yyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^`>r?~^}r?>r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwww~<br></br>
`xxzxuuuuuuz-....`rzr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxxzzzzzzzzxxv|>+.?zxv?}vu^ ?yu| >uxxx> .wzxxuuv.~wux?`...~>....vuvvuxzxx|<br></br>
`xxxxxxxxxuuxuuxwv..^rur|^xr..zv .wxzxxw. ?uuuuz>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuuyyuyyyyuuuyr+-`+w?.-x> >- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzxxxxxxxxx|<br></br>
-xuuuuuuuxuuuuxuuuuxz?`-v+.+}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uuuxxxxuv .wuuxuu+..vuuuuuuuuuuuuuxxuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxxxxxxux- ~xuuuu??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwzzzzwzzzzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzxxxxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>?>>?>>??????????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>vyyyyyuzv?vvvv?vvvv??vvvvvvvvvvvvvvvvvvvvvvv?v?}yyyyyyyyyyyyyuuuuu^<br></br>
-uuuuyw  |xyyyz}v?vrv???????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?v???????????????????????v??v?vvvv?vvvwyz}zyyyuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzwv??vvvvvvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzw}}?vvvv-<br></br>
-uuuxvvuyu+^|vwvvv??vvvvvv?rvvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}rv?vvvvv-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vvvvvvvvvv-<br></br>
`v?v??>^^^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..`>^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}w}+^>?>>vrv?>+>>|||->>>>?vvvv>?+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv~++++|~|~++++^>vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^v>>}?.`-`~|``||-`-`>}vvv}zuyyyw`...`>yyuuyyyyuyyy^<br></br>
`vvvvvvzvr???~--^^?vvv>>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.~~wyyuxuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?|~>>+?????v>-`-`^?>??????vwyyyy+-->--}yyuv?vrxuuuuy^<br></br>
`}rrv}yw}vv+^?>?v|+>ruzr.```~??v}}v  . +r}vvvvwuyyyuy?-~?|-vyy}?>^?|vyuuuu|<br></br>
`rrwr??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuxuyyyuuyw~-v~.|zx}+^???~}uxwrw~<br></br>
`rrww?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^`>r?~^}r?>r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwww~<br></br>
`xxzxxuuuuuz`....`rzr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxxzzzzzzzzxzv|>+.?zxv?}vu^ ?yu| >uxxx> .wzzxuuv.~wux?`.`.~>... vuvvuxzxx|<br></br>
`xxxxxxxxxuuxxuxwv..^rur|^xr..wv .wxzxxw. ?uuuuz>+ryx?~... -`  .`rxxxxxxxx|<br></br>
-xuuuuuyuuuyyyuuuyr+-`+w?.-x> >- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzxxxxxxxxx|<br></br>
-xuuuuuuuxxxxuuuuuuxz?`-v+.+}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  `xuuuuuuuuuxxuuuuuuuuu?^+w|~u?  ?uuuuuuxuv .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxxxxxxuu- ~xuuuu??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzxxxxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>?>??>???>>??????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzv?vvvv?vvvv??vvvvvvvvvvvvvvvvvvvvvvv?v?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuyw  |xyyyz}v?v}v???????+??????????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????????????????vv??v?vvvv?vvvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzwvv?vvvvvvvvvvvvvvvvvvvvvvvv??>??vvvrzxuyz??+vuyuzw}}?vvvv-<br></br>
-uuuxvvuyu+^|vwvv???vvvvvv?rvvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}rv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vvvvvvvvvv-<br></br>
`vvv??>^^^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..`>^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}w}+^>?>>vrv?>+>>|||->>>>?vvvv>?+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv~++++|~|~++++^>vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^v>>}?.`-`~|``||-`-`>}vvv}zuyyyw`...`>yyyyyyyyuuyy^<br></br>
`vvvvvvzvr???~--^^?vvv>>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.~~wyyuxuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?|~>>+?????v>-`-`^?>??????vwyyyy+-->--}yyxv?vrxuuuuy^<br></br>
`}rrv}yw}vv+^?>?v|+>ruzr.```~??v}}v  . +r}vvvvwuyyyuy?-~?|-vyy}>>^?|vyuuuu|<br></br>
`rrwr??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuxuyyyuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrww?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^`>r?~^}r?>r?}?~wwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxuuuuuuz`....`rzr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vu^ ?yu| >uxxx> .wzzxuuv.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^xr..wv .wxzxxw. ?uuuuz>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuuyuuuyyyuuuyr+-`+w?.-x> >- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuuxxxuuuuuuuxz?`-v+.+}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  `xuuuuuuuuuxxuuuuuuuuu?^+w|~u?  ?uuuuuuxuv .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuxuuuuuuuuuuxxuzxu+ `xuxxxxxxuu- ~xuuuu??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzzxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzv?vvv?vvvvv??vvvvvvvvvvvvvvvvvvvvvvv?v?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuyw  |xyyyz}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?vv?????????????????????vv??v?vvvv?vvvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrv?vvvvvvvvvvvvvvvvvvvvvvvvv??>??vvvrzxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvuyu++|vwv????vvvvvv?}vvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r????vvvvvvv-<br></br>
`vvv??>^|^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~~..`>^^??^^??vvvvvv??||>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..```````+}w}+^>?>>vrv?>+>>||^->>>>?vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv~++++|~|~++++^>vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^v>>}?.`-`~|``||---`>vvvv}zuyyyw``..`>yyyyyyyyuuyy^<br></br>
`vvvvvvzvr???~--^^?vvv>>>++^?v>>-~`-``-```^+?vv?>vuyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?|~>>+?????v>-`-`^v???????vwyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}yw}vv+^?>?v|+>ruzr.```~??v}}v  . +r}vvvvwuyyyuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuuuyyyuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>?yyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r?~^}r?>r?}?|rwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrwww+-|xyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxuuuuuuz`....`rzr+>?wz| >vwww` +zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vu^ ?yu| >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^wur|^xr..wv .wxzxxw. >uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuuuxz>`-v+.+}.. .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxuuxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzzxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwrwww}?>????????>>??????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzvvvvv?vvvvv??vvvvvvvvvvvvvvvvvvvvvvv?v?}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuyw  |xyyyz}v???????????+???v??????????????????????vxyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?vv?????????????????????vv??v?vvvv?vvvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrv?vvvvvvvvvvvvvvvvvvvvvvvvv??>??vvvrzxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r????vvvvvvv-<br></br>
`vvv??>^|^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~~..`>^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}w}+^>??>vrv?>>>>|~^~>>>>?vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`||~^?yu?>>|?v>>wv~++++^~|~++++^>v>???vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^??>?v``-`~|-`||``-`+>>?vvwuyyyw``..`>yyyyyyyyuuyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>???>>`~`-``-`-`^+???vvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..|?v?>v?-`-`^v???vvwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}yw}vv+^?>?v|+>ruzr.-. `vvv}}v  . +r}rzuyyyyyyuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuxuuuuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r?~^}r?>r?}?~rwrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrwww+-|xyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxuuuuuuz`....`rzr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vu^ ?yu| >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^xr..wv .wxzxxw. >uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuuuxz>`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxuuxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzwwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzzxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrwwwwwrwww}?>????????>>??????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuyw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?vvvv?vvvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvv??vvvvvvvvvvvvvvvvvvvvvvv??>??vvvrzxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r????vvvvvvv-<br></br>
`vvv??>^|^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~~..`>^^??^^??vvvvvv??||>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}w}+^>??>vrv?>>>>|~^~>>>>?vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`||~^?yu?>>|?v>>wv~++++^~|~++++^>v>???vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^??>?v``-`~|-`||``-`+>>?vvwuyyyw``..`>yyyyyyyyuuyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^+???>>`~`-``-`-`^+???vvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..|?v?>v?-`-`+v???vvwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.-. `vvv}}v  . +r}rzuyyyyyyuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?ur^>?uyy+ . .vrrrw?    -rruuxuuuuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r?~^}r?>r?}?~rwrrrzu^<br></br>
-yzv}??vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwwz~<br></br>
`xxzxuuuuuuz`....`rzr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vu^ ?yu| >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^xr..wv .wxzxxw. >uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuuuxxuuuuuuuuuxz>`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxuuxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzwwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+?zzzzzzzzzxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwrwww}?>????????>>??????????????????????????>vwwwrrrrrrrrrrrrrrr~<br></br>
-uyuyyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyyuuuuy^<br></br>
-uuuuyw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvv??vvvvvvvvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r??v?vvvvvvv-<br></br>
`vvv??>^||``~-`|^~``->?+??rv?vvvvv?rwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..`>^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}w}+^>?>>vv+^>>>>|~^~>>>>+^>vv>?+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`||~^?yu?>>|?v+vv+`^+++^~|~++^+||??v??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|>>^v>>??``-`-|-`||``-^??vvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>+??v+`~`-``-`-`~v>vvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^???+v?-`-`+vv>?vrwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}vv}}v  . +r}wxyyyyyyyuy?-~?|-vyyv+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?yr^>?uyy+ . .vrrrw?    -rrxxxuuuuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?`|}}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r?~^}r?>r?}?|rwrrrzu^<br></br>
-yzv}??vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrwww+-|uyxzuuv-`+^v>?zzwwwww~<br></br>
`xxzxuuuuuuz`....`}zr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...|rxz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>>?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yu| >uxxx> .wzzxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..wv .wxzzxw. >uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
`xuuuuuxuuxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  `xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .wuuxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzxu+ `xuxuuxxxuu- ~xuuxx??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzzzzzzwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrrwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzzxzxzzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?vvv?vvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vvvv}v??vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvvvvv??^|>?vvvvvvvv}uxr}}}v-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}r}+^>?>>vv+^>+>>|~^~>>>>+^>vv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vv+`^^++^~|~++^+||??v??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>??``-`~|-`||``-^??vvvvwuyyyw``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>+??v+`~`-``-`-`~v?vvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^???>v?-`-`+vv>?vrwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruxr.`. `}vv}}v  . +r}wxyyyyyyuuy?-~?|-vyy}+>^?~vyuuuu|<br></br>
`}rr}??+??|. ^>?ur^>?uyy+ . .vr}rw?    -rrxxxuuuuuuyw~-v~.|zx}++???-}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzzxxw~`>?-|}}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r>~^}r?>r?}?|rrrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrrww+-|xyxzuuv``+^v>?xzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwww` ^zxv  ryuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yu| >uxxx> .wzzxuu?.~wuz?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..zv .wxzzxw. ?uuuyx>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuxuxxxxxxxuuuuxz?`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuxxxxxxxxxu|<br></br>
-xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxxxxxxu? .wuxxuu+..}uuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuxxuxxxux- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzwwwzzwwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?vvv??vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vvvv}v??vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^|^``~~`|^~``->?+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^>?^^??vvv?+>v>^|>??|?vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}r}+^>?>>vrv??|^>|~^~++|>>?vvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv?+^++|~|~++|>|^vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}?>>``-|-`||``?^^vvvvvwuyyyr``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv>?-~--``-`--++?vvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}?>?v?-`-`+v}?vrrwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . +r}wuuyyyyyyuy?-~?|-vyy}+>^?~vyuuuu|<br></br>
`}rr}??+??|. ^>?ur^>?uyy+ . .vr}rw?    -rrxxxuuuuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r>~^}r?>r?}?|rrrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrrww+-|xyxzuuv``+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yu| >uxxx> .wzzxuu?.~wuz?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^wu}|^zr..wv .wxzzxw. ?uuuux>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuxuv .wuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuxxxxxxux- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzzwzzzwwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}}?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vvvv}v??vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}}vvvvvvv?r}vxyyuuuuz}^..^r??v?vvvvvvv-<br></br>
`vvv??>^|^``~~`|^~``-??+??rv?vvvvv?wwwwv?vvvvvvvvxyyuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv?+>v>^|>??|?vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}r}+^>?>>vrv?>|^>||^~++|>>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vuv?+^++|~|~++|>|^vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}?>>``-|-`||`.?^^vvvvvwuyyyw``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv>?-~--``-`--++?vvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}?>?v?-`-`+v}?vrrwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . +r}wuuyyyyyyuy?-~?|-vyy}+>^?~vyuuuu|<br></br>
`}rr}??+??|. ^>?ur^>?uyy+ . .vr}rw?    -rrxxxuuuuuuyw~-v~.|zz}+^???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .` .vrrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuux. +}  >wrrrrrrrrr^->r>~^}r?>r?}?|rrrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z~ `zzwwwrrww+-|xyxzuuv``+^v>?xzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwww` ^zxv  ruuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxuv .rzzz` |xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yu| >uxxx> .wzzxuu?.~wuz?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..wv .wxzzxw. ?uuuux>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzxxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz` >uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
-xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuxuv .wuxxuu+..}uuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `zuxxxxxxuu- ~xuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzwwzzzwwwwwwwwwwzzwzxr`.?zzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxu>.-}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+?zzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?v}rv?vvvvv?r}vxyyuuuuz}^..^r??v?vvvvvvv-<br></br>
`vvv??>^||``~~`|^~``-??+??rv?vvvvv?rwrw??vvvvvvvvxyyuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvvv???>|~+????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}r}+^>?>>vrv?+^^|~~^-|^^^>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`||~^?yu?>>|?v+vur^|+~~^-~~~-^^->vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}v~>-.`~-`~~.`>~?vvvvvwuyyyw``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>^vv??>?--``-`^???vvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vvvvv?--~`+v}v}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}v}}v  . +r}wuuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??|. ^>?ur^>?uyy+ . .vr}rw?    -rrxxxuuuuuuyw~-v~.|zz}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy+ .. .vrrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. +}  +wrrrrrrrrr^->r>~^}r?>r?}?|rrrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .}z| `zzwwwrrww+-|xyxzuuv``+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>?wz| >vwww. +zx}  ruuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzzxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^wu}|^zr..w} .wxzzxw. ?uuuux>+ryx?~... -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +- >uxxxxu> .zxxxw>?xuv^>+-`>r???vzxxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. >uxxxxxx+`^v}wzuuuuuuxxxxxxxxxu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xuxxxxxxux` |uuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
                  `zzzzzzzwwzzzwwwwwwwwwwzzwzxw`.vzzzzzzzzzxv. vxzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.+zxxxxxxxxxxu+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?^?zzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv??ww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`|^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`||~^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuyw~-v~.|zz}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzzxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^wu}|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w>.-x> +` +uxxxxu> `zxxxw>?xuv^>+-`>r???vzxxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuuu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xxxuuxxxux. ^uuuux??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwwzzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v???vvvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv??ww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`|^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..`````.`+}w}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~. ^>?ur^>?uyy+ . .vr}rw?    ~rruxxuuuuuuyw~-v~.|zx}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv``+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzzxxu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +` +uxxxxu> `zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuxxxxxxxxxx|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwwwzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>zxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v??????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..+r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}w}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?vvv>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~. ^>?ur^>?uyy+ . .vr}rw?    ~rrxxxuuuuuuyw~-v~.|zz}+^???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz~ -zzwwwrrww+-|xyxzuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuyx>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +` +uxxxxu> `zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuuuuxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xxxuxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwwzzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxzz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vv?vvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvvv???vvvvv??ww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuw}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?-..`````..+}w}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~. ^>?ur^>?uyy+ . .vr}rw?    ~rrxxxuuuuuuyw~-v~.|zx}+^???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|rzz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuuxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +` +uxxxxu+ `zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuxxxxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxxu|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuuuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwwwzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyuuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuu|<br></br>
`}rr}??+??~. ^>?ur^>?uyy^ . .v}}rw?    ~rrxxxuuuuuuyw~-v~.|zz}++???~}uxwrw~<br></br>
`rrwr?|-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrwzu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv``+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv-  ..|xr>>rwwwwz~<br></br>
`zzzzzzzzzzz?-...|}zz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxzxx|<br></br>
`zxxxxxxxxuxxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyuuuyr+-`+w?.-x> +` +uxxxxu+ `zxxxw>?xuv^>+-`>r???vzuxxxxxxxx|<br></br>
-xuxxxxxxxxxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxxx|<br></br>
                  -xuuuuuuuuuuuuuuuuuuuu?^+w|~u?  ?uxuxuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuuu|<br></br>
                  -uuuuuuuuuuuuuuuuuuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwwwzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuxu|<br></br>
`wrrrrrrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
`}rrrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}rrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyuuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuu~<br></br>
`v}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuyw`<br></br>
`v}}?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuuv`<br></br>
`v}vv?>+??~. ^>?ur^>?uyy^ . .v}}rw?    ~rrxxxuuuuuuyw~-v~.|zx}++???~}uxw}?`<br></br>
`vv}v?+^~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrv?`<br></br>
`}}v???|-`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrrr}v`<br></br>
`rrv?vv?r}~``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv-`+^v>?xv?v?vv`<br></br>
`}r}}ruxvrzz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|xr>>??>>vv`<br></br>
`wr}}}}rvvvz?-...|}zz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>+?zwwvv`<br></br>
`w}}vv}}}vvzxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxxvv`<br></br>
`}}}v>>v}rwuxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxxw?`<br></br>
`}rrzw??rwuyyyuuuyr+-`+w?.-x> +` +uxxxxu+ `zxxxw>?xuv^>+-`>r???vzuxxxxxxxv`<br></br>
`}r}}r}}rzuxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxx}-<br></br>
`}}}}}rwxuuuuuuuuuuuuu?^+w|~u?  ?uxuxuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuzw-<br></br>
`}rwwxuuuuuuuuuuuuuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuuzw~<br></br>
`zzzzzzzwwwzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuuzr|<br></br>
`wv??rrrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxuz~<br></br>
.v?>vrrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
.>?vrrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`}}rrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvv?vvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvyyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyuuyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuyz`<br></br>
.vv}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuyv <br></br>
.?vv?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>^?|vyuuu>.<br></br>
`?v???>^??~. ^>?ur^>?uyy^ . .v}}rw?    ~rrxxxuuuuuuyw~-v~.|zx}++???~}uxwv+.<br></br>
`?????>?|^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}^?+>rrrrv?.<br></br>
`????v?v?`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?}?|rwrr}v?.<br></br>
.?v?>}x?v?~``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv``+^v>?z???>??.<br></br>
.????vxz^?rz`....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|xr>>>^^|?v.<br></br>
`rv????v?+>z?-...|}zz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`...?x>>?wrw?v.<br></br>
.v???+?????zxzv|>+.?zxv?}vx^ ?yy~ >uxxx> .wzxxuu?.~wux?`...~>... vuvvuxx??.<br></br>
.???+..+??vxxxuxwv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`}xxxxxx}+.<br></br>
`???v?.~?vzyyuuuuyr+-`+w?.-x> +` +uxxxxu+ `zxxxw>?xuv^>+-`>r???vzuxxxxxxx>.<br></br>
`????????wuxxxxuuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuuuuxxxxxxxx?`<br></br>
                  .??????vxuuuuuuuuuuuuu?^+w|~u?  ?uxuxuuxu? .zuxxuu+..vuuuuuuuuuuuuuuuuuuwv`<br></br>
.??v}wxuuuuuuuuuuuuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuuuuuuuuuuuuu}v`<br></br>
.wrxwzzzzwwzzwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxxxxxxxxxxuuur?~<br></br>
`r?>|}rrrrrrwwwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxxxxxxxxxxxxxur`<br></br>
.?~-?rrrrrrwwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxzzzzzzzzzzzzzz|<br></br>
                  .~~?rrrrwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
`v}rrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvv?vvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuxzwzxyu~<br></br>
`v}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz????????uz`<br></br>
`v}}?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>~+^>???vv`<br></br>
`?vvv?>^??~..^>?ur^>?uyy^ . .v}}rw?    ~rrxxxuuuuuuyw~-v~.|zx}++?^|~>???>>.<br></br>
.?????>?^^|..`^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}|^|^?????>.<br></br>
`vv??v?v?^^. .|~`vuv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r?v>~?????>>.<br></br>
`v}v?v}?v?>~`-...-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv``+^?>^?>>>>+>.<br></br>
.??v??}v??vr|....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...|v}^+>++++>.<br></br>
`vv?v??v??>x}|...|}zz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`..`>w^^+???>>.<br></br>
.vv????????uuw>~>+.?zxv?}vx^ ?yy~ >uxxx> .wzzxuu?.~wux?`...~>...`v?>>???>>.<br></br>
.????++???vwxwvwzv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .`????????>.<br></br>
.???v?+>??vrrrrzyyr+-`+w?.-x> +` >uxxxxu> `zxxxw>?xuv^>+-`>r???v}????????>.<br></br>
`???v???>>>?rrvxuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuzwv????????>.<br></br>
.??v???vv>>+vrwuuuuuuu?^+w|~u?  ?uxuxuuxu? .zuxxuu+..vuuuuuuuwvv?????????>`<br></br>
.>?vvvvrw??v}wuxxxuuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuuwv?????vwxx}v?`<br></br>
.v}v?vvv}vvrwwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxxv?????vxuxux}?`<br></br>
`rv?+vvv}}rrrrwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxxv????vxuxxxux}-<br></br>
.v>>vrrrrrrrwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxw}vv}wxzzzzzzz~<br></br>
.~->rrrrrwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww~<br></br>
.++vrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+??????????????????????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvv?vvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuxzrv}zuy^<br></br>
`v}}}}zyuyv>^+?v?>??}}?^.`..^}vv}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??>?>|||zy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuy?-~?|-vyy}+>|+?>+||?u^<br></br>
`vv}v?>^??~..^>?ur^>?uyy^ . .v}}rw?    ~rrxxxuuuuuuyw~-v~.|zx}++?~-^>>^^^?`<br></br>
.????v+?>^|..`^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw~`>?-|v}>~v}|^++>>>??>`<br></br>
`w}???????>  .|~`}uv>+rux- -.^yuuz. >v  >wrrrrrrrrr^->r>~^}r?>r??>>>>>>>++.<br></br>
`ww??????vv^`-.`.-wz?>>wzv ||`zwz> .rz| -zzwwwrrww+-|xyxzuuv``+^??>>>>>>||.<br></br>
.??v???????v?....`}zr+>?wz| >vwwr. +zxv  ruuxwwwzv--}uuuuv- ...~?}>>>>>>||.<br></br>
.???v?????>xu?...|}zz}>+?zr.`zxu? .wzzz` ^xzwwwz}-`+zzzr^...`..|+}>>>>>>+|.<br></br>
.???v??v???yyr|~>+.?zxv?}vx^ ?yy~ >uxxx> .wzzxuu?.~wux?`...~>...|}>>>>>>>+.<br></br>
.???v??????}wv+rzv..^rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..  -`  .->>>>>>>>>.<br></br>
.???v?????>????}yyr+-`+w?.-x> +` >uxxxxu> `zxxxw>?xuv^>+-`>r???v?>>>>>>>>>`<br></br>
`??vv???|`.|v?>zuuuxz>`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}wzuuuuwv?>>>>>>>>>`<br></br>
.??vv??>^.  +?}uuuuuuu?^+w|~u?  ?uxuxuuxu? .zuxxuu+..vuuuuuuu}??>>++^+^^+>`<br></br>
.>?v?????.`+?}xxxzxuuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuuuu}>>>^||^vxx?v>`<br></br>
.>v>>????>?vrwwwwwwwwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxxxx?>>|^|>zuuuz?v`<br></br>
`rr?????v}rrrrwwwwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxxxxx>>^||^zuxxxuwv-<br></br>
`vvvvrrrrrrrwwwzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzxzxxr?>??rxzzzzzxw-<br></br>
`~`+}rrrrwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww|<br></br>
.~-?rrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+???v?????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvv?vvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuxzrwxuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyuxx+-->--}yz}?????>>?xy^<br></br>
`}rrv}uw}vv^^?+?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuvv>--?|-vyw>+^^++>?>>vu^<br></br>
`}}r}?>^??+`.^>>ur^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuxv^^>~`~rv>>++^^~>?>>>?`<br></br>
`vv}}?^+^^+~~~+?vuv+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw|-++-|>>|->?|++^>>>>>>`<br></br>
`zrv???>>>>-`~+^^vuv>+}zz- -.^yuuz. >v  >wrrrrrrrr}^->}>~|??++?>>>>>+>>>>?`<br></br>
`xz??v?????>|~`~^>}z?>>rwv ||`zwz> .rz| -zzwwwrrwr^-|wzr}xr>~`^|>?>>>>>>^^.<br></br>
`}rrvvv????v?`..`^vrr+>?ww| >vwwr. +zxv  ruuxwwwzv--}uuuuv-`---|>?>++>>>++.<br></br>
`}v}rvv????rw?`.`+vr}v>>?wr.`zxu? .wzzz` ^xzwwwz}-`+z}}?~.`--`~+>??++>+++^.<br></br>
`}}vvv????vzw}+|>>~?vwv?}vx^ ?yy~ >uxxx> .wzzxuu?.~wuz?`..`~+--~+??>>????>.<br></br>
`}v?vv?????vrv?vrv^^+rur|^zr..w} .wxzzxw. ?uuuux>+ryx?~....~|``-^>>?>?????`<br></br>
`vvvv??????????}zz}?^`+w?.-x> +` >uxxxxu> `zxxxw>?xuv^>+-.^?>>+>>>>>>>????`<br></br>
`vvvvv??^||+v??}wwwrr?`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}w??vvv??>>>>>>>>>?`<br></br>
`vv}vv?>^~^|??vrwwrwuu?^+w|~u?  ?uxuxuuuu? .zuxxuu+..vuuu}?vv?>>>>+^^^++>?`<br></br>
.?v}vv?>>~->?vrr}}rxuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuuz?v?>>>>>>?vvv>>>`<br></br>
`v}v?v??+-^?v}vvv}wzwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxxrvv>>>+>+?xuuzv+>.<br></br>
`rrv??v?+>vvvv}}wwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxzvvv>>++>?xuxxxxv>`<br></br>
`}v??rr?vrr}rrzzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzzvvvv?>?vvwxzzzzxz?.<br></br>
`+-^}rr}rwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwww}v???vwwwwwwwwwwww~<br></br>
`|-?rrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}v??v}}}}}}}}}}}}}~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvv???}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvv?vvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyzrw+-->--}uvv??vruuuuuy^<br></br>
`}rrv}uw}vv^^v+?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyx++^~-?|-vy?~>>>>^vyuuuy^<br></br>
`}rrr??+???+.^>+zr^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuw>+>+^-~}?|??>>>-}uxw}v`<br></br>
`rrwr?^-~|?v??>>?x}+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw|-+++>+++>>>>>>^v??>>v-<br></br>
`zr}v>v~-|>?????>?xv>+v}z- -.^yuuz. >v  >wrrrrrrr}v^->}>~|++>>>>>>>>+?}}}z|<br></br>
-yzvvv?????????????w?>+vv? ||`zwz> .rz| -zzwwwrrwr|~|?v??r?|>>>>>??>>>?>>>`<br></br>
`xxzxxr?????????????r>+?wr^ >vwwr. +zxv  ruuxwwwzv--}uxxxv~+>>>>>??|^>>>>>`<br></br>
`zzxxwv?????????????vv>>?wr.`zxu? .wzzz` ^xzwwwz}-`+z??>`.|>>>>>>?r||>^^^^.<br></br>
`zxzrvvv????????????>}v?}vx^ ?yy~ >uxxx> .wzzxuu?.~wuz?`.`^>>>>>>>w+vyzwww~<br></br>
`zzvrv??>???????????>}ur|^zr..w} .wxzzxw. ?uuuux>+ryx?~..-+>>>>>>>?v>zxxxx|<br></br>
-xxu}?+++>??????????+.+w?.-x> +` >uxxxxu> `zxxxw>?xuv^>+--+>>>>>>>>?>>rxxx|<br></br>
-xur}w+++>??????????v?`-v+.+}.  .zuxxxxxz. ?uxxxxxx+`^v}w^+>>>>>>+>>>>>vrx|<br></br>
-xuxxz^++???v???????xu?^+w|~u?  ?uxuxuuuu? .zuxxuu+..vuuu?|>>>>+v?>^|^>?v}~<br></br>
-uuuzu>++^^????????zuuuxxuzzu+ `xxxxxxxxux. ^uuuxx??zuuuur|>>>?vuuuzv+|^+>`<br></br>
`zxzzw?^~`~>?????vwzwwwzzwzxr..vzzzzzzzzzxv..}xzzxxxxxxxu?|>>?}?uxxuuuv^|^.<br></br>
`wwrv}r?`~>++??vrwwwwwzzzzz?-.>xxxxxxxxxxxx+.~}xxxxxxxxxr~^>>}?}uxxxxxuz+|.<br></br>
`rr^^rr~>w}vvrzzzzzzzzzzzzz?>}xxxxxxxxxxxxxz?+vzzzzzzzzz+|>>>>vxzzzzzzzxr^.<br></br>
`}^-vr}vwwwwwwwwwwwwwwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwwwwwv>>>>>wwwwwwwwwwwr-<br></br>
`+-vrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}rv>??}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvv?v????vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyx|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyxwz+-->--}y}v??vruuuuuy^<br></br>
`}rrv}uw}vv^^v+?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyx?>+~-?|-vyv^>+^+|vyuuuy^<br></br>
`}rrr??+???~.^>>xr^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuz?^++|-~}?^?>+++`}uxw}v`<br></br>
`rrwr?^-~|??>++??x}+>?yyv.`  +uuuy^ .. .}rrrrwzzxxxw|-++|^>>^|>>+>+^}v???v~<br></br>
`zwrv>v-`~+>>???+vxv>+}rz- -.^yuuz. >v  >wrrrrr}vvv|->}>~|^+>+>>>+^>>v}}rz|<br></br>
-yzvvv??vv????>>??vw?>+}}v ||`zwz> .rz| -zzwwwr}}v|~|???>v>^+^++>+>>>>????`<br></br>
`xxzxxwvvvvv>>>>>?vvr+>?wr^ >vwwr. +zxv  ruuxrv??>||?}vvv?^+>>>>>??|+>????`<br></br>
`zzxxw}vvvvv????????vv>>?wr.`zxu? .wzzz` ^xzzrvv?--^v>>+~~^>>>>>>?v|^?>>>>`<br></br>
`zxzw}}vvvvvv????????}v?}vx^ ?yy~ >uxxx> .wzzxuu?.~wzv+~-|+>>>>+^>r+vxzzwz|<br></br>
`zz}wrvv??v??????????}z}|^zr..w} .wxzzxw. ?uuuyx>+ryx>|--|+>>>>++>v}?zxxxx|<br></br>
-xuuwv??????????????>~?r?.-x> +` >uxxxxu> `zxxxw>?xuv^>>|~>>>>>>??????wxxx|<br></br>
-xuwrz??????????????v?|^v>`+}.  .zuxxxxxz. ?uxxxxxx+`|???^+>>>>>>>?????vwx|<br></br>
`xuxxz??????????????zzv>>}|~u?  ?uxuxuuuu? .zuxxuu+..?}vv>+>>>>>vv?>>>??vr~<br></br>
-uuuxu???+^????????}wwwrrwwxu+ `xxxxxxxxux. ^uuuxx?>vw}}}v^>>>?vxuux}?+>>?-<br></br>
`zzzzwv?>-->?????vv}}}}}}}zxr..vzzzzzzzzzxv..}xzzxxwwwrrr?^>>?v?xxxuuur>+>`<br></br>
`wwrv}r?~^?+>??vv}}}}}}}rwz?-.>xxxxxxxxxxxx+.~}xxxxwrwrwv|>>+v?vxxxxxxuz>+.<br></br>
`rr>>rr^?wrvvv}}r}}}}rrwzzz?>}xxxxxxxxxxxxxz?^vzzzzw}v}r+^>?>+?wzzzzzzzxw+.<br></br>
`}+|}r}vwwwwwwrrrrrrrwwwwwwzzzwwwwwwwwwwwwwwwwwwwwwwr}vv?++>>?wwwwwwwwwwwr-<br></br>
`>~vrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}vvvv?>??}}}}}}}}}}}}r~<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyy+-->--}yyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuyv-~?|-vyyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuyw~-v~.|zz}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzxxxx}~-??`|v}>~v}^>+>wwrrrz|<br></br>
`zxw?>?..`~. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrv???~-??+^++>??w?}?~??}rrwu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrv??^~~?+>>>>>^`^^>-~++>rwww|<br></br>
`xxzxxuuuuuz`....`rzr+>?wz| >vwwr. +zxv  ruux}>>>>+>>>>>>>>>>>^^+>>>>vwwwz|<br></br>
`zzzzzzzzzzz?~```~?v}v++?zr.`zxu? .wzzz` ^xzzv>+^|~||^>>>>>>>??+^??>>}rzzz|<br></br>
`zzzzzzzzzxwv?>>>>????>>vvx^ ?yy~ >uxxx> .wzzuxx?.~wr|+>>>>>>?r-.?v>?}zxzx|<br></br>
`zxxxxxxxur+>?>>????????>^zr..w} .wxzzxw. ?uuuyx>+ryx^+>>>>>>?r~`}rwxxxxxx|<br></br>
-xuuuuuuyz>++????????????`-x> +` >uxxxxu> `zxxxw>?xuv~^>>>>>>>>>zxxxxxxxxx|<br></br>
-xuuuuuux?>+>????????????>`+}.  .zuxxxxxz. ?uxxxxxx+`-^>>>>>>>>>xxxxxxxxxu|<br></br>
`xuuuuuuz+>+>?????????????~~u?  ?uxuxuuuu? .zuxxuu+..~+>>>>>>>>?ruxuuuuuuu|<br></br>
-uuuuuuuu>>>??????????????rxu+ `xxxxxxxxux. ^uuuxx?^~>>>>>>>>>>?vuuuuuuuuu|<br></br>
`zzzzzzzz?^>??????????????zxr..vzzzzzzzzzxv..}xzzxu>>?>>>>?++>>?zxxxxuuuuu|<br></br>
`wwrrrrrrr?^>????????????wz?-.>xxxxxxxxxxxx+.~}xxxu>+?>>>>u?|++>xxxxxxxxxu|<br></br>
`rrrrrrrrrwv>??????????vwzz?>}xxxxxxxxxxxxxz?^vzzzx}^^+>>>vz+||+zzzzzzzzzz|<br></br>
`rrrrrrrwwwww}vvvvvvv}wwwwwzzzwwwwwwwwwwwwwwwwwwwwwwr?~+>>+rw}}wwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}v?+??>?vrr}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyyu+-->--}yyw??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyuuu?-~?|-vyyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?    ~rrxxxuuuuuuu}~~v~`|rz}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzxxxx}~->?`|vv>~v}^?+>wwrrrz|<br></br>
`zxw?>?..`~. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrrv???~->?>+++???w?}?~??}rrwu^<br></br>
-yzv}?>vzw|``-...-wz?>>wzv ||`zwz> .rz| -zzwwwrv??^~~?+>>>>>|`^^>-~+>>rwww|<br></br>
`xxzxxuuuuuz`....`rzr+>?wz| >vwwr. +zxv  ruux}>>>>>>>>>>>>>>>>^^>>>+>}wwzz|<br></br>
`zzzzzzzzzzz?~```|??}v++?zr.`zxu? .wzzz` ^xzz}>++|~||^>>>>>++??+^??>>}rzzz|<br></br>
`zzzzzzzzzxwv?>>>>????>>}vx| ?yy~ >uxxx> .wzxuxx?.-r}|+>>>>+>?v-.?v>?}zzzx|<br></br>
`zxxxxxxxur>>?>>????????>^z}..w} .wxzzxw. ?uuuyx>+}uz^+>>>>++>v-`}rwxxxxxx|<br></br>
-xuuuuuuyz???????????????~~x> +` >uxxxxu> `zxxxw>?wzv|^>>>>>>>>>zxxxxxxxxx|<br></br>
-xuuuuuux?>>??????????????->}.  .zuxxxxxz. ?uxxxxxw^`~^>>>>>>>>?xxxxxxxxxu|<br></br>
`xuuuuuuz>?+??????????????||x?  ?uxuxuuuu? .zuxxuu+..~>>>>??>>??ruxuuuuuuu|<br></br>
-uuuuuuuu??>??????????????}zx+ `xxxxxxxxux. ^uuuxx?|~>>>>>>??>?v}uuuuuuuuu|<br></br>
`zzzzzzzz?+>??????????????ww}..vzzzzzzzzzxv..}xzzxu>>?>>>>?>>>>vzxxxxuuuuu|<br></br>
`wwrrrrrrr?+>????????????rw?-.>xxxxxxxxxxxx+.~}xxxx>+?>>>?u?^>+?xxxxxxxxxu|<br></br>
`rrrrrrrrrwv>??v???????vwww?>}xxxxxxxxxxxxxz?+vzzzzv^^+>>>vz>^^+zzzzzzzzzz|<br></br>
`rrrrrrrwwwww}vvvvvvv}rwwwwzzzwwwwwwwwwwwwwwwwwwwwww}?~+>>+rw}}wwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}v?+????vr}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`?yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~-}zzuuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyuv+~->-~?v??>?vruuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyyu?++||~-vyuv|>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?    ~rrxxxuuuyuyr^>++++>+v?++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzzxx}+>+^>>^~||v}^>+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrv?vr}++|>v>^^v?>r?}?|rrrrrwu^<br></br>
-yzv}?>vzw|``-...~wz?>>wxv ||`zwz> .rz| -zzwwrvr}v+-~~?^|+?}-.+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz-... -?v>^+>rz| >vwwr. +zxv  ruuxwwv?>-|||||+r~ ...|zr>>rwwwzz|<br></br>
`zzzzzzzzzzz????+>???>?+??v.`zxu? .wzzz` ^xzwzr>?+^|+>>>>w~.`...?x>+>zzzzz|<br></br>
`zzzzzzzzzzxxz?????????????~ ?yy~ >uxxx> .wzxxwz?.~|+>>>>r-->... vuvvuxzzx|<br></br>
`zxxxxxxxxxrrvv?????????????..w} .wxzzxw. ?uuuyx>+||+>>>>>```  .`}xxxxxxxx|<br></br>
-xuuuuuuuyrxuv|>???????????v^ +` >uxxxxu> `zxxxw>>||+>>>>++r???vzxxxxxxxxx|<br></br>
-xuuuuuuuu?vx^^>v????????????.  .zuxxxxxz. ?uxxxxw^|+>^+>>zuuuuuxxxxxxxxxu|<br></br>
`xuuuuuuuuw+v>+>??????????>>?^  ?uxuxuuuu? .zuxxux^|>>^+>?uuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuu?-?^>????????>?>??~ `xxxxxxxxux. ^uuuuu>|+?+++wuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzz}-v^+??????????>>>..vzzzzzzzzzxv..}xzzxr|^v>^?uxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrv+wv>+>??????z>^^``>xxxxxxxxxxxx+.~}xxuz~|>?+ruxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrrrwzwv>^^^+>^?+v?>}xxxxxxxxxxxxxz?+vzzxv`~^>>>zxzzxzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwzrv???v}}wzzzzwwwwwwwwwwwwwwwwwwwwr?>||+>?wwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rr?>>??}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw``..`?yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvruyyyz|-`.~-}zzuuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-+v}}}}rwzxuyyuv+~->-~?v??>?vruuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  . >r}wxuyyyyyyu?++||~-vyuv^>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?    ~rrxxxuuuyuyr^>++++>+v?++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .}rrrrwzzzxx}+>^^>>^~||v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+rux- -.^yuuz. >v  >wrrrrv?vr}++|>v>^^v??r?}?|rrrrrwu^<br></br>
-yzv}??vzw|``-...~wz?>>wxv ||`zwz> .rz| -zzwwrvr}v+-~~?^|+?}-.+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz-... -?v>^+>rz| >vwwr. +zxv  ruuxwwv?>-|||||+r~ ...|zr>>rwwwzz|<br></br>
`zzzzzzzzzzz????+>???>?+??v.`zxu? .wzzz` ^xzwzr>?+^|+>>>>w~.`...?x>+>zzzzz|<br></br>
`zzzzzzzzzzxxz?????????????~ ?yy~ >uxxx> .wzxxwz?.~|+>>>>r-->... vuvvuxzzx|<br></br>
`zxxxxxxxxxrrvv?????????????..w} .wxzzxw. ?uuuyx>+||+>>>>>```  .`}xxxxxxxx|<br></br>
-xuuuuuuuyrxu?|>???????????v^ +` >uxxxxu> `zxxxw>>||+>>>>++r???vzxxxxxxxxx|<br></br>
-xuuuuuuuu?vx^^>v????????????.  .zuxxxxxz. ?uxxxxw^|+>^+>>zuuuuuxxxxxxxxxu|<br></br>
-xuuuuuuuuw+v>+>??????????>>?^  ?uxuxuuuu? .zuxxux^|>?^+>?uuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuuu?-?^>????????>?>??~ `xxxxxxxxux. ^uuuuu>|+?+++wuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzz}-v^+??????????>>>..vzzzzzzzzzxv..}xzzxr|^v>^?uxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrv+wv>+>??????z>^^``>xxxxxxxxxxxx+.~}xxuz~|>?+ruxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrrrwzwv>^^^+>^?+v?>}xxxxxxxxxxxxxz?+vzzxv`~^>>?zxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwzrv???v}}wzzzzwwwwwwwwwwwwwwwwwwwwr?>||+>?wwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rr?>>??}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~>}}}}r?>++>ryy+-?r^~vyyw??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?>}xyy?|~~~>w?~?v?+|xyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~-^}r??r?}?|rrrrrwu^<br></br>
-yzv}??vzw|``-...-wz?>>vv> ||`zwz> .rz| -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zxv  ryuw^>>>>>>>w?>uv- ...|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}++?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~....`..?x>+?zzzzz|<br></br>
`zzzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
`xuuuuuuyyyyyyzvv}?v???????v??+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzxxxxxxxxx|<br></br>
-xuuuuuuuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuuxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr|?????v}??}v??v???wuuuuuu? .zuuv??>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??v?|>????rz??v>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzwr?+????v???????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrwwwwz}>>??????v>>^^>vrxxxxxxxxx+.~}xxz?}?>||wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}v????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrrr}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~?}}}}r?>++>ryy+-?r+~vyyw??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?>}uyy?|~~~>w?~?v?+|xyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~-^}r??r?}?|rrrrrwu^<br></br>
-yzv}??vzw|``-...-wz?>>vv> ||`zwz> .rz| -zzw?^>>>>>>>}^?xuuv-`+^v>?zzwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zxv  ruuw^>>>>>>>w?>uv- ...|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}++?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~....`..?x>+?zzzzz|<br></br>
`zzzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuuyyyyyyzvv}?v???????v??+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzxxxxxxxxx|<br></br>
-xuuuuuuuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuuxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr|?????vr??}v??v???zuuuuuu? .zuuv??>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??v?|>????rz??v>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzwr?+????vv??????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}xxz?}?>||wuxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}v????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrrr}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvzuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~>}}}}r?>^+>ryy+-?r+~vyyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?>}uyy?|~~~>w?~?v?+|xyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~-^}r?>r?}?|rrrrrwu^<br></br>
-yzv}??vzw|``-...-wz?>>vv> ||`zwz> .rz| -zzw?^>>>>>>>}^?xuuv-`+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zxv  ruuw^>>>>>>>w?>uv- ...|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}>+?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zzzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuuyyyyyyzvv}?v???????v??+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzuxxxxxxxx|<br></br>
-xuuuuxxuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuuxxxxxxxxu|<br></br>
-xuuuuuuuuw}v?zr|?????vr??}v??v???zuuuuuu? .zuuv??>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzwr?+????vv??????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrwwwwz}>>??????v>>^^>vrxxxxxxxxx+.~}xxz?}?>||wuxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}v????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrrr}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvzuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~>}}}}r?>++>ryy+-?r+~vyyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?>}uyy?|~~~>w?~?v?+|xyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}++???-}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}??vzw|``-...-wz?>>v?> ||`zwz> .rz| -zzw?^>>>>>>>r^?xuuv-`+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zxv  ruuw^>>>>>>>w?>uv- ...|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}>+?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~....`..?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuuyyyyyyzvv}?v??????????+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzuxxxxxxxx|<br></br>
-xuuuuxxuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuuxxxxxxxxu|<br></br>
-xuuuuuuuuw}v?zr|?????vr??}v??v???zuuuuuu? .zuuv??>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz??v>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzw}?+????vv??????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}xxz?}?>||wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrrr}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvzuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~>}}}}r?>^+>ryy+-?r+~vyyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?+}uyy?|~~~>w?~?v?+|xyv+>^?|vyuuuu^<br></br>
`}rr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}+^???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>v?> ||`zwz> .rz| -zzw?^>>>>>>>r^?xuuv-`+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}| >vwwr. +zxv  ruuw^>>>>>>>w?>uv- ...|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}>+?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~....`..?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzxx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuuyyyyyyzvvr?v???????v??+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzxxxxxxxxx|<br></br>
-xuuuuxxuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuuw}v?zr|?????vr??}v??v???zuuuuuu? .zuuv??>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz??v>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzw}?+????vv??????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wrrrrrrrrrrwwwwz}>>??????v>>^^>vrxxxxxxxxx+.~}xxz?}?>||wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwww}?>????????>>??????????????????????????>vrwwrrrrrrrrrrrrrrr~<br></br>
-uyuuyx>?yyyyyuzv?vvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyuuuuuu^<br></br>
-uuuuuw  |xyyyz}v???????????+?????????????????v????????vuyyyyuuuuuuuuw?}uu^<br></br>
-uuuuuy}. `ryyyyxv?????????????????????????v??v?v?????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???vvvvvvvvvvvvvvvv??>??vvv}zxuyz??+vuyuzr}v?vvvv-<br></br>
-uuuxvvuyu+^|vwv????vvvvvv?}vvvvvvv????vvvvv?vww}zxuyyyyu?r}|vvvv}vv?vvv?v-<br></br>
`}rw}??r}v??++++^^||?v??vv?}?vvvvv?vrw}vvvvvv?r}vxyyuuuuz}^..^r?vv?vvvvvvv-<br></br>
`vvv??>^||``~~`~^~``-??+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..`>^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..``````.+}r}+^>?>>vrv>^++|~~|`^^^+>vvvv>>+vvvv?.     ?}wxuxxuxwrr~<br></br>
`vv?????>??~`|||^?yu?>>|?v+vu}--?v+`~~-`>?~~?vvv??vvrwzv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvv}?-|||~}v?|+>^v>>}}>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`vvvvvvzvr???~`-^^?v?v>?+^>^vv}}?^`-``-`~?vvvvvvvvxyyyz|--`~~wyyyuuyyuuuuy^<br></br>
`}}}}}zyuyv>^+?v?>??}}?^.`..^}v}}}?~~|-~>}}}}r?>^+>ryy+-?r+~vyyz??vrxuuuuy^<br></br>
`}rr?}uw}vv+^?>?v|+>ruzr.`. `}}}}}v  .+}?+}uyy?|~~~>w?~?v?+|xyv+>^?~vyuuuu^<br></br>
`rrr}??+??|. ^>?ur^>?uyy^ . .vr}rw?   `>v?>?v?>++++>>>>>>+|vx}++???~}uxwrw~<br></br>
`rrwr?^-~^|. `^?vyv+>?yyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-...-wz?>>v?> ||`zwz> .rz| -zzw?^>>>>>>>r^?xuuv-`+^v>?zwwwwww|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}| >vwwr. +zxv  ruuw^>>>>>>>w?>uv-....|zr>>}wwwzz|<br></br>
`zzzzzzzzzzz?-...|rzw}>+?v}.`zxu? .wzzz` ^xzw+>>>>>>>?x+~....`..?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`zxxxxxxxxxxxxu?>|>^?v?v?vvv>`}? .wxzzxw. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
`xuuuuuuyyyyyyzvvr?v???????v??+. +zwxxxu> `zxx?>>>>>+^+>>>>>^^?vzuxxxxxxxx|<br></br>
-xuuuuxxuuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuuw}v?zr|?????vr??}v??v???zuuuuuu? .zuuv??>>~^xuxxuuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz??v>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzwr?+????vv??????^>v}zzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`wwrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}xxz?}?>||wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>>vrrzxxxxxxxxxxz?^vzzxw^+>^~}xxzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrwwwwwwwwwwwwwr}}}}rwzzwwwwwwwwwwwwwwwwwwww}v????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrrr}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwwwv??????????????????????????????????????>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx?vyyyyyuzvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyx?????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??vvvvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuuxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?++++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwrr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzv}???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?ur^>?uyy+ . .v}}rw?   ->vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?^-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>}v> ||`wwz> .}z~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  wuuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}.`zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>|>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>>vv>>>>- `}xxxxxxxx|<br></br>
`xuuuuuyuyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
-xuuuuxxxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuxxuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuxuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uxz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzwv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}rrrrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyu}v+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvyyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?++++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>?+vvvv?.     ?vwxuxxuxwrr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~}v?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzv}???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?|vyuuuu|<br></br>
`rrr}??+??|. ^>?ur^>?uyy+ . .v}}rw?   ->vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?^-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  wuuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}.`zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyuyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
-xuuuxxxxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuxuxxxuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrrwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}xuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}rr}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwwwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvv??vvvvvvvvvvvvvvvvv??vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyu}v+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?++++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??r?vvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwrr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`vvvvvvzv}???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?|vyuuuu|<br></br>
`rrr}??+??|..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  wuuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}.`zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuv}uxzzx|<br></br>
`xxxxxxxxxuxxxu?>|>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyuyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
-xuuuxxxxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuxuuuuuuuuuuuuuu|<br></br>
`zzzzzzzzzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}xuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}r}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwwwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvv??vvvvvvv?vvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuu}v+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??vvvvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??r?vvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`v}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   ->vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}.`zxu? .wzzz` ^zwz+>>>>>>>?x+|.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>|>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
`xuuuuuyuyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuxuxxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwrr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}vv?vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`v}vvvvzv}???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   ->vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  wuuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?vr.`zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuv}uxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuxxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu^<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`v}vvvvzv}???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`-~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~-|^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvv?vv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvv?vvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxzzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??vvvvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??r??vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvvvv?vvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwrwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?v?vvvvvvvvvvvvvv?vvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xx|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv????vvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vvvv}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`~^~``->?+??rvvvvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-`.->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}+^???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxuuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>~^+`??}????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxu?>^>^?v?v?vvv>`}? .wxzxxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuuuxxuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>>>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}rrrr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvv?vvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??????????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~-|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  wuuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxxzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxu?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
-xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxzxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuxuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vrwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv??????????????????????????????????????>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzvvvvvvvvvvvv?vvvvvvvvvvvvvvvvvvvvvvvvv?}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??????????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~-|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`}}vvvvzvr???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxu?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxzxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuxuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?rv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vrwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrrrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>~^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvvr?v???????vv?+. +zwxxxu> `zxu?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvvr?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xxw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvvvvvv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvv??vv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvv?vvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyyw}v???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvv??vv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvv??vv??>???vv}zxuyz??+vuyuz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`?yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^}}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuyyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vuuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}r}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?????????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx>?yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v??vvvvvvvvv??vv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv??ww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|``~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`v}}vvvzv}???|-~^^?vvv>?+^>^?v}}?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vxyy?|~~~>w?|?v?+|xy}+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vyv+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zxw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yu~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxu?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxxxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v??v???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?v}v?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~+|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??vvvvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v??v???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzv}???|-~^^?vvv>?+^>^?v}v?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>wxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuuuu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrrzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v?vvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyyuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v??v???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzv}???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrrwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v?vvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v??v???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>>ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v?vvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyyyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvv???v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>wyy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwrrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyyuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxu?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v|.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v>^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuxxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}v}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zzv  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuyurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuxuuxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~^^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^v}}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuuuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxxxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxxxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?v????vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vv???vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v?vvvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv?vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv?vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv?>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv?vvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>????????????>??????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv??vvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv??vvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv?vvvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv??vvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv?vvvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvv??vvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv?vvvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv?vvvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwwwwwrwv>>???????????????????????????????????>>?wrwwrrrrrrrrrrrrrr~<br></br>
-uyyyyx??yyyyyuzv?vvvvvvvvv??v??vvvvvvvvvvvvvvvvvvvvv??}yyyyyyyyyyyyyyuuuy^<br></br>
-uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuuw?}uy^<br></br>
-uuuuuy}. -ryyyyxv????????????v????????????v??v???????vvwyz}zyyuuuurv+?}xu|<br></br>
-uuuuuyyz| .vuzzrvvvvvvvvv??v?vvvvvvvvvvvvvv??>???vv}zxuyz??+vuyxz}}v?vvvv-<br></br>
-uuyxvvuyu++|vwv???vvvvvvv?}?vvvvvv????vvvvv?vww}zxuyyyyu?rr|vv}v}v??vvvvv-<br></br>
`}}r}??r}v>?>+++^^||?v??vv?}?vvvvv?vrw}?vvvvv?r}vxyyuuyuz}^..^}??v?vvvvvvv-<br></br>
`vvv??>^^|`-~~`|^~``->?+??rv?vvvvv?}r}w?vvvvvvvvvxyyuuz}?~.  .~?vvvvvvvvvv-<br></br>
`vv??v~.-|~||^~-..->^^??^^??vvv????>|-^????vvvvv}uxr}}vv-     |vvvvvvvvvvv-<br></br>
`vv>?v?~..```````+}r}+^>?>>v}v?^++|-~|`^^^+>vvvv>>+vvvv?.     ?vwxuxxuxwwr~<br></br>
`vv?????>??|`|||^?yu?>>|?v+vu}~~?v+`~~-`>?~~?vvv???vrwwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>}v>-`+^`-`-`?~`^vvvvvvwuyyyw`...`>yyyyyyyyuyyy^<br></br>
`}}}vvvzvr???|-~+^?vvv>?+^>^?vvv?^`--`-`~?vvvvvvvvxyyyx|--`~~wyyyuuyuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}}?^.`..^vv}}}?~~|~~?}}}}r?>++>ryy+-?r+-vyyz??vrxuuuuy^<br></br>
`rrr?}uw}vv+^v>??|+>rxzr.`. `v}}}}v  .+}?>vuyy?^~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~..^>?ur^>?uyy+ . .v}}rw?   `>vv>?v?>++++>>>>>>+|vx}++???~}uxwrr~<br></br>
`rrwr?|-~^|. `^?vy}+>vyyv.`  +uuuy^ .. .+>>>>>>>>>>>>>>^||?}>|v}^?+>rrrrrz|<br></br>
`zzw?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrzu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wwz> .}w~ -zzw?^>>>>>>>r^?xuuv-`+^v>?zzwwwwz|<br></br>
`xxzxxuuuuuz`....`rzr+>+?}^ >vwwr. +zz}  ruuw^>>>>>>>w?>uv-....|zr>>rwwwwz|<br></br>
`zzzzzzzzzzz?-...^rzw}>+?v}..zxu? .wzzz` ^zww+>>>>>>>?x+~.......?x>+?zzzzz|<br></br>
`zxxzzzzzzzzxz>|^+`??v????}^-?yy~ >uxxx> .wzxv+>>>>>>>vw?|`-??|. vuvvuxzzx|<br></br>
`xxxxxxxxxuuxxx?>^>^?v?v?vvv>`}? .wxzzxr. ?uux>>>>>>>>>?vv>>>>- `}xxxxxxxx|<br></br>
-xuuuuuyyyyyyyzvv}?v???????vv?+. +zwxxxu> `zxx?>>>>>+^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xuuxxxxxuuuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|vv>+>?vwuuxxxxxxxxxu|<br></br>
`xuuuuuuuxw}v?zr^?????vr??}v??v???zuuuxxu? .zuuvv?>>~^xuxxxuuuuuuuuuuuuuuu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxux. ^uux?}?>+~vuuuuuuuuuuuuuuuuuuu|<br></br>
`zzzzzzzwzzzzzwr?+?????v??????^>v}wzzzzzzxv..}xzw?wv>||}xxxxxxxxxxxxxuuuuu|<br></br>
`rrrrrrrrrrrwwwwz}>>??????v>>|^>vrxxxxxxxxx+.~}uuz?}?>|^wxxxxxxxxxxxxxxxxu|<br></br>
`rrrrrrrrrwwwwwzzzzv>++>>++>?vrwzxxxxxxxxzxz?+vxzxw^+>^~vxxzxzzzzzzzzzzzzz|<br></br>
`rrrrrrrrwwwwwwwwwwwwr}}}}rwwzwwwwwwwwwwwwwwwwwwww}?????vwwwwwwwwwwwwwwwww~<br></br>
`rrrrrrrrrrrrrrrrrrrrrrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}rr}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrwzzwwwwwwv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?wwwwwrrrrrrrrrrrrr~<br></br>
-uuuuux>?yyyyyuw???????????????????????????????????????}yyyyyuuuuuuuuuuuuu^<br></br>
                  -uuuuuw  |xyyywvv???????????+??????????????????????????vxyyyyuuuuuuuxw?vuu^<br></br>
-uuuuuu}. -ryyyyz??????????????????????????????????????vryz}zyyuuuu}v+?}xx|<br></br>
-uuuuuuyz| .vuwz}???????????v?????????????????>????v}zxuyz??+vuyxz}}v??vvv-<br></br>
-uuuxvvuyu+^|vw????????????}??????????????????wwvwxuyyyuu?r}~vvvv}v??????v-<br></br>
`}}r}??rvv>?+++^^^||???????}???????vrw}???????r}vxyyuuuuz}^..^}??????????v-<br></br>
`?????>^||``~~`~^~``->?+>?r??vvvvv?}r}w??v?vv???vxyuuxw}?~.  .~????vvvvv?v-<br></br>
`vv??v~.-|~||^~-..->^^??^^???vv????>|-^????vvvvvvuxr}}vv-     |v?vvvvvv??v-<br></br>
`vv>?v?~..``````.+}r}+^>?>>v}v>^++|-~|`^^^+>?vvv>>+vv???.     ?vrxuxxuxwr}-<br></br>
`vv????>>??~`||~^?yu?>>|?v+vuv--?v+`~~-`>?~~?vvv???v}rwv.    .vuyyyyyyyyyy^<br></br>
`vvvvvvvvvvv?-|||~vv?|>>^v>>vv>-`+|`-`-`>~`^vvvvvvwuyyyw`...`>yyyyyyyuuuuy^<br></br>
`vvvv?vz?}???|--^^?v?v>?^^>^?vvv?^`--`-`~?vvvvvv?vxyyyz|--`-~wyyyuuuuuuuuy^<br></br>
`}}}v}zyuyv>^+?v?>??}v?|.`..^vvvvv?~~|~~>vvv}r?>++>ryy+-?r+-vyyw??vrxuuuuy^<br></br>
`}}}?}uw}vv^^?>??|+>rxzr.`. `vvvv}?  .+}?+vxyy?|~~~>w?|?v?+|xyv+>^?~vyuuuu|<br></br>
`rrr}??+??~. ^>?ur^>?uyy^ . .v}}}r?   `>vv>?v?>++++>>>>>>+|vz}+^???-}uzwrr~<br></br>
`rrw}?|-~^|. `^?vyv+>?yyv.`  +xuuy^ .. .+>>>>>>>>>>>>>>^||?}>~v}^?+>rr}}rz|<br></br>
`zzr?>?..`|. .|~.vuv>+ruz- -.^yuuz. >v  >v>++>>>>>>>>v|~~^}r?>r?}?|rrrrrwu^<br></br>
-yzv}?>vzw|``-..`-wz?>>vv> ||`wrw> .}w~ -zwr?^>>>>>>>r|?xuuv-`+^v>?zwrrrww~<br></br>
`xxzxxuuuuuz`....`}wr+>+?}^ >vww}. +wzv  ruuw^>>>>>>>w?>uv-....|zr>>}wwwwz|<br></br>
`zzzzzzzzzzw?-...|}zw}>+?v}..wxu? .rzzw` ^zww+>>>>>>>?x+~.......>x>+?zzzzz|<br></br>
`zzzzzzzzzzzxz>~^+`??v????}^-?uu~ >uxxx> .rzxv+>>>>>>>vw?|`-??|. vuvvxzzzz|<br></br>
`zxzxxxxxxxxxxx?>|>^?v?v?vvv>`}? .wxzzzr. ?uux>>>>>>>>>>vv>+>>- `}xzxzzzzx|<br></br>
-xxuuuuuuuyyuyzvv}?v???????vv?+. +zwxxzx+ `zxx?>>>>>^^+>>>>+^^?vzxxxxxxxxx|<br></br>
`xxxxxxxxxxuuuw+>??????????v??>>???rxxxxz. ?uxw>>>>+~|?v>+>?vwuuxxxxxxxxxx|<br></br>
`xxxxxxxxxwvv?zr|?????vr??}v??v???wuxxxxu? .zuuv??>>~^xuxzxuuuxxxxxxxxxxxu|<br></br>
-uuuuuuuuz?>??vv|>????rz???>????vxuxxxxxxz. ^uux?}?>+~vuxxxxxxxxxxxuuuuuuu|<br></br>
`zzzzzwwwwzzzzw}?+?????v??????^>vvwzwzzzzzv..}xzw?rv>||}xxxxxxxxxxxxxxxxxu|<br></br>
`rrrr}}}rrrrrwwww}>>??????v>>|^>vrxxxxxxxxx^.~}xxz?}?>||wxxxxxxxxxxxxxxxxu|<br></br>
`}r}}}}rrrrrrwwwwzwv>++>>++>>vrrwzzzzzzzzzzz?+vzzzw^+>|~vxzzzzzzzzzzzzzzzz|<br></br>
`rrrrrrrrrrrrwwwwwwwr}}}}}}wwwwwwwwwwwwwwwrwwwwrrr}?????vrwrwwwwwwwwwwwrrw~<br></br>
`}}}}}}}}}}}}}}}}}}}rrrrrrrr}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}}-<br></br>


            </div>,

            <div>

`rrrrrrwwrrrrrwv>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?rrrrrrrrrrrrrrrrrr~<br></br>
-uuuuuz>?yyyyyuw???????????????????????????????????????vyyyuuuuuuuuuuuuuuu|<br></br>
                  `xuxxuw  |xyuurv????????????+???????????????????????????xuyyuuuuuxxxxr?vxu|<br></br>
`xuxxxu}. `ryuyyz????????????>??????????????>???????????ryzvzuuuuuu}v+>vzx|<br></br>
`xuxuuuuz| .?uww}???????????????????????????>?>>????vwzuyw??^vuuxw}vv?????-<br></br>
`xuuzvvuyu^^~vr????????????}??????????????????rwvwzuuuuuu>}}~vvvvvv???????`<br></br>
`vv}v?>}v?>>+^^^||~~>??????v???????vrr}???????}vvzyuuuuuz}^..^}???????????-<br></br>
`?????+||~``~-`~|-```>>^>?}???????>}}vr??????????zyuuxw}?~.  .~>??????????-<br></br>
`???>v~.-~-~~|--..`+||>?^^>>???????+~-^>????????vuz}vvvv`     ~???????????-<br></br>
`??>??>-..`````..+vr}+^+?>>vv?>^++~--~`|^^^+????+>+vv???.     >vrzxxxxzr}v-<br></br>
`??????>+>?~`~~~|?ux?>>~?v^vuv-->v^.-~-`>?~~????????}rrv.    .vuyyuyuuuuuu|<br></br>
`vvvvvv??vvv>-~|~~vv?|++|v>>vv>``^|`````>~`|???vvvrxuyyr`...`>yyuuuuuuuuuy^<br></br>
`vvvv??w?}?>?~`-^^????>?^^>^?vvv?|````-`->?vvvv??vzyuyz|`-`-~wyyuxxuuuuuuu^<br></br>
`vvvvvzuxuv>^+???>?>vv?|.`..|vvvvv>~~~-~>vvvv}?>^+>ryu+`>r^-vyyw??v}xuuuuu^<br></br>
`vvv?vur}v?^^?+??|++rxw}.`. `vvvvv?  .^v>+vxuu?|--->w?~?v?^|xyv+>|?~vuuxxx|<br></br>
`}}}v?>^??~. ^+?ur^>>uyy^ . .?vvv}>   `>v?>?v?>^+^+>++>>>^~?zv^^?>?-}xzr}}~<br></br>
`}}r}?|-~^|. `^?vyv++?uyv..  ^xxuy^ .. .++>>>>>>>>>>>>+^~~?v>~vv|>^+}}vv}w|<br></br>
`wzr?>?..`~. .|~.vuv++}xz- -.^uxuz. +v  +?>+^+>>>>>>>v|--|}}?>r?v?~}}}}}wu|<br></br>
-uwvv?>vzr~``-...-rw>>>v?> ~~`r}r+ .vr~ -wr}?|>>>>>>>}|?xuuv`.+|v+?ww}}rrr~<br></br>
`zzwzxxuuuxw`....`}r}+>^?v| +?rr}. +rwv  ruxr|>>>>>>>r?+xv-  ..~w}>>}rrrrw~<br></br>
`wwwwwwwwwww>-...|}wrv++?v}..wxu? .rwww` ^wrr++>>>>>>?x^~. .....>z>+>wwwww~<br></br>
`wzwwwwwwwwwzw>~^^`>?v????v^-?uu~ >xzzz> .rwzv+>>>>>>>vw?~``??~. vxvvxzwwz|<br></br>
`zzzzzzzzzxxzzx?+|+^????????>`v? .rzwwz}. ?xux>>>>>>>>>>v?++>>` `}zzzzzzzz|<br></br>
`zxxuuuuuuuuuuzvv}?v??????????+. +wrzzzz+ `wzx?+>>>+^|^+>>>+^^?vwzzzzzzzzz|<br></br>
`zxxxxxxxxxxxxr+>??????????v??>>???}zzzzw. >xzr+>>>^-~??>+>?vwxxzzzzzzzzzx|<br></br>
`xxxxxxxxzrvv?w}|?????v}??vv??v???wxxxxxx? .wxx??>>+~^zxzzzxxxxxxxxxxxxxxx|<br></br>
`xxxxxxxxz?>????~>????rz???>???>vxxzzxxxxz. ^xxz?}?>^~?uxxxxxxxxxxxxxxxxxu|<br></br>
`wwwwwwwwwwwwwr}?+??????>?>???^+vvrwwwwwwz?..vzzr?rv>||}xzzzzzzzzzzzxxxxxx|<br></br>
`}}}}}}}}}}}}rrrw}>+??????v>>||>?}zzzzzzzxx^.~}xxw?}?+~|wxzzzzzzzzzxxxzzxx|<br></br>
`}}}}}}}}}}rrrrwwww?+^+++^++>?}rwzzzzzzzzzzw?^?zzzr^++|-vzzzzzzwwwwzzwwwww~<br></br>
`}}}}}}}}}rrrrrrrrrr}}vvvv}rrwwrrrrrrrrrrrrrrrrrrrv?????vrrrrrrrrrrrrrrrrr~<br></br>
`}}}}}}}}}}}}}}}}}}}}}}}}}}}}vvvvvvvvvvvvvvvvvvvvvv}}}}}vvvvvvvvvvvvvvvvvv-<br></br>


            </div>,

            <div>

`}}}}}rwwrrrrrrv+++++++++++++++++++++++++++++++++++++++?rrrrr}}}}}}}}}}}}}~<br></br>
`xxxxxz>?uuuuuxr???????????>>?????????????????????????>vuuuuuxxxxxxxxxxxxx|<br></br>
`xxxzxr  |zuuu}??>>>>>>>?>>>^>??>??>>>?????>>>>???>??>>?zuuuuxxxxxxxz}>vxx|<br></br>
`xxxxxxv. `ryuuuw??????????>>>??????????????>??>????>>??}uwvwuuxxxxvv^>vwz~<br></br>
`xxxxxxuw| .?xrrv????????????????????????>??>?+>>???vrzxuw??^?xxzw}vv>????`<br></br>
`zxxw?vxux^|~v}?>?>???????>v???????>??>?????>?rrvrzxuuuxx>vv~vvvvvv?>?????`<br></br>
`vvvv>>vv?+>^^|||~~~>??????v>?????>?}rv???????}v?zuuxxxxwv|..^}>??????????`<br></br>
.???>>+~~~``-``-~-`.`+>^+>}???????>v}vr??????????wuxxzrv?~.  .->??????????`<br></br>
.??>>?-.`-`--~-`..`+~|>?||>>????>>>+~`|>>>>??????xz}vvvv`     ~???????????`<br></br>
.??+>?>- ..`.`...^v}v^|+>>>?v?+|^^~`-~`|||^+????^>^v???>.     >?}zxzzxw}vv-<br></br>
.??>>>>+^>>-`-~-~?xz?>>~?v^?x?-->?^.--``+?-->???>>>?v}}?.    .vxuuuuuuuuuu|<br></br>
`???????????>`~~~-v?>~++|?++??+``^~`````+-`~??????}xuuyr`....>uuuuuuuxxxuu|<br></br>
`??????r?v?>>~`-^|>???+>^|+^????>|.``.``-+????????zuuuz~````-ruuuxxxxxxxxu|<br></br>
`vvv?vwuxxv>|^???+>>??>~.`. ~?????>--~--+???vv?>^^>}yu^`>}^`vyuw???}zxxxxu|<br></br>
`vvv?vxrv??||?+??~^+}zrv.`. `??????  .^v>^?zuu?|--->r?~??>^~zuv^+|?~vxxxzx|<br></br>
`vvvv>>^?>~. |+?u}|+>xuu^ . .?vvvv>   `>v?+?v?+^^^^+++>>+^~?wv^^?>>-vzw}vv-<br></br>
`vv}v>~-~|~. .|>vuv++?uu? .  ^zzxu^ .. .^++++++>>>>++>+|~~>v+~?v|>^+vvvvvr~<br></br>
`rw}?+?  `~  .~-.vxv++vzw` - ^uxxw. +?  +?+^^+++++++>v~--|v}?>}?v>~vvvvvrx|<br></br>
`uw?v?>?wr~```. .`}r>++v?> ~~`}v}+ .v}~ `r}v?|+++++++}~?zxxv`.^|?+>rrvv}}}~<br></br>
`wwwwzzxxxzr` ...`vrv^+^?v| +?}}v. ^}rv  }xz}|+++++++}?+zv-  ..~rv++v}}}}r~<br></br>
`rrrrrrrrrrr>`...~vr}v+^??v..rzx? .}rrr. |r}}^+++++++?z^-. .....>w>^>rrrrr~<br></br>
`rwrrrrrrrrrwr+~||.>>v?>??v|-?ux~ >zwww+ .}rwv^+++++++?r?~.`??~  vx?vzwrrw~<br></br>
`wwwwwwzwwzzwwz?+|+|>???????+`v? .}wrrw}. ?zxz+++++++>+>??++>+` `vwwwwwwww~<br></br>
`zzzxxxuuuuuxuwvvv????????????^. ^w}wwwz+ `rwz?+++++^|^+++++||>?rwwwwwwwwz~<br></br>
`zzzzzzzzzzzzx}^+>????????????+>>??vwwwwr. >zw}+>++^-~??>^+>?rzzzwwwwwwwwz|<br></br>
`zzzzzzzzzrv??rv~>?????}??vv??v???rzzzzzx> .wzz??>++-|zzzwzzzzzzzzzzzzzzzz|<br></br>
`zxzzzzzzw?>?>??~>???>}w???>???>vzzzzzzzzw. |zzw?v?+^-?xzzzzzzzzzzzzzzzzzx|<br></br>
`wwrrrrrrrrrrr}v?^??????>?>???|+vvrrrrrrrw?..vww}?}?>~~vzwwwwzzwzzzzzzzzzz|<br></br>
`vvvvvvvvvvv}}}}rv++>?????v>+~|>?}wzwzzzzzz^.-vzzw?v?+~~rzwwwzwwzzzzzzzzzz|<br></br>
`vvvvvvvvv}}}}rrrrr?+^^^^|^+>?}}rwwwwwwwwwww>^?www}^^+|-vwwwwwwwwwwwwwrrrw~<br></br>
`vvvvvvvv}}}}}}}}}}}}vvvvvv}rrrr}}}}}}}}}}}}}}}}}}v???>>?}}}}}}}}}}}}}}}}}-<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv-<br></br>


            </div>,

            <div>

`v}}}}}rr}}}}}r?^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^>}}}}}}}}}}}}}}}}}}-<br></br>
`zzzzxw>?xuxxxz}>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>?xxxxxzzzzzzzzxzzzx|<br></br>
`zzzzz}  |zuxxv??>>>>>>>>>>>|>>>>>>>>>>>>>>>>>>>>>>>>>>?wxxxxzzzzzzzw}>?zz|<br></br>
`zzzzzzv. `}uxxxr>>>>>>>>>>>>+>>>>>>>>>>>>>>+>>>>>>>>>>?vxwvrxxxzzzv?^+vrw~<br></br>
`zzzzzxxr~ .?z}rv>>>>>>>>>>>?>>>>>>>>>>>>>>>+?++>>>??}wzxr?>|?zxwrvvv>>?>?`<br></br>
`zzzw??xxz^|-?v>>>>>>>>>>>>v>>>>>>>>>>>>>>>>>?}}?}wzxxxxz>vv-???vv??>>>>>>`<br></br>
`??v?++v??+>|||~~~--+>>>>>>v>>>>>>>?}}v>>>>>>>v??wuxzzzzrv|..|v>?>>>>>>>>>`<br></br>
.>>>>>^---..``.`-`..`+>|^>v?>>>>>>+vvv}>>>>>>>>>?rxxzwrv?-.  .-+>>>>>>>>>>`<br></br>
.>>>+?-.`-`---``..`^~~+?||>+>>>>+>>^-`~+>+>>>>>>?zwvvvv?`     -?>>?>>?>>>>`<br></br>
.??^>?+` ........^v}v^|^>++???+|^^~``-`~|||^>??>^+^v??>>.     +?vwzwwzwvv?`<br></br>
.??>>>+^^+>-.---~>xz?++->?|?z?``+?|.--``+>--+???+>>>vvv?.    .?zxxxxxxxxxx|<br></br>
.??????>????+`-~--??>~^^~?++??^``|~.`.``+-.~??????vzxxu}.. ..+uxxxxxxxxxxx|<br></br>
.????>?r?v>+>-``||>?>?+>||+|>???>~.``.`.`+?????>>?wxxxw~````-}xxxzzxxzzzzx|<br></br>
`????vrxzz?+|^>?>+>+??>~.`. ~?????+---`-^????v>+|^+}ux^`>v|`?uur>??vwzzzzx|<br></br>
`???>vz}v??|~>^>>~^^vw}v.`  .?????>  .^v>^?wxx?~``-+r?->?>|~zuv^+~>-vxzzwz~<br></br>
`????>+|?>-. |^>x}|+>xuu| . .????v+   `+??+???+^^|^+^^+++|-?rv||>>>`vzrvvv-<br></br>
`vvvv>~`-|~. .|>?uv^+?xx? .  |wzzu^ .. .^^+++++++++++++|-->?^-?v~>|^vv???}~<br></br>
`}rv>+?  `~  .~-.?z?^^vwr` ` |xzzw. ^?  ^?+||^+++++++?~``~vv>+v>v>-vvvvv}z|<br></br>
`xr??>>?r}~```. .`v}>++??+ -~`vvv^ .?v- `}vv>~+++++++v~>wzx?`.^~?^>}}vvvvv-<br></br>
`rrrrwwzzzwr` ....vvv^+|>?~ ^?vvv. ^v}v  }zw}~+++++++}?^w?-  ..~}v++vvvvv}~<br></br>
`}}}}}}}}}}}>`...~v}v?^^??v..}wz? .v}}}. |}vv^+++++++?w|-. .....+r+^+}}}}}~<br></br>
`}rr}}}}}}}}r}+-~|.>>?>>??v|->xx~ +wwrr+ .v}r?^+++++++?}>-.`?>~  ?z??wr}}r~<br></br>
`rrrrrrwwwwwwww?^~+|>???????+`v? .vr}}rv. >wzw+++++++++>??^^++` `vrrrrrrrr~<br></br>
`wwzzzxxxxxxxxr??v>???????????^. ^r}rrrw^ .}rw?^++++|~|^+++^|~>?}wrrrrrrrw~<br></br>
`wwwwwwwwwwwwz}|+>??????????>?++>?>vrrrw}. >wr}^+++|`-??+^+>?}wwwrrrrwwwrw~<br></br>
`wwwwwwwww}??>}v->?????v??v???????}wwwwwz> .rww??>+^-|wwwrwwwwwwwwwwwwwwww~<br></br>
`zzzzzzwzr>+>>??->???>vr>??+???>?wwwwwwwww. |wwr>v>+|-?zwwwwwwwwwwwzzzzzzz~<br></br>
`rr}}}}}}}}}}}vv>^>?????>>>>??|^??}}}}}}}r?. ?rr}>}?+~~vwwwwwwwwwwwwwwwwwz~<br></br>
`vvvvvvvvvvvvvvv}v^^>??????>+~~+?vwwwwwwwww| -vwwr?v?^~~}wwwwwrwwwwwwwwwww~<br></br>
`vvvvvvvvvvvvv}}}}}?^|^^^||^+?vv}rrrrrrrrrrr>|?rrr}|^^~`?rrrrrrrrrrrrrr}}r~<br></br>
`vvvvvvvvvvvvvvvvvvvvvvvv?vv}}}}vvvvvvvvvvvv}}vvvv?>>>>>?vvvvvvvvvvvvvvvvv-<br></br>
                  `vvvvvvvvvvvvvvvvvvvvvvvvvvvv??????????????????????vvvvvv?????????????????`<br></br>


            </div>,

            <div>

`vvvvvv}}}}}}}}?|||^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^||^|>}}}}vvvvvvvvvvvvvv-<br></br>
                  `wzwwzr+?xxzxxwv+++++++++++++++++++++++++++++++++++++++?xxxzzzwwwwwwwzwwwz~<br></br>
`wwwww}  ~wxzzv?>+++++++++++|++++++++++++++++++++++++++>rzzxzwwwwwwwrv+?ww~<br></br>
`wwwwwzv. `vxzzz}++++++++++++^++++++++++++++^>>++++++++>vxr?rzzzwww??^^?}r~<br></br>
`wwwwwzzr~ .?zv}?++++++++++>>++++++++++++++++>^++++>?vrzzr>>|?wzr}vvv++>>>`<br></br>
`wwwr??zzw|~-?v++++++++++++?>++++++++++++++++>v}?vrwzzzzz+vv-????v?>+++++>`<br></br>
.????+^v?>^+|~~~--``^>++>+>?+>++>>+?vvv>++++++v??rxzwwwwrv~..|v>>>++>++>+>`<br></br>
.>++++^-``..``.`-`..`^+|^+v>+>>>>>+vvv}>>>>>>+++>}zzww}v>-.  .-^+>>>>>>>>>`<br></br>
.>>++?-.`````-``..`|-~+>~~+++>>++++|-`~^+++>>>>>?wrv?vv?`     ->>>>>>>>++>`<br></br>
.>>^+>+` ........^?vv|~^+++??>^~||-``-.~~~||>>>>|+|??>>+.     ^?vrwrrwrvv?`<br></br>
.>>++++^|^+-.```->zw?++->?|?w?``^?~.```.^>-`+>>>+++>?vv?.    .?wzzzzzzzzzz~<br></br>
.>>>>>>>>>>>^`----??+-^^~?^^?>^`.~~.`.`.^`.->>>>>>vwzxxv.. ..+xzzzzzzzzzzz|<br></br>
.>?>>>>}>?>^+-``|~>?>>^>~~^|+>>>+~....`.`^>>>>>>>>rxzxr-.`.``}xxzwwzzwwwwz|<br></br>
.???>?}zwz?^~|+>>^>^??+-... ~>>>>?^``-``^??>??>^~|+vxz|`+v|`?xx}>>?vwwwwzz|<br></br>
.???+?wv??>~~>|>>-|^vrvv..  .>????>  .|?+|?rzz>~```+}?->?+|-wx?|^~>-?zwwww~<br></br>
.????++|>>-  ~^>zv~^+zxx| . .>????+   `+??^??>^||||^^^++^|->}?||>++`vw}???`<br></br>
                  .??v?+-`-~~. .|+?x?^^?zz? .  |rwwx|  . .|^^^^^^^^^^^^+^~--+?^-??~+||?????v-<br></br>
`v}v+^>  `-  .-`.?w?^^vr}` ` |zwwr. |?  |>^||^^^^^^^+?-``~?v>+v>?>-?????vw~<br></br>
`z}??>+?r}~`.`. .`vv+^^??^ --`v?v^ .??- `vv?>~^^^^^^^v->wzz?`.|~?^+vv????v-<br></br>
`}}}}rrwwww}` .. .?v?|^|>?~ ^>???. |vv?  vwrv~^^^^^^^v>^r?`  ..-v?^^?vvvvv-<br></br>
`vvvvvvvvvvv+`...~?vv?^^>??..vrw> .vvvv. ~vvv|^^^^^^^>w|`   ....+}+^+vvvvv-<br></br>
`v}vvvvvvvvvvv^-~~.+>?>>>??~`>zz- +rr}}^ .vv}?|^^^^^^^?}>-.`>>-  ?w??r}vvv-<br></br>
`}}}}}}r}}rrr}r>^~^~>???>???+`?> .v}vv}v. >rww^^^^^^^+^+??^^^^` `?}v}}}}}}~<br></br>
`rrwzzzzzzzzzz}??v>??????????>^. |vv}}}}^ .v}}>^^^^^|~|^^^^|~~+?v}}}}}}}}}~<br></br>
`rrrrrrrrrrrrwv|^>??????????>?++>?>?}}}}v. +r}v^+^^|`->?^|^+?vrr}}}}}}}}}r~<br></br>
`rrrrrrrrrv??>}v->?????v?>v?>???>>vrrrrrr+ .}rr??+^^`~rrr}rrrrrrrrrrrrrrrr~<br></br>
`wwwwwwrw}>+>>??-+???>vr>>?+>??+?rrrrrrrrr. |rr}>v>^|`?wrrrrrrrrrrrrwwwwww~<br></br>
`}}vvvvvvvvvvvv?>|>?????>>>>??~^??vvvvvvv}?. ?}}v>v?+--vr}rrrrrrrrrrrrrrrw~<br></br>
`???????????vvvvv?^^>??>???+^~~+?vrrrrrrrrr| -?rr}>v>^--vrrrrrrrrrrrrrrrrr~<br></br>
`?????????vvvvvvvvv>|~|||~||^?vvv}}}}}}}}}}}+|?}}}v||^~`?}}}}}}}}}}}}}}}v}-<br></br>
`???????vvvvvvvvvvvvv??????vvvvvvvvvvvvvvvvvvvvvvv?>>>++?vvvvvvvvvvvvvvvvv-<br></br>
                  `?????????????????????????????????????????????????????????????????????????`<br></br>


            </div>,

            <div>

                  `vvvvvvvvvvvvvv?~|||||||||||||||||||||||||||||||||||||~+vvvvvvvvvvvvvvvvvv-<br></br>
`rrrrw}+?zzwzzrv^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^?zzwwwrrrrrrrrwrrrr~<br></br>
`rrrrrv  ~rzww?>+^^^^^^^^^^^~^^^^^^^^^^^^^^^^^^^^^^^^^^+}wwzwrrrrrr}}v+?rr~<br></br>
`rrrrrr?. `vzwwwv^^^^^^^^^^^+|^^^^^^^^^^^^^^|++^^^^^^^^+?w}?}wwwrrr??|^?v}-<br></br>
`rrrrrww}~ .?rvv?^^^^^^^^^^+>^^^^^^^^^^^^^^^^>|^^^^+?v}rw}>+~?rw}v???^^+++.<br></br>
`rrr}>?wwr|~`??^^^^^+^^+^^^?+^^^^^^^^^^^^^^^^>vv?v}rwwwwr+??`??????+^^^^^+.<br></br>
.>???^|?>+|^~~~-````|++^+^+?^^^^^+^?vv?+^^^^^+??>}zwrrrr}?~..~v+>+^^+^^^++.<br></br>
.+^+^^|```..`..```..`^^~|^v>^+++++^?v?v+^++++^^^+vwwr}v?>-.  .`|^+^+++++++.<br></br>
.++^^>`..``````.. .~--^>~~+^^++^^^^~``-|^^^+++++>r}?????`     `+^++++++^^+.<br></br>
.++|^>^` ........|?v?|~|^^^?>+|~|~````.-~~~|++++~^|?>++^.     |>?}r}}r}???`<br></br>
.++^^^^|~|^`.```->wr>^^`>?~?r>``|>~.```.|+``^+++^^^+???>.    .?rwwwwwwwwww~<br></br>
.+++++++++++|.``-`>>+-||-?^^>+|..~-.....|`.-++++++?rwzzv.. ..^zwwwwwwwwwww~<br></br>
.>>>>++v>?+|^```~~+>++|+~~|~^+>>^-......`|+>+++++>}zwz}-.`.``vzzwrrwrrrrrw~<br></br>
.>>>+?vwrr>^~~^++|+^>>^-... ->>>>>|`````|>>>>?+^~|^vzw|.+v~.?zzv+>>?}rrrrw~<br></br>
.>>>^?rv?>>--+|++-||v}v?..  .>>>>>+  .~?^~>}ww>-```^v>`+?+~-rz?~^~+`?wrr}r~<br></br>
.???>^^~++`  ~|+wv~^+wzz~ . .+>>>?^   `^?>^>?>||~~|^||^^|~`>v?~~>^+`?}v???`<br></br>
.????^-``~-. .~^?z?||>ww? .  ~}rrw|  . .||^^^^^^^^^^^^|~``^>|`??-+~|??>>??-<br></br>
`vv?+^>  `-  .-`.?r?||?}v` ` ~wrr}. |>  |>|~~|^^^^^^^?-``~??+^v+?+`??????r~<br></br>
`wv>?+^?}v-..`. .`??^^|>>^ --.???| .>?` `v??+-^^^^^^^?->rww?`.|~>|+v??????`<br></br>
`vvvv}}rrr}v`    .???||~+?- |>???. ~?v?  vr}?-^^^^^^|v>|}?`  ..-??^|??????-<br></br>
`?v?????????^`  .-????||>>?..?}r> .?vv?. ~???~|^^^^^|>r~`   ....^v^|^v????-<br></br>
`vvvvvvvvvvvvv|`--.^+?+>>>?~`>ww- ^}vvv| .??v?|^^^^^^^>v+`..>+-  ?}>?}vvvv-<br></br>
`vvvvvvvvv}}vvv+|~^~+???>??>^`?+ .?vvvv?  +}rr|^^^^^^^^^?>||^|` .?vvvvvvvv-<br></br>
`}}}rwwwwwwwwwv???>?????????>>|. |v?vvvv| .vvv>|^^^|~-~||^^|~-^>vvvvvvvvvv-<br></br>
`}}}}}}}}}}}}}v~|+?????>>>>?>>^+>>>?vvvvv. +}vv|^^^~``>>^||+>v}}vvvvvvvvvv-<br></br>
`}}}}}}}}vv?>+v?`+??????>>??>???>>v}}}}}}+ .v}}>>+^|`-}}}v}}}}}}}}}}}}}}}}-<br></br>
`}r}}}}}}v>^++>>`+???+v}>>>^>??+?}}}}}}}}v. ~}}v+?+^~`>r}}}}}}}}}}}}}}}}}r~<br></br>
`vvvvvvvvvvvvv??+~>???>>+>+>?>~|???vvvvvvv>. ?vvv+v?^--?}vvv}}}v}}}}}}}}}}~<br></br>
.???????????????v?||+>>>?>?+^--^>?}}v}}}}}}~ `?}}v>?>|`-v}}v}}v}}}}}}}}}}}-<br></br>
.?????????????vvvvv>~~~~~~~||>??vvvvvvvvvvvv+~>vvvv~||-`?vvvvvvvvvvvvvvvvv-<br></br>
.???????????????????????????vvvv???????????????????+++^^>?????????????????`<br></br>
                  .?????????????????????????????????????????????????????????????????????????`<br></br>


            </div>,

            <div>

.??????vvvvvvvv>~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~^vvvv??????????????`<br></br>
`}}}}}v^>rwrrr}?|||||||||||||||||||||||||||||||||||||||>rrrr}}}}}}}}}}}}}}-<br></br>
`}}}v}?  ~}wrr?+^|||||||||||-||||||||||||||||||||||||||^vrrrr}}}}v}vv?^>}}-<br></br>
`}}}}v}?. `?wrrr?|||||||||||^~||||||||||||||~^^||||||||^?rv?vrr}}}}>>~|>vv-<br></br>
`v}}}}}}v- .>}??>||||||||||^+||||||||||||||||+|||||^>?v}rv++~>}}vv???||^^^.<br></br>
`v}}v>>}r}~-`>?||^||^||||||?^|||||||||||^||^|+?v>?v}rrr}}^??`>?>??>^|||||^.<br></br>
.>>?>||?>^~^---`````~^^|^|^?|^|||||>?v?^|^^||^?>+vrr}}}}v?-..~?^+^||||||^^.<br></br>
.^^^||~```......`....|^-~^?+|^^^^^|???v^|^^^^^||^vr}}vv?+`.  .`||^^^^^^^^^.<br></br>
.^^||+`....`.`... .~`-|+--^|^^^||||~`.-~|||^^^^^+}v????>.     `^^^^^^^^^^^.<br></br>
.^^~|+|. ........~???~-~^^^>>^~-~~`.``.---~~^^^^-|~?>+^|.     ~+?v}vvvv??>.<br></br>
.^^||||~-~|`.````+}v+||`+>~>v+``|+-.``..~^``|^^^|||^>??>     .>}rrrrrrrrrr-<br></br>
.^^^^^^^^^^^~.````>>^`~~->||+^~..-`.....|`.`^^^^^^?}rrwv.. ..^wrrrrrr}}rrr~<br></br>
.++^^^^v+>^~|`.`--^+^^|^--|-|^^^|`......`~^^^^^^^^vrrrv`....`vrrr}}}}}}}}}~<br></br>
.+++^?v}}}>|--|^^|^|++|`... `+++++~`````~++++>^|-~|?wr~.^?-.?wwv++>?v}}}}}~<br></br>
.>>+^>}??>+-`^~^^`~~?v??..  .+++++^  .~?^~+v}r+-```|v>`^>^~`}w?~|-+`?}}vvv-<br></br>
.>>>+||~+^`  -|+}?-|^rrr~ . .^+++>|   .|>>|>>+|~~-~||||||-`+?>~~+^^`?v?>>>`<br></br>
.>>>>|```--. .-|>r?~|>rr> .  ~vv}r~  . .~~|||||||||||||-``|+~`>?-^~~>>>+>?`<br></br>
`???^|+  ``  .``.>}>|~?vv` ` ~}vvv. ~+  ~+~~~~|||||||>```-??^|?^?^`>>>>>?v-<br></br>
`rv+>^^>vv-..`  ..??|||>>| ``.?>?~ .>>` `??>^-|||||||?`+}}}?`.~->~^??>>>>?`<br></br>
`v??vvv}v}vv.    .>?>~|~+>- ~+>>>. ~???  ?}v?-|||||||?+~v>`  ..`?>|~>?????`<br></br>
.???????????|.  .->??>~|+>>..?vv+ .????. -???~|||||||+v-`   .. .|?^||?????`<br></br>
`?????????????|`--.|^>^++>>-`+}}- |vvv?| .???>~|||||||>v^`..+^`  >v>>v????`<br></br>
`?????vvvvvvvvv+|-|-+>>>+>>>^.>+ .??????  ^vvv|||||||||^>>~~||. .>????????`<br></br>
`vvv}}}r}}rr}rv>??+?>>>>>>>?>+|. ~??????| .??v+|||||--~||||~--^>?v???????v-<br></br>
`vvvvvvvvvvvvv?-|^>>>>>>>>>>+>|^+>+?v??v?. ^vv?||||~.`++|~~^>?vvvvvvvvvvvv-<br></br>
`vvvvvvvvv?>>^??`+>>>>>?>>?>>>??>+?vvvvvv^ .?vv>+^|~`-vvvvvvvvvvvvvvvvvvvv-<br></br>
`vvvvvvvvv+|+^>>`^?>>+?v+>+^>?>^>vvvvvvvvv. ~vvv^?^|~`>vvvvvvvvvvvvvvvvvvv-<br></br>
`??????????????>^~+>>>>>++^+?>-~>>???????v+. >v??^?>|``?vvvvvvvvvvvvvvvvvv-<br></br>
.???>>>>>>>>?????>~~^>>>>>>^|--|>?vvvvvvvvv~ `?vvv+?+|``?vvvvvvvvvvvvvvvvv-<br></br>
.>>>>>>>>??????????+~--~~--~|+???vvvvvvvvvv?^~>vvv?-~|-.>vvvv?v?????v?????`<br></br>
.>>>>>>>??????????????>>>>>???????????????????????>^^^^|+?????????????????`<br></br>
.>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>`<br></br>


            </div>,

            <div>

.??????????????>---------------------------------------|??????????????????`<br></br>
`vvvvvv^+}}}}}v?~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~+}}}}vvvvvvvvvvvvvv-<br></br>
`vvvvv?  -v}}}>^|~~~~~~~~~~~-~~~~~~~~~~~~~~~~~~~~~~~~~~|v}}}vvvvvvvv??|>vv-<br></br>
`vvvvvv?. .?}}}}?~~~~~~~~~~~|-~~~~~~~~~~~~~~~||~~~~~~~~|>}v>v}vvvvv+>~~+??`<br></br>
`vvvvvvv?-  >v??+~~~~~~~~~~|^~~~~~~~~~~~~~~~~^~|~~~|+??v}?^^->vv????>|~|||.<br></br>
`vvv?++vvv-``>>~~~~~~~~~~~~>|~~~~~~~~~~~~~~~~^??+??vv}vvv|>>`+>>>?>^~~~~~~.<br></br>
.++>+~~>^|~|--````..~||~|~|>~~~~~~~>???|~~~~~|>+^v}vvvvv?>-. -?^^|~~~~~~~|.<br></br>
.|||~~-`.............~|--|?^~|||||~??>?^~||||~~~|?}vvv?>+`.  .`~~|~~~~||||.<br></br>
.||~~^`.......... .-``|^`-|~~||~~~~-`.`~~~~|||||^vv>>??>.     `|~||||||~~|.<br></br>
.||-~^~. ....... ~>?>--~|||++|-`--`..`.```--||||-|~>+^|~.     ~^>?v??v?>>+.<br></br>
.||~~~~--~~`....`^vv+|~`^>-+v^..~^-..`..~|``~|||~~~|+>>+     .+v}}}}}}}}}}-<br></br>
.|||||||||||-.````++|`~~`+~~^|-..``.....~..`||||||>v}}r?.. ..|}}}}}}vvvvv}-<br></br>
.^^||||?^>|~~`..--|^||~^-`~-~|||~`.......-||||||||?}}}v`.....?}}}vvvvvvvvv-<br></br>
.^^^|>?vvv+~--~|~~|~^^~`... `^|^^^~..`..-^^^^+|~--|?}}~.|?-.>}}?^^+?vvvvvv-<br></br>
.^^^|>v?>+^``|-^|`-~???> .  .^^^^^|  .->|-^?vv^`..`|?+`|+|-`v}>-~-^`>vvvvv-<br></br>
.++^^~~-^^`  -~^v?`~|v}}- . .|^^^+~   .|>+~++^~----~~~~~~-.^?>--^||`>v?+++.<br></br>
.++++~```-`. .-~+}>~~+vv+ .  -?vv}~  . .-~~~~~~~~~~~~|~``.~^-`+>-|--++^^+>`<br></br>
.??>|~^  .`  .``.+v>~~>??. . -vvv?. -^  -|---~~~~~~~~>`..->>||?|>^`+++++>v-<br></br>
`v?^+^|>v?-...  ..>>|~~++~ ``.>+>-  ^+` .>>+|`~~~~~~~?`^vvv>`.-`+~|?>++++>`<br></br>
.??????vvvv?.    .+>+-~-^+` ~^+++. ->?>  ?v?>`~~~~~~~?^~?>`  ..`>+~~+>>>>>`<br></br>
.>>>>>>>>>>>~.  .`+>>+~~^++..>?v^ .>>>>. ->>>-~~~~~~~^v-.   .. .~?|~|?>>>>`<br></br>
.???????>>>>?>~```.|^+|^++>-`^vv` |????~ .>>?+-~~~~~~~+?|`..^^`  +v++??>>?`<br></br>
.??????????????^~-|-^>>>++++|.+^ .>????>  |?vv~~~~~~~~~|++~~~~. .>????????`<br></br>
.??vvvvvvvvvvv?>>?+>>>>>>>>>+^~. -?>????~ .???^~~~~~-`-~~~~-``|+??????????`<br></br>
.??????????????-~^>>>>>++++>^+||^+^>?????. |??>~|~~-.`^^~-~|+?????????????`<br></br>
.??????????++|?>`^>>>>>?++>>+>>>+^???????| .???++|~~.`????????????????????`<br></br>
`vvvvvvvv?^|^^++`|>>>^??+++|+>>|+?????????. -v??^>^~-.+v????????????vvvvvv-<br></br>
.?????????????>>|-^>>>++^^^^>+--++>???????+. +???|?+~``>??????????????vvvv-<br></br>
.>>>++++++>>>>>>>>~-^+++>++||``|+??????????- `>vv?^>^~``?????????????????v`<br></br>
.>>+++++>>>>>>?????^-`---`--~^>>????????????|-+????--~`.+?????????????????`<br></br>
.>>++++>>>>>>>>>>>>>>>++++>>???>>>>>>>>>>>>>>>>>>>+||||~^>>>>>>>>>>>>>>>>>`<br></br>
.+++++++++++++++++++>>>>>>>>++++++++++++++++++++++++++>>++++++++++++++++++.<br></br>


            </div>,

            <div>

.>>>>>>????????+```````````````````````````````````````~????>>>>>>>>>>>>>>`<br></br>
.??????|+vvvvv?>---------------------------------------^vvvvv?????????????`<br></br>
.?????>  -?vvv>|~-----------`--------------------------~?vvvv????????>~+??`<br></br>
.??????>. .?vvvv>----------~~----------------~~--------~+v?+?vvv???^+--^??`<br></br>
.????????`  +?>>^----------||----------------^~~---~^>??v?^|`+????>>>~-~~~.<br></br>
.????^^?v?-``++--~---------+~--------~-----~-|>?^>??vvv??~++`^+++>+|-----~.<br></br>
.^^^^--+^~-~````....-~~~~-~+-~-----+>?>~-----~>^^?vv?????>`  -?||~------~~.<br></br>
.~~~-~-..............~~``~>|-~~~~~->>>?|-~~~~~--~?v????>^`.  .`--~~~~~~~~~.<br></br>
.~~--|. ......... .```~|``~~~~~----``.`----~~~~~|??++>>+.     .~-~~~~~~--~.<br></br>
.~~-~|-.  ...... -+>>-`-~~~^^~-````....````-~~~~`~-+^|~-      -|+??????++|.<br></br>
                  .~~----``--.....`|??^~~.|+-+?|..-|`.....-~..-~~~---~^++^     .^?vvvvvvvvvv`<br></br>
                  .~~~~~~~~~~~-..``.^^|`--`^~-|~`..``.....-..`~~~~~~+?vvv?.   .~vvvvvvvvvvvv-<br></br>
                  .~~~~~~?|^|--...``~||~-|``-`-~~~-`.......-~~~~~~~~?vvv?`.....?vvvvvv?????v-<br></br>
.|||~+????^-``~~~-~-|~-`... `~~~||-.....`||~|^~~`-~?vv-.~>`.+vv?||^>?????v-<br></br>
.|||~+?>+^|``~`|~`-->?>+ .  .~||||~  .`+~`|?vv^`...~?^`|^~``?v>-~`|`+?????`<br></br>
.||||-~-||`  `-|v>`-~vvv-    ~|||^-   .~+^~^^|--```---~~-`.|>+--|~|.+?>^||.<br></br>
                  .^^^^~`.```. .`-^v+--^vv^ .  -???v-  .  `------~-----~-`..-|-`++`|``^|||^+`<br></br>
.>>+~~|  .`  .`..^?+--+??. . -v???. `|  `~-``-------~+`..`++|~>|+|.^^^^^+?`<br></br>
`v?|^|~+??`...  ..^+~--^^~ ``.+^^-  |^` .++^~`------->`^?vv+..-`^-~>+^^^^+`<br></br>
.>>>????????.    .^+^---|^` -~^^^  `^++  >??+`------->|-?+`  ..`+^~-^^^+++`<br></br>
.+++++++++++~.  .`^+^^--^^^ .+??| .++++. `+++`-------^?`.   .. .->~-~>++++`<br></br>
.>>>>+++++++>+-```.~|^~^^^+``|??` ~??>>-  ^+>^`-------^?|...||`  ^?^^?>++>`<br></br>
.>>>>>>?>>??>>>|~`~`|+++^++^~.^|  +>>>>+  ~???-------~-~^^--~-. .^>>>>>>>>`<br></br>
.?????vvvvvvvv?++>^+++++++++^^~. ->+>>>>- .>>>|-----```-----``~^>>>>>>>>>>`<br></br>
.?????????????>`-|+++++^++++|^~|^^|+>>>>>. ~?>>-~--`..||-`-~^>??>>>>>>>>>>`<br></br>
.?????????>^^~>+.|+++++>^^>+^+>>^^>??????~ .>??^^~--.`???>????????????????`<br></br>
.?????????|~||^^.|>++|>?^^^~^++|^?????????. -???|>|-`.^???????????????????`<br></br>
.>>>>>>>>>>>>>++~`^+++^^|^|^>^`-^^>>>>>>>>^. ^>>>|>^~``+??????????????????`<br></br>
.++^^^^^^^^^++++>^--|^^^+^^|~``~^>?????????- `+???|>^-.`>?????????????????`<br></br>
.^^^^^^^^+++++>>>>>|````````-^>>>>?>>?>??>?>~-^?>?>`--`.^??>>>>>>>>>>>>>>>`<br></br>
.^^^^^^^++++++++++++++^^^^^+>>>>>+++++++++++++++++^~~~~~|+++++++++++++++++.<br></br>
                  .^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^.<br></br>


            </div>,

            <div>

.++++++>>>>>>>>|```````````````````````````````````````->>>>>+++++++++++++.<br></br>
.?????>~|??????+```````````````````````````````````````~??????????????????`<br></br>
.??>>?+  `????^~-``````````````````````````````````````->???????>>>>>+~^??`<br></br>
.??>>>?+. .>????+``````````--````````````````~-````````-^??^>?????>|^-`|>>`<br></br>
.>?>>???>`  ^?++|``````````-~```````````````-|--```-|+>??>|~`^??>>+++-`---.<br></br>
.>>?>||???``.^|``-``-``````^~````````-`````-`~+>|+>??????-^^.|^^^+^~`````-.<br></br>
.~~||``^~-`-```.....`--```-^```````^+>+-`````-^|~??????>>^`  `>~~-````````.<br></br>
.-`-`-`..............--``-+~````--`++^>~``----``->??>>>+|`.  .````-``````-.<br></br>
.---`~. ........  .`.`-~``~-`--`````..`````-----~?>^^++^.     .-`------``-.<br></br>
.--`-~`.  ...... -^+^```---|~-````......````----`-`^|~-`      `~^>?>>>>^|~.<br></br>
.--````````......~??|--.~^`|>~..`~` ....`-..`---```-|^^~      |???????????`<br></br>
.-----------`.....||~.```|--~-`..`......`...------^????>.    -????????????`<br></br>
.-----~>~|~``...``-~~-`~`````---`........`-------->????......>????????>>??`<br></br>
.~~~-^>?>>~````--`~`--`. .. .-----`.....`---~|--``->??`.~+`.^??>~~|+>?>>??`<br></br>
.~~~-^?+^|~..-`~-.``+>+^ .  .------  .`^-`~>??|`...->|.~|-`.??+`-`~.^?>>>>`<br></br>
.~~~~--`~~.  ``~?>`-~???`    -~~~~-   .-||-||~````````--``.~+^``~~~.^>+|~~.<br></br>
.~~||-`..``  .``|?^``|??|    `>>??-  .  `````````````-`...-~`.|^`~``~~~~~^.<br></br>
.++^--~  .`   ...|>^``^>>. . `?>>>. `~  `-`````````--^...`^^~-+~^~.~~~~~^>`<br></br>
.?>~~~~^>>`...  ..|^-``~|- ...|||`  ~|. .^^|-.``````-+.|???^..``~`-^||||||.<br></br>
.+++>>>>>>>>.    .|||```~|` `~|||  `|^^  +?>^.```````+~->|.   ..^|-`|||||^.<br></br>
.^^^^^^^^^^^-.   `|^|~`-||| .^>>~ .^^^^. `^||````````~?`.   .. .-+---^||^^.<br></br>
.^^^^^^^^^^^^^`.``.-~|~|||^``~??` ->++^`  |^+|````````|>~. .~~.  |>||>^^^^.<br></br>
.++^+++>++>>+++~-`-`~^^^|^^|~.|~  ^^^^^^  ->>>```````---|~``-`. .|^^^^++++.<br></br>
.>>>??????????>^^+|^^^^^^^^^||-. `^^++^+` .+++~``````.``````..-~^+++++++++`<br></br>
.>>+++>>>>>>>>^``~^^^^^^^^^^~|-~||~^++++^  -++^`-```..~~-``-~^++++++++++++`<br></br>
.>>>>>>>>>^||~+^.|^^^^^+^|^||^+^||+>+>>>>~ .+>>~|--`.`>>+++>>>++>>>>>>>>>>`<br></br>
.>>>>>>>>>~-~~||.~^^^~+>|||-|^^~|>>>>>>>>>. `>>>~^~``.|>>>>>>>>>>>>>>>>>>>`<br></br>
.+++^^^^^^^^++^|-`|^^^^|~|~|+|``||^^^^^+++~. |+++~+|-..^>>>>>>>>>>>>>>>>>>`<br></br>
.|||||||||||||^^^|``~^^|^||~-``-|+>>>>>>>>>` .^>>>~^~`..+>>>>>>>>>>>>>>>>>`<br></br>
.||||||||||^^^^^^+^~`````````~^^+++++++>++++-`|>+++```..|>++++++++++++++++.<br></br>
                  .||||||||||^^^^^^^^^^||||||^^^^^^^^^^^^^^^^^^^^^^^|-----~^^^^^^^^^^^^^^^^^.<br></br>
                  .|||||~~~~~|||||||||||||||||||||||||||||||||||||||||||||||||||~|||||||||||.<br></br>


            </div>,

            <div>

                  .^^^^^^++++++++~.......................................`++++^^^^^^^^^^^^^^.<br></br>
.++++>+-~>>>>>>|```````````````````````````````````````->>>>>>>++++++>++++`<br></br>
.+++++^  `>>>>|-````````````.```````````````````````````+>>>>>+++++++^-~++`<br></br>
.++++++|. .^>>>>^```````````-````````````````-``````````~>+|+>>>>>+~|``~^^.<br></br>
.++++++++`  |>|^~``````````--````````````````~`-````-^+>>+~-`|>>+^^^^`````.<br></br>
.++++~~>>>``.|~````````````|-````````````````-^^~^+>>>>>>-~|.~|||^|-``````.<br></br>
.--~~``|-`````......```````~```````~^^|-``````|~~+>>>+>++|`  `+-~`````````.<br></br>
 ``````....... ......```.`^-```````^^|^-`````````^>>++^|~.   ..```````````.<br></br>
 ````-. ........  .`..`-`.-````````....`````````->+||^^~.     .```````````.<br></br>
                  ``````.  .....  `|^|```-``~-``.............````.``|~-``      ``~+++++^|~-.<br></br>
.```````.``......->+~``.-|`~+-..`-. ....``..````````-~|~      ~>>>>>>>>>>>`<br></br>
.````````````.....~~-.``.~``-`..........`...``````~+>>?^.    `>>>>>>>>>>>>`<br></br>
.`````-^-~-``.....-~-``-..```````.... ...`````````+>>>+. ....+>>>>>>>++++>`<br></br>
.--``|^+++-`..````-````. .  .``````.....````-~``.``^>>`.-^`.|?>+--~|+++++>`<br></br>
.---`~+|~~-..``-`.``^+|| .  .``````   `|-`-+>>~....`^~.-~``.>>|```-.|>++++.<br></br>
.----```--.  .`->^.`->>>`    ``---`   .`~~`~~-``..```````..-^~``~--.|+^~--.<br></br>
.--~-`...`.  ..`~>|``~>>~    `++>>`     ```````````````...`-`.~|`-``-----|.<br></br>
.|^|``-  ..   ...~+~``|++. . `>+++  `-  ```..````````~....||-`^-~-.-----~+`<br></br>
.>+----|++`...  ..~~```-~` ...~-~`  -~. .|~~`.```````|.~>>>|..`.-`-|~---~~.<br></br>
                  .^^|^^+++++^.    .~~~```-~. ``~~-  `~||  ^++|.```````^-`+~.   ..~~``~~~~~~.<br></br>
.~~~~~~||~~~`.   .~~~-``~~~  |^+-  ~|||. .~~~.```````->`.   ..  `^```|~~~~.<br></br>
.||||||||~~~||`....`-~-~~~~`.->>. `^^^|`  ~~|~.```````~^-. .--.  ~+~~^||||.<br></br>
                  .|||||^^^^^^^^^-``-`~|||~|~~-.~-  ~||||~  -^++``````````~~````. .~||||||||.<br></br>
.^^++>>>>>>>>>+||^~|||||||||~~`. `|~||||` .||^-`````...`````..`-|||||||||^.<br></br>
                  .^^^^^^^^^^^^^|.`-|||||~~~||-~--~~-~|||||  `^||````...--````-|^^^^^^^^^^^^.<br></br>
                  .^^^^^^^^^|~~-||.~|||||^~~|~~|||~~|^^^^^^- .^^^~~```..^^^^^^^^^^^^^^^^^^^^.<br></br>
                  .++++++++^-`--~~.-|||-^+~~~-~||-~++++++++^. `++^-|-`..~+++++^^^^++++++++++.<br></br>
                  .^^||||||||||||~``~|||~~-~-~|~``~~|||||||^-  ~^||-^~`..|^^^^^^^^^^^+++++++`<br></br>
                  .~~~~------~~~~~|~``-~~~|~~-`..-~|^^^^+^^++` .|++^-|-`..^+^^^^^^^^++++++++.<br></br>
                  .~~~--~-~~~~~~|||||-`...`..``~|||^^^^^^^^^^^-`~^^^|```..~^^^^^^^^^^^^^^^^|.<br></br>
                  .~~~---~~~~~~~~~||||~~~~~~~||||||||~~~~~~~~~||~~~~~`````-~~~~|||||||~~|||~.<br></br>
                  .~~----------------~~~~~~~~~~~~~~~~----------------~~~~~~-------------~~--.<br></br>


            </div>,

            <div>

                  .~~~~~||||||||^-.......................................`||||||||||||||||~~.<br></br>
                  .^^^^^|`-+++++^~.......................................`++++^^^^^^^^^^^^^^.<br></br>
                  .^^||^|  `^+++~``......................................`|++++^^^^||||~`-^^.<br></br>
                  .^^^^^^~. .|++++~..........``..............`.``........`-+^~^++^^^^-~``-~|.<br></br>
                  .^^^^^^^|.  ~^~~`..........``.............```-```..``~|^+^-`.~^^||~|~`````.<br></br>
                  .|^^|--^^^`..~-..`..``````.~`.....`..`....``.`~|-~|^^++^^`-~.-~-~|~`..``.. <br></br>
                  .``-``.~``.`.........``````-.....`.-||~`...`.`~--^+^^^^^|~.  `|`-`.........<br></br>
                  ```.`....  .. .... .``..`~````````~~~|`.`````..`|+^|||~-.    ...````.````.<br></br>
                  ```.`. ........  ....``..`````............``````^^~~~|-.     .`.``````....<br></br>
                  ``.``..     .   `~~~`.````-``........ .....```..``~-``.      .`-|^||^|--`.<br></br>
                  `````....`......`^^-``.`-.-|`...`. .....`...```.`.``---      -^++++++++++.<br></br>
                  ```````````......--`....-````.....   .... .``````-^+++|     `+++++++^^^^+.<br></br>
                  ``````|`-`.......`-``.`..`.````..    ....````````|+++^. . ..|+++^^^^^^^^^.<br></br>
                  ````~|^||``..```.`.``.. .  .`````.......````-``..`|++` `|. ~++^``-~^^^^^^.<br></br>
                  ````-^~--`..`.``...~|~~ .  .``````   .~`.`|^+-....`|-.`-`..^+~.`.`.~^^^||.<br></br>
                  ``````.``.  .``^|..`+++.    ``````   .`--`--`........``...`~-`.-``.~|~-``.<br></br>
                  .``-``.....  ...-+~..-^+-    .|^^+`     .............`....``..-~.`..`````-.<br></br>
                  .~~~```  ..   .. -|-..~||. . .^^^|  .`  .`.........``-....~~``~`-`.`````-^.<br></br>
                  .+|````~||....   .--``.`-` ...-`-.  ``. .---`......``~.-^^^~. `.```~-````-.<br></br>
                  .~~~~|||^^||.    .`-`.`.`-. .````  .--~  |^|~........~-`|-.    .--`.------.<br></br>
                  .-----------`.   .---```---  ~|^`  ----  .---........`^..    .  `~```-----.<br></br>
                  .~~-----------`... ``-`----..-^^. `|~~~`  --~-........-~`.  ``.  -|--|~---.<br></br>
                  .~~~~~~~~~||~~~``.`.-~~~-~~-`.-`  ----~-  `~|^.........`-`..... .-----~~~~.<br></br>
                  .~||^^^^^^^^^+|~~|-~~~~~~~~~--`. .~-~~~~. .~~~`...............``~~~~~~~~~~.<br></br>
                  .|~~~|||~|~~~|~.``~~~~~---~~`-``--`-~~~~-  `~~~``.`...```..``~~~~~~~~~~~~~.<br></br>
                  .||~~~||~~~--`~~.-~~~~~~--~--~~~--~|~~~~|`  ~~|`-`....||~~~|~~~~~~~~~~~~~|.<br></br>
                  .|||||||||````--.`~~~`||---`-~~`-|||||||||. .|||`~`...-||||||||||||||||||^.<br></br>
                  .~~~~--------~--`.-~~~--`-`-~-..-----~~~~~`  -~~~`~-...~||||||||||||||||||.<br></br>
                  .--`````````------..`-~-~--``..`-~|||||||||` .~|||`~`...~|||||||||||||||||.<br></br>
                  .-````````-----~~~~`.........`~~~~~~~~~~~~~~`.`~~~~.....-~~~~~~~~~~~~~~~~~.<br></br>
                  .``````-----------------``------------------------```````-----------------.<br></br>
                  .`````````````````````````````````````````````````````````````````````````.<br></br>


            </div>,

            <div>

                  .------~~~~~~~~`........................................~~~~~-------------.<br></br>
                  .~~~~~~``|||||~-.......................................`||||~~~~~~~~~~~~~~.<br></br>
                  .~~~~~-  .~|||``........................................~||||~~~~~~~--``~~.<br></br>
                  .~~~~~~-  .-||||-...........`................``.........`|~-~||~~~~``..`--.<br></br>
                  .~~~~~~~~.  `~--`..........``................`.`....`-~~|~``.`~~~----..... <br></br>
                  .~~~~``~~~...``............`.................`--`-~~|||~~```.````-``...... <br></br>
                  ````..``..................`.......`---.......```~||~~~~~-.  .~``......... <br></br>
                  .........  .. ..   ......-`.......----`.........-|~~~--`.    ............ <br></br>
                  ....`.  .......  .....`..`.....................`~~-`--`.     ............ <br></br>
                  ....`..         .`--...`..``......... ..........`.-``..      ..`~~~~~-``` <br></br>
                  ........... ....`~~`...``.`~`..... ... ............````      `~||||||||||.<br></br>
                  .................```....`..`.. ...     .. .......`~|||~     `||||||||||||.<br></br>
                  .....`-```.......```..`..........      ..........~|||~. .  .~|||||~~~~~~~.<br></br>
                  ....`-~~~`.......`.....    ................``.....-||. `-. `||~```-~~~~~~.<br></br>
                  ```.`~````....`....-~-`     ......   .`..`~~|`.....-`.``...~|-.`.`.`~~~~~.<br></br>
                  ````...``.  ..`~-...|||.    ...``.    .``.```.............`-`..```.`~-```.<br></br>
                  ````......   ..`|`..`|~`    .~~~|.     ...................`..``.`..``````.<br></br>
                  .`-`..`  ..   .. `~`..`--. . .~~~~  ..  .............`....```.-```.``````~.<br></br>
                  .|~`````~~.. .   .``...``. .. ```.  ``. .```.........-.`~~~-. ..`.````````.<br></br>
                  .-----~~~~~-.     ```...``. ..```  .```  -~-`........-`.~`.    .``..``````.<br></br>
                  .```````````..   .````..```  `-~`  ````  .```........`~..       .-...`````.<br></br>
                  .`````````````.... .``.````..`~~. .---`.  ````........`-`.  ``.  `-``-````.<br></br>
                  .```-----------`..`.`````````.``  ``````  .-~~..........``....  .`````````.<br></br>
                  .---~~~|||||||~---`------`--``.  .`````-.  ---`................``---------.<br></br>
                  .-------------`..`----`````-````````----`  .--`..... .``....``------------.<br></br>
                  .---------````-`.`----`-``-``---``-------`  ---``.....--------------------.<br></br>
                  .~~~~~~~~-`.````.`--``-~`````-```~~-------  .~--``....`~-----------~~~~~~~.<br></br>
                  .--`````````````..`---``````-`..`````````-`  `---`-`...`-----------~~~~~~~.<br></br>
                  `````````````````..````-````..``---------~. .-~~-```...-~--------------~~.<br></br>
                  ```````````````````.........`--------------..`----.... `-----------------.<br></br>
                  ``````````````````````````````````````````````````.....``````````````````.<br></br>
                  `````````````````````````````````````````````````````````````````````````.<br></br>


            </div>,

            <div>

                  ```````-```````........................................``````````````````.<br></br>
                  .-----`.`-~----`........................................------------------.<br></br>
                  .--````  .----`.........................................`--------`````.``-.<br></br>
                  .--```-`  .`----`.......................................`--`------`.`..```.<br></br>
                  .--``-`-`.  `-``.....................................``--`...`--`````..... <br></br>
                  .````.`---...``............`..................`````------.``..`````....... <br></br>
                  ......`...................`.......````.......`..-------``.  .`........... <br></br>
                  .........  .   .    .....`........````..........`--`````.    ............ <br></br>
                  ......      .     ..............................-``````       ........... <br></br>
                  ......          .```.............. .. ............``...      ..``-``-```. <br></br>
                  ........... .. ..--.....`.``.  ...  .  ... .........``.      .-----------.<br></br>
                  ............ .....`.....`.....  ..     .. .......`----`     .------------.<br></br>
                  ......`.`....  ..................      ..........`----.     `------------.<br></br>
                  ....`````..............    .......................`--. .`. `--`...`------.<br></br>
                  ....`````..........````     ......   .`...`--......`.......--`.....`--```.<br></br>
                  ..........  ...-`...--~.    ......    .`...`...............``......```... <br></br>
                  ..........   ..`-`...--`    .``--.     ................. ....``.........`.<br></br>
                  ```...  ..   .. ```..```. . .-```  ..  .............`....``..`.`.......``.<br></br>
                  .-`....```.      ..`....`. .. ....  ...  ``..........`.`---`. .....`.....`.<br></br>
                  ```````````.     .......`. .....  ..``  ````........`..``.    .`........`.<br></br>
                  ```````````.    ..`....`..  ```.  ````  .`...........`.        .`...```.`.<br></br>
                  `````````````.... ..`..```...--. .````.  ..``.........`..  ...  ``.``````.<br></br>
                  ``````````````.....````````. ..  ``````  .```................  ..````````.<br></br>
                  ```----------````````````````.  .``````.  ```..................``````````.<br></br>
                  ``````````````...``````````.`...`.``````  .```..... ........`````````````.<br></br>
                  ```````````..`` .```````````````.```````.  ```..... .````````````````````.<br></br>
                  `````````....``..```.````..```.``````````  .```.`... .```````````````````.<br></br>
                  ```````````````...`````....``..``````````.  .```.`....``````````````````-.<br></br>
                  .............```....``````.....```````````. .````.`....``````````````````.<br></br>
                  ..........````````...........``````````````...````.... ``````````````````.<br></br>
                  .........````````````.....`````````````...````...........````````````````.<br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  `.````````````.........................................````````````````.. <br></br>
                  ``````..``````.........................................``````````````````.<br></br>
                  ``````  .````..........................................``````````````..``.<br></br>
                  ``````.   `````.........................................``.```````.....``.<br></br>
                  ````````.  .`........................................````....````........ <br></br>
                  ````..```.......................................````````................. <br></br>
                  ................. .................``...........````````..  .`........... <br></br>
                  .......             .....`...........`..........``````...    ............ <br></br>
                  .....             .................. ...........``.....       ........... <br></br>
                  ......          ..`..............     .................      ...``````... <br></br>
                  ..........   .  .``... ....`.  ...     ..  ............      .```````````.<br></br>
                  ............ .   .............  ..     .  ........`````     .````````````.<br></br>
                  ......`.....   ..................       .........`````      `````````````.<br></br>
                  .....````.............     .......     ...........```. .`. .```....``````.<br></br>
                  .....`.......... ...`..     ......   .....```..   .`...... ``.......`````.<br></br>
                  ..........  ...``...```.    ......    ................... ..........`.... <br></br>
                  ...... ...   ...`....``.    .````.     ................  ................ <br></br>
                  ......   .   .  .`....``    .````  ..  ...............  ......... ......`.<br></br>
                  ``.....``.       ........ .. ....  ..   .... ..........```.  ............ <br></br>
                  `...```````      ......... .....  ....  ```. ..........`..    ........... <br></br>
                  ............    ..........  .``.  ....  .............`.        .......... <br></br>
                  .............. .. ...........``. .`....  ...............   ..   .`....... <br></br>
                  .........``................. ..  ......  .```................   ......... <br></br>
                  ``````````````.``.............  ........  ................. ..............<br></br>
                  `````````````...........................  .........  .........`...........<br></br>
                  ```..````...... ..................`....`.  ..`..... ..`..````````````````.<br></br>
                  `````````...... .....``.........`````````  .```....  .```````````````````.<br></br>
                  ..........................................  ........  .``````````````````.<br></br>
                  .................................`````````. ..```....  .`````````````````.<br></br>
                  .....................................................  .`................ <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ......................................................................... <br></br>
                  ........`````.............................................`.............. <br></br>
                  ......  ..```.............................................`.............. <br></br>
                  .......   .`................................................`............ <br></br>
                  ........   .............................................................. <br></br>
                  ..........  ............................................... ............. <br></br>
                  ..............     .......................................  ............. <br></br>
                  ......              ....................................      ........... <br></br>
                  .....             .  ..  ..........   .................       ........... <br></br>
                  .. ...          ..... .......  .           ............      ............ <br></br>
                  ..........      ...... .. ...  ..      ..  ............      ..``````.... <br></br>
                  ............     ...    .....          .   .........``.     ...````...... <br></br>
                  ............     ......    .....        ..........```.      ..``......... <br></br>
                  ........... ..........      ......     ........ ...... ... ..`........... <br></br>
                  ..........  ....  .....     ......    .. .....    ... ...  ..... . ...... <br></br>
                  .........   ..... ....`.    ......    ........       ..   ........ ...... <br></br>
                  .....   .    ...........    ......           .. ..  .    ... .. .  ...... <br></br>
                  ......          ........     ....  ..  ...    .......   ......... ....... <br></br>
                  .........        ........    ....  ..   ....      ... .....  . .......... <br></br>
                  ...........      ........  .....  ....  ....      . .....      .......... <br></br>
                  ............     .........  ....  ....   ...        ...        .......... <br></br>
                  ..............    ......... ...  ......  ....        ...   ..   ......... <br></br>
                  ................ ........... ..  ......  .....        ....      ......... <br></br>
                  ..............................  ........  ....   .           ............ <br></br>
                  .............. .........................  ...... .   ... ................ <br></br>
                  ............... .........................  .......   .................... <br></br>
                  ............... .........................   ......   .................... <br></br>
                  ................ ............ ............  .......   ................... <br></br>
                  ............................  .............  .......   .................. <br></br>
                  ...................      .........................     .................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ...............                                       ................... <br></br>
                  ................            .          ...            ................... <br></br>
                  ......   .......                           .          ................... <br></br>
                  .......   ...... ...........           ............................. .... <br></br>
                  ........   ....................    ..  . .... ............. ............. <br></br>
                  .........   ............................................... ............. <br></br>
                  ...........        ........ .............................    .......... . <br></br>
                  .....               ..  ......... ......................      ........... <br></br>
                  ... .                ..   ........     . ..............       ........... <br></br>
                  ..  .           ....  ..  ...              .... . .....       ........... <br></br>
                  .. ...   .      ....   .. ...   .       .  ............      ............ <br></br>
                  ...........      ...    .  ..              ............     ............. <br></br>
                  .......... .     ....      .....         .............      ............. <br></br>
                  ..........   .........      ......      ......   ....   .  .............. <br></br>
                  ..........  . ..   ....     ......    .. .....     .. ..   ... . . ...... <br></br>
                  ...... ..     ...  ....     .....      .. ...             ...  ... ...... <br></br>
                  .....         ....  ....     ....                        ..  .. .  ...... <br></br>
                  ......          ...  ...     ....   .   .           .    ........ ....... <br></br>
                  .........        ..   ...    ...   ..   ....        . .....  . . ........ <br></br>
                  ...........      ...   ..   ....   ...  ....        .. ..      ... ...... <br></br>
                  ............     ....  ...  ....  ....   ...        ..          . ....... <br></br>
                  .............     ........  ...   ....   ....        ...   ..   ......... <br></br>
                  ................ . ......... ..  ......  ....          ..       ......... <br></br>
                  .............................    ......   ....               ............ <br></br>
                  ..............  .............. .........  ....       ..   ............... <br></br>
                  ............... .........................  .....     .................... <br></br>
                  ............... .........................   ......   .................... <br></br>
                  ................ ............  ...........  .......   ................... <br></br>
                  .................  ........   ............   .......   .................. <br></br>
                  ...................         ................ .....     .................. <br></br>
                  ......................................................................... <br></br>
                  ......................................................................... <br></br>


            </div>,

            <div>

                  ...............                                        .................. <br></br>
                  ...... .........                                      ................... <br></br>
                  ......   ......                                        .............. ... <br></br>
                  .......   ......                             .         .............  ... <br></br>
                  ........   .....          ..                .      ........ ........  ... <br></br>
                  .........   ...           ..                 ........... .. ......        <br></br>
                  ....  ... .               .       .....      ............    ...          <br></br>
                  .                      ..       .....         ........                  <br></br>
                  .  .                 .                        ........       .           <br></br>
                  ...   .   .                      ..          ........... <br></br>
                  ..    .. ...                      ....      ............ <br></br>
                  ..     .  ..                    ......      ............ <br></br>
                  ..  ...         ...       ...          .   .   .....      ............. <br></br>
                  .   ......    .    ..       .....       .. ..     ...   .  .............. <br></br>
                  ..  ......    .    ....     . ...     .  ....      .   .   ...   . ...... <br></br>
                  .....  .      ...   ...      ....      ..  .               ..   .  ...... <br></br>
                  ....           ...   ...     ....                         .  ..    ...... <br></br>
                  ...             ...  ...     ....   .               .    ... . .  ....... <br></br>
                  ....  ...        ..   ..     ...   ..   ...         .  ....    .  ....... <br></br>
                  ...........      ...    .    ...   ...  ....        .  ..      ..  ...... <br></br>
                  ...........      ....    .  ...   ....   ...         .          .   ..... <br></br>
                  .............       . . ..   ..   ....   ....        ..         ......... <br></br>
                  ..............      .......  .   ......   ...          .        ......... <br></br>
                  .............................    ......   ...                  .......... <br></br>
                  ..............   ....... .. .     ......   ...       .     .............. <br></br>
                  ............ ..  ... ...................   ....      .................... <br></br>
                  .......... ....  ... .. .   .. ..........   ... .    .................... <br></br>
                  ...............   .....    ..  ...........  .... .    ................... <br></br>
                  .................   .. ...     ...........   ......    .................. <br></br>
                  ..................          ...............  .....     .................. <br></br>
                  ..................................................     .................. <br></br>
                  ........................................................ ................ <br></br>


            </div>,

            <div>

                  ...............                                        .................. <br></br>
                  ......  .......                                        .................. <br></br>
                  ......   .....                                         ..............  .. <br></br>
                  .......   ......                                        .. .......     .. <br></br>
                  ........    ...                                     .....    .... ..      <br></br>
                  ....  ...    .                                . ........      .           <br></br>
                  .                             .        .  .........                 <br></br>
                  .        ....          ......                    <br></br>
                  ... .                     <br></br>
                  ..                                            ...... .  <br></br>
                  ..        .                                  ........... <br></br>
                  .....      ............ <br></br>
                  .                                          .....      ............. <br></br>
                  ....                                         ...   .   ...   ....... <br></br>
                  ..             ...                   ...      .       ...     ...... <br></br>
                  ..   ...                                    .       ... .. <br></br>
                  .    ..      ....                             .         . <br></br>
                  ..               .    ..     ....                        ..  . .       .. <br></br>
                  ..     ..        ..          ...        .              ...        ..    . <br></br>
                  ...........                  ..         ...         .  .                . <br></br>
                  ..........                  ...    . .   .           .          .   .   . <br></br>
                  .............                ..   ....     .                     .  ..... <br></br>
                  ..............               .   .....    ...                    ........ <br></br>
                  ..............                   . ....   ...                   ......... <br></br>
                  ..............                     .....   ..               ............. <br></br>
                  ..........   .        .          .......    ..        ................... <br></br>
                  .........            ..         .........   ...       ................... <br></br>
                  ..............                    .......    ... .    ................... <br></br>
                  .                                .........    ...      .................. <br></br>
                  ....           .............    ....      ................. <br></br>
                  ..                             ..            . <br></br>
                  <br></br>


            </div>,

            <div>

                  ...                <br></br>
                  ...   ......                                         ................   <br></br>
                  ....                                           ....              <br></br>
                  ..                                              ....           <br></br>
                  .                                                .     .            <br></br>
                  .                                          . .. .                  <br></br>
                  ..                      <br></br>
                  ...                      <br></br>
                  <br></br>
                  <br></br>
                  ..                                           .... .....  <br></br>
                  ...       .......   .. <br></br>
                  ....       .......   .. <br></br>
                  ..                                          ..       ...    ..   . <br></br>
                  .                                                     ..       .     <br></br>
                  .     ..                                                   <br></br>
                  .    .         ..                                         <br></br>
                  .           . .                                          <br></br>
                  ...                <br></br>
                  <br></br>
                  .                      .                    <br></br>
                  ..                                  .        <br></br>
                  .        <br></br>
                  ....  .                                                             <br></br>
                  .                              <br></br>
                  .                   <br></br>
                  . ....              ...                 <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>


            </div>,

            <div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>


            </div>,

            <div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>


            </div>,

            <div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>


            </div>,

            <div>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>


            </div>

      ]

      const [currentFrameIndex, setCurrentFrameIndex] = useState(0);
      const [animating, setAnimating] = useState(true);

      useEffect(() => {
            const timer = setInterval(() => {
                  if (animating) {
                        setCurrentFrameIndex((prevIndex) => (prevIndex + 1) % frames.length);
                  }
            }, 68);


            return () => {
                  clearInterval(timer);
            };
      }, [animating]);

      const handleMouseEnter = () => {
            setAnimating(false);
      };

      const handleMouseLeave = () => {
            setAnimating(true);
      };

      return (
            <pre className='whitespace-pre text-xs'
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
            >
                  {frames[currentFrameIndex]}
            </pre>
      );
};

