import React from 'react';
import { LogoHeader } from '../image/LogoHeader';
import { AnimatedAscii } from '../image/asciis/AnimatedAscii';

export const MajinDonPatchInfo = () => {
  const domenecUrl = 'https://domenecmirallestagliabue.com';
  const polUrl = '/polcrosas';
  const gustavoUrl = 'https://speculative.monster'; // URL for Gustavo Cilleruelo

  const redirectToUrl = (url) => {
    window.location.href = url;
  };

  return (
    <div className='bg-red-300 flex flex-col items-center justify-center h-screen text-black'>
      {/* "pol", "domenec", and "gustavo" buttons */}
      <div className='flex justify-center space-x-8 pt-32'>
        <button className='border-2 border-dashed border-black border-opacity-0 hover:border-opacity-100 px-2' onClick={() => redirectToUrl(domenecUrl)}>
          domenec miralles
        </button>
        <button className='border-2 border-dashed border-black border-opacity-0 hover:border-opacity-100 px-2' onClick={() => redirectToUrl(polUrl)}>
          pol crosas
        </button>
        <button className='border-2 border-dashed border-black border-opacity-0 hover:border-opacity-100 px-2' onClick={() => redirectToUrl(gustavoUrl)}>
          gustavo cilleruelo
        </button>
      </div>

      {/* ASCII div */}
      <div className='flex justify-center items-center h-full font-black'>
        <AnimatedAscii />
      </div>
    </div>
  );
};
